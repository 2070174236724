import React from "react";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import ProductPrice from "../ProductPrice";
import FormattedCurrency from "../../common/FormattedCurrency";

const useStyles = makeStyles(theme => ({
  priceWrap: {
    boxShadow: "none",
    borderRadius: 0,
    alignSelf: "flex-start",
    marginBottom: 20,
    color: theme.palette.component.productBoxColor,
  },
  price: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
    margin: 0,
    fontWeight: 600,
    fontSize: 22,
  },
  priceLabel: {
    fontSize: 12,
    margin: 0,
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
    marginBotton: 5,
  },
}));

const RenderProductPrice = ({ quantity, product }) => {
  const classes = useStyles();
  const intl = useIntl();

  const { pricesInclusiveFreight } = product;

  return (
    <Card className={classes.priceWrap}>
      <h3 className={classes.price}>
        {quantity === null || quantity === undefined ? (
          <FormattedCurrency value={product.minimumPrice} />
        ) : (
          <ProductPrice product={product} quantity={quantity} />
        )}
      </h3>
      <p className={classes.priceLabel}>exkl. moms</p>
      <p className={classes.priceLabel}>
        {pricesInclusiveFreight
          ? intl.formatMessage({
              id: "product.included",
            })
          : intl.formatMessage({
              id: "product.excluded",
            })}
        {". "}
        frakt
      </p>
    </Card>
  );
};

RenderProductPrice.propTypes = {
  quantity: PropTypes.number.isRequired,
  product: PropTypes.shape({
    minimumPrice: PropTypes.string,
    pricesInclusiveFreight: PropTypes.bool,
  }).isRequired,
};

export default RenderProductPrice;

import { gql } from '@apollo/client';

const searchDynamicList = gql`
  query SearchDynamicList($productId: ID!, $searchTerm: String) {
    searchDynamicList(productId: $productId, searchTerm: $searchTerm) {
      label
      values {
        fieldName
        value
      }
    }
  }
`;

export default searchDynamicList;

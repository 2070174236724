import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { TreeItem } from "@mui/lab";
import { useAlert } from "react-alert";
import {
  MOVE_RESOURCES,
  RENAME_FOLDER,
  MOVE_FOLDER,
} from "../../../graphql/mutations";
import { setRefetchFiles } from "../../../actions";

import FolderLabel from "./FolderLabel";
import { useGetSiteFolders } from "../../../hooks";

const useStyles = makeStyles(theme => ({
  draggedOver: {
    backgroundColor: "transparent",
    border: `2px dashed ${theme.palette.primary.dark}`,
    padding: 0,
  },
  notDraggedOver: {
    backgroundColor: "transparent",
    padding: 1,
  },
  selectedRow: props => ({
    "&:focus > .MuiTreeItem-content ": {
      backgroundColor: theme.palette.component.selectedFolder,
    },
    "& .MuiTreeItem-content": {
      backgroundColor: props.isSelected
        ? theme.palette.component.selectedFolder
        : "transparent",
      borderRadius: 2,
      alignItems: "center",
    },
    "& .MuiTreeItem-group": {
      marginTop: 2,
    },
  }),
}));

const RowData = ({
  checkedFolders,
  children,
  folder,
  folderTree,
  handleContextMenu,
  handleOpenNode,
  handleSetEditNode,
  isEditMode,
  moveFolderEnabled,
  onFolderRowClick,
  refetchFolders,
  selectedFolderIds,
  selectedNode,
  setFolderTree,
  handleCheckboxChange,
  handleError,
  //monitoring,
  folderPropertyFormValues,
  setFolderPropertyFormValues,
  propertyFormValues,
  setPropertyFormValues,
  setCheckedFolders,
  isMonitoring,
}) => {
  const alert = useAlert();
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedWorkspace = useSelector(
    state => state.ui.toggleWorkspaces.workspace
  );
  const { execute: getSiteFolders } = useGetSiteFolders();

  const [moveResources] = useMutation(MOVE_RESOURCES);
  const [renameFolder] = useMutation(RENAME_FOLDER);
  const [moveFolder] = useMutation(MOVE_FOLDER);

  const [draggedOver, setDraggedOver] = useState(false);
  const [folderName, setFolderName] = useState(folder ? folder.name : "");

  const isSelected =
    folder && selectedFolderIds.find(x => x === folder.folderId);
  const dynamicStyle = {
    isSelected,
  };

  const { locked, hasContent } = folder || {};

  const folderId = folder && folder.folderId;
  const parentFolderId = folder && folder.parentFolderId;

  const classes = useStyles(dynamicStyle);

  if (!folder) return null;

  const handleOnFolderRowClick = () => {
    handleOpenNode(folder.folderId);
    onFolderRowClick(folder);
  };

  const handleOnKeyDown = e => {
    if (e.key === "Enter" && !isEditMode) {
      handleOnFolderRowClick();
    }
  };

  const onCheckboxChange = e => {
    handleCheckboxChange(e, folder);
  };

  const handleMoveResources = async (e, item) => {
    const draggedFiles = e.dataTransfer.getData("files");
    const resourceIds = JSON.parse(draggedFiles);
    const targetFolderId = item.folderId;
    try {
      const res = await moveResources({
        variables: { resourceIds, targetFolderId },
      });

      if (res && res.data && res.data.moveResources) {
        const { success } = res.data.moveResources;
        if (success) {
          // TODO: After drop, call a successcallback if there is any
          alert.success(
            intl.formatMessage({
              id: "folder.resourceMovedSuccessful",
            })
          );
          dispatch(setRefetchFiles({ refetch: true }));
        } else {
          const files = intl.formatMessage({
            id: "common.someFiles",
          });
          const { errorResult } = res.data.moveResources;
          handleError(errorResult, files);
        }
      }
    } catch (err) {
      console.log("Error in transfering files", e);
    }
  };

  const handleMoveFolder = async (e, item) => {
    const draggedFolder = e.dataTransfer.getData("dragged_folder");
    let draggedFolderJSON = JSON.parse(draggedFolder);

    // Start: Will create the illusion that the folder is moved instantly
    const targetFolderId = `f_${item.folderId}`;
    const draggedFolderParentId = draggedFolderJSON.parentFolderId;
    if (
      targetFolderId === draggedFolderParentId ||
      targetFolderId === draggedFolderJSON.folderId
    ) {
      // Folder not moved.
      return;
    }

    draggedFolderJSON = {
      ...draggedFolderJSON,
      parentFolderId: targetFolderId,
    };
    const newParentFolder = { ...folderTree[targetFolderId] };

    let newFolderTreeData = {
      ...folderTree,
      [draggedFolderJSON.folderId]: { ...draggedFolderJSON },
      [targetFolderId]: {
        ...newParentFolder,
        childFolderIds: [
          ...newParentFolder.childFolderIds,
          draggedFolderJSON.folderId,
        ],
      },
    };

    if (draggedFolderParentId) {
      let draggedFolderParentFolder = folderTree[draggedFolderParentId];
      let newParentFolderChildIds = [];
      if (draggedFolderParentFolder) {
        newParentFolderChildIds =
          draggedFolderParentFolder && draggedFolderParentFolder.childFolderIds
            ? draggedFolderParentFolder.childFolderIds.filter(
                x => x !== draggedFolderJSON.folderId
              )
            : [];
      }
      draggedFolderParentFolder = {
        ...draggedFolderParentFolder,
        childFolderIds: newParentFolderChildIds,
      };
      newFolderTreeData = {
        ...newFolderTreeData,
        [draggedFolderParentId]: draggedFolderParentFolder,
      };
    }

    setFolderTree(newFolderTreeData);

    // End: Will create the illusion that the folder is moved instantly
    if (draggedFolderJSON.folderId !== targetFolderId) {
      try {
        const res = await moveFolder({
          variables: {
            folderId: draggedFolderJSON.folderId,
            parentFolderId: item.folderId,
            position: 0,
          },
        });

        if (res && res.data && res.data.moveFolder) {
          const { success } = res.data.moveFolder;
          if (success) {
            console.log("success in moving folder...");
            refetchFolders();
            alert.success(
              intl.formatMessage({
                id: "folder.folderMovedSuccessful",
              })
            );
          } else {
            const { errorResult } = res.data.moveFolder;
            handleError(errorResult);
            // refetchFolders();
          }
        }
      } catch (err) {
        console.error("An error occurred while moving the folder. >", err);
      }
    }
  };

  const handleDrop = (e, item) => {
    e.stopPropagation();
    setDraggedOver(false);
    const action = e.dataTransfer.getData("action");
    if (action === "MOVE_RESOURCES") {
      handleMoveResources(e, item);
    }
    if (action === "MOVE_FOLDER") {
      handleMoveFolder(e, item);
    }
  };

  const handleFolderDragOver = (e, item) => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleOFolderDragLeave = (e, item) => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const handleOnDragOver = (e, item) => {
    e.preventDefault();
  };

  const handleDragStart = (e, item) => {
    e.stopPropagation();
    e.dataTransfer.setData("dragged_folder", JSON.stringify(item));
    e.dataTransfer.setData("action", "MOVE_FOLDER");
  };

  const handleGetSiteFolders = () => {
    getSiteFolders({
      variables: {
        siteId: selectedWorkspace.id,
      },
    });
  };

  const handleChangeFolderName = async newName => {
    if (folderId !== parentFolderId) {
      try {
        const res = await renameFolder({
          variables: { folderId, newName },
        });

        if (res && res.data && res.data.renameFolder && res.data.renameFolder) {
          const { success } = res.data.renameFolder;
          if (success) {
            handleGetSiteFolders();
            alert.success(
              <FormattedMessage id="context.successChangeFolderName" />
            );
          } else {
            const { errorResult } = res.data.renameFolder;
            handleError(errorResult);
            setFolderName(folder ? folder.name : "");
          }
        }
      } catch (err) {
        console.log("Error changing folder name ", err);
        alert.success(<FormattedMessage id="context.errorChangeFolderName" />);
      }
    }
  };

  const handleNewFolderName = value => {
    handleChangeFolderName(value);
    handleSetEditNode(false);
  };

  const renderFolderLabel = () => {
    return (
      <FolderLabel
        checkedFolders={checkedFolders}
        folder={folder}
        folderId={folderId}
        folderName={folderName}
        handleCheckboxChange={onCheckboxChange}
        handleFolderDragOver={handleFolderDragOver}
        handleNewFolderName={handleNewFolderName}
        handleOFolderDragLeave={handleOFolderDragLeave}
        handleOnFolderRowClick={handleOnFolderRowClick}
        handleOnKeyDown={handleOnKeyDown}
        isEditMode={isEditMode}
        selectedNode={selectedNode}
        setFolderName={setFolderName}
        hasContent={hasContent}
        selectedFolderIds={selectedFolderIds}
        folderPropertyFormValues={folderPropertyFormValues}
        setFolderPropertyFormValues={setFolderPropertyFormValues}
        propertyFormValues={propertyFormValues}
        setPropertyFormValues={setPropertyFormValues}
        setCheckedFolders={setCheckedFolders}
        isMonitoring={isMonitoring}
      />
    );
  };

  return (
    <>
      <TreeItem
        nodeId={folder.folderId}
        label={renderFolderLabel()}
              onContextMenu={e => handleContextMenu(e, folder)}
              onFocusCapture={e => e.stopPropagation()}
        onDrop={e => handleDrop(e, folder)}
        onDragOver={handleOnDragOver}
        onDragStart={e => handleDragStart(e, folder)}
        draggable={moveFolderEnabled && !isEditMode}
        className={clsx([
          draggedOver ? classes.draggedOver : classes.notDraggedOver,
          classes.selectedRow,
        ])}
      >
        {children}
      </TreeItem>
    </>
  );
};

RowData.defaultProps = {
  handleOpenNode: () => {},
  moveFolderEnabled: false,
  children: null,
  isEditMode: false,
  selectedNode: null,
};

RowData.propTypes = {
  selectedNode: PropTypes.shape({}),
  refetchFolders: PropTypes.func.isRequired,
  children: PropTypes.node,
  moveFolderEnabled: PropTypes.bool,
  handleContextMenu: PropTypes.func.isRequired,
  handleOpenNode: PropTypes.func,
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    folderId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFolderRowClick: PropTypes.func.isRequired,
  handleSetEditNode: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  folderTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setFolderTree: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default memo(RowData);

import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import getFolderTreeDataStructure from "../../../../helpers/getFolderTreeDataStructure";
import HomeMadeFolderTree from "../../../common/HomeMadeFolderTree";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#f5f5f5",
    padding: 15,
    overflowY: "auto",
  },
}));

const FolderTreeContainer = ({
  checkedFolders,
  folderPropertyFormValues,
  foldersData,
  includeSubfolders,
  isMonitoring,
  onFolderRowClick,
  openedNodes,
  propertyFormValues,
  selectedFolder,
  setCheckedFolders,
  setConfirmationModal,
  setFolderIdsUnchecked,
  setFolderPropertyFormValues,
  setPropertyFormValues,
}) => {
  const classes = useStyles();
  const [folderTree, setFolderTree] = useState({});

  useEffect(() => {
    const newFolderTree = getFolderTreeDataStructure({ folders: foldersData });
    setFolderTree(newFolderTree);
  }, [foldersData]);

  const handleCheckboxChange = (e, folder) => {
    if (!e.target.checked) {
      let folderIdsToUncheck = [folder.folderId];
      if (includeSubfolders) {
        // folderIdsToUncheck = getChildFolderIds({ folder, folderIdArrays: [] });
        folderIdsToUncheck = [...folderIdsToUncheck, ...folder.childFolderIds];
      }
      setFolderIdsUnchecked(folderIdsToUncheck);
      setConfirmationModal(true);
    }
  };
  return (
    <Box className={classes.root}>
      <HomeMadeFolderTree
        checkedFolders={checkedFolders}
        defaultExpanded={openedNodes}
        folderPropertyFormValues={folderPropertyFormValues}
        folderTree={folderTree}
        handleCheckboxChange={handleCheckboxChange}
        isMonitoring
        onFolderRowClick={onFolderRowClick}
        propertyFormValues={propertyFormValues}
        selectedFolderIds={selectedFolder ? [selectedFolder.folderId] : []}
        setCheckedFolders={setCheckedFolders}
        setFolderPropertyFormValues={setFolderPropertyFormValues}
        setPropertyFormValues={setPropertyFormValues}
      />
    </Box>
  );
};

FolderTreeContainer.propTypes = {
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  foldersData: PropTypes.arrayOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  includeSubfolders: PropTypes.bool.isRequired,
  onFolderRowClick: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  selectedFolder: PropTypes.shape({
    folderId: PropTypes.number.isRequired,
  }).isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  setFolderIdsUnchecked: PropTypes.func.isRequired,
};

function areEqual(prevProps, nextProps) {
  if (nextProps.checkedFolders !== prevProps.checkedFolders) {
    // console.log(`FolderTreeContainer checkedFolders`);

    return false;
  }
  if (nextProps.foldersData !== prevProps.foldersData) {
    // console.log(`FolderTreeContainer foldersData`);
    return false;
  }
  if (nextProps.onFolderRowClick !== prevProps.onFolderRowClick) {
    // console.log(`FolderTreeContainer onFolderRowClick`);
    return false;
  }

  if (nextProps.setConfirmationModal !== prevProps.setConfirmationModal) {
    // console.log(`FolderTreeContainer setConfirmationModal`);
    return false;
  }

  if (nextProps.setFolderIdsUnchecked !== prevProps.setFolderIdsUnchecked) {
    // console.log(`FolderTreeContainer setFolderIdsUnchecked`);
    return false;
  }

  if (nextProps.includeSubfolders !== prevProps.includeSubfolders) {
    // console.log(`FolderTreeContainer includeSubfolders`);
    return false;
  }

  if (nextProps.openedNodes !== prevProps.openedNodes) {
    // console.log(`FolderTreeContainer openedNodes`);
    return false;
  }

  if (nextProps.selectedFolder !== prevProps.selectedFolder) {
    // console.log(`FolderTreeContainer selectedFolder`);
    return false;
  }

  return true;
}

export default memo(FolderTreeContainer, areEqual);

import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import EventIcon from '@mui/icons-material/Event';

const DateEditor = ({ value, onChange, enableEdit, disabled, textInputProps, views, ...props }) => {
  const [internalValue, setInternalValue] = useState(value ? new Date(value) : null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (theValue) => {
    setInternalValue(theValue);
    handleClose();
    onChange(theValue);
  };

  return (
    <DatePicker
      //readOnly={!enableEdit}
      disabled={disabled}
      TransitionComponent={true}
      open={enableEdit && !disabled && open}
      onClose={handleClose}
      value={internalValue}
      onChange={handleChange}
      views={views}
      TransitionProps={{
        onExited: () => setOpen(false),
        unmountOnExit: true,
      }}
      sx={{ width: '100%' }}
      componentsProps={{
        textField: {
          onClick: handleOpen,
          variant: 'standard',
          InputProps: textInputProps,
        },
        popper: {
          zIndex: 1300,
          //onBlur: () => {
          //  setOpen(false);
          //},
        },
      }}
      components={{
        textField: TextField,
      }}
    />
  );
};

export default DateEditor;

DateEditor.defaultProps = {
  enableEdit: false,
  disabled: false,
  views: ['year', 'month', 'day'],
  textInputProps: {
    disableUnderline: true,
    endAdornment: //Set to null if not show.
      <InputAdornment position="end">
        <EventIcon sx={{ opacity: 0.7, fontSize: '16px' }} />
      </InputAdornment>,
    sx: {
      '& .MuiInputBase-root': {
        padding: 0,
      },
      backgroundColor: '#ffffff',
      textAlign: 'left',
      cursor: "text",
      padding: '4px',
      fontSize: '12px',
      minHeight: '1.5em',
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid rgba(0, 0, 0, 0)',
      borderRadius: "4px",
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
      '&.Mui-focused': {
        border: '1px solid rgba(0, 0, 0, 0.5)',
      },
    },
  },
};
import React from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Box from "@mui/material/Box";
import { Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import DropzoneComponent from "react-dropzone-component";
import "../../../node_modules/react-dropzone-component/styles/filepicker.css";
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";
import ImagePlaceholder from "../../assets/img/placeholder.jpg";

import { DROPZONE_PARALLEL_UPLOADS } from "../../constant";

const useStyles = makeStyles(theme => ({
  dzWrap: {
    "& .dropzone": {
      backgroundColor: theme.palette.component.fileUploadBackground,
      padding: "0 20px",
      border: "2px dashed #ddd",
      borderRadius: 4,
      marginTop: 10,
    },
    "& .dz-message": {
      color: theme.palette.component.productBoxColor,
      fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& .dz-image": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  icon: {
    verticalAlign: "middle",
    marginRight: 20,
    color: theme.palette.common.white,
    backgroundColor: "#cecece",
    padding: 10,
    borderRadius: "50%",
    width: "auto",
    height: "auto",
  },
  spinner: {
    marginTop: 5,
    color: theme.palette.primary.main,
  },
}));
const Dropzone = ({ uploadFiles, customClass, customMessageClass }) => {
  const classes = useStyles();

  const handleUploadFiles = async files => {
    if (uploadFiles) {
      uploadFiles({ files });
    }
  };

  const componentConfig = {
    postUrl: "-",
  };

  const djsConfig = {
    maxFilesize: 4000,
    uploadMultiple: true,
    parallelUploads: DROPZONE_PARALLEL_UPLOADS,
    params: {
      name: "testfile",
    },
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview">
        <div className="dz-image">
          <img
            className="dz-image"
            data-dz-thumbnail
            alt="Uploading"
            src={ImagePlaceholder}
          />
        </div>
        <div className="dz-details">
          <div className="dz-size">
            <span data-dz-size="true" />
          </div>
          <div className="dz-filename">
            <span data-dz-name="true" />
          </div>
        </div>
        <div className="dz-progress">
          <span className="dz-upload" data-dz-uploadprogress="true" />
        </div>
        <CircularProgress className={classes.spinner} size={20} />
      </div>
    ),
  };

  const eventHandlers = {
    processingmultiple: files => {
      handleUploadFiles(files);
    },
  };

  return (
    <Box className={clsx([classes.dzWrap, customClass])}>
      <DropzoneComponent
        config={componentConfig}
        djsConfig={djsConfig}
        eventHandlers={eventHandlers}
      >
        <div className={clsx(["dz-message", customMessageClass])}>
          <Icon className={clsx(["fa fa-upload", classes.icon])} />
          <FormattedMessage id="product.dragAndDrop" />
        </div>
      </DropzoneComponent>
    </Box>
  );
};

Dropzone.defaultProps = {
  uploadFiles: () => {},
  isOneStream: false,
};

Dropzone.propTypes = {
  uploadFiles: PropTypes.func,
  isOneStream: PropTypes.bool,
};

export default Dropzone;

import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import SearchAutoComplete from "../../../common/SearchAutoComplete";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: 12,
    },
    "& input": {
      fontSize: 12,
    },
    "& .MuiChip-label": {
      fontSize: 12,
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "3px 4px",
    },
    "& .MuiInputLabel-outlined": {
      top: -7,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      top: 0,
    },
  },
}));

const MonitorSettingsSearch = ({
  label,
  open,
  setOpen,
  setClose,
  placeholder,
  onChange,
  options,
  loading,
  setValue,
  value,
  required,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <SearchAutoComplete
        open={open}
        setOpen={setOpen}
        setClose={setClose}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        loading={loading}
        setValue={setValue}
        value={value}
        label={label}
        required={required}
        multiple
        data-tut="step3"
      />
    </Box>
  );
};

MonitorSettingsSearch.defaultProps = {
  options: [],
  value: [],
  required: true,
};

MonitorSettingsSearch.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  required: PropTypes.bool,
};

export default MonitorSettingsSearch;

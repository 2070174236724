// state.api.fileView
export const getCopyParams = state => state.api.fileView.copyParams;
export const getFileViewOpenNodes = state => state.api.fileView.openedNodes;
export const getRefetchFiles = state => state.api.fileView.refetchFiles;
export const getSelectedResources = state => state.api.fileView.selectedResources;

// state.api.folder
export const getSelectedFolder = state => state.api.folder.data;

// state.ui.fileView
export const getFileViewFields = state => state.ui.fileView.fields;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";
import OrderGroup from "../fragments/OrderGroup";

const DeleteBasketGroup = gql`
  mutation DeleteBasketGroup($packageId: ID!) {
    shoppingCartContext {
      deleteBasketGroup(packageId: $packageId) {
        errorResult {
          ...ErrorResultFields
        }
        success
        result {
          groups {
            ...OrderGroupFragment
          }
        }
      }
    }
  }
  ${OrderGroup}
  ${ErrorResultFragment}
`;

export default DeleteBasketGroup;

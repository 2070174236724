import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const UPDATE_CREATE_COMPANY_ROLE = gql`
  mutation UpdateCompanyRoleRights($companyRole: CompanyRoleInput!) {
    companyAdminContext {
      companyRoles {
        updateCreateCompanyRole(companyRole: $companyRole) {
          errorResult {
            ...ErrorResultFields
          }
          companyRoleId
          companyRoleListItems {
            companyRoleId
            name
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_CREATE_COMPANY_ROLE;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Divider, AppBar, Toolbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  titleDiv: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  divider: {
    height: "80%",
    backgroundColor: "rgba(255,255,255,0.3)",
  },
  button: {
    marginLeft: 0,
  },
}));

const Header = ({
  handleClose,
  headerHeight,
  renderRightDiv,
  renderLeftDiv,
}) => {
  const classes = useStyles({ headerHeight });

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Box className={classes.titleDiv}>{renderLeftDiv()}</Box>
        <Box>{renderRightDiv()}</Box>
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className={classes.button}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Header.defaultProps = {
  headerHeight: 60,
  renderLeftDiv: () => {},
  renderRightDiv: () => {},
};

Header.propTypes = {
  headerHeight: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  renderLeftDiv: PropTypes.func,
  renderRightDiv: PropTypes.func,
};

export default Header;

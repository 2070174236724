import React from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import HistoryTable from "./HistoryTable";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "900px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnPrint: {
    padding: "5px 15px",
    fontSize: 14,
    marginRight: 15,
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
  },
  btnDownload: {
    padding: "5px 15px",
    fontSize: 14,
    marginRight: 15,
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  inline: {
    display: "inline-block",
    marginRight: 20,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  strong: {
    fontWeight: 700,
  },
  textInline: {
    display: "inline-block",
    marginRight: 5,
  },
}));

const HistoryModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="product.history" />}
      >
        <Button className={classes.btnPrint}>
          <Icon className={clsx(["fa fa-print", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.print" />
          </span>
        </Button>
        <Button className={classes.btnDownload}>
          <Icon className={clsx(["fa fa-file-excel", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.dlExcel" />
          </span>
        </Button>
        <Button className={classes.btnDownload}>
          <Icon className={clsx(["fa fa-file-pdf", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.dlPDF" />
          </span>
        </Button>
      </DraggableModalHead>
      <DraggableModalBody>
        <Box align="center">
          <Box className={classes.inline}>
            <Typography className={clsx([classes.strong, classes.textInline])}>
              Lagersaldo:
            </Typography>
            <Typography className={classes.textInline}>59</Typography>
          </Box>
          <Box className={classes.inline}>
            <Typography className={clsx([classes.strong, classes.textInline])}>
              Namn:
            </Typography>
            <Typography className={classes.textInline}>
              12 september - Flygblad A5
            </Typography>
          </Box>
          <Box className={classes.inline}>
            <Typography className={clsx([classes.strong, classes.textInline])}>
              Artikelnummer:
            </Typography>
            <Typography className={classes.textInline}>123456</Typography>
          </Box>
          <Box className={classes.inline}>
            <Typography className={clsx([classes.strong, classes.textInline])}>
              Ansvarig:
            </Typography>
            <Typography className={classes.textInline}>
              Anna Killander
            </Typography>
          </Box>
        </Box>
        <HistoryTable />
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default HistoryModal;

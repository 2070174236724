import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import IconLink from "../common/FormControl/IconLink";
import ColumnSearch from "./ColumnSearch";

const useStyles = makeStyles(theme => ({
  th: {
    padding: 7,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    verticalAlign: "top",
    whiteSpace: "nowrap",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
}));

const PickingListTableHeaderCell = ({
  handleSortField,
  onChange,
  handleClear,
  searchValues,
  fetchPickingListOrders,
  name,
  label,
  toggleHeader,
  handleToggleHeader,
  showSearchField,
}) => {
  const classes = useStyles();

  return (
    <TableCell className={classes.th}>
      {showSearchField ? (
        <>
          <IconLink
            icon="fa fa-search"
            customClass={classes.colSearchIcon}
            onClick={() => handleToggleHeader(name)}
          />

          <TableSortLabel
            active={searchValues.sortField === name}
            direction={
              searchValues.sortField === name && searchValues.sortDesc
                ? "desc"
                : "asc"
            }
            onClick={handleSortField(name)}
          >
            <FormattedMessage id={label} />
          </TableSortLabel>
        </>
      ) : (
        <FormattedMessage id={label} />
      )}

      {toggleHeader[name] && (
        <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
          <div>
            <ColumnSearch
              onChange={onChange}
              handleClear={handleClear}
              name={name}
              value={searchValues[name]}
              fetchPickingListOrders={fetchPickingListOrders}
            />
          </div>
        </ClickAwayListener>
      )}
    </TableCell>
  );
};

PickingListTableHeaderCell.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleToggleHeader: PropTypes.func.isRequired,
  handleSortField: PropTypes.func.isRequired,
  toggleHeader: PropTypes.PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  searchValues: PropTypes.shape({}).isRequired,
  fetchPickingListOrders: PropTypes.func.isRequired,
  showSearchField: PropTypes.func.isRequired,
};

export default PickingListTableHeaderCell;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import ExpansionDefault from "../common/ExpansionDefault";

const useStyles = makeStyles(theme => ({
  siteIcon: {
    display: "inline-block",
    marginRight: 10,
    fontSize: 14,
    verticalAlign: "middle",
    color: theme.palette.text.primary,
  },
  siteName: {
    display: "inline-block",
    fontSize: 12,
    marginBottom: 0,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  divider: {
    marginTop: 5,
    marginBottom: 2,
  },
  folderList: {
    padding: 0,
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 10,
      paddingRight: 10,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiListItemText-primary": {
      fontSize: 12,
      color: theme.palette.text.primary,
    },
    "& .MuiSvgIcon-root": {
      width: "13px !important",
      color: theme.palette.text.secondary,
    },
    maxHeight: "75vh",
    overflowY: "auto",
  },
  folderWrapper: {
    marginBottom: 10,
  },
}));

const RenderFolders = ({ folders, handleClick, site }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => {
    setExpanded(prevState => !prevState);
  };
  return (
    <div className={classes.folderWrapper}>
      <ExpansionDefault
        label={site}
        panelContent={`${site}Content`}
        panelHeader={`${site}Header`}
        expanded={expanded}
        onChange={() => handleToggle()}
      >
        <List className={classes.folderList} component="nav">
          {folders.map(f => {
            return (
              <ListItem key={f.folderId} button onClick={() => handleClick(f)}>
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary={f.folderName} />
              </ListItem>
            );
          })}
        </List>
      </ExpansionDefault>
    </div>
  );
};

RenderFolders.defaultProps = {
  folders: [],
  site: "",
};

RenderFolders.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape({})),
  site: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
};

export default RenderFolders;

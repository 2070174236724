import { gql } from '@apollo/client';

const INIT_DYNAMIC_TEMPLATE_PREVIEW_DOWNLOAD = gql`
  mutation InitDynamicTemplatePreviewDownload(
    $productId: ID!
    $data: [DynamicTemplateFieldValue]
  ) {
    initDynamicTemplatePreviewDownload(productId: $productId, data: $data)
  }
`;

export default INIT_DYNAMIC_TEMPLATE_PREVIEW_DOWNLOAD;

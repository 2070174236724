import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ConfirmCloseModal from "../ConfirmCloseModal";

/**
 * >>>>> TODO >>>>>
 * Warning: "Warning: A history supports only one prompt at a time"
 * See: https://stackoverflow.com/questions/71035091/custom-react-router-prompt-a-history-supports-only-one-prompt-at-a-time#answer-71105897
 * <<<<< TODO <<<<<
 */
const ConfirmCloseModalRouterPrompt = ({ onCancel, onOK, when }) => {
  const history = useHistory();

  const [currentPath, setCurrentPath] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <ConfirmCloseModal
      onCancel={handleCancel}
      onConfirmCloseModal={handleOK}
      open={showPrompt}
      setOpen={setShowPrompt}
    />
  ) : null;
}

ConfirmCloseModalRouterPrompt.defaultProps = {
  onCancel: () => false,
  onOK: () => true,
  when: false,
};

ConfirmCloseModalRouterPrompt.propTypes = {
  onCancel: PropTypes.func,
  onOK: PropTypes.func,
  when: PropTypes.bool,
};

export default ConfirmCloseModalRouterPrompt;

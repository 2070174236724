import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 2,
    marginTop: 10,
  },
}));

const ProductName = ({ name }) => {
  const classes = useStyles();
  return <h5 className={classes.heading}>{name}</h5>;
};

ProductName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProductName;

// eslint-disable-next-line import/prefer-default-export
export const stockInitialValues = {
  allowNegativeStockBalance: false,
  productDescription: "",
  productName: "",
  refillEmail: "",
  refillThreshold: 0,
  stockBalance: 0,
  stockDescription: "",
  stockLocation: "",
  temporaryOutOfStock: false,
};

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ModalBody from "./ModalBody";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: 0,
  },
}));

const ShowHideFieldsModal = ({ open, toggle }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <DraggableModal
      open={open}
      handleClose={toggle}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={toggle}
        title={intl.formatMessage({
          id: "context.showFields",
        })}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <ModalBody toggle={toggle} />
      </DraggableModalBody>
    </DraggableModal>
  );
};

ShowHideFieldsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ShowHideFieldsModal;

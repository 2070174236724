import React, { forwardRef } from "react";
import UserCellValue from "../../common/UserCellValue";

const UserListBox = forwardRef(({ children, ...rest }, ref) => (
  <ul {...rest} ref={ref}>
    {children.map((child, index) => (
      <li {...child.props}>
        <UserCellValue user={child.option} />
      </li>
    ))}
  </ul>
));

export default UserListBox;

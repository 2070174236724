import { gql } from '@apollo/client';

const RECIPIENT_LOOKUP = gql`
  query RecipientLookup($searchTerm: String) {
    shareContext {
      recipientLookup(searchTerm: $searchTerm) {
        username
        firstname
        lastname
      }
    }
  }
`;

export default RECIPIENT_LOOKUP;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPANY_SALESDOCUMENT } from "../../../graphql/queries";
import {
  Grid,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  DraggableModal,
  DraggableModalBody,
  DraggableModalHead,
} from "../../common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InvoiceTable from "./InvoiceTable";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useField } from "@mui/x-date-pickers/internals";
import CompanyAddressAutoComplete from "./CompanyAddressAutoComplete";
import ArticlePricelistAutoComplete from "./ArticlePricelistAutoComplete";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    "& .datePicker": {
      width: "100%",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    "& .MuiAppBar-root": {
      backgroundColor: "lightgray",
      color: "black",
    },
    "& .MuiCardContent-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "& .DayPickerInput ": {
      width: "100%",
    },
    "& .DayPickerInput input": {
      color: theme.palette.text.primary,
      height: "100%",
      border: "1px solid #c4c4c4",
      borderRadius: "0px",
      padding: "10px 10px 10px 12px",
      fontSize: "12px",
      width: "100%",
      boxSizing: "border-box",
      "&:focus": {
        border: "2px solid #000",
      },
    },
    "& .react-datepicker-wrapper": {
      textAlign: "center",
    },
    "& .react-datepicker__input-container input": {
      color: theme.palette.text.primary,
      height: "100%",
      width: "100%",
      border: "none",
      borderRadius: "4px 4px 0px 0",
      borderLeft: "none",
      padding: "23px 12px 6px 12px",
      fontSize: "12px",
      textAlign: "center",
      background: "rgba(0, 0, 0, 0.03)",
      marginTop: "0px",
      marginRight: "16px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      background: "#353e47 !important",
      color: "#fff",
      borderRadius: "0!important",
    },
    "& .DayPickerInput-OverlayWrapper , .react-datepicker-popper": {
      zIndex: 100,
    },
    "& .DayPicker-Day": {
      borderRadius: "100% !important",
      fontSize: "16px !important",
      fontWeight: 600,
    },
    "& .DayPicker-Day--start": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "5px 0 0 5px!important",
    },
    "& .DayPicker-Day--end": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "0 5px 5px 0!important",
    },
    "& .DayPickerInput-Overlay": {
      width: " 700px",
      marginLeft: "-198px",
    },
    "& .DayPicker-Day--weekends": {
      color: "#D32F2F",
      // background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--outside": {
      visibility: "hidden",
    },
    "& .DayPicker-Day--totallyBusy": {
      color: "#fff",
      background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--partialBusy": {
      color: " #000000",
      background: "#fffad6",
    },
    "& .DayPicker-Weekday": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
    "& .DayPicker-Caption > div": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
  },
  datePickerFormControl: {
    width: "100%",
  },
  body: {
    padding: "0px 20px 20px 20px",
  },
  divider: {
    marginTop: 20,
    marginBottom: 0,
    backgroundColor: "#F5F5F5",
    height: 2,
  },
  modal: {
    width: "98vw",
    height: "98vh",
    margin: 0,
    maxHeight: "unset",
    [theme.breakpoints.down("md")]: {
      width: "99%",
    },
  },
  datepicker__title: {},
  notImportant: {
    opacity: 0.7,
  },
  buttonAdd: {
    color: "#2e7d32",
    minWidth: "20px",
    marginLeft: "8px",
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#fff",
    },
  },
  buttonRemove: {
    color: "#dc004e",
    minWidth: "20px",
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#fff",
    },
  },
}));

const InvoiceModal = ({ open, handleClose, selectedSalesDocumentId }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
  );

  const [invoice, setFetchedInvoice] = useState(null);

  const [getCompanySalesDocument, { data: companySalesDocument, loading, errorLoading }] = useLazyQuery(GET_COMPANY_SALESDOCUMENT);
  const [selectedCompanyCard, setSelectedCompanyCard] = useState();
  const [selectedArticle, setSelectedArticle] = useState();

  const companyId = 20;
  useEffect(() => {
    if (!open) {
      return;
    }

    if (!selectedSalesDocumentId || selectedSalesDocumentId === -1) {
      //We are in "create invoiceMode"
      var newInvoice = {
        invoiceId: -1,
        invoiceNo: null,
        invoiceDate: new Date(Date.now()),
        invoiceType: "USER_GENERATED",
        customerName: "",
        customerNo: "",
        yourOrderReference: "",
        amountDue: 0,
        category: [],
        dueDate: null,
        invoiceInfo: {
          companyInvoiceInformationCardId: -1,
          gLN: "",
          orgNo: "",
          paymentTermDays: 30,
          vatRegNo: "",
          invoiceAddress: {
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            address5: "",
            city: "",
            contact: "",
            country: "",
            customerId: "",
            email: "",
            reference: "",
            telephone: "",
            zip: "",
          },
        },
        invoiceRows: [
          {
            amount: 1,
            description: "",
            id: -1,
            price: 0,
            total: 0,
            unit: "",
          },
        ],
      };
      setFetchedInvoice(newInvoice);

      return;
    }

    getCompanySalesDocument({ variables: { salesDocumentId: selectedSalesDocumentId } });
  }, [open, selectedSalesDocumentId]);

  useEffect(() => {

    if (companySalesDocument) {
      var r = companySalesDocument.companyAdminContext.salesDocuments.getSalesDocument;


      setFetchedInvoice(r);
    }
  }, [companySalesDocument])

  const handleAddRow = (row, index) => {
    var invoiceRows = invoice.invoiceRows.slice();
    var id = Math.min(...invoiceRows.map(x => x.id), 0) - 1;

    invoiceRows.splice(index, 0, {
      amount: 1,
      description: "",
      id: id,
      price: 0,
      total: 0,
      unit: "",
    });

    const newInvoice = { ...invoice, invoiceRows };

    setFetchedInvoice(newInvoice);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="" />
      <DraggableModalBody>
        {invoice && (
          <>
            <Box className={classes.root}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={10} lg={6}>

                  {selectedCompanyCard && (selectedCompanyCard.companyName)}
                  <CompanyAddressAutoComplete companyId={companyId} selectedCompany={selectedCompanyCard} onSelectCompany={setSelectedCompanyCard} />

                  {selectedArticle && (selectedArticle.articleNo)}
                  <ArticlePricelistAutoComplete companyId={companyId} selectedArticle={selectedArticle} onSelectArticle={setSelectedArticle} />


                  <TextField
                    value={invoice.header.address.address1}
                    label="Kund"
                    variant="filled"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={2} lg={6}></Grid>
                <Grid item xs={12} sm={2} lg={1}>
                  <FormControl className={classes.datePickerFormControl}>
                    <InputLabel
                      shrink="true"
                      variant="filled"
                      className="datepicker__title"
                    >
                      Fakturadatum
                    </InputLabel>
                    <DatePicker
                      wrapperClassName="datePicker"
                      selected={invoice.documentDate}
                      onChange={date => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Fakturadatum"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} lg={1}>
                  <FormControl className={classes.datePickerFormControl}>
                    <InputLabel
                      shrink="true"
                      variant="filled"
                      className="datepicker__title"
                    >
                      Förfallodatum
                    </InputLabel>
                    {/*<DatePicker
                      wrapperClassName="datePicker"
                      selected={invoice.header.dueDate}
                      onChange={date => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Förfallodatum"
                      fullWidth
                    />*/}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                  <TextField
                    value={invoice.header.address.reference}
                    label="Er referens"
                    variant="filled"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                  <TextField
                    value=""
                    label="Vår referens"
                    variant="filled"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  lg={1}
                  className={classes.notImportant}
                >
                  <TextField
                    value=""
                    label="Fakturamärkning"
                    variant="filled"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Button
                  variant="text"
                  className={classes.buttonAdd}
                  startIcon={<AddCircleOutlineIcon />}
                />
                <Grid item xs={12} sm={4} lg={6}></Grid>
              </Grid>
            </Box>
            <Divider className={classes.divider} />
            <InvoiceTable rows={invoice.rows} onAddRow={handleAddRow} />
            <Box mb={2} />
            <Box className={classes.modalFooter} align="right">
              <Typography>Summa:</Typography>
              <Typography>Moms:</Typography>
              <Typography>Öresavrundning:</Typography>
              <Divider />
              <Typography>Totalt:</Typography>
            </Box>
          </>
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default InvoiceModal;

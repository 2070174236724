import { useContext } from "react";
import { deepLinkContext } from "../context/DeepLinkContext";

export const useDeepLinkContext = () => {
  const context = useContext(deepLinkContext);

  if (context === undefined) {
    throw new Error(
      "Hook useDeepLinkContext is used outside of its Provider."
    );
  }

  return context;
};

import { gql } from '@apollo/client';

const getWorkspacesWithSupport = gql`
  query getWorkspacesWithSupport {
    siteSupportContext {
      workspacesWithSupport {
        errorResult {
          data {
            key
            value
            __typename
          }
          errorCode
          errorMessage
          __typename
        }
        items {
          name
          siteId
          __typename
        }
        success
        __typename
      }
    }
  }
`;

export default getWorkspacesWithSupport;

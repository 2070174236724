import { gql } from '@apollo/client';


const UPDATE_AUTHENTICATOR_SECRET = gql`
  mutation updateAuthenticatorSecret($secret:String!){
	userAccountContext{
		updateAuthenticatorSecret(secret: $secret)
	}
}`;

export default UPDATE_AUTHENTICATOR_SECRET;

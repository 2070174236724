import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { DYNAMIC_PRODUCT_TYPE } from "../../../constant/types";

const useStyles = makeStyles(theme => ({
  btn: {
    width: "100%",
    padding: "5px 12px",
    fontWeight: 600,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
}));

const ProductOrderButton = ({
  productType,
  handleDynamicProductOrderBtn,
  handleBtnOrder,
  product,
  quantity,
}) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.btn}
      variant="contained"
      color="primary"
      size="small"
      onClick={
        productType === DYNAMIC_PRODUCT_TYPE
          ? () =>
              handleDynamicProductOrderBtn({
                item: product,
                quantity,
              })
          : handleBtnOrder
      }
    >
      <FormattedMessage id="product.btnOrder" />
      <Icon className={clsx(["fa fa-shopping-cart", classes.btnIcon])} />
    </Button>
  );
};

ProductOrderButton.propTypes = {
  productType: PropTypes.string.isRequired,
  product: PropTypes.shape({}).isRequired,
  quantity: PropTypes.number.isRequired,
  handleDynamicProductOrderBtn: PropTypes.func.isRequired,
  handleBtnOrder: PropTypes.func.isRequired,
};

export default ProductOrderButton;

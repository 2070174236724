import { useLazyQuery } from "@apollo/client";
import { GET_FOLDER_SITE_ROLE_RIGHTS } from "../graphql/queries";

// eslint-disable-next-line import/prefer-default-export
export const useGetFolderSiteRoleRights = ({ folderId }) => {
  const [
    getFolderSiteRoleRights,
    { data, loading, error, refetch },
  ] = useLazyQuery(GET_FOLDER_SITE_ROLE_RIGHTS, { variables: { folderId } });

  return { getFolderSiteRoleRights, data, loading, refetch, error };
};

import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import PrimaryButton from "./PrimaryButton";

const useStyles = makeStyles(theme => ({
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
}));

const ShowHideColumnButton = ({ onClick, size }) => {
  const classes = useStyles();
  return (
    <PrimaryButton onClick={onClick} marginRight={10}>
      <FormattedMessage id="btn.showCol" />
      <Icon className={clsx(["fa fa-eye", classes.btnIcon])} />
    </PrimaryButton>
  );
};

export default ShowHideColumnButton;

ShowHideColumnButton.defaultProps = {
  size: "medium",
};

ShowHideColumnButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
};

import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  setRefetchFiles,
  setSelectedResources,
  setSiteFolders,
} from "../../actions";
import { ENTITY_TYPE } from "../../constant/fileViewCopyPaste.js";
import {
  COPY_FOLDER,
  COPY_RESOURCES,
} from "../../graphql/mutations/FolderContext";
import { getCopyParams } from "../../helpers/fileViewSelectors";
import { useFileViewCopy, useSnackbar } from "../../hooks";

// eslint-disable-next-line import/prefer-default-export
export const useFileViewPaste = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const fileViewCopy = useFileViewCopy();
  const snackbar = useSnackbar();

  const [copyFolder] = useMutation(COPY_FOLDER);
  const [copyResources] = useMutation(COPY_RESOURCES);

  const copyParams = useSelector(getCopyParams);

  const handleFolderPasted = (siteFolders) => {
    showSuccessMessage();
    dispatch(setSiteFolders({ folders: siteFolders }));
  };

  const handlePaste = ({ folderId, siteId }) => {
    const { type, ids } = copyParams;

    if (type === ENTITY_TYPE.Folder) {
      handlePasteFolder({
        targetFolderId: folderId,
        siteId,
        sourceFolderId: ids,
      });
    }
    else if (type === ENTITY_TYPE.Resource) {
      handlePasteResources({
        resourceIds: ids,
        siteId,
        targetFolderId: folderId,
      });
    }
    else {
      console.warn("** unhandled type:", type);
    }

    fileViewCopy.clearCopyParams();
  };

  const handlePasteFolder = async ({ siteId, sourceFolderId, targetFolderId }) => {
    try {
      const res = await copyFolder({
        variables: {
          siteId,
          sourceFolderId,
          targetFolderId,
        },
      });

      const { success, siteFolders } = (res?.data?.folderContext?.copyFolder || {});

      if (success) {
        return handleFolderPasted(siteFolders);
      }
    } catch (err) {
      console.error("** paste failed", { err });
    }

    showErrorMessage();
  };

  const handlePasteResources = async ({ resourceIds, siteId, targetFolderId }) => {
    try {
      const res = await copyResources({
        variables: {
          siteId,
          resourceIds,
          targetFolderId,
        },
      });

      const { success } = (res?.data?.folderContext?.copyResources || {});

      if (success) {
        return handleResourcesPasted();
      }
    } catch (err) {
      console.error("** paste failed", { err });
    }

    showErrorMessage();
  };

  const handleResourcesPasted = () => {
    showSuccessMessage();
    dispatch(setRefetchFiles({ refetch: true }));
    dispatch(setSelectedResources({ items: [] }));
  };

  const showErrorMessage = () => snackbar.workspaceError(<FormattedMessage id="folder.errorPaste" />);
  const showSuccessMessage = () => alert.success(<FormattedMessage id="common.genericSuccess" />);

  return { paste: handlePaste };
};

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import SampleProfile from "../../../assets/img/mike.jpg";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    marginBottom: 15,
    marginRight: "auto",
  },
  profile: {
    minWidth: 30,
    minHeight: 30,
    marginRight: 10,
  },
  profileImg: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "#EDEDED",
  },
  messageBubble: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#efefef",
    borderRadius: 6,
    lineHeight: 1.7,
  },
  messageInfo: {
    marginBottom: 5,
    marginLeft: 40,
  },
  date: {
    fontSize: 12,
    color: "#888",
    marginRight: 15,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

const MessageYou = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.messageInfo} display="flex" alignItems="center">
        <Typography className={classes.date}>10:45 AM</Typography>
        <Typography className={classes.name}>Mike Doe</Typography>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.profile}>
          <img
            src={SampleProfile}
            alt="Profile"
            className={classes.profileImg}
          />
        </Box>
        <Box className={classes.messageBubble}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore.
        </Box>
      </Box>
    </Box>
  );
};

export default MessageYou;

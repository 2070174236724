import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Table, TableBody } from "@mui/material";
import ConnectedWorkspaceHeader from "./ConnectedWorkspaceHeader";
import ConnectWorkspaceRow from "./ConnectedWorkspaceRow";
import TableRowLoading from "../Table/TableRowLoading";

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #f1f1f1",
    marginTop: 20,
  },
}));

const ConnectedWorkspaces = ({
  siteSupplierFor,
  loading,
  getCompanySupplierSettings,
}) => {
  const classes = useStyles();

  const [connectedWorkspaces, setConnectedWorkspaces] = useState([]);

  useEffect(() => {
    if (Array.isArray(siteSupplierFor)) {
      setConnectedWorkspaces(siteSupplierFor);
    }
  }, [siteSupplierFor]);

  return (
    <Table className={classes.root}>
      <ConnectedWorkspaceHeader />
      <TableBody>
        {loading ? (
          <TableRowLoading />
        ) : (
          <>
            {connectedWorkspaces.map((x, idx) => {
              const {
                active,
                siteName,
                displayName,
                notifyEmail,
                id,
                supplierUsers,
              } = x;

              return (
                <>
                  {supplierUsers && supplierUsers.length > 0 && (
                    <>
                      {supplierUsers.map(supplier => {
                        return (
                          <ConnectWorkspaceRow
                            key={id}
                            supplier={supplier}
                            idx={idx}
                            siteName={siteName}
                            active={active}
                            getCompanySupplierSettings={
                              getCompanySupplierSettings
                            }
                          />
                        );
                      })}
                    </>
                  )}
                </>
              );
            })}
          </>
        )}
      </TableBody>
    </Table>
  );
};

ConnectedWorkspaces.defaultProps = {
  siteSupplierFor: null,
  loading: false,
  supplierAliasRequests: null,
  getCompanySupplierSettings: null,
};

ConnectedWorkspaces.propTypes = {
  loading: PropTypes.bool,
  supplierAliasRequests: PropTypes.arrayOf(PropTypes.shape({})),
  siteSupplierFor: PropTypes.arrayOf(PropTypes.shape({})),
  getCompanySupplierSettings: PropTypes.func,
};

export default ConnectedWorkspaces;

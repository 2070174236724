import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Box, TextField } from "@mui/material";

const NameRow = ({ accountSettings, setAccountSettings }) => {
  const intl = useIntl();
  const { firstname, lastname } = accountSettings;


  const handleChange = e => {
    const { name, value } = e.target;
    setAccountSettings({ ...accountSettings, [name]: value });
  };

  return (
    <Box display="flex">
          <TextField
            
            label={intl.formatMessage({
              id: "account.firstName",
            })}
            name="firstname"
            onChange={handleChange}
            value={firstname || ""}
            variant="standard"
            style={{ paddingRight: 5, fontWeight: 600 }}
            size="small"
            margin="dense"
            fullWidth
          />
          <TextField
            
            label={intl.formatMessage({
              id: "account.lastName",
            })}
            name="lastname"
            onChange={handleChange}
            value={lastname || ""}
            variant="standard"
            style={{ paddingRight: 5, fontWeight: 600 }}
            size="small"
            margin="dense"
            fullWidth
          />
    </Box>
  );
};

NameRow.defaultProps = {
  accountSettings: {},
};

NameRow.propTypes = {
  accountSettings: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
  setAccountSettings: PropTypes.func.isRequired,
};

export default NameRow;

import React, { useCallback, useState } from "react";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import { MENU_TYPES as MenuTypes } from "../../../../constant/types";
import MenuItem from "./MenuItem";

const { MENU_GROUP, MENU_ITEM } = MenuTypes;

const SortableTree = ({
  onRemoveNode,
  onRenameNode,
  onUpdateSortlyMenu,
  sortlyMenu,
}) => {
  const [prevMenu, setPrevMenu] = useState();

  const blockCount = useCallback(
    (index) => {
      const { depth } = sortlyMenu[index];
      let count = 1;
      for (var i = index + 1; i < sortlyMenu.length && sortlyMenu[i].depth > depth; i++) {
        count++;
      }
      return count;
    },
    [sortlyMenu]
  );

  const clamp = (number, min, max) => Math.max(min, Math.min(number, max));

  const findItem = useCallback(
    (id) => {
      const index = sortlyMenu.findIndex(item => `${item.id}` === id);
      if (index === -1) {
        console.warn("** item not found", { id, sortlyMenu });
      }
      return {
        index,
        item: sortlyMenu[index],
      }
    },
    [sortlyMenu]
  );

  const getDepthRange = useCallback(
    (sourceType, insertIndex) => {
      let maxDepth;
      let minDepth;
      
      if (insertIndex < 1 || sourceType === MENU_GROUP) {
        maxDepth = 0;
        minDepth = 0;
      }
      else if (sourceType === MENU_ITEM) {

        const prevItem = sortlyMenu[insertIndex - 1];
        
        if (insertIndex === sortlyMenu.length) {
        
          if (prevItem.menuType === MENU_GROUP) {
            //maxDepth = prevItem.depth + 1;
            maxDepth = 1;
            minDepth = 0;
          }
          else {
            maxDepth = prevItem.depth;
            minDepth = 0;
          }
        
        }
        else {
          const nextItem = sortlyMenu[insertIndex];
      
          if (prevItem.menuType === MENU_GROUP) {
      
            if (nextItem.menuType === MENU_GROUP) {
              minDepth = 0;
              maxDepth = 1;
            }
            else if (nextItem.depth === prevItem.depth) {
              minDepth = 0;
              maxDepth = 1;
            }
            else if (nextItem.depth > prevItem.depth) {
              minDepth = nextItem.depth;
              maxDepth = nextItem.depth;
            }
            else {
              minDepth = nextItem.depth;
              maxDepth = prevItem.depth + 1;
            }
      
          }
          else if (prevItem.menuType === MENU_ITEM) {
            maxDepth = prevItem.depth;
            minDepth = nextItem.depth;
          }
          else {
            maxDepth = 0;
            minDepth = 0;
          }
        }
      
      }
      
      return {
        maxDepth,
        minDepth,
      };
    },
    [sortlyMenu]
  );

  const getInsertIndex = (sourceType, sourceIndex, targetType, targetIndex, targetCount) => {
    let insertIndex = -1;

    if (targetIndex <= sourceIndex) {
      insertIndex = targetIndex;
    }
    else if (targetType === MENU_ITEM || sourceType === MENU_ITEM) {
      insertIndex = targetIndex + 1;
    }
    else if (sourceType === MENU_GROUP) {
      insertIndex = targetIndex + targetCount;
    }

    return insertIndex;
  };

  const getItem = useCallback(
    (index) => ({
      index,
      itemData: sortlyMenu[index],
    }),
    [sortlyMenu]
  );

  const handleDragAbort = () => {
    onUpdateSortlyMenu(prevMenu);
    setPrevMenu();
  };

  const handleDragStart = () => {
    const copy = cloneDeep(sortlyMenu);
    setPrevMenu(copy);
  };

  const handleHover = ({ draggedItem, targetIndex, targetDepth }) => {
    const sourceItem = findItem(draggedItem.itemData.id);
    const { index: sourceIndex } = sourceItem;
    const { menuType: sourceMenuType } = sourceItem.item;
    const sourceCount = sourceMenuType === MENU_ITEM ? 1 : blockCount(sourceIndex);

    const targetItem = getItem(targetIndex);
    const { menuType: targetMenuType } = targetItem.itemData;
    const targetCount = targetMenuType === MENU_ITEM ? 1 : blockCount(targetIndex);

    const insertIndex = getInsertIndex(sourceMenuType, sourceIndex, targetMenuType, targetIndex, targetCount);
    const depthRange = getDepthRange(sourceMenuType, insertIndex);
    const newDepth = clamp(targetDepth, depthRange.minDepth, depthRange.maxDepth);

    moveItems(sourceItem.item.id, insertIndex, newDepth, sourceCount);
  };

  const moveItems = useCallback(
    (sourceId, targetIndex, desiredDepth, itemCount) => {
      const { item: sourceItem, index: sourceIndex } = findItem(sourceId);

      const depthChange = desiredDepth - sourceItem.depth;

      if (sourceIndex == targetIndex && depthChange === 0) {
        return;
      }

      let newItems = [...sortlyMenu];
      let itemsToMove = newItems.slice(sourceIndex, sourceIndex + itemCount);
      itemsToMove.forEach(n => n.depth += depthChange);

      if (targetIndex > sourceIndex) {
        newItems.splice(targetIndex, 0, ...itemsToMove);
        newItems.splice(sourceIndex, itemCount);
      }
      else {
        newItems.splice(sourceIndex, itemCount);
        newItems.splice(targetIndex, 0, ...itemsToMove);
      }

      onUpdateSortlyMenu(newItems);
    },
    [sortlyMenu]
  );

  return (
    <>
      {sortlyMenu && sortlyMenu.length > 0 && sortlyMenu.map((itemData, index) => (
        <MenuItem
          index={index}
          itemData={itemData}
          key={itemData.id}
          onDragAbort={handleDragAbort}
          onDragStart={handleDragStart}
          onHover={handleHover}
          onRemove={onRemoveNode}
          onRename={onRenameNode}
        />
      ))}
    </>
  );
};

SortableTree.propTypes = {
  onRenameNode: PropTypes.func.isRequired,
  onRemoveNode: PropTypes.func.isRequired,
  onUpdateSortlyMenu: PropTypes.func.isRequired,
  sortlyMenu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SortableTree;

import React from "react";
import PropTypes from "prop-types";
import { CancelButton, SaveButton } from ".";

const EditButtons = ({ disabled, onCancel, onSave }) => {
  return (
    <>
      <CancelButton
        disabled={disabled}
        onClick={onCancel}
        marginRight={"0.25em"}
      />
      <SaveButton disabled={disabled} onClick={onSave} />
    </>
  );
};

EditButtons.defaultProps = {
  disabled: false,
};

EditButtons.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditButtons;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ResourceItem from "./ResourceItem";
import FileViewSkeleton from "../../Skeleton/FileViewSkeleton";
import ImageEmpty from "../../../assets/img/empty-folder.svg";

const useStyles = makeStyles(theme => ({
  tableWrap: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
    },
  },
  th: {
    color: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: 10,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
  thEditedBy: {
    color: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: 10,
    paddingTop: 0,
    minWidth: 75,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
  tdSkeleton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tdEmpty: {
    padding: "50px 0",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const ProductsTable = ({
  resources,
  selectedResources,
  setSelectedResources,
  loading,
  isProductEdit,
}) => {
  const classes = useStyles();

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell className={classes.tdSkeleton} colSpan={7}>
            <FileViewSkeleton />
          </TableCell>
        </TableRow>
      );
    }

    if (resources && resources.length > 0) {
      return resources.map(x => (
        <ResourceItem
          key={x.id}
          selectedResources={selectedResources}
          setSelectedResources={setSelectedResources}
          resource={x}
          isProductEdit={isProductEdit}
        />
      ));
    }
    return (
      <TableRow>
        <TableCell colSpan={7} align="center" className={classes.tdEmpty}>
          <img src={ImageEmpty} alt="Empty" />
          <Typography className={classes.emptyText}>
            <FormattedMessage id="product.noFilesInFolder" />
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box className={classes.tableWrap}>
      <Table className={classes.table} size="small" aria-label="Product table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>
              <FormattedMessage id="product.name" />
            </TableCell>
            <TableCell className={classes.th} />
            <TableCell className={classes.thEditedBy}>
              <FormattedMessage id="product.editedBy" />
            </TableCell>
            <TableCell className={classes.th}>
              <FormattedMessage id="product.edited" />
            </TableCell>
            <TableCell className={classes.th}>
              <FormattedMessage id="product.upload" />
            </TableCell>
            <TableCell className={classes.th}>
              <FormattedMessage id="product.size" />
            </TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </Box>
  );
};

ProductsTable.defaultProps = {
  loading: false,
  isProductEdit: false,
};

ProductsTable.propTypes = {
  loading: PropTypes.bool,
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      changedBy: PropTypes.string.isRequired,
      changedDate: PropTypes.string.isRequired,
      uploadedDate: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  setSelectedResources: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          changedBy: PropTypes.string.isRequired,
          changedDate: PropTypes.string.isRequired,
          uploadedDate: PropTypes.string.isRequired,
          size: PropTypes.number.isRequired,
        })
      ),
    }).isRequired
  ).isRequired,
  isProductEdit: PropTypes.bool,
};

export default ProductsTable;

import { gql } from '@apollo/client';

const GET_SELECTABLE_SUPPLIERS = gql`
  query getSelectableSuppliers($siteId: Int!) {
    monitoringContext {
      getSelectableSuppliers(siteId: $siteId) {
        displayName
        siteSupplierId
      }
    }
  }
`;

export default GET_SELECTABLE_SUPPLIERS;

import PropTypes from "prop-types";
import TreeItem from "@mui/lab/TreeItem";
import { makeStyles } from "@mui/styles";
import { StructureLabel } from "../../../components/common/WorkspaceList";
import { STRUCTURE_TYPES } from "../../../constant/types";
import StructureItem from "./StructureItem";

const useStyles = makeStyles(theme => ({
  node: {
    "& .MuiTreeItem-iconContainer": {
      order: 2,
    },
    "& .MuiCollapse-wrapper": {
      marginLeft: 5,
    },
    "& .MuiTreeItem-label": {
      fontSize: 12,
      color: "#5b5654",
    },
    "& .drop-on": {
      backgroundColor: "transparent",
      border: `2px dashed ${theme.palette.primary.dark}`,
      margin: "-2px",
    },
    "& .drop-before": {
      backgroundColor: "transparent",
      borderTop: `2px solid ${theme.palette.primary.dark}`,
      marginTop: "-2px",
    },
  },
  item: {
    "& .MuiTreeItem-label": {
      fontSize: 12,
      color: "#5b5654",
    },
    "& .drop-before": {
      backgroundColor: "transparent",
      borderTop: `2px solid ${theme.palette.primary.dark}`,
      marginTop: "-2px",
    },
  },
}));

const getPath = item => {
  var path = [];
  var n = item;
  while (n !== null) {
    path.push(n.id);
    n = n.parentNode;
  }
  return path.reverse();
};

const StructureFolder = ({
  handleContextMenu,
  node,
  onMoveFolder,
  onMoveWorkspace,
}) => {
  const { id: nodeId } = node;

  const classes = useStyles();

  const handleDragStart = (e, sourceItem) => {
    e.stopPropagation();

    e.effectAllowed = "move";

    var sourcePath = getPath(sourceItem);

    e.dataTransfer.setData("path", JSON.stringify(sourcePath));
    e.dataTransfer.setData("action", "MOVE_FOLDER");
  };

  const handleDragOver = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    var target = e.target;

    const rect = target.getBoundingClientRect();
    const y = e.clientY - rect.top + target.scrollTop;

    var rel_y = y / rect.height;
    if (rel_y < 0.25) {
      target.classList.remove("drop-on");
      target.classList.add("drop-before");
    } else {
      target.classList.remove("drop-before");
      target.classList.add("drop-on");
    }
  };

  const handleDragLeave = e => {
    e.preventDefault();
    removeDropClasses(e.target);
  };

  const removeDropClasses = target => {
    target.classList.remove("drop-before");
    target.classList.remove("drop-on");
  };

  const getRelativePosition = e => {
    const rect = e.target.getBoundingClientRect();
    const y = e.clientY - rect.top + e.target.scrollTop;
    var rel_y = y / rect.height;
    if (rel_y < 0.25) return -1;
    return 0;
  };

  const handleDrop = (e, targetItem) => {
    e.stopPropagation();

    removeDropClasses(e.target);

    var relativePosition = getRelativePosition(e);

    const action = e.dataTransfer.getData("action");
    const sourcePath = JSON.parse(e.dataTransfer.getData("path"));

    var targetPath = getPath(targetItem);

    if (action === "MOVE_WORKSPACE" && !!onMoveWorkspace) {
      onMoveWorkspace({ sourcePath, targetPath, relativePosition });
    }
    if (action === "MOVE_FOLDER" && !!onMoveFolder) {
      onMoveFolder({ sourcePath, targetPath, relativePosition });
    }
  };

  const renderNode = node => {
    if (!node.items || node.items.length === 0) {
      return;
    }
    return node.items.map((o, idx) => {
      if (o.type === STRUCTURE_TYPES.FOLDER) {
        return (
          <StructureFolder
            handleContextMenu={handleContextMenu}
            key={o.id}
            node={o}
            onMoveFolder={onMoveFolder}
            onMoveWorkspace={onMoveWorkspace}
          />
        );
      } else {
        return (
          <StructureItem
            className={classes.item}
            item={o}
            key={o.id}
            onMoveFolder={onMoveFolder}
            onMoveWorkspace={onMoveWorkspace}
          />
        );
      }
    });
  };

  return (
    <TreeItem
      className={classes.node}
      draggable
      label={<StructureLabel item={node} />}
      nodeId={`${nodeId}`}
      onFocusCapture={e => e.stopPropagation()}
      onContextMenu={e => handleContextMenu(e, node)}
      onDragLeave={e => handleDragLeave(e, node)}
      onDragOver={e => handleDragOver(e, node)}
      onDragStart={e => handleDragStart(e, node)}
      onDrop={e => handleDrop(e, node)}
    >
      {renderNode(node)}
    </TreeItem>
  );
};

StructureFolder.propTypes = {
  handleContextMenu: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape([])),
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onMoveFolder: PropTypes.func.isRequired,
  onMoveWorkspace: PropTypes.func.isRequired,
};

export default StructureFolder;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import MessageTime from "../../Messaging/MessageSection/MessageTime";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    maxWidth: "80%",
    marginRight: "auto",
  },
  profile: {
    minWidth: 30,
    minHeight: 30,
    margin: "0 10px auto 0",
  },
  profileImg: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "#EDEDED",
  },
  messageContainer: {
    padding: "7px 10px",
    backgroundColor: "#0499FF",
    color: theme.palette.common.white,
    borderRadius: 6,
    lineHeight: 1.7,
  },
  messageContent: {
    whiteSpace: "pre-wrap",
  },
  messageInfo: {
    marginBottom: 5,
    marginLeft: 40,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
  },
}));



const CommentBox = ({ comment }) => {

  const classes = useStyles();

  return (<Box>
    <Box className={classes.messageInfo} alignItems="center" display="flex">
      <Typography className={classes.name}>{comment.author.username}</Typography>
      <MessageTime dateTime={comment.timeStamp }/>      
    </Box>
    <Box className={classes.root}>
      <Box className={classes.profile}>
        <img alt="Profile" className={classes.profileImg} src={comment.author.avatar} />
      </Box>
      <Box className={classes.messageContainer}>
        <Box className={classes.messageContent}>
          <div dangerouslySetInnerHTML={{ __html: comment.comment }} />
        </Box>
      </Box>
    </Box>
  </Box>)
}

//<Card sx={{ padding: 1, marginTop: 0.5 }}></Card>
export default CommentBox;
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TabPanel from "../../components/TabPanel";
import PageTabs from "../../components/PageTabs";
import TabHeadSkeleton from "../../components/Skeleton/TabHeadSkeleton";
import { GET_REQUEST_FOR_PROPOSAL_TABS } from "../../graphql/queries";
import RFPTabContent from "../../components/RequestForProposal/TabContent";
import SiteSelect from "../../assets/img/site-select.svg";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
  },
  tabRoot: {
    minHeight: 48,
    textTransform: "initial",
  },
  tabHeading: {
    flexDirection: "row",
    color: theme.palette.component.tabColor,
    textTransform: "none",
    fontSize: 14,
  },
  tabHeadingIcon: {
    marginBottom: "0 !important",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selected: {
    backgroundColor: "#88d1d2",
    fontWeight: 600,
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  formControl: {
    fontSize: 12,
    padding: "9px 12px",
    height: "100%",
    color: "#66615b",
  },
  borderRight: {
    borderRight: "1px solid #ddd",
    [theme.breakpoints.down("sm")]: {
      borderRight: 0,
    },
  },
  checkboxLabel: {
    fontSize: 12,
  },
  rootDate: {
    margin: "0 !important",
  },
  uploadWrap: {
    padding: "9px 12px",
    fontSize: 12,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "5px 15px",
    backgroundColor: "#66615B",
    color: "#fff",
  },
  uploadText: {
    margin: "0 15px",
  },
  btnMain: {
    fontSize: 12,
    fontWeight: 600,
  },
  mb20: {
    marginBottom: 20,
  },
  siteSelect: {
    textAlign: "center",
    padding: "60px 0",
    marginTop: 40,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const RequestForProposal = ({ siteId }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState([]);
  const { data: rfpData, loading: loadingGetTabs } = useQuery(
    GET_REQUEST_FOR_PROPOSAL_TABS,
    {
      variables: {
        siteId,
      },
      skip: !siteId,
    }
  );

  useEffect(() => {
    if (
      rfpData &&
      rfpData.requestForProposal &&
      rfpData.requestForProposal.requestForProposalView &&
      rfpData.requestForProposal.requestForProposalView
        .requestForProposalProductListForSite &&
      rfpData.requestForProposal.requestForProposalView
        .requestForProposalProductListForSite.items
    ) {
      const {
        items,
      } = rfpData.requestForProposal.requestForProposalView.requestForProposalProductListForSite;
      setTabs(items);
    }
  }, [rfpData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!siteId) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box className={classes.siteSelect}>
            <img src={SiteSelect} alt="Empty" />
            <Typography className={classes.emptyText}>
              <FormattedMessage id="rfp.siteNeeded" />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation="0" className={classes.wrap}>
        {loadingGetTabs ? (
          <TabHeadSkeleton />
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="Request for Proposal"
            classes={{
              indicator: classes.indicator,
            }}
          >
            {tabs.map((tab, index) => (
              <PageTabs
                label={tab.name}
                // eslint-disable-next-line react/no-array-index-key
                key={`${tab.name}-${index}`}
                iconClass={tab.icon}
                // {...a11yProps(index)}
                value={index}
              />
            ))}
          </Tabs>
        )}
      </AppBar>
      {tabs.map((x, index) => (
        <TabPanel
          value={value}
          boxP={0}
          className={classes.tabPanel}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}-RFP-tabs`}
        >
          <RFPTabContent identifier={x.identifier} siteId={siteId} />
        </TabPanel>
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    siteId: state.ui.toggleWorkspaces.workspace.id,
  };
};

RequestForProposal.propTypes = {
  siteId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(RequestForProposal);

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CustomSelect from "../CustomSelect";

const NetworkSelector = ({ className, name, onChange, options, value }) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    let option = null;
    if (Array.isArray(options) && options.length) {
      option = options.find(n => n.value === value);
    }

    setEditMode(!option);
    setSelectedOption(option);
    if (option && selectedOption?.value !== value) {
      onChange({ target: { name, value } });
    }
  }, [options, value]);

  return (
    <>
      {editMode && (
        <CustomSelect
          customClass={className}
          inputLabel={<FormattedMessage id="common.select" />}
          name={name}
          onChange={onChange}
          onClose={() => {
            setEditMode(!selectedOption);
          }}
          options={options}
          value={value}
        />
      )}
      {!editMode && (
        <Button
          className={className}
          onClick={() => {
            setEditMode(true);
          }}
        >
          <Typography>{selectedOption?.label}</Typography>
        </Button>
      )}
    </>
  );
};

NetworkSelector.defaultProps = {
  className: null,
  onChange: null,
  options: [],
  value: null,
};

NetworkSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
};

export default NetworkSelector;

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography, Icon } from "@mui/material";
import clsx from "clsx";
import CustomTextField from "../../common/TextField";
import MessageBox from "./MessageBox";
import MessageArea from "./MessageArea";
import { getCurrentUserId } from "../../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  channelName: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 5,
  },
  iconLink: {
    fontSize: 10,
    color: "#888",
    marginLeft: 10,
    padding: 0,
    minWidth: "unset",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
    marginRight: 5,
  },
  searchField: {
    visibility: "hidden",
    marginTop: 0,
    marginLeft: 15,
    borderRadius: 4,
    backgroundColor: "rgba(222, 222, 222, 0.45)",
    width: 260,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  infoLink: {
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    minWidth: "unset",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  iconInfo: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 16,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    flex: 1,
  },
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  head: {
    padding: "10px 20px",
    backgroundColor: theme.palette.common.white,
    borderBottom: "1px solid #E4E4E4",
    display: "flex",
    alignItems: "center",
  },
  foot: {
    padding: "0 20px 10px 20px",
  },
}));

const MessageSection = ({
  activeChannelId,
  activeConversationId,
  channel = {},
  channelLoading,
  conversation,
  conversationLoading,
  currentUserId,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const messagesEndRef = useRef(null);

  let name = "",
    messages = [];

  if (activeChannelId && channel && channel.channelMessages) {
    name = "#" + channel.name;
    messages = channel.channelMessages.items || [];
  } else if (activeConversationId && conversation && conversation.messages) {
    name = conversation.members.map(n => "@" + n.name).join(", ");
    messages = conversation.messages.items || [];
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.channelName}>{name}</Typography>
        {false && (
          <Button className={classes.iconLink}>
            <Icon className={clsx(["far fa-user", classes.icon])} />8
          </Button>
        )}
        {false && (
          <Button className={classes.iconLink}>
            <Icon className={clsx(["fas fa-map-pin", classes.icon])} />2
          </Button>
        )}
        <CustomTextField
          disabled
          customClass={classes.searchField}
          placeholder={intl.formatMessage({
            id: "widget.searchInConversation",
          })}
        />
        {false && (
          <Button className={classes.infoLink}>
            <Icon className={clsx(["fa fa-info-circle", classes.iconInfo])} />
          </Button>
        )}
      </Box>
      <MessageArea
        messages={messages}
        disabled={
          Boolean(activeChannelId) === Boolean(activeConversationId) ||
          conversationLoading ||
          channelLoading
        }
        currentUserId={currentUserId}
        messagesEndRef={messagesEndRef}
      />
      <Box className={classes.foot}>
        <MessageBox
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          disabled={conversationLoading}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    currentUserId: getCurrentUserId(state),
  };
};

MessageSection.defaultProps = {
  activeChannelId: null,
  activeConversationId: null,
  channel: {},
  channelLoading: false,
  conversation: null,
  conversationLoading: false,
};

MessageSection.propTypes = {
  activeChannelId: PropTypes.number,
  activeConversationId: PropTypes.number,
  channel: PropTypes.shape({
    name: PropTypes.string,
  }),
  channelLoading: PropTypes.bool,
  conversation: PropTypes.shape({}),
  conversationLoading: PropTypes.bool,
  currentUserId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(MessageSection);

import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  Box,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../hooks";
import { useMutation, useLazyQuery } from "@apollo/client";
import Chip from "@mui/material/Chip";
import { GET_DISPLAY_TAB_VIEW } from "../../../../../graphql/queries";
import {
  HIGHLIGHT_RESOURCE,
  DELETE_RESOURCE_LINK_BY_ID,
  CREATE_RESOURCE_LINK,
} from "../../../../../graphql/mutations";
import PageTabPanel from "../../../../common/PageTabPanel";
import CardDefault from "../../../../common/CardDefault";
import CustomTextField from "../../../../common/TextField";
import CheckboxDefault from "../../../../common/FormControl/Checkbox";
import DateFull from "../../../../common/FormControl/DateFull";
import SearchModal from "./SearchModal";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    minWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-label p": {
        fontSize: 12,
      },
    },
  },
  cardText: {
    marginTop: 0,
    marginBottom: 5,
    fontSize: 12,
    color: theme.palette.component.productBoxText,
  },
  label: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 8,
    fontWeight: 600,
    color: theme.palette.text.lightGray,
  },

  chipLabel: {
    fontSize: 11,
  },
  dateOverride: {
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
  },
  mobilePadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
  btnMain: {
    marginTop: 10,
    padding: "11px 22px",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  popper: {
    width: "42%",
    zIndex: 100,
    top: "25%!important",
    left: "58px!important",
  },
  paper: {
    borderRadius: 4,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    maxHeight: 400,
    overflowY: "auto",
  },
}));

const initialValues = {
  highlighted: {
    from: new Date(),
    to: new Date(),
  },
  isHighlighted: false,
  linkedFolders: [],
  resourceId: "",
};

const Viewing = ({ resourceId, viewingIsDraft, setViewingIsDraft }) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const anchorRef = useRef(null);

  const [highlightResource] = useMutation(HIGHLIGHT_RESOURCE);
  const [deleteResourceLink] = useMutation(DELETE_RESOURCE_LINK_BY_ID);
  const [createResourceLink] = useMutation(CREATE_RESOURCE_LINK);

  const [searchModal, setSearchModal] = useState(false);
  const [displaySettings, setDisplaySetings] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [linkedFolders, setLinkedFolders] = useState([]);

  const [getDisplaySettings, { data: displaySettingsData }] = useLazyQuery(
    GET_DISPLAY_TAB_VIEW
  );

  useEffect(() => {
    if (
      formValues &&
      formValues.linkedFolders &&
      formValues.linkedFolders.length > 0
    ) {
      setLinkedFolders(formValues.linkedFolders);
    }
  }, [formValues.linkedFolders]);

  useEffect(() => {
    if (resourceId) {
      getDisplaySettings({ variables: { resourceId } });
    }
  }, [resourceId]);

  useEffect(() => {
    if (
      displaySettingsData &&
      displaySettingsData.productEdit &&
      displaySettingsData.productEdit.displayTabView
    ) {
      setDisplaySetings(displaySettingsData.productEdit.displayTabView);
    }
  }, [displaySettingsData]);

  useEffect(() => {
    if (displaySettings) {
      let tempSettings = { ...displaySettings };

      if (!displaySettings.highlighted) {
        tempSettings = {
          ...tempSettings,
          highlighted: {
            from: new Date(),
            to: new Date(),
          },
        };
      }

      setFormValues(tempSettings);
    }
  }, [displaySettings]);

  const setDraft = () => {
    if (!viewingIsDraft) {
      setViewingIsDraft(true);
    }
  };

  const setUnDraft = () => {
    if (viewingIsDraft) {
      setViewingIsDraft(false);
    }
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;

    setFormValues({ ...formValues, [name]: checked, isHighlighted: checked });
    setDraft();
  };

  const handleToDate = value => {
    const { highlighted } = formValues;
    setFormValues({
      ...formValues,
      highlighted: { ...highlighted, to: value },
    });
    setDraft();
  };

  const handleFromDate = value => {
    const { highlighted } = formValues;
    setFormValues({
      ...formValues,
      highlighted: { ...highlighted, from: value },
    });
    setDraft();
  };

  const handleHighlightResource = async () => {
    const { highlighted, isHighlighted } = formValues;
    let highlightResourceInput = { resourceId };
    if (isHighlighted) {
      highlightResourceInput = { ...highlightResourceInput, highlighted };
    }
    try {
      const res = await highlightResource({
        variables: { highlightResourceInput },
      });

      if (
        res &&
        res.data &&
        res.data.highlightResource &&
        res.data.highlightResource.success
      ) {
        alert.success(<FormattedMessage id="product.updatedProduct" />);
        setUnDraft();
      } else {
        snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
      }
    } catch (e) {
      console.log(`Error saving viewing ${JSON.stringify(e)}`);
      snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
    }
  };

  const handleSearchModalClose = () => {
    setSearchModal(false);
  };

  const handleFolderRowClick = (e, folder) => {
    e.stopPropagation();
    setSelectedFolder(folder);
    handleSearchModalClose();
  };

  const handleCreateResourceLink = async () => {
    const tempFolder = {
      folderId: selectedFolder.folderId,
      name: selectedFolder.name,
    };
    try {
      const res = await createResourceLink({
        variables: { resourceId, folderId: selectedFolder.folderId },
      });

      const result = res?.data?.productEditContext?.createResourceLink;
      if (result?.success === true) {
        alert.success(<FormattedMessage id="product.createdResourceLink" />);
        tempFolder.resourceAliasId = result.resourceLinkId;
        setLinkedFolders([...linkedFolders, tempFolder]);
        setSelectedFolder({});
      } else {
        snackbar.workspaceError(<FormattedMessage id="common.error" />);
      }
    } catch (err) {
      console.error(err);
      snackbar.workspaceError(<FormattedMessage id="common.error" />);
    }
  };

  const handleDeleteResourceLink = async (id, idx) => {
    const tempFolders = [...linkedFolders];
    tempFolders.splice(idx, 1);
    try {
      const res = await deleteResourceLink({
        variables: { resourceLinkId: id },
      });

      const result = res?.data?.productEditContext?.deleteResourceLinkById;
      if (result?.success === true) {
        alert.success(<FormattedMessage id="product.deletedResourceLink" />);
        setLinkedFolders(tempFolders);
      } else {
        snackbar.workspaceError(<FormattedMessage id="common.error" />);
      }
    } catch (err) {
      console.error(err);
      snackbar.workspaceError(<FormattedMessage id="common.error" />);
    }
  };

  return (
    <PageTabPanel spacing="3">
      <Grid item md={6}>
        <CardDefault
          title={
            <FormattedMessage id="product.displayingTabCreateLinkToTheProduct" />
          }
        >
          <p className={classes.cardText}>
            <FormattedMessage id="product.displayingTabPlaceTheLinkInFolder" />
          </p>
          <CustomTextField shrink
            label={<FormattedMessage id="product.displayingTabSelectFolder" />}
            value={selectedFolder.name}
          />
          <div className={classes.wrapper}>
            <CustomTextField shrink
              label={
                <FormattedMessage id="product.displayingTabSearchForFolder" />
              }
              onClick={() => setSearchModal(true)}
            />
            <Popper
              open={searchModal}
              anchorEl={anchorRef.current}
              transition
              disablePortal
              className={classes.popper}
              placement="right"
            >
              {({ TransitionProps }) => (                
                  <ClickAwayListener onClickAway={handleSearchModalClose}>
                    <Paper className={classes.paper}>
                      <SearchModal
                        handleFolderRowClick={handleFolderRowClick}
                      />
                    </Paper>
                  </ClickAwayListener>                  
              )}
            </Popper>
          </div>
          <Button
            variant="contained"
            className={classes.btnMain}
            onClick={handleCreateResourceLink}
          >
            <FormattedMessage id="btn.add" />
          </Button>
        </CardDefault>
        <CardDefault
          title={
            <FormattedMessage id="product.displayingTabTheProductIsLinkedToTheseFolder" />
          }
        >
          {linkedFolders.length > 0 &&
            linkedFolders.map((x, idx) => {
              return (
                <Chip
                  variant="outlined"
                  size="small"
                  onDelete={() =>
                    handleDeleteResourceLink(x.resourceAliasId, idx)
                  }
                  label={x.name}
                  classes={{ label: classes.chipLabel }}
                />
              );
            })}
        </CardDefault>
      </Grid>
      <Grid item md={6}>
        <CardDefault title={<FormattedMessage id="common.news" />}>
          <CheckboxDefault
            name="isHighlighted"
            onChange={handleCheckboxChange}
            value={formValues.isHighlighted}
            label={<FormattedMessage id="product.displayingTabShowAsNews" />}
          />
          <p className={classes.label}>
            <FormattedMessage id="product.displayingTabFromDate" />
          </p>
          <span className={classes.dateOverride}>
            <DateFull
              onChange={handleFromDate}
              value={formValues.highlighted.from}
            />
          </span>
          <p className={classes.label}>
            <FormattedMessage id="product.displayingTabToDate" />
          </p>
          <span className={classes.dateOverride}>
            <DateFull
              onChange={handleToDate}
              value={formValues.highlighted.to}
            />
          </span>
          <Button
            variant="contained"
            className={classes.btnMain}
            onClick={() => handleHighlightResource()}
          >
            <FormattedMessage id="btn.saveNews" />
          </Button>
        </CardDefault>
      </Grid>
      <Grid item md={12} align="right">
        <Button variant="contained" className={classes.btnMain}>
          <FormattedMessage id="btn.save" />
        </Button>
      </Grid>
    </PageTabPanel>
  );
};

Viewing.defaultProps = {
  displaySettings: {},
  resourceId: null,
};

Viewing.propTypes = {
  displaySettings: PropTypes.shape({ highlighted: PropTypes.shape({}) }),
  resourceId: PropTypes.string,
  viewingIsDraft: PropTypes.bool.isRequired,
  setViewingIsDraft: PropTypes.func.isRequired,
};

export default Viewing;

import React, { useEffect, useState } from "react";
import { debounce } from '@mui/material/utils';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyQuery } from "@apollo/client";
import { SEARCH_COMPANY_CARDS } from "../../../graphql/queries";
import { TextField } from "@mui/material";
import { useIntl } from "react-intl";

const CompanyAddressAutoComplete = ({ companyId, selectedCompany, onSelectCompany }) => {

  const [value, setValue] = React.useState(selectedCompany);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const intl = useIntl();

  const [getCompanyCards, { data: companyData, loading }] = useLazyQuery(SEARCH_COMPANY_CARDS);


  useEffect(() => {

    if (companyData) {

      let newOptions = [];

      if (value) {
        newOptions = [value];
      }

      if (companyData?.companyAdminContext?.salesDocuments?.searchCompanyCards) {
        newOptions = [...newOptions, ...companyData?.companyAdminContext?.salesDocuments?.searchCompanyCards];
      }

      setOptions(newOptions);
    }

  }, [companyData])


  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        getCompanyCards({ variables: { companyId: request.companyId, searchTerm: request.input } })
      }, 400),
    [],
  );
  useEffect(() => {


    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, companyId: companyId }, (results) => { });

  }, [value, inputValue, fetch]);

  const optionLabel = (option) => {
    return option.companyName;
  }

  const renderOption = (props, option) => {
    return (<li {...props}>{optionLabel(option)}</li>);
  }

  return (<Autocomplete

    sx={{ width: 300 }}
    getOptionLabel={(option) =>
      optionLabel(option)
    }
    filterOptions={(x) => x}
    options={options}
    autoComplete
    loading={loading}
    loadingText={intl.formatMessage({ id: "common.genericLoading" })}
    includeInputInList
    filterSelectedOptions
    value={value}
    noOptionsText=""
    onChange={(event, newValue) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);
      onSelectCompany(newValue);
    }}
    onInputChange={(event, newInputValue) => {
      setInputValue(newInputValue);
    }}
    renderInput={(params) => (
      <TextField {...params} label={intl.formatMessage({ id:"admin.salesdocuments.companyselect"})} fullWidth />
    )}

    renderOption={renderOption}

  />)
};

export default CompanyAddressAutoComplete;
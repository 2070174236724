import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { setFolderData, setRefetchFiles } from "../../actions";

const useStyles = makeStyles(theme =>
  ({
    treeNodeLeaf: props => ({
      backgroundColor: props.backgroundColor,
      //backgroundColor:"green",
      border: `2px solid ${props.backgroundColor}`,
      //border: "2px solid purple",
      marginBottom: "1px",
      cursor: "pointer",
      color: theme.palette.getContrastText(props.backgroundColor),
      //color: "yellow",
      padding: "2px 6px",
      paddingLeft: "13px",
      fontSize: "12px",
      fontWeight: "500",

      // Default styling
      // backgroundColor: "#fff",
      // border: "2px solid #fff",
      // boxShadow: "0 1px 5px #f7f7f7"
    }),
    active: props => ({
      backgroundColor: "transparent !important",
      //  boxShadow: "inset 0 0 50px cyan",
      boxShadow: `inset 0 0 50px ${props.backgroundColor}`,
      borderRadius: 3,

      // Default styling
      // backgroundColor: "#fff",
      // boxShadow: "none",
      // borderColor: "#e2e2e2"
    }),
    treeValue: {
      padding: "6px",
      paddingLeft: "15px",
    },
  })
);

// eslint-disable-next-line no-shadow
const TreeNodeLeaf = ({
  folderId,
  data,
  name,
  setFolderId,
  backgroundColor,
  selectedFolderId,
}) => {
  const dynamicStyle = {
    backgroundColor,
  };
  const classes = useStyles(dynamicStyle);
  const dispatch = useDispatch();

  const onClick = ({ id, data }) => {
    dispatch(setFolderData({ folder: data }));
    setFolderId(id);
    if (selectedFolderId !== id) {
      dispatch(setRefetchFiles({ refetch: true }));
    }
  };

  return (
    <div
      className={clsx([
        classes.treeNodeLeaf,
        selectedFolderId === folderId ? classes.active : null,
      ])}
    >
      <div
        className={classes.treeValue}
        onClick={() => onClick({ id: folderId, data })}
      >
        <div dangerouslySetInnerHTML={{ __html: name }} />
      </div>
    </div>
  );
};

TreeNodeLeaf.defaultProps = {
  selectedFolderId: null,
};

TreeNodeLeaf.propTypes = {
  folderId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  setFolderId: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  selectedFolderId: PropTypes.number,
};

export default TreeNodeLeaf;

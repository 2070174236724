import { gql } from '@apollo/client';

const GET_FILES = gql`
  query GetFiles($productId: ID!) {
    dynamicTemplateEditContext {
      getFiles(productId: $productId) {
        files
        resourceId
      }
    }
  }
`;

export default GET_FILES;

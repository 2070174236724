import { gql } from '@apollo/client';

const SITE_SUPPORT_TICKET_FILE_UPLOAD = gql`
  mutation {
    siteSupportContext {
      initSupportTicketFileUpload {
        uploadToken
        uploadUrl
      }
    }
  }
`;

export default SITE_SUPPORT_TICKET_FILE_UPLOAD;

import React from "react";
import PropTypes from "prop-types";
import SelectInline from "../common/FormControl/SelectInline";

const SelectFieldMandatory = ({
  field,
  propertyValuesState,
  handleOnChangeValue,
  loading,
  value,
}) => {
  const { id, name } = field;
  const options = propertyValuesState.map(x => {
    return {
      label: x.name,
      value: x.id,
    };
  });
  return (
    <SelectInline
      key={`field-key-${id}`}
      label={name}
      options={options}
      value={value || ""}
      onChange={handleOnChangeValue}
      loading={loading}
    />
  );
};

SelectFieldMandatory.defaultProps = {
  value: "",
  loading: false,
};

SelectFieldMandatory.propTypes = {
  loading: PropTypes.bool,
  handleOnChangeValue: PropTypes.func.isRequired,
  field: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  propertyValuesState: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.bool,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  value: PropTypes.number,
};

export default SelectFieldMandatory;

import { createActions } from "redux-actions";

export const {
  setSupplierSettings,
  setSupplierSettingsLoading,
  setSupplierSettingsError,
} = createActions({
  SET_SUPPLIER_SETTINGS: ({ items }) => ({ items }),
  SET_SUPPLIER_SETTINGS_LOADING: ({ loading }) => ({ loading }),
  SET_SUPPLIER_SETTINGS_ERROR: ({ error }) => ({ error }),
});

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const INITIALIZE_THUMBNAIL_UPLOAD = gql`
  mutation InitializeThumbnailUpload($resourceId: ID!) {
    initThumbnailUpload(resourceId: $resourceId) {
      success
      errorResult {
        ...ErrorResultFields
      }
      url
      success
    }
  }

  ${ErrorResultFragment}
`;

export default INITIALIZE_THUMBNAIL_UPLOAD;

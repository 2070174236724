import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import DeliveryInfoHeader from "./DeliveryInfoHeader";
import AddressSection from "./AddressSection";
import { getSortedDeliverySettings } from "../../helpers/get";

const useStyles = makeStyles(theme => ({
  productWrap: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
}));

const DeliveryInfo = ({
  addressOptions,
  deliveryAddressChanged,
  deliveryAddress,
  deliveryAddressFieldsError,
  handleAddressChange,
  handleAddressFieldChange,
  handleChangeDeliveryAddress,
  handleRemoveAllProducts,
  invoiceAddress,
  invoiceAddressFieldsError,
  postalAddress,
  postalAddressFieldsError,
  selectedAddressField,
  shoppingCartSettings,
  hideEmptyCartButton,
  showDeliveryAddress,
  showInvoiceAddress,
  showOrdererAddress,
  loadingFetchCart,
  hideAddressDropdown,
}) => {
  const classes = useStyles();
  const [sortedAddressSettings, setSortedAddressSettings] = useState({
    deliveryAddressSettings: [],
    invoiceAddressSettings: [],
    postalAddressSettings: [],
  });

  const [isAllFieldsHidden, setIsAllFieldsHidden] = useState({
    delivery: false,
    postal: false,
    invoice: false,
  });

  useEffect(() => {
    if (shoppingCartSettings) {
      const {
        deliveryAddressSettings,
        invoiceAddressSettings,
        postalAddressSettings,
      } = shoppingCartSettings;

      const sortedDeliverySettings = getSortedDeliverySettings(
        deliveryAddressSettings
      );
      const sortedPostalSettings = getSortedDeliverySettings(
        postalAddressSettings
      );

      const sortedInvoiceSettings = getSortedDeliverySettings(
        invoiceAddressSettings
      );

      const isDeliveryFieldsHidden = sortedDeliverySettings.every(
        x => x.type === "HIDDEN"
      );
      const isPostalFieldsHidden = sortedPostalSettings.every(
        x => x.type === "HIDDEN"
      );
      const isInvoiceFieldsHidden = sortedInvoiceSettings.every(
        x => x.type === "HIDDEN"
      );

      setIsAllFieldsHidden({
        delivery: isDeliveryFieldsHidden,
        postal: isPostalFieldsHidden,
        invoice: isInvoiceFieldsHidden,
      });
      setSortedAddressSettings({
        deliveryAddressSettings: sortedDeliverySettings,
        invoiceAddressSettings: sortedInvoiceSettings,
        postalAddressSettings: sortedPostalSettings,
      });
    }
  }, [shoppingCartSettings]);

  return (
    <div>
      <Box className={classes.productWrap}>
        <DeliveryInfoHeader
          addressOptions={addressOptions}
          handleAddressChange={handleAddressChange}
          handleRemoveAllProducts={handleRemoveAllProducts}
          selectedAddressField={selectedAddressField}
          hideEmptyCartButton={hideEmptyCartButton}
          hideAddressDropdown={hideAddressDropdown}
        />

        <AddressSection
          sortedAddressSettings={sortedAddressSettings}
          deliveryAddress={deliveryAddress}
          postalAddress={postalAddress}
          invoiceAddress={invoiceAddress}
          handleAddressFieldChange={handleAddressFieldChange}
          invoiceAddressFieldsError={invoiceAddressFieldsError}
          postalAddressFieldsError={postalAddressFieldsError}
          deliveryAddressFieldsError={deliveryAddressFieldsError}
          handleChangeDeliveryAddress={handleChangeDeliveryAddress}
          deliveryAddressChanged={deliveryAddressChanged}
          showDeliveryAddress={showDeliveryAddress}
          showInvoiceAddress={showInvoiceAddress}
          showOrdererAddress={showOrdererAddress}
          isAllFieldsHidden={isAllFieldsHidden}
        />
      </Box>
    </div>
  );
};

DeliveryInfo.defaultProps = {
  selectedAddressField: null,
  hideEmptyCartButton: false,
  showDeliveryAddress: true,
  showInvoiceAddress: true,
  showOrdererAddress: true,
  hideAddressDropdown: false,
};

DeliveryInfo.propTypes = {
  showDeliveryAddress: PropTypes.bool,
  showInvoiceAddress: PropTypes.bool,
  showOrdererAddress: PropTypes.bool,
  deliveryAddressChanged: PropTypes.bool.isRequired,
  handleChangeDeliveryAddress: PropTypes.func.isRequired,
  addressOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  handleAddressFieldChange: PropTypes.func.isRequired,
  handleRemoveAllProducts: PropTypes.func.isRequired,
  selectedAddressField: PropTypes.string,
  shoppingCartSettings: PropTypes.shape({
    deliveryAddressSettings: PropTypes.shape({
      type: PropTypes.string,
      displayOrder: PropTypes.number,
      label: PropTypes.string,
    }).isRequired,
    postalAddressSettings: PropTypes.shape({
      type: PropTypes.string,
      displayOrder: PropTypes.number,
      label: PropTypes.string,
    }).isRequired,
    invoiceAddressSettings: PropTypes.shape({
      type: PropTypes.string,
      displayOrder: PropTypes.number,
      label: PropTypes.string,
    }).isRequired,
  }).isRequired,
  deliveryAddress: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    customerId: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
    telephone: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  postalAddress: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    customerId: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
    telephone: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  invoiceAddress: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    customerId: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
    telephone: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  deliveryAddressFieldsError: PropTypes.objectOf(PropTypes.string).isRequired,
  invoiceAddressFieldsError: PropTypes.objectOf(PropTypes.string).isRequired,
  postalAddressFieldsError: PropTypes.objectOf(PropTypes.string).isRequired,
  hideEmptyCartButton: PropTypes.bool,
  hideAddressDropdown: PropTypes.bool,
};
export default memo(DeliveryInfo);

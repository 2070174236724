import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";

import { getSiteId } from "../../../../helpers/selectors";
import { FIND_TAGS_BY_SITE_ID } from "../../../../graphql/queries";
import { SearchAutoComplete } from "../../../common";

const useStyles = makeStyles(theme => ({
  tag: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 10,
    textTransform: "uppercase",
    color: "#fff",
  },
  icon: {
    color: "#fff",
    width: "14px !important",
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
  },
  tagLabelInfo: {
    marginLeft: 10,
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

const ResourceTags = ({ tags, setTags, siteId }) => {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const [openAutoComplete, setAutoCompleteOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAddTag = (chip, suggestion) => {
    if (suggestion) {
      setTags([...tags, suggestion]);
    } else {
      setTags([...tags, { tagName: chip }]);
    }
  };

  const handleRemoveTag = chip => {
    const newValue = tags.filter(c => c.tagName !== chip);
    setTags(newValue);
  };

  const [getTagsSuggestions, { data, loading }] = useLazyQuery(FIND_TAGS_BY_SITE_ID, {
    onCompleted: data => {
      const { tags: tagsData = {} } = data || {};
      const { findTagsBySiteId = [] } = tagsData;
      if (findTagsBySiteId.length === 0) {
        var suggestionListEmpty = { tagId: -1, tagName: "" };
        setSuggestions([suggestionListEmpty]);
      } else {
        setSuggestions(findTagsBySiteId);
      }
    },
    onError: () => {
      setSuggestions([]);
    },
  });

  

  useEffect(() => {
    if (searchTerm) {
      debouncedGetTagsSuggestions(searchTerm);
    }
  }, [searchTerm]);

  const debouncedGetTagsSuggestions = useCallback(
    debounce(text => {
      getTagsSuggestions({ variables: { siteId, q: text } });
    }, 800),
    []
  );

  /*const handleFetchRequested = text => {
    var loadingSuggestion = { tagId: 0, tagName: "" };
    setSuggestions([loadingSuggestion]);
    debouncedGetTagsSuggestions(text);
  };*/

  return (
    <Box>
      <p className={classes.subTitle}>
        <FormattedMessage id="product.tagging" />
        <span className={classes.tagLabelInfo}>
          (<FormattedMessage id="product.taggingInfo" />)
        </span>
      </p>

      <SearchAutoComplete

        open={openAutoComplete}
        setOpen={n => setAutoCompleteOpen(true)}
        setClose={n => setAutoCompleteOpen(false)}

        placeholder={""}
        onChange={e => setSearchTerm(e.target?.value || "")}
        options={suggestions}
        loading={loading}
        setValue={setTags}
        value={tags}
        label={<FormattedMessage id="product.tagging" />}
        required={true}
        multiple
        data-tut="step3"
        renderValue={n => n.tagName}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    siteId: getSiteId(state),
  };
};

ResourceTags.defaultProps = {
  siteId: null,
};

ResourceTags.propTypes = {
  siteId: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.number,
      tagName: PropTypes.string.isRequired,
    })
  ).isRequired,
  setTags: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ResourceTags);

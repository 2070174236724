import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { Item as StyledItem, Menu, Separator, Submenu } from "./StyledComponents";
import { TaskAction, TaskAddPosition as Pos } from "../helper";

const Item = ({ children, disabled, label, onClick, ...rest }) => {
  return (
    <>
      {disabled && (
        <StyledItem disabled {...rest}>
          {label || children}
        </StyledItem>
      )}
      {!disabled && (
        <StyledItem onClick={onClick} {...rest}>
          {label || children}
        </StyledItem>
      )}
    </>
  );
};

const TaskContextMenu = ({ contextMenuId, enableEdit, onAction, task }) => {

  const intl = useIntl();

  if (!task) {
    return;
  }

  const addTaskAction = (pos) => onAction?.({ action: TaskAction.ADD, task, pos });
  const editTaskAction = () => onAction?.({ action: TaskAction.EDIT, task });
  const deleteTaskAction = () => onAction?.({ action: TaskAction.DELETE, task });

  return (
    <Box>
      <Menu id={contextMenuId} sx={{ zIndex: 1300 }}>
        {enableEdit && (
          <Item
            label={intl.formatMessage({ id: "project.taskContextMenu.editTask" })}
            onClick={editTaskAction}
          />
        )}
        {!enableEdit && (
          <Item
            label={intl.formatMessage({ id: "project.taskContextMenu.showTask" })}
            onClick={editTaskAction}
          />
        )}
        <Submenu
          disabled={!enableEdit}
          label={intl.formatMessage({ id: "project.taskContextMenu.addTask" })}
        >
          <Item
            label={intl.formatMessage({ id: "project.taskContextMenu.addTask.before" })}
            onClick={() => addTaskAction(Pos.BEFORE)}
          />
          <Item
            label={intl.formatMessage({ id: "project.taskContextMenu.addTask.after" })}
            onClick={() => addTaskAction(Pos.AFTER)}
          />
          <Separator />
          <Item
            label={intl.formatMessage({ id: "project.taskContextMenu.addTask.subtask" })}
            onClick={() => addTaskAction(Pos.SUBTASK)}
          />
        </Submenu>
        <Separator />
        <Item
          disabled={!enableEdit}
          label={intl.formatMessage({ id: "project.taskContextMenu.deleteTask" })}
          onClick={deleteTaskAction}
        />
      </Menu>
    </Box>
  );

};

export default TaskContextMenu;

import React, { memo } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { TreeItem } from "@mui/lab";


import FolderLabel from "./FolderLabel";

const useStyles = makeStyles(theme => ({
  draggedOver: {
    backgroundColor: "transparent",
    border: "1px dashed #ddd",
    padding: 0,
  },
  notDraggedOver: {
    backgroundColor: "transparent",
    padding: 1,
  },
  selectedRow: props => ({
    "&:focus > .MuiTreeItem-content ": {
      backgroundColor: theme.palette.component.selectedFolder,
    },
    "& .MuiTreeItem-content": {
      backgroundColor: props.isSelected
        ? theme.palette.component.selectedFolder
        : "transparent",
      borderRadius: 2,
      alignItems: "flex-start",
    },
    "& .MuiTreeItem-group": {
      marginTop: 2,
    },
  }),
}));

const RowData = ({ children, folder, handleFolderRowClick, foundFolders }) => {
  const { hasContent } = folder || {};
  const folderId = folder && folder.folderId;

  const classes = useStyles();

  if (!folder) return null;

  const renderFolderLabel = () => {
    return (
      <FolderLabel
        folder={folder}
        folderId={folderId}
        folderName={folder.name}
        hasContent={hasContent}
        foundFolders={foundFolders}
        handleOnFolderRowClick={handleFolderRowClick}
      />
    );
  };

  return (
    <>
      <TreeItem
        nodeId={folder.folderId}
        label={renderFolderLabel()}
        className={clsx([classes.notDraggedOver, classes.selectedRow])}
      >
        {children}
      </TreeItem>
    </>
  );
};

RowData.defaultProps = {
  children: null,
};

RowData.propTypes = {
  children: PropTypes.node,
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    folderId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(RowData);

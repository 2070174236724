import { gql } from '@apollo/client';

const WORKSPACE_DASHBOARD = gql`
  query GetWorkspaceDashboard($siteId: Int!, $route: String) {
    dashboardContext {
      workspaceDashboard(siteId: $siteId, route: $route) {
        canManageDashboard
        dashboardData {
          content
          id
          type
          route
          dashboardSettings {
            name
            description
            template
            background
          }
        }
        newsImageUploadUrl
      }
    }
  }
`;

export default WORKSPACE_DASHBOARD;

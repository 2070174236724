import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton } from "@mui/material";
import PickerFooter from "./PickerFooter";
import { enUS, sv, es } from "date-fns/locale";

const useStyles = makeStyles(theme => ({
  root: {
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      background: "#353e47 !important",
      color: "#fff",
      borderRadius: "0!important",
    },
    "& .DayPickerInput-OverlayWrapper , .react-datepicker-popper": {
      zIndex: 100,
    },
    "& .DayPicker-Day": {
      borderRadius: "100% !important",
      fontSize: "16px !important",
      fontWeight: 600,
    },
    "& .DayPicker-Day--start": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "5px 0 0 5px!important",
    },
    "& .DayPicker-Day--end": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "0 5px 5px 0!important",
    },
    "& .DayPickerInput-Overlay": {
      width: " 600px",
      marginLeft: "-198px",
    },
    "& .DayPicker-Day--weekends": {
      color: "#D32F2F",
      // background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--outside": {
      visibility: "hidden",
    },
    "& .DayPicker-Day--totallyBusy": {
      color: "#fff",
      background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--partialBusy": {
      color: " #000000",
      background: "#fffad6",
    },
    "& .DayPicker-Weekday": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
    "& .DayPicker-Caption > div": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
  },
  closeBtn: {
    position: "fixed",
    top: 10,
    left: 10,
    zIndex: 1,
  },
}));

const DateRangePicker = ({
  open,
  mobile,
  handleClose,
  from,
  to,
  handleOnDayClick,
  pickUpTime,
  returnTime,
  setPickUpTime,
  setReturnTime,
  weekends,
  partialBusy,
  enteredTo,
  handleMonthChange,
  totallyBusy,
  pickUpTimeSetting,
  returnTimeSetting,
  inRangeTotallyUnvailableDays,
  inRangePartialUnavailableDays,
  unavailableDays,
}) => {
  const classes = useStyles();
  const locale = useSelector(state => state.ui.locale.lang);
  const currentLocale = locale == "SV" ? sv : enUS;
  const modifiers = {
    start: from,
    end: enteredTo,
    weekends,
    partialBusy,
    totallyBusy,
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      className={classes.root}
    >
      <IconButton
        edge="start"
        onClick={handleClose}
        className={classes.closeBtn}
      >
        <CloseIcon />
      </IconButton>
      <DayPicker
        numberOfMonths={5}
        mode="range"
        selected={[from, { from, to: enteredTo }]}
        disabled={{ before: new Date() }}
        fromMonth={new Date()}
        onDayClick={(day, mod) => handleOnDayClick(day, mod)}
        modifiersClassNames={{
          start: 'DayPicker-Day--start',
          end: 'DayPicker-Day--end',
          middle: 'DayPicker-Day--selected',
          selected: 'DayPicker-Day--selected',
          today: 'DayPicker-Day',
          weekends: 'DayPicker-Day--weekends',
        }}
        modifiers={modifiers}
        weekStartsOn={locale === "SV" ? 1 : 0}
        onMonthChange={handleMonthChange}
        locale={currentLocale}
      />
      <PickerFooter
        mobile={mobile}
        from={from}
        to={to}
        pickUpTime={pickUpTime}
        returnTime={returnTime}
        setPickUpTime={setPickUpTime}
        setReturnTime={setReturnTime}
        handleClose={handleClose}
        pickUpTimeSetting={pickUpTimeSetting}
        returnTimeSetting={returnTimeSetting}
        inRangeTotallyUnvailableDays={inRangeTotallyUnvailableDays}
        inRangePartialUnavailableDays={inRangePartialUnavailableDays}
        unavailableDays={unavailableDays}
      />
    </Dialog>
  );
};

DateRangePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  from: PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.instanceOf(Date).isRequired,
  handleOnDayClick: PropTypes.func.isRequired,
  partialBusy: PropTypes.instanceOf(Date).isRequired,
  pickUpTime: PropTypes.instanceOf(Date).isRequired,
  setPickUpTime: PropTypes.func.isRequired,
  returnTime: PropTypes.instanceOf(Date).isRequired,
  setReturnTime: PropTypes.func.isRequired,
  weekends: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  enteredTo: PropTypes.instanceOf(Date).isRequired,
  pickUpTimeSetting: PropTypes.instanceOf(Date).isRequired,
  returnTimeSetting: PropTypes.instanceOf(Date).isRequired,
  handleMonthChange: PropTypes.func.isRequired,
  totallyBusy: PropTypes.instanceOf(Date).isRequired,
};

export default DateRangePicker;

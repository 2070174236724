/**
 * An enumeration of the different subscription levels.
 * @enum {string}
 */
export const SUBSCRIPTION_LEVEL = {
  /** Subscription level 'Unknown'. */
  Unknown: "UNKNOWN",
  /** Subscription level 'Free'. */
  Free: "FREE",
  /** Subscription level 'Mini'. */
  Mini: "MINI",
  /** Subscription level 'Standard'. */
  Standard: "STANDARD",
  /** Subscription level 'Business'. */
  Business: "BUSINESS",
  /** Subscription level 'Pro'. */
  Pro: "PRO",
  /** Subscription level 'Premium'. */
  Premium: "PREMIUM",
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { useQuery, useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../hooks";
import { useIntl } from "react-intl";
import PageTabPanel from "../common/PageTabPanel";
import SendRequestColumn from "./SendRequestColumn";
import RFPField from "./RFPField";
import { ErrorText } from "../common";
import {
  GET_RFP_TAB_FIELDS,
  GET_MATCHING_SUPPLIERS,
  GET_RFP_UPLOAD_TOKEN,
} from "../../graphql/queries";
import {
  SELECT_FIELD_MANDATORY,
  MULTI_CHECKBOX_FIELD,
  NUMBER_FIELD,
} from "../../constant/types";
import { CREATE_RFP, RFP_FILE_UPLOAD } from "../../graphql/mutations";
import { validateEmail } from "../../helpers/validators";
import ProposalSkeleton from "../Skeleton/ProposalSkeleton";

const useStyles = makeStyles(theme => ({
  borderRight: {
    borderRight: "1px solid #ddd",
    [theme.breakpoints.down("sm")]: {
      borderRight: 0,
    },
  },
}));

const TabContent = ({ identifier, siteId }) => {
  const classes = useStyles();
  const [fields, setFields] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [validFormValues, setValidFormValues] = useState(false);
  const [validDeliveryForm, setValidDeliveryForm] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedPropertyValues, setSelectedPropertyValues] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [uploadToken, setUploadToken] = useState(null);
  const [createRFP] = useMutation(CREATE_RFP, { errorPolicy: "all" });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [message, setMessage] = useState("");
  const [replyToEmail, setReplyToEmail] = useState("");
  const [RFPUploadFileUrl, setRFPUploadFileUrl] = useState("");
  const alert = useAlert();
  const snackbar = useSnackbar();
  const intl = useIntl();

  const [getRFPUploadUrls, { data: rfpUploadUrls }] = useMutation(
    RFP_FILE_UPLOAD
  );

  useEffect(() => {
    getRFPUploadUrls();
  }, []);

  useEffect(() => {
    if (rfpUploadUrls && rfpUploadUrls.initRequestForProposalFileUpload) {
      setUploadToken(
        rfpUploadUrls.initRequestForProposalFileUpload.uploadToken
      );
      setRFPUploadFileUrl(
        rfpUploadUrls.initRequestForProposalFileUpload.uploadUrl
      );
    }
  }, [rfpUploadUrls]);

  useEffect(() => {
    if (formValues) {
      const formValuesArray = Object.keys(formValues).map(x => formValues[x]);
      const tempProps = [];
      formValuesArray.forEach(x => {
        // Field with type/s SELECT_FIELD_MANDATORY and NUMBER_FIELD
        if ((x.value || x.quantity) && !Array.isArray(x.value)) {
          tempProps.push({
            propertyId: x.id,
            propertyValueId: Number(x.value),
            quantity: Number(x.quantity) || 0,
          });
        }
        // Field with type/s MULTI_CHECKBOX_FIELD and MULTI_SELECT_WITH_QUANTITIES
        if (Array.isArray(x.value)) {
          x.value.forEach(y => {
            tempProps.push({
              propertyId: x.id,
              propertyValueId: y.propertyValueId,
              quantity: y.value || 0,
            });
          });
        }
      });
      setSelectedPropertyValues(tempProps);
    }
  }, [formValues]);

  const { data, loading, error } = useQuery(GET_RFP_TAB_FIELDS, {
    variables: {
      identifier,
    },
    skip: !identifier,
  });

  const { data: dataSuppliers } = useQuery(GET_MATCHING_SUPPLIERS, {
    variables: {
      data: {
        identifier,
        selectedPropertyValues,
        siteId,
      },
    },
    skip:
      !identifier &&
      !siteId &&
      !selectedPropertyValues &&
      selectedPropertyValues.length < 1,
  });

  useEffect(() => {
    if (
      dataSuppliers &&
      dataSuppliers.requestForProposal &&
      dataSuppliers.requestForProposal.getMatchingSuppliers &&
      dataSuppliers.requestForProposal.getMatchingSuppliers.length > 0
    ) {
      setSuppliers(dataSuppliers.requestForProposal.getMatchingSuppliers);
    }
  }, [dataSuppliers]);

  // RFP delivery form validation
  useEffect(() => {
    let errors = 0;
    if (!message) {
      errors += 1;
    }
    if (replyToEmail !== "") {
      if (!validateEmail(replyToEmail)) {
        errors += 1;
      }
    } else {
      errors += 1;
    }

    if (!deliveryDate) {
      errors += 1;
    }
    setValidDeliveryForm(errors === 0);
  }, [message, deliveryDate, replyToEmail]);

  // Dyanamic form validation
  useEffect(() => {
    if (fields && fields.length > 0) {
      let errors = 0;
      fields.every(x => {
        if (
          !formValues ||
          !formValues[x.id] ||
          (x.type === NUMBER_FIELD && !formValues[x.id].quantity) ||
          (x.type !== NUMBER_FIELD && !formValues[x.id].value)
        ) {
          errors += 1;
          return false;
        }
        return true;
      });
      setValidFormValues(errors === 0);
    }
  }, [fields, formValues]);

  useEffect(() => {
    if (
      data &&
      data.requestForProposal &&
      data.requestForProposal.productDetail &&
      data.requestForProposal.productDetail.properties
    ) {
      const { properties = [] } = data.requestForProposal.productDetail;
      setFields(properties);
      if (properties && properties.length > 0) {
        let initialFormValues = {};
        properties.forEach(property => {
          const { id, type, propertyValues } = property;
          let value = null;
          let quantity = 0;
          if (type === SELECT_FIELD_MANDATORY) {
            propertyValues.forEach(pV => {
              if (pV.selected) {
                value = pV.id;
              }
            });
          }
          if (type === MULTI_CHECKBOX_FIELD) {
            let tempValue = [];
            propertyValues.forEach(pV => {
              if (pV.selected) {
                tempValue = [...tempValue, pV.ID];
              }
            });
            value = [...tempValue];
          }

          if (type === NUMBER_FIELD) {
            const { minValue } = property;
            quantity = minValue || 0;
          }
          initialFormValues = {
            ...initialFormValues,
            [id]: { id, value, quantity },
          };
        });
        setFormValues(initialFormValues);
      }
    }
  }, [data]);

  const handleOnChange = ({ id, value, quantity }) => {
    setFormValues({ ...formValues, [id]: { id, value, quantity } });
  };

  const handleOnSubmitRFP = async () => {
    const fileTokensArray = uploadedFiles.map(x => x.fileToken);
    const resourcesIdArray = selectedResources.map(x => x.id);
    const attachmentsInput = {
      fileTokens: fileTokensArray,
      resourceIds: resourcesIdArray,
      uploadToken,
    };

    try {
      const results = await createRFP({
        variables: {
          attachments: attachmentsInput,
          identifier,
          message,
          replyToEmail,
          requestedDeliveryDate: deliveryDate,
          requestedSupplierIds: selectedSuppliers,
          selectedPropertyValues,
          siteId,
        },
      });
      if (results && results.data && results.data.createRequestForProposal) {
        alert.success(intl.formatMessage({ id: "rfp.successSubmittedRFP" }));
      } else if (
        results &&
        results.error &&
        results.error[0] &&
        results.error[0].message
      ) {
        console.error(`ERROR ${JSON.stringify(results.error[0].message)}`);
        snackbar.workspaceError(results.error[0].message);
      } else {
        snackbar.workspaceError(
          "An error has occurred while submitting Request For Proposal"
        );
      }
    } catch (err) {
      console.error(`ERROR ${JSON.stringify(err)}`);
      snackbar.workspaceError(
        "An error has occurred while submitting Request For Proposal"
      );
    }
  };

  const handleOnSelectSuppliers = event => {
    let tempValues = [...selectedSuppliers] || [];
    const { checked, name: checkBoxName } = event.target;
    if (checked) {
      tempValues.push(Number(checkBoxName));
    } else {
      tempValues = tempValues.filter(x => x !== Number(checkBoxName));
    }
    setSelectedSuppliers([...tempValues]);
  };

  if (loading) {
    return <ProposalSkeleton />;
  }

  if (error) {
    return <ErrorText message="An error has occurred." />;
  }

  const getFieldValue = field => {
    if (formValues[field.id] && formValues[field.id].value) {
      return formValues[field.id].value;
    }

    if (formValues[field.id] && formValues[field.id].quantity) {
      return formValues[field.id].quantity;
    }

    return null;
  };

  return (
    <PageTabPanel>
      <Grid container>
        {identifier && fields && fields.length > 0 && (
          <Grid item container xs={12} md={6} pl={3} pr={3} pt={2.5} pb={2.5} alignContent="flex-start" className={classes.borderRight}>
            {fields.map(field => {
              return (
                <RFPField
                  key={field.id}
                  identifier={identifier}
                  field={field}
                  formValues={formValues}
                  onChangeValue={handleOnChange}
                  value={getFieldValue(field)}
                />
              );
            })}
          </Grid>
        )}
        <SendRequestColumn
          validDynamicForm={validFormValues}
          validDeliveryForm={validDeliveryForm}
          setSelectedResources={setSelectedResources}
          selectedResources={selectedResources}
          suppliers={suppliers}
          selectedSuppliers={selectedSuppliers}
          // TODO:
          handleOnSelectSuppliers={handleOnSelectSuppliers}
          onSubmit={handleOnSubmitRFP}
          uploadToken={uploadToken}
          setUploadToken={setUploadToken}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          deliveryDate={deliveryDate}
          setDeliveryDate={setDeliveryDate}
          message={message}
          setMessage={setMessage}
          replyToEmail={replyToEmail}
          setReplyToEmail={setReplyToEmail}
          RFPUploadFileUrl={RFPUploadFileUrl}
        />
      </Grid>
    </PageTabPanel>
  );
};
TabContent.propTypes = {
  identifier: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default TabContent;

import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { Box, OutlinedInput } from '@mui/material';
import StatusChip from "../../../components/Project/common/StatusSelector/StatusChip";
import StatusSelector from "../../../components/Project/common/StatusSelector";
import findContrastColor from "../../../components/Project/common/StatusSelector/findContrastColor";

const StatusValueEditor = ({
  disabled,
  enableEdit,
  field,
  hideUnassigned,
  onUpdateTaskValue,
  size = "small",
  value,
}) => {
  const intl = useIntl();

  const [initialStatus, setInitialStatus] = useState();
  const [isEditing, setIsEditing] = useState();

  const editable = enableEdit && !!onUpdateTaskValue;
  const ifEditable = o => (editable ? o : undefined);

  const findStatusByName = (statusLookup, name) => {
    return Array.isArray(statusLookup) && statusLookup.find(n => n.name === name);
  };

  //Finds contrast colors and adds them to field.settings
  //TODO: Can there be problems with directly setting the values ​​in the existing array?
  findContrastColor(field?.settings, 'color', 'textColor', { minContrastRatio: 4.5 });

  const startEditing = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    setIsEditing(true);
  };

  const stopEditing = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    const status = findStatusByName(field?.settings, value?.stringValue);
    setInitialStatus(status);
    stopEditing();
  }, [field, value]);

  if (isEditing) {
    return (
      <StatusSelector
        disabled={disabled}
        fieldId={field?.fieldId}
        onChange={
          ({ fieldId, value }) => {
            onUpdateTaskValue?.(fieldId, value);
          }
        }
        onClose={stopEditing}
        statusLookup={field?.settings}
        sx={{
          marginTop: hideUnassigned && "1px",
        }}
        value={initialStatus?.name && value?.stringValue || null}
      />
    );
  }
  else if (initialStatus) {
    const { color, name, textColor } = initialStatus;
    return (
      <Box
        onClick={
          (e) => {
            if (editable && !disabled) {
              startEditing(e);
            }
          }
        }
        sx={{
          alignItems: "center",
          cursor: !disabled && ifEditable("pointer") || "default",
          display: "flex",
          //minHeight: "35px",
          padding: "2px",
          borderRadius: "4px",
          "&:hover": { backgroundColor: '#f0f0f0' },
        }}
      >
        <StatusChip
          bgColor={color}
          disabled={disabled}
          color={textColor}
          label={name}
          size={size}
          fontSize={"13px"}
        />
      </Box>
    );

  }
  else if (editable) {

    return (
      <OutlinedInput
        disabled={disabled}
        fullWidth
        onClick={startEditing}
        onMouseDown={
          (e) => e.preventDefault()
        }
        placeholder={
          intl.formatMessage({ id: "project.statusSelector.placeholder" })
        }
        size={size}
        sx={{
          "& > fieldset": {
            borderStyle: hideUnassigned && "none",
          },
          "& > input": {
            cursor: !disabled && "pointer",
            height: "auto",
            visibility: hideUnassigned && "hidden",
          },
          //"&:hover > fieldset": {
          //  borderStyle: "solid",
          //},
          "&:hover > input": {
            color: hideUnassigned && "transparent",
            visibility: "visible",
          },
        }}
      />
    );

  }
};

export default StatusValueEditor;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import { FormattedDate } from "../../../../common";

const useStyles = makeStyles(() => ({
  td: {
    fontSize: 12,
    paddingTop: 12,
    paddingBottom: 12,
    "& p": {
      fontSize: 12,
    },
  },
}));

const StockHistoryItems = ({ event }) => {
  const classes = useStyles();

  const {
    change,
    timepoint,
    reason,
    orderId,
    username,
    deliveryAddress1,
    deliveryAddress2,
  } = event;

  return (
    <TableBody>
      <TableRow>
        <TableCell className={classes.td}>
          <Typography>{change}</Typography>
        </TableCell>
        <TableCell className={classes.td}>
          <Typography>
            <FormattedDate value={timepoint} />
          </Typography>
        </TableCell>
        <TableCell className={classes.td}>
          <Typography>{deliveryAddress1}</Typography>
        </TableCell>
        <TableCell className={classes.td}>
          <Typography>{deliveryAddress2}</Typography>
        </TableCell>
        <TableCell className={classes.td}>
          <Typography>{username}</Typography>
        </TableCell>
        <TableCell className={classes.td}>
          <Typography>{orderId}</Typography>
        </TableCell>
        <TableCell className={classes.td}>
          <Typography>{reason}</Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

StockHistoryItems.defaultProps = {
  event: null,
};

StockHistoryItems.propTypes = {
  event: PropTypes.shape({
    change: PropTypes.number,
    timepoint: PropTypes.string,
    reason: PropTypes.string,
    username: PropTypes.string,
  }),
};

export default StockHistoryItems;

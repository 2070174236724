import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CREATE_NEWSPOST } from "../../../graphql/mutations/NewsContext";
import {
  GET_NEWS_FOR_SITE,
  GET_NEWS_FOR_USER,
} from "../../../graphql/queries/NewsContext";
import { getSiteId } from "../../../helpers/selectors";
import { useSnackbar } from "../../../hooks";
import CreateNewsModal from "../../common/CreateNewsModal";
import DashboardWidget from "../../common/DashboardWidget";
import DashboardWidgetBody from "../../common/DashboardWidgetBody";
import DashboardWidgetHead from "../../common/DashboardWidgetHead";
import NewsPost from "../../News/NewsPost";
import NewsPostSkeleton from "../../Skeleton/News/NewsPostSkeleton";
import NewsPageDetailsModal from "../NewsPostBig/NewsPageDetailsModal";
import NewsTypeModal from "../NewsPostBig/NewsTypeModal";
import NewspostModal from "../../News/NewspostModal";

const useStyles = makeStyles(() => ({
  container: {
    overflowX: "hidden",
    overflowY: "auto",
    padding: 10,
    background: "transparent",
  },
  skeleton: {
    marginBottom: 2,
    textAlign: "center",
  },
  title: {
    fontSize: 16,
    color: props => props.titleColor || "#212529",
  },
  newLink: {
    fontSize: 12,
    color: props => props.titleColor || "#212529",
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  divider: {
    border: "1px solid",
    borderColor: props => props.dividerColor || "#212529",
    borderLeft: 0,
    borderRight: 0,
    margin: "0 0 -1px 0",
    paddingTop: 5,
  },
}));

const initialFormValues = {
  allowComments: false,
  featuredImage: "",
  preamble: "",
  title: "",
};

const NewsPostVertical = ({ layout, siteId, projectId }) => {
  const { contentBG, dividerColor, name, newsSiteFontColor, titleColor, newsFilterSetting } =
    layout || {};
  const customStyles = {
    contentBG,
    dividerColor,
    newsSiteFontColor,
    titleColor,
  };
  const classes = useStyles(customStyles);
  const alert = useAlert();
  const snackbar = useSnackbar();
  
  const siteRoleRights = useSelector(
    state => state.ui.toggleWorkspaces.workspace.siteRoleRights
  );
  const { canEditNews } = siteRoleRights || {};
  const [news, setNews] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [createNewsModal, setCreateNewsModal] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [allowComments, setAllowComments] = useState(false);
  const [category, setCategory] = useState("");
  const [uploadedAsset, setUploadedAsset] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [newsTypeModal, setNewsTypeModal] = useState(false);
  const [newsPageDetailsModal, setNewsPageDetailsModal] = useState(false);

  const [createNewsPost] = useMutation(CREATE_NEWSPOST);

  const [selectedNewsPost, setSelectedNewspost] = useState();

  const [getNewsForSite, { data, error, loading }] = useLazyQuery(GET_NEWS_FOR_SITE);
  const [getNewsForUser, { data: companyNewsData, error: companyNewsError, loading: companyNewsLoading }] = useLazyQuery(GET_NEWS_FOR_USER);


  const handleLoadCompleted = news => {
    setNews(news ?? []);
  };

  useEffect(() => {
    if (data?.newsContext?.getNewsForSite) {      
      handleLoadCompleted(data.newsContext.getNewsForSite)
    }
  }, [data, error, loading])

  useEffect(() => {
    if (companyNewsData?.newsContext?.getNewsForUser) {
      handleLoadCompleted(companyNewsData.newsContext.getNewsForUser)
    }
  }, [companyNewsData, companyNewsError, companyNewsLoading])
   


  const fetchNews = () => {    
    
    if (Number.isFinite(siteId)) {

      getNewsForSite({
        variables: {
          offset: page,
          pageSize: perPage,
          siteId,
          projectId: (newsFilterSetting || 0) == 0 ? null : projectId
        }
      }

      );
    } else {
      getNewsForUser({
        variables: {
        offset: page,
        pageSize: perPage,        
        projectId: (newsFilterSetting || 0) == 0 ? null : projectId
        }
      }
      );
    }
  };



  useEffect(() => {
    fetchNews();
  }, [siteId, projectId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setPerPage(parseInt(event.target.value, 10));
  };

  const toggleCreateNewsModal = () => {
    setCreateNewsModal(prev => !prev); 
    
  };

  const handleReadMore = (news) => {
    if (news) {
      setSelectedNewspost(news);      
    }    

  }

  const handleCloseNewsPostDisplay = () => {    
    setNewsPageDetailsModal(false);
    setSelectedNewspost(null);
  }

  const handleSaveNewsPost = async (postBody) => {
    try {
      
      const input = {
        postImage: uploadedAsset ? uploadedAsset.assetId : null,
        allowComments,
        category,
        leadText: formValues.preamble,
        siteId,
        templateId: selectedTemplateId,
        title: formValues.title,
        newsPost: postBody || "",
        projectId: projectId
      };

      const res = await createNewsPost({ variables: { input } });

      if (
        res &&
        res.data &&
        res.data.newsContext &&
        res.data.newsContext.createNewsPost
      ) {
        const { success } = res.data.newsContext.createNewsPost;
        if (success) {
          alert.success(<FormattedMessage id="common.genericSuccess" />);
          fetchNews();
        } else {
          snackbar.workspaceError(
            <FormattedMessage id="common.genericErrorMessage" />
          );
        }

        setCreateNewsModal(false);
        setNewsTypeModal(false);
        setNewsPageDetailsModal(false);        
        
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <DashboardWidget>
      <DashboardWidgetBody customClass={classes.container}>
        <DashboardWidgetHead
          title={
            <Typography className={classes.title} component="span">
              {name || <FormattedMessage id="widget.newsPost" />}
            </Typography>
          }
          rightTitle={
            <>
              {canEditNews && (
                <Button
                  className={classes.newLink}
                  onClick={() => toggleCreateNewsModal()}
                >
                  <FormattedMessage id="widget.addNewsPost" />
                </Button>
              )}
            </>
          }
        />
        <Box>
          {loading && (
            <>
              <NewsPostSkeleton classes={{ root: classes.skeleton }} />
              <NewsPostSkeleton classes={{ root: classes.skeleton }} />
              <NewsPostSkeleton classes={{ root: classes.skeleton }} />
            </>
          )}
          {!(loading || error) &&
            Array.isArray(news) &&
            news.length > 0 &&
            news.map(x => (
              <NewsPost
                className={classes.divider}
                customStyles={customStyles}
                key={x.id}
                news={x}
                onClickReadMore={handleReadMore }
              />
            ))}
        </Box>
      </DashboardWidgetBody>

      {createNewsModal && (
        <CreateNewsModal
          open={createNewsModal}
          formValues={formValues}
          setFormValues={setFormValues}
          category={category}
          setCategory={setCategory}
          handleClose={() => setCreateNewsModal(false)}
          uploadedAsset={uploadedAsset}
          setUploadedAsset={setUploadedAsset}
          setNewsTypeModal={setNewsTypeModal}
          handleSaveNewsPost={handleSaveNewsPost}          
        />
      )}

      {newsTypeModal && (
        <NewsTypeModal
          open={newsTypeModal}
          handleClose={() => setNewsTypeModal(false)}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
          setNewsPageDetailsModal={setNewsPageDetailsModal}
          setCreateNewsModal={setCreateNewsModal}
        />
      )}

      {newsPageDetailsModal && (
        <NewsPageDetailsModal
          open={newsPageDetailsModal}
          handleClose={() => setNewsPageDetailsModal(false)}
          setNewsTypeModal={setNewsTypeModal}
          allowComments={allowComments}
          setAllowComments={setAllowComments}
          handleSaveNewsPost={handleSaveNewsPost}
        />
      )}

      {selectedNewsPost && (
        <NewspostModal newsPost={selectedNewsPost} open={!!(selectedNewsPost)} onClose={handleCloseNewsPostDisplay} />)}
      
    </DashboardWidget>
  );
};

export default NewsPostVertical;

import { withStyles } from "@mui/styles";
import { Paper } from "@mui/material";

const SupplierPaper = withStyles(() => ({
  root: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    display: "block",
    width: "100%",
    minHeight: 500,
  },
}))(Paper);

export default SupplierPaper;

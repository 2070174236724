export const settingKeys = {
  companySettings: {
    canEditOwnAccountSettings: "",
    canManageCompany: "",
    canManageDashboard: "",
    canManageRoles: "",
    canManageUsers: "",
    canManageWorkspaces: "",
    userAccountAddressUpdateSetting: "",
    userAccountAddressUpdateRequestEmail: "",
    requestForProposal: "",
  },
};

export const initialRights = {
  canEditOwnAccountSettings: false,
  canManageCompany: false,
  canManageDashboard: false,
  canManageRoles: false,
  canManageUsers: false,
  canManageWorkspaces: "NOT_ALLOWED",
  requestForProposal: false,
  userAccountAddressUpdateSetting: "DIRECT_UPDATE",
  userAccountAddressUpdateRequestEmail: "",
};

export const tempInitialKeys = {
  canEditOwnAccountSettings: true,
  canManageCompany: true,
  canManageDashboard: true,
  canManageRoles: true,
  canManageUsers: true,
  canManageWorkspaces: true,
};

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import FormattedCurrency from "../common/FormattedCurrency";

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: 56,
    marginRight: theme.spacing(1),
  },
  td: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  tdInfo: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 245,
    paddingTop: 5,
    paddingBottom: 5,
  },
  tdImg: {
    width: "20%",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    "& img": {
      maxHeight: 80,
      width: "100%",
      border: "1px solid #ececec",
      objectFit: "cover",
    },
    paddingTop: 10,
    paddingBottom: 5,
  },
  tdAction: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 30,
    paddingTop: 5,
    paddingBottom: 5,
  },
  removeRow: {
    padding: 5,
    color: theme.palette.secondary.dark,
    marginRight: 0,
  },
  name: {
    wordBreak: "break-all",
  },
  subText: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
  },
  subTextRight: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
    display: "inline-block",
    float: "right",
  },
  subTextLeft: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
    display: "inline-block",
    float: "left",
  },

  table: {
    "&:last-of-type": {
      "& td": {
        borderBottom: "none",
      },
    },
  },
}));

const CartItems = ({ item, index, handleRemoveFromCart }) => {
  const classes = useStyles();

  const { name, price, product, quantity, shoppingCartItemId } = item;
  const { articleNo, thumbnailImageUri } = product || {};

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow className={classes.tr}>
          <TableCell className={classes.tdAction}>
            <IconButton
              aria-label="delete"
              className={classes.removeRow}
              edge="end"
            >
              <CloseIcon
                onClick={() => handleRemoveFromCart(shoppingCartItemId, index)}
              />
            </IconButton>
          </TableCell>
          <TableCell className={classes.tdImg}>
            <img
              alt={name}
              src={thumbnailImageUri}
            />
          </TableCell>
          <TableCell className={classes.tdInfo}>
            <Typography className={classes.subText}>
              <FormattedMessage id="cart.articleNo" />:{" "}
              {articleNo}
            </Typography>
            <Typography className={classes.name}>
              {name}
            </Typography>
            <Typography className={classes.subTextLeft}>
              <FormattedMessage id="cart.quantity" />:{" "}
              {quantity}
            </Typography>
            <Typography className={classes.subTextRight}>
              <FormattedMessage id="cart.price" />:{" "}
              <FormattedCurrency value={price} />
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

CartItems.propTypes = {
  handleRemoveFromCart: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    articleNo: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    product: PropTypes.shape({
      thumbnailImageUri: PropTypes.string,
    }),
    quantity: PropTypes.number,
    shoppingCartItemId: PropTypes.string,
  }).isRequired,
};

export default CartItems;

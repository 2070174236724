import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { Loading, TextField } from "../common";

const useStyles = makeStyles(theme => ({
  table: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
    "@media print": {
      tableLayout: "fixed",
      width: "100%",
    },
  },
  td: {
    fontSize: 14,
    color: theme.palette.primary.main,
    borderBottom: "none",
  },
  tableRow: {
    height: 48,
  },
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
}));

const MultiSelectNumber = ({
  field,
  loading,
  propertyValuesState,
  onChange,
  value,
}) => {
  const classes = useStyles();
  const { name, label } = field;
  const intl = useIntl();

  return (
    <Grid container mt={0.5} mb={0.5}>
      <Grid item xs={12} md={10}>
        {loading && <Loading size={20} />}
        {!loading && (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={clsx([classes.label, classes.td])}>
                  {name}
                </TableCell>
                <TableCell
                  className={clsx([classes.label, classes.td])}
                  align="center"
                >
                  {intl.formatMessage({
                    id: label,
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {propertyValuesState.map(x => {
                const fieldValue = value
                  ? value.find(y => y.propertyValueId === x.id)
                  : null;
                const { id: propertyValueId } = x;

                return (
                  <TableRow key={`${x.name}`} className={classes.tableRow}>
                    <TableCell className={classes.td}>{x.name}</TableCell>
                    <TableCell className={classes.td}>
                      <TextField
                        name={propertyValueId}
                        value={
                          fieldValue && fieldValue.value ? fieldValue.value : 0
                        }
                        onChange={onChange}
                        type="number"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

MultiSelectNumber.defaultProps = {
  value: null,
  loading: false,
};

MultiSelectNumber.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      propertyValueId: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  propertyValuesState: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      quantities: PropTypes.shape({
        values: PropTypes.arrayOf(PropTypes.number).isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default MultiSelectNumber;

import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import SaveIcon from '@mui/icons-material/Save';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from "@mui/material/Grid";
import List from '@mui/material/List';
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";

import { getAdminSelectedCompanyId } from "../../../../helpers/adminSelectors";

import { useGetCompanySecuritySettings } from "../../../../hooks/CompanyAdmin/useGetCompanySecuritySettings";
import { useUpdateCompanySecuritySettings } from "../../../../hooks/CompanyAdmin/useUpdateCompanySecuritySettings";

import GenericAdminPageSkeleton from "../../../Skeleton/GenericAdminPageSkeleton";

import MethodListItem from "./MethodListItem";

const useStyles = makeStyles(theme => ({
  actionButtons: {
    justifyContent: "flex-end",
    "& > div": {
      border: "none",
      width: "unset",
      marginTop: "-2px",
      padding: "0px",
      "& button": {
        height: "26px",
      },
    },
    "& :only-child": {
      marginLeft: "auto",
    },
    width: "100%",
    padding: "16px",
  },
  btnDesign: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headSmall: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: "0px",
    color: "inherit",
    backgroundColor: "inherit",
    marginTop: "12px",
  },
  headMarginBottom: {
    marginBottom: "10px",
  },
}));

const CompanySecurity = () => {
  const alert = useAlert();
  const classes = useStyles();

  const _targetCompanyId = useSelector(getAdminSelectedCompanyId);

  const [isDirty, setIsDirty] = useState(false);
  const [selection, setSelection] = useState();
  const [undoSelection, setUndoSelection] = useState();

  const [getSettings, { loading: fetching }] = useGetCompanySecuritySettings();
  const [updateSettings, { loading: updating }] = useUpdateCompanySecuritySettings();

  const waiting = (fetching || updating);

  const createUpdateParams = () => {
    var enabledMFATypes = [];

    for (const [key, value] of Object.entries(selection)) {
      if (value) {
        enabledMFATypes.push(key);
      }
    }

    return {
      companyId: _targetCompanyId,
      enabledMFATypes,
    };
  };

  const handleChange = ({ name, checked }) => {
    setSelection({ ...selection, [name]: checked });
    setIsDirty(true);
  };

  const handleCancelClick = () => {
    setSelection({ ...undoSelection });
    setIsDirty(false);
  };

  const handleUpdateClick = async () => {
    const securitySettings = createUpdateParams();

    await updateSettings({
      securitySettings,
      onError: () => {
        alert.error(<FormattedMessage id="common.genericErrorMessage" />);
      },
      onSuccess: ({ securitySettings }) => {
        alert.success(<FormattedMessage id="common.genericSuccess" />);
        setValidState({ securitySettings });
      },
    });
  };

  const setInitialState = () => {
    setSelection({});
    setUndoSelection({});
    setIsDirty(false);
  };

  const setValidState = ({ securitySettings }) => {
    var tempSelection = {};
    securitySettings.enabledMFATypes.map(n => tempSelection[n] = true);
    setSelection({ ...tempSelection });
    setUndoSelection({ ...tempSelection });
    setIsDirty(false);
  };

  useEffect(() => {
    if (_targetCompanyId) {
      getSettings({
        companyId: _targetCompanyId,
        onError: () => {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
          setInitialState();
        },
        onSuccess: ({ securitySettings }) => {
          setValidState({ securitySettings });
        },
      });
    }
  }, [_targetCompanyId]);

  const TwoFactorAuthentication = () => {
    if (!_targetCompanyId || !selection) {
      return <GenericAdminPageSkeleton />;
    }
    return (
      <>
        <Box className={classes.headMarginBottom} xs={2}>
          <Typography className={classes.headTitle}>Tvåfaktorsautentisering</Typography>
          <Typography className={classes.headSmall}>Välj säkerhetsmetod</Typography>
          <Typography className={classes.headSubTitle}>Tvåfaktorsautentiseringen skyddar användarnas konton genom att begära ytterligare en kod när användaren loggar in.</Typography>
          <Typography className={classes.headSubTitle}>Välj de säkerhetsmetoder som ska gälla för alla användare i organisationen.</Typography>
        </Box>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <Box>
                <FormControl sx={{ width: "100%" }} fullwidth>
                  <FormGroup
                    aria-labelledby="security-Method-radio-buttons-group"
                    defaultValue="App"
                    name="securityMethod-radio-buttons-group"
                  >
                    <List>
                      <MethodListItem
                        checked={selection.AUTHENTICATOR}
                        description="★ Vi rekommenderar att användarna laddar ner en app. Appen skapar en kod som användarna anger vid inloggning."
                        disabled={waiting}
                        label="Autentiseringsapp"
                        name="AUTHENTICATOR"
                        onChange={handleChange}
                      />
                      <MethodListItem
                        checked={selection.SMS}
                        description="Vi skickar en kod till numret som användaren angett."
                        disabled={waiting}
                        label="Textmeddelande (sms)"
                        name="SMS"
                        onChange={handleChange}
                      />
                      <MethodListItem
                        checked={selection.EMAIL}
                        description="Vi skickar en kod till e-postadressen som användaren angett."
                        disabled={waiting}
                        label="E-postmeddelande"
                        name="EMAIL"
                        onChange={handleChange}
                      />
                    </List>
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.actionButtons}>
            <Button
              className={classes.btnDesign}
              disabled={!isDirty || waiting}
              onClick={handleCancelClick}
              size="small"
              variant="text"
            >
              <FormattedMessage id="btn.cancel" />
            </Button>
            <Button
              className={classes.btnDesign}
              disabled={!isDirty || waiting}
              onClick={handleUpdateClick}
              size="small"
              startIcon={
                <SaveIcon />
              }
              variant="outlined"
            >
              <FormattedMessage id="btn.save" />
            </Button>
          </CardActions>
        </Card>
      </>
    );
  };

  return (
    <Grid container spacing={4} >
      <Grid item md={8} xs={12}>
        <TwoFactorAuthentication />
      </Grid>
    </Grid>
  );
};

export default CompanySecurity;

const getChildTreeData = (company, parentCompanyId, networkId) => {
  const { childCompanies, memberId } = company || {};

  let tempTree = {};
  const childIds =
    Array.isArray(childCompanies) && childCompanies.length > 0
      ? childCompanies.map(child => child.memberId)
      : [];
  let childTempTreeData = {};
  const hasChildren =
    childCompanies &&
    Array.isArray(childCompanies) &&
    childCompanies.length > 0;

  if (hasChildren) {
    childCompanies.forEach(x => {
      const childTreeData = getChildTreeData(x, memberId, networkId);

      childTempTreeData = {
        ...childTempTreeData,
        ...childTreeData,
      };
    });
  }

  tempTree = {
    ...tempTree,
    [`c_${memberId}`]: {
      ...company,
      parentCompanyId,
      networkId,
      childCompanyIds: childIds,
    },
    ...childTempTreeData,
  };

  return tempTree;
};

export default getChildTreeData;

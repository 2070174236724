import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useIntl, FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Grid, Icon, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { getSortedDeliverySettings } from "../../../../helpers/get";
import {
  DeliveryAddressHeader,
  InvoiceAddressHeader,
  PostalAddressHeader,
} from "../../../common";
import FieldsForm from "../../../UserDetails/AccountSettingsModal/FieldsForm";
import AddressHeaderTitle from "../../../common/AddressHeaderTitle";
import CustomTextField from "../../../common/TextField";



const useStyles = makeStyles(() => ({
  editIcon: {
    fontSize: 18,
    marginRight: 7,
    color: "#0000ff",
  },
}));

const AddressSection = ({
  accountSettings,
  setAccountSettings, }) => {
  const classes = useStyles();

  const [sortedDeliveryAddress, setSortedDeliveryAddress] = useState([]);
  const [sortedPostalAddress, setSortedPostalAddress] = useState([]);
  const [sortedInvoiceAddress, setSortedInvoiceAddress] = useState([]);
  const [sortedVisitingAddress, setSortedVisitingAddress] = useState([]);

  const {
    addressLabel,
    deliveryAddress,
    invoiceAddress,
    ordererAddress,
    visitingAddress,
  } = accountSettings || {};





  useEffect(() => {
    const {
      deliveryAddressSettings,
      invoiceAddressSettings,
      postalAddressSettings,
      visitingAddressSettings,
    } = accountSettings || {};

    if (accountSettings) {
      const sortedDelivery = getSortedDeliverySettings(deliveryAddressSettings);

      const sortedPostal = getSortedDeliverySettings(postalAddressSettings);

      const sortedInvoice = getSortedDeliverySettings(invoiceAddressSettings);

      const sortedVisiting = getSortedDeliverySettings(visitingAddressSettings);

      setSortedDeliveryAddress(sortedDelivery);
      setSortedPostalAddress(sortedPostal);
      setSortedInvoiceAddress(sortedInvoice);
      setSortedVisitingAddress(sortedVisiting);
    }
  }, [accountSettings]);
  const handleChange = (address, e) => {
    const tempSettings = { ...accountSettings };
    const { name, value } = e.target;
    const newAdressValues = { ...tempSettings[address], [name]: value };
    tempSettings[address] = newAdressValues;
    setAccountSettings(tempSettings);
  };
  const handleAddressLabel = e => {
    const { name, value } = e.target;
    const tempSettings = { ...accountSettings, [name]: value };
    setAccountSettings(tempSettings);
  };

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} alignItems="center" justifyContent="flex-end">
        <Icon className={clsx(["fas fa-info-circle", classes.editIcon])} />
        <Typography>
          <FormattedMessage id="userGroups.emptyFields" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label={<FormattedMessage id="metadata.metaLabel" />}
          name="addressLabel"
          value={addressLabel}
          onChange={handleAddressLabel}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DeliveryAddressHeader />
        <FieldsForm
          addressFields={sortedDeliveryAddress}
          addressForm={deliveryAddress}
          handleChange={handleChange}
          name="deliveryAddress"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <PostalAddressHeader />
        <FieldsForm
          addressFields={sortedPostalAddress}
          addressForm={ordererAddress}
          handleChange={handleChange}
          name="ordererAddress"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InvoiceAddressHeader />
        <FieldsForm
          addressFields={sortedInvoiceAddress}
          addressForm={invoiceAddress}
          handleChange={handleChange}
          name="invoiceAddress"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <AddressHeaderTitle>
          <FormattedMessage id="cart.addressVisiting" />
        </AddressHeaderTitle>
        <FieldsForm
          addressFields={sortedVisitingAddress}
          addressForm={visitingAddress}
          handleChange={handleChange}
          name="visitingAddress"
        />
      </Grid>
    </Grid>
  );
};

AddressSection.proptTypes = {
  formValues: PropTypes.shape({}).isRequired,
  setFormValues: PropTypes.func.isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default AddressSection;

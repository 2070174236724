import { gql } from '@apollo/client';

const CREATE_SITE_SUPPORT_TICKET = gql`
  mutation CreateSiteSupportTicket($supportTicketInput: SupportTicketInput) {
    siteSupportContext {
      createSupportTicket(supportTicket: $supportTicketInput) {
        message
        success
      }
    }
  }
`;

export default CREATE_SITE_SUPPORT_TICKET;

import React from "react";
import { FormattedMessage } from "react-intl";
import AddressHeaderTitle from "../../common/AddressHeaderTitle";

const CardDetailsHeader = () => {
  return (
    <AddressHeaderTitle>
      <FormattedMessage id="admin.payments.cardDetails.title" />
    </AddressHeaderTitle>
  );
};

export default CardDetailsHeader;

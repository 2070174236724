import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import getFolderTreeDataStructure from "../../../helpers/getFolderTreeDataStructure";
import ManageFoldersTree from "./ManageFoldersTree";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 11,
    marginBottom: 5,
    whiteSpace: "nowrap",
  },
  text: {
    fontSize: 10,
    fontWeight: 600,
  },
  treeLabel: {
    paddingRight: 5,
    borderBottom: "1px solid #ddd",
    paddingBottom: 7,
  },
  treeWrap: {
    maxHeight: 500,
    marginTop: 5,
    marginBottom: 15,
  },
}));

const ManageFolders = ({
  disabled,
  folderRights,
  handleFolderRights,
  initialOpenFolders,
  onFolderSelect,
  siteRoleFolders,
}) => {
  const classes = useStyles();
  const [openedNodes, setOpenedNodes] = useState(initialOpenFolders);
  const [folderTree, setFolderTree] = useState({});

  const handleOpenNode = id => {
    const nodeIndex = openedNodes.indexOf(id);
    if (nodeIndex === -1) {
      setOpenedNodes([...openedNodes, id]);
    } else {
      const tempOpenedNodes = openedNodes.filter(x => x !== id);
      setOpenedNodes(tempOpenedNodes);
    }
  };

  useEffect(() => {
    const newFolderTree = getFolderTreeDataStructure({
      folders: siteRoleFolders,
    });
    setFolderTree(newFolderTree);
  }, [siteRoleFolders]);

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <Typography className={classes.text}>
            <FormattedMessage id="siteRoles.folderRights" />
          </Typography>
        </Grid>
        <Grid item xs={3} align="right">
          <Typography className={classes.label}>
            <FormattedMessage id="folder.write" />
            &emsp;
            <FormattedMessage id="folder.read" />
            &ensp;
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <ManageFoldersTree
          disabled={disabled}
          folderRights={folderRights}
          folderTree={folderTree}
          handleCheckboxChange={handleFolderRights}
          handleOpenNode={handleOpenNode}
          initialOpenNodes={initialOpenFolders}
          onFolderSelect={onFolderSelect}
          openedNodes={openedNodes}
          setOpenedNodes={setOpenedNodes}
        />
      </Grid>
    </>
  );
};

ManageFolders.defaultProps = {
  disabled: false,
  folderRights: {},
  initialOpenFolders: [],
  onFolderSelect: () => { },
  siteRoleFolders: [],
};

ManageFolders.propTypes = {
  disabled: PropTypes.bool,
  folderRights: PropTypes.shape({}),
  handleFolderRights: PropTypes.func.isRequired,
  initialOpenFolders: PropTypes.arrayOf(PropTypes.number),
  onFolderSelect: PropTypes.func,
  siteRoleFolders: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ManageFolders;

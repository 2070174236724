import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
  sidebarFoot: {
    padding: "8px 10px",
    borderTop: "1px solid #f0f0f0",
  },
}));

export default function Footer({ children }) {
  const classes = useStyles();

  return (
    <Box className={classes.sidebarFoot} align="right">
      {children}
    </Box>
  );
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

import { gql } from '@apollo/client';

const getDisplayTabView = gql`
  query GetDisplayTabView($resourceId: ID!) {
    productEdit {
      displayTabView(resourceId: $resourceId) {
        highlighted {
          from
          to
        }
        isHighlighted
        linkedFolders {
          resourceAliasId
          folderId
          name
        }
        resourceId
      }
    }
  }
`;

export default getDisplayTabView;

import { gql } from '@apollo/client';

const ResourceMetaDataDTO = gql`
  fragment ResourceMetadata on ResourceMetaDataDTO {
    afterTreatment
    bureau
    bureauContact
    bureauInvoicedPrice
    bureauProjectName
    companyBestallareUserId
    companyBestallareLookupItem {
      companyUserGroup
      firstname
      lastname
      userId
      username
    }
    companyDeliveryDate
    companyEnhet
    companyManager
    companyManagerEmail
    companyOrderdate
    copywriter
    copywriterDescription
    copywriterInvoicedPrice
    copywriterOfferedPrice
    coverColor
    insideColor
    misc
    pageRange
    photograph
    photographDescription
    photographInvoicedPrice
    photographOfferedPrice
    printshop
    printshopContact
    printshopCoverPaper
    printshopCoverPaperWeight
    printshopDeliveryAddress
    printShopFormatHeight
    printshopFormatId
    printShopFormatWidth
    printshopFreight
    printshopInsidePaper
    printshopInsidePaperWeight
    printshopInvoicedPrice
    printshopOfferedPrice
    printshopOffertNo
    printshopWeight
    productResponsibleUserId
    productResponsibleLookupItem {
      companyUserGroup
      firstname
      lastname
      userId
      username
    }
    resourceId
  }
`;

export default ResourceMetaDataDTO;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "../../hooks";
import { FormattedMessage } from "react-intl";
import Skeleton from "@mui/material/Skeleton";
import FormattedCurrency from "../common/FormattedCurrency";
import { getPrice } from "../../graphql/queries/price";

const ProductPrice = ({ product, quantity }) => {
  const snackbar = useSnackbar();

  const [fetchPrice, { data, loading, error }] = useLazyQuery(getPrice, {
    errorPolicy: "none",
    onError: () => {
      snackbar.workspaceError(
        <FormattedMessage id="product.failedToGetPrice" />
      );
    },
  });

  const isValidQuantity = Number.isSafeInteger(quantity) && quantity > 0;

  useEffect(() => {
    if (isValidQuantity) {
      fetchPrice({
        variables: {
          productId: product.id,
          quantity,
        },
      });
    }
  }, [product, quantity]);

  if (loading) {
    return (
      <Skeleton variant="rect" width="100%" height={28} animation="wave" />
    );
  }

  if (error || !isValidQuantity) {
    return "-";
  }

  return <FormattedCurrency value={data?.price?.exclVat} />;
};

ProductPrice.defaultProps = {
  quantity: 0,
};

ProductPrice.propTypes = {
  product: PropTypes.shape({}),
  quantity: PropTypes.number,
};

export default ProductPrice;

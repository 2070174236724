import { gql } from '@apollo/client';
import PickingListAddressFragment from "../fragments/PickingListAddress";

const getPickingListOrders = gql`
  query GetPickingListOrder($model: SearchParametersInput) {
    pickingList {
      pickingListOrders(model: $model) {
        items {
          pickingListOrdersItemId
          companyOrderno
          orderId
          orderDate
          email
          username
          productionSite
          pickedBy
          pickableItemsWeight
          sumInclVat
          comment
          deliveryAddress {
            ...AddressFields
          }
          invoiceAddress {
            ...AddressFields
          }
          ordererAddress {
            ...AddressFields
          }

          sortedAddressFields {
            deliveryAddress {
              label
              value
            }
            ordererAddress {
              label
              value
            }
            invoiceAddress {
              label
              value
            }
          }
          getPickingListUsers
          orderRows {
            amount
            id
            price
            product
            weight
            stockInfo {
              picked
              pickedBy
              stockLocation
            }
          }
          orderStatusChanges {
            statusDate
            statusMessage
          }
          deliveryDateWish
        }

        totalCount
      }
    }
  }

  ${PickingListAddressFragment}
`;

export default getPickingListOrders;

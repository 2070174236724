import { gql } from '@apollo/client';

const getProductView = gql`
  query GetProductView($siteId: Int!) {
    productView(siteId: $siteId) {
      sortingOptions {
        field
        ascending
        default
      }
      siteFolderTreeColors
    }
  }
`;

export default getProductView;

import React from "react";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import TableSortLabel from "@mui/material/TableSortLabel";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  thStatus: {
    fontSize: 10,
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
    textTransform: "uppercase",
    minWidth: 100,
  },
  thActions: {
    minWidth: 225,
  },
}));

/*                case "project":
                case "uploadedby":
                case "date":
                case "status":*/

const OneStreamTableHead = ({ sortField, ascending, onHeaderClick }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th}>
          <TableSortLabel
            active={sortField === "project"}
            direction={ascending ? "asc" : "desc"}
            onClick={() => onHeaderClick("project")}
          >
            <FormattedMessage id="onestream.project" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel
            active={sortField === "uploadedby"}
            direction={ascending ? "asc" : "desc"}
            onClick={() => onHeaderClick("uploadedby")}
          >
            <FormattedMessage id="onestream.uploadedBy" />
          </TableSortLabel>
        </TableCell>

        <TableCell className={classes.th}>
          <TableSortLabel
            active={sortField === "uploadedDate"}
            direction={ascending ? "asc" : "desc"}
            onClick={() => onHeaderClick("uploadedDate")}
          >
            <FormattedMessage id="onestream.uploadedDate" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel
            active={sortField === "date"}
            direction={ascending ? "asc" : "desc"}
            onClick={() => onHeaderClick("date")}
          >
            <FormattedMessage id="onestream.latestStatus" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="view.file" />
        </TableCell>
        <TableCell className={classes.thStatus}>Status</TableCell>
        <TableCell className={classes.thActions} />
      </TableRow>
    </TableHead>
  );
};

export default OneStreamTableHead;

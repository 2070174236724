import { combineReducers } from "redux";
import api from "./api";
import auth from "./auth";
import ui from "./ui";

export default combineReducers({
  api,
  auth,
  ui,
});

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  nameTypography: { fontWeight: 600 },
  propertiesTypography: {
    color: "#808080",
    fontSize: 12,
  },
}));

const NameCellContent = ({ propertyProductData, name }) => {
  const classes = useStyles();

  const renderPropertyData = () => {
    if (
      propertyProductData &&
      Array.isArray(propertyProductData) &&
      propertyProductData.length > 0
    ) {
      return (
        <Box>
          <Typography className={classes.propertiesTypography}>
            {propertyProductData.map(
              (x, xIdx) => `${xIdx === 0 ? "" : " | "}${x.propertyValue}`
            )}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <>
      <Box>
        <Typography className={classes.nameTypography}>{name}</Typography>
      </Box>
      {renderPropertyData()}
    </>
  );
};

NameCellContent.defaultProps = {
  propertyProductData: [],
};

NameCellContent.propTypes = {
  name: PropTypes.string.isRequired,
  propertyProductData: PropTypes.arrayOf(
    PropTypes.shape({
      propertyName: PropTypes.string,
      propertyValue: PropTypes.string,
      propertyValueId: PropTypes.number,
    })
  ),
};

export default NameCellContent;

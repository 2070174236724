import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PublicIcon from "@mui/icons-material/Public";
import {
  Box,
  Chip,
  TextField,
  IconButton,
} from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import RowItem from "./RowItem";
import FaIcon from "./FaIcon";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 18,
    color: "rgba(0,0,0,0.42)",
  },
  actionIcon: {
    fontSize: 18,
    color: "rgba(0,0,0,1)",
  },
  skillWrapper: {
    display: "flex",
    paddingLeft: 12,
    alignItems: "center",
  },
  autocompleteWrapper: {
    flex: 1,
    paddingLeft: 12,
  },
  autocomplete: props => ({
    width: "100%",
    opacity: props.skilltag.opacity,
  }),
  skillTag: {
    width: "100%",
  },
  skillPrivacy: {
    paddingLeft: 10,
    paddingRight: 45,
  },
}));

const UrlsAndTagsColumn = ({
  accountSettings,
  setAccountSettings,
  webLinks,
  skillTags,
  handleOpenPrivacySetting,
  visibility,
  visibilitySettings,
}) => {
  const skillOpacity = {
    skilltag: skillTags.length > 0 ? { opacity: 1 } : { opacity: 0.3 },
  };
  const classes = useStyles(skillOpacity);
  const intl = useIntl();

  const [showInputField, setShowInputField] = useState(null);

  const [url, setUrl] = useState(null);

  const renderIcon = name => {
    if (name === "PUBLIC") {
      return <PublicIcon />;
    }
    return <FaIcon icon="fas fa-lock" />;
  };

  const handleAdd = name => {
    setShowInputField(name);
  };

  const handleCancel = () => {
    setShowInputField(null);
  };

  const handleSave = (actionType, editValue, index = null) => {
    if (actionType === "edit") {
      const tempValues = [...webLinks];
      const newWeblink = tempValues[index];
      newWeblink.url = url || editValue;
      tempValues[index] = newWeblink;
      setAccountSettings({ ...accountSettings, webLinks: tempValues });
      setShowInputField(null);
      setUrl(null);
    }
    if (actionType === "add") {
      const newLink = { url, visibility: "PUBLIC" };
      setAccountSettings({
        ...accountSettings,
        webLinks: [...accountSettings.webLinks, newLink],
      });
      setShowInputField(null);
      setUrl(null);
    }
  };

  const handleChange = e => {
    setUrl(e.target.value);
  };

  const handleSkilltagsChange = (event, value) => {
    setAccountSettings({
      ...accountSettings,
      skillTags: value,
    });
  };

  const plusIcon = "far fa-plus-square";

  return (
    <>
      {Array.isArray(webLinks) && webLinks.length > 0 && (
        <>
          {webLinks.map((weblink, idx) => {
            return (
              <RowItem
                key={weblink.url}
                title={weblink.url}
                subTitle={<FormattedMessage id="settings.website" />}
                leftIcon={<FaIcon icon="fas fa-briefcase" />}
                privacyIcon={renderIcon(weblink.visibility)}
                actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
                handleOpenPrivacySetting={handleOpenPrivacySetting}
                name={idx}
                settingKey="weblinks"
                iconLabel="Website"
                onClickActionIcon="weblinks"
                showInputField={showInputField}
                handleCancel={handleCancel}
                handleSave={handleSave}
                handleChange={handleChange}
                visibility={visibility}
              />
            );
          })}
        </>
      )}

      {/* Comment one for reference incase we might need in the future */}
      <RowItem
        title=""
        subTitle={<FormattedMessage id="settings.website" />}
        leftIcon={
          <FaIcon handleAdd={handleAdd} inputName="weblink" icon={plusIcon} />
        }
        privacyIcon={renderIcon("website6")}
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="weblink"
        settingKey="weblinks"
        onClickActionIcon="weblinks"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        visibility={visibility}
      />
      <Box className={classes.skillWrapper}>
        <Box className={classes.autocompleteWrapper}>
          <Autocomplete
            className={classes.autocomplete}
            multiple
            id="tags-filled"
            options={[]}
            value={skillTags || []}
            freeSolo
            onChange={handleSkilltagsChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                className={classes.skillTag}
                variant="outlined"
                label={intl.formatMessage({ id: "account.skills" })}
                placeholder=""
              />
            )}
          />
        </Box>
        {skillTags.length > 0 && (
          <Box className={classes.skillPrivacy}>
            <IconButton
              onClick={() =>
                handleOpenPrivacySetting({
                  name: "skillTags",
                  visibility: "skillTags",
                })
              }
            >
              {renderIcon(visibilitySettings.skillTags)}
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
};

UrlsAndTagsColumn.defaultProps = {
  webLinks: [],
  skillTags: [],
  visibilitySettings: {},
};

UrlsAndTagsColumn.propTypes = {
  webLinks: PropTypes.instanceOf(Array),
  skillTags: PropTypes.instanceOf(Array),
  handleOpenPrivacySetting: PropTypes.func.isRequired,
  visibility: PropTypes.string.isRequired,
  visibilitySettings: PropTypes.shape({
    company: PropTypes.string,
    mainEducation: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    directMessage: PropTypes.string,
    skillTags: PropTypes.string,
  }),
};

export default UrlsAndTagsColumn;

import { Chip } from "@mui/material";

const StatusChip = ({
  bgColor,
  color,
  fontSize,
  width,
  label,
  ...rest
}) => {

  return (<Chip
    {...rest}
    label={label}
    sx={{
      backgroundColor: bgColor,
      color: color,
      fontSize,
      width,
      "&:hover": {
        backgroundColor: bgColor + "70",
        color: color + "dd",
        opacity: 1,
      },
    }}
  />)

}

export default StatusChip;

StatusChip.defaultProps = {
  fontSize: "13px",
  width: "unset",
  color: "#1e1f21",
};

import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginBottom: 8,
  },
  textfield: {
    fontSize: 14,
  },
  textfieldWrapper: {
    display: "flex",
    marginBottom: 8,
  },
}));

const YoutubeForm = ({
  onWidgetPropertyUpdate,  
  selectedWidget,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const paramValues = {
    BROWSER: "browser",
    SYSTEM: "system",
  };

  const defaultValues = {
    hl: paramValues.BROWSER,
    videoUrl: "",
  };

  const fromLayout = layout => ({
    hl: layout?.hl || defaultValues.hl,
    videoUrl: layout?.videoUrl || defaultValues.videoUrl,
  });

  const { hl, videoUrl } = fromLayout(selectedWidget);

      // https://developers.google.com/youtube/player_parameters#hl

  return (
    <>
      <Box className={classes.textfieldWrapper}>
        <TextField
          className={classes.textfield}
          fullWidth
          label={intl.formatMessage({ id: "common.url" })}
          margin="dense"
          name="videoUrl"
          onChange={e => onWidgetPropertyUpdate("videoUrl", e.target.value)}
          size="small"
          value={videoUrl || ""}
          variant="outlined"
        />
      </Box>
      <Box className={classes.textfieldWrapper}>
        <FormControl
          className={classes.formControl}
          fullWidth
          margin="dense"
          size="small"
          variant="outlined"
        >
          <InputLabel>
            {intl.formatMessage({ id: "widget.youtubeHL" })}
          </InputLabel>
          <Select
            label={intl.formatMessage({ id: "widget.youtubeHL" })}
            name="hl"
            onChange={e => onWidgetPropertyUpdate("hl", e.target.value)}
            value={hl}
          >
            <MenuItem value={paramValues.SYSTEM}>
              {intl.formatMessage({ id: "widget.youtubeHLFromSystem" })}
            </MenuItem>
            <MenuItem value={paramValues.BROWSER}>
              {intl.formatMessage({ id: "widget.youtubeHLFromBrowser" })}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

YoutubeForm.propTypes = {
  onWidgetPropertyUpdate: PropTypes.func.isRequired,
  selectedWidget: PropTypes.shape({
    hl: PropTypes.string,
    isConfigured: PropTypes.bool.isRequired,
    videoUrl: PropTypes.string,
  }).isRequired,
};

export default YoutubeForm;

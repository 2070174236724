import { gql } from '@apollo/client';

const SEARCH_ARTICLES = gql`query searchArticles($companyId:Int!, $searchTerm:String){
	companyAdminContext{
		salesDocuments{
			searchArticles(companyId:$companyId, searchTerm:$searchTerm){
				amount
				articleNo
				description
				price
			}
		}
	}
}`;

export default SEARCH_ARTICLES;

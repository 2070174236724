import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";
import WorkspaceIcon from "../../common/WorkspaceIcon";

const PIMLink = ({ classes }) => {
  const handleClick = () => {
    const newWindow = window.open(
      "https://kommunal.webshop.pgm.nu",
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      onClick={handleClick}
    >
      <Grid className={classes.iconContainer}>
        <WorkspaceIcon icon="fa fa-tshirt" />
      </Grid>
      <Grid>
        <Typography className={classes.name}>
          <FormattedMessage id="workspace.pim.name" />
        </Typography>
        <Typography className={classes.description}>
          <FormattedMessage id="workspace.pim.description" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PIMLink;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const CREATE_GROUP = gql`
  mutation CreateGroup(
    $companyId: Int!
    $groupName: String
    $parentgroupId: Int
  ) {
    companyAdminContext {
      companyGroupSettings {
        createGroup(
          companyId: $companyId
          groupName: $groupName
          parentgroupId: $parentgroupId
        ) {
          errorResult {
            ...ErrorResultFields
          }
          result {
            ...GroupFields
            ...ChildGroupRecursive
          }
          success
        }
      }
    }
  }

  fragment ChildGroupRecursive on CompanyGroup {
    childGroups {
      ...GroupFields
      childGroups {
        ...GroupFields
        childGroups {
          ...GroupFields
        }
      }
    }
  }

  fragment GroupFields on CompanyGroup {
    groupId
    name
    parentGroupId
  }
  ${ErrorResultFragment}
`;

export default CREATE_GROUP;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ViewContainer from "../../../ProductsMainView/ViewContainer";
import ProductRowItem from "./ProductRowItem";
import EmptyList from "../../../ProductsMainView/EmptyList";
import EditProductModal from "../../../ProductsMainView/EditProductModal";
import {
  RESOURCE_LOCKED,
  FOLDER_LOCKED,
  FOLDER_WRITE_ACCESS,
  FOLDER_READ_ACCESS,
} from "../../../../constant/errorCodes";
import { useSnackbar } from "../../../../hooks";

const ProductsListView = ({ products }) => {
  const snackbar = useSnackbar();
  const intl = useIntl();

  const [productEditModal, setProductEditModal] = useState(false);
  const [productEditId, setProductEditId] = useState();

  const handleCloseProductEditModal = () => {
    setProductEditModal(false);
  };

  const handleOpenProductEditModal = id => {
    setProductEditModal(true);
    setProductEditId(id);
  };

  const handleError = (name, errorResult) => {
    const { data: errorData, errorCode } = errorResult;

    const person = errorData[0].value;
    if (errorCode === RESOURCE_LOCKED) {
      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: name, person }
        )
      );
    }

    if (errorCode === FOLDER_LOCKED) {
      const lockedFolder = errorData[1].value;

      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: lockedFolder, person }
        )
      );
    }

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }
    console.error(errorResult.errorMessage);
  };

  return (
    <ViewContainer isSearch>
      <>
        {products && products.items && products.items.length > 0 ? (
          products.items.map((productItem, idx) => (
            <ProductRowItem
              key={idx}
              product={productItem}
              handleOpenProductEditModal={handleOpenProductEditModal}
            />
          ))
        ) : (
          <EmptyList messageId="product.categoryEmpty" />
        )}
      </>
      {productEditModal && (
        <EditProductModal
          handleClose={handleCloseProductEditModal}
          open={productEditModal}
          resourceId={productEditId}
          handleError={handleError}
        />
      )}
    </ViewContainer>
  );
};

ProductsListView.propTypes = {
  products: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            thumbnailImageUri: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            articleNo: PropTypes.string.isRequired,
          })
        ),
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default ProductsListView;

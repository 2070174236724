import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import StockProductsTable from "../../components/StockProducts/StockProductsTable";
import StockProductsSearch from "../../components/StockProducts/StockProductsSearch";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function StockProducts() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <StockProductsSearch />
        </Grid>
        <Grid item xs={12} md={9}>
          <StockProductsTable />
        </Grid>
      </Grid>
    </div>
  );
}

import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import SortableTree from "./SortableTree";

const MenuTree = ({
  onRemoveNode,
  onRenameNode,
  onUpdateSortlyMenu,
  sortlyMenu,
}) => {
  return (
    <Box>
      <DndProvider backend={HTML5Backend}>
        <SortableTree
          onRemoveNode={onRemoveNode}
          onRenameNode={onRenameNode}
          onUpdateSortlyMenu={onUpdateSortlyMenu}
          sortlyMenu={sortlyMenu}
        />
      </DndProvider>
    </Box>
  );
};

MenuTree.propTypes = {
  onRemoveNode: PropTypes.func.isRequired,
  onRenameNode: PropTypes.func.isRequired,
  onUpdateSortlyMenu: PropTypes.func.isRequired,
  sortlyMenu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MenuTree;

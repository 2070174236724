/**
 * An enumeration of the data fields for the columns of the documents table.
 * @enum {string}
 */
export const DATA_FIELD = {
  /** Field 'amountDue'. */
  amountDue: "amountDue",
  /** Field 'unpaidAmount'. */
  unpaidAmount : "unpaidAmount",
  /** Field 'category'. */
  category: "category",
  /** Field 'customerName'. */
  customerName: "customerName",
  /** Field 'customerNo'. */
  customerNo: "customerNo",
  /** Field 'documentDate'. */
  documentDate: "documentDate",
  /** Field 'documentNumber'. */
  documentNumber: "documentNumber",
  /** Field 'documentType'. */
  documentType: "documentType",
  /** Field 'dueDate'. */
  dueDate: "dueDate",
  /** Field 'yourReference'. */
  yourReference: "yourReference",
};

/**
 * An enumeration of the data types for the columns of the documents table.
 * @enum {string}
 */
export const DATA_TYPE = {
  /** Data type 'datetime'. */
  datetime: "DATETIME",
  /** Data type 'numeric'. */
  numeric: "NUMERIC",
  /** Data type 'tags'. */
  tags: "TAGS",
  /** Data type 'text'. */
  text: "TEXT",
  /** Data type 'currency' */
  currency: "CURRENCY"

};

/**
 * Data types and field mappings for the columns of the documents table.
 */
export const DATA_COLUMN_TYPE = {
  /** Column 'amountDue'. */
  amountDue: {
    field: DATA_FIELD.amountDue,
    type: DATA_TYPE.currency,
  },
  unpaidAmount: {
    field: DATA_FIELD.unpaidAmount,
    type: DATA_TYPE.currency,
  },
  /** Column 'category'. */
  category: {
    field: DATA_FIELD.category,
    type: DATA_TYPE.tags,
  },
  /** Column 'customerName'. */
  customerName: {
    field: DATA_FIELD.customerName,
    type: DATA_TYPE.text,
  },
  /** Column 'customerNo'. */
  customerNo: {
    field: DATA_FIELD.customerNo,
    type: DATA_TYPE.numeric,
  },
  /** Column ty 'documentDate'. */
  documentDate: {
    field: DATA_FIELD.documentDate,
    type: DATA_TYPE.datetime,
  },
  /** Column 'documentNumber'. */
  documentNumber: {
    field: DATA_FIELD.documentNumber,
    type: DATA_TYPE.numeric,
  },
  /** Column 'documentType'. */
  documentType: {
    field: DATA_FIELD.documentType,
    type: DATA_TYPE.text,
  },
  /** Column 'dueDate'. */
  dueDate: {
    field: DATA_FIELD.dueDate,
    type: DATA_TYPE.datetime,
  },
  /** Column 'yourReference'. */
  yourReference: {
    field: DATA_FIELD.yourReference,
    type: DATA_TYPE.text,
  },
};

/**
 * An enumeration of the different types of buttons for the sales pages.
 * @enum {string}
 */
export const SALES_BUTTON_TYPE = {
  /** Sales button type 'New sales invoice'. */
  newInvoice: "NEW_INVOICE",
  /** Sales button type 'New recurring invoice'. */
  newRecurringInvoice: "NEW_RECURRING_INVOICE",
  /** Sales button type 'New order'. */
  newOrder: "NEW_ORDER",
  /** Sales button type 'New quote'. */
  newQuote: "NEW_QUOTE",
  /** Sales button type 'New quote requests'. */
  newQuoteRequest: "NEW_QUOTE_REQUEST",
};

/**
 * An enumeration of the different types of sales pages.
 * @enum {string}
 */
export const SALES_PAGE_TYPE = {
  /** Sales page type 'Invoices'. */
  invoices: "INVOICES",
  /** Sales page type 'Orders'. */
  orders: "ORDERS",
  /** Sales page type 'Quotations'. */
  quotations: "QUOTATIONS",
  /** Sales page type 'Quote requests'. */
  quoteRequests: "QUOTE_REQUESTS",
  /** Sales page type 'Supplier Invoices'. */
  supplierInvoices: "SUPPLIER_INVOICES",
};

/**
 * An enumeration of tab types for the 'Invoices' page.
 * @enum {string}
 */
export const INVOICES_TAB_TYPE = {
  /** Invoices page tab type 'Drafts'. */
  drafts: "INVOICES_DRAFTS",
  /** Invoices page tab type 'Unpaid'. */
  unpaid: "INVOICES_UNPAID",
  /** Invoices page tab type 'All'. */
  all: "INVOICES_ALL",
};

/**
 * An enumeration of tab types for the 'Orders' page.
 * @enum {string}
 */
export const ORDERS_TAB_TYPE = {
  /** Orders page tab type 'Drafts'. */
  drafts: "ORDERS_DRAFTS",
  /** Orders page tab type 'In progress'. */
  inProgress: "ORDERS_IN_PROGRESS",
  /** Orders page tab type 'Invoiced'. */
  invoiced: "ORDERS_INVOICED",
};

/**
 * An enumeration of tab types for the 'Quotations' page.
 * @enum {string}
 */
export const QUOTATIONS_TAB_TYPE = {
  /** Quotation page tab type 'Drafts'. */
  drafts: "QUOTATIONS_DRAFTS",
  /** Quotation page tab type 'In progress'. */
  inProgress: "QUOTATIONS_IN_PROGRESS",
  /** Quotation page tab type 'Converted'. */
  converted: "QUOTATIONS_CONVERTED",
};

/**
 * An enumeration of tab types for the 'QuoteRequests' page.
 * @enum {string}
 */
export const QUOTE_REQUESTS_TAB_TYPE = {
  /** Quote requests page tab type 'Drafts'. */
  drafts: "QUOTE_REQUESTS_DRAFTS",
  /** Quote requests page tab type 'In progress'. */
  inProgress: "QUOTE_REQUESTS_IN_PROGRESS",
  /** Quote requests page tab type 'Converted'. */
  converted: "QUOTE_REQUESTS_CONVERTED",
};

/**
 * An enumeration of tab types for the 'Supplier Invoices' page.
 * @enum {string}
 */
export const SUPPLIER_INVOICES_TAB_TYPE = {
  /** Invoices page tab type 'Unpaid'. */
  unpaid: "SUPPLIER_INVOICES_UNPAID",
  /** Invoices page tab type 'All'. */
  all: "SUPPLIER_INVOICES_ALL",
  /** Invoices page tab type 'Paid' */
  completed: "SUPPLIER_INVOICES_COMPLETED"

};

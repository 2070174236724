import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Button, Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  btnPrint: {
    padding: "3px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
  },
  btnReply: {
    padding: "3px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },

  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  heading: {
    fontSize: 18,
    fontWeight: 600,
    display: "inline-block",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginRight: 0,
    },
  },
}));

const SubHeader = ({ handlePrint, handleOpenReply }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography className={classes.heading}>
          <FormattedMessage id="context.pickingList" />
        </Typography>
        <Button className={classes.btnReply} onClick={handleOpenReply}>
          <Icon className={clsx(["fa fa-envelope", classes.btnIcon])} />
          <FormattedMessage id="btn.reply" />
        </Button>
        <Button className={classes.btnPrint} onClick={() => handlePrint()}>
          <Icon className={clsx(["fa fa-print", classes.btnIcon])} />
          <FormattedMessage id="btn.print" />
        </Button>
      </Grid>
    </Grid>
  );
};

SubHeader.propTypes = {
  handleOpenReply: PropTypes.func.isRequired,
  handlePrint: PropTypes.func.isRequired,
};

export default SubHeader;

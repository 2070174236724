import React, { lazy, Suspense, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const IconPicker = lazy(() => import("../../../common/ReactIconPicker"));

const useStyles = makeStyles(theme => ({
  iconSelect: {
    marginLeft: "1em",
  },
  iconWrapper: {
    alignItems: "center",
    display: "flex",
  },
  textfield: {
    fontSize: 14,
  },
  textfieldWrapper: {
    display: "flex",
    marginBottom: 8,
  },
}));

const LinkWidgetForm = ({
  defaultIcon,
  onWidgetPropertyUpdate,
  selectedWidget,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { isConfigured, linkDesc, linkIcon, linkUrl } = selectedWidget;

  useEffect(() => {
    if (!isConfigured && defaultIcon) {
      onWidgetPropertyUpdate("linkIcon", defaultIcon);
    }
  }, [defaultIcon, isConfigured]);

  return (
    <>
      <Box className={classes.textfieldWrapper}>
        <TextField
          className={classes.textfield}
          fullWidth
          label={intl.formatMessage({ id: "common.description" })}
          margin="dense"
          name="linkDesc"
          onChange={e => onWidgetPropertyUpdate("linkDesc", e.target.value)}
          size="small"
          value={linkDesc || ""}
          variant="outlined"
        />
      </Box>
      <Box className={classes.iconWrapper}>
        <Box>{intl.formatMessage({ id: "common.icon" })}:</Box>
        <Box className={classes.iconSelect}>        
          <Suspense fallback={<>Loading icons...</>}>
            <IconPicker
              onChange={icon => onWidgetPropertyUpdate("linkIcon", icon)}
              value={linkIcon || defaultIcon}
            />
          </Suspense>
        </Box>
      </Box>
      
      
      <Box className={classes.textfieldWrapper}>
        <TextField
          className={classes.textfield}
          fullWidth
          label={intl.formatMessage({ id: "common.url" })}
          margin="dense"
          name="linkUrl"
          onChange={e => onWidgetPropertyUpdate("linkUrl", e.target.value)}
          size="small"
          value={linkUrl || ""}
          variant="outlined"
        />
      </Box>
    </>
  );
};

LinkWidgetForm.defaultProps = {
  defaultIcon: "",
};

LinkWidgetForm.propTypes = {
  defaultIcon: PropTypes.string,
  onWidgetPropertyUpdate: PropTypes.func.isRequired,
  selectedWidget: PropTypes.shape({
    isConfigured: PropTypes.bool.isRequired,
    linkDesc: PropTypes.string,
    linkIcon: PropTypes.string,
    linkUrl: PropTypes.string,
  }).isRequired,
};

export default LinkWidgetForm;

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import TemplateBlank from "../../../assets/img/template-blank.svg";

const useStyles = makeStyles(theme => ({
  templateBox: {
    padding: "10px 10px 5px 10px",
    border: "1px solid transparent",
    borderRadius: 4,
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    maxWidth: 250,
    "&:hover": {
      cursor: "pointer",
      borderColor: theme.palette.common.blue,
    },
  },
  active: {
    border: `1px solid ${theme.palette.common.blue}`,
  },
}));

export const TemplatesListItem = ({ src, title, onClick, id, active }) => {
  const classes = useStyles();

  const handleOnClick = () => {
    onClick(id);
  };

  return (
    <Box
      className={clsx([classes.templateBox, active ? classes.active : null])}
      onClick={handleOnClick}
    >
      <img
        src={src || TemplateBlank}
        alt={title}
        style={{ maxWidth: 134, maxHeight: 81 }}
      />
      {title}
    </Box>
  );
};

TemplatesListItem.defaultProps = {
  id: null,
  src: null,
};

TemplatesListItem.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  active: PropTypes.bool.isRequired,
};

export default TemplatesListItem;

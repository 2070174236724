import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import ReviewImage from "../../assets/img/review-icon.svg";
import { ClearButton, PrimaryButton } from "./Buttons";
import DraggableModal from "./DraggableModal";
import DraggableModalHead from "./DraggableModal/DraggableModalHead";
import DraggableModalBody from "./DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
    textAlign: "center",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
    width: 90,
  },
}));

const ConfirmationModalRemoveSettings = ({
  open,
  setConfirmationModal,
  folderIdsUnchecked,
  setPropertyFormValues,
  setCheckedFolders,
  propertyFormValues,
  checkedFolders,
  folderProductionProperties,
  setFolderProductionProperties,
  handleConfirmYesButton,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setConfirmationModal(false);
  };

  const handleNoButton = () => {
    handleClose();
  };

  const handleYesButton = () => {
    if (handleConfirmYesButton) {
      handleConfirmYesButton();
      return;
    }

    const newFormValues = [...propertyFormValues];
    const newFolderFormValues = [...folderProductionProperties];
    folderIdsUnchecked.forEach(x => {
      const fileIndex = newFormValues.findIndex(y => x === y.folderId);
      if (fileIndex !== -1) {
        newFormValues.splice(fileIndex, 1);
      }

      const folderIndex = newFolderFormValues.findIndex(z => z.folderId === x);
      if (folderIndex !== -1) {
        newFolderFormValues.splice(folderIndex, 1);
      }
    });
    const temp = checkedFolders.filter(x => !folderIdsUnchecked.includes(x));

    setCheckedFolders([...temp]);
    setPropertyFormValues([...newFormValues]);
    setFolderProductionProperties([...newFolderFormValues]);
    handleClose();
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        <img src={ReviewImage} alt="Review" className={classes.img} />
        <FormattedMessage id="monitoring.confirmRemoveSettings" />
        <Box className={classes.modalFooter} align="right">
          <ClearButton onClick={handleNoButton} marginRight={15}>
            <FormattedMessage id="btn.no" />
          </ClearButton>
          <PrimaryButton onClick={handleYesButton}>
            <FormattedMessage id="btn.yes" />
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmationModalRemoveSettings.defaultProps = {
  folderIdsUnchecked: [],
  handleConfirmYesButton: null,
};

ConfirmationModalRemoveSettings.propTypes = {
  folderIdsUnchecked: PropTypes.arrayOf(PropTypes.number),
  setPropertyFormValues: PropTypes.func.isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleConfirmYesButton: PropTypes.func,
};

export default ConfirmationModalRemoveSettings;

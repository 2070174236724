import React, { memo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  deleteLink: {
    fontSize: 12,
    color: theme.palette.common.red,
    float: "right",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.common.red,
      textDecoration: "none",
      opacity: 0.8,
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      marginBottom: 10,
    },
  },
  deleteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
  },
}));

const EmptyCartButton = ({ handleConfirmationModalOpen }) => {
  const classes = useStyles();

  return (
    <Box
      onClick={() => handleConfirmationModalOpen()}
      className={classes.deleteLink}
    >
      <Icon className={clsx(["fas fa-trash", classes.deleteIcon])} />
      <FormattedMessage id="cart.empty" />
    </Box>
  );
};

EmptyCartButton.propTypes = {
  handleConfirmationModalOpen: PropTypes.func.isRequired,
};

function areEqual(prevProps, nextProps) {
  // Always true for now since the props handleRemoveAllProducts is just the same function.
  return true;
}

export default memo(EmptyCartButton, areEqual);

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Icon,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import clsx from "clsx";
import LogsTable from "./LogsTable";
import LogsFilter from "./LogsFilter";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  actionButtons: {
    justifyContent: "flex-end",
    "& > div": {
      border: "none",
      width: "unset",
      marginTop: "-2px",
      padding: "0px",
      "& button": {
        height: "26px",
      },
    },
    "& :only-child": {
      marginLeft: "auto",
    },
    width: "100%",
    padding: "16px",
  },
  btnDesign: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headMarginBottom: {
    marginBottom: "10px",
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #F2F2F2",
    marginBottom: 15,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  textItalic: {
    fontSize: 12,
    fontStyle: "italic",
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  greenIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  selectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    "& .MuiFormControl-root": {
      width: "60%",
    },
  },
  logo: {
    background: theme.palette.common.white,
    borderColor: theme.palette.common.lightGrey,
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 1,
    marginBottom: 10,
    maxWidth: 160,
    padding: 3,
    "&:hover": {
      animation: `$bgColorChange 1s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  logoCard: {
    background: theme.palette.secondary.main,
    height: 140,
    padding: "10px",
    "&:hover": {
      animation: `$bgColorChange 1.2s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  logoMail: {
    background: theme.palette.common.white,
    height: 140,
    padding: "10px",
    "&:hover": {
      animation: `$bgColorChangeMail 1.2s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  selectCard: {
    padding: "10px",
  },
  exportLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  exportIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#F5F5F5",
    height: 2,
  },
  "@keyframes bgColorChange": {
    "0%": {
      backgroundColor: theme.palette.secondary.main,
    },
    "50%": {
      backgroundColor: theme.palette.common.black,
    },
    "100%": {
      backgroundColor: theme.palette.common.black,
    },
  },
  "@keyframes bgColorChangeMail": {
    "0%": {
      backgroundColor: theme.palette.common.white,
    },
    "50%": {
      backgroundColor: theme.palette.common.black,
    },
    "100%": {
      backgroundColor: theme.palette.common.black,
    },
  },
}));


const Logs = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.headMarginBottom}>
        <Typography className={classes.headTitle}>Loggar</Typography>
        <Typography className={classes.headSubTitle}>Här kan du söka bland händelser som inträffat i systemet.</Typography>
      </Box>
      <Card>
        <CardContent className={classes.selectCard}>
          <Box sx={{ minWidth: 120, paddingTop: "25px" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.heading}>
                  Search for events
                </Typography>
                <LogsFilter />
                <Divider className={classes.divider} />
                <LogsTable />
                <Box align="right" mt={1}>
                  <Button className={classes.exportLink}>
                    <Icon
                      className={clsx(["fas fa-file-excel", classes.exportIcon])}
                    />
                    Export to Excel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CardActions className={classes.actionButtons}>
          <Button size="small" variant="outlined" className={classes.btnDesign} startIcon={<SaveIcon />}>Spara</Button>
        </CardActions>
      </Card>
    </>

  );
};

export default Logs;

import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_SITE_USERS_FOR_SITE } from "../graphql/queries";
import {
  setSiteUsers,
  setSiteUsersError,
  setSiteUsersLoading,
} from "../actions";

// eslint-disable-next-line import/prefer-default-export
export const useGetSiteUsers = ({ siteId, filter, ascending, sortfield }) => {
  const [getSiteUsersForSite, { data, loading, error }] = useLazyQuery(
    GET_SITE_USERS_FOR_SITE,
    {
      variables: {
        siteId,
        filter,
        ascending,
        sortfield,
      },
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      data &&
      data.siteUsers &&
      Array.isArray(data.siteUsers.listSiteUsersForSite)
    ) {
      dispatch(
        setSiteUsers({
          items: data.siteUsers.listSiteUsersForSite,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setSiteUsersLoading({
        loading,
      })
    );
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(
        setSiteUsersError({
          error,
        })
      );
    }
  }, [error]);

  return { execute: getSiteUsersForSite, data, loading, error };
};

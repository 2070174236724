import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  Icon,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import CustomSelect from "../../common/CustomSelect";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import CustomTextField from "../../common/TextField";
import ShippingRatesModal from "./ShippingRatesModal";

const useStyles = makeStyles(theme => ({
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #e6e6e6",
    marginBottom: 10,
    "& .MuiInputBase-root": {
      marginTop: 5,
    },
    "& .MuiTextField-root": {
      marginTop: 0,
    },
    "& .MuiCheckbox-root": {
      padding: "4px 9px",
    },
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  labelWrap: {
    display: "flex",
    alignItems: "center",
    "& .MuiTextField-root": {
      width: "auto",
      marginLeft: 15,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  subLabel: {
    fontSize: 12,
    marginLeft: "auto",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  grayLink: {
    fontSize: 12,
    color: "#555",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  blueLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  child: {
    paddingLeft: 27,
  },
}));

const OtherSettings = () => {
  const classes = useStyles();

  const [openShippingRates, setOpenShippingRates] = useState(false);
  const handleOpenShippingRates = () => {
    setOpenShippingRates(true);
  };
  const handleCloseShippingRates = () => {
    setOpenShippingRates(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.card}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.heading}>
                  Other settings
                </Typography>
              </Grid>
            </Grid>
            <Box mt={2} mb={1} />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <Typography className={classes.label}>Varukorgstyp</Typography>
                <CustomSelect options={[{ label: "Default", value: 1 }]} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className={classes.labelWrap}>
                  <Typography className={classes.label}>Start cost</Typography>
                  <Typography className={classes.subLabel}>gr</Typography>
                </Box>
                <CustomTextField />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  className={classes.labelWrap}
                  mt={2}
                  justifyContent="center"
                >
                  <Typography className={classes.label}>
                    Shipping cost
                  </Typography>
                  <Button
                    className={classes.greenLink}
                    onClick={handleOpenShippingRates}
                  >
                    <Icon className={clsx(["fas fa-tags", classes.icon])} />
                    Shipping price list
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Show cost center " />
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="View additional cost center (Costplace)" />
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Show additional cost center 2 (Cost center)" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Terms of purchase (The terms of text are edited in the text settings)" />
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Show delivery date" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box className={classes.labelWrap}>
                  <Typography className={classes.label}>
                    Normal delivery time (days)
                  </Typography>
                  <CustomTextField />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="View message" />
                <Box className={classes.child}>
                  <Typography className={classes.label}>
                    A copy of order confirmation will be sent to (Optional)
                  </Typography>
                  <CustomTextField />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Send order confirmation" />
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Always send to the customer's contact email" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box className={classes.labelWrap}>
                  <CheckboxDefault label="Show box email box for order confirmation email." />
                  <Tooltip
                    title="If a confirmation email is to be sent and the box is hidden, the email address is retrieved from the delivery address instead, or ultimately from the user's contact email."
                    placement="top"
                  >
                    <Button className={classes.blueLink}>
                      <Icon
                        className={clsx([
                          "fas fa-question-circle",
                          classes.icon,
                        ])}
                      />
                    </Button>
                  </Tooltip>
                </Box>
                <Box className={classes.child}>
                  <Typography className={classes.label}>
                    A copy of order confirmation will be sent to (required)
                  </Typography>
                  <CustomTextField />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Send a confirmation email" />
                <Box className={classes.child}>
                  <Typography className={classes.label}>
                    Reception email for certification
                  </Typography>
                  <CustomTextField />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Send a confirmation email to the person who is the recipient of the email on the invoice" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Instead of attaching files to order mail, send a link for download." />
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="View the column of clients. If the column is hidden, the information is retrieved from the delivery address" />
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Save order address when orders are placed / sent for certification" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="Static ordering address" />
              </Grid>
              <Grid item xs={12} md={4}>
                <CheckboxDefault label="	Allow uploading of attachments in the shopping cart" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <ShippingRatesModal
        open={openShippingRates}
        handleClose={handleCloseShippingRates}
      />
    </>
  );
};

export default OtherSettings;

import React from "react";
import { useIntl } from "react-intl";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DraggableModal from "../common/DraggableModal";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import ManageDashboards from "./ManageDashboards.jsx";

const useStyles = makeStyles(() => ({
  modal: {
    width: 992,
  },
}));

const ManageDashboardsModal = ({
  onAdd,
  onClose,
  onDelete,
  onEdit,
  open,
  rows,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <DraggableModal
      customClass={classes.modal}
      handleClose={onClose}
      open={open}
    >
      <DraggableModalHead
        handleClose={onClose}
        title={intl.formatMessage({ id: "widget.manageDashboards" })}
      />
      <DraggableModalBody customClass={classes.body}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ManageDashboards
              onAdd={onAdd}
              onDelete={onDelete}
              onEdit={onEdit}
              rows={rows}
            />
          </Grid>
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default ManageDashboardsModal;

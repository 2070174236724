import { Paper, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TextField } from "@mui/material"
import { useEffect } from "react";
const StatusFieldEdit = ({ projectField, onFieldChange }) => {

  //const [statusSettings, setStatusSettings] = useState();

  useEffect(() => {

  }, [projectField])

  

  return (<Grid container>
    <Grid item sx={4}>Name: <TextField value={projectField.name} onChange={e => onFieldChange("name", e.target.value)} /></Grid>
    <Grid item sx={4}>Typ:  {projectField.type}</Grid>
    <Grid item sx={4}>
      <Table>
        <TableHead>
          <TableRow><TableCell>Färg</TableCell><TableCell>Namn</TableCell><TableCell>Action</TableCell></TableRow>
        </TableHead>
        <TableBody>
          {projectField && projectField.settings && projectField.settings.length > 0 && projectField.settings.map(n => (
          <TableRow>
            <TableCell>{n.color}</TableCell>
            <TableCell>{n.name}</TableCell>
          </TableRow>
        ))}       

        </TableBody>
      </Table>

    </Grid>
  </Grid> )
}

export default StatusFieldEdit;
import React, { memo } from "react";
import PropTypes from "prop-types";
import { TreeItem } from "@mui/lab";
import FolderLabel from "./FolderLabel";

const RowData = ({
  children,
  disabled,
  folder,
  folderRights,
  handleCheckboxChange,
}) => {
  const { name, folderId } = folder || {};

  if (!folder) return null;

  const renderFolderLabel = () => {
    return (
      <FolderLabel
        disabled={disabled}
        folder={folder}
        folderId={folderId}
        folderName={name}
        folderRights={folderRights}
        handleCheckboxChange={handleCheckboxChange}
      />
    );
  };

  return (
    <TreeItem nodeId={folder.folderId} label={renderFolderLabel()}>
      {children}
    </TreeItem>
  );
};

RowData.defaultProps = {
  children: null,
  disabled: false,
  folderRights: {},
  selectedNode: null,
};

RowData.propTypes = {
  selectedNode: PropTypes.shape({}),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    folderId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  folderRights: PropTypes.shape({}),
};

export default memo(RowData);

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Submenu } from "react-contexify";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import ContextMenuItem from "./ContextMenuItem";

const useStyles = makeStyles(theme => ({
  contextSubMenu: {
    fontSize: 11,
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "&:hover": {
      "& > .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__submenu-arrow": {
      fontSize: 8,
    },
  },
  contextSubMenuDisabled: {
    pointerEvents: "none",
    "& .react-contexify__item__content": {
      opacity: 0.4,
      backgroundColor: "#7b505d",
    },
  },
}));

const RenderTenderFolders = ({ tenderFolders, locked, handleDisabled }) => {
  const classes = useStyles();
  const disabled = !(!locked && tenderFolders);
  return (
    <Submenu
      // Add classes.contextSubMenuDisabled if submenu is disabled
      className={clsx([classes.contextSubMenu])}
      label={<FormattedMessage id="context.create" />}
      disabled={disabled}
    >
      {/* WI 5636 Hide context menu item "Create / Tender documents" */}
      {/* <Submenu
        // Add classes.contextSubMenuDisabled if submenu is disabled
        className={clsx([classes.contextSubMenu])}
        disabled={disabled}
        label={<FormattedMessage id="context.specifications" />}
      >
        <ContextMenuItem disabled={disabled}>
          <FormattedMessage id="context.new" />
        </ContextMenuItem>
        <ContextMenuItem disabled={disabled}>
          <FormattedMessage id="context.beginCompletion" />
        </ContextMenuItem>
        <ContextMenuItem disabled={disabled}>
          <FormattedMessage id="context.downloadLink" />
        </ContextMenuItem>
      </Submenu> */}
      <ContextMenuItem disabled={disabled}>
        <FormattedMessage id="context.siteTemplate" />
      </ContextMenuItem>
    </Submenu>
  );
};

RenderTenderFolders.defaultProps = {
  locked: false,
};

RenderTenderFolders.propTypes = {
  tenderFolders: PropTypes.bool.isRequired,
  locked: PropTypes.bool,
};

export default RenderTenderFolders;

import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { createTheme } from '@mui/material/styles';
import { GET_THEME } from "../graphql/queries";
import { client } from "../graphql/client";

const CUSTOM_THEME = () => {
  const [theme, setTheme] = useState({
    typography: {
      fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      body1: {
        fontSize: 14,
      },
      h6: {
        fontSize: 18,
        fontWeight: 400,
        textTransform: "none",
      },
      subtitle1: {
        fontSize: 12,
      },
    },
    palette: {
      common: {
        black: "#000",
        white: "#fff",
        grey: "#DEDEDE",
        lightGrey: "#D9D9D9",
        neon: "#E76678",
        cerise: "#F6689B",
        blue: "#26aaf7",
        pink: "#FAD1D6",
        green: "#40ca0a",
        link: "#26aaf7",
        lightBlue: "#D6F2EF",
        gold: "#754f0b",
        red: "#CE122D",
      },
      background: {
        paper: "#fff",
        default: "#fafafa",
        cream: "#f4f3ed",
        gray: "#ddd",
        lightGray: "#f9f9f9",
        contrastGray: "#f1f1f1",
        orange: "#f07d17",
        contrastOrange: "#eba05d",
      },
      primary: {
        light: "#aace6f",
        main: "#8ebe3f",
        dark: "#000000",
        contrastText: "#ffffff",
        alt: "#aace6f",
      },
      secondary: {
        light: "#9DB910",
        main: "#787D7E",
        dark: "#787D7E",
        contrastText: "#000000",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
        gray: "#444",
        lightGray: "#9a9a9a",
        pink: "rgba(102, 35, 54, 0.54)",
        lightPink: "rgba(102, 35, 54, 0.14)",
      },
      component: {
        hamburger: "#2d373c",
        pageBackground: "#f5f5f5",
        headerBackground: "#fff",
        headerColor: "#2d373c",
        navbarBackground: "#fff",
        navbarColor: "#2d373c",
        navbarChildColor: "#ffffff",
        navbarSeparator: "#f1f1f1",
        badgeBackground: "#8ebe3f",
        badgeColor: "#ffffff",
        languageBackground: "#a7a7a7",
        productBoxColor: "#2d373c",
        tabBackground: "#aace6f",
        tabColor: "#4b4b4d",
        tabWrap: "#e3efcf",
        customPanelColor: "#4b4b4d",
        customPanelBackground: "#d2d2d2",
        fileUploadBackground: "#efefef",
        imageCardHover: "#e3efcf",
        homeMainColor: "#8ebe3f",
        viewSwitchBackground: "#e3efcf",
        detailedSearchBox: "#fff",
        detailedSearchBorder: "#f5f5f5",
        detailedSearchSwitch: "#8ebe3f",
        selectedFolder: "#e2e2e2",
      },
    },
  });

  const [getTheme, { data }] = useLazyQuery(GET_THEME, { client });

  useEffect(() => {
    getTheme();
  }, []);

  useEffect(() => {
    if (data && data.themes && data.themes.customTheme) {
      const { themeJson } = data.themes.customTheme;
      const jsonTheme = JSON.parse(themeJson);
      setTheme(jsonTheme);
    }
  }, [data]);

  const newTheme = createTheme(theme);

  return newTheme;
};

export default CUSTOM_THEME;

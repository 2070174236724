import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const MAKE_COMPANY_SUPPLIER_ALIAS = gql`
  mutation MakeCompanySupplierAlias($supplierAliasEmail: String) {
    makeCompanySupplierAliasRequest(supplierAliasEmail: $supplierAliasEmail) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }

  ${ErrorResultFragment}
`;

export default MAKE_COMPANY_SUPPLIER_ALIAS;

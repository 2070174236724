import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Checkbox from "../common/FormControl/Checkbox";
import { FormattedCurrency, FormattedDate } from "../common";
import OrderModal from "./OrderModal";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 12,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 12,
    paddingBottom: 12,
    "& p": {
      fontSize: 12,
    },
    wordBreak: "break-all",
    hyphens: "auto",
    "@media print": {
      visibility: "hidden",
      display: "none",
      height: "100%",
      width: "100%",
    },
  },
  tdCheckbox: {
    padding: "10px 0px 10px 10px",
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 12,
    minWidth: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const OrderTableItem = ({
  order,
  detailSettings,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const {
    costPlaceOrder,
    deliveryAddress,
    deliveryDateWish,
    orderDate,
    orderId,
    supplierOrderId,
    supplierOrderNo,
    companyOrderno,
    status,
    sumInclVat,
    username,
  } = order;
  const { address1, address2, address3, address4, address5, city, reference } =
    deliveryAddress || {};

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCheckboxChange = e => {
    const { checked } = e.target;
    if (checked) {
      addSelectedOrder(supplierOrderId);
    } else {
      removeSelectedOrder(supplierOrderId);
    }
  };

  const getCheckboxValue = () => {
    if (
      order &&
      order.supplierOrderId &&
      Array.isArray(selectedOrders) &&
      selectedOrders.find(x => x === order.supplierOrderId)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <TableRow key={`order-table-${orderId}`}>
        <TableCell className={classes.td}>
          <Checkbox
            value={getCheckboxValue()}
            onChange={handleCheckboxChange}
          />
          <Button onClick={handleOpenModal} className={classes.link}>
            {supplierOrderNo}
          </Button>
        </TableCell>
        <TableCell>{companyOrderno || orderId}</TableCell>
        <TableCell className={classes.td}>
          <FormattedDate value={orderDate} />
        </TableCell>
        <TableCell className={classes.td}>{username}</TableCell>
        <TableCell className={classes.td}>{address1}</TableCell>
        <TableCell className={classes.td}>{address2}</TableCell>
        <TableCell className={classes.td}>{reference}</TableCell>
        <TableCell className={classes.td}>{city}</TableCell>
        <TableCell className={classes.td}>
          <FormattedCurrency value={sumInclVat} />
        </TableCell>
        <TableCell className={classes.td}>
          <FormattedDate value={deliveryDateWish} />
        </TableCell>
        <TableCell className={classes.td}>{costPlaceOrder}</TableCell>
        <TableCell className={classes.td}>{status}</TableCell>
      </TableRow>
      {openModal && (
        <OrderModal
          open={openModal}
          handleClose={handleCloseModal}
          supplierOrderId={supplierOrderId}
          detailSettings={detailSettings}
        />
      )}
    </>
  );
};

OrderTableItem.defaultProps = {
  orderGroupId: null,
  detailSettings: null,
};

OrderTableItem.propTypes = {
  orderGroupId: PropTypes.number,
  order: PropTypes.shape({
    companyOrderno: PropTypes.string,
    costPlaceOrder: PropTypes.string,
    deliveryAddress: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      reference: PropTypes.string,
      city: PropTypes.string,
    }).isRequired,
    deliveryDateWish: PropTypes.string,
    orderDate: PropTypes.string,
    orderId: PropTypes.number.isRequired,
    status: PropTypes.string,
    sumInclVat: PropTypes.number,
    username: PropTypes.string,
  }).isRequired,
  detailSettings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
  selectedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
};

export default OrderTableItem;

import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import Box from "@mui/material/Box";
import WorkspaceIconErrorBoundary from "./WorkspaceIconErrorBoundary";

const IconPicker = lazy(() => import("../../../../common/ReactIconPicker"));

const WorkspaceIconPicker = ({
  buttonIconStyles,
  buttonStyles,
  containerStyles,
  hideSearch,
  onChange,
  onError,
  pickerIconStyles,
  searchInputStyles,
  value,
}) => {
  const IconPickerSkeleton = () => (
    <Box
      sx={{
        position: "relative",
        padding: "5px",
        width: "50px",
        minHeight: "40px",
        borderRadius: "4px",
        borderWidth: "1px",
        borderColor: "#000",
        borderStyle: "solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...buttonStyles
      }}
    />
  );

  return (
    <WorkspaceIconErrorBoundary onError={onError}>
      <Suspense fallback={<IconPickerSkeleton />}>
        <IconPicker
          buttonIconStyles={buttonIconStyles}
          buttonStyles={buttonStyles}
          containerStyles={containerStyles}
          hideSearch={hideSearch}
          onChange={onChange}
          pickerIconStyles={pickerIconStyles}
          searchInputStyles={searchInputStyles}
          value={value}
        />
      </Suspense>
    </WorkspaceIconErrorBoundary>
  );
};

WorkspaceIconPicker.propTypes = {
  buttonIconStyles: PropTypes.object,
  buttonStyles: PropTypes.object,
  containerStyles: PropTypes.object,
  hideSearch: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  pickerIconStyles: PropTypes.object,
  searchInputStyles: PropTypes.object,
  value: PropTypes.string.isRequired,
};

export default WorkspaceIconPicker;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  modalHeader: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "5px 10px",
    color: "#fff",
    minHeight: 20,
    "@media print": {
      display: "none!important",
    },
  },
  modalTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 2.3,
    marginRight: 20,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "normal",
      fontSize: 14,
    },
  },
  close: {
    padding: 4,
    color: "#fff",
    marginLeft: "auto",
  },
}));

const ModalHeader = ({ handleClose, title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.modalHeader} display="block" displayPrint="none">
      <Box display="block" displayPrint="none">
        <h4 className={classes.modalTitle}>{title}</h4>
      </Box>
      <IconButton
        className={classes.close}
        onClick={handleClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

ModalHeader.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ModalHeader;

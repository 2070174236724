import { useDispatch } from "react-redux";
import {
  clearFileviewCopyParams,
  setFileviewCopyParams,
} from "../../actions";
import { ENTITY_TYPE } from "../../constant/fileViewCopyPaste.js";

// eslint-disable-next-line import/prefer-default-export
export const useFileViewCopy = () => {
  const dispatch = useDispatch();

  const copyFolder =
    folderId =>
      handleDispatch({
        type: ENTITY_TYPE.Folder,
        ids: folderId,
      });

  const copyResources =
    resourceIds =>
      handleDispatch({
        type: ENTITY_TYPE.Resource,
        ids: resourceIds,
      });

  const clearCopyParams =
    () =>
      dispatch(
        clearFileviewCopyParams()
      );

  const handleDispatch =
    copyParams =>
      dispatch(
        setFileviewCopyParams({ copyParams })
      );

  return {
    clearCopyParams,
    copyFolder,
    copyResource: copyResources,
    copyResources,
  };
};

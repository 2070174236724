import { gql } from '@apollo/client';

const getResourceGroupsEdit = gql`
  query GetResourceGroupsEdit($resourceId: ID!) {
    productEdit {
      getResourceGroupsEdit(resourceId: $resourceId) {
        resourceGroups {
          ...ChildGroupRecursive
          ...GroupFields
        }
        resourceId
      }
    }
  }

  fragment ChildGroupRecursive on ResourceGroup {
    childGroups {
      ...GroupFields
      childGroups {
        ...GroupFields
        childGroups {
          ...GroupFields
        }
      }
    }
  }

  fragment GroupFields on ResourceGroup {
    groupId
    hasChildGroups
    name
    parentGroupId
    selected
  }
`;

export default getResourceGroupsEdit;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";

import Slide from "@mui/material/Slide";
import Header from "./Header";

const useStyles = makeStyles(() => ({
  root: ({ headerHeight }) => ({
    marginTop: headerHeight,
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  }),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({
  open,
  handleClose,
  headerHeight,
  renderRightDiv,
  renderLeftDiv,
  renderBody,
}) => {
  const classes = useStyles({ headerHeight });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.root}
    >
      <Header
        renderRightDiv={renderRightDiv}
        renderLeftDiv={renderLeftDiv}
        handleClose={handleClose}
      />
      {renderBody()}
    </Dialog>
  );
};

FullScreenDialog.defaultProps = {
  headerHeight: 60,
  renderBody: () => {},
};

FullScreenDialog.propTypes = {
  headerHeight: PropTypes.number,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  renderBody: PropTypes.func,
  renderLeftDiv: PropTypes.func.isRequired,
  renderRightDiv: PropTypes.func.isRequired,
};

export default FullScreenDialog;

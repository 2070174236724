import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import {
  DashboardContainer,
  InformationSection,
  SiteHeaderTitle,
} from "../../components/dashboard";

const useStyles = makeStyles(() => ({
  headerTextBold: {
    fontWeight: 600,
    fontSize: 18,
    textAlign: "left",
  },
  headerText: {
    fontSize: 14,
    textAlign: "left",
  },
  headerTextItalic: {
    fontStyle: "italic",
    fontSize: 14,
    textAlign: "left",
  },
  warning: {
    fontWeight: 700,
    fontSize: 14,
    color: "red",
  },
}));

const Matilda = workspacename => {
  const classes = useStyles();

  return (
    <DashboardContainer>
      <InformationSection>
        <SiteHeaderTitle title={"Välkommen till " + workspacename.workspace} />
        <Typography className={classes.headerText}>
          Matilda är Kommunals nya webshop för beställning av trycksaker. Du
          beställer enkelt genom att skriva i önskat antal och trycka på knappen
          ”beställ” längst ner till höger. Du kan också ladda ner som PDF, för
          att läsa på skärm eller skriva ut på din skrivare. Om lagersaldot är
          noll betyder det att artikeln just nu finns endast som PDF.
        </Typography>
        <br />
        <Typography
          className={clsx([classes.headerTextBold, classes.headerText])}
        >
          När ni beställer, tänk på att ni måste vara där och ta emot eventuellt
          paket. Vi får tyvärr tillbaka en del returpaket. Det blir en onödig
          kostnad.
        </Typography>
        <br />
        <Typography
          className={clsx([classes.headerTextBold, classes.headerText])}
        >
          Har du frågor, maila i första hand till material@kommunal.se eller
          ring 010 442 72 80
        </Typography>
        <br />
        <Typography
          className={clsx([classes.headerTextItalic, classes.headerText])}
        >
          Om du söker information om ett material som inte längre finns i
          Matilda, kontakta material@kommunal.se
        </Typography>
      </InformationSection>
    </DashboardContainer>
  );
};

export default Matilda;

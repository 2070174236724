import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Details from "./Details";
import Summary from "./Summary";
import SectionDetailsLink from "../common/SectionDetailsLink";

const totalStorageSpace = 60;

function createDetailsRow(workspaceName, desiredStorageSpace) {
  return { workspaceName, desiredStorageSpace };
}

const detailsRows = [
  createDetailsRow("Batbyggaren 2355", 10),
  createDetailsRow("Kanalen 32", 50),
];

const StorageSpaceSection = ({ classes }) => {
  const [openDetails, setOpenDetails] = useState(false);

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <>
      <Typography className={classes.sectionTitle}>
        <FormattedMessage id="admin.subscriptions.storageSpace.title" />
      </Typography>
      <Grid container item xs={12} md={4}>
        <Summary
          className={classes.sectionSummary}
          totalStorageSpace={totalStorageSpace}
        />
      </Grid>
      <SectionDetailsLink
        className={classes.sectionDetailsLink}
        onClick={toggleOpenDetails}
      />
      <Grid container item xs={12}>
        <Details
          className={classes.sectionDetails}
          open={openDetails}
          rows={detailsRows}
        />
      </Grid>
    </>
  );
};

StorageSpaceSection.defaultProps = {
  classes: {},
};

StorageSpaceSection.propTypes = {
  classes: PropTypes.object,
};

export default StorageSpaceSection;

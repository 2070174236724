import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import SitePersonsTableRow from "./SitePersonsTableRow";
import SitePersonsTableHead from "./SitePersonsTableHead";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { TableBody, TableRow } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    overflowX: "auto",
    position: "relative",
  },
  loadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
  },
}));

const SitePersonsTable = ({
  fetchSiteUsers,
  sortfield,
  ascending,
  setSortfield,
  setAscending,
  siteRolesRights,
  siteUsers,
  siteUsersLoading,
  onRemoveSiteUser,
  onUpdateSiteUser,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Table size="small">
        <SitePersonsTableHead
          sortfield={sortfield}
          ascending={ascending}
          setSortfield={setSortfield}
          setAscending={setAscending}
          siteRolesRights={siteRolesRights}
        />

        {siteUsersLoading ? (
          <TableBody>
            <TableRow>
              <TableCell className={classes.tdSkeleton} colSpan={9}>
                <TableSkeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {siteUsers.map(siteUser => {
              return (
                <SitePersonsTableRow
                  key={siteUser.id}
                  siteUser={siteUser}
                  handleOpenEditUser={() => { }}
                  fetchSiteUsers={fetchSiteUsers}
                  siteRolesRights={siteRolesRights}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
    </div>
  );
};

SitePersonsTable.propTypes = {
  fetchSiteUsers: PropTypes.func.isRequired,
  setAscending: PropTypes.func.isRequired,
  setSortfield: PropTypes.func.isRequired,
  sortfield: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
};

export default SitePersonsTable;

import { Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import QRCode from "react-qr-code";
import CopyToClipboardButton from "../../../CopyToClipboardButton";

const TOTPQR = ({ username, issuer, secret }) => {

  if (!username || !issuer || !secret) {
    return (<Box sx={{ backGround: 'white', padding: '16px' }}>Vi saknar något för att kunna visa QR-kod.</Box>);
  }

  const otPath = `otpauth://totp/${username}?secret=${secret}&issuer=${issuer}`;

  function splitSecret(secret) {
    const chunkSize = Math.floor(secret.length / 4);
    let groups = [];
    let startIndex = 0;

    while (startIndex < secret.length) {
      let chunkLength = chunkSize;

      groups.push(secret.substr(startIndex, chunkLength));
      startIndex += chunkLength;
    }
    //non breaking space non breaking hyphen non breaking space
    return groups.join("‑");
  }

  return (
    <>
      <Box sx={{ backGround: 'white', padding: '16px' }}>
        <QRCode size={200} value={otPath} />
      </Box>
      <Typography>Eller ange koden manuellt:</Typography>
      <CopyToClipboardButton width="360px">{splitSecret(secret)}</CopyToClipboardButton>
    </>
  );
}

export default TOTPQR;
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import FolderTableHead from "./FolderTableHead";
import FolderTableItems from "./FolderTableItems";

const useStyles = makeStyles(() => ({
  table: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
}));

const FolderPermissionsTable = ({
  siteRoleRights,
  setSiteRoleRights,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Table className={classes.table} size="small">
      <FolderTableHead />
      <TableBody>
        {loading && <CircularProgress color="grey" size={18} />}
        {!loading && (
          <>
            {siteRoleRights.map((role, idx) => {
              return (
                <FolderTableItems
                  key={role.siteRoleId}
                  idx={idx}
                  role={role}
                  siteRoleRights={siteRoleRights}
                  setSiteRoleRights={setSiteRoleRights}
                />
              );
            })}
          </>
        )}
      </TableBody>
    </Table>
  );
};

FolderPermissionsTable.defaultProps = {
  siteRoleRights: [],
  loading: false,
};

FolderPermissionsTable.propTypes = {
  siteRoleRights: PropTypes.arrayOf(PropTypes.shape({})),
  setSiteRoleRights: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default FolderPermissionsTable;

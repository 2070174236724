import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../hooks";
import { Typography, Box, ButtonGroup, Divider } from "@mui/material";
import { UPDATE_STOCK_BALANCE } from "../../../../../graphql/mutations";
import TextField from "../../../../common/TextField";
import TextAreaFull from "../../../../common/FormControl/TextAreaFull";
import DraggableModal from "../../../../common/DraggableModal";
import DraggableModalHead from "../../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  quantityTextFieldWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiFormControl-fullWidth": {
      width: "auto",
      marginLeft: 10,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
  },
  btnGroup: {
    marginLeft: 5,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  value: {
    fontSize: 14,
    marginLeft: 10,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  modalFooter: {
    padding: "10px 20px",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
}));

const EditStockBalanceModal = ({
  open,
  handleClose,
  resourceId,
  refetchStock,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [updateStockBalance] = useMutation(UPDATE_STOCK_BALANCE);
  const [stockBalance, setStockBalance] = useState(0);
  const [reason, setReason] = useState("");

  const handleUpdateStockBalance = async operation => {
    try {
      const res = await updateStockBalance({
        variables: {
          resourceId,
          value: stockBalance,
          reason,
          operation,
        },
      });

      if (res && res.data && res.data.updateStockBalance) {
        if (res.data.updateStockBalance.success) {
          alert.success(<FormattedMessage id="product.updatedStockBalance" />);
          refetchStock();
        } else {
          // TODO: We can also display the res.data.updateStockBalance.errorResult here
          snackbar.workspaceError(
            <FormattedMessage id="common.errorOnSaving" />
          );
        }
      } else {
        snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
      }
    } catch (err) {
      snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="product.changeStockBalance" />}
      />
      <DraggableModalBody>
        <Box className={classes.quantityTextFieldWrapper} mb={2}>
          <Typography className={classes.label}>Lagersaldo:</Typography>
          <TextField
            type="number"
            value={stockBalance}
            onChange={e => setStockBalance(e.target.value)}
          />
          <ButtonGroup className={classes.btnGroup}>
            <Button onClick={() => handleUpdateStockBalance("ADD")}>+</Button>
            <Button onClick={() => handleUpdateStockBalance("SUBTRACT")}>
              -
            </Button>
            <Button onClick={() => handleUpdateStockBalance("SET")}>=</Button>
          </ButtonGroup>
        </Box>
        <Divider className={classes.divider} />
        <Typography className={classes.label}>
          <FormattedMessage id="product.changeStockReason" />
        </Typography>
        <TextAreaFull
          rows="3"
          rowsMax="3"
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

EditStockBalanceModal.defaultProps = {
  resourceId: null,
  refetchStock: null,
};

EditStockBalanceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
  refetchStock: PropTypes.func,
};

export default EditStockBalanceModal;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Divider } from "@mui/material";

import { STRUCTURE_TYPES } from "../../../constant/types";
import getStructureTreeData from "../../../helpers/getStructureTreeDataStructure";
import { WorkspaceList } from "../WorkspaceList";
import SearchInput from "../WorkspaceSelectorSearchInput";
import OrganizeWorkspacesLink from "../OrganizeWorkspacesLink";

const WorkspaceContainer = ({
  onWorkspaceSelected,
  workspaces,
}) => {
  const [structureTree, setStructureTree] = useState({});

  const createStructureTree = (workspaceArray) => {
    return getStructureTreeData({
      structures: workspaceArray,
    });
  };

  const handleSearchInputChange = query => {
    let filteredTree = {};

    if (!query) {
      const tree = createStructureTree(workspaces);
      return setStructureTree(tree);
    }

    const keys = Object.keys(structureTree);

    keys.forEach(x => {
      if (
        structureTree[x].name.toLowerCase().includes(query) &&
        structureTree[x].type !== STRUCTURE_TYPES.FOLDER
      ) {
        const tempValues = { ...structureTree[x], isRoot: true };
        const found = { [x]: tempValues };
        filteredTree = { ...filteredTree, ...found };
      }
    });

    setStructureTree(filteredTree);
  };

  useEffect(() => {
    const tree = createStructureTree(workspaces);
    setStructureTree(tree);
  }, [workspaces]);

  return (
    <Box>
      <SearchInput onChange={handleSearchInputChange} />
      <Divider />
      <WorkspaceList
        headerLabel={<FormattedMessage id="workspace.my" />}
        onClick={(site) => onWorkspaceSelected?.(site.siteId)}
        structureTree={structureTree}
      />
      <OrganizeWorkspacesLink />
    </Box>
  );
};

WorkspaceContainer.propTypes = {
  onWorkspaceSelected: PropTypes.func.isRequired,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default WorkspaceContainer;

import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";
import { CustomSelect } from "../../common";
import CheckboxDefault from "../../common/FormControl/Checkbox";

const options = [
  {
    label: <FormattedMessage id="supplier.visibilitySetting.public" />,
    value: "PUBLIC",
  },
  {
    label: <FormattedMessage id="supplier.visibilitySetting.private" />,
    value: "PRIVATE",
  },
];

const SupplierAlias = ({
  activeSupplier,
  handleCheckbox,
  setSupplierType,
  supplierId,
  supplierType,
}) => (
  <>
    <Grid xs={12}>
      <Typography sx={{ display: "flex", alignItems: "center" }}>
        <CheckboxDefault
          onChange={e => handleCheckbox(e)}
          value={activeSupplier}
        />
        <FormattedMessage id="supplier.supplier" />
        {activeSupplier &&
          <>
            &nbsp;<FormattedMessage id="supplier.supplierWithId" />
          </>
        }
        :&ensp;
        <strong>{supplierId}</strong>
      </Typography>
    </Grid>
    <Grid xs={12}>
      <CustomSelect
        fullWidth
        onChange={e => setSupplierType(e.target.value)}
        options={options}
        value={supplierType}
      />
    </Grid>
  </>
);

SupplierAlias.defaultProps = {
  activeSupplier: false,
  email: "",
  supplierId: null,
  supplierType: null,
};

SupplierAlias.propTypes = {
  activeSupplier: PropTypes.bool,
  email: PropTypes.string,
  error: PropTypes.bool.isRequired,
  handleAddSupplierAlias: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  handleEmail: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSupplierType: PropTypes.func.isRequired,
  supplierId: PropTypes.number,
  supplierType: PropTypes.number,
};

export default SupplierAlias;

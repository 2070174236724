import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ColorPicker from "../../../common/ColorPicker";
import CustomSelect from "../../../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginBottom: 8,
  },
  colorWrapper: {
    display: "flex",
    alignItems: "center",
  },
  colorPicker: {
    width: "24px!important",
  },
}));

const NewsSiteSettings = ({
  newsSiteFontColor,
  newsTemplate,
  onWidgetPropertyUpdate,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const templateOptions = [
    { label: intl.formatMessage({ id: "newsPost.fourSmallNews" }), value: 1 },
    { label: intl.formatMessage({ id: "newsPost.sixSmallNews" }), value: 2 },
  ];

  const ColorSelector = ({ defaultValue, messageId, property, value }) => (
    <Box className={classes.formWrapper}>
      <Box className={classes.colorWrapper}>
        <Box className={classes.colorPicker}>
          <ColorPicker
            onChange={(e) => onWidgetPropertyUpdate(property, e.hex)}
            value={value || defaultValue}
          />
        </Box>
        <FormattedMessage id={messageId} />
      </Box>
    </Box>
  );

  return (
    <>
      {/* <Box className={classes.formWrapper}>
        <Box className={classes.colorWrapper}>
          <InputColor
            initialValue={newsSiteBG || "#fff"}
            onChange={e => handleNewsSiteBackground(e)}
            placement="right"
            className={classes.colorPicker}
          />
          <FormattedMessage id="widget.dashboardColor" />
        </Box>
      </Box> */}

      <ColorSelector
        defaultValue={"#000"}
        messageId={"widget.fontColor"}
        property={"newsSiteFontColor"}
        value={newsSiteFontColor} />

      <Box className={classes.formWrapper}>
        <Box className={classes.colorWrapper}>
          <FormattedMessage id="widget.useTemplate" />
        </Box>
        <Box className={classes.colorWrapper}>
          <CustomSelect
            onChange={e => onWidgetPropertyUpdate("newsTemplate", e.target.value)}
            options={templateOptions}
            value={newsTemplate || 1}
          />
        </Box>
      </Box>

    </>
  );
};

export default NewsSiteSettings;

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import IconLink from "../../common/FormControl/IconLink";

const useStyles = makeStyles(theme => ({
  cbWrap: {
    paddingLeft: 5,
  },
  infoIcon: {
    color: theme.palette.common.link,
  },
}));

const Settings = ({
  handleOpenWebb,
  handleOpenMSOffice,
  handleOpenTryck,
  imageOptions,
  setImageOptions,
}) => {
  const classes = useStyles();

  const handleOptions = e => {
    const { name, checked } = e.target;

    setImageOptions({ ...imageOptions, [name]: checked });
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" className={classes.cbWrap}>
        <CheckboxDefault
          name="webb"
          label="Webb"
          value={imageOptions.webb}
          onChange={e => handleOptions(e)}
        />
        <IconLink
          title={
            <FormattedMessage id="imageBasket.infoIcon.clickForDescription" />
          }
          icon="nc-icon nc-alert-circle-i"
          customClass={classes.infoIcon}
          onClick={handleOpenWebb}
        />
      </Box>
      <Box display="flex" alignItems="center" className={classes.cbWrap}>
        <CheckboxDefault
          label="MS Office"
          name="msOffice"
          value={imageOptions.msOffice}
          onChange={e => handleOptions(e)}
        />
        <IconLink
          title={
            <FormattedMessage id="imageBasket.infoIcon.clickForDescription" />
          }
          icon="nc-icon nc-alert-circle-i"
          customClass={classes.infoIcon}
          onClick={handleOpenMSOffice}
        />
      </Box>
      <Box display="flex" alignItems="center" className={classes.cbWrap}>
        <CheckboxDefault
          label="Tryck"
          name="tryck"
          value={imageOptions.tryck}
          onChange={e => handleOptions(e)}
        />
        <IconLink
          title={
            <FormattedMessage id="imageBasket.infoIcon.clickForDescription" />
          }
          icon="nc-icon nc-alert-circle-i"
          customClass={classes.infoIcon}
          onClick={handleOpenTryck}
        />
      </Box>
    </Box>
  );
};

Settings.propTypes = {
  handleOpenWebb: PropTypes.func.isRequired,
  handleOpenMSOffice: PropTypes.func.isRequired,
  handleOpenTryck: PropTypes.func.isRequired,
  imageOptions: PropTypes.shape({
    webb: PropTypes.bool,
    msOffice: PropTypes.bool,
    tryck: PropTypes.bool,
  }).isRequired,
  setImageOptions: PropTypes.func.isRequired,
};

export default Settings;

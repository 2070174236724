import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import Button from "@mui/material/Button";
import TextAreaFull from "../../common/FormControl/TextAreaFull";
import ButtonText from "../../common/ButtonText";
import { RENAME_RESOURCE } from "../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  editIcon: {
    width: "unset",
    height: "unset",
    fontSize: 10,
    marginRight: 5,
    color: theme.palette.common.link,
    ".uq-pti-hovered &, .uq-pti-selectedResource &": {
      color: theme.palette.primary.dark,
    },
  },
  descEditBox: {
    marginBottom: 5,
    flex: 1,
    "& .MuiFormControl-marginDense": {
      marginTop: 5,
    },
    "& .MuiInputBase-input": {
      fontSize: 11,
    },
    "& .MuiInputBase-root": {
      padding: "5px 10px",
    },
  },
  btnCancel: {
    color: theme.palette.common.link,
    fontSize: 10,
    marginTop: 5,
    marginRight: 8,
    ".uq-pti-hovered &, .uq-pti-selectedResource &": {
      color: theme.palette.primary.dark,
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: 9,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    width: "auto",
    marginTop: 5,
  },
  nameCont: {
    cursor: "pointer",
  },
  root: {
    padding: 5,
  },
}));

const ProductName = ({ name, id, locked, handleError }) => {
  const classes = useStyles();
  const alert = useAlert();
  const [showEdit, setShowEdit] = useState(false);
  const [productName, setProductName] = useState(name);

  const [renameResource, { loading }] = useMutation(RENAME_RESOURCE);

  const handleUpdateResourceItem = async () => {
    try {
      const res = await renameResource({
        variables: { resourceId: id, newName: productName },
      });

      if (res && res.data && res.data.renameResource) {
        const { success } = res.data.renameResource;
        if (success) {
          alert.success(
            <FormattedMessage id="product.successUpdatedResource" />
          );
          setShowEdit(false);
        } else {
          const { errorResult } = res.data.renameResource;
          setProductName(setProductName);
          setShowEdit(false);
          handleError(name, errorResult);
        }
      }
    } catch (e) {
      console.log("Error Updating Resource Item ", e);
    }
  };

  const handleCancel = () => {
    setProductName(setProductName);
    setShowEdit(false);
  };

  return (
    <>
      {!showEdit && (
        <div onClick={() => !locked && setShowEdit(true)}>{productName}</div>
      )}
      {showEdit && (
        <Box className={classes.root} align="right">
          <Box className={classes.descEditBox} align="right">
            <TextAreaFull
              rows="4"
              value={productName}
              onChange={e => setProductName(e.target.value)}
            />
          </Box>
          <ButtonText
            onClick={() => handleCancel()}
            label={<FormattedMessage id="btn.cancel" />}
            btnClass={classes.btnCancel}
            disabled={loading}
          />
          <Button
            className={classes.btnSave}
            onClick={() => handleUpdateResourceItem()}
            disabled={loading}
          >
            <FormattedMessage id="btn.save" />
          </Button>
        </Box>
      )}
    </>
  );
};

ProductName.defaultProps = {
  locked: false,
  lockedByFolder: false,
};

ProductName.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  lockedByFolder: PropTypes.bool,
  handleError: PropTypes.func.isRequired,
};

export default ProductName;

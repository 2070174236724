import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DELETE_RESOURCE_LINK_BY_ID = gql`
  mutation DeleteResourceLinkById($resourceLinkId: ID!) {
    productEditContext {
      deleteResourceLinkById(resourceLinkId: $resourceLinkId) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_RESOURCE_LINK_BY_ID;

import { gql } from '@apollo/client';
import OrderGroup from "../fragments/OrderGroup";

const getCart = gql`
  query GetCart {
    shoppingCartContent {
      groups {
        ...OrderGroupFragment
      }
    }
  }
  ${OrderGroup}
`;

export { getCart };

import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import HomeMadeFolderTree from "./HomeMadeFolderTree";
import getFolderTreeDataStructure from "../../helpers/getFolderTreeDataStructure";

const FolderTreeContainer = ({ folders, setFolderId }) => {
  const [folderTree, setFolderTree] = useState({});

  useEffect(() => {
    const newFolderTree = getFolderTreeDataStructure({ folders });
    setFolderTree(newFolderTree);
  }, [folders]);

  const handleOnfolderRowClick = folder => {
    setFolderId(folder.folderId);
  };

  return (
    <HomeMadeFolderTree
      folderTree={folderTree}
      onFolderRowClick={handleOnfolderRowClick}
    />
  );
};

FolderTreeContainer.defaultProps = {
  folders: [],
};

FolderTreeContainer.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      parentFolderId: PropTypes.number,
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentFolderId: PropTypes.number.isRequired,
        })
      ),
    })
  ),
  setFolderId: PropTypes.func.isRequired,
};

function areEqual(prevProps, nextProps) {
  if (
    nextProps.setFolderId !== prevProps.setFolderId ||
    nextProps.folders !== prevProps.folders
  ) {
    return false;
  }

  return true;
}

export default memo(FolderTreeContainer, areEqual);

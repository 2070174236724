import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { useSelector } from "react-redux";
import { Paper, Box, Typography, Button, Grid } from "@mui/material";
import clsx from "clsx";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import axios from "axios";
import OneStreamTable from "../../components/OneStream/OneStreamTable";
import OneStreamDropzone from "../../components/OneStream/OneStreamDropzone";
import NewUploadModal from "../../components/OneStream/NewUploadModal";
import { useGetOneStreamUploadUrl, useGetOneStreamJobs } from "../../hooks";
import Pagination from "../../components/common/Pagination";
import { getSiteId } from "../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.component.productBoxColor,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
  paperBody: {
    padding: theme.spacing(4),
    paddingTop: 10,
    paddingBottom: 20,
    "& .dropzone": {
      backgroundColor: theme.palette.common.white,
      marginTop: 15,
    },
  },
  paginationWrapper: {
    marginLeft: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      marginTop: 8,
      marginLeft: 0,
    },
  },
}));

const OneStream = () => {
  const classes = useStyles();
  const alert = useAlert();
  const totalCount = useSelector(
    state => state.api.oneStream.oneStreamJobs.total
  );

  const { currentViewer } = useSelector(state => state.api);

  const siteId = useSelector(state => getSiteId(state));

  const [formValues, setFormValues] = useState({ preset: 1, email: "" });
  const [newUploadModal, setNewUploadModal] = useState(false);
  const [refreshDropzone, setRefreshDropzone] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [fileToUpload, setFileToUpload] = useState({});  
  const [progress, setProgress] = useState(0);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25); // !!!
  const [sortField, setSortField] = useState("uploadedDate");
  const [ascending, setAscending] = useState(false);

  const handlePageSizeChange = (e) => {
    setPage(0);
    setPageSize(e.target.value);
  };

  const { execute: getOneStream } = useGetOneStreamJobs({
    siteId,
    pageSize,
    offset: page * pageSize,
    sortField,
    ascending,
  });

  useEffect(() => {
    if (siteId) {
      getOneStream();
    }
  }, [siteId]);

  useEffect(() => {
    setRefreshDropzone(false);
  }, [refreshDropzone]);

  useEffect(() => {
    if (currentViewer) {
      setFormValues({ ...formValues, email: currentViewer.viewer.username });
    }
  }, [currentViewer]);

  const { execute: getUploadUrl } = useGetOneStreamUploadUrl({
    siteid: siteId,
    name: formValues.name,
    email: formValues.email,
    preset: formValues.preset,
  });

  const updateSort = (field, asc) => {
    setSortField(field);
    setAscending(asc);
    getOneStream();
  };

  const resetValues = () => {
    setFormValues({ preset: 0 });
    setProgress(0);    
    setUploadedFile([]);
  };

  const closeUploadModal = () => {
    setNewUploadModal(false);
    resetValues();
  };

  const handleOnSelectUploadFile = async files => {
    if (files && files[0]) {
      const newFile = { name: files[0].name };
      setUploadedFile([newFile]);
      setFileToUpload(files[0]);
    } else {
      console.warn("An error could be happening in selecting files.");
    }
  };

  const handleUploadFiles = async () => {
    let uploadUrl = "";
    setProgress(1);
    const config = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setProgress(percentCompleted);
      },
    };

    try {
       
      const res = await getUploadUrl();
      if (res && res.data && res.data.initOneStreamUpload) {
        uploadUrl = res.data.initOneStreamUpload;
      }

      const formData = new FormData();
      formData.append("file", fileToUpload);

      const response = await axios.post(`${uploadUrl}`, formData, config);
      if (
        response &&
        response.data &&
        response.data.success &&
        response.status === 200
      ) {
        console.log(`Success uploading file.`);
        setNewUploadModal(false);
        resetValues();
        setRefreshDropzone(true);
        getOneStream();
        alert.success(<FormattedMessage id="product.uploaded" />);
      } else {
        console.error(
          `An error occurred while uploading the file. ${JSON.stringify(
            response
          )}`
        );
      }
    } catch (uploadException) {
      console.error(
        `An error occurred while uploading the file. ${JSON.stringify(
          uploadException
        )}`
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper} elevation={0}>
            <Box className={classes.paperHead}>
              <Typography variant="h1" component="h2" className={classes.title}>
                OneStream
              </Typography>
              <Button
                className={classes.btnAdd}
                onClick={() => setNewUploadModal(true)}
              >
                <FormattedMessage id="onestream.newUpload" />
                <Icon
                  className={clsx(["fa fa-plus-circle", classes.btnIcon])}
                />
              </Button>
              {totalCount > pageSize && (
                <div className={classes.paginationWrapper}>
                  <Pagination
                    onChange={handlePageSizeChange}
                    onPageChange={(e, p) => setPage(p)}
                    page={page}
                    perPage={pageSize}
                    totalCount={totalCount}
                  />
                </div>
              )}
            </Box>
            <Box className={classes.paperBody}>
              <OneStreamTable
                sortField={sortField}
                updateSort={updateSort}
                ascending={ascending}
              />
              {refreshDropzone === false && (
                <OneStreamDropzone
                  setNewUploadModal={setNewUploadModal}
                  handleOnSelectUploadFile={handleOnSelectUploadFile}
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {newUploadModal && (
        <NewUploadModal
          open={newUploadModal}
          closeModal={closeUploadModal}
          formValues={formValues}
          setFormValues={setFormValues}
          handleOnSelectUploadFile={handleOnSelectUploadFile}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          setRefreshDropzone={setRefreshDropzone}
          handleUploadFiles={handleUploadFiles}
          progress={progress}
        />
      )}
    </div>
  );
};

export default OneStream;

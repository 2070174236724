import React from "react";
import PropTypes from "prop-types";
import CheckboxInline from "../common/FormControl/CheckboxInline";

const MultiCheckboxField = ({
  propertyValuesState,
  field,
  value,
  handleCheckboxOnChange,
}) => {
  const { id, name } = field;
  const options = propertyValuesState.map(x => {
    return {
      label: x.name,
      value: x.selected,
      id: x.id,
    };
  });
  // value is an array of objects
  // value = [{propertyValueId: **}, {propertyValueId: **}]
  const arrayValues = value.map(x => x.propertyValueId);
  return (
    <CheckboxInline
      key={`field-key-${id}`}
      label={name}
      options={options}
      value={arrayValues || []}
      onChange={handleCheckboxOnChange}
    />
  );
};

MultiCheckboxField.defaultProps = {
  value: false,
};

MultiCheckboxField.propTypes = {
  handleCheckboxOnChange: PropTypes.func.isRequired,
  field: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  propertyValuesState: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.bool,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  value: PropTypes.bool,
};

export default MultiCheckboxField;

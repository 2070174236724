import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "../constant";
import logger from "../helpers/logger";
import { parseJwt } from "./jwtParser";

export default class Auth {
  UserManager;

  accessToken;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded(user => {
      this.accessToken = user.access_token;
      localStorage.setItem("access_token", user.access_token);
      localStorage.setItem("id_token", user.id_token);
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token,
      });
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError(e => {
      logger.debug("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      logger.debug("token expired");
      // TODO signedinSilent has error atm needs to be configured or dicussed
      this.signinSilentCallback();
      //localStorage.clear();
      //this.signinRedirect();
    });
  }

  signinRedirectCallback = () => {
    logger.debug("signinRedirectCallback");
    this.UserManager.signinRedirectCallback().then(() => {
      "";
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      const respond = await this.UserManager.signinRedirectCallback();
      return respond;
    }
    return user;
  };

  setUserInfo = authResult => {
    const data = parseJwt(this.accessToken);
    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    var loc = window.location;
    var redirectUri = loc.pathname + loc.search + loc.hash;
    localStorage.setItem("redirectUri", redirectUri);
    this.UserManager.signinRedirect({});
  };

  setUser = data => {
    localStorage.setItem("userId", data.sub);
  };

  navigateToScreen = () => {
    // const redirectUri = !!localStorage.getItem("redirectUri")
    //   ? localStorage.getItem("redirectUri")
    //   : "/dashboard";
    // const language = "/" + redirectUri.split("/")[1];

    // window.location.replace(language + "/dashboard");
    window.location.replace("/initialize");
  };

  setSessionInfo = authResult => {
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
  };

  clearSessionInfo = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("selected_workspace");
    localStorage.removeItem("suppliers");
    localStorage.removeItem("viewer");
  };

  isAuthenticated = () => {
    const accessToken = localStorage.getItem("access_token");
    return !!accessToken;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then(user => {
        logger.debug("signed in", user);
      })
      .catch(err => {
        logger.debug("Error", err);
      });
  };

  signinSilentCallback = () => {
    logger.debug("** signinSilentCallback");
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    logger.debug("** logout");
    var id_token_hint = localStorage.getItem("id_token");
    this.clearSessionInfo();
    this.UserManager.signoutRedirect({
      id_token_hint: id_token_hint,
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    logger.debug("** signoutRedirectCallback");
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(window.location.origin.toString());
    });
    this.UserManager.clearStaleState();
  };
}

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import FormattedQuantity from "../common/FormattedQuantity";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/tables";

const Details = ({ className, open, rows }) => {
  return (
    open && (
      <TableContainer className={className}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.variableCosts.workspaceName" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.variableCosts.type" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage id="admin.subscriptions.variableCosts.quantity" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.variableCosts.note" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={`${row.workspaceName}-${row.type}-${row.note}`}>
                <TableCell>{row.workspaceName}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell
                  align="right"
                  style={{ whiteSpace: "nowrap", verticalAlign: "top" }}
                >
                  <FormattedQuantity quantity={row.quantity} />
                </TableCell>
                <TableCell>{row.note}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

Details.defaultProps = {
  className: "",
  open: false,
  rows: [],
};

Details.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
      quantity: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      workspaceName: PropTypes.string.isRequired,
    })
  ),
};

export default Details;

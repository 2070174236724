import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import { useLazyQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import { PrimaryButton, TextField } from "../../../common";
import { COMPANY_RESPONSIBLE_LOOK_UP } from "../../../../graphql/queries";
import Checkbox from "../../../common/FormControl/Checkbox";
import DateFull from "../../../common/FormControl/DateFull";
import SearchAutoComplete from "../../../common/SearchAutoComplete";
import Switch from "./Switch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Column } from "primereact/column";

const useStyles = makeStyles(() => ({
  checkboxContainer: { marginTop: 5 },
  accordion: {
    flexDirection: "column",
  },
  replaceMetadataSection: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "0 !important",
  },
  fieldWithSwitch: { display: "flex", alignItems: "center", marginTop: "1em" },
  autoCompleteRoot: {
    width: "100%",
  },
}));

const MetaDataForm = ({
  formValues,
  switchValues,
  handleDateChange,
  handleChange,
  handleSwitchChange,
  handleCheckboxChange,
  handleReplaceMetadata,
  handleSelectContact,
  setSelectedUser,
  selectedUser,
  markedResource,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [searchAutocomplete, setSearchAutoComplete] = useState(false);
  const [users, setUsers] = useState([]);

  const [
    searchCompanyResponsible,
    { data: reponsibleData, loading: responsibleLoading },
  ] = useLazyQuery(COMPANY_RESPONSIBLE_LOOK_UP);

  useEffect(() => {
    if (
      reponsibleData &&
      reponsibleData.productEdit &&
      Array.isArray(reponsibleData.productEdit.companyAnsvarigLookup) &&
      reponsibleData.productEdit.companyAnsvarigLookup.length > 0
    ) {
      const tempUsers = reponsibleData.productEdit.companyAnsvarigLookup.map(
        x => {
          return { label: x.user, value: x.userId };
        }
      );
      setUsers(tempUsers);
    }
  }, [reponsibleData]);

  const debounceFetchUsersList = useCallback(
    debounce(async searchTerm => {
      searchCompanyResponsible({
        variables: { searchTerm },
      });
    }, 500),
    []
  );

  const handleOnChange = async text => {
    if (text && text.length > 2) {
      debounceFetchUsersList(text);
    }
  };

  const setClose = () => {
    setSearchAutoComplete(false);
  };

  const setOpen = () => {
    setSearchAutoComplete(true);
  };

  const handleSetUsers = value => {
    setSelectedUser(value);
    handleSelectContact({ label: value });
  };

  const {
    contact,
    date,
    imageNo,
    isFreeToUser,
    legalDocument,
    location,
    model,
    occupation,
    photographer,
  } = formValues;

  const {
    contact: contactSwitch,
    date: dateSwitch,
    imageNo: imageNoSwitch,
    location: locationSwitch,
    model: modelSwitch,
    occupation: occupationSwitch,
    photographer: photographerSwitch,
    isFreeToUser: isFreeToUserSwitch,
  } = switchValues;

  return (
    <Box>
      {markedResource &&
        markedResource.fileMetaData &&
        markedResource.fileMetaData.length > 0 &&
        markedResource.fileMetaData.map(x => {
          return (
            <Accordion>
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>{x.name}</Typography>
              </AccordionSummary>

              <AccordionDetails className={classes.accordion}>
                {x.subCategories &&
                  x.subCategories.map(y => {
                    return (
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          className={classes.summary}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          {" "}
                          {y.name}
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordion}>
                          {y.values &&
                            y.values.map(v => {
                              return (
                                <TextField
                                  label={v.name}
                                  value={v.value}
                                  disabled
                                ></TextField>
                              );
                            })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      <Box className={classes.fieldWithSwitch}>
        <FormattedMessage id="metadata.editMetadata" />
      </Box>

      <Box className={classes.fieldWithSwitch}>
        <DateFull
          name="date"
          label={intl.formatMessage({
            id: "product.date",
          })}
          value={new Date(date)}
          setDate={handleDateChange}
          disabled={!dateSwitch}
        />
        <Switch
          checked={dateSwitch}
          onChange={e => handleSwitchChange(e, "date")}
        />
      </Box>
      <Box className={classes.fieldWithSwitch}>
        <TextField
          name="location"
          label={intl.formatMessage({
            id: "metadata.place",
          })}
          onChange={handleChange}
          value={location}
          disabled={!locationSwitch}
        />
        <Switch
          checked={locationSwitch}
          onChange={e => handleSwitchChange(e, "location")}
        />
      </Box>
      <Box className={classes.fieldWithSwitch}>
        <TextField
          name="model"
          label={intl.formatMessage({
            id: "metadata.model",
          })}
          value={model}
          onChange={handleChange}
          disabled={!modelSwitch}
        />
        <Switch
          checked={modelSwitch}
          onChange={e => handleSwitchChange(e, "model")}
        />
      </Box>
      <Box className={classes.fieldWithSwitch}>
        <TextField
          name="occupation"
          label={intl.formatMessage({
            id: "metadata.profession",
          })}
          onChange={handleChange}
          value={occupation}
          disabled={!occupationSwitch}
        />
        <Switch
          checked={occupationSwitch}
          onChange={e => handleSwitchChange(e, "occupation")}
        />
      </Box>
      <Box className={classes.fieldWithSwitch}>
        <TextField
          name="imageNo"
          label={intl.formatMessage({
            id: "metadata.imageNo",
          })}
          onChange={handleChange}
          value={imageNo}
          disabled={!imageNoSwitch}
        />
        <Switch
          checked={imageNoSwitch}
          onChange={e => handleSwitchChange(e, "imageNo")}
        />
      </Box>
      <Box className={classes.fieldWithSwitch}>
        <SearchAutoComplete
          open={searchAutocomplete}
          setOpen={setOpen}
          setClose={setClose}
          onChange={value => handleOnChange(value)}
          options={users}
          loading={responsibleLoading}
          setValue={handleSetUsers}
          value={selectedUser}
          label={<FormattedMessage id="settings.email" />}
          customClass={classes.searchField}
          customClasses={{ root: classes.autoCompleteRoot }}
        />
        <Switch
          checked={contactSwitch}
          onChange={e => handleSwitchChange(e, "contact")}
        />
      </Box>
      <Box className={classes.fieldWithSwitch}>
        <TextField
          name="photographer"
          label={intl.formatMessage({
            id: "metadata.photographer",
          })}
          onChange={handleChange}
          value={photographer}
          disabled={!photographerSwitch}
        />
        <Switch
          checked={photographerSwitch}
          onChange={e => handleSwitchChange(e, "photographer")}
        />
      </Box>
      <Box className={classes.checkboxContainer}>
        <Checkbox
          name="isFreeToUser"
          label={intl.formatMessage({
            id: "metadata.royaltyFree",
          })}
          value={isFreeToUser}
          onChange={handleCheckboxChange}
          disabled={!photographerSwitch}
        />
        <Switch
          checked={isFreeToUserSwitch}
          onChange={e => handleSwitchChange(e, "isFreeToUser")}
        />
      </Box>

      {/*<PrimaryButton onClick={() => handleReplaceMetadata()}>
        <FormattedMessage id="metadata.replaceMetadata" />
      </PrimaryButton>*/}
    </Box>
  );
};

MetaDataForm.propTypes = {
  formValues: PropTypes.exact({
    contact: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    imageNo: PropTypes.string,
    isFreeToUser: PropTypes.bool,
    legalDocument: PropTypes.string,
    location: PropTypes.string,
    model: PropTypes.string,
    occupation: PropTypes.string,
    photographer: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  switchValues: PropTypes.exact({
    contact: PropTypes.bool,
    date: PropTypes.bool,
    imageNo: PropTypes.bool,
    legalDocument: PropTypes.bool,
    location: PropTypes.bool,
    model: PropTypes.bool,
    occupation: PropTypes.bool,
    photographer: PropTypes.bool,
  }).isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleReplaceMetadata: PropTypes.func.isRequired,
};

export default MetaDataForm;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Icon, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 2,
  },
}));

const TableCellActions = ({ id }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleViewNewsPost = () => {
    history.push({
      pathname: `/news-post`,
      state: { newpostId: id },
    });
  };

  return (
    <>
      <IconButton
        className={classes.iconButtonEdit}
        onClick={handleViewNewsPost}
      >
        <Icon className={clsx(["fa fa-eye", classes.icon])} />
      </IconButton>
      <IconButton className={classes.iconButtonEdit}>
        <Icon className={clsx(["fa fa-pencil-alt", classes.icon])} />
      </IconButton>
      <IconButton className={classes.iconButtonDelete}>
        <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
      </IconButton>
    </>
  );
};

TableCellActions.defaultProps = {};

TableCellActions.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TableCellActions;

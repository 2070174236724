import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Icon,
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../../../common/DraggableModal";
import DraggableModalHead from "../../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "350px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  body: {
    padding: 0,
    minHeight: "10vh",
  },
  container: {
    padding: 20,
  },
  icon: {
    width: "50px",
    height: "50px",
    background: "#e4e6eb",
    borderRadius: "100%",
    display: " inline-flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#000",
    fontSize: "20px",
    cursor: "pointer",
  },
  label: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000",
    display: "inline-flex",
  },
  optionWrapper: {
    display: "flex",
    marginBottom: 20,
    alignItems: "center",
  },
  fieldset: {
    width: "100%",
  },
  radio: {
    marginLeft: "auto",
  },
}));

const PrivacyModal = ({
  open,
  handleClose,
  name,
  visibilitySettings,
  privacyName,
  handleChange,
  webLinks,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="settings.selectAudience" />}
      />
      <DraggableModalBody customClass={classes.body}>
        <Grid container className={classes.container} alignItems="center">
          <FormControl component="fieldset" className={classes.fieldset}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={
                privacyName === "weblinks"
                  ? webLinks[name].visibility
                  : visibilitySettings[name]
              }
              onChange={e => handleChange(e.target.value)}
            >
              <Box className={classes.optionWrapper}>
                <Box
                  className={classes.icon}
                  onClick={() => handleChange("PUBLIC")}
                >
                  <PublicIcon />
                </Box>
                <Box style={{ marginLeft: 16 }}>
                  <FormattedMessage id="settings.public" />
                </Box>
                <Box className={classes.radio}>
                  <FormControlLabel
                    value="PUBLIC"
                    control={<Radio />}
                    labelPlacement="start"
                  />
                </Box>
              </Box>
              <Box className={classes.optionWrapper}>
                <Box
                  className={classes.icon}
                  onClick={() => handleChange("PRIVATE")}
                >
                  <Icon className={clsx(["fas fa-lock", classes.icon])} />
                </Box>
                <Box style={{ marginLeft: 16 }}>
                  <FormattedMessage id="settings.onlyMe" />
                </Box>
                <Box className={classes.radio}>
                  <FormControlLabel
                    value="PRIVATE"
                    control={<Radio />}
                    labelPlacement="start"
                  />
                </Box>
              </Box>
            </RadioGroup>
          </FormControl>
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

PrivacyModal.defaultProps = {
  visibilitySettings: {},
  name: "",
  privacyName: "",
  webLinks: [
    {
      url: "",
      visibility: "",
    },
  ],
};

PrivacyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.node,
  visibilitySettings: PropTypes.shape({
    company: PropTypes.string,
    mainEducation: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    directMessage: PropTypes.string,
  }),
  handleChange: PropTypes.func.isRequired,
  privacyName: PropTypes.string,
  webLinks: PropTypes.instanceOf(Array),
};

export default PrivacyModal;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    marginRight: "auto",
  },

  editLink: {
    color: theme.palette.common.link,
    fontSize: 12,
    textDecoration: "none",
  },
  editIcon: {
    marginRight: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <Box className={classes.paperHead}>
      <Typography variant="h1" component="h2" className={classes.title}>
        <FormattedMessage id="workspace.organizeWorkspaces" />
      </Typography>
      {false && (
        <Typography>
          <Link to="/shared-workspace" className={classes.editLink}>
            <Icon className={clsx(["fa fa-eye", classes.editIcon])} />
            <FormattedMessage id="workspace.myShared" />
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export default Header;

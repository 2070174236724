import { handleActions } from "redux-actions";
import {
  setOneStreamJobs,
  setOneStreamJobsLoading,
  setOneStreamJobsError,
} from "../../../../actions";

const defaultState = {
  oneStreamJobs: {
    jobs: [],
    loading: false,
    error: null,
    total: 0,
  },
};

export default handleActions(
  {
    [setOneStreamJobs]: (state, { payload }) => {
      const { items } = payload;
      const { result, totalItems } = items;
      return {
        ...state,
        oneStreamJobs: {
          ...state.oneStreamJobs,
          jobs: result,
          total: totalItems,
        },
      };
    },
    [setOneStreamJobsLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        oneStreamJobs: {
          ...state.oneStreamJobs,
          loading,
        },
      };
    },
    [setOneStreamJobsError]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        oneStreamJobs: {
          ...state.oneStreamJobs,
          error,
        },
      };
    },
  },
  defaultState
);

import { gql } from '@apollo/client';
import OrderGroupShoppingCartItem from "./OrderGroupShoppingCartItem";

const OrderGroup = gql`
  fragment OrderGroupFragment on OrderGroup {
    orderGroupId
    items {
      bookingInterval {
        from
        to
      }
      productId
      name
      quantity
      price
      dynamicFieldValues {
        fieldName
        value
      }
      product {
        articleNo
        description
        format
        id
        minimumPrice
        name
        numPages
        productImageUri
        productType
        thumbnailImageUri
        quantities {
          valuesList
          selectionType
        }
        thumbnailImageUri
      }
      propertyProductData {
        propertyValueId
        propertyName
        propertyValue
        quantity
      }
      siteId
      siteSupplierId
      selectableSuppliers {
        displayName
        siteSupplierId
      }
      source
      shoppingCartItemId
      selectableSuppliers {
        displayName
        siteSupplierId
      }
    }
    oneStreamGroups {
      packageId
      packageName
      items {
        ...OrderGroupShoppingCartItemFragment
      }
      selectableSuppliers {
        displayName
        siteSupplierId
      }
    }
    fileGroups {
      folderId
      packageId
      packageName
      selectableSuppliers {
        displayName
        siteSupplierId
      }
      quantity
      propertyProductData {
        propertyValueId
        propertyName
        propertyValue
        quantity
      }
      items {
        ...OrderGroupShoppingCartItemFragment
      }
      selectableSuppliers {
        displayName
        siteSupplierId
      }
    }
  }
  ${OrderGroupShoppingCartItem}
`;

export default OrderGroup;

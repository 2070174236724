import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  thIngress: {
    width: "30%",
  },
}));

const TableHeader = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th} />
        <TableCell className={classes.th}>
          <FormattedMessage id="common.title" />
        </TableCell>
        <TableCell className={clsx([classes.th, classes.thIngress])}>
          <FormattedMessage id="newsPost.preamble" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="newsPost.postedBy" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="newsPost.created" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="newsPost.comment" />
        </TableCell>
        <TableCell className={classes.th} />
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

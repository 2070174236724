import { useState, useEffect, useMemo } from "react";
import { Box, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useIntl } from "react-intl";
import KanbanColumn from "./KanbanColumn";
import { CustomSelect } from "../../common";
import createColumns from "../columnsHelpers";

const Kanban = ({ enableEdit, onProjectUpdate, onTaskAction, onTimeLogAction, project, tasks, userLookup }) => {
  const [columns, setColumns] = useState([]);

  const [taskDetailsColumns, setTaskDetailsColumns] = useState([]);

  const [selectedStatusFieldId, setSelectedStatusFieldId] = useState("");
  const intl = useIntl();

  useEffect(() => {
    if (project) {
      const statusFields = project.projectFields.filter(field => field.type === 3);
      if (statusFields.length > 0) {
        setSelectedStatusFieldId(statusFields[0].fieldId);
      }
    }
  }, [project]);

  const handleStatusFieldChange = (event) => {
    setSelectedStatusFieldId(event.target.value);
  };

  const statusFields = project ? project.projectFields.filter(field => field.type === 3) : [];

  useEffect(() => {
    if (project && Array.isArray(tasks)) {
      var columnsList = createColumns(project.projectFields);
      setTaskDetailsColumns(columnsList);      
    }
  }, [project, tasks]);

  const generateColumns = useMemo(() => {
    if (!project || !Array.isArray(tasks) || !selectedStatusFieldId) return [];

    const statusField = statusFields.find(field => field.fieldId === selectedStatusFieldId);
    if (!statusField) return [];

    let newColumns = [];
    try {
      const settings = JSON.parse(statusField.jsonFieldSettings);
      newColumns = settings.map(status => ({
        name: status.name,
        label: status.name,
        color: status.color,
        fieldId: statusField.fieldId,
        tasks: []
      }));
    } catch (e) {
      console.error("Error parsing JSON:", e);
    }

    newColumns.unshift({
      name: "PROJECT_KANBAN_UNDEFINED",
      label: intl.formatMessage({ id: "project.kanban.undefinedStatus" }),
      color: "#e0e0e0",
      fieldId: null,
      tasks: []
    });

    tasks.forEach(task => {
      let placed = false;
      task.values.forEach(value => {
        newColumns.forEach(column => {
          if (column.fieldId && value.fieldId === column.fieldId && value.stringValue === column.name) {
            column.tasks.push(task);
            placed = true;
          }
        });
      });
      if (!placed) {
        newColumns.find(column => column.name === "PROJECT_KANBAN_UNDEFINED").tasks.push(task);
      }
    });

    // Remove "PROJECT_KANBAN_UNDEFINED" if it is empty
    newColumns = newColumns.filter(column => column.name !== "PROJECT_KANBAN_UNDEFINED" || column.tasks.length > 0);

    return newColumns;
  }, [project, tasks, intl, selectedStatusFieldId, statusFields]);

  useEffect(() => {
    setColumns(generateColumns);
  }, [generateColumns]);


  const selectOptions = statusFields.map(field => ({
    value: field.fieldId,
    label: field.name,
  }));

  return (    
    <Box sx={{ overflowX: "scroll", paddingTop: "4px", maxWidth: "calc(100vw - 340px)" }}>
      {statusFields.length > 0 ? (
        <>
          
          <CustomSelect
            onChange={handleStatusFieldChange}
            value={selectedStatusFieldId}
            options={selectOptions}
            inputLabel={intl.formatMessage({ id: "project.kanban.selectStatusField" })}
            customClass=""
            required
            shrink
            fullWidth={false}
          />
          <Box display="flex" flexDirection="row" justifyContent="flex-start" className="kanban">
            <DndProvider backend={HTML5Backend}>
              {columns.map(column => (
                <KanbanColumn
                  key={column.name}
                  column={column}
                  taskDetailsColumns={taskDetailsColumns}
                  enableEdit={enableEdit}
                  userLookup={userLookup}
                  onTaskAction={onTaskAction}
                />
              ))}
            </DndProvider>
          </Box>
        </>
      ) : (
          <Typography>{intl.formatMessage({ id: "project.kanban.noStatusFields" })}</Typography>
        )}
    </Box>
  );
}

export default Kanban;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery, useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import axios from "axios";
import {
  SITE_SUPPORT_TICKET_FILE_UPLOAD,
  CREATE_SITE_SUPPORT_TICKET,
} from "../../graphql/mutations";
import { GET_WORKSPACES_WITH_SUPPORT } from "../../graphql/queries";
import AccountSupport from "./SupportModalComponents/AccountSupport";
import logger from "../../helpers/logger";
import DraggableModal from "./DraggableModal";
import DraggableModalHead from "./DraggableModal/DraggableModalHead";
import DraggableModalBody from "./DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "95%",
    },
  },
}));

const SupportModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const [siteId, setSiteId] = useState(0);
  const [issueType, setIssueType] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState([]);

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [ticketUploadToken, setTicketUploadToken] = useState("");
  const [ticketUploadUrl, setTicketUploadUrl] = useState("");
  const [ticketFileTokens, setTicketFileToken] = useState([]);
  const [createSiteSupportTicket] = useMutation(CREATE_SITE_SUPPORT_TICKET);
  const [siteSupportTicketFileUpload] = useMutation(
    SITE_SUPPORT_TICKET_FILE_UPLOAD
  );

  const {
    data: workspaceData,
    error: workspaceDataError,
    loading: loadingWorkspaceData,
  } = useQuery(GET_WORKSPACES_WITH_SUPPORT, { skip: !open });

  const [siteOptions, setSiteOptions] = useState([]);

  useEffect(() => {
    if (
      workspaceData &&
      workspaceData.siteSupportContext &&
      workspaceData.siteSupportContext.workspacesWithSupport
    ) {
      var {
        success,
        items,
      } = workspaceData.siteSupportContext.workspacesWithSupport;

      if (success) {
        const tempSiteOptions = items.map(s => {
          return { label: s.name, value: s.siteId };
        });

        setSiteOptions(tempSiteOptions);
      } else {
        handleWorkspaceDataError();
      }
    }
  }, workspaceData);

  useEffect(() => {
    if (workspaceDataError) {
      handleWorkspaceDataError();
    }
  }, workspaceDataError);

  const handleWorkspaceDataError = () => {
    alert.error(<FormattedMessage id="support.error.genericError" />);
    handleClose();
  };

  const handleRemoveUploadedFile = file => {
    const tempUploadedFiles = uploadedFile.filter(
      x => x.fileToken !== file.fileToken
    );
    const tempTicketFileTokens = ticketFileTokens.filter(
      fileToken => fileToken !== file.fileToken
    );
    setUploadedFile(tempUploadedFiles);
    setTicketFileToken(tempTicketFileTokens);
  };

  const handleOnSelectUploadFile = async event => {
    const { target } = event || {};
    const { files } = target || {};

    const haveFile = files && files[0];

    if (!haveFile) {
      alert.error(<FormattedMessage id="support.error.genericError" />);
      return;
    }

    setLoadingUpload(true);

    if (!ticketUploadToken || !ticketUploadUrl) {
      try {
        const res = await siteSupportTicketFileUpload();
        if (
          res &&
          res.data &&
          res.data.siteSupportContext &&
          res.data.siteSupportContext.initSupportTicketFileUpload
        ) {
          const {
            uploadToken,
            uploadUrl,
          } = res.data.siteSupportContext.initSupportTicketFileUpload;

          setTicketUploadToken(uploadToken);
          setTicketUploadUrl(uploadUrl);

          await handleFileUpload(uploadToken, uploadUrl, files);
        } else {
          handleUploadError();
        }
      } catch (ex) {
        handleUploadError(ex);
      }
    } else {
      await handleFileUpload(ticketUploadToken, ticketUploadUrl, files);
    }

    setLoadingUpload(false);
  };

  const handleFileUpload = async (uploadToken, uploadUrl, files) => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("uploadToken", uploadToken);
      const response = await axios.post(`${uploadUrl}`, formData);
      if (
        response &&
        response.data &&
        response.data.success &&
        response.status === 200
      ) {
        const { fileToken } = response.data;
        const newFile = { fileToken, name: files[0].name };

        setUploadedFile([...uploadedFile, newFile]);
        setTicketFileToken([...ticketFileTokens, fileToken]);
      } else {
        handleUploadError();
      }
    } catch (ex) {
      handleUploadError(ex);
    }
  };

  const handleUploadError = ex => {
    logger.error(
      `An error occurred while uploading the file. ${JSON.stringify(ex)}`
    );
    alert.error(<FormattedMessage id="support.error.uploadError" />);
  };

  const handleClearModal = () => {
    setSiteId("");
    setIssueType("");
    setSubject("");
    setMessage("");
    setUploadedFile([]);
    setLoadingUpload(false);
    setTicketFileToken([]);
    setTicketUploadToken("");
  };

  const handleSubmitTicket = async () => {
    const supportTicketInput = {
      message,
      siteId,
      subject,
      type: issueType,
      fileTokens: ticketFileTokens,
      uploadToken: ticketUploadToken,
    };

    if (!ticketUploadToken) {
      delete supportTicketInput.uploadToken;
      delete supportTicketInput.fileTokens;
    }

    try {
      const res = await createSiteSupportTicket({
        variables: { supportTicketInput },
      });

      if (
        res &&
        res.data &&
        res.data.siteSupportContext &&
        res.data.siteSupportContext.createSupportTicket
      ) {
        const { success } = res.data.siteSupportContext.createSupportTicket;
        if (success) {
          alert.success(<FormattedMessage id="support.supportTicketSuccess" />);
          handleClearModal();
          handleClose();
        } else {
          alert.error(<FormattedMessage id="support.error.failedToCreate" />);
        }
      } else {
        alert.error(<FormattedMessage id="support.error.failedToCreate" />);
      }
    } catch (ex) {
      alert.error(<FormattedMessage id="support.error.failedToCreate" />);
    }
  };

  const ticketTypes = {
    ERROR_REPORT: "ERROR_REPORT",
    QUESTION: "QUESTION",
    POINT_OF_VIEW: "POINT_OF_VIEW",
  };

  const issueTypeOptions = [
    {
      label: intl.formatMessage({
        id: "support.errorReport",
      }),
      value: ticketTypes.ERROR_REPORT,
    },
    {
      label: intl.formatMessage({
        id: "support.question",
      }),
      value: ticketTypes.QUESTION,
    },
    {
      label: intl.formatMessage({
        id: "support.pointOfView",
      }),
      value: ticketTypes.POINT_OF_VIEW,
    },
  ];

  useEffect(() => {
    if (open) {
      handleClearModal();
    }
  }, [open]);

  return (
    !(loadingWorkspaceData || workspaceDataError) && (
      <DraggableModal
        open={open}
        handleClose={handleClose}
        customClass={classes.modal}
      >
        <DraggableModalHead
          handleClose={handleClose}
          title={<FormattedMessage id="account.support" />}
        />
        <DraggableModalBody>
          <AccountSupport
            siteOptions={siteOptions}
            setSiteId={setSiteId}
            subject={subject}
            setSubject={setSubject}
            message={message}
            setMessage={setMessage}
            issueTypeOptions={issueTypeOptions}
            setIssueType={setIssueType}
            uploadedFile={uploadedFile}
            handleRemoveUploadedFile={handleRemoveUploadedFile}
            loadingUpload={loadingUpload}
            handleOnSelectUploadFile={handleOnSelectUploadFile}
            handleSubmitTicket={handleSubmitTicket}
            issueType={issueType}
            siteId={siteId}
          />
        </DraggableModalBody>
      </DraggableModal>
    )
  );
};

SupportModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SupportModal;

import React, { Fragment, memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import FolderItem from "./FolderItem";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    padding: 5,
    overflowY: "auto",
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
    },
  },
  treeView: {
    flexGrow: 1,
    "& .MuiSvgIcon-root": {
      width: "16px",
      height: "16px",
    },
  },
}));

const getLastNode = (nodes) => (Array.isArray(nodes) && nodes.length > 0 ? nodes[nodes.length - 1] : undefined);

const ManageFoldersTree = ({
  disabled,
  folderRights,
  folderTree,
  handleCheckboxChange,
  handleOpenNode,
  onFolderSelect,
  openedNodes,
  setFolderTree,
  setOpenedNodes,
}) => {
  const classes = useStyles();
  const [folderTreeRoots, setFolderTreeRoots] = useState([]);
  const [defaultExpanded] = useState(openedNodes);
  const [defaultSelected] = useState(getLastNode(openedNodes));

  useEffect(() => {
    const newFolderTreeRoots = Object.values(folderTree).filter(x => x.isRoot);
    setFolderTreeRoots(newFolderTreeRoots);
  }, [folderTree]);

  const handleOnNodeSelect = (e, nodeIds) => {
    onFolderSelect?.(nodeIds);
  };

  const handleOnNodeToggle = (e, nodeIds) => {
    setOpenedNodes(nodeIds);
  };

  return (
    <TreeView
      className={classes.treeView}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={defaultExpanded}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultSelected={defaultSelected}
      disabledItemsFocusable={false}
      disableSelection={disabled}
      multiSelect={false}
      onNodeSelect={handleOnNodeSelect}
      onNodeToggle={handleOnNodeToggle}
    >
      {folderTreeRoots.map((x, index) => (
        <Fragment key={`folder-tree-roots-${x.folderId}`}>
          <FolderItem
            key={`folder-item-${x.folderId}`}
            disabled={disabled}
            folder={folderTree[`f_${x.folderId}`]}
            folderRights={folderRights}
            folderTree={folderTree}
            handleCheckboxChange={handleCheckboxChange}
            handleOpenNode={handleOpenNode}
            setFolderTree={setFolderTree}
          />
        </Fragment>
      ))}
    </TreeView>
  );
};

ManageFoldersTree.defaultProps = {
  disabled: false,
  handleCheckboxChange: () => { },
  handleOpenNode: () => { },
  onFolderSelect: () => { },
  openedNodes: [],
  selectedNode: null,
  setFolderTree: () => { },
  setOpenedNodes: () => { },
};

ManageFoldersTree.propTypes = {
  disabled: PropTypes.bool,
  folderTree: PropTypes.shape({}).isRequired,
  handleCheckboxChange: PropTypes.func,
  handleOpenNode: PropTypes.func,
  onFolderSelect: PropTypes.func,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  selectedNode: PropTypes.shape({}),
  setFolderTree: PropTypes.func,
  setOpenedNodes: PropTypes.func,
};

export default memo(ManageFoldersTree);

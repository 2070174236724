import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import ReviewImage from "../../../assets/img/review-icon.svg";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
    textAlign: "center",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
    width: 90,
  },
}));

const AgreementModal = ({ open, setOpen, onClick }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title="Supplier Agreement"
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <img src={ReviewImage} alt="Review" className={classes.img} />
        Do you agreee with the terms and conditions of this agreement?
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnCancel} onClick={handleClose}>
            <FormattedMessage id="btn.no" />
          </Button>
          <Button className={classes.btn} onClick={onClick}>
            <FormattedMessage id="btn.yes" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

AgreementModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AgreementModal;

import React from "react";
import { SvgIcon } from "@mui/material";

export function AutoAwesomeOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m19 9 1.25-2.75L23 5l-2.75-1.25L19 1l-1.25 2.75L15 5l2.75 1.25L19 9zm-7.5.5L9 4 6.5 9.5 1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5zM19 15l-1.25 2.75L15 19l2.75 1.25L19 23l1.25-2.75L23 19l-2.75-1.25L19 15z" />
    </SvgIcon>
  );
}

export function BarChartOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M4 9h4v11H4zm12 4h4v7h-4zm-6-9h4v16h-4z" />
    </SvgIcon>
  );
}

export function LocalOfferOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m21.41 11.58-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01 4 11V4h7v-.01l9 9-7 7.02z" />
      <circle cx="6.5" cy="6.5" r="1.5" />
    </SvgIcon>
  );
}

export function ShieldOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2 4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25 6 2.25v4.7z" />
    </SvgIcon>
  );
}

export function TrendingUpOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6h-6z" />
    </SvgIcon>
  );
}

export function WarningAmberOutlinedIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 5.99 19.53 19H4.47L12 5.99M12 2 1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
    </SvgIcon>
  );
}

import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Checkbox } from "@mui/material";

const RightsCheckboxes = ({
  disabled,
  disableWrite,
  onChange,
  read,
  resourceId,
  sx,
  write,
}) => {
  const handleChange = e => onChange?.(e, resourceId);

  const handleClick = e => e.stopPropagation();

  return (
    <Box component="span" sx={sx}>
      <Checkbox
        checked={write}
        color="primary"
        disabled={disabled || disableWrite}
        name="write"
        onChange={handleChange}
        onClick={handleClick}
      />
      <Checkbox
        checked={read}
        color="primary"
        disabled={disabled}
        name="read"
        onChange={handleChange}
        onClick={handleClick}
      />
    </Box>
  );
};

RightsCheckboxes.defaultProps = {
  disabled: false,
  disableWrite: false,
  read: false,
  write: false,
};

RightsCheckboxes.propTypes = {
  disabled: PropTypes.bool,
  disableWrite: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  read: PropTypes.bool,
  resourceId: PropTypes.number.isRequired,
  write: PropTypes.bool,
};

export default memo(RightsCheckboxes);

import { gql } from '@apollo/client';
import SiteMenuEditItem from "../fragments/SiteMenuEditItem";

const getSiteMenu = gql`
  query GetSiteMenu($siteRoleId: Int!) {
    siteRoles {
      getSiteMenu(siteRoleId: $siteRoleId) {
        availablePages
        defaultStartPage
        siteMenus {
          ...ChildMenusRecursive
          ...SiteMenuEditItem
        }
      }
    }
  }
  ${SiteMenuEditItem}
`;

export default getSiteMenu;

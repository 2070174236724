import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { PrimaryButton } from "../../common";

const useStyles = makeStyles(theme => ({
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
}));

const ProductOrderButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <PrimaryButton onClick={onClick}>
      <FormattedMessage id="product.btnOrder" />
      <Icon className={clsx(["fa fa-shopping-cart", classes.btnIcon])} />
    </PrimaryButton>
  );
};

ProductOrderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ProductOrderButton;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    fontSize: 12,
    backgroundColor: "#fff",
  },
  select: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  item: {
    fontSize: 12,
  },
  sortLabel: {
    fontSize: 12,
    marginRight: 10,
  },
  sortIcons: {
    marginLeft: 5,
  },
  sortIconWrap: {
    padding: 6,
  },
  sortIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    opacity: 0.4,
    "&:hover": {
      opacity: 1,
    },
  },
  sortIconActive: {
    opacity: 1,
  },
}));

const SortSelect = ({
  setSortfield,
  sortfield,
  options,
  ascending,
  setAscending,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span className={classes.sortLabel}>
        <FormattedMessage id="context.sorting" />
      </span>
      <FormControl variant="outlined">
        <Select
          onChange={event => setSortfield(event.target.value)}
          value={sortfield}
          classes={{ root: classes.formControl, select: classes.select }}
        >
          {options.map(x => (
            <MenuItem className={classes.item} value={x.value} key={x.value}>
              {x.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <span className={classes.sortIcons}>
        <IconButton
          className={classes.sortIconWrap}
          onClick={() => setAscending(true)}
        >
          <Icon
            className={clsx([
              "fa fa-sort-alpha-up",
              classes.sortIcon,
              ascending ? classes.sortIconActive : null,
            ])}
          />
        </IconButton>

        <IconButton
          className={classes.sortIconWrap}
          onClick={() => setAscending(false)}
        >
          <Icon
            className={clsx([
              "fa fa-sort-alpha-down",
              classes.sortIcon,
              !ascending ? classes.sortIconActive : null,
            ])}
          />
        </IconButton>
      </span>
    </div>
  );
};

SortSelect.propTypes = {
  setSortfield: PropTypes.func.isRequired,
  sortfield: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
        .isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  ascending: PropTypes.bool.isRequired,
  setAscending: PropTypes.func.isRequired,
};

export default SortSelect;

import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import { useTermsOfUseContext } from "../../hooks/useTermsOfUseContext";
import Search from "./Search";

const initialState = {
  isOpen: false,
  searchType: "simple", // !!!
};

export const searchContext = createContext(initialState);

const { Provider } = searchContext;

const SearchProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const { isOpen } = state;

  const termsOfUseContext = useTermsOfUseContext();
  const { userMustAcceptTermsOfUse, userMustSetupMfa } = termsOfUseContext;

  useEffect(() => {
    if (isOpen && userMustAcceptTermsOfUse) {
      termsOfUseContext.openDialog();
    }
    else if (isOpen && userMustSetupMfa) {
      termsOfUseContext.openMfaDialog();
    }

  }, [isOpen, userMustAcceptTermsOfUse, userMustSetupMfa]);

  const close = () => {
    setState({ ...state, isOpen: false });
  };

  const open = () => {
    setState({ ...state, isOpen: true });
  };

  const searchType = type => {
    setState({ ...state, searchType: type });
  };

  return (
    <>
      {isOpen && !userMustAcceptTermsOfUse &&
        <Search onClose={close} />
      }
      <Provider value={{ close, open, searchType, state }}>{children}</Provider>
    </>
  );
};

SearchProvider.propsType = {
  children: PropTypes.node.isRequired,
};

export default SearchProvider;

import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { cartInitialize } from "../actions";
import { ADD_PRODUCT } from "../graphql/mutations";

// eslint-disable-next-line import/prefer-default-export
export const useAddToShoppingCart = () => {
  const dispatch = useDispatch();

  const [addProduct, { error, loading }] = useMutation(ADD_PRODUCT);

  const executeAddProduct = async ({ input, onError, onSuccess }) => {
    try {
      const { item, orderGroupId } = input;

      const res = await addProduct({
        variables: {
          item,
          orderGroupId,
        },
      });

      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.addProduct
      ) {

        const {
          result,
          success,
        } = res.data.shoppingCartContext.addProduct;

        if (success) {
          const { groups = [] } = result;
          const orderGroup = groups.find(x => x.orderGroupId === orderGroupId);
          const { items = [], fileGroups = [], oneStreamGroups = [] } = orderGroup || {};

          dispatch(
            cartInitialize({
              fileGroups,
              items,
              oneStreamGroups
            })
          );

          onSuccess?.();
        } else {
          onError?.();
        }

      }
      else {
        onError?.();
      }

    } catch (e) {
      onError?.();
    }
  };

  return [executeAddProduct, { error, loading }];
};

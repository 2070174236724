import React from "react";
import { useIntl } from "react-intl";
import { MuiChipsInput } from 'mui-chips-input'
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { validateEmail } from "../../../helpers/validators";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiChipsInput-Chip": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
      fontSize: "0.7em",
      textTransform: "uppercase",
      "& .MuiChip-deleteIcon": {
        color: theme.palette.common.white,
        height: "1em !important",
      },
      "&:hover": {
        opacity: 0.4,
      },
    },
  },
}));

const EmailChipInput = ({ value, setChips, label }) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleAddChip = (chipValue, chipIndex) => {
    const newChips = splitChipInputValue(chipValue);
    if (newChips.every(c => validateEmail(c))) {
      setChips(oldChips => [...oldChips, ...newChips]);
    }
  };

  const handleDeleteChip = (chipValue, chipIndex) => {
    const newChips = value.filter(c => c !== chipValue);
    setChips(newChips);
  };

  const splitChipInputValue = (chipValue) => {
    return chipValue.split(/,|;/).map(n => n.trim()).filter(n => n.length > 0);
  }

  const validateChip = (chipValue) => {
    const newChips = splitChipInputValue(chipValue);
    return {
      isError: newChips.some(n => !validateEmail(n)),
      textError: intl.formatMessage({ id: "siteUsers.enterEmailAddressInvalid" }),
    };
  };

  return (
    <MuiChipsInput
      addOnWhichKey={["Enter", "Tab", ",", ";"]}
      className={classes.root}
      disableDeleteOnBackspace
      disableEdition
      fullWidth
      hideClearAll
      margin="dense"
      addOnBlur
      onAddChip={handleAddChip}
      onDeleteChip={handleDeleteChip}
      placeholder={null}
      size="small"
      validate={validateChip}
      value={value}
      label={label}
    />
  );
};

EmailChipInput.defaultProps = {
  setChips: null,
  value: [],
};

EmailChipInput.propTypes = {
  setChips: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default EmailChipInput;

import React from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Button, Grid, Icon } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import ExpansionDefault from "../../../../common/ExpansionDefault";
import MetadataRow from "./MetadataRow";

const useStyles = makeStyles(theme => ({
  customForm: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputAdornment-root button": {
      padding: 5,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 10,
      paddingTop: 6,
      paddingBottom: 6,
      lineHeight: "21px",
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 10,
    },
  },
  btnAdd: {
    textTransform: "none",
    color: theme.palette.common.link,
    fontSize: 11,
    padding: 0,
    marginTop: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    fontSize: 10,
    marginRight: 5,
    color: theme.palette.common.link,
  },
}));

const CustomMetadata = ({ handleAddCustomMetadata }) => {
  const classes = useStyles();
  const intl = useIntl();
  const {
    customMetadata: { metaIndex },
  } = useSelector(state => state.api);

  return (
    <ExpansionDefault
      label={<FormattedMessage id="metadata.customMetadata" />}
      panelContent="customMetadataContent"
      panelHeader="customMetadataHeader"
      defaultExpanded
    >
      <Grid container spacing={1} className={classes.customForm}>
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.customForm}>
            <Grid item xs={2}>
              <div>{intl.formatMessage({ id: "metadata.display" })}</div>
            </Grid>
            <Grid item xs={4}>
              <div>{intl.formatMessage({ id: "metadata.metaLabel" })}</div>
            </Grid>
            <Grid item xs={4}>
              <div>{intl.formatMessage({ id: "metadata.metaValue" })}</div>
            </Grid>
          </Grid>
        </Grid>
        {metaIndex.map((meta, index) => {
          return (
            <Grid item xs={12} key={meta}>
              <MetadataRow metaIndex={index} />
            </Grid>
          );
        })}

        <Box>
          <Button
            className={classes.btnAdd}
            onClick={() => handleAddCustomMetadata()}
          >
            <Icon className={clsx(["fa fa-plus", classes.addIcon])} />
            <FormattedMessage id="btn.add" />
          </Button>
        </Box>
      </Grid>
    </ExpansionDefault>
  );
};

CustomMetadata.propTypes = {
  handleAddCustomMetadata: PropTypes.func.isRequired,
};

export default CustomMetadata;

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/tables";
import { SubscriptionLevelLabel as FormattedSubscriptionLevel } from "../../../common/SubscriptionLevelLabel";
import { SUBSCRIPTION_LEVEL } from "../../../../constant/subscriptionLevel";

const Details = ({ className, open, rows }) => {
  const intl = useIntl();

  const renderExternalUserLabel = ({ isExternalUser }) => {
    return intl.formatMessage({
      id: isExternalUser ? "common.yes" : "common.no",
    });
  };

  return (
    open && (
      <TableContainer className={className}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.subscriptionLevels.username" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.subscriptionLevels.externalUser" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.subscriptionLevels.subscriptionLevel" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.username}>
                <TableCell>{row.username}</TableCell>
                <TableCell>{renderExternalUserLabel(row)}</TableCell>
                <TableCell>
                  <FormattedSubscriptionLevel
                    subscriptionLevel={row.subscriptionLevel}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

Details.defaultProps = {
  className: "",
  open: false,
  rows: [],
};

Details.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      isExternalUser: PropTypes.bool.isRequired,
      subscriptionLevel: PropTypes.oneOf(Object.values(SUBSCRIPTION_LEVEL))
        .isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
};

export default Details;

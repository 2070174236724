import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FormattedMessage } from "react-intl";
import InputTextInline from "../common/FormControl/InputTextInline";
import DateInline from "../common/FormControl/DateInline";
import TextareaInline from "../common/FormControl/TextareaInline";
import PrintFileRow from "./PrintFileRow";
import SupplierCheckboxes from "./SupplierCheckboxes";

const useStyles = makeStyles(theme => ({
  btnMain: {
    fontSize: 12,
    fontWeight: 600,
  },
  btnWrap: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
    },
  },
  buttonRow: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const SendRequestColumn = ({
  validDynamicForm,
  validDeliveryForm,
  setSelectedResources,
  selectedResources,
  suppliers,
  handleOnSelectSuppliers,
  onSubmit,
  uploadToken,
  uploadedFiles,
  setUploadedFiles,
  deliveryDate,
  setDeliveryDate,
  message,
  setMessage,
  replyToEmail,
  setReplyToEmail,
  selectedSuppliers,
  RFPUploadFileUrl,
}) => {
  const classes = useStyles();

  const onMessageChange = event => {
    setMessage(event.target.value);
  };

  const onReplyToEmailChange = event => {
    setReplyToEmail(event.target.value);
  };

  return (
    <Grid item container alignContent="flex-start" xs={12} md={6} pl={3} pr={3} pt={2.5} pb={2.5}>
      <DateInline
        label={<FormattedMessage id="rfp.requestedDeliveryDate" />}
        date={deliveryDate}
        setDate={setDeliveryDate}
      />
      <TextareaInline
        label={<FormattedMessage id="rfp.enterYourRequest" />}
        value={message}
        onChange={onMessageChange}
      />
      <PrintFileRow
        label={<FormattedMessage id="rfp.printFile" />}
        setSelectedResources={setSelectedResources}
        selectedResources={selectedResources}
        uploadToken={uploadToken}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        RFPUploadFileUrl={RFPUploadFileUrl}
      />
      <SupplierCheckboxes
        label={<FormattedMessage id="rfp.sendRequestTo" />}
        handleOnSelectSuppliers={handleOnSelectSuppliers}
        suppliers={suppliers}
        selectedSuppliers={selectedSuppliers}
      />
      <InputTextInline
        label={<FormattedMessage id="rfp.replyToTheFollowingEmailAddress" />}
        value={replyToEmail}
        onChange={onReplyToEmailChange}
      />
      <Grid
        container
        justifyContent="flex-end"
        direction="row"
        className={classes.btnWrap}
      >
        <Grid item container xs={12} md={12} mt={0.5} mb={0.5} className={classes.buttonRow}>
          <Button
            disabled={!validDynamicForm || !validDeliveryForm}
            variant="contained"
            color="primary"
            className={classes.btnMain}
            onClick={onSubmit}
          >
            <FormattedMessage id="rfp.sendRequest" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

SendRequestColumn.propTypes = {
  validDynamicForm: PropTypes.bool.isRequired,
  validDeliveryForm: PropTypes.bool.isRequired,
  handleOnSelectSuppliers: PropTypes.func.isRequired,
  setSelectedResources: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      changedBy: PropTypes.string.isRequired,
      changedDate: PropTypes.string.isRequired,
      uploadedDate: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      supplierId: PropTypes.number.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  uploadToken: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      fileToken: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedSuppliers: PropTypes.arrayOf(PropTypes.number).isRequired,
  deliveryDate: PropTypes.string.isRequired,
  setDeliveryDate: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  replyToEmail: PropTypes.string.isRequired,
  setReplyToEmail: PropTypes.func.isRequired,
  RFPUploadFileUrl: PropTypes.string.isRequired,
};

export default SendRequestColumn;

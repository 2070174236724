import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ContainedSelect } from "../../../common";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: 12,
    },
  },
}));

const MonitorSettingsForm = ({ label, options, onChange, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <ContainedSelect
        label={label}
        onChange={onChange}
        options={options}
        value={value || ""}
        required
        dataTut="step2"
      />
    </Box>
  );
};

MonitorSettingsForm.defaultProps = {
  options: [],
};

MonitorSettingsForm.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default MonitorSettingsForm;

import { gql } from '@apollo/client';
import TaskComment from "../../fragments/Projects/TaskComment";
import ErrorResultFragment from "../../fragments/ErrorResult";

const SAVE_COMMENT = gql`mutation saveComment($comment: SaveTaskCommentInput) {
	projects {
		saveTaskComment(input: $comment) {
			success
			errorResult {
				...ErrorResultFields
			}
			result {
				...TaskComment
			}
		}
	}
}
${ErrorResultFragment}
${TaskComment}
`;

export default SAVE_COMMENT;
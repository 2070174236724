import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, TableBody, Box } from "@mui/material";
import SectionBox from "../../common/FilesTable/SectionBox";
import FolderSection from "./FolderSection";
import FilesSection from "./FilesSection";
import FolderFileTableHeader from "./FolderFileTableHeader";
import AllFilesRow from "./AllFilesRow";

const FolderFileList = ({
  checkedFolders,
  folder,
  folderPropertyFormValues,
  handleCheckboxChange,
  handleCheckboxChangeFolder,
  productionSettings,
  propertyFormValues,
  resources,
  selectedFoldersProperties,
  selectedResources,
  setCheckedFolders,
  setFolderPropertyFormValues,
  setPropertyFormValues,
  folderMandatoryFields,
  validateFolderProperties,
}) => {
  const removeFolderFromCheckedFolders = () => {
    if (checkedFolders.includes(folder.folderId)) {
      const temp = checkedFolders.filter(x => x !== folder.folderId);
      setCheckedFolders(temp);
    }
  };

  const addFolderToCheckedFolders = () => {
    if (!checkedFolders.includes(folder.folderId)) {
      setCheckedFolders([...checkedFolders, folder.folderId]);
    }
  };

  useEffect(() => {
    if (
      (propertyFormValues &&
        Array.isArray(propertyFormValues) &&
        propertyFormValues.length > 0) ||
      (folderPropertyFormValues &&
        Array.isArray(folderPropertyFormValues) &&
        folderPropertyFormValues.length > 0)
    ) {
      const folderResourcesFormValues = propertyFormValues.filter(x =>
        resources.some(y => y.id === x.resourceId)
      );

      const folderFormValues = folderPropertyFormValues.find(
        x => x.folderId === folder.folderId
      );

      // if no folder form values, remove folder from checked folders
      if (!folderResourcesFormValues && !folderPropertyFormValues) {
        removeFolderFromCheckedFolders();
      } else if (folderFormValues && folderFormValues.isIncluded) {
        addFolderToCheckedFolders();
      } else if (Array.isArray(folderResourcesFormValues)) {
        if (folderResourcesFormValues.length < 1) {
          removeFolderFromCheckedFolders();
        } else {
          let hasProperties = false;
          folderResourcesFormValues.forEach(x => {
            const { isNotifyEnabled } = x;
            if (!hasProperties) {
              if (isNotifyEnabled) {
                hasProperties = true;
              }
            }
          });
          if (!hasProperties) {
            removeFolderFromCheckedFolders();
          } else {
            addFolderToCheckedFolders();
          }
        }
      }
    } else {
      removeFolderFromCheckedFolders();
    }
  }, [propertyFormValues, folderPropertyFormValues, checkedFolders]);

  // const callbackFileNotifyChange = (event, resourceId, folderId) => {
  //   const exist = propertyFormValues.find(x => x.resourceId === resourceId);
  //   const { checked } = event.target;

  //   if (!exist) {
  //     let newItem = {
  //       resourceId,
  //       isNotifyEnabled: checked,
  //       folderId,
  //     };
  //     if (checked) {
  //       newItem = {
  //         ...newItem,
  //         productionSettings: {
  //           orderQuantity: 1,
  //         },
  //       };
  //     }
  //     const newPropertyFormValues = [...propertyFormValues, newItem];
  //     setPropertyFormValues(newPropertyFormValues);
  //   } else {
  //     const newPropertyFormValues = propertyFormValues.map(x => {
  //       if (resourceId === x.resourceId) {
  //         let newItem = { ...x, isNotifyEnabled: checked };
  //         if (checked) {
  //           const newOrderQuantity =
  //             newItem &&
  //             newItem.productionSettings &&
  //             newItem.productionSettings.orderQuantity
  //               ? newItem.productionSettings.orderQuantity
  //               : 1;

  //           newItem = {
  //             ...newItem,
  //             productionSettings: {
  //               ...newItem.productionSettings,
  //               orderQuantity: newOrderQuantity,
  //             },
  //           };
  //         }
  //         return newItem;
  //       }
  //       return { ...x };
  //     });

  //     setPropertyFormValues(newPropertyFormValues);
  //   }
  // };

  const handleAllNotifySwitch = event => {
    const { checked } = event.target;

    const newPropertyFormValues = resources.map(x => {
      const fileProperty = propertyFormValues.find(y => x.id === y.resourceId);

      if (fileProperty) {
        const { productionSettings: fileProductionSettings } =
          fileProperty || {};
        const { orderQuantity } = fileProductionSettings || {};

        if (orderQuantity < 1 && checked) {
          return {
            ...fileProperty,
            productionSettings: { ...fileProductionSettings, orderQuantity: 1 },
            isNotifyEnabled: checked,
          };
        }

        return { ...fileProperty, isNotifyEnabled: checked };
      }

      if (checked) {
        return {
          resourceId: x.id,
          productionSettings: { orderQuantity: 1 },
          isNotifyEnabled: checked,
        };
      }
      return { resourceId: x.id, isNotifyEnabled: checked };
    });

    setPropertyFormValues(newPropertyFormValues);
  };

  const onChangeQuantity = ({ resourceId, value, folderId }) => {
    const { quantityProperty } = productionSettings || {};
    const { quantityType } = quantityProperty || {};

    // eslint-disable-next-line no-shadow
    let orderQuantity = 0;
    // eslint-disable-next-line no-shadow
    let quantityFixedValueId = null;

    if (quantityType === "FIXED") {
      quantityFixedValueId = Number(value);
    } else {
      const noZeroValue = value < 0 ? 0 : value;
      orderQuantity = Number(noZeroValue);
    }

    const fileIndex = propertyFormValues.findIndex(
      x => x.resourceId === resourceId
    );

    let newPropertyFormValues = [...propertyFormValues];
    let monitoredFileTemp = { resourceId, folderId };
    if (fileIndex !== -1) {
      monitoredFileTemp = {
        ...monitoredFileTemp,
        ...propertyFormValues[fileIndex],
      };
      newPropertyFormValues.splice(fileIndex, 1);
    }

    monitoredFileTemp = {
      ...monitoredFileTemp,
      productionSettings: {
        ...monitoredFileTemp.productionSettings,
        orderQuantity,
        quantityFixedValueId,
      },
    };

    if (quantityType !== "FIXED") {
      // const quantityValue = Number(value);
      // const isPositive = quantityValue > 0;

      monitoredFileTemp = {
        ...monitoredFileTemp,
        // isNotifyEnabled: isPositive,
      };
    }

    newPropertyFormValues = [...newPropertyFormValues, monitoredFileTemp];
    setPropertyFormValues(newPropertyFormValues);
  };

  const handleRemoveFileSettings = ({ resourceId }) => {
    const newPropertyFormValues = propertyFormValues.filter(
      x => x.resourceId !== resourceId
    );

    setPropertyFormValues(newPropertyFormValues);
  };

  const handleRemoveFolderSettings = ({ folderId }) => {
    const newFolderPropertyFormValues = folderPropertyFormValues.filter(
      x => x.folderId !== folderId
    );
    if (selectedFoldersProperties.includes(folderId)) {
      const newPropertyFormValues = propertyFormValues.filter(
        x => !selectedResources.includes(x.resourceId)
      );
      setPropertyFormValues(newPropertyFormValues);
    }
    setFolderPropertyFormValues(newFolderPropertyFormValues);
  };

  // const handleFolderNotifyChange = (event, folderId, folderName) => {
  //   const { checked } = event.target;

  //   const isFolderHasProperties = folderPropertyFormValues.find(
  //     x => x.folderId === folderId
  //   );

  //   if (isFolderHasProperties) {
  //     const newFolderPropertyFormValues = folderPropertyFormValues.map(x => {
  //       if (x.folderId === folderId) {
  //         if (checked) {
  //           return {
  //             ...x,
  //             name: folderName,
  //             isIncluded: checked,
  //             productionSettings: {
  //               ...x.productionSettings,
  //               orderQuantity: 1,
  //             },
  //           };
  //         }
  //         return {
  //           ...x,
  //           name: folderName,
  //           isIncluded: checked,
  //           productionSettings: {
  //             ...x.productionSettings,
  //           },
  //         };
  //       }

  //       return { ...x };
  //     });
  //     setFolderPropertyFormValues(newFolderPropertyFormValues);
  //   } else {
  //     let newFolderProperty = {
  //       folderId,
  //       isIncluded: checked,
  //       name: folderName,
  //     };
  //     let newResourcesProperty = [];
  //     if (checked) {
  //       newFolderProperty = {
  //         ...newFolderProperty,
  //         productionSettings: {
  //           orderQuantity: 1,
  //         },
  //       };

  //       newResourcesProperty = resources.map(x => {
  //         const item = {
  //           resourceId: x.id,
  //           isIncluded: checked,
  //           folderId,
  //           name: folderName,
  //         };

  //         return item;
  //       });
  //     }
  //     setPropertyFormValues(newResourcesProperty);
  //     setFolderPropertyFormValues([
  //       ...folderPropertyFormValues,
  //       newFolderProperty,
  //     ]);
  //   }
  // };

  const callbackFileNotifyChange = (event, resourceId, folderId) => {
    const exist = propertyFormValues.find(x => x.resourceId === resourceId);
    const { checked } = event.target;

    if (!exist) {
      const isFolderHasProperties = folderPropertyFormValues.find(
        x => x.folderId === folderId
      );
      const { productionSettings: folderProductionSettings } =
        isFolderHasProperties || {};
      const { propertyDataValues: folderPropertyDataValues = [] } =
        folderProductionSettings || {};
      let newItem = {};
      if (checked && validateFolderProperties(folderPropertyDataValues)) {
        newItem = {
          resourceId,
          isIncluded: checked,
          folderId,
          productionSettings: {
            orderQuantity: 1,
          },
        };
      }

      if (!checked) {
        newItem = {
          resourceId,
          isIncluded: checked,
          folderId,
          productionSettings: {
            orderQuantity: 1,
          },
        };
      }

      const newPropertyFormValues = [...propertyFormValues, newItem];
      setPropertyFormValues(newPropertyFormValues);
    } else {
      const isFileHasProperties = propertyFormValues.find(
        x => x.resourceId === resourceId
      );

      const { productionSettings: fileProductionSettings } =
        isFileHasProperties || {};

      const { propertyDataValues: filePropertyDataValues = [] } =
        fileProductionSettings || {};

      const newPropertyFormValues = propertyFormValues.map(x => {
        if (resourceId === x.resourceId) {
          let newItem = { ...x };
          if (checked && validateFolderProperties(filePropertyDataValues)) {
            const newOrderQuantity =
              newItem &&
              newItem.productionSettings &&
              newItem.productionSettings.orderQuantity
                ? newItem.productionSettings.orderQuantity
                : 1;

            newItem = {
              ...newItem,
              isIncluded: checked,
              productionSettings: {
                ...newItem.productionSettings,
                orderQuantity: newOrderQuantity,
              },
            };
          } else {
            const newOrderQuantity =
              newItem &&
              newItem.productionSettings &&
              newItem.productionSettings.orderQuantity
                ? newItem.productionSettings.orderQuantity
                : 1;

            newItem = {
              ...newItem,
              isIncluded: checked,
              productionSettings: {
                ...newItem.productionSettings,
                orderQuantity: newOrderQuantity,
              },
            };
          }

          return newItem;
        }
        return { ...x };
      });

      setPropertyFormValues(newPropertyFormValues);
    }
  };

  const handleFolderInclude = (event, folderId, folderName) => {
    const { checked } = event.target;

    const isFolderHasProperties = folderPropertyFormValues.find(
      x => x.folderId === folderId
    );

    const { productionSettings: folderProductionSettings } =
      isFolderHasProperties || {};
    const { propertyDataValues = [] } = folderProductionSettings || {};

    if (checked && validateFolderProperties(propertyDataValues)) {
      const newFolderPropertyFormValues = folderPropertyFormValues.map(x => {
        if (x.folderId === folderId) {
          if (checked) {
            return {
              ...x,
              name: folderName,
              isIncluded: checked,
              productionSettings: {
                ...x.productionSettings,
                orderQuantity: 1,
              },
            };
          }
          return {
            ...x,
            name: folderName,
            isIncluded: checked,
            productionSettings: {
              ...x.productionSettings,
            },
          };
        }

        return { ...x };
      });
      setFolderPropertyFormValues(newFolderPropertyFormValues);
    } else {
      const newFolderPropertyFormValues = folderPropertyFormValues.map(x => {
        if (x.folderId === folderId) {
          if (checked) {
            return {
              ...x,
              name: folderName,
              isIncluded: checked,
              productionSettings: {
                ...x.productionSettings,
                orderQuantity: 1,
              },
            };
          }
          return {
            ...x,
            name: folderName,
            isIncluded: checked,
            productionSettings: {
              ...x.productionSettings,
            },
          };
        }

        return { ...x };
      });
      setFolderPropertyFormValues(newFolderPropertyFormValues);
    }
    // else {
    //   let newFolderProperty = {
    //     folderId,
    //     isIncluded: checked,
    //     name: folderName,
    //   };
    //   let newResourcesProperty = [];
    //   if (checked) {
    //     newFolderProperty = {
    //       ...newFolderProperty,
    //       productionSettings: {
    //         orderQuantity: 1,
    //       },
    //     };

    //     newResourcesProperty = resources.map(x => {
    //       const item = {
    //         resourceId: x.id,
    //         isIncluded: checked,
    //         folderId,
    //         name: folderName,
    //       };

    //       return item;
    //     });
    //   }
    //   setPropertyFormValues(newResourcesProperty);
    //   setFolderPropertyFormValues([
    //     ...folderPropertyFormValues,
    //     newFolderProperty,
    //   ]);
    // }
  };
  const handleFileNotifyChange = useCallback(
    (event, resourceId, folderId) => {
      callbackFileNotifyChange(event, resourceId, folderId);
    },
    [propertyFormValues]
  );

  const handleFolderQuantityChange = ({ folderId, value }) => {
    const isFolderHasProperties = folderPropertyFormValues.find(
      x => x.folderId === folderId
    );

    const quantityValue = Number(value);
    const isPositiveValue = quantityValue > 0;

    if (isFolderHasProperties) {
      const newFolderPropertyFormValues = folderPropertyFormValues.map(x => {
        if (x.folderId === folderId) {
          return {
            ...x,
            // isNotifyEnabled: isPositiveValue,
            productionSettings: {
              ...x.productionSettings,
              orderQuantity: quantityValue,
            },
          };
        }
        return { ...x };
      });

      setFolderPropertyFormValues(newFolderPropertyFormValues);
    } else {
      let newFolderProperty = { folderId, isNotifyEnabled: isPositiveValue };
      newFolderProperty = {
        ...newFolderProperty,
        productionSettings: {
          orderQuantity: quantityValue,
        },
      };

      setFolderPropertyFormValues([
        ...folderPropertyFormValues,
        newFolderProperty,
      ]);
    }
  };

  return (
    <SectionBox>
      <Box>
        <Table size="small">
          <FolderFileTableHeader
            folder={folder}
            setCheckedFolders={setCheckedFolders}
            checkedFolders={checkedFolders}
            folderPropertyFormValues={folderPropertyFormValues}
            setFolderPropertyFormValues={setFolderPropertyFormValues}
            propertyFormValues={propertyFormValues}
            setPropertyFormValues={setPropertyFormValues}
          />
          <TableBody>
            <FolderSection
              folder={folder}
              folderMandatoryFields={folderMandatoryFields}
              folderPropertyFormValues={folderPropertyFormValues}
              handleCheckboxChangeFolder={handleCheckboxChangeFolder}
              handleFolderInclude={handleFolderInclude}
              handleRemoveFolderSettings={handleRemoveFolderSettings}
              onChangeQuantity={handleFolderQuantityChange}
              productionSettings={productionSettings}
              selectedFoldersProperties={selectedFoldersProperties}
              setFolderPropertyFormValues={setFolderPropertyFormValues}
            />
            <AllFilesRow handleAllNotifySwitch={handleAllNotifySwitch} />
            <FilesSection
              folder={folder}
              folderMandatoryFields={folderMandatoryFields}
              folderPropertyFormValues={folderPropertyFormValues}
              handleCheckboxChange={handleCheckboxChange}
              handleFileNotifyChange={handleFileNotifyChange}
              handleRemoveFileSettings={handleRemoveFileSettings}
              onChangeQuantity={onChangeQuantity}
              productionSettings={productionSettings}
              propertyFormValues={propertyFormValues}
              resources={resources}
              selectedFoldersProperties={selectedFoldersProperties}
              selectedResources={selectedResources}
              validateFolderProperties={validateFolderProperties}
            />
          </TableBody>
        </Table>
      </Box>
    </SectionBox>
  );
};

FolderFileList.defaultProps = {
  folderPropertyFormValues: [],
};

FolderFileList.propTypes = {
  folder: PropTypes.shape({ folderId: PropTypes.number }).isRequired,
  resources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productionSettings: PropTypes.shape({}).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  handleCheckboxChangeFolder: PropTypes.func.isRequired,
  selectedFoldersProperties: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  setFolderPropertyFormValues: PropTypes.func.isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FolderFileList;

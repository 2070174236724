import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { PrimaryButton } from "../../../common/Buttons";

const SaveButton = ({ disabled, hidden, onClick, ...rest }) => {
  if (hidden) return null;

  return (
    <PrimaryButton disabled={disabled} onClick={onClick} {...rest}>
      <FormattedMessage id="btn.save" />
    </PrimaryButton>
  );
};

SaveButton.defaultProps = {
  disabled: false,
  hidden: false,
};

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SaveButton;

import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import ShowHideColumns from '../PageLayoutWithSidebar/ShowHideColumns';

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DynamicTable = ({ columns, data, onRowClick, onSort, onPageChange, tHeight }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([15, 40, { label: 'All', value: -1 }]);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [visibleColumns, setVisibleColumns] = React.useState(() => {
    const initialColumns = {};
    columns.forEach(column => {
      initialColumns[column.field] = !column.hidden;
    });
    return initialColumns;
  });

  const cellLineHeight = { lineHeight: "20px" }; // Line height for a cell. Line height for head is padding*1
  const cellPadding = { padding: "12px" }; //Padding for a cell. Padding for a Head cell is padding*1.25

  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);
  let headerHeight = parseFloat(cellPadding.padding) * 1.25 * 2 + parseFloat(cellLineHeight.lineHeight);;
  let footerHeight = parseFloat(cellPadding.padding) * 2 + parseFloat(cellLineHeight.lineHeight);
  let rowHeight = footerHeight;

  useEffect(() => {
    if (tableRef.current) {
      const height = tableRef.current.clientHeight;
      setTableHeight(height);
      const maxRowsPerPage = Math.floor((height - headerHeight - footerHeight) / rowHeight);
      rowsPerPageOptions.push(maxRowsPerPage);
      setRowsPerPageOptions(sortArrayOptions(rowsPerPageOptions));
      setRowsPerPage(maxRowsPerPage);
      console.log("*** Table", tableHeight);
      console.log("*** head", headerHeight);
      console.log("*** footer", footerHeight);
      console.log("*** maxRows", maxRowsPerPage);
      console.log("*** rowsPerPageOption", rowsPerPageOptions);
    }
  }, []);

  const sortArrayOptions = (arr) => {
    // Sort the array in ascending order
    return arr.sort((a, b) => {
      if (typeof a === 'number' && typeof b === 'number') {
        return a - b; // Sort numbers in ascending order
      } else if (typeof a === 'number' && typeof b !== 'number') {
        return -1; // Place numbers before non-numbers
      } else if (typeof a !== 'number' && typeof b === 'number') {
        return 1; // Place non-numbers after numbers
      } else {
        return 0; // Leave non-numbers in their original order
      }
    });
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleSort = (header) => {
    const isAsc = orderBy === header && order === 'asc';
    setOrderBy(header);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const sortedData = React.useMemo(() => {
    if (!orderBy) return data;

    return [...data].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, orderBy, order]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleColumn = (field) => {
    
    let newState = { ...visibleColumns };
    newState[field] = !visibleColumns[field];

    setVisibleColumns(newState);
  };

  const renderCellValue = (column, row) => {
    let value = row[column.field];
    switch (column.type) {
      case "date": return value?.toLocaleDateString() || '';
      default: return value;
    }

  }

  const renderHeaderCell = (column) => {

    switch (column.type) {

      default: return (<TableCell key={column.field} sortDirection={orderBy === column.field ? order : false} sx={{ ...cellPadding, padding: `${parseFloat(cellPadding.padding) * 1.25}px`, ...cellLineHeight }}>
        <TableSortLabel
          active={orderBy === column.field}
          direction={orderBy === column.field ? order : 'asc'}
          onClick={() => handleSort(column.field)}
        >
          {column.title}
        </TableSortLabel>
        {/*<Checkbox*/}
        {/*  checked={visibleColumns[column.field]}*/}
        {/*  onChange={() => handleToggleColumn(column.field)}*/}
        {/*/>*/}
      </TableCell>)
    }
  }

  return (
    <Box component="div" sx={{ flexGrow: 1, display: "flex", flexDirection: "column", padding: "0 16px 16px 16px", }}>
      <ShowHideColumns columns={columns} visibleColumns={visibleColumns } onChange={handleToggleColumn} />
      <TableContainer ref={tableRef} component={Paper} sx={{width:"100%", height: tHeight? tHeight: "100%"}}>
        <Table stickyHeader stickyFooter aria-label="custom pagination table" sx={{width:"100%"} }>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              visibleColumns[column.field] && (
                renderHeaderCell(column)
              )
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : sortedData
          ).map((row, index) => (
            <TableRow key={index} onClick={() => onRowClick?.(row)} sx={{ cursor: "pointer" }}>
              {columns.map((column) => (
                visibleColumns[column.field] && (
                  <TableCell key={column.field} component="td" scope="row" sx={[cellLineHeight, cellPadding]}>
                    {renderCellValue(column, row)}
                  </TableCell>
                )
              ))}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 50 * emptyRows }}>
              <TableCell colSpan={columns.length} />
            </TableRow>
          )}
        </TableBody>
          <TableFooter style={{ bottom: 0 }}>
            <TableRow>
              <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  colSpan={columns.length}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage={<FormattedMessage id="pagination.rowsPerPage" />}
                  labelDisplayedRows={({ from, to, count }) => (
                    <FormattedMessage
                      id="pagination.ofFromTo"
                      values={{ from, to, count }}
                    />
                )}
                sx={{
                  '&.MuiTablePagination-root': {
                    lineHeight: cellLineHeight.lineHeight, // Set the line height for TablePagination
                    padding: cellLineHeight.padding, // Set the padding for TablePagination
                  },
                  '&.MuiTableCell-root': {
                    lineHeight: cellLineHeight.lineHeight, // Set the line height for TableCell
                    padding: cellLineHeight.padding, // Set the padding for TableCell
                  },
                }}
                />
          </TableRow>
        </TableFooter>
      </Table>
      </TableContainer>
    </Box>
  );
};

DynamicTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default DynamicTable;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography, Grid, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TextAreaFull from "../../common/FormControl/TextAreaFull";
import ButtonPrimaryAlt from "../../common/ButtonPrimaryAlt";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  text: {
    fontSize: 12,
    marginTop: 10,
  },
}));

const TextModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title="Text - Show outcomes"
      />
      <DraggableModalBody>
        <Grid container className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Document Control Text
            </Typography>
            <TextAreaFull rows="5" rowsMax="5" />
          </Grid>
        </Grid>
        <Box mb={2} />
        <Grid container className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Text Action</Typography>
            <TextAreaFull rows="5" rowsMax="5" />
          </Grid>
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <ButtonPrimaryAlt
            label={<FormattedMessage id="btn.saveChanges" />}
            icon="fa fa-save"
          />
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

TextModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TextModal;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const INITIALIZE_FOLDER_DOWNLOAD = gql`
  mutation InitFolderDownload($siteId: Int!, $folderId: Int, $resourceIds: [ID!]) {
    initFolderDownload(siteId: $siteId, folderId: $folderId, resourceIds: $resourceIds) {
      success
      errorResult {
        ...ErrorResultFields
      }
      url
    }
  }
  ${ErrorResultFragment}
`;

export default INITIALIZE_FOLDER_DOWNLOAD;

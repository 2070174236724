import { gql } from '@apollo/client';

const getListOfSuppliersForWorkspace = gql`
  query GetListOfSuppliersForWorkspace($siteId: Int!) {
    supplierAdmin {
      getListOfSuppliersForWorkspace(siteId: $siteId) {
        siteId
        suppliers {
          supplierId
          status
          formattedSupplierId
          supplierEmail
          supplierName
        }
      }
    }
  }
`;

export default getListOfSuppliersForWorkspace;

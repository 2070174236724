import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import MonitoringMain from "./MonitoringMain";
import { getSiteOptions } from "../../../helpers/selectors";
import MonitoringModalTour from "../../Tours/MonitoringModalTour";
import { GET_MONITORING_ITEM } from "../../../graphql/queries";
import { ConfirmCloseModal } from "../../common";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1400px",
    height: "96vh",
    margin: 0,
    maxHeight: "unset",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 2.3,
    marginRight: 20,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "normal",
      fontSize: 14,
    },
  },
  modalHead: {
    paddingLeft: 0,
    "& .MuiIconButton-root": {
      marginLeft: "unset",
    },
  },
  modalBody: {
    padding: 0,
  },
  tourLink: {
    width: "unset",
    height: "unset",
    minWidth: "unset",
    fontSize: 12,
    padding: 0,
    textTransform: "none",
    color: theme.palette.common.link,
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tourIcon: {
    width: "unset",
    height: "unset",
    fontSize: 14,
    marginRight: 5,
    marginTop: 1,
    color: theme.palette.common.white,
  },
}));

const MonitoringModal = ({
  currentViewer,
  folder,
  folders,
  handleClose,
  initialSiteId,
  open,
  siteOptions,
  monitoringItemId,
  openedNodes,
  initialWorkspaceName,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [monitoringName, setMonitoringName] = useState("");
  const [confirmCloseMonitoring, setConfirmCloseMonitoring] = useState(false);
  const [initialMonitoring, setInitialMonitoring] = useState();

  const { data, refetch, loading } = useQuery(GET_MONITORING_ITEM, {
    variables: {
      monitoringItemId,
    },
    skip: !monitoringItemId,
  });

  useEffect(() => {
    if (data) {
      const { monitoringContext } = data || {};
      const { item } = monitoringContext || {};
      const { name } = item;
      const { fileMonitoring } = item || {};
      const { monitoredFiles = [], monitoredFolders = [] } =
        fileMonitoring || {};
      const newMonitoredFiles = monitoredFiles.map(x => ({
        ...x,
        isNotifyEnabled: true,
      }));
      const newMonitoredFolders = monitoredFolders.map(x => ({
        ...x,
        isNotifyEnabled: true,
      }));
      setMonitoringName(name);
      const newItem = {
        ...item,
        fileMonitoring: {
          ...fileMonitoring,
          monitoredFiles: [...newMonitoredFiles],
          monitoredFolders: [...newMonitoredFolders],
        },
      };
      setInitialMonitoring(newItem);
    }
  }, [data]);

  const [openTour, setOpenTour] = useState(false);
  const handleOpenTour = () => {
    setOpenTour(true);
  };

  const handleCloseTour = () => {
    setOpenTour(false);
  };

  return (
    <>
      <DraggableModal
        open={open}
        handleClose={() => setConfirmCloseMonitoring(true)}
        customClass={classes.modal}
      >
        <DraggableModalHead
          handleClose={handleClose}
          customClass={classes.modalHead}
        >
          <Box display="block" displayPrint="none">
            <h4 className={classes.modalTitle}>
              {intl.formatMessage({
                id: "monitoring.createMonitoring",
              })}
              {monitoringName && ` - ${monitoringName}`}
            </h4>
          </Box>
          <Tooltip
            title={<FormattedMessage id="common.takeATour" />}
            placement="top"
            arrow
          >
            <Button className={classes.tourLink} onClick={handleOpenTour}>
              <Icon
                className={clsx(["fas fa-map-marked-alt", classes.tourIcon])}
              />
            </Button>
          </Tooltip>
        </DraggableModalHead>
        <DraggableModalBody customClass={classes.modalBody}>
          {open && (
            <MonitoringMain
              currentViewer={currentViewer}
              folder={folder}
              initialFolders={folders}
              handleClose={handleClose}
              initialSiteId={initialSiteId}
              monitoringName={monitoringName}
              setMonitoringName={setMonitoringName}
              siteOptions={siteOptions}
              initialMonitoring={initialMonitoring}
              openedNodes={openedNodes}
              initialWorkspaceName={initialWorkspaceName}
            />
          )}
        </DraggableModalBody>
      </DraggableModal>
      <MonitoringModalTour isOpen={openTour} onRequestClose={handleCloseTour} />
      <ConfirmCloseModal
        open={confirmCloseMonitoring}
        setOpen={setConfirmCloseMonitoring}
        onConfirmCloseModal={() => {
          handleClose();
          setConfirmCloseMonitoring(false);
        }}
      />
    </>
  );
};

MonitoringModal.defaultProps = {
  folders: [],
  openedNodes: [],
  folder: {},
  monitoringItemId: null,
  initialWorkspaceName: null,
};

MonitoringModal.propTypes = {
  initialWorkspaceName: PropTypes.string,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  folders: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  siteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  initialSiteId: PropTypes.number.isRequired,
  currentViewer: PropTypes.shape({
    userId: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  folder: PropTypes.shape({ folderId: PropTypes.number }),
  monitoringItemId: PropTypes.number,
};

const mapStateToProps = state => {
  return {
    currentViewer: state.api.currentViewer.viewer,
    siteOptions: getSiteOptions(state),
  };
};

export default connect(mapStateToProps)(MonitoringModal);

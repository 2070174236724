import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";
import { useFileViewPaste } from "../../../hooks";

const RenderPaste = ({ siteId, folderId }) => {
  const fileViewPaste = useFileViewPaste();

  const handlePaste = () => {
    fileViewPaste.paste({ folderId, siteId });
  };

  return (
    <ContextMenuItem onClick={handlePaste}>
      <FormattedMessage id="context.paste" />
    </ContextMenuItem>
  );
};

RenderPaste.propTypes = {
  folderId: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default RenderPaste;

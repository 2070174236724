import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_LIST_OF_SUPPLIERS_FOR_WORKSPACE } from "../graphql/queries";
import {
  setSuppliers,
  setSuppliersLoading,
  setSuppliersError,
} from "../actions";

// eslint-disable-next-line import/prefer-default-export
export const useGetListOfSuppliersForWorkspace = ({ siteId }) => {
  const [getListOfSuppliers, { data, loading, error }] = useLazyQuery(
    GET_LIST_OF_SUPPLIERS_FOR_WORKSPACE,
    {
      variables: {
        siteId,
      },
      skip: !siteId,
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      data &&
      data.supplierAdmin &&
      data.supplierAdmin.getListOfSuppliersForWorkspace &&
      data.supplierAdmin.getListOfSuppliersForWorkspace.suppliers
    ) {
      dispatch(
        setSuppliers({
          items: data.supplierAdmin.getListOfSuppliersForWorkspace.suppliers,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setSuppliersLoading({
        loading,
      })
    );
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(
        setSuppliersError({
          error,
        })
      );
    }
  }, [error]);

  return { execute: getListOfSuppliers, data, loading };
};

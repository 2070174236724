import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const MOVE_TASK = gql`
  mutation MoveTask(
    $moveTaskParams: MoveTaskParamsInput!
  ) {
    projects {
      moveTask(
        moveTaskParams: $moveTaskParams
      ) {
        success
        errorResult { ...ErrorResultFields }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default MOVE_TASK;

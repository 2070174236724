export const initialOptionValues = {
  webb: false,
  msOffice: false,
  tryck: false,
};

export const SETTING_IDS = {
  WEB: 1,
  MS_OFFICE: 2,
  PRINT: 3,
};

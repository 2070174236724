import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { CircularProgress, Grid } from "@mui/material";
import { GET_USER_CONTACT_DETAILS } from "../../../graphql/queries";
import DraggableModal from "../DraggableModal";
import DraggableModalHead from "../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";
import AccountSettingsReadOnly from "../AccountSettingsReadOnly";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  body: {
    padding: 0,
    minHeight: "75vh",
  },
  loading: {
    margin: "50px 0",
  },
}));

const UserContactInfoModal = ({ open, handleClose, username }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [userDetails, setUserDetails] = useState({});

  const { firstname, lastname } = userDetails;

  const [getUserContactDetails, { data, loading }] = useLazyQuery(
    GET_USER_CONTACT_DETAILS
  );

  useEffect(() => {
    if (open && username) {
      getUserContactDetails({ variables: { username } });
    }
  }, [open, username]);

  useEffect(() => {
    if (
      data &&
      data.userAccountContext &&
      data.userAccountContext.getUserContactDetails
    ) {
      setUserDetails(data.userAccountContext.getUserContactDetails);
    }
  }, [data]);

  const contactInfoText = intl.formatMessage({
    id: "account.contactInformation",
  });

  const headerText = () => {
    let title = "";

    if (firstname) {
      title = `${firstname}`;
      if (lastname) {
        title = `${firstname} ${lastname}`;
      }
      title = `${title} ${contactInfoText}`;
    } else if (lastname) {
      title = `${lastname} ${contactInfoText}`;
    } else {
      title = `${contactInfoText}`;
    }
    return title;
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title={headerText()} />
      <DraggableModalBody customClass={classes.body}>
        {loading && (
          <Grid container justifyContent="center" className={classes.loading}>
            <CircularProgress size={30} />
          </Grid>
        )}

        {!loading && (
          <AccountSettingsReadOnly
            userDetails={userDetails}
          />
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

UserContactInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default UserContactInfoModal;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const MOVE_FOLDER = gql`
  mutation MoveFolder($folderId: Int!, $parentFolderId: Int, $position: Int!) {
    moveFolder(
      folderId: $folderId
      parentFolderId: $parentFolderId
      position: $position
    ) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }

  ${ErrorResultFragment}
`;

export default MOVE_FOLDER;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const CREATE_ACCOUNT_SETTINGS = gql`
  mutation CreateAccountSettings(
    $companyId: Int!
    $model: CreateAccountSettingsInput
  ) {
    companyAdminContext {
      users {
        createAccountSettings(companyId: $companyId, model: $model) {
          errorResult {
            ...ErrorResultFields
          }
          success
          accountCreated
          user {
            companyId
            userId
            username
            firstname
            lastname
            avatar
            #roleName
            #groupBreadcrumbs {
            #  groups {
            #    groupId
            #    name
            #  }
            #}
            subscriptionLevel
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_ACCOUNT_SETTINGS;

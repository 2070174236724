import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  Switch,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import CustomTextField from "../common/TextField";
import DateFull from "../common/FormControl/DateFull";

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    display: "block",
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 18,
    display: "inline-block",
    marginBottom: 10,
  },
  icon: {
    fontSize: 18,
    verticalAlign: "sub",
  },
  btn: {
    padding: "8px 15px",
    fontWeight: 600,
    marginTop: 10,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  spacing: {
    margin: "10px 0",
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
}));

const OrderSearch = ({
  formValues,
  setFormValues,
  onSearchClick,
  orderHistorySettings,
}) => {
  const {
    fromDate,
    toDate,
    companyOrderNo,
    receiver,
    deliveryAddress2,
    deliveryZip,
    user,
    city,
    onlyMyOrders,
  } = formValues;

  const classes = useStyles();
  const intl = useIntl();

  const handleFromDateChange = date => {
    setFormValues({ ...formValues, fromDate: date });
  };

  const handleToDateChange = date => {
    setFormValues({ ...formValues, toDate: date });
  };

  const handleOnlyMyOrders = value => {
    setFormValues({ ...formValues, onlyMyOrders: value.target.checked });
  };

  const handleTextChange = event => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography className={classes.title}>
        <FormattedMessage id="context.orderHistory" />
      </Typography>
      <Typography className={classes.label}>
        <FormattedMessage id="orderHistorySearch.orderDate" />
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <DateFull
            label={intl.formatMessage({ id: "orderHistorySearch.from" })}
            value={fromDate}
            setDate={handleFromDateChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DateFull
            label={intl.formatMessage({ id: "orderHistorySearch.to" })}
            value={toDate}
            setDate={handleToDateChange}
          />
        </Grid>
        {orderHistorySettings.canSeeAllOrderHistory && (
          <Grid item xs={12} lg={12}>
            <Typography className={classes.switchLabel}>
              <FormattedMessage id="orderHistorySearch.showAllOrder" />
            </Typography>
            <Switch onChange={handleOnlyMyOrders} value={onlyMyOrders} />
          </Grid>
        )}
      </Grid>
      <Box className={classes.spacing} />
      <Typography className={classes.label}>
        <FormattedMessage id="orderHistorySearch.orderNumber" />
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <CustomTextField
            name="companyOrderNo"
            value={companyOrderNo}
            onChange={handleTextChange}
            label={intl.formatMessage({
              id: "orderHistorySearch.orderNumber",
            })}
          />
        </Grid>
      </Grid>
      <Box className={classes.spacing} />
      <Typography className={classes.label}>
        <FormattedMessage id="orderHistorySearch.textSearch" />
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <CustomTextField
            name="receiver"
            value={receiver}
            onChange={handleTextChange}
            label={intl.formatMessage({
              id: "orderHistorySearch.recipient",
            })}
            placeholder={intl.formatMessage({
              id: "orderHistorySearch.recipient",
            })}
          />
          <CustomTextField
            name="deliveryAddress2"
            value={deliveryAddress2}
            onChange={handleTextChange}
            label={intl.formatMessage({
              id: "orderHistorySearch.streetAddress",
            })}
            placeholder={intl.formatMessage({
              id: "orderHistorySearch.streetAddress",
            })}
          />
          <CustomTextField
            name="deliveryZip"
            value={deliveryZip}
            onChange={handleTextChange}
            label={intl.formatMessage({
              id: "orderHistorySearch.postCode",
            })}
            placeholder={intl.formatMessage({
              id: "orderHistorySearch.postCode",
            })}
          />
          <CustomTextField
            name="user"
            value={user}
            onChange={handleTextChange}
            label={intl.formatMessage({
              id: "orderHistorySearch.user",
            })}
            placeholder={intl.formatMessage({
              id: "orderHistorySearch.user",
            })}
          />
          <CustomTextField
            name="city"
            value={city}
            onChange={handleTextChange}
            label={intl.formatMessage({
              id: "orderHistorySearch.city",
            })}
            placeholder={intl.formatMessage({
              id: "orderHistorySearch.city",
            })}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            fullWidth
            onClick={() => onSearchClick()}
          >
            <FormattedMessage id="btn.search" />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

OrderSearch.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
    companyOrderNo: PropTypes.string,
    receiver: PropTypes.string,
    deliveryAddress2: PropTypes.string,
    deliveryZip: PropTypes.string,
    user: PropTypes.string,
    city: PropTypes.string,
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export default OrderSearch;

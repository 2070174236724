import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import TableCell from "@mui/material/TableCell";

import { FormattedCurrency } from "../../common";

const useStyles = makeStyles(() => ({
  td: {
    fontSize: 12,
  },
  tdHeader: {
    backgroundColor: "#ddd",
    borderBottom: "none",
    fontSize: 12,
  },
}));

const PriceCell = ({ price, header }) => {
  const classes = useStyles();

  return (
    <TableCell align="right" className={header ? classes.tdHeader : classes.td}>
      <FormattedCurrency value={price} />
    </TableCell>
  );
};

PriceCell.defaultProps = {};

PriceCell.propTypes = {};

export default memo(PriceCell);

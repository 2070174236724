import { gql } from '@apollo/client';

const OrderGroupShoppingCartItem = gql`
  fragment OrderGroupShoppingCartItemFragment on OrderGroupShoppingCartItem {
    bookingInterval {
      from
      to
    }
    dynamicFieldValues {
      fieldName
      value
    }
    name
    productId
    quantity
    propertyProductData {
      quantity
      propertyName
      propertyValue
      propertyValueId
    }
    selectableSuppliers {
      displayName
      siteSupplierId
    }
    shoppingCartItemId
    siteId
    siteSupplierId
    source
  }
`;

export default OrderGroupShoppingCartItem;

import {
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import * as React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
const useStyles = makeStyles(() => ({
  formControl: {
    fontSize: 12,
    padding: "9px 12px",
    height: "100%",
    color: "#66615b",
    width: "100%",
    display: "flex",
    alignItems: "center",
    "&.Mui-disabled": {
      opacity: 0.3,
    },
  },
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  menuItem: {
    fontSize: 12,
  },
  formHelperText: {
    display: "inline-block",
    marginTop: -1,
    fontSize: 10,
  },
  inputLabelText: {
    fontSize: 12,
    transform: "translate(14px, 13px) scale(1)",
  },
  fix: {
    display: "inline-flex",
  },
}));

const MultiColumnSelect = ({
  onChange,
  value,
  options,
  disabled,
  native,
  inputLabel,
  name,
  helperText,
  error,
  customClass,
  multiple,
  renderValue,
  open,
  onOpen,
  shrink,
  inputLabelCustomClass,
  menuItemClass,
  formControlClass,
  placeholder,
  id,
  defaultOption,
  onClose,
  required,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      required={required}
      className={customClass}
      variant="outlined"
      fullWidth
    >
      {" "}
      <InputLabel shrink={shrink} className={classes.inputLabelText}>
        {inputLabel}
      </InputLabel>
      <Select
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        input={<OutlinedInput label="Arbetsytemall" />}
      >
        {options.map((workspace, i) => (
          <MenuItem
            key={workspace.name + i}
            value={workspace.workspaceTemplateId}
          >
            <ListItemText secondary={workspace.description}>
              {workspace.name}
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiColumnSelect;

import { gql } from '@apollo/client';

const getRecentlyModifiedResourcesForSite = gql`
  query GetRecentlyModifiedResourcesForSite($siteId: Int!) {
    widgets {
      getRecentlyModifiedResourcesForSite(siteId: $siteId) {
        folderId
        lastModified
        name
        fileExtension
        thumbnailImageUri
        productId
      }
    }
  }
`;

export default getRecentlyModifiedResourcesForSite;

import React, { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { PrimaryButton } from "../../common";

const useStyles = makeStyles(theme => ({
  footer: {
    borderTop: `1px solid ${theme.palette.common.black}`,
    padding: "5px",
    textAlign: "center",
  },
  itemLabel: {
    display: "block",
    fontSize: 12,
    padding: 5,
    width: "100%",
  },
  itemLabelHighlight: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
  },
  list: {
    padding: 0,
  },
}));

const CompanyListBox = forwardRef((props, ref) => {
  const classes = useStyles();

  const { children, handleAddNewCompany } = props;

  const handleModal = e => {
    e.stopPropagation();
    handleAddNewCompany();
  };

  return (
    <>
      <ul ref={ref} {...props} className={classes.list}>
        {children.map((child, index) => {
          const { props: childProps, option } = child;
          const { key } = childProps;
          const { companyName: label, isHighlighted, publicCompanyId: value } = option;

          return (
            <li key={`${key}-${index}`} {...childProps}>
              <span
                className={clsx([
                  classes.itemLabel,
                  isHighlighted ? classes.itemLabelHighlight : "",
                ])}
                data-id={value}
                value={value}
              >
                {label}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
});

export default CompanyListBox;

import { Dialog, DialogContent } from "@mui/material";
import ForceMFASetup from "../../components/ForceMFaSetup";

const OptionalMfaSetupDialog = ({
  onAbort,
  onComplete,
  open,
  username,
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        elevation: 0,
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: '#f3f3f3' 
        },
        '& .MuiDialog-paper': {
          width: '595px',
          backgroundColor: "inherit",
        },
        '& .MuiDialogContent-root': {
          padding:"12px",
        },
    }}>
      <DialogContent>
        <ForceMFASetup
          allowAbort
          onAbort={onAbort}
          onMfaSetupComplete={onComplete}
          username={username}
        />
      </DialogContent>
    </Dialog>
  );
}

export default OptionalMfaSetupDialog;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import Checkbox from "../FormControl/Checkbox";
import FormattedFileSize from "../FormattedFileSize";
import Image from "../../../assets/img/sweden-1.jpg";
import FormattedDate from "../FormattedDate";

const useStyles = makeStyles(() => ({
  td: {
    fontSize: 11,
    padding: 10,
  },
  img: {
    maxWidth: 32,
    maxHeight: 32,
    objectFit: "cover",
    border: "1px solid #e8e8e8",
    boxShadow: "0 2px 5px #efefef",
  },
}));

const ResourceItem = ({
  resource,
  selectedResources,
  setSelectedResources,
  isProductEdit,
}) => {
  const {
    thumbnailImageUri,
    name,
    changedBy,
    changedDate,
    uploadedDate,
    size,
    selected,
  } = resource;
  const classes = useStyles();
  const alert = useAlert();

  const handleSelectResources = resource => {
    const tempResource = [...selectedResources, resource];
    setSelectedResources(tempResource);
    if (!isProductEdit) {
      alert.success(<FormattedMessage id="rfp.fileAdded" />);
    }
  };

  const handleRemoveResources = resource => {
    const tempResource = selectedResources.filter(x => x.id !== resource.id);
    setSelectedResources(tempResource);
  };

  const onCheckboxChange = e => {
    if (e && e.target) {
      if (e.target.checked) {
        handleSelectResources(resource);
      } else {
        handleRemoveResources(resource);
      }
    } else {
      console.log("Unexpected error on checkbox change.");
    }
  };

  return (
    <TableRow>
      <TableCell className={classes.td}>
        <img src={thumbnailImageUri || Image} alt="" className={classes.img} />
      </TableCell>
      <TableCell className={classes.td}>{name}</TableCell>
      <TableCell className={classes.td}>{changedBy}</TableCell>
      <TableCell className={classes.td}>
        <FormattedDate value={changedDate} />
      </TableCell>
      <TableCell className={classes.td}>
        <FormattedDate value={uploadedDate} />
      </TableCell>
      <TableCell className={classes.td}>
        <FormattedFileSize value={size} />
      </TableCell>
      <TableCell className={classes.td} align="right">
        <Checkbox value={selected} onChange={onCheckboxChange} />
      </TableCell>
    </TableRow>
  );
};

ResourceItem.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.number.isRequired,
    thumbnailImageUri: PropTypes.string,
    name: PropTypes.string.isRequired,
    changedBy: PropTypes.string,
    changedDate: PropTypes.string,
    uploadedDate: PropTypes.string,
    size: PropTypes.number,
    selected: PropTypes.bool,
  }).isRequired,
  selectedResources: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    .isRequired,
  setSelectedResources: PropTypes.func.isRequired,
};

export default ResourceItem;

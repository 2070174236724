import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Icon, IconButton } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  titleContainer: {
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 16,
  },
  iconButtonAdd: {
    "&.Mui-disabled": {
      visibility: "hidden",
    },
  },
}));

const HeaderList = ({ title, iconOnClick, hideButton }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.groupLabel}>{title}</Typography>
      </Box>
      <Box>
        <IconButton
          disabled={hideButton}
          className={classes.iconButtonAdd}
          onClick={event => {
            event.stopPropagation();
            iconOnClick(event);
          }}
        >
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
        </IconButton>
      </Box>
    </Box>
  );
};

HeaderList.defaultProps = {
  hideButton: false,
};

HeaderList.propTypes = {
  title: PropTypes.node.isRequired,
  iconOnClick: PropTypes.node.isRequired,
  hideButton: PropTypes.bool,
};

export default HeaderList;

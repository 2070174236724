import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const INITIALIZE_FOLDER_IMPORT = gql`
  mutation initFolderImportUpload(
    $siteId: Int!
    $folderId: Int,
    $importFiles: Boolean!
  ) {
    initFolderImportUpload(
      siteId: $siteId
      folderId: $folderId
      importFiles: $importFiles
    ) {
      success
      errorResult {
        ...ErrorResultFields
      }
      url
    }
  }
  ${ErrorResultFragment}
`;

export default INITIALIZE_FOLDER_IMPORT;
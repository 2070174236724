import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import InvitingCompanySelector from "./InvitingCompanySelector";

const useStyles = makeStyles(() => ({
  divider: {
    marginBottom: 15,
    marginTop: 15,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
}));

const HelpUserHeader = ({ onCompanyChange }) => {
  const classes = useStyles();
  const intl = useIntl();

  const texts = [
    intl.formatMessage({
      id: "siteUsers.inviteHeader1",
    }),
    intl.formatMessage({
      id: "siteUsers.inviteHeader2",
    }),
  ];

  return (
    <>
      <InvitingCompanySelector onChange={onCompanyChange} />
      {texts.map((text, idx) => (
        <Typography key={idx} className={classes.text}>
          {text}
        </Typography>
      ))}
      <Divider className={classes.divider} />
    </>
  );
};

HelpUserHeader.propTypes = {
  onCompanyChange: PropTypes.func.isRequired,
};

export default HelpUserHeader;

import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NetworkStatus, useLazyQuery } from "@apollo/client";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { Box, Card, CardHeader, CircularProgress, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { SALES_BUTTON_TYPE, SALES_PAGE_TYPE } from "../../../constant/salesPageTypes";
import { GET_COMPANY_RECEIVED_SALESDOCUMENTS, GET_COMPANY_SALESDOCUMENTS } from "../../../graphql/queries";
import { getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";
import * as pageHelper from "../../../helpers/salesPageHelpers";
import { useSnackbar } from "../../../hooks";
import TabPanel from "../Invoices/TabPanel";
import DocumentsTable from "./DocumentsTable";
import SalesEditModal from "./SalesEditModal/SalesEditModal";
import SalesSearchBar from "./SalesSearchBar";
import SalesTabsBar from "./SalesTabsBar";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 16,
    borderRadius: "4px",
    background: "#FFFFFF",
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    "& .MuiBox-root": {
      marginLeft: 0,
      marginRight: 0,
      padding: "0px 0px 0px 0px",
    },
    "& .MuiAppBar-root": {
      backgroundColor: "#e6e6e6",
      color: "black",
    },
    "& .MuiCardContent-root": {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: "16px",
    },
    "& .DayPickerInput ": {
      width: "100%",
    },
    "& .DayPickerInput input": {
      color: theme.palette.text.primary,
      height: "100%",
      border: "1px solid #c4c4c4",
      borderRadius: "0px",
      padding: "10px 10px 10px 12px",
      fontSize: "12px",
      width: "100%",
      boxSizing: "border-box",
      "&:focus": {
        border: "2px solid #000",
      },
    },
    "& .react-datepicker-wrapper": {
      flex: "0 1 auto",
      textAlign: "center",
    },
    "& .react-datepicker-wrapper input[type='text']:focus": {
      outline: 0,
    },
    "& .react-datepicker__input-container input": {
      color: theme.palette.text.primary,
      height: "100%",
      width: "100%",
      border: "none",
      borderRadius: "4px 4px 0px 0",
      borderLeft: "none",
      padding: "23px 12px 6px 12px",
      fontSize: "12px",
      textAlign: "center",
      background: "rgba(0, 0, 0, 0.03)",
      marginTop: "0px",
      marginRight: "0px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
      "&:focus": {
        borderBottomWidth: "2px",
      },
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      background: "#353e47 !important",
      color: "#fff",
      borderRadius: "0!important",
    },
    "& .DayPickerInput-OverlayWrapper , .react-datepicker-popper": {
      zIndex: 100,
    },
    "& .DayPicker-Day": {
      borderRadius: "100% !important",
      fontSize: "16px !important",
      fontWeight: 600,
    },
    "& .DayPicker-Day--start": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "5px 0 0 5px!important",
    },
    "& .DayPicker-Day--end": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "0 5px 5px 0!important",
    },
    "& .DayPickerInput-Overlay": {
      width: " 700px",
      marginLeft: "-198px",
    },
    "& .DayPicker-Day--weekends": {
      color: "#D32F2F",
      // background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--outside": {
      visibility: "hidden",
    },
    "& .DayPicker-Day--totallyBusy": {
      color: "#fff",
      background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--partialBusy": {
      color: " #000000",
      background: "#fffad6",
    },
    "& .DayPicker-Weekday": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
    "& .DayPicker-Caption > div": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
  },
  cardHead: {
    padding: "0px 0px 8px 0px",
  },
  body: {
    padding: "0px 20px 20px 20px",
  },
  table: {
    minWidth: 650,
  },
  blue: {
    background: "#caeff7",
  },
  pink: {
    background: "#fce0e7",
  },
  green: {
    background: "#d9fce4",
  },
  purple: {
    background: "#f3edff",
  },
  btnReset: {
    margin: "auto",
    padding: "6px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
}));

const INITIAL_DATA_ROWS = [];

const INITIAL_SEARCH_VALUES = {
  documentNumber: "",
  type: "",
  category: "",
  status: "",
  startDate: undefined,
  endDate: undefined,
};

const NEW_DOCUMENT_ID = -1;

const Sales = ({ pageType }) => {
  const classes = useStyles();
  const intl = useIntl();
  const snackbar = useSnackbar();

  const companyId = useSelector(getAdminSelectedCompanyId);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [dataRows, setDataRows] = useState(INITIAL_DATA_ROWS);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [selectedSalesDocumentId, setSelectedSalesDocumentId] = useState(NEW_DOCUMENT_ID);
  const [dialogSettings, setDialogSettings] = useState();
  const [searchValues, setSearchValues] = useState({ ...INITIAL_SEARCH_VALUES });

  const invoiceDialogSettings = () => {

    const intlParts = intl.formatters.getNumberFormat(intl.locale).formatToParts(9999.99);

    const currencySettings = {
      currency: "kr",
      decimalSeparator: intlParts.filter(f => f.type === "decimal")[0].value,
      thousandSeparator: intlParts.filter(f => f.type === "group")[0].value,
    };

    let doc = {
      currencySettings,
      documentNumberLabel: intl.formatMessage({ id: "admin.sales.dataLabels.invoiceNo" }),
      documentDateLabel: intl.formatMessage({ id: "admin.sales.dataLabels.invoiceDate" }),
      dueDateLabel: intl.formatMessage({ id: "admin.sales.dataLabels.dueDate" }),
      ourReferenceLabel: intl.formatMessage({ id: "admin.sales.dataLables.ourReference" }),
      yourReferenceLabel: intl.formatMessage({ id: "admin.sales.dataLabels.yourReference" }),
      paymentTermsDays: intl.formatMessage({ id: "admin.sales.dataLabels.termsOfPayment" }),
      modalTitle: intl.formatMessage({ id: "admin.sales.dataLabels.invoice" }),
      tableLabels: [
        {
          label: intl.formatMessage({
            id: "admin.sales.rowsTable.article"
          }),
          align: "left",
          width: "20%"
        },
        {
          label: intl.formatMessage({
            id: "admin.sales.rowsTable.description"
          }),
          align: "left",
          width: "20%"
        },
        {
          label: intl.formatMessage({
            id: "admin.sales.rowsTable.project"
          }),
          align: "left",
          width: "20%"
        },
        {
          label: intl.formatMessage({
            id: "admin.sales.rowsTable.amount"
          }),
          align: "right",
          width: "10%"
        },
        {
          label: intl.formatMessage({
            id: "admin.sales.rowsTable.unit"
          }),
          align: "left",
          width: "5%"
        },
        {
          label: intl.formatMessage({
            id: "admin.sales.rowsTable.price"
          }),
          align: "right",
          width: "10%"
        },
        {
          label: "Rabatt",
          align: "right",
        },
        {
          label: "Moms",
          align:"right"
        },
        {
          label: intl.formatMessage({
            id: "admin.sales.rowsTable.sumExclVAT"
          }),
          align: "right",
          width: "10%"
        },
      ],
    };

    return doc;
  }

  const { documentType } = useMemo(
    () => ({
      documentType: pageHelper.getDocumentType(pageType),
    }),
    [pageType]
  );

  const { pageButtons, pageTabs, pageTitle } = useMemo(
    () => ({
      pageButtons: pageHelper.getPageButtons({ intl, pageType }),
      pageTabs: pageHelper.getPageTabs({ intl, pageType }),
      pageTitle: pageHelper.getPageTitle({ intl, pageType }),
    }),
    [intl, pageType]
  );

  const { tableColumnSettings } = useMemo(
    () => ({
      tableColumnSettings: pageHelper.getTableColumnSettings({ intl, pageType, tabType: pageTabs[selectedTabIndex].type }),
    }),
    [intl, pageType, selectedTabIndex]
  );

  const [getSalesDocuments, {
    data: companySalesDocuments,
    error: errorLoadingDocuments,
    loading: loadingDocuments,
    refetch: refetchDocuments,
    networkStatus }] = useLazyQuery(GET_COMPANY_SALESDOCUMENTS);

  const [getReceivedDocuments, {
    data: companyRecievedSalesDocuments,
    error: errorRecievedSalesLoadingDocuments,
    loading: loadingRecievedSalesDocuments,
    refetch: refetchRecievedSalesDocuments,
    networkStatus: recievedSalesNetworkStatus
  }] = useLazyQuery(GET_COMPANY_RECEIVED_SALESDOCUMENTS);

  const searchOptions = {
    documentNumber: {
      type: "TEXT",
      label: "Sök",
    },
    type: {
      type: "SELECT",
      label: "Typ",
      options: [{ label: "TODO", value: 0 }, { label: "Apa", value: 10 }, { label: "Banan", value: 20 }],
    },
    status: {
      type: "SELECT",
      label: "Status",
      options: [
        { label: "All invoices", value: 10 }, { label: "Unpaid invoices", value: 15 }, { label: "Partially paid invoices", value: 20 }, { label: "Paid invoices", value: 25 },
        { label: "E-mailed invoice not delivered", value: 40 }, { label: "Doubtful debt", value: 50 }, { label: "Stopped e-invoices", value: 60 },
        { label: "Sold e-invoices", value: 65 }, { label: "Sold e-invoices - without risk management", value: 66 }, { label: "Sold e-invoices - with risk management", value: 67 },
        { label: "Ready for application at Skatteverket", value: 70 }, { label: "Application set to Skatteverket", value: 80 }, { label: "Application rejected from Skatteverket", value: 90 }
      ],
    },
    category: {
      type: "SELECT",
      label: "Kategorier",
      options: [
        { label: "All invoices", value: 10 }, { label: "Normal", value: 20 }, { label: "Housework", value: 30 },
        { label: "VAT reverse charge", value: 40 }, { label: "EU intermediary, VAT triangulation", value: 50 }
      ],
    },
    startDate: {
      type: "DATE",
      maxDate: new Date("2023-12-31"),
      minDate: new Date("2023-01-01"),
    },
    endDate: {
      type: "DATE",
      maxDate: new Date("2023-12-31"),
      minDate: new Date("2023-01-01"),
    },
  };

  const handleCloseModal = (isSaved) => {
    setOpenInvoiceModal(false);
    if (pageType === SALES_PAGE_TYPE.invoices && companyId > 0) {
      refetchDocuments();
    }
    else if (pageType === SALES_PAGE_TYPE.supplierInvoices && companyId > 0) {
      refetchRecievedSalesDocuments();
    }
  };

  const handleClickTableRow = row => {
    const { salesDocumentId } = row;

    switch (pageType) {
      case SALES_PAGE_TYPE.invoices:
        setDialogSettings(invoiceDialogSettings())
        setOpenInvoiceModal(true);
        setSelectedSalesDocumentId(salesDocumentId);
        break;
      case SALES_PAGE_TYPE.orders:
        console.info("** TODO: Open orders modal.");
        break;
      case SALES_PAGE_TYPE.quotations:
        console.info("** TODO: Open quotations modal.");
        break;
      case SALES_PAGE_TYPE.quoteRequests:
        console.info("** TODO: Open quote requests modal.");
        break;
      case SALES_PAGE_TYPE.supplierInvoices:
        setDialogSettings(invoiceDialogSettings())
        setOpenInvoiceModal(true);
        setSelectedSalesDocumentId(salesDocumentId);
        break;
      default:
        console.warn("** Unhandled page type", { pageType });
        break;
    }
  };

  const handleSelectRow = (row, index, checked) => {
    
    let temp = [...dataRows];
    temp[index].checked = checked;
    setDataRows(temp);
  }

  const handlePageButtonClick = ({ type }) => {
    switch (type) {
      case SALES_BUTTON_TYPE.newInvoice:
        setDialogSettings(invoiceDialogSettings())
        setSelectedSalesDocumentId(-1);
        setOpenInvoiceModal(true);
        break;
      case SALES_BUTTON_TYPE.newRecurringInvoice:
        console.info("** TODO: New recurring invoice.");
        break;
      case SALES_BUTTON_TYPE.newOrder:
        console.info("** TODO: New order.");
        break;
      case SALES_BUTTON_TYPE.newQuote:
        console.info("** TODO: New quote.");
        break;
      case SALES_BUTTON_TYPE.newQuoteRequest:
        console.info("** TODO: New quote request.");
        break;
      default:
        console.warn("** Unhandled button type", { type });
        break;
    }
  };

  const handlePageTabChange = ({ tabIndex, type }) => {
    console.log("** [Sales] handlePageTabChange", { type });
    setSelectedTabIndex(tabIndex);

    setDataRows(INITIAL_DATA_ROWS);

    if (pageType === SALES_PAGE_TYPE.invoices && companyId > 0) {

      let filter = "";
      switch (tabIndex) {
        case 0: filter = "DRAFT"; break;
        case 1: filter = "IN_PROGRESS"; break;
        case 2: filter = "COMPLETED"; break;
        default: filter = "DRAFT"; break;
      }

      refetchDocuments({ type, filter });
    }
    else if (pageType === SALES_PAGE_TYPE.supplierInvoices && companyId > 0) {

      let filter = "";
      switch (tabIndex) {
        case 0: filter = "IN_PROGRESS"; break;
        case 1: filter = "COMPLETED"; break;
        default: filter = "DRAFT"; break;
      }

      refetchRecievedSalesDocuments({type, filter});
    }

  };

  const resetSearchValues = () => {
    setSearchValues({ ...INITIAL_SEARCH_VALUES });
  };

  const updateSearchValues = ({ name, value }) => {
    setSearchValues((prev) => {
      var updated = {};
      updated[name] = value;
      return { ...prev, ...updated };
    });
  };

  useEffect(() => {
    if (pageType === SALES_PAGE_TYPE.invoices && companyId > 0) {
      getSalesDocuments({
        variables: {
          companyId,
          documentType,
          filter: "DRAFT",
          offset: 0,
          pageSize: 20
        },
        notifyOnNetworkStatusChange: true
      });
    } else if (pageType === SALES_PAGE_TYPE.supplierInvoices && companyId > 0) {
      getReceivedDocuments({
        variables: {
          companyId,
          documentType,
          filter: "IN_PROGRESS",
          offset: 0,
          pageSize: 20
        },
        notifyOnNetworkStatusChange: true
      });    
    }
  }, [companyId]);

  useEffect(() => {
    const res = companySalesDocuments?.companyAdminContext?.salesDocuments?.getCompanySalesDocuments;
    if (res && res.salesDocuments) {
      const { totalCount } = res;
      const documents = res.salesDocuments.map(
        r => ({
          salesDocumentId: r.salesDocumentId,
          documentNumber: r.documentNumber,
          documentDate: new Date(r.documentDate),
          documentType: r.documentType,
          customerName: r.customerName,
          customerNo: r.customerNo,
          yourReference: r.yourReference,
          amountDue: r.amountDue,
          unpaidAmount: r.unpaidAmount,
          category: r.category,
          dueDate: r.dueDate ? new Date(r.dueDate) : null,
        })
      );

      setDataRows(documents);
    }
  }, [companySalesDocuments]);


  useEffect(() => {
    const res = companyRecievedSalesDocuments?.companyAdminContext?.salesDocuments?.getReceivedSalesDocuments;
    if (res && res.salesDocuments) {
      const { totalCount } = res;
      const documents = res.salesDocuments.map(
        r => ({
          salesDocumentId: r.salesDocumentId,
          documentNumber: r.documentNumber,
          documentDate: new Date(r.documentDate),
          documentType: r.documentType,
          customerName: r.customerName,
          customerNo: r.customerNo,
          yourReference: r.yourReference,
          amountDue: r.amountDue,
          unpaidAmount: r.unpaidAmount,
          category: r.category,
          dueDate: r.dueDate ? new Date(r.dueDate) : null,
        })
      );

      setDataRows(documents);
    }
  }, [companyRecievedSalesDocuments]);

  useEffect(() => {
    if (errorLoadingDocuments) {
      snackbar.error(<FormattedMessage id="admin.sales.data.loadingError" />);
    }
  }, [errorLoadingDocuments]);

  const isLoading = loadingDocuments || networkStatus === NetworkStatus.refetch;

  const DebugInfo = () => (
    <Box mx={8} p={1} sx={{ backgroundColor: "lightyellow", border: "1px solid #ddd" }}>
      [ Tab: {pageTabs[selectedTabIndex].label}(#{selectedTabIndex}) | documentType: {documentType} | companyId: {companyId} | loading: {loadingDocuments ? "Y" : "N"} | error: {!!errorLoadingDocuments ? "Y" : "N"} | networkStatus: {networkStatus} ]
    </Box>
  );

  return (
    <Paper className={classes.root} elevation={2}>
      <Box mx={8} py={1}>
        <Card elevation={0} sx={{ overflow: "visible" }}>
          <CardHeader
            className={classes.cardHead}
            title={pageTitle}
            titleTypographyProps={{ variant: "h6" }}
          />
          <SalesTabsBar
            disabled={loadingDocuments}
            onPageButtonClick={handlePageButtonClick}
            onPageTabChange={handlePageTabChange}
            pageButtons={pageButtons}
            pageTabs={pageTabs}
          />
          <SalesSearchBar
            disabled={loadingDocuments}
            onChange={updateSearchValues}
            onReset={resetSearchValues}
            pageType={pageType}
            searchOptions={searchOptions}
            searchValues={searchValues}
            tabIndex={selectedTabIndex}
          />
        </Card>
      </Box>
      {!true && <DebugInfo />}
      <TabPanel value={0} index={0}>
        <Box className={classes.body} pt={0} mt={0}>
          <Box m={2} mt={0} pt={0}>
            <DocumentsTable
              dataRows={dataRows}
              haveError={errorLoadingDocuments || errorRecievedSalesLoadingDocuments}
              headers={tableColumnSettings}
              isLoading={isLoading || loadingRecievedSalesDocuments}
              messages={{
                emptyMessage: <FormattedMessage id="admin.sales.data.missing" />,
                errorMessage: <Box component="span" sx={{ display: "flex", gap: "2px" }}><ErrorIcon /><FormattedMessage id="admin.sales.data.loadingError" /></Box>,
                loadingMessage: <><CircularProgress size={10} sx={{ marginRight: "3px" }} /><FormattedMessage id="admin.sales.data.loading" /></>,
              }}
              onClickRow={handleClickTableRow}
              onSelectRow={handleSelectRow}
            />
          </Box>
        </Box>
      </TabPanel>

      {openInvoiceModal && (
        <SalesEditModal open={openInvoiceModal}
          handleClose={handleCloseModal}
          companyId={companyId} selectedSalesDocumentId={selectedSalesDocumentId} settings={dialogSettings} />)}

    </Paper>
  );
};

export default Sales;

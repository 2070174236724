import { gql } from '@apollo/client';

const getFileViewSettings = gql`
  query GetFileViewSettings($siteId: Int!) {
    fileView(siteId: $siteId) {
      sortingOptions {
        field
        ascending
        default
      }
    }
  }
`;

export default getFileViewSettings;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { useAlert } from "react-alert";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { Typography, Grid, Box } from "@mui/material";
import TextAreaFull from "../common/FormControl/TextAreaFull";
import CustomTextField from "../common/TextField";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import { SEND_EMAIL_TO_CUSTOMER } from "../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnSend: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
    },
    "& .Mui-disabled:hover": {
      cursor: "not-allowed",
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 0,
  },
  labelMsg: {
    fontSize: 12,
    marginBottom: 0,
    display: "inline-block",
    marginRight: 10,
  },
  msgAttach: {
    display: "inline-block",
    backgroundColor: theme.palette.background.contrastGray,
    padding: "3px 5px",
    borderRadius: 4,
    fontSize: 12,
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 12,
      marginRight: 5,
      backgroundColor: theme.palette.common.blue,
      padding: 2,
      borderRadius: 4,
      color: theme.palette.common.white,
    },
  },
}));

const ReplyModal = ({
  open,
  handleClose,
  orderId,
  pickingListOrdersItemId,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const intl = useIntl();

  const [sendEmailTouser] = useMutation(SEND_EMAIL_TO_CUSTOMER);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSend = async () => {
    try {
      const res = await sendEmailTouser({
        variables: { pickingListOrdersItemId, message },
      });

      if (
        res &&
        res.data &&
        res.data.pickingListContext &&
        res.data.pickingListContext.sendEmailToCustomer
      ) {
        alert.success(<FormattedMessage id="pickingList.replySent" />);
      } else {
        alert.error(<FormattedMessage id="monitoring.error" />);
      }
      handleClose();
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="btn.reply" />}
      />
      <DraggableModalBody>
        {/* commented out for now? */}
        {/* <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              <FormattedMessage id="context.to" />
            </Typography>
            <CustomTextField
              placeholder="To"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              <FormattedMessage id="context.subject" />
            </Typography>
            <CustomTextField
              placeholder="Subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Box>
              <Typography className={classes.labelMsg}>
                <FormattedMessage id="context.message" />
              </Typography>
              <Box className={classes.msgAttach}>
                <Icon className="fa fa-paperclip" />
                Order {orderId}
              </Box>
            </Box>
            <TextAreaFull
              placeholder={intl.formatMessage({
                id: "context.message",
              })}
              rows="3"
              rowsMax="3"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          className={classes.btnSend}
          fullWidth
          onClick={() => handleSend()}
        >
          <FormattedMessage id="btn.send" />
          <Icon className={clsx(["fa fa-paper-plane", classes.btnIcon])} />
        </Button>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ReplyModal.defaultProps = {
  orderId: null,
  pickingListOrdersItemId: null,
};

ReplyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  orderId: PropTypes.number,
  pickingListOrdersItemId: PropTypes.string,
};

export default ReplyModal;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import NameCellContent from "./NameCellContent";

const useStyles = makeStyles(() => ({
  tdName: {
    padding: "2px 15px",
  },
  tdHeader: {
    padding: "0px 24px 0px 16px",
    backgroundColor: "#ddd",
    borderBottom: "none",
  },
}));

const NameCell = ({ propertyProductData, name, header }) => {
  const classes = useStyles();

  return (
    <TableCell className={header ? classes.tdHeader : classes.tdName}>
      <NameCellContent propertyProductData={propertyProductData} name={name} />
    </TableCell>
  );
};

NameCell.defaultProps = {
  propertyProductData: [],
};

NameCell.propTypes = {
  propertyProductData: PropTypes.arrayOf(
    PropTypes.shape({
      propertyName: PropTypes.string,
      propertyValue: PropTypes.string,
      propertyValueId: PropTypes.number,
    })
  ),
  name: PropTypes.string.isRequired,
};

export default memo(NameCell);

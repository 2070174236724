import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Typography, Grid, Box } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomTextField from "../../common/TextField";
import CustomSelect from "../../common/CustomSelect";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 25,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
    },
    "& .Mui-disabled:hover": {
      cursor: "not-allowed",
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  labelSelect: {
    marginBottom: 8,
  },
  folderWrap: {
    marginTop: 5,
    border: "1px solid #d7d7d7",
    borderRadius: 4,
    padding: 10,
    height: 200,
    overflowY: "auto",
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
    },
  },
  content: {
    marginBottom: 5,
    padding: "2px 0",
  },
  contentChild: {
    paddingLeft: 23,
    width: "auto",
  },
  labelTreeRoot: {
    display: "flex",
    alignItems: "center",
  },
  labelIcon: {
    fontSize: 14,
    marginRight: 10,
    width: 14,
    height: 14,
    overflow: "unset",
    textAlign: "center",
    color: "#555",
  },
  labelTree: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "14px",
  },
}));

const TransferSettingsModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title="Transfer files - Settings"
      />
      <DraggableModalBody>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={clsx([classes.label, classes.labelSelect])}>
              Website
            </Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Folders</Typography>
            <CustomTextField placeholder="Search folder" />
            <Box className={classes.folderWrap}>
              <TreeView
                multiSelect
                defaultExpanded={["1"]}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                className={classes.root}
              >
                <TreeItem
                  nodeId="1"
                  label={
                    <Box className={classes.labelTreeRoot}>
                      <Icon
                        className={clsx(["fas fa-folder", classes.labelIcon])}
                      />
                      <Typography className={classes.labelTree}>
                        Folder Sample
                      </Typography>
                    </Box>
                  }
                  classes={{
                    label: classes.labelTree,
                    content: classes.content,
                    root: classes.item,
                    selected: classes.selected,
                  }}
                  defaultExpanded
                >
                  <TreeItem
                    nodeId="2"
                    label={
                      <Box className={classes.labelTreeRoot}>
                        <Icon
                          className={clsx(["fas fa-folder", classes.labelIcon])}
                        />
                        <Typography className={classes.labelTree}>
                          Folder Sample
                        </Typography>
                      </Box>
                    }
                    classes={{
                      label: classes.labelTree,
                      content: clsx([classes.content, classes.contentChild]),
                      root: classes.item,
                      selected: classes.selected,
                    }}
                  />
                </TreeItem>
              </TreeView>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Send to (email address)
            </Typography>
            <CustomTextField />
          </Grid>
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.saveChanges" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

TransferSettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default TransferSettingsModal;

import React, { Fragment, memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from "@mui/lab";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import {
  FOLDER_LOCKED,
  FOLDER_WRITE_ACCESS,
  FOLDER_READ_ACCESS,
  RESOURCE_LOCKED,
} from "../../../constant/errorCodes";
import { useSnackbar } from "../../../hooks";
import FolderItem from "./FolderItem";
import InBetweenFolder from "./InBetweenFolder";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",    
    overflowY: "auto",
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
    },
    marginLeft: -8,
  },
  treeView: {
    flexGrow: 1,
  },
}));

const HomeMadeFolderTree = ({
  checkedFolders,
  defaultExpanded,
  expanded,
  folderPropertyFormValues,
  folderTree,
  handleCheckboxChange,
  handleContextMenu,
  handleOpenNode,
  handleSetEditNode,
  isEditMode,
  isMonitoring,
  moveFolderEnabled,
  onFolderRowClick,
  propertyFormValues,
  refetchFolders,
  selectedFolderIds,
  selectedNode,
  setCheckedFolders,
  setFolderPropertyFormValues,
  setFolderTree,
  setOpenedNodes,
  setPropertyFormValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const snackbar = useSnackbar();
  const [folderTreeRoots, setFolderTreeRoots] = useState([]);
  
  useEffect(() => {
    const newFolderTreeRoots = Object.values(folderTree).filter(x => x.isRoot);
    setFolderTreeRoots(newFolderTreeRoots);
  }, [folderTree]);

  const handleOnNodeToggle = (e, nodeIds) => {
    setOpenedNodes(nodeIds);
  };

  const handleError = (error, name) => {
    const { data, errorCode } = error;

    const person = data[0].value;

    if (errorCode === RESOURCE_LOCKED) {
      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: name, person }
        )
      );
    }

    if (errorCode === FOLDER_LOCKED) {
      const lockedFolder = data[1].value;

      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: lockedFolder, person }
        )
      );
    }

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }

    console.error(error.errorMessage);
  };

  return (
    <Box className={classes.root}>
      <TreeView
        className={classes.treeView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={defaultExpanded}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleOnNodeToggle}
      >
        <InBetweenFolder
          folderTree={folderTree}
          handleError={handleError}
          index={0}
          parentFolderId={null}
          refetchFolders={refetchFolders}
          setFolderTree={setFolderTree}
        />
        {folderTreeRoots.map((x, index) => (
          <Fragment key={`folder-tree-roots-${x.folderId}`}>
            <FolderItem
              key={`folder-item-${x.folderId}`}
              checkedFolders={checkedFolders}
              folder={folderTree[`f_${x.folderId}`]}
              folderTree={folderTree}
              handleContextMenu={handleContextMenu}
              handleOpenNode={handleOpenNode}
              handleSetEditNode={handleSetEditNode}
              isEditMode={isEditMode}
              moveFolderEnabled={moveFolderEnabled}
              onFolderRowClick={onFolderRowClick}
              refetchFolders={refetchFolders}
              selectedFolderIds={selectedFolderIds}
              selectedNode={selectedNode}
              setFolderTree={setFolderTree}
              handleCheckboxChange={handleCheckboxChange}
              handleError={handleError}
              folderPropertyFormValues={folderPropertyFormValues}
              setFolderPropertyFormValues={setFolderPropertyFormValues}
              propertyFormValues={propertyFormValues}
              setPropertyFormValues={setPropertyFormValues}
              setCheckedFolders={setCheckedFolders}
              isMonitoring={isMonitoring}
            />
            <InBetweenFolder
              key={`in-between-folder-${x.folderId}`}
              folderTree={folderTree}
              parentFolderId={x.parentFolderId}
              refetchFolders={refetchFolders}
              setFolderTree={setFolderTree}
              index={index + 1}
              handleError={handleError}
            />
          </Fragment>
        ))}
      </TreeView>
    </Box>
  );
};

HomeMadeFolderTree.defaultProps = {
  checkedFolders: [],
  handleCheckboxChange: () => { },
  handleContextMenu: () => {},
  handleOpenNode: () => {},
  handleSetEditNode: () => {},
  isEditMode: false,
  moveFolderEnabled: false,
  onFolderRowClick: () => {},
  refetchFolders: () => {},
  selectedFolderIds: [],
  selectedNode: null,
  setFolderTree: () => {},
  setOpenedNodes: () => {},
};

HomeMadeFolderTree.propTypes = {
  checkedFolders: PropTypes.arrayOf(PropTypes.number),
  defaultExpanded: PropTypes.arrayOf(PropTypes.number),
  expanded: PropTypes.arrayOf(PropTypes.number),
  folderTree: PropTypes.shape({}).isRequired,
  handleCheckboxChange: PropTypes.func,
  handleContextMenu: PropTypes.func,
  handleOpenNode: PropTypes.func,
  handleSetEditNode: PropTypes.func,
  isEditMode: PropTypes.bool,
  moveFolderEnabled: PropTypes.bool,
  onFolderRowClick: PropTypes.func,
  refetchFolders: PropTypes.func,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  selectedNode: PropTypes.shape({}),
  setFolderTree: PropTypes.func,
  setOpenedNodes: PropTypes.func,
};

export default memo(HomeMadeFolderTree);

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { useQuery, useLazyQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import {
  cartAddItem,
  setFileviewVisibleFields,
  setRefetchFiles,
  setRightColumnVisibility,
} from "../../actions";

import {
  DOCUMENT_VIEW,
  FILE_VIEW,
  IMAGE_VIEW,
  PRODUCT_LIST_VIEW,
} from "../../constant/types";

import {
  GET_FILE_VIEW_SETTINGS,
  GET_USER_FILE_VIEW_SETTINGS,
} from "../../graphql/queries";

import { getProducts } from "../../graphql/queries/product";
import { getRefetchFiles } from "../../helpers/fileViewSelectors";
import { useDeepLinkContext } from "../../hooks";

import SiteDashboard from "../dashboard/SiteDashboard";
import ProductViewSkeleton from "../Skeleton/ProductViewSkeleton";

import ProductsView from "./ProductsView";
import ProductsViewHeader from "./ProductsViewHeader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: 15,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  wrapperEmpty: {
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    // color: theme.palette.secondary.dark,
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ProductsMainView = ({
  // eslint-disable-next-line no-shadow
  cartAddItem,

  detailedSearchOpen,
  folderId,
  handleGetSiteFolders,
  numOfLinks,
  openedNodes,
  selectedWorkspace,
  setFolderId,
  setOpenedNodes,
  setView,
  siteRoleRights,
  sortingOptions: productViewSortingOptions,
  view,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const deepLinkContext = useDeepLinkContext();
  const { deepLink } = deepLinkContext;

  const refetchFiles = useSelector(getRefetchFiles);

  const [
    getUserFileViewSettings,
    { data: userFileViewSettingsData },
  ] = useLazyQuery(GET_USER_FILE_VIEW_SETTINGS);

  const [products, setProducts] = useState({ items: [] });
  const [productTotalCount, setProductTotalCount] = useState(0);
  const [ascending, setAscending] = useState(false);
  const [sortfield, setSortfield] = useState("");
  const [sortFieldOptions, setSortFieldOptions] = useState([]);
  const [breadcrumbs, setBreadCrumbs] = useState(true);
  const [openMetaDataModal, setOpenMetaDataModal] = useState(false);
  const [metaDataSectionVisible, setMetaDataSectionVisible] = useState(false);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [offset, setOffset] = useState(0);

  const [pageOverride, setPageOverride] = useState();

  const handleDeepLinkResourcesFetched = ({ newOffset }) => {
    if (newOffset) {
      const newPage = Math.floor(newOffset / perPage);
      setPageOverride(newPage);
    }
    else {
      setPageOverride();
    }
  };

  const handleChangeView = (newView) => {
    setPage(0);
    setPageOverride();
    setOffset(0);
    setView(newView);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageOverride();
    setOffset(newPage * perPage);
    if (pageOverride && newPage == page) {
      fetchData();
    }
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setOffset(0);
    setPageOverride();
    setPerPage(parseInt(event.target.value, 10));
  };

  const selectedSiteId =
    selectedWorkspace && selectedWorkspace.id ? selectedWorkspace.id : "";

  useEffect(() => {
    // KMF 01/12/019
    // TODO: We may need to refactor this. Move view settings from redux to component state.
    // For now, PRODUCT_LIST_VIEW, DOCUMENT_VIEW, IMAGE_VIEW is using the
    // product view settings. While File View is using file view settings.
    if (
      view === PRODUCT_LIST_VIEW ||
      view === DOCUMENT_VIEW ||
      view === IMAGE_VIEW
    ) {
      if (productViewSortingOptions && productViewSortingOptions.length > 0) {
        const sortFieldOptionsTemp = productViewSortingOptions.map(option => ({
          label: <FormattedMessage id={`sorting.${option.field}`} />,
          value: option.field,
        }));

        const defaultSort = productViewSortingOptions.find(x => x.default);
        if (defaultSort) {
          setAscending(defaultSort ? defaultSort.ascending : true);
          setSortfield(defaultSort.field);
        }
        setSortFieldOptions(sortFieldOptionsTemp);
      }
    }
  }, [productViewSortingOptions, view]);

  const { data: dataFileViewSettings } = useQuery(GET_FILE_VIEW_SETTINGS, {
    variables: {
      siteId: selectedWorkspace.id,
    },
    skip: !selectedWorkspace.id || view !== FILE_VIEW,
  });

  useEffect(() => {
    if (view === FILE_VIEW && selectedWorkspace && selectedWorkspace.id) {
      getUserFileViewSettings({ variables: { siteId: selectedWorkspace.id } });
    }
  }, [view, selectedWorkspace]);

  useEffect(() => {
    if (
      userFileViewSettingsData &&
      userFileViewSettingsData.siteSettingsContext &&
      userFileViewSettingsData.siteSettingsContext.getUserFileViewSettings
    ) {
      const {
        success,
        result,
      } = userFileViewSettingsData.siteSettingsContext.getUserFileViewSettings;
      if (success) {
        const { columnSettings, sidebarSetting } = result;
        dispatch(setFileviewVisibleFields({ fields: columnSettings }));
        dispatch(setRightColumnVisibility({ value: sidebarSetting }));
      }
    }
  }, [userFileViewSettingsData]);

  useEffect(() => {
    // KMF 01/12/019
    // TODO: We may need to refactor this. Move view settings from redux to component state.
    if (view === FILE_VIEW) {
      if (
        dataFileViewSettings &&
        dataFileViewSettings.fileView &&
        dataFileViewSettings.fileView.sortingOptions
      ) {
        const fileViewSortingOptions =
          dataFileViewSettings.fileView.sortingOptions;
        const sortFieldOptionsTemp = fileViewSortingOptions.map(option => ({
          label: <FormattedMessage id={`sorting.${option.field}`} />,
          value: option.field,
        }));

        const defaultSort = fileViewSortingOptions.find(x => x.default);
        if (defaultSort) {
          setAscending(defaultSort ? defaultSort.ascending : true);
          setSortfield(defaultSort.field);
        }
        setSortFieldOptions(sortFieldOptionsTemp);
      } else {
        setSortFieldOptions([]);
      }
    }
  }, [dataFileViewSettings, view]);

  const [getProductsData, { data, loading, error }] = useLazyQuery(getProducts);

  const prevFolderId = usePrevious(folderId);
  const prevView = usePrevious(view);

  const fetchData = () => {
    let productId;
    if (deepLink) {
      productId = deepLink.resourceId;
    }
    else {
      handleDeepLinkResourcesFetched({});
    }

    getProductsData({
      variables: {
        folderId,
        ascending,
        offset,
        pageSize: perPage,
        sortfield,
        breadcrumbs,
        productId,
      },
    });
  };

  useEffect(() => {
    if (
      folderId &&
      view !== FILE_VIEW &&
      (!prevFolderId || prevView === FILE_VIEW)
    ) {
      fetchData();
    }
  }, [folderId, view]);

  useEffect(() => {
    if (folderId && view !== FILE_VIEW) {
      fetchData();
    }
  }, [offset, perPage, sortfield, ascending]);

  useEffect(() => {
    if (refetchFiles && view !== FILE_VIEW) {
      fetchData();
      dispatch(setRefetchFiles({ refetch: false }));
    }
  }, [refetchFiles]);

  useEffect(() => {
    if (!loading && !error && data && data.products) {
      setProducts(data.products);
      setProductTotalCount(data.products.totalCount || 0);
    }
    if (error) {
      setProducts({ items: [] });
      setProductTotalCount(0);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (data && data.products) {
      const { offset: newOffset } = data.products;

      if (deepLink) {
        if (offset != newOffset) {
          handleDeepLinkResourcesFetched({ newOffset });
        }
        const { resourceId: productId } = deepLink;
        var resource = data.products.items.find(n => n.id == productId);
        if (!resource) {
          deepLinkContext.clearData();
        }
      }
    }
  }, [data, deepLink]);

  useEffect(() => {
    setProducts({ items: [] });
    setProductTotalCount(0);
    setAscending(false);
    setSortfield("");
    setSortFieldOptions([]);
  }, [selectedSiteId]);

  useEffect(() => {
    if (!detailedSearchOpen) {
      if (data && data.products) {
        setProducts(data.products);
      } else {
        setProducts({ items: [] });
      }
    }
  }, [detailedSearchOpen]);

  const showView = folderId || !isEmpty(products.items);

  return (
    <div className={classes.root}>
      {folderId && (
        <ProductsViewHeader
          ascending={ascending}
          detailedSearchOpen={detailedSearchOpen}
          folderId={folderId}
          metaDataSectionVisible={metaDataSectionVisible}
          numOfLinks={numOfLinks}
          offset={offset}
          onChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          page={page}
          perPage={perPage}
          productTotalCount={productTotalCount}
          setAscending={setAscending}
          setOpenMetaDataModal={setOpenMetaDataModal}
          setSortfield={setSortfield}
          setView={handleChangeView}
          siteRoleRights={siteRoleRights}
          sortfield={sortfield}
          sortFieldOptions={sortFieldOptions}
          view={view}
          openedNodes={openedNodes}
          handleGetSiteFolders={handleGetSiteFolders}
          pageOverride={pageOverride}
        />
      )}
      <div className={classes.productContentWrapper}>
        {loading && folderId ? (
          <ProductViewSkeleton />
        ) : (
          <>
            {showView ? (
                <ProductsView
                  siteId={selectedSiteId}
                ascending={ascending}
                cartAddItem={cartAddItem}
                detailedSearchOpen={detailedSearchOpen}
                error={error}
                folderId={folderId}
                offset={offset}
                openMetaDataModal={openMetaDataModal}
                perPage={perPage}
                products={products}
                setOpenMetaDataModal={setOpenMetaDataModal}
                siteRoleRights={siteRoleRights}
                view={view}
                metaDataSectionVisible={metaDataSectionVisible}
                setMetaDataSectionVisible={setMetaDataSectionVisible}
                setFolderId={setFolderId}
                openedNodes={openedNodes}
                setProductTotalCount={setProductTotalCount}
                onDeepLinkResourcesFetched={handleDeepLinkResourcesFetched}
              />
            ) : (
              <Box className={classes.wrapperEmpty}>
                <SiteDashboard
                  selectedWorkspace={selectedWorkspace}
                  setOpenedNodes={setOpenedNodes}
                  setFolderId={setFolderId}
                />
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedWorkspace: state.ui.toggleWorkspaces.workspace,
    sortingOptions: state.api.productView.productView.sortingOptions,
    siteRoleRights: state.ui.toggleWorkspaces.workspace.siteRoleRights,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      cartAddItem,
    },
    dispatch
  );
};

ProductsMainView.defaultProps = {
  detailedSearchOpen: null,
  folderId: null,
  numOfLinks: 3,
  openedNodes: [],
  siteRoleRights: null,
};

ProductsMainView.propTypes = {
  cartAddItem: PropTypes.func.isRequired,
  detailedSearchOpen: PropTypes.bool,
  folderId: PropTypes.number,
  numOfLinks: PropTypes.number,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  selectedWorkspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setFolderId: PropTypes.func.isRequired,
  setOpenedNodes: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  siteRoleRights: PropTypes.shape({
    allowMetadataTagging: PropTypes.bool,
    detailedImageView: PropTypes.bool,
    fileView: PropTypes.bool,
    imageView: PropTypes.bool,
    productView: PropTypes.bool,
  }),
  sortingOptions: PropTypes.arrayOf(
    PropTypes.shape({
      ascending: PropTypes.bool.isRequired,
      default: PropTypes.bool.isRequired,
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  view: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsMainView);

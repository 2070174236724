import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionHeader from "./SectionHeader";
import AddCommentSection from "./AddCommentSection";
import CommentList from "./CommentList";

const useStyles = makeStyles(() => ({
  root: {
    padding: 40,
    paddingTop: 0,
  },
  text: {
    color: "#555",
    fontSize: 18,
    marginBottom: 20,
    lineHeight: "32px",
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  panelRoot: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  panelSummaryRoot: {
    padding: 0,
    borderBottom: "2px solid #E3E3E3",
    "&.Mui-expanded": {
      margin: "0 !important",
      minHeight: "unset",
    },
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0 !important",
    },
  },
  panelDetailsRoot: {
    padding: 30,
    flexDirection: "column",
  },
}));

const CommentsSection = ({
  numberOfComments,
  currentUser,
  handleAddSiteComment,
  addSiteCommentLoading,
  handleCommentChange,
  commentText,
  comments,
  handleCommentLike,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Accordion classes={{ root: classes.panelRoot }}>
        <AccordionSummary
          classes={{
            root: classes.panelSummaryRoot,
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <SectionHeader numberOfComments={numberOfComments} />
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.panelDetailsRoot }}>
          <AddCommentSection
            currentUser={currentUser}
            handleAddSiteComment={handleAddSiteComment}
            loading={addSiteCommentLoading}
            handleCommentChange={handleCommentChange}
            commentText={commentText}
          />
          <CommentList
            comments={comments}
            loading={loading}
            handleCommentLike={handleCommentLike}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

CommentsSection.defaultProps = {
  loading: false,
  addSiteCommentLoading: false,
};

CommentsSection.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  numberOfComments: PropTypes.number.isRequired,
  handleCommentChange: PropTypes.func.isRequired,
  commentText: PropTypes.string.isRequired,
  handleAddSiteComment: PropTypes.func.isRequired,
  handleCommentLike: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  addSiteCommentLoading: PropTypes.bool,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CommentsSection;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import CommentsSkeleton from "../../Skeleton/CommentsSkeleton";
import CommentItem from "./CommentItem";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column-reverse",
    display: "flex",
  },
}));

const CommentList = ({ comments, loading, handleCommentLike }) => {
  const classes = useStyles();

  if (loading && comments.length < 1) {
    return <CommentsSkeleton />;
  }

  return (
    <Box className={classes.root}>
      {comments.map(x => {
        return (
          <CommentItem
            key={x.commentId}
            item={x}
            handleCommentLike={handleCommentLike}
          />
        );
      })}
    </Box>
  );
};

CommentList.defaultProps = {
  loading: false,
};

CommentList.propTypes = {
  loading: PropTypes.bool,
  handleCommentLike: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CommentList;

import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { useAlert } from "react-alert";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ImageList from "../../components/ImageBasket/ImageList";
import ImageOptions from "../../components/ImageBasket/ImageOptions";
import { imageBasketInitialize } from "../../actions";
import { REMOVE_IMAGE_ITEM } from "../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  noSiteText: {
    fontSize: 12,
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    textAlign: "center",
    backgroundColor: theme.palette.background.contrastGray,
  },
  noSiteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
    fontSize: 14,
    marginRight: 10,
    color: theme.palette.text.lightGray,
  },
}));

const ImageBasket = ({ imageBasketInitialize, imageBasketItems }) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const [removeItem] = useMutation(REMOVE_IMAGE_ITEM);

  const colorModelOptions = [
    {
      label: intl.formatMessage({
        id: "imageBasket.bitmap",
      }),
      value: "MONOCHROME",
    },
    {
      label: intl.formatMessage({
        id: "imageBasket.grayscale",
      }),
      value: "GRAY",
    },
    { label: "RGB", value: "RGB" },
    { label: "CMYK", value: "CMYK" },
  ];

  const fileFormatOptions = [
    { label: "JPEG", value: "JPEG" },
    { label: "TIFF", value: "TIFF" },
    { label: "EPS", value: "EPS" },
  ];

  const [allImageOption, setAllImageOption] = useState(false);
  const [selectImageOption, setSelectedImageOption] = useState(false);

  let eachImageArr = [];

  if (!selectImageOption) {
    eachImageArr = [];
  }

  const handleImageBasketItem = (params, idx) => {
    eachImageArr[idx] = params;
  };

  const handleOptions = option => {
    if (option === "all") {
      setAllImageOption(true);
      setSelectedImageOption(false);
    }

    if (option === "each") {
      setSelectedImageOption(true);
      setAllImageOption(false);
    }
  };

  const handleOnDeleteItem = async ({ productId, idx }) => {
    const imageBasket = [...imageBasketItems];
    imageBasket.splice(idx, 1);
    const newImageBasketItems = imageBasket.map(item => item);

    let res;
    try {
      res = await removeItem({
        variables: { orderGroupId: 0, productId },
      });
    } catch (err) {
      console.error("Failed to remove item:", err);
    }

    if (res && res.data && res.data.removeFromImageBank) {
      imageBasketInitialize({ items: newImageBasketItems });
      alert.success(
        <FormattedMessage id="sharedImageBasket.success.itemRemoved" />
      );
    } else {
      alert.error(
        <FormattedMessage id="sharedImageBasket.error.errorRemovingItem" />
      );
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <ImageList
            colorModelOptions={colorModelOptions}
            fileFormatOptions={fileFormatOptions}
            handleImageBasketItem={handleImageBasketItem}
            imageBasketItems={imageBasketItems}
            onDeleteItem={handleOnDeleteItem}
            selectImageOption={selectImageOption}
          />
        </Grid>
        <Grid item md={3}>
          <ImageOptions
            shareId={null}
            handleOptions={handleOptions}
            allImageOption={allImageOption}
            selectImageOption={selectImageOption}
            colorModelOptions={colorModelOptions}
            fileFormatOptions={fileFormatOptions}
            eachImageArr={eachImageArr}
            imageBasketItems={imageBasketItems}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    imageBasketItems: state.api.imageBasket.imageBasketItems,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      imageBasketInitialize,
    },
    dispatch
  );
};

ImageBasket.propTypes = {
  imageBasketInitialize: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
      settings: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageBasket);

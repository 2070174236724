import React, { useState } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  IconButton,
  Icon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import ConversationContainer from "./Channels/ConversationContainer";
import ChannelsContainer from "./Channels/ChannelsContainer";
import { useMutation } from "@apollo/client";
import StartConversationModal from "./StartConversationModal";
import { CREATE_CONVERSATION as CREATE_DIRECT_MESSAGE_CONVERSATION } from "../../graphql/mutations/DirectMessageContext";
import clsx from "clsx";
import MessageDialog from "./MessageDialog";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    height: "100%",
  },
  messagingContainer: {
    flex: 1,
    height: "100%",
  },
  messageSection: {
    display: "flex",
    flexGrow: 1,
  },
  channelsSection: {
    height: "100%",
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 16,
  },
}));

const MobileView = ({
  activeChannelId,
  activeConversationId,
  channels,
  channelsLoading,
  conversations,
  conversationsLoading,
  onChangeShowAllChannels,
  onChannelMessageAdded,
  onChannelSelected,
  onConversationAdded,
  onConversationMessageAdded,
  onConversationSelected,
  showAllChannelsAndWorkspaces,
  setSiteId,
  channel,
  conversation,
  channelLoading,
  conversationLoading,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newIndex) => {
    changeTab(newIndex);
  };

  const changeTab = newIndex => {
    //Uppdatera listor?
    setActiveTabIndex(newIndex);
  };

  const [startConversationVisible, setStartConversationVisible] = useState(
    false
  );
  const [showMessages, setShowMessages] = useState(false);

  const [createConversation] = useMutation(CREATE_DIRECT_MESSAGE_CONVERSATION);

  const handleOpenStartConversation = () => {
    setStartConversationVisible(true);
  };

  const handleCloseStartConversation = () => {
    setStartConversationVisible(false);
  };

  const handleMessageDialogClose = () => {
    setShowMessages(false);
  };

  const handleChannelSelected = c => {
    onChannelSelected(c);
    setShowMessages(true);
  };

  const handleConversationSelected = c => {
    onConversationSelected(c);
    setShowMessages(true);
  };

  const handleAddConversation = async userIds => {
    try {
      const res = await createConversation({
        variables: { userIds },
      });
      if (
        res &&
        res.data &&
        res.data.directMessageContext &&
        res.data.directMessageContext.createConversation
      ) {
        onConversationAdded({
          conversation: res.data.directMessageContext.createConversation,
        });
      }
    } catch (err) {
      console.error("error >", err);
    }

    setStartConversationVisible(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className={classes.root}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTabIndex}
          onChange={handleTabChange}
          aria-label="Channels and Users"
        >
          <Tab
            label={intl.formatMessage({ id: "messaging.channels" })}
            {...a11yProps(0)}
          />
          <Tab
            label={intl.formatMessage({ id: "messaging.users" })}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={activeTabIndex} index={0}>
        <ChannelsContainer
          setSiteId={setSiteId}
          activeChannelId={activeChannelId}
          onChannelMessageAdded={onChannelMessageAdded}
          onChannelSelected={handleChannelSelected}
          channelsLoading={channelsLoading}
          channels={channels}
        />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={1}>
        <IconButton onClick={handleOpenStartConversation}>
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
        </IconButton>
        <ConversationContainer
          activeConversationId={activeConversationId}
          conversations={conversations}
          loading={conversationsLoading}
          onConversationSelected={handleConversationSelected}
          onMessageAdded={onConversationMessageAdded}
        />
        <StartConversationModal
          open={startConversationVisible}
          handleClose={handleCloseStartConversation}
          onAddConversation={handleAddConversation}
        />
      </TabPanel>

      <MessageDialog
        open={showMessages}
        onClose={handleMessageDialogClose}
        activeChannelId={activeChannelId}
        activeConversationId={activeConversationId}
        channel={channel}
        channelLoading={channelLoading}
        conversation={conversation}
        conversationLoading={conversationLoading}
      />
    </div>
  );
};

export default MobileView;

import React from "react";
import { makeStyles } from "@mui/styles";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function RecursiveHelpTreeView(props) {
  const classes = useStyles();

  const renderTree = (nodes, onClick) =>
    nodes &&
    Array.isArray(nodes) &&
    nodes.length > 0 &&
    nodes.map(node => (
      <TreeItem
        key={node.id}
        nodeId={node.id}
        label={node.label}
        onClick={e => onClick(node.id)}
      >
        {node.children && renderTree(node.children, onClick)}
      </TreeItem>
    ));

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem key={"dummy_item"} nodeId={"root"} label="Fler hjälpavsnitt">
        {renderTree(props.data, props.onClick)}
      </TreeItem>
    </TreeView>
  );
}

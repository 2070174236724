import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  folderName: {
    fontSize: 11,
    marginLeft: 7,
    marginTop: 1,
    paddingRight: "4em",
  },
}));

const FolderName = ({ name }) => {
  const classes = useStyles();

  return (
    <span
      className={classes.folderName}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: name }}
    />
  );
};

FolderName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(FolderName);

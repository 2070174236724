import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import PagesPanel from "./PagesPanel";
import CategoriesPanel from "./CategoriesPanel";
import CustomLinksPanel from "./CustomLinksPanel";
import Spacer from "./Spacer";

const useStyles = makeStyles(() => ({
  sidebar: {
    borderRight: "1px solid #f0f0f0",
  },
  sidebarHead: {
    padding: 10,
    fontSize: 12,
    fontWeight: 600,
  },
  pagesContainer: {},
  root: {
    height: "100%",
    overflow: "auto",
    backgroundColor: "#F5F5F5",
  },
}));

const EditMenuSidebar = ({
  siteMenuPages,
  handlePageCheckboxChange,
  handlePageAddToMenu,
  handleAddToMenu,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={3} className={classes.root}>
      <Box className={classes.sidebar}>
        <Box className={classes.sidebarHead}>
          <FormattedMessage id="siteRolesMenu.addMenuItems" />
        </Box>
        <Box className={classes.pagesContainer}>
          <PagesPanel
            siteMenuPages={siteMenuPages}
            handlePageCheckboxChange={handlePageCheckboxChange}
            handlePageAddToMenu={handlePageAddToMenu}
          />
          <CustomLinksPanel />
          <Spacer />
          <CategoriesPanel handleAddToMenu={handleAddToMenu} />
        </Box>
      </Box>
    </Grid>
  );
};

EditMenuSidebar.propTypes = {
  handlePageCheckboxChange: PropTypes.func.isRequired,
  handlePageAddToMenu: PropTypes.func.isRequired,
  siteMenuPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default memo(EditMenuSidebar);

import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_COMPANY_SUPPLIER_SETTINGS } from "../graphql/queries/CompanyAdminContext";
import {
  setSupplierSettings,
  setSupplierSettingsLoading,
  setSupplierSettingsError,
} from "../actions";

// eslint-disable-next-line import/prefer-default-export
export const useGetCompanySupplierSettings = () => {
  const [
    getCompanySupplierSettings,
    { data, loading, error, refetch },
  ] = useLazyQuery(GET_COMPANY_SUPPLIER_SETTINGS);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      data &&
      data.companyAdminContext &&
      data.companyAdminContext.companySuppliers &&
      data.companyAdminContext.companySuppliers.companySupplierSettings
    ) {
      dispatch(
        setSupplierSettings({
          items:
            data.companyAdminContext.companySuppliers.companySupplierSettings,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setSupplierSettingsLoading({
        loading,
      })
    );
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(
        setSupplierSettingsError({
          error,
        })
      );
    }
  }, [error]);

  return { execute: getCompanySupplierSettings, data, loading, refetch };
};

import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    // boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    // border: "1px solid #eee",
    height: "100%",
  },
}));

const DashboardWidget = ({ children, customClass, fontColor }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx([classes.root, customClass])}
      style={{ color: fontColor }}
    >
      {children}
    </Box>
  );
};

export default DashboardWidget;

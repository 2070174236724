const getFolderFromChildFolders = ({ childFolders, initialFolderId }) => {
  const found = childFolders.find(x => x.folderId === initialFolderId);

  if (found) {
    return found;
  }

  const childFolderFound = childFolders.find(x => {
    if (
      x.childFolders &&
      Array.isArray(x.childFolders) &&
      x.childFolders.length > 0
    ) {
      return getFolderFromChildFolders({
        childFolders: x.childFolders,
        initialFolderId,
      });
    }

    return null;
  });

  return childFolderFound;
};

const getFolderFromSiteFoldersByFolderId = ({ folders, initialFolderId }) => {
  if (folders && Array.isArray(folders)) {
    let initialFolder = null;
    folders.forEach(folder => {
      if (initialFolder) return;

      const { folderId } = folder;
      if (folderId === initialFolderId) {
        initialFolder = { ...folder };
      }

      const f = getFolderFromChildFolders({
        childFolders: folder.childFolders,
        initialFolderId,
      });

      if (f) {
        initialFolder = { ...f };
      }
    });

    return initialFolder;
  }
  return null;
};

export default getFolderFromSiteFoldersByFolderId;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const ADD_EXTERNAL_USER = gql`
  mutation AddExternalUser(
    $targetCompanyId: Int!
    $targetRoleId: Int!
    $username: String
  ) {
    companyAdminContext {
      externalUsers {
        addExternalUser(
          targetCompanyId: $targetCompanyId
          targetRoleId: $targetRoleId
          username: $username
        ) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default ADD_EXTERNAL_USER;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import clsx from "clsx";
import { Icon } from "@mui/material";
import PageRoute from "../../../constant/pageRouteDictionary.json";

const useStyles = makeStyles(theme => ({
  menuItem: {
    padding: "5px 14px 5px 7px",
    minHeight: "unset",
  },
  menuItemFirst: {
    padding: "5px 14px",
    minHeight: "unset",
  },
  notify: {
    position: "relative",
    cursor: "pointer",
    marginTop: 7,
    color: theme.palette.component.hamburger,
  },
  notifyIcon: {
    fontSize: 14,
    cursor: "pointer",
  },
  notifyTitle: {
    margin: "0 0 0 20px",
    fontSize: 12,
  },
  badge: {
    backgroundColor: theme.palette.component.badgeBackground,
    position: "absolute",
    textAlign: "center",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    borderRadius: "50%",
    padding: "1px 5px",
    fontWeight: "700",
    left: 10,
    bottom: 12,
    cursor: "pointer",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  noSiteText: {
    fontSize: 11,
    padding: "5px 10px",
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 4,
    width: 105,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  noSiteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
    fontSize: 12,
    marginRight: 5,
    color: theme.palette.text.lightGray,
  },
  mobileRightMenu: {
    padding: 0,
    marginLeft: 10,
  },
  notifyLink: {
    textDecoration: "none",
  },
  noSiteWrap: {
    width: "100%",
    marginTop: 15,
    justifyContent: "center",
  },
}));

const MobileMenu = ({ selectedWorkspace, totalImageItems, totalCartItems }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <div
      className={clsx([
        classes.sectionMobile,
        !selectedWorkspace.id && classes.noSiteWrap,
      ])}
    >
      {false && !selectedWorkspace.id && (
        <Typography className={classes.noSiteText}>
          <Icon
            className={clsx(["fa fa-exclamation-circle", classes.noSiteIcon])}
          />
          <FormattedMessage id="common.pleaseSelectSiteFirst" />
        </Typography>
      )}
      {selectedWorkspace.id > 0 && (
        <Link className={classes.notifyLink} to={PageRoute.MESSAGING}>
          <MenuItem className={classes.menuItemFirst}>
            <div className={classes.notify}>
              <i className={`nc-icon nc-chat-33 ${classes.notifyIcon}`} />
              <span className={classes.badge}>{1}</span>
            </div>
          </MenuItem>
        </Link>
      )}
      {selectedWorkspace.id && totalImageItems > 0 && (
        <Link className={classes.notifyLink} to={PageRoute.IMAGE_BASKET}>
          <MenuItem className={classes.menuItem}>
            <div className={classes.notify}>
              <i className={`nc-icon nc-album-2 ${classes.notifyIcon}`} />
              <span className={classes.badge}>{totalImageItems}</span>
            </div>
          </MenuItem>
        </Link>
      )}
      {selectedWorkspace.id && (
        <Link className={classes.notifyLink} to={PageRoute.CHECKOUT}>
          <MenuItem className={classes.menuItem}>
            <div className={classes.notify}>
              <i className={`nc-icon nc-cart-simple ${classes.notifyIcon}`} />
              <span className={classes.badge}>{totalCartItems}</span>
            </div>
          </MenuItem>
        </Link>
      )}
    </div>
  );
};

MobileMenu.propTypes = {
  selectedWorkspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  totalImageItems: PropTypes.number.isRequired,
  totalCartItems: PropTypes.number.isRequired,
};

export default MobileMenu;

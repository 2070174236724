import { gql } from '@apollo/client';

const UPDATE_COMPANY_SETTINGS = gql`
  mutation UpdateCompanySettings(
    $companyId: Int!
    $defaultTheme: String
    $avatarFolderId: Int
    $newsImageFolderId: Int
    $messageAttachmentsFolderId: Int
    $siteTemplateFolderId: Int
  ) {
    companyAdminContext {
      settings {
        updateCompanySettings(
          companyId: $companyId
          defaultTheme: $defaultTheme
          avatarFolderId: $avatarFolderId
          newsImageFolderid: $newsImageFolderId
          messageAttachmentsFolderId: $messageAttachmentsFolderId
          siteTemplateFolderId: $siteTemplateFolderId
        ) {
          companyId
        }
      }
    }
  }
`;

export default UPDATE_COMPANY_SETTINGS;

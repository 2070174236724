import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
} from "@mui/material";
import clsx from "clsx";
import Sample from "../../../assets/img/sweden-1.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #f1f1f1",
    marginTop: 20,
  },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  tdImg: {
    width: 100,
    textAlign: "center",
  },
  img: {
    width: 80,
    objectFit: "cover",
    verticalAlign: "middle",
  },
  iconButtonCopy: {
    padding: 4,
    color: theme.palette.common.link,
    marginLeft: 10,
  },
}));

const UploadedImagesTable = () => {
  const classes = useStyles();

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.th} />
          <TableCell className={classes.th}>Name</TableCell>
          <TableCell className={classes.th}>URL</TableCell>
          <TableCell className={classes.th} />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tdImg}>
            <img src={Sample} alt="Sample" className={classes.img} />
          </TableCell>
          <TableCell className={classes.td}>Sample.png</TableCell>
          <TableCell className={classes.td}>
            https://www.uniqueue.se/UniQueue3/Content/custom/197/lim.png
            <IconButton className={classes.iconButtonCopy}>
              <Icon className={clsx(["fa fa-copy", classes.icon])} />
            </IconButton>
          </TableCell>
          <TableCell className={classes.td} align="right">
            <IconButton className={classes.iconButtonDelete}>
              <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default UploadedImagesTable;

import { gql } from '@apollo/client';

const ProjectViews = gql`
  fragment ProjectViews on ProjectViews {
    __typename

    viewId
    name
    type
    jsonViewSettings
  }
`;

export default ProjectViews;

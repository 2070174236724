import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    minWidth: "unset",
    color: theme.palette.common.blue,
    marginRight: 6,
    "&:hover": {
      backgroundColor: "transparent",
      opacity: "0.7",
    },
  },
  icon: {
    fontSize: 12,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  btnText: {
    fontSize: 12,
  },
}));

const IconLink = ({
  icon,
  onClick,
  customClass,
  customTextClass,
  text,
  title,
  placement,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={title} placement={placement}>
      <IconButton
        className={classes.root}
        onClick={onClick}
        disabled={disabled}
      >
        <Icon className={clsx([icon, customClass, classes.icon])} />
        <span className={clsx([customTextClass, classes.btnText])}>{text}</span>
      </IconButton>
    </Tooltip>
  );
};

export default IconLink;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { IconTextLink } from "../../components/common";
import OrderCheck from "../../assets/img/order-check.svg";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 60,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 2,
    },
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    textAlign: "center",
  },
  text: {
    fontSize: 14,
  },
  mainText: {
    fontSize: 20,
    fontWeight: "700",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  mb4: {
    marginBottom: 20,
  },
  iconContainer: {
    marginTop: 30,
    marginBottom: 20,
  },
}));

const OrderConfirmation = ({ location, history }) => {
  const classes = useStyles();
  const orderNumber =
    location.state && location.state.orderNo ? location.state.orderNo : 0;
  if (orderNumber === 0) {
    return "";
  }

  const companyOrderNo =
    location.state && location.state.companyOrderNo
      ? location.state.companyOrderNo
      : 0;
  if (companyOrderNo === 0) {
    return "";
  }

  const thankYouMessage = location.state.cartThankYouMessage;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item sm={10} md={8}>
          <Paper className={classes.paper}>
            <Box className={classes.iconContainer}>
              <img
                src={OrderCheck}
                alt="Empty Cart"
                className={classes.cartEmptyImg}
              />
            </Box>
            <Box>
              <Box className={classes.mb4}>
                <Typography
                  variant="h3"
                  color="primary"
                  className={classes.mainText}
                >
                  <FormattedMessage id="orderConfirm.thankYou" />
                </Typography>
              </Box>
              <Box className={classes.mb4}>
                <Typography
                  variant="h3"
                  color="primary"
                  className={classes.text}
                >
                  <FormattedMessage id="orderConfirm.yourOrderNumberIs" />{" "}
                  <strong>{companyOrderNo}</strong>
                </Typography>
              </Box>
              <Box className={classes.mb4}>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.text}
                  dangerouslySetInnerHTML={{ __html: thankYouMessage }}
                />
              </Box>
            </Box>
            <Box className={classes.footer}>
              <IconTextLink
                onClick={() => history.push("./")}
                iconClass="fa fa-angle-double-left"
                text={<FormattedMessage id="orderConfirm.linkBack" />}
                fontSize={14}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

OrderConfirmation.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      orderNo: PropTypes.number.isRequired,
      companyOrderNo: PropTypes.number,
      cartThankYouMessage: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default OrderConfirmation;

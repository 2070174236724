import React, { memo } from "react";
import PropTypes from "prop-types";
import RowData from "./RowData";

const FolderItem = ({
  checkedFolders,
  disabled,
  folder,
  folderRights,
  folderTree,
  handleCheckboxChange,
  handleOpenNode,
  setFolderTree,
}) => {
  const renderChildFolders = () => {
    if (
      folder &&
      folder.childFolderIds &&
      Array.isArray(folder.childFolderIds)
    ) {
      return (
        <>
          {folder.childFolderIds.map((x, index) => (
            <React.Fragment key={`folder-item-divider-${x}`}>
              <FolderItem
                key={`folder-item-${x}`}
                checkedFolders={checkedFolders}
                disabled={disabled}
                folder={folderTree[`f_${x}`]}
                folderRights={folderRights}
                folderTree={folderTree}
                handleCheckboxChange={handleCheckboxChange}
                handleOpenNode={handleOpenNode}
                setFolderTree={setFolderTree}
              />
            </React.Fragment>
          ))}
        </>
      );
    }
    return null;
  };

  if (
    folder &&
    folder.childFolderIds &&
    Array.isArray(folder.childFolderIds) &&
    folder.childFolderIds.length > 0
  ) {
    return (
      <RowData
        checkedFolders={checkedFolders}
        disabled={disabled}
        folder={folder}
        folderRights={folderRights}
        folderTree={folderTree}
        handleCheckboxChange={handleCheckboxChange}
        handleOpenNode={handleOpenNode}
        setFolderTree={setFolderTree}
      >
        {renderChildFolders()}
      </RowData>
    );
  }

  return (
    <RowData
      checkedFolders={checkedFolders}
      disabled={disabled}
      folder={folder}
      folderRights={folderRights}
      folderTree={folderTree}
      handleCheckboxChange={handleCheckboxChange}
      handleOpenNode={handleOpenNode}
      setFolderTree={setFolderTree}
    />
  );
};

FolderItem.defaultProps = {
  disabled: false,
  handleOpenNode: () => {},
  selectedNode: null,
};

FolderItem.propTypes = {
  selectedNode: PropTypes.shape({}),
  disabled: PropTypes.bool,
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  folderTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleOpenNode: PropTypes.func,
  handleCheckboxChange: PropTypes.func.isRequired,
  setFolderTree: PropTypes.func.isRequired,
};

export default memo(FolderItem);

import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../hooks";
import { useIntl } from "react-intl";
import { MOVE_FOLDER } from "../../../graphql/mutations";

const useStyles = makeStyles(() => ({
  divider: {
    padding: 0,
  },
  dividerNormal: {
      backgroundColor: "transparent",
      borderColor: "transparent"
  },
  dividerDraggedOver: {
    backgroundColor: "transparent",
    marginLeft: 27,
    padding: "6px 0",
  },
  normal: {},
  draggedOver: { overflow: "hidden" },
  icon: {
    fontSize: 14,
    width: 14,
    height: 14,
    overflow: "unset",
    color: "#888",
    position: "absolute",
    marginTop: 0,
    marginLeft: 15,
  },
  iconDivide: {
    height: 2,
    position: "absolute",
    left: 25,
    top: -3,
    backgroundColor: "#888",
    margin: "8px 0",
    width: "100%",
  },
  iconWrap: {
    position: "relative",
  },
}));

const InBetweenFolder = ({
  parentFolderId,
  folderTree,
  setFolderTree,
  refetchFolders,
  index,
  handleError,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [draggedOver, setDraggedOver] = useState(false);
  const [moveFolder] = useMutation(MOVE_FOLDER);

  const handleMoveFolder = async e => {
    //const parentFolder = parentFolderId ? folderTree[parentFolderId] : null;
    const getParentFolderId = parentFolderId => {
      var folder = parentFolderId ? folderTree["f_" + parentFolderId] : null;
      return folder ? folder.folderId : null;
    };

    const draggedFolder = e.dataTransfer.getData("dragged_folder");
    let draggedFolderJSON = JSON.parse(draggedFolder);

    const targetFolderId = getParentFolderId(parentFolderId);
    // const draggedFolderParentId = draggedFolderJSON.parentFolderId;

    // Start: Will create the illusion that the folder is moved instantly

    // draggedFolderJSON = {
    //   ...draggedFolderJSON,
    //   parentFolderId: targetFolderId
    // };

    // let newFolderTreeData = { ...folderTree };
    // if (targetFolderId) {
    //   const newParentFolder = { ...newFolderTreeData[targetFolderId] };

    //   newFolderTreeData = {
    //     ...newFolderTreeData,
    //     [draggedFolderJSON.folderId]: { ...draggedFolderJSON },
    //     [targetFolderId]: {
    //       ...newParentFolder,
    //       childFolderIds: [
    //         ...newParentFolder.childFolderIds,
    //         draggedFolderJSON.folderId
    //       ]
    //     }
    //   };
    // } else {
    //   newFolderTreeData = {
    //     ...newFolderTreeData,
    //     [draggedFolderJSON.folderId]: { ...draggedFolderJSON, isRoot: true }
    //   };
    // }

    // if (draggedFolderParentId) {
    //   let draggedFolderParentFolder = folderTree[draggedFolderParentId];
    //   let newParentFolderChildIds = [];
    //   if (draggedFolderParentFolder) {
    //     newParentFolderChildIds =
    //       draggedFolderParentFolder && draggedFolderParentFolder.childFolderIds
    //         ? draggedFolderParentFolder.childFolderIds.filter(
    //             x => x !== draggedFolderJSON.folderId
    //           )
    //         : [];
    //   }

    //   draggedFolderParentFolder = {
    //     ...draggedFolderParentFolder,
    //     childFolderIds: newParentFolderChildIds
    //   };
    //   newFolderTreeData = {
    //     ...newFolderTreeData,
    //     [draggedFolderParentId]: draggedFolderParentFolder
    //   };
    // }

    // setFolderTree(newFolderTreeData);
    // End: Will create the illusion that the folder is moved instantly
    if (draggedFolderJSON.folderId !== targetFolderId) {
      try {
        const res = await moveFolder({
          variables: {
            folderId: draggedFolderJSON.folderId,
            parentFolderId: targetFolderId,
            position: index,
          },
        });

        if (res && res.data && res.data.moveFolder) {
          const { success } = res.data.moveFolder;
          // Don't refetch folder first. We might not get the right folder order/position after moving.
          if (success) {
            refetchFolders();
            alert.success(
              intl.formatMessage({
                id: "folder.folderMovedSuccessful",
              })
            );
          } else {
            const { errorResult } = res.data.moveFolder;
            handleError(errorResult);
          }
        }
      } catch (err) {
        snackbar.workspaceError("An error occurred while moving the folder.");
      }
    }
  };

  const handleDrop = (e, item) => {
    e.stopPropagation();
    setDraggedOver(false);
    const action = e.dataTransfer.getData("action");
    if (action === "MOVE_FOLDER") {
      handleMoveFolder(e, item);
    }
  };

  const handleOnDragOver = (e, item) => {
    e.preventDefault();
    setDraggedOver(true);
  };
  const handleDragLeave = (e, item) => {
    e.preventDefault();
    setDraggedOver(false);
  };

  return (
    <Box
      className={clsx([draggedOver ? classes.draggedOver : classes.normal])}
      onDrop={e => handleDrop(e)}
      onDragOver={handleOnDragOver}
      onDragLeave={handleDragLeave}
    >
      {draggedOver && (
        <Box className={classes.iconWrap}>
          <Icon className={clsx(["fas fa-angle-right", classes.icon])} />
          <Box className={classes.iconDivide} />
        </Box>
      )}
      <Divider
        className={clsx([
          classes.divider,
          draggedOver ? classes.dividerDraggedOver : classes.dividerNormal,
        ])}
      />
    </Box>
  );
};

InBetweenFolder.defaultProps = {
  parentFolderId: null,
};

InBetweenFolder.propTypes = {
  parentFolderId: PropTypes.number,
  folderTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setFolderTree: PropTypes.func.isRequired,
  refetchFolders: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default memo(InBetweenFolder);

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Icon,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
} from "@mui/material";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";
import ManageRolesTableRow from "./ManageRolesTableRow";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
}));

const Folders = ({
  colorSchemes,
  disabled,
  handleCheckbox,
  handleOpenColorSets,
  handleSelect,
  roleSubscriptionLevel,
  siteRoleValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [colorOptions, setColorOptions] = useState([]);

  const renderColorSchemeLabel = colorScheme => {
    return (
      <>
        <Icon
          className={"fa fa-folder"}
          style={{
            color: colorScheme.defaultColor,
            fontSize: "unset",
            margin: "0px 5px 1px 0px",
          }}
        />
        {colorScheme.name}
      </>
    );
  };

  useEffect(() => {
    if (colorSchemes) {
      const tempValues = colorSchemes.map(x => {
        return { label: renderColorSchemeLabel(x), value: x.siteColorSetId };
      });

      setColorOptions(tempValues);
    }
  }, [colorSchemes]);

  const headers = [
    {
      label: intl.formatMessage({ id: "siteRoles.canAddToBasket" }),
      name: "canAddToBasket",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({ id: "siteRoles.allowMetadataTagging" }),
      name: "allowMetadataTagging",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.showOnlyOwnFiles",
      }),
      name: "showOnlyOwnFiles",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canUploadFiles",
      }),
      name: "canUploadFiles",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.attestFiles",
      }),
      name: "attestFiles",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.uploadFilesWithoutAttest",
      }),
      name: "uploadFilesWithoutAttest",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canUnlockForOthers",
      }),
      name: "canUnlockForOthers",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canShareDownloadFolders",
      }),
      checkbox: true,
      name: "shareFolders",
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canLock",
      }),
      checkbox: true,
      name: "canLock",
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canDownloadFilesAndFolders",
      }),
      checkbox: true,
      name: "canDownloadFilesAndFolders",
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canEditFolders",
      }),
      checkbox: true,
      name: "directoryCRUD",
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canEditFolderColors",
      }),
      checkbox: true,
      name: "canChangeFolderIcons",
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.folderColorScheme",
      }),
      name: "folderColorScheme",
      options: colorOptions,
      checkbox: false,
      edit: true,
      hideLabel: true,
      toolTipInfo: true,
    },
  ];

  return (
    <Grid item xs={12} md={6}>
      <Typography className={classes.text}>
        <FormattedMessage id="siteRoles.filesFolders" />
      </Typography>
      <Divider className={classes.divider} />
      <Table className={classes.table} size="small">
        <TableBody>
          {headers.map(head => {
            const tooltip = head && head.tooltip ? head.tooltip : false;
            const tooltipTitle =
              head && head.tooltipTitle ? head.tooltipTitle : "";
            const edit = head && head.edit ? head.edit : false;
            const hideLabel = head && head.hideLabel ? head.hideLabel : false;
            const toolTipInfo = head.toolTipInfo ? head.toolTipInfo : false;
            return (
              <ManageRolesTableRow
                checkbox={head.checkbox}
                disabled={disabled}
                editIcon={edit}
                hideLabel={hideLabel}
                key={head.name}
                label={head.label}
                name={head.name}
                onChange={
                  head.options && head.options.length > 0
                    ? handleSelect
                    : handleCheckbox
                }
                onClick={handleOpenColorSets}
                options={head.options}
                roleSubscriptionLevel={roleSubscriptionLevel}
                siteRoleValues={siteRoleValues}
                tooltip={tooltip}
                toolTipInfo={toolTipInfo}
                tooltipTitle={tooltipTitle}
              />
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

Folders.defaultProps = {
  colorSchemes: [],
  disabled: false,
  siteRoleValues: {},
};

Folders.propTypes = {
  colorSchemes: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  handleCheckbox: PropTypes.func.isRequired,
  handleOpenColorSets: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  siteRoleValues: PropTypes.shape({}),
};

export default Folders;

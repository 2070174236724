import { withStyles } from "@mui/styles";
import { Button } from "@mui/material";

const AddSupplierBtn = withStyles(theme => ({
  root: {
    fontWeight: 600,
    marginTop: 10,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
}))(Button);

export default AddSupplierBtn;

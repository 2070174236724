import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Button, Icon } from "@mui/material";

const useStyles = makeStyles(theme => ({
  btnAdd: {
    padding: "5px 6px 5px 10px",
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
      opacity: 0.8,
    },
    "&.MuiButton-root.Mui-disabled": {
      color: `${theme.palette.primary.contrastText}!important`,
    },
  },
  btnAddIcon: {
    fontSize: 12,
    marginLeft: 8,
    verticalAlign: "middle",
  },
}));

export default function AddMenuButton({ onClickSubmit, disabled }) {
  const classes = useStyles();

  return (
    <Button
      className={classes.btnAdd}
      onClick={onClickSubmit}
      disabled={disabled}
    >
      <FormattedMessage id="btn.addToMenu" />
      <Icon className={clsx(["fa fa-angle-right", classes.btnAddIcon])} />
    </Button>
  );
}

AddMenuButton.defaultProps = {
  disabled: false,
};

AddMenuButton.propTypes = {
  disabled: PropTypes.bool,
  onClickSubmit: PropTypes.func.isRequired,
};

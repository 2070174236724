// FOLDER ERROR CODES
export const RESOURCE_LOCKED = "RESOURCE_LOCKED";
export const FOLDER_LOCKED = "FOLDER_LOCKED";
export const FOLDER_WRITE_ACCESS = "FOLDER_WRITE_ACCESS";
export const FOLDER_READ_ACCESS = "FOLDER_READ_ACCESS";

//common ERRORS
export const commonErrors = {
  ALREADY_EXISTS: "ALREADY_EXISTS",
  INVALID_EMAIL: "INVALID_EMAIL",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
  NOT_FOUND: "NOT_FOUND",
  PASSWORD_ERROR: "PASSWORD_ERROR",
  UNSPECIFIED_ERROR: "UNSPECIFIED_ERROR",
  VALIDATION_ERROR: "VALIDATION_ERROR",
};

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

const SearchAutoComplete = ({
  customClass,
  customClasses,
  onChange,
  open,
  options,
  label,
  loading,
  multiple,
  placeholder,
  required,
  setClose,
  setOpen,
  setValue,
  size,
  value,
  renderValue,
  ...rest
}) => {

  const selectedValues = React.useMemo(() => value, [value]);
  return (
    <Autocomplete
      classes={customClasses}
      className={customClass}
      disablePortal
      filterSelectedOptions
      getOptionLabel={option => {

        if (renderValue) {
          return renderValue(option)
        }

        return option.label ?? "";

      }



      }
      loading={loading}
      multiple={multiple}
      noOptionsText={<FormattedMessage id="product.noOptions" />}
      onChange={(event, newValue, reason) => {

        if (setValue) {
          setValue(newValue);
        }


      }}
      onClose={setClose}
      onInputChange={(event, newInputValue, reason) => {
        if (onChange) {
          onChange(newInputValue);
        }
      }}
      onOpen={setOpen}
      open={open}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          variant="outlined"
          {...rest}
        />
      )}
      size={size}
      value={selectedValues}
    />);
};

SearchAutoComplete.defaultProps = {
  options: [],
  value: [],
  placeholder: null,
  required: false,
  multiple: false,
  size: "small",
  customClass: null,
  customClasses: null,
};

SearchAutoComplete.propTypes = {
  size: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  customClass: PropTypes.shape({}),
  customClasses: PropTypes.shape({}),
};

export default SearchAutoComplete;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import CustomTextField from "../common/TextField";
import CustomSelect from "../common/CustomSelect";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import {
  useUpdateItemsShoppingCart,
  useGetSelectableSuppliers,
} from "../../hooks";
import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "95%",
    },
  },
  btn: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 3,
    },
  },
  text: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  label: {
    fontSize: 12,
  },
  formControlUpload: {
    borderRadius: 4,
    border: "1px solid #ddd",
    padding: 10,
    marginBottom: 20,
  },
  btnFile: {
    padding: "3px 10px",
    fontSize: 10,
    fontWeight: 400,
    textTransform: "none",
    boxShadow: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.8,
    },
  },
  input: {
    display: "none",
  },
}));

const UpdateRowsModal = ({ open, handleClose, selectedItemIds = [] }) => {
  const classes = useStyles();
  const alert = useAlert();
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [supplierId, setSupplierId] = useState();
  const [quantity, setQuantity] = useState(1);
  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const { selectableSuppliers, loading, error } = useGetSelectableSuppliers({
    orderGroupId,
    selectedItemIds,
  });

  const {
    execute: executeUpdateItems,
    loading: loadingUpdateItems,
  } = useUpdateItemsShoppingCart();

  useEffect(() => {
    if (loading || error) {
      setSupplierOptions([]);
      return;
    }

    const newOptions = selectableSuppliers.map(x => ({
      label: x.displayName,
      value: x.siteSupplierId,
    }));
    setSupplierOptions([...newOptions]);
  }, [selectableSuppliers, loading, error]);

  const onQuantityChange = e => {
    if (parseInt(e.target.value, 10) === 0) {
      setQuantity(1);
    } else {
      setQuantity(parseInt(e.target.value, 10));
    }
  };

  const onSupplierChange = e => {
    setSupplierId(e.target.value);
  };

  const successUpdateItems = () => {
    handleClose();
    alert.success(<FormattedMessage id="product.successUpdatedItemCart" />);
  };

  const errorUpdateItems = () => {
    alert.error(<FormattedMessage id="product.failUpdatedItemCart" />);
  };

  const handleUpdateMultipleItems = () => {
    const input = selectedItemIds.map(x => ({
      shoppingCartItemId: x,
      quantity,
      siteSupplierId: supplierId,
    }));

    executeUpdateItems({ input, successUpdateItems, errorUpdateItems });
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title="Uppdatera markerade rader"
      />
      <DraggableModalBody>
        {supplierOptions.length > 0 && (
          <Box className={classes.formControl}>
            <Typography className={classes.label}>
              <FormattedMessage id="supplier.supplier" />
            </Typography>
            <CustomSelect
              value={supplierId}
              options={supplierOptions}
              onChange={onSupplierChange}
            />
          </Box>
        )}

        <Box className={classes.formControl}>
          <Typography className={classes.label}>
            <FormattedMessage id="cart.quantity" />
          </Typography>
          <CustomTextField
            type="number"
            value={quantity}
            onChange={onQuantityChange}
            inputProps={{ min: 1 }}
          />
        </Box>
        <Box>
          <Button
            disabled={loadingUpdateItems}
            fullWidth
            className={classes.btn}
            onClick={handleUpdateMultipleItems}
          >
            {loadingUpdateItems ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              <FormattedMessage id="common.apply" />
            )}
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default UpdateRowsModal;

UpdateRowsModal.defaultProps = { selectedItemIds: [] };

UpdateRowsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string),
};

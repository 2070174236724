import { handleActions } from "redux-actions";
import { setFolderData, setFolderId } from "../../../../actions";

const defaultState = {
  data: {
    locked: false,
    write: false,
  },
  folderId: null,
};

export default handleActions(
  {
    [setFolderData]: (state, { payload }) => {
      const { folder } = payload;
      return {
        ...state,
        data: folder,
      };
    },

    [setFolderId]: (state, { payload }) => {
      const { id } = payload;
      return {
        ...state,
        folderId: id,
      };
    },
  },
  defaultState
);

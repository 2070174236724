import React from "react";
import { styled } from "@mui/styles";
import { Checkbox } from "@mui/material";

const makeStylesCheckbox = styled(Checkbox)({
  padding: "3px !important",
});

const RowCheckbox = ({ ...props }) => {
  return <makeStylesCheckbox color="primary" {...props} />;
};

export default RowCheckbox;

import React from "react";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  listRoot: {
    padding: 0,
    width: 260,
  },
  listIcon: {
    minWidth: "unset",
    marginRight: 5,
  },
  listItem: {
    padding: "10px 30px",
  },
  listText: {
    fontSize: 12,
    color: "#000",
  },
  buttonUploadFromComputer: {
    textTransform: "none",
    padding: 0,
    fontSize: 12,
    color: "#000",
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
  },
}));

const UploadPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleOnSelectUploadFile,
  handleOpenuploadFromSystemModal,
}) => {
  const classes = useStyles();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <List classes={{ root: classes.listRoot }}>
        <ListItem
          disabled
          classes={{ root: classes.listItem }}
          button
          onClick={() => handleOpenuploadFromSystemModal()}
        >
          <ListItemText
            classes={{ primary: classes.listText }}
            primary={<FormattedMessage id="btn.uploadFromSystem" />}
          />
        </ListItem>
        <div>
          <Button
            variant="contained"
            component="label"
            className={classes.buttonUploadFromComputer}
          >
            <ListItem classes={{ root: classes.listItem }} button>
              <FormattedMessage id="btn.uploadFromComputer" />
              <input
                type="file"
                style={{ display: "none" }}
                id="upload-popover"
                name="upload-popover"
                onChange={handleOnSelectUploadFile}
              />
            </ListItem>
          </Button>
        </div>
      </List>
    </Popover>
  );
};

UploadPopover.defaultProps = {
  id: undefined,
  anchorEl: null,
};

UploadPopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpenuploadFromSystemModal: PropTypes.func.isRequired,
  handleOnSelectUploadFile: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default UploadPopover;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 18,
    width: "unset",
    height: "unset",
    color: "rgba(0,0,0,0.42)",
    cursor: "pointer",
  },
}));

const FaIcon = ({ handleAdd, inputName, icon }) => {
  const classes = useStyles();

  return (
    <Icon
      onClick={() => handleAdd(inputName)}
      className={clsx([icon, classes.icon])}
    />
  );
};

export default FaIcon;

FaIcon.defaultProps = {
  handleAdd: () => {},
  inputName: "",
};

FaIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  handleAdd: PropTypes.func,
  inputName: PropTypes.string,
};

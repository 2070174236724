import { gql } from '@apollo/client';

const COMPANY_SETTINGS = gql`
  query CompanySettings($companyId: Int!) {
    companyAdminContext {
      settings(companyId: $companyId) {
        companyId
        defaultTheme
        newsFolder {
          ...SiteFolderFields
        }
        avatarFolder {
          ...SiteFolderFields
        }
        messageAttachmentsFolder {
          ...SiteFolderFields
        }
        siteTemplateFolder {
          ...SiteFolderFields
        }
      }
    }
  }

  fragment SiteFolderFields on SiteFolderDTO {
    folderId
    folderName
    siteId
    siteName
  }
`;

export default COMPANY_SETTINGS;

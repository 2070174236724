import getChildStructureTreeData from "./getChildStructureTreeData";

const getStructureTreeData = ({ structures }) => {
  let structureTree = {};
  let childStructuresTreeData = {};
  if (structures && Array.isArray(structures)) {
    structures.forEach(structure => {
      const { items } = structure;
      const hasChildren = Array.isArray(items) && items.length > 0;

      if (hasChildren) {
        items.forEach(item => {
          const childStructureTreeData = getChildStructureTreeData(item);

          childStructuresTreeData = {
            ...childStructuresTreeData,
            ...childStructureTreeData,
          };
        });
      }

      structureTree = {
        ...structureTree,
        ...childStructuresTreeData,
        [`f_${structure.id}`]: { ...structure, isRoot: true },
      };
    });
  }

  return structureTree;
};

export default getStructureTreeData;

import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const IconPickerItem = lazy(() => import("../../common/ReactIconPickerItem"));

const useStyles = makeStyles(theme => ({
  description: {
    //color: props => props.fontColor || "#9a9a9a",
    color: "#9a9a9a",
    fontSize: 12,
  },
  iconContainer: {
    flex: 0,
    margin: "0 auto 7px auto",
    "& div": {
      display: "flex",
      padding: 0,
    }
  },
  name: {
    //color: props => props.fontColor || "#212529",
    color: "#212529",
    fontSize: 16,
    fontWeight: 600,
  },
  root: props => ({
    borderRadius: 4,
    cursor: !!props.linkUrl ? "pointer" : "default",
    display: "flex",
    flexFlow: "row wrap",
    padding: 7,
  }),
  textContainer: props => ({
    flex: 1,
    flexBasis: "50%",
    margin: "0 7px",
    minHeight: props.iconSize,
    overflow: "hidden",
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  }),
}));

const getSettings = (layout, other) => {
  const { contentBG, fontColor, linkDesc, linkIcon, linkUrl, name, titleColor } = layout;

  let link;
  if (/https?:\/\//gi.test(linkUrl)) {
    link = linkUrl;
  } else if (!!linkUrl) {
    link = `//${linkUrl}`;
  }

  return { contentBG, fontColor, linkDesc, linkIcon, linkUrl: link, name, titleColor, ...other }
};

const LinkWidget = ({ layout }) => {
  const settings = getSettings(layout, { iconSize: 30 });
  const classes = useStyles(settings);

  const openLinkInNewTab = url => {
    !!url && window.open(`${url}`, "_blank");
  };

  return (
    <Box
      alignItems="center"
      className={classes.root}
      onClick={() => openLinkInNewTab(settings.linkUrl)}
    >
      <Box className={classes.iconContainer}>
        <Suspense fallback={<>Loading icons...</>}>
          <IconPickerItem
            color={settings.fontColor}
            icon={settings.linkIcon}
            size={settings.iconSize}
          />
        </Suspense>
      </Box>
      <Box className={classes.textContainer}>
        {settings.name && (
          <Typography className={classes.name}>
            {settings.name}
          </Typography>
        )}
        <Typography className={classes.description}>
          {settings.linkDesc}
        </Typography>
      </Box>
    </Box>
  );
};

LinkWidget.propTypes = {
  layout: PropTypes.shape({
    fontColor: PropTypes.string,
    linkDesc: PropTypes.string,
    linkIcon: PropTypes.string,
    linkUrl: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default LinkWidget;

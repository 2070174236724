import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from "@mui/material";
import AddNewAddressModal from "./AddNewAddressModal";

const useStyles = makeStyles(theme => ({
  table: { border: "1px solid #f1f1f1" },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    lineHeight: "normal",
    "& span": {
      float: "right",
    },
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 3,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
  },
  iconEdit: {
    fontSize: 11,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginTop: 10,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
}));

const AddressTable = () => {
  const classes = useStyles();

  const [openAddNewAddress, setOpenAddNewAddress] = useState(false);
  const handleOpenAddNewAddress = () => {
    setOpenAddNewAddress(true);
  };
  const handleCloseAddNewAddress = () => {
    setOpenAddNewAddress(false);
  };

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>shown as</TableCell>
            <TableCell className={classes.th}>name</TableCell>
            <TableCell className={classes.th}>reference</TableCell>
            <TableCell className={classes.th}>address1</TableCell>
            <TableCell className={classes.th}>address2</TableCell>
            <TableCell className={classes.th}>zip</TableCell>
            <TableCell className={classes.th}>city</TableCell>
            <TableCell className={classes.th}>country</TableCell>
            <TableCell className={classes.th}>email</TableCell>
            <TableCell className={classes.th}>telephone</TableCell>
            <TableCell className={classes.th}>customer no.</TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>Test</TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} align="right">
              <IconButton className={classes.iconButtonEdit}>
                <Icon
                  className={clsx([
                    "fa fa-pencil-alt",
                    classes.icon,
                    classes.iconEdit,
                  ])}
                />
              </IconButton>
              <IconButton className={classes.iconButtonDelete}>
                <Icon className={clsx(["fa fa-times", classes.icon])} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button className={classes.addLink} onClick={handleOpenAddNewAddress}>
        <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
        Add new address
      </Button>
      <AddNewAddressModal
        open={openAddNewAddress}
        handleClose={handleCloseAddNewAddress}
      />
    </>
  );
};

export default AddressTable;

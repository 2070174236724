import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Button, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DateFull from "../common/FormControl/DateFull";
import ButtonText from "../common/ButtonText";

const useStyles = makeStyles(theme => ({
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  colSearch: {
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
      textTransform: "none",
      fontSize: 11,
    },
    "& .MuiFormControl-root": {
      marginTop: 10,
    },
    "& legend": {
      maxWidth: 0,
    },
    maxWidth: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  btnColClear: {
    color: theme.palette.primary.main,
    fontSize: 10,
    marginTop: 5,
    marginRight: 8,
  },
  btnColSearch: {
    padding: "5px 15px",
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: 9,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    width: "auto",
    marginTop: 5,
  },
}));

const ColumnSearchDate = ({
  fetchPickingListOrders,
  searchValues,
  setSearchValues,
  handleClearDate,
}) => {
  const classes = useStyles();

  const { fromDate, toDate } = searchValues || {};

  const handleFromDate = dateValue => {
    setSearchValues({ ...searchValues, fromDate: dateValue });
  };

  const handleToDate = dateValue => {
    setSearchValues({ ...searchValues, toDate: dateValue });
  };

  return (
    <Box className={classes.colSearch} align="right">
      <DateFull
        label={<FormattedMessage id="date.dateFrom" />}
        setDate={handleFromDate}
        value={fromDate}
        disablePortal
      />
      <DateFull
        label={<FormattedMessage id="date.dateUntil" />}
        setDate={handleToDate}
        value={toDate}
        disablePortal
      />
      <Box>
        <ButtonText
          onClick={() => handleClearDate()}
          label={<FormattedMessage id="pickingList.revert" />}
          btnClass={classes.btnColClear}
        />
        <Button
          className={classes.btnColSearch}
          onClick={() => fetchPickingListOrders()}
        >
          <FormattedMessage id="btn.search" />
        </Button>
      </Box>
    </Box>
  );
};

ColumnSearchDate.propTypes = {
  fetchPickingListOrders: PropTypes.func.isRequired,
  searchValues: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
  }).isRequired,
  setSearchValues: PropTypes.func.isRequired,
  handleClearDate: PropTypes.func.isRequired,
};

export default ColumnSearchDate;

import PropTypes from "prop-types";
import { Masonry } from "@mui/lab";
import { ImageViewItem } from "../../../ProductsMainView";

const ImageViewMasonry = ({ numCols, products }) => {

  return (
    products && products.length > 0 && (
      <Masonry columns={numCols}>
        {products.map(product => (
          <ImageViewItem
            key={product.id}
            product={product}
            showProductModalOnOpen={false} />
        ))}
      </Masonry>
    )
  );
};

ImageViewMasonry.defaultProps = {
  numCols: 6,
};

ImageViewMasonry.propTypes = {
  numCols: PropTypes.number,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      thumbnailImageUri: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ImageViewMasonry;

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    border: `none!important`,
    borderRadius: "0!important",
  },
  text: {
    fontSize: 11,
  },
  summary: {
    borderBottom: `1px solid ${theme.palette.text.lightGray}`,
    minHeight: "10px!important",
    padding: 0,
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0!important",
    },
  },
}));

const FileItems = () => {
  const classes = useStyles();
  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.text}>brosycher3.pdf</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.text}>1. brosycher.pdf</Typography>
        <Typography className={classes.text}>1. brosycher.pdf</Typography>
        <Typography className={classes.text}>1. brosycher.pdf</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FileItems;

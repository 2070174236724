import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { List, ListSubheader } from "@mui/material";
import { makeStyles } from "@mui/styles";
import WorkspaceListTree from "./WorkspaceListTree";

const useStyles = makeStyles(theme => ({
  rootList: {
    width: "100%",
    "& .MuiListItemIcon-root": {
      minWidth: 30,
      [theme.breakpoints.down("sm")]: {
        minWidth: 24,
      },
    },
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.down("sm")]: {
        width: "16px !important",
        height: "16px !important",
      },
    },
    "& .MuiListItem-root": {
      paddingTop: 4,
      paddingBottom: 4,
      [theme.breakpoints.down("sm")]: {
        padding: "2px 5px",
      },
    },
    "& .MuiListSubheader-root": {
      lineHeight: "30px",
      fontSize: 11,
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        paddingLeft: 5,
        paddingRight: 5,
        lineHeight: "20px",
      },
    },
    "& .MuiTypography-root": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 11,
      },
    },
  },
}));

const WorkspaceList = ({
  headerLabel,
  onClick,
  structureTree,
}) => {
  const classes = useStyles();

  const [structureTreeRoots, setStructureTreeRoots] = useState([]);

  useEffect(() => {
    const newStructureTreeRoots = Object.values(structureTree).filter(
      x => x.isRoot
    );
    setStructureTreeRoots(newStructureTreeRoots);
  }, [structureTree]);

  return (
    <List
      className={classes.rootList}
      component="nav"
      subheader={
        <ListSubheader
          align="right"
          component="div"
        >
          {headerLabel}
        </ListSubheader>
      }
    >
      <WorkspaceListTree
        onClick={onClick}
        structureTreeRoots={structureTreeRoots}
      />
    </List>
  );
};

WorkspaceList.defaultProps = {
  headerLabel: <FormattedMessage id="workspace.workspace" />,
};

WorkspaceList.propTypes = {
  headerLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  structureTree: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default WorkspaceList;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import {
  TableCell,
  TableRow,
  TableHead,
  Popover,
  TableSortLabel,
  ClickAwayListener,
} from "@mui/material";
import clsx from "clsx";
import SortPopover from "./SortPopover";
import IconLink from "../../common/FormControl/IconLink";
import Checkbox from "../../common/FormControl/Checkbox";
import ColumnSearch from "./ColumnSearch";
import { initialToggleHeader } from "./helper";

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflowX: "auto",
    backgroundColor: theme.palette.common.white,
    "& tr:last-child td": {
      borderBottom: 0,
    },
  },
  th: {
    // color: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "4px 5px 2px 5px",
  },
  thName: {
    padding: "4px 5px 2px 0",
    width: "23%",
  },
  thCheckbox: {
    padding: "4px 5px 2px 10px",
    width: 28,
  },
  thSort: {
    minWidth: 90,
  },
  thDesc: {
    minWidth: 200,
  },
  iconButtonSort: {
    padding: "2px 4px",
    color: theme.palette.common.link,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  paperPopover: {
    padding: 10,
    boxShadow: "0px 3px 12px -2px rgba(0, 0, 0, 0.24)",
    minWidth: 150,
  },
  thActions: {},
}));

const ProductsTableHeader = ({
  fileViewFields,
  selectAll,
  handleSelectAllResources,
  setSortfield,
  sortfield,
  ascending,
  setAscending,
  searchValues,
  setSearchValues,
  refetchResources,
}) => {
  const classes = useStyles();

  const [columnFilterValues, setColumnFilterValues] = useState({
    sortfield: "",
    value: "",
  });
  const [toggleHeader, setToggleHeader] = useState(initialToggleHeader);

  const handleToggleHeader = name => {
    setColumnFilterValues({ sortfield: "", value: "" });

    setToggleHeader({ ...initialToggleHeader, [name]: !toggleHeader[name] });
  };

  const [anchorElSortUpload, setAnchorElSortUpload] = useState(null);
  const handleClickSortUpload = event => {
    setAnchorElSortUpload(event.currentTarget);
  };
  const handleCloseSortUpload = () => {
    setAnchorElSortUpload(null);
  };
  const openSortUpload = Boolean(anchorElSortUpload);

  const [anchorElSortDownload, setAnchorElSortDownload] = useState(null);
  const handleClickSortDownload = event => {
    setAnchorElSortDownload(event.currentTarget);
  };
  const handleCloseSortDownload = () => {
    setAnchorElSortDownload(null);
  };
  const openSortDownload = Boolean(anchorElSortDownload);

  const [anchorElSortEdited, setAnchorElSortEdited] = useState(null);
  const handleClickSortEdited = event => {
    setAnchorElSortEdited(event.currentTarget);
  };
  const handleCloseSortEdited = () => {
    setAnchorElSortEdited(null);
  };
  const openSortEdited = Boolean(anchorElSortEdited);

  const handleRequestSort = (event, property) => {
    const isDesc = sortfield === property && ascending === false;

    setSortfield(property);
    setAscending(isDesc);
  };

  const handleSortField = property => event => {
    handleRequestSort(event, property);
  };

  const handleColumnFilter = e => {
    const { name, value } = e.target;
    const tempValues = { sortfield: name, value };
    const tempFilter = { ...tempValues };

    setColumnFilterValues(tempFilter);
  };

  const handleSearchValues = () => {
    if (columnFilterValues.sortfield && columnFilterValues.value) {
      setSearchValues(columnFilterValues);
    } else {
      setSearchValues(null);
    }
  };

  const handleClear = () => {
    setColumnFilterValues({ sortfield: "", value: "" });
  };

  const handleColumnFilterByDate = (name, date) => {
    const strDate = moment(date).format("YYYY-MM-DD");
    setColumnFilterValues({ sortfield: name, value: strDate });
  };

  const isUploaded = sortfield === "UploadedBy" || sortfield === "UploadedDate";
  const isDownloaded =
    sortfield === "DownloadedBy" || sortfield === "DownloadedDate";
  const isChanged = sortfield === "ChangedBy" || sortfield === "ChangedDate";

  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx([classes.th, classes.thCheckbox])}>
          <Checkbox
            onChange={e => handleSelectAllResources(e)}
            value={selectAll}
          />
        </TableCell>
        <TableCell className={classes.th} />
        {fileViewFields.nameColumn && (
          <TableCell className={clsx([classes.th, classes.thName])}>
            <IconLink
              icon="fa fa-search"
              customClass={classes.colSearchIcon}
              onClick={() => handleToggleHeader("name")}
            />
            <TableSortLabel
              active={sortfield === "Name"}
              direction={sortfield === "Name" && ascending ? "asc" : "desc"}
              onClick={handleSortField("Name")}
            >
              <FormattedMessage id="product.name" />
            </TableSortLabel>
            {toggleHeader.name && (
              <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
                <div>
                  <ColumnSearch
                    onChange={handleColumnFilter}
                    handleClear={handleClear}
                    name="Name"
                    value={columnFilterValues.value}
                    handleSearchValues={handleSearchValues}
                  />
                </div>
              </ClickAwayListener>
            )}
          </TableCell>
        )}
        {fileViewFields.descriptionColumn && (
          <TableCell className={clsx([classes.th, classes.thDesc])}>
            <IconLink
              icon="fa fa-search"
              customClass={classes.colSearchIcon}
              onClick={() => handleToggleHeader("description")}
            />
            <TableSortLabel
              active={sortfield === "Description"}
              direction={
                sortfield === "Description" && ascending ? "asc" : "desc"
              }
              onClick={handleSortField("Description")}
            >
              <FormattedMessage id="product.description" />
            </TableSortLabel>
            {toggleHeader.description && (
              <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
                <div>
                  <ColumnSearch
                    onChange={handleColumnFilter}
                    handleClear={handleClear}
                    name="Description"
                    value={columnFilterValues.value}
                    handleSearchValues={handleSearchValues}
                  />
                </div>
              </ClickAwayListener>
            )}
          </TableCell>
        )}
        {fileViewFields.uploadedColumn && (
          <TableCell className={clsx([classes.th, classes.thSort])}>
            <TableSortLabel
              onClick={handleClickSortUpload}
              active={isUploaded}
              direction={isUploaded && ascending ? "asc" : "desc"}
            >
              <FormattedMessage id="product.uploaded" />
            </TableSortLabel>

            <Popover
              open={openSortUpload}
              anchorEl={anchorElSortUpload}
              onClose={handleCloseSortUpload}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                root: classes.rootPopover,
                paper: classes.paperPopover,
              }}
            >
              <SortPopover
                name="Uploaded"
                sortfield={sortfield}
                ascending={ascending}
                handleSortField={handleSortField}
                handleToggleHeader={handleToggleHeader}
                handleColumnFilter={handleColumnFilter}
                handleClear={handleClear}
                columnFilterValues={columnFilterValues}
                handleSearchValues={handleSearchValues}
                toggleHeader={toggleHeader}
                handleColumnFilterByDate={handleColumnFilterByDate}
              />
            </Popover>
          </TableCell>
        )}
        {fileViewFields.downloadedColumn && (
          <TableCell className={clsx([classes.th, classes.thSort])}>
            <TableSortLabel
              onClick={handleClickSortDownload}
              active={isDownloaded}
              direction={isDownloaded && ascending ? "asc" : "desc"}
            >
              <FormattedMessage id="product.downloaded" />
            </TableSortLabel>

            <Popover
              open={openSortDownload}
              anchorEl={anchorElSortDownload}
              onClose={handleCloseSortDownload}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                root: classes.rootPopover,
                paper: classes.paperPopover,
              }}
            >
              <SortPopover
                name="Downloaded"
                sortfield={sortfield}
                ascending={ascending}
                handleSortField={handleSortField}
                handleToggleHeader={handleToggleHeader}
                handleColumnFilter={handleColumnFilter}
                handleClear={handleClear}
                columnFilterValues={columnFilterValues}
                handleSearchValues={handleSearchValues}
                toggleHeader={toggleHeader}
                handleColumnFilterByDate={handleColumnFilterByDate}
              />
            </Popover>
          </TableCell>
        )}
        {fileViewFields.editedColumn && (
          <TableCell className={clsx([classes.th, classes.thSort])}>
            <TableSortLabel
              onClick={handleClickSortEdited}
              active={isChanged}
              direction={isChanged && ascending ? "asc" : "desc"}
            >
              <FormattedMessage id="product.edited" />
            </TableSortLabel>
            <Popover
              open={openSortEdited}
              anchorEl={anchorElSortEdited}
              onClose={handleCloseSortEdited}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{
                root: classes.rootPopover,
                paper: classes.paperPopover,
              }}
            >
              <SortPopover
                name="Changed"
                sortfield={sortfield}
                ascending={ascending}
                handleSortField={handleSortField}
                handleToggleHeader={handleToggleHeader}
                handleColumnFilter={handleColumnFilter}
                handleClear={handleClear}
                columnFilterValues={columnFilterValues}
                handleSearchValues={handleSearchValues}
                toggleHeader={toggleHeader}
                handleColumnFilterByDate={handleColumnFilterByDate}
              />
            </Popover>
          </TableCell>
        )}
        {fileViewFields.versionColumn && (
          <TableCell className={classes.th} align="center">
            <IconLink
              icon="fa fa-search"
              customClass={classes.colSearchIcon}
              onClick={() => handleToggleHeader("version")}
            />
            <TableSortLabel
              active={sortfield === "VersionNo"}
              direction={
                sortfield === "VersionNo" && ascending ? "asc" : "desc"
              }
              onClick={handleSortField("VersionNo")}
            >
              Version
            </TableSortLabel>
            {toggleHeader.version && (
              <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
                <div>
                  <ColumnSearch
                    onChange={handleColumnFilter}
                    handleClear={handleClear}
                    name="VersionNo"
                    value={columnFilterValues.value}
                    handleSearchValues={handleSearchValues}
                  />
                </div>
              </ClickAwayListener>
            )}
          </TableCell>
        )}
        {fileViewFields.typeColumn && (
          <TableCell className={classes.th} align="center">
            <IconLink
              icon="fa fa-search"
              customClass={classes.colSearchIcon}
              onClick={() => handleToggleHeader("type")}
            />
            <TableSortLabel
              active={sortfield === "Type"}
              direction={sortfield === "Type" && ascending ? "asc" : "desc"}
              onClick={handleSortField("Type")}
            >
              <FormattedMessage id="product.type" />
            </TableSortLabel>
            {toggleHeader.type && (
              <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
                <div>
                  <ColumnSearch
                    onChange={handleColumnFilter}
                    handleClear={handleClear}
                    name="Type"
                    value={columnFilterValues.value}
                    handleSearchValues={handleSearchValues}
                  />
                </div>
              </ClickAwayListener>
            )}
          </TableCell>
        )}

        {fileViewFields.sizeColumn && (
          <TableCell className={classes.th}>
            <IconLink
              icon="fa fa-search"
              customClass={classes.colSearchIcon}
              onClick={() => handleToggleHeader("size")}
            />
            <TableSortLabel
              active={sortfield === "Size"}
              direction={sortfield === "Size" && ascending ? "asc" : "desc"}
              onClick={handleSortField("Size")}
            >
              <FormattedMessage id="product.size" />
            </TableSortLabel>
            {toggleHeader.size && (
              <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
                <div>
                  <ColumnSearch
                    onChange={handleColumnFilter}
                    handleClear={handleClear}
                    name="Size"
                    value={columnFilterValues.value}
                    handleSearchValues={handleSearchValues}
                  />
                </div>
              </ClickAwayListener>
            )}
          </TableCell>
        )}
        <TableCell
          className={clsx([classes.th, classes.thActions])}
          align="right"
          onClick={() => setSortfield("Locked")}
        />
      </TableRow>
    </TableHead>
  );
};

ProductsTableHeader.propTypes = {
  fileViewFields: PropTypes.shape({
    nameColumn: PropTypes.bool,
    descriptionColumn: PropTypes.bool,
    uploadedColumn: PropTypes.bool,
    downloadedColumn: PropTypes.bool,
    editedColumn: PropTypes.bool,
    versionColumn: PropTypes.bool,
    typeColumn: PropTypes.bool,
    sizeColumn: PropTypes.bool,
  }).isRequired,
  selectAll: PropTypes.bool.isRequired,
  handleSelectAllResources: PropTypes.func.isRequired,
  setSortfield: PropTypes.func.isRequired,
  sortfield: PropTypes.bool.isRequired,
  ascending: PropTypes.bool.isRequired,
  setAscending: PropTypes.func.isRequired,
  searchValues: PropTypes.shape({ value: PropTypes.string }).isRequired,
  setSearchValues: PropTypes.func.isRequired,
  refetchResources: PropTypes.func.isRequired,
};

export default ProductsTableHeader;

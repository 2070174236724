import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Icon, Tabs, AppBar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { UPDATE_PRODUCT } from "../../../graphql/mutations";
import TabPanel from "../../TabPanel";
import PageTabs from "../../PageTabs";
import CooperationInfo from "./Tabs/CooperationInfo/index";
import ProductInfo from "./Tabs/ProductInfo/index";
import Groups from "./Tabs/Groups";
import Stock from "./Tabs/Stock";
import Viewing from "./Tabs/Viewing";
import Delivery from "./Tabs/Delivery";
import Dynamic from "./Tabs/Dynamic";
import { geta11yProps } from "../../../helpers/get";
import {
  initialManualPrice,
  initialFormValues,
  initialBooking,
  formValidation,
} from "./Tabs/ProductInfo/helpers";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      //fontSize: 10,
      textTransform: "uppercase",
    },
    "& .fa": {
      fontSize: 12,
      marginLeft: 7,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  hasError: {
    backgroundColor: "#ffa7a7",
    opacity: 1,
    boxShadow: "inset 0px 0px 0px 2px #bb1335",
    "& .fa": {
      display: "inline-block !important",
      fontSize: 12,
      marginLeft: 7,
      color: "#b72b2b",
    },
    "& $indicator": {
      backgroundColor: "#bb1335 !important",
    },
  },
  label: {
    fontSize: 12,
  },
  draftIcon: {
    color: "#b72b2b",
  },
  loadingOverlay: {
    background: "#000",
    opacity: 0.5,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  loadingIcon: {
    color: "#fff",
  },
}));

const EditProductTabs = ({
  product,
  image,
  refetchProduct,
  draftStates,
  handleDeleteModalOpen,
  folderId,
  resourceId,
  handleError,
  loading,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [value, setValue] = useState(1);
  const customMetadata = useSelector(
    state => state.api.customMetadata.metadata
  );

  const {
    cooperationIsDraft,
    setCooperationIsDraft,
    productInfoIsDraft,
    setProductInfoIsDraft,
    groupsIsDraft,
    setGroupsIsDraft,
    stockIsDraft,
    setStockIsDraft,
    viewingIsDraft,
    setViewingIsDraft,
    deliveryIsDraft,
    setDeliveryIsDraft,
  } = draftStates;

  const [productInfoHasError, setProductInfoHasError] = useState(false);
  const [initialFileNames, setInitialFileNames] = useState({
    originalFileName: "",
    thumbnailFileName: "",
    displayFileName: "",
  });
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [booking, setBooking] = useState(initialBooking);
  const [selectedProductType, setSelectedProductType] = useState("ON_DEMAND");
  const [vatRates, setVatRates] = useState([]);
  const [errors, setErrors] = useState({});

  const showStockTab =
    selectedProductType === "STOCK_PRODUCT" ||
    selectedProductType === "BOOKABLE" ||
    selectedProductType === "PRODUCT_CONTAINER";

  const setDraft = () => {
    if (!productInfoIsDraft) {
      setProductInfoIsDraft(true);
    }
  };

  const setUnDraft = () => {
    if (productInfoIsDraft) {
      setProductInfoIsDraft(false);
    }
    if (deliveryIsDraft) {
      setDeliveryIsDraft(false);
    }
  };

  useEffect(() => {
    if (product) {
      let productWithInitialValues = { ...product };
      let selectedVatRate = null;
      if (product && product.vateRates && product.vateRates.length > 0) {
        selectedVatRate = product.vateRates.find(x => x.selected === true);
        setVatRates(product.vateRates);
      }

      if (productWithInitialValues.pageRange === 0) {
        productWithInitialValues = {
          ...productWithInitialValues,
          pageRange: null,
        };
      }

      if (productWithInitialValues.weight === 0) {
        productWithInitialValues = {
          ...productWithInitialValues,
          weight: null,
        };
      }

      if (selectedVatRate && selectedVatRate.vatRateId) {
        productWithInitialValues = {
          ...productWithInitialValues,
          vatRateId: selectedVatRate.vatRateId,
        };
      }

      if (product) {
        productWithInitialValues = {
          ...productWithInitialValues,
          pricesInclusiveFreight: product.pricesInclusiveFreight,
        };
      }

      if (
        product.buyPrices &&
        product.buyPrices.manualPrices &&
        (!Array.isArray(product.buyPrices.manualPrices) ||
          product.buyPrices.manualPrices.length < 1)
      ) {
        productWithInitialValues = {
          ...productWithInitialValues,
          buyPrices: {
            ...productWithInitialValues.buyPrices,
            manualPrices: [{ ...initialManualPrice }],
          },
        };
      }

      if (
        product.sellPrices &&
        product.sellPrices.manualPrices &&
        (!Array.isArray(product.sellPrices.manualPrices) ||
          product.sellPrices.manualPrices.length < 1)
      ) {
        productWithInitialValues = {
          ...productWithInitialValues,
          sellPrices: {
            ...productWithInitialValues.sellPrices,
            manualPrices: [{ ...initialManualPrice }],
          },
        };
      }

      if (product.files) {
        setInitialFileNames(product.files);
      }

      setFormValues(productWithInitialValues);
    }
    if (product.bookings) {
      setBooking(product.bookings);
    }

    if (product.orderingType) {
      setSelectedProductType(product.orderingType);
    }
  }, [product]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showRequiredFieldError = () => {
    snackbar.workspaceError(
      <FormattedMessage id="common.requiredFieldMissing" />
    );
  };

  const showSaveProductError = () => {
    snackbar.workspaceError(
      <FormattedMessage id="product.errorSavingProduct" />
    );
  };

  const handleSave = async () => {
    const fieldIsRequired = intl.formatMessage({
      id: "common.fieldIsRequired",
    });

    if (!formValues) {
      showRequiredFieldError();
      return;
    }

    // Validations
    const tempErrors = formValidation(
      formValues,
      fieldIsRequired,
      selectedProductType
    );

    if (!isEmpty(tempErrors)) {
      showRequiredFieldError();
      setTimeout(() => {
        setErrors(tempErrors);
      }, 1000);
      return;
    }
    setErrors({});

    const { tags = [] } = formValues || {};
    let oldTags = [];
    let newTags = [];

    tags.forEach(x => {
      if (x.tagId) {
        oldTags = [...oldTags, x.tagId];
      } else {
        newTags = [...newTags, x.tagName];
      }
    });

    const newImageMetadata = {
      ...formValues.imageMetadata,
      isFreeToUser:
        formValues.imageMetadata && formValues.imageMetadata.isFreeToUser
          ? formValues.imageMetadata.isFreeToUser
          : false,
      id: product.resourceId,
      newTags,
      tagIds: oldTags,
    };

    let params = {
      ...formValues,
      orderingType: selectedProductType,
      bookings: booking,
      resourceId: product.resourceId,
      customMetadata,
      imageMetadata: newImageMetadata,
    };

    if (!params.actionValue) {
      params = { ...params, actionValue: "" };
    }

    if (params.pageRange === null || params.pageRange === undefined) {
      params = { ...params, pageRange: 0 };
    }

    if (params.weight === null || params.weight === undefined) {
      params = { ...params, weight: 0 };
    }

    if (params.material === null || params.material === undefined) {
      params = { ...params, material: "" };
    }

    // Error handling.
    // Set sell prices initialCosts to 0 if it is null.
    if (params.sellPrices && !params.sellPrices.initialCosts) {
      params = {
        ...params,
        sellPrices: { ...params.sellPrices, initialCosts: 0 },
      };
    }

    if (params && params.files) {
      delete params.files;
    }

    if (params && params.imageMetadata) {
      delete params.imageMetadata.legalDocument;
    }

    delete params.thumbnailImageUri;
    delete params.tags;
    delete params.vateRates;
    delete params.siteSuppliers;
    delete params.supplierId;
    // delete inclusiveFreight for now , i tihnk it is not supported yet
    // delete params.inclusiveFreight;
    try {
      const res = await updateProduct({ variables: { update: params } });
      if (res && res.data && res.data.updateProduct) {
        const { success, errorResult } = res.data.updateProduct;
        if (success) {
          alert.success(<FormattedMessage id="product.updatedProduct" />);
          setUnDraft();
          setErrors({});
        } else {
          handleError(errorResult);
        }
      }
    } catch (e) {
      console.log(`Error saving product info ${JSON.stringify(e)}`);
    }
  };

  const renderLabel = (label, draft, error) => {
    const errorIcon = error && <Icon className="fa fa-exclamation-circle" />;
    const draftIcon = draft && (
      <Icon className={clsx(["fa fa-edit", classes.draftIcon])} />
    );

    return (
      <>
        <Typography className={classes.label}>{label}</Typography>
        {draftIcon}
        {errorIcon}
      </>
    );
  };

  return (
    <div className={classes.root}>
      {loading && (
        <Box className={classes.loadingOverlay}>
          <CircularProgress size={60} className={classes.loadingIcon} />
        </Box>
      )}

      <AppBar position="static" elevation="0" className={classes.wrap}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label={intl.formatMessage({ id: "editProduct.editProduct" })}
          classes={{
            indicator: classes.indicator,
          }}
          className={classes.tabsRoot}
        >
          <PageTabs
            className={classes.pageTabs}
            label={renderLabel(
              <FormattedMessage id="product.partnersTab" />,
              cooperationIsDraft
            )}
            {...geta11yProps(0)}
            value={0}
            disabled={loading}
          />
          <PageTabs
            className={clsx([
              classes.pageTabs,
              productInfoHasError ? classes.hasError : {},
            ])}
            label={renderLabel(
              <FormattedMessage id="product.editTabPrice" />,
              productInfoIsDraft,
              productInfoHasError
            )}
            {...geta11yProps(1)}
            value={1}
            disabled={loading}
          />
          <PageTabs
            className={classes.pageTabs}
            label={renderLabel(
              <FormattedMessage id="product.productTabSupplier" />,
              deliveryIsDraft
            )}
            {...geta11yProps(2)}
            value={2}
            disabled={loading}
          />
          {showStockTab && (
            <PageTabs
              className={classes.pageTabs}
              label={renderLabel(
                <FormattedMessage id="product.stockTab" />,
                stockIsDraft
              )}
              {...geta11yProps(3)}
              value={3}
              disabled={loading}
            />
          )}

          <PageTabs
            className={classes.pageTabs}
            label={renderLabel(
              <FormattedMessage id="product.displayingTab" />,
              viewingIsDraft
            )}
            {...geta11yProps(4)}
            value={4}
            disabled={loading}
          />
          <PageTabs
            className={classes.pageTabs}
            label={renderLabel(
              <FormattedMessage id="product.groupsTab" />,
              groupsIsDraft
            )}
            {...geta11yProps(5)}
            value={5}
            disabled={loading}
          />
          {selectedProductType === "DYNAMIC_PRODUCT" && (
            <PageTabs
              className={classes.pageTabs}
              label={renderLabel(
                <FormattedMessage id="product.dynamicTab" />,
                groupsIsDraft
              )}
              {...geta11yProps(6)}
              value={6}
              disabled={loading}
            />
          )}
        </Tabs>
      </AppBar>
      {[
        <CooperationInfo
          cooperationIsDraft={cooperationIsDraft}
          resourceId={resourceId}
          setCooperationIsDraft={setCooperationIsDraft}
        />,
        <ProductInfo
          errors={errors}
          folderId={folderId}
          formValues={formValues}
          handleDeleteModalOpen={handleDeleteModalOpen}
          handleSave={handleSave}
          image={image}
          initialFileNames={initialFileNames}
          productInfoIsDraft={productInfoIsDraft}
          refetchProduct={refetchProduct}
          selectedProductType={selectedProductType}
          setDraft={setDraft}
          setErrors={setErrors}
          setFormValues={setFormValues}
          setInitialFileNames={setInitialFileNames}
          setProductInfoHasError={setProductInfoHasError}
          vatRates={vatRates}
          setSelectedProductType={setSelectedProductType}
        />,
        <Delivery
          booking={booking}
          formValues={formValues}
          handleSave={handleSave}
          selectedProductType={selectedProductType}
          setBooking={setBooking}
          setDraft={setDraft}
          setFormValues={setFormValues}
          setSelectedProductType={setSelectedProductType}
          deliveryIsDraft={deliveryIsDraft}
          setDeliveryIsDraft={setDeliveryIsDraft}
          vatRates={vatRates}
          errors={errors}
        />,
        <Stock
          image={image}
          product={product}
          resourceId={resourceId}
          setStockIsDraft={setStockIsDraft}
          stockIsDraft={stockIsDraft}
        />,
        <Viewing
          viewingIsDraft={viewingIsDraft}
          setViewingIsDraft={setViewingIsDraft}
          resourceId={resourceId}
        />,
        <Groups
          groupsIsDraft={groupsIsDraft}
          resourceId={resourceId}
          setGroupsIsDraft={setGroupsIsDraft}
        />,
        <Dynamic
          resourceId={resourceId}
          image={image}
          selectedProductType={selectedProductType}
        />,
      ].map((x, index) => {
        return (
          <>
            <TabPanel value={value} className={classes.tabPanel} index={index}>
              {x}
            </TabPanel>
          </>
        );
      })}
    </div>
  );
};

EditProductTabs.defaultProps = {
  displaySettings: {},
  folderId: null,
  metaData: {},
  product: {},
  resourceGroupEdit: [],
  stockSettings: {},
  resourceId: null,
};

EditProductTabs.propTypes = {
  folderId: PropTypes.number,
  product: PropTypes.shape({
    pricesInclusiveFreight: PropTypes.bool.isRequired,
    imageMetadata: PropTypes.shape({}),
    tags: PropTypes.arrayOf(),
    vateRates: PropTypes.arrayOf(),
    buyPrices: PropTypes.shape({
      manualPrices: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    sellPrices: PropTypes.shape({
      manualPrices: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    resourceId: PropTypes.string,
    orderingType: PropTypes.string,
    bookings: PropTypes.shape({}),
    files: PropTypes.shape({
      displayFileName: PropTypes.string,
      originalFileName: PropTypes.string,
      thumbnailFileName: PropTypes.string,
    }),
    id: PropTypes.string,
  }),
  stockSettings: PropTypes.shape({}),
  metaData: PropTypes.shape({}),
  displaySettings: PropTypes.shape({}),
  image: PropTypes.string.isRequired,
  refetchProduct: PropTypes.func.isRequired,
  refetchStock: PropTypes.func.isRequired,
  resourceGroupEdit: PropTypes.arrayOf(PropTypes.shape({})),
  draftStates: PropTypes.shape({
    cooperationIsDraft: PropTypes.string.isRequired,
    setCooperationIsDraft: PropTypes.func.isRequired,
    productInfoIsDraft: PropTypes.string.isRequired,
    setProductInfoIsDraft: PropTypes.func.isRequired,
    groupsIsDraft: PropTypes.string.isRequired,
    setGroupsIsDraft: PropTypes.func.isRequired,
    stockIsDraft: PropTypes.string.isRequired,
    setStockIsDraft: PropTypes.func.isRequired,
    viewingIsDraft: PropTypes.string.isRequired,
    setViewingIsDraft: PropTypes.bool.isRequired,
    deliveryIsDraft: PropTypes.bool.isRequired,
    setDeliveryIsDraft: PropTypes.bool.isRequired,
  }).isRequired,
  handleDeleteModalOpen: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
  handleError: PropTypes.func.isRequired,
};

export default EditProductTabs;

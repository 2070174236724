import { handleActions } from "redux-actions";
import {
  setSuppliers,
  setSuppliersError,
  setSuppliersLoading,
  addSupplier,
} from "../../../../actions";

const defaultState = {
  items: [],
  loading: false,
  error: null,
};

export default handleActions(
  {
    [setSuppliers]: (state, { payload }) => {
      const { items } = payload;
      localStorage.setItem("suppliers", JSON.stringify(items));
      return {
        ...state,
        items,
      };
    },
    [setSuppliersLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        loading,
      };
    },
    [setSuppliersError]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        error,
      };
    },
    [addSupplier]: (state, { payload }) => {
      const { item } = payload;
      const { items } = state;
      const updatedItems = [...items, item];
      localStorage.setItem("suppliers", JSON.stringify(updatedItems));

      return {
        ...state,
        items: updatedItems,
      };
    },
  },
  defaultState
);

import { gql } from '@apollo/client';
import DirectMessageFragment from "../../fragments/DirectMessage";

const ADD_MESSAGE = gql`
  mutation AddDirectMessage($message: DirectMessageInput) {
    directMessageContext {
      addMessage(message: $message) {
        ...DirectMessageFragment
      }
    }
  }
  ${DirectMessageFragment}
`;

export default ADD_MESSAGE;

import { gql } from '@apollo/client';

const StructureRecursiveFragment = gql`
  fragment StructureRecursive on StructureItem {
    items {
      ...StructureFields
      items {
        ...StructureFields
        items {
          ...StructureFields
          items {
            ...StructureFields
            items {
              ...StructureFields
              items {
                ...StructureFields
                items {
                  ...StructureFields
                  items {
                    ...StructureFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default StructureRecursiveFragment;

import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { SEARCH_V2 } from "../graphql/queries/Search";
import {
  setSearchProductIds,
  setSearchFolderId,
  setSearchFolders,
  setSearchErrors,
  setSearchCalled,
  setSearchLoading,
  setSearchViewSettings,
  setSearchResourceIds,
} from "../actions";

// eslint-disable-next-line import/prefer-default-export
export const useSearchV2 = () => {
  const dispatch = useDispatch();
  const [detailedSearch, { data, loading, error, called }] = useLazyQuery(
    SEARCH_V2
  );

  useEffect(() => {
    if (data && data.searchV2 && data.searchV2.search) {
      const {
        sortedProductIds,
        sortedResourceIds,
      } = data.searchV2.search;

      dispatch(
        setSearchProductIds({
          items: sortedProductIds,
        })
      );

      dispatch(
        setSearchResourceIds({
          items: sortedResourceIds,
        })
      );
      dispatch(setSearchFolderId({ id: null }));
      
      if (
        Array.isArray(data.searchV2.search.sites)
      ) {
        dispatch(
          setSearchFolders({
            folders: data.searchV2.search.sites,
          })
        );
      }


      if (
        data.searchV2.search.viewSelectorSettings
      ) {
        dispatch(
          setSearchViewSettings({
            settings: data.searchV2.search.viewSelectorSettings,
          })
        );
      }
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setSearchLoading({
        loading,
      })
    );
  }, [loading]);

  useEffect(() => {
      dispatch(
        setSearchErrors({
          error,
        })
      );    
  }, [error]);

  useEffect(() => {
    if (called) {
      dispatch(
        setSearchCalled({
          called,
        })
      );
    }
  }, [called]);

  return { execute: detailedSearch, data, loading, error };
};

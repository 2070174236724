import { gql } from '@apollo/client';

const getOptimizedBookings = gql`
  query GetOptimizedBookings($bookings: [OptimizeBookingInput]) {
    getOptimizedBookings(bookings: $bookings) {
      bgColor
      end
      group
      id
      start
      title
    }
  }
`;

export default getOptimizedBookings;

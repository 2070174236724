import { gql } from '@apollo/client';
import ShareListFragment from "../../fragments/ShareContext/ShareListDTO";

const LIST_USER_SHARES = gql`
  query ListUserShares {
    shareContext {
      listUserShares {
        sharedWithMe {
          ...ShareListFragment
        }
        userShares {
          ...ShareListFragment
        }
        __typename
      }
    }
  }
  ${ShareListFragment}
`;

export default LIST_USER_SHARES;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Slider, Divider, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ColorPicker from "../../../common/ColorPicker";

const useStyles = makeStyles(() => ({
  settingsContainer: {
    border: "1px solid #e1e1e1",
    borderRadius: 5,
    padding: "5px",
    position: "relative",
  },
  textLabel: {
    background: "#fff",
    fontSize: 12,
    color: "#bdb4b4",
    position: "absolute",
    left: 15,
    top: -9,
    minWidth: 80,
    textAlign: "center",
  },
  formWrapper: {
    marginBottom: 8,
  },
  colorWrapper: {
    display: "flex",
    alignItems: "center",
  },
  colorPicker: {
    width: "24px!important",
  },
  slider: {
    width: 80,
    marginRight: 12,
    position: "relative",
  },
  shadowDivider: {
    background: "#000",
    position: "absolute",
    left: "49%",
    height: 20,
    width: 2,
  },
}));

const getSettingsFromLayout = layout => {
  const { textShadowColor, textShadowBlur, textShadowV, textShadowH } =
    layout || {};

  return {
    textShadowBlur: textShadowBlur || 0,
    textShadowColor: textShadowColor || "#dee2e6",
    textShadowH: textShadowH || 0,
    textShadowV: textShadowV || 0,
  };
};

const TextEditorSettings = ({ selectedWidget, onWidgetPropertyUpdate }) => {
  const classes = useStyles();

  const settings = getSettingsFromLayout(selectedWidget);

  return (
    <Box className={classes.settingsContainer}>
      <Typography className={classes.textLabel}>
        <FormattedMessage id="widget.textShadow" />
      </Typography>
      <Box className={classes.formWrapper}>
        <Box className={classes.colorWrapper}>
          <Box className={classes.slider}>
            <Divider
              className={classes.shadowDivider}
              flexItem
              orientation="vertical"
            />
            <Slider
              onChange={(e, newValue) =>
                onWidgetPropertyUpdate("textShadowH", newValue)
              }
              max={40}
              min={-40}
              value={settings.textShadowH}
              valueLabelDisplay="on"
            />
          </Box>
          <FormattedMessage id="widget.textShadowHorizontal" />
        </Box>
      </Box>
      <Box className={classes.formWrapper}>
        <Box className={classes.colorWrapper}>
          <Box className={classes.slider}>
            <Divider
              className={classes.shadowDivider}
              flexItem
              orientation="vertical"
            />
            <Slider
              max={40}
              min={-40}
              onChange={(e, newValue) =>
                onWidgetPropertyUpdate("textShadowV", newValue)
              }
              value={settings.textShadowV}
              valueLabelDisplay="on"
            />
          </Box>
          <FormattedMessage id="widget.textShadowVertial" />
        </Box>
      </Box>
      <Box className={classes.formWrapper}>
        <Box className={classes.colorWrapper}>
          <Box className={classes.slider}>
            <Slider
              max={40}
              min={0}
              onChange={(e, newValue) => onWidgetPropertyUpdate("textShadowBlur", newValue)}
              value={settings.textShadowBlur}
              valueLabelDisplay="on"
            />
          </Box>
          <FormattedMessage id="widget.textShadowBlur" />
        </Box>
      </Box>
      <Box className={classes.formWrapper}>
        <Box className={classes.colorWrapper}>
          <Box className={classes.colorPicker}>
            <ColorPicker
              onChange={e => onWidgetPropertyUpdate("textShadowColor", e.rgb)}
              value={settings.textShadowColor}
            />
          </Box>
          <FormattedMessage id="widget.textShadowColor" />
        </Box>
      </Box>
    </Box>
  );
};

export default TextEditorSettings;

import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import { ResponsiveEllipsis } from "../../common";
import DescriptionEdit from "../DescriptionEdit";
import { UPDATE_RESOURCE_DESCRIPTION } from "../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: "100%",
    "& > div > div > span": {
      display: "none",
      color: theme.palette.common.link,
      fontSize: 11,
      marginTop: 2,
    },
    "&:hover > div > div > span": {
      display: "block",
    },
  },
  desc: {
    "& p": {
      fontSize: 11,
      margin: "5px 0",
      textAlign: "left",
      hyphens: "auto",
      lineHeight: "1.5em",
      whiteSpace: "pre-wrap",
      position: "relative",
      "&:hover": {
        cursor: "pointer",
      },
      "& div": {
        display: "inline-block",
      },
    },
  },
  readMoreCont: {
    textAlign: "right",
  },
  readMore: {
    color: theme.palette.common.link,
    padding: 0,
    fontSize: 11,
    marginLeft: 5,
    minWidth: "unset",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    ".uq-pti-hovered &, .uq-pti-selectedResource &": {
      color: theme.palette.primary.dark,
    },
  },
  readMoreIcon: {
    width: "unset",
    height: "unset",
    fontSize: 12,
    marginLeft: 8,
  },
  descEditContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  clamped: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  },
}));

const ProductDescription = ({
  description,
  id,
  itemCanBeUpdated,
  handleError,
  name,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const [readMore, setReadMore] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [resourceDescription, setResourceDescription] = useState(description);
  const [clampedText, setClampedText] = useState(false);

  const [updateResourceDescription, { loading }] = useMutation(
    UPDATE_RESOURCE_DESCRIPTION
  );

  const handleUpdateResourceItem = async () => {
    try {
      const res = await updateResourceDescription({
        variables: { resourceId: id, description: resourceDescription },
      });

      if (res && res.data && res.data.updateResourceDescription) {
        const { success } = res.data.updateResourceDescription;
        if (success) {
          alert.success(
            <FormattedMessage id="product.successUpdatedResource" />
          );
          setShowEdit(false);
        } else {
          const { errorResult } = res.data.updateResourceDescription;
          setResourceDescription(resourceDescription);
          setShowEdit(false);
          handleError(name, errorResult);
        }
      }
    } catch (e) {
      console.log("Error Updating Resource Item ", e);
    }
  };

  const handleCancel = () => {
    setResourceDescription(resourceDescription);
    setShowEdit(false);
  };

  const handleReflow = rleState => {
    const { clamped } = rleState;

    setClampedText(clamped);
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      {/* {!showEdit && (
        <Typography
          className={classes.desc}
          onClick={() => itemCanBeUpdated && setShowEdit(true)}
        >
          {!readMore ? (
            <ResponsiveEllipsis
              text={`${resourceDescription} `}
              maxLine="4"
              ellipsis={"..."}
              trimRight
              basedOn="letters"
              onReflow={handleReflow}
            />
          ) : (
            resourceDescription
          )}
        </Typography>
      )} */}

      {!showEdit && (
        <div className={classes.desc}>
          {!readMore ? (
            <Typography
              className={classes.clamped}
              onClick={() => itemCanBeUpdated && setShowEdit(true)}
            >
              {resourceDescription}
            </Typography>
          ) : (
            <Typography onClick={() => itemCanBeUpdated && setShowEdit(true)}>
              {resourceDescription}
            </Typography>
          )}
        </div>
      )}

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box className={classes.descEditContainer}>
          {itemCanBeUpdated && (
            <DescriptionEdit
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              handleCancel={handleCancel}
              setDescription={setResourceDescription}
              description={resourceDescription}
              handleUpdateResourceItem={handleUpdateResourceItem}
              loading={loading}
              itemCanBeUpdated={itemCanBeUpdated}
            />
          )}
        </Box>

        <Box>
          {resourceDescription && !showEdit && (
            <Typography className={classes.readMoreCont}>
              <Button
                className={classes.readMore}
                onClick={() => setReadMore(prev => !prev)}
              >
                {!readMore && <FormattedMessage id="product.readMore" />}
                {!readMore && (
                  <Icon
                    className={clsx(["fa fa-angle-down", classes.readMoreIcon])}
                  />
                )}
              </Button>

              <Button
                className={classes.readMore}
                onClick={() => setReadMore(prev => !prev)}
              >
                {readMore && <FormattedMessage id="product.readLess" />}
                {readMore && (
                  <Icon
                    className={clsx(["fa fa-angle-up", classes.readMoreIcon])}
                  />
                )}
              </Button>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ProductDescription.defaultProps = {
  name: "",
};

ProductDescription.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  itemCanBeUpdated: PropTypes.bool.isRequired,
  handleError: PropTypes.func.isRequired,
  name: PropTypes.string,
};

export default memo(ProductDescription);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import MonitorSettings from "./MonitorSettings";
import ReceiversSectionContainer from "./ReceiversSectionContainer";
import ConfirmRemoveRecipient from "./ConfirmRemoveRecipient";
import ConfirmRemoveMottagare from "./ConfirmRemoveMottagare";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflow: "hidden",
    overflowY: "auto",
    paddingTop: 5,
  },
}));

const isReceiversSectionVisible = printAndMailSelected => {
  if (printAndMailSelected) return true;
  return false;
};

const StepOne = ({
  monitoringItemId,
  notificationActions,
  notificationActionTypes,
  notificationOccasion,
  notificationOccasionTypes,
  printAndMailSelected,
  receivers,
  recipients,
  setNotificationActions,
  setNotificationOccasion,
  setReceivers,
  setRecipients,
  siteId,
  visible,
  setReceiversAddressErrors,
  receiversAddressErrors,
}) => {
  const classes = useStyles();
  const [toRemoveUser, setToRemoveUser] = useState();
  const [toRemoveReceiverIndex, setToRemoveReceiverIndex] = useState();
  const [
    openConfirmRemoveRecipientModal,
    setOpenConfirmRemoveRecipientModal,
  ] = useState(false);
  const [
    openConfirmRemoveMottagareModal,
    setOpenConfirmRemoveMottagareModal,
  ] = useState(false);

  const handleRemoveReceiver = index => {
    const removeIndex = index || toRemoveReceiverIndex;
    if (removeIndex !== null || removeIndex !== undefined) {
      const newReceivers = [...receivers];
      newReceivers.splice(removeIndex, 1);
      setReceivers([...newReceivers]);
      setToRemoveReceiverIndex();
    }
  };

  const removeRecipient = () => {
    if (toRemoveUser && toRemoveUser.value) {
      const newRecipients = recipients.filter(
        x => x.value !== toRemoveUser.value
      );
      setRecipients(newRecipients);
    }
    setToRemoveUser();
  };

  // Remove mottagare/receiver tab and user recipient
  const handleConfirmRemoveRecipient = () => {
    removeRecipient();
    handleRemoveReceiver(toRemoveReceiverIndex);
    setOpenConfirmRemoveRecipientModal(false);
  };

  // Remove mottagare/receiver tab
  const handleNotConfirmRemoveRecipient = () => {
    handleRemoveReceiver(toRemoveReceiverIndex);
    setOpenConfirmRemoveRecipientModal(false);
  };

  const handleRemoveReceiverTrigger = index => {
    setToRemoveReceiverIndex(index);
    const toRemoveReceiver = { ...receivers[index] };
    if (toRemoveReceiver) {
      const { userId } = toRemoveReceiver;
      const toRemoveRecipient = recipients.find(x => x.value === userId);
      if (toRemoveRecipient && toRemoveRecipient.value) {
        setToRemoveUser(toRemoveRecipient);
        setOpenConfirmRemoveRecipientModal(true);
      } else {
        handleRemoveReceiver(index);
      }
    }
  };

  const handleRemoveRecipient = selectedOptions => {
    let toRemoveRecipient = null;

    recipients.forEach(x => {
      const found = selectedOptions.find(y => x.value === y.value);
      if (!found) {
        toRemoveRecipient = { ...x };
      }
    });

    if (toRemoveRecipient) {
      const { value: recipientUserId } = toRemoveRecipient;
      const toRemoveReceiver = receivers.find(
        x => x.userId === recipientUserId
      );

      const removeReceiverIndex = receivers.findIndex(
        x => x.userId === recipientUserId
      );

      setToRemoveReceiverIndex(removeReceiverIndex);

      if (toRemoveReceiver) {
        setOpenConfirmRemoveMottagareModal(true);
        setToRemoveUser(toRemoveRecipient);
      }
    }
    setRecipients(selectedOptions);
  };

  // Remove the receiver associated by userId with the recipient
  const handleConfirmRemoveReceiver = () => {
    if (toRemoveUser && toRemoveUser.value) {
      const filteredReceivers = receivers.filter(
        x => x.userId !== toRemoveUser.value
      );
      setReceivers(filteredReceivers);
    }
    setOpenConfirmRemoveMottagareModal(false);
    setToRemoveReceiverIndex();
  };

  // Disconnect the receiver and the removed recipient by removing the userId of the receiver.
  const handleNotConfirmRemoveReceiver = () => {
    if (toRemoveUser && toRemoveUser.value) {
      const filteredReceivers = receivers.map(x => {
        if (x.userId !== toRemoveUser.value) {
          return x;
        }
        return { ...x, userId: null };
      });
      setReceivers(filteredReceivers);
    }
    setOpenConfirmRemoveMottagareModal(false);
    setToRemoveReceiverIndex();
  };

  if (!visible) return null;
  return (
    <div className={classes.root}>
      <MonitorSettings
        notificationActions={notificationActions}
        notificationActionTypes={notificationActionTypes}
        notificationOccasion={notificationOccasion}
        notificationOccasionTypes={notificationOccasionTypes}
        receivers={receivers}
        recipients={recipients}
        setNotificationActions={setNotificationActions}
        setNotificationOccasion={setNotificationOccasion}
        setReceivers={setReceivers}
        setRecipients={setRecipients}
        handleRemoveRecipient={handleRemoveRecipient}
      />
      {isReceiversSectionVisible(printAndMailSelected) && (
        <ReceiversSectionContainer
          monitoringItemId={monitoringItemId}
          receivers={receivers}
          recipients={recipients}
          setReceivers={setReceivers}
          siteId={siteId}
          handleRemoveReceiverTrigger={handleRemoveReceiverTrigger}
          setReceiversAddressErrors={setReceiversAddressErrors}
          receiversAddressErrors={receiversAddressErrors}
        />
      )}
      {openConfirmRemoveRecipientModal && (
        <ConfirmRemoveRecipient
          open={openConfirmRemoveRecipientModal}
          setOpen={setOpenConfirmRemoveRecipientModal}
          handleConfirmButton={handleConfirmRemoveRecipient}
          handleNoButton={handleNotConfirmRemoveRecipient}
          toRemoveUser={toRemoveUser}
          tabNumber={toRemoveReceiverIndex}
        />
      )}
      {openConfirmRemoveMottagareModal && (
        <ConfirmRemoveMottagare
          open={openConfirmRemoveMottagareModal}
          setOpen={setOpenConfirmRemoveMottagareModal}
          handleConfirmButton={handleConfirmRemoveReceiver}
          handleNoButton={handleNotConfirmRemoveReceiver}
          tabNumber={toRemoveReceiverIndex}
        />
      )}
    </div>
  );
};

StepOne.defaultProps = {
  recipients: [],
  monitoringItemId: null,
  visible: false,
  receivers: [],
};

StepOne.propTypes = {
  notificationOccasionTypes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  notificationActions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  notificationActionTypes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  notificationOccasion: PropTypes.string.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.number })),
  setNotificationActions: PropTypes.func.isRequired,
  setNotificationOccasion: PropTypes.func.isRequired,
  setRecipients: PropTypes.func.isRequired,
  printAndMailSelected: PropTypes.bool.isRequired,
  siteId: PropTypes.number.isRequired,
  setReceivers: PropTypes.func.isRequired,
  monitoringItemId: PropTypes.number,
  visible: PropTypes.bool,
  receivers: PropTypes.arrayOf(PropTypes.shape({})),
  setReceiversAddressErrors: PropTypes.func.isRequired,
};

export default StepOne;

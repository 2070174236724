import getChildTreeData from "./getChildTreeData";

const getRootTreeData = ({ networks, parentNetworks }) => {
  let childIds = [];
  let tempTree = {};

  if (parentNetworks && Array.isArray(parentNetworks)) {
    parentNetworks.forEach(parentNode => {
      const { childCompanies, networkId } = parentNode;
      childIds = childCompanies.map(child => child.memberId);
      let childTempTreeData = {};
      const hasChildren =
        Array.isArray(childCompanies) && childCompanies.length > 0;

      if (hasChildren) {
        childCompanies.forEach(company => {
          const childTreeData = getChildTreeData(company, null, networkId);

          childTempTreeData = {
            ...childTempTreeData,
            ...childTreeData,
          };
        });
      }

      const childNetworkIds = networks
        .filter(n => n.parentNetworkId === networkId)
        .map(n => n.networkId);

      tempTree = {
        ...tempTree,
        ...childTempTreeData,
        [parentNode.networkId]: {
          ...parentNode,
          isNetwork: true,
          isRoot: true,
          childCompanyIds: childIds,
          childNetworkIds,
        },
      };
    });
  }

  if (networks && Array.isArray(networks)) {
    networks.forEach(node => {
      const { childCompanies, networkId } = node;
      childIds = childCompanies.map(child => child.memberId);
      let childTempTreeData = {};
      const hasChildren =
        Array.isArray(childCompanies) && childCompanies.length > 0;

      if (hasChildren) {
        childCompanies.forEach(company => {
          const childTreeData = getChildTreeData(company, null, networkId);

          childTempTreeData = {
            ...childTempTreeData,
            ...childTreeData,
          };
        });
      }

      tempTree = {
        ...tempTree,
        ...childTempTreeData,
        [node.networkId]: {
          ...node,
          isNetwork: true,
          isRoot: false,
          childCompanyIds: childIds,
        },
      };
    });
  }

  return tempTree;
};

export default getRootTreeData;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Button, Icon } from "@mui/material";
import clsx from "clsx";
import CustomSelect from "../../common/CustomSelect";
import UploadBox from "./UploadBox";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #F2F2F2",
    marginBottom: 15,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  greenIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  selectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    "& .MuiFormControl-root": {
      width: "60%",
    },
  },
}));

const Import = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>Import / Export</Typography>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Export Folders, Users and Roles
                  </Typography>
                  <Typography className={classes.text}>
                    Files and products are not included in the export.
                  </Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Import Folders, Users and Roles into new business
                  </Typography>
                  <Typography className={classes.text}>
                    NOTE, a new company is created in this company's chain, with
                    the name of the exported company!
                  </Typography>
                  <Typography className={classes.text}>
                    Users already in the importing system are not created on the
                    new company.
                  </Typography>
                  <UploadBox />
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx([
                        "fas fa-file-import",
                        classes.greenIcon,
                      ])}
                    />
                    Import
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Product Update
                  </Typography>
                  <Typography className={classes.text}>
                    Download the product update file from the Statistics page
                  </Typography>
                  <Typography className={classes.text}>
                    Only static, inventory, bookable and dynamic products are
                    updated.
                  </Typography>
                  <Typography className={classes.text}>
                    Folder structures are not affected
                  </Typography>
                  <Typography className={classes.text}>
                    UQ2 templates, online designer templates, publications,
                    packages and combo items cannot be updated
                  </Typography>
                  <UploadBox />
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx([
                        "fas fa-file-import",
                        classes.greenIcon,
                      ])}
                    />
                    Import
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Export products, folders and product settings from UQ3
                  </Typography>
                  <Typography className={classes.text}>
                    UQ2 templates, online designer templates, publications,
                    packages and combo items cannot be exported
                  </Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Import products, folders and product settings from UQ3
                  </Typography>
                  <Typography className={classes.text}>
                    UQ2 templates, online designer templates, publications,
                    packages and combo items cannot be imported
                  </Typography>
                  <Box className={classes.selectBox}>
                    <Typography className={classes.label}>Site</Typography>
                    <CustomSelect options={[{ label: "Default", value: 1 }]} />
                  </Box>
                  <Box className={classes.selectBox}>
                    <Typography className={classes.label}>User</Typography>
                    <CustomSelect
                      options={[{ label: "User test", value: 1 }]}
                    />
                  </Box>
                  <UploadBox />
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx([
                        "fas fa-file-import",
                        classes.greenIcon,
                      ])}
                    />
                    Import
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Import;

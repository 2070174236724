import PrivateRoute from "./privateRoute";
import ProductView from "../pages/ProductView";
import ProductViewLink from "../pages/ProductViewLink";

const DeepLinkRoutes = ({ path }) => (
  <>
    <PrivateRoute
      component={(props) => <ProductView {...props} viewPathName={path} />}
      path={path}
    />
    <PrivateRoute
      component={(props) => <ProductViewLink {...props} viewPathName={path} />}
      path={[
        `${path}/:siteId`,
        `${path}/:siteId/:folderId`,
        `${path}/:siteId/:folderId/:resourceId`,
      ]}
    />
  </>
);

export default DeepLinkRoutes;

import React from "react";
import { useSelector } from "react-redux";
import WorkspaceDashboard from "../../components/WorkspaceDashboard";

const OverviewDashboardPage = () => {
  const companyId = useSelector(
    state => state.api.currentViewer.viewer.companyId
  );

  return <WorkspaceDashboard companyId={companyId} />;
};

export default OverviewDashboardPage;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import "date-fns";
import DateFull from "../DateFull";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
  date: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
  labelWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
  },
}));

const DateInline = ({ label, date, setDate }) => {
  const classes = useStyles();

  return (
    <Grid container mt={0.5} mb={0.5}>
      <Grid item xs={12} md={2} className={classes.labelWrap}>
        <span className={classes.label}>{label}</span>
      </Grid>
      <Grid item xs={12} md={10} className={classes.date}>
        <DateFull value={date} onChange={setDate} sx={{ width: "100%" }} />
      </Grid>
    </Grid>
  );
};

DateInline.defaultProps = {
  date: new Date(),
};

DateInline.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  setDate: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
};

const mapStateToProps = state => {
  return {
    locale: state.ui.locale,
  };
};

export default connect(mapStateToProps)(DateInline);

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    lineHeight: "normal",
    paddingLeft: 5,
    paddingRight: 5,
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
  btnAdd: {
    padding: "2px 5px",
    fontSize: 10,
    textTransform: "none",
    fontWeight: 600,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
  },
}));

const TableHeader = ({ locked, onDownloadVersion }) => {
  const classes = useStyles();

  const handleDownloadVersion = () => {
    onDownloadVersion({ versionNumber: 0 });
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th} align="center">
          Version
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="version.date" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="version.originalFile" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="version.alternativeFile" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="version.originalFileInitial" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="version.uploaded" />
        </TableCell>
        <TableCell className={classes.th} align="center">
          <Button
            disabled={locked}
            className={classes.btnAdd}
            onClick={handleDownloadVersion}
          >
            <FormattedMessage id="version.downloadVersion" />
          </Button>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

TableHeader.defaultProps = {
  locked: false,
};

TableHeader.propTypes = {
  onDownloadVersion: PropTypes.func.isRequired,
  locked: PropTypes.bool,
};

export default TableHeader;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import FieldsContainer from "./FieldsContainer";
import Attributes from "./Attributes";
import { SAVE_FIELDS } from "../../../../../../../graphql/mutations";
import { PrimaryButton } from "../../../../../../common/Buttons";

const useStyles = makeStyles(() => ({
  root: {
    background: "#e6e8e6",
  },
  fieldWrapper: {
    overflowX: "auto",
    minHeight: "60vh",
    maxHeight: "545px",
    position: "relative",
  },
  btnWrapper: {
    background: "#f9f9f9",
    padding: 10,
    position: "sticky",
    bottom: 0,
    zIndex: 1,
    marginTop: 32,
  },
}));

const EditFields = ({
  resourceId,
  fields,
  fieldOrderFile,
  textListOptions,
  imageListOptions,
  setFields,
}) => {
  const classes = useStyles();
  const alert = useAlert();

  const [saveFields] = useMutation(SAVE_FIELDS);

  const [dynamicFields, setDynamicFields] = useState([]);
  const [attributeIndex, setAttributeIndex] = useState(null);

  useEffect(() => {
    setDynamicFields(fields);
  }, [fields]);

  const handleSaveFields = async () => {
    const templateFields = dynamicFields.map(x => {
      const tempField = { ...x };
      delete tempField.textList;
      delete tempField.imageList;
      return tempField;
    });
    const model = { templateFields, fieldForOrderFile: fieldOrderFile };
    try {
      const res = await saveFields({
        variables: { productId: resourceId, model },
      });

      if (
        res &&
        res.data &&
        res.data.dynamicTemplateEditContext &&
        res.data.dynamicTemplateEditContext.saveFields
      ) {
        alert.success(<FormattedMessage id="editProduct.saveDynamicFields" />);
        setFields(dynamicFields);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...dynamicFields];
    const removed = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed[0]);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tempValue = reorder(
      dynamicFields,
      result.source.index,
      result.destination.index
    );

    setDynamicFields(tempValue);
  };

  const handleOpenAttribute = (e, idx) => {
    e.stopPropagation();
    setAttributeIndex(idx);
  };

  const handleAddField = () => {
    const sortNo = dynamicFields.length > 0 ? dynamicFields.length : 0;
    const tempField = {
      displayName: "",
      fieldName: "",
      description: "",
      fieldType: "text",
      required: true,
      fieldValue: "",
      imageFolderId: null,
      maxLength: -1,
      sortNo,
      textListId: null,
    };
    const tempArry = [...dynamicFields, tempField];
    setDynamicFields(tempArry);
    setAttributeIndex(tempArry.length - 1);
  };

  const handleDeleteField = () => {
    const tempFields = [...dynamicFields];
    tempFields.splice(attributeIndex, 1);

    setDynamicFields(tempFields);
    setAttributeIndex(null);
  };

  const showAttribute = attributeIndex !== null;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={4} className={classes.fieldWrapper}>
        <FieldsContainer
          fields={dynamicFields}
          handleOpenAttribute={handleOpenAttribute}
          onDragEnd={onDragEnd}
        />
        <Grid item xs={12} className={classes.btnWrapper} align="right">
          <PrimaryButton onClick={() => handleAddField()} marginRight={10}>
            <FormattedMessage id="editProduct.addField" />
          </PrimaryButton>
          <PrimaryButton onClick={() => handleSaveFields()}>
            <FormattedMessage id="btn.save" />
          </PrimaryButton>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        {showAttribute && (
          <Attributes
            fields={dynamicFields}
            setFields={setDynamicFields}
            attributeIndex={attributeIndex}
            handleDeleteField={handleDeleteField}
            textListOptions={textListOptions}
            imageListOptions={imageListOptions}
          />
        )}
      </Grid>
    </Grid>
  );
};

EditFields.defaultProps = {
  resourceId: null,
};

EditFields.propTypes = {
  resourceId: PropTypes.string,
};

export default EditFields;

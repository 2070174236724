import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DELETE_ITEMS = gql`
  mutation DeleteItems($itemIds: [ID!]) {
    shoppingCartContext {
      deleteItems(itemIds: $itemIds) {
        errorResult {
          ...ErrorResultFields
        }
        result {
          groups {
            oneStreamGroups {
              packageName
              packageId
            }
            orderGroupId
          }
        }
        badItems
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_ITEMS;

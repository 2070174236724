import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import FileAttachment from "./FileAttachment";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  attachmentFileContainer: {},
  fileImageAttachment: { width: "auto", height: 200 },
  fileTitle: { color: "rgba(29,28,29,0.7)", fontSize: 12 },
}));

const FileAttachmentsContainer = ({ files, onDownload, onImageClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {files.map((x, index) => {
        return (
          <FileAttachment
            files={files}
            index={index}
            key={x.attachmentId}
            onDownload={onDownload}
            onImageClick={onImageClick}
            thumbnailUrl={x.thumbnailUrl}
            title={x.title}
          />
        );
      })}
    </Box>
  );
};

FileAttachmentsContainer.defaultProps = {
  files: [],
};

FileAttachmentsContainer.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.string,
      filename: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ),
  onDownload: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default FileAttachmentsContainer;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const PICK_ORDERS = gql`
  mutation PickOrders($pickingListOrdersItemIds: [ID!], $signature: String) {
    pickingListContext {
      pickOrders(
        pickingListOrdersItemIds: $pickingListOrdersItemIds
        signature: $signature
      ) {
        errorResult {
          ...ErrorResultFields
        }
        message
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default PICK_ORDERS;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Typography, Button, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../common/TextField";
import DateFull from "../common/FormControl/DateFull";
import IconLink from "../common/FormControl/IconLink";
import CustomSelect from "../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    display: "block",
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 18,
    display: "inline-block",
  },
  icon: {
    fontSize: 18,
    verticalAlign: "sub",
  },
  btn: {
    padding: "4px 15px",
    fontWeight: 600,
    marginTop: 10,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  dlBox: {
    backgroundColor: theme.palette.background.contrastGray,
    padding: 15,
    marginTop: 15,
    "& > button > span": {
      color: theme.palette.common.link,
      marginRight: 15,
    },
    color: theme.palette.text.primary,
  },
  dlTitle: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  italic: {
    fontStyle: "italic",
  },
  link: {
    marginRight: 8,
    color: theme.palette.common.link,
  },
  linkBlue: {
    color: theme.palette.common.link,
  },
  linkIconPrimary: {
    marginRight: 8,
    color: theme.palette.primary.main,
  },
  linkPrimary: {
    color: theme.palette.primary.main,
  },
  spacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  margin: {
    marginTop: 5,
    marginBottom: 10,
  },
}));

const StockProductsSearch = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Typography className={classes.title}>
        <FormattedMessage id="context.manageProducts" />
      </Typography>
      <Grid container className={classes.margin}>
        <Grid item xs={12} md={12}>
          <CustomTextField
            label="Artikelnamn / Ansvarig ägare / Artikelnummer"
            placeholder="Artikelnamn / Ansvarig ägare / Artikelnummer"
            value="123"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.margin}>
        <Grid item xs={12} md={6}>
          <DateFull label="Från datum:" />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateFull label="Till datum:" />
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item xs={12} md={12}>
          <CustomSelect
            options={[
              { label: "Aktiva", value: 1 },
              { label: "Inaktiva", value: 2 },
              { label: "Aktiva och inaktiva", value: 3 },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item xs={12} md={12}>
          <CustomSelect
            options={[
              { label: "Lagerhållna och PDF", value: 1 },
              { label: "Lagerhållna", value: 2 },
              { label: "PDF-filer", value: 3 },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <IconLink
            icon="fa fa-file-excel"
            text="Hämta som Excel"
            customClass={classes.linkIconPrimary}
            customTextClass={classes.linkPrimary}
          />
          <IconLink
            icon="fa fa-file-pdf"
            text="Hämta som PDF"
            customClass={classes.linkIconPrimary}
            customTextClass={classes.linkPrimary}
          />
        </Grid>
        <Grid item xs={12} lg={5} align="right">
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            fullWidth
          >
            <FormattedMessage id="btn.search" />
          </Button>
        </Grid>
      </Grid>
      <Box className={classes.dlBox}>
        <Typography className={classes.dlTitle} gutterBottom>
          Hämta produktlista (excel)
        </Typography>
        <Box>
          <IconLink
            icon="fa fa-file-excel"
            text="Hämta samtliga Excel"
            customClass={classes.link}
            customTextClass={classes.linkBlue}
          />
        </Box>
        <Box>
          <IconLink
            icon="fa fa-file-pdf"
            text="Hämta samtliga PDF"
            customClass={classes.link}
            customTextClass={classes.linkBlue}
          />
        </Box>
        <Box className={classes.spacing} />
        <Typography gutterBottom>
          Listan innehåller namn, artikelnummer, plats, saldo, beställningspunkt
          och materialägare.
        </Typography>
      </Box>
    </Paper>
  );
};

export default StockProductsSearch;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const CREATE_MONITORING_ITEM = gql`
  mutation CreateMonitoringItem($monitoringItemInput: MonitoringItemInput) {
    monitorContext {
      createMonitoringItem(monitoringItem: $monitoringItemInput) {
        success
        notificationId
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_MONITORING_ITEM;

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Grid, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { INIT_DOWNLOAD_WORKSPACETEMPLATE } from "../../../graphql/mutations/CompanyAdminContext";
import { LIST_WORKSPACES } from "../../../graphql/queries/CompanyAdminContext";
import { getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";
import WorkspaceTemplate from "./WorkspaceTemplate";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
}));

const Sites = () => {
  const classes = useStyles();
  
  const companyId = useSelector(getAdminSelectedCompanyId);

  const [initDownloadFile] = useMutation(INIT_DOWNLOAD_WORKSPACETEMPLATE);
  const [workspaces, setWorkspaces] = useState([]);

  const [listWorkspaces, { data: sitesData }] = useLazyQuery(LIST_WORKSPACES);

  const handleDownload = async siteId => {
    try {
      const res = await initDownloadFile({
        variables: { siteId: siteId },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyWorkspaces
      ) {
        saveAs(
          res.data.companyAdminContext.companyWorkspaces
            .initWorkspaceTemplateDownload
        );
      } else {
        console.log("Error downloading");
      }
    } catch (err) {
      console.error("error  >", err);
    }
  };

  useEffect(() => {
    if (companyId) {
      listWorkspaces({ variables: { companyId } });
    }
  }, [companyId]);

  useEffect(() => {
    if (
      sitesData &&
      sitesData.companyAdminContext &&
      sitesData.companyAdminContext.companyWorkspaces &&
      sitesData.companyAdminContext.companyWorkspaces.listWorkspaces
    ) {
      const {
        items,
      } = sitesData.companyAdminContext.companyWorkspaces.listWorkspaces;

      setWorkspaces(items);
    }
  }, [sitesData]);

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          <FormattedMessage id="admin.workspaceTemplates" />
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={3}>
          {workspaces.map(workspace => {
            return (
              <WorkspaceTemplate
                workspace={workspace}
                handleDownload={handleDownload}
              />
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Sites;

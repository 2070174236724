const addressFields = [
  { label: "Address 1", name: "address1" },
  { label: "Address 2", name: "address2" },
  { label: "Address 3", name: "address3" },
  { label: "Address 4", name: "address4" },
  { label: "Address 5", name: "address5" },
  { label: "Zip", name: "zip" },
  { label: "City", name: "city" },
  { label: "Country", name: "country" },
  { label: "Contact", name: "contact" },
  { label: "Reference", name: "reference" },
  { label: "Telephone", name: "telephone" },
  { label: "E-mail", name: "email" },
  { label: "Customer ID", name: "customerId" },
];

export const addressess = [
  {
    name: "deliveryAddress",
    label: "cart.addressDelivery",
    fields: addressFields,
  },
  { name: "postalAddress", label: "cart.addressPostal", fields: addressFields },
  {
    name: "invoiceAddress",
    label: "cart.addressInvoice",
    fields: addressFields,
  },
  {
    name: "visitingAddress",
    label: "cart.addressVisiting",
    fields: addressFields,
  },
];

export const deliveryAddress = {
  address1: "Företag",
  address2: "Avdelning",
  address3: "Gatuadress",
  address4: "Orgnummer",
  address5: "Box",
  zip: "Postnummer",
  city: "Ort",
  country: "Land",
  contact: "Leverans info",
  reference: "Referens",
  telephone: "Telefon",
  email: "E-post",
  customerId: "Kundnummer",
};

export const postalAddress = {
  address1: "Företag",
  address2: "Avdelning",
  address3: "Gatuadress",
  address4: "Orgnummer",
  address5: "Box",
  zip: "Postnummer",
  city: "Ort",
  country: "Land",
  contact: "Leverans info",
  reference: "Referens",
  telephone: "Telefon",
  email: "E-post",
  customerId: "Kundnummer",
};

export const invoiceAddress = {
  address1: "Företag",
  address2: "Avdelning",
  address3: "Gatuadress",
  address4: "Orgnummer",
  address5: "Box",
  zip: "Postnummer",
  city: "Ort",
  country: "Land",
  contact: "Leverans info",
  reference: "Referens",
  telephone: "Telefon",
  email: "E-post",
  customerId: "Kundnummer",
};

export const visitingAddress = {
  address1: "Företag",
  address2: "Avdelning",
  address3: "Gatuadress",
  address4: "Orgnummer",
  address5: "Box",
  zip: "Postnummer",
  city: "Ort",
  country: "Land",
  contact: "Leverans info",
  reference: "Referens",
  telephone: "Telefon",
  email: "E-post",
  customerId: "Kundnummer",
};

export const initialFieldTypes = {
  address1: "REQUIRED",
  address2: "OPTIONAL",
  address3: "REQUIRED",
  address4: "HIDDEN",
  address5: "HIDDEN",
  zip: "REQUIRED",
  city: "REQUIRED",
  country: "OPTIONAL",
  contact: "OPTIONAL",
  reference: "OPTIONAL",
  telephone: "OPTIONAL",
  email: "OPTIONAL",
  customerId: "HIDDEN",
};

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#edf7ed",
    display: "flex",
    padding: "6px 16px",
    borderRadius: 4,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      alignSelf: "center",
    },
  },
  tagAllFilesText: {
    display: "inline",
    fontSize: 12,
    margin: "0 10px",
    color: "#1e4620",
  },
  clickHereButton: {
    display: "inline",
    fontSize: 12,
    color: theme.palette.common.link,
    padding: 0,
    textTransform: "none",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    color: "#4caf50",
  },
}));

const AddMetaDataSection = ({ setOpenMetaDataModal }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <ErrorOutlineIcon className={classes.icon} />
      <Typography
        component="span"
        className={classes.tagAllFilesText}
        variant="body1"
      >
        <FormattedMessage id="metadata.askTag" />
      </Typography>
      <Button
        component="span"
        className={classes.clickHereButton}
        onClick={() => setOpenMetaDataModal(true)}
      >
        <FormattedMessage id="metadata.clickHere" />
      </Button>
    </Grid>
  );
};

AddMetaDataSection.propTypes = {
  setOpenMetaDataModal: PropTypes.func.isRequired,
};

export default AddMetaDataSection;

import { FormattedMessage } from "react-intl";
import { Button } from "@mui/material";
import { styled } from "@mui/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.common.link,
  fontSize: 13,
  marginLeft: 5,
  minWidth: "unset",
  padding: 0,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
    opacity: 0.8,
  },
}));

const WorkspaceOwnerContactLink = ({ onClick }) => (
  <div>
    <StyledButton onClick={onClick}>
      <FormattedMessage id="workspace.workspaceOwnerContactLinkMessage" />
    </StyledButton>
  </div>
);

export default WorkspaceOwnerContactLink;

import { gql } from '@apollo/client';

const getUserContactDetails = gql`
  query GetUserContactDetails($username: String) {
    userAccountContext {
      getUserContactDetails(username: $username) {
        avatarUrl
        biography
        company
        currentPosition
        directMessage
        displaySettings {
          company
          directMessage
          email
          mainEducation
          mobile
          phone
        }
        email
        employmentType
        firstname
        lastname
        location
        mainEducation
        mobile
        phone
        skillTags
        webLinks
      }
    }
  }
`;

export default getUserContactDetails;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Box, TextField } from "@mui/material";

const EmploymentRow = ({ accountSettings, setAccountSettings }) => {

  const { currentPosition, employmentType } = accountSettings;

  const handleChange = e => {
    const { name, value } = e.target;
    setAccountSettings({ ...accountSettings, [name]: value });
  };

  return (
    <Box display="flex">
          <TextField
            label={<FormattedMessage id="settings.position" />}
            name="currentPosition"
            onChange={handleChange}
            value={currentPosition || ""}
            variant="standard"
            style={{ paddingRight: 5 }}
            size="small"
            margin="dense"
            fullWidth
          />
          <TextField
            label={<FormattedMessage id="settings.employmentType" />}
            name="employmentType"
            onChange={handleChange}
            value={employmentType || ""}
            variant="standard"
            style={{ paddingRight: 5 }}
            size="small"
            margin="dense"
            fullWidth
      />
    </Box>
  );
};

EmploymentRow.defaultProps = {
  accountSettings: {},
};

EmploymentRow.propTypes = {
  accountSettings: PropTypes.shape({
    employmentType: PropTypes.string,
    currentPosition: PropTypes.string,
  }),
  setAccountSettings: PropTypes.func.isRequired,
};

export default EmploymentRow;

import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Typography, Grid, Box } from "@mui/material";
import TextAreaFull from "../../common/FormControl/TextAreaFull";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import { appcUrl } from "../../../services/config";
import { CREATE_IMAGE_BANK_SHARE } from "../../../graphql/mutations/ShareContext";
import RecipientsInput from "./RecipientsInput";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    backgroundColor: theme.palette.common.white,
    "& .MuiGrid-container:last-of-type": {
      marginBottom: 0,
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  btn: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
    },
    "& .Mui-disabled:hover": {
      cursor: "not-allowed",
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 0,
  },
  switchRoot: {
    marginRight: 0,
  },
  switchLabel: {
    fontSize: 12,
  },
}));

const ShareLinkModal = ({ open, handleClose, onShareCompleted, imageData }) => {
  const classes = useStyles();
  const alert = useAlert();

  const MAX_DESCRIPTION_LENGTH = 250;

  const [disableModal, setDisableModal] = useState(false);
  const [disableShareButton, setDisableShareButton] = useState(true);
  const [description, setDescription] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [haveValidDescription, setHaveValidDescription] = useState(false);
  const [haveValidRecipients, setHaveValidRecipients] = useState(false);
  const [haveCallback] = useState(typeof onShareCompleted === "function");
  const [haveImageData] = useState(
    Array.isArray(imageData) && imageData.length > 0
  );

  const [
    createImageBankShare,
    {
      data: createShareData,
      loading: createShareLoading,
      error: createShareError,
    },
  ] = useMutation(CREATE_IMAGE_BANK_SHARE);

  useEffect(() => {

    var notReadyToShare = !(
      haveCallback &&
      haveImageData &&
      haveValidRecipients &&
      haveValidDescription
    );
    setDisableShareButton(notReadyToShare);
  }, [haveValidRecipients, haveValidDescription]);

  useEffect(() => {
    if (description.length === 0) {
      setHaveValidDescription(false);
    } else if (!haveValidDescription) {
      setHaveValidDescription(true);
    }
  }, [description]);

  useEffect(() => {

    if (recipients.length === 0) {
      setHaveValidRecipients(false);
    } else if (!haveValidRecipients) {
      setHaveValidRecipients(true);
    }
  }, [recipients]);

  useEffect(() => {
    if (
      createShareData &&
      createShareData.shareContext &&
      createShareData.shareContext.createImageBankShare
    ) {
      const imageBankShareResult =
        createShareData.shareContext.createImageBankShare;
      handleCreateResult(imageBankShareResult);
    }
  }, [createShareData]);

  useEffect(() => {
    if (createShareError) {
      handleCreateError();
    }
  }, [createShareError]);

  const handleDescriptionChange = e => {
    var newValue = e.target.value;
    if (newValue.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(newValue);
    }
  };

  const handleShareButtonClick = async e => {
    if (haveCallback) {
      setDisableModal(true);
      try {
        const baseUrlForShare = `${appcUrl}/shared-image-basket/`;
        await createImageBankShare({
          variables: {
            baseUrlForShare: baseUrlForShare,
            usernames: recipients.map(n => n.value).join(","),
            comment: description,
            from: null,
            until: null,
            images: imageData,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleCreateResult = imageBankShareResult => {
    const { success, errorResult, result } = imageBankShareResult;
    if (success) {
      onShareCompleted({ description, recipients });
    } else {
      handleCreateError();
    }
  };

  const handleCreateError = () => {
    alert.error(
      <FormattedMessage id="imageBasket.shareModal.error.couldNotShare" />
    );
    setDisableModal(false);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="imageBasket.shareModal.title" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              <Grid container spacing={12} className={classes.labelMargin}>
                <Grid item md={6} xs={12}>
                  <FormattedMessage id="imageBasket.shareModal.selectUsers" />
                  {" *"}
                </Grid>
                <Grid item md={6} xs={12} align="right">
                  <FormattedMessage id="siteUsers.seperateWithComma" />
                </Grid>
              </Grid>
            </Typography>
            <RecipientsInput
              disabled={disableModal}
              recipients={recipients}
              setRecipients={setRecipients}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              <FormattedMessage id="imageBasket.shareModal.addDescription" />
              {" *"}
            </Typography>
            <TextAreaFull
              placeholder=""
              rows="5"
              rowsMax="5"
              required={true}
              disabled={disableModal}
              value={description}
              onChange={handleDescriptionChange}
            />
          </Grid>
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <Button
            className={classes.btn}
            fullWidth
            disabled={disableModal || disableShareButton}
            onClick={handleShareButtonClick}
          >
            <FormattedMessage id="imageBasket.shareModal.share" />
            <Icon className={clsx(["fa fa-paper-plane", classes.btnIcon])} />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ShareLinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onShareCompleted: PropTypes.func.isRequired,
  imageData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ShareLinkModal;

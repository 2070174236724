import { gql } from '@apollo/client';

const ACCEPT_INCOMING_SUPPLIER_REQUEST = gql`
  mutation AccptIncomingSupplierRequest(
    $companyId: Int!
    $supplierRequestId: ID!
  ) {
    companyAdminContext {
      suppliers {
        acceptIncomingSupplierRequest(
          companyId: $companyId
          supplierRequestId: $supplierRequestId
        )
      }
    }
  }
`;

export default ACCEPT_INCOMING_SUPPLIER_REQUEST;

import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ToolTipArrow from "../../../common/ToolTipArrow";

const useStyles = makeStyles(theme => ({
  link: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    color: theme.palette.primary.contrastText,
    marginBottom: 10,
    textTransform: "uppercase",
    fontWeight: 600,
    display: "block",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
    "&:focus": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const PublicNavMenuList = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <ToolTipArrow
        title={intl.formatMessage({ id: "navMenu.overview" })}
        placement="top"
      >
        <Link
          to="/"
          className={classes.link}
          onClick={() => {
            console.log("** onHomeClick");
          }}
        >
          <i className={clsx(["fas fa-home", classes.icon])} />
        </Link>
      </ToolTipArrow>
    </>
  );
};

export default PublicNavMenuList;

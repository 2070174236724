import React from "react";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles(theme => ({
  skeleton: {
    borderRadius: 2,
    marginBottom: 10,
    textAlign: "center",
  },
}));

const TreeSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton
        variant="rect"
        width="90%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="45%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="77%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="85%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="42%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="55%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="90%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="33%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="24%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="65%"
        height={15}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
    </>
  );
};

export default TreeSkeleton;

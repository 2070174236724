import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  skeletonWrap: {
    backgroundColor: "#fff",
    padding: "40px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  skeleton: {
    borderRadius: 4,
  },
}));

const CommentsSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      {[1, 2, 3].map(x => (
        <Box className={classes.skeletonWrap} key={x}>
          <Skeleton
            variant="rect"
            width="100%"
            height={113}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
        </Box>
      ))}
    </>
  );
};

export default CommentsSkeleton;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Grid, Typography } from "@mui/material";
import FormattedDate from "../../common/FormattedDate";
import FormattedTime from "../../common/FormattedTime";

const useStyles = makeStyles(theme => ({
  mdAlignRight: {
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  textInline: {
    display: "inline-block",
    marginRight: 5,
  },
}));

const Header = ({ image, orderDate, companyOrderno }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <img src={image} alt="" />
      </Grid>
      <Grid item xs={12} md={6} className={classes.mdAlignRight}>
        <Typography className={clsx([classes.strong, classes.textInline])}>
          Order:
        </Typography>
        <Typography className={classes.textInline}>{companyOrderno}</Typography>
        <Typography>
          <FormattedDate value={orderDate} />{" "}
          <FormattedTime value={orderDate} />
        </Typography>
      </Grid>
    </Grid>
  );
};

Header.defaultProps = {
  image: null,
  orderDate: null,
  companyOrderno: null,
};

Header.propTypes = {
  image: PropTypes.string,
  orderDate: PropTypes.instanceOf(Date),
  companyOrderno: PropTypes.string,
};

export default Header;

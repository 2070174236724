import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const UPDATE_GROUP_ADDRESSES = gql`
  mutation UpdateGroupAddresses($update: UpdateGroupAddressesInput!) {
    companyAdminContext {
      companyGroupSettings {
        updateGroupAddresses(update: $update) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_GROUP_ADDRESSES;

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { setFolderId } from "../../actions";
import TreeNode from "./TreeNodeCategory";

const TreeNodeContainer = ({
  data,
  siteFolderTreeColors,
  // eslint-disable-next-line no-shadow
  setFolderId,
  selectedFolderId,
  openedNodes,
  handleOpenNode,
}) => {
  return (
    <TreeNode
      setFolderId={setFolderId}
      level={0}
      data={data}
      siteFolderTreeColors={siteFolderTreeColors}
      selectedFolderId={selectedFolderId}
      openedNodes={openedNodes}
      handleOpenNode={handleOpenNode}
    />
  );
};

const mapStateToProps = state => {
  return {
    siteFolderTreeColors:
      state.api.productView.productView.siteFolderTreeColors,
  };
};

TreeNodeContainer.defaultProps = {
  selectedFolderId: null,
  handleOpenNode: () => {},
};

TreeNodeContainer.propTypes = {
  selectedFolderId: PropTypes.number,
  siteFolderTreeColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setFolderId: PropTypes.func.isRequired,
  handleOpenNode: PropTypes.func,
  openedNodes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default connect(mapStateToProps, null)(TreeNodeContainer);

import { handleActions } from "redux-actions";
import {
  setChannels,
  setChannelsLoading,
  setChannelsError,
  setActiveChannelId,
  addNewMessage,
} from "../../../../actions";

const defaultState = {
  channels: [],
  channelsLoading: false,
  channelLoading: false,
  errorMessage: null,
  activeChannelId: null,
};

export default handleActions(
  {
    [setChannels]: (state, { payload }) => {
      const { channels } = payload;
      const initActiveChannelId =
        channels && Array.isArray(channels) && channels.length > 0
          ? channels[0].siteChannelId
          : null;
      return {
        ...state,
        errorMessage: null,
        channelsLoading: false,
        channels,
        activeChannelId: initActiveChannelId,
      };
    },
    [setChannelsLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        errorMessage: null,
        channelsLoading: loading,
      };
    },
    [setChannelsError]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        channels: [],
        channelsLoading: false,
        errorMessage: error,
      };
    },
    [setActiveChannelId]: (state, { payload }) => {
      const { channelId } = payload;
      return {
        ...state,
        activeChannelId: channelId,
      };
    },
    [addNewMessage]: (state, { payload }) => {
      const { channels } = state;
      const { message, siteChannelId } = payload;
      const updatedChannels = channels.map(channel => {
        if (channel.siteChannelId === siteChannelId) {
          const toUpdateChannel = { ...channel };
          const { channelMessages } = toUpdateChannel;
          let { items: toUpdateItems = [] } = channelMessages || {};

          // Need to check if the new message already exist.
          // This sometimes happen when the subscription listens to a "false" update,
          // Meaning there was no messages but the subscription fetches the last message.
          const exist = toUpdateItems.find(x => x.id === message.id);
          if (!exist) {
            toUpdateItems = [...toUpdateItems, message];
          }
          return {
            ...toUpdateChannel,
            channelMessages: {
              ...toUpdateChannel.channelMessages,
              items: toUpdateItems,
            },
          };
        }
        return channel;
      });
      return {
        ...state,
        channels: updatedChannels,
      };
    },
  },
  defaultState
);

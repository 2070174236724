import React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Box, Grid, Icon, Typography } from "@mui/material";
import clsx from "clsx";

import Avatar from "../../common/Avatar";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  authorContainer: {
    flexWrap: "noWrap",
  },
  avatarMargin: {
    marginRight: 0,
  },
  authorName: {
    fontSize: 12,
    fontWeight: 400,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  ingress: {
    paddingTop: "5px",
    fontSize: 12,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  newsTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 5,
    marginBottom: 5,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  newsItemContainer: {
    marginTop: 0,
    marginBottom: 5,
    padding: "10px 10px 15px 10px",
    background: props => props.contentBG || theme.palette.background.paper,
  },
  titleContainer: {
    position: "relative",
  },
  date: {
    fontSize: 11,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  pageTitle: {
    fontSize: 16,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  joinNowContainer: {
    marginTop: 5,
  },
  joinNow: {
    fontSize: 10,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  leftTitleBar: {
    position: "absolute",
    left: -10,
    top: 0,
    height: 30,
    width: 2,
    background: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  arrowIcon: {
    fontSize: 12,
    marginLeft: 3,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  workspace: {
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    fontSize: 10,
  },
  mainImg: {
    width: "100%",
    borderRadius: 0,
    paddingTop: "0px",
    height: "150px",
    objectFit: "cover",
    objectPosition: " 20% 10%",
  },
  clamped: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  },
}));

const NewsPost = ({ className, customStyles, news, onClickReadMore }) => {
  const classes = useStyles(customStyles);

  const {
    author,
    category,
    createdDate,
    id,
    image,
    leadText,
    site,
    title,
    newsPost
  } = news;

  const handleOnClick = (newspost) => {

    if (onClickReadMore) {
      onClickReadMore(newspost);
    }
  }

  return (
    <Box className={className}>
      {image && (
        <Grid container>
          <img alt={title} className={classes.mainImg} src={image} />
        </Grid>
      )}
      <Grid container className={classes.newsItemContainer}>
        <Grid item xs={12}>
          <Typography className={classes.workspace}>{site}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.workspace}>{category}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.titleContainer}>
          <Box className={classes.leftTitleBar} />
          <Typography className={classes.newsTitle}>{title}</Typography>
        </Grid>

        <Grid
          container
          item
          alignItems="center"
          className={classes.authorContainer}
          spacing={1}
          xs={12}
        >
          <Grid item>
            <Avatar author={author} customClass={classes.avatarMargin} />
          </Grid>
          <Grid item>
            <Typography className={classes.authorName}>{author}</Typography>
            <Typography className={classes.date}>
              {moment(createdDate)
                .format("L")
                .toString()}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className={clsx([classes.ingress, image && classes.clamped])}
          >
            {leadText}
          </Typography>
        </Grid>
        {newsPost && (
          
          <Grid item xs={12} className={classes.joinNowContainer} >
            <Typography className={classes.joinNow} onClick={n => handleOnClick(news)}>
            <FormattedMessage id="product.readMore" /> <Icon className={clsx(["fas fa-angle-double-right", classes.arrowIcon])} />
          </Typography>
        </Grid>
        )}

      </Grid>
    </Box>
  );
};

export default NewsPost;

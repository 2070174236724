import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { FROM_VALUES_LIST } from "../../constant/types";
import TextField from "./TextField";
import CustomSelect from "./CustomSelect";

const QuantityField = ({
  selectionType,
  quantity,
  onChange,
  valuesList,
  onClick,
  defaultValue,
}) => {
  const dropDownOptions =
    selectionType === FROM_VALUES_LIST
      ? valuesList.map(option => ({
          label: option,
          value: option,
        }))
      : [];

  if (selectionType === FROM_VALUES_LIST) {
    return (
      <FormControl variant="outlined" fullWidth>
        <CustomSelect
          onChange={onChange}
          value={quantity || defaultValue}
          options={dropDownOptions}
        />
      </FormControl>
    );
  }
  return (
    <Box>
      <TextField
        value={quantity}
        defaultValue={defaultValue}
        onChange={onChange}
        onClick={onClick}
        type="number"
      />
    </Box>
  );
};

QuantityField.propTypes = {
  selectionType: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClick: PropTypes.func.isRequired,
  defaultValue: PropTypes.number.isRequired,
};

export default QuantityField;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useDeepLinkContext } from "../../../hooks";

import EmptyList from "../EmptyList";
import NoViewPermission from "../NoViewPermission";
import ViewContainer from "../ViewContainer";

import ProductRowItem from "./ProductRowItem";

const ProductsListView = ({
  products,
  setFolderId,
  setSearchToInitialState,
  showProductView,
}) => {
  const deepLinkContext = useDeepLinkContext();
  const { deepLink } = deepLinkContext;

  const [linkedProductId] = useState(deepLink?.resourceId);

  useEffect(() => {
    if (deepLink && products && products.items && products.items.length > 0) {
      deepLinkContext.clearData();
    }
  }, [deepLink, products]);

  const RenderItems = ({ items }) => (
    items.map((productItem, idx) => (
      <ProductRowItem
        key={productItem.id}
        product={productItem}
        setFolderId={setFolderId}
        setSearchToInitialState={setSearchToInitialState}
        showProductModalOnOpen={productItem.id == linkedProductId}
      />
    ))
  );

  const haveItems = products && products.items && products.items.length > 0;

  return (
    !deepLink && (
      <ViewContainer>
        {showProductView && haveItems && <RenderItems items={products.items} />}
        {showProductView && !haveItems && <EmptyList messageId="product.categoryEmpty" />}
        {!showProductView && <NoViewPermission />}
      </ViewContainer>
    )
  );
};

ProductsListView.defaultProps = {
  detailedSearchOpen: null,
  siteRoleRights: null,
};

ProductsListView.propTypes = {
  cartAddItem: PropTypes.func.isRequired,
  detailedSearchOpen: PropTypes.bool,
  products: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            articleNo: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            thumbnailImageUri: PropTypes.string.isRequired,
          })
        ),
      }).isRequired
    ).isRequired,
  }).isRequired,
  setFolderId: PropTypes.func.isRequired,
  setSearchToInitialState: PropTypes.func.isRequired,
  showProductView: PropTypes.bool.isRequired,
  siteRoleRights: PropTypes.shape({ productView: PropTypes.bool }),
};

export default ProductsListView;

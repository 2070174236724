import { gql } from '@apollo/client';

const HIGHLIGHT_RESOURCE = gql`
  mutation HighlightResource(
    $highlightResourceInput: HighlightResourceInputType!
  ) {
    highlightResource(highlightResourceInput: $highlightResourceInput) {
      success
    }
  }
`;

export default HIGHLIGHT_RESOURCE;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { alpha, Button, InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  setSearchResults,
  setSearchQuery,
} from "../../../actions";
import { useSearchModal } from "../../../hooks";

const useStyles = makeStyles(theme => ({
  searchWrap: {
    marginLeft: 10,
    maxWidth: 400,
    position: "relative",
    width: "100%",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.grey, 0.45),
    marginRight: 0,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    right: 0,
    cursor: "pointer",
    color: theme.palette.component.headerColor,
    minWidth: "unset",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  inputRoot: {
    color: theme.palette.component.headerColor,
    width: "100%",
  },
  inputInput: {
    paddingTop: 11,
    paddingRight: 20,
    paddingBottom: 11,
    paddingLeft: 11,
    transition: theme.transitions.create("width"),
    color: theme.palette.component.headerColor,
    fontSize: 12,
    "&::placeholder": {
      color: theme.palette.common.navbarColor,
      opacity: 1,
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.common.navbarColor,
      opacity: 1,
    },
    "&::-ms-input-placeholder": {
      color: theme.palette.common.navbarColor,
      opacity: 1,
    },
  },
  zoomIcon: {
    fontSize: 16,
  },
}));

const HeaderSearch = ({
  searchQuery,
  selectedWorkspace,
  setSearchResults,
  setSearchQuery,
}) => {
  const classes = useStyles();
  const search = useSearchModal();
  const intl = useIntl();

  const [searchText, setSearchText] = useState(searchQuery);

  const siteId =
    selectedWorkspace && selectedWorkspace.id ? selectedWorkspace.id : 0;

  useEffect(() => {
    if (siteId && siteId !== 0) {
      setSearchResults({});
    }
  }, [siteId]);

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      initiateSearch();
    }
  };

  const initiateSearch = () => {
    setSearchQuery({ searchQuery: searchText });
    search.open();
  };

  return (
    <div className={classes.searchWrap}>
      <div className={classes.search}>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "Search" }}
          onChange={e => setSearchText(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={intl.formatMessage({ id: "nav.search" })}
          value={searchText}
        />
        <Button
          className={classes.searchIcon}
          onClick={initiateSearch}
        >
          <i className={`nc-icon nc-zoom-split ${classes.zoomIcon}`} />
        </Button>
      </div>
    </div>
  );
};

HeaderSearch.propTypes = {
  searchDetails: PropTypes.shape({
    name: PropTypes.string,
    showDetails: PropTypes.bool,
  }).isRequired,
  searchQuery: PropTypes.string.isRequired,
  selectedWorkspace: PropTypes.shape({ id: PropTypes.number }).isRequired,
  setSearchResults: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    searchDetails: state.ui.toggleDetails,
    searchQuery: state.ui.toggleDetails.searchQuery,
    selectedWorkspace: state.ui.toggleWorkspaces.workspace,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setSearchResults,
      setSearchQuery,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);

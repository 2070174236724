import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import UserSelector from "./UserSelector";
import UserCellValue from "../common/UserCellValue";

const UserField = ({ disabled, editable, hideUnassigned, name, onChange, userLookup, value = null, displayBlock }) => {
  const ifEditable = (o) => ((editable && !disabled) ? o : undefined);

  const [isEditing, setIsEditing] = useState();

  useEffect(() => {
    setIsEditing(false);
  }, [value]);

  if (isEditing) {

    return (
      <Box sx={{
        border: "1px solid rgba(0, 0, 0, 0)",
        borderRadius: "4px",
        paddingLeft: "0px",
        "&:hover": {
          backgroundColor: '#f0f0f0',
          cursor: "text",
        },
      }}>
      <UserSelector
        autoFocus
        disabled={disabled}
        onChange={
          (newValue) => {
            onChange?.({ name, value: newValue });
          }
        }
        onClose={
          () => {
            setIsEditing(false);
          }
        }
        userLookup={userLookup}
        value={value}
        />
      </Box>
    );

  }
  else if (value || !hideUnassigned) {

    return (
        <Box sx={{
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "4px",
          paddingLeft: "0px",
          "&:hover": {
            backgroundColor: (disabled || !editable) ? "" : '#f0f0f0',
            cursor: (disabled || !editable) ? "default" : "text",
          },
        }}>
      <UserCellValue
        disabled={disabled}
        onClick={
          ifEditable(
            (e) => {
              if (!isEditing && Array.isArray(userLookup)) {
                e.preventDefault();
                e.stopPropagation();
                setIsEditing(true);
              }
            }
          )
        }
        user={value}
          />
      </Box>
    );

  }
  else {

    return (
      <div
        onClick={
          ifEditable(
            (e) => {
              if (!isEditing && Array.isArray(userLookup)) {
                e.preventDefault();
                e.stopPropagation();
                setIsEditing(true);
              }
            }
          )
        }
        style={{
          cursor: ifEditable("pointer"),
          display: displayBlock ? "block" : "list-item",
          padding: "8px",        
          border: displayBlock? "solid 1px black" : null,
          minHeight: "20px"
          
        }}
      >
      </div>
    );

  }
};

export default UserField;

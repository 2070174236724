import { PrimaryButton, GrayButton } from "../../../common";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme"; 

const ActionStrip = ({ allowAbort = true, activeStep, onSetActiveStep, onAbort, onComplete, onVerify, verifyloading }) => {

  const theme = useTheme();

  const btnColor = {
    marginTop: 0,
    marginRight: 0,
    marginLeft: 0,
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: '#00c9b7',
    "&:hover": {
      backgroundColor: '#00c9b7',
      opacity: 0.8,
    },
  };

  const setActiveStep = (step) => {
    onSetActiveStep(step);
  }

  const handleVerify = () => {

    onVerify();
  }

  const handleAbort = () => {
    onAbort();
  }

  const handleComplete = () => {
    onComplete();
  }

  return (
  <>
    {activeStep === 0 && (
      <>
        {allowAbort && (<GrayButton marginRight={8} onClick={onAbort}>Avbryt</GrayButton>)}
          {/*<PrimaryButton onClick={n => setActiveStep(1)}>Nästa</PrimaryButton>*/}
          <Button variant="contained" size="small" sx={btnColor} onClick={n => setActiveStep(1)}>Nästa</Button>
      </>
    )}

    {activeStep === 1 && (
      <>
        <GrayButton marginRight={8} disabled={verifyloading} onClick={n => { setActiveStep(0); }}>Tillbaka</GrayButton>        
        <Button variant="contained" size="small" sx={btnColor} disabled={verifyloading} onClick={n => handleVerify()}>Verifiera</Button>
      </>
    )}

    {activeStep === 2 && (
      <>
        {allowAbort && (<GrayButton marginRight={8} onClick={onAbort}>Avbryt</GrayButton>)}
          <PrimaryButton onClick={n => handleComplete()}>Slutför</PrimaryButton>
          <Button variant="contained" size="small" sx={btnColor} onClick={n => handleComplete()}>Slutför</Button>
        </>
      )}

  </>);
}
export default ActionStrip;
import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DELETE_ONE_STREAM_JOB = gql`
  mutation DeleteOneStreamJob($oneStreamJobId: ID!) {
    oneStreamContext {
      deleteJob(oneStreamJobId: $oneStreamJobId) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_ONE_STREAM_JOB;

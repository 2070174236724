import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  widgetHead: {
    padding: "0px 0px 15px 0px",
    display: "flex",
    alignItems: "center",
    "& .MuiFormControl-root": {
      marginRight: 15,
    },
    "& .MuiFormControl-fullWidth": {
      width: 160,
      opacity: 0.7,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.common.white,
    },
  },
  widgetTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#212529",
    marginRight: 15,
    lineHeight: "normal",
    flex: 1,
  },
  channelName: {
    marginLeft: "auto",
    fontSize: 12,
    fontWeight: 500,
    color: "#888888",
    flex: 0,
  },
}));

const DashboardWidgetHead = ({ children, customClass, rightTitle, title }) => {
  const classes = useStyles();

  return (
    <Box className={clsx([classes.widgetHead, customClass])}>
      <Typography className={classes.widgetTitle}>{title}</Typography>
      {rightTitle && (
        <Typography className={classes.channelName}>{rightTitle}</Typography>
      )}
      {children}
    </Box>
  );
};

export default DashboardWidgetHead;

import { gql } from '@apollo/client';
import AddressSettingsFragment from "../../../fragments/AdressSettings";

const GET_COMPANY_ADDRESS_FIELD_SETTINGS = gql`
  query GetCompanyAddressFieldSettings($companyId: Int!) {
    companyAdminContext {
      addressSettings {
        getCompanyAddressFieldSettings(companyId: $companyId) {
          deliveryAddressSettings {
            ...AddressSettingsFields
          }
          invoiceAddressSettings {
            ...AddressSettingsFields
          }
          postalAddressSettings {
            ...AddressSettingsFields
          }
          visitingAddressSettings {
            ...AddressSettingsFields
          }
          success
        }
      }
    }
  }
  ${AddressSettingsFragment}
`;

export default GET_COMPANY_ADDRESS_FIELD_SETTINGS;

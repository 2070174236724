import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../hooks";
import moment from "moment";
import DatePicker from "react-datepicker";
import FolderSelector from "../../common/FolderSelector";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import CustomSelect from "../../common/CustomSelect";
import { UPDATE_BOOKING_FOLDER_SETTINGS } from "../../../graphql/mutations/BookingContext";
import { PrimaryButton } from "../../common/Buttons";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "700px",
    minHeight: "300px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    overflow: "unset",
  },
  img: {
    marginBottom: 10,
    width: 40,
  },
  bodyContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  container: {
    marginTop: 20,
  },
  time: {
    borderRadius: 5,
    marginRight: 15,
    fontSize: 12,
    padding: 10,
    border: "1px solid #dadada",
    textAlign: "center",
  },
}));

const FolderSelectorModal = ({
  open,
  handleClose,
  siteId,
  pickUpTimeSetting,
  returnTimeSetting,
  bookingSettings,
  setBookingSettingsChanged,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [updateBookingFolderSettings] = useMutation(
    UPDATE_BOOKING_FOLDER_SETTINGS
  );
  const [bookingFolderSettings, setBookingFolderSettings] = useState({});

  const options = [
    {
      label: intl.formatMessage({ id: "booking.workingDays" }),
      value: "WORKING_DAYS",
    },
    {
      label: intl.formatMessage({ id: "booking.calendarDays" }),
      value: "CALENDAR_DAYS",
    },
  ];

  useEffect(() => {
    if (bookingSettings) {
      setBookingFolderSettings(bookingSettings);
    }
  }, [bookingSettings]);

  const handleUpdateSettings = async () => {
    const settings = {
      ...bookingFolderSettings,
      folderId: bookingFolderSettings.folderId,
      siteId,
      pickupTime: moment(bookingFolderSettings.pickupTime).format("HH:mm"),
      returnTime: moment(bookingFolderSettings.returnTime).format("HH:mm"),
    };
    delete settings.folderName;
    try {
      const res = await updateBookingFolderSettings({
        variables: { settings },
      });

      if (
        res &&
        res.data &&
        res.data.bookingContext &&
        res.data.bookingContext.updateBookingFolderSettings
      ) {
        const { success } = res.data.bookingContext.updateBookingFolderSettings;
        if (success) {
          setBookingSettingsChanged(true);
          alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          snackbar.workspaceError(
            <FormattedMessage id="common.genericErrorMessage" />
          );
        }
        handleClose();
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setBookingFolderSettings({ ...bookingFolderSettings, [name]: value });
  };

  const handleChangeTime = (name, value) => {
    setBookingFolderSettings({ ...bookingFolderSettings, [name]: value });
  };

  const handleFolderRowClick = (e, folder) => {
    e.stopPropagation();
    setBookingFolderSettings({
      ...bookingFolderSettings,
      folderId: folder.folderId,
      folderName: folder.name,
    });
  };

  const folder = { name: bookingFolderSettings.folderName };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody>
        <FolderSelector
          value={folder}
          handleFolderRowClick={handleFolderRowClick}
        />
        <Box className={classes.container}>
          <Typography>
            <FormattedMessage id="booking.allowedBookingDays" />
          </Typography>
          <CustomSelect
            options={options}
            inputLabel={<FormattedMessage id="monitoring.clickToSelect" />}
            value={bookingFolderSettings.allowedBookingDays}
            name="allowedBookingDays"
            onChange={handleChange}
          />
        </Box>
        <Grid container spacing={1} className={classes.container}>
          <Box>
            <Typography>
              <FormattedMessage id="booking.pickUpTime" />
            </Typography>
            <DatePicker
              className={classes.time}
              selected={bookingFolderSettings.pickupTime || new Date()}
              onChange={e => handleChangeTime("pickupTime", e)}
              showTimeSelect
              showTimeSelectOnly
              timeCaption={<FormattedMessage id="booking.time" />}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeIntervals={30}
            />
          </Box>

          <Box>
            <Typography>
              <FormattedMessage id="booking.returnTime" />
            </Typography>
            <DatePicker
              className={classes.time}
              selected={bookingFolderSettings.returnTime || new Date()}
              onChange={e => handleChangeTime("returnTime", e)}
              showTimeSelect
              showTimeSelectOnly
              timeCaption={<FormattedMessage id="booking.time" />}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeIntervals={30}
            />
          </Box>
        </Grid>

        <Grid container justifyContent="flex-end" className={classes.container}>
          <PrimaryButton
            onClick={handleUpdateSettings}
            disabled={
              !(
                bookingFolderSettings?.folderName &&
                bookingFolderSettings?.allowedBookingDays
              )
            }
          >
            <FormattedMessage id="btn.save" />
          </PrimaryButton>
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

FolderSelectorModal.defaultProps = {};

FolderSelectorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFolderRowClick: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  setBookingSettingsChanged: PropTypes.func.isRequired,
};

export default FolderSelectorModal;

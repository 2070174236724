import { handleActions } from "redux-actions";
import { login, logout } from "../../actions";

const defaultState = {
  accessToken: null,
};

export default handleActions(
  {
    [login]: (state, { payload: { accessToken } }) => {
      return {
        ...state,
        accessToken,
      };
    },

    [logout]: state => {
      // not
      return defaultState;
    },
  },
  defaultState
);

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  root: {
    background: "#66c4de",
    borderRadius: 5,
    padding: "10px 20px",
    display: "flex",
  },
  noticeText: { color: "#fff" },
  iconInfo: {
    marginRight: 15,
  },
  iconBackground: {
    border: "2px solid #fff",
    borderRadius: "100%",
    height: 25,
    width: 25,
    color: "#66c4de",
  },
  infoIcon: {
    color: "#fff",
    position: "relative",
    top: "1%",
    left: "-10%",
  },
}));

const Notice = ({ userDetails }) => {
  const { site, roleName, isSupplierInvitation } = userDetails;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <div className={classes.iconInfo}>
        <span className="fa-stack fa-1x">
          <i
            className={clsx([
              "fa fa-circle fa-stack-1x",
              classes.iconBackground,
            ])}
          />
          <i className={clsx(["fa fa-info fa-stack-1x", classes.infoIcon])} />
        </span>
      </div>
      <Typography className={classes.noticeText}>
        <FormattedMessage
          id={
            isSupplierInvitation
              ? "createAccount.supplierNotice"
              : "createAccount.notice"
          }
          values={{
            workspace: site,
            siteRole: roleName,
          }}
        />
      </Typography>
    </Box>
  );
};

Notice.defaultProps = {
  userDetails: {},
};

Notice.propTypes = {
  userDetails: PropTypes.shape({
    site: PropTypes.string,
    roleName: PropTypes.string,
    isSupplierInvitation: PropTypes.bool,
  }),
};

export default Notice;

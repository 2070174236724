import { gql } from '@apollo/client';

const getShoppingCartTotals = gql`
  query GetShoppingCartTotals(
    $orderGroupId: Int!
    $items: [ShoppingCartItem]
    $fileGroups: [ShoppingCartFileGroup]
    $oneStreamGroups: [ShoppingCartOneStreamGroup]
    $deliveryAddressChanged: Boolean!
  ) {
    calcShoppingCartTotals(
      shoppingCart: {
        orderGroupId: $orderGroupId
        recivers: [
          {
            items: $items
            fileGroups: $fileGroups
            oneStreamGroups: $oneStreamGroups
            deliveryAddressChanged: $deliveryAddressChanged
          }
        ]
      }
    ) {
      freight
      orderFee
      sumExclVAT
      sumInclVAT
      vatRates {
        freight
        freightVAT
        itemsExclVAT
        itemsVat
        name
        orderFee
        orderFeeVAT
        totalExclVAT
        totalInclVAT
      }
    }
  }
`;

export default getShoppingCartTotals;

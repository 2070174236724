import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const LIST_WORKSPACES = gql`
  query ListWorkspaces($companyId: Int!) {
    companyAdminContext {
      companyWorkspaces {
        listWorkspaces(companyId: $companyId) {
          canAddNewWorkspace
          errorResult {
            ...ErrorResultFields
          }
          items {
            canEdit
            icon
            iconColor
            name
            siteId
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default LIST_WORKSPACES;

import { gql } from '@apollo/client';
import Project from "../../fragments/Projects/Project";
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPDATE_PROJECT = gql`mutation updateProject($project: ProjectUpdateInput) {
	projects {
		updateProject(project: $project) {
			success
			errorResult {
				...ErrorResultFields
			}
			project {
				...Project
			}
		}
	}
}
${ErrorResultFragment}
${Project}
`;

export default UPDATE_PROJECT;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const LIST_COMPANY_ROLES = gql`
  query GetCompanyRoleRights($companyId: Int!) {
    companyAdminContext {
      companyRoles {
        listCompanyRoles(companyId: $companyId) {
          errorResult {
            ...ErrorResultFields
          }
          success
          companyRoleListItems {
            companyRoleId
            name
          }
        }
      }
    }
  }

  ${ErrorResultFragment}
`;

export default LIST_COMPANY_ROLES;

import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonText } from "../../common";
import {
  toggleDetails,
  setSearchQuery,
  searchBeataWidget,
} from "../../../actions";

const useStyles = makeStyles(theme => ({
  tagsWrap: {
    position: "relative",
    padding: "10px 20px 5px 20px",
    backgroundColor: theme.palette.background.lightGray,
    borderRadius: 6,
    transition: "visibility 0s, opacity 0.15s linear",
  },
  widgetFolderLink: {
    textDecoration: "none",
    textAlign: "left",
    fontSize: 12,
    color: theme.palette.common.link,
    marginRight: 20,
    marginBottom: 10,
    display: "inline-block",
  },
  tagClose: {
    padding: 4,
    color: "#fff",
    position: "absolute",
    right: -5,
    top: -5,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
}));

// eslint-disable-next-line no-shadow
const Tags = ({ tags, toggleDetails, setSearchQuery, searchBeataWidget }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tagsWrap}>
      <IconButton className={classes.tagClose}>
        <CloseIcon />
      </IconButton>
      {tags.map(x => (
        <ButtonText
          key={x.tagId}
          onClick={() => {
            toggleDetails({ name: "Refined" });
            setSearchQuery({ searchQuery: x.tagName });
            searchBeataWidget({ beataWidget: true });
          }}
          label={`#${x.tagName}`}
          btnClass={classes.widgetFolderLink}
        />
      ))}
    </Box>
  );
};

Tags.defaultProps = {
  tags: [],
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.number.isRequired,
      tagName: PropTypes.string.isRequired,
    })
  ),
  toggleDetails: PropTypes.func.isRequired,
  searchBeataWidget: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleDetails,
      setSearchQuery,
      searchBeataWidget,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Tags);

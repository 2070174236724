export const initialToggleHeader = {
  name: false,
  description: false,
  uploaded: false,
  type: false,
  size: false,
  UploadedBy: false,
  UploadedDate: false,
  DownloadedBy: false,
  DownloadedDate: false,
  ChangedBy: false,
  ChangedDate: false,
  changed: false,
};

import { gql } from '@apollo/client';
import FolderFieldsFragment from "../fragments/FolderFields";
import FolderRecursiveFragment from "../fragments/FolderRecursive";
import SiteRoleRightsFragment from "../fragments/SiteRoleRights";

const getDefaultSiteRoleSettings = gql`
  query GetDefaultSiteRoleSettings($siteId: Int!) {
    siteRoles {
      getDefaultSiteRoleSettings(siteId: $siteId) {
        folders {
          ...FolderFields
          ...FolderRecursive
        }
        name
        siteRoleId
        siteRoleRights {
          ...SiteRoleRightsFields
        }
        selectedSiteFolderColorSet {
          defaultColor
          name
          siteColorSetId
        }
        availableSiteFolderColorSets {
          defaultColor
          name
          siteColorSetId
        }
        siteRoleSettings {
          categoryTreeIndentation
        }
        subscriptionLevel
      }
    }
  }

  ${SiteRoleRightsFragment}
  ${FolderFieldsFragment}
  ${FolderRecursiveFragment}
`;

export default getDefaultSiteRoleSettings;

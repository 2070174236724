import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  typography: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const HeaderText = ({ children }) => {
  const classes = useStyles();
  return <Typography className={classes.typography}>{children}</Typography>;
};

HeaderText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderText;

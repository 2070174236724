import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  folderName: {
    fontSize: 12,
    // padding: "6px 5px",
    wordBreak: "break-all",
    marginLeft: 7,
    marginTop: 1,
  },
}));

const GroupName = ({ name }) => {
  const classes = useStyles();

  return (
    <span
      className={classes.folderName}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: name }}
    />
  );
};

GroupName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default GroupName;

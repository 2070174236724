import { useMutation } from "@apollo/client";
import { MOVE_TASK } from "../../graphql/mutations/ProjectEditContext";

// eslint-disable-next-line import/prefer-default-export
export const useMoveTask = () => {
  const [mutation, { data, error, loading }] = useMutation(MOVE_TASK);

  const moveTask = async ({ projectId, taskId, parentTaskId, insertAfterTaskId }) => {
    const variables = {
      moveTaskParams: {
        projectId,
        taskId,
        parentTaskId,
        insertAfterTaskId,
      }
    };

    const res = await mutation({ variables });

    return res?.data?.projects?.moveTask;
  };

  return [moveTask, { data, error, loading }];
};

import React, { useState } from "react";
import { Unstable_Grid2 as Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import CustomTextField from "../TextField";
import CustomSelect from "../CustomSelect";
import TextAreaFull from "../FormControl/TextAreaFull";
import { Chip } from "..";

const useStyles = makeStyles(theme => ({
  btn: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiInputLabel-shrink": {
      fontSize: 13,
      color: "rgba(0,0,0,0.94)",
      backgroundColor: theme.palette.common.white,
    },
  },
  formControlSelect: {
    marginBottom: 5,
    paddingTop: 20,
    "& .MuiInputLabel-shrink": {
      fontSize: 13,
      color: "rgba(0,0,0,0.94)",
      backgroundColor: theme.palette.common.white,
    },
  },
  inputFile: {
    display: "none",
  },
  selectBox: {
    borderRadius: 4,
    border: "1px solid #ddd",
    padding: 10,
    marginTop: 5,
  },
  btnFile: {
    margin: "5px 0px",
    padding: "3px 10px",
    fontSize: 10,
    fontWeight: 400,
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.8,
    },
  },
  resourcesWrap: { marginTop: 5 },
  progress: { marginTop: 8, marginBottom: 2 },
}));

const AccountSupport = ({
  siteOptions,
  setSiteId,
  subject,
  setSubject,
  message,
  setMessage,
  issueTypeOptions,
  setIssueType,
  handleRemoveUploadedFile,
  loadingUpload,
  handleSubmitTicket,
  siteId,
  issueType,
  uploadedFile,
  handleOnSelectUploadFile,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const errorStates = {
    NOT_VALIDATED: 0,
    VALID: 1,
    INVALID: 2,
    isValid: state => {
      return state === errorStates.VALID;
    },
    isInvalid: state => {
      return state === errorStates.INVALID;
    },
  };

  const [workspaceError, setWorkspaceError] = useState(
    errorStates.NOT_VALIDATED
  );
  const [issueTypeError, setIssueTypeError] = useState(
    errorStates.NOT_VALIDATED
  );
  const [subjectError, setSubjectError] = useState(errorStates.NOT_VALIDATED);
  const [messageError, setMessageError] = useState(errorStates.NOT_VALIDATED);

  const validate = {
    workspace: value => (!!value ? errorStates.VALID : errorStates.INVALID),
    issueType: value => (!!value ? errorStates.VALID : errorStates.INVALID),
    subject: value => (!!value ? errorStates.VALID : errorStates.INVALID),
    message: value => (!!value ? errorStates.VALID : errorStates.INVALID),
  };

  const checkWorkspaceError = () => errorStates.isInvalid(workspaceError);
  const checkIssueTypeError = () => errorStates.isInvalid(issueTypeError);
  const checkSubjectError = () => errorStates.isInvalid(subjectError);
  const checkMessageError = () => errorStates.isInvalid(messageError);
  const checkValidUserData = () =>
    errorStates.isValid(workspaceError) &&
    errorStates.isValid(issueTypeError) &&
    errorStates.isValid(subjectError) &&
    errorStates.isValid(messageError);
  const checkDisableSaveButton = () => !checkValidUserData();

  const selectWorkspace = value => {
    var state = validate.workspace(value);
    setWorkspaceError(state);
    setSiteId(value);
  };

  const selectIssueType = value => {
    var state = validate.issueType(value);
    setIssueTypeError(state);
    setIssueType(value);
  };

  const changeSubject = value => {
    var state = validate.subject(value);
    setSubjectError(state);
    setSubject(value);
  };

  const changeMessage = value => {
    var state = validate.message(value);
    setMessageError(state);
    setMessage(value);
  };

  const handleSendClick = () => {
    console.group("** handleSendClick", {
      workspaceError,
      issueTypeError,
      subjectError,
      messageError,
    });
    console.log("** checkValidUserData:", checkValidUserData());
    console.log("** checkDisableSaveButton:", checkDisableSaveButton());
    console.groupEnd();

    if (!checkValidUserData()) {
      return;
    }

    if (!!handleSubmitTicket) {
      handleSubmitTicket();
    }
  };

  return (
    <>
      <Grid container spacing={2} className={classes.formControlSelect}>
        <Grid item xs={12}>
          <CustomSelect
            required
            error={checkWorkspaceError()}
            helperText={
              checkWorkspaceError() && (
                <FormattedMessage id="support.validation.missingWorkspace" />
              )
            }
            inputLabel={<FormattedMessage id="support.selectWhichSite" />}
            options={siteOptions}
            onChange={e => selectWorkspace(e.target.value)}
            value={siteId}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.formControlSelect}>
        <Grid item xs={12}>
          <CustomSelect
            required
            error={checkIssueTypeError()}
            helperText={
              checkIssueTypeError() && (
                <FormattedMessage id="support.validation.missingIssueType" />
              )
            }
            inputLabel={<FormattedMessage id="support.issueType" />}
            options={issueTypeOptions}
            onChange={e => selectIssueType(e.target.value)}
            value={issueType}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.formControl}>
        <Grid item xs={12}>
          <CustomTextField
            required
            error={checkSubjectError()}
            helperText={
              checkSubjectError() && (
                <FormattedMessage id="support.validation.missingSubject" />
              )
            }
            label={<FormattedMessage id="support.issue" />}
            value={subject}
            onChange={e => changeSubject(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.formControl}>
        <Grid item xs={12}>
          <TextAreaFull
            required
            error={checkMessageError()}
            helperText={
              checkMessageError() && (
                <FormattedMessage id="support.validation.missingDescription" />
              )
            }
            placeholder={intl.formatMessage({
              id: "support.describeYourIssue",
            })}
            rows="4"
            rowsMax="4"
            onChange={e => changeMessage(e.target.value)}
            value={message}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.formControl}>
        <Grid item xs={12}>
          <Box className={classes.selectBox}>
            {loadingUpload ? (
              <CircularProgress
                color="secondary"
                size={20}
                className={classes.progress}
              />
            ) : (
              uploadedFile &&
              uploadedFile.length === 0 && (
                <>
                  <input
                    accept="image/*"
                    className={classes.inputFile}
                    disabled={uploadedFile && uploadedFile.length > 0}
                    id="buttonFile"
                    type="file"
                    onChange={handleOnSelectUploadFile}
                  />
                  <label htmlFor="buttonFile">
                    <Button
                      className={classes.btnFile}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <FormattedMessage id="btn.selectFile" />
                    </Button>
                  </label>
                </>
              )
            )}

            {uploadedFile && uploadedFile.length > 0 && (
              <div className={classes.resourcesWrap}>
                {uploadedFile.map(x => (
                  <Chip
                    key={x.name}
                    label={x.name}
                    onDelete={() => handleRemoveUploadedFile(x)}
                    color="light"
                  />
                ))}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Button
          disabled={checkDisableSaveButton()}
          className={classes.btn}
          fullWidth
          onClick={() => handleSendClick()}
        >
          <FormattedMessage id="btn.send" />
          <Icon className={clsx(["fa fa-paper-plane", classes.btnIcon])} />
        </Button>
      </Box>
    </>
  );
};

AccountSupport.defaultProps = {
  uploadedFile: [],
};

AccountSupport.propTypes = {
  siteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  setSiteId: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
  setSubject: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  issueTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  setIssueType: PropTypes.func.isRequired,
  handleRemoveUploadedFile: PropTypes.func.isRequired,
  loadingUpload: PropTypes.bool.isRequired,
  handleSubmitTicket: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  issueType: PropTypes.string.isRequired,
  uploadedFile: PropTypes.arrayOf(PropTypes.string),
  handleOnSelectUploadFile: PropTypes.func.isRequired,
};

export default AccountSupport;

import { gql } from '@apollo/client';

const RFP_FILE_UPLOAD = gql`
  mutation {
    initRequestForProposalFileUpload {
      uploadToken
      uploadUrl
    }
  }
`;

export default RFP_FILE_UPLOAD;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  IconButton,
  Icon,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
  Button,
} from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../../common/TextField";
import TransferSettingsModal from "./TransferSettingsModal";
import LogoEdit from "./LogoEdit";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  switchRoot: {
    marginRight: 30,
  },
  switchLabel: {
    fontSize: 12,
    fontWeight: 600,
  },
  deleteLink: {
    fontSize: 12,
    color: theme.palette.common.red,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  deleteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 2,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
  body: {
    padding: 20,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#F5F5F5",
    height: 2,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 15,
  },
  customSwitch: {
    display: "flex",
    alignItems: "center",
  },
  switchText: {
    fontSize: 12,
    fontWeight: 600,
  },
  iconButtonLink: {
    padding: 4,
    color: theme.palette.common.link,
    marginLeft: 5,
  },
  btnSave: {
    padding: "10px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
}));

const CompanyInfo = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          Company Information
        </Typography>
        <FormControlLabel
          control={<Switch name="checkedB" color="primary" />}
          label="Active"
          classes={{
            root: classes.switchRoot,
            label: classes.switchLabel,
          }}
        />
        <Button className={classes.deleteLink}>
          <Icon className={clsx(["fas fa-trash", classes.deleteIcon])} />
          Delete company
        </Button>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <LogoEdit />
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Company name</Typography>
                <CustomTextField placeholder="Company name" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Corporate</Typography>
                <CustomTextField placeholder="Corporate" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>
                  Company number
                </Typography>
                <CustomTextField placeholder="Company number" />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Typography className={classes.heading}>Contact details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Reference</Typography>
                <CustomTextField placeholder="Reference" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Telephone</Typography>
                <CustomTextField placeholder="Telephone" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Email</Typography>
                <CustomTextField placeholder="Email" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Reference</Typography>
                <CustomTextField placeholder="Reference" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Address 2</Typography>
                <CustomTextField placeholder="Address 2" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Zip</Typography>
                <CustomTextField placeholder="Zip" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>City</Typography>
                <CustomTextField placeholder="City" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className={classes.label}>Country</Typography>
                <CustomTextField placeholder="Country" />
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Typography className={classes.heading}>Modules</Typography>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" size="small" />}
              label="Mailing module"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" size="small" />}
              label="Mailing module"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" size="small" />}
              label={
                <Box className={classes.customSwitch}>
                  <Typography className={classes.switchText}>
                    Projektmodulen
                  </Typography>
                  <IconButton
                    className={classes.iconButtonLink}
                    onClick={handleOpen}
                  >
                    <Icon className={clsx(["fa fa-cog", classes.icon])} />
                  </IconButton>
                </Box>
              }
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.foot} align="right">
        <Button className={classes.btnSave}>
          <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
          <FormattedMessage id="btn.saveChanges" />
        </Button>
      </Box>
      <TransferSettingsModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default CompanyInfo;

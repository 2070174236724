import React, { useState, useEffect } from "react";
import InputBase from "@mui/material/InputBase";

const StringEditor = ({ value, onEditEnd, inputProps, ...props }) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {    
    setInternalValue(value);    
  }, [value]);

  const handleBlur = (e) => {
    onEditEnd?.(e.target.value, "Blur");
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      onEditEnd?.(e.target.value, "KeyEnter");
    }
  };
 

  return (
    <InputBase
      {...props}
      autoFocus
      inputProps={{ className: props.className, ...inputProps }} //disableUnderline: true,
      value={internalValue}
      onChange={(e) => { setInternalValue(e.target.value) }}
      onBlur={handleBlur}
      onKeyDown={handleOnKeyDown}
      className=""
      style={{ ...props.style }} //, whiteSpace: "pre-wrap"
      sx={{
        ...props.sx,        
      }} //Settings for component "p" and "InputBase" should show same way
    //defaultValue={initialValue || " "}
    />
  );
};

export default StringEditor;
import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import SearchAutoComplete from "../../../common/SearchAutoComplete";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: 12,
    },
    "& input": {
      fontSize: 12,
    },
    "& .MuiChip-label": {
      fontSize: 12,
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "3px 4px",
    },
    "& .MuiInputLabel-outlined": {
      top: -7,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      top: 0,
    },
  },
}));

const AutoCompleteField = ({
  loading,
  onChange,
  options,
  placeholder,
  setValue,
  value,
  label,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Box className={classes.root}>
      <SearchAutoComplete
        open={open}
        setOpen={() => setOpen(true)}
        setClose={() => setOpen(false)}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        loading={loading}
        setValue={setValue}
        value={value}
        label={label}
        {...rest}
      />
    </Box>
  );
};

AutoCompleteField.defaultProps = {
  placeholder: null,
  label: null,
};

AutoCompleteField.propTypes = {
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
};

export default AutoCompleteField;

export const fontList = {
  "Abhaya Libre, serif": "Abhaya Libre",
  "Alegreya, serif": "Alegreya",
  "Aleo, serif": "Aleo",
  "Arapey, serif": "Arapey",
  "Asap Condensed, sans-serif": "Asap Condensed",
  "Bebas Neue, cursive": "Bebas Neue",
  "Benne, serif": "Benne",
  "Big Shoulders Stencil Display, cursive": "Big Shoulders Stencil Display",
  "Cabin, sans-serif": "Cabin",
  "Castoro, serif": "Castoro",
  "Concert One, cursive": "Concert One",
  "Courier Prime, monospace": "Courier Prime",
  "Dancing Script, cursive": "Dancing Script",
  "Forum, cursive": "Forum",
  "Lato, sans-serif": "Lato",
  "Lobster Two, cursive": "Lobster Two",
  "Merriweather, serif": "Merriweather",
  "Merriweather Sans, sans-serif": "Merriweather Sans",
  "Monoton, cursive": "Monoton",
  "Montserrat, sans-serif": "Montserrat",
  "Nothing You Could Do, cursive": "Nothing You Could Do",
  "Noto Sans, sans-serif": "Noto Sans",
  "Noto Sans SC, sans-serif": "Noto Sans SC",
  "Noto Serif, serif": "Noto Serif",
  "Noto Serif SC, serif": "Noto Serif SC",
  "Nunito Sans, sans-serif": "Nunito Sans",
  "Open Sans, sans-serif": "Open Sans",
  "Oswald, sans-serif": "Oswald",
  "Playfair Display, serif": "Playfair Display",
  "Poiret One, cursive": "Poiret One",
  "Prompt, sans-serif": "Prompt",
  "PT Sans, sans-serif": "PT Sans",
  "PT Sans Caption, sans-serif": "PT Sans Caption",
  "PT Sans Narrow, sans-serif": "PT Sans Narrow",
  "PT Serif, serif": "PT Serif",
  "Raleway, sans-serif": "Raleway",
  "Roboto, sans-serif": "Roboto",
  "Roboto Condensed, sans-serif": "Roboto Condensed",
  "Roboto Mono, monospace": "Roboto Mono",
  "Roboto Slab, serif": "Roboto Slab",
  "Shadows Into Light, cursive": "Shadows Into Light",
  "Slabo 13px, serif": "Slabo 13px",
  "Slabo 27px, serif": "Slabo 27px",
  "Source Code Pro, monospace": "Source Code Pro",
  "Source Sans Pro, sans-serif": "Source Sans Pro",
  "Special Elite, cursive": "Special Elite",
  "Tangerine, cursive": "Tangerine",
  "Work Sans, sans-serif": "Work Sans",
};

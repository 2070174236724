import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import LanguageSwitcher from "./NavbarLanguage";
import { setLocale, selectWorkspace } from "../../../actions";
import NavMenu from "../../common/NavMenu";
import NavbarHelpButton from "./NavbarHelpButton";
import ToolTipArrow from "../../common/ToolTipArrow";

const useStyles = makeStyles(theme =>
  createStyles({
    linkWrapper: {
      paddingRight: 30,
      paddingLeft: 30,
      paddingTop: 7,
      paddingBottom: 7,
      backgroundColor: theme.palette.component.navbarBackground,
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      alignItems: "center",
      position: "fixed",
      width: "100%",
      top: 60,
      height: 20,
      zIndex: 1,
      boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.09)",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.palette.component.navbarBackground,
      boxSizing: "initial",
    },
    link: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      color: theme.palette.component.navbarColor,
      marginRight: 10,
      padding: "0 8px",
      textTransform: "uppercase",
      fontWeight: 400,
      display: "inline-flex",
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.component.navbarColor,
      },
      "&:focus": {
        color: theme.palette.component.navbarColor,
      },
      sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      },
    },
    icon: {
      fontSize: 16,
    },
  })
);

const Navbar = ({
  companyMenuItems,
  locale,
  // eslint-disable-next-line no-shadow
  selectWorkspace,
  // eslint-disable-next-line no-shadow
  setLocale,
  sites,
  workspace,
}) => {
  const [menus, setMenus] = useState([]);
  const classes = useStyles();
  const intl = useIntl();
  const homeMenu = menus.find(x => x.page === "OVER_VIEW_DASHBOARD");

  useEffect(() => {
    if (sites && workspace && workspace.id) {
      const selectedSite = sites.find(x => x.id === workspace.id);
      if (selectedSite) {
        const { menuItems } = selectedSite;
        setMenus(menuItems);
      }
    } else {
      setMenus(companyMenuItems);
    }
  }, [sites, workspace]);

  const stringExists = !!intl.messages[`navMenu.${homeMenu && homeMenu.name}`];

  return (
    <Box pl={3} className={classes.linkWrapper}>
      <ToolTipArrow
        title={
          homeMenu && stringExists ? (
            <FormattedMessage id={`navMenu.${homeMenu.name}`} />
          ) : (
            <FormattedMessage id="navMenu.overview" />
          )
        }
        placement="top"
      >
        <Link
          to="/"
          className={classes.link}
          onClick={() => selectWorkspace({ id: null, name: null })}
        >
          <i className={clsx(["fas fa-home", classes.icon])} />
        </Link>
      </ToolTipArrow>
      {menus.map((menu, index) => {
        if (menu.page === "OVER_VIEW_DASHBOARD") {
          return null;
        }
        // We use index as one of the composition for the key,
        // This will prevent react app crashes if backend data will return duplicate menu items.
        // eslint-disable-next-line react/no-array-index-key
        return <NavMenu menu={menu} key={`${menu.name}-${index}`} />;
      })}
      <NavbarHelpButton />
      <LanguageSwitcher setLocale={setLocale} locale={locale} />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    locale: state.ui.locale,
    sites: state.api.currentViewer.viewer.sites,
    workspace: state.ui.toggleWorkspaces.workspace,
    companyMenuItems: state.api.currentViewer.viewer.menuItems,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLocale,
      selectWorkspace,
    },
    dispatch
  );
};

Navbar.defaultProps = {
  workspace: { id: null, name: null },
};

Navbar.propTypes = {
  selectWorkspace: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          page: PropTypes.string.isRequired,
          childMenu: PropTypes.array,
        })
      ),
    }).isRequired
  ).isRequired,
  setLocale: PropTypes.func.isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  companyMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      page: PropTypes.string.isRequired,
      childMenu: PropTypes.array,
    })
  ).isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Navbar);

import React from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@mui/material";
import Alert from "./Alert";

const ErrorMessageSnackBar = ({
  open,
  handleClose,
  message,
  vertical,
  horizontal,
  type,
  autoHideDuration,
}) => {
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={autoHideDuration}
    ><div>
      <Alert onClose={handleClose} type={type}>
        {message}
      </Alert></div>
    </Snackbar>
  );
};

ErrorMessageSnackBar.defaultProps = {
  open: null,
  handleClose: null,
  message: null,
  vertical: null,
  horizontal: null,
  type: "",
};

ErrorMessageSnackBar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  type: PropTypes.string,
};

export default ErrorMessageSnackBar;

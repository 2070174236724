import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import {
  Icon,
  Button,
  IconButton,
  Card as Box,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../hooks";
import ProductModal from "../../../ProductsMainView/ProductModal";
import ProductOrder from "../../../ProductsMainView/ProductOrder";
import ProductDescription from "./ProductDescription";
import {
  FROM_VALUES_LIST,
  DYNAMIC_PRODUCT_TYPE,
} from "../../../../constant/types";
import {
  SAVE_IMAGE_BASKET,
  INITIALIZE_PRODUCT_DOWNLOAD,
} from "../../../../graphql/mutations";
import {
  imageBasketAddItem,
  setSearchFolderId,
  setSearchFolderName,
} from "../../../../actions";
import { getSelectedSiteOrderGroupId } from "../../../../helpers/selectors";
import ImageWithFallback from "../../../common/ImageWithFallback";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  wrapper: {
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    borderRadius: 0,
    "&:hover": {
      boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    },
  },
  wrapperEmpty: {
    marginBottom: theme.spacing(2),
    padding: "40px 0",
    boxShadow: "none",
    borderRadius: 0,
    textAlign: "center",
    backgroundColor: theme.palette.common.white,
  },
  emptyIcon: {
    verticalAlign: "middle",
    marginRight: 20,
    width: "60px !important",
    height: "60px !important",
    color: "#B8B8B8",
  },
  cover: {
    width: "10%",
    paddingRight: 15,
    boxShadow: "none",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingRight: 0,
      marginBottom: 20,
    },
    "& img": {
      border: "1px solid #dedede",
      boxShadow: "0 1px 5px #efefef",
    },
    "& img:hover": {
      boxShadow: "none",
    },
  },
  showHover: {
    display: "none",
    boxShadow: "none",
    verticalAlign: "bottom",
    color: theme.palette.component.productBoxColor,
  },
  showHoverActions: {
    display: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    marginTop: 10,
  },
  prod: {
    display: "flex",
    boxShadow: "none",
    borderRadius: 0,
    padding: 15,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",
    },
    "&:hover": {
      "& $showHover": {
        display: "inline-block",
      },
      "& $showHoverActions": {
        display: "block",
      },
    },
  },
  prodDesc: {
    "&:hover": {
      cursor: "pointer",
    },
    "& div": {
      display: "inline-block",
    },
    color: theme.palette.component.productBoxColor,
    "& > span": {
      display: "none",
      color: theme.palette.common.link,
      fontSize: 11,
      marginBottom: 5,
    },
    "&:hover > span": {
      display: "block",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    color: theme.palette.component.productBoxColor,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 15,
    borderRadius: 0,
    boxShadow: "none",
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 0,
      marginBottom: 20,
    },
  },
  prodName: {
    fontSize: 14,
    fontWeight: 600,
  },
  prodInfo: {
    fontSize: 12,
    display: "block",
    "& span": {
      fontWeight: 600,
    },
  },
  popper: {
    position: "fixed !important",
    top: "110px !important",
    transform: "none !important",
    width: "39.5%",
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    maxWidth: "unset",
    minHeight: "87vh",
    maxHeight: "87vh",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "70%",
      objectFit: "contain",
      maxHeight: "75vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  popperRight: {
    position: "fixed !important",
    top: "165px !important",
    right: "60px !important",
    left: "auto !important",
    transform: "none !important",
    width: "67.5%",
  },
  tooltipRight: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    maxWidth: "unset",
    minHeight: "80vh",
    maxHeight: "80vh",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "70%",
      objectFit: "contain",
      maxHeight: "75vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  actionBtn: {
    padding: "4px 6px",
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 14,
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
    "&:hover": {
      "& .MuiIcon-root": {
        color: theme.palette.primary.alt,
        verticalAlign: "middle",
      },
    },
  },
  disableToolTip: {
    display: "none",
  },
  breadLink: {
    color: theme.palette.common.link,
    fontSize: 12,
    textDecoration: "none",
    padding: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const ProductRowItem = ({ product, handleOpenProductEditModal }) => {
  const { productType, isEditableFromSearchResult } = product;
  const download =
    product && product.userPermissions && product.userPermissions.download
      ? product.userPermissions.download
      : false;

  const order =
    product && product.userPermissions && product.userPermissions.order
      ? product.userPermissions.order
      : false;

  const share =
    product && product.userPermissions && product.userPermissions.share
      ? product.userPermissions.share
      : false;

  const breadcrumbSite =
    product && product.breadcrumb && product.breadcrumb.siteName
      ? product && product.breadcrumb && product.breadcrumb.siteName
      : "";

  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const imageBasketItems = useSelector(
    state => state.api.imageBasket.imageBasketItems
  );
  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState();
  const [hoverImage, setHoverImage] = useState(product.thumbnailImageUri);
  const [initializeDownload] = useMutation(INITIALIZE_PRODUCT_DOWNLOAD);

  const handleBreadcrumbs = (folderId, name) => {
    dispatch(setSearchFolderId({ id: folderId }));
    dispatch(setSearchFolderName({ name: name }));
  };

  const renderBreadcrumbFolders = () => {
    const breadcrumbFolders =
      product &&
      product.breadcrumb &&
      product.breadcrumb.folders &&
      product.breadcrumb.folders.length > 0
        ? product.breadcrumb.folders
        : [];

    const breadcrumbs = breadcrumbFolders.map((x, idx) => {
      return (
        <Button
          onClick={() => handleBreadcrumbs(x.folderId, x.name)}
          className={classes.breadLink}
        >
          {idx !== breadcrumbFolders.length && " / "} {x.name}
        </Button>
      );
    });

    return (
      <div className={classes.breadLink}>
        {breadcrumbSite} {breadcrumbs}
      </div>
    );
  };

  const handleDownloadOnClick = async () => {
    try {
      const results = await initializeDownload({
        variables: {
          productId: product.id,
        },
      });
      if (results && results.data && results.data.initProductDownload) {
        const downloadUrl = results.data.initProductDownload;
        saveAs(downloadUrl);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading product ${e}`);
    }
  };

  const isImage =
    product && product.userPermissions && product.userPermissions.addToImageCart
      ? product.userPermissions.addToImageCart
      : false;

  const [saveImageBasket] = useMutation(SAVE_IMAGE_BASKET, {
    onCompleted: resultData => {
      if (resultData && resultData.addToImageBank) {
        alert.success(<FormattedMessage id="product.addedToImageBasket" />);
        const {
          imageName,
          itemId,
          productId,
          settings,
        } = resultData.addToImageBank;
        dispatch(
          imageBasketAddItem({
            item: { imageName, itemId, productId, settings },
          })
        );
      }
    },
  });

  const handleAddImageBasket = () => {
    const isDuplicate = imageBasketItems.some(x => {
      return x.productId === product.id;
    });

    if (imageBasketItems) {
      if (isDuplicate) {
        snackbar.workspaceError(
          <FormattedMessage id="product.duplicateItem" />
        );
      } else {
        saveImageBasket({
          variables: {
            orderGroupId,
            productId: product.id,
          },
        });
      }
    } else {
      snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
    }
  };

  const handleDynamicProductOrderBtn = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (
      product &&
      product.quantities &&
      product.quantities.valuesList &&
      product.quantities.selectionType === FROM_VALUES_LIST
    ) {
      const { valuesList } = product.quantities;
      if (valuesList && valuesList.length > 0) {
        setQuantity(valuesList[0]);
      }
    }
  }, [product]);

  const renderProductImage = () => {
    // Search result (product view)
    const image = (
      <ImageWithFallback
        alt={product.name}
        imageUrl={product.thumbnailImageUri}
        onClick={() => setOpen(true)}
      />
    );

    if (productType === DYNAMIC_PRODUCT_TYPE) {
      return image;
    }

    return (
      <>
        <Tooltip
          arrow
          classes={{
            tooltip: classes.tooltip
          }}
          disabled
          placement="right"
          title={
            <ImageWithFallback
              alt={product.name}
              imageUrl={hoverImage}
              onLoad={() => setHoverImage(product.productImageUri)}
            />
          }
        >
          {image}
        </Tooltip>
      </>
    );
  };

  return (
    <Box className={classes.wrapper}>
      {open && (
        <ProductModal
          open={open}
          product={product}
          handleClose={() => setOpen(false)}
          quantity={quantity}
          setQuantity={setQuantity}
          isImage={isImage}
          handleAddImageBasket={handleAddImageBasket}
        />
      )}
      <Box className={classes.prod}>
        <Box className={classes.cover}>{renderProductImage()}</Box>

        <Box className={classes.content}>
          {renderBreadcrumbFolders()}
          <Typography className={classes.prodName}>{product.name}</Typography>
          <Box className={classes.prodDesc} elevation={0}>
            <ProductDescription description={product.description} />
          </Box>
          <Typography className={classes.prodInfo}>
            <span>
              <FormattedMessage id="product.artno" />:{" "}
            </span>
            {product.articleNo}
          </Typography>
          {product.productDisplaySettings.showFormat && (
            <Typography className={classes.prodInfo}>
              <span>
                <FormattedMessage id="product.format" />:{" "}
              </span>
              {product.format}
            </Typography>
          )}
          {product.productDisplaySettings.showNumPages && (
            <Typography className={classes.prodInfo}>
              <span>
                <FormattedMessage id="product.numpages" />:{" "}
              </span>
              {product.numPages}
            </Typography>
          )}
          {product.productManager && (
            <Typography className={classes.prodInfo}>
              <span>
                <FormattedMessage id="product.productManager" />:{" "}
              </span>
              {product.productManager}
            </Typography>
          )}
          {product.productDisplaySettings.showStockBalance && product.stock && (
            <Typography className={classes.prodInfo}>
              <span>
                <FormattedMessage id="product.stockBalance" />:{" "}
              </span>
              {product.stock.stockBalance} <FormattedMessage id="common.pcs" />
            </Typography>
          )}
          {product.stock && (
            <Typography className={classes.prodInfo}>
              <span>
                <FormattedMessage id="product.refillThreshold" />:{" "}
              </span>
              {product.stock.refillThreshold}{" "}
              <FormattedMessage id="common.pcs" />
            </Typography>
          )}
          {product.productResponsible && (
            <Typography className={classes.prodInfo}>
              <span>
                <FormattedMessage id="cooperation.productResponsible" />:{" "}
              </span>
              {product.productResponsible}
            </Typography>
          )}

          <Box className={classes.showHoverActions}>
            {share && (
              <Tooltip
                title={<FormattedMessage id="btn.share" />}
                placement="bottom"
              >
                <IconButton className={classes.actionBtn}>
                  <Icon className="fa fa-share-alt" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <ProductOrder
          download={download}
          handleAddImageBasket={handleAddImageBasket}
          handleDownloadOnClick={handleDownloadOnClick}
          handleDynamicProductOrderBtn={handleDynamicProductOrderBtn}
          handleOpenProductEditModal={handleOpenProductEditModal}
          isImage={isImage}
          order={order}
          product={product}
          quantity={quantity}
          setQuantity={setQuantity}
          breadcrumbSite={breadcrumbSite}
        />
      </Box>
    </Box>
  );
};

ProductRowItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    productManager: PropTypes.string,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string,
      valuesList: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    format: PropTypes.string,
    numPages: PropTypes.string,
    thumbnailImageUri: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    articleNo: PropTypes.string,
    stock: PropTypes.shape({
      stockBalance: PropTypes.number,
      refillThreshold: PropTypes.number,
    }).isRequired,
    productDisplaySettings: PropTypes.shape({
      showPrice: PropTypes.bool,
      showFormat: PropTypes.bool,
      showNumPages: PropTypes.bool,
      showStockBalance: PropTypes.bool,
    }).isRequired,
    productImageUri: PropTypes.string.isRequired,
    productType: PropTypes.string,
    userPermissions: PropTypes.shape({
      order: PropTypes.bool,
      share: PropTypes.bool,
      download: PropTypes.bool,
      addToImageCart: PropTypes.bool,
    }),
    breadcrumb: PropTypes.shape({
      siteName: PropTypes.string,
      folders: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
};

export default ProductRowItem;

import { gql } from '@apollo/client';

const getSupportUploadToken = gql`
  {
    supportTicket {
      requestUploadToken
    }
  }
`;

export default getSupportUploadToken;

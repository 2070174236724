import axios from "axios";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { GET_FOLDER_FILE_UPLOAD_URL } from "../../../graphql/mutations";
import { getSelectedFolder } from "../../../helpers/fileViewSelectors";
import logger from "../../../helpers/logger";
import { useSnackbar } from "../../../hooks";
import { Dropzone } from "../../common";

const DropzoneSection = ({
  addSelectedResources,
  folderId,
  handleError,
  refetch,
  setRefreshDropzone,
}) => {
  const alert = useAlert();
  const snackbar = useSnackbar();

  const { locked: lockedFolder } = useSelector(getSelectedFolder);

  const [getFolderFileUploadURL] = useMutation(GET_FOLDER_FILE_UPLOAD_URL, {
    variables: { folderId },
  });

  const getFileUploadURL = async () => {
    try {
      const results = await getFolderFileUploadURL();
      return results;
    } catch (error) {
      return null;
    }
  };

  const uploadFiles = async ({ files }) => {
    if (lockedFolder) {
      snackbar.workspaceError(<FormattedMessage id="context.folderIsLocked" />);
      setRefreshDropzone(true);
      return;
    }
    try {
      const uploadedFiles = [];
      const promises = await files.map(async file => {
        const formData = new FormData();
        formData.append("file", file);

        const urlData = await getFileUploadURL();

        if (urlData && urlData.data && urlData.data.initFileUpload) {
          const { success, url: uploadURL } = urlData.data.initFileUpload;
          if (success) {
            return axios.post(`${uploadURL}`, formData).then(response => {
              if (response && response.data && response.data.id) {
                uploadedFiles.push({ id: response.data.id, tags: [] });
              }
              // setRefreshDropzone(true);
            });
            // eslint-disable-next-line no-else-return
          } else {
            const { errorResult } = urlData.data.initFileUpload;
            handleError("", errorResult);
          }
        }

        setRefreshDropzone(true);
        // If failure to get upload url.
        logger.error(`Error in uploading file. Unable to get upload`);
      });
      axios
        .all(promises)
        .then(() => {
          setRefreshDropzone(true);
          if (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
            addSelectedResources(uploadedFiles);
            alert.success(<FormattedMessage id="fileupload.success" />);
            refetch();
          } else {
            snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
          }
        })
        .catch(err => {
          setRefreshDropzone(true);
          logger.error(
            `Error in uploading file. Response Fail. ${JSON.stringify(err)}`
          );
          snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
        });
    } catch (error) {
      setRefreshDropzone(true);
      snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
      logger.error(`Error in uploading file. Unable to send ${error}`);
    }
  };

  return <Dropzone uploadFiles={uploadFiles} />;
};

DropzoneSection.defaultProps = {
  folderId: null,
};

DropzoneSection.propTypes = {
  addSelectedResources: PropTypes.func.isRequired,
  folderId: PropTypes.number,
  handleError: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  setRefreshDropzone: PropTypes.func.isRequired,
};

export default DropzoneSection;

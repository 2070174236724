import { gql } from '@apollo/client';
import ProjectCreator from "./ProjectCreator";
import ResourceAssignment from "./ResourceAssignment";
import TaskValue from "./TaskValue";
import TimeReport from "./TimeReport";
import TaskComment from "./TaskComment";

const Task = gql`
  fragment Task on TaskDTO {
    #__typename

    id
    projectId
    parentTaskId

    startDate
    endDate
  
    name
  
    timestamp
    description

    createdBy { ...ProjectCreator }

    linkedFolderId
  
    projectTaskNo
    taskWorkflowId
    taskPropertyGroupId
    reportedTime
    reportedTimeLog { ...TimeReportLog }
  	values { ...TaskValue }
  	assignments { ...ResourceAssignment }
    comments { ...TaskComment}
  }
  ${ProjectCreator}
  ${ResourceAssignment}
  ${TaskValue}
  ${TimeReport}
  ${TaskComment}
`;

export default Task;

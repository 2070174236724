import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import ExpansionDefault from "../../common/ExpansionDefault";
import MonitoringPanels from "./MonitoringPanels";

const RollerPanel = ({ roles, setRoles }) => {
  const intl = useIntl();

  return (
    <ExpansionDefault
      label={<FormattedMessage id="monitoring.roles" />}
      subLabel={`(${intl.formatMessage({
        id: "monitoring.clickToSelect",
      })})`}
      panelContent="rollerContent"
      panelHeader="rollerHeader"
      defaultExpanded
    >
      <Grid container spacing={2}>
        <Grid item md={4}>
          <MonitoringPanels
            label={intl.formatMessage({
              id: "monitoring.rolesAreAdded",
            })}
            title={intl.formatMessage({
              id: "monitoring.additions",
            })}
            span={intl.formatMessage({
              id: "monitoring.newRole",
            })}
            value={roles.create}
            onChange={() =>
              setRoles(prevRoles => {
                return { ...prevRoles, create: !roles.create };
              })
            }
          />
        </Grid>
        <Grid item md={4}>
          <MonitoringPanels
            title={intl.formatMessage({
              id: "monitoring.change",
            })}
            span={intl.formatMessage({
              id: "monitoring.update",
            })}
            label={intl.formatMessage({
              id: "monitoring.rolesAreChanging",
            })}
            onChange={() =>
              setRoles(prevRoles => {
                return { ...prevRoles, update: !roles.update };
              })
            }
            value={roles.update}
          />
        </Grid>
        <Grid item md={4}>
          <MonitoringPanels
            title={intl.formatMessage({
              id: "monitoring.deleteDeletion",
            })}
            span={intl.formatMessage({
              id: "monitoring.delete",
            })}
            label={intl.formatMessage({
              id: "monitoring.rolesAreBeingDeleted",
            })}
            onChange={() =>
              setRoles(prevRoles => {
                return { ...prevRoles, delete: !roles.delete };
              })
            }
            value={roles.delete}
          />
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

RollerPanel.propTypes = {
  roles: PropTypes.shape({
    create: PropTypes.bool,
    delete: PropTypes.bool,
    update: PropTypes.bool,
  }).isRequired,
  setRoles: PropTypes.func.isRequired,
};

export default RollerPanel;

import { gql } from '@apollo/client';

const TaskCommenter = gql`
	  fragment TaskCommenter on TaskCommenter {
    #__typename # TaskCommenter:ProjectUser

    userId
    username
    firstname
    lastname
    avatar
  }
`;

export default TaskCommenter;

import React from "react";
import {
  TableRow,
  TableCell,
  Tooltip,
  Box,
  Icon,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import IconLink from "../../common/FormControl/IconLink";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import CustomSelect from "../../common/CustomSelect";
import { subscriptionLevels } from "../../../constant";

const useStyles = makeStyles(theme => ({
  td: {
    padding: "4px 0",
    fontSize: 12,
  },
  childTd: {
    fontSize: 12,
    padding: "1px 3px 1px 9px",
  },
  tdCb: {
    padding: "4px 8px",
    paddingRight: "0 !important",
    minWidth: 16,
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    "& .MuiSelect-select": {
      padding: "4px 6px",
      paddingRight: 16,
      fontSize: 11,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-iconOutlined": {
      right: 1,
      top: 5,
    },
  },
  badgeCircle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 5,
  },
  badgeFree: {
    backgroundColor: "#fff",
    color: "#464646",
    border: "1px solid #989898",
  },
  badgeMini: {
    backgroundColor: "#ececec",
    color: "#00ff00",
    border: "1px solid #989898",
  },
  badgeStandard: {
    backgroundColor: "#E9F4FB",
    color: "#fff",
    border: "1px solid #2491d9",
  },
  badgeBusiness: {
    backgroundColor: "#f6fff4",
    color: "#34bf1b",
    border: "1px solid #34bf1b",
  },
  badgePro: {
    backgroundColor: "#f7f7ca",
    color: "#2491d9",
    border: "1px solid #989819",
  },
  badgePremium: {
    backgroundColor: "#FEF2E9",
    color: "#f58123",
    border: "1px solid #f58123",
  },
  editButton: {
    color: theme.palette.common.link,
    padding: "4px !important",
    marginLeft: 4,
  },
  editIcon: {
    fontSize: 10,
  },
  withChild: {
    "& td": {
      borderBottom: "none",
    },
  },
  childRow: {
    "& td": {
      borderBottom: "none",
    },
  },
  inputLabelCustomClass: {
    fontSize: 12,
  },
  selectContainer: {
    alignItems: "center",
    display: "flex",
    marginLeft: 13,
    "& .MuiInputLabel-outlined": {
      transform: "translate(12px, 12px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -3px) scale(0.75)!important",
    },
  },
}));

const ManageRolesTableRow = ({
  checkbox,
  child,
  disabled,
  editIcon,
  hideLabel,
  label,
  name,
  onChange,
  onClick,
  options,
  roleSubscriptionLevel,
  siteRoleValues,
  tooltip,
  toolTipInfo,
  tooltipTitle,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  let badgeClass = "";

  if (tooltipTitle === "Free") {
    badgeClass = classes.badgeFree;
  }

  if (tooltipTitle === "Standard") {
    badgeClass = classes.badgeStandard;
  }

  if (tooltipTitle === "Mini") {
    badgeClass = classes.badgeMini;
  }

  if (tooltipTitle === "Business") {
    badgeClass = classes.badgeBusiness;
  }

  if (tooltipTitle === "Pro") {
    badgeClass = classes.badgePro;
  }

  if (tooltipTitle === "Premium") {
    badgeClass = classes.badgePremium;
  }

  const showChild = child && child.length > 0;

  const renderBadgeColor = n => {
    switch (subscriptionLevels[roleSubscriptionLevel[n]]) {
      case 6:
        return classes.badgePremium;
      case 5:
        return classes.badgePro;
      case 4:
        return classes.badgeBusiness;
      case 3:
        return classes.badgeStandard;
      case 2:
        return classes.badgeMini;
      case 1:
        return classes.badgeFree;
      default:
        return classes.badgeFree;
    }
  };

  const renderTitle = n => {
    switch (subscriptionLevels[roleSubscriptionLevel[n]]) {
      case 6:
        return "Premium";
      case 5:
        return "Pro";
      case 4:
        return "Business";
      case 3:
        return "Standard";
      case 2:
        return "Mini";
      case 1:
        return "Free";
      default:
        return "Free";
    }
  };

  var customSelectDefaultOption = null;
  var customSelectValue = siteRoleValues[name];

  if (name === "folderColorScheme") {
    customSelectValue = customSelectValue ?? 0;
    customSelectDefaultOption = intl.formatMessage({
      id: "siteRoles.noColorScheme",
    });
  }

  return (
    <>
      <TableRow className={showChild && classes.withChild}>
        {!hideLabel && (
          <TableCell className={classes.td}>
            <span style={{ display: "flex" }}>
              {tooltip && (
                <Tooltip title={tooltipTitle} placement="top">
                  <Box className={clsx([classes.badgeCircle, badgeClass])} />
                </Tooltip>
              )}

              {roleSubscriptionLevel[name] && (
                <span style={{ display: 1 }}>
                  <Tooltip title={renderTitle(name)} placement="top">
                    <Box
                      className={clsx([
                        classes.badgeCircle,
                        renderBadgeColor(name),
                      ])}
                    />
                  </Tooltip>
                </span>
              )}
              <span style={{ flex: 1 }}>{label}</span>
            </span>
          </TableCell>
        )}
        {checkbox && (
          <TableCell className={classes.tdCb} align="right">
            <CheckboxDefault
              disabled={disabled}
              name={name}
              onChange={onChange}
              value={siteRoleValues[name]}
            />
          </TableCell>
        )}

        {!checkbox && (
          <TableCell className={classes.td}>
            <div className={classes.selectContainer}>
              {Array.isArray(options) &&
                (options.length > 1 || !!customSelectDefaultOption) && (
                  <CustomSelect
                    defaultOption={customSelectDefaultOption}
                    disabled={disabled}
                    inputLabel={label}
                    inputLabelCustomClass={classes.inputLabelCustomClass}
                    name={name}
                    onChange={onChange}
                    options={options}
                    value={customSelectValue}
                  />
                )}
            </div>
          </TableCell>
        )}
        {editIcon && !disabled && (
          <TableCell className={classes.tdCb} align="right">
            <IconButton
              className={classes.editButton}
              disabled={disabled}
              onClick={onClick}
            >
              <Icon className={clsx(["fa fa-pencil-alt", classes.editIcon])} />
            </IconButton>
          </TableCell>
        )}
        {!checkbox && !editIcon && (
          <TableCell className={classes.tdCb} align="right" />
        )}

        {toolTipInfo && (
          <TableCell className={classes.tdCb} align="right">
            <IconLink
              title={<FormattedMessage id={`siteRoleInfo.${name}`} />}
              icon="nc-icon nc-alert-circle-i"
              customClass={classes.infoIcon}
            />
          </TableCell>
        )}
      </TableRow>

      {showChild && (
        <>
          {child.map(x => {
            return (
              <TableRow className={classes.childRow}>
                <TableCell className={classes.childTd}>
                  {/* {roleSubscriptionLevel[x.name] && (
                    <Tooltip title={renderTitle(x.name)} placement="top">
                      <Box
                        className={clsx([
                          classes.badgeCircle,
                          renderBadgeColor(x.name),
                        ])}
                      />
                    </Tooltip>
                  )}
                  {x.label} */}
                  <span style={{ display: "flex" }}>
                    {roleSubscriptionLevel[x.name] && (
                      <span style={{ display: 1 }}>
                        <Tooltip title={renderTitle(x.name)} placement="top">
                          <Box
                            className={clsx([
                              classes.badgeCircle,
                              renderBadgeColor(x.name),
                            ])}
                          />
                        </Tooltip>
                      </span>
                    )}
                    <span style={{ flex: 1 }}> {x.label}</span>
                  </span>
                </TableCell>
                <TableCell className={classes.tdCb} align="right">
                  {x.checkbox && (
                    <CheckboxDefault
                      disabled={disabled}
                      name={x.name}
                      onChange={onChange}
                      value={siteRoleValues[x.name]}
                    />
                  )}
                </TableCell>
                {!x.checkbox && (
                  <TableCell className={classes.tdCb} align="right" />
                )}
                {x.toolTipInfo && (
                  <TableCell className={classes.tdCb} align="right">
                    <IconLink
                      customClass={classes.infoIcon}
                      icon="nc-icon nc-alert-circle-i"
                      title={<FormattedMessage id={`siteRoleInfo.${x.name}`} />}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </>
      )}
    </>
  );
};

ManageRolesTableRow.defaultProps = {
  checkbox: false,
  disabled: false,
  editIcon: null,
  label: null,
  name: null,
  onChange: null,
  onClick: null,
  options: null,
  siteRoleValues: {},
  tooltip: false,
  tooltipTitle: null,
  value: null,
};

ManageRolesTableRow.propTypes = {
  checkbox: PropTypes.bool,
  child: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  editIcon: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  siteRoleValues: PropTypes.shape({}),
  tooltip: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

export default ManageRolesTableRow;

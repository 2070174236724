import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Typography } from "@mui/material";
import AdminImage from "../../assets/img/admin-home-img.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { keyframes } from '@mui/system';
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f3f3f3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    textAlign: "center",
  },
  heading: {
    fontSize: 28,
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 10,
    color: "#888",
  },
  subHeading: {
    fontSize: 20,
    lineHeight: "32px",
    color: "#888",
    [theme.breakpoints.up('sm')]: {
      width: "550px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    marginBottom: 6,
  },
  smallBody: {
    fontSize: 16,
    lineHeight: "26px",
    color: "#666",
  },
  arrowInline: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    fontSize: 16,
    fontWeight: 600,
  },
  emphasisB: {
    fontWeight: 600,
  },
  dividerLine: {
    color: "#888888",
    marginBottom: "10px",
    [theme.breakpoints.up('sm')]: {
      width: "260px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "65%",
    },
  },
}));

const blink = keyframes`
  0% { 
    opacity: 1;
    transform: rotate(2deg);
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
  }
  50% {
    opacity: 1;
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.24));
    transform: translateX(-8px);
  }
  100% {
    opacity: 1;
    transform: rotate(-2deg);
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15));
  }
`;

const AdminSidebar = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {/*<img src={AdminImage} alt="Administration Home" />*/}
      <ArrowBackIcon sx={{ animation: `${blink} 3s infinite alternate`, color: "#FFFFFF", fontSize: "180px", filter: 'drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15))' }} />
      <Typography className={classes.heading}>
        <FormattedMessage id="admin.startpage.welcome" />
      </Typography>
      <Divider className={classes.dividerLine} />
      <Typography className={classes.subHeading}>
        <FormattedMessage id="admin.startpage.makeitsimpleforyou" />
        <Box component="span" className={classes.emphasisB}>
          &nbsp;<FormattedMessage id="admin.startpage.whatyoudofirst" />
        </Box>
      </Typography>
      <Typography className={classes.smallBody}><ArrowBackIcon className={classes.arrowInline} /> <FormattedMessage id="admin.startpage.choosefrommenu" /></Typography>
    </Box>
  );
};

export default AdminSidebar;

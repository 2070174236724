import React from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import PublicNavbarMobileDrawer from "../PublicNavbar/PublicNavbarDrawer";
import PublicMobileMenu from "../PublicNavbar/PublicNavbarMobile";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    boxShadow: "unset",
  },
  header: {
    backgroundColor: theme.palette.component.headerBackground,
    color: theme.palette.component.headerColor,
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
    },
    minHeight: 60,
    width: "100%",
  },
  toolbar: {
    minHeight: 53,
    padding: "3px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
      flexWrap: "wrap",
      minHeight: 40,
    },
  },
  navbrandWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navbrand: {
    objectFit: "contain",
    maxWidth: 160,
    maxHeight: 58,
    borderRadius: 0,
    marginRight: "1em",
    [theme.breakpoints.down("sm")]: {
      width: 100,
      backgroundSize: "contain",
      marginRight: 8,
    },
  },
  notifyWrap: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "16px",
      paddingRight: "5px",
    },
  },
  notification: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  notify: {
    position: "relative",
    cursor: "pointer",
  },
  notifyIcon: {
    fontSize: 16,
    cursor: "pointer",
  },
  badge: {
    backgroundColor: theme.palette.component.badgeBackground,
    position: "absolute",
    textAlign: "center",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    borderRadius: "50%",
    padding: "2px 7px",
    fontWeight: "700",
    left: 10,
    bottom: 9,
    cursor: "pointer",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hideProfileOnMobile: {
    [theme.breakpoints.down("md")]: {
      // marginLeft: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const PublicHeader = ({
  // eslint-disable-next-line no-shadow
  companyLogo,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="relative" elevation={0} className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionMobile}>
            <PublicNavbarMobileDrawer />
          </div>
          <Link href="/" className={classes.navbrandWrap}>
            <img src={companyLogo} alt="Logo" className={classes.navbrand} />
          </Link>
          <PublicMobileMenu />
        </Toolbar>
      </AppBar>
    </div>
  );
};

PublicHeader.propTypes = {
  companyLogo: PropTypes.string.isRequired,
};

export default PublicHeader;

import { gql } from '@apollo/client';
import AddressFragment from "./Address";

const MonitoringOrderRecipientFragment = gql`
  fragment MonitoringOrderRecipientFragment on MonitoringOrderRecipient {
    costPlace
    costPlace2
    costPlace3
    deliveryAddress {
      ...AddressFields
    }
    invoiceAddress {
      ...AddressFields
    }
    message
    ordererAddress {
      ...AddressFields
    }
    userId
  }
  ${AddressFragment}
`;

export default MonitoringOrderRecipientFragment;

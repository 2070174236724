import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import BookingRowItem from "./BookingRowItem";

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 800,
    fontSize: 16,
  },
}));

const BookableLifts = ({ availableLifts, handleBooking }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.label}>
          <FormattedMessage id="booking.confirmBooking" />
        </Typography>
      </Grid>
      {Array.isArray(availableLifts) && availableLifts.length > 0 && (
        <>
          {availableLifts.map(lift => {
            return (
              <Grid item xs={12} key={lift.id}>
                <BookingRowItem lift={lift} handleBooking={handleBooking} />
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};

BookableLifts.defaultProps = {
  availableLifts: [],
};

BookableLifts.propTypes = {
  availableLifts: PropTypes.arrayOf(PropTypes.shape({})),
  handleBooking: PropTypes.func.isRequired,
};

export default BookableLifts;

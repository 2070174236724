import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  iconProfile: ({ size }) => ({
    display: "inline",
    fontSize: size,
    minWidth: `calc(${size} * 1.2)`,
  }),
}));

const WorkspaceIcon = ({ icon, color, size }) => {
  const classes = useStyles({ size });

  return (
    <Icon
      className={clsx([
        icon ? `fa ${icon}` : `fa fa-pencil-ruler`,
        classes.iconProfile,
      ])}
      style={{ color: color }}
    />
  );
};

WorkspaceIcon.defaultProps = {
  icon: "",
  color: "",
  size: "22",
};

WorkspaceIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

export default WorkspaceIcon;

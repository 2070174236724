import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Grid,
  Slider,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { CREATE_AVATAR } from "../../../../../graphql/mutations";
import DraggableModal from "../../../../common/DraggableModal";
import DraggableModalHead from "../../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../../common/DraggableModal/DraggableModalBody";
import { currentViewer } from "../../../../../actions";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "768px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  slider: {
    width: 215,
  },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "5px 15px",
    backgroundColor: "#66615B",
    color: "#fff",
    minWidth: 196,
  },
  userAvatar: {
    width: 200,
    height: 200,
    borderWidth: 7,
    borderColor: "#fff",
    borderStyle: "solid",
    marginRight: 28,
    marginTop: "-4px",
  },
  btnSave: {
    marginLeft: "auto",
    marginTop: 32,
    marginRight: 30,
    minWidth: 196,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
}));

const AvatarUpload = ({
  setOpenUpload,
  setAvatarProfile,
  defaultAvatar,
  currentAvatar,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [backDropOpen, setBackDropOpen] = React.useState(false);
  const [editor, setEditor] = useState(null);
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [initial, setInitial] = useState(true);
  const [openSlider, setOpenSlider] = useState(false);
  const [noAvatar, setNoAvatar] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [gravatar, setGravatar] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [uploadAvatar] = useMutation(CREATE_AVATAR);
  const { api } = useSelector(state => state);
  const { avatar } = api.currentViewer.viewer;
  // const [openResources, setOpenResources] = useState(false);
  // const [selectedResources, setSelectedResources] = useState(null);

  const handleQuery = async (avatarType, imageData = null) => {
    const res = await uploadAvatar({
      variables: {
        avatarType,
        imageData,
      },
    });
    if (res.data.userAccountContext.uploadAvatar.success) {
      const viewer = JSON.parse(localStorage.getItem("viewer"));
      viewer.avatar = res.data.userAccountContext.uploadAvatar.avatarUrl;
      setAvatarProfile(res.data.userAccountContext.uploadAvatar.avatarUrl);
      localStorage.setItem("viewer", JSON.stringify(viewer));
      dispatch(currentViewer({ viewer }));
      setBackDropOpen(false);
      setOpenUpload(false);
    }
  };

  const handleChange = (event, newValue) => {
    setScale(newValue);
  };

  const setEditorRef = edit => {
    setEditor(edit);
  };

  const onClickSave = async () => {
    setBackDropOpen(true);
    let croppedImg = null;

    if (isUpload) {
      const canvasScaled = editor.getImageScaledToCanvas();
      croppedImg = canvasScaled.toDataURL();
      setAvatarProfile(croppedImg);
      handleQuery("SYSTEM", croppedImg);
    }
    if (noAvatar) {
      handleQuery("NO_AVATAR", null);
    }
    if (gravatar) {
      setBackDropOpen(false);
      setOpenUpload(false);
    }
  };

  const onImageChange = event => {
    const img = event.target.files[0];
    setIsSave(true);
    setImage(URL.createObjectURL(img));
  };

  const handleSaveNoAvatar = async () => {
    setNoAvatar(true);
    setIsSave(true);
    setIsUpload(false);
    setOpenSlider(false);
    setGravatar(false);
    setInitial(false);
    setEditor(null);
  };

  const handleUploadAvatar = () => {
    setIsUpload(true);
    setOpenSlider(true);
    setIsSave(true);
    setNoAvatar(false);
    setGravatar(false);
    setInitial(false);
  };

  const handleGravatar = async () => {
    setEditor(null);
    setIsSave(true);
    setIsUpload(false);
    setNoAvatar(false);
    setOpenSlider(false);
    setInitial(false);
    setBackDropOpen(true);
    const res = await uploadAvatar({
      variables: {
        avatarType: "GRAVATAR",
        imageData: null,
      },
    });
    if (res.data.userAccountContext.uploadAvatar.success) {
      const viewer = JSON.parse(localStorage.getItem("viewer"));
      viewer.avatar = res.data.userAccountContext.uploadAvatar.avatarUrl;
      setAvatarProfile(res.data.userAccountContext.uploadAvatar.avatarUrl);
      localStorage.setItem("viewer", JSON.stringify(viewer));
      dispatch(currentViewer({ viewer }));
      setBackDropOpen(false);
      setGravatar(true);
    }
  };

  const handleOpenResources = () => {};

  return (
    <DraggableModal open customClass={classes.modal}>
      <DraggableModalHead
        handleClose={() => setOpenUpload()}
        title="Upload Avatar"
      />
      <DraggableModalBody customClass={classes.body}>
        <Box style={{ height: 540 }}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Box className={classes.wrapper} style={{ height: 280 }}>
                <Box style={{ marginLeft: "auto" }}>
                  {initial && (
                    <AvatarEditor
                      ref={setEditorRef}
                      image={noAvatar ? defaultAvatar : avatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={scale}
                    />
                  )}
                  {noAvatar && (
                    <AvatarEditor
                      ref={setEditorRef}
                      image={noAvatar ? defaultAvatar : avatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={1}
                    />
                  )}
                  {isUpload && (
                    <AvatarEditor
                      ref={setEditorRef}
                      image={image || currentAvatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={scale}
                    />
                  )}
                  {gravatar && (
                    <AvatarEditor
                      ref={setEditorRef}
                      disableHiDPIScaling
                      image={currentAvatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={1}
                    />
                  )}
                </Box>
              </Box>
              <Box style={{ height: 150 }}>
                {isUpload && (
                  <Box className={classes.wrapper}>
                    <Box
                      style={{
                        marginLeft: "auto",
                        textAlign: "center",
                        marginRight: 35,
                      }}
                    >
                      <Box>
                        <Button
                          variant="contained"
                          className={classes.uploadButton}
                          component="label"
                        >
                          <FormattedMessage id="btn.uploadFromComputer" />
                          <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={onImageChange}
                          />
                        </Button>
                      </Box>
                      <Box style={{ margin: "4px 0" }}>
                        <FormattedMessage id="rfp.or" />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          className={classes.uploadButton}
                          component="label"
                          onClick={handleOpenResources}
                          disabled
                        >
                          <FormattedMessage id="btn.uploadFromSystem" />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
                {openSlider && (
                  <Box className={classes.wrapper}>
                    <Box
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ marginRight: 10 }}>Zoom: </Box>
                      <Slider
                        min={0.1}
                        className={classes.slider}
                        value={scale}
                        onChange={handleChange}
                        aria-labelledby="continuous-slider"
                        step={0.01}
                        max={5}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={4} style={{ position: "relative" }}>
              <Box style={{ marginTop: "73%" }}>
                <Box style={{ display: "flex", marginBottom: 12 }}>
                  <Button
                    variant="contained"
                    className={classes.uploadButton}
                    component="label"
                    onClick={handleSaveNoAvatar}
                  >
                    <FormattedMessage id="btn.noAvatar" />
                  </Button>
                </Box>
                <Box style={{ display: "flex", marginBottom: 12 }}>
                  <Button
                    variant="contained"
                    className={classes.uploadButton}
                    component="label"
                    onClick={handleUploadAvatar}
                  >
                    <FormattedMessage id="btn.uploadAvatar" />
                  </Button>
                </Box>
                <Box style={{ display: "flex", marginBottom: 12 }}>
                  <Button
                    variant="contained"
                    className={classes.uploadButton}
                    onClick={handleGravatar}
                  >
                    <FormattedMessage id="btn.uploadFromGravatar" />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box style={{ height: 35 }}>
            {isSave && (
              <>
                <Box className={classes.wrapper}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={onClickSave}
                    className={classes.btnSave}
                  >
                    <FormattedMessage id="btn.save" />
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Backdrop className={classes.backdrop} open={backDropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </DraggableModalBody>
    </DraggableModal>
  );
};

AvatarUpload.propTypes = {
  setOpenUpload: PropTypes.func.isRequired,
  setAvatarProfile: PropTypes.func.isRequired,
  defaultAvatar: PropTypes.node.isRequired,
  currentAvatar: PropTypes.node.isRequired,
};

export default AvatarUpload;

import PropTypes from "prop-types";
import { createContext, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import Snackbar from "./Snackbar";
import UserContactInfoModal from "../../components/common/UserContactInfoModal";
import WorkspaceOwnerContactLink from "../../components/common/WorkspaceOwnerContactLink";

const initialState = {
  autoHideDuration: null,
  horizontal: "center",
  isOpen: false,
  message: "",
  type: "",
  vertical: "top",
};

export const snackbarContext = createContext(initialState);

const { Provider } = snackbarContext;

const SnackbarProvider = ({ children, sites, workspace }) => {
  const intl = useIntl();

  const [displaySiteOwner, setDisplaySiteOwner] = useState(false);
  const [siteOwnerUsername, setSiteOwnerUsername] = useState(undefined);
  const [snackbarState, setSnackbarState] = useState(initialState);

  const { autoHideDuration, horizontal, isOpen, message, type, vertical } = snackbarState;

  const handleClose = () => {
    setSnackbarState(initialState);
  }

  const handleCloseSiteOwner = () => {
    setDisplaySiteOwner(false);
  }

  const handleDisplaySiteOwner = () => {
    setDisplaySiteOwner(true);
    handleClose();
  };

  const getSiteOwnerUsername = () => {
    if (sites && workspace && workspace.id) {
      const selectedSite = sites.find(x => x.id === workspace.id);
      if (
        selectedSite &&
        selectedSite.siteOwnerDetails &&
        selectedSite.siteOwnerDetails.username
      ) {
        return selectedSite.siteOwnerDetails.username;
      }
    }
    return undefined;
  };

  const createWorkspaceAlertMessage = (alertMessage, username) => {
    const contactLink = username ? (
      <WorkspaceOwnerContactLink
        onClick={handleDisplaySiteOwner}
      />
    ) : (
      <span></span>
    );

    var message = intl.formatMessage(
      { id: "common.messageWithContactLink" },
      { message: alertMessage, contactLink }
    );

    return message;
  };

  const success = alertMessage => {
    setSnackbarState({
      ...snackbarState,
      autoHideDuration: 1500,
      isOpen: true,
      message: alertMessage,
      type: "success",
    });
  };

  const info = alertMessage => {
    setSnackbarState({
      ...snackbarState,
      isOpen: true,
      message: alertMessage,
      type: "info",
    });
  };

  const warning = alertMessage => {
    setSnackbarState({
      ...snackbarState,
      isOpen: true,
      message: alertMessage,
      type: "warning",
    });
  };

  const error = alertMessage => {
    setSnackbarState({
      ...snackbarState,
      isOpen: true,
      message: alertMessage,
      type: "error",
    });
  };

  const workspaceError = alertMessage => {
    var username = getSiteOwnerUsername();
    setSiteOwnerUsername(username);

    var message = createWorkspaceAlertMessage(alertMessage, username);
    error(message);
  };

  const workspaceInfo = alertMessage => {
    var username = getSiteOwnerUsername();
    setSiteOwnerUsername(username);

    var message = createWorkspaceAlertMessage(alertMessage, username);
    info(message);
  };

  return (
    <>
      {isOpen && (
        <Snackbar
          autoHideDuration={autoHideDuration}
          handleClose={handleClose}
          horizontal={horizontal}
          message={message}
          open={isOpen}
          type={type}
          vertical={vertical}
        />
      )}
      {displaySiteOwner && (
        <UserContactInfoModal
          handleClose={handleCloseSiteOwner}
          open={displaySiteOwner}
          username={siteOwnerUsername}
        />
      )}
      <Provider
        value={{
          close: handleClose,
          error,
          info,
          success,
          warning,
          workspaceError,
          workspaceInfo,
        }}
      >
        {children}
      </Provider>
    </>
  );
};

SnackbarProvider.defaultProps = {
  children: null,
  workspace: { id: null, name: null },
};

SnackbarProvider.propsType = {
  children: PropTypes.node,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          childMenu: PropTypes.arrayOf(PropTypes.shape({})),
          name: PropTypes.string.isRequired,
          page: PropTypes.string.isRequired,
        })
      ),
    }).isRequired
  ).isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  return {
    sites: state.api.currentViewer.viewer?.sites,
    workspace: state.ui.toggleWorkspaces.workspace,
  };
};

export default connect(mapStateToProps)(SnackbarProvider);

import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { useIntl } from "react-intl";
import shortid from "shortid";
import { GET_MONITORING_RECIPIENTS } from "../../../graphql/queries";
import MonitorSettingsForm from "./MonitorSettings/MonitorSettingsForm";
import MonitorSettingsSearch from "./MonitorSettings/MonitorSettingsSearch";
import AutoCompleteField from "./MonitorSettings/AutoCompleteField";
import { isRecipientFieldRequired } from "../../../helpers/monitoring";
import { INITIAL_ADDRESS_FORM_VALUES } from "../../../constant/initialValues";
import { GET_ADDRESS_FOR_USER } from "../../../graphql/queries/MonitoringContext";

const MonitorSettings = ({
  notificationOccasionTypes,
  notificationActions,
  notificationActionTypes,
  notificationOccasion,
  recipients,
  setNotificationActions,
  setNotificationOccasion,
  setRecipients,
  receivers,
  setReceivers,
  handleRemoveRecipient,
}) => {
  const intl = useIntl();
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [recipientsOptions, setRecipientOptions] = useState([]);

  const [getRecipients, { data: recipientsData, loading }] = useLazyQuery(
    GET_MONITORING_RECIPIENTS
  );

  const [getAddresForUser, { data: addressData }] = useLazyQuery(
    GET_ADDRESS_FOR_USER
  );

  const debouncedSearchTerm = useCallback(
    debounce(value => {
      getRecipients({ variables: { searchTerm: value } });
    }, 300),
    []
  );

  const handleAutoCompleteOpen = () => {
    setOpenAutoComplete(true);
  };

  const handleAutoCompleteClose = () => {
    setOpenAutoComplete(false);
  };

  useEffect(() => {
    if (searchTerm) {
      debouncedSearchTerm(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (
      recipientsData &&
      recipientsData.monitoringContext &&
      recipientsData.monitoringContext.recipientLookup.length > 0
    ) {
      const filteredRecipientsOptions = [];
      recipientsData.monitoringContext.recipientLookup.forEach(x => {
        const found = recipients.find(y => x.userId === y.value);
        if (!found) {
          filteredRecipientsOptions.push({ label: x.user, value: x.userId });
        }
      });
      setRecipientOptions(filteredRecipientsOptions);
    }
  }, [recipientsData]);

  useEffect(() => {
    if (
      recipients &&
      recipients.length > 0 &&
      recipientsOptions &&
      recipientsOptions.length > 0
    ) {
      const filteredRecipientsOptions = [];
      recipientsOptions.forEach(x => {
        const found = recipients.find(y => x.value === y.value);
        if (!found) {
          filteredRecipientsOptions.push(x);
        }
      });
      setRecipientOptions(filteredRecipientsOptions);
    }
  }, [recipients]);

  const recipientFieldIsRequired = isRecipientFieldRequired(
    notificationActions
  );

  const addNewReceiver = selectedOptions => {
    let newRecipient = null;

    selectedOptions.forEach(x => {
      const existingRecipient = recipients.find(y => x.value === y.value);
      if (!existingRecipient) {
        newRecipient = x;
      }
    });

    if (newRecipient) {
      getAddresForUser({
        variables: {
          userId: newRecipient.value,
        },
      });

      const newReceiver = {
        shortId: shortid.generate(),
        userId: newRecipient.value,
        ...INITIAL_ADDRESS_FORM_VALUES,
      };
      setReceivers([...receivers, newReceiver]);
    }
  };

  useEffect(() => {
    if (
      addressData &&
      addressData.monitoringContext &&
      addressData.monitoringContext.getAddressForUser
    ) {
      const {
        deliveryAddress: delivery,
        invoiceAddress: invoice,
        postalAddress: postal,
        userId,
      } = addressData.monitoringContext.getAddressForUser;
      const allAddress = {
        deliveryAddress: delivery,
        invoiceAddress: invoice,
        postalAddress: postal,
      };
      const tempReceivers = [...receivers];

      const index = tempReceivers.findIndex(
        receiver => receiver.userId === userId
      );

      tempReceivers[index] = { ...tempReceivers[index], ...allAddress };

      setReceivers(tempReceivers);
    }
  }, [addressData]);

  const handleSelectRecipient = (selectedOptions, reason) => {
    if (selectedOptions.length > recipients.length) {
      addNewReceiver(selectedOptions);
      setRecipients(selectedOptions);
    } else {
      handleRemoveRecipient(selectedOptions);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={6}>
          <AutoCompleteField
            loading={false}
            onChange={() => {}}
            options={notificationActionTypes}
            setValue={setNotificationActions}
            value={notificationActions}
            label={intl.formatMessage({
              id: "monitoring.howMonitorSent",
            })}
            required
            data-tut="step1"
            multiple
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MonitorSettingsForm
            label={intl.formatMessage({
              id: "monitoring.whenShouldNotification",
            })}
            options={notificationOccasionTypes}
            onChange={e => setNotificationOccasion(e.target.value)}
            value={notificationOccasion}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <MonitorSettingsSearch
            label={intl.formatMessage({
              id: "monitoring.toWhomNotificationSent",
            })}
            open={openAutoComplete}
            setOpen={handleAutoCompleteOpen}
            setClose={handleAutoCompleteClose}
            onChange={e => setSearchTerm(e.target?.value || "")}
            options={recipientsOptions}
            loading={loading}
            setValue={handleSelectRecipient}
            value={recipients}
            required={recipientFieldIsRequired}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

MonitorSettings.defaultProps = {
  recipients: [],
  receivers: [],
};

MonitorSettings.propTypes = {
  notificationOccasionTypes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  notificationActions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  notificationActionTypes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  notificationOccasion: PropTypes.string.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.number })),
  setNotificationActions: PropTypes.func.isRequired,
  setNotificationOccasion: PropTypes.func.isRequired,
  setRecipients: PropTypes.func.isRequired,
  setReceivers: PropTypes.func.isRequired,
  receivers: PropTypes.arrayOf(PropTypes.shape({})),
  handleRemoveRecipient: PropTypes.func.isRequired,
};

export default MonitorSettings;

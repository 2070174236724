import { gql } from '@apollo/client';

const GET_SAVED_NOTIFICATIONS = gql`
  query getSavedNotifications(
    $siteid: Int!
    $from: DateTime!
    $to: DateTime!
    $ascending: Boolean!
    $sortColumn: String
  ) {
    myNotificationContext {
      getSavedNotifications(
        siteId: $siteid
        from: $from
        to: $to
        ascending: $ascending
        sortColumn: $sortColumn
      ) {
        savedNotificationId
        name
        savedDate
        notificationSummary {
          numberOfMonitoredResources
          numberOfRecipients
          monitoringTypes
          notificationAction
          notificationOccasion
          siteName
        }
      }
    }
  }
`;

export default GET_SAVED_NOTIFICATIONS;

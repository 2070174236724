import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Button, Icon, AppBar, Tabs } from "@mui/material";
import clsx from "clsx";
import TabPanel from "../../TabPanel";
import PageTabs from "../../PageTabs";
import { geta11yProps } from "../../../helpers/get";
import SvenkaTab from "./SvenkaTab";
import SwedishSpecificTab from "./SwedishSpecificTab";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #F2F2F2",
    marginBottom: 15,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  greenIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  wrap: {
    backgroundColor: "transparent",
    borderBottom: "1px solid #e4e4e4",
    "& .MuiIcon-root": {
      display: "none",
    },
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fbfbfb",
  },
  tabsRoot: {
    minHeight: "unset",
    "& svg": {
      display: "none",
    },
    "& .MuiButtonBase-root": {
      padding: "10px 12px",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
}));

const Texts = () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>Texts</Typography>
        <Button className={classes.addLink}>
          <Icon className={clsx(["fas fa-plus", classes.greenIcon])} />
          Add new text
        </Button>
      </Box>
      <Box className={classes.body}>
        <AppBar position="static" elevation="0" className={classes.wrap}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Texts"
            classes={{
              indicator: classes.indicator,
            }}
            className={classes.tabsRoot}
          >
            <PageTabs
              className={classes.pageTabs}
              label="Svenka"
              {...geta11yProps(0)}
            />
            <PageTabs
              className={classes.pageTabs}
              label="English"
              {...geta11yProps(1)}
            />
            <PageTabs
              className={classes.pageTabs}
              label="Swedish, site-specific"
              {...geta11yProps(2)}
            />
            <PageTabs
              className={classes.pageTabs}
              label="English, site-specific"
              {...geta11yProps(3)}
            />
          </Tabs>
        </AppBar>
        {[
          <SvenkaTab />,
          <SvenkaTab />,
          <SwedishSpecificTab />,
          <SwedishSpecificTab />,
        ].map((x, index) => (
          <TabPanel value={value} className={classes.tabPanel} index={index}>
            {x}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default Texts;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { GET_SAVED_NOTIFICATION } from "../../graphql/queries";
import MyNotificationDetail from "./MyNotificationDetail";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1400px",
    height: "96vh",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "95vh",
    },
  },

  modalHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 10px",
    color: "#fff",
    minHeight: 20,
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
    },
  },
  close: {
    padding: 4,
    color: "#fff",
    marginLeft: "auto",
  },
  modalTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 2.3,
    marginRight: 20,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "normal",
      fontSize: 14,
    },
  },

  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const MyNotificationModal = ({
  open,
  handleClose,
  savedNotificationId,
  notificationName,
  onRequestWorkspace,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [notificationData, setNotificationData] = useState();
  const [modalTitle, setModalTitle] = useState(notificationName);
  const [statusMessage, setStatusMessage] = useState();

  const { data, loading, error } = useQuery(GET_SAVED_NOTIFICATION, {
    variables: {
      savedNotificationId,
    },
    skip: !savedNotificationId,
  });

  const requestWorkspace = siteId => {
    if (typeof onRequestWorkspace === "function") {
      onRequestWorkspace(siteId);
    }
  };

  useEffect(() => {
    if (!error && data) {
      var notificationData =
        data?.myNotificationContext?.getSavedEventsByNotification;
      if (notificationData) {
        requestWorkspace(notificationData.siteId);
        setModalTitle(notificationData.name ?? notificationName);
        setNotificationData(notificationData);
        setStatusMessage();
      }
    } else if (error) {
      var errorMessage = intl.formatMessage({
        id: "common.genericErrorMessage",
      });
      setModalTitle(errorMessage);
      setStatusMessage(errorMessage);
    } else if (loading) {
      var loadingMessage = intl.formatMessage({ id: "common.genericLoading" });
      setModalTitle(loadingMessage);
      setStatusMessage(loadingMessage);
    }
  }, [data, loading, error]);

  return (
    <Dialog open={open} fullScreen>
      <DialogTitle classes={{ root: classes.modalHeader }}>
        <Typography className={classes.modalTitle} component="div">
          {modalTitle}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.close}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DraggableModalBody>
        {(loading || error) && statusMessage}
        {!loading && !error && (
          <MyNotificationDetail notificationData={notificationData} />
        )}
      </DraggableModalBody>
    </Dialog>
  );
};

MyNotificationModal.defaultProps = {
  onRequestWorkspace: () => {},
};

MyNotificationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  notificationName: PropTypes.string.isRequired,
  onRequestWorkspace: PropTypes.func,
  open: PropTypes.bool.isRequired,
  savedNotificationId: PropTypes.number.isRequired,
};

export default MyNotificationModal;

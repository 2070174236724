import React, { useEffect, memo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";
import { debounce } from "lodash";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "../../common/FormControl/Checkbox";
import CustomIconButton from "./CustomIconButton";
import NameCell from "./NameCell";
import QuantityCell from "./QuantityCell";
import PriceCell from "./PriceCell";
import { useUpdateItemsShoppingCart } from "../../../hooks";
import oneStream from "../../../store/reducers/api/collections/one-stream";
import { ContainedSelect } from "../../common";

const useStyles = makeStyles(theme => ({
  headingForm: {
    display: "flex",
  },
  label: {
    fontSize: 10,
    marginRight: 5,
    marginLeft: 15,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginRight: 5,
      display: "inline",
    },
  },
  quantityTextFieldWrapper: {
    display: "flex",
    alignItems: "center",
    "& .MuiFormControl-fullWidth": {
      width: 50,
    },
    "& input": {
      textAlign: "center",
      fontSize: 10,
      padding: "7px 12px",
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 5,
    },
  },
  btnGroup: {
    marginLeft: 5,
    marginRight: 15,
    "& button": {
      padding: "0 15px",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  qtyWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
    },
  },
  tdHeader: {
    padding: "0px 24px 0px 16px",
    backgroundColor: "#ddd",
    borderBottom: "none",
  },
  selectWrap: {
    display: "inline-block",
  },
  selectForm: {
    display: "flex",
    alignItems: "center",
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
      fontSize: 10,
      padding: "4px 12px",
    },
    "& .MuiFormControl-fullWidth": {
      width: 140,
    },
    "& .MuiSelect-iconOutlined": {
      right: 3,
      top: 3,
    },
    "& .MuiInputLabel-outlined": {
      top: -5,
      fontSize: 12,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      top: 0,
    },
    fontWeight: 500,
  },
}));

const OrderGroupRowHeader = ({
  isChecked,
  onChange,
  packageName,
  toggleConfirmDeleteGroup,
  setCollapse,
  collapse,
  propertyProductData,
  selectableSuppliers,
  fileGroup,
  items,
  handleopenAddFolderToBasket,
  quantity,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [itemQuantity, setItemQuantity] = useState(quantity || 0);
  const { execute: executeUpdateItems } = useUpdateItemsShoppingCart();

  const successUpdateItems = () => {
    alert.success(<FormattedMessage id="product.successUpdatedItemCart" />);
  };

  const errorUpdateItems = () => {
    alert.error(<FormattedMessage id="product.failUpdatedItemCart" />);
  };

  const handleUpdateItems = input => {
    executeUpdateItems({ input, successUpdateItems, errorUpdateItems });
  };

  useEffect(() => {
    setItemQuantity(quantity);
  }, [quantity]);

  const handleSuppliers = value => {
    setSelectedSupplier(value);
    const input = items.map(x => ({
      shoppingCartItemId: x.shoppingCartItemId,
      quantity: x.quantity,
      siteSupplierId: value,
    }));
    handleUpdateItems(input);
  };

  const debounceHandleUpdate = useCallback(
    debounce(value => {
      handleUpdateItems(value);
    }, 1000),
    []
  );

  const handleQuantity = value => {
    const intValue = parseInt(value, 10);
    if (intValue > 0) {
      setItemQuantity(intValue);
      if (oneStream) {
        const input = items.map(x => ({
          quantity: intValue,
          shoppingCartItemId: x.shoppingCartItemId,
          siteSupplierId: x.siteSupplierId || null,
        }));
        debounceHandleUpdate(input);
      }
    }
  };

  const handleIncreaseQuantity = () => {
    const intValue = parseInt(itemQuantity, 10);
    const tempValue = intValue + 1;
    setItemQuantity(tempValue);

    if (oneStream) {
      const input = items.map(x => ({
        quantity: tempValue,
        shoppingCartItemId: x.shoppingCartItemId,
        siteSupplierId: x.siteSupplierId || null,
      }));
      debounceHandleUpdate(input);
    }
  };

  const handleDecreaseQuantity = () => {
    const intValue = parseInt(itemQuantity, 10);
    if (intValue > 1) {
      const tempValue = intValue - 1;
      setItemQuantity(tempValue);

      if (oneStream) {
        const input = items.map(x => ({
          quantity: tempValue,
          shoppingCartItemId: x.shoppingCartItemId,
          siteSupplierId: x.siteSupplierId || null,
        }));
        debounceHandleUpdate(input);
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(selectableSuppliers) && selectableSuppliers.length > 0) {
      const options = [];
      selectableSuppliers.forEach(x => {
        options.push({
          label: x.displayName,
          value: x.siteSupplierId,
        });
      });

      setSupplierOptions(options);
    }
  }, [selectableSuppliers]);

  return (
    <TableRow>
      <TableCell
        className={classes.tdHeader}
        style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
      >
        <Checkbox value={isChecked} onChange={onChange} />
      </TableCell>
      <TableCell className={classes.tdHeader} />
      <NameCell
        propertyProductData={propertyProductData}
        name={packageName}
        header
      />
      <TableCell className={classes.tdHeader}>
        <Box className={classes.selectWrap}>
          <Box className={classes.selectForm}>
            <ContainedSelect
              label="Leverantör:"
              onChange={e => handleSuppliers(e.target.value)}
              options={supplierOptions}
              value={selectedSupplier}
              small
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell className={classes.tdHeader} />
      <QuantityCell
        handleQuantity={handleQuantity}
        handleDecreaseQuantity={handleDecreaseQuantity}
        handleIncreaseQuantity={handleIncreaseQuantity}
        header
        itemQuantity={itemQuantity}
      />

      {fileGroup ? (
        <PriceCell price={0} header />
      ) : (
        <TableCell className={classes.tdHeader} />
      )}

      {fileGroup ? (
        <PriceCell price={0} header />
      ) : (
        <TableCell className={classes.tdHeader} />
      )}

      <TableCell
        className={classes.tdHeader}
        style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
      >
        <Box display="flex" justifyContent="flex-end">
          <Box
            className={classes.headingForm}
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
          >
            <CustomIconButton
              faName="fas fa-pencil-alt"
              onClick={handleopenAddFolderToBasket}
            />
            <CustomIconButton
              faName="fa fa-trash"
              color={theme.palette.error.dark}
              onClick={() => toggleConfirmDeleteGroup()}
            />
            <IconButton
              aria-label="expand row"
              onClick={() => setCollapse(prev => !prev)}
            >
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};
OrderGroupRowHeader.defaultProps = {
  collapse: false,
  fileGroup: false,
  isChecked: false,
  items: [],
  propertyProductData: [],
  selectableSuppliers: [],
  quantity: 0,
};

OrderGroupRowHeader.propTypes = {
  collapse: PropTypes.bool,
  fileGroup: PropTypes.bool,
  isChecked: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  packageName: PropTypes.string.isRequired,
  propertyProductData: PropTypes.arrayOf(PropTypes.shape),
  selectableSuppliers: PropTypes.arrayOf(PropTypes.shape({})),
  setCollapse: PropTypes.func.isRequired,
  toggleConfirmDeleteGroup: PropTypes.func.isRequired,
  handleopenAddFolderToBasket: PropTypes.func.isRequired,
  quantity: PropTypes.number,
};

export default memo(OrderGroupRowHeader);

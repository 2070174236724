import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography, Grid, Divider, Table, TableBody } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import ManageRolesTableRow from "./ManageRolesTableRow";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
  siteRole: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  siteRoleLabel: {
    fontSize: 12,
  },
}));

const Booking = ({
  disabled,
  handleCheckbox,
  roleSubscriptionLevel,
  siteRoleValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const headers = [
    {
      label: intl.formatMessage({
        id: "siteRoles.canAccessBookingModule",
      }),
      name: "bookingView",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canEditBookingSettings",
      }),
      name: "canManageBookingView",
      checkbox: true,
      toolTipInfo: true,
    },
  ];

  return (
    <Grid item xs={12} md={6}>
      <Typography className={classes.text}>
        <FormattedMessage id="siteRoles.bookingModule" />
      </Typography>
      <Divider className={classes.divider} />
      <Table className={classes.table} size="small">
        <TableBody>
          {headers.map(head => {
            const toolTipInfo = head.toolTipInfo ? head.toolTipInfo : false;
            return (
              <ManageRolesTableRow
                checkbox={head.checkbox}
                child={head.child}
                disabled={disabled}
                key={head.name}
                label={head.label}
                name={head.name}
                onChange={handleCheckbox}
                roleSubscriptionLevel={roleSubscriptionLevel}
                siteRoleValues={siteRoleValues}
                toolTipInfo={toolTipInfo}
              />
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

Booking.defaultProps = {
  disabled: false,
  roleSubscriptionLevel: {},
  siteRoleValues: {},
};

Booking.propTypes = {
  disabled: PropTypes.bool,
  handleCheckbox: PropTypes.func.isRequired,
  roleSubscriptionLevel: PropTypes.shape({}),
  siteRoleValues: PropTypes.shape({}),
};

export default Booking;

import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../../../../common/TextField";
import ExpansionDefault from "../../../../common/ExpansionDefault";
import TextAreaFull from "../../../../common/FormControl/TextAreaFull";

const useStyles = makeStyles(theme => ({
  customExpansion: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 10,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
  },
  mt8: {
    marginTop: 8,
  },
}));

const Copywriter = ({ formValues, handleChange }) => {
  const classes = useStyles();
  return (
    <ExpansionDefault
      label={<FormattedMessage id="copywriter.copywriter" />}
      panelContent="copywriterContent"
      panelHeader="copywriterHeader"
      defaultExpanded
    >
      <Grid container spacing={1} className={classes.customExpansion}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="copywriter"
            value={formValues.copywriter}
            onChange={handleChange}
            label={<FormattedMessage id="copywriter.copywriter" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="copywriterOfferedPrice"
            value={formValues.copywriterOfferedPrice}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.offeredPrice" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="copywriterInvoicedPrice"
            value={formValues.copywriterInvoicedPrice}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.invoicedPrice" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextAreaFull
            name="copywriterDescription"
            value={formValues.copywriterDescription}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.description" />}
            rows="4"
            rowsMax="4"
          />
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

Copywriter.propTypes = {
  formValues: PropTypes.shape({
    copywriter: PropTypes.string,
    copywriterOfferedPrice: PropTypes.string,
    copywriterInvoicedPrice: PropTypes.string,
    copywriterDescription: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Copywriter;

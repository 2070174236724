import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Box, Icon, Tooltip, FormControlLabel } from "@mui/material";
import { useIntl } from "react-intl";
import { PinkSwitch } from "../../common";

const useStyles = makeStyles(theme => ({
  switchWrap: {
    marginLeft: "auto",
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
    },
  },
  infoIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    marginLeft: 5,
    verticalAlign: "middle",
    color: theme.palette.common.link,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const SameSettingsSwitch = ({ includeSubfolders, setIncludeSubfolders }) => {
  const classes = useStyles();
  const handleSelectSubfolders = () => {
    setIncludeSubfolders(prevState => !prevState);
  };

  const intl = useIntl();

  return (
    <Box className={classes.switchWrap}>
      <FormControlLabel
        control={
          <PinkSwitch
            checked={includeSubfolders}
            onChange={handleSelectSubfolders}
          />
        }
        label={intl.formatMessage({
          id: "monitoring.makeTheSameSettingsForSubfolders",
        })}
      />
      <Tooltip
        title={intl.formatMessage({
          id: "monitoring.selectAllSubfoldersAndApply",
        })}
        placement="top"
      >
        <Icon className={clsx(["fas fa-question-circle", classes.infoIcon])} />
      </Tooltip>
    </Box>
  );
};

SameSettingsSwitch.defaultProps = {
  includeSubfolders: false,
};

SameSettingsSwitch.propTypes = {
  includeSubfolders: PropTypes.bool,
  setIncludeSubfolders: PropTypes.func.isRequired,
};

export default SameSettingsSwitch;

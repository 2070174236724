import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Divider } from "@mui/material";
import MainContentReadOnly from "./MainContentReadOnly";
import MoreContent from "./MoreContent";

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  viewOnly: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },
}));

const AccountSettingsReadOnly = ({ userDetails }) => {
  const classes = useStyles();
  return (
    <>
      <MainContentReadOnly
        userDetails={userDetails}
      />
      <Box className={classes.viewOnly}>
        <Divider className={classes.divider} />
        <MoreContent userDetails={userDetails} />
      </Box>
    </>
  );
};

export default AccountSettingsReadOnly;

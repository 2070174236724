import { createActions } from "redux-actions";

export const {
  setGroups,
  setGroupsLoading,
  setGroupsOpenedNodes,
} = createActions({
  SET_GROUPS_OPENED_NODES: ({ items }) => ({ items }),
  SET_GROUPS: ({ items }) => ({ items }),
  SET_GROUPS_LOADING: ({ loading }) => ({ loading }),
});

import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 20,
    display: "block",
    color: theme.palette.component.productBoxColor,
    overflowWrap: "break-word",
  },
}));

const renderDate = item => {
  const m = moment(item.shareDate);
  const title = m.format("YYYY-MM-DD hh:mm");
  const date = m.format("YYYY-MM-DD");
  return <span title={title}>{date}</span>;
};

const renderDescription = item => {
  return item.description;
};

const renderName = item => {
  return `${item.user.firstname} ${item.user.lastname}`.trim();
};

const renderTitle = isSharedByUser => {
  return isSharedByUser ? (
    <FormattedMessage id="sharedImageBasket.shareInfo.sharedByMe" />
  ) : (
    <FormattedMessage id="sharedImageBasket.shareInfo.sharedWithMe" />
  );
};

const renderUsername = item => {
  return item.user.username;
};

const ShareDetails = ({ className, details, isSharedByUser }) => {
  const classes = useStyles();

  if (details && details.user) {
    const title = renderTitle(isSharedByUser);

    const date = renderDate(details);
    const description = renderDescription(details);
    const name = renderName(details);
    const username = renderUsername(details);

    return (
      <Paper className={clsx([className, classes.paper])} elevation={0}>
        <p>
          <strong>{title}</strong>
        </p>
        <p>
          <FormattedMessage id="sharedImageBasket.shareInfo.date" />
          <br />
          <em>{date}</em>
        </p>
        <p>
          <FormattedMessage id="sharedImageBasket.shareInfo.description" />
          <br />
          <em>{description}</em>
        </p>
        {!isSharedByUser && (
          <p>
            <FormattedMessage id="sharedImageBasket.shareInfo.byUser" />
            <br />
            <em>{username}</em>
            {name && (
              <>
                <br />
                <em>{name}</em>
              </>
            )}
          </p>
        )}
      </Paper>
    );
  }

  return <></>;
};

ShareDetails.defaultProps = {
  className: null,
  details: null,
  isSharedByUser: false,
};

ShareDetails.propTypes = {
  className: PropTypes.string,
  details: PropTypes.shape({
    shareDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
    }).isRequired,
  }),
  isSharedByUser: PropTypes.bool,
};

export default ShareDetails;

import React from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

const Arrow = ({ arrowClassName, onClick }) => (
  <div className={arrowClassName} onClick={onClick}>
    <ArrowDropDown />
  </div>
);

export default Arrow;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import PropertyBox from "./PropertyBox";
import QuantityField from "../FolderFilesPropertiesTable/QuantityField";

const PropertiesFormQuantityField = ({
  productionSettings,
  selectedFoldersProperties,
  selectedResources,
  propertyFormValues,
  folderPropertyFormValues,
  handleQuantityOnChange,
  disabled,
}) => {
  const { quantityProperty = {} } = productionSettings || {};
  const intl = useIntl();

  let changed = false;
  let value = null;

  selectedResources.forEach(x => {
    const propertyFormValue = propertyFormValues.find(y => y.resourceId === x);
    const { productionSettings: productionSettingsFormValue } =
      propertyFormValue || {};

    if (!productionSettingsFormValue) {
      changed = true;
      return;
    }

    const { orderQuantity } = productionSettingsFormValue || {};
    const newValue = orderQuantity;

    if (value !== null) {
      const differentValue = value !== newValue;

      if (differentValue) {
        changed = true;
      }
    } else {
      value = newValue;
    }
  });

  selectedFoldersProperties.forEach(x => {
    const propertyFormValue = folderPropertyFormValues.find(
      y => y.folderId === x
    );
    const { productionSettings: productionSettingsFormValue } =
      propertyFormValue || {};

    if (!productionSettingsFormValue) {
      changed = true;
      return;
    }

    const { orderQuantity } = productionSettingsFormValue || {};
    const newValue = orderQuantity;

    if (value !== null) {
      const differentValue = value !== newValue;

      if (differentValue) {
        changed = true;
      }
    } else {
      value = newValue;
    }
  });

  let quantityValue = "";

  if (changed) {
    quantityValue = "";
  } else if (value) {
    quantityValue = value;
  } else {
    quantityValue = "";
  }
  return (
    <PropertyBox>
      <QuantityField
        name={intl.formatMessage({
          id: "common.quantity",
        })}
        quantityProperty={quantityProperty}
        value={quantityValue}
        onChange={handleQuantityOnChange}
        disabled={disabled}
      />
    </PropertyBox>
  );
};

PropertiesFormQuantityField.defaultProps = {
  folderPropertyFormValues: [],
  disabled: false,
};

PropertiesFormQuantityField.propTypes = {
  productionSettings: PropTypes.shape({
    quantityProperty: PropTypes.shape({}),
  }).isRequired,
  selectedFoldersProperties: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  handleQuantityOnChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default memo(PropertiesFormQuantityField);

import React, { useEffect, useState, memo, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
// import PropertiesForm from "./PropertiesForm";
import PropertiesForm from "../../../common/FilesTable/PropertiesForm";
import FolderFileList from "./FolderFileList";
import { useGetResources } from "../../../../hooks";

const useStyles = makeStyles(() => ({
  section1: {
    flex: 1,
  },
  section2: {
    flex: 3,
  },
  root: {
    marginTop: 10,
  },
}));

const MonitorFolderFilesSection = ({
  checkedFolders,
  filesAndFolderMonitorEnabled,
  folder,
  folderMonitorEnabled,
  folderPropertyFormValues,
  foldersData,
  includeSubfolders,
  noProductionSettingsModal,
  propertyFormValues,
  selectedFolders,
  selectedSite,
  setCheckedFolders,
  setFolderPropertyFormValues,
  setNoProductionSettingsModal,
  setPropertyFormValues,
  setSubFoldersWithDiffSettings,
  showProductionProperties,
  mainTitle,
  subTitle,
  productionSettings,
  loadingProductionSettings,
  notificationActions,
  productionSettingErrors,
  setProductionSettingErrors,
  folderMandatoryFields,
  haveFolderFileSettings,
}) => {
  const classes = useStyles();
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedFoldersProperties, setSelectedFoldersProperties] = useState(
    []
  );

  const { folderId, folderName } = folder || {};

  const { resources, loading, error } = useGetResources({
    folderId,
  });

  useEffect(() => {
    setSelectedResources([]);
    setSelectedFoldersProperties([]);
  }, [folderId]);

  const callbackCheckboxChange = (event, resourceId) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedFoldersProperties([]);
      const newSelectedResources = [
        ...new Set([...selectedResources, resourceId]),
      ];
      setSelectedResources(newSelectedResources);
    } else {
      const newSelectedResources = selectedResources.filter(
        x => x !== resourceId
      );
      setSelectedResources(newSelectedResources);
    }
  };

  const handleCheckboxChange = useCallback(
    (event, resourceId) => {
      callbackCheckboxChange(event, resourceId);
    },
    [selectedResources]
  );

  const handleCheckboxChangeFolder = (event, folderId) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedResources([]);
      const newSelectedFolder = [
        ...new Set([...selectedFoldersProperties, folderId]),
      ];
      setSelectedFoldersProperties(newSelectedFolder);
    } else {
      const newSelectedFolder = selectedFoldersProperties.filter(
        x => x !== folderId
      );
      setSelectedFoldersProperties(newSelectedFolder);
    }
  };

  const hidePropertiesForm =
    notificationActions.length === 1 &&
    notificationActions[0].value === "EMAIL";

  return (
    <Grid container spacing={2} className={classes.root}>
      {haveFolderFileSettings && (
        <>
          {!hidePropertiesForm && (
            <Grid item className={classes.section1}>
              <PropertiesForm
                folder={folder}
                folderId={folderId}
                folderMandatoryFields={folderMandatoryFields}
                folderName={folderName}
                folderPropertyFormValues={folderPropertyFormValues}
                isMonitoring
                productionSettingErrors={productionSettingErrors}
                productionSettings={productionSettings}
                propertyFormValues={propertyFormValues}
                resources={resources}
                selectedFoldersProperties={selectedFoldersProperties}
                selectedResources={selectedResources}
                setFolderPropertyFormValues={setFolderPropertyFormValues}
                setProductionSettingErrors={setProductionSettingErrors}
                setPropertyFormValues={setPropertyFormValues}
              />
            </Grid>
          )}
          <Grid item className={classes.section2}>
            <FolderFileList
              folder={folder}
              folderPropertyFormValues={folderPropertyFormValues}
              handleCheckboxChange={handleCheckboxChange}
              handleCheckboxChangeFolder={handleCheckboxChangeFolder}
              productionSettings={productionSettings}
              propertyFormValues={propertyFormValues}
              resources={resources}
              selectedFoldersProperties={selectedFoldersProperties}
              selectedResources={selectedResources}
              setFolderPropertyFormValues={setFolderPropertyFormValues}
              setPropertyFormValues={setPropertyFormValues}
              setCheckedFolders={setCheckedFolders}
              checkedFolders={checkedFolders}
              notificationActions={notificationActions}
              productionSettingErrors={productionSettingErrors}
              setProductionSettingErrors={setProductionSettingErrors}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

MonitorFolderFilesSection.defaultProps = {
  includeSubfolders: false,
  folderPropertyFormValues: [],
  selectedFolders: [],
  productionSettings: {},
};

MonitorFolderFilesSection.propTypes = {
  selectedFolders: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number.isRequired,
    })
  ),
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
      })
    ),
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  filesAndFolderMonitorEnabled: PropTypes.bool.isRequired,
  folderMonitorEnabled: PropTypes.bool.isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  foldersData: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  includeSubfolders: PropTypes.bool,
  loadingProductionSettings: PropTypes.bool.isRequired,
  mainTitle: PropTypes.string.isRequired,
  noProductionSettingsModal: PropTypes.bool.isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSite: PropTypes.shape({}).isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  setFolderPropertyFormValues: PropTypes.func.isRequired,
  setNoProductionSettingsModal: PropTypes.func.isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  setSubFoldersWithDiffSettings: PropTypes.func.isRequired,
  showProductionProperties: PropTypes.bool.isRequired,
  subTitle: PropTypes.string.isRequired,
  productionSettings: PropTypes.shape({
    propertyValueIds: PropTypes.arrayOf(PropTypes.number),
    productionProperties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default memo(MonitorFolderFilesSection);

export const MATILDA = "MATILDA (Trycksaker)";
export const KLARA = "KLARA (Kontorstryck)";
export const BEATA = "BEATA (Bilder)";
export const PIM = "PIM (Profilshop)";
export const SIV = "SIV (Flaggor, Fanor, Rollup)";
export const LAGERHANTERING = "LAGERHANTERING";
export const MITT_MATERIAL = "MITT MATERIAL";
export const LIFT_MACHINE = "Lift & Maskin";

export const SITE_DISPLAY_NAME = {
  [MATILDA]: "MATILDA",
  [KLARA]: "KLARA",
  [BEATA]: "BEATA",
  [PIM]: "PIM",
  [SIV]: "SIV",
  [LAGERHANTERING]: "LAGERHANTERING",
  [MITT_MATERIAL]: "MITT MATERIAL",
  [LIFT_MACHINE]: "Lift & Maskin",
};

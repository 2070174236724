import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_COMPANY_SALESDOCUMENT, INIT_SALES_DOCUMENTS_PRINT, SEARCH_ARTICLES, SEARCH_COMPANY_CARDS } from "../../../../graphql/queries";
import { DraggableModalBody, DraggableModalHead, FormattedCurrency } from "../../../common";
import SalesDocumentRowTable from "./SalesDocumentRowTable";
import CompanyAddressAutoComplete from "./CompanyAddressAutoComplete";
import lodash from "lodash";
import { Box, Button, Grid, TextField, Dialog, Divider, Table, TableBody, TableRow, TableCell, CircularProgress } from "@mui/material";
import { SALES_DOCUMENT_UPDATE } from "../../../../graphql/mutations";
import { DatePicker } from "@mui/x-date-pickers"

import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { useSnackbar } from "../../../../hooks";

const useStyles = makeStyles(theme => ({
  root: {
    "& .datePicker": {
      width: "100%",
    },
    "& .react-datepicker-wrapper": {
      textAlign: "center",
    },
    "& .react-datepicker__input-container input": {
      color: theme.palette.text.primary,
      height: "100%",
      width: "100%",
      border: "none",
      borderRadius: "4px 4px 0px 0",
      borderLeft: "none",
      padding: "23px 12px 6px 12px",
      fontSize: "12px",
      textAlign: "center",
      background: "rgba(0, 0, 0, 0.03)",
      marginTop: "0px",
      marginRight: "16px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
    },
    "& .react-datepicker-popper": {
      zIndex: 100,
    },
  },
  styleForTotal: {
    fontWeight: "bold",
  },
  invisibleCell: {
    border: "none",
    whiteSpace: "nowrap",
  },
  datePickerFormControl: {
    width: "100%",
  },
  table: {
    borderBottom: "none",
    "& tr:nth-child(3) :nth-of-type(2),& tr:nth-child(3) :nth-of-type(3)": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
    },
    "& td:first-of-type": {
      width: "90%",
      borderBottom: "none",
    },
  },
})
);

const addrBottom = { marginBottom: "32px" };

const lastRowWidth = {
  display: "flex",
  minWidth: "fit-content",
  width: "60px",
  padding: "6px 2px",
  width: "60px",
  padding: "6px 2px",
  borderBottom: "none",
};

const SalesEditModal = ({ open, handleClose, companyId, selectedSalesDocumentId, settings }) => {

  const classes = useStyles();

  const [document, setDocument] = useState(null);
  const intl = useIntl();
  const snackbar = useSnackbar();
  const [getCompanySalesDocument, { data: companySalesDocument, loading, error: errorLoading }] = useLazyQuery(GET_COMPANY_SALESDOCUMENT);
  const [getArticles, { data: articleData, loading: loadingArticles, error: errorLoadingArticles }] = useLazyQuery(SEARCH_ARTICLES);
  const [getCompanyCards, { data: companyData, loading: loadicCompanies, error: errorLoadingCompanies }] = useLazyQuery(SEARCH_COMPANY_CARDS);
  const [initPrint, { data: urlData, loading: loadingUrl, error: errorPrint }] = useLazyQuery(INIT_SALES_DOCUMENTS_PRINT);
  const [articles, setArticles] = useState();
  const [companyCards, setCompanyCards] = useState();
  const [updateSalesDocument, { data: saveResult, loading: savingProgress, error: errorResult }] = useMutation(SALES_DOCUMENT_UPDATE);

  const [selectedCompanyCard, setSelectedCompanyCard] = useState({ companyName: '' });

  const initializeNewDocument = () => {
    var newDocument = {
      salesDocumentId: -1,
      documentType: "INVOICE",
      header: {
        amountDue: 0,
        documentDate: new Date(),
        dueDate: moment(new Date()).add(30, "days").toDate(),
        paymentTermsDays: 30,
        ourReference: "",
        address: {
          address1: "",
          address2: "",
          address3: "",
          address4: "",
          address5: "",
          city: "",
          contact: "",
          country: "",
          customerId: "",
          email: "",
          reference: "",
          telephone: "",
          zip: "",
        }
      },
      rows: [
        {
          articleNo: "",
          amount: 1,
          description: "",
          price: 0,
          total: 0,
          unit: "PIECES",
          discount: 0,
          vAT: 25.0,
          article: { articleNo: "", amount: 1, price: 0, description: "" }
        }
      ]
    };

    setDocument(newDocument);
  };

  useEffect(() => {

    if (!open) {
      return;
    }
    getArticles({ variables: { companyId: companyId, searchTerm: '' } });
    getCompanyCards({ variables: { companyId, searchTerm: ''} })

    if (!selectedSalesDocumentId || selectedSalesDocumentId < 1) {
      //We are in "create documentMode"
      initializeNewDocument();
    }
    else {
      getCompanySalesDocument({ variables: { salesDocumentId: selectedSalesDocumentId } });
    }

  }, [open, selectedSalesDocumentId]);

  const [sumValues, setSumValues] = useState({ sum: 0, vat: 0, rounding: 0, total: 0 });

  useEffect(() => {

    function round(num, decimalPlaces = 0) {
      num = Math.round(num + "e" + decimalPlaces);
      return Number(num + "e" + -decimalPlaces);
    }

    if (document) {

      const sum = round(document.rows.reduce((acc, cur) => acc + cur.amount * cur.price * (1 - (cur.discount / 100.0)), 0), 2);
      //15
      //const vat = round(sum * 0.25, 2);
      const vat = round(document.rows.reduce((acc, cur) => acc + cur.amount * cur.price *(1 - (cur.discount / 100.0)) * (cur.vAT / 100.0), 0),2)
      //3.75
      const sumRoundedInclVAT = sum + vat;
      //18.75
      const trunc = Math.trunc(sumRoundedInclVAT);
      //18
      const decimalPart = sumRoundedInclVAT - trunc;
      //0.75
      const rounding = round(Math.abs(decimalPart) >= 0.5 ? 1 - decimalPart : trunc - sumRoundedInclVAT, 2);
      //0.25

      setSumValues({ sum, vat, rounding, total: sumRoundedInclVAT + rounding })
      //19
    }

  }, [document])

  const handlePrint = (salesDocumentId) => {
    initPrint({
      variables: {
        salesDocumentIds: [salesDocumentId || document.salesDocumentId]
      }
    })
  }

  const handleSave = () => {

    let model = {
      companyId: companyId,
      documentStatus: "USER_GENERATED",
      documentType: "INVOICE",
      salesDocumentId: document.salesDocumentId ?? -1,
      header: document.header,
      rows: document.rows.map(n => ({
        amount: n.amount,
        price: n.price,
        articleNo: n?.article?.articleNo ?? n.articleNo,
        description: n.description,
        unit: n.unit,
        discount: n.discount ?? 0,
        vAT: n.vAT
      }))
    }
    updateSalesDocument({ variables: { model: model } })
  }

  const handleSaveDraft = () => {
    let model = {
      companyId: companyId,
      documentStatus: "DRAFT",
      documentType: "INVOICE",
      salesDocumentId: document.salesDocumentId ?? -1,
      header: document.header,
      rows: document.rows.map(n => ({
        amount: n.amount,
        price: n.price,
        articleNo: n?.article?.articleNo ?? n.articleNo,
        description: n.description,
        unit: n.unit,
        discount: n.discount ?? 0,
        vAT: n.vAT
      }))
    }
    updateSalesDocument({ variables: { model: model } })
  }

  const handleUpdateSelectedCompanyCard = (newSelectedCard) => {

    setSelectedCompanyCard(newSelectedCard);
    if (newSelectedCard) {

      let newDocument = lodash.cloneDeep(document);
      newDocument.header.address = {
        address1: newSelectedCard.address.address1,
        address2: newSelectedCard.address.address2,
        address3: newSelectedCard.address.address3,
        address4: newSelectedCard.address.address4,
        address5: newSelectedCard.address.address5,
        city: newSelectedCard.address.city,
        contact: newSelectedCard.address.contact,
        country: newSelectedCard.address.country,
        customerId: newSelectedCard.address.customerId,
        email: newSelectedCard.address.email,
        reference: newSelectedCard.address.reference,
        telephone: newSelectedCard.address.telephone,
        zip: newSelectedCard.address.zip,
      }
      newDocument.header.companyAddressCardId = newSelectedCard.companyAddressCardId;
      newDocument.header.gLN = newSelectedCard.gLN;
      newDocument.header.orgNo = newSelectedCard.orgNo;
      newDocument.header.paymentTermsDays = newSelectedCard.paymentTermsDays;

      if (newDocument.header.documentDate) {
        newDocument.header.dueDate = moment(newDocument.header.documentDate).add(newDocument.header.paymentTermsDays, 'days').toDate();
      }

      setDocument(newDocument);
    }
  }

  const handleUpdateHead = (field, value) => {
    let newDocument = lodash.cloneDeep(document);
    newDocument.header[field] = value;

    if ((field === "documentDate" || field === "paymentTermsDays") && newDocument.header.documentDate) {

      newDocument.header.dueDate = moment(newDocument.header.documentDate).add(newDocument.header.paymentTermsDays, 'days').toDate();
    }
    setDocument(newDocument);
  }

  const handleUpdateAddress = (field, value) => {
    let newDocument = lodash.cloneDeep(document);
    newDocument.header.address[field] = value;
    setDocument(newDocument);
  }

  const handleUpdateRow = (row, index, field, value) => {

    let newDocument = lodash.cloneDeep(document);
    newDocument.rows[index][field] = value;

    if (field === "article") {
      newDocument.rows[index]["articleNo"] = value?.articleNo ?? "";

      if (value) {
        newDocument.rows[index]["price"] = value.price;
        newDocument.rows[index]["description"] = value.description;
      }
    }
    setDocument(newDocument);
  }

  const handleDeleteRow = (row, index) => {
    let rows = document.rows.slice();
    rows.splice(index, 1);
    let newDocument = lodash.cloneDeep(document);
    newDocument = { ...newDocument, rows };

    setDocument(newDocument);
  }

  const handleMoveRow = ({ fromIndex, toIndex }) => {
    let rows = document.rows.slice();
    const row = rows.splice(fromIndex, 1);
    rows.splice(toIndex, 0, ...row);
    let newDocument = lodash.cloneDeep(document);
    newDocument = { ...newDocument, rows };

    setDocument(newDocument);
  };

  const handleAddRow = (row, index) => {
    let rows = document.rows.slice();

    rows.splice(index + 1, 0, {
      articleNo: "",
      amount: 1,
      description: "",
      price: 0,
      vAT: 25.0,
      discount:0,
      total: 0,
      unit: "PIECES",
      article: { articleNo: "", amount: 1, price: 0, description: "" }
    });

    let newDocument = lodash.cloneDeep(document);
    newDocument = { ...newDocument, rows };

    setDocument(newDocument);
  };

  const handleAddTextRow = () => {
    let rows = document.rows.slice();

    rows.push({
      articleNo: "",
      amount: 1,
      description: "",
      price: 0,
      vAT: 0.0,
      discount: 0,
      total: 0,
      unit: "FREE_TEXT_ROW",
      article: { articleNo: "", amount: 1, price: 0, description: "" }
    });

    let newDocument = lodash.cloneDeep(document);
    newDocument = { ...newDocument, rows };

    setDocument(newDocument);
  }

  //Data fetched useEffects
  useEffect(() => {
    if (companySalesDocument) {
      var r = companySalesDocument.companyAdminContext.salesDocuments.getSalesDocument;
      var newDocument = { ...r };
      newDocument.header.documentDate = new Date(r.header.documentDate);
      newDocument.header.dueDate = r.header.dueDate ? new Date(r.header.dueDate) : undefined;
      setSelectedCompanyCard({ companyName: r.header.address.address1 })

      setDocument(newDocument);
    }
  }, [companySalesDocument])

  useEffect(() => {

    if (articleData) {
      setArticles(articleData.companyAdminContext.salesDocuments.searchArticles);
    }

  }, [articleData])

  useEffect(() => {

    if (companyData) {

      let newOptions = [];

      /*if (value) {
        newOptions = [value];
      }*/

      if (companyData?.companyAdminContext?.salesDocuments?.searchCompanyCards) {
        newOptions = [...newOptions, ...companyData?.companyAdminContext?.salesDocuments?.searchCompanyCards];
      }

      setCompanyCards(newOptions);
    }

  }, [companyData])

  useEffect(() => {
    if (urlData?.companyAdminContext?.salesDocuments) {
      window.open(urlData.companyAdminContext.salesDocuments.salesDoucmentPrint, "_blank");
    }

  }, [urlData])

  //data: saveResult, loading: savingProgress, error: errorResult

  useEffect(() => {
    if (saveResult) {
      var newDocument = saveResult.companyAdminContext.salesDocuments.createUpdateDocument.result;
      if (newDocument) {
        if (newDocument.documentNumber) {
          snackbar.success(intl.formatMessage({ id: "admin.sales.alerts.savedDocument" }));
          handlePrint(newDocument.salesDocumentId);
        } else { 
          snackbar.success(intl.formatMessage({ id: "admin.sales.alerts.savedDraft" }));
        }
        initializeNewDocument();
      } else {
        snackbar.error(intl.formatMessage({ id: "common.errorOnSaving" }));
      }
    } else if (errorResult) {
      snackbar.error(intl.formatMessage({ id: "common.errorOnSaving" }));
    }

  }, [saveResult])

  return (<Dialog
    open={open}
    handleClose={handleClose}
    customClass={classes.modal}
    fullScreen>
    <DraggableModalHead handleClose={handleClose} title={settings.modalTitle + (document?.documentNumber ? " " + document?.documentNumber : "")} />
    <DraggableModalBody>
      {loading && (<CircularProgress />)}
      {document && (
        <Box className={classes.root}>
          <Grid container spacing={1} sx={addrBottom}>
            <Grid item xs={12} sm={12} lg={4}>
              <CompanyAddressAutoComplete companyCards={companyCards} selectedCompany={selectedCompanyCard} onSelectCompany={handleUpdateSelectedCompanyCard} />
            </Grid>
            <Grid item xs={12} sm={6} lg={1}>
              <DatePicker
                className={classes.root}
                label={settings.documentDateLabel}
                localeText={{
                  clearButtonLabel: intl.formatMessage({ id: "common.clear" }),
                }}
                onChange={date => handleUpdateHead("documentDate", date)}
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                sx={{ width: "100%" }}
                value={document.header.documentDate}
              />

            </Grid>

            <Grid item xs={12} sm={10} lg={1}>
              <TextField
                label={settings.paymentTermsDays}
                placeholderText={settings.paymentTermsDays}
                value={document.header.paymentTermsDays}
                onChange={(e) => { handleUpdateHead("paymentTermsDays", e.target.value) }}
                type="number"
                inputProps={{
                  type: "text",
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={1}>
              <DatePicker wrapperClassName="datePicker"
                value={document.header.dueDate}
                onChange={date => handleUpdateHead("dueDate", date)}
                localeText={{
                  clearButtonLabel: intl.formatMessage({ id: "common.clear" }),
                }}
                label={settings.dueDateLabel}
                sx={{ width: "100%" }}
                readOnly
              />
            </Grid>

            <Grid item xs={12} sm={10} lg={1}>
              <TextField
                label={intl.formatMessage({ id: "admin.sales.dataLables.ourReference" })}
                placeholderText={settings.ourReferenceLabel}
                value={document.header.ourReference}
                onChange={(e) => { handleUpdateHead("ourReference", e.target.value) }}
                defaultValue={""}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={10} lg={1}>
              <TextField
                label={settings.yourReferenceLabel}
                placeholderText={settings.yourReferenceLabel}
                value={document.header.address.reference}
                onChange={(e) => { handleUpdateAddress("reference", e.target.value) }}
                defaultValue={""}
                fullWidth
              />
            </Grid>
          </Grid>
          <SalesDocumentRowTable
            articles={articles}
            companyId={companyId}
            onAddRow={handleAddRow}
            onChangeRow={handleUpdateRow}
            onDeleteRow={handleDeleteRow}
            onMoveRow={handleMoveRow}
            rows={document.rows}
            settings={settings}
          />
          <Button onClick={e => handleAddTextRow()}>Ny textrad</Button>
          <Divider sx={{ my: 2 }} />

          <Grid container direction="row" justifyContent="flex-end">
            <Grid item xs={12}>
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell width="75%" className={classes.invisibleCell}></TableCell>
                    <TableCell align="right" className={classes.invisibleCell}>
                      <FormattedMessage id="admin.sales.rowsTable.sumExclVAT" />
                    </TableCell>
                    <TableCell align="right" className={classes.invisibleCell}>
                      <FormattedCurrency value={sumValues.sum} />
                    </TableCell>
                    <TableCell sx={lastRowWidth} className={classes.invisibleCell} ></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="75%" className={classes.invisibleCell}></TableCell>
                    <TableCell align="right" className={classes.invisibleCell}>
                      <FormattedMessage id="admin.sales.dataLabels.vat" />
                    </TableCell>
                    <TableCell align="right" className={classes.invisibleCell}>
                      <FormattedCurrency value={sumValues.vat} />
                    </TableCell>
                    <TableCell sx={lastRowWidth} className={classes.invisibleCell}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="75%" className={classes.invisibleCell}></TableCell>
                    <TableCell align="right">
                      <FormattedMessage id="admin.sales.dataLabels.rounding" />
                    </TableCell>
                    <TableCell align="right">
                      <FormattedCurrency value={sumValues.rounding} />
                    </TableCell>
                    <TableCell sx={lastRowWidth} className={classes.invisibleCell}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="75%" className={classes.invisibleCell}></TableCell>
                    <TableCell align="right" className={classes.invisibleCell}>
                      <span className={classes.styleForTotal}><FormattedMessage id="admin.sales.dataLabels.total" /></span>
                    </TableCell>
                    <TableCell align="right" className={classes.invisibleCell}>
                      <span className={classes.styleForTotal}><FormattedCurrency value={sumValues.total} /></span>
                    </TableCell>
                    <TableCell sx={lastRowWidth} className={classes.invisibleCell}></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Box align="right">
                <Button sx={{ mr: 1 }} variant="contained" disabled={document.salesDocumentId <= 0} onClick={() => handlePrint()}><FormattedMessage id="btn.print" /></Button>
                {!document.documentNumber && (<>
                  <Button sx={{ mr: 1 }} variant="contained" onClick={() => { handleSaveDraft() }}><FormattedMessage id="admin.sales.invoices.buttons.saveDraft" /></Button>
                  <Button variant="contained" onClick={() => { handleSave() }}><FormattedMessage id="admin.sales.invoices.buttons.createInvoice" /></Button></>)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </DraggableModalBody>
  </Dialog>);
}

export default SalesEditModal;
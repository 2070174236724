import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../../hooks";
import axios from "axios";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import {
  INITIALIZE_FILE_UPLOAD,
  INITIALIZE_THUMBNAIL_UPLOAD,
  CREATE_NEW_PRODUCT_VERSION,
  INIT_DOCUMENT_FILE_UPLOAD,
} from "../../../../../../graphql/mutations";
import logger from "../../../../../../helpers/logger";
import FileSection from "./FileSection";
import FormSection from "./FormSection";
import { getProductOptions } from "../../Delivery/helpers";

const useStyles = makeStyles(theme => ({
  customForm: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputAdornment-root button": {
      padding: 5,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 10,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 10,
    },
  },
  text: {
    marginTop: 0,
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.component.productBoxColor,
    "& > span": {
      margin: "0px 5px",
    },
  },
  panelTitle: {
    fontWeight: "800",
  },
}));

const ArticleInfoPanel = ({
  formValues,
  handleFormValues,
  setFormValues,
  image,
  handleCheckbox,
  folderId,
  errors,
  setDraft,
  initialFileNames,
  setInitialFileNames,
  selectedProductType,
  setSelectedProductType,
  handleImageMetadata,
  handleTags,
  handleMetadataDate,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const intl = useIntl();

  const productTypeOptions = getProductOptions({ intl });

  const [uploadPDF] = useMutation(INITIALIZE_FILE_UPLOAD);
  const [uploadThumbnail] = useMutation(INITIALIZE_THUMBNAIL_UPLOAD);
  const [createNewProductVersion] = useMutation(CREATE_NEW_PRODUCT_VERSION);
  const [uploadAlternative] = useMutation(INIT_DOCUMENT_FILE_UPLOAD);

  const [uploadedOriginal, setUploadedOriginal] = useState([]);
  const [uploadedAlternative, setUploadedAlternative] = useState([]);
  const [uploadedThumbnail, setUploadedThumbnail] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);

  const [updatedThumbnail, setUpdatedThumbnail] = useState("");

  const [loadingUpload, setLoadingUpload] = useState({
    original: false,
    alternative: false,
    thumbnail: false,
    attachments: false,
  });

  const handleSelectProductType = e => {
    const { value } = e.target;
    setSelectedProductType(value);
    const selected = productTypeOptions.find(x => x.value === value);
    setDraft();
  };

  const handleRemoveOriginal = file => {
    const tempUploadedFiles = uploadedOriginal.filter(
      x => x.resourceId !== file.resourceId
    );
    setUploadedOriginal(tempUploadedFiles);
  };

  const handleRemoveThumbnail = file => {
    const tempUploadedFiles = uploadedThumbnail.filter(
      x => x.resourceId !== file.resourceId
    );
    setUploadedThumbnail(tempUploadedFiles);
  };

  const handleRemoveAlternative = file => {
    const tempUploadedFiles = uploadAlternative.filter(
      x => x.resourceId !== file.resourceId
    );
    setUploadedAlternative(tempUploadedFiles);
  };

  const handleRemoveResources = resource => {
    const tempResource = selectedResources.filter(x => x.id !== resource.id);
    setSelectedResources(tempResource);
  };

  const { resourceId } = formValues;

  const handleFromDateChange = date => {
    setFormValues({ ...formValues, activeFromDate: date });
    setDraft();
  };

  const handleToDateChange = date => {
    setFormValues({ ...formValues, activeUntilDate: date });
    setDraft();
  };

  const handleUploadFromSystem = async resources => {
    const resource = resources;
    if (selectedResources.length > 0) {
      snackbar.workspaceError(<FormattedMessage id="product.uploadError" />);
      return;
    }

    setSelectedResources(resource);

    const sourceProductId = resource[0].id;

    try {
      const res = await createNewProductVersion({
        variables: { productId: resourceId, sourceProductId },
      });

      if (
        res &&
        res.data &&
        res.data.createNewProductVersion &&
        res.data.createNewProductVersion.success
      ) {
        logger.log(`Success uploading file.`);
      }
    } catch (uploadException) {
      logger.error(
        `An error occurred while uploading the file. ${JSON.stringify(
          uploadException
        )}`
      );
    }
  };

  const handleFileUpload = async e => {
    const { target } = e || {};
    const { files } = target || {};
    if (uploadedOriginal.length > 0) {
      snackbar.workspaceError(<FormattedMessage id="product.uploadError" />);
      return;
    }

    const res = await uploadPDF({ variables: { folderId, resourceId } });

    if (res && res.data && res.data.initFileUpload) {
      const uploadUrl = res.data.initFileUpload.url;

      if (files && files[0]) {
        try {
          setLoadingUpload({ ...loadingUpload, original: true });
          const formData = new FormData();
          formData.append("file", files[0]);

          const response = await axios.post(`${uploadUrl}`, formData);

          if (
            response &&
            response.data &&
            response.data.success &&
            response.status === 200
          ) {
            const id = response.data.resourceId;

            const newFile = { resourceId: id, name: files[0].name };
            setUploadedOriginal([...uploadedOriginal, newFile]);
            logger.log(`Success uploading file.`);
          } else {
            logger.error(
              `An error occurred while uploading the file. ${JSON.stringify(
                response
              )}`
            );
          }
          setLoadingUpload({ ...loadingUpload, original: false });
        } catch (uploadException) {
          setLoadingUpload({ ...loadingUpload, original: false });
          logger.error(
            `An error occurred while uploading the file. ${JSON.stringify(
              uploadException
            )}`
          );
        }
      } else {
        logger.warn("An error could be happening in selecting files.");
      }
    }
  };

  const showUploadFailAlert = () => {
    snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
  };

  const handleUploadThumbnail = async e => {
    const { target } = e || {};
    const { files } = target || {};

    if (uploadedThumbnail && uploadedThumbnail.length > 0) {
      showUploadFailAlert();

      return;
    }

    try {
      const res = await uploadThumbnail({ variables: { resourceId } });

      if (res && res.data && res.data.initThumbnailUpload.success) {
        const uploadUrl = res.data.initThumbnailUpload.url;

        if (files && files[0]) {
          try {
            setLoadingUpload({ ...loadingUpload, thumbnail: true });
            const formData = new FormData();
            formData.append("file", files[0]);

            const response = await axios.post(`${uploadUrl}`, formData);

            if (
              response &&
              response.data &&
              response.data.success &&
              response.status === 200
            ) {
              const id = response.data.resourceId;

              const newFile = { resourceId: id, name: files[0].name };
              setUpdatedThumbnail(response.data.thumbnailImageUri);
              setUploadedThumbnail([newFile]);
            } else {
              showUploadFailAlert();
            }
            setLoadingUpload({ ...loadingUpload, original: false });
          } catch (uploadException) {
            setLoadingUpload({ ...loadingUpload, original: false });
            showUploadFailAlert();
          }
        } else {
          showUploadFailAlert();
        }
      } else {
        showUploadFailAlert();
      }
    } catch (error) {
      showUploadFailAlert();
    }
  };

  const handleAlternativeFileUpload = async e => {
    const { target } = e || {};
    const { files } = target || {};
    if (uploadedAlternative && uploadedAlternative.length > 0) {
      showUploadFailAlert();

      return;
    }

    const res = await uploadAlternative({
      variables: { resourceId, isAltFile: true },
    });

    if (res && res.data && res.data.initDocumentFileUpload) {
      const uploadUrl = res.data.initDocumentFileUpload.url;

      if (files && files[0]) {
        try {
          setLoadingUpload({ ...loadingUpload, alternative: true });
          const formData = new FormData();
          formData.append("file", files[0]);

          const response = await axios.post(`${uploadUrl}`, formData);

          if (
            response &&
            response.data &&
            response.data.success &&
            response.status === 200
          ) {
            const id = response.data.resourceId;

            const newFile = { resourceId: id, name: files[0].name };
            setUploadedAlternative([...uploadedAlternative, newFile]);
          } else {
            showUploadFailAlert();
          }
          setLoadingUpload({ ...loadingUpload, alternative: false });
        } catch (uploadException) {
          setLoadingUpload({ ...loadingUpload, alternative: false });
          showUploadFailAlert();
        }
      } else {
        logger.warn("An error could be happening in selecting files.");
      }
    }
  };

  return (
    <>
      <Grid container spacing={3} className={classes.customForm}>
        <Grid item xs={12} md={9}>
          <p className={classes.text}>
            <b>
              <FormattedMessage id="product.productInformation" />
            </b>
            <span>|</span>
            <FormattedMessage id="product.productInformationSupport" />
          </p>
          <FormSection
            formValues={formValues}
            handleCheckbox={handleCheckbox}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
            handleFormValues={handleFormValues}
            handleImageMetadata={handleImageMetadata}
            errors={errors}
            selectedProductType={selectedProductType}
            handleSelectProductType={handleSelectProductType}
            productTypeOptions={productTypeOptions}
            handleTags={handleTags}
            handleMetadataDate={handleMetadataDate}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={2}>
            <FileSection
              handleAlternativeFileUpload={handleAlternativeFileUpload}
              handleFileUpload={handleFileUpload}
              handleRemoveAlternative={handleRemoveAlternative}
              handleRemoveOriginal={handleRemoveOriginal}
              handleRemoveResources={handleRemoveResources}
              handleRemoveThumbnail={handleRemoveThumbnail}
              handleUploadFromSystem={handleUploadFromSystem}
              handleUploadThumbnail={handleUploadThumbnail}
              image={updatedThumbnail || image}
              initialFileNames={initialFileNames}
              loadingUpload={loadingUpload}
              selectedResources={selectedResources}
              setInitialFileNames={setInitialFileNames}
              uploadedOriginal={uploadedOriginal}
              uploadedThumbnail={uploadedThumbnail}
              uploadedAlternative={uploadedAlternative}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ArticleInfoPanel.defaultProps = {
  selectedProductType: "",
  formValues: {},
  folderId: null,
  initialFileNames: {},
};

ArticleInfoPanel.propTypes = {
  selectedProductType: PropTypes.string,
  errors: PropTypes.shape({ name: PropTypes.string }).isRequired,
  formValues: PropTypes.shape({
    active: PropTypes.string,
    activeFromDate: PropTypes.instanceOf(Date),
    activeUntilDate: PropTypes.instanceOf(Date),
    articleNo: PropTypes.string,
    description: PropTypes.string,
    detailedDescription: PropTypes.string,
    format: PropTypes.string,
    material: PropTypes.string,
    name: PropTypes.string,
    pageRange: PropTypes.number,
    resourceId: PropTypes.string,
    showFormat: PropTypes.bool,
    unit: PropTypes.number,
    weight: PropTypes.number,
  }),
  handleFormValues: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  setDraft: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  folderId: PropTypes.number,
  setInitialFileNames: PropTypes.func.isRequired,
  initialFileNames: PropTypes.shape({
    displayFileName: PropTypes.string,
    originalFileName: PropTypes.string,
    thumbnailFileName: PropTypes.string,
  }),
};

export default ArticleInfoPanel;

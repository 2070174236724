import { gql } from '@apollo/client';

const GET_NETWORK_SELECTOR_ITEMS = gql`
  query GetNetworkSelectorItems($companyId: Int!) {
    companyAdminContext {
      companyNetworks {
        networkSelectorItems(companyId: $companyId) {
          networkId
          name
        }
      }
    }
  }
`;

export default GET_NETWORK_SELECTOR_ITEMS;

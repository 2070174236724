import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Divider } from "@mui/material";
import HeaderTwo from "../../../News/NewsHeaders/HeaderTwo";
import NewsPost from "../../../News/NewsPost";

const useStyles = makeStyles(theme => ({
  divider: {
    background: props => props.dividerColor || "#dee2e6",
  },
  newsSub: {
    background: props => props.contentBG || theme.palette.background.paper,
    padding: 15,
  },
  newsSubItems: {
    borderLeft: props => `1px solid ${props.dividerColor || "#dee2e6"}`,
  },
}));

const TemplateTwo = ({ customStyles, newsMain, newsSub, onClickReadMore }) => {
  const classes = useStyles(customStyles);
  return (
    <Box>
      <HeaderTwo customStyles={customStyles} onClickReadMore={onClickReadMore } news={newsMain} />
      {newsMain.length > 0 && <Divider className={classes.divider} />}
      <Grid container className={classes.newsSub}>
        {newsSub.map(news => {
          return (
            <Grid item className={classes.newsSubItems} md={3} xs={12}>
              <NewsPost customStyles={customStyles} key={news.id} news={news} onClickReadMore={onClickReadMore}/>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TemplateTwo;

import { gql } from '@apollo/client';

const SiteRoleRightsFragment = gql`
  fragment SiteRoleRightsFields on SiteRoleRight {
    allowMetadataTagging
    attestFiles
    bookingView
    canAccessRequestForProposal
    canAddToBasket
    canAddToExistingOrder
    canAttestShoppingCart
    canChangeFolderIcons
    canManageBookingView
    canCreateNotificationForOtherUsers
    canCreatePackages
    canEditNews
    canEditOrderHistory
    canExportSiteTemplates
    canInviteUsers
    canLock
    canDownloadFilesAndFolders
    canManageConsent
    canManageConsentDocuments
    canManageSite
    canManageSiteRoles
    canManageSuppliers
    canManageWorkspaceDashboards
    canManageRequestForProposal
    canNotSeeOtherSuppliers
    canSeeAllOrderHistory
    canOnlySeeAssignedProducts
    canPrepareOrderingInfo
    canPublishProducts
    canUnlockForOthers
    canUploadFiles
    createTemplate
    detailedImageView
    directoryCRUD
    dynamicTemplates
    fileView
    images
    imageView
    notifications
    onlineDesignerDocs
    orderAttestType
    otherDocs
    productView
    shareFolders
    showOnlyOwnFiles
    staticTemplates
    stockTemplates
    supplierInWorkspace
    tenderFolders
    uploadFilesWithoutAttest
    subscriptionLevels {
      allowMetadataTagging
      attestFiles
      bookingView
      canAccessRequestForProposal
      canAddToBasket
      canAddToExistingOrder
      canAttestShoppingCart
      canChangeFolderIcons
      canCreateNotificationForOtherUsers
      canCreatePackages
      canEditOrderHistory
      canEditNews
      canInviteUsers
      canLock
      canDownloadFilesAndFolders
      canManageConsent
      canManageConsentDocuments
      canManageSite
      canManageSiteRoles
      canManageSuppliers
      canManageBookingView
      canManageWorkspaceDashboards
      canManageRequestForProposal
      canNotSeeOtherSuppliers
      canSeeAllOrderHistory
      canOnlySeeAssignedProducts
      canPublishProducts
      canUnlockForOthers
      canUploadFiles
      detailedImageView
      createTemplate
      directoryCRUD
      dynamicTemplates
      fileView
      imageView
      images
      notifications
      onlineDesignerDocs
      otherDocs
      productView
      shareFolders
      showOnlyOwnFiles
      staticTemplates
      stockTemplates
      supplierInWorkspace
      uploadFilesWithoutAttest
    }
  }
`;

export default SiteRoleRightsFragment;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import FolderTreeContainer from "./FolderTreeContainer";
import SubHeader from "./SubHeader";

const useStyles = makeStyles(() => ({
  folderTree: {
    overflowX: "auto",
    paddingTop: "0 !important",
  },
}));

const FolderTreeSection = ({
  setSelectedFolder,
  checkedFolders,
  foldersData,
  selectedFolders,
  setConfirmationModal,
  setFolderIdsUnchecked,
  openedNodes,
  folderPropertyFormValues,
  setFolderPropertyFormValues,
  propertyFormValues,
  setPropertyFormValues,
  setCheckedFolders,
  selectedFolder,
}) => {
  const classes = useStyles();

  const onFolderRowClick = folder => {
    setSelectedFolder(folder);
  };

  return (
    <Grid item xs={12} md={12} className={classes.folderTree}>
      <SubHeader />
      <FolderTreeContainer
        checkedFolders={checkedFolders}
        foldersData={foldersData}
        onFolderRowClick={onFolderRowClick}
        openedNodes={openedNodes}
        selectedFolder={selectedFolder}
        selectedFolders={selectedFolders}
        setConfirmationModal={setConfirmationModal}
        setFolderIdsUnchecked={setFolderIdsUnchecked}
        folderPropertyFormValues={folderPropertyFormValues}
        setFolderPropertyFormValues={setFolderPropertyFormValues}
        propertyFormValues={propertyFormValues}
        setPropertyFormValues={setPropertyFormValues}
        setCheckedFolders={setCheckedFolders}
      />
    </Grid>
  );
};

FolderTreeSection.defaultProps = {
  selectedFolders: [],
  openedNodes: [],
};

FolderTreeSection.propTypes = {
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  foldersData: PropTypes.arrayOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedFolders: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  setFolderIdsUnchecked: PropTypes.func.isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
};

export default memo(FolderTreeSection);

import { gql } from '@apollo/client';
import TaskCommenter from "./TaskCommenter";

const TaskComment = gql`
  fragment TaskComment on TaskCommentDTO {
    #__typename

    id
    comment
    timeStamp
    author { ...TaskCommenter }

  }
  ${TaskCommenter}
`;

export default TaskComment;

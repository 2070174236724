import React from "react";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
}));

const StockHitoryHeader = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th}>
          <FormattedMessage id="product.stockChange" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="product.timeOfChange" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="pickingList.department" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="pickingList.section" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="createAccount.stepOne" />
        </TableCell>
        <TableCell className={classes.th}>Order</TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="newsPost.comment" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default StockHitoryHeader;

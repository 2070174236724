import {
  DATA_COLUMN_TYPE,
  DATA_FIELD,
  INVOICES_TAB_TYPE,
  ORDERS_TAB_TYPE,
  QUOTATIONS_TAB_TYPE,
  QUOTE_REQUESTS_TAB_TYPE,
  SALES_BUTTON_TYPE,
  SALES_PAGE_TYPE,
  SUPPLIER_INVOICES_TAB_TYPE,
} from "../constant/salesPageTypes";

/**
 * Returns table column settings (field name, data type and translated label) for a specific page type and tab combination.
 */
const getTableColumnSettings = ({ intl, pageType, tabType }) => {
  let columnSettings = [];

  const labels = getTableHeaderLabels({ intl, pageType, tabType });
  const add = (dataField, align) => columnSettings.push({ ...DATA_COLUMN_TYPE[dataField], label: labels[dataField], align: align });

  switch (pageType) {
    case SALES_PAGE_TYPE.invoices:
      if (tabType === INVOICES_TAB_TYPE.unpaid || tabType === INVOICES_TAB_TYPE.all) {
        add(DATA_FIELD.documentNumber);
      }
      add(DATA_FIELD.customerNo);
      add(DATA_FIELD.customerName);
      add(DATA_FIELD.yourReference);
      add(DATA_FIELD.documentType);
      add(DATA_FIELD.category);
      add(DATA_FIELD.documentDate, "right");
      add(DATA_FIELD.dueDate, "right");
      add(DATA_FIELD.amountDue, "right");
      break;

    case SALES_PAGE_TYPE.orders:
      if (tabType === ORDERS_TAB_TYPE.inProgress || tabType === ORDERS_TAB_TYPE.invoiced) {
        add(DATA_FIELD.documentNumber);
      }
      add(DATA_FIELD.customerNo);
      add(DATA_FIELD.customerName);
      add(DATA_FIELD.yourReference);
      add(DATA_FIELD.amountDue, "right");
      add(DATA_FIELD.documentDate, "right");
      add(DATA_FIELD.dueDate, "right");
      break;

    case SALES_PAGE_TYPE.quotations:
      if (tabType === QUOTATIONS_TAB_TYPE.inProgress || tabType === QUOTATIONS_TAB_TYPE.converted) {
        add(DATA_FIELD.documentNumber);
      }
      add(DATA_FIELD.customerNo);
      add(DATA_FIELD.customerName);
      add(DATA_FIELD.yourReference);
      add(DATA_FIELD.amountDue, "right");
      add(DATA_FIELD.documentDate, "right");
      add(DATA_FIELD.dueDate, "right");
      break;

    case SALES_PAGE_TYPE.supplierInvoices:
      add(DATA_FIELD.documentNumber);
      add(DATA_FIELD.yourReference);
      add(DATA_FIELD.documentType);
      add(DATA_FIELD.category);
      add(DATA_FIELD.documentDate, "right");
      add(DATA_FIELD.dueDate, "right");
      add(DATA_FIELD.unpaidAmount, "right");
      add(DATA_FIELD.amountDue, "right");
      break;

    case SALES_PAGE_TYPE.quoteRequests:
      break;

    default:
      break;

  }

  return columnSettings;
};

/**
 * Returns translated data table header labels for a specific page type and tab combination.
 */
const getTableHeaderLabels = ({ intl, pageType, tabType }) => {
  let labels = {};
  console.log("** getTableHeaderLabels", { intl, pageType, tabType });
  switch (pageType) {
    case SALES_PAGE_TYPE.invoices:
      labels = {
        amountDue: "(amountDue)",
        category: translate(intl, "admin.sales.dataLabels.category"),
        customerName: translate(intl, "admin.sales.dataLabels.customerName"),
        customerNo: translate(intl, "admin.sales.dataLabels.customerNo"),
        documentDate: translate(intl, "admin.sales.dataLabels.invoiceDate"),
        documentNumber: translate(intl, "admin.sales.dataLabels.invoiceNo"),
        //documentStatus
        documentType: translate(intl, "admin.sales.dataLabels.type"),
        dueDate: translate(intl, "admin.sales.dataLabels.dueDate"),
        yourReference: translate(intl, "admin.sales.dataLabels.yourOrderReference"),
      };

        labels.amountDue = translate(intl, "admin.sales.dataLabels.total");

      break;
    case SALES_PAGE_TYPE.orders:
      labels = {
        amountDue: translate(intl, "admin.sales.dataLabels.amount"),
        //category
        customerName: translate(intl, "admin.sales.dataLabels.customerName"),
        customerNo: translate(intl, "admin.sales.dataLabels.customerNo"),
        documentDate: translate(intl, "admin.sales.dataLabels.orderDate"),
        documentNumber: translate(intl, "admin.sales.dataLabels.orderNo"),
        //documentStatus
        documentType: translate(intl, "admin.sales.dataLabels.type"),
        dueDate: "(dueDate)",
        //yourReference
      };
      if (tabType === ORDERS_TAB_TYPE.drafts || tabType === ORDERS_TAB_TYPE.inProgress) {
        labels.dueDate = translate(intl, "admin.sales.dataLabels.plannedDeliveryDate");
      } else if (tabType === ORDERS_TAB_TYPE.invoiced) {
        labels.dueDate = translate(intl, "admin.sales.dataLabels.deliveryDate");
      }
      break;
    case SALES_PAGE_TYPE.quotations:
    case SALES_PAGE_TYPE.quoteRequests:
      labels = {
        amountDue: translate(intl, "admin.sales.dataLabels.amount"),
        //category
        customerName: translate(intl, "admin.sales.dataLabels.customerName"),
        customerNo: translate(intl, "admin.sales.dataLabels.customerNo"),
        documentDate: translate(intl, "admin.sales.dataLabels.quoteDate"),
        documentNumber: translate(intl, "admin.sales.dataLabels.quoteNo"),
        documentStatus: translate(intl, "admin.sales.dataLabels.status"),
        //documentType
        dueDate: translate(intl, "admin.sales.dataLabels.validThrough"),
        //yourReference
      };
      break;
    case SALES_PAGE_TYPE.supplierInvoices:
      labels = {
        amountDue: translate(intl, "admin.sales.dataLabels.amount"),
        unpaidAmount: translate(intl, "admin.sales.dataLabels.unpaidAmount"),
        category: translate(intl, "admin.sales.dataLabels.category"),
        //customerName: translate(intl, "admin.sales.dataLabels.customerName"),
        //customerNo: translate(intl, "admin.sales.dataLabels.customerNo"),
        documentDate: translate(intl, "admin.sales.dataLabels.invoiceDate"),
        documentNumber: translate(intl, "admin.sales.dataLabels.invoiceNo"),
        //documentStatus
        documentType: translate(intl, "admin.sales.dataLabels.type"),
        dueDate: translate(intl, "admin.sales.dataLabels.dueDate"),
        yourReference: translate(intl, "admin.sales.dataLabels.yourOrderReference"),
      };


        labels.amountDue = translate(intl, "admin.sales.dataLabels.total");

      break;

    default:
      break;
  }
  console.log("** getTableHeaderLabels", { labels });
  return labels;
};

/**
 * Returns the document type for a specific page type.
 */
const getDocumentType = (pageType) => {
  switch (pageType) {
    case SALES_PAGE_TYPE.invoices: return "INVOICE";
    case SALES_PAGE_TYPE.orders: return "ORDER";
    case SALES_PAGE_TYPE.quotations: return "OFFER";
    case SALES_PAGE_TYPE.quoteRequests: return "REQUEST";
    case SALES_PAGE_TYPE.supplierInvoices: return "INVOICE";
    default: return pageType;
  }
};

/**
 * Returns translated button data for a specific page type.
 */
const getPageButtons = ({ intl, pageType }) => {
  switch (pageType) {
    case SALES_PAGE_TYPE.invoices:
      return [
        { label: translate(intl, "admin.sales.invoices.buttons.newInvoice"), type: SALES_BUTTON_TYPE.newInvoice },
        { label: translate(intl, "admin.sales.invoices.buttons.newCreditNote"), type: SALES_BUTTON_TYPE.newCreditNote, disabled: true },
        { label: translate(intl, "admin.sales.invoices.buttons.newRecurringInvoice"), type: SALES_BUTTON_TYPE.newRecurringInvoice, disabled: true },
      ];
    case SALES_PAGE_TYPE.orders:
      return [
        { label: translate(intl, "admin.sales.orders.buttons.newOrder"), type: SALES_BUTTON_TYPE.newOrder, disabled: true },
      ];
    case SALES_PAGE_TYPE.quotations:
      return [
        { label: translate(intl, "admin.sales.quotations.buttons.newQuote"), type: SALES_BUTTON_TYPE.newQuote, disabled: true },
      ];
    case SALES_PAGE_TYPE.quoteRequests:
      return [
        { label: translate(intl, "admin.sales.quoteRequests.buttons.newQuoteRequest"), type: SALES_BUTTON_TYPE.newQuoteRequest, disabled: true },
      ];
    default:
      return [];
  }
};

/**
 * Returns translated tab data for a specific page type.
 */
const getPageTabs = ({ intl, pageType }) => {
  switch (pageType) {
    case SALES_PAGE_TYPE.invoices:
      return [
        { label: translate(intl, "admin.sales.invoices.tabs.drafts"), type: INVOICES_TAB_TYPE.drafts },
        { label: translate(intl, "admin.sales.invoices.tabs.unpaid"), type: INVOICES_TAB_TYPE.unpaid },
        { label: translate(intl, "admin.sales.invoices.tabs.all"), type: INVOICES_TAB_TYPE.all },
      ];
    case SALES_PAGE_TYPE.orders:
      return [
        { label: translate(intl, "admin.sales.orders.tabs.drafts"), type: ORDERS_TAB_TYPE.drafts },
        { label: translate(intl, "admin.sales.orders.tabs.inProgress"), type: ORDERS_TAB_TYPE.inProgress },
        { label: translate(intl, "admin.sales.orders.tabs.invoiced"), type: ORDERS_TAB_TYPE.invoiced },
      ];
    case SALES_PAGE_TYPE.quotations:
      return [
        { label: translate(intl, "admin.sales.quotations.tabs.drafts"), type: QUOTATIONS_TAB_TYPE.drafts },
        { label: translate(intl, "admin.sales.quotations.tabs.inProgress"), type: QUOTATIONS_TAB_TYPE.inProgress },
        { label: translate(intl, "admin.sales.quotations.tabs.converted"), type: QUOTATIONS_TAB_TYPE.converted },
      ];
    case SALES_PAGE_TYPE.quoteRequests:
      return [
        { label: translate(intl, "admin.sales.quoteRequests.tabs.drafts"), type: QUOTE_REQUESTS_TAB_TYPE.drafts },
        { label: translate(intl, "admin.sales.quoteRequests.tabs.inProgress"), type: QUOTE_REQUESTS_TAB_TYPE.inProgress },
        { label: translate(intl, "admin.sales.quoteRequests.tabs.converted"), type: QUOTE_REQUESTS_TAB_TYPE.converted },
      ];
    case SALES_PAGE_TYPE.supplierInvoices:
      return [
        { label: translate(intl, "admin.billing.billspayments.supplierInvoices.tabs.unpaid"), type: SUPPLIER_INVOICES_TAB_TYPE.unpaid },
        { label: translate(intl, "admin.billing.billspayments.supplierInvoices.tabs.completed"), type: SUPPLIER_INVOICES_TAB_TYPE.completed },
      ];
    default:
      return [];
  }
};

/**
 * Returns translated page title for a specific page type.
 */
const getPageTitle = ({ intl, pageType }) => {
  switch (pageType) {
    case SALES_PAGE_TYPE.invoices: return translate(intl, "admin.sales.invoices");
    case SALES_PAGE_TYPE.orders: return translate(intl, "admin.sales.orders");
    case SALES_PAGE_TYPE.quotations: return translate(intl, "admin.sales.quotations");
    case SALES_PAGE_TYPE.quoteRequests: return translate(intl, "admin.sales.quoteRequests");
    case SALES_PAGE_TYPE.supplierInvoices: return translate(intl, "admin.billing.billspayments");
    default: return pageType;
  }
};

const translate = (intl, pageKey) => !!pageKey && intl?.formatMessage({ id: pageKey });

export { getDocumentType, getPageButtons, getPageTabs, getPageTitle, getTableColumnSettings };

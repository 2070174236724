export const fieldTypes = intl => {
  const types = [
    {
      label: intl.formatMessage({
        id: "editProduct.text",
      }),
      value: "TEXT",
    },
    {
      label: intl.formatMessage({
        id: "editProduct.image",
      }),
      value: "IMAGE",
    },
    {
      label: intl.formatMessage({
        id: "editProduct.dropdownText",
      }),
      value: "DROPDOWN_TEXT",
    },
    {
      label: intl.formatMessage({
        id: "editProduct.dropdownImage",
      }),
      value: "DROPDOWN_IMAGE",
    },
    {
      label: intl.formatMessage({
        id: "editProduct.textarea",
      }),
      value: "TEXT_AREA",
    },
    {
      label: intl.formatMessage({
        id: "editProduct.checkbox",
      }),
      value: "CHECKBOX",
    },
  ];

  return types;
};

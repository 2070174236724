import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TablePagination } from "@mui/material";

const Pagination = ({
  hideIfEmpty,
  onChange,
  onPageChange,
  page,
  perPage,
  totalCount,
}) => {
  const rowsPerPageOptions = [25, 50, 100, 200, 400, 800];

  const renderLabelDisplayedRows = ({ from, to, count, page }) => (
    <FormattedMessage id="pagination.displayedItems" values={{ from, to, count }} />
  );

  return !(hideIfEmpty && totalCount === 0) && (
    <TablePagination
      component="div"
      count={totalCount || 0}
      labelDisplayedRows={renderLabelDisplayedRows}
      labelRowsPerPage={<FormattedMessage id="pagination.itemsPerPage" />}
      onPageChange={onPageChange}
      onRowsPerPageChange={onChange}
      page={page}
      rowsPerPage={perPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

Pagination.defaultProps = {
  hideIfEmpty: false,
};

Pagination.propTypes = {
  hideIfEmpty: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default Pagination;

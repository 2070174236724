import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  tabWrap: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  badge: {
    backgroundColor: "#F1C04F",
    borderRadius: "50%",
    color: "black",
    fontWeight: "600",
    padding: "0 7px",
    fontSize: 10,
    marginRight: 5,
    marginBottom: 0,
  },
  label: {
    fontSize: 10,
    fontWeight: "600",
    textTransform: "uppercase",
    color: "#754E0B",
  },
  btnRemove: {
    width: "unset",
    minWidth: "unset",
    fontSize: 14,
    padding: 0,
    borderRadius: "50%",
    marginLeft: "auto",
    color: theme.palette.common.red,
    "& .MuiSvgIcon-root": {
      width: "13px !important",
    },
  },
}));

const TabLabel = ({ index, handleRemoveReceiver }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.tabWrap}>
      <span className={classes.badge}>{index + 1}</span>
      <span className={classes.label}>
        {intl.formatMessage({
          id: "monitoring.recipient",
        })}
      </span>
      <Button
        className={classes.btnRemove}
        onClick={() => handleRemoveReceiver(index)}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

TabLabel.propTypes = {
  index: PropTypes.number.isRequired,
  handleRemoveReceiver: PropTypes.func.isRequired,
};

export default TabLabel;

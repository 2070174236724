import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress, Grid, Dialog } from "@mui/material";


import { isNull } from "lodash";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import AccountSettings from "./AccountSettings";
import {
  CREATE_ACCOUNT_SETTINGS,
  EDIT_ACCOUNT_SETTINGS,
} from "../../../../graphql/queries/CompanyAdminContext";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    "& .MuiCircularProgress-root": {
      margin: "auto",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  body: {
    padding: 0,
    minHeight: "75vh",
  },
}));

const AccountSettingsModal = ({ companyId, handleClose, open, selectedUserId }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [accountSettings, setAccountSettings] = useState(null);

  const [
    getDefaultAccountSettings,
    { data: defaultAccountSettings, loadingDefaultAccountSettings },
  ] = useLazyQuery(CREATE_ACCOUNT_SETTINGS);

  const [
    getUserAccountSettings,
    { data: userAccountSettings, loadingUserAccountSettings },
  ] = useLazyQuery(EDIT_ACCOUNT_SETTINGS);

  const contactInfoText = intl.formatMessage({
    id: "account.contactInformation",
  });

  useEffect(() => {
    if (open) {
      if (isNull(selectedUserId)) {
        getDefaultAccountSettings({ variables: { companyId } });
      } else {
        
        getUserAccountSettings({ variables: { userId: selectedUserId } });
      }
    }
  }, [open, companyId, selectedUserId]);

  useEffect(() => {
    if (
      defaultAccountSettings &&
      defaultAccountSettings.companyAdminContext &&
      defaultAccountSettings.companyAdminContext.users &&
      defaultAccountSettings.companyAdminContext.users.createAccountSettings
    ) {
      setAccountSettings(
        defaultAccountSettings.companyAdminContext.users.createAccountSettings
      );
    }
  }, [defaultAccountSettings]);

  useEffect(() => {
    if (
      userAccountSettings &&
      userAccountSettings.companyAdminContext &&
      userAccountSettings.companyAdminContext.users &&
      userAccountSettings.companyAdminContext.users.editAccountSettings
    ) {
      setAccountSettings(
        userAccountSettings.companyAdminContext.users.editAccountSettings
      );
    }
  }, [userAccountSettings]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
      fullScreen
    >
      <DraggableModalHead handleClose={handleClose} title={contactInfoText} />
      <DraggableModalBody customClass={classes.body}>
        {!accountSettings && (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress size={50} />
          </Grid>
        )}
        {accountSettings && (
          <AccountSettings
            handleClose={handleClose}
            selectedUserId={selectedUserId}
            settings={accountSettings}
          />
        )}
      </DraggableModalBody>
    </Dialog>
  );
};

AccountSettingsModal.defaultProps = {
  selectedUser: null,
};

AccountSettingsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedUser: PropTypes.number,
};

export default AccountSettingsModal;

import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import MessagingWidget from "../../../widgets/MessagingWidget";
import NewsSidebar from "./NewsSidebar";
import { FILEVIEW_RIGHT_COLUMN } from "../../../../actions/constants";

const { SHOW_NEWS, SHOW_MESSAGES } = FILEVIEW_RIGHT_COLUMN;

const useStyles = makeStyles(theme => ({
  root: {
    padding: "5px 32px 20px 32px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  side: {
    width: 300,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  middle: {
    margin: "0 20px",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      margin: 0,
      flex: "unset",
    },
  },
}));

const RightSidebar = ({siteId }) => {
  const classes = useStyles();
  const rightColumn = useSelector(state => state.ui.fileView.rightColumn);

  return (
    <Box className={classes.side}>
      {rightColumn === SHOW_MESSAGES && <MessagingWidget />}
      {rightColumn === SHOW_NEWS && <NewsSidebar siteId={siteId } />}
    </Box>
  );
};
export default RightSidebar;

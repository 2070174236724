import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Details from "./Details";
import Summary from "./Summary";
import SectionDetailsLink from "../common/SectionDetailsLink";
import { SUBSCRIPTION_LEVEL } from "../../../../constant/subscriptionLevel";

function createSummaryRow(subscriptionLevel, quantity) {
  return { subscriptionLevel, quantity };
}

const summaryRows = [
  createSummaryRow(SUBSCRIPTION_LEVEL.Unknown, 1),
  createSummaryRow("xxx", 2),
  createSummaryRow(SUBSCRIPTION_LEVEL.Free, 20),
  createSummaryRow(SUBSCRIPTION_LEVEL.Standard, 10),
  createSummaryRow(SUBSCRIPTION_LEVEL.Business, 22),
  createSummaryRow(SUBSCRIPTION_LEVEL.Pro, 99),
  createSummaryRow(SUBSCRIPTION_LEVEL.Premium, 50),
];

function createDetailsRow(username, isExternalUser, subscriptionLevel) {
  return { username, isExternalUser, subscriptionLevel };
}

const detailsRows = [
  createDetailsRow(
    "albert.andersson@uniqueue.se",
    false,
    SUBSCRIPTION_LEVEL.Pro
  ),
  createDetailsRow(
    "robert.kalmerydsson@uniqueue.se",
    true,
    SUBSCRIPTION_LEVEL.Premium
  ),
  createDetailsRow(
    "sune.svensson@uniqueue.se",
    false,
    SUBSCRIPTION_LEVEL.Business
  ),
  createDetailsRow(
    "torsten.tall@uniqueue.se",
    true,
    SUBSCRIPTION_LEVEL.Premium
  ),
];

const SubscriptionLevelsSection = ({ classes }) => {
  const [openDetails, setOpenDetails] = useState(false);

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <>
      <Typography className={classes.sectionTitle}>
        <FormattedMessage id="admin.subscriptions.subscriptionLevels.title" />
      </Typography>
      <Grid container item xs={12} md={4}>
        <Summary className={classes.sectionSummary} rows={summaryRows} />
      </Grid>
      <SectionDetailsLink
        className={classes.sectionDetailsLink}
        onClick={toggleOpenDetails}
      />
      <Grid container item xs={12}>
        <Details
          className={classes.sectionDetails}
          open={openDetails}
          rows={detailsRows}
        />
      </Grid>
    </>
  );
};

SubscriptionLevelsSection.defaultProps = {
  classes: {},
};

SubscriptionLevelsSection.propTypes = {
  classes: PropTypes.object,
};

export default SubscriptionLevelsSection;

import { gql } from '@apollo/client';

const CompanyFieldsFragment = gql`
  fragment CompanyFields on Company {
    name
    companyId
    memberId
  }
`;

export default CompanyFieldsFragment;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Box } from "@mui/material";
import { useSelector } from "react-redux";

//import { useGetSiteUsers } from "../../hooks";
import SitePersonsTable from "../../components/SitePersons/SitePersonsTable";

import SitePersonsHeader from "./SitePersonsHeader";
import SiteSelectModal from "../../components/common/SiteSelectModal";
import { useGetSiteRoles } from "../../hooks/useGetSiteRoles";

import { FILTER_NAMES } from "../../components/SitePersons/SitePersonsTable/filters";

const { NAME } = FILTER_NAMES;

const sitePersonsDummyData = [{
  name: "Clarette Horrell",
  email: "chorrell0@craigslist.org",
  title: "Systems Administrator I"
}, {
  name: "Stacy Lovelock",
  email: "slovelock1@mysql.com",
  title: "Nuclear Power Engineer"
}, {
  name: "Niccolo Elstub",
  email: "nelstub2@csmonitor.com",
  title: "Computer Systems Analyst I"
}, {
  name: "Mella Baldick",
  email: "mbaldick3@amazonaws.com",
  title: "Pharmacist"
}, {
  name: "Kerianne Charters",
  email: "kcharters4@mozilla.org",
  title: "Clinical Specialist"
}, {
  name: "Shena Di Lucia",
  email: "sdi5@addtoany.com",
  title: "Research Assistant II"
}, {
  name: "Lemar Bewsey",
  email: "lbewsey6@blog.com",
  title: "Pharmacist"
}, {
  name: "Teodor Mason",
  email: "tmason7@walmart.com",
  title: "Speech Pathologist"
}, {
  name: "Dodie Spellessy",
  email: "dspellessy8@constantcontact.com",
  title: "Food Chemist"
}, {
  name: "Kristian Lanning",
  email: "klanning9@who.int",
  title: "Marketing Assistant"
}];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },

  paperBody: {
    padding: theme.spacing(4),
  },
}));

const SitePersons = () => {
  const classes = useStyles();

  const selectedWorkspace = useSelector(
    state => state.ui.toggleWorkspaces.workspace
  );

  const [selectedSite, setSelectedSite] = useState(
    (selectedWorkspace && selectedWorkspace.id) || null
  );

  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState(
    (selectedWorkspace && selectedWorkspace.name) || null
  );

  const [filter] = useState("SHOW_ALL");

  //const siteUsers = useSelector(state => state.api.siteUsers.items);
  //const siteUsersLoading = useSelector(state => state.api.siteUsers.loading);
  const sites = useSelector(state => state.api.currentViewer.viewer.sites);
  const siteRoles = useSelector(state => state.api.siteUsers.siteRoles);

  const [sortfield, setSortfield] = useState(NAME);
  const [ascending, setAscending] = useState(true);
  const [siteRolesRights, setSiteRolesRights] = useState({});

  const [siteSelect, setSiteSelect] = useState(false);

  //const { execute: getSiteUsers } = useGetSiteUsers({
  //  siteId: selectedSite,
  //  filter,
  //  ascending,
  //  sortfield,
  //});

  const { execute: getSiteRoles } = useGetSiteRoles({
    siteId: selectedSite,
  });

  const [sitePersons, setSitePersons] = useState([]);

  const fetchSitePersons = () => {
    console.log("** fetchSitePersons", { sortfield, ascending });
    var sortedData = [...sitePersonsDummyData].sort((a, b) => ((ascending ? 1 : -1) * (a[sortfield] == b[sortfield] ? 0 : a[sortfield] < b[sortfield] ? -1 : 1)));
    setSitePersons(sortedData);
  };

  useEffect(() => {
    if (selectedSite) {
      const found = sites.find(site => site.id === selectedSite);
      const { siteRoleRights } = found;
      setSiteRolesRights(siteRoleRights);
      getSiteRoles();
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedSite) {
      //getSiteUsers();
      fetchSitePersons();
    }
  }, [selectedSite, filter, sortfield, ascending]);

  const handleSetSiteId = site => {
    const { siteId } = site;
    if (siteId !== 0) {
      setSelectedSite(siteId);
      setSelectedWorkspaceName(site.name);
      const siteRoleRights = sites.filter(site => site.id === siteId);
      setSiteRolesRights(siteRoleRights[0].siteRoleRights);
    }
  };

  const siteRoleOptions = siteRoles.map(x => {
    return { label: x.name, value: x.siteRoleId };
  });

  const [openAddPersonModal, setOpenAddPersonModal] = useState(false);

  const handleAddPersonClick = () => {
    console.log("** [SitePersons] handleAddPersonClick - TODO");
    setOpenAddPersonModal(true);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <SitePersonsHeader
          handleSetSiteId={handleSetSiteId}
          onAddPersonClick={handleAddPersonClick}
          selectedWorkspaceName={selectedWorkspaceName}
          siteRolesRights={siteRolesRights}
        />
        <Box className={classes.paperBody}>
          <SitePersonsTable
            fetchSiteUsers={fetchSitePersons}
            siteRoleOptions={siteRoleOptions}
            sortfield={sortfield}
            ascending={ascending}
            setSortfield={setSortfield}
            setAscending={setAscending}
            siteRolesRights={siteRolesRights}
            //siteUsers={siteUsers}
            siteUsers={sitePersons}
            siteUsersLoading={false}
            onRemoveSiteUser={() => { }}
            onUpdateSiteUser={() => { }}
          />
        </Box>
      </Paper>
      {siteSelect && (
        <SiteSelectModal open={siteSelect} setOpen={setSiteSelect} />
      )}

      {/*
      <InviteUsersModals
        open={openInviteModals}
        onCloseDialog={handleCloseInviteUser}
        selectedSite={selectedSite}
        siteRoleOptions={siteRoleOptions}
        getSiteUsers={getSiteUsers}
        setSiteSelect={setSiteSelect}
      />
      */}
    </div>
  );
};

export default SitePersons;

import React from "react";
import { Box, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  radioRoot: {
    padding: 5,
  },
}));

const ConvertOptions = ({ handleOptions }) => {
  const classes = useStyles();
  return (
    <Box>
      <RadioGroup aria-label="option" name="imageOptions">
        <FormControlLabel
          value="a"
          control={<Radio classes={{ root: classes.radioRoot }} />}
          label={<FormattedMessage id="imageBasket.customizeAllImages" />}
          onClick={() => handleOptions("all")}
        />
        <FormControlLabel
          value="male"
          control={<Radio classes={{ root: classes.radioRoot }} />}
          label={<FormattedMessage id="imageBasket.customizeSelectedImages" />}
          onClick={() => handleOptions("each")}
        />
      </RadioGroup>
    </Box>
  );
};

ConvertOptions.propTypes = {
  handleOptions: PropTypes.func.isRequired,
};

export default ConvertOptions;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Button, IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import WorkspaceEditModal from "../common/WorkspaceEditModal";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
  td: {
    fontSize: 11,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12,
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const WorkspaceTable = () => {
  const classes = useStyles();

  const [openWorkspaceEdit, setOpenWorkspaceEdit] = useState(false);
  const handleOpenWorkspaceEdit = () => {
    setOpenWorkspaceEdit(true);
  };
  const handleCloseWorkspaceEdit = () => {
    setOpenWorkspaceEdit(false);
  };

  return (
    <div className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>
              <TableSortLabel>Benämning</TableSortLabel>
            </TableCell>
            <TableCell className={classes.th}>
              <TableSortLabel>Antal mottagare</TableSortLabel>
            </TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>
              <Button
                className={classes.link}
                onClick={handleOpenWorkspaceEdit}
              >
                Norrevos arbetsytor
              </Button>
            </TableCell>
            <TableCell className={classes.td}>1</TableCell>
            <TableCell className={classes.td} align="right">
              <IconButton className={classes.action}>
                <Icon className={clsx(["fa fa-trash", classes.iconTrash])} />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Button
                className={classes.link}
                onClick={handleOpenWorkspaceEdit}
              >
                Norr arbetsytor
              </Button>
            </TableCell>
            <TableCell className={classes.td}>1</TableCell>
            <TableCell className={classes.td} align="right">
              <IconButton className={classes.action}>
                <Icon className={clsx(["fa fa-trash", classes.iconTrash])} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <WorkspaceEditModal
        open={openWorkspaceEdit}
        handleClose={handleCloseWorkspaceEdit}
      />
    </div>
  );
};

export default WorkspaceTable;

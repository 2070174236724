import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Box, TextField } from "@mui/material";

const BioDescription = ({ accountSettings, setAccountSettings }) => {
  const { biography } = accountSettings;

  const handleChange = e => {
    const { name, value } = e.target;
    setAccountSettings({ ...accountSettings, [name]: value });
  };

  return (
    <Box display="flex">
        <TextField
          label={<FormattedMessage id="settings.biography" />}
          name="biography"
          onChange={handleChange}
          value={biography || ""}
          variant="standard"
          style={{ paddingRight: 5 }}
          size="small"
          margin="dense"
          fullWidth
        />
    </Box>
  );
};

BioDescription.defaultProps = {
  accountSettings: {},
};

BioDescription.propTypes = {
  accountSettings: PropTypes.shape({
    biography: PropTypes.string,
  }),
  setAccountSettings: PropTypes.func.isRequired,
};

export default BioDescription;

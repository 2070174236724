
import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, Box, DialogContent } from "@mui/material";

import ActionStrip from "./ActionStrip";
import AuthenticatorSetupContent from "./AuthenticatorSetupContent";

import { useSnackbar } from "../../../../hooks";
import useAuthenticatorSetup from "../../../../hooks/MFA/useAuthenticatorSetup";


const AuthenticatorSetupModal = ({ username, onSetupComplete, onSetupAbort, open }) => {

  const { secret,
    verifyloading,
    updateLoading,
    verifyResult,
    updateResult,
    verifyError,
    updateError,
    handleVerifySecret,
    handleUpdateSecret, } = useAuthenticatorSetup();


  const snackbar = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {

    if (verifyResult) {
      setActiveStep(2);
    }
  }, [verifyResult, verifyError]);

  useEffect(() => {

    if (updateResult) {
      snackbar.success("Tvåfaktors authentisering inställd");

      setActiveStep(0);
      onSetupComplete();
    }
    else if (updateError) {
      snackbar.error("Kunde inte ställa in tvåfaktorsauthentisering");
    }

  }, [updateResult, updateLoading, updateError])

  const [otp, setOtp] = useState("");

  const handleAbort = () => {
    setActiveStep(0);
    onSetupAbort();
  }

  const handleComplete = () => {
    handleUpdateSecret();
  };

  const handleVerify = () => {
    handleVerifySecret(otp);
  };

  return (open && (<Dialog open={open}>
    <DialogContent>

      <AuthenticatorSetupContent
        activeStep={activeStep}
        username={username}
        secret={secret}
        otp={otp}
        onOtpChange={setOtp}
      />

    </DialogContent>
    <DialogActions>

      <Box align="right" sx={{ padding: "16px 32px 16px 16px" }}>
        <ActionStrip
          onAbort={handleAbort}
          onComplete={handleComplete}
          onVerify={handleVerify}
          activeStep={activeStep}
          verifyloading={verifyloading}
          onSetActiveStep={setActiveStep} />
      </Box>

    </DialogActions>
  </Dialog>))
}

export default AuthenticatorSetupModal;
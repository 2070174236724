import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ADDRESS_FIELD_TYPE, PAYMENT_METHOD } from "../../../constant";
import PaymentMethodSelector from "./PaymentMethodSelector";
import CardDetails from "./CardDetails";
import InvoiceDetails from "./InvoiceDetails";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    borderRadius: 10,
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    "& .MuiOutlinedInput-root.Mui-focused .MuiSelect-outlined": {
      backgroundColor: "inherit",
    },
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 600,
  },
  headTitle: {
    fontWeight: "inherit",
  },
  headSubtitle: {
    fontWeight: "initial",
    opacity: 0.8,
  },
  body: {
    padding: 20,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
}));

const createAddressConfig = () => {
  let displayOrder = 0;
  var fields = [];
  const addField = (name, type) => {
    fields.push({
      displayOrder: ++displayOrder,
      label: "",
      name,
      placeholder: "",
      type,
    });
  };
  addField("address1", ADDRESS_FIELD_TYPE.Required);
  addField("address2", ADDRESS_FIELD_TYPE.Optional);
  addField("address3", ADDRESS_FIELD_TYPE.Optional);
  addField("address4", ADDRESS_FIELD_TYPE.Optional);
  addField("address5", ADDRESS_FIELD_TYPE.Optional);
  addField("city", ADDRESS_FIELD_TYPE.Required);
  addField("contact", ADDRESS_FIELD_TYPE.Required);
  addField("country", ADDRESS_FIELD_TYPE.Required);
  addField("customerId", ADDRESS_FIELD_TYPE.Optional);
  addField("email", ADDRESS_FIELD_TYPE.Required);
  addField("reference", ADDRESS_FIELD_TYPE.Required);
  addField("telephone", ADDRESS_FIELD_TYPE.Required);
  addField("zip", ADDRESS_FIELD_TYPE.Required);

  return fields;
};

const addressConfig = createAddressConfig();

const Payments = () => {
  const classes = useStyles();

  const lang = useSelector(state => state.ui.locale.lang);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceAddress, setInvoiceAddress] = useState({});

  const [disableSettingsEdit, setDisableSettingsEdit] = useState(false);
  const [
    disablePaymentMethodSelector,
    setDisablePaymentMethodSelector,
  ] = useState(false);

  useEffect(() => {
    setInvoiceAddress({ reference: "Waldo" });
  }, []);

  const handlePaymentMethodChange = ({ paymentMethod }) => {
    console.count("** handlePaymentMethodChange");
    console.log("**", { paymentMethod });
    setPaymentMethod(paymentMethod);
  };

  const handleCancel = () => {
    console.count("** handleCancel");
    setDisablePaymentMethodSelector(false);
  };

  const handleCardDetailsEdit = e => {
    console.count("** handleCardDetailsEdit");
    setDisablePaymentMethodSelector(true);
  };

  const handleCardDetailsSave = e => {
    console.count("** handleCardDetailsSave");
    setDisablePaymentMethodSelector(false);
  };

  const handleInvoiceDetailsEdit = e => {
    console.count("** handleInvoiceDetailsEdit");
    setDisablePaymentMethodSelector(true);
  };

  const handleInvoiceDetailsSave = ({ invoiceAddress }) => {
    console.count("** handleInvoiceDetailsSave");
    console.log("**", { invoiceAddress });
    setDisableSettingsEdit(true);
    setTimeout(() => {
      var temp = { ...invoiceAddress, reference: "Waldo's sister" };
      setInvoiceAddress(temp);
      setDisableSettingsEdit(false);
      setDisablePaymentMethodSelector(false);
    }, 2000);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          <FormattedMessage id="admin.payments.title" />
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Grid container sx={12} md={4}>
          <Grid container item>
            <PaymentMethodSelector
              disabled={disablePaymentMethodSelector}
              lang={lang}
              onChange={handlePaymentMethodChange}
              paymentMethod={paymentMethod}
            />
          </Grid>
          {paymentMethod && (
            <>
              <Grid container item xs={12} style={{ marginTop: "1.5em" }}>
                {paymentMethod === PAYMENT_METHOD.Invoice && (
                  <InvoiceDetails
                    addressConfig={addressConfig}
                    disabled={disableSettingsEdit}
                    invoiceAddress={invoiceAddress}
                    onCancel={handleCancel}
                    onEdit={handleInvoiceDetailsEdit}
                    onSave={handleInvoiceDetailsSave}
                  />
                )}
                {paymentMethod === PAYMENT_METHOD.Card && (
                  <CardDetails
                    disabled={disableSettingsEdit}
                    onCancel={handleCancel}
                    onEdit={handleCardDetailsEdit}
                    onSave={handleCardDetailsSave}
                  />
                )}
              </Grid>
            </>
          )}
          {!paymentMethod && (
            <Box marginTop="1.5em">
              <em>
                <FormattedMessage id="admin.payments.paymentMethodMissing" />
              </em>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Payments;

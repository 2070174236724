import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { FILE_VIEW } from "../../../constant/types";
import SortSelect from "../../common/FormControl/SelectSort";
import Pagination from "../../common/Pagination";
import AddMetaDataSection from "../../ProductsMainView/FileView/AddMetaDataSection";
import ProductViews from "./ProductViews";
import SearchResultsFor from "./SearchResultsFor";

const useStyles = makeStyles(theme => ({
  productViewHeader: {
    display: "block",
    alignItems: "center",
    padding: "0 10px",
    borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
  },
  paginationWrapper: {
    marginLeft: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      marginTop: 8,
      marginLeft: 0,
    },
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: 52,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
}));

const ProductsViewHeader = ({
  ascending,
  folderId,
  metaDataSectionVisible,
  onClickBack,
  page,
  perPage,
  searchQuery,
  setAscending,
  setOpenMetaDataModal,
  setPage,
  setPerPage,
  setSortfield,
  setView,
  siteRoleRights,
  sortfield,
  totalCount,
  view,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const showAddMetaDataSection = (view === FILE_VIEW) && metaDataSectionVisible && siteRoleRights.allowMetadataTagging;

  const sortOptions = [
    { label: intl.formatMessage({ id: "common.name" }), value: 1 },
    { label: intl.formatMessage({ id: "detailedSearch.articleNo" }), value: 2 },
    { label: intl.formatMessage({ id: "search.bestMatch" }), value: 3 },
  ];

  return view && (
    <div className={classes.productViewHeader}>
      <div className={classes.headerWrapper}>
        <SortSelect
          ascending={ascending}
          options={sortOptions}
          setAscending={setAscending}
          setSortfield={setSortfield}
          sortfield={sortfield}
        />
        {showAddMetaDataSection &&
          <AddMetaDataSection setOpenMetaDataModal={setOpenMetaDataModal} />
        }
        <SearchResultsFor
          folderId={folderId}
          onClickBack={onClickBack}
          searchQuery={searchQuery}
        />
        <div className={classes.paginationWrapper}>
          <Pagination
            hideIfEmpty
            onChange={setPerPage}
            onPageChange={setPage}
            page={page}
            perPage={perPage}
            totalCount={totalCount}
          />
        </div>
        <ProductViews
          onChange={newView => setView(newView)}
          view={view}
        />
      </div>
    </div>
  );
};

ProductsViewHeader.defaultProps = {
  folderId: null,
  searchResults: {},
  siteRoleRights: null,
  sortfield: "",
};

ProductsViewHeader.propTypes = {
  ascending: PropTypes.bool.isRequired,
  folderId: PropTypes.number,
  metaDataSectionVisible: PropTypes.bool.isRequired,
  onClickBack: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  searchQuery: PropTypes.string.isRequired,
  searchResults: PropTypes.shape({}),
  setAscending: PropTypes.func.isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSortfield: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  siteRoleRights: PropTypes.shape({
    allowMetadataTagging: PropTypes.bool,
    detailedImageView: PropTypes.bool,
    fileView: PropTypes.bool,
    imageView: PropTypes.bool,
    productView: PropTypes.bool,
  }),
  sortfield: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  view: PropTypes.string.isRequired,
};

export default ProductsViewHeader;

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GrayButton as CancelButton, PrimaryButton as SaveButton } from "../../common/Buttons";

const useStyles = makeStyles(theme => ({
  closeContainer: {
    backgroundColor: theme.palette.background.gray,
    borderRadius: "6px 0px 0px 6px",
    bottom: 20,
    color: theme.palette.common.white,
    position: "absolute",
    right: "calc(100% + 1px)",
    width: "max-content",
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
      borderRadius: "6px 6px 0px 0px",
      bottom: 70,
      left: 0,
    },
  },
  iconButtonIn: {
    minWidth: 40,
    padding: "8px 0px 8px 4px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  linkToSetting: {
    alignItems: "center",
    background: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.common.lightGrey}`,
    bottom: 0,
    height: 70,
    minWidth: "100%",
    paddingBottom: 12,
    paddingLeft: /*40*/ "1.5em",
    paddingTop: 12,
    position: "fixed",
    zIndex: 1,
  },
  linkText: {
    cursor: "pointer",
    display: "flex",
    "& svg": {
      marginRight: 4,
    },
    fontWeight: "inherit",
    lineHeight: "inherit",
    padding: 0,
    textTransform: "inherit",
  },
  linkSetting: {
    alignItems: "center",
    display: "flex",
    marginBottom: 8,
  },
}));

const SettingsLinkPanel = ({
  dashboardSettings,
  disabled,
  enableSave,
  onClose,
  onOpenDashboardSettings,
  onOpenManageDashboards,
  onSave,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const dashboardName =
    dashboardSettings?.name || intl.formatMessage({ id: "widget.dashboard" });

  const SettingsButton = ({ disabled, label, onClick }) => (
    <Box className={classes.linkSetting}>
      <Button
        size="small"
        className={classes.linkText}
        disabled={disabled}
        onClick={onClick}
      >
        <SettingsIcon />
        {label} »
      </Button>
    </Box>
  );

  return (
    <Box className={classes.linkToSetting}>
      <SettingsButton
        disabled={disabled}
        label={
          <FormattedMessage
            id="widget.linkSetting"
            values={{ dashboardName }}
          />
        }
        onClick={onOpenDashboardSettings}
      />
      <SettingsButton
        disabled={true /* TODO: ManageDashboards */}
        label={<FormattedMessage id="widget.manageDashboards" />}
        onClick={onOpenManageDashboards}
      />
      <Box className={classes.closeContainer}>
        {enableSave && (
          <>
            <SaveButton marginLeft={4} marginRight={4} onClick={onSave}>
              <FormattedMessage id="btn.save" />
            </SaveButton>
            <CancelButton marginLeft={4} marginRight={-4} onClick={onClose}>
              <FormattedMessage id="btn.cancel" />
            </CancelButton>
          </>
        )}
        <Button
          aria-label="SettingsIcon"
          className={classes.iconButtonIn}
          onClick={onClose}
          size="small"
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default SettingsLinkPanel;

import React from "react";
import { FormattedMessage } from "react-intl";
import AddressHeaderTitle from "./AddressHeaderTitle";

const PostalAddressHeader = () => {
  return (
    <AddressHeaderTitle>
      <FormattedMessage id="cart.addressPostal" />
    </AddressHeaderTitle>
  );
};

PostalAddressHeader.propTypes = {};

export default PostalAddressHeader;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const REMOVE_EXTERNAL_USER = gql`
  mutation RemoveExternalUser($targetCompanyId: Int!, $userId: Int!) {
    companyAdminContext {
      externalUsers {
        removeExternalUser(targetCompanyId: $targetCompanyId, userId: $userId) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default REMOVE_EXTERNAL_USER;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPDATE_BOOKING_FOLDER_SETTINGS = gql`
  mutation UpdateBookingFolderSettings($settings: BookingFolderSettingsInput!) {
    bookingContext {
      updateBookingFolderSettings(settings: $settings) {
        success
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_BOOKING_FOLDER_SETTINGS;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($companyId: Int!, $details: WorkspaceDetailsInput!) {
    companyAdminContext {
      companyWorkspaces {
        createWorkspace(companyId: $companyId, details: $details) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_WORKSPACE;

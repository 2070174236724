import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    maxHeight: "95%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px",
    fontSize: 16,
    textAlign: "center",
    lineHeight: "24px",
    "& .MuiList-root": {
      margin: 0,
      padding: 0,
    },
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItem-dense": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));

const SubfoldersDiffSettingsModal = ({ open, handleClose, folders }) => {
  const classes = useStyles();
  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        <FormattedMessage id="monitoring.notAllSubfoldersCouldBeIncluded" />
        <List dense>
          {folders &&
            folders.map(x => (
              <ListItem key={x.folderId}>
                <ListItemText primary={x.name} />
              </ListItem>
            ))}
        </List>
      </DraggableModalBody>
    </DraggableModal>
  );
};

SubfoldersDiffSettingsModal.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      parentFolderId: PropTypes.number,
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentFolderId: PropTypes.number.isRequired,
        })
      ),
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SubfoldersDiffSettingsModal;

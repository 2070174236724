import { gql } from '@apollo/client';
import ShareListFragment from "../../fragments/ShareContext/ShareListDTO";
import ImageBankItemFragment from "../../fragments/ShareContext/ImageBankItem";
import ErrorResultFragment from "../../fragments/ErrorResult";

const CREATE_IMAGE_BANK_SHARE = gql`
  mutation CreateImageBankShare(
    $baseUrlForShare: String
    $usernames: String
    $comment: String
    $from: DateTime
    $until: DateTime
    $images: [OrderGroupImageBankItemDownloadItem]
  ) {
    shareContext {
      createImageBankShare(
        baseUrlForShare: $baseUrlForShare
        usernames: $usernames
        comment: $comment
        from: $from
        until: $until
        images: $images
      ) {
        success
        errorResult {
          ...ErrorResultFields
        }
        result {
          details {
            ...ShareListFragment
          }
          #imageDownloadOptions
          items {
            ...ImageBankItemFragment
          }
          __typename
        }
      }
    }
  }
  ${ShareListFragment}
  ${ImageBankItemFragment}
  ${ErrorResultFragment}
`;

export default CREATE_IMAGE_BANK_SHARE;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useGetListOfSuppliersForWorkspace } from "../../hooks";
import { getSiteId } from "../../helpers/selectors";
import SupplierContainer from "./Container";

const Suppliers = () => {
  const siteId = useSelector(state => getSiteId(state));
  const suppliersState = useSelector(state => state.api.suppliers);
  const { items, loading, error } = suppliersState;

  const { execute: getSuppliersList } = useGetListOfSuppliersForWorkspace({
    siteId,
  });

  useEffect(() => {
    getSuppliersList();
  }, [siteId]);

  return (
    <SupplierContainer
      loading={loading}
      items={items}
      siteId={siteId}
      error={error}
    />
  );
};

Suppliers.defaultProps = {};

Suppliers.propTypes = {};

export default Suppliers;

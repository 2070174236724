import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import AdminSidebar from "../../components/Administration/AdminSidebar";
import UserGroups from "../../components/Administration/UserGroups/index";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function UserGroupsPage() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <AdminSidebar />
        </Grid>
        <Grid item xs={12} md={10}>
          <UserGroups />
        </Grid>
      </Grid>
    </Box>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Button, LinearProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";

const useStyles = makeStyles(theme => ({
  btn: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },

  progressBar: {
    height: 7,
    overflow: "hidden",
    position: "relative",
    borderRadius: "100px",
  },
}));

const OneStreamSubmit = ({ progress, handleUploadFiles, isValid }) => {
  const classes = useStyles();
  return (
    <Box>
      {progress === 0 ? (
        <Button
          fullWidth
          className={classes.btn}
          onClick={() => handleUploadFiles()}
          disabled={!isValid}
        >
          <FormattedMessage id="btn.start" />
        </Button>
      ) : (
        <>
          <LinearProgress
            classes={{ root: classes.progressBar }}
            variant="determinate"
            value={progress}
          />
        </>
      )}
    </Box>
  );
};

OneStreamSubmit.defaultProps = {
  progress: 0,  
};

OneStreamSubmit.propTypes = {
  progress: PropTypes.number,
  handleUploadFiles: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
};

export default OneStreamSubmit;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { FormattedDate } from "../../common";

const MetaDataFields = ({ metaData }) => {
  const useStyles = makeStyles(() => ({
    productInfo: {
      margin: 0,
    },
    dt: {
      width: "40%",
      wordBreak: "break-all",
      verticalAlign: "middle",
      fontWeight: 600,
      display: "inline-block",
      "&::after": {
        content: '":"',
      },
      fontSize: 12,
    },
    dd: {
      wordBreak: "break-all",
      verticalAlign: "middle",
      display: "inline-block",
      marginBottom: 0,
      marginLeft: 5,
      fontSize: 12,
    },
  }));

  const classes = useStyles();

  const renderDate = ({ date }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.date" />
      </dt>
      <dd className={classes.dd}>
        <FormattedDate value={date} />
      </dd>
    </dl>
  );

  const renderContact = ({ contact }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.contact" />
      </dt>
      <dd className={classes.dd}>{contact}</dd>
    </dl>
  );

  const renderModel = ({ model }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.model" />
      </dt>
      <dd className={classes.dd}>{model}</dd>
    </dl>
  );

  const renderLocation = ({ location }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.location" />
      </dt>
      <dd className={classes.dd}>{location}</dd>
    </dl>
  );

  const renderOccupation = ({ occupation }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.occupation" />
      </dt>
      <dd className={classes.dd}>{occupation}</dd>
    </dl>
  );

  const renderPhotographer = ({ photographer }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.photographer" />
      </dt>
      <dd className={classes.dd}>{photographer}</dd>
    </dl>
  );

  const renderImageNumber = ({ imageNo }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.imageNo" />
      </dt>
      <dd className={classes.dd}>{imageNo}</dd>
    </dl>
  );

  const renderRoyaltyFree = ({ isFreeToUser }) => (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="metadata.royaltyFree" />
      </dt>
      <dd className={classes.dd}>
        {isFreeToUser ? (
          <FormattedMessage id="support.yes" />
        ) : (
          <FormattedMessage id="btn.no" />
        )}
      </dd>
    </dl>
  );

  const {
    date,
    contact,
    model,
    location,
    occupation,
    photographer,
    imageNo,
    isFreeToUser,
  } = metaData;

  return (
    <>
      {imageNo && renderImageNumber({ imageNo })}
      {contact && renderContact({ contact })}
      {date && renderDate({ date })}
      {location && renderLocation({ location })}
      {model && renderModel({ model })}
      {occupation && renderOccupation({ occupation })}
      {photographer && renderPhotographer({ photographer })}
      {isFreeToUser && renderRoyaltyFree({ isFreeToUser })}
    </>
  );
};

MetaDataFields.propTypes = {
  contact: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  occupation: PropTypes.string.isRequired,
  photographer: PropTypes.string.isRequired,
  imageNo: PropTypes.number.isRequired,
  isFreeToUser: PropTypes.bool.isRequired,
  metaData: PropTypes.shape({
    contact: PropTypes.string,
    date: PropTypes.string,
    model: PropTypes.string,
    location: PropTypes.string,
    occupation: PropTypes.string,
    photographer: PropTypes.string,
    imageNo: PropTypes.number,
    isFreeToUser: PropTypes.bool,
  }).isRequired,
};

export default MetaDataFields;

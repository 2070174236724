import { gql } from '@apollo/client';

const pickingListPrint = gql`
  query PickingListPrint($orderIds: [ID!]) {
    pickingList {
      pickingListPrint(orderIds: $orderIds)
    }
  }
`;

export default pickingListPrint;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const PICK_ORDER_ROWS = gql`
  mutation PickOrderRows(
    $pickingListOrdersItemId: ID!
    $orderHistoryRowItemIds: [ID!]
    $picked: Boolean!
    $signature: String
  ) {
    pickingListContext {
      pickOrderRows(
        pickingListOrdersItemId: $pickingListOrdersItemId
        orderHistoryRowItemIds: $orderHistoryRowItemIds
        picked: $picked
        signature: $signature
      ) {
        errorResult {
          ...ErrorResultFields
        }
        message
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default PICK_ORDER_ROWS;

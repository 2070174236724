import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const GET_FOLDER_FILE_UPLOAD_URL = gql`
  mutation GetFolderFileUploadURL($folderId: Int!) {
    initFileUpload(folderId: $folderId) {
      success
      errorResult {
        ...ErrorResultFields
      }
      url
    }
  }
  ${ErrorResultFragment}
`;

export default GET_FOLDER_FILE_UPLOAD_URL;

import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CustomSelect from "../CustomSelect";
import Loading from "../Loading";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
  labelWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
  },
  fieldWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
}));

const SelectInline = ({ label, options, value, onChange, loading }) => {
  const classes = useStyles();

  return (
    <Grid container mt={0.5} mb={0.5}>
      {label && (
        <Grid item xs={12} md={2} className={classes.labelWrap}>
          <span className={classes.label}>{label}</span>
        </Grid>
      )}
      <Grid item xs={12} md={10} className={classes.fieldWrap}>
        {loading && <Loading size={20} />}
        {!loading && (
          <FormControl variant="outlined" fullWidth>
            <CustomSelect
              value={value}
              options={options}
              onChange={onChange}
              // native
            />
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};

SelectInline.defaultProps = {
  loading: false,
};

SelectInline.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
};

export default SelectInline;

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";

const MethodListItem = ({ checked, description, disabled, label, name, onChange }) => {
  const handleChange = () => onChange?.({ name, checked: !checked });

  return (
    <ListItem sx={{ paddingBottom: 0, paddingTop: 0 }}>
      <ListItemButton sx={{ borderBottom: "1px solid #f3f3f3", borderRadius: 2 }}
        disabled={disabled}
        onClick={handleChange}
      >
        <ListItemText
          primary={
            <Typography sx={{ color: 'initial', fontSize: "15px", fontWeight: 500 }}>
              {label}
            </Typography>
          }
          secondary={description}
        />
        <FormControlLabel
          control={
            <Checkbox aria-label={label} edge="end" />
          }
          checked={checked}
          name={name}
          onChange={handleChange}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default MethodListItem;

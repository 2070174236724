import { gql } from '@apollo/client';

const DELETE_FILE = gql`
  mutation DeleteFile($productId: ID!, $file: String) {
    dynamicTemplateEditContext {
      deleteFile(productId: $productId, file: $file)
    }
  }
`;

export default DELETE_FILE;

import { gql } from '@apollo/client';

const CREATE_SITE_FOLDER_COLOR = gql`
  mutation CreateSiteFolderColor(
    $siteid: Int!
    $name: String
    $defaultColor: String
  ) {
    createSiteFolderColorSet(
      siteid: $siteid
      name: $name
      defaultColor: $defaultColor
    ) {
      defaultColor
      name
      siteFolderColorSetId
    }
  }
`;

export default CREATE_SITE_FOLDER_COLOR;

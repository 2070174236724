import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Icon, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  subTitle: {
    fontSize: 12,
    textTransform: "uppercase",
    marginRight: 10,
  },
  btnAddFolder: {
    padding: "2px 6px",
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnPlusIcon: {
    fontSize: 10,
    marginRight: 5,
    verticalAlign: "middle",
  },
  myWorkspaceWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
}));

const MyWorkspaceHeader = ({ handleOpen, createRootFolder }) => {
  const classes = useStyles();
  return (
    <Box className={classes.myWorkspaceWrap}>
      <Typography className={classes.subTitle}>
        <FormattedMessage id="workspace.my" />
      </Typography>
      <Button className={classes.btnAddFolder} onClick={createRootFolder}>
        <Icon className={clsx(["fa fa-plus", classes.btnPlusIcon])} />
        <FormattedMessage id="workspace.newFolder" />
      </Button>
    </Box>
  );
};

MyWorkspaceHeader.propTypes = {
  handleOpen: PropTypes.func.isRequired,
};

export default MyWorkspaceHeader;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles(() => ({
  modalFooter: {
    padding: "5px 20px",
    borderTop: "1px solid #ddd",
  },
}));

const ModalFooter = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.modalFooter} align="right">
      {children}
    </Box>
  );
};

ModalFooter.defaultProps = {};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalFooter;

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import RowItem from "./RowItem";
import FaIcon from "./FaIcon";

const SkillsText = ({ text, idx }) => {
  return (
    <Box style={{ marginRight: 10 }}>
      <Typography
        style={{
          border: "1px solid rgba(0,0,0,0.55)",
          padding: 7,
          textTransform: "uppercase",
          borderRadius: 20,
          color: "rgba(0,0,0,0.55)",
          fontSize: 10,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const ColumnTwo = ({ userDetails }) => {
  const intl = useIntl();
  const { skillTags = [], webLinks = [], visibilitySettings = {} } =
    userDetails || {};

  return (
    <>
      {Array.isArray(webLinks) && webLinks.length > 0 && (
        <>
          {webLinks.map(weblink => {
            if (typeof weblink === "object") {
              if (weblink.visibility && weblink.visibility !== "PUBLIC") {
                return null;
              }

              return (
                <RowItem
                  key={weblink.url}
                  title={weblink.url}
                  subTitle={<FormattedMessage id="settings.website" />}
                  leftIcon={<FaIcon icon="fas fa-link" />}
                />
              );
            }

            return (
              <RowItem
                key={weblink}
                title={weblink}
                subTitle={<FormattedMessage id="settings.website" />}
                leftIcon={<FaIcon icon="fas fa-link" />}
              />
            );
          })}
        </>
      )}

      <>
        {Array.isArray(skillTags) && skillTags.length > 0 && (
          <>
            {Object.keys(visibilitySettings).length > 0 &&
            visibilitySettings.skillTags !== "PUBLIC" ? null : (
              <Box style={{ marginTop: 30, padding: "0 0 0 45px" }}>
                <Box>
                  <Typography
                    style={{ textTransform: "uppercase", fontSize: 14 }}
                  >
                    {intl.formatMessage({
                      id: "account.skills",
                    })}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                  {skillTags.map((skill, idx) => {
                    return <SkillsText key={skill} idx={idx} text={skill} />;
                  })}
                </Box>
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
};

export default ColumnTwo;

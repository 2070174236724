import { gql } from '@apollo/client';
import CompanyFieldsFragment from "./CompanyFields";

const CompanyNetworkFieldsFragment = gql`
  fragment CompanyNetworkFields on CompanyNetwork {
    name
    networkId
    parentNetworkId
    childCompanies {
      ...CompanyFields
      ...CompanyRecursive
    }
  }

  fragment CompanyRecursive on Company {
    childCompanies {
      ...CompanyFields
      childCompanies {
        ...CompanyFields
        childCompanies {
          ...CompanyFields
          childCompanies {
            ...CompanyFields
            childCompanies {
              ...CompanyFields
              childCompanies {
                ...CompanyFields
              }
            }
          }
        }
      }
    }
  }

  ${CompanyFieldsFragment}
`;

export default CompanyNetworkFieldsFragment;

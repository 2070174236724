import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

import { Grid, Box, Button, FormControlLabel, Switch } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { IconTextLink } from "../../../common";
import ModalCompact from "../../../common/ModalCompact";
import ModalCompactHead from "../../../common/ModalCompact/ModalCompactHead";
import ModalCompactBody from "../../../common/ModalCompact/ModalCompactBody";
import ModalCompactActions from "../../../common/ModalCompact/ModalCompactActions";

import EmailEditor from 'react-email-editor';

//template to use when it load it can be empty
//import { template } from "./template";

const useStyles = makeStyles(theme => ({
  modalFooter: {
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 10,
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  btnYes: {
    padding: "5px 15px",
    fontSize: 11,
    color: theme.palette.primary.contrastText,
    marginLeft: 7,
    backgroundColor: "#88d1d2",
    "&:hover": {
      backgroundColor: "#88d1d2",
      opacity: 0.8,
    },
  },
  btnNo: {
    padding: "5px 15px",
    fontSize: 11,
    color: theme.palette.primary.contrastText,
    backgroundColor: "#b8b8b8",
    "&:hover": {
      backgroundColor: "#b8b8b8",
      opacity: 0.8,
    },
    marginRight: 7,
  },
}));

const NewsPageDetailsModal = ({
  open,
  handleClose,
  formValues,
  category,
  setNewsTypeModal,
  allowComments,
  setAllowComments,
  handleSaveNewsPost,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [actionsHeight, setActionsHeight] = useState(0);
  const actionsRef = useRef(null);

  //TODO: This useEffect doesn't seems to work. Investigate
  useEffect(() => {
    if (actionsRef.current) {
      setActionsHeight(actionsRef.current.clientHeight);
    }
  }, [open]);

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  }

  const internalHandleSaveNewsPost = () => {
    
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', html);
      handleSaveNewsPost(html);
    });
  }

  const handleBackButton = () => {
    setNewsTypeModal(true);
    handleClose();
  };

  const emailEditorRef = useRef(null);

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)

    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };


  return (
    <ModalCompact
      open={open}
      handleClose={handleClose}
      fullScreen={true}
    >
      <ModalCompactHead
        handleClose={handleClose}
        title={intl.formatMessage({
          id: "newsPost.addNewsPage",
        })}
      />
      <ModalCompactBody>
        <Grid container>
          <EmailEditor
            minHeight={`calc(100vh - ${actionsHeight}px - 120px)`}
            //projectId={9788}
            ref={emailEditorRef}
            onReady={onReady}
            onLoad={onLoad}
            options={{
              appearance: {
                theme: 'light', // Choose between 'light' or 'dark' theme
              },
              locale: 'sv',
              // customJS: [customJS],
              displayMode: "email",
              features: {
                stockImages: false,
              },
              id: "dy-email-editor",
              tools: {
                // Specify the tools you want to enable/disable
                header: {
                  enabled: true,
                },
                image: {
                  enabled: true,
                },
                // Add other tools as needed
                "custom#dy_recommendation": {
                  data: {
                    html:
                    `<div style='
                    border: 1px solid #ccc;
                    padding: 20px;'
                    >Custom dynamic HTML</div>`
                  },
                  position: 0,
                }
              },
              //blocks: {
                // Customize blocks as needed
              //},
              credits: {
                enabled: false, // Disable branding (copyright)
              },
              // Other options as needed
              // apiKey: 'YOUR_API_KEY', // Set your Unlayer API key here
            }}
          />
        </Grid>
      </ModalCompactBody>
      <ModalCompactActions ref={actionsRef}>
        <Grid container className={classes.modalFooter} justifyContent="flex-end">
          <Grid xs={12} md={6}>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    color="primary"
                    checked={allowComments}
                    onChange={e => setAllowComments(e.target.checked)}
                  />
                }
                label={intl.formatMessage({
                  id: "newsPost.commentSection",
                })}
                classes={{ label: classes.label }}
              />
            </Box>
          </Grid>
          <Grid xs={12} md={6} container justifyContent="flex-end" alignItems="center">
            <IconTextLink
              onClick={handleBackButton}
              iconClass="fa fa-long-arrow-alt-left"
              text={<FormattedMessage id="cart.linkBack" />}
              fontSize={14}
            />
            <Button              
              className={classes.btnYes}
              onClick={internalHandleSaveNewsPost}
            >
              {intl.formatMessage({
                id: "btn.save",
              })}
            </Button>
          </Grid>
        </Grid>
      </ModalCompactActions>
    </ModalCompact>
  );
};

NewsPageDetailsModal.defaultProps = {};

NewsPageDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NewsPageDetailsModal;

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";

import { getSiteId } from "../../../../../helpers/selectors";
import { FIND_TAGS_BY_SITE_ID } from "../../../../../graphql/queries";
import { SearchAutoComplete } from "../../../../common";
import { FormattedMessage } from "react-intl";

const ProductInfoTags = ({
  tags,
  setTags,
  siteId,
  label,
  shrink,
  customLabelClass,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [openAutoComplete, setAutoCompleteOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (searchTerm) {
      debouncedGetTagsSuggestions(searchTerm);
    }
  }, [searchTerm]);

  const [getTagsSuggestions, { loading }] = useLazyQuery(FIND_TAGS_BY_SITE_ID, {
    onCompleted: data => {
      const { tags: tagsData = {} } = data || {};
      const { findTagsBySiteId = [] } = tagsData;
      if (findTagsBySiteId.length === 0) {
        var suggestionListEmpty = { tagId: -1, tagName: "" };
        setSuggestions([suggestionListEmpty]);
      } else {
        setSuggestions(findTagsBySiteId);
      }
    },
    onError: () => {
      setSuggestions([]);
    },
  });

  const debouncedGetTagsSuggestions = useCallback(
    debounce(text => {
      getTagsSuggestions({ variables: { siteId, q: text } });
    }, 800),
    []
  );

  return (
    <Box>
      
      <SearchAutoComplete

        open={openAutoComplete}
        setOpen={n => setAutoCompleteOpen(true)}
        setClose={n => setAutoCompleteOpen(false)}

        placeholder={""}
        onChange={e => setSearchTerm(e.target?.value || "")}
        options={suggestions}
        loading={loading}
        setValue={setTags}
        value={tags}
        label={<FormattedMessage id="product.tagging" />}
        required={false}
        multiple
        data-tut="step3"
        renderValue={n => n.tagName}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    siteId: getSiteId(state),
  };
};

ProductInfoTags.defaultProps = {
  siteId: null,
};

ProductInfoTags.propTypes = {
  setTags: PropTypes.func.isRequired,
  siteId: PropTypes.number,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.number,
      tagName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default connect(mapStateToProps)(ProductInfoTags);

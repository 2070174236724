import { handleActions } from "redux-actions";
import {
  setSiteFolders,
  setSiteFoldersLoading,
  setSiteFoldersError,
} from "../../../../actions";

const defaultState = {
  folders: [],
  loading: false,
  error: null,
};

export default handleActions(
  {
    [setSiteFolders]: (state, { payload }) => {
      const { folders } = payload;
      return {
        ...state,
        folders,
      };
    },
    [setSiteFoldersLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        loading,
      };
    },
    [setSiteFoldersError]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        error,
      };
    },
  },
  defaultState
);

import { handleActions } from "redux-actions";
import { removeWidget } from "../../../../actions";

const defaultState = {
  widgets: [
    { id: 1, title: "graph 1", value: "This is the graph 1" },
    { id: 2, title: "graph 2", value: "This is the graph 2" },
    { id: 3, title: "graph 3", value: "This is the graph 3" }
  ]
};

export default handleActions(
  {
    [removeWidget]: (state, { payload }) => {
      const id = payload.id;
      return {
        ...state,
        widgets: state.widgets.filter(widget => widget.id !== id)
      };
    }
  },
  defaultState
);

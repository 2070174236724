import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/tables";

const Details = ({ className, open, rows }) => {
  return (
    open && (
      <TableContainer className={className}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.modules.module" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.subscriptions.modules.username" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.module}>
                <TableCell>{row.module}</TableCell>
                <TableCell>{row.username}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

Details.defaultProps = {
  className: "",
  open: false,
  rows: [],
};

Details.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      module: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
};

export default Details;

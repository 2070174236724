import { gql } from '@apollo/client';

const DELETE_MONITORING_ITEM = gql`
  mutation DeleteMonitoringItem($monitoringItemId: Int!) {
    monitorContext {
      deleteMonitoringItem(monitoringItemId: $monitoringItemId)
    }
  }
`;

export default DELETE_MONITORING_ITEM;

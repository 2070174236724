import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, ListItem, ListItemSecondaryAction, Stack } from "@mui/material";
import { TextField } from "../../common";

const RepositioningButton = ({
  disabled,
  IconComponent,
  onClick,
  size = "20px",
}) => (
  <IconButton
    disabled={disabled}
    onClick={onClick}
    sx={{ height: size, width: size }}
  >
    <IconComponent sx={{ fontSize: size }} />
  </IconButton>
);

const StatusColumnListItem = ({
  index,
  isFirstItem,
  isLastItem,
  onDelete,
  onMove,
  onNameChange,
  onSelectColorPicker,
  status,
}) => {
  const handleDelete = () => onDelete?.(index);
  const handleMove = (direction) => onMove?.(index, direction);
  const handleNameChange = (e) => onNameChange?.(index, e.target.value);
  const handleSelectColorPicker = (e) => onSelectColorPicker?.(e, index);

  return (
    <ListItem
      alignItems="center"
      key={index}
      sx={{ paddingLeft: 0 }}
    >
      <Stack direction="column">
        <RepositioningButton
          disabled={isFirstItem}
          IconComponent={KeyboardArrowUpIcon}
          onClick={() => handleMove("up")}
        />
        <RepositioningButton
          disabled={isLastItem}
          IconComponent={KeyboardArrowDownIcon}
          onClick={() => handleMove("down")}
        />
      </Stack>
      <TextField
        onChange={handleNameChange}
        sx={{ marginRight: 2, marginTop: 0 }}
        value={status.name}
      />
      <Box
        onClick={handleSelectColorPicker}
        sx={{ backgroundColor: status.color, width: 16, height: 16, border: "solid black 2px", padding: 0, cursor: "pointer" }}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default StatusColumnListItem;

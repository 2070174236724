import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CookiesSectionLink,
  PrivacyPolicySectionLink,
  TermsOfUseSectionLink,
} from "../../components/TermsOfUse";
import * as Icons from "./Icons";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialogActions-root": {
      padding: "8px 24px 20px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 36,
    },
    "& a": {
      color: theme.palette.common.link,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.text.primary,
        textDecoration: "underline",
      },
    },
  },
  termsOfUseLinks: {
    flex: "auto",
    paddingBottom: 2,
  },
}));

function Item({ icon, text }) {
  return (
    <ListItem>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ListItem>
  );
}

/**
 * A modal dialog for viewing, accepting, or rejecting terms of use.
 *
 * @param {object} param0
 * @param {boolean} param0.disabled Disables accept button when set to true.
 * @param {function} param0.onAccept Is called when the user accepts the terms.
 * @param {function} param0.onReject Is called when the user rejects the terms.
 * @param {boolean} param0.open Displays the dialog if true.
 */
const TermsOfUseDialog = ({ disabled, onAccept, onReject, open }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={open}>
      <DialogContent>
        <Box mb={2}>
          <Typography component="h5" variant="h5">
            <FormattedMessage id="termsOfUseDialog.header" />
          </Typography>
        </Box>
        <Box>
          <FormattedMessage
            id="termsOfUseDialog.section_1.headerTemplate"
            values={{
              cookies: (
                <CookiesSectionLink>
                  <FormattedMessage id="termsOfUseDialog.cookies" />
                </CookiesSectionLink>
              ),
            }}
          />
        </Box>
        <List dense>
          <Item
            icon={<Icons.ShieldOutlinedIcon />}
            text={<FormattedMessage id="termsOfUseDialog.section_1.item_1" />}
          />
          <Item
            icon={<Icons.WarningAmberOutlinedIcon />}
            text={<FormattedMessage id="termsOfUseDialog.section_1.item_2" />}
          />
          <Item
            icon={<Icons.BarChartOutlinedIcon />}
            text={<FormattedMessage id="termsOfUseDialog.section_1.item_3" />}
          />
        </List>
        <Box>
          <FormattedMessage
            id="termsOfUseDialog.section_2.headerTemplate"
            values={{
              accept: <FormattedMessage id="btn.accept" />,
              cookies: <FormattedMessage id="termsOfUseDialog.cookies" />,
            }}
          />
        </Box>
        <List dense>
          <Item
            icon={<Icons.TrendingUpOutlinedIcon />}
            text={<FormattedMessage id="termsOfUseDialog.section_2.item_1" />}
          />
          <Item
            icon={<Icons.BarChartOutlinedIcon />}
            text={<FormattedMessage id="termsOfUseDialog.section_2.item_2" />}
          />
          <Item
            icon={<Icons.AutoAwesomeOutlinedIcon />}
            text={<FormattedMessage id="termsOfUseDialog.section_2.item_3" />}
          />
          <Item
            icon={<Icons.LocalOfferOutlinedIcon />}
            text={<FormattedMessage id="termsOfUseDialog.section_2.item_4" />}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Box className={classes.termsOfUseLinks} component="div">
          <PrivacyPolicySectionLink>
            <FormattedMessage id="termsOfUse.sectionTitle.privacyPolicy" />
          </PrivacyPolicySectionLink>
          {" | "}
          <TermsOfUseSectionLink>
            <FormattedMessage id="termsOfUse.sectionTitle.termsOfUse" />
          </TermsOfUseSectionLink>
          {" | "}
          <CookiesSectionLink>
            <FormattedMessage id="termsOfUse.sectionTitle.cookies" />
          </CookiesSectionLink>
        </Box>
        <Box>
          <Button
            color="primary"
            disabled={disabled}
            onClick={onAccept}
            variant="contained"
          >
            <FormattedMessage id="btn.accept" />
          </Button>
        </Box>
        {!!onReject && (
          <Box>
            <Button color="secondary" onClick={onReject} variant="outlined">
              <FormattedMessage id="btn.cancel" />
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

TermsOfUseDialog.defaultProps = {
  disabled: false,
  onReject: undefined,
};

TermsOfUseDialog.propTypes = {
  disabled: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default TermsOfUseDialog;

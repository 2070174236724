import { handleActions } from "redux-actions";
import {
  cartInitialize,
  cartAddItems,
  cartAddItem,
  cartUpdateItem,
  cartRemoveItem,
} from "../../../../actions";

const defaultState = {
  cartItems: [],
  fileGroups: [],
  oneStreamGroups: [],
};

export default handleActions(
  {
    [cartInitialize]: (state, { payload }) => {
      const { items, fileGroups, oneStreamGroups } = payload;
      return {
        ...state,
        cartItems: items || state.cartItems,
        fileGroups: fileGroups || state.fileGroups,
        oneStreamGroups: oneStreamGroups || state.oneStreamGroups,
      };
    },
    [cartAddItems]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        cartItems: [...state.cartItems, ...items],
      };
    },
    [cartAddItem]: (state, { payload }) => {
      const { item } = payload;
      return {
        ...state,
        cartItems: [...state.cartItems, item],
      };
    },
    [cartUpdateItem]: (state, { payload }) => {
      const { item, index } = payload;
      const newCartItems = state.cartItems.map((cartItem, cartItemIndex) => {
        if (index !== cartItemIndex) {
          return cartItem;
        }
        return item;
      });
      return {
        ...state,
        cartItems: [...newCartItems],
      };
    },
    [cartRemoveItem]: (state, { payload }) => {
      const { index } = payload;
      const { cartItems } = state;
      cartItems.splice(index, 1);
      const newCartItems = [...cartItems];
      return {
        ...state,
        cartItems: newCartItems,
      };
    },
  },
  defaultState
);

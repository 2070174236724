import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  Chip,
  Input,
} from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import CheckboxDefault from "../../../../common/FormControl/Checkbox";
import CustomTextField from "../../../../common/TextField";
import TextAreaFull from "../../../../common/FormControl/TextAreaFull";
import CustomSelect from "../../../../common/CustomSelect";
import PageTabPanel from "../../../../common/PageTabPanel";
import { getProductOptions, initialDelivery } from "./helpers";
import ProductSelect from "./ProductSelect";
import PricePanel from "../ProductInfo/PricePanel";
import {
  prisValidation,
  artikelInformationValidation,
  getBuyManualPrices,
  getSellManualPrices,
} from "../ProductInfo/helpers";
import { STOCK_PRODUCT } from "../../../../../constant/types";

const useStyles = makeStyles(theme => ({
  customForm: {
    marginBottom: 5,
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
      marginTop: 1,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
      fontSize: 10,
      padding: "9px 12px",
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
      fontSize: 10,
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
      padding: 0,
    },
    "& hr": {
      marginTop: 15,
      border: "none",
      borderBottom: "1px solid #ddd",
    },
    "& .MuiCheckbox-root": {
      padding: "4px 9px",
    },
    "& .MuiTextField-root": {
      marginTop: 0,
    },
  },
  text: {
    marginTop: 0,
    marginBottom: 5,
    fontSize: 12,
    color: theme.palette.component.productBoxColor,
  },
  child: {
    paddingLeft: 25,
  },
  label: {
    marginTop: 0,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  panelTitle: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "#888",
    "& span": {
      color: theme.palette.common.black,
      fontWeight: 600,
    },
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  box: {
    boxShadow: "0 3px 10px 0 rgba(0,0,0,0.01)",
    borderRadius: 4,
    padding: "10px 15px",
    backgroundColor: theme.palette.common.white,
  },
  boxLabel: {
    fontSize: 12,
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
    marginBottom: -10,
    marginLeft: 15,
  },
  antalBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    "& .MuiTextField-root": {
      marginTop: 0,
      width: 80,
      marginLeft: 10,
    },
  },
  btnWrap: {
    borderTop: "1px solid #ddd",
    paddingTop: 20,
  },
  btnMain: {
    padding: "11px 22px",
    marginLeft: 15,
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  chip: {
    margin: 2,
    fontSize: 10,
  },
  textArea: {
    marginBottom: 15,
  },
}));

const Delivery = ({
  formValues,
  setFormValues,
  booking,
  setBooking,
  selectedProductType,
  setSelectedProductType,
  handleSave,
  deliveryIsDraft,
  setDeliveryIsDraft,
  vatRates,
  errors,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const buyManualPrices = getBuyManualPrices(formValues);
  const sellManualPrices = getSellManualPrices(formValues);

  const productTypeOptions = getProductOptions({ intl });

  const [deliveryValues, setDeliveryValues] = useState(initialDelivery);
  const [selectedProductLabel, setSelectedProductLabel] = useState("");
  const [productDescription, setProducDescription] = useState("");
  const [siteSuppliersOptions, setSiteSupplierOptions] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [address, setAddress] = useState(null);
  const [openSelect, setOpenSelect] = useState(false);

  const productMessageToProductionStaff = intl.formatMessage({
    id: "product.productMessageToProductionStaff",
  });

  const additionalMessageToStaff = intl.formatMessage({
    id: "product.additionalMessageToTheProductionStaff",
  });

  const setDraft = () => {
    if (!deliveryIsDraft) {
      setDeliveryIsDraft(true);
    }
  };

  const handleBuyPricesInitialCost = value => {
    const initialCosts = parseFloat(value);

    const { buyPrices = {} } = formValues;

    const tempBuyPrices = { ...buyPrices, initialCosts };

    setFormValues({ ...formValues, buyPrices: tempBuyPrices });
    setDraft();
  };

  const handleAddBuyPricesManualPrice = () => {
    const defaultPrices = { price: 0.0, quantity: 1 };

    const tempManualPrices = [...buyManualPrices, defaultPrices];
    const { buyPrices = {} } = formValues;
    const tempBuyPrices = { ...buyPrices, manualPrices: tempManualPrices };

    setFormValues({ ...formValues, buyPrices: tempBuyPrices });
    setDraft();
  };

  const handleRemoveBuyPricesManualPrice = index => {
    const tempManualPrices = [...buyManualPrices];
    tempManualPrices.splice(index, 1);
    const { buyPrices = {} } = formValues;
    const tempBuyPrices = { ...buyPrices, manualPrices: tempManualPrices };

    setFormValues({ ...formValues, buyPrices: tempBuyPrices });
    setDraft();
  };

  const handleBuyManualPrice = (e, index) => {
    const { name, value } = e.target;
    const values = parseInt(value, 10);

    const tempManualPrices = getBuyManualPrices(formValues);

    tempManualPrices[index] = { ...tempManualPrices[index], [name]: values };
    const { buyPrices = {} } = formValues;
    const tempBuyPrices = { ...buyPrices, manualPrices: tempManualPrices };

    setFormValues({ ...formValues, buyPrices: tempBuyPrices });
    setDraft();
  };

  const handleSellPricesInitialCost = value => {
    const initialCosts = parseFloat(value);
    const { sellPrices = {} } = formValues;
    const tempSellPrices = { ...sellPrices, initialCosts };
    setFormValues({ ...formValues, sellPrices: tempSellPrices });
    setDraft();
  };

  const handleAddSellPricesManualPrice = () => {
    const defaultPrices = { price: 0.0, quantity: 1 };
    const tempManualPrices = [...sellManualPrices, defaultPrices];
    const { sellPrices = {} } = formValues;
    const tempSellPrices = { ...sellPrices, manualPrices: tempManualPrices };

    setFormValues({ ...formValues, sellPrices: tempSellPrices });
    setDraft();
  };

  const handleRemoveSellPricesManualPrice = index => {
    const tempManualPrices = [...sellManualPrices];
    tempManualPrices.splice(index, 1);
    const { sellPrices = {} } = formValues;
    const tempSellPrices = { ...sellPrices, manualPrices: tempManualPrices };

    setFormValues({ ...formValues, sellPrices: tempSellPrices });
    setDraft();
  };

  const handleSellManualPrice = (e, index) => {
    const { name, value } = e.target;
    const values = parseInt(value, 10);
    const tempManualPrices = [...sellManualPrices];
    tempManualPrices[index] = { ...tempManualPrices[index], [name]: values };
    const { sellPrices = {} } = formValues;
    const tempSellPrices = { ...sellPrices, manualPrices: tempManualPrices };

    setFormValues({ ...formValues, sellPrices: tempSellPrices });
    setDraft();
  };

  const handleVatRate = e => {
    const { value } = e.target;

    setFormValues({ ...formValues, vatRateId: value });
    setDraft();
  };

  const handleFormCheckbox = e => {
    const { checked, name } = e.target;
    setFormValues({ ...formValues, [name]: checked });
    setDraft();
  };

  const handleSiteSupplier = value => {
    const supplierIds = value.map(x => x.supplierId);

    setSelectedSuppliers(value);
    setFormValues({
      ...formValues,
      supplierIds,
    });
    setOpenSelect(false);
    setDraft();
  };

  const handleTextArea = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setDraft();
  };

  useEffect(() => {
    if (formValues.siteSuppliers && formValues.siteSuppliers.length > 0) {
      const options = formValues.siteSuppliers.map(x => {
        return {
          label: x.displayName,
          value: x,
          disabled: x.inactive,
        };
      });

      const suppliers = formValues.siteSuppliers.filter(x => x.selected);
      const selectedSupplierIds = suppliers.map(x => x.supplierId);
      setFormValues({
        ...formValues,
        supplierIds: selectedSupplierIds,
      });
      setSelectedSuppliers(suppliers);
      setSiteSupplierOptions(options);
    }
  }, [formValues.siteSuppliers]);

  const handleCheckbox = e => {
    const { name, checked } = e.target;
    if (
      selectedProductType === "BOOKABLE" ||
      selectedProductType === "DYNAMIC_PRODUCT" ||
      selectedProductType === "ON_DEMAND" ||
      selectedProductType === STOCK_PRODUCT
    ) {
      if (
        name === "showPrice" ||
        name === "allowFreeQuantity" ||
        name === "pricesInclusiveFreight"
      ) {
        setFormValues({ ...formValues, [name]: checked });
        return setDeliveryValues({
          ...initialDelivery,
          [name]: checked,
        });
      }
    } else if (selectedProductType === "IMAGE") {
      if (name === "orderShoppingCart") {
        return setDeliveryValues({
          ...initialDelivery,
          [name]: checked,
          shipToSupplier: true,
        });
      }

      if (name === "orderImageBasket") {
        setFormValues({ ...formValues, actionValue: "" });
        setAddress(null);
        return setDeliveryValues({
          ...initialDelivery,
          [name]: checked,
          onlyViewable: true,
        });
      }
    }
    setDeliveryValues({ ...deliveryValues, [name]: checked });
    setDraft();
  };

  const handleSelectProductType = e => {
    const { value } = e.target;
    setSelectedProductType(value);
    const selected = productTypeOptions.find(x => x.value === value);
    setSelectedProductLabel(selected.label);
    setDraft();
  };

  const handleBookingCheckbox = e => {
    const { checked } = e.target;

    setBooking({ ...booking, allowFreeBooking: checked });
    setDraft();
  };

  const handleBookingDays = e => {
    const { value } = e.target;
    const days = parseInt(value, 10);
    setBooking({ ...booking, bookingDays: days });
    setDraft();
  };

  useEffect(() => {
    if (
      selectedProductType === "DYNAMIC_PRODUCT" ||
      selectedProductType === STOCK_PRODUCT ||
      selectedProductType === "ON_DEMAND" ||
      selectedProductType === "KIT"
    ) {
      setDeliveryValues({
        ...initialDelivery,
        orderShoppingCart: true,
        shipToSupplier: true,
      });
    }

    if (selectedProductType === "IMAGE") {
      setDeliveryValues({
        ...initialDelivery,
        orderImageBasket: true,
        onlyViewable: true,
      });
    }

    if (selectedProductType === "BOOKABLE") {
      setDeliveryValues({
        ...initialDelivery,
        bookingCalendar: true,
        onlyViewable: true,
        shipToSupplier: true,
      });
    }

    if (selectedProductType === "ONLY_DOWNLOAD") {
      setDeliveryValues({
        ...initialDelivery,
        onlyDownloadable: true,
        downloadable: true,
      });
    }

    if (
      selectedProductType === "ONLY_DISPLAY" ||
      selectedProductType === "PRODUCT_CONTAINER"
    ) {
      setDeliveryValues({
        ...initialDelivery,
        onlyViewable: true,
      });
    }
  }, [selectedProductType]);

  useEffect(() => {
    if (selectedProductType === "ONLY_DISPLAY") {
      setProducDescription("delivery.onlyViewableDesc");
    }

    if (selectedProductType === "ONLY_DOWNLOAD") {
      setProducDescription("delivery.onlyDownlodableDesc");
    }

    if (selectedProductType === "PRODUCT_CONTAINER") {
      setProducDescription("delivery.productContainerDesc");
    }

    if (selectedProductType === "BOOKABLE") {
      setProducDescription("delivery.bookableDesc");
    }

    if (selectedProductType === STOCK_PRODUCT) {
      setProducDescription("delivery.stockProductDesc");
    }
    if (selectedProductType === "IMAGE") {
      setProducDescription("delivery.imageDesc");
    }
    if (selectedProductType === "ON_DEMAND") {
      setProducDescription("delivery.onDemandDesc");
    }
    if (selectedProductType === "DYNAMIC_PRODUCT") {
      setProducDescription("delivery.dynamicProductDesc");
    }
    if (selectedProductType === "KIT") {
      setProducDescription("delivery.kitDesc");
    }
  }, [selectedProductType]);

  return (
    <PageTabPanel spacing="3">
      <>
        {/* <ProductSelect
          productTypeOptions={productTypeOptions}
          selectedProductType={selectedProductType}
          handleSelectProductType={handleSelectProductType}
        />
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.panelTitle}>
              <FormattedMessage id="product.productSettingFor" />{" "}
              <span>{selectedProductLabel}</span>
            </Typography>
          </Grid>
        </Grid> */}
        <PricePanel
          buyManualPrices={buyManualPrices}
          sellManualPrices={sellManualPrices}
          formValues={formValues}
          handleAddBuyPricesManualPrice={handleAddBuyPricesManualPrice}
          handleBuyPricesInitialCost={handleBuyPricesInitialCost}
          handleBuyManualPrice={handleBuyManualPrice}
          handleSellPricesInitialCost={handleSellPricesInitialCost}
          handleSellManualPrice={handleSellManualPrice}
          handleAddSellPricesManualPrice={handleAddSellPricesManualPrice}
          handleCheckbox={handleCheckbox}
          handleVatRate={handleVatRate}
          vatRates={vatRates}
          errors={errors}
          handleRemoveBuyPricesManualPrice={handleRemoveBuyPricesManualPrice}
          handleRemoveSellPricesManualPrice={handleRemoveSellPricesManualPrice}
          selectedProductType={selectedProductType}
        />

        <Divider className={classes.divider} />

        <Grid container spacing={3} className={classes.customForm}>
          <Grid item xs={12} md={6}>
            <Box className={classes.box}>
              <Typography className={classes.text}>
                {productDescription && (
                  <FormattedMessage id={productDescription} />
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.customForm}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.boxLabel}>
              <FormattedMessage id="product.productOrderingChannel" />
            </Typography>

            <Box className={classes.box} pb={4}>
              <Box>
                <CheckboxDefault
                  label={
                    <FormattedMessage id="product.productBookingCalendar" />
                  }
                  name="bookingCalendar"
                  value={deliveryValues.bookingCalendar}
                  onChange={handleCheckbox}
                  disabled
                />
              </Box>
              <Box>
                <CheckboxDefault
                  label={
                    <FormattedMessage id="product.productOrderViaImageCart" />
                  }
                  name="orderImageBasket"
                  value={deliveryValues.orderImageBasket}
                  onChange={handleCheckbox}
                  disabled={selectedProductType !== "IMAGE"}
                />
              </Box>
              <Box>
                <CheckboxDefault
                  label={
                    <FormattedMessage id="product.productOrderViaShoppingCart" />
                  }
                  name="orderShoppingCart"
                  value={deliveryValues.orderShoppingCart}
                  onChange={handleCheckbox}
                  disabled={selectedProductType !== "IMAGE"}
                />
                <Box className={classes.child}>
                  <CheckboxDefault
                    label={
                      <FormattedMessage id="product.productSignatureAndAttestation" />
                    }
                    name="authentication"
                    value={deliveryValues.authentication}
                    onChange={handleCheckbox}
                    disabled={!deliveryValues.orderShoppingCart}
                  />
                  <Box className={classes.child}>
                    <CustomSelect
                      options={[
                        { label: "Attestering 1", value: 1 },
                        { label: "Attestering 2", value: 2 },
                      ]}
                      value={address}
                      disabled={!deliveryValues.authentication}
                      onChange={e => setAddress(e.target.value)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.boxLabel}>
              <FormattedMessage id="product.productWhenOrdering" />
            </Typography>
            <Box className={classes.box} pb={4}>
              <Box>
                <CheckboxDefault
                  label={
                    <FormattedMessage id="product.productOnlyBeDownloaded" />
                  }
                  name="onlyDownloadable"
                  value={deliveryValues.onlyDownloadable}
                  onChange={handleCheckbox}
                  disabled
                />
              </Box>
              <Box>
                <CheckboxDefault
                  label={
                    <FormattedMessage id="product.productOnlyBeDisplayed" />
                  }
                  name="onlyViewable"
                  value={deliveryValues.onlyViewable}
                  onChange={handleCheckbox}
                  disabled
                />
              </Box>
              <Box>
                <CheckboxDefault
                  label={
                    <FormattedMessage id="product.productBeSentToSupplier" />
                  }
                  name="shipToSupplier"
                  value={deliveryValues.shipToSupplier}
                  onChange={handleCheckbox}
                  disabled
                />
                <Box className={classes.child}>
                  {siteSuppliersOptions.length > 0 ? (
                    <CustomSelect
                      options={siteSuppliersOptions}
                      value={selectedSuppliers}
                      open={openSelect}
                      onChange={e => handleSiteSupplier(e.target.value)}
                      disabled={!deliveryValues.shipToSupplier}
                      input={<Input id="select-multiple-chip" />}
                      onOpen={() => setOpenSelect(true)}
                      renderValue={selected => (
                        <div>
                          {selected.map(value => {
                            return (
                              <Chip
                                key={value.supplierId}
                                label={value.displayName}
                                className={classes.chip}
                              />
                            );
                          })}
                        </div>
                      )}
                      multiple
                    />
                  ) : (
                    <>
                      <FormattedMessage id="product.noSuppliers" />
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.customForm}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.boxLabel}>
              <FormattedMessage id="product.productAddition" />
            </Typography>
            <Box className={classes.box} pb={4}>
              <Box>
                <CheckboxDefault
                  label={
                    <FormattedMessage id="product.productAttachmentsAvailable" />
                  }
                  name="availableAttachment"
                  value={deliveryValues.availableAttachment}
                  onChange={handleCheckbox}
                />
              </Box>
              <Box>
                <CheckboxDefault
                  label={<FormattedMessage id="product.productDownloadable" />}
                  name="allowDownload"
                  value={formValues.allowDownload}
                  onChange={handleFormCheckbox}
                />
              </Box>
              <Box mt={1}>
                {selectedProductType === "BOOKABLE" && (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={5}>
                      <Typography className={classes.label}>
                        Bokning antal dagar
                      </Typography>
                      <Typography className={classes.text}>
                        Antal dagar är minimum
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <CustomTextField
                        onChange={handleBookingDays}
                        value={booking.bookingDays}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <CheckboxDefault
                        label="Tillåt fritt val av dagar"
                        value={booking.allowFreeBooking}
                        onChange={handleBookingCheckbox}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.box}>
              <TextAreaFull
                name="extraFieldColors"
                placeholder={productMessageToProductionStaff}
                rows="5"
                rowsMax="5"
                className={classes.textArea}
                onChange={e => handleTextArea(e)}
                value={formValues.extraFieldColors}
              />
              <TextAreaFull
                name="extraFieldNotes"
                placeholder={additionalMessageToStaff}
                rows="5"
                rowsMax="5"
                className={classes.textArea}
                onChange={e => handleTextArea(e)}
                value={formValues.extraFieldNotes}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            {selectedProductType === STOCK_PRODUCT && (
              <Box className={classes.box}>
                <TextAreaFull
                  placeholder="Ange lagerplats"
                  rows="5"
                  rowsMax="5"
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </>
      <Grid item xs={12} align="right">
        <Button
          variant="contained"
          className={classes.btnMain}
          onClick={() => handleSave()}
        >
          <FormattedMessage id="btn.save" />
        </Button>
      </Grid>
    </PageTabPanel>
  );
};

Delivery.defaultProps = {
  formValues: {},
  booking: {},
  selectedProductType: "",
};

Delivery.propTypes = {
  formValues: PropTypes.shape({
    allowDownload: PropTypes.bool,
    supplierIds: PropTypes.arrayOf(PropTypes.number),
    siteSuppliers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setFormValues: PropTypes.func.isRequired,
  booking: PropTypes.shape({
    allowFreeBooking: PropTypes.bool,
    bookingDays: PropTypes.number,
  }),
  setBooking: PropTypes.func.isRequired,
  selectedProductType: PropTypes.string,
  setSelectedProductType: PropTypes.func.isRequired,
  deliveryIsDraft: PropTypes.bool.isRequired,
  setDeliveryIsDraft: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default Delivery;

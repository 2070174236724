import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import WarningImage from "../../assets/img/warning-icon.svg";
import { ClearButton, PrimaryButton } from "./Buttons";
import DraggableModal from "./DraggableModal";
import DraggableModalBody from "./DraggableModal/DraggableModalBody";
import DraggableModalHead from "./DraggableModal/DraggableModalHead";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  img: {
    marginBottom: 10,
    width: 40,
  },
  bodyContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
  },
}));

const ConfirmModal = ({
  cancelButtonText = <FormattedMessage id="common.cancel" />,
  confirmButtonText = <FormattedMessage id="common.continue" />,
  messageText,
  onCancel,
  onConfirm,
  open,
  titleText,
}) => {
  const classes = useStyles();

  const handleCancel = () => onCancel?.();
  const handleConfirm = () => onConfirm?.();

  return (
    <DraggableModal
      customClass={classes.modal}
      handleClose={handleCancel}
      open={open}
    >
      <DraggableModalHead handleClose={handleCancel} title={titleText} />
      <DraggableModalBody>
        <Box className={classes.bodyContainer}>
          <img alt="Warning" className={classes.img} src={WarningImage} />
          {messageText}
        </Box>
        <Box align="right" className={classes.modalFooter}>
          <ClearButton
            marginRight={15}
            onClick={handleCancel}
          >
            {cancelButtonText}
          </ClearButton>
          <PrimaryButton onClick={handleConfirm} >
            {confirmButtonText}
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmModal.default = {
  messageText: "",
  open: false,
  titleText: "",
};

ConfirmModal.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  messageText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirmCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool,
  titleText: PropTypes.string,
};

export default ConfirmModal;

import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  DOCUMENT_VIEW,
  FILE_VIEW,
  IMAGE_VIEW,
  PRODUCT_LIST_VIEW,
} from "../../constant/types";

import { getShowFileView, getShowProductView } from "../../helpers/get";

import DocumentView from "./DocumentView";
import FileView from "./FileView";
import ImageView from "./ImageView";
import ProductsListView from "./ProductListView/ProductsListView";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: 15,
  },
  wrapperEmpty: {
    marginBottom: theme.spacing(2),
    padding: "30px 0",
    boxShadow: "none",
    borderRadius: 0,
    textAlign: "center",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    marginTop: 10,
  },
}));

const ProductsView = ({
  siteId,
  ascending,
  cartAddItem,
  detailedSearchOpen,
  error,
  folderId,
  metaDataSectionVisible,
  offset,
  onDeepLinkResourcesFetched,
  openedNodes,
  openMetaDataModal,
  perPage,
  products,
  searchQuery,
  setFolderId,
  setMetaDataSectionVisible,
  setOpenMetaDataModal,
  setProductTotalCount,
  setSearchToInitialState,
  siteRoleRights,
  view,
}) => {
  const classes = useStyles();
  const showFileView = getShowFileView(siteRoleRights);
  const showProductView = getShowProductView(siteRoleRights);

  if (error && folderId) {
    return (
      <Box className={classes.wrapperEmpty}>
        <Typography align="center" color="primary">
          There has been an error!
        </Typography>
      </Box>
    );
  }

  const renderView = () => {
    switch (view) {
      case DOCUMENT_VIEW:
        return (
          <DocumentView products={products} siteRoleRights={siteRoleRights} />
        );
      case IMAGE_VIEW:
        return (
          <ImageView products={products} siteRoleRights={siteRoleRights} />
        );
      case FILE_VIEW:
        return (
          <FileView
            siteId={ siteId}
            ascending={ascending}
            detailedSearchOpen={detailedSearchOpen}
            folderId={folderId}
            offset={offset}
            openMetaDataModal={openMetaDataModal}
            perPage={perPage}
            setMetaDataSectionVisible={setMetaDataSectionVisible}
            metaDataSectionVisible={metaDataSectionVisible}
            setOpenMetaDataModal={setOpenMetaDataModal}
            showFileView={showFileView}
            siteRoleRights={siteRoleRights}
            openedNodes={openedNodes}
            setProductTotalCount={setProductTotalCount}
            onDeepLinkResourcesFetched={onDeepLinkResourcesFetched}
          />
        );
      case PRODUCT_LIST_VIEW:
      default:
        return (
          <ProductsListView
            products={products}
            folderId={folderId}
            cartAddItem={cartAddItem}
            showProductView={showProductView}
            detailedSearchOpen={detailedSearchOpen}
            setFolderId={setFolderId}
            setSearchToInitialState={setSearchToInitialState}
          />
        );
    }
  };

  return <>{renderView()}</>;
};

ProductsView.defaultProps = {
  folderId: null,
  error: null,
  detailedSearchOpen: null,
  siteRoleRights: null,
  openedNodes: [],
};

ProductsView.propTypes = {
  ascending: PropTypes.bool.isRequired,
  cartAddItem: PropTypes.func.isRequired,
  detailedSearchOpen: PropTypes.bool,
  error: PropTypes.string,
  folderId: PropTypes.number,
  offset: PropTypes.number.isRequired,
  openMetaDataModal: PropTypes.bool.isRequired,
  perPage: PropTypes.number.isRequired,
  products: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        thumbnailImageUri: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        articleNo: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  siteRoleRights: PropTypes.shape({
    fileView: PropTypes.bool,
    imageView: PropTypes.bool,
    productView: PropTypes.bool,
    detailedImageView: PropTypes.bool,
    allowMetadataTagging: PropTypes.bool,
  }),
  view: PropTypes.string.isRequired,
  metaDataSectionVisible: PropTypes.bool.isRequired,
  setMetaDataSectionVisible: PropTypes.func.isRequired,
  setFolderId: PropTypes.func.isRequired,
  searchResults: PropTypes.shape({}).isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchToInitialState: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  setProductTotalCount: PropTypes.func.isRequired,
};

export default ProductsView;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Box, Typography, Grid, IconButton, Icon } from "@mui/material";
import { PrimaryButton } from "../../../../common/Buttons";
import CustomTextField from "../../../../common/TextField";
import LeftSettings from "./LeftSettings";
import RightSettings from "./RightSettings";

const useStyles = makeStyles(() => ({
  settingsCont: {
    border: "1px solid #c4c4c4",
    borderRadius: 10,
    padding: 5,
    marginBottom: 15,
  },
  text: {
    fontSize: 12,
  },
  icon: {
    fontSize: 12,
  },
  iconButton: {
    padding: 8,
  },
  settings: {
    "&:hover": {
      background: "#e6dddd",
    },
  },
  container: {
    marginTop: 10,
  },
}));

const dummySettings = [
  {
    name: "Setting 1",
    pricingModel: "one",
    priceList: "two",
    preFlight: "two",
    quantityType: "one",
    min: "20",
    max: "100",
  },
  {
    name: "Setting 2",
    pricingModel: "one",
    priceList: "two",
    preFlight: "two",
    quantityType: "one",
    min: "300",
    max: "10000",
  },
  {
    name: "Setting 3",
    pricingModel: "one",
    priceList: "two",
    preFlight: "two",
    quantityType: "one",
    min: "5",
    max: "6",
  },
];

const FolderProductSettings = ({ handleSetDraft }) => {
  const classes = useStyles();

  const [settings, setSettings] = useState({});
  const [openSettings, setOpenSettings] = useState(false);

  const handleEditSettings = setting => {
    setSettings(setting);
    setOpenSettings(true);
  };

  const handleOpenSettings = () => {
    const initialValues = {
      name: "",
      pricingModel: "",
      priceList: "",
      preFlight: "",
      quantityType: "",
      min: "",
      max: "",
    };
    setSettings(initialValues);
    setOpenSettings(true);
  };

  const setDraft = () => {
    handleSetDraft("folderProductSettings", true);
  };

  const setUnDraft = () => {
    handleSetDraft("folderProductSettings", false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
    setDraft();
  };

  const handleSelect = (name, value) => {
    setSettings({ ...settings, [name]: value });
    setDraft();
  };

  return (
    <Box>
      <Grid container spacing={1} className={classes.settingsCont}>
        {dummySettings.map(setting => {
          return (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              className={classes.settings}
            >
              <Typography>{setting.name}</Typography>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleEditSettings(setting)}
              >
                <Icon className={clsx(["fa fa-pencil-alt", classes.icon])} />
              </IconButton>
              <IconButton className={classes.iconButton}>
                <Icon className={clsx(["fa fa-trash", classes.icon])} />
              </IconButton>
            </Grid>
          );
        })}

        <Grid item container xs={12} justifyContent="flex-end">
          <PrimaryButton onClick={() => handleOpenSettings()}>
            <FormattedMessage id="context.new" />
          </PrimaryButton>
        </Grid>
      </Grid>
      {openSettings && (
        <Grid container spacing={1} className={classes.settingsCont}>
          <Grid
            item
            container
            alignItems="center"
            className={classes.container}
            xs={12}
            md={6}
          >
            <Grid xs={12} md={2}>
              <Typography className={classes.text}>
                <FormattedMessage id="product.name" />:
              </Typography>
            </Grid>
            <Grid xs={12} md={10}>
              <CustomTextField
                customClass={classes.textField}
                name="name"
                value={settings.name}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <LeftSettings
              handleChange={handleChange}
              handleSelect={handleSelect}
              settings={settings}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RightSettings
              handleChange={handleChange}
              handleSelect={handleSelect}
              settings={settings}
            />
          </Grid>
        </Grid>
      )}

      <Grid container justifyContent="flex-end">
        <PrimaryButton onClick={() => setUnDraft()}>
          <FormattedMessage id="btn.save" />
        </PrimaryButton>
      </Grid>
    </Box>
  );
};

FolderProductSettings.propTypes = {
  handleSetDraft: PropTypes.func.isRequired,
};

export default FolderProductSettings;

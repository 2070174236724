import { gql } from '@apollo/client';

const getResourceSiteRoleRights = gql`
  query getResourceRoleRights($siteRoleId: Int!, $folderId: Int!) {
    siteRoles {
      getResourceRoleRights(siteRoleId: $siteRoleId, folderId: $folderId) {
        resourceId
			  readAccess
			  writeAccess
			  name
			  thumbnailUri
      }
    }
  }
`;

export default getResourceSiteRoleRights;

import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EmptyList from "../../../ProductsMainView/EmptyList";
import ViewContainer from "../../../ProductsMainView/ViewContainer";
import ImageViewMasonry from "./ImageViewMasonry";

const useStyles = makeStyles(theme => ({
  spacing: {
    "& > .MuiGrid-item": {
      padding: 1,
    },
  },
}));

const ImageView = ({ products }) => {
  const classes = useStyles();

  const haveItems = products && products.items && Array.isArray(products.items) && products.items.length > 0;

  return (
    products && products.items && (
      <ViewContainer>
        <Grid container spacing={1} className={classes.spacing}>
          {haveItems && <ImageViewMasonry numCols={6} products={products.items} />}
          {!haveItems && <EmptyList messageId="product.categoryEmpty" />}
        </Grid>
      </ViewContainer>
    )
  );
};

ImageView.propTypes = {
  products: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        thumbnailImageUri: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default ImageView;

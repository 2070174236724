import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Icon, Typography } from "@mui/material";
import clsx from "clsx";
import SettingsPopover from "../SettingsPopover";
import ChannelRow from "./ChannelRow";

const useStyles = makeStyles(() => ({
  head: {
    display: "flex",
    alignItems: "center",
    padding: "15px 10px",
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  iconBtn: {
    padding: 4,
    marginLeft: "auto",
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
  },
}));

const ChannelHead = ({
  handleClose,
  anchorEl,
  open,
  id,
  handleClickSetting,
  handleOpenCreateChannel,
}) => {
  const classes = useStyles();

  return (
    <ChannelRow>
      <Box className={classes.head}>
        {false && (
          <Typography className={classes.title}>
            <FormattedMessage id="widget.messaging" />
          </Typography>
        )}
        {false && (
          <IconButton className={classes.iconBtn} onClick={handleClickSetting}>
            <Icon className={clsx(["fa fa-cog", classes.icon])} />
          </IconButton>
        )}
        <SettingsPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleOpenCreateChannel={handleOpenCreateChannel}
        />
      </Box>
    </ChannelRow>
  );
};

ChannelHead.defaultProps = {
  anchorEl: null,
  id: undefined,
};

ChannelHead.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleClickSetting: PropTypes.func.isRequired,
  handleOpenCreateChannel: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

export default ChannelHead;

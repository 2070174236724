import React from "react";
import PropTypes from "prop-types";
import { FormattedTime } from "react-intl";

const FormattedTimeComponent = ({ value }) => {
  if (!value) return null;

  return <FormattedTime value={value} />;
};

FormattedTimeComponent.defaultProps = {
  value: null,
};

export default FormattedTimeComponent;

import { gql } from '@apollo/client';

const ProjectUserLookup = gql`
  fragment ProjectUserLookup on ProjectUserLookup {
    #__typename

    avatar
    username: email
    firstname
    lastname
    userId
  }
`;

export default ProjectUserLookup;

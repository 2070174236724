import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
} from "@mui/material";
import clsx from "clsx";
import ViewListModal from "./ViewListModal";
import ManageListModal from "./ManageListModal";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #f1f1f1",
  },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  iconButtonDownload: {
    padding: 4,
    color: theme.palette.common.link,
    marginLeft: 5,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
}));

const StaffListsTable = () => {
  const classes = useStyles();

  const [openViewList, setOpenViewList] = useState(false);
  const handleOpenViewList = () => {
    setOpenViewList(true);
  };
  const handleCloseViewList = () => {
    setOpenViewList(false);
  };

  const [openManageList, setOpenManageList] = useState(false);
  const handleOpenManageList = () => {
    setOpenManageList(true);
  };
  const handleCloseManageList = () => {
    setOpenManageList(false);
  };

  return (
    <>
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>dropdown</TableCell>
            <TableCell className={classes.th}>used by</TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>My dropdown</TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td} align="right">
              <IconButton
                className={classes.iconButtonDownload}
                onClick={handleOpenViewList}
              >
                <Icon className={clsx(["fa fa-eye", classes.icon])} />
              </IconButton>
              <IconButton
                className={classes.iconButtonDownload}
                onClick={handleOpenManageList}
              >
                <Icon className={clsx(["fa fa-cog", classes.icon])} />
              </IconButton>
              <IconButton className={classes.iconButtonDelete}>
                <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ViewListModal open={openViewList} handleClose={handleCloseViewList} />
      <ManageListModal
        open={openManageList}
        handleClose={handleCloseManageList}
      />
    </>
  );
};

export default StaffListsTable;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import {
  addressess,
  deliveryAddress,
  postalAddress,
  invoiceAddress,
  visitingAddress,
} from "../helpers";
import AddressFields from "./AddressFields";
import { PrimaryButton } from "../../../../common/Buttons";
import { GET_COMPANY_ADDRESS_FIELD_SETTINGS } from "../../../../../graphql/queries/CompanyAdminContext";
import { UPDATE_COMPANY_ADDRESS_SETTINGS } from "../../../../../graphql/mutations/CompanyAdminContext";

import { getSortedDeliverySettings } from "../../../../../helpers/get";

const useStyles = makeStyles(() => ({
  btnContainer: {
    marginTop: 15,
  },
}));

const AddressLabels = ({ companyId }) => {
  const classes = useStyles();
  const alert = useAlert();
  const [getAddressSettings, { data: addressSettingsData }] = useLazyQuery(
    GET_COMPANY_ADDRESS_FIELD_SETTINGS
  );
  const [updateAddressSettings] = useMutation(UPDATE_COMPANY_ADDRESS_SETTINGS);

  const [fields, setFields] = useState(addressess);

  const [fieldValues, setFieldValues] = useState({
    deliveryAddress,
    postalAddress,
    invoiceAddress,
    visitingAddress,
  });

  useEffect(() => {
    if (companyId) {
      getAddressSettings({ variables: { companyId } });
    }
  }, [companyId]);

  useEffect(() => {
    if (
      addressSettingsData &&
      addressSettingsData.companyAdminContext &&
      addressSettingsData.companyAdminContext.addressSettings &&
      addressSettingsData.companyAdminContext.addressSettings
        .getCompanyAddressFieldSettings
    ) {
      const {
        success,
        deliveryAddressSettings,
        invoiceAddressSettings,
        postalAddressSettings,
        visitingAddressSettings,
      } = addressSettingsData.companyAdminContext.addressSettings.getCompanyAddressFieldSettings;

      if (success) {
        const sortedDeliverySettings = getSortedDeliverySettings(
          deliveryAddressSettings
        );
        const sortedPostalSettings = getSortedDeliverySettings(
          postalAddressSettings
        );

        const sortedInvoiceSettings = getSortedDeliverySettings(
          invoiceAddressSettings
        );

        const sortedVisitingSettings = getSortedDeliverySettings(
          visitingAddressSettings
        );

        const tempAddress = [...fields];

        tempAddress[0] = {
          ...fields[0],
          fields: sortedDeliverySettings,
        };

        tempAddress[1] = {
          ...fields[1],
          fields: sortedPostalSettings,
        };
        tempAddress[2] = {
          ...fields[2],
          fields: sortedInvoiceSettings,
        };

        tempAddress[3] = {
          ...fields[3],
          fields: sortedVisitingSettings,
        };

        setFields(tempAddress);
      }
    }
  }, [addressSettingsData]);

  const handleChange = (mainIndex, idx, e) => {
    const { value } = e.target;
    // setFieldValues({
    //   ...fieldValues,
    //   [address]: { ...fieldValues[address], [name]: value },
    // });

    const newFields = [...fields];
    const tempSubFields = fields[mainIndex].fields;
    tempSubFields[idx] = { ...tempSubFields[idx], label: value };
    newFields[mainIndex] = { ...fields[mainIndex], fields: tempSubFields };
    setFields(newFields);
  };

  const handleFieldType = (mainIndex, idx, value) => {
    const newFields = [...fields];
    const tempSubFields = fields[mainIndex].fields;
    tempSubFields[idx] = { ...tempSubFields[idx], type: value };
    newFields[mainIndex] = { ...fields[mainIndex], fields: tempSubFields };
    setFields(newFields);
  };

  const reorder = (idx, startIndex, endIndex) => {
    const result = [...fields[idx].fields];
    const removed = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed[0]);

    return result;
  };

  const onDragEnd = (idx, result) => {
    if (!result.destination) {
      return;
    }
    const newAddressFields = reorder(
      idx,
      result.source.index,
      result.destination.index
    );
    const newFields = [...fields];
    const newValue = { ...newFields[idx], fields: newAddressFields };
    newFields[idx] = newValue;
    setFields(newFields);
  };

  const handleConvertFields = fields => {
    let newFields = {};

    fields.forEach((field, idx) => {
      const newProperty = { ...field };
      delete newProperty.name;
      delete newProperty.placeholder;
      newFields = {
        ...newFields,
        [field.name]: { ...newProperty, displayOrder: idx },
      };
    });

    return newFields;
  };

  const handleSave = async () => {
    try {
      const newFields = [...fields];
      const deliveryAddressSettings = handleConvertFields(newFields[0].fields);
      const postalAddressSettings = handleConvertFields(newFields[1].fields);
      const invoiceAddressSettings = handleConvertFields(newFields[2].fields);
      const vistingAddressSettings = handleConvertFields(newFields[3].fields);

      const input = {
        companyId,
        deliveryAddressSettings,
        invoiceAddressSettings,
        postalAddressSettings,
        vistingAddressSettings,
      };
      const res = await updateAddressSettings({ variables: { input } });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyAddressSettings &&
        res.data.companyAdminContext.companyAddressSettings
          .updateCompanyAddressSettings
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyAddressSettings.updateCompanyAddressSettings;
        if (success) {
          alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <Box>
      <Grid container spacing={1} className={classes.btnContainer}>
        {fields.map((address, idx) => {
          return (
            <AddressFields
              name={address.name}
              label={address.label}
              fields={address.fields}
              fieldValues={fieldValues}
              handleChange={handleChange}
              handleFieldType={handleFieldType}
              onDragEnd={onDragEnd}
              mainIndex={idx}
            />
          );
        })}
      </Grid>
      <Grid container justifyContent="flex-end" className={classes.btnContainer}>
        <PrimaryButton onClick={handleSave}>
          <FormattedMessage id="btn.save" />
        </PrimaryButton>
      </Grid>
    </Box>
  );
};

AddressLabels.defaultProps = {
  companyId: null,
};

AddressLabels.propTypes = {
  companyId: PropTypes.number,
};

export default AddressLabels;

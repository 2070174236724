import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ImageList from "../../components/ImageBasket/ImageList";
import ImageOptions from "../../components/ImageBasket/ImageOptions";
import ShareDetails from "../../components/ImageBasket/ShareDetails";
import LoadingScreen from "../../components/common/LoadingScreen";
import { useLazyQuery } from "@apollo/client";
import { GET_SHARE_DETAILS } from "../../graphql/queries/ShareContext";
import { SHARE_TYPES } from "../../constant/shareTypes";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  noSiteText: {
    fontSize: 12,
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    textAlign: "center",
    backgroundColor: theme.palette.background.contrastGray,
  },
  noSiteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
    fontSize: 14,
    marginRight: 10,
    color: theme.palette.text.lightGray,
  },
  error: {
    padding: "1em",
  },
  shareDetails: {
    marginTop: "1em",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
}));

const SharedImageBasket = ({ match }) => {
  const { params } = match || {};
  const { shareId: shareIdParam } = params || {};

  const shareId = parseInt(shareIdParam);

  const { api } = useSelector(state => state);
  const { viewer } = api.currentViewer;

  const [imageBasketDetails, setImageBasketDetails] = useState(null);
  const [imageBasketItems, setImageBasketItems] = useState([]);
  const [isSharedByUser, setIsSharedByUser] = useState();
  const [invalidType, setInvalidType] = useState(false);

  const [
    getShareDetails,
    {
      data: shareDetailsData,
      loading: loadingShareDetails,
      error: errorLoadingShareDetails,
    },
  ] = useLazyQuery(GET_SHARE_DETAILS, { variables: { shareId } });

  useEffect(() => {
    getShareDetails();
  }, []);

  useEffect(() => {
    if (
      shareDetailsData &&
      shareDetailsData.shareContext &&
      shareDetailsData.shareContext.getShareDetails
    ) {
      const { details, items } = shareDetailsData.shareContext.getShareDetails;

      if (details.type === SHARE_TYPES.IMAGE_BANK) {
        setInvalidType(false);
        setImageBasketDetails(details);
        setImageBasketItems(items);
        setIsSharedByUser(details.user.username === viewer.username);
      } else {
        setInvalidType(true);
        setImageBasketDetails(null);
        setImageBasketItems([]);
        setIsSharedByUser(false);
      }
    }
  }, [shareDetailsData]);

  useEffect(() => {
    var imageData = getImageData(imageBasketItems);
    setEachImageArr(imageData);
  }, [imageBasketItems]);

  const classes = useStyles();
  const intl = useIntl();
  
  const colorModelOptions = [
    {
      label: intl.formatMessage({
        id: "imageBasket.bitmap",
      }),
      value: "MONOCHROME",
    },
    {
      label: intl.formatMessage({
        id: "imageBasket.grayscale",
      }),
      value: "GRAY",
    },
    { label: "RGB", value: "RGB" },
    { label: "CMYK", value: "CMYK" },
  ];

  const fileFormatOptions = [
    { label: "JPEG", value: "JPEG" },
    { label: "TIFF", value: "TIFF" },
    { label: "EPS", value: "EPS" },
  ];

  const [allImageOption, setAllImageOption] = useState(false);
  const [selectImageOption, setSelectedImageOption] = useState(false);

  const getImageData = imageBasketItems => {
    return imageBasketItems.map(image => {
      var { includeOriginal, productId } = image;
      return {
        includeOriginal,
        productId,
        settings: image.settings.map(setting => {
          var { settingId, selectedColorMode, selectedFileFormat } = setting;
          return {
            settingId,
            selectedColorMode,
            selectedFileFormat,
          };
        }),
      };
    });
  };

  const [eachImageArr, setEachImageArr] = useState([]);

  const handleImageBasketItem = (params, idx) => {
    eachImageArr[idx] = params;
    setEachImageArr(eachImageArr);
  };

  const handleOptions = option => {
    if (option === "all") {
      setAllImageOption(true);
      setSelectedImageOption(false);
    }

    if (option === "each") {
      setSelectedImageOption(true);
      setAllImageOption(false);
    }
  };

  const handleOnDeleteItem = async ({ productId, idx }) => {
    const imageBasket = [...imageBasketItems];
    imageBasket.splice(idx, 1);
    setImageBasketItems(imageBasket);
  };

  if (loadingShareDetails) {
    return <LoadingScreen />;
  }

  if (errorLoadingShareDetails) {
    return (
      <div className={classes.error}>
        <FormattedMessage id="sharedImageBasket.error.errorLoadingDetails" />
      </div>
    );
  }

  if (invalidType) {
    return (
      <div className={classes.error}>
        <FormattedMessage id="sharedImageBasket.error.invalidShareType" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <ImageList
            colorModelOptions={colorModelOptions}
            fileFormatOptions={fileFormatOptions}
            handleImageBasketItem={handleImageBasketItem}
            imageBasketItems={imageBasketItems}
            onDeleteItem={handleOnDeleteItem}
            selectImageOption={selectImageOption}
          />
        </Grid>
        <Grid item md={3}>
          <ImageOptions
            shareId={shareId}
            handleOptions={handleOptions}
            allImageOption={allImageOption}
            selectImageOption={selectImageOption}
            colorModelOptions={colorModelOptions}
            fileFormatOptions={fileFormatOptions}
            eachImageArr={eachImageArr}
            imageBasketItems={imageBasketItems}
          />
          {imageBasketDetails && (
            <ShareDetails
              className={classes.shareDetails}
              details={imageBasketDetails}
              isSharedByUser={isSharedByUser}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

SharedImageBasket.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SharedImageBasket;

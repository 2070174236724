import { createActions } from "redux-actions";

export const {
  setSearchProductIds,
  setSearchResourceIds,
  setSearchFolders,
  setSearchFolderId,
  setSearchErrors,
  setSearchCalled,
  setSearchLoading,
  setSearchViewSettings,
  setSearchFolderName,
} = createActions({
  SET_SEARCH_PRODUCT_IDS: ({ items }) => ({ items }),
  SET_SEARCH_RESOURCE_IDS: ({ items }) => ({ items }),
  SET_SEARCH_FOLDERS: ({ folders }) => ({ folders }),
  SET_SEARCH_FOLDER_ID: ({ id }) => ({ id }),
  SET_SEARCH_FOLDER_NAME: ({ name }) => ({ name }),
  SET_SEARCH_ERRORS: ({ error }) => ({ error }),
  SET_SEARCH_CALLED: ({ called }) => ({ called }),
  SET_SEARCH_LOADING: ({ loading }) => ({ loading }),
  SET_SEARCH_VIEW_SETTINGS: ({ settings }) => ({ settings }),
});

import React, { useState, Fragment } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import TreeItem from "@mui/lab/TreeItem";
import { Box, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { MOVE_COMPANY } from "../../../../../graphql/mutations/CompanyAdminContext";
import { getAdminSelectedCompanyId } from "../../../../../helpers/adminSelectors";
import CustomTextField from "../../../../common/TextField";
import RenderCompany from "./RenderCompany";

const useStyles = makeStyles(theme => ({
  labelRoot: {
    display: "flex",
    alignItems: "center",
    color: "#555",
    textDecoration: "none",
    "&:hover": {
      color: "#555",
    },
    "&:focus": {
      color: "#555",
    },
    "&:active": {
      color: "#555",
    },
  },
  labelIcon: {
    fontSize: 12,
    marginRight: 10,
    width: 15,
    height: 15,
    overflow: "unset",
    textAlign: "center",
    color: "#555",
  },
  label: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "14px",
  },
  textField: {
    width: "100%",
  },
  draggedOver: {
    backgroundColor: "transparent",
    border: `2px dashed ${theme.palette.primary.dark}`,
    padding: 0,
  },
  notDraggedOver: {
    backgroundColor: "transparent",
    padding: 2,
    margin: 2,
  },
}));

const RenderNetwork = ({
  handleContextMenu,
  handleDeleteNetwork,
  handleError,
  handleRenameNetwork,
  handleSetEditNode,
  idx,
  isEditMode,
  node,
  refresh,
  selectedNode,
  treeData,
}) => {
  const classes = useStyles();
  const alert = useAlert();

  const { childCompanyIds, name, networkId } = node || {};

  const compId = useSelector(getAdminSelectedCompanyId);

  const [draggedOver, setDraggedOver] = useState(false);

  const [moveCompany] = useMutation(MOVE_COMPANY);

  const handleMoveCompany = async (e, c) => {
    setDraggedOver(false);
    const tempCompany = e.dataTransfer.getData("networkCompany");
    const movedCompany = JSON.parse(tempCompany);
    try {
      const { networkId: netId, companyId } = c || {};
      const from = {
        networkId: movedCompany.networkId,
        companyId: movedCompany.companyId,
      };
      const to = {
        networkId: netId,
        parentCompanyId: companyId || null,
      };

      const params = { companyId: compId, from, to };
      const res = await moveCompany({ variables: { parameters: params } });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyNetworks &&
        res.data.companyAdminContext.companyNetworks.moveCompany
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyNetworks.moveCompany;
        if (success) {
          refresh();
          alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          const {
            errorResult,
          } = res.data.companyAdminContext.companyNetworks.moveCompany;
          handleError(errorResult);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const [networkName, setNetworkName] = useState(name);

  const handleChange = e => {
    const { value } = e.target;
    setNetworkName(value);
  };

  const handleChangeNetworkName = async () => {
    if (networkName !== name) {
      const res = await handleRenameNetwork(networkName, networkId);
      if (!res) {
        setNetworkName(name);
      }
    }
    handleSetEditNode(false);
  };

  const undoEdit = () => {
    handleSetEditNode(false);
    setNetworkName(name);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleChangeNetworkName(e.target.value);
    } else if (e.key === "Escape") {
      undoEdit();
    }
  };

  const handleDrop = (e, c) => {
    e.stopPropagation();
    handleMoveCompany(e, c);
  };

  const handleOnDragOver = e => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleOnDragLeave = e => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const renderLabel = () => {
    return (
      <Box
        className={classes.labelRoot}
        onDragEnd={handleOnDragLeave}
        onDragLeave={handleOnDragLeave}
        onDragOver={handleOnDragOver}
      >
        <Icon className={clsx([`fas fa-sitemap`, classes.labelIcon])} />

        {isEditMode && selectedNode.networkId === networkId ? (
          <CustomTextField
            autoFocus
            className={classes.textField}
            onBlur={undoEdit}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={networkName}
          />
        ) : (
          <Typography className={classes.label}>{networkName}</Typography>
        )}
      </Box>
    );
  };

  if (Array.isArray(childCompanyIds) && childCompanyIds.length > 0) {
    return (
      <TreeItem
        className={clsx([
          draggedOver ? classes.draggedOver : classes.notDraggedOver,
        ])}
        label={renderLabel()}
        nodeId={`n_${networkId}`}
        onContextMenu={e => handleContextMenu(e, node)}
        onDrop={e => handleDrop(e, node)}
      >
        {childCompanyIds.map(c => (
          <Fragment key={c}>
            <RenderCompany
              company={treeData[`c_${c}`]}
              handleContextMenu={handleContextMenu}
              handleMoveCompany={handleMoveCompany}
              key={c}
              treeData={treeData}
            />
          </Fragment>
        ))}
      </TreeItem>
    );
  }

  return (
    <TreeItem
      className={clsx([
        draggedOver ? classes.draggedOver : classes.notDraggedOver,
      ])}
      label={renderLabel()}
      nodeId={`n_${networkId}-${idx}`}
      onContextMenu={e => handleContextMenu(e, node)}
      onDrop={e => handleDrop(e, node)}
    />
  );
};

export default RenderNetwork;

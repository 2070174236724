import { gql } from '@apollo/client';
import ProductFragment from "../fragments/Product";

const getProduct = gql`
  query GetProduct($id: ID!, $breadcrumbs: Boolean) {
    product(id: $id, breadcrumbs: $breadcrumbs) {
      ...ProductFields
    }
  }

  ${ProductFragment}
`;

export default getProduct;

export const themes = [
  { label: "Monokai", value: "monokai" },
  { label: "Github", value: "github" },
  { label: "Tomorrow", value: "tomorrow" },
  { label: "Kuroir", value: "kuroir" },
  { label: "Twilight", value: "twilight" },
  { label: "Xcode", value: "xcode" },
  { label: "Textmate", value: "textmate" },
  { label: "Solarized Dark", value: "solarized_dark" },
  { label: "Solarized Light", value: "solarized_light" },
  { label: "Terminal", value: "terminal" },
];

export const modes = [
  { label: "Javascript", value: "javascript" },
  { label: "XML", value: "xml" },
];

export const getFileExtension = name => {
  const re = /(?:\.([^.]+))?$/;

  return re.exec(name)[1];
};

import { useState } from "react";
import { ChromePicker } from "react-color";
import { FormattedMessage } from "react-intl";
import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, List, ListItem, ListItemText, Popover, Typography } from "@mui/material";
import StatusColumnListItem from "./StatusColumnListItem";

const StatusColumnDetails = ({ field, onChange }) => {
  const [colorPickerState, setColorPickerState] = useState({ anchorEl: null, selectedColorIndex: null });

  const handleAddStatus = () => {
    onChange({ ...field, settings: [...field.settings, { color: "#ffffff", name: "" }] });
  };

  const handleCloseColorPicker = () => handleToggleColorPicker();

  const handleColorChange = (index, color) => {
    const newSettings = [...field.settings];
    newSettings[index] = { ...newSettings[index], color: color.hex };
    onChange({ ...field, settings: newSettings });
  };

  const handleNameChange = (index, newValue) => {
    const newSettings = [...field.settings];
    newSettings[index] = { ...newSettings[index], name: newValue };
    onChange({ ...field, settings: newSettings });
  };

  const handleMoveStatus = (index, direction) => {
    const fromIndex = index;
    let toIndex;

    if (direction === "up") {
      toIndex = fromIndex - 1;
    }
    else if (direction === "down") {
      toIndex = fromIndex + 1;
    }
    else {
      console.log("** invalid direction:", direction);
      return;
    }

    let newSettings = [...field.settings];
    const [movedStatus] = newSettings.splice(fromIndex, 1);
    newSettings.splice(toIndex, 0, movedStatus);
    onChange({ ...field, settings: [...newSettings] });
  };

  const handleRemoveStatus = (index) => {
    const newSettings = [...field.settings];
    newSettings.splice(index, 1);
    onChange({ ...field, settings: newSettings });
  };

  const handleSelectColorPicker = (event, index) => handleToggleColorPicker(event, index);

  const handleToggleColorPicker = (event, index) => {
    if (!!event) {
      setColorPickerState({ anchorEl: event.currentTarget, selectedColorIndex: index });
    }
    else {
      setColorPickerState({ anchorEl: null, selectedColorIndex: null });
    }
  };

  const openColorPicker = Boolean(colorPickerState.anchorEl);
  const id = openColorPicker ? 'color-picker-popover' : undefined;
  const totalItems = field.settings?.length;

  return (
    <Box>
      <Typography variant="h6">
        <FormattedMessage id="project.statusManagement" />
      </Typography>
      <List sx={{ paddingTop: 0 }}>
        {field.settings && field.settings.map((status, index) => (
          <StatusColumnListItem
            index={index}
            isFirstItem={index === 0}
            isLastItem={index === totalItems - 1}
            onDelete={handleRemoveStatus}
            onMove={handleMoveStatus}
            onNameChange={handleNameChange}
            onSelectColorPicker={handleSelectColorPicker}
            status={status}
          />
        ))}
        <ListItem sx={{ padding: 0, paddingLeft: "2px" }}>
          <IconButton edge="start" onClick={handleAddStatus}>
            <AddIcon />
          </IconButton>
          <ListItemText primary={<FormattedMessage id="project.newStatus" />} />
        </ListItem>
      </List>
      <Popover
        id={id}
        open={openColorPicker}
        anchorEl={colorPickerState.anchorEl}
        onClose={handleCloseColorPicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ChromePicker
          color={field.settings[colorPickerState.selectedColorIndex]?.color || "#ffffff"}
          onChange={(color) => handleColorChange(colorPickerState.selectedColorIndex, color)}
          disableAlpha
        />
      </Popover>
    </Box>
  );
};

export default StatusColumnDetails;
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { AppBar, Box, Icon, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { geta11yProps } from "../../../../helpers/get";
import { getAdminSelectedCompanyId } from "../../../../helpers/adminSelectors";
import PageTabs from "../../../PageTabs";
import TabPanel from "../../../TabPanel";
import AddressLabels from "./AddressLabels";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
  },
  text: {
    fontSize: 14,
    marginBottom: 20,
  },
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
    "& .fa": {
      fontSize: 12,
      marginLeft: 7,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  hasError: {
    backgroundColor: "#ffa7a7",
    opacity: 1,
    boxShadow: "inset 0px 0px 0px 2px #bb1335",
    "& .fa": {
      display: "inline-block !important",
      fontSize: 12,
      marginLeft: 7,
      color: "#b72b2b",
    },
    "& $indicator": {
      backgroundColor: "#bb1335 !important",
    },
  },
  label: {
    fontSize: 10,
  },
  draftIcon: {
    color: "#b72b2b",
  },
  loadingOverlay: {
    background: "#000",
    opacity: 0.5,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  loadingIcon: {
    color: "#fff",
  },
}));

const AddressSettings = () => {
  const classes = useStyles();
  const intl = useIntl();

  const companyId = useSelector(getAdminSelectedCompanyId);

  const [value, setValue] = useState(0);
  const [draftTabs, setDraftTabs] = useState({
    adressLabels: false,
  });

  const handleSetDraft = (name, draft) => {
    setDraftTabs({ ...draftTabs, [name]: draft });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderLabel = (label, draft, error) => {
    const errorIcon = error && <Icon className="fa fa-exclamation-circle" />;
    const draftIcon = draft && (
      <Icon className={clsx(["fa fa-edit", classes.draftIcon])} />
    );

    return (
      <>
        <Typography className={classes.label}>
          {intl.formatMessage({ id: label })}
        </Typography>
        {draftIcon}
        {errorIcon}
      </>
    );
  };

  return (
    <Box className={classes.root}>
      {/* <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          Combined delivery and invoice address list
        </Typography>
        <Button className={classes.addLink} onClick={handleOpen}>
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
          Add new address
        </Button>
      </Box> */}
      <Box className={classes.body}>
        <AppBar position="static" elevation="0" className={classes.wrap}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Edit Product"
            classes={{
              indicator: classes.indicator,
            }}
            className={classes.tabsRoot}
          >
            <PageTabs
              className={classes.pageTabs}
              label={renderLabel(
                "addresses.addressFields",
                draftTabs.adressLabels
              )}
              {...geta11yProps(0)}
              value={0}
            />
          </Tabs>
        </AppBar>
        {[
          <AddressLabels
            companyId={companyId}
            handleSetDraft={handleSetDraft}
          />,
        ].map((x, index) => {
          return (
            <>
              <TabPanel
                value={value}
                className={classes.tabPanel}
                index={index}
              >
                {x}
              </TabPanel>
            </>
          );
        })}
      </Box>
      {/* <AddressModal open={open} handleClose={handleClose} /> ABANDONED? */}
    </Box>
  );
};

export default AddressSettings;

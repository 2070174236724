import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";

import { FILTER_NAMES } from "./filters";

const {
  NAME,
  COMPANY,
  EMAIL,
  LAST_LOGGED_IN,
  PAYMENT_LIABILITY,
  SITE_ROLE,
  STATUS,
} = FILTER_NAMES;

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
}));

const getOrder = ({ ascending }) => {
  if (ascending) return "asc";
  return "desc";
};

const SiteUsersTableHead = ({
  sortfield,
  ascending,
  setSortfield,
  setAscending,
  siteRolesRights,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { canInviteUsers } =
    siteRolesRights || {};

  const headers = [
    {
      label: intl.formatMessage({
        id: "sorting.name",
      }),
      name: NAME,
    },
    {
      label: intl.formatMessage({
        id: "support.email",
      }),
      name: EMAIL,
    },
    {
      label: intl.formatMessage({
        id: "support.telephone",
      }),
    },
    {
      label: intl.formatMessage({
        id: "sorting.company",
      }),
      name: COMPANY,
    },
    {
      label: intl.formatMessage({
        id: "sorting.siteRole",
      }),
      name: SITE_ROLE,
    },
    {
      label: intl.formatMessage({
        id: "sorting.payingForItself",
      }),
      name: PAYMENT_LIABILITY,
    },
    {
      label: intl.formatMessage({
        id: "sorting.lastLoggedIn",
      }),
      name: LAST_LOGGED_IN,
    },
    {
      label: intl.formatMessage({
        id: "supplier.status",
      }),
      name: STATUS,
    },
  ];

  const createSortHandler = (property, isAscending) => event => {
    setAscending(isAscending);
    setSortfield(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headers.map(head => {
          if (head.name) {
            return (
              <TableCell
                key={head.label}
                className={classes.th}
                sortDirection={
                  head.name === sortfield ? getOrder({ ascending }) : false
                }
              >
                <TableSortLabel
                  active={head.name === sortfield}
                  direction={
                    sortfield === head.name ? getOrder({ ascending }) : "asc"
                  }
                  onClick={createSortHandler(
                    head.name,
                    sortfield === head.name ? !ascending : true
                  )}
                >
                  {head.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell key={head.label} className={classes.th}>
                {head.label}
              </TableCell>
            );
          }
        })}
        {canInviteUsers && <TableCell className={classes.th} />}
      </TableRow>
    </TableHead>
  );
};

export default SiteUsersTableHead;

SiteUsersTableHead.propTypes = {
  setAscending: PropTypes.func.isRequired,
  setSortfield: PropTypes.func.isRequired,
  sortfield: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import VersionHistoryTable from "./VersionHistoryTable";

const useStyles = makeStyles(theme => ({
  btnAdd: {
    padding: "3px 15px",
    fontSize: 12,
    marginRight: 15,
    textTransform: "none",
    fontWeight: 600,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
}));

const VersionHistory = ({
  locked,
  items,
  onDownloadVersion,
  refetchVersionHistory,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box>
        <Button
          className={classes.btnAdd}
          disabled={locked}
          component="label"
          variant="contained"
        >
          <Icon className={clsx(["fa fa-plus", classes.btnIcon])} />
          <FormattedMessage id="version.new" />
          <input type="file" style={{ display: "none" }} onChange={onChange} />
        </Button>
      </Box>
      <VersionHistoryTable
        items={items}
        onDownloadVersion={onDownloadVersion}
        locked={locked}
        refetchVersionHistory={refetchVersionHistory}
      />
    </Box>
  );
};

VersionHistory.defaultProps = {
  locked: false,
};

VersionHistory.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDownloadVersion: PropTypes.func.isRequired,
  refetchVersionHistory: PropTypes.func.isRequired,
  locked: PropTypes.bool,
};

export default VersionHistory;

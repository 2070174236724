import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { FormattedMessage } from "react-intl";

import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    paddingTop: 12,
    paddingBottom: 12,
  },
  tdCheckbox: {
    padding: "10px 0px 10px 10px",
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  img: {
    width: 20,
    height: 20,
    objectFit: "cover",
    borderRadius: "50%",
    verticalAlign: "middle",
    marginRight: 10,
  },
  removeRow: {
    padding: 3,
    color: theme.palette.secondary.dark,
    marginRight: 0,
    "& svg": {
      width: "17px !important",
      height: "17px !important",
    },
  },
}));

const SitePersonsTableRow = ({
  siteUser,  
  siteRolesRights,
}) => {
  
  const classes = useStyles();
  const [formValues, setFormValues] = useState(siteUser);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const { canManageConsent } =
    siteRolesRights || {};

  const showDeletePerson = canManageConsent;

  const {
    avatar,
    email,
    name,
    title,
    id,
    siteId,
  } = formValues;

  useEffect(() => {
    if (siteUser) {
      setFormValues(siteUser);
    }
  }, [siteUser]);

  const confirmDelete = (siteId, id) => {
    //onRemoveSiteUser(siteId, id);
    //setConfirmationModal(false);
  };

  return (
    <TableRow>
      <TableCell className={classes.td}>
        {/*<img src={avatar} alt={name} className={classes.img} />*/}
        {name}
      </TableCell>
      <TableCell className={classes.td}>{email}</TableCell>
      <TableCell className={classes.td}>{title}</TableCell>
      {showDeletePerson && (
        <TableCell className={classes.td} align="right">
          {/* commented out this icon for now , will bring back once functionality is discussed */}
          {/* <IconLink icon="fa fa-edit" onClick={() => handleOpenEditUser(true)} /> */}
          <Tooltip
            title={<FormattedMessage id="TODO" defaultMessage="Ta bort person" />}
            placement="top"
          >
            <IconButton
              edge="end"
              aria-label="delete"
              className={classes.removeRow}
              onClick={() => setConfirmationModal(true)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {confirmationModal && (
            <ConfirmDeleteModal
              open={confirmationModal}
              setOpen={setConfirmationModal}
              onConfirmCloseModal={() => confirmDelete(siteId, id)}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

SitePersonsTableRow.defaultProps = {
  siteUser: null,
  handleOpenEditUser: null,
};

SitePersonsTableRow.propTypes = {
  siteUser: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    siteId: PropTypes.number,
    title: PropTypes.string,
  }),
  handleOpenEditUser: PropTypes.func,
};

export default SitePersonsTableRow;

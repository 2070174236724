import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  IconButton,
} from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import ManageRolesMenu from "./ManageRolesMenu";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 25,
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
  td: {
    padding: "4px 0",
    fontSize: 11,
  },
  tdCb: {
    padding: "4px 0",
    textAlign: "right",
    paddingRight: "0 !important",
    minWidth: 16,
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    "& .MuiSelect-select": {
      padding: "4px 6px",
      paddingRight: 16,
      fontSize: 11,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-iconOutlined": {
      right: 1,
      top: 5,
    },
    "& .MuiFormControl-fullWidth": {
      width: "auto",
    },
  },
  editIcon: {
    fontSize: 10,
  },
  editButton: {
    color: theme.palette.common.link,
    padding: "4px !important",
    marginLeft: 4,
  },
}));

const Modules = ({
  settingKeys,
  handleOpenMenu,
  settingValues,
  handleOpenEditMenus,
  roleValues,
  settings,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.text}>
        <FormattedMessage id="siteRoles.modules" />
      </Typography>
      <Divider className={classes.divider} />
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>
              <FormattedMessage id="siteRoles.editMenus" />
            </TableCell>
            <TableCell className={classes.tdCb}>
              <IconButton
                className={classes.editButton}
                onClick={handleOpenEditMenus}
              >
                <Icon
                  className={clsx(["fa fa-pencil-alt", classes.editIcon])}
                />
              </IconButton>
            </TableCell>
          </TableRow>
          {settings.map(head => {
            return (
              <ManageRolesMenu
                key={head.name}
                name={head.name}
                label={head.label}
                checkbox={head.checkbox}
                onChange={handleOpenMenu}
                settingValues={settingValues}
                roleValues={roleValues}
                disabled={head.disable ? head.disable : false}
                settingKeys={settingKeys}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

Modules.defaultProps = {
  roleValues: {},
  settingValues: {},
  settingKeys: {},
};

Modules.propTypes = {
  settingKeys: PropTypes.shape({}),
  settingValues: PropTypes.shape({}),
  roleValues: PropTypes.shape({}),
  handleOpenMenu: PropTypes.func.isRequired,
  handleOpenEditMenus: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Modules;

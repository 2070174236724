import { gql } from '@apollo/client';

const WorkspaceOwnerCandidateListItemFragment = gql`
  fragment WorkspaceOwnerCandidateListItem on WorkspaceOwnerCandidateListItem {
    userId
    isExternal
    isRegular
    username
    firstname
    lastname
  }
`;

export default WorkspaceOwnerCandidateListItemFragment;

import { gql } from '@apollo/client';

const getOrderRecipientTabSettings = gql`
  query getOrderRecipientTabSettings($siteId: Int!) {
    monitoringContext {
      getOrderRecipientTabSettings(siteId: $siteId) {
        firstTab {
          showDeliveryAddress
          showInvoiceAddress
          showOrdererAddress
        }
        subsequentTabs {
          showDeliveryAddress
          showInvoiceAddress
          showOrdererAddress
        }
      }
    }
  }
`;

export default getOrderRecipientTabSettings;

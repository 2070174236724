import { gql } from '@apollo/client';

const getBookingView = gql`
  query GetBookingView($siteid: Int!) {
    bookingContext {
      getBookingView(siteid: $siteid) {
        buildings {
          childFolders {
            folderId
            name
          }
          folderId
          name
        }
      }
    }
  }
`;

export default getBookingView;

import React, { memo } from "react";
import PropTypes from "prop-types";
import RowData from "./RowData";
import InBetweenFolder from "./InBetweenFolder";

const GroupItem = ({  
  group,
  groupTree,
  handleContextMenu,
  handleOpenNode,  
  moveFolderEnabled,
  onGroupRowClick,
  onUsersMoved,
  refetch,  
  setGroupTree,
  selectedFolderIds,    
}) => {
  const renderChildFolders = () => {
    if (group && group.childGroupIds && Array.isArray(group.childGroupIds)) {
      return (
        <>
          {group.childGroupIds.map((x, index) => (
            <React.Fragment key={`group-item-divider-${x}`}>
              <GroupItem
                key={`group-item-${x}`}
                group={groupTree[`g_${x}`]}
                groupTree={groupTree}
                handleContextMenu={handleContextMenu}
                handleOpenNode={handleOpenNode}                
                moveFolderEnabled={moveFolderEnabled}
                onGroupRowClick={onGroupRowClick}
                onUsersMoved={onUsersMoved}
                refetch={refetch}                
                setGroupTree={setGroupTree}
                selectedFolderIds={selectedFolderIds}                                
              />
              <InBetweenFolder
                groupTree={groupTree}
                parentGroupId={group.groupId}
                refetch={refetch}
                index={index + 1}
              />
            </React.Fragment>
          ))}
        </>
      );
    }
    return null;
  };



  if (
    group &&
    group.childGroupIds &&
    Array.isArray(group.childGroupIds) &&
    group.childGroupIds.length > 0
  ) {
    return (
      <RowData        
        group={group}
        groupTree={groupTree}
        handleContextMenu={handleContextMenu}
        handleOpenNode={handleOpenNode}
        moveFolderEnabled={moveFolderEnabled}
        onGroupRowClick={onGroupRowClick}
        onUsersMoved={onUsersMoved}
        refetch={refetch}
        selectedFolderIds={selectedFolderIds}
        setGroupTree={setGroupTree}        
      >
        <InBetweenFolder
          groupTree={groupTree}
          parentFolderId={group.groupId}
          refetch={refetch}                    
          index={0}
        />
        {renderChildFolders()}
      </RowData>
    );
    }

  return (
    <RowData      
      group={group}
      groupTree={groupTree}
      handleContextMenu={handleContextMenu}
      handleOpenNode={handleOpenNode}            
      moveFolderEnabled={moveFolderEnabled}
      onGroupRowClick={onGroupRowClick}
      onUsersMoved={onUsersMoved}
      refetch={refetch}
      selectedFolderIds={selectedFolderIds}      
      setGroupTree={setGroupTree}            
    />
  );
};

GroupItem.defaultProps = {
  handleOpenNode: () => {},
  moveFolderEnabled: false,
  isEditMode: false,
  selectedNode: null,
};

GroupItem.propTypes = {
  moveFolderEnabled: PropTypes.bool,
  selectedNode: PropTypes.shape({}),
  group: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    groupId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  isEditMode: PropTypes.bool,
  groupTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleContextMenu: PropTypes.func.isRequired,
  handleOpenNode: PropTypes.func,
  handleSetEditNode: PropTypes.func.isRequired,
  onGroupRowClick: PropTypes.func.isRequired,
  onUsersMoved: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  setGroupTree: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default memo(GroupItem);

import { gql } from '@apollo/client';

const SecuritySettingsFragment = gql`
  fragment SecuritySettingsFields on SecuritySettings {
    companyId
    enabledMFATypes
  }
`;

export default SecuritySettingsFragment;

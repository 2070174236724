import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography, Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  text: {
    marginBottom: 15,
  },
  folder: {
    fontWeight: 600,
    marginLeft: 5,
  },
  folderIcon: {
    fontSize: 14,
    marginRight: 8,
    width: "unset",
    height: "unset",
    overflow: "unset",
    color: "#f1c04f",
  },
}));

const FolderName = ({ name }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.text}>
      <FormattedMessage id="folder.current" />:{" "}
      <span className={classes.folder}>
        <Icon className={clsx(["fa fa-folder", classes.folderIcon])} />
        {name}
      </span>
    </Typography>
  );
};

FolderName.defaultProps = {
  name: "",
};

FolderName.propTypes = {
  name: PropTypes.string,
};

export default FolderName;

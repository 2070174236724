import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const getUserFileViewSettings = gql`
  query GetUserFileViewSettings($siteId: Int!) {
    siteSettingsContext {
      getUserFileViewSettings(siteId: $siteId) {
        errorResult {
          ...ErrorResultFields
        }
        success
        result {
          columnSettings {
            descriptionColumn
            downloadedColumn
            editedColumn
            nameColumn
            sizeColumn
            typeColumn
            uploadedColumn
            versionColumn
          }
          sidebarSetting
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default getUserFileViewSettings;

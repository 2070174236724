import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const COPY_RESOURCES = gql`
  mutation CopyResource(
    $siteId: Int!
    $resourceIds: [ID!]
    $targetFolderId: Int!
  ) {
    folderContext {
      copyResources(
        siteId: $siteId
        resourceIds: $resourceIds
        targetFolderId: $targetFolderId
      ) {
        success
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default COPY_RESOURCES;

import { gql } from '@apollo/client';

const getSelectableSuppliers = gql`
  query getSelectableSuppliers($orderGroupId: Int!, $shoppingCartItems: [ID!]) {
    shoppingCartContext {
      selectableSuppliers(
        orderGroupId: $orderGroupId
        shoppingCartItems: $shoppingCartItems
      ) {
        displayName
        siteSupplierId
      }
    }
  }
`;

export default getSelectableSuppliers;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_STOCK = gql`
  mutation UpdateStock($update: StockEditInput) {
    updateStock(update: $update) {
      stockEdit {
        allowNegativeStockBalance
        productDescription
        productName
        refillEmail
        refillThreshold
        stockBalance
        stockDescription
        stockLocation
        temporaryOutOfStock
      }
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_STOCK;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Box, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { companyRoleErrorCodes, subscriptionLevels } from "../../../constant";
import {
  DELETE_COMPANY_ROLE,
  UPDATE_CREATE_COMPANY_ROLE,
} from "../../../graphql/mutations/CompanyAdminContext";
import {
  GET_COMPANY_ROLE,
  LIST_COMPANY_ROLES,
} from "../../../graphql/queries/CompanyAdminContext";
import { getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";
import ManageRolesHeader from "../../RoleManagement/ManageRolesHeader";
import Modules from "../../RoleManagement/Modules";
import CompanyRolesSettings from "./CompanyRolesSettings";
import EditMenusModal from "./EditMenusModal";
import { initialRights, settingKeys, tempInitialKeys } from "./helpers";
import SelectRole from "./SelectRole";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  body: {
    padding: 20,
  },
  label: {
    fontSize: 11,
    marginBottom: 5,
  },
}));

const CompanyRoles = () => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const [companyRoles, setCompanyRoles] = useState([]);

  const companyId = useSelector(getAdminSelectedCompanyId);

  const { data: companyRolesData, loading, refetch } = useQuery(
    LIST_COMPANY_ROLES,
    {
      variables: {
        companyId: companyId || 0,
      },
      skip: (companyId || 0) === 0,
    }
  );

  useEffect(() => {
    if (
      companyRolesData &&
      companyRolesData.companyAdminContext.companyRoles.listCompanyRoles
        .companyRoleListItems
    ) {
      const listRoles = companyRolesData.companyAdminContext.companyRoles.listCompanyRoles.companyRoleListItems.map(
        x => {
          return { label: x.name, value: x.companyRoleId };
        }
      );
      setCompanyRoles(listRoles);
    }
  }, [companyRolesData]);

  const [
    getCompanyRole,
    { data: companyRoleData, loading: companyRoleLoading },
  ] = useLazyQuery(GET_COMPANY_ROLE);

  const [updateCompanyRoles, { loading: updateRoleLoading }] = useMutation(
    UPDATE_CREATE_COMPANY_ROLE
  );

  const [deleteCompanyRole] = useMutation(DELETE_COMPANY_ROLE);

  const [role, setRole] = useState(0);
  const [roleName, setRoleName] = useState("");
  const [addRole, setAddRole] = useState(false);
  const [settingValues, setSettingValues] = useState({ companySettings: true });
  const [roleValues, setRoleValues] = useState(initialRights);

  const [roleSubscriptionLevel, setRoleSubscriptionLevel] = useState({});
  const [currentSubscriptionLevel, setCurrentSubscriptionLevel] = useState(
    null
  );

  const [editMenu, setEditMenu] = useState(false);

  const handleError = errorResult => {
    const { errorCode, errorMessage } = errorResult;
    if (errorCode === companyRoleErrorCodes[errorCode]) {
      console.error("error >", errorMessage);
      alert.error(<FormattedMessage id="companyRoles.companyRoleInUse" />);
    } else {
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
    }
  };

  const handleOpenEditMenusModal = () => {
    if (role) {
      setEditMenu(true);
    } else {
      alert.info(<FormattedMessage id="companyRoles.saveRoleToEditMenu" />);
    }
  };

  const handleCloseEditMenusModal = () => {
    setEditMenu(false);
  };

  useEffect(() => {
    if (role) {
      let subscriptions = [];
      const keys = Object.keys(tempInitialKeys);
      keys.forEach(key => {
        if (typeof roleValues[key] === "boolean" && roleValues[key]) {
          const tempLevel = roleSubscriptionLevel[key];
          subscriptions = [...subscriptions, subscriptionLevels[tempLevel]];
        }
      });
      const newSubscriptionLevel = Math.max(...subscriptions);
      setCurrentSubscriptionLevel(
        Number.isInteger(newSubscriptionLevel) ? newSubscriptionLevel : 1
      );
    }
  }, [roleValues, role]);

  useEffect(() => {
    if (companyId && role) {
      getCompanyRole({ variables: { companyId, companyRoleId: role } });
    }
  }, [companyId, role]);

  useEffect(() => {
    if (
      companyRoleData &&
      companyRoleData.companyAdminContext &&
      companyRoleData.companyAdminContext.companyRoles &&
      companyRoleData.companyAdminContext.companyRoles.getCompanyRole &&
      companyRoleData.companyAdminContext.companyRoles.getCompanyRole
        .companyRole
    ) {
      const {
        companyRoleRights,
        subscriptionLevel,
      } = companyRoleData.companyAdminContext.companyRoles.getCompanyRole.companyRole;
      setRoleValues(companyRoleRights);
      setRoleSubscriptionLevel(companyRoleRights.subscriptionLevels);
      setCurrentSubscriptionLevel(subscriptionLevels[subscriptionLevel]);
    }
  }, [companyRoleData]);

  const handleCheckbox = e => {
    const { checked, name } = e.target;
    if (!name) {
      console.error("Check box has no name. Site roles.");
      return;
    }
    setRoleValues({ ...roleValues, [name]: checked });
  };

  const handleSelect = e => {
    const { name, value } = e.target;

    setRoleValues({ ...roleValues, [name]: value });
  };

  const handleRoleName = e => {
    const { value } = e.target;
    setRoleName(value);
  };

  const handleEnterEmail = e => {
    const { value } = e.target;
    setRoleValues({
      ...roleValues,
      userAccountAddressUpdateRequestEmail: value,
    });
  };

  const handleUpdateCompanyRole = async () => {
    let companyRole = {};
    const companyRoleRights = { ...roleValues };
    delete companyRoleRights.subscriptionLevels;

    if (addRole) {
      companyRole = {
        companyRoleRights,
        companyRoleId: 0,
        companyId,
        companyRoleName: roleName,
      };
    } else {
      companyRole = {
        companyRoleRights,
        companyRoleId: role,
        companyId,
        companyRoleName: roleName,
      };
    }

    try {
      const res = await updateCompanyRoles({
        variables: { companyRole },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext.companyRoles &&
        res.data.companyAdminContext.companyRoles.updateCreateCompanyRole
      ) {
        const {
          success,
          companyRoleId,
          companyRoleListItems,
        } = res.data.companyAdminContext.companyRoles.updateCreateCompanyRole;

        if (success) {
          const newRoles = companyRoleListItems.map(x => {
            return { label: x.name, value: x.companyRoleId };
          });

          const found = newRoles.find(x => x.value === companyRoleId);

          setCompanyRoles(newRoles);
          setRole(found.value);
          setRoleName(found.label);
          setAddRole(false);

          alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
    }
  };

  const handleDeleteCompanyRole = async () => {
    try {
      const res = await deleteCompanyRole({
        variables: { companyId, companyRoleId: role },
      });
      if (
        res &&
        res.data &&
        res.data.companyAdminContext.companyRoles &&
        res.data.companyAdminContext.companyRoles.deleteCompanyRole
      ) {
        const {
          success,
          errorResult,
          companyRoleListItems,
        } = res.data.companyAdminContext.companyRoles.deleteCompanyRole;

        if (success) {
          const newRoles = companyRoleListItems.map(x => {
            return { label: x.name, value: x.companyRoleId };
          });
          setCompanyRoles(newRoles);
          setRole(0);
          setRoleName("");
          setRoleValues(initialRights);
          setAddRole(true);
          alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          handleError(errorResult);
        }
      }
    } catch (err) {
      console.error("error >", err);
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
    }
  };

  const handleOpenMenu = name => {
    if (settingValues[name]) {
      setSettingValues({ [name]: false });
    } else {
      setSettingValues({ [name]: true });
    }
  };

  const handleAddRole = () => {
    setAddRole(true);
    setRole(0);
    setRoleName("");
    setRoleValues(initialRights);
  };

  const handleSelectRole = e => {
    const { value } = e.target;
    handleSelectRoleById(value);
  };

  const handleSelectRoleById = id => {
    const found = companyRoles.find(x => x.value === id);
    setRole(id);
    setRoleName(found.label);
    setAddRole(false);
  };

  const settings = [
    {
      label: intl.formatMessage({
        id: "companyRoles.companySettings",
      }),
      name: "companySettings",
      checkbox: true,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <FormattedMessage id="companyRoles.companyRoles" />
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <SelectRole
              role={role}
              setRole={handleSelectRole}
              addRole={addRole}
              setAddRole={setAddRole}
              roleName={roleName}
              handleAddRole={handleAddRole}
              handleUpdateCompanyRole={handleUpdateCompanyRole}
              companyRoles={companyRoles}
              updateRoleLoading={updateRoleLoading}
              handleDeleteCompanyRole={handleDeleteCompanyRole}
            />
            {(role !== 0 || addRole) && (
              <Modules
                handleOpenMenu={handleOpenMenu}
                handleOpenEditMenus={handleOpenEditMenusModal}
                settingKeys={settingKeys}
                settingValues={settingValues}
                setSettingValues={setSettingValues}
                roleValues={roleValues}
                settings={settings}
              />
            )}
            {companyRoleLoading && (
              <Grid container justifyContent="center">
                <CircularProgress size={30} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={10}>
            {(role !== 0 || addRole) && (
              <>
                <ManageRolesHeader
                  roleName={roleName}
                  handleChange={handleRoleName}
                  currentSubscriptionLevel={currentSubscriptionLevel}
                />
                <CompanyRolesSettings
                  roleValues={roleValues}
                  settingValues={settingValues}
                  handleCheckbox={handleCheckbox}
                  handleSelect={handleSelect}
                  subscriptionLevels={roleSubscriptionLevel}
                  handleEnterEmail={handleEnterEmail}
                />
              </>
            )}
          </Grid>
        </Grid>

        {editMenu && (
          <EditMenusModal
            open={editMenu}
            handleClose={handleCloseEditMenusModal}
            companyId={companyId}
            companyRoleId={role}
          />
        )}
      </Box>
    </Box>
  );
};

export default CompanyRoles;

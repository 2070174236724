import React, { useState } from "react";

//import TwoFactorAuthentication from "./TwoFactorAuthentication";

import { Tab, Tabs, Box, useMediaQuery } from "@mui/material"

import lodash from "lodash";
import PasswordPanel from "./PasswordPanel";
import TwoFactorAdmin from "./TwoFactorAdmin";

const AdminSecurityTab = ({ accountSettings, setAccountSettings, userId }) => {

  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const {
    enabledMFATypes,
    email
  } = accountSettings || {};

  const handle2FaUpdates = (newValues) => {

    var temp = lodash.cloneDeep(accountSettings);
    temp.enabledMFATypes = newValues;
    setAccountSettings(temp);

  }

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);    
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (<Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224, flexDirection: isMdDown ? "column" : "row", }}
  >
    
    <Tabs
      orientation={isMdDown ? "horizontal" : "vertical"}
      value={tabValue}
      onChange={handleTabChange}
      aria-label="security tabs"
      fullWidth={isMdDown}
      sx={{ borderRight: 1, borderColor: 'divider', marginRight: 2, alignItems: "end", }}
    >
      <Tab label="Lösenord" />
      <Tab label="Tvåfaktorsautentisering" />

    </Tabs>
    {tabValue === 0 && (<Box><PasswordPanel userId={userId }/></Box>)}
    {tabValue === 1 && (<Box>      
      <TwoFactorAdmin enabledMFATypes={enabledMFATypes} onUpdateMFASettings={handle2FaUpdates} userId={userId } />
    </Box>)}
    
    </Box>);
}

export default AdminSecurityTab;
import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 0,
  },
  textField: {
    fontSize: 12,
  },
}));

const SelectField = ({
  error,
  isRequired,
  fieldName,
  displayName,
  description,
  onChangeValue,
  options,
  value,
}) => {
  const classes = useStyles();
  let filteredDescription = description.replace("[", "");
  filteredDescription = filteredDescription.replace("]", "");
  return (
    <div>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel
          required={isRequired}
          htmlFor={fieldName}
          className={classes.textField}
          error={error ? error.error : false}
        >
          {value && <span>{displayName === "" ? fieldName : displayName}</span>}
          {filteredDescription && (
            <span style={{ color: "grey" }}>{`${value &&
              ` | `}${filteredDescription}`}</span>
          )}
        </InputLabel>
        <Select
          className={classes.textField}
          value={value || ""}
          error={error ? error.error : false}
          onChange={onChangeValue}
          name={fieldName}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              className={classes.textField}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

SelectField.defaultProps = {
  displayName: null,
  isRequired: false,
  error: null,
  description: "",
};

SelectField.propTypes = {
  description: PropTypes.string,
  error: PropTypes.shape({
    error: PropTypes.bool,
  }),
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SelectField;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import {
  Typography,
  Grid,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../../common/TextField";
import LogoEdit from "../CompanyInfo/LogoEdit";
import CustomSelect from "../../common/CustomSelect";
import TextAreaFull from "../../common/FormControl/TextAreaFull";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import PrintViewModal from "./PrintViewModal";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  text: {
    fontSize: 12,
    marginTop: 10,
    "& span": {
      float: "right",
    },
  },
  switchRoot: {
    marginRight: 30,
  },
  switchLabel: {
    fontSize: 12,
    fontWeight: 600,
  },
  switchLabel2: {
    fontSize: 12,
  },
  selectWrap: {
    "& $label": {
      marginBottom: 8,
    },
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginTop: 5,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
}));

const EditPublicationModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const [openPrintView, setOpenPrintView] = useState(false);
  const handleOpenPrintView = () => {
    setOpenPrintView(true);
  };
  const handleClosePrintView = () => {
    setOpenPrintView(false);
  };

  return (
    <>
      <DraggableModal
        open={open}
        handleClose={handleClose}
        customClass={classes.modal}
      >
        <DraggableModalHead
          handleClose={handleClose}
          title="Edit publication"
        />
        <DraggableModalBody>
          <Grid container spacing={3} className={classes.formControl}>
            <Grid item xs={12} md={3}>
              <LogoEdit />
              <Box mb={1} />
              <FormControlLabel
                control={<Switch name="checkedB" color="primary" />}
                label="Active"
                classes={{
                  root: classes.switchRoot,
                  label: classes.switchLabel,
                }}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} className={classes.selectWrap}>
                  <Typography className={classes.label}>Binding</Typography>
                  <CustomSelect options={[{ label: "Glued", value: 1 }]} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>Article</Typography>
                  <CustomTextField />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>Name</Typography>
                  <CustomTextField />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>Format</Typography>
                  <CustomTextField />
                </Grid>
              </Grid>
              <Box mb={1} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>Price list</Typography>
                  <Typography className={classes.text}>
                    Enter the number and price of the staggered price list.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.text}>Name</Typography>
                      <CustomTextField />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.text}>VAT Rate</Typography>
                      <CustomTextField />
                    </Grid>
                  </Grid>
                  <Button className={classes.addLink}>
                    <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
                    Add row
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.text}>
                    Start cost <span>kr</span>
                  </Typography>
                  <CustomTextField />
                  <Box mb={1} />
                  <FormControlLabel
                    control={
                      <Switch name="checkedB" color="primary" size="small" />
                    }
                    label="Show price when ordering"
                    classes={{
                      root: classes.switchRoot,
                      label: classes.switchLabel2,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch name="checkedB" color="primary" size="small" />
                    }
                    label="Compelling edition"
                    classes={{
                      root: classes.switchRoot,
                      label: classes.switchLabel2,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>
                    Page Range (Number of Sheets)
                  </Typography>
                  <CustomTextField />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>Sends to</Typography>
                  <CustomTextField />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>
                    Production Notes. Notice to production.
                  </Typography>
                  <TextAreaFull rows="5" rowsMax="5" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>
                    Other properties, e.g. color.
                  </Typography>
                  <TextAreaFull rows="5" rowsMax="5" />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>
                    Preflight-profil
                  </Typography>
                  <CustomTextField />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.label}>
                      Available templates
                    </Typography>
                    <IconButton className={classes.iconButtonEdit}>
                      <Icon
                        className={clsx(["fa fa-pencil-alt", classes.icon])}
                      />
                    </IconButton>
                    <Box mr={4} />
                    <Typography className={classes.label}>
                      PrintVis settings
                    </Typography>
                    <IconButton
                      className={classes.iconButtonEdit}
                      onClick={handleOpenPrintView}
                    >
                      <Icon
                        className={clsx(["fa fa-pencil-alt", classes.icon])}
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Box mb={2} />
              <Typography className={classes.label}>
                Tab systems to be available for publication
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <CheckboxDefault label="10 tabs" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CheckboxDefault label="12 tabs" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CheckboxDefault label="15 tabs" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CheckboxDefault label="20 tabs" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CheckboxDefault label="31 tabs" />
                </Grid>
                <Grid item xs={12} md={2}>
                  <CheckboxDefault label="5 tabs" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.modalFooter} align="right">
            <Button className={classes.btnSave}>
              <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
              <FormattedMessage id="btn.saveChanges" />
            </Button>
          </Box>
        </DraggableModalBody>
      </DraggableModal>
      <PrintViewModal open={openPrintView} handleClose={handleClosePrintView} />
    </>
  );
};

EditPublicationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditPublicationModal;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Slider } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../DraggableModal";
import DraggableModalHead from "../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";
import { PrimaryButton } from "../Buttons";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  slider: {
    width: 215,
  },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "5px 15px",
    backgroundColor: "#66615B",
    color: "#fff",
    minWidth: 196,
  },
  userAvatar: {
    width: 200,
    height: 200,
    borderWidth: 7,
    borderColor: "#fff",
    borderStyle: "solid",
    marginRight: 28,
    marginTop: "-4px",
  },
  btnSave: {
    marginTop: 32,
    marginRight: 30,
    minWidth: 196,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
  canvas: {
    width: props => (props.width > 1000 ? "100%!important" : props.width),
    height: props => `${props.height}px!important`,
  },
}));

const AvatarEditorModal = ({
  open,
  image,
  setImage,
  width,
  height,
  borderRadius,
  border,
  scale,
  handleClose,
  setEditorRef,
  handleScale,
  onClick,
}) => {
  const customStyle = { width, height };
  const classes = useStyles(customStyle);

  const handleUploadFile = e => {
    const img = e.target.files[0];
    setImage(URL.createObjectURL(img));
  };

  return (
    <DraggableModal open={open} customClass={classes.modal}>
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.body}>
        <Box className={classes.wrapper}>
          <AvatarEditor
            className={classes.canvas}
            ref={setEditorRef}
            image={image}
            width={width}
            height={height}
            borderRadius={borderRadius}
            border={border}
            scale={scale}
          />
        </Box>

        <Box className={classes.wrapper}>
          <Box style={{ marginRight: 10 }}>Zoom: </Box>
          <Slider
            min={0.1}
            className={classes.slider}
            value={scale}
            onChange={handleScale}
            aria-labelledby="continuous-slider"
            step={0.01}
            max={5}
          />
        </Box>

        <Box className={classes.wrapper}>
          <Button
            variant="contained"
            className={classes.uploadButton}
            component="label"
          >
            <FormattedMessage id="btn.uploadFromComputer" />
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleUploadFile}
            />
          </Button>
        </Box>

        <Box className={classes.wrapper}>
          <PrimaryButton className={classes.uploadButton} onClick={onClick}>
            <FormattedMessage id="btn.save" />
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default AvatarEditorModal;

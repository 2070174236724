import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Divider } from "@mui/material";

import { STRUCTURE_TYPES } from "../../../constant/types";
import getStructureTreeData from "../../../helpers/getStructureTreeDataStructure";
import { WorkspaceList } from "../WorkspaceList";
import SearchInput from "../WorkspaceSelectorSearchInput";

const WorkspaceContainer = ({
  onClick,
  searchable,
  searchAll,
  workspaces,
}) => {
  const intl = useIntl();

  const [structureTree, setStructureTree] = useState({});

  useEffect(() => {
    const tree = createStructureTree(workspaces);
    setStructureTree(tree);
  }, [workspaces]);

  const createStructureTree = (workspaceArray) => {
    let temp;

    if (searchAll) {
      const all = {
        id: Math.random(),
        color: null,
        name: intl.formatMessage({ id: "detailedSearch.all" }),
        description: null,
        siteId: 0,
        type: "SITE",
        projectId: null,
        issueId: null,
      };

      temp = [all, ...workspaceArray];
    }
    else {
      temp = [...workspaceArray];
    }

    return getStructureTreeData({
      structures: temp,
    });
  };

  const handleSearchInputChange = query => {
    let filteredTree = {};

    if (!query) {
      const tree = createStructureTree(workspaces);
      return setStructureTree(tree);
    }

    const keys = Object.keys(structureTree);

    keys.forEach(x => {
      if (
        structureTree[x].name.toLowerCase().includes(query) &&
        structureTree[x].type !== STRUCTURE_TYPES.FOLDER
      ) {
        const tempValues = { ...structureTree[x], isRoot: true };
        const found = { [x]: tempValues };
        filteredTree = { ...filteredTree, ...found };
      }
    });

    setStructureTree(filteredTree);
  };

  return (
    <Box>
      {searchable && <SearchInput onChange={handleSearchInputChange} />}
      {searchable && <Divider />}
      <WorkspaceList
        onClick={onClick}
        structureTree={structureTree}
      />
    </Box>
  );
};

WorkspaceContainer.defaultProps = {
  searchable: false,
};

WorkspaceContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  searchAll: PropTypes.bool,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default WorkspaceContainer;

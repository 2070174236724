import React, { useState, useEffect } from "react";

import { useQuery, useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { GET_UNSUBSCRIBEINFO } from "../../graphql/queries";
import { UNSUBSCRIBE_NOTIFICATION } from "../../graphql/mutations";
import { useSnackbar } from "../../hooks";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Paper, Box, Button } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paperBody: {
    padding: theme.spacing(2),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    display: "block",
    color: theme.palette.primary.main,
  },
  btn: {
    padding: "8px 15px",
    fontWeight: 600,
    marginTop: 10,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  spacing: {
    margin: "10px 0",
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
}));

const UnsubscribeNotification = () => {
  const classes = useStyles();
    const snackbar = useSnackbar();

  const useSearchParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  const searchParams = useSearchParams();
  const [savedNotificationId, setsavedNotificationId] = useState(0);
  const [notificationInfo, setNotificationInfo] = useState({
    name: "",
    allowed: false,
  });

  useEffect(() => {
    const s = searchParams.get("savedNotificationId");

    if (!!s) {
      setsavedNotificationId(s);
    }
  });

  const { data: notificationdata, loading, refetch } = useQuery(
    GET_UNSUBSCRIBEINFO,
    {
      variables: {
        savedNotificationId: savedNotificationId,
      },
      skip: savedNotificationId === 0,
    }
  );

  const [unsubscribeUser] = useMutation(UNSUBSCRIBE_NOTIFICATION);

  useEffect(() => {
    if (
      notificationdata &&
      notificationdata.myNotificationContext &&
      notificationdata.myNotificationContext.getSavedNotificationInfo
    ) {
      setNotificationInfo(
        notificationdata.myNotificationContext.getSavedNotificationInfo
      );
    }
  }, [notificationdata]);

  const handleUnsubscribe = async () => {
    const res = await unsubscribeUser({
      variables: {
        savedNotificationId: savedNotificationId,
      },
    });

    if (res && res.data) {
      const success = res.data.unsubscribeNotification;
      if (success) {
        setNotificationInfo({ allowed: false });
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="common.genericErrorMessage" />
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {loading && <Box>Laddar...</Box>}
        {!loading && notificationInfo && notificationInfo.allowed && (
          <Box>
            Vill du avbryta prenumerationen av{" "}
            <strong>{notificationInfo.name}</strong>?
            <div>
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                onClick={handleUnsubscribe}
              >
                Avbryt prenumeration
              </Button>
            </div>
          </Box>
        )}

        {!loading && notificationInfo && !notificationInfo.allowed && (
          <Box>Du är ej prenumerant av denna bevakning.</Box>
        )}
      </Paper>
    </div>
  );
};

export default UnsubscribeNotification;

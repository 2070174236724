import React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MessageSection from "./MessageSection";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MessageDialog = ({
  open,
  onClose,
  activeChannelId,
  activeConversationId,
  channel,
  channelLoading,
  conversation,
  conversationLoading,
}) => {
  const classes = useStyles();

  let name = "";
  if (channelLoading || conversationLoading) {
    name = "...";
  }
  if (activeChannelId && channel && channel.channelMessages) {
    name = "#" + channel.name;
  } else if (activeConversationId && conversation && conversation.messages) {
    name = conversation.members.map(n => "@" + n.name).join(", ");
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {name}
            </Typography>
          </Toolbar>
        </AppBar>

        <MessageSection
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          channel={channel}
          channelLoading={channelLoading}
          conversation={conversation}
          conversationLoading={conversationLoading}
        />
      </Dialog>
    </div>
  );
};

export default MessageDialog;

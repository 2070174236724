import React from "react";
import PropTypes from "prop-types";
import { TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  td: {
    fontSize: 12,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 12,
    paddingBottom: 12,
    wordBreak: "break-all",
    hyphens: "auto",
  },
}));

const TableBodyCell = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.td}>{children}</TableCell>;
};

TableBodyCell.defaultProps = {};

TableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableBodyCell;

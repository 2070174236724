import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";

import AddressSection from "./AddressSection";
import AboutSection from "./AboutSection";
import ColumnThree from "./AboutSection/ColumnThree";
import SettingsIcon from '@mui/icons-material/Settings';
import ShieldIcon from '@mui/icons-material/Shield';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';

import SecurityTab from "./SecurityTab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AccountTabPanel = ({
  isViewedAs,
  handleSaveAccountSettings,
  accountSettings,
  setAccountSettings,
  setDraftAddress,
  onTabChange
}) => {
  const [value, setValue] = useState(0);
  
  const intl = useIntl();

  const handleChange = (event, newValue) => {
    if (newValue === 0 || newValue === 2) {
      setDraftAddress(false);
    }
    setValue(newValue);

    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ boxShadow: "none", backgroundColor: "white" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="true"
          allowScrollButtonsMobile
          aria-label="Account settings tab"
        >
          <Tab
            icon={<PersonIcon/> }
            label={intl.formatMessage({
              id: "account.about",
            })}
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
          />
          {!isViewedAs && (
            <Tab icon={<ContactMailIcon/> }
              label={intl.formatMessage({
                id: "account.addresses",
              })}
              {...a11yProps(1)}
              style={{ textTransform: "none" }}
            />
          )}
          {!isViewedAs && (
            <Tab icon={<ShieldIcon />} label="Säkerhet" {...a11yProps(3)} style={{ textTransform: "none" }} value={3} />
          )}
          {!isViewedAs && (
            <Tab icon={<SettingsIcon />} {...a11yProps(2)} style={{ textTransform: "none" }} label={intl.formatMessage({ id: "account.settings", })} value={2}/>              

          )}

        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {!isViewedAs && (
          <AboutSection
            accountSettings={accountSettings}
            setAccountSettings={setAccountSettings}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddressSection
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}
          handleSaveAccountSettings={handleSaveAccountSettings}
          setDraftAddress={setDraftAddress}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={1}>
          {!isViewedAs && (
            <ColumnThree
              accountSettings={accountSettings}
              setAccountSettings={setAccountSettings}
            />
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid container>
          <Grid item xs={12}>
            <SecurityTab accountSettings={accountSettings} setAccountSettings={setAccountSettings} />

          </Grid>
        </Grid>
      </TabPanel>
    </>
  );
};

AccountTabPanel.defaultProps = {
  passwordError: "",
  isViewedAs: false,
  accountSettings: {},
};

AccountTabPanel.propTypes = {
  incorrectPassword: PropTypes.bool.isRequired,
  isViewedAs: PropTypes.bool,
  passwordError: PropTypes.string,
  handleSaveAccountSettings: PropTypes.func.isRequired,
  accountSettings: PropTypes.shape({}),
  setAccountSettings: PropTypes.func.isRequired,
  setDraftAddress: PropTypes.func.isRequired,
};

export default AccountTabPanel;

export const SHOPPING_CART_SETTINGS = {
  deliveryAddressSettings: {
    address1: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Avdelning",
    },
    address2: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Sektion",
    },
    address3: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Gatuadress",
    },
    city: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Ort",
    },
    contact: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Leveransinfo",
    },
    country: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Land",
    },
    customerId: {
      type: "HIDDEN",
      displayOrder: 0,
      label: "Kundnummer",
    },
    email: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "E-post",
    },
    reference: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Referens",
    },
    telephone: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Telefon",
    },
    zip: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Postnummer",
    },
  },
  invoiceAddressSettings: {
    address1: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Avdelning",
    },
    address2: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Sektion",
    },
    address3: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Gatuadress",
    },
    city: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Ort",
    },
    contact: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Leveransinfo",
    },
    country: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Land",
    },
    customerId: {
      type: "HIDDEN",
      displayOrder: 0,
      label: "Kundnummer",
    },
    email: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "E-post",
    },
    reference: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Referens",
    },
    telephone: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Telefon",
    },
    zip: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Postnummer",
    },
  },
  postalAddressSettings: {
    address1: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Avdelning",
    },
    address2: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Sektion",
    },
    address3: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Gatuadress",
    },
    city: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Ort",
    },
    contact: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Leveransinfo",
    },
    country: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Land",
    },
    customerId: {
      type: "HIDDEN",
      displayOrder: 0,
      label: "Kundnummer",
    },
    email: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "E-post",
    },
    reference: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Referens",
    },
    telephone: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Telefon",
    },
    zip: {
      type: "OPTIONAL",
      displayOrder: 0,
      label: "Postnummer",
    },
  },
};

export const INITIAL_ADDRESS = {
  address1: "",
  address2: "",
  address3: "",
  city: "",
  contact: "",
  country: "",
  customerId: "",
  email: "",
  reference: "",
  telephone: "",
  zip: "",
};

export const INITIAL_ADDRESS_FORM_VALUES = {
  deliveryAddress: INITIAL_ADDRESS,
  invoiceAddress: INITIAL_ADDRESS,
  postalAddress: INITIAL_ADDRESS,
  selectedAddressField: null,
  deliveryAddressChanged: false,
};

export const initialFileSettings = {
  create: false,
  delete: false,
  download: false,
  lockChange: false,
  update: false,
};

export const initialFolderSettings = {
  changedName: false,
  create: false,
  delete: false,
  fileCountEqualTo: false,
  fileCountEqualToValue: 1,
  fileCountGreaterThan: false,
  fileCountGreaterThanValue: 1,
};

export const initialFilesFoldersSettings = {
  fileFolderCreate: false,
  fileFolderDownload: false,
  fileFolderUpdate: false,
  fileFolderLockChange: false,
  fileFolderDelete: false,
  fileCountEqualTo: false,
  fileCountEqualToValue: 1,
  fileCountGreaterThan: false,
  fileCountGreaterThanValue: 1,
};

export const initialRoleSettings = {
  create: false,
  delete: false,
  update: false,
};

export const initialUserSettings = {
  create: false,
  delete: false,
  update: false,
};

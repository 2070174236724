import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IconTextLink } from "../../common";

const useStyles = makeStyles(() => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexGrow: 2,
  },
  backButton: {
    marginLeft: 8,
    "& > span": {
      marginRight: 4,
    },
  },
  description: {
    color: "#000",
    fontSize: 18,
    fontWeight: 500,
  },
}));

const SearchResultsFor = ({ folderId, onClickBack }) => {
  const classes = useStyles();

  const folderName = useSelector(state => state.api.search.folderName);
  const searchQuery = useSelector(state => state.ui.toggleDetails.searchQuery);

  return (
    <Box className={classes.root}>
      <Typography className={classes.description}>
        {folderId && <FormattedMessage id="search.contentFor" values={{ folderName }} />}
        {!folderId && searchQuery && <FormattedMessage id="search.searchResultsFor" values={{ searchQuery }} />}
        {!folderId && !searchQuery && <FormattedMessage id="search.searchResults" values={{ searchQuery }} />}
      </Typography>
      {folderId &&
        <IconTextLink
          customClass={classes.backButton}
          fontSize={12}
          iconClass="fa fa-long-arrow-alt-left"
          onClick={onClickBack}
          text={<FormattedMessage id="cart.linkBack" />}
        />
      }
    </Box>
  );
};

export default SearchResultsFor;

import { gql } from '@apollo/client';
import Project from "../../fragments/Projects/Project";

const GET_PROJECT = gql`
  query getProject($projectId: Int!) {
    projects {
      getProject(projectId: $projectId) {
        ...Project
      }
    }
  }
  ${Project}
`;

export default GET_PROJECT;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headMarginBottom: {
    marginBottom: "10px",
  },	
  body: {
    padding: 0,
  },
}));

const GenericAdminPageSkeleton = ({ title }) => {
  const classes = useStyles();

  return (
    <>
      {title || (
        <Skeleton variant="rect" width="100%" height="4vh" animation="wave" />
      )}
      <Box className={classes.body}>
        <Skeleton variant="rect" width="100%" height="40vh" animation="wave" />
      </Box>
    </>
  );
};

export default GenericAdminPageSkeleton;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const RENAME_GROUP = gql`
  mutation RenameGroup($groupId: Int!, $newName: String!) {
    companyAdminContext {
      companyGroupSettings {
        renameGroup(groupId: $groupId, newName: $newName) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default RENAME_GROUP;

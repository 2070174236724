import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Container, Box, Typography, Button, Icon } from "@mui/material";
import { connect } from "react-redux";
import clsx from "clsx";
import NewsListTable from "../../components/News-Old/NewsListTable";
import CreateNewsModal from "../../components/common/CreateNewsModal";
import { GET_NEWS_FOR_SITE } from "../../graphql/queries/NewsContext";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
    marginTop: theme.spacing(4),
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
    overflowX: "auto",
  },
}));

const NewsList = ({ selectedWorkspace }) => {
  const classes = useStyles();

  const [newsList, setNewsList] = useState([]);

  const { data: newsSiteData } = useQuery(GET_NEWS_FOR_SITE, {
    variables: { siteId: selectedWorkspace && selectedWorkspace.id },
    skip: !selectedWorkspace && selectedWorkspace.id,
  });

  useEffect(() => {
    if (
      newsSiteData &&
      newsSiteData.newsContext &&
      newsSiteData.newsContext.getNewsForSite &&
      newsSiteData.newsContext.getNewsForSite.length > 0
    ) {
      const news = newsSiteData.newsContext.getNewsForSite;
      setNewsList(news);
    }
  }, [newsSiteData]);

  const [openCreateNews, setOpenCreateNews] = useState(false);
  const handleOpenCreateNews = () => {
    setOpenCreateNews(true);
  };
  const handleCloseCreateNews = () => {
    setOpenCreateNews(false);
  };

  return (
    <Container maxWidth="lg">
      <Box className={classes.root}>
        <Box className={classes.head}>
          <Typography className={classes.headTitle}>
            <FormattedMessage id="newsPost.newsPost" />
          </Typography>
          <Button className={classes.addLink} onClick={handleOpenCreateNews}>
            <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
            <FormattedMessage id="newsPost.addNewNewsPost" />
          </Button>
        </Box>
        <Box className={classes.body}>
          <NewsListTable newsList={newsList} />
        </Box>
      </Box>
      <CreateNewsModal
        open={openCreateNews}
        handleClose={handleCloseCreateNews}
      />
    </Container>
  );
};

NewsList.defaultProps = {
  selectedWorkspace: {
    id: null,
    name: null,
  },
};

NewsList.propTypes = {
  selectedWorkspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  return {
    selectedWorkspace: state.ui.toggleWorkspaces.workspace,
  };
};

export default connect(mapStateToProps)(NewsList);

import { gql } from '@apollo/client';

const getRolesForSite = gql`
  query ListSiteRolesForSite($siteId: Int!) {
    siteRoles {
      listSiteRolesForSite(siteId: $siteId) {
        name
        siteRoleId
      }
    }
  }
`;

export default getRolesForSite;

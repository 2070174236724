import { chunk } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLazyQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";

import { setSearchFolderId } from "../../../actions";
import {
  DOCUMENT_VIEW,
  FILE_VIEW,
  IMAGE_VIEW,
  PRODUCT_LIST_VIEW,
} from "../../../constant/types";
import {
  GET_PRODUCT_LIST,
  GET_RESOURCE_LIST,
  GET_RESOURCES,
} from "../../../graphql/queries";
import { useGetProducts } from "../../../hooks";
import ProductViewSkeleton from "../../Skeleton/ProductViewSkeleton";
import DocumentView from "./DocumentView/DocumentView.jsx";
import FileView from "./FileListView";
import ImageView from "./ImageView/ImageView.jsx";
// eslint-disable-next-line import/no-cycle
import ProductsListView from "./ProductListView";
import ProductHeader from "./ProductsHeader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const folderId = useSelector(state => state.api.search.folderId);
  const searchLoading = useSelector(state => state.api.search.loading);
  const productIds = useSelector(state => state.api.search.productIds);
  const resourceIds = useSelector(state => state.api.search.resourceIds);
  const viewSettings = useSelector(state => state.api.search.settings);
  const siteRoleRights = useSelector(state => state.ui.toggleWorkspaces.workspace.siteRoleRights);

  const [ascending, setAscending] = useState(false);
  const [breadcrumbs] = useState(true);
  const [metaDataSectionVisible, setMetaDataSectionVisible] = useState(false);
  const [openedNodes, setOpenedNodes] = useState([]);
  const [openMetaDataModal, setOpenMetaDataModal] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25); // !!!
  const [sortfield, setSortfield] = useState(3);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalResources, setTotalResources] = useState(0);
  const [view, setView] = useState(/*PRODUCT_LIST_VIEW*/);

  const initialView = PRODUCT_LIST_VIEW;

  useEffect(() => {
    const { detailedImageView, fileView, imageView, productView } = viewSettings || {};

    const eq = (a, b) => a === b;

    const visible = {
      [FILE_VIEW]: eq("VISIBLE", fileView),
      [DOCUMENT_VIEW]: eq("VISIBLE", detailedImageView),
      [IMAGE_VIEW]: eq("VISIBLE", imageView),
      [PRODUCT_LIST_VIEW]: eq("VISIBLE", productView),
    };

    if (!view && initialView && visible[initialView]) {
      setView(initialView);
      return;
    }

    if (visible[view]) {
      return;
    }

    if (visible[PRODUCT_LIST_VIEW]) {
      setView(PRODUCT_LIST_VIEW);
    } else if (visible[FILE_VIEW]) {
      setView(FILE_VIEW);
    } else if (visible[IMAGE_VIEW]) {
      setView(IMAGE_VIEW);
    } else if (visible[DOCUMENT_VIEW]) {
      setView(DOCUMENT_VIEW);
    } else {
      setView();
    }
  }, [viewSettings]);

  const {
    execute: getProducts,
    data: productsData,
    loading: productsLoading,
  } = useGetProducts();

  const [
    getProductList,
    { data: productListData, loading: productListLoading },
  ] = useLazyQuery(GET_PRODUCT_LIST);

  const [
    getResourceList,
    { data: resourceListData, loading: resourceListLoading },
  ] = useLazyQuery(GET_RESOURCE_LIST);

  const [
    getResources,
    { data: resourcesData, loading: resourcesLoading },
  ] = useLazyQuery(GET_RESOURCES);

  const [products, setProducts] = useState({ items: [] });
  const [resources, setResources] = useState([]);

  const handleSortedIds = itemIds => {
    const { sortedByName, sortedByArticleNo, sortedByRank } = itemIds || {};
    let ids;
    if (sortfield === 1) {
      ids = sortedByName;
    }

    if (sortfield === 2) {
      ids = sortedByArticleNo;
    }

    if (sortfield === 3) {
      ids = sortedByRank;
    }

    return ids;
  };

  const handleProductlist = itemIds => {
    const tempIds = handleSortedIds(itemIds) || [];
    setTotalProducts(tempIds.length);

    let tempProductIds = [];
    if (tempIds.length > pageSize) {
      const chunked = chunk(tempIds, pageSize);
      tempProductIds = chunked[page];
    } else {
      tempProductIds = tempIds;
    }

    const params = {
      breadcrumbs,
      productIds: tempProductIds,
    };
    getProductList({
      variables: {
        ...params,
      },
    });
  };

  const handleResourceList = itemIds => {
    const tempIds = handleSortedIds(itemIds) || [];
    setTotalResources(tempIds.length);
    let tempResourceIds = [];
    if (tempIds.length > pageSize) {
      const chunked = chunk(tempIds, pageSize);
      tempResourceIds = chunked[page];
    } else {
      tempResourceIds = tempIds;
    }
    const params = {
      breadcrumbs,
      resourceIds: tempResourceIds,
    };
    getResourceList({
      variables: {
        ...params,
      },
    });
  };

  const handleProducts = () => {
    const params = {
      folderId,
      ascending,
      offset: page * pageSize,
      pageSize,
      sortfield,
      breadcrumbs,
    };

    getProducts({ variables: { ...params } });
  };

  const handleResources = () => {

    const params = {
      folderId,
      ascending,
      offset: page * pageSize,
      pageSize,
      sortfield,
      breadcrumbs,
      includeLinkedResources: true,
    };

    getResources({ variables: { ...params } });
  };

  const handleClickBack = () => {
    setPage(0);
    dispatch(setSearchFolderId({ id: null }));
    if (view === FILE_VIEW) {
      handleResourceList();
    } else {
      handleProductlist();
    }
  };

  useEffect(() => {
    if (productsData && productsData.products) {
      const { items, totalCount } = productsData.products;
      setProducts({ items });
      setTotalProducts(totalCount || 0);
    }
  }, [productsData]);

  useEffect(() => {
    if (resourcesData && resourcesData.resources) {
      const { items, totalCount } = resourcesData.resources;
      setResources(items);
      setTotalResources(totalCount || 0);
    }
  }, [resourcesData]);

  useEffect(() => {
    if (folderId && (view === PRODUCT_LIST_VIEW || view === DOCUMENT_VIEW || view === IMAGE_VIEW)) {
      handleProducts();
    } else if (folderId && view === FILE_VIEW) {
      handleResources();
    } else if (productIds && (view === PRODUCT_LIST_VIEW || view === DOCUMENT_VIEW || view === IMAGE_VIEW)) {
      handleProductlist(productIds);
    } else if (resourceIds && view === FILE_VIEW) {
      handleResourceList(resourceIds);
    }
  }, [
    productIds,
    resourceIds,
    folderId,
    view,
    ascending,
    page,
    pageSize,
    sortfield,
    breadcrumbs,
  ]);

  useEffect(() => {
    setPage(0);
  }, [view, folderId]);

  useEffect(() => {
    if (
      productListData &&
      productListData.productList &&
      productListData.productList.items
    ) {
      const { items } = productListData.productList;
      setProducts({ items });
    }
  }, [productListData]);

  useEffect(() => {
    if (
      resourceListData &&
      resourceListData.resourceList &&
      resourceListData.resourceList.items
    ) {
      const { items } = resourceListData.resourceList;
      setResources(items);
    }
  }, [resourceListData]);

  const handlePage = (e, p) => {
    //Vi har lurat tablepagination.
    setPage(p + 1);
  };

  const handlePerPage = (e) => {
    setPage(0);
    setPageSize(e.target.value);
  };

  const ResultsView = () => {
    switch (view) {
      case FILE_VIEW:
        return (
          <FileView
            folderId={folderId}
            isSearch
            loading={resourceListLoading || resourcesLoading}
            openedNodes={openedNodes}
            openMetaDataModal={openMetaDataModal}
            resources={resources}
            setMetaDataSectionVisible={setMetaDataSectionVisible}
            setOpenMetaDataModal={setOpenMetaDataModal}
            siteRoleRights={siteRoleRights}
          />
        );
      case PRODUCT_LIST_VIEW:
        return (
          <ProductsListView products={products} />
        );
      case DOCUMENT_VIEW:
        return (
          <DocumentView products={products} />
        );
      case IMAGE_VIEW:
        return (
          <ImageView products={products} />
        );

      default:
        return null;
    }
  };

  const loading = searchLoading || productListLoading || productsLoading;

  const totalCount = (view === FILE_VIEW) ? totalResources : totalProducts;

  return (
    <div className={classes.root}>
      <ProductHeader
        ascending={ascending}
        folderId={folderId}
        onClickBack={handleClickBack}
        page={page}
        perPage={pageSize}
        setAscending={setAscending}
        setPage={(e, p) => setPage(p)}
        setPerPage={handlePerPage}
        setSortfield={setSortfield}
        setView={setView}
        sortfield={sortfield}
        totalCount={totalCount}
        view={view}
      />
      {!loading && <ResultsView />}
      {loading && <ProductViewSkeleton />}
    </div>
  );
};

export default ProductList;

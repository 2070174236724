import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useQuery, useLazyQuery } from "@apollo/client";
import OrderSearch from "../../components/OrderHistory/OrderSearch";
import OrderHistoryTable from "../../components/OrderHistory/OrderHistoryTable";
import {
  GET_ORDER_HISTORY,
  PRINT_ORDER_HISTORY,
  GET_ORDER_HISTORY_VIEW,
} from "../../graphql/queries";
import {
  getSelectedSiteOrderGroupId,
  getSiteId,
} from "../../helpers/selectors";
import { getDateOnly } from "../../helpers/get";
import "../../print.css";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const initialFromDate = new Date().setDate(new Date().getDate() - 14);

const initialFormValues = {
  fromDate: new Date(initialFromDate),
  toDate: new Date(),
  companyOrderNo: "",
  receiver: "",
  streetAddress: "",
  zipCode: "",
  user: "",
  city: "",
};

const OrderHistory = ({ orderGroupId, siteId }) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(initialFormValues);
  const {
    fromDate,
    toDate,
    companyOrderNo,
    receiver,
    streetAddress,
    zipCode,
    user,
    city,
    onlyMyOrders,
  } = formValues;

  const [page, setPage] = useState(0);
  const [ascending, setAscending] = useState(true);
  const [sortField, setSortField] = useState("orderId");
  const [pageSize, setPageSize] = useState(25);
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderHistorySettings, setOrderHistorySettings] = useState({});
  const [orderHistoryDetailSettings, setOrderHistoryDetailSettings] = useState(
    {}
  );

  const [getOrderHistory, { data, loading }] = useLazyQuery(GET_ORDER_HISTORY);

  const [printOrderHistory, { data: orderHistoryPrintData }] = useLazyQuery(
    PRINT_ORDER_HISTORY
  );

  const { data: orderHistoryView /*, loadingHistoryView*/ } = useQuery(
    GET_ORDER_HISTORY_VIEW,
    {
      variables: {
        orderGroupId,
        siteId,
      },
      skip: !orderGroupId || !siteId,
    }
  );

  useEffect(() => {
    if (orderHistoryView && orderHistoryView.orderHistoryView) {
      if (orderHistoryView.orderHistoryView.settings) {
        setOrderHistorySettings(orderHistoryView.orderHistoryView.settings);
      }
      if (orderHistoryView.orderHistoryView.detailSettings) {
        setOrderHistoryDetailSettings(
          orderHistoryView.orderHistoryView.detailSettings
        );
      }
      setFormValues({
        ...formValues,
        onlyMyOrders: !orderHistoryView.orderHistoryView.settings
          .canSeeAllOrderHistory,
      });
      if (orderGroupId) {
        handleSearchClick();
      }
    }
  }, [orderHistoryView]);

  useEffect(() => {
    if (data && data.orderHistory) {
      if (data.orderHistory.items) {
        setOrders(data.orderHistory.items);
      }
      if (
        data.orderHistory.totalCount !== null ||
        data.orderHistory.totalCount !== undefined
      ) {
        setTotalCount(data.orderHistory.totalCount);
      }
    }
  }, [data]);

  useEffect(() => {
    if (orderHistoryPrintData && orderHistoryPrintData.orderHistoryPrint) {
      window.open(orderHistoryPrintData.orderHistoryPrint);
    }
  }, [orderHistoryPrintData]);

  const handleSearchClick = () => {
    const showOnlyMyOrders =
      !orderHistoryView.orderHistoryView.settings.canSeeAllOrderHistory ||
      !!onlyMyOrders;

    getOrderHistory({
      variables: {
        model: {
          offset: page * pageSize,
          orderGroupId,
          pageSize,
          sortDesc: !ascending,
          fromDate: getDateOnly(fromDate),
          toDate: getDateOnly(toDate),
          sortField,
          // For kommunal use companyorderNo when you search for orderNumber
          // But it seems not to filter. So change to orderNo for now.
          companyOrderNo,
          receiver,
          deliveryAddress2: streetAddress,
          deliveryZip: zipCode,
          user,
          city,
          onlyMyOrders: showOnlyMyOrders,
        },
      },
    });
  };

  const handleSortChanges = () => {
    handleSearchClick();
  };

  const handleMultiPrintOrder = () => {
    printOrderHistory({
      variables: { orderGroupId, orderIds: selectedOrders },
    });
  };

  const addSelectedOrder = order => {
    setSelectedOrders([...selectedOrders, order]);
  };

  const removeSelectedOrder = order => {
    const temp = selectedOrders.filter(x => x !== order);
    setSelectedOrders(temp);
  };

  useEffect(() => {
    if (orderGroupId && orderHistoryView) {
      handleSortChanges();
    }
  }, [orderGroupId, ascending, sortField, page, pageSize]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <OrderSearch
            formValues={formValues}
            setFormValues={setFormValues}
            onSearchClick={handleSearchClick}
            orderHistorySettings={orderHistorySettings}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <OrderHistoryTable
            addSelectedOrder={addSelectedOrder}
            ascending={ascending}
            handleMultiPrintOrder={handleMultiPrintOrder}
            loading={loading}
            orderGroupId={orderGroupId}
            orderHistoryDetailSettings={orderHistoryDetailSettings}
            orders={orders}
            page={page}
            pageSize={pageSize}
            removeSelectedOrder={removeSelectedOrder}
            selectedOrders={selectedOrders}
            setAscending={setAscending}
            setPage={setPage}
            setPageSize={setPageSize}
            setSortField={setSortField}
            sortField={sortField}
            totalCount={totalCount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    orderGroupId: getSelectedSiteOrderGroupId(state),
    siteId: getSiteId(state),
  };
};

OrderHistory.defaultProps = {
  orderGroupId: null,
  siteId: null,
};

OrderHistory.propTypes = {
  orderGroupId: PropTypes.number,
  siteId: PropTypes.number,
};

export default connect(mapStateToProps)(OrderHistory);

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { CustomSelect } from "../common";
import OneStreamLabel from "./OneStreamLabel";

const useStyles = makeStyles(() => ({
  selectWrapper: {
    marginBottom: 20,
  },
}));

const OneStreamManualType = ({ options, value, onChange, name }) => {
  const classes = useStyles();
  return (
    <Box className={classes.selectWrapper}>
      <OneStreamLabel message={"Manualtyp"} />
      <CustomSelect
        name={name}
        options={options}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
};

OneStreamManualType.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OneStreamManualType;

import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import Checkbox from "../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      padding: 0,
    },
  },
  thQuantity: {
    width: 100,
  },
  hideInMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ProductTableHead = ({
  cartItems,
  handleMultipleCheckboxChange,
  showUpdateSuppliers,
}) => {
  const classes = useStyles();

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = e => {
    const ids = cartItems.map(x => x.shoppingCartItemId);
    const { checked } = e.target;
    setIsChecked(checked);
    handleMultipleCheckboxChange(e, ids);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th} padding="checkbox">
          {/* TODO: markerar inte onestream-rader*/}
          <Checkbox value={isChecked} onChange={handleCheckboxChange} />
        </TableCell>
        <TableCell className={classes.th} />
        <TableCell className={classes.th}>
          <FormattedMessage id="product.product" />
        </TableCell>
        {showUpdateSuppliers && (
          <TableCell className={classes.th}>
            <FormattedMessage id="supplier.suppliers" />
          </TableCell>
        )}

        <TableCell className={clsx([classes.th, classes.hideInMobile])}>
          <FormattedMessage id="product.artno" />
        </TableCell>
        <TableCell
          className={clsx([classes.th, classes.thQuantity])}
          align="center"
        >
          <FormattedMessage id="product.quantity" />
        </TableCell>
        <TableCell className={classes.th} align="right">
          <FormattedMessage id="product.price" />
        </TableCell>
        <TableCell
          className={clsx([classes.th, classes.hideInMobile])}
          align="right"
        >
          <FormattedMessage id="product.total" />
        </TableCell>
        <TableCell className={classes.th} />
      </TableRow>
    </TableHead>
  );
};

ProductTableHead.defaultProps = {
  cartItems: [],
};

ProductTableHead.propTypes = {
  showUpdateSuppliers: PropTypes.bool.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
    })
  ),
  handleMultipleCheckboxChange: PropTypes.func.isRequired,
};

export default memo(ProductTableHead);

import React from "react";
import PropTypes from "prop-types";
import { FormattedDate } from "react-intl";

const FormattedDateComponent = ({ value }) => {
  if (!value) return null;

  return <FormattedDate value={value} />;
};

FormattedDateComponent.defaultProps = {
  value: null,
};

FormattedDateComponent.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
};

export default FormattedDateComponent;

import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import PropTypes from "prop-types";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Grid, Icon, IconButton, Link, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PageRoute from "../../../constant/pageRouteDictionary.json";
import { appcUrl } from "../../../services/config";
import {
  DraggableModal,
  DraggableModalBody,
  DraggableModalHead,
  TextAreaFull
} from "../../common";
import AdvancedShareSettingsModal from "./AdvancedShareSettingsModal";
import EmailChipInput from "./EmailChipInput";
import InviteOptions from "./InviteOptions";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  label: {
    fontSize: 12,
  },
  labelMargin: {
    margin: "5px 0",
  },
  shareHeader: {
    fontSize: 18,
    fontWeight: 600,
    verticalAlign: "middle",
    width: "100%",
    "& > span": {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  btnShareNext: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnLink: {
    padding: "5px 15px 5px 5px",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}33`,
    },
  },
  btnNext: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIconL: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  btnIconR: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  btnDisabled: {
    opacity: 0.8,
    "&.$Mui-disabled": {
      color: theme.palette.primary.contrastText,
    },
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  editButton: {
    color: theme.palette.common.link,
    marginBottom: 6,
    marginTop: 6,
  },
  editIcon: {
    fontSize: 10,
  },
}));

const findFolder = (folderId, folders) => {
  var found = folders.find(n => n.folderId == folderId);
  for (var i = 0; !found && i < folders.length; i++) {
    found = findFolder(folderId, folders[i].childFolders);
  }
  return found;
};

const InviteShareModal = ({
  folder,
  folders,
  handleClose,
  handleOpenHelpUser,
  initialView,
  open,
  setSiteUserComment,
  setSiteUserEmails,
  siteId,
  siteUserComment,
  siteUserEmails,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const isValidInput = Array.isArray(siteUserEmails) && (siteUserEmails.length > 0);

  const [advancedSettings, setAdvancedSettings] = useState();
  const [basicPermissions, setBasicPermissions] = useState({ read: true, write: false });
  const [dirtyShareName, setDirtyShareName] = useState(false);
  const [enableShareNameEdit, setEnableShareNameEdit] = useState(false);
  const [isCommentVisible, setIsCommentVisible] = useState(false);
  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(false);
  const [shareName, setShareName] = useState(`${intl.formatMessage({ id: "shares.share" })} ${folder.name}`);

  const createBaseUrl = (viewName) => {
    return `${appcUrl}${viewName}`;
  }

  const createShareDetails = () => {
    const common = {
      clientShareUrlBase: createBaseUrl(initialView),
      shareName,
    };

    if (advancedSettings) {
      return {
        ...common,
        ...advancedSettings
      };
    }

    const folders = [{ folderId: folder?.folderId, ...basicPermissions }];
    const resourceRights = [];
    const viewSelection = createViewSelection(initialView);

    return {
      ...common,
      folders,
      resourceRights,
      ...viewSelection,
    };
  };

  const createViewSelection = (view) => ({
    accessToFileView: view === PageRoute.FILE_VIEW,
    accessToProductView: view === PageRoute.PRODUCT_VIEW,
    accessToImageView: view === PageRoute.IMAGE_VIEW,
    accessToImageGalleryView: view === PageRoute.IMAGE_GALLERY_VIEW,
  });

  const createShareLinkUrl = () => {
    let viewName = initialView;
    let folderId = folder?.folderId;
    if (advancedSettings) {
      const { accessToFileView, accessToProductView, accessToImageView, accessToImageGalleryView } = advancedSettings;
      if (accessToFileView) {
        viewName = PageRoute.FILE_VIEW;
      }
      else if (accessToProductView) {
        viewName = PageRoute.PRODUCT_VIEW;
      }
      else if (accessToImageView) {
        viewName = PageRoute.IMAGE_VIEW;
      }
      else if (accessToImageGalleryView) {
        viewName = PageRoute.IMAGE_GALLERY_VIEW;
      }
      const firstSharedFolder = advancedSettings.folders.find(n => n.read || n.write);
      if (firstSharedFolder) {
        folderId = firstSharedFolder.folderId;
      }
    }
    const baseUrl = createBaseUrl(viewName);
    return `${baseUrl}/${siteId}/${folderId}`;
  }

  const handleCopyLinkClick = async () => {
    try {
      const url = createShareLinkUrl();
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error("** Unable to copy to clipboard.", err);
    }
  };

  const handleInviteOptionsChange = ({ value }) => {
    let newPermissions = {};
    if (value === "read") {
      newPermissions = { read: true, write: false };
    }
    else if (value === "write") {
      newPermissions = { read: true, write: true };
    }
    setBasicPermissions(newPermissions);
  };

  const handleNextClick = () => {
    const shareDetails = createShareDetails();
    handleOpenHelpUser?.(shareDetails);
  };

  const handleSaveAdvancedSettings = (newSettings) => {
    setAdvancedSettings(newSettings);
    setOpenAdvancedSettings(false);

    if (!dirtyShareName) {
      updateShareName(newSettings);
    }
  };

  const handleShareNameChange = (e) => {
    const { value } = e?.target;
    setShareName(value);
    if (!dirtyShareName) {
      setDirtyShareName(true);
    }
  };

  const updateShareName = (newSettings) => {
    const folderIds = newSettings.folders.filter(n => n.read || n.write).map(n => n.folderId);
    if (folderIds.length > 0) {
      const name = findFolder(folderIds[0], folders)?.name;
      if (!!name) {
        let newShareName;
        if (folderIds.length > 1) {
          newShareName = `${intl.formatMessage({ id: "shares.shareNameTemplate.multiple" }, { name })}`;
        }
        else {
          newShareName = `${intl.formatMessage({ id: "shares.shareNameTemplate.single" }, { name })}`;
        }
        setShareName(newShareName);
      }
    }
  };

  return (
    <>
      <DraggableModal
        customClass={classes.modal}
        handleClose={handleClose}
        open={open}
      >
        <DraggableModalHead
          handleClose={handleClose}
          title={<FormattedMessage id="shares.share" />}
        />
        <DraggableModalBody>
          <Grid container xs={12}>

            <Grid item container alignItems="center" sx={{ marginTop: "6px !important" }}>
              <Grid item md={11} xs={11} className={classes.rowWrap}>
                <Box component="div" style={{
                  alignItems: 'center',
                  display: 'flex',
                }}>
                  <Typography component="div" className={classes.shareHeader}>
                    {!enableShareNameEdit && (
                      <Typography component="span" sx={{ marginTop: "2px" }}>
                        {shareName}
                      </Typography>
                    )}
                    {enableShareNameEdit && (
                      <TextField
                        defaultValue={shareName}
                        label={<FormattedMessage id="shares.shareName" />}
                        onChange={handleShareNameChange}
                        size="small"
                        sx={{ width: "calc(100% - 26px)" }}
                      />
                    )}
                    <IconButton
                      className={classes.editButton}
                      onClick={() => setEnableShareNameEdit((prev) => !prev)}
                    >
                      <Icon className={clsx(["fa fa-pencil-alt", classes.editIcon])} />
                    </IconButton>
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={1} xs={1} className={classes.label} align="right" sx={{ height: "100%" }}>
                <Button
                  onClick={() => setOpenAdvancedSettings(true)}
                  startIcon={<AddCircleOutlineIcon />}
                  sx={{
                    height: "38px",
                    minWidth: "20px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    textTransform: 'none',
                    "& .MuiButton-startIcon": {
                      marginLeft: "0px",
                      marginRight: "0px"
                    }
                  }}
                  variant="text"
                />
              </Grid>
            </Grid>

            {!advancedSettings && (
              <Grid item container alignItems="center" sx={{ marginTop: "1em" }}>
                <Grid item md={10} xs={12} className={classes.rowWrap}>
                  <Box component="div" style={{
                    alignItems: 'center',
                    display: 'flex',
                  }}>
                    <Typography className={classes.label}>
                      <FormattedMessage id="shares.anyoneWithTheLink" />
                    </Typography>
                    <InviteOptions
                      defaultValue="read"
                      onChange={handleInviteOptionsChange}
                    />
                  </Box>
                </Grid>
                {false && (
                  <Grid item md={2} xs={12} className={classes.labelEmail} align="right">
                    <Link className={classes.label} sx={{
                      borderBottom: '1px solid',
                      paddingBottom: '6px',
                      textDecoration: 'none',
                      '&:hover': {
                        borderBottom: '2px solid',
                      },
                    }}>
                      <FormattedMessage id="shares.settings" />
                    </Link>
                  </Grid>
                )}
              </Grid>
            )}

            <Grid item container sx={{ marginTop: "1.5em !important" }}>
              <Grid item md={12} xs={12} className={classes.label} align="right">
                <FormattedMessage id="siteUsers.seperateWithComma" />
              </Grid>
              <Grid item xs={12}>
                <EmailChipInput
                  label={<FormattedMessage id="siteUsers.enterEmailAddress" />}
                  setChips={setSiteUserEmails}
                  value={siteUserEmails}
                />
              </Grid>
            </Grid>

            {(siteUserEmails.length > 0) && (
              <Grid item container>
                <Button
                  className={classes.label}
                  onClick={() => setIsCommentVisible((prev) => !prev)}
                  startIcon={<AddIcon />}
                  sx={{ textTransform: 'none' }}
                  variant="text"
                >
                  <FormattedMessage id="siteUsers.addAComment" />
                </Button>
                {isCommentVisible && (
                  <TextAreaFull
                    maxRows={3}
                    minRows={3}
                    onChange={e => setSiteUserComment(e.target.value)}
                    value={siteUserComment}
                  />
                )}
              </Grid>
            )}

            <Grid container sx={{ justifyContent: 'space-between', marginTop: "1em" }}>
              <Grid item justify={"flex-start"}>
                <Button className={classes.btnLink} onClick={handleCopyLinkClick}>
                  <Icon className={clsx(["fa fa-link", classes.btnIconR])} />
                  <FormattedMessage id="shares.copyLink" />
                </Button>
              </Grid>
              <Grid item justify={"flex-end"}>
                <Button
                  classes={{ disabled: classes.btnDisabled }}
                  className={classes.btnShareNext}
                  disabled={!isValidInput}
                  onClick={handleNextClick}
                >
                  <FormattedMessage id="btn.next" />
                  <Icon className={clsx(["fa fa-long-arrow-alt-right", classes.btnIconL])} />
                </Button>
              </Grid>
            </Grid>

            <AdvancedShareSettingsModal
              folders={folders}
              initialFolderId={folder?.folderId}
              initialResourceId={undefined}
              initialView={initialView}
              onCancel={() => setOpenAdvancedSettings(false)}
              onSave={handleSaveAdvancedSettings}
              open={openAdvancedSettings}
            />

          </Grid>
        </DraggableModalBody>
      </DraggableModal>
    </>
  );
};

InviteShareModal.defaultProps = {
  handleOpenHelpUser: null,
  isShareModal: false,
  open: false,
  setSiteUserComment: null,
  setSiteUserEmails: null,
  siteRoleOptions: [],
  siteUserComment: "",
  siteUserEmails: [],
};

InviteShareModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOpenHelpUser: PropTypes.func,
  isShareModal: PropTypes.bool,
  open: PropTypes.bool,
  setSiteUserComment: PropTypes.func,
  setSiteUserEmails: PropTypes.func,
  siteRoleOptions: PropTypes.arrayOf(PropTypes.shape({})),
  siteUserComment: PropTypes.string,
  siteUserEmails: PropTypes.arrayOf(PropTypes.string),
};

export default InviteShareModal;

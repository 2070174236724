import { gql } from '@apollo/client';
import SiteChannelMessageConnectionFragment from "../fragments/SiteChannelMessageConnection";

const getSiteChannel = gql`
  query getSiteChannel($channelId: Int!, $last: Int) {
    siteChannels {
      channel(channelId: $channelId) {
        name
        private
        siteChannelId
        workspaceName
        unreadMessageCount
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
        channelMessages(last: $last) {
          ...SiteChannelMessageConnectionFragment
        }
      }
    }
  }
  ${SiteChannelMessageConnectionFragment}
`;

export default getSiteChannel;

import React, { useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { Button, IconButton } from "@mui/material";
import { useAlert } from "react-alert";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { DELETE_MONITORING_ITEM } from "../../graphql/mutations";
import RenderMonitoringType from "./RenderMonitoringType";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";
import NotificationTime from "./NotificationTime";
import NotificationSms from "./NotificationSms";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12,
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const MonitoringTableItem = ({
  monitoringItem,
  handleOpenModal,
  refetchMonitoring,
}) => {
  const {
    siteName,
    designation,
    folderName,
    numberOfRecipients,
    notificationActions,
    notificationOccasion,
    numberOfMonitoredResources,
    monitoringTypes,
    monitoringItemId,
  } = monitoringItem;

  const classes = useStyles();
  const alert = useAlert();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMonitoringItem] = useMutation(DELETE_MONITORING_ITEM);

  const handleRemoveMonitoring = async () => {
    try {
      const res = await deleteMonitoringItem({
        variables: { monitoringItemId },
      });

      if (
        res &&
        res.data &&
        res.data.monitorContext &&
        res.data.monitorContext.deleteMonitoringItem
      ) {
        refetchMonitoring();
        alert.success(<FormattedMessage id="monitoring.deleted" />);
      } else {
        alert.error(<FormattedMessage id="monitoring.error" />);
      }

      setDeleteModal(false);
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <TableBody>
      <TableRow>
        <TableCell className={classes.td}>
          <Button
            className={classes.link}
            onClick={() => handleOpenModal(monitoringItem.monitoringItemId)}
          >
            {designation}
          </Button>
        </TableCell>
        <TableCell className={classes.td}>
          <NotificationSms type={notificationActions} />
        </TableCell>
        <TableCell>
          {monitoringTypes.map(m => (
            <RenderMonitoringType type={m} key={m} />
          ))}
        </TableCell>
        <TableCell className={classes.td}>
          <NotificationTime type={notificationOccasion} />
        </TableCell>
        <TableCell className={classes.td}>{numberOfRecipients}</TableCell>
        <TableCell className={classes.td}>
          <IconButton
            className={classes.action}
            onClick={() => setDeleteModal(true)}
          >
            <Icon className={clsx(["fa fa-trash", classes.iconTrash])} />
          </IconButton>
        </TableCell>
      </TableRow>

      {deleteModal && (
        <ConfirmDeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          onConfirmCloseModal={handleRemoveMonitoring}
        />
      )}
    </TableBody>
  );
};

MonitoringTableItem.defaultProps = {
  refetchMonitoring: null,
};

MonitoringTableItem.propTypes = {
  refetchMonitoring: PropTypes.func,
  handleOpenModal: PropTypes.func.isRequired,
  monitoringItem: PropTypes.shape({
    monitoringItemId: PropTypes.number,
    siteName: PropTypes.string,
    designation: PropTypes.string,
    folderName: PropTypes.string,
    numberOfRecipients: PropTypes.number,
    numberOfMonitoredResources: PropTypes.number,
    monitoringTypes: PropTypes.arrayOf(PropTypes.string),
    notificationActions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    notificationOccasion: PropTypes.string,
  }).isRequired,
};

export default MonitoringTableItem;

import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  locationField: {
    marginTop: 4,
    marginBottom: 2,
    "& div.MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0)",
    },
    "& div.MuiInputBase-root": {
      fontSize: 12,
    },
  },
  input: {},
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const AddressRow = ({ userDetails }) => {
  const { location } = userDetails || {};
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.input}>
      {location && (
        <TextField
          className={classes.locationField}
          label={<FormattedMessage id="settings.location" />}
          name="location"
          value={location || ""}
          variant="standard"
          style={{ paddingRight: 5 }}
          size="small"
          margin="dense"
          disabled
          fullWidth
        />
      )}
    </Box>
  );
};

export default AddressRow;

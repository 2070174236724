const md5 = require("md5");

export const buildTree = (allTasks) => {
  var rootNodes = allTasks.filter((n) => n.parentTaskId === null);

  rootNodes.forEach((r) => {
    r.nodes = getChildren(r.id, allTasks);
  });

  return rootNodes;
}

function getChildren(id, allTasks) {
  var children = allTasks.filter((n) => n.parentTaskId === id);

  if (!children.length) {
    return;
  }

  children.forEach((b) => {
    b.nodes = getChildren(b.id, allTasks);
  });

  return children;
}

/** See '@table-library/react-table-library/common/util/tree' */
export const findNodeById = (nodesList, id) =>
  nodesList.reduce((acc, value) => {
    if (acc) return acc;
    if (value.id === id) return value;
    if (value.nodes) return findNodeById(value.nodes, id);
    return acc;
  }, null);

export const getGravatarUrl = name => {
  if (!name || name === "") return "";

  return `https://www.gravatar.com/avatar/${md5(name)}.png?d=identicon&s=25`;
};

export const renderDateCellValue = (value) => {
  if (value && value.dateTimeValue) {
    let date = new Date(value.dateTimeValue);
    if (date instanceof Date) {
      return date.toLocaleDateString();
    }
  }
  return "";
};

export const TimeLogAction = Object.freeze({
  ADD: "add_timelog",
  DELETE: "remove_timelog",
  UPDATE: "update_timelog"
});

export const TaskAction = Object.freeze({
  /**
   * Add task.
   */
  ADD: "add_task",
  /**
   * Delete task.
   */
  DELETE: "delete_task",
  /**
   * Edit task.
   */
  EDIT: "edit_task",
  /**
   * Move task.
   */
  MOVE: "move_task",
  /**
   * Update task.
   */
  UPDATE: "update_task",

  /**
   * Insert new
   */
  INSERT_NEW: "insert_new"
});

export const TaskAddPosition = Object.freeze({
  /**
   * As immediate successor.
   */
  AFTER: "after",
  /**
   * As immediate predecessor.
   */
  BEFORE: "before",
  /**
   * As a subtask.
   */
  SUBTASK: "subtask",

  /**
   * As a new task at the bottom
   */ 
  LAST: "last"
});

export const TASKS_LIST = [
  {
    id: "1",
    name: 'Lägg till ytterligare vyer för sökningen',
    description: '',
    startDate: "2024-01-15",
    endDate: "2024-01-19",
    status: "DONE",
    ownedBy: "Daniel",
    time: "13,5 h",
    values: [],
  },
  {
    id: "2",
    name: 'Ändring av ikoner för bildgallerivy',
    description: 'Ställ in mer passande ikoner för bildgalleryvyn, så att det passar Kommunals grafiska profil bättre.',
    startDate: "2024-01-15",
    endDate: "2024-01-19",
    status: "DONE",
    ownedBy: "Daniel",
    time: "6 h",
    values: [],
  },
  {
    id: "3",
    name: 'Fixar för djuplänkar och delning.',
    description: '',
    startDate: "2024-01-15",
    endDate: "2024-01-19",
    status: "DONE",
    ownedBy: "Carl-Johan",
    time: "5,5 h",
    values: [],
  },
  {
    id: "4",
    name: 'Ändring för prenumerationsnivåer',
    description: '',
    startDate: "2024-01-15",
    endDate: "2024-01-19",
    status: "DONE",
    ownedBy: "Carl-Johan",
    time: "1,5 h",
    values: [],
  },
  {
    id: "6",
    name: 'Genomgång sökningsgränsnitt',
    description: '',
    startDate: "2024-01-15",
    endDate: "2024-01-19",
    status: "DONE",
    ownedBy: "Robert",
    time: "6 h",
    values: [],
  },
  {
    id: "8",
    name: 'Ändringar av sökfunktion för bättre filtrering och träffar',
    description: '',
    startDate: "2024-01-15",
    endDate: "2024-01-19",
    status: "DONE",
    ownedBy: "Carl-Johan",
    time: "4,5 h",
    values: [],
  },
  {
    id: "9",
    name: 'Tvåfaktorsautentisering i Wilma',
    description: '',
    startDate: null,
    endDate: null,
    status: "NEW",
    ownedBy: "Robert",
    time: "4,5 dagar",
    values: [],
    nodes: [
      {
        id: "5",
        name: 'Möte med Göran Brindell. Möjligheter för 2FA Authentisering mm.',
        description: '',
        startDate: "2024-01-15",
        endDate: "2024-01-19",
        status: "DONE",
        ownedBy: "Robert",
        time: "2 h",
        values: [],
      },
      {
        id: "7",
        name: 'Inläsning ang TOTP (QR Codes, Microsoft Authenticator)',
        description: '',
        startDate: "2024-01-15",
        endDate: "2024-01-19",
        status: "DONE",
        ownedBy: "Carl-Johan",
        time: "0,5 h",
        values: [],
      },
      {
        id: "9.1",
        name: 'Policy för tvåfaktorsautentisering på ett företag',
        description: 'Policy ”Kräv tvåfaktorsautentisering för användare” på ett företag.',
        startDate: null,
        endDate: null,
        status: "NEW",
        ownedBy: "Robert",
        time: "0,5 dagar",
        values: [],
      },
      {
        id: "9.2",
        name: 'Säkerhetspanel för tvåfaktorsautentisering under mina sidor',
        description: 'På sidan ska det finnas instruktioner för hur man lägger in i Authenticator-app med QR-kod för tvåfaktorsautentisering.',
        startDate: null,
        endDate: null,
        status: "NEW",
        ownedBy: "Robert",
        time: "0,5 dagar",
        values: [],
      },
      {
        id: "9.3",
        name: 'Popup av säkerhetspanel efter inloggning',
        description: 'Efter inloggning sker kontroll om användaren uppfyller policyn ”Kräv tvåfaktorsautentisering för användare", om så ej är fallet så ska en popup visas som kräver att användaren aktiverar 2FA innan denne kan fortsätta. I popupen visas säkerhetspanelen där 2FA ställs in för användaren.',
        startDate: null,
        endDate: null,
        status: "NEW",
        ownedBy: "Robert",
        time: "0,5 dagar",
        values: [],
      },
      {
        id: "9.4",
        name: 'Inloggning med tvåfaktorsautentisering',
        description: 'Inloggningssida som hanterar tvåfaktorsautentisering via t.ex. Microsoft Authenticator i samband med att en användare loggar in.',
        startDate: null,
        endDate: null,
        status: "NEW",
        ownedBy: "Robert",
        time: "2 dagar",
        values: [],
      },
      {
        id: "9.5",
        name: 'Sida för en arbetsyta som dirigerar',
        description: `Om företaget har policy för ”tvåfaktorsautentisering”, så ska alla användare och alla arbetsytor som företaget äger använda sig av tvåfaktorsautentisering. En användare som tillhör ett externt företag (t.ex. en reklambyrå) som inte använder sig av tvåfaktorsautentisering, ska dirigeras till ”Mina sidor > Tvåfaktorsautentisering” om de går in på en arbetsyta där ägaren har policyn ”tvåfaktorsautentisering”.
OBS! Om Arbetsytans ägare använder sig av tvåfaktorsautentisering, ska arbetsytan default alltid använda sig av tvåfaktorsautentisering.`,
        startDate: null,
        endDate: null,
        status: "NEW",
        ownedBy: "Robert",
        time: "1 dagar",
        values: [],
      },
    ]
  },
  {
    id: "10",
    name: 'Använda Kommunals inloggningstjänst till Wilma',
    description: '',
    startDate: "",
    endDate: "",
    status: "NEW",
    ownedBy: "Robert",
    time: "",
    values: [],
    nodes: [
      {
        id: "10.1",
        name: 'Fas 1: Sätta upp en extern inloggningstjänst och utföra tester mot den',
        description: `Sätta upp en ny extern inloggningstjänst (IdentityServer) som simulerar Kommunals inloggningstjänst. Därefter koppla Wilmas inloggningstjänst mot den uppsatta inloggningstjänsten och utvärdera hur enkelt det är att koppla mot inloggningstjänsten. Utvärdera vissa hinder som kan uppstå. Allt görs i utvecklingsmiljö.
Mål: Att se hur enkelt det är i befintlig lösning att koppla ihop Wilma med ytterligare en extern inloggningstjänst.`,
        startDate: "",
        endDate: "",
        status: "NEW",
        ownedBy: "Robert",
        time: "3-4 dagar",
        values: [],
      },
      {
        id: "10.2",
        name: 'Fas 2: Utvärdera Kommunals befintliga inloggningstjänst',
        description: `Kommunal skickar befintlig information kring hur inloggningstjänsten fungerar, API:er, och vilka parametrar som krävs för att koppla upp sig mot Kommunals inloggningstjänst.
Göran skickar dokumentation över Kommunals inloggningstjänst.
Göran kontrollerar hur leveransadresser sparas i Kommunals inloggningstjänst.
Mål: Förstå hur Kommunals inloggningstjänst fungerar, för att kunna anpassa Wilma till Kommunals inloggningstjänst.
Tid/kostnad: Anges i möte innan fasen påbörjas`,
        startDate: "",
        endDate: "",
        status: "NEW",
        ownedBy: "Robert",
        time: "",
        values: [],
      },
      {
        id: "10.3",
        name: 'Fas 3: Utföra tester mot Kommunals inloggningstjänst i utvecklingsmiljö',
        description: `Koppla samman Wilmas inloggningstjänst mot Kommunals inloggningstjänst i utvecklingsmiljö, och utföra tester.
Tid/kostnad: Anges i möte innan fasen påbörjas`,
        startDate: "",
        endDate: "",
        status: "NEW",
        ownedBy: "Robert",
        time: "",
        values: [],
      },
      {
        id: "10.4",
        name: 'Fas 4: Skapa införandeplan och informera användare',
        description: `Skapa införandeplan och därefter informera Kommunals användare om det nya inloggningsförfarandet. Kommunikationsenheten informerar användarna.
Tid/kostnad: Anges i möte innan fasen påbörjas`,
        startDate: "",
        endDate: "",
        status: "NEW",
        ownedBy: "Robert",
        time: "",
        values: [],
      },
      {
        id: "10.5",
        name: 'Fas 5: Flytta över implementation till produktionsmiljö, dvs till ”skarp miljö”',
        description: `Flytta implementationen av inloggningstjänsten från utvecklingsmiljön till produktionsmiljön (”skarpa servern”). Sluttestning av att inloggningstjänsten i produktionsmiljön även hanterar autentisering av användare enligt specifikation.
Tid/kostnad: Anges i möte innan fasen påbörjas`,
        startDate: "",
        endDate: "",
        status: "NEW",
        ownedBy: "Robert",
        time: "",
        values: [],
      },
      {
        id: "10.6",
        name: 'Fas 6: Skarp drift',
        description: `Fullt fungerande inloggningstjänst där Kommunals inloggningstjänst autentiserar användare som loggar in i Wilma.
Tid/kostnad: Anges i möte innan fasen påbörjas`,
        startDate: "",
        endDate: "",
        status: "NEW",
        ownedBy: "Robert",
        time: "",
        values: [],
      },
    ],
  },
];

import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { Menu, Item } from "react-contexify";
import { FormattedMessage } from "react-intl";
import "react-contexify/dist/ReactContexify.min.css";

const useStyles = makeStyles(theme => ({
  contextWrap: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    fontSize: 11,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    lineHeight: "20px",
    backgroundColor: theme.palette.primary.main,
    zIndex: 9999,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "& .react-contexify__separator": {
      backgroundColor: "#fff !important",
      float: "none",
      margin: 0,
    },
  },
  contextSubMenu: {
    fontSize: 11,
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "&:hover": {
      "& > .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__submenu-arrow": {
      fontSize: 8,
    },
  },
  contextItem: {
    fontSize: 11,
    "&:hover": {
      "& .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
  },
}));

const ContextMenu = ({
  contextMenuId,
  onDelete,
  onNewCompany,
  onRename,
  selectedNode,
}) => {
  const classes = useStyles();
  const { isNetwork, parentCompanyId, parentNetworkId } = selectedNode;

  return (
    <Box>
      <Menu id={contextMenuId} className={classes.contextWrap}>
        {isNetwork && (
          <Item className={classes.contextItem} onClick={onNewCompany}>
            <FormattedMessage id="context.newCompany" />
          </Item>
        )}
        {isNetwork && parentNetworkId && (
          <Item className={classes.contextItem} onClick={onRename}>
            <FormattedMessage id="context.changeName" />
          </Item>
        )}
        {((isNetwork && parentNetworkId) ||
          (!isNetwork && !parentCompanyId)) && (
          <Item className={classes.contextItem} onClick={onDelete}>
            <FormattedMessage id="context.remove" />
          </Item>
        )}
      </Menu>
    </Box>
  );
};

ContextMenu.defaultProps = {
  selectedNode: {},
};

ContextMenu.propTypes = {
  contextMenuId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onNewCompany: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  selectedNode: PropTypes.shape({ isRootMenu: PropTypes.bool }),
};

export default ContextMenu;

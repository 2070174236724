import React from "react";

const UQLoadingScreen = () => {
  return (
    <>
      <div className="dot-revolution" />
      <div className="dot-revolution-UniQueue">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="150"
          height="25"
          viewBox="0 0 194.5 33.3"
        >
          <path
            d="M23.7 1.7c0 19.7 0.8 22.9-6.5 22.9 -7.3 0-6.5-3.3-6.5-22.9H6.8c0 23-0.4 26.1 10.4 26.1 10.8 0 10.5-3.1 10.5-26.1H23.7z"
            className="a"
          />
          <path
            d="M43.5 1.3c-10.8 0-10.5 3.1-10.5 26.1h4c0-19.7-0.8-22.9 6.5-22.9 7.3 0 6.5 3.3 6.5 22.9h4C53.9 4.3 54.3 1.3 43.5 1.3"
            className="a"
          />
          <rect x="59.2" y="1.6" width="3.9" height="25.7" className="a" />
          <path
            d="M109.7 1.7c0 19.7 0.8 22.9-6.5 22.9s-6.5-3.3-6.5-22.9H92.8c0 23-0.4 26.1 10.5 26.1 10.8 0 10.5-3.1 10.5-26.1H109.7z"
            className="a"
          />
          <path
            d="M138.8 8.1c0-5.1-3.1-6.9-9.5-7 -11.1-0.2-11.5 5-11.5 14.8 0 10.1 3.4 11.7 10.2 11.7 6.8 0 9.3-0.9 9.3-0.9l0.3-3.5c-2 0.9-5.9 1.5-9.6 1.5 -5.4 0-6-3-6-8.8l7.2 0C139.9 15.9 138.8 10 138.8 8.1M129 4.2c7.6 0 7.7 8.8 0.2 8.8h-7.2C122 6.1 123.1 4.2 129 4.2"
            className="a"
          />
          <path
            d="M160.3 1.7c0 19.7 0.8 22.9-6.5 22.9 -7.3 0-6.5-3.3-6.5-22.9h-4c0 23-0.4 26.1 10.4 26.1s10.5-3.1 10.5-26.1H160.3z"
            className="a"
          />
          <path
            d="M172.6 15.9l7.2 0c10.7 0 9.6-5.9 9.6-7.8 0-5.1-3.1-6.9-9.5-7 -11.1-0.2-11.5 5-11.5 14.8 0 10.1 3.4 11.7 10.2 11.7 6.8 0 9.3-0.9 9.3-0.9l0.3-3.5c-2 0.9-5.9 1.5-9.6 1.5C173.2 24.7 172.6 21.6 172.6 15.9M179.6 4.2c7.6 0 7.7 8.8 0.2 8.8h-7.2C172.6 6.1 173.7 4.2 179.6 4.2"
            className="a"
          />
          <path
            d="M88.5 14.5c-0.2-7.5-1-10.9-3.9-12.3 -1.5-0.8-3.6-1-6.5-1 -2.9 0-5 0.2-6.5 1 -2.9 1.4-3.7 4.8-3.9 12.3 0.2 7.5 1 10.9 3.9 12.3 1.5 0.8 3.6 1 6.5 1 2.7 0 4.7-0.2 6.2-0.9l4.9 5.5 2.8-2.5 -5-5.6C88 22.4 88.4 19.3 88.5 14.5M78.2 24.6c-5.3 0-6.3-1.7-6.5-10.1 0.2-8.4 1.2-10.1 6.5-10.1 5.3 0 6.3 1.7 6.5 10.1C84.5 22.9 83.4 24.6 78.2 24.6"
            className="a"
          />
        </svg>
      </div>
    </>
  );
};

export default UQLoadingScreen;

import { withStyles } from "@mui/styles";
import { Box } from "@mui/material";

const FilePreviewContainer = withStyles({
  root: {
    marginRight: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.4)",
    borderRadius: 4,
  },
})(Box);

export default FilePreviewContainer;

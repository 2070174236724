import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import NewsImage from "../../assets/img/sweden-1.jpg";
import Profile from "../../assets/img/default-avatar.png";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #e9e9e9",
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    "&:hover": {
      cursor: "pointer",
      borderColor: theme.palette.primary.main,
    },
  },
  newsImageWrap: {
    height: 130,
    position: "relative",
  },
  newsImage: {
    width: "100%",
    height: 130,
    objectFit: "cover",
    borderRadius: 0,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  profile: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 10,
  },
  newsContentWrap: {
    padding: 15,
  },
  category: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  authorWrap: {
    display: "flex",
    flexGrow: 1,
  },
  author: {
    fontSize: 12,
    fontWeight: 600,
  },
  date: {
    fontSize: 11,
    color: "#888",
  },
  siteLabel: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: 600,
    padding: "2px 10px",
    position: "absolute",
    top: 0,
    right: 0,
    borderTopRightRadius: 6,
  },
}));

const NewsWidget = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.newsImageWrap}>
        <img src={NewsImage} className={classes.newsImage} alt="" />
        <Typography className={classes.siteLabel}>Klara</Typography>
      </Box>
      <Box className={classes.newsContentWrap}>
        <Typography className={classes.category}>Category</Typography>
        <Typography className={classes.title}>
          Ny medarbetare till labbet
        </Typography>
        <Box className={classes.authorWrap}>
          <img src={Profile} className={classes.profile} alt="" />
          <Box>
            <Typography className={classes.author}>John Doe</Typography>
            <Typography className={classes.date}>2019-12-08 04:48</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsWidget;

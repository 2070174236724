import { gql } from '@apollo/client';
import AddressSettingsFragment from "../fragments/AdressSettings";

const getEditAccountSettings = gql`
  query UserAccountContext {
    userAccountContext {
      editAccountSettings {
        addressLabel
        allowEditAddresses
        allowEditSubscriptionLevel
        biography
        company
        userGroupBreadCrumb{
          groups{
            groupId,
            name
          }
        }
        currentPosition
        directMessage
        email
        employmentType
        enabledMFATypes
        firstname
        lastname
        location
        mainEducation
        mobile
        phone
        selectedTheme
        skillTags
        companyRoleName
        subscriptionLevel

        webLinks {
          url
          visibility
        }

        visibilitySettings {
          company
          directMessage
          email
          mainEducation
          mobile
          phone
          skillTags
        }

        deliveryAddress {
          ...AddressFields
        }
        invoiceAddress {
          ...AddressFields
        }
        ordererAddress {
          ...AddressFields
        }
        visitingAddress {
          ...AddressFields
        }
        deliveryAddressSettings {
          ...AddressSettingsFields
        }
        invoiceAddressSettings {
          ...AddressSettingsFields
        }
        postalAddressSettings {
          ...AddressSettingsFields
        }
        visitingAddressSettings {
          ...AddressSettingsFields
        }
      }
    }
  }

  fragment AddressFields on AddressDTO {
    address1
    address2
    address3
    address4
    address5
    city
    contact
    country
    customerId
    email
    reference
    telephone
    zip
  }
  ${AddressSettingsFragment}
`;

export default getEditAccountSettings;

import { createTheme } from '@mui/material/styles';

const KOMMUNAL_THEME = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    body1: {
      fontSize: 14,
    },
    h6: {
      fontSize: 18,
      fontWeight: 400,
      textTransform: "none",
    },
    subtitle1: {
      fontSize: 12,
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      grey: "#DEDEDE",
      lightGrey: "#D9D9D9",
      neon: "#E76678",
      cerise: "#F6689B",
      blue: "#88d1d2",
      pink: "#FAD1D6",
      green: "#40ca0a",
      link: "#4A90E2",
      lightBlue: "#D6F2EF",
      gold: "#754f0b",
      red: "#CE122D",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
      cream: "#f4f3ed",
      gray: "#ddd",
      lightGray: "#f9f9f9",
      contrastGray: "#f1f1f1",
      orange: "#f07d17",
      contrastOrange: "#eba05d",
    },
    primary: {
      light: "#f6689b",
      main: "#f07d17",
      dark: "#000000",
      contrastText: "#000000",
      alt: "#a53c59",
    },
    secondary: {
      light: "#ff4081",
      main: "#f1c04f",
      dark: "#CD132D",
      contrastText: "#000000",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      gray: "#444",
      lightGray: "#9a9a9a",
      pink: "rgba(102, 35, 54, 0.54)",
      lightPink: "rgba(102, 35, 54, 0.14)",
    },
    component: {
      hamburger: "#fffce5",
      pageBackground: "#ffffff",
      headerBackground: "#000",
      headerColor: "#fffce5",
      navbarBackground: "#f07d17",
      navbarColor: "#fffce5",
      navbarChildColor: "#fffce5",
      navbarSeparator: "#f07d17",
      badgeBackground: "#f1c04f",
      badgeColor: "#000000",
      languageBackground: "#f5aa68",
      productBoxColor: "#000",
      tabBackground: "#88d1d2",
      tabColor: "#754E0B",
      tabWrap: "#D6F2EF",
      customPanelColor: "#754f0b",
      customPanelBackground: "#FAD1D6",
      fileUploadBackground: "#fffdeb",
      imageCardHover: "#FAD1D6",
      homeMainColor: "#CD132D",
      viewSwitchBackground: "#efebd2",
      detailedSearchBox: "#D6F2EF",
      detailedSearchBorder: "#c4e2df",
      detailedSearchSwitch: "#F6689B",
      selectedFolder: "#f7f2ca",
    },
  },
});

export default KOMMUNAL_THEME;

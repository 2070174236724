import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { useIntl } from "react-intl";

const useStyles = makeStyles(() => ({
  table: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
    "@media print": {
      tableLayout: "fixed",
      width: "100%",
      display: "table",
    },
  },
  th: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: "none",
  },
  td: {
    fontSize: 12,
    "@media print": {
      width: "auto",
      wordWrap: "break-word",
    },
  },
}));

const AddressInfo = ({
  tableTitle,
  address1,
  address2,
  address3,
  address4,
  address5,
  city,
  zip,
  country,
  reference,
  email,
  telephone,
  contact,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const tableRowData = [
    {
      name: intl.formatMessage({
        id: "orderHistory.address",
      }),
      value: address1,
    },
    {
      name: `${intl.formatMessage({
        id: "orderHistory.address",
      })} 2`,
      value: address3,
    },
    {
      name: `${intl.formatMessage({
        id: "orderHistory.address",
      })} 3`,
      value: address4,
    },
    {
      name: `${intl.formatMessage({
        id: "orderHistory.address",
      })} 4`,
      value: address5,
    },
    {
      name: intl.formatMessage({
        id: "orderHistory.section",
      }),
      value: address2,
    },

    {
      name: intl.formatMessage({
        id: "orderHistory.city",
      }),
      value: city,
    },
    {
      name: intl.formatMessage({
        id: "orderHistory.postCode",
      }),
      value: zip,
    },
    {
      name: intl.formatMessage({
        id: "orderHistory.country",
      }),
      value: country,
    },
    {
      name: intl.formatMessage({
        id: "orderHistory.reference",
      }),
      value: reference,
    },
    {
      name: intl.formatMessage({
        id: "orderHistory.email",
      }),
      value: email,
    },
    {
      name: intl.formatMessage({
        id: "orderHistory.telephone",
      }),
      value: telephone,
    },
    {
      name: intl.formatMessage({
        id: "orderHistory.contact",
      }),
      value: contact,
    },
  ];

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.th}>{tableTitle}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRowData.map(x => {
          return (
            <>
              {x.value && (
                <TableRow key={`${x.name}`}>
                  <TableCell className={classes.td}>{x.name}</TableCell>
                  <TableCell className={classes.td}>{x.value}</TableCell>
                </TableRow>
              )}
            </>
          );
        })}
      </TableBody>
    </Table>
  );
};

AddressInfo.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  telephone: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
};

export default AddressInfo;

import { gql } from '@apollo/client';

const getOneStreamJobReport = gql`
  query GetOneStreamJobReport($id: ID!) {
    oneStream {
      oneStreamJobReport(id: $id) {
        detailedMessage
        message
        name
        runListItems {
          fileListItems {
            message
            name
            preflightReport {
              failures {
                message
              }
            }
            status
          }
          name
          status
        }
        status
      }
    }
  }
`;

export default getOneStreamJobReport;

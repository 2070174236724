import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { AppBar, Tabs, Box } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { GET_FIELDS } from "../../../../../graphql/queries";
import PageTabPanel from "../../../../common/PageTabPanel";
import TabPanel from "../../../../TabPanel";
import PageTabs from "../../../../PageTabs";
import { geta11yProps } from "../../../../../helpers/get";
import EditFields from "./Tabs/EditFields";
import Files from "./Tabs/Files";
import Preview from "./Tabs/Preview";

const useStyles = makeStyles(theme => ({
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
    "& .fa": {
      fontSize: 12,
      marginLeft: 7,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  hasError: {
    backgroundColor: "#ffa7a7",
    opacity: 1,
    boxShadow: "inset 0px 0px 0px 2px #bb1335",
    "& .fa": {
      display: "inline-block !important",
      fontSize: 12,
      marginLeft: 7,
      color: "#b72b2b",
    },
    "& $indicator": {
      backgroundColor: "#bb1335 !important",
    },
  },
  label: {
    fontSize: 10,
  },
  draftIcon: {
    color: "#b72b2b",
  },
  boxWrapper: {
    width: "100%",
  },
  boxCustom: {
    padding: "0!important",
  },
}));

const DynamicProduct = ({ resourceId, image, selectedProductType }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [getFields, { data: fieldsData }] = useLazyQuery(GET_FIELDS);

  const [tab, setTab] = useState(0);
  const [fieldOrderFile, setFiledOrderFile] = useState("");
  const [fields, setFields] = useState([]);
  const [textListOptions, setTextListOptions] = useState([]);
  const [imageListOptions, setImageListOptions] = useState([]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    getFields({ variables: { productId: resourceId } });
  }, [resourceId, selectedProductType]);

  useEffect(() => {
    if (
      fieldsData &&
      fieldsData.dynamicTemplateEditContext &&
      fieldsData.dynamicTemplateEditContext.getFields
    ) {
      const {
        templateFields,
        fieldForOrderFile,
        textListDictionary,
        imageResourceDictionary,
      } = fieldsData.dynamicTemplateEditContext.getFields;

      setFiledOrderFile(fieldForOrderFile);

      if (Array.isArray(templateFields) && templateFields.length > 0) {
        setFields(templateFields);
      }

      if (Array.isArray(textListDictionary) && textListDictionary.length > 0) {
        setTextListOptions(textListDictionary);
      }

      if (
        Array.isArray(imageResourceDictionary) &&
        imageResourceDictionary.length > 0
      ) {
        setImageListOptions(imageResourceDictionary);
      }
    }
  }, [fieldsData]);

  return (
    <PageTabPanel>
      {selectedProductType === "DYNAMIC_PRODUCT" && (
        <Box className={classes.boxWrapper}>
          <AppBar position="static" elevation="0" className={classes.wrap}>
            <Tabs
              value={tab}
              onChange={handleChange}
              variant="fullWidth"
              aria-label={intl.formatMessage({ id: "editProduct.editProduct" })}
              classes={{
                indicator: classes.indicator,
              }}
              className={classes.tabsRoot}
            >
              <PageTabs
                className={classes.pageTabs}
                label={<FormattedMessage id="editProduct.preview" />}
                {...geta11yProps(0)}
                value={0}
              />
              <PageTabs
                className={classes.pageTabs}
                label={<FormattedMessage id="editProduct.editFields" />}
                {...geta11yProps(1)}
                value={1}
              />
              <PageTabs
                className={classes.pageTabs}
                label={<FormattedMessage id="editProduct.files" />}
                {...geta11yProps(2)}
                value={2}
              />
              <PageTabs
                className={classes.pageTabs}
                label={<FormattedMessage id="editProduct.dataSources" />}
                {...geta11yProps(3)}
                value={3}
              />
            </Tabs>
          </AppBar>
          {[
            <Preview resourceId={resourceId} fields={fields} />,
            <EditFields
              resourceId={resourceId}
              fields={fields}
              setFields={setFields}
              fieldOrderFile={fieldOrderFile}
              textListOptions={textListOptions}
              imageListOptions={imageListOptions}
            />,
            <Files resourceId={resourceId} image={image} />,
          ].map((x, index) => {
            return (
              <>
                <TabPanel
                  value={tab}
                  className={classes.tabPanel}
                  index={index}
                  boxCustom={classes.boxCustom}
                >
                  {x}
                </TabPanel>
              </>
            );
          })}
        </Box>
      )}
    </PageTabPanel>
  );
};

export default DynamicProduct;

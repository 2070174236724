import { gql } from '@apollo/client';

const CREATE_AVATAR = gql`
  mutation userAccountContext(
    $avatarType: UserAvatarType!
    $imageData: String
  ) {
    userAccountContext {
      uploadAvatar(avatarType: $avatarType, imageData: $imageData) {
        success
        avatarUrl
        errorResult {
          errorCode
          errorMessage
          data {
            key
            value
          }
        }
      }
    }
  }
`;

export default CREATE_AVATAR;

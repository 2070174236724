import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  setRefetchFiles,
  setSelectedResources,
} from "../../../actions";
import {
  FOLDER_LOCKED,
  FOLDER_READ_ACCESS,
  FOLDER_WRITE_ACCESS,
  RESOURCE_LOCKED,
} from "../../../constant/errorCodes";
import {
  DELETE_RESOURCE,
  INITIALIZE_FOLDER_DOWNLOAD,
  INITIALIZE_RESOURCE_DOWNLOAD,
} from "../../../graphql/mutations";
import { getSelectedFolder, getSelectedResources } from "../../../helpers/fileViewSelectors";
import { useFileViewCopy, useSnackbar } from "../../../hooks";
import IconLink from "../../common/FormControl/IconLink";
import UserContactInfoModal from "../../common/UserContactInfoModal";
import AddFolderToBasketModal from "../AddFolderToBasketModal";
import DeleteModal from "../FileView/DeleteModal";

const defaultSiteRoleRights = {
  canAddToBasket: false,
  directoryCRUD: false,
  tenderFolders: false,
  notifications: false,
  shareFolders: false,
  canDownloadFilesAndFolders: false,
};

const useStyles = makeStyles(theme => ({
  tagIcon: {
    marginLeft: 12,
  },
  icon: {
    fontSize: 14,
    marginRight: 12,
  },
}));

const FileViewToolbar = ({
  allowMetadataTagging,
  setOpenMetaDataModal,
  openedNodes,
}) => {
  const alert = useAlert();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileViewCopy = useFileViewCopy();
  const intl = useIntl();
  const snackbar = useSnackbar();

  const selectedFolder = useSelector(getSelectedFolder);
  const { write: userHasWritePermission } = selectedFolder;

  const selectedResources = useSelector(getSelectedResources);

  const workspace = useSelector(state => state.ui.toggleWorkspaces.workspace);
  const sites = useSelector(state => state.api.currentViewer.viewer.sites);
  const siteId = workspace && workspace.id;

  const [loading, setLoading] = useState(false);

  const [siteRoleRights, setSiteRoleRights] = useState(defaultSiteRoleRights);
  const [openAddFolderToBasket, setOpenAddFolderToBasket] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [initFolderDonwload] = useMutation(INITIALIZE_FOLDER_DOWNLOAD);
  const [deleteResource] = useMutation(DELETE_RESOURCE);

  const [userContactDetails, setUserContactDetails] = useState(false);
  const [lockedByUsername, setLockedByUsername] = useState("");

  const {
    canAddToBasket,
    //directoryCRUD,
    //tenderFolders,
    //notifications,
    //shareFolders,
    canDownloadFilesAndFolders,
  } = siteRoleRights;

  useEffect(() => {
    if (sites && workspace && workspace.id) {
      const selectedSite = sites.find(x => x.id === workspace.id);
      if (selectedSite) {
        const { siteRoleRights: siteRoleRightsData } = selectedSite;
        if (siteRoleRightsData) {
          setSiteRoleRights(siteRoleRightsData);
        } else {
          setSiteRoleRights(defaultSiteRoleRights);
        }
      }
    }
  }, [sites, workspace]);

  const handleOpenUserContactInfo = username => {
    setLockedByUsername(username);
    setUserContactDetails(true);
    snackbar.close();
  };

  const handleCloseUserContactInfo = () => {
    setLockedByUsername("");
    setUserContactDetails(false);
  };

  const handleError = (name, errorResult, username) => {
    const { data: errorData, errorCode } = errorResult;

    const personLink = (
      <div>
        {username},
        <Button
          className={classes.btnLink}
          onClick={() => handleOpenUserContactInfo(username)}
        >
          <FormattedMessage id="resources.clickHereToSeeUser" />
        </Button>
      </div>
    );

    const person = errorData[0].value;
    if (errorCode === RESOURCE_LOCKED) {
      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: name, person: personLink }
        )
      );
    }

    if (errorCode === FOLDER_LOCKED) {
      const lockedFolder = errorData[1].value;

      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: lockedFolder, person }
        )
      );
    }

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }
    console.error(errorData.errorMessage);
  };

  const handleCopyResources = () => {
    const ids = selectedResources.map(x => x.id);
    fileViewCopy.copyResources(ids);
  };

  const handleCloseAddFolderToBasket = () => {
    setOpenAddFolderToBasket(false);
  };

  const handleOpenAddFolderToBasket = () => {
    setOpenAddFolderToBasket(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleMultipleDelete = async toDeleteResources => {
    setDeleteLoading(true);
    setLoading(true);
    try {
      let hasErrors = false;

      const promises = toDeleteResources.map(async x => {
        const res = await deleteResource({
          variables: { resourceId: x.id },
        });

        if (
          res &&
          res.data &&
          res.data.deleteResource &&
          res.data.deleteResource.success
        ) {
          console.log(`Resources deleted`);
        } else {
          hasErrors = false;
        }
        return res;
      });

      await Promise.all(promises);
      setDeleteLoading(false);
      handleDeleteModalClose();
      if (hasErrors) {
        alert.success(
          <FormattedMessage id="resources.resourcesDeletedWithErrors" />
        );
        dispatch(setRefetchFiles({ refetch: true }));
        dispatch(setSelectedResources({ items: [] }));
      } else {
        alert.success(<FormattedMessage id="resources.resourcesDeleted" />);
        dispatch(setRefetchFiles({ refetch: true }));
        dispatch(setSelectedResources({ items: [] }));
      }
      setLoading(false);
    } catch (e) {
      snackbar.workspaceError(
        <FormattedMessage id="resources.errorDeletingResources" />
      );
      handleDeleteModalClose();
      setDeleteLoading(false);
      setLoading(false);

      console.log("error >", e);
    }
  };

  const handleDeleteResource = async () => {
    try {
      const res = await deleteResource({
        variables: { resourceId: selectedResources[0].id },
      });
      if (res && res.data && res.data.deleteResource) {
        const { success } = res.data.deleteResource;

        if (success) {
          alert.success(<FormattedMessage id="product.deleted" />);
          dispatch(setRefetchFiles({ refetch: true }));
          dispatch(setSelectedResources({ items: [] }));
        } else {
          const { errorResult } = res.data.deleteResource;
          handleError(selectedResources[0].name || "", errorResult);
        }
        handleDeleteModalClose();
      }
    } catch (e) {
      console.error("error >", e);
    }
  };

  const [initDownloadResource] = useMutation(INITIALIZE_RESOURCE_DOWNLOAD);

  const handleDownloadResource = async item => {
    // versionId set to hardcode since we cannot fetch versionId from the resource at the moment

    try {
      const res = await initDownloadResource({
        variables: { resourceId: item, versionId: 0 },
      });
      if (res && res.data && res.data.initResourceDownload) {
        const downloadUri = res.data.initResourceDownload;
        window.open(downloadUri);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading product ${e}`);
    }
  };

  const handleDownloadMultipleResources = async () => {
    const resourceIds = selectedResources.map(x => x.id);

    if (resourceIds.length === 1) {
      return handleDownloadResource(resourceIds[0]);
    }

    setLoading(true);
    let downloadWindow = window.open("");

    try {
      const res = await initFolderDonwload({
        variables: {
          siteId,
          folderId: selectedFolder.folderId,
          resourceIds,
        },
      });

      if (res && res.data && res.data.initFolderDownload) {
        const { success } = res.data.initFolderDownload;

        if (success) {
          const downloadUri = res.data.initFolderDownload.url;
          //window.open(downloadUri);
          downloadWindow.location.href = downloadUri;
          window.setTimeout(function () {
            downloadWindow.close();
          }, 2000);
        } else {
          snackbar.workspaceError(
            <FormattedMessage id="common.genericErrorMessage" />
          );
        }
        setLoading(false);
      }
    } catch (err) {
      console.log("Error downloading product ", err);
    }
  };

  return (
    <Box display="flex">
      {userHasWritePermission && (
        <IconLink
          icon="fas fa-tags"
          placement="top"
          title={<FormattedMessage id="metadata.metadata" />}
          onClick={() => setOpenMetaDataModal(true)}
          customClass={clsx([classes.tagIcon, classes.icon])}
          disabled={loading}
        />
      )}
      {canAddToBasket && (
        <IconLink
          icon="fas fa-cart-plus"
          placement="top"
          title={<FormattedMessage id="context.addCart" />}
          onClick={() => handleOpenAddFolderToBasket()}
          customClass={classes.icon}
          disabled={loading}
        />
      )}
      {canDownloadFilesAndFolders && (
        <IconLink
          icon="fa fa-download"
          placement="top"
          title={<FormattedMessage id="btn.download" />}
          onClick={() => handleDownloadMultipleResources()}
          customClass={classes.icon}
          disabled={loading}
        />
      )}
      <IconLink
        icon="fas fa-copy"
        placement="top"
        title={<FormattedMessage id="context.copy" />}
        onClick={handleCopyResources}
        customClass={classes.icon}
        disabled={loading || !selectedResources.length > 0}
      />

      {userHasWritePermission && (
        <IconLink
          icon="fas fa-trash"
          placement="top"
          title={<FormattedMessage id="context.delete" />}
          onClick={handleDeleteModalOpen}
          customClass={classes.icon}
          disabled={loading}
        />
      )}

      {openAddFolderToBasket && (
        <AddFolderToBasketModal
          open={openAddFolderToBasket}
          handleClose={handleCloseAddFolderToBasket}
          folder={selectedFolder}
          openedNodes={openedNodes}
          initialFilesAddToCart={selectedResources}
        />
      )}

      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={handleDeleteModalClose}
          isSelected
          handleDelete={handleDeleteResource}
          selectedResources={selectedResources}
          handleMultipleDelete={handleMultipleDelete}
          deleteLoading={deleteLoading}
        />
      )}

      {userContactDetails && (
        <UserContactInfoModal
          open={userContactDetails}
          handleClose={() => handleCloseUserContactInfo()}
          username={lockedByUsername}
        />
      )}
    </Box>
  );
};

export default FileViewToolbar;

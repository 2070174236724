import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton, Paper, Typography, Tooltip } from "@mui/material";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import HistoryModal from "./HistoryModal";
import Image from "../../assets/img/sweden-1.jpg";
import EditProductModal from "../ProductsMainView/EditProductModal";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    display: "block",
    color: theme.palette.primary.main,
  },
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  td: {
    fontSize: 12,
    paddingTop: 12,
    paddingBottom: 12,
    "& p": {
      fontSize: 11,
      marginBottom: 2,
    },
  },
  tdImage: {
    minWidth: 80,
    maxWidth: 80,
    width: 80,
  },
  image: {
    width: "100%",
    maxWidth: "100%",
    height: "auto",
    objectFit: "cover",
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  actionIcon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  historyWrap: {
    marginLeft: 5,
  },
}));

const StockProductsTable = () => {
  const classes = useStyles();

  const [openHistory, setOpenHistory] = useState(false);
  const handleOpenHistory = () => {
    setOpenHistory(true);
  };
  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const [openProduct, setOpenProduct] = useState(false);
  const handleOpenProduct = () => {
    setOpenProduct(true);
  };
  const handleCloseProduct = () => {
    setOpenProduct(false);
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.root}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.th}>Artikel</TableCell>
              <TableCell className={classes.th} />
              <TableCell className={classes.th}>Hantering</TableCell>
              <TableCell className={classes.th}>Lager</TableCell>
              <TableCell className={classes.th}>Status</TableCell>
              <TableCell className={classes.th} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.td}>
                <Typography>Beachflagga</Typography>
                <Typography>
                  <strong>Art.nr.:</strong> 71418227
                </Typography>
                <Typography>
                  <strong>Ansvarig ägare</strong> (Mika/CJ)
                </Typography>
              </TableCell>
              <TableCell className={classes.tdImage}>
                <img src={Image} alt="" className={classes.image} />
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>
                  <strong>Plocklista till</strong>
                </Typography>
                <Typography>Kommunal</Typography>
                <Typography>
                  <strong>Bevakningspunkt skickas till</strong>
                </Typography>
                <Typography>cj@mikasveige.se</Typography>
                <Typography>
                  <strong>Bevakningspunkt vid antal</strong> 0
                </Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>
                  <strong>Lagerplats</strong>
                </Typography>
                <Typography>Platsen</Typography>
                <Typography>
                  <strong>Beskrivning</strong>
                </Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>
                  <strong>Lagersaldo</strong> 10
                  <span className={classes.historyWrap}>
                    <IconButton
                      className={classes.action}
                      onClick={handleOpenHistory}
                    >
                      <Icon
                        className={clsx(["fa fa-clock", classes.actionIcon])}
                      />
                    </IconButton>
                  </span>
                </Typography>
                <Typography>
                  <strong>Negativt lagersaldo tillåts</strong> Nej
                </Typography>
                <Typography>
                  <strong>Bokningsbar</strong> Ja
                </Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <IconButton
                  className={classes.action}
                  onClick={handleOpenProduct}
                >
                  <Icon className={clsx(["fa fa-edit", classes.actionIcon])} />
                </IconButton>
                <Tooltip
                  title="Lagringsplats1 : Överföra filer / Diverse / Mapp1 / Korrkort"
                  placement="top"
                >
                  <IconButton className={classes.action}>
                    <Icon
                      className={clsx([
                        "fa fa-folder-open",
                        classes.actionIcon,
                      ])}
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <HistoryModal open={openHistory} handleClose={handleCloseHistory} />
      <EditProductModal open={openProduct} handleClose={handleCloseProduct} />
    </Paper>
  );
};

export default StockProductsTable;

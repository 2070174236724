import { ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client';

import { publicGraphqlUrl } from "../services/config";

export const client = new ApolloClient({
  uri: publicGraphqlUrl,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  IconButton,
  Icon,  
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CustomSelect from "../../../../common/CustomSelect";
import AddressHeaderTitle from "../../../../common/AddressHeaderTitle";
import CustomTextField from "../../../../common/TextField";

const useStyles = makeStyles(() => ({
  select: {
    width: 130,
  },
  dragIcon: {
    cursor: "grab",
    width: "25px!important",
    height: "25px!important",
    marginTop: 3,
  },
  draggableContainer: {
    display: "flex",
    alignItems: "center",
  },
  dropDownIcon: {
    fontSize: 11,
    marginRight: 3,
    width: 13,
    height: 11,
  },
  fieldIcon: {
    fontSize: 16,
    width: 20,
    height: 20,
  },
}));

const Address = ({
  name,
  label,
  fields,
  fieldValues,
  handleChange,
  onDragEnd,
  mainIndex,
  handleFieldType,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const renderLabel = (icon, dropdownLabel) => {
    return (
      <>
        <span>
          <Icon className={clsx([icon, classes.dropDownIcon])} />
          {intl.formatMessage({ id: dropdownLabel })}
        </span>
      </>
    );
  };

  const options = [
    {
      label: renderLabel("fas fa-minus", "addresses.dontCare"),
      value: "DONT_CARE",
    },
    {
      label: renderLabel("fas fa-eye-slash", "addresses.hidden"),
      value: "HIDDEN",
    },
    {
      label: renderLabel("fas fa-toggle-on", "addresses.fixed"),
      value: "FIXED",
    },
    {
      label: renderLabel("far fa-square", "addresses.optional"),
      value: "OPTIONAL",
    },
    {
      label: renderLabel("far fa-check-square", "addresses.required"),
      value: "REQUIRED",
    },
  ];

  const [showDropdown, setShowDropdown] = useState({});

  const handleSetFieldType = (field, mainIdx, idx, value) => {
    handleFieldType(mainIdx, idx, value);
    setShowDropdown({ ...showDropdown, [field]: false });
  };
  const handleShowDropdown = field => {
    setShowDropdown({ ...showDropdown, [field]: true });
  };

  const handleClose = field => {
    setShowDropdown({ ...showDropdown, [field]: false });
  };

  const renderFieldIcon = fieldType => {
    switch (fieldType) {
      case "DONT_CARE":
        return <Icon className={clsx(["fas fa-minus", classes.fieldIcon])} />;

      case "HIDDEN":
        return (
          <Icon className={clsx(["fas fa-eye-slash", classes.fieldIcon])} />
        );

      case "FIXED":
        return (
          <Icon className={clsx(["fas fa-toggle-on", classes.fieldIcon])} />
        );

      case "OPTIONAL":
        return <Icon className={clsx(["far fa-square", classes.fieldIcon])} />;

      case "REQUIRED":
        return (
          <Icon className={clsx(["far fa-check-square", classes.fieldIcon])} />
        );

      default:
        return <VisibilityIcon />;
    }
  };

  const renderDropdown = (field, type, mainIdx, idx) => {
    return (
      <Box>
        {showDropdown[field] && (
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            customClass={classes.select}
            name={name}
            open={showDropdown[field]}
            options={options}
            value={type}
            onChange={e =>
              handleSetFieldType(field, mainIdx, idx, e.target.value)
            }
            onClose={() => handleClose(field)}
          />
        )}
        {!showDropdown[field] && (
          <IconButton onClick={() => handleShowDropdown(field)}>
            {renderFieldIcon(type)}
          </IconButton>
        )}
      </Box>
    );
  };

  const renderDefaultLabel = fieldName => {
    return intl.formatMessage({ id: `addresses.defaultLabel.${fieldName}` });
  };

  return (
    <Grid item xs={12} md={3}>
      <AddressHeaderTitle>
        <FormattedMessage id={label} />
      </AddressHeaderTitle>
      <DragDropContext onDragEnd={e => onDragEnd(mainIndex, e)}>
        <Droppable droppableId={`adressLabels-${label}`}>
          {provided => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map((field, idx) => {
                return (
                  <Draggable key={idx} draggableId={`field-${idx}`} index={idx}>
                    {p => (
                      <Box
                        ref={p.innerRef}
                        {...p.draggableProps}
                        {...p.dragHandleProps}
                        className={classes.draggableContainer}
                      >
                        <DragIndicatorIcon className={classes.dragIcon} />
                        <CustomTextField
                          name={field.name}
                          label={renderDefaultLabel(field.name)}
                          value={field.label}
                          onChange={e => handleChange(mainIndex, idx, e)}
                          endAdornment={renderDropdown(
                            field.name,
                            field.type,
                            mainIndex,
                            idx
                          )}
                        />
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};

export default Address;

import { gql } from '@apollo/client';
import ResourceMetaDataDTO from "../fragments/ResourceMetadata";

const getMetaData = gql`
  query GetMetaData($resourceId: ID!) {
    productEdit {
      getMetaData(resourceId: $resourceId) {
        resourceMeta {
          ...ResourceMetadata
        }
      }
    }
  }
  ${ResourceMetaDataDTO}
`;

export default getMetaData;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const LIST_WORKSPACE_TEMPLATE_CANDIDATES = gql`
  query listWorkspaceTemplateCandidates($companyId: Int!) {
    companyAdminContext {
      companyWorkspaces {
        listWorkspaceTemplates(companyId: $companyId) {
          items {
            name
            workspaceTemplateId
            description
          }
          success
          errorResult {
            ...ErrorResultFields
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default LIST_WORKSPACE_TEMPLATE_CANDIDATES;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";

const RenderDownloadItems = ({
  read,
  handleDisabled,
  handleDownloadFolder,
}) => {
  const disabled = !read;
  return (
    <ContextMenuItem
      disabled={disabled}
      onClick={() => (disabled ? handleDisabled() : handleDownloadFolder())}
    >
      <FormattedMessage id="context.download" />
    </ContextMenuItem>
  );
};

RenderDownloadItems.defaultProps = {
  locked: false,
};

RenderDownloadItems.propTypes = {
  handleDownloadFolder: PropTypes.func.isRequired,
  read: PropTypes.bool.isRequired,
};

export default RenderDownloadItems;

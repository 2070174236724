import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import CheckImage from "../../assets/img/order-check.svg";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
    textAlign: "center",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnApprove: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
    marginLeft: 7,
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
    width: 90,
  },
}));

const SubscriptionLevelModal = ({
  open,
  handleClose,
  userSubscription,
  setStepThreeValues,
}) => {
  const classes = useStyles();

  const { subscription, level } = userSubscription || {};

  const handleApprove = () => {
    setStepThreeValues(prevState => {
      return { ...prevState, subscription };
    });
    handleClose();
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        {level === "lower" && <FormattedMessage id="createAccount.lowerSub" />}
        {level === "higher" && (
          <FormattedMessage id="createAccount.higherSub" />
        )}

        <Box className={classes.modalFooter} align="center">
          <Button className={classes.btn} onClick={handleClose}>
            <FormattedMessage id="btn.cancel" />
          </Button>
          <Button className={classes.btnApprove} onClick={handleApprove}>
            <FormattedMessage id="btn.approve" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

SubscriptionLevelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SubscriptionLevelModal;

import React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
}));

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

const OutlinedDiv = ({ children, label }) => {
  const classes = useStyles();
  return (
    <TextField
      className="customlegend"
      variant="outlined"
      label={label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children }}
    />
  );
};

OutlinedDiv.defaultProps = {
  children: {},
};

OutlinedDiv.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
};

export default OutlinedDiv;

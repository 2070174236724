import { gql } from '@apollo/client';
import Project from "../../fragments/Projects/Project";

const GET_PROJECTS = gql`
  query getProjects($siteId: Int!) {
    projects {
      getProjects(siteId: $siteId) {
        ...Project
      }
    }
  }
  ${Project}
`;

export default GET_PROJECTS;

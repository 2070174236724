import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import Checkbox from "../../../common/FormControl/Checkbox";
import ResourceTableHeader from "./ResourceTableHeader";
import ResourceImage from "./ResourceImage";
import ImageEmpty from "../../../../assets/img/empty-folder.svg";
import { FormattedDate } from "../../../common";
import IconLink from "../../../common/FormControl/IconLink";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    padding: "5px 10px",
  },
  tdEmpty: {
    padding: "50px 0",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
  disabled: {
    opacity: 0.2,
    pointerEvents: "none",
  },
  marked: {
    backgroundColor: theme.palette.common.lightBlue,
  },
}));

const ResourceTable = ({
  resources,
  selectedResources,
  addSelectedResource,
  removeSelectedResource,
  setSelectedResources,
  selectAllResources,
  selectNoResources,
  handleOpenProductEditModal,
  markedResource,
  setMarkedResource,
}) => {
  const classes = useStyles();

  const onOpenProductEditModal = id => {
    handleOpenProductEditModal(id);
  };

  const handleCheckboxChange = (e, item) => {
    const { checked } = e.target;
    if (checked) {
      addSelectedResource(item);
    } else {
      removeSelectedResource(item);
    }
    setMarkedResource(item);
  };

  const getCheckboxValue = item => {
    if (item && item.id && selectedResources.find(x => x.id === item.id)) {
      return true;
    }
    return false;
  };

  const clickedCell = item => {
    setMarkedResource(item);
  };

  const renderTableBody = () => {
    if (resources && resources.length > 0) {
      return resources.map(x => {
        const {
          productImageUri,
          thumbnailImageUri,
          name,
          imageMetadata,
          tags,
          locked,
        } = x;

        const { contact, date, location, model, photographer } =
          imageMetadata || {};

        return (
          <TableRow
            key={x.id}
            className={clsx([
              locked ? classes.disabled : "",
              x.id === markedResource?.id ? classes.marked : "",
            ])}
          >
            <TableCell className={classes.td}>
              <Checkbox
                value={getCheckboxValue(x)}
                onChange={e => handleCheckboxChange(e, x)}
              />
            </TableCell>
            <TableCell className={classes.td} onClick={() => clickedCell(x)}>
              <ResourceImage
                highResImage={productImageUri}
                image={thumbnailImageUri}
                name={name}
              />
            </TableCell>
            <TableCell className={classes.td} onClick={() => clickedCell(x)}>
              <FormattedDate value={date} />
            </TableCell>
            <TableCell className={classes.td} onClick={() => clickedCell(x)}>
              {contact}
            </TableCell>
            <TableCell className={classes.td} onClick={() => clickedCell(x)}>
              {location}
            </TableCell>
            <TableCell className={classes.td} onClick={() => clickedCell(x)}>
              {photographer}
            </TableCell>
            <TableCell className={classes.td} onClick={() => clickedCell(x)}>
              {model}
            </TableCell>
            <TableCell className={classes.td} onClick={() => clickedCell(x)}>
              {tags.map((tag, index) => (
                <span key={tag.tagId}>
                  {`${tag.tagName}${index !== tags.length - 1 ? ", " : ""}`}
                </span>
              ))}
            </TableCell>
            <TableCell className={classes.td}>
              <IconLink
                icon="fa fa-edit"
                placement="top"
                title={<FormattedMessage id="product.edit" />}
                onClick={() => onOpenProductEditModal(x.id)}
              />
            </TableCell>
          </TableRow>
        );
      });
    }
    return (
      <TableRow>
        <TableCell colSpan={7} align="center" className={classes.tdEmpty}>
          <img src={ImageEmpty} alt="Empty" />
          <Typography className={classes.emptyText}>
            <FormattedMessage id="product.noFilesInFolder" />
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table className={classes.table} size="small" aria-label="Product table">
      <ResourceTableHeader
        setSelectedResources={setSelectedResources}
        selectAllResources={selectAllResources}
        selectNoResources={selectNoResources}
      />
      <TableBody>{renderTableBody()}</TableBody>
    </Table>
  );
};

ResourceTable.defaultProps = {};

ResourceTable.propTypes = {
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      changedBy: PropTypes.string.isRequired,
      changedDate: PropTypes.string.isRequired,
      uploadedDate: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          changedBy: PropTypes.string.isRequired,
          changedDate: PropTypes.string.isRequired,
          uploadedDate: PropTypes.string.isRequired,
          size: PropTypes.number.isRequired,
          thumbnailImageUri: PropTypes.string.isRequired,
        })
      ),
    }).isRequired
  ).isRequired,
  addSelectedResource: PropTypes.func.isRequired,
  removeSelectedResource: PropTypes.func.isRequired,
  setSelectedResources: PropTypes.func.isRequired,
  selectAllResources: PropTypes.func.isRequired,
  handleOpenProductEditModal: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ResourceTable;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Wysiwyg from "../../common/Wysiwyg";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "800px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginTop: 10,
  },
}));

const EditWysiwygModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title="Edit - Help text publications"
      />
      <DraggableModalBody>
        <Typography className={classes.label}>
          Edit text for ContactContent
        </Typography>
        <Wysiwyg label="Type something here..." />
      </DraggableModalBody>
    </DraggableModal>
  );
};

EditWysiwygModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditWysiwygModal;

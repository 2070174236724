import React from "react";
import { Route, useLocation } from "react-router-dom";
import { AuthConsumer } from "../providers/authProvider";
import { LoadingScreen } from "../components/common";
import DefaultView from "../components/layouts/DefaultView";
import PublicView from "../components/layouts/PublicView";

/**
 * Routes to "privateComponent" (in DefaultView) if the user is logged in, otherwise to "publicComponent" (in PublicView).
 *
 * @param {object} param0
 * @param {boolean} param0.noConsentCheck Bypasses the "Terms of Use" check if true.
 * @param {any} param0.privateComponent Component to view (in DefaultView) when logged in.
 * @param {any} param0.publicComponent Component to view (in PublicView) when not logged in.
 */
const PrivateRouteWithPublicFallback = ({
  noConsentCheck = false,
  privateComponent,
  publicComponent,
  ...rest
}) => {
  const location = useLocation();

  const renderFn = (PrivateComponent, PublicComponent) => props => (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (!!PrivateComponent && isAuthenticated()) {
          return (
            <DefaultView noConsentCheck={noConsentCheck}>
              <PrivateComponent {...props} />
            </DefaultView>
          );
        } else if (!!PublicComponent) {
          return (
            <PublicView>
              <PublicComponent {...props} />
            </PublicView>
          );
        } else {
          signinRedirect();
          return <LoadingScreen />;
        }
      }}
    </AuthConsumer>
  );

  return (
    <Route
      exact
      {...rest}
      render={renderFn(privateComponent, publicComponent)}
      key={location.key}
    />
  );
};

export default PrivateRouteWithPublicFallback;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabLabel from "./TabLabel";
import AddRecipientTabLabel from "./AddRecipientTabLabel";

function a11yProps(index) {
  return {
    id: `address-tab-${index}`,
    "aria-controls": `address-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: { marginTop: 15 },
  tabs: {
    backgroundColor: "#f9f9f9",
    minHeight: 35,
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#88d1d2",
    },
    "& .MuiTab-textColorInherit": {
      backgroundColor: "#D6F2EF",
    },
    "& .MuiTab-root": {
      minHeight: 35,
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
  },
  addRecipientTab: {
    "&.MuiTab-textColorInherit": {
      backgroundColor: "transparent",
      opacity: 1,
    },
  },
}));

const ReceiversTabs = ({
  tabIndex,
  handleChangeTab,
  receivers,
  handleOnClickAddRecipientsTab,
  handleRemoveReceiver,
}) => {
  const classes = useStyles();

  return (
    <AppBar position="static" elevation={0}>
      <Tabs
        value={tabIndex}
        onChange={handleChangeTab}
        aria-label="address tabs"
        className={classes.tabs}
      >
        {receivers.map((x, index) => (
          <Tab
            key={x.shortId}
            label={
              <TabLabel
                index={index}
                handleRemoveReceiver={handleRemoveReceiver}
              />
            }
            {...a11yProps(index)}
          />
        ))}
        <Tab
          className={classes.addRecipientTab}
          label={<AddRecipientTabLabel />}
          {...a11yProps(receivers.length)}
          onClick={() => handleOnClickAddRecipientsTab()}
        />
      </Tabs>
    </AppBar>
  );
};

ReceiversTabs.defaultProps = {
  receivers: [],
};

ReceiversTabs.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  receivers: PropTypes.arrayOf(PropTypes.shape({ shortId: PropTypes.string })),
  handleOnClickAddRecipientsTab: PropTypes.func.isRequired,
  handleRemoveReceiver: PropTypes.func.isRequired,
};

export default ReceiversTabs;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import { Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  btnVersion: {
    fontSize: 12,
    padding: "2px 10px",
    width: "unset",
    minWidth: "unset",
    borderRadius: "50%",
  },
  cellContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const VersionHistory = ({ version, handleOpenVersionHistory }) => {
  const classes = useStyles();

  return (
    <Box className={classes.cellContainer} flex={1} justifyContent="center">
      <Button onClick={handleOpenVersionHistory} className={classes.btnVersion}>
        {version}
      </Button>
    </Box>
  );
};

VersionHistory.defaultProps = {
  version: 0,
};

VersionHistory.propTypes = {
  version: PropTypes.number,
  handleOpenVersionHistory: PropTypes.func.isRequired,
};

export default memo(VersionHistory);

import React, { useState } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import CardDetailsHeader from "./CardDetailsHeader";
import { EditButtons } from "./Buttons";

const CardDetails = ({ disabled, onCancel, onEdit, onSave }) => {
  const [dirty, setDirty] = useState(null);

  const restore = () => {
    console.count("** [CardDetails] restore");
  };

  const handleCancel = e => {
    console.count("** [CardDetails] handleCancel");
    setDirty(false);
    restore();
    onCancel();
  };

  const handleSave = e => {
    console.count("** [CardDetails] handleSave");
    setDirty(false);
    onSave();
  };

  return (
    <>
      <Grid item xs={12}>
        <CardDetailsHeader />
      </Grid>
      {dirty && (
        <Grid container item justifyContent="flex-end" style={{ marginTop: "1.5em" }}>
          <EditButtons
            disabled={disabled}
            onCancel={handleCancel}
            onSave={handleSave}
          />
        </Grid>
      )}
    </>
  );
};

CardDetails.defaultProps = {
  disabled: false,
};

CardDetails.propTypes = {
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CardDetails;

import { gql } from '@apollo/client';

const ProjectLookup = gql`
  fragment ProjectLookup on ProjectDTO {
    __typename

    projectId
    name
  }
`;

export default ProjectLookup;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import DashboardWidget from "../../common/DashboardWidget";
import DashboardWidgetHead from "../../common/DashboardWidgetHead";
import DashboardWidgetBody from "../../common/DashboardWidgetBody";
import MessageArea from "./MessageArea";

const useStyles = makeStyles(theme => ({
  moreBtn: {
    padding: 0,
  },
  viewLink: {
    fontSize: 11,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  body: {
    minHeight: "70vh",
    maxHeight: "70vh",
    overflowY: "auto",
  },
  foot: {
    padding: 15,
  },
  widgetHead: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const MessagingWidget = () => {
  const classes = useStyles();

  return (
    <DashboardWidget>
      <DashboardWidgetHead
        customClass={classes.widgetHead}
        title={<FormattedMessage id="widget.messaging" />}
        rightTitle="#Marknadsportalen"
      >
        {/* <IconButton className={classes.moreBtn}>
          <MoreHorizIcon />
        </IconButton> */}
      </DashboardWidgetHead>
      <DashboardWidgetBody customClass={classes.body}>
        <MessageArea />
      </DashboardWidgetBody>
      <Box align="center" mt={1} className={classes.foot}>
        <Link to="/messaging" className={classes.viewLink}>
          <FormattedMessage id="product.readMore" />
        </Link>
      </Box>
    </DashboardWidget>
  );
};

export default MessagingWidget;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDAT_RESOURCE_GROUP_ASSIGNMENT = gql`
  mutation UpdateResourceGroupAssignment(
    $update: ResourceGroupAssignmentInput
  ) {
    updateResourceGroupAssignment(update: $update) {
      success
      errorResult {
        ...ErrorResultFields
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDAT_RESOURCE_GROUP_ASSIGNMENT;

import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@mui/material";

const ConsentTerm = ({ disabled, label, name, onClick, readOnly, value, ...rest }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={value}
        name={name}
        onClick={(e) => !readOnly && onClick?.(e)}
      />
    }
    disabled={disabled}
    label={label}
    {...rest}
  />
);

ConsentTerm.defaultProps = {
  disabled: false,
  onClick: undefined,
  readOnly: false,
};

ConsentTerm.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.bool.isRequired,
};

export default ConsentTerm;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";
import ResourceMetaDataDTO from "../fragments/ResourceMetadata";

const UPDATE_RESOURCE_METADATA = gql`
  mutation UpdateResourceMetaData($metadata: MetaDataInput) {
    updateResourceMetaData(metadata: $metadata) {
      success
      errorResult {
        ...ErrorResultFields
      }
      metadata {
        resourceMetaData {
          ...ResourceMetadata
        }
      }
    }
  }
  ${ResourceMetaDataDTO}
  ${ErrorResultFragment}
`;

export default UPDATE_RESOURCE_METADATA;

import React from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import HelpUserTableRow from "./HelpUserTableBody";
import HelpUserTablHeader from "./HelpUserTableHeader";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  paper: {
    color: theme.palette.primary.main,
    display: "block",
    overflowX: "auto",
  },
  table: {
    marginBottom: 20,
  },
  spinner: {
    color: theme.palette.primary.main,
    marginTop: 5,
  },
}));

const HelpUserTable = ({
  alreadyExistingUsers,
  companyList,
  loading,
  preparedInvitations,
  setNewCompanyModal,
  setPreparedInvitations,
  siteRoleOptions,
}) => {
  const classes = useStyles();

  console.log("preparedInvitations >>>>", preparedInvitations);

  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.root}>
        <Table className={classes.table} size="small">
          <HelpUserTablHeader />
          {loading ? (
            <CircularProgress className={classes.spinner} size={20} />
          ) : (
            <TableBody>
              {preparedInvitations.map((invitation, idx) => {
                return (
                  <HelpUserTableRow
                    companyList={companyList}
                    idx={idx}
                    invitation={invitation}
                    key={invitation.email}
                    preparedInvitations={preparedInvitations}
                    setNewCompanyModal={setNewCompanyModal}
                    setPreparedInvitations={setPreparedInvitations}
                    siteRoleOptions={siteRoleOptions}
                  />
                );
              })}
              {alreadyExistingUsers.map(invitation => {
                return (
                  <HelpUserTableRow
                    existingEmail={invitation}
                    isExistingUser
                    key={invitation}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </Paper>
  );
};

HelpUserTable.defaultProps = {
  companyList: [],
  loading: false,
  preparedInvitations: [],
  siteRoleOptions: [],
  siteUserRole: null,
};

HelpUserTable.propTypes = {
  companyList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  preparedInvitations: PropTypes.arrayOf(PropTypes.shape({})),
  setNewCompanyModal: PropTypes.func.isRequired,
  setPreparedInvitations: PropTypes.func.isRequired,
  siteRoleOptions: PropTypes.arrayOf(PropTypes.shape({})),
  siteUserRole: PropTypes.number,
};

export default HelpUserTable;

import { InputBase, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";


const SimpleEdit = ({ defaultValue, onChange }) => {

  const [internalValue, setInternalValue] = useState(defaultValue);
  const [editMode, setEditMode] = useState(false);


  useEffect(() => {
    onChange?.(internalValue)
  }, [internalValue])

  const beginEdit = () => {
    setEditMode(true);
  }

  const endEdit = () => {
    setEditMode(false);
  }

  const handleChange = (e) => {
    setInternalValue(e.target.value);    
  }


  const handleOnKeyDown = (e) => {
    if (e.key === "Enter") {
      setEditMode(false);
    }
  };

  return (
    editMode ? (<InputBase
      autoFocus
      value={internalValue}
      onChange={handleChange}
      onBlur={endEdit}
      onKeyPress={handleOnKeyDown}

    />) : (<Typography onClick={beginEdit}>{internalValue}</Typography>)    
    )
}

export default SimpleEdit;
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_NETWORK_SELECTOR_ITEMS } from "../../graphql/queries/CompanyAdminContext";

// eslint-disable-next-line import/prefer-default-export
export const useGetNetworkSelectorItems = ({ companyId }) => {
  const [items, setItems] = useState([]);

  const [getItems, { data, loading, error }] = useLazyQuery(
    GET_NETWORK_SELECTOR_ITEMS,
    {
      variables: {
        companyId,
      },
      skip: !companyId,
    }
  );

  useEffect(() => {
    var items =
      data?.companyAdminContext?.companyNetworks?.networkSelectorItems;
    if (Array.isArray(items)) {
      setItems(items);
    } else {
      setItems([]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setItems([]);
    }
  }, [error]);

  return {
    getNetworkSelectorItems: getItems,
    networkSelectorItems: items,
    data,
    loading,
    error,
  };
};

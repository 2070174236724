import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const INIT_COMPANY_MAIL_LOGO_UPLOAD = gql`
  mutation InitCompanyMailLogoUpload($targetCompanyId: Int!) {
    companyAdminContext {
      settings {
        initCompanyMailLogoUpload(targetCompanyId: $targetCompanyId) {
          success
          url
          errorResult {
            ...ErrorResultFields
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default INIT_COMPANY_MAIL_LOGO_UPLOAD;

import { useIntl } from "react-intl";
import { alpha, InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.grey, 0.15),
    marginLeft: "auto",
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 10,
      marginLeft: 0,
    },
  },
  searchIcon: {
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    right: 0,
  },
  inputRoot: {
    color: theme.palette.common.white,
    marginRight: 8,
  },
  inputInput: {
    paddingTop: 11,
    paddingRight: 20,
    paddingBottom: 11,
    paddingLeft: 11,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: theme.palette.common.white,
    fontSize: 12,
    [theme.breakpoints.up("sm")]: {
      width: 85,
      "&:focus": {
        width: 235,
      },
    },
    "&::placeholder": {
      color: theme.palette.common.white,
    },
  },
  zoomIcon: {
    fontSize: 16,
  },
}));

const SearchField = ({ searchText, handleOnChange, siteId }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.search}>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        disabled={siteId === null || siteId === undefined}
        inputProps={{ "aria-label": "Search" }}
        onChange={handleOnChange}
        placeholder={intl.formatMessage({ id: "nav.search" })}
        value={searchText}
      />
      <div className={classes.searchIcon}>
        <i className={"nc-icon nc-zoom-split " + classes.zoomIcon} />
      </div>
    </div>
  );
};

export default SearchField;

import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { TreeItem } from "@mui/lab";
import FolderLabel from "./FolderLabel";

const useStyles = makeStyles(theme => ({
  draggedOver: {
    backgroundColor: "transparent",
    padding: 5,
    border: "2px dashed #ddd",
  },
}));

const RowData = ({
  checkedGroups,
  children,
  group,
  groupTree,
  handleOpenNode,
  isEditMode,
  onFolderRowClick,
  selectedFolderIds,
  selectedNode,
  handleCheckboxChange,
}) => {
  const isSelected = selectedFolderIds.find(x => x === group.groupId);
  const dynamicStyle = {
    isSelected,
  };

  const groupId = group && group.groupId;
  const parentId = group && group.parentGroupId;

  const classes = useStyles(dynamicStyle);

  if (!group) return null;

  const handleOnFolderRowClick = () => {
    handleOpenNode(group.groupId);
    onFolderRowClick(group);
  };

  const handleOnKeyDown = e => {
    if (e.key === "Enter" && !isEditMode) {
      handleOnFolderRowClick();
    }
  };

  const onCheckboxChange = e => {
    handleCheckboxChange(e, group);
  };

  const renderFolderLabel = () => {
    return (
      <FolderLabel
        checkedGroups={checkedGroups}
        group={group}
        groupId={groupId}
        groupName={group.name}
        handleCheckboxChange={onCheckboxChange}
        handleOnFolderRowClick={handleOnFolderRowClick}
        handleOnKeyDown={handleOnKeyDown}
        selectedNode={selectedNode}
      />
    );
  };

  return (
    <TreeItem nodeId={group.groupId} label={renderFolderLabel()}>
      {children}
    </TreeItem>
  );
};

RowData.defaultProps = {
  handleOpenNode: () => {},
  children: null,
  isEditMode: false,
  selectedNode: null,
};

RowData.propTypes = {
  selectedNode: PropTypes.shape({}),
  children: PropTypes.node,
  handleOpenNode: PropTypes.func,
  group: PropTypes.shape({
    childGroups: PropTypes.arrayOf(
      PropTypes.shape({
        groupId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    groupId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildGroups: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  checkedGroups: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFolderRowClick: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  groupTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setFolderTree: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default memo(RowData);

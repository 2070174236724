import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const DELETE_TASK = gql`
  mutation DeleteTask(
    $deleteTaskParams: DeleteTaskParamsInput!
  ) {
    projects {
      deleteTask(
        deleteTaskParams: $deleteTaskParams
      ) {
        success
        errorResult { ...ErrorResultFields }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_TASK;

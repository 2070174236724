import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import {
  SELECT_FIELD_MANDATORY,
  NUMBER_FIELD,
  MULTI_CHECKBOX_FIELD,
  MULTI_SELECT_WITH_QUANTITIES,
  MULTI_SELECT_NUMBER,
} from "../../constant/types";
import { ErrorText } from "../common";
import InputTextInline from "../common/FormControl/InputTextInline";
import { GET_PRODUCT_PROPERTY_VALUES } from "../../graphql/queries";
import MultiCheckboxField from "./MultiCheckboxField";
import SelectFieldMandatory from "./SelectFieldMandatory";
import MultiSelectWithQuantities from "./MultiSelectWithQuantities";
import MultiSelectNumber from "./MultiSelectNumber";

const RFPField = ({ value, field, formValues, identifier, onChangeValue }) => {
  const {
    type,
    name,
    minValue,
    maxValue,
    propertyValues,
    id,
    conditionalPropertyIds,
  } = field;

  const [propertyValuesState, setPropertyValuesState] = useState(
    propertyValues || []
  );

  const [conditionalPropertyValues, setConditionalPropertyValues] = useState(
    []
  );

  const handleOnChangeValue = e => {
    const { value: fieldValue } = e.target;
    onChangeValue({ id, value: fieldValue, quantity: 0 });
  };

  const handleOnChangeNumberValue = e => {
    const { value: fieldValue } = e.target;
    onChangeValue({ id, value: 0, quantity: Number(fieldValue) });
  };

  const handleCheckboxOnChange = e => {
    let tempValues = value || [];
    const { checked, name: checkBoxName } = e.target;
    if (checked) {
      tempValues.push({ propertyValueId: Number(checkBoxName), value: true });
    } else {
      tempValues = tempValues.filter(
        x => x.propertyValueId !== Number(checkBoxName)
      );
    }

    onChangeValue({ id, value: tempValues });
  };

  const handleOnChangeMultiSelectQuantities = e => {
    const tempValues = value || [];
    const { value: optionValue, name: selectFieldName } = e.target;

    const filteredValues = tempValues.filter(
      x => x.propertyValueId !== Number(name)
    );
    filteredValues.push({
      propertyValueId: Number(selectFieldName),
      value: optionValue,
    });

    onChangeValue({ id, value: filteredValues });
  };

  const handleOnChangeMultiSelectNumber = e => {
    const tempValues = value || [];
    const { value: optionValue, name: selectFieldName } = e.target;

    const filteredValues = tempValues.filter(
      x => x.propertyValueId !== Number(selectFieldName)
    );

    filteredValues.push({
      propertyValueId: Number(selectFieldName),
      value: Number(optionValue),
    });

    onChangeValue({ id, value: filteredValues });
  };

  const { data: dependentPropertyValues, loading, error, refetch } = useQuery(
    GET_PRODUCT_PROPERTY_VALUES,
    {
      variables: {
        identifier,
        propertyId: field.id,
        conditionalPropertyValues,
      },
      skip:
        !conditionalPropertyIds ||
        (conditionalPropertyIds && conditionalPropertyIds.length < 1) ||
        !field ||
        !field.id ||
        !identifier,
    }
  );

  useEffect(() => {
    if (formValues && conditionalPropertyIds) {
      const tempValue = [];
      conditionalPropertyIds.forEach(x => {
        if (formValues[x] && formValues[x].value) {
          tempValue.push({
            propertyId: x,
            propertyValueId: formValues[x].value,
          });
        }
      });
      setConditionalPropertyValues(tempValue);
    }
  }, [formValues, conditionalPropertyIds]);

  useEffect(() => {
    if (
      conditionalPropertyIds &&
      conditionalPropertyIds.length > 0 &&
      identifier &&
      field
    ) {
      refetch();
    }
  }, [formValues, conditionalPropertyIds, field]);

  useEffect(() => {
    if (
      dependentPropertyValues &&
      dependentPropertyValues.requestForProposal &&
      dependentPropertyValues.requestForProposal.productPropertyValues &&
      dependentPropertyValues.requestForProposal.productPropertyValues.length >
        0
    ) {
      const { requestForProposal } = dependentPropertyValues;
      const {
        productPropertyValues: newProductPropertyValues,
      } = requestForProposal;
      setPropertyValuesState(newProductPropertyValues);
    }
  }, [dependentPropertyValues]);

  if (error) {
    return <ErrorText message="An error has occurred in this field." />;
  }

  const renderField = () => {
    switch (type) {
      case SELECT_FIELD_MANDATORY: {
        return (
          <SelectFieldMandatory
            key={`select-field-${id}`}
            propertyValuesState={propertyValuesState}
            field={field}
            value={value}
            handleOnChangeValue={handleOnChangeValue}
          />
        );
      }
      case NUMBER_FIELD: {
        return (
          <InputTextInline
            key={`field-key-${id}`}
            label={name}
            type="number"
            inputProps={{ min: minValue, max: maxValue }}
            onChange={handleOnChangeNumberValue}
            value={value}
          />
        );
      }
      case MULTI_CHECKBOX_FIELD: {
        return (
          <MultiCheckboxField
            key={`checkbox-${id}`}
            propertyValuesState={propertyValuesState}
            field={field}
            handleCheckboxOnChange={handleCheckboxOnChange}
            value={value}
          />
        );
      }
      case MULTI_SELECT_WITH_QUANTITIES:
        return (
          <MultiSelectWithQuantities
            key={`multi-select-${id}`}
            field={field}
            loading={loading}
            propertyValuesState={propertyValuesState}
            onChange={handleOnChangeMultiSelectQuantities}
            value={value}
          />
        );

      case MULTI_SELECT_NUMBER:
        return (
          <MultiSelectNumber
            key={`multi-select-number-${id}`}
            field={field}
            loading={loading}
            propertyValuesState={propertyValuesState}
            onChange={handleOnChangeMultiSelectNumber}
            value={value}
          />
        );

      default:
        // TODO: default field?
        return "Field not yet implemented.";
    }
  };

  return <>{renderField()}</>;
};

RFPField.defaultProps = {};

RFPField.propTypes = {
  identifier: PropTypes.number.isRequired,
  field: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    propertyValues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
      })
    ).isRequired,
    id: PropTypes.number.isRequired,
    dependentPropertyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    conditionalPropertyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

export default RFPField;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Typography, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomSelect from "../../../../common/CustomSelect";
import { PrimaryButton } from "../../../../common/Buttons";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    marginBottom: 7,
  },
}));

const dummyOptions = [
  { label: "Option 1", value: "one" },
  { label: "Option 2", value: "two" },
];

const MonitorSettings = ({ handleSetDraft }) => {
  const classes = useStyles();
  const [settings, setSettings] = useState({});

  const setDraft = () => {
    handleSetDraft("monitorSettings", true);
  };

  const setUnDraft = () => {
    handleSetDraft("monitorSettings", false);
  };

  const handleSelect = (name, value) => {
    setSettings({ ...settings, [name]: value });
    setDraft();
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.pricingModel" />
          </Typography>
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            options={dummyOptions}
            value={settings.pricingModel}
            onChange={e => handleSelect("pricingModel", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={6}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.priceList" />
          </Typography>
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            options={dummyOptions}
            value={settings.priceList}
            onChange={e => handleSelect("priceList", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} />
        <Grid item xs={12} md={6}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.preFlight" />
          </Typography>
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            options={dummyOptions}
            value={settings.preFlight}
            onChange={e => handleSelect("preFlight", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} />
      </Grid>

      <Grid container justifyContent="flex-end">
        <PrimaryButton onClick={() => setUnDraft()}>
          <FormattedMessage id="btn.save" />
        </PrimaryButton>
      </Grid>
    </Box>
  );
};

MonitorSettings.propTypes = {
  handleSetDraft: PropTypes.func.isRequired,
};

export default MonitorSettings;

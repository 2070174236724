import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles"
import { DraggableModal, DraggableModalHead, DraggableModalBody } from "../../components/common";
import { FormattedMessage } from "react-intl";
import OneStreamUpload from "../../components/OneStream/OneStreamUpload";
import OneStreamSubmit from "../../components/OneStream/OneStreamSubmit";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { INITIALIZE_FOLDER_IMPORT } from "../../graphql/mutations";
import axios from "axios";
import { FileUpload } from "@mui/icons-material";
import { useAlert } from "react-alert";



const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "95%",
    },
  },
}));

const ImportDialog = ({ siteId, folderId, open, onClose, refetch }) => {

  const [progress, setProgress] = useState(0);
  const [fileToUpload, setUploadedFile] = useState();
  const [importFiles, setImportFiles] = useState(false);
  const classes = useStyles();
  const alert = useAlert();

  const [getUploadUrl, data, loading, error] = useMutation(INITIALIZE_FOLDER_IMPORT);


  const handleRemoveUploadedFile = () => {
    setUploadedFile(null);
  }

  const handleOnSelectUploadFile = (files) => {

     
    if (files && files[0]) {      
      setUploadedFile(files[0]);
    } else {
      console.warn("An error could be happening in selecting files.");
    }
  };

  const handleUploadFiles = async () => {
     
    let uploadUrl = "";
    setProgress(1);

    const config = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        setProgress(percentCompleted);
      },
    };

    try {

      if (fileToUpload === null || fileToUpload === undefined) {
        return;
      }

      const res = await getUploadUrl({ variables: { siteId: siteId, folderId: folderId, importFiles: false}});
      if (res && res.data && res.data.initFolderImportUpload && res.data.initFolderImportUpload.success) {
        uploadUrl = res.data.initFolderImportUpload.url;
      }

      const formData = new FormData();
      formData.append("file", fileToUpload);

      const response = await axios.post(`${uploadUrl}`, formData, config);
       
      if (
        response &&
        response.data &&
        response.data.success &&
        response.status === 200
      ) {
        console.log(`Success uploading file.`);        
        alert.success(<FormattedMessage id="fileupload.success" />);
        refetch();
        onClose();
      } else {
        console.error(
          `An error occurred while uploading the file. ${JSON.stringify(
            response
          )}`
        );
      }
    } catch (uploadException) {
      console.error(
        `An error occurred while uploading the file. ${JSON.stringify(
          uploadException
        )}`
      );
    }
  };




  return (<DraggableModal
    open={open}
    handleClose={onClose}
    customClass={classes.modal}
  >
    <DraggableModalHead
      handleClose={onClose}
      title={"Importera mappstruktur"}
    />
    <DraggableModalBody>
     


      Välj en zipfil som innehåller de mappar och ev. filer som du vill importera.
      <OneStreamUpload
        uploadedFile={fileToUpload}
        handleOnSelectUploadFile={handleOnSelectUploadFile}
        handleRemoveUploadedFile={handleRemoveUploadedFile}
      />

      <Checkbox value={!importFiles} onChange={() => setImportFiles(!importFiles)} /> Importera endast mappar, ej filer.
      
      <OneStreamSubmit
        progress={progress}
        handleUploadFiles={handleUploadFiles}
        error={error}
      />
    </DraggableModalBody>
  </DraggableModal>)
}

export default ImportDialog;
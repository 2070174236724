import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";
import SiteMenuEditItem from "../../../fragments/SiteMenuEditItem";

const EDIT_COMPANY_ROLE_MENU = gql`
  query EditCompanyRoleMenu($companyId: Int!, $companyRoleId: Int!) {
    companyAdminContext {
      companyRoles {
        editCompanyMenu(companyId: $companyId, companyRoleId: $companyRoleId) {
          availablePages
          siteMenus {
            ...ChildMenusRecursive
            ...SiteMenuEditItem
          }
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${SiteMenuEditItem}
  ${ErrorResultFragment}
`;

export default EDIT_COMPANY_ROLE_MENU;

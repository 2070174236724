/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { useSnackbar } from "../../hooks";
import NewsHead from "../../components/News-Old/NewsHead";
import CommentsContainer from "../../components/News-Old/Comments/CommentsContainer";
import { webApiURL } from "../../services/config";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
  iframeWrap: {
    width: "99.8%",
    height: "95vh",
    // marginTop: "5%"
  },
}));

const NewspostDisplay = ({ location, accessToken, match }) => {
  const classes = useStyles();
  const { params } = match || {};
  const { id: urlParamsId } = params || {};
  const { state = {} } = location;
  const { newpostId } = state;
  const [siteNewsObject, setSiteNewsObject] = useState({});
  const [currentNewsPostId, setCurrentNewsPostId] = useState(
    newpostId || urlParamsId
  );

  const snackbar = useSnackbar();

  const showErrorAlert = () => {
    snackbar.workspaceError("An error occurred while fetching news post");
  };

  const getSiteNewsObject = async id => {
    try {
      const response = await axios.get(`${webApiURL}/sitenews/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response && response.data && response.status === 200) {
        setSiteNewsObject(response.data);
      } else {
        showErrorAlert();
      }
    } catch (error) {
      showErrorAlert();
    }
  };

  useEffect(() => {
    if (currentNewsPostId) {
      getSiteNewsObject(currentNewsPostId);
    }
  }, [currentNewsPostId]);

  useEffect(() => {
    if (newpostId) {
      setCurrentNewsPostId(newpostId);
    }
  }, [location]);

  useEffect(() => {
    if (urlParamsId) {
      setCurrentNewsPostId(urlParamsId);
    }
  }, [urlParamsId]);

  return (
    <Container maxWidth={false} className={classes.root}>
      <NewsHead />
      <style dangerouslySetInnerHTML={{ __html: siteNewsObject["gjs-css"] }} />
      <div
        className={siteNewsObject["gjs-css"]}
        styles={siteNewsObject["gjs-css"]}
        dangerouslySetInnerHTML={{ __html: siteNewsObject["gjs-html"] }}
      />
      <CommentsContainer siteNewsPostid={currentNewsPostId} />
    </Container>
  );
};

NewspostDisplay.defaultProps = {
  location: {
    state: {
      showInfoModal: false,
    },
  },
};

NewspostDisplay.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      showInfoModal: PropTypes.bool,
    }),
  }),
  accessToken: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps)(NewspostDisplay);

import { gql } from '@apollo/client';
import AccountSettingsEditFragment from "../../../fragments/CompanyAdminContext/AccountSettingsEdit";

const EDIT_ACCOUNT_SETTINGS = gql`
  query EditAccountSettings($userId: Int!) {
    companyAdminContext {
      users {
        editAccountSettings(userId: $userId) {
          ...AccountSettingsEdit
        }
      }
    }
  }

  ${AccountSettingsEditFragment}
`;

export default EDIT_ACCOUNT_SETTINGS;

import React from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionActions";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 20,
    "&::before": {
      backgroundColor: "transparent",
    },
  },
  panelHead: {
    backgroundColor: theme.palette.background.gray,
    borderRadius: 3,
    maxHeight: "unset",
    minHeight: "unset",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "unset",
    },
    "& .MuiIconButton-root": {
      padding: "8px 12px",
    },
  },
  heading: {
    fontWeight: 600,
    color: theme.palette.text.gray,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
  },
  subLabel: {
    fontWeight: 400,
    fontSize: 11,
    marginLeft: 5,
    opacity: 0.8,
  },
  panelBody: {
    backgroundColor: theme.palette.background.lightGray,
    padding: 8,
    flexDirection: "column",
  },
}));

const ExpansionDefault = ({
  label,
  subLabel,
  panelContent,
  panelHeader,
  children,
  expanded,
  defaultExpanded,
  customClass,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Accordion
      className={clsx([classes.root, customClass])}
      elevation={0}
      expanded={expanded}
      onChange={onChange}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={panelContent}
        id={panelHeader}
        className={classes.panelHead}
      >
        <Typography className={classes.heading}>
          {label}
          <span className={classes.subLabel}>{subLabel}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.panelBody}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionDefault;

import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import AddressHeaderTitle from "./AddressHeaderTitle";

const DeliveryAddressHeader = () => {
  return (
    <AddressHeaderTitle>
      <FormattedMessage id="cart.addressDelivery" />
    </AddressHeaderTitle>
  );
};

export default memo(DeliveryAddressHeader);

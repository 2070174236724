import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";
import clsx from "clsx";
import CustomSelect from "../common/CustomSelect";
import ResponsiveDateRange from "./ResponsiveDateRange";
import FolderSelectorModal from "./FolderSelectorModal";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
    transform: "translate(14px, 13px) scale(1)",
  },
  liftSelect: {
    "& .MuiSelect-select": {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiSelect-select": {
        padding: "23px 12px",
        fontSize: 18,
        color: "#000",
      },
    },
  },
  menuItem: {
    fontSize: 15,
    color: theme.palette.text.primary,
  },
  textLabels: {
    color: theme.palette.text.primary,
    marginBottom: 8,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  searchBtn: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      padding: "2px 8px",
      minHeight: 34,
      marginTop: 28,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "23px 18px",
      marginTop: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },
  mobileDayPicker: {
    display: "block",
  },
  iconButton: {
    padding: 0,
  },
  icon: {
    fontSize: 12,
    color: theme.palette.common.black,
  },
  locationContainer: {
    position: "relative",
  },
  selectedFolderContainer: {
    position: "absolute",
    right: 8,
    bottom: -12,
  },
  selectedFolderLabel: {
    fontSize: 10,
    fontStyle: "italic",
  },
  bookSelectContainers: {
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
}));

const BookingBar = ({
  pickUpDate,
  setPickUpDate,
  returnDate,
  setReturnDate,
  pickUpTime,
  setPickUpTime,
  returnTime,
  setReturnTime,
  enteredTo,
  setEnteredTo,
  handleSearch,
  buildings,
  building,
  setBuilding,
  lifts,
  lift,
  setLift,
  siteId,
  pickUpTimeSetting,
  returnTimeSetting,
  bookingSettings,
  setBookingSettingsChanged,
}) => {
  const classes = useStyles();
  const customLabelClass = classes.label;
  const intl = useIntl();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));

  const canManageBookingSettings = useSelector(
    state =>
      state?.ui?.toggleWorkspaces?.workspace?.siteRoleRights?.canManageBookingSettings || false
  );

  const [folderSelectorModal, setFolderSelectorModal] = useState(false);

  const weekends = {
    daysOfWeek: [0, 6],
  };

  const disableLifts = building === "placeholder";

  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid
        item
        xs={12}
        s={12}
        md={4}
        lg={3}
        xl={3}
        className={classes.locationContainer}
      >
        <Grid container item xs={12} alignItems="center">
          {!mobileView && (
            <Grid xs={11}>
              <Typography className={classes.textLabels}>
                <FormattedMessage id="booking.pickupLocation" />
              </Typography>
            </Grid>
          )}
          {canManageBookingSettings && (
            <Grid xs={1} align="right">
              <IconButton
                className={classes.iconButton}
                onClick={() => setFolderSelectorModal(true)}
              >
                <Icon className={clsx(["fas fa-cog", classes.icon])} />
              </IconButton>
            </Grid>
          )}
        </Grid>

        <CustomSelect
          customClass={classes.liftSelect}
          inputLabelCustomClass={customLabelClass}
          options={buildings}
          value={building}
          menuItemClass={classes.menuItem}
          onChange={e => setBuilding(e.target.value)}
          inputLabel={intl.formatMessage({ id: "booking.buildingLocation" })}
        />

        <Box className={classes.selectedFolderContainer}>
          <Typography className={classes.selectedFolderLabel}>
            {bookingSettings?.folderName ?? ""}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        s={12}
        md={4}
        lg={3}
        xl={3}
        className={classes.bookSelectContainers}
      >
        {!mobileView && (
          <Typography className={classes.textLabels}>
            <FormattedMessage id="booking.hireLiftType" />
          </Typography>
        )}
        <CustomSelect
          customClass={classes.liftSelect}
          inputLabelCustomClass={customLabelClass}
          options={lifts}
          value={lift}
          menuItemClass={classes.menuItem}
          onChange={e => setLift(e.target.value)}
          disabled={disableLifts}
          inputLabel={intl.formatMessage({ id: "booking.typeOrModel" })}
        />
      </Grid>
      <Grid
        item
        xs={12}
        s={12}
        md={3}
        lg={2}
        xl={2}
        className={classes.bookSelectContainers}
      >
        {!mobileView && (
          <Grid xs={11}>
            <Typography className={classes.textLabels}>
              <FormattedMessage id="booking.date" />
            </Typography>
          </Grid>
        )}
        <div className={classes.mobileDayPicker}>
          <ResponsiveDateRange
            mobile={mobileView}
            pickUpDate={pickUpDate}
            returnDate={returnDate}
            setPickUpDate={setPickUpDate}
            setReturnDate={setReturnDate}
            returnTime={returnTime}
            setReturnTime={setReturnTime}
            pickUpTime={pickUpTime}
            setPickUpTime={setPickUpTime}
            weekends={weekends}
            enteredTo={enteredTo}
            setEnteredTo={setEnteredTo}
            lift={lift}
            pickUpTimeSetting={pickUpTimeSetting}
            returnTimeSetting={returnTimeSetting}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        s={12}
        md={1}
        lg={1}
        xl={1}
        className={classes.bookSelectContainers}
      >
        <Button className={classes.searchBtn} onClick={() => handleSearch()}>
          <FormattedMessage id="btn.search" />
        </Button>
      </Grid>

      {folderSelectorModal && (
        <FolderSelectorModal
          open={folderSelectorModal}
          bookingSettings={bookingSettings}
          setBookingSettingsChanged={setBookingSettingsChanged}
          handleClose={() => setFolderSelectorModal(false)}
          siteId={siteId}
          pickUpTimeSetting={pickUpTimeSetting}
          returnTimeSetting={returnTimeSetting}
        />
      )}
    </Grid>
  );
};

BookingBar.defaultProps = {
  buildings: [],
  lifts: [],
  siteId: null,
  pickUpTimeSetting: null,
  returnTimeSetting: null,
};

BookingBar.propTypes = {
  lift: PropTypes.number.isRequired,
  setLift: PropTypes.func.isRequired,
  pickUpDate: PropTypes.instanceOf(Date).isRequired,
  setPickUpDate: PropTypes.func.isRequired,
  returnDate: PropTypes.instanceOf(Date).isRequired,
  setReturnDate: PropTypes.func.isRequired,
  setBuilding: PropTypes.func.isRequired,
  pickUpTime: PropTypes.instanceOf(Date).isRequired,
  setPickUpTime: PropTypes.func.isRequired,
  returnTime: PropTypes.instanceOf(Date).isRequired,
  setReturnTime: PropTypes.func.isRequired,
  enteredTo: PropTypes.instanceOf(Date).isRequired,
  setEnteredTo: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  building: PropTypes.number.isRequired,
  buildings: PropTypes.arrayOf(PropTypes.shape({})),
  lifts: PropTypes.arrayOf(PropTypes.shape({})),
  siteId: PropTypes.number,
  pickUpTimeSetting: PropTypes.instanceOf(Date),
  returnTimeSetting: PropTypes.instanceOf(Date),
  setBookingSettingsChanged: PropTypes.func.isRequired,
};

export default BookingBar;

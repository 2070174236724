import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
  tagLetter: {
    minWidth: 34,
    maxWidth: 34,
    padding: "2px 10px",
    borderRadius: "50%",
    fontSize: 18,
  },
  widgetParentName: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
    textAlign: "left",
    marginBottom: 15,
  },
}));

const TagLetters = ({ tagLetters, setletter }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tagLetters}>
      {tagLetters.map(x => (
        <Button onClick={() => setletter(x)} className={classes.tagLetter}>
          {x}
        </Button>
      ))}
    </Box>
  );
};

TagLetters.defaultProps = {};

TagLetters.propTypes = {
  tagLetters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setletter: PropTypes.func.isRequired,
};

export default TagLetters;

import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "95%",
    },
  },
  modalBody: {
    textAlign: "center",
  },
  statusIcon: {
    padding: 6,
    width: 40,
    height: 40,
    overflow: "unset",
    fontSize: 20,
    borderRadius: "50%",
    marginBottom: 20,
  },
  statusIconWarning: {
    padding: 0,
    lineHeight: "40px",
    backgroundColor: "#ffe5a8",
    color: "#da8f09",
  },
  text: {
    fontSize: 14,
    marginBottom: 20,
  },
  subText: {
    fontSize: 12,
  },
  btnYes: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnNo: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginRight: 5,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.gray,
    "&:hover": {
      backgroundColor: theme.palette.text.gray,
      opacity: 0.8,
    },
  },
  mb20: {
    marginBottom: 20,
  },
  statusCountOk: {
    color: "#37910f",
    marginBottom: 0,
    fontSize: 24,
    display: "block",
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 20,
      marginRight: 5,
    },
  },
  statusCountIssues: {
    color: "#ef8157",
    marginBottom: 0,
    fontSize: 24,
    display: "block",
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 20,
      marginRight: 5,
    },
  },
  iconEquals: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 20,
  },
  total: {
    fontSize: 20,
    marginBottom: 0,
  },
}));

const WarningModal = ({
  open,
  handleClose,
  totalFiles,
  oKFiles,
  errorFiles,
  handleAddToCart,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        <Icon
          className={clsx([
            "fas fa-exclamation",
            classes.statusIcon,
            classes.statusIconWarning,
          ])}
        />
        <Typography className={classes.text}>
          <FormattedMessage id="onestream.problemInfo" />
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.mb20}
        >
          <Grid item xs={3} align="center">
            <span className={classes.statusCountOk}>
              <Icon className={clsx(["fa fa-thumbs-up", classes.iconOk])} />
              {oKFiles}
            </span>
            <Typography className={classes.subText}>
              <FormattedMessage id="onestream.approved" />
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <span className={classes.statusCountIssues}>
              <Icon
                className={clsx(["fa fa-thumbs-down", classes.iconIssues])}
              />
              {errorFiles}
            </span>
            <Typography className={classes.subText}>
              <FormattedMessage id="onestream.warnings" />
            </Typography>
          </Grid>
          <Grid item xs={1} align="center">
            <Icon className={clsx(["fa fa-equals", classes.iconEquals])} />
          </Grid>
          <Grid item xs={3} align="center">
            <Typography className={classes.total}>{totalFiles}</Typography>
            <Typography className={classes.subText}>
              <FormattedMessage id="onestream.total" />
            </Typography>
          </Grid>
        </Grid>
        <Typography className={classes.text}>
          <FormattedMessage id="onestream.addBinder" />
        </Typography>
        <Box>
          <Button className={classes.btnNo} onClick={() => handleClose()}>
            <FormattedMessage id="onestream.abort" />
          </Button>
          <Button className={classes.btnYes} onClick={() => handleAddToCart()}>
            <FormattedMessage id="onestream.addBasket" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

WarningModal.defaultProps = {
  errorFiles: null,
  oKFiles: null,
  totalFiles: null,
  open: false,
};

WarningModal.propTypes = {
  errorFiles: PropTypes.number,
  oKFiles: PropTypes.number,
  totalFiles: PropTypes.number,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
};

export default WarningModal;

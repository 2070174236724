import { gql } from '@apollo/client';
import FolderProductSettings from "../fragments/FolderProductSettings";

const getProductionSettingsForSite = gql`
  query getProductionSettingsForSite($siteId: Int!) {
    monitoringContext {
      getProductionSettingsForSite(siteId: $siteId) {
        folderId
        folderProductSettings {
          ...FolderProductSettingsFragment
        }
      }
    }
  }
  ${FolderProductSettings}
`;

export default getProductionSettingsForSite;

/* eslint-disable no-throw-literal */
import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { debounce } from 'lodash';

const formatMinutesToInternalTime = (time) => {
  let hours = Math.floor(time / 60) || 0;
  let minutes = time % 60 || 0;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

const DurationEditor = ({ value, onEditEnd, propsSetIsEditing, ...props }) => {
  const [internalValue, setInternalValue] = useState(value || 0);
  const [isPopperOpen, setIsPopperOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);
  const popperRef = useRef(null);

  useEffect(() => {    
      setInternalValue(value);    
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      setAnchorEl(inputRef.current);
    }
  }, [inputRef.current]);

  const formatTime = (time) => {
    
    let hours, minutes;

    // Remove any non-digit, non-colon, and non-dot characters
    time = time.replace(/[^0-9:.]/g, "");

    // Split the time string based on the presence of colon or dot
    if (time.includes(":") || time.includes(".")) {
      [hours, minutes] = time.split(/[:.]/);
    } else {
      // If no colon or dot, extract hours and minutes from the string
      hours = time;
      minutes = "00";
    }

    // Parse hours and minutes as integers
    let parsedHours = parseInt(hours) || 0;
    let parsedMinutes = parseInt(minutes) || 0;

    // Adjust hours based on minutes
    parsedHours += Math.floor(parsedMinutes / 60);
    parsedMinutes %= 60;

    // Format hours with leading zeros if necessary
    const formattedHours = String(parsedHours).padStart(Math.max(String(parsedHours).length, 2), "0");

    // Format minutes with leading zeros
    const formattedMinutes = String(parsedMinutes).padStart(2, "0");

    // Construct the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes}`;

    return formattedTime;
  };

  const formatTimeToMinutes = (time) => {
    let hours, minutes;

    // Check if the time string contains a colon or dot
    if (time.includes(":")) {
      // Split the time string based on the presence of colon or dot
      [hours, minutes] = time.split(/[:.]/);
    } else {
      console.error("Time is in wrong format", { time });
    }

    // Parse hours and minutes as integers
    let parsedHours = parseInt(hours) || 0;
    let parsedMinutes = parseInt(minutes) || 0;

    // Adjust hours based on minutes
    parsedHours += Math.floor(parsedMinutes / 60);
    parsedMinutes %= 60;

    // Construct the formatted time string as minutes
    const formattedTime = parsedHours * 60 + parsedMinutes;

    return formattedTime;
  };

  const handleOnKeyDown = (event) => {
    // If Enter is pressed, stop editing and format time
    if (event.key === "Enter") {
      
      setInternalValue(formatTime(event.target.value));
      if (onEditEnd) {
        let formattedTime = formatTime(event.target.value);
        onEditEnd(formatTimeToMinutes(formattedTime), "KeyEnter");
      }
      return;
    }

    // Allow these keys to function normally
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Home",
      "End"
    ];

    // If the pressed key is an allowed key, don't prevent default behavior
    if (allowedKeys.includes(event.key)) {
      return;
    }

    // Allow only specific keys (digits, colon, and dot)
    const isValidKey = /^[0-9:.]$/.test(event.key);

    // If the pressed key is not valid, prevent default behavior
    if (!isValidKey) {
      event.preventDefault();
    }
  };

  const debouncedFormatTimeRef = useRef(
    debounce((inputValue) => {
      const v = formatTime(inputValue);
      setInternalValue(v);
    }, 3000)
  );

  const handleOnChange = (e) => {
    const inputValue = e.target.value;
    setInternalValue(inputValue);
    debouncedFormatTimeRef.current(inputValue);
  };

  const handleBlur = (e) => {
    if (popperRef.current && popperRef.current.contains(e.relatedTarget)) {
      return;
    }
    setInternalValue(formatTime(e.target.value));
    if (onEditEnd) {
      onEditEnd(formatTimeToMinutes(e.target.value), "Blur");
    }
    setIsPopperOpen(false);
  };

  const handleFocus = () => {
    setIsPopperOpen(true);
  };

  const handleClick = (event) => {
    event?.stopPropagation();
    setIsPopperOpen(true);
    if (inputRef.current && document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDoubleClick = (e) => {
    inputRef.current?.select();
  };

  const handleTimeSelect = (e, time) => {
    e.preventDefault();
    e.stopPropagation();

    let formattedTime = formatTime(time);
    let formattedTimeInMinutes = formatTimeToMinutes(time);
    setInternalValue(formattedTime);
    setIsPopperOpen(false);
    //inputRef.current.focus();
    if (onEditEnd) {
      onEditEnd(formattedTimeInMinutes, "TimeSelect");
    }
  };

  const handleClickAway = (event) => {
    if (popperRef.current && popperRef.current.contains(event.target)) {
      return;
    }
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setInternalValue(formatTime(event.target.value));
    if (onEditEnd) {
      onEditEnd(formatTimeToMinutes(event.target.value), "Blur");
    }
    setIsPopperOpen(false);
  };

  useEffect(() => {
    return () => {
      debouncedFormatTimeRef.current.cancel();
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <>
        <InputBase
          {...props}
          autoFocus
          inputProps={{ maxLength: 7, className: props.className, ...props.inputProps }}
          onKeyDown={handleOnKeyDown}
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          value={internalValue}
          inputRef={inputRef}
          className=""
          style={{ ...props.style }}
          sx={{
            ...props.sx,
          }}
        />
        <Popper
          open={isPopperOpen}
          anchorEl={anchorEl}
          placement="top-start"
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                rootBoundary: 'viewport',
              },
            },
            {
              name: 'offset',
              options: {
                offset: [-10, 4],
              },
            },
          ]}
          style={{ zIndex: 1300 }}  // Ensure popper is above other elements
        >
          <Box ref={popperRef} sx={{
            background: "white",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            width: '82px',
            maxHeight: '180px',
            padding: '2px',
            overflowY: 'auto',
            textAlign: "right",
          }}>
            {Array.from({ length: 48 }).map((_, index) => {
              const hour = Math.floor(index / 2);
              const minute = index % 2 === 0 ? '00' : '30';
              const time = `${String(hour).padStart(2, '0')}:${minute}`;
              return (
                <Button key={index} onClick={(e) => handleTimeSelect(e, time)} sx={{ padding: "2px 4px 2px 4px", minWidth: "48px", fontSize: "13px", color: "rgba(0, 0, 0, 0.67)", }}>
                  {time}
                </Button>
              );
            })}
          </Box>
        </Popper>
      </>
    </ClickAwayListener>
  );
};

export default DurationEditor;
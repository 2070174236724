import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import Pagination from "../../common/Pagination";
import Table from "./Table";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "scroll",
    },
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    display: "block",
    color: theme.palette.primary.main,
    overflowX: "scroll",
  },
  btnPrint: {
    padding: "4px 15px",
    fontSize: 12,
    marginTop: 20,
    fontWeight: 600,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  myOrderTableContainer: {
    marginTop: 20,
    marginBottom: 30,
  },
}));

const HEADER_NAMES = {
  SUPPLIERORDERID: "supplierOrderId",
  ORDERNR: "orderId",
  ORDERDATUM: "orderDate",
  ANVÄNDARE: "username",
  FÖRETAGSNAMN: "deliveryAddress1",
  GATUADRESS: "deliveryAddress2",
  MOTTAGARE: "deliveryReference",
  POSTORT: "deliveryCity",
  PRIS_INK_MOMS: "sumInclVat",
  ÖNSKAT_LEVERANSDATUM: "deliveryDateWish",
  KOSTNADSSTÄLLE: "costPlaceOrder",
  ORDERSTATUS: "status",
};

const headers = [
  {
    headerName: HEADER_NAMES.SUPPLIERORDERID,
    label: <FormattedMessage id="orderHistory.orderNumber" />,
  },
  {
    headerName: HEADER_NAMES.ORDERNR,
    label: <FormattedMessage id="orderHistory.orderNumber" />,
  },
  {
    headerName: HEADER_NAMES.ORDERDATUM,
    label: <FormattedMessage id="orderHistory.orderDate" />,
  },
  {
    headerName: HEADER_NAMES.ANVÄNDARE,
    label: <FormattedMessage id="orderHistory.username" />,
  },
  {
    headerName: HEADER_NAMES.FÖRETAGSNAMN,
    label: <FormattedMessage id="orderHistory.address" />,
  },
  {
    headerName: HEADER_NAMES.GATUADRESS,
    label: <FormattedMessage id="orderHistory.section" />,
  },
  {
    headerName: HEADER_NAMES.MOTTAGARE,
    label: <FormattedMessage id="orderHistory.reference" />,
  },
  {
    headerName: HEADER_NAMES.POSTORT,
    label: <FormattedMessage id="orderHistory.city" />,
  },
  {
    headerName: HEADER_NAMES.PRIS_INK_MOMS,
    label: <FormattedMessage id="orderHistory.sumInclVat" />,
  },
  {
    headerName: HEADER_NAMES.ÖNSKAT_LEVERANSDATUM,
    label: <FormattedMessage id="orderHistory.earliestDeliveryDate" />,
  },
  {
    headerName: HEADER_NAMES.KOSTNADSSTÄLLE,
    label: <FormattedMessage id="orderHistory.costPlace" />,
  },
  {
    headerName: HEADER_NAMES.ORDERSTATUS,
    label: <FormattedMessage id="orderHistory.status" />,
  },
];

const IncomingOrdersTable = ({
  ascending,
  loading,
  orderGroupId,
  orderHistoryDetailSettings,
  orders,
  page,
  pageSize,
  setAscending,
  setPage,
  setPageSize,
  setSortField,
  sortField,
  totalCount,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
  handleMultiPrintOrder,
}) => {
  const classes = useStyles();

  const handlePageSizeChange = event => {
    setPageSize(Number(event.target.value));
    setPage(0);
  };

  const handleSetPage = (e, p) => {
    setPage(p);
  };

  const handleOnHeaderClick = headerName => {
    if (sortField === headerName) {
      setAscending(prevValue => !prevValue);
    } else {
      setSortField(headerName);
      setAscending(true);
    }
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.root}>
        <Box>
          <Pagination
            onChange={handlePageSizeChange}
            onPageChange={handleSetPage}
            page={page}
            perPage={pageSize}
            totalCount={totalCount}
          />
        </Box>
        <Table
          orderHistoryDetailSettings={orderHistoryDetailSettings}
          orders={orders}
          orderGroupId={orderGroupId}
          loading={loading}
          headers={headers}
          handleOnHeaderClick={handleOnHeaderClick}
          sortField={sortField}
          ascending={ascending}
          selectedOrders={selectedOrders}
          addSelectedOrder={addSelectedOrder}
          removeSelectedOrder={removeSelectedOrder}
        />
      </div>
      <Box align="right">
        <Button
          className={classes.btnPrint}
          onClick={() => handleMultiPrintOrder()}
        >
          <Icon className={clsx(["fa fa-print", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.print" />
          </span>
        </Button>
      </Box>
    </Paper>
  );
};

IncomingOrdersTable.defaultProps = {
  loading: false,
  orderGroupId: null,
  myOrderHistory: false,
  orderHistoryDetailSettings: null,
};

IncomingOrdersTable.propTypes = {
  myOrderHistory: PropTypes.bool,
  orderGroupId: PropTypes.number,
  loading: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  ascending: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setAscending: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setSortField: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      companyOrderno: PropTypes.string,
      costPlaceOrder: PropTypes.string,
      deliveryAddress1: PropTypes.string,
      deliveryAddress2: PropTypes.string,
      deliveryCity: PropTypes.string,
      deliveryDateWish: PropTypes.string,
      deliveryReference: PropTypes.string,
      orderDate: PropTypes.string,
      orderId: PropTypes.number.isRequired,
      status: PropTypes.string,
      sumInclVat: PropTypes.number,
      username: PropTypes.string,
    })
  ).isRequired,
  orderHistoryDetailSettings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
  selectedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
  handleMultiPrintOrder: PropTypes.func.isRequired,
};

export default IncomingOrdersTable;

import { gql } from '@apollo/client';

const getDetailedSearch = gql`
  query getDetailedSearch(
    $searchValuesInput: StandardSearchFieldValuesInput
    $siteId: Int!
    $searchType: SearchType!
    $tagSearchType: TagSearchType!
  ) {
    search {
      detailedSearch(
        searchFieldValues: $searchValuesInput
        siteId: $siteId
        searchType: $searchType
        tagSearchType: $tagSearchType
      ) {
        itemIds
        sites {
          folders {
            folderId
            folderName
          }
          siteId
          siteName
        }
        tags {
          tagId
          tagName
        }
        viewSelectorSettings {
          fileView
          productView
        }
      }
    }
  }
`;

export default getDetailedSearch;

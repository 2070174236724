import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";

import HelpUserModal from "../../components/SiteUsers/HelpUserModal";
import NewCompanyModal from "../../components/SiteUsers/HelpUserTable/NewCompanyModal";
import InviteUserModal from "../../components/SiteUsers/InviteUserModal";
import PageRoute from "../../constant/pageRouteDictionary.json";
import {
  CREATE_SITE_INVITATIONS,
  PREPARE_SITE_INVITATIONS,
} from "../../graphql/mutations";
import logger from "../../helpers/logger";
import { useSnackbar } from "../../hooks";
import { appcUrl } from "../../services/config";
import { ConfirmModal } from "../common";
import InviteShareModal from "./InviteUserModal/InviteShareModal";

const InviteUsersModals = ({
  getSiteUsers,
  open,
  onCloseDialog,
  selectedSite,
  setSiteSelect,
  siteRoleOptions,
  isShareModal,
  folder,
  folders
}) => {
  const alert = useAlert();
  const snackbar = useSnackbar();

  // Step 1 : Preparing invitations
  const [newCompanyModal, setNewCompanyModal] = useState(false);
  const [openInviteUser, setOpenInviteUser] = useState(false);
  const [shareDetails, setShareDetails] = useState();
  const [siteUserComment, setSiteUserComment] = useState("");
  const [siteUserEmails, setSiteUserEmails] = useState([]);
  const [siteUserRole, setSiteUserRole] = useState(null);
  const [
    prepareSiteInvitations,
    { loading: preparingInvitationLoad },
  ] = useMutation(PREPARE_SITE_INVITATIONS);

  // Step 2 : Creating invitations
  const [alreadyExistingUsers, setAlreadyExistingUsers] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [confirmMissingCompanySelectionModal, setConfirmMissingCompanySelectionModal] = useState(false);
  const [createSiteInvitations] = useMutation(CREATE_SITE_INVITATIONS);
  const [invitingCompanyId, setInvitingCompanyId] = useState();
  const [openHelpUser, setOpenHelpUser] = useState(false);
  const [preparedInvitations, setPreparedInvitations] = useState([]);

  const confirmCreateInvitations = async () => {
    await createInvitations({ invitingCompanyId });
    setConfirmMissingCompanySelectionModal(false);
  };

  const createInvitations = async ({ invitingCompanyId }) => {
    try {
      const input = {
        clientInvitationHandlerUrl: `${appcUrl}${PageRoute.CREATE_ACCOUNT}`,
        comment: siteUserComment,
        invitingCompanyId,
        isShareInvite: isShareModal || false,
        shareDetails,
        siteId: selectedSite,
        userDataItems: createUserDataItems(),
      };

      const res = await createSiteInvitations({ variables: { input } });

      if (res && res.data && res.data.createSiteInvitations) {
        alert.success(<FormattedMessage id="siteUsers.invitationsSent" />);
        handleCloseHelpUser();
        if (!isShareModal) {
          getSiteUsers();
        }
      }
      else {
        snackbar.workspaceError(<FormattedMessage id="common.errors.genericError" />);
      }
    } catch (err) {
      snackbar.workspaceError(<FormattedMessage id="common.errors.genericError" />);
      const logMessage = isShareModal
        ? "Error creating share invitations"
        : "Error creating invitations";
      logger.error(`** ${logMessage}:`, err);
    }
  };

  const createUserDataItems = () => {
    let userDataItems = [];

    if (Array.isArray(preparedInvitations) && preparedInvitations.length > 0) {
      preparedInvitations.forEach(x => {
        userDataItems = [
          ...userDataItems,
          {
            email: x.email,
            paymentLiable: x.paymentLiable,
            publicCompanyId: x.publicCompanyId,
            companyName: x.companyName,
            siteRoleId: x.siteRoleId,
          },
        ];
      });
    }

    return userDataItems;
  };

  const handleCloseHelpUser = () => {
    setOpenHelpUser(false);
    setSiteUserComment("");
    onCloseDialog();
  };

  const handleCloseInviteUser = () => {
    setOpenInviteUser(false);
    setSiteUserEmails([]);
    setSiteUserRole(null);
    onCloseDialog();
  };

  const handleCreateInvitations = async ({ invitingCompanyId }) => {
    let temp = [];
    let haveMissingCompanySelection = false;

    if (Array.isArray(preparedInvitations) && preparedInvitations.length > 0) {
      preparedInvitations.forEach(x => {
        const companyNotSelected = !validateCompanySelection(x);
        haveMissingCompanySelection |= companyNotSelected;
        temp = [...temp, { ...x, companyNotSelected }];
      });
    }

    if (haveMissingCompanySelection) {
      setConfirmMissingCompanySelectionModal(true);
      setInvitingCompanyId(invitingCompanyId);
      setPreparedInvitations(temp);
    }
    else {
      await createInvitations({ invitingCompanyId });
    }
  };

  const handleOpenHelpUser = (preparedShareDetails) => {
    setShareDetails(preparedShareDetails);
    handlePrepareSiteInvitations();
  };

  const handleOpenInviteUser = () => {
    if (selectedSite) {
      setOpenInviteUser(true);
      setPreparedInvitations([]);
    } else {
      setSiteSelect(true);
    }
  };

  const handlePrepareSiteInvitations = async () => {
    try {
      const input = {
        comment: siteUserComment,
        emailAddresses: siteUserEmails.join(),
        isShareInvite: isShareModal || false,
        siteId: selectedSite,
        siteRoleId: isShareModal ? 0 : siteUserRole,
      };

      const res = await prepareSiteInvitations({ variables: { input } });

      if (res && res.data && res.data.prepareSiteInvitations) {
        const {
          userDataItems,
          companies,
          alreadyExistingUsers,
        } = res.data.prepareSiteInvitations;
        setCompanyList(companies);
        // commented out for the moment
        // setPreparedInvitations(userDataItems);
        setAlreadyExistingUsers(alreadyExistingUsers);

        // Redundant logic , will talk to daniel about backend on userdata
        if (Array.isArray(userDataItems) && userDataItems.length > 0) {
          const tempInvitations = userDataItems.map(x => {
            if (!x.knownUser) {
              return {
                ...x,
                companyName: null,
                selectedCompanyId: null,
                siteRoleId: siteUserRole,
              };
            }
            return { ...x, siteRoleId: siteUserRole };
          });

          setPreparedInvitations(tempInvitations);
        }

        setOpenHelpUser(true);
        setOpenInviteUser(false);
      }
      else {
        snackbar.workspaceError(<FormattedMessage id="common.errors.genericError" />);
      }
    } catch (err) {
      snackbar.workspaceError(<FormattedMessage id="common.errors.genericError" />);
      const logMessage = isShareModal
        ? "Error preparing share invitations"
        : "Error preparing invitations";
      logger.error(`** ${logMessage}:`, err);
    }
  };

  const validateCompanyId = (value) => /^\d{3}-\d{3}-\d{3}$/.test(value);

  const validateCompanySelection = (invitation) => {
    const { companyName, publicCompanyId } = invitation;
    return (!!companyName && companyName.length > 0) || (validateCompanyId(publicCompanyId) && publicCompanyId !== "000-000-000");
  };

  useEffect(() => {
    if (open) {
      handleOpenInviteUser();
    } else {
      handleCloseInviteUser();
    }
  }, [open]);

  return (
    <>
      {openInviteUser && !isShareModal && (
        <InviteUserModal
          handleClose={handleCloseInviteUser}
          handleOpenHelpUser={handleOpenHelpUser}
          open={openInviteUser}
          setSiteUserComment={setSiteUserComment}
          setSiteUserEmails={setSiteUserEmails}
          setSiteUserRole={setSiteUserRole}
          siteRoleOptions={siteRoleOptions}
          siteUserComment={siteUserComment}
          siteUserEmails={siteUserEmails}
          siteUserRole={siteUserRole}
        />
      )}

      {openInviteUser && isShareModal && (
        <InviteShareModal
          folder={folder}
          folders={folders}
          handleClose={handleCloseInviteUser}
          handleOpenHelpUser={handleOpenHelpUser}
          initialView={PageRoute.FILE_VIEW}
          open={openInviteUser}
          setSiteUserComment={setSiteUserComment}
          setSiteUserEmails={setSiteUserEmails}
          siteId={selectedSite}
          siteRoleOptions={siteRoleOptions}
          siteUserComment={siteUserComment}
          siteUserEmails={siteUserEmails}
        />
      )}

      {openHelpUser && (
        <HelpUserModal
          alreadyExistingUsers={alreadyExistingUsers}
          companyList={companyList}
          handleCloseHelpUser={handleCloseHelpUser}
          handleCreateInvitations={handleCreateInvitations}
          loading={preparingInvitationLoad}
          open={openHelpUser}
          preparedInvitations={preparedInvitations}
          setNewCompanyModal={setNewCompanyModal}
          setOpenHelpUser={setOpenHelpUser}
          setPreparedInvitations={setPreparedInvitations}
          siteRoleOptions={siteRoleOptions}
          siteUserRole={siteUserRole}
        />
      )}

      {newCompanyModal && (
        <NewCompanyModal
          companyList={companyList}
          open={newCompanyModal}
          setCompanyList={setCompanyList}
          setNewCompanyModal={setNewCompanyModal}
        />
      )}

      {confirmMissingCompanySelectionModal && (
        <ConfirmModal
          messageText={<FormattedMessage id="siteUsers.confirmMissingCompanySelection" />}
          onCancel={() => setConfirmMissingCompanySelectionModal(false)}
          onConfirm={confirmCreateInvitations}
          open={confirmMissingCompanySelectionModal}
          titleText={<FormattedMessage id="siteUsers.helpYourInvitedColleague" />}
        />
      )}
    </>
  );
};

export default InviteUsersModals;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";

const RenderLockFolder = ({
  canLock,
  handleDisabled,
  handleLockFolder,
  locked,
}) => {
  const showText = locked ? "context.unlockFolder" : "context.lockFolder";
  const disabled = !canLock;
  return (
    <ContextMenuItem
      disabled={disabled}
      onClick={() => (disabled ? handleDisabled() : handleLockFolder())}
    >
      <FormattedMessage id={showText} />
    </ContextMenuItem>
  );
};

RenderLockFolder.propTypes = {
  canLock: PropTypes.bool.isRequired,
  handleLockFolder: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
};

export default RenderLockFolder;

import React from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AdminSidebar from "../../../components/Administration/AdminSidebar";
import Sales from "../../../components/Administration/Sales";
import { SALES_PAGE_TYPE } from "../../../constant/salesPageTypes";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#f3f3f3",
    marginTop: "0px",
  },
}));

const negMargin = { marginTop: "-8px" };

export default function SalesQuoteRequestsPage() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <AdminSidebar />
        </Grid>
        <Grid item xs={12} md={10} sx={negMargin}>
          <Sales pageType={SALES_PAGE_TYPE.quoteRequests} />
        </Grid>
      </Grid>
    </Box>
  );
}

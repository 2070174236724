import { useMutation } from "@apollo/client";
import { UPDATE_METADATA } from "../graphql/mutations";

// eslint-disable-next-line import/prefer-default-export
export const useUpdateMetadata = () => {
  const [updateMetadata] = useMutation(UPDATE_METADATA);

  const handleUpdateMetadata = ({ metadata }) => {
    return updateMetadata({
      variables: { UpdateMetadataInputType: metadata },
    });
  };

  return { execute: handleUpdateMetadata };
};

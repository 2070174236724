import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import CompanyLogoUpload from "./CompanyLogoUpload";
import CompanyMailLogoUpload from "./CompanyMailLogoUpload";
import GenericAdminPageSkeleton from "../../../Skeleton/GenericAdminPageSkeleton";

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import getCompanyLogoUrl from "../../../../helpers/getCompanyLogoUrl";
import getCompanyMailLogoUrl from "../../../../helpers/getCompanyMailLogoUrl";
//TODO: Use "CompanyLogo" and "CompanyMailLogo" instead?

import CompanyTemplates from "../../CompanyTemplates/index";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  actionButtons: {
    justifyContent: "space-between",
    "& > div": {
      border: "none",
      width: "unset",
      marginTop: "-2px",
      padding: "0px",
      "& button": {
        height: "26px",
      },
    },
    "& :only-child": {
      marginLeft: "auto",
    },
  },
  btnDesign: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headMarginBottom: {
    marginBottom: "10px",
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #F2F2F2",
    marginBottom: 15,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  textItalic: {
    fontSize: 12,
    fontStyle: "italic",
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  greenIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  selectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    "& .MuiFormControl-root": {
      width: "60%",
    },
  },
  logo: {
    background: theme.palette.common.white,
    borderColor: theme.palette.common.lightGrey,
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 1,
    marginBottom: 10,
    maxWidth: 160,
    padding: 3,
    "&:hover": {
      animation: `$bgColorChange 1s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  logoCard: {
    background: theme.palette.secondary.main,
    height: 140,
    padding: "10px",
    "&:hover": {
      animation: `$bgColorChange 1.2s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  logoMail: {
    background: theme.palette.common.white,
    height: 140,
    padding: "10px",
    "&:hover": {
      animation: `$bgColorChangeMail 1.2s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  selectCard: {
    height: 100,
    padding: "10px",
  },
  "@keyframes bgColorChange": {
    "0%": {
      backgroundColor: theme.palette.secondary.main,
    },
    "50%": {
      backgroundColor: theme.palette.common.black,
    },
    "100%": {
      backgroundColor: theme.palette.common.black,
    },
  },
  "@keyframes bgColorChangeMail": {
    "0%": {
      backgroundColor: theme.palette.common.white,
    },
    "50%": {
      backgroundColor: theme.palette.common.black,
    },
    "100%": {
      backgroundColor: theme.palette.common.black,
    },
  },
}));

const Appearance = () => {
  const classes = useStyles();
  const intl = useIntl();

  const { companyId: _targetCompanyId } = useSelector(
    state => state.api.companyAdmin.company
  );

  const [companyLogoTime, setCompanyLogoTime] = useState(Date.now());
  const [companyMailLogoTime, setCompanyMailLogoTime] = useState(Date.now());

  const handleCompanyLogoUploaded = () => {
    setCompanyLogoTime(Date.now());
  };

  const handleCompanyMailLogoUploaded = () => {
    setCompanyMailLogoTime(Date.now());
  };

  const CompanyLogoPlatformCard = () => {

    if (!_targetCompanyId) {
      return <GenericAdminPageSkeleton />;
    }

    return (
      <>
        <Box className={classes.headMarginBottom} xs={2}>
          <Typography className={classes.headTitle}><FormattedMessage id="admin.companySettings.companyLogoPlatform.Title" /></Typography>
          {/*  <Typography className={classes.headSubTitle}>SubHeader here</Typography>*/}
        </Box>
        <Card>
          <CardMedia
            sx={{ objectFit: "contain" }}
            component="img"
            className={classes.logoCard}
            image={getCompanyLogoUrl(_targetCompanyId, companyLogoTime)}
            title={intl.formatMessage({
              id: "admin.companySettings.companyLogoPlatform.Title",
            })}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 20 }}>
              <FormattedMessage id="admin.companySettings.companyLogoPlatform.Title" />
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
              <FormattedMessage id="admin.companySettings.companyLogoPlatform.Description" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="admin.companySettings.companyLogoPlatform.Clarification" />
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage id="admin.companySettings.companyLogoPlatform.Warning" />
            </Typography>
          </CardContent>
          <Divider />
          <CardActions sx={{ width: '100%', padding: "16px" }} className={classes.actionButtons}>
            <CompanyLogoUpload
              companyId={_targetCompanyId}
              onUploaded={handleCompanyLogoUploaded}
            />
          </CardActions>
        </Card>
      </>
    );
  };

  const CompanyMailLogoCard = () => {

    if (!_targetCompanyId) {
      return <GenericAdminPageSkeleton />;
    }

    return (
      <>
        <Box className={classes.headMarginBottom} xs={2}>
          <Typography className={classes.headTitle}><FormattedMessage id="admin.companySettings.companyLogoMail.Title" /></Typography>
          {/*  <Typography className={classes.headSubTitle}>SubHeader here</Typography>*/}
        </Box>
        <Card>
          <CardMedia
            sx={{ objectFit: "contain" }}
            component="img"
            className={classes.logoMail}
            image={getCompanyMailLogoUrl(_targetCompanyId, companyLogoTime)}
            title={intl.formatMessage({
              id: "admin.companySettings.companyLogoMail.Title",
            })}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 20 }}>
              <FormattedMessage id="admin.companySettings.companyLogoMail.Title" />
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
              <FormattedMessage id="admin.companySettings.companyLogoMail.Description" />
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="admin.companySettings.companyLogoMail.Clarification" />
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage id="admin.companySettings.companyLogoMail.Warning" />
            </Typography>
          </CardContent>
          <Divider />
          <CardActions sx={{ width: '100%', padding: "16px" }} className={classes.actionButtons}>
            <CompanyMailLogoUpload
              companyId={_targetCompanyId}
              onUploaded={handleCompanyMailLogoUploaded}
            />
          </CardActions>
        </Card>
      </>
    );
  };

  const CompanyColorTheme = () => {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    if (!_targetCompanyId) {
      return <GenericAdminPageSkeleton />;
    }

    return (
      <>
        <Box className={classes.headMarginBottom}>
          <Typography className={classes.headTitle}><FormattedMessage id="admin.companySettings.colorTheme.Title" /></Typography>
          {/*<Typography className={classes.headSubTitle}><FormattedMessage id="admin.companySettings.colorTheme.subTitle" /></Typography>*/}
        </Box>
        <Card>
          <CardContent className={classes.selectCard}>
            <Box sx={{ minWidth: 120, paddingTop:"25px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"><FormattedMessage id="admin.companySettings.colorTheme.selectColorTheme" /></InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Välj färgtema"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>UniQueue</MenuItem>
                  <MenuItem value={20}>Blue theme</MenuItem>
                  <MenuItem value={30}>Kommunal theme</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/*<Typography variant="body2" color="text.secondary" align="right">*/}
            {/*  Avancerade inställningar »*/}
            {/*</Typography>*/}
          </CardContent>
          <CardContent sx={{paddingBottom:"12px"}}>
            <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 20 }}>
              <FormattedMessage id="admin.companySettings.colorTheme.Title" />
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
              <FormattedMessage id="admin.companySettings.colorTheme.subTitle" />
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
              <FormattedMessage id="admin.companySettings.colorTheme.warning" />
            </Typography>
          </CardContent>
          <Divider />
          <CardActions sx={{ width: '100%', padding: "16px" }} className={classes.actionButtons}>
            <Button size="small" variant="outlined" className={classes.btnDesign} startIcon={<SaveIcon />}>Spara</Button>
          </CardActions>
        </Card>
      </>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={4} xs={12}>
        <CompanyLogoPlatformCard />
      </Grid>
      <Grid item md={4} xs={12}>
        <CompanyMailLogoCard />
      </Grid>
      <Box width="100%" />
      <Grid item md={4} xs={12}>
        <CompanyColorTheme />
      </Grid>
      <Grid item md={4} xs={12}>
        <CompanyTemplates />
      </Grid>
    </Grid>
  );
};

export default Appearance;
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Switch from "@mui/material/Switch";
import clsx from "clsx";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TreeView from "../common/JSTree";
import WorkspaceCreateModal from "../common/WorkspaceCreateModal";

const useStyles = makeStyles(theme => ({
  panel: {
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "&:before": {
      content: "none",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "unset",
      flexDirection: "row-reverse",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      alignItems: "center",
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0,
      marginRight: 15,
    },
    "& .MuiAccordionDetails-root": {
      padding: "10px 0",
    },
  },
  panelHead: {
    backgroundColor: theme.palette.background.lightGray,
    marginBottom: 5,
    padding: "5px 10px",
    borderRadius: 4,
  },
  action: {
    padding: 5,
    marginRight: "0 !important",
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconEdit: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  actionWrap: {
    marginLeft: "auto",
  },
  headingShared: {
    fontSize: 12,
    textTransform: "uppercase",
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SharedWorkspaceItem = () => {
  const classes = useStyles();

  const dataTree = {
    core: {
      data: [
        { text: "Projects", icon: "fa fa-briefcase" },
        { text: "Issues", icon: "fa fa-tasks" },
        {
          text: "Folder",
          icon: "fas fa-folder",
          children: [
            { text: "Folder 1", icon: "fas fa-folder" },
            { text: "Folder 2", icon: "fas fa-folder" },
          ],
        },
      ],
      check_callback: true,
      themes: {
        dots: false,
      },
    },
  };

  const [openWorkspaceCreate, setOpenWorkspaceCreate] = useState(false);
  const [panels, setPanels] = useState([
    { id: 1, name: "Norrevos arbetsytor" },
    { id: 2, name: "Norr Arbetsytor" },
  ]);
  const handleOpenWorkspaceCreate = () => {
    setOpenWorkspaceCreate(true);
  };
  const handleCloseWorkspaceCreate = () => {
    setOpenWorkspaceCreate(false);
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedPanels = reorder(
      panels,
      result.source.index,
      result.destination.index
    );
    setPanels(orderedPanels);
  }

  const renderNorrevosArbetsytor = index => (
    <Draggable draggableId="NorrevosArbetsytor" index={index}>
      {provided => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.panel}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.panelHead}
          >
            <Typography className={classes.headingShared}>
              <FormattedMessage id="workspace.norrevos" />
            </Typography>
            <Box className={classes.actionWrap}>
              <Switch
                color="primary"
                size="small"
                onClick={event => event.stopPropagation()}
                onFocus={event => event.stopPropagation()}
              />
              <IconButton
                className={classes.action}
                onClick={handleOpenWorkspaceCreate}
              >
                <Icon className={clsx(["fa fa-edit", classes.iconEdit])} />
              </IconButton>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <TreeView treeData={dataTree} />
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );

  const renderNorrArbetsytor = index => (
    <Draggable draggableId="NorrArbetsytor" index={index}>
      {provided => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classes.panel}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.panelHead}
          >
            <Typography className={classes.headingShared}>
              Norr Arbetsytor
            </Typography>
            <Box className={classes.actionWrap}>
              <Switch
                color="primary"
                size="small"
                onClick={event => event.stopPropagation()}
                onFocus={event => event.stopPropagation()}
              />
              <IconButton
                className={classes.action}
                onClick={handleOpenWorkspaceCreate}
              >
                <Icon className={clsx(["fa fa-edit", classes.iconEdit])} />
              </IconButton>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <TreeView treeData={dataTree} />
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {panels.map((x, index) => {
                if (x.id === 1) {
                  return (
                    <React.Fragment key={x.id}>
                      {renderNorrevosArbetsytor(index)}
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={x.id}>
                    {renderNorrArbetsytor(index)}
                  </React.Fragment>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <WorkspaceCreateModal
        open={openWorkspaceCreate}
        handleClose={handleCloseWorkspaceCreate}
      />
    </Box>
  );
};

export default SharedWorkspaceItem;

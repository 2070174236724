import { gql } from '@apollo/client';
import FolderFieldsFragment from "../fragments/FolderFields";
import FolderRecursiveFragment from "../fragments/FolderRecursive";
import SiteRoleRightsFragment from "../fragments/SiteRoleRights";

const getSiteRole = gql`
  query getSiteRole($siteRoleId: Int!) {
    siteRoles {
      getSiteRole(siteRoleId: $siteRoleId) {
        folders {
          ...FolderFields
          ...FolderRecursive
        }
        name
        siteRoleId
        siteRoleRights {
          ...SiteRoleRightsFields
        }
        selectedSiteFolderColorSet {
          defaultColor
          name
          siteColorSetId
        }
        availableSiteFolderColorSets {
          defaultColor
          name
          siteColorSetId
        }
        siteRoleSettings {
          categoryTreeIndentation
        }
        subscriptionLevel
      }
    }
  }
  ${SiteRoleRightsFragment}
  ${FolderFieldsFragment}
  ${FolderRecursiveFragment}
`;

export default getSiteRole;

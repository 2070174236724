import { gql } from '@apollo/client';

const getRFPUploadToken = gql`
  query {
    requestForProposal {
      requestUploadToken
    }
  }
`;

export default getRFPUploadToken;

import getChildFoldersTreeData from "./getChildFoldersTreeData";

const getFolderTreeDataStructure = ({ folders }) => {
  let folderTree = {};
  if (folders && Array.isArray(folders)) {
    folders.forEach(folder => {
      const childIds = folder.childFolders.map(child => child.folderId);

      let childFoldersTreeData = {};

      folder.childFolders.forEach(childFolder => {
        const childFolderTreeData = getChildFoldersTreeData({
          folder: childFolder,
        });
        childFoldersTreeData = {
          ...childFoldersTreeData,
          ...childFolderTreeData,
        };
      });

      folderTree = {
        ...folderTree,
        ...childFoldersTreeData,
        [`f_${folder.folderId}`]: {
          ...folder,
          isRoot: true,
          childFolderIds: childIds,
        },
      };
    });
  }

  return folderTree;
};

export default getFolderTreeDataStructure;

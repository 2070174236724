import { gql } from '@apollo/client';

const GET_UNSUBSCRIBEINFO = gql`
  query unsubscribeinfo($savedNotificationId: Int!) {
    myNotificationContext {
      getSavedNotificationInfo(savedNotficationId: $savedNotificationId) {
        allowed
        name
        siteId
        siteName
      }
    }
  }
`;

export default GET_UNSUBSCRIBEINFO;

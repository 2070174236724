import { createContext, useRef, useState } from "react";

export const projectDnDContext = createContext();

const { Provider } = projectDnDContext;

const ProjectDnDContextProvider = ({ children }) => {

  const activeRef = useRef();

  const [isDragging, setIsDragging] = useState();

  const toggleIsDragging =
    (state) => setIsDragging(
      typeof state === "boolean"
        ? state
        : (prev) => !prev
    );

  const updateRowClasses = (ref = undefined, pos = "") => {
    if (ref) {
      if (activeRef.current !== ref.current) {
        if (activeRef.current) {
          toggleRowClasses(activeRef);
        }
        activeRef.current = ref.current;
      }
      toggleRowClasses(ref, pos);
    }
    else {
      toggleRowClasses(activeRef);
    }
  };

  const toggleRowClasses = (ref, pos = "") => {
    const classList = ref.current?.closest("tr")?.classList;

    if (classList) {
      const activeClass = `drop-${pos}`;

      classList.toggle("drop-subtask", activeClass === "drop-subtask");
      classList.toggle("drop-after", activeClass === "drop-after");
      classList.toggle("drop-before", activeClass === "drop-before");
    }
  };

  return (
    <Provider value={{
      isDragging,
      toggleIsDragging,
      updateRowClasses,
    }}>
      {children}
    </Provider>
  );
};

export default ProjectDnDContextProvider;

import { gql } from '@apollo/client';
import FolderFieldsFragment from "../fragments/FolderFields";
import FolderRecursiveFragment from "../fragments/FolderRecursive";

const getFolders = gql`
  query GetFolders($siteId: Int!) {
    folders(siteId: $siteId) {
      ...FolderFields
      ...FolderRecursive
    }
  }
  ${FolderFieldsFragment}
  ${FolderRecursiveFragment}
`;

export default getFolders;

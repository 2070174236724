import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  skeletonWrap: {
    backgroundColor: "#fff",
    padding: "7px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  skeleton: {
    borderRadius: 4,
  },
  skeletonText: {
    borderRadius: 4,
    marginBottom: 10,
  },
  modalBody: {
    padding: "30px 20px 20px 20px",
  },
  spacer: {
    marginTop: 8,
    marginBottom: 8,
  },
}));

const OrderModalSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.modalBody}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Skeleton
            variant="rect"
            width="30%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton
            variant="rect"
            width="30%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.spacer} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Skeleton
            variant="rect"
            width="30%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="15%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="20%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="25%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="18%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="17%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="5%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="12%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="3%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="15%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
          <Skeleton
            variant="rect"
            width="20%"
            height={10}
            animation="wave"
            classes={{ root: classes.skeletonText }}
          />
        </Grid>
      </Grid>
      <Box className={classes.spacer} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Skeleton
            variant="rect"
            width="100%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Skeleton
            variant="rect"
            width="30%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.skeletonWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={10}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderModalSkeleton;

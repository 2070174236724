import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    backgroundColor: theme.palette.common.white,
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
  },
  btn: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}));

const MSOfficeModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        <Typography className={classes.title}>
          Bilden anpassas för MS Office eller liknande.
        </Typography>
        <Typography>
          Upplösning 72 dpi/ppi, maxbredden 800 px, maxhöjd 600 px, färgläge,
          filformat JPEG.
        </Typography>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btn} onClick={handleClose}>
            <FormattedMessage id="btn.close" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

MSOfficeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MSOfficeModal;

import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 2,
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    marginBottom: 20,
  },
  headRoot: {
    padding: 10,
    backgroundColor: theme.palette.component.customPanelBackground,
  },
  headTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.component.customPanelColor,
  },
}));

const CardDefault = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.root }} elevation={0}>
      <CardHeader
        title={title}
        classes={{ root: classes.headRoot, title: classes.headTitle }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardDefault;

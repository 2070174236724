import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ClearButton, PrimaryButton } from "../Buttons";
import WarningImage from "../../../assets/img/warning-icon.svg";
import DraggableModal from "../DraggableModal";
import DraggableModalHead from "../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  img: {
    marginBottom: 10,
    width: 40,
  },
  bodyContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
}));

const ConfirmDeleteModal = ({
  open,
  setOpen,
  onConfirmCloseModal,
  loading,

}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={() => setOpen(false)}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={() => setOpen(false)} />
      <DraggableModalBody>
        <Box className={classes.bodyContainer}>
          <img src={WarningImage} alt="Warning" className={classes.img} />
          <FormattedMessage id="context.deleteConfirmation" />
        </Box>
        <Box className={classes.modalFooter} align="right">
          <ClearButton
            onClick={() => setOpen(false)}
            marginRight={15}
            disabled={loading}
          >
            <FormattedMessage id="btn.no" />
          </ClearButton>
          <PrimaryButton
            disabled={loading}
            onClick={() => {
              onConfirmCloseModal();
            }}
          >
            {loading ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              <FormattedMessage id="btn.yes" />
            )}
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmDeleteModal.defaultProps = {
  loading: false,
};

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirmCloseModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ConfirmDeleteModal;

import UQ_THEME from "./uq_theme";
import KOMMUNAL_THEME from "./kommunal_theme";
import LINK22_THEME from "./link22_theme";
import REJMES_THEME from "./rejmes_theme";
import LIFT_MACHINE_THEME from "./lift_machine_theme";
import CUSTOM_THEME from "./custom_theme";

const theme = themeName => {
  
  if (themeName === "UQ") {
    return UQ_THEME;
  }

  if (themeName === "Kommunal") {
    return KOMMUNAL_THEME;
  }

  if (themeName === "Link22") {
    return LINK22_THEME;
  }

  if (themeName === "Blue") {
    return REJMES_THEME;
  }

  if (themeName === "Orange") {
    return LIFT_MACHINE_THEME;
  }

  if (themeName === "custom") {
    return CUSTOM_THEME();
  }

  return UQ_THEME;
};

export default theme;

/*
main #CE122D "Kommunal red"(Primary red color)
light #FAD1D6 "Pink"
dark #662336 "Wine-red"
#F6689B "Cerise"
Secondary colors
#D6F2EF "Light blue"
#88d1d2 "Blue"
#FFFCE5 "Light yellow"
#F1C04F "Lion yellow"
Complementart colors(use sparingly)
#F3F1F0
#F9D0D6
#FCF1F3
#D51130
#8BCDD0
*/

import { handleActions } from "redux-actions";
import {
  setRightColumnVisibility,
  setFileviewVisibleField,
  setFileviewVisibleFields,
} from "../../../actions";
import { FILEVIEW_RIGHT_COLUMN } from "../../../actions/constants";

const { HIDE } = FILEVIEW_RIGHT_COLUMN;

const defaultState = {
  rightColumn: HIDE,
  fields: {
    nameColumn: true,
    descriptionColumn: true,
    uploadedColumn: true,
    downloadedColumn: true,
    editedColumn: true,
    versionColumn: true,
    typeColumn: true,
    sizeColumn: true,
  },
};

export default handleActions(
  {
    [setRightColumnVisibility]: (state, { payload }) => {
      const { value } = payload;
      return {
        ...state,
        rightColumn: value,
      };
    },
    [setFileviewVisibleField]: (state, { payload }) => {
      const { name, visible } = payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: visible,
        },
      };
    },
    [setFileviewVisibleFields]: (state, { payload }) => {
      const { fields } = payload;
      return {
        ...state,
        fields: {
          ...state.fields,
          ...fields,
        },
      };
    },
  },
  defaultState
);

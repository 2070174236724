import { createActions } from "redux-actions";

export const {
  setOneStreamJobs,
  setOneStreamJobsLoading,
  setOneStreamJobsError,
} = createActions({
  SET_ONE_STREAM_JOBS: ({ items }) => ({ items }),
  SET_ONE_STREAM_JOBS_LOADING: ({ loading }) => ({ loading }),
  SET_ONE_STREAM_JOBS_ERROR: ({ error }) => ({ error }),
});

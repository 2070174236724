import { gql } from '@apollo/client';

const getResources = gql`
  query GetResources(
    $includeLinkedResources: Boolean
    $folderId: Int!
    $sortfield: String
    $ascending: Boolean
    $offset: Int!
    $pageSize: Int!
    $columnFilter: FileViewColumnFilterInput
    $includeFileMetadata: Boolean
    $resourceId: ID
  ) {
    resources(
      includeLinkedResources: $includeLinkedResources
      folderId: $folderId
      sortfield: $sortfield
      ascending: $ascending
      offset: $offset
      pageSize: $pageSize
      columnFilter: $columnFilter
      includeFileMetadata: $includeFileMetadata
      resourceId: $resourceId
    ) {
      offset
      totalCount
      items {
        changedBy
        changedDate
        description
        downloadedBy
        downloadedDate
        fileExtension
        id
        locked
        isEditableFromSearchResult
        isActive
        isProduct
        stockBalance
        name
        productImageUri
        size
        type
        uploadedBy
        uploadedDate
        versionNo
        isLinkedResource
        imageMetadata {
          contact
          date
          imageNo
          isFreeToUser
          legalDocument
          location
          model
          occupation
          photographer
        }
        lockedByAnotherUser
        lockedByFolder
        lockedByUsername
        tags {
          tagId
          tagName
        }
        thumbnailImageUri
        fileMetaData {
          name
          subCategories {
            name
            values {
              name
              value
            }
          }
        }
      }
    }
  }
`;

export default getResources;

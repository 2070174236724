import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";
import FolderFieldsFragment from "../../fragments/FolderFields";
import FolderRecursiveFragment from "../../fragments/FolderRecursive";

const COPY_FOLDER = gql`
  mutation CopyFolder(
    $siteId: Int!
    $sourceFolderId: Int!
    $targetFolderId: Int!
  ) {
    folderContext {
      copyFolder(
        siteId: $siteId
        sourceFolderId: $sourceFolderId
        targetFolderId: $targetFolderId
      ) {
        success
        siteFolders {
          ...FolderFields
          ...FolderRecursive
        }
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${FolderRecursiveFragment}
  ${FolderFieldsFragment}
  ${ErrorResultFragment}
`;

export default COPY_FOLDER;

import { createActions } from "redux-actions";

export const {
  clearFileviewCopyParams,
  setRefetchFiles,
  setRightColumnVisibility,
  setFileviewVisibleField,
  setFileviewVisibleFields,
  setFileviewCopyParams,
  setFileViewOpenNodes,
  setSelectedResources,
} = createActions({
  CLEAR_FILEVIEW_COPY_PARAMS: () => ({}),
  SET_REFETCH_FILES: ({ refetch }) => ({ refetch }),
  SET_RIGHT_COLUMN_VISIBILITY: ({ value }) => ({ value }),
  SET_FILEVIEW_VISIBLE_FIELD: ({ name, visible }) => ({ name, visible }),
  SET_FILEVIEW_VISIBLE_FIELDS: ({ fields }) => ({ fields }),
  SET_FILEVIEW_COPY_PARAMS: ({ copyParams }) => ({ copyParams }),
  SET_FILE_VIEW_OPEN_NODES: ({ nodes }) => ({ nodes }),
  SET_SELECTED_RESOURCES: ({ items }) => ({ items }),
});

import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "@mui/material";
import PropTypes from "prop-types";

const SectionDetailsLink = ({ className, onClick }) => {
  return (
    <Link
      className={className}
      component="button"
      onClick={onClick}
      underline="hover"
    >
      <FormattedMessage id="admin.subscriptions.sectionDetailsLink.text" />
    </Link>
  );
};

SectionDetailsLink.defaultProps = {
  className: "",
};

SectionDetailsLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default SectionDetailsLink;

import React, { memo, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import LinesEllipsis from "react-lines-ellipsis";
import Close from "@mui/icons-material/Close";
import { Box, Card, CardMedia, IconButton, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { widgetList } from "../../../constant/widgets";

const useStyles = makeStyles(theme => ({
  closeAdornment: {
    padding: 0,
    paddingRight: "0.25em",
  },
  content: {
    flex: "1 0 auto",
    padding: "4px 4px 4px 16px",
    width: 100,
  },
  cover: {
    backgroundSize: "contain",
    height: 80,
    maxHeight: 80,
    width: 80,
  },
  details: {
    display: "flex",
    flex: 1,
  },
  root: {
    borderRadius: 0,
    boxShadow: "none",
    display: "flex",
    flex: 1,
    marginBottom: 20,
    "&[draggable=false]": {
      opacity: 0.8,
    },
    "&[draggable=true]": {
      cursor: "grab",
    },
    "&[draggable=true]:active": {
      cursor: "grabbing",
    },
    "&[draggable=true]:hover": {
      backgroundColor: theme.palette.background.lightGray,
    },
  },
  searchInput: {
    width: "100%",
  },
  toolboxSearch: {
    padding: "0px 20px 10px 20px",
    "& input": {
      paddingBottom: 8.5,
      paddingTop: 8.5,
    },
  },
  toolboxTitle: {
    fontSize: 16,
    fontWeight: 600,
    padding: "20px 20px 10px 20px",
  },
  widgetContent: {
    background: theme.palette.common.white,
    padding: "10px 20px 30px 20px",
  },
  widgetDesc: {
    fontSize: 12,
    lineHeight: "16px",
  },
  widgetImage: {
    border: `1px solid ${theme.palette.common.lightGrey}`,
  },
  widgetTitle: {
    fontSize: 16,
    lineHeight: "22px",
    paddingBottom: 0,
  },
}));

const handleDragStart = ({ e, widget }) => {
  e.dataTransfer.setData("siteWidget", JSON.stringify(widget));
};

const WidgetGallery = ({ intl }) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    let loweredSearchText = searchText.toLocaleLowerCase();

    let items = widgetList.map(item => ({ item, title: intl.formatMessage({ id: item.title }) }));
   
    if (searchText.length > 0) {
      items = items.filter(item => item.title.toLocaleLowerCase().includes(loweredSearchText));
    }
    
    items = items.sort((x, y) => x.title.localeCompare(y.title)).map(n => n.item);

    setFilteredList(items);
  }, [searchText, widgetList]);

  const WidgetCard = ({ widget }) => (
    <Card
      className={classes.root}
      draggable={widget.type !== "page-link"}
      key={widget.i}
      onDragStart={e => handleDragStart({ e, widget })}
      unselectable="on"
    >
      <Box className={classes.widgetImage}>
        <CardMedia className={classes.cover} image={widget.image} />
      </Box>
      <div className={classes.details}>
        <Box className={classes.content}>
          <LinesEllipsis
            basedOn="letters"
            className={classes.widgetTitle}
            ellipsis="..."
            maxLine={1}
            text={intl.formatMessage({ id: widget.title })}
            trimRight
          />
          <LinesEllipsis
            basedOn="letters"
            className={classes.widgetDesc}
            ellipsis="..."
            maxLine={3}
            text={intl.formatMessage({ id: widget.desc })}
            trimRight
          />
        </Box>
      </div>
    </Card>
  );

  const CloseAdornment = ({ onClose }) => (
    <InputAdornment position="end">
      <IconButton size="small" title={intl.formatMessage({ id: "common.clear" })} onClick={onClose}>
        <Close />
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <Box className={classes.toolboxTitle}>
        <FormattedMessage id="widget.addWidget" />
      </Box>
      <Box className={classes.toolboxSearch}>
        <TextField
          className={classes.searchInput}
          InputProps={{
            endAdornment: (
              searchText && <CloseAdornment onClose={() => setSearchText("") } />
            ),
            classes: {
              adornedEnd: classes.closeAdornment
            },
          }}
          label={intl.formatMessage({ id: "nav.search" })}
          onChange={e => setSearchText(e.target.value)}
          size="small"
          value={searchText}
          variant="outlined"
        />
      </Box>
      <Box className={classes.widgetContent}>
        {filteredList.map(widget => (
          <WidgetCard widget={widget} />
        ))}
      </Box>
    </>
  );
};

export default memo(WidgetGallery);

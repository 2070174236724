import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import { bindActionCreators } from "redux";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import CartSummary from "../../components/Checkout/CartSummary";
import PageTitle from "../../components/Checkout/PageTitle";
import CartTableContainer from "../../components/Checkout/CartTableContainer";
import Stepper from "../../components/Checkout/Stepper";
import DeliveryInfo from "../../components/Checkout/DeliveryInfo";
import { cartInitialize } from "../../actions";
import { GET_SHOPPING_CART_VIEW } from "../../graphql/queries";
import { getCart } from "../../graphql/queries/getCart";
import { SAVE_CART, CREATE_ORDER } from "../../graphql/mutations";
import {
  SHOPPING_CART_SETTINGS,
  INITIAL_ADDRESS,
} from "../../constant/initialValues";
import {
  addressFieldsValidator,
  objectShallowCompare,
} from "../../helpers/validators";
import { omitTypename } from "../../helpers/get";
import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";
import ConfirmDeleteModal from "../../components/common/ConfirmDeleteModal";

const useStyles = makeStyles(theme => ({
  checkoutPage: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  priceBox: {
    backgroundColor: "#fff",
  },
  priceBoxHead: {
    padding: theme.spacing(2),
    backgroundColor: "#f9f9f9",
  },
  priceBoxTitle: {
    fontWeight: 600,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.component.productBoxColor,
  },
  priceBoxQty: {
    fontSize: 12,
    fontStyle: "italic",
    marginLeft: 5,
    fontWeight: 400,
  },
  priceBoxBody: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}));

const Checkout = ({
  cartItems,
  oneStreamGroups,
  fileGroups,
  // eslint-disable-next-line no-shadow
  cartInitialize,
  siteId,
  history,
  orderGroupId,
}) => {
  const classes = useStyles();
  const [saveCart] = useMutation(SAVE_CART);
  const [createOrder] = useMutation(CREATE_ORDER);
  const alert = useAlert();
  const [addressOptions, setAddressOptions] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [checkoutFields, setCheckoutFields] = useState({});
  const [orderMessage, setOrderMessage] = useState("");

  const [costCenters, setCostCenters] = useState(["","",""])

  const updateFields = e => {
    if (e.target.name == "message") {
      setOrderMessage(e.target.value);
    }
    else if (e.target.name.startsWith("costcenter")) {
      const index = parseInt(e.target.name.slice(-1)) - 1;
      const updatedCostCenters = [...costCenters];
      updatedCostCenters[index] = e.target.value;
      setCostCenters(updatedCostCenters);
    }

  };

  const [selectedAddressField, setSelectedAddressField] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState(INITIAL_ADDRESS);
  const [postalAddress, setPostalAddress] = useState(INITIAL_ADDRESS);
  const [invoiceAddress, setInvoiceAddress] = useState(INITIAL_ADDRESS);
  const [page, setPage] = useState(1);
  const [deliveryAddressChanged, setDeliveryAddressChanged] = useState(false);
  const [shoppingCartSettings, setShoppingCartSettings] = useState(
    SHOPPING_CART_SETTINGS
  );

  const [deliveryAddressFieldsError, setDeliveryAddressFieldsError] = useState(
    {}
  );

  const [postalAddressFieldsError, setPostalAddressFieldsError] = useState({});

  const [invoiceAddressFieldsError, setInvoiceAddressFieldsError] = useState(
    {}
  );

  const [cartThankYouMessage, setCartThankYouMessage] = useState("");

  const { data } = useQuery(GET_SHOPPING_CART_VIEW, {
    variables: {
      siteId,
    },
  });

  const [fetchCart, { loading: loadingFetchCart }] = useLazyQuery(getCart, {
    onCompleted: cart => {
      const { shoppingCartContent } = cart || {};
      const { groups = [] } = shoppingCartContent || {};
      const orderGroup = groups.find(x => x.orderGroupId === orderGroupId);
      const { items = [] } = orderGroup || {};
      cartInitialize({
        items,
      });
    },
  });

  const handleChangeDeliveryAddress = e => {
    const { checked } = e.target;

    setDeliveryAddressChanged(checked);
    if (checked) {
      setDeliveryAddress({
        ...deliveryAddress,
        address1: null,
        address2: null,
        address3: null,
        city: null,
        contact: null,
        country: null,
        customerId: null,
        reference: null,
        zip: null,
      });
      setPostalAddress({
        ...postalAddress,
        address1: null,
        address2: null,
        address3: null,
        city: null,
        contact: null,
        country: null,
        customerId: null,
        reference: null,
        zip: null,
      });
    } else if (!checked && selectedAddressField && addresses) {
      const addressValue = addresses.find(
        x => x.label === selectedAddressField
      );

      const { deliveryAddress: delivery, postalAddress: postal } = addressValue;

      const deliveryAddressNoTypename = JSON.parse(
        JSON.stringify(delivery),
        omitTypename
      );

      const postalAddressNoTypename = JSON.parse(
        JSON.stringify(postal),
        omitTypename
      );

      if (deliveryAddressNoTypename) {
        setDeliveryAddress(deliveryAddressNoTypename);
      }
      if (postalAddressNoTypename) {
        setPostalAddress(postalAddressNoTypename);
      }
    }
  };

  useEffect(() => {
    if (deliveryAddress && selectedAddressField && addresses) {
      const addressValue = addresses.find(
        x => x.label === selectedAddressField
      );

      const { deliveryAddress: delivery } = addressValue;

      const deliveryAddressNoTypename = JSON.parse(
        JSON.stringify(delivery),
        omitTypename
      );

      const sameObject = objectShallowCompare(
        deliveryAddress,
        deliveryAddressNoTypename
      );
      if (sameObject === deliveryAddressChanged) {
        setDeliveryAddressChanged(!sameObject);
      }
    }
  }, [deliveryAddress, selectedAddressField, addresses]);

  useEffect(() => {
    if (
      data &&
      data.shoppingCartView &&
      data.shoppingCartView.shoppingCartAddresses &&
      data.shoppingCartView.shoppingCartAddresses.addressCombinations
    ) {
      const { shoppingCartView } = data;
      const {
        costPlace1Label,
        costPlace2Label,
        costPlace3Label,
        earliestDeliveryDate,
        showCostPlace1,
        showCostPlace2,
        showCostPlace3,
        showDesiredDeliveryDaySelector,
        showTermsAndConditionsCheckbox,
        thankYouMessage,
      } = shoppingCartView;

      const { shoppingCartAddresses } = shoppingCartView;
      const { addressCombinations } = shoppingCartAddresses;
      const dropDownOptions = addressCombinations.map(x => ({
        value: x.label,
        label: x.label,
      }));
      setCartThankYouMessage(thankYouMessage);
      setCheckoutFields({
        costPlace1Label,
        costPlace2Label,
        costPlace3Label,
        earliestDeliveryDate,
        showCostPlace1,
        showCostPlace2,
        showCostPlace3,
        showDesiredDeliveryDaySelector,
        showTermsAndConditionsCheckbox,
      });
      setAddresses(addressCombinations);
      setAddressOptions(dropDownOptions);
      if (dropDownOptions && dropDownOptions.length > 0) {
        setSelectedAddressField(dropDownOptions[0].value);
      }
    }
    if (data && data.shoppingCartSettings) {
      const shoppingCartSettingsNoTypename = JSON.parse(
        JSON.stringify(data.shoppingCartSettings),
        omitTypename
      );
      setShoppingCartSettings(shoppingCartSettingsNoTypename);
    }
  }, [data]);

  useEffect(() => {
    if (selectedAddressField && addresses) {
      const addressValue = addresses.find(
        x => x.label === selectedAddressField
      );

      const {
        deliveryAddress: delivery,
        postalAddress: postal,
        invoiceAddress: invoice,
      } = addressValue;

      const deliveryAddressNoTypename = JSON.parse(
        JSON.stringify(delivery),
        omitTypename
      );
      const invoiceAddressNoTypename = JSON.parse(
        JSON.stringify(postal),
        omitTypename
      );
      const postalAddressNoTypename = JSON.parse(
        JSON.stringify(invoice),
        omitTypename
      );
      if (deliveryAddressNoTypename) {
        setDeliveryAddress(deliveryAddressNoTypename);
      }
      if (invoiceAddressNoTypename) {
        setPostalAddress(invoiceAddressNoTypename);
      }
      if (postalAddressNoTypename) {
        setInvoiceAddress(postalAddressNoTypename);
      }
    }
  }, [selectedAddressField, addresses]);

  useEffect(() => {
    const deliveryFieldErrors = addressFieldsValidator({
      address: deliveryAddress,
      addressSetting: shoppingCartSettings.deliveryAddressSettings,
    });

    if (!isEmpty(deliveryFieldErrors)) {
      setDeliveryAddressFieldsError({ ...deliveryFieldErrors });
    } else {
      setDeliveryAddressFieldsError({});
    }
  }, [deliveryAddress, shoppingCartSettings.deliveryAddressSettings]);

  useEffect(() => {
    const postalFieldErrors = addressFieldsValidator({
      address: postalAddress,
      addressSetting: shoppingCartSettings.postalAddressSettings,
    });
    if (!isEmpty(postalFieldErrors)) {
      setPostalAddressFieldsError({ ...postalFieldErrors });
    } else {
      setPostalAddressFieldsError({});
    }
  }, [postalAddress, shoppingCartSettings.postalAddressSettings]);

  useEffect(() => {
    const invoiceFieldErrors = addressFieldsValidator({
      address: invoiceAddress,
      addressSetting: shoppingCartSettings.invoiceAddressSettings,
    });

    if (!isEmpty(invoiceFieldErrors)) {
      setInvoiceAddressFieldsError({ ...invoiceFieldErrors });
    } else {
      setInvoiceAddressFieldsError({});
    }
  }, [invoiceAddress, shoppingCartSettings.invoiceAddressSettings]);

  const handleAddressChange = event => {
    setSelectedAddressField(event.target.value);
  };

  const handleAddressFieldChange = ({ fieldName, fieldValue, addressType }) => {
    if (addressType === "deliveryAddress") {
      setDeliveryAddress({
        ...deliveryAddress,
        [fieldName]: fieldValue,
      });
    }
    if (addressType === "postalAddress") {
      setPostalAddress({
        ...postalAddress,
        [fieldName]: fieldValue,
      });
    }
    if (addressType === "invoiceAddress") {
      setInvoiceAddress({
        ...invoiceAddress,
        [fieldName]: fieldValue,
      });
    }
  };

  const handleSubmitOrder = async () => {
    const deliveryFieldErrors = addressFieldsValidator({
      address: deliveryAddress,
      addressSetting: shoppingCartSettings.deliveryAddressSettings,
    });

    const postalFieldErrors = addressFieldsValidator({
      address: postalAddress,
      addressSetting: shoppingCartSettings.postalAddressSettings,
    });

    const invoiceFieldErrors = addressFieldsValidator({
      address: invoiceAddress,
      addressSetting: shoppingCartSettings.invoiceAddressSettings,
    });

    if (
      !isEmpty(deliveryFieldErrors) ||
      !isEmpty(postalFieldErrors) ||
      !isEmpty(invoiceFieldErrors)
    ) {
      setDeliveryAddressFieldsError({ ...deliveryFieldErrors });
      setInvoiceAddressFieldsError({ ...invoiceFieldErrors });
      setPostalAddressFieldsError({ ...postalFieldErrors });
      alert.error(<FormattedMessage id="cart.orderFailure" />);
    } else {
      const itemsForOrder = cartItems.map(cartItem => {
        const tempValues = {
          productId: cartItem.productId,
          quantity: cartItem.quantity,
          dynamicTemplateData: cartItem.dynamicFieldValues || [],
          supplierId: cartItem.siteSupplierId || null,
          siteId: cartItem.siteId,
          bookingInterval: cartItem.bookingInterval,
          shoppingCartItemId: cartItem.shoppingCartItemId,
        };

        /*if (!cartItem.bookingInterval) {
          delete tempValues.supplierId;
        }*/

        return tempValues;
      });

      const newFileGroups = fileGroups.map(x => {
        const { items: groupItems, packageName, propertyProductData } = x;

        let newPropertyProductData = [];

        const newItems = groupItems.map(y => {
          if (
            Array.isArray(y.propertyProductData) &&
            y.propertyProductData.length > 0
          ) {
            newPropertyProductData = y.propertyProductData;
          } else {
            newPropertyProductData = propertyProductData;
          }

          return {
            dynamicTemplateData: y.dynamicFieldValues,
            productId: y.productId,
            propertyProductData: newPropertyProductData,
            quantity: y.quantity,
            siteId: y.siteId,
            supplierId: y.siteSupplierId,
            shoppingCartItemId: y.shoppingCartItemId,
          };
        });

        const newFileGroup = {
          packageName,
          items: newItems,
        };

        return newFileGroup;
      });
      const newOneStreamGroups = oneStreamGroups.map(x => {
        const { items: groupItems, packageName } = x;

        const newItems = groupItems.map(y => ({
          dynamicTemplateData: y.dynamicFieldValues,
          productId: y.productId,
          propertyProductData: y.propertyProductData,
          quantity: y.quantity,
          siteId: y.siteId,
          supplierId: y.siteSupplierId,
          shoppingCartItemId: y.shoppingCartItemId,
        }));

        const newFileGroup = {
          packageName,
          items: newItems,
        };

        return newFileGroup;
      });

      const deliveryAddressNoTypename = JSON.parse(
        JSON.stringify(deliveryAddress),
        omitTypename
      );
      const invoiceAddressNoTypename = JSON.parse(
        JSON.stringify(invoiceAddress),
        omitTypename
      );
      const postalAddressNoTypename = JSON.parse(
        JSON.stringify(postalAddress),
        omitTypename
      );

      const results = await createOrder({
        variables: {
          orderGroupId,
          items: itemsForOrder,
          deliveryAddress: deliveryAddressNoTypename,
          invoiceAddress: invoiceAddressNoTypename,
          postalAddress: postalAddressNoTypename,
          fileGroups: newFileGroups,
          oneStreamGroups: newOneStreamGroups,
          deliveryAddressChanged,
          message: orderMessage,
          costCenters: costCenters
        },
      });

      if (
        results.data.createOrder &&
        results.data.createOrder.orderNo &&
        results.data.createOrder.orderNo !== 0
      ) {
        cartInitialize({
          items: [],
        });
        fetchCart();
        history.push({
          pathname: "/order-confirmation",
          state: {
            orderNo: results.data.createOrder.orderNo,
            companyOrderNo: results.data.createOrder.companyOrderNo,
            cartThankYouMessage,
          },
        });
        alert.success(<FormattedMessage id="cart.orderSuccessful" />);
      } else {
        alert.error(<FormattedMessage id="cart.orderFailure" />);
      }
    }
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleConfirmationModalOpen = () => {
    setConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModal(false);
  };

  const handleRemoveAllProducts = async () => {
    const results = await saveCart({
      variables: {
        orderGroupId,
        items: [],
      },
    });
    if (results && results.data && results.data.saveShoppingCartContent) {
      cartInitialize({
        items: [],
        fileGroups: [],
        oneStreamGroups: [],
      });
      alert.success(<FormattedMessage id="product.successRemovedFromCart" />);
    } else {
      alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
    }
    handleConfirmationModalClose();
  };

  return (
    <div className={classes.checkoutPage}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <PageTitle />
        </Grid>
        <Grid item xs={12} md={6} align="right">
          <Stepper page={page} setPage={setPage} />
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={9}>
          {page === 1 && (
            <CartTableContainer
              handleConfirmationModalOpen={handleConfirmationModalOpen}
              cartItems={cartItems}
              fetchCart={fetchCart}
              loadingFetchCart={loadingFetchCart}
              fileGroups={fileGroups}
              oneStreamGroups={oneStreamGroups}
            />
          )}
          {page === 2 && (
            <DeliveryInfo
              loadingFetchCart={loadingFetchCart}
              handleChangeDeliveryAddress={handleChangeDeliveryAddress}
              deliveryAddressChanged={deliveryAddressChanged}
              invoiceAddressFieldsError={invoiceAddressFieldsError}
              postalAddressFieldsError={postalAddressFieldsError}
              deliveryAddressFieldsError={deliveryAddressFieldsError}
              addressOptions={addressOptions}
              deliveryAddress={deliveryAddress}
              handleAddressChange={handleAddressChange}
              handleAddressFieldChange={handleAddressFieldChange}
              handleRemoveAllProducts={handleRemoveAllProducts}
              invoiceAddress={invoiceAddress}
              postalAddress={postalAddress}
              selectedAddressField={selectedAddressField}
              shoppingCartSettings={shoppingCartSettings}
            />
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <Box className={classes.priceBox}>
            <Box className={classes.priceBoxHead}>
              <h5 className={classes.priceBoxTitle}>
                <FormattedMessage id="cart.pageTitle" />
                <span className={classes.priceBoxQty}>
                  ({cartItems.length} <FormattedMessage id="cart.productQty" />)
                </span>
              </h5>
            </Box>
            <Box className={classes.priceBoxBody}>
              <CartSummary
                deliveryAddressChanged={deliveryAddressChanged}
                submitOrder={handleSubmitOrder}
                shoppingCartSettings={shoppingCartSettings}
                checkoutFields={checkoutFields}
                page={page}
                setPage={setPage}
                disableOrderButton={
                  !isEmpty(deliveryAddressFieldsError) ||
                  !isEmpty(invoiceAddressFieldsError) ||
                  !isEmpty(postalAddressFieldsError)
                }
                costCenters={costCenters}
                message={orderMessage}
                onFieldsChange={updateFields}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {confirmationModal && (
        <ConfirmDeleteModal
          open={confirmationModal}
          setOpen={setConfirmationModal}
          onConfirmCloseModal={handleRemoveAllProducts}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    cartItems: state.api.cart.cartItems,
    fileGroups: state.api.cart.fileGroups,
    oneStreamGroups: state.api.cart.oneStreamGroups,
    siteId: state.ui.toggleWorkspaces.workspace.id,
    orderGroupId: getSelectedSiteOrderGroupId(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      cartInitialize,
    },
    dispatch
  );
};

Checkout.defaultProps = {
  orderGroupId: null,
  oneStreamGroups: [],
  fileGroups: [],
};

Checkout.propTypes = {
  oneStreamGroups: PropTypes.arrayOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      packageName: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  fileGroups: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number,
      packageId: PropTypes.string,
      packageName: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  orderGroupId: PropTypes.number,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
  cartInitialize: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

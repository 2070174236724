import { gql } from '@apollo/client';

const ProjectFields = gql`
  fragment ProjectFields on ProjectFields {
    __typename

    fieldId
    name
    type
    jsonFieldSettings
  }
`;

export default ProjectFields;

/**
 * An enumeration of the different payment methods.
 * @enum {string}
 */
export const PAYMENT_METHOD = {
  /** Payment method 'Unknown'. */
  Unknown: "UNKNOWN",
  /** Payment method 'Invoice'. */
  Invoice: "INVOICE",
  /** Payment method 'Card'. */
  Card: "CARD",
};

export default PAYMENT_METHOD;

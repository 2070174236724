import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import {
  Typography,
  Grid,
  Box,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../../common/TextField";
import CustomSelect from "../../common/CustomSelect";
import TextModal from "./TextModal";
import ButtonPrimaryAlt from "../../common/ButtonPrimaryAlt";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  text: {
    fontSize: 12,
    marginTop: 10,
  },
  selectWrap: {
    "& $label": {
      marginBottom: 8,
    },
    "& .MuiSelect-select": {
      padding: "8px 12px",
    },
  },
  card: {
    border: "1px solid #EFEFEF",
    borderRadius: 4,
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
  },
  cardHead: {
    padding: 10,
  },
  cardBody: {
    borderTop: "1px solid #EFEFEF",
    padding: 10,
    "& .MuiGrid-item": {
      padding: "5px 8px 0 8px",
    },
  },
  formControlRoot: {
    marginLeft: 0,
  },
  textLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  textIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
    marginRight: 8,
  },
  textLabel: {
    fontSize: 11,
    marginBottom: 3,
    "& span": {
      float: "right",
    },
  },
  questionIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginLeft: 3,
    float: "none !important",
    color: theme.palette.common.link,
  },
}));

const ProfileEditModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const [openTextModal, setOpenTextModal] = useState(false);
  const handleOpenTextModal = () => {
    setOpenTextModal(true);
  };
  const handleCloseTextModal = () => {
    setOpenTextModal(false);
  };

  return (
    <>
      <DraggableModal
        open={open}
        handleClose={handleClose}
        customClass={classes.modal}
      >
        <DraggableModalHead handleClose={handleClose} title="Profile edit" />
        <DraggableModalBody>
          <Grid container spacing={2} className={classes.formControl}>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={2}>
                      <Typography className={classes.label}>
                        Profile name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <CustomTextField />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={7}>
                      <FormControlLabel
                        control={<Switch size="small" color="primary" />}
                        label="Show outcomes"
                        classes={{
                          root: classes.formControlRoot,
                          label: classes.label,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} align="right">
                      <CustomSelect
                        options={[
                          { label: "Warn", value: 1 },
                          { label: "Stop", value: 2 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} align="right">
                      <Button
                        className={classes.textLink}
                        onClick={handleOpenTextModal}
                      >
                        <Icon
                          className={clsx([
                            "fas fa-pencil-alt",
                            classes.textIcon,
                          ])}
                        />
                        Texts
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={7}>
                      <FormControlLabel
                        control={<Switch size="small" color="primary" />}
                        label="Pictures"
                        classes={{
                          root: classes.formControlRoot,
                          label: classes.label,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} align="right">
                      <CustomSelect
                        options={[
                          { label: "Warn", value: 1 },
                          { label: "Stop", value: 2 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} align="right">
                      <Button
                        className={classes.textLink}
                        onClick={handleOpenTextModal}
                      >
                        <Icon
                          className={clsx([
                            "fas fa-pencil-alt",
                            classes.textIcon,
                          ])}
                        />
                        Texts
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.cardBody}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textLabel}>
                        Pictures, minimum resolution
                      </Typography>
                      <CustomTextField />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textLabel}>
                        Pictures, maximum resolution
                      </Typography>
                      <CustomTextField />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={7}>
                      <FormControlLabel
                        control={<Switch size="small" color="primary" />}
                        label="Document format"
                        classes={{
                          root: classes.formControlRoot,
                          label: classes.label,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} align="right">
                      <CustomSelect
                        options={[
                          { label: "Warn", value: 1 },
                          { label: "Stop", value: 2 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} align="right">
                      <Button
                        className={classes.textLink}
                        onClick={handleOpenTextModal}
                      >
                        <Icon
                          className={clsx([
                            "fas fa-pencil-alt",
                            classes.textIcon,
                          ])}
                        />
                        Texts
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.cardBody}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textLabel}>
                        Width<span>mm</span>
                      </Typography>
                      <CustomTextField />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textLabel}>
                        Height<span>mm</span>
                      </Typography>
                      <CustomTextField />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={7}>
                      <FormControlLabel
                        control={<Switch size="small" color="primary" />}
                        label="Missing fonts (not embedded)"
                        classes={{
                          root: classes.formControlRoot,
                          label: classes.label,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} align="right">
                      <CustomSelect
                        options={[
                          { label: "Warn", value: 1 },
                          { label: "Stop", value: 2 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} align="right">
                      <Button
                        className={classes.textLink}
                        onClick={handleOpenTextModal}
                      >
                        <Icon
                          className={clsx([
                            "fas fa-pencil-alt",
                            classes.textIcon,
                          ])}
                        />
                        Texts
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={7}>
                      <FormControlLabel
                        control={<Switch size="small" color="primary" />}
                        label=" Documents containing different formats"
                        classes={{
                          root: classes.formControlRoot,
                          label: classes.label,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} align="right">
                      <CustomSelect
                        options={[
                          { label: "Warn", value: 1 },
                          { label: "Stop", value: 2 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} align="right">
                      <Button
                        className={classes.textLink}
                        onClick={handleOpenTextModal}
                      >
                        <Icon
                          className={clsx([
                            "fas fa-pencil-alt",
                            classes.textIcon,
                          ])}
                        />
                        Texts
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={7}>
                      <FormControlLabel
                        control={<Switch size="small" color="primary" />}
                        label="Number of pages"
                        classes={{
                          root: classes.formControlRoot,
                          label: classes.label,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} align="right">
                      <CustomSelect
                        options={[
                          { label: "Warn", value: 1 },
                          { label: "Stop", value: 2 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} align="right">
                      <Button
                        className={classes.textLink}
                        onClick={handleOpenTextModal}
                      >
                        <Icon
                          className={clsx([
                            "fas fa-pencil-alt",
                            classes.textIcon,
                          ])}
                        />
                        Texts
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.cardBody}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textLabel}>
                        Lowest number
                      </Typography>
                      <CustomTextField />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textLabel}>
                        Maximum number{" "}
                        <Tooltip
                          title="Set to 0 for unlimited number"
                          placement="top"
                        >
                          <Icon
                            className={clsx([
                              "fas fa-question-circle",
                              classes.questionIcon,
                            ])}
                          />
                        </Tooltip>
                      </Typography>
                      <CustomTextField />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.card}>
                <Box className={classes.cardHead}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} md={7}>
                      <FormControlLabel
                        control={<Switch size="small" color="primary" />}
                        label="Number of pages, divisibility"
                        classes={{
                          root: classes.formControlRoot,
                          label: classes.label,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} align="right">
                      <CustomSelect
                        options={[
                          { label: "Warn", value: 1 },
                          { label: "Stop", value: 2 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} align="right">
                      <Button
                        className={classes.textLink}
                        onClick={handleOpenTextModal}
                      >
                        <Icon
                          className={clsx([
                            "fas fa-pencil-alt",
                            classes.textIcon,
                          ])}
                        />
                        Texts
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.cardBody}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography className={classes.textLabel}>
                        Evenly divisible by
                      </Typography>
                      <CustomTextField />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.modalFooter} align="right">
            <ButtonPrimaryAlt
              label={<FormattedMessage id="btn.saveChanges" />}
              icon="fa fa-save"
            />
          </Box>
        </DraggableModalBody>
      </DraggableModal>
      <TextModal open={openTextModal} handleClose={handleCloseTextModal} />
    </>
  );
};

ProfileEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ProfileEditModal;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Typography,
  IconButton,
} from "@mui/material";
import clsx from "clsx";
import Swal from "sweetalert2/dist/sweetalert2";
import { useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import { REMOVE_EXTERNAL_USER } from "../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  userBox: {
    padding: "15px 10px",
    border: "1px solid #e3e3e3",
    borderRadius: 4,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.05)",
    position: "relative",
    textAlign: "center",
  },
  profile: {
    width: 40,
    height: 40,
    objectFit: "cover",
    borderRadius: "50%",
  },
  name: {
    fontSize: 12,
    fontWeight: 500,
    wordBreak: "break-word",
    marginBottom: 5,
  },
  email: {
    fontSize: 10,
    fontWeight: 600,
    wordBreak: "break-word",
    marginBottom: 5,
  },
  username: {
    fontSize: 10,
    fontWeight: 500,
    wordBreak: "break-word",
    marginBottom: 5,
  },
  role: {
    fontSize: 10,
    wordBreak: "break-word",
    textTransform: "uppercase",
  },
  iconButtonEdit: {
    padding: 2,
    color: theme.palette.primary.main,
  },
  iconButtonLogin: {
    padding: 2,
    color: theme.palette.common.link,
    marginLeft: 4,
  },
  iconButtonRemove: {
    padding: 2,
    color: theme.palette.common.red,
    marginLeft: 4,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  iconEdit: {
    fontSize: 10,
  },
  iconLogin: {
    fontSize: 13,
  },
  iconRemove: {
    fontSize: 13,
  },
  userActions: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  loading: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
}));

const UserBox = ({ users, loading, refetch, handleToUpdateUser }) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const [removeExternalUser] = useMutation(REMOVE_EXTERNAL_USER);

  const handleRemoveUser = item => {
    Swal.fire({
      icon: "warning",
      text: intl.formatMessage({ id: "context.deleteConfirmation" }),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: intl.formatMessage({ id: "context.delete" }),
      cancelButtonText: intl.formatMessage({ id: "context.cancel" }),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await removeExternalUser({
            variables: {
              targetCompanyId: item.companyId,
              userId: item.userId,
            },
          });

          const {
            success,
          } = res.data.companyAdminContext.externalUsers.removeExternalUser;
          if (success) {
            alert.success(intl.formatMessage({ id: "common.genericSuccess" }));
            refetch();
          }
          if (!success) {
            alert.error(
              intl.formatMessage({ id: "common.genericErrorMessage" })
            );
          }
        } catch (err) {
          console.error("error >", err);
        }
      }
    });
  };

  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid container justifyContent="center">
          <CircularProgress size={30} />
        </Grid>
      )}

      {!loading && (
        <>
          {users.map(user => {
            return (
              <Grid item xs={6} sm={4} md={2} key={user.userId}>
                <Box className={classes.userBox}>
                  <img
                    src={user.avatar}
                    alt="Profile"
                    className={classes.profile}
                  />
                  <Typography className={classes.name}>
                    {user.firstname} {user.lastname}
                  </Typography>
                  <Typography className={classes.email}>
                    {user.username}
                  </Typography>
                  <Typography className={classes.role}>
                    {user.roleName}
                  </Typography>
                  <Box className={classes.userActions}>
                    <IconButton
                      className={classes.iconButtonEdit}
                      onClick={() => handleToUpdateUser(user)}
                    >
                      <Icon
                        className={clsx([
                          "fa fa-pencil-alt",
                          classes.icon,
                          classes.iconEdit,
                        ])}
                      />
                    </IconButton>
                    <IconButton
                      className={classes.iconButtonRemove}
                      onClick={() => handleRemoveUser(user)}
                    >
                      <Icon
                        className={clsx([
                          "fa fa-times",
                          classes.icon,
                          classes.iconRemove,
                        ])}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </>
      )}
    </Grid>
  );
};

UserBox.defaultProps = {
  users: [],
  loading: false,
  refetch: null,
};

UserBox.propTypes = {
  refetch: PropTypes.func,
  loading: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  handleToUpdateUser: PropTypes.func.isRequired,
};

export default UserBox;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { useLazyQuery } from "@apollo/client";
import OrderDetailContainer from "./OrderDetailContainer";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import { PRINT_ORDER_HISTORY } from "../../graphql/queries";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "900px",
    maxHeight: "95vh",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "95vh",
    },
  },
  modalTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 2.3,
    marginRight: 20,
    display: "inline-block",
  },
  btnPrint: {
    padding: "3px 15px",
    fontSize: 14,
    marginRight: 15,
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
    "@media print": {
      display: "none",
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const OrderModal = ({ open, handleClose, supplierOrderId, detailSettings }) => {
  const classes = useStyles();

  const [printOrderHistory, { data: orderHistoryPrintData }] = useLazyQuery(
    PRINT_ORDER_HISTORY
  );

  const handlePrintOrder = () => {
    const supplierOrderIds = [supplierOrderId];
    printOrderHistory({
      variables: { supplierOrderIds },
    });
  };

  useEffect(() => {
    if (orderHistoryPrintData && orderHistoryPrintData.orderHistoryPrint) {
      window.open(orderHistoryPrintData.orderHistoryPrint);
    }
  }, [orderHistoryPrintData]);

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose}>
        <h4 className={classes.modalTitle}>Order {supplierOrderId}</h4>
        <Button className={classes.btnPrint} onClick={() => handlePrintOrder()}>
          <Icon className={clsx(["fa fa-print", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.print" />
          </span>
        </Button>
      </DraggableModalHead>
      <DraggableModalBody>
        <OrderDetailContainer
          supplierOrderId={supplierOrderId}
          settings={detailSettings}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

OrderModal.defaultProps = {
  orderGroupId: null,
  orderId: null,
  companyOrderno: null,
  detailSettings: null,
};

OrderModal.propTypes = {
  orderGroupId: PropTypes.number,
  orderId: PropTypes.number,
  companyOrderno: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  detailSettings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
};

export default OrderModal;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import ButtonPrimaryAltSm from "../../common/ButtonPrimaryAltSm";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    textTransform: "none",
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 5,
  },
  uploadBox: {
    padding: 5,
    borderRadius: 4,
    border: "1px solid #d7d7d7",
    width: "95%",
  },
  input: {
    display: "none",
  },
}));

const ImportModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="Import addresses" />
      <DraggableModalBody>
        <Typography className={classes.label}>Import address file</Typography>
        <Box className={classes.uploadBox}>
          <input
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            type="file"
          />
          <label htmlFor="icon-button-file">
            <ButtonPrimaryAltSm component="span" label="Choose file" />
          </label>
        </Box>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-file-import", classes.btnIcon])} />
            Import
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ImportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ImportModal;

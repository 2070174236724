import React from "react";
import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
} from "@mui/material";
import CustomTextField from "../../common/TextField";
import CustomSelect from "../../common/CustomSelect";
import CheckboxDefault from "../../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  table: {},
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
  },
  tdCb: {
    padding: "4px 0",
    paddingRight: "0 !important",
    minWidth: 18,
    "& .MuiCheckbox-root": {
      padding: 0,
      paddingBottom: 2,
    },
    "& .MuiSvgIcon-root": {
      width: "18px !important",
      height: "18px !important",
    },
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginTop: 10,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
}));

const MatchSettingsTable = () => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>name</TableCell>
            <TableCell className={classes.th}>view in search</TableCell>
            <TableCell className={classes.th}>weight</TableCell>
            <TableCell className={classes.th}>spelling tolerance</TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>
              <CustomTextField />
            </TableCell>
            <TableCell className={classes.tdCb} align="center">
              <CheckboxDefault />
            </TableCell>
            <TableCell className={classes.td}>
              <CustomSelect options={[{ label: "0", value: 1 }]} />
            </TableCell>
            <TableCell className={classes.td}>
              <CustomSelect options={[{ label: "Exactly", value: 1 }]} />
            </TableCell>
            <TableCell className={classes.td}>
              <IconButton className={classes.iconButtonDelete}>
                <Icon className={clsx(["fa fa-times", classes.icon])} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Button className={classes.addLink}>
        <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
        Add row
      </Button>
    </>
  );
};

export default MatchSettingsTable;

import { useLazyQuery } from "@apollo/client";
import { GET_FOLDER_RESOURCE_LISTING } from "../graphql/queries";

// eslint-disable-next-line import/prefer-default-export
export const useGetFolderResourceListing = () => {
  const [
    getFolderResourceListing,
    { data, loading, error, refetch },
  ] = useLazyQuery(GET_FOLDER_RESOURCE_LISTING);

  return { getFolderResourceListing, data, loading, refetch, error };
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme => ({
  headerTitle: {
    fontSize: 28,
    fontWeight: "bolder",
    color: theme.palette.component.homeMainColor,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px !important",
    },
  },
}));

const SiteHeaderTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <Typography align="center" className={classes.headerTitle}>
      {title}
    </Typography>
  );
};

SiteHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SiteHeaderTitle;

import { gql } from '@apollo/client';

const OVERVIEW_DASHBOARD = gql`
  query GetOverviewDashboard {
    dashboardContext {
      overviewDashboard {
        canManageDashboard
        dashboardData {
          content
          id
          type
          dashboardSettings {
            name
            description
            template
            background
          }
        }
        newsImageUploadUrl
      }
    }
  }
`;

export default OVERVIEW_DASHBOARD;

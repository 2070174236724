import { gql } from '@apollo/client';
import SiteChannelMessageFragment from "./SiteChannelMessage";

const SiteChannelMessageEdgeIObservableFragment = gql`
  fragment SiteChannelMessageEdgeIObservableFragment on SiteChannelMessageEdgeIObservable {
    cursor
    node {
      ...SiteChannelMessageFragment
    }
  }
  ${SiteChannelMessageFragment}
`;

export default SiteChannelMessageEdgeIObservableFragment;

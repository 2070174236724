import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const useStyles = makeStyles(() => ({
  checkboxLabel: {
    fontSize: 12,
  },
  root: {
    marginRight: 0,
    "& .MuiCheckbox-colorSecondary.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26) !important",
    },
  },
  checkbox: {
    zIndex: 0,
  },
}));

const CheckboxDefault = ({
  label,
  cbcolor,
  onChange,
  name,
  value,
  disabled,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [color, setColor] = useState(theme.palette.primary.main);
  useEffect(() => {
    if (cbcolor === "red") setColor(theme.palette.common.red);
    if (cbcolor === "blue") setColor(theme.palette.common.blue);
  }, []);

  return (
    <FormControlLabel
      classes={{
        label: classes.checkboxLabel,
        root: classes.root,
      }}
      control={
        <Checkbox
          iconStyle={{ fill: color }}
          labelStyle={{ color }}
          inputStyle={{ color }}
          style={{ color }}
          onChange={onChange}
          name={name}
          checked={value}
          disabled={disabled}
          className={classes.checkbox}
        />
      }
      label={label}
    />
  );
};

CheckboxDefault.defaultProps = {
  label: "",
  onChange: null,
  name: "",
  value: false,
  cbcolor: "",
  disabled: false,
};

CheckboxDefault.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.bool,
  cbcolor: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckboxDefault;

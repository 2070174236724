import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Box, Grid, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { ErrorText, FieldLabel, TextField } from "../../../../common";
import NetworkSelector from "../../../../common/NetworkSelector";
import CustomSelect from "../../../../common/CustomSelect";
import c3validators from "../c3validators";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: 25,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 3,
    },
  },
  text: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 8,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  select: {
    "& fieldset > legend > span": {
      padding: 0,
    },
  },
  validationError: {
    "& fieldset": {
      borderColor: theme.palette.error.main + " !important",
      borderWidth: 2,
    },
    "& :hover fieldset": {
      borderColor: theme.palette.error.main + " !important",
      borderWidth: 2,
    },
  },
  networkSelector: {
    marginTop: 3,
    "&.MuiButton-root": {
      marginTop: 5,
      textTransform: "unset",
      "& .MuiTypography-root": {
        fontSize: 12,
      },
    },
  },
}));

const subscriptionOptions = [
  { label: "Free", value: "FREE" },
  { label: "Mini", value: "MINI" },
  { label: "Standard", value: "STANDARD" },
  { label: "Business", value: "BUSINESS" },
  { label: "Pro", value: "PRO" },
  { label: "Premium", value: "PREMIUM" },
];

const ColumnThree = ({
  editMode,
  formValues,
  setFormValues,
  setValidationC3: setValidation,
  validationC3: validation,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    companyRoleId,
    currentPassword,
    networkId,
    password,
    passwordConfirm,
    selectedTheme,
    subscriptionLevel,
    username,
  } = formValues || {};
  const [companyRoleOptions, setCompanyRoleOptions] = useState([]);
  const [selectableThemes, setSelectableThemes] = useState([]);
  const [selectableNetworks, setSelectableNetworks] = useState([]);

  useEffect(() => {
    if (formValues) {
      const { companyRoles, selectableNetworks, selectableThemes } = formValues;
      const tempNetworks = selectableNetworks.map(x => {
        return {
          label: x.name,
          value: x.networkId,
        };
      });
      const tempRoles = companyRoles.map(x => {
        return {
          subscriptionLevel: x.subscriptionLevel,
          label: x.name,
          value: x.companyRoleId,
        };
      });
      const tempThemes = selectableThemes.map(x => {
        return {
          label: x.key,
          value: x.value,
        };
      });
      setCompanyRoleOptions(tempRoles);
      setSelectableNetworks(tempNetworks);
      setSelectableThemes(tempThemes);
    }
  }, [formValues]);

  const handleChangeCurrentPassword = e => {
    const { value: currentPassword } = e.target;
    var passwordValidationResult = validatePasswords(
      formValues.password || "",
      formValues.passwordConfirm || "",
      currentPassword
    );
    updateValidation(passwordValidationResult);
    updateFormValues({ currentPassword });
  };

  const handleChangePassword = e => {
    const { value: password } = e.target;
    var passwordValidationResult = validatePasswords(
      password,
      formValues.passwordConfirm || "",
      formValues.currentPassword || ""
    );
    updateValidation(passwordValidationResult);
    updateFormValues({ password });
  };

  const handleChangePasswordConfirm = e => {
    const { value: passwordConfirm } = e.target;
    var passwordValidationResult = validatePasswords(
      formValues.password || "",
      passwordConfirm,
      formValues.currentPassword || ""
    );
    updateValidation(passwordValidationResult);
    updateFormValues({ passwordConfirm });
  };

  const handleChangeRole = e => {
    const { value: companyRoleId } = e.target;
    const company = companyRoleOptions.find(x => x.value === companyRoleId);
    var validateRoleResult = validateRole(companyRoleId);
    updateValidation(validateRoleResult);
    updateFormValues({
      subscriptionLevel: company.subscriptionLevel,
      companyRoleId,
    });
  };

  const handleChangeSubscriptionLevel = e => {
    const { value: subscriptionLevel } = e.target;
    updateFormValues({ subscriptionLevel });
  };

  const handleChangeTheme = e => {
    const { value: selectedTheme } = e.target;
    updateFormValues({ selectedTheme });
  };

  const handleChangeNetwork = e => {
    const { value: networkId } = e.target;
    var validateNetworkResult = validateNetwork(networkId);
    updateValidation(validateNetworkResult);
    updateFormValues({ networkId });
  };

  const handleChangeUsername = e => {
    const { value } = e.target;
    var username = value.trim();
    var validateUsernameResult = validateUsername(username);
    updateValidation(validateUsernameResult);
    updateFormValues({
      username,
      email: username,
    });
  };

  const updateFormValues = update => {
    setFormValues({
      ...formValues,
      ...update,
    });
  };

  const updateValidation = update => {
    setValidation({
      ...validation,
      ...update,
    });
  };

  const validatePasswords = (
    password,
    passwordConfirm,
    currentPassword = null
  ) => {
    return c3validators.validatePasswords(
      editMode,
      password,
      passwordConfirm,
      currentPassword
    );
  };

  const validateNetwork = roleId => {
    return c3validators.validateNetwork(editMode, roleId);
  };

  const validateRole = roleId => {
    return c3validators.validateRole(editMode, roleId);
  };

  const validateUsername = username => {
    return c3validators.validateUsername(editMode, username);
  };

  const validationClass = o => {
    return o === false ? classes.validationError : "";
  };

  const validationErrorText = (o, textId) => {
    return (
      o === false &&
      textId && (
        <ErrorText message={intl.formatMessage({ id: textId })} fontSize={11} />
      )
    );
  };

  return (
    <Grid item xs={12} md={12}>
      <Box flex={2}>
        <Grid container spacing={1} className={classes.formControl}>
          <Typography className={classes.text}>
            <FormattedMessage id="settings.userDetails" />
          </Typography>

          <Grid container spacing={1} className={classes.formControl}>
            <Grid item xs={12} md={6}>
              <FieldLabel>
                <FormattedMessage id="settings.username" /> (
                <FormattedMessage id="settings.email" />) {editMode ? "" : "*"}
              </FieldLabel>
              <TextField
                customClass={validationClass(validation.username)}
                disabled={editMode}
                type="text"
                value={username}
                onChange={handleChangeUsername}
              />
              {validationErrorText(
                validation.username,
                "settings.usernameNotValid"
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <FieldLabel>
                <FormattedMessage id="settings.network" /> {editMode ? "" : "*"}
              </FieldLabel>
              <NetworkSelector
                className={classes.networkSelector}
                onChange={handleChangeNetwork}
                options={selectableNetworks}
                value={networkId}
              />
              {validationErrorText(
                validation.network,
                "settings.networkNotSelected"
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1} className={classes.formControl}>
            <Grid item xs={12} md={6}>
              <FieldLabel>
                <FormattedMessage id="settings.role" /> {editMode ? "" : "*"}
              </FieldLabel>
              <CustomSelect
                customClass={clsx([
                  classes.select,
                  validationClass(validation.role),
                ])}
                onChange={handleChangeRole}
                options={companyRoleOptions}
                value={companyRoleId}
              />
              {validationErrorText(validation.role, "settings.roleNotSelected")}
            </Grid>

            <Grid item xs={12} md={6}>
              <FieldLabel>
                <FormattedMessage id="settings.userSubscriptionLevel" />
              </FieldLabel>
              <CustomSelect
                customClass={classes.select}
                options={subscriptionOptions}
                value={subscriptionLevel}
                onChange={handleChangeSubscriptionLevel}
              />
            </Grid>
          </Grid>


          {!editMode && (
          <Grid container spacing={1} className={classes.formControl}>
            <Grid item xs={12} md={6}>
              <FieldLabel>
                <FormattedMessage
                  id={editMode ? "settings.newPassword" : "settings.password"}
                />{" "}
                {editMode ? "" : "*"}
              </FieldLabel>
              <TextField
                customClass={validationClass(validation.password)}
                type="password"
                name="password"
                value={password || ""}
                onChange={handleChangePassword}
              />
              {validationErrorText(validation.password, "settings.lessThan8")}
            </Grid>

            <Grid item xs={12} md={6}>
              <FieldLabel>
                <FormattedMessage id="settings.passwordRepeat" />{" "}
                {editMode ? "" : "*"}
              </FieldLabel>
              <TextField
                customClass={validationClass(validation.passwordConfirm)}
                type="password"
                name="passwordConfirm"
                value={passwordConfirm || ""}
                onChange={handleChangePasswordConfirm}
              />
              {validationErrorText(
                validation.passwordConfirm,
                "createAccount.passwordMatch"
              )}
            </Grid>

            </Grid>)}

        </Grid>
      </Box>

      <Divider className={classes.divider} />

      <Box flex={3}>
        <Grid container spacing={1} className={classes.formControl}>
          <Typography className={classes.text}>
            <FormattedMessage id="settings.userDisplaySetting" />
          </Typography>

          <Grid container spacing={1} className={classes.formControl}>
            <Grid item xs={12} md={6}>
              <Box style={{ marginBottom: 4 }}>
                <FieldLabel>
                  <FormattedMessage id="settings.useTheme" />
                </FieldLabel>
              </Box>
              <CustomSelect
                defaultOption={intl.formatMessage({
                  id: "settings.defaultTheme",
                })}
                inputLabel={intl.formatMessage({ id: "common.select" })}
                options={selectableThemes}
                onChange={handleChangeTheme}
                value={selectedTheme || ""}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

ColumnThree.defaultProps = {
  editMode: false,
  setValidationC3: () => {
    if (console && "error" in console) {
      console.error("Property setValidationC3 is not provided.");
    }
  },
  validationC3: PropTypes.shape({
    currentPassword: null,
    network: null,
    password: null,
    passwordConfirm: null,
    role: null,
    username: null,
  }),
};

ColumnThree.propTypes = {
  editMode: PropTypes.bool,
  setValidationC3: PropTypes.func.isRequired,
  validationC3: PropTypes.shape({
    currentPassword: PropTypes.bool,
    network: PropTypes.bool,
    password: PropTypes.bool,
    passwordConfirm: PropTypes.bool,
    role: PropTypes.bool,
    username: PropTypes.bool,
  }).isRequired,
};

export default ColumnThree;

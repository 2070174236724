import { gql } from '@apollo/client';

const GET_AUTHENTICATOR_SECRET = gql`
  query getAuthenticatorSecret {
    userAccountContext {
      getAuthenticatorSecret
  }
}`;

export default GET_AUTHENTICATOR_SECRET;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Button, Icon } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  btnEdit: {
    marginLeft: 10,
    padding: "3px 10px",
    fontSize: 12,
    textTransform: "none",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    marginRight: 15,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  editIcon: {
    fontSize: 12,
    marginRight: 8,
    verticalAlign: "middle",
    width: "unset",
  },
}));

const UpdateSelectedRows = ({ handleOpen }) => {
  const classes = useStyles();
  return (
    <Button className={classes.btnEdit} onClick={handleOpen}>
      <Icon className={clsx(["fa fa-edit", classes.editIcon])} />
      <FormattedMessage id="cart.updateSelectedRows" />
    </Button>
  );
};

UpdateSelectedRows.propTypes = {
  handleOpen: PropTypes.func.isRequired,
};

export default UpdateSelectedRows;

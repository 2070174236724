import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DELETE_ITEM = gql`
  mutation DeleteItem($shoppingCartItemId: ID!) {
    shoppingCartContext {
      deleteItem(shoppingCartItemId: $shoppingCartItemId) {
        errorResult {
          ...ErrorResultFields
        }
        result {
          groups {
            oneStreamGroups {
              packageName
              packageId
            }
            orderGroupId
          }
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_ITEM;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../../../../hooks";
import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_FILE_CONTENT } from "../../../../../../../../graphql/queries/DynamicTemplateEditContext";
import { UPDATE_FILE_CONTENT } from "../../../../../../../../graphql/mutations/DynamicTemplateEditContext";
import DraggableModal from "../../../../../../../common/DraggableModal";
import DraggableModalHead from "../../../../../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../../../../../common/DraggableModal/DraggableModalBody";
import CodeEditor from "../../../../../../../common/CodeEditor";
import CustomSelect from "../../../../../../../common/CustomSelect";
import CustomTextField from "../../../../../../../common/TextField";
import { PrimaryButton } from "../../../../../../../common/Buttons";

import { themes, modes, getFileExtension } from "../helper";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 25,
  },
  modal: {
    width: 1200,
    minHeight: 600,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: 0,
    overflow: "hidden",
  },
  tourIconWrapper: {
    display: "flex",
    flex: 1,
    marginRight: 8,
  },
  tourIconButton: {
    marginLeft: "auto",
    cursor: "pointer",
    padding: 4,
    color: theme.palette.common.white,
    "& span": {
      fontSize: 16,
    },
  },
  selectCont: {
    marginBottom: 15,
  },
  loading: {
    marginTop: 30,
  },
  btn: {
    marginTop: 15,
  },
  textfield: {
    marginRight: 8,
  },
}));

const EditorModal = ({
  filename,
  handleClose,
  onFileCreated,
  open,
  productId,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const [
    getFileContent,
    { data: fileContentData, loading: filesContentLoading },
  ] = useLazyQuery(GET_FILE_CONTENT);

  const [updateFileContent] = useMutation(UPDATE_FILE_CONTENT);

  const [theme, setTheme] = useState("monokai");
  const [mode, setMode] = useState("javascript");
  const [editorValue, setEditorValue] = useState("");
  const [newFileName, setNewFileName] = useState("");

  const handleEditorOnChange = value => {
    setEditorValue(value);
  };

  useEffect(() => {
    if (filename) {
      getFileContent({ variables: { productId, filename } });

      const extenstion = getFileExtension(filename);

      if (extenstion === "js") {
        setMode("javascript");
      }

      if (extenstion === "xml") {
        setMode("xml");
      }
    }
  }, [filename, productId]);

  useEffect(() => {
    if (
      fileContentData &&
      fileContentData.dynamicTemplateEditContext &&
      fileContentData.dynamicTemplateEditContext.getFileContent
    ) {
      setEditorValue(fileContentData.dynamicTemplateEditContext.getFileContent);
    }
  }, [fileContentData]);

  const handleUpdateFileContent = async () => {
    function displayError() {
      snackbar.workspaceError(
        <FormattedMessage id="editProduct.errorUpdatingFile" />
      );
    }

    try {
      const res = await updateFileContent({
        variables: {
          productId,
          file: filename,
          content: editorValue,
        },
      });

      if (
        res &&
        res.data &&
        res.data.dynamicTemplateEditContext &&
        res.data.dynamicTemplateEditContext.updateFileContent
      ) {
        alert.success(<FormattedMessage id="editProduct.updatedFileContent" />);
        handleClose();
      } else {
        displayError();
      }
    } catch (err) {
      console.error("error >", err);
      displayError();
    }
  };

  const handleCreateFile = async () => {
    function displayError() {
      snackbar.workspaceError(
        <FormattedMessage id="editProduct.errorFileCreate" />
      );
    }

    let file = "";

    if (mode === "javascript") {
      file = `${newFileName}.js`;
    } else {
      file = `${newFileName}.xml`;
    }

    try {
      const res = await updateFileContent({
        variables: {
          productId,
          file,
          content: editorValue,
        },
      });

      if (
        res &&
        res.data &&
        res.data.dynamicTemplateEditContext &&
        res.data.dynamicTemplateEditContext.updateFileContent
      ) {
        handleFileCreated(file);
      } else {
        displayError();
      }
    } catch (err) {
      console.error("error >", err);
      displayError();
    }
  };

  const handleFileCreated = file => {
    alert.success(<FormattedMessage id="editProduct.fileCreated" />);
    handleClose();
    if (typeof onFileCreated === "function") {
      onFileCreated(file);
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        {filesContentLoading && (
          <Grid container justifyContent="center">
            <CircularProgress size={40} className={classes.loading} />
          </Grid>
        )}

        {!filesContentLoading && (
          <Grid container justifyContent="center" spacing={1} className={classes.root}>
            <Grid container item spacing={2}>
              <Grid item xs={3}>
                <Box className={classes.selectCont}>
                  <CustomSelect
                    options={themes}
                    value={theme}
                    onChange={e => setTheme(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item xs={3}>
                {!filename && (
                  <Box className={classes.selectCont}>
                    <CustomSelect
                      options={modes}
                      value={mode}
                      onChange={e => setMode(e.target.value)}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} align="center">
              <CodeEditor
                value={editorValue}
                onChange={handleEditorOnChange}
                placeholder="Enter code here"
                mode={mode}
                theme={theme}
                name="dynamicTemplateEditor"
                width="100%"
                fontSize={14}
                showGutter
                highlightActiveLine
                showLineNumbers
                tabSize={2}
              />
            </Grid>
            {filename && (
              <Grid item xs={12} align="right" className={classes.btn}>
                <PrimaryButton onClick={handleUpdateFileContent}>
                  <FormattedMessage id="btn.update" />
                </PrimaryButton>
              </Grid>
            )}

            {!filename && (
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <CustomTextField
                    label={<FormattedMessage id="detailedSearch.fileName" />}
                    className={classes.textfield}
                    value={newFileName}
                    onChange={e => setNewFileName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={3}>
                  <PrimaryButton onClick={handleCreateFile} fullWidth>
                    <FormattedMessage id="btn.save" />
                  </PrimaryButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

EditorModal.defaultProps = {
  filename: null,
  productId: null,
};

EditorModal.propTypes = {
  filename: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onFileCreated: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  productId: PropTypes.string,
};

export default EditorModal;

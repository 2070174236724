import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import DraggableModal from "../../common/DraggableModal";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import HelpUserTable from "../HelpUserTable";
import HelpUserButton from "./HelpUserButton";
import HelpUserHeader from "./HelpUserHeader";
import HelpUserTips from "./HelpUserTips";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1100px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    "& .MuiDialogContent-root": {
      paddingTop: "1em",
    }
  },
}));

const HelpUserModal = ({
  alreadyExistingUsers,
  companyList,
  handleCloseHelpUser,
  handleCreateInvitations,
  loading,
  open,
  preparedInvitations,
  setNewCompanyModal,
  setPreparedInvitations,
  siteRoleOptions,
}) => {
  const classes = useStyles();

  const [invitingCompanyId, setInvitingCompanyId] = useState();

  const handleCompanyChange = ({ companyId }) => {
    setInvitingCompanyId(companyId);
  };

  const handleHelpUserButtonClick = () => {
    handleCreateInvitations({ invitingCompanyId });
  };

  return (
    <DraggableModal
      customClass={classes.modal}
      handleClose={handleCloseHelpUser}
      open={open}
    >
      <DraggableModalHead
        handleClose={handleCloseHelpUser}
        title={<FormattedMessage id="siteUsers.helpYourInvitedColleague" />}
      />
      <DraggableModalBody>
        <HelpUserHeader onCompanyChange={handleCompanyChange} />
        <HelpUserTable
          alreadyExistingUsers={alreadyExistingUsers}
          companyList={companyList}
          loading={loading}
          preparedInvitations={preparedInvitations}
          setNewCompanyModal={setNewCompanyModal}
          setPreparedInvitations={setPreparedInvitations}
          siteRoleOptions={siteRoleOptions}
        />
        <Grid container spacing={2}>
          <HelpUserTips />
          <HelpUserButton
            disabled={preparedInvitations.length < 1}
            onClick={handleHelpUserButtonClick}
          />
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

HelpUserModal.defaultProps = {
  companyList: [],
  loading: false,
  open: false,
  preparedInvitations: [],
  siteRoleOptions: [],
};

HelpUserModal.propTypes = {
  companyList: PropTypes.arrayOf(PropTypes.shape({})),
  handleCloseHelpUser: PropTypes.func.isRequired,
  handleCreateInvitations: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  preparedInvitations: PropTypes.arrayOf(PropTypes.shape({})),
  setNewCompanyModal: PropTypes.func.isRequired,
  setPreparedInvitations: PropTypes.func.isRequired,
  siteRoleOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HelpUserModal;

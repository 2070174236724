import React from "react";
import { makeStyles } from "@mui/styles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import TableSortLabel from "@mui/material/TableSortLabel";

const useStyles = makeStyles(theme => ({
  th: {
    padding: 7,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    verticalAlign: "top",
    whiteSpace: "nowrap",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },

  thCollapse: {
    padding: 7,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    verticalAlign: "top",
    whiteSpace: "nowrap",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  tcCheckbox: {
    width: 16,
    padding: 0,
    verticalAlign: "top",
  },
}));
const MyNotificationsTableHeader = ({
  ascending,
  handleOnHeaderClick,
  sortField,
}) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th}>
          <TableSortLabel
            active={sortField === "saveDate"}
            direction={ascending ? "asc" : "desc"}
            onClick={() => handleOnHeaderClick("saveDate")}
          >
            <FormattedMessage id="shares.name" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel
            active={sortField === "name"}
            direction={ascending ? "asc" : "desc"}
            onClick={() => handleOnHeaderClick("name")}
          >
            <FormattedMessage id="monitoring.time" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.thCollapse}>
          <FormattedMessage id="monitoring.howIsTheMonitoringSent" />
        </TableCell>
        <TableCell className={classes.thCollapse}>
          <FormattedMessage id="monitoring.monitoringType" />
        </TableCell>
        <TableCell className={classes.thCollapse}>
          <FormattedMessage id="monitoring.numberOfRecipients" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MyNotificationsTableHeader;

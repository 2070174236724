import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { useLazyQuery } from "@apollo/client";
import { GET_STOCK_HISTORY } from "../../../../../graphql/queries";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import HistoryTable from "./HistoryTable";
import DraggableModal from "../../../../common/DraggableModal";
import DraggableModalHead from "../../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "900px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnPrint: {
    padding: "3px 15px",
    fontSize: 14,
    marginRight: 15,
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
    [theme.breakpoints.down("md")]: {
      padding: 5,
      minWidth: "unset",
    },
  },
  btnDownload: {
    padding: "3px 15px",
    fontSize: 14,
    marginRight: 15,
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
    [theme.breakpoints.down("md")]: {
      padding: 5,
      minWidth: "unset",
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  inline: {
    display: "inline-block",
    marginRight: 20,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  text: {
    marginRight: 5,
  },
}));

const HistoryModal = ({ open, handleClose, resourceId }) => {
  const classes = useStyles();

  const [stockHistory, setStockHistory] = useState({});

  const [getStockHistory, { data: stockHistoryData }] = useLazyQuery(
    GET_STOCK_HISTORY
  );

  useEffect(() => {
    if (open && resourceId) {
      getStockHistory({ variables: { resourceId } });
    }
  }, [open, resourceId]);

  useEffect(() => {
    if (
      stockHistoryData &&
      stockHistoryData.productEdit &&
      stockHistoryData.productEdit.getStockHistory
    ) {
      setStockHistory(stockHistoryData.productEdit.getStockHistory);
    }
  }, [stockHistoryData]);

  const {
    articleNo,
    owner,
    productName,
    stockBalance,
    events = [],
  } = stockHistory;

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="product.history" />}
      >
        <Button className={classes.btnPrint}>
          <Icon className={clsx(["fa fa-print", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.print" />
          </span>
        </Button>
        <Button className={classes.btnDownload}>
          <Icon className={clsx(["fa fa-file-excel", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.dlExcel" />
          </span>
        </Button>
        <Button className={classes.btnDownload}>
          <Icon className={clsx(["fa fa-file-pdf", classes.btnIcon])} />
          <span className={classes.hideMobile}>
            <FormattedMessage id="btn.dlPDF" />
          </span>
        </Button>
      </DraggableModalHead>
      <DraggableModalBody>
        <Box align="center">
          <Typography className={classes.inline}>
            <strong className={classes.text}>
              <FormattedMessage id="product.stock" />:
            </strong>
            {stockBalance}
          </Typography>
          <Typography className={classes.inline}>
            <strong className={classes.text}>
              <FormattedMessage id="common.name" />:
            </strong>
            {productName}
          </Typography>
          <Typography className={classes.inline}>
            <strong className={classes.text}>
              <FormattedMessage id="detailedSearch.articleNo" />:
            </strong>
            {articleNo}
          </Typography>
          <Typography className={classes.inline}>
            <strong className={classes.text}>
              <FormattedMessage id="detailedSearch.productOwner" />:
            </strong>
            {owner}
          </Typography>
        </Box>
        <HistoryTable events={events} />
      </DraggableModalBody>
    </DraggableModal>
  );
};

HistoryModal.defaultProps = {
  resourceId: null,
};

HistoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  resourceId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default HistoryModal;

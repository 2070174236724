import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { STOCK_PRODUCT } from "../../constant/types";

const useStyles = makeStyles(theme => ({
  dynamicFooter: {
    padding: 10,
    backgroundColor: "#f9f9f9",
    position: "absolute",
    bottom: 0,
    width: "95%",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      position: "unset",
    },
  },
  dynamicFooterLink: {
    fontSize: 12,
    textDecoration: "none",
    textTransform: "none",
    color: "#4A90E2",
  },
  btn: {
    padding: "5px 15px",
    fontWeight: 600,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
    marginLeft: 5,
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  outOfStockBox: {
    textAlign: "center",
    backgroundColor: theme.palette.background.contrastGray,
    padding: "3px 10px",
  },
  outOfStockIcon: {
    verticalAlign: "middle",
    marginRight: 7,
    fontSize: 12,
    color: theme.palette.text.lightGray,
  },
  outOfStockText: {
    color: theme.palette.text.lightGray,
    fontSize: 11,
    display: "inline-block",
  },
}));

const DynamicFieldFooter = ({
  handleOpenConfirmationModal,
  editMode,
  hasError,
  nextButton,
  onNextButton,
  onUpdateButton,
  orderButton,
  product,
  quantity,
  order,
  handleDownloadPdf,
  isCheckout,
  tab,
}) => {
  const classes = useStyles();
  const { stock, productType } = product;
  const { stockBalance, temporaryOutOfStock } = stock || {};

  const showSave = order || tab === 1;

  return (
    <div className={classes.dynamicFooter}>
      <Button
        className={classes.dynamicFooterLink}
        onClick={handleDownloadPdf}
        startIcon={
          <i className={`far fa-file-pdf ${classes.dynamicFooterLink}`} />
        }
      >
        <FormattedMessage id="product.showPdf" />
      </Button>
      {orderButton &&
        ((!stockBalance || stockBalance === 0 || temporaryOutOfStock) &&
        productType === STOCK_PRODUCT ? (
          <Box className={classes.outOfStockBox}>
            <Icon
              className={clsx([
                "fas fa-exclamation-circle",
                classes.outOfStockIcon,
              ])}
            />
            <Typography className={classes.outOfStockText}>
              <FormattedMessage id="product.outOfStock" />
            </Typography>
          </Box>
        ) : (
          <>
            {showSave && (
              <Button
                className={classes.btn}
                color="primary"
                disabled={hasError || !quantity}
                onClick={handleOpenConfirmationModal}
                size="small"
                variant="contained"
              >
                <FormattedMessage id="product.btnOrder" />
                <Icon
                  className={clsx(["fa fa-shopping-cart", classes.btnIcon])}
                />
              </Button>
            )}
          </>
        ))}
      {!showSave && (
        <Button
          className={classes.btn}
          color="primary"
          onClick={onNextButton}
          variant="contained"
          disabled={hasError}
        >
          <FormattedMessage id="btn.next" />
          <Icon
            className={clsx(["fa fa-long-arrow-alt-right", classes.btnIcon])}
          />
        </Button>
      )}
    </div>
  );
};

DynamicFieldFooter.defaultProps = {
  editMode: false,
  nextButton: false,
  onNextButton: () => {},
  onUpdateButton: () => {},
  orderButton: false,
  quantity: null,
};

DynamicFieldFooter.propTypes = {
  onUpdateButton: PropTypes.func,
  quantity: PropTypes.number,
  editMode: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  nextButton: PropTypes.bool,
  onNextButton: PropTypes.func,
  orderButton: PropTypes.bool,
  product: PropTypes.shape({
    stock: PropTypes.shape({
      stockBalance: PropTypes.number.isRequired,
      temporaryOutOfStock: PropTypes.bool.isRequired,
    }),
    productType: PropTypes.string.isRequired,
  }).isRequired,
  handleOpenConfirmationModal: PropTypes.func.isRequired,
  order: PropTypes.bool.isRequired,
  handleDownloadPdf: PropTypes.func.isRequired,
};

export default DynamicFieldFooter;

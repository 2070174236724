import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
//import MUIRichTextEditor from "mui-rte";

const useStyles = makeStyles(theme => ({
  wrap: {
    borderRadius: 4,
    border: "1px solid #ddd",
  },
  container: {
    marginTop: 0,
  },
  toolbar: {
    backgroundColor: "#f3f3f3",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 10,
    "& .MuiIconButton-root": {
      padding: 5,
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      marginRight: 8,
      border: "1px solid #ddd",
    },
  },
  editor: {
    fontSize: 12,
    padding: 0,
  },
  editorContainer: {
    fontSize: 12,
    padding: 15,
    marginTop: 0,
    width: "auto",
  },
  placeHolder: {
    position: "unset",
  },
}));

const Wysiwyg = ({ label }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
          {/*<MUIRichTextEditor
        label={label}
        inlineToolbar="true"
        // controls={["bold", "italic"]}
        // inlineToolbarControls={["bold", "italic"]}
        classes={{
          root: classes.root,
          container: classes.container,
          editor: classes.editor,
          editorContainer: classes.editorContainer,
          toolbar: classes.toolbar,
          placeHolder: classes.placeHolder,
        }}/>*/}
      
    </Box>
  );
};

export default Wysiwyg;

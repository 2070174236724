import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import WorkspaceIconErrorBoundary from "./WorkspaceIconErrorBoundary";

const IconPickerItem = lazy(() => import("../../../../common/ReactIconPickerItem"));

const WorkspaceIconPickerItem = ({
  color,
  containerStyles,
  icon,
  onClick,
  onError,
  size,
}) => (
  <WorkspaceIconErrorBoundary onError={onError}>
    <Suspense>
      <IconPickerItem
        color={color}
        containerStyles={containerStyles}
        icon={icon}
        onClick={onClick}
        size={size}
      />
    </Suspense>
  </WorkspaceIconErrorBoundary>
);

WorkspaceIconPickerItem.propTypes = {
  color: PropTypes.string,
  containerStyles: PropTypes.object,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  size: PropTypes.number,
};

export default WorkspaceIconPickerItem;

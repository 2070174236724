import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import "jstree/dist/jstree.min";
import "jstree/dist/themes/default/style.css";
import "./style.css";

class TreeView extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { treeData, setJsTree } = this.props;
    if (treeData) {
      setJsTree(this.myRef.current);
      $(this.myRef.current).jstree(treeData);
      $(this.myRef.current).on("changed.jstree", (e, data) => {
        const { onChange } = this.props;
        onChange(e, data);
        if (data && data.node && data.node.id) {
          $(this.myRef.current).jstree("toggle_node", data.node.id);
        }
      });
      $(this.myRef.current).on("open_node.jstree", (e, data) => {
        const { onOpen } = this.props;
        onOpen(e, data);
      });
      $(this.myRef.current).on("close_node.jstree", (e, data) => {
        const { onClose } = this.props;
        onClose(e, data);
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.treeData !== this.props.treeData;
  }

  componentDidUpdate() {
    const { treeData } = this.props;
    if (treeData) {
      $(this.myRef.current).jstree(true).settings = treeData;
      $(this.myRef.current)
        .jstree(true)
        .refresh();
    }
  }

  render() {
    return <div ref={this.myRef} />;
  }
}
TreeView.defaultProps = {
  onChange: () => false,
  onOpen: () => false,
  onClose: () => false,
  setJsTree: () => false,
};

TreeView.propTypes = {
  treeData: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  setJsTree: PropTypes.func,
};

export default TreeView;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import ModalBody from "./ModalBody";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1400px",
    height: "96vh",
    margin: 0,
    maxHeight: "unset",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: 0,
  },
}));

const AddFolderToBasketModal = ({
  open,
  handleClose,
  folder,
  openedNodes,
  initialFilesAddToCart,
  refetchFolders,
  initialFolderId,
  initialPackageGroup,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [initialFilesPackageGroup, setInitialFilesPackageGroup] = useState();
  const [initialFolderPackageGroup, setInitialFolderPackageGroup] = useState();

  useEffect(() => {
    if (initialPackageGroup) {
      const {
        folderId,
        items = [],
        packageName,
        propertyProductData = [],
        quantity,
      } = initialPackageGroup;

      let folderPropertyValueIds = [];

      let folderPropertyDataValues = null;
      if (
        Array.isArray(propertyProductData) &&
        propertyProductData.length > 0
      ) {
        folderPropertyDataValues = propertyProductData.map(y => {
          const { propertyName, propertyValue, propertyValueId } = y;

          folderPropertyValueIds = [...folderPropertyValueIds, propertyValueId];
          return {
            propertyName,
            propertyValue: {
              id: propertyValueId,
              name: propertyValue,
            },
          };
        });
      }

      const initialFolderProperties = {
        folderId,
        isNotifyEnabled: true,
        name: packageName,
        productionSettings: {
          orderQuantity: quantity,
          propertyDataValues: folderPropertyDataValues,
          propertyValueIds: folderPropertyValueIds,
        },
      };

      let initialItems = [];

      items.forEach(x => {
        const { propertyProductData: itemPropertyProductData = {} } = x;

        let itemQuantity = 0;
        let propertyValueIds = [];

        let propertyDataValues = null;
        if (
          Array.isArray(itemPropertyProductData) &&
          itemPropertyProductData.length > 0
        ) {
          propertyDataValues = itemPropertyProductData.map(y => {
            const {
              propertyName,
              propertyValue,
              propertyValueId,
              quantity: pQuantity,
            } = y;
            itemQuantity = pQuantity;
            propertyValueIds = [...propertyValueIds, propertyValueId];
            return {
              propertyName,
              propertyValue: {
                id: propertyValueId,
                name: propertyValue,
              },
            };
          });
        }

        const newFile = {
          // x.productId needs to be resourceId
          folderId,
          isNotifyEnabled: true,
          name: packageName,
          resourceId: x.productId,
          productionSettings: {
            orderQuantity: itemQuantity,
            propertyDataValues,
            propertyValueIds,
          },
        };
        initialItems = [...initialItems, newFile];
      });
      setInitialFilesPackageGroup([...initialItems]);

      setInitialFolderPackageGroup([initialFolderProperties]);
    }
  }, [initialPackageGroup]);

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={intl.formatMessage({
          id: "product.addFilesToCart",
        })}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <ModalBody
          folder={folder}
          handleClose={handleClose}
          initialFilesAddToCart={initialFilesAddToCart}
          initialFilesPackageGroup={initialFilesPackageGroup}
          initialFolderPackageGroup={initialFolderPackageGroup}
          initialFolderId={initialFolderId}
          initialPackageGroup={initialPackageGroup}
          openedNodes={openedNodes}
          refetchFolders={refetchFolders}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

AddFolderToBasketModal.defaultProps = {
  folder: {},
  openedNodes: [],
  initialFilesAddToCart: [],
  initialPackageGroup: null,
  initialFolderId: null,
};

AddFolderToBasketModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }),
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  initialFilesAddToCart: PropTypes.arrayOf(PropTypes.shape({})),
  refetchFolders: PropTypes.func.isRequired,
  initialPackageGroup: PropTypes.shape({
    folderId: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    propertyProductData: PropTypes.arrayOf(PropTypes.shape({})),
    packageName: PropTypes.string,
    quantity: PropTypes.number,
  }),
  initialFolderId: PropTypes.number,
};

export default AddFolderToBasketModal;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const NotificationTime = ({ type }) => {
  let time = "";
  if (type === "DAILY") {
    time = "monitoring.when.daily";
  }

  if (type === "IMMEDIATELY") {
    time = "monitoring.when.immediately";
  }

  if (type === "WEEKLY") {
    time = "monitoring.when.weekly";
  }
  return <FormattedMessage id={time} />;
};

NotificationTime.defaultProps = {
  type: null,
};

NotificationTime.propTypes = {
  type: PropTypes.string,
};

export default NotificationTime;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Icon, IconButton, Box } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import CustomTextField from "../../../../../common/TextField";

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: 0,
    marginBottom: 3,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  iconButtonDelete: {
    color: theme.palette.common.red,
    padding: 4,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
}));

const QuantityAwardRow = ({ onChange, quantity, price, onDeleteRow }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} md={5}>
          <CustomTextField
            type="number"
            name="quantity"
            value={quantity}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={5} md={5}>
          <CustomTextField
            type="number"
            name="price"
            value={price}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <IconButton
            className={classes.iconButtonDelete}
            onClick={onDeleteRow}
          >
            <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

QuantityAwardRow.propTypes = {
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
};

export default QuantityAwardRow;

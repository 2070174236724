import { gql } from '@apollo/client';

const findSuppliersForSiteSupplierRequest = gql`
  query FindSuppliersForSiteSupplierRequest(
    $siteId: Int!
    $searchTerm: String
  ) {
    supplierAdmin {
      findSuppliersForSiteSupplierRequest(
        siteId: $siteId
        searchTerm: $searchTerm
      ) {
        supplierId
        supplierName
        status
        formattedSupplierId
        supplierEmail
      }
    }
  }
`;

export default findSuppliersForSiteSupplierRequest;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Item } from "react-contexify";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  contextItem: {
    fontSize: 11,
    "&:hover": {
      "& .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
  },
  contextItemDisabled: {
    // [7563] pointerEvents: "none",
    "& .react-contexify__item__content": {
      opacity: 0.4,
      backgroundColor: "#7b505d",
    },
  },
}));

const ContextMenuItem = ({ onClick, children, disabled }) => {
  const classes = useStyles();

  return (
    <Item
      // Add classes.contextItemDisabled if item is disabled
      className={clsx([
        classes.contextItem,
        disabled ? classes.contextItemDisabled : "",
      ])}
      onClick={onClick}
      // [7563] disabled={disabled}
    >
      {children}
    </Item>
  );
};

ContextMenuItem.defaultProps = {
  onClick: null,
  children: null,
  disabled: null,
};

ContextMenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default ContextMenuItem;

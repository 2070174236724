import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, TextField} from "@mui/material";
import PropTypes from "prop-types";


const AddressRow = ({ accountSettings, setAccountSettings }) => {
  const { location } = accountSettings;



  const handleChange = e => {
    const { name, value } = e.target;
    setAccountSettings({ ...accountSettings, [name]: value });
  };


  return (
    <Box display="flex">
      <TextField
        label={<FormattedMessage id="settings.location" />}
        name="location"
        onChange={handleChange}
        value={location || ""}
        variant="standard"
        style={{ paddingRight: 5 }}
        size="small"
        margin="dense"
        disabled={false}
        fullWidth
      />
    </Box>
  );
};

AddressRow.defaultProps = {
  accountSettings: {},
};

AddressRow.propTypes = {
  accountSettings: PropTypes.shape({
    location: PropTypes.string,
  }),
  setAccountSettings: PropTypes.func.isRequired,
};

export default AddressRow;

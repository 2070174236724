import { gql } from '@apollo/client';

const GET_COMPANY_SALESDOCUMENT = gql`
  query getCompanySalesDocument($salesDocumentId: Int!) {
	companyAdminContext {
		salesDocuments {
			getSalesDocument(salesDocumentID: $salesDocumentId) {
				salesDocumentId
				documentNumber
				documentStatus
				documentType
				header {
					amountDue
					category
					paymentTermsDays
					companyAddressCardId
					documentDate
					dueDate
					gLN
					orgNo
					ourReference
					otherReferences
					address {
						address1
						address2
						address3
						address4
						address5
						city
						contact
						country
						customerId
						email
						reference
						telephone
						zip
					}
				}
				rows {
					amount
					articleNo
					description
					id
					price
					projectNo
					unit
					discount
					vAT
					article{
						articleNo
						description
						amount
						price
					}
				}
			}
		}
	}
}
`;

export default GET_COMPANY_SALESDOCUMENT;

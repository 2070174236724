import { gql } from '@apollo/client';

const GET_COMPANY_GROUPS = gql`
  query GetCompanyGroups($companyId: Int!) {
    companyAdminContext {
      companyGroupSettings {
        companyGroups(companyId: $companyId) {
          ...GroupFields
          ...ChildGroupRecursive
        }
      }
    }
  }

  fragment ChildGroupRecursive on CompanyGroup {
    childGroups {
      ...GroupFields
      childGroups {
        ...GroupFields
        childGroups {
          ...GroupFields
          childGroups {
            ...GroupFields
            childGroups {
              ...GroupFields
              childGroups {
                ...GroupFields
                childGroups {
                  ...GroupFields
                  childGroups {
                    ...GroupFields
                    childGroups {
                      ...GroupFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment GroupFields on CompanyGroup {
    groupId
    name
    parentGroupId
  }
`;

export default GET_COMPANY_GROUPS;

import { gql } from '@apollo/client';

const CREATE_CONVERSATION = gql`
  mutation CreateDirectMessageConversation($userIds: [Int!]) {
    directMessageContext {
      createConversation(userIds: $userIds) {
        directMessageConversationId
        members {
          name
          userId
          username
          avatarUrl
        }
      }
    }
  }
`;

export default CREATE_CONVERSATION;

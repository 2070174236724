import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NewsPostSkeleton from "./NewsPostSkeleton";

const useStyles = makeStyles(theme => ({
  divider: {
    background: props => props.dividerColor || "#dee2e6",
  },
  newsSub: {
    background: props => props.contentBG || theme.palette.background.paper,
    padding: 15,
  },
}));

const MainItemSkeleton = () => (
  <Grid item md={6}>
    <NewsPostSkeleton />
  </Grid>
);

const SubItemSkeleton = () => (
  <Grid item md={3} xs={12}>
    <NewsPostSkeleton />
  </Grid>
);

const TemplateOneSkeleton = ({ customStyles }) => {
  const classes = useStyles(customStyles);
  return (
    <Box className={classes.body}>
      <Grid container justifyContent="center">
        <MainItemSkeleton />
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.newsSub}>
        <SubItemSkeleton />
        <SubItemSkeleton />
        <SubItemSkeleton />
        <SubItemSkeleton />
      </Grid>
    </Box>
  );
};

export default TemplateOneSkeleton;

import React from "react";
import { FormattedMessage } from "react-intl";
import AddressHeaderTitle from "./AddressHeaderTitle";

const InvoiceAddressHeader = () => {
  return (
    <AddressHeaderTitle>
      <FormattedMessage id="cart.addressInvoice" />
    </AddressHeaderTitle>
  );
};

export default InvoiceAddressHeader;

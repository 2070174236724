import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const DELETE_NETWORK = gql`
  mutation DeleteNetwork($companyId: Int!, $networkId: Int!) {
    companyAdminContext {
      companyNetworks {
        deleteNetwork(companyId: $companyId, networkId: $networkId) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_NETWORK;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import SameSettingsSwitch from "./SameSettingsSwitch";
import "../../../../node_modules/react-splitter-layout/lib/index.css";

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  folderLabel: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  label: {
    fontSize: 14,
    color: theme.palette.text.lightGray,
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const SubHeader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.sectionTitle}>
      <Box className={classes.labelWrap}>
        <Typography className={classes.folderLabel} component="span">
          <FormattedMessage id="addFolderToBasket.selectFoldersToAddToCart" />
        </Typography>
        <Typography className={classes.label} component="span">
          | <FormattedMessage id="common.selectFolder" />
        </Typography>
      </Box>
    </Box>
  );
};

SubHeader.defaultProps = {
  includeSubfolders: false,
};

SubHeader.propTypes = {};

export default memo(SubHeader);

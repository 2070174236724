import React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import MyNotificationsTableRow from "./MyNotificationsTableRow";
import MyNotificationsTableHeader from "./MyNotificationsTableHeader";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  spinner: {
    marginTop: 5,
    color: theme.palette.primary.main,
  },
}));

const MyNotificationsTable = ({
  loading,
  savedNotifications,
  ascending,
  handleOnHeaderClick,
  sortField,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Table>
        <MyNotificationsTableHeader
          ascending={ascending}
          handleOnHeaderClick={handleOnHeaderClick}
          sortField={sortField}
        />
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell>
                <CircularProgress className={classes.spinner} size={20} />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            savedNotifications.map(x => {
              return <MyNotificationsTableRow notification={x} key={x} />;
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default MyNotificationsTable;

import { gql } from '@apollo/client';

const SAVE_IMAGE_BASKET = gql`
  mutation saveImageBasket($orderGroupId: Int!, $productId: ID!) {
    addToImageBank(orderGroupId: $orderGroupId, productId: $productId) {
      imageName
      itemId
      productId
      settings {
        selectedColorMode
        selectedFileFormat
        settingId
      }
    }
  }
`;

export default SAVE_IMAGE_BASKET;

import { gql } from '@apollo/client';

const dynamicTemplateImages = gql`
  query DynamicTemplateImages($productId: ID!, $folderId: Int!) {
    dynamicTemplateImages(productId: $productId, folderId: $folderId) {
      items {
        id
        name
        thumbnailImageUri
      }
      totalCount
    }
  }
`;

export default dynamicTemplateImages;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { Paper, Box, Typography, Button } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import IssuesTable from "../../components/Issues/IssuesTable";
import ShowColumnModal from "../../components/Issues/ShowColumnModal";
import IssueModal from "../../components/Issues/IssueModal";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 5,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 400,
    marginRight: 15,
    fontStyle: "italic",
  },
  btnAdd: {
    padding: "8px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 0,
    },
  },
  btnShow: {
    padding: "8px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  btnWrap: {
    marginLeft: "auto",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      textAlign: "center",
      marginTop: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
  paperBody: {
    padding: theme.spacing(2),
  },
}));

const initialColumnValues = {
  arende: true,
  kunds: true,
  typ: true,
  skapad: true,
  skapadAv: true,
  senastAndrad: true,
  tilldelad: true,
  comments: true,
  attachments: true,
  farg: true,
  prio: true,
  status: true,
  framsteg: true,
};

export default function Issues() {
  const classes = useStyles();

  const [columnModal, setColumnModal] = useState(false);
  const [showColumn, setShowColumn] = useState(initialColumnValues);
  const [openIssue, setOpenIssue] = useState(false);

  const handleToggleColumn = e => {
    const { name, checked } = e.target;
    setShowColumn({ ...showColumn, [name]: checked });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Box className={classes.paperHead}>
          <Typography variant="h1" component="h2" className={classes.title}>
            <FormattedMessage id="context.issues" />
          </Typography>
          <Typography className={classes.subTitle}>
            (3 st varav 2 olästa)
          </Typography>
          <Button className={classes.btnAdd} onClick={() => setOpenIssue(true)}>
            <FormattedMessage id="btn.newIssue" />
            <Icon className={clsx(["fa fa-plus-circle", classes.btnIcon])} />
          </Button>
          <Box className={classes.btnWrap}>
            <Button
              className={classes.btnShow}
              onClick={() => setColumnModal(true)}
            >
              <FormattedMessage id="btn.showCol" />
              <Icon className={clsx(["fa fa-eye", classes.btnIcon])} />
            </Button>
          </Box>
        </Box>
        <Box className={classes.paperBody}>
          <IssuesTable showColumn={showColumn} />
        </Box>
      </Paper>
      {columnModal && (
        <ShowColumnModal
          open={columnModal}
          setOpen={setColumnModal}
          showColumn={showColumn}
          handleToggleColumn={handleToggleColumn}
        />
      )}
      {openIssue && <IssueModal open={openIssue} setOpen={setOpenIssue} />}
    </div>
  );
}

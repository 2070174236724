import { gql } from '@apollo/client';

const GET_GROUP_MEMBERS = gql`
  query GetGroupMembers(
    $companyId: Int!
    $groupId: Int!
    $companyRoleId: Int
    $searchTerm: String
    $offset: Int
    $pagesize: Int
  ) {
    companyAdminContext {
      companyGroupSettings {
        getGroupMembers(
          companyId: $companyId
          groupId: $groupId
          companyRoleId: $companyRoleId
          searchTerm: $searchTerm
          offset: $offset
          pagesize: $pagesize
        ) {
          success
          errorResult {
            errorCode
            errorMessage
            data {
              key
              value
            }
          }
          totalUserCount
          companyUsersListItems {
            userId
            username
            firstname
            lastname
            avatar
            roleName
            groupBreadcrumbs {
              groups {
                groupId
                name
              }
            }
            subscriptionLevel
          }
        }
      }
    }
  }
`;

export default GET_GROUP_MEMBERS;

import { gql } from '@apollo/client';
import TimeReport from "../../fragments/Projects/TimeReport";
import ErrorResultFragment from "../../fragments/ErrorResult";

const REPORT_TIME = gql`
  mutation ReportTime(
    $input: ReportTaskTimeInput    
  ) {
    projects {
      reportTaskTime(
        input: $input        
      ) {
        success
        errorResult { ...ErrorResultFields }
        timeReport { ...TimeReportLog }
      }
    }
  }
  ${TimeReport}
  ${ErrorResultFragment}
`;

export default REPORT_TIME;

import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ProjectProgress from './ProjectProgress/ProjectProgress';

const steps = [
  'Uppstart',
  'Implementation',
  'Utbildning',
  'Uppföljning',
];

const HorizontalStepper = () => {
  return (
    <Paper elevation={1} sx={{ p: 2, backgroundColor: "#ffffff", borderRadius: "4px", flexGrow: 1, display: "flex" }}>
      <Box sx={{width: "100%"}}>
        <Typography variant="body1" sx={{ fontSize: "16px", padding: "0px 8px 8px 8px", margin: "0px", textAlign: "center" }}>Projektfaser och framsteg</Typography>
        <ProjectProgress />
      </Box>
    </Paper>
  );
}

export default HorizontalStepper;
import { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  Typography
} from "@mui/material";

import PageRoute from "../../../constant/pageRouteDictionary.json";
import { GET_SHAREABLE_FILES } from "../../../graphql/queries/ShareContext";

import ManageFolders from "../ManageRolesModal/ManageFolders";
import ResourceRightsList from "../ManageRolesModal/ResourceRightsList";
import ShareViewSelector from "./ShareViewSelector";

const findFolder = (folderId, folders) => {
  var found = folders.find(n => n.folderId == folderId);
  for (var i = 0; !found && i < folders.length; i++) {
    found = findFolder(folderId, folders[i].childFolders);
  }
  return found;
};

const findFolderPath = (folderId, folders) => {
  const length = folders.length;
  if (length === 0) {
    return undefined
  }
  const folder = folders.find(n => n.folderId == folderId);
  if (!!folder) {
    return [folder.folderId];
  }
  for (var i = 0; i < length; i++) {
    const folder = folders[i];
    const result = findFolderPath(folderId, folder.childFolders);
    if (!!result) {
      return [folder.folderId, ...result];
    }
  }
  return undefined;
};

const getRightsUpdateValues = ({ right, checked }) => {
  let updateValues = {};
  if (right === "write") {
    updateValues = checked
      ? { write: true, read: true }
      : { write: false };
  }
  else if (right === "read") {
    updateValues = checked
      ? { read: true }
      : { read: false, write: false };
  }
  return updateValues;
};

const handleCheckedParentFolder = (right, checked, folders) => {
  const updateValues = getRightsUpdateValues({ right, checked });
  let tempValues = {};
  folders.forEach(folder => {
    tempValues = {
      ...tempValues,
      [folder.folderId]: { ...tempValues[folder.folderId], ...updateValues },
    };
    if (folder.hasChildFolders) {
      const childFoldersData = handleCheckedParentFolder(
        right,
        checked,
        folder.childFolders
      );
      tempValues = { ...tempValues, ...childFoldersData };
    }
  });
  return tempValues;
};

const mapRightsChanges = (dict, keyName) => {
  const keys = Object.keys(dict);
  return keys.map(id => {
    return {
      [keyName]: id,
      read: dict[id].read || false,
      write: dict[id].write || false,
    };
  });
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdvancedShareSettingsModal = ({
  folders,
  initialFolderId,
  initialResourceId,
  initialView,
  onCancel,
  onSave,
  open,
}) => {
  const [getResourceRights, {
    data: resourceRightsData,
    loading: fetchingResourceRights
  }] = useLazyQuery(GET_SHAREABLE_FILES);

  const [folderRights, setFolderRights] = useState({});
  const [initialOpenFolders] = useState(findFolderPath(initialFolderId, folders));
  const [isValid, setIsValid] = useState(false);
  const [resourceRights, setResourceRights] = useState([]);
  const [resourceRightsChanges, setResourceRightsChanges] = useState({});
  const [selectedFolder, setSelectedFolder] = useState();
  const [validFolderSelection, setValidFolderSelection] = useState(false);
  const [validViewSelection, setValidViewSelection] = useState(false);
  const [viewSelection, setViewSelection] = useState({
    accessToFileView: initialView === PageRoute.FILE_VIEW,
    accessToProductView: initialView === PageRoute.PRODUCT_VIEW,
    accessToImageView: initialView === PageRoute.IMAGE_VIEW,
    accessToImageGalleryView: initialView === PageRoute.IMAGE_GALLERY_VIEW,
  });

  useEffect(() => {
    handleFolderSelect(initialFolderId);
  }, [initialFolderId]);

  useEffect(() => {
    var rights = resourceRightsData?.shareContext?.getShareableFiles;
    if (!!rights && rights != undefined) {
      setResourceRights(rights ?? []);
    }
  }, [resourceRightsData]);

  useEffect(() => {
    setIsValid(validFolderSelection && validViewSelection);
  }, [validFolderSelection, validViewSelection]);

  useEffect(() => {
    validateFolderSelection();
  }, [folderRights]);

  useEffect(() => {
    validateViewSelection();
  }, [viewSelection]);

  const fetchResourceRights = (folderId) => {
    getResourceRights({ variables: { folderId } });
  };

  const handleCancel = () => {
    onCancel?.();
  }

  const handleFolderRightsChange = (e, folder) => {
    e.stopPropagation();
    const { name, checked } = e.target;
    updateFolderRight({ folder, name, checked, updateChildFolders: true });
  };

  const handleFolderSelect = (folderId) => {
    const folder = findFolder(folderId, folders);
    setSelectedFolder(folder);
    fetchResourceRights(folderId);
  };

  const handleResourceRightsChange = ({ resourceId, name, value }) => {
    const updateValues = getRightsUpdateValues({ right: name, checked: value });

    if (updateValues.write) {
      updateFolderRight({ folder: selectedFolder, name: "write", checked: true, updateChildFolders: false });
    }
    else if (updateValues.read) {
      updateFolderRight({ folder: selectedFolder, name: "read", checked: true, updateChildFolders: false });
    }

    setResourceRightsChanges((prev) => {
      const updated = {
        ...prev,
        [resourceId]: {
          ...prev[resourceId],
          ...updateValues
        }
      };
      return updated;
    });
  };

  const handleSave = () => {
    onSave?.({
      folders: mapRightsChanges(folderRights, "folderId"),
      resourceRights: mapRightsChanges(resourceRightsChanges, "resourceId"),
      ...viewSelection
    });
  }

  const updateFolderRight = ({ folder, name, checked, updateChildFolders }) => {
    const id = folder.folderId;
    const updateValues = getRightsUpdateValues({ right: name, checked });

    if (folder.hasChildFolders && updateChildFolders) {
      let tempFolderRights = { ...folderRights };
      const childFoldersData = handleCheckedParentFolder(
        name,
        checked,
        folder.childFolders
      );

      tempFolderRights = {
        ...tempFolderRights,
        ...childFoldersData,
        [id]: { ...folderRights[id], ...updateValues },
      };

      setFolderRights(tempFolderRights);
    } else {
      setFolderRights({
        ...folderRights,
        [id]: { ...folderRights[id], ...updateValues },
      });
    }
  };

  const validateFolderSelection = () => {
    const isValid = mapRightsChanges(folderRights, "folderId").find(n => n.read || n.write) !== undefined;
    setValidFolderSelection(isValid);
  };

  const validateViewSelection = () => {
    const { accessToFileView, accessToProductView, accessToImageView, accessToImageGalleryView } = viewSelection;
    const isValid = accessToFileView || accessToProductView || accessToImageView || accessToImageGalleryView;
    setValidViewSelection(isValid);
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-slide-description"
      fullScreen
      keepMounted
      onClose={handleCancel}
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <FormattedMessage id="shares.shareFilesAndFolders" />
      </DialogTitle>

      <DialogContent spacing={2}>
        <DialogContentText>
          <Typography variant="span" sx={{ marginRight: "1em" }}>
            <FormattedMessage id="shares.selectViews" />
          </Typography>
          <ShareViewSelector
            onChange={(sel) => setViewSelection(sel)}
            viewSelection={viewSelection}
          />
        </DialogContentText>
        <DialogContentText sx={{ marginTop: "1em" }}>
          <FormattedMessage id="shares.selectFilesAndFolders" />
        </DialogContentText>
        <Grid container spacing={2} sx={{ paddingTop: "0.5em" }}>
          <Grid item xs={12} md={6}>
            <ManageFolders
              disabled={fetchingResourceRights}
              folderRights={folderRights}
              handleFolderRights={handleFolderRightsChange}
              initialOpenFolders={initialOpenFolders}
              onFolderSelect={handleFolderSelect}
              siteRoleFolders={folders}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {open && (
              <ResourceRightsList
                sx={{ fontSize: "12px", lineHeight: "17.5px", minHeight: "17.5px" }}
                disabled={fetchingResourceRights}
                onChange={handleResourceRightsChange}
                resourceRights={resourceRights}
                resourceRightsChanges={resourceRightsChanges}
                shareMode
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel}>
          <FormattedMessage id="btn.cancel" />
        </Button>
        <Button disabled={!isValid} onClick={handleSave}>
          <FormattedMessage id="btn.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AdvancedShareSettingsModal;

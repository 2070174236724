import { gql } from '@apollo/client';

const GET_WORKSPACE_ROLES = gql`
  query ListSiteRolesForSite($siteId: Int!) {
    companyAdminContext {
      companyWorkspaces {
        listSiteRolesForSite(siteId: $siteId) {
          label: name
          value: siteRoleId
        }
      }
    }
  }
`;

export default GET_WORKSPACE_ROLES;

import React from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import CustomTextField from "../../../../common/TextField";
import Footer from "./Footer";
import AddMenuButton from "./AddMenuButton";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  btnAdd: {
    padding: "5px 6px 5px 10px",
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
      opacity: 0.8,
    },
  },
  sidebarCustom: {
    padding: 10,
  },
  expansionDetails: {
    padding: 0,
  },
  expansionDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

export default function CustomLinksPanel() {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>
          <FormattedMessage id="siteRolesMenu.customLinks" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetails}>
        <Box className={classes.expansionDetailsContainer}>
          <Box className={classes.sidebarCustom}>
            <CustomTextField placeholder="URL" label="URL" />
            <CustomTextField
              placeholder={intl.formatMessage({
                id: "siteRolesMenu.linkText",
              })}
              label={intl.formatMessage({
                id: "siteRolesMenu.linkText",
              })}
            />
          </Box>
          <Footer>
            <AddMenuButton onClickSubmit={() => {}} />
          </Footer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";
import OrderGroup from "../fragments/OrderGroup";

const UPDATE_FILE_GROUPS = gql`
  mutation UpdateFileGroups($input: UpdateFileGroupInput) {
    shoppingCartContext {
      updateFileGroups(input: $input) {
        errorResult {
          ...ErrorResultFields
        }
        success
        shoppingCart {
          groups {
            ...OrderGroupFragment
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${OrderGroup}
`;

export default UPDATE_FILE_GROUPS;

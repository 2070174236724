import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import EditMenuSidebar from "./EditMenuSidebar";
import MainContent from "./MainContent";
import MainFooter from "./MainFooter";
import CustomSelect from "../../common/CustomSelect";

const useStyles = makeStyles(() => ({
  cardBody: {
    height: 600,
  },
  content: {
    // display: "flex",
    // flexDirection: "column",
  },
  container: {
    height: "100%",
  },
  startPage: {
    padding: 20,
  },
}));

const Body = ({
  handleAddToMenu,
  handleOnRemoveSortlyMenu,
  handlePageAddToMenu,
  handlePageCheckboxChange,
  handleSaveMenu,
  handleSelectDefaultStartPage,
  setSortlyMenu,
  siteMenuPages,
  sortlyMenu,
  handleRenameLabel,
  siteMenus,
  defaultStartPage,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [startPageOptions, setStartPageOptions] = useState([]);

  useEffect(() => {
    if (Array.isArray(sortlyMenu) && sortlyMenu.length > 0) {
      const startMenuPages = sortlyMenu
        .filter(menu => menu.sitePage !== "NONE")
        .map(x => {
          let newLabel = x.label;
          if (!x.label) {
            const tempLabel = intl.formatMessage({
              id: `pageKey.${x.sitePage}`,
            });
            newLabel = tempLabel;
          }
          return { label: newLabel, value: x.sitePage };
        });

      setStartPageOptions(startMenuPages);
    }
  }, [sortlyMenu]);

  return (
    <Box className={classes.cardBody}>
      <Grid container spacing={0} className={classes.container}>
        <EditMenuSidebar
          siteMenuPages={siteMenuPages}
          handlePageCheckboxChange={handlePageCheckboxChange}
          handlePageAddToMenu={handlePageAddToMenu}
          handleAddToMenu={handleAddToMenu}
        />

        <Grid item md={9} container>
          <Grid item xs={12} md={8}>
            <MainContent
              sortlyMenu={sortlyMenu}
              setSortlyMenu={setSortlyMenu}
              handleOnRemoveSortlyMenu={handleOnRemoveSortlyMenu}
              handleRenameLabel={handleRenameLabel}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.startPage}>
            <Typography>
              <FormattedMessage id="siteRoles.workspaceStartPage" />
            </Typography>
            <CustomSelect
              value={defaultStartPage}
              onChange={handleSelectDefaultStartPage}
              options={startPageOptions}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <MainFooter handleSaveMenu={handleSaveMenu} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

Body.defaultProps = {};

Body.propTypes = {
  handlePageCheckboxChange: PropTypes.func.isRequired,
  handleSaveMenu: PropTypes.func.isRequired,
  handlePageAddToMenu: PropTypes.func.isRequired,
  siteMenuPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortlyMenu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSortlyMenu: PropTypes.func.isRequired,
  handleOnRemoveSortlyMenu: PropTypes.func.isRequired,
  handleAddToMenu: PropTypes.func.isRequired,
  handleRenameLabel: PropTypes.func.isRequired,
};

export default Body;

import React, { memo } from "react";
import PropTypes from "prop-types";
import RowData from "./RowData";

const GroupItem = ({
  checkedGroups,
  group,
  groupTree,
  handleOpenNode,
  onFolderRowClick,
  selectedFolderIds,
  handleCheckboxChange,
}) => {
  const renderChildFolders = () => {
    if (group && group.childGroupIds && Array.isArray(group.childGroupIds)) {
      return (
        <>
          {group.childGroupIds.map(x => (
            <GroupItem
              key={`group-item-${x}`}
              checkedGroups={checkedGroups}
              group={groupTree[`g_${x}`]}
              groupTree={groupTree}
              handleOpenNode={handleOpenNode}
              onFolderRowClick={onFolderRowClick}
              selectedFolderIds={selectedFolderIds}
              handleCheckboxChange={handleCheckboxChange}
            />
          ))}
        </>
      );
    }
    return null;
  };

  if (
    group &&
    group.childGroupIds &&
    Array.isArray(group.childGroupIds) &&
    group.childGroupIds.length > 0
  ) {
    return (
      <RowData
        checkedGroups={checkedGroups}
        group={group}
        groupTree={groupTree}
        handleOpenNode={handleOpenNode}
        onFolderRowClick={onFolderRowClick}
        selectedFolderIds={selectedFolderIds}
        handleCheckboxChange={handleCheckboxChange}
      >
        {renderChildFolders()}
      </RowData>
    );
  }

  return (
    <RowData
      checkedGroups={checkedGroups}
      group={group}
      groupTree={groupTree}
      handleOpenNode={handleOpenNode}
      onFolderRowClick={onFolderRowClick}
      selectedFolderIds={selectedFolderIds}
      handleCheckboxChange={handleCheckboxChange}
    />
  );
};

GroupItem.defaultProps = {
  handleOpenNode: () => {},
  selectedNode: null,
};

GroupItem.propTypes = {
  selectedNode: PropTypes.shape({}),
  group: PropTypes.shape({
    childGroups: PropTypes.arrayOf(
      PropTypes.shape({
        groupId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    groupId: PropTypes.number.isRequired,
    hasChildGroups: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  checkedGroups: PropTypes.arrayOf(PropTypes.number).isRequired,
  groupTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleOpenNode: PropTypes.func,
  onFolderRowClick: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default memo(GroupItem);

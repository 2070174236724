import { handleActions } from "redux-actions";
import { currentViewer, setCurrentUser } from "../../../../actions";

const defaultState = {};

export default handleActions(
  {
    [currentViewer]: (state, { payload }) => {
      const { viewer } = payload;
      return {
        ...state,
        viewer,
      };
    },
    [setCurrentUser]: (state, { payload }) => {
      const { user } = payload;
      return {
        ...state,
        viewer: {
          ...state.viewer,
          ...user,
        },
      };
    },
  },
  defaultState
);

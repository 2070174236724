import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_BASKET_GROUP } from "../graphql/mutations";
import { cartInitialize } from "../actions";
import { getSelectedSiteOrderGroupId } from "../helpers/selectors";

// eslint-disable-next-line import/prefer-default-export
export const useDeleteBasketGroup = ({
  errorRemoveCart,
  successRemoveCart,
}) => {
  const [deleteGroup, { loading }] = useMutation(DELETE_BASKET_GROUP);
  const dispatch = useDispatch();
  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const handleRemoveGroup = async ({ packageId }) => {
    try {
      const response = await deleteGroup({
        variables: {
          packageId,
        },
      });
      if (
        response &&
        response.data &&
        response.data.shoppingCartContext.deleteBasketGroup &&
        response.data.shoppingCartContext.deleteBasketGroup.success
      ) {
        const {
          result = {},
        } = response.data.shoppingCartContext.deleteBasketGroup;
        const { groups = [] } = result;
        const orderGroup = groups.find(x => x.orderGroupId === ordergroupId);
        const { items = [], fileGroups = [], oneStreamGroups = [] } =
          orderGroup || {};
        successRemoveCart();
        dispatch(
          cartInitialize({
            items,
            fileGroups,
            oneStreamGroups,
          })
        );
      } else {
        errorRemoveCart();
      }
    } catch (e) {
      errorRemoveCart();
    }
  };

  return { execute: handleRemoveGroup, loading };
};

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPDATE_OVERVIEW_DASHBOARD = gql`
  mutation UpdateOverviewDashboard(
    $id: Int
    $filter: DashboardType!
    $jsonContent: String
    $dashboardSettings: DashboardSettingsInput!
  ) {
    dashboardContext {
      updateOverviewDashboard(
        id: $id
        filter: $filter
        jsonContent: $jsonContent
        dashboardSettings: $dashboardSettings
      ) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_OVERVIEW_DASHBOARD;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 15,
    borderRadius: 3,
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    backgroundColor: theme.palette.common.white,
  },
  skeletonWrap: {
    backgroundColor: "#fff",
    padding: "10px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  skeletonImg: {
    marginRight: 15,
    borderRadius: 4,
    marginLeft: 18,
  },
  skeleton: {
    borderRadius: 4,
  },
  sortWrap: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    paddingBottom: 15,
    display: "flex",
    flexDirection: "row",
  },
  skeletonRight: {
    borderRadius: 4,
    marginLeft: "auto",
  },
}));

const MaterialTableSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.sortWrap}>
        <Skeleton
          variant="rect"
          width={220}
          height={35}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
        <Skeleton
          variant="rect"
          width={160}
          height={35}
          animation="wave"
          classes={{ root: classes.skeletonRight }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width={32}
          height={32}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width={32}
          height={32}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width={32}
          height={32}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width={32}
          height={32}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width={32}
          height={32}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
    </Box>
  );
};

export default MaterialTableSkeleton;

import { gql } from '@apollo/client';

const getSiteUsersForSite = gql`
  query GetSiteUsersForSite(
    $siteId: Int!
    $filter: SiteUserListingFilterType!
    $ascending: Boolean
    $sortfield: SortField
  ) {
    siteUsers {
      listSiteUsersForSite(
        siteId: $siteId
        filter: $filter
        ascending: $ascending
        sortfield: $sortfield
      ) {
        avatar
        companyName
        email
        telephone
        id
        lastLoggedIn
        name
        paymentLiability
        siteId
        siteRoleId
        siteRoleName
        status
      }
    }
  }
`;

export default getSiteUsersForSite;

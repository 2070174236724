export const initialSiteRoleSettings = {
  categoryTreeIndentation: false,
};

export const initialRights = {
  allowMetadataTagging: false,
  bookingView: false,
  canAddToBasket: false,
  canAccessRequestForProposal: false,
  canChangeFolderIcons: false,
  canCreateNotificationForOtherUsers: false,
  canCreatePackages: false,
  canEditNews: false,
  canExportSiteTemplates: false,
  canInviteUsers: false,
  canLock: false,
  canDownloadFilesAndFolders: false,
  canManageBookingView: false,
  canManageSite: false,
  canManageSiteRoles: false,
  canManageSuppliers: false,
  canNotSeeOtherSuppliers: false,
  canOnlySeeAssignedProducts: false,
  canPrepareOrderingInfo: false,
  canUnlockForOthers: false,
  canPublishProducts: false,
  createTemplate: false,
  detailedImageView: false,
  directoryCRUD: false,
  dynamicTemplates: false,
  fileView: false,
  images: false,
  imageView: false,
  notifications: false,
  onlineDesignerDocs: false,
  otherDocs: false,
  productView: false,
  shareFolders: false,
  staticTemplates: false,
  stockTemplates: false,
  supplierInWorkspace: false,
  tenderFolders: false,
  canManageConsent: false,
  canManageConsentDocuments: false,
};

export const getChildFolderRights = ({ folder }) => {
  let folderTree = {};
  if (folder && folder.childFolders) {
    folder.childFolders.forEach(childFolder => {
      const childFolderTreeData = getChildFolderRights({
        folder: childFolder,
      });
      folderTree = {
        ...folderTree,
        ...childFolderTreeData,
      };
    });
  }
  folderTree = {
    ...folderTree,
    [folder.folderId]: {
      write: folder.write || false,
      read: folder.read || false,
    },
  };
  return folderTree;
};

export const getFolderRights = ({ folders }) => {
  let folderTree = {};
  if (folders && Array.isArray(folders)) {
    folders.forEach(folder => {
      folder.childFolders.forEach(childFolder => {
        const childFolderTreeData = getChildFolderRights({
          folder: childFolder,
        });
        folderTree = {
          ...folderTree,
          ...childFolderTreeData,
        };
      });
      folderTree = {
        ...folderTree,
        [folder.folderId]: {
          write: folder.write || false,
          read: folder.read || false,
        },
      };
    });
  }
  return folderTree;
};

export const settingKeys = {
  workSpaceSettings: {
    canCreateNotificationForOtherUsers: "",
    canInviteUsers: "",
    canManageSiteRoles: "",
    canManageSite: "",
    canManageWorkspaceDashboards: "",
  },
  orderingModule: {
    productView: "",
    createTemplate: "",
    canCreatePackages: "",
    canPublishProducts: "",
    canAttestShoppingCart: "",
    canEditOrderHistory: "",
    canAddToExistingOrder: "",
    canManageSuppliers: "",
    supplierInWorkspace: "",
    canOnlySeeAssignedProducts: "",
    canNotSeeOtherSuppliers: "",

    // createPublications: "",
  },
  documentCollaboration: {
    fileView: "",
    productView: "",
    imageView: "",
    detailedImageView: "",

    showOnlyOwnFiles: "",
    canUploadFiles: "",
    attestFiles: "",
    uploadFilesWithoutAttest: "",
    canUnlockForOthers: "",
    shareFolders: "",
    canLock: "",
    canDownloadFilesAndFolders: "",
    directoryCRUD: "",
    canChangeFolderIcons: "",
    // folderColorScheme: "",
    dynamicTemplates: "",
    images: "",
    onlineDesignerDocs: "",
    staticTemplates: "",
    stockTemplates: "",
    otherDocs: "",
  },
  booking: {
    bookingView: "",
    canManageBookingView: "",
  },
  procurement: {
    canAccessRequestForProposal: "",
    canManageRequestForProposal: "",
  },
  monitoring: {
    notifications: "",
  },
  newsPost: {
    canEditNews: "",
  },
  manageConsent: {
    canManageConsent: "",
    canManageConsentDocuments: "",
  },
};

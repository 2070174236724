import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DELETE_FOLDER = gql`
  mutation DeleteFolder($folderId: Int!) {
    deleteFolder(folderId: $folderId) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_FOLDER;

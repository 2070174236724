import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/client";
import { GET_TAG_DATA } from "../../../graphql/queries";
import Tags from "./Tags";
import TagLetters from "./TagLetters";
import WidgetLabel from "../WidgetLabel";

const SiteFolderWidget3 = ({ siteId }) => {
  const [tagData, setTagData] = useState([]);
  const [tagLetters, setTagLetters] = useState([]);
  const [letter, setletter] = useState();
  const [tags, setTags] = useState([]);

  // TODO: minTagUsage is hardcoded.
  const { data } = useQuery(GET_TAG_DATA, {
    variables: {
      siteId,
      minTagUsage: 1,
    },
    skip: !siteId,
  });

  useEffect(() => {
    if (data && data.widgets && data.widgets.getTagData) {
      setTagData(data.widgets.getTagData);
      // Get all the unique letters in getTagData
      const letters = data.widgets.getTagData.reduce((unique, item) => {
        return unique.includes(item.letter) ? unique : [...unique, item.letter];
      }, []);
      setTagLetters(letters);
      if (letters && Array.isArray(letters) && letters.length > 0) {
        setletter(letters[0]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (letter && tagData) {
      const tempTags = tagData.reduce((lists, item) => {
        return item.letter === letter ? [...lists, ...item.tags] : lists;
      }, []);
      setTags(tempTags);
    }
  }, [letter]);

  return (
    <Box>
      <WidgetLabel title="Taggar" />
      <TagLetters tagLetters={tagLetters} setletter={setletter} />
      <Tags tags={tags} />
    </Box>
  );
};

SiteFolderWidget3.defaultProps = {
  siteId: null,
};

SiteFolderWidget3.propTypes = {
  siteId: PropTypes.number,
};

export default SiteFolderWidget3;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Card";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: "10px 0",
  },
  searchWrap: {
    maxHeight: "70vh",
    overflowY: "auto",
  },
}));

const ViewContainer = ({ children, isSearch }) => {
  const classes = useStyles();

  return (
    <div className={clsx([isSearch && classes.searchWrap])}>
      <Box className={classes.root}>{children}</Box>
    </div>
  );
};

ViewContainer.propTypes = {
  isSearch: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ViewContainer;

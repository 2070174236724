import React from "react";
import {
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  listRoot: {
    padding: 0,
  },
  listIcon: {
    minWidth: "unset",
    marginRight: 5,
  },
  listItem: {
    padding: "5px 10px",
  },
  listText: {
    fontSize: 12,
    color: "#000",
  },
}));

const SettingsPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleOpenCreateChannel,
}) => {
  const classes = useStyles();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <List classes={{ root: classes.listRoot }}>
        <ListItem
          classes={{ root: classes.listItem }}
          button
          onClick={handleOpenCreateChannel}
        >
          <ListItemIcon classes={{ root: classes.listIcon }}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listText }}
            primary="Create a channel"
          />
        </ListItem>
      </List>
    </Popover>
  );
};

SettingsPopover.defaultProps = {
  id: undefined,
  anchorEl: null,
};

SettingsPopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpenCreateChannel: PropTypes.func.isRequired,
  anchorEl: PropTypes.node,
};

export default SettingsPopover;

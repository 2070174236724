import { gql } from '@apollo/client';

const CREATE_CHANNEL = gql`
  mutation CreateSiteChannel(
    $siteId: Int!
    $name: String!
    $isPrivate: Boolean!
  ) {
    siteChannelContext {
      createChannel(siteId: $siteId, name: $name, isPrivate: $isPrivate) {
        name
        private
        siteChannelId
        workspaceName
        unreadMessageCount
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
      }
    }
  }
`;

export default CREATE_CHANNEL;

import React, { memo } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  checkbox: { padding: "3px !important" },
  checkboxCont: {
    position: "absolute",
    right: 0,
  },
}));

const RightsCheckboxes = ({
  disabled,
  folder,
  handleCheckboxChange,
  read,
  write,
}) => {
  const classes = useStyles();

  const onChange = e => handleCheckboxChange(e, folder);

  const onClick = e => e.stopPropagation();

  return (
    <div className={classes.checkboxCont}>
      <Checkbox
        name="write"
        checked={write}
        className={classes.checkbox}
        color="primary"
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
      <Checkbox
        name="read"
        checked={read}
        className={classes.checkbox}
        color="primary"
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
    </div>
  );
};

RightsCheckboxes.defaultProps = {
  disabled: false,
  read: false,
  write: false,
};

RightsCheckboxes.propTypes = {
  disabled: PropTypes.bool,
  folder: PropTypes.shape({}).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  read: PropTypes.bool,
  write: PropTypes.bool,
};

export default memo(RightsCheckboxes);

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPDATE_ACCOUNT_PASSWORD = gql`mutation updateAccountPassword($model:UpdatePasswordInput){
	userAccountContext{		
		updateAccountPassword(model:$model){
			success
			errorResult{
				...ErrorResultFields
			}
		}
	}
}
${ErrorResultFragment}
`;

export default UPDATE_ACCOUNT_PASSWORD;

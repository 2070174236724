import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import CustomTextField from "../../common/TextField";

const useStyles = makeStyles(theme => ({
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #e6e6e6",
    marginBottom: 10,
    "& .MuiInputBase-root": {
      marginTop: 5,
    },
    "& .MuiTextField-root": {
      marginTop: 0,
    },
    "& .MuiCheckbox-root": {
      padding: "4px 9px",
    },
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  labelWrap: {
    display: "flex",
    alignItems: "center",
    "& .MuiTextField-root": {
      width: "auto",
      marginLeft: 15,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  subLabel: {
    fontSize: 12,
    marginLeft: "auto",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  grayLink: {
    fontSize: 12,
    color: "#555",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  blueLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  child: {
    paddingLeft: 27,
  },
}));

const Reservations = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.card}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography className={classes.heading}>Reservations</Typography>
            </Grid>
          </Grid>
          <Box mt={2} mb={1} />
          <Typography className={classes.label}>
            Fixed Booking Duration (days)
          </Typography>
          <CustomTextField />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Reservations;

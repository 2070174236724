export const prisValidation = errors => {
  let hasErrors = false;

  if (!errors) return false;

  if (errors.vatRateId) {
    hasErrors = true;
  }

  return hasErrors;
};

export const artikelInformationValidation = errors => {
  let hasErrors = false;

  if (!errors) return false;

  if (errors.name) {
    hasErrors = true;
  }

  return hasErrors;
};

export const isFieldRequired = (fieldName, selectedProductType) => {
  if (selectedProductType === "PRODUCT_CONTAINER") {
    return false;
  }

  if (fieldName === "name" || fieldName === "vatRateId") {
    return true;
  }

  return false;
};

export const formValidation = (
  formValues,
  fieldIsRequired,
  selectedProductType
) => {
  let tempErrors = {};

  if (
    isFieldRequired("vatRateId", selectedProductType) &&
    !formValues.vatRateId
  ) {
    tempErrors = { ...tempErrors, vatRateId: fieldIsRequired };
  }

  if (isFieldRequired("name", selectedProductType) && !formValues.name) {
    tempErrors = { ...tempErrors, name: fieldIsRequired };
  }
  return tempErrors;
};

export const initialManualPrice = {
  price: 0,
  quantity: 1,
};

export const initialFormValues = {
  actionValue: "",
  active: false,
  articleNo: "",
  allowDownload: false,
  activeFromDate: null,
  activeUntilDate: null,
  format: "",
  material: "",
  weight: null,
  detailedDescription: "",
  description: "",
  name: "",
  unit: null,
  pageRange: null,
  showFormat: false,
  showPrice: false,
  allowFreeQuantity: false,
  pricesInclusiveFreight: false,
  orderingType: "BOOKABLE",
  imageMetadata: {},
  buyPrices: {
    initialCosts: 0,
    manualPrices: [{ ...initialManualPrice }],
  },
  sellPrices: {
    initialCosts: null,
    manualPrices: [{ ...initialManualPrice }],
  },
  siteSuppliers: [],
  supplierIds: [],
  vateRates: [],
  vatRateId: 37,
  tags: [],
};

export const initialBooking = {
  allowFreeBooking: false,
  bookingDays: 0,
};

export const initialMetadataValues = {
  contact: "",
  date: new Date(),
  imageNo: "",
  isFreeToUser: false,
  location: "",
  model: "",
  occupation: "",
  photographer: "",
};

export const getBuyManualPrices = formValues => {
  const { buyPrices = {} } = formValues;
  const { manualPrices = [] } = buyPrices;
  if (!Array.isArray(manualPrices)) return [];
  return manualPrices;
};

export const getSellManualPrices = formValues => {
  const { sellPrices = {} } = formValues;
  const { manualPrices = [] } = sellPrices;
  if (!Array.isArray(manualPrices)) return [];
  return manualPrices;
};

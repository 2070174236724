import { gql } from '@apollo/client';
import AddressFragment from "../../fragments/Address";

const getIncomingOrders = gql`
  query GetIncomingOrders($model: IncomingOrderParams) {
    incomingOrdersContext {
      incomingOrders(model: $model) {
        items {
          supplierOrderId
          supplierOrderNo
          comment
          companyOrderno
          costPlaceOrder
          deliveryDateWish
          deliveryAddress {
            ...AddressFields
          }
          invoiceAddress {
            ...AddressFields
          }
          orderDate
          ordererAddress {
            email
          }
          orderId
          status
          sumInclVat
          username
        }
        totalResult
      }
    }
  }
  ${AddressFragment}
`;

export default getIncomingOrders;

import React, { useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";

const PrimaryButton = ({disabled, label, onClick}) => (
  <Button aria-label="default action" color="primary" disabled={disabled} onClick={onClick} variant="contained">{label}</Button>
);

const SplitButton = ({ disabled, onClick, options }) => {
  const anchorRef = useRef(null);

  const [openPopper, setOpenPopper] = useState(false);

  const handleClick = (event, index = 0) => {
    const { type } = options[index];
    onClick?.({ type });
    setOpenPopper(false);
  };

  const handleClickAway = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
  };

  const renderPrimaryButton = () => (
    <PrimaryButton disabled={disabled || options[0].disabled} label={options[0].label} onClick={handleClick} />
  );

  if (options.length === 0) {
    return null;
  }

  if (options.length === 1) {
    return renderPrimaryButton();
  }

  return (
    <>
      <ButtonGroup
        aria-label="actions group"
        color="primary"
        disabled={disabled}
        ref={anchorRef}
        variant="contained"
      >
        {renderPrimaryButton()}
        <Button
          aria-controls={openPopper ? "split-button-menu" : undefined}
          aria-expanded={openPopper ? "true" : undefined}
          aria-haspopup="menu"
          aria-label="actions"
          color="primary"
          onClick={() => setOpenPopper(open => !open)}
          size="small"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={openPopper}
        role={undefined}
        sx={{ zIndex: 2 }}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList id="split-button-menu">
                  {options.slice(1).map((option, index) => (
                    <MenuItem
                      disabled={option.disabled}
                      key={option}
                      onClick={e => handleClick(e, index + 1)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;

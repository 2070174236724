import { gql } from '@apollo/client';

const GET_CONVERSATIONS = gql`
  query getConversations {
    directMessages {
      conversations {
        directMessageConversationId
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
        members {
          name
          userId
          username
          avatarUrl
        }
        unreadMessageCount
      }
    }
  }
`;

export default GET_CONVERSATIONS;

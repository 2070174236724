const options = {
  position: "top center",
  timeout: 4000,
  offset: "70px",
  transition: "scale",
  containerStyle: {
    zIndex: 99999,
  },
};

export default options;

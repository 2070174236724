import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  biographyField: {
    marginTop: 4,
    marginBottom: 2,
    "& div.MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0)",
    },
  },
  input: {},
}));

const BioDescription = ({ userDetails }) => {
  const { biography } = userDetails || {};

  const classes = useStyles();

  return (
    <Box display="flex" className={classes.input}>
      {biography && (
        <TextField
          className={classes.biographyField}
          label={<FormattedMessage id="settings.biography" />}
          name="biography"
          value={biography || ""}
          variant="standard"
          style={{ paddingRight: 5 }}
          size="small"
          margin="dense"
          disabled
          fullWidth
        />
      )}
    </Box>
  );
};

export default BioDescription;

import React, { useState, useEffect } from "react";
import { CircularProgress, Grid, Box, Stepper, StepLabel, Step, Typography  } from "@mui/material";
import TOTPQR from './TOTPQR';
import { MuiOtpInput } from 'mui-one-time-password-input';
import useTheme from "@mui/material/styles/useTheme";

const AuthenticatorSetupContent = ({
  activeStep,
  username,  
  secret,  
  otp,
  onOtpChange

}) => {

  const theme = useTheme();

  const stepColor =
  {
    '& .MuiStepLabel-iconContainer .Mui-completed': {
      color: '#00c9b7', // text color (COMPLETED)
    },
    '& .MuiStepLabel-labelContainer .Mui-completed': {
      color: 'rgba(0, 0, 0, 0.87)', // text color (COMPLETED)
      fontWeight: 600,
    },
    '& .MuiStepLabel-iconContainer .Mui-active': {
      color: '#00c9b7', // circle color (ACTIVE)
    },
    '& .MuiStepLabel-labelContainer .Mui-active': {
      color: 'rgba(0, 0, 0, 0.87)', // circle color (ACTIVE)
      fontWeight: 600,
    },
    '& .MuiStepIcon-text': {
      fontWeight: 600,
    },
  };

  const informationText = {
    marginTop: "1em",
    fontSize: "14px",
    lineHeight: "18px",
  };

  const informationTextItalic = {
    marginTop: "1em",
    fontSize: "14px",
    lineHeight: "18px",
    fontStyle: 'italic',
  };

  const steps = ['Skanna', 'Verifiera', 'Slutför'];

  return (<>

    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps = {};
        const labelProps = {};

        return (
          <Step sx={stepColor} key={label} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
    <Grid container spacing={2} sx={{ padding: 2 }}>

      {activeStep === 0 && (<>
        <Grid item xs={6}>
          <Box align="middle">
            {secret && (
              <TOTPQR size={200} username={username} secret={secret} issuer={"UniQueue"} />)}
            {!secret && (<CircularProgress size={200} />)}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={informationText}>Skanna QR-koden med din autentiseringsapp, t.ex. Microsoft Authenticator, i din mobila enhet.</Typography>
          <Typography sx={informationTextItalic}>Du kan även ange koden som finns under QR-koden direkt i din autentiseringsapp.</Typography>
          <Typography sx={informationText}>Detta länkar samman autentiseringsappen med ditt konto.</Typography>
        </Grid>
      </>)}

      {activeStep === 1 && (
        <>
          <Grid item xs={12}>Ange koden från din app här</Grid>
          <Grid item xs={12}><MuiOtpInput value={otp} onChange={onOtpChange} length={6} /></Grid>
        </>
      )}

      {activeStep === 2 && (
        <Grid item xs={12}>Din Authenticator är verifierad! Nu kan vi koppla den till ditt konto.</Grid>
      )}
    </Grid>

  </>);
}

export default AuthenticatorSetupContent;
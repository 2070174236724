import { gql } from '@apollo/client';

const WorkspaceOrderGroupCandidateListItemFragment = gql`
  fragment WorkspaceOrderGroupCandidateListItem on WorkspaceOrderGroupCandidateListItem {
    orderGroupId
    displayName
  }
`;

export default WorkspaceOrderGroupCandidateListItemFragment;

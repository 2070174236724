import { gql } from '@apollo/client';

const DELETE_RESOURCE = gql`
  mutation DeleteResource($resourceId: ID!) {
    deleteResource(resourceId: $resourceId) {
      success
      errorResult {
        data {
          key
          value
        }
        errorCode
        errorMessage
      }
    }
  }
`;

export default DELETE_RESOURCE;

import PropTypes from "prop-types";
import TreeItem from "@mui/lab/TreeItem";
import { makeStyles } from "@mui/styles";
import { StructureLabel } from "../../../components/common/WorkspaceList";

const useStyles = makeStyles(theme => ({
  item: {
    "& .MuiTreeItem-label": {
      fontSize: 12,
      color: "#5b5654",
    },
    "& .drop-before": {
      backgroundColor: "transparent",
      borderTop: `2px solid ${theme.palette.primary.dark}`,
      marginTop: "-2px",
    },
    "& .MuiTreeItem-iconContainer": {
      order: 2,
    },
  },
}));

const StructureItem = ({
  handleContextMenu,
  item,
  onMoveFolder,
  onMoveWorkspace,
}) => {
  const { id: nodeId } = item;

  const classes = useStyles();

  const getPath = item => {
    var path = [];
    var n = item;
    while (n !== null) {
      path.push(n.id);
      n = n.parentNode;
    }
    return path.reverse();
  };

  const handleDragStart = (e, sourceItem) => {
    e.stopPropagation();

    e.effectAllowed = "move";

    var sourcePath = getPath(sourceItem);

    e.dataTransfer.setData("path", JSON.stringify(sourcePath));
    e.dataTransfer.setData("action", "MOVE_WORKSPACE");
  };

  const handleDragOver = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    var target = e.target;

    target.classList.add("drop-before");
  };

  const handleDragLeave = e => {
    e.preventDefault();
    removeDropClasses(e.target);
  };

  const removeDropClasses = target => {
    target.classList.remove("drop-before");
  };

  const handleDrop = (e, targetItem) => {
    e.stopPropagation();

    var target = e.target;

    removeDropClasses(target);

    var relativePosition = -1;

    const action = e.dataTransfer.getData("action");
    const sourcePath = JSON.parse(e.dataTransfer.getData("path"));

    var targetPath = getPath(targetItem);

    if (action === "MOVE_WORKSPACE" && !!onMoveWorkspace) {
      onMoveWorkspace({ sourcePath, targetPath, relativePosition });
    }
    if (action === "MOVE_FOLDER" && !!onMoveFolder) {
      onMoveFolder({ sourcePath, targetPath, relativePosition });
    }
  };

  return (
    <TreeItem
      className={classes.item}
      draggable
      label={<StructureLabel item={item} />}
      nodeId={`${nodeId}`}
      onFocusCapture={e => e.stopPropagation()}
      onContextMenu={e => handleContextMenu(e, item)}
      onDragLeave={e => handleDragLeave(e, item)}
      onDragOver={e => handleDragOver(e, item)}
      onDragStart={e => handleDragStart(e, item)}
      onDrop={e => handleDrop(e, item)}
    />
  );
};

StructureItem.defaultProps = {
  handleContextMenu: e => {
    e.preventDefault();
    e.stopPropagation();
  },
};

StructureItem.propTypes = {
  handleContextMenu: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onMoveFolder: PropTypes.func.isRequired,
  onMoveWorkspace: PropTypes.func.isRequired,
};

export default StructureItem;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Table, TableBody } from "@mui/material";
import IncomingRequestsHeader from "./IncomingRequestsHeader";
import IncomingRequestRow from "./IncomingRequestsRow";
import TableRowLoading from "../Table/TableRowLoading";

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #f1f1f1",
    marginTop: 20,
  },
}));

const IncomingRequests = ({
  loading,
  incomingSupplierRequests,
  getCompanySupplierSettings,
  companyId,
}) => {
  const classes = useStyles();

  const [supplierRequests, setSupplierRequests] = useState([]);

  useEffect(() => {
    if (
      incomingSupplierRequests &&
      Array.isArray(incomingSupplierRequests.items)
    ) {
      setSupplierRequests(incomingSupplierRequests.items);
    }
  }, [incomingSupplierRequests]);

  return (
    <Table className={classes.root}>
      <IncomingRequestsHeader />
      <TableBody>
        {loading ? (
          <TableRowLoading />
        ) : (
          <>
            {supplierRequests.map((x, idx) => {
              return (
                <IncomingRequestRow
                  workspace={x}
                  idx={idx}
                  getCompanySupplierSettings={getCompanySupplierSettings}
                  companyId={companyId}
                />
              );
            })}
          </>
        )}
      </TableBody>
    </Table>
  );
};

IncomingRequests.defaultProps = {
  incomingSupplierRequests: [],
  loading: false,
  getCompanySupplierSettings: null,
};
IncomingRequests.propTypes = {
  loading: PropTypes.bool,
  incomingSupplierRequests: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  getCompanySupplierSettings: PropTypes.func,
};

export default IncomingRequests;

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import CustomSelect from "../../../../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  labelHead: {
    fontSize: 12,
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
  },
  panelHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSelect-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const ProductSelect = ({
  productTypeOptions,
  selectedProductType,
  handleSelectProductType,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      className={classes.panelHead}
    >
      <Grid item xs={12} md={1}>
        <Typography className={classes.labelHead}>
          <FormattedMessage id="product.productType" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomSelect
          options={productTypeOptions}
          value={selectedProductType}
          onChange={handleSelectProductType}
        />
      </Grid>
    </Grid>
  );
};

ProductSelect.defaultProps = {
  selectedProductType: "",
};

ProductSelect.propTypes = {
  productTypeOptions: PropTypes.string.isRequired,
  selectedProductType: PropTypes.string,
  handleSelectProductType: PropTypes.func.isRequired,
};

export default ProductSelect;

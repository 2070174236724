import { gql } from '@apollo/client';

const CREATE_RFP = gql`
  mutation CreateRFP(
    $attachments: AttachmentsInput!
    $identifier: Int!
    $message: String
    $replyToEmail: String
    $requestedDeliveryDate: DateTime!
    $requestedSupplierIds: [Int!]
    $selectedPropertyValues: [SelectedPropertyValue]
    $siteId: Int!
  ) {
    createRequestForProposal(
      request: {
        attachments: $attachments
        identifier: $identifier
        message: $message
        replyToEmail: $replyToEmail
        requestedDeliveryDate: $requestedDeliveryDate
        requestedSupplierIds: $requestedSupplierIds
        selectedPropertyValues: $selectedPropertyValues
        siteId: $siteId
      }
    )
  }
`;

export default CREATE_RFP;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import VersionHistoryContainer from "./VersionHistoryContainer";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

const VersionHistoryModal = ({
  locked,
  open,
  handleClose,
  resourceId,
  folderId,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="version.historyFileFor" />}
      />
      <DraggableModalBody>
        <VersionHistoryContainer
          locked={locked}
          resourceId={resourceId}
          folderId={folderId}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

VersionHistoryModal.defaultProps = {
  locked: false,
};

VersionHistoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
};

export default VersionHistoryModal;

import { gql } from '@apollo/client';

const getStockHistory = gql`
  query GetStockSettings($resourceId: ID!) {
    productEdit {
      getStockHistory(resourceId: $resourceId) {
        articleNo
        events {
          change
          companyOrderId
          orderId
          reason
          timepoint
          userId
          userIP
          username
          deliveryAddress1
          deliveryAddress2
        }
        owner
        productName
        stockBalance
      }
    }
  }
`;

export default getStockHistory;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  errorText: {
    fontSize: 11,
    color: "#f44336",
  },
  label: {
    fontSize: 12,
  },
}));

const OneStreamLabel = ({ message, isError }) => {
  const classes = useStyles();
  return (
    <Typography className={clsx([isError ? classes.errorText : classes.label])}>
      {message}
    </Typography>
  );
};

OneStreamLabel.defaultProps = {
  message: null,
  isError: null,
};

OneStreamLabel.propTypes = {
  message: PropTypes.node,
  isError: PropTypes.bool,
};

export default OneStreamLabel;

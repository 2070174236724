import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";

import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLazyQuery, useMutation } from "@apollo/client";

import { ACCEPT_TERMS_OF_USE } from "../../graphql/mutations/TermsOfUseContext";
import { POST_LOGON_STATUS } from "../../graphql/queries/PostLogonContext";
import { AuthContext } from "../../providers/authProvider";
import ErrorDialog from "./ErrorDialog";
import MfaSetupDialog from "./MfaSetupDialog";
import OptionalMfaSetupDialog from "./OptionalMfaSetupDialog";
import TermsOfUseDialog from "./TermsOfUseDialog";

const useStyles = makeStyles(() => ({
  spinnerContainer: {
    "&.MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
}));

const initialState = {
  isError: false,
  isMFADialogOpen: false,
  isOpen: false,
  mustAccept: undefined,
  mustCheck: false,
  mustSetupMFA: undefined,
  requiredSecurityLevel: undefined,
};

export const termsOfUseContext = createContext();

const { Provider } = termsOfUseContext;

const TermsOfUseProvider = ({ children }) => {
  const alert = useAlert();
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;

  const [state, setState] = useState(initialState);
  const { isError, isOpen, mustSetupMFA, requiredSecurityLevel, isMFADialogOpen, isOptionalMFADialogOpen, username } = state;


  const [acceptTermsOfUse, { loading: acceptingTermsOfUse }] = useMutation(
    ACCEPT_TERMS_OF_USE
  );

  const [
    checkTermsOfUse,
    {
      data: termsOfUseData,
      loading: checkingTermsOfUse,
      error: errorCheckingTermsOfUse,
    },
  ] = useLazyQuery(POST_LOGON_STATUS);

  useEffect(() => {
    const mustCheck = isAuthenticated();
    setState({ ...state, mustCheck });
  }, []);

  useEffect(() => {
    if (state.mustCheck && !checkingTermsOfUse) {
      isAuthenticated() && checkTermsOfUse();
      setState({ ...state, mustCheck: false });
    }
  }, [state.mustCheck]);

  useEffect(() => {

    const res = termsOfUseData?.postLogonContext?.postLogonStatus;
    if (res) {
      const { success, mustAcceptTermsOfUse, mustSetupMFA, username } = res;

      if (success) {
        setState({
          ...state,
          isError: false,
          mustAccept: mustAcceptTermsOfUse,
          mustCheck: false,
          mustSetupMFA: mustSetupMFA,
          username: username
        });
      } else {
        console.error("** ERROR: Failed check of Terms of Use.");
        setState({ ...state, isError: true });
      }
    }
  }, [termsOfUseData]);

  useEffect(() => {
    if (errorCheckingTermsOfUse) {
      console.error(
        "** ERROR: Failed check of Terms of Use.",
        errorCheckingTermsOfUse
      );
      setState({ ...state, isError: true });
    }
  }, [errorCheckingTermsOfUse]);

  const displayErrorAlert = (e = null) => {
    console.error("** ERROR: Failed to accept Terms of Use.", e ?? "");
    alert.error(<FormattedMessage id="common.genericErrorMessage" />);
  };

  const handleAccept = async () => {
    try {
      const res = await acceptTermsOfUse();
      const { success } =
        res?.data?.termsOfUseContext?.acceptTermsOfUse ?? false;

      if (success) {
        setState({
          ...state,
          isOpen: false,
          mustAccept: false,
          mustCheck: false,
        });
      } else {
        displayErrorAlert();
      }
    } catch (e) {
      displayErrorAlert(e);
    }
  };

  const handleOnMfaComplete = async () => {
    setState({ ...state, isMFADialogOpen: false, mustSetupMFA: false })
  }

  const handleRetryCheck = () => {
    setState({ ...state, isError: false, mustCheck: true });
  };

  const openDialog = () => {
    setState({ ...state, isOpen: true });
  };

  const openMfaDialog = () => {
    setState({ ...state, isMFADialogOpen: true });
  }

  const openOptionalMfaDialog = () => setState({ ...state, isOptionalMFADialogOpen: true });

  const closeOptionalMfaDialog = () => setState({ ...state, isOptionalMFADialogOpen: false });

  const DebugLabel = ({ hidden = false }) => {
    return (
      !hidden && (
        <div
          style={{
            background: "lightyellow",
            color: "black",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 10000,
            border: "2px solid gray",
            padding: "0 4px",
          }}
        >
          mustAccept:{" "}
          {state.mustAccept === true && (
            <span style={{ color: "red", fontWeight: "bold" }}>YES</span>
          )}
          {state.mustAccept === false && (
            <span style={{ color: "green", fontWeight: "bold" }}>NO</span>
          )}
          {state.mustAccept === undefined && (
            <span style={{ color: "black", fontWeight: "bolder" }}>
              UNDEFINED
            </span>
          )}
          <br />
          mustCheck:{" "}
          {state.mustCheck === true && (
            <span style={{ color: "black", fontWeight: "bold" }}>YES</span>
          )}
          {state.mustCheck === false && (
            <span style={{ color: "black", fontWeight: "bold" }}>NO</span>
          )}
          <br />
          isError:{" "}
          {state.isError === true && (
            <span style={{ color: "red", fontWeight: "bold" }}>YES</span>
          )}
          {state.isError === false && (
            <span style={{ color: "green", fontWeight: "bold" }}>NO</span>
          )}
          <br />
          isOpen:{" "}
          {state.isOpen === true && (
            <span style={{ color: "black", fontWeight: "bold" }}>YES</span>
          )}
          {state.isOpen === false && (
            <span style={{ color: "black", fontWeight: "bold" }}>NO</span>
          )}
          <br />
          checkingTermsOfUse:{" "}
          {checkingTermsOfUse === true && (
            <span style={{ color: "black", fontWeight: "bold" }}>YES</span>
          )}
          {checkingTermsOfUse === false && (
            <span style={{ color: "black", fontWeight: "bold" }}>NO</span>
          )}
          {checkingTermsOfUse === undefined && (
            <span style={{ color: "black", fontWeight: "bolder" }}>
              UNDEFINED
            </span>
          )}
          <hr />
          acceptingTermsOfUse:{" "}
          {acceptingTermsOfUse === true && (
            <span style={{ color: "black", fontWeight: "bold" }}>YES</span>
          )}
          {acceptingTermsOfUse === false && (
            <span style={{ color: "black", fontWeight: "bold" }}>NO</span>
          )}
          {acceptingTermsOfUse === undefined && (
            <span style={{ color: "black", fontWeight: "bolder" }}>
              UNDEFINED
            </span>
          )}
          <hr />
          mustSetupMFA:{" "}
          {mustSetupMFA === undefined && (
            <span style={{ color: "black", fontWeight: "bolder" }}>
              UNDEFINED
            </span>
          )}
          {mustSetupMFA === true && (
            <span style={{ color: "black", fontWeight: "bold" }}>YES</span>
          )}
          {mustSetupMFA === false && (
            <span style={{ color: "black", fontWeight: "bold" }}>NO</span>
          )}
          requiredSecrityLevel: {requiredSecurityLevel}
        </div>
      )
    );
  };

  return (
    <>
      {false && <DebugLabel />}

      {isOpen && (
        <TermsOfUseDialog
          disabled={acceptingTermsOfUse}
          onAccept={() => handleAccept()}
          open={isOpen}
        />
      )}

      {!isOpen && isMFADialogOpen && (
        <MfaSetupDialog
          onMfaSetupComplete={handleOnMfaComplete}
          open={isMFADialogOpen}
          username={username}
        />
      )}

      {!isOpen && isOptionalMFADialogOpen && (
        <OptionalMfaSetupDialog
          onAbort={closeOptionalMfaDialog}
          onComplete={closeOptionalMfaDialog}
          open={isOptionalMFADialogOpen}
          username={username}
        />
      )}

      {!state.mustCheck && !(checkingTermsOfUse || isError) && (
        <Provider
          value={{
            openDialog,
            openMfaDialog,
            openOptionalMfaDialog,
            userMustAcceptTermsOfUse: state.mustAccept,
            userMustSetupMfa: mustSetupMFA,
          }}
        >
          {children}
        </Provider>
      )}

      {isError && <ErrorDialog onRetry={handleRetryCheck} open={isError} />}

      {checkingTermsOfUse && (
        <Backdrop open={true} className={classes.spinnerContainer}>
          <CircularProgress size={100} />
        </Backdrop>
      )}
    </>
  );
};

TermsOfUseProvider.propsType = {
  children: PropTypes.node.isRequired,
};

export default TermsOfUseProvider;

import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Box, Icon, Table } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IconTextLink, TextField } from "../common";
import CheckboxDefault from "../common/FormControl/Checkbox";
import DateFull from "../common/FormControl/DateFull";
import TextAreaFull from "../common/FormControl/TextAreaFull";
import SummaryComputation from "./SummaryComputation";

const useStyles = makeStyles(theme => ({
  table: {
    marginBottom: 20,
  },
  btnNextWrap: {
    marginBottom: 20,
  },
  btn: {
    padding: "8px 15px",
    fontWeight: 600,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  label: {
    fontSize: 12,
    marginTop: 5,
    display: "block",
    color: "#999",
  },
  mt10: {
    marginTop: 10,
  },
  mt20: {
    marginTop: 20,
  },
}));

const isCartEmpty = ({ cartItems, fileGroups, oneStreamGroups }) => {
  const cartItemsEmpty = !cartItems.length > 0;

  const fileGroupsEmpty = !fileGroups.length > 0;
  const oneStreamGroupsEmpty = !oneStreamGroups.length > 0;

  if (cartItemsEmpty && fileGroupsEmpty && oneStreamGroupsEmpty) {
    return true;
  }
  return false;
};

const isGroupLackingSupplier = ({ group }) => {
  let lackingSupplier = false;

  lackingSupplier = group.some(x => {
    const { items } = x;

    const itemsHasLackingSupplier = items.some(y => {
      if (!y.siteSupplierId) {
        return true;
      }

      return false;
    });

    if (itemsHasLackingSupplier) return true;

    return false;
  });

  if (lackingSupplier) return true;
  return false;
};

const isCartLackingSupplier = ({ cartItems, fileGroups, oneStreamGroups }) => {
  if (!cartItems || !fileGroups || !oneStreamGroups) {
    return true;
  }

  let lackingSupplier = false;

  lackingSupplier = cartItems.some(x => {
    if (!x.siteSupplierId) {
      if (
        x.selectableSuppliers &&
        Array.isArray(x.selectableSuppliers) &&
        x.selectableSuppliers.length < 2
      ) {
        return false;
      }

      return true;
    }

    return false;
  });

  if (lackingSupplier) return true;
  lackingSupplier = isGroupLackingSupplier({ group: fileGroups });

  if (lackingSupplier) return true;

  lackingSupplier = isGroupLackingSupplier({ group: oneStreamGroups });

  if (lackingSupplier) return true;

  return false;
};

const CartSummary = ({
  submitOrder,
  page,
  setPage,
  disableOrderButton,
  checkoutFields,
  deliveryAddressChanged,
  message,
  costCenters,
  onFieldsChange,
}) => {
  const [checked, setChecked] = useState(false);
  const cartItems = useSelector(state => state.api.cart.cartItems);
  const fileGroups = useSelector(state => state.api.cart.fileGroups);
  const oneStreamGroups = useSelector(state => state.api.cart.oneStreamGroups);

  const classes = useStyles();
  const intl = useIntl();

  const {
    costPlace1Label,
    costPlace2Label,
    costPlace3Label,
    showCostPlace1,
    showCostPlace2,
    showCostPlace3,
    showOrderConfirmationEmail,
    earliestDeliveryDate,
    showDesiredDeliveryDaySelector,
    showTermsAndConditionsCheckbox,
  } = checkoutFields;

  const disableBtn =
    (!disableOrderButton && checked) || !showTermsAndConditionsCheckbox
      ? false
      : true;

  const hasLackingSupplier = isCartLackingSupplier({
    cartItems,
    fileGroups,
    oneStreamGroups,
  });

  const cartEmpty = isCartEmpty({ cartItems, fileGroups, oneStreamGroups });

  return (
    <div>
      <Table className={classes.table}>
        <SummaryComputation deliveryAddressChanged={deliveryAddressChanged} />
      </Table>
      {page === 1 && (
        <Box className={classes.btnNextWrap} align="right">
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={() => setPage(2)}
            disabled={hasLackingSupplier || cartEmpty}
          >
            <FormattedMessage id="btn.next" />
            <Icon
              className={clsx(["fa fa-long-arrow-alt-right", classes.btnIcon])}
            />
          </Button>
        </Box>
      )}
      {page === 2 && (
        <Box>
          <TextAreaFull
            placeholder={intl.formatMessage({ id: "cart.message" })}
            value={message}
            name="message"
            onChange={onFieldsChange}
            rows={5}
          />
          {showCostPlace1 && <TextField placeholder={costPlace1Label} defaultValue={costCenters[0]} name="costcenter1" onChange={onFieldsChange} />}
          {showCostPlace2 && <TextField placeholder={costPlace2Label} defaultValue={costCenters[1]} name="costcenter2" onChange={onFieldsChange} />}
          {showCostPlace3 && <TextField placeholder={costPlace3Label} defaultValue={costCenters[2]} name="costcenter3" onChange={onFieldsChange} />}
          {showDesiredDeliveryDaySelector && (
            <DateFull label="Önskat leveransdatum" date={earliestDeliveryDate} sx={{ marginTop: "1em" }} />
          )}
          {showOrderConfirmationEmail && (
            <TextField
              placeholder={intl.formatMessage({ id: "cart.confirmationEmail" })}
            />
          )}
          {showTermsAndConditionsCheckbox && (
            <CheckboxDefault
              label={<FormattedMessage id="cart.understood" />}
              onChange={() => setChecked(prevCheck => !prevCheck)}
            />
          )}
          <Box className={classes.mt10}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={submitOrder}
              disabled={disableBtn || hasLackingSupplier}
            >
              <FormattedMessage id="cart.btnOrder" />
              <Icon className={clsx(["fa fa-paper-plane", classes.btnIcon])} />
            </Button>
          </Box>
          <Box className={classes.mt20}>
            <IconTextLink
              onClick={() => setPage(1)}
              iconClass="fa fa-long-arrow-alt-left"
              text={<FormattedMessage id="cart.linkBack" />}
              fontSize={14}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

CartSummary.defaultProps = {
  disableOrderButton: false,
  checkoutFields: {},
};

CartSummary.propTypes = {
  submitOrder: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  disableOrderButton: PropTypes.bool,
  deliveryAddressChanged: PropTypes.bool.isRequired,
  checkoutFields: PropTypes.shape({
    showCostPlace1: PropTypes.bool,
    showCostPlace2: PropTypes.bool,
    showCostPlace3: PropTypes.bool,
    showOrderConfirmationEmail: PropTypes.bool,
    showDesiredDeliveryDaySelector: PropTypes.bool,
    showTermsAndConditionsCheckbox: PropTypes.bool,
  }),
};

export default memo(CartSummary);

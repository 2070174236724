import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { GrayButton } from "../../../common/Buttons";

const CancelButton = ({ disabled, hidden, onClick, ...rest }) => {
  if (hidden) return null;

  return (
    <GrayButton disabled={disabled} onClick={onClick} {...rest}>
      <FormattedMessage id="btn.cancel" />
    </GrayButton>
  );
};

CancelButton.defaultProps = {
  disabled: false,
  hidden: false,
};

CancelButton.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CancelButton;

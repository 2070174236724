import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  Paper,
  Popper,
} from "@mui/material";
import FolderTreeContainer from "./FolderTreeContainer";

const useStyles = makeStyles(() => ({
  popper: {
    width: "100%",
    zIndex: 100,
    top: "46%!important",
    left: "0!important",
    position: "absolute!important",
  },
  paper: {
    borderRadius: 4,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    minHeight: 200,
    maxHeight: 400,
    overflowY: "auto",
  },
  loading: {
    minHeight: 200,
    maxHeight: 400,
  },
}));

const FolderSelector = ({
  folders,
  loadingFolders,
  onClose,
  onFolderSelected,
  open,
  openedNodes,
  setOpenedNodes,
}) => {
  const classes = useStyles();
  const anchorRef = useRef(null);

  const handleOpenNode = id => {
    const nodeIndex = openedNodes.indexOf(id);
    if (nodeIndex === -1) {
      setOpenedNodes([...openedNodes, id]);
    } else {
      const tempOpenedNodes = openedNodes.filter(x => x !== id);
      setOpenedNodes(tempOpenedNodes);
    }
  };

  const FolderSelectorPaper = ({ TransitionProps }) => (
    <Grow {...TransitionProps}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper className={classes.paper}>
          {loadingFolders && (
            <Grid
              alignItems="center"
              className={classes.loading}
              container
              justifyContent="center"
            >
              <Grid item align="center">
                <CircularProgress size={30} />
              </Grid>
            </Grid>
          )}
          {!loadingFolders && (
            <FolderTreeContainer
              folders={folders}
              handleFolderRowClick={onFolderSelected}
              handleOpenNode={handleOpenNode}
              openedNodes={openedNodes}
              setOpenedNodes={setOpenedNodes}
            />
          )}
        </Paper>
      </ClickAwayListener>
    </Grow>
  );

  return (
    <Popper
      anchorEl={anchorRef.current}
      disablePortal
      className={classes.popper}
      open={open}
      transition
    >
      <FolderSelectorPaper />
    </Popper>
  );
};

FolderSelector.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadingFolders: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFolderSelected: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number).isRequired,
  setOpenedNodes: PropTypes.func.isRequired,
};

export default FolderSelector;

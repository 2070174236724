import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import OrderDetail from "./OrderDetail";
import { GET_ORDER_HISTORY_DETAIL } from "../../graphql/queries";
import OrderModalSkeleton from "../Skeleton/OrderModalSkeleton";

const OrderDetailContainer = ({ orderId, orderGroupId, settings }) => {
  const [orderHistory, setOrderHistory] = useState();

  const { data, loading, error } = useQuery(GET_ORDER_HISTORY_DETAIL, {
    variables: {
      orderGroupId,
      orderId,
    },
    skip: !orderId || !orderGroupId,
  });

  useEffect(() => {
    if (data && data.orderHistoryDetail) {
      setOrderHistory(data.orderHistoryDetail);
    }
  }, [data]);

  if (loading) {
    return <OrderModalSkeleton />;
  }
  if (error) {
    return "There has been an error";
  }

  return <OrderDetail order={orderHistory} settings={settings} />;
};

OrderDetailContainer.defaultProps = {
  orderId: null,
  orderGroupId: null,
  settings: null,
};

OrderDetailContainer.propTypes = {
  orderId: PropTypes.number,
  orderGroupId: PropTypes.number,
  settings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
};

export default OrderDetailContainer;

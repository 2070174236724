import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomSelect from "../../../../common/CustomSelect";
import CustomTextField from "../../../../common/TextField";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
  },
  icon: {
    fontSize: 12,
  },
  iconButton: {
    padding: 8,
  },
  settings: {
    "&:hover": {
      background: "#e6dddd",
    },
  },
  container: {
    marginTop: 10,
  },
}));

const dummyOptions = [
  { label: "Option 1", value: "one" },
  { label: "Option 2", value: "two" },
];

const LeftSettings = ({ handleChange, handleSelect, settings }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item container alignItems="center" className={classes.container}>
        <Grid xs={12} md={2}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.pricingModel" />:
          </Typography>
        </Grid>
        <Grid xs={12} md={10}>
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            customClass={classes.select}
            options={dummyOptions}
            value={settings.pricingModel}
            onChange={e => handleSelect("pricingModel", e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.container}>
        <Grid xs={12} md={2}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.priceList" />:
          </Typography>
        </Grid>
        <Grid xs={12} md={10}>
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            customClass={classes.select}
            options={dummyOptions}
            value={settings.priceList}
            onChange={e => handleSelect("priceList", e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.container}>
        <Grid xs={12} md={2}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.preFlight" />:
          </Typography>
        </Grid>
        <Grid xs={12} md={10}>
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            customClass={classes.select}
            options={dummyOptions}
            value={settings.preFlight}
            onChange={e => handleSelect("preFlight", e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
};

LeftSettings.defaultProps = {
  settings: {},
};

LeftSettings.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    priceList: PropTypes.string,
    pricingModel: PropTypes.string,
    name: PropTypes.string,
    preFlight: PropTypes.string,
  }),
};

export default LeftSettings;

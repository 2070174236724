import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import TableCell from "@mui/material/TableCell";
import clsx from "clsx";
import Box from "@mui/material/Box";
import QuantityInput from "./QuantityInput";

const useStyles = makeStyles(theme => ({
  quantityWrapper: {},
  quantityContainer: {
    width: "100%",
    margin: "0 auto",
  },
  tdQuantity: {
    fontSize: 12,
  },
  tdHeader: {
    backgroundColor: "#ddd",
    borderBottom: "none",
  },
}));

const QuantityCell = ({
  handleDecreaseQuantity,
  handleIncreaseQuantity,
  handleQuantity,
  header,
  itemQuantity,
}) => {
  const classes = useStyles();

  const handleTextInputChange = e => handleQuantity(e.target.value);

  return (
    <TableCell
      align="right"
      className={clsx([classes.tdQuantity, header && classes.tdHeader])}
    >
      <Box className={classes.quantityWrapper}>
        <Box className={classes.quantityContainer}>
          <QuantityInput
            handleDecreaseQuantity={handleDecreaseQuantity}
            handleIncreaseQuantity={handleIncreaseQuantity}
            handleTextInputChange={handleTextInputChange}
            value={itemQuantity}
            min={0}
          />
        </Box>
      </Box>
    </TableCell>
  );
};

QuantityCell.defaultProps = {
  item: {},
  header: false,
};

QuantityCell.propTypes = {
  header: PropTypes.bool,
  itemQuantity: PropTypes.number.isRequired,
  handleQuantity: PropTypes.func.isRequired,
  handleIncreaseQuantity: PropTypes.func.isRequired,
  handleDecreaseQuantity: PropTypes.func.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    quantity: PropTypes.number,
    selectableSuppliers: PropTypes.arrayOf(PropTypes.shape({})),
    price: PropTypes.number,
    siteSupplierId: PropTypes.number,
    propertyProductData: PropTypes.arrayOf(
      PropTypes.shape({
        propertyName: PropTypes.string,
        propertyValue: PropTypes.string,
        propertyValueId: PropTypes.number,
      })
    ),
  }),
};

export default memo(QuantityCell);

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  folderName: {
    fontSize: 12,
    // padding: "6px 5px",
    wordBreak: "break-all",
    marginLeft: 7,
    marginTop: 1,
  },
  highlight: {
    color: "#0065ff",
    fontWeight: 600,
  },
}));

const FolderName = ({ name, found }) => {
  const classes = useStyles();
  return (
    <span
      className={clsx([classes.folderName, found && classes.highlight])}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: name }}
    />
  );
};

FolderName.defaultProps = {
  found: false,
};

FolderName.propTypes = {
  name: PropTypes.string.isRequired,
  found: PropTypes.bool,
};

export default FolderName;

import { gql } from '@apollo/client';

const DELETE_USER = gql`
  mutation DeleteUser($userId: Int!) {
    companyAdminContext {
      users {
        deleteUser(userId: $userId)
      }
    }
  }
`;

export default DELETE_USER;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Grid } from "@mui/material";
import CustomTextField from "../../../../common/TextField";
import TextAreaFull from "../../../../common/FormControl/TextAreaFull";

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    color: theme.palette.component.productBoxColor,
    marginTop: 0,
  },
  customStyle: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 12,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 12,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 12,
    },
  },
  spacer: {
    marginBottom: 10,
  },
}));

const StockLeftColumn = ({
  formValues,
  articleNo,
  imageMetadata,
  handleOnChange,
}) => {
  const classes = useStyles();
  return (
    <Grid xs={12} md={8} item className={classes.customStyle}>
      <p className={classes.text}>
        <strong>
          <FormattedMessage id="product.articleNum" />
          :&nbsp;
        </strong>
        {articleNo}
      </p>
      <CustomTextField
        label="Artikel"
        name="productName"
        value={formValues.productName}
        onChange={handleOnChange}
      />
      <div className={classes.spacer} />
      {/* commented out wysiwyg for now might be available in  the future */}
      {/* <Wysiwyg placeholder="Beskrivning" /> */}
      <TextAreaFull
        label="Beskrivning"
        rows="5"
        rowsMax="5"
        name="productDescription"
        value={formValues.productDescription}
        onChange={handleOnChange}
      />
      <div className={classes.spacer} />
      {/* <SelectNativeFull options={[{ label: "Kommunal", value: 1 }]} /> */}
      <div className={classes.spacer} />
      <p className={classes.text}>
        <strong>
          <FormattedMessage id="product.responsibleOwner" />
          :&nbsp;
        </strong>
        {imageMetadata && imageMetadata.contact}
      </p>
      <CustomTextField
        label="Lagerplats"
        name="stockLocation"
        value={formValues.stockLocation}
        onChange={handleOnChange}
      />
      <div className={classes.spacer} />
      <TextAreaFull
        label="Lageranteckning"
        rows="5"
        rowsMax="5"
        name="stockDescription"
        value={formValues.stockDescription}
        onChange={handleOnChange}
      />
      <div className={classes.spacer} />
    </Grid>
  );
};

StockLeftColumn.defaultProps = {
  formValues: {},
  articleNo: null,
  imageMetadata: {},
};

StockLeftColumn.propTypes = {
  formValues: PropTypes.shape({
    productName: PropTypes.string,
    productDescription: PropTypes.shape({ contact: PropTypes.string }),
    stockLocation: PropTypes.string,
    stockDescription: PropTypes.string,
  }),
  articleNo: PropTypes.string,
  imageMetadata: PropTypes.shape({
    contact: PropTypes.string,
  }),
  handleOnChange: PropTypes.func.isRequired,
};

export default StockLeftColumn;

import { gql } from '@apollo/client';

const GET_FILE_CONTENT = gql`
  query GetFileContent($productId: ID!, $filename: String) {
    dynamicTemplateEditContext {
      getFileContent(productId: $productId, filename: $filename)
    }
  }
`;

export default GET_FILE_CONTENT;

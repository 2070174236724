import { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";

const StringValueEditor = ({
  disabled,
  enableEdit,
  field,
  inline,
  onUpdateTaskValue,
  size = "small",
  value,
}) => {
  const inputRef = useRef();

  const [internalValue, setInternalValue] = useState(value?.stringValue || "");
  const [isEditing, setIsEditing] = useState();
  const [selectionStart, setSelectionStart] = useState();

  const checkIfDirty = () => (internalValue !== (value?.stringValue || ""));
  const editable = enableEdit && !!onUpdateTaskValue;

  const saveChanges = (e) => {
    if (checkIfDirty()) {
      setSelectionStart(e?.target?.selectionStart);
      onUpdateTaskValue(field.fieldId, internalValue);
    }
  };

  const startEditing = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    resetInitialValue();

    setIsEditing(true);
    setSelectionStart(e?.target?.selectionStart);
  };

  const stopEditing = () => {
    setIsEditing(false);
    inputRef.current?.setSelectionRange(selectionStart, selectionStart);
  };

  const resetInitialValue = () => {
    setInternalValue(value?.stringValue || "");
  };

  useEffect(() => {
    resetInitialValue();
  }, [field, value]);

  useEffect(() => {
    if (selectionStart && !checkIfDirty()) {
      inputRef.current?.setSelectionRange(selectionStart, selectionStart);
    }
  }, [value, internalValue]);

  if (isEditing) {

    return (
      <TextField
        autoFocus
        disabled={disabled}
        fullWidth
        inputProps={{
          style: {
            boxSizing: "content-box",
          },
        }}
        inputRef={inputRef}
        onBlur={
          (e) => {
            saveChanges();
            stopEditing();
          }
        }
        onChange={
          (e) => {
            setInternalValue(e.target.value);
          }
        }
        onKeyDown={
          (e) => {
            if (e.key === "Enter") {
              saveChanges(e);
            }
            else if (e.key === "Escape") {
              resetInitialValue();
            }
          }
        }
        size={size}
        sx={{
          marginTop: inline && "1px",
        }}
        value={internalValue}
      />
    );

  }
  else if (editable) {

    return (
      <TextField
        disabled={disabled}
        fullWidth
        inputProps={{
          style: {
            boxSizing: "content-box",
            paddingBottom: inline && "6px",
            paddingTop: inline && "6px",
          },
        }}
        onSelect={startEditing}
        size={size}
        sx={{
          "&:not(:hover) fieldset": {
            border: inline && "none",
          },
        }}
        value={internalValue}
      />
    );

  }
  else {

    return (
      <Box
        component="span"
        sx={{ ml: "13px" }}
      >
        {value?.stringValue}
      </Box>
    );

  }

};

export default StringValueEditor;

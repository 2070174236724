import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Typography, Grid, Box, Divider } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomSelect from "../../common/CustomSelect";
import AddressTable from "./AddressTable";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    paddingTop: 10,
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 5,
  },
  btnSearch: {
    padding: "7px 25px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnSearchIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: "#F5F5F5",
    height: 1,
  },
}));

const ChangeAddressModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="Edit address" />
      <DraggableModalBody>
        <Typography className={classes.heading}>Filter address</Typography>
        <Grid
          container
          spacing={2}
          className={classes.date}
          alignItems="flex-end"
        >
          <Grid item xs={12} md={3}>
            <Typography className={classes.label}>Business</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography className={classes.label}>User</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography className={classes.label}>Address list type</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button className={classes.btnSearch}>
              <Icon className={clsx(["fa fa-search", classes.btnSearchIcon])} />
              <FormattedMessage id="btn.search" />
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <AddressTable />
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.saveChanges" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ChangeAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ChangeAddressModal;

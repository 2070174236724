import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../hooks";
import { Grid, Box, Typography, Button, Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { UPDATE_STOCK } from "../../../../../graphql/mutations";
import CheckboxDefault from "../../../../common/FormControl/Checkbox";
import CustomTextField from "../../../../common/TextField";

const useStyles = makeStyles(theme => ({
  customStyle: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 12,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 12,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 12,
    },
  },
  backLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    float: "left",
    marginTop: 2,
    padding: 0,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.6,
    },
  },
  backIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    verticalAlign: "middle",
  },
  cbText: {
    fontSize: 12,
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
    marginRight: 10,
  },
  clear: {
    clear: "both",
  },
  securityBox: {
    padding: "5px 15px 15px 15px",
    borderRadius: 4,
    border: "1px solid #ddd",
    marginBottom: 10,
  },
  btnMain: {
    padding: "11px 22px",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  stockEditWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  editBalanceLink: {
    fontSize: 11,
    color: theme.palette.common.link,
    float: "left",
    padding: 0,
    textTransform: "none",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.6,
    },
  },
  editBalanceIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 10,
    marginRight: 5,
    verticalAlign: "middle",
  },
  label: {
    marginTop: 0,
    marginBottom: 3,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  btnMainCont: {
    marginTop: 15,
  },
}));

const StockRightColumn = ({
  formValues,
  handleOpenEditStock,
  handleOpenHistory,
  handleCheckbox,
  handleOnChange,
  image,
  productId,
  setUnDraft,
  refetchStock,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [updateStock] = useMutation(UPDATE_STOCK);
  const handleFocus = event => event.target.select();

  const handleSave = async () => {
    const params = { ...formValues, productId };
    delete params.stockBalance;

    try {
      const res = await updateStock({ variables: { update: params } });

      if (res && res.data && res.data.updateStock) {
        const { success } = res.data.updateStock;
        if (success) {
          alert.success(<FormattedMessage id="product.updatedStockSettings" />);
          refetchStock();
          setUnDraft();
        } else {
          // TODO: We can also display the res.data.updatedStockSettings.errorResult here
          snackbar.workspaceError(
            <FormattedMessage id="common.errorOnSaving" />
          );
        }
      } else {
        snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
      }
    } catch (e) {
      console.log(`Error saving stock ${JSON.stringify(e)}`);
    }
  };

  return (
    <Grid xs={12} md={4} item className={classes.customStyle}>
      <Box className={classes.stockEditWrap}>
        <Typography className={classes.text}>
          <strong>
            <FormattedMessage id="product.stock" />
            :&nbsp;
          </strong>
          {formValues.stockBalance}
        </Typography>
        <Button
          className={classes.editBalanceLink}
          onClick={handleOpenEditStock}
        >
          <Icon
            className={clsx(["fa fa-pencil-alt", classes.editBalanceIcon])}
          />
          <FormattedMessage id="product.changeStockBalance" />
        </Button>
      </Box>
      <Button className={classes.backLink} onClick={handleOpenHistory}>
        <Icon className={clsx(["fa fa-clock", classes.backIcon])} />
        <FormattedMessage id="product.history" />
      </Button>
      <div className={classes.clear} />
      <span className={classes.cbText}>
        <FormattedMessage id="product.tempOutOfStock" />:
      </span>
      <CheckboxDefault
        name="temporaryOutOfStock"
        value={formValues.temporaryOutOfStock}
        onChange={handleCheckbox}
      />
      <div />
      <span className={classes.cbText}>
        <FormattedMessage id="product.negativeStock" />:
      </span>
      <CheckboxDefault
        name="allowNegativeStockBalance"
        value={formValues.allowNegativeStockBalance}
        onChange={handleCheckbox}
      />
      <div className={classes.spacer} />
      <Box className={classes.securityBox}>
        <p className={classes.text}>
          <strong>
            <FormattedMessage id="product.monitorTreshold" />
          </strong>
        </p>
        <Grid item xs={12} md={5}>
          <Typography className={classes.label}>Vid antal</Typography>
        </Grid>
        <CustomTextField
          name="refillThreshold"
          value={formValues.refillThreshold}
          type="number"
          onFocus={handleFocus}
          onChange={handleOnChange}
        />
        <div className={classes.spacer} />
        <Grid item xs={12} md={5}>
          <Typography className={classes.label}>Skickas till</Typography>
        </Grid>
        <CustomTextField
          name="refillEmail"
          value={formValues.refillEmail}
          onChange={handleOnChange}
        />
      </Box>
      <img src={image} alt="Product" />
      <Box align="right" className={classes.btnMainCont}>
        <Button
          variant="contained"
          className={classes.btnMain}
          onClick={() => handleSave()}
        >
          <FormattedMessage id="btn.save" />
        </Button>
      </Box>
    </Grid>
  );
};

StockRightColumn.defaultProps = {
  formValues: {},
  image: null,
  productId: null,
  refetchStock: null,
};

StockRightColumn.propTypes = {
  formValues: PropTypes.shape({
    allowNegativeStockBalance: PropTypes.bool,
    refillEmail: PropTypes.string,
    refillThreshold: PropTypes.number,
    stockBalance: PropTypes.number,
    temporaryOutOfStock: PropTypes.bool,
  }),
  handleOpenEditStock: PropTypes.func.isRequired,
  handleOpenHistory: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  image: PropTypes.string,
  productId: PropTypes.string,
  setUnDraft: PropTypes.func.isRequired,
  refetchStock: PropTypes.func,
};

export default StockRightColumn;

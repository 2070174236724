import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Button, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import DateFull from "../../common/FormControl/DateFull";
import ButtonText from "../../common/ButtonText";

const useStyles = makeStyles(theme => ({
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  colSearch: {
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
      textTransform: "none",
      fontSize: 11,
    },
    "& .MuiFormControl-root": {
      marginTop: 10,
    },
    "& legend": {
      maxWidth: 0,
    },
    maxWidth: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  btnColClear: {
    color: theme.palette.primary.main,
    fontSize: 10,
    marginTop: 5,
    marginRight: 8,
  },
  btnColSearch: {
    padding: "5px 15px",
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: 9,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    width: "auto",
    marginTop: 5,
  },
}));

const ColumnSearchDate = ({
  name,
  value,
  handleClear,
  handleSearchValues,
  onChange,
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (moment(value).isValid()) {
      setDate(value);
    } else {
      setDate(new Date());
    }
  }, [value]);

  return (
    <Box className={classes.colSearch} align="right">
      <DateFull
        label={<FormattedMessage id="metadata.datetime" />}
        setDate={e => onChange(name, e)}
        value={date}
        disablePortal
      />
      <Box>
        <ButtonText
          onClick={() => handleClear()}
          label={<FormattedMessage id="btn.clear" />}
          btnClass={classes.btnColClear}
        />
        <Button
          className={classes.btnColSearch}
          onClick={() => handleSearchValues()}
        >
          <FormattedMessage id="btn.search" />
        </Button>
      </Box>
    </Box>
  );
};

ColumnSearchDate.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleSearchValues: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
};

export default ColumnSearchDate;

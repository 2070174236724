import { gql } from '@apollo/client';

const getRFPTabFields = gql`
  query GetRFPTabFields($identifier: Int!) {
    requestForProposal {
      productDetail(identifier: $identifier) {
        identifier
        name
        properties {
          conditionalPropertyIds
          dependentPropertyIds
          id
          label
          maxValue
          minValue
          name
          propertyValues {
            id
            name
            selected
            quantities {
              values
            }
          }
          type
        }
      }
    }
  }
`;

export default getRFPTabFields;

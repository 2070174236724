import { gql } from '@apollo/client';

const MessageAttachmentFragment = gql`
  fragment MessageAttachmentFragment on MessageAttachment {
    attachmentId
    created
    filename
    fileSize
    mimeType
    preview
    resourceId
    thumbnailUrl
    __typename
  }
`;

export default MessageAttachmentFragment;

import { gql } from '@apollo/client';

const UPDATE_SITE_FOLDER_COLOR = gql`
  mutation UpdateSiteFolderColorSet(
    $siteFolderColorSetId: Int!
    $name: String
    $defaultColor: String
  ) {
    updateSiteFolderColorSet(
      siteFolderColorSetId: $siteFolderColorSetId
      name: $name
      defaultColor: $defaultColor
    ) {
      defaultColor
      name
      siteFolderColorSetId
    }
  }
`;

export default UPDATE_SITE_FOLDER_COLOR;

import React from "react";
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomStepper from "./CustomStepper";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const steps = ["Uppstart", "Implementation", "Information", "Utbildning", "Uppföljning"];

const ProjectProgress = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(30);

  const handleNext = () => {
    progress < 90 ? setProgress((prev) => prev + 10) : nextStep();
  };
  const handlePrevious = () => {
    progress > 10 ? setProgress((prev) => prev - 10) : previousStep();
  };

  const nextStep = () => {
    setProgress(0);
    setActiveStep((prev) => prev + 1);
  };

  const previousStep = () => {
    setProgress(100);
    setActiveStep((prev) => prev - 1);
  };

  return (
    <div style={{paddingBottom: "0px"}}>
      <CustomStepper steps={steps} current={activeStep} progress={progress} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingRight: "20px",
          position: "relative",
          opacity: 0.2,
          transition: "opacity 0.3s",
          "&:hover": {
            opacity: 1.0,
          },
        }}
      >
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={handlePrevious}
          disabled={activeStep <= 0 && progress <= 10}
          startIcon={<ChevronLeftIcon />}
        >
          Föregående
        </Button>
        <Button variant="text" color="primary" onClick={handleNext} endIcon={<ChevronRightIcon />}>
          {activeStep === steps.length - 1 ? "Färdigt" : "Nästa"}
        </Button>
      </Box>
    </div>
  );
}

export default ProjectProgress;
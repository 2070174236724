import { gql } from '@apollo/client';
import SiteNewsComment from "../../fragments/SiteNewsComment";

const ADD_NEWS_COMMENT = gql`
  mutation AddNewsComment($siteNewsPostid: ID!, $comment: String) {
    newsContext {
      addNewsComment(siteNewsPostid: $siteNewsPostid, comment: $comment) {
        ...SiteNewsCommentProperties
      }
    }
  }
  ${SiteNewsComment}
`;

export default ADD_NEWS_COMMENT;

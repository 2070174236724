import { useMutation } from "@apollo/client";
import { DELETE_TASK } from "../../graphql/mutations/ProjectEditContext";

// eslint-disable-next-line import/prefer-default-export
export const useDeleteTask = () => {
  const [mutation, { data, error, loading }] = useMutation(DELETE_TASK);

  const deleteTask = async ({ projectId, taskId }) => {
    const variables = {
      deleteTaskParams: {
        projectId,
        taskId,
      }
    };

    const res = await mutation({ variables });

    return res?.data?.projects?.deleteTask;
  };

  return [deleteTask, { data, error, loading }];
};

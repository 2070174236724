import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_COMPANY_GROUPS } from "../../graphql/queries/CompanyAdminContext";
import { setGroups, setGroupsLoading } from "../../actions";

// eslint-disable-next-line import/prefer-default-export
export const useGetCompanyGroups = () => {
  const dispatch = useDispatch();

  const [getCompanyGroups, { data, loading, error }] = useLazyQuery(
    GET_COMPANY_GROUPS
  );

  useEffect(() => {
    if (
      data &&
      data.companyAdminContext &&
      data.companyAdminContext.companyGroupSettings &&
      Array.isArray(data.companyAdminContext.companyGroupSettings.companyGroups)
    ) {
      const { companyGroups } = data.companyAdminContext.companyGroupSettings;

      dispatch(
        setGroups({
          items: companyGroups,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setGroupsLoading({
        loading,
      })
    );
  }, [loading]);

  return { execute: getCompanyGroups, data, loading, error };
};

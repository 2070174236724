import { Paper, Card, CardContent, Typography, Box, CardHeader, IconButton } from '@mui/material';
import KanbanBoardItem from "./KanbanBoardItem";
import { useDrop } from 'react-dnd'
import { TaskAction } from '../helper';
import { cloneDeep } from "lodash";

const KanbanColum = ({ column, enableEdit, userLookup, onTaskAction, taskDetailsColumns }) => {

  const removeTreeProperties = (item) => {
    delete item.ancestors;
    delete item.parentNode;
    delete item.treeXLevel;
    delete item.treeYLevel;

    return item;
  };


  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "KANBAN_ITEM",
    canDrop: (draggedItem) => {
      if (column.name === "PROJECT_KANBAN_UNDEFINED") {
        return false;
      }

      if (draggedItem.values && Array.isArray(draggedItem.values)) {
        return !draggedItem.values.some(n => n.fieldId === column.fieldId && n.stringValue === column.name);
      }

      return true;
    }
    ,
    drop: (draggedItem, monitor) => {
      if (column.name === "PROJECT_KANBAN_UNDEFINED") {
        return; // Do nothing if the column name is "PROJECT_KANBAN_UNDEFINED"
      }
      moveTaskToStatus(draggedItem, monitor, column);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }), [column]);

  const moveTaskToStatus = (draggedItem, monitor, column) => {

    let clonedTask = removeTreeProperties(cloneDeep(draggedItem));
    
    let valueFound = false;

    // Find and update the value in clonedTask.values
    if (clonedTask.values && Array.isArray(clonedTask.values)) {
      clonedTask.values.forEach(value => {
        if (value.fieldId === column.fieldId) {
          value.stringValue = column.name;
          valueFound = true;
        }
      });

      if (!valueFound) {
        clonedTask.values.push({
          fieldId: column.fieldId,
          stringValue: column.name,
          numericValue: null,
          dateTimeValue: null
        });
      }
    } else {

      clonedTask.values = [{
        fieldId: column.fieldId,
        stringValue: column.name,
        numericValue: null,
        dateTimeValue: null
      }];
    }
    
    onTaskAction({
      action: TaskAction.UPDATE,
      columns: null, //ska skicka in kolumns men det anv�nds bara f�r TaskAction.Edit
      data: {
        task: clonedTask,
      },
    });

  };

  return (<div key={column.name} ref={drop} style={{ flexGrow: 1, minWidth:"300px", marginRight: '5px', marginBottom: '5px' }}>
    <Card sx={{ backgroundColor: column.color }}>
      <CardContent>
        <Typography component="div" sx={{fontWeight: 600, marginBottom: "15px"}}>{column.label}</Typography>
        <Box>
          {isOver && canDrop && <Paper
            sx={{
              cursor: 'move',
              marginBottom: "0.5em",
              padding: 1,
              borderStyle: "dashed",
              borderWidth: "thin"
            }}></Paper>}
          {column.tasks.map(task => (
              <KanbanBoardItem id={task.Id}
                task={task}
                enableEdit={enableEdit}
                userLookup={userLookup}
                onTaskAction={onTaskAction}

                taskDetailsColumns={taskDetailsColumns}
              ></KanbanBoardItem>
            )
          )}
        </Box>
      </CardContent>
    </Card>
  </div>)
}

export default KanbanColum;
import React from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-xml";

import "ace-builds/src-noconflict/theme-idle_fingers";

const CodeEditor = ({
  theme,
  mode,
  name,
  placeholder,
  onLoad,
  onChange,
  fontSize,
  showPrintMargin,
  showGutter,
  highlightActiveLine,
  enableBasicAutocompletion,
  enableLiveAutocompletion,
  enableSnippets,
  showLineNumbers,
  tabSize,
  value,
  width,
}) => {
  return (
    <AceEditor
      placeholder={placeholder}
      mode={mode}
      theme={theme}
      name={name}
      width={width}
      onLoad={onLoad}
      onChange={onChange}
      fontSize={fontSize}
      showPrintMargin={showPrintMargin}
      showGutter={showGutter}
      highlightActiveLine={highlightActiveLine}
      value={`${value}`}
      setOptions={{
        enableBasicAutocompletion,
        enableLiveAutocompletion,
        enableSnippets,
        showLineNumbers,
        tabSize,
      }}
      wrapEnabled
    />
  );
};

CodeEditor.defaultProps = {
  theme: null,
  mode: null,
  name: null,
  placeholder: null,
  onLoad: null,
  onChange: null,
  fontSize: null,
  showPrintMargin: null,
  showGutter: null,
  highlightActiveLine: null,
  enableBasicAutocompletion: null,
  enableLiveAutocompletion: null,
  enableSnippets: null,
  showLineNumbers: null,
  tabSize: null,
  value: null,
  width: null,
};

CodeEditor.propTypes = {
  width: PropTypes.number,
  theme: PropTypes.string,
  mode: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onLoad: PropTypes.func,
  onChange: PropTypes.func,
  fontSize: PropTypes.number,
  showPrintMargin: PropTypes.bool,
  showGutter: PropTypes.bool,
  highlightActiveLine: PropTypes.bool,
  enableBasicAutocompletion: PropTypes.bool,
  enableLiveAutocompletion: PropTypes.bool,
  enableSnippets: PropTypes.bool,
  showLineNumbers: PropTypes.bool,
  tabSize: PropTypes.bool,
  value: PropTypes.node,
};

export default CodeEditor;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Divider,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormattedMessage } from "react-intl";
import CreateChannelModal from "./CreateChannelModal";
import ChannelListItem from "./ChannelListItem";
import ChannelsSkeleton from "../../Skeleton/ChannelsSkeleton";
import ConversationContainer from "./ConversationContainer";
import ShowChannelAndWorkspaces from "./ShowChannelAndWorkspaces";
import ChannelHead from "./ChannelHead";
import HeaderList from "./HeaderList";
import StartConversationModal from "../StartConversationModal";
import ChannelsMiscPopover from "./ChannelsMiscPopover";
import { CREATE_CONVERSATION as CREATE_DIRECT_MESSAGE_CONVERSATION } from "../../../graphql/mutations/DirectMessageContext";

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.common.white,
    borderRight: "1px solid #E4E4E4",
    height: "100%",
  },
  head: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 15,
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  iconBtn: {
    padding: 4,
    marginLeft: "auto",
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
  },
  panelRoot: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  panelSummaryRoot: {
    padding: 0,
    minHeight: 30,
    "&.Mui-expanded": {
      margin: "0 !important",
      minHeight: 30,
    },
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
      alignItems: "center",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 !important",
      minHeight: 30,
    },
  },
  panelDetailsRoot: {
    padding: 0,
    flexDirection: "column",
  },
  usersListPanel: {
    padding: "8px 0 24px 0px",
  },
  groupLabel: {
    fontSize: 12,
    fontWeight: 600,
  },
  expandIcon: {
    order: -1,
    padding: 0,
    marginRight: 5,
  },
  listRoot: {
    padding: 0,
  },
  directMessagesContainer: {
    flex: 1,
  },
  divider: {
    margin: "15px 0",
  },
  headDivider: {
    margin: "15px 0 0 0",
  },
  channelsBodyContainer: { overflow: "auto" },
}));

const Channels = ({
  activeChannelId,
  activeConversationId,
  channels,
  channelsLoading,
  conversations,
  conversationsLoading,
  onChangeShowAllChannels,
  showAllChannelsAndWorkspaces,
  siteId,
  setSiteId,
  onChannelMessageAdded,
  onChannelSelected,
  onConversationAdded,
  onConversationMessageAdded,
  onConversationSelected,
}) => {
  const classes = useStyles();

  const initialSiteId = useSelector(
    state => state.ui.toggleWorkspaces.workspace.id
  );
  const initialWorkspaceName = useSelector(
    state => state.ui.toggleWorkspaces.workspace.name
  );
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );
  const sites = useSelector(state => state.api.currentViewer.viewer.sites);

  const [createConversation] = useMutation(CREATE_DIRECT_MESSAGE_CONVERSATION);

  const [siteSelected, setSiteSelected] = useState(initialSiteId);
  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName);

  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  const handleOnClickSite = site => {
    let found = {};
    if (site.siteId !== 0) {
      found = sites.find(x => x.id === site.siteId);
    } else {
      found = { ...site, id: 0 };
    }

    const { id, name } = found;
    setSiteSelected(id);
    setWorkspaceName(name);
    setWorkspaceSelector(false);

    setSiteId(id);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickSetting = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const settingPopoverId = open ? "settings-popover" : undefined;

  const [openCreateChannel, setOpenCreateChannel] = useState(false);
  const [startConversationVisible, setStartConversationVisible] = useState(
    false
  );

  const handleCloseStartConversation = () => {
    setStartConversationVisible(false);
  };

  const handleOpenStartConversation = () => {
    setStartConversationVisible(true);
  };

  const handleOpenCreateChannel = () => {
    setOpenCreateChannel(true);
  };
  const handleCloseCreateChannel = () => {
    setOpenCreateChannel(false);
  };

  const handleAddConversation = async userIds => {
    try {
      const res = await createConversation({
        variables: { userIds },
      });
      if (
        res &&
        res.data &&
        res.data.directMessageContext &&
        res.data.directMessageContext.createConversation
      ) {
        onConversationAdded({
          conversation: res.data.directMessageContext.createConversation,
        });
      }
    } catch (err) {
      console.error("error >", err);
    }

    setStartConversationVisible(false);
  };

  const [anchorElChannelsMisc, setAnchorElChannelsMisc] = useState(null);
  const channelsMiscVisible = Boolean(anchorElChannelsMisc);
  const id = channelsMiscVisible ? "channels-misc-popover" : undefined;

  const handleOpenChannelsMisc = event => {
    setAnchorElChannelsMisc(event.currentTarget);
  };
  const handleCloseChannelsMisc = () => {
    setAnchorElChannelsMisc(null);
  };

  const onClickCreateChannel = () => {
    handleOpenCreateChannel();
    handleCloseChannelsMisc();
  };

  return (
    <>
      <Box className={classes.root}>
        <Box>
          <ChannelHead
            handleClose={handleClose}
            anchorEl={anchorEl}
            open={open}
            id={settingPopoverId}
            handleOpenCreateChannel={handleOpenCreateChannel}
            handleClickSetting={handleClickSetting}
          />
        </Box>
        <ShowChannelAndWorkspaces
          workspaces={workspaces}
          workspaceName={workspaceName}
          onClick={handleOnClickSite}
          handleToggle={handleToggle}
          open={workspaceSelector}
          setOpen={setWorkspaceSelector}
        />
        <Divider className={classes.headDivider} />
        <Box className={classes.channelsBodyContainer}>
          <Box>
            <Accordion
              classes={{ root: classes.panelRoot }}
              defaultExpanded
            >
              <AccordionSummary
                classes={{
                  root: classes.panelSummaryRoot,
                  expandIcon: classes.expandIcon,
                }}
                expandIcon={<ArrowDropDownIcon />}
              >
                <HeaderList
                  hideButton
                  iconOnClick={handleOpenChannelsMisc}
                  title={<FormattedMessage id="widget.channels" />}
                />
              </AccordionSummary>
              <AccordionDetails
                classes={{ root: classes.panelDetailsRoot }}
              >
                {channelsLoading ? (
                  <ChannelsSkeleton />
                ) : (
                  <List className={classes.listRoot}>
                    {channels.map(x => (
                      <ChannelListItem
                        key={x.siteChannelId}
                        activeChannelId={activeChannelId}
                        channel={x}
                        onChannelMessageAdded={onChannelMessageAdded}
                        onChannelSelected={onChannelSelected}
                      />
                    ))}
                  </List>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.directMessagesContainer}>
            <Accordion
              classes={{ root: classes.panelRoot }}
              defaultExpanded
            >
              <AccordionSummary
                classes={{
                  root: classes.panelSummaryRoot,
                  expandIcon: classes.expandIcon,
                }}
                expandIcon={<ArrowDropDownIcon />}
              >
                <HeaderList
                  iconOnClick={handleOpenStartConversation}
                  title={<FormattedMessage id="widget.directMessages" />}
                />
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.usersListPanel }}>
                {
                  <ConversationContainer
                    activeConversationId={activeConversationId}
                    conversations={conversations}
                    loading={conversationsLoading}
                    onConversationSelected={onConversationSelected}
                    onMessageAdded={onConversationMessageAdded}
                  />
                }
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
      <CreateChannelModal
        open={openCreateChannel}
        handleClose={handleCloseCreateChannel}
      />
      <StartConversationModal
        open={startConversationVisible}
        handleClose={handleCloseStartConversation}
        onAddConversation={handleAddConversation}
      />
      <ChannelsMiscPopover
        id={id}
        open={channelsMiscVisible}
        anchorEl={anchorElChannelsMisc}
        handleClose={handleCloseChannelsMisc}
        onClickCreateChannel={onClickCreateChannel}
      />
    </>
  );
};

Channels.defaultProps = {
  activeChannelId: null,
  activeConversationId: null,
  channels: [],
  channelsLoading: false,
  conversations: [],
  conversationsLoading: false,
  siteId: null,
};

Channels.propTypes = {
  activeChannelId: PropTypes.number,
  activeConversationId: PropTypes.number,
  channels: PropTypes.arrayOf(PropTypes.shape({})),
  channelsLoading: PropTypes.bool,
  conversations: PropTypes.arrayOf(PropTypes.shape({})),
  conversationsLoading: PropTypes.bool,
  onChangeShowAllChannels: PropTypes.func.isRequired,
  onChannelMessageAdded: PropTypes.func.isRequired,
  onChannelSelected: PropTypes.func.isRequired,
  onConversationAdded: PropTypes.func.isRequired,
  onConversationMessageAdded: PropTypes.func.isRequired,
  onConversationSelected: PropTypes.func.isRequired,
  showAllChannelsAndWorkspaces: PropTypes.bool.isRequired,
  siteId: PropTypes.number,
};

export default Channels;

import React from "react";
import { TextField } from "@mui/material";

const TextSearchField = ({ disabled, name, onChange, options, value }) => {
  const { label } = options;

  return (
    <TextField sx={{ flex: "1 1 auto" }}
      disabled={disabled}
      label={label}
      onChange={e => onChange?.({ name, value: e.target.value })}
      size="small"
      value={value}
      variant="filled"
    />
  );
}

export default TextSearchField;

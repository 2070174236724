const getChildFoldersTreeData = ({ group, parentGroupId }) => {
  let groupTree = {};

  const childIds =
    group && group.childGroups
      ? group.childGroups.map(child => child.groupId)
      : [];

  let childGroupsTreeData = {};
  if (group && group.childGroups) {
    group.childGroups.forEach(childGroup => {
      const childGroupTreeData = getChildFoldersTreeData({
        group: childGroup,
        parentGroupId: group.groupId,
      });
      childGroupsTreeData = {
        ...childGroupsTreeData,
        ...childGroupTreeData,
      };
    });
  }

  groupTree = {
    ...groupTree,
    [`g_${group.groupId}`]: {
      ...group,
      isRoot: false,
      parentGroupId,
      childGroupIds: childIds,
    },
    ...childGroupsTreeData,
  };

  return groupTree;
};

export default getChildFoldersTreeData;

import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { contextMenu } from "react-contexify";
import { useDispatch } from "react-redux";
import HomeMadeFolderTree from "../../../common/HomeMadeFolderTree";
import ProductsMainViewContextMenu from "../../ProductsMainViewContextMenu";
import getFolderTreeDataStructure from "../../../../helpers/getFolderTreeDataStructure";
import { setFolderData, setRefetchFiles } from "../../../../actions";

const ProductFolderTree = ({
  folders,
  setFolderId,
  refetchFolders,
  openedNodes,
  handleOpenNode,
  setOpenedNodes,
  selectedFolderId,
  setSearchQuery,
  setSearchResults,
}) => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const contextMenuId = "productsmainview_menu";

  const [folderTree, setFolderTree] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const newFolderTree = getFolderTreeDataStructure({ folders });
    setFolderTree(newFolderTree);
  }, [folders]);

  const deletingFolder = () => {
    const { parentFolderId, folderId } = selectedNode;
    let newFolderTree = { ...folderTree };
    if (parentFolderId) {
      const newChildFolderIds = newFolderTree[
        `f_${parentFolderId}`
      ].childFolderIds.filter(x => x !== folderId);

      const newChildFolders = newFolderTree[
        `f_${parentFolderId}`
      ].childFolders.filter(x => x.folderId !== folderId);

      const newFolderData = {
        ...newFolderTree[`f_${parentFolderId}`],
        childFolderIds: newChildFolderIds,
        childFolders: newChildFolders,
      };

      newFolderTree = {
        ...newFolderTree,
        [`f_${parentFolderId}`]: newFolderData,
      };
    } else {
      delete newFolderTree[`f_${folderId}`];
    }
    setFolderTree(newFolderTree);
  };

  const creatingFolder = newFolder => {
    const { folderId } = selectedNode;
    let newFolderTree = { ...folderTree };
    if (folderId) {
      const newChildFolders = [
        ...newFolderTree[`f_${folderId}`].childFolders,
        newFolder,
      ];

      const { folderId: newFolderId } = newFolder;

      const newChildFoldersIds = [
        ...(newFolderTree[`f_${folderId}`].childFolderIds ?? []),
        newFolderId,
      ];

      const newFolderData = {
        ...newFolderTree[`f_${folderId}`],
        childFolderIds: newChildFoldersIds,
        childFolders: newChildFolders,
      };

      newFolderTree = {
        ...newFolderTree,
        [`f_${newFolderId}`]: newFolder,
        [`f_${folderId}`]: newFolderData,
      };
    }
    setFolderTree(newFolderTree);
  };

  // eslint-disable-next-line no-shadow
  const handleOnFolderRowClick = folder => {
    setSearchResults({});
    dispatch(setFolderData({ folder }));
    setFolderId(folder.folderId);
    if (selectedFolderId === folder.folderId) {
      dispatch(setRefetchFiles({ refetch: true }));
    }
  };

  const handleSetEditNode = value => {
    setIsEditMode(value);
  };

  const handleContextMenu = (e, node) => {
    e.preventDefault();
    e.stopPropagation();
    contextMenu.show({
      id: contextMenuId,
      event: e,
    });
    if (selectedNode !== node) {
      setSelectedNode(node);
    }
  };

  const handleFolderColorChange = (id, color) => {
    const folderId = `f_${id}`;
    const newFolderTree = { ...folderTree };

    newFolderTree[folderId] = { ...newFolderTree[folderId], colorValue: color };

    setFolderTree(newFolderTree);
  };

  return (
    <>
      {/* Används för filvyn*/}
      <HomeMadeFolderTree
        expanded={openedNodes}
        folderTree={folderTree}
        handleContextMenu={handleContextMenu}
        handleOpenNode={handleOpenNode}
        handleSetEditNode={handleSetEditNode}
        isEditMode={isEditMode}
        moveFolderEnabled
        onFolderRowClick={handleOnFolderRowClick}
        refetchFolders={refetchFolders}
        selectedNode={selectedNode}
        setFolderTree={setFolderTree}
        setOpenedNodes={setOpenedNodes}
        selectedFolderIds={selectedFolderId ? [selectedFolderId] : []}
      />
      <ProductsMainViewContextMenu
        contextMenuId={contextMenuId}
        creatingFolder={creatingFolder}
        deletingFolder={deletingFolder}
        folder={selectedNode}
        folders={folders}
        handleSetEditNode={handleSetEditNode}
        onFolderColorChange={handleFolderColorChange}
        openedNodes={openedNodes}
        refetchFolders={refetchFolders}
        setOpenedNodes={setOpenedNodes}
      />
    </>
  );
};

ProductFolderTree.defaultProps = {
  folders: [],
  selectedFolderId: null,
};

ProductFolderTree.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentFolderId: PropTypes.number.isRequired,
        })
      ),
      folderId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      parentFolderId: PropTypes.number,
    })
  ),
  handleOpenNode: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number).isRequired,
  refetchFolders: PropTypes.func.isRequired,
  selectedFolderId: PropTypes.number,
  setFolderId: PropTypes.func.isRequired,
  setOpenedNodes: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
};

export default memo(ProductFolderTree);

import React from 'react';

const GoogleDocumentViewer = () => {
  //When adding '?rm=minimal' at the end of the link, the document will be opened in a minimal view 926px
  const documentURL = 'https://docs.google.com/document/d/1yyNdPcjUwQsezyZnPxNxJl5vCi_wpMERxkOqBH62Gfo/edit?rm=minimal';
  return (
    <div>
      <iframe src={documentURL} style={{ border: 0, width: '850px', height: 'calc(100vh - 294px)' }} />
    </div>
  );
};
export default GoogleDocumentViewer;
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
    textAlign: "left",
    marginBottom: 15,
  },
}));

const WidgetLabel = ({ title }) => {
  const classes = useStyles();

  return <Typography className={classes.title}>{title}</Typography>;
};

WidgetLabel.defaultProps = {};

WidgetLabel.propTypes = {
  title: PropTypes.string.isRequired,
};

export default WidgetLabel;

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Divider } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import IconLink from "../../common/FormControl/IconLink";
import SelectInline from "../../common/FormControl/SelectInline";
import CheckboxDefault from "../../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  headingWrap: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    marginRight: "auto",
  },
  text: {
    marginBottom: 10,
  },
  imageItem: {
    "& .MuiIconButton-root": {
      marginRight: 0,
      marginLeft: "auto",
    },
    "& .MuiCheckbox-root": {
      padding: 9,
    },
    "& .MuiSvgIcon-root": {
      width: "18px !important",
      height: "18px !important",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
    },
    "& select": {
      paddingTop: 6,
      paddingBottom: 6,
      fontSize: 12,
    },
    "& .MuiSelect-icon": {
      top: 6,
    },
  },
  imageText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  cbWrap: {
    paddingLeft: 5,
  },
  cbIndent: {
    paddingLeft: 20,
    marginBottom: 5,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const ConvertSettings = ({
  allImageOption,
  colorModelOptions,
  fileFormatOptions,
  handleOpenMSOffice,
  handleOpenOriginalImage,
  handleOpenTryck,
  handleOpenWebb,
  includeOriginal,
  selectedColorMode,
  selectedFileFormat,
  setIncludeOriginal,
  setSelectedColorMode,
  setSelectedFormat,
  setShowColorModel,
  setShowFileFormat,
  showColorModel,
  showFileFormat,
  imageOptions,
  setImageOptions,
}) => {
  const classes = useStyles();

  const handleColorModel = e => {
    const { checked } = e.target;
    if (!checked) {
      setSelectedColorMode("");
    }
    setShowColorModel(checked);
  };

  const handleFileFormat = e => {
    const { checked } = e.target;
    if (!checked) {
      setSelectedFormat("");
    }
    setShowFileFormat(checked);
  };

  const handleOptions = e => {
    const { name, checked } = e.target;

    setImageOptions({ ...imageOptions, [name]: checked });
  };

  return (
    <>
      {allImageOption && (
        <Box>
          <Box className={classes.headingWrap}>
            <Typography className={classes.heading}>
              2. <FormattedMessage id="imageBasket.customizeImageFor" />
            </Typography>
          </Box>
          <Box className={classes.imageItem}>
            <Box display="flex" alignItems="center" className={classes.cbWrap}>
              <CheckboxDefault
                label={<FormattedMessage id="imageBasket.originalImage" />}
                value={includeOriginal}
                onChange={() => setIncludeOriginal(prevCheck => !prevCheck)}
              />
              <IconLink
                icon="nc-icon nc-alert-circle-i"
                customClass={classes.infoIcon}
                onClick={handleOpenOriginalImage}
              />
            </Box>
            <Typography className={classes.imageText}>
              <FormattedMessage id="imageBasket.customizeFor" />
            </Typography>
            <Box display="flex" alignItems="center" className={classes.cbWrap}>
              <CheckboxDefault
                name="webb"
                label="Webb"
                value={imageOptions.webb}
                onChange={e => handleOptions(e)}
              />
              <IconLink
                icon="nc-icon nc-alert-circle-i"
                customClass={classes.infoIcon}
                onClick={handleOpenWebb}
              />
            </Box>
            <Box display="flex" alignItems="center" className={classes.cbWrap}>
              <CheckboxDefault
                label="MS Office"
                name="msOffice"
                value={imageOptions.msOffice}
                onChange={e => handleOptions(e)}
              />
              <IconLink
                icon="nc-icon nc-alert-circle-i"
                customClass={classes.infoIcon}
                onClick={handleOpenMSOffice}
              />
            </Box>
            <Box display="flex" alignItems="center" className={classes.cbWrap}>
              <CheckboxDefault
                label="Tryck"
                name="tryck"
                value={imageOptions.tryck}
                onChange={e => handleOptions(e)}
              />
              <IconLink
                icon="nc-icon nc-alert-circle-i"
                customClass={classes.infoIcon}
                onClick={handleOpenTryck}
              />
            </Box>
            {imageOptions.tryck && (
              <>
                <Box className={classes.cbIndent}>
                  <CheckboxDefault
                    label={<FormattedMessage id="imageBasket.colorModel" />}
                    onChange={e => handleColorModel(e)}
                    value={showColorModel}
                  />
                  {showColorModel && (
                    <SelectInline
                      value={selectedColorMode}
                      options={colorModelOptions}
                      onChange={e => setSelectedColorMode(e.target.value)}
                    />
                  )}
                </Box>
                <Box className={classes.cbIndent}>
                  <CheckboxDefault
                    value={showFileFormat}
                    label={<FormattedMessage id="imageBasket.fileFormat" />}
                    onChange={e => handleFileFormat(e)}
                  />

                  {showFileFormat && (
                    <SelectInline
                      value={selectedFileFormat}
                      options={fileFormatOptions}
                      onChange={e => setSelectedFormat(e.target.value)}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
          <Divider className={classes.divider} />
        </Box>
      )}
    </>
  );
};

ConvertSettings.propTypes = {
  allImageOption: PropTypes.bool.isRequired,
  colorModelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  fileFormatOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  handleOpenMSOffice: PropTypes.func.isRequired,
  handleOpenOriginalImage: PropTypes.func.isRequired,
  handleOpenTryck: PropTypes.func.isRequired,
  handleOpenWebb: PropTypes.func.isRequired,
  includeOriginal: PropTypes.bool.isRequired,
  selectedColorMode: PropTypes.string.isRequired,
  selectedFileFormat: PropTypes.string.isRequired,
  setIncludeOriginal: PropTypes.func.isRequired,
  setSelectedColorMode: PropTypes.func.isRequired,
  setSelectedFormat: PropTypes.func.isRequired,
  setShowColorModel: PropTypes.func.isRequired,
  setShowFileFormat: PropTypes.func.isRequired,
  showColorModel: PropTypes.bool.isRequired,
  showFileFormat: PropTypes.bool.isRequired,
  imageOptions: PropTypes.shape({
    webb: PropTypes.bool,
    msOffice: PropTypes.bool,
    tryck: PropTypes.bool,
  }).isRequired,
  setImageOptions: PropTypes.func.isRequired,
};

export default ConvertSettings;

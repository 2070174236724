import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const MOVE_RESOURCES = gql`
  mutation MoveResources($resourceIds: [ID!], $targetFolderId: Int!) {
    moveResources(resourceIds: $resourceIds, targetFolderId: $targetFolderId) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }

  ${ErrorResultFragment}
`;

export default MOVE_RESOURCES;

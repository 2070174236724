import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/client";
import DynamicFields from "../DynamicFields";
import { GET_TEMPLATE } from "../../../graphql/queries/dynamic-templace";
import ProductModalSkeleton from "../../Skeleton/ProductModalSkeleton";

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: "center",
  },
}));

const DynamicProduct = ({
  handleClose,
  product,
  editMode,
  handleUpdateDynamicProductFields,
  productIndex,
  quantity,
  setQuantity,
  isCheckout,
  customMetadata,
}) => {
  const classes = useStyles();
  const { data, loading, error } = useQuery(GET_TEMPLATE, {
    variables: {
      productId: product.id,
    },
  });
  if (loading) {
    return (
      <Box className={classes.content}>
        <ProductModalSkeleton />
      </Box>
    );
  }
  if (error) {
    return (
      <Box className={classes.content}>
        <p>There has been an error!</p>
      </Box>
    );
  }
  const { dynamicTemplate } = data;
  return (
    <DynamicFields
      customMetadata={customMetadata}
      product={product}
      templates={dynamicTemplate}
      closeModal={handleClose}
      editMode={editMode}
      handleUpdateDynamicProductFields={handleUpdateDynamicProductFields}
      productIndex={productIndex}
      quantity={quantity}
      setQuantity={setQuantity}
      isCheckout={isCheckout}
    />
  );
};
DynamicProduct.defaultProps = {
  cartAddItem: () => {},
  editMode: false,
  handleUpdateDynamicProductFields: () => {},
  productIndex: null,
};

DynamicProduct.propTypes = {
  editMode: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleUpdateDynamicProductFields: PropTypes.func,
  cartAddItem: PropTypes.func,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  productIndex: PropTypes.number,
  setQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default DynamicProduct;

import { gql } from '@apollo/client';
import AddressFragment from "../../fragments/Address";

const getIncomingOrder = gql`
  query GetIncomingOrder($supplierOrderId: Int!) {
    incomingOrdersContext {
      details(supplierOrderId: $supplierOrderId) {
        supplierOrderId
        comment
        companyOrderno
        costPlaceOrder
        deliveryDateWish
        deliveryAddress {
          ...AddressFields
        }
        invoiceAddress {
          ...AddressFields
        }
        orderDate
        ordererAddress {
          email
        }
        orderId
        status
        sumInclVat
        username

        orderRows {
          amount
          id
          price
          product
          stockInfo {
            picked
            pickedBy
            stockLocation
          }
          weight
        }
      }
    }
  }
  ${AddressFragment}
`;

export default getIncomingOrder;

import React from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
    textTransform: "uppercase",
    padding: 4,
  },
  thCb: {
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
    textTransform: "uppercase",
    padding: 4,
    width: 50,
  },
}));

const FolderTableHead = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th}>
          <FormattedMessage id="folder.role" />
        </TableCell>
        <TableCell className={classes.thCb} align="center">
          <FormattedMessage id="folder.write" />
        </TableCell>
        <TableCell className={classes.thCb} align="center">
          <FormattedMessage id="folder.read" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FolderTableHead;

import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState, lazy, Suspense  } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ErrorText, FieldLabel, PrimaryButton, TextField } from "../../../common";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { UPDATE_ACCOUNT_PASSWORD } from "../../../../graphql/mutations";
import { useEffect } from "react";
import { useSnackbar } from "../../../../hooks";


const PasswordStrength = lazy(() => import("../../../PasswordStrength"));

const useStyle = makeStyles({
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headSmall: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: "0px",
    color: "inherit",
  },
  headMarginBottom: {
    marginBottom: "10px",
  }
});


const PasswordPanel = ({ }) => {

  const classes = useStyle();
  const intl = useIntl();

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  
  const snackbar = useSnackbar();

  const [updateAccountPassword, { data, loading, error }] = useMutation(UPDATE_ACCOUNT_PASSWORD);


  const handleUpdatePassword = () => {

    if (currentPassword !== "" && password === passwordRepeat) {
      updateAccountPassword({
        variables: {
          model: {
            password: password,
            passwordConfirm: passwordRepeat,
            currentPassword: currentPassword
          }
        }
      });
    }
  }

  useEffect(() => {
    let error;
    if (password || passwordRepeat) {
      if (password !== passwordRepeat) {
        error = intl.formatMessage({
          id: "settings.passwordNotMatch",
        });
      } else if (
        password.length < 8 ||
        passwordRepeat.length < 8
      ) {
        error = intl.formatMessage({
          id: "settings.lessThan8",
        });
      } else {
        error = null;
      }
    } else {
      error = null;
    }
    setPasswordError(error);
  }, [password, passwordRepeat]);

  useEffect(() => {

    if (data?.userAccountContext?.updateAccountPassword) {

      const { success, errorResult } = data?.userAccountContext?.updateAccountPassword;

      setIncorrectPassword(!success);

      if (success) {
        snackbar.success(
          intl.formatMessage({ id: "settings.updateSuccess" })
        );
      } else {
        snackbar.error(
          intl.formatMessage({ id: "settings.updateFailure" })
        );
      }

    }


  }, [data, loading, error]);


  const handleChange = (e) => {
    switch (e.target.name) {
      case "currentPassword": setCurrentPassword(e.target.value); break;
      case "password": setPassword(e.target.value); break;
      case "passwordRepeat": setPasswordRepeat(e.target.value); break;
    }

  }

  return (<>   
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
    <Box className={classes.headMarginBottom} xs={2}>
      <Typography className={classes.headTitle}>Lösenord</Typography>
      <Typography sx={{ backgroundColor: "inherit", marginTop: "12px" }} className={classes.headSmall}>Här kan du byta lösenord.</Typography>
      <Typography className={classes.headSubTitle}>Lösenordet skyddar ditt konto så välj ett bra lösenord. Ett bra lösenord innehåller en blandning av stora och små bokstäver, siffror och andra tecken. En lösenordsfras med flera ord kan vara bättre än ett lösenord!</Typography>
    </Box>
    


        <Stack direction="column">
          <FieldLabel>
            <FormattedMessage id="settings.currentPassword" />
          </FieldLabel>
          <TextField
            type="password"
            name="currentPassword"
            onChange={handleChange}
          />
          {incorrectPassword && (
            <ErrorText
              message={
                <FormattedMessage id="settings.incorrectPassword" />
              }
              fontSize={11}
            />
          )}
          <FieldLabel>
            <FormattedMessage id="shares.passwordNew" />
          </FieldLabel>
          <TextField
            type="password"
            name="password"
            onChange={handleChange}
          />
          <Suspense><PasswordStrength width={100} password={password} /></Suspense>
          <ErrorText message={passwordError} fontSize={11} />
          <FieldLabel>
            <FormattedMessage id="settings.passwordRepeat" />
          </FieldLabel>
          <TextField
            type="password"
            name="passwordRepeat"
            onChange={handleChange}
          />

          <PrimaryButton marginTop={8} disabled={password.length == 0 && currentPassword.length == 0 && password !== passwordRepeat } onClick={handleUpdatePassword}>Byt lösenord</PrimaryButton>
        </Stack>
      </Grid>
    </Grid></>);
}

export default PasswordPanel; 
import { gql } from '@apollo/client';
import DirectMessageFragment from "../../fragments/DirectMessage";

const GET_CONVERSATION = gql`
  query getConversation($directMessageConversationId: Int!) {
    directMessages {
      conversation(directMessageConversation: $directMessageConversationId) {
        directMessageConversationId
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
        members {
          name
          userId
          username
          avatarUrl
        }
        messages(last: 100) {
          #edges
          items {
            ...DirectMessageFragment
          }
          #pageInfo
          #totalCount
        }
        unreadMessageCount
      }
    }
  }
  ${DirectMessageFragment}
`;

export default GET_CONVERSATION;

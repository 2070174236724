import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../hooks";
import logger from "../../helpers/logger";

import { REMOVE_SITE_USER, UPDATE_SITE_USER } from "../../graphql/mutations";

import { useGetSiteUsers } from "../../hooks";
import SiteUsersTable from "../SiteUsers/SiteUsersTable";

import ManageRolesModal from "../SiteUsers/ManageRolesModal";

import { useGetSiteRoles } from "../../hooks/useGetSiteRoles";

import { FILTER_NAMES } from "../../components/SiteUsers/SiteUsersTable/filters";
import InviteUsersModals from "../../components/SiteUsers/InviteUserModals";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Typography, Box, Divider, Icon, Switch } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import clsx from "clsx";
import CustomTextField from "../common/TextField";
import TreeView from "../common/JSTree/index";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const { UNORDERED } = FILTER_NAMES;

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnLink: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}33`,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    marginBottom: 0,
  },
  space: {
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  formControl: {
    "& .MuiInput-underline": {
      borderRadius: 4,
      border: "1px solid #ddd",
    },
    "& .MuiInput-underline::before": {
      content: "none",
    },
    "& .MuiSelect-selectMenu": {
      paddingLeft: 10,
      fontSize: 12,
    },
    "& .MuiInputLabel-root": {
      fontSize: 12,
      marginTop: 0,
    },
    "& .MuiInputLabel-shrink": {
      marginTop: 10,
      backgroundColor: theme.palette.common.white,
    },
    "& label + .MuiInput-formControl": {
      marginTop: 9,
    },
  },
  menuItem: {
    fontSize: 12,
  },
  treeWrap: {
    marginTop: 5,
    maxHeight: 300,
    overflowY: "auto",
  },
  rowWrap: {
    marginBottom: 15,
  },
  pwIcon: {
    verticalAlign: "middle",
    color: theme.palette.text.secondary,
    fontSize: 14,
    marginRight: 8,
  },
  title: {
    display: "inline-block",
    fontSize: 12,
    marginBottom: 2,
  },
  subTitle: {
    fontSize: 11,
    color: theme.palette.text.secondary,
  },
  pwLabel: {
    fontSize: 11,
    marginBottom: 0,
  },
  passwordBox: {
    padding: 10,
    marginTop: 10,
    borderRadius: 6,
    backgroundColor: theme.palette.background.lightGray,
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const data = {
  core: {
    data: [
      { text: "Lönefrågor", icon: "fa fa-briefcase" },
      { text: "Issues", icon: "fa fa-comment-alt" },
      {
        text: "Organisation och stadgar",
        icon: "fas fa-folder",
        children: [
          { text: "Facklig tid på jobbet", icon: "fas fa-folder" },
          { text: "Stadgar", icon: "fas fa-folder" },
        ],
      },
    ],
    check_callback: true,
    themes: {
      dots: false,
    },
  },
  plugins: ["dnd", "checkbox"],
};

const ShareFileModal = ({ open, handleClose, folder, folders }) => {
  const classes = useStyles();

  const emails = [
    "Uniqueue 4 (uniqueue4@gmail.com)",
    "(ulf.nilsson@uqbygg.se)",
  ];

  const [emailName, setEmail] = useState([]);
  const [passwordProtection, setPasswordProtection] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleEmail = event => {
    setEmail(event.target.value);
  };





  const intl = useIntl();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const selectedWorkspace = useSelector(
    state => state.ui.toggleWorkspaces.workspace
  );

  const [selectedSite, setSelectedSite] = useState(
    (selectedWorkspace && selectedWorkspace.id) || null
  );
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState(
    (selectedWorkspace && selectedWorkspace.name) || null
  );

  const [filter, setFilter] = useState("SHOW_ALL");

  //const filterOptions = [
  //  {
  //    label: intl.formatMessage({
  //      id: "siteUsers.showAllUsers",
  //    }),
  //    value: "SHOW_ALL",
  //  },
  //  {
  //    label: intl.formatMessage({
  //      id: "siteUsers.showInvitedUsers",
  //    }),
  //    value: "SHOW_INVITED",
  //  },
  //  {
  //    label: intl.formatMessage({
  //      id: "siteUsers.showActiveUsers",
  //    }),
  //    value: "SHOW_ACTIVE",
  //  },
  //  {
  //    label: intl.formatMessage({
  //      id: "siteUsers.showInactiveUsers",
  //    }),
  //    value: "SHOW_INACTIVE",
  //  },
  //];

  const sites = useSelector(state => state.api.currentViewer.viewer.sites);

  const siteRoles = useSelector(state => state.api.siteUsers.siteRoles);
  const [sortfield, setSortfield] = useState(UNORDERED);
  const [ascending, setAscending] = useState(false);
  const [siteRolesRights, setSiteRolesRights] = useState({});

  const [siteSelect, setSiteSelect] = useState(false);
  const [openManageRoles, setOpenManageRoles] = useState(false);

  const { execute: getSiteUsers } = useGetSiteUsers({
    siteId: selectedSite,
    filter,
    ascending,
    sortfield,
  });

  const { execute: getSiteRoles } = useGetSiteRoles({
    siteId: selectedSite,
  });

  useEffect(() => {
    if (selectedSite) {
      const found = sites.find(site => site.id === selectedSite);
      const { siteRoleRights } = found;
      setSiteRolesRights(siteRoleRights);
      getSiteRoles();
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedSite) {
      getSiteUsers();
    }
  }, [selectedSite, filter, sortfield, ascending]);

  const handleOpenManageRoles = () => {
    const canManageSiteRoles = !!(
      siteRolesRights && siteRolesRights.canManageSiteRoles
    );

    const manageRoles = !!(selectedSite && canManageSiteRoles);

    if (manageRoles) {
      setOpenManageRoles(true);
    } else {
      setOpenManageRoles(false);
    }
  };

  const options = [{ label: "Välj arbetsyta här", value: 0 }];
  sites.forEach(site => {
    options.push({
      label: site.name,
      value: site.id,
      orderGroupId: site.orderGroupId,
    });
  });

  const handleSetSiteId = site => {
    const { siteId } = site;
    if (siteId !== 0) {
      setSelectedSite(siteId);
      setSelectedWorkspaceName(site.name);
      const siteRoleRights = sites.filter(site => site.id === siteId);
      setSiteRolesRights(siteRoleRights[0].siteRoleRights);
    }
  };

  const siteRoleOptions = siteRoles.map(x => {
    return { label: x.name, value: x.siteRoleId };
  });


  const [removeSiteUser] = useMutation(REMOVE_SITE_USER);
  const [updateSiteUser] = useMutation(UPDATE_SITE_USER);

  const handleRemoveSiteUser = async (siteId, id) => {
    try {
      const res = await removeSiteUser({ variables: { siteId, id } });

      if (
        res &&
        res.data &&
        res.data.removeSiteUser &&
        res.data.removeSiteUser.success
      ) {
        getSiteUsers();
        alert.success(<FormattedMessage id="siteUsers.removedUser" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="siteUsers.errorRemoveUser" />
        );
      }
    } catch (err) {
      snackbar.workspaceError(
        <FormattedMessage id="siteUsers.errorRemoveUser" />
      );
      logger.error(`Error in removing site user. Unable to send ${err}`);
    }
  };

  const handleUpdateSiteUser = async params => {
    try {
      const res = await updateSiteUser({ variables: { siteUser: params } });

      if (
        res &&
        res.data &&
        res.data.updateSiteUser &&
        res.data.updateSiteUser.success
      ) {
        alert.success(<FormattedMessage id="siteUsers.update" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="siteUsers.errorUpdate" />
        );
      }
    } catch (err) {
      snackbar.workspaceError(<FormattedMessage id="siteUsers.errorUpdate" />);
      logger.error(`Error in updating site user. Unable to send ${err}`);
    }
  };






  return (
    <>
    {false && (
      <DraggableModal
        open={open}
        handleClose={handleClose}
        customClass={classes.modal}
      >
        <DraggableModalHead
          handleClose={handleClose}
          title={<FormattedMessage id="btn.share" />}
        />
        <DraggableModalBody>
          <Box className={classes.rowWrap}>
            <Typography className={classes.label}>
              <FormattedMessage id="workspace.name" />
            </Typography>
            <CustomTextField placeholder="Benämning" />
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.rowWrap}>
            <Typography className={classes.label}>
              <FormattedMessage id="workspace.whatToShare" />
            </Typography>
            <Box className={classes.treeWrap}>
              <TreeView treeData={data} />
            </Box>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.rowWrap}>
            <Typography className={classes.label}>
              <FormattedMessage id="workspace.whoToShare" />
            </Typography>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel>Välj</InputLabel>
              <Select
                multiple
                value={emailName}
                onChange={handleEmail}
                input={<Input />}
              >
                {emails.map(email => (
                  <MenuItem
                    className={classes.menuItem}
                    key={email}
                    value={email}
                  >
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider className={classes.divider} />
          {/*<Box>*/}
          {/*  <Grid container spacing={2} alignItems="center">*/}
          {/*    <Grid item md={9}>*/}
          {/*      <Icon className={clsx(["fa fa-lock", classes.pwIcon])} />*/}
          {/*      <Typography className={classes.title}>*/}
          {/*        <FormattedMessage id="shares.password" />*/}
          {/*      </Typography>*/}
          {/*      <Typography className={classes.subTitle}>*/}
          {/*        <FormattedMessage id="shares.passwordLabel" />*/}
          {/*      </Typography>*/}
          {/*    </Grid>*/}
          {/*    <Grid item md={3} align="right">*/}
          {/*      <Switch*/}
          {/*        size="small"*/}
          {/*        checked={passwordProtection}*/}
          {/*        onChange={() => setPasswordProtection(prevCheck => !prevCheck)}*/}
          {/*      />*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Box>*/}
          {/*{passwordProtection && (*/}
          {/*  <Box className={classes.passwordBox}>*/}
          {/*    <Grid container spacing={2}>*/}
          {/*      <Grid item md={6}>*/}
          {/*        <Typography className={classes.pwLabel}>*/}
          {/*          <FormattedMessage id="shares.passwordNew" />*/}
          {/*        </Typography>*/}
          {/*        <CustomTextField*/}
          {/*          type="password"*/}
          {/*          value={password}*/}
          {/*          onChange={e => setPassword(e.target.value)}*/}
          {/*        />*/}
          {/*      </Grid>*/}
          {/*      <Grid item md={6}>*/}
          {/*        <Typography className={classes.pwLabel}>*/}
          {/*          <FormattedMessage id="shares.passwordConfirm" />*/}
          {/*        </Typography>*/}
          {/*        <CustomTextField*/}
          {/*          type="password"*/}
          {/*          value={confirmPassword}*/}
          {/*          onChange={e => setConfirmPassword(e.target.value)}*/}
          {/*        />*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Box>*/}
          {/*)}*/}
          <Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={9}>
                <Icon className={clsx(["fa fa-lock", classes.pwIcon])} />
                <Typography className={classes.title}>
                  <FormattedMessage id="shares.allowFileUploads" />
                </Typography>
                <Typography className={classes.subTitle}>
                  <FormattedMessage id="shares.allowFileUploadsLabel" />
                </Typography>
              </Grid>
              <Grid item md={3} align="right">
                <Switch
                  size="small"
                  checked={passwordProtection}
                  onChange={() => setPasswordProtection(prevCheck => !prevCheck)}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider className={classes.divider} />
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item justify={"flex-start"}>
              <Button className={classes.btnLink}>
                <Icon className={clsx(["fa fa-link", classes.btnIcon])} />
                <FormattedMessage id="Kopiera länk" />
              </Button>
          </Grid>
            <Grid item justify={"flex-end"}>
              <Button className={classes.btnSave}>
                <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
                <FormattedMessage id="btn.share" />
              </Button>
            </Grid>
          </Grid>
        </DraggableModalBody>
        </DraggableModal>
    )}
      {true && open && (
      <InviteUsersModals
          open={open}
          onCloseDialog={handleClose}
          selectedSite={selectedSite} e
          siteRoleOptions={siteRoleOptions}
          getSiteUsers={getSiteUsers}
          setSiteSelect={setSiteSelect}
          isShareModal={true}
          folder={folder}
          folders={folders }

        />
    )}
    </>
  );
};

export default ShareFileModal;

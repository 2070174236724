import { gql } from '@apollo/client';

const GET_SITE_CHANNEL_EVENTS = gql`
  subscription SiteChannelEventsSubscription($authToken: String!) {
    siteChannelEvents(authToken: $authToken) {
      siteId
      siteChannelId
      eventType
      eventTime
    }
  }
`;

export default GET_SITE_CHANNEL_EVENTS;

import React, { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Grid, Divider } from "@mui/material";
import PricePanelHeader from "./PricePanelHeader";
import PricePanelManual from "./PricePanelManual";
import PricePanelOrder from "./PricePanelOrder";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const PricePanel = ({
  buyManualPrices,
  sellManualPrices,
  formValues,
  handleAddBuyPricesManualPrice,
  handleBuyPricesInitialCost,
  handleBuyManualPrice,
  handleCheckbox,
  handleVatRate,
  vatRates,
  handleAddSellPricesManualPrice,
  handleSellPricesInitialCost,
  handleSellManualPrice,
  errors,
  handleRemoveBuyPricesManualPrice,
  handleRemoveSellPricesManualPrice,
  selectedProductType,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  // Preselecting option { label: "Manuellt (Fyll i pris nedan)", value: 1 }
  const [selectedOption, setSelectedOption] = useState(1);

  const priceOptions = [
    {
      label: intl.formatMessage({ id: "product.priceModelOptionTitle" }),
      value: 1,
    },
    // { label: "Från uppdrag", value: 2 }
  ];

  return (
    <>
      <Grid container spacing={3} className={classes.pricePanelCont}>
        <PricePanelHeader
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          priceOptions={priceOptions}
        />
      </Grid>
      <Divider className={classes.divider} />
      {selectedOption === 1 && (
        <PricePanelManual
          buyManualPrices={buyManualPrices}
          sellManualPrices={sellManualPrices}
          formValues={formValues}
          handleAddBuyPricesManualPrice={handleAddBuyPricesManualPrice}
          handleBuyPricesInitialCost={handleBuyPricesInitialCost}
          handleBuyManualPrice={handleBuyManualPrice}
          handleSellPricesInitialCost={handleSellPricesInitialCost}
          handleSellManualPrice={handleSellManualPrice}
          handleAddSellPricesManualPrice={handleAddSellPricesManualPrice}
          handleCheckbox={handleCheckbox}
          handleVatRate={handleVatRate}
          vatRates={vatRates}
          errors={errors}
          handleRemoveBuyPricesManualPrice={handleRemoveBuyPricesManualPrice}
          handleRemoveSellPricesManualPrice={handleRemoveSellPricesManualPrice}
          selectedProductType={selectedProductType}
        />
      )}
      {selectedOption === 2 && <PricePanelOrder />}
    </>
  );
};

PricePanel.defaultProps = {
  selectedProductType: "",
};

PricePanel.propTypes = {
  selectedProductType: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  vatRates: PropTypes.arrayOf().isRequired,
  handleVatRate: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  buyManualPrices: PropTypes.arrayOf().isRequired,
  sellManualPrices: PropTypes.arrayOf().isRequired,
  handleAddBuyPricesManualPrice: PropTypes.func.isRequired,
  handleBuyPricesInitialCost: PropTypes.func.isRequired,
  handleBuyManualPrice: PropTypes.func.isRequired,
  handleAddSellPricesManualPrice: PropTypes.func.isRequired,
  handleSellPricesInitialCost: PropTypes.func.isRequired,
  handleSellManualPrice: PropTypes.func.isRequired,
  handleRemoveBuyPricesManualPrice: PropTypes.func.isRequired,
  handleRemoveSellPricesManualPrice: PropTypes.func.isRequired,
};

export default PricePanel;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Button, Icon } from "@mui/material";
import clsx from "clsx";
import DateFull from "../../common/FormControl/DateFull";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #F2F2F2",
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  greenIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
}));

const Statistics = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>Statistics</Typography>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Downloaded products
                  </Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
              <Box mt={2} mb={1}>
                <Typography className={classes.text}>
                  Downloaded date
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>From</Typography>
                  <DateFull />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>To</Typography>
                  <DateFull />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>Logins</Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
              <Box mt={2} mb={1}>
                <Typography className={classes.text}>Login date</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>From</Typography>
                  <DateFull />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>To</Typography>
                  <DateFull />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Orders - Products
                  </Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
              <Box mt={2} mb={1}>
                <Typography className={classes.text}>Order date</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>From</Typography>
                  <DateFull />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>To</Typography>
                  <DateFull />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Typography className={classes.heading}>
                    Orders - Extended
                  </Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
              <Box mt={2} mb={1}>
                <Typography className={classes.text}>Order date</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>From</Typography>
                  <DateFull />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>To</Typography>
                  <DateFull />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Box mb={2}>
                    <Typography className={classes.heading}>
                      Export Folders, Users and Roles
                    </Typography>
                  </Box>
                  <Typography className={classes.text}>
                    UQ2 templates, online designer templates, publications,
                    packages and combo items cannot be exported. The associated
                    files are not exported.
                  </Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                  <Box mb={2}>
                    <Typography className={classes.heading}>
                      Company Export - Export products, folders and product
                      settings from UQ3
                    </Typography>
                  </Box>
                  <Typography className={classes.text}>
                    Creates an Excel file with a tab containing all companies in
                    the current chain.
                  </Typography>
                  <Typography className={classes.text}>
                    UQ2 templates, online designer templates, publications,
                    packages and combo items cannot be exported. The associated
                    files are not exported.
                  </Typography>
                </Grid>
                <Grid xs={12} md={3} align="right">
                  <Button className={classes.greenLink}>
                    <Icon
                      className={clsx(["fas fa-download", classes.greenIcon])}
                    />
                    Download
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Statistics;

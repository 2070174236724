/**
 * An enumeration of the different address field types.
 * @enum {string}
 */
export const ADDRESS_FIELD_TYPE = {
  /** Address field type 'Fixed'. */
  Fixed: "FIXED",
  /** Address field type 'Hidden'. */
  Hidden: "HIDDEN",
  /** Address field type 'Optional'. */
  Optional: "OPTIONAL",
  /** Address field type 'Required'. */
  Required: "REQUIRED",
};

export default ADDRESS_FIELD_TYPE;

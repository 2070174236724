import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_TAGS = gql`
  mutation UpdateTags($UpdateTagsInputType: [UpdateTagsInputType]) {
    updateTags(tagsUpdateList: $UpdateTagsInputType) {
      errorResult {
        ...ErrorResultFields
      }
      success
      message
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_TAGS;

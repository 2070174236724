import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  TableSortLabel,
  ClickAwayListener,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import IconLink from "../../common/FormControl/IconLink";
import ColumnSearch from "./ColumnSearch";
import ColumnSearchDate from "./ColumnSearchDate";

const useStyles = makeStyles(theme => ({
  popoverText: {
    fontSize: 12,
    marginBottom: 5,
  },
  popoverSort: {
    fontSize: 11,
    "& span": {
      fontWeight: 600,
    },
  },
  iconButtonSort: {
    padding: "2px 4px",
    color: theme.palette.common.link,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  iconSort: {
    fontSize: 11,
  },
  sortName: {
    cursor: "pointer",
    fontWeight: 800,
  },
}));

const SortPopover = ({
  name,
  ascending,
  sortfield,
  handleSortField,
  handleToggleHeader,
  handleColumnFilter,
  handleClear,
  columnFilterValues,
  handleSearchValues,
  toggleHeader,
  handleColumnFilterByDate,
}) => {
  const classes = useStyles();

  const byName = `${name}By`;
  const byDate = `${name}Date`;
  return (
    <>
      <Typography className={classes.popoverText}>
        <FormattedMessage id="sorting.by" />:
      </Typography>
      <Box className={classes.popoverSort}>
        <IconLink
          icon="fa fa-search"
          customClass={classes.colSearchIcon}
          onClick={() => handleToggleHeader(byName)}
        />
        <TableSortLabel
          active={sortfield === byName}
          direction={sortfield === byName && ascending ? "asc" : "desc"}
          onClick={handleSortField(byName)}
        >
          <FormattedMessage id="sorting.name" />
        </TableSortLabel>
        {toggleHeader[byName] && (
          <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
            <div>
              <ColumnSearch
                onChange={handleColumnFilter}
                handleClear={handleClear}
                name={byName}
                value={columnFilterValues.value}
                handleSearchValues={handleSearchValues}
              />
            </div>
          </ClickAwayListener>
        )}
      </Box>
      <Box className={classes.popoverSort}>
        <IconLink
          icon="fa fa-search"
          customClass={classes.colSearchIcon}
          onClick={() => handleToggleHeader(byDate)}
        />
        <TableSortLabel
          active={sortfield === byDate}
          direction={sortfield === byDate && ascending ? "asc" : "desc"}
          onClick={handleSortField(byDate)}
        >
          <FormattedMessage id="product.date" />
        </TableSortLabel>
        {toggleHeader[byDate] && (
          <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
            <div>
              <ColumnSearchDate
                onChange={handleColumnFilterByDate}
                handleClear={handleClear}
                name={byDate}
                value={columnFilterValues.value}
                handleSearchValues={handleSearchValues}
              />
            </div>
          </ClickAwayListener>
        )}
      </Box>
    </>
  );
};

SortPopover.propTypes = {
  name: PropTypes.string.isRequired,
  sortfield: PropTypes.bool.isRequired,
  ascending: PropTypes.bool.isRequired,
  handleSortField: PropTypes.func.isRequired,
};

export default SortPopover;

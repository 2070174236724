import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage } from "react-intl";
import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  Checkbox,
} from "@mui/material";
import Footer from "./Footer";
import AddMenuButton from "./AddMenuButton";
import PageItemText from "./PageItemText";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  list: {
    padding: 0,
    "& .MuiCheckbox-root": {
      padding: 0,
      paddingRight: 4,
    },
  },
  listItem: {
    paddingLeft: 10,
  },
  listText: {
    "& span": {
      fontSize: 12,
    },
  },
  expansionDetails: {
    padding: 0,
  },
  expansionDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

export default function PagesPanel({
  siteMenuPages,
  handlePageCheckboxChange,
  handlePageAddToMenu,
}) {
  const classes = useStyles();

  const onClickSubmit = () => {
    handlePageAddToMenu({ menuType: "MENU_ITEM" });
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className="openPageMenu"
      >
        <Typography className={classes.heading}>
          <FormattedMessage id="siteRolesMenu.pages" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetails}>
        <Box className={classes.expansionDetailsContainer}>
          <List dense className={clsx([classes.list, "pagesMenu"])}>
            {siteMenuPages.map(x => {
              return (
                <ListItem className={classes.listItem} key={x.name}>
                  <PageItemText name={x.name} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      checked={x.checked}
                      name={x.name}
                      onChange={handlePageCheckboxChange}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
          <Footer>
            <AddMenuButton onClickSubmit={onClickSubmit} />
          </Footer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

PagesPanel.propTypes = {
  siteMenuPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handlePageCheckboxChange: PropTypes.func.isRequired,
  handlePageAddToMenu: PropTypes.func.isRequired,
};

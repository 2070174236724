import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Box, Typography, Grid } from "@mui/material";
//import { EditorState, ContentState } from "draft-js";
//import htmlToDraft from "html-to-draftjs";
import CheckboxDefault from "../../../../common/FormControl/Checkbox";
import DateFull from "../../../../common/FormControl/DateFull";
//import MessageEditor from "./MessageEditor";
//import { htmlContent } from "./helper";
import { PrimaryButton } from "../../../../common/Buttons";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 10,
  },
  btnSaveCont: {
    marginTop: 20,
  },
  text: {
    fontSize: 12,
  },
  colorPicker: {
    height: "30px!important",
    width: "30px!important",
    marginRight: 7,
  },
  editorMain: {
    border: "1px solid #F1F1F1",
    minHeight: "300px",
    padding: "5px",
  },
}));

const PopUpMessage = ({ handleSetDraft }) => {
  const classes = useStyles();

  const [checkbox, setCheckbox] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  //const contentBlock = htmlToDraft(htmlContent);
  /*const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );*/
  //const initialEditorValue = EditorState.createWithContent(contentState);
  //const [content, setContent] = useState(initialEditorValue);

  const setDraft = () => {
    handleSetDraft("popUpMessage", true);
  };

  const setUnDraft = () => {
    handleSetDraft("popUpMessage", false);
  };

  const handleEditorChange = editorContent => {
    //setContent(editorContent);
    setDraft();
  };

  const handleFromDate = date => {
    setFromDate(date);
    setDraft();
  };

  const handleToDate = date => {
    setToDate(date);
    setDraft();
  };

  const handleCheckbox = value => {
    setCheckbox(value);
    setDraft();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Box className={classes.container}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.popUpMessageForWorkspace" />
          </Typography>
          <Typography className={classes.text}>
            <CheckboxDefault
              value={checkbox}
              onChange={e => handleCheckbox(e.target.checked)}
            />
            <span>
              <FormattedMessage id="siteUsers.ACTIVE" />
            </span>
          </Typography>

          <Typography className={classes.text}>
            <FormattedMessage id="sites.messageSchedule" />
          </Typography>
          <Grid item container alignItems="center">
            <Grid xs={12} md={1}>
              <Typography className={classes.text}>
                <FormattedMessage id="orderHistorySearch.from" />
              </Typography>
            </Grid>
            <Grid xs={12} md={11}>
              <DateFull
                name="from"
                value={fromDate}
                onChange={handleFromDate}
              />
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid xs={12} md={1}>
              <Typography className={classes.text}>
                <FormattedMessage id="context.to" />
              </Typography>
            </Grid>
            <Grid xs={12} md={11}>
              <DateFull name="to" value={toDate} onChange={handleToDate} />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.container}>
                  {/*<MessageEditor
            content={content}
            onChange={handleEditorChange}
            editorClassName={classes.editorMain}
          />*/}
        </Box>
      </Grid>

      <Grid container justifyContent="flex-end" className={classes.btnSaveCont}>
        <PrimaryButton onClick={setUnDraft}>
          <FormattedMessage id="btn.save" />
        </PrimaryButton>
      </Grid>
    </Box>
  );
};

export default PopUpMessage;

import PropTypes from "prop-types";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import TuneIcon from "@mui/icons-material/Tune";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Button, Dialog, DialogContent, Paper, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

import DraggableModalHead from "../../components/common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../components/common/DraggableModal/DraggableModalBody";
import DetailedSearch from "../../components/DetailedSearch";
import FolderList from "../../components/DetailedSearch/FolderList";
import ProductList from "../../components/DetailedSearch/Products";

const useStyles = makeStyles(theme => ({
  buttonBar: {
    flex: "none",
    padding: 7,
    "& .MuiButton-root": {
      marginRight: 5,
      textTransform: "none",
    },
    "& .MuiButton-startIcon": {
      marginRight: 2,
    },
    "& .MuiButton-endIcon": {
      marginLeft: 2,
    },
  },
  modalBody: {
    backgroundColor: theme.palette.background.cream,
    padding: 8,
  },
}));

const ChevronButton = ({ icon, onClick, showChevron, text }) => (
  <Button
    endIcon={showChevron ? <ChevronLeftIcon /> : null}
    onClick={onClick}
    size="small"
    startIcon={icon}
    variant="outlined"
  >
    {text}
  </Button>
);

const Search = ({ onClose }) => {
  const classes = useStyles();

  const [filtersPanel, setFiltersPanel] = useState(false);
  const [foldersPanel, setFoldersPanel] = useState(false);

  return (
    <Dialog
      fullScreen
      handleClose={onClose}
      open
    >
      <DraggableModalHead handleClose={onClose} ><FormattedMessage id="btn.search"/></DraggableModalHead>
      <DialogContent className={classes.buttonBar}>
        <ChevronButton
          icon={<TuneIcon />}
          onClick={() => setFiltersPanel(state => !state)}
          showChevron={filtersPanel}
          text={<FormattedMessage id="search.filter" />}
        />
        <ChevronButton
          icon={<FolderOpenIcon />}
          onClick={() => setFoldersPanel(state => !state)}
          showChevron={foldersPanel}
          text={<FormattedMessage id="search.foundFolders" />}
        />
      </DialogContent>
      <DraggableModalBody customClass={classes.modalBody}>
        <Stack
          alignItems="stretch"
          justifyContent="space-between"
          spacing={1}
          sx={{ flexDirection: { md: "row", xs: "column" } }}
          useFlexGap
        >
          <Paper sx={{ display: filtersPanel ? "" : "none", width: { md: "20%" } }}>
            <DetailedSearch />
          </Paper>
          <Paper sx={{ display: foldersPanel ? "" : "none", padding: "10px", width: { md: "20%" } }}>
            <FolderList />
          </Paper>
          <Paper sx={{ flexGrow: 1 }}>
            <ProductList />
          </Paper>
        </Stack>
      </DraggableModalBody >
    </Dialog>
  );
};

Search.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Search;

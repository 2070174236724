import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  input: {
    "& div.MuiInput-underline.Mui-disabled:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0)",
    },
    "& div.MuiInputBase-root.Mui-disabled": {
      color: theme.palette.common.black,
    },
  },
  nameRow: {
    marginTop: 4,
    marginBottom: 2,
  },
}));

const NameRow = ({ userDetails }) => {
  const classes = useStyles();
  const { firstname, lastname } = userDetails || {};
  const intl = useIntl();

  return (
    <Box display="flex" className={classes.input}>
      {firstname && (
        <TextField
          className={classes.nameRow}
          label={intl.formatMessage({
            id: "account.firstName",
          })}
          name="firstname"
          value={firstname || ""}
          variant="standard"
          style={{ paddingRight: 5, fontWeight: 600 }}
          size="small"
          margin="dense"
          disabled
          fullWidth
        />
      )}
      {lastname && (
        <TextField
          className={classes.nameRow}
          label={intl.formatMessage({
            id: "account.lastName",
          })}
          name="lastname"
          value={lastname || ""}
          variant="standard"
          style={{ paddingRight: 5, fontWeight: 600 }}
          size="small"
          margin="dense"
          disabled
          fullWidth
        />
      )}
    </Box>
  );
};

export default NameRow;

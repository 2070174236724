import { createActions } from "redux-actions";

export const {
  addMetadata,
  initMetadata,
  removeMetadata,
  setMetadata,
} = createActions({
  ADD_METADATA: ({ value }) => ({ value }),
  INIT_METADATA: () => {},
  REMOVE_METADATA: ({ metaIndex }) => ({ metaIndex }),
  SET_METADATA: ({ label, value, metaIndex }) => ({ label, value, metaIndex }),
});

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLazyQuery, useMutation } from "@apollo/client";
import CreateIcon from "@mui/icons-material/Create";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "../../hooks";
import { ConfirmCloseModal } from "../common";
import GridLayout from "./GridLayout";
import ConfirmCloseModalRouterPrompt from "../common/Router/ConfirmCloseModalRouterPrompt";
import ConfirmRemoveWidgetModal from "./ConfirmRemoveWidgetModal";
import ManageDashboardsModal from "./ManageDashboardsModal.jsx";
import { WidgetsDrawer, WIDGET_PANELS } from "./WidgetsDrawer";
import {
  UPDATE_OVERVIEW_DASHBOARD,
  UPDATE_WORKSPACE_DASHBOARD
} from "../../graphql/mutations/DashboardContext";
import {
  OVERVIEW_DASHBOARD,
  WORKSPACE_DASHBOARD
} from "../../graphql/queries/DashboardContext";
import {
  copyArray,
  copyLayouts,
  initialDashboards,
  initialOverviewDashboardLayout,
  initialWorkspaceDashboardLayout,
  updateBorderRadius,
  updateBorderWidth
} from "./helper";
import { WIDGET_TYPES } from "../../constant/widgets";

const pageTopHeight = "96px";
const widgetsDrawerWidth = "470px";

const useStyles = makeStyles(theme => ({
  container: {
    overflowX: "auto",
    height: props => (props.isEditing ? `calc(100vh - ${pageTopHeight})` : ""),
    width: props => (props.isEditing ? `calc(100% - ${widgetsDrawerWidth})` : "")
  },
  gridContainer: { marginRight: widgetsDrawerWidth },
  iconButton: {
    backgroundColor: theme.palette.text.hint,
    borderRadius: "6px 0px 0px 6px",
    color: theme.palette.common.white,
    minWidth: 40,
    padding: "8px 0px 8px 4px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  },
  penIcon: { color: theme.palette.common.white },
  preLine: { whiteSpace: "pre-line" },
  root: {
    background: props => props.rootBG || theme.palette.component.pageBackground,
    position: "relative",
    width: props => (props.isEditing ? `calc(100% + ${widgetsDrawerWidth})` : "")
  },
  saveButton: {
    alignItems: "center",
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    left: 0,
    padding: "10px",
    position: "fixed",
    right: 0,
    top: "49px",
    zIndex: 10001
  },
  widgetIcon: {
    bottom: 20,
    position: "fixed",
    right: 0,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: { right: 20 }
  }
}));

const WorkspaceDashboard = ({ companyId, siteId, projectId, route }) => {

  const intl = useIntl();
  const snackbar = useSnackbar();

  const [initialDashboardSettings, setInitialDashboardSettings] = useState(
    null
  );
  const [currentDashboardSettings, setCurrentDashboardSettings] = useState(
    null
  );
  const [rows, setRows] = useState(initialDashboards); // TODO: DashboardSettings, ManageDashboards

  const [isEditing, setIsEditing] = useState(false);
  const [isOverview, setIsOverView] = useState(null);
  const [dashboardIndex, setDashboardIndex] = useState(null);
  const [currentWidgetIndex, setCurrentWidgetIndex] = useState(null);
  const [layouts, setLayouts] = useState({ lg: [] });
  const [previousLayouts, setPreviousLayouts] = useState({ lg: [] });
  const [canEditDashboard, setCanEditDashboard] = useState(false);
  const [initialLayout, setInitialLayout] = useState([]);
  const [newsImageUploadUrl, setNewsImageUploadUrl] = useState(""); // !!!
  
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [confirmRemoveWidgetModalData, setConfirmRemoveWidgetModalData] = useState(null);
  const [dirtyDashboard, setDirtyDashboard] = useState(false);
  const [dirtyDashboardConfiguration, setDirtyDashboardConfiguration] = useState(false);
  const [dirtyWidgetConfiguration, setDirtyWidgetConfiguration] = useState(false);


  //const [configuringWidget, setConfiguringWidget] = useState(false);
  //const [openDashboardSettings, setOpenDashboardSettings] = useState(false);
  //const [openWidgetToolbox, setOpenWidgetToolbox] = useState(false);

  const [widgetDrawerPanel, setWidgetDrawerPanel] = useState(WIDGET_PANELS.None);


  const [openManageDashboardsModal, setOpenManageDashboardsModal] = useState(false);

  

  const [showTextEditor, setShowTextEditor] = useState(false);
  const [widgetTitle, setWidgetTitle] = useState(null);

  const customStyles = {
    isEditing,
    rootBG: currentDashboardSettings?.background,
  }; // !!!
  const classes = useStyles(customStyles);

 

  const [
    getOverviewDashboard,
    {
      data: overviewData,
      loading: overviewDataLoading,
      error: overviewDataError,
    },
  ] = useLazyQuery(OVERVIEW_DASHBOARD);

  const [
    getWorkspaceDashboard,
    {
      data: workspaceData,
      loading: workspaceDataLoading,
      error: workspaceDataError,
    },
  ] = useLazyQuery(WORKSPACE_DASHBOARD);

  const [updateOverviewDashboard] = useMutation(UPDATE_OVERVIEW_DASHBOARD);
  const [updateWorkspaceDashboard] = useMutation(UPDATE_WORKSPACE_DASHBOARD);

  //// dashboard settings // TODO: DashboardSettings, ManageDashboards
  //const createData = ({ background, name, description, owner, template }) => {
  //  return { background, description, name, owner, template };
  //};

  const initializeLayouts = layout =>copyArray(layout, lg => setLayouts({ lg }));
  const restoreLayouts = () => copyLayouts(previousLayouts, setLayouts);
  const saveLayouts = () => copyLayouts(layouts, setPreviousLayouts);

  const updateInitialLayout = () => copyArray(layouts.lg, layout => setInitialLayout(layout));

  useEffect(() => {
    if (companyId) {
      setIsOverView(true);
      getOverviewDashboard();
    } else if (siteId) {
      setIsOverView(false);
      getWorkspaceDashboard({ variables: { siteId, route } });
    }

  }, [companyId, siteId]);

  useEffect(() => {
    if (
      overviewData &&
      overviewData.dashboardContext &&
      overviewData.dashboardContext.overviewDashboard
    ) {
      const {
        canManageDashboard,
        dashboardData,
        newsImageUploadUrl,
      } = overviewData.dashboardContext.overviewDashboard;

      const { content, dashboardSettings, id, type } = dashboardData;

      const layout =
        content === "" ? initialOverviewDashboardLayout : JSON.parse(content);

      setCanEditDashboard(canManageDashboard);
      setCurrentDashboardSettings(dashboardSettings);
      setInitialDashboardSettings(dashboardSettings);
      setInitialLayout(layout);

      initializeLayouts(layout);

      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 500); // react-grid-layout
    }
  }, [overviewData]);

  useEffect(() => {
    if (
      workspaceData &&
      workspaceData.dashboardContext &&
      workspaceData.dashboardContext.workspaceDashboard
    ) {
      const {
        canManageDashboard,
        dashboardData,
        newsImageUploadUrl,
      } = workspaceData.dashboardContext.workspaceDashboard;

      const { content, dashboardSettings, id, type } = dashboardData;

      const layout =
        content === "" ? initialWorkspaceDashboardLayout : JSON.parse(content);

      setCanEditDashboard(canManageDashboard);
      setCurrentDashboardSettings(dashboardSettings);
      setInitialDashboardSettings(dashboardSettings);
      setInitialLayout(layout);
      setNewsImageUploadUrl(newsImageUploadUrl);

      initializeLayouts(layout);

      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 500); // react-grid-layout
    }
  }, [workspaceData]);

  const displayChangesMustBeApprovedMessage = () => {
    snackbar.info(
      <Typography className={classes.preLine}>
        <FormattedMessage id="widget.changesMustBeApproved" />
      </Typography>
    );
  };

  const saveOverviewDashboard = async () => {
    try {
      const jsonContent = JSON.stringify(layouts.lg);
      const res = await updateOverviewDashboard({
        variables: {
          id: companyId /* !!! */,
          filter: "COMPANY" /* !!! */,
          jsonContent,
          dashboardSettings: currentDashboardSettings,
        },
      });

      if (
        res &&
        res.data &&
        res.data.dashboardContext &&
        res.data.dashboardContext.updateOverviewDashboard
      ) {
        const { success } = res.data.dashboardContext.updateOverviewDashboard;
        return { ok: success };
      }
    } catch (err) {
      console.error("** Error saving dashboard data:", err);
    }
    return { ok: false };
  };

  const saveWorkspaceDashboard = async () => {
    try {
      const jsonContent = JSON.stringify(layouts.lg);
      const res = await updateWorkspaceDashboard({
        variables: {
          id: siteId /* !!! */,
          filter: "SITE" /* !!! */,
          route,
          jsonContent,
          dashboardSettings: currentDashboardSettings,
        },
      });

      if (
        res &&
        res.data &&
        res.data.dashboardContext &&
        res.data.dashboardContext.updateWorkspaceDashboard
      ) {
        const { success } = res.data.dashboardContext.updateWorkspaceDashboard;
        return { ok: success };
      }
    } catch (err) {
      console.error("** Error saving dashboard data:", err);
    }
    return { ok: false };
  };

  const handleSaveDashboard = async () => {
    const result = isOverview
      ? await saveOverviewDashboard() // SAVE (overview dashboard)
      : await saveWorkspaceDashboard(); // SAVE (workspace dashboard)

    const { ok } = result;

    if (ok) {
      saveLayouts();
      updateInitialLayout();

      setInitialDashboardSettings(currentDashboardSettings);

      setDirtyDashboard(false);
      setDirtyDashboardConfiguration(false);
      setDirtyWidgetConfiguration(false);

      setIsEditing(false);

      snackbar.success(<FormattedMessage id="dashboard.success.savedDashboard" />);
    } else if (isOverview) {
      snackbar.error(<FormattedMessage id="dashboard.error.failedToSave" />);
    } else {
      snackbar.workspaceError(
        <FormattedMessage id="dashboard.error.failedToSave" />
      );
    }
  };

  const handleBeginEditing = () => {
    
    setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_GALLERY);

    setDirtyDashboard(false);
    setDirtyDashboardConfiguration(false);
    setDirtyWidgetConfiguration(false);
    setDashboardIndex(null);
    setIsEditing(true);
    
    
  };

  const handleFinishEditing = () => {

    if (widgetDrawerPanel == WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL && (dirtyWidgetConfiguration || showTextEditor) || dirtyDashboardConfiguration) {
      displayChangesMustBeApprovedMessage();
    } else if (dirtyDashboard) {
      setConfirmCloseModal(true);
    } else {
      setIsEditing(false);
    }
  };

  const handleSaveDashboardSettings = ({ isDirty, settings }) => {

    if (isDirty) {
      setCurrentDashboardSettings(settings);
      setDirtyDashboard(true);
    }
    setDirtyDashboardConfiguration(false);

    setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_GALLERY);

    setDashboardIndex(null);
  };

  const handleAddDashboard = () => {
    // TODO: ManageDashboards
    
    setOpenManageDashboardsModal(false);
   
    setWidgetDrawerPanel(WIDGET_PANELS.SETTINGS_LINK_PANEL);
  };

  const handleDeleteDashboard = dashboardIndex => {
    // TODO: ManageDashboards
    const tempDashboards = [...rows];
    tempDashboards.splice(dashboardIndex, 1);
    setRows(tempDashboards);
    setWidgetDrawerPanel(WIDGET_PANELS.NONE);    
    
  };

  const handleEditDashboard = (row, dashboardIndex) => {
    // TODO: ManageDashboards
    setCurrentDashboardSettings(row);
    setDashboardIndex(dashboardIndex); 
    
    setOpenManageDashboardsModal(false);

    setWidgetDrawerPanel(WIDGET_PANELS.NONE);  
  };

  const handleCloseDashboardSettings = ({ isDirty }) => {

    if (isDirty) {
      displayChangesMustBeApprovedMessage();
      return;
    }

    setDirtyDashboardConfiguration(false);

    setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_GALLERY);  

    setDashboardIndex(null);
  };

  const handleConfigureWidget = (widget, widgetIndex) => {

    const sameWidget = widgetIndex === currentWidgetIndex;

    if (sameWidget && widget.type === WIDGET_TYPES.TEXTEDITOR) {
      setShowTextEditor(true);
      return;
    }

    if (widgetDrawerPanel == WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL) {
      if ((dirtyWidgetConfiguration && !sameWidget) || showTextEditor) {
        displayChangesMustBeApprovedMessage();
        return;
      }
    } else {
      saveLayouts();

      setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL); 
    }

    setCurrentWidgetIndex(widgetIndex);

    setWidgetTitle(widget.name);
  };

  const handleCloseConfigure = async () => {

    if (widgetDrawerPanel == WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL && dirtyWidgetConfiguration) {
      displayChangesMustBeApprovedMessage();
      return;
    }

    setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_GALLERY); 

    setDirtyWidgetConfiguration(false);
    setDashboardIndex(null);    
    
  };

  const handleOpenManageDashboardsModal = () => {
    setOpenManageDashboardsModal(true);
  };

  const handleOpenDashboardSettings = () => {
    setWidgetDrawerPanel(WIDGET_PANELS.SETTINGS_LINK_PANEL); 
  };

  const handleCancelWidgetConfiguration = () => {

    if (widgetDrawerPanel == WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL && dirtyWidgetConfiguration) {
      restoreLayouts();
    }

    setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_GALLERY);
    
    setDirtyWidgetConfiguration(false);
    setCurrentWidgetIndex(null);
    
    setWidgetTitle(null);
  };

  const handleSaveWidgetConfiguration = () => {
    const lgs = [...layouts.lg];
    const widget = lgs[currentWidgetIndex];

    if (widget.type === WIDGET_TYPES.YOUTUBE) {
      delete widget.borderRadiusBottomLeft;
      delete widget.borderRadiusBottomRight;
      delete widget.borderRadiusTopLeft;
      delete widget.borderRadiusTopRight;
    }

    delete widget.image;

    widget.isConfigured = true;

    lgs[currentWidgetIndex] = widget;
    setLayouts({ lg: lgs });

    
    setDirtyDashboard(true);
    setDirtyWidgetConfiguration(false);
    setCurrentWidgetIndex(null);
    
    setWidgetTitle(null);

    setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_GALLERY);
  };

  const handleTextEditorCancel = () => {
    setShowTextEditor(false);
  };

  const handleTextEditorEdit = widgetIndex => {
    const sameWidget = widgetIndex === currentWidgetIndex;

    if (widgetDrawerPanel == WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL && dirtyWidgetConfiguration && !sameWidget) {
      displayChangesMustBeApprovedMessage();
      return;
    }

    setShowTextEditor(true);
  };

  const handleTextEditorUpdate = (widgetIndex, value) => {
    handleWidgetPropertyUpdate(widgetIndex, "textContent", value);
    setShowTextEditor(false);
  };

  const handleLayoutChanged = ({ cols }) => {
    console.log("** [WorkspaceDashboard] handleLayoutChanged", { cols });

    const lgs = [...layouts.lg];
    const lg = cols.map((col, index) => {
      const item = lgs[index];
      item.w = col.w;
      item.h = col.h;
      item.x = col.x;
      item.y = col.y;
      return item;
    });
    setLayouts({ lg });

    setDirtyDashboard(true);
  };

  const handleWidgetAdd = ({ layoutItem }) => {
    console.log("** [WorkspaceDashboard] handleWidgetAdd", { layoutItem });

    const lg = [...layouts.lg, layoutItem];
    setLayouts({ lg });

    setDirtyDashboard(true);
  };

  const handleWidgetPropertyUpdate = (widgetIndex, property, value) => {
    //TODO: Vi kan inte anta att vi använder "lg" hela tiden.
    
    const currentLayout = [...layouts.lg];
    const widget = currentLayout[widgetIndex];

    switch (property) {
      case "borderRadius": updateBorderRadius({ widget, position: value }); break;
      case "borderWidth": updateBorderWidth({ widget, action: value }); break;
      
      default: widget[property] = value;
    }

    setLayouts({ lg: currentLayout });
    setDirtyWidgetConfiguration(true);
  }

  const handleWidgetRemove = ({ layoutIndex }) => {
    setConfirmRemoveWidgetModalData({ layoutIndex });
  };

  const handleConfirmRemoveWidget = () => {
    const { layoutIndex } = confirmRemoveWidgetModalData || {};

    if (isFinite(layoutIndex)) {
      const lg = [...layouts.lg];
      lg.splice(layoutIndex, 1);
      setLayouts({ lg });

      setDirtyDashboard(true);
    }

    setConfirmRemoveWidgetModalData(null);
  };

  const restoreInitialState = () => {
    setCurrentDashboardSettings(initialDashboardSettings);
    initializeLayouts(initialLayout);
  };

  const finishEditingWithoutSaving = () => {
    
    setConfirmCloseModal(false);
    setDirtyWidgetConfiguration(false);
    setIsEditing(false);
    
    setShowTextEditor(false);

    setWidgetDrawerPanel(WIDGET_PANELS.WIDGET_GALLERY);

    restoreInitialState();
  };

  useEffect(() => {
    if (
      (isOverview && overviewDataError) ||
      (!isOverview && workspaceDataError)
    ) {
      var errorData = isOverview ? overviewDataError : workspaceDataError;

      console.error("** Error fetching dashboard data:", { ...errorData });

      snackbar.error(<FormattedMessage id="dashboard.error.failedToFetch" />);
    }
  }, [overviewDataError, workspaceDataError]);

  if (
    (isOverview && overviewDataLoading) ||
    (!isOverview && workspaceDataLoading)
  ) {
    return (
      <Grid item container xs={12} justifyContent="center">
        <CircularProgress size={30} />
      </Grid>
    );
  }

  if (
    (isOverview && overviewDataError) ||
    (!isOverview && workspaceDataError)
  ) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <GridLayout
          canEditDashboard={canEditDashboard}
          configuring={WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL == widgetDrawerPanel}
          configuringWidgetIndex={currentWidgetIndex}
          disabled={false} //TODO
          intl={intl}
          isEditing={isEditing}
          layouts={layouts}
          onConfigureWidget={handleConfigureWidget}
          onLayoutChanged={handleLayoutChanged}
          onSetShowTextEditor={setShowTextEditor}
          onTextEditorCancel={handleTextEditorCancel}
          onTextEditorEdit={handleTextEditorEdit}
          onTextEditorUpdate={handleTextEditorUpdate}
          onWidgetAdd={handleWidgetAdd}
          onWidgetRemove={handleWidgetRemove}
          showTextEditor={showTextEditor}
          siteId={ siteId }
          projectId={ projectId}
        />

        {canEditDashboard && !isEditing && (
          <Box className={classes.widgetIcon}>
            <Button
              aria-label="SettingsIcon"
              className={classes.iconButton}
              onClick={handleBeginEditing}
              size="small"
            >
              <CreateIcon className={classes.penIcon} />
            </Button>
          </Box>
        )}

        <WidgetsDrawer
          dashboardSettings={currentDashboardSettings}
          dirtyDashboard={dirtyDashboard}
          dirtyWidgetConfiguration={dirtyWidgetConfiguration}
          handleCloseConfigure={handleCloseConfigure}
          //handleNewsSiteBackground={handleNewsSiteBackground}  
          widgetDrawerPanel={widgetDrawerPanel }

          intl={intl}
          isOverview={isOverview}
          layouts={layouts}
          onCancelWidgetConfiguration={handleCancelWidgetConfiguration}
          onCloseDashboardSettings={handleCloseDashboardSettings}
          onDashboardSettingsUpdated={() => setDirtyDashboardConfiguration(true)}
          onFinishEditing={handleFinishEditing}
          onOpenDashboardSettings={handleOpenDashboardSettings}
          onOpenManageDashboards={
            handleOpenManageDashboardsModal /* TODO: ManageDashboards */
          }
          onSaveDashboard={handleSaveDashboard}
          onSaveDashboardSettings={handleSaveDashboardSettings}
          onSaveWidgetConfiguration={handleSaveWidgetConfiguration}
          onWidgetPropertyUpdate={handleWidgetPropertyUpdate}
          
          openManageDashboards={
            openManageDashboardsModal /* TODO: ManageDashboards */
          }
          
          openWidgetsDrawer={isEditing}
          
          setWidgetTitle={setWidgetTitle}
          widgetIndex={currentWidgetIndex}
          widgetTitle={widgetTitle}
        />

        {openManageDashboardsModal && (
          <ManageDashboardsModal
            onAdd={handleAddDashboard}
            onClose={() => setOpenManageDashboardsModal(false)}
            onDelete={handleDeleteDashboard}
            onEdit={handleEditDashboard}
            open={openManageDashboardsModal}
            rows={rows}
          />
        )}

        {confirmCloseModal && (
          <ConfirmCloseModal
            onCancel={() => setConfirmCloseModal(false)}
            onConfirmCloseModal={finishEditingWithoutSaving}
            open={confirmCloseModal}
            setOpen={setConfirmCloseModal}
          />
        )}

        {confirmRemoveWidgetModalData !== null && (
          <ConfirmRemoveWidgetModal
            onCancel={() => setConfirmRemoveWidgetModalData(null)}
            onConfirm={handleConfirmRemoveWidget}
          />
        )}

        <ConfirmCloseModalRouterPrompt when={dirtyDashboard || dirtyDashboardConfiguration || dirtyWidgetConfiguration} />
      </Box>
    </Box>
  );
};

export default WorkspaceDashboard;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Icon, IconButton } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  clearIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  iconButton: {
    padding: 0,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
}));

const ClearSettingsIcon = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.iconButton} onClick={onClick}>
      <Icon className={clsx(["fas fa-times", classes.clearIcon])} />
    </IconButton>
  );
};

ClearSettingsIcon.defaultProps = {};

ClearSettingsIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ClearSettingsIcon;

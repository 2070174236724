import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, Typography, IconButton, Popover, Icon } from "@mui/material";
import FormField from "./FormField";
import { removeSetting } from "../../../../actions";

const useStyles = makeStyles(theme => ({
  leftIconContainer: {
    width: 32,
  },
  actionsContainer: {
    marginLeft: 10,
  },
  actionIconButton: { backgroundColor: "rgba(0,0,0,0.15)", marginLeft: 10 },
  icon: {
    fontSize: 13,
    width: "unset",
    height: "unset",
    color: "#000",
    marginRight: 7,
  },
  box: {
    padding: "10px 20px 10px 20px",
    alignItems: "center",
    minWidth: 200,
  },
  typography: {
    cursor: "pointer",
    margin: "5px 0",
  },
  title: {
    flex: 1,
  },
  leftFavIcon: {
    paddingTop: 10,
    opacity: "0.3",
  },
  rowItem: {
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 0,
    height: 50,
    "&:hover": {
      "& span": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      height: "auto",
      marginBottom: 20,
    },
  },
}));

const RowItem = ({
  onClickActionIcon,
  title,
  subTitle,
  leftIcon,
  privacyIcon,
  actionIcon,
  iconLabel,
  handleOpenPrivacySetting,
  name,
  settingKey,
  showInputField,
  handleCancel,
  handleSave,
  handleChange,
  hideActionButtons,
  visibility,
  setShowInputField,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState(null);
  const [editLink, setEditLink] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editUserContextSetting = (val, keyVal) => {
    if (val === "weblinks") {
      setShowInputField(keyVal);
      setEditLink(keyVal);
      setEditValue(title);
      setIsEdit(true);
      setAnchorEl(null);
    } else {
      setShowInputField(settingKey);
      setIsEdit(true);
      setEditValue(title);
      setAnchorEl(null);
    }
  };

  const handleOk = () => {
    handleSave(isEdit ? "edit" : "add", title, editLink);
    setShowInputField(null);
    setIsEdit(false);
  };

  const cancelEdit = () => {
    handleCancel();
    setIsEdit(false);
    setEditLink(null);
    setEditValue(null);
  };

  const deleteUserContextSetting = (context, key) => {
    dispatch(removeSetting({ context, key }));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (isEdit && showInputField && showInputField !== settingKey) {
    setIsEdit(false);
    setEditLink(null);
    setEditValue(null);
  }

  return (
    <Box display="flex" className={classes.rowItem}>
      <Box alignItems="center" className={classes.leftIconContainer}>
        {title ? (
          <span>{leftIcon}</span>
        ) : (
          <span style={{ paddingTop: 10 }}>
              {!hideActionButtons ? (
              <span style={{ marginTop: "-10px" }}>{leftIcon}</span>
            ) : (
              <span className={classes.leftFavIcon}>{leftIcon}</span>
            )}
          </span>
        )}
      </Box>
      <Box display="flex" flexDirection="column" className={classes.title}>
        <span>
          {!isEdit ? (
            <span>
              {title ? (
                <Typography style={{ fontSize: 14 }}>
                  <span>
                    {name === "company" && (
                      <span>
                        <FormattedMessage id="account.workingOn" />
                      </span>
                    )}
                  </span>{" "}
                  {title}
                </Typography>
              ) : null}
            </span>
          ) : null}
        </span>
        {editLink === name && (
          <span>
            {isEdit && (
              <FormField
                value={editValue}
                handleCancel={cancelEdit}
                handleSave={handleOk}
                handleChange={handleChange}
              />
            )}
          </span>
        )}
        {showInputField === name ? (
          <FormField
            value={editValue}
            handleCancel={cancelEdit}
            handleSave={handleOk}
            handleChange={handleChange}
          />
        ) : (
          <div>
              {hideActionButtons ? (
              <Typography style={{ fontSize: 12, color: "rgba(0,0,0,0.55)" }}>
                {subTitle}
              </Typography>
            ) : (
              <div>
                {title ? (
                  <div>
                    {!isEdit ? (
                      <Typography
                        style={{
                          fontSize: 12,
                          color: "rgba(0,0,0,0.55)",
                        }}
                      >
                        {subTitle}
                      </Typography>
                    ) : null}
                  </div>
                ) : (
                  <Typography
                    style={{
                      fontSize: 12,
                      color: "rgba(0,0,0,0.55)",
                      opacity: 0.3,
                    }}
                  >
                    {subTitle}
                  </Typography>
                )}
              </div>
            )}
          </div>
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.actionsContainer}
      >
        {!hideActionButtons && title && (
          <div>            
              <IconButton
                onClick={() => handleOpenPrivacySetting({ name, visibility })}
              >
                {privacyIcon}
              </IconButton>            
            
              <IconButton
                size="small"
                id={id}
                className={classes.actionIconButton}
                onClick={handleClick}
              >
                {actionIcon}
              </IconButton>
          </div>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box className={classes.box}>
            <Typography
              className={classes.typography}
              onClick={() => editUserContextSetting(settingKey, name)}
            >
              <Icon className={clsx(["fas fa-pen", classes.icon])} />
              <FormattedMessage id="context.edit" /> {iconLabel}
            </Typography>

            <Typography
              className={classes.typography}
              onClick={() => deleteUserContextSetting(name, onClickActionIcon)}
            >
              <Icon className={clsx(["fas fa-trash", classes.icon])} />
              <FormattedMessage id="context.delete" /> {iconLabel}
            </Typography>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default RowItem;

RowItem.defaultProps = {
  name: "",
  hideActionButtons: false,
  showInputField: "",
  visibility: "",
  title: undefined,
  setShowInputField: () => {},
};

RowItem.propTypes = {
  onClickActionIcon: PropTypes.string.isRequired,
  leftIcon: PropTypes.node.isRequired,
  handleOpenPrivacySetting: PropTypes.func.isRequired,
  privacyIcon: PropTypes.node.isRequired,
  actionIcon: PropTypes.node.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.node.isRequired,
  showInputField: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.node,
  settingKey: PropTypes.string.isRequired,
  hideActionButtons: PropTypes.bool,
  visibility: PropTypes.string,
  setShowInputField: PropTypes.func,
  iconLabel: PropTypes.string.isRequired,
};

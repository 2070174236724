import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const MOVE_GROUP = gql`
  mutation MoveGroup($groupId: Int!, $parentGroupId: Int, $position: Int!) {
    companyAdminContext {
      companyGroupSettings {
        moveGroup(
          groupId: $groupId
          parentGroupId: $parentGroupId
          position: $position
        ) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default MOVE_GROUP;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Icon, Typography } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { MOVE_USERS } from "../../../graphql/mutations/CompanyAdminContext";
import { GROUPS } from "./helpers";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    padding: 5,
    overflowY: "auto",
    cursor: "pointer",
  },
  groupIcon: {
    fontSize: 14,
    color: "#5b5654",
    width: 18,
  },
  groupIconNoChild: { margin: "2px 3px 2px 31px !important" },
  labelText: {
    fontSize: "12px",
    wordBreak: " break-all",
    marginLeft: "7px",
  },
  draggedOver: {
    backgroundColor: "transparent",
    border: `2px dashed ${theme.palette.primary.dark}`,
    padding: 0,
  },
  notDraggedOver: {
    backgroundColor: "transparent",
    padding: 1,
  },
  selectedRow: props => ({
    "&:focus > .MuiTreeItem-content ": {
      backgroundColor: theme.palette.component.selectedFolder,
    },
    "& .MuiTreeItem-content": {
      borderRadius: 2,
      alignItems: "flex-start",
    },
    "& .MuiTreeItem-group": {
      marginTop: 2,
    },
  }),
  isSelected: {
    backgroundColor: theme.palette.component.selectedFolder,
  },
}));

const UnassignedGroup = ({
  selectedGroupId,
  companyId,
  onGroupRowClick,
  onUsersMoved,
}) => {
  const alert = useAlert();
  const isSelected = selectedGroupId === GROUPS.UNASSIGNED_USERS;
  const dynamicStyle = {
    isSelected,
  };
  const classes = useStyles(dynamicStyle);

  const [draggedOver, setDraggedOver] = useState(false);
  const [moveUsers] = useMutation(MOVE_USERS);

  const handleOnDragOver = e => {
    e.preventDefault();
    setDraggedOver(true);
  };
  const handleOnDragLeave = e => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const handleMoveUsers = async e => {
    const draggedUser = e.dataTransfer.getData("users");
    const userIds = JSON.parse(draggedUser);
    const targetGroup = GROUPS.UNASSIGNED_USERS;
    try {
      const res = await moveUsers({
        variables: { userIds, targetGroup, companyId },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.moveUsers
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyGroupSettings.moveUsers;

        if (success) {
          alert.success(
            <FormattedMessage id="userGroups.success.usersMoved" />
          );
          if (typeof onUsersMoved === "function") {
            onUsersMoved({ userIds, targetGroupId: targetGroup });
          }
        } else {
          errorMovingUsers("No success.");
        }
      } else {
        errorMovingUsers("No result.");
      }
    } catch (err) {
      errorMovingUsers(err);
    }
  };

  const errorMovingUsers = err => {
    console.error("Error moving users", err ?? "");
    alert.error(<FormattedMessage id="userGroups.error.couldNotMoveUsers" />);
  };

  const handleDrop = e => {
    e.stopPropagation();
    setDraggedOver(false);
    const action = e.dataTransfer.getData("action");
    if (action === "MOVE_USERS") {
      handleMoveUsers(e);
    }
  };

  const handleClick = () => {
    onGroupRowClick(GROUPS.UNASSIGNED_USERS);
  };

  return (
    <Box
      className={clsx([
        classes.root,
        draggedOver ? classes.draggedOver : classes.notDraggedOver,
        classes.selectedRow,
        isSelected && classes.isSelected,
      ])}
      onDrop={e => handleDrop(e)}
      onDragOver={handleOnDragOver}
      onDragLeave={handleOnDragLeave}
      onGroupRowClick={onGroupRowClick}
    >
      <Grid container alignItems="center" onClick={handleClick}>
        <Icon
          className={clsx([
            "fas fa-users",
            classes.groupIcon,
            classes.groupIconNoChild,
          ])}
        />
        <Typography className={classes.labelText}>
          <FormattedMessage id="userGroups.unassignedUsers" />
        </Typography>
      </Grid>
    </Box>
  );
};

UnassignedGroup.defaultProps = {
  selectedGroupId: null,
};

UnassignedGroup.propTypes = {
  selectedGroupId: PropTypes.number,
  onGroupRowClick: PropTypes.func.isRequired,
  onUsersMoved: PropTypes.func.isRequired,
};

export default UnassignedGroup;

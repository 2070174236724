import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 11,
    color: theme.palette.text.secondary,
    fontWeight: 600,
  },
}));

const FieldLabel = ({ children }) => {
  const classes = useStyles();

  return <Typography className={classes.label}>{children}</Typography>;
};

FieldLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldLabel;

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { GET_COMPANY_SALESDOCUMENTS } from "../../../graphql/queries";
import { getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";
import InvoiceModal from "./InvoiceModal";
import InvoicesTable from "./InvoicesTable";
import SplitButton from "./SplitButton";
import TabPanel from "./TabPanel";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    "& .MuiAppBar-root": {
      backgroundColor: "lightgray",
      color: "black",
    },
    "& .MuiCardContent-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    "& .DayPickerInput ": {
      width: "100%",
    },
    "& .DayPickerInput input": {
      color: theme.palette.text.primary,
      height: "100%",
      border: "1px solid #c4c4c4",
      borderRadius: "0px",
      padding: "10px 10px 10px 12px",
      fontSize: "12px",
      width: "100%",
      boxSizing: "border-box",
      "&:focus": {
        border: "2px solid #000",
      },
    },
    "& .react-datepicker-wrapper": {
      textAlign: "center",
    },
    "& .react-datepicker__input-container input": {
      color: theme.palette.text.primary,
      height: "100%",
      width: "100%",
      border: "none",
      borderRadius: "4px 4px 0px 0",
      borderLeft: "none",
      padding: "23px 12px 6px 12px",
      fontSize: "12px",
      textAlign: "center",
      background: "rgba(0, 0, 0, 0.03)",
      marginTop: "0px",
      marginRight: "0px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      background: "#353e47 !important",
      color: "#fff",
      borderRadius: "0!important",
    },
    "& .DayPickerInput-OverlayWrapper , .react-datepicker-popper": {
      zIndex: 100,
    },
    "& .DayPicker-Day": {
      borderRadius: "100% !important",
      fontSize: "16px !important",
      fontWeight: 600,
    },
    "& .DayPicker-Day--start": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "5px 0 0 5px!important",
    },
    "& .DayPicker-Day--end": {
      background: "#22292f !important",
      color: "#f0f8ff!important",
      borderRadius: "0 5px 5px 0!important",
    },
    "& .DayPickerInput-Overlay": {
      width: " 700px",
      marginLeft: "-198px",
    },
    "& .DayPicker-Day--weekends": {
      color: "#D32F2F",
      // background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--outside": {
      visibility: "hidden",
    },
    "& .DayPicker-Day--totallyBusy": {
      color: "#fff",
      background: "#D32F2F",
      "&:hover": {
        background: "#0CFBC2!important",
      },
    },
    "& .DayPicker-Day--partialBusy": {
      color: " #000000",
      background: "#fffad6",
    },
    "& .DayPicker-Weekday": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
    "& .DayPicker-Caption > div": {
      color: theme.palette.text.primary,
      fontWeight: "600!important",
    },
  },
  body: {
    padding: "0px 20px 20px 20px",
  },
  table: {
    minWidth: 650,
  },
  blue: {
    background: "#caeff7",
  },
  pink: {
    background: "#fce0e7",
  },
  green: {
    background: "#d9fce4",
  },
  purple: {
    background: "#f3edff",
  },
  btnReset: {
    padding: "6px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
}));

const getMax = (data, field, defaultValue) => {
    
  if (data && data.length == 0) {
    return defaultValue;
  }


  return Math.max(...data.map(row => row[field]));
};

const getMin = (data, field, defaultValue) => {
  
  if (data && data.length == 0) {
    return defaultValue;
  }

  return Math.min(...data.map(row => row[field]));
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Range = ({
  value,
  setter,
  field,
  onChange,
  max,
  min,
  label,
  valueLabelFormatter,
  marks,
}) => (
  <>
    <Typography id={`${field}-range-slider`} gutterBottom>
      {label}: {value[0]} - {value[1]}
    </Typography>
    <Slider
      value={value}
      onChange={(e, v) => {
        setter(v);
        if (onChange) onChange(e);
      }}
      valueLabelDisplay="auto"
      aria-labelledby={`${field}-range-slider`}
      max={max}
      min={min}
      getAriaValueText={valueLabelFormatter}
      marks={marks}
    />
  </>
);

const Dropdown = ({ id, value, onChange, label, options }) => {
  const forwardID = id ?? `${JSON.stringify(options)}-select`;
  return (
    <FormControl variant="filled" fullWidth size="small">
      <InputLabel id={`${forwardID}-label`}>{label}</InputLabel>
      <Select
        labelId={`${forwardID}-label`}
        fullWidth
        multiple
        id={forwardID}
        value={value}
        onChange={onChange}
        label={label}
        variant="filled"
      >
        {options.map(option => {
          const { label: optionLabel, value } = option;
          return (
            <MenuItem key={value} value={value}>
              {optionLabel}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const Invoices = ({}) => {
  const classes = useStyles();

  const companyId = useSelector(getAdminSelectedCompanyId);
  const [data, setData] = useState();
  const [selectedSalesDocumentId, setSelectedSalesDocumentId] = useState(-1);

  const [filteredData, setFilteredData] = useState();

  const [getSalesDocuments, {data: companySalesDocuments, loading, refetch }] = useLazyQuery( GET_COMPANY_SALESDOCUMENTS);

  const setInitialFilterValues = () => {
    const maxDocumentDate = getMax(data, "documentDate", moment().add(5,'d').toDate() );
    const minDocumentDate = getMin(data, "documentDate", moment().add(-5, 'd').toDate());

    const filterOptions = {
      minDocumentDate: maxDocumentDate,
      minDocumentDate: minDocumentDate,
      startDate: minDocumentDate,
      endDate: maxDocumentDate,
      filterDocumentNo: "",
    };

    setFilterOptions(filterOptions);
  };

  useEffect(() => {
    if (companyId > 0) {
      getSalesDocuments({
        variables: {
          companyId: companyId,
          documentType: "INVOICE",
          offset: 0,
          pageSize: 20
        }       
      })
    }

  }, [companyId])

  useEffect(() => {

    
    if (
      companySalesDocuments &&
      companySalesDocuments.companyAdminContext.salesDocuments.getCompanySalesDocuments
    ) {
      const documents = companySalesDocuments.companyAdminContext.salesDocuments.getCompanySalesDocuments.salesDocuments.map(
        r => {
          return {
            salesDocumentId: r.salesDocumentId,
            documentNumber: r.documentNumber,
            documentDate: new Date(r.documentDate),
            documentType: r.documentType,
            customerName: r.customerName,
            customerNo: r.customerNo,
            yourOrderReference: r.yourOrderReference,
            amountDue: r.amountDue,
            category: r.category,
            dueDate: r.dueDate ? new Date(r.dueDate) : null,
          };
        }
      );
      
      setData(documents);
      setFilteredData(documents);

      const maxDocumentDate = getMax(documents, "documentDate");
      const minDocumentDate = getMin(documents, "documentDate");

      const filterOptions = {
        maxDocumentDate: maxDocumentDate,
        minDocumentDate: minDocumentDate,
        startDate: minDocumentDate,
        endDate: maxDocumentDate,
        filterDocumentNumber: "",
      };

      setFilterOptions(filterOptions);
    }
  }, [companySalesDocuments]);

  const tblHeaders = [
    {
      field: "documentNumber",
      label: "Fakt.nr",
      type: "numeric",
    },
    {
      field: "custumerNo",
      label: "Kundnr",
      type: "numeric",
    },
    {
      field: "customerName",
      label: "Kundnamn",
    },
    {
      field: "yourOrderReference",
      label: "Er orderreferens",
    },
    {
      field: "documentType",
      label: "Typ",
    },
    {
      field: "category",
      label: "Kategori",
      //type: "tags"
    },
    {
      field: "documentDate",
      label: "Fakturadatum",
      type: "datetime",
    },
    {
      field: "dueDate",
      label: "Förfallodatum",
      type: "datetime",
    },
    {
      field: "amountDue",
      label: "Obetalt belopp",
      type: "numeric",
    },
  ];

  const [filterOptions, setFilterOptions] = useState({
    filterDocumentNumber: "",
    filterCategories: [],
  });

  const resetFilters = () => {
    setInitialFilterValues();
  };

  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setSelectedSalesDocumentId(-1);
    setOpen(true);
  };

  const openDialog2 = () => {};

  const openDialog3 = () => {};

  const splitButtonOptions = [
    { label: "Ny kundfaktura", handler: openDialog },
    { label: "Ny kreditfaktura", handler: openDialog2 },
    { label: "Ny återkommande faktura", handler: openDialog3 },
  ];

  const [selectedTab, setSelectedTab] = React.useState(0);

  const onChangeTab = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleUpdate = (value, property) => {
    var newProp = {};
    newProp[property] = value;
    var newValue = { ...filterOptions, ...newProp };
    setFilterOptions(newValue);
  };

  const handleClickRow = row => {
    setOpen(true);
    setSelectedSalesDocumentId(row.salesDocumentId);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const updatedRows = data;      

    setFilteredData(updatedRows);
  }, [filterOptions]);

  return (
    <Box className={classes.root}>
      <Box py={1} mx={8}>
        <Card elevation={0} sx={{ overflow: 'visible' }}>
          <CardHeader
            titleTypographyProps={{ variant: "h6" }}
            title="Fakturor"
          />
          <AppBar position="static">
            <Toolbar variant="dense">
              <Tabs
                value={selectedTab}
                onChange={onChangeTab}
                aria-label="Välj vilka fakturor som ska visas"
              >
                <Tab label="Utkast" {...a11yProps(0)} />
                <Tab label="Skapade fakturor" {...a11yProps(1)} />
              </Tabs>
              <Box flexGrow={1} />
              <SplitButton options={splitButtonOptions} />
            </Toolbar>
          </AppBar>

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} lg={2}>
                <TextField
                  value={filterOptions.filterDocumentNumber}
                  onChange={e =>
                    handleUpdate(e.target.value, "filterDocumentNumber")
                  }
                  label="Sök"
                  variant="filled"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                {
                  <Dropdown
                    id="category"
                    value={["TODO"]}
                    //onChange={e => setFilterCategories(e.target.value)}
                    label="Typ"
                    options={["TODO"]}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={4} lg={2}>
                {
                  <Dropdown
                    id="category"
                    value={["TODO"]}
                    //onChange={e => (e.target.value)}
                    label="Kategorier"
                    options={["TODO"]}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <DatePicker
                  wrapperClassName="datePicker"
                  selected={filterOptions.startDate}
                  onChange={date => handleUpdate(date, "startDate")}
                  selectsStart
                  minDate={filterOptions.minDocumentDate}
                  maxDate={filterOptions.maxDocumentDate}
                  dateFormat="yyyy-MM-dd"
                />
                <DatePicker
                  wrapperClassName="datePicker"
                  selected={filterOptions.endDate}
                  minDate={filterOptions.minDocumentDate}
                  maxDate={filterOptions.maxDocumentDate}
                  onChange={date => handleUpdate(date, "endDate")}
                  selectsEnd
                  dateFormat="yyyy-MM-dd"
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={1}>
                <Button onClick={resetFilters} className={classes.btnReset}>
                  Reset
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <TabPanel value={selectedTab} index={0} pt={0} mt={0}>
        <Box className={classes.body} pt={0} mt={0}>
          <Box m={2} mt={0} pt={0}>
            <InvoicesTable
              onClickRow={handleClickRow}
              dataRows={filteredData}
              headers={tblHeaders}
            />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        Skapade fakturor
      </TabPanel>
      <InvoiceModal
        open={open}
        selectedSalesDocumentId={selectedSalesDocumentId}
        handleClose={() => setOpen(false)}
      />
    </Box>
  );
};
export default Invoices;

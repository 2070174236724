import { gql } from '@apollo/client';
import SiteNewsListItemFragment from "../../fragments/SiteNewsListItem";

const GET_TEMPLATES_FOR_SITE = gql`
  query GetTemplatesForSite($siteId: Int!) {
    newsContext {
      getTemplatesForSite(siteid: $siteId) {
        ...SiteNewsListItemFields
      }
    }
  }
  ${SiteNewsListItemFragment}
`;

export default GET_TEMPLATES_FOR_SITE;

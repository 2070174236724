import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";
import AddressFragment from "../../../fragments/Address";
import AddressSettingsFragment from "../../../fragments/AdressSettings";

const EDIT_GROUP_ADDRESSES = gql`
  query GetCompanyGroups($groupId: Int!) {
    companyAdminContext {
      companyGroupSettings {
        editGroupAddresses(groupId: $groupId) {
          errorResult {
            ...ErrorResultFields
          }
          success
          result {
            groupId
            deliveryAddress {
              ...AddressFields
            }
            postalAddress {
              ...AddressFields
            }
            invoiceAddress {
              ...AddressFields
            }
            visitingAddress {
              ...AddressFields
            }
            deliveryAddressSettings {
              ...AddressSettingsFields
            }
            invoiceAddressSettings {
              ...AddressSettingsFields
            }
            postalAddressSettings {
              ...AddressSettingsFields
            }
            visitingAddressSettings {
              ...AddressSettingsFields
            }
          }
        }
      }
    }
  }
  ${AddressSettingsFragment}
  ${AddressFragment}
  ${ErrorResultFragment}
`;

export default EDIT_GROUP_ADDRESSES;

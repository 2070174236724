import { gql } from '@apollo/client';
import CompanyFieldsFragment from "./CompanyFields";

const CompanyRecursiveFragment = gql`
  fragment CompanyRecursive on CompanyNetwork {
    childCompanies {
      ...CompanyFields
      childCompanies {
        ...CompanyFields
        childCompanies {
          ...CompanyFields
          childCompanies {
            ...CompanyFields
            childCompanies {
              ...CompanyFields
              childCompanies {
                ...CompanyFields
              }
            }
          }
        }
      }
    }
  }

  ${CompanyFieldsFragment}
`;

export default CompanyRecursiveFragment;

import { gql } from '@apollo/client';

const PickingListAddressFragment = gql`
  fragment AddressFields on Address {
    address1
    address2
    address3
    address4
    address5
    city
    reference
    telephone
    zip
    customerId
    email
    country
    contact
  }
`;

export default PickingListAddressFragment;

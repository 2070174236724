import { gql } from '@apollo/client';

const getProductImages = gql`
  query GetThumbNail($productId: ID!) {
    product(id: $productId) {
      thumbnailImageUri
      productImageUri
    }
  }
`;

export default getProductImages;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const INIT_ATTACHMENT_UPLOAD = gql`
  mutation InitAttachmentUpload($siteChannelId: Int!) {
    siteChannelContext {
      initAttachmentUpload(siteChannelId: $siteChannelId) {
        success
        errorResult {
          ...ErrorResultFields
        }
        url
      }
    }
  }
  ${ErrorResultFragment}
`;

export default INIT_ATTACHMENT_UPLOAD;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Icon, IconButton } from "@mui/material";
import { FormattedTime } from "react-intl";
import clsx from "clsx";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FullScreenModal, FormattedDate } from "../../common";

const useStyles = makeStyles(theme => ({
  body: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  created: {
    fontSize: "80%",
    opacity: 0.8,
  },
  leftDiv: {
    display: "flex",
    flexDirection: "column",
  },
  rightDiv: {
    display: "flex",
    flexDirection: "row",
  },
  iconButton: {
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },
  icon: { color: "rgba(255,255,255,0.8)", fontSize: 20 },
  thumbnailUrl: {},
  thumbnailUrlContainer: {},
  carousel: {
    border: "3px solid",
    borderColor: "rgba(0,0,0,0.2)",
    borderStyle: "double",
    "&:hover .control-arrow": {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
    "& .slide": {
      backgroundColor: "transparent",
      margin: "auto",
    },
    "& .carousel-status": {
      display: "none",
    },
    "& .carousel .control-dots": {
      margin: 0,
      top: 290,
    },
    "& .carousel .control-dots .dot": {
      width: 12,
      height: 12,
      boxShadow: "none",
      opacity: 1,
      background: "#fff",
      margin: "0 5px",
    },
    "& .carousel .control-dots .dot.selected": {
      background: theme.palette.primary.main,
    },
    "& .carousel .thumbs-wrapper": {
      display: "none",
    },
    "& .MuiAvatar-square": {
      width: "initial",
      height: "initial",
    },
    "& .MuiAvatar-square.MuiAvatar-colorDefault": {
      height: 170,
      margin: "auto",
    },
  },
}));

const ImageModal = ({ files, initialIndex, onClose, onDownload, open }) => {
  const classes = useStyles();

  const [currentFile, setCurrentFile] = useState(null);

  useEffect(() => {
    if (open) {
      selectFile(initialIndex);
    }
  }, [open, initialIndex]);

  const selectFile = fileIndex => {
    const file = files[fileIndex];
    setCurrentFile(file ? { ...file } : null);
  };

  const handleCarouselChange = fileIndex => {
    selectFile(fileIndex);
  };

  const handleDownloadClick = () => {
    var { attachmentId } = currentFile;
    onDownload({ attachmentId });
  };

  const renderLeftDiv = () => {
    return (
      currentFile && (
        <Box className={classes.leftDiv}>
          <Box>{currentFile.filename}</Box>
          <Box className={classes.created}>
            <FormattedDate value={currentFile.created} />{" "}
            <FormattedTime value={currentFile.created} />
          </Box>
        </Box>
      )
    );
  };

  const renderBody = () => {
    return (
      <Box className={classes.body}>
        <Carousel
          className={classes.carousel}
          onChange={handleCarouselChange}
          selectedItem={initialIndex}
          showArrows
          showIndicators={false}
          showThumbs={false}
        >
          {files &&
            files.map(file => {
              return (
                <Box className={classes.thumbnailUrlContainer} key={file}>
                  {false && <img alt={file.filename} src={file.thumbnailUrl} />}
                  {!false && (
                    <Avatar
                      alt="?"
                      classes={{
                        img: classes.avatarImg,
                      }}
                      className={classes.avatar}
                      src={file.thumbnailUrl}
                      variant="square"
                    />
                  )}
                </Box>
              );
            })}
        </Carousel>
      </Box>
    );
  };

  const renderRightDiv = () => {
    return (
      <Box className={classes.rightDiv}>
        <IconButton
          className={classes.iconButton}
          disabled={!currentFile}
          onClick={handleDownloadClick}
        >
          <Icon className={clsx(["fa fa-download", classes.icon])} />
        </IconButton>
      </Box>
    );
  };

  return (
    <FullScreenModal
      handleClose={onClose}
      open={open}
      renderBody={renderBody}
      renderLeftDiv={renderLeftDiv}
      renderRightDiv={renderRightDiv}
    />
  );
};

ImageModal.defaultProps = { files: [] };

ImageModal.propTypes = {
  initialIndex: PropTypes.number.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.string,
      filename: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ),
  onClose: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ImageModal;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { MAKE_COMPANY_SUPPLIER_ALIAS } from "../../../graphql/mutations";

import { UPDATE_COMPANY_SUPPLIER } from "../../../graphql/mutations/CompanyAdminContext";
import SuppliersTable from "./SuppliersTable";
import { validateEmail } from "../../../helpers/validators";
import AgreementModal from "./AgreementModal";
import SupplierAlias from "./SupplierAlias";

const SupplierSettings = ({
  active,
  supplierId,
  supplierAlias,
  loading,
  getCompanySupplierSettings,
  supplierVisibility,
  companyId,
}) => {
  const alert = useAlert();
  const [supplierList, setSupplierList] = useState([]);
  const [activeSupplier, setActiveSupplier] = useState(false);
  const [supplierType, setSupplierType] = useState(supplierVisibility);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  const [agreementModal, setAgreementModal] = useState(false);

  const [updateCompanySupplier] = useMutation(UPDATE_COMPANY_SUPPLIER);
  const [makeCompanySupplierAlias] = useMutation(MAKE_COMPANY_SUPPLIER_ALIAS);

  const handleAddSupplierAlias = async () => {
    const isEmail = validateEmail(email);
    if (isEmail) {
      try {
        const res = await makeCompanySupplierAlias({
          variables: { supplierAliasEmail: email },
        });
        if (res && res.data.makeCompanySupplierAliasRequest) {
          const { success } = res.data.makeCompanySupplierAliasRequest;

          if (success) {
            alert.success(
              <FormattedMessage id="supplier.addedSupplierAlias" />
            );
            getCompanySupplierSettings();
            setEmail("");
          } else {
            alert.error(
              <FormattedMessage id="supplier.errorAddingSupplierAlias" />
            );
          }
        }
      } catch (err) {
        console.error("error >", err);
        setEmail("");
      }
    } else {
      setError(true);
      setEmail("");
    }
  };

  const handleEmail = e => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleCheckbox = async e => {
    const { checked } = e.target;

    if (checked) {
      setAgreementModal(true);
    } else {
      const res = await updateCompanySupplier({
        variables: { companyId, active: false, visibility: supplierType },
      });
      try {
        if (
          res &&
          res.data &&
          res.data.companyAdminContext &&
          res.data.companyAdminContext.suppliers &&
          res.data.companyAdminContext.suppliers.updateCompanySupplier
        ) {
          setActiveSupplier(false);
          alert.success(<FormattedMessage id="supplier.supplierIsInactive" />);
        }
      } catch (err) {
        console.error("error >", err);
      }
    }
  };

  const handleAgree = async () => {
    try {
      const res = await updateCompanySupplier({
        variables: { companyId, active: true, visibility: supplierType },
      });
      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.suppliers &&
        res.data.companyAdminContext.suppliers.updateCompanySupplier
      ) {
        setActiveSupplier(true);
        alert.success(<FormattedMessage id="supplier.supplierIsActive" />);
      }
    } catch (err) {
      console.error("error >", err);
    }

    setAgreementModal(false);
  };

  const handleSupplierVisibility = async visibility => {
    const res = await updateCompanySupplier({
      variables: { companyId, active: activeSupplier, visibility },
    });
    if (
      res &&
      res.data &&
      res.data.companyAdminContext &&
      res.data.companyAdminContext.suppliers &&
      res.data.companyAdminContext.suppliers.updateCompanySupplier
    ) {
      if (visibility === "PUBLIC") {
        alert.success(<FormattedMessage id="supplier.supplierIsPublic" />);
      } else {
        alert.success(<FormattedMessage id="supplier.supplierIsPrivate" />);
      }
    }
    setSupplierType(visibility);
  };

  useEffect(() => {
    setActiveSupplier(active);
  }, [active]);

  useEffect(() => {
    setSupplierType(supplierVisibility);
  }, [supplierVisibility]);

  useEffect(() => {
    if (
      supplierAlias &&
      Array.isArray(supplierAlias) &&
      supplierAlias.length > 0
    ) {
      setSupplierList(supplierAlias);
    }
  }, [supplierAlias]);

  return (
    <Box>
      <SupplierAlias
        activeSupplier={activeSupplier}
        handleCheckbox={handleCheckbox}
        setSupplierType={handleSupplierVisibility}
        supplierType={supplierType}
        email={email}
        handleEmail={handleEmail}
        setError={setError}
        error={error}
        handleAddSupplierAlias={handleAddSupplierAlias}
        supplierId={supplierId}
      />

      {agreementModal && (
        <AgreementModal
          open={agreementModal}
          setOpen={setAgreementModal}
          onClick={handleAgree}
        />
      )}
    </Box>
  );
};

SupplierSettings.defaultProps = {
  active: false,
  supplierId: null,
  supplierAlias: null,
  loading: false,
};

SupplierSettings.propTypes = {
  active: PropTypes.bool,
  supplierId: PropTypes.number,
  supplierAlias: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  getCompanySupplierSettings: PropTypes.func.isRequired,
};

export default SupplierSettings;

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import WarningImage from "../../assets/img/warning-icon.svg";
import { ClearButton, PrimaryButton } from "./Buttons";
import DraggableModal from "./DraggableModal";
import DraggableModalBody from "./DraggableModal/DraggableModalBody";
import DraggableModalHead from "./DraggableModal/DraggableModalHead";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  img: {
    marginBottom: 10,
    width: 40,
  },
  bodyContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
  },
}));

const ConfirmCloseModal = ({
  onConfirmCloseModal,
  onCancel,
  open,
  setOpen,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      customClass={classes.modal}
      handleClose={() => setOpen(false)}
      open={open}
    >
      <DraggableModalHead handleClose={() => setOpen(false)} />
      <DraggableModalBody>
        <Box className={classes.bodyContainer}>
          <img alt="Warning" className={classes.img} src={WarningImage} />
          <FormattedMessage id="common.unsavedMessage" />
        </Box>
        <Box align="right" className={classes.modalFooter}>
          <ClearButton
            marginRight={15}
            onClick={() => {
              return onCancel ? onCancel() : setOpen(false);
            }}
          >
            <FormattedMessage id="common.cancel" />
          </ClearButton>
          <PrimaryButton
            onClick={() => {
              onConfirmCloseModal();
            }}
          >
            <FormattedMessage id="common.discardChanges" />
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmCloseModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirmCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ConfirmCloseModal;

import { withStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const HeaderName = withStyles(theme => ({
  root: {
    fontSize: 18,
    display: "inline-block",
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
}))(Typography);

export default HeaderName;

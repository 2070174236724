import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import MessageTime from "./MessageTime";
import FileAttachmentsContainer from "./FileAttachmentsContainer";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    maxWidth: "80%",
    marginRight: "auto",
  },
  profile: {
    minWidth: 30,
    minHeight: 30,
    margin: "0 10px auto 0",
  },
  profileImg: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "#EDEDED",
  },
  messageContainer: ({ myMessage }) => ({
    padding: 10,
    backgroundColor: myMessage ? "#0499FF" : theme.palette.common.white,
    color: myMessage ? theme.palette.common.white : "#000",
    borderRadius: 6,
    lineHeight: 1.7,
  }),
  messageContent: {
    whiteSpace: "pre-wrap",
  },
  messageInfo: {
    marginBottom: 5,
    marginLeft: 40,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

const Message = ({ message, myMessage, onDownload, onImageClick }) => {
  const classes = useStyles({
    myMessage,
  });

  const {
    messageTime,
    messageparts,
    author,
    authorUsername,
    files = [],
    avatarUrl,
  } = message;

  return (
    <Box>
      <Box className={classes.messageInfo} alignItems="center" display="flex">
        <Typography className={classes.name}>{author}</Typography>
        <MessageTime dateTime={messageTime} />
      </Box>
      <Box className={classes.root}>
        <Box className={classes.profile}>
          <img alt="Profile" className={classes.profileImg} src={avatarUrl} />
        </Box>
        <Box className={classes.messageContainer}>
          <Box className={classes.messageContent}>
            {messageparts && messageparts.map(x => x.content)}
          </Box>
          <FileAttachmentsContainer
            files={files}
            onDownload={onDownload}
            onImageClick={onImageClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

Message.defaultProps = {};

Message.propTypes = {
  message: PropTypes.shape({
    author: PropTypes.string,
    authorUserId: PropTypes.number,
    authorUsername: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape({})),
    messageparts: PropTypes.arrayOf(PropTypes.shape({})),
    messageTime: PropTypes.string,
  }).isRequired,
  myMessage: PropTypes.bool.isRequired,
  onDownload: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default Message;

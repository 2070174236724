import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Box, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DOCUMENT_VIEW,
  FILE_VIEW,
  IMAGE_VIEW,
  PRODUCT_LIST_VIEW,
} from "../../../constant/types";

const useStyles = makeStyles(theme => ({
  pageView: {
    alignItems: "center",
    display: "flex",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  viewIcon: {
    backgroundColor: theme.palette.component.viewSwitchBackground,
    color: "#4c4c4c",
    cursor: "pointer",
    display: "inline",
    fontSize: 12,
    padding: "7px 12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  activeView: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const buttonSettings = {
  [IMAGE_VIEW]: { icon: "fa-th", textId: "view.thumbnail" },
  [DOCUMENT_VIEW]: { icon: "fa-th-large", textId: "view.image" },
  [PRODUCT_LIST_VIEW]: { icon: "fa-th-list", textId: "view.product" },
  [FILE_VIEW]: { icon: "fa-align-justify", textId: "view.file" },
};

const ProductViews = ({ onChange, view }) => {
  const classes = useStyles();
  const viewSettings = useSelector(state => state.api.search.settings);

  const { detailedImageView, fileView, imageView, productView } = viewSettings || {};

  const ViewButton = ({ viewId }) => {
    const { icon, textId } = buttonSettings[viewId];
    const isActive = (view === viewId);

    return (
      <Tooltip
        classes={{ popper: classes.popper }}
        placement="bottom"
        title={<FormattedMessage id={textId} />}
      >
        <Box
          className={clsx(classes.viewIcon, isActive && classes.activeView)}
          onClick={() => !isActive && onChange(viewId)}
        >
          <i className={`fa ${icon}`} />
        </Box>
      </Tooltip>
    );
  };

  return (
    <div className={classes.pageView}>
      {"VISIBLE" === imageView && <ViewButton viewId={IMAGE_VIEW} />}
      {"VISIBLE" === detailedImageView && <ViewButton viewId={DOCUMENT_VIEW} />}
      {"VISIBLE" === productView && <ViewButton viewId={PRODUCT_LIST_VIEW} />}
      {"VISIBLE" === fileView && <ViewButton viewId={FILE_VIEW} />}
    </div>
  );
};

ProductViews.propTypes = {
  onChange: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

export default ProductViews;

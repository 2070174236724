import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import ImageViewMasonry from "./ImageViewMasonry";
import ViewContainer from "./ViewContainer";
import EmptyList from "./EmptyList";
import NoViewPermission from "./NoViewPermission";

const useStyles = makeStyles(theme => ({
  spacing: {
    "& > .MuiGrid-item": {
      padding: 1,
    },
  },
}));

const ImageView = ({ products, siteRoleRights }) => {
  const classes = useStyles();

  return (
    <ViewContainer>
      {siteRoleRights && siteRoleRights.imageView ? (
        <Grid container spacing={1} className={classes.spacing}>
          {products && products.items && products.items.length > 0 ? (
            <ImageViewMasonry numCols={6} products={products.items} />
          ) : (
            <EmptyList messageId="product.categoryEmpty" />
          )}
        </Grid>
      ) : (
        <NoViewPermission />
      )}
    </ViewContainer>
  );
};

ImageView.propTypes = {
  products: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        thumbnailImageUri: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  siteRoleRights: PropTypes.shape({ imageView: PropTypes.bool }).isRequired,
};

export default ImageView;

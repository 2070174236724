import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const CREATE_RESOURCE_LINK = gql`
  mutation CreateResourceLink($resourceId: ID!, $folderId: Int!) {
    productEditContext {
      createResourceLink(resourceId: $resourceId, folderId: $folderId) {
        errorResult {
          ...ErrorResultFields
        }
        resourceLinkId
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_RESOURCE_LINK;

import React from "react";
import { Typography, Grid, Divider, Table, TableBody } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import ManageRolesTableRow from "./ManageRolesTableRow";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
}));

const OrderingSystem = ({
  disabled,
  handleCheckbox,
  handleSelect,
  roleSubscriptionLevel,
  siteRoleValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const headers = [
    {
      label: intl.formatMessage({
        id: "siteRoles.canAccessOrderingModule",
      }),
      name: "productView",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canCreateDynamicTemplate",
      }),
      name: "createTemplate",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canCreatePackages",
      }),
      name: "canCreatePackages",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canPublishProducts",
      }),
      name: "canPublishProducts",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canAttestShoppingCart",
      }),
      name: "canAttestShoppingCart",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canOrderWithoutAttest",
      }),
      name: "orderAttestType",
      hideLabel: true,
      toolTipInfo: true,
      options: [
        {
          label: intl.formatMessage({
            id: "support.yes",
          }),
          value: "ALWAYS",
        },
        {
          label: intl.formatMessage({
            id: "btn.no",
          }),
          value: "NEVER",
        },
        {
          label: intl.formatMessage({
            id: "siteRoles.productControlled",
          }),
          value: "PRODUCT_DEPENDENT",
        },
      ],
      checkbox: false,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canEditOrderHistory",
      }),
      name: "canEditOrderHistory",
      checkbox: true,
      toolTipInfo: true,
    },

    {
      label: intl.formatMessage({
        id: "siteRoles.canSeeAllOrderHistory",
      }),
      name: "canSeeAllOrderHistory",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canAddToExistingOrder",
      }),
      name: "canAddToExistingOrder",
      checkbox: true,
      toolTipInfo: true,
    },

    {
      label: intl.formatMessage({
        id: "siteRoles.canManageSuppliers",
      }),
      name: "canManageSuppliers",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.supplierToWorkspace",
      }),
      name: "supplierInWorkspace",
      checkbox: true,
      toolTipInfo: true,
      child: [
        {
          label: intl.formatMessage({
            id: "siteRoles.canOnlySeeAssignedProducts",
          }),
          name: "canOnlySeeAssignedProducts",
          checkbox: true,
          toolTipInfo: true,
        },
        {
          label: intl.formatMessage({
            id: "siteRoles.canNotSeeOtherSuppliers",
          }),
          name: "canNotSeeOtherSuppliers",
          checkbox: true,
          toolTipInfo: true,
        },
      ],
    },
  ];

  return (
    <Grid item xs={12} md={6}>
      <Typography className={classes.text}>
        <FormattedMessage id="siteRoles.manageOrderingSystem" />
      </Typography>
      <Divider className={classes.divider} />
      <Table className={classes.table} size="small">
        <TableBody>
          {headers.map(head => {
            const hideLabel = head && head.hideLabel ? head.hideLabel : false;
            const toolTipInfo = head.toolTipInfo ? head.toolTipInfo : false;
            return (
              <ManageRolesTableRow
                checkbox={head.checkbox}
                disabled={disabled}
                child={head.child}
                hideLabel={hideLabel}
                key={head.name}
                label={head.label}
                name={head.name}
                onChange={
                  head.options && head.options.length > 0
                    ? handleSelect
                    : handleCheckbox
                }
                options={head.options}
                roleSubscriptionLevel={roleSubscriptionLevel}
                siteRoleValues={siteRoleValues}
                toolTipInfo={toolTipInfo}
              />
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

OrderingSystem.defaultProps = {
  disabled: false,
  siteRoleValues: {},
};

OrderingSystem.propTypes = {
  disabled: PropTypes.bool,
  handleCheckbox: PropTypes.func.isRequired,
  siteRoleValues: PropTypes.shape({}),
};

export default OrderingSystem;

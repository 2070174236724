import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import TreeItem from "@mui/lab/TreeItem";
import { Box, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReactComponent as WorkspaceIconSVG } from "../../../assets/img/WorkspaceIconSmallFillToCurrentColor.svg";

const useStyles = makeStyles(() => ({
  content: {
    cursor: "default",
    marginBottom: 4,
    padding: "5px 0 6px 0",
    width: "auto",
  },
  labelRoot: {
    alignItems: "center",
    color: "#555",
    cursor: "pointer",
    display: "flex",
    textDecoration: "none",
    "&:active,&:focus,&:hover": {
      color: "#555",
    },
  },
  labelDisabledNode: {
    alignItems: "center",
    color: "#aaa",
    cursor: "default",
    display: "flex",
    textDecoration: "none",
  },
  labelIcon: {
    color: "#555",
    fontSize: 15,
    marginLeft: -2,
    marginRight: 7,
    minWidth: "1.3em",
    overflow: "unset",
    textAlign: "center",
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "14px",
  },
  svgIcon: {
    height: "1.04em",
    "& > svg": {
      height: "1.04em",
    },
  },
}));

const AdminSidebarTreeItem = ({
  childtree,
  enabledByRights,
  icon,
  idx,
  label,
  link,
  usersRights,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const checkEnabled = validRights =>
    validRights.some(x => (usersRights ?? []).includes(x));

  const rootItem = {
    enabledByRights,
    icon,
    label,
    link,
  };

  const ItemIcon = ({ item }) => {
    switch (item.icon) {
      case "fas fa-layer-group":
        return (
          <span className={clsx([classes.svgIcon, classes.labelIcon, "fas"])}><WorkspaceIconSVG /></span>
        );
      case "fas fa-question":
        return (
          <span className="fa-stack fa-2x" style={{ fontSize: "0.58em", marginLeft: "-2px", marginRight: "7px" }}>
            <span className="fas fa-file fa-stack-2x" style={{verticalAlign: "middle"}}></span>
            <span className="fas fa-question fa-stack-1x fa-inverse" style={{ verticalAlign: "middle", marginLeft: "-0.7px", marginTop: "1.4px" }}></span>
          </span>
        );
      case "fas fa-exclamation":
        return (
          <span className="fa-stack fa-2x" style={{ fontSize: "0.58em", marginLeft: "-2px", marginRight: "7px" }}>
            <span className="fas fa-file fa-stack-2x" style={{ verticalAlign: "middle" }}></span>
            <span className="fas fa-exclamation fa-stack-1x fa-inverse" style={{ verticalAlign: "middle", marginLeft: "-0.7px", marginTop: "1.4px" }}></span>
          </span>
        );
    default: return (<Icon className={clsx([`${item.icon}`, classes.labelIcon])} />);
    }
  };

  const IconWithLabel = ({ item }) => (
    <>
      <ItemIcon item={item} />
      <Typography className={classes.label}>
        {intl.formatMessage({ id: item.label })}
      </Typography>
    </>
  );

  const createLabel = item => {
    const isEnabled = checkEnabled(item.enabledByRights);

    return (
      <>
        {isEnabled && !item.link && (
          <Box className={classes.labelRoot}>
            <IconWithLabel item={item} />
          </Box>
        )}

        {isEnabled && item.link && (
          <Link to={item.link} className={classes.labelRoot}>
            <IconWithLabel item={item} />
          </Link>
        )}

        {!isEnabled && (
          <Box className={classes.labelDisabledNode}>
            <IconWithLabel item={item} />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {childtree.length > 0 ? (
        <TreeItem
          classes={{
            content: classes.content,
            label: classes.label,
            root: classes.item,
            selected: classes.selected,
          }}
          label={createLabel(rootItem)}
          nodeId={`parent-${idx}`}
        >
          {childtree.map((child, childIdx) => (
            <TreeItem
              classes={{
                content: classes.content,
                label: classes.label,
                root: classes.item,
                selected: classes.selected,
              }}
              key={`tree-item-${idx}-${childIdx}`}
              label={createLabel(child)}
              nodeId={`child-${idx}-${childIdx}`}
            />
          ))}
        </TreeItem>
      ) : (
        <TreeItem
          classes={{
            content: classes.content,
            label: classes.label,
            root: classes.item,
            selected: classes.selected,
          }}
          label={createLabel(rootItem)}
          nodeId={`${idx}`}
        />
      )}
    </>
  );
};

AdminSidebarTreeItem.propTypes = {
  childtree: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enabledByRights: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default AdminSidebarTreeItem;

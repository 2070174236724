import { isEqual as dateFnsIsEqual } from "date-fns";
import lodash from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  Divider,
  Stack,
  Card,
  Grid
} from "@mui/material";

import { TimeLogAction } from "../helper";
import NameField from "./NameField";
import DescriptionField from "./DescriptionField";
import JoditTextEditor from "../../common/JoditTextEditor";
import TaskDetailsGrid from "./TaskDetailsGrid";
import { useMutation } from "@apollo/client";
import SAVE_COMMENT from "../../../graphql/mutations/ProjectEditContext/saveComment";
import CommentBox from "./CommentBox";

const TaskDetailsBase = ({ renderComponent: RenderComponent, currentProject, columns, disabled, enableEdit, item, onSave, userLookup, onTimeLogAction }) => {

  const [dirtyProperties, setDirtyProperties] = useState({
    assignments: false,
    createdBy: false,
    description: false,
    endDate: false,
    name: false,
    startDate: false,
  });
  const [editItem, setEditItem] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [reset, setReset] = useState(false);

  const handleSave = (e) => {
    onSave?.({ item: editItem });
  };

  const [saveComment, { data, loading, error }] = useMutation(SAVE_COMMENT);

  useEffect(() => {

    if (data) {
      setCurrentComment("");
    }
    else if (loading) {

    }
    else if (error) {

    }


  }, [data, loading, error])

  const handleTaskAssignmentPropertyChange = (name, value) => {
    const originalValue = item[name] ? item[name][0] : null;
    const editValue = editItem[name] ? editItem[name][0] : null;

    if (lodash.isEqual(value, originalValue)) {
      setDirtyProperties(prev => ({
        ...prev,
        [name]: false,
      }));
    }
    else if (lodash.isEqual(editValue, originalValue)) {
      setDirtyProperties(prev => ({
        ...prev,
        [name]: true,
      }));
    }

    setEditItem(prev => ({
      ...prev,
      [name]: value ? [value] : [],
    }));
  };

  const handleTaskDatePropertyChange = (name, value) => {
    const isDateEqual = (first, second) => {
      const dateOnly = (date) => {
        var o = new Date(date);
        o.setHours(0, 0, 0, 0);
        return o;
      }
      return dateFnsIsEqual(dateOnly(first), dateOnly(second));
    }

    const originalValue = item[name];
    const editValue = editItem[name];

    if ((!value && !originalValue) || isDateEqual(value, originalValue)) {
      setDirtyProperties(prev => ({
        ...prev,
        [name]: false,
      }));
    }
    else if ((!editValue && !originalValue) || isDateEqual(editValue, originalValue)) {
      setDirtyProperties(prev => ({
        ...prev,
        [name]: true,
      }));
    }

    setEditItem(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTaskPropertyChange = (name, value) => {
    const originalValue = item[name];
    const editValue = editItem[name];

    if (lodash.isEqual(value, originalValue)) {
      setDirtyProperties(prev => ({
        ...prev,
        [name]: false,
      }));
    }
    else if (lodash.isEqual(editValue, originalValue)) {
      setDirtyProperties(prev => ({
        ...prev,
        [name]: true,
      }));
    }

    setEditItem(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTaskPropertyDirty = (name, dirty) => {
    setDirtyProperties(prev => ({
      ...prev,
      [name]: dirty,
    }));
  };

  const handleTaskValueChange = (fieldId, value) => {
    var field = currentProject.projectFields.find(f => f.fieldId === fieldId);
    var valueField = '';
    switch (field.type) {

      case 4: //duration and numeric 
      case 1: valueField = 'numericValue'; break;

      case 0: //string and status
      case 3: valueField = 'stringValue'; break;

      case 2: valueField = 'dateTimeValue'; break;

      default: valueField = 'stringValue';
    }

    setDirtyProperties(prev => ({
      ...prev,
      [`_${fieldId}`]: true,
    }));

    setEditItem(prev => {
      let values = lodash.cloneDeep(prev.values);

      if (!values) {
        values = [{ fieldId, [valueField]: value }];
      } else {
        const existingIndex = values.findIndex(taskValue => taskValue.fieldId === fieldId);

        if (existingIndex !== -1) {
          // If the fieldId already exists, update its value
          (values[existingIndex])[valueField] = value;
        } else {
          // If the fieldId doesn't exist, add a new object to the values array
          values.push({ fieldId, [valueField]: value });
        }
      }

      return {
        ...prev,
        values,
      }
    });
  };

  const handleUpdateTimeLog = (task, reportItem, minutes, date) => {

    onTimeLogAction(TimeLogAction.UPDATE, { task, reportItem, minutes, date });
  }

  const handleAddTimeLog = (task, minutes, date) => {
    onTimeLogAction(TimeLogAction.ADD, { task, minutes, date });
  }

  const handleDeleteTimeLog = (task, timeLogId) => {

    onTimeLogAction(TimeLogAction.DELETE, { task, timeLogId })
  }

  useEffect(() => {
    let dirty = false;
    for (var propertyName in dirtyProperties) {
      if (dirtyProperties[propertyName]) {
        dirty = true;
        break;
      }
    }
    if (isDirty !== dirty) {
      setIsDirty(dirty);
    }
  }, [dirtyProperties]);

  useEffect(() => {
    const task = lodash.cloneDeep(item);
    setDirtyProperties({
      assignments: false,
      createdBy: false,
      description: false,
      endDate: false,
      name: false,
      startDate: false,
    });
    setEditItem(task);
    setIsDirty(false);
    setReset(true);
  }, [item]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const [currentComment, setCurrentComment] = useState();

  const handleNewComment = () => {

    /*let newList = [...comments, { id: 0, timestamep: "banan", comment: currentComment }];
    setCurrentComment('');
    setComments(newList);*/

    saveComment({
      variables: {
        comment: {
          taskId: item.id,
          comment: currentComment
        }
      }
    });

  }

  const [comments, setComments] = useState([{
    id: 1,
    timestamp: "2024-01-01 15:00",
    author: { userId: 33, username: "devops@kommunal.se", firstname: "CJ", lastname: "Lundberg", },
    comment: "<p>Tjosan</p>"
  },
  {
    id: 2,
    timestamp: "2024-01-01 15:00",
    author: { userId: 33, username: "devops@kommunal.se", firstname: "CJ", lastname: "Lundberg", },
    comment: "<p>Tjosan hejsan</p>"
  }]);

  // Assuming RenderComponent is a component, not a string


  return item && editItem && (
    <Box>
      <NameField
        disabled={disabled}
        enableEdit={enableEdit}
        onChange={({ value }) => handleTaskPropertyChange("name", value)}
        onDirty={({ dirty }) => handleTaskPropertyDirty("name", dirty)}
        reset={reset}
        value={item.name}
      />
      <DescriptionField
        disabled={disabled}
        editable={enableEdit}
        onChange={({ value }) => handleTaskPropertyChange("description", value)}
        onDirty={({ dirty }) => handleTaskPropertyDirty("description", dirty)}
        reset={reset}
        value={item.description}
      />
      <RenderComponent

        editItem={editItem}
        columns={columns}
        disabled={disabled}
        enableEdit={enableEdit}
        item={item}
        userLookup={userLookup}
        handleTaskDatePropertyChange={handleTaskDatePropertyChange}
        handleTaskAssignmentPropertyChange={handleTaskAssignmentPropertyChange}
        handleAddTimeLog={handleAddTimeLog}
        handleDeleteTimeLog={handleDeleteTimeLog}
        handleUpdateTimeLog={handleUpdateTimeLog}
        handleTaskPropertyChange={handleTaskPropertyChange}
        handleTaskValueChange={handleTaskValueChange}


      />
      <Divider sx={{ margin: "8px 0px 8px 0px", opacity: 0.2 }} />
      <Stack direction="row" justifyContent="end" sx={{ pr: "1em" }}>
        {enableEdit &&
          <Button disabled={disabled || !isDirty} onClick={handleSave} variant="contained">
            <FormattedMessage id="btn.save" />
          </Button>
        }
      </Stack>
      <Divider sx={{ margin: "8px 0px 8px 0px", opacity: 0.2 }} />

      <Grid container>
        <Grid item xs={RenderComponent == TaskDetailsGrid ? 6 : 12}>
          <JoditTextEditor value={currentComment} onBlur={newContent => setCurrentComment(newContent)} />
          <Button variant="contained" disabled={loading} sx={{ marginBottom: 1 }} onClick={handleNewComment}>Ny kommentar</Button>
          {item.comments && item.comments.map(n => (
            <CommentBox comment={n}/>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskDetailsBase;

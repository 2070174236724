import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Icon, Typography } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { GROUPS } from "./helpers";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    padding: 5,
    overflowY: "auto",
    cursor: "pointer",
  },
  groupIcon: {
    fontSize: 14,
    color: "#5b5654",
    width: 18,
  },
  groupIconNoChild: { margin: "2px 3px 2px 31px !important" },
  labelText: {
    fontSize: "12px",
    wordBreak: " break-all",
    marginLeft: "7px",
  },
  draggedOver: {
    backgroundColor: "transparent",
    border: `2px dashed ${theme.palette.primary.dark}`,
    padding: 0,
  },
  notDraggedOver: {
    backgroundColor: "transparent",
    padding: 1,
  },
  selectedRow: props => ({
    "&:focus > .MuiTreeItem-content ": {
      backgroundColor: theme.palette.component.selectedFolder,
    },
    "& .MuiTreeItem-content": {
      borderRadius: 2,
      alignItems: "flex-start",
    },
    "& .MuiTreeItem-group": {
      marginTop: 2,
    },
  }),
  isSelected: {
    backgroundColor: theme.palette.component.selectedFolder,
  },
}));

const AllUsersGroup = ({ selectedGroupId, onGroupRowClick }) => {
  const isSelected = selectedGroupId === GROUPS.ALL_USERS;
  const dynamicStyle = {
    isSelected,
  };
  const classes = useStyles(dynamicStyle);

  const handleClick = () => {
    onGroupRowClick(GROUPS.ALL_USERS);
  };

  return (
    <Box
      className={clsx([
        classes.root,
        classes.notDraggedOver,
        classes.selectedRow,
        isSelected && classes.isSelected,
      ])}
    >
      <Grid container alignItems="center" onClick={handleClick}>
        <Icon
          className={clsx([
            "fas fa-users",
            classes.groupIcon,
            classes.groupIconNoChild,
          ])}
        />
        <Typography className={classes.labelText}>
          <FormattedMessage id="userGroups.allUsers" />
        </Typography>
      </Grid>
    </Box>
  );
};

AllUsersGroup.defaultProps = {
  selectedGroupId: null,
};

AllUsersGroup.propTypes = {
  onGroupRowClick: PropTypes.func.isRequired,
  selectedGroupId: PropTypes.number,
};

export default AllUsersGroup;

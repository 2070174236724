import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPDATE_COMPANY_TEXT_EDITOR_CONTENT = gql`
  mutation UpdateCompanyTextEditorContent(
    $textkey: String
    $culture: String
    $contents: String
  ) {
    widgetsContext {
      updateCompanyTextEditorContent(
        textkey: $textkey
        culture: $culture
        contents: $contents
      ) {
        errorResult {
          ...ErrorResultFields
        }
        success
        result
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_COMPANY_TEXT_EDITOR_CONTENT;

import { gql } from '@apollo/client';

const USER_DETAILS = gql`
  query UserDetails($token: String) {
    userDetails(token: $token) {
      companyName
      firstName
      isKnownUser
      lastName
      orgNo
      publicCompanyId
      roleName
      site
      username
      comment
      availableSubscriptionLevels
      otherCompannyHasPaymentLiability
      userAlreadyHaveSubscription
      isSupplierInvitation
      userCurrentSubscriptionLevel
      requiredSubscriptionLevel
		  suggestedCompanies{
			  companyName
			  isHighlighted
			  publicCompanyId
		  }
    }
  }
`;

export default USER_DETAILS;

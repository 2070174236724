import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const FormattedQuantity = ({ quantity }) => {
  return (
    <>
      {quantity} <FormattedMessage id="common.pcs" />
    </>
  );
};
FormattedQuantity.propTypes = {
  quantity: PropTypes.number.isRequired,
};

export default FormattedQuantity;

import { handleActions } from "redux-actions";
import {
  toggleDetails,
  setSearchQuery,
  setDoDetailedSearch,
  searchBeataWidget,
} from "../../../actions";

const defaultState = {
  name: "Regular",
  showDetails: false,
  searchQuery: "",
  doDetailedSearch: false,
  beataWidget: false,
  productOwner: "",
};

export default handleActions(
  {
    [toggleDetails]: (state, { payload }) => {
      const { name } = payload;
      return {
        ...state,
        name,
        showDetails: !state.showDetails,
      };
    },
    [setSearchQuery]: (state, { payload }) => {
      const { searchQuery, productOwner } = payload;
      return {
        ...state,
        searchQuery,
        productOwner,
      };
    },
    [setDoDetailedSearch]: (state, { payload }) => {
      const { doDetailedSearch } = payload;
      return {
        ...state,
        doDetailedSearch,
      };
    },
    [searchBeataWidget]: (state, { payload }) => {
      const { beataWidget } = payload;
      return {
        ...state,
        beataWidget,
      };
    },
  },
  defaultState
);

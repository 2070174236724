import { gql } from '@apollo/client';

const CONFIRM_MESSAGE_READ = gql`
  mutation ConfirmSiteChannelMessageRead($messageId: Int!) {
    siteChannelContext {
      confirmMessageRead(messageId: $messageId)
    }
  }
`;

export default CONFIRM_MESSAGE_READ;

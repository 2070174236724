import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { FormattedDate } from "../common";
import OrderInfoModal from "./OrderInfoModal";
import CheckboxDefault from "../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    padding: 7,
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tcCheckbox: {
    width: 16,
    padding: 0,
    verticalAlign: "top",
  },
}));

const PickingListTableRow = ({
  order,
  showColumn,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
  pickingListOrderStatus,
  fetchPickingListOrders,
  onOrderPrinted,
}) => {
  const classes = useStyles();
  const [openOrder, setOpenOrder] = useState(false);

  const {
    companyOrderno,
    orderDate,
    email,
    pickedBy,
    deliveryAddress,
    orderId,
    pickableItemsWeight,
    sumInclVat,
    pickingListOrdersItemId,
  } = order;

  const { address1, address2 } = deliveryAddress || {};

  const handleCheckboxChange = e => {
    const { checked } = e.target;
    if (checked) {
      addSelectedOrder(pickingListOrdersItemId);
    } else {
      removeSelectedOrder(pickingListOrdersItemId);
    }
  };

  const getCheckboxValue = () => {
    if (
      order &&
      order.pickingListOrdersItemId &&
      Array.isArray(selectedOrders) &&
      selectedOrders.find(x => x === order.pickingListOrdersItemId)
    ) {
      return true;
    }
    return false;
  };

  const { city, reference } = deliveryAddress;
  return (
    <TableRow key={order.pickingListOrdersItemId}>
      {showColumn.orderNo && (
        <TableCell className={classes.td}>
          <Button className={classes.link} onClick={() => setOpenOrder(true)}>
            {companyOrderno}
          </Button>
        </TableCell>
      )}

      {showColumn.orderDate && (
        <TableCell className={classes.td}>
          <FormattedDate value={orderDate} />
        </TableCell>
      )}

      {showColumn.user && <TableCell className={classes.td}>{email}</TableCell>}

      {showColumn.department && (
        <TableCell className={classes.td}>{address1}</TableCell>
      )}

      {showColumn.section && (
        <TableCell className={classes.td}> {address2}</TableCell>
      )}

      {showColumn.reference && (
        <TableCell className={classes.td}>{reference} </TableCell>
      )}

      {showColumn.city && <TableCell className={classes.td}>{city}</TableCell>}

      {showColumn.pickableItemsWeight && (
        <TableCell className={classes.td}>{pickableItemsWeight}</TableCell>
      )}

      {showColumn.sumInclVat && (
        <TableCell className={classes.td}>{sumInclVat}</TableCell>
      )}

      {showColumn.pickedBy && (
        <TableCell className={classes.td}>{pickedBy}</TableCell>
      )}

      <TableCell className={classes.tcCheckbox}>
        <CheckboxDefault
          value={getCheckboxValue()}
          onChange={handleCheckboxChange}
        />
      </TableCell>

      {openOrder && (
        <OrderInfoModal
          order={order}
          open={openOrder}
          setOpen={setOpenOrder}
          pickingListOrderStatus={pickingListOrderStatus}
          fetchPickingListOrders={fetchPickingListOrders}
          onOrderPrinted={onOrderPrinted}
        />
      )}
    </TableRow>
  );
};

PickingListTableRow.defaultProps = {
  order: {},
  showColumn: {},
  pickingListOrderStatus: "",
};

PickingListTableRow.propTypes = {
  order: PropTypes.shape({
    pickingListOrdersItemId: PropTypes.string,
    orderId: PropTypes.number,
    companyOrderno: PropTypes.number,
    orderDate: PropTypes.string,
    email: PropTypes.string,
    username: PropTypes.string,
    pickedBy: PropTypes.string,
    productionSite: PropTypes.string,
    deliveryAddress: PropTypes.object,
    ordererAddress: PropTypes.object,
    pickableItemsWeight: PropTypes.number,
    sumInclVat: PropTypes.number,
  }),
  showColumn: PropTypes.shape({
    orderNo: PropTypes.bool,
    orderDate: PropTypes.bool,
    section: PropTypes.bool,
    department: PropTypes.bool,
    user: PropTypes.bool,
    reference: PropTypes.bool,
    city: PropTypes.bool,
    pickedBy: PropTypes.bool,
    pickableItemsWeight: PropTypes.bool,
    sumInclVat: PropTypes.bool,
  }),
  selectedOrders: PropTypes.arrayOf(PropTypes.number).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
  pickingListOrderStatus: PropTypes.string,
  onOrderPrinted: PropTypes.func.isRequired,
};

export default PickingListTableRow;

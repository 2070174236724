import React, { useState, useEffect } from "react";
import Divider from '@mui/material/Divider';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import ListItemIcon from "@mui/material/ListItemIcon";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const DocumentConsent = ({open}) => { 

  return (
    <List
      sx={{
        height: "100%",
        marginTop: "-30px",
        zIndex: 1,
      }}
    >
      {[
        { text: "Startsida", icon: <HomeOutlinedIcon /> },
        { text: "Verifieringar", icon: <TaskOutlinedIcon /> },
        ].map(({ text, icon: Icon }, index) => (
          <ListItem key={text} disablePadding sx={{ display: "block", marginTop: "0px", marginBottom: "0px", padding: 0 }}>
            <ListItemButton
              sx={{
                minHeight: 34,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  marginRight: "8px",
                }}
              >
                {Icon}
              </ListItemIcon>
              <ListItemText primary={text} sx={{ marginTop: "0px", marginBottom: "0px" }} />
            </ListItemButton>
          </ListItem>
        ))}
      {/*<Divider sx={{ marginTop: "8px", marginBottom: "6px" }} />*/}
    </List>
  )
}

export default DocumentConsent;
import { gql } from '@apollo/client';

const UPDATE_SITE_USER = gql`
  mutation UpdateSiteUser($siteUser: SiteUserInput) {
    updateSiteUser(siteUser: $siteUser) {
      message
      success
      siteUser {
        avatar
        companyName
        email
        isInvitedUser
        lastLoggedIn
        name
        paymentLiability
        siteId
        siteInvitationId
        siteRoleId
        siteRoleName
        userId
      }
    }
  }
`;

export default UPDATE_SITE_USER;

import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Divider,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  inputLabelText: {
    fontSize: "12px"
  },
  menuItem: {
    fontSize: "12px",
  },
  select: {
    background: theme.palette.common.white,
    color: "#66615b",
    fontSize: "12px",
    padding: "9px 12px",
    "&.Mui-disabled": {
      opacity: 0.3,
    },
  },
}));

const AdminCompanySelect = ({
  adminUserCompanyId,
  disabled,
  inputLabel,
  onChange,
  options,
  value,
}) => {
  const classes = useStyles();

  const ownCompanyOptions = (options || []).filter(n => n.value == adminUserCompanyId);
  const externalCompaniesOptions = (options || []).filter(n => n.value != adminUserCompanyId);

  const isDisabled = disabled || (ownCompanyOptions.length == 0) && (externalCompaniesOptions.length == 0);

  const renderDivider = () => (
    (ownCompanyOptions.length > 0) && (externalCompaniesOptions.length > 0) && <Divider />
  );

  const renderSection = ({ key, label, options }) => {
    if (options.length) {
      return [
        <ListSubheader key={`${key}-section`}>
          {label}
        </ListSubheader>,
        options.map((x, index) => (
          <MenuItem
            className={classes.menuItem}
            // eslint-disable-next-line react/no-array-index-key
            key={`${key}-item-${x.value}`}
            value={x.value}
          >
            {x.label}
          </MenuItem>
        )),
      ];
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel className={classes.inputLabelText} shrink>
        {inputLabel}
      </InputLabel>
      <Select
        classes={{ select: classes.select }}
        disabled={isDisabled}
        fullWidth
        label={inputLabel}
        onChange={onChange}
        value={value}
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiDivider-root": {
                marginBottom: 0,
                marginTop: 0,
              },
              "& .MuiList-root": {
                paddingTop: 0,
              },
              "& .MuiListSubheader-root": {
                fontSize: "12px",
                lineHeight: "unset",
                opacity: "0.8",
                paddingBottom: 0,
                paddingTop: "8px",
                textAlign: "right",
                textTransform: "uppercase",
              },
            },
          },
        }}
      >
        {renderSection({
          key: "own",
          label: <FormattedMessage id="admin.companySelector.section.yourCompany" />,
          options: ownCompanyOptions,
          showDivider: (ownCompanyOptions.length > 0) && (externalCompaniesOptions.length > 0),
        })}
        {renderDivider()}
        {renderSection({
          key: "external",
          label: <FormattedMessage id="admin.companySelector.section.externalCompanies" />,
          options: externalCompaniesOptions
        })}
      </Select>
    </FormControl>
  );
};

AdminCompanySelect.defaultProps = {
  disabled: false,
  inputLabel: null,
  value: 0,
};

AdminCompanySelect.propTypes = {
  disabled: PropTypes.bool,
  inputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  value: PropTypes.number,
};

export default AdminCompanySelect;

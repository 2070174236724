import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import PreviewAttachment from "./PreviewAttachment";

const useStyles = makeStyles(() => ({
  messageBoxAttachmentsContainer: {
    width: "100%",
    marginLeft: 20,
    marginRight: 20,
    justifyContent: "flex-start",
    minHeight: 80,
    display: "flex",
    overflowX: "auto",
  },
}));

const MessageBoxAttachments = ({ attachments, removeAttachment }) => {
  const classes = useStyles();

  const haveAttachments = attachments && attachments.length > 0;

  return (
    haveAttachments && (
      <Box className={classes.messageBoxAttachmentsContainer}>
        {attachments.map((attachment, index) => {
          return (
            <PreviewAttachment
              // eslint-disable-next-line react/no-array-index-key
              key={`${JSON.stringify(attachment)}-${index}`}
              removeAttachment={() => removeAttachment(attachment.key)}
              attachment={attachment}
            />
          );
        })}
      </Box>
    )
  );
};

MessageBoxAttachments.defaultProps = {
  attachments: [],
};

MessageBoxAttachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      uploaded: PropTypes.bool.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  removeAttachment: PropTypes.func.isRequired,
};

export default memo(MessageBoxAttachments);

import { gql } from '@apollo/client';

const GET_WORKSPACE_USERS = gql`
  query GetSiteUsersForSite(
    $siteId: Int!
    $filter: SiteUserListingFilterType!
    $ascending: Boolean
    $sortfield: SortField
  ) {
    companyAdminContext {
      companyWorkspaces {
        listSiteUsersForSite(
          siteId: $siteId
          filter: $filter
          ascending: $ascending
          sortfield: $sortfield
        ) {
          avatar
          companyName
          email
          telephone
          id
          lastLoggedIn
          name
          paymentLiability
          siteId
          siteRoleId
          siteRoleName
          status
        }
      }
    }
  }
`;

export default GET_WORKSPACE_USERS;

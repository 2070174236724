import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    backgroundColor: theme.palette.common.white,
    wordBreak: "break-all",
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 5px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const DeleteModal = ({ open, handleClose, imageName, handleDeleteItem }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="context.deleteImage" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <FormattedMessage id="context.deleteConfirmation" />{" "}
        <strong>{imageName}</strong>?
        <Box className={classes.modalFooter} align="right">
          <Button
            className={classes.btnCancel}
            onClick={() => handleDeleteItem(false)}
          >
            <FormattedMessage id="btn.cancel" />
          </Button>

          <Button
            className={classes.btn}
            onClick={() => handleDeleteItem(true)}
          >
            <FormattedMessage id="btn.yes" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

DeleteModal.defaultProps = {
  imageName: " ",
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  imageName: PropTypes.string,
};

export default DeleteModal;

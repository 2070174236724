import React from "react";
import DatePicker from "react-datepicker";

const DateSearchField = ({ disabled, name, onChange, options, value, ...rest }) => {
  const { maxDate, minDate } = options;
  return (
    <DatePicker
      dateFormat="yyyy-MM-dd"
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      onChange={value => onChange?.({ name, value })}
      selected={value}
      wrapperClassName="datePicker"
      {...rest}
    />
  );
}

export default DateSearchField;

import { gql } from '@apollo/client';

const ProjectCreator = gql`
  fragment ProjectCreator on ProjectCreator {
    #__typename # ProjectCreator:ProjectUser

    userId
    username
    firstname
    lastname
    avatar
  }
`;

export default ProjectCreator;

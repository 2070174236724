import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  btn: {
    width: "100%",
    padding: "5px 12px",
    fontWeight: 600,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
    marginTop: 10,
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
}));

const ProductAddToBasket = ({ handleOpenProductEditModal, productId }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.btn}
      variant="contained"
      color="primary"
      size="small"
      onClick={() => handleOpenProductEditModal(productId)}
    >
      <FormattedMessage id="product.edit" />
      <Icon className={clsx(["fa fa-pencil-alt", classes.btnIcon])} />
    </Button>
  );
};

ProductAddToBasket.defaultProps = {
  productId: null,
};

ProductAddToBasket.propTypes = {
  handleOpenProductEditModal: PropTypes.func.isRequired,
  productId: PropTypes.string,
};

export default ProductAddToBasket;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import EditUserModal from "../EditUserModal";
import SiteUsersTableRow from "./SiteUsersTableRow";
import SiteUsersTableHead from "./SiteUsersTableHead";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import WarningModal from "./WarningModal";
import UserContactInfoModal from "../../common/UserContactInfoModal";
import { TableBody, TableRow } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    overflowX: "auto",
    position: "relative",
  },
  loadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
  },
}));

const SiteUsersTable = ({
  siteRoleOptions,
  fetchSiteUsers,
  sortfield,
  ascending,
  setSortfield,
  setAscending,
  siteRolesRights,
  siteUsers,
  siteUsersLoading,
  onRemoveSiteUser,
  onUpdateSiteUser,
}) => {
  const classes = useStyles();

  const [openEditUser, setOpenEditUser] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [userContactDetails, setUserContactDetails] = useState(false);
  const [username, setUsername] = useState("");

  const handleCloseUserContactInfo = () => {
    setUserContactDetails(false);
  };

  const handleOpenUserDetails = username => {
    setUsername(username);
    setUserContactDetails(true);
  };

  return (
    <div className={classes.root}>
      <Table size="small">
        <SiteUsersTableHead
          sortfield={sortfield}
          ascending={ascending}
          setSortfield={setSortfield}
          setAscending={setAscending}
          siteRolesRights={siteRolesRights}
        />

        {siteUsersLoading ? (
          <TableBody>
            <TableRow>
              <TableCell className={classes.tdSkeleton} colSpan={9}>
                <TableSkeleton />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {siteUsers.map(siteUser => {
              return (
                <SiteUsersTableRow
                  key={siteUser.id}
                  siteRoleOptions={siteRoleOptions}
                  siteUser={siteUser}
                  handleOpenEditUser={setOpenEditUser}
                  fetchSiteUsers={fetchSiteUsers}
                  setOpenWarningModal={setOpenWarningModal}
                  handleOpenUserDetails={handleOpenUserDetails}
                  siteRolesRights={siteRolesRights}
                  onRemoveSiteUser={onRemoveSiteUser}
                  onUpdateSiteUser={onUpdateSiteUser}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
      {openWarningModal && (
        <WarningModal
          open={openWarningModal}
          handleClose={() => setOpenWarningModal(false)}
        />
      )}
      {openEditUser && (
        <EditUserModal open={openEditUser} setOpen={setOpenEditUser} />
      )}

      {userContactDetails && (
        <UserContactInfoModal
          open={userContactDetails}
          handleClose={() => handleCloseUserContactInfo()}
          username={username}
        />
      )}
    </div>
  );
};

SiteUsersTable.defaultProps = {
  siteRoleOptions: [],
};

SiteUsersTable.propTypes = {
  siteRoleOptions: PropTypes.arrayOf(PropTypes.shape({})),
  fetchSiteUsers: PropTypes.func.isRequired,
  setAscending: PropTypes.func.isRequired,
  setSortfield: PropTypes.func.isRequired,
  sortfield: PropTypes.string.isRequired,
  ascending: PropTypes.bool.isRequired,
};

export default SiteUsersTable;

import React from "react";
import { makeStyles } from "@mui/styles";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "800px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    textAlign: "center",
    maxHeight: "85vh",
    overflowY: "auto",
  },
}));

const ImageModal = ({ imageName, productImageUri, open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title={imageName} />
      <DraggableModalBody customClass={classes.modalBody}>
        <img src={productImageUri} alt="" className={classes.image} />
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default ImageModal;

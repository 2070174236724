import getChildGroupsTreeData from "./getChildGroupsTreeData";

const getGroupTreeDataStructure = ({ groups }) => {
  let groupTree = {};
  if (groups && Array.isArray(groups)) {
    groups.forEach(group => {
      let childIds = [];
      let childGroupsTreeData = {};

      if (group.childGroups && group.childGroups.length > 0) {
        childIds = group.childGroups.map(child => child.groupId);

        group.childGroups.forEach(childGroup => {
          const childGroupTreeData = getChildGroupsTreeData({
            group: childGroup,
            parentGroupId: group.groupId,
          });
          childGroupsTreeData = {
            ...childGroupsTreeData,
            ...childGroupTreeData,
          };
        });
      }

      groupTree = {
        ...groupTree,
        ...childGroupsTreeData,
        [`g_${group.groupId}`]: {
          ...group,
          isRoot: true,
          childGroupIds: childIds,
        },
      };
    });
  }

  return groupTree;
};

export default getGroupTreeDataStructure;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { contextMenu } from "react-contexify";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CompanyNetworkTree from "./CompanyNetworkTree";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import ContextMenu from "./ContextMenu";
import CreateCompanyModal from "../../../common/CreateNameModal/CompanyNetworks";
import CreateNetworkModal from "../../../common/CreateNameModal";
import ExistingCompanyConfirmationModal from "../../../common/ConfirmationModal";
import { GenericAdminPageSkeleton } from "../../../Skeleton";
import getRootTreeData from "./helpers/getRootTreeData";
import { companyNetworkErrorCodes } from "../../../../constant";
import { LIST_COMPANY_NETWORKS } from "../../../../graphql/queries/CompanyAdminContext";
import {
  CREATE_NETWORK,
  RENAME_NETWORK,
  DELETE_NETWORK,
  ADD_COMPANY,
  CREATE_COMPANY,
  REMOVE_COMPANY,
} from "../../../../graphql/mutations/CompanyAdminContext";

const useStyles = makeStyles(theme => ({
  root: {

  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headSubTitleExample: {
    opacity: 0.6,
    display: 'inline',
  },
  headMarginBottom: {
    marginBottom: "10px",
  },
  gridContainer: {
    marginTop: 10,
    padding: 15,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    //marginLeft: "auto",
    marginLeft: "2em",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  treeItem: {
    "& .MuiTreeItem-label": {
      marginBottom: 5,
    },
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    color: "#555",
    textDecoration: "none",
    "&:hover": {
      color: "#555",
    },
    "&:focus": {
      color: "#555",
    },
    "&:active": {
      color: "#555",
    },
  },
  labelIcon: {
    fontSize: 15,
    marginRight: 10,
    width: 15,
    height: 15,
    overflow: "unset",
    textAlign: "center",
    color: "#555",
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "14px",
  },
  parentNetworkHeading: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 600,
    marginTop: "1.5em",
    marginBottom: "0.5em",
    "$gridContainer > :nth-child(1) &": {
      marginTop: 0,
    },
  },
  parentNetworkName: {
    "$parentNetworkHeading > &": {
      fontWeight: 600,
      marginRight: "auto",
    },
  },
}));

const contextMenuId = "company_networks_context_menu";

const CompanyNetworks = () => {
  const classes = useStyles();
  const alert = useAlert();
  const intl = useIntl();

  const { companyId: adminCompanyId } = useSelector(
    state => state.api.companyAdmin.company
  );

  const [companyNetworks, setCompanyNetworks] = useState();

  const [selectedNode, setSelectedNode] = useState({});
  const [openedNodes, setOpenedNodes] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showNetworkModal, setShowNetworkModal] = useState(false);
  const [newNetworkName, setNewNetworkName] = useState("");
  const [newParentNetworkId, setNewParentNetworkId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [
    getCompanyNetworks,
    {
      data: companyNetworksData,
      error: companyNetworksError,
      loading: companyNetworksLoading,
    },
  ] = useLazyQuery(LIST_COMPANY_NETWORKS);
  const [addCompany, { loading: addingCompany }] = useMutation(ADD_COMPANY);
  const [createCompany, { loading: creatingCompany }] = useMutation(
    CREATE_COMPANY
  );
  const [removeCompany, { loading: removingCompany }] = useMutation(
    REMOVE_COMPANY
  );
  const [createNetwork, { loading: creatingNetwork }] = useMutation(
    CREATE_NETWORK
  );
  const [renameNetwork, { loading: renamingNetwork }] = useMutation(
    RENAME_NETWORK
  );
  const [deleteNetwork, { loading: deletingNetwork }] = useMutation(
    DELETE_NETWORK
  );

  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [
    showExistingCompanyConfirmationModal,
    setShowExistingCompanyConfirmationModal,
  ] = useState(false);
  const [existingCompanyName, setExistingCompanyName] = useState("");

  const [
    existingCompanyPublicCompanyId,
    setExistingCompanyPublicCompanyId,
  ] = useState("");

  const handleSetEditNode = value => {
    setIsEditMode(value);
  };

  const handleOnRename = value => {
    handleSetEditNode(value);
  };

  const handleError = errorResult => {
    const { errorCode, errorMessage } = errorResult;
    if (errorCode === companyNetworkErrorCodes.ALREADY_EXISTS) {
      alert.error(<FormattedMessage id="companyNetworks.alreadyExists" />);
    } else if (errorCode === companyNetworkErrorCodes.NOT_FOUND) {
      alert.error(<FormattedMessage id="companyNetworks.notFound" />);
    } else {
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
    }
  };

  const handleFetchCompanyNetworks = () => {
    getCompanyNetworks({ variables: { companyId: adminCompanyId } });
  };

  useEffect(() => {
    if (adminCompanyId && adminCompanyId > 0) {
      getCompanyNetworks({ variables: { companyId: adminCompanyId } });
    }
  }, [adminCompanyId]);

  useEffect(() => {
    if (
      companyNetworksData &&
      companyNetworksData.companyAdminContext &&
      companyNetworksData.companyAdminContext.companyNetworks &&
      companyNetworksData.companyAdminContext.companyNetworks
        .listCompanyNetworks
    ) {
      const { networks, parentNetworks } = {
        ...companyNetworksData?.companyAdminContext?.companyNetworks
          ?.listCompanyNetworks,
      };
      const companyNetworksTree = getRootTreeData({ networks, parentNetworks });
      setCompanyNetworks(companyNetworksTree);
    }
  }, [companyNetworksData]);

  const handleOnNodeToggle = (e, nodeIds) => {
    e.preventDefault();
    setOpenedNodes(nodeIds);
  };

  const handleContextMenu = (e, node) => {
    e.preventDefault();
    e.stopPropagation();
    contextMenu.show({
      id: contextMenuId,
      event: e,
    });
    if (selectedNode !== node) {
      setSelectedNode(node);
    }
  };

  const handleOnCreateCompany = async ({ name }) => {
    const {
      isNetwork,
      isRoot,
      networkId,
    } = selectedNode;

    if (!isNetwork) {
      return;
    }

    var variables = {
      companyId: adminCompanyId,
      name,
      networkId,
      parentCompanyId: isRoot ? adminCompanyId : null,
    };

    try {
      const res = await createCompany({ variables });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyNetworks &&
        res.data.companyAdminContext.companyNetworks.createCompany
      ) {
        const {
          success,
          result,
          errorResult,
        } = res.data.companyAdminContext.companyNetworks.createCompany;

        if (success) {
          const newResult = { ...result, networkId };
          handleCompanyCreated(newResult);
          //alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          const { data, errorCode } = errorResult;
          if (errorCode === companyNetworkErrorCodes.ALREADY_EXISTS) {
            setExistingCompanyPublicCompanyId(data[0].value);
            setExistingCompanyName(variables.name);
            setShowExistingCompanyConfirmationModal(true);
          } else {
            handleError(errorResult);
          }
        }

        setShowCompanyModal(false);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const addCompanyByPublicCompanyId = async publicCompanyId => {
    const {
      isNetwork,
      isRoot,
      networkId,
    } = selectedNode;

    if (!isNetwork) {
      return;
    }

    var variables = {
      companyId: adminCompanyId,
      networkId,
      parentCompanyId: isRoot ? adminCompanyId : null,
      publicCompanyId: publicCompanyId,
    };

    try {
      const res = await addCompany({ variables });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyNetworks &&
        res.data.companyAdminContext.companyNetworks.addCompany
      ) {
        const {
          success,
          result,
          errorResult,
        } = res.data.companyAdminContext.companyNetworks.addCompany;

        if (success) {
          const newResult = { ...result, networkId };
          handleCompanyCreated(newResult);
          //alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          handleError(errorResult);
        }

        setShowCompanyModal(false);
        setShowExistingCompanyConfirmationModal(false);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleCompanyCreated = newCompany => {
    const { memberId: compId, networkId } = selectedNode;
    const updatedNewCompany = { ...newCompany, parentCompanyId: compId };
    const { memberId: newCompanyId } = updatedNewCompany;

    let newNetworkTree = { ...companyNetworks };
    let id = null;

    if (compId) {
      id = `c_${compId}`;
    } else {
      id = networkId;
    }

    if (id) {
      const hasChildren =
        Array.isArray(newNetworkTree[id].childCompanies) &&
        newNetworkTree[id].childCompanies.length > 0;

      let newChildCompanies = [];
      let newChildCompanyIds = [];

      if (hasChildren) {
        newChildCompanies = [
          ...newNetworkTree[id].childCompanies,
          updatedNewCompany,
        ];
        newChildCompanyIds = [
          ...newNetworkTree[id].childCompanyIds,
          newCompanyId,
        ];
      } else {
        newChildCompanies = [updatedNewCompany];
        newChildCompanyIds = [newCompanyId];
      }

      const newCompanyData = {
        ...newNetworkTree[id],
        childCompanyIds: newChildCompanyIds,
        childCompanies: newChildCompanies,
      };

      newNetworkTree = {
        ...newNetworkTree,
        [id]: newCompanyData,
        [`c_${newCompanyId}`]: updatedNewCompany,
      };
    }

    setCompanyNetworks(newNetworkTree);
  };

  const handleNetworkCreated = newNetwork => {
    let newNetworkTree = { ...companyNetworks };

    const { networkId: newNetworkId } = newNetwork;
    const newTempNetwork = { ...newNetwork, isNetwork: true, isRoot: false };

    newNetworkTree = {
      ...newNetworkTree,
      [newNetworkId]: newTempNetwork,
    };

    setCompanyNetworks(newNetworkTree);
  };

  const handleRenameNetwork = async (name, networkId) => {
    try {
      const res = await renameNetwork({
        variables: {
          companyId: adminCompanyId,
          networkId,
          name,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyNetworks &&
        res.data.companyAdminContext.companyNetworks.renameNetwork
      ) {
        const {
          success,
          errorResult,
        } = res.data.companyAdminContext.companyNetworks.renameNetwork;

        if (success) {
          handleNetworkRenamed({ networkId, name });
          //alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          handleError(errorResult);
        }

        return success;
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleNetworkRenamed = ({ networkId, name }) => {
    const newCompanyNetworks = { ...companyNetworks };
    newCompanyNetworks[networkId].name = name;
    setCompanyNetworks(newCompanyNetworks);
  };

  const handleCreateNetwork = async () => {
    try {
      const variables = {
        companyId: adminCompanyId,
        name: newNetworkName,
        parentNetworkId: newParentNetworkId,
      };

      const res = await createNetwork({ variables });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyNetworks &&
        res.data.companyAdminContext.companyNetworks.createNetwork
      ) {
        const {
          success,
          result,
          errorResult,
        } = res.data.companyAdminContext.companyNetworks.createNetwork;

        if (success) {
          handleNetworkCreated(result);
          //alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          handleError(errorResult);
        }

        setShowNetworkModal(false);
      }
    } catch (err) {
      console.error("error >", err);
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
    }
  };

  const handleNetworkDeleted = () => {
    const { networkId } = selectedNode;
    const newCompanyNetworks = { ...companyNetworks };
    delete newCompanyNetworks[networkId];
    setCompanyNetworks(newCompanyNetworks);
  };

  const handleDeleteNetwork = async networkId => {
    try {
      const res = await deleteNetwork({
        variables: {
          companyId: adminCompanyId,
          networkId,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyNetworks &&
        res.data.companyAdminContext.companyNetworks.deleteNetwork
      ) {
        const {
          success,
          errorResult,
        } = res.data.companyAdminContext.companyNetworks.deleteNetwork;

        if (success) {
          handleNetworkDeleted();
          //alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          handleError(errorResult);
        }

        setShowDeleteModal(false);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleCompanyRemoved = () => {
    const { parentCompanyId, memberId: compId, networkId } = selectedNode;
    const key = parentCompanyId ? `c_${parentCompanyId}` : `${networkId}`;

    let newCompanyNetworks = { ...companyNetworks };
    let newChildCompanyIds = [];
    let newChildCompanies = [];

    if (
      Array.isArray(newCompanyNetworks[key].childCompanyIds) &&
      newCompanyNetworks[key].childCompanyIds.length > 0
    ) {
      newChildCompanyIds = newCompanyNetworks[key].childCompanyIds.filter(
        x => x !== compId
      );
      newChildCompanies = newCompanyNetworks[key].childCompanies.filter(
        x => x.memberId !== compId
      );
    }

    const newCompanyData = {
      ...newCompanyNetworks[key],
      childCompanyIds: newChildCompanyIds,
      childCompanies: newChildCompanies,
    };

    newCompanyNetworks = {
      ...newCompanyNetworks,
      [key]: newCompanyData,
    };

    setCompanyNetworks(newCompanyNetworks);
  };

  const handleRemoveCompany = async (networkId, targetCompanyId) => {
    try {
      const res = await removeCompany({
        variables: {
          companyId: adminCompanyId,
          networkId,
          targetCompanyId,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyNetworks &&
        res.data.companyAdminContext.companyNetworks.removeCompany
      ) {
        const {
          success,
          errorResult,
        } = res.data.companyAdminContext.companyNetworks.removeCompany;

        if (success) {
          handleCompanyRemoved();
          //alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          handleError(errorResult);
        }

        setShowDeleteModal(false);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleOpenNetworkModal = parentNetworkId => {
    setShowNetworkModal(true);
    const defaultNetworkName = intl.formatMessage({
      id: "companyNetworks.newNetwork",
    });
    setNewParentNetworkId(parentNetworkId);
    setNewNetworkName(defaultNetworkName);
  };

  const handleCreateCompanyClicked = networkId => {
    var node = companyNetworks[networkId];
    setSelectedNode(node);
    setShowCompanyModal(true);
  };

  const handleOnAddCompany = async ({ publicCompanyId }) => {
    await addCompanyByPublicCompanyId(publicCompanyId);
  };

  const handleAddExistingCompany = async () => {
    await addCompanyByPublicCompanyId(existingCompanyPublicCompanyId);
  };

  const PageTitle = () => {
    return (
      <Box className={classes.headMarginBottom} xs={2}>
        <Typography className={classes.headTitle}><FormattedMessage id="admin.companysettings.companyNetworks" /></Typography>
        <Typography className={classes.headSubTitle}><FormattedMessage id="admin.companysettings.companyNetworks.subTitle" /><Typography display="inline" className={classes.headSubTitleExample}> <FormattedMessage id="admin.companysettings.companyNetworks.subTitle.Example" /></Typography></Typography>
      </Box>
    );
  };

  const ParentNetworkHeading = ({ parentNetwork }) => {
    return (
      <Typography className={classes.parentNetworkHeading}>
        <Typography className={classes.parentNetworkName}>
          {parentNetwork.name}
        </Typography>
        <Button
          className={classes.addLink}
          onClick={() => handleCreateCompanyClicked(parentNetwork.networkId)}
        >
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
          <FormattedMessage id="companyNetworks.newCompany" />
        </Button>
        <Button
          className={classes.addLink}
          onClick={() => handleOpenNetworkModal(parentNetwork.networkId)}
        >
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
          <FormattedMessage id="companyNetworks.newNetwork" />
        </Button>
      </Typography>
    );
  };

  if (companyNetworksLoading || !companyNetworks) {
    return <GenericAdminPageSkeleton title={<PageTitle />} xs={12} />;
  }

  const rootNetworks = Object.values(companyNetworks).filter(
    n => n.isNetwork && n.isRoot
  );
  const haveRootNetworks = rootNetworks.length > 0;

  return (
    <Grid container spacing={4}>
      <Grid item className={classes.root} xs={12}>
        <PageTitle />
        <Box className={classes.gridContainer}>
          {!haveRootNetworks && (
            <Box>
              <CompanyNetworkTree
                handleContextMenu={handleContextMenu}
                handleError={handleError}
                handleOnNodeToggle={handleOnNodeToggle}
                handleRenameNetwork={handleRenameNetwork}
                handleSetEditNode={handleSetEditNode}
                isEditMode={isEditMode}
                openedNodes={openedNodes}
                parentNetworkId={null}
                refresh={handleFetchCompanyNetworks}
                selectedNode={selectedNode}
                treeData={companyNetworks}
              />
            </Box>
          )}

          {rootNetworks.map(n => (
            <Box>
              <ParentNetworkHeading parentNetwork={n} />
              <CompanyNetworkTree
                handleContextMenu={handleContextMenu}
                handleError={handleError}
                handleOnNodeToggle={handleOnNodeToggle}
                handleRenameNetwork={handleRenameNetwork}
                handleSetEditNode={handleSetEditNode}
                isEditMode={isEditMode}
                openedNodes={openedNodes}
                parentNetworkId={n.networkId}
                refresh={handleFetchCompanyNetworks}
                selectedNode={selectedNode}
                treeData={companyNetworks}
              />
            </Box>
          ))}
        </Box>

      <ContextMenu
        contextMenuId={contextMenuId}
        onDelete={() => {
          setShowDeleteModal(true);
        }}
        onNewCompany={() => {
          setShowCompanyModal(true);
        }}
        onRename={handleOnRename}
        selectedNode={selectedNode}
      />

      {showNetworkModal && (
        <CreateNetworkModal
          handleClose={() => {
            setShowNetworkModal(false);
          }}
          onChange={e => setNewNetworkName(e.target.value)}
          onClick={handleCreateNetwork}
          open={showNetworkModal}
          value={newNetworkName}
        />
      )}

      {showCompanyModal && (
        <CreateCompanyModal
          onAddCompany={handleOnAddCompany}
          onClose={() => {
            setShowCompanyModal(false);
          }}
          onCreateCompany={handleOnCreateCompany}
          open={showCompanyModal}
        />
      )}

      {showExistingCompanyConfirmationModal && (
        <ExistingCompanyConfirmationModal
          handleClose={() => {
            setShowExistingCompanyConfirmationModal(false);
          }}
          message={
            <FormattedMessage
              id="companyNetworks.addExistingCompany"
              values={{ company: existingCompanyName }}
            />
          }
          onClick={handleAddExistingCompany}
          open={showExistingCompanyConfirmationModal}
        />
      )}

      {showDeleteModal && (
        <ConfirmDeleteModal
          companyNetworks={companyNetworks}
          handleDeleteNetwork={handleDeleteNetwork}
          handleRemoveCompany={handleRemoveCompany}
          open={showDeleteModal}
          selectedNode={selectedNode}
          setOpen={setShowDeleteModal}
        />
      )}
      </Grid>
    </Grid>
  );
};

export default CompanyNetworks;

import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import UQFileUploader from "../../common/UQFileUploader";

import {
  IMPORT_COMPANY_TEMPLATE_FROM_SYSTEM,
  INIT_COMPANY_TEMPLATE_UPLOAD,
} from "../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  uploadBox: {
    marginTop: 15,
    padding: 5,
    borderRadius: 4,
    border: "1px solid #d7d7d7",
    width: "70%",
  },
  input: {
    display: "none",
  },
}));

const UploadBox = () => {
  const classes = useStyles();
  const alert = useAlert();
  const [importFromSystem] = useMutation(IMPORT_COMPANY_TEMPLATE_FROM_SYSTEM);
  const [initUpload] = useMutation(INIT_COMPANY_TEMPLATE_UPLOAD);

  const { companyId: _targetCompanyId } = useSelector(
    state => state.api.companyAdmin.company
  );

  const targetCompanyIdRef = useRef();

  useEffect(() => {
    targetCompanyIdRef.current = _targetCompanyId;
  }, [_targetCompanyId]);

  const handleFilesSelected = async (files, done) => {
    console.log("* handleFilesSelected *");

    const signalDone = () => {
      if (!!done) {
        done();
      }
    };

    if (files?.length !== 1) {
      console.error("Expected a single file.");
      alert.error(
        <FormattedMessage id="admin.companyTemplates.uploadError.expectedSingleFile" />
      );
      signalDone();
      return;
    }

    let file = files[0];
    let targetCompanyId = targetCompanyIdRef.current;
    let uploadUrl;

    console.info(`Initializing upload (target company: ${targetCompanyId}).`);

    try {
      const response = await initUpload({
        variables: { targetCompanyId },
      });

      const result =
        response.data.companyAdminContext.companyTemplates
          .initCompanyTemplateUpload;
      const { success: uploadInitialized } = result;

      if (uploadInitialized) {
        uploadUrl = result.url;
      } else {
        console.error(
          `Could not initialize upload (${result.errorResult?.errorMessage ??
            "Unknown error"}).`
        );
        alert.error(
          <FormattedMessage id="admin.companyTemplates.uploadError" />
        );
        signalDone();
        return;
      }
    } catch (err) {
      console.error("Error while uploading.", err);
      alert.error(<FormattedMessage id="admin.companyTemplates.uploadError" />);
      signalDone();
      return;
    }

    console.info(`Importing file (target company: ${targetCompanyId}).`);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(`${uploadUrl}`, formData);
      const { success: fileImported, message } = response.data;

      if (fileImported) {
        console.info(message);
        alert.info(
          <FormattedMessage id="admin.companyTemplates.importSuccess" />
        );
      } else {
        console.error(message);
        alert.error(
          <FormattedMessage id="admin.companyTemplates.importError" />
        );
      }
    } catch (err) {
      console.error("Error while importing.", err);
      alert.error(<FormattedMessage id="admin.companyTemplates.importError" />);
    }

    signalDone();
  };

  const handleSystemFilesSelected = async (files, done) => {
    console.log("* handleSystemFilesSelected *");

    const signalDone = () => {
      if (!!done) {
        done();
      }
    };

    if (files?.length !== 1) {
      console.error("Expected a single file.");
      alert.error(
        <FormattedMessage id="admin.companyTemplates.uploadError.expectedSingleFile" />
      );
      signalDone();
      return;
    }

    let file = files[0];
    let targetCompanyId = targetCompanyIdRef.current;
    let resourceId = file.id;

    console.info(`Importing file (target company: ${targetCompanyId}).`);

    try {
      const response = await importFromSystem({
        variables: { targetCompanyId, id: resourceId },
      });

      const result =
        response.data.companyAdminContext.companyTemplates
          .importCompanyTemplateFromSystem;

      const { success: fileImported, errorResult } = result;

      if (fileImported) {
        console.info("Import succeeded.");
        alert.info(
          <FormattedMessage id="admin.companyTemplates.importSuccess" />
        );
      } else {
        console.error(
          `Could not import file (${result.errorResult?.errorMessage ??
            "Unknown error"}).`
        );
        alert.error(
          <FormattedMessage id="admin.companyTemplates.importError" />
        );
      }
    } catch (err) {
      console.error("Error while importing.", err);
      alert.error(<FormattedMessage id="admin.companyTemplates.importError" />);
    }

    signalDone();
  };

  var eventHandlers = {
    filesSelected: handleFilesSelected,
    systemFilesSelected: handleSystemFilesSelected,
  };

  return (
    <Box className={classes.uploadBox}>
      <UQFileUploader
        acceptedFiles=".zip"
        compactDesign={true}
        displayItems={[]}
        eventHandlers={eventHandlers}
        multipleSelection={false}
        uploadFromSystem={true}
        verticalOrientation={false}
      />
    </Box>
  );
};

export default UploadBox;

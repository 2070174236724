import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import ExpansionDefault from "../../common/ExpansionDefault";
import MonitoringPanels from "./MonitoringPanels";

const ParticipantsPanel = ({ users, setUsers }) => {
  const intl = useIntl();

  return (
    <ExpansionDefault
      label={<FormattedMessage id="monitoring.participants" />}
      subLabel={`(${intl.formatMessage({
        id: "monitoring.clickToSelect",
      })})`}
      panelContent="deltagareContent"
      panelHeader="deltagareHeader"
      defaultExpanded
    >
      <Grid container spacing={2}>
        <Grid item md={4}>
          <MonitoringPanels
            title={<FormattedMessage id="monitoring.additions" />}
            span={intl.formatMessage({
              id: "monitoring.newParticipant",
            })}
            label={<FormattedMessage id="monitoring.triggers.user.create" />}
            onChange={() =>
              setUsers(prevUsers => {
                return { ...prevUsers, create: !users.create };
              })
            }
            value={users.create}
          />
        </Grid>
        <Grid item md={4}>
          <MonitoringPanels
            title={<FormattedMessage id="monitoring.change" />}
            span={intl.formatMessage({
              id: "monitoring.update",
            })}
            label={<FormattedMessage id="monitoring.triggers.user.update" />}
            onChange={() =>
              setUsers(prevUsers => {
                return { ...prevUsers, update: !users.update };
              })
            }
            value={users.update}
          />
        </Grid>
        <Grid item md={4}>
          <MonitoringPanels
            title={<FormattedMessage id="monitoring.deleteDeletion" />}
            span={intl.formatMessage({
              id: "monitoring.delete",
            })}
            label={<FormattedMessage id="monitoring.triggers.user.delete" />}
            onChange={() =>
              setUsers(prevUsers => {
                return { ...prevUsers, delete: !users.delete };
              })
            }
            value={users.delete}
          />
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

ParticipantsPanel.propTypes = {
  users: PropTypes.shape({
    create: PropTypes.bool,
    delete: PropTypes.bool,
    update: PropTypes.bool,
  }).isRequired,
  setUsers: PropTypes.func.isRequired,
};

export default ParticipantsPanel;

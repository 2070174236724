import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ContainedSelect from "../../../common/ContainedSelect";
import { useQuery } from "@apollo/client";
import { GET_SELECTABLE_SUPPLIERS } from "../../../../graphql/queries/MonitoringContext";

const SupplierSelection = ({
  siteId,
  selectedSupplier,
  handleSupplierSelection,
  updateSelection,
}) => {
  const [suppliers, setSuppliers] = useState();
  const updateSupplier = siteId => {
    handleSupplierSelection(siteId);
  };

  const { data, loading, error } = useQuery(GET_SELECTABLE_SUPPLIERS, {
    variables: {
      siteId: siteId || 0,
    },
    skip: siteId === undefined || siteId === null || siteId <= 0,
  });

  useEffect(() => {
    if (!error && data) {
      var supplierData = data?.monitoringContext?.getSelectableSuppliers;
      if (supplierData) {
        setSuppliers(supplierData);
      }
    }
  }, [data, loading, error]);

  var items = suppliers
    ? suppliers.map(n => ({ label: n.displayName, value: n.siteSupplierId }))
    : [];

  return (
    <ContainedSelect
      addMargin
      label={<FormattedMessage id="product.productBeSentToSupplier" />}
      options={items}
      value={selectedSupplier}
      onChange={e => {
        updateSupplier(e.target.value);
      }}
    />
  );
};
/*  disabled,
  label,
  name,
  onChange,
  options,
  value,
  dataTut,
  required,
  small,*/
export default SupplierSelection;

import React from "react";
import { makeStyles } from "@mui/styles";
import DialogContent from "@mui/material/DialogContent";

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
  },
}));

const DraggableModalBody = ({ children, customClass }) => {
  const classes = useStyles();

  return (
    <DialogContent classes={{ root: classes.root }} className={customClass}>
      {children}
    </DialogContent>
  );
};

export default DraggableModalBody;

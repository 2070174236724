import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import FormattedDate from "../../common/FormattedDate";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  date: {
    fontSize: 12,
    marginRight: 5,
    fontWeight: "normal",
  },
  strong: {
    fontWeight: 700,
  },
  spacing: {
    marginTop: 5,
  },
}));

const OrderStatusChanges = ({ orderStatusChanges }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <div>
        <Typography className={clsx([classes.text, classes.strong])}>
          <FormattedMessage id="pickingList.messageHistory" />
        </Typography>
      </div>
      {orderStatusChanges.map(status => {
        return (
          <div className={classes.spacing}>
            <Typography className={classes.text}>
              <span className={classes.date}>
                <FormattedDate value={status.statusDate} />
              </span>
              {status.statusMessage}
            </Typography>
          </div>
        );
      })}
    </Grid>
  );
};

OrderStatusChanges.defaultProps = {
  orderStatusChanges: null,
};

OrderStatusChanges.propTypes = {
  orderStatusChanges: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OrderStatusChanges;

export const GROUPS = {
  ALL_USERS: 0,
  UNASSIGNED_USERS: -1,
};

const addressFields = [
  { label: "Address 1", name: "address1" },
  { label: "Address 2", name: "address2" },
  { label: "Address 3", name: "address3" },
  { label: "Address 4", name: "address4" },
  { label: "Address 5", name: "address5" },
  { label: "Address 6", name: "address6" },
  { label: "City", name: "city" },
  { label: "Contact", name: "contact" },
  { label: "Country", name: "country" },
  { label: "Email", name: "email" },
  { label: "Reference", name: "reference" },
  { label: "Telephone", name: "telephone" },
  { label: "Zip", name: "zip" },
];

export const addressess = [
  {
    name: "deliveryAddress",
    label: "cart.addressDelivery",
    fields: addressFields,
  },
  { name: "postalAddress", label: "cart.addressPostal", fields: addressFields },
  {
    name: "invoiceAddress",
    label: "cart.addressInvoice",
    fields: addressFields,
  },
  {
    name: "visitingAddress",
    label: "cart.addressVisiting",
    fields: addressFields,
  },
];

export const deliveryAddress = {
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  city: "",
  contact: "",
  country: "",
  customerId: "",
  email: "",
  reference: "",
  telephone: "",
  zip: "",
};

export const postalAddress = {
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  city: "",
  contact: "",
  country: "",
  customerId: "",
  email: "",
  reference: "",
  telephone: "",
  zip: "",
};

export const invoiceAddress = {
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  city: "",
  contact: "",
  country: "",
  customerId: "",
  email: "",
  reference: "",
  telephone: "",
  zip: "",
};

export const visitingAddress = {
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  city: "",
  contact: "",
  country: "",
  customerId: "",
  email: "",
  reference: "",
  telephone: "",
  zip: "",
};

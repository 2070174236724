import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    maxWidth: "fit-content!important",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    backgroundColor: theme.palette.common.white,
    wordBreak: "break-all",
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 5px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const ConfirmDeleteModal = ({ id, name, onClose, onConfirm, open }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      customClass={classes.modal}
      handleClose={onClose}
      open={open}
    >
      <DraggableModalHead
        handleClose={onClose}
        title={<FormattedMessage id="context.delete" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <FormattedMessage id="context.deleteConfirmation" />{" "}
        <strong>{name}</strong>?
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnCancel} onClick={() => onClose()}>
            <FormattedMessage id="btn.cancel" />
          </Button>

          <Button className={classes.btn} onClick={e => onConfirm({ e, id })}>
            <FormattedMessage id="btn.yes" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmDeleteModal.defaultProps = {
  id: null,
  name: "",
};

ConfirmDeleteModal.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ConfirmDeleteModal;

import { gql } from '@apollo/client';

const getTheme = gql`
  query GetTheme {
    themes {
      customTheme(themeName: "foobar") {
        name
        themeJson
      }
    }
  }
`;

export default getTheme;

import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import { FormattedMessage, useIntl } from "react-intl";
import ExpansionDefault from "../../../common/ExpansionDefault";
import CreateColumn from "./CreateColumn";
import UpdateColumn from "./UpdateColumn";
import DeleteColumn from "./DeleteColumn";

const useStyles = makeStyles(theme => ({
  customForm: {
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.background.lightGray,
    },
  },
  divider: {
    margin: "15px 0",
  },
}));

const FoldersPanel = ({
  fileSettings,
  folderSettings,
  setFileSettings,
  setFolderSettings,
  filesFoldersSettings,
  setFilesFolderSettings,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ExpansionDefault
      label={<FormattedMessage id="monitoring.foldersAndFiles" />}
      subLabel={`(${intl.formatMessage({
        id: "monitoring.clickToSelect",
      })})`}
      panelContent="mapparContent"
      panelHeader="mapparHeader"
      defaultExpanded
    >
      <Grid container spacing={2} className={classes.customForm}>
        <CreateColumn
          filesFoldersSettings={filesFoldersSettings}
          setFilesFolderSettings={setFilesFolderSettings}
        />
        <UpdateColumn
          filesFoldersSettings={filesFoldersSettings}
          setFilesFolderSettings={setFilesFolderSettings}
        />
        <DeleteColumn
          filesFoldersSettings={filesFoldersSettings}
          setFilesFolderSettings={setFilesFolderSettings}
        />
      </Grid>
      {/* <Divider className={classes.divider} /> */}
    </ExpansionDefault>
  );
};

FoldersPanel.defaultProps = {};

FoldersPanel.propTypes = {
  fileSettings: PropTypes.shape({
    create: PropTypes.bool,
    delete: PropTypes.bool,
    download: PropTypes.bool,
    lockChange: PropTypes.bool,
    update: PropTypes.bool,
  }).isRequired,
  folderSettings: PropTypes.shape({
    changedName: PropTypes.bool,
    create: PropTypes.bool,
    delete: PropTypes.bool,
  }).isRequired,
  setFolderSettings: PropTypes.func.isRequired,
  setFileSettings: PropTypes.func.isRequired,
};

export default memo(FoldersPanel);

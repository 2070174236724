import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_FOLDER_SITE_ROLE_RIGHTS = gql`
  mutation UpdateFolderSiteRoleRights($input: UpdateSiteRoleRightsInput) {
    folderContext {
      updateSiteRoleRights(input: $input) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_FOLDER_SITE_ROLE_RIGHTS;

import React from "react";
import { Badge, Box, Grid, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Banner from "../../../assets/img/bg/UserDetails.png";
import NameRow from "./NameRow";
import EmploymentRow from "./EmploymentRow";
import AddressRow from "./AddressRow";
import BioDescriptionRow from "./BioDescriptionRow";

const useStyles = makeStyles(theme => ({
  userAvatar: {
    width: 150,
    height: 150,
    borderWidth: 7,
    borderColor: "#fff",
    borderStyle: "solid",
  },
  badgedAvatar: {
    position: "absolute",
    left: 30,
    top: 65,
  },
  name: {
    fontWeight: 800,
    fontSize: 22,
    color: theme.palette.text.gray,
    marginTop: 5,
    marginBottom: 5,
  },
  position: {
    fontSize: 16,
    color: theme.palette.text.gray,
  },
  location: {
    fontSize: 11,
    color: theme.palette.text.gray,
  },
  description: {
    fontSize: 16,
    color: theme.palette.text.gray,
  },
  viewAs: {
    position: "absolute",
    right: 0,
    marginRight: 18,
    top: 0,
    marginTop: 185,
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
  },
  compactSwitch: {
    position: "absolute",
    right: 0,
    marginRight: 0,
    top: 0,
    marginTop: 140,
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
  },
  mainContent: {
    paddingLeft: 200,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 18px",
      marginTop: 100,
    },
  },
}));

const MainContentReadOnly = ({ userDetails }) => {
  const classes = useStyles();
  const { avatarUrl } = userDetails;

  return (
    <Box flex={1} style={{ position: "relative" }}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        className={classes.badgedAvatar}
      >
        <Avatar className={classes.userAvatar} src={avatarUrl} />
      </Badge>

      <Box flex={1} style={{ height: 130, background: `url(${Banner})` }} />
      <Box flex={1} style={{ minHeight: 100 }}>
        <Grid container alignItems="center" className={classes.mainContent}>
          <Grid item sm={12} md={6}>
            <NameRow userDetails={userDetails} />
            <EmploymentRow userDetails={userDetails} />
            <AddressRow userDetails={userDetails} />
          </Grid>
          <Grid item xs={11}>
            <BioDescriptionRow userDetails={userDetails} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MainContentReadOnly;

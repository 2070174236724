import { gql } from '@apollo/client';

const PUBLIC_COMPANY = gql`
  query PublicCompany($token: String, $publicCompanyId: String) {
    publicCompany(token: $token, publicCompanyId: $publicCompanyId) {
      foundCompany {
        companyName
        publicCompanyId
        isHighlighted
      }
      notFound
      displayWarning
      inactivated
      attemptsMade
      remainingAttempts
    }
  }
`;

export default PUBLIC_COMPANY;

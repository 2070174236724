import React from "react";
import { makeStyles } from "@mui/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import PropTypes from "prop-types";
import ListItemText from "@mui/material/ListItemText";
import ContactSupport from "@mui/icons-material/ContactSupport";
import ListItem from "@mui/material/ListItem";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  listIcon: {
    minWidth: 0,
    marginRight: 8,
    color: theme.palette.component.headerColor,
    marginTop: 2,
    "& svg": {
      width: 15,
      height: 15,
    },
  },
  listText: {
    "& span": {
      color: theme.palette.component.headerColor,
      fontSize: 12,
    },
  },
}));

const AccountSupportMenuItem = ({ handleOpenSupport }) => {
  const classes = useStyles();

  return (
    <ListItem button className={classes.listItem} onClick={handleOpenSupport}>
      <ListItemIcon className={classes.listIcon}>
        <ContactSupport />
      </ListItemIcon>
      <FormattedMessage id="account.support">
        {text => <ListItemText className={classes.listText} primary={text} />}
      </FormattedMessage>
    </ListItem>
  );
};

AccountSupportMenuItem.propTypes = {
  handleOpenSupport: PropTypes.func.isRequired,
};

export default AccountSupportMenuItem;

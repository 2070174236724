import React from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DropzoneComponent from "react-dropzone-component";
import "../../../node_modules/react-dropzone-component/styles/filepicker.css";
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";

import { DROPZONE_PARALLEL_UPLOADS } from "../../constant";

const useStyles = makeStyles(theme => ({
  dzWrap: {
    "& .dropzone": {
      textAlign: "inherit",
      display: "flex",
      backgroundColor: "transparent",
      padding: 0,
      border: "none",
      // borderRadius: 4,
      fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
    },
    "& .dz-image": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  spinner: {
    marginTop: 5,
    color: theme.palette.primary.main,
  },
}));
const DropzoneSection = ({
  uploadFiles,
  uploadMultiple,
  maxFiles,
  children,
  clickable,
}) => {
  let myDropzone;

  const classes = useStyles();

  const handleUploadFiles = async files => {
    if (uploadFiles) {
      uploadFiles({ files });
    }
  };

  const initCallback = dropzone => {
    myDropzone = dropzone;
  };
  const componentConfig = {
    postUrl: "-",
  };

  const djsConfig = {
    maxFilesize: 4000,
    maxFiles,
    uploadMultiple,
    clickable,
    parallelUploads: DROPZONE_PARALLEL_UPLOADS,
    dictDefaultMessage: "",
    params: {
      name: "",
    },
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <CircularProgress className={classes.spinner} size={20} />
    ),
  };

  const eventHandlers = {
    init: dropzone => {
      initCallback(dropzone);
    },
    processingmultiple: files => {
      handleUploadFiles(files);
    },
  };

  return (
    <Box className={classes.dzWrap}>
      <DropzoneComponent
        config={componentConfig}
        djsConfig={djsConfig}
        eventHandlers={eventHandlers}
      >
        {children}
      </DropzoneComponent>
    </Box>
  );
};

DropzoneSection.defaultProps = {
  uploadFiles: () => {},
  uploadMultiple: false,
  clickable: false,
  maxFiles: null,
};

DropzoneSection.propTypes = {
  uploadFiles: PropTypes.func,
  uploadMultiple: PropTypes.bool,
  clickable: PropTypes.bool,
  maxFiles: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default DropzoneSection;

import { gql } from '@apollo/client';

const MonitoringFragment = gql`
  fragment MonitoringRecipientFragment on MonitoringRecipient {
    name
    userId
    username
  }
`;

export default MonitoringFragment;

// eslint-disable-next-line import/prefer-default-export
export const sidebarmenuitems = [
  {
    link: "",
    label: "admin.companySettings",
    icon: "fas fa-cog",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [
      {
        link: "/administration/company-settings/basic-settings",
        label: "admin.companySettings.basicSettings",
        icon: "fas fa-sliders-h",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/company-settings/appearance",
        label: "admin.companySettings.appearance",
        icon: "fas fa-palette",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      //{
      //  link: "/administration/company-templates",
      //  label: "admin.companyTemplates",
      //  icon: "fas fa-clone",
      //  defaultExpanded: false,
      //  enabledByRights: ["CanManageCompany"],
      //  childtree: [],
      //},
      {
        link: "/administration/company-settings/company-data",
        label: "admin.companySettings.companyData.Title",
        icon: "fas fa-database",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/company-settings/address",
        label: "admin.addressSettings",
        icon: "fas fa-map-marked-alt",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      //{
      //  link: "/administration/import",
      //  label: "admin.importExport",
      //  icon: "fas fa-arrows-alt-v",
      //  defaultExpanded: false,
      //  enabledByRights: ["CanManageCompany"],
      //  childtree: [],
      //},
      {
        link: "/administration/company-settings/logs",
        label: "admin.logs",
        icon: "fas fa-file-alt",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      /*{
        link: "/administration/texts",
        label: "admin.texts",
        icon: "fas fa-comment ",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },*/
      {
        link: "/administration/company-settings/company-networks",
        label: "admin.companysettings.companyNetworks",
        icon: "fas fa-sitemap",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/company-settings/company-security",
        label: "admin.companySettings.security",
        icon: "fas fa-shield-alt",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
    ],
  },
  {
    link: "",
    label: "admin.billing",
    icon: "fas fa-credit-card",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [
      {
        link: "",
        label: "admin.billing.purchaseServices",
        icon: "fas fa-shopping-cart",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/subscriptions",
        label: "admin.subscriptions.title",
        icon: "fas fa-user-cog",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/bills-and-payment",
        label: "admin.billing.billspayments",
        icon: "fas fa-file-invoice-dollar",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/payments",
        label: "admin.payments.menuTitle",
        icon: "fas fa-dollar-sign",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
    ],
  },
  {
    link: "",
    label: "user.management",
    icon: "fas fa-user-cog",
    defaultExpanded: true,
    enabledByRights: ["CanManageCompany", "CanManageRoles", "CanManageUsers"],
    childtree: [
      {
        link: "/administration/users",
        label: "user.users",
        icon: "fas fa-user",
        defaultExpanded: true,
        enabledByRights: ["CanManageCompany", "CanManageUsers"],
        childtree: [],
      },
      {
        link: "/administration/external-user",
        label: "user.externalUser",
        icon: "fas fa-user",
        defaultExpanded: true,
        enabledByRights: ["CanManageCompany", "CanManageUsers"],
        childtree: [],
      },
      // {
      //   link: "/administration/user-data",
      //   label: "User data",
      //   icon: "fas fa-user",
      //   defaultExpanded: true,
      //   enabledByRights: ["CanManageCompany", "CanManageUsers"],
      //   childtree: [],
      // },
      //{
      //  link: "/administration/user-groups",
      //  label: "product.groupsTab",
      //  icon: "fas fa-users",
      //  defaultExpanded: true,
      //  enabledByRights: ["CanManageCompany", "CanManageUsers"],
      //  childtree: [],
      //},
      {
        link: "/administration/company-roles",
        label: "companyRoles.companyRoles",
        icon: "fas fa-user-tie",
        defaultExpanded: true,
        enabledByRights: ["CanManageCompany", "CanManageRoles"],
        childtree: [],
      },
    ],
  },
  {
    link: "/administration/reservations",
    label: "admin.reservations",
    icon: "fas fa-check-double",
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },
  {
    link: "/administration/supplier",
    label: "supplier.suppliers",
    icon: "fas fa-clipboard-list",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },
  /*{
    link: "/administration/lists",
    label: "admin.list",
    icon: "fas fa-list",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },*/
  /*{
    link: "/administration/statistics",
    label: "admin.statistic",
    icon: "fas fa-chart-bar",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },*/
  /*{
    link: "/administration/cart",
    label: "cart.pageTitle",
    icon: "fas fa-shopping-cart",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },*/
  /*{
    link: "/administration",
    label: "admin.priceManagement",
    icon: "fas fa-dollar-sign",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },*/
  /*{
    link: "/administration/publications",
    label: "siteRoles.publications",
    icon: "fas fa-book-open",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },*/
  /*{
    link: "/administration/document-inspector",
    label: "admin.preFlight",
    icon: "fas fa-file-contract",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [],
  },*/
  {
    link: "/administration/workspaces",
    label: "admin.workspaceSettings",
    icon: "fas fa-layer-group",
    defaultExpanded: true,
    enabledByRights: [
      "CanManageCompany",
      "CanManageWorkspaces.AllWorkspaces",
      "CanManageWorkspaces.OwnedByTheUser",
    ],
    childtree: [],
  },
  // {
  //   link: "/administration/site-settings",
  //   label: "Default",
  //   icon: "fas fa-home",
  //   defaultExpanded: false,
  //   enabledByRights: ["CanManageCompany", "CanManageWorkspaces.AllWorkspaces", "CanManageWorkspaces.OwnedByTheUser"],
  //   childtree: [],
  // },
  {
    link: "",
    label: "admin.sales",
    icon: "fas fa-chart-line",
    defaultExpanded: false,
    enabledByRights: ["CanManageCompany"],
    childtree: [
      {
        link: "/administration/sales/quote-requests",
        label: "admin.sales.quoteRequests",
        icon: "fas fa-question",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/sales/quotations",
        label: "admin.sales.quotations",
        icon: "fas fa-exclamation",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/sales/orders",
        label: "admin.sales.orders",
        icon: "fas fa-handshake",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
      {
        link: "/administration/sales/invoices",
        label: "admin.sales.invoices",
        icon: "fas fa-file-invoice",
        defaultExpanded: false,
        enabledByRights: ["CanManageCompany"],
        childtree: [],
      },
    ],
  },
];

import { useLazyQuery } from "@apollo/client";
import { GET_COMPANY_SECURITY_SETTINGS } from "../../graphql/queries/CompanyAdminContext";

// eslint-disable-next-line import/prefer-default-export
export const useGetCompanySecuritySettings = () => {
  const [getSettings, { error, loading }] = useLazyQuery(GET_COMPANY_SECURITY_SETTINGS);

  const executeGetSettings = async ({ companyId, onError, onSuccess }) => {
    try {
      const res = await getSettings({
        variables: {
          companyId,
        },
      });

      const result = res?.data?.companyAdminContext?.companySettings?.getCompanySecuritySettings;

      if (result?.success) {
        onSuccess?.({
          securitySettings: result.securitySettings
        });
      }
      else {
        onError?.();
      }
    } catch (err) {
      console.error("error >", err);
      onError?.();
    }
  };

  return [executeGetSettings, { error, loading }];
};

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const INITIALIZE_FILE_UPLOAD = gql`
  mutation InitializeFileUpload(
    $folderId: Int!
    $forceCreateNewResource: Boolean
    $resourceId: ID
  ) {
    initFileUpload(
      folderId: $folderId
      forceCreateNewResource: $forceCreateNewResource
      resourceId: $resourceId
    ) {
      success
      errorResult {
        ...ErrorResultFields
      }
      url
    }
  }
  ${ErrorResultFragment}
`;

export default INITIALIZE_FILE_UPLOAD;

import { Separator, Item, Menu, Submenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { styled } from "@mui/material/styles";

const StyledItem = styled(Item)(({ theme }) => ({
  fontSize: 11,
  "&:hover": {
    "& .react-contexify__item__content": {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  "& .react-contexify__item__content": {
    color: "#fff !important",
  },
}));

const StyledMenu = styled(Menu)(({ sx, theme }) => ({
  backgroundColor: `${theme.palette.primary.main} !important`,
  boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125) !important",
  fontSize: 11,
  lineHeight: "20px",
  paddingTop: "0 !important",
  paddingBottom: "0 !important",
  "& .react-contexify__separator": {
    backgroundColor: "#fff !important",
    float: "none",
    margin: 0,
  },
  ...sx,
}));

const StyledSubmenu = styled(Submenu)(({ theme }) => ({
  fontSize: 11,
  backgroundColor: theme.palette.primary.main,
  "& .react-contexify__submenu": {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    padding: 0,
  },
  "& .react-contexify__item__content": {
    color: "#fff !important",
  },
  "&:hover": {
    "& > .react-contexify__item__content": {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  "& .react-contexify__submenu-arrow": {
    fontSize: 8,
  },
}));

export { StyledItem as Item };
export { StyledMenu as Menu };
export { Separator };
export { StyledSubmenu as Submenu };

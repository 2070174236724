import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  paper: ({ width }) => ({
    width,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[5],
  }),
}));

const ModalPaper = ({ children, width }) => {
  const dynamicStyle = {
    width,
  };
  const classes = useStyles(dynamicStyle);

  return <div className={classes.paper}>{children}</div>;
};

ModalPaper.defaultProps = {
  width: 500,
};

ModalPaper.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default ModalPaper;

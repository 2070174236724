import React, { useState, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TreeItem from "@mui/lab/TreeItem";

const useStyles = makeStyles(theme => ({
  labelRoot: {
    display: "flex",
    alignItems: "center",
    color: "#555",
    textDecoration: "none",
    "&:hover": {
      color: "#555",
    },
    "&:focus": {
      color: "#555",
    },
    "&:active": {
      color: "#555",
    },
  },
  dimmed: {
    opacity: 0.5,
  },
  labelIcon: {
    fontSize: 12,
    marginLeft: 4,
    marginRight: 8,
    lineHeight: "14px",
    overflow: "unset",
    textAlign: "center",
    color: "#555",
  },
  label: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: "14px",
  },
  draggedOver: {
    backgroundColor: "transparent",
    border: `2px dashed ${theme.palette.primary.dark}`,
    padding: 0,
  },
  notDraggedOver: {
    backgroundColor: "transparent",
    padding: 2,
    margin: 2,
  },
}));

const RenderCompany = ({
  company,
  handleContextMenu,
  handleMoveCompany,
  treeData,
}) => {
  const classes = useStyles();

  const [draggedOver, setDraggedOver] = useState(false);

  const { name, childCompanyIds, memberId } = company || {};

  const handleDragStart = (e, c) => {
    e.stopPropagation();
    e.dataTransfer.setData("networkCompany", JSON.stringify(c));
  };

  const handleDrop = (e, c) => {
    e.stopPropagation();
    setDraggedOver(false);
    handleMoveCompany(e, c);
  };

  const handleOnDragOver = e => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleOnDragLeave = e => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const renderLabel = () => {
    return (
      <Box
        className={classes.labelRoot}
        onDragLeave={handleOnDragLeave}
        onDragOver={handleOnDragOver}
      >
        <Icon className={clsx([`fas fa-building`, classes.labelIcon])} />
        <Typography className={classes.label}>{name}</Typography>
      </Box>
    );
  };

  if (Array.isArray(childCompanyIds) && childCompanyIds.length > 0) {
    return (
      <TreeItem
        className={clsx([
          draggedOver ? classes.draggedOver : classes.notDraggedOver,
        ])}
        draggable
        label={renderLabel()}
        nodeId={`c_${memberId}`}
        onContextMenu={e => handleContextMenu(e, company)}
        onDrop={e => handleDrop(e, company)}
        onDragStart={e => handleDragStart(e, company)}
      >
        {childCompanyIds.map(c => (
          <Fragment key={c}>
            <RenderCompany
              company={treeData[`c_${c}`]}
              handleContextMenu={handleContextMenu}
              handleMoveCompany={handleMoveCompany}
              key={c}
              treeData={treeData}
            />
          </Fragment>
        ))}
      </TreeItem>
    );
  }

  return (
    <TreeItem
      className={clsx([
        draggedOver ? classes.draggedOver : classes.notDraggedOver,
      ])}
      draggable
      label={renderLabel()}
      nodeId={`c_${memberId}`}
      onContextMenu={e => handleContextMenu(e, company)}
      onDrop={e => handleDrop(e, company)}
      onDragStart={e => handleDragStart(e, company)}
    />
  );
};

RenderCompany.defaultProps = {
  company: null,
  treeData: {},
};

RenderCompany.propTypes = {
  company: PropTypes.shape({
    childCompanies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    childCompanyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    companyId: PropTypes.number.isRequired,
    isRoot: PropTypes.bool.isRequired,
    memberId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    networkId: PropTypes.number.isRequired,
    parentCompanyId: PropTypes.number,
  }),
  handleContextMenu: PropTypes.func.isRequired,
  handleMoveCompany: PropTypes.func.isRequired,
  treeData: PropTypes.shape({}),
};

export default RenderCompany;

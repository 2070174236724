import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import IconLink from "../common/FormControl/IconLink";
import ColumnSearch from "./ColumnSearch";
import ColumnSearchDate from "./ColumnSearchDate";
import CheckboxDefault from "../common/FormControl/Checkbox";
import PickingListTableHeaderCell from "./PickingListTableHeaderCell";
import { headers } from "./helpers";

const useStyles = makeStyles(theme => ({
  th: {
    padding: 7,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    verticalAlign: "top",
    whiteSpace: "nowrap",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },

  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  tcCheckbox: {
    width: 16,
    padding: 0,
    verticalAlign: "top",
  },
}));

const PickingListTableHeader = ({
  searchValues,
  setSearchValues,
  fetchPickingListOrders,
  showColumn,
  toggleHeader,
  handleToggleHeader,
  pickingListOrders,
  setSelectedOrders,
  isHistory,
}) => {
  const classes = useStyles();

  const [checkbox, setCheckbox] = useState(false);

  const handleCheckAll = e => {
    const { checked } = e.target;
    setCheckbox(checked);
    if (checked) {
      const ids = pickingListOrders.map(x => x.pickingListOrdersItemId);
      setSelectedOrders(ids);
    } else {
      setSelectedOrders([]);
    }
  };

  const handleSearchValues = e => {
    const { name, value } = e.target;

    setSearchValues({ ...searchValues, [name]: value });
  };

  const handleClear = name => {
    setSearchValues({ ...searchValues, [name]: "" });
  };

  const handleClearDate = () => {
    const initialFromDate = new Date().setDate(new Date().getDate() - 14);

    setSearchValues({
      ...searchValues,
      fromDate: initialFromDate,
      toDate: new Date(),
    });
  };

  const handleRequestSort = (event, property) => {
    const isDesc =
      searchValues.sortField === property && searchValues.sortDesc === true;

    setSearchValues({
      ...searchValues,
      sortField: property,
      sortDesc: !isDesc,
    });
  };

  const handleSortField = property => event => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {showColumn.orderNo && (
          <TableCell className={classes.th}>
            <IconLink
              icon="fa fa-search"
              customClass={classes.colSearchIcon}
              onClick={() => handleToggleHeader("orderNo")}
            />
            <TableSortLabel
              active={searchValues.sortField === "orderNo"}
              direction={
                searchValues.sortField === "orderNo" && searchValues.sortDesc
                  ? "desc"
                  : "asc"
              }
              onClick={handleSortField("orderNo")}
            >
              <FormattedMessage id="pickingList.orderNo" />
            </TableSortLabel>
            {toggleHeader.orderNo && (
              <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
                <div>
                  <ColumnSearch
                    onChange={handleSearchValues}
                    handleClear={handleClear}
                    name="companyOrderNo"
                    value={searchValues.companyOrderNo}
                    fetchPickingListOrders={fetchPickingListOrders}
                  />
                </div>
              </ClickAwayListener>
            )}
          </TableCell>
        )}
        {showColumn.orderDate && (
          <TableCell className={classes.th}>
            <IconLink
              icon="fa fa-search"
              customClass={classes.colSearchIcon}
              onClick={() => handleToggleHeader("orderDate")}
            />
            <TableSortLabel
              active={searchValues.sortField === "orderDate"}
              direction={
                searchValues.sortField === "orderDate" && searchValues.sortDesc
                  ? "desc"
                  : "asc"
              }
              onClick={handleSortField("orderDate")}
            >
              <FormattedMessage id="pickingList.orderDate" />
            </TableSortLabel>
            {toggleHeader.orderDate && (
              <ClickAwayListener onClickAway={() => handleToggleHeader("")}>
                <div>
                  <ColumnSearchDate
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                    fetchPickingListOrders={fetchPickingListOrders}
                    handleClearDate={handleClearDate}
                  />
                </div>
              </ClickAwayListener>
            )}
          </TableCell>
        )}

        {headers.map(header => {
          return (
            <>
              {showColumn[header.column] && (
                <PickingListTableHeaderCell
                  showSearchField={header.showSearchField}
                  fetchPickingListOrders={fetchPickingListOrders}
                  handleClear={handleClear}
                  handleSortField={handleSortField}
                  label={header.label}
                  name={header.name}
                  searchValues={searchValues}
                  onChange={handleSearchValues}
                  toggleHeader={toggleHeader}
                  handleToggleHeader={handleToggleHeader}
                />
              )}
            </>
          );
        })}

        {showColumn.mark && (
          <TableCell className={classes.th}>
            <FormattedMessage id="pickingList.mark" />
          </TableCell>
        )}
        {!isHistory && (
          <TableCell className={classes.tcCheckbox}>
            <CheckboxDefault value={checkbox} onChange={handleCheckAll} />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

PickingListTableHeader.defaultProps = {
  searchValues: {},
  showColumn: {},
  pickingListOrders: [],
};

PickingListTableHeader.propTypes = {
  showColumn: PropTypes.shape({
    orderNo: PropTypes.bool,
    orderDate: PropTypes.bool,
    section: PropTypes.bool,
    department: PropTypes.bool,
    user: PropTypes.bool,
    reference: PropTypes.bool,
    city: PropTypes.bool,
    pickedBy: PropTypes.bool,
    totalWeight: PropTypes.bool,
    sumInclVat: PropTypes.bool,
  }),
  fetchPickingListOrders: PropTypes.func.isRequired,
  searchValues: PropTypes.shape({
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
    companyOrderNo: PropTypes.string,
    ordererReference: PropTypes.string,
    pickedBy: PropTypes.string,
    username: PropTypes.string,
    ordererCity: PropTypes.string,
    deliveryCity: PropTypes.string,
    section: PropTypes.string,
  }),
  toggleHeader: PropTypes.shape({
    orderNo: PropTypes.bool,
    orderDate: PropTypes.bool,
  }).isRequired,
  setSearchValues: PropTypes.func.isRequired,
  handleToggleHeader: PropTypes.func.isRequired,
  pickingListOrders: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedOrders: PropTypes.func.isRequired,
};

export default PickingListTableHeader;

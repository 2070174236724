import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import NavMenu from "../../../common/NavMenu";
import ToolTipArrow from "../../../common/ToolTipArrow";

const useStyles = makeStyles(theme => ({
  link: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 20,
    color: theme.palette.primary.contrastText,
    marginBottom: 10,
    textTransform: "uppercase",
    fontWeight: 600,
    display: "block",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
    "&:focus": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const NavMenuList = ({ menus, selectWorkspace }) => {
  const classes = useStyles();

  const homeMenu = menus.find(x => x.page === "OVER_VIEW_DASHBOARD");
  return (
    <>
      <ToolTipArrow
        title={
          homeMenu ? (
            <FormattedMessage
              id={`navMenu.${homeMenu.name ? homeMenu.name : "Hem"}`}
            />
          ) : (
            <FormattedMessage id="navMenu.overview" />
          )
        }
        placement="top"
      >
        <Link
          to="/"
          className={classes.link}
          onClick={() => selectWorkspace({ id: null, name: null })}
        >
          <i className={clsx(["fas fa-home", classes.icon])} />
        </Link>
      </ToolTipArrow>
      {menus.map((menu, index) => {
        if (menu.page === "OVER_VIEW_DASHBOARD") {
          return null;
        }
        return (
          <NavMenu
            customCss={classes.link}
            menu={menu}
            key={`${menu.name}-${index}`}
          />
        );
      })}
    </>
  );
};

NavMenuList.defaultProps = {
  menus: [],
  selectWorkspace: null,
};

NavMenuList.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.shape({})),
  selectWorkspace: PropTypes.func,
};

export default NavMenuList;

import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "3px 10px",
    fontSize: 12,
    textTransform: "none",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
}));

const ButtonPrimaryAltSm = ({ label, customClass, component, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      component={component}
      className={clsx([classes.root, customClass])}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default ButtonPrimaryAltSm;

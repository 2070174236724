import { gql } from '@apollo/client';

const getRequestForProposalTabs = gql`
  query GetRequestForProposalTabs($siteId: Int!) {
    requestForProposal {
      requestForProposalView(siteId: $siteId) {
        requestForProposalProductListForSite {
          items {
            icon
            identifier
            name
          }
          siteId
        }
        sitesAllowedForFileSelection {
          name
        }
      }
    }
  }
`;

export default getRequestForProposalTabs;

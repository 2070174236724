import React, { memo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import FolderFileList from "./FolderFileList";
// import PropertiesForm from "./PropertiesForm";
import PropertiesForm from "../../common/FilesTable/PropertiesForm";
// import { useGetResources } from "../../../hooks";

const useStyles = makeStyles(() => ({
  section1: {
    flex: 1,
  },
  section2: {
    flex: 3,
  },
  root: {
    marginTop: 10,
  },
}));

const FolderFileSection = ({
  checkedFolders,
  folder,
  folderId,
  folderPropertyFormValues,
  productionSettings,
  propertyFormValues,
  selectedFoldersProperties,
  selectedResources,
  setCheckedFolders,
  setFolderPropertyFormValues,
  setPropertyFormValues,
  setSelectedFoldersProperties,
  setSelectedResources,
  selectedFolders,
  resources,
  setProductionSettingErrors,
  productionSettingErrors,
  folderMandatoryFields,
}) => {
  const classes = useStyles();

  const { name: folderName } = folder || {};

  const callbackCheckboxChange = (event, resourceId) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedFoldersProperties([]);
      const newSelectedResources = [
        ...new Set([...selectedResources, resourceId]),
      ];
      setSelectedResources(newSelectedResources);
    } else {
      const newSelectedResources = selectedResources.filter(
        x => x !== resourceId
      );
      setSelectedResources(newSelectedResources);
    }
  };

  const handleCheckboxChange = useCallback(
    (event, resourceId) => {
      callbackCheckboxChange(event, resourceId);
    },
    [selectedResources]
  );

  const validateFolderProperties = folderPropertyData => {
    let valid = false;

    const fields = Object.keys(folderMandatoryFields);

    if (folderPropertyData.length === 0) {
      return valid;
    }

    if (folderPropertyData.length !== fields.length) {
      return valid;
    }

    let isValidFields = [];
    folderPropertyData.forEach((property, idx) => {
      fields.forEach(field => {
        if (property.propertyName === field) {
          const { propertyValue } = property;
          const isValid = !isEmpty(propertyValue);

          isValidFields = [...isValidFields, isValid];
        }
      });
    });

    valid = isValidFields.every(x => x === true);

    return valid;
  };

  const handleCheckboxChangeFolder = (event, folderId) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedResources([]);
      const newSelectedFolder = [
        ...new Set([...selectedFoldersProperties, folderId]),
      ];
      setSelectedFoldersProperties(newSelectedFolder);
      // if (propertyFormValues.length > 0) {
      //   const newSelectedFolder = [
      //     ...new Set([...selectedFoldersProperties, folderId]),
      //   ];
      //   setSelectedFoldersProperties(newSelectedFolder);
      // } else {
      //   const resourcesId = resources.map(x => {
      //     return x.id;
      //   });
      //   const newSelectedFolder = [
      //     ...new Set([...selectedFoldersProperties, folderId]),
      //   ];
      //   setSelectedFoldersProperties(newSelectedFolder);

      //   setSelectedResources(resourcesId);
      // }
    } else {
      const newSelectedFolder = selectedFoldersProperties.filter(
        x => x !== folderId
      );
      setSelectedFoldersProperties(newSelectedFolder);
      setSelectedResources([]);
    }
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item className={classes.section1}>
        <PropertiesForm
          folder={folder}
          folderId={folderId}
          folderMandatoryFields={folderMandatoryFields}
          folderName={folderName}
          folderPropertyFormValues={folderPropertyFormValues}
          productionSettingErrors={productionSettingErrors}
          productionSettings={productionSettings}
          propertyFormValues={propertyFormValues}
          resources={resources}
          selectedFoldersProperties={selectedFoldersProperties}
          selectedResources={selectedResources}
          setFolderPropertyFormValues={setFolderPropertyFormValues}
          setProductionSettingErrors={setProductionSettingErrors}
          setPropertyFormValues={setPropertyFormValues}
        />
      </Grid>
      <Grid item className={classes.section2}>
        <FolderFileList
          folderMandatoryFields={folderMandatoryFields}
          validateFolderProperties={validateFolderProperties}
          checkedFolders={checkedFolders}
          folder={folder}
          folderPropertyFormValues={folderPropertyFormValues}
          handleCheckboxChange={handleCheckboxChange}
          handleCheckboxChangeFolder={handleCheckboxChangeFolder}
          productionSettings={productionSettings}
          propertyFormValues={propertyFormValues}
          resources={resources}
          selectedFoldersProperties={selectedFoldersProperties}
          selectedResources={selectedResources}
          setCheckedFolders={setCheckedFolders}
          setFolderPropertyFormValues={setFolderPropertyFormValues}
          setPropertyFormValues={setPropertyFormValues}
        />
      </Grid>
    </Grid>
  );
};

FolderFileSection.defaultProps = {
  productionSettings: {},
  folder: {},
};

FolderFileSection.propTypes = {
  productionSettings: PropTypes.shape({}),
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  folder: PropTypes.shape({}),
};

export default memo(FolderFileSection);

import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  th: {
    padding: 7,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    verticalAlign: "top",
    background: "#efefef",
  },
}));

const ConnectedWorkspaceHeader = () => {
  const classes = useStyles();
  const intl = useIntl();

  const headers = [
    {
      name: "workspace",
      label: intl.formatMessage({
        id: "workspace.workspace",
      }),
    },
    {
      name: "user",
      label: intl.formatMessage({
        id: "orderHistorySearch.user",
      }),
    },
    {
      name: "status",
      label: intl.formatMessage({
        id: "supplier.status",
      }),
    },
    { name: "remove", label: "" },
  ];
  return (
    <TableHead>
      <TableRow>
        {headers.map(head => {
          return <TableCell className={classes.th}>{head.label}</TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
};

export default ConnectedWorkspaceHeader;

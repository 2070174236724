import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, TableCell, Typography } from "@mui/material";
import { FileIcon } from "../../../common";
import RowCheckbox from "../RowCheckbox";
import FileItemLabels from "../FileItemLabels";

const NameTableCell = ({
  handleCheckboxChange,
  isSelected,
  resource,
  folder,
  productionProperties,
  itemFormValue,
  folderFormValues,
  handleRemoveFileSettings,
}) => {
  const onCheckboxChange = event => {
    handleCheckboxChange(event, resource.id);
  };

  return (
    <TableCell style={{}} colSpan={3}>
      <Box display="flex" alignItems="center">
        <RowCheckbox onChange={onCheckboxChange} checked={isSelected} />
        <FileIcon fileExtension={resource.fileExtension} />
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography style={{ fontSize: 12 }}>{resource.name}</Typography>
          </Box>
          <FileItemLabels
            productionProperties={productionProperties}
            itemFormValue={itemFormValue}
            folderFormValues={folderFormValues}
            handleRemoveFileSettings={handleRemoveFileSettings}
            resource={resource}
          />
        </Box>
      </Box>
    </TableCell>
  );
};

NameTableCell.defaultProps = {
  isSelected: false,
  productionProperties: [],
};

NameTableCell.propTypes = {
  handleRemoveFileSettings: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  resource: PropTypes.shape({
    fileExtension: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  folder: PropTypes.shape({ folderId: PropTypes.number }).isRequired,
  productionProperties: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(NameTableCell);

import { useState } from "react";

import {
  Cell,
  FooterRow,
} from '@table-library/react-table-library/table';
import EditorContainer from "../common/EditorContainer";
import StringEditor from "../common/EditorContainer/StringEditor";
import { TaskAction, TaskAddPosition } from './helper';

const NewTaskRow = ({ columns, onAddTask }) => {

  const [value, setValue] = useState("");

  const handleAddTask = (newValue, reason) => {


    if (reason == "KeyEnter") {
      onAddTask({ action: TaskAction.INSERT_NEW, task: null, pos: TaskAddPosition.LAST, taskname: newValue });

      setValue("");
    }
    else {
      setValue(newValue);
    }
  }

  return (      
        <EditorContainer
          component={StringEditor}
          disabled={false}
          enableEdit={true}
          fullWidth
          multiline={false}
          onChange={handleAddTask}
          placeholder="Ny uppgift ..."
          sx={{
            border: "1px solid rgba(0, 0, 0, 0)",
            borderRadius: "4px",
            color: "rgba(0, 0, 0, 0.87)",
            "&.MuiBox-root": { minHeight: "1.5em", padding: "0px", border: "0px", },
            "& .MuiTypography-root": { whiteSpace: "pre-wrap", },
            "&.MuiInputBase-root": { border: "1px solid rgba(0, 0, 0, 0.5)", },
            "&:hover": {
              backgroundColor: "#f0f0f0",
              cursor: "text",
            },
          }}
          value={value}
        />);
}

export default NewTaskRow;
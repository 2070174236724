import { handleActions } from "redux-actions";
import { toggleCart } from "../../../actions";

const defaultState = {
  open: false,
  name: null,
};

export default handleActions(
  {
    [toggleCart]: (state, { payload }) => {
      ("toggleCart");
      const { name } = payload;
      return {
        ...state,
        name,
        open: !state.open,
      };
    },
  },
  defaultState
);

import { gql } from '@apollo/client';

const CREATE_CONSENT_REQUEST = gql`
mutation createConsentRequest($input:ConsentRequestInput, $clientUrlBase:String!){
	
	consentContext{
		createConsentRequest(input:$input, clientUrlBase: $clientUrlBase)						
	}
}
`;

export default CREATE_CONSENT_REQUEST;

import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import NewCompanyButton from "./NewCompanyButton";

const useStyles = makeStyles(theme => ({
  list: {
    margin: 0,
    padding: 0,
  },
}));

const DividerItem = styled('li')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.black}`,
  height: "1px",
  pointerEvents: "none",
}));

const HeaderItem = styled('li')({
  pointerEvents: "none",
});

const ListBoxFooter = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.black}`,
  padding: "5px",
  textAlign: "center",
}));

const ListBoxHeader = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.common.black}`,
  padding: "5px",
  textAlign: "center",
}));

const StyledItemLabel = styled('span')(({ isHeaderItemLabel }) => ({
  display: "block",
  fontSize: 12,
  padding: 5,
  textTransform: (isHeaderItemLabel ? "uppercase" : ""),
  width: "100%",
}));

const ItemLabel = ({ isHighlighted, label, value }) => (
  <StyledItemLabel
    data-id={value}
    value={value}
  >
    {label}
    {isHighlighted ? " *" : ""}
  </StyledItemLabel>
);

const createItems = ({ children, highlightedCompanyIds }) => {
  const highlightedItems = [];
  const normalItems = [];

  const haveHighlightedItems =
    Array.isArray(highlightedCompanyIds) &&
    highlightedCompanyIds.length > 0;

  children.map((child, index) => {
    const isHighlighted = haveHighlightedItems && highlightedCompanyIds.includes(child.option.publicCompanyId);
    if (isHighlighted) {
      highlightedItems.push({ ...child });
    }
    else {
      normalItems.push({ ...child });
    }
  })

  return { highlightedItems, normalItems };
};

const renderListItem = (child, index, isHighlighted) => {
  const { props: childProps, option } = child;
  const { key } = childProps;
  const { companyName: label, publicCompanyId: value } = option;

  return (
    <li key={`${key}-${index}`} {...childProps}>
      <ItemLabel
        isHighlighted={isHighlighted}
        label={label}
        value={value}
      />
    </li>
  );
};

const CompanyListBox = forwardRef((props, ref) => {
  const classes = useStyles();

  const { children, highlightedCompanyIds, onNewCompanyClick, showFooter, showHeader } = props;

  const items = createItems({ children, highlightedCompanyIds });

  const haveHighlightedItems = (items.highlightedItems.length > 0);

  return (
    <>
      {showHeader && (
        <ListBoxHeader>
          <NewCompanyButton onClick={onNewCompanyClick} />
        </ListBoxHeader>
      )}
      <ul ref={ref} {...props} className={classes.list}>
        {haveHighlightedItems &&
          <>
            <HeaderItem>
              <StyledItemLabel isHeaderItemLabel>
                <FormattedMessage id="siteUsers.suggestedCompanies" />
              </StyledItemLabel>
            </HeaderItem>
            {items.highlightedItems.map((child, index) => renderListItem(child, index, true))}
            <DividerItem />
          </>
        }
        {items.normalItems.map((child, index) => renderListItem(child, index, false))}
      </ul>
      {showFooter && (
        <ListBoxFooter>
          <NewCompanyButton onClick={onNewCompanyClick} />
        </ListBoxFooter>
      )}
    </>
  );
});

export default CompanyListBox;

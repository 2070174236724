import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

const FormattedCurrency = ({ value }) => {
  return (
    <FormattedNumber
      value={value || 0}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="SEK"
      minimumFractionDigits={2}
      maximumFractionDigits={2}
    />
  );
};

FormattedCurrency.defaultProps = { value: 0 };

FormattedCurrency.propTypes = {
  value: PropTypes.number,
};

export default FormattedCurrency;

import { gql } from '@apollo/client';

const DELETE_TIME = gql`
  mutation deleteTimeReport(
    $taskId: Int!, $reportItemId:Int !
  ) {
    projects {
      deleteTimeReport(taskId:$taskId,reportItemId:$reportItemId) 
    }
  }  
`;

export default DELETE_TIME;

const isHexColor = (color) => /^#[0-9A-F]{6}$/i.test(color);

const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
};

const parseColor = (color) => {
  if (isHexColor(color)) {
    return hexToRgb(color);
  } else if (Array.isArray(color) && color.length === 3) {
    return color;
  } else if (typeof color === 'string' && color.startsWith('rgb')) {
    return color.match(/\d+/g).map(Number);
  }
  throw new Error(`Invalid color format: ${color}`);
};

const calculateLuminance = (r, g, b) => {
  const a = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const calculateContrastRatio = (luminance1, luminance2) => {
  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);
  return (brightest + 0.05) / (darkest + 0.05);
};

const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
  const hex = x.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}).join('');

const findContrastColors = (items, colorKey = 'color', contrastKey = 'contrastColor', options = {}) => {
  const {
    minContrastRatio = 4.5,
    baseColors = ['#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF']
  } = options;

  const backgroundColors = items.map(item => item[colorKey]);
  const backgroundLuminances = backgroundColors.map(color => calculateLuminance(...parseColor(color)));

  const contrastColors = [];
  const unusedBaseColors = [...baseColors];

  for (let i = 0; i < backgroundColors.length; i++) {
    let bestColor = null;
    let bestRatio = 0;

    // First, try to use an existing contrast color
    for (const existingColor of contrastColors) {
      const luminance = calculateLuminance(...parseColor(existingColor));
      const ratio = calculateContrastRatio(backgroundLuminances[i], luminance);
      if (ratio >= minContrastRatio && ratio > bestRatio) {
        bestColor = existingColor;
        bestRatio = ratio;
      }
    }

    // If no existing color works, try unused base colors
    if (!bestColor) {
      for (let j = 0; j < unusedBaseColors.length; j++) {
        const color = unusedBaseColors[j];
        const luminance = calculateLuminance(...parseColor(color));
        const ratio = calculateContrastRatio(backgroundLuminances[i], luminance);
        if (ratio >= minContrastRatio && ratio > bestRatio) {
          bestColor = color;
          bestRatio = ratio;
          unusedBaseColors.splice(j, 1);
          break;
        }
      }
    }

    // If still no color works, generate a new color
    if (!bestColor) {
      const bgRgb = parseColor(backgroundColors[i]);
      const newColor = bgRgb.map(v => 255 - v);
      bestColor = rgbToHex(...newColor);
      bestRatio = calculateContrastRatio(backgroundLuminances[i], calculateLuminance(...newColor));
    }

    contrastColors.push(bestColor);

    // Add the contrast color to the original item
    items[i][contrastKey] = bestColor;
  }

  return items;
};

export default findContrastColors;

// Usage example
//items = is an array of objects, that at least have color, but it might have other key-value pairs also (name etc).

//const itemsWithContrastColors = findContrastColors(items, 'backgroundColor', 'textColor', { minContrastRatio: 4.5 });
//console.log('Background colors:', backgroundColors);
//console.log('Contrast colors:', contrastColors);
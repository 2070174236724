import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  errorTitle: {
    fontWeight: 600,
    fontSize: 11,
  },
  errorSubTitle: {
    fontSize: 11,
  },
  errorFix: {
    fontWeight: 600,
    fontSize: 11,
    color: theme.palette.primary.main,
  },
}));

const StatusItemsFiles = ({ file, renderStatus }) => {
  const classes = useStyles();

  const { name, status, message, preflightReport } = file;

  return (
    <Box margin={2}>
      <Typography className={classes.errorTitle}>
        {name} {renderStatus(status)}
      </Typography>
      {preflightReport &&
        Array.isArray(preflightReport.failures) &&
        preflightReport.failures.length > 0 &&

        preflightReport.failures.map(x => {
          return (
            <Typography className={classes.errorSubTitle}>{x.message}</Typography>
          );
        })
      }
    </Box>
  );
};

StatusItemsFiles.defaultProps = {
  file: null,
};

StatusItemsFiles.propTypes = {
  file: PropTypes.shape({
    preflightReport: PropTypes.shape({
      failures: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    name: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }),
  renderStatus: PropTypes.func.isRequired,
};

export default StatusItemsFiles;

import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Typography, Grid, Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import CustomTextField from "../common/TextField";

const useStyles = makeStyles(theme => ({
  roleNameWrap: {
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: "1px solid #ddd",
    "& input": {
      backgroundColor: theme.palette.common.white,
    },
  },
  planLabel: {
    display: "inline-block",
    fontSize: 12,
    marginRight: 10,
  },
  badge: {
    fontSize: 10,
    borderRadius: 4,
    padding: "4px 6px",
    display: "inline-block",
  },
  badgeFree: {
    backgroundColor: "#fff",
    color: "#464646",
    border: "1px solid #989898",
  },
  badgeMini: {
    backgroundColor: "#ececec",
    color: "#989898",
    border: "1px solid #989898",
  },
  badgeStandard: {
    backgroundColor: "#E9F4FB",
    color: "#2491d9",
    border: "1px solid #2491d9",
  },
  badgeBusiness: {
    backgroundColor: "#f6fff4",
    color: "#34bf1b",
    border: "1px solid #34bf1b",
  },
  badgePro: {
    backgroundColor: "#f7f7ca",
    color: "#2491d9",
    border: "1px solid #989819",
  },
  badgePremium: {
    backgroundColor: "#FEF2E9",
    color: "#f58123",
    border: "1px solid #f58123",
  },
}));

const ManageRolesHeader = ({
  currentSubscriptionLevel,
  disabled,
  handleChange,
  isCreating,
  roleName,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const premiumText = intl.formatMessage({ id: "role.premium" });
  const proText = intl.formatMessage({ id: "role.pro" });
  const businessText = intl.formatMessage({ id: "role.business" });
  const standardText = intl.formatMessage({ id: "role.standard" });
  const miniText = intl.formatMessage({ id: "role.mini" });
  const freeText = intl.formatMessage({ id: "role.free" });

  const renderBadge = (label, customClass) => {
    return <Box className={clsx([classes.badge, customClass])}>{label}</Box>;
  };

  const handleCurrentSubscriptionLevel = subscription => {
    switch (subscription) {
      case 6:
        return renderBadge(premiumText, classes.badgePremium);
      case 5:
        return renderBadge(proText, classes.badgePro);
      case 4:
        return renderBadge(businessText, classes.badgeBusiness);
      case 3:
        return renderBadge(standardText, classes.badgeStandard);
      case 2:
        return renderBadge(miniText, classes.badgeMini);
      case 1:
        return renderBadge(freeText, classes.badgeFree);
      default:
        return renderBadge(freeText, classes.badgeFree);
    }
  };

  const showSubscriptionLevel = isCreating || currentSubscriptionLevel;

  return (
    <Grid container className={classes.roleNameWrap} alignItems="center">
      <Grid item xs={12} md={4}>
        <Box>
          <CustomTextField
            disabled={disabled}
            label={<FormattedMessage id="companyRoles.roleName" />}
            onChange={handleChange}
            required
            value={roleName}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={8} align="right">
        {showSubscriptionLevel && (
          <Box>
            <Typography className={classes.planLabel}>
              <FormattedMessage id="role.requirement" />
            </Typography>
            {handleCurrentSubscriptionLevel(currentSubscriptionLevel)}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

ManageRolesHeader.defaultProps = {
  currentSubscriptionLevel: "",
  disabled: false,
  roleName: null,
};

ManageRolesHeader.propTypes = {
  currentSubscriptionLevel: PropTypes.number,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  roleName: PropTypes.string,
};

export default memo(ManageRolesHeader);

import React, { memo } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { getFaClassType } from "../../helpers/get";

const useStyles = makeStyles(() => ({
  iconType: {
    fontSize: 16,
    paddingRight: 5,
    color: "#5b5654",
  },
}));

const FileIcon = ({ fileExtension }) => {
  const classes = useStyles();
  const faClassType = getFaClassType(fileExtension);

  return <Icon className={clsx([faClassType, classes.iconType])} />;
};

FileIcon.propTypes = {
  fileExtension: PropTypes.string.isRequired,
};

export default memo(FileIcon);

import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { FIND_SUPPLIERS_FOR_SITE_SUPPLIER_REQUEST } from "../../../graphql/queries";
import { getSiteId } from "../../../helpers/selectors";

const SearchSupplierField = ({
  onSearchChange,
  value,
  handleSearchTextChange,
}) => {
  const intl = useIntl();
  const [options, setOptions] = useState([]);
  const siteId = useSelector(state => getSiteId(state));

  const [getSearchResults, { data, loading }] = useLazyQuery(
    FIND_SUPPLIERS_FOR_SITE_SUPPLIER_REQUEST
  );

  const debouncedGetSearchResults = useCallback(
    debounce(searchTerm => {
      getSearchResults({
        variables: {
          siteId,
          searchTerm,
        },
      });
    }, 800),
    []
  );

  const onChangeSearchText = e => {
    debouncedGetSearchResults(e.target.value);
    handleSearchTextChange(e.target.value);
  };

  useEffect(() => {
    if (
      data &&
      data.supplierAdmin &&
      data.supplierAdmin.findSuppliersForSiteSupplierRequest
    ) {
      setOptions([...data.supplierAdmin.findSuppliersForSiteSupplierRequest]);
    }
  }, [data]);

  return (
    <Autocomplete
      getOptionLabel={option => option?.supplierName || ""}
      id="autocomplete-list-of-suppliers"
      noOptionsText={<FormattedMessage id="supplier.noOptions" />}
      onChange={(event, newValue, reason, details) => {
        if (onSearchChange) {
          onSearchChange(newValue);
        }
      }}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          autoComplete="off"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onChange={onChangeSearchText}
          placeholder={intl.formatMessage({
            id: "supplier.searchSupplierEmail",
          })}
          variant="outlined"
        />
      )}
      value={value}
    />
  );
};

SearchSupplierField.defaultProps = {
  value: {},
  options: [],
};

SearchSupplierField.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSearchTextChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  value: PropTypes.shape({ supplierName: PropTypes.string }),
};

export default SearchSupplierField;

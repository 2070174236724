import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import NewsHeadSample from "../../components/News-Old/NewsHeadSample";
import NewsContent from "../../components/News-Old/NewsContent";
import CommentsSection from "../../components/News-Old/Comments/CommentsSection";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
}));

export default function NewsSample() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <NewsHeadSample />
      <NewsContent />
      <CommentsSection
        numberOfComments={98}
        currentUser={{}}
        handleAddSiteComment={() => {}}
        loading={false}
        handleCommentChange={() => {}}
        commentText=""
        comments={[]}
        addSiteCommentLoading={false}
      />
    </Container>
  );
}

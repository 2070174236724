import React from "react";
import { Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  pageTitle: {
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
    fontSize: 16,
    marginTop: 0,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      textAlign: "center",
    },
  },
  cartIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 24,
    marginRight: 15,
    verticalAlign: "middle",
  },
}));
const PageTitle = () => {
  const classes = useStyles();

  return (
    <h5 className={classes.pageTitle}>
      <Icon className={clsx(["nc-icon nc-cart-simple", classes.cartIcon])} />
      <FormattedMessage id="cart.pageTitle" />
    </h5>
  );
};

export default PageTitle;

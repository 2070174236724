import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import CheckboxDefault from "./Checkbox";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
  root: {
    justifyContent: "space-evenly",
  },
  labelWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
  },
  fieldWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
}));

const CheckboxInline = ({ label, options, onChange, value }) => {
  const classes = useStyles();

  return (
    <Grid container mb={0.5} mt={0.5}>
      <Grid item xs={12} md={2} className={classes.labelWrap}>
        <span className={classes.label}>{label}</span>
      </Grid>
      <Grid item xs={12} md={10} className={classes.fieldWrap}>
        <FormGroup
          classes={{
            root: classes.root,
          }}
          row
        >
          {options.map(x => {
            return (
              <CheckboxDefault
                name={x.id}
                key={`checkbox-${x.id}`}
                label={x.label}
                value={value && Array.isArray(value) && value.includes(x.id)}
                cbcolor="main"
                onChange={onChange}
              />
            );
          })}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

CheckboxInline.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.isRequired,
    })
  ).isRequired,
};

export default CheckboxInline;

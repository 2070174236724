import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import InputColor from "react-input-color";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import { Typography, Box, Grid, IconButton } from "@mui/material";
import CustomTextField from "../common/TextField";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import DeleteModal from "./DeleteModal";
import {
  CREATE_SITE_FOLDER_COLOR,
  UPDATE_SITE_FOLDER_COLOR,
  DELETE_FOLDER_COLOR_SET,
} from "../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "700px",
    minHeight: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnNext: {
    marginTop: "8px",
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
    },
    "& .Mui-disabled:hover": {
      cursor: "not-allowed",
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
  },
  colorSet: {
    border: "1px solid transparent",
    fontSize: 12,
    padding: "7px 15px",
    backgroundColor: "#F9F9F9",
    borderRadius: 4,
    marginBottom: 5,
    position: "relative",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
    position: "absolute",
    right: "15px",
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  colorPicker: {
    width: "100%!important",
    marginTop: 8,
    height: "35px!important",
  },
  colorPickerContainer: {
    width: "100%",
  },
  selected: {
    border: "1px solid #f1c04f",
  },
}));

const ColorSetsModal = ({
  colorSchemes,
  disabled,
  fetchColorSchemes,
  handleClose,
  open,
  selectedSite,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const intl = useIntl();

  const initialColor = "#f1c04f";

  const [createColorSet] = useMutation(CREATE_SITE_FOLDER_COLOR);
  const [updateColorSet] = useMutation(UPDATE_SITE_FOLDER_COLOR);
  const [deleteColorSet] = useMutation(DELETE_FOLDER_COLOR_SET);

  const [schemeName, setSchemeName] = useState("");
  const [selectedColor, setSelectedColor] = useState(initialColor);
  const [selectedSet, setSelectedSet] = useState(null);

  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteScheme, setDeletedScheme] = useState({});

  const initialState = () => {
    setSchemeName("");
    setSelectedColor(initialColor);
  };

  const handleCreateColorSet = async () => {
    try {
      const res = await createColorSet({
        variables: {
          siteid: selectedSite,
          name: schemeName,
          defaultColor: selectedColor,
        },
      });

      if (res && res.data && res.data.createSiteFolderColorSet) {
        alert.success(<FormattedMessage id="siteRoles.createdColorScheme" />);
        setSelectedSet(null);
        initialState();
        fetchColorSchemes();
      } else {
        alert.error(
          <FormattedMessage id="siteRoles.failedToCreateColorScheme" />
        );
      }
    } catch (err) {
      console.log("error >", err);
      alert.error(
        <FormattedMessage id="siteRoles.failedToCreateColorScheme" />
      );
    }
  };

  const handleDeleteColorSet = async id => {
    try {
      const res = await deleteColorSet({
        variables: { siteFolderColorSetId: id },
      });

      if (res && res.data && res.data.deleteFolderColorSet) {
        alert.success(<FormattedMessage id="siteRoles.deletedColorScheme" />);
        setSelectedSet(null);
        initialState();
        fetchColorSchemes();
      } else {
        alert.error(
          <FormattedMessage id="siteRoles.failedToDeleteColorScheme" />
        );
      }
      setConfirmModal(false);
    } catch (err) {
      console.log("error >", err);
      alert.error(
        <FormattedMessage id="siteRoles.failedToDeleteColorScheme" />
      );
    }
  };

  const handleUpdateColorSet = async () => {
    try {
      const res = await updateColorSet({
        variables: {
          siteFolderColorSetId: selectedSet,
          name: schemeName,
          defaultColor: selectedColor,
        },
      });

      if (res && res.data && res.data.updateSiteFolderColorSet) {
        alert.success(<FormattedMessage id="siteRoles.updatedColorScheme" />);
        setSelectedSet(null);
        initialState();
        fetchColorSchemes();
      } else {
        alert.error(
          <FormattedMessage id="siteRoles.failedToUpdateColorScheme" />
        );
      }
    } catch (err) {
      console.error("error >", err);
      alert.error(
        <FormattedMessage id="siteRoles.failedToUpdateColorScheme" />
      );
    }
  };

  const handleButton = () => {
    if (selectedSet) {
      handleUpdateColorSet();
    } else {
      handleCreateColorSet();
    }
  };

  const handleSelectColorScheme = x => {
    if (x.siteColorSetId === selectedSet) {
      setSelectedSet(null);
      initialState();
    } else {
      setSelectedSet(x.siteColorSetId);
      setSelectedColor(x.defaultColor);
      setSchemeName(x.name);
    }
  };

  const handleOpenDeleteModal = (e, scheme) => {
    e.stopPropagation();
    const tempScheme = { name: scheme.name, id: scheme.siteColorSetId };
    setDeletedScheme(tempScheme);
    setConfirmModal(true);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="siteRoles.colorSets" />}
      />
      <DraggableModalBody>
        <Typography className={classes.label}>
          <FormattedMessage id="siteRoles.availableColorSets" />
        </Typography>
        {colorSchemes.map((x, idx) => {
          return (
            <Typography
              className={clsx([
                classes.colorSet,
                x.siteColorSetId === selectedSet && classes.selected,
              ])}
              key={x.siteColorSetId}
              onClick={() => !disabled && handleSelectColorScheme(x)}
            >
              <Icon
                className={"fa fa-folder"}
                style={{
                  color: x.defaultColor,
                  fontSize: "unset",
                  margin: "0px 5px 1px 0px",
                  verticalAlign: "middle",
                }}
              />
              {x.name}
              <IconButton
                className={classes.iconButtonDelete}
                disabled={disabled}
                onClick={e => handleOpenDeleteModal(e, x)}
              >
                <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
              </IconButton>
            </Typography>
          );
        })}
        <Grid container spacing={1} alignItems="center">
          <Grid item md={4}>
            <CustomTextField
              disabled={disabled}
              placeholder={intl.formatMessage({
                id: "siteRoles.addNewColorScheme",
              })}
              value={schemeName}
              onChange={e => setSchemeName(e.target.value)}
            />
          </Grid>
          <Grid item md={4} align="center">
            <Button
              className={classes.colorPickerContainer}
              disabled={disabled}
            >
              <InputColor
                className={classes.colorPicker}
                initialValue={selectedColor || initialColor}
                onChange={color => setSelectedColor(color.hex)}
              />
            </Button>
          </Grid>
          <Grid item md={4}>
            <Box align="center">
              <Button
                className={classes.btnNext}
                disabled={disabled || schemeName.length === 0}
                onClick={handleButton}
                fullWidth
              >
                {selectedSet ? (
                  <FormattedMessage id="btn.update" />
                ) : (
                  <FormattedMessage id="btn.add" />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        {confirmModal && (
          <DeleteModal
            handleClose={() => setConfirmModal(false)}
            handleDeleteColorSet={handleDeleteColorSet}
            id={deleteScheme.id}
            name={deleteScheme.name}
            open={confirmModal}
          />
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

ColorSetsModal.defaultProps = {
  colorSchemes: [],
  disabled: false,
  selectedSite: null,
};

ColorSetsModal.propTypes = {
  colorSchemes: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  fetchColorSchemes: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedSite: PropTypes.number,
};

export default ColorSetsModal;

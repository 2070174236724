import React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

const useStyles = makeStyles(() => ({
  root: {
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle=".draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ModalCompact = ({
  open,
  handleClose,
  customClass,
  children,
  padding,
  fullScreen,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      fullScreen={fullScreen}
      {...rest}
      className={classes.root}
      PaperProps={{
        style: fullScreen ?
          {
            padding,
            borderRadius: 6,
            margin: "6px",
            width: `calc(100% - 12px)`,
            maxWidth: `calc(100% - 12px)`,
            height: `calc(100% - 12px)`,
            maxHeight: `calc(100% - 12px)`,
          }
          : { padding, borderRadius: 6, maxWidth: "unset" },
      }}
    >
      {children}
    </Dialog>
  );
};

export default ModalCompact;

ModalCompact.defaultProps = {
  padding: 6,
  fullscreen: true,
};

export { default as ModalCompactBody } from "./ModalCompactBody";
export { default as ModalCompactHead } from "./ModalCompactHead";

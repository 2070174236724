import React, { useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useIntl, FormattedMessage } from "react-intl";
import SplitterLayout from "react-splitter-layout";
import CustomSelect from "../../../common/CustomSelect";
import ErrorBoundary from "../../../common/ErrorBoundary";
import FolderTreeContainer from "../FolderTreeContainer";
import SubfoldersDiffSettingsModal from "../SubfoldersDiffSettingsModal";
import NoProductionSettingsModal from "../../../common/NoProductionSettingsModal";
import ConfirmationModalRemoveSettings from "../../../common/ConfirmationModalRemoveSettings";
import MonitorFolderFilesSection from "./MonitorFolderFilesSection";
import WorkspaceSelector from "../../../common/WorkspaceSelector";
import SupplierSelection from "./SupplierSelection";
import "../../../../../node_modules/react-splitter-layout/lib/index.css";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    "& .splitter-layout": {
      width: "97%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .splitter-layout .layout-pane": {
      overflowX: "hidden !important",
      paddingTop: 5,
    },
  },
  folderLabel: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  label: {
    fontSize: 14,
    color: theme.palette.text.lightGray,
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  folderTree: {
    overflowX: "auto",
    paddingTop: "0 !important",
  },
  customSelect: {
    margin: "10px 0",
  },
  sectionTitle: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  siteSelectWrap: {
    marginBottom: 5,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.background.lightGray,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },
  dragbar: {
    "& .layout-splitter": {
      position: "relative !important",
      height: "4px !important",
      "&::after": {
        content: '" "',
        position: "absolute !important",
        left: 0,
        right: 0,
        top: -3,
        marginLeft: "auto",
        marginRight: "auto",
        width: 40,
        height: 8,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        border: "1px solid #dedede",
      },
    },
  },
  hasProperties: {
    "& .layout-splitter": {
      display: "none",
    },
  },
}));

const StepThree = ({
  checkedFolders,
  filesAndFolderMonitorEnabled,
  folderMonitorEnabled,
  folderPropertyFormValues,
  foldersData,
  handleSelectSubfolders,
  includeSubfolders,
  onFolderRowClick,
  printAndMailSelected,
  propertyFormValues,
  selectedFolders,
  setCheckedFolders,
  setFolderPropertyFormValues,
  setPropertyFormValues,
  setSelectedFolders,
  setSiteId,
  setSubFoldersWithDiffSettings,
  siteId,
  siteOptions,
  subFoldersWithDiffSettings,
  selectedFolder,
  visible,
  openedNodes,
  foldersProductionSettings,
  loadingProductionSettings,
  notificationActions,
  setWorkspaceName,
  workspaceName,
  workspaces,
  productionSettingErrors,
  setProductionSettingErrors,
  folderMandatoryFields,
  haveFolderFileSettings,
  siteSupplierId,
  setSiteSupplierId,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { folderId: selectedFolderId } = selectedFolder || {};
  const showProductionProperties = printAndMailSelected;

  // Modals
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [diffSettingsModal, setDiffSettingsModal] = useState(false);
  const [noProductionSettingsModal, setNoProductionSettingsModal] = useState(
    false
  );
  const [folderIdsUnchecked, setFolderIdsUnchecked] = useState([]);
  const [productionSettings, setProductionSettings] = useState({});

  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const showMonitorFolders = siteId !== null;

  const selectedSite = siteOptions.find(x => x.value === siteId);

  const hidePropertiesForm =
    notificationActions.length === 1 &&
    notificationActions[0].value === "EMAIL";
  // useEffect(() => {
  //   if (siteId) {
  //     console.log("siteId >>>", siteId);
  //     const site = workspaces.filter(x => x.id === siteId);
  //     console.log("site >>>", site);

  //     setWorkspaceName(site.name);
  //   }
  // }, [siteId, workspaces]);

  useEffect(() => {
    if (loadingProductionSettings) {
      setProductionSettings({});
    }
  }, [loadingProductionSettings]);

  useEffect(() => {
    if (selectedFolder && selectedFolderId && foldersProductionSettings) {
      const productionSettingsTemp =
        foldersProductionSettings[selectedFolderId];
      if (
        productionSettingsTemp &&
        productionSettingsTemp.folderProductSettings
      ) {
        setProductionSettings(productionSettingsTemp.folderProductSettings);
      } else {
        setProductionSettings(null);
      }
    }
  }, [selectedFolder, showProductionProperties, foldersProductionSettings]);

  useEffect(() => {
    if (
      includeSubfolders &&
      subFoldersWithDiffSettings &&
      subFoldersWithDiffSettings.length > 0
    ) {
      setDiffSettingsModal(true);
    }
  }, [includeSubfolders, subFoldersWithDiffSettings]);

  useEffect(() => {
    if (!confirmationModal) {
      setFolderIdsUnchecked([]);
    }
  }, [confirmationModal]);

  const handleCloseDiffSettingsModal = () => {
    setDiffSettingsModal(false);
  };

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  function dg(arg) {
    return arg;
  }

  const handleSiteChange = site => {
    setSiteId(site.siteId);
    setWorkspaceName(site.name);
    if (site.siteId !== siteId) {
      setSelectedFolders([]);
    }
    setWorkspaceSelector(false);
  };

  const handleConfirmYesButton = () => {
    // let newFormValues = [...propertyFormValues];
    // let newFolderFormValues = [...folderPropertyFormValues];

    // folderIdsUnchecked.forEach(x => {
    //   newFormValues = newFormValues.filter(y => x !== y.folderId);
    //   newFolderFormValues = newFolderFormValues.filter(z => z.folderId !== x);
    // });

    // const temp = checkedFolders.filter(x => !folderIdsUnchecked.includes(x));

    // setCheckedFolders([...temp]);
    // setPropertyFormValues([...newFormValues]);
    // setFolderPropertyFormValues([...newFolderFormValues]);

    setCheckedFolders([]);
    setPropertyFormValues([]);
    setFolderPropertyFormValues([]);

    setConfirmationModal(false);
  };

  if (!visible) return null;

  return (
    <div className={classes.root}>
      <ErrorBoundary>
        <Grid container spacing={2} className={classes.siteSelectWrap}>
          <Grid item xs={12} md={6}>
            <WorkspaceSelector
              workspaces={workspaces}
              open={workspaceSelector}
              searchable
              setOpen={setWorkspaceSelector}
              handleToggle={handleToggle}
              onClick={handleSiteChange}
              workspaceName={workspaceName}
            />
          </Grid>
        </Grid>
        <Box mb={1} />
        <SplitterLayout
          vertical
          percentage
          primaryIndex={0}
          primaryMinSiz={haveFolderFileSettings ? 50 : 100}
          secondaryInitialSize={haveFolderFileSettings ? 50 : 0}
          customClassName={clsx([
            classes.dragbar,
            !haveFolderFileSettings && classes.hasProperties,
          ])}
        >
          <Grid container spacing={2}>
            {showMonitorFolders && (
              <Grid item xs={12} md={12} className={classes.folderTree}>
                {!hidePropertiesForm && (
                  <SupplierSelection
                    siteId={siteId}
                    selectedSupplier={dg(siteSupplierId)}
                    handleSupplierSelection={setSiteSupplierId}
                  />
                )}
                {haveFolderFileSettings && (
                  <>
                    <Box className={classes.sectionTitle}>
                      <Box className={classes.labelWrap}>
                        <Typography
                          className={classes.folderLabel}
                          component="span"
                        >
                          <FormattedMessage id="monitoring.selectFoldersToMonitor" />
                        </Typography>
                        <Typography className={classes.label} component="span">
                          | <FormattedMessage id="common.selectFolder" />
                        </Typography>
                      </Box>
                    </Box>
                    <FolderTreeContainer
                      checkedFolders={checkedFolders}
                      folderPropertyFormValues={folderPropertyFormValues}
                      foldersData={foldersData}
                      includeSubfolders={includeSubfolders}
                      onFolderRowClick={onFolderRowClick}
                      openedNodes={openedNodes}
                      selectedFolder={selectedFolder}
                      setConfirmationModal={setConfirmationModal}
                      setFolderIdsUnchecked={setFolderIdsUnchecked}
                      propertyFormValues={propertyFormValues}
                      setPropertyFormValues={setPropertyFormValues}
                      setFolderPropertyFormValues={setFolderPropertyFormValues}
                      setCheckedFolders={setCheckedFolders}
                      isMonitoring
                    />
                  </>
                )}
              </Grid>
            )}
          </Grid>

          <MonitorFolderFilesSection
            checkedFolders={checkedFolders}
            filesAndFolderMonitorEnabled={filesAndFolderMonitorEnabled}
            folder={selectedFolder}
            selectedFolders={selectedFolders}
            folderMonitorEnabled={folderMonitorEnabled}
            folderPropertyFormValues={folderPropertyFormValues}
            propertyFormValues={propertyFormValues}
            includeSubfolders={includeSubfolders}
            selectedSite={selectedSite}
            setCheckedFolders={setCheckedFolders}
            setFolderPropertyFormValues={setFolderPropertyFormValues}
            setSubFoldersWithDiffSettings={setSubFoldersWithDiffSettings}
            setPropertyFormValues={setPropertyFormValues}
            showProductionProperties={showProductionProperties}
            setNoProductionSettingsModal={setNoProductionSettingsModal}
            noProductionSettingsModal={noProductionSettingsModal}
            foldersData={foldersData}
            mainTitle={intl.formatMessage({
              id: "monitoring.makeSettingsForSelectedFolder",
            })}
            subTitle={intl.formatMessage({
              id: "monitoring.settingAlsoAppliedToNewFiles",
            })}
            productionSettings={productionSettings}
            loadingProductionSettings={loadingProductionSettings}
            notificationActions={notificationActions}
            productionSettingErrors={productionSettingErrors}
            setProductionSettingErrors={setProductionSettingErrors}
            folderMandatoryFields={folderMandatoryFields}
            haveFolderFileSettings={haveFolderFileSettings}
          />
        </SplitterLayout>
        <ConfirmationModalRemoveSettings
          setConfirmationModal={setConfirmationModal}
          open={confirmationModal}
          setFolderIdsUnchecked={setFolderIdsUnchecked}
          folderIdsUnchecked={folderIdsUnchecked}
          setPropertyFormValues={setPropertyFormValues}
          setCheckedFolders={setCheckedFolders}
          checkedFolders={checkedFolders}
          propertyFormValues={propertyFormValues}
          setFolderPropertyFormValues={setFolderPropertyFormValues}
          folderPropertyFormValues={folderPropertyFormValues}
          handleConfirmYesButton={handleConfirmYesButton}
        />
        <SubfoldersDiffSettingsModal
          handleClose={handleCloseDiffSettingsModal}
          open={diffSettingsModal}
          folders={subFoldersWithDiffSettings}
        />
        <NoProductionSettingsModal
          open={noProductionSettingsModal}
          setOpen={setNoProductionSettingsModal}
        />
      </ErrorBoundary>
    </div>
  );
};

StepThree.defaultProps = {
  subFoldersWithDiffSettings: [],
  openedNodes: [],
  selectedFolders: [],
  selectedFolder: null,
  visible: false,
  loadingProductionSettings: false,
  foldersProductionSettings: {},
};

StepThree.propTypes = {
  workspaceName: PropTypes.string.isRequired,
  siteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,

  selectedFolders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  filesAndFolderMonitorEnabled: PropTypes.bool.isRequired,
  folderMonitorEnabled: PropTypes.bool.isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  foldersData: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  handleSelectSubfolders: PropTypes.func.isRequired,
  includeSubfolders: PropTypes.bool.isRequired,
  onFolderRowClick: PropTypes.func.isRequired,
  printAndMailSelected: PropTypes.bool.isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  setFolderPropertyFormValues: PropTypes.func.isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  setSelectedFolders: PropTypes.func.isRequired,
  setWorkspaceName: PropTypes.func.isRequired,
  setSiteId: PropTypes.func.isRequired,
  setSubFoldersWithDiffSettings: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  subFoldersWithDiffSettings: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFolder: PropTypes.shape({
    name: PropTypes.string.isRequired,
    folderId: PropTypes.number.isRequired,
  }),
  visible: PropTypes.bool,
  foldersProductionSettings: PropTypes.shape({}),
  loadingProductionSettings: PropTypes.bool,
};

export default memo(StepThree);

import { gql } from '@apollo/client';

const getResourceList = gql`
  query GetResourceList($resourceIds: [Int!], $breadcrumbs: Boolean) {
    resourceList(resourceIds: $resourceIds, breadcrumbs: $breadcrumbs) {
      items {
        breadcrumb {
          folders {
            folderId
            name
          }
          siteId
          siteName
        }
        changedBy
        changedDate
        description
        downloadedBy
        downloadedDate
        fileExtension
        id
        imageMetadata {
          contact
          date
          imageNo
          isFreeToUser
          location
          model
          occupation
          photographer
        }
        isActive
        isEditableFromSearchResult
        isLinkedResource
        lockedByAnotherUser
        lockedByFolder
        lockedByUsername
        name
        productImageUri
        size
        tags {
          tagId
          tagName
        }
        thumbnailImageUri
        type
        uploadedBy
        uploadedDate
        userCanUpdateLock
        versionNo
      }
      totalCount
    }
  }
`;

export default getResourceList;

import React from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { TableHead, TableCell, TableRow } from "@mui/material";

const useStyles = makeStyles(() => ({
  th: {
    fontSize: 10,
    fontWeight: 600,
    textTransform: "Uppercase",
  },
}));

const OrderRowHeader = ({ pickingListOrderStatus }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th} />
        <TableCell className={classes.th}>
          <FormattedMessage id="orderHistory.product" />
        </TableCell>
        {/* <TableCell className={classes.th}>
          <FormattedMessage id="orderHistory.package" />
        </TableCell> */}
        <TableCell className={classes.th}>
          <FormattedMessage id="detailedSearch.imageLocation" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="orderHistory.quantity" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="orderHistory.weight" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage
            id={
              pickingListOrderStatus === "PICKED"
                ? "pickingList.picked"
                : "pickingList.pick"
            }
          />
        </TableCell>
        {pickingListOrderStatus !== "NEW" && (
          <TableCell className={classes.th}>
            <FormattedMessage id="pickingList.pickedBy" />
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
export default OrderRowHeader;

import { gql } from '@apollo/client';

const SEND_EMAIL_TO_CUSTOMER = gql`
  mutation SendEmailToCustomer(
    $pickingListOrdersItemId: ID!
    $message: String
  ) {
    pickingListContext {
      sendEmailToCustomer(
        pickingListOrdersItemId: $pickingListOrdersItemId
        message: $message
      )
    }
  }
`;

export default SEND_EMAIL_TO_CUSTOMER;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const CREATE_USER = gql`
  mutation CreateUser($token: String, $user: CreateAccount) {
    createUser(token: $token, user: $user) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_USER;

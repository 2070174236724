import React, { useState, useEffect } from "react";
import { TableRow, TableCell, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import CheckboxDefault from "../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  tr: {
    cursor: "pointer",
  },
  td: {
    padding: "4px 0",
    fontSize: 11,
  },
  tdCb: {
    padding: "4px 8px",
    paddingRight: "0 !important",
    minWidth: 16,
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    "& .MuiSelect-select": {
      padding: "4px 6px",
      paddingRight: 16,
      fontSize: 11,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-iconOutlined": {
      right: 1,
      top: 5,
    },
  },
  infoIcon: {
    fontSize: 11,
  },
  iconButton: {
    padding: 0,
    marginLeft: 5,
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const ManageRolesMenu = ({
  settingKeys,
  label,
  name,
  checkbox,
  onChange,
  roleValues,
  disabled,
}) => {
  const classes = useStyles();
  const [checkedSettings, setCheckedSettings] = useState(false);
  useEffect(() => {
    if (settingKeys[name]) {
      const keys = Object.keys(settingKeys[name]);

      const checked = keys.some(x => roleValues[x]);
      setCheckedSettings(checked);
    }
  }, [roleValues]);

  return (
    <>
      <TableRow onClick={() => onChange(name)} className={classes.tr}>
        <TableCell className={classes.td}>{label}</TableCell>

        <TableCell className={classes.tdCb}>
          <div className={classes.checkBoxContainer}>
            <CheckboxDefault value={checkedSettings} disabled={disabled} />
            <IconButton className={classes.iconButton}>
              <Icon
                className={clsx([
                  "fas fa-angle-double-right",
                  classes.infoIcon,
                ])}
              />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

ManageRolesMenu.defaultProps = {
  options: null,
  tooltipTitle: null,
  tooltip: false,
  editIcon: null,
  onClick: null,
  name: null,
  checkbox: false,
  label: null,
  onChange: null,
  value: null,
  siteRoleValues: {},
  disabled: false,
};

ManageRolesMenu.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  checkbox: PropTypes.bool,
  options: PropTypes.arrayOf(),
  tooltipTitle: PropTypes.string,
  tooltip: PropTypes.bool,
  editIcon: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  child: PropTypes.arrayOf(PropTypes.shape({})),
  siteRoleValues: PropTypes.shape({}),
};

export default ManageRolesMenu;

import { gql } from '@apollo/client';

const INIT_SALES_DOCUMENTS_PRINT = gql`query initSalesDocumentPrint($salesDocumentIds: [Int!]) {
	companyAdminContext {
		salesDocuments {
			salesDoucmentPrint(salesDocumentIds: $salesDocumentIds)
		}
	}
}
`;

export default INIT_SALES_DOCUMENTS_PRINT;







import React from "react";
import PropTypes from "prop-types";
import FormattedQuantity from "../common/FormattedQuantity";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "../common/tables";

const Summary = ({ className, rows }) => {
  return (
    <TableContainer className={className}>
      <Table>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.module}>
              <TableCell>{row.module}</TableCell>
              <TableCell
                align="right"
                style={{ whiteSpace: "nowrap", verticalAlign: "top" }}
              >
                <FormattedQuantity quantity={row.quantity} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Summary.defaultProps = {
  className: {},
  rows: [],
};

Summary.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      module: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ),
};

export default Summary;

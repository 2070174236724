import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const getFolderSiteRoleRights = gql`
  query GetFolderSiteRoleRights($folderId: Int!) {
    folderContext {
      getFolderSiteRoleRights(folderId: $folderId) {
        errorResult {
          ...ErrorResultFields
        }
        siteRoleAccessRights {
          readAccess
          siteRoleId
          siteRoleName
          writeAccess
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default getFolderSiteRoleRights;

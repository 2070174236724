import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { alpha, Box, Button, ClickAwayListener, Grow, List, Paper, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import useChangeCurrentWorkspace from "../../../hooks/useChangeCurrentWorkspace";
import { ResponsiveEllipsis } from "..";
import WorkspaceContainer from "./WorkspaceContainer";

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: "auto",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flex: 11,
    },
  },
  wrapper: {
    position: "relative",
    minWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-label p": {
        fontSize: 12,
      },
    },
  },
  buttonWorkspace: {
    position: "relative",
    justifyContent: "space-between",
    color: theme.palette.component.headerColor,
    backgroundColor: alpha(theme.palette.common.grey, 0.35),
    paddingLeft: 22,
    paddingRight: 22,
    paddingTop: 10,
    paddingBottom: 10,
    width: "100%",
    textTransform: "none",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.grey, 0.5),
    },
    "& svg": {
      position: "absolute",
      right: 16,
      width: 20,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "7px 10px",
      fontSize: 12,
      "& svg": {
        right: 2,
      },
    },
  },
  popper: {
    //minWidth: "inherit",
    //width: "max-content",
    width: "100%",
    zIndex: 100,
  },
  paper: {
    borderRadius: 4,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    maxHeight: 400,
    overflowY: "auto",
    "& .MuiList-padding": {
      [theme.breakpoints.down("sm")]: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
  },
  ellipsisText: {
    maxWidth: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const WorkspaceNew = ({
  workspaceName,
  workspaces,
}) => {
  const changeCurrentWorkspace = useChangeCurrentWorkspace();

  const anchorRef = useRef(null);

  const classes = useStyles();

  const [lines, setLines] = useState(1);
  const [open, setOpen] = useState(false);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (workspaceName) {
      const strLine = workspaceName.split("\n");
      setLines(strLine.length);
    }
  }, [workspaceName]);

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={() => setOpen(prev => !prev)}
          className={classes.buttonWorkspace}
        >
          {lines > 1 && (
            <ResponsiveEllipsis
              text={workspaceName}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="words"
            />
          )}
          {lines === 1 && (
            <div className={classes.ellipsisText}>
              {workspaceName || <FormattedMessage id="btn.workspace" />}
            </div>
          )}
          <ArrowDropDown className={classes.caret} />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          className={classes.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "left top" : "left bottom",
              }}
            >
              <Paper id="menu-list-workspaces" className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <List component="nav" aria-labelledby="nested-list-subheader">
                    <WorkspaceContainer
                      onWorkspaceSelected={(siteId) => changeCurrentWorkspace(siteId)}
                      workspaces={workspaces}
                    />
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

WorkspaceNew.defaultProps = {
  workspaceName: null,
};

WorkspaceNew.propTypes = {
  workspaceName: PropTypes.string,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default WorkspaceNew;

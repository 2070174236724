import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import {
  SAVE_CART,
  DELETE_ITEM,
  UPDATE_ITEM,
  DELETE_ITEMS,
} from "../../../graphql/mutations";
import { cartInitialize } from "../../../actions";
import ProductRow from "./ProductRow";
import { getSelectedSiteOrderGroupId } from "../../../helpers/selectors";
import ProductTableHead from "../ProductTableHead";
import OrderGroup from "./OrderGroup";
import ConfirmationModal from "./ConfirmationModal";
import { CircularProgress, Grid } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    fontSize: 12,
  },
  th: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  removeRow: {
    padding: 5,
    color: theme.palette.secondary.dark,
  },
}));

const CartTable = ({
  cartItems,
  fetchCart,
  loadingFetchCart,
  fileGroups,
  oneStreamGroups,
  selectedItemIds,
  setSelectedItemIds,
  showUpdateSuppliers,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const [deleteItem] = useMutation(DELETE_ITEM);
  const [deleteItems] = useMutation(DELETE_ITEMS);
  const [saveCart] = useMutation(SAVE_CART);
  const [updateItem] = useMutation(UPDATE_ITEM);
  const dispatch = useDispatch();
  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleCheckboxChange = (e, id) => {
    const { checked } = e.target;

    if (checked) {
      setSelectedItemIds([...new Set([...selectedItemIds, id])]);
    } else {
      const filtered = selectedItemIds.filter(x => x !== id);
      setSelectedItemIds([...filtered]);
    }
  };

  const handleMultipleCheckboxChange = (e, ids) => {
    const { checked } = e.target;

    if (checked) {
      setSelectedItemIds([...new Set([...selectedItemIds, ...ids])]);
    } else {
      const filtered = selectedItemIds.filter(x => !ids.includes(x));
      setSelectedItemIds([...filtered]);
    }
  };

  const handleUpdateItem = async input => {
    try {
      const res = await updateItem({ variables: { input } });
      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.updateItem
      ) {
        const { success } = res.data.shoppingCartContext.updateItem;

        if (success) {
          alert.success(
            <FormattedMessage id="product.successUpdatedItemCart" />
          );
          fetchCart();
        } else {
          alert.error(<FormattedMessage id="product.failUpdatedItemCart" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const debounceHandleUpdate = useCallback(
    debounce(value => {
      handleUpdateItem(value);
    }, 1000),
    []
  );

  const handleQuantity = (value, shoppingCartItemId, supplier) => {
    const input = {
      quantity: value,
      shoppingCartItemId,
      siteSupplierId: supplier || null,
    };
    debounceHandleUpdate(input);
  };

  const handleUpdateSuppliers = (value, shoppingCartItemId, itemQuantity) => {
    const input = {
      quantity: itemQuantity,
      shoppingCartItemId,
      siteSupplierId: value,
    };
    handleUpdateItem(input);
  };

  const handleDeleteItem = async (id, index) => {
    const cartItemsTemp = [...cartItems];
    cartItemsTemp.splice(index, 1);

    try {
      const res = await deleteItem({
        variables: {
          shoppingCartItemId: id,
        },
      });

      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.deleteItem
      ) {
        const { success } = res.data.shoppingCartContext.deleteItem;
        if (success) {
          alert.success(
            <FormattedMessage id="product.successRemovedFromCart" />
          );
          dispatch(
            cartInitialize({
              items: cartItemsTemp,
            })
          );
        } else {
          alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
        }
      }
    } catch (err) {
      console.error("error ", err);
    }
  };

  const handleDeleteItems = async () => {
    const cartItemsTemp = [...cartItems];

    const newCartItems = cartItemsTemp.filter(
      x => !selectedItemIds.includes(x.shoppingCartItemId)
    );

    try {
      const res = await deleteItems({
        variables: {
          itemIds: selectedItemIds,
        },
      });

      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.deleteItems
      ) {
        const { success } = res.data.shoppingCartContext.deleteItems;
        if (success) {
          alert.success(
            <FormattedMessage id="product.successRemovedFromCart" />
          );
          dispatch(
            cartInitialize({
              items: newCartItems,
            })
          );
        } else {
          alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
        }
        setConfirmationModal(false);
      }
    } catch (err) {
      console.error("error ", err);
    }
  };

  const handleRemoveItemsFromCart = (id, index) => {
    if (Array.isArray(selectedItemIds) && selectedItemIds.length > 1) {
      setConfirmationModal(true);
    } else {
      handleDeleteItem(id, index);
    }
  };

  return (
    <Paper className={classes.root} elevation="0">
      <Table className={classes.table} stickyHeader size="medium">
        <ProductTableHead
          showUpdateSuppliers={showUpdateSuppliers}
          cartItems={cartItems}
          handleMultipleCheckboxChange={handleMultipleCheckboxChange}
        />
        <TableBody>
          {cartItems.map((item, index) => {
            const selected = selectedItemIds.includes(item.shoppingCartItemId);
            return (
              <ProductRow
                // eslint-disable-next-line react/no-array-index-key
                key={`${item.productId}-${index}`}
                cartItems={cartItems}
                handleDeleteItem={handleDeleteItem}
                handleUpdateProductQuantity={handleQuantity}
                handleUpdateSuppliers={handleUpdateSuppliers}
                item={item}
                orderGroupId={orderGroupId}
                productIndex={index}
                saveCart={saveCart}
                fetchCart={fetchCart}
                // loadingFetchCart={loadingFetchCart}
                handleCheckboxChange={handleCheckboxChange}
                selected={selected}
                handleRemoveItemsFromCart={handleRemoveItemsFromCart}
                showUpdateSuppliers={showUpdateSuppliers}
              />
            );
          })}
          {fileGroups.map((x, idx) => {
            return (
              <OrderGroup
                key={x.packageId}
                handleCheckboxChange={handleCheckboxChange}
                handleMultipleCheckboxChange={handleMultipleCheckboxChange}
                packageGroup={x}
                selectedItemIds={selectedItemIds}
                fileGroup
                folderIndex={idx}
              />
            );
          })}

          {oneStreamGroups.map((x, idx) => {
            return (
              <OrderGroup
                key={x.packageId}
                handleCheckboxChange={handleCheckboxChange}
                handleMultipleCheckboxChange={handleMultipleCheckboxChange}
                oneStream
                packageGroup={x}
                selectedItemIds={selectedItemIds}
                folderIndex={idx}
              />
            );
          })}
        </TableBody>
      </Table>
      {confirmationModal && (
        <ConfirmationModal
          open={confirmationModal}
          handleDeleteItems={handleDeleteItems}
          handleClose={() => setConfirmationModal(false)}
        />
      )}
    </Paper>
  );
};

CartTable.defaultProps = {
  loadingFetchCart: false,
  cartItems: [],
  oneStreamGroups: [],
  fileGroups: [],
};

CartTable.propTypes = {
  showUpdateSuppliers: PropTypes.bool.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.shape({})),
  fetchCart: PropTypes.func.isRequired,
  loadingFetchCart: PropTypes.bool,
  oneStreamGroups: PropTypes.arrayOf(PropTypes.shape({})),
  fileGroups: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CartTable;

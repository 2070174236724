import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import CustomSelect from "../../CustomSelect";

const useStyles = makeStyles(() => ({
  formControl: {
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3 !important",
    },
    "& .Mui-disabled:hover": {
      cursor: "not-allowed",
    },
    "& .MuiInputLabel-shrink.Mui-disabled": {
      backgroundColor: "transparent !important",
    },
  },
}));

const SelectNativeFull = ({
  inputLabel,
  onChange,
  value,
  options,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <InputLabel>{inputLabel}</InputLabel>
      <CustomSelect
        onChange={onChange}
        value={value}
        options={options}
        disabled={disabled}
        native
      />
    </FormControl>
  );
};

SelectNativeFull.defaultProps = {
  disabled: false,
  value: null,
  onChange: null,
};

SelectNativeFull.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  inputLabel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
};

export default SelectNativeFull;

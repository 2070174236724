import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import SelectFileFromSystemModal from "../../../../common/SelectFileFromSystemModal";

const useStyles = makeStyles(() => ({
  fieldWrap: {
    padding: 5,
    fontSize: 12,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  resourcesWrap: { marginTop: 3 },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "3px 8px",
    backgroundColor: "#66615B",
    color: "#fff",
  },
  uploadText: {
    margin: "0 10px",
    fontSize: 10,
  },
  chips: {
    marginTop: "20px",
  },
  chip: {
    width: "100%",
  },
}));

const disableUploadButton = (
  uploadedFiles,
  selectedResources,
  initialFileName
) => {
  if (
    (uploadedFiles && uploadedFiles.length > 0) ||
    (selectedResources && selectedResources.length > 0) ||
    initialFileName
  ) {
    return true;
  }

  return false;
};

const UploadField = ({
  uploadedFiles,
  onChange,
  handleRemoveUploadedFile,
  loading,
  multiple,
  accepts,
  selectedResources,
  setSelectedResources,
  handleRemoveResources,
  systemUpload,
  initialFileName,
  onDeleteInitialFileName,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.fieldWrap}>
      <Box>
        {loading ? (
          <CircularProgress color="secondary" size={20} />
        ) : (
          <>
            <Button
              variant="contained"
              className={classes.uploadButton}
              component="label"
              disabled={disableUploadButton(
                uploadedFiles,
                selectedResources,
                initialFileName
              )}
            >
              <FormattedMessage id="product.selectFile" />
              <input
                type="file"
                style={{ display: "none" }}
                onChange={onChange}
                multiple={multiple}
                accepts={accepts}
              />
            </Button>
            {systemUpload && (
              <span className={classes.uploadText}>
                <FormattedMessage id="rfp.or" />
              </span>
            )}

            {systemUpload && (
              <Button
                variant="contained"
                className={classes.uploadButton}
                onClick={handleOpen}
                disabled={disableUploadButton(
                  uploadedFiles,
                  selectedResources,
                  initialFileName
                )}
              >
                <FormattedMessage id="btn.uploadFromSystem" />
              </Button>
            )}
          </>
        )}
        {uploadedFiles && uploadedFiles.length > 0 && (
          <div className={classes.chips}>
            {uploadedFiles.map(x => (
              <Chip
                key={x.name}
                label={x.name}
                onDelete={() => handleRemoveUploadedFile(x)}
                color="primary"
                size="small"
                className={classes.chip}
              />
            ))}
          </div>
        )}
        {selectedResources && selectedResources.length > 0 && (
          <div className={classes.chips}>
            {selectedResources.map(x => (
              <Chip
                key={x.name}
                label={x.name}
                onDelete={() => handleRemoveResources(x)}
                color="primary"
                size="small"
                className={classes.chip}
              />
            ))}
          </div>
        )}
        {/* If no uploaded files or selected resources. Display the files that are
        uploaded previously */}
        {(!uploadedFiles ||
          uploadedFiles.length < 1 ||
          !selectedResources ||
          selectedResources.length < 1) &&
          initialFileName && (
            <div className={classes.chips}>
              <Chip
                label={initialFileName}
                onDelete={() => onDeleteInitialFileName()}
                color="primary"
                size="small"
                className={classes.chip}
              />
            </div>
          )}
      </Box>

      <SelectFileFromSystemModal
        open={open}
        handleClose={handleClose}
        setSelectedResources={setSelectedResources}
        selectedResources={selectedResources}
        isProductEdit
      />
    </Box>
  );
};

UploadField.defaultProps = {
  uploadedFiles: [],
  accepts: null,
  loading: false,
  selectedResources: [],
  setSelectedResources: () => false,
  handleRemoveResources: () => false,
  systemUpload: false,
  initialFileName: null,
};

UploadField.propTypes = {
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
  uploadedFiles: PropTypes.arrayOf(),
  accepts: PropTypes.string,
  handleRemoveUploadedFile: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectedResources: PropTypes.arrayOf(),
  setSelectedResources: PropTypes.func,
  handleRemoveResources: PropTypes.func,
  systemUpload: PropTypes.bool,
  initialFileName: PropTypes.string,
  onDeleteInitialFileName: PropTypes.func.isRequired,
};

export default UploadField;

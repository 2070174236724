import React, { useState, lazy,Suspense } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
//import { IconPicker, IconPickerItem } from "react-fa-icon-picker";
import InputColor from "react-input-color";
import ButtonPrimaryAlt from "../../common/ButtonPrimaryAlt";
import CustomTextField from "../../common/TextField";
import CustomSelect from "../../common/CustomSelect";
import TextAreaFull from "../../common/FormControl/TextAreaFull";
import SampleProfile from "../../../assets/img/default-avatar.png";
import PricingTable from "./PricingTable";

const IconPicker = lazy(() => import("../../common/ReactIconPicker"));
const IconPickerItem = lazy(() => import("../../common/ReactIconPickerItem"))


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  body: {
    padding: 20,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
  logoWrap: {
    padding: 10,
    backgroundColor: "#F7FCF6",
    borderRadius: 15,
    textAlign: "center",
    height: 160,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  select: {
    marginTop: 8,
    "& .MuiSelect-select": {
      padding: "7px 12px 8px 12px",
    },
  },
  switchRoot: {
    marginTop: 10,
  },
  text: {
    fontSize: 12,
  },
  ownerBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
  },
  profile: {
    width: 40,
    height: 40,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 10,
  },
  iconsWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },
  iconSelect: {
    marginLeft: 12,
  },
  colorPicker: {
    height: "30px!important",
    width: "30px!important",
  },
}));

const SiteSettings = () => {
  const classes = useStyles();
  const [iconState, setIconState] = useState("FaBookOpen");
  const [color, setColor] = useState("#662336");

  const handleIconPicker = icon => {
    setIconState(icon);
  };

  const handleChangeColor = e => {
    setColor(e.hex);
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.head}>
          <Typography className={classes.headTitle}>Site settings</Typography>
        </Box>
        <Box className={classes.body}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
                          <Box className={classes.logoWrap}>
                              <Suspense><IconPickerItem icon={iconState} size={100} color={color} /></Suspense>
              </Box>
              <Box className={classes.iconsWrapper}>
                <Box>
                  <FormattedMessage id="common.icon" />:{" "}
                </Box>
                              <Box className={classes.iconSelect}>
                                  <Suspense> <IconPicker
                    value={iconState}
                    buttonIconStyles={{ color }}
                    buttonStyles={{ borderColor: color }}
                    onChange={v => handleIconPicker(v)}
                                  /></Suspense>
                </Box>
              </Box>
              <Box className={classes.iconsWrapper}>
                <Box>
                  <FormattedMessage id="common.color" />:{" "}
                </Box>
                <Box className={classes.iconSelect}>
                  <InputColor
                    initialValue={color}
                    onChange={e => handleChangeColor(e)}
                    name="background"
                    placement="right"
                    className={classes.colorPicker}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>Site name</Typography>
                  <CustomTextField />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>Theme</Typography>
                  <CustomSelect
                    customClass={classes.select}
                    options={[{ label: "Default", value: 1 }]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.label}>
                    Site description
                  </Typography>
                  <TextAreaFull rows="5" rowsMax="5" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>
                    Beställningsvy <span>(Show prices)</span>
                  </Typography>
                  <CustomSelect
                    customClass={classes.select}
                    options={[{ label: "Default", value: 1 }]}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" />}
                    label="Let the user choose"
                    classes={{ root: classes.switchRoot, label: classes.text }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className={classes.label}>Site owner</Typography>
                  <Box className={classes.ownerBox}>
                    <img
                      src={SampleProfile}
                      alt="Profile"
                      className={classes.profile}
                    />
                    <Typography>Daniel Forsman</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.foot} align="right">
          <ButtonPrimaryAlt
            label={<FormattedMessage id="btn.saveChanges" />}
            icon="fa fa-save"
          />
        </Box>
      </Box>
      <Box mb={3} />
      <Box className={classes.root}>
        <Box className={classes.head}>
          <Typography className={classes.headTitle}>
            Allowed use of the following modules on the site
          </Typography>
        </Box>
        <Box className={classes.body}>
          <PricingTable />
        </Box>
      </Box>
    </>
  );
};

export default SiteSettings;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GET_NEWS_FOR_USER } from "../../../../graphql/queries/NewsContext";
import NewsTemplates from "../NewsTemplates";
import NewspostDisplay from "../../../../pages/NewspostDisplay";
import NewspostModal from "../../../News/NewspostModal";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.bgColor || theme.palette.common.white,
  },
}));

const NewsSite = ({ layout }) => {
  const { bgColor, contentBG, dividerColor, newsSiteFontColor, newsTemplate } =
    layout || {};
  const customStyles = { contentBG, dividerColor, newsSiteFontColor };
  const classes = useStyles({ bgColor });

  const [newsMain, setNewsMain] = useState([]);
  const [newsSub, setNewsSub] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(7);
  const [selectedNewspost, setSelectedNewspost] = useState(false);

  const { data: newsSiteData, loading } = useQuery(GET_NEWS_FOR_USER, {
    variables: {
      offset: page,
      pageSize: perPage,
    },
    skip: newsMain.length > 0,
  });

  //const handleChangePage = (event, newPage) => {
  //  setPage(newPage);
  //};
  //const handleChangeRowsPerPage = event => {
  //  setPerPage(parseInt(event.target.value, 10));
  //};

  const handleReadMore = (newspost) => {
    setSelectedNewspost(newspost);
  }

  const handleCloseNewsDisplay = () => {
    setSelectedNewspost(null);
  }

  useEffect(() => {
    if (
      newsSiteData &&
      newsSiteData.newsContext &&
      newsSiteData.newsContext.getNewsForUser &&
      newsSiteData.newsContext.getNewsForUser.length > 0
    ) {
      const news = newsSiteData.newsContext.getNewsForUser;
      let mainNews = [];
      let subNews = [];

      if (newsTemplate === 1) {
        mainNews = news.slice(0, 1); // [      1      ]
        subNews = news.slice(1, 5); // [1] [1] [1] [1]
        //subNews = news.slice(1, 9);  // [1] [1] [1] [1] x2
      }

      if (newsTemplate === 2) {
        mainNews = news.slice(0, 3); // [  1  ] [1] [1]
        subNews = news.slice(3, 7); // [1] [1] [1] [1]
        //subNews = news.slice(3, 11); // [1] [1] [1] [1] x2
      }

      setNewsMain(mainNews);
      setNewsSub(subNews);
    }
  }, [newsSiteData, newsTemplate]);

  return (
    <Box className={classes.root}>
      <NewsTemplates
        customStyles={customStyles}
        newsMain={newsMain}
        newsSub={newsSub}
        newsTemplate={newsTemplate}
        skeleton={loading}
        onClickReadMore={handleReadMore}
      />

      {selectedNewspost && (
        <NewspostModal open={!!(selectedNewspost)} newsPost={selectedNewspost} onClose={handleCloseNewsDisplay} />)}
    </Box>
  );
};

NewsSite.propTypes = {
  layout: PropTypes.shape({}).isRequired,
};

export default NewsSite;

import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box } from "@mui/material";
import SalesDocumentRowTableRow from "./SalesDocumentRowTableRow";

const grCell = {
  padding: "0px 0px 0px 0px",
  margin: "2px 8px 2px 0px",
};

const grCell_last = {
  padding: "0px 0px 0px 0px",
  margin: "2px 2px 2px 0px",
};

const grHeader = {
  display: "grid",
  //gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateColumns: "28px 0.6fr 0.9fr 0.6fr 108px 108px 168px 78px 78px 0.39fr 64px",
  width: "calc(100vw - (100vw - 100%))",
  height: "25px",
  paddingBottom: "26px",
  marginBottom: "10px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.22)",
};

const SalesDocumentRowTable = ({ companyId, settings, rows, articles, onAddRow, onChangeRow, onDeleteRow, onMoveRow }) => {

  const onDragEnd = (data) => {
    const { destination, reason, source } = data;
    if (!!destination && reason === "DROP") {
      onMoveRow?.({
        fromIndex: source.index,
        toIndex: destination.index,
      });
    }
  };

  return (
    <Box id="container">

      <Box sx={grHeader}>
        <Box sx={grCell} />
        {settings?.tableLabels && settings.tableLabels.map(h => {
          return <Box align={h.align} sx={grCell}>{h.label}</Box>;
        })}
        <Box sx={grCell_last} />
      </Box>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="salesDocumentRowTable">
          {provided => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {settings?.tableLabels && rows && rows.map((row, index) =>
                <Draggable draggableId={`row-${index}`} index={index} key={index}>
                  {props => (
                    <Box
                      key={row.articleNo + "_key_" + index}
                      ref={props.innerRef}
                      {...props.draggableProps}
                      {...props.dragHandleProps}
                    >
                      <SalesDocumentRowTableRow
                        articles={articles}
                        companyId={companyId}
                        disableDelete={rows.length < 2}
                        index={index}
                        onAddRow={onAddRow}
                        onChangeRow={onChangeRow}
                        onDeleteRow={onDeleteRow}
                        row={row}
                        settings={settings}
                      />
                    </Box>
                  )}
                </Draggable>
              )}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

    </Box >
  );
}

export default SalesDocumentRowTable;

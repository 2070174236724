import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SketchPicker } from "react-color";
import React, { useState, useCallback } from "react";
import { makeStyles, withStyles, useTheme } from "@mui/styles";

import { useMutation } from "@apollo/client";

import { useAlert } from "react-alert";
import { useSnackbar } from "../../../hooks";
import { Submenu, contextMenu } from "react-contexify";

import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { UPDATE_FOLDER_COLOR } from "../../../graphql/mutations";
import { PrimaryButton, GrayButton as CancelButton  } from "../../common";


const useStyles = makeStyles(theme => ({
  contextSubMenu: {
    fontSize: 11,
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "&:hover": {
      "& > .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__submenu-arrow": {
      fontSize: 8,
    },
  },
  contextSubMenuDisabled: {
    // [7563] pointerEvents: "none",
    "& .react-contexify__item__content": {
      opacity: 0.4,
      backgroundColor: "#7b505d",
    },
  },
  colorsWrapper: {
    background: "#fff",
    padding: 5,
    display: "flex",
    flexWrap: "wrap",
    minWidth: 150,
  },
  customColorWrapper: {
    borderTop: "1px solid #000",
    padding: 5,
    background: "#fff",
  },
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: 5,
    minHeight: 100,
    zIndex: 10,
    position: "relative",
  },
  label: {
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    position: "relative",
  },
  colorPicker: {
    border: "none!important",
    padding: "0!important",
    width: "20px !important",
    height: "20px !important",
    marginRight: "10px !important",

    "& span": {
      borderRadius: "4px !important",
    },
  },
  popOver: {
    position: "absolute",
    top: "-150px",
    right: "-210px",
    zIndex: 5,
  },
}));

const ColorSelectorModal = ({ canChangeFolderIcons,
  folderColor,
  folderId,
  onDisabled,
  onFolderColorChange,
  onClose

}) => {

  const [updateFolderColor] = useMutation(UPDATE_FOLDER_COLOR);

  const [color, setColor] = useState(folderColor ?? "#5b5654");

  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const handleUpdateFolder = async hex => {
    if (!canChangeFolderIcons) {
      onDisabled();
      return;
    }

    try {
      const res = await updateFolderColor({
        variables: { folderId, color: hex },
      });

      if (res && res.data && res.data.updateFolderColor) {
        onFolderColorChange(folderId, res.data.updateFolderColor);
        contextMenu.hideAll();
        alert.success(<FormattedMessage id="context.updatedFolderColor" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="context.unableToUpdateFolderColor" />
        );
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleOnChangeComplete = hex => {
    setColor(hex);    
  };

  const handleOnChange = hex => {
    setColor(hex);
  };

  const handleOk = () => {
    handleUpdateFolder(color);
    onClose();
  }

  return (<Dialog open={ true}>
    <DialogTitle>Mappfärg</DialogTitle>
      <DialogContent>
        <SketchPicker
          color={color}
          onChange={c => handleOnChange(c.hex)} onChangeComplete={c => handleOnChangeComplete(c.hex)}
        />
    </DialogContent>
    <DialogActions><CancelButton onClick={onClose}><FormattedMessage id="btn.cancel" /></CancelButton> <PrimaryButton onClick={handleOk}><FormattedMessage id="btn.ok" /></PrimaryButton></DialogActions>
  </Dialog>)
}

export default ColorSelectorModal;
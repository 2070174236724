import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const SAVE_COMPANY_MENU = gql`
  mutation SaveCompanyMenu(
    $companyId: Int!
    $companyRoleId: Int!
    $companyMenu: [SiteMenuEditInput]
  ) {
    companyAdminContext {
      companyRoles {
        saveCompanyMenu(
          companyId: $companyId
          companyRoleId: $companyRoleId
          companyMenu: $companyMenu
        ) {
          errorResult {
            ...ErrorResultFields
          }

          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default SAVE_COMPANY_MENU;

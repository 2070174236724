import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import ForceMFaSetup from "../../components/ForceMFaSetup";
import { MuiOtpInput } from "mui-one-time-password-input";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    background: "#f3f3f3",
  },
}));

const ForceMFASetupPage = () => {
  const classes = useStyles();

  const [otp, setOtp] = useState("");
  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={2} sx={{ justifyContent: 'center', height: 'calc(100vh - 112px - 20px)', alignContent: 'center' }}>
        <Grid item xs={12} md={5}>
            
            <ForceMFaSetup />          
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForceMFASetupPage;

import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  currentPositionField: {
    marginTop: 4,
    marginBottom: 2,
    "& div.MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0)",
    },
  },
  employmentTypeField: {
    marginTop: 4,
    marginBottom: 2,
    "& div.MuiInput-underline:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0)",
    },
  },
}));

const EmploymentRow = ({ userDetails }) => {
  const { employmentType, currentPosition } = userDetails || {};

  const classes = useStyles();

  return (
    <Box display="flex">
      {currentPosition && (
        <>
          <TextField
            className={classes.currentPositionField}
            label={<FormattedMessage id="settings.position" />}
            name="currentPosition"
            value={currentPosition || ""}
            variant="standard"
            style={{ paddingRight: 5 }}
            size="small"
            margin="dense"
            disabled
            fullWidth
          />
          <TextField
            className={classes.employmentTypeField}
            label={<FormattedMessage id="settings.employmentType" />}
            name="employmentType"
            value={employmentType || ""}
            variant="standard"
            style={{ paddingRight: 5 }}
            size="small"
            margin="dense"
            disabled
            fullWidth
          />
        </>
      )}
    </Box>
  );
};

export default EmploymentRow;

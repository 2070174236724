import { gql } from '@apollo/client';

const MonitoringTriggers = gql`
  fragment MonitoringTriggersFragment on MonitoringTriggers {
    fileFolders {
      fileFolderCreate
      fileFolderDownload
      fileFolderUpdate
      fileFolderLockChange
      fileFolderDelete
      fileCountEqualTo
      fileCountEqualToValue
      fileCountGreaterThan
      fileCountGreaterThanValue
    }
    roles {
      create
      delete
      update
    }
    users {
      create
      delete
      update
    }
  }
`;

export default MonitoringTriggers;

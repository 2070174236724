import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_ITEMS_SHOPPING_CART } from "../graphql/mutations";
import { cartInitialize } from "../actions";
import { getSelectedSiteOrderGroupId } from "../helpers/selectors";

// eslint-disable-next-line import/prefer-default-export
export const useUpdateItemsShoppingCart = () => {
  const [updateItems, { loading }] = useMutation(UPDATE_ITEMS_SHOPPING_CART);
  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const dispatch = useDispatch();

  const handleUpdateItems = async ({
    input,
    successUpdateItems,
    errorUpdateItems,
  }) => {
    try {
      const response = await updateItems({
        variables: {
          input,
        },
      });

      if (
        response &&
        response.data &&
        response.data.shoppingCartContext.updateItems
      ) {
        const {
          success,
          result = {},
        } = response.data.shoppingCartContext.updateItems;
        if (success) {
          const { groups = [] } = result;
          const orderGroup = groups.find(x => x.orderGroupId === ordergroupId);
          const { items = [], fileGroups = [], oneStreamGroups = [] } =
            orderGroup || {};
          dispatch(
            cartInitialize({
              items,
              fileGroups,
              oneStreamGroups,
            })
          );
          successUpdateItems();
        } else {
          errorUpdateItems();
        }
      } else {
        errorUpdateItems();
      }
    } catch (e) {
      errorUpdateItems();
    }
  };

  return { execute: handleUpdateItems, loading };
};

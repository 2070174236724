import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DeleteResourceVersion = gql`
  mutation DeleteResourceVersion($resourceVersionIdentifier: ID!) {
    deleteResourceVersion(
      resourceVersionIdentifier: $resourceVersionIdentifier
    ) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default DeleteResourceVersion;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  formControl: {
    fontSize: 12,
    padding: "10px 12px 11px 12px",
    color: "#66615b",
    height: "100%",
    top: "-0.5em",
  },
  labelRoot: {
    fontSize: 12,
    top: "-0.8em",
    "&.MuiInputLabel-shrink": {
      top: 0,
    },
  },
  inputRoot: {
    marginBottom: 0,
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3 !important",
    },
    "& .Mui-disabled:hover": {
      cursor: "default",
    },
    "& .MuiInputLabel-shrink.Mui-disabled": {
      backgroundColor: "transparent !important",
    },
  },
}));

const CustomTextField = ({
  disabled,
  endAdornment,
  error,
  helperText,
  label,
  marginType,
  name,
  onChange,
  placeholder,
  required,
  type,
  value,
  inputProps,
  onClick,
  defaultValue,
  onKeyDown,
  customClass,
  onFocus,
  customLabelClass,
  shrink,
  ...rest
}) => {
  const classes = useStyles();

  const onClickDisabled = () => {};

  // const shrink = value !== undefined && value !== null && value !== "" && label;
  // const shrink = Boolean(value) && Boolean(label);

  return (
    <TextField
      fullWidth
      name={name}
      margin={marginType}
      variant="outlined"
      className={clsx([classes.inputRoot, customClass])}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        classes: { input: classes.formControl },
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
      }}
      InputLabelProps={{
        classes: {
          root: customLabelClass || classes.labelRoot,
        },
        shrink,
      }}
      placeholder={placeholder}
      label={label}
      type={type}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      disabled={disabled}
      helperText={helperText}
      onClick={disabled ? onClickDisabled : onClick}
      onKeyDown={onKeyDown}
      error={error}
      required={required}
      defaultValue={defaultValue}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputProps}
      {...rest}
    />
  );
};

CustomTextField.defaultProps = {
  disabled: false,
  endAdornment: null,
  error: false,
  helperText: null,
  label: null,
  marginType: "dense",
  name: null,
  placeholder: null,
  required: false,
  type: "text",
  inputProps: {},
  defaultValue: "",
  onClick: null,
  value: null,
  onChange: null,
};

CustomTextField.propTypes = {
  disabled: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  marginType: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputProps: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  onClick: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CustomTextField;

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import IconLink from "../../common/FormControl/IconLink";

const useStyles = makeStyles(theme => ({
  cbWrap: {
    paddingLeft: 5,
  },
  infoIcon: {
    color: theme.palette.common.link,
  },
}));

const OriginalImage = ({
  includeOriginal,
  setIncludeOriginal,
  handleOpenOriginalImage,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.cbWrap}>
      <CheckboxDefault
        label={<FormattedMessage id="imageBasket.originalImage" />}
        value={includeOriginal}
        onChange={() => setIncludeOriginal(prevCheck => !prevCheck)}
      />
      <IconLink
        title={
          <FormattedMessage id="imageBasket.infoIcon.clickForDescription" />
        }
        icon="nc-icon nc-alert-circle-i"
        customClass={classes.infoIcon}
        onClick={handleOpenOriginalImage}
      />
    </Box>
  );
};

OriginalImage.propTypes = {
  includeOriginal: PropTypes.bool.isRequired,
  setIncludeOriginal: PropTypes.func.isRequired,
  handleOpenOriginalImage: PropTypes.func.isRequired,
};

export default OriginalImage;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  tabRoot: {
    minHeight: 48,
    color: theme.palette.component.tabColor,
  },
  tabHeading: {
    flexDirection: "row",
    color: theme.palette.component.tabColor,
    textTransform: "none",
    fontSize: 14,
    lineHeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      wordBreak: "break-all",
    },
  },
  tabHeadingIcon: {
    marginBottom: "0 !important",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selected: {
    backgroundColor: theme.palette.component.tabBackground,
    fontWeight: 600,
  },
}));

const PageTabs = ({ label, iconClass, value, ...others }) => {
  const classes = useStyles();
  return (
    <Tab
      icon={
        iconClass && (
          <Icon className={clsx([iconClass, classes.tabHeadingIcon])} />
        )
      }
      iconPosition="start"
      label={label}
      classes={{
        root: classes.tabRoot,
        wrapper: classes.tabHeading,
        selected: classes.selected,
      }}
      {...others}
      value={value}
    />
  );
};

PageTabs.defaultProps = {
  iconClass: "",
  label: "",
  value: null,
};

PageTabs.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconClass: PropTypes.string,
  value: PropTypes.number,
};

export default PageTabs;

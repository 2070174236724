import { useMutation } from "@apollo/client";
import { REPORT_TIME } from "../../graphql/mutations/ProjectEditContext";

// eslint-disable-next-line import/prefer-default-export
export const useReportTime = () => {
  const [mutation, { data, error, loading }] = useMutation(REPORT_TIME);

  const reportTime = async ({ id, comment, projectId, taskId, reportedTime, reportedAt }) => {

    const variables = {
      input: {
        id,
        comment,
        projectId,
        taskId,
        reportedTime,
        reportedAt
      }      
    };

    const res = await mutation({ variables });

    return res?.data?.projects?.reportTaskTime;
  };

  return [reportTime, { data, error, loading }];
};

// eslint-disable-next-line import/prefer-default-export
export const FILTER_NAMES = {
  UNORDERED: "_UNORDERED",
  COMPANY: "COMPANY",
  EMAIL: "EMAIL",
  LAST_LOGGED_IN: "LAST_LOGGED_IN",
  NAME: "NAME",
  PAYMENT_LIABILITY: "PAYMENT_LIABILITY",
  SITE_ROLE: "SITE_ROLE",
  STATUS: "STATUS",
};

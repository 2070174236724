export const validateFolderProperties = (
  folderPropertyData,
  folderMandatoryFields
) => {
  const manadatoryFields = Object.keys(folderMandatoryFields);

  //Selects unique propertynames from the selected properties
  const selectedFields = folderPropertyData
    .map(n => {
      return n.propertyName;
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  // all mandatory fields should be contained in the selectedFields array
  for (var i = 0; i < manadatoryFields.length; i++) {
    var test = manadatoryFields[i];
    if (selectedFields.indexOf(test) === -1) {
      return false;
    }
  }

  return true;
};

import PropTypes from "prop-types";
import TreeItem from "@mui/lab/TreeItem";
import { makeStyles } from "@mui/styles";
import { STRUCTURE_TYPES } from "../../../constant/types";
import StructureItem from "./StructureItem";
import StructureLabel from "./StructureLabel";

const useStyles = makeStyles(theme => ({
  node: {
    "& .MuiTreeItem-iconContainer": {
      order: 2,
    },
    "& .MuiCollapse-wrapper": {
      marginLeft: 5,
    },
  },
}));

const StructureNode = ({ node, onClick }) => {
  const { id, items, type } = node || {};

  const classes = useStyles();

  return (
    <>
      {Array.isArray(items) && items.length > 0 ? (
        <TreeItem
          className={classes.node}
          label={<StructureLabel item={node} />}
          nodeId={id}
        >
          {items.map(item => {
            return (
              <StructureItem
                item={item}
                key={item.siteId}
                onClick={onClick}
              />
            );
          })}
        </TreeItem>
      ) : (
        <TreeItem
          className={classes.node}
          label={<StructureLabel item={node} />}
          nodeId={id}
          onClick={() =>
            type === STRUCTURE_TYPES.SITE && onClick(node)
          }
        />
      )}
    </>
  );
};

StructureNode.defaultProps = {
  node: null,
};

StructureNode.propTypes = {
  node: PropTypes.shape({
    description: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    siteId: PropTypes.string,
    type: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};

export default StructureNode;

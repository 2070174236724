import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditProductModal from "./EditProductModal";
import ViewContainer from "./ViewContainer";
import ProductsTable from "./FileView/ProductsTable";
import "../../../node_modules/react-dropzone-component/styles/filepicker.css";
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";
import NoViewPermission from "./NoViewPermission";
import MetaDataModal from "./FileView/MetaDataModal/Modal";
import DropzoneSection from "./FileView/DropzoneSection";
import { GET_RESOURCES } from "../../graphql/queries";
import {
  setFileViewOpenNodes,
  setRefetchFiles,
  setSelectedResources,
} from "../../actions";
import { FILEVIEW_RIGHT_COLUMN } from "../../actions/constants";
import {
  FOLDER_LOCKED,
  FOLDER_READ_ACCESS,
  FOLDER_WRITE_ACCESS,
  RESOURCE_LOCKED,
} from "../../constant/errorCodes";
import {
  getRefetchFiles,
  getSelectedFolder,
  getSelectedResources,
} from "../../helpers/fileViewSelectors";
import { useDeepLinkContext, useSnackbar } from "../../hooks";
import UserContactInfoModal from "../common/UserContactInfoModal";
import RightSidebar from "./FileView/RightSidebar";

const { HIDE } = FILEVIEW_RIGHT_COLUMN;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    flex: "1 1 auto",
  },
  rightSidebarContainer: {
    minWidth: 300,
  },
  btnLink: {
    padding: 0,
    fontSize: 13,
    textTransform: "none",
    marginLeft: 5,
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
}));

const FileView = ({
  siteId,
  detailedSearchOpen,
  folderId,
  isSearch,
  metaDataSectionVisible,
  offset,
  openedNodes,
  openMetaDataModal,
  perPage,
  products,
  setMetaDataSectionVisible,
  setOpenMetaDataModal,
  setProductTotalCount,
  showFileView,
  siteRoleRights,
  onDeepLinkResourcesFetched,
  resourceListLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const snackbar = useSnackbar();

  const deepLinkContext = useDeepLinkContext();
  const { deepLink } = deepLinkContext;

  const { locked: folderIsLocked, write: userHasWritePermission } = useSelector(getSelectedFolder);

  const refetchFiles = useSelector(getRefetchFiles);
  const selectedResources = useSelector(getSelectedResources);

  const [getResources, { data, error, loading, refetch }] = useLazyQuery(GET_RESOURCES);

  const [sortfield, setSortfield] = useState("Name");
  const [resources, setResources] = useState([]);
  const [ascending, setAscending] = useState(true);
  const [refreshDropzone, setRefreshDropzone] = useState(false);
  const [productEditModal, setProductEditModal] = useState(false);
  const [productEditId, setProductEditId] = useState();
  const rightColumn = useSelector(state => state.ui.fileView.rightColumn);

  const [searchValues, setSearchValues] = useState(null);

  const [userContactDetails, setUserContactDetails] = useState(false);
  const [lockedByUsername, setLockedByUsername] = useState("");

  const handleOpenUserContactInfo = username => {
    setLockedByUsername(username);
    setUserContactDetails(true);
    snackbar.close();
  };

  const handleCloseUserContactInfo = () => {
    setLockedByUsername("");
    setUserContactDetails(false);
  };

  const handleCloseProductEditModal = () => {
    setProductEditModal(false);
  };

  const handleOpenProductEditModal = id => {
    setProductEditModal(true);
    setProductEditId(id);
  };

  useEffect(() => {
    setRefreshDropzone(false);
  }, [refreshDropzone]);

  useEffect(() => {
    if (!refreshDropzone) {
      setRefreshDropzone(true);
    }
    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length > 0
    ) {
      if (!metaDataSectionVisible) {
        setMetaDataSectionVisible(true);
      }
    } else if (metaDataSectionVisible) {
      setMetaDataSectionVisible(false);
    }
  }, [selectedResources]);

  useEffect(() => {
    return () => {
      dispatch(setFileViewOpenNodes({ nodes: [] }));
    };
  }, []);

  const fetchResources = (resourceId) => {
    getResources({
      variables: {
        includeLinkedResources: true,
        folderId,
        ascending,
        offset,
        pageSize: perPage,
        sortfield,
        columnFilter: searchValues,
        resourceId,
      },
      notifyOnNetworkStatusChange: true,
    });
  };

  const selectAllResources = () => {
    dispatch(setSelectedResources({ items: resources }));
  };

  const selectNoResources = () => {
    dispatch(setSelectedResources({ items: [] }));
  };

  const addSelectedResource = item => {
    dispatch(setSelectedResources({ items: [...selectedResources, item] }));
  };

  const addSelectedResources = items => {
    dispatch(setSelectedResources({ items: [...selectedResources, ...items] }));
  };

  const removeSelectedResource = item => {
    const temp = selectedResources.filter(x => x.id !== item.id);
    dispatch(setSelectedResources({ items: temp }));
  };

  useEffect(() => {
    if (refetchFiles) {
      refetch();
      dispatch(setRefetchFiles({ refetch: false }));
    }
  }, [refetchFiles]);

  useEffect(() => {
    if (folderId) {
      let resourceId;
      if (deepLink) {
        resourceId = deepLink.resourceId;
      }
      else {
        onDeepLinkResourcesFetched({});
      }
      fetchResources(resourceId);
    }
  }, [folderId, ascending, offset, perPage, sortfield, searchValues]);

  useEffect(() => {
    if (data && data.resources && data.resources.items) {
      const { offset: newOffset, totalCount } = data.resources;
      setResources(data.resources.items);
      setProductTotalCount(totalCount);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.resources && data.resources.items) {
      const { offset: newOffset, totalCount } = data.resources;

      if (deepLink) {
        if (offset != newOffset) {
          onDeepLinkResourcesFetched({ newOffset });
        }
        const { resourceId } = deepLink;
        var resource = data.resources.items.find(n => n.id == resourceId);
        if (!resource) {
          console.info("** Resource not found", { resourceId });
          deepLinkContext.clearData();
        }
      }
    }
  }, [data, deepLink]);

  // useEffect(() => {
  //   console.log("Setting products data!");
  //   if (products && Array.isArray(products.items) && !folderId) {
  //     setResources(products.items);
  //   }
  // }, [products]);

  useEffect(() => {
    if (Array.isArray(selectedResources)) {
      let updatedSelectedResources = [];

      selectedResources.forEach(x => {
        const resource = resources.find(y => y && x && y.id === x.id);
        if (resource) {
          updatedSelectedResources = [...updatedSelectedResources, resource];
        }
      });
      setSelectedResources(updatedSelectedResources);
    }
  }, [resources]);

  useEffect(() => {
    //setSelectedResources([]);
    dispatch(setSelectedResources({ items: [] }));
    setMetaDataSectionVisible(false);
  }, [folderId]);

  const handleError = (name, errorResult, username) => {
    const { data: errorData, errorCode } = errorResult;

    const personLink = (
      <div>
        {username},
        <Button
          className={classes.btnLink}
          onClick={() => handleOpenUserContactInfo(username)}
        >
          <FormattedMessage id="resources.clickHereToSeeUser" />
        </Button>
      </div>
    );

    const person = errorData[0].value;
    if (errorCode === RESOURCE_LOCKED) {
      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: name, person: personLink }
        )
      );
    }

    if (errorCode === FOLDER_LOCKED) {
      const lockedFolder = errorData[1].value;

      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: lockedFolder, person }
        )
      );
    }

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }
    console.error(errorData.errorMessage);
  };

  return (
    <ViewContainer isSearch={isSearch}>
      <div className={classes.root}>
        <div className={classes.mainContainer}>
          {showFileView || detailedSearchOpen ? (
            <>
              <ProductsTable
                resources={resources}
                sortfield={sortfield}
                setSortfield={setSortfield}
                searchValues={searchValues}
                setSearchValues={setSearchValues}
                ascending={ascending}
                setAscending={setAscending}
                loading={loading || resourceListLoading}
                error={error}
                selectedResources={selectedResources}
                addSelectedResource={addSelectedResource}
                removeSelectedResource={removeSelectedResource}
                setOpenMetaDataModal={setOpenMetaDataModal}
                refetchResources={refetch}
                siteRoleRights={siteRoleRights}
                setSelectedResources={setSelectedResources}
                addSelectedResources={addSelectedResources}
                folderId={folderId}
                openedNodes={openedNodes}
                handleOpenProductEditModal={handleOpenProductEditModal}
                handleError={handleError}
                isSearch={isSearch}
              />
              {refreshDropzone === false &&
                folderIsLocked === false &&
                userHasWritePermission && (
                  <DropzoneSection
                    folderId={folderId}
                    setMetaDataSectionVisible={setMetaDataSectionVisible}
                    addSelectedResources={addSelectedResources}
                    selectedResources={selectedResources}
                    setSelectedResources={setSelectedResources}
                    refetch={refetch}
                    setRefreshDropzone={setRefreshDropzone}
                    handleError={handleError}
                  />
                )}
            </>
          ) : (
            <NoViewPermission />
          )}
        </div>
        {rightColumn !== HIDE && (
          <div className={classes.rightSidebarContainer}>
            <RightSidebar siteId={ siteId}/>
          </div>
        )}
      </div>

      {openMetaDataModal && (
        <MetaDataModal
          folderId={folderId}
          open={openMetaDataModal}
          handleClose={() => setOpenMetaDataModal(false)}
          //resources={resources}
          selectedResources={selectedResources}
          //addSelectedResource={addSelectedResource}
          //removeSelectedResource={removeSelectedResource}
          //setSelectedResources={setSelectedResources}
          //selectAllResources={selectAllResources}
          //selectNoResources={selectNoResources}
          //refetch={refetch}
          handleOpenProductEditModal={handleOpenProductEditModal}
          handleError={handleError}
        />
      )}

      {productEditModal && (
        <EditProductModal
          refetchResources={refetch}
          handleClose={handleCloseProductEditModal}
          open={productEditModal}
          resourceId={productEditId}
          folderId={folderId}
          handleError={handleError}
        />
      )}

      {userContactDetails && (
        <UserContactInfoModal
          open={userContactDetails}
          handleClose={() => handleCloseUserContactInfo()}
          username={lockedByUsername}
        />
      )}
    </ViewContainer>
  );
};

FileView.defaultProps = {
  folderId: null,
  isSearch: null,
  openedNodes: [],
  products: {},
  resourceListLoading: null,
};

FileView.propTypes = {
  detailedSearchOpen: PropTypes.bool.isRequired,
  folderId: PropTypes.number,
  isSearch: PropTypes.bool,
  metaDataSectionVisible: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  openMetaDataModal: PropTypes.bool.isRequired,
  perPage: PropTypes.number.isRequired,
  products: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) }),
  resourceListLoading: PropTypes.bool,
  setMetaDataSectionVisible: PropTypes.func.isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  setProductTotalCount: PropTypes.func.isRequired,
  showFileView: PropTypes.bool.isRequired,
  siteRoleRights: PropTypes.shape({ fileView: PropTypes.bool }).isRequired,
};

export default FileView;

import { gql } from '@apollo/client';
import MonitoringRecipient from "../fragments/MonitoringRecipient";
import MonitoringTriggers from "../fragments/MonitoringTriggers";
import MonitoringOrderRecipient from "../fragments/MonitoringOrderRecipient";

const getMonitoringItem = gql`
  query getMonitoringItem($monitoringItemId: Int!) {
    monitoringContext {
      item(monitoringItemId: $monitoringItemId) {
        fileMonitoring {
          monitoredFiles {
            folderId
            name
            productionSettings {
              orderQuantity
              propertyValueIds
              quantityFixedValueId
            }
            resourceId
          }
          monitoredFolders {
            folderId
            name
            productionSettings {
              orderQuantity
              propertyValueIds
              quantityFixedValueId
            }
          }
          shoppingCartSettings {
            costPlace
            costPlace2
            costPlace3
            message
          }
        }
        monitoringItemId
        name
        notificationActions
        notificationOccasion
        orderRecipients {
          ...MonitoringOrderRecipientFragment
        }
        recipients {
          ...MonitoringRecipientFragment
        }
        siteId
        triggers {
          ...MonitoringTriggersFragment
        }
        userId
        siteSupplierId
      }
    }
  }
  ${MonitoringTriggers}
  ${MonitoringRecipient}
  ${MonitoringOrderRecipient}
`;

export default getMonitoringItem;

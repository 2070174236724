import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  modalBody: {
    padding: 20,
    backgroundColor: theme.palette.common.white,
  },
}));

const ModalBody = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.modalBody}>{children}</div>;
};

ModalBody.defaultProps = {};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalBody;

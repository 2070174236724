import { handleActions } from "redux-actions";
import { setTotalProducts } from "../../../../actions";

const defaultState = {
  count: null,
};

export default handleActions(
  {
    [setTotalProducts]: (state, { payload }) => {
      const { total } = payload;
      return {
        ...state,
        count: total,
      };
    },
  },
  defaultState
);

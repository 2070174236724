import { gql } from '@apollo/client';
import SiteChannelMessageFragment from "../../fragments/SiteChannelMessage";

const ADD_MESSAGE = gql`
  mutation AddSiteChannelMessage($message: MessageInput) {
    siteChannelContext {
      addMessage(message: $message) {
        ...SiteChannelMessageFragment
      }
    }
  }
  ${SiteChannelMessageFragment}
`;

export default ADD_MESSAGE;

import { gql } from '@apollo/client';

const getProductEdit = gql`
  query GetProductEdit($resourceId: ID!) {
    productEdit {
      getProduct(resourceId: $resourceId) {
        actionValue
        active
        activeFromDate
        activeUntilDate
        allowDownload
        allowFreeQuantity
        articleNo
        customMetadata {
          display
          label
          value
        }
        bookings {
          allowFreeBooking
          bookingDays
        }
        buyPrices {
          initialCosts
          manualPrices {
            price
            quantity
          }
        }
        description
        detailedDescription
        extraFieldColors
        extraFieldNotes
        files {
          displayFileName
          originalFileName
          thumbnailFileName
        }
        format
        imageMetadata {
          contact
          date
          imageNo
          isFreeToUser
          legalDocument
          location
          model
          occupation
          photographer
        }
        material
        name
        orderingType
        pageRange
        pricesInclusiveFreight
        resourceId
        sellPrices {
          initialCosts
          manualPrices {
            price
            quantity
          }
        }
        showFormat
        showPrice
        siteSuppliers {
          displayName
          selected
          notifyEmail
          displayName
          supplierId
          inactive
        }
        tags {
          tagId
          tagName
        }
        thumbnailImageUri
        unit
        vateRates {
          name
          selected
          vatRateId
        }
        weight
      }
    }
  }
`;

export default getProductEdit;

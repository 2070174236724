import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Grid, CircularProgress } from "@mui/material";
import { useAlert } from "react-alert";
import SplitterLayout from "react-splitter-layout";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { isEmpty } from "lodash";
import {
  GET_ADD_FILES_TO_CART_PRODUCTION_SETTINGS_FOR_SITE,
  GET_FOLDERS,
} from "../../../graphql/queries";
import {
  getSelectedSiteOrderGroupId,
  getSiteId,
  getSiteOptions,
} from "../../../helpers/selectors";
import { SELECT_FIELD_MANDATORY } from "../../../constant/types";
import getFolderFromSiteFoldersByFolderId from "../../../helpers/getFolderFromSiteFoldersByFolderId";
import { convertArrayToObject } from "../../../helpers/converters";
import { getSelectedChildFolders } from "../../../helpers/monitoring";
import { UPDATE_FILE_GROUPS } from "../../../graphql/mutations";
import { getCart } from "../../../graphql/queries/getCart";
import { cartInitialize } from "../../../actions";
import ConfirmationModalRemoveSettings from "../../common/ConfirmationModalRemoveSettings";
import NoProductionSettingsModal from "../../common/NoProductionSettingsModal";
import FolderFileSection from "./FolderFileSection";
import ModalActions from "./ModalActions";
import FolderTreeSection from "./FolderTreeSection";
import "../../../../node_modules/react-splitter-layout/lib/index.css";
import {
  useGetSiteFolders,
  useGetResources,
  useSnackbar,
} from "../../../hooks";
import WorkspaceSelector from "../../common/WorkspaceSelector";
import { INIT_SHOPPING_BASKET_FILE_UPLOAD } from "../../../graphql/mutations";
import { commonErrors } from "../../../constant/errorCodes";

const { VALIDATION_ERROR } = commonErrors;

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    "& .splitter-layout": {
      width: "97%",
      [theme.breakpoints.down("sm")]: {
        width: "89%",
      },
    },
    "& .splitter-layout .layout-pane": {
      overflowX: "hidden !important",
      paddingTop: 5,
    },
    position: "relative",
  },
  loadingIcon: {
    color: "#fff",
  },
  loadingOverlay: {
    background: "#000",
    opacity: 0.5,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  modalBody: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "hidden",
    flex: 1,
  },
  cardBody: {
    padding: 10,
    minHeight: "87%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  folderTitle: {
    color: theme.palette.text.gray,
    fontSize: 12,
    paddingBottom: 5,
  },
  dragbar: {
    "& .layout-splitter": {
      position: "relative !important",
      height: "4px !important",
      "&::after": {
        content: '" "',
        position: "absolute !important",
        left: 0,
        right: 0,
        top: -3,
        marginLeft: "auto",
        marginRight: "auto",
        width: 40,
        height: 8,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        border: "1px solid #dedede",
      },
    },
  },
  footer: {
    padding: "0px 20px 0px 20px",
    boxShadow: "2px 0 15px 0 rgba(0,0,0,0.1)",
    marginTop: "auto",
  },
  customSelect: {
    margin: "10px 0",
  },
  siteSelectWrap: {
    marginBottom: 5,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.background.lightGray,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },
}));

const ModalBody = ({
  folder,
  handleClose,
  initialFilesAddToCart,
  initialFilesPackageGroup,
  initialFolderPackageGroup,
  initialFolderId,
  initialPackageGroup,
  openedNodes,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const [getFolders, { data }] = useLazyQuery(GET_FOLDERS);
  const [initShoppingBasketFileUpload] = useMutation(
    INIT_SHOPPING_BASKET_FILE_UPLOAD
  );

  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );

  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));
  const siteOptions = useSelector(state => getSiteOptions(state));
  const initialSiteId = useSelector(state => getSiteId(state));
  const initialWorkspaceName = useSelector(
    state => state.ui.toggleWorkspaces.workspace.name
  );

  const [siteId, setSiteId] = useState(initialSiteId);
  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName);
  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const [updateFileGroups] = useMutation(UPDATE_FILE_GROUPS);

  const initialFolders = useSelector(state => state.api.siteFolders.folders);
  const [foldersData, setFoldersData] = useState(initialFolders);
  const [checkedFolders, setCheckedFolders] = useState([]);
  const [includeSubfolders, setIncludeSubfolders] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(folder);
  const { folderId: selectedFolderId } = selectedFolder || {};
  const [selectedFolders, setSelectedFolders] = useState(
    !isEmpty(folder) ? [folder] : []
  );
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [noProductionSettingsModal, setNoProductionSettingsModal] = useState(
    false
  );
  const [folderIdsUnchecked, setFolderIdsUnchecked] = useState([]);
  const [folderProductionProperties, setFolderProductionProperties] = useState(
    []
  );
  // const [subFoldersWithDiffSettings, setSubFoldersWithDiffSettings] = useState(
  //   []
  // );
  const [propertyFormValues, setPropertyFormValues] = useState([]);
  const [productionSettings, setProductionSettings] = useState({});
  const [foldersProductionSettings, setFoldersProductionSettings] = useState();

  const [folderPropertyFormValues, setFolderPropertyFormValues] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedFoldersProperties, setSelectedFoldersProperties] = useState(
    !isEmpty(folder) ? [folder.folderId] : []
  );

  const [resources, setResources] = useState([]);
  const [refreshDropzone, setRefreshDropzone] = useState(false);

  const [productionSettingErrors, setProductionSettingErrors] = useState({});
  const [folderMandatoryFields, setFolderMandatoryFields] = useState({});

  const showProductionProperties = true;

  const selectedWorkspace = useSelector(
    state => state.ui.toggleWorkspaces.workspace
  );

  const { execute: getSiteFolders } = useGetSiteFolders();
  const { resources: fetchedResources, loading, error } = useGetResources({
    folderId: selectedFolderId,
  });

  const handleGetSiteFolders = () => {
    getSiteFolders({
      variables: {
        siteId: selectedWorkspace.id,
      },
    });
  };

  useEffect(() => {
    handleGetSiteFolders();
  }, []);

  useEffect(() => {
    if (initialPackageGroup) {
      let tempUploadedItems = [];
      let uploadedItems = [];

      const { items } = initialPackageGroup;

      tempUploadedItems = items.filter(
        ({ productId: id1 }) =>
          !fetchedResources.some(({ id: id2 }) => id2 === id1)
      );

      uploadedItems = tempUploadedItems.map(x => {
        const fileExtension = x.name.split(".").pop();

        const newItem = { name: x.name, id: x.productId, fileExtension };
        return newItem;
      });
      if (uploadedItems.length === 0) {
        setResources(fetchedResources);
        return;
      }
      setResources([...fetchedResources, ...uploadedItems]);
    } else {
      setResources(fetchedResources);
    }
  }, [fetchedResources, initialPackageGroup]);

  useEffect(() => {
    if (
      foldersData &&
      Array.isArray(foldersData) &&
      foldersData.length > 0 &&
      initialFolderId
    ) {
      const initialFolder = getFolderFromSiteFoldersByFolderId({
        folders: foldersData,
        initialFolderId,
      });
      setSelectedFolder(initialFolder);
    }
  }, [foldersData, initialFolderId]);

  useEffect(() => {
    if (
      initialFilesPackageGroup &&
      Array.isArray(initialFilesPackageGroup) &&
      initialFilesPackageGroup.length > 0
    ) {
      setPropertyFormValues(initialFilesPackageGroup);
    }
  }, [initialFilesPackageGroup]);

  useEffect(() => {
    if (
      initialFolderPackageGroup &&
      Array.isArray(initialFolderPackageGroup) &&
      initialFolderPackageGroup.length > 0
    ) {
      setFolderPropertyFormValues(initialFolderPackageGroup);
    }
  }, [initialFolderPackageGroup]);

  useEffect(() => {
    if (
      initialFilesAddToCart &&
      Array.isArray(initialFilesAddToCart) &&
      initialFilesAddToCart.length > 0
    ) {
      const initialFilesWithQuantity = [];
      let initialSelectedResources = [];

      initialFilesAddToCart.forEach(x => {
        const newFile = {
          resourceId: x.id,
          folderId: folder.folderId,
          productionSettings: { orderQuantity: 1, quantityFixedValueId: null },
        };
        initialSelectedResources = [...initialSelectedResources, x.id];
        initialFilesWithQuantity.push(newFile);
      });
      setSelectedResources(initialSelectedResources);
      setPropertyFormValues(initialFilesWithQuantity);
    }
  }, [folder, initialFilesAddToCart]);

  const [
    getProductionSettingsForSite,
    { loading: loadingProductionSettings },
  ] = useLazyQuery(GET_ADD_FILES_TO_CART_PRODUCTION_SETTINGS_FOR_SITE, {
    onCompleted: data => {
      const {
        getProductionSettingsForSite: settingsTempArray,
      } = data.addFilesToCart;
      const settingsTempArrayObj = convertArrayToObject(
        settingsTempArray,
        "folderId"
      );

      setFoldersProductionSettings(settingsTempArrayObj);
    },
    onError: () => {
      setFoldersProductionSettings();
    },
  });

  useEffect(() => {
    if (siteId) {
      getProductionSettingsForSite({ variables: { siteId } });
      getFolders({ variables: { siteId } });
    }
  }, [siteId]);

  useEffect(() => {
    if (loadingProductionSettings) {
      setProductionSettings({});
    }
  }, [loadingProductionSettings]);

  const [
    getCartQuery,
    { data: shoppingCartData, loading: getCartQueryLoading },
  ] = useLazyQuery(getCart, {
    onCompleted: () => {
      handleClose();
    },
  });

  useEffect(() => {
    if (shoppingCartData && shoppingCartData.shoppingCartContent) {
      const { groups = [] } = shoppingCartData.shoppingCartContent;
      const orderGroup = groups.find(x => x.orderGroupId === orderGroupId);
      const { items = [] } = orderGroup || {};
      dispatch(
        cartInitialize({
          items,
        })
      );
    }
  }, [shoppingCartData]);

  // eslint-disable-next-line no-shadow
  const handleSetSelectedFolders = (folder, foldersWithDiffSettings) => {
    const allSubfolders = getSelectedChildFolders({
      folder,
      folderArrays: [],
      foldersWithDiffSettings,
    });
    setSelectedFolders(allSubfolders);
  };

  // useEffect(() => {
  //   if (includeSubfolders) {
  //     handleSetSelectedFolders(selectedFolder, subFoldersWithDiffSettings);
  //   } else {
  //     setSelectedFolders(selectedFolder ? [selectedFolder] : []);
  //   }
  // }, [includeSubfolders, selectedFolder, subFoldersWithDiffSettings]);

  useEffect(() => {
    if (selectedFolder && selectedFolderId && foldersProductionSettings) {
      const productionSettingsTemp =
        foldersProductionSettings[selectedFolderId];
      if (
        productionSettingsTemp &&
        productionSettingsTemp.folderProductSettings
      ) {
        const {
          productionProperties,
        } = productionSettingsTemp.folderProductSettings;
        setProductionSettings(productionSettingsTemp.folderProductSettings);
        let mandatoryFields = {};
        productionProperties
          .filter(property => property.type === SELECT_FIELD_MANDATORY)
          .forEach(x => {
            mandatoryFields = { ...mandatoryFields, [x.name]: "" };
          });
        const newValue = { [selectedFolderId]: mandatoryFields };

        setProductionSettingErrors({ ...productionSettingErrors, ...newValue });
        setFolderMandatoryFields(mandatoryFields);
      } else {
        setProductionSettings(null);
      }
    }
  }, [selectedFolder, showProductionProperties, foldersProductionSettings]);

  useEffect(() => {
    setSelectedFolders(folder ? [folder] : []);
  }, [folder]);

  const handleError = errorResult => {
    const { errorCode, errorMessage } = errorResult;

    if (errorCode === VALIDATION_ERROR) {
      snackbar.workspaceError(
        <FormattedMessage id="common.requiredNotEmpty" />
      );
      console.error("error >", errorMessage);
      return;
    }
  };

  const handleBtnOrder = async () => {
    let packages = [];

    // new code
    if (
      Array.isArray(folderPropertyFormValues) &&
      folderPropertyFormValues.length > 0
    ) {
      folderPropertyFormValues.forEach(folderProperty => {
        const { isIncluded, resourcesId, productionSettings, name, folderId } =
          folderProperty || {};
        const { propertyDataValues = [], orderQuantity } =
          productionSettings || {};

        const propertyProductData = propertyDataValues.map(x => {
          const tempDataValue = {
            propertyName: x.propertyName,
            propertyValueId: x.propertyValue.id,
            propertyValue: x.propertyValue.name,
            quantity: orderQuantity,
          };

          return tempDataValue;
        });

        let folderResources = [];
        let resourcesWithProperty = [];

        let items = [];

        resourcesId.forEach(id => {
          const found = propertyFormValues.find(
            property => property.resourceId === id
          );

          if ((found && found.isIncluded) === true) {
            resourcesWithProperty = [...resourcesWithProperty, found];
          } else if (found) {
            //skip this item
          } else {
            folderResources = [...folderResources, id];
          }
        });

        const folderItems = folderResources.map(x => {
          const tempItem = {
            productId: x,
            quantity: orderQuantity,
            source: "ADD_TO_BASKET",
            siteId,
            propertyProductData: null,
          };

          return tempItem;
        });

        const resourceItems = resourcesWithProperty.map(property => {
          const { productionSettings, resourceId } = property || {};
          const { propertyDataValues = [], orderQuantity } =
            productionSettings || {};

          const newPropertyProductData =
            propertyDataValues.map(x => ({
              propertyValueId: x.propertyValue.id,
              quantity: orderQuantity,
              propertyName: x.propertyName,
              propertyValue: x.propertyValue.name,
            })) || [];

          const tempItem = {
            productId: resourceId,
            quantity: orderQuantity,
            source: "ADD_TO_BASKET",
            siteId,
            propertyProductData: newPropertyProductData,
          };

          return tempItem;
        });

        items = [...folderItems, ...resourceItems];

        const newPackage = {
          name,
          items,
          includeFolder: true,
          folderId,
          propertyProductData,
          quantity: orderQuantity,
        };

        packages = [...packages, newPackage];
      });
    } else {
      let folders = [];

      propertyFormValues.forEach(x => {
        const found = folders.find(y => y.folderId === x.folderId);
        if (!found) {
          const propertyFolder = { name: x.folderName, folderId: x.folderId };
          folders = [...folders, propertyFolder];
        }
      });

      folders.forEach(propertyFolder => {
        const folderResources = propertyFormValues.filter(
          x => x.folderId === propertyFolder.folderId
        );

        let items = [];

        const newFolderResources = folderResources.map(resource => {
          const { productionSettings } = resource;
          const { orderQuantity = 1, propertyDataValues = [] } =
            productionSettings || {};

          const newPropertyProductData =
            propertyDataValues.map(x => ({
              propertyValueId: x.propertyValue.id,
              quantity: orderQuantity,
              propertyName: x.propertyName,
              propertyValue: x.propertyValue.name,
            })) || [];

          const newResources = {
            productId: resource.resourceId,
            quantity: orderQuantity,
            siteId,
            source: "ADD_TO_BASKET",
            propertyProductData: newPropertyProductData,
          };
          return newResources;
        });

        items = newFolderResources;

        const newPackage = {
          name: propertyFolder.name,
          items,
          includeFolder: false,
          folderId: propertyFolder.folderId,
          propertyProductData: null,
          quantity: 0,
        };

        packages = [...packages, newPackage];
      });
    }

    let deletedPackages = [];

    if (initialPackageGroup) {
      const { packageId } = initialPackageGroup;
      deletedPackages = [packageId];
    }

    const input = {
      newPackages: packages,
      deletedPackages,
      orderGroupId,
    };

    try {
      const res = await updateFileGroups({ variables: { input } });
      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.updateFileGroups
      ) {
        const {
          success,
          shoppingCart = {},
        } = res.data.shoppingCartContext.updateFileGroups;

        if (success) {
          const { groups = [] } = shoppingCart;
          const orderGroup = groups.find(x => x.orderGroupId === orderGroupId);
          const { items = [], fileGroups = [], oneStreamGroups = [] } =
            orderGroup || {};
          dispatch(
            cartInitialize({
              items,
              fileGroups,
              oneStreamGroups,
            })
          );
          alert.success(<FormattedMessage id="product.addedToCart" />);
          handleClose();
        } else {
          const { errorResult } = res.data.shoppingCartContext.updateFileGroups;
          handleError(errorResult);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  useEffect(() => {
    if (data && data.folders) {
      setFoldersData(data.folders);
    }
  }, [data]);

  const handleSiteChange = site => {
    setSiteId(site.siteId);
    setWorkspaceName(site.name);
    if (site.siteId !== siteId) {
      setSelectedFolders([]);
    }
    setWorkspaceSelector(false);
  };

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  const getFileUploadURL = async () => {
    try {
      const results = await initShoppingBasketFileUpload({
        variables: {
          orderGroupId,
        },
      });
      return results;
    } catch (error) {
      return null;
    }
  };

  const uploadFiles = async ({ files }) => {
    try {
      let uploadedFiles = [];
      const promises = await files.map(async file => {
        const formData = new FormData();
        formData.append("file", file);

        const urlData = await getFileUploadURL();

        if (
          urlData &&
          urlData.data &&
          urlData.data.initShoppingBasketFileUpload
        ) {
          const { initShoppingBasketFileUpload: uploadURL } = urlData.data;
          return axios.post(`${uploadURL}`, formData).then(response => {
            if (response && response.data && response.data.id) {
              const fileExtension = files[0].name.split(".").pop();
              const tempResource = {
                name: files[0].name,
                fileExtension,
                id: response.data.id,
              };
              uploadedFiles = [...uploadedFiles, tempResource];
            }
            setRefreshDropzone(true);
          });
        }
        setRefreshDropzone(true);
        // If failure to get upload url.
        console.error(`Error in uploading file. Unable to get upload`);
      });
      axios
        .all(promises)
        .then(() => {
          if (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
            setResources(prevState => [...prevState, ...uploadedFiles]);
            alert.success(<FormattedMessage id="fileupload.success" />);
          } else {
            snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
          }
        })
        .catch(err => {
          console.error(
            `Error in uploading file. Response Fail. ${JSON.stringify(err)}`
          );
          setRefreshDropzone(true);
          snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
        });
    } catch (error) {
      setRefreshDropzone(true);
      console.error(`Error in uploading file. Unable to send ${error}`);
    }
  };

  useEffect(() => {
    setRefreshDropzone(false);
  }, [refreshDropzone]);

  return (
    <Box className={classes.modalBody}>
      <Box className={classes.cardBody}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <WorkspaceSelector
              workspaces={workspaces}
              open={workspaceSelector}
              searchable
              setOpen={setWorkspaceSelector}
              handleToggle={handleToggle}
              onClick={handleSiteChange}
              workspaceName={workspaceName}
            />
          </Grid>
        </Grid>
        <Box mb={1} />
        <Box className={classes.root}>
          {loadingProductionSettings && (
            <Box className={classes.loadingOverlay}>
              <CircularProgress size={60} className={classes.loadingIcon} />
            </Box>
          )}
          <SplitterLayout
            vertical
            percentage
            primaryIndex={0}
            secondaryInitialSize={62}
            customClassName={classes.dragbar}
          >
            <FolderTreeSection
              checkedFolders={checkedFolders}
              foldersData={foldersData}
              includeSubfolders={includeSubfolders}
              selectedFolder={selectedFolder}
              selectedFolders={selectedFolders}
              setConfirmationModal={setConfirmationModal}
              setFolderIdsUnchecked={setFolderIdsUnchecked}
              setIncludeSubfolders={setIncludeSubfolders}
              setSelectedFolder={setSelectedFolder}
              openedNodes={openedNodes}
              folderPropertyFormValues={folderPropertyFormValues}
              setFolderPropertyFormValues={setFolderPropertyFormValues}
              propertyFormValues={propertyFormValues}
              setPropertyFormValues={setPropertyFormValues}
              setCheckedFolders={setCheckedFolders}
            />
            <FolderFileSection
              checkedFolders={checkedFolders}
              folder={selectedFolder}
              folderId={selectedFolderId}
              productionSettingErrors={productionSettingErrors}
              setProductionSettingErrors={setProductionSettingErrors}
              folderProductionProperties={folderProductionProperties}
              productionSettings={productionSettings}
              propertyFormValues={propertyFormValues}
              selectedFoldersProperties={selectedFoldersProperties}
              selectedResources={selectedResources}
              setCheckedFolders={setCheckedFolders}
              setFolderPropertyFormValues={setFolderPropertyFormValues}
              setPropertyFormValues={setPropertyFormValues}
              setSelectedFoldersProperties={setSelectedFoldersProperties}
              setSelectedResources={setSelectedResources}
              folderPropertyFormValues={folderPropertyFormValues}
              selectedFolders={selectedFolders}
              resources={resources}
              folderMandatoryFields={folderMandatoryFields}
            />
          </SplitterLayout>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <ModalActions
          productionSettingErrors={productionSettingErrors}
          handleBtnOrder={handleBtnOrder}
          loading={false || getCartQueryLoading}
          propertyFormValues={propertyFormValues}
          folderPropertyFormValues={folderPropertyFormValues}
          uploadFiles={uploadFiles}
          refreshDropzone={refreshDropzone}
        />
      </Box>
      {confirmationModal && (
        <ConfirmationModalRemoveSettings
          setConfirmationModal={setConfirmationModal}
          open={confirmationModal}
          setFolderIdsUnchecked={setFolderIdsUnchecked}
          folderIdsUnchecked={folderIdsUnchecked}
          setPropertyFormValues={setPropertyFormValues}
          setCheckedFolders={setCheckedFolders}
          checkedFolders={checkedFolders}
          propertyFormValues={propertyFormValues}
          setFolderProductionProperties={setFolderProductionProperties}
          folderProductionProperties={folderProductionProperties}
        />
      )}
      {noProductionSettingsModal && (
        <NoProductionSettingsModal
          open={noProductionSettingsModal}
          setOpen={setNoProductionSettingsModal}
        />
      )}
    </Box>
  );
};

ModalBody.defaultProps = {
  folder: null,
  openedNodes: [],
  initialPackageGroup: null,
  initialFolderId: null,
  initialFilesPackageGroup: null,
  initialFolderPackageGroup: null,
};

ModalBody.propTypes = {
  handleClose: PropTypes.func.isRequired,
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }),
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  initialFilesAddToCart: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialPackageGroup: PropTypes.shape({
    packageId: PropTypes.string,
  }),
  initialFolderId: PropTypes.number,
  initialFilesPackageGroup: PropTypes.arrayOf(PropTypes.shape({})),
  initialFolderPackageGroup: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ModalBody;

import { webApiURL } from "../services/config";

const getCompanyLogoUrl = (companyId, t = 0) => {
  if (typeof companyId !== "number") {
    return null;
  }

  if (t) {
    return `${webApiURL}/CompanyLogo?companyId=${companyId}&t=${t}`;
  } else {
    return `${webApiURL}/CompanyLogo?companyId=${companyId}`;
  }
};

export default getCompanyLogoUrl;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useIntl, FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Grid, Icon, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { getSortedDeliverySettings } from "../../../helpers/get";
import {
  DeliveryAddressHeader,
  InvoiceAddressHeader,
  PostalAddressHeader,
} from "../../common";
import FieldsForm from "./FieldsForm";
import CustomTextField from "../../common/TextField";
import ChangeAddressModal from "./ChangeAddressModal";

import ConfirmationModal from "../../common/ConfirmationModal";
import AddressHeaderTitle from "../../common/AddressHeaderTitle";


const POSTAL_EDIT = "POSTAL_EDIT";
const INVOICE_EDIT = "INVOICE_EDIT";
const DELIVERY_EDIT = "DELIVERY_EDIT";
const VISITING_EDIT = "VISITING_EDIT";

const addressFields = [
  { label: "Address 1", name: "address1" },
  { label: "Address 2", name: "address2" },
  { label: "Address 3", name: "address3" },
  { label: "Address 4", name: "address4" },
  { label: "Address 5", name: "address5" },
  { label: "Address 6", name: "address6" },
  { label: "City", name: "city" },
  { label: "Contact", name: "contact" },
  { label: "Country", name: "country" },
  { label: "Email", name: "email" },
  { label: "Reference", name: "reference" },
  { label: "Telephone", name: "telephone" },
  { label: "Zip", name: "zip" },
];

const useStyles = makeStyles(() => ({
  editIcon: {
    fontSize: 18,
    marginRight: 7,
    color: "#0000ff",
  },
}));

const AddressSection = ({
  accountSettings,
  setAccountSettings,
  handleSaveAccountSettings,
  setDraftAddress,
}) => {
  const intl = useIntl();  
  const classes = useStyles();
  
  const [sortedDeliveryAddress, setSortedDeliveryAddress] = useState([]);
  const [sortedPostalAddress, setSortedPostalAddress] = useState([]);
  const [sortedInvoiceAddress, setSortedInvoiceAddress] = useState([]);
  const [sortedVisitingAddress, setSortedVisitingAddress] = useState([]);

  const {
    allowEditAddresses,
    addressLabel,
    deliveryAddress,
    invoiceAddress,
    ordererAddress,
    visitingAddress,
  } = accountSettings || {};

  const [open, setOpen] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [addressEdit, setAddressEdit] = useState();



  useEffect(() => {
    const {
      deliveryAddressSettings,
      invoiceAddressSettings,
      postalAddressSettings,
      visitingAddressSettings,
    } = accountSettings || {};
    if (accountSettings) {
      const sortedDelivery = getSortedDeliverySettings(deliveryAddressSettings);

      const sortedPostal = getSortedDeliverySettings(postalAddressSettings);

      const sortedInvoice = getSortedDeliverySettings(invoiceAddressSettings);

      const sortedVisiting = getSortedDeliverySettings(visitingAddressSettings);

      setSortedDeliveryAddress(sortedDelivery);
      setSortedPostalAddress(sortedPostal);
      setSortedInvoiceAddress(sortedInvoice);
      setSortedVisitingAddress(sortedVisiting);
    }
  }, [accountSettings]);

  const handleChange = (address, e) => {
    const tempSettings = { ...accountSettings };
    const { name, value } = e.target;
    const newAdressValues = { ...tempSettings[address], [name]: value };
    tempSettings[address] = newAdressValues;
    setAccountSettings(tempSettings);
    setDraftAddress(true);
  };

  const handleAddressLabel = e => {
    const { name, value } = e.target;
    const tempSettings = { ...accountSettings, [name]: value };
    setAccountSettings(tempSettings);
    setDraftAddress(true);
    setDraftAddress(true);
  };

  // eslint-disable-next-line no-shadow
  const getAddressForm = ({ addressEdit }) => {
    if (addressEdit === POSTAL_EDIT) return ordererAddress;
    if (addressEdit === DELIVERY_EDIT) return deliveryAddress;
    if (addressEdit === INVOICE_EDIT) return invoiceAddress;
    if (addressEdit === VISITING_EDIT) return visitingAddress;
    return null;
  };

  // eslint-disable-next-line no-shadow
  const getModalTitle = ({ addressEdit }) => {
    let translationId = "";
    if (addressEdit === POSTAL_EDIT) translationId = "cart.addressPostal";
    if (addressEdit === DELIVERY_EDIT) translationId = "cart.addressDelivery";
    if (addressEdit === INVOICE_EDIT) translationId = "cart.addressInvoice";
    if (addressEdit === VISITING_EDIT) translationId = "cart.addressVisiting";

    const newText = intl.formatMessage({
      id: "common.new",
    });

    const label = intl.formatMessage({
      id: translationId,
    });

    return `${newText.toUpperCase()} ${label.toUpperCase()}`;
  };

  const handleSave = () => {
    if (!allowEditAddresses) {
      setWarningModal(true);
      setOpen(false);
    } else {
      handleSaveAccountSettings();
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} alignItems="center" justifyContent="flex-end">
        <Icon className={clsx(["fas fa-info-circle", classes.editIcon])} />
        <Typography>
          <FormattedMessage id="userGroups.emptyFields" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label={<FormattedMessage id="metadata.metaLabel" />}
          name="addressLabel"
          value={addressLabel}
          onChange={handleAddressLabel}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <DeliveryAddressHeader />
        {!isEmpty(deliveryAddress) && (
          <FieldsForm
            addressFields={sortedDeliveryAddress}
            addressForm={deliveryAddress}
            handleChange={handleChange}
            isEditable={allowEditAddresses}
            name="deliveryAddress"
          />
        )}
      </Grid>
      <Grid item xs={12} md={3}>
        <PostalAddressHeader />
        {!isEmpty(ordererAddress) && (
          <FieldsForm
            addressFields={sortedPostalAddress}
            addressForm={ordererAddress}
            handleChange={handleChange}
            isEditable={allowEditAddresses}
            name="ordererAddress"
          />
        )}
      </Grid>
      <Grid item xs={12} md={3}>
        <InvoiceAddressHeader />
        {!isEmpty(invoiceAddress) && (
          <FieldsForm
            addressFields={sortedInvoiceAddress}
            addressForm={invoiceAddress}
            handleChange={handleChange}
            isEditable={allowEditAddresses}
            name="invoiceAddress"
          />
        )}
      </Grid>

      <Grid item xs={12} md={3}>
        <AddressHeaderTitle>
          <FormattedMessage id="cart.addressVisiting" />
        </AddressHeaderTitle>

        <FieldsForm
          addressFields={sortedVisitingAddress}
          addressForm={visitingAddress}
          handleChange={handleChange}
          isEditable={allowEditAddresses}
          name="visitingAddress"
        />
      </Grid>
      {open && (
        <ChangeAddressModal
          open={open}
          setOpen={setOpen}
          addressFields={addressFields}
          addressForm={getAddressForm({ addressEdit })}
          modalTitle={getModalTitle({ addressEdit })}
          handleSaveAccountSettings={handleSave}
        />
      )}
      {warningModal && (
        <ConfirmationModal
          open={warningModal}
          message={<FormattedMessage id="settings.addressWarning" />}
          handleClose={() => setWarningModal(false)}
          variant="secondary"
        />
      )}
    </Grid>
  );
};

AddressSection.defaultProps = {
  accountSettings: {},
};

AddressSection.propTypes = {
  accountSettings: PropTypes.shape({
    addressLabel: PropTypes.string,
    deliveryAddress: PropTypes.string,
    invoiceAddress: PropTypes.string,
    ordererAddress: PropTypes.string,
    allowEditAddresses: PropTypes.bool,
  }),
  handleSaveAccountSettings: PropTypes.func.isRequired,
  setAccountSettings: PropTypes.func.isRequired,
  setDraftAddress: PropTypes.func.isRequired,
};

export default AddressSection;

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
} from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #f1f1f1",
  },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  iconButtonDownload: {
    padding: 4,
    color: theme.palette.common.link,
    marginLeft: 5,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
}));

const AddressesTable = () => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>Order</TableCell>
            <TableCell className={classes.th}>Product</TableCell>
            <TableCell className={classes.th}>From</TableCell>
            <TableCell className={classes.th}>To</TableCell>
            <TableCell className={classes.th}>Username</TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>Order 1234</TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} align="right">
              <IconButton className={classes.iconButtonEdit}>
                <Icon className={clsx(["fa fa-pencil-alt", classes.icon])} />
              </IconButton>
              <IconButton className={classes.iconButtonDownload}>
                <Icon className={clsx(["fa fa-download", classes.icon])} />
              </IconButton>
              <IconButton className={classes.iconButtonDelete}>
                <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default AddressesTable;

import React from "react";
import PropTypes from "prop-types";
import TermsOfUseLink from "./TermsOfUseLink";
import { TERMS_OF_USE_SECTION_ID } from "../../constant/termsOfUse";

const CookiesSectionLink = ({ children }) => {
  return (
    <TermsOfUseLink tabId={TERMS_OF_USE_SECTION_ID.Cookies}>
      {children}
    </TermsOfUseLink>
  );
};

CookiesSectionLink.defaultProps = {
  children: null,
};

CookiesSectionLink.propTypes = {
  children: PropTypes.node,
};

export default CookiesSectionLink;

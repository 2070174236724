import React, { useState, useEffect, useSelector } from "react";
import { useSubscription } from "@apollo/client";
import { GET_SITE_EVENT_UPDATE } from "../../graphql/subscription";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getCurrentUserId } from "../../helpers/selectors";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
    width: 90,
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}));

const SiteEventsWatcher = ({ currentUserId }) => {
  const [showModal, setShowModal] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const accessToken = localStorage.getItem("access_token");
  const { data } = useSubscription(GET_SITE_EVENT_UPDATE, {
    variables: { authToken: accessToken },
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const reloadPage = () => {
    history.push("/initialize");
  };

  useEffect(() => {
    if (data && data.siteEvents) {
      setShowModal(true);
    }
  }, [data]);

  return (
    <DraggableModal open={showModal} handleClose={handleClose}>
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody>
        <FormattedMessage id="siteEvent.siteChanged" />
        <Box align="center">
          <Button onClick={reloadPage} className={classes.btn}>
            <FormattedMessage id="siteEvent.reloadPage" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

const mapStateToProps = state => {
  return {
    currentUserId: getCurrentUserId(state),
  };
};

export default connect(mapStateToProps)(SiteEventsWatcher);

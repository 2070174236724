import PropTypes from "prop-types";
import { useEffect, useState, memo } from "react";
import { useAlert } from "react-alert";
import { contextMenu } from "react-contexify";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageEmpty from "../../../assets/img/empty-folder.svg";
import {
  INITIALIZE_FOLDER_DOWNLOAD,
  INITIALIZE_RESOURCE_DOWNLOAD,
  LOCK_RESOURCE,
  UNLOCK_RESOURCE,
} from "../../../graphql/mutations";
import { getSelectedFolder } from "../../../helpers/fileViewSelectors";
import { getFaClassType } from "../../../helpers/get";
import { useDeepLinkContext, useFileViewCopy } from "../../../hooks";
import FileViewSkeleton from "../../Skeleton/FileViewSkeleton";
import ContextMenu from "./ContextMenu";
import ProductsTableItem from "./ProductsTableItem";

const useStyles = makeStyles(theme => ({
  tdEmpty: {
    padding: "50px 0",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const ResourcesClasses = makeStyles(theme => ({
  hidden: {
    display: "none",
  },
  td: {
    fontSize: 11,
    padding: "10px 5px",
  },
  tdDesc: {
    fontSize: 11,
    padding: "10px 5px",
    width: "22%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tdCheckbox: {
    padding: "4px 5px 2px 10px",
  },
  tdImage: {
    padding: 10,
    paddingRight: 5,
  },
  tdName: {
    fontSize: 11,
    padding: "0px",
    paddingLeft: 0,
    minWidth: 100,
    maxWidth: 100,
    wordBreak: "break-all",
  },
  cursorContext: {
    // flex: 1,
    "&:hover": {
      // cursor: "context-menu"
      cursor: "pointer",
    },
  },
  tdType: {
    color: theme.palette.text.gray,
  },
  iconType: {
    fontSize: 16,
  },
  tdActions: {
    "& > div": {
      display: "inline-flex",
    },
    "&.expanded": {
      minWidth: 130,
      padding: "3px 5px 0 0",
    },
    minWidth: 44,
  },
  img: {
    maxWidth: 32,
    maxHeight: 32,
    objectFit: "cover",
    border: "1px solid #e8e8e8",
    boxShadow: "0 2px 5px #efefef",
    cursor: "pointer",
  },
  extText: {
    fontSize: 10,
  },
  popper: {
    width: 400,
    height: 400,
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.6)",
    width: "100%",
    maxWidth: "unset",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  selectedResource: {
    background: "#8ad",
  },
  hovered: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    background: "#8ad",
  },
  btnVersion: {
    fontSize: 12,
    padding: "2px 10px",
    width: "unset",
    minWidth: "unset",
    borderRadius: "50%",
  },
  cellContainer: {
    // display: "flex",
    // alignItems: "center",
    width: "fit-content",
  },
  typeCell: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: 2,
    },
  },
  icon: {
    fontSize: 18,
  },
}));

const contextMenuId = "fileviewresources_menu";

const ProductsTableBody = ({
  addSelectedResource,
  addSelectedResources,
  error,
  folderId,
  handleClickAddFilesToCart,
  handleError,
  handleOpenProductEditModal,
  isSearch,
  loading,
  onRequestConsent,
  refetchResources,
  removeSelectedResource,
  resources,
  selectedResources,
  setOpenMetaDataModal,
  siteRoleRights,
}) => {
  const alert = useAlert();
  const classes = useStyles();

  const resourcesClasses = ResourcesClasses();

  const deepLinkContext = useDeepLinkContext();
  const { deepLink } = deepLinkContext;

  const fileViewCopy = useFileViewCopy();

  const { locked: folderIsLocked, write: userHasWritePermission } = useSelector(getSelectedFolder);

  const [linkedResourceId, setLinkedResourceId] = useState(deepLink?.resourceId);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState({});
  const [deleteModal, setDeleteModal] = useState({});

  const {
    canAddToBasket: canAddToCart,
    canDownloadFilesAndFolders,
    canManageConsent: canRequestConsent,
    fileView: canCopyResource,
  } = siteRoleRights || {};

  const canUnlockForOthers =
    siteRoleRights && siteRoleRights.canUnlockForOthers
      ? siteRoleRights.canUnlockForOthers
      : false;

  const canLock =
    siteRoleRights && siteRoleRights.canLock ? siteRoleRights.canLock : false;

  const workspace = useSelector(state => state.ui.toggleWorkspaces.workspace);

  const siteId = workspace && workspace.id;

  const [initFolderDonwload] = useMutation(INITIALIZE_FOLDER_DOWNLOAD);
  const [lockResource] = useMutation(LOCK_RESOURCE);
  const [unlockResource] = useMutation(UNLOCK_RESOURCE);

  const lockSelectedResource = async (resourceId, name, username) => {
    try {
      const res = await lockResource({
        variables: { resourceId },
      });

      if (res && res.data && res.data.lockResource) {
        const { success } = res.data.lockResource;
        if (success) {
          alert.success(<FormattedMessage id="context.successLockResource" />);
          refetchResources();
        } else {
          const { errorResult } = res.data.lockResource;
          handleError(name, errorResult, username);
        }
      }
    } catch (err) {
      console.log("Error locking resource ", err);
    }
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const unlockSelectedResource = async (resourceId, name, username) => {
    try {
      const res = await unlockResource({
        variables: { resourceId },
      });

      if (res && res.data && res.data.unlockResource) {
        const { success } = res.data.unlockResource;

        if (success) {
          if (confirmationModal) {
            handleCloseConfirmationModal();
          }
          alert.success(
            <FormattedMessage id="context.successUnlockResource" />
          );
          refetchResources();
        } else {
          const { errorResult } = res.data.unlockResource;
          handleError(name, errorResult, username);
        }
      }
    } catch (err) {
      console.log("Error unlocking resource ", err);
    }
  };

  const handleLockResource = (
    id,
    name,
    locked,
    username,
    lockedByAnotherUser
  ) => {
    if (!locked) {
      lockSelectedResource(id, name, username);
    }

    if (locked) {
      if (lockedByAnotherUser) {
        if (canUnlockForOthers) {
          setConfirmationModal(true);
        } else {
          unlockSelectedResource(id, name, username);
        }
      } else {
        unlockSelectedResource(id, name, username);
      }
    }
  };

  const [initDownloadResource] = useMutation(INITIALIZE_RESOURCE_DOWNLOAD);

  const handleDownloadResource = async item => {
    // versionId set to hardcode since we cannot fetch versionId from the resource at the moment

    try {
      const res = await initDownloadResource({
        variables: { resourceId: item, versionId: 0 },
      });
      if (res && res.data && res.data.initResourceDownload) {
        const downloadUri = res.data.initResourceDownload;
        window.open(downloadUri);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading product ${e}`);
    }
  };

  const handleDownloadMultipleResources = async () => {
    const resourceIds = selectedResources.map(x => x.id);

    try {
      const res = await initFolderDonwload({
        variables: { siteId, folderId, resourceIds },
      });

      if (res && res.data && res.data.initFolderDownload) {
        const { success } = res.data.initFolderDownload;

        if (success) {
          const downloadUri = res.data.initFolderDownload.url;
          window.open(downloadUri);
        } else {
          const { errorResult } = res.data.initFolderDownload;
          handleError(errorResult);
        }
      }
    } catch (err) {
      console.log("Error downloading product ", err);
    }
  };

  const getCheckboxValue = () => {
    if (
      selectedResource &&
      selectedResource.id &&
      selectedResources &&
      Array.isArray(selectedResources)
    ) {
      const exist = selectedResources.find(
        x => x && x.id === selectedResource.id
      );
      if (exist) {
        return true;
      }
    }
    return false;
  };

  const onAddFileToCart = () => {
    const checked = getCheckboxValue();

    if (checked) {
      handleClickAddFilesToCart(selectedResources);
    } else {
      handleClickAddFilesToCart([selectedResource]);
    }
  };



  const handleDeleteModalClose = id => {
    setDeleteModal({ ...deleteModal, [id]: false });
  };

  const handleDeleteModalOpen = id => {
    setDeleteModal({ ...deleteModal, [id]: true });
  };

  const handleContextMenu = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    contextMenu.show({
      id: contextMenuId,
      event: e,
    });
    if (selectedResource.id !== item.id) {
      setSelectedResource(item);
    }
  };

  const haveResources = resources && Array.isArray(resources) && resources.length > 0;

  const EmptyResultTableRow = () => (
    <TableRow>
      <TableCell
        align="center"
        className={classes.tdEmpty}
        colSpan={11}
      >
        <img src={ImageEmpty} alt="Empty" />
        <Typography className={classes.emptyText}>
          <FormattedMessage id="product.noFilesInFolder" />
        </Typography>
      </TableCell>
    </TableRow>
  );

  const LoadingTableRow = () => (
    <TableRow>
      <TableCell className={classes.tdLoad} colSpan={11}>
        <FileViewSkeleton />
      </TableCell>
    </TableRow>
  );

  useEffect(() => {
    if (deepLink && haveResources) {
      deepLinkContext.clearData();
    }
  }, [deepLink, resources]);

  const handleProductModalClosed = () => {
    if (deepLink) {
      deepLinkContext.clearData();
    }
    setLinkedResourceId();
  };

  return (
    !deepLink &&
    <TableBody>
      {error && "There has been an error"}
      {loading && <LoadingTableRow />}
      {!loading && !haveResources && <EmptyResultTableRow />}
      {!loading && haveResources &&
        resources.map(x => {
          const typeClass = getFaClassType(x.fileExtension);

          const fileExtension = x.fileExtension.toLowerCase();

          const handleDragStart = (e, locked) => {
            if (locked) {
              e.preventDefault();
            } else {
              let resourceIdArray = [];

              if (selectedResources && selectedResources.length > 1) {
                const resourceids = selectedResources.map(x => {
                  return x.id;
                });

                resourceIdArray = resourceids;
              } else {
                resourceIdArray = [x.id];
              }
              e.dataTransfer.setData("files", JSON.stringify(resourceIdArray));
              e.dataTransfer.setData("action", "MOVE_RESOURCES");
            }
          };

          const handleDragEnd = () => {
            refetchResources();
          };

          return (
            <ProductsTableItem
              key={x.id}

              onProductModalClosed={handleProductModalClosed}
              showProductModalOnOpen={x.id == linkedResourceId}

              addSelectedResource={addSelectedResource}
              addSelectedResources={addSelectedResources}
              confirmationModal={confirmationModal}


              fileExtension={fileExtension}
              folderId={folderId}
              folderIsLocked={folderIsLocked}
              handleClickAddFilesToCart={handleClickAddFilesToCart}
              handleCloseConfirmationModal={handleCloseConfirmationModal}
              handleDragEnd={handleDragEnd}
              handleDragStart={handleDragStart}
              handleError={handleError}
              handleLockResource={handleLockResource}
              handleOpenProductEditModal={handleOpenProductEditModal}


              isSearchResultItem={false}
              item={x}


              refetchResources={refetchResources}
              removeSelectedResource={removeSelectedResource}
              selectedResources={selectedResources}
              setOpenMetaDataModal={setOpenMetaDataModal}
              siteRoleRights={siteRoleRights}

              type={typeClass}
              unlockSelectedResource={unlockSelectedResource}

              userHasWritePermission={userHasWritePermission}

              isSearch={isSearch}
              classes={resourcesClasses}
              handleDownloadMultipleResources={handleDownloadMultipleResources}
              deleteModal={deleteModal[x.id]}
              handleDeleteModalClose={handleDeleteModalClose}
              handleDeleteModalOpen={handleDeleteModalOpen}
              handleContextMenu={handleContextMenu}
            />
          );
        })
      }

      <ContextMenu
        canAddToCart={canAddToCart}
        canCopyResource={canCopyResource}
        canDownload={canDownloadFilesAndFolders}
        canEditResource={userHasWritePermission && !folderIsLocked}
        canRequestConsent={canRequestConsent}
        contextMenuId={contextMenuId}
        item={selectedResource}
        onAddFileToCart={onAddFileToCart}
        onCopyResources={(ids) => fileViewCopy.copyResources(ids)}
        onDeleteResource={(id) => handleDeleteModalOpen(id)}
        onDownloadMultipleResources={handleDownloadMultipleResources}
        onDownloadResource={handleDownloadResource}
        onEditMetadata={() => setOpenMetaDataModal(true)}
        onRequestConsent={onRequestConsent}
        selectedResources={selectedResources}
      />
    </TableBody>
  );
};

ProductsTableBody.defaultProps = {
  error: false,
  isSearch: null,
  loading: false,
};

ProductsTableBody.propTypes = {
  addSelectedResource: PropTypes.func.isRequired,
  addSelectedResources: PropTypes.func.isRequired,
  error: PropTypes.bool,
  folderId: PropTypes.number.isRequired,
  handleClickAddFilesToCart: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleOpenProductEditModal: PropTypes.func.isRequired,
  isSearch: PropTypes.bool,
  loading: PropTypes.bool,
  onRequestConsent: PropTypes.func.isRequired,
  refetchResources: PropTypes.func.isRequired,
  removeSelectedResource: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      articleNo: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thumbnailImageUri: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  siteRoleRights: PropTypes.shape({
    canLock: PropTypes.bool,
    canUnlockForOthers: PropTypes.bool,
    fileView: PropTypes.bool,
  }).isRequired,
};

export default memo(ProductsTableBody);

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { SketchPicker } from "react-color";
import { Box, ClickAwayListener, Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  swatch: {
    position: "relative",
    cursor: "pointer",
    padding: 3,
    height: 15,
    width: 15,
    border: "1px solid #bebebe",
    borderRadius: 3,
    display: "inline-block",
  },
  swatchColor: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: 3,
    background: props => props.backgroundColor,
  },
  colorPicker: {
    position: "absolute",
    right: -221,
    top: -129,
    zIndex: 20000,
  },
}));

const createCustomStyles = color => {
  return {
    backgroundColor: rgbColorOrDefault(color, color),
  };
};

const rgbColorOrDefault = (color, defaultColor) =>
  typeof color === "object" && "r" in color
    ? `rgb(${color.r || 0} ${color.g || 0} ${color.b || 0} / ${color.a || 1})`
    : defaultColor;

const ColorPicker = ({ value, onChange }) => {
  var customStyles = createCustomStyles(value);
  const classes = useStyles(customStyles);
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  const onClickAway = () => {
    setOpen(false);
    onChange(internalValue);
  }

  const changeColor = (e, b) => {
    setInternalValue(e);
  }

  const styles = {
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
  };

  /*
     
         <Box className={classes.root}>
      <Box onClick={() => setOpen(true)} className={classes.swatch}>
        <span className={classes.swatchColor} />
        {open && (
          <Box className={classes.colorPicker}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <SketchPicker color={value} onChange={onChange} />
            </ClickAwayListener>
          </Box>
        )}
      </Box>
    </Box>     
     */

  return (
    <div>
      <Box onClick={() => setOpen(true)} className={classes.swatch}>
        <span className={classes.swatchColor} />
      </Box>
      {open ? <div style={styles.popover}>
        <div style={styles.cover} onClick={onClickAway} />
        <SketchPicker color={internalValue} onChange={changeColor} />
      </div> : null}

    </div>


  );
};

export default ColorPicker;

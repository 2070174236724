import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box } from "@mui/material";
import { useProjectDnDContext } from "../../hooks/Projects";

const position = {
  position: "absolute",
  top: -1,
  left: 0,
  bottom: -1,
};

export const DRAG_HANDLE_WIDTH = "1.75em";

const DRAGGABLE_TASK_TYPE = "draggable-task";

const DragDropHandle = ({ disabled, isHovered, item, onDrop }) => {

  const ref = useRef(null);

  const { isDragging, toggleIsDragging, updateRowClasses } = useProjectDnDContext();

  const calcPos = (monitor) => {
    let pos;

    if (!!ref.current) {
      const { height, top } = ref.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset()

      const hoverClientY = clientOffset.y - top;

      const topLimit = 0.25 * height;
      const bottomLimit = 0.75 * height;

      if (0 <= hoverClientY && hoverClientY <= topLimit) {
        pos = "before";
      }
      else if (topLimit < hoverClientY && hoverClientY < bottomLimit) {
        pos = "subtask";
      }
      else if (bottomLimit <= hoverClientY && hoverClientY <= height) {
        pos = "after";
      }
    }

    return pos;
  };

  const [collected, drag, dragPreview] = useDrag(
    () => ({
      type: DRAGGABLE_TASK_TYPE,

      canDrag: (monitor) => !disabled,

      collect: (monitor, props) => ({
        isDragging: monitor.isDragging(),
      }),

      item: () => {
        toggleIsDragging(true);
        return item;
      },

      end: (draggedItem, monitor) => {
        toggleIsDragging(false);
        updateRowClasses();
      },
    }), [disabled]
  );

  const [, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_TASK_TYPE,

      canDrop: (draggedItem, monitor) => {
        return true; // TODO
      },

      drop: (draggedItem, monitor) => {
        const pos = calcPos(monitor);
        if (pos) {
          onDrop({
            pos,
            source: draggedItem,
            target: item,
          });
        }
      },

      hover: (draggedItem, monitor) => {
        if (monitor.canDrop()) {
          const pos = calcPos(monitor);
          if (pos) {
            updateRowClasses(ref, pos);
          }
        }
      }
    }), [onDrop]
  );

  drag(drop(ref));

  //if (collected.isDragging) {
  //  return (
  //    <div ref={dragPreview} /*style={{background: "pink", padding: "1em"}}*/ />
  //  );
  //}

  return (
    <Box
      ref={ref}
      sx={{
        ...position,
        //border: collected.isDragging ? "1px solid red" : "",
        width: isDragging ? "100%" : DRAG_HANDLE_WIDTH,
      }}
    >
      <DragIndicatorIcon
        onClick={(e) => e.stopPropagation()}
        sx={{
          ...position,
          cursor: !disabled && "grab",
          margin: "auto",
          opacity: isHovered && !disabled ? 0.5 : 0.1,
        }}
      />
    </Box>
  );
};

export default DragDropHandle;

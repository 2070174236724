import { gql } from '@apollo/client';
import FolderFieldsFragment from "../fragments/FolderFields";
import FolderRecursiveFragment from "../fragments/FolderRecursive";
import ErrorResultFragment from "../fragments/ErrorResult";

const CREATE_FOLDER = gql`
  mutation CreateFolder(
    $folderName: String
    $siteId: Int!
    $parentFolderId: Int
  ) {
    createFolder(
      folderName: $folderName
      siteId: $siteId
      parentFolderId: $parentFolderId
    ) {
      folder {
        ...FolderFields
        ...FolderRecursive
      }
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${FolderFieldsFragment}
  ${FolderRecursiveFragment}
  ${ErrorResultFragment}
`;

export default CREATE_FOLDER;

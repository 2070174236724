import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import {
  DOCUMENT_VIEW,
  IMAGE_VIEW,
  PRODUCT_LIST_VIEW,
  FILE_VIEW,
} from "../../constant/types";

const useStyles = makeStyles(theme => ({
  pageView: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  viewIcon: {
    display: "inline",
    fontSize: 12,
    backgroundColor: theme.palette.component.viewSwitchBackground,
    padding: "7px 12px",
    cursor: "pointer",
    color: "#4c4c4c",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  activeView: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  caret: {
    width: "13px !important",
    height: "12px !important",
  },
  showHide: {
    background: "#ebf1e0",
    borderRight: "1px solid #cbd0c2",
  },
  showHideIcon: {
    display: "inline",
    fontSize: 12,
    backgroundColor: theme.palette.component.viewSwitchBackground,
    padding: "7px 6px 7px 12px",
    cursor: "pointer",
    color: "#4c4c4c",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const PageViewIcons = ({
  detailedSearchOpen,
  setView,
  showDetailedImageView,
  showFileView,
  showImageView,
  showProductView,
  toggleFieldsModal,
  view,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.pageView}>
      {view === FILE_VIEW && (
        <Tooltip
          title={<FormattedMessage id="btn.showCol" />}
          placement="bottom"
          classes={{ popper: classes.popper }}
        >
          <Box
            className={[classes.showHideIcon, classes.showHide]}
            onClick={() => toggleFieldsModal()}
          >
            <i className="fa fa-columns" />
            <ArrowDropDown className={classes.caret} />
          </Box>
        </Tooltip>
      )}

      {showImageView && !detailedSearchOpen && (
        <Tooltip
          title={<FormattedMessage id="view.thumbnail" />}
          placement="bottom"
          classes={{ popper: classes.popper }}
        >
          <Box
            className={[
              classes.viewIcon,
              view === IMAGE_VIEW ? classes.activeView : "",
            ].join(" ")}
            onClick={() => setView(IMAGE_VIEW)}
          >
            <i className="fa fa-th" />
          </Box>
        </Tooltip>
      )}

      {showDetailedImageView && !detailedSearchOpen && (
        <Tooltip
          title={<FormattedMessage id="view.image" />}
          placement="bottom"
          classes={{ popper: classes.popper }}
        >
          <Box
            className={[
              classes.viewIcon,
              view === DOCUMENT_VIEW ? classes.activeView : "",
            ].join(" ")}
            onClick={() => setView(DOCUMENT_VIEW)}
          >
            <i className="fa fa-th-large" />
          </Box>
        </Tooltip>
      )}

      {showProductView || detailedSearchOpen ? (
        <Tooltip
          title={<FormattedMessage id="view.product" />}
          placement="bottom"
          classes={{ popper: classes.popper }}
        >
          <Box
            className={[
              classes.viewIcon,
              view === PRODUCT_LIST_VIEW ? classes.activeView : "",
            ].join(" ")}
            onClick={() => setView(PRODUCT_LIST_VIEW)}
          >
            <i className="fa fa-th-list" />
          </Box>
        </Tooltip>
      ) : null}
      {showFileView || detailedSearchOpen ? (
        <Tooltip
          title={<FormattedMessage id="view.file" />}
          placement="bottom"
          classes={{ popper: classes.popper }}
        >
          <Box
            className={[
              classes.viewIcon,
              view === FILE_VIEW ? classes.activeView : "",
            ].join(" ")}
            onClick={() => setView(FILE_VIEW)}
          >
            <i className="fa fa-align-justify" />
          </Box>
        </Tooltip>
      ) : null}
    </div>
  );
};

PageViewIcons.defaultProps = {
  detailedSearchOpen: null,
};

PageViewIcons.propTypes = {
  detailedSearchOpen: PropTypes.bool,
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  showFileView: PropTypes.bool.isRequired,
  showImageView: PropTypes.bool.isRequired,
  showDetailedImageView: PropTypes.bool.isRequired,
  showProductView: PropTypes.bool.isRequired,
  toggleFieldsModal: PropTypes.func.isRequired,
};

export default PageViewIcons;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { isBefore, isWithinInterval  } from "date-fns";
import moment from "moment";
import { Box, Grid } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { GET_UNAVAILABLE_DAYS } from "../../../graphql/queries/BookingContext";

import DateRangePicker from "./DateRangePicker";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #c4c4c4",
    borderRadius: "4px",
    padding: "9px 9px 9px 9px",
    [theme.breakpoints.down("md")]: {
      padding: "12px 9px 12px 9px",
    },
  },
  dateWrapper: {
    fontSize: 12,
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      fontWeight: 500,
    },
    [theme.breakpoints.down("md")]: {
      fontWeight: 600,
      fontSize: 18,
    },
  },
  timeWrapper: {
    fontSize: 12,
    fontWeight: 400,
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      fontWeight: 500,
      fontSize: 16,
    },
  },
}));

const ResponsiveDateRange = ({
  mobile,
  pickUpDate,
  returnDate,
  setPickUpDate,
  setReturnDate,
  pickUpTime,
  setPickUpTime,
  returnTime,
  setReturnTime,
  weekends,
  enteredTo,
  setEnteredTo,
  lift,
  pickUpTimeSetting,
  returnTimeSetting,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [getUnavailableDays, { data: unavailableDaysData }] = useLazyQuery(
    GET_UNAVAILABLE_DAYS
  );
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [partiallyUnavailableDays, setPartiallyUnavailableDays] = useState([]);
  const [totallyUnavailableDays, setTotallyUnavailableDays] = useState([]);
  const [unavailableDays, setUnavailableDays] = useState([]);

  const [
    inRangeTotallyUnvailableDays,
    setInRangeTotallyUnvailableDays,
  ] = useState([]);
  const [
    inRangePartialUnavailableDays,
    setInRangePartialUnavaialbleDays,
  ] = useState([]);

  useEffect(() => {
    if (
      unavailableDaysData &&
      unavailableDaysData.bookingContext &&
      unavailableDaysData.bookingContext.getUnavailableDays
    ) {
      const {
        partiallyUnavailableDays: partialUnavailDays,
        totallyUnavailableDays: totallyUnavailDays,
        unavailableDays: unavailable,
      } = unavailableDaysData.bookingContext.getUnavailableDays;

      const partial = partialUnavailDays.map(x => new Date(x));
      const totally = totallyUnavailDays.map(x => new Date(x));
      setUnavailableDays(unavailable);
      setPartiallyUnavailableDays(partial);
      setTotallyUnavailableDays(totally);
    }
  }, [unavailableDaysData]);

  useEffect(() => {
    const firstDayOfInitialMonth = moment(currentMonth)
      .clone()
      .startOf("month");

    const lastDayOfFinalMonth = moment(currentMonth)
      .add(4, "M")
      .clone()
      .endOf("month");

    getUnavailableDays({
      variables: {
        productGroupfolderId: lift,
        startOfPeriod: firstDayOfInitialMonth,
        endOfPeriod: lastDayOfFinalMonth,
      },
    });
  }, [lift, currentMonth]);

  const handleMonthChange = e => {
    setCurrentMonth(e);
  };

  const isSelectingFirstDay = (from, to, day) => {
      const isBeforeFirstDay = from && isBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayMouseEnter = day => {
    if (isSelectingFirstDay(pickUpDate, returnDate, day)) {
      setEnteredTo(day);
    }
  };

  const handleOnDayClick = (day, mod) => {
    const { disabled } = mod || {};
    if (disabled) {
      return;
    }
    if (isSelectingFirstDay(pickUpDate, returnDate, day)) {
      setPickUpDate(day);
      setReturnDate(null);
      setEnteredTo(null);
    } else {
      setReturnDate(day);
      setEnteredTo(day);
    }
  };

  const handleOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormatDate = date => {
    const formattedDate = moment(date).format("D MMM");
    return formattedDate;
  };

  const handleFormatTime = time => {
    const formattedTime = moment(time).format("HH:mm");
    return formattedTime;
  };

  useEffect(() => {
    setInRangeTotallyUnvailableDays([]);
    setInRangePartialUnavaialbleDays([]);
    const range = {
      start: pickUpDate,
      end: returnDate,
    };
    if (range && totallyUnavailableDays.length > 0) {
      totallyUnavailableDays.forEach(d => {
          const inRange = isWithinInterval(d, range);
        if (inRange) {
          setInRangeTotallyUnvailableDays(prevState => [...prevState, d]);
        }
      });
    }

    if (range && partiallyUnavailableDays.length > 0) {
      partiallyUnavailableDays.forEach(d => {
          const inRange = isWithinInterval (d, range);
        if (inRange) {
          setInRangePartialUnavaialbleDays(prevState => [...prevState, d]);
        }
      });
    }
  }, [
    pickUpDate,
    returnDate,
    totallyUnavailableDays,
    partiallyUnavailableDays,
  ]);

  return (
    <Grid
      container
      className={classes.root}
      xs={12}
      onClick={handleOpen}
      justifyContent="center"
      alignItems="flex-end"
    >
      {!mobile && (
        <Grid item className={classes.dateWrapper}>
          {handleFormatDate(pickUpDate)}
          <Box item xs={3} className={classes.timeWrapper} component="span">
            {" "}
            {handleFormatTime(pickUpTime)}{" "}
          </Box>
          – {handleFormatDate(returnDate)}
          <Box item xs={3} className={classes.timeWrapper} component="span">
            {" "}
            {handleFormatTime(returnTime)}
          </Box>
        </Grid>
      )}
      {mobile && (
        <Grid item justifyContent="center">
          <Box
            component="div"
            className={classes.dateWrapper}
            style={{ display: "flex", alignItems: "center" }}
          >
            {handleFormatDate(pickUpDate)} – {handleFormatDate(returnDate)}
          </Box>
          <Box
            component="div"
            className={classes.timeWrapper}
            style={{ display: "flex", alignItems: "center" }}
          >
            {handleFormatTime(pickUpTime)} – {handleFormatTime(returnTime)}
          </Box>
        </Grid>
      )}
          {open && (
              <DateRangePicker
                  open={open}
              mobile = {mobile}
          handleClose={handleClose}
          from={pickUpDate}
          to={returnDate}
          pickUpTime={pickUpTime}
          returnTime={returnTime}
          setPickUpTime={setPickUpTime}
          setReturnTime={setReturnTime}
          handleOnDayClick={handleOnDayClick}
          handleFormatDate={handleFormatDate}
          weekends={weekends}
          onDayMouseEnter={handleDayMouseEnter}
          enteredTo={enteredTo}
          handleMonthChange={handleMonthChange}
          partialBusy={partiallyUnavailableDays}
          totallyBusy={totallyUnavailableDays}
          pickUpTimeSetting={pickUpTimeSetting}
          returnTimeSetting={returnTimeSetting}
          inRangeTotallyUnvailableDays={inRangeTotallyUnvailableDays}
          inRangePartialUnavailableDays={inRangePartialUnavailableDays}
          unavailableDays={unavailableDays}
        />
      )}
    </Grid>
  );
};

ResponsiveDateRange.propTypes = {
  pickUpDate: PropTypes.instanceOf(Date).isRequired,
  returnDate: PropTypes.instanceOf(Date).isRequired,
  setPickUpDate: PropTypes.func.isRequired,
  setReturnDate: PropTypes.func.isRequired,
  pickUpTime: PropTypes.instanceOf(Date).isRequired,
  setPickUpTime: PropTypes.func.isRequired,
  returnTime: PropTypes.instanceOf(Date).isRequired,
  setReturnTime: PropTypes.func.isRequired,
  weekends: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  enteredTo: PropTypes.instanceOf(Date).isRequired,
  setEnteredTo: PropTypes.func.isRequired,
  lift: PropTypes.string.isRequired,
  pickUpTimeSetting: PropTypes.instanceOf(Date).isRequired,
  returnTimeSetting: PropTypes.instanceOf(Date).isRequired,
};

export default ResponsiveDateRange;

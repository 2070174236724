import { createActions } from "redux-actions";

export const { setCompany, setCompanies } = createActions({
  SET_COMPANY: ({ companyId, companyName, usersRights }) => ({
    companyId,
    companyName,
    usersRights,
  }),
  SET_COMPANIES: ({ items }) => ({ items }),
});

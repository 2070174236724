import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import UploadField from "../UploadField";
import FileSectionButton from "./FileSectionButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(theme => ({
  imgBox: {
    backgroundColor: theme.palette.common.white,
    "& img": {
      minWidth: 170,
      minHeight: 170,
    },
  },
  labelHead: {
    fontSize: 10,
    marginBottom: 3,
    "& span": {
      color: theme.palette.component.productBoxColor,
      fontWeight: 600,
    },
  },
  cardHighlight: {
    padding: "5px 10px",
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: "0 3px 10px 0 rgba(0,0,0,0.1)",
    marginBottom: 3,
  },
  cardLowlight: {
    padding: "5px 10px",
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: "0 3px 10px 0 rgba(0,0,0,0.1)",
    marginBottom: 3,
    opacity: "0.7",
  },
  card: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      borderRadius: 0,
      boxShadow: "0px 0px 5px 5px rgba(0,0,0,0.1)",
      margin: "-3px -3px -4px -3px",
      padding: "3px 3px 1px 3px",
    },
  },
  divider: {
    //marginBottom: 10,
    //marginTop: 10,
  },
  fileButton: {
    justifyContent: "left",
    textAlign: "left",
    textTransform: "none",
  },
  modal: {
    width: "1400px",
    height: "96vh",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "95vh",
    },
  },
  modalHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 10px",
    color: "#fff",
    display: "flex",
    minHeight: 20,
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
    },
  },
  close: {
    padding: 4,
    color: "#fff",
    marginLeft: "auto",
  },
  modalTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 2.3,
    marginRight: 20,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "normal",
      fontSize: 14,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const FileSection = ({
  handleAlternativeFileUpload,
  handleFileUpload,
  handleRemoveAlternative,
  handleRemoveOriginal,
  handleRemoveResources,
  handleRemoveThumbnail,
  handleUploadFromSystem,
  handleUploadThumbnail,
  image,
  initialFileNames,
  loadingUpload,
  selectedResources,
  setInitialFileNames,
  uploadedOriginal,
  uploadedThumbnail,
  uploadedAlternative,
}) => {
  const classes = useStyles();

  const {
    displayFileName,
    originalFileName,
    thumbnailFileName,
  } = initialFileNames;

  const fileSelectorType = {
    NONE: "NONE",
    THUMBNAIL_FILE: "THUMBNAIL_FILE",
    ORIGINAL_FILE: "ORIGINAL_FILE",
    DISPLAY_FILE: "DISPLAY_FILE",
  };

  const [showDialog, setShowDialog] = useState(false);
  const [showThumbnailFileSelector, setShowThumbnailFileSelector] = useState(
    false
  );
  const [showOriginalFileSelector, setShowOriginalFileSelector] = useState(
    false
  );
  const [showDisplayFileSelector, setShowDisplayFileSelector] = useState(false);

  const handleOnDeleteInitialFileName = removeFileName => {
    setInitialFileNames({ ...initialFileNames, [removeFileName]: null });
  };

  const fileSectionButtonOnClick = ({ e, value }) => {
    setShowDialog(true);
    setShowThumbnailFileSelector(value === fileSelectorType.THUMBNAIL_FILE);
    setShowOriginalFileSelector(value === fileSelectorType.ORIGINAL_FILE);
    setShowDisplayFileSelector(value === fileSelectorType.DISPLAY_FILE);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setShowThumbnailFileSelector(false);
    setShowOriginalFileSelector(false);
    setShowDisplayFileSelector(false);
  };

  return (
    <>
      <Grid item>
        <Box className={classes.imgBox} align="center" spacing={2}>
          <img src={image} alt="Product" />
        </Box>
        <Divider className={classes.divider} />
        <FileSectionButton
          value={fileSelectorType.THUMBNAIL_FILE}
          label={<FormattedMessage id="product.fileSectionThumbnailImage" />}
          description={
            <FormattedMessage id="product.fileSectionThumbnailImageDescription" />
          }
          onClick={fileSectionButtonOnClick}
        />
        <Divider className={classes.divider} />
        <FileSectionButton
          value={fileSelectorType.ORIGINAL_FILE}
          label={<FormattedMessage id="product.fileSectionOriginalFile" />}
          description={
            <FormattedMessage id="product.fileSectionOriginalFileDescription" />
          }
          onClick={fileSectionButtonOnClick}
        />
        <Divider className={classes.divider} />
        <FileSectionButton
          dimmed
          value={fileSelectorType.DISPLAY_FILE}
          label={
            <FormattedMessage id="product.fileSectionOptionalOriginalFileForViewing" />
          }
          description={
            <FormattedMessage id="product.fileSectionOptionalOriginalFileForViewingDescription" />
          }
          onClick={fileSectionButtonOnClick}
        />
        <Divider className={classes.divider} />
      </Grid>
      <Dialog open={showDialog} handleClose={handleCloseDialog}>
        <DialogTitle classes={{ root: classes.modalHeader }}>
          <Typography className={classes.modalTitle} component="div">
            <FormattedMessage id="product.selectFile" />
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.close}
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Typography component="div">
          {showOriginalFileSelector && (
            <Grid item>
              <Box className={classes.cardHighlight}>
                <Typography className={classes.labelHead}>
                  <span>
                    <FormattedMessage id="product.fileSectionOriginalFile" />
                  </span>{" "}
                  |{" "}
                  <FormattedMessage id="product.fileSectionOriginalFileDescription" />
                </Typography>
                <UploadField
                  uploadedFiles={uploadedOriginal}
                  onChange={handleFileUpload}
                  loading={loadingUpload.original}
                  handleRemoveUploadedFile={handleRemoveOriginal}
                  selectedResources={selectedResources}
                  setSelectedResources={handleUploadFromSystem}
                  handleRemoveResources={handleRemoveResources}
                  initialFileName={originalFileName}
                  onDeleteInitialFileName={() =>
                    handleOnDeleteInitialFileName("originalFileName")
                  }
                  systemUpload
                />
              </Box>
            </Grid>
          )}
          {showDisplayFileSelector && (
            <Grid item>
              <Box className={classes.cardHighlight}>
                <Typography className={classes.labelHead}>
                  <span>
                    <FormattedMessage id="product.fileSectionOptionalOriginalFileForViewing" />
                  </span>{" "}
                  |{" "}
                  <FormattedMessage id="product.fileSectionOptionalOriginalFileForViewingDescription" />
                </Typography>
                <UploadField
                  initialFileName={displayFileName}
                  uploadedFiles={uploadedAlternative}
                  onChange={handleAlternativeFileUpload}
                  loading={loadingUpload.alternative}
                  handleRemoveUploadedFile={handleRemoveAlternative}
                  onDeleteInitialFileName={() =>
                    handleOnDeleteInitialFileName("displayFileName")
                  }
                  systemUpload={false}
                />
              </Box>
            </Grid>
          )}
          {showThumbnailFileSelector && (
            <Grid item>
              <Box className={classes.cardHighlight}>
                <Typography className={classes.labelHead}>
                  <span>
                    <FormattedMessage id="product.fileSectionThumbnailImage" />
                  </span>{" "}
                  |{" "}
                  <FormattedMessage id="product.fileSectionThumbnailImageDescription" />
                </Typography>
                <UploadField
                  uploadedFiles={uploadedThumbnail}
                  onChange={handleUploadThumbnail}
                  loading={loadingUpload.thumbnail}
                  handleRemoveUploadedFile={handleRemoveThumbnail}
                  initialFileName={thumbnailFileName}
                  onDeleteInitialFileName={() =>
                    handleOnDeleteInitialFileName("thumbnailFileName")
                  }
                  systemUpload={false}
                />
              </Box>
            </Grid>
          )}
        </Typography>
      </Dialog>
    </>
  );
};

FileSection.defaultProps = {
  selectedResources: [],
  initialFileNames: {},
};

FileSection.propTypes = {
  uploadedOriginal: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  loadingUpload: PropTypes.shape({
    thumbnail: PropTypes.bool,
    original: PropTypes.bool,
    alternative: PropTypes.bool,
  }).isRequired,
  handleRemoveOriginal: PropTypes.func.isRequired,
  uploadedThumbnail: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleUploadThumbnail: PropTypes.func.isRequired,
  handleRemoveThumbnail: PropTypes.func.isRequired,
  handleUploadFromSystem: PropTypes.func.isRequired,
  handleRemoveResources: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(),
  initialFileNames: PropTypes.shape({
    displayFileName: PropTypes.string,
    originalFileName: PropTypes.string,
    thumbnailFileName: PropTypes.string,
  }),
};

export default FileSection;

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import ClearSettingsIcon from "./ClearSettingsIcon";

const FileItemLabels = ({
  productionProperties,
  folderFormValues,
  itemFormValue,
  handleRemoveFileSettings,
  resource,
}) => {
  let firstProperty = true;
  const { productionSettings: folderProductionSettings = {} } =
    folderFormValues || {};
  const {
    propertyValueIds: folderPropertyValueIds = [],
  } = folderProductionSettings;

  const { productionSettings: fileProductionSettings = {} } =
    itemFormValue || {};
  const {
    propertyValueIds: filePropertyValueIds = [],
  } = fileProductionSettings;

  return (
    <Box display="flex" alignItems="center">
      {filePropertyValueIds &&
        Array.isArray(filePropertyValueIds) &&
        filePropertyValueIds.length > 0 && (
          <ClearSettingsIcon
            onClick={() =>
              handleRemoveFileSettings({ resourceId: resource.id })
            }
          />
        )}
      <Typography style={{ fontSize: 12, color: "#808080" }}>
        {productionProperties.map(x => {
          const { propertyValues = [] } = x;

          let propertiesSelected = [];

          const isFirstProperty = firstProperty;

          propertyValues.forEach(pV => {
            const exist = filePropertyValueIds.includes(pV.id);

            if (exist) {
              propertiesSelected = [...propertiesSelected, pV];
            }
          });

          if (propertiesSelected && propertiesSelected.length > 0) {
            firstProperty = false;
            return `${isFirstProperty ? "" : " | "}${propertiesSelected.map(
              (pS, index) => `${index === 0 ? "" : " "}${pS.name}`
            )}`;
          }

          propertyValues.forEach(pV => {
            const exist = folderPropertyValueIds.includes(pV.id);

            if (exist) {
              propertiesSelected = [...propertiesSelected, pV];
            }
          });

          if (propertiesSelected && propertiesSelected.length > 0) {
            firstProperty = false;
            return `${isFirstProperty ? "" : " | "}${propertiesSelected.map(
              (pS, index) => `${index === 0 ? "" : " "}${pS.name}`
            )}`;
          }

          return "";
        })}
      </Typography>
    </Box>
  );
};

FileItemLabels.defaultProps = {
  folderFormValues: {},
  itemFormValue: {},
  productionProperties: [],
};

FileItemLabels.propTypes = {
  handleRemoveFileSettings: PropTypes.func.isRequired,
  folderFormValues: PropTypes.shape({
    productionSettings: PropTypes.shape({}),
  }),
  itemFormValue: PropTypes.shape({
    productionSettings: PropTypes.shape({}),
  }),
  productionProperties: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FileItemLabels;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  typography: {
    fontSize: 11,
    margin: "10px 0",
  },
}));

const NotificationSms = ({ type }) => {
  const classes = useStyles();

  if (!Array.isArray(type)) {
    var temp = type;
    type = [];
    type.push(temp);
  }

  return (
    <>
      {type.map(x => {
        let time = "";
        if (x === "EMAIL") {
          time = "monitoring.how.email";
        } else if (x === "PRINT_AND_MAIL") {
          time = "monitoring.how.printAndMail";
        } else if (x === "SMS") {
          time = "monitoring.how.sms";
        } else if (x === "SYSTEM_NOTIFICATION") {
          time = "monitoring.how.systemNotification";
        }

        return (
          <Typography className={classes.typography} key={x}>
            <FormattedMessage id={time} />
          </Typography>
        );
      })}
    </>
  );
};

NotificationSms.defaultProps = {
  type: null,
};

NotificationSms.propTypes = {
  type: PropTypes.string,
};

export default NotificationSms;

import { useState } from "react";
import { useIntl } from "react-intl";
import { Autocomplete, Popper, TextField } from "@mui/material";
import UserListBox from "./UserListBox";

const UserSelector = ({
  autoFocus,
  disabled,
  onChange,
  onClose,
  userLookup,
  value,
}) => {
  const intl = useIntl();

  const [internalValue, setInternalValue] = useState(value);

  const createOptionLabel = (option) => `${option.firstname} ${option.lastname} <${option.username}>`;
  const equalityCheck = (option, value) => (option?.username === value?.username);

  const popperComponent = (props) => (
    <Popper
      {...props}
      placement="bottom-start"
      sx={{
        "& *": {
          cursor: "pointer",
        },
        "& li:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      }}
    >
      {props.children}
    </Popper>
  );

  return (
    <Autocomplete
      blurOnSelect
      disabled={disabled}
      getOptionLabel={createOptionLabel}
      handleHomeEndKeys={false}
      isOptionEqualToValue={equalityCheck}
      ListboxComponent={UserListBox}
      ListboxProps={{
        style: {
          maxHeight: 300
        }
      }}
      noOptionsText={
        intl.formatMessage({ id: "project.userSelector.noOptionsText" })
      }
      onBlur={onClose}
      onChange={
        (event, newValue, reason, details) => {
          if (reason === "selectOption" && !equalityCheck(value, newValue)) {
            onChange?.(newValue);
          }
          else if (reason === "clear") {
            setInternalValue(null);
          }
        }
      }
      onKeyDown={(e) => {
        const key = e?.key;
        if (key === "Enter" && !internalValue) {
          onChange?.(null);
        }
        else if (key === "Escape") {
          onClose?.();
        }
      }}
      openOnFocus
      options={userLookup}
      PopperComponent={popperComponent}
      renderInput={
        (params) => (
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0)', // Default border color
                  borderWidth: '1px', // Default border width
                  marginLeft: '-2px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.87)', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.5)', // Border color when focused
                  borderWidth: '1px', // Border width when focused
                },
              }, } }
            {...params}
            autoFocus={autoFocus}
            placeholder={
              intl.formatMessage({ id: "project.userSelector.placeholder" })
            }
            size="small"
          />
        )
      }
      renderOption={
        (props, option, state) => (
          {
            option,
            props,
          }
        )
      }
      sx={{
        marginLeft: "1px",
        marginTop: "1px",
      }}
      value={internalValue}
    />
  );
};

export default UserSelector;

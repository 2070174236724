import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";

import { useLazyQuery } from "@apollo/client";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { GET_RESOURCES } from "../../../../graphql/queries";
import {
  useUpdateMetadata,
  useUpdateTags,
  useSnackbar,
} from "../../../../hooks";

import { PrimaryButton } from "../../../common";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import Pagination from "../../../common/Pagination";

import MetaDataForm from "./MetaDataForm";
import ResourceTags from "./ResourceTags";
import ResourceTable from "./ResourceTable";
import { useSelector } from "react-redux";
import { getSiteId } from "../../../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  modal: {},
  body: {
    padding: 0,
  },
  listItemContainer: {
    backgroundColor: theme.palette.common.white,
  },
  listItemScroll: {
    overflowY: "auto",
    height: "90vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  sectionTitle: {
    marginBottom: 10,
    fontSize: 14,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 15px",
    backgroundColor: theme.palette.background.contrastGray,
    marginTop: "auto",
    "& button": {
      marginLeft: "2px",
    },
  },
  checkboxContainer: {
    marginTop: 5,
  },
  replaceMetadataSection: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "0 !important",
  },
  fieldWithSwitch: {
    display: "flex",
    alignItems: "center",
  },
  formWrap: {
    padding: 0,
    borderLeft: "1px solid #ddd",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fieldsWrap: {
    padding: 15,
  },
}));

const initialFormValues = {
  contact: "",
  date: new Date(),
  imageNo: "",
  isFreeToUser: false,
  location: "",
  model: "",
  occupation: "",
  photographer: "",
};

const initialSwitchValues = {
  contact: true,
  date: true,
  imageNo: true,
  location: true,
  model: true,
  occupation: true,
  photographer: true,
};

const MetaDataTaggingModal = ({
  folderId,
  handleClose,
  handleError,
  handleOpenProductEditModal,
  open,
  selectedResources,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const [tags, setTags] = useState([]);
  const [displayTags, setDisplayTags] = useState([]);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [switchValues, setSwitchValues] = useState(initialSwitchValues);
  const [selectedUser, setSelectedUser] = useState();

  const { execute: updateMetadata } = useUpdateMetadata();
  const { execute: updateTags } = useUpdateTags();
  const [resources, setResources] = useState([]);
  const [ascending, setAscending] = useState(true);
  const [sortfield, setSortfield] = useState("Name");

  const [searchValues, setSearchValues] = useState(null);
  const [productTotalCount, setProductTotalCount] = useState(0);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  
  const siteId = useSelector(state => getSiteId(state));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  const [markedResource, setMarkedResource] = useState(null);
  const [selectedLocalResources, setLocalSelectedResources] = useState(
    selectedResources
  );
  const addSelectedResource = item => {
    setLocalSelectedResources([...selectedLocalResources, item]);
  };
  const removeSelectedResource = item => {
    const temp = selectedLocalResources.filter(x => x.id !== item.id);
    setLocalSelectedResources(temp);
  };
  const setSelectedResources = () => {};
  const selectAllResources = () => {
    setLocalSelectedResources(resources);
  };
  const selectNoResources = () => {
    setLocalSelectedResources([]);
  };

  const [getResources, { data, loading }] = useLazyQuery(GET_RESOURCES, {
    variables: {
      includeLinkedResources: true,
      folderId,
      ascending,
      offset: page * pageSize,
      pageSize,
      sortfield,
      columnFilter: searchValues,
      includeFileMetadata: true,
    },
  });

  useEffect(() => {
    if (folderId) {
      getResources();
    }
  }, [page, pageSize]);

  useEffect(() => {
    if (data && data.resources && data.resources.items) {
      const { totalCount } = data.resources;
      setResources(data.resources.items);
      setProductTotalCount(totalCount);
    } else {
      getResources();
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length >= 1
    ) {
      const { imageMetadata, id } = selectedResources[0];

      var tags = [];
      const meta = {};
      meta.contact = `${!imageMetadata ? "" : imageMetadata.contact}`;
      meta.date = `${!imageMetadata ? new Date() : imageMetadata.date}`;
      meta.imageNo = `${!imageMetadata ? "" : imageMetadata.imageNo}`;
      meta.model = `${!imageMetadata ? "" : imageMetadata.model}`;
      meta.location = `${!imageMetadata ? "" : imageMetadata.location}`;
      meta.occupation = `${
        !imageMetadata ? "" : imageMetadata.occupation
      }`;
      meta.photographer = `${
        !imageMetadata ? "" : imageMetadata.photographer
      }`;

      var conctactIsTheSame = true;
      var dateIsTheSame = true;
      var imageNoIsTheSame = true;
      var locationIsTheSame = true;
      var modelIsTheSame = true;
      var occupationisTheSame = true;
      var photographerIsTheSame = true;
      var newSwitchValues = initialSwitchValues;

      for (var i = 0; i < selectedResources.length; i++) {
        var current = selectedResources[i].imageMetadata;
        var currentTags = selectedResources[i].tags || [];

        if (current === null) {
          continue;
        }

        conctactIsTheSame &= current.contact === meta.contact;
        dateIsTheSame &= current.date === meta.date;
        imageNoIsTheSame &= current.imageNo === meta.imageNo;
        locationIsTheSame &= current.location === meta.location;
        modelIsTheSame &= current.model === meta.model;
        occupationisTheSame &= current.occupation === meta.occupation;
        photographerIsTheSame &= current.photographer === meta.photographer;

        for (var t = 0; t < currentTags.length; t++) {
          var tag = currentTags[t];
          var found = tags.indexOf(n => n.tagName === tag.tagName);
          if (found === -1) {
            tags.push(tag);
          } else {
            tags[found].multiple = true;
          }
        }
      }

      newSwitchValues.contact = conctactIsTheSame;
      newSwitchValues.date = dateIsTheSame;
      newSwitchValues.imageNo = imageNoIsTheSame;
      newSwitchValues.location = locationIsTheSame;
      newSwitchValues.model = modelIsTheSame;
      newSwitchValues.occupation = occupationisTheSame;
      newSwitchValues.photographer = photographerIsTheSame;

      if (!conctactIsTheSame) {
        meta.contact = `${initialFormValues.contact}`;
      }

      if (!dateIsTheSame) {
        meta.date = `${initialFormValues.date}`;
      }

      if (!imageNoIsTheSame) {
        meta.imageNo = `${initialFormValues.imageNo}`;
      }

      if (!locationIsTheSame) {
        meta.location = `${initialFormValues.location}`;
      }

      if (!modelIsTheSame) {
        meta.model = `${initialFormValues.model}`;
      }

      if (!occupationisTheSame) {
        meta.occupation = `${initialFormValues.occupation}`;
      }

      if (!photographerIsTheSame) {
        meta.photographer = `${initialFormValues.photographer}`;
      }

      setTags(tags || []);
      setDisplayTags(tags || []);

      if (meta) {
        const newMeta = { ...meta, id };
        setFormValues(newMeta);
        setSelectedUser();
      } else {
        setFormValues(initialFormValues);
        setSelectedUser();
      }

      setSwitchValues(switchValues);
    } else {
      setFormValues(initialFormValues);
      setTags([]);
      setSelectedUser();
    }
  }, [selectedResources]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };

  const handleDateChange = dateValue => {
    setFormValues({ ...formValues, date: dateValue });
  };

  const handleSwitchChange = (e, name) => {
    setSwitchValues({
      ...switchValues,
      [name]: e.target.checked,
    });
  };

  const handleSelectContact = value => {
    setFormValues({ ...formValues, contact: value.label });
  };

  const handleUpdateTags = async updatedTags => {
    try {
      const res = await updateTags({ tags: updatedTags });
      if (res && res.data && res.data.updateTags) {
        const { success } = res.data.updateTags;
        if (success) {
          alert.success(<FormattedMessage id="product.tagsUpdated" />);
          getResources();
        } else {
          // TODO: We can also display the res.data.replaceMetadata.errorResult here
          const { errorResult } = res.data.updateTags;
          const files = intl.formatMessage({
            id: "common.someFiles",
          });
          handleError(files, errorResult);
          snackbar.workspaceError(
            <FormattedMessage id="product.errorReplaceMetadata" />
          );
        }
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="product.errorReplaceMetadata" />
        );
      }
    } catch (e) {
      snackbar.workspaceError(
        <FormattedMessage id="product.errorReplaceMetadata" />
      );
    }
  };

  const handleAddTags = async () => {
    let metadataInputs = [];
    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length === 1
    ) {
      const resourceId = selectedResources[0].id;
      const { tags: currentTags } = selectedResources[0];
      console.log("selectedResources >", selectedResources);
      let oldTags = [];
      let newTags = [];

      tags.forEach(x => {
        if (x.tagId) {
          oldTags = [...oldTags, x.tagId];
        } else {
          newTags = [...newTags, x.tagName];
        }
      });

      currentTags.forEach(cTag => {
        oldTags = [...oldTags, cTag.tagId];
      });

      const tempTags = {
        id: resourceId,
        tagIds: oldTags,
        newTags,
      };

      metadataInputs = [tempTags];
    }

    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length > 1
    ) {
      let oldTags = [];
      let newTags = [];

      tags.forEach(x => {
        if (x.tagId) {
          oldTags = [...oldTags, x.tagId];
        } else {
          newTags = [...newTags, x.tagName];
        }
      });
      selectedResources.forEach(x => {
        const resourceId = x.id;
        const { tags: currentTags } = x;

        currentTags.forEach(cTag => {
          oldTags = [...oldTags, cTag.tagId];
        });

        const tempTags = {
          id: resourceId,
          tagIds: oldTags,
          newTags,
        };

        metadataInputs = [...metadataInputs, tempTags];
      });
    }

    handleUpdateTags(metadataInputs);
  };

  const handleReplaceTags = async () => {
    let metadataInputs = [];
    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length === 1
    ) {
      const resourceId = selectedResources[0].id;

      let oldTags = [];
      let newTags = [];

      tags.forEach(x => {
        if (x.tagId) {
          oldTags = [...oldTags, x.tagId];
        } else {
          newTags = [...newTags, x.tagName];
        }
      });

      const tempTags = {
        id: resourceId,
        tagIds: oldTags,
        newTags,
      };

      metadataInputs = [tempTags];
    }

    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length > 1
    ) {
      let oldTags = [];
      let newTags = [];

      tags.forEach(x => {
        if (x.tagId) {
          oldTags = [...oldTags, x.tagId];
        } else {
          newTags = [...newTags, x.tagName];
        }
      });
      selectedResources.forEach(x => {
        const resourceId = x.id;
        const tempTags = {
          id: resourceId,
          tagIds: oldTags,
          newTags,
        };

        metadataInputs = [...metadataInputs, tempTags];
      });
    }

    handleUpdateTags(metadataInputs);
  };

  const handleUpdateMetadata = async metadata => {
    try {
      const res = await updateMetadata({ metadata });
      if (res && res.data && res.data.updateMetadata) {
        const { success } = res.data.updateMetadata;
        if (success) {
          alert.success(
            <FormattedMessage id="product.successReplaceMetadata" />
          );
          getResources();
        } else {
          // TODO: We can also display the res.data.replaceMetadata.errorResult here
          const { errorResult } = res.data.updateMetadata;
          const files = intl.formatMessage({
            id: "common.someFiles",
          });
          handleError(files, errorResult);
          snackbar.workspaceError(
            <FormattedMessage id="product.errorReplaceMetadata" />
          );
        }
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="product.errorReplaceMetadata" />
        );
      }
    } catch (e) {
      snackbar.workspaceError(
        <FormattedMessage id="product.errorReplaceMetadata" />
      );
    }
  };

  const handleReplaceMetadata = async () => {
    let metadataInputs = [];
    const objectKeys = Object.keys(formValues);

    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length === 1
    ) {
      const resourceId = selectedResources[0].id;

      let tempMetadata = {
        id: resourceId,
        isFreeToUser: formValues.isFreeToUser,
      };

      objectKeys.forEach(x => {
        if (switchValues[x]) {
          tempMetadata = { ...tempMetadata, [x]: formValues[x] };
        }
      });

      metadataInputs = [tempMetadata];
    }

    if (
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length > 1
    ) {
      selectedResources.map(x => {
        let tempMetadata = {
          id: x.id,
          isFreeToUser: formValues.isFreeToUser,
        };

        objectKeys.forEach(x => {
          if (switchValues[x]) {
            tempMetadata = { ...tempMetadata, [x]: formValues[x] };
          }
        });

        metadataInputs = [...metadataInputs, tempMetadata];
      });
    }

    handleUpdateMetadata(metadataInputs);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
      title={intl.formatMessage({
        id: "metadata.editMetadataAndTagging",
      })}
      fullScreen
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="product.edit" />}
      />
      <DraggableModalBody customClass={classes.body}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={8}>
            <Box className={classes.listItemContainer}>
              <div className={classes.paginationWrapper}>
                <Pagination
                  onChange={handleChangeRowsPerPage}
                  onPageChange={handleChangePage}
                  page={page}
                  perPage={pageSize}
                  totalCount={productTotalCount}
                />
              </div>
              <Box className={classes.listItemScroll}>
                <ResourceTable
                  resources={resources}
                  selectedResources={selectedLocalResources}
                  addSelectedResource={addSelectedResource}
                  removeSelectedResource={removeSelectedResource}
                  setSelectedResources={setSelectedResources}
                  selectAllResources={selectAllResources}
                  selectNoResources={selectNoResources}
                  handleOpenProductEditModal={handleOpenProductEditModal}
                  handleClose={handleClose}
                  markedResource={markedResource}
                  setMarkedResource={setMarkedResource}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.formWrap}>
              <Box className={classes.fieldsWrap}>
                <MetaDataForm
                  formValues={formValues}
                  switchValues={switchValues}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handleSwitchChange={handleSwitchChange}
                  handleCheckboxChange={handleCheckboxChange}
                  handleReplaceMetadata={handleReplaceMetadata}
                  handleSelectContact={handleSelectContact}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                  markedResource={markedResource}
                />
                <ResourceTags tags={tags} setTags={setTags} siteId={ siteId} />
              </Box>
              <Grid container spacing={0} className={classes.footer}>
                <Grid item xs={12} align="right">
                  <PrimaryButton onClick={() => handleAddTags()}>
                    <FormattedMessage id="btn.add" />
                  </PrimaryButton>
                  <PrimaryButton onClick={() => handleReplaceTags()}>
                    <FormattedMessage id="btn.replace" />
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

MetaDataTaggingModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleOpenProductEditModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      imageMetadata: PropTypes.shape({
        contact: PropTypes.string.isRequired,
        date: PropTypes.oneOfType([
          PropTypes.instanceOf(Date),
          PropTypes.string,
        ]),
        imageNo: PropTypes.string.isRequired,
        isFreeToUser: PropTypes.bool.isRequired,
        legalDocument: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
        occupation: PropTypes.string.isRequired,
        photographer: PropTypes.string.isRequired,
      }),
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          tagId: PropTypes.number.isRequired,
          tagName: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};

export default MetaDataTaggingModal;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { TableCell, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { PinkSwitch } from "../../../../common";

const NotifyTableCell = ({
  handleFileNotifyChange,
  isNotifyEnabled,
  resource,
}) => {
  const intl = useIntl();

  const onSwitchChange = e => {
    handleFileNotifyChange(e, resource.id);
  };

  return (
    <TableCell align="center">
      <PinkSwitch checked={isNotifyEnabled} onChange={onSwitchChange} />
      <Typography component="span">
        {intl.formatMessage({
          id: "addFolderToBasket.include",
        })}
      </Typography>
    </TableCell>
  );
};

NotifyTableCell.defaultProps = {};

NotifyTableCell.propTypes = {};

export default memo(NotifyTableCell);

import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { StatusApprovedIcon, StatusRejectedIcon, StatusSentIcon } from "../../components/Approval/StatusIcons";
import DocumentApproval from "../../components/DocumentConsent";
import DynamicTable from "../../components/DynamicTable/";
import PageLayoutWithSidebar from "../../components/PageLayoutWithSidebar";
import RequestConsentModal from "../../components/ProductsMainView/FileView/RequestConsentModal/RequestConsentModal";
import { useListConsents } from "../../hooks/ConsentContext";

const bgColor = (theme) => ({
  backgroundColor: theme.palette.background.cream,
  flexGrow: 1,
});

const columnHeaders = [
  { title: 'Namn', field: 'name', hidden: false },
  { title: 'Skapad', field: 'created', hidden: false },
  { title: 'Verifierad', field: 'verified', hidden: false },
  { title: 'Verifierat datum', field: 'verifiedDate', hidden: false },
  { title: 'Status', field: 'status', hidden: false },
  { title: '', field: 'action', hidden: false },
];

const mapConsentItem = (n) => {
  const verifiedItemCount = n.resources.filter(n => n.approved).length;
  const haveVerifiedItems = verifiedItemCount > 0;
  const isAnswered = !!n.answeredDate;
  const itemCount = n.resources.length;

  return {
    token: n.token,
    name: `${n.firstname} ${n.lastname}`,
    created: mapDateString(n.createdDate),
    verified: `${verifiedItemCount}/${itemCount}`,
    verifiedDate: mapDateString(n.answeredDate),
    status: isAnswered ? (haveVerifiedItems ? <StatusApprovedIcon /> : <StatusRejectedIcon />) : <StatusSentIcon />
  };
};

const mapDateString = (date) => (date && new Date(date).toLocaleDateString() || "");

const SentConsents = () => {
  const [listConsents, { data: consentsData, loading: loadingConsents, error: consentsError }] = useListConsents();

  const [consents, setConsents] = useState();
  const [requestDetails, setRequestDetails] = useState();
  const [tableData, setTableData] = useState();

  useEffect(() => {
    listConsents({ siteId: null });
  }, []);

  useEffect(() => {
    if (consentsData && !(loadingConsents || consentsError)) {
      const newConsents = Array.isArray(consentsData) && consentsData;
      setConsents(newConsents);
    }
    else if (consentsError) {
      setConsents();
    }
  }, [consentsData, loadingConsents, consentsError]);

  useEffect(() => {
    const newTableData = Array.isArray(consents) && consents.map(n => mapConsentItem(n));
    setTableData(newTableData);
  }, [consents]);

  const handleTableRowClick = (item) => {
    var index = consents.findIndex(n => n.token === item?.token);
    setRequestDetails(consents[index]);
  };

  return (
    <Box sx={bgColor}>
      <PageLayoutWithSidebar
        sidebarContent={
          <div>
            <DocumentApproval open={true} />
          </div>
        }
        mainContent={
          !loadingConsents &&
          tableData && (
            <DynamicTable
              columns={columnHeaders}
              data={tableData}
              tHeight={'calc(100vh - 172px)'}
              onRowClick={handleTableRowClick}
            />
          )
        }
      />
      {requestDetails && (
        <RequestConsentModal
          details={requestDetails}
          open={!!requestDetails}
          onClose={() => setRequestDetails()}
          readOnly
        />
      )}
    </Box>
  );
}

export default SentConsents;

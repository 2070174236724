import { gql } from '@apollo/client';

const getTagData = gql`
  query GetTagData($siteId: Int!, $minTagUsage: Int!) {
    widgets {
      getTagData(siteId: $siteId, minTagUsage: $minTagUsage) {
        letter
        tags {
          tagId
          tagName
        }
      }
    }
  }
`;

export default getTagData;

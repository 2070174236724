import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, TextField } from "@mui/material";

import { useIntl } from "react-intl";

const ArticlePricelistAutoComplete = ({ articles, selectedArticle, onSelectArticle }) => {
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();
  const optionLabel = (option) => {
    return option.articleNo;
  }

  const renderOption = (props, option) => {
    return (<li {...props}>{option.articleNo}</li>);
  }

  return (<>
    {!articles && (<CircularProgress/>)}
    { articles && (
      <Autocomplete
        size="small"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}

        sx={{ fullWidth: "true" }}
        getOptionLabel={(option) =>
          optionLabel(option)
        }
        options={articles}
        autoComplete
        filterSelectedOptions
        value={selectedArticle}
        isOptionEqualToValue={(option, value) => option.articleNo === value.articleNo}
        noOptionsText=""
        loadingText={intl.formatMessage({ id: "common.genericLoading" })}
        onChange={(event, newValue) => {
          onSelectArticle(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label={intl.formatMessage({ id: "product.articleNum" })} fullWidth />
        )}
        renderOption={renderOption}
      />)}</>)
};

export default ArticlePricelistAutoComplete;
import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import LinkPrimary from "../common/LinkPrimary";
import SampleProfile from "../../assets/img/default-avatar.png";
import SampleFeatured from "../../assets/img/sweden-1.jpg";

const useStyles = makeStyles(theme => ({
  newsHeadLinks: {
    marginBottom: 20,
  },
  linkBlue: {
    color: theme.palette.common.blue,
    fontWeight: 400,
  },
  linkPrimary: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    marginLeft: 30,
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    textAlign: "center",
    color: "#555",
    lineHeight: "normal",
  },
  newsMetaInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
  },
  authorBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
  },
  profile: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 15,
  },
  authorName: {
    fontSize: 14,
  },
  dateSeparator: {
    marginLeft: 15,
    marginRight: 15,
  },
  category: {
    padding: "3px 15px",
    backgroundColor: "#D8D8D8",
    fontWeight: 600,
    color: "#555",
    borderRadius: 12,
    fontSize: 10,
  },
  featuredImg: {
    width: "100%",
    height: 380,
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
  },
}));

const NewsHead = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.newsHeadLinks}>
        <LinkPrimary
          icon="fa fa-angle-left"
          label="Back"
          customClass={classes.linkBlue}
        />
      </Box>
      <Typography className={classes.title}>
        Ut enim ad minima veniam, quis nostrum
      </Typography>
      <Box className={classes.newsMetaInfo}>
        <Typography class={classes.date}>June 1</Typography>
        <Typography class={classes.dateSeparator}>—</Typography>
        <Box className={classes.authorBox}>
          <img src={SampleProfile} alt="Profile" className={classes.profile} />
          <Typography className={classes.authorName}>Daniel Forsman</Typography>
        </Box>
        <Typography class={classes.category}>Category</Typography>
      </Box>
      <Box>
        <img src={SampleFeatured} alt="" className={classes.featuredImg} />
      </Box>
    </Box>
  );
};

export default NewsHead;

import { gql } from '@apollo/client';

const getMostDownloadedProductsForSite = gql`
  query GetMostDownloadedProductsForSite($siteId: Int!, $limit: Int) {
    widgets {
      getMostDownloadedProductsForSite(siteId: $siteId, limit: $limit) {
        downloadCount
        name
        fileExtension
        thumbnailImageUri
        productId
      }
    }
  }
`;

export default getMostDownloadedProductsForSite;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import PrintImageItem from "./PrintImageItem";
import { INITIALIZE_IMAGE_MAP_DOWNLOAD } from "../../../graphql/mutations";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1100px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    "@media print": {
      display: "block !important",
      overflow: "visible !important",
      position: "relative !important",
      top: 0,
      height: "100%",
      maxHeight: "100%",
      verticalAlign: "top",
      float: "none !important",
      padding: 0,
      margin: 0,
    },
    "& *": {
      "@media print": {
        visibility: "visible",
        overflow: "visible",
        display: "block",
      },
    },
  },
  modalBody: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center",
    maxHeight: "85vh",
    "& .MuiGrid-container": {
      "@media print": {
        height: "100%",
        width: "100%",
        position: "relative",
        display: "block",
        float: "none",
      },
    },
    "& .MuiGrid-item": {
      "@media print": {
        maxWidth: "100%",
        flexGrow: "1",
        flexBasis: "100%",
        height: "100%",
        width: "100%",
        display: "block",
        breakAfter: "always",
        pageBreakAfter: "always",
        clear: "both",
      },
    },
    "@media print": {
      maxHeight: "none",
      overflowY: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      overflowY: "auto",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    "@media print": {
      display: "none!important",
    },
  },
  btn: {
    padding: "3px 10px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  print: {
    "@media print": {
      textAlign: "left",
    },
  },
}));

const PrintImages = ({ open, handleClose, imageBasketItems, shareId }) => {
  const classes = useStyles();

  const [printImagemap] = useMutation(INITIALIZE_IMAGE_MAP_DOWNLOAD);

  const handlePrint = async () => {
    try {
      const res = await printImagemap({
        variables: { shareId },
      });

      if (res && res.data && res.data.initImageMapDownload) {
        window.open(res.data.initImageMapDownload);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="btn.printImageMap" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <Grid container spacing={2} className={classes.print}>
          {imageBasketItems.map(x => {
            return (
              <PrintImageItem imageBasketItem={x} key={`p-${x.productId}`} />
            );
          })}
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btn} onClick={() => handlePrint()}>
            <FormattedMessage id="btn.print" />
            <Icon className={clsx(["fa fa-print", classes.btnIcon])} />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

PrintImages.defaultProps = {
  shareId: null,
};

PrintImages.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
      settings: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  shareId: PropTypes.number,
};

export default PrintImages;

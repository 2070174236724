import React, { useEffect, useState } from "react";
import { debounce } from '@mui/material/utils';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyQuery } from "@apollo/client";

import { CircularProgress, TextField } from "@mui/material";
import { useIntl } from "react-intl";

const CompanyAddressAutoComplete = ({ companyCards, selectedCompany, onSelectCompany }) => {
    const [options, setOptions] = React.useState([]);
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);

  


  /**/


  /*const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        getCompanyCards({ variables: { companyId: request.companyId, searchTerm: request.input } })
      }, 400),
    [],
  );

  useEffect(() => {


    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, companyId: companyId }, (results) => { });

  }, [value, inputValue, fetch]);*/

  const optionLabel = (option) => {
    return option.companyName;
  }

  const renderOption = (props, option) => {
    return (<li {...props}>{optionLabel(option)}</li>);
  }

  return (<>
    {!companyCards && (<CircularProgress />)}
    {companyCards && (<Autocomplete
      getOptionLabel={(option) =>
        optionLabel(option)
      }      
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={companyCards}
      autoComplete
      includeInputInList
      filterSelectedOptions
      fullWidth
      value={selectedCompany}
      noOptionsText=""
      onChange={(event, newValue) => {
        onSelectCompany(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={intl.formatMessage({ id: "admin.salesdocuments.companyselect" })} fullWidth />
      )}

      renderOption={renderOption}

    />)
    }
  </>)
};

export default CompanyAddressAutoComplete;
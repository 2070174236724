import { WIDGET_TYPES } from "../../constant/widgets";

export const copyArray = (source, setDestination) => {
  var copy = Array.isArray(source) ? JSON.parse(JSON.stringify(source)) : [];
  setDestination(copy);
};

export const copyLayouts = (source, setDestination) => {
  copyArray(source?.lg, lg => setDestination({ lg }));
};

//H�rdkodade f�rgv�rden. Kanske anv�nda fr�n nuvarande tema ist�llet? (G�r till en funktion som tar theme som parameter)
//Blandade layout-parametrar och widget-inst�llningar.
export const initialLayoutValues = {
  actionBarBG: "#91657185",
  bgColor: "#fff",
  borderColor: "#7a0625ff",
  borderRadiusBottomLeft: 6,
  borderRadiusBottomRight: 6,
  borderRadiusTopLeft: 6,
  borderRadiusTopRight: 6,
  borderWidth: 0,
  contentBG: "#fff",
  dividerColor: "#dee2e6",
  fontColor: "#662336",
  isConfigured: true,
  maxH: undefined,
  maxW: undefined,
  minH: undefined,
  minW: undefined,
  showTitleBar: true,
  titleColor: "#212529",
  videoUrl: null,
  videoId: null,
};

export const initialDashboardSettings = {
  name: "Dashboard (name)",
  description: "(description)",
  owner: "(todo)",
  template: "A",
  background: "#f4f3ef",
};

export const initialDashboards = [
  {
    name: "Overview Dashboard",
    description: "Dashboard for the company",
    owner: "COMPANY",
    template: "B",
    background: "#f4f3ef",
  },
  {
    name: "Workspace Dashboard",
    description: "Dashboard for the workspace",
    owner: "WORKSPACE",
    template: "C",
    background: "#f4f3ef",
  },
  {
    name: "Customized Dashboard",
    description: "This is a customized dashboard",
    owner: "USER",
    template: "A",
    background: "#f4f3ef",
  },
];

export const initialOverviewDashboardLayout = [
  {
    actionBarBG: "#91657185",
    bgColor: "#f4f3ef",
    borderColor: "#7a0625ff",
    borderRadiusBottomLeft: 6,
    borderRadiusBottomRight: 6,
    borderRadiusTopLeft: 6,
    borderRadiusTopRight: 6,
    borderWidth: 0,
    contentBG: "#fff",
    desc: "widget.myWorkspaces",
    dividerColor: "#dee2e6",
    fontColor: "#662336",
    h: 20,
    i: "i",
    isConfigured: true,
    showTitleBar: false,
    static: true,
    title: "widget.myWorkspaces",
    titleColor: "#212529",
    type: WIDGET_TYPES.WORKSPACES,
    videoId: null,
    w: 2,
    x: 0,
    y: 0,
  },
  {
    actionBarBG: "#91657185",
    bgColor: "#f4f3ef",
    borderColor: "#7a0625ff",
    borderRadiusBottomLeft: 6,
    borderRadiusBottomRight: 6,
    borderRadiusTopLeft: 6,
    borderRadiusTopRight: 6,
    borderWidth: 0,
    contentBG: "#fff",
    desc: "widget.newsDesc",
    dividerColor: "#dee2e6",
    fontColor: "#662336",
    h: 16,
    i: "c",
    isConfigured: true,
    newsTemplate: 1,
    showTitleBar: false,
    static: true,
    title: "widget.news",
    titleColor: "#212529",
    type: WIDGET_TYPES.NEWS_BIG,
    videoId: null,
    w: 8,
    x: 2,
    y: 4,
  },
  {
    actionBarBG: "#91657185",
    bgColor: "#f4f3ef",
    borderColor: "#7a0625ff",
    borderRadiusBottomLeft: 6,
    borderRadiusBottomRight: 6,
    borderRadiusTopLeft: 6,
    borderRadiusTopRight: 6,
    borderWidth: 0,
    contentBG: "#fff",
    desc: "widget.informationDesc",
    dividerColor: "#dee2e6",
    fontColor: "#662336",
    h: 4,
    i: "f",
    isConfigured: true,
    showTitleBar: false,
    static: true,
    title: "widget.information",
    titleColor: "#212529",
    type: WIDGET_TYPES.TEXTEDITOR,
    videoId: null,
    w: 8,
    x: 2,
    y: 0,
  },
];

export const initialWorkspaceDashboardLayout = [
  {
    actionBarBG: "#91657185",
    background: "#e8e8e8",
    borderRadiusTopLeft: 6,
    borderRadiusTopRight: 6,
    borderRadiusBottomLeft: 6,
    borderRadiusBottomRight: 6,
    borderWidth: 0,
    borderColor: "#7a0625ff",
    desc: "widget.recentlyModifiedDesc",
    fontColor: "#662336",
    h: 20,
    i: "b",
    isConfigured: true,
    maxH: undefined,
    maxW: undefined,
    minH: undefined,
    minW: undefined,
    name: "",
    showTitleBar: false,
    title: "widget.recentlyModified",
    type: WIDGET_TYPES.RECENTLY_MODIFIED_FILES,
    w: 2,
    x: 0,
    y: 0,
  },
  {
    actionBarBG: "#91657185",
    background: "#e8e8e8",
    borderColor: "#7a0625ff",
    borderRadiusTopLeft: 6,
    borderRadiusTopRight: 6,
    borderRadiusBottomLeft: 6,
    borderRadiusBottomRight: 6,
    borderWidth: 0,
    desc: "widget.informationDesc",
    fontColor: "#662336",
    h: 4,
    i: "f",
    isConfigured: true,
    maxH: undefined,
    maxW: undefined,
    minH: undefined,
    minW: undefined,
    name: "",
    showTitleBar: false,
    title: "widget.information",
    type: WIDGET_TYPES.TEXTEDITOR,
    w: 8,
    x: 2,
    y: 0,
  },
  {
    actionBarBG: "#91657185",
    background: "#e8e8e8",
    borderColor: "#f4f3ef",
    borderRadiusTopLeft: 6,
    borderRadiusTopRight: 6,
    borderRadiusBottomLeft: 6,
    borderRadiusBottomRight: 6,
    borderWidth: 0,
    desc: "widget.newsPostDesc",
    fontColor: "#662336",
    h: 20,
    i: "h",
    isConfigured: false,
    maxH: undefined,
    maxW: undefined,
    minH: undefined,
    minW: undefined,
    showTitleBar: false,
    static: true,
    title: "widget.newsPost",
    type: WIDGET_TYPES.NEWS_COLUMN,
    videoId: null,
    w: 2,
    x: 10,
    y: 4,
  },
];

export const ownerValues = {
  COMPANY_ROLE: "user.companyRole",
  COMPANY: "cooperation.companyBestallare",
  WORKSPACE: "workspace.workspace",
  WORKSPACE_ROLE: "sorting.siteRole",
  USER: "orderHistorySearch.user",
};

export const updateBorderRadius = ({ widget, position }) => {
  switch (position) {
    case "topLeftAdd":
      widget.borderRadiusTopLeft += 1;
      break;

    case "topLeftMinus":
      if (widget.borderRadiusTopLeft > 0) {
        widget.borderRadiusTopLeft -= 1;
      }
      break;

    case "topRightAdd":
      widget.borderRadiusTopRight += 1;
      break;

    case "topRightMinus":
      if (widget.borderRadiusTopRight > 0) {
        widget.borderRadiusTopRight -= 1;
      }
      break;

    case "bottomLeftAdd":
      widget.borderRadiusBottomLeft += 1;
      break;

    case "bottomLeftMinus":
      if (widget.borderRadiusBottomLeft > 0) {
        widget.borderRadiusBottomLeft -= 1;
      }
      break;

    case "bottomRightAdd":
      widget.borderRadiusBottomRight += 1;
      break;

    case "bottomRightMinus":
      if (widget.borderRadiusBottomRight > 0) {
        widget.borderRadiusBottomRight -= 1;
      }
      break;

    default:
      console.warn("** Unhandled position:", position);
      break;
  }
};

export const updateBorderWidth = ({ widget, action }) => {
  switch (action) {
    case "add":
      widget.borderWidth += 1;
      break;

    case "subtract":
      if (widget.borderWidth > 0) {
        widget.borderWidth -= 1;
      }
      break;

    default:
      console.warn("** Unhandled action:", action);
      break;
  }
};
import React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import SortSelect from "../FormControl/SelectSort";
import Pagination from "../Pagination";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
    paddingBottom: 5,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      paddingBottom: 10,
    },
  },
}));

const ResourcesHeader = ({
  ascending,
  onPageChange,
  onPageSizeChange,
  page,
  pageSize,
  setAscending,
  setSortfield,
  sortfield,
  sortFieldOptions,
  totalCount,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <SortSelect
        ascending={ascending}
        setAscending={setAscending}
        sortfield={sortfield}
        setSortfield={setSortfield}
        options={sortFieldOptions}
      />
      <Pagination
        onChange={onPageSizeChange}
        onPageChange={onPageChange}
        page={page}
        perPage={pageSize}
        totalCount={totalCount}
      />
    </Box>
  );
};

export default ResourcesHeader;

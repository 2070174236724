import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TableRow, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PrimaryButton, FormattedDate } from "../../common";
import { useIncomingSupplierRequest } from "../../../hooks";

const useStyles = makeStyles(() => ({
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
}));

const ConnectedWorksapceRow = ({
  workspace,
  getCompanySupplierSettings,
  companyId,
}) => {
  const classes = useStyles();

  const { workspace: siteName, requestedAt, supplierRequestId } = workspace;
  const { execute: handleRequest } = useIncomingSupplierRequest({
    supplierRequestId,
    getCompanySupplierSettings,
    companyId,
  });

  return (
    <TableRow>
      <TableCell className={classes.td}>{siteName}</TableCell>
      <TableCell className={classes.td}>
        <FormattedDate value={requestedAt} />
      </TableCell>
      <TableCell className={classes.td} align="right">
        <PrimaryButton onClick={() => handleRequest("ACCEPT")}>
          <FormattedMessage id="btn.approve" />
        </PrimaryButton>
        <PrimaryButton marginLeft={7} onClick={() => handleRequest("REJECT")}>
          <FormattedMessage id="btn.reject" />
        </PrimaryButton>
      </TableCell>
    </TableRow>
  );
};

ConnectedWorksapceRow.defaultProps = {
  workspace: null,
};

ConnectedWorksapceRow.propTypes = {
  workspace: PropTypes.shape({
    workspace: PropTypes.string,
    requestedAt: PropTypes.string,
    supplierRequestId: PropTypes.number,
  }),
};

export default ConnectedWorksapceRow;

import { useEffect, useRef, useState, useMemo } from "react";
import { Box } from "@mui/material";
import JoditTextEditor from "../../common/JoditTextEditor";
import { makeStyles } from '@mui/styles';
import { fontList } from "../../common/JoditTextEditor/helper";

const useStyles = makeStyles({
  editorBorder: {
    '& .jodit-wysiwyg': {
      border: '2px solid #000',
      padding: '10px',
    },
  },
});

const DescriptionField = ({ disabled, editable, onChange, onDirty, reset, value }) => {
  const classes = useStyles();
  const isPastingRef = useRef(false);
  const blurTimeoutRef = useRef(null);

  const [state, setState] = useState({
    dirty: false,
    editing: false,
    editValue: value,
    undoValue: undefined
  });

  const stateRef = useRef();

  stateRef.current = state;

  const hasChanged = (newValue) => (newValue !== value);

  const handleCancel = () => {
    const { dirty, undoValue } = stateRef.current;

    const changed = hasChanged(undoValue);

    setState((prev) => ({
      ...prev,
      dirty: changed,
      editing: false,
      editValue: undoValue,
    }));

    if (dirty !== changed) {
      onDirty?.({ dirty: changed });
    }
  };

  const handleSave = (newValue) => {
    // Handle save logic
    console.log("Saved content:", newValue);
  };

  const handleBlur = (newValue) => {
    if (isPastingRef.current) return;

    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current);
    }

    blurTimeoutRef.current = setTimeout(() => {
      const changed = hasChanged(newValue);

      setState((prev) => ({
        ...prev,
        dirty: changed,
        editing: false,
        editValue: newValue,
      }));

      if (changed) {
        onChange?.({ value: newValue });
      }
    }, 50);
  };

  const handleChange = (newValue) => {
    const { dirty, editing } = stateRef.current;

    if (editing) {
      const changed = hasChanged(newValue);

      setState((prev) => ({
        ...prev,
        dirty: changed,
        editValue: newValue,
      }));

      if (dirty !== changed) {
        onDirty?.({ dirty: changed });
      }
    }
  };

  const handleClick = (e) => {
    const { editing, editValue } = stateRef.current;

    if (editable && !editing && !disabled) {
      e.preventDefault();
      e.stopPropagation();

      setState((prev) => ({
        ...prev,
        editing: true,
        undoValue: editValue,
      }));
    }
  };

  const handleDoubleClick = (e) => {
    e.preventDefault();
  };

  const handlePaste = () => {
    isPastingRef.current = true;
    setTimeout(() => {
      isPastingRef.current = false;
    }, 100);
  };

  useEffect(() => {
    if (reset) {
      setState({
        dirty: false,
        editing: false,
        editValue: value,
        undoValue: undefined,
      });
    }
  }, [reset, value]);

  useEffect(() => {
    return () => {
      if (blurTimeoutRef.current) {
        clearTimeout(blurTimeoutRef.current);
      }
    };
  }, []);

  const placeholder = "Uppgiftens beskrivning";

  const joditConfig = useMemo(
    () => ({
      autofocus: true,
      inline: !state.editing,
      placeholder: placeholder || "Start writing...",
      readonly: !state.editing,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      style: {
        padding: '4px',
        maxHeight: '300px',
      },
      toolbar: state.editing,
      uploader: {
        insertImageAsBase64URI: true,
      },
      useSearch: false,
      zIndex: 5,
      controls: {
        font: {
          list: fontList,
        },
      },
      extraIcons: {
        someIcon: `<span class="fas fa-tint-slash" />`,
      },
      extraButtons: [
        {
          icon: "someIcon",
          tooltip: "Makes background transparent",
          exec: function (editor) {
            editor.execCommand("backColor", false, "#FFFFFF00");
          },
        },
        {
          name: "Save content",
          icon: "save",
          tooltip: "Save current content",
          exec: function (e) {
            const { value } = e;
            handleSave(value);
          },
        },
        {
          name: "Avbryt",
          icon: "cancel",
          tooltip: "Avbryt redigering",
          exec: function (e) {
            handleCancel();
          },
        },
      ],
      className: 'jodit-editor__editable',
      events: {
        paste: handlePaste,
        dblclick: handleDoubleClick,
      },
    }),
    [state, placeholder, handleCancel]
  );

  const displayPlaceholder = !state?.editing && ((state?.editValue || "").trim().length === 0);
  const displayValue = displayPlaceholder ? placeholder : state?.editValue;

  const ifEditable = (o) => (editable ? o : undefined);
  const ifEditing = (o) => (!state?.editing ? o : undefined);

  return (<Box
      component="div"
      key="jodit"
      onClick={handleClick}
      sx={{
        border: "1px solid transparent",
        borderRadius: "4px",
        //paddingLeft: ifEditing("2px"),
        "&:focus": ifEditable({
          border: "1px solid rgba(0, 0, 0, 0.5)",
          cursor: "default",
        }),
        "&:hover": ifEditable({
          backgroundColor: '#f0f0f0',
          borderRadius: "4px",
          cursor: "text",
        }),
        "& p": {
          opacity: displayPlaceholder ? 0.5 : undefined,
        },
      }}
    >
      <JoditTextEditor
        onBlur={handleBlur}
        onCancel={handleCancel}
        //onSave={handleSave}
        placeholder={placeholder}
        readonly={!state.editing}
        value={displayValue}
        config={joditConfig}
      />
    </Box>
  );
};

export default DescriptionField;

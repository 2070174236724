import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { LoadingScreen } from "../common";

export const SigninSilent = () => (
  <AuthConsumer>
    {({ signinSilent }) => {
      signinSilent();
      return <LoadingScreen />;
    }}
  </AuthConsumer>
);

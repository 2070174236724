import { gql } from '@apollo/client';

const ProductFragment = gql`
  fragment ProductFields on Product {
    articleNo
    breadcrumb {
      folders {
        folderId
        name
      }
      siteId
      siteName
    }

    changedBy
    changedDate
    consented
    customMetadata {
      display
      label
      value
    }
    description
    detailedDescription
    downloadedBy
    downloadedDate
    fileExtension
    format
    id
    imageMetadata {
      contact
      date
      imageNo
      isFreeToUser
      location
      model
      occupation
      photographer
    }
    isEditableFromSearchResult
    locked
    material
    minimumPrice
    minimumQuantity
    name
    numPages
    pricesInclusiveFreight
    productDisplaySettings {
      showFormat
      showNumPages
      showPrice
      showStockBalance
    }
    productImageUri
    productManager
    productResponsible
    productType
    quantities {
      selectionType
      valuesList
    }
    size
    statistics {
      downloadCount
      shareCount
      viewCount
    }
    stock {
      refillThreshold
      stockBalance
      temporaryOutOfStock
    }
    tags {
      tagId
      tagName
    }
    thumbnailImageUri
    unit
    uploadedBy
    uploadedDate
    userPermissions {
      addToImageCart
      download
      order
      share
    }
    vatPercentage
    weight
    versionNo
  }
`;

export default ProductFragment;

/**
 * Identifiers for the different sections of the Terms of Use page.
 * @enum {string}
 */
export const TERMS_OF_USE_SECTION_ID = {
  /** Identifier for section 'Privacy Policy'. */
  PrivacyPolicy: "privacy-policy",
  /** Identifier for section 'Terms of Use'. */
  TermsOfUse: "terms-of-use",
  /** Identifier for section 'Cookies'. */
  Cookies: "cookies",
};

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CustomSelect from "../../common/CustomSelect";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import TextAreaFull from "../../common/FormControl/TextAreaFull";

const useStyles = makeStyles(theme => ({
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #e6e6e6",
    marginBottom: 10,
    "& .MuiInputBase-root": {
      marginTop: 5,
    },
    "& .MuiTextField-root": {
      marginTop: 0,
    },
    "& .MuiCheckbox-root": {
      padding: "4px 9px",
    },
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginRight: 15,
  },
  labelWrap: {
    display: "flex",
    alignItems: "center",
    "& .MuiTextField-root": {
      width: "auto",
      marginLeft: 15,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  subLabel: {
    fontSize: 12,
    marginLeft: "auto",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  grayLink: {
    fontSize: 12,
    color: "#555",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  blueLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  child: {
    paddingLeft: 27,
  },
  headingWrap: {
    marginBottom: 10,
    "& .MuiFormControl-root": {
      width: 240,
    },
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#F5F5F5",
    height: 2,
  },
  inlineWrap: {
    "& .MuiFormControl-root": {
      width: 200,
    },
    "& $text": {
      marginRight: 15,
    },
  },
}));

const Coupling = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.card}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box
                className={classes.headingWrap}
                display="flex"
                alignItems="center"
              >
                <Typography className={classes.heading}>Coupling</Typography>
                <CustomSelect options={[{ label: "0", value: 1 }]} />
              </Box>
            </Grid>
          </Grid>
          <Box mt={2} mb={1} />
          <Typography className={classes.label}>
            Production locations available:
          </Typography>
          <Box mb={2} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Eskilstuna" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Gavle" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Gothenburg" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Halmstad" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Jonkoping" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Kalmar" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Eskilstuna" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Gavle" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Gothenburg" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Halmstad" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Jonkoping" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Kalmar" />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Typography className={classes.label}>Mode of delivery</Typography>
          <Box mb={2} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Bid" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Pulled" />
            </Grid>
            <Grid item xs={12} md={2}>
              <CheckboxDefault label="Post" />
            </Grid>
          </Grid>
          <Box mb={1} />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <Box
                className={classes.inlineWrap}
                display="flex"
                alignItems="center"
              >
                <Typography className={classes.text}>Standardsätt</Typography>
                <CustomSelect options={[{ label: "Bid", value: 1 }]} />
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControlLabel
                control={<Switch size="small" color="primary" />}
                label="View in cart"
                classes={{ label: classes.text }}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Typography className={classes.label}>
            Default info added to Ado's message box
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextAreaFull rows="5" rowsMax="5" />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Coupling;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_FOLDER_DATA } from "../../../graphql/queries";
import WidgetLabel from "../WidgetLabel";
import FolderItem from "./FolderItem";

const SiteFolderWidget1 = ({
  label,
  rootFolderId,
  siteId,
  setOpenedNodes,
  setFolderId,
}) => {
  const [folders, setFolders] = useState([]);

  const { data } = useQuery(GET_FOLDER_DATA, {
    variables: {
      siteId,
      folderId: rootFolderId,
      // TODO: folderLevel hardcoded to 2, to get the folders
      folderLevel: 2,
    },
    skip: !siteId || !rootFolderId,
  });

  useEffect(() => {
    if (data && data.widgets && data.widgets.getFolderData) {
      setFolders(data.widgets.getFolderData);
    } else {
      setFolders([]);
    }
  }, [data]);

  const handleFolderClick = ({ folder }) => {
    setFolderId(folder.folderId);
    setOpenedNodes([rootFolderId, folder.folderId]);
  };

  return (
    <Box>
      <WidgetLabel title={label} />
      <Box>
        <Grid container>
          {folders.map(folder => (
            <FolderItem folder={folder} handleFolderClick={handleFolderClick} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

SiteFolderWidget1.defaultProps = {
  rootFolderId: null,
  siteId: null,
};

SiteFolderWidget1.propTypes = {
  label: PropTypes.string.isRequired,
  rootFolderId: PropTypes.number,
  siteId: PropTypes.number,
  setOpenedNodes: PropTypes.func.isRequired,
  setFolderId: PropTypes.func.isRequired,
};

export default SiteFolderWidget1;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { Box, Select, MenuItem } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Checkbox from "../../common/FormControl/Checkbox";
import { PrimaryButton } from "../../common";
import {
  setFileviewVisibleFields,
  setRightColumnVisibility,
} from "../../../actions";
import { FILEVIEW_RIGHT_COLUMN } from "../../../actions/constants";
import { getFileViewFields } from "../../../helpers/fileViewSelectors";
import { UPDATE_USER_FILE_VIEW_SETTINGS } from "../../../graphql/mutations";

const { HIDE, SHOW_MESSAGES, SHOW_NEWS } = FILEVIEW_RIGHT_COLUMN;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  checkboxSection: {
    display: "flex",
    flexWrap: "wrap",
    padding: 15,
  },
  section2: {
    padding: "0 15px 15px 15px",
  },
  checkboxItem: {
    width: 120,
    marginRight: 10,
  },
  modalFooter: {
    padding: 15,
    borderTop: "1px solid #ddd",
  },
  formControl: {
    fontSize: 12,
    padding: "8px 12px",
    height: "100%",
    color: "#66615b",
    width: 150,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    backgroundColor: theme.palette.common.white,
  },
}));

const checkBoxes = [
  { name: "nameColumn", translationKey: "product.name" },
  { name: "descriptionColumn", translationKey: "product.description" },
  { name: "uploadedColumn", translationKey: "product.uploaded" },
  { name: "downloadedColumn", translationKey: "product.downloaded" },
  { name: "editedColumn", translationKey: "product.edited" },
  { name: "versionColumn", translationKey: "product.version" },
  { name: "typeColumn", translationKey: "product.type" },
  { name: "sizeColumn", translationKey: "product.size" },
];

const selectOptions = [
  { value: HIDE, labelKey: "sidebar.hide" },
  { value: SHOW_MESSAGES, labelKey: "sidebar.showMessages" },
  { value: SHOW_NEWS, labelKey: "sidebar.showNews" },
];

const ModalBody = ({ toggle }) => {
  const classes = useStyles();
  const alert = useAlert();

  const [saveUserFileViewSettings] = useMutation(
    UPDATE_USER_FILE_VIEW_SETTINGS
  );

  const fileViewFields = useSelector(getFileViewFields);
  const rightColumn = useSelector(state => state.ui.fileView.rightColumn);
  const siteId = useSelector(state => state.ui.toggleWorkspaces.workspace.id);

  const [fields, setFields] = useState({
    nameColumn: true,
    descriptionColumn: true,
    uploadedColumn: true,
    downloadedColumn: true,
    editedColumn: true,
    versionColumn: true,
    typeColumn: true,
    sizeColumn: true,
  });
  const [sidebarValue, setSidebarValue] = useState(HIDE);
  const dispatch = useDispatch();

  useEffect(() => {
    setFields(fileViewFields);
  }, [fileViewFields]);

  useEffect(() => {
    setSidebarValue(rightColumn);
  }, [rightColumn]);

  // eslint-disable-next-line no-shadow
  const onCheckboxChange = ({ name }) => e => {
    setFields({
      ...fields,
      [name]: e.target.checked,
    });
  };

  const onSubmit = async () => {
    try {
      const update = {
        columnSettings: fields,
        sidebarSetting: sidebarValue,
        siteId,
      };

      const res = await saveUserFileViewSettings({ variables: { update } });
      if (
        res &&
        res.data &&
        res.data.siteSettingsContext &&
        res.data.siteSettingsContext.updateUserFileViewSettings
      ) {
        const {
          success,
        } = res.data.siteSettingsContext.updateUserFileViewSettings;
        if (success) {
          alert.success(<FormattedMessage id="fileView.savedColumnSettings" />);
          dispatch(
            setFileviewVisibleFields({
              fields,
            })
          );
          dispatch(
            setRightColumnVisibility({
              value: sidebarValue,
            })
          );
          if (typeof toggle === "function") {
            toggle();
          }
        } else {
          alert.error(<FormattedMessage id="fileView.errorColumnSettings" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleSidebarChange = e => {
    setSidebarValue(e.target.value);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.checkboxSection}>
        {checkBoxes.map(x => {
          return (
            <Box className={classes.checkboxItem} key={x.name}>
              <Checkbox
                label={<FormattedMessage id={x.translationKey} />}
                onChange={onCheckboxChange({ name: x.name })}
                name={x.name}
                value={fields[x.name]}
              />
            </Box>
          );
        })}
      </Box>
      <Box className={classes.section2}>
        <Select
          value={sidebarValue}
          onChange={handleSidebarChange}
          variant="outlined"
          classes={{ select: classes.formControl }}
        >
          {selectOptions.map(x => (
            <MenuItem
              key={x.value}
              value={x.value}
              className={classes.menuItem}
            >
              <FormattedMessage id={x.labelKey} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={classes.modalFooter} align="right">
        <PrimaryButton onClick={onSubmit}>
          <FormattedMessage id="btn.saveFields" />
        </PrimaryButton>
      </Box>
    </Box>
  );
};

ModalBody.propTypes = {};

ModalBody.defaultProps = {};

ModalBody.propTypes = {};

export default ModalBody;

import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Grid, Icon, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from "@mui/styles";
import {
  DraggableModal,
  DraggableModalBody,
  DraggableModalHead,
  CustomSelect,
  TextAreaFull
} from "../../common";
import EmailChipInput from "./EmailChipInput";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  label: {
    fontSize: 12,
  },
  labelMargin: {
    margin: "5px 0",
  },
  btnShareNext: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnLink: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}33`,
    },
  },
  btnNext: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  btnDisabled: {
    opacity: 0.8,
    "&.$Mui-disabled": {
      color: theme.palette.primary.contrastText,
    },
  },
  outerGrid: {
    "& .MuiGrid-container": {
      marginTop: "1em",
    },
  }
}));

const InviteUserModal = ({
  handleClose,
  handleOpenHelpUser,
  open,
  setSiteUserComment,
  setSiteUserEmails,
  setSiteUserRole,
  siteRoleOptions,
  siteUserComment,
  siteUserEmails,
  siteUserRole,
  isShareModal,
}) => {
  const classes = useStyles();

  const handleSetSiteUserRole = e => {
    setSiteUserRole(e.target.value);
  };

  const isValidInput = siteUserRole && Array.isArray(siteUserEmails) && (siteUserEmails.length > 0);

  const [isCommentVisible, setisCommentVisible] = useState(false);

  const handleCommentClick = () => {
    setisCommentVisible((prevVisibility) => !prevVisibility);
  };
 
  return (
    <>
      <DraggableModal
        customClass={classes.modal}
        handleClose={handleClose}
        open={open}
      >
        <DraggableModalHead
          handleClose={handleClose}
          title={isShareModal ? <FormattedMessage id="btn.share" /> : <FormattedMessage id="btn.inviteUsers" />}
        />
        <DraggableModalBody>
          <Grid container xs={12}  className={classes.outerGrid}>

            <Grid item container>
              {/*<Grid item md={6} xs={12} className={classes.label}>*/}
              {/*  */}{/*<FormattedMessage id="siteUsers.enterEmailAddress" />*/}
              {/*</Grid>*/}
              <Grid item md={12} xs={12} className={classes.label} align="right">
                <FormattedMessage id="siteUsers.seperateWithComma" />
              </Grid>
              <Grid item xs={12}>
                <EmailChipInput value={siteUserEmails} setChips={setSiteUserEmails} label={<FormattedMessage id="siteUsers.enterEmailAddress" />} />
              </Grid>
            </Grid>

            <Grid item container>
              <Typography className={classes.label}>
                <FormattedMessage id="siteUsers.specifyRole" />
              </Typography>
              <CustomSelect
                customClass={classes.labelMargin}
                onChange={handleSetSiteUserRole}
                options={siteRoleOptions}
                value={siteUserRole}
              />
            </Grid>

            {(siteUserEmails.length > 0) && (
              <Grid item container>
                <Button variant="text" onClick={handleCommentClick} startIcon={<AddIcon />} className={classes.label} sx={{ textTransform: 'none' }}><FormattedMessage id="siteUsers.addAComment" /></Button>
                {isCommentVisible && (
                  <TextAreaFull
                    maxRows={3}
                    minRows={3}
                    onChange={e => setSiteUserComment(e.target.value)}
                    value={siteUserComment}
                  />
                )}
              </Grid>
            )}

            {!isShareModal && (
              <Grid item container>
                <Button
                  classes={{ disabled: classes.btnDisabled }}
                  className={classes.btnNext}
                  disabled={!isValidInput}
                  fullWidth
                  onClick={() => handleOpenHelpUser()}
                >
                  <FormattedMessage id="btn.next" />
                  <Icon className={clsx(["fa fa-long-arrow-alt-right", classes.btnIcon])} />
                </Button>
              </Grid>
            )}

            {isShareModal && (
              <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item justify={"flex-start"}>
                  <Button className={classes.btnLink}>
                    <Icon className={clsx(["fa fa-link", classes.btnIcon])} />
                    <FormattedMessage id="Kopiera l�nk" />
                  </Button>
                </Grid>
                <Grid item justify={"flex-end"}>
                  <Button
                    classes={{ disabled: classes.btnDisabled }}
                    className={classes.btnShareNext}
                    disabled={!isValidInput}
                    onClick={() => handleOpenHelpUser()}
                  >
                    <FormattedMessage id="btn.next" />
                    <Icon className={clsx(["fa fa-long-arrow-alt-right", classes.btnIcon])} />
                  </Button>
                </Grid>
              </Grid>
            )}


          </Grid>
        </DraggableModalBody>
      </DraggableModal>
    </>
  );
};

InviteUserModal.defaultProps = {
  handleOpenHelpUser: null,
  open: false,
  setSiteUserComment: null,
  setSiteUserEmails: null,
  setSiteUserRole: null,
  siteRoleOptions: [],
  siteUserComment: "",
  siteUserEmails: [],
  siteUserRole: null,
  isShareModal: false,
};

InviteUserModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOpenHelpUser: PropTypes.func,
  open: PropTypes.bool,
  setSiteUserComment: PropTypes.func,
  setSiteUserEmails: PropTypes.func,
  setSiteUserRole: PropTypes.func,
  siteRoleOptions: PropTypes.arrayOf(PropTypes.shape({})),
  siteUserComment: PropTypes.string,
  siteUserEmails: PropTypes.arrayOf(PropTypes.string),
  siteUserRole: PropTypes.number,
  isShareModal: PropTypes.bool,
};

export default InviteUserModal;

import React from "react";
import { Paper, Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import MySharesTable from "../../components/MyShares/MySharesTable";

export default function SharedByMeTab({
  items,
  classes,
  onDeleteItem,
  onOpenItem,
  loading,
  disabled,
}) {
  return (
    <Paper className={classes.paper} elevation={0}>
      <Box className={classes.paperHead}>
        <Typography variant="h1" component="h2" className={classes.title}>
          <FormattedMessage id="myShares.title.sharedByMe" />
        </Typography>
      </Box>
      <Box className={classes.paperBody}>
        <MySharesTable
          items={items}
          withRecipientsColumn
          onDeleteItem={onDeleteItem}
          onOpenItem={onOpenItem}
          loading={loading}
          disabled={disabled}
        />
      </Box>
    </Paper>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import WorkspaceDashboard from "../../components/WorkspaceDashboard";
import { getSiteId } from "../../helpers/selectors";

const WorkspaceDashboardPage = () => {
  const siteId = useSelector(state => getSiteId(state));

  return <WorkspaceDashboard siteId={siteId} />;
};

export default WorkspaceDashboardPage;

import React from "react";
import Grid from "@mui/material/Grid";

const PageTabPanel = ({ spacing, customClass, children }) => {
  return (
    <div>
      <Grid container spacing={spacing} className={customClass}>
        {children}
      </Grid>
    </div>
  );
};

PageTabPanel.propTypes = {};

export default PageTabPanel;

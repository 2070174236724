import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const INIT_ATTACHMENT_DOWNLOAD = gql`
  mutation InitAttachmentDownload($attachmentId: ID!) {
    messageAttachmentContext {
      initAttachmentDownload(attachmentId: $attachmentId) {
        errorResult {
          ...ErrorResultFields
        }
        success
        url
      }
    }
  }
  ${ErrorResultFragment}
`;

export default INIT_ATTACHMENT_DOWNLOAD;

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    "& .MuiTableCell-sizeSmall": {
      padding: "2px 2px 2px 2px",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonAdd: {
    color: "#2e7d32",
    minWidth: "20px",
    marginLeft: "8px",
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#fff",
    },
  },
  buttonRemove: {
    color: "#dc004e",
    minWidth: "20px",
    paddingLeft: "0px",
    paddingRight: "0px",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#fff",
    },
  },
}));

const invoiceTblHeaders = [
  { label: "Artikel", numeric: true, align: "left" },
  { label: "Benämning", numeric: true, align: "left" },
  { label: "Projekt", align: "left" },
  { label: "Antal", align: "right" },
  { label: "Enhet", align: "left" },
  { label: "A-pris", align: "right" },
  { label: "Rabatt", align: "right" },
  { label: "Summa exkl. moms", align: "right" },
];

const InvoiceTable = ({ rows, onAddRow }) => {
  const classes = useStyles();
  const [unit, setUnit] = React.useState("");

  const handleChange = event => {
    setUnit(event.target.value);
  };

  const standardFormatProps = {
    size: "small",
    variant: "standard",
    InputProps: { disableUnderline: false },
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table
        className={classes.table}
        aria-label="Invoice rows"
        elevation={0}
        size="small"
      >
        <TableHead elevation={0}>
          <TableRow>
            {invoiceTblHeaders.map(h => {
              return <TableCell align="{h.align}">{h.label}</TableCell>;
            })}

            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody elevation={0}>
          {rows &&
            rows.map((row, i) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="left">
                  <TextField
                    defaultValue={row.description}
                    {...standardFormatProps}
                  />
                </TableCell>
                <TableCell align="left">
                  <TextField
                    defaultValue={"projekt"}
                    {...standardFormatProps}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    inputProps={{ style: { textAlign: "right" } }}
                    {...standardFormatProps}
                    defaultValue={row.amount}
                  />
                </TableCell>
                <TableCell align="left">
                  <FormControl
                    className={classes.formControl}
                    {...standardFormatProps}
                    inputProps={{
                      style: { textAlign: "left", disableUnderline: true },
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={row.unit}
                      onChange={handleChange}
                      {...standardFormatProps}
                      disableUnderline
                    >
                      <MenuItem value={"PIECES"}>St</MenuItem>
                      <MenuItem value={"GIGA_BYTES"}>Gigabyte</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  <TextField
                    inputProps={{
                      style: { textAlign: "right", disableUnderline: true },
                    }}
                    defaultValue={row.price}
                    {...standardFormatProps}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    inputProps={{
                      style: { textAlign: "right", disableUnderline: true },
                    }}
                    defaultValue={0}
                    {...standardFormatProps}
                  />
                </TableCell>
                <TableCell align="right">{row.amount * row.price}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="text"
                    className={classes.buttonAdd}
                    onClick={() => {
                      onAddRow(row, i);
                    }}
                    startIcon={<AddCircleOutlineIcon />}
                  />
                  <Button
                    variant="text"
                    className={classes.buttonRemove}
                    startIcon={<RemoveCircleOutlineIcon />}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;

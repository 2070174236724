import { gql } from '@apollo/client';


const REMOVE_AUTHENTICATOR = gql`
  mutation removeAuthenticator($password:String!){
	userAccountContext{
		removeAuthenticator(password:$password)
	}
}`;

export default REMOVE_AUTHENTICATOR;

// useAuthenticatorSetup.js
import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_AUTHENTICATOR_SECRET, VERIFY_SECRET } from "../../graphql/queries";
import { UPDATE_AUTHENTICATOR_SECRET } from "../../graphql/mutations";

const useAuthenticatorSetup = () => {
    const [secret, setSecret] = useState();
    const [verifyResult, setVerifyResult] = useState();
    const [updateResult, setUpdateResult] = useState();

    const [getSecret, { data, loading, error }] = useLazyQuery(GET_AUTHENTICATOR_SECRET);
    const [verifySecret, { data: verifyData, loading: verifyloading, error: verifyError }] = useLazyQuery(VERIFY_SECRET);
    const [updateSecret, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_AUTHENTICATOR_SECRET);

    useEffect(() => {
        if (!secret) {
            getSecret();
        }
    }, [secret, getSecret]);

    useEffect(() => {
        if (data?.userAccountContext?.getAuthenticatorSecret) {
            setSecret(data.userAccountContext.getAuthenticatorSecret);
        }
    }, [data, loading, error]);


    useEffect(() => {

        if (verifyData?.userAccountContext?.verifySecret) {
            setVerifyResult(true);
        } else if (verifyError) {
            setVerifyResult(false);
		}

    }, [verifyData, verifyloading, verifyError]);



    useEffect(() => {

        if (updateData?.userAccountContext?.updateAuthenticatorSecret) {
            setUpdateResult(true);
        }
        else if (updateError) {
            setUpdateResult(false);
        }

    }, [updateData, updateLoading, updateError])

    const handleVerifySecret = (otp) => {
        verifySecret({ variables: { secret, totpCode: otp } });
    };

    const handleUpdateSecret = () => {
        
        updateSecret({ variables: { secret } });
    };

    return {
        secret,
        loading,
        verifyResult,
        verifyloading,
        updateResult,
        updateLoading,
        verifyError,
        updateError,
        handleVerifySecret,
        handleUpdateSecret,
    };
};

export default useAuthenticatorSetup;
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import CustomSelect from "../../components/common/CustomSelect";
import WorkspaceSelector from "../../components/common/WorkspaceSelector";

const useStyles = makeStyles(theme => ({
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  btn: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    minWidth: "unset",
    borderRadius: 4,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 30,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputLabel-formControl": {
      fontSize: 12,
      top: -7,
    },
    "& .MuiInputLabel-formControl.Mui-focused": {
      top: 0,
    },
  },
  popper: {
    width: 280,
  },
  btnDisabled: {
    opacity: 0.8,
    "&.$Mui-disabled": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const SiteUserHeader = ({
  options,
  selectedSite,
  selectedWorkspaceName,
  handleSetSiteId,
  handleOpenInviteUser,
  handleOpenManageRoles,
  filterOptions,
  filter,
  setFilter,
  siteRolesRights,
}) => {
  const classes = useStyles();
  const [openWorkspaceSelector, setOpenWorkspaceSelector] = useState(false);
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );

  const { canInviteUsers, /*canManageSite,*/ canManageSiteRoles } =
    siteRolesRights || {};

  const handleToggle = () => {
    setOpenWorkspaceSelector(prevState => !prevState);
  };

  const onClick = value => {
    handleSetSiteId(value);
    setOpenWorkspaceSelector(false);
  };

  const showInvite = canInviteUsers;
  const showManageRoles = canManageSiteRoles;

  return (
    <Box className={classes.paperHead}>
      <div className={classes.popper}>
        <WorkspaceSelector
          workspaces={workspaces}
          workspaceName={selectedWorkspaceName}
          open={openWorkspaceSelector}
          handleToggle={handleToggle}
          searchable
          setOpen={setOpenWorkspaceSelector}
          onClick={onClick}
        />
      </div>
      {showInvite && (
        <Button
          className={classes.btn}
          classes={{ disabled: classes.btnDisabled }}
          onClick={handleOpenInviteUser}
        >
          <FormattedMessage id="btn.inviteUsers" />
          <Icon className={clsx(["fa fa-user-plus", classes.btnIcon])} />
        </Button>
      )}

      {showManageRoles && (
        <Button
          className={classes.btn}
          classes={{ disabled: classes.btnDisabled }}
          onClick={handleOpenManageRoles}
        >
          <FormattedMessage id="btn.manageRoles" />
          <Icon className={clsx(["fa fa-user-cog", classes.btnIcon])} />
        </Button>
      )}

      <Box className={classes.selectWrap}>
        <CustomSelect
          inputLabel={<FormattedMessage id="siteUsers.filter" />}
          options={filterOptions}
          value={filter}
          onChange={e => setFilter(e.target.value)}
        />
      </Box>
    </Box>
  );
};

SiteUserHeader.defaultProps = {
  options: [],
  selectedSite: null,
  filter: "",
  siteRolesRights: {},
};

SiteUserHeader.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSite: PropTypes.number,
  handleSetSiteId: PropTypes.func.isRequired,
  handleOpenInviteUser: PropTypes.func.isRequired,
  handleOpenManageRoles: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filter: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  siteRolesRights: PropTypes.shape({}),
};

export default SiteUserHeader;

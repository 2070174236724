import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Table } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { setSelectedResources } from "../../../actions";
import { getFileViewFields, getSelectedFolder } from "../../../helpers/fileViewSelectors";
// eslint-disable-next-line import/no-cycle
import AddFolderToBasketModal from "../AddFolderToBasketModal";
// eslint-disable-next-line import/no-cycle
import ProductsTableBody from "./ProductsTableBody";
import ProductsTableHeader from "./ProductsTableHeader";
import RequestConsentModal from "./RequestConsentModal/RequestConsentModal";

const useStyles = makeStyles(theme => ({
  tableWrap: {
    overflowX: "auto",
    backgroundColor: theme.palette.common.white,
    "& tr:last-child td": {
      borderBottom: 0,
    },
  },
  th: {
    color: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "4px 5px 2px 5px",
    cursor: "pointer",
  },
  thName: {
    padding: "4px 5px 2px 0",
    cursor: "pointer",
    width: "23%",
  },
  thCheckbox: {
    padding: "4px 5px 2px 10px",
    width: 28,
  },
  thSort: {
    minWidth: 90,
  },
  thDesc: {
    minWidth: 200,
  },
  iconButtonSort: {
    padding: "2px 4px",
    color: theme.palette.common.link,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  paperPopover: {
    padding: 10,
    boxShadow: "0px 3px 12px -2px rgba(0, 0, 0, 0.24)",
  },
}));

const ProductsTable = ({
  addSelectedResource,
  addSelectedResources,
  ascending,
  error,
  folderId,
  handleError,
  handleOpenProductEditModal,
  isSearch,
  loading,
  openedNodes,
  refetchResources,
  removeSelectedResource,
  resources,
  searchValues,
  selectedResources,
  setAscending,
  setOpenMetaDataModal,
  setSearchValues,
  setSortfield,
  siteRoleRights,
  sortfield,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const fileViewFields = useSelector(getFileViewFields);
  const selectedFolder = useSelector(getSelectedFolder);

  const [consentResources, setConsentResources] = useState([]);
  const [initialFilesAddToCart, setInitialFilesAddToCart] = useState([]);
  const [openAddFolderToBasket, setOpenAddFolderToBasket] = useState(false);
  const [openRequestConsent, setOpenRequestConsent] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleClickAddFilesToCart = useCallback(files => {
    setInitialFilesAddToCart(files || []);
    setOpenAddFolderToBasket(true);
  }, []);

  const handleCloseAddFolderToBasket = () => {
    setOpenAddFolderToBasket(false);
  };

  const handleRequestConsent = (item) => {
    const itemId = item?.id;
    if (!itemId || itemId && selectedResources.find(n => n.id === itemId)) {
      setConsentResources([...selectedResources]);
    }
    else {
      setConsentResources([item, ...selectedResources]);
    }
    setOpenRequestConsent(true);
  }

  const handleSelectAllResources = e => {
    const { checked } = e.target;
    let tempResources = [];

    if (checked) {
      resources.forEach(x => {
        if (!x.locked) {
          tempResources = [...tempResources, x];
        }
      });
      dispatch(setSelectedResources({ items: tempResources }));
    } else {
      dispatch(setSelectedResources({ items: [] }));
    }
    setSelectAll(checked);
  };

  return (
    <Box className={classes.tableWrap}>
      <Table className={classes.table} size="small">
        <ProductsTableHeader
          ascending={ascending}
          fileViewFields={fileViewFields}
          handleSelectAllResources={handleSelectAllResources}
          refetchResources={refetchResources}
          searchValues={searchValues}
          selectAll={selectAll}
          setAscending={setAscending}
          setSearchValues={setSearchValues}
          setSortfield={setSortfield}
          sortfield={sortfield}
        />
        <ProductsTableBody
          addSelectedResource={addSelectedResource}
          addSelectedResources={addSelectedResources}
          error={error}
          folderId={folderId}
          handleClickAddFilesToCart={handleClickAddFilesToCart}
          handleError={handleError}
          handleOpenProductEditModal={handleOpenProductEditModal}
          isSearch={isSearch}
          loading={loading}
          onRequestConsent={handleRequestConsent}
          refetchResources={refetchResources}
          removeSelectedResource={removeSelectedResource}
          resources={resources}
          selectedResources={selectedResources}
          setOpenMetaDataModal={setOpenMetaDataModal}
          siteRoleRights={siteRoleRights}
        />
      </Table>
      {openAddFolderToBasket && (
        <AddFolderToBasketModal
          folder={selectedFolder}
          handleClose={handleCloseAddFolderToBasket}
          initialFilesAddToCart={initialFilesAddToCart}
          open={openAddFolderToBasket}
          openedNodes={openedNodes}
        />
      )}
      {openRequestConsent && (
        <RequestConsentModal
          onClose={() => setOpenRequestConsent(false)}
          open={openRequestConsent}
          selectedResources={consentResources}
        />
      )}
    </Box>
  );
};

ProductsTable.defaultProps = {
  error: false,
  isSearch: null,
  loading: false,
  openedNodes: [],
};

ProductsTable.propTypes = {
  addSelectedResource: PropTypes.func.isRequired,
  addSelectedResources: PropTypes.func.isRequired,
  ascending: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  folderId: PropTypes.number.isRequired,
  handleError: PropTypes.func.isRequired,
  handleOpenProductEditModal: PropTypes.func.isRequired,
  isSearch: PropTypes.bool,
  loading: PropTypes.bool,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  refetchResources: PropTypes.func.isRequired,
  removeSelectedResource: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      articleNo: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thumbnailImageUri: PropTypes.string.isRequired,
    })
  ).isRequired,
  searchValues: PropTypes.shape({}).isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  setAscending: PropTypes.func.isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  setSearchValues: PropTypes.func.isRequired,
  setSortfield: PropTypes.func.isRequired,
  siteRoleRights: PropTypes.shape({ fileView: PropTypes.bool }).isRequired,
};

export default ProductsTable;

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import FormattedStorageSpace from "../common/FormattedStorageSpace";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "../common/tables";

const Summary = ({ className, totalStorageSpace }) => {
  return (
    <TableContainer className={className}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <FormattedMessage id="admin.subscriptions.storageSpace.totalStorageSpace" />
            </TableCell>
            <TableCell
              align="right"
              style={{ whiteSpace: "nowrap", verticalAlign: "top" }}
            >
              <FormattedStorageSpace
                storageSpace={totalStorageSpace}
                unit="GB"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Summary.defaultProps = {
  className: {},
};

Summary.propTypes = {
  className: PropTypes.string,
  totalStorageSpace: PropTypes.number.isRequired,
};

export default Summary;

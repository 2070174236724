import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../TextField";
import DraggableModal from "../DraggableModal";
import DraggableModalHead from "../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
    width: 90,
  },
  btnLink: {
    padding: 0,
    fontSize: 13,
    textTransform: "none",
    marginLeft: 5,
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
}));

const NameModal = ({
  open,
  title,
  handleClose,
  value,
  onChange,
  onClick,
  disabled,
  placeholder,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead title={title} handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        <FormattedMessage id="common.name" />
        <CustomTextField
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnCancel} onClick={handleClose}>
            <FormattedMessage id="btn.cancel" />
          </Button>
          <Button className={classes.btn} disabled={disabled} onClick={onClick}>
            <FormattedMessage id="btn.ok" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

NameModal.defaultProps = {
  disabled: false,
  placeholder: "",
  value: "",
};

NameModal.propTypes = {
  disabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default NameModal;

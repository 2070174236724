import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ColorPicker from "../../common/ColorPicker";
import NewsSiteSettings from "./NewsSiteSettings";
import TextEditorSettings from "./TextEditorSettings";
import NewsFilterSettings from "./NewsSiteSettings/NewsFilterSettings";
import { WIDGET_TYPES } from "../../../constant/widgets";

const useStyles = makeStyles(() => ({
  formWrapper: {
    marginBottom: 8,
  },
  colorWrapper: {
    display: "flex",
    alignItems: "center",
  },
  colorPicker: {
    width: "24px!important",
  },
}));

const ContentSettings = ({
  onWidgetPropertyUpdate,
  //newsSiteBG,
  //handleNewsSiteBackground,
  selectedWidget,
}) => {
  const classes = useStyles();
  const { contentBG, titleColor, dividerColor, newsSiteFontColor, newsTemplate, type, newsFilterSetting } = selectedWidget || {};

  const showTitle =
    type === WIDGET_TYPES.WORKSPACES ||
    type === WIDGET_TYPES.NEWS_COLUMN ||
    type === WIDGET_TYPES.RECENTLY_MODIFIED_FILES;

  const showContentBackground = type !== WIDGET_TYPES.TEXTEDITOR;
  const showDividerColor = type !== WIDGET_TYPES.TEXTEDITOR;

  const ColorSelector = ({ defaultValue, messageId, property, value }) => (
    <Box className={classes.formWrapper}>
      <Box className={classes.colorWrapper}>
        <Box className={classes.colorPicker}>
          <ColorPicker
            onChange={(e) => onWidgetPropertyUpdate(property, e.hex)}
            value={value || defaultValue}
          />
        </Box>
        <FormattedMessage id={messageId} />
      </Box>
    </Box>
  );

  return (
    <>
      {showContentBackground && (
        <ColorSelector
          defaultValue={"#ffffff"}
          messageId={"widget.contentBackground"}
          property={"contentBG"}
          value={contentBG} />
      )}

      {showDividerColor && (
        <ColorSelector
          defaultValue={"#dee2e6"}
          messageId={"widget.dividerColor"}
          property={"dividerColor"}
          value={dividerColor} />
      )}

      {showTitle && (
        <ColorSelector
          defaultValue={"#212529"}
          messageId={"widget.titleTextColor"}
          property={"titleColor"}
          value={titleColor} />
      )}

      {type === WIDGET_TYPES.NEWS_COLUMN && (<>
        <ColorSelector
          defaultValue={"#000"}
          messageId={"widget.fontColor"}
          property={"newsSiteFontColor"}
          value={newsSiteFontColor} />

        <NewsFilterSettings newsFilterSetting={newsFilterSetting}
          onWidgetPropertyUpdate={onWidgetPropertyUpdate} /></>
      )}

      {type === WIDGET_TYPES.NEWS_BIG && (
        <>
          <NewsSiteSettings
            onWidgetPropertyUpdate={onWidgetPropertyUpdate}
            newsSiteFontColor={newsSiteFontColor}
            newsTemplate={newsTemplate}
          />
          <NewsFilterSettings newsFilterSetting={newsFilterSetting}
            onWidgetPropertyUpdate={onWidgetPropertyUpdate} />   </>
      )}

      {type === WIDGET_TYPES.TEXTEDITOR && (
        <TextEditorSettings
          onWidgetPropertyUpdate={onWidgetPropertyUpdate}
          selectedWidget={selectedWidget}
        />
      )}
    </>
  );
};

export default ContentSettings;

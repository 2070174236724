import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeView from "@mui/lab/TreeView";
import StructureFolder from "./StructureFolder";
import StructureItem from "./StructureItem";
import { STRUCTURE_TYPES } from "../../../constant/types";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0 0 0 5px",
  },
  icon: {
    width: "15px!important",
  },
}));

const StructureTree = ({
  handleContextMenu,
  structureTree,
  onMoveFolder,
  onMoveWorkspace,
}) => {
  const classes = useStyles();

  const renderItems = rootItems => {
    return rootItems.map((node, index) => {
      if (node.type === STRUCTURE_TYPES.FOLDER) {
        return (
          <StructureFolder
            handleContextMenu={handleContextMenu}
            key={node.id}
            node={node}
            onMoveFolder={onMoveFolder}
            onMoveWorkspace={onMoveWorkspace}
          />
        );
      } else {
        return (
          <StructureItem
            handleContextMenu={e => e.preventDefault()}
            item={node}
            key={node.id}
            onMoveFolder={onMoveFolder}
            onMoveWorkspace={onMoveWorkspace}
          />
        );
      }
    });
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
    >
      {renderItems(structureTree)}
    </TreeView>
  );
};

StructureTree.propTypes = {
  handleContextMenu: PropTypes.func.isRequired,
  onMoveFolder: PropTypes.func.isRequired,
  onMoveWorkspace: PropTypes.func.isRequired,
  structureTree: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape([])),
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default StructureTree;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Badge, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import FilePreviewContainer from "./FilePreviewContainer";

const useStyles = makeStyles(() => ({
  avatar: {
    width: 80,
    minHeight: 80,
  },
  filename: {
    fontSize: "80%",
    margin: "0 3px 2px 2px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  avatarImg: {},
  iconButtonDownload: {},
  badge: {
    padding: "2px",
    width: "100%",
  },
  anchorTopRight: {
    top: 10,
    right: 10,
  },
  previewContainer: {
    maxWidth: "160px",
    minWidth: "103px",
    overflow: "hidden",
  },
}));

const PreviewAttachment = ({ attachment, removeAttachment }) => {
  const classes = useStyles();

  const { name, url: source } = attachment;

  return (
    attachment && (
      <FilePreviewContainer className={classes.previewContainer}>
        <Badge
          classes={{
            root: classes.badge,
            anchorOriginTopRightCircle: classes.anchorTopRight,
          }}
          overlap="circle"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={
            <IconButton
              className={classes.iconButtonDownload}
              onClick={() => removeAttachment()}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <Avatar
            variant="rounded"
            src={source}
            alt="?"
            className={classes.avatar}
            classes={{
              img: classes.avatarImg,
            }}
          />
        </Badge>
        <Box className={classes.filename}>{name}</Box>
      </FilePreviewContainer>
    )
  );
};

PreviewAttachment.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    uploaded: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  removeAttachment: PropTypes.func.isRequired,
};

export default PreviewAttachment;

import { useIntl } from "react-intl";
import { Autocomplete, ListItem, TextField } from '@mui/material';
import StatusChip from "./StatusChip";
import findContrastColor from "./findContrastColor";

const listboxProps = {
  sx: {
    "&.MuiAutocomplete-listbox": {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: "300px",
      padding: "3px",
      "& .MuiAutocomplete-option": {
        padding: "3px",
        width: "auto",
        "& > div": {
          outlineColor: "inherit",
          outlineOffset: "0px",
          outlineStyle: "solid",
          outlineWidth: "0px",
        },
        "&.Mui-focused": {
          backgroundColor: "inherit",
          "& > div": {
            //outlineWidth: "1px",
          },
        },
        "&[aria-selected='true']": {
          backgroundColor: "inherit",
          "& > div": {
            //outlineWidth: "1px",
            border: "1px solid rgba(0, 0, 0, 0.3)",
          },
        },
      },
    },
  },
};

const StatusSelector = ({
  debugMode = undefined,
  disabled,
  fieldId,
  onChange,
  onClose,
  size = "small",
  statusLookup,
  sx,
  value,
}) => {
  const intl = useIntl();

  const equalityCheck = (option, value) => (option.name === value);

  const handleClose = (e) => {
    if (debugMode) {
      e.preventDefault();
      return;
    }

    onClose?.();
  };

  const handleSelectOption = (option) => {
    if (debugMode) {
      return;
    }

    if (!equalityCheck(option, value)) {
      onChange?.({ fieldId, value: option.name });
    }
  };

  return (
    <Autocomplete
      autoFocus={false}
      blurOnSelect
      disabled={disabled}
      getOptionLabel={
        (option) => option?.name || option
      }
      isOptionEqualToValue={equalityCheck}
      ListboxProps={listboxProps}
      noOptionsText={
        intl.formatMessage({ id: "project.statusSelector.noOptionsText" })
      }
      onBlur={handleClose}
      open={debugMode || undefined}
      onChange={
        (event, newValue, reason, details) => {
          if (reason === "selectOption") {
            handleSelectOption(newValue);
          }
        }
      }
      onKeyDown={
        (e) => {
          if (e?.key === "Escape") {
            handleClose(e);
          }
        }
      }
      openOnFocus
      options={statusLookup || []}
      slotProps={{
        popper: {
          placement: "bottom-start",
          style: {
            maxWidth: "265px",
          }
        }
      }}
      renderInput={
        (params) => (
          <TextField
            {...params}
            autoFocus={true}
            placeholder={
              intl.formatMessage({ id: "project.statusSelector.placeholder" })
            }
            size={size}
          />
        )
      }
      renderOption={
        (props, option) => (
          <ListItem {...props}>
            <StatusChip
              bgColor={option.color}
              color={option.textColor}
              disabled={disabled}
              label={option.name}
              size={size}
              width="300px"
            />
          </ListItem>
        )
      }
      sx={{
        ...sx,
        "&:hover": { backgroundColor: '#f0f0f0', cursor: "text", },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          border: "1px solid rgba(0, 0, 0, 0.5)",
        },
      }}
      value={value || null}
    />
  );
};

export default StatusSelector;

import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomSelect from "../CustomSelect";

const SelectFieldMandatory = ({
  propertyValuesState,
  value,
  onChange,
  name,
  ...rest
}) => {
  const options = propertyValuesState.map(x => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        id="demo-simple-select-outlined-label"
        shrink
        style={{ background: "rgb(245, 245, 245)" }}
      >
        {`${name}*`}
      </InputLabel>
      <CustomSelect
        labelWidth={100}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        options={options}
        onChange={onChange}
        {...rest}
      />
    </FormControl>
  );
};

SelectFieldMandatory.defaultProps = {
  value: "",
};

SelectFieldMandatory.propTypes = {
  propertyValuesState: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default SelectFieldMandatory;

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import { Box, Skeleton } from "@mui/material";
import { debounce } from "lodash";
import { DYNAMIC_PREVIEW } from "../../graphql/queries/dynamic-preview";
import ProductImagePreview from "./ProductModal/ProductImagePreview";

const useStyles = makeStyles(theme => ({
  imagePreviewControls: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center",
    display: "inline-block",
    borderRadius: 3,
    boxShadow: "0 1px 5px #e8e8e8",
    padding: "8px 5px",
    position: "absolute",
    bottom: 10,
    margin: "0 auto",
    right: 15,
    width: 110,
  },
  iconButton: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    textAlign: "center",
    margin: "0 12px",
    borderRadius: 0,
    padding: 0,
  },
  skeleton: {
    borderRadius: 4,
  },
  imgWrap: {
    display: "block",
    width: "50vw",
    height: "70vh",
    borderRadius: 4,
    backgroundColor: "transparent",
    margin: "0 auto",
  },
}));

const PreviewTemplate = ({ formValues, product }) => {
  const classes = useStyles();
  const [imageIndex, setImageIndex] = useState(0);

  const [getPreview, { data, loading, error }] = useLazyQuery(DYNAMIC_PREVIEW);

  const debouncedGetPreview = useCallback(
    debounce(formData => {
      getPreview({
        variables: {
          productId: product.id,
          data: formData,
        },
      });
    }, 800),
    []
  );

  useEffect(() => {
    const formData = Object.keys(formValues).map(key => {
      return { fieldName: key, value: formValues[key] };
    });

    debouncedGetPreview(formData);
  }, [formValues]);

  const handleNext = () => {
    setImageIndex(prevIndex => prevIndex + 1);
  };

  const handlePrevious = () => {
    setImageIndex(prevIndex => prevIndex - 1);
  };

  let inner;
  if (loading) {
    inner = (
      <Box className={classes.imgWrap}>
        <Skeleton
          variant="rect"
          animation="wave"
          height="100%"
          classes={{ root: classes.skeleton }}
        />
      </Box>
    );
  } else if (error) {
    inner = <p>Error</p>;
  } else if (!loading && !error && data && data.dynamicTemplatePreview) {
    inner = (
      <ProductImagePreview
        imageIndex={imageIndex}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        imageUri={data.dynamicTemplatePreview[imageIndex]}
        alt={product.name}
        minPage={0}
        maxPage={data.dynamicTemplatePreview.length - 1}
        productType="DYNAMIC_TEMPLATE"
      />
    );
  }

  return <div>{inner}</div>;
};

PreviewTemplate.propTypes = {
  formValues: PropTypes.objectOf(PropTypes.string).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PreviewTemplate;

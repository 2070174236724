import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DashboardWidget from "../../components/common/DashboardWidget";
import DashboardWidgetHead from "../../components/common/DashboardWidgetHead";
import DashboardWidgetBody from "../../components/common/DashboardWidgetBody";
import CustomSelect from "../../components/common/CustomSelect";
import Sample from "../../assets/img/checkout-img-1.png";
import NewsWidget from "../../components/News-Old/NewsWidget";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: 32,
    },
    dashboardWidgetContainer: {
      marginTop: 20,
    },
    productBox: {
      padding: 5,
      borderRadius: 6,
      "&:hover": {
        backgroundColor: "#f9f9f9",
        cursor: "pointer",
      },
    },
    productImg: {
      width: 50,
      objectFit: "cover",
      marginBottom: 10,
    },
    productName: {
      fontSize: 11,
      fontWeight: 600,
      marginBottom: 5,
    },
    productInfo: {
      fontSize: 10,
    },
    viewLink: {
      fontSize: 12,
      color: theme.palette.primary.main,
      marginLeft: "auto",
      padding: 0,
      textTransform: "none",
      fontWeight: 600,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "transparent",
        opacity: 0.8,
      },
    },
  })
);

// eslint-disable-next-line no-shadow
const Widgets = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {/* New files widget */}
          <DashboardWidget>
            <DashboardWidgetHead title="New files">
              <CustomSelect
                inputLabel="Välj arbetsyta här"
                options={[
                  { label: "Matilda", value: 1 },
                  { label: "Beata", value: 2 },
                  { label: "Klara", value: 3 },
                ]}
              />
            </DashboardWidgetHead>
            <DashboardWidgetBody>
              <Grid container spacing={2}>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </DashboardWidgetBody>
          </DashboardWidget>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.dashboardWidgetContainer}>
          {/* Most downloaded products widget */}
          <DashboardWidget>
            <DashboardWidgetHead title="Products">
              <CustomSelect
                options={[{ label: "Most downloaded", value: 1 }]}
              />
              <CustomSelect options={[{ label: "This month", value: 1 }]} />
            </DashboardWidgetHead>
            <DashboardWidgetBody>
              <Grid container spacing={2}>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={2} align="center">
                  <Box className={classes.productBox}>
                    <img src={Sample} className={classes.productImg} alt="" />
                    <Typography className={classes.productName}>
                      Dina rättigheter i Sverige 2018 - Arabiska
                    </Typography>
                    <Typography className={classes.productInfo}>
                      2020-03-30 - 2020-04-13
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </DashboardWidgetBody>
          </DashboardWidget>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.dashboardWidgetContainer}>
          {/* News widget */}
          <DashboardWidget>
            <DashboardWidgetHead title="News from sites">
              <CustomSelect options={[{ label: "All sites", value: 1 }]} />
              <Button className={classes.viewLink}>View more</Button>
            </DashboardWidgetHead>
            <DashboardWidgetBody>
              <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                  <NewsWidget />
                </Grid>
                <Grid item xs={6} md={2}>
                  <NewsWidget />
                </Grid>
                <Grid item xs={6} md={2}>
                  <NewsWidget />
                </Grid>
                <Grid item xs={6} md={2}>
                  <NewsWidget />
                </Grid>
                <Grid item xs={6} md={2}>
                  <NewsWidget />
                </Grid>
                <Grid item xs={6} md={2}>
                  <NewsWidget />
                </Grid>
              </Grid>
            </DashboardWidgetBody>
          </DashboardWidget>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Widgets;

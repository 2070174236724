import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";
import ButtonPrimaryAltSm from "../../common/ButtonPrimaryAltSm";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "800px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  btnUpload: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    textTransform: "none",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  text: {
    fontSize: 11,
    marginBottom: 15,
  },
  table: {
    border: "1px solid #f1f1f1",
  },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
  textLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  textLinkIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  textLinkDanger: {
    fontSize: 12,
    color: theme.palette.common.red,
    marginLeft: 20,
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  textLinkDangerIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    marginRight: 8,
    marginTop: 3,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#F5F5F5",
    height: 2,
  },
  uploadBox: {
    marginTop: 5,
    marginBottom: 10,
    padding: 5,
    borderRadius: 4,
    border: "1px solid #d7d7d7",
    width: "50%",
  },
  input: {
    display: "none",
  },
}));

const NewStaffListModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="List - List name" />
      <DraggableModalBody>
        <Box align="right" mb={1}>
          <Button className={classes.textLink}>
            <Icon className={clsx(["fas fa-download", classes.textLinkIcon])} />
            Download list
          </Button>
          <Button className={classes.textLinkDanger}>
            <Icon
              className={clsx(["fas fa-times", classes.textLinkDangerIcon])}
            />
            Empty list
          </Button>
        </Box>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.th}>name</TableCell>
              <TableCell className={classes.th}>title</TableCell>
              <TableCell className={classes.th}>phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.td}>My dropdown</TableCell>
              <TableCell className={classes.td} />
              <TableCell className={classes.td} />
            </TableRow>
          </TableBody>
        </Table>
        <Divider className={classes.divider} />
        <Typography className={classes.heading}>Fill in list</Typography>
        <Typography className={classes.text}>
          We support lists in Excel 97 - 2003 (.xls) and Excel 2010 (.xlsx)
          formats.
        </Typography>
        <Typography className={classes.label}>Upload a list</Typography>
        <Box className={classes.uploadBox}>
          <input
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            type="file"
          />
          <label htmlFor="icon-button-file">
            <ButtonPrimaryAltSm component="span" label="Choose file" />
          </label>
        </Box>
        <Box>
          <Button className={classes.btnUpload}>Upload list</Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewStaffListModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NewStaffListModal;

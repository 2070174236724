import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { debounce, isEmpty } from "lodash";
import SearchAutoComplete from "../../common/SearchAutoComplete";
import { SEARCH_DYNAMIC_LIST } from "../../../graphql/queries";

const SearchField = ({ product, setFormValues }) => {
  const [personList, setPersonList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [searchAutocomplete, setSearchAutoComplete] = useState(false);

  const [searchDynamicList, { data, loading }] = useLazyQuery(
    SEARCH_DYNAMIC_LIST
  );

  useEffect(() => {
    if (data && Array.isArray(data.searchDynamicList)) {
      setPersonList(data.searchDynamicList);
    }
  }, [data]);

  const debounceFetchPersonList = useCallback(
    debounce(async searchTerm => {
      searchDynamicList({
        variables: { productId: product.id, searchTerm },
      });
    }, 500),
    []
  );

  const handleOnChange = async text => {
    if (text && text.length > 2) {
      debounceFetchPersonList(text);
    }
  };

  const handleSelectUser = user => {
    if (!isEmpty(user)) {
      const { values } = user;

      let tempValues = {};

      values.forEach(x => {
        tempValues = { ...tempValues, [`t_${x.fieldName}`]: x.value };
      });

      setSelectedUser(user);
      setFormValues(tempValues);
    }
  };

  const setClose = () => {
    setSearchAutoComplete(false);
  };

  const setOpen = () => {
    setSearchAutoComplete(true);
  };
  return (
    <SearchAutoComplete
      open={searchAutocomplete}
      setOpen={setOpen}
      setClose={setClose}
      onChange={e => handleOnChange(e)}
      options={personList}
      loading={loading}
      setValue={handleSelectUser}
      value={selectedUser}
      multiple={false}
      label={<FormattedMessage id="btn.search" />}
      size="small"
    />
  );
};

SearchField.defaultProps = {
  setFormValues: null,
  product: {},
};

SearchField.propTypes = {
  setFormValues: PropTypes.func,
  product: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default SearchField;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import DateFull from "../../../../common/FormControl/DateFull";
import ExpansionDefault from "../../../../common/ExpansionDefault";
import CustomTextField from "../../../../common/TextField";
import CheckboxDefault from "../../../../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  customForm: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputAdornment-root button": {
      padding: 5,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 10,
      paddingTop: 6,
      paddingBottom: 6,
      lineHeight: "21px",
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 10,
    },
  },
  text: {
    marginTop: 0,
    marginBottom: 5,
    fontSize: 12,
    color: theme.palette.component.productBoxColor,
  },
  label: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  gramLabel: {
    float: "right",
    fontSize: 10,
    marginBottom: 3,
    marginTop: 20,
  },
  imgBox: {
    backgroundColor: theme.palette.common.white,
    padding: 15,
    marginBottom: 10,
  },
  uploadBox: {
    backgroundColor: theme.palette.background.contrastGray,
    padding: 15,
    "& p": {
      fontSize: 10,
      color: theme.palette.component.productBoxColor,
      marginTop: 0,
      textAlign: "left",
    },
  },
  buttonDrop: {
    padding: "5px 15px",
    lineHeight: 0.5,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    minWidth: "unset",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    textTransform: "none",
    marginBottom: 10,
  },
  caret: {
    width: "13px !important",
    height: "13px !important",
  },
  menuWrap: {
    backgroundColor: theme.palette.primary.main,
  },
  menuItem: {
    fontSize: 11,
    color: theme.palette.primary.contrastText,
  },
  dateWrap: {
    "& .MuiFormControl-marginNormal": {
      marginTop: 0,
    },
  },
}));

const Metadata = ({
  metadataValues,
  handleMetadataValues,
  setMetadataValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleDateChange = dateValue => {
    setMetadataValues({ ...metadataValues, date: dateValue });
  };

  const handleCheckbox = e => {
    const { name, checked } = e.target;

    setMetadataValues({ ...metadataValues, [name]: checked });
  };

  return (
    <ExpansionDefault
      label={<FormattedMessage id="metadata.metadata" />}
      panelContent="metadataContent"
      panelHeader="metadataHeader"
      defaultExpanded
    >
      <Grid container spacing={1} className={classes.customForm}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="imageNo"
                label={intl.formatMessage({
                  id: "metadata.imageNo",
                })}
                value={metadataValues.imageNo}
                onChange={handleMetadataValues}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="contact"
                label={intl.formatMessage({
                  id: "metadata.responsible",
                })}
                value={metadataValues.contact}
                onChange={handleMetadataValues}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.dateWrap}>
              <DateFull
                name="date"
                label={intl.formatMessage({
                  id: "product.date",
                })}
                value={metadataValues.date}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="photographer"
                label={intl.formatMessage({
                  id: "metadata.photographer",
                })}
                value={metadataValues.photographer}
                onChange={handleMetadataValues}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="location"
                label={intl.formatMessage({
                  id: "metadata.place",
                })}
                value={metadataValues.location}
                onChange={handleMetadataValues}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CheckboxDefault
                name="isFreeToUser"
                label={intl.formatMessage({
                  id: "metadata.royaltyFree",
                })}
                cbcolor="main"
                value={metadataValues.isFreeToUser}
                onChange={handleCheckbox}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="model"
                label={intl.formatMessage({
                  id: "metadata.model",
                })}
                value={metadataValues.model}
                onChange={handleMetadataValues}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <SelectNativeFull
                options={[{ label: "GenerelltFotografAvtal.pdf", value: 1 }]}
              /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTextField
                name="occupation"
                label={intl.formatMessage({
                  id: "metadata.profession",
                })}
                value={metadataValues.occupation}
                onChange={handleMetadataValues}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

Metadata.defaultProps = {
  metadataValues: {},
};

Metadata.propTypes = {
  metadataValues: PropTypes.shape({
    contact: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    imageNo: PropTypes.string,
    isFreeToUser: PropTypes.bool,
    legalDocument: PropTypes.string,
    location: PropTypes.string,
    model: PropTypes.string,
    occupation: PropTypes.string,
    photographer: PropTypes.string,
  }),
  setMetadataValues: PropTypes.func.isRequired,
  handleMetadataValues: PropTypes.func.isRequired,
};

export default Metadata;

import React from "react";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle, IconButton, Typography  } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  modalHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    minHeight: 47,
    padding: "5px 35px 5px 10px",
    [theme.breakpoints.down("sm")]: {
      minHeight: 40,
    },
    "& .MuiTypography-root": {
      alignItems: "center",
      display: "flex",
    },
  },
  modalTitle: {
    display: "inline-block",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 2.3,    
    margin: "0 8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "normal",
    },
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    height: "calc(16px * 2.3 + 10px)",
    margin: 0,
    position: "absolute",
    right: 5,
    [theme.breakpoints.down("sm")]: {
      height: "calc(14px * 2.3 + 8px)",
    },
  },
  closeButton: {
    color: theme.palette.common.white,
  },
}));

const DraggableModalHead = ({ children, customClass, handleClose, title }) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle
        classes={{ root: classes.modalHeader }}
        className={clsx(["draggable-dialog-title", customClass])}
      >
        <Typography className={classes.modalTitle} component="div">
          {title}
        </Typography>
        {children}
      </DialogTitle>
      <Box className={classes.buttonContainer}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default DraggableModalHead;

import { gql } from '@apollo/client';

const ShareUserLookupItemFragment = gql`
  fragment ShareUserLookupItemFragment on ShareUserLookupItemDTO {
    username
    firstname
    lastname
    __typename
  }
`;

export default ShareUserLookupItemFragment;

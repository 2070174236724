import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { Paper, Box, Typography, Button } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import WorkspaceTable from "../../components/SharedWorkspace/WorkspaceTable";
import WorkspaceCreateModal from "../../components/common/WorkspaceCreateModal";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    marginTop: 20,
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    marginRight: 10,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "15px auto",
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  paperBody: {
    padding: theme.spacing(4),
  },
}));

export default function SharedWorkspace() {
  const classes = useStyles();

  const [openWorkspaceCreate, setOpenWorkspaceCreate] = useState(false);
  const handleOpenWorkspaceCreate = () => {
    setOpenWorkspaceCreate(true);
  };
  const handleCloseWorkspaceCreate = () => {
    setOpenWorkspaceCreate(false);
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper} elevation={0}>
            <Box className={classes.paperHead}>
              <Typography variant="h1" component="h2" className={classes.title}>
                <FormattedMessage id="workspace.myShared" />
              </Typography>
              <Button
                className={classes.btnAdd}
                onClick={handleOpenWorkspaceCreate}
              >
                <FormattedMessage id="btn.add" />
                <Icon
                  className={clsx(["fa fa-plus-circle", classes.btnIcon])}
                />
              </Button>
            </Box>
            <Box className={classes.paperBody}>
              <WorkspaceTable />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <WorkspaceCreateModal
        open={openWorkspaceCreate}
        handleClose={handleCloseWorkspaceCreate}
      />
    </div>
  );
}

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_RESOURCE_DESCRIPTION = gql`
  mutation UpdateResourceDescription($resourceId: ID!, $description: String) {
    updateResourceDescription(
      resourceId: $resourceId
      description: $description
    ) {
      success
      errorResult {
        ...ErrorResultFields
      }
    }
  }

  ${ErrorResultFragment}
`;

export default UPDATE_RESOURCE_DESCRIPTION;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_MONITORING_ITEM = gql`
  mutation UpdateMonitoringItem($monitoringItemInput: MonitoringItemInput) {
    monitorContext {
      updateMonitoringItem(monitoringItem: $monitoringItemInput) {
        success
        notificationId
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }

  ${ErrorResultFragment}
`;

export default UPDATE_MONITORING_ITEM;

import React from "react";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles(theme => ({
  formControl: {
    fontSize: 12,
    padding: "9px 12px",
    height: "100%",
    color: "#66615b",
    lineHeight: "normal",
  },
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
  labelWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
  },
  fieldWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
}));

const CustomTextarea = ({ label, value, onChange }) => {
  const classes = useStyles();

  return (
    <Grid container mt={0.5} mb={0.5}>
      <Grid item xs={12} md={2} className={classes.labelWrap}>
        <span className={classes.label}>{label}</span>
      </Grid>
      <Grid item xs={12} md={10} className={classes.fieldWrap}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            variant="outlined"
            InputProps={{
              classes: { input: classes.formControl },
            }}
            multiline
            rows={4}
            rowsMax={4}
            value={value}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

CustomTextarea.defaultProps = { value: "" };

CustomTextarea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default CustomTextarea;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import { PICKING_LIST_PRINT } from "../../graphql/queries";
import TabPanel from "../TabPanel";
import PageTabs from "../PageTabs";
import NewTab from "./NewTab";
import PickTab from "./PickTab";
import HistoryTab from "./HistoryTab";
import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
    "& .MuiIcon-root": {
      display: "none",
    },
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
    "& svg": {
      display: "none",
    },
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
}));

const PickingListTabs = ({
  showColumn,
  searchValues,
  setSearchValues,
  pickingListOrders,
  setPickingListOrders,
  totalCount,
  setTotalCount,
  fetchPickingListOrders,
  loading,
  page,
  setPage,
}) => {
  const classes = useStyles();

  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const NEW_TAB = "NEW_TAB";
  const PICK_TAB = "PICK_TAB";
  const HISTORY_TAB = "HISTORY_TAB";

  const orderStatusFilters = {
    NEW_TAB: "NEW",
    PICK_TAB: "ACTIVE",
    HISTORY_TAB: "PICKED",
  };

  const [pickingListPrint, { data: printUrl }] = useLazyQuery(
    PICKING_LIST_PRINT
  );

  const [selectedNewTabOrders, setSelectedNewTabOrders] = useState([]);
  const [selectedPickTabOrders, setSelectedPickTabOrders] = useState([]);
  const [selectedHistoryTabOrders, setSelectedHistoryTabOrders] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const addSelectedOrder = (order, tab) => {
    if (tab === NEW_TAB) {
      setSelectedNewTabOrders([...selectedNewTabOrders, order]);
    } else if (tab === PICK_TAB) {
      setSelectedPickTabOrders([...selectedPickTabOrders, order]);
    } else if (tab === HISTORY_TAB) {
      setSelectedHistoryTabOrders([...selectedHistoryTabOrders, order]);
    }
  };

  const removeSelectedOrder = (order, tab) => {
    if (tab === NEW_TAB) {
      const temp = selectedNewTabOrders.filter(x => x !== order);
      setSelectedNewTabOrders(temp);
    } else if (tab === PICK_TAB) {
      const temp = selectedPickTabOrders.filter(x => x !== order);
      setSelectedPickTabOrders(temp);
    } else if (tab === HISTORY_TAB) {
      const temp = selectedHistoryTabOrders.filter(x => x !== order);
      setSelectedHistoryTabOrders(temp);
    }
  };

  const handleChangeTab = (event, newTabIndex) => {
    setSelectedTabIndex(newTabIndex);
  };

  const handleNewTabOrderPrinted = o => {
    var printedOrderId = o?.pickingListOrdersItemId;
    setSelectedPickTabOrders([printedOrderId]);
    removeSelectedOrder(printedOrderId, NEW_TAB);
  };

  const handlePicked = () => {
    fetchPickingListOrders();
    setSelectedPickTabOrders([]);
  };

  const handlePrint = () => {
    pickingListPrint({
      variables: { orderGroupId: ordergroupId, orderIds: selectedNewTabOrders },
    });
  };

  const handlePrinted = () => {
    fetchPickingListOrders();
    setSelectedPickTabOrders([...selectedNewTabOrders]);
    setSelectedNewTabOrders([]);
  };

  const handleTabSelected = tab => {
    setSearchValues({
      ...searchValues,
      sortField: "orderNo",
      pickingListOrderStatus: orderStatusFilters[tab],
    });
  };

  useEffect(() => {
    if (
      printUrl &&
      printUrl.pickingList &&
      printUrl.pickingList.pickingListPrint
    ) {
      window.open(printUrl.pickingList.pickingListPrint);
      handlePrinted();
    }
  }, [printUrl]);

  return (
    <div>
      <AppBar position="static" elevation="0" className={classes.wrap}>
        <Tabs
          value={selectedTabIndex}
          onChange={handleChangeTab}
          aria-label="Edit User"
          classes={{
            indicator: classes.indicator,
          }}
          className={classes.tabsRoot}
        >
          <PageTabs
            className={classes.pageTabs}
            onClick={() => handleTabSelected(NEW_TAB)}
            label={<FormattedMessage id="pickingList.new" />}
            value={0}
          />
          <PageTabs
            onClick={() => handleTabSelected(PICK_TAB)}
            className={classes.pageTabs}
            label={<FormattedMessage id="pickingList.pick" />}
            value={1}
          />
          <PageTabs
            onClick={() => handleTabSelected(HISTORY_TAB)}
            className={classes.pageTabs}
            label={<FormattedMessage id="pickingList.history" />}
            value={2}
          />
        </Tabs>
      </AppBar>
      {[
        <NewTab
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          loading={loading}
          fetchPickingListOrders={fetchPickingListOrders}
          pickingListOrders={pickingListOrders}
          showColumn={showColumn}
          totalCount={totalCount}
          selectedOrders={selectedNewTabOrders}
          addSelectedOrder={order => addSelectedOrder(order, NEW_TAB)}
          removeSelectedOrder={order => removeSelectedOrder(order, NEW_TAB)}
          setSelectedOrders={setSelectedNewTabOrders}
          handlePrint={handlePrint}
          page={page}
          setPage={setPage}
          onOrderPrinted={handleNewTabOrderPrinted}
        />,
        <PickTab
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          loading={loading}
          fetchPickingListOrders={fetchPickingListOrders}
          pickingListOrders={pickingListOrders}
          showColumn={showColumn}
          totalCount={totalCount}
          selectedOrders={selectedPickTabOrders}
          addSelectedOrder={order => addSelectedOrder(order, PICK_TAB)}
          removeSelectedOrder={order => removeSelectedOrder(order, PICK_TAB)}
          setSelectedOrders={setSelectedPickTabOrders}
          page={page}
          setPage={setPage}
          onPicked={handlePicked}
          //onOrderPrinted={handlePickTabOrderPrinted}
        />,
        <HistoryTab
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          loading={loading}
          fetchPickingListOrders={fetchPickingListOrders}
          pickingListOrders={pickingListOrders}
          showColumn={showColumn}
          totalCount={totalCount}
          selectedOrders={selectedHistoryTabOrders}
          addSelectedOrder={order => addSelectedOrder(order, HISTORY_TAB)}
          removeSelectedOrder={order => removeSelectedOrder(order, HISTORY_TAB)}
          setSelectedOrders={setSelectedHistoryTabOrders}
          page={page}
          setPage={setPage}
          isHistory
          //onOrderPrinted={handleHistoryTabOrderPrinted}
        />,
      ].map((x, index) => (
        <TabPanel
          value={selectedTabIndex}
          className={classes.tabPanel}
          index={index}
        >
          {x}
        </TabPanel>
      ))}
    </div>
  );
};

PickingListTabs.defaultProps = {
  showColumn: {},
  loading: null,
  pickingListOrders: [],
};

PickingListTabs.propTypes = {
  showColumn: PropTypes.shape({}),
  searchValues: PropTypes.shape({}).isRequired,
  setSearchValues: PropTypes.func.isRequired,
  pickingListOrders: PropTypes.arrayOf(PropTypes.shape({})),
  setPickingListOrders: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  setTotalCount: PropTypes.func.isRequired,
  fetchPickingListOrders: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default PickingListTabs;

import { gql } from '@apollo/client';
import SiteChannelMessageFragment from "../fragments/SiteChannelMessage";

const MESSAGE_UPDATE_SUBSCRIPTION = gql`
  subscription MessageUpdateSubscription(
    $siteChannelId: Int!
    $fromMessage: ID
    $authToken: String!
  ) {
    messageUpdate(
      siteChannelId: $siteChannelId
      fromMessage: $fromMessage
      authToken: $authToken
    ) {
      cursor
      node {
        ...SiteChannelMessageFragment
      }
    }
  }
  ${SiteChannelMessageFragment}
`;

export default MESSAGE_UPDATE_SUBSCRIPTION;

import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

const ColumnPopover = ({ columnHeaders, visibleColumns, open, anchorEl, onClose, onChange }) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 500, paddingLeft: "9px", paddingRight: "9px" }}>Visa kolumner</Typography>
        {columnHeaders.map(column => (
          <Box key={column.field} sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={visibleColumns[column.field]}
              onChange={e => onChange(column.field)} />
            <span>{column.title}</span>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

const ShowHideColumns = ({ columns, visibleColumns, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Stack direction="row" spacing={1} justifyContent="end">
        <IconButton aria-label="show-hide-columns" onClick={handleClick}>
          <ViewColumnIcon />
        </IconButton>
      </Stack>
      <ColumnPopover columnHeaders={columns} visibleColumns={visibleColumns} open={open} onChange={onChange} anchorEl={anchorEl} onClose={handleClose} />
    </Box>
  );
};

export default ShowHideColumns;

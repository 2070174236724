import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DELETE_SITE_ROLE = gql`
  mutation DeleteSiteRole($siteRoleId: Int!) {
    deleteSiteRole(siteRoleId: $siteRoleId) {
      __typename
      success
      errorResult {
        ...ErrorResultFields
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_SITE_ROLE;

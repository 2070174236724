import { useContext } from "react";
import { termsOfUseContext } from "../context/TermsOfUseContext";

export const useTermsOfUseContext = () => {
  const context = useContext(termsOfUseContext);

  if (context === undefined) {
    throw new Error(
      "Hook useTermsOfUseContext is used outside of its Provider."
    );
  }

  return context;
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Box, Divider } from "@mui/material";

import { useSnackbar } from "../../../hooks";
import { setTheme, setCurrentUser } from "../../../actions";
import { UPDATE_ACCOUNT_SETTINGS } from "../../../graphql/mutations";
import BannerSection from "./BannerSection";
import AccountTabPanel from "./AccountTabPanel";
import ConfirmationModal from "../../common/ConfirmationModal";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  modalFooter: {
    padding: "10px 20px",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 25,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 3,
    },
  },
  text: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  divider: {
    marginTop: 4,
    marginBottom: 0,
  },
  settingWrapper: {
    padding: "5px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 8px 20px 8px",
    },
  },
}));

const AccountSettings = ({ accountSettings, setAccountSettings, setIsViewedAs }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  
  const snackbar = useSnackbar();

  const [draftAddress, setDraftAddress] = useState(false);

  const [confirmModal, setConfirmModal] = useState(false);
  const [
    showAddressChangeRequestSentModal,
    setShowAddressChangeRequestSentModal,
  ] = useState(false);

  const [updateAccountSettings] = useMutation(UPDATE_ACCOUNT_SETTINGS);

  const handeCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleSaveAccountSettings = async () => {
    try {
      setDraftAddress(false);

      const model = { ...accountSettings };

      delete model.allowEditAddresses;
      delete model.allowEditSubscriptionLevel;
      delete model.companyRoleName;
      delete model.companyUserGroup;
      delete model.userGroupBreadCrumb;
      delete model.deliveryAddressSettings;
      delete model.invoiceAddressSettings;      
      delete model.postalAddressSettings;
      delete model.visitingAddressSettings;
      delete model.enabledMFATypes;

      const results = await updateAccountSettings({
        variables: { model },
      });

      if (results.data.userAccountContext.updateAccountSettings.success) {
        snackbar.success(<FormattedMessage id="common.genericSuccess" />);

        if (
          results.data.userAccountContext.updateAccountSettings
            .addressChangeRequestSent
        ) {
          setShowAddressChangeRequestSentModal(true);
        }

        dispatch(
          setTheme({
            theme:
              accountSettings.selectedTheme !== "0"
                ? accountSettings.selectedTheme
                : "",
          })
        );

        if (accountSettings.selectedTheme !== "0") {
          const viewer = JSON.parse(localStorage.getItem("viewer"));
          viewer.defaultTheme = accountSettings.selectedTheme;
          localStorage.setItem("viewer", JSON.stringify(viewer));
        }
      }

      if (results && results.data && results.data.updateAccountSettings) {
        const { user } = results.data.updateAccountSettings;

        if (user) {

          setCurrentUser({
            user,
          });

          snackbar.success(intl.formatMessage({ id: "settings.updateSuccess" }));

        } else {
          snackbar.workspaceError(
            intl.formatMessage({ id: "settings.updateFailure" })
          );
        }
      }

      if (confirmModal) {
        handeCloseConfirmModal();
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const [showSaveButton, setShowButton] = useState(true);

  const handleTabChange = (tabValue) => {
    if (tabValue == 3) {
      setShowButton(false);
    }
    else {
      setShowButton(true);
    }
  }

  return (
    <>
      <BannerSection
        setIsViewedAs={setIsViewedAs}
        accountSettings={accountSettings}
        setAccountSettings={setAccountSettings}
      />
      <Box className={classes.settingWrapper}>
        <Divider className={classes.divider} />
        <AccountTabPanel
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}          
          handleSaveAccountSettings={handleSaveAccountSettings}
          setDraftAddress={setDraftAddress}
          onTabChange={handleTabChange}
        />
        {showSaveButton && (
          <Box align="right">
            <Button
              className={classes.btn}
              onClick={() =>
                draftAddress ? setConfirmModal(true) : handleSaveAccountSettings()
              }              
            >
              <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
              <FormattedMessage id="btn.save" />
            </Button>
          </Box>)}
      </Box>
      {confirmModal && (
        <ConfirmationModal
          open={confirmModal}
          handleClose={handeCloseConfirmModal}
          onClick={handleSaveAccountSettings}
          message={<FormattedMessage id="addresses.saveChanges" />}
        />
      )}
      {showAddressChangeRequestSentModal && (
        <ConfirmationModal
          open={showAddressChangeRequestSentModal}
          message={<FormattedMessage id="settings.addressWarning" />}
          handleClose={() => setShowAddressChangeRequestSentModal(false)}
          variant="secondary"
        />
      )}
    </>
  );
};

AccountSettings.defaultProps = {
  accountSettings: {},
};

AccountSettings.propTypes = {
  accountSettings: PropTypes.shape({
    selectedTheme: PropTypes.string,
  }),
  setIsViewedAs: PropTypes.func.isRequired,
};

export default AccountSettings;

import React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import PreviewTemplate from "../PreviewTemplate";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  equalHeight: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  imagePreview: {
    padding: 20,
    backgroundColor: "#F4F3EF",
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
    "& .react-transform-component": {
      margin: "0 auto",
      width: "initial",
    },
    "& .react-transform-component:hover": {
      cursor: "grabbing",
    },
    "& .react-transform-element": {
      margin: "0 auto",
      width: "100%",
      height: "85vh",
    },
    "& > div": {
      width: "100%",
    },
  },
}));

const TabPanelContainer = ({ children, product, formValues }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes.equalHeight}>
        <Grid item xs={12} md={3}>
          <div className={classes.equalHeight}>{children}</div>
        </Grid>
        <Grid item xs={12} md={9} className={classes.imagePreview}>
          <PreviewTemplate product={product} formValues={formValues} />
        </Grid>
      </Grid>
    </div>
  );
};
TabPanelContainer.defaultProps = {
  formValues: {},
};

TabPanelContainer.propTypes = {
  children: PropTypes.node.isRequired,
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    dynamicFieldValues: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  formValues: PropTypes.shape({}),
};

export default TabPanelContainer;

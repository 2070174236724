import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Link } from "@mui/material";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  container: {
    margin: "15px 0",
  },
  label: {
    fontWeight: 800,
    fontSize: 16,
  },
  liftLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
  link: {
    margin: 0,
    color: "#000",
  },
}));

const NoAvailableLifts = () => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid xs={12} className={classes.container}>
          <Typography className={classes.label}>
            <FormattedMessage id="booking.noLiftAvailableForDate" />
          </Typography>
          <Typography className={classes.liftLabel}>
            <FormattedMessage id="booking.pleaseContact" />
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid xs={12} className={classes.container}>
          <Typography className={classes.label}>
            <FormattedMessage id="booking.telephoneExchange" />:
          </Typography>
          <Typography className={classes.liftLabel}>020-44 78 10</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid xs={12} className={classes.container}>
          <Typography className={classes.label}>
            <FormattedMessage id="booking.linkToContact" />:
          </Typography>
          <Typography className={classes.liftLabel}>
            <Link
              href="https://lift-och-maskinuthyrning.se/kontakt/"
              underline="always"
              className={classes.link}
            >
              https://lift-och-maskinuthyrning.se/kontakt/
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoAvailableLifts;

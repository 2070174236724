import React, { memo, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { TreeView } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderItem from "./FolderItem";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    padding: 5,
    overflowY: "auto",
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
    },
  },
  treeView: {
    flexGrow: 1,
  },
}));

const HomeMadeFolderTree = ({
  folderTree,
  handleOpenNode,
  openedNodes,
  setFolderTree,
  setOpenedNodes,
  foundFolders,
  handleFolderRowClick,
}) => {
  const classes = useStyles();
  const [folderTreeRoots, setFolderTreeRoots] = useState([]);

  useEffect(() => {
    const newFolderTreeRoots = Object.values(folderTree).filter(x => x.isRoot);
    setFolderTreeRoots(newFolderTreeRoots);
  }, [folderTree]);

  const handleOnNodeToggle = (e, nodeIds) => {
    setOpenedNodes(nodeIds);
  };

  return (
    <Box className={classes.root}>
      <TreeView
        className={classes.treeView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={openedNodes}
        onNodeToggle={handleOnNodeToggle}
      >
        {folderTreeRoots.map((x, index) => (
          <Fragment key={`folder-tree-roots-${x.folderId}`}>
            <FolderItem
              key={`folder-item-${x.folderId}`}
              folder={folderTree[`f_${x.folderId}`]}
              folderTree={folderTree}
              handleOpenNode={handleOpenNode}
              setFolderTree={setFolderTree}
              foundFolders={foundFolders}
              handleFolderRowClick={handleFolderRowClick}
            />
          </Fragment>
        ))}
      </TreeView>
    </Box>
  );
};

HomeMadeFolderTree.defaultProps = {
  handleOpenNode: () => {},
  openedNodes: [],
  setFolderTree: () => {},
  setOpenedNodes: () => {},
  selectedNode: null,
  foundFolders: [],
};

HomeMadeFolderTree.propTypes = {
  folderTree: PropTypes.shape({}).isRequired,
  handleOpenNode: PropTypes.func,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  selectedNode: PropTypes.shape({}),
  setFolderTree: PropTypes.func,
  setOpenedNodes: PropTypes.func,
  foundFolders: PropTypes.arrayOf(PropTypes.number),
  handleFolderRowClick: PropTypes.func.isRequired,
};

export default memo(HomeMadeFolderTree);

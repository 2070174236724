import React, { memo, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { TreeView } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupItem from "./GroupItem";
import InBetweenFolder from "./InBetweenFolder";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    padding: 5,
    paddingTop: 0,
    overflowY: "auto",
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
    },
  },
  treeView: {
    flexGrow: 1,
  },
}));

const UserGroupTree = ({
  
  groupTree,
  handleContextMenu,
  handleOpenNode,
  
  moveFolderEnabled,
  onGroupRowClick,
  onUsersMoved,
  openedNodes,
  refetch,

  setGroupTree,
  selectedFolderIds,
  setOpenedNodes,

}) => {
  const classes = useStyles();

  const [groupTreeRoots, setGroupTreeRoots] = useState([]);

  useEffect(() => {
    const newGroupTreeRoots = Object.values(groupTree).filter(x => x.isRoot);

    setGroupTreeRoots(newGroupTreeRoots);
  }, [groupTree]);

  const handleOnNodeToggle = (e, nodeIds) => {
    setOpenedNodes(nodeIds);
  };

  return (
      <Box className={classes.root}>
      <TreeView
        className={classes.treeView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={openedNodes}
        onNodeToggle={handleOnNodeToggle}
      >
        <InBetweenFolder
          groupTree={groupTree}
          parentGroupId={null}
          refetch={refetch}          
          index={0}          
              />
        {groupTreeRoots.map((x, index) => (
          <Fragment key={`group-tree-roots-${x.groupId}`}>
            <GroupItem
              key={`group-item-${x.groupId}`}              
              group={groupTree[`g_${x.groupId}`]}
              groupTree={groupTree}
              handleContextMenu={handleContextMenu}
              handleOpenNode={handleOpenNode}                            
              moveFolderEnabled={moveFolderEnabled}
              onGroupRowClick={onGroupRowClick}
              onUsersMoved={onUsersMoved}
              refetch={refetch}
              setGroupTree={setGroupTree}
              selectedFolderIds={selectedFolderIds}
            />
            <InBetweenFolder
              key={`in-between-group-${x.groupId}`}
              groupTree={groupTree}
              parentGroupId={x.parentGroupId}
              refetch={refetch}              
              index={index + 1}              
            />
          </Fragment>
        ))}
              </TreeView>
    </Box>
  );
};

UserGroupTree.defaultProps = {
  checkedFolders: [],
  handleContextMenu: () => {},
  handleOpenNode: () => {},
  handleSetEditNode: () => {},
  isEditMode: false,
  moveFolderEnabled: false,
  onGroupRowClick: () => {},
  openedNodes: [],
  refetchFolders: () => {},
  selectedFolderIds: [],
  handleCheckboxChange: () => {},
  setGroupTree: () => {},
  setOpenedNodes: () => {},
  selectedNode: null,
};

UserGroupTree.propTypes = {
  checkedFolders: PropTypes.arrayOf(PropTypes.number),
  groupTree: PropTypes.shape({}).isRequired,
  handleContextMenu: PropTypes.func,
  handleOpenNode: PropTypes.func,
  handleSetEditNode: PropTypes.func,
  isEditMode: PropTypes.bool,
  moveFolderEnabled: PropTypes.bool,
  onGroupRowClick: PropTypes.func,
  onUsersMoved: PropTypes.func,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  refetch: PropTypes.func,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  selectedNode: PropTypes.shape({}),
  handleCheckboxChange: PropTypes.func,
  setGroupTree: PropTypes.func,
  setOpenedNodes: PropTypes.func,
};

export default memo(UserGroupTree);

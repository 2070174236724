import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import PropertyField from "../FolderFilesPropertiesTable/PropertyField";
import {
  SELECT_FIELD_MANDATORY,
  MULTI_CHECKBOX_FIELD,
} from "../../../constant/types";
import PropertyBox from "./PropertyBox";

const getSelectValue = (propertyValueIds, propertyValues) => {
  let value = "";
  for (let i = 0; i < propertyValueIds.length; i += 1) {
    const val = propertyValueIds[i];
    const foundValue = propertyValues.find(x => x.id === val);
    if (foundValue) {
      value = foundValue.id;
      break;
    }
  }
  return value;
};

const getMutliCheckboxValue = (propertyValueIds, propertyValues) => {
  let value = [];
  for (let i = 0; i < propertyValueIds.length; i += 1) {
    const val = propertyValueIds[i];
    const foundValue = propertyValues.find(x => x.id === val);
    if (foundValue) {
      value = [...value, foundValue.id];
    }
  }
  return value;
};

const ProductionPropertyFormItem = ({
  property,
  handleOnChange,
  selectedResources,
  propertyFormValues,
  selectedFoldersProperties,
  folderPropertyFormValues,
  disabled,
  folderId,
  setProductionSettingErrors,
  productionSettingErrors,
}) => {
  const { type, propertyValues, name } = property;

  const folderFormValues = folderPropertyFormValues.find(
    x => x.folderId === folderId
  );

  let changed = false;

  let value = null;

  selectedResources.forEach(x => {
    const propertyFormValue = propertyFormValues.find(y => y.resourceId === x);
    const { productionSettings: productionSettingsFormValue } =
      propertyFormValue || {};

    const { propertyValueIds = [] } = productionSettingsFormValue || {};
    let newValue = null;

    if (type === SELECT_FIELD_MANDATORY) {
      newValue = getSelectValue(propertyValueIds, propertyValues);
    }

    if (type === MULTI_CHECKBOX_FIELD) {
      newValue = getMutliCheckboxValue(propertyValueIds, propertyValues);
    }

    if (value !== null) {
      const differentValue = !isEqual(value, newValue);

      if (differentValue) {
        changed = true;
      }
    } else {
      value = newValue;
    }
  });

  selectedFoldersProperties.forEach(x => {
    const propertyFormValue = folderPropertyFormValues.find(
      y => y.folderId === x
    );
    const { productionSettings: productionSettingsFormValue } =
      propertyFormValue || {};

    const { propertyValueIds = [] } = productionSettingsFormValue || {};
    let newValue = null;

    if (type === SELECT_FIELD_MANDATORY) {
      newValue = getSelectValue(propertyValueIds, propertyValues);
    }

    if (type === MULTI_CHECKBOX_FIELD) {
      newValue = getMutliCheckboxValue(propertyValueIds, propertyValues);
    }

    if (value !== null) {
      const differentValue = !isEqual(value, newValue);

      if (differentValue) {
        changed = true;
      }
    } else {
      value = newValue;
    }
  });

  let tempPropertyValueIds = [];

  if (changed) {
    tempPropertyValueIds = [];
  } else if (value) {
    if (Array.isArray(value)) {
      tempPropertyValueIds = [...value];
    } else {
      tempPropertyValueIds = [value];
    }
  } else {
    tempPropertyValueIds = [];
  }

  useEffect(() => {
    if (type === SELECT_FIELD_MANDATORY) {
      setProductionSettingErrors({
        ...productionSettingErrors,
        [folderId]: { ...productionSettingErrors[folderId], [name]: value },
      });
    }
  }, [type, name, value, SELECT_FIELD_MANDATORY, folderId]);

  const onChange = (e, selectedCheckbox) => {
    handleOnChange(e, property, selectedCheckbox, folderFormValues);
  };

  return (
    <PropertyBox>
      <PropertyField
        property={property}
        onChange={onChange}
        propertyValueIds={tempPropertyValueIds}
        disabled={disabled}
      />
    </PropertyBox>
  );
};

ProductionPropertyFormItem.defaultProps = {
  folderPropertyFormValues: [],
  disabled: false,
  folderId: null,
  productionSettingErrors: {},
};

ProductionPropertyFormItem.propTypes = {
  folderId: PropTypes.number,
  setProductionSettingErrors: PropTypes.func.isRequired,
  productionSettingErrors: PropTypes.shape({}),
  property: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    propertyValues: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedFoldersProperties: PropTypes.arrayOf(PropTypes.number).isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
};

export default ProductionPropertyFormItem;

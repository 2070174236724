import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { LIST_WORKSPACE_ORDER_GROUP_CANDIDATES } from "../../graphql/queries/CompanyAdminContext";
import {
  setWorkspaceOrderGroupCandidates,
  setWorkspaceOrderGroupCandidatesLoading,
} from "../../actions";

// eslint-disable-next-line import/prefer-default-export
export const useListWorkspaceOrderGroupCandidates = () => {
  const dispatch = useDispatch();

  const [
    listWorkspaceOrderGroupCandidates,
    { data, loading, error },
  ] = useLazyQuery(LIST_WORKSPACE_ORDER_GROUP_CANDIDATES);

  useEffect(() => {
    if (
      data &&
      data.companyAdminContext &&
      data.companyAdminContext.companyWorkspaces &&
      data.companyAdminContext.companyWorkspaces
        .listWorkspaceOrderGroupCandidates &&
      Array.isArray(
        data.companyAdminContext.companyWorkspaces
          .listWorkspaceOrderGroupCandidates.items
      )
    ) {
      const {
        listWorkspaceOrderGroupCandidates,
      } = data.companyAdminContext.companyWorkspaces;

      dispatch(
        setWorkspaceOrderGroupCandidates({
          items: listWorkspaceOrderGroupCandidates.items,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setWorkspaceOrderGroupCandidatesLoading({
        loading,
      })
    );
  }, [loading]);

  return { execute: listWorkspaceOrderGroupCandidates, data, loading, error };
};

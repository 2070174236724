import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormattedCurrency from "../common/FormattedCurrency";
import { GET_SHOPPING_CART_TOTALS } from "../../graphql/queries";

const useStyles = makeStyles(theme => ({
  td: {
    borderBottom: 0,
    color: theme.palette.component.productBoxColor,
    paddingTop: 0,
  },
  tdTotal: {
    borderBottom: 0,
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
  },
}));

const SummaryComputation = ({
  cartItems,
  fileGroups,
  oneStreamGroups,
  sites,
  workspace,
  deliveryAddressChanged,
}) => {
  const classes = useStyles();
  const [total, setTotals] = useState({
    freight: 0,
    orderFee: 0,
    sumExclVAT: 0,
    sumInclVAT: 0,
  });
  const [siteOrderGroupId, setSiteOrderGroupId] = useState(null);
  const itemsForQuery = cartItems.map(cartItem => ({
    productId: cartItem.productId,
    quantity: cartItem.quantity,
    dynamicTemplateData: cartItem.dynamicFieldValues || [],
    supplierId: cartItem.siteSupplierId,
    siteId: cartItem.siteId,
  }));

  const fileGroupsForQuery = fileGroups.map(x => {
    const { items: groupItems, packageName } = x;

    const newItems = groupItems.map(y => ({
      dynamicTemplateData: y.dynamicFieldValues,
      productId: y.productId,
      propertyProductData: y.propertyProductData,
      quantity: y.quantity,
      siteId: y.siteId,
      supplierId: y.siteSupplierId,
    }));

    const newFileGroup = {
      packageName,
      items: newItems,
    };

    return newFileGroup;
  });
  const oneStreamGroupsForQuery = oneStreamGroups.map(x => {
    const { items: groupItems, packageName } = x;

    const newItems = groupItems.map(y => ({
      dynamicTemplateData: y.dynamicFieldValues,
      productId: y.productId,
      propertyProductData: y.propertyProductData,
      quantity: y.quantity,
      siteId: y.siteId,
      supplierId: y.siteSupplierId,
    }));

    const newFileGroup = {
      packageName,
      items: newItems,
    };

    return newFileGroup;
  });

  const { data, refetch } = useQuery(GET_SHOPPING_CART_TOTALS, {
    variables: {
      orderGroupId: siteOrderGroupId,
      items: itemsForQuery,
      fileGroups: fileGroupsForQuery,
      oneStreamGroups: oneStreamGroupsForQuery,
      deliveryAddressChanged,
    },
    skip: !siteOrderGroupId,
  });

  useEffect(() => {
    if (sites && workspace && workspace.id) {
      const selectedSite = sites.find(x => x.id === workspace.id);
      if (selectedSite) {
        const { orderGroupId } = selectedSite;
        setSiteOrderGroupId(orderGroupId);
      }
    }
  }, [sites, workspace]);

  useEffect(() => {
    if (siteOrderGroupId) {
      refetch();
    }
  }, [siteOrderGroupId]);

  useEffect(() => {
    if (data && data.calcShoppingCartTotals) {
      setTotals(data.calcShoppingCartTotals);
    }
  }, [data]);

  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell className={classes.td}>
            <FormattedMessage id="cart.productSum" />
          </TableCell>
          <TableCell className={classes.td} align="right">
            <FormattedCurrency value={total.sumExclVAT} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.td}>
            <FormattedMessage id="cart.freight" />
          </TableCell>
          <TableCell className={classes.td} align="right">
            <FormattedCurrency value={total.freight} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.td}>
            <FormattedMessage id="cart.vat" />
          </TableCell>
          <TableCell className={classes.td} align="right">
            <FormattedCurrency value={total.sumInclVAT - total.sumExclVAT} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tdTotal}>
            <FormattedMessage id="cart.total" />
          </TableCell>
          <TableCell className={classes.tdTotal} align="right">
            <FormattedCurrency
              value={total.freight + total.orderFee + total.sumInclVAT}
            />{" "}
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
};

SummaryComputation.defaultProps = {
  workspace: { id: null, name: null },
};

SummaryComputation.propTypes = {
  deliveryAddressChanged: PropTypes.bool.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      quantity: PropTypes.number,
      dynamicFieldValues: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    })
  ).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      orderGroupId: PropTypes.number.isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          page: PropTypes.string.isRequired,
          childMenu: PropTypes.array,
        })
      ),
    }).isRequired
  ).isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  return {
    cartItems: state.api.cart.cartItems,
    fileGroups: state.api.cart.fileGroups,
    oneStreamGroups: state.api.cart.oneStreamGroups,
    sites: state.api.currentViewer.viewer.sites,
    workspace: state.ui.toggleWorkspaces.workspace,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // cartInitialize
    },
    dispatch
  );
};

function areEqual(prevProps, nextProps) {
  if (prevProps.cartItems !== nextProps.prevProps) {
    return false;
  }
  return true;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(SummaryComputation, areEqual));

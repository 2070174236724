import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { debounce } from "lodash";
import { RECIPIENT_LOOKUP } from "../../../graphql/queries/ShareContext";
import { SearchAutoComplete } from "../../common";
import { FormattedMessage } from "react-intl";

const RecipientsInput = ({ recipients, setRecipients, disabled }) => {
  const [suggestions, setSuggestions] = useState([]);


  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  useEffect(() => {
    if (searchTerm) {
      debouncedGetSuggestions(searchTerm);
    }
  }, [searchTerm]);

  const handleAutoCompleteOpen = () => {
    setOpenAutoComplete(true);
  };

  const handleAutoCompleteClose = () => {
    setOpenAutoComplete(false);
  };


  const [getSuggestions, { data, loading, error }] = useLazyQuery(RECIPIENT_LOOKUP);

  useEffect(n => {
    if (data?.shareContext?.recipientLookup) {
      const { recipientLookup } = data.shareContext;
      let newValues = recipientLookup.map(item => createSuggestion(item));
      setSuggestions(newValues);
    }
  }, [data]);

  const createSuggestion = item => {
    const { firstname, lastname, username } = item;
    const name = `${firstname ?? ""} ${lastname ?? ""}`.trim();
    return {
      label: username,
      text: name.length > 0 ? `(${name}) ${username}` : `${username}`,
      value: username,
      lookup: item,
    };
  };

  const debouncedGetSuggestions = useCallback(
    debounce(text => {      
      getSuggestions({ variables: { searchTerm: text } });
    }, 800),
    []
  );

  const handleOnAdd = (suggestionValue, suggestion) => {

    setRecipients(suggestionValue);

  };

  return (

    <SearchAutoComplete
      
      open={openAutoComplete}
      setOpen={handleAutoCompleteOpen}
      setClose={handleAutoCompleteClose}

      placeholder={""}
      onChange={e => setSearchTerm(e.target?.value || "")}
      options={suggestions}
      loading={loading}
      setValue={handleOnAdd}
      value={recipients}
      label={<FormattedMessage id="myShares.tableHeader.Recipients" />}
      required={true}
      multiple
      data-tut="step3"
      
      />
  );
};

RecipientsInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  setRecipients: PropTypes.func.isRequired,
};

export default RecipientsInput;

import React from "react";
import { useIntl } from "react-intl";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12,
  },
  tr: {
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      background: "#8ad",
    },
    "&[disabled]:hover": {
      boxShadow: "inherit",
      background: "inherit",
    },
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const MySharesTableItem = ({
  item,
  disabled,
  withFromColumn,
  withRecipientsColumn,
  onDeleteItem,
  onOpenItem,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const renderDate = () => {
    const m = moment(item.shareDate);
    const title = m.format("YYYY-MM-DD hh:mm");
    const date = m.format("YYYY-MM-DD");
    return <span title={title}>{date}</span>;
  };

  const renderDescription = () => item.description;

  const renderFrom = () => renderUserdata(item.user);

  const renderRecipients = () => item.recipients.map(n => renderUserdata(n));

  const renderUserdata = ({ shareId, firstname, lastname, username }) => {
    const key = `s-${shareId}-u-${username}`;
    const name = `${firstname} ${lastname}`.trim();
    return name ? (
      <div key={key}>{`(${name}) ${username}`}</div>
    ) : (
      <div key={key}>{`${username}`}</div>
    );
  };

  const handleOnRowClick = e => {
    onOpenItem(item);
  };

  const handleOnDeleteClick = e => {
    e.stopPropagation();
    onDeleteItem(item);
  };

  return (
    <TableRow
      disabled={disabled}
      className={classes.tr}
      onClick={disabled ? void 0 : handleOnRowClick}
    >
      <TableCell className={classes.td}>{renderDate()}</TableCell>
      <TableCell className={classes.td}>{renderDescription()}</TableCell>
      {withFromColumn && (
        <TableCell className={classes.td}>{renderFrom()}</TableCell>
      )}
      {withRecipientsColumn && (
        <TableCell className={classes.td}>{renderRecipients()}</TableCell>
      )}
      <TableCell className={classes.td}>
        <IconButton
          className={classes.action}
          disabled={disabled}
          onClick={handleOnDeleteClick}
        >
          <Icon
            className={clsx(["fa fa-trash", classes.iconTrash])}
            title={intl.formatMessage({ id: "myShares.tooltip.deleteShare" })}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

MySharesTableItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onOpenItem: PropTypes.func.isRequired,
  item: PropTypes.shape({
    shareId: PropTypes.number.isRequired,
    shareDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    user: PropTypes.shape({
      username: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
    }).isRequired,
    recipients: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  withFromColumn: PropTypes.bool.isRequired,
  withRecipientsColumn: PropTypes.bool.isRequired,
};

export default MySharesTableItem;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { Typography, Grid, Box, Button } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { getSiteId } from "../../../../helpers/selectors";
import { IconTextLink } from "../../../common";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import TemplatesList from "../../../common/TemplatesList";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  labelTitle: {
    fontWeight: 600,
  },
  templates: {
    marginTop: 15,
  },
  modalFooter: {
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 10,
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  btnYes: {
    padding: "5px 15px",
    fontSize: 11,
    color: theme.palette.primary.contrastText,
    marginLeft: 7,
    backgroundColor: "#88d1d2",
    "&:hover": {
      backgroundColor: "#88d1d2",
      opacity: 0.8,
    },
  },
}));

const NewsTypeModal = ({
  open,
  handleClose,
  setCreateNewsModal,
  selectedTemplateId,
  setSelectedTemplateId,
  setNewsPageDetailsModal,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const siteId = useSelector(state => getSiteId(state));

  const handleYesButton = () => {
    setNewsPageDetailsModal(true);
    handleClose();
  };
  const handleBackButton = () => {
    setCreateNewsModal(true);
    handleClose();
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={intl.formatMessage({
          id: "newsPost.addNewsPage",
        })}
      />
      <DraggableModalBody>
        <Grid container>
          <Grid xs={12}>
            <Typography className={classes.labelTitle}>
              <FormattedMessage id="newsPost.addNewsPage" />
            </Typography>
            <Typography>
              <FormattedMessage id="newsPost.newsType" />
            </Typography>
          </Grid>

          <Grid xs={12} className={classes.templates}>
            <Typography className={classes.labelTitle}>
              <FormattedMessage id="newsPost.templateForNewsPage" />
            </Typography>
            <Box>
              <TemplatesList
                siteId={siteId}
                selectedTemplateId={selectedTemplateId}
                setSelectedTemplateId={setSelectedTemplateId}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.modalFooter}
          justifyContent="flex-end"
          alignItems="center"
        >
          <IconTextLink
            onClick={handleBackButton}
            iconClass="fa fa-long-arrow-alt-left"
            text={<FormattedMessage id="cart.linkBack" />}
            fontSize={14}
          />

          <Button className={classes.btnYes} onClick={handleYesButton}>
            {intl.formatMessage({
              id: "btn.next",
            })}
          </Button>
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewsTypeModal.defaultProps = {
  selectedTemplateId: null,
};

NewsTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedTemplateId: PropTypes.number,
  setSelectedTemplateId: PropTypes.func.isRequired,
  handleSaveNewsPost: PropTypes.func.isRequired,
  setNewsPageDetailsModal: PropTypes.func.isRequired,
};

export default NewsTypeModal;

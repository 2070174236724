import React from "react";
import { makeStyles } from "@mui/styles";
import Tour from "reactour";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@mui/material";
import CheckboxDefault from "../common/FormControl/Checkbox";

const useStyles = makeStyles(() => ({
  last: {
    marginBottom: 20,
  },
}));
const MonitoringModalTour = ({ isOpen, onRequestClose }) => {
  const classes = useStyles();
  const intl = useIntl();

  const tourConfig = [
    {
      selector: '[data-tut="step1"]',
      content: intl.formatMessage({
        id: "monitoring.selectHowMonitorSent",
      }),
    },
    {
      selector: '[data-tut="step2"]',
      content: intl.formatMessage({
        id: "monitoring.selectWhenShouldNotification",
      }),
    },
    {
      selector: '[data-tut="step3"]',
      content: intl.formatMessage({
        id: "monitoring.selectToWhomNotificationSent",
      }),
    },
    {
      selector: '[data-tut="step4"]',
      content: () => (
        <>
          <Typography className={classes.last}>
            <FormattedMessage id="monitoring.tourMessage" />
          </Typography>

          <CheckboxDefault
            label={<FormattedMessage id="monitoring.doNotShowTour" />}
          />
        </>
      ),
    },
  ];

  const accentColor = "#662336";

  return (
    <Tour
      steps={tourConfig}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      accentColor={accentColor}
    />
  );
};

export default MonitoringModalTour;

import {
  oidcAuthorizeUrl,
  oidcClientId,
  oidcRedirectUri,
  oidcLogoutUrl,
  oidcScope,
  oidcSilentRenewUri,
  oidcLoginUrl,
  oidcUrl,
} from "../services/config";

export const IDENTITY_CONFIG = {
  authority: oidcAuthorizeUrl,
  client_id: oidcClientId,
  redirect_uri: oidcRedirectUri,
  post_logout_redirect_uri: oidcLogoutUrl,
  response_type: "id_token token",
  scope: oidcScope,
  silent_redirect_uri: oidcSilentRenewUri,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 4,
  silentRequestTimeout: 10000,
  login: oidcLoginUrl,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

export const METADATA_OIDC = {
  issuer: `${oidcUrl}`,
  jwks_uri: `${oidcUrl}/.well-known/openid-configuration/jwks`,
  authorization_endpoint: `${oidcUrl}/connect/authorize`,
  token_endpoint: `${oidcUrl}/connect/token`,
  userinfo_endpoint: `${oidcUrl}/connect/userinfo`,
  end_session_endpoint: `${oidcUrl}/connect/endsession`,
  check_session_iframe: `${oidcUrl}/connect/checksession`,
  revocation_endpoint: `${oidcUrl}/connect/revocation`,
  introspection_endpoint: `${oidcUrl}/connect/introspect`,
};

export const DROPZONE_PARALLEL_UPLOADS = 100;
export const BEATA_WIDGET_ROOT_FOLDER = 3294;

export * from "./addressFieldType";
export * from "./paymentMethod";
export * from "./siteNewsItemType";
export * from "./types";
export * from "./userSubscriptionLevels";
export * from "./companyAdmin";
export * from "./shareTypes";
export * from "./siteChannelEventTypes";

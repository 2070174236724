import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLazyQuery, useSubscription } from "@apollo/client";
import Messaging from "../../components/Messaging";
import { GET_SITE_CHANNELS } from "../../graphql/queries";
import { GET_CONVERSATIONS } from "../../graphql/queries/DirectMessages";
import {
  GET_DIRECT_MESSAGE_CONVERSATION_UPDATE,
  GET_SITE_CHANNEL_EVENTS,
} from "../../graphql/subscription";
import { getSiteId } from "../../helpers/selectors";

const MessagingPage = ({ siteId }) => {
  const [activeChannelId, setActiveChannelId] = useState(null);
  const [activeConversationId, setActiveConversationId] = useState(null);
  const [channels, setChannels] = useState([]);
  const [conversations, setConversations] = useState(null);
  const [selectedSiteId, setSelectedSiteId] = useState(siteId);
  const accessToken = localStorage.getItem("access_token");
  const {
    data: siteChannelEventsData,
    loading: siteChannelEventsLoading,
    error: siteChannelEventsError,
  } = useSubscription(GET_SITE_CHANNEL_EVENTS, {
    variables: { authToken: accessToken },
  });

  useEffect(() => {
    if (siteChannelEventsLoading) {
      console.log("** [siteChannelEventsLoading]", {
        siteChannelEventsLoading,
      });
    }
    if (siteChannelEventsError) {
      console.log("** [siteChannelEventsError]", {
        siteChannelEventsError,
      });
    }
  }, [siteChannelEventsLoading, siteChannelEventsError]);

  useEffect(() => {
    if (siteChannelEventsData && siteChannelEventsData.siteChannelEvents) {
      var { siteChannelEvents: event } = siteChannelEventsData;
      console.log("** [siteChannelEventsData]", { event });
      if (event.siteId === siteId) {
        console.log("** Refreshing channels", { siteId });
        if (selectedSiteId === siteId) {
          getSiteChannels();
        } else {
          selectedSiteId(siteId);
        }
      }
    }
  }, [siteChannelEventsData]);

  const {
    data: conversationsUpdateData,
    loading: conversationsUpdateLoading,
    error: conversationsUpdateError,
  } = useSubscription(GET_DIRECT_MESSAGE_CONVERSATION_UPDATE, {
    variables: { authToken: accessToken },
  });

  useEffect(() => {
    if (conversationsUpdateLoading) {
      console.log("** [conversationsUpdateLoading]", {
        conversationsUpdateLoading,
      });
    }
    if (conversationsUpdateError) {
      console.log("** [conversationsUpdateError]", {
        conversationsUpdateError,
      });
    }
  }, [conversationsUpdateLoading, conversationsUpdateError]);

  useEffect(() => {
    if (conversationsUpdateData) {
      var { directMessageConversationUpdate: update } = conversationsUpdateData;
      var { node: conversation } = update;
      addDirectMessageConversation(conversation);
    }
  }, [conversationsUpdateData]);

  const addDirectMessageConversation = conversation => {
    console.log("** addDirectMessageConversation", { conversation });
    var { directMessageConversationId } = conversation;
    if (!conversations) {
      console.log(
        "** Conversations not loaded yet. No need to add this conversation."
      );
      return;
    }
    var index = conversations.findIndex(
      n => n.directMessageConversationId === directMessageConversationId
    );
    if (index === -1) {
      var tempConversations = [...conversations];
      tempConversations.splice(0, 0, conversation);
      setConversations(tempConversations);
    }
  };

  const [
    getSiteChannels,
    {
      data: siteChannelsData,
      loading: loadingSiteChannels,
      error: errorLoadingSiteChannels,
    },
  ] = useLazyQuery(GET_SITE_CHANNELS, {
    variables: {
      siteId: selectedSiteId,
    },
  });

  const [
    getConversations,
    {
      data: conversationsData,
      loading: conversationsLoading,
      error: conversationsError,
    },
  ] = useLazyQuery(GET_CONVERSATIONS, {});

  useEffect(() => {
    if (!conversations) {
      getConversations();
    }
  }, [conversations]);

  useEffect(() => {
    if (conversationsData && conversationsData.directMessages) {
      setConversations(conversationsData.directMessages.conversations);
    }
  }, [conversationsData]);

  const handleOnChannelSelected = async ({ channelId }) => {
    console.log("** handleOnChannelSelected", { channelId });
    setActiveChannelId(channelId);
    setActiveConversationId(null);
  };

  const handleOnChannelMessageAdded = async ({ siteChannelId }) => {
    console.log("** handleOnChannelMessageAdded", { siteChannelId });
    if (siteChannelId === activeChannelId) {
      resetUnreadSiteChannelMessagesCounter(siteChannelId);
    } else {
      increaseUnreadSiteChannelMessagesCounter(siteChannelId);
    }
  };

  const handleOnChannelMessagesLoaded = async ({ siteChannelId }) => {
    console.log("** onChannelMessagesLoaded", { siteChannelId });
    if (siteChannelId === activeChannelId) {
      resetUnreadSiteChannelMessagesCounter(siteChannelId);
    }
  };

  const handleOnConversationAdded = async ({ conversation }) => {
    const { directMessageConversationId } = conversation;

    const alreadyExists = conversations.find(
      x => x.directMessageConversationId === directMessageConversationId
    );
    if (!alreadyExists) {
      setConversations([...conversations, conversation]);
      handleOnConversationSelected({ directMessageConversationId });
    }
  };

  const handleOnConversationMessageAdded = async ({
    directMessageConversationId,
  }) => {
    console.log("** handleOnConversationMessageAdded", {
      directMessageConversationId,
    });
    if (directMessageConversationId === activeConversationId) {
      resetUnreadDirectMessagesCounter(directMessageConversationId);
    } else {
      increaseUnreadDirectMessagesCounter(directMessageConversationId);
    }
  };

  const handleOnConversationMessagesLoaded = async ({
    directMessageConversationId,
  }) => {
    console.log("** onConversationMessagesLoaded", {
      directMessageConversationId,
    });
    if (directMessageConversationId === activeConversationId) {
      resetUnreadDirectMessagesCounter(directMessageConversationId);
    }
  };

  const resetUnreadSiteChannelMessagesCounter = siteChannelId => {
    var index = channels.findIndex(n => n.siteChannelId === siteChannelId);
    if (index > -1) {
      var tempChannels = [...channels];
      var tempChannel = { ...tempChannels[index] };
      tempChannel.unreadMessageCount = 0;
      tempChannels.splice(index, 1, tempChannel);
      setChannels(tempChannels);
    }
  };

  const increaseUnreadSiteChannelMessagesCounter = siteChannelId => {
    var index = channels.findIndex(n => n.siteChannelId === siteChannelId);
    if (index > -1) {
      var tempChannels = [...channels];
      var tempChannel = { ...tempChannels[index] };
      tempChannel.unreadMessageCount++;
      tempChannels.splice(index, 1, tempChannel);
      setChannels(tempChannels);
    }
  };

  const resetUnreadDirectMessagesCounter = directMessageConversationId => {
    var index = conversations.findIndex(
      n => n.directMessageConversationId === directMessageConversationId
    );
    if (index > -1) {
      var tempConversations = [...conversations];
      var tempConversation = { ...tempConversations[index] };
      tempConversation.unreadMessageCount = 0;
      tempConversations.splice(index, 1, tempConversation);
      setConversations(tempConversations);
    }
  };

  const increaseUnreadDirectMessagesCounter = directMessageConversationId => {
    var index = conversations.findIndex(
      n => n.directMessageConversationId === directMessageConversationId
    );
    if (index > -1) {
      var tempConversations = [...conversations];
      var tempConversation = { ...tempConversations[index] };
      tempConversation.unreadMessageCount++;
      tempConversations.splice(index, 1, tempConversation);
      setConversations(tempConversations);
    }
  };

  const handleOnConversationSelected = async ({
    directMessageConversationId,
  }) => {
    setActiveChannelId(null);
    setActiveConversationId(directMessageConversationId);
  };

  useEffect(() => {
    console.log("** useEffect[selectedSiteId]", { selectedSiteId });
    if (selectedSiteId > 0) {
      getSiteChannels();
    } else {
      // TODO: Get all site channels?
    }
    setActiveConversationId(null);
  }, [selectedSiteId]);

  useEffect(() => {
    if (
      !loadingSiteChannels &&
      !errorLoadingSiteChannels &&
      siteChannelsData &&
      siteChannelsData.siteChannels &&
      siteChannelsData.siteChannels.channels
    ) {
      // dispatch here success
      var loadedChannels = siteChannelsData.siteChannels.channels;
      var firstChannelId =
        Array.isArray(loadedChannels) && loadedChannels.length > 0
          ? loadedChannels[0].siteChannelId
          : null;
      setChannels(loadedChannels);
      setActiveChannelId(firstChannelId);
    } else {
      // dispatch here error
      setActiveChannelId(null);
    }
    if (errorLoadingSiteChannels) {
      // dispatch here error
      setActiveChannelId(null);
    }
    if (loadingSiteChannels) {
      ////setChannelsLoading({ loading: loadingSiteChannels });
    }
  }, [siteChannelsData, loadingSiteChannels, errorLoadingSiteChannels]);

  return (
    <Messaging
      activeChannelId={activeChannelId}
      activeConversationId={activeConversationId}
      channels={channels}
      channelsLoading={loadingSiteChannels}
      conversations={conversations}
      conversationsLoading={conversationsLoading}
      onChannelMessageAdded={handleOnChannelMessageAdded}
      onChannelMessagesLoaded={handleOnChannelMessagesLoaded}
      onChannelSelected={handleOnChannelSelected}
      onConversationAdded={handleOnConversationAdded}
      onConversationMessageAdded={handleOnConversationMessageAdded}
      onConversationMessagesLoaded={handleOnConversationMessagesLoaded}
      onConversationSelected={handleOnConversationSelected}
      siteId={selectedSiteId}
      setSiteId={setSelectedSiteId}
    />
  );
};

const mapStateToProps = state => {
  return {
    siteId: getSiteId(state),
  };
};

MessagingPage.defaultProps = {
  siteId: null,
};

MessagingPage.propTypes = {
  siteId: PropTypes.number,
};

export default connect(mapStateToProps)(MessagingPage);

import { gql } from '@apollo/client';

const MessagePartFragment = gql`
  fragment MessagePartFragment on MessagePart {
    content
    type
    __typename
  }
`;

export default MessagePartFragment;

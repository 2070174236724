import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import { Paper } from "@mui/material";
import StockHistoryHeader from "./StockHistoryHeader";
import StockHistoryItems from "./StockHistoryItems";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
    marginTop: 15,
  },
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
  td: {
    fontSize: 12,
    paddingTop: 12,
    paddingBottom: 12,
    "& p": {
      fontSize: 12,
    },
  },
}));

const HistoryTable = ({ events }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <div className={classes.root}>
        <Table size="small">
          <StockHistoryHeader />
          {events.map(event => {
            return <StockHistoryItems event={event} />;
          })}
        </Table>
      </div>
    </Paper>
  );
};

HistoryTable.defaultProps = {
  events: null,
};

HistoryTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HistoryTable;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Pagination from "../common/Pagination";
import AddMetaDataSection from "./FileView/AddMetaDataSection";
import SortSelect from "../common/FormControl/SelectSort";
import { ShowHideColumnButton } from "../common";
import PageViewIcons from "./PageViewIcons";
import { FILE_VIEW } from "../../constant/types";
import {
  getShowDetailedImageView,
  getShowImageView,
  getShowFileView,
  getShowProductView,
} from "../../helpers/get";
import { useToggle } from "../../hooks/common";
import ShowHideFieldsModal from "./ShowHideFieldsModal";
import FileViewToolbar from "./FileView/FileViewToolbar";

const useStyles = makeStyles(theme => ({
  productViewHeader: {
    display: "block",
    alignItems: "center",
    padding: "0 0 5px 0",
    borderBottom: `1px solid ${theme.palette.common.lightGrey}`,
    marginTop: 10,
  },
  paginationWrapper: {
    marginLeft: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
      marginTop: 8,
      marginLeft: 0,
    },
  },

  headerWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
}));

const ProductsViewHeader = ({
  ascending,
  folderId,
  metaDataSectionVisible,
  numOfLinks,
  offset,
  onChange,
  onPageChange,
  page,
  pageOverride,
  perPage,
  productTotalCount,
  setAscending,
  setOpenMetaDataModal,
  setSortfield,
  setView,
  siteRoleRights,
  sortfield,
  sortFieldOptions,
  view,
  handleGetSiteFolders,
  openedNodes,
}) => {
  const classes = useStyles();
  const { on: isFieldsModalDisplayed, toggle: toggleFieldsModal } = useToggle({
    initialValue: false,
  });

  const showImageView = getShowImageView(siteRoleRights);
  const showDetailedImageView = getShowDetailedImageView(siteRoleRights);
  const showFileView = getShowFileView(siteRoleRights);
  const showProductView = getShowProductView(siteRoleRights);

  return (
    <>
      <div className={classes.productViewHeader}>
        <div className={classes.headerWrapper}>
          {view !== FILE_VIEW && (
            <SortSelect
              ascending={ascending}
              setAscending={setAscending}
              sortfield={sortfield}
              setSortfield={setSortfield}
              options={sortFieldOptions}
            />
          )}
          {/* {view === FILE_VIEW &&
            metaDataSectionVisible &&
            siteRoleRights.allowMetadataTagging && (
              <AddMetaDataSection setOpenMetaDataModal={setOpenMetaDataModal} />
            )} */}

          {view === FILE_VIEW && metaDataSectionVisible && (
            <FileViewToolbar
              allowMetadataTagging={siteRoleRights.allowMetadataTagging}
              setOpenMetaDataModal={setOpenMetaDataModal}
              openedNodes={openedNodes}
            />
          )}
          <div className={classes.paginationWrapper}>
            {/* {view === FILE_VIEW && (
              <ShowHideColumnButton onClick={toggleFieldsModal} />
            )} */}

            <Pagination
              onChange={onChange}
              onPageChange={onPageChange}
              page={pageOverride ? pageOverride : page}
              perPage={perPage}
              totalCount={productTotalCount}
            />
          </div>
          <PageViewIcons
            setView={setView}
            showDetailedImageView={showDetailedImageView}
            showFileView={showFileView}
            showImageView={showImageView}
            showProductView={showProductView}
            toggleFieldsModal={toggleFieldsModal}
            view={view}
          />
        </div>
      </div>
      {isFieldsModalDisplayed && (
        <ShowHideFieldsModal
          open={isFieldsModalDisplayed}
          toggle={toggleFieldsModal}
        />
      )}
    </>
  );
};

ProductsViewHeader.defaultProps = {
  folderId: null,
  numOfLinks: 3,
  detailedSearchOpen: null,
  siteRoleRights: null,
  sortfield: "",
  searchResults: {},
};

ProductsViewHeader.propTypes = {
  ascending: PropTypes.bool.isRequired,
  detailedSearchOpen: PropTypes.bool,
  folderId: PropTypes.number,
  metaDataSectionVisible: PropTypes.bool.isRequired,
  numOfLinks: PropTypes.number,
  offset: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  productTotalCount: PropTypes.number.isRequired,
  setAscending: PropTypes.func.isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  setSortfield: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  siteRoleRights: PropTypes.shape({
    fileView: PropTypes.bool,
    imageView: PropTypes.bool,
    productView: PropTypes.bool,
    detailedImageView: PropTypes.bool,
    allowMetadataTagging: PropTypes.bool,
  }),
  sortfield: PropTypes.string,
  sortFieldOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  view: PropTypes.string.isRequired,
  searchResults: PropTypes.shape({}),
  searchQuery: PropTypes.string.isRequired,
};

export default ProductsViewHeader;

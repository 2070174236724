import React, { useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import { debounce } from "lodash";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { COMPANY_RESPONSIBLE_LOOK_UP } from "../../../../../graphql/queries";
import CustomTextField from "../../../../common/TextField";
import ExpansionDefault from "../../../../common/ExpansionDefault";
import SearchAutoComplete from "../../../../common/SearchAutoComplete";

const useStyles = makeStyles(theme => ({
  customExpansion: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 10,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
  },
  searchField: {
    background: "#fff",
  },
  listbox: {
    fontSize: 12,
  },
}));

const Agency = ({
  formValues,
  handleChange,
  handleSelectResponsible,
  compacompanyBestallareUser,
  setcompanyBestallareUser,
}) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [searchAutocomplete, setSearchAutoComplete] = useState(false);

  const { companyBestallareLookupItem } = formValues || {};
  const { companyUserGroup } = companyBestallareLookupItem || {};

  const [searchCompanyResponsible, { data, loading }] = useLazyQuery(
    COMPANY_RESPONSIBLE_LOOK_UP
  );

  const debounceFetchUsersList = useCallback(
    debounce(async searchTerm => {
      searchCompanyResponsible({
        variables: { searchTerm },
      });
    }, 500),
    []
  );

  const handleOnChange = async text => {
    if (text && text.length > 2) {
      debounceFetchUsersList(text);
    }
  };

  const setClose = () => {
    setSearchAutoComplete(false);
  };

  const setOpen = () => {
    setSearchAutoComplete(true);
  };

  useEffect(() => {
    if (
      data &&
      data.productEdit &&
      Array.isArray(data.productEdit.companyAnsvarigLookup) &&
      data.productEdit.companyAnsvarigLookup.length > 0
    ) {
      const tempUsers = data.productEdit.companyAnsvarigLookup.map(x => {
        return { label: x.user, value: x.userId, groupName: x.groupName };
      });
      setUsers(tempUsers);
    }
  }, [data]);

  const handleSetUsers = value => {
    setcompanyBestallareUser(value);
    handleSelectResponsible(value);
  };

  return (
    <ExpansionDefault
      label={<FormattedMessage id="cooperation.companyBestallare" />}
      panelContent="kommunalContent"
      panelHeader="kommunalHeader"
      defaultExpanded
    >
      <Grid container spacing={1} className={classes.customExpansion}>
        <Grid item xs={12}>
          <SearchAutoComplete
            open={searchAutocomplete}
            setOpen={setOpen}
            setClose={setClose}
            onChange={e => handleOnChange(e)}
            options={users}
            loading={loading}
            setValue={handleSetUsers}
            value={compacompanyBestallareUser}
            label={<FormattedMessage id="settings.email" />}
            customClass={classes.searchField}
            customClasses={{ listbox: classes.listbox }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomTextField
            value={companyUserGroup || ""}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.department" />}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="companyOrderdate"
            value={formValues.companyOrderdate}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.companyOrderDate" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="companyManager"
            value={formValues.companyManager}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.companyManager" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="companyDeliveryDate"
            value={formValues.companyDeliveryDate}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.companyDeliveryDate" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="companyManagerEmail"
            value={formValues.companyManagerEmail}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.companyManagerEmail" />}
          />
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

Agency.propTypes = {
  formValues: PropTypes.shape({
    companyBestallare: PropTypes.string,
    companyManagerEmail: PropTypes.string,
    companyAnsvarig: PropTypes.string,
    companyOrderdate: PropTypes.string,
    companyEnhet: PropTypes.string,
    companyDeliveryDate: PropTypes.string,
    companyManager: PropTypes.string,
    companyAnsvarigUserId: PropTypes.number,
    companyDepartment: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelectResponsible: PropTypes.func.isRequired,
};

export default Agency;

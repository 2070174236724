import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_SELECTABLE_SUPPLIERS } from "../graphql/queries";

// eslint-disable-next-line import/prefer-default-export
export const useGetSelectableSuppliers = ({
  orderGroupId,
  selectedItemIds,
}) => {
  const [selectableSuppliers, setSelectableSuppliers] = useState([]);

  const { data, loading, error, refetch } = useQuery(GET_SELECTABLE_SUPPLIERS, {
    variables: {
      orderGroupId,
      shoppingCartItems: selectedItemIds,
    },
    skip: !orderGroupId,
  });

  useEffect(() => {
    refetch();
  }, [orderGroupId, selectedItemIds]);

  useEffect(() => {
    if (loading || error) {
      setSelectableSuppliers([]);
      return;
    }

    if (
      data &&
      data.shoppingCartContext &&
      data.shoppingCartContext.selectableSuppliers
    ) {
      // eslint-disable-next-line no-shadow
      const { selectableSuppliers = [] } = data.shoppingCartContext;
      setSelectableSuppliers(selectableSuppliers);
    }
  }, [data, loading, error]);

  return { refetch, selectableSuppliers, loading, error };
};

import React from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { Grid, Typography, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import PageTabPanel from "../common/PageTabPanel";
import CustomTextField from "../common/TextField";
import CheckboxDefault from "../common/FormControl/Checkbox";
import SelectNativeFull from "../common/FormControl/SelectNativeFull";
import IconLink from "../common/FormControl/IconLink";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  btnSub: {
    padding: "3px 20px",
    textTransform: "none",
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.8),
    },
  },
  btnMain: {
    padding: "5px 20px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  borderRight: {
    borderRight: "1px solid #dee2e6",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  label: {
    fontSize: 11,
  },
  labelItalic: {
    fontSize: 11,
    marginTop: 7,
    marginBottom: 7,
    fontStyle: "italic",
  },
  customForm: {
    "& .MuiInputBase-input": {
      fontSize: 11,
      paddingTop: 7,
      paddingBottom: 8,
    },
    "& .MuiSelect-select": {
      fontSize: 11,
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  space: {
    marginBottom: 5,
  },
  link: {
    marginRight: 5,
    color: theme.palette.common.link,
  },
  textLink: {
    fontSize: 11,
    color: theme.palette.common.link,
    marginRight: 10,
  },
}));

const UserInfoTab = () => {
  const classes = useStyles();

  return (
    <PageTabPanel spacing="3">
      <Grid item xs={12} md={6} className={classes.borderRight}>
        <Typography variant="h4" className={classes.title}>
          trafik.sydvast@kommunal.se
        </Typography>
        <Typography className={classes.text}>
          Adresser (kombinerad leverans och faktura-adress)
        </Typography>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={9}>
            <SelectNativeFull
              options={[{ label: "Kommunal Sydväst avd 10 Trafik", value: 1 }]}
            />
          </Grid>
          <Grid item md={3}>
            <Button className={classes.btnSub} fullWidth>
              <FormattedMessage id="btn.save" />
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography className={classes.text}>Företag</Typography>
        <Typography className={classes.text} color="primary">
          Kommunal
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.text}>Användaruppgifter</Typography>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <Typography className={classes.label}>Användarnamn</Typography>
          </Grid>
          <Grid item md={8}>
            <CustomTextField value="trafik.sydvast@kommunal.se" />
          </Grid>
        </Grid>
        <Typography className={classes.labelItalic}>
          Användarnamnet måste vara unikt
        </Typography>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <Typography className={classes.label}>Förnamn</Typography>
          </Grid>
          <Grid item md={8}>
            <CustomTextField value="Sydväst" />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <Typography className={classes.label}>Efternamn</Typography>
          </Grid>
          <Grid item md={8}>
            <CustomTextField value="- Trafik" />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography className={classes.text}>Byte av Lösenord</Typography>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <Typography className={classes.label}>Nytt lösenord</Typography>
          </Grid>
          <Grid item md={8}>
            <CustomTextField />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <Typography className={classes.label}>Bekräfta lösenord</Typography>
          </Grid>
          <Grid item md={8}>
            <CustomTextField />
          </Grid>
        </Grid>
        <Typography className={classes.labelItalic}>
          Ange ett lösenord ovan om du vill byta annars lämna blankt.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography className={classes.text}>Språk och Roller</Typography>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <Typography className={classes.label}>Språk</Typography>
          </Grid>
          <Grid item md={8}>
            <SelectNativeFull options={[{ label: "Svenska", value: 1 }]} />
          </Grid>
        </Grid>
        <Box className={classes.space} />
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <Typography className={classes.label}>Roll</Typography>
          </Grid>
          <Grid item md={8}>
            <SelectNativeFull options={[{ label: "Beställare", value: 1 }]} />
          </Grid>
        </Grid>
        <CheckboxDefault label="Aktiv" />
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={6}>
            <CheckboxDefault label="Utökade admin-rättigheter" />
          </Grid>
          <Grid item md={6}>
            <CheckboxDefault label="Tryckförfrågan" />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography className={classes.text}>Sajter</Typography>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <CheckboxDefault cbcolor="blue" label="Beata" />
          </Grid>
          <Grid item md={4}>
            <CheckboxDefault cbcolor="blue" label="Klara" />
          </Grid>
          <Grid item md={4}>
            <CheckboxDefault cbcolor="blue" label="Matilda" />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className={classes.customForm}
        >
          <Grid item md={4}>
            <CheckboxDefault cbcolor="blue" label="Pim" />
          </Grid>
          <Grid item md={4}>
            <CheckboxDefault cbcolor="blue" label="Siv" />
          </Grid>
          <Grid item md={4}>
            <CheckboxDefault cbcolor="blue" label="SKPF" />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Box align="right" alignItems="center">
          <IconLink
            icon="fa fa-user-times"
            text="Radera Användare"
            customClass={classes.link}
            customTextClass={classes.textLink}
          />
          <Button className={classes.btnMain}>
            <FormattedMessage id="btn.save" />
          </Button>
        </Box>
      </Grid>
    </PageTabPanel>
  );
};

export default UserInfoTab;

import { useLazyQuery } from "@apollo/client";
import { LIST_CONSENTS } from "../../graphql/queries/ConsentContext";

// eslint-disable-next-line import/prefer-default-export
export const useListConsents = () => {
  const [listConsentsQuery, { data, loading, error }] = useLazyQuery(LIST_CONSENTS);

  const listConsents = async ({ siteId } = {}) => {
    listConsentsQuery({
      variables: {
        siteId
      }
    });
  };

  return [listConsents, { data: data?.consentContext?.listConsents, loading, error }];
};

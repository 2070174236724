import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
  root: {
    justifyContent: "space-evenly",
  },
  labelWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
  },
  fieldWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
}));

const SupplierCheckboxes = ({
  label,
  suppliers,
  selectedSuppliers,
  handleOnSelectSuppliers,
}) => {
  const classes = useStyles();
  return (
    <Grid container mt={0.5} mb={0.5}>
      <Grid item xs={12} md={2} className={classes.labelWrap}>
        <span className={classes.label}>{label}</span>
      </Grid>
      <Grid item xs={12} md={10} className={classes.fieldWrap}>
        <FormGroup
          classes={{
            root: classes.root,
          }}
          row
        >
          {suppliers.map(x => (
            <Checkbox
              name={x.supplierId}
              label={x.displayName}
              value={
                selectedSuppliers &&
                Array.isArray(selectedSuppliers) &&
                selectedSuppliers.includes(Number(x.supplierId))
              }
              cbcolor="blue"
              onChange={handleOnSelectSuppliers}
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

SupplierCheckboxes.propTypes = {
  handleOnSelectSuppliers: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  suppliers: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      supplierId: PropTypes.number.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  selectedSuppliers: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SupplierCheckboxes;

import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { useLazyQuery, useMutation } from "@apollo/client";
import { useTheme } from "@emotion/react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Container, Dialog, DialogTitle, DialogContent, Grid, Paper, Stack, Typography, ThemeProvider } from "@mui/material"
import { styled } from "@mui/system";

import { PrimaryButton, GrayButton } from "../../components/common";
import { ConsentCard, ConsentTerm } from "../../components/Consent";
import { ANSWER_CONSENT_REQUEST } from "../../graphql/public/mutations";
import { GET_CONSENT_REQUEST } from "../../graphql/public/queries";
import { client } from "../../graphql/publicClient";
import theme from "../../theme/index";

const ConsentContainer = styled(Container)({
  display: 'flex',
  overflowX: 'auto',
  margin: 0,
  flexWrap: 'wrap',
  flexDirection: 'row',
});

const HeaderTypography = styled(Typography)({
  fontWeight: "bold",
});

const StyledConsentCard = styled(ConsentCard)(({ theme }) => ({
  height: 190,
  margin: theme.spacing(1),
  position: 'relative',
  width: 190,
}));

const ConsentPage = () => {

  const [getConsentRequest, { data, loading, error }] = useLazyQuery(GET_CONSENT_REQUEST, { client });
  const [answerConsent, { data: updateResult, loading: updateLoading, error: updateError }] = useMutation(ANSWER_CONSENT_REQUEST, { client });

  const [request, setRequest] = useState();
  const [processed, setProcessed] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const token = query.get("token");
  const kommunalTheme = theme("Kommunal");
  //const theme = useTheme();

  useEffect(() => {
    if (!request) {
      getConsentRequest({ variables: { token: token } });
    }
  }, [request, token])

  useEffect(() => {
    if (data?.getConsentRequest) {
      var modifiedData = { ...data.getConsentRequest };
      modifiedData.resources = modifiedData.resources.map(r => ({ ...r, checked: true }));
      setRequest(modifiedData);
    }
    else if (error) {
      //...
    }
  }, [data, loading, error]);

  const onImageButtonClick = (resourceId) => {
    const index = request.resources.findIndex(resource => resource.resourceId === resourceId);

    if (index !== -1) {
      const updatedResources = [...request.resources];

      updatedResources[index] = {
        ...updatedResources[index],
        checked: !updatedResources[index].checked
      };

      setRequest(prevState => ({
        ...prevState,
        resources: updatedResources
      }));
    }
  }

  const handleSendConsent = () => {
    var approvedResourceIds = request.resources.filter(resource => resource.checked).map(n => n.resourceId);
    var deniedResourceIds = request.resources.filter(resource => !resource.checked).map(n => n.resourceId);

    answerConsent({
      variables: {
        token: token,
        approvedResourceIds: approvedResourceIds,
        deniedResourceIds: deniedResourceIds
      }
    });
  }

  useEffect(() => {
    if (updateResult?.answerConsentRequest) {
      setProcessed(true);
    }
  }, [updateResult, updateLoading, updateError]);

  const handleSendDenied = () => {
    var deniedResourceIds = request.resources.map(n => n.resourceId);

    answerConsent({
      variables: {
        token: token,
        approvedResourceIds: [],
        deniedResourceIds,
      }
    });
  }

  const ButtonPrimary = () => {
    const numConsentedImages = request?.resources?.filter(resource => resource.checked).length;
    return (
      <PrimaryButton onClick={handleSendConsent}> Jag samtycker med {numConsentedImages} bilder</PrimaryButton>
    );
  }

  const ButtonRejectAll = () => {
    return (
      <GrayButton onClick={handleSendDenied} marginRight="10px">Avvisa allt</GrayButton>
    );
  }

  return (
    <ThemeProvider theme={kommunalTheme}>
      <Paper sx={{ display: "flex", padding: 2 }}>
        <Grid container>

          <Grid item xs={8}>
            <ConsentContainer>
              {request && request.resources.map((resource) => {
                const { checked, resourceId, url } = resource;
                return (
                  <StyledConsentCard
                    consented={checked}
                    imageUrl={url}
                    key={resourceId}
                    onConsentChanged={() => onImageButtonClick(resourceId)}
                  />
                );
              })}
            </ConsentContainer>
          </Grid>

          <Grid item xs={4}>
            {request && (
              <Stack direction="column">
                <Box sx={{ paddingBottom: 1 }}>
                  <ButtonRejectAll alwaysShow />
                  <ButtonPrimary />
                </Box>
                <HeaderTypography>
                  Samtycke till lagring och användning av bilder
                </HeaderTypography>
                <Typography sx={{ marginBottom: "1em" }}>
                  Kommunal använder vår plattform Wilma (UniQueues Mediabank) för smidig lagring och arkivering av viktiga resurser som bilder, video, och grafik mm ("Materialet"). Hos Wilma är det tryggt och säkert att hantera allt ditt material. Du har full kontroll över hur den personliga informationen behandlas i Wilma, och det finns stöd tillgängligt om du behöver hjälp. Kommunal är själva uppgiftsansvariga för behandlingen av den personliga informationen i Wilma. Alla användare i Wilma har tillgång till det material som tillgängliggjorts i Wilma.
                </Typography>
                <Typography sx={{ marginBottom: "1em" }}>
                  Välkommen till Wilma!
                </Typography>
                <Stack>
                  <HeaderTypography>
                    <FormattedMessage id="consent.terms" />
                  </HeaderTypography>
                  <ConsentTerm name="villkor1" value={true} label="Jag håller med om att bild/video kan sparas" readOnly disabled />
                  <ConsentTerm name="villkor2" value={true} label="Samtycke för externt bruk" readOnly />
                  <ConsentTerm name="villkor3" value={true} label="Bilden får endast användas i intern kommunikation" readOnly />
                  <ConsentTerm name="villkor4" value={true} label="Bilden får användas i marknadsföring" readOnly />
                </Stack>
                <Box>
                  <ButtonPrimary />
                </Box>
              </Stack>
            )}
          </Grid>

          <Dialog open={processed}>
            <DialogTitle>Svar på begäran skickad</DialogTitle>
            <DialogContent>
              <Box display="flex" alignItems="center">
                <CheckCircleOutlineIcon />
                <Typography>
                  Vi har mottagit ditt svar, du kan stänga webbläsarfönstret.
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog open={error || request?.resources?.length == 0}>
            <DialogTitle>Kunde inte hitta begäran</DialogTitle>
            <DialogContent>
              <Box display="flex" alignItems="center">
                <Typography>
                  Den här begäran om godkännande kunde inte hittas.
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>

        </Grid>
      </Paper>
    </ThemeProvider>
  )
}

export default ConsentPage;

import { useEffect, useState } from "react";
import { Dialog, Box, Grid, DialogContent } from "@mui/material";
import { ModalCompact, ModalCompactBody, ModalCompactHead } from "../../common";

const NewspostModal = ({ newsPost, open, onClose }) => {
  const handleClose = () => {
    onClose();
  }

  return (<ModalCompact open={open} fullScreen >
    <ModalCompactHead handleClose={handleClose} title={newsPost.title}></ModalCompactHead>
    <ModalCompactBody>    
      <Box sx={{ width: 720, height: "calc(100vh - 90px)", display: "flex", justifyContent: "center", margin: "0 auto" }}>
        {newsPost && (
          <iframe width="100%" srcDoc={newsPost.newsPost} style={{ border: 0, width: "100%", height: "calc(100vh - 30px)" } } frameBorder="0"></iframe>       
        
        )}
      </Box>


    </ModalCompactBody>
  </ModalCompact>)
}

export default NewspostModal;
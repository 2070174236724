import { BrowserRouter as Router, Route } from "react-router-dom";

import DeepLinkRoutes from "./deepLinkRoutes";
import PrivateRoute from "./privateRoute";
import PrivateRouteWithPublicFallback from "./privateRouteWithPublicFallback";

import { InitializeScreen } from "../components/common";
import { Logout } from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
import { SilentRenew } from "../components/auth/silentRenew";
import { SigninSilent } from "../components/auth/signinSilent";

import Administration from "../pages/Administration";
import ConsentPage from "../pages/ConsentPage";

import BillingsPage from "../pages/Administration/BillingsPage";
import BillsAndPaymentPage from "../pages/Administration/BillsAndPaymentPage";
import BookingPage from "../pages/Booking";

import CallbackPage from "../pages/Callback";
import CartPage from "../pages/Administration/CartPage";
import Checkout from "../pages/Checkout";
import CompanySettings_AddressPage from "../pages/Administration/CompanySettings/CompanySettings_AddressPage";
import CompanySettings_CompanyDataPage from "../pages/Administration/CompanySettings/CompanySettings_CompanyDataPage";
import CompanySettings_CompanyNetworksPage from "../pages/Administration/CompanySettings/CompanySettings_CompanyNetworksPage";
import CompanyRolesPage from "../pages/Administration/CompanyRolesPage";
import CompanySettings_BasicSettingsPage from "../pages/Administration/CompanySettings/CompanySettings_BasicSettingsPage";
import CompanySettings_AppearancePage from "../pages/Administration/CompanySettings/CompanySettings_AppearancePage";
import CompanySubscriptionsPage from "../pages/Administration/CompanySubscriptionsPage";
import CompanySettings_CompanySecurityPage from "../pages/Administration/CompanySettings/CompanySettings_CompanySecurityPage";

import CompanyTemplatesPage from "../pages/Administration/CompanyTemplatesPage";
import CompInfo from "../pages/Administration/CompInfo";
import CreateAccountPage from "../pages/CreateAccount";

import SentConsents from "../pages/SentConsents";

import DocumentPage from "../pages/Administration/DocumentPage";

import ExternalUserPage from "../pages/Administration/ExternalUserPage";

import Help from "../pages/Help";
import HomepageSettingsPage from "../pages/Administration/HomepageSettingsPage";

import ImageBasket from "../pages/ImageBasket";
import ImportPage from "../pages/Administration/ImportPage";
import IncomingOrders from "../pages/IncomingOrders";
import InvoicePage from "../pages/Administration/InvoicePage";
import Issues from "../pages/Issues";

import ListsPage from "../pages/Administration/ListsPage";
import Loading from "../pages/LoadingScreen";
import CompanySettings_LogsPage from "../pages/Administration/CompanySettings/CompanySettings_LogsPage";
import ForceMFASetupPage from "../pages/ForceMFASetupPage";

import MessagingPage from "../pages/MessagingPage";
import MyMonitoring from "../pages/MyMonitoring";
import MyShares from "../pages/MyShares";
import MyConsents from "../pages/MyConsents"

import NewsFullpage from "../pages/NewsFullPage";
import NewsList from "../pages/NewsList";
import NewspostDisplay from "../pages/NewspostDisplay";
import NewsSample from "../pages/NewsSample";

import OneStream from "../pages/OneStream";
import OrderConfirmation from "../pages/OrderConfirmation";
import OrderHistory from "../pages/OrderHistory";
import OverviewBinder from "../pages/OverviewBinder";
import OverviewDashboardPage from "../pages/OverviewDashboard";

import PaymentsPage from "../pages/Administration/PaymentsPage";
import PickingList from "../pages/PickingList";
import ProjectPage from "../pages/ProjectPage";
import ProjectTaskPage from "../pages/ProjectTaskPage";
import PublicationsPage from "../pages/Administration/PublicationsPage";

import RequestForProposal from "../pages/RequestForProposal";
import ReservationsPage from "../pages/Administration/ReservationsPage";

import SalesInvoicesPage from "../pages/Administration/Sales/InvoicesPage";
import SalesOrdersPage from "../pages/Administration/Sales/OrdersPage";
import SalesQuotationsPage from "../pages/Administration/Sales/QuotationsPage";
import SalesQuoteRequestsPage from "../pages/Administration/Sales/QuoteRequestsPage";
import SavedNotifications from "../pages/SavedNotifications";
import SharedImageBasket from "../pages/SharedImageBasket";
import SharedWorkspace from "../pages/SharedWorkspace";
import SiteSettingsPage from "../pages/Administration/SiteSettingsPage";
import SitesPage from "../pages/Administration/SitesPage";
import SitePersons from "../pages/SitePersons";
import SiteTemplatesPage from "../pages/Administration/SiteTemplatesPage";
import SiteUsers from "../pages/SiteUsers";
import StatisticsPage from "../pages/Administration/StatisticsPage";
import StockProducts from "../pages/StockProducts";
import Supplier from "../pages/Administration/Supplier";
import SupplierSettingsPage from "../pages/SupplierSettingsPage";
import SuppliersPage from "../pages/SuppliersPage";

import TermsOfUsePage from "../pages/TermsOfUsePage";
import TextsPage from "../pages/Administration/TextsPage";

import UnsubscribeNotification from "../pages/UnsubscribeNotification";
import UserGroupsPage from "../pages/Administration/UserGroupsPage";

import Widgets from "../pages/Widgets";
import WorkspaceDashboardPage from "../pages/WorkspaceDashboard";
import WorkspaceEdit from "../pages/WorkspaceEdit";

const PageRoute = (
  <Router getUserConfirmation={() => { }}>
        
    <Route exact path="/signin-oidc" component={CallbackPage} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/logout/callback" component={LogoutCallback} />
    <Route exact path="/silentrenew" component={SilentRenew} />
    <Route exact path="/signin-silent" component={SigninSilent} />
    <Route path="/initialize" component={InitializeScreen} />
    <Route path="/loading" component={Loading} />
    <Route path="/create-account" component={CreateAccountPage} />
    <Route path="/consent" component={ConsentPage}/>

    <DeepLinkRoutes path="/file-view" />
    <DeepLinkRoutes path="/image-gallery-view" />
    <DeepLinkRoutes path="/image-view" />
    <DeepLinkRoutes path="/product-view" />

    <PrivateRouteWithPublicFallback
      noConsentCheck
      path="/terms-of-use"
      privateComponent={TermsOfUsePage}
      publicComponent={TermsOfUsePage}
    />

    <PrivateRoute path="/" component={OverviewDashboardPage} />

    <PrivateRoute path="/administration" component={Administration} />
    <PrivateRoute path="/administration/billings" component={BillingsPage} />
    <PrivateRoute path="/administration/bills-and-payment" component={BillsAndPaymentPage} /* TODO */ />
    <PrivateRoute path="/administration/cart" component={CartPage} /* TODO */ />
    <PrivateRoute path="/administration/company-settings/address" component={CompanySettings_AddressPage} />
    <PrivateRoute path="/administration/company-settings/appearance" component={CompanySettings_AppearancePage} />
    <PrivateRoute path="/administration/company-settings/basic-settings" component={CompanySettings_BasicSettingsPage} />
    <PrivateRoute path="/administration/company-settings/company-data" component={CompanySettings_CompanyDataPage} />
    <PrivateRoute path="/administration/company-settings/company-networks" component={CompanySettings_CompanyNetworksPage} />
    <PrivateRoute path="/administration/company-settings/company-security" component={CompanySettings_CompanySecurityPage} />
    <PrivateRoute path="/administration/company-settings/logs" component={CompanySettings_LogsPage} />
    <PrivateRoute path="/administration/company-info" component={CompInfo} /* TODO */ />
    <PrivateRoute path="/administration/company-roles" component={CompanyRolesPage} />

    <PrivateRoute path="/administration/company-templates" component={CompanyTemplatesPage} />
    <PrivateRoute path="/administration/document-inspector" component={DocumentPage} /* TODO */ />
    <PrivateRoute path="/administration/external-user" component={ExternalUserPage} />
    <PrivateRoute path="/administration/homepage" component={HomepageSettingsPage} />
    <PrivateRoute path="/administration/import" component={ImportPage} />
    <PrivateRoute path="/administration/invoices" component={InvoicePage} /* TODO */ />
    <PrivateRoute path="/administration/lists" component={ListsPage} /* TODO */ />
    <PrivateRoute path="/administration/payments" component={PaymentsPage} />
    <PrivateRoute path="/administration/publications" component={PublicationsPage} /* TODO */ />
    <PrivateRoute path="/administration/reservations" component={ReservationsPage} />
    <PrivateRoute path="/administration/sales/invoices" component={SalesInvoicesPage} />
    <PrivateRoute path="/administration/sales/orders" component={SalesOrdersPage} />
    <PrivateRoute path="/administration/sales/quotations" component={SalesQuotationsPage} />
    <PrivateRoute path="/administration/sales/quote-requests" component={SalesQuoteRequestsPage} />
    <PrivateRoute path="/administration/site-settings" component={SiteSettingsPage} />
    <PrivateRoute path="/administration/site-templates" component={SiteTemplatesPage} />
    <PrivateRoute path="/administration/statistics" component={StatisticsPage} />
    <PrivateRoute path="/administration/subscriptions" component={CompanySubscriptionsPage} />
    <PrivateRoute path="/administration/supplier" component={Supplier} />
    <PrivateRoute path="/administration/texts" component={TextsPage} /* TODO */ />
    <PrivateRoute path="/administration/users" component={UserGroupsPage} />
    <PrivateRoute path="/administration/workspaces" component={SitesPage} />

    
    <PrivateRoute path="/booking" component={BookingPage} />

    <PrivateRoute path="/checkout" component={Checkout} />
    <PrivateRoute path="/company-supplier-settings" component={SupplierSettingsPage} />

    <PrivateRoute path="/help" component={Help} />

    <PrivateRoute path="/image-basket" component={ImageBasket} />

    <PrivateRoute path="/incomingOrders" component={IncomingOrders} />
    <PrivateRoute path="/issues" component={Issues} />

    <PrivateRoute path="/setupMFA" component={ForceMFASetupPage} />

    <PrivateRoute path="/messaging" component={MessagingPage} />
    <PrivateRoute path="/my-monitoring" component={MyMonitoring} />
    <PrivateRoute path="/my-order-history" component={OrderHistory} />
    <PrivateRoute path="/my-shares" component={MyShares} />
    <PrivateRoute path="/mynotifications" component={SavedNotifications} />
    <PrivateRoute path="/my-consent-requests" component={MyConsents}/>

    <PrivateRoute path="/news-full/:id" component={NewsFullpage} />
    <PrivateRoute path="/news-list" component={NewsList} />
    <PrivateRoute path="/news-post" component={NewspostDisplay} />
    <PrivateRoute path="/news-post/:id" component={NewspostDisplay} />
    <PrivateRoute path="/news-sample" component={NewsSample} />

    <PrivateRoute path="/onestream" component={OneStream} />
    <PrivateRoute path="/order-confirmation" component={OrderConfirmation} />
    <PrivateRoute path="/order-history" component={OrderHistory} />
    <PrivateRoute path="/organize-workspaces" component={WorkspaceEdit} />
    <PrivateRoute path="/overview-binder" component={OverviewBinder} />

    <PrivateRoute path="/picking-list" component={PickingList} />
    <PrivateRoute path="/projects" component={ProjectPage} />
    <PrivateRoute path="/projectedit" component={ProjectTaskPage}/>

    <PrivateRoute path="/request-for-proposal" component={RequestForProposal} />

    <PrivateRoute path="/shared-image-basket/:shareId" component={SharedImageBasket} />
    <PrivateRoute path="/shared-workspace" component={SharedWorkspace} /* Demo sida ej riktig kod */ />
    <PrivateRoute path="/stock-products" component={StockProducts} />
    <PrivateRoute path="/suppliers" component={SuppliersPage} />

    <PrivateRoute path="/unsubscribe_notification" component={UnsubscribeNotification} />

    <PrivateRoute path="/widgets" component={Widgets} />
    <PrivateRoute path="/workspace-dashboard" component={WorkspaceDashboardPage} />
    <PrivateRoute path="/workspace-persons" component={SitePersons} />
    <PrivateRoute path="/workspace-users" component={SiteUsers} />

    <PrivateRoute path="/SentConsents" component={SentConsents}/>
  </Router>
);

export default PageRoute;

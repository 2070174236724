import { gql } from '@apollo/client';
import WorkspaceOwnerCandidateListItemFragment from "../../../fragments/WorkspaceOwnerCandidateListItem";
import ErrorResultFragment from "../../../fragments/ErrorResult";

const LIST_WORKSPACE_OWNER_CANDIDATES = gql`
  query ListWorkspaceOwnerCandidates($companyId: Int!) {
    companyAdminContext {
      companyWorkspaces {
        listWorkspaceOwnerCandidates(companyId: $companyId) {
          errorResult {
            ...ErrorResultFields
          }
          items {
            ...WorkspaceOwnerCandidateListItem
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${WorkspaceOwnerCandidateListItemFragment}
`;

export default LIST_WORKSPACE_OWNER_CANDIDATES;

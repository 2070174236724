import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Box, Divider } from "@mui/material";
import { debounce } from "lodash";
import Search from "./Search";
import FolderTreeContainer from "../FolderTreeContainer";

const SearchModal = ({ handleFolderRowClick }) => {
  const folders = useSelector(state => state.api.siteFolders.folders);
  const [openedNodes, setOpenedNodes] = useState([]);
  const [foundFolders, setFoundFolders] = useState([]);

  const handleOpenNode = id => {
    const nodeIndex = openedNodes.indexOf(id);
    if (nodeIndex === -1) {
      setOpenedNodes([...openedNodes, id]);
    } else {
      const tempOpenedNodes = openedNodes.filter(x => x !== id);
      setOpenedNodes(tempOpenedNodes);
    }
  };

  const findChildFolders = ({ folder, searchText }) => {
    let tempIds = [];
    let nodeIds = [];
    if (folder.name.toUpperCase().indexOf(searchText) > -1) {
      const found = tempIds.some(x => x === folder.folderId);
      if (!found) {
        tempIds = [...tempIds, folder.folderId];
        if (!nodeIds.includes(folder.parentFolderId)) {
          nodeIds = [...nodeIds, folder.parentFolderId];
        }
      }
    }
    if (folder && folder.childFolders) {
      folder.childFolders.forEach(childFolder => {
        const childFolderIds = findChildFolders({
          folder: childFolder,
          searchText,
        });
        tempIds = [...tempIds, ...childFolderIds.folderIds];
        nodeIds = [...nodeIds, ...childFolderIds.nodeIds];
      });
    }

    return { folderIds: tempIds, nodeIds };
  };

  const findFolders = searchText => {
    let tempIds = [];
    let nodeIds = [];
    const upperCaseSearchText = searchText.toUpperCase();
    folders.forEach(folder => {
      if (folder.name.toUpperCase().indexOf(upperCaseSearchText) > -1) {
        const found = tempIds.some(x => x === folder.folderId);
        if (!found) {
          tempIds = [...tempIds, folder.folderId];
        }
      }

      if (folder && folder.childFolders) {
        folder.childFolders.forEach(childFolder => {
          const childFolderIds = findChildFolders({
            folder: childFolder,
            searchText: upperCaseSearchText,
          });

          tempIds = [...tempIds, ...childFolderIds.folderIds];
          nodeIds = [...nodeIds, ...childFolderIds.nodeIds];
        });
      }
    });
    return { folderIds: tempIds, nodeIds };
  };

  const handleFindFolders = searchQuery => {
    if (searchQuery) {
      const foundFolderData = findFolders(searchQuery);
      const { folderIds, nodeIds } = foundFolderData;
      setFoundFolders(folderIds);
      setOpenedNodes(nodeIds);
    } else {
      setFoundFolders([]);
    }
  };

  const debounceFindFolders = useCallback(
    debounce(value => {
      handleFindFolders(value);
    }, 600),
    []
  );

  return (
    <Box>
      <Search setQuery={debounceFindFolders} />
      <Divider />
      <FolderTreeContainer
        folders={folders}
        handleOpenNode={handleOpenNode}
        openedNodes={openedNodes}
        setOpenedNodes={setOpenedNodes}
        foundFolders={foundFolders}
        handleFolderRowClick={handleFolderRowClick}
      />
    </Box>
  );
};

SearchModal.propTypes = {
  handleFolderRowClick: PropTypes.func.isRequired,
};

export default SearchModal;

import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Box } from "@mui/material";

import { setCompany, setCompanies } from "../../../actions";
import { GET_AVAILABLE_COMPANIES } from "../../../graphql/queries/CompanyAdminContext";
import { getCurrentCompanyId } from "../../../helpers/selectors";
import { getAdminAvailableCompanies, getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";
import AdminCompanySelect from "./AdminCompanySelect";

const AdminCompanySelector = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [getCompanies, { data, loading: loadingCompanies }] = useLazyQuery(GET_AVAILABLE_COMPANIES);

  const adminUserCompanyId = useSelector(getCurrentCompanyId);
  const companies = useSelector(getAdminAvailableCompanies);
  const companyId = useSelector(getAdminSelectedCompanyId);

  useEffect(() => {
    if (Array.isArray(companies) && companies.length === 0) {
      getCompanies();
    }
  }, [companies]);

  useEffect(() => {
    if (
      data &&
      data.companyAdminContext &&
      Array.isArray(data.companyAdminContext.availableCompanies) &&
      data.companyAdminContext.availableCompanies.length > 0
    ) {
      const { availableCompanies } = data.companyAdminContext;

      const tempCompanies = availableCompanies.map(x => {
        return {
          label: x.name,
          value: x.companyId,
          usersRights: x.usersRights,
        };
      });

      dispatch(setCompanies({ items: tempCompanies }));
      dispatch(
        setCompany({
          companyId: data.companyAdminContext.availableCompanies[0].companyId,
          companyName: data.companyAdminContext.availableCompanies[0].name,
          usersRights:
            data.companyAdminContext.availableCompanies[0].usersRights,
        })
      );
    }
  }, [data]);

  const history = useHistory();

  const handleChange = item => {
    const company = companies.filter(x => x.value === item.target.value);
    dispatch(
      setCompany({
        companyId: item.target.value,
        companyName: company[0].label,
        usersRights: company[0].usersRights,
      })
    );
    history.push("/administration");
  };

  return (
    <Box>
      <AdminCompanySelect
        adminUserCompanyId={adminUserCompanyId}
        inputLabel={intl.formatMessage({ id: "admin.companySelector.label" })}
        onChange={handleChange}
        options={companies}
        value={companyId}
      />
    </Box>
  );
};

export default AdminCompanySelector;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const getBookingFolderSettings = gql`
  query GetBookingFolderSettings($siteId: Int!) {
    bookingContext {
      getBookingFolderSettings(siteId: $siteId) {
        success
        errorResult {
          ...ErrorResultFields
        }
        bookingFolderSettings {
          allowedBookingDays
          folderName
          folderId
          pickupTime
          returnTime
          siteId
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default getBookingFolderSettings;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const REMOVE_COMPANY = gql`
  mutation RemoveCompany(
    $companyId: Int!
    $networkId: Int!
    $targetCompanyId: Int!
  ) {
    companyAdminContext {
      companyNetworks {
        removeCompany(
          companyId: $companyId
          networkId: $networkId
          targetCompanyId: $targetCompanyId
        ) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default REMOVE_COMPANY;

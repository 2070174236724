import { gql } from '@apollo/client';

import Task from "../fragments/Projects/Task";
import Project from "../fragments/Projects/Project";

const GET_PROJECT_EVENTS = gql`
  subscription ProjectEventsSubscription($authToken: String!, $projectId:Int!) {
    projectEvents(authToken: $authToken, projectId: $projectId) {
			userId
			projectId
			taskId		
			eventType
			eventTime
			task{
				...Task
			}
			project{
				...Project
			}
    }
  }
	${Task}
	${Project}
`;

export default GET_PROJECT_EVENTS;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import {
  Typography,
  Grid,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CustomTextField from "../../common/TextField";
import TextAreaFull from "../../common/FormControl/TextAreaFull";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  switchRoot: {
    marginRight: 30,
  },
  switchLabel: {
    fontSize: 12,
  },
}));

const NewDropdownModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="Dropdown list" />
      <DraggableModalBody>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Name</Typography>
            <CustomTextField />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label="Allow free entry where the list is used"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Name</Typography>
            <TextAreaFull rows="10" rowsMax="10" />
          </Grid>
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            Save list
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewDropdownModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NewDropdownModal;

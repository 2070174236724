import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useMutation } from "@apollo/client";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  searchBeataWidget,
  selectWorkspace,
  setDoDetailedSearch,
  setFileViewOpenNodes,
  setFolderData,
  setFolderId,
  setSearchQuery,
  setSearchResults,
  toggleDetails,
} from "../../actions";
import CreateNameModal from "../../components/common/CreateNameModal";
import { ProductsMainView } from "../../components/ProductsMainView";
import {
  FOLDER_READ_ACCESS,
  FOLDER_WRITE_ACCESS,
} from "../../constant/errorCodes";
import {
  DOCUMENT_VIEW,
  FILE_VIEW,
  IMAGE_VIEW,
  PRODUCT_LIST_VIEW,
} from "../../constant/types";
import { CREATE_FOLDER } from "../../graphql/mutations";
import { getFileViewOpenNodes } from "../../helpers/fileViewSelectors";
import {
  useDeepLinkContext,
  useGetSiteFolders,
  useInitializeFolderDownload,
  useSnackbar,
} from "../../hooks";
import useChangeCurrentWorkspace from "../../hooks/useChangeCurrentWorkspace";
import FolderCategoryTree from "./FolderCategoryTree";
import FolderTreeMenuButton from "./FolderTreeMenuButton";
import ImportDialog from "./ImportDialog";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
    maxHeight: 860,
    overflowX: "auto",
    position: "relative",
  },
  gridRight: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  container: {
    padding: "0 15px",
  },

  productContent: {
    color: theme.palette.text.secondary,
    minHeight: "80vh",
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid  ${theme.palette.common.lightGrey}`,
    },
  },
  tree: {
    flexGrow: 1,
    maxWidth: "auto",
  },
  newFolderBtnCont: {
    fontSize: 14,
    color: theme.palette.primary.contrastText,
  },
  newFolderBtn: {
    color: theme.palette.primary.contrastText,
    opacity: 0.8,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.6,
    },
    textTransform: "unset",
    borderRadius: "15px",
    verticalAlign: "right"
  },
}));

const findFolder = (folderId, folders) => {
  const length = folders.length;
  if (length === 0) {
    return undefined
  }
  const folder = folders.find(n => n.folderId == folderId);
  if (!!folder) {
    return { folder, nodes: [folder.folderId] };
  }
  for (var i = 0; i < length; i++) {
    const folder = folders[i];
    const result = findFolder(folderId, folder.childFolders);
    if (!!result) {
      return { folder: result.folder, nodes: [folder.folderId, ...result.nodes] };
    }
  }
  return undefined;
};

const ProductView = ({
  beataWidget,
  defaultView,
  location,
  numOfLinks,
  searchBeataWidget,
  searchDetails,
  searchQuery,
  selectedWorkspace,
  setSearchQuery,
  setSearchResults,
  viewPathName,
}) => {
  const changeCurrentWorkspace = useChangeCurrentWorkspace();

  const classes = useStyles();
  const snackbar = useSnackbar();
  const intl = useIntl();
  const alert = useAlert();

  const [folderId, setFolderId] = useState(null);
  const folders = useSelector(state => state.api.siteFolders.folders);

  const deepLinkContext = useDeepLinkContext();
  const { deepLink } = deepLinkContext;

  const dispatch = useDispatch();
  const workspaces = useSelector(state => state.api.currentViewer.viewer.sites);

  const [openedNodes, setOpenedNodes] = useState([]);
  const fileViewNodes = useSelector(getFileViewOpenNodes);

  const siteRoleRights = useSelector(
    state => state.ui.toggleWorkspaces.workspace.siteRoleRights
  );

  const sortingOptions = useSelector(
    state => state.api.productView.productView.sortingOptions
  );

  const [importModal, setImportModal] = useState(false);
  const [nameModal, setNameModal] = useState(false);
  const [newName, setNewName] = useState(
    intl.formatMessage({ id: "context.newFolder" })
  );
  const [view, setView] = useState("");

  const [createFolder] = useMutation(CREATE_FOLDER);
  const { execute: getSiteFolders, data: siteFoldersData, error: siteFoldersError, loading } = useGetSiteFolders();

  const [initializeFolderDownload] = useInitializeFolderDownload({
    onError: errorResult => {
      console.error("Failed to initialize download.", errorResult || "");
    },
    onSuccess: url => {
      window.open(url);
    },
  });

  const handleSiteDownloadClick = () => {
    const siteId = selectedWorkspace?.id;
    if (!!siteId) {
      initializeFolderDownload({ siteId });
    }
  }

  const handleCloseNameModal = () => {
    setNewName(intl.formatMessage({ id: "context.newFolder" }));
    setNameModal(false);
  };

  const handleOpenNameModal = () => {
    setNameModal(true);
  };

  const handleGetSiteFolders = () => {
    getSiteFolders({
      variables: {
        siteId: selectedWorkspace.id,
      },
    });
  };

  useEffect(() => {
    if (selectedWorkspace && selectedWorkspace.id) {
      handleGetSiteFolders();
    }
  }, [selectedWorkspace]);

  const handleOpenNode = id => {
    const nodeIndex = openedNodes.indexOf(id);
    if (nodeIndex === -1) {
      setOpenedNodes([...openedNodes, id]);
    } else {
      const tempOpenedNodes = openedNodes.filter(x => x !== id);
      setOpenedNodes(tempOpenedNodes);
    }
  };

  useEffect(() => {
    if (viewPathName) {
      if (viewPathName === "/product-view") {
        setView(PRODUCT_LIST_VIEW);
      } else if (viewPathName === "/file-view") {
        setView(FILE_VIEW);
      } else if (viewPathName === "/image-view") {
        setView(DOCUMENT_VIEW);
      } else if (viewPathName === "/image-gallery-view") {
        setView(IMAGE_VIEW);
      } else {
        setView(defaultView);
      }
    } else {
      setView(defaultView);
    }
  }, [viewPathName]);

  useEffect(() => {
    if (fileViewNodes.length > 0) {
      const tempId = fileViewNodes[fileViewNodes.length - 1];
      setFolderId(tempId);
      setOpenedNodes(fileViewNodes);
    }
  }, [fileViewNodes]);

  useEffect(() => {
    if (!deepLink) {
      return;
    }

    const { siteId, folderId, resourceId } = deepLink;

    if (!siteId && !folderId && !resourceId) {
      deepLinkContext.clearData();
      return;
    }

    if (siteId) {
      if (selectedWorkspace?.id == siteId) {
        deepLinkContext.clearSiteId();
        return;
      }

      const workspace = workspaces.find(n => n.id == siteId);
      if (workspace) {
        changeCurrentWorkspace(siteId, false);
      }
      else {
        deepLinkContext.clearData();
      }

      return;
    }

    if (folderId) {
      if (loading) {
        return;
      }

      if (siteFoldersError || !siteFoldersData) {
        deepLinkContext.clearData();
        return;
      }

      const findFolderResult = findFolder(folderId, siteFoldersData.folders);
      if (findFolderResult) {
        const { folder, nodes } = findFolderResult;
        const isOpened = fileViewNodes[fileViewNodes.length - 1] == nodes[nodes.length - 1];
        if (isOpened) {
          deepLinkContext.clearFolderId();
        }
        else {
          dispatch(setFileViewOpenNodes({ nodes }));
          dispatch(setFolderData({ folder }));
        }
      }
      else {
        deepLinkContext.clearData();
      }

      return;
    }
  }, [deepLink, selectedWorkspace?.id, loading, siteFoldersError, siteFoldersData, fileViewNodes]);

  const handleError = error => {
    const { errorCode } = error;

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }

    console.error(error.errorMessage);
  };

  const handleCreateRootFolder = async () => {
    try {
      const res = await createFolder({
        variables: {
          folderName: newName,
          siteId: selectedWorkspace.id,
          parentFolderId: null,
        },
      });

      if (res.data && res.data.createFolder && res.data.createFolder.success) {
        alert.success(<FormattedMessage id="context.successCreateFolder" />);
        handleGetSiteFolders();
      }

      if (res && res.data && !res.data.createFolder.success) {
        const { errorResult } = res.data.createFolder;
        handleError(errorResult);
      }

      handleCloseNameModal();
    } catch (err) {
      console.log("Error creating folder ", err);
    }
  };

  const folderTreeMenuOptions = [
    {
      label: intl.formatMessage({
        id: "context.newFolder"
      }), onClick: (e) => {
        handleOpenNameModal();
      },
      enabled: () => siteRoleRights?.directoryCRUD ?? false
    }, {
      label: intl.formatMessage({
        id: "context.importFolderders"
      }),
      onClick: (e) => {
        setImportModal(true);
      },
      enabled: () => siteRoleRights?.directoryCRUD ?? false
    }, {
      label: intl.formatMessage({ id: "context.download" }),
      onClick: (e) => {
        handleSiteDownloadClick();

      },
      enabled: () => siteRoleRights?.canDownloadFilesAndFolders ?? false
    }
  ];

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item md={2} xs={12} className={classes.grid}>
          {!loading && view === FILE_VIEW && (
            <div style={{ textAlign: "right" }}><FolderTreeMenuButton options={folderTreeMenuOptions} /></div>
          )}
          <FolderCategoryTree
            folderId={folderId}
            folders={folders}
            handleGetSiteFolders={handleGetSiteFolders}
            handleOpenNode={handleOpenNode}
            loading={loading}
            openedNodes={openedNodes}
            setFolderId={setFolderId}
            setOpenedNodes={setOpenedNodes}
            setSearchQuery={setSearchQuery}
            setSearchResults={setSearchResults}
            view={view}
          />

          {nameModal && (
            <CreateNameModal
              handleClose={handleCloseNameModal}
              onChange={e => setNewName(e.target.value)}
              onClick={handleCreateRootFolder}
              open={nameModal}
              title={intl.formatMessage({ id: "context.newFolder" })}
              value={newName}
            />
          )}
          {!loading && view === FILE_VIEW && importModal && (
            <ImportDialog siteId={selectedWorkspace.id} open={importModal} onClose={() => setImportModal(false)} refetch={handleGetSiteFolders} />
          )}

        </Grid>
        <Grid
          item
          md={searchDetails.name === "Refined" ? 7 : 10}
          xs={12}
          className={classes.gridRight}
        >
          <div className={classes.productContent}>
            <ProductsMainView
              folderId={folderId}
              handleGetSiteFolders={handleGetSiteFolders}
              numOfLinks={numOfLinks}
              openedNodes={openedNodes}
              searchQuery={searchQuery}
              setFolderId={setFolderId}
              setOpenedNodes={setOpenedNodes}
              setView={setView}
              sortingOptions={sortingOptions}
              total={100}
              view={view}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    workspace: state.ui.toggleWorkspaces.workspace,
    folderId: state.api.folder.folderId,
    searchDetails: state.ui.toggleDetails,
    searchResults: state.api.searchResults.searchResultsItems,
    searchQuery: state.ui.toggleDetails.searchQuery,
    defaultView: state.ui.toggleWorkspaces.workspace.defaultView,
    doDetailedSearch: state.ui.toggleDetails.doDetailedSearch,
    selectedWorkspace: state.ui.toggleWorkspaces.workspace,
    beataWidget: state.ui.toggleDetails.beataWidget,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFolderId,
      toggleDetails,
      setDoDetailedSearch,
      searchBeataWidget,
      setSearchResults,
      setSearchQuery,
      setFileViewOpenNodes,
    },
    dispatch
  );
};

ProductView.defaultProps = {
  defaultView: null,
  folderId: null,
  numOfLinks: 3,
  searchResults: {},
};

ProductView.propTypes = {
  beataWidget: PropTypes.bool.isRequired,
  defaultView: PropTypes.string,
  folderId: PropTypes.number,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  numOfLinks: PropTypes.number,
  searchBeataWidget: PropTypes.func.isRequired,
  searchDetails: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  searchQuery: PropTypes.string.isRequired,
  searchResults: PropTypes.shape({
    itemIds: PropTypes.arrayOf(PropTypes.number),
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        folders: PropTypes.arrayOf(
          PropTypes.shape({
            folderId: PropTypes.number,
            folderName: PropTypes.string,
          })
        ),
        siteId: PropTypes.number,
        siteName: PropTypes.string,
      })
    ),
  }),
  selectedWorkspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setFolderId: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import SiteFolderWidget1 from "../../components/widgets/SiteFolderWidget1";
import SiteFolderWidget2 from "../../components/widgets/SiteFolderWidget2";
import {
  DashboardContainer,
  InformationSection,
  SiteHeaderTitle,
  SiteHeaderText,
} from "../../components/dashboard";
import SiteFolderWidget3 from "../../components/widgets/SiteFolderWidget3";
import { getSiteId } from "../../helpers/selectors";
import { BEATA_WIDGET_ROOT_FOLDER } from "../../constant/index";
import WorkspaceDashboard from "../../components/WorkspaceDashboard";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  moreInfoButton: {
    textTransform: "none",
    color: theme.palette.common.link,
    textAlign: "left",
    fontWeight: 600,
    padding: 0,
    marginBottom: 30,
  },
  border: {
    borderTop: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
  },
}));

const Beata = ({ siteId, setOpenedNodes, setFolderId }) => {
  const classes = useStyles();
  const location = useLocation();


  return (<WorkspaceDashboard siteId={siteId} route={location.pathname} />);
};

const mapStateToProps = state => {
  return {
    siteId: getSiteId(state),
  };
};

Beata.defaultProps = {
  siteId: null,
};

Beata.propTypes = {
  siteId: PropTypes.number,
  setOpenedNodes: PropTypes.func.isRequired,
  setFolderId: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Beata);

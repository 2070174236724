import { createActions } from "redux-actions";

export const {
  setAbout,
  setEditAccountSettings,
  setPrivacy,
  setSkills,
  setWeblinks,
  setWeblinkVisibility,
  removeSetting,
  updateWeblinks,
  setDeliveryAddress,
  setOrderAddress,
  setInvoiceAddress,
} = createActions({
  SET_ABOUT: ({ key, value }) => ({ key, value }),
  SET_EDIT_ACCOUNT_SETTINGS: ({ data }) => ({ data }),
  SET_PRIVACY: ({ key, value }) => ({ key, value }),
  SET_SKILLS: ({ value }) => ({ value }),
  SET_WEBLINKS: ({ url }) => ({ url }),
  SET_WEBLINK_VISIBILITY: ({ key, value }) => ({ key, value }),
  REMOVE_SETTING: ({ context, key }) => ({ context, key }),
  UPDATE_WEBLINKS: ({ index, value }) => ({ index, value }),
  SET_DELIVERY_ADDRESS: ({ name, value }) => ({ name, value }),
  SET_ORDER_ADDRESS: ({ name, value }) => ({ name, value }),
  SET_INVOICE_ADDRESS: ({ name, value }) => ({ name, value }),
});

import React, { useState } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UserInformationColumn from "./UserInformationColumn";
import UrlsAndTagsColumn from "./UrlsAndTagsColumn";
import PrivacyModal from "./PrivacyModal";

const useStyles = makeStyles(theme => ({
  borderLeft: {
    borderLeft: "1px solid #cacaca",
    paddingLeft: "12px!important",
    [theme.breakpoints.down("sm")]: {
      borderLeft: 0,
      paddingLeft: "5px!important",
    },
  },
}));

const AboutSection = ({ accountSettings, setAccountSettings }) => {
  const classes = useStyles();
  
  const [privacyModal, setPrivacyModal] = useState(false);
  const [settingName, setSettingName] = useState(false);
  const [privacyName, setPrivacyName] = useState(false);

  const { webLinks = [], skillTags = [], visibilitySettings } =
    accountSettings || {};

  const handleOpenPrivacySetting = ({ name, visibility }) => {
    setSettingName(name);
    setPrivacyModal(true);
    setPrivacyName(visibility);
  };

  const handleChange = value => {
    if (privacyName === "skillTags" || privacyName === "visibilitySettings") {
      const tempValues = {
        ...accountSettings,
        visibilitySettings: {
          ...accountSettings.visibilitySettings,
          [settingName]: value,
        },
      };

      setAccountSettings(tempValues);
    }

    if (privacyName === "weblinks") {
      const tempValues = [...accountSettings.webLinks];
      const newWeblink = tempValues[settingName];
      newWeblink.visibility = value;
      tempValues[settingName] = newWeblink;
      setAccountSettings({ ...accountSettings, webLinks: tempValues });
    }
    setPrivacyModal(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <UserInformationColumn
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}
          visibilitySettings={visibilitySettings}
          visibility="visibilitySettings"
          handleOpenPrivacySetting={handleOpenPrivacySetting}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.borderLeft}>
        <UrlsAndTagsColumn
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}
          webLinks={webLinks}
          skillTags={skillTags}
          handleOpenPrivacySetting={handleOpenPrivacySetting}
          visibility="weblinks"
          visibilitySettings={visibilitySettings}
        />
      </Grid>
      {privacyModal && (
        <PrivacyModal
          open={privacyModal}
          handleClose={() => setPrivacyModal(false)}
          name={settingName}
          visibilitySettings={visibilitySettings}
          webLinks={webLinks}
          privacyName={privacyName}
          handleChange={handleChange}
        />
      )}
    </Grid>
  );
};

export default AboutSection;

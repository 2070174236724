import React, { memo, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Tooltip, Icon } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../../common";
import { useGetFolderResourceListing } from "../../../../hooks";

const useStyles = makeStyles(theme => ({
  infoIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    marginLeft: 5,
    verticalAlign: "middle",
    color: theme.palette.common.link,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const ApplyToSubfolder = ({
  folder,
  setCheckedFolders,
  checkedFolders,
  folderPropertyFormValues,
  setFolderPropertyFormValues,
  propertyFormValues,
  setPropertyFormValues,
  enabled,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    getFolderResourceListing,
    data,
    loading,
    error,
  } = useGetFolderResourceListing();

  useEffect(() => {
    if (
      data &&
      data.addFilesToCart &&
      data.addFilesToCart.folderResourceListing &&
      data.addFilesToCart.folderResourceListing.folders
    ) {
      const { folders } = data.addFilesToCart.folderResourceListing;
      const selectedFolderProps = folderPropertyFormValues.find(
        x => x.folderId === folder.folderId
      );

      if (!selectedFolderProps) {
        return;
      }

      let newFoldersChecked = [];
      let newFolderPropertyFormValues = [...folderPropertyFormValues];
      let newPropertyFormValues = [...propertyFormValues];

      folders.forEach(item => {
        const { folder: folderItem = {}, resources = [] } = item;

        newFoldersChecked = [...newFoldersChecked, folderItem.folderId];

        // Applying to folders start
        let existingFolderProps = newFolderPropertyFormValues.find(
          y => y.folderId === folderItem.folderId
        );

        if (existingFolderProps) {
          newFolderPropertyFormValues = newFolderPropertyFormValues.filter(
            y => y.folderId !== folderItem.folderId
          );

          const {
            productionSettings: selectedFolderProductionSettings,
          } = selectedFolderProps;

          existingFolderProps = {
            ...existingFolderProps,
            productionSettings: {
              ...selectedFolderProductionSettings,
            },
          };

          newFolderPropertyFormValues = [
            ...newFolderPropertyFormValues,
            existingFolderProps,
          ];
        } else {
          const newFolderProps = {
            ...selectedFolderProps,
            folderId: folderItem.folderId,
          };
          newFolderPropertyFormValues = [
            ...newFolderPropertyFormValues,
            newFolderProps,
          ];
        }
        // Applying to folders end

        // Applying to files start
        resources.forEach(r => {
          let existingFileProps = newPropertyFormValues.find(
            y => y.resourceId === r.id
          );

          if (existingFileProps) {
            newPropertyFormValues = newPropertyFormValues.filter(
              y => y.resourceId !== r.id
            );

            const {
              productionSettings: selectedFolderProductionSettings,
            } = selectedFolderProps;

            existingFileProps = {
              ...existingFileProps,
              productionSettings: {
                ...selectedFolderProductionSettings,
              },
            };

            newPropertyFormValues = [
              ...newPropertyFormValues,
              existingFolderProps,
            ];
          } else {
            const newFileProps = {
              ...selectedFolderProps,
              resourceId: r.id,
              folderId: folderItem.folderId,
            };
            newPropertyFormValues = [...newPropertyFormValues, newFileProps];
          }
        });
        // Applying to files end
      });

      setCheckedFolders([
        ...new Set([...checkedFolders, ...newFoldersChecked]),
      ]);
      setFolderPropertyFormValues(newFolderPropertyFormValues);
      setPropertyFormValues(newPropertyFormValues);
    }
  }, [data]);

  const handleOnclick = () => {
    if (!folder || !folder.folderId) return;
    getFolderResourceListing({
      variables: { folderId: folder.folderId },
    });
  };

  return (
    <>
      <PrimaryButton fontSize={9} onClick={handleOnclick} disabled={!enabled}>
        <FormattedMessage id="monitoring.applyToAllSubfolders" />
      </PrimaryButton>
      <Tooltip
        title={intl.formatMessage({
          id: "monitoring.selectAllSubfoldersAndApply",
        })}
        placement="top"
      >
        <Icon className={clsx(["fas fa-question-circle", classes.infoIcon])} />
      </Tooltip>
    </>
  );
};

ApplyToSubfolder.defaultProps = {
  folderPropertyFormValues: [],
  propertyFormValues: [],
};

ApplyToSubfolder.propTypes = {
  folder: PropTypes.shape({ folderId: PropTypes.number.isRequired }).isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  setFolderPropertyFormValues: PropTypes.func.isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(ApplyToSubfolder);

import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import Suppliers from "../../components/Suppliers";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const SuppliersPage = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} justifyContent="center">
      <Suppliers />
    </Grid>
  );
};

export default SuppliersPage;

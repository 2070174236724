import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Menu, Separator } from "react-contexify";
import { FormattedMessage, useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import "react-contexify/dist/ReactContexify.min.css";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { setRefetchFiles } from "../../../actions";
import RenderCrudItems from "../ContextMenuItems/RenderCrudItems";
import RenderDownloadItems from "../ContextMenuItems/RenderDownloadItems";
import ContextMenuItem from "../ContextMenuItems/ContextMenuItem";
//import RederShareItems from "../ContextMenuItems/RenderShareItems";
import RenderFolderColors from "../ContextMenuItems/RenderFolderColors";
import RenderNotificationItems from "../ContextMenuItems/RenderNotificationItems";
import RenderTenderFolders from "../ContextMenuItems/RenderTenderFolders";
import RenderLockFolder from "../ContextMenuItems/RenderLockFolder";
import RenderAddToCart from "../ContextMenuItems/RenderAddToCart";
//import RenderRequestConsent from "../ContextMenuItems/RenderRequestConsent"
import RenderFolderPermissions from "../ContextMenuItems/RenderFolderPermissions";
import RenderCopy from "../ContextMenuItems/RenderCopy";
import RenderPaste from "../ContextMenuItems/RenderPaste";
import MonitoringModal from "../MonitoringModal";
import ShareFileModal from "../ShareFileModal";
import AddFolderToBasketModal from "../AddFolderToBasketModal";
import { getCopyParams } from "../../../helpers/fileViewSelectors";
import { useSnackbar } from "../../../hooks";
import {
  INITIALIZE_FOLDER_DOWNLOAD,
  DELETE_FOLDER,
  CREATE_FOLDER,
  LOCK_FOLDER,
  UNLOCK_FOLDER,
} from "../../../graphql/mutations";

import FolderPermissionsModal from "../FolderPermissionsModal";
import DeleteModal from "./DeleteModal";
import ConfirmationModal from "../../common/ConfirmationModal/FileView";
import {
  FOLDER_LOCKED,
  FOLDER_WRITE_ACCESS,
  FOLDER_READ_ACCESS,
} from "../../../constant/errorCodes";

import UserContactInfoModal from "../../common/UserContactInfoModal";
import CreateNameModal from "../../common/CreateNameModal";
import ColorSelectorModal from "../ContextMenuItems/ColorSelectorModal";

const defaultSiteRoleRights = {
  canAddToBasket: false,
  directoryCRUD: false,
  tenderFolders: false,
  notifications: false,
  shareFolders: false,
};

const useStyles = makeStyles(theme => ({
  contextWrap: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    fontSize: 11,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    lineHeight: "20px",
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "& .react-contexify__separator": {
      backgroundColor: "#fff !important",
      float: "none",
      margin: 0,
    },
  },
  btnLink: {
    padding: 0,
    fontSize: 13,
    textTransform: "none",
    marginLeft: 5,
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
}));

const ProductsMainViewContextMenu = ({
  contextMenuId,
  creatingFolder,
  deletingFolder,
  folder,
  folders,
  handleSetEditNode,
  onFolderColorChange,
  openedNodes,
  refetchFolders,
  setOpenedNodes,
  sites,
  workspace,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const intl = useIntl();
  const snackbar = useSnackbar();

  const copyParams = useSelector(getCopyParams);

  const [initFolderDownload] = useMutation(INITIALIZE_FOLDER_DOWNLOAD);
  const [createFolder] = useMutation(CREATE_FOLDER);
  const [deleteFolder] = useMutation(DELETE_FOLDER);
  const [lockFolder] = useMutation(LOCK_FOLDER);
  const [unlockFolder] = useMutation(UNLOCK_FOLDER);
  const [prevNodes, setPrevNodes] = useState([]);
  const [userContactDetails, setUserContactDetails] = useState(false);

  const {
    colorValue,
    folderId,
    locked,
    lockedByAnotherUser,
    lockedByUsername,
    name,
    read = false,
    userCanUpdateLock,
    write = false,
  } = folder || {};

  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState(false);
  const [nameModal, setNameModal] = useState(false);
  const [openColorModal, setOpenColorModal] = useState(false);

  const [newName, setNewName] = useState(
    intl.formatMessage({ id: "context.newFolder" })
  );

  const siteId = workspace && workspace.id;

  const [siteRoleRights, setSiteRoleRights] = useState(defaultSiteRoleRights);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const {
    canAddToBasket,
    canChangeFolderIcons,
    canLock,
    canUnlockForOthers,
    directoryCRUD,
    notifications,
    shareFolders,
    tenderFolders,
    canDownloadFilesAndFolders,
  } = siteRoleRights;

  const handleDisabled = () => {
    snackbar.workspaceInfo(
      <FormattedMessage id="common.userLacksSufficientRightsMessage" />
    );
  };

  const handleCloseNameModal = () => {
    setNewName("");
    setNameModal(false);
  };

  const handleOpenNameModal = () => {
    setNewName(intl.formatMessage({ id: "context.newFolder" }));
    setNameModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openShare, setOpenShare] = useState(false);

  const handleOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };



  const [openPermissions, setOpenPermissions] = useState(false);
  const handleOpenPermissions = () => {
    setOpenPermissions(true);
  };
  const handleClosePermissions = () => {
    setOpenPermissions(false);
  };

  const [openAddFolderToBasket, setOpenAddFolderToBasket] = useState(false);

  const handleCloseAddFolderToBasket = () => {
    setOpenedNodes(prevNodes);
    setOpenAddFolderToBasket(false);
  };

  const handleOpenAddFolderToBasket = () => {
    setPrevNodes(openedNodes);
    if (!folder.isRoot) {
      const tempNodes = [folder.parentFolderId];
      setOpenedNodes(tempNodes);
    } else {
      setOpenedNodes([]);
    }
    setOpenAddFolderToBasket(true);
  };

  const handleOpenUserContactInfo = () => {
    setUserContactDetails(true);
    snackbar.close();
  };

  const handleCloseUserContactInfo = () => {
    setUserContactDetails(false);
  };

  const handleRequestConsent = () => {

  }

  const handleError = error => {
    const { data, errorCode } = error;

    const personLink = (
      <div>
        {lockedByUsername},
        <Button
          className={classes.btnLink}
          onClick={() => handleOpenUserContactInfo(lockedByUsername)}
        >
          <FormattedMessage id="resources.clickHereToSeeUser" />
        </Button>
      </div>
    );

    if (errorCode === FOLDER_LOCKED) {
      const lockedFolder = data[1].value;

      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: lockedFolder, person: personLink }
        )
      );
    }

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }

    console.error(error.errorMessage);
  };

  useEffect(() => {
    if (sites && workspace && workspace.id) {
      const selectedSite = sites.find(x => x.id === workspace.id);
      if (selectedSite) {
        const {
          siteRoleRights: siteRoleRightsData,
        } = selectedSite;
        if (siteRoleRightsData) {
          setSiteRoleRights(siteRoleRightsData);
        } else {
          setSiteRoleRights(defaultSiteRoleRights);
        }
      }
    }
  }, [sites, workspace]);

  const handleDownloadFolder = async () => {
    try {
      const res = await initFolderDownload({
        variables: { siteId, folderId },
      });

      if (res && res.data && res.data.initFolderDownload.success) {
        const downloadUri = res.data.initFolderDownload.url;
        window.open(downloadUri);
      }

      if (res && res.data && !res.data.initFolderDownload.success) {
        const { errorResult } = res.data.initFolderDownload;
        handleError(errorResult);
      }
    } catch (err) {
      console.log("Error downloading folder ", err);
    }
  };

  const lockSelectedFolder = async () => {
    try {
      const res = await lockFolder({
        variables: { folderId },
      });

      if (
        res &&
        res.data &&
        res.data.lockFolder &&
        res.data.lockFolder.success
      ) {
        alert.success(<FormattedMessage id="context.successLockFolder" />);
        refetchFolders();
        dispatch(setRefetchFiles({ refetch: true }));
      }
    } catch (err) {
      console.log("Error locking the folder ", err);
      snackbar.workspaceError(
        <FormattedMessage id="context.errorLockFolder" />
      );
    }
  };

  const unlockSelectedFolder = async () => {
    try {
      const res = await unlockFolder({
        variables: { folderId },
      });

      if (res && res.data && res.data.unlockFolder) {
        const { success } = res.data.unlockFolder;
        if (success) {
          alert.success(<FormattedMessage id="context.successUnlockFolder" />);
          refetchFolders();
          dispatch(setRefetchFiles({ refetch: true }));
        } else {
          const { errorResult } = res.data.unlockFolder;
          handleError(errorResult);
        }
      }
    } catch (err) {
      console.log("Error unlocking the folder ", err);
    }
  };

  const handleLockFolder = () => {
    if (!locked) {
      lockSelectedFolder();
    }

    if (locked) {
      if (lockedByAnotherUser) {
        if (canUnlockForOthers) {
          setConfirmationModal(true);
        } else {
          unlockSelectedFolder();
        }
      } else {
        unlockSelectedFolder();
      }
    }
  };

  const handleDeleteFolder = async () => {
    try {
      const res = await deleteFolder({ variables: { folderId } });
      if (
        res &&
        res.data &&
        res.data.deleteFolder &&
        res.data.deleteFolder.success
      ) {
        deletingFolder();
        alert.success(<FormattedMessage id="context.successDeleteFolder" />);
      }

      if (res && res.data && !res.data.deleteFolder.success) {
        const { errorResult } = res.data.deleteFolder;
        handleError(errorResult);
      }

      handleDeleteModalClose();
    } catch (err) {
      console.log("Error deleting folder ", err);
      snackbar.workspaceError(
        <FormattedMessage id="context.errorDeleteFolder" />
      );
    }
  };

  const handleCreateFolder = async () => {
    if (folderId !== 0) {
      try {
        const res = await createFolder({
          variables: { folderName: newName, siteId, parentFolderId: folderId },
        });

        if (
          res &&
          res.data &&
          res.data.createFolder &&
          res.data.createFolder.success
        ) {
          const { folder: newFolder } = res.data.createFolder;
          creatingFolder(newFolder);
          alert.success(<FormattedMessage id="context.successCreateFolder" />);
        }

        if (res && res.data && !res.data.createFolder.success) {
          const { errorResult } = res.data.createFolder;
          handleError(errorResult);
        }

        handleCloseNameModal();
      } catch (err) {
        console.log("Error creating folder ", err);
      }
    } else {
      snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
    }
  };

  return (
    <Box>
      <Menu id={contextMenuId} className={classes.contextWrap}>
        <RenderCrudItems
          directoryCRUD={directoryCRUD}
          handleCreateFolder={handleOpenNameModal}
          handleDeleteFolder={handleDeleteModalOpen}
          handleDisabled={handleDisabled}
          handleSetEditNode={handleSetEditNode}
          locked={locked}
          notifications={notifications}
          read={read}
          shareFolders={shareFolders}
          write={write}
        />

        <RenderLockFolder
          canLock={canLock}
          handleDisabled={handleDisabled}
          handleLockFolder={handleLockFolder}
          locked={locked}
        />

        <RenderDownloadItems
          handleDisabled={handleDisabled}
          handleDownloadFolder={handleDownloadFolder}
          read={canDownloadFilesAndFolders && read}
        />

        {/* Comment out for now , will implement in the future */}

        {/* <RederShareItems
          handleDisabled={handleDisabled}
          handleOpenShare={handleOpenShare}
          shareFolders={shareFolders}
        /> */}

        <RenderNotificationItems
          handleDisabled={handleDisabled}
          handleOpen={handleOpen}
          notifications={notifications}
        />

        <Separator />

        <RenderCopy
          folderId={folderId}
        />

        {copyParams.type && (
          <RenderPaste
            folderId={folderId}
            siteId={siteId}
          />
        )}

        <Separator />

        <RenderTenderFolders
          handleDisabled={handleDisabled}
          locked={locked}
          tenderFolders={tenderFolders}
        />

        <RenderFolderColors
          canChangeFolderIcons={canChangeFolderIcons}
          folderColor={colorValue}
          folderId={folderId}
          onDisabled={handleDisabled}
          onFolderColorChange={onFolderColorChange}
          onOpenColorDialog={() => setOpenColorModal(true)}
        />

        <RenderAddToCart
          canAddToBasket={canAddToBasket}
          handleDisabled={handleDisabled}
          onClick={handleOpenAddFolderToBasket}
        />

        {/*<RenderRequestConsent onClickRequestConsent={handleRequestConsent} />*/}

        <ContextMenuItem
          onClick={() => setOpenShare(true)}>
          {intl.formatMessage({ id: "btn.share" })}
        </ContextMenuItem>

        <RenderFolderPermissions
          handleDisabled={handleDisabled}
          handleOpenPermissions={handleOpenPermissions}
          locked={locked}
        />
      </Menu>

      {/* Prevents the modal from mounting */}
      {open && (
        <MonitoringModal
          folder={folder}
          handleClose={handleClose}
          initialFolderTreeData={folders}
          initialSiteId={workspace.id}
          initialWorkspaceName={workspace.name}
          open={open}
          openedNodes={openedNodes}
        />
      )}

      {openShare && (
        <ShareFileModal
          folder={folder}
          folders={folders}
          open={openShare}
          handleClose={handleCloseShare}
        />
      )}

      {openAddFolderToBasket && (
        <AddFolderToBasketModal
          folder={folder}
          handleClose={handleCloseAddFolderToBasket}
          open={openAddFolderToBasket}
          openedNodes={openedNodes}
          refetchFolders={refetchFolders}
        />
      )}

      {openColorModal && (
        <ColorSelectorModal
          canChangeFolderIcons={canChangeFolderIcons}
          folderColor={colorValue}
          folderId={folderId}
          onDisabled={handleDisabled}
          onFolderColorChange={onFolderColorChange}
          onClose={() => setOpenColorModal(false)}
        />
      )}

      {openPermissions && (
        <FolderPermissionsModal
          folderId={folderId}
          handleClose={handleClosePermissions}
          open={openPermissions}
          name={name}
        />
      )}

      {deleteModal && (
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleDelete={handleDeleteFolder}
          name={name}
          open={deleteModal}
        />
      )}

      {nameModal && (
        <CreateNameModal
          handleClose={handleCloseNameModal}
          onChange={e => setNewName(e.target.value)}
          onClick={handleCreateFolder}
          open={nameModal}
          title={intl.formatMessage({ id: "context.newFolder" })}
          value={newName}
        />
      )}

      {confirmationModal && (
        <ConfirmationModal
          handleClose={handleCloseConfirmationModal}
          lockedBy={lockedByUsername}
          onClick={unlockSelectedFolder}
          isResource={false}
          open={confirmationModal}
        />
      )}

      {userContactDetails && (
        <UserContactInfoModal
          handleClose={() => handleCloseUserContactInfo()}
          open={userContactDetails}
          username={lockedByUsername}
        />
      )}
    </Box>
  );
};

ProductsMainViewContextMenu.defaultProps = {
  folder: null,
  openedNodes: [],
  workspace: { id: null, name: null },
};

ProductsMainViewContextMenu.propTypes = {
  contextMenuId: PropTypes.string.isRequired,
  creatingFolder: PropTypes.func.isRequired,
  deletingFolder: PropTypes.func.isRequired,
  folder: PropTypes.shape({
    folderId: PropTypes.number,
    isRoot: PropTypes.bool,
    parentFolderId: PropTypes.number,
    read: PropTypes.bool.isRequired,
    write: PropTypes.bool.isRequired,
  }),
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      read: PropTypes.bool.isRequired,
      write: PropTypes.bool.isRequired,
    })
  ).isRequired,
  handleSetEditNode: PropTypes.func.isRequired,
  onFolderColorChange: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  refetchFolders: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          childMenu: PropTypes.arrayOf(PropTypes.shape({})),
          name: PropTypes.string.isRequired,
          page: PropTypes.string.isRequired,
        })
      ),
    }).isRequired
  ).isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  return {
    sites: state.api.currentViewer.viewer.sites,
    workspace: state.ui.toggleWorkspaces.workspace,
  };
};

export default connect(mapStateToProps)(ProductsMainViewContextMenu);

import React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
    marginTop: 15,
  },
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
  td: {
    fontSize: 12,
    paddingTop: 12,
    paddingBottom: 12,
    "& p": {
      fontSize: 12,
    },
  },
}));

const HistoryTable = () => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <div className={classes.root}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.th}>ÄNDRING</TableCell>
              <TableCell className={classes.th}>TID FÖR ÄNDRING</TableCell>
              <TableCell className={classes.th}>AVDELNING</TableCell>
              <TableCell className={classes.th}>SEKTION</TableCell>
              <TableCell className={classes.th}>ANVÄNDARE</TableCell>
              <TableCell className={classes.th}>KOMMENTAR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.td}>
                <Typography>-40</Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>2018-08-23 09:27:47</Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>SKPF</Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>Förbundet</Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>anna.killander@kommunal.se</Typography>
              </TableCell>
              <TableCell className={classes.td}>
                <Typography>Inleverans</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default HistoryTable;

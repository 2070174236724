import { handleActions } from "redux-actions";
import { setTheme } from "../../../actions";

const defaultState = {
  theme: "",
};

export default handleActions(
  {
    [setTheme]: (state, { payload }) => {
      const { theme } = payload;
      return {
        ...state,
        theme,
      };
    },
  },
  defaultState
);

import { gql } from '@apollo/client';

const ANSWER_CONSENT_REQUEST = gql`mutation answerConsentRequest($token:String!, $approvedResourceIds:[Int!], $deniedResourceIds:[Int!] ){
	answerConsentRequest(token:$token, approvedResources:$approvedResourceIds, deniedResources:$deniedResourceIds)	
}
`;

export default ANSWER_CONSENT_REQUEST;




import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
    "&.MuiGrid-item": {
      padding: 20,
    },
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
  },
  body: {
    padding: 20,
  },
  card: {
    //padding: 15,
    //borderRadius: 4,
    //border: "1px solid #F2F2F2",
    //marginBottom: 15,
    padding: "0 15px",
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    marginTop: "1em",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  textItalic: {
    fontSize: 12,
    fontStyle: "italic",
    marginBottom: 10,
  },
}));

const lastUpdatedDate = new Date(2020, 9, 10);

const TermsOfUseSection = ({ lang }) => {
  const classes = useStyles();

  const Card = ({ children, hidden = false }) => {
    return !hidden && <Box className={classes.card}>{children}</Box>;
  };

  const Heading = ({ children, hidden = false, ...rest }) => {
    return (
      !hidden && (
        <Typography className={classes.heading} {...rest}>
          {children}
        </Typography>
      )
    );
  };

  const Label = ({ children, hidden = false }) => {
    return (
      !hidden && <Typography className={classes.label}>{children}</Typography>
    );
  };

  const Section = ({ children, hidden = false }) => {
    return !hidden && <>{children}</>;
  };

  const Text = ({ children, hidden = false }) => {
    return (
      !hidden && (
        <Typography className={classes.text} variant="body1">
          {children}
        </Typography>
      )
    );
  };

  const LastUpdated = () => {
    const lastUpdated = moment(lastUpdatedDate)
      .locale(lang)
      .format("LL");
    return (
      <>
        <FormattedMessage id="common.lastUpdated" />: {lastUpdated}
      </>
    );
  };

  const ContentInSwedish = () => {
    return (
      <>
        <Typography className={classes.headTitle} variant="h1">
          Vad villkoren täcker
        </Typography>
        <Typography className={classes.headTitle} variant="h1">
          Vi vet att det är frestande att hoppa över användarvillkoren, men det
          är viktigt att veta vad du kan förvänta dig av oss när du använder
          UniQueues tjänster, och vad vi förväntar oss av dig.
        </Typography>
        <Section>
          <Text>
            Användarvillkoren speglar hur UniQueue bedriver verksamheten, de
            lagar som gäller för vårt företag och vår filosofi. Det innebär att
            vi med hjälp av dessa användarvillkor kan definiera UniQueues
            relation till dig när du interagerar med våra tjänster. Villkoren
            innehåller bland annat följande ämnesrubriker:
          </Text>
          <ul className={classes.text}>
            <li>
              Vad du kan förvänta dig av oss, där vi beskriver hur vi
              tillhandahåller och utvecklar våra tjänster.
            </li>
            <li>
              Vad vi förväntar oss av dig, där vi fastslår vissa regler som
              gäller för användningen av våra tjänster.
            </li>
            <li>
              Innehållet i UniQueues tjänster, där vi beskriver de immateriella
              rättigheterna till innehållet du hittar i våra tjänster — oavsett
              om innehållet tillhör dig, UniQueue eller någon annan.
            </li>
            <li>
              Om det uppstår problem eller oenighet, där vi beskriver andra
              juridiska rättigheter du har och vad som händer om någon bryter
              mot dessa villkor.
            </li>
          </ul>
          <Text>
            Det är viktigt att du förstår dessa villkor, eftersom du måste
            godkänna dem för att kunna använda våra tjänster.
          </Text>
          <Text>
            Utöver dessa villkor publicerar vi även en integritetspolicy. Även
            om den inte är en del av villkoren rekommenderar vi att du läser
            igenom den för att bättre förstå hur du kan uppdatera, hantera,
            exportera och radera dina uppgifter.
          </Text>
        </Section>
      </>
    );
  };

  const ContentInEnglish = () => {
    return (
      <>
        <Typography className={classes.headTitle} variant="h1">
          What the terms cover
        </Typography>
        <Typography className={classes.headTitle} variant="h1">
          We know it's tempting to skip the Terms of Service, but it's important
          to know what you can expect from us when you use UniQueue's services,
          and what we expect from you.
        </Typography>
        <Section>
          <Text>
            The Terms of Service reflect the way UniQueue conducts business, the
            laws that apply to our company and our philosophy. This means that
            with the help of these terms of use, we can define UniQueue's
            relationship with you when you interact with our services. The terms
            contain, among other things, the following subject headings:
          </Text>
          <ul className={classes.text}>
            <li>
              What you can expect from us, where we describe how we provide and
              develop our services.
            </li>
            <li>
              What we expect from you, where we establish certain rules that
              apply to the use of our services.
            </li>
            <li>
              The content of UniQueue's services, where we describe the
              intellectual property rights to the content you find in our
              services — whether the content belongs to you, UniQueue or someone
              else.
            </li>
            <li>
              If a problem or disagreement arises, where we describe other legal
              rights you have and what happens if someone breaches these terms.
            </li>
          </ul>
          <Text>
            It is important that you understand these terms, as you must agree
            to them in order to use our services.
          </Text>
          <Text>
            In addition to these terms, we also publish a privacy policy.
            Although it is not part of the Terms, we recommend that you read it
            to better understand how you can update, manage, export and delete
            your data.
          </Text>
        </Section>
      </>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} className={classes.root}>
        {lang === "SV" && <ContentInSwedish />}
        {lang !== "SV" && <ContentInEnglish />}
        <Typography
          className={classes.textItalic}
          style={{ marginBottom: 0, textAlign: "right" }}
        >
          <LastUpdated />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TermsOfUseSection;

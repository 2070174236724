import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import DateFull from "../../../../../common/FormControl/DateFull";

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: 0,
    marginBottom: 3,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  activeDate: {
    "& .MuiFormControl-fullWidth": {
      marginTop: 0,
    },
  },
}));

const DateActive = ({
  formValues,
  handleFromDateChange,
  handleToDateChange,
  customLabelClass,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6} className={classes.activeDate}>
          <DateFull
            name="activeFromDate"
            value={formValues.activeFromDate}
            onChange={handleFromDateChange}
            emptyLabel={intl.formatMessage({
              id: "common.notSet",
            })}
            variant="dialog"
            allowNull
            label={<FormattedMessage id="date.dateFrom" />}
            customLabelClass={customLabelClass}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.activeDate}>
          <DateFull
            name="activeUntilDate"
            value={formValues.activeUntilDate}
            onChange={handleToDateChange}
            emptyLabel={intl.formatMessage({
              id: "common.notSet",
            })}
            variant="dialog"
            allowNull
            label={<FormattedMessage id="date.dateUntil" />}
            customLabelClass={customLabelClass}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DateActive.defaultProps = {
  formValues: {},
};

DateActive.propTypes = {
  formValues: PropTypes.shape({
    activeFromDate: PropTypes.instanceOf(Date),
    activeUntilDate: PropTypes.instanceOf(Date),
  }),
  handleFromDateChange: PropTypes.func.isRequired,
  handleToDateChange: PropTypes.func.isRequired,
};

export default DateActive;

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import WorkspaceSettings from "./EditSiteModal/WorkspaceSettings";

const useStyles = makeStyles(theme => ({
  modal: {
    width: 1200,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    //padding: 15,
  },
  tourIconWrapper: {
    display: "flex",
    flex: 1,
    marginRight: 8,
  },
  tourIconButton: {
    marginLeft: "auto",
    cursor: "pointer",
    padding: 4,
    color: theme.palette.common.white,
    "& span": {
      fontSize: 16,
    },
  },
}));

const NewWorkspaceModal = ({
  companyId,
  handleClose,
  networkSelectorItems,
  open,
  refresh,
  usersNetworkId,
  workspaceOrderGroupCandidates,
  workspaceOwnerCandidates,
  workspaceTemplateCandidates,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="admin.addNewWorkspace" />}
      />

      <DraggableModalBody customClass={classes.modalBody}>
        <WorkspaceSettings
          isCreate
          companyId={companyId}
          networkSelectorItems={networkSelectorItems}
          usersNetworkId={usersNetworkId}
          workspaceOrderGroupCandidates={workspaceOrderGroupCandidates}
          workspaceOwnerCandidates={workspaceOwnerCandidates}
          workspaceTemplateCandidates={workspaceTemplateCandidates}
          handleClose={handleClose}
          refresh={refresh}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewWorkspaceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NewWorkspaceModal;

import _ from "lodash";
import svLocale from "date-fns/locale/sv";
import enLocale from "date-fns/locale/en-US";

export default function get(obj, path, defaultValue = undefined) {
  try {
    return (
      path.split(".").reduce((o, key) => o[key], obj || {}) || defaultValue
    );
  } catch (e) {
    return defaultValue;
  }
}

export const getSortedChildFolders = folder => {
  const { childFolders } = folder;
  const sortedChildFolders = _.orderBy(childFolders, ["name"], ["asc"]);

  return sortedChildFolders;
};

export const getSortedGrandChildFolders = folder => {
  const { childFolders } = folder;

  const childFolderSecondLevel = [];
  childFolders.forEach(childFolder => {
    const { childFolders: grandChildFolders } = childFolder;
    grandChildFolders.forEach(grandChild => {
      childFolderSecondLevel.push(grandChild);
    });
  });

  const sortedGrandChildFolders = _.orderBy(
    childFolderSecondLevel,
    ["name"],
    ["asc"]
  );

  return sortedGrandChildFolders;
};

export const getSortedDeliverySettings = addressSettings => {
  const addressSettingsArray = Object.keys(addressSettings).map(key => ({
    ...addressSettings[key],
    name: key,
  }));

  const sortedAddressSettings = addressSettingsArray.sort(
    (a, b) => a.displayOrder - b.displayOrder
  );
  return sortedAddressSettings;
};

export const geta11yProps = index => {
  return {
    id: `${index}`,
  };
};

export const omitTypename = (key, value) =>
  key === "__typename" ? undefined : value;

export const getDateOnly = date => {
  const tempDate = new Date(date);

  return `${tempDate.getFullYear()}-${tempDate.getMonth() +
    1}-${tempDate.getDate()}`;
};

const localeMap = {
  EN: enLocale,
  SV: svLocale,
};

export const getLocaleLanguage = locale => {
    const language = locale === "SV" || locale === "EN" ? locale : "EN";
  return localeMap[language];
};

export const getShowFileView = siteRoleRights =>
  siteRoleRights && siteRoleRights.fileView ? siteRoleRights.fileView : false;

export const getShowImageView = siteRoleRights =>
  siteRoleRights && siteRoleRights.imageView ? siteRoleRights.imageView : false;

export const getShowDetailedImageView = siteRoleRights =>
  siteRoleRights && siteRoleRights.detailedImageView
    ? siteRoleRights.detailedImageView
    : false;

export const getShowProductView = siteRoleRights =>
  siteRoleRights && siteRoleRights.productView
    ? siteRoleRights.productView
    : false;

const imageType = [
  ".jpg",
  ".png",
  ".tif",
  ".eps",
  ".ai",
  ".indd",
  ".jpeg",
  ".cdr",
];
const altType = [".dwg", ".cal", ".doc", ".txt", ".ttf"];
const codeType = [".xml", ".db"];

export const getFaClassType = fileExtension => {
  const ext = (fileExtension ?? "").toLowerCase();
  let typeClass = "far fa-file-alt";
  if (imageType.includes(ext)) {
    typeClass = "far fa-file-image";
  }

  if (altType.includes(ext)) {
    typeClass = "far fa-file-alt";
  }

  if (codeType.includes(ext)) {
    typeClass = "far fa-file-code";
  }

  if (ext === ".docx") {
    typeClass = "far fa-file-word";
  }

  if (ext === ".pdf") {
    typeClass = "far fa-file-pdf";
  }

  if (ext === ".xlsx") {
    typeClass = "far fa-file-excel";
  }

  if (ext === ".zip") {
    typeClass = "far fa-file-archive";
  }
  return typeClass;
};

export const getSupportedExtension = fileExtension => {
  const ext = fileExtension.toLowerCase();
  let typeClass = false;
  if (imageType.includes(ext)) {
    typeClass = true;
  }

  if (altType.includes(ext)) {
    typeClass = true;
  }

  if (codeType.includes(ext)) {
    typeClass = true;
  }

  if (ext === ".docx") {
    typeClass = true;
  }

  if (ext === ".pdf") {
    typeClass = true;
  }

  if (ext === ".xlsx") {
    typeClass = true;
  }

  if (ext === ".zip") {
    typeClass = true;
  }
  return typeClass;
};

import { gql } from '@apollo/client';
import AccountSettingsEditFragment from "../../../fragments/CompanyAdminContext/AccountSettingsEdit";

const CREATE_ACCOUNT_SETTINGS = gql`
  query CreateAccountSettings($companyId: Int!) {
    companyAdminContext {
      users {
        createAccountSettings(companyId: $companyId) {
          ...AccountSettingsEdit
        }
      }
    }
  }

  ${AccountSettingsEditFragment}
`;

export default CREATE_ACCOUNT_SETTINGS;

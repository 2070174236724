import { gql } from '@apollo/client';
import AddressFragment from "../../fragments/CompanyAdminContext/AddressDTO";
import AddressSettingsFragment from "../../fragments/AdressSettings";

const AccountSettingsEditFragment = gql`
  fragment AccountSettingsEdit on AccountSettingsEdit {
    allowEditAddresses
    allowEditAvatar
    allowEditCompanyRole
    allowEditSubscriptionLevel
    allowEditUsername
    companyRoleName
    companyRoleId

    companyRoles {
      companyRoleId
      name
      subscriptionLevel
    }

    visibilitySettings {
      company
      directMessage
      email
      mainEducation
      mobile
      phone
      skillTags
    }

    selectedTheme

    selectableThemes {
      key
      value
    }

    networkId
    selectableNetworks {
      name
      networkId
    }

    subscriptionLevel
    username
    firstname
    lastname
    avatarUrl
    currentPosition
    employmentType
    location
    biography
    company
    mainEducation
    phone
    mobile
    email
    directMessage
    enabledMFATypes
    userGroupBreadCrumb {
      groups {
        groupId
        name
      }
    }

    webLinks {
      url
      visibility
    }

    skillTags
    addressLabel

    deliveryAddress {
      ...AddressDTO
    }
    invoiceAddress {
      ...AddressDTO
    }
    ordererAddress {
      ...AddressDTO
    }
    visitingAddress {
      ...AddressDTO
    }

    deliveryAddressSettings {
      ...AddressSettingsFields
    }
    invoiceAddressSettings {
      ...AddressSettingsFields
    }
    postalAddressSettings {
      ...AddressSettingsFields
    }
    visitingAddressSettings {
      ...AddressSettingsFields
    }
  }

  ${AddressFragment}
  ${AddressSettingsFragment}
`;

export default AccountSettingsEditFragment;

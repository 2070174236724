import React, { useState, useEffect } from "react";
import {
  Box, Typography, Grid, Card, CardContent, FormControl, RadioGroup,
  List, ListItem, ListItemText, ListItemButton, IconButton, Menu, MenuItem
} from "@mui/material"

import { Check } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ConfirmDeleteModal from "../../../../SiteUsers/ManageRolesModal/ConfirmDeleteModal";
import { useMutation } from "@apollo/client";
import { REMOVE_MFA_FOR_USER } from "../../../../../graphql/mutations";
import { useSnackbar } from "../../../../../hooks";


const useStyle = makeStyles({
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headSmall: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: "0px",
    color: "inherit",
  },
  headMarginBottom: {
    marginBottom: "10px",
  }
});

const TwoFactorAdmin = ({ enabledMFATypes, onUpdateMFASettings, userId }) => {

  const METHODS = Object.freeze({ AUTHENTICATOR: "AUTHENTICATOR", EMAIL: "EMAIL", SMS: "SMS" });

  const [showApp2faRemove, setShowApp2faRemove] = useState(false);
  const snackbar = useSnackbar();

  const handleApp2faRemoveComplete = () => {

    var newSettings = enabledMFATypes.filter(n => n != METHODS.AUTHENTICATOR);
    onUpdateMFASettings(newSettings);
    setShowApp2faRemove(false);
    snackbar.success("MFA Raderad");
  }

  const handleMethodDisable = (method) => {
    switch (method) {
      case METHODS.EMAIL:
        break;
      case METHODS.SMS:
        break;
      case METHODS.AUTHENTICATOR:
        
        setShowApp2faRemove(true);
        break;
      default:
    }

  }


  const isMethodEnabled = (method) => {
    
    return Array.isArray(enabledMFATypes) && enabledMFATypes.length > 0 && enabledMFATypes.indexOf(method) > -1;
  }



  const MfaType = ({ type, name, description, isEnabled }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleRemoveClick = () => {

      handleMethodDisable(type)
      handleClose();
    };

    const [removeMfa, { data, loading, error }] = useMutation(REMOVE_MFA_FOR_USER);

    const handleRemoveConfirm = () => {

      removeMfa({ variables: { userId: userId } })
    }

    useEffect(() => {

      if (data?.companyAdminContext?.users?.removeMFASecret) {
        handleApp2faRemoveComplete();
      }
      else if (error) {

      }

    },[data, loading, error])

    return (<ListItem sx={{ paddingTop: "0px", paddingBottom: "1px" }}>
      {isEnabled && (<Check sx={{ color: "green", verticalAlign: "middle" }} />)}
      {!isEnabled && (<CheckBoxOutlineBlankIcon sx={{ verticalAlign: "middle" }} />)}
      <ListItemButton sx={{ borderRadius: 2, borderBottom: "1x solid #f3f3f3" }} disabled={isEnabled} >
        <ListItemText primary={<Typography sx={{ color: 'initial', fontSize: "15px", fontWeight: 500 }}>{name}</Typography>} secondary={description} />
      </ListItemButton>

      <IconButton onClick={handleClick}><MoreVertIcon /></IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={!isEnabled} onClick={() => handleRemoveClick()}>Ta bort</MenuItem>
      </Menu>
      {showApp2faRemove && (<ConfirmDeleteModal open={showApp2faRemove} setOpen={setShowApp2faRemove} onClose={n => setShowApp2faRemove(false)} onConfirm={handleRemoveConfirm} />)}
    </ListItem>)
    
  }


  const classes = useStyle();
  return (
    <>
      <Box className={classes.headMarginBottom} xs={2}>
        <Typography className={classes.headTitle}>Tvåfaktorsautentisering</Typography>
        <Typography sx={{ backgroundColor: "inherit", marginTop: "12px" }} className={classes.headSmall}>Välj en säkerhetsmetod</Typography>
        <Typography className={classes.headSubTitle}>Tvåfaktorsautentiseringen skyddar användarens konto genom att begära ytterligare en kod när användaren loggar in.</Typography>
        <Typography className={classes.headSubTitle}>Välj den säkerhetsmetod som ska gälla för användaren.</Typography>

      </Box>
      <Card sx={{ backgroundColor: "#f3f3f3" }}>
        <CardContent>
          <Grid item xs={12}>
            <Box>
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  aria-labelledby="security-Method-radio-buttons-group"
                  defaultValue="App"
                  name="securityMethod-radio-buttons-group"
                >
                  <List>

                    <MfaType type={METHODS.AUTHENTICATOR} name="Autentiseringsapp"
                      description={"★ Vi rekommenderar att du laddar ner en app. Appen skapar en kod som du anger vid inloggning."}
                      isEnabled={isMethodEnabled(METHODS.AUTHENTICATOR)} />

                    <MfaType type={METHODS.EMAIL} name="E-postmeddelande"
                      description={"Vi skickar en kod till numret som du har angett."}
                      isEnabled={isMethodEnabled(METHODS.EMAIL)} />

                    <MfaType type={METHODS.SMS} name="Textmeddelande (sms)"
                      description={"Vi skickar en kod till e-postadressen som du har angett."}
                      isEnabled={isMethodEnabled(METHODS.SMS)} />


                  </List>
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </CardContent>
      </Card>

    </>
  );
}

export default TwoFactorAdmin;
import { handleActions } from "redux-actions";
import { setProductView } from "../../../../actions";

const defaultState = {
  productView: {
    sortingOptions: [],
    siteFolderTreeColors: ["#999", "#b3b3b3", "#ccc", "#e4e4e4"],
  },
};

export default handleActions(
  {
    [setProductView]: (state, { payload }) => {
      const { productView } = payload;
      return {
        ...state,
        productView,
      };
    },
  },
  defaultState
);

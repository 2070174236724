import React from "react";
import AppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.component.tabBackground,
    padding: 0,
  },
  tabWrap: {
    backgroundColor: theme.palette.component.tabWrap,
  },
  tabRoot: {
    minHeight: 48,
    width: "50%",
    minWidth: "50%",
  },
  tabHeading: {
    flexDirection: "row",
    color: theme.palette.component.tabColor,
    textTransform: "none",
    fontSize: 12,
  },
  selected: {
    backgroundColor: theme.palette.component.tabBackground,
    fontWeight: 600,
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AppBarTabs = ({ tab, handleTab, editMode }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" elevation="0" className={classes.tabWrap}>
      <Tabs
        value={tab}
        onChange={handleTab}
        aria-label="Request for Proposal"
        classes={{
          indicator: classes.indicator,
        }}
      >
        <Tab
          label={<FormattedMessage id="product.enter" />}
          {...a11yProps(0)}
          classes={{
            root: classes.tabRoot,
            wrapper: classes.tabHeading,
            selected: classes.selected,
          }}
          badgecontent={4}
        />

        <Tab
          label={<FormattedMessage id="product.order" />}
          {...a11yProps(1)}
          classes={{
            root: classes.tabRoot,
            wrapper: classes.tabHeading,
            selected: classes.selected,
          }}
        />
      </Tabs>
    </AppBar>
  );
};

AppBarTabs.propTypes = {
  tab: PropTypes.number.isRequired,
  handleTab: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
};

export default AppBarTabs;

import clsx from "clsx";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import "react-contexify/dist/ReactContexify.min.css";

import { useLazyQuery, useMutation } from "@apollo/client";
import AlarmIcon from '@mui/icons-material/Alarm';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Icon, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import {
  cartAddItem,
  imageBasketAddItem,
  setSelectedResources,
} from "../../../../actions";
import ImagePlaceHolder from "../../../../assets/img/placeholder.jpg";
import {
  DELETE_RESOURCE,
  INITIALIZE_RESOURCE_DOWNLOAD,
  SAVE_IMAGE_BASKET,
} from "../../../../graphql/mutations";
import { GET_PRODUCT } from "../../../../graphql/queries";
import { getFileViewFields } from "../../../../helpers/fileViewSelectors";
import { getSupportedExtension } from "../../../../helpers/get";
import { getSelectedSiteOrderGroupId } from "../../../../helpers/selectors";
import { useSnackbar } from "../../../../hooks";

import { FormattedDate, FormattedFileSize, ImageWithFallback } from "../../../common";
import ConfirmationModal from "../../../common/ConfirmationModal/FileView";
import Checkbox from "../../../common/FormControl/Checkbox";

// eslint-disable-next-line import/no-cycle
import ProductModal from "../../ProductModal";

import DeleteModal from "../DeleteModal";
import FilenameDropzone from "../FilenameDropzone";
import ProductDescription from "../ProductDescription";
import ProductName from "../ProductName";
import ProductTableItemActionsCell from "../ProductTableItemActionsCell";

// eslint-disable-next-line import/no-cycle
import VersionHistoryModal from "../VersionHistoryModal";

import VersionHistory from "./VersionHistory";

const initialValue = {
  quantities: {
    selectionType: "",
  },
};

const ProductsTableItem = ({
  addSelectedResource,
  addSelectedResources,
  classes,
  confirmationModal,
  deleteModal,
  fileExtension,
  folderId,
  folderIsLocked,
  handleClickAddFilesToCart,
  handleCloseConfirmationModal,
  handleContextMenu,
  handleDeleteModalClose,
  handleDeleteModalOpen,
  handleDownloadMultipleResources,
  handleDragEnd,
  handleDragStart,
  handleError,
  handleLockResource,
  handleOpenProductEditModal,
  isSearchResultItem,
  item,
  onProductModalClosed,
  refetchResources,
  removeSelectedResource,
  selectedResources,
  setOpenMetaDataModal,
  showProductModalOnOpen,
  siteRoleRights,
  type,
  userHasWritePermission,
}) => {
  const alert = useAlert();
  const snackbar = useSnackbar();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { downloadedDate, downloadedBy,
    changedDate, changedBy, id, thumbnailImageUri, locked, lockedByAnotherUser, lockedByFolder, lockedByUsername,
    name, size, uploadedDate, uploadedBy, versionNo, isEditableFromSearchResult, productImageUri
  } = item;

  const canLock =
    siteRoleRights && siteRoleRights.canLock
      ? siteRoleRights.canLock && userHasWritePermission
      : false;

  const canDownloadFilesAndFolders =
    siteRoleRights && siteRoleRights.canDownloadFilesAndFolders ? true : false;

  const [hoverImage, setHoverImage] = useState(thumbnailImageUri);
  const [initDownloadResource] = useMutation(INITIALIZE_RESOURCE_DOWNLOAD);
  const [deleteResource] = useMutation(DELETE_RESOURCE);
  const [productEditModal, setProductEditModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [fileImageError, setFileImageError] = useState(false);

  const [productModal, setProductModal] = useState(showProductModalOnOpen);
  const [product, setProduct] = useState(initialValue);
  const [quantity, setQuantity] = useState();

  const [hovered, setHovered] = useState(false);

  const fileViewFields = useSelector(getFileViewFields);
  const imageBasketItems = useSelector(
    state => state.api.imageBasket.imageBasketItems
  );
  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const isImage =
    product && product.userPermissions && product.userPermissions.addToImageCart
      ? product.userPermissions.addToImageCart
      : false;

  const errorConfirmUnlock = `${intl.formatMessage({
    id: "product.resourceLockedBy",
  })} ${lockedByUsername} ${intl.formatMessage({
    id: "product.doYouWantToUnlockResource",
  })}`;

  const cannotEditResource =
    locked || lockedByFolder || !isSearchResultItem && (folderIsLocked || !userHasWritePermission) || isSearchResultItem && !isEditableFromSearchResult;

  const [saveImageBasket] = useMutation(SAVE_IMAGE_BASKET, {
    onCompleted: resultData => {
      if (resultData && resultData.addToImageBank) {
        alert.success(<FormattedMessage id="product.addedToImageBasket" />);
        const {
          imageName,
          itemId,
          productId,
          settings,
        } = resultData.addToImageBank;
        imageBasketAddItem({
          item: { imageName, itemId, productId, settings },
        });
      }
    },
  });

  const handleAddImageBasket = () => {
    const isDuplicate = imageBasketItems.some(x => {
      return x.productId === product.id;
    });

    if (imageBasketItems) {
      if (isDuplicate) {
        snackbar.workspaceError(
          <FormattedMessage id="product.duplicateItem" />
        );
      } else {
        saveImageBasket({
          variables: {
            orderGroupId,
            productId: product.id,
          },
        });
      }
    } else {
      snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
    }
  };

  const handleDeleteResource = async () => {
    if (productEditModal) {
      setProductEditModal(false);
    }
    handleDeleteModalClose();

    try {
      const res = await deleteResource({
        variables: { resourceId: id },
      });
      if (res && res.data && res.data.deleteResource) {
        const { success } = res.data.deleteResource;

        if (success) {
          alert.success(<FormattedMessage id="product.deleted" />);
          refetchResources();
        } else {
          const { errorResult } = res.data.deleteResource;
          handleError(name, errorResult);
        }
      }
    } catch (e) {
      console.error("error >", e);
    }
  };

  const handleMultipleDelete = async toDeleteResources => {
    setDeleteLoading(true);
    if (productEditModal) {
      setProductEditModal(false);
    }

    try {
      let hasErrors = false;

      const promises = toDeleteResources.map(async x => {
        const res = await deleteResource({
          variables: { resourceId: x.id },
        });

        if (
          res &&
          res.data &&
          res.data.deleteResource &&
          res.data.deleteResource.success
        ) {
          console.log(`Resources deleted`);
        } else {
          hasErrors = false;
        }
        return res;
      });

      await Promise.all(promises);
      setDeleteLoading(false);
      handleDeleteModalClose(id);
      if (hasErrors) {
        alert.success(
          <FormattedMessage id="resources.resourcesDeletedWithErrors" />
        );
        refetchResources();
        dispatch(setSelectedResources({ items: [] }));
      } else {
        alert.success(<FormattedMessage id="resources.resourcesDeleted" />);
        refetchResources();
        dispatch(setSelectedResources({ items: [] }));
      }
    } catch (e) {
      snackbar.workspaceError(
        <FormattedMessage id="resources.errorDeletingResources" />
      );
      handleDeleteModalClose(id);
      setDeleteLoading(false);
      console.log("error >", e);
    }
  };

  const [getProduct, { data: productData }] = useLazyQuery(GET_PRODUCT);

  const handleDownloadResource = async () => {
    // versionId set to hardcode since we cannot fetch versionId from the resource at the moment
    try {
      const res = await initDownloadResource({
        variables: { resourceId: id, versionId: 0 },
      });
      if (res && res.data && res.data.initResourceDownload) {
        const downloadUri = res.data.initResourceDownload;
        window.open(downloadUri);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading product ${e}`);
    }
  };

  const [openVersionHistory, setOpenVersionHistory] = useState(false);
  const handleOpenVersionHistory = () => {
    setOpenVersionHistory(true);
  };
  const handleCloseVersionHistory = () => {
    setOpenVersionHistory(false);
  };

  const onOpenProductEditModal = () => {
    handleOpenProductEditModal(id);
  };

  const handleCheckboxChange = e => {
    const { checked } = e.target;
    if (checked) {
      addSelectedResource(item);
    } else {
      removeSelectedResource(item);
    }
  };

  const getCheckboxValue = () => {
    if (
      item &&
      item.id &&
      selectedResources &&
      Array.isArray(selectedResources)
    ) {
      const exist = selectedResources.find(x => x && x.id === item.id);
      if (exist) {
        return true;
      }
    }
    return false;
  };

  const onAddFileToCart = item => {
    const checked = getCheckboxValue();

    if (checked) {
      handleClickAddFilesToCart(selectedResources);
    } else {
      handleClickAddFilesToCart([item]);
    }
  };

  useEffect(() => {
    if (productModal) {
      getProduct({ variables: { id, breadcrumbs: false } });
    }
  }, [productModal]);

  useEffect(() => {
    if (productData && productData.product) {
      setProduct(productData.product);
      setQuantity(productData.product.minimumQuantity);
    }
  }, [productData]);

  const fileImage =
    thumbnailImageUri && getSupportedExtension(fileExtension) ? thumbnailImageUri : ImagePlaceHolder;

  const handleOnMouseEnter = () => {
    setHovered(true);
  };

  const handleOnMouseLeave = () => {
    setHovered(false);
  };

  const renderImageCell = () => (
    // File view
    // Search result (file view)
    <Box className={classes.cellContainer}>
      {fileImageError && (
        <IconButton
          onClick={() => setProductModal(true)}
        >
          <Icon className={clsx([classes.icon, "fa fa-file"])} />
        </IconButton>
      )}
      {!fileImageError && (
        <Tooltip
          arrow
          classes={{
            popper: classes.popper,
            tooltip: classes.tooltip,
          }}
          disabled
          placement="right"
          title={
            <ImageWithFallback
              alt={name}
              errorImageUrl={ImagePlaceHolder}
              imageUrl={hoverImage}
              onLoad={() => setHoverImage(productImageUri)}
            />
          }
        >
          <ImageWithFallback
            alt={name}
            className={classes.img}
            imageUrl={fileImage}
            onError={() => setFileImageError(true)}
            onClick={() => setProductModal(true)}
          />
        </Tooltip>
      )}
    </Box>
  );

  const renderDescCell = () => (
    <Box className={classes.cellContainer} flex={1}>
      <ProductDescription
        description={item.description}
        id={id}
        itemCanBeUpdated={!cannotEditResource}
        handleError={handleError}
        name={name}
      />
    </Box>
  );

  const renderUploadCell = () => (
    <Box className={classes.cellContainer}>
      {uploadedBy}
      <br />
      <FormattedDate value={uploadedDate} />
    </Box>
  );

  const renderDownloadedCell = () => (
    <Box className={classes.cellContainer}>
      {downloadedBy}
      <br />
      <FormattedDate value={downloadedDate} />
    </Box>
  );

  const renderEdited = () => (
    <Box className={classes.cellContainer}>
      {changedBy}
      <br />
      <FormattedDate value={changedDate} />
    </Box>
  );

  const renderVersionHistory = () => {
    return (
      <VersionHistory
        version={versionNo}
        handleOpenVersionHistory={handleOpenVersionHistory}
      />
    );
  };

  const renderSizeCell = () => (
    <Box className={classes.cellContainer}>
      <FormattedFileSize value={size} />

    </Box>
  );

  const renderCheckbox = () => (
    <Box className={classes.cellContainer}>
      <Checkbox
        value={getCheckboxValue()}
        onChange={handleCheckboxChange}
      // disabled={locked || lockedByFolder}
      />
    </Box>
  );

  const renderActionsCell = () => (
    <ProductTableItemActionsCell
      canDownloadFilesAndFolders={isSearchResultItem ? false : canDownloadFilesAndFolders}
      canLock={isSearchResultItem ? false : canLock}
      handleDeleteModalOpen={handleDeleteModalOpen}
      handleDownloadResource={handleDownloadResource}
      handleLockResource={handleLockResource}
      id={id}
      name={name}
      itemCanBeUpdated={!cannotEditResource}
      locked={locked}
      lockedByFolder={lockedByFolder}
      lockedByAnotherUser={lockedByAnotherUser}
      lockedByUsername={lockedByUsername}
      onOpenProductEditModal={onOpenProductEditModal}
      hovered={hovered}
    />
  );
  const renderTypeCell = () => {
    const { isProduct, stockBalance, fileExtension } = item;
    const isStockProduct = isProduct && Number.isFinite(stockBalance);

    return (
      <Box className={clsx([classes.cellContainer, classes.typeCell])}>
        {isProduct && (
          <Icon
            className={clsx(["nc-icon nc-cart-simple", classes.iconType])}
          />
        )}
        {isStockProduct && (
          <Typography className={classes.extText}>{stockBalance}</Typography>
        )}
        {!isStockProduct && <Icon className={clsx([type, classes.iconType])} />}
        {!isProduct && (
          <Typography className={classes.extText}>{fileExtension}</Typography>
        )}
        {item.thumbnailImageUri.endsWith("5") && (
          <>
            <AlarmIcon fontSize="16px" />
            <ThumbUpIcon fontSize="16px" color="#00FF00" /></>)}
      </Box>
    );
  };

  const handleCloseProductModal = () => {
    setProductModal(false);
    onProductModalClosed?.();
  };

  return (
    <TableRow
      onDragStart={e => handleDragStart(e, locked)}
      onDragEnd={handleDragEnd}
      draggable
      dense
      className={clsx([
        getCheckboxValue() && classes.selectedResource,
        getCheckboxValue() && "uq-pti-selectedResource",
        hovered && !getCheckboxValue() && classes.hovered,
        hovered && "uq-pti-hovered",
      ])}
      onMouseEnter={() => handleOnMouseEnter()}
      onMouseLeave={() => handleOnMouseLeave()}
      onContextMenu={e => handleContextMenu(e, item)}
    >
      <TableCell className={classes.tdCheckbox}>
        {cannotEditResource ? (
          renderCheckbox()
        ) : (
          <FilenameDropzone
            fileName={name}
            refetchResources={refetchResources}
            folderId={folderId}
            addSelectedResources={addSelectedResources}
            resourceId={id}
            handleError={handleError}
          >
            {renderCheckbox()}
          </FilenameDropzone>
        )}
      </TableCell>
      <TableCell className={classes.tdImage}>
        {cannotEditResource ? (
          renderImageCell()
        ) : (
          <FilenameDropzone
            fileName={name}
            refetchResources={refetchResources}
            folderId={folderId}
            addSelectedResources={addSelectedResources}
            resourceId={id}
            handleError={handleError}
          >
            {renderImageCell()}
          </FilenameDropzone>
        )}
      </TableCell>
      {fileViewFields.nameColumn && (
        <TableCell className={classes.tdName}>
          {cannotEditResource ? (
            <Box className={classes.cellContainer}>
              {/* <MenuProvider
                id={`edit-metadata-${item.id}`}
                className={classes.cursorContext}
              > */}

              <ProductName
                name={name}
                id={id}
                locked={cannotEditResource}
                handleError={handleError}
              />
              {/* </MenuProvider> */}
            </Box>
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              <Box className={classes.cellContainer} flex={1}>
                {/* <MenuProvider
                  id={`edit-metadata-${item.id}`}
                  className={classes.cursorContext}
                > */}

                <ProductName
                  name={name}
                  id={id}
                  lockedByFolder={lockedByFolder}
                  handleError={handleError}
                />
                {/* </MenuProvider> */}
                {/* {renderContextMenu()} */}
              </Box>
            </FilenameDropzone>
          )}
        </TableCell>
      )}
      {fileViewFields.descriptionColumn && (
        <TableCell className={classes.tdDesc}>
          {cannotEditResource ? (
            renderDescCell()
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              {renderDescCell()}
            </FilenameDropzone>
          )}
        </TableCell>
      )}
      {fileViewFields.uploadedColumn && (
        <TableCell className={classes.td}>
          {cannotEditResource ? (
            renderUploadCell()
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              {renderUploadCell()}
            </FilenameDropzone>
          )}
        </TableCell>
      )}
      {fileViewFields.downloadedColumn && (
        <TableCell className={classes.td}>
          {cannotEditResource ? (
            renderDownloadedCell()
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              {renderDownloadedCell()}
            </FilenameDropzone>
          )}
        </TableCell>
      )}
      {fileViewFields.editedColumn && (
        <TableCell className={classes.td}>
          {cannotEditResource ? (
            renderEdited()
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              {renderEdited()}
            </FilenameDropzone>
          )}
        </TableCell>
      )}
      {fileViewFields.versionColumn && (
        <TableCell className={classes.td} align="center">
          {cannotEditResource ? (
            renderVersionHistory()
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              {renderVersionHistory()}
            </FilenameDropzone>
          )}
        </TableCell>
      )}
      {fileViewFields.typeColumn && (
        <TableCell className={classes.tdType} align="center">
          {cannotEditResource ? (
            renderTypeCell()
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              {renderTypeCell()}
            </FilenameDropzone>


          )}
        </TableCell>
      )}

      {fileViewFields.sizeColumn && (
        <TableCell
          className={clsx([classes.td, hovered ? "hidden" : ""])}
          align="left"
        >
          {cannotEditResource ? (
            renderSizeCell()
          ) : (
            <FilenameDropzone
              fileName={name}
              refetchResources={refetchResources}
              folderId={folderId}
              addSelectedResources={addSelectedResources}
              resourceId={id}
              handleError={handleError}
            >
              {renderSizeCell()}
            </FilenameDropzone>
          )}
        </TableCell>
      )}
      <TableCell
        className={clsx([classes.tdActions, hovered ? "expanded" : ""])}
        align="right"
        colSpan={hovered ? 2 : 1}
      >
        {cannotEditResource ? (
          renderActionsCell()
        ) : (
          <FilenameDropzone
            fileName={name}
            refetchResources={refetchResources}
            folderId={folderId}
            addSelectedResources={addSelectedResources}
            resourceId={id}
            handleError={handleError}
          >
            {renderActionsCell()}
          </FilenameDropzone>
        )}
      </TableCell>

      {productModal && (
        <ProductModal
          product={product}
          handleClose={handleCloseProductModal}
          cartAddItem={cartAddItem}
          open={productModal}
          quantity={quantity}
          setQuantity={setQuantity}
          isImage={isImage}
          handleAddImageBasket={handleAddImageBasket}
          fromFileView={true}
        />
      )}

      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={() => handleDeleteModalClose(id)}
          name={name}
          handleDelete={handleDeleteResource}
          isSelected={getCheckboxValue()}
          selectedResources={selectedResources}
          handleMultipleDelete={handleMultipleDelete}
          deleteLoading={deleteLoading}
        />
      )}

      {openVersionHistory && (
        <VersionHistoryModal
          open={openVersionHistory}
          handleClose={handleCloseVersionHistory}
          resourceId={id}
          folderId={folderId}
          locked={cannotEditResource}
        />
      )}

      {confirmationModal && (
        <ConfirmationModal
          open={confirmationModal}
          lockedBy={lockedByUsername}
          isResource={true}
          handleClose={handleCloseConfirmationModal}
          message={errorConfirmUnlock}
          onClick={() =>
            handleLockResource(
              id,
              name,
              locked,
              lockedByUsername,
              lockedByAnotherUser
            )
          }
        />
      )}
    </TableRow>
  );
};

ProductsTableItem.defaultProps = {
  downloadedDate: "",
  downloadedBy: "",
  changedDate: "",
  changedBy: "",
  fileExtension: "",
  locked: false,
  showProductModalOnOpen: false,
  size: 0,
  type: "",
  uploadedDate: "",
  uploadedBy: "",
  versionNo: 0,
};

ProductsTableItem.propTypes = {
  addSelectedResource: PropTypes.func.isRequired,
  addSelectedResources: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  fileExtension: PropTypes.string,
  folderId: PropTypes.number.isRequired,
  folderIsLocked: PropTypes.bool.isRequired,
  handleClickAddFilesToCart: PropTypes.func.isRequired,
  handleCloseConfirmationModal: PropTypes.func.isRequired,
  handleDownloadMultipleResources: PropTypes.func.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  handleDragStart: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleLockResource: PropTypes.func.isRequired,
  handleOpenProductEditModal: PropTypes.func.isRequired,
  highResImage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    thumbnailImageUri: PropTypes.string.isRequired,
  }).isRequired,
  refetchResources: PropTypes.func.isRequired,
  removeSelectedResource: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  showProductModalOnOpen: PropTypes.bool,
  siteRoleRights: PropTypes.shape({
    canLock: PropTypes.bool,
  }).isRequired,
  size: PropTypes.number,
  type: PropTypes.string,
  userHasWritePermission: PropTypes.bool.isRequired,
};

export default memo(ProductsTableItem);

import { gql } from '@apollo/client';

const getMatchingSuppliers = gql`
  query GetMatchingSuppliers($data: MatchingSuppliersQueryInput!) {
    requestForProposal {
      getMatchingSuppliers(data: $data) {
        displayName
        supplierId
      }
    }
  }
`;

export default getMatchingSuppliers;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: 5,
    lineHeight: "normal",
    "& .MuiTableSortLabel-icon": {
      width: "14px !important",
      marginLeft: 2,
    },
  },
}));

const TableHeader = ({
  ascending,
  handleOnHeaderClick,
  headers,
  sortField,
}) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headers.map(x => (
          <TableCell key={x.headerName} className={classes.th}>
            <TableSortLabel
              active={sortField === x.headerName}
              direction={ascending ? "asc" : "desc"}
              onClick={() => handleOnHeaderClick(x.headerName)}
            >
              {x.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.defaultProps = {
  orderHistoryDetailSettings: null,
};

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  handleOnHeaderClick: PropTypes.func.isRequired,

  ascending: PropTypes.bool.isRequired,
  sortField: PropTypes.string.isRequired,
  orderHistoryDetailSettings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
};

export default TableHeader;

import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Box, Icon } from "@mui/material";
import CustomTextField from "../common/TextField";

const useStyles = makeStyles(() => ({
  searchWrap: {
    width: 300,
    marginBottom: 10,
  },
  iconSearch: {
    fontSize: 12,
    color: "#b3b3b3",
  },
}));

const PickingListTableSearch = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.searchWrap}>
      <CustomTextField
        placeholder={intl.formatMessage({
          id: "btn.search",
        })}
        endAdornment={
          <Icon className={clsx(["fa fa-search", classes.iconSearch])} />
        }
      />
    </Box>
  );
};

export default PickingListTableSearch;

import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import DraggableModal from "../../components/common/DraggableModal";
import DraggableModalHead from "../../components/common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../components/common/DraggableModal/DraggableModalBody";
import NewsTitleImage from "../../assets/img/newspost_title.png";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "700px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const NewsInformationModal = ({ open, toggleOpen, handleBtnOrder }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={() => toggleOpen()}
      customClass={classes.modal}
    >
      <DraggableModalHead
        title="Information"
        handleClose={() => toggleOpen()}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <Box>
          <Typography>
            INFORMATION WHEN YOU CREATE A NEWS POST TEMPLATE
          </Typography>
          <Typography>
            A template is a document that serves as a starting point for a new
            News post. When the news post creator opens a template, it is
            pre-formatted in some way by having a predefined page layout, fonts,
            margins and styles. The template also consists of fields, for
            example title fields, that are filled in by the news post creator.
          </Typography>
          <br />
          <Typography>
            Below you can read about what fields that you need to add to the
            template.
          </Typography>
          <br />
          <Typography>TITLE</Typography>
          <Typography>
            You must add a title field that later will be replaced with the text
            that the user writes in the field "Title" (see screenshot). This is
            done by adding $Title$ in a text field, see example.
          </Typography>
          <img src={NewsTitleImage} alt="Title" className={classes.img} />
          <br />
          <Typography>FEATURED IMAGE</Typography>
          <Typography>
            You must add a featured image link to create a replaceable image in
            the news post. Add the image link
            https://www.uniqueue.se/DynamicImageReplacement.png. We suggest that
            you first insert a correct image, and then replace it with the link
            above.
          </Typography>
          <br />
          <Typography>CREATOR</Typography>
          <Typography>
            You must add a creator field that later will be automatically
            replaced with the creators name or user name. To add this, add the
            field $Creator$ in a text field.
          </Typography>
          <br />
          <Typography>AVATAR</Typography>
          <Typography>
            You can add an avatar of the creator in the news post. Add the image
            link https://www.uniqueue.se/avatar.png.
          </Typography>
          <br />
          <Typography>CREATED DATE</Typography>
          <Typography>
            You can add a created date that later will be replaced with the
            creation date for the news posts. This is done by adding
            $CreatedDate$ in a text field, that later will be substituted with
            the text you write in "CreatedDate" in the news post.
          </Typography>
          <br />
          <Typography>CATEGORY</Typography>
          <Typography>
            You can add a category field by adding $Category$ in a text field,
            that later will be substituted with the text that the News post
            creator writes in "Category".
          </Typography>
        </Box>

        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btn} onClick={handleBtnOrder}>
            Ok
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default NewsInformationModal;

import PageRoute from "../constant/pageRouteDictionary.json";

const TAB_ID_PARAMETER = "t";

const createUrl = tabId => {
  var qs = (tabId && `?${TAB_ID_PARAMETER}=${tabId}`) || "";
  return `${PageRoute.TERMS_OF_USE}${qs}`;
};

const getTabId = searchParams => {
  return searchParams?.get(TAB_ID_PARAMETER) || "";
};

export { createUrl, getTabId };

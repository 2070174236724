import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(theme => ({
  popper: {
    position: "fixed !important",
    top: "110px !important",
    transform: "none !important",
    width: "39.5%",
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    maxWidth: "unset",
    minHeight: "87vh",
    maxHeight: "87vh",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "70%",
      objectFit: "contain",
      maxHeight: "75vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  popperRight: {
    position: "fixed !important",
    top: "165px !important",
    right: "60px !important",
    left: "auto !important",
    transform: "none !important",
    width: "67.5%",
  },
  tooltipRight: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    maxWidth: "unset",
    minHeight: "80vh",
    maxHeight: "80vh",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "70%",
      objectFit: "contain",
      maxHeight: "75vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  disableToolTip: {
    display: "none",
  },
  image: {
    maxHeight: 150,
    maxWidth: "inherit",
  },
}));

const Image = ({ name, img, hoverImg }) => {
  const classes = useStyles();

  const [hoverImage, setHoverImage] = useState(img);
  const handleImageLoaded = () => {
    setHoverImage(hoverImg);
  };

  return (
    <Tooltip
      placement="right"
      classes={{
        tooltip: classes.tooltipRight,
        popper: classes.popperRight,
      }}
      disabled
      title={
        <img src={hoverImage} onLoad={() => handleImageLoaded()} alt={name} />
      }
    >
      <img src={img} alt={name} className={classes.image} />
    </Tooltip>
  );
};

Image.defaultProps = {
  name: null,
  img: null,
  hoverImg: null,
};

Image.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
  hoverImg: PropTypes.string,
};

export default Image;

import { gql } from '@apollo/client';
import ProjectLookup from "../../fragments/Projects/ProjectLookup";

const LIST_PROJECTS = gql`
  query listProjects($siteId: Int!) {
    projects {
      getProjects(siteId: $siteId) {
        ...ProjectLookup
      }
    }
  }
  ${ProjectLookup}
`;

export default LIST_PROJECTS;

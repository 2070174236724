import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import HeaderText from "../../common/FilesTable/HeaderText";
import { PinkSwitch } from "../../common";
import RowCheckbox from "./RowCheckbox";

const AllFilesRow = ({ handleAllNotifySwitch }) => {
  return (
    <>
      <TableRow style={{ backgroundColor: "#d5d5d5" }}>
        <TableCell colSpan={5} style={{ borderBottom: "none" }}>
          <HeaderText>
            <FormattedMessage id="monitoring.settingsForFilesInSelectedFolder" />
          </HeaderText>
        </TableCell>
      </TableRow>
    </>
  );
};

AllFilesRow.propTypes = {};

export default AllFilesRow;

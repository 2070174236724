import React from "react";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles({
  skeleton: {
    marginBottom: 2,
    textAlign: "center",
  },
});

const CategorySkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={35}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
    </>
  );
};

export default CategorySkeleton;

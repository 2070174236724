import { gql } from '@apollo/client';

const UPDATE_FILE_CONTENT = gql`
  mutation UpdateFileContent($productId: ID!, $file: String, $content: String) {
    dynamicTemplateEditContext {
      updateFileContent(productId: $productId, file: $file, content: $content)
    }
  }
`;

export default UPDATE_FILE_CONTENT;

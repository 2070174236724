import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  Input,
  FormControl,
  Select,
  Button,
  Icon,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import CustomSelect from "../../../common/CustomSelect";
import DateFull from "../../../common/FormControl/DateFull";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiInput-underline:before": {
      content: "none",
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 5,
  },
  labelDate: {
    fontSize: 12,
    fontWeight: 600,
  },
  formControl: {
    fontSize: 12,
    padding: "8px 12px",
    height: "100%",
    color: "#66615b",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    backgroundColor: theme.palette.common.white,
  },
  menuItem: {
    fontSize: 12,
  },
  text: {
    fontSize: 12,
  },
  date: {
    "& .MuiTextField-root": {
      marginTop: 6,
    },
  },
  btnSearch: {
    padding: "7px 25px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  clearLink: {
    fontSize: 12,
    marginLeft: 20,
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  clearIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
}));

const selectValues = [
  "Admin events",
  "User events",
  "Folder events",
  "Project events",
];

const LogsFilter = () => {
  const classes = useStyles();
  const [option, setoption] = React.useState([]);
  const handleChange = event => {
    setoption(event.target.value);
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography className={classes.label}>User</Typography>
              <CustomSelect options={[{ label: "All users", value: 1 }]} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.label}>Events</Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  multiple
                  value={option}
                  onChange={handleChange}
                  input={<Input />}
                  variant="outlined"
                  classes={{ select: classes.formControl }}
                >
                  {selectValues.map(selectValue => (
                    <MenuItem
                      key={selectValue}
                      value={selectValue}
                      className={classes.menuItem}
                    >
                      {selectValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            spacing={2}
            className={classes.date}
            alignItems="flex-end"
          >
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelDate}>From</Typography>
              <DateFull />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.labelDate}>To</Typography>
              <DateFull />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button className={classes.btnSearch}>
                <Icon className={clsx(["fa fa-search", classes.btnIcon])} />
                <FormattedMessage id="btn.search" />
              </Button>
              <Button className={classes.clearLink}>
                <Icon className={clsx(["fas fa-times", classes.clearIcon])} />
                Clear filters
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogsFilter;

import React from "react";
import { Typography, Grid, Divider, Table, TableBody } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import ManageRolesTableRow from "./ManageRolesTableRow";

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  editButton: {
    color: theme.palette.common.link,
    padding: "4px !important",
    marginLeft: 4,
  },
  editIcon: {
    fontSize: 10,
  },
  spaceLg: {
    marginBottom: 24,
  },
  td: {
    padding: "4px 0",
    fontSize: 11,
  },
}));

const Menus = ({
  disabled,
  handleCheckbox,
  handleCheckboxSiteRoleSettings,
  handleOpenEditMenus,
  roleSubscriptionLevel,
  siteRoleValues,
  siteSettings,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const headers = [
    {
      label: intl.formatMessage({
        id: "siteRoles.canSeeDynamicTemplates",
      }),
      name: "dynamicTemplates",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canSeeImages",
      }),
      name: "images",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canSeeOnlineDesignerDocs",
      }),
      name: "onlineDesignerDocs",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canSeeStaticProducts",
      }),
      name: "staticTemplates",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canSeeStockProducts",
      }),
      name: "stockTemplates",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.canSeeOtherDocs",
      }),
      name: "otherDocs",
      checkbox: true,
      toolTipInfo: true,
    },
  ];

  const menuHead = [
    {
      label: intl.formatMessage({
        id: "siteRoles.indentTree",
      }),
      name: "categoryTreeIndentation",
      checkbox: true,
    },
  ];

  return (
    <Grid item xs={12} md={6}>
      <Typography className={classes.text}>
        <FormattedMessage id="siteRoles.showFileTypes" />
      </Typography>
      <Divider className={classes.divider} />
      <Table className={classes.table} size="small">
        <TableBody>
          {headers.map(head => {
            const toolTipInfo = head.toolTipInfo ? head.toolTipInfo : false;
            return (
              <ManageRolesTableRow
                checkbox={head.checkbox}
                disabled={disabled}
                key={head.name}
                label={head.label}
                name={head.name}
                onChange={handleCheckbox}
                roleSubscriptionLevel={roleSubscriptionLevel}
                siteRoleValues={siteRoleValues}
                toolTipInfo={toolTipInfo}
              />
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

Menus.defaultProps = {
  disabled: false,
  siteRoleValues: {},
  siteSettings: {},
};

Menus.propTypes = {
  disabled: PropTypes.bool,
  handleCheckbox: PropTypes.func.isRequired,
  handleCheckboxSiteRoleSettings: PropTypes.func.isRequired,
  handleOpenEditMenus: PropTypes.func.isRequired,
  siteRoleValues: PropTypes.shape({}),
  siteSettings: PropTypes.shape({}),
};

export default Menus;

import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../common";

const NewCompanyButton = ({ onClick }) => (
  <PrimaryButton onMouseDown={onClick}>
    <FormattedMessage id="siteUsers.addNewCompany" />
  </PrimaryButton>
);

export default NewCompanyButton;

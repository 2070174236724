import React, { memo } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FolderName from "./FolderName";
import ApplyToSubfolder from "../../ProductsMainView/MonitoringModal/StepThree/ApplyToSubfolder";

const useStyles = makeStyles(theme => ({
  folderIcon: {
    fontSize: 14,
    color: "#5b5654",
  },
  folderIconNoChild: { margin: "2px 3px 2px 3px !important" },
  folderIconWithChild: { margin: "2px 3px 2px 3px" },
  checkbox: { padding: "3px !important", marginLeft: 20 },
  applyToSubfolders: {
    marginLeft: "auto",
  },
  folderTitleSpan: {
    flexDirection: "row",
    alignItems: "flex-start",
    display: "flex",
    cursor: "pointer",
    flex: 1,
    position: "relative",
  },
  editInput: {
    backgroundColor: "#fff",
    fontSize: 11,
    height: 5,
  },
  dimIcon: {
    opacity: "0.5",
  },
  lockIconWithChild: {
    position: "absolute",
    fontSize: 8,
    top: 5,
    left: 6,
    color: "#CD132D",
  },
  lockIconNoChild: {
    position: "absolute",
    fontSize: 8,
    top: 5,
    left: 6,
    opacity: "0.8",
    color: "#CD132D",
  },
  actions: {
    flex: 1,
    textAlign: "right",
  },
}));

const FolderLabel = ({
  checkedFolders,
  folder,
  folderId,
  folderName,
  handleCheckboxChange,
  handleFolderDragOver,
  handleNewFolderName,
  handleOFolderDragLeave,
  handleOnFolderRowClick,
  handleOnKeyDown,
  isEditMode,
  selectedNode,
  setFolderName,
  hasContent,
  //monitoring,
  folderPropertyFormValues,
  setFolderPropertyFormValues,
  propertyFormValues,
  setPropertyFormValues,
  setCheckedFolders,
  isMonitoring,
  selectedFolderIds,
}) => {
  const classes = useStyles();
  let folderFormValues = {};
  if (isMonitoring) {
    folderFormValues = folderPropertyFormValues.find(
      x => x.folderId === folder.folderId
    );
  }

  const { isNotifyEnabled, isIncluded } = folderFormValues || {};

  return (
    <span
      role="presentation"
      className={clsx([classes.folderTitleSpan])}
      onClick={handleOnFolderRowClick}
      onKeyDown={handleOnKeyDown}
      onDragOver={handleFolderDragOver}
      onDragLeave={handleOFolderDragLeave}
    >
      <Icon
        className={clsx([
          "fa fa-folder",
          classes.folderIcon,
          folder && folder.childFolderIds && folder.childFolderIds.length > 0
            ? classes.folderIconWithChild
            : classes.folderIconNoChild,
          !hasContent && classes.dimIcon,
        ])}
        style={{ color: folder.colorValue }}
      />
      {folder && folder.locked && (
        <Icon
          className={clsx([
            "fa fa-lock",
            folder && folder.childFolderIds && folder.childFolderIds.length > 0
              ? classes.lockIconWithChild
              : classes.lockIconNoChild,
          ])}
        />
      )}

      {isEditMode && selectedNode.folderId === folderId ? (
        <TextField
          variant="outlined"
          onBlur={e => handleNewFolderName(e.target.value)}
          onKeyDown={e =>
            e.key === "Enter" && handleNewFolderName(e.target.value)
          }
          autoFocus
          onFocus={e => {
            e.target.select();
          }}
          InputProps={{
            classes: { input: classes.editInput },
          }}
          margin="dense"
          value={folderName}
          onChange={e => setFolderName(e.target.value)}
        />
      ) : (
        <FolderName name={folderName} />
      )}

      <span className={classes.actions}>
        {isMonitoring && selectedFolderIds.includes(folderId) && (
          <ApplyToSubfolder
            folder={folder}
            setCheckedFolders={setCheckedFolders}
            checkedFolders={checkedFolders}
            folderPropertyFormValues={folderPropertyFormValues}
            setFolderPropertyFormValues={setFolderPropertyFormValues}
            propertyFormValues={propertyFormValues}
            setPropertyFormValues={setPropertyFormValues}
            enabled={isNotifyEnabled || isIncluded}
          />
        )}
        {checkedFolders.includes(folder.folderId) && (
          <Checkbox
            color="primary"
            className={classes.checkbox}
            checked={checkedFolders.includes(folder.folderId)}
            onChange={handleCheckboxChange}
          />
        )}
      </span>
    </span>
  );
};

FolderLabel.defaultProps = {
  isEditMode: false,
  folderName: "",
  selectedNode: null,
  hasContent: false,
};

FolderLabel.propTypes = {
  selectedNode: PropTypes.shape({
    folderId: PropTypes.number.isRequired,
  }),
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    folderId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
    locked: PropTypes.bool,
  }).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  isEditMode: PropTypes.bool,
  folderId: PropTypes.number.isRequired,
  folderName: PropTypes.string,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleFolderDragOver: PropTypes.func.isRequired,
  handleNewFolderName: PropTypes.func.isRequired,
  handleOFolderDragLeave: PropTypes.func.isRequired,
  handleOnFolderRowClick: PropTypes.func.isRequired,
  handleOnKeyDown: PropTypes.func.isRequired,
  setFolderName: PropTypes.func.isRequired,
  hasContent: PropTypes.bool,
};

export default memo(FolderLabel);

import { gql } from '@apollo/client';
import { Project, ProjectUserLookup, Tasks } from "../../fragments/Projects";

const GET_PROJECT_AND_TASKS = gql`
  query getProjectAndTasks($projectId: Int!) {
    projects {
      getProject(projectId: $projectId) {
        ...Project
      }
      getTasks(projectId: $projectId) {
        ...Tasks
      }
      listProjectUserCandidates(projectId: $projectId) {
        ...ProjectUserLookup
      }
    }
  }
  ${Project}
  ${ProjectUserLookup}
  ${Tasks}
`;

export default GET_PROJECT_AND_TASKS;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_FOLDER_DATA } from "../../../graphql/queries";
import { ButtonText } from "../../common";

const useStyles = makeStyles(theme =>
  createStyles({
    widgetFolderLink: {
      textDecoration: "none",
      textAlign: "left",
      fontSize: 12,
      color: theme.palette.primary.main,
      marginBottom: 8,
      display: "block",
    },
  })
);

const ParentFolderItem = ({
  siteId,
  parentFolderId,
  setOpenedNodes,
  setFolderId,
  rootFolderId,
}) => {
  const classes = useStyles();
  const [folders, setFolders] = useState([]);

  const { data } = useQuery(GET_FOLDER_DATA, {
    variables: {
      siteId,
      folderId: parentFolderId,
      folderLevel: 2,
    },
    skip: !siteId || !parentFolderId,
  });

  useEffect(() => {
    if (data && data.widgets && data.widgets.getFolderData) {
      setFolders(data.widgets.getFolderData);
    } else {
      setFolders([]);
    }
  }, [data]);

  return (
    <>
      {folders.map(folder => (
        <Grid item xs={12} sm={3} key={folder.folderId}>
          <ButtonText
            key={folder.folderId}
            onClick={() => {
              setFolderId(folder.folderId);
              setOpenedNodes([rootFolderId, parentFolderId, folder.folderId]);
            }}
            label={folder.name}
            btnClass={classes.widgetFolderLink}
          />
        </Grid>
      ))}
    </>
  );
};

ParentFolderItem.defaultProps = {};

ParentFolderItem.propTypes = {
  rootFolderId: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
  parentFolderId: PropTypes.number.isRequired,
  setOpenedNodes: PropTypes.func.isRequired,
  setFolderId: PropTypes.func.isRequired,
};

export default ParentFolderItem;

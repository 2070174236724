import React, { memo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  listSteps: {
    paddingLeft: 0,
    marginBottom: 0,
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginBottom: 20,
      textAlign: "left",
    },
  },
  badge: {
    fontSize: 14,
    fontWeight: 700,
    padding: 0,
    borderRadius: "50%",
    marginRight: 5,
    width: 24,
    height: 24,
    display: "inline-block",
    textAlign: "center",
    lineHeight: "20px",
    backgroundColor: "transparent",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    transition: "all 0.7s ease",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: 20,
      height: 20,
      lineHeight: "18px",
    },
  },
  badgeActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  badgeList: {
    display: "inline-block",
    listStyleType: "none",
    marginRight: 0,
    verticalAlign: "middle",
    fontSize: 14,
    color: "#555",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    "&:hover": {
      cursor: "pointer",
    },
    "&:hover $badge": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  line: {
    display: "inline-block",
    width: 40,
    borderTop: "1px solid #d6d6d6",
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 3,
  },
}));
const Stepper = ({ page, setPage }) => {
  const classes = useStyles();
  return (
    <ul className={classes.listSteps}>
      <li className={classes.badgeList} onClick={() => setPage(1)}>
        <span className={clsx([classes.badge, page === 1 && classes.badgeActive])}>
          1
        </span>{" "}
        <FormattedMessage id="cart.products" />{" "}
        <span className={classes.line} />
      </li>
      <li className={classes.badgeList} onClick={() => setPage(2)}>
        <span className={clsx([classes.badge, page === 2 && classes.badgeActive])}>
          2
        </span>{" "}
        <FormattedMessage id="cart.deliveryInfo" />
      </li>
    </ul>
  );
};

Stepper.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

function areEqual(prevProps, nextProps) {
  if (prevProps.page !== nextProps.page) {
    return false;
  }
  return true;
}

export default memo(Stepper, areEqual);

import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_RECENTLY_MODIFIED_RESOURCES_FOR_SITE } from "../graphql/queries";

// eslint-disable-next-line import/prefer-default-export
export const useGetRecentlyModifiedResourcesForSite = ({ siteId, limit }) => {
  const [items, setItems] = useState([]);

  const [getItems, { data, loading, error }] = useLazyQuery(
    GET_RECENTLY_MODIFIED_RESOURCES_FOR_SITE,
    {
      variables: {
        siteId,
        limit,
      },
      skip: !siteId,
    }
  );

  useEffect(() => {
    if (siteId) {
      getItems();
    }
  }, [siteId]);

  useEffect(() => {
    if (
      data &&
      data.widgets &&
      data.widgets.getRecentlyModifiedResourcesForSite
    ) {
      setItems(data.widgets.getRecentlyModifiedResourcesForSite);
    } else {
      setItems([]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setItems([]);
    }
  }, [error]);

  return { execute: getItems, items, loading, error };
};

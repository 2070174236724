import PropTypes from "prop-types";
import { Item, Menu } from "react-contexify";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { useSnackbar } from "../../../hooks";

const StyledItem = styled(Item)(({ appearDisabled, theme }) => ({
  fontSize: 11,
  "&:hover": {
    "& .react-contexify__item__content": {
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  "& .react-contexify__item__content": {
    color: "#fff !important",
    opacity: appearDisabled ? 0.4 : "",
    backgroundColor: appearDisabled ? "#7b505d" : "",
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  paddingTop: "0 !important",
  paddingBottom: "0 !important",
  fontSize: 11,
  boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
  lineHeight: "20px",
  backgroundColor: `${theme.palette.primary.main} !important`,
  "& .react-contexify__submenu": {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  "& .react-contexify__item__content": {
    color: "#fff !important",
  },
  "& .react-contexify__separator": {
    backgroundColor: "#fff !important",
    float: "none",
    margin: 0,
  },
}));

const ContextMenuItem = ({ disabled, labelId, onClick, onDisabledClick }) => (
  <StyledItem
    appearDisabled={disabled}
    disabled={disabled && !onDisabledClick}
    onClick={disabled ? onDisabledClick : onClick}
  >
    <FormattedMessage id={labelId} />
  </StyledItem>
);

const ContextMenu = ({
  canAddToCart,
  canCopyResource,
  canDownload,
  canEditResource,
  canRequestConsent,
  contextMenuId,
  item,
  onAddFileToCart,
  onCopyResources,
  onDeleteResource,
  onDownloadMultipleResources,
  onDownloadResource,
  onEditMetadata,
  onRequestConsent,
  selectedResources,
}) => {
  const snackbar = useSnackbar();

  const handleCopyResource = () => {
    let ids = [item.id];

    if (selectedResources.length > 1) {
      ids = selectedResources.map(x => x.id);
    }

    onCopyResources?.(ids);
  };

  const handleDisabledClick = () => {
    snackbar.workspaceInfo(
      <FormattedMessage id="common.userLacksSufficientRightsMessage" />
    );
  };

  const handleDownloadClick = () => {
    const multipleItemDownload = (selectedResources.length > 1) && selectedResources.find(x => x.id === item.id);

    if (multipleItemDownload) {
      onDownloadMultipleResources?.();
    }
    else {
      onDownloadResource?.(item.id);
    }
  };

  return (
    <StyledMenu id={contextMenuId}>
      <ContextMenuItem
        disabled={!canEditResource}
        labelId="metadata.editMetadataAndTagging"
        onClick={onEditMetadata}
        onDisabledClick={handleDisabledClick}
      />

      <ContextMenuItem
        disabled={!canAddToCart}
        labelId="context.addCart"
        onClick={onAddFileToCart}
        onDisabledClick={handleDisabledClick}
      />

      <ContextMenuItem
        disabled={!canRequestConsent}
        labelId="context.requestConsent"
        onClick={() => onRequestConsent?.(item)}
        onDisabledClick={handleDisabledClick}
      />

      <ContextMenuItem
        disabled={!canEditResource}
        labelId="context.delete"
        onClick={() => onDeleteResource?.(item.id)}
        onDisabledClick={handleDisabledClick}
      />

      <ContextMenuItem
        disabled={!canCopyResource}
        labelId="context.copy"
        onClick={handleCopyResource}
        onDisabledClick={handleDisabledClick}
      />

      <ContextMenuItem
        disabled={!canDownload}
        labelId="context.download"
        onClick={handleDownloadClick}
        onDisabledClick={handleDisabledClick}
      />
    </StyledMenu>
  );
};

ContextMenu.defaultProps = {
  selectedResources: [],
};

ContextMenu.propTypes = {
  canAddToCart: PropTypes.bool.isRequired,
  canCopyResource: PropTypes.bool.isRequired,
  canDownload: PropTypes.bool.isRequired,
  canEditResource: PropTypes.bool.isRequired,
  canRequestConsent: PropTypes.bool.isRequired,
  contextMenuId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onAddFileToCart: PropTypes.func.isRequired,
  onCopyResources: PropTypes.func.isRequired,
  onDeleteResource: PropTypes.func.isRequired,
  onDownloadMultipleResources: PropTypes.func.isRequired,
  onDownloadResource: PropTypes.func.isRequired,
  onEditMetadata: PropTypes.func.isRequired,
  onRequestConsent: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ContextMenu;

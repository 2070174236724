import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";
import CompanyRecursiveFragment from "../../../fragments/CompanyRecursive";

const RENAME_NETWORK = gql`
  mutation RenameNetwork($companyId: Int!, $networkId: Int!, $name: String) {
    companyAdminContext {
      companyNetworks {
        renameNetwork(
          companyId: $companyId
          networkId: $networkId
          name: $name
        ) {
          errorResult {
            ...ErrorResultFields
          }
          result {
            ...CompanyRecursive
            name
            networkId
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${CompanyRecursiveFragment}
`;

export default RENAME_NETWORK;

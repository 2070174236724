import { gql } from '@apollo/client';

const UPDATE_HELP = gql`
  mutation updateHelp(
    $helpKey: String!
    $content: String!
    $relatedSection: [HelpRelatedSectionInput]
  ) {
    updateHelp(
      helpId: $helpKey
      content: $content
      relatedSection: $relatedSection
    ) {
      content
      helpId
      relatedSections {
        helpId
        label
      }
    }
  }
`;

export default UPDATE_HELP;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const ACCEPT_TERMS_OF_USE = gql`
  mutation AcceptTermsOfUse {
    termsOfUseContext {
      acceptTermsOfUse {
        __typename
        success
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default ACCEPT_TERMS_OF_USE;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useMutation } from "@apollo/client";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

import PageRoute from "../../../../constant/pageRouteDictionary.json";
import { CREATE_CONSENT_REQUEST } from "../../../../graphql/mutations";
import { useSnackbar } from "../../../../hooks";
import { appcUrl } from "../../../../services/config";
import { DraggableModal, DraggableModalBody, DraggableModalHead } from "../../../common";
import CustomTextField from "../../../common/TextField";
import { ConsentCard, ConsentTerm } from "../../../Consent";

const ConsentContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  marginTop: theme.spacing(2),
  flexWrap: 'wrap',
  flexDirection: 'row',
}));

const StyledConsentCard = styled(ConsentCard)(({ theme }) => ({
  width: 170,
  height: 170,
  margin: theme.spacing(1),
  overflow: 'hidden',
  position: 'relative',
}));

const TermsHeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.component.productBoxColor,
  fontWeight: 600,
}));

const textFieldLabelStyle = {
  color: "#000",
  fontSize: "11px!important",
  fontWeight: 600,
};

const initialFormValues = {
  firstname: "",
  lastname: "",
  title: "",
  email: "",
  villkor1: true,
  villkor2: true,
  villkor3: true,
  villkor4: true
};

const RequestConsentModal = ({ details, open, onClose, readOnly, selectedResources }) => {
  const intl = useIntl();
  const snackbar = useSnackbar();

  const [createConsentRequest, { data, loading, error }] = useMutation(CREATE_CONSENT_REQUEST);

  const selectedWorkspace = useSelector(
    state => state.ui.toggleWorkspaces.workspace
  );

  const [disabled, setDisabled] = useState(true);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [resources, setResources] = useState([]);
  const [selectedSiteId] = useState(
    (selectedWorkspace && selectedWorkspace.id) || null
  );

  useEffect(() => {
    let newValues = initialFormValues;
    let newResources = selectedResources;
    if (readOnly && details) {
      var { email, firstname, lastname, title, resources } = details;
      newValues = { ...newValues, email, firstname, lastname, title };
      newResources = resources;
    }
    setFormValues(newValues);
    setResources(newResources);
  }, [readOnly]);

  useEffect(() => {
    if (data?.consentContext?.createConsentRequest) {
      snackbar.success("Skickat");
      onClose();
    }
    setDisabled(false);
  }, [data, loading, error])

  const onSubmit = () => {
    setDisabled(true);

    const { firstname, lastname, email, title } = formValues;

    var resourceIds = selectedResources.map(n => n.id);

    var clientInvitationHandlerUrl = `${appcUrl}${PageRoute.CONSENT}`;

    var input = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      title: title,
      resourceIds: resourceIds,
      siteId: selectedSiteId,
    }

    createConsentRequest({ variables: { input: input, clientUrlBase: clientInvitationHandlerUrl } });
  }

  const onTermClick = (e) => {
    const { checked, name, type, value } = e.target;

    const temp = {
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    };

    setDisabled(temp["firstname"]?.length === 0 || temp["lastname"]?.length === 0 || temp["email"]?.length === 0);
    setFormValues(temp);
  }

  const commonTextFieldProps = {
    inputProps: {
      readOnly,
    },
    onChange: onTermClick,
    shrink: true,
    sx: {
      "& > .MuiInputLabel-root": textFieldLabelStyle
    },
  };

  return (
    <DraggableModal open={open} onClose={onClose}>
      <DraggableModalHead
        handleClose={onClose}
        title={intl.formatMessage({
          id: readOnly
            ? "context.requestedConsent"
            : "context.requestConsent"
        })}
      />
      <DraggableModalBody>

        <Stack spacing={{ xs: 1 }}>
          <Stack direction="row" spacing={{ xs: 1 }}>
            <CustomTextField
              name="firstname"
              required
              label={<FormattedMessage id="settings.firstName" />}
              value={formValues.firstname}
              {...commonTextFieldProps}
            />

            <CustomTextField
              name="lastname"
              required
              label={<FormattedMessage id="settings.lastName" />}
              value={formValues.lastname}
              {...commonTextFieldProps}
            />
          </Stack>

          <CustomTextField
            name="title"
            label={<FormattedMessage id="settings.position" />}
            value={formValues.title}
            {...commonTextFieldProps}
          />

          <CustomTextField
            name="email"
            required
            label={<FormattedMessage id="settings.email" />}
            value={formValues.email}
            {...commonTextFieldProps}
          />

          <ConsentContainer>
            {resources.map((resource) => {
              const { approved, id, resourceId, thumbnailImageUri } = { approved: false, ...resource };
              return (
                <StyledConsentCard
                  consented={approved}
                  imageUrl={thumbnailImageUri}
                  key={id || resourceId}
                  readOnly={readOnly}
                />
              );
            })}
          </ConsentContainer>

          <Stack>
            <TermsHeaderTypography>
              <FormattedMessage id="consent.terms" />
            </TermsHeaderTypography>
            <ConsentTerm name="villkor1" onClick={onTermClick} value={formValues.villkor1} label="Jag håller med om att bild/video kan sparas" readOnly={readOnly} disabled />
            <ConsentTerm name="villkor2" onClick={onTermClick} value={formValues.villkor2} label="Samtycke för externt bruk" readOnly={readOnly} />
            <ConsentTerm name="villkor3" onClick={onTermClick} value={formValues.villkor3} label="Bilden får endast användas i intern kommunikation" readOnly={readOnly} />
            <ConsentTerm name="villkor4" onClick={onTermClick} value={formValues.villkor4} label="Bilden får användas i marknadsföring" readOnly={readOnly} />
          </Stack>
        </Stack>

        {!readOnly &&
          <Box align="right">
            <Button onClick={onClose} sx={{ marginRight: 1 }} variant="outlined">
              <FormattedMessage id="btn.cancel" />
            </Button>
            <Button disabled={disabled} onClick={onSubmit} variant="contained">
              <FormattedMessage id="consent.createAndSubmit" />
            </Button>
          </Box>
        }

      </DraggableModalBody>
    </DraggableModal>
  );
}

RequestConsentModal.defaultProps = {
  details: undefined,
  open: false,
  onClose: undefined,
  readOnly: false,
  resources: [],
  selectedResources: undefined,
};

RequestConsentModal.propTypes = {
  details: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    title: PropTypes.string,
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        approved: PropTypes.bool.isRequired,
        resourceId: PropTypes.number.isRequired,
        thumbnailImageUri: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  readOnly: PropTypes.bool,
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      thumbnailImageUri: PropTypes.string.isRequired,
    })
  ),
};

export default RequestConsentModal;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Box, Typography, Grid } from "@mui/material";
import clsx from "clsx";
import MarkerStart from "../../assets/img/link-broken-start.svg";
import MarkerEnd from "../../assets/img/link-broken-end.svg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperHead: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    paddingBottom: 0,
  },
  title: {
    color: theme.palette.component.productBoxColor,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
    display: "inline-block",
  },
  subTitle: {
    color: theme.palette.component.productBoxColor,
    fontSize: 14,
    display: "inline-block",
  },
  paperBody: {
    padding: theme.spacing(3),
  },
  binder: {
    marginBottom: 30,
  },
  binderLabels: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
  binderDot: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 10,
    backgroundColor: "#ddd",
  },
  binderName: {
    fontSize: 16,
    fontWeight: 700,
  },
  binderPercent: {
    fontSize: 16,
    fontWeight: 700,
    marginLeft: "auto",
    "& span": {
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 5,
    },
  },
  blue: {
    "& $binderDot": {
      backgroundColor: "#00a0e7",
    },
    "& $binderName": {
      color: "#00a0e7",
    },
  },
  red: {
    "& $binderDot": {
      backgroundColor: "#d66459",
    },
    "& $binderName": {
      color: "#d66459",
    },
  },
  binderProgress: {
    backgroundColor: "#f2f2f2",
    borderRadius: 5,
    height: 34,
    position: "relative",
  },
  binderItem: {
    height: "100%",
    backgroundColor: "#ddd",
    float: "left",
    "&:first-of-type": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
  },
  binderItemGreen: {
    backgroundColor: "#88d79b",
  },
  binderItemDGreen: {
    backgroundColor: "#40c566",
  },
  binderItemRed: {
    backgroundColor: "#d66459",
  },
  binderLinkStart: {
    marginLeft: -20,
    marginTop: -6,
  },
  binderLinkEnd: {
    position: "absolute",
    marginTop: -6,
  },
  binderMarker: {
    borderLeft: "2px dotted #929292",
    height: 48,
    display: "inline-block",
    position: "absolute",
    top: -7,
  },
  binderMarkerDBlue: {
    borderColor: "#004f8f",
  },
  binderMarkerBlue: {
    borderColor: "#00a0e7",
  },
}));

export default function OverviewBinder() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper} elevation={0}>
            <Box className={classes.paperHead}>
              <Typography variant="h1" component="h2" className={classes.title}>
                Yamal
              </Typography>
              <Typography className={classes.subTitle}>
                - Overview of binders
              </Typography>
            </Box>
            <Box className={classes.paperBody}>
              {/* Sample item 1 */}
              <Box className={clsx(classes.binder, classes.blue)}>
                <Box className={classes.binderLabels}>
                  <span className={classes.binderDot} />
                  <span className={classes.binderName}>4A#1</span>
                  <span className={classes.binderPercent}>
                    81%
                    <span>285/350 ark</span>
                  </span>
                </Box>
                <Box className={classes.binderProgress}>
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemGreen
                    )}
                    width="30%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemDGreen
                    )}
                    width="10%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemGreen
                    )}
                    width="12%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemDGreen
                    )}
                    width="13%"
                  />
                  <Box
                    className={clsx(classes.binderItem, classes.binderItemRed)}
                    width="15%"
                  />
                  <img
                    src={MarkerStart}
                    className={classes.binderLinkStart}
                    alt=""
                  />
                  <span
                    className={clsx(
                      classes.binderMarker,
                      classes.binderMarkerDBlue
                    )}
                    style={{ left: "1%" }}
                  />
                  <span
                    className={clsx(
                      classes.binderMarker,
                      classes.binderMarkerBlue
                    )}
                    style={{ left: "3%" }}
                  />
                  <span
                    className={clsx(classes.binderMarker)}
                    style={{ left: "29%" }}
                  />
                </Box>
              </Box>
              {/* Sample item 2 */}
              <Box className={clsx(classes.binder, classes.blue)}>
                <Box className={classes.binderLabels}>
                  <span className={classes.binderDot} />
                  <span className={classes.binderName}>4A#2</span>
                  <span className={classes.binderPercent}>
                    51%
                    <span>175/350 ark</span>
                  </span>
                </Box>
                <Box className={classes.binderProgress}>
                  <img
                    src={MarkerEnd}
                    className={classes.binderLinkEnd}
                    alt=""
                  />
                  <Box
                    className={clsx(classes.binderItem, classes.binderItemRed)}
                    width="30%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemGreen
                    )}
                    width="20%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemDGreen
                    )}
                    width="10%"
                  />
                  <span
                    className={clsx(
                      classes.binderMarker,
                      classes.binderMarkerDBlue
                    )}
                    style={{ left: "20%" }}
                  />
                  <span
                    className={clsx(
                      classes.binderMarker,
                      classes.binderMarkerBlue
                    )}
                    style={{ left: "27%" }}
                  />
                </Box>
              </Box>
              {/* Sample item 3 */}
              <Box className={clsx(classes.binder, classes.red)}>
                <Box className={classes.binderLabels}>
                  <span className={classes.binderDot} />
                  <span className={classes.binderName}>4A#3</span>
                  <span className={classes.binderPercent}>
                    81%
                    <span>285/350 ark</span>
                  </span>
                </Box>
                <Box className={classes.binderProgress}>
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemGreen
                    )}
                    width="30%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemDGreen
                    )}
                    width="10%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemGreen
                    )}
                    width="12%"
                  />
                  <Box
                    className={clsx(
                      classes.binderItem,
                      classes.binderItemDGreen
                    )}
                    width="13%"
                  />
                  <Box
                    className={clsx(classes.binderItem, classes.binderItemRed)}
                    width="15%"
                  />
                  <span
                    className={clsx(
                      classes.binderMarker,
                      classes.binderMarkerDBlue
                    )}
                    style={{ left: "1%" }}
                  />
                  <span
                    className={clsx(
                      classes.binderMarker,
                      classes.binderMarkerBlue
                    )}
                    style={{ left: "3%" }}
                  />
                  <span
                    className={clsx(classes.binderMarker)}
                    style={{ left: "29%" }}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

import { gql } from '@apollo/client';

const getProductList = gql`
  query GetProductList($productIds: [Int!], $breadcrumbs: Boolean) {
    productList(productIds: $productIds, breadcrumbs: $breadcrumbs) {
      items {
        articleNo
        breadcrumb {
          folders {
            folderId
            name
          }
          siteId
          siteName
        }
        imageMetadata {
          contact
          date
          imageNo
          isFreeToUser
          legalDocument
          location
          model
          occupation
          photographer
        }
        changedBy
        changedDate
        description
        downloadedBy
        downloadedDate
        fileExtension
        format
        id
        isEditableFromSearchResult
        locked
        minimumPrice
        minimumQuantity
        name
        numPages
        pricesInclusiveFreight
        productImageUri
        productDisplaySettings {
          showFormat
          showNumPages
          showPrice
          showStockBalance
        }
        productManager
        productResponsible
        productType
        quantities {
          selectionType
          valuesList
        }
        size
        statistics {
          downloadCount
          shareCount
          viewCount
        }
        stock {
          refillThreshold
          stockBalance
          temporaryOutOfStock
        }
        tags {
          tagId
          tagName
        }
        uploadedBy
        uploadedDate
        userPermissions {
          addToImageCart
          download
          order
          share
        }
        versionNo
        thumbnailImageUri
      }
      totalCount
    }
  }
`;

export default getProductList;

import React from "react";
import PropTypes from "prop-types";
import { Alert as MuiAlert }  from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAlert-message": {
      flexDirection: "row",
    },
  },
}));

const Alert = ({ children, onClose, type }) => {
  const classes = useStyles();
  return (
    <MuiAlert elevation={6} severity={type} onClose={onClose}>
      {children}
    </MuiAlert>
  );
};

Alert.defaultProps = {
  children: null,
  onClose: null,
  type: null,
};

Alert.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  type: PropTypes.string,
};

export default Alert;

import { gql } from '@apollo/client';

const CREATE_SITE_INVITATIONS = gql`
  mutation CreateSiteInvitations($input: CreateSiteInvitationInput) {
    createSiteInvitations(input: $input) {
      comment
      companies {
        companyName
        publicCompanyId
      }
      siteId
      siteName
      userDataItems {
        companyName
        highlightedCompanyIds
        email
        status
        statusMessage
        selectedCompanyId
        knownUser
        paymentLiable
        publicCompanyId
        siteRoleId
        siteRoleName
      }
    }
  }
`;

export default CREATE_SITE_INVITATIONS;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import { useAlert } from "react-alert";
import { useDeleteBasketGroup } from "../../hooks";

const useStyles = makeStyles(theme => ({
  image: {
    maxWidth: 56,
    marginRight: theme.spacing(1),
  },
  td: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  tdInfo: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 245,
    paddingTop: 5,
    paddingBottom: 5,
  },
  tdImg: {
    width: "20%",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    "& img": {
      maxHeight: 80,
      width: "100%",
      border: "1px solid #ececec",
      objectFit: "cover",
    },
    paddingTop: 10,
    paddingBottom: 5,
  },
  tdAction: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 30,
    paddingTop: 5,
    paddingBottom: 5,
  },
  removeRow: {
    padding: 5,
    color: theme.palette.secondary.dark,
    marginRight: 0,
  },
  loading: {
    marginLeft: 7,
  },
  name: {
    wordBreak: "break-all",
  },
  subText: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
  },
  subTextRight: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
    display: "inline-block",
    float: "right",
  },
  subTextLeft: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
    display: "inline-block",
    float: "left",
  },

  table: {
    "&:last-of-type": {
      "& td": {
        borderBottom: "none",
      },
    },
  },
}));

const GroupCartItem = ({ item }) => {
  const { packageName, items = [], packageId } = item;

  const classes = useStyles();
  const alert = useAlert();

  const errorRemoveCart = () => {
    alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
  };

  const successRemoveCart = () => {
    alert.success(<FormattedMessage id="product.successRemovedFromCart" />);
  };

  const { execute: executeDeleteBasketGroup, loading } = useDeleteBasketGroup({
    errorRemoveCart,
    successRemoveCart,
  });

  const handleRemoveGroup = async () => {
    executeDeleteBasketGroup({ packageId });
  };

  return (
    <Table className={classes.table}>
      <TableBody>
        <TableRow className={classes.tr}>
          <TableCell className={classes.tdAction}>
            {loading ? (
              <CircularProgress size={16} className={classes.loading} />
            ) : (
              <IconButton
                edge="end"
                aria-label="delete"
                className={classes.removeRow}
                onClick={() => handleRemoveGroup()}
              >
                <CloseIcon />
              </IconButton>
            )}
          </TableCell>
          <TableCell className={classes.tdInfo}>
            <Typography className={classes.subText}>Package</Typography>
            <Typography className={classes.name}>{packageName}</Typography>
            <Typography className={classes.subTextLeft}>
              {/* <FormattedMessage id="cart.quantity" />:{" "}
              {product ? product.quantity : quantity} */}
              Total Files: {items.length}
            </Typography>
            <Typography className={classes.subTextRight}>
              {/* <FormattedMessage id="cart.price" />:{" "}
              <FormattedCurrency value={product ? product.price : price} /> */}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

GroupCartItem.defaultProps = {
  item: [],
};

GroupCartItem.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      packageName: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
};

export default GroupCartItem;

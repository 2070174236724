import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import PageTabPanel from "../../../../common/PageTabPanel";
import ArticleInfoPanel from "./ArticleInfoPanel";
import { prisValidation, artikelInformationValidation } from "./helpers";

const useStyles = makeStyles(theme => ({
  customStyle: {
    "& .MuiAccordionSummary-content p": {
      fontSize: 10,
      textTransform: "uppercase",
      color: theme.palette.component.customPanelColor,
    },
    "& .MuiAccordionSummary-root": {
      backgroundColor: theme.palette.component.customPanelBackground,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
    position: "relative",
  },
  btnWrap: {
    borderTop: "1px solid #ddd",
    paddingTop: 20,
  },
  btnMain: {
    padding: "11px 22px",
    marginLeft: 15,
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  hasError: {
    "& .MuiAccordionSummary-root": {
      backgroundColor: "#ffa7a7",
      opacity: 1,
      boxShadow: "inset 0px 0px 0px 2px #bb1335",
    },
    "& .fa": {
      display: "inline-block !important",
      fontSize: 12,
      marginLeft: 7,
      color: "#b72b2b",
    },
    "& $indicator": {
      backgroundColor: "#bb1335",
    },
  },
  label: {
    fontWeight: 600,
  },
}));

const ProductInfo = ({
  image,
  setProductInfoHasError,
  handleDeleteModalOpen,
  formValues,
  setFormValues,
  selectedProductType,
  vatRates,
  setDraft,
  initialFileNames,
  setInitialFileNames,
  folderId,
  errors,
  handleSave,
  setSelectedProductType,
}) => {
  const classes = useStyles();

  const artikelInformationHasErrors = artikelInformationValidation(errors);
  const prisHasErrors = prisValidation(errors);

  useEffect(() => {
    if (prisHasErrors || artikelInformationHasErrors) {
      setProductInfoHasError(true);
    } else {
      setProductInfoHasError(false);
    }
  }, [prisHasErrors, artikelInformationHasErrors]);

  const handleFormValues = e => {
    const { name, value } = e.target;

    let values = value;

    if (name === "weight" || name === "pageRange") {
      values = parseInt(value, 10) || null;
    }

    setFormValues({ ...formValues, [name]: values });
    setDraft();
  };

  const handleTags = newTags => {
    setFormValues({ ...formValues, tags: newTags });
    setDraft();
  };

  const handleImageMetadata = e => {
    const { name, value, checked } = e.target;

    let newValue = value;

    if (name === "isFreeToUser") {
      newValue = checked;
    }

    setFormValues({
      ...formValues,
      imageMetadata: { ...formValues.imageMetadata, [name]: newValue },
    });
    setDraft();
  };

  const handleMetadataDate = date => {
    setFormValues({
      ...formValues,
      imageMetadata: { ...formValues.imageMetadata, date: date },
    });
    setDraft();
  };

  const handleCheckbox = e => {
    const { checked, name } = e.target;
    setFormValues({ ...formValues, [name]: checked });
    setDraft();
  };

  const renderPanelLabel = (label, hasErrors) => {
    return (
      <>
        <Typography className={classes.label}>{label}</Typography>
        {hasErrors && <Icon className="fa fa-exclamation-circle" />}
      </>
    );
  };

  return (
    <PageTabPanel spacing="3">
      <Grid item xs={12} className={classes.customStyle}>
        <ArticleInfoPanel
          formValues={formValues}
          handleFormValues={handleFormValues}
          setFormValues={setFormValues}
          handleCheckbox={handleCheckbox}
          handleImageMetadata={handleImageMetadata}
          image={image}
          errors={errors}
          setDraft={setDraft}
          initialFileNames={initialFileNames}
          setInitialFileNames={setInitialFileNames}
          selectedProductType={selectedProductType}
          folderId={folderId}
          setSelectedProductType={setSelectedProductType}
          handleTags={handleTags}
          handleMetadataDate={handleMetadataDate}
        />
        <Box align="right" className={classes.btnWrap}>
          <Button variant="contained" className={classes.btnMain}>
            <FormattedMessage id="btn.exportTemplate" />
          </Button>
          <Button variant="contained" className={classes.btnMain}>
            <FormattedMessage id="btn.importTemplate" />
          </Button>
          <Button
            variant="contained"
            className={classes.btnMain}
            onClick={() => handleSave()}
          >
            <FormattedMessage id="btn.save" />
          </Button>
        </Box>
      </Grid>
    </PageTabPanel>
  );
};

ProductInfo.defaultProps = {
  folderId: null,
  formValues: {},
  selectedProductType: "",
  vatRates: [],
  errors: {},
};

ProductInfo.propTypes = {
  formValues: PropTypes.shape({
    imageMetadata: PropTypes.shape({}),
    tags: PropTypes.arrayOf(),
    vateRates: PropTypes.arrayOf(),
    buyPrices: PropTypes.shape({
      manualPrices: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    sellPrices: PropTypes.shape({
      manualPrices: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    resourceId: PropTypes.string,
    orderingType: PropTypes.string,
    bookings: PropTypes.shape({}),
    files: PropTypes.shape({
      displayFileName: PropTypes.string,
      originalFileName: PropTypes.string,
      thumbnailFileName: PropTypes.string,
    }),
    id: PropTypes.string,
  }),
  folderId: PropTypes.number,
  handleDeleteModalOpen: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  initialFileNames: PropTypes.shape(PropTypes.shape({})).isRequired,
  selectedProductType: PropTypes.string,
  setDraft: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  setInitialFileNames: PropTypes.func.isRequired,
  setProductInfoHasError: PropTypes.func.isRequired,
  vatRates: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape({}),
  handleSave: PropTypes.func.isRequired,
};

export default ProductInfo;

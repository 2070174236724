import { Dialog, DialogContent } from "@mui/material";
import { useSelector } from "react-redux";
import ForceMFASetup from "../../components/ForceMFaSetup";

const MfaSetupDialog = ({ open, onMfaSetupComplete, username }) => {  
  return (
    <Dialog
      open={open}
    >
      <DialogContent>
        <ForceMFASetup username={username} onMfaSetupComplete={onMfaSetupComplete} allowAbort={false}/>
      </DialogContent>
    </Dialog>
  )
}

export default MfaSetupDialog;
import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";
import CompanyFieldsFragment from "../../../fragments/CompanyFields";

const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $companyId: Int!
    $networkId: Int!
    $parentCompanyId: Int
    $name: String
  ) {
    companyAdminContext {
      companyNetworks {
        createCompany(
          companyId: $companyId
          networkId: $networkId
          parentCompanyId: $parentCompanyId
          name: $name
        ) {
          errorResult {
            ...ErrorResultFields
          }
          result {
            ...CompanyRecursive
            ...CompanyFields
          }
          success
        }
      }
    }
  }
  fragment CompanyRecursive on Company {
    childCompanies {
      ...CompanyFields
      childCompanies {
        ...CompanyFields
        childCompanies {
          ...CompanyFields
        }
      }
    }
  }

  ${ErrorResultFragment}
  ${CompanyFieldsFragment}
`;

export default CREATE_COMPANY;

import { gql } from '@apollo/client';

const StructureFieldsFragment = gql`
  fragment StructureFields on StructureItem {
    id
    icon
    iconColor
    name
    description
    siteId
    type
    projectId
    issueId
  }
`;

export default StructureFieldsFragment;

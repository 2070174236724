import { gql } from '@apollo/client';

const getMyMaterial = gql`
  query getMyMaterialItems(
    $createdFrom: DateTime
    $createdUntil: DateTime
    $searchProductName: String
    $sortfield: String
    $ascending: Boolean
    $offset: Int!
    $pageSize: Int!
  ) {
    myMaterial(
      createdFrom: $createdFrom
      createdUntil: $createdUntil
      searchProductName: $searchProductName
      sortfield: $sortfield
      ascending: $ascending
      offset: $offset
      pageSize: $pageSize
    ) {
      totalCount
      items {
        changedBy
        changedDate
        description
        downloadedBy
        downloadedDate
        fileExtension
        id
        locked
        name
        productImageUri
        size
        tags {
          tagId
          tagName
        }
        thumbnailImageUri
        type
        uploadedBy
        uploadedDate
        versionNo
      }
    }
  }
`;

export default getMyMaterial;

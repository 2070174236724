import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import AccountSettings from "./AccountSettings";
import AccountSettingsReadOnly from "../../common/AccountSettingsReadOnly";
import { GET_EDIT_ACCOUNT_SETTINGS } from "../../../graphql/queries";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

const AccountSettingsViewSelector = ({  
  accountSettings,
  setAccountSettings,
}) => {
  const classes = useStyles();
  const [isViewedAs, setIsViewedAs] = useState(false);
  
  const [getEditAccountSettings, { data, loading }] = useLazyQuery(
    GET_EDIT_ACCOUNT_SETTINGS
  );

  useEffect(() => {
    getEditAccountSettings();
  }, []);

  useEffect(() => {
    if (
      data &&
      data.userAccountContext &&
      data.userAccountContext.editAccountSettings
    ) {      
      setAccountSettings(data.userAccountContext.editAccountSettings);
    }      
  }, [data]);

  return (
    <div className={classes.root}>
      {loading && (
        <Grid container justifyContent="center">
          <CircularProgress size={30} />
        </Grid>
      )}
      {!loading && (
        <>
          {!isViewedAs && (
            <AccountSettings
              accountSettings={accountSettings}
              setAccountSettings={setAccountSettings}
              setIsViewedAs={setIsViewedAs}
            />
          )}
          {isViewedAs && (
            <AccountSettingsReadOnly
              userDetails={accountSettings}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AccountSettingsViewSelector;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import Message from "./Message";
import ImageModal from "./ImageModal";
import logger from "../../../helpers/logger";
import { useMutation } from "@apollo/client";
import { INIT_ATTACHMENT_DOWNLOAD } from "../../../graphql/mutations/MessageAttachmentContext";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: 500,
    minHeight: 0,
    flexGrow: 1,
    overflowY: "auto",
    overflow: "auto",
    padding: "20px 20px 0 20px",
  },
}));

const MessageArea = ({ currentUserId, disabled, messages, messagesEndRef }) => {
  const classes = useStyles();
  const alert = useAlert();

  const [fileIndex, setFileIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);

  const [initAttachmentDownload] = useMutation(INIT_ATTACHMENT_DOWNLOAD);

  const handleAttachmentDownload = async ({ attachmentId }) => {
    if (!attachmentId) {
      logger.info("No attachment selected for dowload.");
      return;
    }

    const createExceptionMessage = errorResult => {
      return errorResult
        ? `${errorResult.errorCode} : ${errorResult.errorMessage}`
        : "unknown error";
    };

    const getInitAttachmentDownloadResult = res => {
      var { messageAttachmentContext } = res?.data || {};
      return messageAttachmentContext?.initAttachmentDownload || {};
    };

    try {
      const res = await initAttachmentDownload({ variables: { attachmentId } });
      const initResult = getInitAttachmentDownloadResult(res);
      if (!initResult.success) {
        throw new Error(createExceptionMessage(initResult.errorResult));
      }
      window.open(initResult.url);
    } catch (ex) {
      logger.error("Failed to initialize download.", ex);
      alert.error(<FormattedMessage id="messaging.errors.failedToDownload" />);
    }
  };

  // eslint-disable-next-line no-shadow
  const handleImageClick = ({ files, index }) => {
    setFiles(files);
    setFileIndex(index);
    setShowImageModal(true);
  };

  const handleImageModalClose = () => {
    setShowImageModal(false);
  };

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "auto",
        block: "end",
        inline: "nearest",
      });
    }
  };

  useEffect(scrollToBottom, [messages]);

  if (disabled) {
    return <Box className={classes.root} />;
  }

  return (
    <div className={classes.root}>
      {messages.map(x => {
        const myMessage = x.authorUserId === currentUserId;
        return (
          <Message
            key={x.id}
            message={x}
            myMessage={myMessage}
            onDownload={handleAttachmentDownload}
            onImageClick={handleImageClick}
          />
        );
      })}
      <div ref={messagesEndRef} />
      <ImageModal
        files={files}
        initialIndex={fileIndex}
        onClose={handleImageModalClose}
        onDownload={handleAttachmentDownload}
        open={showImageModal}
      />
    </div>
  );
};

MessageArea.defaultProps = {
  disabled: false,
  messagesEndRef: null,
};

MessageArea.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  messagesEndRef: PropTypes.shape({
    current: PropTypes.shape({
      scrollIntoView: PropTypes.func,
    }),
  }),
};

export default MessageArea;

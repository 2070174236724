import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";

const RenderAddToCart = ({
  canAddToBasket,
  onClick,
  locked,
  handleDisabled,
}) => {
  const disabled = !canAddToBasket;
  return (
    <ContextMenuItem
      disabled={disabled}
      onClick={() => (disabled ? handleDisabled() : onClick())}
    >
      <FormattedMessage id="context.addCart" />
    </ContextMenuItem>
  );
};

RenderAddToCart.propTypes = {
  onClick: PropTypes.func.isRequired,
  canAddToBasket: PropTypes.bool.isRequired,
};

export default RenderAddToCart;

import { FormattedMessage } from 'react-intl';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';

const InviteOptions = ({ defaultValue, onChange }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    onChange?.({ value });
  };

  return (
    <Box sx={{ minWidth: 120, paddingLeft: "6px" }}>
      <FormControl variant="standard">
        <Select
          defaultValue={defaultValue}
          onChange={handleChange}
        >
          <MenuItem value="read"><FormattedMessage id="shares.anyoneWithTheLink.canView" /></MenuItem>
          <MenuItem value="write"><FormattedMessage id="shares.anyoneWithTheLink.canEdit" /></MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default InviteOptions;

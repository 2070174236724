import React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import MySharesTableItem from "./MySharesTableItem";
import MySharesTableHead from "./MySharesTableHead";
import LoadingScreen from "../common/LoadingScreen";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
}));

const MySharesTable = ({
  items,
  loading,
  disabled,
  withFromColumn,
  withRecipientsColumn,
  onDeleteItem,
  onOpenItem,
}) => {
  const classes = useStyles();

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <Table size="small">
        <MySharesTableHead
          withFromColumn={withFromColumn}
          withRecipientsColumn={withRecipientsColumn}
          disabled={disabled}
        />
        <TableBody>
          {items &&
            items.map(item => {
              return (
                <MySharesTableItem
                  key={item.shareId}
                  item={item}
                  disabled={disabled}
                  withFromColumn={withFromColumn}
                  withRecipientsColumn={withRecipientsColumn}
                  onDeleteItem={onDeleteItem}
                  onOpenItem={onOpenItem}
                />
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

MySharesTable.defaultProps = {
  items: [],
  withFromColumn: false,
  withRecipientsColumn: false,
};

MySharesTable.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onOpenItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      shareId: PropTypes.number.isRequired,
      shareDate: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
      }).isRequired,
      recipients: PropTypes.arrayOf(
        PropTypes.shape({
          username: PropTypes.string.isRequired,
          firstname: PropTypes.string.isRequired,
          lastname: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ),
  loading: PropTypes.bool.isRequired,
  withFromColumn: PropTypes.bool,
  withRecipientsColumn: PropTypes.bool,
};

export default MySharesTable;

import React from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, Icon } from "@mui/material";
import { TextField } from "../../common";
import { ADDRESS_FIELD_TYPE } from "../../../constant";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiInputBase-input": {
      padding: "7px 12px 8px 12px !important",
    },
    "& label": {
      marginTop: -3,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      marginTop: 0,
    },
  },
  blueIcon: {
    fontSize: 16,
    width: 20,
    height: 20,
    color: "#6865ae",
  },
}));

const FieldsForm = ({
  name,
  addressFields,
  addressForm,
  handleChange,
  hideAdornments = false,
  isEditable = true,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const renderBlueIcon = (fieldName, placeholder) => {
    if (!addressForm[fieldName] && placeholder) {
      return <Icon className={clsx(["fas fa-link", classes.blueIcon])} />;
    }
  };

  const renderIcon = (fieldName, placeholder) => {
    return <Box>{renderBlueIcon(fieldName, placeholder)}</Box>;
  };

  const renderDefaultLabel = fieldName => {
    return intl.formatMessage({ id: `addresses.defaultLabel.${fieldName}` });
  };

  return (
    addressFields &&
    addressFields.map(field => {
      if (field.type === ADDRESS_FIELD_TYPE.Hidden) {
        return null;
      }

      const defaultLabel = renderDefaultLabel(field.name);

      return (
        <div key={`${field.name}`}>
          <TextField
            shrink={true}
            disabled={field.type === ADDRESS_FIELD_TYPE.Fixed || !isEditable}
            placeholder={field.placeholder}
            customClass={classes.input}
            label={field.label || defaultLabel}
            name={field.name}
            value={addressForm[field.name] || ""}
            onChange={e => handleChange(name, e)}
            endAdornment={
              !hideAdornments && renderIcon(field.name, field.placeholder)
            }
          />
        </div>
      );
    })
  );
};

export default FieldsForm;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import { useAddToShoppingCart, useSnackbar } from "../../hooks";
import { saveAs } from "file-saver";
import {
  INIT_DYNAMIC_TEMPLATE_PREVIEW_DOWNLOAD,
} from "../../graphql/mutations";
import {
  getSelectedSiteOrderGroupId,
  getSiteId,
} from "../../helpers/selectors";
import { cartInitialize } from "../../actions";
import TabPanelContainer from "./DynamicFieldsComponents";
import ConfirmationModal from "./ProductModal/ConfirmationModal";
import AppBarTabs from "./DynamicFieldsComponents/AppBarTabs";
import TabPanelInfo from "./DynamicFieldsComponents/TabPanelInfo";
import TabPanelOrder from "./DynamicFieldsComponents/TabPanelOrder";

const DynamicFields = ({
  closeModal,
  editMode,
  handleUpdateDynamicProductFields,
  product,
  productIndex,
  quantity,
  setQuantity,
  templates,
  isCheckout,
  customMetadata,
}) => {
  const alert = useAlert();
  const snackbar = useSnackbar();
  const siteId = useSelector(state => getSiteId(state));
  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));
  const [tab, setTab] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState({});
  const [hasError, setHasError] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [addToShoppingCart] = useAddToShoppingCart();

  const handleOpenConfirmationModal = () => {
    setConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const { quantities, id } = product;
  const { selectionType, valuesList } = quantities;

  const onUpdateButton = () => {
    const dynamicFields = templates.fields.map(field => ({
      fieldName: field.fieldName,
      value: formValues[field.fieldName] || "",
    }));
    handleUpdateDynamicProductFields({
      newDynamicValues: dynamicFields,
    });
    handleCloseConfirmationModal();
  };

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleBtnOrder = async () => {
    if (!(templates && templates.fields)) {
      console.log("Error in Dynamic Fields. No values.");
      return;
    }

    const dynamicFieldValues = templates.fields.map(field => ({
      fieldName: field.fieldName,
      value: formValues[field.fieldName] || "",
    }));

    const item = {
      productId: product.id,
      name: product.name,
      quantity,
      dynamicFieldValues,
      propertyProductData: null,
      siteId,
      source: "PRODUCT_VIEW",
    };

    addToShoppingCart({
      input: {
        item,
        orderGroupId,
      },
      onError: () => {
        snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
      },
      onSuccess: () => {
        alert.success(<FormattedMessage id="product.addedToCart" />);
        closeModal();
      },
    });
  };

  const updateFieldValidator = (fieldName, error) => {
    setError(prevState => ({
      ...prevState,
      [fieldName]: {
        error,
      },
    }));
  };

  const requestPreview = values => {
    let count = 0;
    let fieldError;
    let result;
    templates.fields.map(field => {
      if (field.required) {
        result = _.get(values, field.fieldName);
        if (!result && !(field.fieldType === "IMAGE" && result === "")) {
          count += 1;
          fieldError = true;
        } else {
          fieldError = false;
        }
        updateFieldValidator(field.fieldName, fieldError);
      }
    });
    if (count === 0) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (editMode && product && product.dynamicFieldValues) {
      const { dynamicFieldValues } = product;
      const storedForm = {};
      dynamicFieldValues.forEach(item => {
        storedForm[item.fieldName] = item.value;
      });
      setFormValues(storedForm);
    }
  }, [editMode]);

  useEffect(() => {
    if (!_.isEmpty(formValues)) {
      requestPreview(formValues);
    }
  }, [formValues]);

  const handleChange = event => {
    event.persist();
    const key = event.target.name;
    const val = event.target.value;
    setFormValues({ ...formValues, [key]: val });
  };

  const quantityChange = event => {
    setQuantity(Number(event.target.value));
  };

  const handleSetDefaultValues = () => {
    let defaultValues = {};

    templates.fields.forEach(field => {
      const { fieldName, fieldValue } = field;

      defaultValues = { ...defaultValues, [fieldName]: fieldValue };
    });

    setFormValues(defaultValues);
  };

  useEffect(() => {
    if (!editMode) {
      handleSetDefaultValues();
    }
  }, [templates]);

  const [initDynamicTemplatePreviewDownload] = useMutation(
    INIT_DYNAMIC_TEMPLATE_PREVIEW_DOWNLOAD
  );

  const handleDownloadPdf = async () => {
    const formData = Object.keys(formValues).map(key => {
      return { fieldName: key, value: formValues[key] };
    });

    try {
      const res = await initDynamicTemplatePreviewDownload({
        variables: {
          productId: product.id,
          data: formData,
        },
      });

      if (res && res.data && res.data.initDynamicTemplatePreviewDownload) {
        saveAs(res.data.initDynamicTemplatePreviewDownload);
      } else {
        console.log("Error downloading");
      }
    } catch (err) {
      console.log("Error downloading ", err);
    }
  };

  return (
    <TabPanelContainer product={product} formValues={formValues}>
      <AppBarTabs tab={tab} handleTab={handleTab} editMode={editMode} />
      <TabPanelInfo
        editMode={editMode}
        error={error}
        formValues={formValues}
        handleChange={handleChange}
        handleTab={handleTab}
        hasError={hasError}
        onUpdateButton={onUpdateButton}
        product={product}
        quantity={quantity}
        tab={tab}
        templates={templates}
        setFormValues={setFormValues}
        handleDownloadPdf={handleDownloadPdf}
      />
      <TabPanelOrder
        customMetadata={customMetadata}
        editMode={editMode}
        handleBtnOrder={handleBtnOrder}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        hasError={hasError}
        product={product}
        quantity={quantity}
        quantityChange={quantityChange}
        selectionType={selectionType}
        tab={tab}
        valuesList={valuesList}
        handleDownloadPdf={handleDownloadPdf}
        isCheckout={isCheckout}
      />
      {confirmationModal && (
        <ConfirmationModal
          handleBtnOrder={editMode ? onUpdateButton : handleBtnOrder}
          handleClose={handleCloseConfirmationModal}
          open={confirmationModal}
        />
      )}
    </TabPanelContainer>
  );
};

DynamicFields.defaultProps = {
  editMode: false,
  productIndex: null,
};

DynamicFields.propTypes = {
  cartAddItem: PropTypes.func.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.number,
      dynamicFieldValues: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    })
  ).isRequired,
  closeModal: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  handleUpdateDynamicProductFields: PropTypes.func.isRequired,
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    dynamicFieldValues: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
    thumbnailImageUri: PropTypes.string,
  }).isRequired,
  templates: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
  productIndex: PropTypes.number,
  setQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default DynamicFields;

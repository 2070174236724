import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($update: ProductEditInput) {
    updateProduct(update: $update) {
      success
      errorResult {
        ...ErrorResultFields
      }
      productEdit {
        actionValue
        active
        activeFromDate
        activeUntilDate
        allowDownload
        allowFreeQuantity
        articleNo
        bookings {
          allowFreeBooking
          bookingDays
        }
        buyPrices {
          initialCosts
          manualPrices {
            price
            quantity
          }
        }
        description
        detailedDescription
        extraFieldColors
        extraFieldNotes
        format
        imageMetadata {
          contact
          date
          imageNo
          isFreeToUser
          legalDocument
          location
          model
          occupation
          photographer
        }
        material
        name
        orderingType
        pageRange
        resourceId
        sellPrices {
          initialCosts
          manualPrices {
            price
            quantity
          }
        }
        showFormat
        showPrice
        tags {
          tagId
          tagName
        }
        unit
        vateRates {
          name
          selected
          vatRateId
        }
        weight
      }
    }
  }

  ${ErrorResultFragment}
`;

export default UPDATE_PRODUCT;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const PICK_ORDER_ROW = gql`
  mutation PickOrderRow(
    $pickingListOrdersItemId: ID!
    $orderHistoryRowItemId: ID!
    $picked: Boolean!
    $signature: String
  ) {
    pickingListContext {
      pickOrderRow(
        pickingListOrdersItemId: $pickingListOrdersItemId
        orderHistoryRowItemId: $orderHistoryRowItemId
        picked: $picked
        signature: $signature
      ) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }

  ${ErrorResultFragment}
`;

export default PICK_ORDER_ROW;

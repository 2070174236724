import { withStyles } from "@mui/styles";
import { Box } from "@mui/material";

const Spacer = withStyles({
  root: {
    marginTop: 10,
  },
})(Box);

export default Spacer;

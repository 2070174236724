import { handleActions } from "redux-actions";
import {
  setAbout,
  setEditAccountSettings,
  setPrivacy,
  setWeblinks,
  setWeblinkVisibility,
  removeSetting,
  updateWeblinks,
  setSkills,
  setDeliveryAddress,
  setOrderAddress,
  setInvoiceAddress,
} from "../../../../actions";

const defaultState = {
  accountSettings: {},
};

export default handleActions(
  {
    [setEditAccountSettings]: (state, { payload }) => {
      const { data } = payload;
      return {
        ...state,
        accountSettings: data,
      };
    },
    [setAbout]: (state, { payload }) => {
      const { key, value } = payload;
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          [key]: value,
        },
      };
    },
    [removeSetting]: (state, { payload }) => {
      const { context, key } = payload;
      const accountSettings = { ...state.accountSettings };

      let weblinks;

      if (key === "weblinks") {
        weblinks = accountSettings.webLinks.filter(
          (_, index) => index !== context
        );
        accountSettings.webLinks = weblinks;
      } else {
        accountSettings[key] = "";
      }

      return {
        ...state,
        accountSettings,
      };
    },
    [setPrivacy]: (state, { payload }) => {
      const { key, value } = payload;
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          visibilitySettings: {
            ...state.accountSettings.visibilitySettings,
            [key]: value,
          },
        },
      };
    },
    [setWeblinkVisibility]: (state, { payload }) => {
      const { key, value } = payload;
      const webLinks = [...state.accountSettings.webLinks];
      const webLink = webLinks[key];
      webLink.visibility = value;
      webLinks[key] = webLink;

      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          webLinks,
        },
      };
    },
    [setWeblinks]: (state, { payload }) => {
      const { url } = payload;
      const newLink = { url, visibility: "PUBLIC" };
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          webLinks: [...state.accountSettings.webLinks, newLink],
        },
      };
    },
    [updateWeblinks]: (state, { payload }) => {
      const { index, value } = payload;
      const webLinks = [...state.accountSettings.webLinks];
      const webLink = webLinks[index];
      webLink.url = value;
      webLinks[index] = webLink;
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          webLinks,
        },
      };
    },
    [setSkills]: (state, { payload }) => {
      const skillTags = payload.value;
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          skillTags,
        },
      };
    },
    [setDeliveryAddress]: (state, { payload }) => {
      const { name, value } = payload;
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          deliveryAddress: {
            ...state.accountSettings.deliveryAddress,
            [name]: value,
          },
        },
      };
    },
    [setOrderAddress]: (state, { payload }) => {
      const { name, value } = payload;
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          ordererAddress: {
            ...state.accountSettings.ordererAddress,
            [name]: value,
          },
        },
      };
    },
    [setInvoiceAddress]: (state, { payload }) => {
      const { name, value } = payload;
      return {
        ...state,
        accountSettings: {
          ...state.accountSettings,
          invoiceAddress: {
            ...state.accountSettings.invoiceAddress,
            [name]: value,
          },
        },
      };
    },
  },
  defaultState
);

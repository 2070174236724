const getChildFoldersTreeData = ({ folder }) => {
  let folderTree = {};
  const childIds =
    folder && folder.childFolders
      ? folder.childFolders.map(child => child.folderId)
      : [];

  let childFoldersTreeData = {};
  if (folder && folder.childFolders) {
    folder.childFolders.forEach(childFolder => {
      const childFolderTreeData = getChildFoldersTreeData({
        folder: childFolder,
      });
      childFoldersTreeData = {
        ...childFoldersTreeData,
        ...childFolderTreeData,
      };
    });
  }

  folderTree = {
    ...folderTree,
    [`f_${folder.folderId}`]: {
      ...folder,
      isRoot: false,
      childFolderIds: childIds,
    },
    ...childFoldersTreeData,
  };

  return folderTree;
};

export default getChildFoldersTreeData;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../common/TextField";
import ButtonText from "../common/ButtonText";

const useStyles = makeStyles(theme => ({
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  colSearch: {
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingTop: 7,
      paddingBottom: 7,
    },
    maxWidth: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  btnColClear: {
    color: theme.palette.primary.main,
    fontSize: 10,
    marginTop: 5,
    marginRight: 8,
  },
  btnColSearch: {
    padding: "5px 15px",
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: 9,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    width: "auto",
    marginTop: 5,
  },
}));

const ColumnSearch = () => {
  const classes = useStyles();

  return (
    <Box className={classes.colSearch} align="right">
      <CustomTextField placeholder="Sök" />
      <Box>
        <ButtonText
          label={<FormattedMessage id="btn.clear" />}
          btnClass={classes.btnColClear}
        />
        <Button className={classes.btnColSearch}>
          <FormattedMessage id="btn.search" />
        </Button>
      </Box>
    </Box>
  );
};

export default ColumnSearch;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($details: WorkspaceDetailsInput!) {
    companyAdminContext {
      companyWorkspaces {
        updateWorkspace(details: $details) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_WORKSPACE;

import React, { useState, useEffect } from 'react';
import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditorContainer from '../EditorContainer';
import DurationEditor from './DurationEditor';
import DateEditor from '../DateEditor';

const textInputProps = {
  disableUnderline: true,
  endAdornment: null,
  sx: {
    '& .MuiInputBase-input': {
      textAlign: 'right',
      paddingRight: '4px',
      fontSize: "13px",
    },
    '&:hover .MuiInputBase-input': {
      backgroundColor: '#f0f0f0',
      borderRadius: "4px",
      cursor: "default",
    },
  },
};

const getInitials = (firstname, lastname) => {
  const getFirstLetter = (str) => str ? str[0].toUpperCase() : '';

  const firstInitial = getFirstLetter(firstname);
  const lastInitial = getFirstLetter(lastname);

  return (firstInitial + lastInitial).slice(0, 2);
};

const TimeDateAvatarRows = ({ enableEdit, task, data, onDelete, onUpdate }) => {
  const [hoveredRowId, setHoveredRowId] = React.useState(null);

  const handleMouseEnter = (id) => {
    setHoveredRowId(id);
  };

  const handleMouseLeave = () => {
    setHoveredRowId(null);
  };

  const handleDelete = (id) => {
    onDelete(id);
  };

  const handleUpdateTime = (task, reportItem, time, date) => {
    onUpdate(task, reportItem, time || formatDuration(reportItem.reportedMinutes), date || reportItem.reportedAt);
  }

  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  return (
    <Box
      sx={{
        height: '300px',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: "0px 0px 4px 4px",
        padding: "8px 16px 16px 16px",
        width: 265,
      }}
    >

      {data && data.sort((a, b) => new Date(b.reportedAt) - new Date(a.reportedAt)).map((item) => (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: "4px",
            paddingBottom: "4px",
            marginTop: "0px",
            paddingTop: "0px",
            position: 'relative',
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          }}
          //onMouseEnter={() => handleMouseEnter(item.id)}
          onMouseLeave={handleMouseLeave}
        >
          <EditorContainer
            //fullWidth
            component={DurationEditor}
            enableEdit={enableEdit}
            value={formatDuration(item.reportedMinutes)}
            onChange={time => handleUpdateTime(task, item, time)}
            style={{ cursor: "text", }}
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              border: "1px solid rgba(0, 0, 0, 0)",
              borderRadius: "4px",
              fontSize: "13px",
              fontWeight: 400,
              minHeight: "1.5em",
              lineHeight: "22px",
              textAlign: 'left',
              width: "60px",
              "&.MuiBox-root": { minHeight: "1.5em", lineHeight: "22px", padding: "0px 4px 0px 4px", border: "1px solid rgba(0, 0, 0, 0)", },
              "&.MuiBox-root:hover": { minHeight: "1.5em", lineHeight: "22px", padding: "0px 4px 0px 4px", border: "1px solid rgba(0, 0, 0, 0)", backgroundColor: '#f0f0f0', cursor: "text", },
              "&.MuiBox-root.editingClass": { minHeight: "1.5em", padding: "0px 4px 0px 4px", border: "1px solid rgba(0, 0, 0, 0.5)", },
              "&.MuiTypography-root": { minHeight: "1.5em", lineHeight: "22px", whiteSpace: "pre-wrap", padding: "0px", border: "1px solid rgba(0, 0, 0, 0)", },
              "&.MuiInputBase-fullWidth": { minHeight: "1.5em", lineHeight: "22px", padding: "2px 0px 2px 0px", border: "0px", },
              "&.MuiInputBase-root": { minHeight: "1.5em", lineHeight: "22px", padding: "2px 0px 2px 1px", border: "0px", },
              "& .MuiInputBase-input": { minHeight: "1.5em", lineHeight: "22px", padding: "0px", border: "0px", },
              //"&:hover": { backgroundColor: '#f0f0f0', cursor: "text", },
              //"&:focus": {border: "1px solid rgba(0, 0, 0, 0.5)", cursor: "text",},
            }}
          />
          <Typography variant="body2" sx={{ flex: 1, textAlign: 'right', justifyConent: "flex-end", minWidth: 121, maxWidth: 126, marginRight: "0px", paddingRight: "4px", }}>
            <DateEditor value={item.reportedAt} enableEdit={enableEdit} textInputProps={textInputProps} views={['month', 'day']} onChange={date => handleUpdateTime(task, item, undefined, date)} />
          </Typography>
          <Tooltip title={item.user.username || ''} arrow placement="top">
            <Avatar
              src={item.user.avatar}
              alt={item.user.username}
              sx={{ width: 24, height: 24, mr: "2px", bgcolor: green[100], fontSize: "11px", color: "rgba(0, 0, 0, 0.87)" }}
            >
              {getInitials(item.user.firstname, item.user.lastname)}
            </Avatar>
          </Tooltip>
          <Typography variant="body2" sx={{ flex: 1, textAlign: 'right', mr: 0, width: "24px", padding: 0, margin: 0 }}>
            <IconButton
              size="small"
              sx={{
                width: 24,
                height: 24,
                mr: 0,
                opacity: 0.26,
                '&:hover': {
                  opacity: 0.87,
                },
              }}
              onClick={() => handleDelete(item.id)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default TimeDateAvatarRows;
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useAddToShoppingCart, useSnackbar } from "../../../../hooks";
import { saveAs } from "file-saver";
import { PrimaryButton } from "../../../common";
import ProductTags from "../../ProductTags";
import RenderProductPrice from "../RenderProductPrice";
import { STOCK_PRODUCT } from "../../../../constant/types";
import {
  INITIALIZE_PRODUCT_DOWNLOAD,
} from "../../../../graphql/mutations";
import {
  getSelectedSiteOrderGroupId,
  getSiteId,
} from "../../../../helpers/selectors";
import MetaDataFields from "../MetaDataFields";
import ProductImagePreview from "../ProductImagePreview";
import ProductName from "../ProductName";
import ProductDescription from "../ProductDescription";
import ProductArtNo from "../ProductArtNo";
import ProductFormat from "../ProductFormat";
import ProductQuantity from "../ProductQuantity";
import ProductOutOfStock from "../../ProductOutOfStock";
import ProductOrderButton from "../ProductOrderButton";
import ProductStatistics from "../ProductStatistics";
import ProductPageRange from "../ProductPageRange";
import ProductUnit from "../ProductUnit";
import ProductWeight from "../ProductWeight";
import ProductMaterial from "../ProductMaterial";
import CustomMetadata from "../CustomMetadata";
import ProductResponsible from "../ProductResponsible";
import VatPercentage from "../VatPercentage";
import Price from "../Price";

import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import Tooltip from '@mui/material/Tooltip';


const useStyles = makeStyles(theme => ({
  equalHeight: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  imagePreviewContainer: {
    alignItems: "center",
    backgroundColor: "#F4F3EF",
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    position: "relative",
    textAlign: "center",
    "& .react-transform-component": {
      margin: "0 auto",
      width: "initial",
      height: "85vh",
    },
    "& .react-transform-component:hover": {
      cursor: "grabbing",
    },
    "& .react-transform-element": {
      margin: "0 auto",
      width: "100%",
      height: "85vh",
    },
  },
  imagePreview: {
    maxHeight: "85vh",
    width: "100%",
    objectFit: "contain",
  },
  dynamicWrap: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    backgroundColor: theme.palette.common.white,
  },
  formWrap: {
    padding: 10,
    height: "100%",
    overflow: "auto",
    display: "flex",
    paddingBottom: 70,
    flexDirection: "column",
    minHeight: "80vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      paddingBottom: 20,
      minHeight: "unset",
    },
  },
  dynamicFooter: {
    padding: 10,
    backgroundColor: "#f9f9f9",
    position: "absolute",
    bottom: 0,
    width: "95%",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      position: "unset",
    },
    "& button": {
      marginLeft: 2,
    },
  },
  btn: {
    padding: "5px 10px",
    fontWeight: 600,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
    marginLeft: 5,
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  tagTitle: {
    margin: "20 0 0 0",
    fontSize: 10,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 5,
    background: "#fff",
    position: "absolute",
    top: -15,
    width: 50,
    left: 10,
  },
  totalWrap: {
    marginTop: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  tagsContainer: {
    position: "relative",
    marginTop: 20,
  },
}));

const NonDynamicProduct = ({
  cartItems,
  editMode,
  handleClose,
  product,
  quantity,
  setQuantity,
  isImage,
  handleAddImageBasket,
  customMetadata,
  fromFileView,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const [imageIndex, setImageIndex] = useState(1);
  const [initializeDownload] = useMutation(INITIALIZE_PRODUCT_DOWNLOAD);
  const siteId = useSelector(state => getSiteId(state));
  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));
  const [addToShoppingCart] = useAddToShoppingCart();

  const {
    imageMetadata,
    minimumQuantity,
    quantities,
    stock,
    productType,
    tags,
    statistics,
    userPermissions,
    vatPercentage,
    productDisplaySettings,
    productResponsible,
    pricesInclusiveFreight,
    consented
  } = product || {};

  useEffect(() => {
    if (!quantity && product) {
      var { minimumQuantity } = product;
      setQuantity(minimumQuantity);
    }
  }, [product, quantity]);

  const { showFormat, showNumPages, showPrice, showStockBalance } =
    productDisplaySettings || {};

  const display = {
    format: showFormat && product && product.format,
    numPages: showNumPages && (product?.numPages || "0") !== "0",
    price: showPrice,
    vatPercentage: !isImage && showPrice && vatPercentage,
    weight: (product?.weight || 0) !== 0,
  };

  const download = userPermissions?.download || fromFileView;
  const order = userPermissions?.order || false;

  const { downloadCount, shareCount, viewCount } = statistics || {};
  const { stockBalance, temporaryOutOfStock } = stock || {};
  const { selectionType, valuesList } = quantities || {};

  // eslint-disable-next-line radix
  const maxPage = parseInt(product.numPages) ? parseInt(product.numPages) : 1;

  const imageUri =
    product.productImageUri && product.productImageUri.indexOf("?") > -1
      ? `${product.productImageUri}&pageno=${imageIndex}`
      : `${product.productImageUri}?pageno=${imageIndex}`;

  const handleNext = () => {
    setImageIndex(prevIndex => prevIndex + 1);
  };

  const handlePrevious = () => {
    setImageIndex(prevIndex => prevIndex - 1);
  };

  const selectAll = event => {
    if (event && event.target && event.target.value) {
      event.target.select();
    }
  };

  const handleDownloadOnClick = async () => {
    try {
      const results = await initializeDownload({
        variables: {
          productId: product.id,
        },
      });
      if (results && results.data && results.data.initProductDownload) {
        const downloadUrl = results.data.initProductDownload;
        saveAs(downloadUrl);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading product ${e}`);
    }
  };

  const quantityChange = event => {
    const newQuantity = Number(event.target.value);
    const isValidQuantity =
      Number.isSafeInteger(newQuantity) && newQuantity >= 0;
    setQuantity(isValidQuantity ? newQuantity : 0);
  };

  const handleBtnOrder = async () => {
    const item = {
      productId: product.id,
      name: product.name,
      quantity,
      dynamicFieldValues: null,
      propertyProductData: null,
      siteId,
      source: "PRODUCT_VIEW",
    };

    addToShoppingCart({
      input: {
        item,
        orderGroupId,
      },
      onError: () => {
        snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
      },
      onSuccess: () => {
        alert.success(<FormattedMessage id="product.addedToCart" />);
        handleClose();
      },
    });
  };

  const IconConsented = () => {
    let icon = null;
    let tooltipText = '';

    switch (consented) {
      case "NOT_SENT":
        return null;
      case "SENT":
        icon = <GppMaybeOutlinedIcon sx={{ color: "lightgray" }} />;
        tooltipText = "Begäran om samtycke skickad";
        break;
      case "DENIED":
        icon = <GppBadOutlinedIcon sx={{ color: "red" }} />;
        tooltipText = "Samtycke avslaget";
        break;
      case "CONSENTED":
        icon = <GppGoodOutlinedIcon sx={{ color: "green" }} />;
        tooltipText = "Samtycke finns";
        break;
      default:
        return null;
    }

    return (
      <Tooltip title={tooltipText}>
        {icon}
      </Tooltip>
    );
  }

  const description =
    product && product.detailedDescription
      ? product.detailedDescription
      : product.description;

  return (
    <div className={classes.modalBody}>
      <Grid container spacing={0} className={classes.equalHeight}>
        <Grid item xs={12} md={3}>
          <div className={classes.dynamicWrap}>
            <div className={classes.formWrap}>
              <ProductName name={<><IconConsented /> {product.name}</>} />
              <ProductDescription description={description} />

              {product && product.articleNo && (
                <ProductArtNo articleNo={product.articleNo} />
              )}

              {display.format && <ProductFormat format={product.format} />}

              {display.weight && <ProductWeight weight={product.weight} />}

              {product && product.material && (
                <ProductMaterial material={product.material} />
              )}

              {product && product.unit && <ProductUnit unit={product.unit} />}

              {display.numPages && (
                <ProductPageRange numPages={product.numPages} />
              )}

              {Array.isArray(customMetadata) && customMetadata.length > 0 && (
                <>
                  {customMetadata.map(metadata => {
                    if (metadata.display) {
                      return <CustomMetadata metadata={metadata} />;
                    }
                  })}
                </>
              )}

              {productResponsible && (
                <ProductResponsible productResponsible={productResponsible} />
              )}

              {display.vatPercentage && (
                <VatPercentage vatPercentage={vatPercentage} />
              )}

              {pricesInclusiveFreight && (
                <Price price={pricesInclusiveFreight} />
              )}

              {imageMetadata && <MetaDataFields metaData={imageMetadata} />}

              {statistics && (
                <ProductStatistics
                  downloads={downloadCount}
                  shares={shareCount}
                  views={viewCount}
                />
              )}

              {Array.isArray(tags) && tags.length > 0 && (
                <Box className={classes.tagsContainer}>
                  <p className={classes.tagTitle}>
                    <FormattedMessage id="product.tagging" />
                  </p>
                  <ProductTags tags={tags} />
                </Box>
              )}

              {!editMode && (
                <>
                  {order && (
                    <Grid container spacing={1} className={classes.totalWrap}>
                      <ProductQuantity
                        valuesList={valuesList}
                        selectionType={selectionType}
                        quantity={quantity}
                        onChange={quantityChange}
                        onClick={selectAll}
                        defaultValue={minimumQuantity}
                      />
                      {display.price && (
                        <RenderProductPrice
                          product={product}
                          quantity={quantity}
                        />
                      )}
                    </Grid>
                  )}
                </>
              )}
            </div>
            <div className={classes.dynamicFooter}>
              {download && (
                <PrimaryButton onClick={handleDownloadOnClick}>
                  <FormattedMessage id="btn.download" />
                </PrimaryButton>
              )}

              {isImage && (
                <PrimaryButton onClick={handleAddImageBasket}>
                  <FormattedMessage id="product.addImage" />
                </PrimaryButton>
              )}

              {!editMode &&
                ((!stockBalance || stockBalance === 0 || temporaryOutOfStock) &&
                productType === STOCK_PRODUCT ? (
                  <ProductOutOfStock />
                ) : (
                  <>
                    {order && (
                      <ProductOrderButton onClick={() => handleBtnOrder()} />
                    )}
                  </>
                ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={9} className={classes.imagePreviewContainer}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <ProductImagePreview
                imageIndex={imageIndex}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                imageUri={imageUri}
                alt={product.name}
                product={product}
                tags={tags}
                handleDownloadOnClick={handleDownloadOnClick}
                minPage={1}
                maxPage={maxPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

NonDynamicProduct.defaultProps = {
  editMode: false,
  customMetadata: null,
};

NonDynamicProduct.propTypes = {
  product: PropTypes.shape({
    articleNo: PropTypes.string.isRequired,
    imageMetadata: PropTypes.shape({
      contact: PropTypes.string,
      date: PropTypes.string,
      model: PropTypes.string,
      location: PropTypes.string,
      occupation: PropTypes.string,
      photographer: PropTypes.string,
    }),
    unit: PropTypes.string,
    weight: PropTypes.number,
    material: PropTypes.string,
    numPages: PropTypes.string.isRequired,
    detailedDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productImageUri: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    statistics: PropTypes.shape({
      downloadCount: PropTypes.number,
      shareCount: PropTypes.number,
      viewCount: PropTypes.number,
    }),
    stock: PropTypes.shape({
      stockBalance: PropTypes.number.isRequired,
      temporaryOutOfStock: PropTypes.bool.isRequired,
    }),
    productDisplaySettings: PropTypes.shape({
      showFormat: PropTypes.bool,
      showNumPages: PropTypes.bool,
      showPrice: PropTypes.bool,
      showStockBalance: PropTypes.bool,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        tagId: PropTypes.number.isRequired,
        tagName: PropTypes.string.isRequired,
      })
    ).isRequired,
    userPermissions: PropTypes.shape({
      order: PropTypes.bool,
      download: PropTypes.bool,
    }),
    thumbnailImageUri: PropTypes.string,
  }).isRequired,
  editMode: PropTypes.bool,
  setQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.number,
      dynamicFieldValues: PropTypes.arrayOf(
        PropTypes.shape({
          fieldName: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      ),
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  isImage: PropTypes.bool.isRequired,
  handleAddImageBasket: PropTypes.func.isRequired,
  customMetadata: PropTypes.arrayOf(PropTypes.shape({})),
};

export default NonDynamicProduct;

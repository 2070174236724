import React from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import { Box, Typography, Button } from "@mui/material";

const useStyles = makeStyles(theme => ({
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
  },
  btn: {
    padding: "8px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 0,
    },
  },
  btnShow: {
    padding: "8px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  btnWrap: {
    marginLeft: "auto",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      textAlign: "center",
      marginTop: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const PickingListHeader = ({ setColumnModal, fetchPickingListOrders }) => {
  const classes = useStyles();
  return (
    <Box className={classes.paperHead}>
      <Typography variant="h1" component="h2" className={classes.title}>
        <FormattedMessage id="context.pickingList" />
      </Typography>
      <Button
        variant="contained"
        className={classes.btn}
        onClick={fetchPickingListOrders}
      >
        <FormattedMessage id="btn.update" />
        <Icon className={clsx(["fa fa-sync", classes.btnIcon])} />
      </Button>
      <Box className={classes.btnWrap}>
        <Button
          variant="contained"
          className={classes.btnShow}
          onClick={() => setColumnModal(true)}
        >
          <FormattedMessage id="btn.showCol" />
          <Icon className={clsx(["fa fa-eye", classes.btnIcon])} />
        </Button>
      </Box>
    </Box>
  );
};

PickingListHeader.propTypes = {
  setColumnModal: PropTypes.func.isRequired,
  fetchPickingListOrders: PropTypes.func.isRequired,
};

export default PickingListHeader;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles(theme => ({
  tagWrap: {
    padding: 10,
    borderRadius: 4,
    border: "1px solid #e8e8e8",
    backgroundColor: "#fff",
    textAlign: "left",
  },
  tag: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 10,
    textTransform: "uppercase",
    color: "#fff",
  },
  chipRoot: {
    backgroundColor: theme.palette.secondary.dark,
  },
  icon: {
    color: "#fff",
    width: "14px !important",
  },
}));

const ProductTags = ({ tags }) => {
  const classes = useStyles();

  const handleDelete = () => {
    console.info("Delete tags not yet implemented.");
  };

  return (
    <div className={classes.tagWrap}>
      {tags.map(x => (
        <Chip
          size="small"
          label={x.tagName}
          onDelete={handleDelete}
          className={classes.tag}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.icon,
          }}
        />
      ))}
    </div>
  );
};

ProductTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagId: PropTypes.number.isRequired,
      tagName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProductTags;

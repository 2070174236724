import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import red from "@mui/material/colors/red";

const useStyles = makeStyles(() => ({
  error: ({ fontSize }) => ({
    fontSize,
    color: red[900],
  }),
}));

const ErrorText = ({ message, fontSize }) => {
  const classes = useStyles({
    fontSize,
  });

  return <Typography className={classes.error}>{message}</Typography>;
};

ErrorText.defaultProps = {
  fontSize: 18,
  message: "There has been an error, please check!",
};

ErrorText.propTypes = {
  fontSize: PropTypes.number,
  message: PropTypes.node,
};

export default ErrorText;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import TreeNodeLeaf from "./TreeNodeLeaf";
import Arrow from "./ArrowDropDown";
import { setFolderData, setRefetchFiles } from "../../actions";

const useStyles = makeStyles(theme => ({
  rootTree: props => ({
    border: `2px solid ${props.backgroundColor}`,
    backgroundColor: props.backgroundColor,
    alignItems: "center",
    color: theme.palette.getContrastText(props.backgroundColor),
    marginBottom: "1px",
    cursor: "pointer",
    padding: "2px 6px",
    fontSize: "12px",
    fontWeight: "500",
    // Default styling
    // backgroundColor: "#fff",
    // border: "2px solid #fff",
    // boxShadow: "0 1px 5px #f7f7f7"
  }),
  active: props => ({
    backgroundColor: "transparent !important",
    boxShadow: `inset 0 0 50px ${props.backgroundColor}`,
    borderRadius: 3,

    // Default styling
    // backgroundColor: "#fff",
    // boxShadow: "none",
    // borderColor: "#e2e2e2"
  }),
  rooTreeValue: {
    padding: "6px 0",
  },
  treeNode: {
    overflow: "hidden",
  },
  treeNodeArrow: {
    cursor: "pointer",
    marginRight: 2,
    marginTop: 2,
    display: "inline-block",
  },
  treeNodeArrowCollapsed: {
    transform: "rotate(-90deg)",
  },
  treeNodeChildren: {
    marginLeft: 15,
  },
  noIndent: {
    "& + $treeNodeChildren": {
      marginLeft: 0,
    },
  },
  treeNodeChildrenCollapsed: {
    height: 0,
  },
}));

const TreeNode = ({
  data,
  siteFolderTreeColors,
  level,
  setFolderId,
  selectedFolderId,
  openedNodes,
  handleOpenNode,
}) => {
  const dispatch = useDispatch();

  const { hasChildFolders, childFolders, folderId } = data;
  const [collapsed, setCollapsed] = useState(true);

  const name = data.name || data.folderName;

  useEffect(() => {
    if (openedNodes && openedNodes.length > 0) {
      const found = openedNodes.includes(folderId);
      if (found) {
        setCollapsed(false);
      }
    }
  }, [openedNodes, folderId]);

  const dynamicStyle = {
    backgroundColor: siteFolderTreeColors[level < 4 ? level : 3],
  };
  const classes = useStyles(dynamicStyle);

  const onClick = ({ id, data }) => {
    dispatch(setFolderData({ folder: data }));
    setFolderId(id);
    setCollapsed(!collapsed);
    handleOpenNode(id);
    if (selectedFolderId !== id) {
      dispatch(setRefetchFiles({ refetch: true }));
    }
  };

  if (hasChildFolders && childFolders && childFolders.length > 0) {
    return (
      <div className={classes.treeNode}>
        <Box
          display="flex"
          data-id={folderId}
          className={clsx([
            classes.rootTree,
            // Add the class below if no indent setting is on
            // classes.noIndent,
            selectedFolderId === folderId ? classes.active : null,
          ])}
          onClick={() => onClick({ id: folderId, data })}
        >
          <Arrow
            arrowClassName={`${classes.treeNodeArrow} ${
              collapsed ? classes.treeNodeArrowCollapsed : ""
            }`}
          />
          <Box className={classes.rooTreeValue}>
            <div dangerouslySetInnerHTML={{ __html: name }} />
          </Box>
        </Box>
        <div
          className={`${classes.treeNodeChildren} ${
            collapsed ? classes.treeNodeChildrenCollapsed : ""
          }`}
        >
          {hasChildFolders &&
            !collapsed &&
            childFolders &&
            childFolders.map(childFolder => {
              return (
                <TreeNode
                  key={`folder-${folderId}-child-${childFolder.folderId}`}
                  data={childFolder}
                  level={level + 1}
                  openedNodes={openedNodes}
                  selectedFolderId={selectedFolderId}
                  setFolderId={setFolderId}
                  siteFolderTreeColors={siteFolderTreeColors}
                  handleOpenNode={handleOpenNode}
                />
              );
            })}
        </div>
      </div>
    );
  }
  return (
    <TreeNodeLeaf
      classes={classes}
      folderId={folderId}
      name={name}
      data={data}
      setFolderId={setFolderId}
      backgroundColor={siteFolderTreeColors[level < 4 ? level : 3]}
      selectedFolderId={selectedFolderId}
    />
  );
};

TreeNode.defaultProps = {
  level: 0,
  selectedFolderId: null,
  handleOpenNode: () => {},
};

TreeNode.propTypes = {
  selectedFolderId: PropTypes.number,
  level: PropTypes.number,
  siteFolderTreeColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setFolderId: PropTypes.func.isRequired,
  handleOpenNode: PropTypes.func,
  openedNodes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default React.memo(TreeNode);

import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_RESOURCES } from "../graphql/queries";

// eslint-disable-next-line import/prefer-default-export
export const useGetResources = ({ folderId }) => {
  const [resources, setResources] = useState([]);

  const [getResources, { data, loading, error }] = useLazyQuery(GET_RESOURCES, {
    variables: {
      folderId,
      ascending: true,
      offset: 0,
      pageSize: 100,
      sortfield: "",
    },
    skip: !folderId,
  });

  useEffect(() => {
    if (folderId) {
      getResources();
    }
  }, [folderId]);

  useEffect(() => {
    if (data && data.resources && data.resources.items) {
      setResources(data.resources.items);
    } else {
      setResources([]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setResources([]);
    }
  }, [error]);

  return { execute: getResources, resources, loading, error };
};

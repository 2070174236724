import { gql } from '@apollo/client';

const getPrice = gql`
  query GetPrice($productId: ID!, $quantity: Int!, $propertyValues: [Int!]) {
    price(
      productId: $productId
      quantity: $quantity
      propertyValues: $propertyValues
    ) {
      exclVat
      inclVat
    }
  }
`;

export { getPrice };

import { gql } from '@apollo/client';

const getMonitoringItems = gql`
  query getMonitoringItems($siteId: Int) {
    monitoringContext {
      listing(siteId: $siteId) {
        items {
          designation
          folderName
          monitoringTypes
          monitoringItemId
          notificationActions
          notificationOccasion
          numberOfMonitoredResources
          numberOfRecipients
          siteName
        }
      }
    }
  }
`;

export default getMonitoringItems;

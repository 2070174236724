import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Grid, Table, TableBody, Divider, Box } from "@mui/material";
import Image from "../../../assets/img/kommunal-logo-black.png";
import { PICK_ORDER_ROWS } from "../../../graphql/mutations/PickingListContext";
import ReplyModal from "../ReplyModal";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import { PICKING_LIST_PRINT } from "../../../graphql/queries";
import { getSelectedSiteOrderGroupId } from "../../../helpers/selectors";
import CustomSelect from "../../common/CustomSelect";
import Header from "./Header";
import SubHeader from "./SubHeader";
import OrderRowHeader from "./OrderRowHeader";
import OrderInfoRight from "./OrderInfoRight";
import OrderRowItems from "./OrderRowItems";
import OrderStatusChanges from "./OrderStatusChanges";
import { PrimaryButton } from "../../common/Buttons";
import AddressInfo from "../../OrderHistory/AddressInfo";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "95vh",
    },
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  table: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
  },
  divider: {
    margin: "20px 0",
  },
  spacing: {
    margin: "10px 0",
  },
  tableResponsive: {
    overflowX: "auto",
  },
  btnPrint: {
    marginTop: "5px",
    padding: "3px 10px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginRight: 8,
    verticalAlign: "middle",
  },
  customSelect: {
    marginTop: "50px",
  },
  btnSave: {
    textAlign: "right",
    marginTop: 5,
  },
}));

const OrderInfoModal = ({
  order,
  open,
  setOpen,
  pickingListOrderStatus,
  fetchPickingListOrders,
  onOrderPrinted,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const [pickOrderRows] = useMutation(PICK_ORDER_ROWS);

  const {
    pickingListOrdersItemId,
    orderRows = [],
    //ordererAddress = {},
    //deliveryAddress = {},
    //invoiceAddress = {},
    deliveryDateWish,
    companyOrderno,
    orderDate,
    //email,
    orderId,
    getPickingListUsers = [],
    orderStatusChanges = [],
    comment,
    sortedAddressFields,
  } = order;

  const { deliveryAddress } = sortedAddressFields || {};

  const { invoiceAddress } = sortedAddressFields || {};

  const { ordererAddress } = sortedAddressFields || {};

  const [pickingListPrint, { data: printUrl }] = useLazyQuery(
    PICKING_LIST_PRINT
  );

  const [pickingListUsers, setPickingListUsers] = useState(null);

  const [pickedOrderIds, setPickedOrderIds] = useState([]);

  useEffect(() => {
    if (
      getPickingListUsers &&
      Array.isArray(getPickingListUsers) &&
      getPickingListUsers.length > 0
    ) {
      let tempOptions = [];

      getPickingListUsers.forEach(x => {
        const user = { label: x, value: x };
        tempOptions = [...tempOptions, user];
      });
      setPickingListUsers(tempOptions);
    }
  }, [getPickingListUsers]);

  const [openReply, setOpenReply] = useState(false);
  const [personPicked, setPersonPicked] = useState("AH");
  const [orderRowSig, setOrderRowSig] = useState("");
  const orderWeight = orderRows.reduce((acc, c) => acc + c.weight, 0) / 1000;
  const [haveUpdated, setHaveUpdated] = useState(false);

  const handlePersonPicked = e => {
    const { value } = e.target;
    setPersonPicked(value);
  };
  const handleOpenReply = () => {
    setOpenReply(true);
  };
  const handleCloseReply = () => {
    setOpenReply(false);
  };

  const handleClose = () => {
    setOpen(false);
    if (haveUpdated) {
      setHaveUpdated(false);
      fetchPickingListOrders();
    }
  };

  const handlePrint = () => {
    pickingListPrint({
      variables: {
        orderIds: [pickingListOrdersItemId],
      },
    });
  };

  useEffect(() => {
    if (
      printUrl &&
      printUrl.pickingList &&
      printUrl.pickingList.pickingListPrint
    ) {
      window.open(printUrl.pickingList.pickingListPrint);
      onOrderPrinted({ pickingListOrdersItemId });
      setHaveUpdated(false);
      fetchPickingListOrders();
    }
  }, [printUrl]);

  const handlePickOrderRows = async () => {
    try {
      const res = await pickOrderRows({
        variables: {
          pickingListOrdersItemId,
          orderHistoryRowItemIds: pickedOrderIds,
          picked: true,
          signature: personPicked,
        },
      });

      if (
        res &&
        res.data &&
        res.data.pickingListContext &&
        res.data.pickingListContext.pickOrderRows
      ) {
        const { success } = res.data.pickingListContext.pickOrderRows;

        if (success) {
          alert.success(<FormattedMessage id="pickingList.pickedOrderRow" />);
          setHaveUpdated(true);
          setOrderRowSig(personPicked);
        } else {
          alert.error(<FormattedMessage id="common.error" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <>
      <DraggableModal
        open={open}
        handleClose={handleClose}
        customClass={classes.modal}
      >
        <DraggableModalHead
          handleClose={handleClose}
          title={`Order ${companyOrderno}`}
        />
        <DraggableModalBody>
          <Header
            image={Image}
            orderDate={orderDate}
            companyOrderno={companyOrderno}
          />
          <SubHeader
            handlePrint={handlePrint}
            handleOpenReply={handleOpenReply}
          />
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {" "}
              <AddressInfo
                tableTitle={intl.formatMessage({
                  id: "orderHistory.deliveryAddress",
                })}
                sortedAddressFields={deliveryAddress}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              {" "}
              <AddressInfo
                tableTitle={intl.formatMessage({
                  id: "orderHistory.postalAddress",
                })}
                sortedAddressFields={ordererAddress}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              {" "}
              <AddressInfo
                tableTitle={intl.formatMessage({
                  id: "orderHistory.invoiceAddress",
                })}
                sortedAddressFields={invoiceAddress}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <OrderInfoRight
              message={comment}
              deliveryDateWish={deliveryDateWish}
              orderWeight={orderWeight}
            />
            
            <OrderStatusChanges orderStatusChanges={orderStatusChanges} />
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={pickingListOrderStatus === "ACTIVE" ? 10 : 12}
              className={classes.tableResponsive}              
            >
              <Table className={classes.table} size="small">
                <OrderRowHeader
                  pickingListOrderStatus={pickingListOrderStatus}
                />
                <TableBody>
                  {orderRows.map((row, idx) => {
                    return (
                      <OrderRowItems
                        keys={row.id}
                        row={row}
                        idx={idx}
                        orderRowSig={orderRowSig}
                        pickingListUsers={pickingListUsers}
                        personPicked={personPicked}
                        pickingListOrdersItemId={pickingListOrdersItemId}
                        fetchPickingListOrders={fetchPickingListOrders}
                        pickedOrderIds={pickedOrderIds}
                        setPickedOrderIds={setPickedOrderIds}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
            {pickingListOrderStatus === "ACTIVE" && (
              <Grid item xs={12} md={2}>
                {/* <Summation /> */}
                <Box>
                  <div className={classes.customSelect}>
                    {pickingListUsers &&
                      (<CustomSelect
                        value={personPicked}
                        options={pickingListUsers}
                        onChange={handlePersonPicked}
                      />)}
                  </div>
                  <div className={classes.btnSave}>
                    <PrimaryButton
                      disabled={pickedOrderIds.length === 0}
                      onClick={handlePickOrderRows}
                    >
                      <FormattedMessage id="btn.save" />
                    </PrimaryButton>
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
        </DraggableModalBody>
      </DraggableModal>
      {openReply && (
        <ReplyModal
          open={openReply}
          handleClose={handleCloseReply}
          orderId={orderId}
          pickingListOrdersItemId={pickingListOrdersItemId}
        />
      )}
    </>
  );
};

OrderInfoModal.defaultProps = {
  order: {},
};

OrderInfoModal.propTypes = {
  order: PropTypes.shape({
    pickingListOrdersItemId: PropTypes.string,
    orderId: PropTypes.number,
    orderRows: PropTypes.arrayOf(PropTypes.shape({})),
    ordererAddress: PropTypes.shape({}),
    deliveryAddress: PropTypes.shape({}),
    invoiceAddress: PropTypes.shape({}),
    deliveryDateWish: PropTypes.instanceOf(Date),
    companyOrderno: PropTypes.string,
    orderDate: PropTypes.instanceOf(Date),
    email: PropTypes.string,
    getPickingListUsers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  pickingListOrderStatus: PropTypes.string.isRequired,
  fetchPickingListOrders: PropTypes.func.isRequired,
  onOrderPrinted: PropTypes.func.isRequired,
};

export default OrderInfoModal;

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    padding: "8px 15px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}));

const HelpUserButton = ({ disabled, onClick }) => {
  const classes = useStyles();

  return (
    <Grid item md={3} align="right">
      <Button className={classes.btn} disabled={disabled} onClick={onClick}>
        <FormattedMessage id="btn.sendInvite" />
      </Button>
    </Grid>
  );
};

HelpUserButton.defaultProps = {
  disabled: false,
};

HelpUserButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default HelpUserButton;

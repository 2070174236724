import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
const StyledStepper = styled("ul")({
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-around",
  padding: 0,
  width: "100%",
  marginBottom: "0px",
});
const StyledStepperStep = styled("li")({
  position: "relative",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%",
});
const StyledStepperStepIndex = styled("div")(({ currentStep, done }) => ({
  width: "30px",
  height: "30px",
  lineHeight: "30px",
  borderRadius: "50%",
  background: currentStep || done ? "#00c875 !important" : "#dedede",
  color: currentStep || done ? "#fff" : "#666",
  fontWeight: 600,
  textAlign: "center",
  marginBottom: "5px",
}));

const StyledLabelContainer = styled("div")({
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
});

function CustomStepper(props) {
  const { steps, current, progress } = props;

  function StepContent({ done, index }) {
    return done ? "✓" : index + 1;
  }

  const ProgressBar = ({ current, step, progress }) => {
    let value = 0;
    if (current + 1 === step) {
      value = progress;
    } else if (current >= step) {
      value = 100;
    }

    return (
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          "&.MuiLinearProgress-root": {
            flex: "1 1 auto",
            position: "absolute",
            top: 12,
            left: "calc(-50% + 18px)",
            right: "calc(50% + 18px)",
            backgroundColor: "#dedede93",
            height: 6,
            borderRadius: 3,
          },
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#00c875",
          },
        }}
      />
    );
  };

  function renderStep(label, key) {
    const { current, progress } = this;
    const done = key < current;
    const currentStep = key === current;
    return (
      <StyledStepperStep key={key}>
        <StyledLabelContainer>
          <StyledStepperStepIndex currentStep={currentStep} done={done}>
            <StepContent done={done} index={key} />
          </StyledStepperStepIndex>
          <Typography
            noWrap={true}
            sx={{
              margin: 0,
              fontWeight: 500,
            }}
          >
            {label}
          </Typography>
        </StyledLabelContainer>
        {!!key && (
          <ProgressBar current={current} step={key} progress={progress} />
        )}
      </StyledStepperStep>
    );
  }

  return (
    <StyledStepper>
      {steps.map(renderStep, { current, progress })}
    </StyledStepper>
  );
}

CustomStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
  progress: PropTypes.number,
};

export default CustomStepper;

import { createTheme } from '@mui/material/styles';

const COLORS = {
  UNIQUEUE_GRÖN: "#aace6f",
  UNIQUEUE_MKTLJUSGRÖN: "#e3efcf",
  UNIQUEUE_LJUSGRÖN: "#8ebe3f",
  UNIQUEUE_GULGRÖN: "#9DB910",
  UNIQUEUE_BLÅTTSTÅL: "#2d373c",
  UNIQUEUE_MÖRKGRÅ: "#4b4b4d",
  UNIQUEUE_VIT: "#fff",
};

const UQ_THEME = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    body1: {
      fontSize: 14,
    },
    h6: {
      fontSize: 18,
      fontWeight: 400,
      textTransform: "none",
    },
    subtitle1: {
      fontSize: 12,
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      grey: "#DEDEDE",
      lightGrey: "#D9D9D9",
      neon: "#E76678",
      cerise: "#F6689B",
      blue: "#26aaf7",
      pink: "#FAD1D6",
      green: "#40ca0a",
      link: "#26aaf7",
      lightBlue: "#D6F2EF",
      gold: "#754f0b",
      red: "#CE122D",
    },
    background: {
      paper: COLORS.UNIQUEUE_VIT,
      default: "#fafafa",
      cream: "#f4f3ed",
      gray: "#ddd",
      lightGray: "#f9f9f9",
      contrastGray: "#f1f1f1",
      orange: "#f07d17",
      contrastOrange: "#eba05d",
    },
    primary: {
      light: COLORS.UNIQUEUE_GRÖN,
      main: COLORS.UNIQUEUE_LJUSGRÖN,
      dark: "#000000",
      contrastText: "#ffffff",
      alt: "#aace6f",
    },
    secondary: {
      light: COLORS.UNIQUEUE_GULGRÖN,
      main: "#787D7E",
      dark: "#787D7E",
      contrastText: "#000000",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      gray: "#444",
      lightGray: "#9a9a9a",
      pink: "rgba(102, 35, 54, 0.54)",
      lightPink: "rgba(102, 35, 54, 0.14)",
    },
    component: {
      hamburger: COLORS.UNIQUEUE_BLÅTTSTÅL,
      pageBackground: "#f5f5f5",
      headerBackground: COLORS.UNIQUEUE_VIT,
      headerColor: COLORS.UNIQUEUE_BLÅTTSTÅL,
      navbarBackground: COLORS.UNIQUEUE_VIT,
      navbarColor: COLORS.UNIQUEUE_BLÅTTSTÅL,
      navbarChildColor: COLORS.UNIQUEUE_BLÅTTSTÅL,
      navbarSeparator: "#f1f1f1",
      badgeBackground: COLORS.UNIQUEUE_LJUSGRÖN,
      badgeColor: COLORS.UNIQUEUE_VIT,
      languageBackground: "#a7a7a7",
      productBoxColor: COLORS.UNIQUEUE_BLÅTTSTÅL,
      tabBackground: COLORS.UNIQUEUE_GRÖN,
      tabColor: COLORS.UNIQUEUE_MÖRKGRÅ,
      tabWrap: COLORS.UNIQUEUE_MKTLJUSGRÖN,
      customPanelColor: COLORS.UNIQUEUE_MÖRKGRÅ,
      customPanelBackground: "#d2d2d2",
      fileUploadBackground: "#efefef",
      imageCardHover: COLORS.UNIQUEUE_MKTLJUSGRÖN,
      homeMainColor: COLORS.UNIQUEUE_LJUSGRÖN,
      viewSwitchBackground: COLORS.UNIQUEUE_MKTLJUSGRÖN,
      detailedSearchBox: COLORS.UNIQUEUE_VIT,
      detailedSearchBorder: "#f5f5f5",
      detailedSearchSwitch: COLORS.UNIQUEUE_LJUSGRÖN,
      selectedFolder: "#e2e2e2",
    },
  },
});

export default UQ_THEME;

import React from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";

function renderTime(dateTime) {
  return (
    dateTime && (
      <>
        <FormattedDate value={dateTime} /> <FormattedTime value={dateTime} />
      </>
    )
  );
}

function renderEvent(eventId) {
  const textKey = getMonitoringHistoryEventTextKey(eventId);
  return textKey && <FormattedMessage id={textKey} />;
}

function getMonitoringHistoryEventTextKey(eventId) {
  switch (eventId) {
    case "FOLDER_CREATE":
    case "RESOURCE_CREATE":
    case "RESOURCE_CREATE_VERSION":
    case "SITE_ROLE_CREATE":
      return "monitoring.history.event.created";

    case "FOLDER_DELETE":
    case "RESOURCE_DELETE":
    case "RESOURCE_DELETE_VERSION":
    case "SITE_ROLE_DELETE":
      return "monitoring.history.event.deleted";

    case "FOLDER_DOWNLOAD":
    case "RESOURCE_DOWNLOAD":
    case "RESOURCE_DOWNLOAD_VERSION":
      return "monitoring.history.event.downloaded";

    case "FOLDER_LOCK":
    case "RESOURCE_LOCK":
      return "monitoring.history.event.locked";

    case "FOLDER_MOVE":
    case "RESOURCE_MOVE":
      return "monitoring.history.event.moved";

    case "FOLDER_UNLOCK":
    case "RESOURCE_UNLOCK":
      return "monitoring.history.event.unlocked";

    case "FOLDER_UPDATE":
    case "RESOURCE_UPDATE":
    case "SITE_ROLE_UPDATE":
    case "SITE_USER_UPDATE":
      return "monitoring.history.event.updated";

    case "SITE_USER_ADD":
      return "monitoring.history.event.added";

    case "SITE_USER_REMOVE":
      return "monitoring.history.event.removed";

    case "UNKNOWN":
      return "monitoring.history.event.unknown";

    default:
      return false;
  }
}

export { renderEvent, renderTime };

import { forwardRef } from "react";
import { useIntl } from "react-intl";
import { Avatar, CardHeader, Tooltip } from "@mui/material";
import { styled } from "@mui/styles";

const StyledAvatar = styled(Avatar)({
  height: 27,
  width: 27,
  fontSize: "inherit",
});

const StyledCardHeader = styled(CardHeader)(({ disabled, onClick }) => ({
  cursor: (onClick && !disabled) ? "pointer" : "default",
  padding: 0,
  whiteSpace: "nowrap",
  "& .MuiCardHeader-avatar": {
    marginRight: 5,
  },
  "& .MuiCardHeader-content": {
    overflow: "auto",
  },
  "& .MuiCardHeader-title": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "12px",
    lineHeight: "13px",
  },
}));

const ProjectUserCard = forwardRef(({ avatar, name, ...rest }, ref) => {
  return (
    <StyledCardHeader avatar={avatar} title={name} {...rest} ref={ref} />
  )
});

const ProjectUserItem = ({ avatar, disabled, name, onClick, tooltip }) => (
  <Tooltip
    arrow
    describeChild={false}
    placement="left"
    title={tooltip}
  >
    <ProjectUserCard
      avatar={avatar}
      disabled={disabled}
      name={name}
      onClick={onClick}
    />
  </Tooltip>
);

const UserCellValue = ({ disabled, hideUnassigned, onClick, user }) => {
  const intl = useIntl();

  if (hideUnassigned && !user) {
    return;
  }

  if (!user) {
    const unassignedMessage = intl.formatMessage({ id: "project.userSelector.unassigned" });
    return (
      <ProjectUserItem
        avatar={<StyledAvatar>?</StyledAvatar>}
        disabled={disabled}
        name={unassignedMessage}
        onClick={onClick}
        tooltip={unassignedMessage}
      />
    );
  }

  const { avatar, firstname, lastname, username } = user;

  const initials = (firstname || "")[0] + (lastname || "")[0];

  const renderName = (separator) => {
    const separate = !!firstname && !!lastname;
    return <>{firstname}{separate && separator}{lastname}</>;
  };

  const renderTooltip = () => {
    const name = renderName(" ");
    return <>{name} &lt;{username}&gt;</>;
  };

  return (
    <ProjectUserItem
      avatar={<StyledAvatar src={avatar}>{initials}</StyledAvatar>}
      disabled={disabled}
      name={renderName(<br />)}
      onClick={onClick}
      tooltip={renderTooltip()}
    />
  );
};

export default UserCellValue;

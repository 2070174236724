import { gql } from '@apollo/client';

const DELETE_SHARE = gql`
  mutation DeleteShare($shareId: Int!) {
    shareContext {
      deleteShare(shareId: $shareId)
    }
  }
`;

export default DELETE_SHARE;

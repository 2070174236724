import React, { memo } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import MonitoringPanelTitle from "../MonitoringPanelTitle";
import CheckboxDefault from "../../../common/FormControl/Checkbox";

const useStyles = makeStyles(() => ({
  checkboxTextField: {
    width: 40,
    paddingLeft: 5,
    paddingRight: 5,
    verticalAlign: "inherit",
    "& .MuiOutlinedInput-input": {
      padding: 3,
      textAlign: "center",
      fontSize: 14,
    },
  },
  checkBoxText: {
    fontSize: "12px",
  },
}));

const CreateColumn = ({ filesFoldersSettings, setFilesFolderSettings }) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    fileCountEqualTo,
    fileCountEqualToValue,
    fileCountGreaterThan,
    fileCountGreaterThanValue,
    fileFolderCreate,
  } = filesFoldersSettings || {};

  const handleCheckboxInputChange = e => {
    setFilesFolderSettings({
      ...filesFoldersSettings,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid item md={4}>
      <MonitoringPanelTitle
        title={<FormattedMessage id="monitoring.additions" />}
        span={intl.formatMessage({
          id: "monitoring.newFileOrFolder",
        })}
      />
      <Box>
        <CheckboxDefault
          label={<FormattedMessage id="monitoring.triggers.file.create" />}
          onChange={() =>
            setFilesFolderSettings(prevFiles => {
              return { ...prevFiles, fileFolderCreate: !fileFolderCreate };
            })
          }
          value={fileFolderCreate}
        />
      </Box>
      <Box>
        <CheckboxDefault
          onChange={() =>
            setFilesFolderSettings(prev => {
              return {
                ...prev,
                fileCountEqualTo: !fileCountEqualTo,
              };
            })
          }
          value={fileCountEqualTo}
        />
        <Typography component="span" className={classes.checkBoxText}>
          <FormattedMessage id="monitoring.triggers.folder.fileCountCheckIsGreaterThan.greaterThan" />
        </Typography>
        <TextField
          className={classes.checkboxTextField}
          defaultValue={1}
          variant="outlined"
          name="fileCountEqualToValue"
          value={fileCountEqualToValue}
          onChange={handleCheckboxInputChange}
          type="number"
        />
        <Typography component="span" className={classes.checkBoxText}>
          <FormattedMessage id="monitoring.triggers.folder.fileCountCheckIsGreaterThan.files" />
        </Typography>
      </Box>
      <Box>
        <CheckboxDefault
          onChange={() =>
            setFilesFolderSettings(prev => {
              return {
                ...prev,
                fileCountGreaterThan: !fileCountGreaterThan,
              };
            })
          }
          value={fileCountGreaterThan}
        />
        <Typography component="span" className={classes.checkBoxText}>
          <FormattedMessage id="monitoring.triggers.folder.fileCountCheckIsEqualTo.equalTo" />
        </Typography>
        <TextField
          className={classes.checkboxTextField}
          defaultValue={1}
          variant="outlined"
          name="fileCountGreaterThanValue"
          value={fileCountGreaterThanValue}
          onChange={handleCheckboxInputChange}
          type="number"
        />
        <Typography component="span" className={classes.checkBoxText}>
          <FormattedMessage id="monitoring.triggers.folder.fileCountCheckIsEqualTo.files" />
        </Typography>
      </Box>
    </Grid>
  );
};

CreateColumn.propTypes = {
  filesFoldersSettings: PropTypes.shape({
    fileFolderCreate: PropTypes.bool,
    fileCountEqualTo: PropTypes.bool,
    fileCountGreaterThan: PropTypes.bool,
    fileCountEqualToValue: PropTypes.number.isRequired,
    fileCountGreaterThanValue: PropTypes.number.isRequired,
  }).isRequired,
  setFilesFolderSettings: PropTypes.func.isRequired,
};

export default memo(CreateColumn);

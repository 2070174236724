import { gql } from '@apollo/client';

const getFolderData = gql`
  query GetFolderData($siteId: Int!, $folderId: Int!, $folderLevel: Int!) {
    widgets {
      getFolderData(
        siteId: $siteId
        folderId: $folderId
        folderLevel: $folderLevel
      ) {
        folderId
        name
      }
    }
  }
`;

export default getFolderData;

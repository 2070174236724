import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { MOVE_GROUP } from "../../../../graphql/mutations/CompanyAdminContext";

const useStyles = makeStyles(() => ({
  divider: {
    padding: 0,
  },
  dividerNormal: {
      backgroundColor: "transparent",
      borderColor: "transparent"
  },
  dividerDraggedOver: {
    backgroundColor: "transparent",
    marginLeft: 27,
    padding: "6px 0",
  },
  normal: {},
  draggedOver: { overflow: "hidden" },
  icon: {
    fontSize: 14,
    width: 14,
    height: 14,
    overflow: "unset",
    color: "#888",
    position: "absolute",
    marginTop: 0,
    marginLeft: 15,
  },
  iconDivide: {
    height: 2,
    position: "absolute",
    left: 25,
    top: -3,
    backgroundColor: "#888",
    margin: "8px 0",
    width: "100%",
  },
  iconWrap: {
    position: "relative",
  },
}));

const InBetweenFolder = ({
  parentGroupId,
  groupTree,
  refetch,
  index,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const [draggedOver, setDraggedOver] = useState(false);
  const [moveGroup] = useMutation(MOVE_GROUP);

  const checkIfValidTarget = (sourceGroupId, targetGroupId) => {
    var node = groupTree[`g_${targetGroupId}`];
    while (!!node) {
      if (node.groupId === sourceGroupId) {
        return false;
      }
      node = groupTree[`g_${node.parentGroupId}`];
    }
    return true;
  };

  const handleMoveGroup = async e => {
    const targetGroupId = parentGroupId;

    const draggedGroupJsonData = e.dataTransfer.getData("dragged_group");
    const draggedGroup = JSON.parse(draggedGroupJsonData);

    var isValidTarget = checkIfValidTarget(draggedGroup.groupId, targetGroupId);

    if (!isValidTarget || targetGroupId === draggedGroup.groupId) {
      console.info("Group not moved.");
      return;
    }

    try {
      const res = await moveGroup({
        variables: {
          groupId: draggedGroup.groupId,
          parentGroupId: targetGroupId,
          position: index,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.moveGroup
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyGroupSettings.moveGroup;

        if (success) {
          alert.success(
            <FormattedMessage id="userGroups.success.groupMoved" />
          );
          refetch();
        } else {
          console.error("Error moving group");
          alert.error(
            <FormattedMessage id="userGroups.error.couldNotMoveGroup" />
          );
        }
      } else {
        console.error("Error moving group");
        alert.error(
          <FormattedMessage id="userGroups.error.couldNotMoveGroup" />
        );
      }
    } catch (err) {
      console.error("Error moving group", err);
      alert.error(<FormattedMessage id="userGroups.error.couldNotMoveGroup" />);
    }
  };

  const handleDrop = (e, item) => {
    e.stopPropagation();
    setDraggedOver(false);
    const action = e.dataTransfer.getData("action");
    if (action === "MOVE_GROUP") {
      handleMoveGroup(e, item);
    }
  };

  const handleOnDragOver = (e, item) => {
    e.preventDefault();
    setDraggedOver(true);
  };
  const handleDragLeave = (e, item) => {
    e.preventDefault();
    setDraggedOver(false);
  };

  return (
    <Box
      className={clsx([draggedOver ? classes.draggedOver : classes.normal])}
      onDrop={e => handleDrop(e)}
      onDragOver={handleOnDragOver}
      onDragLeave={handleDragLeave}
    >
      {draggedOver && (
        <Box className={classes.iconWrap}>
          <Icon className={clsx(["fas fa-angle-right", classes.icon])} />
          <Box className={classes.iconDivide} />
        </Box>
      )}
          <Divider
        className={clsx([
          classes.divider,
          draggedOver ? classes.dividerDraggedOver : classes.dividerNormal,
        ])}
      />
    </Box>
  );
};

InBetweenFolder.defaultProps = {
  parentGroupId: null,
};

InBetweenFolder.propTypes = {
  parentGroupId: PropTypes.number,
  groupTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setGroupTree: PropTypes.func,
  refetch: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default memo(InBetweenFolder);

import { gql } from '@apollo/client';

const SiteNewsListItem = gql`
  fragment SiteNewsListItemFields on SiteNewsListItem {
    commentDate
    commentId
    dislikes
    likes
    text
    userHasDislikedComment
    userHasLikedComment
    username
  }
`;

export default SiteNewsListItem;

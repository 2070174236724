import { gql } from '@apollo/client';

const FolderRecursiveFragment = gql`
  fragment FolderRecursive on FolderDTO {
    childFolders {
      ...FolderFields
      childFolders {
        ...FolderFields
        childFolders {
          ...FolderFields
          childFolders {
            ...FolderFields
            childFolders {
              ...FolderFields
              childFolders {
                ...FolderFields
                childFolders {
                  ...FolderFields
                  childFolders {
                    ...FolderFields
                    childFolders {
                      ...FolderFields
                      childFolders {
                        ...FolderFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FolderRecursiveFragment;

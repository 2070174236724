import { alpha, Box, Button, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import SiteUsersTable from "../../../../SiteUsers/SiteUsersTable";
import {
  GET_WORKSPACE_USERS,
  GET_WORKSPACE_ROLES,
} from "../../../../../graphql/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useIntl, FormattedMessage } from "react-intl";
import clsx from "clsx";
import CustomSelect from "../../../../common/CustomSelect";
import ManageRolesModal from "../../../../SiteUsers/ManageRolesModal";
import InviteUsersModals from "../../../../SiteUsers/InviteUserModals";
import { REMOVE_SITE_USER } from "../../../../../graphql/mutations";
import { useSnackbar } from "../../../../../hooks";
import logger from "../../../../../helpers/logger";
import UPDATE_WORKSPACE_ROLE from "../../../../../graphql/mutations/CompanyAdminContext/CompanyWorkspaces/updateSiteUser";

const useStyles = makeStyles(theme => ({
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  btn: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    minWidth: "unset",
    borderRadius: 4,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 30,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputLabel-formControl": {
      fontSize: 12,
      top: -7,
    },
    "& .MuiInputLabel-formControl.Mui-focused": {
      top: 0,
    },
  },
  popper: {
    width: 280,
  },
  btnDisabled: {
    opacity: 0.8,
    "&.$Mui-disabled": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const WorkspaceUsers = ({ siteId }) => {
  const classes = useStyles();
  const intl = useIntl();
  const snackbar = useSnackbar();
  const filterOptions = [
    {
      label: intl.formatMessage({
        id: "siteUsers.showAllUsers",
      }),
      value: "SHOW_ALL",
    },
    {
      label: intl.formatMessage({
        id: "siteUsers.showInvitedUsers",
      }),
      value: "SHOW_INVITED",
    },
    {
      label: intl.formatMessage({
        id: "siteUsers.showActiveUsers",
      }),
      value: "SHOW_ACTIVE",
    },
    {
      label: intl.formatMessage({
        id: "siteUsers.showInactiveUsers",
      }),
      value: "SHOW_INACTIVE",
    },
  ];

  const [siteRoleOptions, setSiteRoleOptions] = useState([]);
  const [sortfield, setSortfield] = useState();
  const [ascending, setAscending] = useState();
  var siteRolesRights = {
    canManageSiteRoles: true,
    canManageSite: true,
    canInviteUsers: true,
  };
  const [filter, setFilter] = useState("SHOW_ALL");

  const [siteUsers, setSiteUsers] = useState([]);
  const [siteUsersLoading, setSiteUsersLoading] = useState(false);
  const [siteUsersError, setSiteUsersError] = useState(false);

  const setSiteSelect = () => {
    //dummy;
  };
  //GET_WORKSPACE_USERS
  const [
    listWorkspaceUsers,
    { data: siteUsersData, loading, error },
  ] = useLazyQuery(GET_WORKSPACE_USERS, {
    variables: {
      siteId,
      filter,
      ascending,
      sortfield,
    },
  });

  const [openInviteModals, setOpenInviteModals] = useState(false);

  const handleOpenInviteUser = () => {
    setOpenInviteModals(true);
  };

  const handleCloseInviteUser = () => {
    setOpenInviteModals(false);
  };

  const [openManageRoles, setOpenManageRoles] = useState(false);
  const handleOpenManageRoles = () => {
    setOpenManageRoles(true);
  };

  const [
    listWorkspaceRoles,
    { data: siteRolesData, loadingRoles, errorRoles },
  ] = useLazyQuery(GET_WORKSPACE_ROLES, {
    variables: {
      siteId,
    },
  });

  const getSiteUsers = () => {
    listWorkspaceUsers();
  };

  useEffect(() => {
    if (siteId) {
      listWorkspaceUsers();
      listWorkspaceRoles();
    }
  }, [siteId]);

  useEffect(() => {
    if (
      siteUsersData &&
      siteUsersData.companyAdminContext &&
      siteUsersData.companyAdminContext.companyWorkspaces &&
      Array.isArray(
        siteUsersData.companyAdminContext.companyWorkspaces.listSiteUsersForSite
      )
    ) {
      setSiteUsers(
        siteUsersData.companyAdminContext.companyWorkspaces.listSiteUsersForSite
      );
    }
  }, [siteUsersData]);

  useEffect(() => {
    if (
      siteRolesData &&
      siteRolesData.companyAdminContext &&
      siteRolesData.companyAdminContext.companyWorkspaces &&
      Array.isArray(
        siteRolesData.companyAdminContext.companyWorkspaces.listSiteRolesForSite
      )
    ) {
      setSiteRoleOptions(
        siteRolesData.companyAdminContext.companyWorkspaces.listSiteRolesForSite
      );
    }
  }, [siteRolesData]);

  useEffect(() => {
    setSiteUsersLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setSiteUsersError(error);
    }
  }, [error]);

  const [removeSiteUser] = useMutation(REMOVE_SITE_USER);
  const [updateSiteUser] = useMutation(UPDATE_WORKSPACE_ROLE);

  const handleRemoveSiteUser = async (siteId, id) => {
    try {
      const res = await removeSiteUser({ variables: { siteId, id } });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyWorkspaces &&
        res.data.companyAdminContext.companyWorkspaces.removeSiteUser &&
        res.data.companyAdminContext.companyWorkspaces.removeSiteUser.success
      ) {
        getSiteUsers();
        alert.success(<FormattedMessage id="siteUsers.removedUser" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="siteUsers.errorRemoveUser" />
        );
      }
    } catch (err) {
      snackbar.workspaceError(
        <FormattedMessage id="siteUsers.errorRemoveUser" />
      );
      logger.error(`Error in removing site user. Unable to send ${err}`);
    }
  };

  const handleUpdateSiteUser = async params => {
    try {
      const res = await updateSiteUser({ variables: { siteUser: params } });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyWorkspaces &&
        res.data.companyAdminContext.companyWorkspaces.updateSiteUserRole &&
        res.data.companyAdminContext.companyWorkspaces.updateSiteUserRole
          .success
      ) {
        alert.success(<FormattedMessage id="siteUsers.update" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="siteUsers.errorUpdate" />
        );
      }
    } catch (err) {
      snackbar.workspaceError(<FormattedMessage id="siteUsers.errorUpdate" />);
      logger.error(`Error in updating site user. Unable to send ${err}`);
    }
  };

  return (
    <>
      <Box className={classes.paperHead}>
        {siteRolesRights.canInviteUsers && (
          <Button
            className={classes.btn}
            classes={{ disabled: classes.btnDisabled }}
            onClick={handleOpenInviteUser}
          >
            <FormattedMessage id="btn.inviteUsers" />
            <Icon className={clsx(["fa fa-user-plus", classes.btnIcon])} />
          </Button>
        )}

        {siteRolesRights.canManageSiteRoles && (
          <Button
            className={classes.btn}
            classes={{ disabled: classes.btnDisabled }}
            onClick={handleOpenManageRoles}
          >
            <FormattedMessage id="btn.manageRoles" />
            <Icon className={clsx(["fa fa-user-cog", classes.btnIcon])} />
          </Button>
        )}

        <Box className={classes.selectWrap}>
          <CustomSelect
            inputLabel={<FormattedMessage id="siteUsers.filter" />}
            options={filterOptions}
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        </Box>
      </Box>

      <SiteUsersTable
        fetchSiteUsers={getSiteUsers}
        siteRoleOptions={siteRoleOptions}
        sortfield={sortfield}
        ascending={ascending}
        setSortfield={setSortfield}
        setAscending={setAscending}
        siteRolesRights={siteRolesRights}
        siteUsers={siteUsers}
        siteUsersLoading={siteUsersLoading}
        onRemoveSiteUser={handleRemoveSiteUser}
        onUpdateSiteUser={handleUpdateSiteUser}
      />

      {openManageRoles && (
        <ManageRolesModal
          open={openManageRoles}
          setOpenManageRoles={setOpenManageRoles}
          selectedSite={siteId}
          siteRoleOptions={siteRoleOptions}
        />
      )}

      <InviteUsersModals
        open={openInviteModals}
        onCloseDialog={handleCloseInviteUser}
        selectedSite={siteId}
        siteRoleOptions={siteRoleOptions}
        getSiteUsers={getSiteUsers}
        setSiteSelect={setSiteSelect}
      />
    </>
  );
};

export default WorkspaceUsers;

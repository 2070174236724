import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import {
  DashboardContainer,
  InformationSection,
  SiteHeaderTitle,
} from "../../components/dashboard";
import RightSidebar from "../../components/ProductsMainView/FileView/RightSidebar";
import { makeStyles } from "@mui/styles";
import { FILEVIEW_RIGHT_COLUMN } from "../../actions/constants";
import { useSelector } from "react-redux";

const { HIDE } = FILEVIEW_RIGHT_COLUMN;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    flex: "1 1 auto",
  },
  rightSidebarContainer: {
    minWidth: 300,
  },
  btnLink: {
    padding: 0,
    fontSize: 13,
    textTransform: "none",
    marginLeft: 5,
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
}));

const DefaultDashboard = ({ workspace }) => {
  var classes = useStyles();
  const rightColumn = useSelector(state => state.ui.fileView.rightColumn);

  return (
    <DashboardContainer>
      <div className={classes.root}>
        <div className={classes.mainContainer}>
          {" "}
          <InformationSection>
            <SiteHeaderTitle
              title={
                <FormattedMessage
                  id="common.welcomeTo"
                  values={{
                    workspace: workspace || "site dashboard",
                  }}
                />
              }
            />
          </InformationSection>
        </div>
        {rightColumn !== HIDE && (
          <div className={classes.rightSidebarContainer}>
            <RightSidebar />
          </div>
        )}
      </div>
    </DashboardContainer>
  );
};

DefaultDashboard.defaultProps = {
  workspace: "",
};

DefaultDashboard.propTypes = {
  workspace: PropTypes.string,
};

export default DefaultDashboard;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { PinkSwitch } from "../common";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 11,
    hyphens: "auto",
  },
  pl0: {
    paddingLeft: "0 !important",
  },
  colLh: {
    lineHeight: "10px",
    overflowX: "clip",
    textOverflow: "ellipsis",
  },
  colPadding: {
    marginTop: 4,
  },
}));

const DetailedSearchItem = ({
  label,
  children,
  name,
  onChange,
  checked,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={3} md={3} align="right" className={classes.colLh}>
        <span className={classes.label} title={label}>{label}</span>
      </Grid>
      <Grid item xs={7} md={7} className={classes.colPadding}>
        {children}
      </Grid>
      <Grid item xs={2} md={2} align="center" className={classes.pl0}>
        <PinkSwitch
          size="small"
          name={name}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

DetailedSearchItem.defaultProps = {
  checked: false,
  disabled: false,
};

DetailedSearchItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DetailedSearchItem;

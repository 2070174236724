import React from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import PropTypes from "prop-types";
import { Box, Button, Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import PickingListTable from "./PickingListTable";

const useStyles = makeStyles(theme => ({
  btn: {
    padding: "8px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 0,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
}));

const NewTab = ({
  loading,
  searchValues,
  setSearchValues,
  fetchPickingListOrders,
  pickingListOrders,
  showColumn,
  totalCount,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
  setSelectedOrders,
  handlePrint,
  page,
  setPage,
  onOrderPrinted,
}) => {
  const classes = useStyles();

  return (
    <div>
      <PickingListTable
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        loading={loading}
        fetchPickingListOrders={fetchPickingListOrders}
        pickingListOrders={pickingListOrders}
        showColumn={showColumn}
        totalCount={totalCount}
        selectedOrders={selectedOrders}
        addSelectedOrder={addSelectedOrder}
        removeSelectedOrder={removeSelectedOrder}
        setSelectedOrders={setSelectedOrders}
        page={page}
        setPage={setPage}
        onOrderPrinted={onOrderPrinted}
      />
      <Box align="right">
        <Button
          className={classes.btn}
          variant="contained"
          disabled={loading || selectedOrders.length === 0}
          onClick={() => handlePrint()}
        >
          <FormattedMessage id="btn.print" />
          <Icon className={clsx(["fa fa-print", classes.btnIcon])} />
        </Button>
      </Box>
    </div>
  );
};

NewTab.defaultProps = {
  searchValues: {},
  loading: false,
  pickingListOrders: [],
  showColumn: {},
};

NewTab.propTypes = {
  totalCount: PropTypes.number.isRequired,
  showColumn: PropTypes.shape({}),
  pickingListOrders: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  searchValues: PropTypes.shape({}),
  setSearchValues: PropTypes.func.isRequired,
  fetchPickingListOrders: PropTypes.func.isRequired,
  selectedOrders: PropTypes.arrayOf(PropTypes.number).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
  setSelectedOrders: PropTypes.func.isRequired,
  onOrderPrinted: PropTypes.func.isRequired,
};

export default NewTab;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import FolderName from "./FolderName";

const useStyles = makeStyles(() => ({
  folderIcon: {
    fontSize: 14,
    color: "#5b5654",
  },
  folderIconNoChild: { margin: "2px 3px 2px 3px !important" },
  folderIconWithChild: { margin: "2px 3px 2px 3px" },
  checkbox: { padding: "3px !important", marginLeft: "auto" },
  folderTitleSpan: {
    flexDirection: "row",
    alignItems: "flex-start",
    display: "flex",
    cursor: "pointer",
    flex: 1,
    position: "relative",
  },
  lockIconWithChild: {
    position: "absolute",
    fontSize: 8,
    top: 5,
    left: 6,
    color: "#CD132D",
  },
  lockIconNoChild: {
    position: "absolute",
    fontSize: 8,
    top: 5,
    left: 6,
    opacity: "0.8",
    color: "#CD132D",
  },
}));

const FolderLabel = ({
  folder,
  folderId,
  folderName,
  handleOnFolderRowClick,
  foundFolders,
}) => {
  const classes = useStyles();

  const found = foundFolders.includes(folderId);

  return (
    <span
      role="presentation"
      className={clsx([classes.folderTitleSpan])}
      onClick={e => handleOnFolderRowClick(e, folder)}
    >
      <Icon
        className={clsx([
          "fa fa-folder",
          classes.folderIcon,
          folder && folder.childFolderIds && folder.childFolderIds.length > 0
            ? classes.folderIconWithChild
            : classes.folderIconNoChild,
        ])}
        style={{ color: folder.colorValue }}
      />
      {folder && folder.locked && (
        <Icon
          className={clsx([
            "fa fa-lock",
            folder && folder.childFolderIds && folder.childFolderIds.length > 0
              ? classes.lockIconWithChild
              : classes.lockIconNoChild,
          ])}
        />
      )}

      <FolderName name={folderName} found={found} />
    </span>
  );
};

FolderLabel.defaultProps = {
  folderName: "",
  selectedNode: null,
  foundFolders: [],
};

FolderLabel.propTypes = {
  selectedNode: PropTypes.shape({
    folderId: PropTypes.number.isRequired,
  }),
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    folderId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
    locked: PropTypes.bool,
  }).isRequired,
  folderId: PropTypes.number.isRequired,
  folderName: PropTypes.string,
  handleOnFolderRowClick: PropTypes.func.isRequired,
  foundFolders: PropTypes.arrayOf(PropTypes.number),
};

export default memo(FolderLabel);

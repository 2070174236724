import React, { Fragment, Component } from "react";
import Header from "../Header";
import Navbar from "../NavBar";

class DefaultLayout extends Component {
  render() {
    const { history } = this.props;
    return (
      <Fragment>
        <Header history={history} />
        <Navbar />
      </Fragment>
    );
  }
}

export default DefaultLayout;

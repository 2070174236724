import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const ADD_USER = gql`
  mutation AddUser($token: String) {
    addUser(token: $token) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default ADD_USER;

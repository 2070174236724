import React, { useState, useEffect, memo, useCallback } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedTime } from "react-intl";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "lodash";
import { useMutation } from "@apollo/client";
import { CircularProgress, Box, TableRow, TableCell } from "@mui/material";
import {
  FormattedCurrency,
  ContainedSelect,
  FormattedDate,
} from "../../common";
import CustomIconButton from "./CustomIconButton";
import Checkbox from "../../common/FormControl/Checkbox";
import EditDynamicProductModal from "../EditDynamicProductModal";
import SelectNativeFull from "../../common/FormControl/SelectNativeFull";
import { cartInitialize } from "../../../actions";
import {
  FROM_VALUES_LIST,
  DYNAMIC_PRODUCT_TYPE,
  BOOKING_TYPE,
} from "../../../constant/types";
import { getSiteId } from "../../../helpers/selectors";
import QuantityInput from "./QuantityInput";
import { UPDATE_DYNAMIC_PRODUCT } from "../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  quantityWrapper: {},
  quantityContainer: {
    width: "100%",
    margin: "0 auto",
  },
  td: {
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  tdQuantity: {
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  tdAction: {},
  tdProductImage: {
    width: 70,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "25%",
      padding: 0,
    },
  },
  tdProduct: {
    fontSize: 12,
    verticalAlign: "middle",
    color: theme.palette.component.productBoxColor,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  productText: {
    marginBottom: 5,
    marginTop: 0,
  },
  supplierWrapper: {
    display: "flex",
    alignItems: "center",
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
      fontSize: 10,
      padding: "4px 12px",
    },
    "& .MuiFormControl-fullWidth": {
      width: 140,
    },
    "& .MuiSelect-iconOutlined": {
      right: 3,
      top: 3,
    },
    "& .MuiInputLabel-outlined": {
      top: -5,
      fontSize: 12,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      top: 0,
    },
    fontWeight: 500,
  },
  bookButton: {
    color: "#999",
    padding: "4px !important",
  },
  bookIcon: {
    fontSize: 14,
    width: "unset",
  },
  hideInMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ProductRow = ({
  cartItems,
  cartUpdateItem,
  handleDeleteItem,
  handleUpdateProductQuantity,
  handleUpdateSuppliers,
  item,
  orderGroupId,
  productIndex,
  saveCart,
  fetchCart,
  loadingFetchCart,
  handleCheckboxChange,
  selected,
  handleRemoveItemsFromCart,
  showUpdateSuppliers,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [productQuantity, setProductQuantity] = useState(item.quantity || 0);
  const [modalOpen, setModalOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const {
    name,
    product,
    quantity,
    productId,
    price,
    dynamicFieldValues,
    selectableSuppliers,
    siteSupplierId,
    shoppingCartItemId,
    source,
    bookingInterval = {},
  } = item;
  const [selectedSupplier, setSelectedSupplier] = useState(siteSupplierId || 0);
  const [piecePrice, setPiecePrice] = useState(price / productQuantity);
  const siteId = useSelector(state => getSiteId(state));
  const [updateQuantity, setUpdateQuanitty] = useState(null);
  const [updateDynamicProduct] = useMutation(UPDATE_DYNAMIC_PRODUCT);

  const {
    articleNo,
    format,
    numPages,
    quantities,
    thumbnailImageUri,
    productType,
    propertyProductData,
  } = product || {};

  const { valuesList, selectionType } = quantities || {};
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const dropDownOptions =
    selectionType === FROM_VALUES_LIST
      ? valuesList.map(option => ({
          label: option,
          value: option,
        }))
      : [];

  useEffect(() => {
    if (Array.isArray(selectableSuppliers) && selectableSuppliers.length > 1) {
      const options = [];
      selectableSuppliers.forEach(x => {
        options.push({
          label: x.displayName,
          value: x.siteSupplierId,
        });
      });

      setSuppliers(options);
    }

    if (
      Array.isArray(selectableSuppliers) &&
      selectableSuppliers.length === 1 &&
      !siteSupplierId
    ) {
      const tempCartItems = [...cartItems];
      const newItem = {
        ...tempCartItems[productIndex],
        siteSupplierId: selectableSuppliers[0].siteSupplierId,
      };
      tempCartItems[productIndex] = newItem;

      dispatch(cartInitialize({ items: tempCartItems }));
    }
  }, [selectableSuppliers]);

  const debounceHandleUpdateProductQuantity = useCallback(
    debounce(value => {
      handleUpdateProductQuantity(value, shoppingCartItemId, selectedSupplier);
    }, 1000),
    [shoppingCartItemId, selectedSupplier]
  );

  const handleQuantityChange = value => {
    const intValue = parseInt(value, 10);
    if (selectionType === FROM_VALUES_LIST) {
      setProductQuantity(intValue);
      handleUpdateProductQuantity(
        intValue,
        shoppingCartItemId,
        selectedSupplier
      );
    } else if (intValue > 0) {
      setProductQuantity(intValue);
      debounceHandleUpdateProductQuantity(intValue);
    }
  };

  useEffect(() => {
    setProductQuantity(quantity);
  }, [quantity]);

  const handleSupplier = e => {
    const { value } = e.target;
    setSelectedSupplier(value);
    handleUpdateSuppliers(value, shoppingCartItemId, productQuantity);
  };

  const handleQuantityFieldChange = event => {
    // setPiecePrice(0);
    handleQuantityChange(event.target.value);
  };

  const handleUpdateDynamicProductFields = async ({ newDynamicValues }) => {
    const dynamicFields = newDynamicValues.map(field => ({
      fieldName: field.fieldName,
      value: field.value || "",
    }));
    const params = {
      dynamicFieldValues: dynamicFields,
      propertyProductData: propertyProductData || null,
      quantity: updateQuantity || quantity,
      shoppingCartItemId,
    };
    try {
      const res = await updateDynamicProduct({ variables: { item: params } });
      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.updateDynamicProduct
      ) {
        const { success } = res.data.shoppingCartContext.updateDynamicProduct;
        if (success) {
          alert.success(
            <FormattedMessage id="product.successUpdatedItemCart" />
          );
        } else {
          alert.error(<FormattedMessage id="product.failUpdatedItemCart" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  useEffect(() => {
    if (price) {
      setPiecePrice(price / productQuantity);
    } else {
      setPiecePrice(0);
    }
  }, [price]);

  const renderQuantity = () => {
    if (selectionType === FROM_VALUES_LIST) {
      return (
        <SelectNativeFull
          value={productQuantity}
          onChange={handleQuantityFieldChange}
          options={dropDownOptions}
        />
      );
    }

    return (
      <QuantityInput
        handleDecreaseQuantity={() => handleQuantityChange(productQuantity - 1)}
        handleIncreaseQuantity={() => handleQuantityChange(productQuantity + 1)}
        handleTextInputChange={handleQuantityFieldChange}
        value={productQuantity}
        min={0}
      />
    );
  };

  const onCheckboxChange = e => {
    handleCheckboxChange(e, item.shoppingCartItemId);
  };

  return (
    <TableRow key={`${productId}-${productIndex}`}>
      <TableCell className={classes.td} padding="checkbox">
        <Checkbox value={selected} onChange={onCheckboxChange} />
      </TableCell>
      <TableCell className={classes.tdProductImage}>
        <Box onClick={handleOpenModal}>
          <img src={thumbnailImageUri} alt="Checkout" />
        </Box>
      </TableCell>
      <TableCell className={classes.tdProduct}>
        <p className={classes.productText}>
          <strong>{name}</strong>
        </p>

        {source === BOOKING_TYPE && (
          <p className={classes.productText}>
            <FormattedMessage id="cart.booked" />{" "}
            <FormattedDate value={bookingInterval.from} />{" "}
            <FormattedTime value={bookingInterval.from} />{" "}
            <FormattedMessage id="orderHistorySearch.to" />{" "}
            <FormattedDate value={bookingInterval.to} />{" "}
            <FormattedTime value={bookingInterval.to} />
          </p>
        )}

        {source !== BOOKING_TYPE && (
          <>
            <p className={classes.productText}>
              <FormattedMessage id="cart.articleNo" />: {articleNo}
            </p>
            <p className={classes.productText}>
              <FormattedMessage id="Format" />: {format}
            </p>
            <p className={classes.productText}>
              <FormattedMessage id="product.pageRange" />: {numPages} sidor
            </p>
          </>
        )}
      </TableCell>
      {showUpdateSuppliers && (
        <TableCell className={classes.td}>
          <Box className={classes.supplierWrapper}>
            {Array.isArray(suppliers) && suppliers.length > 0 && (
              <ContainedSelect
                label="Leverantör:"
                onChange={handleSupplier}
                options={suppliers}
                value={selectedSupplier}
                small
              />
            )}
          </Box>
        </TableCell>
      )}

      <TableCell className={clsx([classes.td, classes.hideInMobile])}>
        {articleNo}
      </TableCell>

      <TableCell align="center" className={classes.tdQuantity}>
        <Box className={classes.quantityWrapper}>
          {source !== BOOKING_TYPE && (
            <Box className={classes.quantityContainer}>{renderQuantity()}</Box>
          )}
          {source === BOOKING_TYPE && <p className={classes.productText}>1</p>}
        </Box>
      </TableCell>

      <TableCell align="right" className={classes.td}>
        {!loadingFetchCart && <FormattedCurrency value={piecePrice} />}
        {loadingFetchCart && <CircularProgress color="grey" size={18} />}
      </TableCell>
      <TableCell
        align="right"
        className={clsx([classes.td, classes.hideInMobile])}
      >
        {!loadingFetchCart && <FormattedCurrency value={price} />}
        {loadingFetchCart && <CircularProgress color="grey" size={18} />}
      </TableCell>
      <TableCell align="right" className={classes.tdAction}>
        <Box display="flex" justifyContent="flex-end">
          {productType === DYNAMIC_PRODUCT_TYPE && (
            <CustomIconButton
              faName="fas fa-pencil-alt"
              onClick={handleOpenModal}
            />
          )}
          <CustomIconButton
            faName="fa fa-trash"
            color={theme.palette.error.dark}
            onClick={() =>
              handleRemoveItemsFromCart(shoppingCartItemId, productIndex)
            }
          />
        </Box>
      </TableCell>
      {modalOpen && (
        <EditDynamicProductModal
          handleUpdateDynamicProductFields={handleUpdateDynamicProductFields}
          open={modalOpen}
          handleClose={handleCloseModal}
          quantity={updateQuantity || quantity}
          setQuantity={setUpdateQuanitty}
          product={{
            ...product,
            quantity: item.quantity,
            dynamicFieldValues,
            propertyProductData,
          }}
          productIndex={productIndex}
          isCheckout
        />
      )}
    </TableRow>
  );
};

ProductRow.defaultProps = {
  orderGroupId: null,
  loadingFetchCart: false,
};

ProductRow.propTypes = {
  fetchCart: PropTypes.func.isRequired,
  loadingFetchCart: PropTypes.bool,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
  // cartUpdateItem: PropTypes.func.isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  handleUpdateProductQuantity: PropTypes.func.isRequired,
  productIndex: PropTypes.number.isRequired,
  item: PropTypes.shape({
    dynamicFieldValues: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    name: PropTypes.string,
    price: PropTypes.number.isRequired,
    product: PropTypes.shape({
      articleNo: PropTypes.string.isRequired,
      format: PropTypes.string.isRequired,
      minimumPrice: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      numPages: PropTypes.string.isRequired,
      quantities: PropTypes.shape({
        selectionType: PropTypes.string.isRequired,
        valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
      }).isRequired,
      thumbnailImageUri: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
    }),
    productId: PropTypes.string,
    quantity: PropTypes.number,
    selectableSuppliers: PropTypes.arrayOf(
      PropTypes.shape({ siteSupplierId: PropTypes.number })
    ),
    siteSupplierId: PropTypes.number,
    siteId: PropTypes.number,
    source: PropTypes.string,
    shoppingCartItemId: PropTypes.string,
  }).isRequired,
  orderGroupId: PropTypes.number,
  saveCart: PropTypes.func.isRequired,
};

export default memo(ProductRow);

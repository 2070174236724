import PropTypes from "prop-types";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReactComponent as WorkspaceIconSVG } from "../../../assets/img/WorkspaceIconSmallFillToCurrentColor.svg";
import { STRUCTURE_TYPES } from "../../../constant/types";
import WorkspaceIcon from "../WorkspaceIcon";

const defaultSize = "1rem";

const useStyles = makeStyles(() => ({
  icon: {
    color: "rgba(0, 0, 0, 0.38)",
    flexShrink: 0,
    height: "0.8em",
    marginRight: 4,
  },
  workspaceIconSVG: {
    color: "rgba(0, 0, 0, 0.42)",
    flexShrink: 0,
    height: "1.1em",
    marginRight: 7,
  },
  item: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  name: {
    flex: "auto",
    marginLeft: 5,
  },
  noIcon: {
    display: "inline-block",
    height: defaultSize,
    minWidth: `calc(${defaultSize} * 1.2)`,
  },
}));

const StructureLabel = ({ item }) => {
  const { icon, iconColor, name, type } = item;

  const classes = useStyles();

  const ItemTypeIcon = () => {
    switch (type) {
      case STRUCTURE_TYPES.FOLDER:
        return (
          <FolderOutlinedIcon className={classes.icon} />
        );

      case STRUCTURE_TYPES.SITE:
        return (
          <WorkspaceIconSVG className={classes.workspaceIconSVG} />
        );

      default:
        return (
          <FolderOutlinedIcon className={classes.icon} />
        );
    }
  };

  const ItemIcon = () => {
    switch (type) {
      case STRUCTURE_TYPES.SITE:
        return (
          <WorkspaceIcon
            color={iconColor}
            icon={icon}
            size={defaultSize}
          />
        );

      default:
        return (
          <span className={classes.noIcon}></span>
        );
    }
  };

  const ItemName = () => {
    return (
      <span className={classes.name}>{name}</span>
    );
  };

  return (
    <Box className={classes.item}>
      <ItemIcon />
      <ItemName />
      <ItemTypeIcon />
    </Box>
  );
};

StructureLabel.defaultProps = {
  item: null,
};

StructureLabel.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default StructureLabel;

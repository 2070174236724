import React from "react";
import { makeStyles } from "@mui/styles";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import { Chip } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { FormattedDate } from "react-intl";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const InvoicesTable = ({
  emptyMessage = "No data",
  headers,
  dataRows,
  onClickRow,
}) => {
  const classes = useStyles();

  const formatDataCells = (cell, column) => {
    if (!column) {
      return;
    }

    switch (column.type) {
      case "datetime":
        return !cell ? "" : <FormattedDate value={cell} />;
      case "tags":
        return <Chip className={classes[cell.class]} label={cell.name} />;
    }
    return cell;
  };

  const dataCellOptions = cell => {
    return { align: cell.type === "numeric" ? "right" : undefined };
  };

  return (
    <MuiTable className={classes.root}>
      <TableHead>
        <TableRow>
          {headers.map(c => {
            return <TableCell key={JSON.stringify(c)}>{c.label}</TableCell>;
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {dataRows &&
          dataRows.map(row => (
            <TableRow
              key={JSON.stringify(row)}
              onClick={() => {
                onClickRow(row);
              }}
            >
              {headers.map(c => {
                var cellValue = row[c.field];
                return (
                  <TableCell {...dataCellOptions(c)}>
                    {formatDataCells(cellValue, c)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
      </TableBody>
      {!dataRows ||
        (dataRows.length === 0 && (
          <TableFooter>
            <Typography>{emptyMessage}</Typography>
          </TableFooter>
        ))}
    </MuiTable>
  );
};

export default InvoicesTable;

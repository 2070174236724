import { gql } from '@apollo/client';

const TaskValue = gql`
  fragment TaskValue on TaskValueDTO {
    #__typename

  	fieldId
  	stringValue
    numericValue
    dateTimeValue
  }
`;

export default TaskValue;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  imageSkeleton: {
    borderRadius: 4,
    marginBottom: 20,
  },
  textSkeleton: {
    borderRadius: 4,
  },
  root: {
    padding: 5,
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "94.5%",
  },
  gridItem: {
    height: 200,
  },
  box: {
    backgroundColor: "#F4F3EF",
    height: "100%",
  },
}));

const PickImageFieldSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        {[1, 2, 3, 4, 5, 6].map(x => {
          return (
            <Grid
              item
              key={x}
              md={4}
              sm={4}
              xs={3}
              className={classes.gridItem}
            >
              <Box className={classes.box}>
                <Skeleton
                  variant="rect"
                  height="70%"
                  animation="wave"
                  classes={{ root: classes.imageSkeleton }}
                />
                <Skeleton
                  variant="rect"
                  animation="wave"
                  classes={{ root: classes.textSkeleton }}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PickImageFieldSkeleton;

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import CustomTextField from "../TextField";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: 12,
    fontWeight: "600",
  },
  summary: {
    backgroundColor: "#f1f1f1",
    paddingLeft: 10,
    paddingRight: 10,
  },
  expansionDetails: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  expansionDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  label: {
    fontSize: 11,
    fontStyle: "italic",
    color: "#66615b",
  },
  linkText: {
    color: theme.palette.common.link,
    display: "inline",
    textDecoration: "underline",
  },
  removeLink: { color: "#AA0000" },
  cancelLink: { color: theme.palette.common.link },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 8,
  },
  smallText: {
    fontSize: 11,
  },
  originalContainer: {
    display: "flex",
    padding: 5,
  },
  labelValueText: {
    display: "inline",
  },
  labelValueLabel: {
    paddingRight: 8,
  },
  buttonLink: {
    textDecoration: "underline",
    textTransform: "none",
  },
}));

export default function PagesMenuItem({
  node,
  handleOnRemoveSortlyMenu,
  handleRenameLabel,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [newLabel, setNewLabel] = useState();
  const intl = useIntl();

  const { label, id: nodeId, sitePage, originalLabel } = node || {};

  const debounceRenameLabel = debounce(value => {
    handleRenameLabel({ id: nodeId, newLabel: value });
  }, 1000);

  useEffect(() => {
    if (!label || label === "") {
      setNewLabel(null);
    } else {
      setNewLabel(label);
    }
  }, []);

  // eslint-disable-next-line no-shadow
  const getLabel = ({ sitePage, label }) => {
    if (!label || label === "") {
      return getDefaultLabel(sitePage);
    } else {
      return label;
    }
  };

  const getDefaultLabel = sitePage => {
    if (!sitePage || sitePage === "") {
      return "";
    }

    const tempLabel = intl.formatMessage({
      id: `pageKey.${sitePage}`,
    });

    if (tempLabel !== `pageKey.${sitePage}`) {
      return tempLabel;
    }
    return sitePage;
  };

  const onChangeLabel = e => {
    var value = e.target.value;
    setNewLabel(value);
    debounceRenameLabel(value.trim());
  };

  const handleRemoveClick = () => {
    handleOnRemoveSortlyMenu({ id: nodeId });
  };

  const onChangeExpand = () => {
    setExpanded(prev => !prev);
  };

  return (
    <Accordion expanded={expanded} onChange={onChangeExpand}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={clsx([classes.summary, "addedMenu"])}
      >
        <Typography className={classes.heading}>
          {newLabel?.trim() || getDefaultLabel(sitePage)}
        </Typography>
      </AccordionSummary>
      {expanded && (
        <AccordionDetails className={classes.expansionDetails}>
          <Box className={classes.expansionDetailsContainer}>
            <Typography className={classes.label}>
              <FormattedMessage id={"siteRolesMenu.navigationLabel"} />
            </Typography>
            <CustomTextField
              name="new_label"
              value={newLabel}
              onChange={onChangeLabel}
              placeholder={getDefaultLabel(sitePage)}
            />
            <Box className={classes.originalContainer}>
              <Typography
                className={clsx([classes.labelValueLabel, classes.smallText])}
              >
                <FormattedMessage id={"siteRolesMenu.original"} />:{" "}
              </Typography>
              <Typography
                className={clsx([classes.labelValueText, classes.smallText])}
              >
                {getLabel({ sitePage, label: originalLabel })}
              </Typography>
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                size="small"
                className={clsx([classes.buttonLink, classes.removeLink])}
                onClick={handleRemoveClick}
              >
                <FormattedMessage id={"siteRolesMenu.remove"} />
              </Button>
              <Button
                size="small"
                className={clsx([classes.buttonLink, classes.cancelLink])}
              >
                <FormattedMessage id={"siteRolesMenu.cancel"} />
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
}

PagesMenuItem.propTypes = {
  node: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string,
    sitePage: PropTypes.string,
  }).isRequired,
  handleOnRemoveSortlyMenu: PropTypes.func.isRequired,
  handleRenameLabel: PropTypes.func.isRequired,
};

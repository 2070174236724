import React from "react";
import { Helmet } from "react-helmet";
import { pageTitle } from "../services/config";

const PageHelmet = () => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default PageHelmet;

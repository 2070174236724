const addressFieldValidator = ({ addressSetting, fieldName, address }) => {
  if (
    addressSetting[fieldName] &&
    addressSetting[fieldName].type &&
    addressSetting[fieldName].type === "REQUIRED" &&
    (address[fieldName] === null ||
      address[fieldName] === "" ||
      address[fieldName] === undefined)
  ) {
    return true;
  }
  return false;
};

export const isFieldEmpty = value =>
  value === "" || value === null || value === undefined;

export const addressFieldsValidator = ({ address, addressSetting }) => {
  const addressFieldsError = {};
  Object.keys(addressSetting).forEach(fieldName => {
    if (
      addressFieldValidator({
        addressSetting,
        fieldName,
        address,
      })
    ) {
      addressFieldsError[fieldName] = "Required";
    }
  });

  return addressFieldsError;
};

export const validateEmail = email => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regex.test(email)) {
    return true;
  }
  return false;
};

export const objectShallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => {
    return obj2.hasOwnProperty(key) && obj1[key] === obj2[key];
  });

import { gql } from '@apollo/client';

const CREATE_NEW_PRODUCT_VERSION = gql`
  mutation CreateNewProductVersion($productId: ID!, $sourceProductId: ID!) {
    createNewProductVersion(
      productId: $productId
      sourceProductId: $sourceProductId
    ) {
      message
      success
    }
  }
`;

export default CREATE_NEW_PRODUCT_VERSION;

import { ListItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import AbcIcon from '@mui/icons-material/Abc';
import AddIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ScheduleIcon from '@mui/icons-material/Schedule';

// Define a frozen enumeration for column types
const ColumnTypes = Object.freeze({
  STRING: 0,
  NUMBER: 1,
  DATE: 2,
  STATUS: 3,
  TIME_REPORT: 4
});

const Column = ({ onClick, IconComponent, labelText, type }) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="add" onClick={() => onClick(type)}>
          <AddIcon />
        </IconButton>
      }
    >
      <ListItemIcon>
        <IconComponent />
      </ListItemIcon>
      <ListItemText>
        {labelText}
      </ListItemText>
    </ListItem>
  );
};

const StringColumn = (props) => {
  const intl = useIntl();
  return (
    <Column {...props} IconComponent={AbcIcon} labelText={intl.formatMessage({ id: "project.stringColumn" })} type={ColumnTypes.STRING} />
  );
};

const NumberColumn = (props) => {
  const intl = useIntl();
  return (
    <Column {...props} IconComponent={CalculateIcon} labelText={intl.formatMessage({ id: "project.numberColumn" })} type={ColumnTypes.NUMBER} />
  );
};

const DateColumn = (props) => {
  const intl = useIntl();
  return (
    <Column {...props} IconComponent={CalendarMonthIcon} labelText={intl.formatMessage({ id: "project.dateColumn" })} type={ColumnTypes.DATE} />
  );
};

const StatusColumn = (props) => {
  const intl = useIntl();
  return (
    <Column {...props} IconComponent={DoneAllIcon} labelText={intl.formatMessage({ id: "project.statusColumn" })} type={ColumnTypes.STATUS} />
  );
};

const TimeReportColumn = (props) => {
  const intl = useIntl();
  return (
    <Column {...props} IconComponent={ScheduleIcon} labelText={intl.formatMessage({ id: "project.timeReportColumn" })} type={ColumnTypes.TIME_REPORT} />
  );
};

export { StringColumn, NumberColumn, DateColumn, StatusColumn, TimeReportColumn, ColumnTypes };
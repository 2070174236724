import React, { memo } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import TextAreaFull from "../common/FormControl/TextAreaFull";
import ButtonText from "../common/ButtonText";

const useStyles = makeStyles(theme => ({
  editIcon: {
    width: "unset",
    height: "unset",
    fontSize: 10,
    marginRight: 5,
    color: theme.palette.common.link,
    ".uq-pti-hovered &, .uq-pti-selectedResource &": {
      color: theme.palette.primary.dark,
    },
  },
  descEditBox: {
    flex: 1,
    marginBottom: 5,
    "& .MuiFormControl-marginDense": {
      marginTop: 5,
    },
    "& .MuiInputBase-input": {
      fontSize: 11,
    },
    "& .MuiInputBase-root": {
      padding: "5px 10px",
    },
  },
  btnAddEditDesc: {
    ".uq-pti-hovered &, .uq-pti-selectedResource &": {
      color: theme.palette.primary.dark,
    },
  },
  btnCancel: {
    color: theme.palette.common.link,
    fontSize: 10,
    marginTop: 5,
    marginRight: 8,
    ".uq-pti-hovered &, .uq-pti-selectedResource &": {
      color: theme.palette.primary.dark,
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: 9,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    width: "auto",
    marginTop: 5,
    marginLeft: 5,
  },
  actionDescText: {
    fontSize: 11,
  },
}));

const DescriptionEdit = ({
  description,
  handleCancel,
  handleUpdateResourceItem,
  setDescription,
  setShowEdit,
  showEdit,
  loading,
  itemCanBeUpdated,
}) => {
  const classes = useStyles();

  return (
    <>
      {!showEdit && (
        <span
          onClick={() => itemCanBeUpdated && setShowEdit(true)}
          className={classes.btnAddEditDesc}
        >
          <Icon className={clsx(["fas fa-pencil-alt", classes.editIcon])} />
          {description ? (
            <FormattedMessage id="product.editDesc" />
          ) : (
            <FormattedMessage id="product.addDesc" />
          )}
        </span>
      )}

      {showEdit && (
        <Box className={classes.descEditBox} align="right">
          <TextAreaFull
            rows="4"
            value={description}
            onChange={e => setDescription(e.target.value)}
            disabled={loading}
          />
          {/* <ButtonText
            onClick={() => handleCancel()}
            className={classes.btnCancel}
            disabled={loading}
          >
            <FormattedMessage id="btn.cancel" />
          </Button> */}
          <ButtonText
            onClick={() => handleCancel()}
            label={<FormattedMessage id="btn.cancel" />}
            btnClass={classes.btnCancel}
            disabled={loading}
          />
          <Button
            className={classes.btnSave}
            onClick={() => handleUpdateResourceItem()}
            disabled={loading}
          >
            <FormattedMessage id="btn.save" />
          </Button>
        </Box>
      )}
    </>
  );
};

DescriptionEdit.defaultProps = {};

DescriptionEdit.propTypes = {
  showEdit: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  handleUpdateResourceItem: PropTypes.func.isRequired,
  setShowEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  itemCanBeUpdated: PropTypes.bool.isRequired,
};

export default memo(DescriptionEdit);

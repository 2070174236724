import { handleActions } from "redux-actions";
import { currentWorkspace } from "../../../../actions";

const defaultState = {
  workspace: []
};

export default handleActions(
  {
    [currentWorkspace]: (state, { payload }) => {
      const { workspace } = payload;
      return {
        ...state,
        workspace
      };
    }
  },
  defaultState
);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import { initialValues } from "./helpers";

const useStyles = makeStyles(() => ({
  td: {
    padding: 4,
    fontSize: 12,
  },
  tdCb: {
    padding: "4px 0",
    paddingRight: "0 !important",
    minWidth: 18,
    "& .MuiCheckbox-root": {
      padding: 0,
      paddingBottom: 2,
    },
    "& .MuiSvgIcon-root": {
      width: "18px !important",
      height: "18px !important",
    },
  },
}));

const FolderTableItems = ({ role, siteRoleRights, setSiteRoleRights, idx }) => {
  const classes = useStyles();
  const { siteRoleName } = role;
  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    if (!isEmpty(role)) {
      // blha eslint-disable-next-line no-shadowwriteAccess
      const { readAccess, writeAccess, siteRoleId } = role;

      const tempValues = { readAccess, writeAccess, siteRoleId };
      setFormValues(tempValues);
    }
  }, [role]);

  const handleCheckbox = e => {
    const { name, checked } = e.target;
    const tempValues = [...siteRoleRights];
    tempValues[idx] = { ...tempValues[idx], [name]: checked };

    setSiteRoleRights(tempValues);
  };

  return (
    <TableRow>
      <TableCell className={classes.td}>{siteRoleName}</TableCell>
      <TableCell className={classes.tdCb} align="center">
        <CheckboxDefault
          name="writeAccess"
          value={formValues.writeAccess}
          onChange={handleCheckbox}
        />
      </TableCell>
      <TableCell className={classes.tdCb} align="center">
        <CheckboxDefault
          name="readAccess"
          value={formValues.readAccess}
          onChange={handleCheckbox}
        />
      </TableCell>
    </TableRow>
  );
};

FolderTableItems.defaultProps = {
  role: {},
};

FolderTableItems.propTypes = {
  role: PropTypes.shape({
    readAccess: PropTypes.bool,
    siteRoleName: PropTypes.string,
    writeAccess: PropTypes.bool,
    siteRoleId: PropTypes.number,
  }),
  idx: PropTypes.number.isRequired,
};

export default FolderTableItems;

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import { FormattedMessage } from "react-intl";
import { ResponsiveEllipsis } from "../../common";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 13,
    margin: "5px 0",
    textAlign: "left",
    hyphens: "auto",
    lineHeight: "1.6em",
    whiteSpace: "pre-wrap",
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
    "& div": {
      display: "inline-block",
    },
  },
  readMore: {
    color: theme.palette.common.link,
    padding: 0,
    fontSize: 11,
    marginLeft: 5,
    minWidth: "unset",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  readMoreIcon: {
    width: "unset",
    height: "unset",
    fontSize: 12,
    marginLeft: 8,
  },
}));

const ProductDescription = ({ description }) => {
  const classes = useStyles();
  const [readMore, setReadMore] = useState(false);
  const [showReadMore, setShowReadMode] = useState(false);
  const handleReflow = rleState => {
    const { clamped, text } = rleState;

    if (clamped) {
      setShowReadMode(true);
    }
  };

  return (
    <>
      <Typography className={classes.root}>
        {!readMore ? (
          <>
            <ResponsiveEllipsis
              unsafeHTML={`${description} `}
              maxLine="4"
              onReflow={handleReflow}
              // eslint-disable-next-line react/jsx-wrap-multilines

              trimRight
              basedOn="letters"
            />

            {showReadMore && (
              <Button
                className={classes.readMore}
                onClick={() => setReadMore(prev => !prev)}
              >
                {!readMore && <FormattedMessage id="product.readMore" />}
                {!readMore && (
                  <Icon
                    className={clsx(["fa fa-angle-down", classes.readMoreIcon])}
                  />
                )}
              </Button>
            )}
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </Typography>
    </>
  );
};

ProductDescription.defaultProps = {};

ProductDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ProductDescription;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  productInfo: {
    margin: 0,
  },
  dt: {
    width: "40%",
    wordBreak: "break-all",
    verticalAlign: "middle",
    fontWeight: 600,
    display: "inline-block",
    "&::after": {
      content: '":"',
    },
    fontSize: 12,
  },
  dd: {
    wordBreak: "break-all",
    verticalAlign: "middle",
    display: "inline-block",
    marginBottom: 0,
    marginLeft: 5,
    fontSize: 12,
  },
}));

const ProductStatistics = ({ downloads, shares, views }) => {
  const classes = useStyles();
  return (
    <>
      <dl className={classes.productInfo}>
        <dt className={classes.dt}>
          <FormattedMessage id="product.downloads" />
        </dt>
        <dd className={classes.dd}>{downloads}</dd>
      </dl>

      <dl className={classes.productInfo}>
        <dt className={classes.dt}>
          <FormattedMessage id="product.shares" />
        </dt>
        <dd className={classes.dd}>{shares}</dd>
      </dl>

      <dl className={classes.productInfo}>
        <dt className={classes.dt}>
          <FormattedMessage id="product.views" />
        </dt>
        <dd className={classes.dd}>{views}</dd>
      </dl>
    </>
  );
};

ProductStatistics.propTypes = {
  downloads: PropTypes.number.isRequired,
  shares: PropTypes.number.isRequired,
  views: PropTypes.number.isRequired,
};

export default ProductStatistics;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
    "& h3": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    "& p": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
  },
}));

const DashboardContainer = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};

DashboardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardContainer;

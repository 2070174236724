import React from "react";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
}));

const MonitoringTableHead = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th}>
          <TableSortLabel>
            <FormattedMessage id="shares.name" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel>
            <FormattedMessage id="monitoring.howIsTheMonitoringSent" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel>
            <FormattedMessage id="monitoring.monitoringType" />{" "}
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel>
            <FormattedMessage id="monitoring.notificationtime" />{" "}
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel>
            {" "}
            <FormattedMessage id="monitoring.numberOfRecipients" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th} />
      </TableRow>
    </TableHead>
  );
};

export default MonitoringTableHead;

import React from "react";
import { makeStyles } from "@mui/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import ListItem from "@mui/material/ListItem";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PageRoute from "../../constant/pageRouteDictionary.json";

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  listIcon: {
    minWidth: 0,
    marginRight: 8,
    color: theme.palette.component.headerColor,
    marginTop: 2,
    "& svg": {
      width: 15,
      height: 15,
    },
  },
  listText: {
    "& span": {
      color: theme.palette.component.headerColor,
      fontSize: 12,
    },
  },
}));

const MyOrderHistoryMenuItem = () => {
  const classes = useStyles();

  return (
    <ListItem
      button
      component={Link}
      className={classes.listItem}
      to={PageRoute.MY_MONITORING}
    >
      <ListItemIcon className={classes.listIcon}>
        <ChatBubbleOutline />
      </ListItemIcon>
      <FormattedMessage id="account.notifications">
        {text => <ListItemText className={classes.listText} primary={text} />}
      </FormattedMessage>
    </ListItem>
  );
};

export default MyOrderHistoryMenuItem;

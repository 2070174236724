import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import OrderingSystem from "./OrderingSystem";
import GeneralSettings from "./GeneralSettings";
import FileSettings from "./FileSettings";
import Procurement from "./Procurement";
import Booking from "./Booking";
import Monitoring from "./Monitoring";
import NewsPost from "./NewsPost";
import ManageConsent from "./ManageConsent";

const SiteRoles = ({
  colorSchemes,
  disabled,
  handleCheckbox,
  handleOpenColorSets,
  handleSelect,
  roleSubscriptionLevel,
  settingValues,
  siteRoleValues,
}) => {
  return (
    <Grid container spacing={1}>
      {settingValues.booking && (
        <Booking
          disabled={disabled}
          handleCheckbox={handleCheckbox}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}
        />
      )}

      {settingValues.manageConsent && (
        <ManageConsent disabled={disabled}
          handleCheckbox={handleCheckbox}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}/>
        )        
      }

      {settingValues.monitoring && (
        <Monitoring
          disabled={disabled}
          handleCheckbox={handleCheckbox}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}
        />
      )}

      {settingValues.newsPost && (
        <NewsPost
          disabled={disabled}
          handleCheckbox={handleCheckbox}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}
        />
      )}

      {settingValues.documentCollaboration && (
        <FileSettings
          colorSchemes={colorSchemes}
          disabled={disabled}
          handleCheckbox={handleCheckbox}
          handleOpenColorSets={handleOpenColorSets}
          handleSelect={handleSelect}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}
        />
      )}

      {settingValues.workSpaceSettings && (
        <GeneralSettings
          disabled={disabled}
          handleCheckbox={handleCheckbox}
          handleSelect={handleSelect}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}
        />
      )}

      {settingValues.orderingModule && (
        <OrderingSystem
          disabled={disabled}
          handleCheckbox={handleCheckbox}
          handleSelect={handleSelect}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}
        />
      )}

      {settingValues.procurement && (
        <Procurement
          disabled={disabled}
          handleCheckbox={handleCheckbox}
          handleSelect={handleSelect}
          roleSubscriptionLevel={roleSubscriptionLevel}
          siteRoleValues={siteRoleValues}
        />
      )}
    </Grid>
  );
};

SiteRoles.defaultProps = {
  colorSchemes: [],
  disabled: false,
  settingValues: {},
  siteRoleValues: {},
};

SiteRoles.propTypes = {
  colorSchemes: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  handleCheckbox: PropTypes.func.isRequired,
  handleOpenColorSets: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  settingValues: PropTypes.shape({
    booking: PropTypes.bool,
    documentCollaboration: PropTypes.bool,
    monitoring: PropTypes.bool,
    newsPost: PropTypes.bool,
    orderingModule: PropTypes.bool,
    procurement: PropTypes.bool,
    workSpaceSettings: PropTypes.bool,
  }),
  siteRoleValues: PropTypes.shape({}),
};

export default SiteRoles;

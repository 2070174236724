import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const TabPanel = props => {
  const { children, value, index, boxCustom, boxP, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      <Box p={boxP} className={boxCustom}>
        {children}
      </Box>
    </Typography>
  );
};

TabPanel.defaultProps = {
  boxP: 3,
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  boxCustom: PropTypes.any.isRequired,
  boxP: PropTypes.number,
  value: PropTypes.any.isRequired,
};

export default TabPanel;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { ListItemText } from "@mui/material";

const useStyles = makeStyles(() => ({
  listText: {
    "& span": {
      fontSize: 12,
    },
  },
}));

const PageItemText = ({ name }) => {
  const classes = useStyles();
  const intl = useIntl();

  const getLabel = ({ sitePage }) => {
    const tempLabel = intl.formatMessage({
      id: `pageKey.${sitePage}`,
    });

    if (tempLabel !== `pageKey.${sitePage}`) {
      return tempLabel;
    }

    return sitePage;
  };

  return (
    <ListItemText
      className={classes.listText}
      primary={getLabel({ sitePage: name })}
    />
  );
};

export default memo(PageItemText);

PageItemText.propTypes = {
  name: PropTypes.string.isRequired,
};

import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import ScheduleIcon from '@mui/icons-material/Schedule';
import PropTypes from "prop-types";


const EditorContainer = ({
  component: Component,
  value,
  onChange,
  enableEdit,
  stayInEditMode,
  placeholder,
  startIcn,
  multiline,
  style,
  inputProps,
  ...props }) => {

  const { disabled } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [selectionIndex, setSelectionIndex] = useState(null);

  // Reference to input element (InputBase) to see cursor position
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && selectionIndex !== null && enableEdit) {
      inputRef.current?.setSelectionRange(selectionIndex, selectionIndex);
    }
  }, [isEditing, selectionIndex]);

  //Calculate what char no user is clickin at on edit
  const getSelectionIndex = () => {
    if (isEditing) {
      // Get the selection range within the InputBase component
      return inputRef.current?.selectionStart;
    } else {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        return range.startOffset;
      }
      return null;
    }
  };

  const handleEditStart = (e) => {

    //Only calculate and set cursor position if enableEdit is true
    if (enableEdit && !disabled) {
      // Calculate the cursor positions index within the Typography component
      const index = getSelectionIndex();

      //Change Typograpghy to an InputBase
      setIsEditing(true);

      // Set the selection index state
      setSelectionIndex(index);
    }
  };

  const handleChange = (e) => {
    /*const newValue = e.target?.value;
    if (newValue) {
      setInternalValue(newValue);
    }*/
  };

  const handleEditEnd = (newValue, reason) => {

    //setInternalValue(newValue);   

    onChange?.(newValue, reason);

    setIsEditing(false);
  };

  const handleBlur = (e) => {
    //handleSetIsEditing(false);
    //handleEditEnd(e.target.value);
  };

  const handleDoubleClick = (e) => {
    if (enableEdit) {
      inputRef.current?.select();
      e?.stopPropagation();
    }
  };

  return (
    <Box className={isEditing ? "editingClass" : null} sx={{ display: 'flex', alignItems: 'center', ...props.sx, "&.MuiBox-root": { ...(props.sx?.["&.MuiBox-root"] || {}), }, }} onClick={handleEditStart} onDoubleClick={handleDoubleClick}>
      {startIcn && (
        <InputAdornment position="start">
          <Box sx={{ display: 'flex', alignItems: 'center', width: "12px" }}>
            <ScheduleIcon sx={{
              opacity: 0.7, fontSize: '16px',
            }} />
          </Box>
        </InputAdornment>)}
      {isEditing && enableEdit ? (
        <Component
          autoFocus
          multiline={multiline}
          {...props}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          inputRef={inputRef}
          style={{ cursor: "text", ...style }}
          sx={{ ...props.sx, }}
          inputProps={{ inputProps }}
          onDoubleClick={handleDoubleClick}
          onEditEnd={handleEditEnd}
        />
      ) : (
          <Typography
            onClick={handleEditStart}
            onDoubleClick={handleDoubleClick}
            style={{ cursor: "text", ...style }}
            sx={{ ...props.sx, }}
          >
            {value || placeholder}
          </Typography>
        )}
    </Box>);
};

export default EditorContainer;

EditorContainer.defaultProps = {
  enableEdit: false,
  startIcn: false,
};

EditorContainer.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  enableEdit: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
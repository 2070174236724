import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import BookingRowItem from "./BookingRowItem";

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 800,
    fontSize: 16,
  },
  liftLabel: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 800,
  },
}));

const AlternativeLifts = ({
  alternativeLifts,
  avialbleLiftDate,
  handleBooking,
  availableLifts,
}) => {
  const classes = useStyles();
  const locale = useSelector(state => state.ui.locale.lang);
  const { availableFrom, availableTo } = avialbleLiftDate;
  const from = moment(availableFrom)
    .locale(locale.toLowerCase())
    .format("D MMMM kk:mm");

  const to = moment(availableTo)
    .locale(locale.toLowerCase())
    .format("D MMMM kk:mm");

  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.label}>
          <FormattedMessage id="booking.noLiftAvailableForDate" />
        </Typography>
      </Grid>
      {Array.isArray(availableLifts) && availableLifts.length > 0 && (
        <>
          {availableLifts.map((lift, idx) => {
            return (
              <>
                {idx === 0 && (
                  <Box key={lift.id}>
                    <Typography className={classes.liftLabel}>
                      <FormattedMessage
                        id="booking.freeDates"
                        values={{
                          fromDate: from,
                          toDate: to,
                        }}
                      />
                    </Typography>
                  </Box>
                )}

                <Grid item xs={12} key={lift.folderId}>
                  <BookingRowItem lift={lift} handleBooking={handleBooking} />
                </Grid>
              </>
            );
          })}
        </>
      )}
      {Array.isArray(alternativeLifts) && alternativeLifts.length > 0 && (
        <>
          {alternativeLifts.map((lift, idx) => {
            return (
              <>
                {idx === 0 && (
                  <Box key={lift.id}>
                    <Typography className={classes.liftLabel}>
                      <FormattedMessage id="booking.availableLiftsOnDate" />
                    </Typography>
                  </Box>
                )}

                <Grid item xs={12} key={lift.id}>
                  <BookingRowItem lift={lift} handleBooking={handleBooking} />
                </Grid>
              </>
            );
          })}
        </>
      )}
    </>
  );
};

AlternativeLifts.defaultProps = {
  alternativeLifts: [],
  avialbleLiftDate: null,
  availableLifts: null,
};

AlternativeLifts.propTypes = {
  avialbleLiftDate: PropTypes.string,
  alternativeLifts: PropTypes.arrayOf(PropTypes.shape({})),
  handleBooking: PropTypes.func.isRequired,
  availableLifts: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AlternativeLifts;

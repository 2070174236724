import React from "react";
import PropTypes from "prop-types";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  stepLabel: {
    fontSize: 12,
    marginTop: "5px !important",
  },
}));

const MonitoringStepper = ({ activeStep, steps }) => {
  const classes = useStyles();
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      p={0}
      classes={{ root: classes.root }}
    >
      {steps.map(label => {
        const stepProps = {};
        return (
          <Step key={label} {...stepProps} disabled={false}>
            <StepLabel classes={{ label: classes.stepLabel }}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

MonitoringStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MonitoringStepper;

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Box, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

import WorkspaceLinkWidgetForm from "./widgetsForm/WorkspaceLinkWidgetForm";
import YoutubeForm from "./widgetsForm/YoutubeForm";
import LinkWidgetForm from "./widgetsForm/LinkWidgetForm";

import Appearance from "./Appearance";
import Header from "./Header";
import { WIDGET_TYPES } from "../../../constant/widgets";


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 50,
    padding: "10px 20px",
    position: "relative",
  },
  textfieldWrapper: {
    display: "flex",
    marginBottom: 8,
  },
  formWrapper: {
    marginBottom: 8,
  },
  textfield: {
    fontSize: 14,
    marginBottom: 0,
  },
  btnSave: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.gray,
    },
  },
  btnCancel: {
    backgroundColor: theme.palette.common.lightGrey,
    color: theme.palette.primary.dark,
    marginLeft: "auto",
    marginRight: "0.5em",
    "&:hover": {
      backgroundColor: theme.palette.text.lightGray,
      opacity: 0.8,
    },
  },
  btnWrapper: {
    display: "flex",
  },
}));

const WidgetConfiguration = ({
  dirtyWidgetConfiguration,
  handleCloseConfigure,
  //handleNewsSiteBackground,
  onCancelWidgetConfiguration,
  onSaveWidgetConfiguration,
  onWidgetPropertyUpdate,
  selectedWidget,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    name,
    //newsSiteBG,
  } = selectedWidget || {};

  const [selectedWorkspace, setSelectedWorkspace] = useState();

  useEffect(() => {
    if (selectedWidget && selectedWidget.type === WIDGET_TYPES.WORKSPACE) {
      if (Array.isArray(selectedWidget.workspace) && selectedWidget.workspace.length > 0) {
        setSelectedWorkspace(selectedWidget.workspace[0]);
      } else {
        setSelectedWorkspace(selectedWidget.workspace);
      }
    };
  }, [selectedWidget]);

  return (
    !!selectedWidget && (
      <Box className={classes.root}>
        <Header onClose={handleCloseConfigure} />
        <Box className={classes.formWrapper}>

          <Box className={classes.textfieldWrapper}>
            <TextField
              className={classes.textfield}
              fullWidth
              label={intl.formatMessage({ id: "widget.formInputName" })}
              margin="dense"
              name="name"
              onChange={e => onWidgetPropertyUpdate("name", e.target.value)}
              size="small"
              value={name || ""}
              variant="outlined"
            />
          </Box>

          {selectedWidget.type === WIDGET_TYPES.YOUTUBE && (
            <YoutubeForm
              onWidgetPropertyUpdate={onWidgetPropertyUpdate}
              selectedWidget={selectedWidget}
            />
          )}

          {selectedWidget.type === WIDGET_TYPES.LINK && (
            <LinkWidgetForm
              defaultIcon={"FaBookOpen"}
              onWidgetPropertyUpdate={onWidgetPropertyUpdate}
              selectedWidget={selectedWidget}
            />
          )}

          {selectedWidget.type === WIDGET_TYPES.WORKSPACE && (
              <Box className={classes.formWrapper}>
                <WorkspaceLinkWidgetForm
                  onWidgetPropertyUpdate={onWidgetPropertyUpdate}
                  workspace={selectedWorkspace}
                />
              </Box>
            )}

          <Box className={classes.formWrapper}>
            <Appearance
              onWidgetPropertyUpdate={onWidgetPropertyUpdate}
              selectedWidget={selectedWidget}
            />
          </Box>

          <Box className={classes.btnWrapper}>
            <Button
              className={classes.btnCancel}
              onClick={onCancelWidgetConfiguration}
              size="small"
            >
              <FormattedMessage id="btn.cancel" />
            </Button>
            <Button
              className={classes.btnSave}
              disabled={!dirtyWidgetConfiguration}
              onClick={onSaveWidgetConfiguration}
              size="small"
            >
              <FormattedMessage id="btn.approve" />
            </Button>
          </Box>
        </Box>
      </Box>
    )
  );
};

WidgetConfiguration.defaultProps = {
};

WidgetConfiguration.propTypes = {
  //handleNewsSiteBackground: PropTypes.func.isRequired,
  onCancelWidgetConfiguration: PropTypes.func.isRequired,
  onSaveWidgetConfiguration: PropTypes.func.isRequired,
  onWidgetPropertyUpdate: PropTypes.func.isRequired,
  selectedWidget: PropTypes.shape({
    actionBarBG: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    fontColor: PropTypes.string,
    hl: PropTypes.string,
    name: PropTypes.string,
    showTitleBar: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    videoUrl: PropTypes.string,
  }).isRequired,
};

export default WidgetConfiguration;

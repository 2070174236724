import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HelpModal from "../../HelpModal";
import { useState } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: "auto",
  },
  buttonHelp: {
    lineHeight: 0.5,
    color: theme.palette.component.navbarColor,
    fontWeight: 600,
    fontSize: 11,
  },
  icon: {
    width: "13px !important",
    height: "13px !important",
  },
}));

const NavbarHelpButton = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = event => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Button
        data-cy="help-button"
        onClick={handleClick}
        className={classes.buttonHelp}
      >
        <HelpOutlineOutlinedIcon className={classes.caret} />
      </Button>
      <HelpModal open={open} handleClose={handleClose} />
    </div>
  );
};

export default NavbarHelpButton;

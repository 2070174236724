import { gql } from '@apollo/client';
import CompanyNetworkFieldsFragment from "../../../fragments/CompanyNetworkFields";

const LIST_COMPANY_NETWORKS = gql`
  query ListCompanyNetworks($companyId: Int!) {
    companyAdminContext {
      companyNetworks {
        listCompanyNetworks(companyId: $companyId) {
          parentNetworks {
            ...CompanyNetworkFields
          }
          networks {
            ...CompanyNetworkFields
          }
        }
      }
    }
  }

  ${CompanyNetworkFieldsFragment}
`;

export default LIST_COMPANY_NETWORKS;

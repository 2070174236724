import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { SUBSCRIPTION_LEVEL } from "../../../constant/subscriptionLevel";

const textKeys = {
  [SUBSCRIPTION_LEVEL.Free]: "role.free",
  [SUBSCRIPTION_LEVEL.Mini]: "role.mini",
  [SUBSCRIPTION_LEVEL.Standard]: "role.standard",
  [SUBSCRIPTION_LEVEL.Business]: "role.business",
  [SUBSCRIPTION_LEVEL.Pro]: "role.pro",
  [SUBSCRIPTION_LEVEL.Premium]: "role.premium",
};

const SubscriptionLevelLabel = ({ subscriptionLevel }) => {
  var textKey = textKeys[subscriptionLevel];
  return (textKey && <FormattedMessage id={textKey} />) || subscriptionLevel;
};

SubscriptionLevelLabel.propTypes = {
  subscriptionLevel: PropTypes.string.isRequired,
};

export { SubscriptionLevelLabel };
export default SubscriptionLevelLabel;

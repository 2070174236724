import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";
import OrderGroup from "../fragments/OrderGroup";

const UPDATE_ITEM = gql`
  mutation UpdateItem($input: UpdateItemInput) {
    shoppingCartContext {
      updateItem(input: $input) {
        errorResult {
          ...ErrorResultFields
        }
        success
        result {
          groups {
            ...OrderGroupFragment
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${OrderGroup}
`;

export default UPDATE_ITEM;

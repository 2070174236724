// eslint-disable-next-line import/prefer-default-export
export const headers = [
  {
    name: "username",
    label: "pickingList.user",
    column: "user",
    showSearchField: true,
  },
  {
    name: "deliveryAddress1",
    label: "pickingList.department",
    column: "department",
    showSearchField: true,
  },
  {
    name: "deliveryAddress2",
    label: "pickingList.section",
    column: "section",
    showSearchField: true,
  },
  {
    name: "ordererReference",
    label: "pickingList.reference",
    column: "reference",
    showSearchField: true,
  },
  {
    name: "ordererCity",
    label: "pickingList.city",
    column: "city",
    showSearchField: true,
  },
  {
    name: "pickableItemsWeight",
    label: "pickingList.totalWeight",
    column: "pickableItemsWeight",
    showSearchField: false,
  },
  {
    name: "sumInclVat",
    label: "pickingList.sumInclVat",
    column: "sumInclVat",
    showSearchField: false,
  },
  {
    name: "pickedBy",
    label: "pickingList.pickedBy",
    column: "pickedBy",
    showSearchField: true,
  },
];

export const initialToggleHeader = {
  orderNo: false,
  orderDate: false,
  username: false,
  deliveryCity: false,
  section: false,
  ordererReference: false,
  ordererCity: false,
  pickedBy: false,
};

const initialFromDate = new Date().setDate(new Date().getDate() - 30);

export const initialsearchValues = {
  fromDate: new Date(initialFromDate),
  toDate: new Date(),
  offset: 0,
  pageSize: 25,
  sortDesc: true,
  pickingListOrderStatus: "NEW",
  sortField: "orderNo",
};

export const initialColumnValues = {
  orderNo: true,
  orderDate: true,
  section: true,
  department: true,
  user: true,
  reference: true,
  city: true,
  pickedBy: true,
  mark: true,
};

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Box,  
  List,
  Divider,
} from "@mui/material";
import CreateChannelModal from "./CreateChannelModal";
import ChannelListItem from "./ChannelListItem";
import ChannelsSkeleton from "../../Skeleton/ChannelsSkeleton";
import ShowChannelAndWorkspaces from "./ShowChannelAndWorkspaces";
import ChannelHead from "./ChannelHead";

const useStyles = makeStyles(theme => ({
  listRoot: {
    padding: 0,
  },
  headDivider: {
    margin: "15px 0 0 0",
  },
}));

const ChannelsContainer = ({
  setSiteId,
  activeChannelId,
  onChannelMessageAdded,
  onChannelSelected,
  channelsLoading,
  channels,
}) => {
  const classes = useStyles();

  const initialSiteId = useSelector(
    state => state.ui.toggleWorkspaces.workspace.id
  );
  const initialWorkspaceName = useSelector(
    state => state.ui.toggleWorkspaces.workspace.name
  );
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreateChannel, setOpenCreateChannel] = useState(false);
  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName);
  const [siteSelected, setSiteSelected] = useState(initialSiteId);
  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const open = Boolean(anchorEl);
  const settingPopoverId = open ? "settings-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCreateChannel = () => {
    setOpenCreateChannel(true);
  };
  const handleCloseCreateChannel = () => {
    setOpenCreateChannel(false);
  };

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  const handleClickSetting = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClickSite = site => {
    let found = {};
    if (site.siteId !== 0) {
      found = sites.find(x => x.id === site.siteId);
    } else {
      found = { ...site, id: 0 };
    }

    const { id, name } = found;
    setSiteSelected(id);
    setWorkspaceName(name);
    setWorkspaceSelector(false);

    setSiteId(id);
  };

  const sites = useSelector(state => state.api.currentViewer.viewer.sites);

  return (
    <>
      <Box>
        <ChannelHead
          handleClose={handleClose}
          anchorEl={anchorEl}
          open={open}
          id={settingPopoverId}
          handleOpenCreateChannel={handleOpenCreateChannel}
          handleClickSetting={handleClickSetting}
        />
      </Box>
      <ShowChannelAndWorkspaces
        workspaces={workspaces}
        workspaceName={workspaceName}
        onClick={handleOnClickSite}
        handleToggle={handleToggle}
        open={workspaceSelector}
        setOpen={setWorkspaceSelector}
      />
      <Divider className={classes.headDivider} />

      {channelsLoading ? (
        <ChannelsSkeleton />
      ) : (
        <List className={classes.listRoot}>
          {channels.map(x => (
            <ChannelListItem
              key={x.siteChannelId}
              activeChannelId={activeChannelId}
              channel={x}
              onChannelMessageAdded={onChannelMessageAdded}
              onChannelSelected={onChannelSelected}
            />
          ))}
        </List>
      )}

      <CreateChannelModal
        open={openCreateChannel}
        handleClose={handleCloseCreateChannel}
      />
    </>
  );
};

export default ChannelsContainer;

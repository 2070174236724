import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  propertyBox: {
    margin: "6px 10px",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      fontSize: 11,
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "9px 32px 9px 12px",
    },
  },
}));

const PropertyBox = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.propertyBox}>{children}</Box>;
};

PropertyBox.defaultProps = {};

PropertyBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PropertyBox;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main,
    "& span": {
      color: "#cacaca",
      marginLeft: 5,
    },
  },
}));
const MonitoringPanelTitle = ({ title, span }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.title}>
      {title} <span>{`| ${span}`}</span>
    </Typography>
  );
};

MonitoringPanelTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  span: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default MonitoringPanelTitle;

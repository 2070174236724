import { gql } from '@apollo/client';
import AddressFragment from "../fragments/Address";

const getOrderHistoryDetail = gql`
  query GetOrderHistoryDetail($orderGroupId: Int!, $orderId: Int!) {
    orderHistoryDetail(orderGroupId: $orderGroupId, orderId: $orderId) {
      comment
      costPlaceOrder
      deliveryDateWish
      orderDate
      orderId
      orderId
      status
      sumExclVat
      sumInclVat
      transportFee
      username
      deliveryAddress {
        ...AddressFields
      }
      invoiceAddress {
        ...AddressFields
      }
      ordererAddress {
        ...AddressFields
      }

      sortedAddressFields {
        deliveryAddress {
          label
          value
        }
        ordererAddress {
          label
          value
        }
        invoiceAddress {
          label
          value
        }
      }

      orderRows {
        id
        amount
        price
        product
        weight
      }
      vatAmounts {
        name
        vatAmount
        vatPercentage
      }
    }
  }

  ${AddressFragment}
`;

export default getOrderHistoryDetail;

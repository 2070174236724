import { gql } from '@apollo/client';

const DETAILED_SEARCH = gql`
  query DetailedSearch(
    $searchValuesInput: StandardSearchFieldValuesInput
    $siteId: Int!
    $searchType: SearchType!
    $tagSearchType: TagSearchType!
  ) {
    search {
      detailedSearch(
        searchFieldValues: $searchValuesInput
        siteId: $siteId
        searchType: $searchType
        tagSearchType: $tagSearchType
      ) {
        sites {
          folders {
            folderId
            folderName
          }
          siteId
          siteName
        }
        sortedProductIds {
          sortedByArticleNo
          sortedByName
          sortedByRank
        }
        sortedResourceIds {
          sortedByArticleNo
          sortedByName
          sortedByRank
        }
        tags {
          tagId
          tagName
        }
        viewSelectorSettings {
          detailedImageView
          fileView
          imageView
          productView
        }
      }
    }
  }
`;

export default DETAILED_SEARCH;

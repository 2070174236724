import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import { Box, Skeleton } from "@mui/material";
import { debounce } from "lodash";
import { DYNAMIC_TEMPLATE_EDIT_PREVIEW } from "../../../../../../../graphql/queries/DynamicTemplateEditContext";
import ProductImagePreview from "../../../../../ProductModal/ProductImagePreview";

const useStyles = makeStyles(theme => ({
  imagePreviewControls: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center",
    display: "inline-block",
    borderRadius: 3,
    boxShadow: "0 1px 5px #e8e8e8",
    padding: "8px 5px",
    position: "absolute",
    bottom: 10,
    margin: "0 auto",
    right: 15,
    width: 110,
  },
  iconButton: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    textAlign: "center",
    margin: "0 12px",
    borderRadius: 0,
    padding: 0,
  },
  skeleton: {
    borderRadius: 4,
  },
  imgWrap: {
    display: "block",
    height: "70vh",
    borderRadius: 4,
    backgroundColor: "transparent",
    margin: "0 auto",
  },
}));

const PreviewTemplate = ({ formValues, productId, usePreview }) => {
  const classes = useStyles();
  const [imageIndex, setImageIndex] = useState(0);

  const [getDynamicTemplatePreview, { data, loading, error }] = useLazyQuery(
    DYNAMIC_TEMPLATE_EDIT_PREVIEW
  );

  const debouncedGetPreview = useCallback(
    debounce((formData, usePreview) => {
      getDynamicTemplatePreview({
        variables: {
          productId,
          data: formData,
          usePreviewTemplate: usePreview,
        },
      });
    }, 800),
    []
  );

  useEffect(() => {
    const formData = Object.keys(formValues).map(key => {
      return { fieldName: key, value: formValues[key] };
    });

    debouncedGetPreview(formData, usePreview);
  }, [formValues, usePreview]);

  const handleNext = () => {
    setImageIndex(prevIndex => prevIndex + 1);
  };

  const handlePrevious = () => {
    setImageIndex(prevIndex => prevIndex - 1);
  };

  let inner;
  if (loading) {
    inner = (
      <Box className={classes.imgWrap}>
        <Skeleton
          variant="rect"
          animation="wave"
          height="100%"
          classes={{ root: classes.skeleton }}
        />
      </Box>
    );
  } else if (error) {
    inner = (
      <p>
        <FormattedMessage id="editProduct.previewNotAvailable" />
      </p>
    );
  } else if (
    !loading &&
    !error &&
    data &&
    data.dynamicTemplateEditContext &&
    Array.isArray(data.dynamicTemplateEditContext.dynamicTemplateEditPreview) &&
    data.dynamicTemplateEditContext.dynamicTemplateEditPreview
  ) {
    const dynamicPreview =
      data.dynamicTemplateEditContext.dynamicTemplateEditPreview;

    if (Array.isArray(dynamicPreview) && dynamicPreview.length > 0) {
      inner = (
        <ProductImagePreview
          imageIndex={imageIndex}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          imageUri={dynamicPreview[imageIndex]}
          alt="edit_preview_image"
          minPage={0}
          maxPage={dynamicPreview.length - 1}
          productType="DYNAMIC_TEMPLATE"
        />
      );
    } else {
      inner = (
        <p>
          <FormattedMessage id="editProduct.previewNotAvailable" />
        </p>
      );
    }
  }

  return <div>{inner}</div>;
};

PreviewTemplate.defaultProps = {
  formValues: {},
  productId: null,
};

PreviewTemplate.propTypes = {
  formValues: PropTypes.shape({}),
  productId: PropTypes.string,
  usePreview: PropTypes.bool.isRequired,
};

export default PreviewTemplate;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const RENAME_RESOURCE = gql`
  mutation RenameResource($resourceId: ID!, $newName: String) {
    renameResource(resourceId: $resourceId, newName: $newName) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }

  ${ErrorResultFragment}
`;

export default RENAME_RESOURCE;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
// import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FOLDER_LOCKED,
  FOLDER_READ_ACCESS,
  FOLDER_WRITE_ACCESS,
  RESOURCE_LOCKED,
} from "../../../../constant/errorCodes";
import { useSnackbar } from "../../../../hooks";
import EditProductModal from "../../../ProductsMainView/EditProductModal";
import MetaDataModal from "../../../ProductsMainView/FileView/MetaDataModal/Modal";
import UserContactInfoModal from "../../../common/UserContactInfoModal";
import ViewContainer from "../../../ProductsMainView/ViewContainer";
import ProductsTable from "./ProductsTable";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    flex: "1 1 auto",
  },
  rightSidebarContainer: {
    minWidth: 300,
  },
  btnLink: {
    padding: 0,
    fontSize: 13,
    textTransform: "none",
    marginLeft: 5,
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
}));

const FileView = ({
  error,
  folderId,
  isSearch,
  loading,
  openedNodes,
  openMetaDataModal,
  refetch,
  resources,
  setMetaDataSectionVisible,
  setOpenMetaDataModal,
  siteRoleRights,
}) => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const classes = useStyles();

  const [selectedResources, setSelectedResources] = useState([]);
  const [lockedByUsername, setLockedByUsername] = useState("");
  const [productEditId, setProductEditId] = useState();

  const [productEditModal, setProductEditModal] = useState(false);
  const [userContactDetails, setUserContactDetails] = useState(false);

  const handleOpenUserContactInfo = username => {
    setLockedByUsername(username);
    setUserContactDetails(true);
    snackbar.close();
  };

  const handleCloseUserContactInfo = () => {
    setLockedByUsername("");
    setUserContactDetails(false);
  };

  const handleCloseProductEditModal = () => {
    setProductEditModal(false);
  };

  const handleOpenProductEditModal = id => {
    setProductEditModal(true);
    setProductEditId(id);
  };

  //const refetchFiles = useSelector(getRefetchFiles);
  //const dispatch = useDispatch();

  // useEffect(() => {
  //   setRefreshDropzone(false);
  // }, [refreshDropzone]);

  // useEffect(() => {
  //   if (!refreshDropzone) {
  //     setRefreshDropzone(true);
  //   }
  //   if (
  //     selectedResources &&
  //     Array.isArray(selectedResources) &&
  //     selectedResources.length > 0
  //   ) {
  //     if (!metaDataSectionVisible) {
  //       setMetaDataSectionVisible(true);
  //     }
  //   } else if (metaDataSectionVisible) {
  //     setMetaDataSectionVisible(false);
  //   }
  // }, [selectedResources]);

  const selectAllResources = () => {
    setSelectedResources(resources);
  };

  const addSelectedResource = item => {
    setSelectedResources([...selectedResources, item]);
  };

  const addSelectedResources = items => {
    setSelectedResources([...selectedResources, ...items]);
  };

  const removeSelectedResource = item => {
    const temp = selectedResources.filter(x => x.id !== item.id);
    setSelectedResources(temp);
  };

  // useEffect(() => {
  //   if (refetchFiles) {
  //     refetch();
  //     dispatch(setRefetchFiles({ refetch: false }));
  //   }
  // }, [refetchFiles]);

  // useEffect(() => {
  //   if (data && data.resources && data.resources.items) {
  //     const { totalCount } = data.resources;
  //     setResources(data.resources.items);
  //     setProductTotalCount(totalCount);
  //   }
  // }, [data]);

  useEffect(() => {
    if (Array.isArray(selectedResources)) {
      let updatedSelectedResources = [];

      selectedResources.forEach(x => {
        const resource = resources.find(y => y && x && y.id === x.id);
        if (resource) {
          updatedSelectedResources = [...updatedSelectedResources, resource];
        }
      });
      setSelectedResources(updatedSelectedResources);
    }
  }, [resources]);

  useEffect(() => {
    setSelectedResources([]);
    setMetaDataSectionVisible(false);
  }, [folderId]);

  const handleError = (name, errorResult, username) => {
    const { data: errorData, errorCode } = errorResult;

    const personLink = (
      <div>
        {username},
        <Button
          className={classes.btnLink}
          onClick={() => handleOpenUserContactInfo(username)}
        >
          <FormattedMessage id="resources.clickHereToSeeUser" />
        </Button>
      </div>
    );

    const person = errorData[0].value;
    if (errorCode === RESOURCE_LOCKED) {
      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: name, person: personLink }
        )
      );
    }

    if (errorCode === FOLDER_LOCKED) {
      const lockedFolder = errorData[1].value;

      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: lockedFolder, person }
        )
      );
    }

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }
    console.error(errorData.errorMessage);
  };

  return (
    <ViewContainer isSearch={isSearch}>
      <div className={classes.root}>
        <div className={classes.mainContainer}>
          <ProductsTable
            resources={resources}
            loading={loading}
            error={error}
            selectedResources={selectedResources}
            addSelectedResource={addSelectedResource}
            removeSelectedResource={removeSelectedResource}
            setOpenMetaDataModal={setOpenMetaDataModal}
            refetchResources={refetch}
            siteRoleRights={siteRoleRights}
            setSelectedResources={setSelectedResources}
            addSelectedResources={addSelectedResources}
            folderId={folderId}
            openedNodes={openedNodes}
            handleOpenProductEditModal={handleOpenProductEditModal}
            handleError={handleError}
          />
        </div>
      </div>

      {openMetaDataModal && (
        <MetaDataModal
          open={openMetaDataModal}
          handleClose={() => setOpenMetaDataModal(false)}
          resources={resources}
          selectedResources={selectedResources}
          addSelectedResource={addSelectedResource}
          removeSelectedResource={removeSelectedResource}
          setSelectedResources={setSelectedResources}
          selectAllResources={selectAllResources}
          refetch={refetch}
          handleOpenProductEditModal={handleOpenProductEditModal}
          handleError={handleError}
        />
      )}

      {productEditModal && (
        <EditProductModal
          refetchResources={refetch}
          handleClose={handleCloseProductEditModal}
          open={productEditModal}
          resourceId={productEditId}
          folderId={folderId}
          handleError={handleError}
        />
      )}

      {userContactDetails && (
        <UserContactInfoModal
          open={userContactDetails}
          handleClose={() => handleCloseUserContactInfo()}
          username={lockedByUsername}
        />
      )}
    </ViewContainer>
  );
};

FileView.defaultProps = {
  folderId: null,
  isSearch: null,
  openedNodes: [],
  products: {},
};

FileView.propTypes = {
  folderId: PropTypes.number,
  isSearch: PropTypes.bool,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  openMetaDataModal: PropTypes.bool.isRequired,
  products: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) }),
  setMetaDataSectionVisible: PropTypes.func.isRequired,
  setOpenMetaDataModal: PropTypes.func.isRequired,
  siteRoleRights: PropTypes.shape({ fileView: PropTypes.bool }).isRequired,
};

export default FileView;

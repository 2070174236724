import { gql } from '@apollo/client';
import SiteChannelMessageConnectionFragment from "../fragments/SiteChannelMessageConnection";

const getSiteChannelMessages = gql`
  query getSiteChannelMessages(
    $channelId: Int!
    $first: Int
    $last: Int
    $after: ID
    $before: ID
    $totalCount: Int
  ) {
    siteChannels {
      channel(channelId: $channelId) {
        channelMessages(
          first: $first
          last: $last
          after: $after
          before: $before
          totalCount: $totalCount
        ) {
          ...SiteChannelMessageConnectionFragment
        }
      }
    }
  }
  ${SiteChannelMessageConnectionFragment}
`;

export default getSiteChannelMessages;

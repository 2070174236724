import { handleActions } from "redux-actions";
import { setLocale } from "../../../actions";

const defaultState = {
  lang: (function() {
    var browserLang = (
      navigator.language ||
      navigator.userLanguage ||
      "SV"
    ).slice(0, 2);
    return ["SV", "EN"].includes(browserLang) ? browserLang : "SV";
  })(),
};

export default handleActions(
  {
    [setLocale]: (state, { payload }) => {
      const { name } = payload;
      return {
        ...state,
        lang: name,
      };
    },
  },
  defaultState
);

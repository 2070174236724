import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const UPDATE_COMPANY_ADDRESS_SETTINGS = gql`
  mutation UpdateCompanyAddressSettings($input: CompanyAdddressSettingsInput) {
    companyAdminContext {
      companyAddressSettings {
        updateCompanyAddressSettings(input: $input) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_COMPANY_ADDRESS_SETTINGS;

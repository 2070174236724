import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Typography, Grid, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../../common/TextField";
import CustomSelect from "../../common/CustomSelect";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  text: {
    fontSize: 12,
    marginTop: 10,
  },
  selectWrap: {
    "& $label": {
      marginBottom: 8,
    },
    "& .MuiSelect-select": {
      padding: "8px 12px",
    },
  },
}));

const PrintViewModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title="Print view properties"
      />
      <DraggableModalBody>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12} md={6} className={classes.selectWrap}>
            <Typography className={classes.label}>Product</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.selectWrap}>
            <Typography className={classes.label}>Post processing</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.selectWrap}>
            <Typography className={classes.label}>
              Item number (Printfish)
            </Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.label}>Format</Typography>
            <CustomTextField />
          </Grid>
          <Grid item xs={12} md={6} className={classes.selectWrap}>
            <Typography className={classes.label}>Color, inlay</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.label}>Weight, inlay</Typography>
            <CustomTextField />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.label}>
              Number of pages, submissions
            </Typography>
            <CustomTextField />
          </Grid>
          <Grid item xs={12} md={6} className={classes.selectWrap}>
            <Typography className={classes.label}>Paper, inlay</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.selectWrap}>
            <Typography className={classes.label}>Color, cover</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.label}>Weight, cover</Typography>
            <CustomTextField />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.label}>
              Number of pages, cover
            </Typography>
            <CustomTextField />
          </Grid>
          <Grid item xs={12} md={6} className={classes.selectWrap}>
            <Typography className={classes.label}>Paper, cover</Typography>
            <CustomSelect options={[{ label: "Default", value: 1 }]} />
          </Grid>
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.saveChanges" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

PrintViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PrintViewModal;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Box, Button, IconButton, Checkbox, CircularProgress, Grid, Typography, CardActions } from "@mui/material";
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';



import { makeStyles } from "@mui/styles";


import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import AuthenticatorSetupContent from "../UserDetails/AccountSettingsModal/SecurityTab/AuthenticatorSetupContent";
import ActionStrip from "../UserDetails/AccountSettingsModal/SecurityTab/ActionStrip";
import useAuthenticatorSetup from "../../hooks/MFA/useAuthenticatorSetup";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSnackbar } from "../../hooks";


const useStyles = makeStyles(theme => ({
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headSubTitleItalic: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'italic',
  },
  headMarginBottom: {
    marginBottom: "10px",
  }
}));

const ForceMFASetup = ({
  allowAbort = false,
  onAbort,
  onMfaSetupComplete,
  username,
}) => {

  const snackbar = useSnackbar();
  const classes = useStyles();
  const intl = useIntl();

  const [activeStep, setActiveStep] = useState(0);

  const { secret,
    loading,
    verifyloading,
    updateLoading,
    verifyResult,
    updateResult,
    verifyError,
    updateError,
    handleVerifySecret,
    handleUpdateSecret, } = useAuthenticatorSetup();

  const [otp, setOtp] = useState("");

  useEffect(() => {

    if (verifyResult) {
      setActiveStep(2);
    }

  }, [verifyResult]);

  useEffect(() => {

    if (updateResult) {
      snackbar.success("MFA iordningställd");
      onMfaSetupComplete();
    }

  }, [updateResult])

  const handleComplete = () => {
    handleUpdateSecret();
  };

  const handleAbort = () => {
    setActiveStep(0);
    onAbort?.();
  };

  const handleVerify = () => {
    handleVerifySecret(otp);
  };

  return (
    <>
      <Box className={classes.headMarginBottom} xs={2}>
        <Typography className={classes.headTitle}>Verifiera din identitet</Typography>
        <Typography className={classes.headSubTitle}>Tvåfaktorsautentisering har aktiverats för att skydda ditt konto. Därför krävs det att du verifierar din identitet via din autentiseringsapp på din mobila enhet.</Typography>
      </Box>
      <Card>
        <CardContent>
          <AuthenticatorSetupContent
            activeStep={activeStep}
            username={username}
            secret={secret}
            otp={otp}
            onOtpChange={setOtp}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end", padding: "16px 32px 16px 16px"}}>
          <ActionStrip
            allowAbort={allowAbort}
            onAbort={handleAbort}
            onComplete={handleComplete}
            onVerify={handleVerify}
            activeStep={activeStep}
            verifyloading={verifyloading}
            onSetActiveStep={setActiveStep} />
        </CardActions>
      </Card>

      <Alert severity="info" sx={{ marginTop: "20px" }}>
        <AlertTitle>Om du inte kan fortsätta, eller om du har frågor, kontakta</AlertTitle>
        <Typography>Wilma Support</Typography>
        {/*<Typography>Burt Von Bolton</Typography>*/}
        {/*<Typography>Mobil: 0704-922200</Typography>*/}
        <Typography>E-post: wilma@kommunal.se</Typography>
      </Alert>
    </>
  );
};

export default ForceMFASetup;

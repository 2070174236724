import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Dialog from "@mui/material/Dialog";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: 1200,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: 20,
  },
  tourIconWrapper: {
    display: "flex",
    flex: 1,
    marginRight: 8,
  },
  tourIconButton: {
    marginLeft: "auto",
    cursor: "pointer",
    padding: 4,
    color: theme.palette.common.white,
    "& span": {
      fontSize: 16,
    },
  },
  toolTipIcon: {
    marginLeft: 7,
  },
  toolTipInfoText: {
    fontSize: 12,
  },
}));

const InfoModal = ({ open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} customClass={classes.modal}>
      <DraggableModalHead handleClose={handleClose}>
        <FormattedMessage id="settings.general" />
      </DraggableModalHead>
      <DraggableModalBody customClass={classes.modalBody}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.toolTipInfoText}>
              <FormattedMessage id="booking.info" />
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography
              className={classes.toolTipInfoText}
              style={{ fontWeight: 800 }}
            >
              <FormattedMessage id="booking.yellow" />
            </Typography>
            :
            <Typography
              className={classes.toolTipInfoText}
              style={{ marginLeft: 5 }}
            >
              <FormattedMessage id="booking.bookedPartDay" />
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Typography
              className={classes.toolTipInfoText}
              style={{ fontWeight: 800 }}
            >
              <FormattedMessage id="booking.red" />
            </Typography>
            :
            <Typography
              className={classes.toolTipInfoText}
              style={{ marginLeft: 5 }}
            >
              <FormattedMessage id="booking.bookedAllDay" />
            </Typography>
          </Grid>
        </Grid>
      </DraggableModalBody>
    </Dialog>
  );
};

export default InfoModal;

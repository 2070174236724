import { gql } from '@apollo/client';

const getStockSettings = gql`
  query GetStockSettings($resourceId: ID!) {
    productEdit {
      getStockSettings(resourceId: $resourceId) {
        allowNegativeStockBalance
        productDescription
        productName
        refillEmail
        refillThreshold
        stockBalance
        stockDescription
        stockLocation
        temporaryOutOfStock
      }
    }
  }
`;

export default getStockSettings;

import { gql } from '@apollo/client';

const getAvailableProducts = gql`
  query GetAvailableProducts(
    $productGroupfolderId: Int!
    $from: DateTime!
    $to: DateTime!
  ) {
    bookingContext {
      getAvailableProducts(
        productGroupfolderId: $productGroupfolderId
        from: $from
        to: $to
      ) {
        alternativeProducts {
          name
          weight
          description
          productImageUri
          thumbnailImageUri
          id
          customMetadata {
            display
            label
            value
          }
        }
        availableFrom
        availableTo
        product {
          name
          weight
          description
          productImageUri
          thumbnailImageUri
          id
          customMetadata {
            display
            label
            value
          }
        }
        status
      }
    }
  }
`;

export default getAvailableProducts;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Footer from "./Footer";
import AddMenuButton from "./AddMenuButton";
import { MENU_TYPES } from "../../../../constant";

const { MENU_GROUP } = MENU_TYPES;

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  btnAdd: {
    padding: "5px 6px 5px 10px",
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
      opacity: 0.8,
    },
  },
  sidebarCustom: {
    padding: 10,
  },
  expansionDetails: {
    padding: 0,
  },
  expansionDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

export default function CategoriesPanel({ handleAddToMenu }) {
  const classes = useStyles();
  const intl = useIntl();

  const [isChecked, setIsChecked] = useState(false);

  const onChange = e => {
    const { checked } = e.target;

    setIsChecked(checked);
  };

  const onClickSubmit = () => {
    handleAddToMenu({ menuType: MENU_GROUP });
    setIsChecked(false);
  };

  return (
    <Accordion className="categoryMenu">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>
          <FormattedMessage id="siteRolesMenu.categories" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetails}>
        <Box className={classes.expansionDetailsContainer}>
          <List dense className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText
                className={classes.listText}
                primary={intl.formatMessage({
                  id: "siteRolesMenu.newCategory",
                })}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  checked={isChecked}
                  name="checkbox"
                  onChange={onChange}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <Footer>
            <Box className="saveButtonCategory">
              <AddMenuButton
                onClickSubmit={onClickSubmit}
                disabled={!isChecked}
              />
            </Box>
          </Footer>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

CategoriesPanel.propTypes = {
  handleAddToMenu: PropTypes.func.isRequired,
};

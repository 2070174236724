import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableBody,
} from "@mui/material";
import _ from "lodash";
import { useIntl } from "react-intl";
import TableBodyCell from "./TableBodyCell";
import TableHeaderCell from "./TableHeaderCell";
import TableRowLoading from "./TableRowLoading";

const SuppliersTable = ({ items, loading }) => {
  const intl = useIntl();

  const HEADERS = [
    {
      headerName: "supplierName",
      label: intl.formatMessage({
        id: "supplier.supplier",
      }),
    },
    {
      headerName: "supplierId",
      label: intl.formatMessage({
        id: "supplier.supplierId",
      }),
    },
    {
      headerName: "supplierEmail",
      label: intl.formatMessage({
        id: "supplier.supplierEmail",
      }),
    },
    {
      headerName: "status",
      label: intl.formatMessage({
        id: "supplier.status",
      }),
    },
  ];

  const [sortField, setSortField] = useState("supplierName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedItems, setSortedItems] = useState([]);

  const handleOnHeaderClick = name => {
    setSortField(name);
    setSortDirection(prev => (prev === "asc" ? "desc" : "asc"));
  };

  useEffect(() => {
    const sorted = _.orderBy(items, [sortField], [sortDirection]);
    setSortedItems(sorted);
  }, [sortField, sortDirection, items]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {HEADERS.map(x => (
            <TableHeaderCell key={x.headerName}>
              <TableSortLabel
                active={sortField === x.headerName}
                direction={sortDirection}
                onClick={() => handleOnHeaderClick(x.headerName)}
              >
                {x.label}
              </TableSortLabel>
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRowLoading />
        ) : (
          <>
            {sortedItems.map(x => (
              <TableRow
                key={x.supplierId}
                style={
                  x.status === "PENDING"
                    ? { backgroundColor: "rgba(0,0,0,0.10)" }
                    : {}
                }
              >
                <TableBodyCell>{x.supplierName}</TableBodyCell>
                <TableBodyCell>{x.supplierId}</TableBodyCell>
                <TableBodyCell>
                  {x.supplierEmail &&
                    x.supplierEmail.map((y, index) => {
                      if (index + 1 < x.supplierEmail.length) {
                        return `${y}, `;
                      }
                      return y;
                    })}
                </TableBodyCell>
                <TableBodyCell>
                  {intl.formatMessage({
                    id: `supplier.${x.status}`,
                  })}
                </TableBodyCell>
              </TableRow>
            ))}
          </>
        )}
      </TableBody>
    </Table>
  );
};

SuppliersTable.defaultProps = { loading: false };

SuppliersTable.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SuppliersTable;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, InputAdornment, IconButton, Button } from "@mui/material";
import { useIntl } from "react-intl";
import Input from "@mui/material/Input";
import Close from "@mui/icons-material/Close";
import ButtonPrimaryAltSm from "../../common/ButtonPrimaryAltSm";
import PickImageFieldModal from "./PickImageFieldModal";

const useStyles = makeStyles(() => ({
  uploadBox: { paddingTop: 10, display: "flex" },
  input: {
    display: "none",
  },
}));

const ImageField = ({
  productId,
  imageFolderid,
  formValues,
  setFormValues,
  fieldName,
  error,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [imageField, setImageField] = useState({});
  const [pickModalVisible, setPickModalVisible] = useState(false);
  const handleImageSelect = item => {
    setFormValues({
      ...formValues,
      [fieldName]: item && item.id ? item.id : "",
    });
    setImageField(item);
  };

  const handleRemoveImageField = () => {
    setImageField({});
    setFormValues({
      ...formValues,
      [fieldName]: "",
    });
  };

  const handleClosePickModal = () => {
    setPickModalVisible(false);
  };

  const handleOpenPickModal = () => {
    setPickModalVisible(true);
  };

  return (
    <Box className={classes.uploadBox}>
      <Input
        name="imageField"
        value={
          imageField && imageField.name && imageField.id ? imageField.name : ""
        }
        variant="outlined"
        onChange={() => {}}
        error={error ? error.error : false}
        style={{
          flex: 1,
          fontSize: 12,
          width: "100%",
          marginRight: 5,
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleRemoveImageField}>
              <Close />
            </IconButton>
          </InputAdornment>
        }
      />
      <ButtonPrimaryAltSm
        onClick={handleOpenPickModal}
        label={intl.formatMessage({
          id: "common.chooseImage",
        })}
      />
      <PickImageFieldModal
        open={pickModalVisible}
        handleClose={handleClosePickModal}
        handleImageSelect={handleImageSelect}
        productId={productId}
        imageFolderid={imageFolderid}
        imageField={imageField}
      />
    </Box>
  );
};

ImageField.defaultProps = {};

ImageField.propTypes = {};

export default ImageField;

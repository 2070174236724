import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_METADATA = gql`
  mutation UpdateMetadata($UpdateMetadataInputType: [UpdateMetadataInputType]) {
    updateMetadata(metadataUpdateList: $UpdateMetadataInputType) {
      errorResult {
        ...ErrorResultFields
      }
      success
      message
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_METADATA;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import PickingListTable from "./PickingListTable";
import CustomSelect from "../common/CustomSelect";
import { PICK_ORDERS } from "../../graphql/mutations/PickingListContext";
import { useLazyQuery, useMutation } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  btn: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
    alignItems: "center",
    "& .MuiFormControl-fullWidth": {
      width: "auto",
      display: "inline-block",
      marginRight: 10,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
    "& select": {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  text: {
    marginRight: 5,
  },
}));

const PickTab = ({
  loading,
  searchValues,
  setSearchValues,
  fetchPickingListOrders,
  pickingListOrders,
  showColumn,
  totalCount,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
  setSelectedOrders,
  page,
  setPage,
  onPicked,
  onOrderPrinted,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const options = [
    { label: "- Välj -", value: -1 },
    { label: "AH", value: "AH" },
    { label: "SK", value: "SK" },
    { label: "SB", value: "SB" },
    { label: "MT", value: "MT" },
    { label: "VIK", value: "VIK" },
  ];
  const [pickOrders] = useMutation(PICK_ORDERS);
  const [personPicked, setPersonPicked] = useState(-1);

  const handlePersonPicked = e => {
    const { value } = e.target;
    setPersonPicked(value);
  };

  const handlePickOrder = async () => {
    if (personPicked === -1) {
      return;
    }

    try {
      const res = await pickOrders({
        variables: {
          pickingListOrdersItemIds: selectedOrders,
          signature: personPicked,
        },
      });
      if (
        res &&
        res.data &&
        res.data.pickingListContext &&
        res.data.pickingListContext.pickOrders
      ) {
        const { success } = res.data.pickingListContext.pickOrders;

        if (success) {
          alert.success(<FormattedMessage id="pickingList.pickedOrderRow" />);
          onPicked();
        } else {
          alert.error(<FormattedMessage id="common.error" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <div>
      <PickingListTable
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        loading={loading}
        fetchPickingListOrders={fetchPickingListOrders}
        showColumn={showColumn}
        pickingListOrders={pickingListOrders}
        totalCount={totalCount}
        selectedOrders={selectedOrders}
        addSelectedOrder={addSelectedOrder}
        removeSelectedOrder={removeSelectedOrder}
        setSelectedOrders={setSelectedOrders}
        page={page}
        setPage={setPage}
        onOrderPrinted={onOrderPrinted}
      />
      <Box className={classes.footer} align="right">
        <Typography className={classes.text}>
          <FormattedMessage id="product.select" />
        </Typography>
        <CustomSelect
          options={options}
          onChange={handlePersonPicked}
          value={personPicked}
        />
        <Button
          className={classes.btn}
          onClick={handlePickOrder}
          disabled={loading || selectedOrders.length === 0 || personPicked === -1}
        >
          <FormattedMessage id="btn.picked" />
        </Button>
      </Box>
    </div>
  );
};

PickTab.defaultProps = {
  searchValues: {},
  loading: false,
  pickingListOrders: [],
  showColumn: {},
  onOrderPrinted: () => {
    if (console && "error" in console) {
      console.error("Property onOrderPrinted is not provided.");
    }
  },
};

PickTab.propTypes = {
  totalCount: PropTypes.number.isRequired,
  pickingListOrders: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  showColumn: PropTypes.shape({}),
  searchValues: PropTypes.shape({}),
  setSearchValues: PropTypes.func.isRequired,
  fetchPickingListOrders: PropTypes.func.isRequired,
  selectedOrders: PropTypes.arrayOf(PropTypes.number).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
  setSelectedOrders: PropTypes.func.isRequired,
  onPicked: PropTypes.func.isRequired,
  onOrderPrinted: PropTypes.func,
};

export default PickTab;

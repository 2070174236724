import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const MOVE_COMPANY = gql`
  mutation MoveCompany($parameters: MoveNetworkCompanyParametersInput!) {
    companyAdminContext {
      companyNetworks {
        moveCompany(parameters: $parameters) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default MOVE_COMPANY;

import { gql } from '@apollo/client';

const getResourceVersionHistory = gql`
  query GetResourceVersionHistory($resourceId: ID!) {
    resourceVersionHistory(resourceId: $resourceId) {
      resourceId
      versions {
        createdBy
        createdDate
        displayFilename
        initialOriginalFilename
        originalFilename
        resourceVersionIdentifier
        versionNumber
      }
    }
  }
`;

export default getResourceVersionHistory;

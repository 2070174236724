import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  headerText: { fontSize: 14, margin: "20px 0", textAlign: "left" },
}));

const SiteHeaderText = ({ text }) => {
  const classes = useStyles();

  return (
    <Typography align="center" className={classes.headerText}>
      {text}
    </Typography>
  );
};

SiteHeaderText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SiteHeaderText;

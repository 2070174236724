import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Icon, IconButton } from "@mui/material";
import clsx from "clsx";
import { FormattedDate, FormattedTime } from "react-intl";
import getGravatarUrl from "../../../helpers/getGravatarUrl";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    padding: "40px 0",
    borderBottom: "1px solid #E3E3E3",
    "&:first-of-type": {
      borderBottom: "none",
      paddingBottom: 0,
    },
  },
  profile: {
    width: 50,
    height: 50,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 15,
  },
  w100: {
    width: "100%",
  },
  commentInfo: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 15,
  },
  time: {
    fontSize: 12,
    color: "#888",
  },
  content: {
    fontSize: 14,
    lineHeight: "22px",
    marginBottom: 15,
  },
  social: {
    fontSize: 12,
    color: "#888",
    marginRight: 10,
  },
  iconButton: {
    padding: 4,
    color: theme.palette.text.secondary,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
  commentSocial: {
    display: "flex",
    alignItems: "center",
  },
  selectedThumb: {
    color: theme.palette.primary.light,
  },
}));

const CommentItem = ({ item, handleCommentLike }) => {
  const classes = useStyles();
  const {
    commentDate,
    dislikes,
    likes,
    text,
    userHasDislikedComment,
    userHasLikedComment,
    username,
    commentId,
  } = item || {};

  const gravatarURL = getGravatarUrl(username);

  const onDislike = () => {
    const newLike = userHasDislikedComment ? null : false;

    handleCommentLike({ commentId, like: newLike });
  };

  const onLike = () => {
    const newLike = userHasLikedComment ? null : true;

    handleCommentLike({ commentId, like: newLike });
  };

  return (
    <Box className={classes.root}>
      <img src={gravatarURL} alt="Profile" className={classes.profile} />
      <Box className={classes.w100}>
        <Box className={classes.commentInfo}>
          <Typography className={classes.name}>{username}</Typography>
          <Typography className={classes.time}>
            <FormattedDate value={commentDate} />{" "}
            <FormattedTime value={commentDate} />
          </Typography>
        </Box>
        <Box className={classes.commentContent}>
          <Typography className={classes.content}>{text}</Typography>
        </Box>
        <Box className={classes.commentSocial}>
          <Typography className={classes.social}>{likes} Likes</Typography>
          <Typography className={classes.social}>
            {dislikes} Dislikes
          </Typography>
          <IconButton className={classes.iconButton} onClick={onLike}>
            <Icon
              className={clsx([
                "fa fa-thumbs-up",
                classes.icon,
                userHasLikedComment ? classes.selectedThumb : null,
              ])}
            />
          </IconButton>
          <IconButton className={classes.iconButton} onClick={onDislike}>
            <Icon
              className={clsx([
                "fa fa-thumbs-down",
                classes.icon,
                userHasDislikedComment ? classes.selectedThumb : null,
              ])}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

CommentItem.propTypes = {
  item: PropTypes.shape({
    commentDate: PropTypes.string.isRequired,
    commentId: PropTypes.number.isRequired,
    dislikes: PropTypes.number.isRequired,
    likes: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    userHasDislikedComment: PropTypes.bool.isRequired,
    userHasLikedComment: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  handleCommentLike: PropTypes.func.isRequired,
};

export default CommentItem;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Paper, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../hooks";
import logger from "../../helpers/logger";

import { REMOVE_SITE_USER, UPDATE_SITE_USER } from "../../graphql/mutations";

import { useGetSiteUsers } from "../../hooks";
import SiteUsersTable from "../../components/SiteUsers/SiteUsersTable";

import ManageRolesModal from "../../components/SiteUsers/ManageRolesModal";

import SiteUserHeader from "./SiteUsersHeader";
import SiteSelectModal from "../../components/common/SiteSelectModal";
import { useGetSiteRoles } from "../../hooks/useGetSiteRoles";

import { FILTER_NAMES } from "../../components/SiteUsers/SiteUsersTable/filters";
import InviteUsersModals from "../../components/SiteUsers/InviteUserModals";

const { UNORDERED } = FILTER_NAMES;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },

  paperBody: {
    padding: theme.spacing(4),
  },
}));

const SiteUsers = () => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const selectedWorkspace = useSelector(
    state => state.ui.toggleWorkspaces.workspace
  );

  const [selectedSite, setSelectedSite] = useState(
    (selectedWorkspace && selectedWorkspace.id) || null
  );
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState(
    (selectedWorkspace && selectedWorkspace.name) || null
  );

  const [filter, setFilter] = useState("SHOW_ALL");

  const siteUsers = useSelector(state => state.api.siteUsers.items);
  const siteUsersLoading = useSelector(state => state.api.siteUsers.loading);

  const filterOptions = [
    {
      label: intl.formatMessage({
        id: "siteUsers.showAllUsers",
      }),
      value: "SHOW_ALL",
    },
    {
      label: intl.formatMessage({
        id: "siteUsers.showInvitedUsers",
      }),
      value: "SHOW_INVITED",
    },
    {
      label: intl.formatMessage({
        id: "siteUsers.showActiveUsers",
      }),
      value: "SHOW_ACTIVE",
    },
    {
      label: intl.formatMessage({
        id: "siteUsers.showInactiveUsers",
      }),
      value: "SHOW_INACTIVE",
    },
  ];

  const sites = useSelector(state => state.api.currentViewer.viewer.sites);

  const siteRoles = useSelector(state => state.api.siteUsers.siteRoles);
  const [sortfield, setSortfield] = useState(UNORDERED);
  const [ascending, setAscending] = useState(false);
  const [siteRolesRights, setSiteRolesRights] = useState({});

  const [siteSelect, setSiteSelect] = useState(false);
  const [openManageRoles, setOpenManageRoles] = useState(false);

  const { execute: getSiteUsers } = useGetSiteUsers({
    siteId: selectedSite,
    filter,
    ascending,
    sortfield,
  });

  const { execute: getSiteRoles } = useGetSiteRoles({
    siteId: selectedSite,
  });

  useEffect(() => {
    if (selectedSite) {
      const found = sites.find(site => site.id === selectedSite);
      const { siteRoleRights } = found;
      setSiteRolesRights(siteRoleRights);
      getSiteRoles();
    }
  }, [selectedSite]);

  useEffect(() => {
    if (selectedSite) {
      getSiteUsers();
    }
  }, [selectedSite, filter, sortfield, ascending]);

  const handleOpenManageRoles = () => {
    const canManageSiteRoles = !!(
      siteRolesRights && siteRolesRights.canManageSiteRoles
    );

    const manageRoles = !!(selectedSite && canManageSiteRoles);

    if (manageRoles) {
      setOpenManageRoles(true);
    } else {
      setOpenManageRoles(false);
    }
  };

  const options = [{ label: "Välj arbetsyta här", value: 0 }];
  sites.forEach(site => {
    options.push({
      label: site.name,
      value: site.id,
      orderGroupId: site.orderGroupId,
    });
  });

  const handleSetSiteId = site => {
    const { siteId } = site;
    if (siteId !== 0) {
      setSelectedSite(siteId);
      setSelectedWorkspaceName(site.name);
      const siteRoleRights = sites.filter(site => site.id === siteId);
      setSiteRolesRights(siteRoleRights[0].siteRoleRights);
    }
  };

  const siteRoleOptions = siteRoles.map(x => {
    return { label: x.name, value: x.siteRoleId };
  });

  const [openInviteModals, setOpenInviteModals] = useState(false);

  const handleOpenInviteUser = () => {
    setOpenInviteModals(true);
  };

  const handleCloseInviteUser = () => {
    setOpenInviteModals(false);
  };

  const [removeSiteUser] = useMutation(REMOVE_SITE_USER);
  const [updateSiteUser] = useMutation(UPDATE_SITE_USER);

  const handleRemoveSiteUser = async (siteId, id) => {
    try {
      const res = await removeSiteUser({ variables: { siteId, id } });

      if (
        res &&
        res.data &&
        res.data.removeSiteUser &&
        res.data.removeSiteUser.success
      ) {
        getSiteUsers();
        alert.success(<FormattedMessage id="siteUsers.removedUser" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="siteUsers.errorRemoveUser" />
        );
      }
    } catch (err) {
      snackbar.workspaceError(
        <FormattedMessage id="siteUsers.errorRemoveUser" />
      );
      logger.error(`Error in removing site user. Unable to send ${err}`);
    }
  };

  const handleUpdateSiteUser = async params => {
    try {
      const res = await updateSiteUser({ variables: { siteUser: params } });

      if (
        res &&
        res.data &&
        res.data.updateSiteUser &&
        res.data.updateSiteUser.success
      ) {
        alert.success(<FormattedMessage id="siteUsers.update" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="siteUsers.errorUpdate" />
        );
      }
    } catch (err) {
      snackbar.workspaceError(<FormattedMessage id="siteUsers.errorUpdate" />);
      logger.error(`Error in updating site user. Unable to send ${err}`);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <SiteUserHeader
          filter={filter}
          filterOptions={filterOptions}
          setFilter={setFilter}
          handleOpenInviteUser={handleOpenInviteUser}
          handleOpenManageRoles={handleOpenManageRoles}
          handleSetSiteId={handleSetSiteId}
          options={options}
          selectedSite={selectedSite}
          selectedWorkspaceName={selectedWorkspaceName}
          siteRolesRights={siteRolesRights}
        />
        <Box className={classes.paperBody}>
          <SiteUsersTable
            fetchSiteUsers={getSiteUsers}
            siteRoleOptions={siteRoleOptions}
            sortfield={sortfield}
            ascending={ascending}
            setSortfield={setSortfield}
            setAscending={setAscending}
            siteRolesRights={siteRolesRights}
            siteUsers={siteUsers}
            siteUsersLoading={siteUsersLoading}
            onRemoveSiteUser={handleRemoveSiteUser}
            onUpdateSiteUser={handleUpdateSiteUser}
          />
        </Box>
      </Paper>
      {siteSelect && (
        <SiteSelectModal open={siteSelect} setOpen={setSiteSelect} />
      )}

      <InviteUsersModals
        open={openInviteModals}
        onCloseDialog={handleCloseInviteUser}
        selectedSite={selectedSite}
        siteRoleOptions={siteRoleOptions}
        getSiteUsers={getSiteUsers}
        setSiteSelect={setSiteSelect}
      />

      {openManageRoles && (
        <ManageRolesModal
          open={openManageRoles}
          selectedSite={selectedSite}
          selectedWorkspaceName={selectedWorkspaceName}
          setOpenManageRoles={setOpenManageRoles}
          siteRoles={siteRoles}
          siteRoleOptions={siteRoleOptions}
        />
      )}
    </div>
  );
};

export default SiteUsers;

/* eslint-disable react/jsx-wrap-multilines */
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(theme => ({
  arrow: {
    color: "#ffffff",
  },
  tooltip: {
    position: "relative",
    backgroundColor: "#ffffff",
    color: theme.palette.primary.dark,
    fontSize: 10,
  },
  tooltipPlacementTop: {
    top: 4,
  },
  tooltipPlacementBottom: {
    bottom: 4,
  },
}));

const ToolTipArrow = ({ children, placement, title }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      // eslint-disable-next-line react/no-children-prop
      children={children}
      classes={classes}
      placement={placement}
      title={title}
    />
  );
};

ToolTipArrow.defaultProps = {
  placement: "top",
};

ToolTipArrow.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ToolTipArrow;

import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const BaseIcon = ({ color, pushRight = true, children }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ marginRight: pushRight ? "20px" : "0", minWidth: 24 }}
  >
    {children}
  </svg>
);

const ErrorIcon = () => (
  <BaseIcon color="#FF0040">
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="12" />
    <line x1="12" y1="16" x2="12" y2="16" />
  </BaseIcon>
);

const InfoIcon = () => (
  <BaseIcon color="#2E9AFE">
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="16" x2="12" y2="12" />
    <line x1="12" y1="8" x2="12" y2="8" />
  </BaseIcon>
);

const SuccessIcon = () => (
  <BaseIcon color="#31B404">
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <polyline points="22 4 12 14.01 9 11.01" />
  </BaseIcon>
);

const alertStyle = {
  backgroundColor: "#fefefe",
  border: "1px solid #ddd",
  color: "#333",
  padding: 10,
  textTransform: "none",
  borderRadius: 4,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.06)",
  fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
  width: 360,
  boxSizing: "border-box",
  fontSize: 13,
};

const buttonStyle = {
  marginLeft: 20,
  border: "none",
  cursor: "pointer",
  color: "#333",
  width: 30,
};

const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <div style={{ ...alertStyle, ...style }}>
      {options.type === "info" && <InfoIcon />}
      {options.type === "success" && <SuccessIcon />}
      {options.type === "error" && <ErrorIcon />}
      <span style={{ flex: 2 }}>{message}</span>
      <button onClick={close} style={buttonStyle}>
        <CloseIcon color="disabled" />
      </button>
    </div>
  );
};

export default AlertTemplate;

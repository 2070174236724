import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CONFIRM_MESSAGE_READ as CONFIRM_DIRECT_MESSAGE_READ } from "../../graphql/mutations/DirectMessageContext";
import { CONFIRM_MESSAGE_READ as CONFIRM_SITE_CHANNEL_MESSAGE_READ } from "../../graphql/mutations/SiteChannelContext";
import { GET_CONVERSATION } from "../../graphql/queries/DirectMessages";
import { GET_SITE_CHANNEL } from "../../graphql/queries";
import DesktopView from "./DesktopView";

import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileView from "./MobileView";

const useStyles = makeStyles(theme => ({
  root: {
    height: "calc(100vh - 98px)",
    flex: 1,
    // overflowY: "hidden",
  },

  noSiteContainer: {
    marginTop: 50,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noSiteText: { fontSize: 16, color: theme.palette.primary.main },
  columns: {
    flex: 1,
    height: "100%",
  },
}));

const Messaging = ({
  activeChannelId,
  activeConversationId,
  channels,
  channelsLoading,
  conversations,
  conversationsLoading,
  onChannelMessageAdded,
  onChannelMessagesLoaded,
  onChannelSelected,
  onConversationAdded,
  onConversationMessageAdded,
  onConversationMessagesLoaded,
  onConversationSelected,
  setSiteId,
  siteId,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [
    showAllChannelsAndWorkspaces,
    setShowAllChannelsAndWorkspaces,
  ] = useState(true);

  const onChangeShowAllChannels = e => {
    setShowAllChannelsAndWorkspaces(e.target.checked);
  };

  const [confirmDirectMessageRead] = useMutation(CONFIRM_DIRECT_MESSAGE_READ);
  const [confirmSiteChannelMessageRead] = useMutation(
    CONFIRM_SITE_CHANNEL_MESSAGE_READ
  );

  const handleConfirmDirectMessageRead = async messageId => {
    console.log("** handleConfirmDirectMessageRead", { messageId });
    try {
      const res = await confirmDirectMessageRead({ variables: { messageId } });

      if (res && res.data && res.data.directMessageContext) {
        const { confirmMessageRead: success } = res.data.directMessageContext;
        console.log("** ", { messageId, success });
        return success;
      }
    } catch (err) {
      console.error("** error >", err);
    }
    return false;
  };

  const handleConfirmSiteChannelMessageRead = async messageId => {
    console.log("** handleConfirmSiteChannelMessageRead", { messageId });
    try {
      const res = await confirmSiteChannelMessageRead({
        variables: { messageId },
      });

      if (res && res.data && res.data.siteChannelContext) {
        const { confirmMessageRead: success } = res.data.siteChannelContext;
        console.log("** ", { messageId, success });
        return success;
      }
    } catch (err) {
      console.error("** error >", err);
    }
    return false;
  };

  // ----- site channel -----

  const [channel, setChannel] = useState(null);

  const [lastCount, setLastCount] = useState(50);

  const [
    getChannel,
    { data: channelData, loading: channelLoading, error: channelError },
  ] = useLazyQuery(GET_SITE_CHANNEL, {
    variables: {
      channelId: activeChannelId,
      last: lastCount,
    },
  });

  useEffect(() => {
    console.log("** useEffect[activeChannelId]", { activeChannelId });
    if (activeChannelId > 0) {
      //setLastCount(10);
      getChannel();
    } else {
      setChannel(null);
    }
  }, [activeChannelId]);

  useEffect(() => {
    console.log("** useEffect[channelData, channelLoading, channelError]", {
      channelData,
      channelLoading,
      channelError,
    });

    if (!Number.isFinite(activeChannelId) || activeChannelId <= 0) {
      return;
    }

    if (channelError) {
      console.warn("** Could not get site channel data.");
      setChannel(null);
    } else if (channelLoading) {
      console.info("** Loading site channel data.");
    } else if (
      channelData &&
      channelData.siteChannels &&
      channelData.siteChannels.channel
    ) {
      console.info("** Site channel data loaded.");
      var loadedChannel = channelData.siteChannels.channel;
      setChannel(loadedChannel);
      onChannelMessagesLoaded({ siteChannelId: loadedChannel.siteChannelId });
    } else {
      console.warn("** Could not get site channel data.");
      setChannel(null);
    }
  }, [channelData, channelLoading, channelError]);

  const handleOnChannelMessageAdded = async ({ siteChannelId, message }) => {
    console.log("** handleOnChannelMessageAdded", {
      siteChannelId,
      message,
    });

    if (siteChannelId === activeChannelId && !!channel) {
      const alreadyExists = channel.channelMessages.items.find(
        x => x.id === message.id
      );

      if (!alreadyExists) {
        var tempItems = [...channel.channelMessages.items, message];
        var tempChannel = { ...channel };
        tempChannel.channelMessages.items = tempItems;
        setChannel(tempChannel);

        await handleConfirmSiteChannelMessageRead(message.id);
      }
    }

    await onChannelMessageAdded({ siteChannelId, message });
  };

  // -----(site channel)-----

  // ----- conversation -----

  const [conversation, setConversation] = useState(null);

  const [
    getConversation,
    {
      data: conversationData,
      loading: conversationLoading,
      error: conversationError,
    },
  ] = useLazyQuery(GET_CONVERSATION, {
    variables: {
      directMessageConversationId: activeConversationId,
    },
  });

  useEffect(() => {
    console.log("** useEffect[activeConversationId]", { activeConversationId });
    if (activeConversationId > 0) {
      getConversation();
    } else {
      setConversation(null);
    }
  }, [activeConversationId]);

  useEffect(() => {
    if (!Number.isFinite(activeConversationId) || activeConversationId <= 0) {
      return;
    }

    if (conversationError) {
      console.warn("** Could not get conversation.");
      setConversation(null);
    } else if (conversationLoading) {
      console.info("** Loading conversation.");
    } else if (
      conversationData &&
      conversationData.directMessages &&
      conversationData.directMessages.conversation
    ) {
      console.info("** Conversation loaded.");
      var loadedConversation = conversationData.directMessages.conversation;
      setConversation(loadedConversation);
      onConversationMessagesLoaded({
        directMessageConversationId:
          loadedConversation.directMessageConversationId,
      });
    } else {
      console.warn("** Could not get conversation.");
      setConversation(null);
    }
  }, [conversationData, conversationLoading, conversationError]);

  const handleOnConversationMessageAdded = async ({
    directMessageConversationId,
    update,
  }) => {
    console.log("** handleOnConversationMessageAdded", {
      directMessageConversationId,
      update,
    });

    const { node: message } = { ...update };

    if (
      directMessageConversationId === activeConversationId &&
      !!conversation
    ) {
      const alreadyExists = conversation.messages.items.find(
        x => x.id === message.id
      );

      if (!alreadyExists) {
        var tempItems = [...conversation.messages.items, message];
        var tempConversation = { ...conversation };
        tempConversation.messages.items = tempItems;
        setConversation(tempConversation);

        await handleConfirmDirectMessageRead(message.id);
      }
    }

    await onConversationMessageAdded({ directMessageConversationId, update });
  };

  // -----(conversation)-----

  if (!siteId && !showAllChannelsAndWorkspaces)
    return (
      <Box className={classes.root}>
        <Grid container spacing={0} className={classes.noSiteContainer}>
          <Typography className={classes.noSiteText}>
            <FormattedMessage id="common.pleaseSelectSiteFirst" />
          </Typography>
        </Grid>
      </Box>
    );

  return (
    <Box className={classes.root}>
      {matches ? (
        <MobileView
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          channels={channels}
          channelsLoading={channelsLoading}
          conversations={conversations}
          conversationsLoading={conversationsLoading}
          onChangeShowAllChannels={onChangeShowAllChannels}
          onChannelMessageAdded={handleOnChannelMessageAdded}
          onChannelSelected={onChannelSelected}
          onConversationAdded={onConversationAdded}
          onConversationMessageAdded={handleOnConversationMessageAdded}
          onConversationSelected={onConversationSelected}
          showAllChannelsAndWorkspaces={showAllChannelsAndWorkspaces}
          setSiteId={setSiteId}
          siteId={siteId}
          channel={channel}
          channelLoading={channelLoading}
          conversation={conversation}
          conversationLoading={conversationLoading}
        />
      ) : (
        <DesktopView
          activeChannelId={activeChannelId}
          activeConversationId={activeConversationId}
          channels={channels}
          channelsLoading={channelsLoading}
          conversations={conversations}
          conversationsLoading={conversationsLoading}
          onChangeShowAllChannels={onChangeShowAllChannels}
          onChannelMessageAdded={handleOnChannelMessageAdded}
          onChannelSelected={onChannelSelected}
          onConversationAdded={onConversationAdded}
          onConversationMessageAdded={handleOnConversationMessageAdded}
          onConversationSelected={onConversationSelected}
          showAllChannelsAndWorkspaces={showAllChannelsAndWorkspaces}
          setSiteId={setSiteId}
          siteId={siteId}
          channel={channel}
          channelLoading={channelLoading}
          conversation={conversation}
          conversationLoading={conversationLoading}
        />
      )}
    </Box>
  );
};

Messaging.defaultProps = {
  activeChannelId: null,
  activeConversationId: null,
  channels: [],
  channelsLoading: false,
  conversations: [],
  conversationsLoading: false,
  siteId: null,
};

Messaging.propTypes = {
  activeChannelId: PropTypes.number,
  activeConversationId: PropTypes.number,
  channel: PropTypes.shape({}),
  channelsLoading: PropTypes.bool.isRequired,
  conversations: PropTypes.arrayOf(PropTypes.shape({})),
  conversationsLoading: PropTypes.bool,
  onChannelMessageAdded: PropTypes.func.isRequired,
  onChannelSelected: PropTypes.func.isRequired,
  onConversationAdded: PropTypes.func.isRequired,
  onConversationMessageAdded: PropTypes.func.isRequired,
  onConversationSelected: PropTypes.func.isRequired,
  setSiteId: PropTypes.func.isRequired,
  siteId: PropTypes.number,
};

export default Messaging;

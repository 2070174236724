import { gql } from '@apollo/client';
import Task from "../../fragments/Projects/Task";
import Project from "../../fragments/Projects/Project";
import ErrorResultFragment from "../../fragments/ErrorResult";

const CREATE_PROJECT = gql`mutation createProject($project: ProjectCreateInput) {
	projects {
		createProject(project: $project) {
			success
			errorResult {
				...ErrorResultFields
			}
			project {
				...Project
			}
		}
	}
}
${Project}
${ErrorResultFragment}
`;

export default CREATE_PROJECT;

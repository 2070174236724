import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer as MuiDrawer, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const drawerWidth = 400;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: 0,
  },
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 0),
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginRight: open ? "0px" : "-8px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const TaskDetailsDrawer = ({ children, show, onClose, onShowDetailsModal }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(show);

  useEffect(() => {
    if (open !== show) {
      setOpen(show);
    }
  }, [show]);

  const handleDrawerClose = () => {
    onClose?.();
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: theme.palette.background.cream }}>
      <Drawer variant="permanent"
        anchor="right"
        open={open}
        PaperProps={{
          elevation: 1,
          square: false,
          sx: {
            boxShadow: '-5px 5px 5px 0px rgba(0, 0, 0, 0.2)',
            height: 'calc(100vh - 112px)',
            top: "104px",
          },
        }}>
        <DrawerHeader open={open} className="MuiDrawerHeader">
          
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon sx={{ opacity: 0.4, "&:hover": { opacity: 1 } }} />
          </IconButton>
          <IconButton onClick={onShowDetailsModal}><OpenInNewIcon sx={{ opacity: 0.4, "&:hover": { opacity: 1 } }} /></IconButton>
        </DrawerHeader>

        <Box sx={{ margin: "8px", marginTop: 0, whiteSpace: "normal" }}>
          
          {children}
        </Box>
      </Drawer>
    </Box>
  );
};

export default TaskDetailsDrawer;

import React, { memo } from "react";
import { useIntl } from "react-intl";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: 5,
    lineHeight: "normal",
  },
}));

const HelpUserTableHeader = () => {
  const classes = useStyles();
  const intl = useIntl();

  const headers = [
    {
      name: intl.formatMessage({
        id: "support.email",
      }),
    },
    {
      name: intl.formatMessage({
        id: "siteUsers.companyId",
      }),
    },
    {
      name: intl.formatMessage({
        id: "sorting.company",
      }),
    },
    {
      name: intl.formatMessage({
        id: "sorting.siteRole",
      }),
    },
    {
      name: intl.formatMessage({
        id: "sorting.payingForItself",
      }),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers.map((header, idx) => {
          return (
            <TableCell
              key={idx}
              className={classes.th}
              align={idx + 1 === headers.length ? "right" : undefined}
            >
              {header.name}
            </TableCell>
          );
        })}
        <TableCell className={classes.th} />
      </TableRow>
    </TableHead>
  );
};

export default memo(HelpUserTableHeader);

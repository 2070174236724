import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import SelectInline from "../../common/FormControl/SelectInline";

const useStyles = makeStyles(theme => ({
  cbWrap: {
    paddingLeft: 25,
    "& .MuiSelect-select": {
      padding: "4px 12px",
      fontSize: 10,
    },
  },
  infoIcon: {
    color: theme.palette.common.link,
  },
}));

const FileFormat = ({
  setShowFileFormat,
  showFileFormat,
  selectedFileFormat,
  setSelectedFormat,
  fileFormatOptions,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.cbWrap}>
      <CheckboxDefault
        label={<FormattedMessage id="imageBasket.fileFormat" />}
        onChange={() => setShowFileFormat(prevCheck => !prevCheck)}
        value={showFileFormat}
      />
      {showFileFormat && (
        <SelectInline
          label=""
          value={selectedFileFormat}
          options={fileFormatOptions}
          onChange={e => setSelectedFormat(e.target.value)}
        />
      )}
    </Box>
  );
};

FileFormat.propTypes = {
  setShowFileFormat: PropTypes.func.isRequired,
  showFileFormat: PropTypes.bool.isRequired,
  selectedFileFormat: PropTypes.string.isRequired,
  setSelectedFormat: PropTypes.func.isRequired,
  fileFormatOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default FileFormat;

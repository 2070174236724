import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  formControl: {
    color: "#66615b",
    "& .MuiOutlinedInput-input": {
      padding: "9px 12px",
      fontSize: 12,
    },
  },
  smallMenuItem: {
    fontSize: 12,
  },
  addMarginTop: {
    marginTop: "10px",
  },
}));

const ContainedSelect = ({
  disabled,
  label,
  name,
  onChange,
  options,
  value,
  dataTut,
  required,
  small,
  addMargin,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={clsx([
        classes.formControl,
        addMargin ? classes.addMarginTop : "",
      ])}
      fullWidth
      required={required}
      data-tut={dataTut}
    >
      <InputLabel margin="dense">{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        name={name}
        disabled={disabled}
        fullWidth
      >
        {options.map(x => (
          <MenuItem
            value={x.value}
            key={x.value}
            className={small ? classes.smallMenuItem : ""}
          >
            {x.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ContainedSelect.defaultProps = {
  disabled: false,
  name: "",
  value: null,
  label: null,
  required: false,
  small: false,
};

ContainedSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  required: PropTypes.bool,
  small: PropTypes.bool,
};

export default ContainedSelect;

import { createSelector } from "reselect";

export const getSiteOptions = state =>
  state.api.currentViewer.viewer.sites.map(s => ({
    label: s.name,
    value: s.id,
  }));

export const getSites = state =>
  state &&
  state.api &&
  state.api.currentViewer &&
  state.api.currentViewer.viewer &&
  state.api.currentViewer.viewer.sites
    ? state.api.currentViewer.viewer.sites
    : [];

export const getSingleSite = createSelector(
  [getSites],
  (sites) => (siteId) => sites.find(x => x.id === siteId)
);

export const getCurrentUser = state =>
  state &&
  state.api &&
  state.api.currentViewer &&
  state.api.currentViewer.viewer;

export const getChannels = state =>
  state && state.api && state.api.messaging.channels;

export const getActiveChannelId = state =>
  state && state.api && state.api.messaging.activeChannelId;

export const getSiteId = state =>
  state &&
  state.ui &&
  state.ui.toggleWorkspaces &&
  state.ui.toggleWorkspaces.workspace &&
  state.ui.toggleWorkspaces.workspace.id
    ? state.ui.toggleWorkspaces.workspace.id
    : null;

export const getSelectedSiteOrderGroupId = createSelector(
  [getSites, getSiteId],
  (sites, siteId) => {
    const selectedSite = sites.find(x => x.id === siteId);
    if (selectedSite) {
      const { orderGroupId } = selectedSite;
      return orderGroupId;
    }
    return null;
  }
);

export const getActiveChannel = createSelector(
  [getChannels, getActiveChannelId],
  (channels, activeChannelId) => {
    const activeChannel = channels.find(
      x => x.siteChannelId === activeChannelId
    );
    return activeChannel || {};
  }
);

export const getCompanyLogo = state =>
  state &&
  state.api &&
  state.api.currentViewer &&
  state.api.currentViewer.viewer &&
  state.api.currentViewer.viewer.companyLogo
    ? state.api.currentViewer.viewer.companyLogo
    : "";

export const getThemeName = state =>
  state.api.currentViewer &&
  state.api.currentViewer.viewer &&
  state.api.currentViewer.viewer.defaultTheme
    ? state.api.currentViewer.viewer.defaultTheme
    : null;

export const getCurrentCompanyId = state => state.api.currentViewer.viewer.companyId;

export const getCurrentUserId = state => state.api.currentViewer.viewer.userId;

export const getCurrentUserSecurityLevel = state => state.api.currentViewer.viewer.securityLevel || 0;

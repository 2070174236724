import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";
import { Menu, Item } from "react-contexify";
import { useMutation } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmDelete from "../../common/ConfirmDeleteModal";
import CreateNameModal from "../../common/CreateNameModal";
import {
  CREATE_GROUP,
  DELETE_GROUP,
  RENAME_GROUP,
} from "../../../graphql/mutations/CompanyAdminContext";
import "react-contexify/dist/ReactContexify.min.css";

const useStyles = makeStyles(theme => ({
  contextWrap: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    fontSize: 11,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    lineHeight: "20px",
    backgroundColor: theme.palette.primary.main,
    zIndex: 9999,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "& .react-contexify__separator": {
      backgroundColor: "#fff !important",
      float: "none",
      margin: 0,
    },
  },
  contextSubMenu: {
    fontSize: 11,
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "&:hover": {
      "& > .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__submenu-arrow": {
      fontSize: 8,
    },
  },
  contextItem: {
    fontSize: 11,
    "&:hover": {
      "& .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
  },
}));

const ContextMenu = ({
  contextMenuId,
  createNewGroup,
  renameUserGroup,
  group,  
  deleteSelectedGroup,  
  companyId,
  setEditAddressModal,
  nameModal,
  setNameModal,
  openRenameModal,
  setOpenRenameModal,
  isNewRootGroupMode,
  setIsNewRootGroupMode,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const intl = useIntl();
  const [newName, setNewName] = useState("");
  const [oldName, setOldName] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  const { groupId } = group;

  const [createGroup, { loading: creatingGroup }] = useMutation(CREATE_GROUP);
  const [deleteGroup, { loading: deletingGroup }] = useMutation(DELETE_GROUP);
  const [renameGroup, { loading: renamingGroup }] = useMutation(RENAME_GROUP);

  const [nameModalDisabled, setNameModalDisabled] = useState(false);

  useEffect(() => {
    setNameModalDisabled(!newName);
  }, [newName]);

  const handleCloseNameModal = () => {
    setOldName("");
    setNewName("");
    setNameModal(false);
    setIsNewRootGroupMode(false);
  };

  const handleCloseRenameModal = () => {
    setOldName("");
    setNewName("");
    setOpenRenameModal(false);
    setIsNewRootGroupMode(false);
  };

  const handleDeleteGroup = async () => {
    try {
      const res = await deleteGroup({ variables: { groupId } });
      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.deleteGroup
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyGroupSettings.deleteGroup;

        if (success) {
          alert.success(
            <FormattedMessage id="userGroups.success.groupDeleted" />
          );
          setDeleteModal(false);
          deleteSelectedGroup();
          return;
        }
      }
      console.error("Error deleting group");
      alert.error(<FormattedMessage id="userGroups.error.couldNotDelete" />);
    } catch (err) {
      console.error("Error deleting group", err);
      alert.error(<FormattedMessage id="userGroups.error.couldNotDelete" />);
    }
  };

  const handleCreateNewGroup = async () => {
    if (groupId === 0) {
      console.info(`Invalid groupId: ${groupId}`);
      return;
    }

    try {
      const res = await createGroup({
        variables: {
          companyId,
          groupName: newName,
          parentgroupId: isNewRootGroupMode ? null : groupId || null,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.createGroup
      ) {
        const {
          success,
          result,
        } = res.data.companyAdminContext.companyGroupSettings.createGroup;

        if (success) {
          alert.success(
            <FormattedMessage id="userGroups.success.groupCreated" />
          );
          handleCloseNameModal();
          createNewGroup({ ...result, childGroupIds: [] });
          return;
        }
      }
      console.error("Error creating group");
      alert.error(<FormattedMessage id="userGroups.error.couldNotCreate" />);
    } catch (err) {
      console.error("Error creating group", err);
      alert.error(<FormattedMessage id="userGroups.error.couldNotCreate" />);
    }
  };

  const handleRenameGroup = async () => {
    if ((groupId || 0) < 1) {
      console.info(`Invalid groupId: ${groupId}`);
      return;
    }

    try {
      const res = await renameGroup({
        variables: {
          groupId,
          newName,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.renameGroup
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyGroupSettings.renameGroup;

        if (success) {
          alert.success(
            <FormattedMessage id="userGroups.success.groupRenamed" />
          );
          handleCloseRenameModal();
          renameUserGroup({ groupId, newName });
          return;
        }
      }
      console.error("Error renaming group");
      alert.error(<FormattedMessage id="userGroups.error.couldNotRename" />);
    } catch (err) {
      console.error("Error renaming group", err);
      alert.error(<FormattedMessage id="userGroups.error.couldNotRename" />);
    }
  };

  const handleRenamingGroup = () => {
    const { name } = group;

    setOldName(name);
    setNewName(name);
    setOpenRenameModal(true);
  };

  return (
    <Box>
      <Menu id={contextMenuId} className={classes.contextWrap}>
        <Item
          className={classes.contextItem}
          onClick={() => setEditAddressModal(true)}
        >
          <FormattedMessage id="context.editAddresses" />
        </Item>
        <Item
          className={classes.contextItem}
          onClick={() => setNameModal(true)}
        >
          <FormattedMessage id="userGroups.addNewGroup" />
        </Item>
        <Item className={classes.contextItem} onClick={handleRenamingGroup}>
          <FormattedMessage id="userGroups.renameGroup" />
        </Item>
        <Item
          className={classes.contextItem}
          onClick={() => setDeleteModal(true)}
        >
          <FormattedMessage id="userGroups.deleteGroup" />
        </Item>
      </Menu>

      {deleteModal && (
        <ConfirmDelete
          open={deleteModal}
          loading={deletingGroup}
          setOpen={setDeleteModal}
          onConfirmCloseModal={handleDeleteGroup}
        />
      )}

      {nameModal && (
        <CreateNameModal
          open={nameModal}
          disabled={creatingGroup || nameModalDisabled}
          title={intl.formatMessage({ id: "userGroups.addNewGroup" })}
          handleClose={handleCloseNameModal}
          placeholder={intl.formatMessage({ id: "userGroups.addNewGroup" })}
          value={newName}
          onChange={e => setNewName(e.target.value)}
          onClick={handleCreateNewGroup}
        />
      )}

      {openRenameModal && (
        <CreateNameModal
          open={openRenameModal}
          disabled={renamingGroup || nameModalDisabled}
          title={intl.formatMessage({ id: "userGroups.renameGroup" })}
          handleClose={handleCloseRenameModal}
          placeholder={oldName}
          value={newName}
          onChange={e => setNewName(e.target.value)}
          onClick={handleRenameGroup}
        />
      )}
    </Box>
  );
};

ContextMenu.defaultProps = {
  group: {},
};

ContextMenu.propTypes = {
  contextMenuId: PropTypes.string.isRequired,
  createNewGroup: PropTypes.func.isRequired,
  renameGroup: PropTypes.func.isRequired,
  group: PropTypes.shape({
    groupId: PropTypes.number,
    parentGroupId: PropTypes.number,
  }),
  handleSetEditNode: PropTypes.func.isRequired,
  deleteSelectedGroup: PropTypes.func.isRequired,
  setEditAddressModal: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  setIsNewRootGroupMode: PropTypes.func.isRequired,
  isNewRootGroupMode: PropTypes.bool.isRequired,
};

export default ContextMenu;

import { gql } from '@apollo/client';

const GET_NEWS_FOR_SITE = gql`
  query GetNewsForSite($siteId: Int!, $projectId: Int, $offset: Int!, $pageSize: Int!) {
    newsContext {
      getNewsForSite(siteId: $siteId, projectId: $projectId,  offset: $offset, pageSize: $pageSize) {
        author
        category
        createdDate
        id
        image
        itemType
        leadText
        site
        thumbnail
        title
        viewCount
        newsPost
      }
    }
  }
`;

export default GET_NEWS_FOR_SITE;

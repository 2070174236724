import { withStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";

const PinkSwitch = withStyles(theme => ({
  switchBase: {
    "&$checked": {
      color: theme.palette.component.detailedSearchSwitch,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.component.detailedSearchSwitch,
    },
  },
  checked: { color: theme.palette.component.detailedSearchSwitch },
  track: {
    "&$checked + $track": {
      backgroundColor: theme.palette.component.detailedSearchSwitch,
    },
  },
}))(Switch);

export default PinkSwitch;

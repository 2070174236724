import { gql } from '@apollo/client';

const getUnavailableDays = gql`
  query GetUnavailableDays(
    $productGroupfolderId: Int!
    $startOfPeriod: DateTime!
    $endOfPeriod: DateTime!
  ) {
    bookingContext {
      getUnavailableDays(
        productGroupfolderId: $productGroupfolderId
        startOfPeriod: $startOfPeriod
        endOfPeriod: $endOfPeriod
      ) {
        partiallyUnavailableDays
        totallyUnavailableDays
        unavailableDays {
          day
          intervals {
            from
            to
          }
        }
      }
    }
  }
`;

export default getUnavailableDays;

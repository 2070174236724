import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";
import SecuritySettingsFragment from "../../../fragments/CompanyAdminContext/SecuritySettings";

const UPDATE_COMPANY_SECURITY_SETTINGS = gql`
  mutation GetCompanySecuritySettings(
    $securitySettings: SecuritySettingsInput!
  ) {
    companyAdminContext {
      settings {
        updateCompanySecuritySettings(securitySettings: $securitySettings) {
          success
          errorResult {
            ...ErrorResultFields
          }
          securitySettings {
            ...SecuritySettingsFields
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${SecuritySettingsFragment}
`;

export default UPDATE_COMPANY_SECURITY_SETTINGS;

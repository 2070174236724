import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import WarningImage from "../../assets/img/warning-icon.svg";
import { ClearButton, PrimaryButton } from "../common/Buttons";
import DraggableModal from "../common/DraggableModal";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";

const useStyles = makeStyles(theme => ({
  bodyContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  img: {
    marginBottom: 10,
    width: 40,
  },
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    borderTop: "1px solid #ddd",
    marginTop: 20,
    padding: "10px 0 0 0",
  },
}));

const ConfirmRemoveWidgetModal = ({ onCancel, onConfirm }) => {
  const classes = useStyles();

  const handleCancel = () => onCancel?.();
  const handleConfirm = () => onConfirm?.();

  return (
    <DraggableModal
      customClass={classes.modal}
      handleClose={handleCancel}
      open={true}
    >
      <DraggableModalHead handleClose={handleCancel} />
      <DraggableModalBody>
        <Box className={classes.bodyContainer}>
          <img alt="Warning" className={classes.img} src={WarningImage} />
          <FormattedMessage id="widget.removeItem" />
        </Box>
        <Box align="right" className={classes.modalFooter}>
          <ClearButton marginRight={15} onClick={handleCancel}>
            <FormattedMessage id="common.cancel" />
          </ClearButton>
          <PrimaryButton onClick={handleConfirm}>
            <FormattedMessage id="common.remove" />
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmRemoveWidgetModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmRemoveWidgetModal;

import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from "@mui/icons-material/Settings";

const ActionBar = ({
  disableConfigure,
  disabled,
  disableRemove,
  index,
  layout,
  onConfigure,
  onRemove,
  onSetShowTextEditor,
  showActions,
  showTextEditIcon,
}) => {
  const {
    actionBarBG,
    borderRadiusTopLeft,
    borderRadiusTopRight,
    i,
    name,
    title,
  } = layout || {};

  const showEditor = () => {
    onSetShowTextEditor(true);
    onConfigure(layout, index)
  };

  const titleMap = {
    "admin.workspaces": "widget.myWorkspaces",
    "common.news": "widget.news",
    "newsPost.newsPost": "widget.newsPost",
  };

  return (
    <Box
      className="widgetBar"
      style={{
        background: actionBarBG,
        paddingLeft:
          borderRadiusTopLeft >= 20 ? `${borderRadiusTopLeft - 10}px` : "4px",
      }}
    >
      <Box className="widgetTitle">
        {name || <FormattedMessage id={titleMap[title] || title} />}
      </Box>
      {showActions && (
        <>
          <Box className="widgetConfig">
            {showTextEditIcon && (<IconButton onClick={() => showEditor() } size="small"><EditIcon /></IconButton>)}
            <IconButton
              disabled={disabled || disableConfigure}
              onClick={() => onConfigure(layout, index)}
              size="small"
            >
              <SettingsIcon />
            </IconButton>
          </Box>
          <Box
            className="widgetRemove"
            style={{
              paddingRight:
                borderRadiusTopLeft >= 20
                  ? `${borderRadiusTopLeft -
                      10 +
                      (borderRadiusTopRight >= 20
                        ? borderRadiusTopRight / 2
                        : 4)}px`
                  : "4px",
            }}
          >
            <IconButton
              disabled={disabled || disableRemove}
              onClick={() => onRemove(index, i)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ActionBar;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  skeletonWrap: {
    backgroundColor: "#fff",
    padding: "15px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  skeleton: {
    borderRadius: 4,
  },
}));

const TableSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="100%"
          height={15}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      </Box>
    </>
  );
};

export default TableSkeleton;

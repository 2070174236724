import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Table } from "@mui/material";
import TableHeader from "./TableHeader";
import TableBodyList from "./TableBody";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #f1f1f1",
  },
}));

const NewsListTable = ({ newsList }) => {
  const classes = useStyles();

  return (
    <Table className={classes.root}>
      <TableHeader />
      {newsList.map(news => (
        <TableBodyList key={news.id} news={news} />
      ))}
    </Table>
  );
};

NewsListTable.defaultProps = {
  newsList: [],
};

NewsListTable.propTypes = {
  newsList: PropTypes.arrayOf({}),
};

export default NewsListTable;

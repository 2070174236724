import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  leftIconContainer: {
    marginRight: 15,
  },
  actionsContainer: {
    marginLeft: 10,
  },
}));

const RowItem = ({
  title,
  subTitle,
  leftIcon,
  name,
  displaySettings,
  visibilitySettings,
}) => {
  const classes = useStyles();
  

  if (displaySettings && !displaySettings[name]) {
    return null;
  }

  if (    
    visibilitySettings &&
    visibilitySettings[name] !== "PUBLIC"
  ) {
    return null;
  }



  return (
    <Box
      display="flex"
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
    >
      <Box
        display="flex"
        alignItems="center"
        className={classes.leftIconContainer}
      >
        {leftIcon}
      </Box>
      <Box display="flex" flexDirection="column" style={{ flex: 1 }}>
        <Typography style={{ fontSize: 14 }}>{title}</Typography>
        <Typography style={{ fontSize: 12, color: "rgba(0,0,0,0.55)" }}>
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default RowItem;

RowItem.defaultProps = {
  title: {},
  subTitle: {},
};

RowItem.propTypes = {
  leftIcon: PropTypes.node.isRequired,
  title: PropTypes.node,
  subTitle: PropTypes.node,
};

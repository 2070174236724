import { gql } from '@apollo/client';

const LIST_COMPANY_USERS = gql`
  query ListCompanyUsers(
    $companyId: Int!
    $offset: Int
    $pagesize: Int
    $companyRoleId: Int
    $groupId: Int
    $searchTerm: String
  ) {
    companyAdminContext {
      users {
        listCompanyUsers(
          companyId: $companyId
          offset: $offset
          pagesize: $pagesize
          companyRoleId: $companyRoleId
          searchTerm: $searchTerm
          groupId: $groupId
        ) {
          success
          errorResult {
            errorCode
            errorMessage
            data {
              key
              value
            }
          }
          totalUserCount
          companyUsersListItems {
            userId
            username
            firstname
            lastname
            avatar
            roleName
            groupBreadcrumbs {
              groups {
                groupId
                name
              }
            }
            subscriptionLevel
          }
        }
      }
    }
  }
`;

export default LIST_COMPANY_USERS;

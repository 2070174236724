import { ModalCompact, ModalCompactBody, ModalCompactHead } from "../common";
import TaskDetailsBase from "./TaskDetails/TaskDetailsBase";
import TaskDetailsGrid from "./TaskDetails/TaskDetailsGrid";

const TaskDetailsModal = ({ open, onClose, task, currentProject, columns, disabled, enableEdit, onSave, userLookup, onTimeLogAction  }) => {

   

  return (<ModalCompact open={open}
    handleClose={onClose} fullScreen
  >
    <ModalCompactHead handleClose={onClose} title={task.name} />
    <ModalCompactBody>

      <TaskDetailsBase
        renderComponent={TaskDetailsGrid}
        columns={columns}
        currentProject={currentProject}
        disabled={disabled}
        enableEdit={enableEdit}
        item={task}
        onSave={onSave}
        userLookup={userLookup}
        onTimeLogAction={onTimeLogAction}
      />

    </ModalCompactBody>

  </ModalCompact>)
}

export default TaskDetailsModal;
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import {
  Typography,
  Grid,
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Divider,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../../common/TextField";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "800px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    "& span": {
      fontWeight: 400,
      float: "right",
    },
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  td: {
    backgroundColor: "#F7F7F7",
    borderBottomColor: theme.palette.common.white,
    padding: "7px 10px",
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: "#F5F5F5",
    height: 1,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginTop: 10,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
}));

const ShippingRatesModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="Shipping rates" />
      <DraggableModalBody>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.td}>
                    <strong>0</strong> - 100
                  </TableCell>
                  <TableCell className={classes.td} align="right">
                    75
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.td}>
                    <strong>100</strong> - 500
                  </TableCell>
                  <TableCell className={classes.td} align="right">
                    150
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.td}>
                    <strong>500</strong> - *
                  </TableCell>
                  <TableCell className={classes.td} align="right">
                    300
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography className={classes.text}>
              Prices are quoted at the initial interval. If 0 g to 100 g costs
              75 SEK, you enter 0 in the first line for weight and 75 SEK in the
              first line for price.
            </Typography>
            <Typography className={classes.text}>
              If 100 g to 500 g costs SEK 150, enter 100 and 150 in the second
              row.
            </Typography>
            <Typography className={classes.text}>
              If then 500 g and up costs 300 SEK, then you enter 500 and 300 on
              the third row.
            </Typography>
            <Typography className={classes.text}>
              If you need more rows you can click "New row". Only the rows you
              fill in will be saved
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Typography className={classes.label}>
              Weight <span>g</span>
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography className={classes.label}>Award</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <CustomTextField />
          </Grid>
          <Grid item xs={6} md={4}>
            <CustomTextField />
          </Grid>
        </Grid>
        <Button className={classes.addLink}>
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
          Add row
        </Button>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.saveChanges" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ShippingRatesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ShippingRatesModal;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPDATE_QUANTITY = gql`
  mutation UpdatePickingListQuantity($input: UpdateQuantityInput) {
    pickingListContext {
      updateQuantity(input: $input) {
        errorResult {
          ...ErrorResultFields
        }
        success
        totalWeight
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_QUANTITY;

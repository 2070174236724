import { gql } from '@apollo/client';

const GET_ADDRESS_FOR_USER = gql`
  query GetAddressForUser($userId: Int!) {
    monitoringContext {
      getAddressForUser(userId: $userId) {
        label
        userId
        deliveryAddress {
          ...AddressFields
        }
        postalAddress {
          ...AddressFields
        }
        invoiceAddress {
          ...AddressFields
        }
      }
    }
  }

  fragment AddressFields on Address {
    address1
    address2
    address3
    address4
    address5
    city
    contact
    country
    customerId
    email
    reference
    telephone
    zip
  }
`;

export default GET_ADDRESS_FOR_USER;

import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import SiteSelect from "../../../assets/img/site-select.svg";
import DraggableModal from "../DraggableModal";
import DraggableModalHead from "../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  siteSelect: {
    textAlign: "center",
    padding: "60px 0",
    marginTop: 40,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const SiteSelectModal = ({ open, setOpen }) => {
  const classes = useStyles();
  return (
    <DraggableModal
      open={open}
      handleClose={() => setOpen(false)}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={() => setOpen(false)}
        title={<FormattedMessage id="rfp.siteNeeded" />}
      />
      <DraggableModalBody>
        <Box className={classes.siteSelect}>
          <img src={SiteSelect} alt="Empty" />
          <Typography className={classes.emptyText}>
            <FormattedMessage id="rfp.siteNeeded" />
          </Typography>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

SiteSelectModal.defaultProps = {
  open: false,
  setOpen: null,
};

SiteSelectModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default SiteSelectModal;

import { gql } from '@apollo/client';

const getProductPropertyValues = gql`
  query GetProductPropertyValues(
    $identifier: Int!
    $propertyId: Int!
    $conditionalPropertyValues: [SelectedPropertyValue]
  ) {
    requestForProposal {
      productPropertyValues(
        identifier: $identifier
        propertyId: $propertyId
        conditionalPropertyValues: $conditionalPropertyValues
      ) {
        id
        name
        selected
      }
    }
  }
`;

export default getProductPropertyValues;

import React from "react";
import { makeStyles } from "@mui/styles";
import { renderEvent, renderTime } from "./EventsRenderer";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { setFileViewOpenNodes } from "../../actions";
import PageRoute from "../../constant/pageRouteDictionary.json";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  row: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: "0.2rem",
    },
  },
  centercontainer: {
    [theme.breakpoints.up("sm")]: {
      width: "800px",
      margin: "auto",
    },
  },
  column: {
    flexDirection: "row",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  heading: {
    fontWeight: "bold",
    width: "100%",
  },
  subheading: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  header: {
    fontWeight: "bold",
    padding: "0.2rem",
  },
  data: {
    padding: "0.2rem",
  },
}));

const SideBarContent = selectedNode => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const tempHandleOpenFolder = (ids, siteId) => {
    dispatch(setFileViewOpenNodes({ nodes: ids }));

    history.push(PageRoute.FILE_VIEW);
  };

  return (
    <>
      {((selectedNode.data.folderEvents &&
        selectedNode.data.folderEvents.length > 0) ||
        (selectedNode.data.resourceEvents &&
          selectedNode.data.resourceEvents.length > 0)) && (
        <Button
          label={<FormattedMessage id="monitoring.eventdetails.goToFolder" />}
          onClick={() =>
            tempHandleOpenFolder(
              selectedNode.data.folderPath,
              selectedNode.data.siteId
            )
          }
        />
      )}

      <div className={classes.centercontainer}>
        <div className={classes.row}>
          <div className={classes.row}>
            <div className={classes.heading}>
              <Typography>
                <FormattedMessage id="monitoring.eventdetails.eventsFor" />{" "}
                {selectedNode.data.name}
              </Typography>
            </div>
          </div>
        </div>
        {selectedNode.data.resourceEvents &&
          selectedNode.data.resourceEvents.map((x, index) => (
            <div className={classes.row}>
              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.eventdetails.event" />
                </div>
                <div className={classes.data}>{renderEvent(x.eventId)}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.who" />
                </div>
                <div className={classes.data}>{x.username}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.when" />
                </div>
                <div className={classes.data}>{renderTime(x.eventTime)}</div>
              </div>

              {x.resourceEvent.previousName ? (
                <div className={classes.column}>
                  <div
                    className={clsx([
                      index > 0 ? classes.subheading : "",
                      classes.header,
                    ])}
                  >
                    <FormattedMessage id="monitoring.eventdetails.previousName" />
                  </div>
                  <div className={classes.data}>
                    {x.resourceEvent.previousName}
                  </div>
                </div>
              ) : (
                <div className={classes.column}>
                  <div
                    className={clsx([
                      index > 0 ? classes.subheading : "",
                      classes.header,
                    ])}
                  >
                    <FormattedMessage id="monitoring.eventdetails.previousName" />
                  </div>
                  <div className={classes.data}>
                    {x.resourceEvent.previousName}
                  </div>
                </div>
              )}
            </div>
          ))}

        {selectedNode.data.folderEvents &&
          selectedNode.data.folderEvents.map((x, index) => (
            <div className={classes.row}>
              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.eventdetails.event" />
                </div>
                <div className={classes.data}>{renderEvent(x.eventId)}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.who" />
                </div>
                <div className={classes.data}>{x.username}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.when" />
                </div>
                <div className={classes.data}>{renderTime(x.eventTime)}</div>
              </div>
            </div>
          ))}

        {selectedNode.data.siteRoleEvents &&
          selectedNode.data.siteRoleEvents.map((x, index) => (
            <div className={classes.row}>
              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.eventdetails.event" />
                </div>
                <div className={classes.data}>{renderEvent(x.eventId)}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.who" />
                </div>
                <div className={classes.data}>{x.username}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.when" />
                </div>
                <div className={classes.data}>{renderTime(x.eventTime)}</div>
              </div>

              {
                <div className={classes.column}>
                  <div
                    className={clsx([
                      index > 0 ? classes.subheading : "",
                      classes.header,
                    ])}
                  >
                    <FormattedMessage id="monitoring.eventdetails.previousName" />
                  </div>
                  <div className={classes.data}>{x.previousName}</div>
                </div>
              }

              {
                <div className={classes.column}>
                  <div
                    className={clsx([
                      index > 0 ? classes.subheading : "",
                      classes.header,
                    ])}
                  >
                    <FormattedMessage id="monitoring.eventdetails.subscriptionLevelChanged" />
                  </div>
                  <div className={classes.data}>
                    {x.subscriptionLevelChanged ? (
                      <FormattedMessage id="btn.yes" />
                    ) : (
                      <FormattedMessage id="btn.no" />
                    )}
                  </div>
                </div>
              }
            </div>
          ))}

        {selectedNode.data.siteUserEvents &&
          selectedNode.data.siteUserEvents.map((x, index) => (
            <div className={classes.row}>
              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.eventdetails.event" />
                </div>
                <div className={classes.data}>{renderEvent(x.eventId)}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.who" />
                </div>
                <div className={classes.data}>{x.username}</div>
              </div>

              <div className={classes.column}>
                <div
                  className={clsx([
                    index > 0 ? classes.subheading : "",
                    classes.header,
                  ])}
                >
                  <FormattedMessage id="monitoring.details.when" />
                </div>
                <div className={classes.data}>{renderTime(x.eventTime)}</div>
              </div>

              {
                <div className={classes.column}>
                  <div
                    className={clsx([
                      index > 0 ? classes.subheading : "",
                      classes.header,
                    ])}
                  >
                    <FormattedMessage id="monitoring.eventdetails.previousRole" />
                  </div>
                  <div className={classes.data}>{x.previousSiteRole}</div>
                </div>
              }
            </div>
          ))}
      </div>
    </>
  );
};

export default SideBarContent;

import { webApiURL } from "../services/config";

const getCompanyMailLogoUrl = (companyId, t = 0) => {
  if (t) {
    return `${webApiURL}/CompanyMailLogo?companyId=${companyId}&t=${t}`;
  } else {
    return `${webApiURL}/CompanyMailLogo?companyId=${companyId}`;
  }
};

export default getCompanyMailLogoUrl;

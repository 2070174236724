import { gql } from '@apollo/client';

const TimeReport = gql`fragment TimeReporter on TimeReporter {
	avatar
	firstname
	lastname
	userId
	username
	__typename
}

fragment TimeReportLog on ReportedTimeDTO {
	id
	comment
	reportedAt
	reportedMinutes
	user {
		...TimeReporter
	}
}
`;

export default TimeReport;

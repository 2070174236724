import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Box, List, ListItem, ListSubheader } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import RightsCheckboxes from "./RightsCheckboxes";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 11,
    marginBottom: 5,
  },
  text: {
    fontSize: 11,
    fontWeight: 600,
    paddingRight: "6em",
  },
}));

const ResourcesList = styled(List)(({ theme }) => ({
  "& .MuiCheckbox-root": {
    padding: "3px",
  },
  "& .MuiSvgIcon-root": {
    height: "16px",
    width: "16px",
  },
  "& .MuiListItem-root": {
    minHeight: "inherit",
  },
  "& .MuiListSubheader-root": {
    color: "inherit",
    lineHeight: "inherit",
    marginBottom: "5px",
  },
}));

const ResourceRightsList = ({
  disabled,
  onChange,
  resourceRights,
  resourceRightsChanges,
  shareMode,
  sx,
}) => {
  const classes = useStyles();

  const displayValue = (value, override) => (override === undefined ? value : override);

  const handleCheckboxChange = (e, resourceId) => {
    const { name, checked } = e.target;
    onChange?.({ resourceId, name, value: checked });
  };

  return (
    <ResourcesList
      sx={sx}
      disablePadding
      subheader={
        <ListSubheader disableGutters disablePadding>
          <>
            <Box component="span" className={classes.text}>
              <FormattedMessage id="siteRoles.folderResourceRights" />
            </Box>
            <Box component="span" className={classes.label} sx={{ position: "absolute", right: 0 }}>
              <FormattedMessage id="folder.write" />
              &emsp;
              <FormattedMessage id="folder.read" />
              &ensp;
            </Box>
          </>
        </ListSubheader>
      }
    >
      {resourceRights.map(item => {
        let { resourceId, readAccess, writeAccess } = item;
        let disableWrite = shareMode && !writeAccess;
        const { read, write } = resourceRightsChanges[resourceId] || {};
        if (shareMode) {
          readAccess = false;
          writeAccess = false;
        }
        return (
          <ListItem disablePadding sx={{ paddingRight: "3em" }}>
            {item.name}
            <RightsCheckboxes
              sx={{ marginRight: "0.6em", position: "absolute", right: 0 }}
              disabled={disabled}
              disableWrite={disableWrite}
              onChange={handleCheckboxChange}
              read={displayValue(readAccess, read)}
              resourceId={resourceId}
              write={displayValue(writeAccess, write)}
            />
          </ListItem>
        );
      }
      )}
    </ResourcesList>
  );
};

ResourceRightsList.defaultProps = {
  disabled: false,
  onChange: () => { },
  resourceRights: [],
  resourceRightsChanges: {},
  shareMode: false,
};

ResourceRightsList.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  resourceRights: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    readAccess: PropTypes.bool.isRequired,
    resourceId: PropTypes.number.isRequired,
    thumbnailUri: PropTypes.string,
    writeAccess: PropTypes.bool.isRequired,
  })),
  resourceRightsChanges: PropTypes.shape(),
  shareMode: PropTypes.bool,
  sx: PropTypes.shape(),
};

export default ResourceRightsList;

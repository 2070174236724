import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";
import { QuantityField } from "../../common";

const useStyles = makeStyles(theme => ({
  qtyLabel: {
    margin: 0,
    fontSize: "0.8571em",
    marginBottom: 5,
    color: "#9A9A9A",
  },
}));

const ProductQuantity = ({
  defaultValue,
  onChange,
  onClick,
  quantity,
  selectionType,
  valuesList,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <p className={classes.qtyLabel}>
        <FormattedMessage id="product.quantity" />
      </p>
      <QuantityField
        defaultValue={defaultValue}
        onChange={onChange}
        onClick={onClick}
        quantity={quantity}
        selectionType={selectionType}
        valuesList={valuesList}
      />
    </Grid>
  );
};

ProductQuantity.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  selectionType: PropTypes.string.isRequired,
  valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ProductQuantity;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const UPDATE_EXTERNAL_USER = gql`
  mutation UpdateExternaluser(
    $targetCompanyId: Int!
    $userId: Int!
    $targetRoleId: Int!
  ) {
    companyAdminContext {
      externalUsers {
        updateExternalUser(
          targetCompanyId: $targetCompanyId
          userId: $userId
          targetRoleId: $targetRoleId
        ) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_EXTERNAL_USER;

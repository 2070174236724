import { useMutation } from "@apollo/client";
import { INITIALIZE_FOLDER_DOWNLOAD } from "../../graphql/mutations";

// eslint-disable-next-line import/prefer-default-export
export const useInitializeFolderDownload = ({ onError, onSuccess }) => {
  const [initializeFolderDownload, { loading: initializing }] = useMutation(INITIALIZE_FOLDER_DOWNLOAD);

  const onHandleInitializeFolderDownload = async ({ siteId, folderId, resourceIds }) => {
    const variables = {
      siteId,
      folderId,
      resourceIds,
    };

    try {
      const res = await initializeFolderDownload({ variables });

      const { errorResult, success, url } = res?.data?.initFolderDownload || {};

      success
        ? handleSuccess(url)
        : handleError(errorResult);

    } catch {
      handleError();
    }
  };

  function handleError(errorResult) {
    typeof onError === "function"
      ? onError(errorResult)
      : console.warn("Missing 'onError' function.");
  }

  function handleSuccess(url) {
    typeof onSuccess === "function"
      ? onSuccess(url)
      : console.warn("Missing 'onSuccess' function.");
  }

  return [onHandleInitializeFolderDownload, { initializing }];
};

import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import MonitoringPanelTitle from "./MonitoringPanelTitle";

const MonitoringPanels = ({ title, span, label, onChange, value }) => {
  return (
    <Box>
      <MonitoringPanelTitle title={title} span={span} />
      <Box>
        <CheckboxDefault label={label} onChange={onChange} value={value} />
      </Box>
    </Box>
  );
};

MonitoringPanels.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  span: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default MonitoringPanels;

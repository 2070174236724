import { PRINT_AND_MAIL } from "../constant/monitoringTypes";

export const getSelectedChildFolders = ({
  folder,
  folderArrays,
  foldersWithDiffSettings,
}) => {
  if (
    folder &&
    folder.hasChildFolders &&
    folder.childFolders &&
    folder.childFolders.length > 0
  ) {
    let childFoldersArray = [];
    folder.childFolders.forEach(x => {
      const folderDiffSettings = foldersWithDiffSettings.find(
        y => y.folderId === x.folderId
      );

      if (!folderDiffSettings) {
        const temp = getSelectedChildFolders({
          folder: x,
          folderArrays: [],
          foldersWithDiffSettings,
        });
        childFoldersArray = [...childFoldersArray, ...temp];
      }
    });

    const temp1 = [{ ...folder }, ...childFoldersArray, ...folderArrays];
    return temp1;
  }
  return [{ ...folder }, ...folderArrays];
};

export const isActivateStepThree = ({ filesFoldersSettings }) => {
  if (filesFoldersSettings) {
    const {
      fileFolderCreate,
      fileFolderDownload,
      fileFolderUpdate,
      fileFolderLockChange,
      fileFolderDelete,
    } = filesFoldersSettings;
    if (
      fileFolderCreate ||
      fileFolderDownload ||
      fileFolderUpdate ||
      fileFolderLockChange ||
      fileFolderDelete
    )
      return true;
  }

  return false;
};

export const isFilesAndFolderMonitorEnabled = ({ fileSettings }) => {
  if (fileSettings) {
    const {
      create,
      download,
      update,
      lockChange,
      delete: fileDelete,
    } = fileSettings;
    if (create || download || update || lockChange || fileDelete) return true;
  }

  return false;
};

export const isFolderMonitorEnabled = ({ filesFoldersSettings }) => {
  if (filesFoldersSettings) {
    const {
      fileFolderCreate,
      fileFolderUpdate,
      fileFolderDelete,
      fileCountEqualTo,
      fileCountGreaterThan,
    } = filesFoldersSettings;
    if (
      fileFolderCreate ||
      fileFolderUpdate ||
      fileFolderDelete ||
      fileCountEqualTo ||
      fileCountGreaterThan
    )
      return true;
  }
  return false;
};

export const getFolderProductionPropertiesValues = (
  folderProductionProperties,
  folderId
) => {
  let productionPropertiesValues = [];
  const folderProperties =
    folderProductionProperties[Number(folderId)].properties;
  if (folderProperties) {
    const folderPropertiesValues = Object.values(folderProperties);
    folderPropertiesValues.forEach(folderPropertiesValue => {
      if (Array.isArray(folderPropertiesValue.value)) {
        const selectedCheckboxes = folderPropertiesValue.value;
        const selectedCheckboxesValues = selectedCheckboxes.map(x => x.value);
        productionPropertiesValues = [
          ...productionPropertiesValues,
          ...selectedCheckboxesValues,
        ];
      } else {
        productionPropertiesValues = [
          ...productionPropertiesValues,
          folderPropertiesValue.value,
        ];
      }
    });
  }
  return productionPropertiesValues;
};

export const getFileProductionPropertiesValues = (
  folderResourceProductionProperties,
  resourceId
) => {
  let productionPropertiesValues = [];
  const resourceProperties = folderResourceProductionProperties[resourceId];
  if (resourceProperties) {
    const resourcePropertiesValues = Object.values(resourceProperties);
    resourcePropertiesValues.forEach(folderPropertiesValue => {
      if (Array.isArray(folderPropertiesValue.value)) {
        const selectedCheckboxes = folderPropertiesValue.value;
        const selectedCheckboxesValues = selectedCheckboxes.map(x => x.value);
        productionPropertiesValues = [
          ...productionPropertiesValues,
          ...selectedCheckboxesValues,
        ];
      } else if (folderPropertiesValue.value) {
        productionPropertiesValues = [
          ...productionPropertiesValues,
          folderPropertiesValue.value,
        ];
      }
    });
  }
  return productionPropertiesValues;
};

export const getMonitorFolderInput = folderProductionProperties => {
  const monitoredFolderInput = folderProductionProperties.map(x => {
    let input = { folderId: x.folderId, productionSettings: {} };

    const { productionSettings = {} } = x || {};

    input = {
      ...input,
      include: x.isNotifyEnabled || false,
      productionSettings: {
        orderQuantity: productionSettings.orderQuantity || 0,
        propertyValueIds: productionSettings.propertyValueIds || [],
      },
    };

    if (productionSettings.quantityFixedValueId) {
      input = {
        ...input,
        productionSettings: {
          ...input.productionSettings,
          quantityFixedValueId: productionSettings.quantityFixedValueId || null,
        },
      };
    }

    return input;
  });

  return monitoredFolderInput;
};

export const getMonitorFileInput = fileProductionProperties => {
  const monitoredFileInput = fileProductionProperties.map(x => {
    let input = { resourceId: x.resourceId, productionSettings: {} };

    const { productionSettings = {} } = x || {};

    input = {
      ...input,
      include: x.isNotifyEnabled || false,
      productionSettings: {
        orderQuantity: productionSettings.orderQuantity || 0,
        propertyValueIds: productionSettings.propertyValueIds || [],
      },
    };

    if (productionSettings.quantityFixedValueId) {
      input = {
        ...input,
        productionSettings: {
          ...input.productionSettings,
          quantityFixedValueId: productionSettings.quantityFixedValueId || null,
        },
      };
    }

    return input;
  });

  return monitoredFileInput;
};

export const isRecipientFieldRequired = notificationActions => {
  let required = true;
  if (notificationActions && notificationActions.length === 1) {
    if (
      notificationActions[0] &&
      notificationActions[0].value === PRINT_AND_MAIL
    ) {
      required = false;
    }
  }
  return required;
};

export const notificationActionsReceiversValid = (
  notificationActions,
  receivers
) => {
  let valid = false;
  if (notificationActions && notificationActions.length > 0) {
    valid = true;
    const found = notificationActions.find(x => x.value === PRINT_AND_MAIL);
    if (found) {
      if (!receivers || receivers.length < 1) {
        valid = false;
      }
    }
  }
  return valid;
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Button, Box, TableCell, TableRow } from "@mui/material";
import { UPDATE_QUANTITY } from "../../../graphql/mutations/PickingListContext";
import CustomTextField from "../../common/TextField";
import CustomSelect from "../../common/CustomSelect";
import IconLink from "../../common/FormControl/IconLink";
import CheckboxDefault from "../../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 12,
  },
  btnSave: {
    padding: "3px 5px",
    fontSize: 10,
    fontWeight: 600,
    marginRight: 7,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnLink: {
    padding: 0,
    fontSize: 11,
    textTransform: "none",
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  action: {
    color: theme.palette.common.link,
  },
  actionEdit: {
    color: theme.palette.common.link,
    fontSize: 12,
    marginLeft: 5,
    verticalAlign: "middle",
  },
  editBox: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& .MuiFormControl-fullWidth": {
      width: "30%",
      marginTop: 0,
      marginRight: 10,
    },
    "& input": {
      paddingTop: 5,
      paddingBottom: 6,
      fontSize: 10,
    },
  },
  signature: {
    width: "100%",
    display: "flex",
    marginTop: 5,
    "& input": {
      padding: "3px 12px",
      fontSize: 11,
    },
  },
}));

const OrderRowItems = ({
  row,
  orderRowSig,
  pickingListUsers,
  fetchPickingListOrders,
  pickedOrderIds,
  setPickedOrderIds,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const [updateQuantity, { loading }] = useMutation(UPDATE_QUANTITY);

  const [quantitySignature, setQuantitySignature] = useState("AH");
  const [rowSignature, setRowSignature] = useState("");

  const { amount, product, weight, stockInfo = {}, id } = row;
  const { picked, pickedBy, stockLocation } = stockInfo;

  const [edit, setEdit] = useState(false);
  const [quantity, setQuantity] = useState(amount);
  const [productWeight, setProductWeight] = useState(weight);

  useEffect(() => {
    if (orderRowSig && pickedOrderIds.includes(id)) {
      setRowSignature(orderRowSig);
    }
  }, [orderRowSig]);

  useEffect(() => {
    setQuantity(amount);
  }, [amount]);

  useEffect(() => {
    setProductWeight(weight);
  }, [weight]);

  const handleQuantity = e => {
    const { value } = e.target;

    setQuantity(value);
  };

  const handleQuantitySignature = e => {
    const { value } = e.target;
    setQuantitySignature(value);
  };

  const handleUpdateQuantity = async () => {
    try {
      const input = {
        orderHistorRowItemId: id,
        quantity,
        signature: quantitySignature,
      };
      const res = await updateQuantity({ variables: { input } });

      if (
        res &&
        res.data &&
        res.data.pickingListContext &&
        res.data.pickingListContext.updateQuantity
      ) {
        const {
          success,
          totalWeight,
        } = res.data.pickingListContext.updateQuantity;
        if (success) {
          alert.success(<FormattedMessage id="pickingList.updatedQuantity" />);
          setProductWeight(totalWeight);
          fetchPickingListOrders();
        } else {
          alert.error(
            <FormattedMessage id="pickingList.errorUpdatingQuantity" />
          );
        }
      }
    } catch (err) {
      console.error("error >", err);
    }

    setEdit(false);
  };

  const handlePickOrder = () => {
    if (pickedOrderIds.includes(id)) {
      const tempValues = pickedOrderIds.filter(x => x !== id);
      setPickedOrderIds(tempValues);
    } else {
      setPickedOrderIds(prevState => [...prevState, id]);
    }
  };

  return (
    <TableRow>
      <TableCell className={classes.td}>
        <IconLink icon="fa fa-download" customClass={classes.action} />
      </TableCell>
      <TableCell className={classes.td}>{product}</TableCell>
      <TableCell className={classes.td}>{stockLocation}</TableCell>
      <TableCell className={classes.td}>
        {edit ? (
          <Box className={classes.editBox}>
            <CustomTextField
              value={quantity}
              onChange={handleQuantity}
              disabled={loading}
            />
            <Button
              className={classes.btnSave}
              onClick={() => handleUpdateQuantity()}
            >
              <FormattedMessage id="btn.save" />
            </Button>
            <Button className={classes.btnLink} onClick={() => setEdit(false)}>
              <FormattedMessage id="btn.cancel" />
            </Button>
            <div className={classes.signature}>
              <CustomSelect
                value={quantitySignature}
                options={pickingListUsers}
                onChange={handleQuantitySignature}
              />
            </div>
          </Box>
        ) : (
          <>
            {quantity}
            <IconLink
              onClick={() => setEdit(true)}
              icon="fa fa-pencil-alt"
              customClass={classes.actionEdit}
            />
          </>
        )}
      </TableCell>

      <TableCell className={classes.td}>{productWeight} g</TableCell>
      <TableCell align="center" className={classes.td}>
        <CheckboxDefault
          value={pickedOrderIds.includes(id) || picked}
          onChange={() => handlePickOrder()}
        />
      </TableCell>
      <TableCell className={classes.td}>{rowSignature || pickedBy}</TableCell>
    </TableRow>
  );
};

OrderRowItems.defaultProps = {
  row: {},
  pickingListUsers: [],
  pickedOrderIds: [],
};

OrderRowItems.propTypes = {
  orderRowSig: PropTypes.string.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    price: PropTypes.number,
    weight: PropTypes.number,
    product: PropTypes.string,
    stockInfo: PropTypes.shape({
      picked: PropTypes.bool,
      pickedBy: PropTypes.string,
      stockLocation: PropTypes.string,
    }),
  }),
  pickingListUsers: PropTypes.arrayOf(PropTypes.shape({})),
  fetchPickingListOrders: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  setPickedOrderIds: PropTypes.func.isRequired,
  pickedOrderIds: PropTypes.arrayOf(PropTypes.string),
};

export default OrderRowItems;

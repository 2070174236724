import { useMutation } from "@apollo/client";
import { UPDATE_TAGS } from "../graphql/mutations";

// eslint-disable-next-line import/prefer-default-export
export const useUpdateTags = () => {
  const [updateTags] = useMutation(UPDATE_TAGS);

  const handleUpdateTags = ({ tags }) => {
    return updateTags({
      variables: { UpdateTagsInputType: tags },
    });
  };

  return { execute: handleUpdateTags };
};

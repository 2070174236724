import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_ROLES_FOR_SITE } from "../graphql/queries";
import {
  setSiteRoles,
  setSiteRolesLoading,
  setSiteRolesError,
} from "../actions";

// eslint-disable-next-line import/prefer-default-export
export const useGetSiteRoles = ({ siteId }) => {
  const [getSiteRoles, { data, loading, error }] = useLazyQuery(
    GET_ROLES_FOR_SITE,
    {
      variables: {
        siteId,
      },
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      data &&
      data.siteRoles &&
      Array.isArray(data.siteRoles.listSiteRolesForSite)
    ) {
      dispatch(
        setSiteRoles({
          items: data.siteRoles.listSiteRolesForSite,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setSiteRolesLoading({
        loading,
      })
    );
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(
        setSiteRolesError({
          error,
        })
      );
    }
  }, [error]);

  return { execute: getSiteRoles, data, loading, error };
};

import { gql } from '@apollo/client';

const CREATE_ORDER = gql`
  mutation CreateOrder(
    $orderGroupId: Int!
    $items: [ShoppingCartItem]
    $fileGroups: [ShoppingCartFileGroup]
    $oneStreamGroups: [ShoppingCartOneStreamGroup]
    $deliveryAddress: AddressInputType
    $invoiceAddress: AddressInputType
    $postalAddress: AddressInputType
    $deliveryAddressChanged: Boolean!
    $message: String,
    $costCenters: [String]
  ) {
    createOrder(
      shoppingCart: {
        orderGroupId: $orderGroupId
        recivers: [
          {
            costCenters: $costCenters
            items: $items
            deliveryAddress: $deliveryAddress
            invoiceAddress: $invoiceAddress
            postalAddress: $postalAddress
            deliveryAddressChanged: $deliveryAddressChanged
            message: $message
            fileGroups: $fileGroups
            oneStreamGroups: $oneStreamGroups
          }
        ]
      }
    ) {
      companyOrderNo
      orderNo
      orderSuccess
      status {
        shoppingBasketItemId
        statusCode
        statusMessage
      }
    }
  }
`;

export default CREATE_ORDER;

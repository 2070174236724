import { React, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer as MuiDrawer, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PushPinIcon from "@mui/icons-material/PushPin";

const drawerWidth = 400;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(3)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(3)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginRight: open ? "0px" : "-8px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SidebarDrawer = ({ children }) => {
  const theme = useTheme();

  const chevronStates = { CLOSED: 0, OPEN: 1, PINNED: 2 };
  const [chevronState, setChevronState] = useState(chevronStates.PINNED);


  const handleMouseEnter = (event) => {

    if (chevronState === chevronStates.CLOSED) {
      setChevronState(chevronStates.OPEN);
    }
  };

  const handleMouseLeave = () => {
    if (chevronState !== chevronStates.PINNED) {
      setChevronState(chevronStates.CLOSED);
    }
  };

  const handleDrawerToggle = () => {

    if (chevronState === chevronStates.PINNED) {
      setChevronState(chevronStates.CLOSED);
    } else {
      setChevronState(chevronStates.PINNED);
    }
  };

  const renderDrawerIcon = () => {

    switch (chevronState) {
      case chevronStates.PINNED:
        return (
          <ChevronLeftIcon
            sx={{ opacity: 0.4, "&:hover": { opacity: 1 } }}
          />
        );
      case chevronStates.OPEN:
        return (<PushPinIcon
          sx={{
            fontSize: "70%",
            marginTop: "5px",
            marginBottom: "2px",
          }}
        />);
      default:
        return (<ChevronRightIcon />)
    }
  }

  return (
    <Drawer variant="permanent"
      open={chevronState === chevronStates.PINNED || chevronState === chevronStates.OPEN}
      
      onMouseLeave={handleMouseLeave}
      PaperProps={{
        elevation: 1,
        square: false,
        sx: {
          height: 'calc(100vh - 112px)',
          top: "104px",
          zIndex: 0,
        },
      }}>
      <DrawerHeader open={chevronState === chevronStates.PINNED || chevronState === chevronStates.OPEN} className="MuiDrawerHeader">
        <IconButton onClick={handleDrawerToggle} className="MuiDrawerButton">
          {renderDrawerIcon()}
        </IconButton>
      </DrawerHeader>
      <Box onMouseEnter={handleMouseEnter} sx={{ height:"100%" }}>{children}</Box>
      
    </Drawer>
  );
};

export default SidebarDrawer;

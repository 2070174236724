import { gql } from '@apollo/client';

import ProductFragment from "../fragments/Product";

const getProducts = gql`
  query GetProducts(
    $folderId: Int!
    $sortfield: String
    $ascending: Boolean
    $offset: Int!
    $pageSize: Int!
    $breadcrumbs: Boolean
    $productId: ID
  ) {
    products(
      folderId: $folderId
      sortfield: $sortfield
      ascending: $ascending
      offset: $offset
      pageSize: $pageSize
      breadcrumbs: $breadcrumbs
      productId: $productId
    ) {
      offset
      totalCount
      items {
        ...ProductFields
      }
    }
  }

  ${ProductFragment}
`;

export { getProducts };

import React, { useState, memo } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import { useQuery } from "@apollo/client";
import Typography from "@mui/material/Typography";
import SearchField from "../SearchField";
import Resources from "./Resources";
import FolderTreeContainer from "../FolderTreeContainer";
import { GET_FOLDERS } from "../../../graphql/queries";
import TreeSkeleton from "../../Skeleton/TreeSkeleton";
import SiteSelect from "../../../assets/img/site-select.svg";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";
import WorkspaceSelector from "../WorkspaceSelector";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "900px",
    maxWidth: "900px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxWidth: "90%",
    },
  },
  modalBody: {
    padding: 0,
    minHeight: 300,
    display: "flex",
    flexDirection: "column",
  },
  borderRight: {
    borderRight: "1px solid #dee2e6",
  },
  btn: {
    padding: "5px 15px",
    fontWeight: 600,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  modalFooter: {
    borderTop: "1px solid #ddd",
    padding: "10px 15px 20px 15px",
  },
  modalScroll: {
    maxHeight: 360,
    overflowY: "auto",
    flex: 1,
    display: "flex",
  },
  siteSelect: {
    textAlign: "center",
    padding: "40px 0",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
  workspaceSelector: {
    padding: "0 10px",
    width: "45%",
  },
  modalHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "5px 10px",
    color: "#fff",
    display: "flex",
    minHeight: 20,
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
      lineHeight: "normal",
    },
  },
  close: {
    padding: 4,
    color: "#fff",
    marginLeft: "auto",
  },
  modalTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 2.3,
    marginRight: 20,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "normal",
      fontSize: 14,
    },
  },
}));

const SelectFileFromSystemModal = ({
  open,
  handleClose,
  sites,
  setSelectedResources,
  selectedResources,
  isProductEdit,
}) => {
  const classes = useStyles();
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );

  const [workspace, setWorkspace] = useState({});
  const [folderId, setFolderId] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  const handleSelectWorkspace = value => {
    setWorkspace(value);
    setWorkspaceSelector(false);
  };

  const { data, loading } = useQuery(GET_FOLDERS, {
    variables: {
      siteId: workspace && workspace.siteId ? workspace.siteId : null,
    },
    skip: !workspace || !workspace.siteId,
  });

  const handleOnChange = event => {
    if (event.target.value) {
      setFolderId(null);
      setSearchText(event.target.value);
    }
  };

  const handleCloseModal = () => {
    setWorkspace({});
    setFolderId(null);
    setSearchText("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.modal }}
    >
      <DialogTitle handleClose={handleClose} className={classes.modalHeader}>
        <Typography className={classes.modalTitle} component="div">
          <FormattedMessage id="common.uploadFromSystem" />
        </Typography>

        <Box className={classes.workspaceSelector}>
          <WorkspaceSelector
            workspaces={workspaces}
            workspaceName={workspace.name}
            onClick={handleSelectWorkspace}
            handleToggle={handleToggle}
            open={workspaceSelector}
            searchable
            setOpen={setWorkspaceSelector}
          />
        </Box>

        <SearchField
          searchText={searchText}
          handleOnChange={handleOnChange}
          siteId={workspace && workspace.siteId ? workspace.siteId : null}
        />

        <IconButton
          className={classes.close}
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DraggableModalBody customClass={classes.modalBody}>
        {!workspace ? (
          <Box className={classes.siteSelect}>
            <img src={SiteSelect} alt="Empty" />
            <Typography className={classes.emptyText}>
              <FormattedMessage id="rfp.siteNeeded" />
            </Typography>
          </Box>
        ) : (
          <>
            <div className={classes.modalScroll}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={3} className={classes.borderRight}>
                  <Box px={1} py={2}>
                    {loading && <TreeSkeleton />}
                    {!loading && data && data.folders && (
                      <FolderTreeContainer
                        folders={data.folders}
                        setFolderId={setFolderId}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Resources
                    folderId={folderId}
                    workspace={workspace}
                    setSelectedResources={setSelectedResources}
                    selectedResources={selectedResources}
                    searchText={searchText}
                    siteId={
                      workspace && workspace.siteId ? workspace.siteId : null
                    }
                    isProductEdit={isProductEdit}
                  />
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              justifyContent="flex-end"
              direction="row"
              className={classes.modalFooter}
            >
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleCloseModal()}
              >
                <FormattedMessage id="btn.close" />
              </Button>
            </Grid>
          </>
        )}
      </DraggableModalBody>
    </Dialog>
  );
};

SelectFileFromSystemModal.defaultProps = {
  isProductEdit: false,
};

SelectFileFromSystemModal.propTypes = {
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      changedBy: PropTypes.string.isRequired,
      changedDate: PropTypes.string.isRequired,
      uploadedDate: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  setSelectedResources: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isProductEdit: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    sites: state.api.currentViewer.viewer.sites,
  };
};

export default connect(mapStateToProps)(memo(SelectFileFromSystemModal));

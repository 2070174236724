import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useIntl } from "react-intl";
import LanguageSwitcher from "../NavBar/NavbarLanguage";
import { setLocale } from "../../../actions";
import ToolTipArrow from "../../common/ToolTipArrow";

const useStyles = makeStyles(theme =>
  createStyles({
    linkWrapper: {
      paddingRight: 30,
      paddingLeft: 30,
      paddingTop: 7,
      paddingBottom: 7,
      backgroundColor: theme.palette.component.navbarBackground,
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      alignItems: "center",
      position: "fixed",
      width: "97%",
      top: 60,
      height: 20,
      zIndex: 1,
      boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.09)",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.palette.component.navbarSeparator,
      boxSizing: "initial",
    },
    link: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      color: theme.palette.component.navbarColor,
      marginRight: 10,
      padding: "0 8px",
      textTransform: "uppercase",
      fontWeight: 400,
      display: "inline-flex",
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.component.navbarColor,
      },
      "&:focus": {
        color: theme.palette.component.navbarColor,
      },
      sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      },
    },
    icon: {
      fontSize: 16,
    },
  })
);

const PublicNavbar = ({
  locale,
  // eslint-disable-next-line no-shadow
  setLocale,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box pl={3} className={classes.linkWrapper}>
      <ToolTipArrow
        title={intl.formatMessage({ id: "navMenu.overview" })}
        placement="top"
      >
        <Link
          to="/"
          className={classes.link}
          onClick={() => {
            console.log("** onHomeClick");
          }}
        >
          <i className={clsx(["fas fa-home", classes.icon])} />
        </Link>
      </ToolTipArrow>
      <LanguageSwitcher setLocale={setLocale} locale={locale} />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    locale: state.ui.locale,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLocale,
    },
    dispatch
  );
};

PublicNavbar.defaultProps = {};

PublicNavbar.propTypes = {
  locale: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
  setLocale: PropTypes.func.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PublicNavbar
);

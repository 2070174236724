import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomSelect from "../../../../common/CustomSelect";
import CustomTextField from "../../../../common/TextField";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
  },
  icon: {
    fontSize: 12,
  },
  iconButton: {
    padding: 8,
  },
  settings: {
    "&:hover": {
      background: "#e6dddd",
    },
  },
  container: {
    marginTop: 10,
  },
  textField: {
    marginTop: 0,
  },
}));

const dummyOptions = [
  { label: "Quantity 1", value: "one" },
  { label: "Quantity 2", value: "two" },
];

const RightSettings = ({ handleChange, handleSelect, settings }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" className={classes.container}>
        <Grid xs={12} md={2}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.quantityType" />:
          </Typography>
        </Grid>
        <Grid xs={12} md={10}>
          <CustomSelect
            inputLabel={<FormattedMessage id="common.select" />}
            customClass={classes.select}
            options={dummyOptions}
            value={settings.quantityType}
            onChange={e => handleSelect("quantityType", e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid item container alignItems="center" className={classes.container}>
        <Grid xs={12} md={2}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.minQuantity" />:
          </Typography>
        </Grid>
        <Grid xs={12} md={10}>
          <CustomTextField
            customClass={classes.textField}
            name="min"
            value={settings.min}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid item container alignItems="center" className={classes.container}>
        <Grid xs={12} md={2}>
          <Typography className={classes.text}>
            <FormattedMessage id="sites.maxQuantity" />:
          </Typography>
        </Grid>
        <Grid xs={12} md={10}>
          <CustomTextField
            customClass={classes.textField}
            name="max"
            value={settings.max}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

RightSettings.defaultProps = {
  settings: {},
};

RightSettings.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    quantityType: PropTypes.string,
    max: PropTypes.string,
    min: PropTypes.string,
  }),
};

export default RightSettings;

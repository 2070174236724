import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImagePlaceHolder from "../../../assets/img/placeholder.jpg";
import ImagePager from "../../common/ImagePager";
import ProductTags from "../ProductTags";

const useStyles = makeStyles(theme => ({
  imagePreview: {
    maxHeight: "85vh",
    width: "100%",
    objectFit: "contain",
  },
  imageZoomControls: {
    backgroundColor: theme.palette.common.white,
    textAlign: "center",
    borderRadius: 3,
    boxShadow: "0 1px 5px #e8e8e8",
    padding: "7px 5px",
    marginLeft: 20,
    whiteSpace: "nowrap",
    width: 110,
  },
  iconButton: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    textAlign: "center",
    margin: "0 12px",
    borderRadius: 0,
    padding: 0,
  },
  previousButton: {
    borderRight: "1px solid #8a8a8a",
    paddingRight: 10,
    marginLeft: 0,
  },
  nextButton: {
    borderLeft: "1px solid #8a8a8a",
    paddingLeft: 10,
    marginRight: 0,
  },
  imgActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: 15,
    left: 0,
    right: 0,
  },
}));

const ProductImagePreview = ({
  handleNext,
  handlePrevious,
  imageUri,
  imageIndex,
  alt,
  minPage,
  maxPage,
  tags,
  product,
  handleDownloadOnClick,
  productType,
}) => {
  const classes = useStyles();

  const LOWER_SCALE_LIMIT = 1;
  const UPPER_SCALE_LIMIT = 8;

  const [image, setImage] = useState(imageUri);
  const [scale, setScale] = useState(LOWER_SCALE_LIMIT);

  const handleImageError = () => {
    setImage(ImagePlaceHolder);
  };

  useEffect(() => {
    if (imageUri) {
      setImage(imageUri);
    }
  }, [imageUri]);

  const ImageZoomControls = ({ scale, zoomIn, zoomOut }) => {
    return (
      <div className={classes.imageZoomControls}>
        <IconButton
          className={classes.iconButton}
          disabled={scale <= LOWER_SCALE_LIMIT}
          onClick={() => zoomOut(1)}
        >
          <i className="fa fa-minus"></i>
        </IconButton>
        {Math.trunc(scale * 100)}%
        <IconButton
          className={classes.iconButton}
          disabled={scale >= UPPER_SCALE_LIMIT}
          onClick={() => zoomIn(1)}
        >
          <i className="fa fa-plus"></i>
        </IconButton>
      </div>
    );
  };

  return (
    <TransformWrapper
      centerOnInit
      initialScale={scale}
      onTransformed={(e, state) => { 
        setScale(state.scale);
      }}
    >
      {({ zoomIn, zoomOut }) => {

        return (
          <>
            <TransformComponent>
              <img
                className={classes.imagePreview}
                src={image}
                onError={() => handleImageError()}
                alt={alt}
              />
            </TransformComponent>
            {/* {tags && tags.length > 0 && (
              <Box>
                <p className={classes.tagTitle}>
                  <FormattedMessage id="product.tagging" />
                </p>
                <ProductTags tags={tags} />
              </Box>
            )} */}
            <Box className={classes.imgActions}>
              <ImagePager
                customClass={classes.imgPager}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                disabledNext={imageIndex === maxPage}
                disabledPrev={imageIndex === minPage}
                imageIndex={imageIndex}
                maxPage={maxPage}
                productType={productType}
              />
              <ImageZoomControls
                scale={scale}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
              />
            </Box>
          </>
        );

      }}
    </TransformWrapper>
  );
};

export default ProductImagePreview;

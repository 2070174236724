import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { saveAs } from "file-saver";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  DELETE_WORKSPACE,
  INIT_DOWNLOAD_WORKSPACETEMPLATE,
} from "../../../graphql/mutations/CompanyAdminContext";
import {
  LIST_WORKSPACE_OWNER_CANDIDATES,
  LIST_WORKSPACE_TEMPLATE_CANDIDATES,
  LIST_WORKSPACES,
} from "../../../graphql/queries/CompanyAdminContext";
import { getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";
import {
  useGetNetworkSelectorItems,
  useListWorkspaceOrderGroupCandidates,
} from "../../../hooks";

import EditSiteModal from "./EditSiteModal";
import NewWorkspaceModal from "./NewWorkspaceModal";
import Workspace from "./Workspace";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
}));

const Sites = () => {
  const classes = useStyles();
  const alert = useAlert();
  const companyId = useSelector(getAdminSelectedCompanyId);
  const usersNetworkId = useSelector(
    state => state.api.currentViewer.viewer.networkId
  );
  const [initDownloadFile] = useMutation(INIT_DOWNLOAD_WORKSPACETEMPLATE);
  const [editSiteModal, setEditSiteModal] = useState(false);
  const [addWorkspaceModal, setAddWorkspaceModal] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceOwnerCandidates, setWorkspaceOwnerCandidates] = useState([]);
  const [
    workspaceTemplateCandidates,
    setWorkspaceTemplateCandidates,
  ] = useState([]);
  const [displayAddNewWorkspace, setDisplayAddNewWorkspace] = useState(false);

  const [selectedSiteId, setSelectedSiteId] = useState("");



  const [listWorkspaces, { data: sitesData }] = useLazyQuery(LIST_WORKSPACES);
  

  const {
    execute: listWorkspaceOrderGroupCandidates,
    loadingWorkspaceOrderGroupCandidates,
  } = useListWorkspaceOrderGroupCandidates();

  const workspaceOrderGroupCandidates = useSelector(
    state => state.api.companyAdmin.workspaceOrderGroupCandidates.items
  );

  const [
    listWorkspaceOwnerCandidates,
    { data: workspaceOwnerCandidatesData, loadingWorkspaceOwnerCandidatesData },
  ] = useLazyQuery(LIST_WORKSPACE_OWNER_CANDIDATES);

  const [
    listWorkspaceTemplateCandidates,
    {
      data: workspaceTemplateCandidatesData,
      loadingWorkspaceTemplateCandidatesData,
    },
  ] = useLazyQuery(LIST_WORKSPACE_TEMPLATE_CANDIDATES);

  const fetchWorkspaces = () => {
    listWorkspaces({ variables: { companyId } });
  };

  const {
    getNetworkSelectorItems,
    networkSelectorItems,
  } = useGetNetworkSelectorItems({ companyId });

  useEffect(() => {
    if (companyId) {
      listWorkspaces({ variables: { companyId } });
      listWorkspaceOwnerCandidates({ variables: { companyId } });
      listWorkspaceTemplateCandidates({ variables: { companyId } });
    }
  }, [companyId]);

  useEffect(() => {
    if (
      sitesData &&
      sitesData.companyAdminContext &&
      sitesData.companyAdminContext.companyWorkspaces &&
      sitesData.companyAdminContext.companyWorkspaces.listWorkspaces
    ) {
      const {
        canAddNewWorkspace,
        items,
      } = sitesData.companyAdminContext.companyWorkspaces.listWorkspaces;
      setDisplayAddNewWorkspace(canAddNewWorkspace);
      setWorkspaces(items);
    }
  }, [sitesData]);

  useEffect(() => {
    var candidates =
      workspaceOwnerCandidatesData?.companyAdminContext?.companyWorkspaces
        ?.listWorkspaceOwnerCandidates;

    if (candidates) {
      const { items } = candidates;
      setWorkspaceOwnerCandidates(items);
    }
  }, [workspaceOwnerCandidatesData]);

  useEffect(() => {
    var candidates =
      workspaceTemplateCandidatesData?.companyAdminContext?.companyWorkspaces
        ?.listWorkspaceTemplates;

    if (candidates) {
      const { items } = candidates;
      setWorkspaceTemplateCandidates(items);
    }
  }, [workspaceTemplateCandidatesData]);

  const handleCloseEditSite = () => {
    setEditSiteModal(false);
  };

  const handleOpen = siteId => {
    listWorkspaceOrderGroupCandidates();
    getNetworkSelectorItems({ companyId });
    setSelectedSiteId(siteId);
    setEditSiteModal(true);
  };



  const handleOpenAddWorkspaceModal = () => {
    listWorkspaceOrderGroupCandidates();
    getNetworkSelectorItems({ companyId });
    setAddWorkspaceModal(true);
  };

  const handleCloseAddWorkspace = () => {
    setAddWorkspaceModal(false);
  };

  const handleDownload = async siteId => {
    try {
      const res = await initDownloadFile({
        variables: { siteId: siteId },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyWorkspaces
      ) {
        saveAs(
          res.data.companyAdminContext.companyWorkspaces
            .initWorkspaceTemplateDownload
        );
      } else {
        console.log("Error downloading");
        alert.error(<FormattedMessage id="common.genericErrorMessage" />);
      }
    } catch (err) {
      console.error("error  >", err);
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
    }
  };



  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          <FormattedMessage id="admin.workspaceSettings" />
        </Typography>
        {displayAddNewWorkspace && (
          <Button
            className={classes.addLink}
            onClick={handleOpenAddWorkspaceModal}
          >
            <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
            <FormattedMessage id="admin.addNewWorkspace" />
          </Button>
        )}
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={3}>
          {workspaces && workspaces.length > 0 && workspaces.map(workspace => {
            return (
              <Workspace
            key={`workspace-${workspace.siteId}`}
            workspace={workspace}
            handleOpenEdit={handleOpen}
            //handleOpenDeleteModal={handleOpenDeleteModal}
            handleDownload={handleDownload}
          />
            );
          })}
        </Grid>
      </Box>

      {editSiteModal && !loadingWorkspaceOrderGroupCandidates && (
        <EditSiteModal
          open={editSiteModal}
          companyId={companyId}
          siteId={selectedSiteId}
          networkSelectorItems={networkSelectorItems}
          workspaceOwnerCandidates={workspaceOwnerCandidates}
          workspaceOrderGroupCandidates={workspaceOrderGroupCandidates}
          handleClose={handleCloseEditSite}
          refresh={fetchWorkspaces}
        />
      )}
      {addWorkspaceModal && !loadingWorkspaceOrderGroupCandidates && (
        <NewWorkspaceModal
          open={addWorkspaceModal}
          handleClose={handleCloseAddWorkspace}
          networkSelectorItems={networkSelectorItems}
          usersNetworkId={usersNetworkId}
          workspaceOwnerCandidates={workspaceOwnerCandidates}
          workspaceTemplateCandidates={workspaceTemplateCandidates}
          workspaceOrderGroupCandidates={workspaceOrderGroupCandidates}
          companyId={companyId}
          refresh={fetchWorkspaces}
        />
      )}

    </Box>
  );
};

export default Sites;

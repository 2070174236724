import { gql } from '@apollo/client';

const getMonitoringRecipients = gql`
  query GetMonitoringRecipients($searchTerm: String) {
    monitoringContext {
      recipientLookup(searchTerm: $searchTerm) {
        userId
        user
      }
    }
  }
`;

export default getMonitoringRecipients;

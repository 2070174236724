import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import CustomTextField from "../../../../common/TextField";
import ExpansionDefault from "../../../../common/ExpansionDefault";
import TextAreaFull from "../../../../common/FormControl/TextAreaFull";
import SelectNativeFull from "../../../../common/FormControl/SelectNativeFull";

const useStyles = makeStyles(theme => ({
  customExpansion: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 10,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
  },
  mt8: {
    marginTop: 8,
  },
}));

const Printshop = ({ formValues, handleChange, handleIntChange }) => {
  const classes = useStyles();
  const intl = useIntl();
  const deliveryAddressPlaceholder = intl.formatMessage({
    id: "orderHistory.deliveryAddress",
  });
  return (
    <ExpansionDefault
      label={<FormattedMessage id="cooperation.printer" />}
      panelContent="tryckeriContent"
      panelHeader="tryckeriHeader"
      defaultExpanded
    >
      <Grid container spacing={1} className={classes.customExpansion}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="printshop"
            value={formValues.printshop}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printer" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="printshopContact"
            value={formValues.printshopContact}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerContact" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="printshopOffertNo"
            value={formValues.printshopOffertNo}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerQuotationNumber" />}
            type="number"
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="printshopOfferedPrice"
            value={formValues.printshopOfferedPrice}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.offeredPrice" />}
            type="number"
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="printshopFreight"
            value={formValues.printshopFreight}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerShipping" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="printshopWeight"
            value={formValues.printshopWeight}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerWeight" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="printshopInvoicedPrice"
            value={formValues.printshopInvoicedPrice}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.invoicedPrice" />}
            type="number"
          />
          <div className={classes.mt8} />

          <TextAreaFull
            name="printshopDeliveryAddress"
            value={formValues.printshopDeliveryAddress}
            onChange={handleChange}
            placeholder={deliveryAddressPlaceholder}
            label={<FormattedMessage id="orderHistory.deliveryAddress" />}
            rows="2"
            rowsMax="2"
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="pageRange"
            value={formValues.pageRange}
            onChange={handleChange}
            label={<FormattedMessage id="product.pageRange" />}
            type="number"
          />
        </Grid>
      </Grid>
      <div className={classes.mt8} />
      <Grid container spacing={1} className={classes.customExpansion}>
        <Grid item md={4}>
          <CustomTextField
            name="printshopFormatId"
            value={
              formValues.printshopFormatId === 0
                ? ""
                : formValues.printshopFormatId
            }
            onChange={handleIntChange}
            label={<FormattedMessage id="cooperation.printerFormat" />}
            type="number"
          />
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            name="printShopFormatWidth"
            value={formValues.printShopFormatWidth}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerWidth" />}
          />
        </Grid>
        <Grid item md={4}>
          <CustomTextField
            name="printShopFormatHeight"
            value={formValues.printShopFormatHeight}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerHeight" />}
          />
        </Grid>
      </Grid>
      <div className={classes.mt8} />
      <Grid container spacing={1} className={classes.customExpansion}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="printshopCoverPaper"
            value={formValues.printshopCoverPaper}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerCover" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="printshopInsidePaper"
            value={formValues.printshopInsidePaper}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerInlay" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="coverColor"
            value={formValues.coverColor}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerCoverColor" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="printshopCoverPaperWeight"
            value={formValues.printshopCoverPaperWeight}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerPaperWeight" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="printshopInsidePaperWeight"
            value={formValues.printshopInsidePaperWeight}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.printerPaperWeight" />}
          />
          <div className={classes.mt8} />
          <SelectNativeFull
            label="Efterbehandling"
            options={[
              {
                label: intl.formatMessage({
                  id: "cooperation.printerSaddleStitch",
                }),
                value: 1,
              },
              {
                label: intl.formatMessage({
                  id: "cooperation.printerPunching",
                }),
                value: 2,
              },
            ]}
          />
        </Grid>
      </Grid>
      <div className={classes.mt8} />
      <Grid container spacing={1} className={classes.customExpansion}>
        <Grid item xs={12}>
          <TextAreaFull
            name="misc"
            value={formValues.misc}
            onChange={handleChange}
            placeholder="Övrigt"
            label={<FormattedMessage id="cooperation.printerOther" />}
            rows="2"
            rowsMax="2"
          />
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

Printshop.propTypes = {
  formValues: PropTypes.shape({
    printshop: PropTypes.string,
    printshopContact: PropTypes.string,
    printshopOffertNo: PropTypes.string,
    printshopFreight: PropTypes.string,
    printshopOfferedPrice: PropTypes.string,
    printshopInvoicedPrice: PropTypes.string,
    printshopDeliveryAddress: PropTypes.string,
    pageRange: PropTypes.string,
    printshopFormatId: PropTypes.number,
    printShopFormatHeight: PropTypes.string,
    printShopFormatWidth: PropTypes.string,
    printshopCoverPaper: PropTypes.string,
    printshopInsidePaper: PropTypes.string,
    printshopCoverPaperWeight: PropTypes.string,
    printshopInsidePaperWeight: PropTypes.string,
    coverColor: PropTypes.string,
    printshopWeight: PropTypes.string,
    misc: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleIntChange: PropTypes.func.isRequired,
};

export default Printshop;

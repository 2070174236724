import { gql } from '@apollo/client';

const listConsents = gql`
  query ListConsents($siteId: Int) {
    consentContext {
      listConsents(siteId: $siteId) {
        answeredDate
        createdDate
			  email
			  firstname
			  lastname
			  title
			  token
			  resources {
          approved
          resourceId
          thumbnailImageUri
        }
      }
    }
  }
`;

export default listConsents;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import TemplateBlank from "../../../assets/img/template-blank.svg";
import TemplateListItem from "./TemplateListItem";
import { GET_TEMPLATES_FOR_SITE } from "../../../graphql/queries/NewsContext";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
  },
}));

const TemplatesList = ({
  siteId,
  selectedTemplateId,
  setSelectedTemplateId,
}) => {
  const classes = useStyles();
  const [templates, setTemplates] = useState([]);
  const intl = useIntl();

  const { data, loading, error } = useQuery(GET_TEMPLATES_FOR_SITE, {
    variables: {
      siteId,
    },
    skip: !siteId,
  });

  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      data.siteNews &&
      data.siteNews.getTemplatesForSite
    ) {
      setTemplates(data.siteNews.getTemplatesForSite);
    }
    if (error) {
      setTemplates([]);
    }
  }, [data, loading, error]);

  const handleOnClick = id => {
    setSelectedTemplateId(id);
  };

  return (
    <Box className={classes.root}>
      {templates.map(x => (
        <TemplateListItem
          key={x.id}
          id={x.id}
          onClick={handleOnClick}
          src={x.thumbnail}
          title={x.title}
          active={selectedTemplateId === x.id}
        />
      ))}
      <TemplateListItem
        id={null}
        onClick={handleOnClick}
        src={TemplateBlank}
        title={intl.formatMessage({
          id: "common.blankTemplate",
        })}
        active={selectedTemplateId === null || selectedTemplateId === undefined}
      />
    </Box>
  );
};

TemplatesList.defaultProps = {};

TemplatesList.propTypes = {
  siteId: PropTypes.number.isRequired,
  selectedTemplateId: PropTypes.string.isRequired,
  setSelectedTemplateId: PropTypes.func.isRequired,
};

export default TemplatesList;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

const useStyles = makeStyles(theme => ({
  pagination: {
    border: "none",
    marginTop: 20,
  },
  caption: {
    fontSize: 12,
  },
  toolbar: {
    minHeight: "unset",
    marginTop: 5,
    paddingLeft: 0,
  },
  spacer: {
    display: "none",
  },
  select: {
    border: "1px solid #ddd",
    borderRadius: 3,
    fontSize: 12,
  },
  selectIcon: {
    top: 4,
  },
  progressRoot: {
    height: 12,
    backgroundColor: theme.palette.background.gray,
    borderRadius: 3,
  },
  progressBar: {
    backgroundColor: theme.palette.common.blue,
  },
}));

const PickingListTableFooter = ({
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalCount,
}) => {
  const classes = useStyles();
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 75, 100]}
          colSpan={13}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "visa rader" },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<FormattedMessage id="context.showRows" />}
          className={classes.pagination}
          classes={{
            caption: classes.caption,
            toolbar: classes.toolbar,
            select: classes.select,
            spacer: classes.spacer,
            selectIcon: classes.selectIcon,
          }}
        />
      </TableRow>
    </TableFooter>
  );
};

PickingListTableFooter.propTypes = {
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

export default PickingListTableFooter;

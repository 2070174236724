import { gql } from '@apollo/client';

const REMOVE_MFA_FOR_USER = gql`
mutation removeMFAForUser($userId:Int!){
	companyAdminContext{
		users{
			removeMFASecret(userId:$userId)
		}
	}
}

`;

export default REMOVE_MFA_FOR_USER;
import { gql } from '@apollo/client';

const REMOVE_SITE_USER = gql`
  mutation RemoveSiteUser($siteId: Int!, $id: ID!) {
    removeSiteUser(siteId: $siteId, id: $id) {
      message
      success
    }
  }
`;

export default REMOVE_SITE_USER;

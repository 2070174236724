import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Button,
  CardContent,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SALES_PAGE_TYPE } from "../../../constant/salesPageTypes";
import { DateSearchField, SelectSearchField, TextSearchField } from "./SearchFields";

const useStyles = makeStyles(theme => ({
  btnReset: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 600,
    margin: "auto",
    padding: "6px 15px",
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
}));

const InvoicesSearchFields = ({commonProps, tabIndex}) => (
  <>
    <TextSearchField {...commonProps("documentNumber")} />
    {(tabIndex === 1) && <SelectSearchField {...commonProps("category")} />}
    {(tabIndex === 2) && <DateSearchField {...commonProps("startDate")} />}
    {(tabIndex === 2) && <DateSearchField {...commonProps("endDate")} />}
    {(tabIndex !== 0) && <SelectSearchField {...commonProps("status")} />}
  </>
);

const OrdersSearchFields = ({commonProps, tabIndex}) => (
  <>
    <TextSearchField {...commonProps("documentNumber")} />
  </>
);

const QuotationsSearchFields = ({commonProps, tabIndex}) => (
  <>
    <TextSearchField {...commonProps("documentNumber")} />
  </>
);

const QuoteRequestsSearchFields = ({commonProps, tabIndex}) => (
  <>
    <TextSearchField {...commonProps("documentNumber")} />
  </>
);

const SalesSearchBar = ({ disabled, onChange, onReset, pageType, searchOptions, searchValues, tabIndex }) => {
  const classes = useStyles();

  const commonProps = (name) => ({
    disabled,
    name,
    onChange,
    options: searchOptions[name],
    value: searchValues[name],
  });

  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item sx={{ display: "flex", flex: 1, flexFlow: "row wrap", gap: "10px", justifyContent: "flex-start" }}>
          {(pageType === SALES_PAGE_TYPE.invoices) && <InvoicesSearchFields commonProps={commonProps} tabIndex={tabIndex} />}
          {(pageType === SALES_PAGE_TYPE.orders) && <OrdersSearchFields commonProps={commonProps} tabIndex={tabIndex} />}
          {(pageType === SALES_PAGE_TYPE.quotations) && <QuotationsSearchFields commonProps={commonProps} tabIndex={tabIndex} />}
          {(pageType === SALES_PAGE_TYPE.quoteRequests) && <QuoteRequestsSearchFields commonProps={commonProps} tabIndex={tabIndex} />}
          {(pageType === SALES_PAGE_TYPE.supplierInvoices) && <InvoicesSearchFields commonProps={commonProps} tabIndex={tabIndex} />}
          <Button disabled={disabled} onClick={onReset} className={classes.btnReset}>
            <FormattedMessage id="btn.reset" />
          </Button>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default SalesSearchBar;
import React from "react";
import PropTypes from "prop-types";
import CompanySettings from "./CompanySettings";

const CompanyRolesSettings = ({
  handleCheckbox,
  roleValues,
  handleSelect,
  handleEnterEmail,
  settingValues,
  subscriptionLevels,
}) => {
  return (
    <>
      {settingValues.companySettings && (
        <CompanySettings
          handleCheckbox={handleCheckbox}
          roleValues={roleValues}
          subscriptionLevels={subscriptionLevels}
          handleSelect={handleSelect}
          handleEnterEmail={handleEnterEmail}
        />
      )}
    </>
  );
};

CompanyRolesSettings.defaultProps = {
  settingValues: {},
  roleValues: {},
};

CompanyRolesSettings.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  roleValues: PropTypes.shape({}),
  settingValues: PropTypes.shape({
    companySettings: PropTypes.bool,
  }),
  handleEnterEmail: PropTypes.func.isRequired,
};

export default CompanyRolesSettings;

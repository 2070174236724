import { gql } from '@apollo/client';

const getAvailableCompanies = gql`
  query getAvailableCompanies {
    companyAdminContext {
      availableCompanies {
        companyId
        name
        usersRights
      }
    }
  }
`;

export default getAvailableCompanies;

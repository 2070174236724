import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import CustomTextField from "../../../../common/TextField";
import ExpansionDefault from "../../../../common/ExpansionDefault";

const useStyles = makeStyles(theme => ({
  customExpansion: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiInputBase-formControl select": {
      fontSize: 10,
      paddingTop: 7,
      paddingBottom: 7,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
  },
  mt8: {
    marginTop: 8,
  },
}));

const Bureau = ({ formValues, handleChange }) => {
  const classes = useStyles();
  return (
    <ExpansionDefault
      label={<FormattedMessage id="cooperation.bureau" />}
      panelContent="byraContent"
      panelHeader="byraHeader"
      defaultExpanded
    >
      <Grid container spacing={1} className={classes.customExpansion}>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="bureau"
            value={formValues.bureau}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.bureau" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="bureauContact"
            value={formValues.bureauContact}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.contact" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="bureauProjectName"
            value={formValues.bureauProjectName}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.projectName" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomTextField
            name="bureauOfferedPrice"
            value={formValues.bureauOfferedPrice}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.offeredPrice" />}
          />
          <div className={classes.mt8} />
          <CustomTextField
            name="bureauInvoicedPrice"
            value={formValues.bureauInvoicedPrice}
            onChange={handleChange}
            label={<FormattedMessage id="cooperation.invoicedPrice" />}
          />
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

Bureau.propTypes = {
  formValues: PropTypes.shape({
    bureau: PropTypes.string,
    bureauContact: PropTypes.string,
    bureauProjectName: PropTypes.string,
    bureauOfferedPrice: PropTypes.string,
    bureauInvoicedPrice: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Bureau;

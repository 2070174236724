import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import useDeepLink from "../../hooks/common/useDeepLink";
import { useDeepLinkContext } from "../../hooks";

const ProductViewLink = ({
  match,
  viewPathName,
}) => {
  const deepLink = useDeepLink(match);
  const deepLinkContext = useDeepLinkContext();

  useEffect(() => {
    if (deepLink && !deepLinkContext.deepLink) {
      deepLinkContext.setData(deepLink);
    }
  }, [deepLink, deepLinkContext.deepLink]);

  return (!deepLink || deepLinkContext.deepLink) && (
    <Redirect to={viewPathName} />
  );
};

export default ProductViewLink;

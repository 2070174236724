import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles(theme => ({
  btn: {
    padding: 0,
    fontWeight: 400,
    color: theme.palette.common.link,
    fontSize: 14,
    backgroundColor: "transparent",
    minWidth: "unset",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    width: "auto",
  },
}));

const ButtonText = ({ label, btnClass, onClick, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={clsx([classes.btn, btnClass])}
    >
      {label}
    </Button>
  );
};

export default ButtonText;

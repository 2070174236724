import { handleActions } from "redux-actions";
import { setMonitoringOrderRecipientTabSettings } from "../../../../actions";

const defaultState = {};

export default handleActions(
  {
    [setMonitoringOrderRecipientTabSettings]: (state, { payload }) => {
      const { settings } = payload;
      return {
        ...state,
        orderRecipientTabSettings: settings,
      };
    },
  },
  defaultState
);

import { gql } from '@apollo/client';

const FILE_UPLOAD = gql`
  mutation FileUpload($productId: ID!) {
    dynamicTemplateEditContext {
      initFileUpload(productId: $productId)
    }
  }
`;

export default FILE_UPLOAD;

import React, { useState, useCallback } from "react";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { debounce } from "lodash";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../hooks";
import { Submenu, contextMenu } from "react-contexify";
import { Box, Grid, ClickAwayListener } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { SketchPicker } from "react-color";
import { UPDATE_FOLDER_COLOR } from "../../../graphql/mutations";
import ContextMenuItem from "./ContextMenuItem";


const useStyles = makeStyles(theme => ({
  contextSubMenu: {
    fontSize: 11,
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "&:hover": {
      "& > .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__submenu-arrow": {
      fontSize: 8,
    },
  },
  contextSubMenuDisabled: {
    // [7563] pointerEvents: "none",
    "& .react-contexify__item__content": {
      opacity: 0.4,
      backgroundColor: "#7b505d",
    },
  },
  colorsWrapper: {
    padding: 5,
    display: "flex",
    flexWrap: "wrap",
    minWidth: 150,
  },
  customColorWrapper: {
    borderTop: "1px solid #000",
    padding: 5,
    background: "#fff",
  },
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: 5,
    minHeight: 100,
    zIndex: 10,
    position: "relative",
  },
  label: {
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    position: "relative",
  },
  colorPicker: {
    border: "none!important",
    padding: "0!important",
    width: "20px !important",
    height: "20px !important",
    marginRight: "10px !important",

    "& span": {
      borderRadius: "4px !important",
    },
  },
  popOver: {
    position: "absolute",
    top: "-150px",
    right: "-210px",
    zIndex: 5,
  },
}));

const RenderFolderColor = ({
  canChangeFolderIcons,
  defaultColor,
  folderColor,
  folderId,
  onDisabled,
  onFolderColorChange,
  onOpenColorDialog
}) => {
  const [updateFolderColor] = useMutation(UPDATE_FOLDER_COLOR);

  const [color, setColor] = useState(folderColor ?? "#5b5654");
  const [colorPicker, setColorPicker] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const handleUpdateFolder = async hex => {
    if (!canChangeFolderIcons) {
      onDisabled();
      return;
    }

    try {
      const res = await updateFolderColor({
        variables: { folderId, color: hex },
      });

      if (res && res.data && res.data.updateFolderColor) {
        onFolderColorChange(folderId, res.data.updateFolderColor);
        contextMenu.hideAll();
        alert.success(<FormattedMessage id="context.updatedFolderColor" />);
      } else {
        snackbar.workspaceError(
          <FormattedMessage id="context.unableToUpdateFolderColor" />
        );
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleColorSwatch = async (color) => {    
    debounceUpdateFolder(color);
  };

  const handleResetToDefaultColor = async _ => {
    debounceUpdateFolder(null);
  };

  const debounceUpdateFolder = useCallback(
    debounce(value => {
      handleUpdateFolder(value);
    }, 1200),
    []
  );

  const handleClose = () => {
    setColorPicker(false);    
  }

   const handleOnChangeComplete = hex => {
    setColor(hex);
    debounceUpdateFolder(hex);
  };

  const handleOnChange = hex => {
    setColor(hex);    
  };

  const initialColors = [
    { color: "#FF0023" },
    { color: "#fa9d00" },
    { color: "#f1c04f" },
    { color: "#f8e71c" },
    { color: "#4791db" },
    { color: "#105572" },
    { color: "#7dbdc9" },
    { color: "#60a44f" },
    { color: "#b6d7a0" },
    { color: theme.palette.primary.light },
    { color: theme.palette.primary.main },
    { color: theme.palette.primary.dark },
    { color: theme.palette.secondary.light },
    { color: theme.palette.secondary.main },
    { color: theme.palette.secondary.dark },
    { color: theme.palette.error.light },
    { color: theme.palette.error.main },
    { color: theme.palette.error.dark },
  ];

  const renderSwatchItem = (idx, color) => {
    return (
    <Box key={idx} onClick={e => handleColorSwatch(color)} sx={{
        backgroundColor: color,
        "&:hover": {
          borderWidth:"2px"
        },
        width: "15px", height: "15px",
        margin: "7px 5px",
        borderRadius: 4,
        border: `1px solid white`,

    }} ></Box>)
  }

  return (
    <Submenu
      className={clsx([
        classes.contextSubMenu,
        canChangeFolderIcons ? "" : classes.contextSubMenuDisabled,
      ])}
      label={<FormattedMessage id="context.folderColor" />}
    >
      <div
        className={classes.container}
        onClick={e => e.stopPropagation()}
        onMouseEnter={e => e.stopPropagation()}
      >
        <Box className={classes.colorsWrapper}>
          {initialColors.map((x, idx) => renderSwatchItem(idx, x.color))}
        </Box>
      </div>

        <ContextMenuItem onClick={handleResetToDefaultColor}> <FormattedMessage id="context.resetToDefaultColor" /></ContextMenuItem>
        <ContextMenuItem onClick={onOpenColorDialog}><FormattedMessage id="context.customColor" /> </ContextMenuItem>
      
    </Submenu>
  );
};

RenderFolderColor.defaultProps = {
  canChangeFolderIcons: false,
  defaultColor: "#f1c04f",
  folderColor: "#5b5654",
  folderId: null,
};

RenderFolderColor.propTypes = {
  canChangeFolderIcons: PropTypes.bool,
  defaultColor: PropTypes.string,
  folderColor: PropTypes.string,
  folderId: PropTypes.number,
  onDisabled: PropTypes.func.isRequired,
  onFolderColorChange: PropTypes.func.isRequired,
};

export default RenderFolderColor;

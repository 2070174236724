import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from 'react';
import { contextMenu } from "react-contexify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormattedMessage } from "react-intl";
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button } from '@mui/material';
import {
  Cell,
  Body,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
  Footer,
  FooterRow,
  FooterCell,
} from '@table-library/react-table-library/table';
import { useTheme } from "@table-library/react-table-library/theme";
import { TreeExpandClickTypes, useTree } from '@table-library/react-table-library/tree';
//import { findNodeById } from '@table-library/react-table-library/common/util/tree';
import ProjectDnDContextProvider from "../../context/ProjectDnDContext";
import {
  DateValueEditor,
  DurationValueEditor,
  NumberValueEditor,
  StatusValueEditor,
  StringValueEditor,
} from "../../pages/ProjectTaskPage/ValueEditors";
import CellTreeHoverMore from './CellTreeHoverMore';
import UserField from "./TaskDetails/UserField";
import {
  buildTree,
  findNodeById,
  renderDateCellValue,
  TaskAction,
  TaskAddPosition,
  TimeLogAction,
} from "./helper";
import TaskContextMenu from "./TaskContextMenu";
import ColumnModal from "./ColumnModal/ColumnModal";
import EditorContainer from "../common/EditorContainer";
import DateEditor from "../common/EditorContainer/DateEditor";
import DurationPopper from "../common/EditorContainer/DurationEditor/DurationPopper";
import StringEditor from "../common/EditorContainer/StringEditor";
import NewTaskRow from "./NewTaskRow";

const getProjectFieldColumnWidth = (field) => {
  switch (field.type) {
    case 4: return "auto"; // Duration
    case 3: return "minmax(50px,150px)"; // Status
    case 2: return "auto"; // Date
    case 1: return "auto"; // Number
    case 0: return "209px"; // String

    default:
      console.warn("** unhandled type: " + field.type);
      return "auto";
  }
};

const createColumns = (projectFields) => {
  if (!Array.isArray(projectFields) || projectFields.length === 0) {
    return [];
  }

  return projectFields.map((projectField) => {
    const { fieldId, name, type, jsonFieldSettings } = projectField;

    let field = { ...projectField };

    if (jsonFieldSettings) {
      try {
        const settings = JSON.parse(jsonFieldSettings);
        field = { ...field, settings };
      } catch (error) {
        console.error(`Error parsing settings for projectField: ${field}`, error);
      }
    }

    return {
      fieldId,
      label: name,
      renderCell: (item, onChange, enableEdit, inline, disabled) => renderProjectFieldCell(enableEdit, field, item, onChange, inline, disabled),
      type,
      width: getProjectFieldColumnWidth(field),
    };
  });
};

const createNodes = (tasksList) => {
  if (!Array.isArray(tasksList) || tasksList.length === 0) {
    return [];
  }

  return buildTree(tasksList);
};

const renderProjectFieldCell = (enableEdit, field, item, onChange, inline, disabled) => {
  const displayValue = item.values.find(n => n.fieldId === field.fieldId);

  return renderValueCell(enableEdit, field, displayValue, onChange, inline, disabled);
};

const renderValueCell = (enableEdit, field, value, onChange, inline, disabled) => {
  switch (field.type) {
    case 4: return (<DurationValueEditor enableEdit={enableEdit} field={field} value={value} onUpdateTaskValue={onChange} sx={{ justifyContent: (inline ? "flex-end" : "flex-start") }} />); // Duration
    case 3: return (<StatusValueEditor disabled={disabled} enableEdit={enableEdit} field={field} hideUnassigned={inline} onUpdateTaskValue={onChange} value={value} />); // Status
    case 2: return renderDateValueCell(enableEdit, field, value, onChange); // Date
    case 1: return <NumberValueEditor disabled={disabled} enableEdit={enableEdit} field={field} inline={inline} value={value} onUpdateTaskValue={onChange} />; // Number
    case 0: return <StringValueEditor disabled={disabled} enableEdit={enableEdit} field={field} inline={inline} value={value} onUpdateTaskValue={onChange} />; // String

    default: return "Type: " + field.type + ":" + value || "No Value"
  }
};

const renderDateValueCell = (enableEdit, field, value, onChange) => {
  return enableEdit
    ? <DateValueEditor field={field} value={value} onUpdateTaskValue={onChange} />
    : renderDateCellValue(value);
};

const getTableStyle = (columns, disabled) => {
  const cursor = disabled ? "wait" : undefined;
  const dateColumnWidth = "auto"; // "150px"
  const extra = columns.map(n => n.width).map(n => n || "auto").join(" ");
  const opacity = disabled ? 0.5 : 1;
  // Name, StartDate, EndDate, Assignments, {ProjectFieldColumns}
  //return `opacity: ${opacity}; --x-data-table-library_grid-template-columns: minmax(200px,100%) ${dateColumnWidth} ${dateColumnWidth} 150px minmax(auto,200px) ${extra};`;
  return `
    opacity: ${opacity};
    --x-data-table-library_grid-template-columns: minmax(200px,100%) ${dateColumnWidth} ${dateColumnWidth} 150px minmax(auto,200px) ${extra};
    & * { cursor: ${cursor}; }
  `;
};

const tableViewTheme = {
  Table: ``,
  Header: `
    font-size: 23px;
    font-weight: 500;
  `,
  Body: ``,
  BaseRow: `
    background-color: var(--theme-ui-colors-background);

    &.row-select-selected, &.row-select-single-selected {
      //background-color: var(--theme-ui-colors-background-secondary);
      background-color: #e7e7f7;
      color: var(--theme-ui-colors-text);
      font-weight: normal;
    }
  `,
  Row: `
    font-size: 13px;
    color: var(--theme-ui-colors-text);

    &:first-of-type .td {
      border-top-color: #edeae9;
    }

    &:last-of-type .td {
      border-bottom-color: #edeae9;
    }

    & .td {
      border-bottom-color: #edeae97f;
      border-top-color:  #edeae97f;
      &.celltree > div > div > div:nth-child(2) {
        margin-right: 20px;
        width: 100%;
      }
    }

    &.drop-before .td {
      border-top-color: #bbb;
      background: linear-gradient(to bottom, #edeae9, #fff, #fff, #fff, #fff, #fff, #fff);
    }

    &.drop-after .td {
      border-bottom-color: #bbb;
      background: linear-gradient(to bottom, #fff, #fff, #fff, #fff, #fff, #fff, #edeae9);
    }

    &.drop-subtask .td {
      border-bottom-color: #bbb;
      border-top-color: #bbb;
      //background: linear-gradient(to bottom, #fff, #fff, #fff, #edeae9, #fff, #fff, #fff);
    }

    &:hover {
      color: var(--theme-ui-colors-text-light);
    }
  `,
  BaseCell: `
    border-bottom: 1px solid transparent;
    //border-right: 1px solid transparent;
    border-top: 1px solid transparent;

    padding: 4px;
    height: 38px;

    svg {
      fill: var(--theme-ui-colors-text);
    }
  `,
  HeaderCell: `
    font-size: 13px !important;
    font-weight: 500 !important;
    text-transform: initial !important;
  `,
  Cell: `
    font-size: 13px;
    font-weight: 400;
  `,
  FooterCell: `
    font-size: 13px !important;
    font-weight: 500 !important;
  `,
};

const dateEditorTextInputProps = {
  disableUnderline: true,
  endAdornment: null,
  startAdornment: null,
  placeholder: null,
  sx: {
    '&.MuiInputBase-root': {
      padding: 0,
    },
    '&.MuiInputBase-root.MuiInput-root': {
      padding: 0,
    },
    //width: "100%",
    backgroundColor: '#ffffff',
    textAlign: 'left',
    cursor: "text",
    padding: '4px',
    fontSize: '14px',
    minHeight: '1.5em',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid rgba(0, 0, 0, 0)',
    borderRadius: "4px",
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    '&.Mui-focused': {
      border: '1px solid rgba(0, 0, 0, 0.5)',
    },
  },
};

const TASK_CONTEXT_MENU_ID = "task_context_menu";

const TableView = ({ disabled, enableEdit, onProjectUpdate, onTaskAction, onTimeLogAction, project, tasks, userLookup }) => {

  const [columns, setColumns] = useState([]);
  const [contextMenuTask, setContextMenuTask] = useState();
  const [showColumnEditor, setShowColumnEditor] = useState(false);
  const [nodes, setNodes] = useState([]);

  const data = { nodes };

  const theme = useTheme({ ...tableViewTheme, Table: getTableStyle(columns, disabled) });

  const tree = useTree(
    data,
    {
      onChange: (action, state) => { },
    },
    {
      clickType: TreeExpandClickTypes.ButtonClick,
      treeIcon: {
        margin: "2px",
        iconDefault: <ChevronRightIcon sx={{ opacity: 0, marginTop: "5px" }} fontSize="8px" />,
        iconRight: <ChevronRightIcon sx={{ marginTop: "5px" }} fontSize="8px" />,
        iconDown: <ExpandMoreIcon sx={{ marginTop: "5px" }} fontSize="8px" />,
      },
    }
  );

  const calcDropData = ({ pos, target }) => {
    let newPos = pos;
    let { taskId, parentTaskId } = target;

    if (pos === TaskAddPosition.AFTER) {
      var isExpanded = tree.state.ids.includes(taskId);
      if (isExpanded) {
        var targetNode = findNodeById(nodes, taskId);
        var hasChildren = !!targetNode.nodes;
        if (hasChildren) {
          newPos = TaskAddPosition.BEFORE;
          parentTaskId = taskId;
          taskId = targetNode.nodes[0].id;
        }
      }
    }

    return { pos: newPos, parentTaskId, taskId };
  };

  const createEmptyTask = (projectId, name) => ({
    assignments: [],
    description: "",
    endDate: null,
    id: 0,
    linkedFolderId: null,
    name: name || "",
    parentTaskId: null,
    projectId,
    projectTaskNo: 0,
    startDate: null,
    taskPropertyGroupId: null,
    taskWorkflowId: null,
    timestamp: null,
    values: [],
  });

  const handleButtonAction = ({ action, ...rest }) => {
    if (!onTaskAction) {
      console.warn("** missing onTaskAction");
      return;
    }

    const { projectId } = project;

    let data = {};

    switch (action) {
      case TaskAction.ADD:
        data = {
          parentTaskId: null,
          insertAfterTaskId: null,
          task: createEmptyTask(projectId),
        };
        break;

      case TaskAction.EDIT:
        const { task } = rest;
        data = {
          task: removeTreeProperties(cloneDeep(task)),
          taskId: task.id,
        };
        break;

      default:
        console.warn("** unhandled action", { action });
        return;
    };

    onTaskAction({ action, columns, data });
  };

  const handleDetailsButtonClick = (item, event) => {
    event.stopPropagation();
    handleButtonAction({ action: TaskAction.EDIT, task: item });
  };

  const handleDrop = ({ pos, source, target }) => {
    var { pos: newPos, parentTaskId, taskId } = calcDropData({ pos, target });

    handleTaskAction({
      action: TaskAction.MOVE,
      pos: newPos,
      source,
      target: {
        parentTaskId,
        taskId,
      },
    });
  };

  const handleNewTaskClick = (e) => {
    e.stopPropagation();
    handleButtonAction({ action: TaskAction.ADD });
  };

  const handleShowColumnModal = () => {
    setShowColumnEditor(true);
  }

  const handleRowContextMenuEvent = (event, item) => {
    const { target } = event;
    const { nodeName, offsetParent } = target;

    if (nodeName === "TD" || ["TD", "TABLE", "TR"].includes(offsetParent?.offsetParent?.nodeName)) {
      event.preventDefault();
      contextMenu.show({ id: TASK_CONTEXT_MENU_ID, event });
      setContextMenuTask(item);
    }
  };

  const handleProjectUpdate = (project) => {
    onProjectUpdate?.(project);
  }

  const renderTimeReportCell = (enableEdit, item) => {



    return <DurationPopper
      item={item}
      onAdd={handleAddTimeLog}
      onDelete={handleDeleteTimeLog}
      onUpdate={handleUpdateTimeLog}
      enableEdit={enableEdit}
      sx={{fontSize: "13px",}}
    />
  }

  const handleUpdateTimeLog = (task, reportItem, minutes, date) => {

    //onUpdate(task, reportId, timeToMinutes(time), date);
    onTimeLogAction(TimeLogAction.UPDATE, { task, reportItem, minutes, date });
  }

  const handleAddTimeLog = (task, minutes, date) => {
    onTimeLogAction(TimeLogAction.ADD, { task, minutes, date });
  }

  const handleDeleteTimeLog = (task, timeLogId) => {
    onTimeLogAction(TimeLogAction.DELETE, { task, timeLogId })
  }


  const handleTaskAction = ({ action, task, ...rest }) => {
    if (!onTaskAction) {
      console.warn("** missing onTaskAction");
      return;
    }

    const findPreviousSiblingTaskId = (parentTaskId, taskId) => {
      const siblings = tasks.filter(n => n.parentTaskId === parentTaskId);
      const currentTaskIndex = siblings.findIndex(n => n.id == taskId);
      return siblings[currentTaskIndex - 1]?.id ?? null;
    };

    const { projectId } = project;
    const { pos, source, target, taskname } = rest;

    let data = {};

    switch (action) {
      case TaskAction.INSERT_NEW:

        data = {
          parentTaskId: null,
          insertAfterTaskId: null,
          task: createEmptyTask(projectId, taskname),
        };

        break;
      case TaskAction.ADD:
        switch (pos) {
          case TaskAddPosition.AFTER:
            data = {
              parentTaskId: task.parentTaskId,
              insertAfterTaskId: task.id,
              task: createEmptyTask(projectId),
            };
            break;
          case TaskAddPosition.BEFORE:
            data = {
              parentTaskId: task.parentTaskId,
              insertAfterTaskId: findPreviousSiblingTaskId(task.parentTaskId, task.id),
              task: createEmptyTask(projectId),
            };
            break;
          case TaskAddPosition.SUBTASK:
            data = {
              parentTaskId: task.id,
              insertAfterTaskId: null,
              task: createEmptyTask(projectId),
            };
          default:
            console.warn("** unhandled parameter", { pos });
            return;
        };
        break;

      case TaskAction.EDIT:
        data = {
          task: removeTreeProperties(task),
          taskId: task.id,
        };
        break;

      case TaskAction.DELETE:
        data = {
          taskId: task.id,
        };
        break;

      case TaskAction.MOVE:
        switch (pos) {
          case TaskAddPosition.AFTER:
            data = {
              parentTaskId: target.parentTaskId,
              insertAfterTaskId: target.taskId,
              taskId: source.taskId,
            };
            break;
          case TaskAddPosition.BEFORE:
            data = {
              parentTaskId: target.parentTaskId,
              insertAfterTaskId: findPreviousSiblingTaskId(target.parentTaskId, target.taskId),
              taskId: source.taskId,
            };
            break;
          case TaskAddPosition.SUBTASK:
            data = {
              parentTaskId: target.taskId,
              insertAfterTaskId: null,
              taskId: source.taskId,
            };
            break;
          default:
            console.warn("** unhandled parameter", { pos });
            return;
        };
        break;

      default:
        console.warn("** unhandled action", { action });
        return;
    }

    onTaskAction({ action, columns, data });
  };

  const removeTreeProperties = (item) => {
    delete item.ancestors;
    delete item.parentNode;
    delete item.treeXLevel;
    delete item.treeYLevel;

    return item;
  };

  const formatDuration = (duration) => {

    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  useEffect(() => {
    if (project && Array.isArray(tasks)) {
      var nodesList = createNodes(tasks);
      setNodes(nodesList);
      var columnsList = createColumns(project.projectFields);
      setColumns(columnsList);
      setContextMenuTask(nodesList?.[0]);
    }
  }, [project, tasks]);

  const handleTaskValueChange = (fieldId, value, taskId) => {
    var field = project.projectFields.find(f => f.fieldId === fieldId);
    var valueField = '';
    switch (field.type) {

      case 4: //duration and numeric 
      case 1: valueField = 'numericValue'; break;

      case 0: //string and status
      case 3: valueField = 'stringValue'; break;

      case 2: valueField = 'dateTimeValue'; break;

      default: valueField = 'stringValue';
    }

    const task = tasks.find(n => n.id === taskId);
    if (!task) {
      console.warn("** task not found");
      return;
    }
    let updatedTask = cloneDeep(task);
    let updatedField = updatedTask.values.find(n => n.fieldId === fieldId);
    if (updatedField) {
      updatedField[valueField] = value;
    }
    else {
      updatedTask.values = [...updatedTask.values, { fieldId, [valueField]: value }];
    }

    onTaskAction({
      action: TaskAction.UPDATE,
      columns,
      data: {
        task: updatedTask,
      },
    });
  };

  const handleTaskPropertyChange = (name, value, taskId) => {
    const task = tasks.find(n => n.id === taskId);
    if (!task) {
      console.warn("** task not found");
      return;
    }

    let updatedTask = cloneDeep(task);

    updatedTask[name] = value;

    onTaskAction({
      action: TaskAction.UPDATE,
      columns,
      data: {
        task: updatedTask,
      },
    });
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0, }}>
      <Button
        disabled={disabled || !enableEdit}
        onClick={handleNewTaskClick}
        size="small"
        startIcon={<AddIcon />}
        sx={{ textTransform: 'none', margin: "-30px 4px 8px 0", padding: "1px 8px 1px 8px", fontSize: "12px" }}
        variant="outlined"
      >
        <FormattedMessage id="project.buttons.addTask" />
      </Button>

      <Button onClick={handleShowColumnModal}
        disabled={disabled || !enableEdit}
        size="small"
        startIcon={<ListIcon />}
        sx={{ textTransform: 'none', margin: "-30px 4px 8px 0", padding: "1px 8px 1px 8px", fontSize: "12px" }}
        variant="outlined"
      >
        <FormattedMessage id="project.buttons.columns" />
      </Button>

      {!disabled && (
        <TaskContextMenu
          contextMenuId={TASK_CONTEXT_MENU_ID}
          enableEdit={enableEdit}
          onAction={handleTaskAction}
          task={contextMenuTask}
        />
      )}

      {showColumnEditor && (
        <ColumnModal
          project={project}
          open={showColumnEditor}
          onClose={(e) => setShowColumnEditor(false)}
          onSave={onProjectUpdate}
        />
      )}

      <ProjectDnDContextProvider>
        <DndProvider backend={HTML5Backend}>
          <Table
            data={data}
            layout={{ custom: true }}
            style={{
              gridTemplateColumns: "var(--x-data-table-library_grid-template-columns)",
              width: "max-content",
            }}
            theme={theme}
            tree={tree}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell resize style={{ marginLeft: "2.4em" }}>Uppgift</HeaderCell>
                    <HeaderCell resize>Startdatum</HeaderCell>
                    <HeaderCell resize>Förfallodatum</HeaderCell>
                    <HeaderCell resize>Tilldelad</HeaderCell>
                    <HeaderCell resize>Rapporterad tid</HeaderCell>
                    {columns.map((column, colIndex) => (
                      <HeaderCell key={`col_${colIndex}`}>{column.label}</HeaderCell>
                    ))}
                  </HeaderRow>
                </Header>
                <Body>
                  {
                    tableList.map((item, rowIndex) => {
                      return (
                        <Row
                          item={item}
                          key={item.id}
                          onContextMenu={(e) => handleRowContextMenuEvent(e, item)}
                        >
                          <CellTreeHoverMore
                            className="celltree"
                            disabled={disabled}
                            draggable={enableEdit}
                            item={item}
                            onDrop={handleDrop}
                            onMoreClick={(e) => handleDetailsButtonClick(item, e)}
                          >
                            <EditorContainer
                              component={StringEditor}
                              disabled={disabled}
                              enableEdit={enableEdit}
                              fullWidth
                              multiline={false}
                              onChange={(newValue) => {
                                if (newValue !== item.name) {
                                  handleTaskPropertyChange("name", newValue, item.id);
                                }
                              }}
                              sx={{
                                border: "1px solid rgba(0, 0, 0, 0)",
                                borderRadius: "4px",
                                color: "rgba(0, 0, 0, 0.87)",
                                "&.MuiBox-root": { minHeight: "1.5em", padding: "0px", border: "0px", },
                                "& .MuiTypography-root": { whiteSpace: "pre-wrap", },
                                "&.MuiInputBase-root": { border: "1px solid rgba(0, 0, 0, 0.5)", },
                                "&:hover": {
                                  backgroundColor: enableEdit ? "#f0f0f0" : undefined,
                                  cursor: enableEdit ? "text" : undefined,
                                },
                              }}
                              value={item.name}
                            />
                          </CellTreeHoverMore>
                          <Cell>
                            {
                              enableEdit
                                ? <DateEditor
                                  disabled={disabled}
                                  enableEdit={enableEdit}
                                  onChange={(value) => handleTaskPropertyChange("startDate", value, item.id)}
                                  textInputProps={dateEditorTextInputProps}
                                  value={item.startDate}
                                  views={['year', 'month', 'day']}
                                />
                                : renderDateCellValue({ dateTimeValue: item.startDate })
                            }
                          </Cell>
                          <Cell>
                            {
                              enableEdit
                                ? <DateEditor
                                  disabled={disabled}
                                  enableEdit={enableEdit}
                                  onChange={(value) => handleTaskPropertyChange("endDate", value, item.id)}
                                  textInputProps={dateEditorTextInputProps}
                                  value={item.endDate}
                                  views={['year', 'month', 'day']}
                                />
                                : renderDateCellValue({ dateTimeValue: item.endDate })
                            }
                          </Cell>
                          <Cell>
                            <UserField
                              disabled={disabled}
                              editable={enableEdit}
                              hideUnassigned
                              name="assignments"
                              onChange={({ name, value }) => {
                                let task = removeTreeProperties(cloneDeep(item));
                                task[name] = value ? [value] : [];
                                onTaskAction({
                                  action: TaskAction.UPDATE,
                                  columns,
                                  data: {
                                    task,
                                  },
                                });
                              }}
                              userLookup={userLookup}
                              value={item.assignments ? item.assignments[0] : null}
                            />
                          </Cell>
                          <Cell>{renderTimeReportCell(!disabled && enableEdit, item)}</Cell>
                          {columns.map((column, colIndex) => (
                            <Cell key={`col_${colIndex}`}>
                              {
                                column.renderCell(item, (fieldId, value) => handleTaskValueChange(fieldId, value, item.id), enableEdit, true, disabled)
                              }
                            </Cell>
                          ))}
                        </Row>
                      );
                    })


                  }

                </Body>
                <Footer>

                  <FooterRow>
                    <FooterCell resize style={{ marginLeft: "2.4em" }}>
                      <NewTaskRow columns={columns} onAddTask={handleTaskAction} />
                    </FooterCell>
                    <FooterCell resize></FooterCell>
                    <FooterCell resize></FooterCell>
                    <FooterCell resize></FooterCell>
                    <FooterCell resize style={{ paddingLeft: "11px" }}>{formatDuration(tasks.reduce((sum, row) => sum + row.reportedTime, 0))}</FooterCell>
                    {columns.map((column, colIndex) => (
                      <FooterCell key={`col_${colIndex}`}></FooterCell>
                    ))}
                  </FooterRow>
                </Footer>
              </>
            )}
          </Table>
        </DndProvider>
      </ProjectDnDContextProvider>
    </Box>
  );
};

export default TableView;

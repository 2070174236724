import React, { useState } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../../TextField";
import DraggableModal from "../../DraggableModal";
import DraggableModalHead from "../../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
    width: 90,
  },
  btnLink: {
    padding: 0,
    fontSize: 13,
    textTransform: "none",
    marginLeft: 5,
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
}));

const CompanyNameModal = ({ onAddCompany, onClose, onCreateCompany, open }) => {
  const classes = useStyles();
  const intl = useIntl();

  const getDefaultValue = companyIdMode => {
    return companyIdMode
      ? ""
      : intl.formatMessage({ id: "companyNetworks.newCompany" });
  };

  const [companyIdMode, setCompanyIdMode] = useState(false);
  const [value, setValue] = useState(getDefaultValue(companyIdMode));

  const handleCompanyIdModeChange = () => {
    setValue(getDefaultValue(!companyIdMode));
    setCompanyIdMode(!companyIdMode);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={onClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={onClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        {!companyIdMode && <FormattedMessage id="common.name" />}
        {companyIdMode && <FormattedMessage id="sorting.companyId" />}
        <CustomTextField
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        {!companyIdMode && (
          <Button
            className={classes.btnLink}
            onClick={handleCompanyIdModeChange}
          >
            <FormattedMessage id="companyNetworks.iHaveCompanyId" />
          </Button>
        )}

        {companyIdMode && (
          <Button
            className={classes.btnLink}
            onClick={handleCompanyIdModeChange}
          >
            <FormattedMessage id="companyNetworks.enterCompanyName" />
          </Button>
        )}

        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnCancel} onClick={onClose}>
            <FormattedMessage id="btn.cancel" />
          </Button>
          <Button
            className={classes.btn}
            onClick={() => {
              if (companyIdMode) {
                onAddCompany({ publicCompanyId: value });
              } else {
                onCreateCompany({ name: value });
              }
            }}
          >
            <FormattedMessage id="btn.ok" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

CompanyNameModal.propTypes = {
  onAddCompany: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateCompany: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CompanyNameModal;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { TextField } from "../../common";

const useStyles = makeStyles(theme => ({
  quantityTextFieldWrapper: {
    display: "flex",
    alignItems: "center",
    "& .MuiFormControl-fullWidth": {
      width: 70,
    },
    "& input": {
      textAlign: "center",
      fontSize: 10,
      padding: "7px 12px",
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
  },
  btnGroup: {
    marginLeft: 5,
    "& button": {
      padding: "0 5px",
    },
  },
  quantityBtnLeft: {
    borderRadius: "10px 0 0 10px",
  },
  quantityBtnRight: {
    borderRadius: "0 10px 10px 0",
  },
}));

const QuantityInput = ({
  handleDecreaseQuantity,
  handleIncreaseQuantity,
  handleTextInputChange,
  value,
  min,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.quantityTextFieldWrapper}>
      <TextField
        type="number"
        value={value}
        onChange={handleTextInputChange}
        inputProps={{ min }}
      />
      <ButtonGroup className={classes.btnGroup}>
        <Button
          className={classes.quantityBtnLeft}
          onClick={handleDecreaseQuantity}
        >
          -
        </Button>
        <Button
          className={classes.quantityBtnRight}
          onClick={handleIncreaseQuantity}
        >
          +
        </Button>
      </ButtonGroup>
    </Box>
  );
};

QuantityInput.defaultProps = {
  min: 0,
  value: 0,
};

QuantityInput.propTypes = {
  min: PropTypes.number,
  value: PropTypes.number,
  handleTextInputChange: PropTypes.func.isRequired,
  handleIncreaseQuantity: PropTypes.func.isRequired,
  handleDecreaseQuantity: PropTypes.func.isRequired,
};

export default memo(QuantityInput);

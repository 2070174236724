import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  menuItem: {
    padding: "5px 14px 5px 7px",
    minHeight: "unset",
  },
  menuItemFirst: {
    padding: "5px 14px",
    minHeight: "unset",
  },
  notify: {
    position: "relative",
    cursor: "pointer",
    marginTop: 7,
    color: "#fff",
  },
  notifyIcon: {
    fontSize: 14,
    cursor: "pointer",
  },
  notifyTitle: {
    margin: "0 0 0 20px",
    fontSize: 12,
  },
  badge: {
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    textAlign: "center",
    color: theme.palette.common.black,
    fontSize: 10,
    borderRadius: "50%",
    padding: "1px 5px",
    fontWeight: "700",
    left: 10,
    bottom: 12,
    cursor: "pointer",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  noSiteText: {
    fontSize: 11,
    padding: "5px 10px",
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 4,
    width: 105,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  noSiteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
    fontSize: 12,
    marginRight: 5,
    color: theme.palette.text.lightGray,
  },
  mobileRightMenu: {
    padding: 0,
    marginLeft: 10,
  },
  notifyLink: {
    textDecoration: "none",
  },
  noSiteWrap: {
    width: "100%",
    marginTop: 15,
    justifyContent: "center",
  },
}));

const PublicMobileMenu = () => {
  const classes = useStyles();

  return (
    false && (
      <div className={clsx([classes.sectionMobile, classes.noSiteWrap])}>
        [PublicMobileMenu]
      </div>
    )
  );
};

export default PublicMobileMenu;

import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import { useAddToShoppingCart, useSnackbar } from "../../../hooks";
import { STOCK_PRODUCT } from "../../../constant/types";
import {
  getSelectedSiteOrderGroupId,
  getSiteId,
} from "../../../helpers/selectors";
import ProductOutOfStock from "../ProductOutOfStock";
import RenderProductPrice from "./RenderProductPrice";
import ProductQuantity from "./ProductQuantity";
import ProductOrderButton from "./ProductOrderButton";
import ProductAddToBasket from "./ProductAddToBasket";
import ProductDownload from "./ProductDownload";
import ProductEdit from "./ProductEdit";

const useStyles = makeStyles(theme => ({
  orderBtn: {
    boxShadow: "none",
  },
  order: {
    boxShadow: "none",
    borderRadius: 0,
    alignSelf: "flex-end",
    paddingLeft: "2%",
    paddingRight: "1%",
    width: "18%",
    paddingBottom: 10,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const ProductOrder = ({
  download,
  handleAddImageBasket,
  handleDownloadOnClick,
  handleDynamicProductOrderBtn,
  handleOpenProductEditModal,
  isImage,
  order,
  orderGroupId,
  product,
  quantity,
  setQuantity,
  breadcrumbSite,
}) => {
  const {
    productType,
    stock,
    minimumQuantity,
    isEditableFromSearchResult,
    id,
  } = product;
  const { stockBalance, temporaryOutOfStock } = stock || {};
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const sites = useSelector(state => state.api.currentViewer.viewer.sites);
  const siteId = useSelector(state => getSiteId(state));

  let { quantities } = product;

  if (!quantities) {
    console.info(`** quantities is null (${product.id})`);
    quantities = { selectionType: "UNRESTRICTED", valuesList: [1] };
  }

  const { selectionType, valuesList } = quantities;

  const [addToShoppingCart] = useAddToShoppingCart();

  const selectAll = event => {
    if (event && event.target && event.target.value) {
      event.target.select();
    }
  };

  const handleChange = event => {
    const newQuantity = Number(event.target.value);
    const isValidQuantity =
      Number.isSafeInteger(newQuantity) && newQuantity >= 0;
    setQuantity(isValidQuantity ? newQuantity : 0);
  };

  const handleBtnOrder = async () => {
    let selectedId = null;
    let groupId = null;
    if (siteId) {
      selectedId = siteId;
      groupId = orderGroupId;
    } else {
      const breadcrumb = sites.find(x => x.name === breadcrumbSite);
      selectedId = breadcrumb.id;
      groupId = breadcrumb.orderGroupId;
    }

    const item = {
      productId: product.id,
      name: product.name,
      quantity: quantity || minimumQuantity,
      dynamicFieldValues: null,
      propertyProductData: null,
      siteId: selectedId,
      source: "PRODUCT_VIEW",
    };

    addToShoppingCart({
      input: {
        item,
        orderGroupId: groupId,
      },
      onError: () => {
        snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
      },
      onSuccess: () => {
        alert.success(<FormattedMessage id="product.addedToCart" />);
      },
    });
  };

  return (
    <Card className={classes.order}>
      {product.productDisplaySettings.showPrice && !isImage && (
        <RenderProductPrice quantity={quantity} product={product} />
      )}
      {order && (
        <ProductQuantity
          valuesList={valuesList}
          selectionType={selectionType}
          quantity={quantity}
          onChange={handleChange}
          onClick={selectAll}
          defaultValue={minimumQuantity}
        />
      )}
      <Card className={classes.orderBtn}>
        {(!stockBalance || stockBalance === 0 || temporaryOutOfStock) &&
        productType === STOCK_PRODUCT ? (
          <ProductOutOfStock />
        ) : (
          <>
            {order && (
              <ProductOrderButton
                productType={productType}
                handleDynamicProductOrderBtn={handleDynamicProductOrderBtn}
                handleBtnOrder={handleBtnOrder}
                product={product}
                quantity={quantity}
              />
            )}
          </>
        )}

        {isImage && (
          <ProductAddToBasket handleAddImageBasket={handleAddImageBasket} />
        )}

        {download && (
          <ProductDownload handleDownloadOnClick={handleDownloadOnClick} />
        )}

        {isEditableFromSearchResult && (
          <ProductEdit
            handleOpenProductEditModal={handleOpenProductEditModal}
            productId={id}
          />
        )}
      </Card>
    </Card>
  );
};

ProductOrder.defaultProps = {
  orderGroupId: null,
  quantity: null,
};

ProductOrder.propTypes = {
  order: PropTypes.bool.isRequired,
  download: PropTypes.bool.isRequired,
  orderGroupId: PropTypes.number,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    minimumQuantity: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    productDisplaySettings: PropTypes.shape({
      showPrice: PropTypes.bool.isRequired,
    }).isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
    stock: PropTypes.shape({
      stockBalance: PropTypes.number.isRequired,
      temporaryOutOfStock: PropTypes.bool.isRequired,
    }),
    thumbnailImageUri: PropTypes.string,
    isEditableFromSearchResult: PropTypes.bool,
  }).isRequired,
  handleDynamicProductOrderBtn: PropTypes.func.isRequired,
  setQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  isImage: PropTypes.bool.isRequired,
  handleAddImageBasket: PropTypes.func.isRequired,
  handleDownloadOnClick: PropTypes.func.isRequired,
  breadcrumbSite: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    orderGroupId: getSelectedSiteOrderGroupId(state),
  };
};

export default connect(mapStateToProps)(ProductOrder);

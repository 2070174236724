import { handleActions } from "redux-actions";
import {
  setCompanyRoles,
  setCompanyRolesLoading,
  setAdminOpenedNodes,
  setCompany,
  setCompanies,
  setGroups,
  setGroupsLoading,
  setGroupsOpenedNodes,
  setCompanyNetwork,
  setWorkspaceOrderGroupCandidates,
  setWorkspaceOrderGroupCandidatesLoading,
} from "../../../../actions";

const defaultState = {
  company: {
    companyId: null,
    companyName: null,
    companies: [],
    usersRoles: [],
  },
  companyGroups: {
    openedNodes: [],
    groups: [],
    loading: false,
  },
  workspaceOrderGroupCandidates: {
    items: [],
    loading: false,
  },
  openedNodes: [],
  companyRoles: {
    roles: [],
    loading: false,
  },
  companyNetwork: [],
};

export default handleActions(
  {
    [setCompanyRoles]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        companyRoles: { ...state.companyRoles, roles: items },
      };
    },
    [setCompanyRolesLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        companyRoles: { ...state.companyRoles, loading },
      };
    },
    [setAdminOpenedNodes]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        openedNodes: items,
      };
    },
    [setCompany]: (state, { payload }) => {
      const { companyId, companyName, usersRights } = payload;
      return {
        ...state,
        company: {
          ...state.company,
          companyId,
          companyName,
          usersRights,
        },
      };
    },
    [setCompanies]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        company: {
          ...state.company,
          companies: items,
        },
      };
    },
    [setGroups]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        companyGroups: { ...state.companyGroups, groups: items },
      };
    },
    [setGroupsLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        companyGroups: { ...state.companyGroups, loading },
      };
    },
    [setGroupsOpenedNodes]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        companyGroups: { ...state.companyGroups, openedNodes: items },
      };
    },
    [setCompanyNetwork]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        companyNetwork: items,
      };
    },
    [setWorkspaceOrderGroupCandidates]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        workspaceOrderGroupCandidates: {
          ...state.workspaceOrderGroupCandidates,
          items,
        },
      };
    },
    [setWorkspaceOrderGroupCandidatesLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        workspaceOrderGroupCandidates: {
          ...state.workspaceOrderGroupCandidates,
          loading,
        },
      };
    },
  },
  defaultState
);

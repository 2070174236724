import { MENU_TYPES } from "../../../../constant";

const { MENU_GROUP, MENU_ITEM } = MENU_TYPES;

export const getChildMenusTreeData = ({ menu }) => {
  let menuTree = {};
  const childIds =
    menu && Array.isArray(menu.childMenus)
      ? menu.childMenus.map((child, index) => `${menu.id}-${index}`)
      : [];

  let childMenusTreeData = {};
  if (menu && menu.childMenus) {
    menu.childMenus.forEach((childMenu, menuIndex) => {
      const childMenuTreeData = getChildMenusTreeData({
        menu: {
          ...childMenu,
          id: `${menu.id}-${menuIndex}`,
          parentId: menu.id,
        },
      });
      childMenusTreeData = {
        ...childMenusTreeData,
        ...childMenuTreeData,
      };
    });
  }

  menuTree = {
    ...menuTree,
    [`${menu.id}`]: {
      ...menu,
      isRoot: false,
      childIds,
    },
    ...childMenusTreeData,
  };

  return menuTree;
};

export const getMenuDataStructure = ({ menus }) => {
  let menuTree = {};
  if (menus && Array.isArray(menus)) {
    menus.forEach((menu, menuIndex) => {
      let childMenusTreeData = {};
      let childIds = [];
      const { childMenus } = menu;
      if (childMenus && Array.isArray(childMenus)) {
        childIds = childMenus.map(
          (child, index) => `parent-${menuIndex}-${index}`
        );
        childMenus.forEach((childMenu, index) => {
          const childMenuTreeData = getChildMenusTreeData({
            menu: {
              ...childMenu,
              id: `parent-${menuIndex}-${index}`,
              parentId: `parent-${menuIndex}`,
            },
          });
          childMenusTreeData = {
            ...childMenusTreeData,
            ...childMenuTreeData,
          };
        });
      }

      menuTree = {
        ...menuTree,
        ...childMenusTreeData,
        [`parent-${menuIndex}`]: {
          ...menu,
          id: `parent-${menuIndex}`,
          isRoot: true,
          childIds,
        },
      };
    });
  }

  return menuTree;
};

export const getChildMenus = ({ siteMenuObject, ids }) => {
  const menus = [];

  ids.forEach(x => {
    const childMenu = siteMenuObject[x];
    const { customPageId, label, menuType, sitePage, childIds } =
      childMenu || {};
    let childMenus = null;
    if (childIds && Array.isArray(childIds) && childIds.length > 0) {
      childMenus = getChildMenus({ siteMenuObject, ids: childIds });
    }
    menus.push({
      customPageId,
      label,
      menuType,
      sitePage,
      childMenus,
    });
  });

  return menus;
};

const getToUpdateChildSiteMenus = ({ currentDepth, sortlyMenu }) => {
  let childMenus = [];

  if (sortlyMenu && Array.isArray(sortlyMenu) && sortlyMenu.length > 0) {
    let flag = true;

    for (let index = 0; index < sortlyMenu.length && flag; index += 1) {
      const element = sortlyMenu[index];

      const remainingSortlyMenus = sortlyMenu.slice(index + 1);
      const { depth } = element;

      const elementChildMenus = getToUpdateChildSiteMenus({
        currentDepth: depth,
        sortlyMenu: remainingSortlyMenus,
      });

      let { menuType } = element;

      if (
        elementChildMenus &&
        Array.isArray(elementChildMenus) &&
        elementChildMenus.length > 0
      ) {
        menuType = MENU_GROUP;
      }

      const newMenu = {
        customPageId: element.customPageId,
        label: element.label,
        menuType,
        sitePage: element.sitePage,
        childMenus: elementChildMenus,
      };

      if (depth - currentDepth === 1) {
        childMenus = [...childMenus, newMenu];
      } else if (currentDepth >= depth) {
        flag = false;
      }
    }
  }

  if (!childMenus || childMenus.length < 1) return null;

  return childMenus;
};

export const getToUpdateSiteMenus = ({ sortlyMenu }) => {
  let toUpdateSiteMenu = [];

  if (sortlyMenu && Array.isArray(sortlyMenu) && sortlyMenu.length > 0) {
    sortlyMenu.forEach((x, index) => {
      const { depth } = x;

      if (depth === 0) {
        const remainingSortlyMenus = sortlyMenu.slice(index + 1);
        let { menuType } = x;

        const elementChildMenus = getToUpdateChildSiteMenus({
          currentDepth: depth,
          sortlyMenu: remainingSortlyMenus,
        });

        if (
          elementChildMenus &&
          Array.isArray(elementChildMenus) &&
          elementChildMenus.length > 0
        ) {
          menuType = MENU_GROUP;
        }

        const newMenu = {
          customPageId: x.customPageId,
          label: x.label,
          menuType,
          sitePage: x.sitePage,
          childMenus: elementChildMenus,
        };
        toUpdateSiteMenu = [...toUpdateSiteMenu, newMenu];
      }
    });
  }
  return toUpdateSiteMenu;
};

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_RESOURCE_ITEM = gql`
  mutation UpdateResourceItem($update: ResourceUpdateInputType) {
    updateResource(update: $update) {
      success
      errorResult {
        ...ErrorResultFields
      }
    }
    resource {
      breadcrumb {
        folders {
          folderId
          name
        }
        siteId
        siteName
      }
      changedBy
      changedDate
      description
      downloadedBy
      downloadedDate
      fileExtension
      id
      imageMetadata {
        contact
        date
        imageNo
        isFreeToUser
        legalDocument
        location
        model
        occupation
        photographer
      }
      locked
      name
      size
      tags {
        tagId
        tagName
      }
      thumbnailImageUri
      type
      uploadedBy
      uploadedDate
      versionNo
    }
  }

  ${ErrorResultFragment}
`;

export default UPDATE_RESOURCE_ITEM;

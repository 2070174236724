import React from "react";

import { makeStyles } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DynamicFieldFooter from "../DynamicFieldFooter";
import TabPanel from "../../TabPanel";
import { QuantityField } from "../../common";
import RenderProductPrice from "../ProductModal/RenderProductPrice";

import MetaDataFields from "../ProductModal/MetaDataFields";
import ProductName from "../ProductModal/ProductName";
import ProductDescription from "../ProductModal/ProductDescription";
import ProductArtNo from "../ProductModal/ProductArtNo";
import ProductFormat from "../ProductModal/ProductFormat";
import ProductStatistics from "../ProductModal/ProductStatistics";
import ProductPageRange from "../ProductModal/ProductPageRange";
import ProductUnit from "../ProductModal/ProductUnit";
import ProductWeight from "../ProductModal/ProductWeight";
import ProductMaterial from "../ProductModal/ProductMaterial";
import ProductTags from "../ProductTags";
import CustomMetadata from "../ProductModal/CustomMetadata";

const useStyles = makeStyles(theme => ({
  dynamicWrap: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
  },
  formWrap: {
    padding: 10,
    paddingBottom: 70,
    overflow: "auto",
    minHeight: "75vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      paddingBottom: 30,
      minHeight: "unset",
    },
  },
  formWrapFlex: {
    padding: 10,
    paddingBottom: 70,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    minHeight: "75vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  noMargin: {
    margin: 0,
  },
  totalWrap: {
    marginTop: "auto",
  },
  qtyLabel: {
    margin: 0,
    fontSize: "0.8571em",
    marginBottom: 5,
    color: "#9A9A9A",
  },
  noPadding: {
    padding: 0,
  },
  productDesc: {
    fontSize: 12,
    marginTop: 10,
  },
}));

const TabPanelOrder = ({
  product,
  tab,
  quantity,
  quantityChange,
  valuesList,
  selectionType,
  handleBtnOrder,
  handleOpenConfirmationModal,
  hasError,
  editMode,
  handleDownloadPdf,
  isCheckout,
  customMetadata,
}) => {
  const classes = useStyles();

  const { imageMetadata, tags, statistics } = product;

  const { downloadCount, shareCount, viewCount } = statistics || {};

  const showPrice =
    product &&
    product.productDisplaySettings &&
    product.productDisplaySettings.showPrice
      ? product.productDisplaySettings.showPrice
      : false;

  const order =
    product && product.userPermissions && product.userPermissions.order
      ? product.userPermissions.order
      : false;

  const description =
    product && product.detailedDescription
      ? product.detailedDescription
      : product.description;

  const showQuantity = order || isCheckout;

  return (
    <>
      <TabPanel value={tab} index={1} boxCustom={classes.noPadding}>
        <div className={classes.dynamicWrap}>
          <div className={classes.formWrapFlex}>
            <ProductName name={product.name} />
            <ProductDescription description={description} />
            {product && product.articleNo && (
              <ProductArtNo articleNo={product.articleNo} />
            )}

            {product && product.format && (
              <ProductFormat format={product.format} />
            )}

            {product && product.weight && (
              <ProductWeight weight={product.weight} />
            )}
            {product && product.material && (
              <ProductMaterial material={product.material} />
            )}

            {product && product.unit && <ProductUnit unit={product.unit} />}

            {product && product.numPages && (
              <ProductPageRange numPages={product.numPages} />
            )}

            {Array.isArray(customMetadata) && customMetadata.length > 0 && (
              <>
                {customMetadata.map(metadata => {
                  if (metadata.display) {
                    return <CustomMetadata metadata={metadata} />;
                  }
                })}
              </>
            )}

            {imageMetadata && <MetaDataFields metaData={imageMetadata} />}

            <ProductStatistics
              downloads={downloadCount}
              shares={shareCount}
              views={viewCount}
            />

            {Array.isArray(tags) && tags.length > 0 && (
              <Box>
                <p className={classes.tagTitle}>
                  <FormattedMessage id="product.tagging" />
                </p>
                <ProductTags tags={tags} />
              </Box>
            )}

            {showQuantity && (
              <Grid container spacing={1} className={classes.totalWrap}>
                <Grid item lg={6}>
                  <p className={classes.qtyLabel}>
                    <FormattedMessage id="product.quantity" />
                  </p>
                  <QuantityField
                    valuesList={valuesList}
                    selectionType={selectionType}
                    quantity={quantity}
                    onChange={quantityChange}
                  />
                </Grid>
                {showPrice && (
                  <RenderProductPrice product={product} quantity={quantity} />
                )}
              </Grid>
            )}
          </div>
          <DynamicFieldFooter
            handleBtnOrder={handleBtnOrder}
            handleOpenConfirmationModal={handleOpenConfirmationModal}
            hasError={hasError}
            quantity={quantity}
            product={product}
            orderButton
            order={order}
            handleDownloadPdf={handleDownloadPdf}
            tab={tab}
            isCheckout={isCheckout}
          />
        </div>
      </TabPanel>
    </>
  );
};

TabPanelOrder.defaultProps = {
  editMode: false,
  customMetadata: null,
};

TabPanelOrder.propTypes = {
  handleDownloadPdf: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired,
  hasError: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    articleNo: PropTypes.string.isRequired,
    imageMetadata: PropTypes.shape({
      contact: PropTypes.string,
      date: PropTypes.string,
      model: PropTypes.string,
      location: PropTypes.string,
      occupation: PropTypes.string,
      photographer: PropTypes.string,
    }),
    unit: PropTypes.string,
    weight: PropTypes.number,
    material: PropTypes.string,
    numPages: PropTypes.string.isRequired,
    detailedDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productImageUri: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    statistics: PropTypes.shape({
      downloadCount: PropTypes.number,
      shareCount: PropTypes.number,
      viewCount: PropTypes.number,
    }),
    stock: PropTypes.shape({
      stockBalance: PropTypes.number.isRequired,
      temporaryOutOfStock: PropTypes.bool.isRequired,
    }),
    userPermissions: PropTypes.shape({
      order: PropTypes.bool,
      download: PropTypes.bool,
    }),
    productDisplaySettings: PropTypes.shape({ showPrice: PropTypes.string }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        tagId: PropTypes.number.isRequired,
        tagName: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  editMode: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  handleBtnOrder: PropTypes.func.isRequired,
  handleOpenConfirmationModal: PropTypes.func.isRequired,
  quantityChange: PropTypes.func.isRequired,
  valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectionType: PropTypes.string.isRequired,
  customMetadata: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TabPanelOrder;

import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";

import BookableLifts from "./BookableLifts";
import AlternativeLifts from "./AlternativeLifts";

const AvailableLifts = ({
  handleBooking,
  availableLifts,
  liftSchedStatus,
  alternativeLifts,
  avialbleLiftDate,
}) => {
  return (
    <Grid container>
      {liftSchedStatus === "BOOKABLE_AT_LATER_DATE" && (
        <AlternativeLifts
          availableLifts={availableLifts}
          alternativeLifts={alternativeLifts}
          avialbleLiftDate={avialbleLiftDate}
          handleBooking={handleBooking}
        />
      )}
      {liftSchedStatus === "BOOKABLE" && (
        <BookableLifts
          availableLifts={availableLifts}
          handleBooking={handleBooking}
        />
      )}
    </Grid>
  );
};

AvailableLifts.defaultProps = {
  availableLifts: [],
  liftSchedStatus: "",
  alternativeLifts: [],
  avialbleLiftDate: "",
};

AvailableLifts.propTypes = {
  alternativeLifts: PropTypes.arrayOf(PropTypes.shape({})),
  avialbleLiftDate: PropTypes.string,
  liftSchedStatus: PropTypes.string,
  availableLifts: PropTypes.arrayOf(PropTypes.shape({})),
  handleBooking: PropTypes.func.isRequired,
};

export default AvailableLifts;

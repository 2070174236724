import { gql } from '@apollo/client';

const REMOVE_CURRENT_USER_FROM_SHARE = gql`
  mutation RemoveCurrentUserFromShare($shareId: Int!) {
    shareContext {
      removeCurrentUserFromShare(shareId: $shareId)
    }
  }
`;

export default REMOVE_CURRENT_USER_FROM_SHARE;

import React from "react";
import {
  TableRow,
  TableCell,
  Tooltip,
  Box,
  Icon,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import IconLink from "../common/FormControl/IconLink";
import CheckboxDefault from "../common/FormControl/Checkbox";
import CustomSelect from "../common/CustomSelect";
import { subscriptionLevels } from "../../constant";

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  td: {
    padding: "4px 0",
    fontSize: 11,
    borderBottom: "none!Important",
  },
  childTd: {
    fontSize: 11,
    padding: "1px 3px 1px 9px",
  },
  tdCb: {
    padding: "4px 8px",
    paddingRight: "0 !important",
    minWidth: 16,
    borderBottom: "none!Important",
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    "& .MuiSelect-select": {
      padding: "4px 6px",
      paddingRight: 16,
      fontSize: 11,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-iconOutlined": {
      right: 1,
      top: 5,
    },
  },
  badgeCircle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 5,
  },
  badgeFree: {
    backgroundColor: "#fff",
    color: "#464646",
    border: "1px solid #989898",
  },
  badgeMini: {
    backgroundColor: "#ececec",
    color: "#00ff00",
    border: "1px solid #989898",
  },
  badgeStandard: {
    backgroundColor: "#E9F4FB",
    color: "#fff",
    border: "1px solid #2491d9",
  },
  badgeBusiness: {
    backgroundColor: "#f6fff4",
    color: "#34bf1b",
    border: "1px solid #34bf1b",
  },
  badgePro: {
    backgroundColor: "#f7f7ca",
    color: "#2491d9",
    border: "1px solid #989819",
  },
  badgePremium: {
    backgroundColor: "#FEF2E9",
    color: "#f58123",
    border: "1px solid #f58123",
  },
  editButton: {
    color: theme.palette.common.link,
    padding: "4px !important",
    marginLeft: 4,
  },
  editIcon: {
    fontSize: 10,
  },
  withChild: {
    "& td": {
      borderBottom: "none",
    },
  },
  childRow: {
    "& td": {
      borderBottom: "none",
    },
  },
  inputLabelCustomClass: {
    fontSize: 11,
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    "& .MuiInputLabel-outlined": {
      transform: "translate(12px, 12px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -3px) scale(0.75)!important",
    },
  },
}));

const ManageRolesTableRow = ({
  options,
  tooltip,
  tooltipTitle,
  editIcon,
  onClick,
  label,
  name,
  checkbox,
  onChange,
  child,
  roleValues,
  disabled,
  hideLabel,
  toolTipInfo,
  infoName,
  roleSubscriptionLevel,
}) => {
  const classes = useStyles();

  let badgeClass = "";

  if (tooltipTitle === "Free") {
    badgeClass = classes.badgeFree;
  } else if (tooltipTitle === "Mini") {
    badgeClass = classes.badgeMini;
  } else if (tooltipTitle === "Standard") {
    badgeClass = classes.badgeStandard;
  } else if (tooltipTitle === "Business") {
    badgeClass = classes.badgeBusiness;
  } else if (tooltipTitle === "Pro") {
    badgeClass = classes.badgePro;
  } else if (tooltipTitle === "Premium") {
    badgeClass = classes.badgePremium;
  }

  const showChild = child && child.length > 0;

  const renderBadgeColor = n => {
    switch (subscriptionLevels[roleSubscriptionLevel[n]]) {
      case 6:
        return classes.badgePremium;
      case 5:
        return classes.badgePro;
      case 4:
        return classes.badgeBusiness;
      case 3:
        return classes.badgeStandard;
      case 2:
        return classes.badgeMini;
      case 1:
        return classes.badgeFree;
      default:
        return classes.badgeFree;
    }
  };

  const renderTitle = n => {
    switch (subscriptionLevels[roleSubscriptionLevel[n]]) {
      case 5:
        return "Premium";
      case 4:
        return "Pro";
      case 3:
        return "Business";
      case 2:
        return "Standard";
      case 1:
        return "Free";
      default:
        return "Free";
    }
  };

  return (
    <>
      <TableRow
        className={clsx([classes.root, showChild && classes.withChild])}
      >
        {!hideLabel && (
          <TableCell className={classes.td}>
            <span style={{ display: "flex" }}>
              {tooltip && (
                <Tooltip title={tooltipTitle} placement="top">
                  <Box className={clsx([classes.badgeCircle, badgeClass])} />
                </Tooltip>
              )}

              {roleSubscriptionLevel && roleSubscriptionLevel[name] && (
                <span style={{ display: 1 }}>
                  <Tooltip title={renderTitle(name)} placement="top">
                    <Box
                      className={clsx([
                        classes.badgeCircle,
                        renderBadgeColor(name),
                      ])}
                    />
                  </Tooltip>
                </span>
              )}
              <span style={{ flex: 1 }}>{label}</span>
            </span>
          </TableCell>
        )}
        {checkbox && (
          <TableCell className={classes.tdCb} align="right">
            <CheckboxDefault
              name={name}
              onChange={onChange}
              value={roleValues[name]}
              disabled={disabled}
            />
          </TableCell>
        )}

        {!checkbox && (
          <TableCell className={classes.td}>
            <div className={classes.selectContainer}>
              {tooltip && (
                <Tooltip title={tooltipTitle} placement="top">
                  <Box className={clsx([classes.badgeCircle, badgeClass])} />
                </Tooltip>
              )}
              {!checkbox && Array.isArray(options) && options.length > 0 && (
                <CustomSelect
                  name={name}
                  inputLabel={label}
                  options={options}
                  onChange={onChange}
                  value={roleValues[name]}
                  disabled={disabled}
                  inputLabelCustomClass={classes.inputLabelCustomClass}
                />
              )}
            </div>
          </TableCell>
        )}
        {editIcon && !disabled && (
          <TableCell className={classes.tdCb} align="right">
            <IconButton className={classes.editButton} onClick={onClick}>
              <Icon className={clsx(["fa fa-pencil-alt", classes.editIcon])} />
            </IconButton>
          </TableCell>
        )}
        {!checkbox && !editIcon && (
          <TableCell className={classes.tdCb} align="right" />
        )}
        {toolTipInfo && (
          <TableCell className={classes.tdCb} align="right">
            <IconLink
              title={<FormattedMessage id={`${infoName}.${name}`} />}
              icon="nc-icon nc-alert-circle-i"
              customClass={classes.infoIcon}
            />
          </TableCell>
        )}
      </TableRow>

      {showChild && (
        <>
          {child.map(x => {
            return (
              <TableRow className={classes.childRow}>
                <TableCell className={classes.childTd}>
                  {roleSubscriptionLevel && roleSubscriptionLevel[x.name] && (
                    <span style={{ display: 1 }}>
                      <Tooltip title={renderTitle(x.name)} placement="top">
                        <Box
                          className={clsx([
                            classes.badgeCircle,
                            renderBadgeColor(x.name),
                          ])}
                        />
                      </Tooltip>
                    </span>
                  )}
                  <span style={{ flex: 1 }}> {x.label}</span>
                </TableCell>
                <TableCell className={classes.tdCb} align="right">
                  {x.checkbox && (
                    <CheckboxDefault
                      name={x.name}
                      onChange={onChange}
                      value={roleValues[x.name]}
                    />
                  )}
                </TableCell>
                {!x.checkbox && (
                  <TableCell className={classes.tdCb} align="right" />
                )}
                {x.toolTipInfo && (
                  <TableCell className={classes.tdCb} align="right">
                    <IconLink
                      title={<FormattedMessage id={`siteRoleInfo.${x.name}`} />}
                      icon="nc-icon nc-alert-circle-i"
                      customClass={classes.infoIcon}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </>
      )}
    </>
  );
};

ManageRolesTableRow.defaultProps = {
  options: null,
  tooltipTitle: null,
  tooltip: false,
  editIcon: null,
  onClick: null,
  name: null,
  checkbox: false,
  label: null,
  onChange: null,
  value: null,
  roleValues: {},
  disabled: false,
};

ManageRolesTableRow.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  checkbox: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  tooltipTitle: PropTypes.string,
  tooltip: PropTypes.bool,
  editIcon: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  child: PropTypes.arrayOf(PropTypes.shape({})),
  roleValues: PropTypes.shape({}),
};

export default ManageRolesTableRow;

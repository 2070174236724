/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Box, Typography, Icon, IconButton } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  attachmentContainer: ({ index }) => ({
    display: "flex",
    flexDirection: "column",
    marginLeft: index !== 0 ? 5 : 0,
    maxWidth: 200,
  }),
  attachmentImageContainer: {
    "&:hover > div": {
      display: "flex",
    },
  },
  fileImageAttachment: {
    width: "auto",
    height: "auto",
    minWidth: 80,
    minHeight: 80,
    "&:hover": {
      cursor: "pointer",
    },
    "& .MuiAvatar-img": {
      margin: "0 0 auto 0",
    },
  },
  fileTitle: {
    color: "inherit",
    cursor: "pointer",
    fontSize: 12,
    opacity: 0.8,
  },
  icon: {
    marginLeft: 5,
    fontSize: 15,
    lineHeight: "unset",
    "&:hover": {
      cursor: "pointer",
    },
  },
  iconGroupContainer: {
    display: "none",
    position: "relative",
    zIndex: 1,
  },
  iconGroup: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  hoverIcon: {
    fontSize: 15,
  },
  iconButton: {
    marginLeft: 5,
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.8)",
      color: "rgba(0,0,255,0.8)",
    },
  },
}));

const FileAttachment = ({ files, index, onDownload, onImageClick }) => {
  const classes = useStyles({ index });

  const { attachmentId, filename, thumbnailUrl } = files[index];

  const [showImage, setShowImage] = useState(true);

  const handleAvatarClick = () => onImageClick({ files, index });
  const handleDownloadClick = () => onDownload({ attachmentId });
  const handleFilenameClick = () => setShowImage(prev => !prev);

  return (
    <Box className={classes.attachmentContainer}>
      <Typography className={classes.fileTitle}>
        <Icon
          onClick={handleFilenameClick}
          className={clsx([
            `fas fa-caret-${showImage ? "down" : "right"}`,
            classes.icon,
          ])}
        />
        <Box component="span" onClick={handleFilenameClick}>
          {filename}
        </Box>
      </Typography>
      {showImage && (
        <Box className={classes.attachmentImageContainer}>
          <Box className={classes.iconGroupContainer}>
            <Box className={classes.iconGroup}>
              <IconButton
                className={classes.iconButton}
                onClick={handleDownloadClick}
              >
                <Icon className={clsx(["fa fa-download", classes.hoverIcon])} />
              </IconButton>
            </Box>
          </Box>
          <Avatar
            alt="?"
            className={classes.fileImageAttachment}
            onClick={handleAvatarClick}
            src={thumbnailUrl}
            variant="square"
          />
        </Box>
      )}
    </Box>
  );
};

FileAttachment.defaultProps = { files: [] };

FileAttachment.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      created: PropTypes.string,
      filename: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  onDownload: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default FileAttachment;

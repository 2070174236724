import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../hooks";
import { GET_SITE_MENU } from "../../../graphql/queries";
import Modal from "./Modal";
import { UPDATE_SITE_MENU } from "../../../graphql/mutations";
import { getMenuDataStructure, getToUpdateSiteMenus } from "./helpers";
import { MENU_TYPES } from "../../../constant";

const { MENU_GROUP, MENU_ITEM } = MENU_TYPES;

const getChildMenu = ({ depth, menus, parentIndex }) => {
  let menusTemp = [];
  menus.forEach((x, index) => {
    const newMenu = {
      id: `${parentIndex}-${index}-${x.label}-${x.sitePage}`,
      name: x.label,
      label: x.label,
      originalLabel: x.label,
      depth,
      menuType: x.menuType,
      sitePage: x.sitePage,
      customPageId: x.customPageId,
    };
    menusTemp = [...menusTemp, newMenu];

    const { childMenus } = x;

    if (childMenus && Array.isArray(childMenus) && childMenus.length > 0) {
      const childMenusArray = getChildMenu({
        depth: depth + 1,
        menus: childMenus,
      });

      menusTemp = [...menusTemp, ...childMenusArray];
    }
  });

  return menusTemp;
};

const getSiteMenu = ({ siteMenus }) => {
  let sortlyMenusTemp = [];

  const initialDepth = 0;

  siteMenus.forEach((x, index) => {
    const newMenu = {
      id: `${index}-${x.label}-${x.sitePage}`,
      name: x.label,
      label: x.label,
      originalLabel: x.label,
      depth: initialDepth,
      menuType: x.menuType,
      sitePage: x.sitePage,
      customPageId: x.customPageId,
    };
    sortlyMenusTemp = [...sortlyMenusTemp, newMenu];

    const { childMenus } = x;

    if (childMenus && Array.isArray(childMenus) && childMenus.length > 0) {
      const childMenusArray = getChildMenu({
        depth: initialDepth + 1,
        menus: childMenus,
        parentIndex: index,
      });

      sortlyMenusTemp = [...sortlyMenusTemp, ...childMenusArray];
    }
  });

  return sortlyMenusTemp;
};

const EditMenusModal = ({ open, handleClose, siteRoleId, roleName }) => {
  const intl = useIntl();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [rootMenuIds, setRootMenuIds] = useState([]);
  const [siteMenuPages, setSiteMenuPages] = useState([]);
  const [siteMenus, setSiteMenus] = useState([]);
  const [siteMenuLastIndex, setSiteMenuLastIndex] = useState(null);
  const [siteMenuObject, setSiteMenuObject] = useState({});
  const [sortlyMenu, setSortlyMenu] = useState([]);
  const [updateSiteMenu] = useMutation(UPDATE_SITE_MENU);

  const [defaultStartPage, setDefaultStartPage] = useState("");

  const { data, error, loading } = useQuery(GET_SITE_MENU, {
    variables: {
      siteRoleId,
    },
    skip: !siteRoleId || siteRoleId === undefined || siteRoleId === null,
  });

  useEffect(() => {
    if (!error && !loading) {
      if (data && data.siteRoles && data.siteRoles.getSiteMenu) {
        const {
          availablePages = [],
          defaultStartPage: defaultPage,
          // eslint-disable-next-line no-shadow
          siteMenus = [],
        } = data.siteRoles.getSiteMenu;

        const pages = availablePages.map(x => {
          return { name: x, checked: false };
        });
        setDefaultStartPage(defaultPage);
        setSiteMenuPages(pages);

        // TODO: Just for sample of menu with child
        // setSiteMenus(siteMenusSample);
        setSiteMenus(siteMenus);

        const sortlyMenuTemp = getSiteMenu({ siteMenus });

        setSortlyMenu(sortlyMenuTemp);
      }
    }
    if (error) {
      setSiteMenuPages([]);
      setSiteMenus([]);
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (siteMenus && Array.isArray(siteMenus)) {
      const newMenuDataObject = getMenuDataStructure({ menus: siteMenus });
      setSiteMenuObject(newMenuDataObject);
      const rootIds = siteMenus.map((x, index) => `parent-${index}`);
      setSiteMenuLastIndex(siteMenus.length - 1);
      setRootMenuIds(rootIds);
    } else {
      setSiteMenuObject({});
      setSiteMenuLastIndex(null);
    }
  }, [siteMenus]);

  const handleOnRemoveSortlyMenu = ({ id }) => {
    const sortlyMenuTemp = sortlyMenu.filter(x => x.id !== id);
    setSortlyMenu(sortlyMenuTemp);
  };

  const handlePageCheckboxChange = e => {
    const { name, checked } = e.target;

    const updatedSiteMenuPages = siteMenuPages.map(x => {
      if (x.name !== name) {
        return x;
      }
      return {
        ...x,
        checked,
      };
    });
    setSiteMenuPages(updatedSiteMenuPages);
  };

  const handleRenameLabel = ({ id, newLabel }) => {
    const newSortlyMenu = sortlyMenu.map(x => {
      if (x.id === id) {
        return {
          ...x,
          label: newLabel,
        };
      }
      return { ...x };
    });
    setSortlyMenu(newSortlyMenu);
  };

  const handleSaveMenu = async () => {
    try {
      if (!siteRoleId) {
        snackbar.workspaceError(
          intl.formatMessage({
            id: "siteMenu.neededSiteRole",
          })
        );

        return;
      }

      const toUpdateSiteSortleMenus = getToUpdateSiteMenus({ sortlyMenu });

      const res = await updateSiteMenu({
        variables: {
          siteRoleId,
          siteMenu: toUpdateSiteSortleMenus,
          defaultStartPage,
        },
      });

      if (
        res &&
        res.data &&
        res.data.updateSiteMenu &&
        res.data.updateSiteMenu.siteMenus
      ) {
        alert.success(
          intl.formatMessage({
            id: "siteMenu.updateSuccessful",
          })
        );
      } else {
        snackbar.workspaceError(
          intl.formatMessage({
            id: "siteMenu.updateFail",
          })
        );
      }
    } catch (err) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "siteMenu.updateFail",
        })
      );
    }
  };

  const uncheckAllSiteMenuPages = () => {
    const uncheckedSiteMenupages = siteMenuPages.map(x => {
      return {
        ...x,
        checked: false,
      };
    });
    setSiteMenuPages(uncheckedSiteMenupages);
  };

  const handlePageAddToMenu = () => {
    let toAddPages = {};

    let lastIndex = siteMenuLastIndex;
    let toAddRootMenuIds = [];
    let toAddSortlyMenu = [];

    siteMenuPages.forEach(x => {
      if (x.checked) {
        const newPageMenu = {
          childIds: [],
          childMenus: null,
          customPageId: null,
          depth: 0,
          id: `${lastIndex}-${x.name}`,
          isRoot: true,
          // label: x.name,
          menuType: MENU_ITEM,
          name: x.name,
          sitePage: x.name,
        };
        toAddPages = { ...toAddPages, [newPageMenu.id]: { ...newPageMenu } };
        lastIndex += 1;
        toAddRootMenuIds = [...toAddRootMenuIds, newPageMenu.id];
        toAddSortlyMenu = [...toAddSortlyMenu, newPageMenu];
      }
    });
    setSiteMenuObject({ ...siteMenuObject, ...toAddPages });
    setRootMenuIds([...rootMenuIds, ...toAddRootMenuIds]);
    setSiteMenuLastIndex(lastIndex);
    setSortlyMenu([...sortlyMenu, ...toAddSortlyMenu]);

    uncheckAllSiteMenuPages();
  };

  const handleAddToMenu = () => {
    let toAddPages = {};

    let lastIndex = siteMenuLastIndex;
    let toAddRootMenuIds = [];

    const newPageMenu = {
      childIds: [],
      childMenus: null,
      customPageId: null,
      depth: 0,
      id: `${lastIndex}-${"Category name goes here"}`,
      isRoot: true,
      label: "Category name goes here",
      menuType: MENU_GROUP,
      name: "Category name goes here",
      sitePage: null,
    };
    toAddPages = { ...toAddPages, [newPageMenu.id]: { ...newPageMenu } };
    lastIndex += 1;
    toAddRootMenuIds = [...toAddRootMenuIds, newPageMenu.id];
    setSiteMenuObject({ ...siteMenuObject, ...toAddPages });
    setRootMenuIds([...rootMenuIds, ...toAddRootMenuIds]);
    setSiteMenuLastIndex(lastIndex);
    setSortlyMenu([...sortlyMenu, newPageMenu]);
  };

  const handleSelectDefaultStartPage = e => {
    const { value } = e.target;
    setDefaultStartPage(value);
  };

  return (
    <Modal
      handleAddToMenu={handleAddToMenu}
      handleClose={handleClose}
      handleOnRemoveSortlyMenu={handleOnRemoveSortlyMenu}
      handlePageAddToMenu={handlePageAddToMenu}
      handlePageCheckboxChange={handlePageCheckboxChange}
      handleSaveMenu={handleSaveMenu}
      defaultStartPage={defaultStartPage}
      handleSelectDefaultStartPage={handleSelectDefaultStartPage}
      open={open}
      roleName={roleName}
      setSortlyMenu={setSortlyMenu}
      siteMenuPages={siteMenuPages}
      sortlyMenu={sortlyMenu}
      handleRenameLabel={handleRenameLabel}
    />
  );
};

EditMenusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  siteRoleId: PropTypes.number.isRequired,
};

export default EditMenusModal;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import clsx from "clsx";
import {
  TableRow,
  TableCell,
  IconButton,
  Icon,
  Collapse,
} from "@mui/material";
import StatusItemsFiles from "./StatusItemsFiles";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  td: {
    fontSize: 11,
    padding: "12px 7px",
  },
  errorTitle: {
    fontWeight: 600,
    fontSize: 11,
  },
  errorSubTitle: {
    fontSize: 11,
  },
  errorFix: {
    fontWeight: 600,
    fontSize: 11,
    color: theme.palette.primary.main,
  },
  statusIcon: {
    padding: 6,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
    borderRadius: "50%",
    marginLeft: 5,
  },
  statusIconOk: {
    backgroundColor: "#e7f7e0",
    color: "#37910f",
  },
  statusIconDanger: {
    padding: 4,
    backgroundColor: "#de514b",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#de514b",
    },
  },
  statusIconWarning: {
    padding: "5px 9px",
    backgroundColor: "#ffe5a8",
    color: "#da8f09",
  },
  action: {
    padding: 4,
  },
  actionIcon: {
    color: "#999",
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  noPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  iconButton: {
    width: 30,
  },
}));

const StatusItems = ({ folder }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { name, status, fileListItems } = folder;

  const renderStatus = value => {
    if (value === "OK") {
      return (
        <Icon
          className={clsx([
            "fas fa-check",
            classes.statusIcon,
            classes.statusIconOk,
          ])}
        />
      );
    }
    if (value === "WARNING") {
      return (
        <Icon
          className={clsx([
            "fas fa-exclamation",
            classes.statusIcon,
            classes.statusIconWarning,
          ])}
        />
      );
    }
    if (value === "ERROR") {
      return (
        <Icon
          className={clsx([
            "fas fa-exclamation-triangle",
            classes.statusIcon,
            classes.statusIconDanger,
          ])}
        />
      );
    }
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.iconButton}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.td}>
          {name}
        </TableCell>
        <TableCell className={classes.td} align="right">
          <IconButton className={classes.action}>
            <Icon className={clsx(["fa fa-download", classes.actionIcon])} />
          </IconButton>
          <IconButton className={classes.action}>
            <Icon className={clsx(["fa fa-upload", classes.actionIcon])} />
          </IconButton>
          {renderStatus(status)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.noPadding} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {(fileListItems || []).map(file => {
              return (
                <StatusItemsFiles file={file} renderStatus={renderStatus} />
              );
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

StatusItems.defaultProps = {
  folder: null,
};

StatusItems.propTypes = {
  folder: PropTypes.shape({
    fileListItems: PropTypes.arrayOf(
      PropTypes.shape({
        preflightReport: PropTypes.shape({
          failures: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      })
    ),
    name: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default StatusItems;

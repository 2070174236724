import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import HeaderBox from "./HeaderBox";
import SectionBox from "./SectionBox";
import HeaderText from "./HeaderText";
import {
  NUMBER_FIELD,
  SELECT_FIELD_MANDATORY,
  MULTI_CHECKBOX_FIELD,
} from "../../../constant/types";
import ProductionPropertyFormItem from "./ProductionPropertyFormItem";
import PropertiesFormQuantityField from "./PropertiesFormQuantityField";
import { validateFolderProperties } from "./helpers";

const getNewPropertyValueIds = (propertyValueIds, property) => {
  const newPropertyValueIds = propertyValueIds.filter(z => {
    const { propertyValues } = property;
    const found = propertyValues.find(y => y.id === z);
    if (found) return false;
    return true;
  });

  return newPropertyValueIds;
};

const PropertiesForm = ({
  folder,
  folderId,
  folderMandatoryFields,
  folderName,
  folderPropertyFormValues,
  isMonitoring,
  productionSettingErrors,
  productionSettings,
  propertyFormValues,
  resources,
  selectedFoldersProperties,
  selectedResources,
  setFolderPropertyFormValues,
  setProductionSettingErrors,
  setPropertyFormValues,
}) => {
  const { productionProperties = [] } = productionSettings || {};
  let resourcesId = [];
  if (!isMonitoring) {
    resourcesId = resources.map(x => x.id);
  }

  const switchKey = isMonitoring ? "isNotifyEnabled" : "isIncluded";

  const hasSelectedResources =
    selectedResources &&
    Array.isArray(selectedResources) &&
    selectedResources.length > 0;

  const hasSelectedFolders =
    selectedFoldersProperties &&
    Array.isArray(selectedFoldersProperties) &&
    selectedFoldersProperties.length > 0;

  const disableForm = !hasSelectedResources && !hasSelectedFolders;

  const handleOnChange = (e, property, selectedCheckbox) => {
    const { type } = property;
    const { value: fValue } = e.target;

    let fieldValue = null;
    switch (type) {
      case SELECT_FIELD_MANDATORY:
        fieldValue = fValue;
        break;
      case NUMBER_FIELD:
        fieldValue = Number(fValue);
        break;
      case MULTI_CHECKBOX_FIELD: {
        fieldValue = [...selectedCheckbox];
        break;
      }
      default:
        fieldValue = fValue;
    }

    let newFolderPropertyFormValues = [...folderPropertyFormValues];

    selectedFoldersProperties.forEach(x => {
      const folderIndex = newFolderPropertyFormValues.findIndex(
        y => y.folderId === x
      );

      let monitoredFolderTemp = {};

      if (isMonitoring) {
        monitoredFolderTemp = { folderId, name: folderName };
      } else {
        monitoredFolderTemp = { folderId, name: folderName, resourcesId };
      }

      if (folderIndex !== -1) {
        monitoredFolderTemp = {
          ...monitoredFolderTemp,
          ...newFolderPropertyFormValues[folderIndex],
        };
      } else if (folder) {
        monitoredFolderTemp = {
          ...monitoredFolderTemp,
          name: folder.name,
        };
      }

      const newQuantity =
        (monitoredFolderTemp &&
          monitoredFolderTemp.productionSettings &&
          monitoredFolderTemp.productionSettings.orderQuantity) ||
        1;
      if (type === SELECT_FIELD_MANDATORY) {
        const { productionSettings: productionSettingsFolder = {} } =
          monitoredFolderTemp || {};
        const {
          propertyValueIds = [],
          propertyDataValues = [],
        } = productionSettingsFolder;

        const newPropertyValueIds = propertyValueIds.filter(z => {
          const { propertyValues } = property;
          const found = propertyValues.find(y => y.id === z);
          if (found) return false;
          return true;
        });

        const newPropertyDataValues = propertyDataValues.filter(x => {
          const { propertyValues } = property;
          const found = propertyValues.find(y => y.id === x.propertyValue.id);
          if (found) return false;
          return true;
        });

        const propertyName = property.name;
        const propertyValue = property.propertyValues.filter(
          pV => pV.id === fValue
        );

        const newPropertyValues = {
          propertyName,
          propertyValue: propertyValue[0],
        };
        const tempPropertyDataValues = [
          ...newPropertyDataValues,
          newPropertyValues,
        ];

        monitoredFolderTemp = {
          ...monitoredFolderTemp,
          [switchKey]: validateFolderProperties(
            tempPropertyDataValues,
            folderMandatoryFields
          ),
          productionSettings: {
            ...monitoredFolderTemp.productionSettings,
            orderQuantity: newQuantity,
            propertyValueIds: [...newPropertyValueIds, fieldValue],
            propertyDataValues: tempPropertyDataValues,
          },
        };
      } else if (type === MULTI_CHECKBOX_FIELD) {
        const { productionSettings: productionSettingsFolder = {} } =
          monitoredFolderTemp || {};
        const {
          propertyValueIds = [],
          propertyDataValues = [],
        } = productionSettingsFolder;

        const newPropertyValueIds = getNewPropertyValueIds(
          propertyValueIds,
          property
        );

        const newPropertyDataValues = propertyDataValues.filter(x => {
          const { propertyValues } = property;
          const found = propertyValues.find(y => y.id === x.propertyValue.id);
          if (found) return false;
          return true;
        });

        const valueIds = fieldValue.map(v => v.value);

        const selectedValues = selectedCheckbox.map(x => {
          return {
            propertyName: property.name,
            propertyValue: { id: x.value, name: x.title },
          };
        });

        monitoredFolderTemp = {
          ...monitoredFolderTemp,
          productionSettings: {
            ...monitoredFolderTemp.productionSettings,
            orderQuantity: newQuantity,
            propertyValueIds: [...newPropertyValueIds, ...valueIds],
            propertyDataValues: [...newPropertyDataValues, ...selectedValues],
          },
        };
      }

      if (folderIndex !== -1) {
        newFolderPropertyFormValues.splice(folderIndex, 1);
      }

      newFolderPropertyFormValues = [
        ...newFolderPropertyFormValues,
        monitoredFolderTemp,
      ];
    });

    let newPropertyFormValues = [...propertyFormValues];

    selectedResources.forEach(x => {
      const fileIndex = newPropertyFormValues.findIndex(
        y => y.resourceId === x
      );

      const resourceData = resources.find(resource => resource.id === x);

      let monitoredFileTemp = {
        resourceId: x,
        folderId,
        folderName,
      };
      if (fileIndex !== -1) {
        // If file has already some settings
        monitoredFileTemp = {
          ...monitoredFileTemp,
          ...newPropertyFormValues[fileIndex],
        };
      } else if (resourceData) {
        // If file doesn't have settings yet
        monitoredFileTemp = {
          ...monitoredFileTemp,
          name: resourceData.name,
        };
      }

      const newQuantity =
        (monitoredFileTemp &&
          monitoredFileTemp.productionSettings &&
          monitoredFileTemp.productionSettings.orderQuantity) ||
        1;

      if (type === SELECT_FIELD_MANDATORY) {
        const { productionSettings: productionSettingsFile = {} } =
          monitoredFileTemp || {};
        const {
          propertyValueIds = [],
          propertyDataValues = [],
        } = productionSettingsFile;

        const newPropertyValueIds = propertyValueIds.filter(z => {
          const { propertyValues } = property;
          const found = propertyValues.find(y => y.id === z);
          if (found) return false;
          return true;
        });

        const newPropertyDataValues = propertyDataValues.filter(x => {
          const { propertyValues } = property;
          const found = propertyValues.find(y => y.id === x.propertyValue.id);
          if (found) return false;
          return true;
        });

        const propertyName = property.name;
        const propertyValue = property.propertyValues.filter(
          x => x.id === fValue
        );

        const newPropertyValues = {
          propertyName,
          propertyValue: propertyValue[0],
        };

        const tempPropertyDataValues = [
          ...newPropertyDataValues,
          newPropertyValues,
        ];

        monitoredFileTemp = {
          ...monitoredFileTemp,
          [switchKey]: validateFolderProperties(
            tempPropertyDataValues,
            folderMandatoryFields
          ),
          productionSettings: {
            ...monitoredFileTemp.productionSettings,
            orderQuantity: newQuantity,
            propertyValueIds: [...newPropertyValueIds, fieldValue],
            propertyDataValues: tempPropertyDataValues,
          },
        };
      } else if (type === MULTI_CHECKBOX_FIELD) {
        const { productionSettings: productionSettingsFile = {} } =
          monitoredFileTemp || {};
        const {
          propertyValueIds = [],
          propertyDataValues = [],
        } = productionSettingsFile;

        const newPropertyValueIds = getNewPropertyValueIds(
          propertyValueIds,
          property
        );

        const newPropertyDataValues = propertyDataValues.filter(x => {
          const { propertyValues } = property;
          const found = propertyValues.find(y => y.id === x.propertyValue.id);
          if (found) return false;
          return true;
        });

        const valueIds = fieldValue.map(v => v.value);

        const selectedValues = selectedCheckbox.map(x => {
          return {
            propertyName: property.name,
            propertyValue: { id: x.value, name: x.title },
          };
        });

        monitoredFileTemp = {
          ...monitoredFileTemp,
          productionSettings: {
            ...monitoredFileTemp.productionSettings,
            orderQuantity: newQuantity,
            propertyValueIds: [...newPropertyValueIds, ...valueIds],
            propertyDataValues: [...newPropertyDataValues, ...selectedValues],
          },
        };
      }

      if (fileIndex !== -1) {
        newPropertyFormValues.splice(fileIndex, 1);
      }

      newPropertyFormValues = [...newPropertyFormValues, monitoredFileTemp];
    });
    setFolderPropertyFormValues(newFolderPropertyFormValues);
    setPropertyFormValues(newPropertyFormValues);
  };

  const handleQuantityOnChange = e => {
    const { value } = e.target;
    const { quantityProperty } = productionSettings || {};

    const quantityValue = Number(value);
    const isPositiveValue = quantityValue > 0;

    let newFolderPropertyFormValues = [...folderPropertyFormValues];

    selectedFoldersProperties.forEach(x => {
      const folderIndex = newFolderPropertyFormValues.findIndex(
        y => y.folderId === x
      );

      let monitoredFolderTemp = { folderId, isNotifyEnabled: isPositiveValue };

      if (folderIndex !== -1) {
        monitoredFolderTemp = {
          ...monitoredFolderTemp,
          ...newFolderPropertyFormValues[folderIndex],
        };
      } else if (folder) {
        monitoredFolderTemp = {
          ...monitoredFolderTemp,
          name: folder.name,
        };
      }

      monitoredFolderTemp = {
        ...monitoredFolderTemp,
        productionSettings: {
          ...monitoredFolderTemp.productionSettings,
          orderQuantity: quantityValue,
        },
      };

      if (folderIndex !== -1) {
        newFolderPropertyFormValues.splice(folderIndex, 1);
      }

      newFolderPropertyFormValues = [
        ...newFolderPropertyFormValues,
        monitoredFolderTemp,
      ];
    });

    let newPropertyFormValues = [...propertyFormValues];

    selectedResources.forEach(x => {
      const fileIndex = newPropertyFormValues.findIndex(
        y => y.resourceId === x
      );

      const resourceData = resources.find(resource => resource.id === x);

      let monitoredFileTemp = {
        resourceId: x,
        folderId,
        isNotifyEnabled: isPositiveValue,
      };
      if (fileIndex !== -1) {
        monitoredFileTemp = {
          ...monitoredFileTemp,
          ...newPropertyFormValues[fileIndex],
        };
      } else if (resourceData) {
        // If file doesn't have settings yet
        monitoredFileTemp = {
          ...monitoredFileTemp,
          name: resourceData.name,
        };
      }

      monitoredFileTemp = {
        ...monitoredFileTemp,
        productionSettings: {
          ...monitoredFileTemp.productionSettings,
          orderQuantity: quantityValue,
        },
      };

      if (fileIndex !== -1) {
        newPropertyFormValues.splice(fileIndex, 1);
      }

      newPropertyFormValues = [...newPropertyFormValues, monitoredFileTemp];
    });
    setFolderPropertyFormValues(newFolderPropertyFormValues);
    setPropertyFormValues(newPropertyFormValues);
  };

  const { quantityProperty = {} } = productionSettings || {};
  const { quantityType } = quantityProperty || {};

  return (
    <SectionBox>
      <HeaderBox>
        <HeaderText>
          <FormattedMessage id="monitoring.changeSettings" />
        </HeaderText>
      </HeaderBox>
      <Box
        style={{
          backgroundColor: "#f5f5f5",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingTop: 6,
        }}
      >
        {productionProperties.map(property => {
          return (
            <ProductionPropertyFormItem
              key={property.id}
              disabled={disableForm}
              folderId={folderId}
              folderPropertyFormValues={folderPropertyFormValues}
              handleOnChange={handleOnChange}
              productionSettingErrors={productionSettingErrors}
              property={property}
              propertyFormValues={propertyFormValues}
              selectedFoldersProperties={selectedFoldersProperties}
              selectedResources={selectedResources}
              setProductionSettingErrors={setProductionSettingErrors}
            />
          );
        })}
        {quantityType && (
          <PropertiesFormQuantityField
            productionSettings={productionSettings}
            selectedFoldersProperties={selectedFoldersProperties}
            selectedResources={selectedResources}
            propertyFormValues={propertyFormValues}
            folderPropertyFormValues={folderPropertyFormValues}
            handleQuantityOnChange={handleQuantityOnChange}
            disabled={disableForm}
          />
        )}
      </Box>
    </SectionBox>
  );
};

PropertiesForm.defaultProps = {
  resources: [],
  folder: null,
  isMonitoring: null,
};

PropertiesForm.propTypes = {
  isMonitoring: PropTypes.bool,
  productionSettings: PropTypes.shape({
    productionProperties: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  folderId: PropTypes.number.isRequired,
  setFolderPropertyFormValues: PropTypes.func.isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedFoldersProperties: PropTypes.arrayOf(PropTypes.number).isRequired,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  folder: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default memo(PropertiesForm);

import { gql } from '@apollo/client';
import SiteMenuEditItem from "../fragments/SiteMenuEditItem";

const UPDATE_SITE_MENU = gql`
  mutation UpdateSiteMenu(
    $siteRoleId: Int!
    $siteMenu: [SiteMenuEditInput]
    $defaultStartPage: SitePage
  ) {
    updateSiteMenu(
      siteRoleId: $siteRoleId
      siteMenu: $siteMenu
      defaultStartPage: $defaultStartPage
    ) {
      siteMenus {
        ...ChildMenusRecursive
        ...SiteMenuEditItem
      }
    }
  }
  ${SiteMenuEditItem}
`;

export default UPDATE_SITE_MENU;

import { styled } from "@mui/styles";
import { Box } from "@mui/material";

const SectionBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  borderWidth: 2,
  borderColor: "#d5d5d5",
  borderStyle: "solid",
  height: "100%",
});

export default SectionBox;

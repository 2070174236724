import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #f1f1f1",
  },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  iconButtonDownload: {
    padding: 4,
    color: theme.palette.common.link,
    marginLeft: 5,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
}));

const BillingsTable = () => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>OrderNo</TableCell>
            <TableCell className={classes.th}>Order Date</TableCell>
            <TableCell className={classes.th}>User</TableCell>
            <TableCell className={classes.th}>Receiver</TableCell>
            <TableCell className={classes.th}>Reference</TableCell>
            <TableCell className={classes.th}>City</TableCell>
            <TableCell className={classes.th}>Price inc VAT</TableCell>
            <TableCell className={classes.th}>Desired delivery date</TableCell>
            <TableCell className={classes.th}>cost Center</TableCell>
            <TableCell className={classes.th}>Picked</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>Order 1234</TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
            <TableCell className={classes.td} />
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default BillingsTable;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UNLOCK_FOLDER = gql`
  mutation UnlockFolder($folderId: Int!) {
    unlockFolder(folderId: $folderId) {
      status {
        isLocked
      }
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }

  ${ErrorResultFragment}
`;

export default UNLOCK_FOLDER;

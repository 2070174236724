import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

const useStyles = makeStyles(theme => ({
  contentFoot: {
    padding: 10,
    borderTop: "1px solid #f0f0f0",
    marginTop: "auto",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
      opacity: 0.8,
    },
  },
  btnSaveIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
}));

const MainFooter = ({ handleSaveMenu }) => {
  const classes = useStyles();

  return (
    <Box className={classes.contentFoot} align="right">
      <Button
        className={clsx([classes.btnSave, "saveAddedMenu"])}
        onClick={handleSaveMenu}
      >
        <FormattedMessage id="btn.saveMenu" />
        <Icon className={clsx(["fa fa-save", classes.btnSaveIcon])} />
      </Button>
    </Box>
  );
};

MainFooter.defaultProps = {};

MainFooter.propTypes = {
  handleSaveMenu: PropTypes.func.isRequired,
};

export default MainFooter;

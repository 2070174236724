import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

const useStyles = makeStyles(theme =>
  createStyles({
    link: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      color: theme.palette.component.navbarColor,
      marginRight: 10,
      padding: "0 8px",
      textTransform: "uppercase",
      fontWeight: 600,
      display: "inline-flex",
      cursor: "pointer",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 10,
        display: "block",
        width: "100%",
        textAlign: "left",
        color: theme.palette.primary.contrastText,
      },
      "&:hover": {
        color: theme.palette.component.navbarColor,
        [theme.breakpoints.down("sm")]: {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.secondary.light,
        },
      },
      "&:focus": {
        color: theme.palette.component.navbarColor,
      },
      "& svg": {
        [theme.breakpoints.down("sm")]: {
          verticalAlign: "bottom",
        },
      },
      sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      },
    },
  })
);

const NavLink = ({ label, link, onClick, hasChild, open }) => {
  const classes = useStyles();

  return (
    <>
      {hasChild ? (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.link}
          onClick={onClick}
        >
          {label}
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      ) : (
        <Link to={link} className={classes.link} onClick={onClick}>
          {label}
        </Link>
      )}
    </>
  );
};

NavLink.defaultProps = {
  onClick: null,
  hasChild: false,
  open: false,
};

NavLink.propTypes = {
  open: PropTypes.bool,
  hasChild: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default NavLink;

import { gql } from '@apollo/client';

import StructureFieldsFragment from "../fragments/StructureFields";
import StructureRecursiveFragment from "../fragments/StructureRecursive";

// eslint-disable-next-line import/prefer-default-export
export const getViewer = gql`
  query {
    viewer {
      avatar
      companyId
      userId
      networkId
      companyLogo
      companyName
      defaultTheme
      email
      firstname
      lastname
      contact
      username
      securityLevel
      structure {
        items {
          ...StructureFields
          ...StructureRecursive
        }
      }
      sites {
        defaultStartPage
        icon
        iconColor
        description
        id
        orderGroupId
        requiredSecurityLevel
        siteRoleId
        siteOwnerDetails {
          company
          userId
          username
        }
        menuItems {
          name
          page
          childMenu {
            name
            page
          }
        }
        defaultViewType
        name
        siteRoleRights {
          allowMetadataTagging
          attestFiles
          bookingView
          canAccessRequestForProposal
          canAddToBasket
          canAddToExistingOrder
          canAttestShoppingCart
          canChangeFolderIcons
          canManageBookingSettings
          canCreateNotificationForOtherUsers
          canCreatePackages
          canEditNews
          canEditOrderHistory
          canExportSiteTemplates
          canInviteUsers
          canLock
          canDownloadFilesAndFolders
          canManageConsent
          canManageConsentDocuments
          canManageSite
          canManageSiteRoles
          canManageSuppliers
          canManageWorkspaceDashboards
          canManageRequestForProposal
          canSeeOtherSuppliers
          canPrepareOrderingInfo
          canPublishProducts
          canUnlockForOthers
          canUploadFiles
          createTemplate
          detailedImageView
          directoryCRUD
          dynamicTemplates
          fileView
          images
          imageView
          notifications
          onlineDesignerDocs
          orderAttestType
          otherDocs
          productView
          shareFolders
          showOnlyOwnFiles
          staticTemplates
          stockTemplates
          supplierInWorkspace
          tenderFolders
          uploadFilesWithoutAttest
        }
      }
      menuItems {
        name
        page
        childMenu {
          name
          page
        }
      }
    }
  }

  ${StructureFieldsFragment}
  ${StructureRecursiveFragment}
`;

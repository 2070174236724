import { useMutation } from "@apollo/client";
import { DELETE_TIME } from "../../graphql/mutations/ProjectEditContext";

// eslint-disable-next-line import/prefer-default-export
export const useDeleteTime = () => {
  const [mutation, { data, error, loading }] = useMutation(DELETE_TIME);

  const deleteTime = async ({ taskId, reportItemId }) => {
    const variables = {
        taskId,
        reportItemId      
    };

    const res = await mutation({ variables });

    return res?.data?.projects?.deleteTimeReport;
  };

  return [deleteTime, { data, error, loading }];
};

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const CREATE_NEWSPOST = gql`
  mutation CreateNewsPost($input: NewsInput) {
    newsContext {
      createNewsPost(input: $input) {
        errorResult {
          ...ErrorResultFields
        }
        success
        result {
          title
          id
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_NEWSPOST;

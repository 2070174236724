import React from "react";
import { useIntl } from "react-intl";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommonSettings from "./CommonSettings";
import ContentSettings from "./ContentSettings";
import { WIDGET_TYPES } from "../../../constant/widgets";

const useStyles = makeStyles(theme => ({
  accordion: {
    width: "100%",
    border: `1px solid #ccc`,
    boxShadow: "none",
    borderRadius: 4,
  },
  accordionDetails: {
    display: "block",
    borderTop: "1px solid #ccc",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#000",
    height: 2,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Appearance = ({
  onWidgetPropertyUpdate,
  //handleNewsSiteBackground,
  //newsSiteBG,
  selectedWidget,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { type } = selectedWidget;

  const haveContentSettings = [

    WIDGET_TYPES.NEWS_BIG,
    WIDGET_TYPES.NEWS_COLUMN,
    WIDGET_TYPES.WORKSPACES,
    WIDGET_TYPES.RECENTLY_MODIFIED_FILES,
    WIDGET_TYPES.TEXTEDITOR
  ].includes(type);

  return (
    <Accordion className={classes.accordion} defaultExpanded={true}>
      <AccordionSummary
        aria-controls="panel1a-content"
        expandIcon={<ExpandMoreIcon />}
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          {intl.formatMessage({ id: "widget.basicSetting" })}
        </Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <CommonSettings
          onWidgetPropertyUpdate={onWidgetPropertyUpdate}
          selectedWidget={selectedWidget}
        />

        {haveContentSettings && (
          <>
            <Divider className={classes.divider} />
            <ContentSettings
              onWidgetPropertyUpdate={onWidgetPropertyUpdate}              
              //handleNewsSiteBackground={handleNewsSiteBackground}              
              //newsSiteBG={newsSiteBG}
              selectedWidget={selectedWidget}
            />
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Appearance;

import { gql } from '@apollo/client';

const INIT_DOWNLOAD_WORKSPACETEMPLATE = gql`
  mutation initDownloadWorkspaceTemplate($siteId: Int!) {
    companyAdminContext {
      companyWorkspaces {
        initWorkspaceTemplateDownload(siteId: $siteId)
      }
    }
  }
`;

export default INIT_DOWNLOAD_WORKSPACETEMPLATE;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography, Icon, Box, Switch } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  text: {
    marginBottom: 15,
  },
  questionCircle: {
    fontSize: 14,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    color: "#4a90e2",
  },
}));

const SameSettings = ({ folderSameSettings, setFolderSameSettings }) => {
  const classes = useStyles();
  return (
    <Box align="right">
      <Typography className={classes.text}>
        <Switch
          checked={folderSameSettings}
          onChange={e => setFolderSameSettings(e.target.checked)}
        />
        Gör samma inställning på undermappar
        <Icon
          className={clsx(["fa fa-question-circle", classes.questionCircle])}
        />
      </Typography>
    </Box>
  );
};

SameSettings.propTypes = {
  folderSameSettings: PropTypes.bool.isRequired,
  setFolderSameSettings: PropTypes.func.isRequired,
};

export default SameSettings;

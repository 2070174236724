import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const GET_WORKSPACE_DETAILS = gql`
  query GetWorkspaceDetails($siteId: Int!) {
    companyAdminContext {
      companyWorkspaces {
        getWorkspaceDetails(siteId: $siteId) {
          details {
            description
            icon
            iconColor
            name
            networkId
            orderGroupId
            orderGroupOwnerName
            owner {
              company
              userId
              username
            }
            siteId
            storage
          }
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default GET_WORKSPACE_DETAILS;

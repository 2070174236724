import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const MOVE_USERS = gql`
  mutation MoveUsers($userIds: [Int!], $targetGroup: Int!, $companyId: Int!) {
    companyAdminContext {
      companyGroupSettings {
        moveUsers(
          userIds: $userIds
          targetGroup: $targetGroup
          companyId: $companyId
        ) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default MOVE_USERS;

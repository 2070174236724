import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import { Icon } from "@mui/material";
import EmptyCartButton from "./EmptyCartButton";
import CustomSelect from "../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  pageTitle: {
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
    fontSize: 16,
    marginTop: 0,
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 5,
    },
  },
  selectLabel: {
    marginBottom: 5,
    fontSize: 12,
  },
  infoLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    marginRight: 5,
    "&:hover": {
      color: theme.palette.common.link,
      opacity: 0.8,
    },
  },
  infoIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    verticalAlign: "bottom",
  },
  selectAddressWrap: {
    borderBottom: "1px solid #ddd",
    paddingBottom: 20,
    marginBottom: 20,
  },
}));

const DeliveryInfoHeader = ({
  addressOptions,
  handleAddressChange,
  handleRemoveAllProducts,
  selectedAddressField,
  hideEmptyCartButton,
  hideAddressDropdown,
}) => {
  const classes = useStyles();
  const preventDefault = event => event.preventDefault();

  return (
    <>
      <h5 className={classes.pageTitle}>
        <FormattedMessage id="cart.deliveryInfo" />
      </h5>
      {!hideEmptyCartButton && (
        <EmptyCartButton handleRemoveAllProducts={handleRemoveAllProducts} />
      )}
      <Grid
        container
        spacing={0}
        direction="row"
        align-items="center"
        className={classes.selectAddressWrap}
      >
        {!hideAddressDropdown && addressOptions.length > 0 && (
          <>
            <Grid item xs={12} md={2} lg={1}>
              <p className={classes.selectLabel}>
                <FormattedMessage id="cart.selectAddress" />:
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <CustomSelect
                options={addressOptions}
                value={selectedAddressField || ""}
                onChange={handleAddressChange}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

DeliveryInfoHeader.defaultProps = {
  selectedAddressField: null,
  hideEmptyCartButton: false,
  hideAddressDropdown: false,
};

DeliveryInfoHeader.propTypes = {
  addressOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  handleRemoveAllProducts: PropTypes.func.isRequired,
  selectedAddressField: PropTypes.string,
  hideEmptyCartButton: PropTypes.bool,
  hideAddressDropdown: PropTypes.bool,
};

function areEqual(prevProps, nextProps) {
  if (
    nextProps.selectedAddressField !== prevProps.selectedAddressField ||
    nextProps.addressOptions !== prevProps.addressOptions
  ) {
    return false;
  }
  return true;
}

export default memo(DeliveryInfoHeader, areEqual);

import { gql } from '@apollo/client';

const INITIALIZE_ONE_STREAM_UPLOAD = gql`
  mutation InitOneStreamUpload(
    $siteid: Int!
    $name: String
    $email: String
    $preset: String
  ) {
    initOneStreamUpload(
      siteid: $siteid
      name: $name
      email: $email
      preset: $preset
    )
  }
`;

export default INITIALIZE_ONE_STREAM_UPLOAD;

import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, TableBody, Box } from "@mui/material";
import SectionBox from "../../../common/FilesTable/SectionBox";
import FolderSection from "./FolderSection";
import FilesSection from "./FilesSection";
import FolderFileTableHeader from "./FolderFileTableHeader";
import AllFilesRow from "./AllFilesRow";

const FolderFileList = ({
  folder,
  resources,
  productionSettings,
  handleCheckboxChange,
  handleCheckboxChangeFolder,
  selectedResources,
  selectedFoldersProperties,
  propertyFormValues,
  setPropertyFormValues,
  setFolderPropertyFormValues,
  folderPropertyFormValues,
  checkedFolders,
  setCheckedFolders,
  notificationActions,
}) => {
  const [showCheckbox, setShowCheckbox] = useState(true);
  const removeFolderFromCheckedFolders = () => {
    if (checkedFolders.includes(folder.folderId)) {
      const temp = checkedFolders.filter(x => x !== folder.folderId);
      setCheckedFolders(temp);
    }
  };

  const addFolderToCheckedFolders = () => {
    if (!checkedFolders.includes(folder.folderId)) {
      setCheckedFolders([...checkedFolders, folder.folderId]);
    }
  };

  useEffect(() => {
    if (notificationActions.length > 0) {
      const dontShowCheckbox = notificationActions.some(
        action =>
          action.value === "EMAIL" || action.value === "SYSTEM_NOTIFICATION"
      );

      setShowCheckbox(!dontShowCheckbox);
    }
  }, [notificationActions]);

  useEffect(() => {
    if (
      (propertyFormValues &&
        Array.isArray(propertyFormValues) &&
        propertyFormValues.length > 0) ||
      (folderPropertyFormValues &&
        Array.isArray(folderPropertyFormValues) &&
        folderPropertyFormValues.length > 0)
    ) {
      const folderResourcesFormValues = propertyFormValues.filter(x =>
        resources.some(y => y.id === x.resourceId)
      );

      const folderFormValues = folderPropertyFormValues.find(
        x => x.folderId === folder.folderId
      );

      // if no folder form values, remove folder from checked folders
      if (!folderResourcesFormValues && !folderPropertyFormValues) {
        removeFolderFromCheckedFolders();
      } else if (folderFormValues && folderFormValues.isNotifyEnabled) {
        addFolderToCheckedFolders();
      } else if (Array.isArray(folderResourcesFormValues)) {
        if (folderResourcesFormValues.length < 1) {
          removeFolderFromCheckedFolders();
        } else {
          let hasProperties = false;
          folderResourcesFormValues.forEach(x => {
            const { isNotifyEnabled } = x;
            if (!hasProperties) {
              if (isNotifyEnabled) {
                hasProperties = true;
              }
            }
          });
          if (!hasProperties) {
            removeFolderFromCheckedFolders();
          } else {
            addFolderToCheckedFolders();
          }
        }
      }
    } else {
      removeFolderFromCheckedFolders();
    }
  }, [propertyFormValues, folderPropertyFormValues, checkedFolders]);

  const callbackFileNotifyChange = (event, resourceId) => {
    const exist = propertyFormValues.find(x => x.resourceId === resourceId);
    const { checked } = event.target;

    if (!exist) {
      let newItem = {
        resourceId,
        isNotifyEnabled: checked,
        folderId: folder.folderId,
      };
      if (checked) {
        newItem = {
          ...newItem,
          productionSettings: {
            orderQuantity: 1,
          },
        };
      }
      const newPropertyFormValues = [...propertyFormValues, newItem];
      setPropertyFormValues(newPropertyFormValues);
    } else {
      const newPropertyFormValues = propertyFormValues.map(x => {
        if (resourceId === x.resourceId) {
          let newItem = { ...x, isNotifyEnabled: checked };
          if (checked) {
            const newOrderQuantity =
              newItem &&
              newItem.productionSettings &&
              newItem.productionSettings.orderQuantity
                ? newItem.productionSettings.orderQuantity
                : 1;

            newItem = {
              ...newItem,
              productionSettings: {
                ...newItem.productionSettings,
                orderQuantity: newOrderQuantity,
              },
            };
          }
          return newItem;
        }
        return { ...x };
      });

      setPropertyFormValues(newPropertyFormValues);
    }
  };

  const handleFileNotifyChange = useCallback(
    (event, resourceId) => {
      callbackFileNotifyChange(event, resourceId);
    },
    [propertyFormValues]
  );

  const handleAllNotifySwitch = event => {
    const { checked } = event.target;

    const newPropertyFormValues = resources.map(x => {
      const fileProperty = propertyFormValues.find(y => x.id === y.resourceId);

      if (fileProperty) {
        const { productionSettings: fileProductionSettings } =
          fileProperty || {};
        const { orderQuantity } = fileProductionSettings || {};

        if (orderQuantity < 1 && checked) {
          return {
            ...fileProperty,
            productionSettings: { ...fileProductionSettings, orderQuantity: 1 },
            isNotifyEnabled: checked,
          };
        }

        return { ...fileProperty, isNotifyEnabled: checked };
      }

      if (checked) {
        return {
          resourceId: x.id,
          productionSettings: { orderQuantity: 1 },
          isNotifyEnabled: checked,
        };
      }
      return { resourceId: x.id, isNotifyEnabled: checked };
    });

    setPropertyFormValues(newPropertyFormValues);
  };

  const onChangeQuantity = ({ resourceId, value, folderId }) => {
    const { quantityProperty } = productionSettings || {};
    const { quantityType } = quantityProperty || {};

    // eslint-disable-next-line no-shadow
    let orderQuantity = 0;
    // eslint-disable-next-line no-shadow
    let quantityFixedValueId = null;

    if (quantityType === "FIXED") {
      quantityFixedValueId = Number(value);
    } else {
      const noZeroValue = value < 0 ? 0 : value;
      orderQuantity = Number(noZeroValue);
    }

    const fileIndex = propertyFormValues.findIndex(
      x => x.resourceId === resourceId
    );

    let newPropertyFormValues = [...propertyFormValues];
    let monitoredFileTemp = { resourceId, folderId };
    if (fileIndex !== -1) {
      monitoredFileTemp = {
        ...monitoredFileTemp,
        ...propertyFormValues[fileIndex],
      };
      newPropertyFormValues.splice(fileIndex, 1);
    }

    monitoredFileTemp = {
      ...monitoredFileTemp,
      productionSettings: {
        ...monitoredFileTemp.productionSettings,
        orderQuantity,
        quantityFixedValueId,
      },
    };

    if (quantityType !== "FIXED") {
      const quantityValue = Number(value);
      const isPositive = quantityValue > 0;

      monitoredFileTemp = {
        ...monitoredFileTemp,
        isNotifyEnabled: isPositive,
      };
    }

    newPropertyFormValues = [...newPropertyFormValues, monitoredFileTemp];
    setPropertyFormValues(newPropertyFormValues);
  };

  const handleRemoveFileSettings = ({ resourceId }) => {
    const newPropertyFormValues = propertyFormValues.filter(
      x => x.resourceId !== resourceId
    );

    setPropertyFormValues(newPropertyFormValues);
  };

  const handleRemoveFolderSettings = ({ folderId }) => {
    const newFolderPropertyFormValues = folderPropertyFormValues.filter(
      x => x.folderId !== folderId
    );

    setFolderPropertyFormValues(newFolderPropertyFormValues);
  };

  const handleFolderNotifyChange = (event, folderId) => {
    const { checked } = event.target;

    const isFolderHasProperties = folderPropertyFormValues.find(
      x => x.folderId === folderId
    );

    if (isFolderHasProperties) {
      const newFolderPropertyFormValues = folderPropertyFormValues.map(x => {
        if (x.folderId === folderId) {
          if (checked) {
            return {
              ...x,
              isNotifyEnabled: checked,
              productionSettings: {
                ...x.productionSettings,
                orderQuantity: 1,
              },
            };
          }
          return {
            ...x,
            isNotifyEnabled: checked,
            productionSettings: {
              ...x.productionSettings,
            },
          };
        }

        return { ...x };
      });

      setFolderPropertyFormValues(newFolderPropertyFormValues);
    } else {
      let newFolderProperty = { folderId, isNotifyEnabled: checked };

      if (checked) {
        newFolderProperty = {
          ...newFolderProperty,
          productionSettings: {
            orderQuantity: 1,
          },
        };
      }

      setFolderPropertyFormValues([
        ...folderPropertyFormValues,
        newFolderProperty,
      ]);
    }
  };

  const handleFolderQuantityChange = ({ folderId, value }) => {
    const isFolderHasProperties = folderPropertyFormValues.find(
      x => x.folderId === folderId
    );

    const quantityValue = Number(value);
    const isPositiveValue = quantityValue > 0;

    if (isFolderHasProperties) {
      const newFolderPropertyFormValues = folderPropertyFormValues.map(x => {
        if (x.folderId === folderId) {
          return {
            ...x,
            isNotifyEnabled: isPositiveValue,
            productionSettings: {
              ...x.productionSettings,
              orderQuantity: quantityValue,
            },
          };
        }
        return { ...x };
      });

      setFolderPropertyFormValues(newFolderPropertyFormValues);
    } else {
      let newFolderProperty = { folderId, isNotifyEnabled: isPositiveValue };
      newFolderProperty = {
        ...newFolderProperty,
        productionSettings: {
          orderQuantity: quantityValue,
        },
      };

      setFolderPropertyFormValues([
        ...folderPropertyFormValues,
        newFolderProperty,
      ]);
    }
  };

  return (
    <SectionBox>
      <Box>
        <Table size="small">
          <FolderFileTableHeader
            folder={folder}
            setCheckedFolders={setCheckedFolders}
            checkedFolders={checkedFolders}
            folderPropertyFormValues={folderPropertyFormValues}
            setFolderPropertyFormValues={setFolderPropertyFormValues}
            propertyFormValues={propertyFormValues}
            setPropertyFormValues={setPropertyFormValues}
          />
          <TableBody>
            <FolderSection
              showCheckbox={showCheckbox}
              folder={folder}
              productionSettings={productionSettings}
              handleCheckboxChangeFolder={handleCheckboxChangeFolder}
              selectedFoldersProperties={selectedFoldersProperties}
              handleFolderNotifyChange={handleFolderNotifyChange}
              folderPropertyFormValues={folderPropertyFormValues}
              setFolderPropertyFormValues={setFolderPropertyFormValues}
              onChangeQuantity={handleFolderQuantityChange}
              handleRemoveFolderSettings={handleRemoveFolderSettings}
            />
            <AllFilesRow handleAllNotifySwitch={handleAllNotifySwitch} />
            <FilesSection
              showCheckbox={showCheckbox}
              handleCheckboxChange={handleCheckboxChange}
              resources={resources}
              productionSettings={productionSettings}
              selectedResources={selectedResources}
              folder={folder}
              handleFileNotifyChange={handleFileNotifyChange}
              propertyFormValues={propertyFormValues}
              onChangeQuantity={onChangeQuantity}
              folderPropertyFormValues={folderPropertyFormValues}
              handleRemoveFileSettings={handleRemoveFileSettings}
            />
          </TableBody>
        </Table>
      </Box>
    </SectionBox>
  );
};

FolderFileList.defaultProps = {
  folderPropertyFormValues: [],
};

FolderFileList.propTypes = {
  folder: PropTypes.shape({ folderId: PropTypes.number }).isRequired,
  resources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productionSettings: PropTypes.shape({}).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  handleCheckboxChangeFolder: PropTypes.func.isRequired,
  selectedFoldersProperties: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  setFolderPropertyFormValues: PropTypes.func.isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
};

export default FolderFileList;

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  CircularProgress,
  Grid,
} from "@mui/material";
import FolderTreeContainer from "./FolderTreeContainer";
import { useGetSiteFolders } from "../../../hooks";

const useStyles = makeStyles(() => ({
  popper: {
    width: "100%",
    zIndex: 100,
    top: "18%!important",
    left: "0!important",
    position: "absolute!important",
  },
  paper: {
    borderRadius: 4,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    minHeight: 200,
    maxHeight: 400,
    overflowY: "auto",
  },
}));

const Folders = ({
  open,
  handleClose,
  openedNodes,
  setOpenedNodes,
  handleFolderRowClick,
}) => {
  const folders = useSelector(state => state.api.siteFolders.folders);
  const siteId = useSelector(state => state.ui.toggleWorkspaces.workspace.id);

  const classes = useStyles();
  const anchorRef = useRef(null);
  const { execute: getSiteFolders, loading } = useGetSiteFolders();

  const handleOpenNode = id => {
    const nodeIndex = openedNodes.indexOf(id);
    if (nodeIndex === -1) {
      setOpenedNodes([...openedNodes, id]);
    } else {
      const tempOpenedNodes = openedNodes.filter(x => x !== id);
      setOpenedNodes(tempOpenedNodes);
    }
  };

  const handleGetSiteFolders = () => {
    getSiteFolders({
      variables: {
        siteId,
      },
    });
  };

  useEffect(() => {
    if (open && siteId && Array.isArray(folders) && folders.length === 0) {
      handleGetSiteFolders();
    }
  }, [open, siteId]);

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      //transition
      disablePortal
      className={classes.popper}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className={classes.paper}>
              {loading && (
                <Grid container justifyContent="center">
                  <CircularProgress size={30} />
                </Grid>
              )}
              {!loading && (
                <FolderTreeContainer
                  folders={folders}
                  handleOpenNode={handleOpenNode}
                  openedNodes={openedNodes}
                  setOpenedNodes={setOpenedNodes}
                  handleFolderRowClick={handleFolderRowClick}
                />
              )}
            </Paper>
          </ClickAwayListener>
        </Grow>
      )}
    </Popper>
  );
};

Folders.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number).isRequired,
  setOpenedNodes: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  handlerFolderClick: PropTypes.func.isRequired,
};

export default Folders;

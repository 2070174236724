import React, { Fragment, useState } from "react";
import { Badge, Box, Grid, Avatar, Button, Tooltip } from "@mui/material";

import { makeStyles, withStyles } from "@mui/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormattedMessage, useIntl } from "react-intl";
import Banner from "../../../../../assets/img/bg/UserDetails.png";
import Profile from "../../../../../assets/img/default-avatar.png";
import AvatarUpload from "../AvatarUpload";
import NameRow from "../../../../UserDetails/AccountSettingsModal/BannerSection/NameRow";
import EmploymentRow from "../../../../UserDetails/AccountSettingsModal/BannerSection/EmploymentRow";
import AddressRow from "../../../../UserDetails/AccountSettingsModal/BannerSection/AddressRow";
import BioDescription from "../../../../UserDetails/AccountSettingsModal/BannerSection/BioDescription";

const SmallAvatar = withStyles(theme => ({
  root: {
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles(theme => ({
  userAvatar: {
    width: 150,
    height: 150,
    borderWidth: 7,
    borderColor: "#fff",
    borderStyle: "solid",
  },
  badgedAvatar: {
    position: "absolute",
    left: 30,
    top: 40,
  },
  btnContainer: {
    marginTop: 40,
  },
  viewAs: {
    position: "absolute",
    right: 0,
    marginRight: 18,
    top: 0,
    marginTop: 185,
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
  },
  mainContent: {
    paddingLeft: 200,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 18px",
      marginTop: 100,
    },
  },
  bioDesc: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const CameraAvatar = () => (
  <SmallAvatar>
    <CameraAltIcon style={{ color: "#000", cursor: "pointer" }} />
  </SmallAvatar>
);

const BannerSection = ({
  accountSettings,
  setAccountSettings,
  setIsViewedAs,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [openUpload, setOpenUpload] = useState(false);
  const [avatarProfile, setAvatarProfile] = useState(null);

  const {
    avatarUrl,
  } = accountSettings || {};

  return accountSettings && (
    <Fragment>
      <Box flex={1} style={{ position: "relative" }}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          //badgeContent={<CameraAvatar />}
          className={classes.badgedAvatar}
          //onClick={uploadAvatar}
        >
          <Avatar
            className={classes.userAvatar}
            src={avatarProfile || avatarUrl}
          />
        </Badge>

        <Box flex={1} style={{ height: 80, background: `url(${Banner})` }} />
        <Box flex={1} style={{ minHeight: 100 }}>
          <Grid container alignItems="center" className={classes.mainContent}>
            <div className={classes.viewAs}>
              <Tooltip title={<FormattedMessage id="tooltip.preview" />}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="small"
                  onClick={() => setIsViewedAs(true)}
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
            </div>
            <Grid item sm={12} md={6}>
              <NameRow
                accountSettings={accountSettings}
                setAccountSettings={setAccountSettings}
              />
              <EmploymentRow
                accountSettings={accountSettings}
                setAccountSettings={setAccountSettings}
              />
              <AddressRow
                accountSettings={accountSettings}
                setAccountSettings={setAccountSettings}
              />
              <BioDescription
                accountSettings={accountSettings}
                setAccountSettings={setAccountSettings}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {openUpload && (
        <AvatarUpload
          setOpenUpload={setOpenUpload}
          setAvatarProfile={setAvatarProfile}
          defaultAvatar={Profile}
          currentAvatar={avatarUrl}
        />
      )}
    </Fragment>
  );
};

BannerSection.propTypes = {};

export default BannerSection;

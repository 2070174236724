import React, { memo } from "react";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import MonitoringPanelTitle from "../MonitoringPanelTitle";
import CheckboxDefault from "../../../common/FormControl/Checkbox";

const DeleteColumn = ({ filesFoldersSettings, setFilesFolderSettings }) => {
  const intl = useIntl();

  const { fileFolderDelete } = filesFoldersSettings || {};

  return (
    <Grid item md={4}>
      <MonitoringPanelTitle
        title={<FormattedMessage id="monitoring.deleteDeletion" />}
        span={intl.formatMessage({
          id: "monitoring.update",
        })}
      />
      <Box>
        <CheckboxDefault
          label={<FormattedMessage id="monitoring.triggers.file.delete" />}
          onChange={() =>
            setFilesFolderSettings(prevFiles => {
              return { ...prevFiles, fileFolderDelete: !fileFolderDelete };
            })
          }
          value={fileFolderDelete}
        />
      </Box>
    </Grid>
  );
};

DeleteColumn.propTypes = {
  filesFoldersSettings: PropTypes.shape({
    fileFolderDelete: PropTypes.bool,
  }).isRequired,

  setFilesFolderSettings: PropTypes.func.isRequired,
};

export default memo(DeleteColumn);

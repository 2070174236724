import { gql } from '@apollo/client';

const getHelp = gql`
  query getHelp($helpKey: String!) {
    getHelp(helpKey: $helpKey) {
      helpId
      content
      relatedSections {
        helpId
        label
      }
    }
  }
`;

export default getHelp;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Icon,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import clsx from "clsx";
import DropdownDynamicTable from "./DropdownDynamicTable";
import DropdownOthersTable from "./DropdownOthersTable";
import StaffListsTable from "./StaffListsTable";
import NewDropdownModal from "./NewDropdownModal";
import NewStaffListModal from "./NewStaffListModal";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  exportLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  exportIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#F5F5F5",
    height: 2,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: 20,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  tableHead: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
  },
}));

const Lists = () => {
  const classes = useStyles();

  const [openNewDropdown, setOpenNewDropdown] = useState(false);
  const handleOpenNewDropdown = () => {
    setOpenNewDropdown(true);
  };
  const handleCloseNewDropdown = () => {
    setOpenNewDropdown(false);
  };

  const [openNewStaffList, setOpenNewStaffList] = useState(false);
  const handleOpenNewStaffList = () => {
    setOpenNewStaffList(true);
  };
  const handleCloseNewStaffList = () => {
    setOpenNewStaffList(false);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>Lists</Typography>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box className={classes.tableHead}>
              <Typography className={classes.heading}>
                Drop-down fields for dynamic templates
              </Typography>
              <Button
                className={classes.addLink}
                onClick={handleOpenNewDropdown}
              >
                <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
                Add new list
              </Button>
            </Box>
            <DropdownDynamicTable />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.tableHead}>
              <Typography className={classes.heading}>
                Drop-down fields for other purposes (Materials, Cost center
                etc.)
              </Typography>
            </Box>
            <DropdownOthersTable />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box className={classes.tableHead}>
              <Typography className={classes.heading}>Staff lists</Typography>
              <Button
                className={classes.addLink}
                onClick={handleOpenNewStaffList}
              >
                <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
                Add new list
              </Button>
            </Box>
            <StaffListsTable />
          </Grid>
        </Grid>
      </Box>
      <NewDropdownModal
        open={openNewDropdown}
        handleClose={handleCloseNewDropdown}
      />
      <NewStaffListModal
        open={openNewStaffList}
        handleClose={handleCloseNewStaffList}
      />
    </Box>
  );
};

export default Lists;

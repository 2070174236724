import React, { useEffect, useReducer } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { EditButtons } from "./Buttons";
import FieldsForm from "../../UserDetails/AccountSettingsModal/FieldsForm";
import InvoiceDetailsHeader from "./InvoiceDetailsHeader";

const ACTIONS = {
  Initialize: "INITIALIZE",
  UpdateField: "UPDATE_FIELD",
};

const initialState = {
  address: {},
  draft: false,
};

const initializeAddress = payload => ({ type: ACTIONS.Initialize, payload });
const updateAddressField = payload => ({ type: ACTIONS.UpdateField, payload });

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.Initialize:
      return { ...state, address: action.payload, draft: false };
    case ACTIONS.UpdateField:
      const { field, value } = action.payload;
      const tempAddress = { ...state.address, [field]: value };
      return { ...state, address: tempAddress, draft: true };
    default:
      return state;
  }
};

const InvoiceDetails = ({
  addressConfig,
  disabled,
  invoiceAddress,
  onCancel,
  onEdit,
  onSave,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAddressChange = (keep_this, e) => {
    const { name: field, value } = e.target;
    dispatch(updateAddressField({ field, value }));
    if (!state.draft) {
      onEdit();
    }
  };

  const handleCancel = () => {
    restore();
    onCancel();
  };

  const handleSave = () => {
    onSave({ invoiceAddress: { ...state.address } });
  };

  const restore = () => {
    dispatch(initializeAddress(invoiceAddress));
  };

  useEffect(() => {
    restore();
  }, [invoiceAddress]);

  return (
    <>
      <Grid item xs={12}>
        <InvoiceDetailsHeader />
        <FieldsForm
          addressFields={addressConfig}
          addressForm={state.address}
          hideAdornments
          handleChange={handleAddressChange}
          isEditable={!disabled}
          name="address"
        />
      </Grid>
      {state.draft && (
        <Grid container item justifyContent="flex-end" style={{ marginTop: "1.5em" }}>
          <EditButtons
            disabled={disabled}
            onCancel={handleCancel}
            onSave={handleSave}
          />
        </Grid>
      )}
    </>
  );
};

InvoiceDetails.defaultProps = {
  disabled: false,
  invoiceAddress: {},
};

InvoiceDetails.propTypes = {
  addressConfig: PropTypes.arrayOf(
    PropTypes.shape({
      displayOrder: PropTypes.number,
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string.isRequired,
    }).isRequired
  ),
  disabled: PropTypes.bool,
  invoiceAddress: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default InvoiceDetails;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  formTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  formDesc: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
  },
  closeBox: {
    position: "absolute",
    right: 10,
    top: 6,
  },
}));

const Header = ({ onClose }) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.closeBox}>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography variant="h5" component="h5" className={classes.formTitle}>
        <FormattedMessage id="widget.widgetConfigure" />
      </Typography>
      <Typography className={classes.formDesc}>
        <FormattedMessage id="widget.formSettingDesc" />
      </Typography>
    </>
  );
};

export default Header;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Masonry } from "@mui/lab";
import { useDeepLinkContext } from "../../hooks";
import { ImageViewItem } from ".";

const ImageViewMasonry = ({ numCols, products }) => {
  const deepLinkContext = useDeepLinkContext();
  const { deepLink } = deepLinkContext;

  const [linkedProductId] = useState(deepLink?.resourceId);

  useEffect(() => {
    if (deepLink) {
      deepLinkContext.clearData();
    }
  }, []);

  return (
    !deepLink && products && products.length > 0 && (
      <Masonry columns={numCols}>
        {products.map(product => (
          <ImageViewItem
            key={product.id}
            product={product}
            showProductModalOnOpen={product.id == linkedProductId} />
        ))}
      </Masonry>
    )
  );
};

ImageViewMasonry.defaultProps = {
  numCols: 6,
};

ImageViewMasonry.propTypes = {
  numCols: PropTypes.number,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      thumbnailImageUri: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ImageViewMasonry;

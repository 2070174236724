import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import OrderGroupRowItem from "./OrderGroupRowItem";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { useDeleteBasketGroup } from "../../../hooks";
import OrderGroupRowHeader from "./OrderGroupRowHeader";
import AddFolderToBasketModal from "../../ProductsMainView/AddFolderToBasketModal";

const OrderGroup = ({
  fileGroup,
  handleCheckboxChange,
  handleMultipleCheckboxChange,
  oneStream,
  packageGroup,
  selectedItemIds,
  folderIndex,
}) => {
  const [collapse, setCollapse] = useState(false);
  const [confirmDeleteGroup, setConfirmDeleteGroup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [openAddFolderToBasket, setOpenAddFolderToBasket] = useState(false);
  const alert = useAlert();

  const {
    folderId,
    items,
    packageName,
    packageId,
    selectableSuppliers = [],
    propertyProductData = [],
    quantity,
  } = packageGroup;
  const toggleConfirmDeleteGroup = () => {
    setConfirmDeleteGroup(prev => !prev);
  };

  const errorRemoveCart = () => {
    alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
    toggleConfirmDeleteGroup();
  };

  const successRemoveCart = () => {
    alert.success(<FormattedMessage id="product.successRemovedFromCart" />);
    toggleConfirmDeleteGroup();
  };

  const { execute: executeDeleteBasketGroup, loading } = useDeleteBasketGroup({
    errorRemoveCart,
    successRemoveCart,
  });

  const handleRemoveGroup = () => {
    executeDeleteBasketGroup({ packageId });
  };

  const onChange = e => {
    const ids = items.map(x => x.shoppingCartItemId);
    const { checked } = e.target;
    setIsChecked(checked);
    handleMultipleCheckboxChange(e, ids);
  };

  const handleopenAddFolderToBasket = () => {
    setOpenAddFolderToBasket(true);
  };

  const handleCloseAddFolderToBasket = () => {
    setOpenAddFolderToBasket(false);
  };

  return (
    <>
      <OrderGroupRowHeader
        isChecked={isChecked}
        onChange={onChange}
        packageName={packageName}
        toggleConfirmDeleteGroup={toggleConfirmDeleteGroup}
        setCollapse={setCollapse}
        collapse={collapse}
        propertyProductData={propertyProductData}
        selectableSuppliers={selectableSuppliers}
        quantity={quantity}
        fileGroup={fileGroup}
        items={items}
        handleopenAddFolderToBasket={handleopenAddFolderToBasket}
      />
      {items.map((item, idx) => {
        const selected = selectedItemIds.includes(item.shoppingCartItemId);

        return (
          <OrderGroupRowItem
            key={item.shoppingCartItemId}
            item={item}
            selected={selected}
            onCheckboxChange={handleCheckboxChange}
            collapse={collapse}
            oneStream={oneStream}
            idx={idx}
            folderIndex={folderIndex}
          />
        );
      })}

      {confirmDeleteGroup && (
        <ConfirmDeleteModal
          open={confirmDeleteGroup}
          setOpen={setConfirmDeleteGroup}
          loading={loading}
          onConfirmCloseModal={() => {
            handleRemoveGroup();
          }}
        />
      )}

      {openAddFolderToBasket && (
        <AddFolderToBasketModal
          open={openAddFolderToBasket}
          handleClose={handleCloseAddFolderToBasket}
          initialFolderId={folderId}
          initialPackageGroup={packageGroup}
          // folder={selectedFolder}
          // openedNodes={openedNodes}
          // initialFilesAddToCart={initialFilesAddToCart}
        />
      )}
    </>
  );
};
OrderGroup.defaultProps = {
  fileGroup: false,
  oneStream: false,
  packageGroup: {},
  selectedItemIds: [],
};

OrderGroup.propTypes = {
  packageGroup: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    packageName: PropTypes.string,
    packageId: PropTypes.string,
    selectableSuppliers: PropTypes.arrayOf(PropTypes.shape({})),
    propertyProductData: PropTypes.arrayOf(PropTypes.shape({})),
    quantity: PropTypes.number,
    folderId: PropTypes.number,
  }),
  fileGroup: PropTypes.bool,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleMultipleCheckboxChange: PropTypes.func.isRequired,
  oneStream: PropTypes.bool,
  selectedItemIds: PropTypes.arrayOf(PropTypes.string),
};

export default memo(OrderGroup);

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Button, Icon } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import DateFull from "../../common/FormControl/DateFull";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiInput-underline:before": {
      content: "none",
    },
  },
  labelDate: {
    fontSize: 12,
    fontWeight: 600,
  },
  date: {
    "& .MuiTextField-root": {
      marginTop: 6,
    },
  },
  btnSearch: {
    padding: "7px 25px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  clearLink: {
    fontSize: 12,
    marginLeft: 20,
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  clearIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
}));

const LogsFilter = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid
        container
        spacing={2}
        className={classes.date}
        alignItems="flex-end"
      >
        <Grid item xs={12} md={2}>
          <Typography className={classes.labelDate}>From</Typography>
          <DateFull />
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography className={classes.labelDate}>To</Typography>
          <DateFull />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button className={classes.btnSearch}>
            <Icon className={clsx(["fa fa-search", classes.btnIcon])} />
            <FormattedMessage id="btn.search" />
          </Button>
          <Button className={classes.clearLink}>
            <Icon className={clsx(["fas fa-times", classes.clearIcon])} />
            Clear filters
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogsFilter;

import React, { memo } from "react";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import MonitoringPanelTitle from "../MonitoringPanelTitle";
import CheckboxDefault from "../../../common/FormControl/Checkbox";

const UpdateColumn = ({ filesFoldersSettings, setFilesFolderSettings }) => {
  const intl = useIntl();
  const { fileFolderDownload, fileFolderUpdate, fileFolderLockChange } =
    filesFoldersSettings || {};

  return (
    <Grid item md={4}>
      <MonitoringPanelTitle
        title={<FormattedMessage id="monitoring.change" />}
        span={intl.formatMessage({
          id: "monitoring.update",
        })}
      />
      <Box>
        <CheckboxDefault
          label={<FormattedMessage id="monitoring.triggers.file.download" />}
          onChange={() =>
            setFilesFolderSettings(prevFiles => {
              return { ...prevFiles, fileFolderDownload: !fileFolderDownload };
            })
          }
          value={fileFolderDownload}
        />
      </Box>
      <Box>
        <CheckboxDefault
          label={<FormattedMessage id="monitoring.triggers.file.update" />}
          onChange={() =>
            setFilesFolderSettings(prevFiles => {
              return { ...prevFiles, fileFolderUpdate: !fileFolderUpdate };
            })
          }
          value={fileFolderUpdate}
        />
      </Box>
      <Box>
        <CheckboxDefault
          label={<FormattedMessage id="monitoring.triggers.file.lockChange" />}
          onChange={() =>
            setFilesFolderSettings(prevFiles => {
              return {
                ...prevFiles,
                fileFolderLockChange: !fileFolderLockChange,
              };
            })
          }
          value={fileFolderLockChange}
        />
      </Box>
    </Grid>
  );
};

UpdateColumn.propTypes = {
  filesFoldersSettings: PropTypes.shape({
    fileFolderDownload: PropTypes.bool,
    fileFolderUpdate: PropTypes.bool,
    fileFolderLockChange: PropTypes.bool,
  }).isRequired,
  setFilesFolderSettings: PropTypes.func.isRequired,
};

export default memo(UpdateColumn);

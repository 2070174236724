import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import OrderGroupTableCells from "./OrderGroupTableCells";

const useStyles = makeStyles(() => ({
  td: {
    fontSize: 12,
  },
}));

const OrderGroupRowItem = ({
  collapse,
  item,
  selected,
  onCheckboxChange,
  oneStream,
  idx,
  folderIndex,
}) => {
  const classes = useStyles();

  const handleCheckboxChange = e => {
    onCheckboxChange(e, item.shoppingCartItemId);
  };

  return (
    <TableRow style={{ visibility: collapse ? "collapse" : "visible" }}>
      <TableCell className={classes.td}>
        <CheckboxDefault value={selected} onChange={handleCheckboxChange} />
      </TableCell>
      <OrderGroupTableCells
        item={item}
        oneStream={oneStream}
        idx={idx}
        folderIndex={folderIndex}
      />
    </TableRow>
  );
};

OrderGroupRowItem.defaultProps = {
  item: {},
  collapse: false,
};

OrderGroupRowItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    quantity: PropTypes.number,
    selectableSuppliers: PropTypes.arrayOf(PropTypes.shape({})),
    price: PropTypes.number,
    siteSupplierId: PropTypes.number,
    shoppingCartItemId: PropTypes.string,
    propertyProductData: PropTypes.arrayOf(
      PropTypes.shape({
        propertyName: PropTypes.string,
        propertyValue: PropTypes.string,
        propertyValueId: PropTypes.number,
      })
    ),
  }),
  selected: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  collapse: PropTypes.bool,
};

export default memo(OrderGroupRowItem);

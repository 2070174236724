import { validateEmail } from "../../../../helpers/validators";

const c3validators = {
  validateC3: function(editMode, formValues) {
    var network = c3validators.validateNetwork(
      editMode,
      formValues?.networkId || 0
    );
    var passwords = c3validators.validatePasswords(
      editMode,
      formValues?.password || "",
      formValues?.passwordConfirm || "",
      formValues?.currentPassword || ""
    );
    var role = c3validators.validateRole(
      editMode,
      formValues?.companyRoleId || 0
    );
    var username = c3validators.validateUsername(
      editMode,
      formValues?.username || ""
    );

    return {
      ...network,
      ...passwords,
      ...role,
      ...username,
    };
  },
  validateNetwork: function(editMode, networkId) {
    var haveSelectedNetwork = networkId !== 0;

    return {
      network: haveSelectedNetwork,
    };
  },
  validatePasswords: function(
    editMode,
    password,
    passwordConfirm,
    currentPassword = null
  ) {
    const MIN_PASSWORD_LENGTH = 8;

    var haveCurrentPassword =
      currentPassword !== null && currentPassword.length > 0;
    var haveMinLength = password.length >= MIN_PASSWORD_LENGTH;
    var havePassword = password.length > 0;
    var matchesPassword = passwordConfirm === password;

    var validCurrentPassword = editMode
      ? haveCurrentPassword || !havePassword
      : true;
    var validPassword = editMode
      ? haveMinLength || !(haveCurrentPassword || havePassword)
      : haveMinLength;

    return {
      currentPassword: validCurrentPassword,
      password: validPassword,
      passwordConfirm: matchesPassword,
    };
  },
  validateRole: function(editMode, roleId) {
    var haveSelectedRole = roleId !== 0;

    return {
      role: haveSelectedRole,
    };
  },
  validateUsername: function(editMode, username) {
    var isValidEnough = editMode || validateEmail(username);

    return {
      username: isValidEnough,
    };
  },
};

export default c3validators;

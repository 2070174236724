import React from "react";
import { makeStyles } from "@mui/styles";
import DialogActions from '@mui/material/DialogActions';

const useStyles = makeStyles(() => ({
  root: {
    padding: 6,
  },
}));

const ModalCompactActions = ({ children, customClass, ...rest }) => {
  const classes = useStyles();

  return (
    <DialogActions classes={{ root: classes.root }} className={customClass} {...rest}>
      {children}
    </DialogActions>
  );
};

export default ModalCompactActions;

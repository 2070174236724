import { gql } from '@apollo/client';

const GET_FIELDS = gql`
  query GetFields($productId: ID!) {
    dynamicTemplateEditContext {
      getFields(productId: $productId) {
        fieldForOrderFile
        productId
        textListDictionary {
          textListId
          name
        }
        imageResourceDictionary {
          images {
            name
            resourceId
          }
        }
        templateFields {
          description
          displayName
          fieldName
          fieldType
          fieldValue
          imageFolderId
          maxLength
          required
          sortNo
          textListId
        }
      }
    }
  }
`;

export default GET_FIELDS;

import PropTypes from "prop-types";
import { useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageWithFallback from "../../../common/ImageWithFallback";
import ImagePlaceHolder from "../../../../assets/img/placeholder.jpg";

const useStyles = makeStyles(theme => ({
  img: {
    maxWidth: 32,
    maxHeight: 32,
    objectFit: "cover",
    marginRight: 10,
    border: "1px solid #e8e8e8",
    boxShadow: "0 2px 5px #efefef",
  },
  popper: {
    width: 400,
    height: 400,
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.6)",
    width: "100%",
    maxWidth: "unset",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  tdImageName: { display: "flex", alignItems: "center" },
}));

const ResourceImage = ({ highResImage, image, name }) => {
  // File view (metadata dialog)
  const classes = useStyles();

  const [hoverImage, setHoverImage] = useState(image);

  return (
    <Box
      className={classes.tdImageName}
      component="div"
    >
      <Tooltip
        arrow
        classes={{
          popper: classes.popper,
          tooltip: classes.tooltip,
        }}
        disabled
        placement="right"
        title={
          <ImageWithFallback
            alt={name}
            //errorImageUrl={ImagePlaceHolder}
            imageUrl={hoverImage}
            onLoad={() => setHoverImage(highResImage)}
          />
        }
      >
        <ImageWithFallback
          alt={name}
          className={classes.img}
          //errorImageUrl={ImagePlaceHolder}
          imageUrl={image || ImagePlaceHolder}
        />
      </Tooltip>
      {name}
    </Box>
  );
};

ResourceImage.propTypes = {
  highResImage: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ResourceImage;

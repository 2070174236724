import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import SelectInline from "../../common/FormControl/SelectInline";

const useStyles = makeStyles(theme => ({
  cbWrap: {
    paddingLeft: 25,
    "& .MuiSelect-select": {
      padding: "4px 12px",
      fontSize: 10,
    },
  },
  infoIcon: {
    color: theme.palette.common.link,
  },
}));

const ColorModel = ({
  setShowColorModel,
  showColorModel,
  selectedColorMode,
  setSelectedColorMode,
  colorModelOptions,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.cbWrap}>
      <CheckboxDefault
        label={<FormattedMessage id="imageBasket.colorModel" />}
        onChange={() => setShowColorModel(prevCheck => !prevCheck)}
        value={showColorModel}
      />
      {showColorModel && (
        <SelectInline
          label=""
          value={selectedColorMode}
          options={colorModelOptions}
          onChange={e => setSelectedColorMode(e.target.value)}
        />
      )}
    </Box>
  );
};

ColorModel.propTypes = {
  setShowColorModel: PropTypes.func.isRequired,
  showColorModel: PropTypes.bool.isRequired,
  selectedColorMode: PropTypes.string.isRequired,
  setSelectedColorMode: PropTypes.func.isRequired,
  colorModelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default ColorModel;

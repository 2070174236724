import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { GET_COMPANIES_LOOKUP } from "../../../graphql/queries/CompanyAdminContext";
import { getCurrentCompanyId } from "../../../helpers/selectors";
import { getAdminAvailableCompanies } from "../../../helpers/adminSelectors";
import CustomSelect from "../../common/CustomSelect";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    marginBottom: 10,
  },
  customSelect: {
    minWidth: "30%",
    width: "fit-content",
  },
  divider: {
    marginBottom: 15,
    marginTop: 15,
  },
  selectorHeading: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
  },
}));

const InvitingCompanySelector = ({ onChange }) => {
  const classes = useStyles();
  const intl = useIntl();

  const companies = useSelector(getAdminAvailableCompanies);
  const currentCompanyId = useSelector(getCurrentCompanyId);

  const [getCompaniesLookup, { data }] = useLazyQuery(GET_COMPANIES_LOOKUP);

  const [options, setOptions] = useState([...companies]);
  const [value, setValue] = useState(currentCompanyId);

  const handleCompanyChange = (event, child) => {
    const companyId = event?.target?.value;
    setValue(companyId);
    onChange?.({ companyId });
  };

  useEffect(() => {
    if (Array.isArray(companies) && companies.length === 0) {
      getCompaniesLookup();
    }
  }, [companies]);

  useEffect(() => {
    const availableCompanies = data?.companyAdminContext?.availableCompanies;
    if (!!availableCompanies && Array.isArray(availableCompanies)) {
      const items = availableCompanies.map(x => (
        {
          label: x.name,
          value: x.companyId,
        }
      ));

      setOptions(items);
    }
  }, [data]);

  return (
    (options && options.length > 1) && (
      <>
        <Box className={classes.root}>
          <Typography className={classes.selectorHeading}>
            <FormattedMessage id="siteUsers.fromWhichCompany" />
          </Typography>
          <CustomSelect
            customClass={classes.customSelect}
            inputLabel={intl.formatMessage({ id: "siteUsers.selectCompany" })}
            onChange={handleCompanyChange}
            options={options}
            value={value}
          />
        </Box>
        <Divider className={classes.divider} />
      </>
    )
  );
};

InvitingCompanySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default InvitingCompanySelector;

import { gql } from '@apollo/client';

const SEARCH_COMPANYCARDS = gql`query searchCompanyCards($companyId:Int!, $searchTerm:String){
	companyAdminContext{
		salesDocuments{
			searchCompanyCards(companyId:$companyId, searchTerm:$searchTerm){
				address{
					address1
					address2
					address3
					address4
					address5
					city
					contact
					country
					customerId
					email
					reference
					telephone
					zip
				}
				companyAddressCardId
				companyName
				gLN
				orgNo
				paymentTermsDays
				vatRegNo
			}
		}
	}
}`;

export default SEARCH_COMPANYCARDS;

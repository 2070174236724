import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Box, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getSiteId } from "../../../helpers/selectors";
import { useGetRecentlyModifiedResourcesForSite } from "../../../hooks";
import DashboardWidget from "../../common/DashboardWidget";
import DashboardWidgetBody from "../../common/DashboardWidgetBody";
import DashboardWidgetHead from "../../common/DashboardWidgetHead";
import RecentlyModifiedItem from "./RecentlyModifiedItem";

const useStyles = makeStyles(theme => ({
  container: {
    background: "transparent",
    maxHeight: "99vh",
    minHeight: "99vh",
    overflowY: "auto",
    padding: 10,
  },
  skeleton: {
    margin: 5,
  },
}));

const RecentlyModifiedWidget = ({ layout }) => {
  const { name } = layout || {};

  const classes = useStyles();
  const siteId = useSelector(state => getSiteId(state));

  const { items, loading, error } = useGetRecentlyModifiedResourcesForSite({
    siteId,
  });

  const skeletons = count => {
    let skeletonItems = [];
    for (var idx = 0; idx < count; idx++) {
      skeletonItems.push(
        <Box key={idx}>
          <Skeleton
            animation="wave"
            className={classes.skeleton}
            height={50}
            variant="rect"
          />
        </Box>
      );
    }
    return skeletonItems;
  };

  return (
    <DashboardWidget>
      <DashboardWidgetBody>
        <DashboardWidgetHead
          title={name || <FormattedMessage id="widget.newFiles" />}
        />
        {loading && !error && <Box>{skeletons(3)}</Box>}
        {!loading && !error && (
          <Box>
            {items.map(item => (
              <RecentlyModifiedItem item={item} key={item.productId}/>
            ))}
          </Box>
        )}
      </DashboardWidgetBody>
    </DashboardWidget>
  );
};

export default RecentlyModifiedWidget;

import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import ChatIcon from "@mui/icons-material/Chat";
import PhoneIcon from "@mui/icons-material/Phone";
import RowItem from "./RowItem";
import FaIcon from "./FaIcon";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 18,
    width: "unset",
    height: "unset",
    color: "rgba(0,0,0,0.42)",
  },
  actionIcon: {
    fontSize: 18,
    width: "unset",
    height: "unset",
    color: "rgba(0,0,0,1)",
  },
}));

const ColumnOne = ({ userDetails }) => {
  const classes = useStyles();
  const {
    company,
    companyUserGroup,
    directMessage,
    email,
    mainEducation,
    mobile,
    phone,
    visibilitySettings, //Kan bli null om vi anv�nder den fr�n SiteUsersTable (m.fl)
    displaySettings, //�r null n�r vi anv�nder den fr�n anv�ndarens f�rhandsgranskning
  } = userDetails || {};

  return (
    <>
      {company && (
        <RowItem
          title={
            <span>
              <FormattedMessage id="account.workingOn" /> {company}
            </span>
          }
          subTitle={<FormattedMessage id="settings.theUsersCompany" />}
          leftIcon={<FaIcon icon="fas fa-briefcase" />}
        />
      )}
      {companyUserGroup && (
        <RowItem
          title={<span>{companyUserGroup}</span>}
          subTitle={<FormattedMessage id="settings.companyUserGroup" />}
          leftIcon={<FaIcon icon="fas fa-sitemap" />}
        />
      )}
      {email && (
        <RowItem
          title={email}
          subTitle={<FormattedMessage id="settings.email" />}
          leftIcon={<FaIcon icon="far fa-envelope" />}
        />
      )}
      {mainEducation && (
        <RowItem
          name="mainEducation"
          visibilitySettings={visibilitySettings}
          displaySettings={displaySettings}
          title={mainEducation}
          subTitle={<FormattedMessage id="settings.professionalDegrees" />}
          leftIcon={<FaIcon icon="fas fa-graduation-cap" />}
        />
      )}
      {phone && (
        <RowItem
          name="phone"
          visibilitySettings={visibilitySettings}
          displaySettings={displaySettings}
          title={phone}
          subTitle={<FormattedMessage id="settings.phone" />}
          leftIcon={<PhoneIcon className={classes.icon} />}
        />
      )}

      {mobile && (
        <RowItem
          name="mobile"
          visibilitySettings={visibilitySettings}
          displaySettings={displaySettings}
          title={mobile}
          subTitle={<FormattedMessage id="settings.mobile" />}
          leftIcon={<PhoneIcon className={classes.icon} />}
        />
      )}
      {directMessage && (
        <RowItem
          name="directMessage"
          visibilitySettings={visibilitySettings}
          displaySettings={displaySettings}
          title={directMessage}
          subTitle={<FormattedMessage id="settings.instantMessage" />}
          leftIcon={<ChatIcon className={classes.icon} />}
        />
      )}
    </>
  );
};

export default ColumnOne;

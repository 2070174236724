import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import News from "./News";

const useStyles = makeStyles(theme => ({
  newsContainer: {
    background: props => props.contentBG || theme.palette.background.paper,
    padding: 15,
  },
}));

const HeaderTwo = ({ news, customStyles, onClickReadMore, idx }) => {
  const classes = useStyles(customStyles);

  return (
    <Grid container className={classes.newsContainer}>
      {news.map((x, idx) => {
        return <News idx={idx} news={x} onClickReadMore={onClickReadMore } customStyles={customStyles} />;
      })}
    </Grid>
  );
};

export default HeaderTwo;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import CreateNewsModalBody from "./CreateNewsModalBody";
import DraggableModal from "../DraggableModal";
import DraggableModalHead from "../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

const CreateNewsModal = ({
  open,
  handleClose,
  formValues,
  setFormValues,
  category,
  setCategory,
  uploadedAsset,
  setUploadedAsset,
  setNewsTypeModal,
  handleSaveNewsPost,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={intl.formatMessage({
          id: "common.news",
        })}
      />
      <DraggableModalBody>
        <CreateNewsModalBody
          handleClose={handleClose}
          formValues={formValues}
          setFormValues={setFormValues}
          category={category}
          setCategory={setCategory}
          uploadedAsset={uploadedAsset}
          setUploadedAsset={setUploadedAsset}
          setNewsTypeModal={setNewsTypeModal}
          handleSaveNewsPost={handleSaveNewsPost}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

CreateNewsModal.defaultProps = {
  formValues: {},
  category: "",
  uploadedAsset: null,
};

CreateNewsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  setFormValues: PropTypes.func.isRequired,
  category: PropTypes.string,
  setCategory: PropTypes.func.isRequired,
  uploadedAsset: PropTypes.shape({}),
  setUploadedAsset: PropTypes.func.isRequired,
  setNewsTypeModal: PropTypes.func.isRequired,
};

export default CreateNewsModal;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import OrderSearch from "../../components/IncomingOrders/OrderSearch";
import OrderHistoryTable from "../../components/IncomingOrders/IncomingOrdersTable";
import { GET_INCOMING_ORDERS } from "../../graphql/queries/IncomingOrdersContext";
import { getDateOnly } from "../../helpers/get";
import "../../print.css";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const initialFromDate = new Date().setDate(new Date().getDate() - 14);

const initialFormValues = {
  fromDate: new Date(initialFromDate),
  toDate: new Date(),
  supplierOrderNo: "",
  receiver: "",
  streetAddress: "",
  zipCode: "",
  user: "",
  city: "",  
};

const IncomingOrders = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(initialFormValues);
  const {
    fromDate,
    toDate,
    receiver,
    streetAddress,
    zipCode,
    user,
    city,
    supplierOrderNo,
  } = formValues;

  const orderGroupId = 18;
  const [page, setPage] = useState(0);
  const [ascending, setAscending] = useState(true);
  const [sortField, setSortField] = useState("orderId");
  const [pageSize, setPageSize] = useState(25);
  const [orders, setOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderHistorySettings, setOrderHistorySettings] = useState({});
  const [orderHistoryDetailSettings, setOrderHistoryDetailSettings] = useState({
    showComment: true,
    showCostCenter: true,
    showDeliveryAddress: true,
    showDeliveryDate: true,
    showEmail: true,
    showInvoiceAddress: true,
    showOrderStatus: true,
    showPackageColumn: true,
    showPostalAddress: true,
    showPriceColumn: true,
    showProductColumn: true,
    showQuantityColumn: true,
    showSummation: true,
    showWeightColumn: true,
  });

  const [getIncomingOrders, { data, loading }] = useLazyQuery(
    GET_INCOMING_ORDERS
  );

  useEffect(() => {
    if (data && data.incomingOrdersContext) {
      if (data.incomingOrdersContext.incomingOrders.items) {
        setOrders(data.incomingOrdersContext.incomingOrders.items);
      }
      if (
        data.incomingOrdersContext.incomingOrders.totalResult !== null ||
        data.incomingOrdersContext.incomingOrders.totalResult !== undefined
      ) {
        setTotalCount(data.incomingOrdersContext.incomingOrders.totalResult);
      }
    }
  }, [data]);

  const handleSearchClick = () => {
    const showOnlyMyOrders = false;

    getIncomingOrders({
      variables: {
        model: {
          offset: 0,
          pageSize,
          sortDesc: !ascending,
          fromDate: getDateOnly(fromDate),
          toDate: getDateOnly(toDate),
          sortField,
          supplierOrderNo: supplierOrderNo,
          // For kommunal use companyorderNo when you search for orderNumber
          // But it seems not to filter. So change to orderNo for now.
          receiver,
          deliveryAddress2: streetAddress,
          deliveryZip: zipCode,
          user,
          city,
        },
      },
    });
  };

  const handleSortChanges = () => {
    handleSearchClick();
  };

  const handleMultiPrintOrder = () => {
    /*printOrderHistory({
            variables: { orderGroupId, orderIds: selectedOrders },
        });*/
  };

  const addSelectedOrder = order => {
    setSelectedOrders([...selectedOrders, order]);
  };

  const removeSelectedOrder = order => {
    const temp = selectedOrders.filter(x => x !== order);
    setSelectedOrders(temp);
  };

  useEffect(() => {
    /*if (orderGroupId && orderHistoryView) {
            handleSortChanges();
        }*/
  }, [orderGroupId, ascending, sortField, page, pageSize]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <OrderSearch
            formValues={formValues}
            setFormValues={setFormValues}
            onSearchClick={handleSearchClick}
            orderHistorySettings={orderHistorySettings}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <OrderHistoryTable
            addSelectedOrder={addSelectedOrder}
            ascending={ascending}
            handleMultiPrintOrder={handleMultiPrintOrder}
            loading={loading}
            orderGroupId={orderGroupId}
            orderHistoryDetailSettings={orderHistoryDetailSettings}
            orders={orders}
            page={page}
            pageSize={pageSize}
            removeSelectedOrder={removeSelectedOrder}
            selectedOrders={selectedOrders}
            setAscending={setAscending}
            setPage={setPage}
            setPageSize={setPageSize}
            setSortField={setSortField}
            sortField={sortField}
            totalCount={totalCount}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default IncomingOrders;

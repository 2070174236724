import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const deepLinkContext = createContext();

const { Provider } = deepLinkContext;

const DeepLinkProvider = ({ children }) => {
  const [state, setState] = useState();

  const clearData = () => {
    setData();
  };

  const clearSiteId = () => {
    setState((prev) => ({ ...prev, siteId: undefined }));
  };

  const clearFolderId = () => {
    setState((prev) => ({ ...prev, folderId: undefined }));
  };

  const setData = (deepLink) => {
    if (!!deepLink) {
      const { siteId, folderId, resourceId } = deepLink;
      setState((prev) => ({
        ...prev,
        siteId,
        folderId,
        resourceId,
      }));
    }
    else {
      setState();
    }
  };

  return (
    <Provider value={{
      clearData,
      clearFolderId,
      clearSiteId,
      deepLink: state,
      setData,
    }}>
      {children}
    </Provider>
  );
};

DeepLinkProvider.propsType = {
  children: PropTypes.node.isRequired,
};

export default DeepLinkProvider;

import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { TextField as CustomTextField } from "../../common";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btnApprove: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const NewCompanyModal = ({
  companyList,
  open,
  setCompanyList,
  setNewCompanyModal,
}) => {
  const classes = useStyles();

  const [newCompanyName, setNewCompanyName] = useState("");

  const handleClose = () => {
    setNewCompanyModal(false);
  };

  const handleAddNewCompany = () => {
    let tempCompanies = [...companyList];
    const newCompany = { companyName: newCompanyName, publicCompanyId: 0 };
    tempCompanies = [...tempCompanies, newCompany];
    setCompanyList(tempCompanies);
    handleClose();
  };

  return (
    <DraggableModal
      customClass={classes.modal}
      handleClose={handleClose}
      open={open}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="siteUsers.addNewCompany" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <Typography>
          <FormattedMessage id="siteUsers.companyName" />
        </Typography>
        <CustomTextField
          onChange={e => setNewCompanyName(e.target.value)}
          value={newCompanyName}
        />
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnCancel} onClick={handleClose}>
            <FormattedMessage id="btn.cancel" />
          </Button>
          <Button disabled={newCompanyName === ""} className={classes.btnApprove} onClick={handleAddNewCompany}>
            <FormattedMessage id="btn.approve" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewCompanyModal.defaultProps = {
  companyList: null,
};

NewCompanyModal.propTypes = {
  companyList: PropTypes.arrayOf(PropTypes.shape({})),
  open: PropTypes.bool.isRequired,
  setCompanyList: PropTypes.func.isRequired,
  setNewCompanyModal: PropTypes.func.isRequired,
};

export default NewCompanyModal;

import React, { useEffect, useState, lazy, Suspense } from "react";
import { useAlert } from "react-alert";
import { makeStyles } from "@mui/styles";
import { Dialog, Paper, TextField, Button } from "@mui/material";
import { DraggableModalBody, DraggableModalHead } from "../common";
import Draggable from "react-draggable";

import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
///import JoditEditor from "jodit-react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_HELP } from "../../graphql/queries";
import { UPDATE_HELP } from "../../graphql/mutations";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { helpIndex } from "../../constant/helpIndex";
import RecursiveHelpTreeView from "./RecursiveHelpTreeView";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/mode-html";

const JoditEditor = lazy(() => import("jodit-react"));

const useStyles = makeStyles(theme => ({
  icon: {
    width: "unset",
    height: "unset",
    fontSize: 14,
    marginRight: 5,
    marginTop: 1,
    color: theme.palette.common.white,
  },
  breadCrumbs: {
    marginBottom: 12,
    "& .MuiTypography-root": {
      paddingBottom: 0,
    },
  },
  dialogContainer: {
    "& .MuiDialog-container": {
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  dialogContent: {
    "& a": {
      textDecoration: "none",
    },
    "& h1": {
      color: theme.palette.primary.main,
      fontSize: 22,
      fontWeight: 500,
      marginTop: 6,
      marginBottom: 6,
    },
    "& h2": {
      color: theme.palette.primary.main,
      fontSize: 20,
      marginTop: 6,
      marginBottom: 4,
    },
    "& h3": {
      color: theme.palette.primary.main,
      fontSize: 17,
      marginTop: 6,
      marginBottom: 2,
    },
    "& h4": {
      color: theme.palette.primary.main,
      fontSize: 15,
      marginTop: 6,
      marginBottom: 1,
    },
    "& p": {
      color: theme.palette.text.primary,
      fontSize: 14,
      marginTop: 0,
      marginBottom: 0,
      lineHeight: 1.5,
      paddingBottom: 14,
    },
    "& hr": {
      border: "solid 1px #cccccc55",
      marginBottom: 12,
    },
    "& .MuiTreeView-root": {
      backgroundColor: theme.palette.info.light + "1e",
      padding: "10px 20px 10px 20px",
      marginTop: 4,
      borderRadius: 6,
    },
    "& .Mui-expanded": {
      backgroundColor: "#FFFFFF",
    },
    "& aside": {
      backgroundColor: theme.palette.info.light + "3F",
      padding: "10px 20px 20px 20px",
      marginTop: 10,
      borderRadius: 6,
      "& a": {
        textDecoration: "none",
      },
      "& h1": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 20,
        fontWeight: 500,
        marginTop: 6,
        marginBottom: 6,
      },
      "& h2": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 16,
        marginTop: 6,
        marginBottom: 4,
      },
      "& h3": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 14,
        marginTop: 6,
        marginBottom: 2,
      },
      "& h4": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 14,
        marginTop: 6,
        marginBottom: 1,
      },
      "& p": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        paddingBottom: 0,
      },
      "& hr": {
        border: "solid 1px #ffffff99",
        marginBottom: 12,
      },
      "& ul": {
        marginTop: 4,
        marginBottom: 4,
      },
    },
    "& blockquote": {
      position: "relative",
      fontSize: 20,
      padding: "5px 15px 15px 15px",
      margin: "0px 0px 30px 0px",
      alignSelf: "center",
      "& a": {
        textDecoration: "none",
      },
      "& h1": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 20,
        fontWeight: 500,
        marginTop: 6,
        marginBottom: 6,
      },
      "& h2": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 16,
        marginTop: 6,
        marginBottom: 4,
      },
      "& h3": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 14,
        marginTop: 6,
        marginBottom: 2,
      },
      "& h4": {
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        fontSize: 14,
        marginTop: 6,
        marginBottom: 1,
      },
      "& p": {
        backgroundColor: theme.palette.info.light + "12",
        color: theme.palette.getContrastText(theme.palette.info.light + "3F"),
        position: "relative",
        fontSize: 16,
        margin: 0,
        border: "solid 2px " + theme.palette.info.light + "3F",
        borderRadius: 20,
        padding: 20,
        "&::after": {
          content: '""',
          position: "absolute",
          border: "solid 2px " + theme.palette.info.light + "3F",
          borderRadius: "0 40px 0 0",
          width: 40,
          height: 32,
          bottom: -34,
          left: 50,
          borderBottom: "none",
          borderLeft: "none",
          zIndex: 3,
        },
        "&::before": {
          content: '""',
          position: "absolute",
          border: "solid 2px #fff",
          width: 50,
          bottom: -3,
          left: 50,
          zIndex: 2,
        },
      },
      "& hr": {
        border: "solid 1px " + theme.palette.info.light + "3F",
        marginBottom: 12,
      },
    },
    "& blockquote::before, blockquote::after": {
      position: "absolute",
      color: theme.palette.info.light + "3F",
      fontSize: 50,
      width: 30,
      height: 30,
    },
  },
}));

const HelpModal = ({ open, handleClose }) => {
  const [height, setHeight] = React.useState(window.innerHeight - 98 - 20);
  const [width, setWidth] = React.useState(600);
  const alert = useAlert();
  const [index, setIndex] = useState();
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [currentBreadCrumb, setCurrentBreadCrumb] = useState();

  const [textContent, setTextContent] = useState("");
  const [relatedSection, setRelatedSections] = useState([]);

  const [textKey, setTextKey] = useState(
    "/" + window.location.pathname.split("/").at(-1)
  ); //Set textKey to the Last part of the page URL

  const classes = useStyles();

  const [showEditor, setShowEditor] = useState(false);
  const [getTextContent, { data: helpContent, loading }] = useLazyQuery(
    GET_HELP
  );

  useEffect(() => {
    if (helpContent) {
      setTextContent(helpContent.getHelp.content);
      setRelatedSections(helpContent.getHelp.relatedSections);
    }
  }, [helpContent]);

  //Updates the JoditEditor
  useEffect(() => {
    if (textKey && textKey !== "") {
      getText();
    }
  }, [textKey]);

  useEffect(() => {
    if (!index) {
      var temp = [...helpIndex];
      var quickIndex = [];

      function generateBreadCrumbs(parents) {
        var parent = parents[parents.length - 1];

        if (parent.children) {
          for (var i = 0; i < parent.children.length; i++) {
            var child = parent.children[i];
            var breadCrumb = [...parents, child];
            quickIndex[child.id] = breadCrumb;
            generateBreadCrumbs(breadCrumb);
          }
        }
      }

      for (var i = 0; i < temp.length; i++) {
        var item = temp[i];
        generateBreadCrumbs([item]);
        quickIndex[item.id] = [item];
      }
      setBreadCrumbs(quickIndex);
      setIndex(temp);
    }
  }, [index]);

  const [updateTextContent] = useMutation(UPDATE_HELP);

  function PaperComponent(props) {
    return (
      <Draggable
        handle=".draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const getHelpTextByPageKey = pageKey => {
    const helpTexts = [];
    const testTest = helpIndex;

    helpTexts["DEFAULT"] = {
      content:
        "<h1>Standardtext</h1><p>Här kommer en beskrivning av vad sidan gör, vilka funktioner som finns och vilken nytta sidan gör.</p>",
      related:
        "<blockquote><p>Instrumentpanelen används för att enkelt kommunicera med deltagare och anställda. Viktiga händelser, nyheter, och förändringar samlas på ett och samma ställe. Företag använder den ofta som en del av sitt intranät.</p></blockquote><aside><h2>Relaterad information</h2><hr><ul><li><a href='https://www.w3schools.com'>Konfigurera systemets startsida</a></li><li><a href='https://www.w3schools.com'>Lägg till, ta bort och ändra en widget</a></li><li><a href='https://www.w3schools.com'>Beskrivning av alla widgetar</a></li><li><a href='https://www.w3schools.com'>Vad är en widget</a></li><li><a href='https://www.w3schools.com'>Det här är UniQueue</a></li><li><a href='https://www.w3schools.com'>Vad är en arbetsyta</a></li><li><a href='https://www.w3schools.com'>Varukorgen</a></li><li><a href='https://www.w3schools.com'>Bildvarukorgen</a></li><li><a href='https://www.w3schools.com'>Bjuda in användare</a></li></ul></aside>",
    };
    helpTexts["/order-histiory"] = {
      content:
        "<p>Det här är orderhistoriksidan. Vad tror du man kan göra här? Jo. Visa orderhistoriken såklart, det hörs ju påamnet. Sök på datum mm så klurar du nog ut det</p>",
    };
    helpTexts["/product-view"] = { content: "<p>Det här är produktsidan</p>" };
    helpTexts["/workspace-dashboard"] = {
      content: "",
      related:
        "<blockquote><p>Arbetsytans startsida används för att enkelt kommunicera med deltagare i arbetsytan. Viktiga händelser, nyheter, och förändringar i arbetsytan samlas på ett och samma ställe.</p></blockquote><aside><h2>Relaterad information</h2><hr><ul><li><a href='https://www.w3schools.com'>Konfigurera arbetsytans startsida</a></li><li><a href='https://www.w3schools.com'>Lägga, ta bort och ändra en widget</a></li><li><a href='https://www.w3schools.com'>Beskrivning av alla widgetar</a></li><li><a href='https://www.w3schools.com'>Vad är en widget</a></li><li><a href='https://www.w3schools.com'>Det här är UniQueue</a></li><li><a href='https://www.w3schools.com'>Vad är en arbetsyta</a></li><li><a href='https://www.w3schools.com'>Varukorgen</a></li><li><a href='https://www.w3schools.com'>Bildvarukorgen</a></li><li><a href='https://www.w3schools.com'>Bjuda in användare</a></li></ul></aside>",
    };
    helpTexts["/file-view"] = {
      content: "",
      related:
        "<blockquote><p>Dokumentsamarbete hjälper framgångsrika team att arbeta effektivt tillsammans och att uppnå gemensamma mål.</p></blockquote><aside><h2>Relaterad information</h2><hr><p>Här kan du läsa mer om hur du kan:</p><ul><li><a href='https://www.w3schools.com'>Lägga till, ta bort och ändra filer</a></li><li><a href='https://www.w3schools.com'>Filversioner</a></li><li><a href='https://www.w3schools.com'>Lägga till, ta bort och ändra mappar</a></li><li><a href='https://www.w3schools.com'>Lägga till, ta bort och ändra produkter</a></li><li><a href='https://www.w3schools.com'>Flytta filer, dokument, produkter och mappar</a></li><li><a href='https://www.w3schools.com'>Dölja/visa sidofält för meddelanden eller nyheter</a></li><li><a href='https://www.w3schools.com'>Låsa/låsa upp filer och mappar</a></li><li><a href='https://www.w3schools.com'>Ladda ner filer och mappar</a></li><li><a href='https://www.w3schools.com'>Metadata</a></li><li><a href='https://www.w3schools.com'>Kopiera filer</a></li></ul></aside>",
    };

    if (helpTexts[pageKey]) {
      return helpTexts[pageKey];
    }

    return helpTexts["DEFAULT"];
  };

  const onResize = (event, { element, size, handle }) => {
    setWidth(size.widht);
    setHeight(size.height);
    //this.setState({ width: size.width, height: size.height });
  };

  const handleTreeViewClick = item => {
    if (item !== "/more-help") {
      setTextKey(item);
      setCurrentBreadCrumb(breadCrumbs[item]);
    }
  };
  const handleChange = e => {
    setTextKey(e.target.value);
  };

  const onSave = async () => {
    try {
      const res = await updateTextContent({
        variables: {
          helpKey: textKey,
          content: textContent,
          relatedSection: relatedSection,
        },
      });
      if (res && res.data && res.data.updateHelp) {

        if (res.data.updateHelp.helpId) {
          alert.success("Texten uppdaterades");
        } else {
          alert.error("Något gick fel!");
        }
        /*const {
              success,
            } = res.data.widgetsContext.updateWorkspaceTextEditorContent;*/

        /*if (success) {
              alert.success("Texten uppdaterades");
            } else {
              alert.error("Något gick fel!");
            }
          } else {
            alert.error("FEL (fick inget resultat från servern)");
            }*/
      }
    } catch (err) {
      alert.error("FEL (fick inget resultat från servern)");
      console.error("error >", err);
    }
  };

  const handleRelatedChange = (e, index, field) => {

    var temp = [...relatedSection];
    var item = temp[index];
    item[field] = e.target.value;

    setRelatedSections(temp);
  };

  const getText = () => {
    getTextContent({
      variables: { helpKey: textKey },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialogContainer}
      disableEnforceFocus={true}
      hideBackdrop={true}
      maxWidth={false}
      style={{ pointerEvents: "none" }}
      //PaperComponent={PaperComponent}
      PaperProps={{
        style: {
          pointerEvents: "auto",
          m: 0,
          top: 73,
          left: `calc(100vw - ${width}px - 50px)`,
        },
      }}
    >
      <ResizableBox
        height={height}
        width={width}
      //onResize={onResize}
      >
        <>
          <DraggableModalHead
            handleClose={handleClose}
            title={
              <>
                <HelpOutlineOutlinedIcon className={classes.icon} />
                Hjälp
              </>
            }
          />
          <DraggableModalBody customClass={classes.dialogContent}>
            <Button onClick={n => setShowEditor(!showEditor)}>
              {showEditor ? "Dölj editor" : "Visa editor"}
            </Button>
            {showEditor && (
              <>
                <TextField
                  //autoFocus
                  value={textKey}
                  name="textkey"
                  onChange={handleChange}
                />

                <Button onClick={getText}>Hämta text</Button>
                <Button onClick={onSave}>Spara</Button>
                <Suspense><JoditEditor
                  value={textContent}
                  onBlur={newContent => setTextContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={newContent => { }}
                /></Suspense>
                <div>&nbsp;</div>
                <div>
                  {relatedSection &&
                    relatedSection.length > 0 &&
                    relatedSection.map((x, index) => (
                      <div key={"related_" + index}>
                        <TextField
                          value={x.helpId}
                          onChange={e => handleRelatedChange(e, index, "helpId")}
                        />
                        <TextField
                          value={x.label}
                          onChange={e => handleRelatedChange(e, index, "label")}
                        />
                      </div>
                    ))}

                  <Button
                    onClick={n => {
                      var temp = [...relatedSection, { helpId: "", label: "" }];
                      setRelatedSections(temp);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </>
            )}

            <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumbs}>
              {currentBreadCrumb &&
                currentBreadCrumb.map(n => (
                  <Link onClick={x => handleTreeViewClick(n.id)}>{n.label}</Link>
                ))}
            </Breadcrumbs>

            <div
              dangerouslySetInnerHTML={{
                __html: textContent,
              }}
            />
            {relatedSection && relatedSection.length > 0 && (
              <ul>
                {relatedSection.map(n => (
                  <li>
                    <Link onClick={x => handleTreeViewClick(n.helpId)}>
                      {n.label}
                    </Link>{" "}
                  </li>
                ))}
              </ul>
            )}

            {/*<div
              dangerouslySetInnerHTML={{
                __html: getHelpTextByPageKey(textKey).related,
              }}
            />*/}
            <RecursiveHelpTreeView data={index} onClick={handleTreeViewClick} />
          </DraggableModalBody>
        </>
      </ResizableBox>
    </Dialog>
  );
};

export default HelpModal;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";

const RenderFolderPermissions = ({
  handleOpenPermissions,
  locked,
  handleDisabled,
}) => {
  const disabled = locked;
  return (
    <ContextMenuItem
      disabled={disabled}
      onClick={() => (disabled ? handleDisabled() : handleOpenPermissions())}
    >
      <FormattedMessage id="context.manageFolderPermissions" />
    </ContextMenuItem>
  );
};

RenderFolderPermissions.defaultProps = {
  locked: false,
};

RenderFolderPermissions.propTypes = {
  handleOpenPermissions: PropTypes.func.isRequired,
  locked: PropTypes.bool,
};

export default RenderFolderPermissions;

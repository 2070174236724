import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import ButtonPrimaryAltSm from "../../common/ButtonPrimaryAltSm";

const useStyles = makeStyles(theme => ({
  uploadBox: {
    marginTop: 15,
    padding: 5,
    borderRadius: 4,
    border: "1px solid #d7d7d7",
    width: "70%",
  },
  input: {
    display: "none",
  },
}));

const Import = () => {
  const classes = useStyles();

  return (
    <Box className={classes.uploadBox}>
      <input
        accept="image/*"
        className={classes.input}
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <ButtonPrimaryAltSm component="span" label="Choose file" />
      </label>
    </Box>
  );
};

export default Import;

import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    textAlign: "center",
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.05)",
    border: "1px solid #f1f1f1",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  media: {
    width: "auto",
    height: 160,
    backgroundSize: "contain",
    "&:hover": {
      cursor: "pointer",
      "& > div": {
        visibility: "visible",
        opacity: 1,
      },
    },
  },
  cardContent: {
    padding: 10,
    paddingBottom: 0,
    "& .MuiChip-root": {
      backgroundColor: theme.palette.secondary.dark,
      height: 18,
      marginRight: 3,
    },
    "& .MuiChip-labelSmall": {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
  fileNameText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selected: {
    border: "1px solid rgba(0, 0, 0, 0.75)",
  },
}));

const ResourceImageItem = ({ item, handleImageSelect, selected }) => {
  const classes = useStyles();

  const { thumbnailImageUri, name } = item || {};

  const handleOnClick = () => {
    handleImageSelect(item);
  };

  return (
    <Card className={clsx([classes.root, selected ? classes.selected : ""])}>
      <CardActionArea onClick={handleOnClick}>
        <CardMedia
          className={classes.media}
          image={thumbnailImageUri}
          title={name}
        />
        <CardContent className={classes.cardContent}>
          <Typography className={classes.fileNameText}>{name}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ResourceImageItem;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import DynamicProduct from "../ProductsMainView/ProductModal/DynamicProduct";
import NonDynamicProduct from "../ProductsMainView/ProductModal/NonDynamicProduct";
import { DYNAMIC_PRODUCT_TYPE } from "../../constant/types";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(() => ({
  modal: {
    width: "90%",
  },
  modalBody: {
    padding: 0,
  },
}));

const EditDynamicProductModal = ({
  open,
  handleClose,
  product,
  handleUpdateDynamicProductFields,
  productIndex,
  isCheckout,
  quantity,
  setQuantity,
}) => {
  const { productType } = product;
  const classes = useStyles();
  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        {productType === DYNAMIC_PRODUCT_TYPE ? (
          <DynamicProduct
            handleClose={handleClose}
            product={product}
            editMode
            quantity={quantity}
            setQuantity={setQuantity}
            handleUpdateDynamicProductFields={handleUpdateDynamicProductFields}
            productIndex={productIndex}
            isCheckout={isCheckout}
          />
        ) : (
          <NonDynamicProduct
            handleClose={handleClose}
            product={product}
            editMode
          />
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

EditDynamicProductModal.defaultProps = {
  isCheckout: null,
};

EditDynamicProductModal.propTypes = {
  isCheckout: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleUpdateDynamicProductFields: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    productType: PropTypes.string.isRequired,
  }).isRequired,
  productIndex: PropTypes.number.isRequired,
};

export default EditDynamicProductModal;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box, Icon } from "@mui/material";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomTextField from "../../../../../../common/TextField";

const useStyles = makeStyles(() => ({
  draggable: {
    position: "static!important",
  },
  btnIcon: {
    cursor: "grab",
    fontSize: 10,
    color: "#9A9A9A",
  },
  customField: {
    background: "#fff",
    color: "#000",
    "& ::placeholder": {
      color: "#000",
    },
  },
  fieldsWrapper: {
    background: "#e6e8e6",
    minHeight: 450,
    padding: "5px 5px 25px 5px",
  },
}));

const FieldsContainer = ({ fields, handleOpenAttribute, onDragEnd }) => {
  const classes = useStyles();
  const RenderDraggableField = ({ field, idx }) => {
    const {
      displayName,
      fieldName,
      description,
      fieldType,
      required,
      fieldValue,
    } = field;

    return (
      <Draggable key={idx} draggableId={`field-${idx}`} index={idx}>
        {provided => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classes.draggable}
            onClick={e => handleOpenAttribute(e, idx)}
          >
            <CustomTextField
              label={displayName}
              name={fieldName}
              value={fieldValue}
              required={required}
              customClass={classes.customField}
              placeholder={description}
              endAdornment={
                <Icon className={clsx(["fa fa-hand-rock", classes.btnIcon])} />
              }
            />
          </Box>
        )}
      </Draggable>
    );
  };

  return (
    <div className={classes.fieldsWrapper}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="dynamicFieldsList">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map((field, idx) => {
                return <RenderDraggableField field={field} idx={idx} />;
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

FieldsContainer.defaultProps = {
  fields: [],
};

FieldsContainer.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      fieldName: PropTypes.string,
      description: PropTypes.string,
      fieldType: PropTypes.string,
      required: PropTypes.bool,
      fieldValue: PropTypes.string,
    })
  ),
  handleOpenAttribute: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

export default FieldsContainer;

import { gql } from '@apollo/client';
import Dependencies from "./Dependencies";
import Task from "./Task";

const Tasks = gql`
  fragment Tasks on TasksDTO {
    __typename

	  tasks { ...Task }
  	dependencies { ...Dependencies }
  }
  ${Dependencies}
  ${Task}
`;

export default Tasks;

import React, { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { Grid, Radio, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../common/Buttons";
import CustomTextField from "../common/TextField";
import CompanySelector from "./CompanySelector";
import { TextField } from "../common";
import { USER_DETAILS, PUBLIC_COMPANY } from "../../graphql/public/queries";
import { useLazyQuery } from "@apollo/client";
import { client } from "../../graphql/publicClient";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputMask from "react-input-mask";

const StepTWo = ({
  token,
  companies,
  setCompanies,
  selectedCompany,
  setSelectedCompany,
  setStepTwoValues,
  stepTwoValues,
  snackbar,
  searchedCompany,
  setSearchedCompany,

  useSearched,
  setUseSearched
}) => {
  const [addNewCompany, setAddNewCompany] = useState(false);


  const handleChange = e => {
    const { name, value } = e.target;
    setStepTwoValues({ ...stepTwoValues, [name]: value });
  };

  const [getPublicCompany, { data: companyData }] = useLazyQuery(PUBLIC_COMPANY, { client, fetchPolicy: 'no-cache' });
  const [inactivated, setInactivated] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);

  const debounceFetchCompanies = useCallback(
    debounce(async searchTerm => {
      
      if (searchTerm && (searchTerm.match(/^\d{3}-\d{3}-\d{3}$/) || searchTerm.match(/^\d{9}$/))) {
        getPublicCompany({
          variables: { token, publicCompanyId: searchTerm },
        });
      } else {
        setShowCheckmark(false);
        setSearchedCompany({});
      }
    }, 800),
    []
  );

  useEffect(() => {

    if (companyData && companyData.publicCompany) {
      const {
        foundCompany,
        notFound,
        displayWarning,
        inactivated,
        remainingAttempts,
        attemptsMade,
      } = companyData.publicCompany;

      if (notFound) {
        if (inactivated) {
          snackbar.error(<FormattedMessage id="siteUsers.inactivateSearch" />);
          setInactivated(inactivated);
        } else {
          setInactivated(inactivated);
        }

        if (displayWarning && !inactivated) {
          snackbar.error(
            <FormattedMessage
              id="siteUsers.warning"
              value={{ attemptsMade, remainingAttempts }}
            />
          );
        }
      } else {
        if (foundCompany) {
          setSearchedCompany(foundCompany);
          setShowCheckmark(true);
        }
      }
    }
  }, [companyData]);

  const handleOnChange = value => {
    
    debounceFetchCompanies(value);
  };

  const handleCompany = e => {
    
    const { innerText } = e.target;
    const companyId = e.target.getAttribute("data-id");
    const tempValues = { companyName: innerText, publicCompanyId: companyId };
    setSelectedCompany(tempValues);
  };

  const handleAddNewCompany = () => {
    setAddNewCompany(true);
    setSelectedCompany({});
  };

  const handleAddCompany = () => {
    const { companyName } = stepTwoValues;
    // const tempCompanyId = companies.length;
    const tempCompany = { companyName };

    setCompanies([...companies, tempCompany]);
    setSelectedCompany(tempCompany);
    setAddNewCompany(false);
  };

  const disableAddBtn = () => {
    const allTrue = Object.values(stepTwoValues).every(item => item);
    return !allTrue;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography>
          <FormattedMessage id="createAccount.companyNotice" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>

        <Stack direction="row" alignItems="center" gap={1}>          
          <InputMask
            /*class={classes.formControl}*/
            disabled={false}
            fullWidth
            
            mask="999-999-999"
            name="selectedCompanyId"
            onChange={e => handleOnChange(e.target.value)}
            placeholder="___-___-___"
            onClick={e => setUseSearched(true)}
          >
            {
              (inputProps) => <TextField {...inputProps} disabled={false} label={<FormattedMessage id="sorting.companyId"/> } defaultValue={stepTwoValues.publicCompanyId || ""} />
            }
          </InputMask>
          {showCheckmark && (< CheckCircleIcon color="primary" />)}
        </Stack>


      </Grid>
      <Grid item xs={12} md={4}>
        {!showCheckmark && (
          <CompanySelector
            name="company"
            options={companies}
            //onChange={e => handleOnChange(e.target.value)}
            setValue={handleCompany}
            value={selectedCompany}
            handleAddNewCompany={handleAddNewCompany}
            disabled={inactivated}
            onClick={e => setUseSearched(false)}
          />)}          
      </Grid>
      <Grid item xs={12} md={4}>
        <PrimaryButton onMouseDown={e => handleAddNewCompany(e)} marginTop={"8px"} disabled={addNewCompany}>
          <FormattedMessage id="siteUsers.addNewCompany" />
        </PrimaryButton>
      </Grid>
        {addNewCompany && (
          <>
            <Grid item xs={12} md={12}>
              <CustomTextField
                name="companyName"
                label={<FormattedMessage id="createAccount.companyName" />}
                onChange={handleChange}
                value={stepTwoValues.companyName}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTextField
                name="orgnNo"
                label={<FormattedMessage id="createAccount.organisationNumber" />}
                onChange={handleChange}
                value={stepTwoValues.orgnNo}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <CustomTextField
                name="street"
                label={<FormattedMessage id="createAccount.street" />}
                onChange={handleChange}
                value={stepTwoValues.street}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomTextField
                name="zip"
                label={<FormattedMessage id="createAccount.zipCode" />}
                onChange={handleChange}
                value={stepTwoValues.zip}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomTextField
                name="city"
                label={<FormattedMessage id="orderHistorySearch.city" />}
                onChange={handleChange}
                value={stepTwoValues.city}
              />
            </Grid>
            <Grid item xs={12} md={12} align="right">
              <PrimaryButton
                onClick={() => handleAddCompany()}
                disabled={disableAddBtn()}
              >
                <FormattedMessage id="btn.add" />
              </PrimaryButton>
            </Grid>
          </>
        )}
      </Grid>
  );
};

StepTWo.defaultProps = {
        stepTwoValues: {},
  company: {},
  companies: null,
  inactivated: false,
};

StepTWo.propTypes = {
        getPublicCompany: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  company: PropTypes.shape({
        companyName: PropTypes.string,
  }),
  setCompany: PropTypes.func.isRequired,
  stepTwoValues: PropTypes.shape({
        companyId: PropTypes.string,
    companyName: PropTypes.string,
    orgnNo: PropTypes.string,
    reference: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
  }),
  setStepTwoValues: PropTypes.func.isRequired,
  inactivated: PropTypes.bool,
};
export default StepTWo;

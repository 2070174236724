import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import PageTabPanel from "../../../../common/PageTabPanel";
import { GET_STOCK_SETTINGS } from "../../../../../graphql/queries";
import HistoryModal from "./HistoryModal";
import EditStockBalanceModal from "./EditStockBalanceModal";
import StockLeftColumn from "./StockLeftColumn";
import StockRightColumn from "./StockRightColumn";
import { stockInitialValues } from "./helpers";

const Stock = ({
  image,
  product,
  stockIsDraft,
  setStockIsDraft,
  resourceId,
}) => {
  const { articleNo, imageMetadata } = product;
  const [stockSettings, setStockSettings] = useState({});
  const [formValues, setFormValues] = useState(stockInitialValues);

  const [
    getStockSettings,
    { data: stockSettingsData, refetch: refetchStock },
  ] = useLazyQuery(GET_STOCK_SETTINGS);

  useEffect(() => {
    if (resourceId) {
      getStockSettings({ variables: { resourceId } });
    }
  }, [resourceId]);

  useEffect(() => {
    if (
      stockSettingsData &&
      stockSettingsData.productEdit &&
      stockSettingsData.productEdit.getStockSettings
    ) {
      setStockSettings(stockSettingsData.productEdit.getStockSettings);
    }
  }, [stockSettingsData]);

  useEffect(() => {
    if (stockSettings) {
      setFormValues(stockSettings);
    }
  }, [stockSettings]);

  const setDraft = () => {
    if (!stockIsDraft) {
      setStockIsDraft(true);
    }
  };

  const setUnDraft = () => {
    if (stockIsDraft) {
      setStockIsDraft(false);
    }
  };

  const handleOnChange = e => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
    setDraft();
  };

  const handleCheckbox = e => {
    const { name, checked } = e.target;

    setFormValues({ ...formValues, [name]: checked });
    setDraft();
  };

  const [openHistory, setOpenHistory] = useState(false);
  const handleOpenHistory = () => {
    setOpenHistory(true);
  };
  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const [openEditStock, setOpenEditStock] = useState(false);
  const handleOpenEditStock = () => {
    setOpenEditStock(true);
  };
  const handleCloseEditStock = () => {
    setOpenEditStock(false);
  };

  return (
    <PageTabPanel spacing="3">
      <StockLeftColumn
        formValues={formValues}
        articleNo={articleNo}
        imageMetadata={imageMetadata}
        handleOnChange={handleOnChange}
      />
      <StockRightColumn
        formValues={formValues}
        handleOpenEditStock={handleOpenEditStock}
        handleOpenHistory={handleOpenHistory}
        handleCheckbox={handleCheckbox}
        handleOnChange={handleOnChange}
        image={image}
        productId={product.resourceId}
        setUnDraft={setUnDraft}
      />
      {openHistory && (
        <HistoryModal
          open={openHistory}
          handleClose={handleCloseHistory}
          resourceId={resourceId}
        />
      )}
      {openEditStock && (
        <EditStockBalanceModal
          open={openEditStock}
          handleClose={handleCloseEditStock}
          refetchStock={refetchStock}
          resourceId={product.resourceId}
        />
      )}
    </PageTabPanel>
  );
};

Stock.defaultProps = {
  product: {},
  stockIsDraft: false,
  resourceId: null,
};

Stock.propTypes = {
  image: PropTypes.string.isRequired,
  product: PropTypes.shape({
    articleNo: PropTypes.string,
    imageMetadata: PropTypes.shape({ contact: PropTypes.string }),
    resourceId: PropTypes.string,
  }),
  resourceId: PropTypes.string,
  setStockIsDraft: PropTypes.func.isRequired,
  stockIsDraft: PropTypes.bool,
};

export default Stock;

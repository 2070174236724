import clsx from "clsx";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useMutation } from "@apollo/client";
import { Box, Button, Grid, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { imageBasketAddItem, cartAddItem } from "../../actions";
import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";
import {
  INITIALIZE_PRODUCT_DOWNLOAD,
  SAVE_IMAGE_BASKET,
} from "../../graphql/mutations";
import { useSnackbar } from "../../hooks";

import ProductModal from "./ProductModal";

const useStyles = makeStyles(theme => ({
  hoverActionsWrap: {
    position: "absolute",
    width: "97% !important",
    height: 33,
    backgroundColor: theme.palette.common.white,
    boxShadow: "1px 0 10px #ececec",
    borderTop: "1px solid #f7f7f7",
    bottom: 6,
    visibility: "hidden",
    transition: "visibility 0s, opacity 0.3s linear",
    opacity: 0,
  },
  mediaHover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    transition: "visibility 0s, opacity 0.15s linear",
    visibility: "hidden",
    opacity: 0,
    position: "absolute",
    top: "-14px",
    left: 0,
    right: 0,
  },
  card: {
    position: "relative",
    padding: "2px 4px",
    "&:hover > div": {
      visibility: "visible",
      opacity: 1,
    },
    "&:hover .MuiBox-root": {
      cursor: "pointer",
      visibility: "visible",
      opacity: 1,
    },
  },
  iconButton: {
    fontSize: 14,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
    color: theme.palette.primary.light,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "none",
    },
  },
  iconButtonView: {
    fontSize: 14,
    marginLeft: 5,
    marginRight: 5,
    color: theme.palette.common.white,
    padding: 6,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    width: "auto !important",
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  media: {
    width: "auto",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "top center",
  },
  productImg: {
    width: "100%",
    objectFit: "contain",
    borderRadius: 0,
  },
  cardActionArea: {
    height: 140,
    position: "relative",
  },
  hoverActions: {
    paddingLeft: 0,
    margin: "0 auto",
    textAlign: "center",
    marginTop: 6,
  },
  hoverAction: {
    display: "inline-block",
    marginRight: 10,
    listStyleType: "none",
    width: "auto !important",
    "&:last-child": {
      marginRight: 0,
    },
  },
  hoverActionLink: {
    color: theme.palette.primary.light,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    padding: 0,
    minWidth: "unset",
  },
  hoverActionIcon: {
    fontSize: 12,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  tooltipDesc: {
    fontSize: 11,
    marginBottom: 5,
    color: theme.palette.text.primary,
  },
  tooltipStat: {
    fontSize: 12,
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  tooltipStatTitle: {
    fontSize: 11,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ddd",
  },
  arrow: {
    color: theme.palette.common.white,
  },
}));

const ImageViewItem = ({
  cartAddItem,
  imageBasketAddItem,
  imageBasketItems,
  orderGroupId,
  product,
  showProductModalOnOpen,
}) => {
  const [downloadProduct] = useMutation(INITIALIZE_PRODUCT_DOWNLOAD);

  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const { statistics, description, userPermissions, minimumQuantity } = product;

  const [quantity, setQuantity] = useState(minimumQuantity);
  const [open, setOpen] = useState(showProductModalOnOpen);

  const { addToImageCart, download, order, share } = userPermissions || {};

  const isImage = addToImageCart ?? false;

  const { downloadCount, shareCount, viewCount } = statistics;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [saveImageBasket] = useMutation(SAVE_IMAGE_BASKET, {
    onCompleted: resultData => {
      if (resultData && resultData.addToImageBank) {
        alert.success(<FormattedMessage id="product.addedToImageBasket" />);
        const {
          imageName,
          itemId,
          productId,
          settings,
        } = resultData.addToImageBank;
        imageBasketAddItem({
          item: { imageName, itemId, productId, settings },
        });
      }
    },
  });

  const handleAddImageBasket = () => {
    const isDuplicate = imageBasketItems.some(x => {
      return x.productId === product.id;
    });

    if (imageBasketItems) {
      if (isDuplicate) {
        snackbar.workspaceError(
          <FormattedMessage id="product.duplicateItem" />
        );
      } else {
        saveImageBasket({
          variables: {
            orderGroupId,
            productId: product.id,
          },
        });
      }
    } else {
      snackbar.workspaceError(<FormattedMessage id="product.failedToCart" />);
    }
  };

  const handleProduct = () => {
    if (addToImageCart) {
      handleAddImageBasket();
    } else {
      handleOpen();
    }
  };

  const renderCart = () => {
    const { productType } = product;

    return (
      <>
        {productType !== "DYNAMIC_TEMPLATE" && (
          <li className={classes.hoverAction}>
            <Button
              className={classes.hoverActionLink}
              onClick={() => handleProduct()}
            >
              <Icon
                className={clsx([
                  "fas fa-shopping-cart",
                  classes.hoverActionIcon,
                ])}
              />
            </Button>
          </li>
        )}
      </>
    );
  };

  const handleDownloadProduct = async () => {
    try {
      const res = await downloadProduct({
        variables: { productId: product.id },
      });
      if (res && res.data && res.data.initProductDownload) {
        const downloadUrl = res.data.initProductDownload;
        saveAs(downloadUrl);
      } else {
        console.log("Download Failure");
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <>
      <Box elevation="0" className={classes.card} onClick={handleOpen}>
        <img 
          src={product.thumbnailImageUri}
          alt=""
          className={classes.productImg}
        />
        <Box className={classes.mediaHover}>
          <IconButton className={classes.iconButtonView} onClick={handleOpen}>
            <i className="fa fa-search-plus" />
          </IconButton>
        </Box>
        <div className={classes.hoverActionsWrap}>
          <ul className={classes.hoverActions}>
            <li className={classes.hoverAction}>
              <Tooltip
                placement="top"
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                title={
                  <>
                    <Typography color="inherit" className={classes.tooltipDesc}>
                      {description}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item md={4} align="center">
                        <Typography
                          color="inherit"
                          className={classes.tooltipStatTitle}
                        >
                          <FormattedMessage id="product.downloads" />
                        </Typography>
                        <Typography
                          color="inherit"
                          className={classes.tooltipStat}
                        >
                          {downloadCount}
                        </Typography>
                      </Grid>
                      <Grid item md={4} align="center">
                        <Typography
                          color="inherit"
                          className={classes.tooltipStatTitle}
                        >
                          <FormattedMessage id="product.views" />
                        </Typography>
                        <Typography
                          color="inherit"
                          className={classes.tooltipStat}
                        >
                          {viewCount}
                        </Typography>
                      </Grid>
                      <Grid item md={4} align="center">
                        <Typography
                          color="inherit"
                          className={classes.tooltipStatTitle}
                        >
                          <FormattedMessage id="product.shares" />
                        </Typography>
                        <Typography
                          color="inherit"
                          className={classes.tooltipStat}
                        >
                          {shareCount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }
              >
                <Button className={classes.hoverActionLink}>
                  <Icon
                    className={clsx([
                      "fas fa-info-circle",
                      classes.hoverActionIcon,
                    ])}
                    onClick={handleOpen}
                  />
                </Button>
              </Tooltip>
            </li>
            {share && (
              <li className={classes.hoverAction}>
                <IconButton className={classes.hoverActionLink}>
                  <Icon
                    className={clsx([
                      "fas fa-share-alt",
                      classes.hoverActionIcon,
                    ])}
                  />
                </IconButton>
              </li>
            )}
            {download && (
              <li className={classes.hoverAction}>
                <IconButton
                  onClick={handleDownloadProduct}
                  className={classes.hoverActionLink}
                >
                  <Icon
                    className={clsx([
                      "fas fa-download",
                      classes.hoverActionIcon,
                    ])}
                  />
                </IconButton>
              </li>
            )}
            {(isImage || order) && renderCart()}
          </ul>
        </div>
      </Box>
      <ProductModal
        cartAddItem={cartAddItem}
        open={open}
        product={product}
        handleClose={handleClose}
        quantity={quantity}
        setQuantity={setQuantity}
        isImage={isImage}
        handleAddImageBasket={handleAddImageBasket}
      />
    </>
  );
};

ImageViewItem.defaultProps = {
  orderGroupId: null,
  showProductModalOnOpen: false,
};

ImageViewItem.propTypes = {
  cartAddItem: PropTypes.func.isRequired,
  imageBasketAddItem: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
      settings: PropTypes.string,
    })
  ).isRequired,
  orderGroupId: PropTypes.number,
  product: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    minimumQuantity: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
    statistics: PropTypes.shape({
      downloadCount: PropTypes.number,
      shareCount: PropTypes.number,
      viewCount: PropTypes.number,
    }),
    thumbnailImageUri: PropTypes.string.isRequired,
    userPermissions: PropTypes.shape({
      addToImageCart: PropTypes.bool,
      download: PropTypes.bool,
      order: PropTypes.bool,
      share: PropTypes.bool,
    }),
  }).isRequired,
  showProductModalOnOpen: PropTypes,
};

const mapStateToProps = state => {
  return {
    imageBasketItems: state.api.imageBasket.imageBasketItems,
    orderGroupId: getSelectedSiteOrderGroupId(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      imageBasketAddItem,
      cartAddItem,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewItem);

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const ProjectSelector = ({ disabled, onChangeProject, onClickNewProject, projects, selectedProject }) => {
  const isSelectable = projects?.length > 0;

  const STATUS_VALUE = "status";

  return (
    <FormControl disabled={disabled || !isSelectable} fullWidth sx={{ p: 1 }} size="small">
      <InputLabel id="project-selector-label-id">
        <FormattedMessage id="projectSelector.selectProject" />
      </InputLabel>
      <Select
        disabled={disabled || !isSelectable}
        label={<FormattedMessage id="projectSelector.selectProject" />}
        labelId="project-selector-label-id"
        onChange={
          e =>
            onChangeProject(e.target.value)
        }
        value={
          isSelectable
            ? selectedProject
            : STATUS_VALUE
        }
      >
        {!projects &&
          <MenuItem value={STATUS_VALUE}>
            <CircularProgress color="inherit" size="1em" sx={{ verticalAlign: "middle" }} />
          </MenuItem>
        }
        {(projects && projects.length == 0) &&
          <MenuItem value={STATUS_VALUE}>
            <FormattedMessage id="projectSelector.noProjects" />
          </MenuItem>
        }
        {isSelectable && projects.map(project => (
          <MenuItem
            key={project.projectId}
            value={project}
          >
            {project.name}
          </MenuItem>
        ))}
      </Select>
      <Button variant="contained" sx={{ mt: 1 }} onClick={onClickNewProject}>
        <FormattedMessage id="projectSelector.newProject" />
      </Button>
    </FormControl>
  );
};

export default ProjectSelector;

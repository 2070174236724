import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
}));

const LinkPrimary = ({ label, icon, customClass, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={clsx([classes.root, customClass])} onClick={onClick}>
      <Icon className={clsx([icon, classes.icon])} />
      {label}
    </Button>
  );
};

export default LinkPrimary;

import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from '@mui/material/Paper';

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PushPinIcon from "@mui/icons-material/PushPin";
import ListItemIcon from "@mui/material/ListItemIcon";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import ProjectContent from "../../components/Project/ProjectContent"
import ProjectSelector from "../../components/Project/ProjectSelector";
import { Resizable } from "react-resizable";
import { useLazyQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../graphql/queries";
import { useSelector } from "react-redux";
import ProjectModal from "../../components/Project/ProjectModal/ProjectModal";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(3)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(3)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  transition: theme.transitions.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginRight: open ? "0px" : "-8px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ProjectPage = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [isResizable, setIsResizable] = useState(true);
  const [btnClicked, setBtnClicked] = useState(true);

  const selectedWorkspace = useSelector(
    state => state.ui.toggleWorkspaces.workspace
  );

  const [selectedSiteId, setSelectedSiteId] = useState(
    (selectedWorkspace && selectedWorkspace.id) || null
  );

  const [selectedProject, setSelectedProject] = useState();
  const [projects, setProjects] = useState();
  const [getProjects, { data: projectsData, loading: projectsLoading, error: projectsError }] = useLazyQuery(GET_PROJECTS);

  useEffect(() => {
    if (selectedSiteId && selectedSiteId > 0) {
      getProjects({ variables: { siteId: selectedSiteId } });
    }
  }, [selectedSiteId]);

  useEffect(() => {
    if (projectsData?.projects?.getProjects && !(projectsLoading || projectsError)) {

      // Parse settings for each projectField
      const parsedProjects = projectsData.projects.getProjects.map((project) => {
        const parsedProjectFields = project.projectFields.map((field) => {
          // Parse the settings property if it exists
          if (field.jsonFieldSettings) {

            try {
              const settings = JSON.parse(field.jsonFieldSettings);

              return { ...field, settings };
            } catch (error) {
              console.error(`Error parsing settings for projectField: ${field}`, error);
              return field;
            }
          }
          return field;
        });

        return { ...project, projectFields: parsedProjectFields };
      });
      setProjects(parsedProjects);
      setSelectedProject(parsedProjects[0] || null);
    }
    else if (projectsError) {
      setProjects([]);
      setSelectedProject(null);
    }
  }, [projectsData, projectsError, projectsLoading]);

  const handleAddProject = (project) => {
    var temp = [...projects, project];
    setProjects(temp);
    setProjectModalOpen(false);
    setSelectedProject(project);

  }

  const handleOnChangeProjects = (project) => {
    setSelectedProject(project);
  }

  const handleDrawerToggle = () => {
    if (!btnClicked && !open) {
      setOpen(!open);
      setBtnClicked(true);
    }
    if (open && btnClicked) {
      setOpen(!open);
      setBtnClicked(false);
    }
    if (open && !btnClicked) {
      setBtnClicked(true);
    }
  };

  const handleMouseEnter = (event) => {
    const isMouseOnDrawerHeader = event.target.closest(".MuiDrawerHeader");
    if (!isMouseOnDrawerHeader) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!btnClicked) {
      setOpen(false);
      setBtnClicked(false);
    }
  };

  const handleResizeStart = () => {
    setIsResizable(false);
  };

  const handleResizeStop = () => {
    setIsResizable(true);
  };

  const displayProjectContent = selectedProject && !(projectsError || projectsLoading);

  return (
    <Box sx={{ display: "flex", backgroundColor: theme.palette.background.cream }}>
      <Drawer variant="permanent"
        open={open}
        onMouseLeave={handleMouseLeave}
        PaperProps={{
          elevation: 1,
          square: false,
          sx: {
            height: 'calc(100vh - 112px)',
            top: "104px",
            zIndex: 2,
          },
        }}>
        <DrawerHeader open={open} className="MuiDrawerHeader">
          <IconButton onClick={handleDrawerToggle}>
            {open ? (
              btnClicked ? (
                <ChevronLeftIcon
                  sx={{ opacity: 0.4, "&:hover": { opacity: 1 } }}
                />
              ) : (
                <PushPinIcon
                  sx={{
                    fontSize: "70%",
                    marginTop: "5px",
                    marginBottom: "2px",
                  }}
                />
              )
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Box
          onMouseEnter={handleMouseEnter}
          sx={{
            height: "100%",
            marginTop: "-30px",
            zIndex: 1,
            opacity: open ? 1 : 0,
          }}
        >
          <List>
            {[
              { text: "Startsida", icon: <HomeOutlinedIcon /> },
              { text: "Mina uppgifter", icon: <TaskOutlinedIcon /> },
              { text: "Tidredovisning", icon: <PunchClockOutlinedIcon /> },
              { text: "Inkorg", icon: <NotificationsNoneOutlinedIcon /> }].map(({ text, icon: Icon }, index) => (
                <ListItem key={text} disablePadding sx={{ display: "block", marginTop: "0px", marginBottom: "0px", padding: 0 }}>
                  <ListItemButton
                    sx={{
                      minHeight: 34,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        marginRight: "8px",
                      }}
                    >
                      {Icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ marginTop: "0px", marginBottom: "0px" }} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
          {open && (
            <>
              <Divider sx={{ marginTop: "8px", marginBottom: "22px" }} />
              <ProjectSelector projects={projects} onChangeProject={handleOnChangeProjects} loading={projectsLoading} selectedProject={selectedProject} onClickNewProject={n => setProjectModalOpen(true)} />
            </>
          )}
        </Box>
      </Drawer>
      <Paper sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "8px",
      }}
      >
        {!displayProjectContent &&
          <Box color="text.secondary" component="h1" m="auto">
            {projectsLoading &&
              <CircularProgress color="inherit" size={60} sx={{ verticalAlign: "middle" }} />
            }
            {(projectsError || !projectsLoading && (projects?.length === 0)) &&
              <FormattedMessage id="projectSelector.noProject" />
            }
          </Box>
        }
        {displayProjectContent &&
          <ProjectContent
            projectId={selectedProject.projectId}
            siteId={selectedSiteId}
          />
        }
        {projectModalOpen &&
          <ProjectModal
            onClose={e => setProjectModalOpen(false)}
            onSave={handleAddProject}
            open={projectModalOpen}
            siteId={selectedSiteId}
          />
        }
      </Paper>

    </Box>
  );
};

export default ProjectPage;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import IconLink from "../../../common/FormControl/IconLink";
import { ConfirmDeleteModal } from "../../../common";
import { useDeleteResourceVersion, useSnackbar } from "../../../../hooks";

const useStyles = makeStyles(() => ({
  td: {
    fontSize: 12,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 5,
    paddingRight: 5,
    "& p": {
      fontSize: 12,
    },
  },
}));

const VersionTableRowItem = ({
  item,
  locked,
  handleDownloadResource,
  refetchVersionHistory,
  isDeleteIconVisible,
}) => {
  const classes = useStyles();
  const [deleteModal, setDeleteModal] = useState(false);
  const alert = useAlert();
  const snackbar = useSnackbar();

  const { execute, loading } = useDeleteResourceVersion();

  const toggleDeleteModal = () => {
    setDeleteModal(prev => !prev);
  };

  const onError = () => {
    snackbar.workspaceError(
      <FormattedMessage id="common.genericErrorMessage" />
    );
  };

  const onSuccess = () => {
    alert.success(<FormattedMessage id="common.genericSuccess" />);
    setTimeout(() => {
      refetchVersionHistory();
    }, 400);
  };

  const onConfirmDeleteModal = () => {
    const { resourceVersionIdentifier } = item;
    execute({ resourceVersionIdentifier, onSuccess, onError });
  };

  return (
    <TableRow>
      <TableCell className={classes.td} align="center">
        {item.versionNumber}
      </TableCell>
      <TableCell className={classes.td}>
        <Typography>
          <FormattedDate value={item.createdDate} />
        </Typography>
      </TableCell>
      <TableCell className={classes.td}>
        <Typography>{item.originalFilename}</Typography>
      </TableCell>
      <TableCell className={classes.td}>
        <Typography>{item.displayFilename}</Typography>
      </TableCell>
      <TableCell className={classes.td}>
        <Typography> {item.initialOriginalFilename}</Typography>
      </TableCell>
      <TableCell className={classes.td}>
        <Typography>{item.createdBy}</Typography>
      </TableCell>
      <TableCell className={classes.td} align="center">
        <IconLink
          icon="fa fa-download"
          disabled={locked}
          onClick={() => {
            handleDownloadResource(item.versionNumber);
          }}
        />
        {isDeleteIconVisible && (
          <IconLink
            icon="fas fa-trash"
            placement="top"
            title={<FormattedMessage id="context.delete" />}
            onClick={toggleDeleteModal}
          />
        )}
      </TableCell>
      {deleteModal && (
        <ConfirmDeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          onConfirmCloseModal={onConfirmDeleteModal}
          loading={loading}
        />
      )}
    </TableRow>
  );
};

VersionTableRowItem.defaultProps = {
  locked: false,
  isDeleteIconVisible: false,
};

VersionTableRowItem.propTypes = {
  item: PropTypes.shape({
    createdBy: PropTypes.string,
    createdDate: PropTypes.string,
    displayFilename: PropTypes.string,
    initialOriginalFilename: PropTypes.string,
    originalFilename: PropTypes.string,
    resourceVersionIdentifier: PropTypes.string,
    versionNumber: PropTypes.number,
  }).isRequired,
  locked: PropTypes.bool,
  handleDownloadResource: PropTypes.func.isRequired,
  refetchVersionHistory: PropTypes.func.isRequired,
  isDeleteIconVisible: PropTypes.bool,
};

export default VersionTableRowItem;

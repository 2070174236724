import React from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import { Typography, Box, Divider, Icon } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import clsx from "clsx";
import CustomTextField from "./TextField";
import TreeView from "./JSTree/index";
import DraggableModal from "./DraggableModal";
import DraggableModalHead from "./DraggableModal/DraggableModalHead";
import DraggableModalBody from "./DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  label: {
    fontSize: 12,
    marginBottom: 0,
  },
  space: {
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  formControl: {
    "& .MuiInput-underline": {
      borderRadius: 4,
      border: "1px solid #ddd",
    },
    "& .MuiInput-underline::before": {
      content: "none",
    },
    "& .MuiSelect-selectMenu": {
      paddingLeft: 10,
      fontSize: 12,
    },
    "& .MuiInputLabel-root": {
      fontSize: 12,
      marginTop: 0,
    },
    "& .MuiInputLabel-shrink": {
      marginTop: 10,
      backgroundColor: theme.palette.common.white,
    },
    "& label + .MuiInput-formControl": {
      marginTop: 9,
    },
  },
  menuItem: {
    fontSize: 12,
  },
  treeWrap: {
    marginTop: 5,
    maxHeight: 300,
    overflowY: "auto",
  },
  rowWrap: {
    marginBottom: 15,
  },
}));

const WorkspaceEditModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const data = {
    core: {
      data: [
        { text: "Projects", icon: "fa fa-briefcase" },
        { text: "Issues", icon: "fa fa-tasks" },
        {
          text: "Folder",
          icon: "fas fa-folder",
          children: [
            { text: "Folder 1", icon: "fas fa-folder" },
            { text: "Folder 2", icon: "fas fa-folder" },
          ],
        },
      ],
      check_callback: true,
      themes: {
        dots: false,
      },
    },
    plugins: ["dnd", "checkbox"],
  };

  const emails = [
    "Uniqueue 4 (uniqueue4@gmail.com)",
    "(ulf.nilsson@uqbygg.se)",
  ];

  const [emailName, setEmail] = React.useState([]);

  const handleEmail = event => {
    setEmail(event.target.value);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="workspace.organizeWorkspaces" />}
      />
      <DraggableModalBody>
        <Box className={classes.rowWrap}>
          <Typography className={classes.label}>
            <FormattedMessage id="workspace.name" />
          </Typography>
          <CustomTextField
            placeholder="Benämning"
            value="Norrevos arbetsytor"
          />
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.rowWrap}>
          <Typography className={classes.label}>
            <FormattedMessage id="workspace.whatToShare" />
          </Typography>
          <Box className={classes.treeWrap}>
            <TreeView treeData={data} />
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.rowWrap}>
          <Typography className={classes.label}>
            <FormattedMessage id="workspace.whoToShare" />
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <InputLabel>Välj</InputLabel>
            <Select
              multiple
              value={emailName}
              onChange={handleEmail}
              input={<Input />}
            >
              {emails.map(email => (
                <MenuItem
                  className={classes.menuItem}
                  key={email}
                  value={email}
                >
                  {email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Divider className={classes.divider} />
        <Box align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.saveChanges" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default WorkspaceEditModal;

import React, { useState } from "react";
import { useIntl } from "react-intl";
import CustomTextField from "../common/TextField";
import { makeStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from "prop-types";
import { Grid, Button, IconButton } from "@mui/material";

const useStyle = makeStyles({});

const FiterField = ({ groupKey, onAddFilter, label }) => {

  const classes = useStyle();

  const [filterTerm, setFilterTerm] = useState("");

  const addItem = () => {

    if (filterTerm.trim() !== "") {
      onAddFilter(groupKey, filterTerm)
      setFilterTerm("");  
    }
  }

  const handlePressEnter = e => {
    if (e.key === "Enter") {
      addItem();
    }
  };

  return (    
      <Grid container spacing = {1} alignItems = "center" >
      <Grid item xs={10} className={classes.colPadding}>
        <CustomTextField label={label} name="tag" value={filterTerm} onKeyDown={handlePressEnter} onChange={(e) => setFilterTerm(e.target.value)} />
      </Grid>
      <Grid item xs={2} align="center" className={classes.pl0}>

        <IconButton sx={{ marginTop: 0.5 }} color="primary" onClick={n => addItem()} ><AddIcon/></IconButton>
      </Grid>
    </Grid >)
}

export default FiterField;
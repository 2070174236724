import clsx from "clsx";
import PropTypes from "prop-types";

import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { useQuery } from "@apollo/client";
import { AppBar, Box, Link, Toolbar, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  cartInitialize,
  expandItem,
  imageBasketInitialize,
  setProductView,
  toggleCart,
  toggleDetails,
} from "../../../actions";

import {
  getCompanyLogo,
  getSelectedSiteOrderGroupId,
  getThemeName,
} from "../../../helpers/selectors";

import { getCart } from "../../../graphql/queries/getCart";
import { getImageBasket } from "../../../graphql/queries/getImageBasket";
import getProductView from "../../../graphql/queries/productView";

import { Cart } from "../../Cart";
import { HeaderSearch, WorkspaceNew } from "../../common";
import SiteEventsWatcher from "../../SiteEventsWatcher";
import UserDetails from "../../UserDetails";

import NavbarMobileDrawer from "../NavBar/NavbarDrawer";
import MobileMenu from "../NavBar/NavbarMobile";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
        boxShadow: "unset",
        minHeight: 60,        
  },
  header: {
    backgroundColor: theme.palette.component.headerBackground,
    color: theme.palette.component.headerColor,
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
    },
    minHeight: 60,
      width: "100%",
    zIndex:1100
  },
  toolbar: {
    minHeight: 53,
    padding: "3px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
      flexWrap: "wrap",
      minHeight: 40,
    },
  },
  navbrandWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navbrand: {
    objectFit: "contain",
    maxWidth: 160,
    maxHeight: 58,
    borderRadius: 0,
    marginRight: "1em",
    [theme.breakpoints.down("sm")]: {
      width: 100,
      backgroundSize: "contain",
      marginRight: 8,
    },
  },
  notifyWrap: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "16px",
      paddingRight: "5px",
    },
  },
  notification: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  notify: {
    position: "relative",
    cursor: "pointer",
  },
  notifyIcon: {
    fontSize: 16,
    cursor: "pointer",
  },
  badge: {
    backgroundColor: theme.palette.component.badgeBackground,
    position: "absolute",
    textAlign: "center",
    color: theme.palette.component.badgeColor,
    fontSize: 10,
    borderRadius: "50%",
    padding: "2px 7px",
    fontWeight: "700",
    left: 10,
    bottom: 9,
    cursor: "pointer",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hideProfileOnMobile: {
    [theme.breakpoints.down("md")]: {
      // marginLeft: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Header = ({
  api,
  cartInitialize,
  cartItems,  
  history,  
  imageBasketInitialize,
  imageBasketItems,  
  orderGroupId,
  selectedWorkspace,  
  setProductView,  
  toggleCart,
  toggleDetails,
  companyLogo,
  ui,
  workspaceName,
  fileGroups,
  oneStreamGroups,
  structure,
}) => {
  const classes = useStyles();
  
 
  const company = useSelector(
    state => state.api.currentViewer.viewer.defaultTheme
  );

  const { data: imageBasketData, refetch: refetchImageBasket } = useQuery(
    getImageBasket,
    {
      variables: { orderGroupId: 0 },
    }
  );

  const { data, refetch: refetchGetCart } = useQuery(getCart, {
    skip: !selectedWorkspace.id,
  });
  const { data: productViewData, loading } = useQuery(getProductView, {
    variables: {
      siteId: selectedWorkspace.id,
    },
    skip: !selectedWorkspace.id,
  });

  const handleDeleteItem = async ({ index }) => {
    const cartItemsTemp = [...cartItems];
    cartItemsTemp.splice(index, 1);
    // const cartItemsToSave = cartItemsTemp.map(item => ({
    //   productId: item.productId,
    //   quantity: item.quantity,
    // }));

    cartInitialize({
      items: cartItemsTemp,
    });

    // const results = await saveCart({
    //   variables: {
    //     orderGroupId,
    //     items: cartItemsToSave,
    //   },
    // });
    // if (results && results.data && results.data.saveShoppingCartContent) {
    //   cartInitialize({
    //     items: cartItemsTemp,
    //   });
    //   alert.success(<FormattedMessage id="product.successRemovedFromCart" />);
    // } else {
    //   alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
    // }
  };

  const handleImageBasketIcon = () => {
    history.push("/image-basket");
  };

  const handleMessageIcon = () => {
    history.push("/messaging");
  };

  useEffect(() => {
    if (productViewData && !loading) {
      setProductView(productViewData);
    }
  }, [productViewData]);

  /* 
      When cartItems changes (specifically targeting the product quantity in checkout),
      will refetch the entire cart items to get the updated the price.
      TODO: Future work, will remove this use effect function as Backend will return the updated price
      or entire updated product item when quantity changes. 
   */
  // TODO: This keeps on looping
  // useEffect(() => {
  //   if (cartItems && selectedWorkspace && selectedWorkspace.id) {
  //     refetchGetCart();
  //   }
  // }, [cartItems]);

  useEffect(() => {
    if (
      imageBasketData &&
      imageBasketData.imageBank &&
      imageBasketData.imageBank.items.length > 0
    ) {
      const { items } = imageBasketData.imageBank;
      imageBasketInitialize({ items });
    }
  }, [imageBasketData]);

  useEffect(() => {
    if (data && data.shoppingCartContent) {
      const { groups = [] } = data.shoppingCartContent || {};
      const orderGroup = groups.find(x => x.orderGroupId === orderGroupId);
      const { items = [], oneStreamGroups = [], fileGroups = [] } =
        orderGroup || {};

      cartInitialize({
        items,
        fileGroups,
        oneStreamGroups,
      });
    }
  }, [data, company]);

  return (
    <div className={classes.root}>
      <AppBar position="relative" elevation={0} className={classes.header}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.sectionMobile}>
            <NavbarMobileDrawer />
          </div>
          <Link href="/" className={classes.navbrandWrap}>
            <img src={companyLogo} alt="Logo" className={classes.navbrand} />
          </Link>
          <WorkspaceNew
            workspaceName={workspaceName}
            workspaces={structure}
          />
          <HeaderSearch
            toggleDetails={toggleDetails}
            searchDetails={ui.toggleDetails}
          />

          <div className={classes.notification}>
            <Box px={2} className={classes.notifyWrap}>
              <Tooltip
                title={<FormattedMessage id="widget.messaging" />}
                placement="bottom"
              >
                <div
                  className={classes.notify}
                  onClick={() => handleMessageIcon()}
                >
                  <i
                    className={clsx(["nc-icon nc-chat-33", classes.notifyIcon])}
                  />
                  {0 > 1 && <span className={classes.badge}>{1}</span>}
                </div>
              </Tooltip>
            </Box>

            <Box px={2} className={classes.notifyWrap}>
              <Tooltip
                title={<FormattedMessage id="context.imageBasket" />}
                placement="bottom"
              >
                <div
                  className={classes.notify}
                  onClick={() => handleImageBasketIcon()}
                >
                  <i
                    className={clsx(["nc-icon nc-album-2", classes.notifyIcon])}
                  />
                  {imageBasketItems.length > 0 && (
                    <span className={classes.badge}>
                      {imageBasketItems.length}
                    </span>
                  )}
                </div>
              </Tooltip>
            </Box>
            <Box px={2}>
              <Tooltip
                title={<FormattedMessage id="context.shoppingCart" />}
                placement="bottom"
              >
                <div
                  className={classes.notify}
                  onClick={() => toggleCart({ name: "Cart" })}
                >
                  <i
                    className={clsx([
                      "nc-icon nc-cart-simple",
                      classes.notifyIcon,
                    ])}
                  />
                  {(cartItems.length > 0 ||
                    fileGroups.length > 0 ||
                    oneStreamGroups.length > 0) &&
                    selectedWorkspace.id && (
                      <span className={classes.badge}>
                        {cartItems.length +
                          fileGroups.length +
                          oneStreamGroups.length}
                      </span>
                    )}
                </div>
              </Tooltip>
              {ui.toggleCart.open === true ? (
                <Cart
                  miniBasketIsOpen={ui.toggleCart.open}
                  toggleCart={toggleCart}
                  handleDeleteItem={handleDeleteItem}
                  cartItems={cartItems}
                  fileGroups={fileGroups}
                  oneStreamGroups={oneStreamGroups}
                  selectedWorkspace={selectedWorkspace}
                  history={history}
                />
              ) : (
                ""
              )}
            </Box>
          </div>
          <div className={classes.hideProfileOnMobile}>
            <UserDetails viewer={api.currentViewer.viewer} />
          </div>
          <MobileMenu
            selectedWorkspace={selectedWorkspace}
            totalImageItems={imageBasketItems.length}
            totalCartItems={cartItems.length}
          />
        </Toolbar>
        <SiteEventsWatcher />
      </AppBar>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    api: state.api,
    cartItems: state.api.cart.cartItems,
    fileGroups: state.api.cart.fileGroups,
    oneStreamGroups: state.api.cart.oneStreamGroups,
    imageBasketItems: state.api.imageBasket.imageBasketItems,
    orderGroupId: getSelectedSiteOrderGroupId(state),
    selectedWorkspace: state.ui.toggleWorkspaces.workspace,
    workspaceName: state.ui.toggleWorkspaces.workspace.name,
    ui: state.ui,
    companyLogo: getCompanyLogo(state),
    themeName: getThemeName(state),
    workspaces:
      state.api.currentViewer &&
      state.api.currentViewer.viewer &&
      state.api.currentViewer.viewer.sites
        ? state.api.currentViewer.viewer.sites
        : [],
    structure:
      state.api.currentViewer &&
      state.api.currentViewer.viewer &&
      state.api.currentViewer.viewer.structure &&
      state.api.currentViewer.viewer.structure.items &&
      state.api.currentViewer.viewer.structure.items.length > 0
        ? state.api.currentViewer.viewer.structure.items
        : [],
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      cartInitialize,
      setProductView,
      expandItem,
      toggleCart,
      toggleDetails,
      imageBasketInitialize,
    },
    dispatch
  );
};

Header.defaultProps = {
  orderGroupId: null,
  selectedWorkspace: {
    id: null,
    name: null,
  },
  history: {
    push: () => {
      console.log("History is null");
    },
  },
  workspaceName: null,
  oneStreamGroups: [],
  fileGroups: [],
};

Header.propTypes = {
  oneStreamGroups: PropTypes.arrayOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      packageName: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  fileGroups: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number,
      packageId: PropTypes.string,
      packageName: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  workspaceName: PropTypes.string,
  api: PropTypes.shape({
    currentViewer: PropTypes.shape({
      viewer: PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        email: PropTypes.string,
        avatar: PropTypes.string,
      }),
    }),
  }).isRequired,
  cartInitialize: PropTypes.func.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      quantity: PropTypes.number,
    })
  ).isRequired,
  expandItem: PropTypes.func.isRequired,
  selectedWorkspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  companyLogo: PropTypes.string.isRequired,
  setProductView: PropTypes.func.isRequired,
  toggleCart: PropTypes.func.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  orderGroupId: PropTypes.number,
  ui: PropTypes.shape({
    toggleCart: PropTypes.shape({
      open: PropTypes.bool.isRequired,
    }).isRequired,
    toggleDetails: PropTypes.shape({
      showDetails: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  imageBasketInitialize: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
    })
  ).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Typography, Box, Grid } from "@mui/material";
import ImageModal from "../ImageModal";
import OriginalImageModal from "../OriginalImageModal";
import WebbModal from "../WebbModal";
import MSOfficeModal from "../MSOfficeModal";
import TryckModal from "../TryckModal";
import { GET_PRODUCT_IMAGES } from "../../../graphql/queries";
import DeleteModal from "../DeleteModal";

import Image from "./Image";
import OriginalImage from "./OriginalImage";
import Settings from "./Settings";
import ColorModel from "./ColorModel";
import FileFormat from "./FileFormat";
import { initialOptionValues, SETTING_IDS } from "../helpers";

const useStyles = makeStyles(theme => ({
  imageItem: {
    padding: 10,
    border: "1px solid #f1f1f1",
    borderRadius: 3,
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.06)",
    minHeight: "80%",
    "& .MuiIconButton-root": {
      marginRight: 0,
      marginLeft: "auto",
    },
    "& .MuiCheckbox-root": {
      padding: 5,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 11,
    },
    "& select": {
      paddingTop: 3,
      paddingBottom: 3,
      fontSize: 11,
    },
    "& .MuiSelect-icon": {
      top: 4,
    },
  },
  imageText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
  },
}));

const ImageListItem = ({
  colorModelOptions,
  fileFormatOptions,
  handleImageBasketItem,
  idx,
  imageBasketItem,
  onDeleteItem,
  selectImageOption,
}) => {
  const { imageName, productId } = imageBasketItem;
  const classes = useStyles();
  const [productImages, setProductImages] = useState({});
  const { thumbnailImageUri, productImageUri } = productImages || {};
  const { data, error, loading } = useQuery(GET_PRODUCT_IMAGES, {
    variables: { productId },
  });

  const [showColorModel, setShowColorModel] = useState(false);
  const [showFileFormat, setShowFileFormat] = useState(false);
  const [includeOriginal, setIncludeOriginal] = useState(
    imageBasketItem.includeOriginal ?? false
  );
  const [selectedColorMode, setSelectedColorMode] = useState("");
  const [selectedFileFormat, setSelectedFormat] = useState("");
  const [imageOptions, setImageOptions] = useState(initialOptionValues);

  useEffect(() => {
    let { webb, msOffice, tryck } = initialOptionValues;
    let colorMode, fileFormat;
    if (Array.isArray(imageBasketItem.settings)) {
      imageBasketItem.settings.forEach((setting, index) => {
        const { settingId } = setting;
        if (settingId === SETTING_IDS.WEB) {
          webb = true;
        } else if (settingId === SETTING_IDS.MS_OFFICE) {
          msOffice = true;
        } else if (settingId === SETTING_IDS.PRINT) {
          tryck = true;
        }
        if (tryck) {
          colorMode = setting.selectedColorMode;
          fileFormat = setting.selectedFileFormat;
        }
      });
    }
    setImageOptions({ webb, msOffice, tryck });
    if (colorMode) {
      setShowColorModel(true);
      setSelectedColorMode(colorMode);
    }
    if (fileFormat) {
      setShowFileFormat(true);
      setSelectedFormat(fileFormat);
    }
  }, []);

  useEffect(() => {
    if (selectImageOption) {
      let settings = [];

      const { webb, msOffice, tryck } = imageOptions;

      if (webb) {
        settings.push({ settingId: SETTING_IDS.WEB });
      } else {
        settings = settings.filter(x => x.settingId !== SETTING_IDS.WEB);
      }

      if (msOffice) {
        settings.push({ settingId: SETTING_IDS.MS_OFFICE });
      } else {
        settings = settings.filter(x => x.settingId !== SETTING_IDS.MS_OFFICE);
      }

      if (tryck) {
        settings.push({
          settingId: SETTING_IDS.PRINT,
          selectedFileFormat,
          selectedColorMode,
        });
      } else {
        settings = settings.filter(x => x.settingId !== SETTING_IDS.PRINT);
      }

      const params = {
        includeOriginal,
        productId,
        settings,
      };

      handleImageBasketItem(params, idx);
    }
  }, [includeOriginal, imageOptions, selectedColorMode, selectedFileFormat]);

  useEffect(() => {
    if (data && data.product) {
      const { product } = data;
      setProductImages(product);
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [data]);

  const [openImage, setOpenImage] = useState(false);

  const handleOpenImage = () => {
    setOpenImage(true);
  };
  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const [openOriginalImage, setOpenOriginalImage] = useState(false);
  const handleOpenOriginalImage = () => {
    setOpenOriginalImage(true);
  };
  const handleCloseOriginalImage = () => {
    setOpenOriginalImage(false);
  };

  const [openWebb, setOpenWebb] = useState(false);
  const handleOpenWebb = () => {
    setOpenWebb(true);
  };
  const handleCloseWebb = () => {
    setOpenWebb(false);
  };

  const [openMSOffice, setOpenMSOffice] = useState(false);
  const handleOpenMSOffice = () => {
    setOpenMSOffice(true);
  };
  const handleCloseMSOffice = () => {
    setOpenMSOffice(false);
  };

  const [openTryck, setOpenTryck] = useState(false);
  const handleOpenTryck = () => {
    setOpenTryck(true);
  };
  const handleCloseTryck = () => {
    setOpenTryck(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteItem = async yes => {
    if (!yes) {
      setOpenDelete(false);
    } else if (typeof onDeleteItem === "function") {
      setOpenDelete(false);
      onDeleteItem({ productId, idx });
    }
  };

  return (
    <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
      <Box className={classes.imageItem}>
        <Image
          thumbnailImageUri={thumbnailImageUri}
          handleOpenImage={handleOpenImage}
          handleOpenDelete={handleOpenDelete}
          imageName={imageName}
          loading={loading}
        />
        {selectImageOption && (
          <>
            <OriginalImage
              includeOriginal={includeOriginal}
              setIncludeOriginal={setIncludeOriginal}
              handleOpenOriginalImage={handleOpenOriginalImage}
            />
            <Typography className={classes.imageText}>
              <FormattedMessage id="imageBasket.customizeFor" />
            </Typography>
            <Settings
              handleOpenMSOffice={handleOpenMSOffice}
              handleOpenTryck={handleOpenTryck}
              handleOpenWebb={handleOpenWebb}
              imageOptions={imageOptions}
              setImageOptions={setImageOptions}
            />
            {imageOptions.tryck && (
              <>
                <ColorModel
                  colorModelOptions={colorModelOptions}
                  selectedColorMode={selectedColorMode}
                  setSelectedColorMode={setSelectedColorMode}
                  setShowColorModel={setShowColorModel}
                  showColorModel={showColorModel}
                />

                <FileFormat
                  fileFormatOptions={fileFormatOptions}
                  selectedFileFormat={selectedFileFormat}
                  setSelectedFormat={setSelectedFormat}
                  setShowFileFormat={setShowFileFormat}
                  showFileFormat={showFileFormat}
                />
              </>
            )}
          </>
        )}
      </Box>
      {openImage && (
        <ImageModal
          open={openImage}
          handleClose={handleCloseImage}
          productImageUri={productImageUri}
          imageName={imageName}
        />
      )}

      {openOriginalImage && (
        <OriginalImageModal
          open={openOriginalImage}
          handleClose={handleCloseOriginalImage}
        />
      )}
      {openWebb && <WebbModal open={openWebb} handleClose={handleCloseWebb} />}

      {openMSOffice && (
        <MSOfficeModal open={openMSOffice} handleClose={handleCloseMSOffice} />
      )}
      {openTryck && (
        <TryckModal open={openTryck} handleClose={handleCloseTryck} />
      )}
      {openDelete && (
        <DeleteModal
          open={openDelete}
          handleClose={handleCloseDelete}
          imageName={imageName}
          handleDeleteItem={handleDeleteItem}
        />
      )}
    </Grid>
  );
};

ImageListItem.propTypes = {
  colorModelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  fileFormatOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  handleImageBasketItem: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  imageBasketItem: PropTypes.shape({
    imageName: PropTypes.string,
    itemId: PropTypes.number,
    productId: PropTypes.string,
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  selectImageOption: PropTypes.bool.isRequired,
};

export default ImageListItem;

import React from "react";
import PropTypes from "prop-types";
import {
  SELECT_FIELD_MANDATORY,
  MULTI_CHECKBOX_FIELD,
} from "../../../constant/types";
import MultiSelectCheckboxes from "../FilesTable/MultiSelectCheckboxes";
import SelectFieldMandatory from "../FilesTable/SelectFieldMandatory";

const getSelectValue = (propertyValueIds, propertyValues) => {
  let value = "";
  for (let i = 0; i < propertyValueIds.length; i += 1) {
    const val = propertyValueIds[i];
    const foundValue = propertyValues.find(x => x.id === val);
    if (foundValue) {
      value = foundValue.id;
      break;
    }
  }
  return value;
};

const getMutliCheckboxValue = (propertyValueIds, propertyValues) => {
  let value = [];
  for (let i = 0; i < propertyValueIds.length; i += 1) {
    const val = propertyValueIds[i];
    const foundValue = propertyValues.find(x => x.id === val);
    if (foundValue) {
      const optionValue = { title: foundValue.name, value: foundValue.id };
      value = [...value, optionValue];
    }
  }
  return value;
};

const PropertyField = ({ property, onChange, propertyValueIds, disabled }) => {
  const { type, propertyValues, name } = property;
  if (type === SELECT_FIELD_MANDATORY) {
    const value = getSelectValue(propertyValueIds, propertyValues);
    return (
      <SelectFieldMandatory
        propertyValuesState={propertyValues}
        name={name}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  if (type === MULTI_CHECKBOX_FIELD) {
    const options = propertyValues.map(x => {
      return {
        title: x.name,
        value: x.id,
      };
    });

    const value = getMutliCheckboxValue(propertyValueIds, propertyValues);
    return (
      <MultiSelectCheckboxes
        name={name}
        options={options}
        value={value || []}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  console.log("Production Property Field not yet implemented.");
  return null;
};

PropertyField.defaultProps = {
  propertyValueIds: [],
  disabled: false,
};

PropertyField.propTypes = {
  propertyValueIds: PropTypes.arrayOf(PropTypes.number),
  property: PropTypes.shape({
    type: PropTypes.string.isRequired,
    propertyValues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
      })
    ).isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PropertyField;

import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ShowContentDialog from './ShowContentDialog';


const ImageListTitlebarBelow = () => {

  const [dialogContent, setDialogContent] = useState(null);


  return (
    <Paper elevation={1} sx={{ p: 2, backgroundColor: "#ffffff", borderRadius: "4px", }}>
      <Typography variant="body1" sx={{ fontSize: "16px", padding: "0px 8px 8px 8px", margin: "0px", textAlign: "center" }}>Nyckelresurser</Typography>
      <ImageList sx={{ width: "100%" }} cols={4}> {/*rowHeight = {164}*/}
        {itemData.map((item) => (
          <ImageListItem key={item.img} style={{ cursor: "pointer" } }>
            <img
          srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
          src={`${item.img}?w=248&fit=crop&auto=format`}
          alt={item.title}
          loading="lazy"
              onClick={n => setDialogContent(item)}
            />
            <ImageListItemBar
              title={item.title}
              subtitle={<span>{item.author}</span>}
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
      {dialogContent && (<ShowContentDialog onClose={n => setDialogContent(null)} selectedValue={dialogContent} open={dialogContent != null}/>)}
    </Paper>
  );
}

export default ImageListTitlebarBelow;

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Viktiga projektnyheter',
    author: 'Video med viktig information',
    videoUrl: 'ffdjeYkdXKE'
  },
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Uppdragsbeskrivning',
    author: 'Mål, riskhantering, beslut mm',
    pdfUrl: 'https://uq.unq.se/webapi/pdf/uppgiftsbeskrivning_150124.pdf'
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Tvåfaktorsautentisering',
    author: 'Därför behövs tvåfaktorsautentisering',
    pdfUrl: 'https://uq.unq.se/webapi/pdf/Behovsanalys%20Tvafaktorsautentisering%20Kommunal%202024.pdf'
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Wilmas inloggningstjänst',
    author: 'Använd Kommunals inloggningstjänst',
    pdfUrl: 'https://uq.unq.se/webapi/pdf/Behovsanalys%20Anvanda%20Kommunals%20inloggningstjanst%20till%20Wilma%202024.pdf'
  },
];

import React from "react";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

const SearchDropdown = ({ disabled, id, label, onChange, options, sx, value }) => {
  const forwardId = id ?? `${JSON.stringify(options)}-select`;
  const labelId = `${forwardId}-label`
  return (
    <FormControl size="small" variant="filled" sx={sx}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        disabled={disabled}
        fullWidth
        id={forwardId}
        label={label}
        labelId={labelId}
        native={false}
        onChange={onChange}
        value={value}
        variant="filled"
      >
        {Array.isArray(options) && options.map((option, index) => {
          const { label, value } = option;
          const key = `${forwardId}-${value}`;
          return (
            <MenuItem key={key} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SearchDropdown;

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import ResourcesTable from "../ResourcesTable";
import ResourcesHeader from "./ResourcesHeader";
import {
  GET_RESOURCES,
  GET_FILE_VIEW_SETTINGS,
  GET_RFP_RESOURCES,
} from "../../../graphql/queries";
import { DOCUMENT_TYPE } from "../../../constant/types";
import SelectFolder from "../../../assets/img/select-folder.svg";

const useStyles = makeStyles(theme => ({
  selectFolder: {
    textAlign: "center",
    padding: "40px 0",
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const Resources = ({
  folderId,
  workspace,
  setSelectedResources,
  selectedResources,
  searchText,
  siteId,
  isProductEdit,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25); // !!!
  const [resources, setResources] = useState([]);
  const [ascending, setAscending] = useState(false);
  const [sortfield, setSortfield] = useState("");
  const [sortFieldOptions, setSortFieldOptions] = useState([]);
  const [resourcesTotalCount, setResourcesTotalCount] = useState(0);
  const [searchTextQuery, setSearchTextQuery] = useState(searchText);

  const { data, loading, error } = useQuery(GET_RESOURCES, {
    variables: {
      includeLinkedResources: false,
      folderId,
      ascending,
      offset: page * pageSize,
      pageSize,
      sortfield,
      resourceType: DOCUMENT_TYPE,
    },
    skip: !folderId,
  });

  const {
    data: dataSearchResult,
    loading: loadingSearchResult,
    error: errorSearchResult,
    refetch: refetchFileSearch,
  } = useQuery(GET_RFP_RESOURCES, {
    variables: {
      siteId,
      searchText: searchTextQuery,
      sortfield,
      ascending,
      offset: page * pageSize,
      pageSize,
    },
    skip: !siteId || !searchTextQuery || searchTextQuery === "",
  });

  const debouncedSetSearchTextQuery = useCallback(
    debounce(value => {
      setSearchTextQuery(value);
    }, 800),
    []
  );

  useEffect(() => {
    debouncedSetSearchTextQuery(searchText);
  }, [searchText]);

  useEffect(() => {
    refetchFileSearch();
  }, [searchTextQuery]);

  useEffect(() => {
    if (
      !loadingSearchResult &&
      !errorSearchResult &&
      dataSearchResult &&
      dataSearchResult.requestForProposal &&
      dataSearchResult.requestForProposal.fileSearch &&
      dataSearchResult.requestForProposal.fileSearch.items
    ) {
      const tempResources = dataSearchResult.requestForProposal.fileSearch.items.map(
        file => {
          const selectedResource = selectedResources.find(
            selected => selected.id === file.id
          );
          if (selectedResource) {
            return { ...file, selected: true };
          }
          return { ...file, selected: false };
        }
      );
      setResources(tempResources);
      setResourcesTotalCount(
        dataSearchResult.requestForProposal.fileSearch.totalCount || 0
      );
    }
    if (errorSearchResult) {
      setResources([]);
      setResourcesTotalCount(0);
    }
  }, [dataSearchResult, loadingSearchResult, errorSearchResult]);

  const { data: dataFileViewSettings } = useQuery(GET_FILE_VIEW_SETTINGS, {
    variables: {
      siteId: workspace && workspace.id ? workspace.id : null,
    },
    skip: !workspace || !workspace.id,
  });

  const handlePageSizeChange = (e) => {
    setPage(0);
    setPageSize(e.target.value);
  };

  useEffect(() => {
    if (
      dataFileViewSettings &&
      dataFileViewSettings.fileView &&
      dataFileViewSettings.fileView.sortingOptions
    ) {
      const fileViewSortingOptions =
        dataFileViewSettings.fileView.sortingOptions;
      const sortFieldOptionsTemp = fileViewSortingOptions.map(option => ({
        label: option.field,
        value: option.field,
      }));

      const defaultSort = fileViewSortingOptions.find(x => x.default);
      if (defaultSort) {
        setAscending(defaultSort ? defaultSort.ascending : true);
        setSortfield(defaultSort.field);
      }
      setSortFieldOptions(sortFieldOptionsTemp);
    } else {
      setSortFieldOptions([]);
    }
  }, [dataFileViewSettings]);

  useEffect(() => {
    if (!loading && !error && data && data.resources && data.resources.items) {
      const tempResources = data.resources.items.map(file => {
        const selectedResource = selectedResources.find(
          selected => selected.id === file.id
        );
        if (selectedResource) {
          return { ...file, selected: true };
        }
        return { ...file, selected: false };
      });
      setResources(tempResources);
      setResourcesTotalCount(data.resources.totalCount || 0);
    }
    if (error) {
      setResources([]);
      setResourcesTotalCount(0);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const tempResources = resources.map(file => {
      const selectedResource = selectedResources.find(
        selected => selected.id === file.id
      );
      if (selectedResource) {
        return { ...file, selected: true };
      }
      return { ...file, selected: false };
    });
    setResources(tempResources);
  }, [selectedResources]);

  const classes = useStyles();

  const renderTable = () => {
    if (!folderId && (searchText === "" || !searchText))
      return (
        <Box className={classes.selectFolder}>
          <img src={SelectFolder} alt="Empty" />
          <Typography className={classes.emptyText}>
            <FormattedMessage id="rfp.selectFolder" />
          </Typography>
        </Box>
      );

    return (
      <ResourcesTable
        resources={resources || []}
        selectedResources={selectedResources}
        setSelectedResources={setSelectedResources}
        loading={loading || loadingSearchResult}
        isProductEdit={isProductEdit}
      />
    );
  };

  return (
    <Box p={2}>
      <ResourcesHeader
        ascending={ascending}
        onPageChange={(e, p) => setPage(p)}
        onPageSizeChange={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        setAscending={setAscending}
        setSortfield={setSortfield}
        sortfield={sortfield}
        sortFieldOptions={sortFieldOptions}
        totalCount={resourcesTotalCount}
      />
      {renderTable()}
    </Box>
  );
};

Resources.defaultProps = {
  folderId: null,
  workspace: null,
  searchText: "",
  siteId: null,
  isProductEdit: false,
};

Resources.propTypes = {
  siteId: PropTypes.bool,
  searchText: PropTypes.string,
  folderId: PropTypes.number,
  workspace: PropTypes.shape({
    id: PropTypes.number, //.isRequired,
    name: PropTypes.string, //.isRequired,
  }),
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      changedBy: PropTypes.string.isRequired,
      changedDate: PropTypes.string.isRequired,
      uploadedDate: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  setSelectedResources: PropTypes.func.isRequired,
  isProductEdit: PropTypes.bool,
};

export default Resources;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    marginTop: 0,
    marginBottom: 10,
    lineHeight: "18px",
  },
}));

const ProductDescription = ({ description }) => {
  const classes = useStyles();
  return <p className={classes.text}>{description}</p>;
};

ProductDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ProductDescription;

import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_ITEM_IN_BASKET_GROUP } from "../graphql/mutations";
import { cartInitialize } from "../actions";
import { getSelectedSiteOrderGroupId } from "../helpers/selectors";

// eslint-disable-next-line import/prefer-default-export
export const useDeleteItemInBasketGroup = ({
  errorRemoveBasketItem,
  successRemoveBasketItem,
}) => {
  const [deleteItemInGroup, { loading }] = useMutation(
    DELETE_ITEM_IN_BASKET_GROUP
  );
  const dispatch = useDispatch();
  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const handleRemoveItem = async ({ shoppingCartItemId }) => {
    try {
      const response = await deleteItemInGroup({
        variables: {
          shoppingCartItemId,
        },
      });
      if (
        response &&
        response.data &&
        response.data.shoppingCartContext.deleteItem &&
        response.data.shoppingCartContext.deleteItem.success
      ) {
        const { result = {} } = response.data.shoppingCartContext.deleteItem;
        const { groups = [] } = result;
        const orderGroup = groups.find(x => x.orderGroupId === ordergroupId);
        const { items = [], fileGroups = [], oneStreamGroups = [] } =
          orderGroup || {};
        successRemoveBasketItem();
        dispatch(
          cartInitialize({
            items,
            fileGroups,
            oneStreamGroups,
          })
        );
      } else {
        errorRemoveBasketItem();
      }
    } catch (e) {
      errorRemoveBasketItem();
    }
  };

  return { execute: handleRemoveItem, loading };
};

import React from "react";
import { Popover, List, ListItem, ListItemText } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  listRoot: {
    padding: 0,
    width: 260,
  },
  listIcon: {
    minWidth: "unset",
    marginRight: 5,
  },
  listItem: {
    padding: "10px 30px",
  },
  listText: {
    fontSize: 12,
    color: "#000",
  },
  buttonUploadFromComputer: {
    textTransform: "none",
    padding: 0,
    fontSize: 12,
    color: "#000",
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
  },
}));

const ChannelsMiscPopover = ({
  id,
  open,
  anchorEl,
  handleClose,
  onClickCreateChannel,
}) => {
  const classes = useStyles();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List classes={{ root: classes.listRoot }}>
        <ListItem
          classes={{ root: classes.listItem }}
          button
          onClick={onClickCreateChannel}
        >
          <ListItemText
            classes={{ primary: classes.listText }}
            primary="Create a channel"
          />
        </ListItem>
      </List>
    </Popover>
  );
};

ChannelsMiscPopover.defaultProps = {
  id: undefined,
  anchorEl: null,
};

ChannelsMiscPopover.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClickCreateChannel: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default ChannelsMiscPopover;

import { gql } from '@apollo/client';
import Task from "../../fragments/Projects/Task";

const GET_TASK = gql`
  query getTask($projectId: Int!) {
    projects {
      getTask(projectId: $projectId) {
        ...Task
      }
    }
  }
  ${Task}
`;

export default GET_TASK;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Icon, Typography, IconButton } from "@mui/material";
import clsx from "clsx";
import { useIntl } from "react-intl";
import WorkspaceIcon from "../../common/WorkspaceIcon";

const useStyles = makeStyles(theme => ({
  userBox: {
    padding: "15px 10px",
    border: "1px solid #e3e3e3",
    borderRadius: 4,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.05)",
    position: "relative",
    textAlign: "center",
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  profile: {
    width: 40,
    height: 40,
    objectFit: "contain",
    borderRadius: "50%",
    backgroundColor: "#EDEDED",
    padding: 5,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
    wordBreak: "break-word",
    marginTop: 10,
  },
  iconButtonDownload: {
    margin: -4,
    padding: 8,
  },
  iconButtonEdit: {
    color: theme.palette.primary.main,
    margin: -4,
    padding: 8,
  },
  iconButtonRemove: {
    color: theme.palette.common.red,
    margin: -4,
    padding: 8,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  iconEdit: {
    fontSize: 10,
  },
  iconRemove: {
    fontSize: 13,
  },
  userActions: {
    position: "absolute",
    right: 4,
    top: 4,
  },
}));

const Workspace = ({
  workspace,
  handleOpenEdit,
  //handleOpenDeleteModal,
  handleDownload,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { icon, iconColor, name, siteId, canEdit } = workspace;

  return (
    <>
      <Grid item xs={12} md={2}>
        <Box className={classes.userBox}>
          <WorkspaceIcon icon={icon} color={iconColor} size={"1.5rem"} />
          <Typography className={classes.name}>{name}</Typography>
          {canEdit && (
            <Box className={classes.userActions}>
              <IconButton
                className={classes.iconButtonDownload}
                onClick={() => handleDownload(siteId)}
                title={intl.formatMessage({
                  id: "admin.workspaceTemplatesDownload",
                })}
              >
                <Icon
                  className={clsx([
                    "fa fa-download",
                    classes.icon,
                    classes.iconEdit,
                  ])}
                />
              </IconButton>
              <IconButton
                className={classes.iconButtonEdit}
                onClick={() => handleOpenEdit(siteId)}
                title={intl.formatMessage({ id: "context.edit" })}
              >
                <Icon
                  className={clsx([
                    "fa fa-pencil-alt",
                    classes.icon,
                    classes.iconEdit,
                  ])}
                />
              </IconButton>
              {/*<IconButton
                className={classes.iconButtonRemove}
                onClick={() => handleOpenDeleteModal(siteId)}
                title={intl.formatMessage({ id: "context.delete" })}
              >
                <Icon
                  className={clsx([
                    "fa fa-times",
                    classes.icon,
                    classes.iconRemove,
                  ])}
                />
              </IconButton>*/}
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

Workspace.defaultProps = {
  workspace: null,
};

Workspace.propTypes = {
  handleOpenEdit: PropTypes.func.isRequired,
  workspace: PropTypes.shape({
    canEdit: PropTypes.bool,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    name: PropTypes.string,
    siteId: PropTypes.number,
  }),
  handleOpenDeleteModal: PropTypes.func.isRequired,
};

export default Workspace;

import React, { forwardRef, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { FormattedMessage } from "react-intl";
import { Box, Icon, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { enUS, sv } from "date-fns/locale";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    "& .react-datepicker__navigation--next:disabled": {
      visibility: "hidden",
    },
  },
  changeButton: {
    "&.MuiButton-text": {
      color: "inherit",
      fontSize: "90%",
      marginLeft: "0.5em",
      padding: "2px",
    },
    "&.MuiButton-text .MuiButton-label": {
      color: "inherit",
      fontWeight: "initial",
      textTransform: "initial",
    },
    "&.MuiButton-text .MuiButton-label .MuiIcon-root": {
      fontSize: "120%",
    },
  },
}));

const MonthSelector = ({ lang, onChange }) => {
  const classes = useStyles();

  const [maxDate, setMaxDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    const now = new Date();
    setMaxDate(now);
    updateDateRange(now);
    registerLocale("EN", enUS);
    registerLocale("SV", sv);
  }, []);

  const handleDatePickerChange = date => {
    onChange(updateDateRange(date));
  };

  const updateDateRange = date => {
    const m = moment(date);
    const range = {
      startDate: m.startOf("month").toDate(),
      endDate: m.endOf("month").toDate(),
    };
    setStartDate(range.startDate);
    setEndDate(range.endDate);
    return range;
  };

  const ChangeButton = forwardRef(({ value, onClick }, ref) => (
    <Button className={classes.changeButton} onClick={onClick}>
      <Icon className={clsx(["far fa-calendar-alt"])} />
      &nbsp;
      <FormattedMessage id="common.change" />
    </Button>
  ));

  const renderCustomHeader = ({
    date,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => {
    return (
      <div className="react-datepicker__header--time">
        <button
          aria-label="Previous Year"
          className="react-datepicker__navigation react-datepicker__navigation--previous"
          disabled={prevYearButtonDisabled}
          onClick={decreaseYear}
        />
        <div className="react-datepicker-year-header">{date.getFullYear()}</div>
        <button
          aria-label="Next Year"
          className="react-datepicker__navigation react-datepicker__navigation--next"
          disabled={nextYearButtonDisabled}
          onClick={increaseYear}
        />
      </div>
    );
  };

  return (
    <Box className={classes.root}>
      <Box component="span" style={{ verticalAlign: "middle" }}>
        {startDate?.toLocaleDateString()}&nbsp;
        <FormattedMessage id="common.to" />
        &nbsp;
        {endDate?.toLocaleDateString()}
      </Box>
      <DatePicker
        customInput={<ChangeButton />}
        dateFormat="yyyy-MM-dd"
        locale={lang}
        maxDate={maxDate}
        onChange={handleDatePickerChange}
        renderCustomHeader={renderCustomHeader}
        selected={startDate}
        showFullMonthYearPicker
        showMonthYearPicker
      />
    </Box>
  );
};

MonthSelector.defaultProps = {
  lang: "SV",
};

MonthSelector.propTypes = {
  lang: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default MonthSelector;

import { gql } from '@apollo/client';

const UPDATE_COMPANY_SUPPLIER = gql`
  mutation UpdateCompanySupplier(
    $companyId: Int!
    $active: Boolean
    $visibility: SupplierVisibility
  ) {
    companyAdminContext {
      suppliers {
        updateCompanySupplier(
          companyId: $companyId
          active: $active
          visibility: $visibility
        ) {
          supplierVisibility
          active
          incomingSupplierRequests {
            items {
              requestedAt
              supplierRequestId
              workspace
            }
          }
          siteSupplierFor {
            active
            siteId
            siteName
            id
            deleted
            displayName
            notifyEmail
            supplierUsers {
              companySiteSupplierUserId
              deleteUrl
              username
            }
          }

          formattedSupplierId
          supplierId
        }
      }
    }
  }
`;

export default UPDATE_COMPANY_SUPPLIER;

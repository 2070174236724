import React, { useState } from "react";

import { Paper, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TextField } from "@mui/material"
import BaseTypeEdit from "./BaseTypeEdit";
import cloneDeep from 'lodash/cloneDeep';
import StatusFieldEdit from "./StatusFieldEdit";

const ProjectDialog = ({ project, onProjectUpdate, open, onClose, onSave }) => {


  const updateProjectValue = (name, value) => {

    var newProj = { ...project };
    newProj[name] = value;
    onProjectUpdate(newProj);

  }


  const updateProjectFieldValue = (name, value) => {
    // Ensure selectedProject is not null or undefined
    if (!project) {
      return;
    }

    // Clone the selectedProject to avoid mutating the state directly
    const newSelectedProject = cloneDeep(project);

    // Update the field value in projectFields
    newSelectedProject.projectFields = newSelectedProject.projectFields.map((field) => {

      if (field.fieldId === currentSelectedField.fieldId) {
        // Update the specific field value
        const updatedField = { ...field, [name]: value };

        setCurrentSelectedField(updatedField);

        return updatedField;
      }
      return field;

    });

    // Update the state with the newSelectedProject
    onProjectUpdate(newSelectedProject);

  }

  const [currentSelectedField, setCurrentSelectedField] = useState();

  const renderFieldComponent = () => {
    
    switch (currentSelectedField.type) {
      case 3: return (<StatusFieldEdit projectField={currentSelectedField} onFieldChange={updateProjectFieldValue} />); break;
      case 2:
      case 1:
      case 0: return (<BaseTypeEdit projectField={currentSelectedField} onFieldChange={updateProjectFieldValue} />)

      default: return "unknown field";
    }
  }

  return (<>{open && (<Dialog open={open}>
    <DialogTitle>Projekt <TextField value={project.name} onChange={e => updateProjectValue("name", e.target.value)} /></DialogTitle>
    <DialogContent>

      <TableContainer component={Paper}>
        <Table>

          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {project && project.projectFields.length > 0 && project.projectFields.map(n => (
              <TableRow key={"f_" + n.id}>
                <TableCell>{n.name}</TableCell><TableCell>{n.type}</TableCell><TableCell item sx={2}><Button onClick={e => setCurrentSelectedField(n)}>Edit</Button><Button>Delete</Button></TableCell>
              </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button>Ny statuskolumn</Button>
      {currentSelectedField && (renderFieldComponent())}

    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button type="submit" onClick={onSave}>Spara</Button></DialogActions>
  </Dialog >)}</>)

}

export default ProjectDialog;
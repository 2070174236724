import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../hooks";
import { Grid, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { GET_RESOURCE_GROUPS_EDIT } from "../../../../../graphql/queries";
import { UPDATE_RESOURCE_GROUP_ASSIGNMENT } from "../../../../../graphql/mutations";
import GroupTree from "./GroupTree";
import getGroupDataStructure from "../../../../../helpers/getGroupTreeDataStructure";
import PageTabPanel from "../../../../common/PageTabPanel";

const useStyles = makeStyles(theme => ({
  heading: {
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
  },

  btnMain: {
    padding: "11px 22px",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  mobilePadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
}));

const Groups = ({ groupsIsDraft, setGroupsIsDraft, resourceId }) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const [resourceGroupEdit, setResourceGroupsEdit] = useState([]);
  const [groupTree, setGroupTree] = useState({});
  const [checkedGroups, setCheckedGroups] = useState([]);

  const [
    getResourceGroupsEdit,
    { data: resourceGroupsEditData },
  ] = useLazyQuery(GET_RESOURCE_GROUPS_EDIT);

  const [updateResourceGroup] = useMutation(UPDATE_RESOURCE_GROUP_ASSIGNMENT);

  useEffect(() => {
    if (resourceId) {
      getResourceGroupsEdit({ variables: { resourceId } });
    }
  }, [resourceId]);

  useEffect(() => {
    if (
      resourceGroupsEditData &&
      resourceGroupsEditData.productEdit &&
      resourceGroupsEditData.productEdit.getResourceGroupsEdit &&
      resourceGroupsEditData.productEdit.getResourceGroupsEdit.resourceGroups &&
      resourceGroupsEditData.productEdit.getResourceGroupsEdit.resourceGroups
        .length > 0
    ) {
      setResourceGroupsEdit(
        resourceGroupsEditData.productEdit.getResourceGroupsEdit.resourceGroups
      );
    }
  }, [resourceGroupsEditData]);

  useEffect(() => {
    const newGroupTree = getGroupDataStructure({
      groups: resourceGroupEdit,
    });
    setGroupTree(newGroupTree);
  }, [resourceGroupEdit]);

  useEffect(() => {
    if (resourceGroupEdit && resourceGroupEdit.length > 0) {
      var foundGroups = findCheckedGroups(resourceGroupEdit);
      setCheckedGroups(foundGroups);
    }
  }, [resourceGroupEdit]);

  const findCheckedGroups = groups => {
    let tempGroups = [];    
    groups && groups.forEach(group => {
      if (group.selected) {
        tempGroups = [...tempGroups, group.groupId];
      }
      tempGroups = [...tempGroups, ...findCheckedGroups(group.childGroups)];
    });
    return tempGroups;
  };

  const setDraft = () => {
    if (!groupsIsDraft) {
      setGroupsIsDraft(true);
    }
  };

  const setUnDraft = () => {
    if (groupsIsDraft) {
      setGroupsIsDraft(false);
    }
  };

  const removeSelectedGroup = group => {
    const root = group && group.isRoot && group.isRoot;

    if (!root) {
      const temp = checkedGroups.filter(x => x !== group.groupId);
      setCheckedGroups(temp);
    }

    if (root) {
      const { hasChildGroups, groupId, childGroupIds } = group;
      let groupedIds = [];
      if (hasChildGroups) {
        const ids = [...childGroupIds, groupId];
        const filtered = checkedGroups.filter(x => !ids.includes(x));
        groupedIds = filtered;
      } else {
        groupedIds = checkedGroups.filter(x => x !== group.groupId);
      }

      setCheckedGroups(groupedIds);
    }
    setDraft();
  };

  const addSelectedGroup = group => {
    const root = group && group.isRoot && group.isRoot;

    if (!root) {
      const { groupId } = group;
      setCheckedGroups([...checkedGroups, groupId]);
    }

    if (root) {
      const { hasChildGroups, groupId, childGroupIds } = group;
      let groupedIds = [...checkedGroups];
      if (hasChildGroups) {
        groupedIds = [...groupedIds, ...childGroupIds, groupId];
      } else {
        groupedIds = [...groupedIds, groupId];
      }

      setCheckedGroups(groupedIds);
    }
    setDraft();
  };

  const handleCheckboxChange = (e, group) => {
    const { checked } = e.target;
    if (checked) {
      addSelectedGroup(group);
    } else {
      removeSelectedGroup(group);
    }
  };

  const handleSave = async () => {
    const update = { resourceId, groupIds: checkedGroups };

    try {
      const res = await updateResourceGroup({
        variables: { update },
      });

      if (res && res.data && res.data.updateResourceGroupAssignment) {
        alert.success(
          <FormattedMessage id="product.updatedResourceGroupAssignment" />
        );
        setUnDraft();
      } else {
        snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
      }
    } catch (e) {
      console.log(`Error saving group ${JSON.stringify(e)}`);
      snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
    }
  };

  return (
    <PageTabPanel spacing="3">
      <Grid item md={12}>
        <Typography className={classes.heading}>
          <FormattedMessage id="productEdit.groups" />
        </Typography>
        <Typography className={classes.text}>
          <FormattedMessage id="productEdit.groupsInfo" />
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <GroupTree
              groupTree={groupTree}
              checkedGroups={checkedGroups}
              handleCheckboxChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item md={12} align="right">
            <Button
              variant="contained"
              className={classes.btnMain}
              onClick={() => handleSave()}
            >
              <FormattedMessage id="btn.save" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PageTabPanel>
  );
};

Groups.defaultProps = {
  resourceId: null,
};

Groups.propTypes = {
  groupsIsDraft: PropTypes.bool.isRequired,
  setGroupsIsDraft: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
};

export default Groups;

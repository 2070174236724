import { gql } from '@apollo/client';

const SiteMenuEditItem = gql`
  fragment ChildMenusRecursive on SiteMenuEditItemDTO {
    childMenus {
      ...SiteMenuEditItem
      childMenus {
        ...SiteMenuEditItem
        childMenus {
          ...SiteMenuEditItem
          childMenus {
            ...SiteMenuEditItem
            childMenus {
              ...SiteMenuEditItem
            }
          }
        }
      }
    }
  }

  fragment SiteMenuEditItem on SiteMenuEditItemDTO {
    customPageId
    label
    menuType
    sitePage
  }
`;

export default SiteMenuEditItem;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import ProductPrice from "../ProductPrice";
import FormattedCurrency from "../../common/FormattedCurrency";

const useStyles = makeStyles(theme => ({
  productPrice: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  noMargin: {
    margin: 0,
  },
}));

const RenderProductPrice = ({ product, quantity }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6} className={classes.productPrice}>
      <h5 className={classes.noMargin}>
        <strong>
          {quantity === null ? (
            <FormattedCurrency value={product.minimumPrice} />
          ) : (
            <ProductPrice product={product} quantity={quantity} />
          )}
        </strong>
      </h5>
    </Grid>
  );
};

RenderProductPrice.propTypes = {
  product: PropTypes.shape({
    articleNo: PropTypes.string.isRequired,
    imageMetadata: PropTypes.shape({
      contact: PropTypes.string,
      date: PropTypes.string,
      model: PropTypes.string,
      location: PropTypes.string,
      occupation: PropTypes.string,
      photographer: PropTypes.string,
    }),
    numPages: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productImageUri: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    stock: PropTypes.shape({
      stockBalance: PropTypes.number.isRequired,
      temporaryOutOfStock: PropTypes.bool.isRequired,
    }),
    productDisplaySettings: PropTypes.shape({ showPrice: PropTypes.string }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        tagId: PropTypes.number.isRequired,
        tagName: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
};

export default RenderProductPrice;

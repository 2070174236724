import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import SelectFieldMandatory from "../FilesTable/SelectFieldMandatory";

const useStyles = makeStyles(() => ({
  formControl: {},
  textfield: {},
  input: {
    fontSize: 12,
    color: "#66615b",
    paddingRight: 0,
    minWidth: 50,
    paddingLeft: 10,
  },
  labelRoot: {
    color: "rgba(0, 0, 0, 0.54)!important",
    fontSize: 14,
  },
}));

const QuantityField = ({
  value,
  onChange,
  quantityProperty,
  fullWidth,
  name,
  disabled,
  ...rest
}) => {
  const classes = useStyles();
  const { quantityType, quantityFixedValues, quantityRange } =
    quantityProperty || {};

  if (quantityType === "FIXED") {
    const options = quantityFixedValues.map(x => ({ name: x.value, id: x.id }));
    return (
      <SelectFieldMandatory
        name={name}
        propertyValuesState={options}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }
  if (quantityType === "FREE" || quantityType === "RANGE") {
    const { max, min = 0 } = quantityRange || {};

    const inputProps = { inputmode: "numeric", pattern: "[0-9]*", min };

    return (
      <FormControl
        variant="outlined"
        fullWidth={fullWidth}
        className={classes.formControl}
      >
        <TextField
          className={classes.textfield}
          fullWidth={fullWidth}
          label={name}
          value={value !== undefined && value !== null ? value : ""}
          type="number"
          onChange={onChange}
          variant="outlined"
          size="small"
          inputProps={
            quantityType === "FREE" ? { ...inputProps } : { ...inputProps, max }
          }
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            classes: {
              input: classes.input,
            },
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.labelRoot,
            },
          }}
          disabled={disabled}
          {...rest}
        />
      </FormControl>
    );
  }
  return null;
};

QuantityField.defaultProps = {
  fullWidth: true,
  disabled: false,
};

QuantityField.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default QuantityField;

//import { createTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const COLORS = {
  KOMMUNALRÖD: "#CE122D",
  VIT: "#FFFFFF",
  VINRÖD: "#5A1432",
  CERISE: "#DA6CA3",
  LJUSROSA: "#FFDFE4",
  ORANGE: "#E87722",
  LEJONGUL: "#F1C04F",
  LJUSGUL: "#FCF2C8",
  MÖRKBLÅ: "#007C91",
  BLÅ: "#59BEC9",
  LJUSBLÅ: "#D6F2EF",
  MÖRKGRÖN: "#006F62",
  GRÖN: "#34B78F",
  LJUSGRÖN: "#CAEAD0",
  SVART: "#000000",
  GRÅ5: "#6F6F6F",
  GRÅ4: "#9D9D9D",
  GRÅ3: "#C6C6C6",
  GRÅ2: "#DADADA",
  GRÅ1: "#EDEDED",
};

const KOMMUNAL_THEME = createTheme({
  typography: {
    fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    body1: {
      fontSize: 14,
    },
    h6: {
      fontSize: 18,
      fontWeight: 400,
      textTransform: "none",
    },
    subtitle1: {
      fontSize: 12,
    },
  },

  palette: {
    common: {
      black: COLORS.SVART,
      white: COLORS.VIT,
      grey: COLORS.GRÅ2,
      lightGrey: COLORS.GRÅ1,
      neon: "#E76678",
      cerise: "#F6689B",
      blue: "#88d1d2",
      pink: "#FAD1D6",
      green: "#40ca0a",
      link: "#4A90E2",
      lightBlue: "#D6F2EF",
      gold: "#754f0b",
      red: COLORS.KOMMUNALRÖD,
    },
    background: {
      paper: COLORS.VIT,
      default: "#fafafa",
      cream: "#f4f3ed",
      gray: "#ddd",
      lightGray: "#f9f9f9",
      contrastGray: "#f1f1f1",
      orange: "#f07d17",
      contrastOrange: COLORS.LEJONGUL,
    },
    primary: {
      light: COLORS.CERISE,
      main: COLORS.VINRÖD, //COLORS.KOMMUNALRÖD,
      dark: COLORS.VINRÖD,
      contrastText: COLORS.VIT,
      alt: "#a53c59",
    },
    secondary: {
      light: COLORS.LJUSROSA,
      main: COLORS.KOMMUNALRÖD,
      dark: COLORS.KOMMUNALRÖD,
      contrastText: COLORS.VIT,
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: COLORS.VIT,
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      gray: "#444",
      lightGray: "#9a9a9a",
      pink: "rgba(102, 35, 54, 0.54)",
      lightPink: "rgba(102, 35, 54, 0.14)",
    },
    component: {
      hamburger: COLORS.VIT, //primary contrastText
      pageBackground: COLORS.VIT, //primary contrastText
      headerBackground: COLORS.KOMMUNALRÖD, //primary main
      headerColor: COLORS.VIT, //primary contrastText
      navbarBackground: COLORS.GRÅ3,
      navbarColor: COLORS.SVART, //primary contrastText
      navbarChildColor: COLORS.SVART, //primary contrastText
      navbarSeparator: COLORS.VINRÖD,
      badgeBackground: COLORS.LEJONGUL, //secondary main
      badgeColor: COLORS.SVART, //primary dark
      languageBackground: COLORS.KOMMUNALRÖD,
      productBoxColor: COLORS.VINRÖD, //primary main
      tabBackground: COLORS.CERISE,
      tabColor: COLORS.VIT,
      tabWrap: COLORS.KOMMUNALRÖD,
      customPanelColor: COLORS.VIT,
      customPanelBackground: COLORS.KOMMUNALRÖD,
      fileUploadBackground: COLORS.VIT,
      imageCardHover: COLORS.LJUSROSA,
      homeMainColor: COLORS.KOMMUNALRÖD, //secondary dark
      viewSwitchBackground: COLORS.GRÅ1,
      detailedSearchBox: COLORS.LJUSBLÅ, //
      detailedSearchBorder: COLORS.LJUSBLÅ,
      detailedSearchSwitch: COLORS.CERISE,
      selectedFolder: COLORS.LJUSGUL,
    },
  },
});

export default KOMMUNAL_THEME;

import { gql } from '@apollo/client';

const GET_COMPANY_RECEIVED_SALESDOCUMENTS = gql`
  query getReceivedSalesDocuments($companyId: Int!, $documentType:DocumentType!, $filter:DocumentFilter!, $offset:Int!, $pageSize:Int!) {
    companyAdminContext {
      salesDocuments {
        getReceivedSalesDocuments(companyId: $companyId, documentType:$documentType, filter:$filter, offset:$offset, count:$pageSize){
					salesDocuments{
						salesDocumentId
						documentNumber
						documentType
						documentStatus
		        documentDate
						customerName
						customerNo,
						yourReference,
						amountDue,
						unpaidAmount,
						category
						dueDate					
						
					}
					totalCount
				}			
      }
    }
  }
`;

export default GET_COMPANY_RECEIVED_SALESDOCUMENTS;

import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";

import { DYNAMIC_PRODUCT_TYPE } from "../../../constant/types";
import { useDeepLinkContext } from "../../../hooks";

import { Loading } from "../../common";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";

import DynamicProduct from "./DynamicProduct";
import NonDynamicProduct from "./NonDynamicProduct";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "90%",
  },
  body: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  loading: {
    height: "85vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ProductModal = ({
  customMetadata,
  fromFileView,
  handleAddImageBasket,
  handleClose,
  isImage,
  loading,
  open,
  product,
  quantity,
  setQuantity,
}) => {
  const classes = useStyles();

  const deepLinkContext = useDeepLinkContext();
  const { deepLink } = deepLinkContext;

  const _handleClose = () => {
    if (deepLink) {
      deepLinkContext.clearData();
    }
    handleClose();
  };

  const renderBody = () => {
    if (product.productType === DYNAMIC_PRODUCT_TYPE) {
      return (
        <DynamicProduct
          handleClose={_handleClose}
          product={product}
          quantity={quantity}
          setQuantity={setQuantity}
          customMetadata={customMetadata}
        />
      );
    }

    return (
      <NonDynamicProduct
        handleClose={_handleClose}
        product={product}
        quantity={quantity}
        setQuantity={setQuantity}
        isImage={isImage}
        handleAddImageBasket={handleAddImageBasket}
        customMetadata={customMetadata}
        fromFileView={fromFileView}
      />
    );
  };

  const renderLoading = () => {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  };

  return (
    !deepLink &&
    <DraggableModal
      open={open}
      handleClose={_handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={_handleClose} title={product.name} />
      <DraggableModalBody customClass={classes.body}>
        {loading ? renderLoading() : renderBody()}
      </DraggableModalBody>
    </DraggableModal>
  );
};

ProductModal.defaultProps = {
  customMetadata: null,
  fromFileView: false,
  loading: false,
  quantity: null,
};

ProductModal.propTypes = {
  cartAddItem: PropTypes.func.isRequired,
  customMetadata: PropTypes.arrayOf(PropTypes.shape({})),
  handleAddImageBasket: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isImage: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  quantity: PropTypes.number,
  setQuantity: PropTypes.func.isRequired,
};

export default ProductModal;

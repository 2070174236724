import { makeStyles } from "@mui/styles";
import {
  Box,
  InputAdornment,
  Card,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import UserField from "./UserField";
import DateEditor from "../../common/EditorContainer/DateEditor";
import DurationPopper from "../../common/EditorContainer/DurationEditor/DurationPopper";
import EventIcon from '@mui/icons-material/Event';
import CurrentUserCellValue from "../common/CurrentUserCellValue";
import JoditTextEditor from "../../common/JoditTextEditor";

const typography = {
  body: { fontSize: "13px", lineHeight: "18px", textTransform: "none", paddingLeft: "2px", paddingRight: "2px" },
  h6: { fontSize: "17px", fontWeight: 500, paddingLeft: "2px", paddingRight: "2px", paddingBottom: "4px" },
  label: { fontSize: "13px" },
};

const useStyles = makeStyles({
  table: {
    "& td": {
      border: "none",
      fontSize: 14,
      padding: 4,
      whiteSpace: "nowrap",
      "& svg": {
        fill: "#000",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        fontSize: "13px",
      },
      "&:first-child": {
        cursor: "default",
        width: 110,
      },
    },
  },
});

const textInputProps = {
  disableUnderline: true,
  startAdornment: //Set to null if not show.
    //null,
    <InputAdornment position="start">
      <Box sx={{ display: 'flex', alignItems: 'center', width: "16px", paddingLeft: "4px" }}>
        <EventIcon sx={{
          opacity: 0.7, fontSize: '16px',
        }} />
      </Box>
    </InputAdornment>,
  endAdornment: //Set to null if not show.
    null,
  sx: {
    '&.MuiInputBase-root': {
      padding: 0,
    },
    '&.MuiInputBase-root.MuiInput-root': {
      padding: 0,
    },
    width: "100%",
    backgroundColor: '#ffffff',
    textAlign: 'left',
    cursor: "text",
    padding: '4px',
    fontSize: '13px',
    minHeight: '1.5em',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid rgba(0, 0, 0, 0)',
    borderRadius: "4px",
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    '&.Mui-focused': {
      border: '1px solid rgba(0, 0, 0, 0.5)',
    },
  },
};

const TaskDetailsGrid = ({ editItem, columns, disabled, enableEdit, item, userLookup,
  handleTaskDatePropertyChange, handleTaskAssignmentPropertyChange,
  handleAddTimeLog,
  handleDeleteTimeLog,
  handleUpdateTimeLog,
  handleTaskPropertyChange,
  handleTaskValueChange


}) => {
  const classes = useStyles();




  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant="body1" sx={typography.label}>Startdatum</Typography>
      </Grid>
      <Grid item xs={2}><DateEditor disabled={disabled} enableEdit={enableEdit} value={item.startDate} views={['year', 'month', 'day']}
        onChange={(value) => handleTaskDatePropertyChange("startDate", value)} textInputProps={textInputProps} />
      </Grid>

      <Grid item xs={2}><Typography variant="body1" sx={typography.label}>Förfallodatum</Typography></Grid>
      <Grid item xs={2}><DateEditor disabled={disabled} enableEdit={enableEdit} value={item.endDate} views={['year', 'month', 'day']}
        onChange={(value) => handleTaskDatePropertyChange("endDate", value)} textInputProps={textInputProps} /></Grid>

      <Grid item xs={2}><Typography variant="body1" sx={typography.label}>Tilldelad</Typography></Grid>
      <Grid item xs={2}><UserField
        disabled={disabled}
        editable={enableEdit}
        name="assignments"
        onChange={({ name, value }) => handleTaskAssignmentPropertyChange(name, value)}
        userLookup={userLookup}
        value={editItem.assignments ? editItem.assignments[0] : null}
      /></Grid>

      <Grid item xs={2}><Typography variant="body1" sx={typography.label}>Registrerad tid</Typography></Grid>
      <Grid item xs={2}><DurationPopper
        disabled={disabled}
        enableEdit={enableEdit}
        item={item}
        onAdd={handleAddTimeLog}
        onDelete={handleDeleteTimeLog}
        onUpdate={handleUpdateTimeLog}
        startIcn={true}

        sx={{
          color: "rgba(0, 0, 0, 0.87)",
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "4px",
          fontSize: "13px",
          fontWeight: 400,
          minHeight: "1.5em",
          lineHeight: "22px",
          padding: "4px",
          "&.MuiBox-root": { minHeight: "1.5em", padding: "0px 0px 0px 4px", border: "0px", },
          "& .MuiTypography-root": { whiteSpace: "pre-wrap", padding: "0px", },
          "&.MuiInputBase-root": { border: "1px solid rgba(0, 0, 0, 0.5)", },
          "&:hover": { backgroundColor: '#f0f0f0', cursor: "text", },
        }}
      /></Grid>

      {columns?.map((column, index) => (
        <>
          <Grid item xs={2}>
            <Typography variant="body1" sx={typography.label}>{column.label}</Typography>
          </Grid>
          <Grid item xs={2}>
            {column.renderCell(editItem, handleTaskValueChange, enableEdit, false, disabled)}
          </Grid>
        </>
      ))}

      <Grid item xs={2}><Typography variant="body1" sx={typography.label}>Ägare</Typography></Grid><Grid item xs={2}>          {editItem.createdBy && (
        <UserField
          disabled
          name="createdBy"
          onChange={({ name, value }) => handleTaskPropertyChange(name, value)}
          userLookup={userLookup}
          value={editItem.createdBy}
        />
      )}
        {!editItem.createdBy && (
          <CurrentUserCellValue />
        )}</Grid>



    </Grid>
  )
}

export default TaskDetailsGrid;
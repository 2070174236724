import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { Grid, Typography} from "@mui/material";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import CustomTextField from "../../../common/TextField";
import CustomSelect from "../../../common/CustomSelect";
import { PrimaryButton } from "../../../common/Buttons";
import { ADD_EXTERNAL_USER } from "../../../../graphql/mutations/CompanyAdminContext";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    backgroundColor: theme.palette.background.cream,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "95%",
    },
  },
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
    justifyContent: "center",
    "& .MuiIcon-root": {
      display: "none",
    },
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
    "& svg": {
      display: "none",
    },
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
  margin: {
    marginTop: 15,
  },
}));

const EditUserModal = ({
  open,
  handleClose,
  refetch,
  companyId,
  isUpdate,
  handleUpdateExternalUser,
  selectedUser,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const roleOptions = useSelector(
    state => state.api.companyAdmin.companyRoles.roles
  );

  const [addExternalUser] = useMutation(ADD_EXTERNAL_USER);

  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    if (isUpdate) {
      setUsername(selectedUser.username);
    }
  }, [isUpdate]);

  const handleAddExternaluser = async () => {
    try {
      const res = await addExternalUser({
        variables: { targetCompanyId: companyId, targetRoleId: role, username },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.externalUsers &&
        res.data.companyAdminContext.externalUsers.addExternalUser
      ) {
        const {
          success,
        } = res.data.companyAdminContext.externalUsers.addExternalUser;
        if (success) {
          alert.success(<FormattedMessage id="common.genericSuccess" />);
          refetch();
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
        handleClose();
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="user.addNewExternalUser" />}
      />
      <DraggableModalBody>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="user.addNewExternalUser" />:
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <CustomTextField
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.margin}>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage id="user.companyRole" />:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomSelect
              options={roleOptions}
              value={role}
              onChange={e => setRole(e.target.value)}
              inputLabel={<FormattedMessage id="user.selectCompanyRole" />}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.margin}>
          <PrimaryButton
            onClick={() =>
              isUpdate
                ? handleUpdateExternalUser(role)
                : handleAddExternaluser()
            }
          >
            <FormattedMessage id="btn.save" />
          </PrimaryButton>
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default EditUserModal;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";
import RenderMonitoringType from "../MyMonitoring/RenderMonitoringType";
import { FormattedDate, FormattedTime } from "react-intl";
import NotificationSms from "../MyMonitoring/NotificationSms";
import MyNotificationModal from "./MyNotificationModal";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12,
  },

  tdCollapse: {
    fontSize: 11,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const MyNotificationsTableRow = ({ notification }) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const {
    savedDate,
    //workspace,
    savedNotificationId,
    name,
    notificationSummary,
  } = notification;

  return (
    <>
      <TableRow>
        <TableCell className={classes.td} style={{ whiteSpace: "nowrap" }}>
          <FormattedDate value={savedDate} />
          {"\u00A0"}
          <FormattedTime value={savedDate} />
        </TableCell>
        <TableCell className={classes.td}>
          <Button className={classes.link} onClick={() => handleOpenModal()}>
            {name}
          </Button>
        </TableCell>
        <TableCell className={classes.tdCollapse}>
          <NotificationSms type={notificationSummary.notificationAction} />
        </TableCell>
        <TableCell className={classes.tdCollapse}>
          {notificationSummary.monitoringTypes.map(m => (
            <RenderMonitoringType type={m} key={m} />
          ))}
        </TableCell>
        <TableCell className={classes.tdCollapse}>
          {notificationSummary.numberOfRecipients}
        </TableCell>
      </TableRow>

      {openModal && (
        <MyNotificationModal
          open={openModal}
          handleClose={handleCloseModal}
          savedNotificationId={savedNotificationId}
          notificationName={name}
        />
      )}
    </>
  );
};

export default MyNotificationsTableRow;

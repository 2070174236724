import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DocumentViewItem,
  EmptyList,
  ViewContainer,
} from "../../../ProductsMainView";

const useStyles = makeStyles(theme => ({
  spacing: {
    "& > .MuiGrid-item": {
      padding: 5,
    },
  },
}));

const DocumentView = ({ products }) => {
  const classes = useStyles();

  const RenderItems = ({ items }) => (
    items.map(product =>
    (
      <DocumentViewItem
        key={product.id}
        product={product}
        showProductModalOnOpen={false}
      />
    ))
  );

  const RenderView = ({ items }) => {
    const haveItems = Array.isArray(items) && items.length > 0;

    return (
      <ViewContainer>
        <Grid container className={classes.spacing}>
          {haveItems && <RenderItems items={items} />}
          {!haveItems && <EmptyList messageId="product.categoryEmpty" />}
        </Grid>
      </ViewContainer>
    );
  };

  return (
    products && products.items && (
      <RenderView items={products.items} />
    )
  );
};

DocumentView.propTypes = {
  products: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        thumbnailImageUri: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default DocumentView;

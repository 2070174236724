import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  skeleton: {
    borderRadius: 4,
    marginBottom: 15,
  },
  wrap: {
    padding: 0,
  },
  borderRight: {
    borderRight: "1px solid #ddd",
  },
}));

const ProposalSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6} className={classes.borderRight}>
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={35}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProposalSkeleton;

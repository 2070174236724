import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const DELETE_COMPANY_ROLE = gql`
  mutation DeleteCompanyRole($companyId: Int!, $companyRoleId: Int!) {
    companyAdminContext {
      companyRoles {
        deleteCompanyRole(
          companyId: $companyId
          companyRoleId: $companyRoleId
        ) {
          errorResult {
            ...ErrorResultFields
          }
          companyRoleListItems {
            companyRoleId
            name
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_COMPANY_ROLE;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { MuiChipsInput } from 'mui-chips-input'
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  tagWrap: {
    padding: 8,
    borderRadius: 4,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    backgroundColor: "#fff",
    textAlign: "left",
    "& .MuiFormControl-root > div:before": {
      content: "unset",
    },
    "& .MuiFormControl-root > div": {
      minHeight: "unset",
    },
  },
  tag: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 11,
    textTransform: "uppercase",
    color: "#fff",
  },
  chipRoot: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    fontSize: 10,
    textTransform: "uppercase",
    height: 24,
    "& .MuiChip-deleteIcon": {
      width: "16px !important",
      color: theme.palette.common.white,
    },
  },
}));

const TagsField = ({ onChange, value, setChips }) => {
  const classes = useStyles();
  const handleDeleteChip = (chip, index) => {
    const newValue = value.filter(c => c !== chip);
    setChips(newValue);
  };

  const intl = useIntl();

  const handleAddChip = newChip => {
    setChips(oldChips => [...oldChips, newChip]);
  };

  return (
    <div className={classes.tagWrap}>
      <MuiChipsInput
        classes={{
          chip: classes.chipRoot,
        }}
        value={value}
        addOnBlur
        onAddChip={chip => handleAddChip(chip)}
        onDeleteChip={(chip, index) => handleDeleteChip(chip, index)}
        placeholder={intl.formatMessage({ id:"product.tagging"})}
      />
    </div>
  );
};
TagsField.defaultProps = {
  setChips: null,
  onChange: null,
  value: [],
};

TagsField.propTypes = {
  setChips: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(),
};

export default TagsField;

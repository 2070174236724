import React, { useState, useEffect } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Typography,
  Divider,
  Button,
  Icon,
  IconButton,
} from "@mui/material";
import CustomSelect from "../CustomSelect";

import InfoModal from "./InfoModal";
import EditorContainer from "../EditorContainer";
import TimeEditor from "../EditorContainer/TimeEditor";

const useStyles = (intent) => makeStyles(theme => ({
  root: {
    position: intent === "project" ? "fixed" : "inherit",
    ...(intent !== "project" && { display: "flex" }),
    bottom: 0,
    background: "#fff",
    padding: 15,
    borderTop: "1px solid #c4c4c4",
  },
  dates: {
    fontSize: 14,
    fontWeight: 800,
  },
  time: {
    "& .react-datepicker-wrapper": {
      marginTop: 10,
    },
    "& .react-datepicker__input-container input": {
      width: "100%",
      borderRadius: "4px",
      border: "1px solid #c4c4c4",
      padding: "2px 2px 2px 5px",
    },
  },
  select: {
    "& select.MuiSelect-select": {
      color: "#000!important",
      padding: 100,
    },
  },
  btn: {
    padding: "10px",
    fontSize: 12,
    fontWeight: 600,
    width: "100%",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  },
  formControl: {
    fontSize: 18,
    padding: "9px 12px",
    height: "100%",
    color: theme.palette.text.primary,
    width: "100%",
  },
  iconButton: {
    marginLeft: 5,
    fontSize: "10px",
  },
  icon: {
    fontSize: 14,
  },
  partiallyBookedDays: {
    marginTop: 10,
  },
}));

const PickerFooter = ({
  intent,
  enableTime,
  singleLine,
  pickUpTime,
  returnTime,
  setPickUpTime,
  setReturnTime,
  from,
  to,
  handleClose,
  pickUpTimeSetting,
  returnTimeSetting,
  inRangeTotallyUnvailableDays,
  inRangePartialUnavailableDays,
  unavailableDays,
}) => {
  const classes = useStyles();
  const [timeOptions, setTimeOptions] = useState([]);
  const [infoModal, setInfoModal] = useState(false);
  const [dayIntervals, setDayIntervals] = useState([]);
  const currentDay = moment();

  const [time, setTime] = useState("");
  const [selectedTime, setSelectedTime] = useState("12:00");

  const handleChange = (newValue) => {
    setTime(newValue.value); //changes to event
    //setSelectedTime(newTime);
  };

  const handleDirty = ({ dirty }) => {
    console.log("Dirty:", dirty);
  };

  const iconSize = { fontSize: "12px" };

  const labelBody1 = {
    fontSize: 15,
    fontWeight: 600,
    color: "#000",
  };

  useEffect(() => {
    if (Array.isArray(unavailableDays) && unavailableDays.length > 0) {
      let tempDays = [];

      inRangePartialUnavailableDays.forEach(x => {
        const tempFilter = unavailableDays.filter(y => {
          return moment(y.day).isSame(x, "day");
        });

        tempDays = tempFilter;
      });

      setDayIntervals(tempDays);
    }
  }, [unavailableDays, inRangePartialUnavailableDays]);

  const handleSplitTime = time => {
    return time.split(":");
  };

  const handleFromTime = e => {
    const time = handleSplitTime(e);
    const hour = time[0];
    const minute = time[1];
    const value = currentDay.set({ hour, minute });
    setPickUpTime(value.toDate());
  };
  const handleToTime = e => {
    const time = handleSplitTime(e);
    const hour = time[0];
    const minute = time[1];
    const value = currentDay.set({ hour, minute });
    setReturnTime(value.toDate());
  };

  const handleFormatDate = date => {
    const formattedDate = moment(date).format("ddd, D MMM");
    return formattedDate;
  };
  const handleCreateTimeIntervals = () => {
    // eslint-disable-next-line one-var
    let a, b;
    let minMin = 0;
    let maxMin = 60;
    let minHour = 0;
    let maxHour = 24;
    const tempTimes = [];

    if (pickUpTimeSetting) {
      const time = moment(pickUpTimeSetting).format("HH:mm");
      const tempTime = handleSplitTime(time);
      [a, b] = tempTime;
      minHour = a;
      minMin = b;
    }

    if (returnTimeSetting) {
      const time = moment(returnTimeSetting).format("HH:mm");
      const tempTime = handleSplitTime(time);
      [a, b] = tempTime;
      maxHour = Number(a) + 1;
      if (b === "30") {
        maxMin = 60;
      }

      if (b === "00") {
        maxMin = 30;
      }
    }

    // eslint-disable-next-line no-plusplus
    for (let hour = minHour; hour < maxHour; hour++) {
      for (let minute = minMin; minute < maxMin; minute += 30) {
        const tempValues = {
          label: moment({ hour, minute }).format("HH:mm"),
          value: moment({ hour, minute }).format("HH:mm"),
        };
        tempTimes.push(tempValues);
      }
    }

    setTimeOptions(tempTimes);
  };

  const handleRoundOf = date => {
    const roundOff = moment(date).format("HH:mm");

    return roundOff;
  };

  useEffect(() => {
    handleCreateTimeIntervals();
  }, []);

  const handleCloseInfoModal = () => {
    setInfoModal(false);
  };

  return (
    <Grid container className={classes.root} xs={12} spacing={1} sx={{ ...(intent === "project" && { marginLeft: "10px", marginRight: "10px" }) }}>
      {intent !== "project" && (
        <Grid item xs={12}>
          {inRangeTotallyUnvailableDays.length > 0 && (
            <Grid item={12}>
              <Typography>
                <FormattedMessage id="booking.daysSelectedUnavailable" />
              </Typography>
            </Grid>
          )}
          {dayIntervals.length > 0 && (
            <Grid item={12} className={classes.partiallyBookedDays}>
              <Typography>
                <FormattedMessage id="booking.daysSelectedPartiallyUnavailable" />
              </Typography>
              {dayIntervals.map(x => {
                const { intervals = [], day } = x;
                return (
                  <Typography style={{ marginTop: 5 }}>
                    {`${moment(day).format("YYYY-MM-DD")}`}{" "}
                    {Array.isArray(intervals) && intervals.length > 0 && (
                      <span style={{ marginLeft: 5 }}>
                        {intervals.map(y => {
                          return (
                            <>
                              {`${moment(y.from).format("HH:mm")} to ${moment(
                                y.to
                              ).format("HH:mm")}`}
                            </>
                          );
                        })}
                      </span>
                    )}
                  </Typography>
                );
              })}
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={5}>
        <Typography variant="body1" sx={labelBody1}>
          <FormattedMessage id={intent === "project" ? "project.task.start" : "booking.pickup"} />
          <IconButton
            sx={{ ...(intent === "project" && iconSize) }}
            className={classes.iconButton}
            onClick={() => setInfoModal(true)}
          >
            <Icon
              className={clsx(["nc-icon nc-alert-circle-i", classes.icon])}
            />
          </IconButton>
        </Typography>
        <Typography className={classes.dates}>
          {handleFormatDate(from)}
        </Typography>
        <div className={classes.time}>
          {/*<CustomSelect*/}
          {/*  formControlClass={classes.formControl}*/}
          {/*  customClass={classes.select}*/}
          {/*  native*/}
          {/*  options={timeOptions}*/}
          {/*  value={handleRoundOf(pickUpTime)}*/}
          {/*  onChange={e => handleFromTime(e.target.value)}*/}
          {/*/>*/}
        </div>
      </Grid>
      {intent !== "project" && (
        <Grid item xs={1} align="center">
          <Divider orientation="vertical" flexItem />
        </Grid>
      )}
      <Grid item xs={5}>
        <Typography sx={labelBody1}>
          <FormattedMessage id={intent === "project" ? "project.task.end" : "booking.dropOff"} />
          <IconButton
            sx={{ ...(intent === "project" && iconSize) }}
            className={classes.iconButton}
            onClick={() => setInfoModal(true)}
          >
            <Icon
              className={clsx(["nc-icon nc-alert-circle-i", classes.icon])}
            />
          </IconButton>
        </Typography>
        <Typography className={classes.dates}>
          {handleFormatDate(to)}
        </Typography>
        <div className={classes.time}>
          {/*<CustomSelect*/}
          {/*  formControlClass={classes.formControl}*/}
          {/*  customClass={classes.select}*/}
          {/*  native*/}
          {/*  options={timeOptions}*/}
          {/*  value={handleRoundOf(returnTime)}*/}
          {/*  onChange={e => handleToTime(e.target.value)}*/}
          {/*/>*/}
          <EditorContainer
            //fullWidth
            component={TimeEditor}
            editable={true}
            //value={time}
            //onChange={handleChange}
            value={handleRoundOf(pickUpTime)}
            onChange={e => handleFromTime(e.target.value)}
            onDirty={handleDirty}
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              border: "1px solid rgba(0, 0, 0, 0)",
              borderRadius: "4px",
              fontSize: "14px",
              fontWeight: 400,
              minHeight: "2em",
              padding: "4px",
              "&:hover": {
                backgroundColor: '#f0f0f0',
                borderRadius: "4px",
                cursor: "default",
              },
              "&:focus": {
                border: "1px solid rgba(0, 0, 0, 0.5)",
                cursor: "default",
              },
            }}
          />
        </div>
      </Grid>
      <Grid item xs={intent === "project" ? 10 : 12} align="center">
        <Button
          className={classes.btn}
          marginTop={10}
          onClick={handleClose}
          width={!singleLine && "100%"}
        >
          <FormattedMessage id="booking.btnSelectTheseDates" />
        </Button>
      </Grid>

      {infoModal && (
        <InfoModal
          open={infoModal}
          handleClose={() => handleCloseInfoModal()}
        />
      )}
    </Grid>
  );
};

export default PickerFooter;

import { gql } from '@apollo/client';

const Dependencies = gql`
  fragment Dependencies on Dependencies {
  	from
  	to
  	type
  	id
  }
`;

export default Dependencies;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const DELETE_RESOURCE_LINK = gql`
  mutation DeleteResourceLink($resourceId: ID!, $folderId: Int!) {
    productEditContext {
      deleteResourceLink(resourceId: $resourceId, folderId: $folderId) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_RESOURCE_LINK;

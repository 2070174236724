export const helpIndex = [
  {
    id: "/quick-guide",
    label: "Snabbguide: Kom igång",
    children: [
      {
        id: "/quick-guide/register",
        label: "Registrering",
      },
      {
        id: "/quick-guide/login",
        label: "Logga in",
      },
      {
        id: "/quick-guide/forgot-password",
        label: "Glömt lösenord",
      },
      {
        id: "/quick-guide/invite-users",
        label: "Bjuda in användare",
      },
      {
        id: "/quick-guide/order",
        label: "Beställa",
      },
      {
        id: "/quick-guide/document",
        label: "Dokument",
      },
    ],
  },
  {
    id: "/common",
    label: "Allmänt",
    children: [
      {
        id: "/common/about",
        label: "Det här är UniQueue",
      },
    ],
  },
  {
    id: "/workspace",
    label: "Arbetsyta",
    children: [
      {
        id: "/workspace/what-is-a-workspace",
        label: "Vad är en arbetsyta",
      },
      {
        id: "/workspace/add-remove-edit-a-workspace",
        label: "Lägg till, ta bort och redigera en arbetsyta",
      },
    ],
  },
  {
    id: "/company-users",
    label: "Användare",
    children: [
      {
        id: "/company-users/invite-users",
        label: "Bjuda in användare",
      },
      {
        id: "/company-users/groups",
        label: "Grupper",
      },
      {
        id: "/company-users/groups/addresses",
        label: "Adresshantering",
      },
    ],
  },
  {
    id: "/search",
    label: "Sökfunktionen",
  },
  {
    id: "/system-startpage",
    label: "Systemets startsida",
    children: [
      {
        id: "/system-startpage/configure",
        label: "Konfigurera systemets startsida",
      },
    ],
  },
  {
    id: "/workspace-startpage",
    label: "Arbetsytans startsida",
    children: [
      {
        id: "/workspace-startpage/configure",
        label: "Konfigurera arbetsytans startsida",
      },
    ],
  },
  {
    id: "/dashboard",
    label: "Instrumenpanel",
    children: [
      {
        id: "/dashboard/what-is-a-dashboard",
        label: "Vad är en instrumentpanel",
      },
      {
        id: "/dashboard/edit",
        label: "Anpassa instrumentpanelen",
      },
      {
        id: "/dashboard/templates",
        label: "Instrumentpanelsmallar",
      },
      {
        id: "/dashboard/widgets",
        label: "Vad är en widget",
      },
      {
        id: "/dashboard/all-widgets",
        label: "Beskrivning av alla widgetar",
      },
      {
        id: "/dashboard/add-delete-edit-a-widget",
        label: "Lägg till, ta bort och ändra en widget",
      },
    ],
  },
  {
    id: "/menu",
    label: "Menyn",
    children: [
      {
        id: "/menu/basics",
        label: "Grunderna i menyn",
      },
      {
        id: "/menu/edit",
        label: "Anpassa menyn",
      },
      {
        id: "/menu/add",
        label: "Lägg till en sida från menyn",
      },
      {
        id: "/menu/remove",
        label: "Ta bort en sida från menyn",
      },
    ],
  },
  {
    id: "/account-settings",
    label: "Mina kontoinställningar",
  },
  {
    id: "/document",
    label: "Dokumentsamarbete",
    children: [
      {
        id: "/document/file-view",
        label: "Filvy",
      },
      {
        id: "/document/add-remove-edit-files",
        label: "Lägga till, ta bort och ändra filer",
      },
      {
        id: "/document/add-remove-edit-folders",
        label: "Lägga till, ta bort och ändra mappar",
      },
      {
        id: "/document/move-files-folders",
        label: "Flytta filer och mappar",
      },
      {
        id: "/document/sidebar",
        label: "Dölj och visa sidfältet för meddelanden eller nyheter",
      },
      {
        id: "/document/lock-unlock-files-folders",
        label: "Låsa/låsa upp filer och mappar",
      },
      {
        id: "/document/download-files-folders",
        label: "Ladda ner filer och mappar",
      },
      {
        id: "/document/metadata",
        label: "Metadata",
      },
      {
        id: "/document/copy-files-folder",
        label: "Kopiera filer och mappar",
      },
      {
        id: "/document/files-versions",
        label: "Versioner",
      },
    ],
  },
  {
    id: "/order-system",
    label: "Beställningssystem",
    children: [
      {
        id: "/order-system/product-view",
        label: "Produktvy",
      },
      {
        id: "/order-system/add-remove-edit-categories",
        label: "Lägga till, ta bort och ändra kategorier",
      },
      {
        id: "/order-system/add-remove-edit-products",
        label: "Lägga till, ta bort och ändra produkter",
      },
      {
        id: "/order-system/move-products",
        label: "Flytta produkter",
      },
      {
        id: "/order-system/sidebar",
        label: "Dölj och visa sidfältet för meddelanden eller nyheter",
      },
    ],
  },
  {
    id: "/image-bank",
    label: "Bildbank",
    children: [
      {
        id: "/image-bank/image-shopping-cart",
        label: "Bildvarukorgen",
      },
      {
        id: "/image-bank/images-view",
        label: "Vyn Bilder",
      },
      {
        id: "/image-bank/image-gallery-view",
        label: "Vyn Bildgalleri",
      },
    ],
  },
  {
    id: "/calculation-system",
    label: "Kalkylsystem",
    children: [
      {
        id: "/calculation-system/about",
        label: "Beskrivning av UniQueue kalkylsystem",
      },
      {
        id: "/calculation-system/uppdrag",
        label: "Uppdrag",
      },
      {
        id: "/calculation-system/article-list",
        label: "Artikellista",
      },
      {
        id: "/calculation-system/properties",
        label: "Egenskaper",
      },
      {
        id: "/calculation-system/tasks",
        label:
          "Uppgifter eller kalla det operation? (kallades tidigare moment)",
      },
    ],
  },
  {
    id: "/shopping-cart",
    label: "Varukorgen",
    children: [
      {
        id: "/shopping-cart/edit-addresses",
        label: "Ändra varukorgens adresser",
      },
      {
        id: "/shopping-cart/edit-fields",
        label: "Ändra fält i varukorgen",
      },
    ],
  },
  {
    id: "/pickinglist",
    label: "Plocklista",
  },
  {
    id: "/booking-system",
    label: "Bokningssystem",
    children: [
      {
        id: "/booking-system/use",
        label: "Använda",
      },
      {
        id: "booking-system/administer",
        label: "Administrera",
      },
    ],
  },
];

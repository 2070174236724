import { gql } from '@apollo/client';
import ProjectFields from "./ProjectFields";
import ProjectViews from "./ProjectViews";

const Project = gql`
  fragment Project on ProjectDTO {
    __typename

    projectId
    name
    description

    projectFields { ...ProjectFields }
    projectViews { ...ProjectViews }
  }
  ${ProjectFields}
  ${ProjectViews}
`;

export default Project;

import React from "react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, FormHelperText } from "@mui/material";

const useStyles = makeStyles(() => ({
  formControl: {
    fontSize: 12,
    padding: "9px 12px",
    height: "100%",
    color: "#66615b",
    width: "100%",
    display: "flex",
    alignItems: "center",
    "&.Mui-disabled": {
      opacity: 0.3,
    },
  },
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  menuItem: {
    fontSize: 12,
  },
  formHelperText: {
    display: "inline-block",
    marginTop: -1,
    fontSize: 10,
  },
  inputLabelText: {
    fontSize: 12    
  },
}));

const CustomSelect = ({
  onChange,
  value,
  options,
  disabled,
  native,
  inputLabel,
  name,
  helperText,
  error,
  customClass,
  multiple,
  renderValue,
  open,
  onOpen,
  shrink,
  size,
  menuItemClass,
  formControlClass,
  id,
  defaultOption,
  onClose,
  required,
  fullWidth
}) => {
  const classes = useStyles();  

  return (
    <FormControl
      required={required}
      className={customClass}
      variant="outlined"
      size={size}
      fullWidth={fullWidth}
    >
      <InputLabel shrink={shrink} size={size} className={classes.inputLabelText}>
        {inputLabel}
      </InputLabel>
      <Select
        id={id}
        open={open}
        onOpen={onOpen}
        error={error}
        classes={{ select: clsx([classes.formControl, formControlClass]) }}
        onChange={onChange}
        value={value === "" ? 0 : value}
        disabled={disabled}
        name={name}
        fullWidth={fullWidth}
        renderValue={renderValue}
        multiple={multiple}
        variant="outlined"
        label={inputLabel}
        onClose={onClose}
      >
        {defaultOption && <MenuItem value="0">{defaultOption}</MenuItem>}
        {options.map((x, index) => (
          <MenuItem
            className={menuItemClass || classes.menuItem}
            value={x.value}
            disabled={x.disabled}
            // eslint-disable-next-line react/no-array-index-key
            key={`${x.value}-${index}`}
          >
            {x.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText className={classes.formHelperText} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

CustomSelect.defaultProps = {
  disabled: false,
  native: false,
  name: "",
  value: "",
  helperText: null,
  error: false,
  multiple: false,
  inputLabel: null,
  customClass: "",
  renderValue: null,
  size: "normal",
  defaultOption: null,
  fullWidth: true
};

CustomSelect.propTypes = {
  multiple: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  native: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  inputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  customClass: PropTypes.string,
  renderValue: PropTypes.node,
  defaultOption: PropTypes.node,
};

export default CustomSelect;

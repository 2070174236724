import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
    "&.MuiGrid-item": {
      padding: 20,
    },
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
  },
  body: {
    padding: 20,
  },
  card: {
    //padding: 15,
    //borderRadius: 4,
    //border: "1px solid #F2F2F2",
    //marginBottom: 15,
    padding: "0 15px",
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    marginTop: "1em",
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  textItalic: {
    fontSize: 12,
    fontStyle: "italic",
    marginBottom: 10,
  },
}));

const lastUpdatedDate = new Date(2020, 9, 10);

const PrivacyPolicySection = ({ lang }) => {
  const classes = useStyles();

  const Card = ({ children, hidden = false }) => {
    return !hidden && <Box className={classes.card}>{children}</Box>;
  };

  const Heading = ({ children, hidden = false, ...rest }) => {
    return (
      !hidden && (
        <Typography className={classes.heading} {...rest}>
          {children}
        </Typography>
      )
    );
  };

  const Label = ({ children, hidden = false }) => {
    return (
      !hidden && <Typography className={classes.label}>{children}</Typography>
    );
  };

  const Section = ({ children, hidden = false }) => {
    return !hidden && <>{children}</>;
  };

  const Text = ({ children, hidden = false }) => {
    return (
      !hidden && (
        <Typography className={classes.text} variant="body1">
          {children}
        </Typography>
      )
    );
  };

  const LastUpdated = () => {
    const lastUpdated = moment(lastUpdatedDate)
      .locale(lang)
      .format("LL");
    return (
      <>
        <FormattedMessage id="common.lastUpdated" />: {lastUpdated}
      </>
    );
  };

  const ContentInSwedish = () => {
    return (
      <>
        <Typography className={classes.headTitle} variant="h1">
          När du använder våra tjänster anförtror du oss dina uppgifter. Vi
          förstår att det är ett stort ansvar och arbetar hårt för att skydda
          dina uppgifter och ge dig kontrollen över dem.
        </Typography>
        <Section>
          <Text>
            I den här integritetspolicyn förklarar vi vilken data vi samlar in,
            varför vi samlar in den och hur du uppdaterar, hanterar, exporterar
            och raderar dina uppgifter.
          </Text>
          <Text>
            Om EU:s eller Förenade kungarikets dataskyddslagstiftning gäller för
            behandlingen av dina uppgifter kan du läsa avsnittet Europeiska krav
            nedan för att få mer information om dina rättigheter och UniQueues
            efterlevnad av dessa lagar.
          </Text>
        </Section>
      </>
    );
  };

  const ContentInEnglish = () => {
    return (
      <>
        <Typography className={classes.headTitle} variant="h1">
          When you use our services, you entrust us with your data. We
          understand that it is a big responsibility and work hard to protect
          your data and give you control over it.
        </Typography>
        <Section>
          <Text>
            In this privacy policy we explain what data we collect, why we
            collect it and how to update, manage, export and delete your data.
          </Text>
          <Text>
            If EU or UK data protection legislation applies to the processing of
            your data, please read the European requirements section below for
            more information about your rights and UniQueue's compliance with
            these laws.
          </Text>
        </Section>
      </>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} className={classes.root}>
        {lang === "SV" && <ContentInSwedish />}
        {lang !== "SV" && <ContentInEnglish />}
        <Typography
          className={classes.textItalic}
          style={{ marginBottom: 0, textAlign: "right" }}
        >
          <LastUpdated />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicySection;

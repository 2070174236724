import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ClearButton, PrimaryButton } from "../../../../common/Buttons";
import WarningImage from "../../../../../assets/img/warning-icon.svg";
import DraggableModal from "../../../../common/DraggableModal";
import DraggableModalHead from "../../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  img: {
    marginBottom: 10,
    width: 40,
  },
  bodyContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
}));

const ConfirmDeleteModal = ({
  companyNetworks,
  handleDeleteNetwork,
  handleRemoveCompany,
  open,
  selectedNode,
  setOpen,
}) => {
  const classes = useStyles();

  const { name, networkId, companyId } = selectedNode || {};

  let message = "";
  let onClick = null;

  if (!companyId) {
    message = (
      <FormattedMessage
        id="companyNetworks.removeNetwork"
        values={{ network: name }}
      />
    );
    onClick = handleDeleteNetwork;
  } else {
    const networkName = companyNetworks[networkId].name;
    message = (
      <FormattedMessage
        id="companyNetworks.removeCompany"
        values={{ company: name, network: networkName }}
      />
    );
    onClick = handleRemoveCompany;
  }

  return (
    <DraggableModal
      open={open}
      handleClose={() => setOpen(false)}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={() => setOpen(false)} />
      <DraggableModalBody>
        <Box className={classes.bodyContainer}>
          <img src={WarningImage} alt="Warning" className={classes.img} />
          {message}
        </Box>
        <Box className={classes.modalFooter} align="right">
          <ClearButton onClick={() => setOpen(false)} marginRight={15}>
            <FormattedMessage id="btn.no" />
          </ClearButton>
          <PrimaryButton
            onClick={() => {
              onClick(networkId, companyId);
            }}
          >
            <FormattedMessage id="btn.yes" />
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmDeleteModal.defaultProps = {
  companyNetworks: null,
  selectedNode: {},
};

ConfirmDeleteModal.propTypes = {
  companyNetworks: PropTypes.shape({}),
  handleDeleteNetwork: PropTypes.func.isRequired,
  handleRemoveCompany: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedNode: PropTypes.shape({}),
  setOpen: PropTypes.func.isRequired,
};

export default ConfirmDeleteModal;

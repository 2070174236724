import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  iconBtn: {},
  icon: {},
}));

const CustomIconButton = ({ color, size, fontSize, faName, ...rest }) => {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.iconBtn}
      style={{ color }}
      size={size}
      {...rest}
    >
      <Icon className={clsx([faName, classes.icon])} style={{ fontSize }} />
    </IconButton>
  );
};
CustomIconButton.defaultProps = {
  color: "#999",
  fontSize: 14,
  size: "medium",
};

CustomIconButton.propTypes = {
  fontSize: PropTypes.number,
  size: PropTypes.string,
  color: PropTypes.string,
  faName: PropTypes.string.isRequired,
};

export default CustomIconButton;

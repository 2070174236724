import React, { useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import {
  Chip,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Checkbox,
  Button,
} from "@mui/material";
import MuiTable from "@mui/material/Table";
import { makeStyles } from "@mui/styles";
import { DATA_TYPE as columnType } from "../../../constant/salesPageTypes";
import { useEffect } from "react";
import { INIT_SALES_DOCUMENTS_PRINT } from "../../../graphql/queries";
import { FormattedCurrency } from "../../common";

const useStyles = makeStyles({
  root: {
  },
  table: {
    minWidth: 650,
  },
});

const alternateTbl = {
  '&:nth-of-type(odd)': {
    backgroundColor: "white",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "#fbfbfb",
  },
};

const cellPad = {
  padding: "8px",
};

export const DocumentsTable = ({
  dataRows,
  headers,
  haveError,
  isLoading,
  messages,
  onClickRow,
  onSelectRow
}) => {
  const classes = useStyles();

  const defaultMessages = {
    emptyMessage: "No data",
    errorMessage: "Error",
    loadingMessage: "Loading...",
  };

  const { emptyMessage, errorMessage, loadingMessage } = { ...defaultMessages, ...messages };

  const formatDataCells = (cell, column) => {
    if (!column) {
      return;
    }
    switch (column.type) {
      case columnType.currency:
        return !cell ? "" : <FormattedCurrency value={cell} />;
      case columnType.datetime:
        return !cell ? "" : <FormattedDate value={cell} />;
      case columnType.tags:
        return (Array.isArray(cell) && cell.length > 0) && cell.map(n => <Chip label={cell} sx={{ cursor: "pointer" }} />);
    }
    return cell;
  };

  const [initPrint, { data: urlData, loading: loadingUrl, error: errorPrint }] = useLazyQuery(INIT_SALES_DOCUMENTS_PRINT);
  const handlePrint = () => {
    let documentIds = dataRows.filter(x => x.checked).map(n => n.salesDocumentId);

    initPrint({
      variables: {
        salesDocumentIds: documentIds
      }
    })

  }

  useEffect(() => {
    if (urlData?.companyAdminContext?.salesDocuments) {
      window.open(urlData.companyAdminContext.salesDocuments.salesDoucmentPrint, "_blank");
    }

  }, [urlData])

  const haveDataRows = Array.isArray(dataRows) && dataRows.length > 0;

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setSelectedRows([]);
  }, [dataRows])

  return (
    <MuiTable className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell sx={cellPad} width={42}></TableCell>
          {headers.map(c => <TableCell align={c.align ?? "left"} sx={cellPad} key={JSON.stringify(c)}>{c.label}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {!isLoading && haveDataRows &&
          dataRows.map((row, index) => (
            <TableRow
              key={row.salesDocumentId}
              onClick={() => onClickRow(row)}
              sx={{ cursor: "pointer", ...alternateTbl, }}
            >
              <TableCell sx={cellPad} width={42}><Checkbox checked={!!row.checked} onClick={(e) => { e.stopPropagation(); onSelectRow(row, index, e.target.checked); }} /></TableCell>
              {headers.map((c, colIndex) => {
                var cellValue = row[c.field];
                return (
                  <TableCell sx={cellPad} align={headers[colIndex].align ?? "left" }>
                    {formatDataCells(cellValue, c)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
      </TableBody>

      <TableFooter>
        <TableRow>

          {(isLoading || (!isLoading && !haveDataRows) || haveError) &&
            <TableCell sx={cellPad} colSpan={headers.length + 1}>
              {haveError && <Typography>{errorMessage}</Typography>}
              {isLoading && !haveError && <Typography>{loadingMessage}</Typography>}
              {!isLoading && !haveError && !haveDataRows && <Typography>{emptyMessage}</Typography>}
            </TableCell>
          }

          {dataRows && dataRows.length > 0 && (
            <TableCell sx={{ borderBottom: "none", ...cellPad, }} colSpan={headers.length + 1}>
              <Button variant="contained" disabled={!dataRows.some(n => n.checked)}
                onClick={() => handlePrint()} > <FormattedMessage id="btn.print" /></Button></TableCell>
          )}
        </TableRow>
      </TableFooter>

    </MuiTable >
  );
};

export default DocumentsTable;

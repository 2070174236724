import React from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";

const useStyles = makeStyles(theme => ({
  newsWrap: {
    backgroundColor: theme.palette.common.white,
    marginTop: 5,
    color: theme.palette.primary.main,
  },
  newsHeading: {
    padding: "15px",
    margin: 0,
    fontSize: 16,
    fontWeight: 400,
    borderBottom: "1px solid #D9D9D9",
  },
  newsBody: {
    padding: "15px",
  },
  newsItem: {
    marginBottom: 15,
    "&:last-of-type": {
      marginBottom: 5,
    },
  },
  newsTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 5,
  },
  newsExcerpt: {
    fontSize: 13,
    marginTop: 0,
    marginBottom: 0,
    lineHeight: 1.4,
    color: theme.palette.text.primary,
  },
  newsLink: {
    color: theme.palette.common.link,
    textDecoration: "none",
    marginLeft: 5,
    "&:hover": {
      color: alpha(theme.palette.common.link, 0.8),
      textDecoration: "none",
    },
  },
}));

export default function NewsBox() {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.newsWrap} elevation={1}>
        <div className={classes.newsHeading}>Nyheter</div>
        <div className={classes.newsBody}>
          <div className={classes.newsItem}>
            <h3 className={classes.newsTitle}>Nyhet 2</h3>
            <p className={classes.newsExcerpt}>
              Beskrivning av nyheten kommer här. Det kan vara en fantastisk
              nyhet.
              <Link href="/" className={classes.newsLink}>
                Läs mer här »
              </Link>
            </p>
          </div>
          <div className={classes.newsItem}>
            <h3 className={classes.newsTitle}>Nyhet 1</h3>
            <p className={classes.newsExcerpt}>
              Beskrivning av nyheten kommer här. Det kan vara en fantastisk
              nyhet.
              <Link href="/" className={classes.newsLink}>
                Läs mer här »
              </Link>
            </p>
          </div>
          <div className={classes.newsItem}>
            <h3 className={classes.newsTitle}>Nyhet 3</h3>
            <p className={classes.newsExcerpt}>
              Beskrivning av nyheten kommer här. Det kan vara en fantastisk
              nyhet.
              <Link href="/" className={classes.newsLink}>
                Läs mer här »
              </Link>
            </p>
          </div>
        </div>
      </Paper>
    </div>
  );
}

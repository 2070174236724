import { gql } from '@apollo/client';

const getPublicCompany = gql`
  query GetPublicCompany($publicCompanyId: String, $username: String) {
    siteInvitationContext {
      getPublicCompany(publicCompanyId: $publicCompanyId, username: $username) {
        item {
          companyName
          publicCompanyId
        }
        notFound
        displayWarning
        inactivated
        attemptsMade
        remainingAttempts
      }
    }
  }
`;

export default getPublicCompany;

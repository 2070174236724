import React, { memo } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import GroupName from "./GroupName";

const useStyles = makeStyles(() => ({
  folderIcon: {
    fontSize: 14,
    color: "#5b5654",
    width: 18,
  },
  folderIconNoChild: { margin: "2px 3px 2px 27px !important" },
  folderIconWithChild: { margin: "2px 3px 2px 3px" },
  checkbox: { padding: "3px !important", marginLeft: "auto" },
  folderTitleSpan: {
    flexDirection: "row",
    alignItems: "flex-start",
    display: "flex",
    cursor: "pointer",
    flex: 1,
    position: "relative",
  },
  editInput: {
    backgroundColor: "#fff",
    fontSize: 11,
    height: 5,
  },
  dimIcon: {
    opacity: "0.5",
  },
  lockIconWithChild: {
    position: "absolute",
    fontSize: 8,
    top: 5,
    left: 6,
    color: "#CD132D",
  },
  lockIconNoChild: {
    position: "absolute",
    fontSize: 8,
    top: 5,
    left: 30,
    opacity: "0.8",
    color: "#CD132D",
  },
}));

const GroupLabel = ({
  groupName,
  handleFolderDragOver,
  handleOFolderDragLeave,
  handleOnGroupRowClick,  
}) => {
  const classes = useStyles();

  return (
    <span
      role="presentation"
      className={clsx([classes.folderTitleSpan])}
      onClick={handleOnGroupRowClick}      
      onDragOver={handleFolderDragOver}
      onDragLeave={handleOFolderDragLeave}
    >
      <Icon
        className={clsx([
          "fas fa-users",
          classes.folderIcon,
          classes.folderIconWithChild,
        ])}
      />
        <GroupName name={groupName} />

    </span>
  );
};

GroupLabel.defaultProps = {
  isEditMode: false,
  groupName: "",
  selectedNode: null,
};

GroupLabel.propTypes = {
  selectedNode: PropTypes.shape({
    groupId: PropTypes.number.isRequired,
  }),
  group: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        groupId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    groupId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
    locked: PropTypes.bool,
  }).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  isEditMode: PropTypes.bool,
  groupId: PropTypes.number.isRequired,
  groupName: PropTypes.string,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleFolderDragOver: PropTypes.func.isRequired,
  handleNewFolderName: PropTypes.func.isRequired,
  handleOFolderDragLeave: PropTypes.func.isRequired,
  handleOnGroupRowClick: PropTypes.func.isRequired,
  handleOnKeyDown: PropTypes.func.isRequired,
  setGroupName: PropTypes.func.isRequired,
};

export default memo(GroupLabel);

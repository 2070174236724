import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const GET_COMPANY_ROLE = gql`
  query GetCompanyRoleRights($companyId: Int!, $companyRoleId: Int!) {
    companyAdminContext {
      companyRoles {
        getCompanyRole(companyId: $companyId, companyRoleId: $companyRoleId) {
          errorResult {
            ...ErrorResultFields
          }
          success
          companyRole {
            companyRoleRights {
              canEditOwnAccountSettings
              canManageCompany
              canManageUsers
              canManageWorkspaces
              canManageRoles
              canManageDashboard
              requestForProposal
              userAccountAddressUpdateRequestEmail
              userAccountAddressUpdateSetting
              subscriptionLevels {
                canEditOwnAccountSettings
                canManageCompany
                canManageUsers
                canManageWorkspaces
                canManageRoles
                requestForProposal
                canManageDashboard
              }
            }
            subscriptionLevel
          }
        }
      }
    }
  }

  ${ErrorResultFragment}
`;

export default GET_COMPANY_ROLE;

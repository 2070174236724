import { handleActions } from "redux-actions";
import {
  clearFileviewCopyParams,
  setFileviewCopyParams,
  setFileViewOpenNodes,
  setRefetchFiles,
  setSelectedResources,
} from "../../../../actions";

const defaultState = {
  copyParams: {
    ids: null,
    type: "",
  },
  lockedFolder: false,
  openedNodes: [],
  refetchFiles: false,
  selectedResources: [],
};

export default handleActions(
  {
    [clearFileviewCopyParams]: (state) => {
      const { copyParams } = defaultState;
      return {
        ...state,
        copyParams,
      };
    },
    [setRefetchFiles]: (state, { payload }) => {
      const { refetch } = payload;
      return {
        ...state,
        refetchFiles: refetch,
      };
    },
    [setFileviewCopyParams]: (state, { payload }) => {
      const { copyParams } = payload;
      return {
        ...state,
        copyParams,
      };
    },
    [setFileViewOpenNodes]: (state, { payload }) => {
      const { nodes } = payload;
      return {
        ...state,
        openedNodes: nodes,
      };
    },
    [setSelectedResources]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        selectedResources: items,
      };
    },
  },
  defaultState
);

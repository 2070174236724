import { gql } from '@apollo/client';

const SIMPLE_SEARCH = gql`
  query SimpleSearch($searchText: String, $siteId: Int!) {
    search {
      simpleSearch(searchText: $searchText, siteId: $siteId) {
        sites {
          folders {
            folderId
            folderName
          }
          siteId
          siteName
        }
        sortedProductIds {
          sortedByArticleNo
          sortedByName
          sortedByRank
        }
        sortedResourceIds {
          sortedByArticleNo
          sortedByName
          sortedByRank
        }
        tags {
          tagId
          tagName
        }
        viewSelectorSettings {
          fileView
          productView
        }
      }
    }
  }
`;

export default SIMPLE_SEARCH;

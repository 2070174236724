import { gql } from '@apollo/client';

const VERIFY_SECRET = gql`
  query getAuthenticatorSecret($totpCode:String!, $secret:String!) {
    userAccountContext {
      verifySecret(totpCode:$totpCode, secret:$secret)
  }
}`;

export default VERIFY_SECRET;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomSelect from "../../../../../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  customForm: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputAdornment-root button": {
      padding: 5,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiSelect-select": {
      fontSize: 10,
      paddingTop: 5,
      paddingBottom: 5,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 10,
    },
  },
  label: {
    marginTop: 0,
    marginBottom: 3,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  cbPadding: {
    paddingLeft: 5,
    paddingTop: 10,
  },
}));

const PricePanelOrder = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={1} className={classes.customForm}>
        <Grid item xs={4} md={4}>
          <Typography className={classes.label}>Uppdrag</Typography>
          <CustomSelect
            options={[
              { label: "My Pricing Assignment (My Pricing Model)", value: 1 },
            ]}
          />
        </Grid>
        <Grid item xs={4} md={4}>
          <Typography className={classes.label}>Prislista</Typography>
          <CustomSelect options={[{ label: "My Price List", value: 1 }]} />
        </Grid>
      </Grid>
      <Grid container className={classes.customForm}>
        <Grid item xs={12} className={classes.cbPadding}>
          <FormControlLabel
            control={<Switch size="small" color="primary" />}
            label="Visa pris vid beställning"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PricePanelOrder;

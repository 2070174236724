import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { useLazyQuery } from "@apollo/client";
import { Dialog } from "@mui/material";

import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import { GET_USER_CONTACT_DETAILS } from "../../../graphql/queries";
import AccountSettingsViewSelector from "./AccountSettingsViewSelector";


const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  body: {
    padding: 0,
    minHeight: "75vh",
  },
}));

const AccountSettingsModal = ({ open, handleClose, initialValues }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [accountSettings, setAccountSettings] = useState(initialValues);

  const { firstname, lastname } = accountSettings;

  const contactInfoText = intl.formatMessage({
    id: "account.contactInformation",
  });

  const headerText = () => {
    let title = "";

    if (firstname) {
      title = `${firstname}`;
      if (lastname) {
        title = `${firstname} ${lastname}`;
      }
      title = `${title} ${contactInfoText}`;
    } else if (lastname) {
      title = `${lastname} ${contactInfoText}`;
    } else {
      title = `${contactInfoText}`;
    }
    return title;
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
      fullScreen
    >
      <DraggableModalHead handleClose={handleClose} title={headerText()} />
      <DraggableModalBody customClass={classes.body}>
        <AccountSettingsViewSelector
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}
        />
      </DraggableModalBody>
    </Dialog>
  );
};

AccountSettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,  
  initialValues: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
};

export default AccountSettingsModal;

import React from "react";
import PropTypes from "prop-types";

const FormattedStorageSpace = ({ storageSpace, unit }) => {
  return (
    <>
      {storageSpace} {unit}
    </>
  );
};
FormattedStorageSpace.propTypes = {
  storageSpace: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
};

export default FormattedStorageSpace;

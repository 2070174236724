import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

const Label = ({ title }) => {
  const classes = useStyles();

  return <Typography className={classes.label}>{title}</Typography>;
};

Label.defaultProps = {};

Label.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Label;

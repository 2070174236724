import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import Check from "../../../assets/img/check-success.svg";
import Times from "../../../assets/img/times-danger.svg";

const useStyles = makeStyles(theme => ({
  root: {
    "& tbody tr:nth-child(even)": {
      backgroundColor: "#F4F4F4",
      border: "1px solid #E8E8E8",
    },
  },
  th: {
    fontSize: 14,
    fontWeight: 600,
    borderBottom: "none",
  },
  td: {
    borderBottom: "none",
    paddingTop: 5,
    paddingBottom: 5,
  },
  tdIcon: {
    textAlign: "center",
    borderBottom: "none",
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  subText: {
    fontSize: 12,
    paddingLeft: 10,
    marginTop: 3,
  },
  tableResponsive: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
    },
  },
  btnChoose: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    textTransform: "none",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  textCurrent: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    fontStyle: "italic",
  },
}));

const PricingTable = () => {
  const classes = useStyles();

  return (
    <Box className={classes.tableResponsive}>
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th} />
            <TableCell className={classes.th} align="center">
              Web2market Standard
            </TableCell>
            <TableCell className={classes.th} align="center">
              Web2market Plus +
            </TableCell>
            <TableCell className={classes.th} align="center">
              Web2market Project
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>Collaboration</Typography>
              <Typography className={classes.subText}>
                - Manage documents
              </Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>Photo editor</Typography>
              <Typography className={classes.subText}>
                - Photo editing online
              </Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>Mediabank</Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>
                OnlineDesigner Standard
              </Typography>
              <Typography className={classes.subText}>
                - Edit documents online
              </Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>
                OnlineDesigner Advanced
              </Typography>
              <Typography className={classes.subText}>
                - Edit documents online as well as variable data management
              </Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Times} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>Brand Manual</Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Times} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>Campaign</Typography>
              <Typography className={classes.subText}>
                - Send Email / SMS
              </Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Times} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Typography className={classes.text}>Planner</Typography>
              <Typography className={classes.subText}>
                - Planning tool
              </Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Times} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Times} alt="" />
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <img src={Check} alt="" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td} />
            <TableCell className={classes.tdIcon}>
              <Typography className={classes.textCurrent}>
                Current package
              </Typography>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <Button className={classes.btnChoose}>Choose package</Button>
            </TableCell>
            <TableCell className={classes.tdIcon}>
              <Button className={classes.btnChoose}>Choose package</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default PricingTable;

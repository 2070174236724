import React from "react";
import {
  DashboardContainer,
  InformationSection,
  SiteHeaderTitle,
  SiteHeaderText,
} from "../../components/dashboard";

const Siv = workspacename => {
  return (
    <DashboardContainer>
      <InformationSection>
        <SiteHeaderTitle title={"Välkommen till " + workspacename.workspace} />
        <SiteHeaderText
          text="Siv är Kommunals nya webshop för beställning av fasadskyltar, 
				fanor och flaggor. Materialet visas som PDF exempel.  Då produktionen av 
				fasadskyltar är komplicerad arbetar vi tätt tillsammans med tillverkaren. De har 
				kontor över hela landet."
        />
        <SiteHeaderText text="Har du frågor om systemet, maila i första hand till wilma@kommunal.se" />
        <SiteHeaderText text="Har du frågor om din order maila i första hand till anna@tellusflagor.se" />
      </InformationSection>
    </DashboardContainer>
  );
};

export default Siv;

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import {
  CookiesSection,
  PrivacyPolicySection,
  TermsOfUseSection,
} from "../../components/TermsOfUse";
import { TERMS_OF_USE_SECTION_ID } from "../../constant/termsOfUse";
import {
  createUrl as createTermsOfUseURL,
  getTabId,
} from "../../helpers/termsOfUse";

const useStyles = makeStyles(theme => ({
  root: {},
  wrap: {},
  indicator: {
    backgroundColor: theme.palette.secondary.dark,
  },
  tabRoot: {},
}));

function a11yIds(index) {
  return {
    tabId: `terms-of-use-tab-${index}`,
    panelId: `terms-of-use-tabpanel-${index}`,
  };
}

function a11yProps(index) {
  const { tabId, panelId } = a11yIds(index);
  return {
    id: tabId,
    "aria-controls": panelId,
  };
}

function TabPanel(props) {
  const { children, hidden, index, ...other } = props;
  const { tabId, panelId } = a11yIds(index);
  return (
    <div
      aria-labelledby={tabId}
      hidden={hidden}
      id={panelId}
      role="tabpanel"
      {...other}
    >
      {!hidden && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useSearchParams = () => {
  return new URLSearchParams(useLocation().search);
};

export default function TermsOfUse() {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = useSearchParams();

  const lang = useSelector(state => state.ui.locale.lang);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const sections = [
    {
      tabId: TERMS_OF_USE_SECTION_ID.PrivacyPolicy,
      label: <FormattedMessage id="termsOfUse.sectionTitle.privacyPolicy" />,
      panel: <PrivacyPolicySection lang={lang} />,
    },
    {
      tabId: TERMS_OF_USE_SECTION_ID.TermsOfUse,
      label: <FormattedMessage id="termsOfUse.sectionTitle.termsOfUse" />,
      panel: <TermsOfUseSection lang={lang} />,
    },
    {
      tabId: TERMS_OF_USE_SECTION_ID.Cookies,
      label: <FormattedMessage id="termsOfUse.sectionTitle.cookies" />,
      panel: <CookiesSection lang={lang} />,
    },
  ];

  const changeTab = tabIndex => {
    const tabId = sections[tabIndex]?.tabId;
    const url = createTermsOfUseURL(tabId);
    history.replace(url);
  };

  const handleTabChange = (keep_this, tabIndex) => {
    changeTab(tabIndex);
  };

  useEffect(() => {
    const tabId = getTabId(searchParams);
    if (!!tabId) {
      const tabIndex = sections.findIndex(x => x.tabId === tabId);
      if (tabIndex === -1) {
        changeTab(0);
      } else {
        setActiveTabIndex(tabIndex);
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        elevation={0}
        className={classes.wrap}
        color="secondary"
      >
        <Tabs
          aria-label="terms of use page sections"
          classes={{
            indicator: classes.indicator,
            root: classes.tabRoot,
          }}
          onChange={handleTabChange}
          value={activeTabIndex}
        >
          {sections.map((section, index) => {
            return (
              <Tab
                key={`tab-${index}`}
                label={section.label}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {sections.map((section, index) => {
        const hidePanel = index !== activeTabIndex;
        return (
          <TabPanel key={`tabpanel-${index}`} index={index} hidden={hidePanel}>
            {section.panel}
          </TabPanel>
        );
      })}
    </div>
  );
}

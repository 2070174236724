import { gql } from '@apollo/client';
import SiteChannelMessageEdgeIObservableFragment from "./SiteChannelMessageEdgeIObservable";
import SiteChannelMessageFragment from "./SiteChannelMessage";
import PageInfo from "./PageInfo";

const SiteChannelMessageConnectionFragment = gql`
  fragment SiteChannelMessageConnectionFragment on SiteChannelMessageConnection {
    edges {
      ...SiteChannelMessageEdgeIObservableFragment
    }
    items {
      ...SiteChannelMessageFragment
    }
    pageInfo {
      ...PageInfoProperties
    }
    totalCount
  }
  ${SiteChannelMessageEdgeIObservableFragment}
  ${SiteChannelMessageFragment}
  ${PageInfo}
`;

export default SiteChannelMessageConnectionFragment;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { contextMenu } from "react-contexify";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Divider, Box, Button, Grid, Icon, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import WorkspaceNewModal from "../../components/WorkspaceEdit/WorkspaceNewModal";
import SharedWorkspaceItem from "../../components/WorkspaceEdit/SharedWorkspaceItem";
import FolderContextMenu from "../../components/WorkspaceEdit/FolderContextMenu";
import ItemContextMenu from "../../components/WorkspaceEdit/ItemContextMenu";
import Header from "./Header";
import MyWorkspaceHeader from "./MyWorkspaceHeader";
import StructureTree from "./Structure";
import { STRUCTURE_TYPES } from "../../constant/types";
import CreateNameModal from "../../components/common/CreateNameModal";
import ConfirmDeleteModal from "../../components/common/ConfirmDeleteModal";
import { useMutation } from "@apollo/client";
import { SAVE_STRUCTURE } from "../../graphql/mutations";
import { store } from "../../store";
import { currentViewer } from "../../actions";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    marginTop: 20,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  paperBody: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  panel: {
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0,
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },
    "&:before": {
      content: "none",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "unset",
      flexDirection: "row-reverse",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      alignItems: "center",
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 0,
      marginRight: 15,
    },
    "& .MuiAccordionDetails-root": {
      padding: "10px 0",
    },
  },
  paperFooter: {
    padding: theme.spacing(2),
    borderTop: "1px solid #ddd",
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnSaveIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  menuProvider: {
    width: "auto",
    display: "inline-block",
    minWidth: "50%",
  },
}));

const WorkspaceEdit = () => {
  const alert = useAlert();
  const classes = useStyles();
  const intl = useIntl();

  const [saveStructure, { loading: savingStructure }] = useMutation(
    SAVE_STRUCTURE
  );

  const folderContextMenuId = "organize_workspaces__folder_context_menu";
  const itemContextMenuId = "organize_workspaces__item_context_menu";
  const [selectedNode, setSelectedNode] = useState({});

  const getRandomId = () => Math.floor(10000 + 100000 * Math.random());

  const [renameFolderModalDisabled, setRenameFolderModalDisabled] = useState(
    false
  );
  const [renameFolderModalOpen, setRenameFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [oldFolderName, setOldFolderName] = useState("");

  useEffect(() => {
    setRenameFolderModalDisabled(!newFolderName);
  }, [newFolderName]);

  const [rootFolderModalDisabled, setRootFolderModalDisabled] = useState(false);
  const [rootFolderModalOpen, setRootFolderModalOpen] = useState(false);
  const [rootFolderName, setRootFolderName] = useState("");

  useEffect(() => {
    setRootFolderModalDisabled(!rootFolderName);
  }, [rootFolderName]);

  const [subFolderModalDisabled, setSubFolderModalDisabled] = useState(false);
  const [subFolderModalOpen, setSubFolderModalOpen] = useState(false);
  const [subFolderName, setSubFolderName] = useState("");

  useEffect(() => {
    setSubFolderModalDisabled(!subFolderName);
  }, [subFolderName]);

  const [
    confirmDeleteFolderModalOpen,
    setConfirmDeleteFolderModalOpen,
  ] = useState(false);

  const structure = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );

  const [structureTree, setStructureTree] = useState([]);

  useEffect(() => {
    var temp = JSON.stringify(structure);
    var copy = JSON.parse(temp);
    addRelations(copy);
    setStructureTree(copy);
  }, [structure]);

  const addRelations = (structure, parentNode = null) => {
    for (var idx in structure) {
      var item = structure[idx];
      item.parentNode = parentNode;
      addRelations(item.items, item);
    }
  };

  const createFolderNode = (name, parentNode) => {
    var id = getRandomId();
    return {
      id: id,
      items: null,
      name: name,
      parentNode: parentNode,
      type: STRUCTURE_TYPES.FOLDER,
    };
  };

  const [newWorkspaceModal, setNewWorkspaceModal] = useState(false);

  const handleOpenNewWorkspaceModal = () => {
    setNewWorkspaceModal(true);
  };

  const handleCloseNewWorkspaceModal = () => {
    setNewWorkspaceModal(false);
  };

  const handleContextMenu = (e, node) => {
    e.preventDefault();
    e.stopPropagation();
    if (node.type === STRUCTURE_TYPES.FOLDER) {
      contextMenu.show({
        id: folderContextMenuId,
        event: e,
      });
      if (selectedNode !== node) {
        setSelectedNode(node);
      }
    } else if (node.type !== null) {
      contextMenu.show({
        id: itemContextMenuId,
        event: e,
      });
      if (selectedNode !== node) {
        setSelectedNode(node);
      }
    }
  };

  const handleCreateRootFolder = () => {
    setRootFolderName("");
    setRootFolderModalOpen(true);
  };

  const handleCloseRootFolderModal = () => {
    setRootFolderModalOpen(false);
  };

  const createRootFolder = () => {
    const newFolderNode = createFolderNode(rootFolderName, null);

    var tempTree = structureTree;
    tempTree.splice(0, 0, newFolderNode);
    setStructureTree([...tempTree]);

    handleCloseRootFolderModal();
  };

  const handleDeletingFolder = () => {
    setConfirmDeleteFolderModalOpen(true);
  };

  const handleConfirmDeleteFolder = () => {
    setConfirmDeleteFolderModalOpen(false);
    deleteSelectedFolder();
  };

  const deleteSelectedFolder = () => {
    var { parentNode } = selectedNode;

    var tempTree = structureTree;

    var nonFolders = findNonFolderItems(selectedNode);

    for (var x in nonFolders) {
      nonFolders[x].parentNode = null;
    }

    if (nonFolders.length > 0) {
      tempTree.splice(0, 0, ...nonFolders);
    }

    if (!parentNode) {
      let idx = tempTree.findIndex(x => x.id === selectedNode.id);
      tempTree.splice(idx, 1);
    } else {
      let idx = parentNode.items.findIndex(x => x.id === selectedNode.id);
      parentNode.items.splice(idx, 1);
    }

    setStructureTree([...tempTree]);
  };

  const findNonFolderItems = node => {
    if (!node.items) {
      return [];
    }
    var items = [...node.items.filter(x => x.type !== STRUCTURE_TYPES.FOLDER)];
    var folders = node.items.filter(x => x.type === STRUCTURE_TYPES.FOLDER);
    for (var idx in folders) {
      var f = folders[idx];
      items = [...items, ...findNonFolderItems(f)];
    }
    return items;
  };

  const handleCreatingFolder = () => {
    setSubFolderName("");
    setSubFolderModalOpen(true);
  };

  const handleCloseSubFolderModal = () => {
    setSubFolderModalOpen(false);
  };

  const createSubFolder = () => {
    const newFolderNode = createFolderNode(subFolderName, selectedNode);

    var tempTree = [...(selectedNode.items || [])];
    tempTree.splice(0, 0, newFolderNode);
    selectedNode.items = tempTree;

    handleCloseSubFolderModal();
  };

  const handleRenamingFolder = () => {
    const { name } = selectedNode;

    setOldFolderName(name);
    setNewFolderName(name);
    setRenameFolderModalOpen(true);
  };

  const handleCloseRenameFolderModal = () => {
    setRenameFolderModalOpen(false);
  };

  const renameFolder = () => {
    selectedNode.name = newFolderName;
    handleCloseRenameFolderModal();
  };

  const findNodeByPath = (tree, path) => {
    var p = [...path].reverse();
    var id = p.pop();
    var node = tree.find(x => x.id === id);
    while (p.length > 0) {
      id = p.pop();
      node = node.items.find(x => x.id === id);
    }
    return node ?? null;
  };

  const handleMoveFolder = moveParams => {
    const { sourcePath, targetPath, relativePosition } = moveParams;

    if (targetPath.includes(sourcePath[sourcePath.length - 1])) {
      console.error("** Move not permitted. [Circular reference.]");
      return;
    }

    var tempTree = copyStructureWithRelationsAndIds(structureTree);

    var dropTargetNode = findNodeByPath(tempTree, targetPath);

    if (
      relativePosition === 0 &&
      dropTargetNode.type !== STRUCTURE_TYPES.FOLDER
    ) {
      console.error("** Move not permitted. [Wrong target type.]");
      return;
    }

    var droppedNode = findNodeByPath(tempTree, sourcePath);

    var sourceNode = droppedNode.parentNode;
    var sourceList = !sourceNode ? tempTree : sourceNode.items;
    var sourceListIndex = sourceList.findIndex(x => x.id === droppedNode.id);

    var targetNode; // The node to which the folder will be moved.
    var targetList; // The list to which the folder will be added.
    var targetListIndex; // Where in the list the folder will be added.

    if (relativePosition === 0) {
      targetNode = dropTargetNode;
      if (!targetNode.items) {
        targetNode.items = [];
      }
      targetList = targetNode.items;
      targetListIndex = 0;
    } else {
      targetNode = dropTargetNode.parentNode;
      if (targetNode && !targetNode.items) {
        targetNode.items = [];
      }
      targetList = !targetNode ? tempTree : targetNode.items;
      targetListIndex = targetList.findIndex(x => x.id === dropTargetNode.id);
      if (relativePosition > 0) {
        targetListIndex++;
      }
    }

    if (sourceList === targetList && targetListIndex > sourceListIndex) {
      targetListIndex--;
    }

    var removedNode = sourceList.splice(sourceListIndex, 1)[0];

    removedNode.parentNode = targetNode;
    targetList.splice(targetListIndex, 0, removedNode);

    setStructureTree([...tempTree]);
  };

  const handleMoveWorkspace = moveParams => {
    const { sourcePath, targetPath, relativePosition } = moveParams;

    var tempTree = copyStructureWithRelationsAndIds(structureTree);

    var droppedNode = findNodeByPath(tempTree, sourcePath);

    if (droppedNode.type === STRUCTURE_TYPES.FOLDER) {
      console.error("** Move not permitted. [Wrong type dropped.]");
      return;
    }

    var dropTargetNode = findNodeByPath(tempTree, targetPath);

    if (relativePosition === 0 && dropTargetNode === droppedNode) {
      console.info("** Same position. No update.");
      return;
    }

    var sourceNode = droppedNode.parentNode;
    var sourceList = !sourceNode ? tempTree : sourceNode.items;
    var sourceListIndex = sourceList.findIndex(x => x.id === droppedNode.id);

    var targetNode; // The node to which the folder will be moved.
    var targetList; // The list to which the folder will be added.
    var targetListIndex; // Where in the list the folder will be added.

    if (relativePosition === 0) {
      targetNode = dropTargetNode;
      if (!targetNode.items) {
        targetNode.items = [];
      }
      targetList = targetNode.items;
      targetListIndex = 0;
    } else {
      targetNode = dropTargetNode.parentNode;
      if (!targetNode && targetNode.items) {
        targetNode.items = [];
      }
      targetList = !targetNode ? tempTree : targetNode.items;
      targetListIndex = targetList.findIndex(x => x.id === dropTargetNode.id);
      if (relativePosition > 0) {
        targetListIndex++;
      }
    }

    if (sourceList === targetList && targetListIndex > sourceListIndex) {
      targetListIndex--;
    }

    var removedNode = sourceList.splice(sourceListIndex, 1)[0];

    removedNode.parentNode = targetNode;
    targetList.splice(targetListIndex, 0, removedNode);

    setStructureTree([...tempTree]);
  };

  const handleSaveStructure = async e => {
    try {
      var items = copyStructure(structureTree);

      const res = await saveStructure({ variables: { input: { items } } });

      if (res && res.data && res.data.saveStructure) {
        const { success, errorResult, result } = res.data.saveStructure;

        if (success) {
          alert.success(
            intl.formatMessage({ id: "organizeStructure.success.savedChanges" })
          );
          var viewer = JSON.parse(localStorage.getItem("viewer"));
          viewer.structure.items = result.items;
          localStorage.setItem("viewer", JSON.stringify(viewer));
          store.dispatch(currentViewer({ viewer }));
        } else {
          alert.error(
            intl.formatMessage({ id: "organizeStructure.error.couldNotSave" })
          );
        }
      }
    } catch (err) {
      console.error("error >", err);
      alert.error(
        intl.formatMessage({ id: "organizeStructure.error.couldNotSave" })
      );
    }
  };

  const copyStructure = structure => {
    return structure.map(node => copyStructureNode(node));
  };

  const copyStructureNode = node => {
    var { name, siteId, type } = node;
    var result = { name, siteId, type, items: null };

    if (node.items && node.items.length > 0) {
      var items = node.items.map(x => copyStructureNode(x));
      result.items = [...items];
    }

    return result;
  };

  const copyStructureWithRelationsAndIds = structure => {
    var copy = structure.map(node => copyStructureNodeWithIds(node));
    addRelations(copy);
    return copy;
  };

  const copyStructureNodeWithIds = node => {
    var { name, siteId, type, id } = node;
    var result = { name, siteId, type, items: null, id };

    if (node.items && node.items.length > 0) {
      var items = node.items.map(x => copyStructureNodeWithIds(x));
      result.items = [...items];
    }

    return result;
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={5}>
          <Paper className={classes.paper} elevation={0}>
            <Header />
            <Box className={classes.paperBody}>
              <MyWorkspaceHeader
                handleOpen={handleOpenNewWorkspaceModal}
                createRootFolder={handleCreateRootFolder}
              />
              <Box className={classes.menuProvider}>
                <StructureTree
                  structureTree={structureTree}
                  handleContextMenu={handleContextMenu}
                  onMoveFolder={handleMoveFolder}
                  onMoveWorkspace={handleMoveWorkspace}
                />
                <FolderContextMenu
                  contextMenuId={folderContextMenuId}
                  creatingFolder={handleCreatingFolder}
                  deletingFolder={handleDeletingFolder}
                  renamingFolder={handleRenamingFolder}
                />
                <ItemContextMenu
                  contextMenuId={itemContextMenuId}
                  removingItem={e => e.stopPropagation()}
                />
              </Box>
              {false && (
                <>
                  <Divider className={classes.divider} />
                  <SharedWorkspaceItem />
                </>
              )}
            </Box>
            <Box className={classes.paperFooter} align="right">
              <Button
                className={classes.btnSave}
                onClick={handleSaveStructure}
                disabled={savingStructure}
              >
                <Icon className={clsx(["fa fa-save", classes.btnSaveIcon])} />
                <FormattedMessage id="btn.saveChanges" />
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {newWorkspaceModal && (
        <WorkspaceNewModal
          open={newWorkspaceModal}
          handleClose={handleCloseNewWorkspaceModal}
        />
      )}
      {rootFolderModalOpen && (
        <CreateNameModal
          disabled={rootFolderModalDisabled}
          open={rootFolderModalOpen}
          title={intl.formatMessage({ id: "context.newFolder" })}
          handleClose={handleCloseRootFolderModal}
          placeholder={intl.formatMessage({ id: "context.newFolder" })}
          value={rootFolderName}
          onChange={e => setRootFolderName(e.target.value)}
          onClick={createRootFolder}
        />
      )}
      {subFolderModalOpen && (
        <CreateNameModal
          disabled={subFolderModalDisabled}
          open={subFolderModalOpen}
          title={intl.formatMessage({ id: "context.newFolder" })}
          handleClose={handleCloseSubFolderModal}
          placeholder={intl.formatMessage({ id: "context.newFolder" })}
          value={subFolderName}
          onChange={e => setSubFolderName(e.target.value)}
          onClick={createSubFolder}
        />
      )}
      {renameFolderModalOpen && (
        <CreateNameModal
          disabled={renameFolderModalDisabled}
          open={renameFolderModalOpen}
          title={intl.formatMessage({ id: "workspace.rename" })}
          handleClose={handleCloseRenameFolderModal}
          placeholder={oldFolderName}
          value={newFolderName}
          onChange={e => setNewFolderName(e.target.value)}
          onClick={renameFolder}
        />
      )}
      {confirmDeleteFolderModalOpen && (
        <ConfirmDeleteModal
          open={confirmDeleteFolderModalOpen}
          setOpen={setConfirmDeleteFolderModalOpen}
          onConfirmCloseModal={handleConfirmDeleteFolder}
          loading={false}
        />
      )}
    </div>
  );
};

export default WorkspaceEdit;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  informationSection: {
    fontSize: 14,
    textAlign: "center",
    color: theme.palette.primary.dark,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));

const InformationSection = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.informationSection}>{children}</Box>;
};

InformationSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InformationSection;

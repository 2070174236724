import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import FolderName from "./FolderName";
import RightsCheckboxes from "./RightsCheckboxes";

const useStyles = makeStyles(() => ({
  folderIcon: {
    fontSize: 11,
    color: "#5b5654",
  },
  folderIconNoChild: { margin: "2px 3px 2px 27px !important" },
  folderIconWithChild: { margin: "2px 3px 2px 3px" },
  folderTitleSpan: {
    position: "relative",
    flexDirection: "row",
    alignItems: "flex-start",
    display: "flex",
    cursor: "pointer",
    flex: 1,
  },
  editInput: {
    backgroundColor: "#fff",
    fontSize: 11,
    height: 5,
  },
}));

const FolderLabel = ({
  disabled,
  folder,
  folderId,
  folderName,
  folderRights,
  handleCheckboxChange,
}) => {
  const classes = useStyles();

  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);

  useEffect(() => {
    if (folderRights && folderRights[folderId]) {
      if (
        folderRights[folderId].read !== null &&
        folderRights[folderId].read !== undefined
      ) {
        setRead(folderRights[folderId].read);
      }
      if (
        folderRights[folderId].write !== null &&
        folderRights[folderId].write !== undefined
      ) {
        setWrite(folderRights[folderId].write);
      }
    }
  }, [folderRights]);

  return (
    <span role="presentation" className={clsx([classes.folderTitleSpan])}>
      <Icon
        className={clsx([
          "fa fa-folder",
          classes.folderIcon,
          // folder && folder.childFolderIds && folder.childFolderIds.length > 0
          //   ? classes.folderIconWithChild
          //   : classes.folderIconNoChild,
          classes.folderIconWithChild,
        ])}
        style={{ color: folder.colorValue }}
      />
      <FolderName name={folderName} />
      <RightsCheckboxes
        disabled={disabled}
        folder={folder}
        handleCheckboxChange={handleCheckboxChange}
        read={read}
        write={write}
      />
    </span>
  );
};

FolderLabel.defaultProps = {
  disabled: false,
  folderName: "",
  folderRights: {},
  selectedNode: null,
};

FolderLabel.propTypes = {
  disabled: PropTypes.bool,
  selectedNode: PropTypes.shape({
    folderId: PropTypes.number.isRequired,
  }),
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    folderId: PropTypes.number.isRequired,
    parentFolderId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
    read: PropTypes.bool,
    write: PropTypes.bool,
  }).isRequired,
  folderId: PropTypes.number.isRequired,
  folderName: PropTypes.string,
  handleCheckboxChange: PropTypes.func.isRequired,
  folderRights: PropTypes.shape({
    read: PropTypes.bool,
    write: PropTypes.bool,
  }),
};

export default memo(FolderLabel);

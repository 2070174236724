import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const IMPORT_COMPANY_TEMPLATE_FROM_SYSTEM = gql`
  mutation ImportCompanyTemplateFromSystem($targetCompanyId: Int!, $id: ID!) {
    companyAdminContext {
      companyTemplates {
        importCompanyTemplateFromSystem(
          targetCompanyId: $targetCompanyId
          id: $id
        ) {
          success
          errorResult {
            ...ErrorResultFields
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default IMPORT_COMPANY_TEMPLATE_FROM_SYSTEM;

import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { LoadingScreen } from "../common";

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <LoadingScreen />;
    }}
  </AuthConsumer>
);

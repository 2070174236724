import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeView from "@mui/lab/TreeView";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { setAdminOpenedNodes } from "../../actions";
import { useGetCompanyRoles } from "../../hooks";
import AdminCompanySelector from "./AdminCompanySelector";
import { sidebarmenuitems } from "./sidebarmenuitems";
import AdminSidebarTreeItem from "./Users/AdminSidebarTreeItem";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: "#e6e6e6",
    marginLeft: -24,
    marginTop: -24,
    padding: "16px 10px 10px 12px",
    "& .MuiTreeItem-iconContainer": {
      marginRight: 0,
      width: 15,
      height: 15,
      "& .MuiSvgIcon-root": {
        fontSize: 17,
      },
    },
  },
  content: {
    marginBottom: 5,
    padding: "5px 0",
    width: "auto",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    color: "#555",
    textDecoration: "none",
    "&:hover": {
      color: "#555",
    },
    "&:focus": {
      color: "#555",
    },
    "&:active": {
      color: "#555",
    },
  },
  labelIcon: {
    fontSize: 15,
    marginRight: 10,
    width: 15,
    height: 15,
    overflow: "unset",
    textAlign: "center",
    color: "#555",
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "14px",
  },
  spaceBelowSelector: {
    marginBottom: 0,
    height: 6,
  },
}));

const AdminSidebar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { companyId, usersRights } = useSelector(
    state => state.api.companyAdmin.company
  );

  const openedNodes = useSelector(state => state.api.companyAdmin.openedNodes);

  const { execute: getCompanyRoles } = useGetCompanyRoles();

  useEffect(() => {
    if (companyId !== null) {
      getCompanyRoles();
    }
  }, [companyId]);

  useEffect(() => {
    const prevNodeIds = JSON.parse(localStorage.getItem("companyOpenedNodes"));
    if (Array.isArray(prevNodeIds) && prevNodeIds.length > 0) {
      dispatch(setAdminOpenedNodes({ items: prevNodeIds }));
    }
  }, []);

  const handleOnNodeToggle = (e, nodeIds) => {
    e.preventDefault();
    localStorage.setItem("companyOpenedNodes", JSON.stringify(nodeIds));
    dispatch(setAdminOpenedNodes({ items: nodeIds }));
  };

  return (
    <Box className={classes.root}>
      <AdminCompanySelector />
      <Box component="div" className={classes.spaceBelowSelector}></Box>
      <TreeView
        multiSelect
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={openedNodes}
        onNodeToggle={handleOnNodeToggle}
      >
        {sidebarmenuitems.map((x, idx) => {
          return (
            <AdminSidebarTreeItem
              key={`tree-item-${idx}`}
              idx={idx}
              link={x.link}
              icon={x.icon}
              label={x.label}
              enabledByRights={x.enabledByRights}
              usersRights={usersRights}
              childtree={x.childtree}
            />
          );
        })}
      </TreeView>
    </Box>
  );
};

export default AdminSidebar;

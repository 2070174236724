import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const LOCK_FOLDER = gql`
  mutation LockFolder($folderId: Int!) {
    lockFolder(folderId: $folderId) {
      status {
        isLocked
      }
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default LOCK_FOLDER;

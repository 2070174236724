import { gql } from '@apollo/client';

const SiteNewsComment = gql`
  fragment SiteNewsCommentProperties on SiteNewsComment {
    commentDate
    commentId
    dislikes
    likes
    text
    userHasDislikedComment
    userHasLikedComment
    username
    __typename
  }
`;

export default SiteNewsComment;

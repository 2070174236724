import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import axios from "axios";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../hooks";
import { FormattedMessage } from "react-intl";
import {
  GET_FOLDER_FILE_UPLOAD_URL,
  INITIALIZE_FILE_UPLOAD,
} from "../../../graphql/mutations";
import logger from "../../../helpers/logger";
import { DropzoneNoStyle } from "../../common";
import NewFileVersionModal from "./NewFileVersionModal";

const FilenameDropzone = ({
  folderId,
  addSelectedResources,
  refetchResources,
  children,
  fileName,
  resourceId,
  locked,
  handleError,
}) => {
  const alert = useAlert();
  const snackbar = useSnackbar();
  const [refreshDropzone, setRefreshDropzone] = useState(false);
  const [newFileVersionModal, setNewFileVersionModal] = useState(false);
  const [fileToUpload, setFileToUpload] = useState();

  useEffect(() => {
    setRefreshDropzone(false);
  }, [refreshDropzone]);

  //const [getFileUploadURLNewFile] = useMutation(GET_FOLDER_FILE_UPLOAD_URL);
  const [initFileUpload] = useMutation(INITIALIZE_FILE_UPLOAD);

  const getFileUploadURL = async newVersion => {
    try {
      if (newVersion) {
        return await initFileUpload({
          variables: {
            folderId,
            resourceId,
          },
        });
      }
      return await initFileUpload({
        variables: {
          folderId,
          forceCreateNewResource: true,
        },
      });
    } catch (error) {
      return null;
    }
  };

  const uploadFile = async ({ file, newVersion = false }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const urlData = await getFileUploadURL(newVersion);

      if (urlData && urlData.data && urlData.data.initFileUpload) {
        const { success, url: uploadURL } = urlData.data.initFileUpload;
        if (success) {
          const response = await axios.post(`${uploadURL}`, formData);
          if (response && response.data && response.data.id) {
            addSelectedResources([{ id: response.data.id }]);
            alert.success(<FormattedMessage id="fileupload.success" />);
            refetchResources();
          } else {
            snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
          }
        } else {
          const { errorResult } = urlData.data.initFileUpload;
          setRefreshDropzone(true);
          handleError(fileName, errorResult);
        }
      } else {
        snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
      }
      setRefreshDropzone(true);
    } catch (error) {
      logger.error(
        `Error in uploading file. Response Fail. ${JSON.stringify(error)}`
      );
    }
  };

  const uploadFiles = async ({ files }) => {
    try {
      const uploadedFiles = [];
      const promises = await files.map(async file => {
        const formData = new FormData();
        formData.append("file", file);

        const urlData = await getFileUploadURL();

        if (urlData && urlData.data && urlData.data.initFileUpload) {
          const { success, url: uploadURL } = urlData.data.initFileUpload;
          if (success) {
            return axios.post(`${uploadURL}`, formData).then(response => {
              if (response && response.data && response.data.id) {
                uploadedFiles.push({ id: response.data.id });
              }
              setRefreshDropzone(true);
            });
            // eslint-disable-next-line no-else-return
          } else {
            const { errorResult } = urlData.data.initFileUpload;
            setRefreshDropzone(true);
            handleError(fileName, errorResult);
          }
        }
        setRefreshDropzone(true);
        // If failure to get upload url.
        logger.error(`Error in uploading file. Unable to get upload`);
      });
      axios
        .all(promises)
        .then(() => {
          if (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
            addSelectedResources(uploadedFiles);
            alert.success(<FormattedMessage id="fileupload.success" />);
            refetchResources();
          } else {
            snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
          }
        })
        .catch(err => {
          logger.error(
            `Error in uploading file. Response Fail. ${JSON.stringify(err)}`
          );
        });
    } catch (error) {
      logger.error(`Error in uploading file. Unable to send ${error}`);
    }
  };

  const handleProcessUploadFile = async ({ file = {} }) => {
    if (file.name === fileName) {
      uploadFile({ file, newVersion: true });
    } else {
      setNewFileVersionModal(true);
      setFileToUpload(file);
    }
  };

  const handleCreateNewFile = () => {
    setNewFileVersionModal(false);
    uploadFile({ file: fileToUpload, newVersion: false });
  };
  const handleCreateNewVersion = () => {
    setNewFileVersionModal(false);
    uploadFile({ file: fileToUpload, newVersion: true });
  };

  const handleCloseNewVersionModal = () => {
    setNewFileVersionModal(false);
    setRefreshDropzone(true);
  };

  if (refreshDropzone === true) {
    return children;
  }

  const handleUploadFiles = ({ files = [] }) => {
    if (files && Array.isArray(files)) {
      if (files.length === 1) {
        handleProcessUploadFile({ file: files[0] });
      } else {
        uploadFiles({ files });
      }
    }
  };

  return (
    <>
      <DropzoneNoStyle
        uploadFiles={handleUploadFiles}
        uploadMultiple
        maxFiles={null}
        clickable={false}
      >
        {children}
      </DropzoneNoStyle>
      <NewFileVersionModal
        open={newFileVersionModal}
        handleClose={handleCloseNewVersionModal}
        createNewFile={handleCreateNewFile}
        createNewVersion={handleCreateNewVersion}
      />
    </>
  );
};

FilenameDropzone.defaultProps = {
  folderId: null,
  locked: false,
};

FilenameDropzone.propTypes = {
  folderId: PropTypes.number,
  addSelectedResources: PropTypes.func.isRequired,
  refetchResources: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  fileName: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  handleError: PropTypes.func.isRequired,
};

export default FilenameDropzone;

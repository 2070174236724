import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { FormattedMessage, useIntl } from "react-intl";
import { TextField } from "../common";
import { isFieldEmpty } from "../../helpers/validators";

const useStyles = makeStyles(theme => ({
  formHelperText: {
    color: "#fff !important",
    backgroundColor: "#f44336",
    display: "inline-block",
    margin: 0,
    marginLeft: 5,
    marginTop: -1,
    fontSize: 10,
    padding: "0px 5px",
    borderRadius: 3,
  },
  labelWrap: {
    display: "block",
  },
  input: {
    "& .MuiInputBase-input": {
      padding: "7px 12px 8px 12px !important",
    },
    "& label": {
      marginTop: -3,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      marginTop: 0,
    },
  },
}));

const AddressFields = ({
  addressSettings,
  address,
  handleAddressFieldChange,
  addressType,
  fieldsDisabled,
  errors,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <>
      {addressSettings.map(item => {
        if (item.type === "HIDDEN") {
          return null;
        }

        const hasError =
          Boolean(errors[item.name]) && isFieldEmpty(address[item.name]);

        const renderDefaultLabel = fieldName => {
          return intl.formatMessage({
            id: `addresses.defaultLabel.${fieldName}`,
          });
        };

        const defaultLabel = renderDefaultLabel(item.name);
        const itemLabel = item.label || defaultLabel;

        return (
          <TextField
            shrink={true}
            key={`${addressType}-${item.name}`}
            placeholder={item.placeholder}
            customClass={classes.input}
            label={
              !hasError ? (
                itemLabel
              ) : (
                <>
                  {itemLabel}
                  <FormHelperText className={classes.formHelperText}>
                    <FormattedMessage id="context.required" />
                  </FormHelperText>
                </>
              )
            }
            name={item.name}
            value={address[item.name] || ""}
            onChange={event => {
              handleAddressFieldChange({
                fieldName: event.target.name,
                fieldValue: event.target.value,
                addressType,
              });
            }}
            disabled={fieldsDisabled || item.type === "FIXED"}
            error={
              Boolean(errors[item.name]) && isFieldEmpty(address[item.name])
            }
            // helperText={
            //   isFieldEmpty(address[item.name]) ? errors[item.name] : null
            // }
            required={item.type === "REQUIRED"}
          />
        );
      })}
    </>
  );
};

AddressFields.defaultProps = {
  fieldsDisabled: false,
  errors: {},
};

AddressFields.propTypes = {
  addressType: PropTypes.string.isRequired,
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    customerId: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
    telephone: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  addressSettings: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      displayOrder: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  fieldsDisabled: PropTypes.bool,
  handleAddressFieldChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string),
};

// export default memo(AddressFields);

function areEqual(prevProps, nextProps) {
  if (
    nextProps.address !== prevProps.address ||
    nextProps.addressSettings !== prevProps.addressSettings ||
    nextProps.handleAddressFieldChange !== prevProps.handleAddressFieldChange ||
    nextProps.errors !== prevProps.errors
  ) {
    return false;
  }

  return true;
}

export default memo(AddressFields, areEqual);

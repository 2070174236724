import React, { memo, useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { TreeView } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupItem from "./GroupItem";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    padding: 5,
    overflowY: "auto",
    "& .MuiTreeItem-label": {
      paddingLeft: 0,
    },
  },
  treeView: {
    flexGrow: 1,
  },
}));

const GroupTree = ({
  checkedGroups,
  groupTree,
  handleOpenNode,
  onFolderRowClick,
  openedNodes,
  selectedFolderIds,
  setOpenedNodes,
  handleCheckboxChange,
}) => {
  const classes = useStyles();
  const [groupTreeRoots, setGroupTreeRoots] = useState([]);
  const [defaultExpanded] = useState(openedNodes);

  useEffect(() => {
    const newGroupTreeRoots = Object.values(groupTree).filter(x => x.isRoot);
    setGroupTreeRoots(newGroupTreeRoots);
  }, [groupTree]);

  const handleOnNodeToggle = (e, nodeIds) => {
    setOpenedNodes(nodeIds);
  };

  return (
    <Box className={classes.root}>
      <TreeView
        className={classes.treeView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={defaultExpanded}
        onNodeToggle={handleOnNodeToggle}
      >
        {groupTreeRoots.map(x => (
          <Fragment key={`group-tree-roots-${x.groupId}`}>
            <GroupItem
              key={`group-item-${x.groupId}`}
              checkedGroups={checkedGroups}
              group={groupTree[`g_${x.groupId}`]}
              groupTree={groupTree}
              handleCheckboxChange={handleCheckboxChange}
              handleOpenNode={handleOpenNode}
              onFolderRowClick={onFolderRowClick}
              selectedFolderIds={selectedFolderIds}
            />
          </Fragment>
        ))}
      </TreeView>
    </Box>
  );
};

GroupTree.defaultProps = {
  checkedGroups: [],
  handleOpenNode: () => {},
  onFolderRowClick: () => {},
  openedNodes: [],
  selectedFolderIds: [],
  handleCheckboxChange: () => {},
  setOpenedNodes: () => {},
  selectedNode: null,
};

GroupTree.propTypes = {
  checkedGroups: PropTypes.arrayOf(PropTypes.number),
  groupTree: PropTypes.shape({}).isRequired,
  handleOpenNode: PropTypes.func,
  onFolderRowClick: PropTypes.func,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  selectedNode: PropTypes.shape({}),
  handleCheckboxChange: PropTypes.func,
  setOpenedNodes: PropTypes.func,
};

export default memo(GroupTree);

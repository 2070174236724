import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(`error: ${error}`);
    console.error(`errorInfo: ${JSON.stringify(errorInfo)}`);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return <h3>Something went wrong.</h3>;
    }

    return children;
  }
}

export default ErrorBoundary;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CustomSelect, PrimaryButton } from "../../common";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnSave: {
    marginTop: 5,
  },
  label: {
    fontSize: 11,
    marginBottom: 5,
  },
  paperSidebar: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
  },
  space: {
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  roleNameWrap: {
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: "1px solid #ddd",
    "& input": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const SelectRole = ({
  role,
  setRole,
  addRole,
  setAddRole,
  roleName,
  handleUpdateCompanyRole,
  companyRoles,
  updateRoleLoading,
  handleAddRole,
  handleDeleteCompanyRole,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paperSidebar} elevation={0}>
      <Grid container>
        <Grid item md={12}>
          <Typography className={classes.label}>
            
          </Typography>
          <CustomSelect
                      options={companyRoles}
                      value={role}
                      onChange={e => setRole(e)}
                      inputLabel={<FormattedMessage id="companyRoles.chooseRole" />}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <div className={classes.btnSave}>
            <PrimaryButton
              fullWidth
              onClick={handleAddRole}
              disabled={updateRoleLoading}
            >
              <FormattedMessage id="btn.add" />
            </PrimaryButton>
          </div>
        </Grid>
        <Grid item md={12}>
          <div className={classes.btnSave}>
            <PrimaryButton
              fullWidth
              disabled={updateRoleLoading || !(role !== 0) || addRole}
              onClick={handleDeleteCompanyRole}
            >
              <FormattedMessage id="context.delete" />
            </PrimaryButton>
          </div>
        </Grid>
        <Grid item md={12}>
          <div className={classes.btnSave}>
            <PrimaryButton
              fullWidth
              disabled={
                updateRoleLoading || !(role !== 0 || addRole) || !roleName
              }
              onClick={handleUpdateCompanyRole}
            >
              <FormattedMessage id="btn.save" />
            </PrimaryButton>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

SelectRole.defaultProps = {
  updateRoleLoading: null,
};

SelectRole.propTypes = {
  role: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,
  addRole: PropTypes.bool.isRequired,
  setAddRole: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired,
  handleUpdateCompanyRole: PropTypes.func.isRequired,
  companyRoles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleAddRole: PropTypes.func.isRequired,
  updateRoleLoading: PropTypes.bool,
  handleDeleteCompanyRole: PropTypes.func.isRequired,
};

export default SelectRole;

import React from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "block",
  },
  formControl: {
    fontSize: 12,
    padding: 0,
    color: "#66615b",
  },
  inputRoot: {
    marginBottom: 0,
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3 !important",
    },
    "& .Mui-disabled:hover": {
      cursor: "default",
    },
    "& .MuiInputLabel-shrink.Mui-disabled": {
      backgroundColor: "transparent !important",
    },
    "& .MuiInputBase-root": {
      lineHeight: "normal",
      padding: "0.75em 1em",
    },
  },
}));

const TextAreaFull = ({
  placeholder,
  required,
  rows,
  rowsMax,
  label,
  defaultValue,
  disabled,
  value,
  onChange,
  name,
  customLabelClass,
  shrink,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TextField
      name={name}
      fullWidth
      margin="dense"
      variant="outlined"
      className={classes.inputRoot}
      multiline
      required={required}
      rows={rows}
      rowsMax={rowsMax}
      InputProps={{
        classes: { input: classes.formControl },
      }}
      InputLabelProps={{
        classes: {
          root: customLabelClass || classes.labelRoot,
        },
        shrink,
      }}
      onChange={onChange}
      placeholder={placeholder}
      label={label}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      {...rest}
    />
  );
};

export default TextAreaFull;

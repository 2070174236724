import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import SampleProfile from "../../../assets/img/default-avatar.png";
import TextAreaFull from "../../common/FormControl/TextAreaFull";
import ButtonPrimaryAlt from "../../common/ButtonPrimaryAlt";
import getGravatarUrl from "../../../helpers/getGravatarUrl";

const useStyles = makeStyles(() => ({
  profile: {
    width: 50,
    height: 50,
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: 15,
  },
  commentAdd: {
    display: "flex",
    flexGrow: 1,
    marginBottom: 20,
    backgroundColor: "#fbfbfb",
    padding: 20,
    borderRadius: 4,
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginBottom: 15,
      backgroundColor: "#fff",
    },
  },
  w100: {
    width: "100%",
  },
  btnPost: {
    textTransform: "none",
  },
}));

const AddCommentSection = ({
  currentUser,
  handleAddSiteComment,
  loading,
  handleCommentChange,
  commentText,
}) => {
  const classes = useStyles();

  const onSubmitComment = () => {
    handleAddSiteComment();
  };
  const gravatarURL = getGravatarUrl(currentUser.username);

  return (
    <Box className={classes.commentAdd}>
      <img src={gravatarURL} alt="Profile" className={classes.profile} />
      <Box className={classes.w100} align="right">
        <TextAreaFull
          value={commentText}
          onChange={handleCommentChange}
          placeholder="Add a comment…"
          rows="5"
          rowsMax="5"
        />
        <ButtonPrimaryAlt
          label="Post comment"
          icon="fa fa-comment-alt"
          customClass={classes.btnPost}
          disabled={loading}
          onClick={onSubmitComment}
        />
      </Box>
    </Box>
  );
};

AddCommentSection.defaultProps = {
  loading: false,
};

AddCommentSection.propTypes = {
  handleCommentChange: PropTypes.func.isRequired,
  commentText: PropTypes.string.isRequired,
  handleAddSiteComment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  currentUser: PropTypes.shape({ username: PropTypes.string.isRequired })
    .isRequired,
};

export default AddCommentSection;

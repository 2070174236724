const getChildStructureTreeData = item => {
  let structureTree = {};
  let childStructuresTreeData = {};
  const { items, type } = item || {};
  const hasChildren = items && Array.isArray(items) && items.length > 0;

  const itemId = hasChildren ? `f_${item.id}` : `s_${item.id}`;

  if (hasChildren) {
    items.forEach(x => {
      const childStructureTreeData = getChildStructureTreeData(x);

      childStructuresTreeData = {
        ...childStructuresTreeData,
        ...childStructureTreeData,
      };
    });
  }

  structureTree = {
    ...structureTree,
    [itemId]: { ...item, isRoot: false },
    ...childStructuresTreeData,
  };

  return structureTree;
};

export default getChildStructureTreeData;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import Grid from "@mui/material/Grid";
import { useMutation } from "@apollo/client";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { saveAs } from "file-saver";
import { FormattedCurrency, FormattedDate } from "../common";
import IconLink from "../common/FormControl/IconLink";
import AddressInfo from "./AddressInfo";
import { INITIALIZE_ORDER_HISTORY_DOWNLOAD } from "../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  modalBody: {
    backgroundColor: theme.palette.common.white,
    "@media print": {
      margin: 0,
      padding: 0,
    },
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
    padding: "6px 0",
    textTransform: "uppercase",
  },
  table: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "rgba(0,0,0,.05)",
    },
    "@media print": {
      display: "table",
    },
  },
  th: {
    fontSize: 12,
    fontWeight: 600,
  },
  td: {
    fontSize: 12,
  },
  divider: {
    margin: "20px 0",
    "@media print": {
      margin: "5px 0",
    },
  },
  spacing: {
    margin: "10px 0",
  },
  text: {
    fontSize: 12,
  },
  action: {
    color: theme.palette.common.link,
  },
  orderInformation: {
    "@media print": {
      display: "inline-block !important",
      position: "relative",
      width: "100%",
    },
  },
  ordererInformation: {
    "@media print": {
      display: "inline-block !important",
      verticalAlign: "top",
      width: "50%",
    },
  },
  orderStatus: {
    "@media print": {
      display: "inline-block !important",
      verticalAlign: "top",
      width: "50%",
    },
  },

  addressRow: {
    "@media print": {
      display: "block",
      width: "100%",
      margin: 0,
      padding: 0,
      position: "relative",
      verticalAlign: "top",
    },
  },
  addressContainer: {
    "@media print": {
      padding: 0,
      margin: 0,
      position: "relative",
      display: "inline-block !important",
      width: "50%",
      verticalAlign: "top",
    },
  },
  productSummation: {
    "@media print": {
      position: "relative",
      display: "block",
      width: "100%",
      verticalAlign: "top",
    },
  },
  productsSection: {
    "@media print": {
      position: "relative",
      display: "inline-block !important",
      width: "75%",
      verticalAlign: "top",
    },
  },
  summationSection: {
    "@media print": {
      position: "relative",
      display: "inline-block !important",
      width: "25%",
      verticalAlign: "top",
    },
  },
  download: {
    "@media print": {
      display: "none",
    },
  },
  tableResponsive: {
    overflowX: "auto",
  },
}));

const OrderDetail = ({ order, settings }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [initializeDownload] = useMutation(INITIALIZE_ORDER_HISTORY_DOWNLOAD);

  const {
    comment,
    costPlaceOrder,
    deliveryAddress,
    deliveryDateWish,
    invoiceAddress,
    orderDate,
    ordererAddress,
    orderRows,
    sumExclVat,
    sumInclVat,
    transportFee,
    vatAmounts,
  } = order || {};
  const {
    address1: deliveryAddress1,
    address2: deliveryAddress2,
    address3: deliveryAddress3,
    address4: deliveryAddress4,
    address5: deliveryAddress5,
    city: deliveryCity,
    reference: deliveryReference,
    contact: deliveryContact,
    country: deliveryCountry,
    email: deliveryEmail,
    telephone: deliveryTelephone,
    zip: deliveryZip,
  } = deliveryAddress || {};

  const {
    address1: invoiceAddress1,
    address2: invoiceAddress2,
    address3: invoiceAddress3,
    address4: invoiceAddress4,
    address5: invoiceAddress5,
    city: invoiceCity,
    reference: invoiceReference,
    contact: invoiceContact,
    country: invoiceCountry,
    email: invoiceEmail,
    telephone: invoiceTelephone,
    zip: invoiceZip,
  } = invoiceAddress || {};

  const {
    address1: ordererAddress1,
    address2: ordererAddress2,
    address3: ordererAddress3,
    address4: ordererAddress4,
    address5: ordererAddress5,
    city: ordererCity,
    zip: ordererZip,
    country: ordererCountry,
    reference: ordererReference,
    email: ordererEmail,
    telephone: ordererTelephone,
    contact: ordererContact,
  } = ordererAddress || {};

  const {
    showComment,
    showCostCenter,
    showDeliveryAddress,
    showDeliveryDate,
    showEmail,
    showInvoiceAddress,
    showOrderStatus,
    showPackageColumn,
    showPostalAddress,
    showPriceColumn,
    showProductColumn,
    showQuantityColumn,
    showSummation,
    showWeightColumn,
  } = settings;

  const renderComment = () => (
    <>
      <Typography className={classes.text}>
        <strong>
          <FormattedMessage id="orderHistory.comment" />
        </strong>
      </Typography>
      <Typography className={classes.text}>{comment}</Typography>
    </>
  );

  const renderCostCenter = () => (
    <>
      <Typography className={classes.text}>
        <strong>
          <FormattedMessage id="orderHistory.costPlace" />
        </strong>
      </Typography>
      <Typography className={classes.text}>{costPlaceOrder}</Typography>
    </>
  );

  const handleDownloadOnClick = async orderRowId => {
    try {
      const results = await initializeDownload({
        variables: {
          orderRowId,
        },
      });
      if (results && results.data && results.data.initOrderFileDownload) {
        const downloadUrl = results.data.initOrderFileDownload;
        saveAs(downloadUrl);
      } else {
        console.log("Download Failure");
      }
    } catch (e) {
      console.log(`Error downloading order history ${e}`);
    }
  };

  return (
    <div className={classes.modalBody}>
      <Grid container spacing={2} className={classes.addressRow}>
        {showDeliveryAddress && (
          <Grid item xs={12} md={6} className={classes.addressContainer}>
            <AddressInfo
              tableTitle={intl.formatMessage({
                id: "orderHistory.deliveryAddress",
              })}
              address1={deliveryAddress1}
              address2={deliveryAddress2}
              address3={deliveryAddress3}
              address4={deliveryAddress4}
              address5={deliveryAddress5}
              city={deliveryCity}
              zip={deliveryZip}
              country={deliveryCountry}
              reference={deliveryReference}
              email={deliveryEmail}
              telephone={deliveryTelephone}
              contact={deliveryContact}
            />
          </Grid>
        )}
        {showInvoiceAddress && (
          <Grid item xs={12} md={6} className={classes.addressContainer}>
            <AddressInfo
              tableTitle={intl.formatMessage({
                id: "orderHistory.invoiceAddress",
              })}
              address1={invoiceAddress1}
              address2={invoiceAddress2}
              address3={invoiceAddress3}
              address4={invoiceAddress4}
              address5={invoiceAddress5}
              city={invoiceCity}
              zip={invoiceZip}
              country={invoiceCountry}
              reference={invoiceReference}
              email={invoiceEmail}
              telephone={invoiceTelephone}
              contact={invoiceContact}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} className={classes.addressContainer}>
          <AddressInfo
            tableTitle={intl.formatMessage({
              id: "orderHistory.ordererAddress",
            })}
            address1={ordererAddress1}
            address2={ordererAddress2}
            address3={ordererAddress3}
            address4={ordererAddress4}
            address5={ordererAddress5}
            city={ordererCity}
            zip={ordererZip}
            country={ordererCountry}
            reference={ordererReference}
            email={ordererEmail}
            telephone={ordererTelephone}
            contact={ordererContact}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.addressContainer}>
          <Typography className={classes.title}>
            <FormattedMessage id="orderHistory.ordererInformation" />
          </Typography>
          {showEmail && (
            <>
              <Typography className={classes.text}>
                <strong>
                  <FormattedMessage id="orderHistory.email" />
                </strong>
              </Typography>
              <Typography className={classes.text}>{ordererEmail}</Typography>
            </>
          )}
          <Box className={classes.spacing} />
          <Typography className={classes.text}>
            <strong>
              <FormattedMessage id="orderHistory.earliestDeliveryDate" />
            </strong>
          </Typography>
          <Typography className={classes.text}>
            <FormattedDate value={deliveryDateWish} />
          </Typography>
          <Box className={classes.spacing} />
          {showComment && renderComment()}
          <Box className={classes.spacing} />
          {showCostCenter && renderCostCenter()}
          <Box className={classes.spacing} />
          <Box className={classes.spacing} />
          {showOrderStatus && (
            <>
              <Typography className={classes.title}>
                <FormattedMessage id="orderHistory.status" />
              </Typography>
              <Typography className={classes.text}>
                <strong>
                  <FormattedMessage id="orderHistory.orderCreated" />
                </strong>
              </Typography>
            </>
          )}

          <Typography className={classes.text}>
            <FormattedDate value={orderDate} />
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2} className={classes.productSummation}>
        <Grid item xs={12} md={9} className={classes.productsSection}>
          <Box className={classes.tableResponsive}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={clsx([classes.th, classes.download])} />
                  {showProductColumn && (
                    <TableCell className={classes.th}>
                      <FormattedMessage id="orderHistory.product" />
                    </TableCell>
                  )}
                  {showPackageColumn && (
                    <TableCell className={classes.th}>
                      <FormattedMessage id="orderHistory.package" />
                    </TableCell>
                  )}
                  {showQuantityColumn && (
                    <TableCell className={classes.th}>
                      <FormattedMessage id="orderHistory.quantity" />
                    </TableCell>
                  )}

                  {showPriceColumn && (
                    <TableCell className={classes.th}>
                      <FormattedMessage id="orderHistory.award" />
                    </TableCell>
                  )}

                  {showWeightColumn && (
                    <TableCell className={classes.th}>
                      <FormattedMessage id="orderHistory.weight" />
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderRows &&
                  Array.isArray(orderRows) &&
                  orderRows.length > 0 &&
                  orderRows.map(x => (
                    <TableRow key={`${x.product}-${x.amount}`}>
                      <TableCell
                        className={clsx([classes.td, classes.download])}
                      >
                        <IconLink
                          icon="fa fa-download"
                          customClass={classes.action}
                          onClick={() => handleDownloadOnClick(x.id)}
                        />
                      </TableCell>
                      {showProductColumn && (
                        <TableCell className={classes.td}>
                          {x.product}
                        </TableCell>
                      )}
                      {showPackageColumn && (
                        <TableCell className={classes.td} />
                      )}
                      {showQuantityColumn && (
                        <TableCell className={classes.td}>
                          <FormattedNumber value={x.amount} />
                        </TableCell>
                      )}
                      {showPriceColumn && (
                        <TableCell className={classes.td}>
                          <FormattedCurrency value={x.price} />
                        </TableCell>
                      )}
                      {showWeightColumn && (
                        <TableCell className={classes.td}>
                          <FormattedNumber
                            // eslint-disable-next-line react/style-prop-object
                            style="unit"
                            value={x.weight}
                            unit="gram"
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} className={classes.summationSection}>
          {showSummation && (
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.th}>
                    <FormattedMessage id="orderHistory.summation" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.td}>
                    <FormattedMessage id="orderHistory.freight" />
                  </TableCell>
                  <TableCell className={classes.td}>
                    <FormattedCurrency value={transportFee} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.td}>
                    <FormattedMessage id="orderHistory.net" />
                  </TableCell>
                  <TableCell className={classes.td}>
                    <FormattedCurrency value={sumExclVat} />
                  </TableCell>
                </TableRow>
                {vatAmounts &&
                  Array.isArray(vatAmounts) &&
                  vatAmounts.length > 0 &&
                  vatAmounts.map(x => (
                    <TableRow key={`${x.name}`}>
                      <TableCell className={classes.td}>{x.name}</TableCell>
                      <TableCell className={classes.td}>
                        <FormattedCurrency value={x.vatAmount} />
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell className={classes.td}>
                    <FormattedMessage id="orderHistory.total" />
                  </TableCell>
                  <TableCell className={classes.td}>
                    <FormattedCurrency value={sumInclVat} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

OrderDetail.defaultProps = {
  settings: null,
};

OrderDetail.propTypes = {
  settings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
  order: PropTypes.shape({
    comment: PropTypes.string,
    costPlaceOrder: PropTypes.string,
    deliveryAddress: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      contact: PropTypes.string,
      country: PropTypes.string,
      email: PropTypes.string,
      reference: PropTypes.string,
      telephone: PropTypes.string,
      zip: PropTypes.string,
    }).isRequired,
    invoiceAddress: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      contact: PropTypes.string,
      country: PropTypes.string,
      email: PropTypes.string,
      reference: PropTypes.string,
      telephone: PropTypes.string,
      zip: PropTypes.string,
    }).isRequired,
    ordererAddress: PropTypes.shape({
      email: PropTypes.string,
    }).isRequired,
    deliveryDateWish: PropTypes.string,
    orderDate: PropTypes.string,
    orderId: PropTypes.number.isRequired,
    status: PropTypes.string,
    orderRows: PropTypes.shape({
      id: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      product: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OrderDetail;

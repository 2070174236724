import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";

const RenderNotificationItems = ({
  notifications,
  handleDisabled,
  handleOpen,
}) => {
  const disabled = !notifications;
  return (
    <ContextMenuItem
      disabled={disabled}
      onClick={() => (disabled ? handleDisabled() : handleOpen())}
    >
      <FormattedMessage id="context.shortlist" />
    </ContextMenuItem>
  );
};

RenderNotificationItems.defaultProps = {
  notifications: false,
  locked: false,
};

RenderNotificationItems.propTypes = {
  notifications: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  locked: PropTypes.bool,
};

export default RenderNotificationItems;

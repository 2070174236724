import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import { PrimaryButton } from "../../common";

const NoOption = ({ handleAddNewCompany }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <FormattedMessage id="product.noOptions" />
      <PrimaryButton onMouseDown={() => handleAddNewCompany()}>
        <FormattedMessage id="createAccount.addNewCompany" />
      </PrimaryButton>
    </Box>
  );
};

NoOption.propTypes = {
  handleAddNewCompany: PropTypes.func.isRequired,
};

export default NoOption;

import React, { useState } from "react";
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Toolbar,
} from "@mui/material";
import SplitButton from "./SplitButton"

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

const SalesTabsBar = ({ defaultTabIndex = 0, disabled, onPageButtonClick, onPageTabChange, pageButtons, pageTabs }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex);

  const handleTabChange = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex);
    onPageTabChange?.({ tabIndex, type: pageTabs[tabIndex].type });
  };

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Tabs
          aria-label="V�lj vad som ska visas"
          onChange={handleTabChange}
          value={selectedTabIndex}
        >
          {Array.isArray(pageTabs) && pageTabs.map((tab, index) => {
            return (
              <Tab disabled={disabled} key={index} label={tab.label} {...a11yProps(index)} />
            )
          })}
        </Tabs>
        <Box flexGrow={1} />
        <SplitButton disabled={disabled} onClick={onPageButtonClick} options={pageButtons} />
      </Toolbar>
    </AppBar>
  );
};

export default SalesTabsBar;

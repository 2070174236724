import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  imgPager: {
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    padding: "2px 0",
    backgroundColor: theme.palette.common.white,
    minWidth: 130,
    boxShadow: "0 1px 5px #e8e8e8",
  },
  imgPagerBtn: {
    padding: "5px 10px",
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 18,
      color: theme.palette.text.secondary,
    },
  },
  imgPagerText: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
    borderLeft: "1px solid #eaeaea",
    borderRight: "1px solid #eaeaea",
    textAlign: "center",
    verticalAlign: "middle",
    "& span": {
      margin: "0 7px",
      fontSize: 12,
    },
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
    "& input": {
      backgroundColor: theme.palette.common.white,
      borderRadius: 3,
      padding: 5,
      width: 25,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.06)",
    },
  },
}));

const ImagePager = ({
  customClass,
  handlePrevious,
  handleNext,
  disabledNext,
  disabledPrev,
  imageIndex,
  maxPage,
  productType,
}) => {
  const classes = useStyles();
  let minPages;
  let maxPages;
  if (productType === "DYNAMIC_TEMPLATE") {
    minPages = imageIndex + 1;
    maxPages = maxPage + 1;
  } else {
    minPages = imageIndex;
    maxPages = maxPage;
  }

  return (
    <Box className={clsx([classes.imgPager, customClass])}>
      <IconButton
        className={classes.imgPagerBtn}
        onClick={handlePrevious}
        disabled={disabledPrev}
      >
        <Icon className="fa fa-angle-left" />
      </IconButton>
      <Typography className={classes.imgPagerText}>
        {minPages}
        <span>of</span> {maxPages}
      </Typography>
      <IconButton
        className={classes.imgPagerBtn}
        onClick={handleNext}
        disabled={disabledNext}
      >
        <Icon className="fa fa-angle-right" />
      </IconButton>
    </Box>
  );
};

export default ImagePager;

import { gql } from '@apollo/client';

const ADD_SITE_SUPPLIER = gql`
  mutation AddSiteSupplier(
    $siteId: Int!
    $name: String
    $email: String
    $supplierId: String
  ) {
    addSiteSupplier(
      siteId: $siteId
      name: $name
      email: $email
      supplierId: $supplierId
    ) {
      formattedSupplierId
      status
      supplierEmail
      supplierId
      supplierName
    }
  }
`;

export default ADD_SITE_SUPPLIER;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import MessageMe from "./MessageMe";
import MessageYou from "./MessageYou";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column-reverse",
    padding: 0,
  },
}));

const MessageArea = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <MessageMe />
      <MessageYou />
      <MessageMe />
      <MessageYou />
      <MessageYou />
      <MessageMe />
    </Box>
  );
};

export default MessageArea;

import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import { CellTree } from "@table-library/react-table-library/tree";
import { useProjectDnDContext } from "../../hooks/Projects";
import DragDropHandle, { DRAG_HANDLE_WIDTH } from "./DragDropHandle";

const CellTreeHoverMore = ({ children, className, disabled, draggable, item, onDrop, onMoreClick, ...props }) => {

  const { id: taskId, parentTaskId } = item;

  const { isDragging } = useProjectDnDContext();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <CellTree
      {...props}
      className={className}
      item={item}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        alignItems: "center",
        display: "flex",
        paddingLeft: DRAG_HANDLE_WIDTH,
        position: "relative",
      }}
      treeIcon={{
        iconDefault: "",
        noIconMargin: "17px",
      }}
    >
      {draggable &&
        <DragDropHandle
          disabled={disabled}
          isDragging={isDragging}
          isHovered={isHovered}
          item={{ parentTaskId, taskId }}
          onDrop={onDrop}
        />
      }
      {children}
      {isHovered && !disabled && !isDragging && (
        <IconButton
          onClick={onMoreClick}
          style={{
            padding: "6px",
            position: "absolute",
            right: 0,
            top: "3px",
          }}>
          <ChevronRightIcon sx={{ fontSize: "large" }} />
        </IconButton>
      )}
    </CellTree>
  );
};

export default CellTreeHoverMore;

import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Icon, IconButton, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ImageWithFallback from "../../common/ImageWithFallback";

const useStyles = makeStyles(theme => ({
  image: {
    height: 120,
    width: "100%",
    objectFit: "cover",
  },
  imageName: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
    marginTop: 5,
    marginBottom: 0,
    wordBreak: "break-all",
  },
  imageWrap: {
    position: "relative",
    "&:hover > div": {
      opacity: 1,
    },
  },
  imageHover: {
    position: "absolute",
    top: 0,
    bottom: 0,
    textAlign: "center",
    width: "100%",
    height: "97%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: 3,
    opacity: 0,
    transition: "opacity 0.4s, top 0.4s",
  },
  searchButton: {
    color: theme.palette.common.white,
    marginTop: 40,
    display: "inline-block",
  },
  searchIcon: {
    fontSize: 14,
  },
  trashButton: {
    color: theme.palette.common.white,
    position: "absolute",
    bottom: 0,
    right: 0,
    marginTop: 0,
  },
  trashIcon: {
    fontSize: 14,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  skeleton: {
    borderRadius: 3,
  },
}));

const Image = ({
  handleOpenDelete,
  handleOpenImage,
  imageName,
  loading,
  thumbnailImageUri,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.imageWrap}>
        {loading ? (
          <div className={classes.loadingContainer}>
            <Skeleton
              animation="wave"
              classes={{ root: classes.skeleton }}
              height={120}
              variant="rect"
              width="100%"
            />
          </div>
        ) : (
          <ImageWithFallback
            alt={imageName}
            className={classes.image}
            imageUrl={thumbnailImageUri}
          />
        )}
        <Box className={classes.imageHover}>
          <IconButton
            className={classes.searchButton}
            onClick={handleOpenImage}
          >
            <Icon className={clsx(["fa fa-search-plus", classes.searchIcon])} />
          </IconButton>
          <IconButton
            className={classes.trashButton}
            onClick={handleOpenDelete}
          >
            <Icon className={clsx(["far fa-trash-alt", classes.trashIcon])} />
          </IconButton>
        </Box>
      </Box>
      <Typography align="center" className={classes.imageName}>
        {imageName || " "}
      </Typography>
    </Box>
  );
};

Image.propTypes = {
  thumbnailImageUri: PropTypes.string,
  handleOpenImage: PropTypes.func.isRequired,
  handleOpenDelete: PropTypes.func.isRequired,
  imageName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Image;

import PropTypes from "prop-types";
import { useState } from "react";
import { CardMedia } from "@mui/material";
import _brokenImageUrl from "../../assets/img/broken-img.png";

const ConsentImage = ({ imageUrl, ...rest }) => {
  const [error, setError] = useState(false);
  const handleError = () => setError(true);
  return (
    <CardMedia
      component="img"
      image={error ? _brokenImageUrl : imageUrl}
      onError={handleError}
      {...rest}
    />
  );
};

ConsentImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default ConsentImage;

import { ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { graphqlUrl, graphqlUrlWS } from "../services/config";
import { store } from "../store";
import { logout } from "../actions";

const httpLink = new HttpLink({
  uri: graphqlUrl,
});

const accessToken = localStorage.getItem("access_token");

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  // The uri property is what being used.
  // It seems there is an error in apollo-link-ws library.
  // Reference: https://github.com/apollographql/apollo-link/issues/773
  uri: graphqlUrlWS,
  options: {
    reconnect: true,
    connectionParams: {
      authToken: accessToken,
    },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const access_token = localStorage.getItem("access_token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: access_token ? `Bearer ${access_token}` : "",
    },
  };
});

const resetToken = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    // remove cached token on 401 from the server
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    store.dispatch(logout());
    // redirect to logout page, which will be then the identity server logout.
    window.location.replace("/logout");
  }
});

const authFlowLink = authLink.concat(resetToken);

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const link = authFlowLink.concat(splitLink);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
});

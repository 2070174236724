import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import FoldersPanel from "./FoldersPanel";
import ParticipantsPanel from "./ParticipantsPanel";
import RollerPanel from "./RollerPanel";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflow: "hidden",
    overflowY: "auto",
  },
}));

const StepTwo = ({
  fileSettings,
  folderSettings,
  roles,
  setFileSettings,
  setFolderSettings,
  setRoles,
  setUsers,
  users,
  visible,
  filesFoldersSettings,
  setFilesFolderSettings,
}) => {
  const classes = useStyles();

  if (!visible) return null;
  return (
    <div className={classes.root}>
      <FoldersPanel
        filesFoldersSettings={filesFoldersSettings}
        setFilesFolderSettings={setFilesFolderSettings}
      />
      <ParticipantsPanel users={users} setUsers={setUsers} />
      <RollerPanel roles={roles} setRoles={setRoles} />
    </div>
  );
};

StepTwo.propTypes = {
  roles: PropTypes.shape({}).isRequired,
  users: PropTypes.shape({}).isRequired,
  setRoles: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
  fileSettings: PropTypes.shape({}).isRequired,
  folderSettings: PropTypes.shape({}).isRequired,
  setFolderSettings: PropTypes.func.isRequired,
  setFileSettings: PropTypes.func.isRequired,
};

export default StepTwo;

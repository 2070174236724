import { gql } from '@apollo/client';

const AddressSettingsFragment = gql`
  fragment AddressSettingsFields on AddressSettings {
    address1 {
      ...AddressSettingFields
    }
    address2 {
      ...AddressSettingFields
    }
    address3 {
      ...AddressSettingFields
    }
    address4 {
      ...AddressSettingFields
    }
    address5 {
      ...AddressSettingFields
    }
    city {
      ...AddressSettingFields
    }
    contact {
      ...AddressSettingFields
    }
    country {
      ...AddressSettingFields
    }
    customerId {
      ...AddressSettingFields
    }
    email {
      ...AddressSettingFields
    }
    reference {
      ...AddressSettingFields
    }
    telephone {
      ...AddressSettingFields
    }
    zip {
      ...AddressSettingFields
    }
  }

  fragment AddressSettingFields on AddressSetting {
    displayOrder
    label
    type
    placeholder
  }
`;

export default AddressSettingsFragment;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import { Paper, Stack, Box, Typography, Button, Container, Checkbox, Card, CardMedia, FormControlLabel } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import CustomTextField from "../../components/common/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    width: "500px",
    borderRadius: 3,
    padding: "16px"
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
  paperBody: {
    padding: theme.spacing(4),
  },

  container: {
    display: 'flex',
    overflowX: 'auto',
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  card: {
    width: 170,
    height: 170,
    margin: theme.spacing(1),
    overflow: 'hidden',
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'none', // Crop without stretching
    objectPosition: 'center center', // Center the image within the square
  },
  boxLabel: {
    fontSize: 12,
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
  },
  textFieldLabel: {
    color: "#000",
    fontSize: "11px!important",
    fontWeight: 600,
  },

}));

const MyConsents = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [formValues, setFormValues] = useState({ firstname: "Testperson", lastname: "Efternamn", title: "", email: "devops@kommunal.se", vilkor1: true, vilkor2: true, vilkor3: true, vilkor4: true });
  const handleFormValues = (e) => {
    var temp = { ...formValues };
    if (e.target.type == "checkbox") {
      temp[e.target.name] = e.target.checked;

    } else {
      temp[e.target.name] = e.target.value;
    }
    setFormValues(temp);
  }

  const selectedResources = [{
    id: "1", thumbnailImageUri:"https://uq.unq.se/UniQueue3/Content/IconHandler.ashx?resourceId=140538"}]

  return (
    <div className={classes.root}>
      <Box align="center">
      <Paper className={classes.paper} elevation={0}>
        <Stack spacing={{ xs: 1 }}>
          <Stack direction="row" spacing={{ xs: 1 }}>
            <CustomTextField
              name="firstName"
              required={false}
              label={<FormattedMessage id="settings.firstName" />}
              value={formValues.firstname}
              onChange={handleFormValues}
              customLabelClass={classes.textFieldLabel}
              shrink
            />

            <CustomTextField
              name="lastname"
              required={false}
              label={<FormattedMessage id="settings.lastName" />}
              value={formValues.lastname}
              onChange={handleFormValues}
              customLabelClass={classes.textFieldLabel}
              shrink
            />
          </Stack>

          
          <CustomTextField
            name="title"
            required={false}
            label={<FormattedMessage id="settings.position" />}
            value={formValues.title}
            onChange={handleFormValues}
            customLabelClass={classes.textFieldLabel}
            shrink
          />

          <CustomTextField
            name="email"
            required={false}
            label={<FormattedMessage id="settings.email" />}
            value={formValues.email}
            onChange={handleFormValues}
            customLabelClass={classes.textFieldLabel}
            shrink
          />


          <Container className={classes.container}>

            {selectedResources.map((resource) => (
              <Card key={resource.id} className={classes.card}>
                <CardMedia
                  component="img"
                  alt={`Thumbnail for ${resource.id}`}
                  image={resource.thumbnailImageUri}
                  className={classes.media}
                />
              </Card>
            ))}
          </Container>
          <Stack>
            <Typography className={classes.boxLabel}>Vilkor</Typography>


            <FormControlLabel
              classes={{
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  name={"vilkor1"}
                  checked={formValues.vilkor1}
                  className={classes.checkbox}
                  onClick={handleFormValues}
                  disabled
                />
              }
              label={"Jag håller med om att bild/video kan sparas"}
            />

            <FormControlLabel
              classes={{
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  name={"vilkor2"}
                  checked={formValues.vilkor2}
                  className={classes.checkbox}
                  onClick={handleFormValues}
                  
                />
              }
              label={"Samtycke för externt bruk"}
            />

            <FormControlLabel
              classes={{
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  name={"vilkor3"}
                  checked={formValues.vilkor3}
                  className={classes.checkbox}
                  onClick={handleFormValues}
                  
                />
              }
              label={"Bilden får endast användas i intern kommunikation"}
            />


            <FormControlLabel
              classes={{
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  name={"vilkor4"}
                  checked={formValues.vilkor4}
                  className={classes.checkbox}
                  onClick={handleFormValues}
                  
                />
              }
              label={"Bilden får användas i marknadsföring"}
            />
          </Stack>

        </Stack>
        <Box align="right">
          <Button variant="outlined">Neka</Button> <Button variant="contained" >Godkänn</Button>
        </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default MyConsents;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Grid, Typography, Switch } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import CustomSelect from "../common/CustomSelect";
import SubscriptionLevelModal from "./SubscriptionLevelModal";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    transform: "translate(14px, 13px) scale(1)",
  },
  switchLabel: {
    fontWeight: 700,
  },
}));

const StepThree = ({
  stepThreeValues,
  setStepThreeValues,
  availableSubscriptionLevels,
  requiredSubscriptionLevel,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [subscriptionLevels, setSubscriptionLevels] = useState([]);
  const [showFields, setShowFields] = useState(false);

  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [userSubscription, setUserSubscription] = useState({});

  const getSubscriptionLevel = subscription => {
    switch (subscription) {
      case "FREE":
        return 1;
      case "MINI":
        return 2;
      case "STANDARD":
        return 3;
      case "PREMIUM":
        return 4;
      case "PRO":
        return 5;
      case "BUSINESS":
        return 6;
      default:
        return "";
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setStepThreeValues({ ...stepThreeValues, [name]: value });
  };

  const handleSwitch = e => {
    const { name, checked } = e.target;
    setStepThreeValues({ ...stepThreeValues, [name]: checked });
  };

  const handleChangeSubscription = e => {
    const { value } = e.target;
    const currentLevel = getSubscriptionLevel(value);
    const requiredLevel = getSubscriptionLevel(requiredSubscriptionLevel);
    let tempUserSub = {};

    if (currentLevel < requiredLevel) {
      tempUserSub = { subscription: value, level: "lower" };
      setUserSubscription(tempUserSub);
      setSubscriptionModal(true);
    }

    if (currentLevel > requiredLevel) {
      tempUserSub = { subscription: value, level: "higher" };
      setUserSubscription(tempUserSub);
      setSubscriptionModal(true);
    }

    if (currentLevel === requiredLevel) {
      setStepThreeValues({ ...stepThreeValues, subscription: value });
    }
  };

  const customLabelClass = classes.label;

  const paymentMethods = [
    {
      label: intl.formatMessage({ id: "createAccount.invoice" }),
      value: "INVOICE",
    },
  ];

  useEffect(() => {
    if (
      Array.isArray(availableSubscriptionLevels) &&
      availableSubscriptionLevels.length > 0
    ) {
      const levels = availableSubscriptionLevels.map(x => {
        return {
          label: x,
          value: x,
          // disabled:
          //   getSubscriptionLevel(userCurrentSubscriptionLevel) >
          //   getSubscriptionLevel(x),
        };
      });

      setSubscriptionLevels(levels);
    }
  }, [availableSubscriptionLevels]);

  useEffect(() => {
    if (stepThreeValues.userCompanyPaysForItself) {
      if (
        getSubscriptionLevel(stepThreeValues.subscription) <
        getSubscriptionLevel(requiredSubscriptionLevel)
      ) {
        setShowFields(true);
      } else {
        setShowFields(false);
      }
    }
  }, [stepThreeValues, requiredSubscriptionLevel]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Typography>
          <FormattedMessage id="createAccount.payerNotice" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} align="right">
        <Typography className={classes.switchLabel}>
          <FormattedMessage id="createAccount.myCompanyPays" />
        </Typography>
        <Switch
          name="userCompanyPaysForItself"
          onChange={handleSwitch}
          value={stepThreeValues.userCompanyPaysForItself}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomSelect
          name="subscription"
          inputLabel={<FormattedMessage id="createAccount.subscriptionLevel" />}
          options={subscriptionLevels}
          onChange={handleChangeSubscription}
          value={stepThreeValues.subscription}
          inputLabelCustomClass={customLabelClass}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomSelect
          name="paymentMethod"
          inputLabel={<FormattedMessage id="createAccount.paymentMethod" />}
          options={paymentMethods}
          onChange={handleChange}
          value={stepThreeValues.paymentMethod}
          inputLabelCustomClass={customLabelClass}
        />
      </Grid>
      {subscriptionModal && (
        <SubscriptionLevelModal
          open={subscriptionModal}
          userSubscription={userSubscription}
          setStepThreeValues={setStepThreeValues}
          handleClose={() => setSubscriptionModal(false)}
        />
      )}
    </Grid>
  );
};

StepThree.defaultProps = {
  stepThreeValues: {},
  availableSubscriptionLevels: null,
  requiredSubscriptionLevel: null,
};

StepThree.propTypes = {
  stepThreeValues: PropTypes.shape({
    subscription: PropTypes.string,
    paymentMethod: PropTypes.string,
    userCompanyPaysForItself: PropTypes.bool,
  }),
  setStepThreeValues: PropTypes.func.isRequired,
  availableSubscriptionLevels: PropTypes.arrayOf(PropTypes.string),
  requiredSubscriptionLevel: PropTypes.string,
};
export default StepThree;

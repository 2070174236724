import { gql } from '@apollo/client';

const GET_SHAREABLE_FILES = gql`
  query GetShareableFiles($folderId: Int!) {
    shareContext {
      getShareableFiles(folderId: $folderId) {
        resourceId
			  readAccess
			  writeAccess
			  name
			  thumbnailUri
      }
    }
  }
`;

export default GET_SHAREABLE_FILES;

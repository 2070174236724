import { gql } from '@apollo/client';

const UPDATE_WORKSPACE_ROLE = gql`
  mutation UpdateWorkspaceRole($siteUser: SiteUserInput) {
    companyAdminContext {
      companyWorkspaces {
        updateSiteUserRole(siteUser: $siteUser) {
          message
          success
          siteUser {
            avatar
            companyName
            email
            isInvitedUser
            lastLoggedIn
            name
            paymentLiability
            siteId
            siteInvitationId
            siteRoleId
            siteRoleName
            userId
          }
        }
      }
    }
  }
`;

export default UPDATE_WORKSPACE_ROLE;

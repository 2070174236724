import { gql } from '@apollo/client';

const GET_COMPANY_SUPPLIER_SETTINGS = gql`
  query GetCompanySupplierSettings($companyId: Int!) {
    companyAdminContext {
      companySuppliers {
        companySupplierSettings(companyId: $companyId) {
          supplierVisibility
          active
          incomingSupplierRequests {
            items {
              requestedAt
              supplierRequestId
              workspace
            }
          }
          siteSupplierFor {
            active
            siteId
            siteName
            id
            deleted
            displayName
            notifyEmail
            supplierUsers {
              companySiteSupplierUserId
              deleteUrl
              username
            }
          }

          formattedSupplierId
          supplierId
        }
      }
    }
  }
`;

export default GET_COMPANY_SUPPLIER_SETTINGS;

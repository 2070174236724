import React from "react";
import PropTypes from "prop-types";
import {
  NewsTemplateOneSkeleton,
  NewsTemplateTwoSkeleton,
} from "../../../Skeleton";
import NewsTemplateOne from "./TemplateOne";
import NewsTemplateTwo from "./TemplateTwo";
// For future carousel purposes
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";

const NewsTemplates = ({
  customStyles,
  newsMain,
  newsSub,
  newsTemplate,
  skeleton,
  title,
  onClickReadMore
}) => {
  return (
    <>
      {newsTemplate === 1 &&
        (skeleton ? (
          <NewsTemplateOneSkeleton />
        ) : (
          <NewsTemplateOne
            customStyles={customStyles}
            newsMain={newsMain[0]}
            newsSub={newsSub}
            title={title}
            onClickReadMore={onClickReadMore}
          />
        ))}

      {newsTemplate === 2 &&
        (skeleton ? (
          <NewsTemplateTwoSkeleton />
        ) : (
          <NewsTemplateTwo
            customStyles={customStyles}
            newsMain={newsMain}
            newsSub={newsSub}
            title={title}
            onClickReadMore={onClickReadMore}
          />
        ))}
    </>
  );
};

NewsTemplates.defaultProps = {
  customStyles: {},
  newsMain: [],
  newsSub: [],
  skeleton: false,
  title: "",
};

NewsTemplates.propTypes = {
  customStyles: PropTypes.shape({}),
  newsMain: PropTypes.arrayOf(PropTypes.shape({})),
  newsSub: PropTypes.arrayOf(PropTypes.shape({})),
  newsTemplate: PropTypes.oneOf([1, 2]).isRequired,
  skeleton: PropTypes.bool,
  title: PropTypes.string,
};

export default NewsTemplates;

import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_FOLDERS } from "../graphql/queries";
import {
  setSiteFolders,
  setSiteFoldersError,
  setSiteFoldersLoading,
} from "../actions";

// eslint-disable-next-line import/prefer-default-export
export const useGetSiteFolders = () => {
  const [getSiteFolders, { data, loading, error }] = useLazyQuery(GET_FOLDERS);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.folders) {
      dispatch(
        setSiteFolders({
          folders: data.folders,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setSiteFoldersLoading({
        loading,
      })
    );
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(
        setSiteFoldersError({
          error,
        })
      );
    }
  }, [error]);

  return { execute: getSiteFolders, data, loading, error };
};

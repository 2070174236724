import { gql } from '@apollo/client';

const getCompaniesLookup = gql`
  query getCompaniesLookup {
    companyAdminContext {
      availableCompanies {
        companyId
        name
      }
    }
  }
`;

export default getCompaniesLookup;

import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  Icon,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import clsx from "clsx";
import UploadBox from "./UploadBox";
import UploadedImagesTable from "./UploadedImagesTable";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #F2F2F2",
    marginBottom: 15,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 5,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  greenIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  switchRoot: {
    display: "block",
    marginRight: 0,
  },
  switchLabel: {
    fontSize: 12,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#F5F5F5",
    height: 2,
  },
  textLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    marginLeft: 20,
    padding: 0,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  textIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    marginRight: 8,
  },
  cssBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  cssIcon: {
    color: "#888",
    fontSize: 30,
    marginRight: 5,
  },
  cssText: {
    fontSize: 12,
    color: "#888",
  },
}));

const HomepageSettings = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          Home page settings
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Typography className={classes.heading}>Home widgets</Typography>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label='Visa texten "Select a category on the left"'
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label="View Top 10 Downloads"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label="View Top 10 News"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label="View Top 10 Ordered Products"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label="Show folders folded"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label="Enable Site Users"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={<Switch name="checkedB" color="primary" />}
              label="Sort folders alphabetically"
              classes={{
                root: classes.switchRoot,
                label: classes.switchLabel,
              }}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid xs={12} md={6}>
            <Typography className={classes.heading}>
              Uploaded CSS file
            </Typography>
          </Grid>
          <Grid xs={12} md={6} align="right">
            <Button className={classes.textLink}>
              <Icon className={clsx(["fab fa-css3-alt", classes.textIcon])} />
              Sample CSS Layout
            </Button>
            <Button className={classes.textLink}>
              <Icon
                className={clsx(["fa fa-question-circle", classes.textIcon])}
              />
              More info
            </Button>
          </Grid>
          <Grid xs={12}>
            <Box mt={1}>
              <Typography className={classes.text}>
                Here you can customize the company style template (CSS). You are
                uploading a file with CSS definitions. The uploaded style
                template applies in front of the company's main template. Just
                upload the CSS properties you want to customize - other CSS
                properties are downloaded from the company's main template
                already in the system.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.cssBox}>
          <Icon className={clsx(["fab fa-css3-alt", classes.cssIcon])} />
          <Typography className={classes.cssText}>
            The company has no css of its own
          </Typography>
        </Box>
        <Grid container spacing={0} alignItems="center">
          <Grid xs={12} md={4}>
            <UploadBox />
          </Grid>
          <Grid xs={12} md={4}>
            <Button className={classes.greenLink}>
              <Icon
                className={clsx(["fas fa-file-import", classes.greenIcon])}
              />
              Import
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Box mb={2}>
          <Typography className={classes.heading}>
            Uploaded images for home and other purposes
          </Typography>
        </Box>
        <Grid container spacing={0} alignItems="top">
          <Grid xs={12} md={4}>
            <UploadBox />
            <Box mt={1}>
              <Typography className={classes.cssText}>
                Allowed files (* .jpg, * .png, * .gif)
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Button className={classes.greenLink}>
              <Icon
                className={clsx(["fas fa-file-import", classes.greenIcon])}
              />
              Import
            </Button>
          </Grid>
        </Grid>
        <UploadedImagesTable />
      </Box>
    </Box>
  );
};

export default HomepageSettings;

import React, { Fragment, Component } from "react";
import { webApiURL } from "../../../services/config";
import PublicHeader from "./PublicHeader";
import PublicNavbar from "../PublicNavbar";

class PublicLayout extends Component {
  render() {
    const companyLogo = `${webApiURL}/CompanyLogo?companyId=1`;

    return (
      <Fragment>
        <PublicHeader companyLogo={companyLogo} />
        <PublicNavbar />
      </Fragment>
    );
  }
}

export default PublicLayout;

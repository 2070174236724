import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid
} from "@mui/material"
import { useEffect, useState } from "react"
import { GrayButton, TextAreaFull, TextField  } from "../../common";

import { useMutation } from "@apollo/client";
import { CREATE_PROJECT, UPDATE_PROJECT } from "../../../graphql/mutations/ProjectEditContext";
import { useSnackbar } from "../../../hooks";
import { cloneDeep } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

const ProjectModal = ({ siteId, project, open, onClose, onSave }) => {

  const initalProject = {
    name: "",
    description: "",
    projectFields: [],
    siteId: siteId,
    //projectViews: []
  };
  const snackbar = useSnackbar();
  const intl = useIntl();
  const [internalProject, setInternalProject] = useState();

  useEffect(() => {
    if (project) {
      setInternalProject(project);
    } else {
      setInternalProject(initalProject)
    }

  }, [project]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  }


  const [createProject, { data: createProjectData, loading: createProjectLoading, error: createProjectError }] = useMutation(CREATE_PROJECT);
  const [updateProject, { data: updateData, loading: loadingData, error: updateError }] = useMutation(UPDATE_PROJECT);

  const handleCreate = () => {

    createProject({ variables: { project: internalProject}} );
    
  }

  const handleUpdate = () => {
    
    var temp = cloneDeep(internalProject);
    delete temp.__typename;    
    delete temp.projectViews;

    temp.projectFields = temp.projectFields.map(n => {
      return { id: n.fieldId, name: n.name, type: n.type, settings: n.jsonFieldSettings };
    });

    updateProject({ variables: { project: temp } });

  }

  useEffect(() => {

    
    if (createProjectData && createProjectData?.projects?.createProject?.success) {
      snackbar.success(<FormattedMessage id="project.created" />);
      onSave?.(createProjectData.projects.createProject.project);


    } else if (createProjectData && !createProjectData?.createProject?.success) {
      snackbar.error(<FormattedMessage id="project.errorSave" />);
    }
    else if (createProjectError) {
      snackbar.error(<FormattedMessage id="project.errorSave" />);
    }

  }, [createProjectData, createProjectError])

  useEffect(() => {
    
    if (updateData && updateData?.projects?.updateProject?.success) {
      snackbar.success(<FormattedMessage id="project.updated" />);
      onSave?.(updateData?.projects?.updateProject?.project);


    } else if (updateData && !!(updateData?.projects?.updateProject?.success)) {
      snackbar.error(<FormattedMessage id="project.errorSave" />);
    }
    else if (updateError) {
      snackbar.error(<FormattedMessage id="project.errorSave" />);
    }

  }, [updateData, createProjectError])

  const onChangeTitle = (value) => {    
    
    setInternalProject({ ...internalProject, name: value });

  }

  const onChangeDescription = (value) => {
    
    setInternalProject({ ...internalProject, description: value });
  }

  return internalProject && (   

    <Dialog open={open} onClose={handleClose} fullWidth={true}
      maxWidth={"sm"}>
      <DialogTitle> {!(internalProject?.projectId) ? (<FormattedMessage id="project.newproject" />) : (<FormattedMessage id="project.editproject" />)}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>   
          <Grid item xs={12 }>
            <TextField value={internalProject.name} onChange={e => onChangeTitle(e.target.value)} label={<FormattedMessage id="project.project" />}/>
          </Grid>
          <Grid item xs={12}>         
            <TextAreaFull
              placeholder={intl.formatMessage({ id: "project.description" })}
              rows="6"
              rowsMax="6"
              label={ <FormattedMessage id="project.description" />}
              name="description"
              value={internalProject.description}
              onChange={e=>onChangeDescription(e.target.value)}
              //customLabelClass={classes.textFieldLabel}
              shrink            />
          </Grid>
      </Grid>

      </DialogContent>
    <DialogActions>      
      <Box align="right"></Box>
        <GrayButton marginRight={8} disabled={false} onClick={handleClose}><FormattedMessage id="btn.cancel" /></GrayButton>

        {!(internalProject?.projectId) && (<Button variant="contained" size="small" disabled={false} onClick={n => handleCreate()}><FormattedMessage id="btn.create" /></Button>)}
        {!!(internalProject?.projectId) && (<Button variant="contained" size="small" disabled={false} onClick={n => handleUpdate()}><FormattedMessage id="btn.save" /></Button>)}      
      
    </DialogActions>
  </Dialog>)
}

export default ProjectModal;
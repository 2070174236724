import { isEmpty, kebabCase, startCase } from "lodash";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import InputColor from "react-input-color";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  Box,
  Checkbox,
  CircularProgress,
  Typography,
  Unstable_Grid2 as Grid
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CREATE_WORKSPACE,
  DELETE_WORKSPACE,
  UPDATE_WORKSPACE,
} from "../../../../graphql/mutations/CompanyAdminContext";
import { PrimaryButton } from "../../../common/Buttons";
import ConfirmationModal from "../../../common/ConfirmationModal";
import CustomSelect from "../../../common/CustomSelect";
import TextAreaFull from "../../../common/FormControl/TextAreaFull";
import NetworkSelector from "../../../common/NetworkSelector";
import CustomTextField from "../../../common/TextField";
import { getCurrentUserId } from "../../../../helpers/selectors";
import MultiColumnSelect from "./MultiColumnSelect";
import {
  WorkspaceIconPicker,
  WorkspaceIconPickerItem,
} from "./WorkspaceIconPicker";

const useStyles = makeStyles(() => ({
  logoWrap: {
    padding: 10,
    backgroundColor: "#F7FCF6",
    borderRadius: 15,
    textAlign: "center",
    height: 160,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  container: {
    marginTop: 10,
  },
  text: {
    fontSize: 12,
  },
  iconsWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },
  iconSelector: {
    "& > div": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
    },
  },
  colorPicker: {
    height: "30px!important",
    marginLeft: 9,
    width: "30px!important",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
  username: {
    fontWeight: 800,
  },
  pickerContainer: {
    position: "relative",
    top: -200,
  },
  networkSelector: {
    "&.MuiButton-root": {
      textTransform: "unset",
      "& .MuiTypography-root": {
        fontSize: 12,
      },
    },
  },
}));

const WorkspaceSettings = ({
  companyId,
  handleClose,
  handleSetDraft,
  isCreate,
  networkSelectorItems,
  refresh,
  usersNetworkId,
  workspaceDetails,
  workspaceOrderGroupCandidates,
  workspaceOwnerCandidates,
  workspaceTemplateCandidates,
}) => {
  const { networkId, orderGroupOwnerName, owner } = workspaceDetails || {};
  const { username } = owner || {};

  const fallbackIcon = "FaPencilRuler";
  const fallbackIconColor = "#212529";
  const initialIcon = "FaBookOpen";
  const initialIconColor = "#662336";

  const alert = useAlert();
  const classes = useStyles();

  const [createWorkspace, { loading: createInProgress }] = useMutation(CREATE_WORKSPACE);
  const [deleteWorkspace, { loading: deleteInProgress }] = useMutation(DELETE_WORKSPACE);
  const [updateWorkspace, { loading: updateInProgress }] = useMutation(UPDATE_WORKSPACE);

  const currentUserId = useSelector(state => getCurrentUserId(state));

  const [color, setColor] = useState(isCreate ? initialIconColor : "");
  const [deleteModal, setDeleteModal] = useState(false);
  const [initialWorkspaceStorage, setInitialWorkspaceStorage] = useState(0);
  const [saving, setSaving] = useState(false);
  const [selectableNetworks, setSelectableNetworks] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(isCreate ? initialIcon : "");
  const [workspaceOrderGroupCandidatesList, setWorkspaceOrderGroupCandidatesList] = useState([]);
  const [workspaceSettings, setWorkspaceSettings] = useState({});

  useEffect(() => {
    const { name, description, icon, iconColor, storage } = workspaceDetails || {};

    var { orderGroupId } = workspaceDetails || {};

    if (Array.isArray(workspaceOrderGroupCandidates)) {
      var listing = workspaceOrderGroupCandidates.map(o => {
        return {
          value: o.orderGroupId,
          label: o.displayName,
        };
      });

      if (
        0 < orderGroupId &&
        -1 ===
        workspaceOrderGroupCandidates.findIndex(
          o => o.orderGroupId === orderGroupId
        )
      ) {
        orderGroupId = 0;
        listing.unshift({ value: orderGroupId, label: orderGroupOwnerName });
      }

      setWorkspaceOrderGroupCandidatesList(listing);
    }

    if (!isCreate && !isEmpty(workspaceDetails)) {
      const { userId: ownerUserId } = owner || {};

      const settings = {
        description,
        name,
        networkId,
        orderGroupId,
        ownerUserId,
        storage,
      };

      setColor(iconColor || fallbackIconColor);
      setInitialWorkspaceStorage(workspaceDetails.storage);

      const newIcon = startCase(icon || fallbackIcon).split(" ").join("");

      setSelectedIcon(newIcon);
      setWorkspaceSettings(settings);
    }
    else if (isCreate) {
      const { name, description, storage, } = workspaceDetails || {};
      const ownerUserId = currentUserId;

      const settings = {
        description,
        name,
        networkId: usersNetworkId,
        orderGroupId,
        ownerUserId,
        storage,
      };

      setWorkspaceSettings(settings);
    }
  }, [isCreate, workspaceDetails, workspaceOrderGroupCandidates]);

  useEffect(() => {
    if (Array.isArray(networkSelectorItems)) {
      const tempNetworks = networkSelectorItems.map(x => {
        return {
          label: x.name,
          value: x.networkId,
        };
      });
      setSelectableNetworks(tempNetworks);
    } else {
      setSelectableNetworks([]);
    }
  }, [networkSelectorItems]);

  const setDraft = () => {
    if (!isCreate) {
      handleSetDraft("workspaceSettings", true);
    }
  };

  const setUnDraft = () => {
    if (!isCreate) {
      handleSetDraft("workspaceSettings", false);
    }
  };

  const handleChangeColor = e => {
    setColor(e.hex);
    if (!!workspaceDetails?.iconColor && workspaceDetails.iconColor !== e.hex) {
      setDraft();
    }
  };

  const handleIconPicker = icon => {
    setSelectedIcon(icon);
    if (!!workspaceDetails?.icon && workspaceDetails.icon !== kebabCase(icon)) {
      setDraft();
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setWorkspaceSettings({ ...workspaceSettings, [name]: value });
    if (workspaceSettings[name] != value) {
      setDraft();
    }
  };

  const handleOpenDeleteModal = siteId => {
    setDeleteModal(true);
  };

  const handleDeleteWorkspace = async () => {
    try {
      const res = await deleteWorkspace({
        variables: {
          companyId,
          siteId: workspaceDetails.siteId,
        },
      });
      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyWorkspaces &&
        res.data.companyAdminContext.companyWorkspaces.deleteWorkspace
      ) {
        const { success } = res.data.companyAdminContext.companyWorkspaces.deleteWorkspace;
        if (success) {
          alert.success(<FormattedMessage id="admin.workspaces.workspaceDeleted" />);
          refresh();
          handleClose();
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
        setDeleteModal(false);
      }
    } catch (err) {
      console.error("err >", err);
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
    }
  };

  const handleSave = async () => {
    const newIcon = kebabCase(selectedIcon);
    setSaving(true);
    if (isCreate) {
      const details = {
        ...workspaceSettings,
        siteId: 0,
        description: workspaceSettings.description ?? "",
        orderGroupId: workspaceSettings.orderGroupId ?? 0,
        icon: newIcon,
        iconColor: color,
      };
      try {
        const res = await createWorkspace({
          variables: {
            companyId,
            details,
          },
        });

        if (
          res &&
          res.data &&
          res.data.companyAdminContext &&
          res.data.companyAdminContext.companyWorkspaces &&
          res.data.companyAdminContext.companyWorkspaces.createWorkspace
        ) {
          const { success } = res.data.companyAdminContext.companyWorkspaces.createWorkspace;
          if (success) {
            alert.success(<FormattedMessage id="admin.workspaces.workspaceCreated" />);
          } else {
            alert.error(<FormattedMessage id="common.genericErrorMessage" />);
          }
          setSaving(false);
        }
      } catch (err) {
        console.error("error >", err);
        alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        setSaving(false);
      }
      handleClose();
    } else {
      const details = {
        ...workspaceDetails,
        ...workspaceSettings,
        icon: newIcon,
        iconColor: color,
      };
      delete details.owner;
      delete details.orderGroupOwnerName;
      try {
        const res = await updateWorkspace({
          variables: {
            details,
          },
        });

        if (
          res &&
          res.data &&
          res.data.companyAdminContext &&
          res.data.companyAdminContext.companyWorkspaces &&
          res.data.companyAdminContext.companyWorkspaces.updateWorkspace
        ) {
          const { success } = res.data.companyAdminContext.companyWorkspaces.updateWorkspace;
          if (success) {
            alert.success(<FormattedMessage id="admin.workspaces.workspaceUpdated" />);
          } else {
            alert.error(<FormattedMessage id="common.genericErrorMessage" />);
          }
          setSaving(false);
        }
      } catch (err) {
        console.error("error >", err);
        alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        setSaving(false);
      }
      setUnDraft();
    }
    refresh();
  };

  function WorkspaceOwnersListing(items) {
    return items.map(o => {
      return {
        value: o.userId,
        label: o.firstname + " " + o.lastname + " (" + o.username + ")",
      };
    });
  }

  function ValidateOwnerCandidateSelection() {
    const selectedUserId = workspaceSettings.ownerUserId;
    const notFound = (workspaceOwnerCandidates.find(x => x.userId === selectedUserId)) === undefined;
    return !notFound;
  }

  const handleIconError = (error) => {
    console.info("** Unhandled icon: ", selectedIcon);
    setSelectedIcon("");
  };

  return (
    <Box>
      {saving && <CircularProgress className={classes.spinner} size={20} />}
      <Grid container spacing={5}>
        <Grid xs={12} md={6} item sx={{ paddingTop: 0 }}>
          <Box className={classes.container}>
            <Typography className={classes.text}>
              <FormattedMessage id="product.name" />&nbsp;*
            </Typography>
            <CustomTextField
              name="name"
              value={workspaceSettings.name}
              onChange={handleChange}
            />
          </Box>

          <Box className={classes.container}>
            <Typography className={classes.text}>
              <FormattedMessage id="product.description" />
            </Typography>
            <TextAreaFull
              name="description"
              value={workspaceSettings.description}
              onChange={handleChange}
            />
          </Box>

          {!isCreate && false && (
            <Box className={classes.container}>
              <Typography className={classes.text}>
                <FormattedMessage id="widget.dashboardTableHeadCellOwner" />
              </Typography>
              <Typography>
                <span className={classes.username}>{username}</span>
              </Typography>
            </Box>
          )}

          {isCreate && (
            <Box className={classes.container}>
              <Typography className={classes.text}>
                <FormattedMessage id="context.siteTemplate" />
              </Typography>
              <MultiColumnSelect
                inputLabel={<FormattedMessage id="common.select" />}
                options={workspaceTemplateCandidates}
                name="workspaceTemplateId"
                value={workspaceSettings.workspaceTemplateId}
                onChange={handleChange}
              />
            </Box>
          )}

          <Box className={classes.container}>
            <Typography className={classes.text}>
              <FormattedMessage id="admin.workspaces.workspaceOwner" />&nbsp;*
            </Typography>
            <CustomSelect
              inputLabel={<FormattedMessage id="common.select" />}
              options={WorkspaceOwnersListing(workspaceOwnerCandidates)}
              name="ownerUserId"
              value={workspaceSettings.ownerUserId}
              onChange={handleChange}
            />
          </Box>

          <Box className={classes.container}>
            <Typography className={classes.text}>
              <Checkbox sx={{ padding: 0, margin: "0px 3px 1px -3px" }} /> Kräv tvåfaktorsauthentisering
            </Typography>
          </Box>

          <Box className={classes.container}>
            <Typography className={classes.text}>
              <FormattedMessage id="admin.workspaces.orderGroup" />
            </Typography>
            <CustomSelect
              inputLabel={<FormattedMessage id="common.select" />}
              options={workspaceOrderGroupCandidatesList}
              name="orderGroupId"
              value={workspaceSettings.orderGroupId}
              onChange={handleChange}
            />
          </Box>

          <Box className={classes.container}>
            <Typography className={classes.text}>
              <FormattedMessage id="admin.workspaces.storage" />&nbsp;*
              {initialWorkspaceStorage > 0 && (
                <span style={{ float: "right" }}>
                  <FormattedMessage id="admin.workspaces.currentStorage" />:&ensp;
                  {initialWorkspaceStorage}&nbsp;GB
                </span>
              )}
            </Typography>
            <CustomSelect
              inputLabel={<FormattedMessage id="common.select" />}
              options={[
                { label: "1 GB", value: 1 },
                { label: "2 GB", value: 2 },
                { label: "4 GB", value: 4 },
                { label: "10 GB", value: 10 },
                { label: "50 GB", value: 50 },
                { label: "100 GB", value: 100 },
                { label: "200 GB", value: 200 },
                { label: "300 GB", value: 300 },
              ]}
              name="storage"
              value={workspaceSettings.storage}
              onChange={handleChange}
            />
          </Box>

          <Box className={classes.container}>
            <Typography className={classes.text}>
              <FormattedMessage id="settings.network" />&nbsp;*
            </Typography>
            <NetworkSelector
              className={classes.networkSelector}
              name="networkId"
              onChange={handleChange}
              options={selectableNetworks}
              value={workspaceSettings.networkId}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6} item>
          <Box className={classes.logoWrap}>
            <WorkspaceIconPickerItem
              color={color}
              containerStyles={{
                display: "flex",
              }}
              icon={selectedIcon}
              onError={handleIconError}
              size={100}
            />
          </Box>
          <Box className={classes.iconsWrapper}>
            <Box>
              <FormattedMessage id="common.icon" />:{" "}
            </Box>
            <Box className={classes.iconSelector}>
              <WorkspaceIconPicker
                buttonIconStyles={{
                  color,
                  display: "flex",
                }}
                buttonStyles={{
                  marginLeft: "12px",
                  minHeight: "40px",
                  minWidth: "40px",
                  width: "unset",
                }}
                containerStyles={{
                  top: -150,
                }}
                onChange={handleIconPicker}
                onError={handleIconError}
                value={selectedIcon}
              />
            </Box>
          </Box>
          <Box className={classes.iconsWrapper}>
            <Box>
              <FormattedMessage id="common.color" />:{" "}
            </Box>
            <InputColor
              className={classes.colorPicker}
              initialValue={color}
              name="background"
              onChange={handleChangeColor}
              placement="right"
            />
          </Box>
          {!isCreate && (
            <Box className={classes.iconsWrapper}>
              <Box>
                <FormattedMessage id="admin.workspace.deleteWorkspace" />:{" "}
              </Box>
              <PrimaryButton
                onClick={handleOpenDeleteModal}
                marginLeft={12}
              >
                <FormattedMessage id="common.delete" />
              </PrimaryButton>
            </Box>
          )}

        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <PrimaryButton
          onClick={handleSave}
          disabled={
            createInProgress ||
            updateInProgress ||
            (workspaceSettings.name ?? "").trim().length === 0 ||
            (workspaceSettings.networkId ?? 0) < 1 ||
            (workspaceSettings.storage ?? 0) === 0 ||
            ValidateOwnerCandidateSelection() === false
          }
        >
          <FormattedMessage id="btn.save" />
        </PrimaryButton>
      </Grid>

      {deleteModal && (
        <ConfirmationModal
          companyId={companyId}
          disableYesButton={deleteInProgress}
          handleClose={() => setDeleteModal(false)}
          message={
            <FormattedMessage id="admin.workspaces.deleteConfirmation" />
          }
          onClick={handleDeleteWorkspace}
          open={deleteModal}
        />
      )}
    </Box>
  );
};

export default WorkspaceSettings;

import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../../../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginBottom: 8,
  },
  colorWrapper: {
    display: "flex",
    alignItems: "center",
  },
  colorPicker: {
    width: "24px!important",
  },
}));

const NewsFilterSettings = ({
  newsFilterSetting,
  onWidgetPropertyUpdate,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const newsOptions = [
    {
      label: "Alla nyheter",// intl.formatMessage({ id: "newsPost.fourSmallNews" }),
      value: 0
    },
    {
      label: "Endast projektnyheter", //intl.formatMessage({ id: "newsPost.sixSmallNews" }),
      value: 1
    },
  ];

  return (
    <>
      <Box className={classes.formWrapper}>
        <Box className={classes.colorWrapper}>
          Vilka nyheter
        </Box>
        <Box className={classes.colorWrapper}>
          <CustomSelect
            onChange={e => onWidgetPropertyUpdate("newsFilterSetting", e.target.value)}
            options={newsOptions}
            value={newsFilterSetting || 0}
          />
        </Box>
      </Box>

    </>
  );
};

export default NewsFilterSettings;

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuTree from "../../../common/Roles/MenuTree";

const useStyles = makeStyles(theme => ({
  contentBody: {
    flex: 1,
    padding: 10,
  },
  menuProvider: {
    display: "inline-block",
    width: "auto",
  },
}));

const MainContent = ({
  handleOnRemoveSortlyMenu,
  handleRenameLabel,
  setSortlyMenu,
  sortlyMenu,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.contentBody}>
      <MenuTree
        onRemoveNode={handleOnRemoveSortlyMenu}
        onRenameNode={handleRenameLabel}
        onUpdateSortlyMenu={newItems => setSortlyMenu(newItems)}
        sortlyMenu={sortlyMenu}
      />
    </Box>
  );
};

MainContent.propTypes = {
  handleOnRemoveSortlyMenu: PropTypes.func.isRequired,
  handleRenameLabel: PropTypes.func.isRequired,
  setSortlyMenu: PropTypes.func.isRequired,
  sortlyMenu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MainContent;

import React from "react";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      //marginLeft: "auto",
    },
    marginRight: "40px",
  },
  buttonLang: {
    padding: "4px 10px 4px 15px",
    lineHeight: 0.5,
    color: "#fff",
    fontWeight: 600,
    fontSize: 11,
    backgroundColor: theme.palette.component.languageBackground,
    minWidth: "unset",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: alpha(theme.palette.component.languageBackground, 0.8),
    },
  },
  caret: {
    width: "13px !important",
    height: "13px !important",
  },
}));

const LanguageSwitcher = props => {
  const { locale, setLocale } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = ({ name }) => {
    if (name) {
      setLocale({ name });
    }
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        data-cy="language-selector"
        onClick={handleClick}
        className={classes.buttonLang}
      >
        {locale.lang}
        <ArrowDropDown className={classes.caret} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-cy="SV-language"
          onClick={() => handleClose({ name: "SV" })}
        >
          SV
        </MenuItem>
        <MenuItem
          data-cy="EN-language"
          onClick={() => handleClose({ name: "EN" })}
        >
          EN
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;

import { handleActions } from "redux-actions";
import { setSearchResults, emptySearchResults } from "../../../../actions";

const defaultState = {
  searchResultsItems: {},
};

export default handleActions(
  {
    [setSearchResults]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        searchResultsItems: items,
      };
    },
    [emptySearchResults]: () => {
      return {
        searchResultsItems: {},
      };
    },
  },
  defaultState
);

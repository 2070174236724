import { gql } from '@apollo/client';
import Task from "../../fragments/Projects/Task";
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPDATE_TASK = gql`
  mutation UpdateTask(
    $updateTaskParams: UpdateTaskParamsInput!
    $task: TaskInput!
  ) {
    projects {
      updateTask(
        updateTaskParams: $updateTaskParams
        task: $task
      ) {
        success
        errorResult { ...ErrorResultFields }
        task { ...Task }
      }
    }
  }
  ${Task}
  ${ErrorResultFragment}
`;

export default UPDATE_TASK;

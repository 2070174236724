import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  skeletonWrap: {
    backgroundColor: "#fff",
    padding: 15,
    marginTop: 10,
    marginBottom: 6,
    display: "flex",
  },
  skeleton: {
    borderRadius: 4,
    marginBottom: 10,
  },
  skeletonTitle: {
    borderRadius: 4,
    marginBottom: 15,
  },
  skeletonLast: {
    borderRadius: 4,
    marginBottom: 0,
  },
  skeletonImg: {
    marginRight: 15,
    borderRadius: 4,
  },
  skeletonWrapMid: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
  },
  skeletonWrapRight: {
    width: "18%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  space: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const ProductViewSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="10%"
          height={190}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Box className={classes.skeletonWrapMid}>
          <Skeleton
            variant="rect"
            width="50%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonTitle }}
          />
          <Skeleton
            variant="rect"
            width="70%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="25%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="23%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="30%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeletonLast }}
          />
        </Box>
        <Box className={classes.skeletonWrapRight}>
          <Skeleton
            variant="rect"
            width="60%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonTitle }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.space} />
          <Skeleton
            variant="rect"
            width="100%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonLast }}
          />
        </Box>
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="10%"
          height={190}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Box className={classes.skeletonWrapMid}>
          <Skeleton
            variant="rect"
            width="50%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonTitle }}
          />
          <Skeleton
            variant="rect"
            width="70%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="25%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="23%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="30%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeletonLast }}
          />
        </Box>
        <Box className={classes.skeletonWrapRight}>
          <Skeleton
            variant="rect"
            width="60%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonTitle }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.space} />
          <Skeleton
            variant="rect"
            width="100%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonLast }}
          />
        </Box>
      </Box>
      <Box className={classes.skeletonWrap}>
        <Skeleton
          variant="rect"
          width="10%"
          height={190}
          animation="wave"
          classes={{ root: classes.skeletonImg }}
        />
        <Box className={classes.skeletonWrapMid}>
          <Skeleton
            variant="rect"
            width="50%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonTitle }}
          />
          <Skeleton
            variant="rect"
            width="70%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="25%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="23%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="30%"
            height={12}
            animation="wave"
            classes={{ root: classes.skeletonLast }}
          />
        </Box>
        <Box className={classes.skeletonWrapRight}>
          <Skeleton
            variant="rect"
            width="60%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonTitle }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="40%"
            height={15}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Box className={classes.space} />
          <Skeleton
            variant="rect"
            width="100%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeleton }}
          />
          <Skeleton
            variant="rect"
            width="100%"
            height={20}
            animation="wave"
            classes={{ root: classes.skeletonLast }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductViewSkeleton;

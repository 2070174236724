import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";
import CompanyRecursiveFragment from "../../../fragments/CompanyRecursive";

const CREATE_NETWORK = gql`
  mutation CreateNetwork(
    $companyId: Int!
    $name: String
    $parentNetworkId: Int!
  ) {
    companyAdminContext {
      companyNetworks {
        createNetwork(
          companyId: $companyId
          name: $name
          parentNetworkId: $parentNetworkId
        ) {
          errorResult {
            ...ErrorResultFields
          }
          result {
            ...CompanyRecursive
            name
            networkId
            parentNetworkId
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${CompanyRecursiveFragment}
`;

export default CREATE_NETWORK;

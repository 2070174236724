import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SupplierHeader from "./SupplierHeader";
import SupplierRow from "./SupplierRow";
import TableRowLoading from "../Table/TableRowLoading";

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #f1f1f1",
    marginTop: 50,
  },
}));

const SuppliersTable = ({ suppliers, setSupplierList, loading }) => {
  const classes = useStyles();
  return (
    <Table className={classes.root}>
      <SupplierHeader />
      <TableBody>
        {loading ? (
          <TableRowLoading />
        ) : (
          <>
            {suppliers.map((x, idx) => {
              return (
                <SupplierRow
                  supplier={x}
                  idx={idx}
                  setSupplierList={setSupplierList}
                />
              );
            })}
          </>
        )}
      </TableBody>
    </Table>
  );
};

SuppliersTable.defaultProps = {
  suppliers: null,
  loading: false,
};

SuppliersTable.propTypes = {
  loading: PropTypes.bool,
  suppliers: PropTypes.arrayOf(PropTypes.shape({})),
  setSupplierList: PropTypes.func.isRequired,
};

export default SuppliersTable;

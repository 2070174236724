import { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EditorContainer from '../../../components/common/EditorContainer/EditorContainer';
import DurationEditor from '../../../components/common/EditorContainer/DurationEditor/DurationEditor';
export default function DurationValueEditor({ value, field, onUpdateTaskValue, enableEdit, sx }) {

  const formatMinutesToInternalTime = (time) => {
    let hours = Math.floor(time / 60) || 0;
    let minutes = time % 60 || 0;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };


  const handleChange = (newValue) => {  

    onUpdateTaskValue(field.fieldId, newValue);
  }

  return (
    <>
      <EditorContainer
        fullWidth
        //multiline={true}
        startIcn={true}
        disabled={false}
        enableEdit={enableEdit}
        onChange={handleChange}
        //placeholder={placeholder}
        //onBlur={handleBlur}
        //onKeyDown={handleOnKeyDown}
        component={DurationEditor}
        value={formatMinutesToInternalTime(value && value.numericValue)}
        sx={{
          color: "rgba(0, 0, 0, 0.87)",
          border: "1px solid rgba(0, 0, 0, 0)",
          borderRadius: "4px",
          fontSize: "13px",
          fontWeight: 400,
          minHeight: "1.5em",
          lineHeight: "22px",
          padding: "4px",
          "&.MuiBox-root": { minHeight: "1.5em", padding: "0px 0px 0px 4px", border: "1px solid rgba(0, 0, 0, 0)", },
          "&.MuiBox-root.editingClass": { minHeight: "1.5em", padding: "0px 0px 0px 4px", border: "1px solid rgba(0, 0, 0, 0.5)", },
          "&.MuiTypography-root": { whiteSpace: "pre-wrap", padding: "0px", border: "1px solid rgba(0, 0, 0, 0)", lineHeight: "22px", minHeight: "1.5em", },
          "&.MuiTypography-root:hover": { backgroundColor: '#f0f0f0', cursor: "text", },
          "&.Mui-focused": { paddingTop: "0px", paddingBottom: "0px", border: "1px solid rgba(0, 0, 0, 0.5)", cursor: "text", },
          "&.MuiInputBase-fullWidth": { padding: "0px", border: "0px" },
          "&.MuiInputBase-root": { padding: "0px", border: "0px", },
          "& .MuiInputBase-input": { padding: "0px", border: "0px", minHeight: "1.5em", lineHeight: "22px", },
          //"&:hover": { backgroundColor: '#f0f0f0', cursor: "text", },
          //"&:focus": {border: "1px solid rgba(0, 0, 0, 0.5)", cursor: "text",},
        }}
      />
    </>
  );

}

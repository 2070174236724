import React, { useState, useEffect, useRef } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TimeDateAvatarRows from './TimeDateAvatarRows';
import EditorContainer from '../EditorContainer';
import DurationEditor from "./DurationEditor";

const DurationPopper = ({ item, onAdd, onDelete, onUpdate, enableEdit, disabled, startIcn, ...props }) => {
  const [value, setValue] = useState(0);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      setAnchorEl(inputRef.current);
    }
  }, [inputRef.current]);




  /*
     const handleAddTimeLog = (task, minutes, date) => {
    onTimeLogAction("add", { taskId, minutes, date });
  }

  const handleDeleteTimeLog = (task, timeLogId) => {
    onTimeLogAction("remove", { taskId, timeLogId })
  }*/

  function timeToMinutes(time) {
    
    const [hours, minutes] = time.split(':');

    // Convert the split values to integers
    const hoursInMinutes = parseInt(hours) * 60;
    const totalMinutes = hoursInMinutes + parseInt(minutes);

    // Return the total minutes
    return totalMinutes;
  }


  const handleAdd = () => {

    onAdd(item, value, new Date());
  };

  const handleUpdate = (task, reportItem, time, date) => {

    //onUpdate(task, item.id, time || formatDuration(item.reportedMinutes), date || item.reportedAt);
    onUpdate(task, reportItem, timeToMinutes(time), date);
  }

  const handleDeleteTimeLog = (id) => {
    onDelete(item, id);
  }


  const formatDuration = (duration) => {

    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (enableEdit && !disabled) {
      setIsPopperOpen(true);
      if (inputRef.current && document.activeElement !== inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleChangeInput = (newValue, reason) => {

    if (reason == "KeyEnter") {
      //onAddTask({ action: TaskAction.INSERT_NEW, task: null, pos: TaskAddPosition.LAST, taskname: newValue });
      onAdd(item, newValue, new Date());
      setValue(0);
    }
    else {
      setValue(newValue);
    }
    
  }

  const handleClickAway = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setIsPopperOpen(false);
  };

  const totalDurationInMinutes = item?.reportedTimeLog ? item.reportedTimeLog.reduce((sum, row) => sum + row.reportedMinutes, 0) : 0;

  const formattedDuration = formatDuration(totalDurationInMinutes);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        onClick={handleClick}
        ref={inputRef}
        sx={{ display: 'flex', alignItems: 'center', ...props.sx, "&.MuiBox-root": { ...(props.sx?.["&.MuiBox-root"] || {}), borderRadius: "4px", paddingLeft: "4px", border: isPopperOpen ? "1px solid rgba(0, 0, 0, 0.5)" : "1px solid rgba(0, 0, 0, 0)", backgroundColor: isPopperOpen ? '#f0f0f0' : 'inherit', }, }}
      >
        {startIcn && (
          <InputAdornment position="start">
            <Box sx={{ display: 'flex', alignItems: 'center', width: "12px" }}>
              <ScheduleIcon sx={{
                opacity: 0.7, fontSize: '16px',
              }} />
            </Box>
          </InputAdornment>)}
        <Typography
          className={props.className}
          onClick={handleClick}
          sx={{ ...props.sx, }} //Settings for component "p" and "InputBase" should show same way
        >
          {formattedDuration}
        </Typography>



        <Popper
          open={isPopperOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                rootBoundary: 'viewport',
              },
            },
          ]}
          style={{ zIndex: 1300 }}  // Ensure popper is above other elements
        >
          <Paper>
            <Box
              sx={{
                background: '#fcfcfc',
                boxShadow: 1,
                padding: "8px 16px 8px 16px",
                width: 265,
              }}
            >
              <Box
                key="FirstRow"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <EditorContainer
                  //fullWidth
                  component={DurationEditor}
                  enableEdit={enableEdit}
                  value={formatDuration(value)}
                  onChange={handleChangeInput}
                  style={{ cursor: "text", }}
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                    border: "1px solid rgba(0, 0, 0, 0)",
                    borderRadius: "4px",
                    fontSize: "13px",
                    fontWeight: 400,
                    minHeight: "1.5em",
                    lineHeight: "22px",
                    textAlign: 'left',
                    width: "60px",
                    "&.MuiBox-root": { minHeight: "1.5em", lineHeight: "22px", padding: "0px 4px 0px 4px", border: "1px solid rgba(0, 0, 0, 0.2)", background: "white", },
                    "&.MuiBox-root:hover": { minHeight: "1.5em", lineHeight: "22px", padding: "0px 4px 0px 4px", border: "1px solid rgba(0, 0, 0, 0.3)", backgroundColor: '#fcfcfc', cursor: "text", },
                    "&.MuiBox-root.editingClass": { minHeight: "1.5em", padding: "0px 4px 0px 4px", border: "1px solid rgba(0, 0, 0, 0.5)", },
                    "&.MuiTypography-root": { minHeight: "1.5em", lineHeight: "22px", whiteSpace: "pre-wrap", padding: "0px", },
                    "&.MuiInputBase-fullWidth": { minHeight: "1.5em", lineHeight: "22px", padding: "2px 0px 2px 0px", border: "0px", },
                    "&.MuiInputBase-root": { minHeight: "1.5em", lineHeight: "22px", padding: "2px 0px 2px 1px", border: "0px", },
                    "& .MuiInputBase-input": { minHeight: "1.5em", lineHeight: "22px", padding: "0px", border: "0px", },
                  }}
                />
                <Box sx={{ flex: "auto", flexGrow: 1, textAlign: 'left', mr: 1, }}>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => handleAdd()}
                    sx={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: "13px",
                      fontWeight: "500",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      marginLeft: "4px",
                      textTransform: "none",
                      borderRadius: "4px",
                      '.MuiButton-startIcon': {
                        marginRight: '4px',
                      },
                    }} startIcon={<AddCircleOutlineIcon sx={{ marginRight: "0px", color: 'rgba(0, 0, 0, 0.87)', }} />}>Lägg till</Button>
                </Box>
              </Box>
            </Box>

            <TimeDateAvatarRows
              task={item}
              data={item.reportedTimeLog}
              onDelete={handleDeleteTimeLog}
              onUpdate={handleUpdate}
              enableEdit={enableEdit }
            />
          </Paper>
        </Popper>
      </Box>

    </ClickAwayListener>
  );
};

export default DurationPopper;

DurationPopper.defaultProps = {
  enableEdit: false,
  disabled: false,
  startIcn: false,
};
import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { CircularProgress, Box } from "@mui/material";
import Table from "@mui/material/Table";
import OneStreamTableItem from "./OneStreamTableItem";
import OneStreamTableHead from "./OneStreamTableHead";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
  spinner: {
    marginTop: 20,
    color: theme.palette.primary.main,
  },
}));

const OneStreamTable = ({ updateSort, sortField, ascending }) => {
  const classes = useStyles();

  const jobs = useSelector(state => state.api.oneStream.oneStreamJobs.jobs);
  const loading = useSelector(
    state => state.api.oneStream.oneStreamJobs.loading
  );

  const handleOnHeaderClick = header => {
    if (header === sortField) {
      updateSort(header, !ascending);
    } else {
      updateSort(header, ascending);
    }
  };

  return (
    <div className={classes.root}>
      <Table size="small">
        <OneStreamTableHead
          sortField={sortField}
          ascending={ascending}
          onHeaderClick={handleOnHeaderClick}
        />
        {loading ? (
          <Box>
            <CircularProgress className={classes.spinner} size={20} />
          </Box>
        ) : (
          <>
            {jobs.map((job, idx) => {
              return (
                <OneStreamTableItem
                  key={job.jobGuid}
                  job={job}
                  idx={idx}
                  jobs={jobs}
                />
              );
            })}
          </>
        )}
      </Table>
    </div>
  );
};

export default OneStreamTable;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";
import OrderGroup from "../fragments/OrderGroup";

const ADD_ONE_STREAM_TO_CART = gql`
  mutation AddOneStreamToCart(
    $ordergroupId: Int!
    $siteId: Int!
    $oneStreamJobId: ID!
  ) {
    shoppingCartContext {
      addOneStreamToCart(
        ordergroupId: $ordergroupId
        siteId: $siteId
        oneStreamJobId: $oneStreamJobId
      ) {
        errorResult {
          ...ErrorResultFields
        }
        result {
          groups {
            ...OrderGroupFragment
          }
        }
        success
      }
    }
  }
  ${OrderGroup}
  ${ErrorResultFragment}
`;

export default ADD_ONE_STREAM_TO_CART;

import React from "react";
import PropTypes from "prop-types";
import { TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: 5,
    lineHeight: "normal",
    "& .MuiTableSortLabel-icon": {
      width: "14px !important",
      marginLeft: 2,
    },
  },
}));

const TableHeaderCell = ({ children }) => {
  const classes = useStyles();

  return <TableCell className={classes.td}>{children}</TableCell>;
};

TableHeaderCell.defaultProps = {};

TableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableHeaderCell;

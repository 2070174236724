import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const h6Typo = { fontSize: "16px", fontWeight: 500, };
const body1Typo = { fontSize: "12px", lineHeight: "18px", textTransform: "none", paddingBottom: "10px" };


const ProjectOverview = () => {
  return (
    <Box >
      <Paper component="main" elevation={1} sx={{ flexGrow: 1, p: 2, justifyContent: 'flex-end', backgroundColor: "#ffffff", borderRadius: "4px", }}>
        <Typography variant="h6" sx={h6Typo}>Vad omfattar införandeprojektet</Typography>
        <Typography variant="body1" sx={body1Typo}>Införandeprojektet omfattar att införa den nya plattformen Wilma i Kommunals organisation. Teammedlemmar på förbundskontoret, på avdelningar och på sektioner ska kunna logga in i plattformen och samarbeta effektivt. Alla måste erbjudas utbildning.</Typography>
        <Typography variant="h6" sx={h6Typo}>Vad är Wilma?</Typography>
        <Typography variant="body1" sx={body1Typo}>Wilma är det innovativa IT-systemet som förenklar Kommunals processer – en molnbaserad plattform som skräddarsytts så att Kommunals teammedlemmar kan samarbeta mer effektivt.</Typography>
        <Typography variant="h6" sx={h6Typo}>Bakgrunden till införandeprojektet</Typography>
        <Typography variant="body1" sx={body1Typo}>Kommunal med samarbetspartners har använt Wilma sedan 2013 för att effektivisera flera av sina affärsprocesser. Nu har Wilma byggts om med ännu fler inovativa funktioner och med ett nytt grässnitt. Systemet är nu redo att ”rullas ut” och därför har ett införandeprojekt skapats.</Typography>
        <Typography variant="h6" sx={h6Typo}>Effektivare samarbete för alla</Typography>
        <Typography variant="body1" sx={body1Typo}>Genom flera smarta funktioner så förenklas Kommunals marknadsföringsprocess. Reklambyråer och kommunikationsenheten kan lagra och söka bland digitala tryckoriginal. Utskriftsprocessen är automatiserad mot flera tryckerier, och beställningar skickas automatiskt till rätt tryckeri. Wilma hanterar beställningar till flera aktörer för t.ex. för mässmaterial, flaggor, fanor, kontorstrycksaker, presentreklam mm. Lagerhanteringen effektiviseras genom att Wilma skapar plocklistor och hanterar vart i lagret alla Kommununals produkter återfinns. Funktionerna för projektledning har förbättrats så att teammedlemmar förväntas spara tusentals timmar genom effektivare projektledning. Bildbanken hanterar digital samtyckesavtal och inköpsprocesserna optimeras i Wilma. Oavsett om du tillhör Kommunal, är en fotograf, jobbar på en reklambyrå eller tryckeri så har du nytta av Wilmas funktioner som är öppet dygnet runt i den onlinebaserade plattformen.</Typography>
      </Paper>
    </Box>
  );
};
export default ProjectOverview;
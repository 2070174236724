import { ListItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import AbcIcon from '@mui/icons-material/Abc';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const EditColumn = ({ field, onClick, isActiveEdit, IconComponent }) => {
  return (
    <ListItem
      secondaryAction={
        !isActiveEdit && (
        <IconButton edge="end" aria-label="edit" onClick={onClick}>
           <EditOutlinedIcon />
          </IconButton>)
      }
    >
      <ListItemIcon style={{ cursor: 'grab' }} >
        <DragIndicatorIcon />
        <IconComponent />
      </ListItemIcon>
      {field.name}
    </ListItem>
  );
};

const StringEditColumn = (props) => <EditColumn {...props} IconComponent={AbcIcon} />;
const NumberEditColumn = (props) => <EditColumn {...props} IconComponent={CalculateIcon} />;
const DateEditColumn = (props) => <EditColumn {...props} IconComponent={CalendarMonthIcon} />;
const StatusEditColumn = (props) => <EditColumn {...props} IconComponent={DoneAllIcon} />;
const TimeReportEditColumn = (props) => <EditColumn {...props} IconComponent={ScheduleIcon} />;

export { StringEditColumn, NumberEditColumn, DateEditColumn, StatusEditColumn, TimeReportEditColumn };
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import InputField from "./FormControl/InputField";
import SelectField from "./FormControl/SelectField";
import ImageField from "./DynamicFieldsComponents/ImageField";
import TextAreaField from "./DynamicFieldsComponents/TextAreaField";
import CheckboxField from "./DynamicFieldsComponents/CheckboxField";

const DynamicField = ({
  field,
  error,
  formValues,
  handleChange,
  productId,
  setFormValues,
  handleCheckbox,
}) => {
  const intl = useIntl();
  const [textList, setTextList] = useState([]);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const { imageList, textList, fieldType } = field;

    if (fieldType === "DROPDOWN_TEXT") {
      const placeholder = [
        {
          label: intl.formatMessage({ id: "product.noOptions" }),
          value: "placeholder",
          disabled: true,
        },
      ];
      if (
        textList &&
        Array.isArray(textList.items) &&
        textList.items.length > 0
      ) {
        setTextList(textList.items);
      } else {
        setTextList(placeholder);
      }
    }

    if (fieldType === "DROPDOWN_IMAGE") {
      const placeholder = [
        {
          label: intl.formatMessage({ id: "product.noOptions" }),
          value: "placeholder",
          disabled: true,
        },
      ];
      if (
        imageList &&
        Array.isArray(imageList.items) &&
        imageList.items.length > 0
      ) {
        setImageList(imageList.items);
      } else {
        setImageList(placeholder);
      }
    }
  }, [field]);

  if (field.fieldType === "TEXT") {
    return (
      <InputField
        key={field.fieldName}
        value={formValues[field.fieldName] || ""}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        displayName={field.displayName}
        description={field.description}
        isRequired={field.required}
        handleChange={handleChange}
      />
    );
  }
  if (field.fieldType === "DROPDOWN_TEXT") {
    return (
      <SelectField
        key={field.fieldName}
        value={formValues[field.fieldName]}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        displayName={field.displayName}
        description={field.description}
        options={textList}
        isRequired={field.required}
        onChangeValue={handleChange}
      />
    );
  }
  if (field.fieldType === "DROPDOWN_IMAGE") {
    return (
      <SelectField
        key={field.fieldName === "" ? field.displayName : field.fieldName}
        value={formValues[field.fieldName]}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        displayName={field.displayName}
        description={field.description}
        options={imageList}
        isRequired={field.required}
        onChangeValue={handleChange}
      />
    );
  }

  if (field.fieldType === "IMAGE") {
    return (
      <ImageField
        imageFolderid={field.imageFolderId}
        productId={productId}
        setFormValues={setFormValues}
        formValues={formValues}
        fieldName={field.fieldName}
        error={error[field.fieldName]}
      />
    );
  }

  if (field.fieldType === "TEXT_AREA") {
    return (
      <TextAreaField
        key={field.fieldName}
        value={formValues[field.fieldName]}
        error={error[field.fieldName]}
        fieldName={field.fieldName}
        displayName={field.displayName}
        description={field.description}
        isRequired={field.required}
        handleChange={handleChange}
      />
    );
  }

  if (field.fieldType === "CHECKBOX") {
    return (
      <CheckboxField
        value={formValues[field.fieldName]}
        fieldName={field.fieldName}
        displayName={field.displayName}
        handleChange={handleCheckbox}
      />
    );
  }

  return null;
};

DynamicField.defaultProps = {
  error: {},
};

DynamicField.propTypes = {
  error: PropTypes.objectOf(
    PropTypes.shape({
      error: PropTypes.bool,
    })
  ),
  field: PropTypes.shape({
    imageFolderId: PropTypes.number,
    displayName: PropTypes.string,
    description: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    textList: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
        })
      ).isRequired,
    }),
    imageList: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
          label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
        })
      ).isRequired,
    }),
    required: PropTypes.bool,
  }).isRequired,
  formValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default DynamicField;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_ACCOUNT_SETTINGS = gql`
  mutation userAccountContext($model: UpdateAccountSettingsInput) {
    userAccountContext {
      updateAccountSettings(model: $model) {
        addressChangeRequestSent
        addressesUpdated        
        success
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_ACCOUNT_SETTINGS;

import { gql } from '@apollo/client';
import ShareUserLookupItemFragment from "./ShareUserLookupItemDTO";

const ShareListFragment = gql`
  fragment ShareListFragment on ShareListDTO {
    shareId
    shareDate
    type
    token
    description
    availableFrom
    availableUntil
    user {
      ...ShareUserLookupItemFragment
    }
    recipients {
      ...ShareUserLookupItemFragment
    }
    __typename
  }
  ${ShareUserLookupItemFragment}
`;

export default ShareListFragment;

import React from "react";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { Grid, Box, Icon } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FormattedMessage } from "react-intl";
import AddressHeaderTitle from "../../../common/AddressHeaderTitle";
import CustomTextField from "../../../common/TextField";

const useStyles = makeStyles(() => ({
  fieldIcon: {
    fontSize: 16,
    width: 20,
    height: 20,
  },
  blueIcon: {
    fontSize: 16,
    width: 20,
    height: 20,
    color: "#6865ae",
  },
}));

const Address = ({ name, label, fields, fieldValues, handleChange }) => {
  const classes = useStyles();
  const intl = useIntl();
  const renderFieldIcon = fieldType => {
    switch (fieldType) {
      case "DONT_CARE":
        return <Icon className={clsx(["fas fa-minus", classes.fieldIcon])} />;

      case "HIDDEN":
        return (
          <Icon className={clsx(["fas fa-eye-slash", classes.fieldIcon])} />
        );

      case "FIXED":
        return (
          <Icon className={clsx(["fas fa-toggle-on", classes.fieldIcon])} />
        );

      case "OPTIONAL":
        return <Icon className={clsx(["far fa-square", classes.fieldIcon])} />;

      case "REQUIRED":
        return (
          <Icon className={clsx(["far fa-check-square", classes.fieldIcon])} />
        );

      default:
        return <VisibilityIcon />;
    }
  };

  const renderBlueIcon = (fieldName, placeholder) => {
    if (!fieldValues[name][fieldName] && placeholder) {
      return <Icon className={clsx(["fas fa-link", classes.blueIcon])} />;
    }
  };

  const renderIcon = (fieldName, placeholder, type) => {
    return (
      <Box>
        {renderBlueIcon(fieldName, placeholder)}
        {renderFieldIcon(type)}
      </Box>
    );
  };

  const renderDefaultLabel = fieldName => {
    return intl.formatMessage({ id: `addresses.defaultLabel.${fieldName}` });
  };

  return (
    <Grid item xs={12} md={3}>
      <AddressHeaderTitle>
        <FormattedMessage id={label} />
      </AddressHeaderTitle>

      {fields.map(field => {
        if (field.type === "HIDDEN") {
          return null;
        }

        const defaultLabel = renderDefaultLabel(field.name);

        return (
          <CustomTextField
            shrink={true}
            name={field.name}
            label={field.label || defaultLabel}
            placeholder={field.placeholder}
            value={fieldValues[name][field.name]}
            onChange={e => handleChange(name, e)}
            endAdornment={renderIcon(field.name, field.placeholder, field.type)}
          />
        );
      })}
    </Grid>
  );
};

export default Address;

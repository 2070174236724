import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Box, Grid } from "@mui/material";
import DynamicField from "../../../../../DynamicField";
import PreviewTemplate from "./PreviewTemplate";
import SelectPreview from "./SelectPreview";

const useStyles = makeStyles(() => ({
  fieldsContainer: {
    padding: 10,
  },
  imagePreview: {
    display: "flex",
    padding: "20px",
    overflow: "auto",
    position: "relative",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F4F3EF",
  },
}));

const Preview = ({ fields, resourceId }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [formValues, setFormValues] = useState({});
  const [usePreview, setUsePreview] = useState(false);

  const handleChange = event => {
    event.persist();
    const key = event.target.name;
    const val = event.target.value;
    setFormValues({ ...formValues, [key]: val });
  };

  const handleCheckbox = e => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };

  const options = [
    {
      label: intl.formatMessage({ id: "editProduct.showOptional" }),
      value: true,
    },
    {
      label: intl.formatMessage({ id: "editProduct.showOriginal" }),
      value: false,
    },
  ];

  return (
    <Box>
      <Grid container>
        <Grid item xs={4} className={classes.fieldsContainer}>
          <SelectPreview
            options={options}
            value={usePreview}
            handleChange={e => setUsePreview(e.target.value)}
          />
          {fields.map(field => {
            return (
              <DynamicField
                field={field}
                productId={resourceId}
                formValues={formValues}
                setFormValues={setFormValues}
                handleChange={handleChange}
                handleCheckbox={handleCheckbox}
              />
            );
          })}
        </Grid>

        <Grid item xs={8} className={classes.imagePreview}>
          <PreviewTemplate
            formValues={formValues}
            productId={resourceId}
            usePreview={usePreview}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

Preview.defaultProps = {
  fields: [],
  resourceId: null,
};

Preview.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  resourceId: PropTypes.string,
};

export default Preview;

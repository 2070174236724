import { handleActions } from "redux-actions";
import {
  selectWorkspace,
  expandItem,
  updateWorkspaceRoleRights,
} from "../../../actions";

const defaultState = {
  workspace: {
    id: null,
    name: null,
    siteRoleRights: null,
    defaultView: null,
    siteRoleId: null,
  },
  sublistId: null,
  expand: false,
};

export default handleActions(
  {
    [selectWorkspace]: (state, { payload }) => {
      const { id, name, siteRoleRights, defaultViewType, siteRoleId } = payload;
      localStorage.setItem("selected_workspace", JSON.stringify(payload));
      return {
        ...state,
        workspace: {
          id,
          name,
          siteRoleRights,
          defaultView: defaultViewType,
          siteRoleId,
        },
      };
    },
    [expandItem]: (state, { payload }) => {
      const { id } = payload;
      return {
        ...state,
        sublistId: id,
        expand: state.sublistId !== id ? true : !state.expand,
      };
    },
    [updateWorkspaceRoleRights]: (state, { payload }) => {
      const { items } = payload;
      const tempValue = { ...state.workspace, siteRoleRights: items };
      localStorage.setItem("selected_workspace", JSON.stringify(tempValue));

      return {
        ...state,
        workspace: {
          ...state.workspace,
          siteRoleRights: items,
        },
      };
    },
  },
  defaultState
);

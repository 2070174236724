import { gql } from '@apollo/client';
import AddressFragment from "../fragments/Address";

const getShoppingCartView = gql`
  query GetShoppingCartView($siteId: Int!) {
    shoppingCartView(siteId: $siteId) {
      costPlace1Label
      costPlace2Label
      costPlace3Label
      earliestDeliveryDate
      showCostPlace1
      showCostPlace2
      showCostPlace3
      showOrderConfirmationEmail
      showDesiredDeliveryDaySelector
      showTermsAndConditionsCheckbox
      thankYouMessage
      shoppingCartAddresses {
        addressCombinations {
          deliveryAddress {
            ...AddressFields
          }
          invoiceAddress {
            ...AddressFields
          }
          label
          postalAddress {
            ...AddressFields
          }
        }
      }
    }
    shoppingCartSettings(siteId: $siteId) {
      ...ShoppingCartSettingsFields
    }
  }

  fragment ShoppingCartSettingsFields on ShoppingCartSettings {
    deliveryAddressSettings {
      ...AddressSettingsFields
    }
    invoiceAddressSettings {
      ...AddressSettingsFields
    }
    postalAddressSettings {
      ...AddressSettingsFields
    }
  }
  fragment AddressSettingsFields on AddressSettings {
    address1 {
      ...AddressSettingFields
    }
    address2 {
      ...AddressSettingFields
    }
    address3 {
      ...AddressSettingFields
    }
    address4 {
      ...AddressSettingFields
    }
    address5 {
      ...AddressSettingFields
    }
    city {
      ...AddressSettingFields
    }
    contact {
      ...AddressSettingFields
    }
    country {
      ...AddressSettingFields
    }
    customerId {
      ...AddressSettingFields
    }
    email {
      ...AddressSettingFields
    }
    reference {
      ...AddressSettingFields
    }
    telephone {
      ...AddressSettingFields
    }
    zip {
      ...AddressSettingFields
    }
  }
  fragment AddressSettingFields on AddressSetting {
    displayOrder
    label
    type
  }
  ${AddressFragment}
`;

export default getShoppingCartView;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useIntl, FormattedMessage } from "react-intl";
import { Box, Typography, Grid } from "@mui/material";
import InputColor from "react-input-color";
import CustomSelect from "../../../../common/CustomSelect";
import { PrimaryButton } from "../../../../common/Buttons";
import TreeNode from "./TreeNodeColorPreview";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 10,
  },
  text: {
    fontSize: 12,
  },
  colorPicker: {
    height: "30px!important",
    width: "30px!important",
    marginRight: 7,
  },
}));

const ViewSettings = ({ handleSetDraft }) => {
  const classes = useStyles();
  const intl = useIntl();
  const options = [
    { label: intl.formatMessage({ id: "btn.yes" }), value: 0 },
    { label: intl.formatMessage({ id: "btn.no" }), value: 1 },
    { label: intl.formatMessage({ id: "sites.letUserDecide" }), value: 2 },
  ];

  const [vat, setVat] = useState(null);
  const [categoryColors, setCategoryColors] = useState({
    color1: "#662336",
    color2: "#662336",
    color3: "#662336",
    color4: "#662336",
  });

  const handleChangeColor = (name, color) => {
    setCategoryColors({ ...categoryColors, [name]: color });
  };

  const setDraft = () => {
    handleSetDraft("viewSettings", true);
  };

  const setUnDraft = () => {
    handleSetDraft("viewSettings", false);
  };

  const handleSelect = value => {
    setVat(value);
    setDraft();
  };

  const dummy = val => {};

  var folderDummyData = {
    folderId: 1,
    name: "Nivå 1",
    hasChildFolders: true,
    childFolders: [
      {
        folderId: 2,
        name: "Nivå 2",
        hasChildFolders: true,
        childFolders: [
          {
            folderId: 3,
            name: "Nivå 3",
            hasChildFolders: true,
            childFolders: [
              {
                folderId: 4,
                name: "Nivå 4",
                hasChildFolders: true,
                childFolders: [],
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Box>
            <CustomSelect
              inputLabel={intl.formatMessage({ id: "sites.showPricesWithVat" })}
              options={options}
              value={vat}
              onChange={e => handleSelect(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={11} md={5}>
          <Typography>
            <FormattedMessage id="sites.categoryColor" />
          </Typography>
          <TreeNode
            data={folderDummyData}
            siteFolderTreeColors={[
              categoryColors.color1,
              categoryColors.color2,
              categoryColors.color3,
              categoryColors.color4,
            ]}
            setFolderId={dummy}
            handleOpenNode={dummy}
            selectedFolderId={1}
            openedNodes={[]}
          />
        </Grid>
        <Grid item xs={1} md={1}>
          <Box className={classes.container}>
            <InputColor
              initialValue={categoryColors.color1}
              onChange={e => handleChangeColor("color1", e.hex)}
              placement="right"
              className={classes.colorPicker}
            />

            <InputColor
              initialValue={categoryColors.color2}
              onChange={e => handleChangeColor("color2", e.hex)}
              placement="right"
              className={classes.colorPicker}
            />

            <InputColor
              initialValue={categoryColors.color3}
              onChange={e => handleChangeColor("color3", e.hex)}
              placement="right"
              className={classes.colorPicker}
            />

            <InputColor
              initialValue={categoryColors.color4}
              onChange={e => handleChangeColor("color4", e.hex)}
              placement="right"
              className={classes.colorPicker}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <PrimaryButton onClick={setUnDraft}>
          <FormattedMessage id="btn.save" />
        </PrimaryButton>
      </Grid>
    </Box>
  );
};

export default ViewSettings;

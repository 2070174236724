import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Button, Icon } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import CustomSelect from "../../common/CustomSelect";
import OtherSettings from "./OtherSettings";
import AddressDisplay from "./AddressDisplay";
import Reservations from "./Reservations";
import VATRates from "./VATRates";
import Coupling from "./Coupling";
import ChangeAddressModal from "./ChangeAddressModal";
import ImportModal from "./ImportModal";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
    marginBottom: 30,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    marginBottom: 10,
    border: "1px solid #e6e6e6",
    "& .MuiInputBase-root": {
      marginTop: 5,
    },
    "& .MuiTextField-root": {
      marginTop: 0,
    },
    "& .MuiCheckbox-root": {
      padding: "4px 9px",
    },
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  labelWrap: {
    display: "flex",
    alignItems: "center",
    "& .MuiTextField-root": {
      width: "auto",
      marginLeft: 15,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
  },
  subLabel: {
    fontSize: 12,
    marginLeft: "auto",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  grayLink: {
    fontSize: 12,
    color: "#555",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    marginLeft: 20,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  blueLink: {
    fontSize: 12,
    color: theme.palette.common.link,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  child: {
    paddingLeft: 27,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
  btnSave: {
    padding: "10px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
}));

const CartSettings = () => {
  const classes = useStyles();

  const [openChangeAddress, setOpenChangeAddress] = useState(false);
  const handleOpenChangeAddress = () => {
    setOpenChangeAddress(true);
  };
  const handleCloseChangeAddress = () => {
    setOpenChangeAddress(false);
  };

  const [openImport, setOpenImport] = useState(false);
  const handleOpenImport = () => {
    setOpenImport(true);
  };
  const handleCloseImport = () => {
    setOpenImport(false);
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.head}>
          <Typography className={classes.headTitle}>Shopping cart</Typography>
        </Box>
        <Box className={classes.body}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.card}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.heading}>Address</Typography>
                  </Grid>
                  <Grid xs={12} md={6} align="right">
                    <Button
                      className={classes.greenLink}
                      onClick={handleOpenImport}
                    >
                      <Icon
                        className={clsx(["fas fa-file-import", classes.icon])}
                      />
                      Import
                    </Button>
                    <Button className={classes.greenLink}>
                      <Icon
                        className={clsx(["fas fa-file-export", classes.icon])}
                      />
                      Export
                    </Button>
                    <Button
                      className={classes.grayLink}
                      onClick={handleOpenChangeAddress}
                    >
                      <Icon
                        className={clsx(["fas fa-pencil-alt", classes.icon])}
                      />
                      Change
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={2} mb={1} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.label}>Business</Typography>
                    <CustomSelect options={[{ label: "Default", value: 1 }]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.label}>User</Typography>
                    <CustomSelect options={[{ label: "Default", value: 1 }]} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={classes.label}>
                      Address list type
                    </Typography>
                    <CustomSelect options={[{ label: "Default", value: 1 }]} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <OtherSettings />
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <AddressDisplay />
            </Grid>
            <Grid item xs={12} md={5}>
              <Reservations />
              <VATRates />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.foot} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.saveChanges" />
          </Button>
        </Box>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.head}>
          <Typography className={classes.headTitle}>
            Back-office CLUTCH
          </Typography>
        </Box>
        <Box className={classes.body}>
          <Coupling />
        </Box>
        <Box className={classes.foot} align="right">
          <Button className={classes.btnSave}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.saveChanges" />
          </Button>
        </Box>
      </Box>
      <ChangeAddressModal
        open={openChangeAddress}
        handleClose={handleCloseChangeAddress}
      />
      <ImportModal open={openImport} handleClose={handleCloseImport} />
    </>
  );
};

export default CartSettings;

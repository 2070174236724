import React from "react";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles(() => ({
  skeleton: {
    borderRadius: 2,
    marginBottom: 10,
    textAlign: "center",
  },
}));

const ChannelsSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      {[1, 2, 3, 4, 5].map(x => (
        <Skeleton
          key={x}
          variant="rect"
          width="100%"
          height={28}
          animation="wave"
          classes={{ root: classes.skeleton }}
        />
      ))}
    </>
  );
};

export default ChannelsSkeleton;

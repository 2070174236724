import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Paper } from "@mui/material";
import TableHeader from "./TableHeader";
import VersionTableRows from "./VersionTableRows";

const useStyles = makeStyles(() => ({
  root: {
    overflowX: "auto",
    marginTop: 15,
  },
}));

const VersionHistoryTable = ({
  locked,
  items,
  onDownloadVersion,
  refetchVersionHistory,
}) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <div className={classes.root}>
        <Table size="small">
          <TableHeader locked={locked} onDownloadVersion={onDownloadVersion} />
          <TableBody>
            <VersionTableRows
              items={items}
              onDownloadVersion={onDownloadVersion}
              locked={locked}
              refetchVersionHistory={refetchVersionHistory}
            />
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

VersionHistoryTable.defaultProps = {
  locked: false,
};

VersionHistoryTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDownloadVersion: PropTypes.func.isRequired,
  refetchVersionHistory: PropTypes.func.isRequired,
  locked: PropTypes.bool,
};

export default VersionHistoryTable;

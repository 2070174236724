import { gql } from '@apollo/client';

const ResourceAssignment = gql`
  fragment ResourceAssignment on ResourceAssignment {
    #__typename # ResourceAssignment:ProjectUser

    userId
    username
    firstname
    lastname
    avatar
  }
`;

export default ResourceAssignment;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles(() => ({
  addRecipientSpan: { display: "flex", alignItems: "center" },
  addRecipientLabel: {
    fontSize: 10,
    color: "#3d7e90",
    marginLeft: 5,
  },
  addIcon: {
    color: "#3d7e90",
    width: "16px !important",
    height: "16px !important",
  },
}));

const AddRecipientTabLabel = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <span className={classes.addRecipientSpan}>
      <AddIcon className={classes.addIcon} />
      <span className={classes.addRecipientLabel}>
        {intl.formatMessage({
          id: "monitoring.newRecipient",
        })}
      </span>
    </span>
  );
};

AddRecipientTabLabel.propTypes = {};

export default AddRecipientTabLabel;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import SelectFileFromSystemModal from "./SelectFileFromSystemModal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Chip } from "../common";
import { FormattedMessage } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import DropzoneComponent from "react-dropzone-component";
import "../../../node_modules/react-dropzone-component/styles/filepicker.css";
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";
import ImagePlaceholder from "../../assets/img/placeholder.jpg";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const useStyles = makeStyles(theme => ({
  dzWrap: {
    "& .dropzone": {
      backgroundColor: theme.palette.component.fileUploadBackground,
      padding: "0 20px",
      border: "2px dashed #ddd",
      borderRadius: 4,
    },
    "& .uq-dz-message": {
      color: theme.palette.component.productBoxColor,
      fontFamily: '"Montserrat", "Helvetica Neue", "Arial", sans-serif',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
      pointerEvents: "none",
      cursor: "pointer",
      margin: "2em 0",
    },
    "& .uq-dz-message button *": {
      cursor: "pointer",
      pointerEvents: "all",
    },
    "& .dz-message": {
      display: "none",
    },
    "& .dz-image": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  uqDzDisabled: {
    "& *": {
      pointerEvents: "none !important",
    },
  },
  uqDzCompactDesign: {
    "&": {
      display: "inline-block",
    },
    "& .dropzone": {
      border: "0 none",
      minWidth: 0,
      minHeight: 0,
      padding: 0,
    },
    "& .dropzone .uq-dz-message": {
      margin: 0,
    },
  },
  //chip: {
  //  margin: 2,
  //},
  resourcesWrap: {
    marginTop: 3,
  },
  icon: {
    verticalAlign: "middle",
    marginRight: 6,
    color: theme.palette.primary.main,
    width: "50px !important",
    height: "50px !important",
  },
  spinner: {
    marginTop: 5,
    color: theme.palette.primary.main,
    width: 100,
    height: 100,
    fontSize: 30,
  },
  flexContainer: {
    display: "flex",
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
  },
  flexRowspan: {
    flex: 2,
  },
  flexColumn: {
    display: "flex",
    whiteSpace: "nowrap",
    flexDirection: "column",
    flex: 1,
    marginTop: 2,
  },
  flexCenter: {
    alignItems: "center",
  },
  flexVerticalSpacing: {
    marginBottom: 12,
  },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    fontWeight: 600,
    borderRadius: 3,
    padding: "1px 4px",
    marginRight: 4,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    minWidth: 100,
  },
  dNdHeader: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}));

const UQFileUploader = ({
  customClass,
  customMessageClass,

  /**
   * A comma separated list of accepted mime types or file extensions.
   * Only files with a accepted mime type or file extension are allowed.
   * If no value is set, all types are accepted.
   *
   * Example: "image/*,application/pdf,.psd"
   */
  acceptedFiles,

  /**
   * Remove text and image from the dropzone, make the dropzone as small as possible, and disable preview images.
   */
  compactDesign,

  /**
   * An array of items to display in the component.
   */
  displayItems,

  /**
   * Event handlers for file selection.
   *
   *    - filesSelected
   *        Handles file selection from the user's device.
   *        Receives a list of files as first parameter.
   *
   *    - systemFilesSelected
   *        Handles file selection from the system.
   *        Receives a list of resources as first parameter.
   */
  eventHandlers,

  /**
   * Allow multiple file selection.
   */
  multipleSelection,

  /**
   * Display the "From system" button.
   * When the button is clicked, the selectFromSystem event handler is called.
   * Allows for selecting files from within the system.
   */
  uploadFromSystem,

  /**
   * Use vertical orientation.
   */
  verticalOrientation,
}) => {
  const classes = useStyles();

  const [isDisabled, setIsDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [myDropzone, _setMyDropzone] = useState({});
  const myDropzoneRef = useRef(myDropzone);
  const setMyDropzone = data => {
    myDropzoneRef.current = data;
    _setMyDropzone(data);
  };

  const [fileCounter, _setFileCounter] = useState(0);
  const fileCounterRef = useRef(fileCounter);
  const setFileCounter = data => {
    fileCounterRef.current = data;
    _setFileCounter(data);
  };

  const [selectedResources, _setSelectedResources] = useState([]);
  const selectedResourcesRef = useRef(selectedResources);
  const setSelectedResources = args => {
    let data = multipleSelection ? args : args.slice(-1);
    selectedResourcesRef.current = data;
    _setSelectedResources(data);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    systemFilesSelectedEvent();
  };

  const handleSelectFromComputerEvent = e => {
    e.stopPropagation();
    let myDropzone = myDropzoneRef.current;
    if (myDropzone && myDropzone.hiddenFileInput) {
      myDropzone.hiddenFileInput.click();
    }
  };

  const handleSelectFromSystemEvent = e => {
    e.stopPropagation();
    disableNewSelection();
    setSelectedResources([]);
    setModalOpen(true);
  };

  function disableNewSelection() {
    setIsDisabled(true);
  }

  function enableNewSelection() {
    setIsDisabled(false);
  }

  function reset() {
    setFileCounter(0);
    myDropzoneRef.current.removeAllFiles();
    enableNewSelection();
  }

  function filesSelectedEvent() {
    setFileCounter(0);
    var files = myDropzoneRef.current.files.filter(function(e) {
      return e.accepted || e.status === "added";
    });
    if (eventHandlers && eventHandlers.filesSelected && files.length > 0) {
      eventHandlers.filesSelected(files, reset);
    } else {
      enableNewSelection();
    }
  }

  function systemFilesSelectedEvent() {
    var files = selectedResourcesRef.current;
    if (
      eventHandlers &&
      eventHandlers.systemFilesSelected &&
      files.length > 0
    ) {
      eventHandlers.systemFilesSelected(files, reset);
    } else {
      enableNewSelection();
    }
  }

  const componentEventHandlers = {
    init: dropzone => {
      if (multipleSelection === false) {
        dropzone.hiddenFileInput.removeAttribute("multiple");
      }
      setMyDropzone(dropzone);
    },
    processing: null,
    processingmultiple: null,
    addedfile: file => {
      disableNewSelection();
      var newCount = fileCounterRef.current - 1;
      setFileCounter(newCount);
      if (newCount === 0) {
        // All file added via drag and drop are added. Call the "files selected" event handler.
        return setTimeout(function() {
          // This needs to be deferred so that the last file will be checked.
          filesSelectedEvent();
        }, 0);
      } else {
        // File added via file selector. Wait for the "addedFiles" event.
      }
    },
    addedfiles: files => {
      disableNewSelection();
      if (fileCounterRef.current === 0) {
        // Files will be added via drag and drop. Set the expected file count and let the "addedFiles" event handle the "files selected" event.
        setFileCounter(files.length);
      } else {
        // All file added via file selector are added. Call the "files selected" event handler.
        filesSelectedEvent();
      }
    },
    error: file => {
      let myDropzone = myDropzoneRef.current;
      myDropzone.removeFile(file);
    },
    maxfilesexceeded: file => {
      console.log("maxfilesexceeded");
      console.log(file);
    },
  };

  const dropzoneComponentConfig = {
    postUrl: "-",
    dropzoneSelector: null,
  };

  const djsConfig = {
    acceptedFiles: acceptedFiles,
    autoProcessQueue: false /* Must be false. */,
    maxFiles: multipleSelection ? null : 1,
    parallelUploads: 10000 /* Max number of selectable files. */,
    uploadMultiple: true /* Must be true. */,

    createImageThumbnails: true,
    disablePreviews: compactDesign,
    previewsContainer: compactDesign ? false : null,

    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview">
        <div className="dz-image">
          <img
            className="dz-image"
            data-dz-thumbnail
            alt="Uploading"
            src={ImagePlaceholder}
          />
        </div>
        <div className="dz-details">
          <div className="dz-size">
            <span data-dz-size="true" />
          </div>
          <div className="dz-filename">
            <span data-dz-name="true" />
          </div>
        </div>
        <div className="dz-progress">
          <span className="dz-upload" data-dz-uploadprogress="true" />
        </div>
        <CircularProgress className={classes.spinner} size={20} />
      </div>
    ),
  };

  function UQCloudUploadIcon(props: SvgIconProps) {
    return (
      <SvgIcon {...props}>
        <path d="m20.90842,8.80405c-0.23551,-0.14004 -0.53402,-0.26754 -0.66427,-0.32095c0.01525,-0.13168 0.047,-0.43268 0.047,-0.69899c0,-3.29559 -2.72359,-5.97612 -6.07095,-5.97612c-1.92556,0 -3.75638,0.91459 -4.89741,2.44573c-0.1245,0.16761 -0.24926,0.3682 -0.32976,0.50381c-0.14225,-0.08171 -0.36126,-0.19985 -0.57127,-0.27812c-0.43226,-0.16121 -0.88753,-0.24317 -1.3543,-0.24317c-2.12332,0 -3.85088,1.70047 -3.85088,3.79054c0,0.26901 0.048,0.55575 0.07,0.67487c-0.1095,0.05858 -0.37201,0.20477 -0.58302,0.35983c-1.22354,0.8949 -1.95357,2.32414 -1.95357,3.82229c0,2.62613 2.17032,4.76249 4.83791,4.76249l1.79406,0c0.22001,0 0.39826,-0.17524 0.39826,-0.39183c0,-0.21683 -0.17826,-0.39208 -0.39826,-0.39208l-1.79406,0c-2.22882,0 -4.04188,-1.78464 -4.04188,-3.97858c0,-1.27049 0.59502,-2.43441 1.63205,-3.19394c0.21826,-0.15949 0.52727,-0.32045 0.52902,-0.32168c0.27376,-0.14029 0.43251,-0.47133 0.36976,-0.76963c-0.0005,-0.0032 -0.0645,-0.31061 -0.0645,-0.57174c0,-1.65789 1.37055,-3.00689 3.0551,-3.00689c0.36976,0 0.73027,0.06448 1.07179,0.19222c0.26376,0.0982 0.57452,0.29485 0.57727,0.29682c0.14525,0.09254 0.32351,0.12232 0.48952,0.08122c0.16676,-0.04086 0.31001,-0.15038 0.39426,-0.30002c0.00175,-0.00345 0.18926,-0.33793 0.36451,-0.57322c0.99178,-1.33079 2.58234,-2.12552 4.25539,-2.12552c2.90885,0 5.27493,2.32931 5.27493,5.19271c0,0.29362 -0.04775,0.66059 -0.0485,0.66379c-0.04125,0.30839 0.14875,0.62515 0.44051,0.73763c0.0035,0.00123 0.36376,0.14324 0.61052,0.28944c1.22554,0.72754 1.95732,2.00172 1.95732,3.40856c0,2.19394 -1.81306,3.97858 -4.04188,3.97858l-1.79406,0c-0.22001,0 -0.39826,0.17524 -0.39826,0.39208c0,0.21659 0.17826,0.39183 0.39826,0.39183l1.79406,0c2.66759,0 4.83791,-2.13635 4.83791,-4.76249c-0.00025,-1.68348 -0.87578,-3.20871 -2.34258,-4.07949l0,0.00001z" />
        <path d="m12.00038,22.15442c0.21976,0 0.39801,-0.18588 0.39801,-0.41446l0,-10.93252l3.15085,3.28134c0.078,0.08097 0.17976,0.12158 0.28126,0.12158c0.10175,0 0.20376,-0.04061 0.28151,-0.12158c0.15551,-0.16193 0.15551,-0.42436 0,-0.58603l-3.57437,-3.72184c-0.28626,-0.29887 -0.78853,-0.29887 -1.07454,-0.00052l-3.57437,3.72236c-0.15551,0.16167 -0.15551,0.4241 0,0.58603c0.15526,0.16193 0.40751,0.16193 0.56277,0l3.1511,-3.28134l0,10.93278c-0.00025,0.22858 0.17801,0.4142 0.39776,0.4142l0,0z" />
      </SvgIcon>
    );
  }

  return (
    <Box
      className={clsx([
        classes.dzWrap,
        customClass,
        compactDesign ? classes.uqDzCompactDesign : "",
        isDisabled ? classes.uqDzDisabled : "",
      ])}
    >
      <DropzoneComponent
        config={dropzoneComponentConfig}
        djsConfig={djsConfig}
        eventHandlers={componentEventHandlers}
      >
        <div
          className={clsx([
            "uq-dz-message",
            "dz-clickable",
            customMessageClass,
          ])}
        >
          <div className={clsx("dz-message")}></div>
          <div
            className={clsx(classes.flexContainer, [
              verticalOrientation
                ? [classes.flexColumn, classes.flexCenter]
                : [classes.flexRow],
            ])}
          >
            {!!verticalOrientation && !compactDesign && (
              <div
                className={clsx(
                  classes.flexRow,
                  verticalOrientation && !compactDesign
                    ? classes.flexVerticalSpacing
                    : []
                )}
              >
                <UQCloudUploadIcon className={clsx(classes.icon)} />
              </div>
            )}
            <div className={clsx(classes.flexRow)}>
              {!compactDesign && !verticalOrientation && (
                <div className={clsx(classes.flexColumn)}>
                  <div
                    className={clsx(
                      classes.flexRow,
                      classes.flexRowspan,
                      classes.flexCenter
                    )}
                  >
                    <UQCloudUploadIcon className={clsx(classes.icon)} />
                  </div>
                </div>
              )}
              <div className={classes.flexColumn}>
                {!compactDesign && (
                  <div
                    className={clsx(
                      classes.flexRow,
                      classes.dNdHeader,
                      verticalOrientation && !compactDesign
                        ? classes.flexVerticalSpacing
                        : []
                    )}
                  >
                    <FormattedMessage id="product.dragAndDropHeader" />
                  </div>
                )}
                <div
                  className={
                    verticalOrientation && compactDesign
                      ? classes.flexColumn
                      : classes.flexRow
                  }
                >
                  <div className={clsx(classes.flexColumn, classes.flexCenter)}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.uploadButton}
                      onClick={handleSelectFromComputerEvent}
                    >
                      <FormattedMessage id="btn.uploadFromComputer" />
                    </Button>
                  </div>
                  {!!uploadFromSystem && (
                    <div
                      className={clsx(classes.flexColumn, classes.flexCenter)}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.uploadButton}
                        onClick={handleSelectFromSystemEvent}
                      >
                        <FormattedMessage id="btn.uploadFromSystem" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DropzoneComponent>
      <SelectFileFromSystemModal
        handleClose={handleModalClose}
        isProductEdit={true}
        open={modalOpen}
        selectedResources={selectedResources}
        setSelectedResources={setSelectedResources}
      />
      {displayItems && displayItems.length > 0 && (
        <div className={classes.resourcesWrap}>
          {displayItems.map(x => (
            <Chip key={x.name} label={x.name} color="light" />
          ))}
        </div>
      )}
    </Box>
  );
};

UQFileUploader.defaultProps = {
  acceptedFiles: null,
  compactDesign: false,
  displayItems: [],
  eventHandlers: {
    filesSelected: null,
    systemFilesSelected: null,
  },
  multipleSelection: false,
  uploadFromSystem: false,
  verticalOrientation: false,
};

UQFileUploader.propTypes = {
  acceptedFiles: PropTypes.string,
  compactDesign: PropTypes.bool,
  displayItems: PropTypes.array,
  eventHandlers: PropTypes.shape({
    filesSelected: PropTypes.func,
    systemFilesSelected: PropTypes.func,
  }),
  multipleSelection: PropTypes.bool,
  uploadFromSystem: PropTypes.bool,
  verticalOrientation: PropTypes.bool,
};

export default UQFileUploader;

import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedDate } from "react-intl";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import WidgetItemImage from "../WidgetItemImage";
import { setFileViewOpenNodes } from "../../../actions";

const useStyles = makeStyles(theme => ({
  iconType: {
    marginRight: 10,
    textAlign: "center",
    width: 30,
  },
  productBox: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px",
    margin: "0px 10px",
    borderBottom: `1px solid #dee2e6`,
    background: "#ffffff",
    cursor: "pointer",
    "&:first-of-type": {
      borderTop: `1px solid #dee2e6`,
    },
    "&:hover": {
      background: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
    },
    "& .MuiBox-root": {
      overflowX: "hidden",
    },
  },
  productImg: {
    width: 30,
    objectFit: "cover",
    marginRight: 10,
  },
  productName: {
    fontSize: 11,
    fontWeight: 400,
    color: "#212529",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  productInfo: {
    fontSize: 10,
    color: "#9a9a9a",
  },
}));

export default function RecentlyModifiedItem({ item }) {
  const { folderId, lastModified, name } = item || {};

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const tempHandleOpenFolder = id => {

    
    dispatch(setFileViewOpenNodes({ nodes: [id] }));
    history.push("/file-view");
  };

  return (
    <Box
      className={classes.productBox}
      onClick={() => tempHandleOpenFolder(folderId)}
    >
      <WidgetItemImage classes={classes} item={item} />
      <Box>
        <Typography className={classes.productName}>{name}</Typography>
        <Typography className={classes.productInfo}>
          <FormattedDate value={lastModified} />
        </Typography>
      </Box>
    </Box>
  );
}

RecentlyModifiedItem.propTypes = {
  item: PropTypes.shape({
    fileExtension: PropTypes.string,
    folderId: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    thumbnailImageUri: PropTypes.string,
  }).isRequired,
};

import React from "react";
import SidebarDrawer from "./SidebarDrawer";
import { Box, Paper } from "@mui/material";

const PageLayoutWithSidebar = ({ sidebarContent, mainContent }) => {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, height: 'calc(100vh - 96px)',}}>
      <SidebarDrawer>{sidebarContent}</SidebarDrawer>
      <Paper sx={{ flex: 1, width: "100%", margin: "8px", overflow: 'auto', }}>{mainContent}</Paper>
    </Box>
  )
}

export default PageLayoutWithSidebar;

import { useMutation } from "@apollo/client";
import { CREATE_TASK } from "../../graphql/mutations/ProjectEditContext";

// eslint-disable-next-line import/prefer-default-export
export const useCreateTask = () => {
  const [mutation, { data, error, loading }] = useMutation(CREATE_TASK);

  const createTask = async ({ projectId, parentTaskId, insertAfterTaskId, task }) => {
    const variables = {
      createTaskParams: {
        projectId,
        parentTaskId,
        insertAfterTaskId,
      },
      task,
    };

    const res = await mutation({ variables });

    return res?.data?.projects?.createTask;
  };

  return [createTask, { data, error, loading }];
};

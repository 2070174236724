import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";

function getUnit(a) {
  if (a === 0) return "byte";
  const c = 1024;
  const e = [
    "byte",
    "kilobyte",
    "megabyte",
    "gigabyte",
    "terabyte",
    "petabyte",
  ];
  const f = Math.floor(Math.log(a) / Math.log(c));
  // eslint-disable-next-line no-restricted-properties
  return e[f];
}

function getBytesToSize(bytes) {
  if (bytes === 0) return 0;
  const k = 1024;

  // 2 decimal places
  const dm = 2;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
}

const FormattedFileSize = ({ value }) => {
  return (
    <FormattedNumber
      value={getBytesToSize(value)}
      // eslint-disable-next-line react/style-prop-object
      style="unit"
      unit={getUnit(value)}
      unitDisplay="narrow"
    />
  );
};

FormattedFileSize.propTypes = {
  value: PropTypes.number.isRequired,
};

export default FormattedFileSize;

import React from "react";
import { makeStyles } from "@mui/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    background: "#88d1d2",
    marginBottom: 15,
    "& label + .MuiInput-formControl": {
      marginTop: "0px!important",
    },
  },
  select: {
    color: "#fff",
    fontWeight: "800",
    fontSize: "12px",
    padding: 5,
    "&.MuiInput-underline:after": {
      display: "none!important",
    },
    "&.MuiInput-underline:before": {
      display: "none!important",
    },
  },
}));

const SelectPreview = ({ options, handleChange, value, label }) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange} className={classes.select}>
        {options.map(x => {
          return <MenuItem value={x.value}>{x.label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default SelectPreview;

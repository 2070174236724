import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import HomeMadeFolderTree from "../FoldersTree";
import getFolderTreeDataStructure from "../../../../../helpers/getFolderTreeDataStructure";

const FolderTreeContainer = ({
  folders,
  handleOpenNode,
  openedNodes,
  setOpenedNodes,
  foundFolders,
  handleFolderRowClick,
}) => {
  const [folderTree, setFolderTree] = useState({});

  useEffect(() => {
    const newFolderTree = getFolderTreeDataStructure({ folders });
    setFolderTree(newFolderTree);
  }, [folders]);

  return (
    <HomeMadeFolderTree
      folderTree={folderTree}
      handleOpenNode={handleOpenNode}
      openedNodes={openedNodes}
      setFolderTree={setFolderTree}
      setOpenedNodes={setOpenedNodes}
      foundFolders={foundFolders}
      handleFolderRowClick={handleFolderRowClick}
    />
  );
};

FolderTreeContainer.defaultProps = {
  folders: null,
  openedNodes: [],
  foundFolders: [],
};

FolderTreeContainer.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape({})),
  handleOpenNode: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  setOpenedNodes: PropTypes.func.isRequired,
  foundFolders: PropTypes.arrayOf(PropTypes.number),
  handleFolderRowClick: PropTypes.func.isRequired,
};

export default FolderTreeContainer;

import { gql } from '@apollo/client';

const getFolderResourceListing = gql`
  query GetFolderResourceListing($folderId: Int!) {
    addFilesToCart {
      folderResourceListing(folderId: $folderId) {
        folders {
          folder {
            folderId
            name
          }
          resources {
            id
            name
          }
        }
      }
    }
  }
`;

export default getFolderResourceListing;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const SALES_DOCUMENT_UPDATE = gql`
mutation updateCreateSalesDocument ($model:SalesDocumentInput!) {
	companyAdminContext {
		salesDocuments {
			createUpdateDocument(
				input:$model
			) {
				errorResult {
					data {
						key
						value
					}
					errorCode
					errorMessage
				}
				result {
					salesDocumentId
					documentNumber
					documentStatus
					documentType
					header {
						address {
							address1
							address2
							address3
							address4
							address5
							city
							contact
							country
							customerId
							email
							reference
							telephone
							zip
						}
						amountDue
						category
						companyAddressCardId
						documentDate
						dueDate
						gLN
						otherReferences
						orgNo
						ourReference
						paymentTermsDays
						vatRegNo
					}
					rows {						
						articleNo
						amount
						description
						price
						projectNo
						unit
						article{
							articleNo
							description
							amount
							price
						}
					}
				}
			}
		}
	}
}
`;

export default SALES_DOCUMENT_UPDATE;
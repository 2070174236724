import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, AppBar, Tabs } from "@mui/material";
import PageTabPanel from "../../common/PageTabPanel";
import { geta11yProps } from "../../../helpers/get";
import TabPanel from "../../TabPanel";
import PageTabs from "../../PageTabs";
import SvenkaTab from "./SvenkaTab";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: 12,
    fontWeight: 600,
  },
  custom: {
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
  },
  wrap: {
    backgroundColor: "transparent",
    borderBottom: "1px solid #e4e4e4",
    "& .MuiIcon-root": {
      display: "none",
    },
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
    "& svg": {
      display: "none",
    },
    "& .MuiButtonBase-root": {
      padding: "10px 12px",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
}));

const SwedishSpecificTab = () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PageTabPanel spacing="3">
      <Grid item xs={12}>
        <AppBar position="static" elevation="0" className={classes.wrap}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Texts"
            classes={{
              indicator: classes.indicator,
            }}
            className={classes.tabsRoot}
          >
            <PageTabs
              className={classes.pageTabs}
              label="Gefabgruppen AB"
              {...geta11yProps(0)}
            />
            <PageTabs
              className={classes.pageTabs}
              label="Gefab Data"
              {...geta11yProps(1)}
            />
            <PageTabs
              className={classes.pageTabs}
              label="Test"
              {...geta11yProps(2)}
            />
            <PageTabs
              className={classes.pageTabs}
              label="Lars Zetterström"
              {...geta11yProps(3)}
            />
          </Tabs>
        </AppBar>
        {[<SvenkaTab />, <SvenkaTab />, <SvenkaTab />, <SvenkaTab />].map(
          (x, index) => (
            <TabPanel value={value} className={classes.tabPanel} index={index}>
              {x}
            </TabPanel>
          )
        )}
      </Grid>
    </PageTabPanel>
  );
};

export default SwedishSpecificTab;

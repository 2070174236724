import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import * as siteNames from "../../../constant/siteNames";
import useChangeCurrentWorkspace from "../../../hooks/useChangeCurrentWorkspace";
import { ResponsiveLinesEllipsis, WorkspaceIcon } from "../../common";
import { getCurrentUser } from "../../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  description: {
    //color: props => props.fontColor || "#9a9a9a",
    color: "#9a9a9a",
    fontSize: 12,
  },
  iconContainer: {
    flex: 0,
    margin: "0 auto 7px auto",
  },
  name: {
    //color: props => props.fontColor || "#212529",
    color: "#212529",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 7,
  },
  root: props => ({
    borderRadius: 4,
    cursor: !!props.canNavigate ? "pointer" : "default",
    display: "flex",
    flexFlow: "row wrap",
    padding: 7,
  }),
  textContainer: props => ({
    flex: 1,
    flexBasis: "50%",
    margin: "0 7px",
    overflow: "hidden",
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    }
  }),
}));

const WorkspaceLinkWidget = ({ workspace }) => {
  const intl = useIntl();

  const { companyId, sites } = useSelector(getCurrentUser);

  const [item, setItem] = useState(null);
  const [nameClamped, setNameClamped] = useState(false);
  const [siteId, setSiteId] = useState();

  const classes = useStyles({ canNavigate: (siteId > 0) });

  const changeCurrentWorkspace = useChangeCurrentWorkspace();

  useEffect(() => {
    const workspaceData = Array.isArray(workspace) ? workspace[0] : workspace;

    if (!workspaceData) {
      return;
    }

    const site = sites.find(n => n.id === workspaceData.siteId);

    setSiteId(site?.id);

    const source = site || workspaceData;

    setItem({
      description: source.description,
      icon: source.icon,
      iconColor: source.iconColor,
      name: source.name,
    });
  }, [workspace]);

  const getSiteDisplayName = ({ sitename }) => {
    switch (sitename) {
      case siteNames.LIFT_MACHINE:
        return getTranslatedWorkspaceName(
          siteNames.SITE_DISPLAY_NAME[siteNames.LIFT_MACHINE]
        );
      default:
        return sitename;
    }
  };

  const getTranslatedWorkspaceDesc = name => {
    return intl.formatMessage({ id: `workspaceDesc.${name}` });
  };

  const getTranslatedWorkspaceName = name => {
    return intl.formatMessage({ id: `workspaceName.${name}` });
  };

  const handleEllipsisReflow = rleState => {
    const { clamped } = rleState;
    setNameClamped(clamped);
  };

  const navigateToWorkspace = () => {
    if (siteId > 0) {
      changeCurrentWorkspace(siteId);
    }
  };

  if (!item) {
    return;
  }

  return (
    <Box
      alignItems="center"
      className={classes.root}
      onClick={navigateToWorkspace}
    >
      <Box className={classes.iconContainer}>
        <WorkspaceIcon icon={item.icon} color={item.iconColor} size={30} />
      </Box>
      <Box className={classes.textContainer}>
        <Box
          className={classes.name}
          title={
            nameClamped
              ? getSiteDisplayName({ sitename: item.name })
              : undefined
          }
        >
          <ResponsiveLinesEllipsis
            basedOn="letters"
            ellipsis="..."
            maxLine="2"
            onReflow={handleEllipsisReflow}
            text={getSiteDisplayName({ sitename: item.name })}
            trimRight
          />
        </Box>
        <Typography className={classes.description}>
          {companyId !== 754 ? (
            <>{item.description}</>
          ) : (
            <>
              {getTranslatedWorkspaceDesc(
                siteNames.SITE_DISPLAY_NAME[siteNames.LIFT_MACHINE]
              )}
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default WorkspaceLinkWidget;

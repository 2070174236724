import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
  clear: ({ marginRight, marginLeft }) => ({
    marginRight,
    marginLeft,
    textTransform: "none",
    color: theme.palette.common.link,
    padding: 0,
    minWidth: "unset",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  }),
}));

const ClearButton = ({
  children,
  onClick,
  marginRight,
  marginLeft,
  disabled,
}) => {
  const dynamicStyle = { marginRight, marginLeft };
  const classes = useStyles(dynamicStyle);

  return (
    <Button className={classes.clear} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

ClearButton.defaultProps = {
  marginRight: 0,
  marginLeft: 0,
  disabled: false,
};

ClearButton.propTypes = {
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ClearButton;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const DELETE_GROUP = gql`
  mutation DeleteGroup($groupId: Int!) {
    companyAdminContext {
      companyGroupSettings {
        deleteGroup(groupId: $groupId) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_GROUP;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { FormattedTime } from "react-intl";
import FormattedDate from "../../common/FormattedDate";

const useStyles = makeStyles(() => ({
  date: {
    fontSize: 12,
    color: "#888",
    marginLeft: 10,
  },
  time: { marginLeft: 5 },
}));

const MessageTime = ({ dateTime }) => {
  const classes = useStyles();
  const messageDate = new Date(dateTime);

  return (
    <Typography className={classes.date}>
      <FormattedDate value={messageDate} />
      <span className={classes.time}>
        <FormattedTime value={messageDate} />
      </span>
    </Typography>
  );
};

export default MessageTime;

import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Grid, Select, MenuItem, TextField, Table, TableHead, TableCell, TableBody } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import WorkspaceSelector from "../../components/common/WorkspaceSelector";
import { GET_PROJECTS, GET_TASKS } from "../../graphql/queries";
import { makeStyles } from "@mui/styles";
import JoditTextEditor from "../../components/common/JoditTextEditor";
import { CREATE_TASK, UPDATE_TASK } from "../../graphql/mutations/ProjectEditContext";
import { useSnackbar } from "../../hooks";
import ProjectDialog from "./ProjectDialog";
import cloneDeep from 'lodash/cloneDeep';

import StatusValueEditor from "./ValueEditors/StatusValueEditor";
import StringValueEditor from "./ValueEditors/StringValueEditor";
import NumberValueEditor from "./ValueEditors/NumberValueEditor";
import DateValueEditor from "./ValueEditors/DateValueEditor";
import DurationValueEditor from "./ValueEditors/DurationValueEditor";
import { DatePicker } from "@mui/x-date-pickers";
import SimpleEdit from "./SimpleEdit";
import NewTaskRow from "../../components/Project/NewTaskRow";
import EditorContainer from "../../components/common/EditorContainer";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },

  paperBody: {
    padding: theme.spacing(4),
  },
}));

const ProjectTaskPage = ({ }) => {

  const classes = useStyles();
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );
  const snackbar = useSnackbar();
  const [siteId, setSiteId] = useState();
  const [openWorkspaceSelector, setOpenWorkspaceSelector] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState("");

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState();

  const handleOpenWorkspaces = value => {
    setSiteId(value.siteId);
    setSelectedWorkspaceName(value.name);
    setOpenWorkspaceSelector(false);
  };

  const handleToggle = () => {
    setOpenWorkspaceSelector(prevState => !prevState);
  };

  const [getProjects, { data: projectData, loading: projectsLoading, error: projectsError }] = useLazyQuery(GET_PROJECTS);
  const [getTasks, { refetch: refetchTasks, data: taskData, loading: taskLoading, error: taskError }] = useLazyQuery(GET_TASKS);
  const [updateTask, { data: updateData, loading: loadingUpdate, error: updateError }] = useMutation(UPDATE_TASK);
  const [createTask, { data: createData, loading: loadingCreate, error: createError }] = useMutation(CREATE_TASK);

  useEffect(() => {
    if (updateData || createData) {
      snackbar.success("Sparat!");
      setSelectedTask(null);
      refetchTasks();
    }
  }, [updateData, updateError, createData, createError]);

  useEffect(() => {
    if (taskData?.projects?.getTasks) {
      setSelectedTask(null);
      setTasks(taskData?.projects?.getTasks.tasks);
    }
  }, [taskData]);

  useEffect(() => {
    if (projectData?.projects?.getProjects) {
      setSelectedProject(null);
      setSelectedTask(null);
      setTasks([]);

      // Parse settings for each projectField
      const parsedProjects = projectData.projects.getProjects.map((project) => {
        const parsedProjectFields = project.projectFields.map((field) => {
          // Parse the settings property if it exists
          if (field.jsonFieldSettings) {
            try {
              const settings = JSON.parse(field.jsonFieldSettings);

              return { ...field, settings };
            } catch (error) {
              console.error(`Error parsing settings for projectField: ${field}`, error);
              return field;
            }
          }
          return field;
        });
        return { ...project, projectFields: parsedProjectFields };
      });
      setProjects(parsedProjects);
    }
  }, [projectData]);

  useEffect(() => {
    if (siteId) {
      getProjects({
        variables: {
          siteId
        }
      });
    }
  }, [siteId]);

  useEffect(() => {
    if (selectedProject) {
      getTasks({
        variables: {
          projectId: selectedProject.projectId
        }
      });
    }
  }, [selectedProject]);

  const handleCancel = () => { }

  const handleValueChange = (field, value) => {
    const newValue = { ...selectedTask };
    newValue[field] = value;
    setSelectedTask(newValue);
  }

  const handleTaskValueChange = (fieldId, value) => {
    const task = cloneDeep(selectedTask);
    var field = selectedProject.projectFields.find(f => f.fieldId === fieldId);
    var valueField = '';
    switch (field.type) {
      case 4: //duration and numeric 
      case 1: valueField = 'numericValue'; break;

      case 0: //string and status
      case 3: valueField = 'stringValue'; break;

      case 2: valueField = 'dateTimeValue'; break;

      default: valueField = 'stringValue';
    }

    if (!task.values) {
      task.values = [{ fieldId }];
      (task.values[0])[valueField] = value;
    } else {
      const existingIndex = task.values.findIndex(taskValue => taskValue.fieldId === fieldId);

      if (existingIndex !== -1) {
        // If the fieldId already exists, update its value
        //task.values[existingIndex] = { ...task.values[existingIndex], value };
        (task.values[existingIndex])[valueField] = value;
      } else {
        // If the fieldId doesn't exist, add a new object to the values array
        var newValue = { fieldId };
        newValue[valueField] = value

        task.values.push(newValue);
      }
    }
    setSelectedTask(task);
  };

  const handleNewTask = () => {
    const selected = {
      assignments: [],
      description: "",
      endDate: null,
      id: 0,
      linkedFolderId: null,
      name: "",
      parentTaskId: null,
      projectId: selectedProject.projectId,
      projectTaskNo: 0,
      startDate: null,
      taskPropertyGroupId: null,
      taskWorkflowId: null,
      timestamp: null,
      values: []
    };
    setSelectedTask(selected);
    var temp = [...tasks, selected];
    setTasks(temp);
  }

  const handleSaveProject = () => { }

  const handleSave = () => {
    let temp = cloneDeep(selectedTask);
    delete temp.timestamp;
    delete temp.__typename;
    delete temp.createdBy;

    if (temp.id > 0) {
      updateTask({
        variables: {
          task: temp
        }
      });
    } else {
      createTask({
        variables: {
          task: temp
        }
      });
    }
  }


  const renderValueCell = (field, value) => {

    switch (field.type) {
      case 4: return (<DurationValueEditor field={field} value={value} onUpdateTaskValue={handleTaskValueChange} />);
      case 3: return (<StatusValueEditor field={field} value={value} onUpdateTaskValue={handleTaskValueChange} />);
      case 2: return (<DateValueEditor field={field} value={value} onUpdateTaskValue={handleTaskValueChange} />);
      case 1: return (<NumberValueEditor field={field} value={value} onUpdateTaskValue={handleTaskValueChange} />);
      case 0: return (<StringValueEditor field={field} value={value} onUpdateTaskValue={handleTaskValueChange} />);

      default: return "Type: " + field.type + ":" + value || "No Value"
    }
  }

  const [open, setOpen] = useState(false);
  const [simpleValue, setSimpleValue] = useState("");

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={4}>
          <WorkspaceSelector
            workspaces={workspaces}
            workspaceName={selectedWorkspaceName}
            open={openWorkspaceSelector}
            handleToggle={handleToggle}
            searchable
            setOpen={setOpenWorkspaceSelector}
            onClick={handleOpenWorkspaces}
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleEdit value={simpleValue} onChange={newValue => setSimpleValue(newValue) } />
          Projekt:
          {projects && projects.length > 0 && (
            <Select value={selectedProject} onChange={n => setSelectedProject(n.target.value)}>
              {projects.map(n => (<MenuItem key={"p" + n.projectId} value={n}>{n.name}</MenuItem>))}
            </Select>
          )}
          {selectedProject && (
            <>
              <Button variant="contained" onClick={n => setOpenProjectDialog(true)}>Projektegenskaper</Button>
              <ProjectDialog
                onProjectUpdate={n => setSelectedProject(n)}
                project={selectedProject}
                open={openProjectDialog}
                onClose={n => setOpenProjectDialog(false)}
                onSave={handleSaveProject}
              />
            </>
          )}
        </Grid>
        <Grid item xs={4}>Task:
          {tasks && tasks.length > 0 && (
            <Select value={selectedTask} onChange={n => setSelectedTask(n.target.value)}>
              {tasks.map(n => (<MenuItem key={"p" + n.taskId} value={n}>{n.name}</MenuItem>))}
            </Select>
          )}
          {selectedProject && (
            <Button variant="contained" onClick={n => handleNewTask()}>Ny task</Button>
          )}
        </Grid>

        {selectedTask && (
          <>
            <Grid item xs={12}>
              Name: <TextField value={selectedTask.name} onChange={e => handleValueChange("name", e.target.value)} />
            </Grid>

            <Grid item xs={6}>
              Beskrivning
              <JoditTextEditor
                onSave={value => handleValueChange("description", value)}
                onCancel={handleCancel}
                value={selectedTask.description}
              />
            </Grid>
            <Grid item xs={6}>
              Förhandsgranskning
              <div
                className={classes.htmlContent}
                dangerouslySetInnerHTML={{
                  __html: selectedTask.description,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  {selectedProject && selectedProject.projectFields.map(n => (
                    <TableCell key={n.fieldId}>{n.name}</TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {selectedProject && selectedProject.projectFields.map(n => {
                    // Find the corresponding object in selectedTask.values
                    const matchingValue = selectedTask.values.find(value => value.fieldId === n.fieldId);
                    // Print value.value if a match is found
                    //const displayValue = matchingValue ? matchingValue.value : null;
                    return (<TableCell key={n.fieldId + "_" + selectedTask.id}>{renderValueCell(n, matchingValue)}</TableCell>)
                  })}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={4}>
              <Button variant="contained" onClick={n => handleSave()}>Spara Task:{selectedTask.id || "Ny"}</Button>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default ProjectTaskPage;
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { UPDATE_EXTERNAL_USER } from "../../../graphql/mutations/CompanyAdminContext";
import { GET_COMPANY_EXTERNAL_USER_LIST_ITEMS } from "../../../graphql/queries";
import { getAdminSelectedCompanyId } from "../../../helpers/adminSelectors";

import CustomSelect from "../../common/CustomSelect";
import Pagination from "../../common/Pagination";

import AddUserModal from "./AddUserModal";
import UserBox from "./UserBox";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  select: {
    width: 160,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
  pagination: {
    marginLeft: "auto",
    marginRight: theme.spacing(4),
  },
}));

const Users = () => {
  const classes = useStyles();
  const alert = useAlert();
  const intl = useIntl();

  const companyId = useSelector(getAdminSelectedCompanyId);

  const [users, setUsers] = useState([]);
  const [perPage, setPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);

  const [addUserModal, setAddUserModal] = useState(false);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const [getExternalUsers, { data, loading }] = useLazyQuery(
    GET_COMPANY_EXTERNAL_USER_LIST_ITEMS
  );

  const [updateExternalUser] = useMutation(UPDATE_EXTERNAL_USER);

  const handleFetchExternalusers = () => {
    getExternalUsers({
      variables: {
        companyId,
        offset: page,
        pagesize: perPage,
      },
    });
  };

  useEffect(() => {
    if (companyId) {
      handleFetchExternalusers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, page, perPage]);

  useEffect(() => {
    if (
      data &&
      data.companyAdminContext &&
      data.companyAdminContext.externalUsers &&
      data.companyAdminContext.externalUsers.listExternalUsers
        .externalUserListItems
    ) {
      setTotalCount(
        data.companyAdminContext.externalUsers.listExternalUsers.totalUserCount
      );
      setUsers(
        data.companyAdminContext.externalUsers.listExternalUsers
          .externalUserListItems
      );
    }
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    if (updateUserModal) {
      setUpdateUserModal(false);
    }

    if (addUserModal) {
      setAddUserModal(false);
    }
  };

  const handleUpdateExternalUser = async newRole => {
    try {
      const res = await updateExternalUser({
        variables: {
          targetCompanyId: companyId,
          userId: selectedUser.userId,
          targetRoleId: newRole,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.externalUsers &&
        res.data.companyAdminContext.externalUsers.updateExternalUser
      ) {
        const {
          success,
        } = res.data.companyAdminContext.externalUsers.updateExternalUser;

        if (success) {
          alert.success(<FormattedMessage id="common.genericSuccess" />);
          handleFetchExternalusers();
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
        handleClose();
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const handleToUpdateUser = user => {
    setSelectedUser(user);
    setUpdateUserModal(true);
  };

  const userModal = addUserModal || updateUserModal;

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          <FormattedMessage id="user.externalUser" />
        </Typography>
        <CustomSelect
          customClass={classes.select}
          inputLabel={intl.formatMessage({ id: "common.select" })}
          options={[
            { label: intl.formatMessage({ id: "user.allUsers" }), value: 1 },
          ]}
          value={1}
        />
        <Box className={classes.pagination}>
          <Pagination
            onChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            page={page}
            perPage={perPage}
            totalCount={totalCount}
          />
        </Box>
        <Button
          className={classes.addLink}
          onClick={() => setAddUserModal(true)}
        >
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
          <FormattedMessage id="user.addNewExternalUser" />
        </Button>
      </Box>
      <Box className={classes.body}>
        <Grid container spacing={3}>
          <UserBox
            handleToUpdateUser={handleToUpdateUser}
            loading={loading}
            refetch={handleFetchExternalusers}
            users={users}
          />
        </Grid>
      </Box>

      {userModal && (
        <AddUserModal
          companyId={companyId}
          handleClose={handleClose}
          handleUpdateExternalUser={handleUpdateExternalUser}
          isUpdate={updateUserModal}
          open={userModal}
          refetch={handleFetchExternalusers}
          selectedUser={selectedUser}
        />
      )}
    </Box>
  );
};

export default Users;

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 12,
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
    cursor: "default",
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const RenderMonitoringType = ({ type }) => {
  const classes = useStyles();
  switch (type) {
    case "FILES":
      return (
        <IconButton className={classes.action}>
          <Icon className={clsx(["fa fa-file", classes.icon])} />
        </IconButton>
      );
    case "FOLDERS":
      return (
        <IconButton className={classes.action}>
          <Icon className={clsx(["fa fa-folder", classes.icon])} />
        </IconButton>
      );
    case "ROLES":
      return (
        <IconButton className={classes.action}>
          <Icon className={clsx(["fa fa-user", classes.icon])} />
        </IconButton>
      );
    case "USERS":
      return (
        <IconButton className={classes.action}>
          <Icon className={clsx(["fa fa-user-cog", classes.icon])} />
        </IconButton>
      );
    default:
      return null;
  }
};

RenderMonitoringType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default RenderMonitoringType;

import { gql } from '@apollo/client';

const ErrorResultFragment = gql`
  fragment ErrorResultFields on ErrorResult {
    data {
      key
      value
    }
    errorCode
    errorMessage
    __typename
  }
`;

export default ErrorResultFragment;

import React from "react";
import { TableCell, TableRow } from "@mui/material";
import TableSkeleton from "../../Skeleton/TableSkeleton";

const TableRowLoading = () => {
  return (
    <TableRow>
      <TableCell
        style={{
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        }}
        colSpan={6}
      >
        <TableSkeleton />
      </TableCell>
    </TableRow>
  );
};

TableRowLoading.defaultProps = {};

TableRowLoading.propTypes = {};

export default TableRowLoading;

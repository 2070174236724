import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import ProfileEditModal from "./ProfileEditModal";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #f1f1f1",
  },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 10,
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  textWarning: {
    fontSize: 12,
    color: "#BFBA3C",
  },
  textStop: {
    fontSize: 12,
    color: "#F36969",
  },
}));

const PreflightTable = () => {
  const classes = useStyles();

  const [openProfileEdit, setOpenProfileEdit] = useState(false);
  const handleOpenProfileEdit = () => {
    setOpenProfileEdit(true);
  };
  const handleCloseProfileEdit = () => {
    setOpenProfileEdit(false);
  };

  return (
    <>
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>Profile name</TableCell>
            <TableCell className={classes.th}>show outcomes</TableCell>
            <TableCell className={classes.th}>pictures</TableCell>
            <TableCell className={classes.th}>
              Missing fonts (not embedded)
            </TableCell>
            <TableCell className={classes.th}>
              Documents containing different formats
            </TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>Profile sample</TableCell>
            <TableCell className={classes.td}>
              <Typography className={classes.textWarning}>Warn</Typography>
            </TableCell>
            <TableCell className={classes.td}>
              <Typography className={classes.textStop}>Stop</Typography>
            </TableCell>
            <TableCell className={classes.td}>
              <Typography className={classes.textWarning}>Warn</Typography>
            </TableCell>
            <TableCell className={classes.td}>
              <Typography className={classes.textStop}>Stop</Typography>
            </TableCell>
            <TableCell className={classes.td} align="right">
              <IconButton
                className={classes.iconButtonEdit}
                onClick={handleOpenProfileEdit}
              >
                <Icon className={clsx(["fa fa-pencil-alt", classes.icon])} />
              </IconButton>
              <IconButton className={classes.iconButtonDelete}>
                <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ProfileEditModal
        open={openProfileEdit}
        handleClose={handleCloseProfileEdit}
      />
    </>
  );
};

export default PreflightTable;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import WarningImage from "../../../../assets/img/warning-icon.svg";
import {
  DraggableModal,
  DraggableModalBody,
  DraggableModalHead,
} from "../../../common";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  img: {
    width: 50,
  },
  name: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  invalidProperties: {
    marginLeft: 30,
    marginRight: 30,
  },
  invalidPropertiesText: { fontSize: 12 },
  headerText: {
    fontWeight: 600,
  },
}));

const NameTypography = ({ name }) => {
  const classes = useStyles();
  return <Typography className={classes.name}>{name}: </Typography>;
};

NameTypography.propTypes = {
  name: PropTypes.string.isRequired,
};

const InvalidProperties = ({ invalidProperties }) => {
  const classes = useStyles();

  return (
    <Box className={classes.invalidProperties}>
      <Typography className={classes.invalidPropertiesText}>
        {invalidProperties.map((y, idx) => {
          if (idx === 0) return y;
          return `, ${y}`;
        })}
      </Typography>
    </Box>
  );
};

InvalidProperties.propTypes = {
  invalidProperties: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Header = ({ title }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.headerText}>{title}</Typography>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

const InvalidFileFolderModal = ({
  open,
  handleClose,
  invalidFiles,
  invalidFolders,
  invalidSupplier,
  noProductionSettings,
}) => {
  const classes = useStyles();

  const renderInvalidFiles = () => {
    if (invalidFiles.length < 1) return null;

    return (
      <Box style={{}}>
        <Header
          title={<FormattedMessage id="monitoring.invalid.invalidfiles" />}
        />
        {invalidFiles.map(x => {
          const { name = "", invalidProperties = [] } = x;
          return (
            <Box key={x.name}>
              <NameTypography name={name} />
              <InvalidProperties invalidProperties={invalidProperties} />
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderInvalidFolders = () => {
    if (invalidFolders.length < 1) return null;

    return (
      <Box style={{ marginBottom: 10 }}>
        <Header
          title={<FormattedMessage id="monitoring.invalid.invalidfolder" />}
        />
        {invalidFolders.map(x => {
          const { name = "", invalidProperties = [] } = x;
          return (
            <Box key={x.name}>
              <NameTypography name={name} />
              <InvalidProperties invalidProperties={invalidProperties} />
            </Box>
          );
        })}
      </Box>
    );
  };
  const renderInvalidSupplier = () => {
    if (!invalidSupplier) return null;

    return (
      <Box style={{ marginBottom: 10 }}>
        <Header title={<FormattedMessage id="monitoring.invalid.supplier" />} />
      </Box>
    );
  };
  const renderBody = () => {
    if (noProductionSettings) {
      return (
        <Typography className={classes.name}>No production settings</Typography>
      );
    }

    return (
      <>
        {renderInvalidSupplier()}
        {renderInvalidFolders()}
        {renderInvalidFiles()}
      </>
    );
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="Warning" />
      <DraggableModalBody customClass={classes.modalBody}>
        <Box
          display="flex"
          justifyContent="center"
          style={{ marginBottom: 10 }}
        >
          <img src={WarningImage} alt="Warning" className={classes.img} />
        </Box>

        {renderBody()}

        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btn} onClick={handleClose}>
            <FormattedMessage id="btn.close" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

InvalidFileFolderModal.defaultProps = {
  invalidFiles: [],
  invalidFolders: [],
  invalidSupplier: false,
};

InvalidFileFolderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  invalidFiles: PropTypes.arrayOf(PropTypes.shape({})),
  invalidFolders: PropTypes.arrayOf(PropTypes.shape({})),
  noProductionSettings: PropTypes.bool.isRequired,
  invalidSupplier: PropTypes.bool.isRequired,
};

export default InvalidFileFolderModal;

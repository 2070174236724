import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  TextareaAutosize,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: "10px 0",
  },
  textarea: {
    fontSize: 12,
    width: "100%",
    resize: "vertical",
    padding: 10,
    border: "1px solid #a9a9a9",
    "&:focus": {
      borderWidth: 2,
      borderColor: "#000",
    },
  },
  FormHelperText: {
    color: theme.palette.common.white,
    backgroundColor: "#f44336",
    display: "inline-block",
    marginLeft: 5,
    fontSize: 10,
    padding: "2px 5px",
    borderRadius: 3,
  },
}));

const TextAreaField = ({
  error,
  isRequired,
  description,
  fieldName,
  handleChange,
  value,
}) => {
  const classes = useStyles();
  let filteredDescription = description.replace("[", "");
  filteredDescription = filteredDescription.replace("]", "");
  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel
        error={error ? error.error : false}
        required={isRequired}
        className={classes.textField}
        shrink
      >
        {error && error.error && (
          <FormHelperText className={classes.FormHelperText}>
            <FormattedMessage id="context.required" />
          </FormHelperText>
        )}
      </InputLabel>
      <TextareaAutosize
        className={classes.textarea}
        name={fieldName}
        onChange={handleChange}
        value={value}
        placeholder={filteredDescription}
        rowsMin={4}
      />
    </FormControl>
  );
};

TextAreaField.defaultProps = {
  displayName: null,
  isRequired: false,
  error: null,
  description: "",
};

TextAreaField.propTypes = {
  description: PropTypes.string,
  error: PropTypes.shape({
    error: PropTypes.bool,
  }),
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default TextAreaField;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, Switch } from "@mui/material";
import TableCellActions from "./TableCellActions";

const useStyles = makeStyles(() => ({
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  tdImg: {
    minWidth: 100,
    textAlign: "center",
  },
  img: {
    width: 80,
    height: 50,
    objectFit: "cover",
    verticalAlign: "middle",
  },
}));

const TableBodyList = ({ news }) => {
  const classes = useStyles();

  const {
    author,
    category,
    createDate,
    title,
    viewCount,
    leadText,
    image,
    id,
  } = news;

  return (
    <TableBody>
      <TableRow>
        <TableCell className={classes.tdImg}>
          <img src={image} alt="Sample" className={classes.img} />
        </TableCell>
        <TableCell className={classes.td}>{title}</TableCell>
        <TableCell className={classes.td}>{leadText}</TableCell>
        <TableCell className={classes.td}>{author}</TableCell>
        <TableCell className={classes.td}>{createDate}</TableCell>
        <TableCell className={classes.td}>
          <Switch name="checkedB" color="primary" size="small" />
        </TableCell>
        <TableCell className={classes.td} align="right">
          <TableCellActions id={id} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

TableBodyList.defaultProps = {
  news: {},
};

TableBodyList.propTypes = {
  news: PropTypes.shape({
    author: PropTypes.string,
    category: PropTypes.string,
    createdDate: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    leadText: PropTypes.string,
    title: PropTypes.string,
    viewCount: PropTypes.number,
  }),
};

export default TableBodyList;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles(theme => ({
  btn: ({ marginRight, marginLeft, marginTop, fontSize }) => ({
    marginRight,
    marginLeft,
    marginTop,
    padding: "3px 8px",
    fontSize: fontSize || 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.text.lightGray,
    "&:hover": {
      backgroundColor: theme.palette.text.lightGray,
      opacity: 0.8,
    },
  }),
  btnDisabled: {
    backgroundColor: "#e0e0e0!important",
    color: "#ababa6!important",
  },
}));

const GrayButton = ({
  children,
  onClick,
  marginRight,
  marginLeft,
  dataTut,
  disabled,
  marginTop,
  fullWidth,
  onMouseDown,
  fontSize,
}) => {
  const dynamicStyle = { marginRight, marginLeft, marginTop, fontSize };
  const classes = useStyles(dynamicStyle);

  return (
    <Button
      onMouseDown={onMouseDown}
      className={classes.btn}
      classes={{ disabled: classes.btnDisabled }}
      onClick={onClick}
      data-tut={dataTut}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  );
};

GrayButton.defaultProps = {
  marginTop: 0,
  marginRight: 0,
  marginLeft: 0,
  disabled: false,
};

GrayButton.propTypes = {
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default GrayButton;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import AdminSidebar from "../../../components/Administration/AdminSidebar";
import Appearance from "../../../components/Administration/CompanySettings/Appearance";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#f3f3f3",
  },
}));

const CompanySettings_AppearancePage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <AdminSidebar />
        </Grid>
        <Grid item xs={12} md={10}>
          <Box>
            <Appearance />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanySettings_AppearancePage;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Button, IconButton } from "@mui/material";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { FormattedMessage } from "react-intl";

import { CustomSelect, FormattedDate } from "../../common";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";

const useStyles = makeStyles(theme => ({
  td: {
    fontSize: 11,
    paddingTop: 12,
    paddingBottom: 12,
  },
  tdCheckbox: {
    padding: "10px 0px 10px 10px",
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  img: {
    width: 20,
    height: 20,
    objectFit: "cover",
    borderRadius: "50%",
    verticalAlign: "middle",
    marginRight: 10,
  },
  removeRow: {
    padding: 3,
    color: theme.palette.secondary.dark,
    marginRight: 0,
    "& svg": {
      width: "17px !important",
      height: "17px !important",
    },
  },
}));

const SiteUsersTableRow = ({
  siteRoleOptions,
  siteUser,  
  setOpenWarningModal,
  handleOpenUserDetails,
  siteRolesRights,
  onRemoveSiteUser,
  onUpdateSiteUser,
}) => {
  
  const classes = useStyles();
  const [formValues, setFormValues] = useState(siteUser);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { canManageSiteRoles, canInviteUsers } =
    siteRolesRights || {};

  const {
    avatar,
    companyName,
    email,
    lastLoggedIn,
    name,
    paymentLiability,
    id,
    siteId,
    siteRoleId,
    status,
    siteRoleName,
    telephone,
  } = formValues;

  useEffect(() => {
    if (siteUser) {
      setFormValues(siteUser);
    }
  }, [siteUser]);

  const confirmDelete = (siteId, id) => {
    onRemoveSiteUser(siteId, id);
    setConfirmationModal(false);
  };

  const handleFormValues = e => {
    if (status === "INVITED") {
      setOpenWarningModal(true);
      return;
    }
    const { value } = e.target;
    const key = e.target.name;
    setFormValues({ ...formValues, [key]: value });
    const params = { id, paymentLiability, siteId, siteRoleId: value };

    onUpdateSiteUser(params);
  };

  const handleSwitchValues = e => {
    if (status === "INVITED") {
      setOpenWarningModal(true);
      return;
    }
    const { checked } = e.target;
    const key = e.target.name;
    setFormValues({ ...formValues, [key]: checked });
    const params = { id, paymentLiability: checked, siteId, siteRoleId };

    onUpdateSiteUser(params);
  };

  return (
    <TableRow>
      <TableCell className={classes.td}>
        <img src={avatar} alt={name} className={classes.img} />
        {name}
      </TableCell>
      <TableCell className={classes.td}>
        <Button
          className={classes.link}
          onClick={() => handleOpenUserDetails(email)}
        >
          {email}
        </Button>
      </TableCell>
      <TableCell className={classes.td}>{telephone}</TableCell>
      <TableCell className={classes.td}>{companyName}</TableCell>

      <TableCell className={classes.td}>
        {canManageSiteRoles ? (
          <CustomSelect
            name="siteRoleId"
            options={siteRoleOptions}
            value={siteRoleId}
            onChange={handleFormValues}
          />
        ) : (
          siteRoleName
        )}
      </TableCell>

      <TableCell className={classes.td}>
        {canManageSiteRoles ? (
          <Switch
            size="small"
            color="primary"
            name="paymentLiability"
            checked={paymentLiability}
            onChange={handleSwitchValues}
          />
        ) : paymentLiability ? (
          <FormattedMessage id="btn.yes" />
        ) : (
          <FormattedMessage id="btn.no" />
        )}
      </TableCell>

      <TableCell className={classes.td}>
        <FormattedDate value={lastLoggedIn} />
      </TableCell>
      <TableCell className={classes.td}>
        <FormattedMessage id={`siteUsers.${status}`} />
      </TableCell>
      {canInviteUsers && (
        <TableCell className={classes.td} align="right">
          {/* commented out this icon for now , will bring back once functionality is discussed */}
          {/* <IconLink icon="fa fa-edit" onClick={() => handleOpenEditUser(true)} /> */}
          <Tooltip
            title={<FormattedMessage id="btn.removeUser" />}
            placement="top"
          >
            <IconButton
              edge="end"
              aria-label="delete"
              className={classes.removeRow}
              onClick={() => setConfirmationModal(true)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {confirmationModal && (
            <ConfirmDeleteModal
              open={confirmationModal}
              setOpen={setConfirmationModal}
              onConfirmCloseModal={() => confirmDelete(siteId, id)}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

SiteUsersTableRow.defaultProps = {
  siteUser: null,
  handleOpenEditUser: null,
  siteRoleOptions: [],
};

SiteUsersTableRow.propTypes = {
  siteRoleOptions: PropTypes.arrayOf({}),
  siteUser: PropTypes.shape({
    avatar: PropTypes.string,
    companyName: PropTypes.string,
    email: PropTypes.string,
    lastLoggedIn: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    paymentLiability: PropTypes.bool,
    siteId: PropTypes.number,
    siteRoleId: PropTypes.number,
    siteRoleName: PropTypes.string,
    userId: PropTypes.number,
  }),
  handleOpenEditUser: PropTypes.func,
  handleOpenUserDetails: PropTypes.func.isRequired,
};

export default SiteUsersTableRow;

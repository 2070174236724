import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const INIT_DOCUMENT_FILE_UPLOAD = gql`
  mutation InitDocumentFileUpload($resourceId: ID!, $isAltFile: Boolean!) {
    initDocumentFileUpload(resourceId: $resourceId, isAltFile: $isAltFile) {
      errorResult {
        ...ErrorResultFields
      }
      url
      success
    }
  }
  ${ErrorResultFragment}
`;

export default INIT_DOCUMENT_FILE_UPLOAD;

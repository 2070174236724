import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { setSearchFolderId, setSearchFolderName } from "../../actions";
import CategorySkeleton from "../Skeleton/CategorySkeleton";
import RenderFolders from "./RenderFolders";

const useStyles = makeStyles(theme => ({
  folderListWrap: {
    marginBottom: 20,
    "& .MuiAccordionActions-root": {
      alignItems: "flex-start",
    },
  },
  siteName: {
    display: "inline-block",
    fontSize: 12,
    marginBottom: 0,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));

const FolderList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const detailedSearchCalled = useSelector(state => state.api.search.called);
  const error = useSelector(state => state.api.search.error);
  const siteFolders = useSelector(state => state.api.search.folders);
  const loading = useSelector(state => state.api.search.loading);

  const dispatchSelectedFolder = folder => {
    dispatch(setSearchFolderId({ id: folder.folderId }));
    dispatch(setSearchFolderName({ name: folder.folderName }));
  };

  if (loading) {
    return <CategorySkeleton />;
  }

  if (error) {
    return "An error occurred.";
  }

  if (detailedSearchCalled && siteFolders.length === 0) {
    return <FormattedMessage id="search.noResultsFound" />;
  }

  return (
    <Box className={classes.folderListWrap}>
      {siteFolders.map((x, idx) => {
        return (
          <RenderFolders
            folders={x.folders}
            handleClick={dispatchSelectedFolder}
            idx={idx}
            key={x.siteName}
            site={x.siteName}
          />
        );
      })}
    </Box>
  );
};

export default FolderList;

import { gql } from '@apollo/client';

const GET_CONSENT_REQUEST = gql`
  query getConsentRequest($token:String!){
	
	getConsentRequest(token:$token){
		email
		firstname
		lastname
		title
		token
		resources{
			resourceId
			url
		}
	}
}`;

export default GET_CONSENT_REQUEST;

import { useMutation } from "@apollo/client";
import { DELETE_SHARE } from "../../graphql/mutations/ShareContext";

// eslint-disable-next-line import/prefer-default-export
export const useDeleteShare = ({ onError, onSuccess }) => {
  const [deleteShare, { loading }] = useMutation(DELETE_SHARE);

  const handleDeleteShare = async ({ shareId }) => {
    try {
      const res = await deleteShare({ variables: { shareId } });
      if (
        res &&
        res.data &&
        res.data.shareContext &&
        res.data.shareContext.deleteShare
      ) {
        handleSuccess(shareId);
      } else {
        handleError(shareId, null);
      }
    } catch (e) {
      handleError(shareId, e);
    }
  };

  function handleError(shareId, e) {
    if (typeof onError === "function") {
      onError(e, { shareId });
    } else {
      console.error("** ", e);
      console.warn("** Missing 'onError' function.");
    }
  }

  function handleSuccess(shareId) {
    if (typeof onSuccess === "function") {
      onSuccess({ shareId });
    } else {
      console.warn("** Missing 'onSuccess' function.");
    }
  }

  return { execute: handleDeleteShare, loading };
};

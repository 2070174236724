import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CustomTextField from "../TextField";
import Folders from "./Folders";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
}));

const FolderSelector = ({ value, handleFolderRowClick }) => {
  const classes = useStyles();

  const [openedNodes, setOpenedNodes] = useState([]);
  const [openFolders, setOpenFolders] = useState(false);

  const handleRowClick = (e, folder) => {
    handleFolderRowClick(e, folder);
    setOpenFolders(false);
  };

  return (
    <Box className={classes.root}>
      <CustomTextField
        value={value.name}
        label={<FormattedMessage id="common.selectFolder" />}
        onClick={() => setOpenFolders(true)}
        defaultValue=" "
      />
      <Folders
        open={openFolders}
        handleClose={() => setOpenFolders(false)}
        openedNodes={openedNodes}
        setOpenedNodes={setOpenedNodes}
        handleFolderRowClick={handleRowClick}
      />
    </Box>
  );
};

FolderSelector.defaultProps = {
  open: null,
  value: null,
  handleOpen: null,
  handleClose: null,
  handlerFolderClick: null,
};

FolderSelector.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.shape({}),
  handleOpen: PropTypes.bool,
  handleClose: PropTypes.bool,
  handlerFolderClick: PropTypes.bool,
};

export default FolderSelector;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { alpha } from  "@mui/material";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import ConfirmDeleteModal from "../../components/common/ConfirmDeleteModal";
import SharedByMeTab from "../../components/MyShares/SharedByMeTab";
import SharedWithMeTab from "../../components/MyShares/SharedWithMeTab";
import { useLazyQuery } from "@apollo/client";
import { LIST_USER_SHARES } from "../../graphql/queries/ShareContext";
import { SHARE_TYPES } from "../../constant/shareTypes";
import {
  useDeleteShare,
  useRemoveCurrentUserFromShare,
} from "../../hooks/ShareContext";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    marginRight: 10,
  },
  btnAdd: {
    padding: "10px 15px",
    fontWeight: 600,
    lineHeight: 0.5,
    color: theme.palette.common.white,
    fontSize: 12,
    backgroundColor: theme.palette.primary.light,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.8),
    },
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: 15,
      marginBottom: 15,
    },
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
  },
  selectWrap: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    width: 300,
    "& .MuiIconButton-root": {
      padding: 5,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
  },
  paperBody: {
    padding: theme.spacing(4),
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MyShares() {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();
  const history = useHistory();

  const [deleteData, setDeleteData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [disableTables, setDisableTables] = useState(false);
  const [lists, setLists] = useState({});

  const clearLists = () => {
    setLists({
      sharedWithMe: [],
      userShares: [],
    });
  };

  useEffect(() => {
    clearLists();
    changeTab(0);
  }, []);

  const [
    listUserShares,
    {
      data: userSharesData,
      loading: loadingUserShares,
      error: errorLoadingUserShared,
    },
  ] = useLazyQuery(LIST_USER_SHARES);

  useEffect(() => {
    if (userSharesData && !loadingUserShares && !errorLoadingUserShared) {
      setLists(userSharesData.shareContext.listUserShares);
    }
  }, [userSharesData]);

  useEffect(() => {
    if (errorLoadingUserShared) {
      clearLists();
      alert.error(
        <FormattedMessage id="sharedImageBasket.error.errorFetchingList" />
      );
    }
  }, [errorLoadingUserShared]);

  const handleErrorRemoveCurrentUserFromShare = (e, { shareId }) => {
    alert.error(
      <FormattedMessage id="sharedImageBasket.error.errorRemovingRecipient" />
    );
  };

  const handleSuccessRemoveCurrentUserFromShare = ({ shareId }) => {
    alert.info(
      <FormattedMessage id="sharedImageBasket.success.recipientRemoved" />
    );
    var { sharedWithMe, userShares } = lists;
    var tempLists = {
      sharedWithMe: sharedWithMe.filter(n => n.shareId !== shareId),
      userShares,
    };
    setLists(tempLists);
  };

  const {
    execute: removeCurrentUserFromShare,
    loading: removingCurrentUser,
  } = useRemoveCurrentUserFromShare({
    onError: handleErrorRemoveCurrentUserFromShare,
    onSuccess: handleSuccessRemoveCurrentUserFromShare,
  });

  const handleErrorDeleteShare = (e, { shareId }) => {
    alert.error(
      <FormattedMessage id="sharedImageBasket.error.errorRemovingItem" />
    );
  };

  const handleSuccessDeleteShare = ({ shareId }) => {
    alert.info(<FormattedMessage id="sharedImageBasket.success.itemRemoved" />);
    var { sharedWithMe, userShares } = lists;
    var tempLists = {
      sharedWithMe,
      userShares: userShares.filter(n => n.shareId !== shareId),
    };
    setLists(tempLists);
  };

  const { execute: deleteShare, loading: deletingShare } = useDeleteShare({
    onError: handleErrorDeleteShare,
    onSuccess: handleSuccessDeleteShare,
  });

  const changeTab = newIndex => {
    listUserShares();
    setActiveTabIndex(newIndex);
  };

  const showRemoveFromShareModal = item => {
    var { shareId } = item;
    setDeleteData({ shareId, type: "REMOVE_CURRENT_USER_FROM_SHARE" });
    setDeleteModal(true);
  };

  const showDeleteShareModal = item => {
    var { shareId } = item;
    setDeleteData({ shareId, type: "DELETE_SHARE" });
    setDeleteModal(true);
  };

  const handleDeleteItem = async () => {
    var { shareId, type } = deleteData;
    if (type === "DELETE_SHARE") {
      await deleteShare({ shareId });
    } else if (type === "REMOVE_CURRENT_USER_FROM_SHARE") {
      await removeCurrentUserFromShare({ shareId });
    }
    setDeleteModal(false);
    setDeleteData(null);
  };

  const handleOnOpenItem = async item => {
    if (item.type === SHARE_TYPES.IMAGE_BANK) {
      history.push(`/shared-image-basket/${item.shareId}`);
    } else {
      alert.error(<FormattedMessage id="myShares.error.unhandledShareType" />);
    }
  };

  const handleTabChange = (event, newIndex) => {
    changeTab(newIndex);
  };

  return (
    <div className={classes.root}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTabIndex}
          onChange={handleTabChange}
          aria-label="Shared links."
        >
          <Tab
            label={intl.formatMessage({ id: "myShares.title.sharedWithMe" })}
            {...a11yProps(0)}
          />
          <Tab
            label={intl.formatMessage({ id: "myShares.title.sharedByMe" })}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={activeTabIndex} index={0}>
        <SharedWithMeTab
          classes={classes}
          items={lists.sharedWithMe}
          onDeleteItem={showRemoveFromShareModal}
          onOpenItem={handleOnOpenItem}
          loading={loadingUserShares}
          disabled={disableTables}
        />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={1}>
        <SharedByMeTab
          classes={classes}
          items={lists.userShares}
          onDeleteItem={showDeleteShareModal}
          onOpenItem={handleOnOpenItem}
          loading={loadingUserShares}
          disabled={disableTables}
        />
      </TabPanel>

      {deleteModal && (
        <ConfirmDeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          onConfirmCloseModal={handleDeleteItem}
        />
      )}
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Paper, Box, Typography, Button, Grid } from "@mui/material";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_SAVED_NOTIFICATIONS } from "../../graphql/queries";
import { CustomSelect } from "../../components/common";
import MyNotificationModal from "../../components/MyNotifications/MyNotificationModal";
import MyNotificationsTable from "../../components/MyNotifications/MyNotificationsTable";
import { FormattedMessage, useIntl } from "react-intl";
import { getSiteId } from "../../helpers/selectors";
import DateFull from "../../components/common/FormControl/DateFull";
import { getDateOnly } from "../../helpers/get";
import { selectWorkspace } from "../../actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paperBody: {
    padding: theme.spacing(2),
  },
  paperHead: {
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
    display: "flex",

    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    display: "block",
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 18,
    display: "inline-block",
    marginBottom: 10,
  },
  icon: {
    fontSize: 18,
    verticalAlign: "sub",
  },
  btn: {
    padding: "8px 15px",
    fontWeight: 600,
    marginTop: 10,
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  spacing: {
    margin: "10px 0",
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
}));

const SavedNotifications = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const siteId = useSelector(state => getSiteId(state));
  const workspaces = useSelector(state =>
    state.api.currentViewer &&
    state.api.currentViewer.viewer &&
    state.api.currentViewer.viewer.sites
      ? state.api.currentViewer.viewer.sites
      : []
  );

  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [filter, setFilter] = useState(siteId);

  const [fromDate, setFromDate] = useState(
    new Date().setDate(new Date().getDate() - 14)
  );
  const [toDate, setToDate] = useState(new Date());

  const [ascending, setAscending] = useState(true);
  const [sortField, setSortField] = useState("saveDate");

  const handleOnHeaderClick = headerName => {
    if (sortField === headerName) {
      setAscending(prevValue => !prevValue);
    } else {
      setSortField(headerName);
      setAscending(true);
    }
  };

  function updateWorkspacename(workspaceid) {
    if (workspaceid && workspaces && Array.isArray(workspaces)) {
      var workspace = workspaces.filter(x => x.id === workspaceid)[0];

      dispatch(
        selectWorkspace({
          id: workspace.id,
          name: workspace.name,
          siteRoleRights: workspace.siteRoleRights,
          defaultViewType: workspace.defaultViewType,
          siteRoleId: workspace.siteRoleId,
        })
      );
    }
  }

  const handleFromDateChange = date => {
    setFromDate(date);
  };

  const handleToDateChange = date => {
    setToDate(date);
  };

  const { data: notificationdata, loading, refetch } = useQuery(
    GET_SAVED_NOTIFICATIONS,
    {
      variables: {
        siteid: filter || 0,
        from: getDateOnly(fromDate),
        to: getDateOnly(toDate),
        sortColumn: sortField,
        ascending: ascending,
      },
    }
  );

  const [savedNotifications, setSavedNotifications] = useState([]);

  const useSearchParams = () => {
    return new URLSearchParams(useLocation().search);
  };
  const searchParams = useSearchParams();

  const [modalState, setModalState] = useState({
    savedNotificationId: null,
    open: false,
    opened: false,
  });

  const handleCloseModal = () => {
    setModalState({ savedNotificationId: null, open: false, opened: true });
  };

  const handleOnRequestWorkspace = requestedSiteId => {
    if (requestedSiteId !== siteId) {
      updateWorkspacename(requestedSiteId);
    }
  };

  useEffect(() => {
    const paramValue = searchParams.get("savedNotificationId");
    if (!!paramValue && !modalState.opened) {
      setModalState({
        savedNotificationId: parseInt(paramValue),
        open: true,
        opened: true,
      });
    }
  }, []);

  useEffect(() => {
    if (
      notificationdata &&
      notificationdata.myNotificationContext &&
      notificationdata.myNotificationContext.getSavedNotifications
    ) {
      setSavedNotifications(
        notificationdata.myNotificationContext.getSavedNotifications
      );
    }
  }, [notificationdata]);

  useEffect(() => {
    if (workspaces && Array.isArray(workspaces) && workspaces.length > 0) {
      const tempValue = workspaces.map(x => {
        return { label: x.name, value: x.id };
      });
      setWorkspaceOptions(tempValue);
      updateWorkspacename(siteId);
    }
  }, [workspaces]);

  /*const fetchNotifications = () => {
    getSavedNotification({
      variables: {
        siteid: filter,
        from: "1970-01-01",
        to: "2100-01-01",
      },
    });
  };*/

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper className={classes.paper} elevation={0}>
            <Typography variant="h1" component="h2" className={classes.title}>
              <FormattedMessage id="account.notificationsHistory" />
            </Typography>
            <Typography className={classes.label}>
              <FormattedMessage id="workspace.workspace" />
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={12}>
                <CustomSelect
                  inputLabel={<FormattedMessage id="monitoring.filter" />}
                  options={workspaceOptions}
                  onChange={e => {
                    setFilter(e.target.value);
                    updateWorkspacename(e.target.value);
                  }}
                  value={filter}
                />
              </Grid>
            </Grid>
            <Typography className={classes.label}>
              <FormattedMessage id="monitoring.date" />
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <DateFull
                  label={intl.formatMessage({ id: "orderHistorySearch.from" })}
                  value={fromDate}
                  setDate={handleFromDateChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DateFull
                  label={intl.formatMessage({ id: "orderHistorySearch.to" })}
                  value={toDate}
                  setDate={handleToDateChange}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper className={classes.paper} elevation={0}>
            <Box className={classes.paperBody}>
              <MyNotificationsTable
                savedNotifications={savedNotifications}
                loading={loading}
                ascending={ascending}
                handleOnHeaderClick={handleOnHeaderClick}
                sortField={sortField}
              />
              {modalState.open && (
                <MyNotificationModal
                  open={modalState.open}
                  handleClose={handleCloseModal}
                  savedNotificationId={modalState.savedNotificationId}
                  notificationName={""}
                  onRequestWorkspace={handleOnRequestWorkspace}
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SavedNotifications;

import { handleActions } from "redux-actions";
import {
  setSupplierSettings,
  setSupplierSettingsLoading,
  setSupplierSettingsError,
} from "../../../../actions";

const defaultState = {
  items: {},
  loading: false,
  error: null,
};

export default handleActions(
  {
    [setSupplierSettings]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        items,
      };
    },
    [setSupplierSettingsLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        loading,
      };
    },
    [setSupplierSettingsError]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        error,
      };
    },
  },
  defaultState
);

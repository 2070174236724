import { gql } from '@apollo/client';

const getOrderHistoryView = gql`
  query GetOrderHistoryView($orderGroupId: Int!, $siteId: Int!) {
    orderHistoryView(orderGroupId: $orderGroupId, siteId: $siteId) {
      settings {
        defaultColumnOrder
        defaultSortColumn
        defaultSortDirection
        canSeeAllOrderHistory
      }
      detailSettings {
        deliveryAddressSettings {
          ...addressSettings
        }
        invoiceAddressSettings {
          ...addressSettings
        }
        postalAddressSettings {
          ...addressSettings
        }
        showComment
        showCostCenter
        showDeliveryAddress
        showDeliveryDate
        showEmail
        showInvoiceAddress
        showOrderStatus
        showPackageColumn
        showPostalAddress
        showPriceColumn
        showProductColumn
        showQuantityColumn
        showSummation
        showWeightColumn
      }
    }
  }

  fragment addressSettings on AddressSettings {
    address1 {
      ...addressSetting
    }
    address2 {
      ...addressSetting
    }
    address3 {
      ...addressSetting
    }
    city {
      ...addressSetting
    }
    contact {
      ...addressSetting
    }
    country {
      ...addressSetting
    }
    customerId {
      ...addressSetting
    }
    email {
      ...addressSetting
    }
    isHidden
    reference {
      ...addressSetting
    }
    telephone {
      ...addressSetting
    }
    zip {
      ...addressSetting
    }
  }

  fragment addressSetting on AddressSetting {
    displayOrder
    label
    type
  }
`;

export default getOrderHistoryView;

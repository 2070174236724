import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import NewsHead from "../../components/News-Old/NewsHead";
import { useToggle } from "../../hooks/common";
import NewsInformationModal from "./NewsInformationModal";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
  iframeWrap: {
    width: "99.8%",
    height: "95vh",
    // marginTop: "5%"
  },
}));

const NewsFullPage = ({ match, location }) => {
  const { params } = match || {};
  const { id } = params || {};
  const classes = useStyles();
  const { state = {} } = location;
  const { showInfoModal } = state;
  const { on: isInfoModalDisplayed, toggle: toggleInfoModal } = useToggle({
    initialValue: showInfoModal,
  });
  return (
    <Container maxWidth={false} className={classes.root}>
      <NewsHead />
      <iframe
        title="page-builder"
        className={classes.iframeWrap}
        src={`${process.env.REACT_APP_PAGE_BUILDER_URL}/?id=${id}`}
      />
      {isInfoModalDisplayed && (
        <NewsInformationModal
          open={isInfoModalDisplayed}
          toggleOpen={toggleInfoModal}
        />
      )}
    </Container>
  );
};

NewsFullPage.defaultProps = {
  location: {
    state: {
      showInfoModal: false,
    },
  },
};

NewsFullPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      showInfoModal: PropTypes.bool,
    }),
  }),
};

export default NewsFullPage;

import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { TableRow } from "@mui/material";
import NameTableCell from "./NameTableCell";
import QuantityTableCell from "./QuantityTableCell";
import NotifyTableCell from "./NotifyTableCell";

const FileItemRow = ({
  handleCheckboxChange,
  isSelected,
  quantityProperty,
  resource,
  folder,
  productionProperties,
  handleFileNotifyChange,
  isIncluded,
  onChangeQuantity,
  folderOrderQuantity,
  resourceQuantity,
  folderFormValues,
  itemFormValue,
  handleRemoveFileSettings,
  selectedFoldersProperties,
  folderMandatoryFields,
  quantity,
}) => {
  const { folderId } = folder;

  // const [quantity, setQuantity] = useState(resourceQuantity);

  // useEffect(() => {
  //   if (selectedFoldersProperties.length > 0) {
  //     setQuantity(folderOrderQuantity);
  //   }
  // }, [folderOrderQuantity]);

  // useEffect(() => {
  //   setQuantity(resourceQuantity);
  // }, [resourceQuantity]);
  return (
    <TableRow>
      <NameTableCell
        handleCheckboxChange={handleCheckboxChange}
        isSelected={isSelected}
        resource={resource}
        folder={folder}
        productionProperties={productionProperties}
        folderFormValues={folderFormValues}
        itemFormValue={itemFormValue}
        handleRemoveFileSettings={handleRemoveFileSettings}
      />
      <QuantityTableCell
        quantityProperty={quantityProperty}
        onChangeQuantity={onChangeQuantity}
        resource={resource}
        folderId={folderId}
        quantity={quantity}
        isSelected={isSelected}
        isIncluded={isIncluded}
      />
      <NotifyTableCell
        folderMandatoryFields={folderMandatoryFields}
        handleFileNotifyChange={handleFileNotifyChange}
        isIncluded={isIncluded}
        resource={resource}
        folderId={folderId}
      />
    </TableRow>
  );
};

FileItemRow.defaultProps = {
  isSelected: false,
  productionProperties: [],
};

FileItemRow.propTypes = {
  handleRemoveFileSettings: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  handleFileNotifyChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  quantityProperty: PropTypes.shape({
    quantityType: PropTypes.string,
  }).isRequired,
  resource: PropTypes.shape({
    fileExtension: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  folder: PropTypes.shape({ folderId: PropTypes.number }).isRequired,
  productionProperties: PropTypes.arrayOf(PropTypes.shape({})),
};

// function areEqual(prevProps, nextProps) {
//   if (nextProps.handleCheckboxChange !== prevProps.handleCheckboxChange) {
//     return false;
//   }
//   if (nextProps.isSelected !== prevProps.isSelected) {
//     return false;
//   }
//   if (nextProps.quantityProperty !== prevProps.quantityProperty) {
//     return false;
//   }
//   if (nextProps.resource !== prevProps.resource) {
//     return false;
//   }
//   if (nextProps.folder !== prevProps.folder) {
//     return false;
//   }
//   if (nextProps.productionProperties !== prevProps.productionProperties) {
//     return false;
//   }
//   if (nextProps.handleFileNotifyChange !== prevProps.handleFileNotifyChange) {
//     return false;
//   }

//   if (nextProps.isNotifyEnabled !== prevProps.isNotifyEnabled) {
//     return false;
//   }

//   if (nextProps.onChangeQuantity !== prevProps.onChangeQuantity) {
//     return false;
//   }
//   return true;
// }

export default memo(FileItemRow);

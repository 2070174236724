import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  headingWrap: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    marginRight: "auto",
  },
  text: {
    marginBottom: 10,
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    textTransform: "uppercase",
    fontWeight: 600,
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
    //"&.Mui-disabled": {
    //  backgroundColor: theme.palette.primary.dark,
    //  color: theme.palette.primary.light,
    //  opacity: 0.5,
    //},
  },
  btnWrap: {
    marginTop: 40,
    "& button:first-of-type": {
      marginRight: 5,
    },
  },
}));

const ConvertActions = ({
  allImageOption,
  selectImageOption,
  downloadOptions,
  shareLinkOptions,
}) => {
  const classes = useStyles();
  const showActions = allImageOption || selectImageOption;
  return (
    <>
      {showActions && (
        <Box>
          <Box className={classes.headingWrap}>
            <Typography className={classes.heading}>
              3.{" "}
              <FormattedMessage id="imageBasket.chooseHowYouWantToDistribute" />
            </Typography>
          </Box>
          <Box className={classes.btnWrap} align="center">
            {downloadOptions.display && (
              <Button
                className={classes.btn}
                disabled={!downloadOptions.enable}
                onClick={downloadOptions.onClick}
              >
                <FormattedMessage id="btn.download" />
              </Button>
            )}
            {shareLinkOptions.display && (
              <Button
                className={classes.btn}
                disabled={!shareLinkOptions.enable}
                onClick={shareLinkOptions.onClick}
              >
                <FormattedMessage id="btn.share" />
              </Button>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

ConvertActions.defaultProps = {
  downloadOptions: {
    enable: false,
    display: false,
    onClick: () => {},
  },
  shareLinkOptions: {
    enable: false,
    display: false,
    onClick: () => {},
  },
};

ConvertActions.propTypes = {
  allImageOption: PropTypes.bool.isRequired,
  selectImageOption: PropTypes.bool.isRequired,
  downloadOptions: PropTypes.shape({
    enable: PropTypes.bool,
    display: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  shareLinkOptions: PropTypes.shape({
    enable: PropTypes.bool,
    display: PropTypes.bool,
    onClick: PropTypes.func,
  }),
};

export default ConvertActions;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import Tooltip from "@mui/material/Tooltip";
import { FormattedMessage } from "react-intl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { REMOVE_SUPPLIER_ALIAS } from "../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
  removeRow: {
    padding: 3,
    color: theme.palette.secondary.dark,
    marginRight: 0,
    "& svg": {
      width: "17px !important",
      height: "17px !important",
    },
  },
}));

const SupplierRow = ({ supplier, setSupplierList, idx }) => {
  const classes = useStyles();
  const alert = useAlert();
  const { supplierAlias, supplierAliasRequestId } = supplier;
  const [deleteModal, setDeleteModal] = useState(false);
  const [removeSupplierAlias] = useMutation(REMOVE_SUPPLIER_ALIAS);

  const handleRemoveSupplier = async () => {
    try {
      const res = await removeSupplierAlias({
        variables: { supplierAliasId: supplierAliasRequestId },
      });
      if (res && res.data && res.data.removeSiteSupplierAlias) {
        setSupplierList(suppliers => {
          const tempSupplier = [...suppliers];

          tempSupplier.splice(idx, 1);

          return tempSupplier;
        });
        alert.succes(<FormattedMessage id="supplier.removedSupplierAlias" />);
      } else {
        alert.error(
          <FormattedMessage id="supplier.errorRemovingSupplierAlias" />
        );
      }
    } catch (err) {
      console.error("error >", err);
    }
    setDeleteModal(false);
  };

  return (
    <TableRow>
      <TableCell className={classes.td}>{supplierAlias}</TableCell>
      {/* <TableCell className={classes.td}>{requestedAt}</TableCell> */}
      <TableCell className={classes.td}>
        <Tooltip
          title={<FormattedMessage id="btn.removeUser" />}
          placement="top"
        >
          <IconButton
            edge="end"
            aria-label="delete"
            className={classes.removeRow}
            onClick={() => setDeleteModal(true)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      {deleteModal && (
        <ConfirmDeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          onConfirmCloseModal={handleRemoveSupplier}
        />
      )}
    </TableRow>
  );
};

SupplierRow.defaultProps = {
  supplier: null,
  idx: null,
};

SupplierRow.propTypes = {
  idx: PropTypes.number,
  setSupplierList: PropTypes.func.isRequired,
  supplier: PropTypes.shape({
    supplierAlias: PropTypes.string,
    supplierAliasRequestId: PropTypes.number,
  }),
};

export default SupplierRow;

import { lazy, Suspense, useMemo, useRef } from "react";
//import JoditEditor from "jodit-react";
import { fontList } from "./helper";
//import "ace-builds/src-noconflict/theme-idle_fingers";
//import "ace-builds/src-noconflict/mode-html";

const JoditEditor = lazy(() => import("jodit-react"));

const JoditTextEditor = ({ onBlur, onCancel, onSave, placeholder, readonly, value, config = {} }) => {
  const editor = useRef(null);

  const defaultConfig = useMemo(
    () => ({
      autofocus: true,
      inline: readonly,
      placeholder: placeholder || "Start writing...",
      readonly: readonly,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      //cleanHTML: {
      //  fillEmptyParagraph: false,
      //},
      style: {
        padding: '4px',
      },
      toolbar: !readonly,
      uploader: {
        insertImageAsBase64URI: true,
      },
      useSearch: false,
      zIndex: 5,
      controls: {
        font: {
          list: fontList,
        },
      },
      /*sourceEditorNativeOptions : {
          showGutter: true,
          theme: 'ace/theme/idle_fingers',
          mode: 'ace/mode/html',
          wrap: true,
          highlightActiveLine: true
      },*/
      toolbarButtonSize: "small",
      disablePlugins: "class-span,clean-html,clipboard,inline-popup,key-arrow-outside,limit,line-height,link,mobile,media,xpath,wrap-nodes,table,video,table-keyboard-navigation,tab,symbols,sticky,stat,spellcheck,speech-recognize,source,size,select-cells,select,search,resizer,resize-handler,resize-cells,print,preview,powered-by-jodit,placeholder,about,copyformat,drag-and-drop,drag-and-drop-element,dtd,enter,file,focus,format-block,fullsize,hotkeys,hr,iframe,image,image-processor,image-properties,indent,delete-command,backspace,ai-assistant,add-new-line",
      buttons: "bold,italic,underline,strikethrough,ul,ol,font,fontsize,paragraph,brush,|",
      toolbarAdaptive: false,

      extraIcons: {
        someIcon: `<span class="fas fa-tint-slash" />`,
      },
      extraButtons: [
        {
          icon: "someIcon",
          tooltip: "Makes background transparent",
          exec: function (editor) {
            editor.execCommand("backColor", false, "#FFFFFF00");
          },
        },
        {
          name: "Save content",
          icon: "save",
          tooltip: "Save current content",
          exec: function (e) {
            const { value } = e;
            onSave?.(value);
          },
        },
        {
          name: "Avbryt",
          icon: "cancel",
          tooltip: "Avbryt redigering",
          exec: function (e) {
            onCancel?.();
          },
        },
      ],
      events: {},
    }),
    [placeholder, readonly, onCancel, onSave]
  );

  const mergedConfig = useMemo(
    () => ({
      ...defaultConfig,
      ...config,
      events: {
        ...defaultConfig.events,
        ...config.events,
      },
    }),
    [defaultConfig, config]
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <JoditEditor
        config={mergedConfig}
        onBlur={newContent => onBlur?.(newContent)} // preferred to use only this option to update the content for performance reasons
        ref={editor}
        tabIndex={1} // tabIndex of textarea
        value={value}
      />
    </Suspense>
  );
};

export default JoditTextEditor;

import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const TableComponent = ({
  ascending,
  handleOnHeaderClick,
  headers,
  loading,
  orderGroupId,
  orderHistoryDetailSettings,
  orders,
  sortField,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
}) => {
  return (
    <Table size="small">
      <TableHeader
        ascending={ascending}
        handleOnHeaderClick={handleOnHeaderClick}
        headers={headers}
        sortField={sortField}
      />
      <TableBody
        loading={loading}
        orderGroupId={orderGroupId}
        orderHistoryDetailSettings={orderHistoryDetailSettings}
        orders={orders}
        selectedOrders={selectedOrders}
        addSelectedOrder={addSelectedOrder}
        removeSelectedOrder={removeSelectedOrder}
      />
    </Table>
  );
};

TableComponent.defaultProps = {
  loading: false,
  orderGroupId: null,
  orderHistoryDetailSettings: null,
};

TableComponent.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  handleOnHeaderClick: PropTypes.func.isRequired,
  orderGroupId: PropTypes.number,
  loading: PropTypes.bool,
  ascending: PropTypes.bool.isRequired,
  sortField: PropTypes.string.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.number.isRequired,
      orderDate: PropTypes.string,
      username: PropTypes.string,
      deliveryAddress1: PropTypes.string,
      deliveryAddress2: PropTypes.string,
      deliveryReference: PropTypes.string,
      deliveryCity: PropTypes.string,
      sumInclVat: PropTypes.number,
      deliveryDateWish: PropTypes.string,
      costPlaceOrder: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  orderHistoryDetailSettings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
  selectedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
};

export default TableComponent;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useLazyQuery } from "@apollo/client";
// eslint-disable-next-line import/no-cycle
import EditProductTabs from "./EditProductTabs";
import { ConfirmCloseModal } from "../../common";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import {
  GET_PRODUCT_EDIT,
  GET_DISPLAY_TAB_VIEW,
} from "../../../graphql/queries";
import { DELETE_RESOURCE } from "../../../graphql/mutations";
import DeleteModal from "../FileView/DeleteModal";
import {
  RESOURCE_LOCKED,
  FOLDER_LOCKED,
  FOLDER_WRITE_ACCESS,
  FOLDER_READ_ACCESS,
} from "../../../constant/errorCodes";
// eslint-disable-next-line import/no-cycle
import { useSnackbar } from "../../../hooks";
import { addMetadata, initMetadata } from "../../../actions";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1100px",
    minHeight: "97vh",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  customBody: {
    backgroundColor: theme.palette.background.cream,
  },
}));

const EditProductModal = ({
  handleClose,
  open,
  resourceId,
  refetchResources,
  folderId,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const [product, setProductEdit] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteResource] = useMutation(DELETE_RESOURCE);

  const [
    getProductEdit,
    { data: productEditData, loading, refetch: refetchProduct },
  ] = useLazyQuery(GET_PRODUCT_EDIT);

  useEffect(() => {
    if (open) {
      getProductEdit({ variables: { resourceId } });
    }
  }, [open, resourceId]);

  useEffect(() => {
    if (
      productEditData &&
      productEditData.productEdit &&
      productEditData.productEdit.getProduct
    ) {
      const { customMetadata = [] } = productEditData.productEdit.getProduct;
      if (customMetadata.length > 0) {
        dispatch(initMetadata());
        customMetadata.map(meta => {
          dispatch(addMetadata({ value: meta }));
          return meta;
        });
      }

      setProductEdit(productEditData.productEdit.getProduct);
    }
  }, [productEditData]);

  // Draft states
  const [cooperationIsDraft, setCooperationIsDraft] = useState(false);
  const [productInfoIsDraft, setProductInfoIsDraft] = useState(false);
  const [groupsIsDraft, setGroupsIsDraft] = useState(false);
  const [stockIsDraft, setStockIsDraft] = useState(false);
  const [deliveryIsDraft, setDeliveryIsDraft] = useState(false);
  const [viewingIsDraft, setViewingIsDraft] = useState(false);

  const [confirmCloseModal, setConfirmCloseModal] = useState(false);

  const onClose = () => {
    if (
      cooperationIsDraft ||
      productInfoIsDraft ||
      groupsIsDraft ||
      stockIsDraft ||
      deliveryIsDraft ||
      viewingIsDraft
    ) {
      setConfirmCloseModal(true);
    } else {
      handleClose();
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };
  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleError = error => {
    const { data, errorCode } = error;

    const person = data[0].value;

    if (errorCode === RESOURCE_LOCKED) {
      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: product.name, person }
        )
      );
    }

    if (errorCode === FOLDER_LOCKED) {
      const lockedFolder = data[1].value;

      snackbar.workspaceError(
        intl.formatMessage(
          {
            id: "resources.errorDeletingResource",
          },
          { isLocked: lockedFolder, person }
        )
      );
    }

    if (errorCode === FOLDER_READ_ACCESS || errorCode === FOLDER_WRITE_ACCESS) {
      snackbar.workspaceError(
        intl.formatMessage({
          id: "folder.noWriteAccess",
        })
      );
    }

    console.error(error.errorMessage);
  };

  const handleDeleteResource = async () => {
    if (open) {
      handleClose();
    }
    handleDeleteModalClose();

    try {
      const res = await deleteResource({
        variables: { resourceId },
      });
      if (res && res.data && res.data.deleteResource) {
        const { success } = res.data.deleteResource;
        if (success) {
          alert.success(<FormattedMessage id="product.deleted" />);
          refetchResources();
        } else {
          const { errorResult } = res.data.deleteResource;
          handleError(errorResult);
        }
      }
    } catch (e) {
      console.error("error >", e);
    }
  };

  return (
    <>
      <DraggableModal
        open={open}
        handleClose={onClose}
        customClass={classes.modal}
      >
        <DraggableModalHead
          handleClose={onClose}
          title={<FormattedMessage id="product.edit" />}
        />
        <DraggableModalBody customClass={classes.customBody}>
          <EditProductTabs
            draftStates={{
              cooperationIsDraft,
              setCooperationIsDraft,
              productInfoIsDraft,
              setProductInfoIsDraft,
              groupsIsDraft,
              setGroupsIsDraft,
              stockIsDraft,
              setStockIsDraft,
              viewingIsDraft,
              setViewingIsDraft,
              deliveryIsDraft,
              setDeliveryIsDraft,
            }}
            folderId={folderId}
            image={
              product && product.thumbnailImageUri
                ? product.thumbnailImageUri
                : ""
            }
            product={product}
            handleDeleteModalOpen={handleDeleteModalOpen}
            refetchProduct={refetchProduct}
            resourceId={resourceId}
            handleError={handleError}
            loading={loading}
          />
        </DraggableModalBody>
      </DraggableModal>
      <ConfirmCloseModal
        open={confirmCloseModal}
        setOpen={setConfirmCloseModal}
        onConfirmCloseModal={() => {
          handleClose();
          setConfirmCloseModal(false);
        }}
      />
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          handleClose={handleDeleteModalClose}
          name={product && product.name ? product.name : ""}
          handleDelete={handleDeleteResource}
          isSelected={false}
          selectedResources={[]}
          handleMultipleDelete={() => {}}
          deleteLoading={false}
        />
      )}
    </>
  );
};

EditProductModal.defaultProps = {
  displaySettings: {},
  folderId: null,
  metaData: {},
  product: {},
  refetchResources: () => {},
  stockSettings: {},
};

EditProductModal.propTypes = {
  displaySettings: PropTypes.shape({}),
  folderId: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  metaData: PropTypes.shape({}),
  open: PropTypes.bool.isRequired,
  product: PropTypes.shape({}),
  refetchResources: PropTypes.func,
  resourceId: PropTypes.string.isRequired,
  stockSettings: PropTypes.shape({}),
};

export default EditProductModal;

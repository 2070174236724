import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormattedMessage } from "react-intl";
import Checkbox from "../../../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  th: {
    color: theme.palette.primary.main,
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "0 10px",
  },
  thCheckbox: {
    padding: 0,
    paddingLeft: 10,
    width: 35,
  },
}));

const ResourceTableHeader = ({
  setSelectedResources,
  selectAllResources,
  selectNoResources,
}) => {
  const classes = useStyles();
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = e => {
    const { checked } = e.target;
    if (checked) {
      setSelectAll(true);
      selectAllResources();
    } else {
      setSelectAll(false);
      selectNoResources();
    }
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.thCheckbox}>
          <Checkbox value={selectAll} onChange={handleCheckboxChange} />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="product.name" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="metadata.datetime" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="metadata.responsible" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="metadata.place" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="metadata.photographer" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="metadata.model" />
        </TableCell>
        <TableCell className={classes.th}>
          <FormattedMessage id="product.tagging" />
        </TableCell>
        <TableCell className={classes.th} />
      </TableRow>
    </TableHead>
  );
};

ResourceTableHeader.defaultProps = {};

ResourceTableHeader.propTypes = {
  setSelectedResources: PropTypes.func.isRequired,
  selectAllResources: PropTypes.func.isRequired,
};

export default ResourceTableHeader;

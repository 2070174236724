import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Icon, Typography, Button } from "@mui/material";
import clsx from "clsx";
import PreflightTable from "./PreflightTable";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
  },
}));

const Document = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>
          Document Inspector
        </Typography>
      </Box>
      <Box className={classes.body}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography className={classes.heading}>
              Preflight settings
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} align="right">
            <Button className={classes.addLink}>
              <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
              Add new preflight setting
            </Button>
          </Grid>
        </Grid>
        <Box mb={2} />
        <Grid container>
          <Grid item xs={12}>
            <PreflightTable />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Document;

import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  link: props => ({
    fontSize: props.fontSize,
    color:
      props.color === "warning"
        ? theme.palette.secondary.dark
        : theme.palette.common.link,
    "&:hover": {
      cursor: "pointer",
      color:
        props.color === "warning"
          ? theme.palette.secondary.dark
          : theme.palette.common.link,
      textDecoration: "none",
      opacity: 0.8,
    },
  }),
  icon: props => ({
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: props.fontSize,
    marginRight: 8,
  }),
}));

const IconTextLink = ({
  onClick,
  iconClass,
  text,
  fontSize,
  color,
  customClass,
}) => {
  const dynamicStyle = {
    fontSize,
    color,
  };
  const classes = useStyles(dynamicStyle);

  return (
    <Box
      onClick={() => onClick()}
      className={clsx([classes.link, customClass])}
    >
      <Icon className={clsx([iconClass, classes.icon])} />
      {text}
    </Box>
  );
};

IconTextLink.defaultProps = {
  fontSize: 12,
  color: "blue",
  customClass: null,
};

IconTextLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconClass: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  customClass: PropTypes.shape,
};

export default IconTextLink;

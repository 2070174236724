import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 18,
    width: "unset",
    height: "unset",
    color: "rgba(0,0,0,0.42)",
  },
}));

const FaIcon = ({ icon }) => {
  const classes = useStyles();

  return <Icon className={clsx([icon, classes.icon])} />;
};

export default FaIcon;

FaIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

import { gql } from '@apollo/client';
import ShareListFragment from "../../fragments/ShareContext/ShareListDTO";
import ImageBankItemFragment from "../../fragments/ShareContext/ImageBankItem";

const GET_SHARE_DETAILS = gql`
  query GetShareDetails($shareId: Int!) {
    shareContext {
      getShareDetails(shareId: $shareId) {
        details {
          ...ShareListFragment
        }
        #imageDownloadOptions
        items {
          ...ImageBankItemFragment
        }
        __typename
      }
    }
  }
  ${ShareListFragment}
  ${ImageBankItemFragment}
`;

export default GET_SHARE_DETAILS;

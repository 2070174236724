import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { debounce, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import Image from "../../../assets/img/sweden-1.jpg";
import { GET_PUBLIC_COMPANY } from "../../../graphql/queries";
import { useSnackbar } from "../../../hooks";
import { CustomSelect } from "../../common";
import CompanySelector from "./CompanySelector";

const useStyles = makeStyles(theme => ({
  tr: {
    "& td": {
      fontSize: 11,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 12,
      paddingBottom: 12,
      "&.notSelected div.MuiInputBase-formControl": {
        border: "2px solid",
      }
    },
    "& p": {
      fontSize: 12,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-input": {
      fontSize: 11,
      paddingTop: 8,
      paddingBottom: 8,
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
    },
    "& td.email": {
      whiteSpace: "noWrap",
    },
    "& td.company": {
      minWidth: "20em",
      width: "20em",
    },
    "& td.companyId": {
      minWidth: "10.5em",
      width: "10.5em",
      "& .Mui-disabled": {
        backgroundColor: "#f3f3f3 !important",
      },
    },
    "& td.role": {
      minWidth: "20em",
      width: "20em",
    },
    "& td.payment": {
      maxWidth: "13em",
      width: "13em",
    },
    "& td.action": {
      maxWidth: "3em",
      width: "3em",
    },
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    minWidth: "unset",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  img: {
    width: 20,
    height: 20,
    objectFit: "cover",
    borderRadius: "50%",
    verticalAlign: "middle",
    marginRight: 10,
  },
  removeRow: {
    padding: 3,
    color: theme.palette.secondary.dark,
    marginRight: 0,
    "& svg": {
      width: "17px !important",
      height: "17px !important",
    },
  },
  formControl: {
    fontSize: 12,
    //padding: "10px 12px 11px 12px",
    color: "#66615b",
    height: "100%",
    top: "-0.5em",
  },
}));

const HelpUserTableRow = ({
  companyList,
  existingEmail,
  idx,
  invitation,
  isExistingUser,
  preparedInvitations,
  setNewCompanyModal,
  setPreparedInvitations,
  siteRoleOptions,
}) => {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [getPublicCompany, { data }] = useLazyQuery(GET_PUBLIC_COMPANY);

  const {
    companyName,
    companyNotSelected,
    email,
    highlightedCompanyIds,
    knownUser,
    paymentLiable,
    selectedCompanyId,
    siteRoleId,
  } = invitation || {};

  const [haveValidCompanyId, setHaveValidCompanyId] = useState(false);

  const [highlightedCompanyList, setHighlightedCompanyList] = useState([
    ...companyList,
  ]);

  const [inactivated, setInactivated] = useState(false);

  const handleInvitationChange = (index, e) => {
    const { target } = e;
    const { checked, name: key, value } = target;

    switch (key) {
      case "paymentLiable":
        updatePreparedInvitation({ index, props: { [key]: checked } });
        break;

      case "selectedCompanyId":
        updatePreparedInvitation({ index, props: { [key]: value, publicCompanyId: value } });
        const company = companyList.find(
          x => x.publicCompanyId === value
        );
        if (!isEmpty(company)) {
          updateInvitationCompanyData(index, company);
        }
        else if (validateCompanyId(value)) {
          performPublicCompanySearch(email, value);
        }
        break;

      case "siteRoleId":
        updatePreparedInvitation({ index, props: { [key]: value } });
        break;
    }
  };

  const handleSelectCompany = (index, company) => {
    const { companyName, publicCompanyId } = company || {};

    var props = {
      companyName,
      selectedCompanyId: publicCompanyId,
      publicCompanyId,
    };

    updatePreparedInvitation({ index, props });
  };

  const performPublicCompanySearch = useCallback(
    debounce((username, publicCompanyId) => {
      getPublicCompany({
        variables: { username, publicCompanyId },
      });
    }, 500),
    []
  );

  const removePreparedInvitationRow = (index) => {
    setPreparedInvitations((prev) => {
      const temp = [...prev];
      temp.splice(index, 1);
      return temp;
    });
  };

  const resetPaymentLiability = index => {
    updatePreparedInvitation({ index, props: { paymentLiable: false } });
  };

  const updateInvitationCompanyData = (index, company) => {
    const { companyName, publicCompanyId } = company || {};

    const props = {
      companyName,
      selectedCompanyId: publicCompanyId,
      publicCompanyId,
    };

    updatePreparedInvitation({ index, props });
  };

  const updatePreparedInvitation = ({ index, props }) => {
    setPreparedInvitations((prev) => {
      const temp = [...prev];
      temp[index] = { ...temp[index], ...props };
      return temp;
    });
  };

  const validateCompanyId = (value) => /^\d{3}-\d{3}-\d{3}$/.test(value);

  useEffect(() => {
    if (!companyName && !haveValidCompanyId && !isExistingUser) {
      resetPaymentLiability(idx);
    }
  }, [companyName, haveValidCompanyId, idx, isExistingUser]);

  useEffect(() => {
    const temp = [...companyList];
    if (
      Array.isArray(highlightedCompanyIds) &&
      highlightedCompanyIds.length > 0
    ) {
      companyList.forEach((x, i) => {
        if (highlightedCompanyIds.includes(x.publicCompanyId)) {
          temp.splice(i, 1);
          temp.unshift(x);

          setHighlightedCompanyList(temp);
        }
      });
    } else {
      setHighlightedCompanyList(temp);
    }
  }, [companyList, highlightedCompanyIds]);

  useEffect(() => {
    if (
      data &&
      data.siteInvitationContext &&
      data.siteInvitationContext.getPublicCompany
    ) {
      const {
        item,
        notFound,
        displayWarning,
        inactivated,
        remainingAttempts,
        attemptsMade,
      } = data.siteInvitationContext.getPublicCompany;

      if (notFound) {
        if (inactivated) {
          snackbar.workspaceError(
            <FormattedMessage id="siteUsers.inactivateSearch" />
          );
          setInactivated(inactivated);
        } else {
          setInactivated(inactivated);
        }

        if (displayWarning && !inactivated) {
          snackbar.workspaceError(
            <FormattedMessage
              id="siteUsers.warning"
              values={{ attemptsMade, remainingAttempts }}
            />
          );
        }
      } else {
        updateInvitationCompanyData(idx, item);

        const found = companyList.find(
          x =>
            x.publicCompanyId ===
            data.siteInvitationContext.getPublicCompany.publicCompanyId
        );
        if (isEmpty(found)) {
          setHighlightedCompanyList([...companyList, item]);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const isValid = validateCompanyId(selectedCompanyId);
    setHaveValidCompanyId(isValid);
  }, [selectedCompanyId]);

  if (isExistingUser) {
    return (
      <TableRow className={classes.tr}>
        <TableCell>
          <img src={Image} alt="" className={classes.img} />
          <Button className={classes.link}>{email || existingEmail}</Button>
        </TableCell>

        <TableCell colspan={5}>
          <FormattedMessage id="siteRoles.theUserIsAlreadyAMember" />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow className={classes.tr}>
      <TableCell className="email">
        <img src={Image} alt="" className={classes.img} />
        <Button className={classes.link}>{email || existingEmail}</Button>
      </TableCell>

      <TableCell className={clsx(["companyId", companyNotSelected ? "notSelected" : ""])}>
        <InputMask
          class={classes.formControl}
          disabled={knownUser || inactivated}
          fullWidth
          mask="999-999-999"
          name="selectedCompanyId"
          onChange={e => handleInvitationChange(idx, e)}
          placeholder="___-___-___"
          value={selectedCompanyId || ""}
        >
        {
          (inputProps) => <TextField {...inputProps} disabled={knownUser || inactivated} />
        }
        </InputMask>
      </TableCell>
      <TableCell className={clsx(["company", companyNotSelected ? "notSelected" : ""])}>
        <CompanySelector
          disabled={knownUser || inactivated}
          highlightedCompanyIds={highlightedCompanyIds}
          name="companyName"
          onChange={company => handleSelectCompany(idx, company)}
          onNewCompanyClick={(e) => {
            e.stopPropagation();
            setNewCompanyModal(true);
          }}
          options={highlightedCompanyList}
          showHeader
          value={companyName}
        />
      </TableCell>
      <TableCell className="role">
        <CustomSelect
          name="siteRoleId"
          value={siteRoleId}
          options={siteRoleOptions}
          onChange={e => handleInvitationChange(idx, e)}
        />
      </TableCell>
      <TableCell className="payment" align="right">
        <Switch
          name="paymentLiable"
          color="secondary"
          checked={paymentLiable}
          disabled={!(companyName || haveValidCompanyId)}
          onChange={e => handleInvitationChange(idx, e)}
        />
      </TableCell>

      <TableCell className="action" align="right">
        <Tooltip
          title={<FormattedMessage id="btn.removeUser" />}
          placement="top"
        >
          <IconButton
            edge="end"
            aria-label="delete"
            className={classes.removeRow}
            onClick={() => removePreparedInvitationRow(idx)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

HelpUserTableRow.defaultProps = {
  companyList: [],
  invitation: null,
  preparedInvitations: [],
  siteRoleOptions: [],
};

HelpUserTableRow.propTypes = {
  companyList: PropTypes.arrayOf(PropTypes.shape({})),
  idx: PropTypes.number.isRequired,
  invitation: PropTypes.shape({
    companyName: PropTypes.string,
    email: PropTypes.string,
    highlightedCompanyIds: PropTypes.arrayOf(PropTypes.number),
    knownUser: PropTypes.bool,
    paymentLiable: PropTypes.bool,
    publicCompanyId: PropTypes.string, //number,
    selectedCompanyId: PropTypes.string,
    siteRoleId: PropTypes.number,
  }),
  preparedInvitations: PropTypes.arrayOf(PropTypes.shape({})),
  setNewCompanyModal: PropTypes.func.isRequired,
  setPreparedInvitations: PropTypes.func.isRequired,
  siteRoleOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HelpUserTableRow;

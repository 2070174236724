import React, { useState, useEffect, useCallback, memo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { saveAs } from "file-saver";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import CustomIconButton from "./CustomIconButton";
import QuantityCell from "./QuantityCell";
import PriceCell from "./PriceCell";
import {
  UPDATE_ITEM,
  INIT_ONE_STREAM_FILE_DOWNLOAD,
} from "../../../graphql/mutations";
import { ContainedSelect } from "../../common";
import { cartInitialize } from "../../../actions";
import NameCell from "./NameCell";
import { useDeleteItemInBasketGroup } from "../../../hooks";

const useStyles = makeStyles(theme => ({
  removeRow: {
    padding: 5,
    color: theme.palette.common.red,
  },
  td: {
    fontSize: 12,
  },
  tdAction: {},
  label: {
    fontSize: 10,
    marginRight: 5,
    marginLeft: 15,
  },
  headingForm: {
    display: "flex",
    alignItems: "center",
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.common.white,
      fontSize: 10,
      padding: "4px 12px",
    },
    "& .MuiFormControl-fullWidth": {
      width: 140,
    },
    "& .MuiSelect-iconOutlined": {
      right: 3,
      top: 3,
    },
    "& .MuiInputLabel-outlined": {
      top: -5,
      fontSize: 12,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      top: 0,
    },
    fontWeight: 500,
  },
  selectWrap: {
    display: "inline-block",
  },
  bookButton: {
    color: "#999",
    padding: "4px !important",
  },
  bookIcon: {
    fontSize: 14,
    width: "unset",
  },
}));

const OrderGroupTableCells = ({ item, oneStream, idx, folderIndex }) => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.api.cart.cartItems);
  const fileGroups = useSelector(state => state.api.cart.fileGroups);
  const oneStreamGroups = useSelector(state => state.api.cart.oneStreamGroups);

  const [updateItem] = useMutation(UPDATE_ITEM);

  const {
    name,
    quantity,
    selectableSuppliers,
    price,
    siteSupplierId,
    shoppingCartItemId,
    propertyProductData = [],
    productId,
  } = item;

  const [itemQuantity, setItemQuantity] = useState(quantity || 0);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [initDownloadFile] = useMutation(INIT_ONE_STREAM_FILE_DOWNLOAD);

  console.log("siteSupplierId >", siteSupplierId);

  const handleDownloadFile = async () => {
    try {
      const res = await initDownloadFile({
        variables: { id: productId },
      });

      if (res && res.data && res.data.initOneStreamFileDownload) {
        saveAs(res.data.initOneStreamFileDownload);
      } else {
        console.log("Error downloading");
      }
    } catch (err) {
      console.error("error  >", err);
    }
  };

  useEffect(() => {
    setItemQuantity(quantity);
  }, [quantity]);

  useEffect(() => {
    setSelectedSupplier(siteSupplierId);
  }, [siteSupplierId]);

  const errorRemoveBasketItem = () => {
    alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
  };

  const successRemoveBasketItem = () => {
    alert.success(<FormattedMessage id="product.successRemovedFromCart" />);
  };

  const { execute: executeDeleteItem, loading } = useDeleteItemInBasketGroup({
    errorRemoveBasketItem,
    successRemoveBasketItem,
  });

  const handleRemoveItem = () => {
    executeDeleteItem({ shoppingCartItemId });
  };

  const handleUpdateItem = async input => {
    const { quantity: q, siteSupplierId: id } = input;
    const tempGroups = oneStream ? [...oneStreamGroups] : [...fileGroups];
    const tempFolder = tempGroups[folderIndex];

    tempFolder.items[idx] = {
      ...tempFolder.items[idx],
      quantity: q,
      siteSupplierId: id,
    };
    tempGroups[folderIndex] = tempFolder;

    try {
      const res = await updateItem({ variables: { input } });
      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.updateItem
      ) {
        const { success } = res.data.shoppingCartContext.updateItem;

        if (success) {
          alert.success(
            <FormattedMessage id="product.successUpdatedItemCart" />
          );
          if (oneStream) {
            dispatch(
              cartInitialize({
                cartItems,
                fileGroups,
                oneStreamGroups: tempGroups,
              })
            );
          } else {
            dispatch(
              cartInitialize({
                cartItems,
                fileGroups: tempGroups,
                oneStreamGroups,
              })
            );
          }
        } else {
          alert.error(<FormattedMessage id="product.failUpdatedItemCart" />);
        }
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const debounceHandleUpdate = useCallback(
    debounce(value => {
      handleUpdateItem(value);
    }, 1000),
    []
  );

  useEffect(() => {
    if (Array.isArray(selectableSuppliers) && selectableSuppliers.length > 0) {
      const options = [];
      selectableSuppliers.forEach(x => {
        options.push({
          label: x.displayName,
          value: x.siteSupplierId,
        });
      });

      setSupplierOptions(options);
    }
  }, [selectableSuppliers]);

  const handleQuantity = value => {
    const intValue = parseInt(value, 10);
    if (intValue > 0) {
      const input = {
        quantity: intValue,
        shoppingCartItemId,
        siteSupplierId: selectedSupplier || null,
      };
      setItemQuantity(intValue);
      debounceHandleUpdate(input);
    }
  };

  const handleSuppliers = value => {
    const input = {
      quantity: itemQuantity,
      shoppingCartItemId,
      siteSupplierId: value,
    };
    setSelectedSupplier(value);
    handleUpdateItem(input);
  };

  const handleIncreaseQuantity = () => {
    const intValue = parseInt(itemQuantity, 10);
    const tempValue = intValue + 1;
    const input = {
      quantity: tempValue,
      shoppingCartItemId,
      siteSupplierId: selectedSupplier || null,
    };
    setItemQuantity(tempValue);
    debounceHandleUpdate(input);
  };

  const handleDecreaseQuantity = () => {
    const intValue = parseInt(itemQuantity, 10);
    if (intValue > 1) {
      const tempValue = intValue - 1;

      const input = {
        quantity: tempValue,
        shoppingCartItemId,
        siteSupplierId: selectedSupplier || null,
      };
      setItemQuantity(tempValue);
      debounceHandleUpdate(input);
    }
  };

  return (
    <>
      <TableCell className={classes.td} />
      <NameCell propertyProductData={propertyProductData} name={name} />
      <TableCell className={classes.td}>
        <Box className={classes.selectWrap}>
          <Box className={classes.headingForm}>
            <ContainedSelect
              label="Leverantör:"
              onChange={e => handleSuppliers(e.target.value)}
              options={supplierOptions}
              value={selectedSupplier}
              small
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell className={classes.td} />
      <QuantityCell
        handleDecreaseQuantity={handleDecreaseQuantity}
        handleIncreaseQuantity={handleIncreaseQuantity}
        handleQuantity={handleQuantity}
        itemQuantity={itemQuantity}
      />
      <PriceCell price={price} />
      <PriceCell price={price} />

      <TableCell align="right" className={classes.tdAction}>
        <Box display="flex" justifyContent="flex-end">
          {loading ? (
            <IconButton className={classes.removeRow}>
              <CircularProgress size={18} />
            </IconButton>
          ) : (
            <>
              {/* <CustomIconButton faName="fas fa-pencil-alt" /> */}
              {oneStream && (
                <IconButton
                  className={classes.bookButton}
                  onClick={() => handleDownloadFile()}
                >
                  <Icon className={clsx(["fa fa-cloud", classes.bookIcon])} />
                </IconButton>
              )}

              <CustomIconButton
                faName="fa fa-trash"
                color={theme.palette.error.dark}
                onClick={handleRemoveItem}
              />
            </>
          )}
        </Box>
      </TableCell>
    </>
  );
};

OrderGroupTableCells.defaultProps = {
  item: {},
};

OrderGroupTableCells.propTypes = {
  item: PropTypes.shape({
    productId: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    selectableSuppliers: PropTypes.arrayOf(PropTypes.shape({})),
    price: PropTypes.number,
    siteSupplierId: PropTypes.number,
    shoppingCartItemId: PropTypes.string,
    propertyProductData: PropTypes.arrayOf(
      PropTypes.shape({
        propertyName: PropTypes.string,
        propertyValue: PropTypes.string,
        propertyValueId: PropTypes.number,
      })
    ),
  }),
};

export default memo(OrderGroupTableCells);

import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useAlert } from "react-alert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useMutation } from "@apollo/client";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Icon } from "@mui/material";
import EmptyCart from "../../assets/img/cart-empty.svg";
import FormattedCurrency from "../common/FormattedCurrency";
import { DELETE_ITEM } from "../../graphql/mutations";
import CartItems from "./CartItems";
import GroupCartItem from "./GroupCartItem";
import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";

const isCartEmpty = ({ cartItems, fileGroups, oneStreamGroups }) => {
  if (cartItems && Array.isArray(cartItems) && cartItems.length > 0) {
    return false;
  }

  if (fileGroups && Array.isArray(fileGroups) && fileGroups.length > 0) {
    return false;
  }

  if (
    oneStreamGroups &&
    Array.isArray(oneStreamGroups) &&
    oneStreamGroups.length > 0
  ) {
    return false;
  }

  return true;
};

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  paper: {
    width: 400,
    position: "absolute",
    top: 12,
    right: -20,
    zIndex: 10,
    borderRadius: 0,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    padding: 0,
  },
  listItem: {
    borderBottom: "1px solid #dee2e6",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  listItemEmpty: {
    padding: 0,
  },
  cartEmptyImg: {
    width: 40,
  },
  image: {
    maxWidth: 56,
    marginRight: theme.spacing(1),
  },
  total: {
    color: theme.palette.secondary.dark,
    textAlign: "right",
    fontSize: 14,
    "& span": {
      fontWeight: 600,
      fontSize: 18,
    },
  },
  totalLabel: {
    fontSize: 14,
    "& span": {
      fontWeight: 600,
    },
  },
  button: {
    fontWeight: 600,
    boxShadow: "none",
    marginBottom: 15,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  noSiteText: {
    fontSize: 12,
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    textAlign: "center",
    backgroundColor: theme.palette.background.contrastGray,
  },
  noSiteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
    fontSize: 14,
    marginRight: 10,
    color: theme.palette.text.lightGray,
  },
  td: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  tdInfo: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 245,
    paddingTop: 5,
    paddingBottom: 5,
  },
  tdImg: {
    width: "20%",
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    "& img": {
      maxHeight: 80,
      width: "100%",
      border: "1px solid #ececec",
      objectFit: "cover",
    },
    paddingTop: 10,
    paddingBottom: 5,
  },
  tdAction: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 30,
    paddingTop: 5,
    paddingBottom: 5,
  },
  removeRow: {
    padding: 5,
    color: theme.palette.secondary.dark,
    marginRight: 0,
  },
  name: {
    wordBreak: "break-all",
  },
  subText: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
  },
  subTextRight: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
    display: "inline-block",
    float: "right",
  },
  subTextLeft: {
    fontSize: 12,
    color: theme.palette.text.lightGray,
    display: "inline-block",
    float: "left",
  },
  cartItemsWrap: {
    maxHeight: 300,
    overflow: "auto",
    padding: 10,
  },
  summaryWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: theme.palette.background.lightGray,
  },
  table: {
    "&:last-of-type": {
      "& td": {
        borderBottom: "none",
      },
    },
  },
  listItemSummary: {
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const Cart = ({
  miniBasketIsOpen,
  toggleCart,
  cartItems,
  handleDeleteItem,
  selectedWorkspace,
  history,
  fileGroups,
  oneStreamGroups,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));
  const [deleteItem] = useMutation(DELETE_ITEM);
  let subtotal = 0;

  const company = useSelector(
    state => state.api.currentViewer.viewer.defaultTheme
  );

  if (cartItems && cartItems.length > 0) {
    subtotal = cartItems.reduce((prev, cur) => {
      if (cur && cur.price) {
        return prev + cur.price;
      }
      return prev;
    }, 0);
  }

  const handleClickAway = () => {
    toggleCart({ name: "Cart" });
  };

  const handleCashDesk = () => {
    toggleCart({ name: "Cart" });
    history.push("/checkout");
  };

  const handleRemoveFromCart = index => {
    handleDeleteItem({ index });
  };

  const handleRemoveItem = async (id, index) => {
    try {
      const res = await deleteItem({
        variables: {
          shoppingCartItemId: id,
        },
      });

      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.deleteItem
      ) {
        const { success } = res.data.shoppingCartContext.deleteItem;
        if (success) {
          alert.success(
            <FormattedMessage id="product.successRemovedFromCart" />
          );
          handleDeleteItem({ index });
        } else {
          alert.error(<FormattedMessage id="product.failRemovedFromCart" />);
        }
      }
    } catch (err) {
      console.error("error ", err);
    }
  };

  const renderCartItems = () =>
    cartItems.map((item, idx) => {
      return (
        <CartItems
          key={item.productId}
          handleRemoveFromCart={handleRemoveItem}
          index={idx}
          item={item}
        />
      );
    });

  const renderOneStreamItems = () =>
    oneStreamGroups.map((item, idx) => {
      return <GroupCartItem key={item.packageId} item={item} />;
    });

  const renderFileGroupItems = () =>
    fileGroups.map((item, idx) => {
      return <GroupCartItem key={item.packageId} item={item} />;
    });

  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          {miniBasketIsOpen ? (
            <Grow in>
              <Paper className={classes.paper}>
                <Box className={classes.cartItemsWrap}>
                  {!isCartEmpty({ cartItems, fileGroups, oneStreamGroups }) ? (
                    <>
                      {renderCartItems()}
                      {renderFileGroupItems()}
                      {renderOneStreamItems()}
                    </>
                  ) : (
                    <ListItem className={classes.listItemEmpty}>
                      <ListItemAvatar>
                        <img
                          src={EmptyCart}
                          alt="Empty Cart"
                          className={classes.cartEmptyImg}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<FormattedMessage id="cart.emptyCart" />}
                      />
                    </ListItem>
                  )}
                </Box>
                <Box className={classes.summaryWrap}>
                  {!isCartEmpty({ cartItems, fileGroups, oneStreamGroups }) ? (
                    <div>
                      <List>
                        <ListItem className={classes.listItemSummary}>
                          <ListItemText
                            className={classes.totalLabel}
                            primary={<FormattedMessage id="cart.subTotal" />}
                          />
                          <ListItemText
                            className={classes.total}
                            primary={<FormattedCurrency value={subtotal} />}
                            edge="end"
                          />
                        </ListItem>
                      </List>
                      {!selectedWorkspace.id && (
                        <Typography className={classes.noSiteText}>
                          <Icon
                            className={clsx([
                              "fa fa-exclamation-circle",
                              classes.noSiteIcon,
                            ])}
                          />
                          <FormattedMessage id="cart.needSite" />
                        </Typography>
                      )}
                      <Button
                        className={classes.button}
                        variant="contained"
                        size="medium"
                        disabled={!selectedWorkspace.id}
                        onClick={() => handleCashDesk()}
                        fullWidth
                      >
                        <FormattedMessage id="cart.cashDesk" />
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </Paper>
            </Grow>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
};

Cart.defaultProps = {
  selectedWorkspace: {
    id: null,
    name: null,
  },
  item: {},
};

Cart.propTypes = {
  miniBasketIsOpen: PropTypes.bool.isRequired,
  toggleCart: PropTypes.func.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    })
  ).isRequired,
  handleDeleteItem: PropTypes.func.isRequired,
  selectedWorkspace: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    productId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    name: PropTypes.string,
    product: PropTypes.shape({
      thumbnailImageUri: PropTypes.string,
      articleNo: PropTypes.string,
    }),
    shoppingCartItemId: PropTypes.string,
  }),
};

export default Cart;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import { validateEmail } from "../../helpers/validators";
import FormInput from "./FormInput";
import OneStreamUpload from "./OneStreamUpload";
import OneStreamSubmit from "./OneStreamSubmit";
import OneStreamManualType from "./OneStreamManualType";
import { ManualTypeOptions } from "./helpers";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "95%",
    },
  },
}));

const NewUploadModal = ({
  open,
  closeModal,
  handleOnSelectUploadFile,
  uploadedFile,
  setUploadedFile,
  setRefreshDropzone,
  handleUploadFiles,
  progress,
  formValues,
  setFormValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {

    if (formValues) {
      setIsValidEmail(formValues["email"]);
      setIsValidName(formValues["name"] !== "");
    }
    
    if (uploadedFile.length > 0) {
      setIsValidFile(true);
    }

  }, [formValues, uploadedFile]);

  const [manualType, setManualType] = useState(0);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidFile, setIsValidFile] = useState(false);

  const handleRemoveUploadedFile = file => {
    const tempUploadedFiles = uploadedFile.filter(
      x => x.fileToken !== file.fileToken
    );
    setUploadedFile(tempUploadedFiles);
  };

  const handleClose = () => {
    setRefreshDropzone(true);
    closeModal();
  };

  const setPresetValue = e => {
    setManualType(e.target.value);
    handleCheckField(e);
  };

  const handleCheckField = e => {
    
    const { value } = e.target;
    const key = e.target.name;
    setFormValues({ ...formValues, [key]: "" + value });



  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="onestream.newUpload" />}
      />
      <DraggableModalBody>

        <FormInput
          name={"name"}
          label={intl.formatMessage({id: "onestream.project"})}
          errorMessage={intl.formatMessage({id: "common.fieldIsRequired"})}
          placeholder={intl.formatMessage({id: "product.name"})}
          onChange={e => handleCheckField(e)}
          formValues={formValues}
          isValid={isValidName}
        />

        <FormInput
          name={"email"}
          label={intl.formatMessage({id: "support.email" })}
          errorMessage={intl.formatMessage({id: "common.invalidEmail"})}
          placeholder={intl.formatMessage({id: "orderHistory.email"})}
          onChange={e => handleCheckField(e)}
          formValues={formValues}
          isValid={isValidEmail}
        />
        <OneStreamManualType
          options={ManualTypeOptions}
          value={manualType}
          name="preset"
          onChange={e => setPresetValue(e)}
        />
        <OneStreamUpload
          uploadedFile={uploadedFile}
          handleOnSelectUploadFile={handleOnSelectUploadFile}
          handleRemoveUploadedFile={handleRemoveUploadedFile}
        />
        <OneStreamSubmit
          progress={progress}
          handleUploadFiles={handleUploadFiles}
          isValid={isValidEmail && isValidName && isValidFile}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewUploadModal.defaultProps = {
  uploadedFile: [],
  formValues: {},
};

NewUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  setFormValues: PropTypes.func.isRequired,
  handleOnSelectUploadFile: PropTypes.func.isRequired,
  uploadedFile: PropTypes.arrayOf(PropTypes.shape({})),
  setUploadedFile: PropTypes.func.isRequired,
  setRefreshDropzone: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
};

export default NewUploadModal;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_ONE_STREAM_JOB } from "../graphql/queries";
import {
  setOneStreamJobs,
  setOneStreamJobsLoading,
  setOneStreamJobsError,
} from "../actions";

// eslint-disable-next-line import/prefer-default-export
export const useGetOneStreamJobs = ({
  siteId,
  pageSize,
  sortField,
  ascending,
  offset,
}) => {
  const [getOneStreamJob, { data, loading, error }] = useLazyQuery(
    GET_ONE_STREAM_JOB,
    {
      variables: {
        siteId,
        pageSize,
        sortField,
        ascending,
        offset,
      },
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.oneStream && data.oneStream.oneStreamJobs) {
      dispatch(
        setOneStreamJobs({
          items: data.oneStream.oneStreamJobs,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setOneStreamJobsLoading({
        loading,
      })
    );
  }, [loading]);

  useEffect(() => {
    if (error) {
      dispatch(
        setOneStreamJobsError({
          error,
        })
      );
    }
  }, [error]);

  return { execute: getOneStreamJob, loading, error };
};

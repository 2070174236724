import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Icon } from "@mui/material";
import clsx from "clsx";
import Sample from "../../../assets/img/uniqueue-logo.png";

const useStyles = makeStyles(() => ({
  logoWrap: {
    padding: 10,
    backgroundColor: "#F7FCF6",
    borderRadius: 15,
    textAlign: "center",
    height: 160,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  logoActions: {
    position: "absolute",
    padding: 10,
    bottom: 0,
    width: "90%",
    textAlign: "right",
  },
  input: {
    display: "none",
  },
  iconButton: {
    padding: 4,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  iconRemove: {
    fontSize: 15,
  },
}));

const LogoEdit = () => {
  const classes = useStyles();

  return (
    <Box className={classes.logoWrap}>
      <img src={Sample} alt="Company logo" />
      <Box className={classes.logoActions}>
        <input
          accept="image/*"
          className={classes.input}
          id="icon-button-file"
          type="file"
        />
        <label htmlFor="icon-button-file">
          <IconButton
            aria-label="upload picture"
            component="span"
            className={classes.iconButton}
          >
            <Icon className={clsx(["fa fa-pencil-alt", classes.icon])} />
          </IconButton>
        </label>
        <IconButton className={classes.iconButton}>
          <Icon
            className={clsx(["fa fa-times", classes.icon, classes.iconRemove])}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default LogoEdit;

import React from "react";
import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import OrderTableItem from "../OrderTableItem";

const useStyles = makeStyles(theme => ({
  tdSkeleton: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
}));

const TableBodyComponent = ({
  loading,
  orderGroupId,
  orderHistoryDetailSettings,
  orders,
  addSelectedOrder,
  removeSelectedOrder,
  selectedOrders,
}) => {
  const classes = useStyles();
  return (
    <TableBody>
      {loading ? (
        <TableRow>
          <TableCell className={classes.tdSkeleton} colSpan={11}>
            <TableSkeleton />
          </TableCell>
        </TableRow>
      ) : (
        <>
          {orders.map(x => (
            <OrderTableItem
              key={x.orderId}
              order={x}
              orderGroupId={orderGroupId}
              detailSettings={orderHistoryDetailSettings}
              selectedOrders={selectedOrders}
              addSelectedOrder={addSelectedOrder}
              removeSelectedOrder={removeSelectedOrder}
            />
          ))}
        </>
      )}
    </TableBody>
  );
};

TableBodyComponent.defaultProps = {
  loading: false,
  orderGroupId: null,
  orderHistoryDetailSettings: null,
};

TableBodyComponent.propTypes = {
  orderGroupId: PropTypes.number,
  loading: PropTypes.bool,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.number.isRequired,
      orderDate: PropTypes.string,
      username: PropTypes.string,
      deliveryAddress1: PropTypes.string,
      deliveryAddress2: PropTypes.string,
      deliveryReference: PropTypes.string,
      deliveryCity: PropTypes.string,
      sumInclVat: PropTypes.number,
      deliveryDateWish: PropTypes.string,
      costPlaceOrder: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  orderHistoryDetailSettings: PropTypes.shape({
    showComment: PropTypes.bool,
    showCostCenter: PropTypes.bool,
    showDeliveryAddress: PropTypes.bool,
    showDeliveryDate: PropTypes.bool,
    showEmail: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrderStatus: PropTypes.bool,
    showPackageColumn: PropTypes.bool,
    showPostalAddress: PropTypes.bool,
    showPriceColumn: PropTypes.bool,
    showProductColumn: PropTypes.bool,
    showQuantityColumn: PropTypes.bool,
    showSummation: PropTypes.bool,
    showWeightColumn: PropTypes.bool,
  }),
  selectedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
};

export default TableBodyComponent;

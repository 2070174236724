import { gql } from '@apollo/client';

const getRFPResources = gql`
  query GetRFPResources(
    $siteId: Int!
    $searchText: String
    $sortfield: String
    $ascending: Boolean
    $offset: Int!
    $pageSize: Int!
  ) {
    requestForProposal {
      fileSearch(
        siteId: $siteId
        searchText: $searchText
        sortfield: $sortfield
        ascending: $ascending
        offset: $offset
        pageSize: $pageSize
      ) {
        items {
          changedBy
          changedDate
          description
          downloadedBy
          downloadedDate
          fileExtension
          id
          locked
          name
          size
          type
          uploadedBy
          uploadedDate
          versionNo
        }
        totalCount
      }
    }
  }
`;

export default getRFPResources;

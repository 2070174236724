import { gql } from '@apollo/client';

const DYNAMIC_TEMPLATE_EDIT_PREVIEW = gql`
  query DynamicTemplateEditPreview(
    $productId: ID!
    $data: [DynamicTemplateFieldValue]
    $usePreviewTemplate: Boolean!
  ) {
    dynamicTemplateEditContext {
      dynamicTemplateEditPreview(
        productId: $productId
        data: $data
        usePreviewTemplate: $usePreviewTemplate
      )
    }
  }
`;

export default DYNAMIC_TEMPLATE_EDIT_PREVIEW;

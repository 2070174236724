import React, { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { MENU_TYPES as MenuTypes } from "../../../../constant/types";
import PagesMenuItem from "../PagesMenuItem";

const { MENU_GROUP, MENU_ITEM } = MenuTypes;

const useStyles = makeStyles(theme => ({
  body: ({ isDragging }) => ({
    background: "#fff",
    cursor: "move",
    border: isDragging
      ? `1px dashed ${theme.palette.secondary.dark}`
      : `1px solid transparent`,
    boxShadow: isDragging ? `0px 0px 8px #666` : `none`,
  }),
}));

const MenuItem = props => {
  const { index, itemData, onDragAbort, onDragStart, onHover, onRemove, onRename } = props;
  const { depth } = itemData;

  const MENU_NODE_TYPE = "menu-node-type";
  const INDENTATION_WIDTH = 20;

  const [collected, drag] = useDrag(
    () => ({
      type: MENU_NODE_TYPE,

      collect: (monitor, props) => ({
        isDragging: monitor.isDragging(),
      }),

      item: () => {
        onDragStart();
        return { index, itemData };
      },

      end: (draggedItem, monitor) => {
        if (!monitor.didDrop()) {
          onDragAbort();
        }
      },
    }), [onDragAbort, onDragStart]
  )

  const [, drop] = useDrop(
    () => ({
      accept: MENU_NODE_TYPE,

      canDrop: (draggedItem, monitor) => {
        const { depth: targetDepth, menuType: targetMenuType } = itemData;
        const { menuType: draggedMenuType } = draggedItem.itemData;

        if (draggedMenuType === MENU_ITEM) {
          return true;
        }

        if (draggedMenuType === MENU_GROUP) {
          return (targetMenuType === MENU_GROUP) || (targetMenuType === MENU_ITEM && targetDepth === 0);
        }

        return false;
      },

      hover: (draggedItem, monitor) => {
        if (monitor.canDrop()) {
          const diff = monitor.getDifferenceFromInitialOffset();
          const depthChange = Math.round(diff.x / INDENTATION_WIDTH);          
          const targetDepth = draggedItem.itemData.depth + depthChange;
          const targetIndex = index;
          
          onHover({ draggedItem, targetIndex, targetDepth });
        }
      }
    }), [onHover]
  )

  const classes = useStyles({ isDragging: collected.isDragging });

  const opacity = collected.isDragging ? 0.5 : 1

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ marginBottom: 5, marginLeft: depth * INDENTATION_WIDTH, opacity, width: 332 }}
      className={classes.body}
    >
      <PagesMenuItem
        node={itemData}
        handleOnRemoveSortlyMenu={onRemove}
        handleRenameLabel={onRename}
      />
    </div>
  );
};

MenuItem.propTypes = {
  index: PropTypes.number.isRequired,
  itemData: PropTypes.shape({}).isRequired,
  onDragAbort: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
};

export default memo(MenuItem);

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const REPLACE_METADATA = gql`
  mutation ReplaceMetadata($metadataInputs: [MetadataInputType]) {
    replaceMetadata(metadataInputs: $metadataInputs) {
      errorResult {
        ...ErrorResultFields
      }
      message
      success
      errorResult {
        ...ErrorResultFields
      }
    }
  }
  ${ErrorResultFragment}
`;

export default REPLACE_METADATA;

import React from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeView from "@mui/lab/TreeView";
import StructureNode from "./StructureNode";

const WorkspaceListTree = ({ structureTreeRoots, onClick }) => {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
    >
      {structureTreeRoots.map(x => {
        return (
          <StructureNode
            key={x.id}
            node={x}
            onClick={onClick}
          />
        );
      })}
    </TreeView>
  );
};

export default WorkspaceListTree;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  CircularProgress as Circular,
} from "@mui/material";
import DraggableModal from "../DraggableModal";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
  },
  body: {
    padding: 25,
  },
  circular: {
    color: theme.palette.primary.main,
  },
}));

function CircularProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <Circular className={classes.circular} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const CircularProgress = ({ open, handleClose, progress, message }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalBody customClass={classes.body}>
        <Grid container justifyContent="center">
          <CircularProgressWithLabel value={progress} />
        </Grid>

        <Grid container justifyContent="center">
          {message && <>{message}</>}
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

CircularProgress.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
};

export default CircularProgress;

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Icon } from "@mui/material";
import { getFaClassType } from "../../helpers/get";

const WidgetItemImage = ({ classes, item, onClick }) => {
  const [showIcon, setShowIcon] = useState(!item.thumbnailImageUri);

  if (showIcon) {
    const typeClass = getFaClassType(item.fileExtension);

    return (
      <Icon className={clsx([typeClass, classes.iconType])} onClick={onClick} />
    );
  }

  return (
    <img
      alt={item.name}
      className={classes.productImg}
      onClick={onClick}
      onError={() => setShowIcon(true)}
      src={item.thumbnailImageUri}
    />
  );
};

export default WidgetItemImage;

WidgetItemImage.propTypes = {
  item: PropTypes.shape({
    fileExtension: PropTypes.string,
    name: PropTypes.string.isRequired,
    thumbnailImageUri: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
};

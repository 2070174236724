import { gql } from '@apollo/client';
import ProjectUserLookup from "../../fragments/Projects/ProjectUserLookup";

const LIST_PROJECT_USER_CANDIDATES = gql`
  query listProjectUserCandidates($siteId: Int!) {
    projects {
      listProjectUserCandidates(siteId: $siteId) {
        ...ProjectUserLookup
      }
    }
  }
  ${ProjectUserLookup}
`;

export default LIST_PROJECT_USER_CANDIDATES;

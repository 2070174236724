import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UNLOCK_RESOURCE = gql`
  mutation UnlockResource($resourceId: ID!) {
    unlockResource(resourceId: $resourceId) {
      errorResult {
        ...ErrorResultFields
      }
      status {
        isLocked
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default UNLOCK_RESOURCE;

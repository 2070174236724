import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_FOLDER_DATA } from "../../../graphql/queries";
import ParentFolderItem from "./ParentFolderItem";
import WidgetLabel from "../WidgetLabel";

const SiteFolderWidget2 = ({
  label,
  siteId,
  rootFolderId,
  setOpenedNodes,
  setFolderId,
}) => {
  const [folders, setFolders] = useState([]);

  const { data } = useQuery(GET_FOLDER_DATA, {
    variables: {
      siteId,
      folderId: rootFolderId,
      folderLevel: 2,
    },
    skip: !siteId || !rootFolderId,
  });

  useEffect(() => {
    if (data && data.widgets && data.widgets.getFolderData) {
      setFolders(data.widgets.getFolderData);
    } else {
      setFolders([]);
    }
  }, [data]);

  return (
    <Box>
      <WidgetLabel title={label} />
      <Box>
        <Grid container>
          {folders.map(folder => (
            <ParentFolderItem
              parentFolderId={folder.folderId}
              rootFolderId={rootFolderId}
              key={folder.folderId}
              siteId={siteId}
              setFolderId={setFolderId}
              setOpenedNodes={setOpenedNodes}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

SiteFolderWidget2.defaultProps = {};

SiteFolderWidget2.propTypes = {
  label: PropTypes.string.isRequired,
  rootFolderId: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
  setOpenedNodes: PropTypes.func.isRequired,
  setFolderId: PropTypes.func.isRequired,
};

export default SiteFolderWidget2;

import { gql } from '@apollo/client';

const findTagsBySiteId = gql`
  query FindTagsBySiteId($siteId: Int!, $q: String) {
    tags {
      findTagsBySiteId(siteId: $siteId, q: $q) {
        tagId
        tagName
      }
    }
  }
`;

export default findTagsBySiteId;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import Tour from "reactour";
import { FormattedMessage, useIntl } from "react-intl";
import Body from "./Body";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: 1200,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: 0,
  },
  tourIconWrapper: {
    display: "flex",
    flex: 1,
    marginRight: 8,
  },
  tourIconButton: {
    marginLeft: "auto",
    cursor: "pointer",
    padding: 4,
    color: theme.palette.common.white,
    "& span": {
      fontSize: 16,
    },
  },
}));

const Modal = ({
  open,
  handleClose,
  siteMenuPages,
  handlePageCheckboxChange,
  handleSaveMenu,
  handlePageAddToMenu,
  defaultStartPage,
  handleAddToMenu,
  sortlyMenu,
  setSortlyMenu,
  handleOnRemoveSortlyMenu,
  handleRenameLabel,
  siteMenus,
  handleSelectDefaultStartPage,
  roleName,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [isTourOpen, setIsTourOpen] = useState(false);

  const openTour = () => {
    setIsTourOpen(true);
  };

  const steps = [
    {
      selector: ".openPageMenu",
      content: intl.formatMessage({ id: "siteUsers.tourStep1" }),
    },
    {
      selector: ".pagesMenu",
      content: intl.formatMessage({ id: "siteUsers.tourStep2" }),
    },
    {
      selector: ".categoryMenu ",
      content: intl.formatMessage({ id: "siteUsers.tourStep3" }),
    },
    {
      selector: ".saveButtonCategory ",
      content: intl.formatMessage({ id: "siteUsers.tourStep4" }),
    },
    {
      selector: ".addedMenu ",
      content: intl.formatMessage({ id: "siteUsers.tourStep5" }),
    },
    {
      selector: ".sortMenu ",
      content: intl.formatMessage({ id: "siteUsers.tourStep6" }),
    },
    {
      selector: ".saveAddedMenu ",
      content: intl.formatMessage({ id: "siteUsers.tourStep6" }),
    },
    // ...
  ];

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={`${intl.formatMessage({ id: "role.editMenus" })} - ${roleName}`}
      >
        <Box className={classes.tourIconWrapper}>
          <IconButton className={classes.tourIconButton} onClick={openTour}>
            <i className="fas fa-map-marked-alt" />
          </IconButton>
        </Box>
      </DraggableModalHead>
      <DraggableModalBody customClass={classes.modalBody}>
        <Body
          handleAddToMenu={handleAddToMenu}
          handleOnRemoveSortlyMenu={handleOnRemoveSortlyMenu}
          handlePageAddToMenu={handlePageAddToMenu}
          handlePageCheckboxChange={handlePageCheckboxChange}
          handleSaveMenu={handleSaveMenu}
          defaultStartPage={defaultStartPage}
          handleSelectDefaultStartPage={handleSelectDefaultStartPage}
          setSortlyMenu={setSortlyMenu}
          siteMenuPages={siteMenuPages}
          sortlyMenu={sortlyMenu}
          handleRenameLabel={handleRenameLabel}
        />
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
        />
      </DraggableModalBody>
    </DraggableModal>
  );
};

Modal.defaultProps = {};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSaveMenu: PropTypes.func.isRequired,
  siteMenuPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handlePageCheckboxChange: PropTypes.func.isRequired,
  siteMenuObject: PropTypes.shape({}).isRequired,
  handlePageAddToMenu: PropTypes.func.isRequired,
  sortlyMenu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSortlyMenu: PropTypes.func.isRequired,
  handleOnRemoveSortlyMenu: PropTypes.func.isRequired,
  handleAddToMenu: PropTypes.func.isRequired,
  handleRenameLabel: PropTypes.func.isRequired,
};

export default Modal;

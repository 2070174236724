import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Autocomplete, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PrimaryButton, TextField as CustomTextfield } from "../../common";
import CompanyListBox from "./CompanyListBox";
import NoOption from "./NoOption";

const useStyles = makeStyles(theme => ({
  listboxContainer: {
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    margin: "4px 0",
    overflow: "hidden",
    width: "100%",
    "& li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
      cursor: "pointer",
    },
  },
}));

const CompanySelector = ({
  disabled,
  handleAddNewCompany,
  name,
  onChange,
  options,
  setValue,
  size,
  value,
  onClick
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      blurOnSelect
      classes={{ paper: classes.listboxContainer }}
      disabled={disabled}
      onClick={onClick}
      getOptionLabel={option => option?.companyName || ""}
      isOptionEqualToValue={(option, newValue) => option?.publicCompanyId === newValue}
      ListboxProps={{ style: { maxHeight: 300 } }}
      ListboxComponent={(props, ref) => (
        <CompanyListBox
          {...props}
          handleAddNewCompany={handleAddNewCompany}
        />
      )}
      name={name}
      noOptionsText={<NoOption handleAddNewCompany={handleAddNewCompany} />}
      onChange={e => setValue(e)}
      options={options}
      renderInput={params => (
        <CustomTextfield
          {...params}
          onChange={onChange}          
          label={<FormattedMessage id="siteUsers.selectCompany" />}
        />
      )}
      renderOption={(props, option, state) => ({ option, props })}
      size={size}
      value={value}
    />
  );
};

CompanySelector.defaultProps = {
  disabled: false,
  name: null,
  options: [],
  size: "small",
  value: null,
};

CompanySelector.propTypes = {
  disabled: PropTypes.bool,
  handleAddNewCompany: PropTypes.func.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string.isRequired,
      isHighlighted: PropTypes.bool.isRequired,
      publicCompanyId: PropTypes.string.isRequired
    })
  ),
  setValue: PropTypes.func.isRequired,
  size: PropTypes.string,
  value: PropTypes.string,
};

export default CompanySelector;

import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Details from "./Details";
import Summary from "./Summary";
import SectionDetailsLink from "../common/SectionDetailsLink";

function createSummaryRow(module, quantity) {
  return { module, quantity };
}

const summaryRows = [
  createSummaryRow("Prepressmodul", 3),
  createSummaryRow("Prepressmodul Avancerad", 1),
];

function createDetailsRow(module, username) {
  return { module, username };
}

const detailsRows = [
  createDetailsRow("Prepressmodul", "siemens.interntryckeri@siemens.com"),
  createDetailsRow(
    "Prepressmodul Avancerad",
    "siemens.interntryckeri@siemens.com"
  ),
];

const ModulesSection = ({ classes }) => {
  const [openDetails, setOpenDetails] = useState(false);

  const toggleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  return (
    <>
      <Typography className={classes.sectionTitle}>
        <FormattedMessage id="admin.subscriptions.modules.title" />
      </Typography>
      <Grid container item xs={12} md={4}>
        <Summary className={classes.sectionSummary} rows={summaryRows} />
      </Grid>
      <SectionDetailsLink
        className={classes.sectionDetailsLink}
        onClick={toggleOpenDetails}
      />
      <Grid container item xs={12}>
        <Details
          className={classes.sectionDetails}
          open={openDetails}
          rows={detailsRows}
        />
      </Grid>
    </>
  );
};

ModulesSection.defaultProps = {
  classes: {},
};

ModulesSection.propTypes = {
  classes: PropTypes.object,
};

export default ModulesSection;

import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles(theme => ({
  gridLayout: {
    flexGrow: 1,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#f0f2f5",
  },
  root: {
    maxWidth: 345,
    borderColor: "#00000020",
    boxShadow:
      "rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem",
  },
  media: {
    height: 194.0625,
    /* paddingTop: '56.25%', // 16:9*/
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  groupHeader: {
    paddingTop: 20,
  },
}));

export default function Help() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.gridLayout}>
      <Container
        maxWidth="false"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: "#f8f9fa",
          height: "10vh",
          paddingTop: 0,
        }}
      >
        <Typography
          component="div"
          style={{ paddingTop: 0, paddingLeft: 16, paddingRight: 16 }}
        >
          <Typography
            variant="h4"
            color="#344767"
            className={classes.groupHeader}
          >
            Hej, hur kan vi hjälpa dig?
          </Typography>
        </Typography>
      </Container>

      <Divider />

      <Typography
        variant="h6"
        component="h2"
        color="textPrimary"
        className={classes.groupHeader}
      >
        Användarhantering
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              {/*<Typography gutterBottom variant="h6" component="h3" color="textPrimary">*/}
              {/*    Bjuda in användare*/}
              {/*</Typography>*/}
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>
                  1. Klicka på menyn "Användare". 2. Klicka på... Såhär bjuds en
                  användare in till en arbetsyta. Användaren erhåller en
                  inbjudingslänk och registrerar sig genom att följa
                  inbjudningsprocessen.
                </Typography>
                <Typography paragraph>
                  Det går även att manuellt skapa ett företag, lägga till en
                  användare, lägga till användaren till arbetsytan men det är en
                  långsammare process.
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Arbetsyteroller
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Hantera roller för en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Företagsroller
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Hantera roller för ett företag.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Menyer
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Menyer är knutna till en arbetsyteroll.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        component="h2"
        color="textPrimary"
        className={classes.groupHeader}
      >
        Arbetsytor
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        component="h2"
        color="textPrimary"
        className={classes.groupHeader}
      >
        Användarhantering
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <CardMedia
              component="iframe"
              className={classes.media}
              image="https://www.youtube.com/embed/NiUOZYHw6RQ"
              title="Bjuda in användare"
              frameBorder="0"
              allowFullScreen
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="h3"
                color="textPrimary"
              >
                Bjuda in användare
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"                
              >
                Bjuda in användare till en arbetsyta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

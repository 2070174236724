import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ExpansionDefault from "../../../../common/ExpansionDefault";
import ProductInfoTags from "../ProductInfo/ProductInfoTags";

const useStyles = makeStyles(theme => ({
  tagLabel: {
    marginTop: 0,
    marginBottom: 8,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },

  tagLabelInfo: {
    marginLeft: 10,
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

const Tags = ({ tags, setTags }) => {
  const classes = useStyles();

  return (
    <ExpansionDefault
      label={<FormattedMessage id="product.tagging" />}
      panelContent="tagsContent"
      panelHeader="tagsHeader"
      defaultExpanded
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <p className={classes.tagLabel}>
            <span className={classes.tagLabelInfo}>
              (<FormattedMessage id="product.taggingInfo" />)
            </span>
          </p>
          <ProductInfoTags tags={tags} setTags={setTags} />
        </Grid>
      </Grid>
    </ExpansionDefault>
  );
};

Tags.defaultProps = {
  tags: [],
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  setTags: PropTypes.func.isRequired,
};

export default Tags;

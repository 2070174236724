import React, { memo } from "react";
import PropTypes from "prop-types";
import { TableCell, Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import QuantityField from "../../../../common/FolderFilesPropertiesTable/QuantityField";

const QuantityTableCell = ({
  quantityProperty,
  onChangeQuantity,
  resource,
  folderId,
  quantity,
  isNotifyEnabled,
}) => {
  const intl = useIntl();
  const { quantityType } = quantityProperty || {};
  return (
    <TableCell style={{ width: 150 }}>
      {isNotifyEnabled && quantityType && (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <span style={{ flex: 1 }}>
            <QuantityField
              fullWidth={false}
              quantityProperty={quantityProperty}
              value={quantity}
              onChange={e => {
                onChangeQuantity({
                  resourceId: resource.id,
                  value: e.target.value,
                  folderId,
                });
              }}
            />
          </span>
          <Typography component="span" style={{ marginLeft: 5 }}>
            {intl.formatMessage({
              id: "common.quantity",
            })}
          </Typography>
        </Box>
      )}
    </TableCell>
  );
};

QuantityTableCell.defaultProps = {};

QuantityTableCell.propTypes = {
  quantityProperty: PropTypes.shape({
    quantityType: PropTypes.string,
  }).isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
};

export default memo(QuantityTableCell);

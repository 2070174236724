import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";
import { useFileViewCopy } from "../../../hooks";

const RenderCopy = ({ folderId }) => {
  const fileViewCopy = useFileViewCopy();

  const handleCopyFolder = () => {
    fileViewCopy.copyFolder(folderId);
  };

  return (
    <ContextMenuItem onClick={handleCopyFolder}>
      <FormattedMessage id="context.copy" />
    </ContextMenuItem>
  );
};

RenderCopy.propTypes = {
  folderId: PropTypes.number.isRequired,
};

export default RenderCopy;

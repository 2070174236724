import React, { useEffect, useState } from "react";
import { debounce } from '@mui/material/utils';
import Autocomplete from '@mui/material/Autocomplete';
import { useLazyQuery } from "@apollo/client";

import { TextField } from "@mui/material";
import { SEARCH_ARTICLES } from "../../../graphql/queries";
import { useIntl } from "react-intl";

const ArticlePricelistAutoComplete = ({ companyId, selectedArticle, onSelectArticle, inputValue, onInputChange }) => {

  const [value, setValue] = React.useState(selectedArticle);
    const [options, setOptions] = React.useState([]);

  const [getArticles, { data: articleData, loading }] = useLazyQuery(SEARCH_ARTICLES);

  const intl = useIntl();

  useEffect(() => {

    if (articleData) {
      
      let newOptions = [];

      if (value) {
        newOptions = [value];
      }

      if (articleData?.companyAdminContext?.salesDocuments?.searchArticles) {
        newOptions = [...newOptions, ...articleData?.companyAdminContext?.salesDocuments?.searchArticles];
      }

      

      setOptions(newOptions);
    }

  }, [articleData])


  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        getArticles({ variables: { companyId: request.companyId, searchTerm: request.input } })
      }, 400),
    [],
  );

  useEffect(() => {

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, companyId: companyId }, (results) => { });

  }, [value, inputValue, fetch]);

  const optionLabel = (option) => {
    return option.articleNo + " " + option.description;
  }

  const renderOption = (props, option) => {
    return (<li {...props}>{optionLabel(option)}</li>);
  }

  return (<Autocomplete

    sx={{ width: 300 }}
    getOptionLabel={(option) =>
      optionLabel(option)
    }
    filterOptions={(x) => x}
    options={options}
    autoComplete
    loading={loading}
    includeInputInList
    filterSelectedOptions
    value={value}
    noOptionsText=""
    loadingText={intl.formatMessage({ id: "common.genericLoading" })}

    onChange={(event, newValue) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);
      onSelectArticle(newValue);
    }}
    onInputChange={(event, newInputValue) => {
      onInputChange(newInputValue);
    }}
    renderInput={(params) => (
      <TextField {...params} label={intl.formatMessage({ id: "product.articleNum" })} fullWidth />
    )}

    renderOption={renderOption}

  />)
};

export default ArticlePricelistAutoComplete;
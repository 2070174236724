import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import ReviewImage from "../../../assets/img/review-icon.svg";
import DraggableModal from "../DraggableModal";
import DraggableModalHead from "../DraggableModal/DraggableModalHead";
import DraggableModalBody from "../DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
    textAlign: "center",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  img: {
    display: "block",
    margin: "0 auto",
    marginBottom: 20,
    width: 90,
  },
  btnLink: {
    padding: 0,
    fontSize: 13,
    textTransform: "none",
    marginLeft: 5,
    minWidth: "unset",
    color: theme.palette.common.link,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
}));

const ConfirmationModal = ({
  disableYesButton,
  open,
  handleClose,
  onClick,
  message,
  variant,
}) => {
  const classes = useStyles();

  //variant for yes and no or okay buttons.

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody customClass={classes.modalBody}>
        <img src={ReviewImage} alt="Review" className={classes.img} />
        {message}
        <Box className={classes.modalFooter} align="right">
          {!variant && (
            <>
              <Button className={classes.btnCancel} onClick={handleClose}>
                <FormattedMessage id="btn.no" />
              </Button>
              <Button className={classes.btn} onClick={() => onClick()} disabled={disableYesButton}>
                <FormattedMessage id="btn.yes" />
              </Button>
            </>
          )}
          {variant === "secondary" && (
            <Button className={classes.btn} onClick={handleClose}>
              <FormattedMessage id="btn.ok" />
            </Button>
          )}
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmationModal.defaultProps = {
  message: null,
  variant: "",
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  variant: PropTypes.string,
};

export default ConfirmationModal;

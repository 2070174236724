import React, { useState } from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useIntl } from "react-intl";
import { createStyles, makeStyles } from "@mui/styles";
import NavLink from "../NavLink";
import PageRoute from "../../../constant/pageRouteDictionary.json";
import { useSelector, connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useSearchModal } from "../../../hooks";
import { setSearchQuery } from "../../../actions";

const useStyles = makeStyles(theme =>
  createStyles({
    menu: {
      backgroundColor: theme.palette.component.navbarBackground,
      borderRadius: 0,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.background.contrastOrange,
      },
    },
    menuWrap: {
      padding: 0,
    },
    childList: {
      [theme.breakpoints.down("sm")]: {
        minHeight: 40,
      },
      "&:hover": {
        backgroundColor: theme.palette.component.navbarBackground,
      },
      "& a": {
        color: theme.palette.component.navbarChildColor,
        [theme.breakpoints.down("sm")]: {
          marginBottom: 0,
        },
      },
      "& a:hover": {
        color: theme.palette.component.navbarChildColor,
        [theme.breakpoints.down("sm")]: {
          color: theme.palette.primary.contrastText,
        },
      },
      "& span": {
        color: theme.palette.component.navbarChildColor,
        [theme.breakpoints.down("sm")]: {
          marginBottom: 0,
        },
      },
      "& span:hover": {
        color: theme.palette.component.navbarChildColor,
        [theme.breakpoints.down("sm")]: {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    link: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      color: theme.palette.component.navbarColor,
      marginRight: 10,
      padding: "0 8px",
      textTransform: "uppercase",
      fontWeight: 600,
      display: "inline-flex",
      cursor: "pointer",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        marginBottom: 10,
        display: "block",
        width: "100%",
        textAlign: "left",
        color: theme.palette.primary.contrastText,
      },
      "&:hover": {
        color: theme.palette.component.navbarColor,
        [theme.breakpoints.down("sm")]: {
          color: theme.palette.primary.contrastText,
        },
      },
      "&:focus": {
        color: theme.palette.component.navbarColor,
      },
      "& svg": {
        [theme.breakpoints.down("sm")]: {
          verticalAlign: "bottom",
        },
      },
      sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
        },
      },
    },
  })
);

const NavMenu = ({ menu, setSearchQuery, customCss }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  var search = useSearchModal();

  const viewer = useSelector(state => state.api.currentViewer.viewer);

  const openMyMaterial = () => {
    search.searchType("detailed");
    setSearchQuery({
      searchQuery: "",
      productOwner: { value: viewer.userId, label: viewer.username },
    });

    search.open();
  };

  const handleNonOverviewLink = () => {
    if (menu.childMenu) return handleClick;
    return null;
  };
  return (
    <>
      {menu.page === "MY_MATERIAL" && (
        <span onClick={openMyMaterial} className={classes.link}>
          {menu.name?.trim() ||
            intl.formatMessage({ id: `pageKey.${menu.page}` })}
        </span>
      )}
      {menu.page !== "MY_MATERIAL" && (
        <NavLink
          label={
            menu.name?.trim() ||
            intl.formatMessage({ id: `pageKey.${menu.page}` })
          }
          hasChild={Boolean(menu.childMenu && menu.childMenu.length > 0)}
          open={Boolean(anchorEl)}
          link={PageRoute[menu.page] || "/"}
          onClick={handleNonOverviewLink()}
        />
      )}
      {menu.childMenu && menu.childMenu.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{ paper: classes.menu, list: classes.menuWrap }}
          onClose={handleClose}
        >
          {menu.childMenu.map((x, idx) => {
            return (
              <MenuItem
                onClick={handleClose}
                key={`menu-item-${idx}`}
                className={classes.childList}
              >
                {x.page === "MY_MATERIAL" && (
                  <span onClick={openMyMaterial} className={classes.link}>
                    {x.name?.trim() ||
                      intl.formatMessage({ id: `pageKey.${x.page}` })}
                  </span>
                )}
                {x.page !== "MY_MATERIAL" && (
                  <NavLink
                    key={`navlink-${idx}`}
                    label={
                      x.name?.trim() ||
                      intl.formatMessage({ id: `pageKey.${x.page}` })
                    }
                    link={PageRoute[x.page] || "/"}
                  />
                )}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

NavMenu.defaultProps = {};

NavMenu.propTypes = {
  menu: PropTypes.shape({
    name: PropTypes.string,
    page: PropTypes.string.isRequired,
    childMenu: PropTypes.array,
  }).isRequired,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setSearchQuery,
    },
    dispatch
  );
};

//export default UserDetails;
export default connect(null, mapDispatchToProps)(NavMenu);

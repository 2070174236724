import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Checkbox, Grid, Icon } from "@mui/material";
import clsx from "clsx";
import { FormattedList, FormattedMessage } from "react-intl";
import { PrimaryButton } from "../common/Buttons";
import {
  CookiesSectionLink,
  TermsOfUseSectionLink,
} from "../../components/TermsOfUse";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  btnPrev: {
    fontSize: 12,
    marginRight: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  marginRight: {
    marginRight: 5,
  },
  termsOfUse: {
    marginRight: "0.6em",
    "& .MuiCheckbox-root": {
      marginBottom: 3,
      marginRight: 2,
      padding: 0,
    },
    "& a": {
      color: theme.palette.common.link,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        color: theme.palette.text.primary,
        textDecoration: "underline",
      },
    },
  },
}));

const Footer = ({
  activeStep,
  setActiveStep,
  steps,
  stepOneValues,
  stepThreeValues,
  searchedCompany,
  selectedCompany,
  isKnownUser,
  handleSubmit,
}) => {
  const classes = useStyles();
  const maxSteps = steps.length - 1;

  const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false);

  useEffect(() => {
    if (activeStep === maxSteps) {
      setAcceptedTermsOfUse(false);
    }
  }, [activeStep]);

  const checkBtnDisabled = () => {
    if (activeStep === 0) {
      const tempValues = { ...stepOneValues };
      delete tempValues.language;
      const allTrue = Object.values(tempValues).every(item => item);

      if (allTrue && tempValues.password.length >= 8) {
        return false;
      }

      return true;
    }

    if (activeStep === 1) {
      const enabled = searchedCompany?.publicCompanyId || selectedCompany?.publicCompanyId || selectedCompany?.companyName;
      return !enabled;
    }

    if (activeStep === 2) {
      const tempValues = { ...stepThreeValues };
      delete tempValues.userCompanyPaysForItself;
      const allTrue = Object.values(tempValues).every(item => item);
      return !allTrue || !acceptedTermsOfUse;
    }

    return true;
  };

  const handleAcceptTermsOfUseChange = e => {
    setAcceptedTermsOfUse(e.target.checked);
  };

  const showBackBtn = activeStep > 0 && !isKnownUser;

  const TermsOfUse = () => {
    return (
      <Box component="span" className={classes.termsOfUse}>
        <Checkbox
          onChange={handleAcceptTermsOfUseChange}
          checked={acceptedTermsOfUse}
        />
        <FormattedMessage
          id="createAccount.termsOfUse.messageTemplate"
          defaultMessage="Godk&auml;nn villkor / Accept terms"
          values={{
            forApproval: (
              <FormattedList
                type="conjunction"
                value={[
                  <TermsOfUseSectionLink>
                    <FormattedMessage id="createAccount.termsOfUse.terms" />
                  </TermsOfUseSectionLink>,
                  <CookiesSectionLink>
                    <FormattedMessage id="createAccount.termsOfUse.useOfCookies" />
                  </CookiesSectionLink>,
                ]}
              />
            ),
          }}
        />
      </Box>
    );
  };

  return (
    <Grid container className={classes.root} align="right">
      <Grid item xs={12} md={12}>
        {activeStep === maxSteps && <TermsOfUse />}

        {showBackBtn && (
          <span className={classes.marginRight}>
            <PrimaryButton onClick={() => setActiveStep(activeStep - 1)}>
              <Icon
                className={clsx(["fa fa-long-arrow-alt-left", classes.btnPrev])}
              />
              <FormattedMessage id="btn.previous" />
            </PrimaryButton>
          </span>
        )}

        {activeStep !== maxSteps && (
          <PrimaryButton
            onClick={() => setActiveStep(activeStep + 1)}
            disabled={checkBtnDisabled()}
          >
            <FormattedMessage id="btn.next" />
            <Icon
              className={clsx(["fa fa-long-arrow-alt-right", classes.btnIcon])}
            />
          </PrimaryButton>
        )}

        {activeStep === maxSteps && (
          <PrimaryButton
            disabled={checkBtnDisabled()}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="btn.submit" />
            <Icon
              className={clsx(["fa fa-long-arrow-alt-right", classes.btnIcon])}
            />
          </PrimaryButton>
        )}
      </Grid>
    </Grid>
  );
};

Footer.defaultProps = {
  isKnownUser: null,
};

Footer.propTypes = {
  company: PropTypes.shape({
    companyName: PropTypes.string,
    publicCompanyId: PropTypes.string,
  }).isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.node).isRequired,
  stepOneValues: PropTypes.shape({}).isRequired,
  stepTwoValues: PropTypes.shape({}).isRequired,
  stepThreeValues: PropTypes.shape({}).isRequired,
  isKnownUser: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export default Footer;

import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Box } from "@mui/material";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(theme => ({
  outOfStockBox: {
    textAlign: "center",
    backgroundColor: theme.palette.background.contrastGray,
    padding: 3,
  },
  outOfStockIcon: {
    verticalAlign: "middle",
    marginRight: 7,
    fontSize: 12,
    color: theme.palette.text.lightGray,
  },
  outOfStockText: {
    color: theme.palette.text.lightGray,
    fontSize: 11,
    display: "inline-block",
  },
}));

const ProductOutOfStock = () => {
  const classes = useStyles();
  return (
    <Box className={classes.outOfStockBox}>
      <Icon
        className={clsx(["fas fa-exclamation-circle", classes.outOfStockIcon])}
      />
      <Typography className={classes.outOfStockText}>
        <FormattedMessage id="product.outOfStock" />
      </Typography>
    </Box>
  );
};

export default ProductOutOfStock;

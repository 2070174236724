import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { isNull } from "lodash";
import { useMutation } from "@apollo/client";
import { Box, Button, Divider, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CREATE_ACCOUNT_SETTINGS,
  UPDATE_ACCOUNT_SETTINGS,
} from "../../../../graphql/mutations/CompanyAdminContext";
import { getAdminSelectedCompanyId } from "../../../../helpers/adminSelectors";
import { commonErrors } from "../../../../constant/errorCodes";
import BannerSection from "./BannerSection";
import AccountTabPanel from "./AccountTabPanel";
import c3validators from "./c3validators";

const {
  ALREADY_EXISTS,
  INVALID_EMAIL,
  NOT_AUTHORIZED,
  PASSWORD_ERROR,
} = commonErrors;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  modalFooter: {
    padding: "10px 20px",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 25,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 3,
    },
  },
  text: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginBottom: 20,
  },
  divider: {
    marginTop: 4,
    marginBottom: 0,
  },
  settingWrapper: {
    padding: "5px 20px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 8px 20px 8px",
    },
  },
}));

const AccountSettings = ({ selectedUserId, settings, handleClose }) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const companyId = useSelector(getAdminSelectedCompanyId);

  const [currentUserId, setCurrentUserId] = useState(null);
  const [disableSave, setDisableSave] = useState(false);
  const [hideSave, setHideSave] = useState(false);
  const [isViewedAs, setIsViewedAs] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [accountSettings, setAccountSettings] = useState(null);
  const [haveValidColumnThree, setHaveValidColumnThree] = useState(false);

  const [createAccountSettings] = useMutation(CREATE_ACCOUNT_SETTINGS);
  const [updateAccountSettings] = useMutation(UPDATE_ACCOUNT_SETTINGS);

  const [validationC3, setValidationC3] = useState({
    currentPassword: null,
    network: null,
    role: null,
    password: null,
    passwordConfirm: null,
    username: null,
  });

  useEffect(() => {
    setDisableSave(!haveValidColumnThree);
  }, [haveValidColumnThree]);

  useEffect(() => {
    if (editMode && accountSettings) {
      var update = c3validators.validateC3(editMode, accountSettings);
      setValidationC3({
        ...validationC3,
        ...update,
      });
    }
  }, [editMode, accountSettings]);

  useEffect(() => {
    if (validationC3) {
      for (var x in validationC3) {
        if (!validationC3[x]) {
          setHaveValidColumnThree(false);
          return;
        }
      }
      setHaveValidColumnThree(true);
    }
  }, [validationC3]);

  useEffect(() => {
    var isEditMode = !isNull(selectedUserId);
    setCurrentUserId(selectedUserId);
    setEditMode(isEditMode);
  }, [selectedUserId]);

  useEffect(() => {
    if (settings) {      
      setAccountSettings(settings);
    }
  }, [settings]);

  const handleTabChange = (newValue) => {
    //Hide if security-tab is shown.
    setHideSave(newValue === 3);
  }

  const handleCleanParams = () => {
    const newParams = { ...accountSettings };

    delete newParams.allowEditAddresses;
    delete newParams.allowEditAvatar;
    delete newParams.allowEditCompanyRole;
    delete newParams.allowEditSubscriptionLevel;
    delete newParams.allowEditUsername;
    delete newParams.companyRoleName;
    delete newParams.companyRoles;
    delete newParams.deliveryAddressSettings;
    delete newParams.invoiceAddressSettings;
    delete newParams.postalAddressSettings;
    delete newParams.selectableNetworks;
    delete newParams.selectableThemes;
    delete newParams.userGroupBreadCrumb;
    delete newParams.visitingAddressSettings;
    delete newParams.enabledMFATypes;
    delete newParams.avatarUrl;

    return newParams;
  };

  const handleCreateAccountSettings = async () => {
    if (!haveValidColumnThree) {
      return handleCreateError();
    }

    try {
      const model = handleCleanParams();

      const res = await createAccountSettings({
        variables: { companyId, model },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.users &&
        res.data.companyAdminContext.users.createAccountSettings
      ) {
        const {
          success,
          errorResult,
          user,
        } = res.data.companyAdminContext.users.createAccountSettings;

        if (success) {
          alert.success(
            <FormattedMessage id="userAccount.success.userCreated" />
          );
          handleClose({ ...user, userCreated: true });
        } else {
          handleCreateError(errorResult.errorCode);
        }
      }
    } catch (err) {
      console.error("error >", err);
      handleCreateError();
    }
  };

  const handleCreateError = errorCode => {
    const alertError = id => {
      const specific = intl.formatMessage({ id });
      const couldNotCreate = intl.formatMessage({
        id: "userAccount.errors.couldNotCreate",
      });
      alert.error(`${specific}\n${couldNotCreate}`);
    };

    if (errorCode === NOT_AUTHORIZED) {
      alertError("userAccount.errors.notAuthorized");
    } else if (errorCode === INVALID_EMAIL) {
      alertError("userAccount.errors.invalidEmail");
    } else if (errorCode === PASSWORD_ERROR) {
      alertError("userAccount.errors.invalidPassword");
    } else if (errorCode === ALREADY_EXISTS) {
      alertError("userAccount.errors.alreadyExists");
    } else {
      alertError("userAccount.errors.genericError");
    }

    setDisableSave(false);
  };

    const handleLocation = e => {
        
      setAccountSettings({ ...accountSettings, location: e.target.value });
  };

  const handleSaveClick = async e => {
    setDisableSave(true);
    if (editMode) {
      await handleUpdateAccountSettings();
    } else {
      await handleCreateAccountSettings();
    }
  };

  const handleUpdateAccountSettings = async () => {
    if (!haveValidColumnThree) {
      return handleUpdateError();
    }

    try {
      const model = handleCleanParams();

      const res = await updateAccountSettings({
        variables: { userId: currentUserId, model },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.users &&
        res.data.companyAdminContext.users.updateAccountSettings
      ) {
        const {
          success,
          errorResult,
          user,
        } = res.data.companyAdminContext.users.updateAccountSettings;

        if (success) {
          alert.success(
            <FormattedMessage id="userAccount.success.userUpdated" />
          );
          handleClose({ ...user, userUpdated: true });
        } else {
          handleUpdateError(errorResult.errorCode);
        }
      }
    } catch (err) {
      console.error("error >", err);
      handleUpdateError();
    }
  };

  const handleUpdateError = errorCode => {
    const alertError = id => {
      const specific = intl.formatMessage({ id });
      const couldNotUpdate = intl.formatMessage({
        id: "userAccount.errors.couldNotUpdate",
      });
      alert.error(`${specific}\n${couldNotUpdate}`);
    };

    if (errorCode === NOT_AUTHORIZED) {
      alertError("userAccount.errors.notAuthorized");
    } else if (errorCode === INVALID_EMAIL) {
      alertError("userAccount.errors.invalidEmail");
    } else if (errorCode === PASSWORD_ERROR) {
      alertError("userAccount.errors.invalidPassword");
    } else {
      alertError("userAccount.errors.genericError");
    }

    setDisableSave(false);
  };

  return (accountSettings && (
    <>
      <BannerSection
        editMode={editMode}
        accountSettings={accountSettings}
        setIsViewedAs={setIsViewedAs}
        setAccountSettings={setAccountSettings}
        handleLocation={handleLocation}
        settings={settings}
      />
      <Box className={classes.settingWrapper}>
        <Divider className={classes.divider} />

        <AccountTabPanel
          editMode={editMode}
          selectedUserId={selectedUserId}
          accountSettings={accountSettings}
          setAccountSettings={setAccountSettings}
          setValidationC3={setValidationC3}
          validationC3={validationC3}
          onTabChange={handleTabChange}
        />
        <Box align="right">

          {!hideSave && (
          <Button
            className={classes.btn}
            disabled={disableSave}
            onClick={handleSaveClick}
          >
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.save" />
            </Button>)}
        </Box>
      </Box>
    </>)
  );
};

export default AccountSettings;

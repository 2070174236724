import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import PickingListTableHeader from "./PickingListTableHeader";
import PickingListTableSearch from "./PickingListTableSearch";
import PickingListTableRow from "./PickingListTableRow";
import PickingLisTableFooter from "./PickingListTableFooter";
import { initialToggleHeader } from "./helpers";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  spinner: {
    marginTop: 5,
    color: theme.palette.primary.main,
  },
}));

const PickingListTable = ({
  loading,
  searchValues,
  setSearchValues,
  fetchPickingListOrders,
  pickingListOrders,
  showColumn,
  totalCount,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
  setSelectedOrders,
  isHistory,
  page,
  setPage,
  onOrderPrinted,
}) => {
  const classes = useStyles();

  const [toggleHeader, setToggleHeader] = useState(initialToggleHeader);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchValues({
      ...searchValues,
      offset: newPage * searchValues.pageSize,
    });
  };

  const handleChangeRowsPerPage = e => {
    const pageSize = parseInt(e.target.value, 10);
    setSearchValues({ ...searchValues, pageSize });
  };

  const handleToggleHeader = name => {
    setToggleHeader({ ...initialToggleHeader, [name]: !toggleHeader[name] });
  };

  const { pickingListOrderStatus } = searchValues;

  return (
    <div className={classes.root}>
      <Table size="small">
        <PickingListTableHeader
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          fetchPickingListOrders={fetchPickingListOrders}
          showColumn={showColumn}
          toggleHeader={toggleHeader}
          handleToggleHeader={handleToggleHeader}
          pickingListOrders={pickingListOrders}
          setSelectedOrders={setSelectedOrders}
          isHistory={isHistory}
        />
        <TableBody>
          {loading && (
            <CircularProgress className={classes.spinner} size={20} />
          )}
          {!loading && (
            <>
              {pickingListOrders.map(x => {
                return (
                  <PickingListTableRow
                    key={x.pickingListOrdersItemId}
                    order={x}
                    showColumn={showColumn}
                    selectedOrders={selectedOrders}
                    addSelectedOrder={addSelectedOrder}
                    removeSelectedOrder={removeSelectedOrder}
                    pickingListOrderStatus={pickingListOrderStatus}
                    fetchPickingListOrders={fetchPickingListOrders}
                    onOrderPrinted={onOrderPrinted}
                  />
                );
              })}
            </>
          )}
        </TableBody>
        <PickingLisTableFooter
          page={page}
          rowsPerPage={searchValues.pageSize}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        />
      </Table>
    </div>
  );
};

PickingListTable.defaultProps = {
  searchValues: {},
  loading: false,
  pickingListOrders: [],
  showColumn: {},
};

PickingListTable.propTypes = {
  totalCount: PropTypes.number.isRequired,
  showColumn: PropTypes.shape({}),
  pickingListOrders: PropTypes.arrayOf(PropTypes.shape({})),
  fetchPickingListOrders: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  searchValues: PropTypes.shape({
    offset: PropTypes.number,
    pageSize: PropTypes.number,
    pickingListOrderStatus: PropTypes.string,
  }),
  setSearchValues: PropTypes.func.isRequired,
  selectedOrders: PropTypes.arrayOf(PropTypes.number).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
  setSelectedOrders: PropTypes.func.isRequired,
  onOrderPrinted: PropTypes.func.isRequired,
};

export default PickingListTable;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Typography, Tabs, Tab } from "@mui/material";
import { useIntl } from "react-intl";
import AboutSectionReadOnly from "./AboutSectionReadOnly";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const MoreContent = ({ userDetails }) => {
  const [value, setValue] = useState(0);
  const intl = useIntl();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ boxShadow: "none", backgroundColor: "white" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="Account settings tab"
        >
          <Tab
            label={intl.formatMessage({
              id: "account.about",
            })}
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AboutSectionReadOnly userDetails={userDetails} />
      </TabPanel>
    </>
  );
};

export default MoreContent;

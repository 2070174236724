import { gql } from '@apollo/client';
import FolderProductSettings from "../fragments/FolderProductSettings";

const getMonitoringProductionSettings = gql`
  query getMonitoringProductionSettings($folderId: Int!) {
    monitoringContext {
      productionSettings(folderId: $folderId) {
        ...FolderProductSettingsFragment
      }
    }
  }
  ${FolderProductSettings}
`;

export default getMonitoringProductionSettings;

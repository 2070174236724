import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Box } from "@mui/material";
import { FormattedMessage, FormattedNumber } from "react-intl";
import clsx from "clsx";
import FormattedDate from "../../common/FormattedDate";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
  },
  strong: {
    fontWeight: 700,
  },
}));

const OrderInfoRight = ({ message, deliveryDateWish, orderWeight }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <Typography className={clsx([classes.text, classes.strong])}>
        <FormattedMessage id="orderHistory.earliestDeliveryDate" />
      </Typography>
      <Typography className={classes.text}>
        <FormattedDate value={deliveryDateWish} />
      </Typography>
      <Box className={classes.spacing} />
      <Typography className={clsx([classes.text, classes.strong])}>
        <FormattedMessage id="orderHistory.weight" />
      </Typography>
      <Typography className={classes.text}><FormattedNumber value={orderWeight}/> kg</Typography>
      <Typography className={clsx([classes.text, classes.strong])}>
        <FormattedMessage id="context.message" />
      </Typography>
      <Typography className={classes.text}>{message}</Typography>

    </Grid>
  );
};

OrderInfoRight.defaultProps = {
  message: null,
  deliveryDateWish: null,
};

OrderInfoRight.propTypes = {
  deliveryDateWish: PropTypes.instanceOf(Date),
  message: PropTypes.string,
};

export default OrderInfoRight;

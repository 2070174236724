import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Box, Button, Icon } from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import logger from "../../../helpers/logger";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import { ADD_MESSAGE as ADD_DIRECT_MESSAGE } from "../../../graphql/mutations/DirectMessageContext";
import { ADD_MESSAGE as ADD_SITE_CHANNEL_MESSAGE } from "../../../graphql/mutations/SiteChannelContext";
import { INIT_ATTACHMENT_UPLOAD as INIT_DIRECT_MESSAGE_ATTACHMENT_UPLOAD } from "../../../graphql/mutations/DirectMessageContext";
import { INIT_ATTACHMENT_UPLOAD as INIT_SITE_CHANNEL_ATTACHMENT_UPLOAD } from "../../../graphql/mutations/SiteChannelContext";
import CustomTextField from "../../common/TextField";
import MessageBoxAttachments from "./MessageBoxAttachments";
import SelectFileFromSystemModal from "../../common/SelectFileFromSystemModal";
import UploadPopover from "./UploadPopover";

const useStyles = makeStyles(theme => ({
  iconLink: {
    color: "#888",
    marginLeft: 10,
    padding: 0,
    minWidth: "unset",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    marginRight: 5,
  },
  messageField: {
    marginTop: 0,
    borderRadius: 4,
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  messageBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconSendLink: {
    color: theme.palette.primary.main,
    padding: 10,
    borderRadius: 0,
    borderLeft: "1px solid #dedede",
    marginLeft: 10,
    minWidth: "unset",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  iconSend: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 16,
  },
  root: {
    padding: "5px 15px 5px 10px",
    borderRadius: 6,
    border: "1px solid #E4E4E4",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const MessageBox = ({ activeChannelId, activeConversationId, disabled }) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();

  const [addMessage, { loading: loadingAddMessage }] = useMutation(
    ADD_SITE_CHANNEL_MESSAGE
  );
  const [
    sendDirectMessage,
    { loading: loadingSendDirectMessage },
  ] = useMutation(ADD_DIRECT_MESSAGE);

  const [uploadFromSystemModal, setUploadFromSystemModal] = useState(false);
  const [toUploadFromSystem, setToUploadFromSystem] = useState([]);
  const [toUploadFiles, setToUploadFiles] = useState([]);

  const [initDirectMessageAttachmentUpload] = useMutation(
    INIT_DIRECT_MESSAGE_ATTACHMENT_UPLOAD
  );
  const [initSiteChannelAttachmentUpload] = useMutation(
    INIT_SITE_CHANNEL_ATTACHMENT_UPLOAD
  );
  const [attachments, setAttachments] = useState([]);
  const [uploadingAttachment, setUploadingAttachment] = useState(false);

  const [messageText, setTextMessage] = useState("");
  const [hideComponent, setHideComponent] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const uploadPopoverVisible = Boolean(anchorEl);
  const uploadPopoverId = uploadPopoverVisible ? "upload-popover" : undefined;

  useEffect(() => {
    setHideComponent(Boolean(activeChannelId) === Boolean(activeConversationId));
  }, [activeChannelId, activeConversationId]);

  if (hideComponent) {
    return null;
  }

  const handleOpenPopover = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleCloseuploadFromSystemModal = () => {
    setToUploadFiles([...toUploadFiles, ...toUploadFromSystem]);
    setUploadFromSystemModal(false);
    setToUploadFromSystem([]);
  };

  const handleOpenuploadFromSystemModal = () => {
    handleClosePopover();
    setUploadFromSystemModal(true);
    setToUploadFromSystem([]);
  };

  const handleUploadFilesFromSystem = files => {
    setToUploadFromSystem([...files]);
  };

  const handleAddMessage = async () => {
    if (messageText === "" && attachments.length === 0) {
      return;
    }
    try {
      const message = {
        content: [{ content: messageText, type: "TEXT" }],
        siteChannelId: activeChannelId,
        attachments: attachments.filter(x => x.uploaded).map(x => x.id),
      };

      const results = await addMessage({
        variables: {
          message,
        },
      });
      if (
        results &&
        results.data &&
        results.data.siteChannelContext &&
        results.data.siteChannelContext.addMessage
      ) {
        setTextMessage("");
        setAttachments([]);
      } else {
        alert.error(<FormattedMessage id="messaging.errors.failedToSend" />);
      }
    } catch (e) {
      alert.error(<FormattedMessage id="messaging.errors.failedToSend" />);
    }
  };

  const handleSendDirectMessage = async () => {
    if (messageText === "" && attachments.length === 0) {
      return;
    }
    try {
      const message = {
        content: [{ content: messageText, type: "TEXT" }],
        directConversationId: activeConversationId,
        attachments: attachments.filter(x => x.uploaded).map(x => x.id),
      };

      const results = await sendDirectMessage({
        variables: {
          message,
        },
      });
      if (
        results &&
        results.data &&
        results.data.directMessageContext &&
        results.data.directMessageContext.addMessage
      ) {
        setTextMessage("");
        setAttachments([]);
      } else {
        alert.error(<FormattedMessage id="messaging.errors.failedToSend" />);
      }
    } catch (e) {
      alert.error(<FormattedMessage id="messaging.errors.failedToSend" />);
    }
  };

  const handleChangeText = e => {
    setTextMessage(e.target.value);
  };

  const handleOnKeyDown = e => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessageButtonClick(e);
    }
  };

  const handleSendMessageButtonClick = e => {
    if (Boolean(activeChannelId)) {
      handleAddMessage();
    } else if (Boolean(activeConversationId)) {
      handleSendDirectMessage();
    }
  };

  const handleInitAttachmentUpload = async () => {
    if (Boolean(activeChannelId)) {
      return await initSiteChannelAttachmentUpload({
        variables: { siteChannelId: activeChannelId },
      });
    } else if (Boolean(activeConversationId)) {
      return await initDirectMessageAttachmentUpload({
        variables: { directMessageConversationId: activeConversationId },
      });
    } else {
      throw new Error("Invalid state.");
    }
  };

  const getInitAttachmentUploadResult = res => {
    var { directMessageContext, siteChannelContext } = res?.data || {};
    if (Boolean(activeChannelId)) {
      return siteChannelContext?.initAttachmentUpload || {};
    } else if (Boolean(activeConversationId)) {
      return directMessageContext?.initAttachmentUpload || {};
    } else {
      throw new Error("Invalid state.");
    }
  };

  const createExceptionMessage = errorResult => {
    return errorResult
      ? `${errorResult.errorCode} : ${errorResult.errorMessage}`
      : "unknown error";
  };

  const handleAttachmentUpload = async e => {
    const { target } = e || {};
    const { files } = target || {};

    handleClosePopover();

    const file = files[0];
    if (!file) {
      logger.info("No attachment provided for upload.");
      return;
    }

    setUploadingAttachment(true);

    var uploadUrl;

    try {
      const res = await handleInitAttachmentUpload();
      const initResult = getInitAttachmentUploadResult(res);
      if (!initResult.success) {
        throw new Error(createExceptionMessage(initResult.errorResult));
      }
      uploadUrl = initResult.url;
    } catch (ex) {
      logger.error("Failed to initialize upload.", ex);
      alert.error(<FormattedMessage id="messaging.errors.failedToUpload" />);
      setUploadingAttachment(false);
      return;
    }

    setAttachments([
      ...attachments,
      {
        id: null,
        key: uploadUrl,
        name: file.name,
        size: file.size,
        type: file.type,
        uploaded: false,
        url: URL.createObjectURL(file),
      },
    ]);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(`${uploadUrl}`, formData);

      if (response && response.data && response.status === 200) {
        const { data } = response;
        if (!data.success) {
          throw new Error(createExceptionMessage(data.errorResult));
        }
        updateOrAddAttachment(uploadUrl, data);
      } else {
        throw new Error(createExceptionMessage(response?.data?.errorResult));
      }

      logger.info("Attachment added.");
    } catch (ex) {
      logger.error("Failed to upload attachment.", ex);
      alert.error(<FormattedMessage id="messaging.errors.failedToUpload" />);
      removeAttachment(uploadUrl);
    }

    setUploadingAttachment(false);
  };

  const removeAttachment = key => {
    const temp = attachments.filter((x, idx) => x.key !== key);
    setAttachments([...temp]);
  };

  const updateOrAddAttachment = (key, data) => {
    var temp = [...attachments];
    var o = temp.find(n => n.key === key);
    if (o) {
      o.id = data.id;
      o.uploaded = true;
      o.url = data.previewUrl;
      setAttachments(temp);
    } else {
      setAttachments([
        ...attachments,
        {
          id: data.id,
          key: data.id,
          name: data.filename,
          size: data.size,
          type: data.mediaType,
          uploaded: true,
          url: data.previewUrl,
        },
      ]);
    }
  };

  return (
    <Box className={classes.root}>
      <MessageBoxAttachments
        attachments={attachments}
        removeAttachment={removeAttachment}
      />
      <Box className={classes.messageBox}>
        <CustomTextField
          disabled={
            disabled ||
            loadingAddMessage ||
            loadingSendDirectMessage ||
            uploadingAttachment
          }
          value={messageText}
          multiline
          onChange={handleChangeText}
          customClass={classes.messageField}
          onKeyDown={handleOnKeyDown}
          placeholder={intl.formatMessage({
            id: "messaging.messageTextInput.placeholder",
          })}
        />
        {false && (
          <Button className={classes.iconLink}>
            <Icon className={clsx(["fas fa-font", classes.icon])} />
          </Button>
        )}
        {false && (
          <Button className={classes.iconLink}>
            <Icon className={clsx(["fas fa-at", classes.icon])} />
          </Button>
        )}
        {false && (
          <Button className={classes.iconLink}>
            <Icon className={clsx(["far fa-smile", classes.icon])} />
          </Button>
        )}
        <Button
          className={classes.iconLink}
          onClick={handleOpenPopover}
          disabled={
            disabled ||
            loadingAddMessage ||
            loadingSendDirectMessage ||
            uploadingAttachment
          }
        >
          <Icon className={clsx(["fas fa-paperclip", classes.icon])} />
        </Button>
        <Button
          title={intl.formatMessage({
            id: "messaging.sendMessageButton.title",
          })}
          className={classes.iconSendLink}
          onClick={handleSendMessageButtonClick}
          disabled={
            disabled ||
            loadingAddMessage ||
            loadingSendDirectMessage ||
            uploadingAttachment ||
            (messageText === "" && attachments.length === 0)
          }
        >
          <Icon className={clsx(["fas fa-paper-plane", classes.iconSend])} />
        </Button>
      </Box>
      <UploadPopover
        id={uploadPopoverId}
        open={uploadPopoverVisible}
        anchorEl={anchorEl}
        handleClose={handleClosePopover}
        handleOnSelectUploadFile={handleAttachmentUpload}
        handleOpenuploadFromSystemModal={handleOpenuploadFromSystemModal}
      />
      <SelectFileFromSystemModal
        open={uploadFromSystemModal}
        handleClose={handleCloseuploadFromSystemModal}
        setSelectedResources={handleUploadFilesFromSystem}
        selectedResources={toUploadFromSystem}
      />
    </Box>
  );
};

MessageBox.defaultProps = {
  activeChannelId: null,
  activeConversationId: null,
  disabled: false,
};

MessageBox.propTypes = {
  activeChannelId: PropTypes.number,
  activeConversationId: PropTypes.number,
  disabled: PropTypes.bool,
};

export default MessageBox;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import { Box, Grid, Typography, Icon, IconButton } from "@mui/material";
import { FILE_DOWNLOAD } from "../../../../../../../graphql/mutations";
import { getFileExtension } from "./helper";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "5px 0",
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  iconButtonDownload: {
    padding: 4,
    color: theme.palette.common.lightGrey,
    marginLeft: 5,
  },
  iconButtonEdit: {
    padding: 4,
    marginLeft: 5,
    color: theme.palette.common.black,
  },
}));

const FilesRow = ({ file, productId, onDeleteFile, onEditFile }) => {
  const classes = useStyles();
  const [fileDownload] = useMutation(FILE_DOWNLOAD);

  const handleFileDownload = async () => {
    try {
      const res = await fileDownload({
        variables: { productId, filename: file },
      });
      if (
        res &&
        res.data &&
        res.data.dynamicTemplateEditContext &&
        res.data.dynamicTemplateEditContext.initFileDownload
      ) {
        window.open(res.data.dynamicTemplateEditContext.initFileDownload);
      } else {
        console.log("Download Failure");
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  const fileExtension = getFileExtension(file);
  const showEdit = fileExtension === "xml" || fileExtension === "js";

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={10}>
          <Typography>{file}</Typography>
        </Grid>
        <Grid item xs={2} align="right">
          {showEdit && (
            <IconButton
              className={classes.iconButtonEdit}
              onClick={() => onEditFile(file)}
            >
              <Icon className={clsx(["fa fa-edit", classes.icon])} />
            </IconButton>
          )}

          <IconButton
            className={classes.iconButtonDownload}
            onClick={handleFileDownload}
          >
            <Icon className={clsx(["fa fa-download", classes.icon])} />
          </IconButton>
          <IconButton
            className={classes.iconButtonDelete}
            onClick={() => onDeleteFile(file)}
          >
            <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

FilesRow.defaultProps = {
  file: null,
  productId: null,
};

FilesRow.propTypes = {
  file: PropTypes.string,
  productId: PropTypes.string,
  onDeleteFile: PropTypes.func.isRequired,
  onEditFile: PropTypes.func.isRequired,
};

export default FilesRow;

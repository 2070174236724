import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Divider } from "@mui/material";
import { useAlert } from "react-alert";
import axios from "axios";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { DOWNLOAD_IMAGES } from "../../../graphql/mutations";
import OriginalImageModal from "../OriginalImageModal";
import WebbModal from "../WebbModal";
import MSOfficeModal from "../MSOfficeModal";
import TryckModal from "../TryckModal";
import ShareLinkModal from "../ShareLinkModal/ShareLinkModal";
import PrintImages from "../PrintImages";
import ConvertTodo from "./ConvertToDo";
import ConvertOptions from "./ConvertOptions";
import ConvertInstructions from "./ConvertInstructions";
import ConvertActions from "./ConvertActions";
import ConvertSettings from "./ConvertSettings";
import { initialOptionValues, SETTING_IDS } from "../helpers";
import CircularProgress from "../../common/CircularProgress";

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 20,
    display: "block",
    color: theme.palette.component.productBoxColor,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const ImageOptions = ({
  allImageOption,
  colorModelOptions,
  eachImageArr,
  fileFormatOptions,
  handleOptions,
  imageBasketItems,
  selectImageOption,
  shareId,
}) => {
  const classes = useStyles();
  const alert = useAlert();

  const [showColorModel, setShowColorModel] = useState(false);
  const [showFileFormat, setShowFileFormat] = useState(false);
  const [includeOriginal, setIncludeOriginal] = useState(false);
  const [selectedColorMode, setSelectedColorMode] = useState("");
  const [selectedFileFormat, setSelectedFormat] = useState("");
  const [downloadImages] = useMutation(DOWNLOAD_IMAGES);
  const [imageOptions, setImageOptions] = useState(initialOptionValues);
  const [progress, setProgress] = useState(0);

  const [loadingModal, setLoadingModal] = useState(false);

  const [displayActionButtons, setDisplayActionButtons] = useState(false);

  const [imagesToShare, setImagesToShare] = useState([]);

  useEffect(() => {
    var haveItems =
      Array.isArray(imageBasketItems) && imageBasketItems.length > 0;
    setDisplayActionButtons(haveItems);
  }, [imageBasketItems]);

  const [openOriginalImage, setOpenOriginalImage] = useState(false);
  const handleOpenOriginalImage = () => {
    setOpenOriginalImage(true);
  };
  const handleCloseOriginalImage = () => {
    setOpenOriginalImage(false);
  };

  const [openWebb, setOpenWebb] = useState(false);
  const handleOpenWebb = () => {
    setOpenWebb(true);
  };
  const handleCloseWebb = () => {
    setOpenWebb(false);
  };

  const [openMSOffice, setOpenMSOffice] = useState(false);
  const handleOpenMSOffice = () => {
    setOpenMSOffice(true);
  };
  const handleCloseMSOffice = () => {
    setOpenMSOffice(false);
  };

  const [openTryck, setOpenTryck] = useState(false);
  const handleOpenTryck = () => {
    setOpenTryck(true);
  };
  const handleCloseTryck = () => {
    setOpenTryck(false);
  };

  const [openShareLink, setOpenShareLink] = useState(false);
  const handleOpenShareLink = () => {
    var images = getImages();
    setImagesToShare(images);
    setOpenShareLink(true);
  };
  const handleCloseShareLink = () => {
    setOpenShareLink(false);
  };
  const handleOnShareCompleted = () => {
    alert.info(
      <FormattedMessage id="imageBasket.shareModal.success.shareCreated" />
    );
    handleCloseShareLink();
  };

  const [openPrint, setOpenPrint] = useState(false);
  const handleOpenPrint = () => {
    setOpenPrint(true);
  };
  const handleClosePrint = () => {
    setOpenPrint(false);
  };

  const downloadFile = (filePath, filename) => {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filename ?? filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
    setProgress(0);
    setLoadingModal(false);
  };

  const handleDownloadImage = async () => {
    setLoadingModal(true);
    let images = [];
    if (selectImageOption) {
      images = eachImageArr.filter(arr => {
        return arr !== null;
      });
    } else {
      let tempImages = [];
      const settings = [];

      if (imageOptions.webb) {
        settings.push({ settingId: SETTING_IDS.WEB });
      }

      if (imageOptions.msOffice) {
        settings.push({ settingId: SETTING_IDS.MS_OFFICE });
      }

      if (imageOptions.tryck) {
        settings.push({
          settingId: SETTING_IDS.PRINT,
          selectedFileFormat,
          selectedColorMode,
        });
      }

      imageBasketItems.forEach(x => {
        const params = {
          includeOriginal,
          productId: x.productId,
          settings,
        };

        tempImages = [...tempImages, params];
      });

      images = tempImages;
    }

    if (images && images.length === 0) {
      return alert.error(
        <FormattedMessage id="imageBasket.pleaseSelectAnOption" />
      );
    }

    try {
      const results = await downloadImages({
        variables: {
          images,
        },
      });

      if (results && results.data) {
        const { initImageBankDownload } = results.data;
        const result = await axios({
          url: initImageBankDownload,
          method: "GET",
          responseType: "blob",
          onDownloadProgress: progressEvent => {
            const { loaded, total } = progressEvent;
            const percentCompleted = Math.round((loaded * 100) / total);
            setProgress(percentCompleted);
          },
        }).then(res => {
          const filename = getFilename(res);
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: res.data.type })
          );
          downloadFile(url, filename);
        });
      }
    } catch (err) {
      alert.error(<FormattedMessage id="common.genericErrorMessage" />);
      setProgress(0);
      setLoadingModal(false);
    }
  };

  const getFilename = response => {
    const contentDisposition = response?.headers["content-disposition"];
    if (!contentDisposition) {
      return null;
    }
    const filename = contentDisposition
      .split(";")[1]
      .split("=")[1]
      .replace('"', "")
      .replace('"', "");
    return decodeURI(filename);
  };

  const getImages = () => {
    let images = [];
    if (selectImageOption) {
      images = eachImageArr.filter(arr => {
        return arr !== null;
      });
    } else {
      let tempImages = [];
      const settings = [];

      if (imageOptions.webb) {
        settings.push({ settingId: SETTING_IDS.WEB });
      }

      if (imageOptions.msOffice) {
        settings.push({ settingId: SETTING_IDS.MS_OFFICE });
      }

      if (imageOptions.tryck) {
        settings.push({
          settingId: SETTING_IDS.PRINT,
          selectedFileFormat,
          selectedColorMode,
        });
      }

      imageBasketItems.forEach(x => {
        const params = {
          includeOriginal,
          productId: x.productId,
          settings,
        };

        tempImages = [...tempImages, params];
      });

      images = tempImages;
    }

    return images;
  };

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        <ConvertTodo handleOpenPrint={handleOpenPrint} />
        <ConvertOptions handleOptions={handleOptions} />
        <Divider className={classes.divider} />
        <ConvertSettings
          allImageOption={allImageOption}
          colorModelOptions={colorModelOptions}
          fileFormatOptions={fileFormatOptions}
          handleOpenMSOffice={handleOpenMSOffice}
          handleOpenOriginalImage={handleOpenOriginalImage}
          handleOpenTryck={handleOpenTryck}
          handleOpenWebb={handleOpenWebb}
          setImageOptions={setImageOptions}
          imageOptions={imageOptions}
          includeOriginal={includeOriginal}
          selectedColorMode={selectedColorMode}
          selectedFileFormat={selectedFileFormat}
          setIncludeOriginal={setIncludeOriginal}
          setSelectedColorMode={setSelectedColorMode}
          setSelectedFormat={setSelectedFormat}
          setShowColorModel={setShowColorModel}
          setShowFileFormat={setShowFileFormat}
          showColorModel={showColorModel}
          showFileFormat={showFileFormat}
        />
        <ConvertInstructions selectImageOption={selectImageOption} />
        <ConvertActions
          allImageOption={allImageOption}
          selectImageOption={selectImageOption}
          downloadOptions={{
            display: displayActionButtons,
            enable: true,
            onClick: handleDownloadImage,
          }}
          shareLinkOptions={{
            display: displayActionButtons,
            enable: true,
            onClick: handleOpenShareLink,
          }}
        />
      </Paper>
      {openOriginalImage && (
        <OriginalImageModal
          open={openOriginalImage}
          handleClose={handleCloseOriginalImage}
        />
      )}
      {openWebb && <WebbModal open={openWebb} handleClose={handleCloseWebb} />}
      {openMSOffice && (
        <MSOfficeModal open={openMSOffice} handleClose={handleCloseMSOffice} />
      )}
      {openTryck && (
        <TryckModal open={openTryck} handleClose={handleCloseTryck} />
      )}

      {openShareLink && (
        <ShareLinkModal
          open={openShareLink}
          handleClose={handleCloseShareLink}
          onShareCompleted={handleOnShareCompleted}
          imageData={imagesToShare}
        />
      )}

      {openPrint && (
        <PrintImages
          open={openPrint}
          handleClose={handleClosePrint}
          imageBasketItems={imageBasketItems}
          shareId={shareId}
        />
      )}
      {loadingModal && (
        <CircularProgress
          open={loadingModal}
          handleClose={() => setLoadingModal(false)}
          progress={progress}
          message={<FormattedMessage id="common.preparingDownload" />}
        />
      )}
    </div>
  );
};

ImageOptions.defaultProps = {
  shareId: null,
};

ImageOptions.propTypes = {
  allImageOption: PropTypes.bool.isRequired,
  colorModelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  eachImageArr: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fileFormatOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  handleOptions: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
      settings: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  selectImageOption: PropTypes.bool.isRequired,
  shareId: PropTypes.number,
};

export default ImageOptions;

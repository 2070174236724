export const BOOKING_TYPE = "BOOKING_VIEW";
export const DOCUMENT_TYPE = "DOCUMENT";
export const DYNAMIC_PRODUCT_TYPE = "DYNAMIC_TEMPLATE";
export const FROM_VALUES_LIST = "FROM_VALUES_LIST";
export const STOCK_PRODUCT = "STOCK_PRODUCT";

// Types of product view
export const DOCUMENT_VIEW = "DOCUMENT_VIEW";
export const FILE_VIEW = "FILE_VIEW";
export const IMAGE_VIEW = "IMAGE_VIEW";
export const PRODUCT_LIST_VIEW = "PRODUCT_VIEW";

// Types of RFP fields
export const MULTI_CHECKBOX_FIELD = "MULTI_SELECT_CHECKBOXES";
export const MULTI_SELECT_NUMBER = "MULTI_SELECT_NUMBER";
export const MULTI_SELECT_WITH_QUANTITIES = "MULTI_SELECT_WITH_QUANTITIES";
export const NUMBER_FIELD = "NUMBER";
export const SELECT_FIELD_MANDATORY = "SINGLE_SELECT_DROP_DOWN_MANDATORY";

export const DASHBOARD_TYPES = {
  COMPANY: "COMPANY",
  SITE: "SITE",
  SITE_ROLE: "SITE_ROLE",
  SITE_USER: "SITE_USER",
  SYSTEM_DEFAULT: "SYSTEM_DEFAULT",
  USER: "USER",
};

export const MENU_TYPES = {
  MENU_GROUP: "MENU_GROUP",
  MENU_ITEM: "MENU_ITEM",
};

export const STRUCTURE_TYPES = {
  FOLDER: "USER_FOLDER",
  ISSUE: "ISSUE",
  PROJECT: "PROJECT",
  SITE: "SITE",
};

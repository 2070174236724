import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  skelWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  skeleton: {
    borderRadius: 2,
  },
}));

const TabHeadSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.skelWrap}>
      <Skeleton
        variant="rect"
        width="100%"
        height={48}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={48}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={48}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={48}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={48}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={48}
        animation="wave"
        classes={{ root: classes.skeleton }}
      />
    </Box>
  );
};

export default TabHeadSkeleton;

import React from "react";
import PropTypes from "prop-types";
import TermsOfUseLink from "./TermsOfUseLink";
import { TERMS_OF_USE_SECTION_ID } from "../../constant/termsOfUse";

const PrivacyPolicySectionLink = ({ children }) => {
  return (
    <TermsOfUseLink tabId={TERMS_OF_USE_SECTION_ID.PrivacyPolicy}>
      {children}
    </TermsOfUseLink>
  );
};

PrivacyPolicySectionLink.defaultProps = {
  children: null,
};

PrivacyPolicySectionLink.propTypes = {
  children: PropTypes.node,
};

export default PrivacyPolicySectionLink;

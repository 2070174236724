import { gql } from '@apollo/client';

const FolderFieldsFragment = gql`
  fragment FolderFields on FolderDTO {
    colorValue
    folderId
    name
    lockedByUsername
    lockedByAnotherUser
    userCanUpdateLock
    hasChildFolders
    hasContent
    locked
    parentFolderId
    read
    write
  }
`;

export default FolderFieldsFragment;

import { handleActions } from "redux-actions";
import {
  setSiteUsers,
  setSiteUsersLoading,
  setSiteUsersError,
  setSiteRoles,
  setSiteRolesLoading,
  setSiteRolesError,
} from "../../../../actions";

const defaultState = {
  items: [],
  loading: false,
  error: null,
  siteRoles: [],
  siteRolesLoading: false,
  siteRolesError: null,
};

export default handleActions(
  {
    [setSiteUsers]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        items,
      };
    },
    [setSiteUsersLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        loading,
      };
    },
    [setSiteUsersError]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        error,
      };
    },
    [setSiteRoles]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        siteRoles: items,
      };
    },
    [setSiteRolesLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        loading,
      };
    },
    [setSiteRolesError]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        error,
      };
    },
  },
  defaultState
);

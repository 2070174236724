import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';


const CopyToClipboardButton = ({ children, width }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(children);
  };

  return (
    <>
      <TextField
        sx={{ border: "none", width: width }}
        id="copy-textfield"
        defaultValue={children}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ minWidth: "24px", marginLeft: "2px" }}>
              <Button sx={{ color: "inherit", minWidth: "24px", marginLeft: "2px" }} size="small" onClick={handleClick} endIcon={<ContentCopyIcon />} />
            </InputAdornment>
          ),
          disableUnderline: true,
          readOnly: true,
        }}
        variant="standard"
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  )
}

export default CopyToClipboardButton;
import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headingWrap: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    marginRight: "auto",
  },
  text: {
    marginBottom: 10,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const ConvertInstructions = ({ selectImageOption }) => {
  const classes = useStyles();
  return (
    <>
      {selectImageOption && (
        <Box>
          <Box>
            <Box className={classes.headingWrap}>
              <Typography className={classes.heading}>
                2.{" "}
                <FormattedMessage id="imageBasket.selectAndCostumizeImages" />
              </Typography>
            </Box>
            <Typography className={classes.text}>
              <FormattedMessage id="imageBasket.selectAndCostumizeImagesOnTheLeft" />
            </Typography>
            <Typography className={classes.text}>
              <FormattedMessage id="imageBasket.selectImagesYouWantToCustomize" />
            </Typography>
          </Box>
          <Divider className={classes.divider} />
        </Box>
      )}
    </>
  );
};

ConvertInstructions.propTypes = {
  selectImageOption: PropTypes.bool.isRequired,
};

export default ConvertInstructions;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  fileButton: {
    justifyContent: "left",
    textAlign: "left",
    textTransform: "none",
  },
  labelHead: {
    fontSize: 10,
    marginBottom: 3,
    "& span": {
      color: theme.palette.component.productBoxColor,
      fontWeight: 600,
    },
  },
  dimmed: {
    opacity: "0.7",
  },
}));

const FileSectionButton = ({ description, dimmed, label, onClick, value }) => {
  const classes = useStyles();

  const handleButtonClick = async e => {
    await onClick({ e, value });
  };

  const renderDescription = () => {
    return (
      <>
        <br />
        {description}
      </>
    );
  };

  return (
    <Button
      className={clsx([classes.fileButton, dimmed ? classes.dimmed : ""])}
      fullWidth
      onClick={handleButtonClick}
    >
      <Typography className={classes.labelHead}>
        <span>{label}&nbsp;&raquo;</span>
        {description && renderDescription()}
      </Typography>
    </Button>
  );
};

FileSectionButton.defaultProps = {
  description: "",
  dimmed: false,
  label: "",
  onClick: () => {},
  value: "",
};

FileSectionButton.propTypes = {
  description: PropTypes.string.isRequired,
  dimmed: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default FileSectionButton;

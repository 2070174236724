import React from "react";
import PropTypes from "prop-types";
import { Box, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardSettings from "../WidgetConfiguration/DashboardSettings"
import WidgetGallery from "./WidgetGallery";
import WidgetConfiguration from "../WidgetConfiguration";
import SettingsLinkPanel from "./SettingsLinkPanel";

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    background: "rgba(0, 0, 0,0)",
    border: "none",
    top: 64,
    width: 470 /* widgetsDrawerWidth */,
    zIndex: 5,
    [theme.breakpoints.down("sm")]: {
      width: 340,
    },
  },
  contentWrapper: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.lightGrey}`,
    marginBottom: 50,
    minHeight: "calc(100vh - 130px)",
    overflow: "auto",
    paddingBottom: 50,
    [theme.breakpoints.down("sm")]: {
      width: 320,
    },
  },
}));

const WIDGET_PANELS = Object.freeze({
  WIDGET_CONFIGURATION_PANEL : 1,
  WIDGET_GALLERY : 2,
  SETTINGS_LINK_PANEL: 3,
  DASHBOARD_SETTINGS : 4,
  NONE : 0
});

const WidgetsDrawer = ({
  dashboardSettings,
  dirtyDashboard,
  dirtyWidgetConfiguration,
  handleCloseConfigure,
  intl,
  isOverview,
  layouts,
  onCancelWidgetConfiguration,
  onCloseDashboardSettings /* TODO: DashboardSettings */,
  onDashboardSettingsUpdated,
  onFinishEditing,
  onOpenDashboardSettings,
  onOpenManageDashboards,
  onSaveDashboard,
  onSaveDashboardSettings /* TODO: DashboardSettings */,
  onSaveWidgetConfiguration,
  onWidgetPropertyUpdate,
  openWidgetsDrawer,  
  widgetIndex,
  widgetDrawerPanel
}) => {
  const classes = useStyles();

  const handlePropertyUpdate = (property, value) => {
    onWidgetPropertyUpdate(widgetIndex, property, value);
  }

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      className={classes.drawer}
      open={openWidgetsDrawer}
      variant="persistent"
    >
      <Box className={classes.contentWrapper}>
        {widgetDrawerPanel == WIDGET_PANELS.DASHBOARD_SETTINGS && (
          <DashboardSettings
            initialSettings={dashboardSettings}
            isOverview={isOverview}
            onClose={onCloseDashboardSettings}
            onSave={onSaveDashboardSettings}
            onUpdated={onDashboardSettingsUpdated}
          />
        )}
        {widgetDrawerPanel == WIDGET_PANELS.WIDGET_GALLERY && (
          <WidgetGallery intl={intl} />
        )}
        {widgetDrawerPanel == WIDGET_PANELS.WIDGET_CONFIGURATION_PANEL && (

          <WidgetConfiguration
            dirtyWidgetConfiguration={dirtyWidgetConfiguration}
            handleCloseConfigure={handleCloseConfigure}
            isOverview={isOverview}
            onCancelWidgetConfiguration={onCancelWidgetConfiguration}
            onSaveWidgetConfiguration={onSaveWidgetConfiguration}
            onWidgetPropertyUpdate={handlePropertyUpdate}
            selectedWidget={layouts.lg[widgetIndex]}
          />
        )}        
        <SettingsLinkPanel
          dashboardSettings={dashboardSettings}
          disabled={
            widgetDrawerPanel != WIDGET_PANELS.SETTINGS_LINK_PANEL
          }
          enableSave={dirtyDashboard && !dirtyWidgetConfiguration}
          onClose={onFinishEditing}
          onOpenDashboardSettings={onOpenDashboardSettings}
          onOpenManageDashboards={onOpenManageDashboards}
          onSave={onSaveDashboard}
        />
      </Box>
    </Drawer>
  );
};

WidgetsDrawer.propTypes = {
  handleCloseConfigure: PropTypes.func.isRequired,
  //handleNewsSiteBackground: PropTypes.func.isRequired,
  onCancelWidgetConfiguration: PropTypes.func.isRequired,
  onSaveWidgetConfiguration: PropTypes.func.isRequired,
  onWidgetPropertyUpdate: PropTypes.func.isRequired,
};

export { WidgetsDrawer, WIDGET_PANELS };

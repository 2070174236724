import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";
import OrderGroup from "../../fragments/OrderGroup";

const ADD_BOOKABLE_PRODUCT = gql`
  mutation AddBookableProduct(
    $ordergroupId: Int!
    $item: OrderGroupBookingItemInput
  ) {
    shoppingCartContext {
      addBookableProduct(ordergroupId: $ordergroupId, item: $item) {
        errorResult {
          ...ErrorResultFields
        }
        success
        result {
          groups {
            ...OrderGroupFragment
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${OrderGroup}
`;

export default ADD_BOOKABLE_PRODUCT;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
//import clsx from "clsx";
//import { EditorState, convertToRaw, ContentState } from "draft-js";
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, Icon, IconButton } from "@mui/material";
//import htmlToDraft from "html-to-draftjs";
//import draftToHtml from "draftjs-to-html";
import MatildaDashboard from "../../pages/Dashboard/Matilda";
import BeataDashboard from "../../pages/Dashboard/Beata";
import SivDashboard from "../../pages/Dashboard/Siv";
import KlaraDashboard from "../../pages/Dashboard/Klara";
import DefaultDashboard from "../../pages/Dashboard/Default";
import * as sites from "../../constant/siteNames";
//import LatestHighlightedProductsForSite from "./LatestHighlightedProductsForSite";
//import MostDownloadedProductsForSite from "./MostDownloadedProductsForSite";

import { htmlContent } from "./helper";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  icon: {
    fontSize: 14,
  },
  iconButton: {
    position: "absolute",
    right: 20,
    top: -20,
  },
  editor: {
    padding: 10,
    border: "1px solid #F1F1F1",
    minHeight: 250,
  },
}));

const SiteDashboard = ({ selectedWorkspace, setOpenedNodes, setFolderId }) => {
  const classes = useStyles();
  //const [editMode, setEditMode] = useState(false);

  //const contentBlock = htmlToDraft(htmlContent);
  //const contentState = ContentState.createFromBlockArray(
  //  contentBlock.contentBlocks
  //);
 // const initialEditorValue = EditorState.createWithContent(contentState);
  //const [content, setContent] = useState(initialEditorValue);

  //const handleEditorChange = editorContent => {
  //  setContent(editorContent);
  //};

  //const rawContentState = convertToRaw(content.getCurrentContent());

  //const markup = draftToHtml(rawContentState);

  const renderSiteDashboard = () => {
    switch (selectedWorkspace.name) {
      case sites.BEATA:
        return (
          <BeataDashboard
            setOpenedNodes={setOpenedNodes}
            setFolderId={setFolderId}
          />
        );
      case sites.SIV:
        return <SivDashboard workspace={selectedWorkspace.name} />;
      case sites.KLARA:
        return <KlaraDashboard workspace={selectedWorkspace.name} />;
      case sites.MATILDA:
        return <MatildaDashboard workspace={selectedWorkspace.name} />;
      default:
        return <DefaultDashboard workspace={selectedWorkspace.name} />;
    }
  };

  return (
    <Box className={classes.root}>
      {/* commented content editor to be used in the future */}

      {/* {!editMode && (
        <IconButton
          onClick={() => setEditMode(true)}
          className={classes.iconButton}
        >
          <Icon className={clsx(["fas fa-cog", classes.icon])} />
        </IconButton>
      )} */}
      {/* {editMode && (
        <WelcomeTextEdtior
          content={content}
          onChange={handleEditorChange}
          editorClassName={classes.editor}
          onBlur={() => setEditMode(false)}
        />
      )}
      {!editMode && (
        <DashboardContainer>
          <div dangerouslySetInnerHTML={{ __html: markup }} />
        </DashboardContainer>
      )} */}
      {renderSiteDashboard()}
      {/*<LatestHighlightedProductsForSite />
      <MostDownloadedProductsForSite />*/}
    </Box>
  );
};

export default SiteDashboard;

SiteDashboard.propTypes = {
  setOpenedNodes: PropTypes.func.isRequired,
  setFolderId: PropTypes.func.isRequired,
  selectedWorkspace: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Grid, Box } from "@mui/material";
import CheckboxDefault from "../common/FormControl/Checkbox";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "800px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "20px 20px 15px 20px",
  },
  modalFooter: {
    marginTop: 20,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btnSave: {
    padding: "3px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
}));

const ShowColumnModal = ({ open, setOpen, showColumn, handleToggleColumn }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="context.showFields" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Box>
              <CheckboxDefault
                label="Ordernr"
                name="orderNo"
                value={showColumn.orderNo}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
            <Box>
              <CheckboxDefault
                label="Orderdatum"
                name="orderDate"
                value={showColumn.orderDate}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
            <Box>
              <CheckboxDefault
                label="Användare"
                name="user"
                value={showColumn.user}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
            <Box>
              <CheckboxDefault
                label="Avdelning"
                name="department"
                value={showColumn.department}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box>
              <CheckboxDefault
                label="Sektion"
                name="section"
                value={showColumn.section}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
            <Box>
              <CheckboxDefault
                label="Referens"
                name="reference"
                value={showColumn.reference}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
            <Box>
              <CheckboxDefault
                label="Postort"
                name="city"
                value={showColumn.city}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
            <Box>
              <CheckboxDefault
                label="Plockad av"
                name="pickedBy"
                value={showColumn.pickedBy}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box>
              <CheckboxDefault
                label="Markera"
                name="mark"
                value={showColumn.mark}
                onChange={e => handleToggleColumn(e)}
              />
            </Box>
            <Box>
              <CheckboxDefault label="Lev.address2" />
            </Box>
            <Box>
              <CheckboxDefault label="Lev.postnr" />
            </Box>
            <Box>
              <CheckboxDefault label="Ev.postort" />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box>
              <CheckboxDefault label="Artiklar" />
            </Box>
            <Box>
              <CheckboxDefault label="Totalvikt" />
            </Box>
            <Box>
              <CheckboxDefault label="Pris inkl. moms" />
            </Box>
            <Box>
              <CheckboxDefault label="Orderstatus" />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave} onClick={handleClose}>
            <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
            <FormattedMessage id="btn.close" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ShowColumnModal.defaultProps = {
  open: false,
  showColumn: {},
};

ShowColumnModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  showColumn: PropTypes.shape({
    orderNo: PropTypes.bool,
    orderDate: PropTypes.bool,
    section: PropTypes.bool,
    department: PropTypes.bool,
    user: PropTypes.bool,
    reference: PropTypes.bool,
    city: PropTypes.bool,
    pickedBy: PropTypes.bool,
    mark: PropTypes.bool,
  }),
  handleToggleColumn: PropTypes.func.isRequired,
};

export default ShowColumnModal;

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ExpansionDefault from "../../components/common/ExpansionDefault";
import ConnectedWorkspaces from "../../components/Suppliers/ConnectedWorkspaces";
import IncomingReuqests from "../../components/Suppliers/IncomingRequests";
import SupplierSettings from "../../components/Suppliers/SupplerSettings";
import { getAdminSelectedCompanyId } from "../../helpers/adminSelectors";
import { useGetCompanySupplierSettings } from "../../hooks";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "40px 100px 20px 100px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  side: {
    width: 300,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  middle: {
    margin: "0 20px",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      margin: 0,
      flex: "unset",
    },
  },
}));

const SupplierSettingsPage = () => {
  const classes = useStyles();
  const companyId = useSelector(getAdminSelectedCompanyId);

  const [currentCompanyId, setCurrentCompanyId] = useState(null);

  const supplierSettings = useSelector(state => state.api.supplierSettings);
  const { items, loading, error } = supplierSettings;
  const {
    active,
    formattedSupplierId,
    supplierVisibility,
    siteSupplierFor,
    supplierAliasRequests,
    incomingSupplierRequests,
  } = items;

  const {
    execute: getCompanySupplierSettings,
  } = useGetCompanySupplierSettings();

  const handleFetchCompanySupplierSettings = () => {
    getCompanySupplierSettings({ variables: { companyId } });
  };

  useEffect(() => {
    if (companyId !== currentCompanyId) {
      handleFetchCompanySupplierSettings();
      setCurrentCompanyId(companyId);
    }

    if (
      companyId === currentCompanyId &&
      Array.isArray(supplierSettings) &&
      supplierSettings.legnth === 0
    ) {
      handleFetchCompanySupplierSettings();
    }
  }, [companyId, currentCompanyId]);

  return (
    <Box className={classes.root}>
      <ExpansionDefault
        label={<FormattedMessage id="supplier.settings" />}
        panelContent="supplerSettingsContent"
        panelHeader="supplerSettingsHeader"
        defaultExpanded
      >
        <SupplierSettings
          active={active}
          supplierVisibility={supplierVisibility}
          supplierId={formattedSupplierId}
          loading={loading}
          supplierAlias={supplierAliasRequests}
          getCompanySupplierSettings={handleFetchCompanySupplierSettings}
          companyId={companyId}
        />
      </ExpansionDefault>

      <ExpansionDefault
        label={<FormattedMessage id="supplier.incomingRequest" />}
        panelContent="incomingRequestsContent"
        panelHeader="incomingRequestsHeader"
      >
        <IncomingReuqests
          loading={loading}
          incomingSupplierRequests={incomingSupplierRequests}
          getCompanySupplierSettings={handleFetchCompanySupplierSettings}
          companyId={companyId}
        />
      </ExpansionDefault>
      <ExpansionDefault
        label={<FormattedMessage id="supplier.connectedWorkspaces" />}
        panelContent="connectedWorkspacesContent"
        panelHeader="connectedWorkspaceHeader"
      >
        <ConnectedWorkspaces
          siteSupplierFor={siteSupplierFor}
          getCompanySupplierSettings={handleFetchCompanySupplierSettings}
        />
      </ExpansionDefault>
    </Box>
  );
};

export default SupplierSettingsPage;

import { Box, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react"
import { GrayButton, PrimaryButton } from "../../../common";
import KeyIcon from '@mui/icons-material/Key';
import { useMutation } from "@apollo/client";
import { REMOVE_AUTHENTICATOR } from "../../../../graphql/mutations";
import { useSnackbar } from "../../../../hooks";

const AuthenticatorRemoveModal = ({ open, onRemove, onCancel }) => {


  const [removeAuthenticator, { data, loading, error }] = useMutation(REMOVE_AUTHENTICATOR);

  const [password, setPassword] = useState("");
  const snackbar = useSnackbar();

  const handleCancel = () => {

    if (onCancel) {
      onCancel();
    }
  }

  const handleRemove = () => {
    removeAuthenticator({ variables: { password: password } });
  }

  useEffect(() => {

    if (data?.userAccountContext?.removeAuthenticator) {

      snackbar.success("Authentikator frånkopplad");

      if (onRemove) {
        onRemove();
      }
    } else if (!error && !loading && data?.userAccountContext) {
      snackbar.error("Authentikator ej bortkopplad");
    }        

  }, [data, loading, error])

  return (open && (<Dialog open={open}>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>För att koppla bort en authentikator måste du bekräfta din identitet genom att ange ditt lösenord.</Grid>
        <Grid item xs={12}>         
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <KeyIcon sx={{ mr: 1, my: 0.5 }} />
            <TextField label="Lösenord" type="password" onChange={e => setPassword(e.target.value)} />
          </Box>

          </Grid>
      </Grid>           

    </DialogContent>
    <DialogActions>
      <Box align="right">
        <GrayButton marginRight={8} disabled={ loading} onClick={n => handleCancel()}>Avbryt</GrayButton>
        <PrimaryButton onClick={n => handleRemove()} disabled={loading}>Radera</PrimaryButton>
      </Box>

    </DialogActions>

  </Dialog>))
}

export default AuthenticatorRemoveModal;
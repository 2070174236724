import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { isTokenValid } from "../services/jwtParser";
import { Timeout } from "../components/auth/timeout";

const BrowserContainer = ({ accessToken, children }) => {
  const [validToken, setValidToken] = useState(true);

  useEffect(() => {
    if (accessToken) {
      const valid = isTokenValid(accessToken);
      setValidToken(valid);
    }
  }, []);

  if (!validToken) {
    return <Timeout />;
  }

  return (
    <div className="App">
      <BrowserRouter basename="/">{children}</BrowserRouter>
    </div>
  );
};

BrowserContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default BrowserContainer;

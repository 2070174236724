import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Menu, Item } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import WorkspaceCreateModal from "../common/WorkspaceCreateModal";

const useStyles = makeStyles(theme => ({
  contextWrap: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    fontSize: 11,
    boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    lineHeight: "20px",
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "& .react-contexify__separator": {
      backgroundColor: "#fff !important",
      float: "none",
      margin: 0,
    },
  },
  contextSubMenu: {
    fontSize: 11,
    backgroundColor: theme.palette.primary.main,
    "& .react-contexify__submenu": {
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      boxShadow: "1px 2px 7px 1px rgba(0, 0, 0, 0.125)",
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
    "&:hover": {
      "& > .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__submenu-arrow": {
      fontSize: 8,
    },
  },
  contextItem: {
    fontSize: 11,
    "&:hover": {
      "& .react-contexify__item__content": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
    },
    "& .react-contexify__item__content": {
      color: "#fff !important",
    },
  },
}));

const ContextMenu = ({
  contextMenuId,
  creatingFolder,
  deletingFolder,
  renamingFolder,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Menu className={classes.contextWrap} id={contextMenuId}>
        {false && (
          <Item className={classes.contextItem} onClick={handleOpen}>
            <FormattedMessage id="workspace.share" />
          </Item>
        )}
        <Item className={classes.contextItem} onClick={creatingFolder}>
          <FormattedMessage id="workspace.addNewFolder" />
        </Item>
        <Item className={classes.contextItem} onClick={renamingFolder}>
          <FormattedMessage id="workspace.rename" />
        </Item>
        <Item className={classes.contextItem} onClick={deletingFolder}>
          <FormattedMessage id="workspace.delete" />
        </Item>
      </Menu>
      <WorkspaceCreateModal open={open} handleClose={handleClose} />
    </Box>
  );
};

ContextMenu.propTypes = {
  contextMenuId: PropTypes.string.isRequired,
  creatingFolder: PropTypes.func.isRequired,
  deletingFolder: PropTypes.func.isRequired,
  renamingFolder: PropTypes.func.isRequired,
};

export default ContextMenu;

import React from "react";
import { Route, useLocation } from "react-router-dom";
import { AuthConsumer } from "../providers/authProvider";
import DefaultView from "../components/layouts/DefaultView";
import { LoadingScreen } from "../components/common";

const PrivateRoute = ({ component, noConsentCheck = false, ...rest }) => {
  const location = useLocation();

  const renderFn = Component => props => (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (!!Component && isAuthenticated()) {
          return (
            <DefaultView noConsentCheck={noConsentCheck}>
              <Component {...props} />
            </DefaultView>
          );
        } else {
          signinRedirect();
          return <LoadingScreen />;
        }
      }}
    </AuthConsumer>
  );

  return (
    <Route exact {...rest} render={renderFn(component)} key={location.key} />
  );
};

export default PrivateRoute;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_STOCK_BALANCE = gql`
  mutation UpdateStock(
    $resourceId: ID!
    $value: Int!
    $reason: String
    $operation: StockEditOperation!
  ) {
    updateStockBalance(
      resourceId: $resourceId
      value: $value
      reason: $reason
      operation: $operation
    ) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_STOCK_BALANCE;

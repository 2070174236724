import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Paper, Box } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import ShowColumnModal from "../../components/PickingList/ShowColumnModal";
import PickingListTabs from "../../components/PickingList/PickingListTabs";
import PickingListHeader from "./PickingListHeader";
import { GET_PICKING_LIST_ORDERS } from "../../graphql/queries";
import {
  initialColumnValues,
  initialsearchValues,
} from "../../components/PickingList/helpers";
import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
  },
  paperBody: {
    padding: theme.spacing(2),
  },
}));

const PickingList = () => {
  const classes = useStyles();

  const [columnModal, setColumnModal] = useState(false);
  const [showColumn, setShowColumn] = useState(initialColumnValues);

  const orderGroupId = useSelector(state => getSelectedSiteOrderGroupId(state));
  const [searchValues, setSearchValues] = useState(initialsearchValues);
  const [page, setPage] = useState(0);
  const [pickingListOrders, setPickingListOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const {
    sortField,
    pickingListOrderStatus,
    pageSize,
    offset,
    sortDesc,
  } = searchValues;

  const handleToggleColumn = e => {
    const { name, checked } = e.target;
    setShowColumn({ ...showColumn, [name]: checked });
  };

  const [
    getPickingListOrders,
    { data: pickingListData, loading },
  ] = useLazyQuery(GET_PICKING_LIST_ORDERS);

  const fetchPickingListOrders = () => {
    const model = { ...searchValues, orderGroupId };
    getPickingListOrders({ variables: { model } });
  };

  useEffect(() => {
    fetchPickingListOrders();
  }, []);

  useEffect(() => {
    if (
      pickingListData &&
      pickingListData.pickingList &&
      pickingListData.pickingList.pickingListOrders &&
      pickingListData.pickingList.pickingListOrders.items
    ) {
      setPickingListOrders(pickingListData.pickingList.pickingListOrders.items);
      setTotalCount(pickingListData.pickingList.pickingListOrders.totalCount);
    }
  }, [pickingListData]);

  useEffect(() => {
    fetchPickingListOrders();
  }, [sortField, pickingListOrderStatus, pageSize, offset, sortDesc]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <PickingListHeader
          setColumnModal={setColumnModal}
          fetchPickingListOrders={fetchPickingListOrders}
        />
        <Box className={classes.paperBody}>
          <PickingListTabs
            showColumn={showColumn}
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            pickingListOrders={pickingListOrders}
            setPickingListOrders={setPickingListOrders}
            totalCount={totalCount}
            setTotalCount={setTotalCount}
            fetchPickingListOrders={fetchPickingListOrders}
            loading={loading}
            page={page}
            setPage={setPage}
          />
        </Box>
      </Paper>
      {columnModal && (
        <ShowColumnModal
          open={columnModal}
          setOpen={setColumnModal}
          showColumn={showColumn}
          handleToggleColumn={handleToggleColumn}
        />
      )}
    </div>
  );
};

export default PickingList;

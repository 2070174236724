import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import MonitoringModal from "../ProductsMainView/MonitoringModal";
import MonitoringTableItem from "./MonitoringTableItem";
import MonitoringTableHead from "./MonitoringTableHead";
import LoadingScreen from "../common/LoadingScreen";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
}));

const MonitoringTable = ({ monitoringItems, loading, refetchMonitoring }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedMonitoringItemId, setSelectedMonitoringItemId] = useState(
    null
  );
  const handleOpenModal = monitoringId => {
    setOpen(true);
    setSelectedMonitoringItemId(monitoringId);
  };

  const handleClose = () => {
    refetchMonitoring();
    setOpen(false);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className={classes.root}>
      <Table size="small">
        <MonitoringTableHead />
        {monitoringItems.map(item => {
          return (
            <MonitoringTableItem
              key={item.monitoringItemId}
              monitoringItem={item}
              handleOpenModal={handleOpenModal}
              refetchMonitoring={refetchMonitoring}
            />
          );
        })}
      </Table>
      {open && (
        <MonitoringModal
          open={open}
          handleClose={handleClose}
          monitoringItemId={selectedMonitoringItemId}
        />
      )}
    </div>
  );
};

MonitoringTable.defaultProps = {
  refetchMonitoring: () => {},
};

MonitoringTable.propTypes = {
  refetchMonitoring: PropTypes.func,
  monitoringItems: PropTypes.arrayOf(
    PropTypes.shape({
      designation: PropTypes.string,
      folderName: PropTypes.string,
      monitoringItemId: PropTypes.number.isRequired,
      monitoringTypes: PropTypes.arrayOf(PropTypes.string),
      notificationActions: PropTypes.arrayOf(PropTypes.string),
      notificationOccasion: PropTypes.string,
      numberOfMonitoredResources: PropTypes.number,
      numberOfRecipients: PropTypes.number,
      siteName: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MonitoringTable;

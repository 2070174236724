import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FileItemRow from "./FileItemRow";

const FilesSection = ({
  resources,
  productionSettings,
  handleCheckboxChange,
  selectedResources,
  folder,
  handleFileNotifyChange,
  propertyFormValues,
  onChangeQuantity,
  folderPropertyFormValues,
  handleRemoveFileSettings,
  selectedFoldersProperties,
  folderMandatoryFields,
  validateFolderProperties,
}) => {
  const { quantityProperty = {}, productionProperties = [] } =
    productionSettings || {};

  const folderFormValues = folderPropertyFormValues.find(
    x => x.folderId === folder.folderId
  );
  const { productionSettings: folderSettingsValues = {} } =
    folderFormValues || {};

  return (
    <>
      {resources.map(x => {
        const isSelected = selectedResources.some(y => y === x.id);

        const itemFormValue = propertyFormValues.find(
          z => z.resourceId === x.id
        );

        let { isIncluded, productionSettings: productionSettingsValues = {} } =
          itemFormValue || {};

        const { propertyDataValues = [], propertyValueIds = [] } =
          productionSettingsValues || {};

        const {
          isIncluded: folderIsIncluded = false,
          productionSettings: folderProductionSettings = {},
        } = folderFormValues || {};

        const {
          propertyDataValues: folderDataValues = [],
          propertyValueIds: folderPropertyValueIds = [],
        } = folderProductionSettings || {};

        // if (isIncluded === null || isIncluded === undefined) {
        //   const { isIncluded: folderIsIncluded = false } =
        //     folderFormValues || {};
        //   isIncluded = folderIsIncluded;
        // }

        if (isIncluded === null || isIncluded === undefined) {
          isIncluded = folderIsIncluded;
        }

        const { orderQuantity, quantityFixedValueId } =
          productionSettingsValues || {};

        let quantity;

        if (
          (quantityFixedValueId !== null &&
            quantityFixedValueId !== undefined) ||
          (orderQuantity !== null && orderQuantity !== undefined)
        ) {
          if (quantityProperty.quantityType === "FIXED") {
            quantity = quantityFixedValueId;
          } else {
            quantity = orderQuantity;
          }
        } else {
          const { productionSettings: folderSettingsValues = {} } =
            folderFormValues || {};

          const {
            orderQuantity: folderOrderQuantity,
            quantityFixedValueId: folderQuantityFixedValueId,
          } = folderSettingsValues || {};

          quantity =
            quantityProperty.quantityType === "FIXED"
              ? folderQuantityFixedValueId
              : folderOrderQuantity;
        }

        const { orderQuantity: folderOrderQuantity = 1 } =
          folderSettingsValues || {};

        return (
          <FileItemRow
            key={x.id}
            folderMandatoryFields={folderMandatoryFields}
            handleCheckboxChange={handleCheckboxChange}
            isSelected={isSelected}
            quantityProperty={quantityProperty}
            resource={x}
            folder={folder}
            quantity={quantity}
            productionProperties={productionProperties}
            handleFileNotifyChange={handleFileNotifyChange}
            isIncluded={isIncluded}
            onChangeQuantity={onChangeQuantity}
            folderOrderQuantity={folderOrderQuantity}
            resourceQuantity={orderQuantity}
            folderFormValues={folderFormValues}
            itemFormValue={itemFormValue}
            handleRemoveFileSettings={handleRemoveFileSettings}
            selectedFoldersProperties={selectedFoldersProperties}
          />
        );
      })}
    </>
  );
};

FilesSection.defaultProps = {
  resources: [],
  folderPropertyFormValues: [],
};

FilesSection.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired })
  ),
  handleCheckboxChange: PropTypes.func.isRequired,
  handleFileNotifyChange: PropTypes.func.isRequired,
  handleRemoveFileSettings: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  selectedResources: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ).isRequired,
  productionSettings: PropTypes.shape({
    quantityProperty: PropTypes.shape({}),
  }).isRequired,
  folder: PropTypes.shape({ folderId: PropTypes.number }).isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FilesSection;

import React from "react";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

const useStyles = makeStyles(theme => ({
  th: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
}));

const MySharesTableHead = ({
  disabled,
  withFromColumn,
  withRecipientsColumn,
}) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.th}>
          <TableSortLabel disabled={disabled}>
            <FormattedMessage id="myShares.tableHeader.Date" />
          </TableSortLabel>
        </TableCell>
        <TableCell className={classes.th}>
          <TableSortLabel disabled={disabled}>
            <FormattedMessage id="myShares.tableHeader.Description" />
          </TableSortLabel>
        </TableCell>
        {withFromColumn && (
          <TableCell className={classes.th}>
            <TableSortLabel disabled={disabled}>
              <FormattedMessage id="myShares.tableHeader.From" />
            </TableSortLabel>
          </TableCell>
        )}
        {withRecipientsColumn && (
          <TableCell className={classes.th}>
            <TableSortLabel disabled={disabled}>
              <FormattedMessage id="myShares.tableHeader.Recipients" />
            </TableSortLabel>
          </TableCell>
        )}
        <TableCell className={classes.th} />
      </TableRow>
    </TableHead>
  );
};

export default MySharesTableHead;

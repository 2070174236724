import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectWorkspace } from "../actions";
import PageRoute from "../constant/pageRouteDictionary.json";
import { getCurrentUserSecurityLevel, getSingleSite } from "../helpers/selectors";
import { useTermsOfUseContext } from "./useTermsOfUseContext";

// eslint-disable-next-line import/prefer-default-export
const useChangeCurrentWorkspace = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const termsOfUseContext = useTermsOfUseContext();

  const getSite = useSelector(getSingleSite);
  const userSecurityLevel = useSelector(getCurrentUserSecurityLevel);

  const changeCurrentWorkspace = (siteId, changePage = true) => {
    const navigationData = getNavigationData(Number(siteId));

    if (navigationData) {
      const { pageRoute, securityLevel, workspace } = navigationData;
      if (userSecurityLevel < securityLevel) {
        termsOfUseContext.openOptionalMfaDialog();
      }
      else {
        dispatch(selectWorkspace(workspace));
        if (changePage) {
          history.push(pageRoute);
        }
      }
    }
  };

  const getNavigationData = (siteId) => {
    let navigationData = null;

    const site = getSite(siteId);

    if (site) {
      navigationData = {
        pageRoute:
          PageRoute[site.defaultStartPage] || PageRoute.WORKSPACE_DASHBOARD,
        securityLevel:
          site.requiredSecurityLevel,
        workspace: {
          defaultViewType: site.defaultViewType,
          id: site.id,
          name: site.name,
          siteRoleId: site.siteRoleId,
          siteRoleRights: site.siteRoleRights,
        },
      };
    }

    return navigationData;
  };

  return changeCurrentWorkspace;
};

export default useChangeCurrentWorkspace;

import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { green, red } from "@mui/material/colors";
import { store } from "../../store";
import { currentViewer } from "../../actions";
import { getViewer } from "../../graphql/queries/viewer";
//import "../../../node_modules/three-dots/dist/three-dots.css";
import "../../initialize.css";

const useStyles = makeStyles(theme => ({
  initialize: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  progress: {
    margin: theme.spacing(2),
    marginTop: "10%",
  },
  success: {
    paddingTop: "10%",
    fontSize: 20,
    color: green[800],
  },
  error: {
    paddingTop: "10%",
    fontSize: 20,
    color: red[900],
  },
}));

const Initialize = props => {
  const classes = useStyles();
  const { data, error } = useQuery(getViewer);

  useEffect(() => {
    if (data && data.viewer) {
      const { viewer } = data;

      const redirectUri = !!localStorage.getItem("redirectUri")
        ? localStorage.getItem("redirectUri")
        : "/dashboard";

      localStorage.setItem("viewer", JSON.stringify(viewer));
      store.dispatch(currentViewer({ viewer }));
      props.history.push({
        pathname: "/loading",
        state: { theme: data.viewer.defaultTheme, redirectUri },
      });
    }
  }, [data, error]);

  if (error) {
    return (
      <div className={classes.initialize} align="center">
        <Typography className={classes.error}>
          There has been an error, please check!
        </Typography>
      </div>
    );
  }

  return null;
};

export default Initialize;

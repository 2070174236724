import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_SECURITY_SETTINGS } from "../../graphql/mutations/CompanyAdminContext";

// eslint-disable-next-line import/prefer-default-export
export const useUpdateCompanySecuritySettings = () => {
  const [updateSettings, { error, loading }] = useMutation(UPDATE_COMPANY_SECURITY_SETTINGS);

  const executeUpdateSettings = async ({ securitySettings, onError, onSuccess }) => {
    try {
      const { companyId, enabledMFATypes } = securitySettings;

      const res = await updateSettings({
        variables: {
          securitySettings: {
            companyId,
            enabledMFATypes,
          },
        },
      });

      const result = res?.data?.companyAdminContext?.settings?.updateCompanySecuritySettings;

      if (result?.success) {
        onSuccess?.({
          securitySettings: result.securitySettings
        });
      }
      else {
        onError?.();
      }
    } catch (err) {
      console.error("error >", err);
      onError?.();
    }
  };

  return [executeUpdateSettings, { error, loading }];
};

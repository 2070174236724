import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Icon } from "@mui/material";

const useStyles = makeStyles(() => ({
  status: {
    padding: 6,
    marginRight: 5,
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 11,
    },
  },
  statusOk: {
    backgroundColor: "#e7f7e0",
    "&:hover": {
      backgroundColor: "#e7f7e0",
    },
    "& .MuiIcon-root": {
      color: "#37910f",
    },
  },
  statusDanger: {
    padding: 4,
    backgroundColor: "#de514b",
    "&:hover": {
      backgroundColor: "#de514b",
    },
    "& .MuiIcon-root": {
      color: "#fff",
    },
  },
  statusWarning: {
    padding: "5px 9px",
    backgroundColor: "#ffe5a8",
    "&:hover": {
      backgroundColor: "#ffe5a8",
    },
    "& .MuiIcon-root": {
      color: "#da8f09",
    },
  },
  statusCountOk: {
    color: "#37910f",
    marginLeft: 5,
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 11,
      marginRight: 3,
    },
  },
}));

const OneStreamToolTip = ({ errorFiles, warningFiles, handleOpenStatus }) => {
  const classes = useStyles();

  const renderStatus = () => {
    if (errorFiles === 0 && warningFiles === 0) {
      return (
        <IconButton
          className={clsx([classes.status, classes.statusOk])}
          onClick={handleOpenStatus}
        >
          <Icon className="fa fa-check" />
        </IconButton>
      );
    }

    if (errorFiles > 0) {
      return (
        <IconButton
          className={clsx([classes.status, classes.statusDanger])}
          onClick={handleOpenStatus}
        >
          <Icon className="fa fa-exclamation-triangle" />
        </IconButton>
      );
    }

    if (warningFiles > 0) {
      return (
        <IconButton
          className={clsx([classes.status, classes.statusWarning])}
          onClick={handleOpenStatus}
        >
          <Icon className="fa fa-exclamation" />
        </IconButton>
      );
    }
  };

  return (
    <Tooltip title={<FormattedMessage id="onestream.report" />} placement="top">
      {renderStatus()}
    </Tooltip>
  );
};

OneStreamToolTip.defaultProps = {
  errorFiles: null,
  warningFiles: null,
};

OneStreamToolTip.propTypes = {
  errorFiles: PropTypes.number,
  warningFiles: PropTypes.number,
  handleOpenStatus: PropTypes.func.isRequired,
};

export default OneStreamToolTip;

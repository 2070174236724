import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Divider } from "@mui/material";
import HeaderOne from "../../../News/NewsHeaders/HeaderOne";
import NewsPost from "../../../News/NewsPost";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  head: {
    padding: "8px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 15,
    lineHeight: "normal",
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 15,
  },
  newsSub: {
    padding: 15,
    background: props => props.contentBG || theme.palette.background.paper,
  },
  divider: {
    // backgroundColor: theme.palette.text.secondary,
    background: props => props.dividerColor || "#dee2e6",
  },
  newLink: {
    fontSize: 11,
    color: theme.palette.primary.main,
    marginLeft: 15,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  plusIcon: {
    fontSize: 10,
    marginRight: 5,
    width: 12,
    height: 12,
    overflow: "unset",
    textAlign: "center",
  },
  headButton: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
  },
  newsSubItems: {
    borderLeft: props => `1px solid ${props.dividerColor || "#dee2e6"}`,
  },
}));

const TemplateOne = ({ customStyles, newsMain, newsSub, onClickReadMore }) => {
  const classes = useStyles(customStyles);
  return (
    <Box className={classes.body}>
      <HeaderOne customStyles={customStyles} news={newsMain} onClickReadMore={onClickReadMore}/>
      {newsMain && <Divider className={classes.divider} />}
      <Grid container className={classes.newsSub}>
        {newsSub.map(news => {
          return (
            <Grid
              className={classes.newsSubItems}
              item
              key={`news-post-${news.id}`}
              md={3}
              xs={12}
            >
              <NewsPost customStyles={customStyles} key={news.id} news={news} onClickReadMore={onClickReadMore} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TemplateOne;

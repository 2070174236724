import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import { ClearButton, PrimaryButton } from "../../common";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
}));

const NewFileVersionModal = ({
  open,
  handleClose,
  createNewFile,
  createNewVersion,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} />
      <DraggableModalBody>
        <FormattedMessage id="modal.createNewVersionNewFile" />
        <Box className={classes.modalFooter} align="right">
          <ClearButton onClick={() => createNewVersion()} marginRight={15}>
            <FormattedMessage id="btn.createNewVersion" />
          </ClearButton>
          <PrimaryButton
            onClick={() => {
              createNewFile();
            }}
          >
            <FormattedMessage id="btn.createNewFile" />
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

NewFileVersionModal.defaultProps = {};

NewFileVersionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  createNewFile: PropTypes.func.isRequired,
  createNewVersion: PropTypes.func.isRequired,
};

export default NewFileVersionModal;

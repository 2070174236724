import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  authorName: {
    fontSize: 12,
    fontWeight: 400,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  ingress: {
    paddingTop: "5px",
    fontSize: 12,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    height: "2em",
    marginTop: 5,
  },
  newsTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 5,
    marginBottom: 5,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  newsItemContainer: {
    marginTop: 0,
    marginBottom: 5,
    padding: "10px 10px 15px 10px",
    background: props => props.contentBG || theme.palette.background.paper,
  },
  titleContainer: {
    position: "relative",
  },
  date: {
    fontSize: 11,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  pageTitle: {
    fontSize: 16,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  joinNowContainer: {
    marginTop: 5,
  },
  joinNow: {
    fontSize: 10,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  leftTitleBar: {
    position: "absolute",
    left: -10,
    top: 0,
    height: 30,
    width: 2,
    //background: props => props.newsSiteFontColor || theme.palette.text.primary,
    background: "rgba(0, 0, 0, 0.04)",
  },
  arrowIcon: {
    fontSize: 12,
    marginLeft: 3,
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
  },
  workspace: {
    color: props => props.newsSiteFontColor || theme.palette.text.primary,
    fontSize: 10,
    width: "50%",
  },
  mainImg: {
    width: "100%",
    borderRadius: 0,
    paddingTop: "0px",
    height: "150px",
    objectFit: "cover",
    objectPosition: " 20% 10%",
  },
  clamped: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  },
  authorAndDateCell: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
  },
  avatarCell: {
    marginRight: 3,
    width: "fit-content",
  },
  avatar: {
    aspectRatio: 1,
    height: 24,
    marginTop: 2,
  },
  leadText: {
    marginTop: 2,
  },
}));

const NewsPostSkeleton = () => {
  const classes = useStyles();

  const animation = "pulse";

  return (
    <Box>
      <Grid container className={classes.newsItemContainer}>
        <Grid item xs={12}>
          <Skeleton
            variant="text"
            animation={animation}
            className={classes.workspace}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="text"
            animation={animation}
            className={classes.workspace}
          />
        </Grid>
        <Grid item xs={12} className={classes.titleContainer}>
          <Box className={classes.leftTitleBar} />
          <Skeleton
            variant="rect"
            animation={animation}
            className={classes.newsTitle}
          />
        </Grid>
        <Grid item xs={12} container>
          <div className={classes.avatarCell}>
            <Skeleton
              variant="circular"
              animation={animation}
              className={classes.avatar}
            />
          </div>
          <div className={classes.authorAndDateCell}>
            <Skeleton
              variant="text"
              animation={animation}
              className={classes.authorName}
            />
            <Skeleton
              variant="text"
              animation={animation}
              className={classes.date}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rect"
            animation={animation}
            className={classes.ingress}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsPostSkeleton;

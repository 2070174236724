import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  infoBox: {
    backgroundColor: "#f1f1f1",
    padding: "10px 15px",
    borderRadius: 3,
  },
  tipText: {
    fontSize: 12,
    marginBottom: 0,
  },
}));

const HelpUserTips = () => {
  const classes = useStyles();

  return (
    <Grid item md={9}>
      <Box className={classes.infoBox}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={1} align="center">
            <Icon className="nc-icon nc-bulb-63" />
          </Grid>
          <Grid item md={11}>
            <Typography className={classes.tipText}>
              Tips: <FormattedMessage id="siteUsers.tip" />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default HelpUserTips;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  addressTitle: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: 0,
  },
}));

const AddressHeaderTitle = ({ children }) => {
  const classes = useStyles();
  return <h5 className={classes.addressTitle}>{children}</h5>;
};

AddressHeaderTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AddressHeaderTitle;

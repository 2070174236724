import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { getCurrentUser } from "../../../helpers/selectors";
import {
  ADD_NEWS_COMMENT,
  UPDATE_COMMENT_LIKE,
} from "../../../graphql/mutations/NewsContext";
import { GET_NEWS_ITEM } from "../../../graphql/queries/NewsContext";
import CommentsSection from "./CommentsSection";

const CommentsContainer = ({ currentUser, siteNewsPostid }) => {
  const alert = useAlert();
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [addSiteComment, { loading: addSiteCommentLoading }] = useMutation(
    ADD_NEWS_COMMENT
  );

  const [updateCommentLike] = useMutation(UPDATE_COMMENT_LIKE);

  const { data, loading, error } = useQuery(GET_NEWS_ITEM, {
    variables: {
      siteNewsId: siteNewsPostid,
    },
    skip: !siteNewsPostid,
  });

  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      data.newsContext &&
      data.newsContext.getNewsItem &&
      data.newsContext.getNewsItem.comments
    ) {
      setComments(data.newsContext.getNewsItem.comments);
    }
    if (error || loading) {
      setComments([]);
    }
  }, [data, loading, error]);

  const handleCommentChange = e => {
    setCommentText(e.target.value);
  };

  const alertErrorAddingComment = () => {
    alert.error(<FormattedMessage id="newsPost.addCommentError" />);
  };

  const handleCommentLike = async ({ commentId, like }) => {
    try {
      const results = await updateCommentLike({
        variables: {
          commentId,
          like,
        },
      });
      if (
        results &&
        results.data &&
        results.data.newsContext &&
        results.data.newsContext.updateCommentLike
      ) {
        const updateComments = comments.map(x => {
          if (x.commentId === commentId)
            return {
              ...x,
              ...results.data.newsContext.updateCommentLike,
            };
          return x;
        });

        setComments([...updateComments]);
      } else {
        console.log("Error occurred on like/dislike");
      }
    } catch (e) {
      console.log("Error occurred on like/dislike");
    }
  };

  const handleAddSiteComment = async () => {
    if (!siteNewsPostid) {
      alertErrorAddingComment();
      return;
    }
    try {
      const results = await addSiteComment({
        variables: {
          siteNewsPostid,
          comment: commentText,
        },
      });
      if (
        results &&
        results.data &&
        results.data.newsContext &&
        results.data.newsContext.addSiteNewsComment
      ) {
        setComments([...comments, results.data.newsContext.addSiteNewsComment]);
        setCommentText("");
      } else {
        alertErrorAddingComment();
      }
    } catch (e) {
      alertErrorAddingComment();
    }
  };

  return (
    <CommentsSection
      currentUser={currentUser}
      handleAddSiteComment={handleAddSiteComment}
      loading={addSiteCommentLoading}
      handleCommentChange={handleCommentChange}
      commentText={commentText}
      comments={comments}
      addSiteCommentLoading={addSiteCommentLoading}
      numberOfComments={comments.length}
      handleCommentLike={handleCommentLike}
    />
  );
};

const mapStateToProps = state => {
  return {
    currentUser: getCurrentUser(state),
  };
};

CommentsContainer.propTypes = {
  siteNewsPostid: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps)(CommentsContainer);

import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, Paper, Stack, Typography } from "@mui/material";
import { UPDATE_PROJECT } from "../../../graphql/mutations/ProjectEditContext";
import { useSnackbar } from "../../../hooks";
import { ConfirmModal, GrayButton, TextField } from "../../common";
import { ColumnTypes, DateColumn, NumberColumn, StatusColumn, StringColumn, TimeReportColumn } from "./Columns";
import { DateEditColumn, NumberEditColumn, StatusEditColumn, StringEditColumn, TimeReportEditColumn } from "./EditColumns";
import StatusColumnDetails from "./StatusColumnDetails";

const ItemTypes = {
  COLUMN: 'COLUMN',
};

const DraggableColumn = ({ field, index, moveColumn, setSelectedField, selectedField }) => {

  const [, ref] = useDrag({
    type: ItemTypes.COLUMN,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveColumn(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const renderField = () => {
    const commonProps = { isActiveEdit: field.fieldId == selectedField?.fieldId, field, onClick: () => setSelectedField(field) };

    switch (field.type) {
      case ColumnTypes.STRING:
        return <StringEditColumn {...commonProps} />;
      case ColumnTypes.NUMBER:
        return <NumberEditColumn {...commonProps} />;
      case ColumnTypes.DATE:
        return <DateEditColumn {...commonProps} />;
      case ColumnTypes.STATUS:
        return <StatusEditColumn {...commonProps} />;
      case ColumnTypes.TIME_REPORT:
        return <TimeReportEditColumn {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <div ref={(node) => ref(drop(node))}>
      {renderField()}
    </div>
  );
};

const ColumnModal = ({ project, open, onSave, onClose }) => {
  const [internalProject, setInternalProject] = useState();
  const [selectedField, setSelectedField] = useState(null);
  const [deletedColumns, setDeletedColumns] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [nextFieldId, setNextFieldId] = useState(-1);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const snackbar = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    var temp = cloneDeep(project)
    temp.projectFields = createColumns(temp.projectFields);
    setInternalProject(temp);
  }, [project]);

  const createColumns = (projectFields) => {
    if (!Array.isArray(projectFields) || projectFields.length === 0) {
      return [];
    }

    return projectFields.map((projectField) => {
      const { name, jsonFieldSettings } = projectField;

      let field = { ...projectField };

      if (jsonFieldSettings) {
        try {
          const settings = JSON.parse(jsonFieldSettings);
          field = { ...field, settings };
        } catch (error) {
          console.error(`Error parsing settings for projectField: ${field}`, error);
        }
      }

      return field;
    });
  };

  const handleUpdate = () => {
    var temp = cloneDeep(internalProject);
    delete temp.__typename;
    delete temp.projectViews;

    temp.projectFields = temp.projectFields.map(n => {
      const updatedField = {
        id: Math.max(n.fieldId, 0),
        name: n.name,
        type: n.type,
        settings: JSON.stringify(n.settings)
      };
      return updatedField;
    });

    updateProject({ variables: { project: temp } })
      .then(response => {
        const { data } = response;
        if (data?.projects?.updateProject?.success) {
          snackbar.success(<FormattedMessage id="project.updated" />);

          onSave?.(data?.projects?.updateProject.project);
          onClose?.();
        } else {
          snackbar.error(<FormattedMessage id="project.errorSave" />);
        }
      })
      .catch(error => {
        snackbar.error(<FormattedMessage id="project.errorSave" />);

        console.error('Error updating project:', error);
      });
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleFieldNameChange = (e) => {
    const newName = e.target.value;
    setSelectedField((prevField) => ({ ...prevField, name: newName }));

    const temp = cloneDeep(internalProject);
    const fieldIndex = temp.projectFields.findIndex((field) => field.fieldId === selectedField.fieldId);
    if (fieldIndex !== -1) {
      temp.projectFields[fieldIndex].name = newName;
      setInternalProject(temp);
    }
  };

  const handleStatusFieldUpdate = (updatedField) => {
    const index = internalProject.projectFields.findIndex((field) => field.fieldId === updatedField.fieldId);
    const tempFields = [...internalProject.projectFields];
    tempFields[index] = updatedField;
    setInternalProject({ ...internalProject, projectFields: tempFields });
    setSelectedField(updatedField);
  };

  const moveColumn = (fromIndex, toIndex) => {
    const tempFields = cloneDeep(internalProject.projectFields);
    const [movedField] = tempFields.splice(fromIndex, 1);
    tempFields.splice(toIndex, 0, movedField);
    setInternalProject({ ...internalProject, projectFields: tempFields });
  };

  const handleSave = () => {
    if (deletedColumns.length > 0) {
      setShowConfirmDialog(true);
    } else {
      handleUpdate();
    }
  };

  const handleDelete = () => {
    if (selectedField.fieldId > 0) {
      setDeletedColumns([...deletedColumns, selectedField.fieldId]);
    }
    setInternalProject({
      ...internalProject,
      projectFields: internalProject.projectFields.filter(field => field.fieldId !== selectedField.fieldId)
    });
    setSelectedField(null);
  };

  const handleAdd = (type) => {
    let columnName = "";
    let settings = {};
    switch (type) {
      case ColumnTypes.STRING:
        columnName = intl.formatMessage({ id: "project.stringColumn" });
        break;
      case ColumnTypes.NUMBER:
        columnName = intl.formatMessage({ id: "project.numberColumn" });
        break;
      case ColumnTypes.DATE:
        columnName = intl.formatMessage({ id: "project.dateColumn" });;
        break;
      case ColumnTypes.STATUS:
        columnName = intl.formatMessage({ id: "project.statusColumn" });;
        settings = [];
        break;
      case ColumnTypes.TIME_REPORT:
        columnName = intl.formatMessage({ id: "project.timeReportColumn" });;
        break;
      default:
        break;
    }

    const temp = cloneDeep(internalProject);
    let newField = { name: columnName, type, fieldId: nextFieldId, settings: settings, jsonFieldSettings: JSON.stringify(settings) };

    temp.projectFields = [...temp.projectFields, newField];
    setNextFieldId(nextFieldId - 1);
    setInternalProject(temp);
  };

  const handleConfirmDelete = () => {
    setShowConfirmDialog(false);
    handleUpdate();
  };

  return internalProject && (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
        <DialogTitle>
          {!(internalProject?.projectId) ? (
            <FormattedMessage id="project.newproject" />
          ) : (
            <FormattedMessage id="project.editproject" />
          )}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>

            <Grid item xs={4}>
              <Paper elevation={3} sx={{ padding: 2, height: '100%', overflowY: 'auto' }}>
                <Typography variant="h6">
                  <FormattedMessage id="project.availableColumns" />
                </Typography>
                <List>
                  <StringColumn onClick={() => handleAdd(ColumnTypes.STRING)} />
                  <NumberColumn onClick={() => handleAdd(ColumnTypes.NUMBER)} />
                  <DateColumn onClick={() => handleAdd(ColumnTypes.DATE)} />
                  <StatusColumn onClick={() => handleAdd(ColumnTypes.STATUS)} />
                  <TimeReportColumn onClick={() => handleAdd(ColumnTypes.TIME_REPORT)} />
                </List>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <DndProvider backend={HTML5Backend}>
                <Paper elevation={3} sx={{ padding: 2, height: '100%', overflowY: 'auto' }}>
                  <Typography variant="h6"><FormattedMessage id="project.selectedColumns" /></Typography>
                  <List>
                    {internalProject.projectFields.map((field, index) => (
                      <DraggableColumn
                        key={field.fieldId}
                        index={index}
                        field={field}
                        moveColumn={moveColumn}
                        selectedField={selectedField}
                        setSelectedField={setSelectedField}
                      />
                    ))}
                  </List>
                </Paper>
              </DndProvider>
            </Grid>

            {selectedField && (
              <Grid item xs={4}>
                <Paper elevation={3} sx={{ padding: 2, height: '100%', overflowY: 'auto' }}>
                  <Typography variant="h6">
                    <FormattedMessage id="project.columnDetails" />
                  </Typography>
                  <Stack direction="row" sx={{ marginBottom: 2 }}>
                    <TextField label={intl.formatMessage({ id: "common.name" })} value={selectedField.name} onChange={handleFieldNameChange} />
                    <IconButton onClick={handleDelete} sx={{ marginRight: "4px" }} color="error" >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                  {selectedField.type == ColumnTypes.STATUS && (
                    <StatusColumnDetails field={selectedField} onChange={handleStatusFieldUpdate} />
                  )}
                </Paper>
              </Grid>
            )}

          </Grid>
        </DialogContent>

        <DialogActions>
          <Box align="right">

            <GrayButton marginRight={8} disabled={false} onClick={handleClose}>
              <FormattedMessage id="btn.cancel" />
            </GrayButton>

            {!!internalProject?.projectId && (
              <Button variant="contained" size="small" onClick={handleSave}>
                <FormattedMessage id="btn.save" />
              </Button>
            )}

          </Box>
        </DialogActions>
      </Dialog>

      <ConfirmModal
        messageText={<FormattedMessage id="project.confirmDeleteColumn.message" />}
        onCancel={() => setShowConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        onConfirmCloseModal={() => setShowConfirmDialog(false)}
        open={showConfirmDialog}
        titleText={<FormattedMessage id="project.confirmDeleteColumn.title" />}
      />
    </>
  );
};

export default ColumnModal;

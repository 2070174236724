import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,    
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ColorPicker from "../../common/ColorPicker";
import CustomSelect from "../../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  btnApprove: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginLeft: "0.5em",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.gray,
    },
  },
  btnCancel: {
    backgroundColor: theme.palette.common.lightGrey,
    color: theme.palette.primary.dark,
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: theme.palette.text.lightGray,
      opacity: 0.8,
    },
  },
  btnWrapper: {
    display: "flex",
    marginTop: 50,
  },
  closeBox: {
    position: "absolute",
    right: 10,
    top: 6,
  },
  colorPicker: {
    display: "flex",
    marginRight: 5,
    marginTop: 5,
  },
  colorWrapper: {
    alignItems: "center",
    display: "flex",
  },
  formDesc: {
    color: theme.palette.text.lightGray,
    fontSize: 12,
  },
  formTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  formWrapper: {
    marginTop: 8,
  },
  root: {
    marginBottom: 50,
    padding: "10px 20px",
    position: "relative",
  },
  textField: {
    fontSize: 14,
  },
  textFieldWrapper: {
    marginBottom: 8,
  },
}));

const createOwnerOptions = ({ intl, isOverview }) => {
  return isOverview
    ? [
        {
          label: intl.formatMessage({ id: "orderHistorySearch.user" }),
          value: "USER",
        },
        {
          label: intl.formatMessage({ id: "user.companyRole" }),
          value: "COMPANY_ROLE ",
        },
        {
          label: intl.formatMessage({ id: "cooperation.companyBestallare" }),
          value: "COMPANY",
        },
      ]
    : [
        {
          label: intl.formatMessage({ id: "orderHistorySearch.user" }),
          value: "USER",
        },
        {
          label: intl.formatMessage({ id: "sorting.siteRole" }),
          value: "WORKSPACE_ROLE ",
        },
        {
          label: intl.formatMessage({ id: "workspace.workspace" }),
          value: "WORKSPACE",
        },
      ];
};

const templateOptions = [
  { label: "Template A", value: "A" },
  { label: "Template B", value: "B" },
  { label: "Template C", value: "C" },
];

const DashboardSettings = ({
  initialSettings,
  isOverview,
  onClose,
  onSave,
  onUpdated,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const [currentSettings, setCurrentSettings] = useState({
    ...initialSettings,
  });
  const [isDirty, setIsDirty] = useState(false);
  const [ownerOptions, setOwnerOptions] = useState([]);

  useEffect(() => {
    var tempOptions = createOwnerOptions({ intl, isOverview });
    setOwnerOptions(tempOptions);
  }, [isOverview]);

  const actions = {
    approve: () => onSave({ isDirty, settings: { ...currentSettings } }),
    cancel: () => onClose({ isDirty: false }),
    close: () => onClose({ isDirty }),
    updated: () => {
      if (!isDirty) {
        setIsDirty(true);
        onUpdated();
      }
    },
  };

  const createLabel = id => intl.formatMessage({ id });

  const handleChange = event => {
    const { name, value } = event.target;
    updateCurrentSettings({ name, value });
  };

  const handleColorPickerChange = event => {
    updateCurrentSettings({ name: "background", value: event.hex });
  };

  const updateCurrentSettings = data => {
    const { name, value } = data;
    setCurrentSettings({
      ...currentSettings,
      [name]: value,
    });
    actions.updated();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.closeBox}>
        <IconButton onClick={actions.close} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography className={classes.formTitle} component="h5" variant="h5">
        <FormattedMessage id="widget.linkSetting" values={{ dashboardName: currentSettings.name || intl.formatMessage({ id: "widget.dashboard" }) }} />
      </Typography>
      <Typography className={classes.formDesc}>
        <FormattedMessage id="widget.formSettingDesc" />
      </Typography>
      <Box className={classes.formWrapper}>
        <Box className={classes.textFieldWrapper}>
          <TextField
            className={classes.textField}
            fullWidth
            label={createLabel("widget.formInputName")}
            margin="dense"
            name="name"
            onChange={e => handleChange(e)}
            required
            size="small"
            value={currentSettings.name}
            variant="outlined"
          />
        </Box>
        <Box className={classes.textFieldWrapper}>
          <TextField
            className={classes.textField}
            fullWidth
            label={createLabel("widget.formInputDesc")}
            multiline
            name="description"
            onChange={e => handleChange(e)}
            rows={5}
            value={currentSettings.description}
            variant="outlined"
          />
        </Box>
        <Box className={classes.textFieldWrapper}>
          <CustomSelect
            disabled
            inputLabel={createLabel("widget.dashboardTableHeadCellOwner")}
            name="owner"
            onChange={handleChange}
            options={ownerOptions}
            value={currentSettings.owner}
          />
        </Box>
        <Box className={classes.textFieldWrapper}>
          <CustomSelect
            disabled
            inputLabel={createLabel("widget.dashboardTableHeadCellTemplate")}
            name="template"
            onChange={handleChange}
            options={templateOptions}
            value={currentSettings.template}
          />
        </Box>
        <Box className={classes.textFieldWrapper}>
          <Box className={classes.colorWrapper}>
            <Box className={classes.colorPicker}>
              <ColorPicker
                onChange={handleColorPickerChange}
                value={currentSettings.background || "#f4f3ef"}
              />
            </Box>
            <FormattedMessage id="widget.dashboardColor" />
          </Box>
        </Box>
        <Box className={classes.btnWrapper}>
          <Button
            className={classes.btnCancel}
            onClick={actions.cancel}
            size="small"
          >
            <FormattedMessage id="btn.cancel" />
          </Button>
          <Button
            className={classes.btnApprove}
            disabled={!isDirty || currentSettings.name === ""}
            onClick={actions.approve}
            size="small"
          >
            <FormattedMessage id="btn.approve" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

DashboardSettings.defaultProps = {
  initialSettings: {},
};

DashboardSettings.propTypes = {
  initialSettings: PropTypes.shape({
    background: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    owner: PropTypes.string,
    template: PropTypes.string,
  }),
  isOverview: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default DashboardSettings;

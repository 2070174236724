import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  AddCircleOutline as IncrementIcon,
  RemoveCircleOutline as DecrementIcon,
} from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ColorPicker from "../../common/ColorPicker";
import OutlinedDiv from "./widgetsForm/OutlinedDiv";

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginBottom: 8,
  },
  colorWrapper: {
    display: "flex",
    alignItems: "center",
  },
  colorPicker: {
    width: "24px!important",
  },
  radiusWrapper: {
    padding: 8,
    width: "200px",
    marginTop: 8,
  },
  topRadius: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  bottomRadius: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },
  radiusLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  radiusRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
  },
  borderThickness: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  borderWrapper: {
    width: 18,
    minHeight: 18,
    border: `1px solid #bebebe`,
    marginRight: 14,
    borderRadius: 3,
    padding: 2,
    display: "flex",
    alignItems: "center",
  },
  borderPickerThickess: {
    width: "100%",
    borderTopStyle: "solid",
  },
}));

const IncrementButton = ({ onClick }) => (
  <IconButton size="small" onClick={onClick}>
    <IncrementIcon />
  </IconButton>
);

const DecrementButton = ({ onClick }) => (
  <IconButton size="small" onClick={onClick}>
    <DecrementIcon />
  </IconButton>
);

const getSettingsFromLayout = layout => {
  const {
    actionBarBG,
    bgColor,
    borderColor,
    borderRadiusBottomLeft,
    borderRadiusBottomRight,
    borderRadiusTopLeft,
    borderRadiusTopRight,
    borderWidth,
    fontColor,
    showTitleBar,
    type,
  } = layout || {};

  const defaultColor = "#e8e8e8";

  return {
    actionBarBG: actionBarBG || defaultColor,
    bgColor: bgColor || defaultColor,
    borderColor: borderColor || defaultColor,
    borderRadiusBottomLeft,
    borderRadiusBottomRight,
    borderRadiusTopLeft,
    borderRadiusTopRight,
    borderWidth,
    fontColor: fontColor || defaultColor,
    showTitleBar,
    type,
  };
};

const CommonSettings = ({
  onWidgetPropertyUpdate,    
  selectedWidget,
}) => {
  const settings = getSettingsFromLayout(selectedWidget);

  const classes = useStyles();
  const intl = useIntl();

  const ColorSelector = ({ messageId, property, value }) => (
    <Box className={classes.formWrapper}>
      <Box className={classes.colorWrapper}>
        <Box className={classes.colorPicker}>
          <ColorPicker
            onChange={(e) => onWidgetPropertyUpdate(property, e.hex)}
            value={value}
          />
        </Box>
        <FormattedMessage id={messageId} />
      </Box>
    </Box>
  );

  return (
    <>
      <Box className={classes.formWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.showTitleBar}
              color="primary"
              name="checkedB"
              onChange={e => onWidgetPropertyUpdate("showTitleBar",e.target.checked)}
              size="medium"
            />
          }
          label={intl.formatMessage({ id: "widget.titleBar" })}
        />
      </Box>

      <ColorSelector
        messageId={"widget.titleBarBGColor"}
        property={"actionBarBG"}
        value={settings.actionBarBG} />

      <ColorSelector
        messageId={"widget.border"}
        property={"borderColor"}
        value={settings.borderColor} />

      <ColorSelector
        messageId={"widget.dashboardColor"}
        property={"bgColor"}
        value={settings.bgColor} />

      <ColorSelector
        messageId={"widget.fontColor"}
        property={"fontColor"}
        value={settings.fontColor} />

      <Box className={classes.formWrapper}>
        <Box className={classes.borderThickness}>
          <Box className={classes.borderWrapper}>
            <Box
              className={classes.borderPickerThickess}
              style={{
                borderTopWidth: `${settings.borderWidth}px`,
                borderColor: settings.borderColor,
              }}
            />
          </Box>
          <Box>
            <FormattedMessage id="widget.borderThickness" />
          </Box>
          <Box>
            <DecrementButton onClick={() => onWidgetPropertyUpdate("borderWidth", "subtract")} />
          </Box>
          <Box>{settings.borderWidth}</Box>
          <Box>
            <IncrementButton onClick={() => onWidgetPropertyUpdate("borderWidth","add")} />
          </Box>
        </Box>
      </Box>

      {settings.type !== "video" && (
        <>
          <Box className="spacer" />
          <Box className={classes.formWrapper}>
            <OutlinedDiv
              label={intl.formatMessage({ id: "widget.borderRadius" })}
            >
              <Box className={classes.radiusWrapper}>
                <Box className={classes.topRadius}>
                  <Box className={classes.radiusLeft}>
                    <Box>
                      <DecrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius","topLeftMinus")}
                      />
                    </Box>
                    <Box>{settings.borderRadiusTopLeft}</Box>
                    <Box>
                      <IncrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius", "topLeftAdd")}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.radiusRight}>
                    <Box>
                      <DecrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius", "topRightMinus")}
                      />
                    </Box>
                    <Box>{settings.borderRadiusTopRight}</Box>
                    <Box>
                      <IncrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius", "topRightAdd")}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.bottomRadius}>
                  <Box className={classes.radiusLeft}>
                    <Box>
                      <DecrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius", "bottomLeftMinus")}
                      />
                    </Box>
                    <Box>{settings.borderRadiusBottomLeft}</Box>
                    <Box>
                      <IncrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius", "bottomLeftAdd")}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.radiusRight}>
                    <Box>
                      <DecrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius", "bottomRightMinus")}
                      />
                    </Box>
                    <Box>{settings.borderRadiusBottomRight}</Box>
                    <Box>
                      <IncrementButton
                        onClick={() => onWidgetPropertyUpdate("borderRadius", "bottomRightAdd")}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </OutlinedDiv>
          </Box>
          <Box className="spacer" />
        </>
      )}
    </>
  );
};

export default CommonSettings;

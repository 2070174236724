import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import CommentIcon from "../../../assets/img/comment-icon.svg";

const useStyles = makeStyles(() => ({
  commentTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 15,
  },
  commentIcon: {
    width: 30,
  },
}));
const SectionHeader = ({ numberOfComments }) => {
  const classes = useStyles();

  const getCommentText = numberOfComments => {
    if (numberOfComments > 1) return "Comments";
    return "Comment";
  };

  return (
    <>
      <img src={CommentIcon} alt="Profile" className={classes.commentIcon} />
      <Typography className={classes.commentTitle}>
        {numberOfComments} {getCommentText(numberOfComments)}
      </Typography>
    </>
  );
};

SectionHeader.defaultProps = {
  numberOfComments: 0,
};

SectionHeader.propTypes = {
  numberOfComments: PropTypes.number,
};

export default SectionHeader;

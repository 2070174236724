import { useSelector } from "react-redux";
import UserCellValue from "./UserCellValue";

const CurrentUserCellValue = () => {
  const { avatar, firstname, lastname, username } = useSelector(state => state.api.currentViewer.viewer);

  return (
    <UserCellValue
      disabled
      user={{ avatar, firstname, lastname, username }}
    />
  );
};

export default CurrentUserCellValue;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { UPDATE_COMPANY_SETTINGS } from "../../../../graphql/mutations/CompanyAdminContext";
import { GET_FOLDERS } from "../../../../graphql/queries";
import {
  COMPANY_SETTINGS,
  LIST_WORKSPACES,
} from "../../../../graphql/queries/CompanyAdminContext";
import { PrimaryButton } from "../../../common/Buttons";
import CustomTextField from "../../../common/TextField";
import WorkspaceSelector from "../../../common/WorkspaceSelector";
import FolderSelector from "./FolderSelector";

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  actionButtons: {
    justifyContent: "flex-end",
    "& > div": {
      border: "none",
      width: "unset",
      marginTop: "-2px",
      padding: "0px",
      "& button": {
        height: "26px",
      },
    },
    "& :only-child": {
      marginLeft: "auto",
    },
    width: "100%",
    padding: "16px",
  },
  btnDesign: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headMarginBottom: {
    marginBottom: "10px",
  },
  body: {
    padding: 20,
  },
  card: {
    padding: 15,
    borderRadius: 4,
    border: "1px solid #F2F2F2",
    marginBottom: 15,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginRight: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  textItalic: {
    fontSize: 12,
    fontStyle: "italic",
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  textFields: {
    marginTop: 15,
    position: "relative",
  },
  greenLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  greenIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  gridContainer: {
    padding: 25,
  },
  selectBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
    "& .MuiFormControl-root": {
      width: "60%",
    },
  },
  loading: {
    padding: "15px 20px",
  },
  logo: {
    background: theme.palette.common.white,
    borderColor: theme.palette.common.lightGrey,
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 1,
    marginBottom: 10,
    maxWidth: 160,
    padding: 3,
    "&:hover": {
      animation: `$bgColorChange 1s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  logoCard: {
    background: theme.palette.secondary.main,
    height: 140,
    padding: "10px",
    "&:hover": {
      animation: `$bgColorChange 1.2s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  logoMail: {
    background: theme.palette.common.white,
    height: 140,
    padding: "10px",
    "&:hover": {
      animation: `$bgColorChangeMail 1.2s linear`,
      backgroundColor: theme.palette.common.black,
    },
  },
  selectCard: {
    minHeight: 400,
    padding: "10px",
  },
  "@keyframes bgColorChange": {
    "0%": {
      backgroundColor: theme.palette.secondary.main,
    },
    "50%": {
      backgroundColor: theme.palette.common.black,
    },
    "100%": {
      backgroundColor: theme.palette.common.black,
    },
  },
  "@keyframes bgColorChangeMail": {
    "0%": {
      backgroundColor: theme.palette.common.white,
    },
    "50%": {
      backgroundColor: theme.palette.common.black,
    },
    "100%": {
      backgroundColor: theme.palette.common.black,
    },
  },
}));

//const useStyles = makeStyles(theme => ({
//  root: {
//    backgroundColor: theme.palette.common.white,
//    border: "1px solid #EEE",
//    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
//    borderRadius: 10,
//  },
//  head: {
//    padding: "15px 20px",
//    borderBottom: "2px solid #F5F5F5",
//    display: "flex",
//    alignItems: "center",
//    fontWeight: 600,
//  },
//  headTitle: {
//    fontSize: 16,
//    fontWeight: 600,
//    marginRight: 20,
//  },
//}));

const CompanyDataSettings = () => {
  const alert = useAlert();
  const classes = useStyles();
  const intl = useIntl();

  const { companyId: _targetCompanyId } = useSelector(
    state => state.api.companyAdmin.company
  );

  const [updateCompanySettings] = useMutation(UPDATE_COMPANY_SETTINGS);

  const [
    fetchCompanySettings,
    {
      data: companySettingsData,
      loading: loadingCompanySettings,
      error: errorLoadingCompanySettings,
    },
  ] = useLazyQuery(COMPANY_SETTINGS, {
    variables: {
      companyId: _targetCompanyId,
    },
  });

  const [
    listCompanyWorkspaces,
    {
      data: companyWorkspacesData,
      loading: loadingCompanyWorkspaces,
      error: errorLoadingCompanyWorkspaces,
    },
  ] = useLazyQuery(LIST_WORKSPACES, {
    variables: {
      companyId: _targetCompanyId,
    },
  });

  const [
    listWorkspaceFolders,
    { loading: loadingWorkspaceFolders, error: errorLoadingWorkspaceFolders },
  ] = useLazyQuery(GET_FOLDERS, {
    errorPolicy: "none",
    onError: error => {
      console.error("** ERROR: Failed to list folders.", error);
      closeFolderSelectors();
      setWorkspaceFolderSelectorsDisabled(true);
      setWorkspaceFolders([]);
      setWorkspaceFoldersInvalid(true);
      setWorkspaceFoldersSiteId(0);
    },
    onCompleted: data => {
      const { folders } = data;
      setWorkspaceFolders(folders ?? []);
      setWorkspaceBackupFolders(folders ?? []);
      setWorkspaceFoldersInvalid(false);
    },
  });

  const [openedNodes, setOpenedNodes] = useState([]);

  const [workspaceName, setWorkspaceName] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceSelectorOpen, setWorkspaceSelectorOpen] = useState(false);

  const [workspaceFolders, setWorkspaceFolders] = useState([]);
  const [workspaceFoldersBackup, setWorkspaceBackupFolders] = useState([]);

  const [
    workspaceFolderSelectorsDisabled,
    setWorkspaceFolderSelectorsDisabled,
  ] = useState(true);

  const [workspaceFoldersInvalid, setWorkspaceFoldersInvalid] = useState(true);
  const [workspaceFoldersSiteId, setWorkspaceFoldersSiteId] = useState(0);

  const [showCancel, setShowCancel] = useState(false);

  const [avatarFolder, setAvatarFolder] = useState({});
  const [avatarFolderModalOpen, setAvatarFolderModalOpen] = useState(false);

  const [siteTemplateFolder, setSiteTemplateFolder] = useState({});
  const [siteTemplateModalOpen, setSiteTemplateModalOpen] = useState(false);

  const [messageAttachmentsFolder, setMessageAttachmentsFolder] = useState({});
  const [
    messageAttachmentsFolderModalOpen,
    setMessageAttachmentsFolderModalOpen,
  ] = useState(false);

  const [newsFolder, setNewsFolder] = useState({});
  const [newsFolderModalOpen, setNewsFolderModalOpen] = useState(false);

  useEffect(() => {
    if (workspaceFoldersBackup.length > 0) {
      setWorkspaceFolders(workspaceFoldersBackup);
    }
  }, [workspaceFoldersBackup]);

  useEffect(() => {
    if (_targetCompanyId) {
      fetchCompanySettings();
      listCompanyWorkspaces();
    }
  }, [_targetCompanyId]);

  useEffect(() => {
    if (
      companySettingsData &&
      companySettingsData.companyAdminContext &&
      companySettingsData.companyAdminContext.settings
    ) {
      const {
        messageAttachmentsFolder,
        avatarFolder,
        newsFolder,
        siteTemplateFolder,
      } = companySettingsData.companyAdminContext.settings;

      setAvatarFolder(avatarFolder);
      setNewsFolder(newsFolder);
      setMessageAttachmentsFolder(messageAttachmentsFolder);
      setSiteTemplateFolder(siteTemplateFolder);

      var siteId = [
        avatarFolder,
        newsFolder,
        messageAttachmentsFolder,
        siteTemplateFolder,
      ]
        .map(n => n?.siteId ?? 0)
        .find(n => n > 0);
      setWorkspaceFoldersSiteId(siteId);
    }
  }, [companySettingsData]);

  useEffect(() => {
    if (workspaceFoldersSiteId > 0 && workspaces && workspaces.length > 0) {
      var site = workspaces.find(n => n.siteId === workspaceFoldersSiteId);
      setWorkspaceName(site.name);
      setWorkspaceSelectorOpen(false);
      setWorkspaceFolderSelectorsDisabled(false);
    }
  }, [workspaceFoldersSiteId, workspaces]);

  useEffect(() => {
    if (
      companyWorkspacesData &&
      companyWorkspacesData.companyAdminContext &&
      companyWorkspacesData.companyAdminContext.companyWorkspaces &&
      companyWorkspacesData.companyAdminContext.companyWorkspaces.listWorkspaces
    ) {
      const {
        success,
        items,
      } = companyWorkspacesData.companyAdminContext.companyWorkspaces.listWorkspaces;
      if (success) {
        var companyWorkspaces = items.map((item, idx) => {
          return {
            icon: item.icon,
            iconColor: item.iconColor,
            id: idx,
            name: item.name,
            siteId: item.siteId,
            type: "SITE",
          };
        });
        setWorkspaces(companyWorkspaces);
        setWorkspaceFolders([]);
        setWorkspaceFoldersInvalid(true);
      } else {
        handleWorkspacesLoadingError();
      }
    }
  }, [companyWorkspacesData]);

  useEffect(() => {
    if (errorLoadingCompanyWorkspaces) {
      handleWorkspacesLoadingError();
    }
  }, [errorLoadingCompanyWorkspaces]);

  useEffect(() => {
    if (errorLoadingWorkspaceFolders) {
      console.error("** ERROR: Failed to list folders.");
      alertError();
    }
  }, [errorLoadingWorkspaceFolders]);

  const alertError = () => {
    alert.error(<FormattedMessage id="common.genericErrorMessage" />);
  };

  const alertSuccess = () => {
    alert.success(<FormattedMessage id="common.genericSuccess" />);
  };

  const closeFolderSelectors = () => {
    setAvatarFolderModalOpen(false);
    setMessageAttachmentsFolderModalOpen(false);
    setNewsFolderModalOpen(false);
    setSiteTemplateModalOpen(false);
  };

  const fetchWorkspaceFolders = () => {
    if (workspaceFoldersInvalid) {
      listWorkspaceFolders({
        variables: {
          siteId: workspaceFoldersSiteId,
        },
      });
    }
  };

  const getFolderName = folder => {
    return folder ? folder.name || folder.folderName : "";
  };

  const handleWorkspaceSelected = value => {
    setShowCancel(true);
    setWorkspaceFoldersSiteId(value.siteId);
    setWorkspaceName(value.name);
    setWorkspaceSelectorOpen(false);
    setWorkspaceFolderSelectorsDisabled(false);
    setWorkspaceFoldersInvalid(true);
    setAvatarFolder(null);
    setNewsFolder(null);
    setMessageAttachmentsFolder(null);
    setSiteTemplateFolder(null);
  };

  const handleWorkspacesLoadingError = () => {
    console.error("** ERROR: Failed to list workspaces.");
    alertError();
    setWorkspaces([]);
  };

  const handleSiteTemplateFolderSelected = (e, folder) => {
    e.stopPropagation();
    setShowCancel(true);
    setSiteTemplateFolder(folder);
    closeFolderSelectors();
  };

  const handleAvatarFolderSelected = (e, folder) => {
    e.stopPropagation();
    setShowCancel(true);
    setAvatarFolder(folder);
    closeFolderSelectors();
  };

  const handleMessageAttachmentFolderSelected = (e, folder) => {
    e.stopPropagation();
    setShowCancel(true);
    setMessageAttachmentsFolder(folder);
    closeFolderSelectors();
  };

  const handleNewsFolderSelected = (e, folder) => {
    e.stopPropagation();
    setShowCancel(true);
    setNewsFolder(folder);
    closeFolderSelectors();
  };

  const handleCancel = () => {
    setShowCancel(false);

    const {
      messageAttachmentsFolder,
      avatarFolder,
      newsFolder,
      siteTemplateFolder,
    } = companySettingsData.companyAdminContext.settings;

    setAvatarFolder(avatarFolder);
    setNewsFolder(newsFolder);
    setMessageAttachmentsFolder(messageAttachmentsFolder);
    setSiteTemplateFolder(siteTemplateFolder);

    var siteId = [
      avatarFolder,
      newsFolder,
      messageAttachmentsFolder,
      siteTemplateFolder,
    ]
      .map(n => n?.siteId ?? 0)
      .find(n => n > 0);
    setWorkspaceFoldersSiteId(siteId);
  };

  const handleSaveSettings = async () => {
    try {
      const res = await updateCompanySettings({
        variables: {
          companyId: _targetCompanyId,
          //defaultTheme: null,
          avatarFolderId: avatarFolder?.folderId,
          newsImageFolderId: newsFolder?.folderId,
          messageAttachmentsFolderId: messageAttachmentsFolder?.folderId,
          siteTemplateFolderId: siteTemplateFolder?.folderId,
        },
      });
      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.settings &&
        res.data.companyAdminContext.settings.updateCompanySettings
      ) {
        alertSuccess();
        setShowCancel(false);
      }
    } catch (err) {
      console.error("** ERROR: Failed to save settings.", err);
      alertError();
    }
  };

  const openMessageAttachmentModal = () => {
    closeFolderSelectors();
    setMessageAttachmentsFolderModalOpen(true);
    fetchWorkspaceFolders();
  };

  const openAvatarFolderModal = () => {
    closeFolderSelectors();
    setAvatarFolderModalOpen(true);
    fetchWorkspaceFolders();
  };

  const openNewsFolderModal = () => {
    closeFolderSelectors();
    setNewsFolderModalOpen(true);
    fetchWorkspaceFolders();
  };

  const openSiteTemplateFolderModal = () => {
    closeFolderSelectors();
    setSiteTemplateModalOpen(true);
    fetchWorkspaceFolders();
  };

  const toggleWorkspaceSelector = () => {
    setWorkspaceSelectorOpen(prevState => !prevState);
  };

  return (
     <>
        <Box className={classes.headMarginBottom}>
          <Typography className={classes.headTitle}><FormattedMessage id="admin.companySettings.companyData.Title" /></Typography>
          <Typography className={classes.headSubTitle}><FormattedMessage id="admin.companySettings.companyData.subTitle" /></Typography>
        </Box>
        <Card>
          <CardContent className={classes.selectCard}>
            <Box>
              {((!_targetCompanyId && !errorLoadingCompanySettings) || loadingCompanySettings) && (
              <Grid container spacing={1} className={classes.gridContainer} justifyContent="center">
                <CircularProgress />
              </Grid>
              )}
              {errorLoadingCompanySettings && (
                <Grid container spacing={1} className={classes.gridContainer}>
                  <FormattedMessage id="common.genericErrorMessage" />
                </Grid>
              )}
          {_targetCompanyId &&
            !loadingCompanySettings &&
            !errorLoadingCompanySettings && (
              <Grid container spacing={1} xs={12} md={12} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Box>
                    <WorkspaceSelector
                      handleToggle={toggleWorkspaceSelector}
                      onClick={handleWorkspaceSelected}
                      open={workspaceSelectorOpen}
                      searchable
                      setOpen={setWorkspaceSelectorOpen}
                      workspaceName={workspaceName}
                      workspaces={workspaces}
                    />
                  </Box>
                  <Box className={classes.textFields}>
                    <Typography>
                      <FormattedMessage id="userData.selectFolderUserData" />
                    </Typography>
                    <CustomTextField
                      disabled={workspaceFolderSelectorsDisabled}
                      onClick={openNewsFolderModal}
                      value={getFolderName(newsFolder)}
                    />
                    <FolderSelector
                      folders={workspaceFolders}
                      loadingFolders={loadingWorkspaceFolders}
                      onClose={closeFolderSelectors}
                      onFolderSelected={handleNewsFolderSelected}
                      open={newsFolderModalOpen}
                      openedNodes={openedNodes}
                      setOpenedNodes={setOpenedNodes}
                    />
                  </Box>
                  <Box className={classes.textFields}>
                    <Typography>
                      <FormattedMessage id="userData.selectFolderMessageAttachment" />
                    </Typography>
                    <CustomTextField
                      disabled={workspaceFolderSelectorsDisabled}
                      onClick={openMessageAttachmentModal}
                      value={getFolderName(messageAttachmentsFolder)}
                    />
                    <FolderSelector
                      folders={workspaceFolders}
                      loadingFolders={loadingWorkspaceFolders}
                      onClose={closeFolderSelectors}
                      onFolderSelected={handleMessageAttachmentFolderSelected}
                      open={messageAttachmentsFolderModalOpen}
                      openedNodes={openedNodes}
                      setOpenedNodes={setOpenedNodes}
                    />
                  </Box>
                  <Box className={classes.textFields}>
                    <Typography>
                      <FormattedMessage id="userData.selectFolderAvatar" />
                    </Typography>
                    <CustomTextField
                      disabled={workspaceFolderSelectorsDisabled}
                      onClick={openAvatarFolderModal}
                      value={getFolderName(avatarFolder)}
                    />
                    <FolderSelector
                      folders={workspaceFolders}
                      loadingFolders={loadingWorkspaceFolders}
                      onClose={closeFolderSelectors}
                      onFolderSelected={handleAvatarFolderSelected}
                      open={avatarFolderModalOpen}
                      openedNodes={openedNodes}
                      setOpenedNodes={setOpenedNodes}
                    />
                  </Box>
                  <Box className={classes.textFields}>
                    <Typography>
                      <FormattedMessage id="admin.workspaceTemplates" />
                    </Typography>
                    <CustomTextField
                      disabled={workspaceFolderSelectorsDisabled}
                      onClick={openSiteTemplateFolderModal}
                      value={getFolderName(siteTemplateFolder)}
                    />
                    <FolderSelector
                      folders={workspaceFolders}
                      loadingFolders={loadingWorkspaceFolders}
                      onClose={closeFolderSelectors}
                      onFolderSelected={handleSiteTemplateFolderSelected}
                      open={siteTemplateModalOpen}
                      openedNodes={openedNodes}
                      setOpenedNodes={setOpenedNodes}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            </Box>
          </CardContent>
          <Divider />
          <CardActions className={classes.actionButtons}>
          <Button size="small" variant="text" className={classes.btnDesign} disabled={!showCancel} onClick={handleCancel}><FormattedMessage id="btn.cancel" /></Button>
          <Button size="small" variant="outlined" className={classes.btnDesign} onClick={handleSaveSettings} startIcon={<SaveIcon />}><FormattedMessage id="btn.save" /></Button>
          </CardActions>
        </Card>
      </>
  );
};

export default CompanyDataSettings;

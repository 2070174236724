import React, { memo } from "react";
import PropTypes from "prop-types";
import RowData from "./RowData";

const FolderItem = ({
  folder,
  folderTree,
  handleOpenNode,
  isEditMode,
  selectedNode,
  setFolderTree,
  foundFolders,
  handleFolderRowClick,
}) => {
  const renderChildFolders = () => {
    if (
      folder &&
      folder.childFolderIds &&
      Array.isArray(folder.childFolderIds)
    ) {
      return (
        <>
          {folder.childFolderIds.map((x, index) => (
            <React.Fragment key={`folder-item-divider-${x}`}>
              <FolderItem
                key={`folder-item-${x}`}
                folder={folderTree[`f_${x}`]}
                folderTree={folderTree}
                handleOpenNode={handleOpenNode}
                isEditMode={isEditMode}
                selectedNode={selectedNode}
                setFolderTree={setFolderTree}
                foundFolders={foundFolders}
                handleFolderRowClick={handleFolderRowClick}
              />
            </React.Fragment>
          ))}
        </>
      );
    }
    return null;
  };

  if (
    folder &&
    folder.childFolderIds &&
    Array.isArray(folder.childFolderIds) &&
    folder.childFolderIds.length > 0
  ) {
    return (
      <RowData
        folder={folder}
        folderTree={folderTree}
        handleOpenNode={handleOpenNode}
        isEditMode={isEditMode}
        selectedNode={selectedNode}
        setFolderTree={setFolderTree}
        foundFolders={foundFolders}
        handleFolderRowClick={handleFolderRowClick}
      >
        {renderChildFolders()}
      </RowData>
    );
  }

  return (
    <RowData
      folder={folder}
      folderTree={folderTree}
      handleOpenNode={handleOpenNode}
      isEditMode={isEditMode}
      selectedNode={selectedNode}
      setFolderTree={setFolderTree}
      foundFolders={foundFolders}
      handleFolderRowClick={handleFolderRowClick}
    />
  );
};

FolderItem.defaultProps = {
  handleOpenNode: () => {},
  isEditMode: false,
  selectedNode: null,
  foundFolders: [],
};

FolderItem.propTypes = {
  selectedNode: PropTypes.shape({}),
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isEditMode: PropTypes.bool,
  folderTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleOpenNode: PropTypes.func,
  setFolderTree: PropTypes.func.isRequired,
  foundFolders: PropTypes.arrayOf(PropTypes.number),
  handleFolderRowClick: PropTypes.func.isRequired,
};

export default memo(FolderItem);

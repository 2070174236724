import { gql } from '@apollo/client';
import AddressFragment from "../fragments/Address";

const getOrderHistory = gql`
  query GetOrderHistory($model: OrderHistoryParams) {
    orderHistory(model: $model) {
      items {
        comment
        companyOrderno
        costPlaceOrder
        deliveryDateWish
        deliveryAddress {
          ...AddressFields
        }
        invoiceAddress {
          ...AddressFields
        }
        orderDate
        ordererAddress {
          email
        }
        orderId
        status
        sumInclVat
        username
      }
      totalCount
    }
  }

  ${AddressFragment}
`;

export default getOrderHistory;

import React, { useEffect, useCallback } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import UQLoadingScreen from "../../components/common/UQLoadingScreen";

const useStyles = makeStyles(theme => ({
  initialize: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
}));

const Loading = props => {
  const classes = useStyles();
  const { theme, redirectUri } = props.location.state;

  const debounceRedirect = useCallback(
    debounce(() => {
      props.history.push(redirectUri);
    }, 2000),
    []
  );
  useEffect(() => {
    debounceRedirect();
  }, []);
  return (
    <div className={classes.initialize}>
      {theme === "UQ" ? <UQLoadingScreen /> : <CircularProgress />}
    </div>
  );
};

export default Loading;

import React from "react";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "../TextField";

const useStyles = makeStyles(theme => ({
  formControl: {
    fontSize: 12,
    padding: "9px 12px",
    height: "100%",
    color: "#66615b",
  },
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
  labelWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
  },
  fieldWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
}));

const InputTextInline = ({
  label,
  value,
  type,
  inputProps,
  onChange,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid container mt={0.5} mb={0.5}>
      <Grid item xs={12} md={2} className={classes.labelWrap}>
        <span className={classes.label}>{label}</span>
      </Grid>
      <Grid item xs={12} md={10} className={classes.fieldWrap}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            marginType="none"
            value={value}
            type={type}
            inputProps={inputProps}
            onChange={onChange}
            {...rest}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

InputTextInline.defaultProps = {
  type: "text",
  inputProps: {},
};

InputTextInline.propTypes = {
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inputProps: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
};

export default InputTextInline;

import { gql } from '@apollo/client';
import SiteRoleRightsFragment from "../fragments/SiteRoleRights";

const UPDATE_SITE_ROLE = gql`
  mutation UpdateSiteRole($siteRole: SiteRoleEdit) {
    updateSiteRole(siteRole: $siteRole) {
      __typename
      siteRoleId
      siteRoleRights {
        ...SiteRoleRightsFields
      }
    }
  }
  ${SiteRoleRightsFragment}
`;

export default UPDATE_SITE_ROLE;

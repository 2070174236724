import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  icon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
}));

const ButtonPrimaryAlt = ({ label, icon, customClass, ...rest }) => {
  const classes = useStyles();

  return (
    <Button className={clsx([classes.root, customClass])} {...rest}>
      <Icon className={clsx([icon, classes.icon])} />
      {label}
    </Button>
  );
};

export default ButtonPrimaryAlt;

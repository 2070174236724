import React, { memo } from "react";
import PropTypes from "prop-types";
import RowData from "./RowData";
import InBetweenFolder from "./InBetweenFolder";

const FolderItem = ({
  checkedFolders,
  folder,
  folderTree,
  handleContextMenu,
  handleOpenNode,
  handleSetEditNode,
  isEditMode,
  moveFolderEnabled,
  onFolderRowClick,
  refetchFolders,
  selectedNode,
  setFolderTree,
  selectedFolderIds,
  handleCheckboxChange,
  handleError,

  //monitoring,
  folderPropertyFormValues,
  setFolderPropertyFormValues,
  propertyFormValues,
  setPropertyFormValues,
  setCheckedFolders,
  isMonitoring,
}) => {
  const renderChildFolders = () => {
    if (
      folder &&
      folder.childFolderIds &&
      Array.isArray(folder.childFolderIds)
    ) {
      return (
        <>
          {folder.childFolderIds.map((x, index) => (
            <React.Fragment key={`folder-item-divider-${x}`}>
              <FolderItem
                key={`folder-item-${x}`}
                checkedFolders={checkedFolders}
                folder={folderTree[`f_${x}`]}
                folderTree={folderTree}
                handleContextMenu={handleContextMenu}
                handleOpenNode={handleOpenNode}
                handleSetEditNode={handleSetEditNode}
                isEditMode={isEditMode}
                moveFolderEnabled={moveFolderEnabled}
                onFolderRowClick={onFolderRowClick}
                refetchFolders={refetchFolders}
                selectedNode={selectedNode}
                setFolderTree={setFolderTree}
                selectedFolderIds={selectedFolderIds}
                handleCheckboxChange={handleCheckboxChange}
                handleError={handleError}
                folderPropertyFormValues={folderPropertyFormValues}
                setFolderPropertyFormValues={setFolderPropertyFormValues}
                propertyFormValues={propertyFormValues}
                setPropertyFormValues={setPropertyFormValues}
                setCheckedFolders={setCheckedFolders}
                isMonitoring={isMonitoring}
              />
              <InBetweenFolder
                folderTree={folderTree}
                parentFolderId={folder.folderId}
                refetchFolders={refetchFolders}
                setFolderTree={setFolderTree}
                index={index + 1}
                handleError={handleError}
              />
            </React.Fragment>
          ))}
        </>
      );
    }
    return null;
  };

  if (
    folder &&
    folder.childFolderIds &&
    Array.isArray(folder.childFolderIds) &&
    folder.childFolderIds.length > 0
  ) {
    return (
      <RowData
        checkedFolders={checkedFolders}
        folder={folder}
        folderTree={folderTree}
        handleContextMenu={handleContextMenu}
        handleOpenNode={handleOpenNode}
        handleSetEditNode={handleSetEditNode}
        isEditMode={isEditMode}
        moveFolderEnabled={moveFolderEnabled}
        onFolderRowClick={onFolderRowClick}
        refetchFolders={refetchFolders}
        selectedFolderIds={selectedFolderIds}
        selectedNode={selectedNode}
        setFolderTree={setFolderTree}
        handleCheckboxChange={handleCheckboxChange}
        handleError={handleError}
        folderPropertyFormValues={folderPropertyFormValues}
        setFolderPropertyFormValues={setFolderPropertyFormValues}
        propertyFormValues={propertyFormValues}
        setPropertyFormValues={setPropertyFormValues}
        setCheckedFolders={setCheckedFolders}
        isMonitoring={isMonitoring}
      >
        <InBetweenFolder
          folderTree={folderTree}
          parentFolderId={folder.folderId}
          refetchFolders={refetchFolders}
          setFolderTree={setFolderTree}
          handleError={handleError}
          index={0}
        />
        {renderChildFolders()}
      </RowData>
    );
  }

  return (
    <RowData
      checkedFolders={checkedFolders}
      folder={folder}
      folderTree={folderTree}
      handleContextMenu={handleContextMenu}
      handleOpenNode={handleOpenNode}
      handleSetEditNode={handleSetEditNode}
      isEditMode={isEditMode}
      moveFolderEnabled={moveFolderEnabled}
      onFolderRowClick={onFolderRowClick}
      refetchFolders={refetchFolders}
      selectedFolderIds={selectedFolderIds}
      selectedNode={selectedNode}
      setFolderTree={setFolderTree}
      handleCheckboxChange={handleCheckboxChange}
      handleError={handleError}
    />
  );
};

FolderItem.defaultProps = {
  handleOpenNode: () => {},
  moveFolderEnabled: false,
  isEditMode: false,
  selectedNode: null,
};

FolderItem.propTypes = {
  moveFolderEnabled: PropTypes.bool,
  selectedNode: PropTypes.shape({}),
  folder: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    folderId: PropTypes.number.isRequired,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  isEditMode: PropTypes.bool,
  folderTree: PropTypes.objectOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleContextMenu: PropTypes.func.isRequired,
  handleOpenNode: PropTypes.func,
  handleSetEditNode: PropTypes.func.isRequired,
  onFolderRowClick: PropTypes.func.isRequired,
  refetchFolders: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  setFolderTree: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default memo(FolderItem);

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { FormattedMessage, useIntl } from "react-intl";
import { Accordion, AccordionTab } from "primereact/accordion";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import SideBarContent from "./SideBarContent";
import { renderEvent, renderTime } from "./EventsRenderer";

const useStyles = makeStyles(theme => ({
  cell: {
    fontSize: "14px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const MyNotificationDetail = ({ notificationData }) => {
  const { fileFolderEvents, siteRoleEvents, siteUserEvents } =
    notificationData || {};

  const classes = useStyles();
  const intl = useIntl();

  function debug(args) {
    return true;
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function groupByRole(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    var temp = Array.from(map, ([key, value]) => value);

    var result = [];
    for (var i = 0; i < temp.length; i++) {
      var entries = temp[i];

      result.push({
        key: "role" + entries[entries.length - 1].name,
        data: {
          name: entries[entries.length - 1].name,
          username: entries[entries.length - 1].username,
          eventId: entries[entries.length - 1].eventId,
          eventTime: entries[entries.length - 1].eventTime,
          siteRoleEvents: entries,
        },
      });
    }

    return result;
  }

  function groupByUsername(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    var temp = Array.from(map, ([key, value]) => value);

    var result = [];
    for (var i = 0; i < temp.length; i++) {
      var entries = temp[i];

      result.push({
        key: "user" + entries[entries.length - 1].affectedUsername,
        data: {
          name: entries[entries.length - 1].affectedUsername,
          username: entries[entries.length - 1].username,
          eventId: entries[entries.length - 1].eventId,
          eventTime: entries[entries.length - 1].eventTime,
          siteUserEvents: entries,
        },
      });
    }

    return result;
  }

  function groupByResource(list, keyGetter, folderPath) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    var temp = Array.from(map, ([key, value]) => value);

    var result = [];
    for (var i = 0; i < temp.length; i++) {
      var entries = temp[i];

      result.push({
        key: "resource" + entries[entries.length - 1].resourceEvent.resourceId,
        data: {
          name: entries[entries.length - 1].resourceEvent.name,
          username: entries[entries.length - 1].username,
          eventId: entries[entries.length - 1].eventId,
          eventTime: entries[entries.length - 1].eventTime,
          versionId: entries[entries.length - 1].resourceEvent.versionId,
          versionNo: entries[entries.length - 1].resourceEvent.versionNo,
          resourceEvents: entries,
          folderPath: folderPath,
          siteId: entries[entries.length - 1].siteId,
        },
      });
    }

    /* [resouceId], {event}
        return map.map(x => ({
        )) */

    return result;
  }

  function RemapData(data, parent) {
    return data.map(function(x) {
      var folderEvents =
        x.events && (x.events.filter(n => n.eventChannel === "FOLDER") || []);
      var lastFolderEvent =
        folderEvents.length > 0 ? folderEvents[folderEvents.length - 1] : null;

      var obj = {
        key: "folder" + x.folderId,
        data: {
          name: x.name,
          username: lastFolderEvent && lastFolderEvent.username,
          eventId: lastFolderEvent && lastFolderEvent.eventId,
          eventTime: lastFolderEvent && lastFolderEvent.eventTime,
          folderEvents: folderEvents,
          siteId: lastFolderEvent && lastFolderEvent.siteId,
        },
      };

      obj.data.folderPath = parent
        ? [...parent.data.folderPath, x.folderId]
        : [x.folderId];

      obj.children = [
        ...RemapData(x.children, obj),
        ...groupByResource(
          x.events.filter(n => n.eventChannel === "RESOURCE"),
          x => x.resourceEvent.resourceId,
          obj.data.folderPath
        ),
      ];

      return obj;
    });
  }

  const eventTemplate = (node, column) => {
    return <div>{renderEvent(node.data.eventId)}</div>;
  };

  const eventTimeTemplate = (node, column) => {
    return <div>{renderTime(node.data.eventTime)}</div>;
  };

  const eventInfoTemplate = (node, column) => {
    if (
      (node.data.folderEvents && node.data.folderEvents.length > 0) ||
      (node.data.resourceEvents && node.data.resourceEvents.length > 0) ||
      (node.data.siteUserEvents && node.data.siteUserEvents.length > 0) ||
      (node.data.siteRoleEvents && node.data.siteRoleEvents.length > 0)
    ) {
      return (
        <Button
          icon={"nc-icon nc-alert-circle-i"}
          tooltip={intl.formatMessage({
            id: "monitoring.details.clickForMoreInfo",
          })}
          tooltipOptions={{ position: "left" }}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            setSelectedNode(node.data);
            setVisibleTop(true);
          }}
          className="p-button-text"
        />
      );
    }
  };

  const [selectedNode, setSelectedNode] = useState([]);
  const [visibleTop, setVisibleTop] = useState(false); //For Sidebar

  const onSelect = event => {
    if (event.node.children && event.node.children.length > 0) {
      toggleFolders(event.node.key);
    } else {
      setSelectedNode(event.node.data);
      setVisibleTop(true);
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const onAccordionClick = itemIndex => {
    let _activeIndex = activeIndex ? [...activeIndex] : [];

    if (_activeIndex.length === 0) {
      _activeIndex.push(itemIndex);
    } else {
      const index = _activeIndex.indexOf(itemIndex);
      if (index === -1) {
        _activeIndex.push(itemIndex);
      } else {
        _activeIndex.splice(index, 1);
      }
    }

    setActiveIndex(_activeIndex);
  };

  const columns = [
    {
      label: intl.formatMessage({ id: "monitoring.details.version" }),
      value: "version",
    },
    {
      label: intl.formatMessage({ id: "monitoring.details.who" }),
      value: "who",
    },
    {
      label: intl.formatMessage({ id: "monitoring.details.when" }),
      value: "when",
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState(
    window.innerWidth < 820 ? [] : ["version", "who", "when"]
  );

  const renderColumnsOption = values => {
    var value = [];
    for (var i = 0; i < columns.length; i++) {
      if (values.includes(columns[i].value)) {
        value.push(columns[i].label);
      }
    }
    return value.join(",");
  };

  const handleChange = event => {
    setSelectedColumns(event.target.value);
  };

  const [expandedKeys, setExpandedKeys] = useState({});

  const [expandedRoleKeys, setExpandedRoleKeys] = useState({});

  const toggleFolders = openRow => {
    let _expandedKeys = { ...expandedKeys };
    if (_expandedKeys[openRow]) {
      delete _expandedKeys[openRow];
    } else {
      _expandedKeys[openRow] = true;
    }

    setExpandedKeys(_expandedKeys);
  };

  return (
    <>
      <Sidebar
        visible={visibleTop}
        position="top"
        blockScroll="true"
        className="p-sidebar-md"
        baseZIndex="10000"
        modal={true}
        onHide={() => setVisibleTop(false)}
      >
        <SideBarContent data={selectedNode} />
      </Sidebar>

      <div>
        {fileFolderEvents && fileFolderEvents.length > 0 && (
          <Button
            icon={
              activeIndex && activeIndex.some(index => index === 0)
                ? "pi pi-minus"
                : "pi pi-plus"
            }
            label={<FormattedMessage id="monitoring.details.filesFolders" />}
            onClick={() => onAccordionClick(0)}
            className="p-button-text"
          />
        )}
        {siteRoleEvents && siteRoleEvents.length > 0 && (
          <Button
            icon={
              activeIndex && activeIndex.some(index => index === 1)
                ? "pi pi-minus"
                : "pi pi-plus"
            }
            label={<FormattedMessage id="monitoring.details.roles" />}
            onClick={() => onAccordionClick(1)}
            className="p-button-text p-ml-2"
          />
        )}
        {siteUserEvents && siteUserEvents.length > 0 && (
          <Button
            icon={
              activeIndex && activeIndex.some(index => index === 2)
                ? "pi pi-minus"
                : "pi pi-plus"
            }
            label={<FormattedMessage id="monitoring.details.participants" />}
            onClick={() => onAccordionClick(2)}
            className="p-button-text p-ml-2"
          />
        )}
      </div>

      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">
            <FormattedMessage id="monitoring.details.showColumns" />
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={selectedColumns}
            onChange={handleChange}
            input={<Input />}
            MenuProps={MenuProps}
            renderValue={selected => {
              return renderColumnsOption(selected);
            }}
          >
            {columns.map(x => (
              <MenuItem key={x} value={x.value}>
                <Checkbox checked={selectedColumns.indexOf(x.value) > -1} />
                <ListItemText primary={x.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Accordion
        multiple
        activeIndex={activeIndex}
        onTabChange={e => setActiveIndex(e.index)}
      >
        {fileFolderEvents && fileFolderEvents.length > 0 && (
          <AccordionTab
            header={<FormattedMessage id="monitoring.details.filesFolders" />}
            headerClassName={classes.cell}
            contentClassName={classes.cell}
          >
            <TreeTable
              value={RemapData(fileFolderEvents)}
              resizableColumns="fit"
              selectionMode="single"
              onSelect={onSelect}
              style={{ marginTop: ".5em" }}
              className={classes.cell}
              expandedKeys={expandedKeys}
              onToggle={e => setExpandedKeys(e.value)}
            >
              <Column
                key="name"
                field="name"
                header={
                  <FormattedMessage id="monitoring.details.monitoredItem" />
                }
                expander
                className={classes.cell}
                style={{ minWidth: "250px", fontSize: "14px" }}
              ></Column>
              <Column
                className={classes.cell}
                header={<FormattedMessage id="monitoring.details.what" />}
                body={eventTemplate}
              />
              {selectedColumns && selectedColumns.includes("version") && (
                <Column
                  key="versionNo"
                  field="versionNo"
                  header={<FormattedMessage id="monitoring.details.version" />}
                  className={classes.cell}
                  style={{ width: "10%" }}
                ></Column>
              )}
              {selectedColumns && selectedColumns.includes("who") && (
                <Column
                  key="username"
                  field="username"
                  header={<FormattedMessage id="monitoring.details.who" />}
                  className={classes.cell}
                ></Column>
              )}
              {selectedColumns && selectedColumns.includes("when") && (
                <Column
                  body={eventTimeTemplate}
                  header={<FormattedMessage id="monitoring.details.when" />}
                  className={classes.cell}
                  style={{ width: "15%", whiteSpace: "nowrap" }}
                ></Column>
              )}
              <Column
                header=""
                body={eventInfoTemplate}
                className={classes.cell}
                style={{ width: "10%" }}
              />
            </TreeTable>
          </AccordionTab>
        )}

        {siteRoleEvents && siteRoleEvents.length > 0 && (
          <AccordionTab
            header={<FormattedMessage id="monitoring.details.roles" />}
          >
            <TreeTable
              value={groupByRole(siteRoleEvents, x => x.name)}
              resizableColumns="fit"
              selectionMode="single"
              //onSelect={onSelect}
              style={{ marginTop: ".5em" }}
              className={classes.cell}
              expandedKeys={expandedRoleKeys}
              onToggle={e => setExpandedRoleKeys(e.value)}
            >
              <Column
                key="name"
                field="name"
                header={
                  <FormattedMessage id="monitoring.details.monitoredItem" />
                }
                expander
                className={classes.cell}
                style={{ minWidth: "250px", fontSize: "14px" }}
              ></Column>
              <Column
                className={classes.cell}
                header={<FormattedMessage id="monitoring.details.what" />}
                body={eventTemplate}
              />
              {selectedColumns && selectedColumns.includes("who") && (
                <Column
                  key="username"
                  field="username"
                  header={<FormattedMessage id="monitoring.details.who" />}
                  className={classes.cell}
                ></Column>
              )}
              {selectedColumns && selectedColumns.includes("when") && (
                <Column
                  body={eventTimeTemplate}
                  header={<FormattedMessage id="monitoring.details.when" />}
                  className={classes.cell}
                  style={{ width: "15%", whiteSpace: "nowrap" }}
                ></Column>
              )}
              <Column
                header=""
                body={eventInfoTemplate}
                className={classes.cell}
                style={{ width: "10%" }}
              />
            </TreeTable>
          </AccordionTab>
        )}

        {siteUserEvents && siteUserEvents.length > 0 && (
          <AccordionTab
            header={<FormattedMessage id="monitoring.details.participants" />}
          >
            <TreeTable
              value={groupByUsername(siteUserEvents, x => x.affectedUsername)}
              resizableColumns="fit"
              selectionMode="single"
              //onSelect={onSelect}
              style={{ marginTop: ".5em" }}
              className={classes.cell}
            >
              <Column
                key="name"
                field="name"
                header={
                  <FormattedMessage id="monitoring.details.monitoredItem" />
                }
                expander
                className={classes.cell}
                style={{ minWidth: "250px", fontSize: "14px" }}
              ></Column>
              <Column
                className={classes.cell}
                header={<FormattedMessage id="monitoring.details.what" />}
                body={eventTemplate}
              />
              {selectedColumns && selectedColumns.includes("who") && (
                <Column
                  key="username"
                  field="username"
                  header={<FormattedMessage id="monitoring.details.who" />}
                  className={classes.cell}
                ></Column>
              )}
              {selectedColumns && selectedColumns.includes("when") && (
                <Column
                  body={eventTimeTemplate}
                  header={<FormattedMessage id="monitoring.details.when" />}
                  className={classes.cell}
                  style={{ width: "15%", whiteSpace: "nowrap" }}
                ></Column>
              )}
              <Column
                header=""
                body={eventInfoTemplate}
                className={classes.cell}
                style={{ width: "10%" }}
              />
            </TreeTable>
          </AccordionTab>
        )}
      </Accordion>
    </>
  );
};

export default MyNotificationDetail;

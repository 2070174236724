import React from "react";
import PropTypes from "prop-types";
import TermsOfUseLink from "./TermsOfUseLink";
import { TERMS_OF_USE_SECTION_ID } from "../../constant/termsOfUse";

const TermsOfUseSectionLink = ({ children }) => {
  return (
    <TermsOfUseLink tabId={TERMS_OF_USE_SECTION_ID.TermsOfUse}>
      {children}
    </TermsOfUseLink>
  );
};

TermsOfUseSectionLink.defaultProps = {
  children: null,
};

TermsOfUseSectionLink.propTypes = {
  children: PropTypes.node,
};

export default TermsOfUseSectionLink;

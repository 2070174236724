import { isEqual } from "lodash";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { GET_WIDGET_PRODUCTS } from "../../../graphql/queries";
import { getSiteId } from "../../../helpers/selectors";
import DocumentViewItem from "../../ProductsMainView/DocumentViewItem";
import EmptyList from "../../ProductsMainView/EmptyList";
import { DocumentViewItemSkeleton } from "../../Skeleton";

const ITEM_HEIGHT = 306;
const ITEM_WIDTH = 228;

const ImageGrid = styled(Box)(({ gridLayout }) => ({
  alignContent: "space-around",
  display: "grid",
  gridTemplateColumns: `repeat(${gridLayout.columns},${gridLayout.cellWidth}px)`,
  gridTemplateRows: `repeat(${gridLayout.rows},${gridLayout.cellHeight}px)`,
  height: "100%",
  justifyContent: "center",
  maxHeight: "100%",
  overflow: "hidden",
  paddingTop: "8px",
}));

const ImageGridCell = styled(Box)({
  padding: "5px",
});

const ImageGalleryWidget = () => {

  const gridRef = useRef();

  const siteId = useSelector(state => getSiteId(state));

  const [gridLayout, setGridLayout] = useState({
    columns: 0,
    rows: 0,
    cellHeight: ITEM_HEIGHT,
    cellWidth: ITEM_WIDTH,
  });
  const [maxItemsToDisplay, setMaxItemsToDisplay] = useState(0);
  const [products, setProducts] = useState();

  const [getWidgetData, { data, loading }] = useLazyQuery(GET_WIDGET_PRODUCTS);

  useEffect(n => {
    if (siteId && !products) {
      getWidgetData({ variables: { siteId: siteId } });
    }
  }, [products, siteId]);

  useEffect(n => {
    if (data && data.widgets && data.widgets.getWidgetProducts) {
      setProducts(data.widgets.getWidgetProducts);
    }
  }, [data])

  useEffect(() => {
    const { columns, rows } = gridLayout;
    setMaxItemsToDisplay(columns * rows);
  }, [gridLayout]);

  useLayoutEffect(() => {
    const { current } = gridRef;

    const trigger = () => {
      const { clientWidth, clientHeight } = current;

      const ch = clientHeight - 20;
      const cw = clientWidth - 10;

      const rows = Math.round(ch / ITEM_HEIGHT);
      const cellHeight = Math.floor(ch / rows);
      const cellWidth = Math.floor((ITEM_WIDTH / ITEM_HEIGHT) * (ch / rows));
      const columns = Math.floor(cw / cellWidth);

      const newLayout = {
        columns,
        rows,
        cellHeight,
        cellWidth,
      };

      if (!isEqual(gridLayout, newLayout)) {
        setGridLayout(newLayout);
      }
    };

    if (current) {
      trigger();

      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }
    }
  }, [gridRef]);

  const haveItems = products?.items?.length > 0;

  return (
    <ImageGrid component="div" gridLayout={gridLayout} ref={gridRef}>
      {haveItems ? (
        products.items.slice(0, maxItemsToDisplay).map((product) => (
          <ImageGridCell component="div" key={product.id}>
            <DocumentViewItem
              cardMediaSize={`${gridLayout.cellWidth - 10}px`}
              product={product}
              showProductModalOnOpen={false}
            />
          </ImageGridCell>
        ))
      ) : loading ? (
        [...Array(maxItemsToDisplay)].map((idx) => (
          <ImageGridCell component="div" key={`skeleton_${idx}`}>
            <DocumentViewItemSkeleton cardMediaSize={`${gridLayout.cellWidth - 10}px`} />
          </ImageGridCell>
        ))
      ) : (
        <Box component="div" sx={{ position: "absolute", left: 0, right: 0 }}>
          <EmptyList />
        </Box>
      )}
    </ImageGrid>
  );
}

export default ImageGalleryWidget;

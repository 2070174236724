import { gql } from '@apollo/client';

const SEARCH_ARTICLE = gql`query searchArticle($companyId: Int!, $articleNo: String, $amount: Decimal!){
	companyAdminContext{
		salesDocuments{
			searchArticle(companyId: $companyId, articleNo: $articleNo, amount: $amount){
				amount
				articleNo
				description
				price
			}
		}
	}
}`;

export default SEARCH_ARTICLE;







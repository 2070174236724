import React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "unset",
      borderRadius: 0,
    },
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle=".draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DraggableModal = ({
  open,
  handleClose,
  customClass,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className={classes.root}
      classes={{ paper: customClass }}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default DraggableModal;

DraggableModal.defaultProps = {};

export { default as DraggableModalBody } from "./DraggableModalBody";
export { default as DraggableModalHead } from "./DraggableModalHead";

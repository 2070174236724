import { FormattedMessage } from "react-intl";
import { Tooltip } from "@mui/material";
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';

export const StatusApprovedIcon = () => (
  <Tooltip arrow placement="left" title={<FormattedMessage id="common.approved" />}>
    <SentimentSatisfiedOutlinedIcon />
  </Tooltip>
);

export const StatusRejectedIcon = () => (
  <Tooltip arrow placement="left" title={<FormattedMessage id="common.rejected" />}>
    <SentimentDissatisfiedOutlinedIcon />
  </Tooltip>
);

export const StatusSentIcon = () => (
  <Tooltip arrow placement="left" title={<FormattedMessage id="common.sent" />}>
    <MarkEmailReadOutlinedIcon />
  </Tooltip>
);

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Box, Divider, Icon } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomTextField from "../../../../../common/TextField";
import CustomSelect from "../../../../../common/CustomSelect";
import QuantityAwardRow from "./QuantityAwardRow";
import { isFieldRequired } from "../helpers";

const useStyles = makeStyles(theme => ({
  customForm: {
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 10,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputAdornment-root button": {
      padding: 5,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 10,
    },
    "& .MuiSelect-select": {
      fontSize: 10,
      paddingTop: 5,
      paddingBottom: 5,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 10,
    },
    "& .MuiFormControlLabel-label": {
      fontSize: 10,
      marginRight: "1em",
    },
  },
  button: {
    padding: "10px 15px",
    lineHeight: 0.5,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    minWidth: "unset",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    textTransform: "none",
  },
  panelTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
    marginRight: 10,
  },
  box: {
    boxShadow: "0 3px 10px 0 rgba(0,0,0,0.01)",
    borderRadius: 4,
    padding: "10px 15px",
    backgroundColor: theme.palette.common.white,
    "& $panelTitle": {
      marginBottom: 10,
      marginRight: 0,
    },
  },
  box2: {
    borderRadius: 4,
    padding: "10px 15px",
    "& $panelTitle": {
      marginBottom: 10,
      marginRight: 0,
    },
  },
  formDivider: {
    marginTop: 10,
    marginBottom: 10,
  },
  label: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  subLabel: {
    float: "right",
    fontSize: 10,
    marginBottom: 3,
  },
  btnAdd: {
    textTransform: "none",
    color: theme.palette.common.link,
    fontSize: 11,
    padding: 0,
    marginTop: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    fontSize: 10,
    marginRight: 5,
    color: theme.palette.common.link,
  },
  momssatsLabel: {
    marginBottom: 5,
    marginTop: 0,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const PricePanelManual = ({
  buyManualPrices,
  sellManualPrices,
  formValues,
  handleAddBuyPricesManualPrice,
  handleBuyPricesInitialCost,
  handleBuyManualPrice,
  handleAddSellPricesManualPrice,
  handleSellPricesInitialCost,
  handleSellManualPrice,
  handleCheckbox,
  handleVatRate,
  vatRates,
  errors,
  handleRemoveBuyPricesManualPrice,
  handleRemoveSellPricesManualPrice,
  selectedProductType,
}) => {
  const classes = useStyles();

  const { vatRateId = "" } = formValues || {};

  const vatOptions = vatRates.map(x => {
    return { label: x.name, value: x.vatRateId };
  });

  const renderRequiredMark = fieldName => {
    const isRequired = isFieldRequired(fieldName, selectedProductType);

    if (isRequired) {
      return "*";
    }
    return null;
  };

  return (
    <>
      <Grid container spacing={3} className={classes.customForm}>
        <Grid item xs={12} md={12}>
          <Box className={classes.box}>
            <FormControlLabel
              control={
                <Switch
                  name="showPrice"
                  checked={formValues.showPrice}
                  onChange={handleCheckbox}
                  size="small"
                  color="primary"
                />
              }
              label={<FormattedMessage id="product.showPriceOptionSwitch" />}
            />
            <FormControlLabel
              control={
                <Switch
                  name="allowFreeQuantity"
                  checked={formValues.allowFreeQuantity}
                  onChange={handleCheckbox}
                  size="small"
                  color="primary"
                />
              }
              label={<FormattedMessage id="product.allowFreeNumberOption" />}
            />
            <FormControlLabel
              control={
                <Switch
                  name="pricesInclusiveFreight"
                  checked={formValues.pricesInclusiveFreight}
                  onChange={handleCheckbox}
                  size="small"
                  color="primary"
                />
              }
              label={<FormattedMessage id="product.includeShipping" />}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.customForm}>
        <Grid item xs={12} md={6}>
          <Box className={classes.box}>
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <CustomSelect
                  inputLabel={<FormattedMessage id="product.sellingVatrate" />}
                  value={vatRateId}
                  options={vatOptions}
                  onChange={handleVatRate}
                  error={vatRateId ? false : errors.vatRateId || false}
                  helperText={vatRateId ? null : errors.vatRateId}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.customForm}>
        <Grid item xs={12} md={12}>
          <Divider className={classes.formDivider} />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.customForm}>
        <Grid item xs={12} md={6}>
          <Box className={classes.box}>
            <Typography className={classes.panelTitle}>
              <FormattedMessage id="product.sellingPriceTitle" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <CustomTextField
                  label={<FormattedMessage id="product.sellingStartCost" />}
                  onChange={e => handleSellPricesInitialCost(e.target.value)}
                  type="number"
                  value={
                    formValues && formValues.sellPrices
                      ? formValues.sellPrices.initialCosts
                      : 0
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={5} md={5}>
                <Typography className={classes.label}>
                  <FormattedMessage id="product.sellingQuantity" /> *
                </Typography>
              </Grid>
              <Grid item xs={5} md={5}>
                <Typography className={classes.label}>
                  <FormattedMessage id="product.sellingPrice" /> *
                </Typography>
              </Grid>
            </Grid>
            {sellManualPrices.map((x, idx) => {
              return (
                <QuantityAwardRow
                  // Using index. There are no other values that can be used
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  quantity={x.quantity}
                  price={x.price}
                  onChange={e => handleSellManualPrice(e, idx)}
                  onDeleteRow={() => handleRemoveSellPricesManualPrice(idx)}
                />
              );
            })}

            <Box>
              <Button
                className={classes.btnAdd}
                onClick={() => handleAddSellPricesManualPrice()}
              >
                <Icon className={clsx(["fa fa-plus", classes.addIcon])} />
                <FormattedMessage id="btn.add" />
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.box2}>
            <Typography className={classes.panelTitle}>
              <FormattedMessage id="product.costPrice" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={5} md={5}>
                <CustomTextField
                  label={<FormattedMessage id="product.sellingStartCost" />}
                  onChange={e => handleBuyPricesInitialCost(e.target.value)}
                  type="number"
                  value={
                    formValues && formValues.buyPrices
                      ? formValues.buyPrices.initialCosts
                      : 0
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={5} md={5}>
                <Typography className={classes.label}>
                  <FormattedMessage id="product.sellingQuantity" />
                </Typography>
              </Grid>
              <Grid item xs={5} md={5}>
                <Typography className={classes.label}>
                  <FormattedMessage id="product.sellingPrice" />
                </Typography>
              </Grid>
            </Grid>
            {buyManualPrices.map((x, idx) => {
              return (
                <QuantityAwardRow
                  // Using index. There are no other values that can be used
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  quantity={x.quantity}
                  price={x.price}
                  onChange={e => handleBuyManualPrice(e, idx)}
                  onDeleteRow={() => handleRemoveBuyPricesManualPrice(idx)}
                />
              );
            })}

            <Box>
              <Button
                className={classes.btnAdd}
                onClick={() => handleAddBuyPricesManualPrice()}
              >
                <Icon className={clsx(["fa fa-plus", classes.addIcon])} />
                <FormattedMessage id="btn.add" />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

PricePanelManual.defaultProps = {
  selectedProductType: "",
};

PricePanelManual.propTypes = {
  selectedProductType: PropTypes.string,
  errors: PropTypes.shape({ vatRateId: PropTypes.string }).isRequired,
  formValues: PropTypes.shape({
    showPrice: PropTypes.bool,
    allowFreeQuantity: PropTypes.bool,
    buyPrices: PropTypes.shape({ initialCosts: PropTypes.number }).isRequired,
    sellPrices: PropTypes.shape({ initialCosts: PropTypes.number }).isRequired,
  }).isRequired,
  sellManualPrices: PropTypes.arrayOf().isRequired,
  vatRates: PropTypes.arrayOf().isRequired,
  handleVatRate: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  buyManualPrices: PropTypes.arrayOf().isRequired,
  handleAddBuyPricesManualPrice: PropTypes.func.isRequired,
  handleBuyPricesInitialCost: PropTypes.func.isRequired,
  handleBuyManualPrice: PropTypes.func.isRequired,
  handleAddSellPricesManualPrice: PropTypes.func.isRequired,
  handleSellPricesInitialCost: PropTypes.func.isRequired,
  handleSellManualPrice: PropTypes.func.isRequired,
  handleRemoveBuyPricesManualPrice: PropTypes.func.isRequired,
  handleRemoveSellPricesManualPrice: PropTypes.func.isRequired,
};

export default PricePanelManual;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import SuppliersTable from "./Table";
import AddSupplierBtn from "./AddSupplierBtn";
import HeaderName from "./HeaderName";
import SupplierPaper from "./Paper";
import AddSupplierModal from "./AddSupplierModal";
import { addSupplier } from "../../actions";
import { ADD_SITE_SUPPLIER } from "../../graphql/mutations";
import { ErrorText } from "../common";

const SuppliersContainer = ({ loading, items, error, siteId }) => {
  const [addSupplierModalVisible, setAddSupplierModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [addSiteSupplier] = useMutation(ADD_SITE_SUPPLIER);
  const alert = useAlert();

  const handleCloseModal = () => {
    setAddSupplierModalVisible(false);
  };

  const handleOpenModal = () => {
    setAddSupplierModalVisible(true);
  };

  const handleOnSendClick = async ({ item }) => {
    const { supplierName, supplierEmail, supplierId } = item;

    const results = await addSiteSupplier({
      variables: {
        siteId,
        name: supplierName,
        email: supplierEmail,
        supplierId: supplierId || "",
      },
    });

    if (results && results.data && results.data.addSiteSupplier) {
      dispatch(
        addSupplier({
          item: results.data.addSiteSupplier,
        })
      );
      alert.success(
        <FormattedMessage id="supplier.supplierAddedSuccessfully" />
      );
    } else {
      alert.error(<FormattedMessage id="supplier.unableToAddSupplier" />);
    }

    setAddSupplierModalVisible(false);
  };

  return (
    <Grid container item lg={6} md={8} sm={10} xs={12}>
      <SupplierPaper>
        <Box>
          <HeaderName>
            <FormattedMessage id="supplier.suppliers" />
          </HeaderName>
        </Box>
        <Box>
          <AddSupplierBtn
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleOpenModal()}
          >
            <FormattedMessage id="supplier.addSupplier" />
          </AddSupplierBtn>
        </Box>
        <Box>
          {error ? (
            <ErrorText message="An error occured while getting suppliers." />
          ) : (
            <SuppliersTable loading={loading} items={items} />
          )}
        </Box>
      </SupplierPaper>
      {addSupplierModalVisible && (
        <AddSupplierModal
          open={addSupplierModalVisible}
          handleClose={handleCloseModal}
          onSendClick={handleOnSendClick}
        />
      )}
    </Grid>
  );
};

SuppliersContainer.defaultProps = { loading: false };

SuppliersContainer.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SuppliersContainer;

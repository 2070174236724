import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { createUrl } from "../../helpers/termsOfUse";

const TermsOfUseLink = ({ children, tabId }) => {
  const url = createUrl(tabId);
  return (
    <Link target="_blank" to={url}>
      {children}
    </Link>
  );
};

TermsOfUseLink.defaultProps = {
  children: null,
  tabId: null,
};

TermsOfUseLink.propTypes = {
  children: PropTypes.node,
  tabId: PropTypes.string,
};

export default TermsOfUseLink;

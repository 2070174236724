import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { Button, Box, Icon, LinearProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import CustomTextField from "../common/TextField";
import IssueModal from "./IssueModal";
import IconLink from "../common/FormControl/IconLink";
import ColumnSearch from "./ColumnSearch";

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: "auto",
  },
  th: {
    padding: 7,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
    textTransform: "uppercase",
    verticalAlign: "top",
    whiteSpace: "nowrap",
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: theme.palette.primary.main,
    },
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
  },
  td: {
    fontSize: 11,
    padding: 7,
  },
  link: {
    color: theme.palette.common.link,
    textTransform: "none",
    padding: 0,
    fontSize: 11,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  action: {
    padding: 5,
  },
  icon: {
    color: theme.palette.common.link,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  iconTrash: {
    color: theme.palette.primary.main,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
  pagination: {
    border: "none",
    marginTop: 20,
  },
  caption: {
    fontSize: 12,
  },
  toolbar: {
    minHeight: "unset",
    marginTop: 5,
    paddingLeft: 0,
  },
  spacer: {
    display: "none",
  },
  select: {
    border: "1px solid #ddd",
    borderRadius: 3,
    fontSize: 12,
  },
  selectIcon: {
    top: 4,
  },
  searchWrap: {
    width: 300,
    marginBottom: 10,
  },
  iconSearch: {
    fontSize: 12,
    color: "#b3b3b3",
  },
  progressRoot: {
    height: 12,
    backgroundColor: theme.palette.background.gray,
    borderRadius: 3,
  },
  progressBar: {
    backgroundColor: theme.palette.common.blue,
  },
  colSearchIcon: {
    fontSize: 11,
    color: "#ccc",
  },
  colSearch: {
    fontWeight: 400,
    "& .MuiInputBase-input": {
      paddingTop: 7,
      paddingBottom: 7,
    },
    maxWidth: "fit-content",
  },
  btnColClear: {
    padding: 0,
    fontWeight: 400,
    color: theme.palette.primary.main,
    fontSize: 10,
    backgroundColor: "transparent",
    minWidth: "unset",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
    width: "auto",
    marginTop: 5,
    marginRight: 8,
  },
  btnColSearch: {
    padding: "5px 15px",
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: 9,
    backgroundColor: theme.palette.common.cerise,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.common.neon,
    },
    width: "auto",
    marginTop: 5,
  },
}));

const IssuesTable = ({ showColumn }) => {
  const classes = useStyles();
  const [openIssue, setOpenIssue] = useState(false);
  const handleOpenIssue = () => {
    setOpenIssue(true);
  };
  const handleCloseIssue = () => {
    setOpenIssue(false);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [show, toggleShow] = React.useState(false);

  const [selectedColumn, setSelectedColumn] = React.useState("");

  const onToggleClick = columnName => {
    toggleShow(!show);
    setSelectedColumn(columnName);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.searchWrap}>
        <CustomTextField
          placeholder="Sök"
          endAdornment={
            <Icon className={clsx(["fa fa-search", classes.iconSearch])} />
          }
        />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("arende")}
              />
              <TableSortLabel active="true">Ärende</TableSortLabel>
              {show && selectedColumn === "arende" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("kunds")}
              />
              <TableSortLabel>Kunds ärendenr</TableSortLabel>
              {show && selectedColumn === "kunds" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("typ")}
              />
              <TableSortLabel>Typ</TableSortLabel>
              {show && selectedColumn === "typ" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("skapad")}
              />
              <TableSortLabel>Skapad</TableSortLabel>
              {show && selectedColumn === "skapad" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("skapadAv")}
              />
              <TableSortLabel>Skapad av</TableSortLabel>
              {show && selectedColumn === "skapadAv" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("senastAndrad")}
              />
              <TableSortLabel>Senast ändrad</TableSortLabel>
              {show && selectedColumn === "senastAndrad" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("tilldelad")}
              />
              <TableSortLabel>Tilldelad</TableSortLabel>
              {show && selectedColumn === "tilldelad" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <TableSortLabel>Kommentarer</TableSortLabel>
            </TableCell>
            <TableCell className={classes.th}>
              <TableSortLabel>Bilaga</TableSortLabel>
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("farg")}
              />
              <TableSortLabel>FÄRG</TableSortLabel>
              {show && selectedColumn === "farg" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("prio")}
              />
              <TableSortLabel>Prio</TableSortLabel>
              {show && selectedColumn === "prio" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("status")}
              />
              <TableSortLabel>Status</TableSortLabel>
              {show && selectedColumn === "status" && <ColumnSearch />}
            </TableCell>
            <TableCell className={classes.th}>
              <IconLink
                icon="fa fa-search"
                customClass={classes.colSearchIcon}
                onClick={() => onToggleClick("framsteg")}
              />
              <TableSortLabel>Framsteg</TableSortLabel>
              {show && selectedColumn === "framsteg" && <ColumnSearch />}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>
              <Button className={classes.link} onClick={handleOpenIssue}>
                Roll-ups och vepor till mässa
              </Button>
            </TableCell>
            <TableCell className={classes.td}>43098</TableCell>
            <TableCell className={classes.td}>Annonsbeställning</TableCell>
            <TableCell className={classes.td}>2018-04-01 09:45</TableCell>
            <TableCell className={classes.td}>R. Hassel</TableCell>
            <TableCell className={classes.td}>2018-04-03 13:23</TableCell>
            <TableCell className={classes.td}>G. Larsson</TableCell>
            <TableCell className={classes.td}>4</TableCell>
            <TableCell className={classes.td}>2</TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td}>Brådskande</TableCell>
            <TableCell className={classes.td}>Beställd</TableCell>
            <TableCell className={classes.td}>
              <LinearProgress
                variant="determinate"
                color="secondary"
                value={20}
                classes={{
                  root: classes.progressRoot,
                  bar: classes.progressBar,
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <Button className={classes.link} onClick={handleOpenIssue}>
                ICA Mölndal
              </Button>
            </TableCell>
            <TableCell className={classes.td}>12390</TableCell>
            <TableCell className={classes.td}>Annonsbeställning</TableCell>
            <TableCell className={classes.td}>2018-04-01 09:45</TableCell>
            <TableCell className={classes.td}>M. Wern</TableCell>
            <TableCell className={classes.td}>2018-04-03 13:23</TableCell>
            <TableCell className={classes.td}>A. Dahl</TableCell>
            <TableCell className={classes.td}>4</TableCell>
            <TableCell className={classes.td}>2</TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td}>Brådskande</TableCell>
            <TableCell className={classes.td}>Beställd</TableCell>
            <TableCell className={classes.td}>
              <LinearProgress
                variant="determinate"
                color="secondary"
                value={70}
                classes={{
                  root: classes.progressRoot,
                  bar: classes.progressBar,
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={13}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "visa rader" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<FormattedMessage id="context.showRows" />}
              className={classes.pagination}
              classes={{
                caption: classes.caption,
                toolbar: classes.toolbar,
                select: classes.select,
                spacer: classes.spacer,
                selectIcon: classes.selectIcon,
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <IssueModal open={openIssue} handleClose={handleCloseIssue} />
    </div>
  );
};

export default IssuesTable;

import React, { memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  TableHead,
  TableRow,
  TableCell,
  Box,
  Typography,
} from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import HeaderText from "../../common/FilesTable/HeaderText";

const useStyles = makeStyles(theme => ({
  infoIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    marginLeft: 5,
    verticalAlign: "middle",
    color: theme.palette.common.link,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const FolderFileTableHeader = ({
  folder,
  setCheckedFolders,
  checkedFolders,
  folderPropertyFormValues,
  setFolderPropertyFormValues,
  propertyFormValues,
  setPropertyFormValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <TableHead style={{ backgroundColor: "#d5d5d5", padding: "5px 10px" }}>
      <TableRow>
        <TableCell colSpan={5} style={{ borderBottom: "none" }}>
          <Box style={{ display: "flex" }}>
            <Box style={{ flex: 1 }}>
              <HeaderText>
                <Typography>
                  <FormattedMessage id="monitoring.settingsForSelectedFolder" />
                  <Box component="span" style={{ color: "#808080" }}>
                    |{" "}
                    <FormattedMessage id="monitoring.appliesToNewFilesWhichLackSettings" />
                  </Box>
                </Typography>
              </HeaderText>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

FolderFileTableHeader.defaultProps = {
  folderPropertyFormValues: [],
  propertyFormValues: [],
};

FolderFileTableHeader.propTypes = {
  folder: PropTypes.shape({}).isRequired,
  setCheckedFolders: PropTypes.func.isRequired,
  setFolderPropertyFormValues: PropTypes.func.isRequired,
  setPropertyFormValues: PropTypes.func.isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(FolderFileTableHeader);

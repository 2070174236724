import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import logger from "../../helpers/logger";
import { Chip } from "../common";
import SelectFileFromSystemModal from "../common/SelectFileFromSystemModal";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: 2,
  },
  label: {
    fontSize: 10,
    color: "#9A9A9A",
    fontWeight: 600,
    textTransform: "uppercase",
    overflowWrap: "anywhere",
  },
  fieldWrap: {
    padding: "9px 12px",
    fontSize: 12,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
  },
  resourcesWrap: { marginTop: 3 },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "5px 15px",
    backgroundColor: "#66615B",
    color: "#fff",
  },
  uploadText: {
    margin: "0 15px",
  },
  labelWrap: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
  },
  fieldCol: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
  },
  uploadButtonMobile: {
    marginTop: 5,
  },
}));

const PrintFileRow = ({
  label,
  setSelectedResources,
  selectedResources,
  uploadToken,
  uploadedFiles,
  setUploadedFiles,
  RFPUploadFileUrl,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleOnSelectUploadFile = async event => {
    const { target } = event || {};
    const { files } = target || {};
    if (files && files[0]) {
      try {
        setLoadingUpload(true);
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("uploadToken", uploadToken);
        const response = await axios.post(`${RFPUploadFileUrl}`, formData);

        if (
          response &&
          response.data &&
          response.data.success &&
          response.status === 200
        ) {
          const { fileToken } = response.data;

          const newFile = { fileToken, name: files[0].name };
          setUploadedFiles([...uploadedFiles, newFile]);
          logger.log(`Success uploading file.`);
        } else {
          logger.error(
            `An error occurred while uploading the file. ${JSON.stringify(
              response
            )}`
          );
        }
        setLoadingUpload(false);
      } catch (uploadException) {
        setLoadingUpload(false);
        logger.error(
          `An error occurred while uploading the file. ${JSON.stringify(
            uploadException
          )}`
        );
      }
    } else {
      logger.warn("An error could be happening in selecting files.");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveResources = resource => {
    const tempResource = selectedResources.filter(x => x.id !== resource.id);
    setSelectedResources(tempResource);
  };

  const handleRemoveUploadedFiles = file => {
    const tempUploadedFiles = uploadedFiles.filter(
      x => x.fileToken !== file.fileToken
    );
    setUploadedFiles(tempUploadedFiles);
  };

  return (
    <Grid container mt={0.5} mb={0.5}>
      <Grid item xs={12} md={2} className={classes.labelWrap}>
        <span className={classes.label}>{label}</span>
      </Grid>
      <Grid item xs={12} md={10} className={classes.fieldCol}>
        <div className={classes.fieldWrap}>
          <div>
            <Button
              variant="contained"
              className={classes.uploadButton}
              component="label"
            >
              <FormattedMessage id="btn.uploadFromComputer" />
              {loadingUpload ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleOnSelectUploadFile}
                />
              )}
            </Button>
            <span className={classes.uploadText}>
              <FormattedMessage id="rfp.or" />
            </span>
            <Button
              variant="contained"
              className={clsx([
                classes.uploadButton,
                classes.uploadButtonMobile,
              ])}
              onClick={handleOpen}
            >
              <FormattedMessage id="btn.uploadFromSystem" />
            </Button>
            <SelectFileFromSystemModal
              open={open}
              handleClose={handleClose}
              setSelectedResources={setSelectedResources}
              selectedResources={selectedResources}
            />
          </div>
          {uploadedFiles && uploadedFiles.length > 0 && (
            <div className={classes.resourcesWrap}>
              {uploadedFiles.map(x => (
                <Chip
                  key={x.name}
                  label={x.name}
                  onDelete={() => handleRemoveUploadedFiles(x)}
                  color="light"
                />
              ))}
            </div>
          )}
          {selectedResources && selectedResources.length > 0 && (
            <div className={classes.resourcesWrap}>
              {selectedResources.map(x => (
                <Chip
                  key={x.name}
                  label={x.name}
                  onDelete={() => handleRemoveResources(x)}
                  color="light"
                />
              ))}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

PrintFileRow.propTypes = {
  selectedResources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      changedBy: PropTypes.string.isRequired,
      changedDate: PropTypes.string.isRequired,
      uploadedDate: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  setSelectedResources: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  uploadToken: PropTypes.string.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      fileToken: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  RFPUploadFileUrl: PropTypes.string.isRequired,
};

export default PrintFileRow;

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import CustomSelect from "../../common/CustomSelect";
import { PAYMENT_METHOD } from "../../../constant";

const PaymentMethodSelector = ({ disabled, lang, onChange, paymentMethod }) => {
  const intl = useIntl();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const tempOptions = [
      {
        disabled: false,
        label: intl.formatMessage({ id: "paymentMethod.invoice" }),
        value: PAYMENT_METHOD.Invoice,
      },
      {
        disabled: !true,
        label: intl.formatMessage({ id: "paymentMethod.card" }),
        value: PAYMENT_METHOD.Card,
      },
    ].sort((a, b) => a.label.localeCompare(b.label));
    setOptions(tempOptions);
  }, [lang]);

  const onSelectChange = e => {
    const { value: paymentMethod } = e.target;
    onChange({ paymentMethod });
  };

  return (
    <CustomSelect
      disabled={disabled}
      inputLabel={<FormattedMessage id="admin.payments.paymentMethodLabel" />}
      onChange={onSelectChange}
      options={options}
      required
      value={paymentMethod}
    />
  );
};

PaymentMethodSelector.defaultProps = {
  disabled: false,
  lang: "SV",
  paymentMethod: "",
};

PaymentMethodSelector.propTypes = {
  disabled: PropTypes.bool,
  lang: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
};

export default PaymentMethodSelector;

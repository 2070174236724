import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace($siteId: Int!) {
    companyAdminContext {
      companyWorkspaces {
        deleteWorkspace(siteId: $siteId) {
          errorResult {
            ...ErrorResultFields
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default DELETE_WORKSPACE;

import React, { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTextField from "../../common/TextField";
import ManageRolesTableRow from "../../RoleManagement/ManageRolesTableRow";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
  siteRole: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  siteRoleLabel: {
    fontSize: 12,
  },
  textField: {
    marginTop: 0,
  },
  td: {
    padding: "4px 0",
    fontSize: 11,
    borderBottom: "none!Important",
  },
}));

const CompanySettings = ({
  handleCheckbox,
  roleValues,
  subscriptionLevels,
  handleSelect,
  handleEnterEmail,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const addressOptions = [
    {
      label: intl.formatMessage({ id: "companyRoles.canChangeAddress" }),
      value: "DIRECT_UPDATE",
    },
    {
      label: intl.formatMessage({ id: "companyRoles.sendAddressTo" }),
      value: "SEND_REQUEST",
    },
  ];

  const canManageCompanyWorkspacesOptions = [
    {
      label: intl.formatMessage({
        id: "companyRoles.canManageCompanyWorkspaces.notAllowed",
      }),
      value: "NOT_ALLOWED",
    },
    {
      label: intl.formatMessage({
        id: "companyRoles.canManageCompanyWorkspaces.allWorkspaces",
      }),
      value: "ALL_WORKSPACES",
    },
    {
      label: intl.formatMessage({
        id: "companyRoles.canManageCompanyWorkspaces.ownedByTheUser",
      }),
      value: "OWNED_BY_THE_USER",
    },
  ];

  const headers = [
    {
      label: intl.formatMessage({
        id: "companyRoles.canManageCompany",
      }),
      name: "canManageCompany",
      checkbox: true,
      toolTipInfo: true,
      showChild: true,
    },
    {
      label: intl.formatMessage({
        id: "companyRoles.canManageCompanyUsers",
      }),
      name: "canManageUsers",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "companyRoles.canManageCompanyRoles",
      }),
      name: "canManageRoles",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "companyRoles.canManageCompanyWorkspaces",
      }),
      name: "canManageWorkspaces",
      options: canManageCompanyWorkspacesOptions,
      checkbox: false,
      toolTipInfo: true,
      hideLabel: true,
    },

    {
      label: intl.formatMessage({
        id: "companyRoles.canManageDashboard",
      }),
      name: "canManageDashboard",
      checkbox: true,
      toolTipInfo: true,
    },
    {
      label: intl.formatMessage({
        id: "companyRoles.canEditOwnAccountSettings",
      }),
      name: "canEditOwnAccountSettings",
      checkbox: true,
      toolTipInfo: false,
    },
    {
      label: intl.formatMessage({
        id: "companyRoles.addressChanges",
      }),
      name: "userAccountAddressUpdateSetting",
      options: addressOptions,
      checkbox: false,
      toolTipInfo: false,
      hideLabel: true,
    },
  ];
  return (
    <Grid item xs={12} md={4}>
      <Typography className={classes.text}>
        <FormattedMessage id="companyRoles.companySettings" />
      </Typography>
      <Box className={classes.divider} />

      <Table className={classes.table} size="small">
        <TableBody>
          {headers.map(head => {
            const hideLabel = head && head.hideLabel ? head.hideLabel : false;
            const toolTipInfo = head.toolTipInfo ? head.toolTipInfo : false;
            return (
              <Fragment key={head.name}>
                <ManageRolesTableRow
                  name={head.name}
                  label={head.label}
                  toolTipInfo={toolTipInfo}
                  checkbox={head.checkbox}
                  onChange={
                    head.options && head.options.length > 0
                      ? handleSelect
                      : handleCheckbox
                  }
                  child={head.child}
                  roleValues={roleValues}
                  options={head.options}
                  hideLabel={hideLabel}
                  infoName="companyRoleInfo"
                  roleSubscriptionLevel={subscriptionLevels}
                />
                {head.name === "userAccountAddressUpdateSetting" &&
                  roleValues.userAccountAddressUpdateSetting ===
                    "SEND_REQUEST" && (
                    <TableRow>
                      <TableCell className={classes.td}>
                        <CustomTextField
                          customClass={classes.textField}
                          label={
                            <FormattedMessage id="companyRoles.enterTheEmailAddress" />
                          }
                          value={roleValues.userAccountAddressUpdateRequestEmail}
                          onChange={handleEnterEmail}
                        />
                      </TableCell>
                    </TableRow>
                  )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Grid>
  );
};

CompanySettings.defaultProps = {
  roleValues: {},
};

CompanySettings.propTypes = {
  handleCheckbox: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  roleValues: PropTypes.shape({}),
};

export default CompanySettings;

import DateFull from '../../../components/common/FormControl/DateFull';
import { useState, useEffect } from 'react';

const DateValueEditor = ({ value, field, onUpdateTaskValue }) => {
  const [internalValue, setInternalValue] = useState();

  useEffect(() => {
    if (value && value.dateTimeValue) {
      setInternalValue(value.dateTimeValue)
    } else {
      setInternalValue(null);
    }
  }, [value])

  const handleChange = (newValue) => {
    if (newValue !== "Invalid Date") {
      onUpdateTaskValue(field.fieldId, newValue);
    }
  };

  return (<DateFull value={internalValue} allowNull label="" setDate={handleChange} />  );
};

export default DateValueEditor;
import React, { memo } from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FolderName from "./FolderName";

const useStyles = makeStyles(() => ({
  folderIcon: {
    fontSize: 14,
    color: "#5b5654",
    width: 18,
  },
  groupIconNoChild: { margin: "3px 3px 3px 29px !important" },
  groupIconWithChild: { margin: 3, marginTop: 2 },
  checkbox: { padding: "3px !important", marginLeft: "5px" },
  groupTitleSpan: {
    flexDirection: "row",
    alignItems: "flex-start",
    display: "flex",
    cursor: "pointer",
  },
  editInput: {
    backgroundColor: "#fff",
    fontSize: 11,
    height: 5,
  },
  groupContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const FolderLabel = ({
  group,
  groupId,
  groupName,
  handleCheckboxChange,
  checkedGroups,
  handleOnFolderRowClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.groupContainer}>
      <Checkbox
        color="primary"
        className={clsx([classes.checkbox, classes.groupIconWithChild])}
        checked={checkedGroups.includes(group.groupId)}
        onChange={e => handleCheckboxChange(e, group)}
        onClick={e => e.stopPropagation()}
      />
      <span
        role="presentation"
        className={clsx([classes.groupTitleSpan])}
        onClick={handleOnFolderRowClick}
      >
        <FolderName name={groupName} />
      </span>
    </div>
  );
};

FolderLabel.defaultProps = {
  groupId: null,
};

FolderLabel.propTypes = {
  groupName: PropTypes.string.isRequired,
  groupId: PropTypes.number,
  group: PropTypes.shape({
    childGroups: PropTypes.arrayOf(
      PropTypes.shape({
        folderId: PropTypes.number,
        name: PropTypes.string,
        parentId: PropTypes.number,
      })
    ),
    childGroupIds: PropTypes.arrayOf(PropTypes.number),
    groupId: PropTypes.number,
    parentFolderId: PropTypes.number,
    hasChildGroups: PropTypes.bool,
    name: PropTypes.string,
    selected: PropTypes.bool,
  }).isRequired,
  checkedGroups: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleOnFolderRowClick: PropTypes.func.isRequired,
};

export default memo(FolderLabel);

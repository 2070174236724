import PropTypes from "prop-types";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Card, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import ConsentImage from "./ConsentImage.jsx";

const StyledIconButton = styled(IconButton)(({ consented }) => ({
  bottom: 0,
  color: consented ? "#5a1432" : "#D9D9D9",
  position: "absolute",
  right: 0,
}));

const StyledConsentImage = styled(ConsentImage)(({ consented }) => ({
  border: "1px solid",
  borderColor: consented ? "#5a1432" : "#D9D9D9",
  width: "100%",
  height: "100%",
  objectFit: "none", // Crop without stretching
  objectPosition: "center center", // Center the image within the square
}));

const ConsentCard = ({ consented, imageUrl, key, onConsentChanged, readOnly, ...rest }) => (
  <Card key={key} {...rest}>
    <StyledConsentImage
      alt="thumbnail"
      consented={consented}
      imageUrl={imageUrl}
    />
    {(readOnly || onConsentChanged ) &&
      <StyledIconButton consented={consented} onClick={() => !readOnly && onConsentChanged?.()}>
        <CheckCircleOutlineIcon />
      </StyledIconButton>
    }
  </Card>
);

ConsentCard.defaultProps = {
  consented: false,
  onConsentChanged: undefined,
  readOnly: false,
};

ConsentCard.propTypes = {
  consented: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  onConsentChanged: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default ConsentCard;

import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../hooks";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Grid,
  Typography,
  Card,
  Collapse,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ADD_BOOKABLE_PRODUCT } from "../../graphql/mutations/ShoppingCartContext";
import {
  GET_BOOKING_VIEW,
  GET_AVAILABLE_PRODUCTS,
  GET_BOOKING_FOLDER_SETTINGS,
} from "../../graphql/queries";
import { cartInitialize } from "../../actions";
import { getSelectedSiteOrderGroupId } from "../../helpers/selectors";
import BookingBar from "./BookingBar";
import BookingRow from "./BookingRow";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  box: {
    padding: 20,
    minHeight: "450px",
  },
  select: {
    marginTop: 20,
  },
  selectWrapper: {
    marginTop: 15,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 800,
    margin: "20px 0",
  },
  label: {
    fontSize: 12,
    transform: "translate(14px, 13px) scale(1)",
  },
}));

const initialTime = moment()
  .set({ hours: "10", minute: "00" })
  .toDate();
const initialReturnDate = new Date(moment().add(7, "day"));

const Booking = () => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const intl = useIntl();
  const dispatch = useDispatch();
  const siteId = useSelector(state => state.ui.toggleWorkspaces.workspace.id);
  const ordergroupId = useSelector(state => getSelectedSiteOrderGroupId(state));

  const [addBookableProduct] = useMutation(ADD_BOOKABLE_PRODUCT);

  const [getBookingSettings, { data: bookingSettingsData }] = useLazyQuery(
    GET_BOOKING_FOLDER_SETTINGS
  );

  const [getBookingView, { data: bookingViewData }] = useLazyQuery(
    GET_BOOKING_VIEW
  );

  const [
    getAvailableLifts,
    { data: availableLiftsData, loading: availableLiftsLoading },
  ] = useLazyQuery(GET_AVAILABLE_PRODUCTS);

  const [buildings, setBuildings] = useState([
    {
      label: intl.formatMessage({
        id: "booking.buildingLocation",
      }),
      value: "placeholder",
      disabled: true,
    },
  ]);

  const [lifts, setLifts] = useState([
    {
      label: intl.formatMessage({ id: "booking.typeOrModel" }),
      value: "placeholder",
      disabled: true,
    },
  ]);

  const [building, setBuilding] = useState("placeholder");
  const [lift, setLift] = useState("placeholder");

  const [avialbleLiftDate, setAvailableLiftDate] = useState({});
  const [liftSchedStatus, setLiftSchedStatus] = useState("");
  const [availableLifts, setAvailableLifts] = useState([]);
  const [alternativeLifts, setAlternativeLifts] = useState([]);

  const [scheduler, setScheduler] = useState(false);

  const [pickUpDate, setPickUpDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(initialReturnDate);
  const [enteredTo, setEnteredTo] = useState(initialReturnDate);

  const [pickUpTime, setPickUpTime] = useState(initialTime);
  const [returnTime, setReturnTime] = useState(pickUpTime);

  const [bookingSettings, setBookingSettings] = useState({});
  const [pickUpTimeSetting, setPickUpTimeSetting] = useState(null);
  const [returnTimeSetting, setReturnTimeSetting] = useState(null);

  const [bookingSettingsChanged, setBookingSettingsChanged] = useState(true);

  useEffect(() => {
    if (
      bookingSettingsData &&
      bookingSettingsData.bookingContext &&
      bookingSettingsData.bookingContext.getBookingFolderSettings
    ) {
      const {
        success,
      } = bookingSettingsData.bookingContext.getBookingFolderSettings;

      if (
        success &&
        bookingSettingsData.bookingContext.getBookingFolderSettings
          .bookingFolderSettings
      ) {
        const { pickupTime: tempPickup, returnTime: tempReturn } =
          bookingSettingsData.bookingContext.getBookingFolderSettings
            .bookingFolderSettings || {};

        const splitPickUp = tempPickup.split(":");
        const splitReturn = tempReturn.split(":");

        const newPickup = moment()
          .set("hours", splitPickUp[0])
          .set("minute", splitPickUp[1])
          .toDate();
        const newReturn = moment()
          .set("hours", splitReturn[0])
          .set("minute", splitReturn[1])
          .toDate();

        const tempSettings = {
          ...bookingSettingsData.bookingContext.getBookingFolderSettings
            .bookingFolderSettings,
          pickupTime: newPickup,
          returnTime: newReturn,
        };
        setBookingSettings(tempSettings);
        setPickUpTimeSetting(newPickup);
        setReturnTimeSetting(newReturn);
      }
    }
  }, [bookingSettingsData]);

  useEffect(() => {
    if (bookingSettingsChanged && siteId) {
      getBookingSettings({ variables: { siteId } });
      clearBookingForm();
      setScheduler(false);
    }
    if (bookingSettingsChanged) {
      setBookingSettingsChanged(false);
    }
    if (siteId) {
      getBookingView({ variables: { siteid: siteId } });
    }
  }, [siteId, bookingSettingsChanged]);

  useEffect(() => {
    if (
      bookingViewData &&
      bookingViewData.bookingContext &&
      bookingViewData.bookingContext.getBookingView
    ) {
      let tempBuildings = [
        {
          label: intl.formatMessage({
            id: "booking.buildingLocation",
          }),
          value: "placeholder",
          disabled: true,
        },
      ];

      bookingViewData.bookingContext.getBookingView.buildings.forEach(x => {
        const tempValues = {
          label: x.name,
          value: x.folderId,
          childFolders: x.childFolders,
        };

        tempBuildings = [...tempBuildings, tempValues];
      });
      setBuildings(tempBuildings);
    }
  }, [bookingViewData]);

  useEffect(() => {
    if (building && building !== "placeholder") {
      const tempBuilding = buildings.find(x => x.value === building);

      let tempLifts = [
        {
          label: intl.formatMessage({ id: "booking.typeOrModel" }),
          value: "placeholder",
          disabled: true,
        },
      ];

      tempBuilding.childFolders.forEach(x => {
        const tempValues = {
          label: x.name,
          value: x.folderId,
        };
        tempLifts = [...tempLifts, tempValues];
      });

      setLifts(tempLifts);
    }
  }, [building, buildings]);

  useEffect(() => {
    if (
      availableLiftsData &&
      availableLiftsData.bookingContext &&
      availableLiftsData.bookingContext.getAvailableProducts
    ) {
      const {
        product,
        alternativeProducts,
        availableFrom,
        availableTo,
        status,
      } = availableLiftsData.bookingContext.getAvailableProducts;
      const tempAvailable = [product];
      const availDates = { availableFrom, availableTo };
      setAvailableLifts(tempAvailable);
      setAlternativeLifts(alternativeProducts);
      setAvailableLiftDate(availDates);
      setLiftSchedStatus(status);
      setScheduler(true);
    }
  }, [availableLiftsData]);

  const handleSplitTime = time => {
    return time.split(":");
  };

  const handleReturnConvertedDate = () => {
    const tempStart = moment(pickUpTime).format("HH:mm");
    const tempEnd = moment(returnTime).format("HH:mm");

    const startTime = handleSplitTime(tempStart);
    const endTime = handleSplitTime(tempEnd);

    const startDate = moment(pickUpDate).set({
      hour: startTime[0],
      minute: startTime[1],
    });
    const endDate = moment(returnDate).set({
      hour: endTime[0],
      minute: endTime[1],
    });

    return { startDate, endDate };
  };

  const handleSearch = () => {
    const dates = handleReturnConvertedDate();
    const { startDate, endDate } = dates;

    getAvailableLifts({
      variables: { productGroupfolderId: lift, from: startDate, to: endDate },
    });
  };

  const clearBookingForm = () => {
    setBuilding("placeholder");
    setLift("placeholder");
  };

  const handleBooking = async productId => {
    const dateValues = handleReturnConvertedDate();
    const { startDate: bookedFrom, endDate: bookedUntil } = dateValues;

    const item = {
      bookedFrom,
      bookedUntil,
      productId,
      quantity: 1,
      siteId,
    };
    try {
      const res = await addBookableProduct({
        variables: { ordergroupId, item },
      });

      if (
        res &&
        res.data &&
        res.data.shoppingCartContext &&
        res.data.shoppingCartContext.addBookableProduct
      ) {
        const {
          success,
          result,
        } = res.data.shoppingCartContext.addBookableProduct;

        const { groups = [] } = result || {};
        const { fileGroups, items, oneStreamGroup } = groups[0] || {};

        if (success) {
          alert.success(<FormattedMessage id="booking.liftBooked" />);
          dispatch(
            cartInitialize({
              fileGroups,
              items,
              oneStreamGroup,
            })
          );
        } else {
          snackbar.workspaceError(
            <FormattedMessage id="booking.errorBookingLift" />
          );
        }
      }
    } catch (err) {
      console.error("error >", err);
    }

    clearBookingForm();
    setScheduler(false);
  };

  const updatePlaceholder = (items, textId) => {
    const idx = items.findIndex(x => x.value === "placeholder");
    if (idx > -1) {
      items[idx].label = `${intl.formatMessage({ id: textId })}`;
    }
    return items;
  };

  return (
    <Box className={classes.root}>
      <Card className={classes.box}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography className={classes.headerText}>
                <FormattedMessage id="booking.searchDescription" />
              </Typography>
            </Box>
            <BookingBar
              building={building}
              buildings={updatePlaceholder(
                buildings,
                "booking.buildingLocation"
              )}
              enteredTo={enteredTo}
              handleSearch={handleSearch}
              lift={lift}
              lifts={updatePlaceholder(lifts, "booking.typeOrModel")}
              pickUpDate={pickUpDate}
              pickUpTime={pickUpTime}
              returnDate={returnDate}
              returnTime={returnTime}
              setBuilding={setBuilding}
              setEnteredTo={setEnteredTo}
              setLift={setLift}
              setPickUpDate={setPickUpDate}
              setPickUpTime={setPickUpTime}
              setReturnDate={setReturnDate}
              setReturnTime={setReturnTime}
              siteId={siteId}
              pickUpTimeSetting={pickUpTimeSetting}
              returnTimeSetting={returnTimeSetting}
              bookingSettings={bookingSettings}
              setBookingSettingsChanged={setBookingSettingsChanged}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {availableLiftsLoading && (
              <CircularProgress size={50} className={classes.select} />
            )}
            {!availableLiftsLoading && (
              <Collapse in={scheduler}>
                <BookingRow
                  alternativeLifts={alternativeLifts}
                  availableLifts={availableLifts}
                  avialbleLiftDate={avialbleLiftDate}
                  handleBooking={handleBooking}
                  liftSchedStatus={liftSchedStatus}
                />
              </Collapse>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default Booking;

import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import { GET_ADDRESS_FOR_USER } from "../../../../graphql/queries/MonitoringContext";
import DeliveryInfo from "../../../Checkout/DeliveryInfo";
import { omitTypename } from "../../../../helpers/get";
import {
  addressFieldsValidator,
  objectShallowCompare,
} from "../../../../helpers/validators";

const useStyles = makeStyles(() => ({
  root: {
    padding: "15px 10px",
    border: "1px solid #efefef",
    borderTop: "none",
    "& > div > .MuiBox-root": {
      padding: 0,
    },
  },
}));

const ReceiversSection = ({
  addressCombinationsOptions,
  addressCombinations,
  shoppingCartSettings,
  receivers,
  setReceivers,
  index,
  monitoringItemId,
  recipientTabSettings,
  setReceiversAddressErrors,
  receiversAddressErrors,
}) => {
  const classes = useStyles();
  const {
    showDeliveryAddress = false,
    showInvoiceAddress = false,
    showOrdererAddress = false,
  } = recipientTabSettings || {};
  const receiver = receivers[index] || {};
  const {
    selectedAddressField,
    deliveryAddress,
    postalAddress,
    invoiceAddress,
    deliveryAddressChanged,
    userId,
  } = receiver || {};

  const [fetchUserAddress, { data: userAddress }] = useLazyQuery(
    GET_ADDRESS_FOR_USER
  );

  const [deliveryAddressFieldsError, setDeliveryAddressFieldsError] = useState(
    {}
  );

  const [postalAddressFieldsError, setPostalAddressFieldsError] = useState({});

  const [invoiceAddressFieldsError, setInvoiceAddressFieldsError] = useState(
    {}
  );

  const setNewSelectedAddress = value => {
    const newReceivers = [...receivers];
    newReceivers[index] = {
      ...newReceivers[index],
      selectedAddressField: value,
    };
    setReceivers(newReceivers);
  };

  const setAllAddress = allAddress => {
    const newReceivers = [...receivers];
    newReceivers[index] = {
      ...newReceivers[index],
      ...allAddress,
      deliveryAddressChanged: false,
    };
    setReceivers(newReceivers);
  };

  const setDeliveryAddress = value => {
    const newReceivers = [...receivers];
    newReceivers[index] = {
      ...newReceivers[index],
      deliveryAddress: { ...value },
    };
    setReceivers(newReceivers);
  };

  const setInvoiceAddress = value => {
    const newReceivers = [...receivers];
    newReceivers[index] = {
      ...newReceivers[index],
      invoiceAddress: { ...value },
    };
    setReceivers(newReceivers);
  };

  const setPostalAddress = value => {
    const newReceivers = [...receivers];
    newReceivers[index] = {
      ...newReceivers[index],
      postalAddress: { ...value },
    };
    setReceivers(newReceivers);
  };

  const setDeliveryAddressChanged = checked => {
    const newReceivers = [...receivers];
    newReceivers[index] = {
      ...newReceivers[index],
      deliveryAddressChanged: checked,
    };
    setReceivers(newReceivers);
  };

  const handleChangeDeliveryAddress = e => {
    const { checked } = e.target;

    if (checked) {
      const newReceivers = [...receivers];
      newReceivers[index] = {
        ...newReceivers[index],
        deliveryAddressChanged: checked,
        deliveryAddress: {
          ...deliveryAddress,
          address1: null,
          address2: null,
          address3: null,
          city: null,
          contact: null,
          country: null,
          customerId: null,
          reference: null,
          zip: null,
        },
      };
      setReceivers(newReceivers);
    } else if (!checked && selectedAddressField && addressCombinations) {
      const addressValue = addressCombinations.find(
        x => x.label === selectedAddressField
      );

      if (addressValue) {
        const { deliveryAddress: delivery } = addressValue;

        const deliveryAddressNoTypename = JSON.parse(
          JSON.stringify(delivery),
          omitTypename
        );

        if (deliveryAddressNoTypename) {
          const newReceivers = [...receivers];
          newReceivers[index] = {
            ...newReceivers[index],
            deliveryAddressChanged: checked,
            deliveryAddress: {
              ...deliveryAddressNoTypename,
            },
          };
          setReceivers(newReceivers);
        }
      }
    }
  };

  const handleAddressChange = event => {
    setNewSelectedAddress(event.target.value);
  };

  const handleAddressFieldChange = ({ fieldName, fieldValue, addressType }) => {
    if (addressType === "deliveryAddress") {
      setDeliveryAddress({
        ...deliveryAddress,
        [fieldName]: fieldValue,
      });
    }
    if (addressType === "postalAddress") {
      setPostalAddress({
        ...postalAddress,
        [fieldName]: fieldValue,
      });
    }
    if (addressType === "invoiceAddress") {
      setInvoiceAddress({
        ...invoiceAddress,
        [fieldName]: fieldValue,
      });
    }
  };

  // useEffect(() => {
  //   if (userId) {
  //     fetchUserAddress({ variables: { userId } });
  //   }
  // }, [userId]);

  // useEffect(() => {
  //   if (
  //     userAddress &&
  //     userAddress.monitoringContext &&
  //     userAddress.monitoringContext.getAddressForUser
  //   ) {
  //     const {
  //       deliveryAddress: delivery,
  //       invoiceAddress: invoice,
  //       postalAddress: postal,
  //     } = userAddress.monitoringContext.getAddressForUser;

  //     const allAddress = {
  //       deliveryAddress: delivery,
  //       invoiceAddress: invoice,
  //       postalAddress: postal,
  //     };

  //     setAllAddress(allAddress);
  //   }
  // }, [userAddress]);

  // useEffect(() => {
  //   if (
  //     !selectedAddressField &&
  //     addressCombinationsOptions &&
  //     addressCombinationsOptions[0] &&
  //     !monitoringItemId
  //   ) {
  //     setNewSelectedAddress(addressCombinationsOptions[0].value);
  //   }
  // }, [selectedAddressField, addressCombinationsOptions, monitoringItemId]);

  useEffect(() => {
    const invoiceFieldErrors = addressFieldsValidator({
      address: invoiceAddress,
      addressSetting: shoppingCartSettings.invoiceAddressSettings,
    });

    if (!isEmpty(invoiceFieldErrors)) {
      setInvoiceAddressFieldsError({ ...invoiceFieldErrors });
    } else {
      setInvoiceAddressFieldsError({});
    }
  }, [invoiceAddress, shoppingCartSettings.invoiceAddressSettings]);

  useEffect(() => {
    const deliveryFieldErrors = addressFieldsValidator({
      address: deliveryAddress,
      addressSetting: shoppingCartSettings.deliveryAddressSettings,
    });

    if (!isEmpty(deliveryFieldErrors)) {
      setDeliveryAddressFieldsError({ ...deliveryFieldErrors });
    } else {
      setDeliveryAddressFieldsError({});
    }
  }, [deliveryAddress, shoppingCartSettings.deliveryAddressSettings]);

  useEffect(() => {
    const postalFieldErrors = addressFieldsValidator({
      address: postalAddress,
      addressSetting: shoppingCartSettings.postalAddressSettings,
    });
    if (!isEmpty(postalFieldErrors)) {
      setPostalAddressFieldsError({ ...postalFieldErrors });
    } else {
      setPostalAddressFieldsError({});
    }
  }, [postalAddress, shoppingCartSettings.postalAddressSettings]);

  useEffect(() => {
    const tempValue = [...receiversAddressErrors];
    if (
      !isEmpty(deliveryAddressFieldsError) ||
      !isEmpty(postalAddressFieldsError) ||
      !isEmpty(invoiceAddressFieldsError)
    ) {
      tempValue[index] = { ...tempValue[index], errors: true };
    } else {
      tempValue[index] = { ...tempValue[index], errors: false };
    }
    setReceiversAddressErrors(tempValue);
  }, [
    deliveryAddressFieldsError,
    postalAddressFieldsError,
    invoiceAddressFieldsError,
  ]);

  // useEffect(() => {
  //   if (selectedAddressField && addressCombinations) {
  //     const addressValue = addressCombinations.find(
  //       x => x.label === selectedAddressField
  //     );
  //     if (addressValue) {
  //       const {
  //         deliveryAddress: delivery,
  //         postalAddress: postal,
  //         invoiceAddress: invoice,
  //       } = addressValue;

  //       const deliveryAddressNoTypename = JSON.parse(
  //         JSON.stringify(delivery),
  //         omitTypename
  //       );
  //       const invoiceAddressNoTypename = JSON.parse(
  //         JSON.stringify(postal),
  //         omitTypename
  //       );
  //       const postalAddressNoTypename = JSON.parse(
  //         JSON.stringify(invoice),
  //         omitTypename
  //       );
  //       let allAddress = {};
  //       if (deliveryAddressNoTypename) {
  //         allAddress = {
  //           ...allAddress,
  //           deliveryAddress: { ...deliveryAddressNoTypename },
  //         };
  //       }
  //       if (invoiceAddressNoTypename) {
  //         allAddress = {
  //           ...allAddress,
  //           invoiceAddress: { ...invoiceAddressNoTypename },
  //         };
  //       }
  //       if (postalAddressNoTypename) {
  //         allAddress = {
  //           ...allAddress,
  //           postalAddress: { ...invoiceAddressNoTypename },
  //         };
  //       }
  //       setAllAddress(allAddress);
  //     }
  //   }
  // }, [selectedAddressField, addressCombinations]);

  useEffect(() => {
    if (deliveryAddress && selectedAddressField && addressCombinations) {
      const addressValue = addressCombinations.find(
        x => x.label === selectedAddressField
      );

      if (addressValue) {
        const { deliveryAddress: delivery } = addressValue;

        const deliveryAddressNoTypename = JSON.parse(
          JSON.stringify(delivery),
          omitTypename
        );

        const sameObject = objectShallowCompare(
          deliveryAddress,
          deliveryAddressNoTypename
        );
        setDeliveryAddressChanged(!sameObject);
      }
    }
  }, [deliveryAddress]);

  return (
    <Box className={classes.root}>
      <DeliveryInfo
        handleChangeDeliveryAddress={handleChangeDeliveryAddress}
        deliveryAddressChanged={deliveryAddressChanged}
        invoiceAddressFieldsError={invoiceAddressFieldsError}
        postalAddressFieldsError={postalAddressFieldsError}
        deliveryAddressFieldsError={deliveryAddressFieldsError}
        addressOptions={addressCombinationsOptions}
        deliveryAddress={deliveryAddress}
        handleAddressChange={handleAddressChange}
        handleAddressFieldChange={handleAddressFieldChange}
        handleRemoveAllProducts={() => {}}
        invoiceAddress={invoiceAddress}
        postalAddress={postalAddress}
        selectedAddressField={selectedAddressField}
        shoppingCartSettings={shoppingCartSettings}
        showDeliveryAddress={showDeliveryAddress}
        showInvoiceAddress={showInvoiceAddress}
        showOrdererAddress={showOrdererAddress}
        hideEmptyCartButton
        hideAddressDropdown
      />
    </Box>
  );
};

ReceiversSection.defaultProps = {
  monitoringItemId: null,
  recipientTabSettings: {
    showDeliveryAddress: false,
    showInvoiceAddress: false,
    showOrdererAddress: false,
  },
  receiversAddressErrors: [],
};

ReceiversSection.propTypes = {
  recipientTabSettings: PropTypes.shape({
    showDeliveryAddress: PropTypes.bool,
    showInvoiceAddress: PropTypes.bool,
    showOrdererAddress: PropTypes.bool,
  }),
  monitoringItemId: PropTypes.number,
  setReceivers: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  shoppingCartSettings: PropTypes.shape({
    deliveryAddressSettings: PropTypes.shape({}),
    postalAddressSettings: PropTypes.shape({}),
    invoiceAddressSettings: PropTypes.shape({}),
  }).isRequired,
  addressCombinationsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  addressCombinations: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired })
  ).isRequired,
  receivers: PropTypes.arrayOf(
    PropTypes.shape({
      deliveryAddress: PropTypes.shape({}).isRequired,
      invoiceAddress: PropTypes.shape({}).isRequired,
      postalAddress: PropTypes.shape({}).isRequired,
      selectedAddressField: PropTypes.string,
    })
  ).isRequired,
  setReceiversAddressErrors: PropTypes.func.isRequired,
  receiversAddressErrors: PropTypes.arrayOf(PropTypes.shape({})),
};

export default memo(ReceiversSection);

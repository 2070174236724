import React, { useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import {  useMutation } from "@apollo/client";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import UQFileUploader from "../../../common/UQFileUploader";
import { INIT_COMPANY_MAIL_LOGO_UPLOAD } from "../../../../graphql/mutations";

const useStyles = makeStyles(theme => ({
  uploadBox: {
    width: "fit-content",
    "& button": {
      marginRight: 0,
      marginTop: -2,
    },
  },
}));

const CompanyMailLogoUpload = ({ companyId, onUploaded }) => {
  const classes = useStyles();
  const alert = useAlert();
  const targetCompanyIdRef = useRef();

  const [initUpload] = useMutation(INIT_COMPANY_MAIL_LOGO_UPLOAD);

  useEffect(() => {
    targetCompanyIdRef.current = companyId;
  }, [companyId]);

  const handleFilesSelected = async (files, done) => {
    const signalDone = () => {
      if (!!done) {
        done();
      }
      if (!!onUploaded) {
        onUploaded();
      }
    };

    if (files?.length !== 1) {
      console.error("Expected a single file.");
      alert.error(
        <FormattedMessage id="admin.companySettings.uploadError.expectedSingleFile" />
      );
      signalDone();
      return;
    }

    let file = files[0];
    let targetCompanyId = targetCompanyIdRef.current;
    let uploadUrl;

    console.info(`Initializing upload (target company: ${targetCompanyId}).`);

    try {
      const response = await initUpload({
        variables: { targetCompanyId },
      });

      const result =
        response.data.companyAdminContext.settings.initCompanyMailLogoUpload;
      const { success: uploadInitialized } = result;

      if (uploadInitialized) {
        uploadUrl = result.url;
      } else {
        console.error(
          `Could not initialize upload (${result.errorResult?.errorMessage ??
            "Unknown error"}).`
        );
        alert.error(
          <FormattedMessage id="admin.companySettings.uploadError" />
        );
        signalDone();
        return;
      }
    } catch (err) {
      console.error("Error while uploading.", err);
      alert.error(<FormattedMessage id="admin.companySettings.uploadError" />);
      signalDone();
      return;
    }

    console.info(`Uploading file (target company: ${targetCompanyId}).`);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(`${uploadUrl}`, formData);
      const { success: fileUploaded, message } = response.data;

      if (fileUploaded) {
        console.info(message);
        alert.info(
          <FormattedMessage id="admin.companySettings.uploadSuccess" />
        );
      } else {
        console.error(message);
        alert.error(
          <FormattedMessage id="admin.companySettings.uploadError" />
        );
      }
    } catch (err) {
      console.error("Error while uploading.", err);
      alert.error(<FormattedMessage id="admin.companySettings.uploadError" />);
    }

    signalDone();
  };

  var eventHandlers = {
    filesSelected: handleFilesSelected,
    systemFilesSelected: undefined,
  };

  return (
    <Box className={classes.uploadBox}>
      <UQFileUploader
        acceptedFiles=".png"
        compactDesign
        eventHandlers={eventHandlers}
      />
    </Box>
  );
};

export default CompanyMailLogoUpload;

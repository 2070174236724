import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Icon } from "@mui/material";
import { validateEmail } from "../../../helpers/validators";
import { TextField as TextFieldCustom } from "../../common";
import DraggableModal, {
  DraggableModalHead,
  DraggableModalBody,
} from "../../common/DraggableModal";
import SearchSupplierField from "./SearchSupplierField";

const useStyles = makeStyles(theme => ({
  modal: {
    maxHeight: "95%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    marginTop: 20,
  },
  btn: {
    padding: "8px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  backIcon: {
    fontSize: 14,
  },
  footer: {
    paddingTop: 10,
  },
  buttonEmail: { textTransform: "none" },
}));

const STEP_ONE = "STEP_ONE";
const STEP_TWO = "STEP_TWO";

const requiredFieldsError = ({ supplier = {} }) => {
  const { supplierEmail, supplierName } = supplier || {};

  if (!supplierEmail || supplierEmail === "") return true;
  if (!supplierName || supplierName === "") return true;
  return false;
};

const AddSupplierModal = ({ open, handleClose, onSendClick }) => {
  const intl = useIntl();
  const classes = useStyles();
  const [supplier, setSupplier] = useState({});
  const [supplierSelect, setSupplierSelect] = useState({});
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(STEP_ONE);

  const handleChange = e => {
    const { name, value } = e.target;
    setSupplier({ ...supplier, [name]: value });
    if (name === "supplierEmail") {
      if (!validateEmail(value)) {
        setErrors({
          ...errors,
          [name]: intl.formatMessage({
            id: "common.invalidEmail",
          }),
        });
      } else if (!value || value === "") {
        setErrors({
          ...errors,
          [name]: intl.formatMessage({
            id: "context.required",
          }),
        });
      } else {
        const newErrors = { ...errors };
        delete newErrors.supplierEmail;
        setErrors({ ...newErrors });
      }
    } else if (name === "supplierName") {
      if (!value || value === "") {
        setErrors({
          ...errors,
          [name]: intl.formatMessage({
            id: "context.required",
          }),
        });
      } else {
        const newErrors = { ...errors };
        delete newErrors.supplierName;
        setErrors({ ...newErrors });
      }
    }
  };

  const handleSendClick = () => {
    onSendClick({ item: supplier });
  };

  useEffect(() => {
    if (!open) {
      setSupplierSelect({});
      setSupplier({});
    }
  }, [open]);

  const handleSearchChange = (value = {}) => {
    if (typeof value === "string" || value instanceof String) {
      setSupplier({ supplierEmail: value });
      setSupplierSelect({});
    } else {
      setSupplier(value || {});
      setSupplierSelect(value || {});
    }
  };

  const handleSearchTextChange = text => {
    setSupplier({ supplierEmail: text });
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="supplier.addSupplierWorkspace" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <Grid
          container
          direction="column"
          style={{ height: 250 }}
          justifyContent="space-between"
        >
          {step === STEP_ONE && (
            <>
              <Grid item>
                <SearchSupplierField
                  onSearchChange={handleSearchChange}
                  value={supplierSelect}
                  handleSearchTextChange={handleSearchTextChange}
                />
              </Grid>
              <Grid item className={classes.footer}>
                <Button
                  className={classes.btn}
                  fullWidth
                  onClick={() => setStep(STEP_TWO)}
                >
                  <FormattedMessage id="btn.next" />
                  <Icon
                    className={clsx([
                      "fa fa-long-arrow-alt-right",
                      classes.btnIcon,
                    ])}
                  />
                </Button>
              </Grid>
            </>
          )}
          {step === STEP_TWO && (
            <>
              <Grid item>
                <Button
                  onClick={() => setStep(STEP_ONE)}
                  className={classes.buttonEmail}
                  startIcon={
                    <Icon
                      className={clsx([
                        "fa fa-long-arrow-alt-left",
                        classes.backIcon,
                      ])}
                    />
                  }
                >
                  {supplier && supplier.supplierEmail
                    ? supplier.supplierEmail
                    : "Back"}
                </Button>
              </Grid>
              <Grid item>
                <TextFieldCustom
                  label={<FormattedMessage id="common.name" />}
                  name="supplierName"
                  value={supplier ? supplier.supplierName : ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextFieldCustom
                  label={<FormattedMessage id="supplier.supplierEmail" />}
                  name="supplierEmail"
                  onChange={handleChange}
                  type="email"
                  value={supplier ? supplier.supplierEmail : ""}
                  error={errors.supplierEmail}
                  helperText={errors.supplierEmail}
                />
              </Grid>
              <Grid item>
                <TextFieldCustom
                  label={<FormattedMessage id="supplier.supplierId" />}
                  disabled
                  value={supplier ? supplier.supplierId : ""}
                />
              </Grid>
              <Grid item className={classes.footer}>
                <Button
                  className={classes.btn}
                  fullWidth
                  onClick={handleSendClick}
                  disabled={
                    !isEmpty(errors) || requiredFieldsError({ supplier })
                  }
                >
                  <FormattedMessage id="btn.send" />
                  <Icon
                    className={clsx(["fa fa-paper-plane", classes.btnIcon])}
                  />
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DraggableModalBody>
    </DraggableModal>
  );
};

AddSupplierModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSendClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AddSupplierModal;

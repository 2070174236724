import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { UPDATE_FOLDER_SITE_ROLE_RIGHTS } from "../../../graphql/mutations";
import FolderPermissionsTable from "./FolderPermissionsTable";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import { useGetFolderSiteRoleRights, useSnackbar } from "../../../hooks";
import FolderName from "./FolderName";
import SameSettings from "./SameSettings";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    backgroundColor: theme.palette.common.white,
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "4px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  tableWrap: {
    backgroundColor: theme.palette.background.lightGray,
    padding: 10,
  },
}));

const FolderPermissionsModal = ({ open, handleClose, folderId, name }) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const [siteRoleRights, setSiteRoleRights] = useState([]);
  const [folderSameSettings, setFolderSameSettings] = useState(false);
  const [updateFolderSiteRoleRights] = useMutation(
    UPDATE_FOLDER_SITE_ROLE_RIGHTS
  );

  const { getFolderSiteRoleRights, data, loading } = useGetFolderSiteRoleRights(
    {
      folderId,
    }
  );

  useEffect(() => {
    if (open && folderId) {
      getFolderSiteRoleRights();
    }
  }, [open, folderId]);

  useEffect(() => {
    if (
      data &&
      data.folderContext &&
      data.folderContext.getFolderSiteRoleRights &&
      data.folderContext.getFolderSiteRoleRights.siteRoleAccessRights &&
      Array.isArray(
        data.folderContext.getFolderSiteRoleRights.siteRoleAccessRights
      ) &&
      data.folderContext.getFolderSiteRoleRights.siteRoleAccessRights.length > 0
    ) {
      setSiteRoleRights(
        data.folderContext.getFolderSiteRoleRights.siteRoleAccessRights
      );
    }
  }, [data]);

  const handleSaveRoleRights = async () => {
    const siteRoleAccessRights = siteRoleRights.map(x => {
      return {
        readAccess: x.readAccess,
        siteRoleId: x.siteRoleId,
        writeAccess: x.writeAccess,
      };
    });

    const input = {
      folderId,
      siteRoleAccessRights,
      updateSubFolders: folderSameSettings,
    };
    try {
      const res = await updateFolderSiteRoleRights({ variables: { input } });
      if (
        res &&
        res.data &&
        res.data.folderContext &&
        res.data.folderContext.updateSiteRoleRights
      ) {
        const { success } = res.data.folderContext.updateSiteRoleRights;
        if (success) {
          alert.success(<FormattedMessage id="siteRoles.updatedSiteRoles" />);
        } else {
          snackbar.workspaceError(
            <FormattedMessage id="failedToUpdateSiteRoles" />
          );
        }
        handleClose();
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="context.manageFolderPermissions" />}
      />
      <DraggableModalBody className={classes.modalBody}>
        <FolderName name={name} />
        <SameSettings
          folderSameSettings={folderSameSettings}
          setFolderSameSettings={setFolderSameSettings}
        />
        <Box className={classes.tableWrap}>
          <FolderPermissionsTable
            siteRoleRights={siteRoleRights}
            setSiteRoleRights={setSiteRoleRights}
            loading={loading}
          />
        </Box>
        <Box className={classes.modalFooter} align="right">
          <Button
            className={classes.btn}
            onClick={() => handleSaveRoleRights()}
          >
            <FormattedMessage id="btn.save" />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

FolderPermissionsModal.defaultProps = {
  folderId: null,
  name: "",
};

FolderPermissionsModal.propTypes = {
  name: PropTypes.string,
  folderId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FolderPermissionsModal;

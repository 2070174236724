import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../../../hooks";
import logger from "../../../../../../../helpers/logger";
import CustomSelect from "../../../../../../common/CustomSelect";
import { PrimaryButton } from "../../../../../../common/Buttons";
import { GET_FILES } from "../../../../../../../graphql/queries";
import {
  DELETE_FILE,
  FILE_UPLOAD,
} from "../../../../../../../graphql/mutations/DynamicTemplateEditContext";
import FileItems from "./FileItems";
import FilesRow from "./FilesRow";
import EditorModal from "./EditorModal";
import ConfirmDeleteModal from "../../../../../../common/ConfirmDeleteModal";

const useStyles = makeStyles(theme => ({
  contentCont: {
    padding: 13,
  },
  marginBottom: { marginBottom: 15 },
  label: {
    fontWeight: 800,
    color: theme.palette.text.lightGray,

    "& span": {
      marginLeft: 7,
      fontWeight: "normal",
      fontSize: 11,
    },
    marginBottom: 10,
  },
  filesCont: {
    border: `1px solid ${theme.palette.text.lightGray}`,
    borderRadius: 5,
    minHeight: 150,
    padding: "0 10px",
    overflowY: "auto",
    maxHeight: "400px",
  },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "5px 15px",
    backgroundColor: "#66615B",
    color: "#fff",
  },
  fieldWrap: {
    padding: 7,
    fontSize: 12,
    border: `1px solid ${theme.palette.text.lightGray}`,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
  },
  uploadDesc: {
    fontSize: 11,
    fontStyle: "italic",
  },
  instructionCont: {
    marginTop: 20,
  },
  instructionHead: {
    fontWeight: 800,
    fontSize: 11,
  },
  instructionDesc: {
    fontSize: 11,
  },
  imgBox: {
    backgroundColor: "#f9f9f9",
    padding: 15,
    marginBottom: 10,
  },
  chips: {
    marginLeft: "10px",
    "& .MuiChip-label": {
      maxWidth: "300px!important",
    },
  },
}));

const Files = ({ resourceId, image }) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();

  const [deleteModal, setDeleteModal] = useState(false);
  const [editorModal, setEditorModal] = useState(false);

  const [deleteFile, { loading: deletingFile }] = useMutation(DELETE_FILE);
  const [fileUpload] = useMutation(FILE_UPLOAD);
  const [getFiles, { data: filesData }] = useLazyQuery(GET_FILES);

  const [fileToDelete, setFileToDelete] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [files, setFiles] = useState([]);
  const [displayFile, setDisplayFile] = useState("");
  const [orderFile, setOrderFile] = useState("");

  const [editFileName, setEditFileName] = useState("");

  useEffect(() => {
    getFiles({ variables: { productId: resourceId } });
  }, [resourceId]);

  useEffect(() => {
    if (
      filesData &&
      filesData.dynamicTemplateEditContext &&
      filesData.dynamicTemplateEditContext.getFiles
    ) {
      const { files } = filesData.dynamicTemplateEditContext.getFiles;
      if (Array.isArray(files) && files.length > 0) {
        setFiles(files);
      }
    }
  }, [filesData]);

  const caseInsensitiveComparison = (a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "base" });

  const handleDeleteFile = async file => {
    function displayError() {
      snackbar.workspaceError(
        <FormattedMessage id="editProduct.errorDeletingFile" />
      );
    }

    try {
      const res = await deleteFile({
        variables: {
          productId: resourceId,
          file,
        },
      });

      if (
        res &&
        res.data &&
        res.data.dynamicTemplateEditContext &&
        res.data.dynamicTemplateEditContext.deleteFile
      ) {
        alert.success(<FormattedMessage id="editProduct.deletedFile" />);
        var tempFiles = files.filter(
          n => caseInsensitiveComparison(file, n) !== 0
        );
        setFiles(tempFiles);
      } else {
        displayError();
      }
    } catch (err) {
      console.error("error >", err);
      displayError();
    }
  };

  const handleOpenDeleteModal = file => {
    setFileToDelete(file);
    setDeleteModal(true);
  };

  const handleConfirmDeleteFile = async () => {
    await handleDeleteFile(fileToDelete);
    setFileToDelete(null);
    setDeleteModal(false);
  };

  const handleUploadFile = async () => {
    function displayError() {
      snackbar.workspaceError(<FormattedMessage id="fileupload.fail" />);
    }

    try {
      setLoadingUpload(true);
      const res = await fileUpload({ variables: { productId: resourceId } });

      if (
        res &&
        res.data &&
        res.data.dynamicTemplateEditContext &&
        res.data.dynamicTemplateEditContext.initFileUpload
      ) {
        const uploadUrl = res.data.dynamicTemplateEditContext.initFileUpload;

        try {
          const formData = new FormData();
          formData.append("file", uploadedFiles[0]);
          const response = await axios.post(`${uploadUrl}`, formData);

          if (
            response &&
            response.data &&
            response.data.result &&
            response.data.result.success &&
            response.status === 200
          ) {
            logger.log(`Success uploading file.`);
            alert.success(<FormattedMessage id="fileupload.success" />);
            const newFile = uploadedFiles[0].name;
            handleFileCreated(newFile);
            setUploadedFiles([]);
          } else {
            logger.error(
              `An error occurred while uploading the file. ${JSON.stringify(
                response
              )}`
            );
            displayError();
          }
          setLoadingUpload(false);
        } catch (uploadException) {
          setLoadingUpload(false);
          logger.error(
            `An error occurred while uploading the file. ${JSON.stringify(
              uploadException
            )}`
          );
          displayError();
        }
      } else {
        displayError();
      }
    } catch (err) {
      console.error("error >", err);
      displayError();
    }
  };

  const handleOnSelectUploadFile = async event => {
    const { target } = event || {};
    const { files } = target || {};
    if (files && files[0]) {
      setUploadedFiles([files[0]]);
    } else {
      console.error("error selecting file");
    }
  };

  const handleCloseEditorModal = () => {
    setEditFileName("");
    setEditorModal(false);
  };

  const handleEditFile = name => {
    setEditFileName(name);
    setEditorModal(true);
  };

  const handleFileCreated = file => {
    var filenameExists = files.some(
      n => caseInsensitiveComparison(file, n) === 0
    );
    if (filenameExists === false) {
      var tempFiles = [...files, file].sort((a, b) =>
        caseInsensitiveComparison(a, b)
      );
      setFiles(tempFiles);
    }
  };

  return (
    <Grid container>
      <Grid item sm={12} md={6} lg={8} className={classes.contentCont}>
        {/* commented out  temporarily */}
        {/* <Box className={classes.marginBottom}>
          <Typography className={classes.label}>
            Visningsfil
            <span>Fil som används till visning och förhandsgrandskning</span>
          </Typography>
          <CustomSelect
            options={files}
            value={displayFile}
            onChange={e => setDisplayFile(e.target.value)}
            disabled
          />
        </Box> */}

        {/* commented out  temporarily */}
        {/* 
        <Box className={classes.marginBottom}>
          <Typography className={classes.label}>
            Beställningsfil
            <span>Fil som skickas till leverantören vid beställning</span>
          </Typography>
          <CustomSelect
            options={files}
            value={orderFile}
            onChange={e => setOrderFile(e.target.value)}
            disabled
          />
        </Box> */}

        <Box className={classes.marginBottom}>
          <Typography className={classes.label}>
            <FormattedMessage id="editProduct.files" />
          </Typography>
          <Box className={classes.filesCont}>
            {files.map((file, idx) => {
              return (
                <FilesRow
                  key={`${idx}-${file}`}
                  file={file}
                  productId={resourceId}
                  onDeleteFile={handleOpenDeleteModal}
                  onEditFile={handleEditFile}
                />
              );
            })}
          </Box>

          <PrimaryButton marginTop={10} onClick={() => setEditorModal(true)}>
            <FormattedMessage id="editProduct.createNewFile" />
          </PrimaryButton>
        </Box>

        {/* commented out  temporarily */}
        {/* <Box className={classes.marginBottom}>
          <Typography className={classes.label}>
            Bilagor
            <span>
              Filer som bifogas när artikeln laddas ned eller beställs
            </span>
          </Typography>
          <CustomSelect
            options={files}
            value={orderFile}
            onChange={e => setOrderFile(e.target.value)}
          />
        </Box> */}

        <Box className={classes.marginBottom}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box className={classes.fieldWrap}>
                <Button
                  variant="contained"
                  className={classes.uploadButton}
                  component="label"
                >
                  <FormattedMessage id="product.selectFile" />
                  {loadingUpload ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : (
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleOnSelectUploadFile}
                    />
                  )}
                </Button>

                {uploadedFiles && uploadedFiles.length > 0 && (
                  <div className={classes.chips}>
                    {uploadedFiles.map(x => (
                      <Chip
                        key={x.name}
                        label={x.name}
                        // onDelete={() => handleRemoveUploadedFile(x)}
                        color="primary"
                        size="small"
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              </Box>
              <Typography className={classes.uploadDesc}>
                <FormattedMessage id="editProduct.willBeReplaced" />
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <PrimaryButton
                marginTop={10}
                onClick={handleUploadFile}
                disabled={
                  loadingUpload || (uploadedFiles && uploadedFiles.length === 0)
                }
              >
                <FormattedMessage id="editProduct.upload" />
              </PrimaryButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item sm={12} md={6} lg={4} className={classes.contentCont}>
        <Box className={classes.imgBox} align="center">
          <img src={image} alt="Product" />
        </Box>
      </Grid>

      <Grid container>
        <Grid item sm={12} md={6} lg={9} className={classes.contentCont}>
          {/* <Box className={classes.instructionCont}>
            <Typography className={classes.instructionHead}>
              Instruktioner
            </Typography>
            <Typography className={classes.instructionDesc}>
              Dra och släpp en fil från din dator till området Visningsfil,
              Beställningsfil, Filer eller Bilagor. Du kan i efterhand dra och
              släppa en fil mellan olika områden.
            </Typography>
          </Box> */}
        </Grid>
        <Grid item sm={12} md={6} lg={3} className={classes.contentCont}>
          <PrimaryButton fullWidth marginTop={10}>
            <FormattedMessage id="btn.exportTemplate" />
          </PrimaryButton>
          {/* <PrimaryButton fullWidth marginTop={10}>
            <FormattedMessage id="btn.saveTemplate" />
          </PrimaryButton> */}
        </Grid>
      </Grid>
      {editorModal && (
        <EditorModal
          filename={editFileName}
          handleClose={handleCloseEditorModal}
          onFileCreated={handleFileCreated}
          open={editorModal}
          productId={resourceId}
        />
      )}
      {deleteModal && (
        <ConfirmDeleteModal
          open={deleteModal}
          loading={deletingFile}
          setOpen={setDeleteModal}
          onConfirmCloseModal={handleConfirmDeleteFile}
        />
      )}
    </Grid>
  );
};

export default Files;

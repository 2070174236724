import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles(theme => ({
  buttonLang: {
    padding: "4px 10px 4px 15px",
    lineHeight: 0.5,
    color: "#fff",
    fontWeight: 600,
    fontSize: 11,
    backgroundColor: alpha(theme.palette.common.grey, 0.15),
    minWidth: "unset",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  caret: {
    width: "13px !important",
    height: "13px !important",
  },
}));

const LanguageSelector = ({
  locale,
  handleLang,
  anchorLang,
  handleCloseLang,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.rootLang}>
      <Button
        data-cy="language-selector"
        className={classes.buttonLang}
        onClick={handleLang}
      >
        {locale.lang}
        <ArrowDropDown className={classes.caret} />
      </Button>
      <Menu
        anchorEl={anchorLang}
        keepMounted
        open={Boolean(anchorLang)}
        onClose={handleCloseLang}
      >
        <MenuItem
          data-cy="SV-language"
          onClick={() => handleCloseLang({ name: "SV" })}
        >
          SV
        </MenuItem>
        <MenuItem
          data-cy="EN-language"
          onClick={() => handleCloseLang({ name: "EN" })}
        >
          EN
        </MenuItem>
      </Menu>
    </Box>
  );
};

LanguageSelector.defaultProps = {
  handleLang: null,
  anchorLang: {},
  handleCloseLang: null,
  locale: null,
};

LanguageSelector.propTypes = {
  handleLang: PropTypes.func,
  anchorLang: PropTypes.shape({}),
  handleCloseLang: PropTypes.func,
  locale: PropTypes.shape({
    lang: PropTypes.string,
  }),
};

export default LanguageSelector;

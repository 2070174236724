import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import WorkspaceSelector from "../../../common/WorkspaceSelector";

const WorkspaceLinkWidgetForm = ({ onWidgetPropertyUpdate, workspace }) => {
  const { currentViewer } = useSelector(state => state.api);
  const { items } = currentViewer.viewer.structure;

  const [open, setOpen] = useState(false);

  const handleWorkspaceSelected = val => {
    if (val?.siteId) {
      onWidgetPropertyUpdate("workspace", {
        description: val.description,
        icon: val.icon,
        iconColor: val.iconColor,
        name: val.name,
        siteId: val.siteId,
      });
      setOpen(false);
    }
  };

  return (
    <Box>
      <WorkspaceSelector
        handleToggle={() => setOpen(!open)}
        onClick={handleWorkspaceSelected}
        open={open}
        searchable
        setOpen={setOpen}
        workspaceName={workspace?.name || ""}
        workspaces={items}
      />
    </Box>
  );
};

WorkspaceLinkWidgetForm.defaultProps = {
  workspace: {},
};

WorkspaceLinkWidgetForm.propTypes = {
  onWidgetPropertyUpdate: PropTypes.func.isRequired,
  workspace: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    siteId: PropTypes.number.isRequired,
  }),
};

export default WorkspaceLinkWidgetForm;

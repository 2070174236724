import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Autocomplete, Popper } from "@mui/material";
import { styled } from "@mui/styles";
import { TextField as CustomTextfield } from "../../common";
import CompanyListBox from "./CompanyListBox";
import NewCompanyButton from "./NewCompanyButton";

const NoOptionsContainer = styled('div')({
  padding: "5px",
  textAlign: "center",
});

const StyledPopper = styled(Popper)({
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1.5,
  margin: "4px 0",
  maxHeight: "100vh",
  maxWidth: "250px",
  "& li:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    cursor: "pointer",
  },
});

const CompanySelector = ({
  disabled,
  highlightedCompanyIds,
  name,
  onChange,
  onNewCompanyClick,
  options,
  showFooter,
  showHeader,
  size,
  value,
}) => {
  const intl = useIntl();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized && !value && highlightedCompanyIds.length === 1) {
      const highlightedOption = options.find(n => n.publicCompanyId === highlightedCompanyIds[0]);
      onChange?.(highlightedOption);
      setIsInitialized(true);
    }
  }, [highlightedCompanyIds, isInitialized, options, value]);

  return (
    <Autocomplete
      blurOnSelect
      disabled={disabled}
      getOptionLabel={option => option?.companyName || option || ""}
      isOptionEqualToValue={(option, newValue) => option?.companyName === newValue}
      ListboxProps={{ style: { maxHeight: 300 } }}
      ListboxComponent={(props, ref) => (
        <CompanyListBox
          {...props}
          highlightedCompanyIds={highlightedCompanyIds}
          onNewCompanyClick={onNewCompanyClick}
          showFooter={showFooter}
          showHeader={showHeader}
        />
      )}
      name={name}
      noOptionsText={
        <NoOptionsContainer>
          <NewCompanyButton onClick={onNewCompanyClick} />
        </NoOptionsContainer>
      }
      onChange={(event, newValue, reason, details) => onChange(newValue)}
      options={options}
      PopperComponent={(props) => (
        <StyledPopper {...props} placement="left">
          {props.children}
        </StyledPopper>
      )}
      renderInput={params => (
        <CustomTextfield
          {...params}
          placeholder={intl.formatMessage({ id: "siteUsers.selectCompany" })}
        />
      )}
      renderOption={(props, option, state) => ({ option, props })}
      size={size}
      value={value}
    />
  );
};

CompanySelector.defaultProps = {
  disabled: false,
  highlightedCompanyIds: [],
  name: null,
  options: [],
  showFooter: false,
  showHeader: false,
  size: "small",
  value: null,
};

CompanySelector.propTypes = {
  disabled: PropTypes.bool,
  highlightedCompanyIds: PropTypes.arrayOf(PropTypes.number),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onNewCompanyClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      companyName: PropTypes.string.isRequired,
      publicCompanyId: PropTypes.string.isRequired,
    })
  ),
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.string,
};

export default CompanySelector;

import React, { useState, useEffect } from "react";
import { TableRow, TableCell, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import CheckboxDefault from "../../common/FormControl/Checkbox";
import { settingKeys } from "./helpers";

const useStyles = makeStyles(theme => ({
  tr: {
    cursor: "pointer",
  },
  td: {
    padding: "4px 0",
    fontSize: 11,
    paddingLeft: "2px",
  },
  tbActive: {
    borderLeft: "solid " + theme.palette.primary.main + " 2px",
  },
  tdCb: {
    padding: "4px 8px",
    paddingRight: "0 !important",
    minWidth: 16,
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    "& .MuiSelect-select": {
      padding: "4px 6px",
      paddingRight: 16,
      fontSize: 11,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-iconOutlined": {
      right: 1,
      top: 5,
    },
  },
  infoIcon: {
    fontSize: 11,
  },
  iconButton: {
    padding: 0,
    marginLeft: 5,
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const ManageRolesMenu = ({
  checkbox,
  disabled,
  label,
  name,
  onChange,
  siteRoleValues,
  settingValues,
}) => {
  const classes = useStyles();
  const [checkedSettings, setCheckedSettings] = useState(false);

  useEffect(() => {
    if (settingKeys[name]) {
      const keys = Object.keys(settingKeys[name]);

      const checked = keys.some(x => {
        return siteRoleValues[x];
      });

      setCheckedSettings(checked);
    }
  }, [siteRoleValues]);

  return (
    <>
      <TableRow
        onClick={() => !disabled && onChange(name)}
        className={classes.tr}
        disabled={disabled}
      >
        <TableCell
          className={clsx([
            classes.td,
            settingValues[name] ? classes.tbActive : "",
          ])}
        >
          {label}
        </TableCell>

        <TableCell className={classes.tdCb}>
          <div className={classes.checkBoxContainer}>
            <CheckboxDefault value={checkedSettings} disabled={disabled} />
            <IconButton className={classes.iconButton} disabled={disabled}>
              <Icon
                className={clsx([
                  "fas fa-angle-double-right",
                  classes.infoIcon,
                ])}
              />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

ManageRolesMenu.defaultProps = {
  checkbox: false,
  disabled: false,
  editIcon: null,
  label: null,
  name: null,
  onChange: null,
  onClick: null,
  options: null,
  siteRoleValues: {},
  tooltip: false,
  tooltipTitle: null,
  value: null,
};

ManageRolesMenu.propTypes = {
  checkbox: PropTypes.bool,
  child: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  editIcon: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(),
  siteRoleValues: PropTypes.shape({}),
  tooltip: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

export default ManageRolesMenu;

import { gql } from '@apollo/client';

const getDirectMessagesUsers = gql`
  query GetDirectMessagesUsers($siteId: Int!) {
    siteChannels {
      directMessagesUsers(siteId: $siteId) {
        name
        userId
        username
      }
    }
  }
`;

export default getDirectMessagesUsers;

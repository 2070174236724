import React, { useEffect } from "react";
import { faviconUrl } from "./services/config";
import Helmet from "./app/Helmet";
import Page from "./app/Page";


const App = () => {

    useEffect(() => {        
        const favicon = document.getElementById("favicon");
        favicon.href = faviconUrl;
    }, [])

    return (
        <>
            <Helmet />
            <Page />
        </>
    );
}
  /*componentDidMount() {
    const favicon = document.getElementById("favicon");
    favicon.href = faviconUrl;
  }*/   


export default App;

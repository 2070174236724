import { gql } from '@apollo/client';
import Tasks from "../../fragments/Projects/Tasks";

const GET_TASKS = gql`
  query getTasks($projectId: Int!) {
    projects {
      getTasks(projectId: $projectId) {
        ...Tasks
      }
    }
  }
  ${Tasks}
`;

export default GET_TASKS;

import { gql } from '@apollo/client';

const getAvailableColorSchemes = gql`
  query getAvailableSiteFolderColorSets($siteId: Int!) {
    siteRoles {
      availableSiteFolderColorSets(siteId: $siteId) {
        defaultColor
        name
        siteColorSetId
        __typename
      }
    }
  }
`;

export default getAvailableColorSchemes;

import { gql } from '@apollo/client';

const SEARCH_V2 = gql`
  query SearchV2(
    $searchTerm: String,
    $siteId: Int!
    $filterItems: [SearchFilterItem]    
  ) {
    searchV2 {
      search(
        searchTerm: $searchTerm
        siteId: $siteId
        filterItems: $filterItems        
      ) {
        sites {
          folders {
            folderId
            folderName
          }
          siteId
          siteName
        }
        sortedProductIds {
          sortedByArticleNo
          sortedByName
          sortedByRank
        }
        sortedResourceIds {
          sortedByArticleNo
          sortedByName
          sortedByRank
        }
        viewSelectorSettings {
          detailedImageView
          fileView
          imageView
          productView
        }
      }
    }
  }
`;

export default SEARCH_V2;

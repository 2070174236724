import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
  Switch,
} from "@mui/material";
import clsx from "clsx";
import Sample from "../../../assets/img/sweden-1.jpg";
import EditPublicationModal from "./EditPublicationModal";

const useStyles = makeStyles(theme => ({
  root: {
    border: "1px solid #f1f1f1",
  },
  th: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 10,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingTop: 5,
    paddingBottom: 5,
  },
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
    marginLeft: 5,
  },
  iconButtonDelete: {
    padding: 4,
    color: theme.palette.common.red,
    marginLeft: 5,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  tdImg: {
    width: 100,
    textAlign: "center",
  },
  img: {
    width: 80,
    objectFit: "cover",
    verticalAlign: "middle",
  },
}));

const PublicationsTable = () => {
  const classes = useStyles();

  const [openEditPublication, setOpenEditPublication] = useState(false);
  const handleOpenEditPublication = () => {
    setOpenEditPublication(true);
  };
  const handleCloseEditPublication = () => {
    setOpenEditPublication(false);
  };

  return (
    <>
      <Table className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th} />
            <TableCell className={classes.th}>Publication name</TableCell>
            <TableCell className={classes.th}>item number</TableCell>
            <TableCell className={classes.th}>binding</TableCell>
            <TableCell className={classes.th}>status</TableCell>
            <TableCell className={classes.th} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tdImg}>
              <img src={Sample} alt="" className={classes.img} />
            </TableCell>
            <TableCell className={classes.td}>Publication sample</TableCell>
            <TableCell className={classes.td} />
            <TableCell className={classes.td}>Ringbunden</TableCell>
            <TableCell className={classes.td}>
              <Switch name="checkedB" color="primary" size="small" />
            </TableCell>
            <TableCell className={classes.td} align="right">
              <IconButton
                className={classes.iconButtonEdit}
                onClick={handleOpenEditPublication}
              >
                <Icon className={clsx(["fa fa-pencil-alt", classes.icon])} />
              </IconButton>
              <IconButton className={classes.iconButtonDelete}>
                <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <EditPublicationModal
        open={openEditPublication}
        handleClose={handleCloseEditPublication}
      />
    </>
  );
};

export default PublicationsTable;

import React from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { Typography, Box } from "@mui/material";
import ImageEmpty from "../../assets/img/empty-folder.svg";

const useStyles = makeStyles(theme => ({
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const StatusModalEmpty = () => {
  const classes = useStyles();
  return (
    <Box align="center">
      <img src={ImageEmpty} alt="Empty" />
      <Typography className={classes.emptyText}>
        <FormattedMessage id="product.noFilesInFolder" />
      </Typography>
    </Box>
  );
};

export default StatusModalEmpty;

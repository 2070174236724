import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { debounce } from 'lodash';

const TimeEditor = ({ value, propsSetIsEditing, propsHandleEditEnd, ...props }) => {
  const [internalValue, setInternalValue] = useState(value);
  const [isPopperOpen, setIsPopperOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);
  const popperRef = useRef(null);

  useEffect(() => {
    if (value) {
      if (typeof value === "string" || typeof value === "number") {
        setInternalValue(internalValue);
      }
    }
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      setAnchorEl(inputRef.current);
    }
  }, [inputRef.current]);

  const getCurrentTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const formatTime = (time) => {
    let hours, minutes;

    //Remove any non-digit, non-colon, and non-dot characters
    time = time.replace(/[^0-9:.]/g, "");

    // Check if the time string contains a colon or dot
    if (time.includes(":") || time.includes(".")) {
      // Split the time string based on the presence of colon or dot
      [hours, minutes] = time.split(/[:.]/);
    } else {
      // If no colon or dot, extract hours and minutes from the string

      // Extract the first two characters as hours
      hours = time.substring(0, 2);

      // Extract the next two characters as minutes
      minutes = time.substring(2, 4);
    }

    // Parse hours and minutes as integers
    const parsedHours = parseInt(hours) || 0;
    const parsedMinutes = parseInt(minutes) || 0;

    // Ensure hours are within valid range (0-23)
    const validHours = Math.min(Math.max(parsedHours, 0), 23);

    // Ensure minutes are within valid range (0-59)
    const validMinutes = Math.min(Math.max(parsedMinutes, 0), 59);

    // Format hours and minutes with leading zeros
    const formattedHours = String(validHours).padStart(2, "0");
    const formattedMinutes = String(validMinutes).padEnd(2, "0");

    // Construct the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes}`;

    return formattedTime;
  };

  const handleOnKeyDown = (event) => {
    //If Enter is pressed, stop editing and format time
    if (event.key === "Enter") {

      setInternalValue(formatTime(event.target.value));
      if (propsHandleEditEnd) {
        propsHandleEditEnd(false);
      }
      return;
    }

    // Allow these keys to function normally
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "",
    ];

    // If the pressed key is an allowed key, don't prevent default behavior
    if (allowedKeys.includes(event.key)) {
      return;
    }

    // Allow only specific keys (digits, colon, and dot)
    const isValidKey = /^[0-9:.]?$/.test(event.key);

    // If the pressed key is not valid, prevent default behavior
    if (!isValidKey) {
      //event.stopPropagation();
      event.preventDefault();
    }
  };

  const debouncedFormatTimeRef = useRef(
    debounce((inputValue) => {
      setInternalValue(formatTime(inputValue));
    }, 3000)
  );

  const handleOnChange = (e) => {
    const inputValue = e.target.value;
    setInternalValue(inputValue);
    debouncedFormatTimeRef.current(inputValue);
  };

  const handleBlur = (e) => {
    setInternalValue(formatTime(e.target.value));
    //setIsPopperOpen(false);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setIsPopperOpen(true);
    if (inputRef.current && document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDoubleClick = () => {
    inputRef.current.select();
  };

  const handleTimeSelect = (e, time) => {
    e.preventDefault();
    setInternalValue(formatTime(time));
    setIsPopperOpen(true);
    inputRef.current.focus();
  };

  const handleClickAway = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setIsPopperOpen(false);
  };

  //const handleClose = (event) => {
  //  if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //    return;
  //  }

  //  setOpen(false);
  //};

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{padding: 0, margin: 0}}>
        <InputBase
          {...props}
          autoFocus
          inputProps={{ maxLength: 5, className: props.className, ...props.inputProps }} //disableUnderline: true,
          onKeyDown={handleOnKeyDown}
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
          onBlur={handleBlur}
          onChange={handleOnChange}
          value={value}
          inputRef={inputRef}
          //startAdornment={
          //  <InputAdornment>
          //    <ScheduleIcon sx={{opacity: 0.7, fontSize: '20px', paddingRight: "4px",}} />
          //</InputAdornment>
          //}
          className=""
          style={{ cursor: "text", ...props.style, }} //, whiteSpace: "pre-wrap"
          sx={{ ...props.sx, padding: "0px", "&.Mui-focused": { padding: "0px", }, "& .MuiInputBase-input": { minHeight: "1.5em", }, }} //Settings for component "p" and "InputBase" should show same way
        />
        <Popper
          open={isPopperOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                rootBoundary: 'viewport',
              },
            },
          ]}
          style={{ zIndex: 1300 }}  // Ensure popper is above other elements
        >
          <Box ref={popperRef} sx={{ background: "white", border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "4px", width: '82px', maxHeight: '180px', padding: '2px', overflowY: 'auto', }}>            
            {Array.from({ length: 48 }).map((_, index) => {
              const hour = Math.floor(index / 2);
              const minute = index % 2 === 0 ? '00' : '30';
              const time = `${String(hour).padStart(2, '0')}:${minute}`;
              return (
                <Button key={index} onClick={(e) => handleTimeSelect(e, time)} sx={{ padding: "2px 4px 2px 4px", minWidth: "48px", color: "rgba(0, 0, 0, 0.67)", }}>
                  {time}
                </Button>
              );
            })}
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default TimeEditor;
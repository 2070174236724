import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Icon, Typography, IconButton } from "@mui/material";
import clsx from "clsx";
import WorkspaceIcon from "../../common/WorkspaceIcon";

const useStyles = makeStyles(theme => ({
  userBox: {
    padding: "15px 10px",
    border: "1px solid #e3e3e3",
    borderRadius: 4,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.05)",
    position: "relative",
    textAlign: "center",
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  profile: {
    width: 40,
    height: 40,
    objectFit: "contain",
    borderRadius: "50%",
    backgroundColor: "#EDEDED",
    padding: 5,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
    wordBreak: "break-word",
    marginTop: 10,
  },
  iconButtonDownload: {
    padding: 2,
    color: theme.palette.text.primary,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  iconEdit: {
    fontSize: 10,
  },
  iconRemove: {
    fontSize: 13,
  },
  userActions: {
    position: "absolute",
    right: 8,
    top: 5,
  },
}));

const WorkspaceTemplate = ({ workspace, handleDownload }) => {
  const classes = useStyles();

  const { icon, iconColor, name, siteId } = workspace;

  return (
    <>
      <Grid item xs={12} md={2}>
        <Box className={classes.userBox}>
          <WorkspaceIcon icon={icon} color={iconColor} />
          <Typography className={classes.name}>{name}</Typography>
          <Box className={classes.userActions}>
            <IconButton
              className={classes.iconButtonDownload}
              onClick={() => handleDownload(siteId)}
            >
              <Icon
                className={clsx([
                  "fa fa-download",
                  classes.icon,
                  classes.iconEdit,
                ])}
              />
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

WorkspaceTemplate.defaultProps = {
  workspace: null,
};

WorkspaceTemplate.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  workspace: PropTypes.shape({
    canEdit: PropTypes.bool,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    name: PropTypes.string,
    siteId: PropTypes.number,
  }),
};

export default WorkspaceTemplate;

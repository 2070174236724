import { gql } from '@apollo/client';
// import SiteNewsItem from "../fragments/SiteNewsItem";
import ErrorResultFragment from "../fragments/ErrorResult";

const CREATE_SIMPLIFIED_NEWSPOST = gql`
  mutation CreateSimplifiedNewsPost($input: SimplifiedNewsPostInput) {
    createSimplifiedNewsPost(input: $input) {
      errorResult {
        ...ErrorResultFields
      }
      success
      result {
        allowComments
        author
        category
        createdDate
        id
        image
        leadText
        siteId
        siteId
        template
        thumbnail
        title
        viewCount
      }
    }
  }
  ${ErrorResultFragment}
`;

export default CREATE_SIMPLIFIED_NEWSPOST;

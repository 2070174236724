import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const UPLOAD_NEWS_POST_THUMBNAIL = gql`
  mutation UploadNewsPostThumbnail(
    $siteId: Int!
    $imageData: String!
    $imageName: String
  ) {
    newsContext {
      uploadNewsPostThumbnail(
        siteId: $siteId
        imageData: $imageData
        imageName: $imageName
      ) {
        success
        errorResult {
          ...ErrorResultFields
        }
        thumbnailFileId
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPLOAD_NEWS_POST_THUMBNAIL;

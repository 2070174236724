import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Box, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  pageView: {
    alignItems: "center",
    display: "inline-flex",
    marginLeft: 0,
  },
  viewIcon: {
    display: "inline",
    fontSize: 12,
    backgroundColor: theme.palette.component.viewSwitchBackground,
    padding: "7px 12px",
    cursor: "pointer",
    color: "#4c4c4c",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  activeView: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  caret: {
    width: "13px !important",
    height: "12px !important",
  },
  showHide: {
    background: "#ebf1e0",
    borderRight: "1px solid #cbd0c2",
  },
  showHideIcon: {
    display: "inline",
    fontSize: 12,
    backgroundColor: theme.palette.component.viewSwitchBackground,
    padding: "7px 6px 7px 12px",
    cursor: "pointer",
    color: "#4c4c4c",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const ShareViewSelector = ({
  onChange,
  viewSelection,
}) => {
  const classes = useStyles();

  const handleChange = (name) => {
    onChange?.({ ...viewSelection, [name]: !viewSelection[name] });
  };

  return (
    <Box component="span" className={classes.pageView}>

      <Tooltip
        classes={{ popper: classes.popper }}
        placement="bottom"
        title={<FormattedMessage id="view.thumbnail" />}
      >
        <Box component="span"
          className={[
            classes.viewIcon,
            viewSelection.accessToImageGalleryView ? classes.activeView : "",
          ].join(" ")}
          onClick={() => handleChange("accessToImageGalleryView")}
        >
          <i className="fa fa-th" />
        </Box>
      </Tooltip>

      <Tooltip
        classes={{ popper: classes.popper }}
        placement="bottom"
        title={<FormattedMessage id="view.image" />}
      >
        <Box component="span"
          className={[
            classes.viewIcon,
            viewSelection.accessToImageView ? classes.activeView : "",
          ].join(" ")}
          onClick={() => handleChange("accessToImageView")}
        >
          <i className="fa fa-th-large" />
        </Box>
      </Tooltip>

      <Tooltip
        classes={{ popper: classes.popper }}
        placement="bottom"
        title={<FormattedMessage id="view.product" />}
      >
        <Box component="span"
          className={[
            classes.viewIcon,
            viewSelection.accessToProductView ? classes.activeView : "",
          ].join(" ")}
          onClick={() => handleChange("accessToProductView")}
        >
          <i className="fa fa-th-list" />
        </Box>
      </Tooltip>

      <Tooltip
        classes={{ popper: classes.popper }}
        placement="bottom"
        title={<FormattedMessage id="view.file" />}
      >
        <Box component="span"
          className={[
            classes.viewIcon,
            viewSelection.accessToFileView ? classes.activeView : "",
          ].join(" ")}
          onClick={() => handleChange("accessToFileView")}
        >
          <i className="fa fa-align-justify" />
        </Box>
      </Tooltip>

    </Box>
  );
};

ShareViewSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  viewSelection: PropTypes.string.isRequired,
};

export default ShareViewSelector;

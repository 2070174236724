import { gql } from '@apollo/client';
const GET_SITE_EVENT_UPDATE = gql`
  subscription siteEvents($authToken: String!) {
    siteEvents(authToken: $authToken) {
      eventTime
      userId
      siteId
      siteRoleId
      eventType
    }
  }
`;

export default GET_SITE_EVENT_UPDATE;

import { createActions } from "redux-actions";

export const {
  setSuppliers,
  setSuppliersLoading,
  setSuppliersError,
  addSupplier,
} = createActions({
  SET_SUPPLIERS: ({ items }) => ({ items }),
  SET_SUPPLIERS_LOADING: ({ loading }) => ({ loading }),
  SET_SUPPLIERS_ERROR: ({ error }) => ({ error }),
  ADD_SUPPLIER: ({ item }) => ({ item }),
});

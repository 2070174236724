import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Loading from "./Loading";

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    marginTop: 20,
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

const LoadingScreen = ({ size }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box align="center">
        <Loading size={size} />
      </Box>
    </div>
  );
};

LoadingScreen.defaultProps = {
  size: 40,
};

LoadingScreen.propTypes = {
  size: PropTypes.number,
};

export default LoadingScreen;

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import { PrimaryButton, TextField } from "../../common";
import {
  isRecipientFieldRequired,
  notificationActionsReceiversValid,
} from "../../../helpers/monitoring";
import { SELECT_FIELD_MANDATORY } from "../../../constant/types";
import InvalidFileFolderModal from "./StepThree/InvalidFileFolderModal";

const useStyles = makeStyles(() => ({
  textFieldContainer: {
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
  },
  root: {
    padding: "15px 20px 10px 20px",
    boxShadow: "2px 0 15px 0 rgba(0,0,0,0.1)",
    marginTop: "auto",
  },
  btnIcon: {
    fontSize: 12,
    marginLeft: 10,
    marginTop: 2,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  btnIconPrev: {
    fontSize: 12,
    marginRight: 10,
    marginTop: 2,
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
}));

const isSaveDisabled = ({ monitoringName, addressErrors }) => {
  let disabled = false;

  if (!monitoringName || monitoringName === "" || addressErrors) {
    disabled = true;
  }
  return disabled;
};

const isNextDisabled = ({
  recipients,
  activeStep,
  notificationActions,
  notificationOccasion,
  receivers,
}) => {
  let disabled = false;

  if (activeStep === 0) {
    if (
      !notificationActions ||
      notificationActions.length < 1 ||
      !notificationOccasion ||
      notificationOccasion === ""
    ) {
      disabled = true;
    } else if (
      !notificationActionsReceiversValid(notificationActions, receivers)
    ) {
      disabled = true;
    } else if (isRecipientFieldRequired(notificationActions)) {
      if (!recipients || recipients.length < 1) {
        disabled = true;
      }
    }
  }

  return disabled;
};

const StepFooter = ({
  activeStep,
  addressErrors,
  handleSaveMonitoringItem,
  monitoringName,
  notificationActions,
  notificationOccasion,
  receivers,
  recipients,
  setActiveStep,
  setMonitoringName,
  stepsLength,
  folderPropertyFormValues,
  propertyFormValues,
  foldersProductionSettings,
  loadingProductionSettings,
  disableSave,
  siteSupplierId,
}) => {
  const classes = useStyles();
  const [invalidModalOpen, setInvalidModalOpen] = useState(false);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [invalidFolders, setInvalidFolders] = useState([]);
  const [invalidSupplier, setInvalidSupplier] = useState(false);

  const handleOnchange = e => {
    setMonitoringName(e.target.value);
  };

  const validateFolderFileSettings = () => {
    const isEmail =
      notificationActions.length === 1 &&
      notificationActions[0].value === "EMAIL";
    let invalidFilesTemp = [];
    if (!foldersProductionSettings && !isEmail) {
      setInvalidModalOpen(true);
      return;
    }

    if (!isEmail && !siteSupplierId) {
      setInvalidSupplier(true);
      setInvalidModalOpen(true);
      return;
    } else {
      setInvalidSupplier(false);
    }

    if (
      propertyFormValues &&
      Array.isArray(propertyFormValues) &&
      propertyFormValues.length > 0
    ) {
      propertyFormValues.forEach(x => {
        if (x.isNotifyEnabled === false) return;

        const folderSettings = foldersProductionSettings[x.folderId];

        if (!folderSettings) return;

        const { folderProductSettings = {} } = folderSettings;
        const { productionProperties = [] } = folderProductSettings;

        let invalidProperties = [];
        productionProperties.forEach(y => {
          if (y.type === SELECT_FIELD_MANDATORY) {
            const { propertyValues } = y;

            const { productionSettings = {} } = x;
            const { propertyValueIds = [] } = productionSettings;

            const hasSelection = propertyValues.some(pV =>
              propertyValueIds.includes(pV.id)
            );

            if (!hasSelection) {
              invalidProperties = [...invalidProperties, y.name];
            }
          }
        });

        if (invalidProperties.length > 0) {
          invalidFilesTemp = [
            ...invalidFilesTemp,
            { name: x.name, invalidProperties },
          ];
        }
      });
    }

    let invalidFoldersTemp = [];

    if (
      folderPropertyFormValues &&
      Array.isArray(folderPropertyFormValues) &&
      folderPropertyFormValues.length > 0
    ) {
      folderPropertyFormValues.forEach(x => {
        if (x.isNotifyEnabled === false) return;

        const folderSettings = foldersProductionSettings[x.folderId];

        if (!folderSettings) return;

        const { folderProductSettings = {} } = folderSettings;
        const { productionProperties = [] } = folderProductSettings;

        let invalidProperties = [];
        productionProperties.forEach(y => {
          if (y.type === SELECT_FIELD_MANDATORY) {
            const { propertyValues } = y;

            const { productionSettings = {} } = x;
            const { propertyValueIds = [] } = productionSettings;

            const hasSelection = propertyValues.some(pV =>
              propertyValueIds.includes(pV.id)
            );

            if (!hasSelection) {
              invalidProperties = [...invalidProperties, y.name];
            }
          }
        });

        if (invalidProperties.length > 0) {
          invalidFoldersTemp = [
            ...invalidFoldersTemp,
            { name: x.name, invalidProperties },
          ];
        }
      });
    }

    const hasInvalidFolder = invalidFoldersTemp.length > 0;
    const hasInvalidFiles = invalidFilesTemp.length > 0;
    const isInvalid = hasInvalidFolder || hasInvalidFiles;

    if (isInvalid && !isEmail) {
      setInvalidFiles(invalidFilesTemp);
      setInvalidFolders(invalidFoldersTemp);
      setInvalidModalOpen(true);
    } else {
      handleSaveMonitoringItem();
    }
  };

  const handleCloseInvalidModal = () => {
    setInvalidFiles([]);
    setInvalidFolders([]);
    setInvalidModalOpen(false);
  };

  return (
    <Box className={classes.root}>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item xs={12} md={4} className={classes.textFieldContainer}>
          <TextField
            value={monitoringName}
            label={<FormattedMessage id="monitoring.nameYourMonitoring" />}
            name="monitoringName"
            onChange={handleOnchange}
            required
          />
        </Grid>
        <Grid item>
          {activeStep !== 0 && (
            <>
              <PrimaryButton onClick={() => setActiveStep(prev => prev - 1)}>
                <Icon
                  className={clsx([
                    "fa fa-long-arrow-alt-left",
                    classes.btnIconPrev,
                  ])}
                />
                <FormattedMessage id="btn.previous" />
              </PrimaryButton>
              &nbsp;
            </>
          )}
          {activeStep < stepsLength - 1 && (
            <PrimaryButton
              onClick={() => setActiveStep(prev => prev + 1)}
              disabled={isNextDisabled({
                recipients,
                activeStep,
                notificationActions,
                notificationOccasion,
                receivers,
              })}
              dataTut="step4"
            >
              <FormattedMessage id="btn.next" />
              <Icon
                className={clsx([
                  "fa fa-long-arrow-alt-right",
                  classes.btnIcon,
                ])}
              />
            </PrimaryButton>
          )}
          {activeStep === stepsLength - 1 && (
            <PrimaryButton
              onClick={() => validateFolderFileSettings()}
              loading={loadingProductionSettings}
              disabled={
                loadingProductionSettings ||
                disableSave ||
                isSaveDisabled({
                  monitoringName,
                  addressErrors,
                })
              }
            >
              <FormattedMessage id="btn.save" />
              {loadingProductionSettings ? (
                <CircularProgress size={20} />
              ) : (
                <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
              )}
            </PrimaryButton>
          )}
        </Grid>
      </Grid>
      {invalidModalOpen && (
        <InvalidFileFolderModal
          open={invalidModalOpen}
          handleClose={handleCloseInvalidModal}
          invalidFiles={invalidFiles}
          invalidFolders={invalidFolders}
          invalidSupplier={invalidSupplier}
          noProductionSettings={!foldersProductionSettings}
        />
      )}
    </Box>
  );
};

StepFooter.defaultProps = {
  recipients: [],
  receivers: [],
  loadingProductionSettings: false,
  folderPropertyFormValues: [],
  propertyFormValues: [],
  foldersProductionSettings: {},
};

StepFooter.propTypes = {
  activeStep: PropTypes.number.isRequired,
  monitoringName: PropTypes.string.isRequired,
  notificationActions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  handleSaveMonitoringItem: PropTypes.func.isRequired,
  notificationOccasion: PropTypes.string.isRequired,
  receivers: PropTypes.arrayOf(PropTypes.shape({})),
  recipients: PropTypes.arrayOf(PropTypes.shape({})),
  setActiveStep: PropTypes.func.isRequired,
  setMonitoringName: PropTypes.func.isRequired,
  stepsLength: PropTypes.number.isRequired,
  addressErrors: PropTypes.bool.isRequired,
  loadingProductionSettings: PropTypes.bool,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})),
  foldersProductionSettings: PropTypes.shape({}),
};

export default StepFooter;

import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(() =>
  createStyles({
    treeNodeLeaf: props => ({
      backgroundColor: props.backgroundColor,
      border: `2px solid ${props.backgroundColor}`,
      marginBottom: "1px",
      cursor: "pointer",
      color: "#252422",
      padding: "2px 6px",
      fontSize: "12px",

      // Default styling
      // backgroundColor: "#fff",
      // border: "2px solid #fff",
      // boxShadow: "0 1px 5px #f7f7f7"
    }),
    active: props => ({
      backgroundColor: "transparent !important",
      boxShadow: `inset 0 0 50px ${props.backgroundColor}`,
      borderRadius: 3,

      // Default styling
      // backgroundColor: "#fff",
      // boxShadow: "none",
      // borderColor: "#e2e2e2"
    }),
    treeValue: {
      padding: "6px 2px",
    },
  })
);

// eslint-disable-next-line no-shadow
const TreeNodeColorLeaf = ({
  folderId,
  name,
  setFolderId,
  backgroundColor,
  selectedFolderId,
}) => {
  const dynamicStyle = {
    backgroundColor,
  };
  const classes = useStyles(dynamicStyle);

  return (
    <div
      className={clsx([
        classes.treeNodeLeaf,
        selectedFolderId === folderId ? classes.active : null,
      ])}
    >
      <div
        className={classes.treeValue}
        onClick={() => setFolderId({ id: folderId })}
      >
        <div dangerouslySetInnerHTML={{ __html: name }} />
      </div>
    </div>
  );
};

TreeNodeColorLeaf.defaultProps = {
  selectedFolderId: null,
};

TreeNodeColorLeaf.propTypes = {
  folderId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  setFolderId: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  selectedFolderId: PropTypes.number,
};

export default TreeNodeColorLeaf;

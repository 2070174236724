import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/system";
import { isBefore, isWithinInterval } from "date-fns";
import moment from "moment";
import { Box, Grid, Popover, Fade } from "@mui/material";
import { FocusTrap } from '@mui/base/FocusTrap';
import { useLazyQuery } from "@apollo/client";
import { GET_UNAVAILABLE_DAYS } from "../../../graphql/queries/BookingContext";

import DateRangePicker from "./DateRangePicker";
import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';

const useStyles = (intent) =>
  makeStyles((theme) => ({
    root: {
      border: intent === "project" ? "1px solid rgba(0, 0, 0, 0.0)" : "1px solid #c4c4c4",
      borderRadius: intent === "project" ? "4px" : "4px",
      padding: intent === "project" ? "0px" : "9px 9px 9px 9px",
      [theme.breakpoints.down("md")]: {
        padding: intent === "project" ? "12px 9px 12px 9px" : "12px 9px 12px 9px",
      },
    },
    dateWrapper: {
      border: "1px solid rgba(0, 0, 0, 0.0)",
      fontSize: 12,
      [theme.breakpoints.up("md")]: {
        fontWeight: 500,
      },
      [theme.breakpoints.down("md")]: {
        fontWeight: 600,
        fontSize: 18,
      },
    },
    timeWrapper: {
      border: "1px solid rgba(0, 0, 0, 0.0)",
      fontSize: 12,
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontWeight: 500,
        fontSize: 16,
      },
    },
  }));

const EditableDateRangeTimePicker = ({
  intent,
  enableTime,
  singleLine,
  pickUpDate,
  returnDate,
  setPickUpDate,
  setReturnDate,
  pickUpTime,
  setPickUpTime,
  returnTime,
  setReturnTime,
  weekends,
  enteredTo,
  setEnteredTo,
  lift,
  pickUpTimeSetting,
  returnTimeSetting,
}) => {
  const classes = useStyles(intent)();
  const theme = useTheme();

  const gridContainerStyling =
  {
    alignItems: "center !important",
    border: "1px solid rgba(0, 0, 0, 0.0) !important",
    borderRadius: "4px !important",
    justifyContent: intent === "project" ? "flex-start" : "center",
    minWidth: "370px", //Change this value later
    '&:hover': {
      border: "1px solid rgba(0, 0, 0, 0.0) !important",
    },
  };

  const gridItemStyling =
  {
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.06) !important",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: 12,
    justifyContent: intent === "project" ? "flex-start" : "center",
    paddingLeft: "0px !important",
    '&:hover': {
      border: "1px solid rgba(0, 0, 0, 0.5) !important",
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: 500,
    },
    [theme.breakpoints.down("md")]: {
      fontWeight: 600,
      fontSize: 18,
    },
  };

  const gridItemNoBorderStyling =
  {
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.0)",
    borderRadius: "4px",
    cursor: "pointer",
    justifyContent: intent === "project" ? "space-between" : "center",
    '&:hover': {
      border: "1px solid rgba(0, 0, 0, 0.0)",
      cursor: "pointer",
    },
  };

  const boxInGridStyling = {
    border: "1px solid rgba(0, 0, 0, 0.0)",
    textAlign: "center",
  };

  const [open, setOpen] = useState(false);

  const [getUnavailableDays, { data: unavailableDaysData }] = useLazyQuery(
    GET_UNAVAILABLE_DAYS
  );
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const [partiallyUnavailableDays, setPartiallyUnavailableDays] = useState([]);
  const [totallyUnavailableDays, setTotallyUnavailableDays] = useState([]);
  const [unavailableDays, setUnavailableDays] = useState([]);

  const [
    inRangeTotallyUnvailableDays,
    setInRangeTotallyUnvailableDays,
  ] = useState([]);
  const [
    inRangePartialUnavailableDays,
    setInRangePartialUnavaialbleDays,
  ] = useState([]);

  useEffect(() => {
    if (
      unavailableDaysData &&
      unavailableDaysData.bookingContext &&
      unavailableDaysData.bookingContext.getUnavailableDays
    ) {
      const {
        partiallyUnavailableDays: partialUnavailDays,
        totallyUnavailableDays: totallyUnavailDays,
        unavailableDays: unavailable,
      } = unavailableDaysData.bookingContext.getUnavailableDays;

      const partial = partialUnavailDays.map(x => new Date(x));
      const totally = totallyUnavailDays.map(x => new Date(x));
      setUnavailableDays(unavailable);
      setPartiallyUnavailableDays(partial);
      setTotallyUnavailableDays(totally);
    }
  }, [unavailableDaysData]);

  useEffect(() => {
    const firstDayOfInitialMonth = moment(currentMonth)
      .clone()
      .startOf("month");

    const lastDayOfFinalMonth = moment(currentMonth)
      .add(4, "M")
      .clone()
      .endOf("month");

    getUnavailableDays({
      variables: {
        productGroupfolderId: lift,
        startOfPeriod: firstDayOfInitialMonth,
        endOfPeriod: lastDayOfFinalMonth,
      },
    });
  }, [lift, currentMonth]);

  const handleMonthChange = e => {
    setCurrentMonth(e);
  };

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && isBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayMouseEnter = day => {
    if (isSelectingFirstDay(pickUpDate, returnDate, day)) {
      setEnteredTo(day);
    }
  };

  const handleOnDayClick = (day, mod) => {
    const { disabled } = mod || {};
    if (disabled) {
      return;
    }
    if (isSelectingFirstDay(pickUpDate, returnDate, day)) {
      setPickUpDate(day);
      setReturnDate(null);
      setEnteredTo(null);
    } else {
      setReturnDate(day);
      setEnteredTo(day);
    }
  };

  const handleOpen = (event) => {
    if (!open) {
      setOpen(true);
      if (intent === "project") { setAnchorEl(anchorEl ? null : event.currentTarget) };
    }
  };

  const handleClose = (event) => {
    setOpen(false);
    if (intent === "project") { setAnchorEl(anchorEl ? null : event.currentTarget) };
  };

  const handleFormatDate = date => {
    const formattedDate = moment(date).format("D MMM");
    return formattedDate;
  };

  const handleFormatTime = time => {
    const formattedTime = moment(time).format("HH:mm");
    return formattedTime;
  };

  useEffect(() => {
    setInRangeTotallyUnvailableDays([]);
    setInRangePartialUnavaialbleDays([]);
    const range = {
      start: pickUpDate,
      end: returnDate,
    };
    if (range && totallyUnavailableDays.length > 0) {
      totallyUnavailableDays.forEach(d => {
        const inRange = isWithinInterval(d, range);
        if (inRange) {
          setInRangeTotallyUnvailableDays(prevState => [...prevState, d]);
        }
      });
    }

    if (range && partiallyUnavailableDays.length > 0) {
      partiallyUnavailableDays.forEach(d => {
        const inRange = isWithinInterval(d, range);
        if (inRange) {
          setInRangePartialUnavaialbleDays(prevState => [...prevState, d]);
        }
      });
    }
  }, [
    pickUpDate,
    returnDate,
    totallyUnavailableDays,
    partiallyUnavailableDays,
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  //const isPopperOpen = Boolean(anchorEl);
  //const id = isPopperOpen ? 'simple-popper' : undefined;
  //const id = Boolean(anchorEl) ? 'simple-popover' : undefined;

  return (
    <Grid
      container
      className={classes.root}
      onClick={handleOpen}
      sx={gridContainerStyling}
    >
      {singleLine && (
        <Grid item sx={gridItemStyling}>
          {handleFormatDate(pickUpDate)}
          {enableTime && (
            <Box item xs={3} className={classes.timeWrapper} component="span" sx={boxInGridStyling}>
              {" "}
              {handleFormatTime(pickUpTime)}{" "}
            </Box>
          )}
          – {handleFormatDate(returnDate)}
          {enableTime && (
            <Box item xs={3} className={classes.timeWrapper} component="span" sx={boxInGridStyling}>
              {" "}
              {handleFormatTime(returnTime)}
            </Box>
          )}
        </Grid>
      )}
      {!singleLine && (
        <Grid item container className={classes.dateWrapper} sx={gridItemStyling} xs={6}>
          <Grid item className={classes.dateWrapper} sx={gridItemNoBorderStyling}>
            <IconButton sx={{ padding: "4px" }}><EventIcon /></IconButton>
          </Grid>
          <Grid item className={classes.dateWrapper} sx={gridItemNoBorderStyling}>
            <Box
              component="div"
              className={classes.dateWrapper}
              sx={boxInGridStyling}
            >
              {handleFormatDate(pickUpDate)} – {handleFormatDate(returnDate)}
            </Box>
            {enableTime && (
              <Box
                component="div"
                className={classes.timeWrapper}
                sx={boxInGridStyling}
              >
                {handleFormatTime(pickUpTime)} – {handleFormatTime(returnTime)}
              </Box>
            )}
          </Grid>
        </Grid>
      )}
      {/*{intent === "project" && open && (*/}
      {/*  <FocusTrap>*/}
      {/*    <Popover*/}
      {/*      //id={id} //Specific for Popper*/}
      {/*      open={open} //Specific for Popper*/}
      {/*      anchorEl={anchorEl} //Specific for Popper*/}
      {/*      placement="bottom" //Specific for Popper*/}
      {/*      transition //Specific for Popper*/}
      {/*    >*/}
      {/*      {({ TransitionProps }) => (*/}
      {/*        <Fade {...TransitionProps} timeout={350}>*/}
      {/*          <FocusTrap>*/}
      {/*            <DateRangePicker*/}
      {/*              anchorEl={anchorEl}*/}
      {/*              open={open}*/}
      {/*              singleLine={singleLine}*/}
      {/*              handleClose={handleClose}*/}
      {/*              from={pickUpDate}*/}
      {/*              to={returnDate}*/}
      {/*              pickUpTime={pickUpTime}*/}
      {/*              returnTime={returnTime}*/}
      {/*              setPickUpTime={setPickUpTime}*/}
      {/*              setReturnTime={setReturnTime}*/}
      {/*              handleOnDayClick={handleOnDayClick}*/}
      {/*              handleFormatDate={handleFormatDate}*/}
      {/*              weekends={weekends}*/}
      {/*              onDayMouseEnter={handleDayMouseEnter}*/}
      {/*              enteredTo={enteredTo}*/}
      {/*              handleMonthChange={handleMonthChange}*/}
      {/*              partialBusy={partiallyUnavailableDays}*/}
      {/*              totallyBusy={totallyUnavailableDays}*/}
      {/*              pickUpTimeSetting={pickUpTimeSetting}*/}
      {/*              returnTimeSetting={returnTimeSetting}*/}
      {/*              inRangeTotallyUnvailableDays={inRangeTotallyUnvailableDays}*/}
      {/*              inRangePartialUnavailableDays={inRangePartialUnavailableDays}*/}
      {/*              unavailableDays={unavailableDays}*/}
      {/*            />*/}
      {/*          </FocusTrap>*/}
      {/*        </Fade>*/}
      {/*      )}*/}
      {/*    </Popover>*/}
      {/*  </FocusTrap>  */}
      {/*)}*/}
      {true && open && (
            <DateRangePicker
              intent={intent}
              anchorEl={anchorEl}
              open={open}
              singleLine={singleLine}
              handleClose={handleClose}
              from={pickUpDate}
              to={returnDate}
              pickUpTime={pickUpTime}
              returnTime={returnTime}
              setPickUpTime={setPickUpTime}
              setReturnTime={setReturnTime}
              handleOnDayClick={handleOnDayClick}
              handleFormatDate={handleFormatDate}
              weekends={weekends}
              onDayMouseEnter={handleDayMouseEnter}
              enteredTo={enteredTo}
              handleMonthChange={handleMonthChange}
              partialBusy={partiallyUnavailableDays}
              totallyBusy={totallyUnavailableDays}
              pickUpTimeSetting={pickUpTimeSetting}
              returnTimeSetting={returnTimeSetting}
              inRangeTotallyUnvailableDays={inRangeTotallyUnvailableDays}
              inRangePartialUnavailableDays={inRangePartialUnavailableDays}
              unavailableDays={unavailableDays}
            />
        )} 
    </Grid>
  );
};

EditableDateRangeTimePicker.defaultProps = {
  intent: "project",
  singleLine: false,
  enableTime: false,
  pickUpDate: new Date(),
  returnDate: new Date(),
  setPickUpDate: () => { },
  setReturnDate: () => { },
  pickUpTime: new Date(),
  setPickUpTime: () => { },
  returnTime: new Date(),
  setReturnTime: () => { },
  weekends: { daysOfWeek: [0, 6] },
  enteredTo: new Date(),
  setEnteredTo: () => { },
  lift: "",
  pickUpTimeSetting: new Date(),
  returnTimeSetting: new Date(),
};

EditableDateRangeTimePicker.propTypes = {
  intent: PropTypes.string,
  enableTime: PropTypes.bool,
  pickUpDate: PropTypes.instanceOf(Date).isRequired,
  returnDate: PropTypes.instanceOf(Date).isRequired,
  singleLine: PropTypes.bool,
  setPickUpDate: PropTypes.func.isRequired,
  setReturnDate: PropTypes.func.isRequired,
  pickUpTime: PropTypes.instanceOf(Date).isRequired,
  setPickUpTime: PropTypes.func.isRequired,
  returnTime: PropTypes.instanceOf(Date).isRequired,
  setReturnTime: PropTypes.func.isRequired,
  weekends: PropTypes.object.isRequired,
  enteredTo: PropTypes.instanceOf(Date).isRequired,
  setEnteredTo: PropTypes.func.isRequired,
  lift: PropTypes.string.isRequired,
  pickUpTimeSetting: PropTypes.instanceOf(Date).isRequired,
  returnTimeSetting: PropTypes.instanceOf(Date).isRequired,
};

export default EditableDateRangeTimePicker;

import React from "react";
import PropTypes from "prop-types";
import VersionTableRowItem from "./VersionTableRowItem";

const VersionTableRows = ({
  locked,
  items,
  onDownloadVersion,
  refetchVersionHistory,
}) => {
  const handleDownloadResource = versionNumber => {
    onDownloadVersion({ versionNumber });
  };

  return items.map(x => {
    return (
      <VersionTableRowItem
        item={x}
        locked={locked}
        handleDownloadResource={handleDownloadResource}
        refetchVersionHistory={refetchVersionHistory}
        isDeleteIconVisible={items.length > 1}
        key={x.resourceVersionIdentifier}
      />
    );
  });
};

VersionTableRows.defaultProps = {
  locked: false,
};

VersionTableRows.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      displayFilename: PropTypes.string,
      initialOriginalFilename: PropTypes.string,
      originalFilename: PropTypes.string,
      resourceVersionIdentifier: PropTypes.string,
      versionNumber: PropTypes.number,
    })
  ).isRequired,
  locked: PropTypes.bool,
  refetchVersionHistory: PropTypes.func.isRequired,
};

export default VersionTableRows;

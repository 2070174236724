import {
  DateValueEditor,
  DurationValueEditor,
  NumberValueEditor,
  StatusValueEditor,
  StringValueEditor,
} from "../../pages/ProjectTaskPage/ValueEditors";
import { renderDateCellValue } from "./helper";

const getProjectFieldColumnWidth = (field) => {
  switch (field.type) {
    case 4: return "auto"; // Duration
    case 3: return "minmax(50px,150px)"; // Status
    case 2: return "auto"; // Date
    case 1: return "auto"; // Number
    case 0: return "209px"; // String

    default:
      console.warn("** unhandled type: " + field.type);
      return "auto";
  }
};

const createColumns = (projectFields) => {
  if (!Array.isArray(projectFields) || projectFields.length === 0) {
    return [];
  }

  return projectFields.map((projectField) => {
    const { fieldId, name, type, jsonFieldSettings } = projectField;

    let field = { ...projectField };

    if (jsonFieldSettings) {
      try {
        const settings = JSON.parse(jsonFieldSettings);
        field = { ...field, settings };
      } catch (error) {
        console.error(`Error parsing settings for projectField: ${field}`, error);
      }
    }

    return {
      fieldId,
      label: name,
      renderCell: (item, onChange, enableEdit, inline, disabled) => renderProjectFieldCell(enableEdit, field, item, onChange, inline, disabled),
      type,
      width: getProjectFieldColumnWidth(field),
    };
  });
};

const renderProjectFieldCell = (enableEdit, field, item, onChange, inline, disabled) => {
  const displayValue = item.values.find(n => n.fieldId === field.fieldId);

  return renderValueCell(enableEdit, field, displayValue, onChange, inline, disabled);
};

const renderDateValueCell = (enableEdit, field, value, onChange) => {
  return enableEdit
    ? <DateValueEditor field={field} value={value} onUpdateTaskValue={onChange} />
    : renderDateCellValue(value);
};

const renderValueCell = (enableEdit, field, value, onChange, inline, disabled) => {
  switch (field.type) {
    case 4: return (<DurationValueEditor enableEdit={enableEdit} field={field} value={value} onUpdateTaskValue={onChange} sx={{ justifyContent: (inline ? "flex-end" : "flex-start") }} />); // Duration
    case 3: return (<StatusValueEditor disabled={disabled} enableEdit={enableEdit} field={field} hideUnassigned={inline} onUpdateTaskValue={onChange} value={value} />); // Status
    case 2: return renderDateValueCell(enableEdit, field, value, onChange); // Date
    case 1: return <NumberValueEditor disabled={disabled} enableEdit={enableEdit} field={field} inline={inline} value={value} onUpdateTaskValue={onChange} />; // Number
    case 0: return <StringValueEditor disabled={disabled} enableEdit={enableEdit} field={field} inline={inline} value={value} onUpdateTaskValue={onChange} />; // String

    default: return "Type: " + field.type + ":" + value || "No Value"
  }
};

export default createColumns;
import React from "react";
import { useMutation } from "@apollo/client";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";

import {
  ACCEPT_INCOMING_SUPPLIER_REQUEST,
  REJECT_INCOMING_SUPPLIER_REQUEST,
} from "../graphql/mutations/CompanyAdminContext";
// eslint-disable-next-line import/prefer-default-export
export const useIncomingSupplierRequest = ({
  supplierRequestId,
  getCompanySupplierSettings,
  companyId,
}) => {
  const alert = useAlert();

  const [acceptIncomingSupplierRequest] = useMutation(
    ACCEPT_INCOMING_SUPPLIER_REQUEST
  );

  const [rejectIncomingSupplierRequest] = useMutation(
    REJECT_INCOMING_SUPPLIER_REQUEST
  );

  const handleIncomingSupplierRequest = async actionType => {
    let res = null;
    const variables = {
      variables: { companyId, supplierRequestId },
    };
    if (actionType === "ACCEPT") {
      try {
        res = await acceptIncomingSupplierRequest(variables);
        if (
          res &&
          res.data &&
          res.data.companyAdminContext &&
          res.data.companyAdminContext.suppliers &&
          res.data.companyAdminContext.suppliers.acceptIncomingSupplierRequest
        ) {
          alert.success(<FormattedMessage id="supplier.supplierAccepted" />);
          getCompanySupplierSettings();
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
      } catch (err) {
        console.log("Something went wrong", err);
      }
    }

    if (actionType === "REJECT") {
      try {
        res = await rejectIncomingSupplierRequest(variables);

        if (
          res &&
          res.data &&
          res.data.companyAdminContext &&
          res.data.companyAdminContext.suppliers &&
          res.data.companyAdminContext.suppliers.rejectIncomingSupplierRequest
        ) {
          alert.success(<FormattedMessage id="supplier.supplierRejected" />);
          getCompanySupplierSettings();
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
      } catch (err) {
        console.log("Something went wrong", err);
      }
    }
  };

  return { execute: handleIncomingSupplierRequest };
};

import PropTypes from "prop-types";
import { forwardRef, useState } from "react";
import { Box } from "@mui/material";
import _fallbackImageUrl from "../../assets/img/broken-img.png";

const ImageWithFallback = forwardRef((
  {
    errorImageUrl,
    imageUrl,
    onError,
    onLoad,
    ...rest
  },
  ref
) => {
  const [error, setError] = useState(false);

  return (
    <Box
      {...rest}
      component="img"
      onError={e => {
        setError(true);
        onError?.(e);
      }}
      onLoad={e => {
        !error && onLoad?.(e);
      }}
      ref={ref}
      src={error ? (errorImageUrl || _fallbackImageUrl) : imageUrl}
    />
  );
});

ImageWithFallback.defaultProps = {
  errorImageUrl: undefined,
  onError: undefined,
  onLoad: undefined,
};

ImageWithFallback.propTypes = {
  errorImageUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

export default ImageWithFallback;

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import PickingListTable from "./PickingListTable";

const HistoryTab = ({
  loading,
  searchValues,
  setSearchValues,
  fetchPickingListOrders,
  pickingListOrders,
  showColumn,
  totalCount,
  selectedOrders,
  addSelectedOrder,
  removeSelectedOrder,
  setSelectedOrders,
  page,
  setPage,
  isHistory,
  onOrderPrinted,
}) => {
  return (
    <Box>
      <PickingListTable
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        loading={loading}
        showColumn={showColumn}
        fetchPickingListOrders={fetchPickingListOrders}
        pickingListOrders={pickingListOrders}
        totalCount={totalCount}
        selectedOrders={selectedOrders}
        addSelectedOrder={addSelectedOrder}
        removeSelectedOrder={removeSelectedOrder}
        setSelectedOrders={setSelectedOrders}
        page={page}
        setPage={setPage}
        isHistory={isHistory}
        onOrderPrinted={onOrderPrinted}
      />
    </Box>
  );
};

HistoryTab.defaultProps = {
  searchValues: {},
  loading: false,
  pickingListOrders: [],
  showColumn: {},
  onOrderPrinted: () => {
    if (console && "error" in console) {
      console.error("Property onOrderPrinted is not provided.");
    }
  },
};

HistoryTab.propTypes = {
  totalCount: PropTypes.number.isRequired,
  pickingListOrders: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  showColumn: PropTypes.shape({}),
  searchValues: PropTypes.shape({}),
  setSearchValues: PropTypes.func.isRequired,
  fetchPickingListOrders: PropTypes.func.isRequired,
  selectedOrders: PropTypes.arrayOf(PropTypes.number).isRequired,
  addSelectedOrder: PropTypes.func.isRequired,
  removeSelectedOrder: PropTypes.func.isRequired,
  setSelectedOrders: PropTypes.func.isRequired,
  onOrderPrinted: PropTypes.func,
};

export default HistoryTab;

import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import * as sites from "../../../constant/siteNames";
import { getCurrentCompanyId } from "../../../helpers/selectors";
import WorkspaceIcon from "../../common/WorkspaceIcon";

const Workspace = ({ className, classes, onClick, workspace }) => {
  const intl = useIntl();

  const companyId = useSelector(getCurrentCompanyId);

  const {
    id: siteId,
    name,
    icon,
    description,
  } = workspace || {};

  //const iconColor = workspace?.iconColor || fontColor;
  const iconColor = workspace?.iconColor || "#212529";

  const getTranslatedWorkspaceName = workspaceName => {
    return intl.formatMessage({ id: `workspaceName.${workspaceName}` });
  };

  const getTranslatedWorkspaceDesc = workspaceName => {
    return <FormattedMessage id={`workspaceDesc.${workspaceName}`} />;
  };

  const getSiteDisplayName = () => {
    switch (name) {
      case sites.LIFT_MACHINE:
        return getTranslatedWorkspaceName(
          sites.SITE_DISPLAY_NAME[sites.LIFT_MACHINE]
        );
      default:
        return name;
    }
  };

  return (
    <Grid
      alignItems="center"
      className={clsx([classes.root, className])}
      container
      onClick={() => onClick(siteId)}
    >
      <Grid className={classes.iconContainer}>
        <WorkspaceIcon color={iconColor} icon={icon} />
      </Grid>
      <Grid>
        <Typography className={classes.name}>
          {getSiteDisplayName(sites.SITE_DISPLAY_NAME[sites.LIFT_MACHINE])}
        </Typography>
        <Typography className={classes.description}>
          {companyId !== 754 ? (
            <>{description}</>
          ) : (
            <>
              {getTranslatedWorkspaceDesc(
                sites.SITE_DISPLAY_NAME[sites.LIFT_MACHINE]
              )}
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Workspace;

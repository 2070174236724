import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  autocomplete: {
    "& .MuiFormLabel-root": {
      fontSize: 11,
    },
    "& input": {
      fontSize: 11,
    },
    "& .MuiChip-label": {
      fontSize: 11,
    },
    "& .MuiAutocomplete-tag": {
      marginLeft: 0,
      margintTop: 0,
      marginBottom: 2,
      marginRight: 3,
      height: 24,
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      padding: "3px 4px",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "4.5px 4px",
    },
    "& .MuiInputLabel-outlined": {
      top: -7,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      top: 0,
    },
  },
  option: {
    fontSize: 11,
    padding: "5px 10px !important",
  },
  labelRoot: {
    color: "rgba(0, 0, 0, 0.54)!important",
    fontSize: "14px!important",
  },
}));

const MultiSelectCheckboxes = ({
  disabled,
  name,
  onChange,
  options,
  value,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.autocomplete}>
      <Autocomplete
        classes={{ option: classes.option }}
        disabled={disabled}
        getOptionLabel={option => option.title}
        // eslint-disable-next-line no-shadow
        isOptionEqualToValue={(option, value) => option.title === value.title}
        multiple
        noOptionsText={<FormattedMessage id="supplier.noOptions" />}
        onChange={onChange}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
              shrink: true,
            }}
            label={name}
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              checked={Boolean(selected)}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              style={{ marginRight: 8, padding: 2 }}
            />
            {option.title}
          </li>
        )}
        value={value}
      />
    </Box>
  );
};

MultiSelectCheckboxes.defaultProps = {
  disabled: false,
  name: null,
  options: [],
  value: [],
};

MultiSelectCheckboxes.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
};

export default MultiSelectCheckboxes;

import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import {
  login,
  currentViewer,
  selectWorkspace,
  setSuppliers,
} from "../actions";
import { debug } from "../services/config";

const middleware = [];
const initialState = {};

if (debug) {
  const { logger } = require("redux-logger");
  middleware.push(logger);
}

middleware.push(thunk);

const accessToken = localStorage.getItem("access_token");

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

if (accessToken) store.dispatch(login({ accessToken }));

/**
 * @dev Set store before dispatching any additional calls
 */

if (accessToken) {
  const viewer = JSON.parse(localStorage.getItem("viewer"));
  const selectedWorkspace = localStorage.getItem("selected_workspace");
  const suppliers = localStorage.getItem("suppliers");
  store.dispatch(async () => {
    store.dispatch(currentViewer({ viewer }));

    if (suppliers && Array.isArray(suppliers)) {
      store.dispatch(setSuppliers({ items: JSON.parse(suppliers) }));
    }
    if (selectedWorkspace) {
      store.dispatch(selectWorkspace(JSON.parse(selectedWorkspace)));
    }
  });
}

export { store };

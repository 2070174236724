import { gql } from '@apollo/client';

const GET_DIRECT_MESSAGE_CONVERSATION_UPDATE = gql`
  subscription DirectMessageConversationUpdateSubscription(
    $authToken: String!
  ) {
    directMessageConversationUpdate(authToken: $authToken) {
      cursor
      node {
        directMessageConversationId
        latestMessageId
        latestReadMessageId
        latestReadMessageTime
        members {
          name
          userId
          username
        }
        unreadMessageCount
      }
    }
  }
`;

export default GET_DIRECT_MESSAGE_CONVERSATION_UPDATE;

import { gql } from '@apollo/client';

const companyResponsibleLookUp = gql`
  query CompanyResponsibleLookUp($searchTerm: String) {
    productEdit {
      companyAnsvarigLookup(searchTerm: $searchTerm) {
        user
        userId
        groupName
      }
    }
  }
`;
export default companyResponsibleLookUp;

import { useMutation } from "@apollo/client";
import { DELETE_RESOURCE_VERSION } from "../graphql/mutations";

// eslint-disable-next-line import/prefer-default-export
export const useDeleteResourceVersion = () => {
  const [deleteItemInGroup, { loading }] = useMutation(DELETE_RESOURCE_VERSION);

  const handleDelete = async ({
    resourceVersionIdentifier,
    onSuccess,
    onError,
  }) => {
    try {
      const response = await deleteItemInGroup({
        variables: {
          resourceVersionIdentifier,
        },
      });
      if (response && response.data && response.data.deleteResourceVersion) {
        const { success } = response.data.deleteResourceVersion;
        if (success) {
          onSuccess();
        } else {
          onError();
        }
      } else {
        onError();
      }
    } catch (e) {
      onError();
    }
  };

  return { execute: handleDelete, loading };
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Typography, Grid, Box } from "@mui/material";
import CustomTextField from "../common/TextField";
import SelectNativeFull from "../common/FormControl/SelectNativeFull";
import TextAreaFull from "../common/FormControl/TextAreaFull";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "10px 20px",
    backgroundColor: theme.palette.common.white,
  },
  modalFooter: {
    padding: "10px 0",
    borderTop: "1px solid #ddd",
  },
  btnNext: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginLeft: 8,
    verticalAlign: "middle",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .Mui-disabled": {
      backgroundColor: "#f3f3f3",
    },
    "& .Mui-disabled:hover": {
      cursor: "not-allowed",
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: 15,
  },
  label: {
    fontSize: 12,
    marginBottom: 0,
  },
}));

const IssueModal = ({ open, setOpen }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="Ärende" />
      <DraggableModalBody customClass={classes.modalBody}>
        <Typography className={classes.title}>
          <FormattedMessage id="context.orderForm" />
        </Typography>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Kund</Typography>
            <CustomTextField placeholder="Ange kundens namn" />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Kundnummer</Typography>
            <CustomTextField placeholder="Ange kundnummer" />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Län-nr-år</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField placeholder="Län" />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField placeholder="Nummer" />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomTextField placeholder="Är" />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Format</Typography>
            <SelectNativeFull
              options={[
                { label: "1/4", value: 1 },
                { label: "1/2", value: 2 },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Färg</Typography>
            <SelectNativeFull
              options={[
                { label: "Färg", value: 1 },
                { label: "Svartvitt", value: 2 },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Hantera korrektur</Typography>
            <SelectNativeFull
              options={[
                { label: "Korrektur till säljare", value: 1 },
                { label: "Korrektur till admin", value: 2 },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Kommentar / synpunkter
            </Typography>
            <TextAreaFull
              placeholder="Skriv din kommentar här"
              rows="3"
              rowsMax="3"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Ansvarig beställare
            </Typography>
            <CustomTextField
              placeholder="Ansvarig beställare"
              value="Test Testarsson"
              disabled="disabled"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Sajt</Typography>
            <CustomTextField
              placeholder="Ansvarig beställare"
              value="DemoSite"
              disabled="disabled"
            />
          </Grid>
        </Grid>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnNext}>
            <FormattedMessage id="btn.next" />
            <Icon
              className={clsx(["fa fa-long-arrow-alt-right", classes.btnIcon])}
            />
          </Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

IssueModal.defaultProps = {
  open: false,
};

IssueModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

export default IssueModal;

import { gql } from '@apollo/client';

const ImageBankItemFragment = gql`
  fragment ImageBankItemFragment on ImageBankItem {
    itemId
    imageName
    productId
    siteId
    includeOriginal
    settings {
      settingId
      selectedColorMode
      selectedFileFormat
      __typename
    }
    __typename
  }
`;

export default ImageBankItemFragment;

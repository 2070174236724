import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { TreeNodeContainer } from "../../components/ProductsMainView";
import { FILE_VIEW } from "../../constant/types";
import ProductFolderTree from "../../components/ProductsMainView/FileView/ProductFolderTree";
import FolderList from "../../components/ProductsMainView/FolderList";
import CategorySkeleton from "../../components/Skeleton/CategorySkeleton";

const useStyles = makeStyles(theme => ({
  productMenuCategory: {
    color: theme.palette.common.black,
    paddingLeft: 0,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
}));

const FolderCategoryTree = ({
  detailedSearchCalled,
  detailedSearchOpen,
  folderId,
  folders,
  handleGetSiteFolders,
  handleOpenNode,
  loading,
  openedNodes,
  searchResultsError,
  searchResultsLoading,
  setFolderId,
  setOpenedNodes,
  setSearchQuery,
  setSearchResults,
  siteFolders,
  view,
}) => {
  const classes = useStyles();

  const renderCategoryTree = () => {
    if (view === FILE_VIEW) {
      return (
        <>
          <ProductFolderTree
            folders={folders}
            setFolderId={setFolderId}
            refetchFolders={handleGetSiteFolders}
            handleOpenNode={handleOpenNode}
            openedNodes={openedNodes}
            setOpenedNodes={setOpenedNodes}
            selectedFolderId={folderId}
            setSearchQuery={setSearchQuery}
            setSearchResults={setSearchResults}
          />
        </>
      );
    }
    return folders.map(folder => {
      return (
        <TreeNodeContainer
          key={folder.folderId}
          data={folder}
          openedNodes={openedNodes}
          handleOpenNode={handleOpenNode}
          selectedFolderId={folderId}
          setFolderId={setFolderId}
        />
      );
    });
  };

  const renderFolderTree = () => {
    if (loading) {
      return <CategorySkeleton />;
    }

    if (detailedSearchOpen && detailedSearchCalled) {
      return (
        <FolderList
          siteFolders={siteFolders}
          setFolderId={setFolderId}
          loading={searchResultsLoading}
          error={searchResultsError}
          detailedSearchCalled={detailedSearchCalled}
        />
      );
    }

    return <Box>{folders && renderCategoryTree()}</Box>;
  };

  return (
    <Box className={classes.productMenuCategory}>{renderFolderTree()}</Box>
  );
};

FolderCategoryTree.defaultProps = {
  folderId: null,
  folders: [],
  searchResultsError: null,
  openedNodes: [],
};

FolderCategoryTree.propTypes = {
  folderId: PropTypes.number,
  view: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number.isRequired,
    })
  ),
  handleGetSiteFolders: PropTypes.func.isRequired,
  handleOpenNode: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  setOpenedNodes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  detailedSearchOpen: PropTypes.bool.isRequired,
  detailedSearchCalled: PropTypes.bool.isRequired,
  siteFolders: PropTypes.arrayOf(
    PropTypes.shape({ siteName: PropTypes.string })
  ).isRequired,
  searchResultsLoading: PropTypes.bool.isRequired,
  searchResultsError: PropTypes.string,
  setFolderId: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
};

export default FolderCategoryTree;

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { GET_PRODUCT_IMAGES } from "../../../graphql/queries";
import ImageWithFallback from "../../common/ImageWithFallback";

const useStyles = makeStyles(theme => ({
  imageItem: {
    padding: 10,
    border: "1px solid #f1f1f1",
    borderRadius: 3,
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.06)",
    "@media print": {
      display: "none!important",
    },
  },
  image: {
    height: 100,
    objectFit: "cover",
    width: "100%",
    "@media print": {
      display: "none!important",
    },
  },
  imageName: {
    fontSize: 11,
    fontWeight: 400,
    color: theme.palette.component.productBoxColor,
    marginTop: 5,
    marginBottom: 0,
    "@media print": {
      display: "none!important",
    },
  },
  printWrap: {
    "@media print": {
      display: "inline-block !important",
      width: "16.666667% !important",
      verticalAlign: "middle",
    },
  },
  toPrintImage: {
    display: "none",
  },
}));

const PrintImageItem = ({ imageBasketItem }) => {
  const classes = useStyles();
  const [productImages, setProductImages] = useState({});
  const { thumbnailImageUri, productImageUri } = productImages;

  const { imageName, productId } = imageBasketItem;

  const { data, error, loading } = useQuery(GET_PRODUCT_IMAGES, {
    variables: { productId },
  });

  useEffect(() => {
    if (data && data.product) {
      const { product } = data;
      setProductImages(product);
    }

    if (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [data]);

  return (
    <Grid
      item
      xs={6}
      sm={3}
      md={2}
      className={classes.printWrap}
      justifyContent="flex-start"
    >
      <Box className={classes.imageItem}>
        {loading ? (
          <Skeleton
            animation="wave"
            height={100}
            variant="rect"
            width="100%"
          />
        ) : (
          <ImageWithFallback
            alt={imageName}
            className={classes.image}
            imageUrl={thumbnailImageUri}
          />
        )}
        <Typography className={classes.imageName}>{imageName}</Typography>
      </Box>
      <Box className={classes.toPrintImage}>
        <img src={productImageUri} alt="high res image" />
      </Box>
    </Grid>
  );
};

PrintImageItem.propTypes = {
  imageBasketItem: PropTypes.shape({
    imageName: PropTypes.string,
    itemId: PropTypes.number,
    productId: PropTypes.string,
    settings: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default PrintImageItem;

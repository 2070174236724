import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { FormattedMessage } from "react-intl";
import { PrimaryButton, Dropzone } from "../../common";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 15,
    marginBottom: 10,
    alignItems: "center",
  },
  loading: {
    color: "white",
  },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "5px 15px",
    backgroundColor: "#66615B",
    color: "#fff",
    minWidth: 196,
    marginLeft: 15,
    marginRight: 7,
  },
  uploadMessage: {
    margin: "0.4em 0!important",
  },
  dropzoneContainer: {
    marginTop: "0px!importnat",
  },
}));

const getHasProductsToAdd = propertyFormValues => {
  if (
    !propertyFormValues ||
    !Array.isArray(propertyFormValues) ||
    propertyFormValues.length < 1
  ) {
    return false;
  }

  for (let i = 0; i < propertyFormValues.length; i += 1) {
    const file = propertyFormValues[i];

    const { productionSettings, isIncluded = false } = file || {};
    const { orderQuantity = 0 } = productionSettings || {};

    if (
      isIncluded &&
      orderQuantity !== null &&
      orderQuantity !== undefined &&
      orderQuantity > 0
    ) {
      return true;
    }
  }

  return false;
};

const getHasFoldersToAdd = folderPropertyFormValues => {
  if (
    !folderPropertyFormValues ||
    !Array.isArray(folderPropertyFormValues) ||
    folderPropertyFormValues.length < 1
  ) {
    return false;
  }

  for (let i = 0; i < folderPropertyFormValues.length; i += 1) {
    const file = folderPropertyFormValues[i];

    const { productionSettings, isIncluded = false } = file || {};
    const { orderQuantity = 0 } = productionSettings || {};
    if (
      isIncluded &&
      orderQuantity !== null &&
      orderQuantity !== undefined &&
      orderQuantity > 0
    ) {
      return true;
    }
  }

  return false;
};

const validateFields = properties => {
  let newProperties = {};
  let filledMandatoryFields = [];

  const keys = Object.keys(properties);

  keys.forEach(x => {
    const fieldKeys = Object.keys(properties[x]);
    // check if one of the fields is filled
    const tempField = fieldKeys.some(field => properties[x][field]);

    if (tempField) {
      const property = properties[x];
      newProperties = { ...newProperties, [x]: property };
    }
  });

  if (!isEmpty(newProperties)) {
    const newKeys = Object.keys(newProperties);
    newKeys.forEach(x => {
      const fieldKeys = Object.keys(newProperties[x]);
      const isFilled = fieldKeys.every(field => newProperties[x][field]);
      filledMandatoryFields = [...filledMandatoryFields, isFilled];
    });
  }

  let isValidated = false;
  if (filledMandatoryFields.length > 0) {
    isValidated = filledMandatoryFields.every(x => x === true);
  } else {
    isValidated = false;
  }

  return !isValidated;
};

const ModalActions = ({
  handleBtnOrder,
  loading,
  propertyFormValues,
  folderPropertyFormValues,
  uploadFiles,
  refreshDropzone,
  productionSettingErrors,
}) => {
  const classes = useStyles();

  const hasProductsToAdd = getHasProductsToAdd(propertyFormValues);
  const hasFoldersToAdd = getHasFoldersToAdd(folderPropertyFormValues);
  const toAddToBasket = hasProductsToAdd || hasFoldersToAdd;
  //const validatedFields = validateFields(productionSettingErrors);

  return (
    <Box className={classes.actions}>
      {refreshDropzone === false && (
        <Dropzone
          uploadFiles={uploadFiles}
          customClass={classes.dropzoneContainer}
          customMessageClass={classes.uploadMessage}
        />
      )}

      <PrimaryButton
        onClick={() => handleBtnOrder()}
        disabled={loading || !toAddToBasket}
        marginLeft={15}
        endIcon={
          loading ? (
            <CircularProgress className={classes.loading} size={20} />
          ) : null
        }
      >
        <FormattedMessage id="context.addCart" />
      </PrimaryButton>
    </Box>
  );
};

ModalActions.propTypes = {
  handleBtnOrder: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  propertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  folderPropertyFormValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default memo(ModalActions);

import React, { memo, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { TreeItem } from "@mui/lab";
import { makeStyles } from "@mui/styles";

import {
  MOVE_GROUP,
  MOVE_USERS,
} from "../../../../graphql/mutations/CompanyAdminContext";
import { getAdminSelectedCompanyId } from "../../../../helpers/adminSelectors";
import GroupLabel from "./GroupLabel";

const useStyles = makeStyles(theme => ({
  draggedOver: {
    backgroundColor: "transparent",
    border: `2px dashed ${theme.palette.primary.dark}`,
    padding: 0,
  },
  notDraggedOver: {
    backgroundColor: "transparent",
    padding: 1,
  },
  selectedRow: props => ({
    "&:focus > .MuiTreeItem-content ": {
      backgroundColor: theme.palette.component.selectedFolder,
    },
    "& .MuiTreeItem-content": {
      backgroundColor: props.isSelected
        ? theme.palette.component.selectedFolder
        : "transparent",
      borderRadius: 2,
      alignItems: "flex-start",
    },
    "& .MuiTreeItem-group": {
      marginTop: 2,
    },
  }),
}));

const RowData = ({
  children,
  group,
  groupTree,
  handleContextMenu,
  handleOpenNode,
  moveFolderEnabled,
  onGroupRowClick,
  onUsersMoved,
  refetch,
  selectedFolderIds,
  setGroupTree,
}) => {
  const alert = useAlert();

  const companyId = useSelector(getAdminSelectedCompanyId);

  const [moveUsers] = useMutation(MOVE_USERS);
  const [moveGroup] = useMutation(MOVE_GROUP);

  const [draggedOver, setDraggedOver] = useState(false);

  const isSelected = group && selectedFolderIds.find(x => x === group.groupId);
  const dynamicStyle = {
    isSelected,
    };
    

  const classes = useStyles(dynamicStyle);

  if (!group) return null;

  const handleOnGroupRowClick = () => {
    handleOpenNode(group.groupId);
    onGroupRowClick(group);
  };

  const handleMoveUsers = async (e, item) => {
    const draggedUser = e.dataTransfer.getData("users");
    const userIds = JSON.parse(draggedUser);
    const targetGroup = item.groupId;
    try {
      const res = await moveUsers({
        variables: { userIds, targetGroup, companyId },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.moveUsers
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyGroupSettings.moveUsers;

        if (success) {
          alert.success(
            <FormattedMessage id="userGroups.success.usersMoved" />
          );
          if (typeof onUsersMoved === "function") {
            onUsersMoved({ userIds, targetGroupId: targetGroup });
          }
        } else {
          errorMovingUsers("No success.");
        }
      } else {
        errorMovingUsers("No result.");
      }
    } catch (err) {
      errorMovingUsers(err);
    }
  };

  const errorMovingUsers = err => {
    console.error("Error moving users", err ?? "");
    alert.error(<FormattedMessage id="userGroups.error.couldNotMoveUsers" />);
  };

  const checkIfValidTarget = (sourceGroupId, targetGroupId) => {
    var node = groupTree[`g_${targetGroupId}`];
    while (!!node) {
      if (node.groupId === sourceGroupId) {
        return false;
      }
      node = groupTree[`g_${node.parentGroupId}`];
    }
    return true;
  };

  const moveGroupNode = (draggedGroup, targetGroupId) => {
    const previousParentGroupId = draggedGroup.parentGroupId;
    const newParentGroup = { ...groupTree[`g_${targetGroupId}`] };

    var group = {
      ...draggedGroup,
      parentGroupId: targetGroupId,
      isRoot: false,
    };

    var newTargetGroupChildGroups = null;

    if (!newParentGroup.childGroups) {
      newTargetGroupChildGroups = [group];
    } else {
      newTargetGroupChildGroups = [group, ...newParentGroup.childGroups];
    }

    let newGroupTreeData = {
      ...groupTree,
      [`g_${group.groupId}`]: { ...group },
      [`g_${targetGroupId}`]: {
        ...newParentGroup,
        childGroupIds: [group.groupId, ...newParentGroup.childGroupIds],
        childGroups: newTargetGroupChildGroups,
      },
    };

    if (previousParentGroupId) {
      let draggedGroupParentGroup = groupTree[`g_${previousParentGroupId}`];
      let newParentGroupChildIds = [];
      let newParentGroupChildGroups = null;
      if (draggedGroupParentGroup) {
        newParentGroupChildIds =
          draggedGroupParentGroup && draggedGroupParentGroup.childGroupIds
            ? draggedGroupParentGroup.childGroupIds.filter(
                x => x !== group.groupId
              )
            : [];
        newParentGroupChildGroups =
          draggedGroupParentGroup && draggedGroupParentGroup.childGroups
            ? draggedGroupParentGroup.childGroups.filter(
                x => x.groupId !== group.groupId
              )
            : null;
      }
      draggedGroupParentGroup = {
        ...draggedGroupParentGroup,
        childGroupIds: newParentGroupChildIds,
        childGroups: newParentGroupChildGroups,
      };
      newGroupTreeData = {
        ...newGroupTreeData,
        [`g_${previousParentGroupId}`]: draggedGroupParentGroup,
      };
    }

    setGroupTree(newGroupTreeData);
  };

  const handleMoveGroup = async (e, item) => {
    const draggedGroupJSON = e.dataTransfer.getData("dragged_group");
    const draggedGroup = JSON.parse(draggedGroupJSON);
    const targetGroupId = item.groupId;

    var isValidTarget = checkIfValidTarget(draggedGroup.groupId, targetGroupId);

    if (
      !isValidTarget ||
      targetGroupId === draggedGroup.parentGroupId ||
      targetGroupId === draggedGroup.groupId
    ) {
      console.info("Group not moved.");
      return;
    }

    // Start: Will create the illusion that the group is moved instantly
    moveGroupNode(draggedGroup, targetGroupId);
    // End: Will create the illusion that the group is moved instantly

    try {
      const res = await moveGroup({
        variables: {
          groupId: draggedGroup.groupId,
          parentGroupId: targetGroupId,
          position: 0,
        },
      });

      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.moveGroup
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyGroupSettings.moveGroup;

        if (success) {
          alert.success(
            <FormattedMessage id="userGroups.success.groupMoved" />
          );
        } else {
          console.error("Error moving group");
          alert.error(
            <FormattedMessage id="userGroups.error.couldNotMoveGroup" />
          );
          refetch();
        }
      } else {
        console.error("Error moving group");
        alert.error(
          <FormattedMessage id="userGroups.error.couldNotMoveGroup" />
        );
        refetch();
      }
    } catch (err) {
      console.error("Error moving group", err);
      alert.error(<FormattedMessage id="userGroups.error.couldNotMoveGroup" />);
      refetch();
    }
  };

  const handleDrop = (e, item) => {
    e.stopPropagation();
    setDraggedOver(false);
    const action = e.dataTransfer.getData("action");
    if (action === "MOVE_USERS") {
      handleMoveUsers(e, item);
    }
    if (action === "MOVE_GROUP") {
      handleMoveGroup(e, item);
    }
  };

  const handleFolderDragOver = (e, item) => {
    e.preventDefault();
    setDraggedOver(true);
  };

  const handleOFolderDragLeave = (e, item) => {
    e.preventDefault();
    setDraggedOver(false);
  };

  const handleOnDragOver = (e, item) => {
    e.preventDefault();
  };

  const handleDragStart = (e, item) => {
    e.stopPropagation();
    e.dataTransfer.setData("dragged_group", JSON.stringify(item));
    e.dataTransfer.setData("action", "MOVE_GROUP");
    };

  const renderGroupLabel = () => {
    return (
      <GroupLabel
        groupName={group.name}
        handleFolderDragOver={handleFolderDragOver}
        handleOFolderDragLeave={handleOFolderDragLeave}
        handleOnGroupRowClick={handleOnGroupRowClick}        
      />
    );
  };

  return (
    <>
      <TreeItem
        nodeId={group.groupId}
              label={renderGroupLabel()}
              onFocusCapture={e => e.stopPropagation()}
        onContextMenu={e => handleContextMenu(e, group)}
        onDrop={e => handleDrop(e, group)}
        onDragOver={handleOnDragOver}
        onDragStart={e => handleDragStart(e, group)}
        draggable={moveFolderEnabled}
        className={clsx([
          draggedOver ? classes.draggedOver : classes.notDraggedOver,
          classes.selectedRow,
        ])}
      >
        {children}
      </TreeItem>
    </>
  );
};

RowData.defaultProps = {
  handleOpenNode: () => {},
  moveFolderEnabled: false,
  children: null,
  isEditMode: false,
  selectedNode: null,
};

RowData.propTypes = {
  selectedNode: PropTypes.shape({}),
  refetch: PropTypes.func.isRequired,
  children: PropTypes.node,
  moveFolderEnabled: PropTypes.bool,
  handleContextMenu: PropTypes.func.isRequired,
  handleOpenNode: PropTypes.func,
  group: PropTypes.shape({
    childFolders: PropTypes.arrayOf(
      PropTypes.shape({
        groupId: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ),
    childFolderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    colorValue: PropTypes.string.isRequired,
    groupId: PropTypes.number.isRequired,
    parentGroupId: PropTypes.number,
    hasChildFolders: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedFolderIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  onGroupRowClick: PropTypes.func.isRequired,
  onUsersMoved: PropTypes.func.isRequired,
  handleSetEditNode: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  groupTree: PropTypes.objectOf(
    PropTypes.shape({
      childGroups: PropTypes.arrayOf(
        PropTypes.shape({
          groupId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      childGroupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      groupId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setGroupTree: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
};

export default memo(RowData);

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../fragments/ErrorResult";

const POST_LOGON_STATUS = gql`
  query getPostLogonStatus {
    postLogonContext {
      postLogonStatus {
        __typename
        success
        mustAcceptTermsOfUse
        mustSetupMFA
        requiredSecurityLevel
        username
        errorResult {
          ...ErrorResultFields
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default POST_LOGON_STATUS;

import { handleActions } from "redux-actions";
import {
  setSearchProductIds,
  setSearchFolders,
  setSearchFolderId,
  setSearchLoading,
  setSearchErrors,
  setSearchCalled,
  setSearchViewSettings,
  setSearchResourceIds,
  setSearchFolderName,
} from "../../../../actions";

const defaultState = {
  productIds: {},
  resourceIds: {},
  folders: [],
  folderId: null,
  folderName: "",
  loading: false,
  error: null,
  called: false,
  settings: {},
};

export default handleActions(
  {
    [setSearchProductIds]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        productIds: items,
      };
    },
    [setSearchResourceIds]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        resourceIds: items,
      };
    },
    [setSearchFolders]: (state, { payload }) => {
      const { folders } = payload;
      return {
        ...state,
        folders,
      };
    },
    [setSearchFolderId]: (state, { payload }) => {
      const { id } = payload;
      return {
        ...state,
        folderId: id,
      };
    },
    [setSearchFolderName]: (state, { payload }) => {
      const { name } = payload;
      return {
        ...state,
        folderName: name,
      };
    },
    [setSearchLoading]: (state, { payload }) => {
      const { loading } = payload;
      return {
        ...state,
        loading,
      };
    },
    [setSearchErrors]: (state, { payload }) => {
      const { error } = payload;
      return {
        ...state,
        error,
      };
    },
    [setSearchCalled]: (state, { payload }) => {
      const { called } = payload;
      return {
        ...state,
        called,
      };
    },
    [setSearchViewSettings]: (state, { payload }) => {
      const { settings } = payload;
      return {
        ...state,
        settings,
      };
    },
  },
  defaultState
);

import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { LoadingScreen } from "../common";

export const Timeout = () => (
  <AuthConsumer>
    {({ clearSessionInfo, signinRedirect }) => {
      clearSessionInfo();
      signinRedirect();
      return <LoadingScreen />;
    }}
  </AuthConsumer>
);

import { createActions } from "redux-actions";

export const {
  setSiteFolders,
  setSiteFoldersLoading,
  setSiteFoldersError,
} = createActions({
  SET_SITE_FOLDERS: ({ folders }) => ({ folders }),
  SET_SITE_FOLDERS_LOADING: ({ loading }) => ({ loading }),
  SET_SITE_FOLDERS_ERROR: ({ error }) => ({ error }),
});

import { gql } from '@apollo/client';
import WorkspaceOrderGroupCandidateListItemFragment from "../../../fragments/WorkspaceOrderGroupCandidateListItem";
import ErrorResultFragment from "../../../fragments/ErrorResult";

const LIST_WORKSPACE_ORDER_GROUP_CANDIDATES = gql`
  query ListWorkspaceOrderGroupCandidates {
    companyAdminContext {
      companyWorkspaces {
        listWorkspaceOrderGroupCandidates {
          errorResult {
            ...ErrorResultFields
          }
          items {
            ...WorkspaceOrderGroupCandidateListItem
          }
          success
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${WorkspaceOrderGroupCandidateListItemFragment}
`;

export default LIST_WORKSPACE_ORDER_GROUP_CANDIDATES;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const PICK_ORDER = gql`
  mutation PickOrderRow($pickingListOrdersItemId: ID!, $signature: String) {
    pickingListContext {
      pickOrder(
        pickingListOrdersItemId: $pickingListOrdersItemId
        signature: $signature
      ) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }

  ${ErrorResultFragment}
`;

export default PICK_ORDER;

import React, { Fragment, Component } from "react";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import PublicLayout from "./PublicLayout";

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.component.pageBackground,
    height: "100%",
    maxHeight: "100%",
    position: "relative",
    minHeight: "100vh",
    paddingTop: 2,
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: 55,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 36,
    },
  },
});

class PublicView extends Component {
  render() {
    const { children, classes } = this.props;

    return (
      <Fragment>
        <PublicLayout />
        <Box className={classes.root}>{children}</Box>
      </Fragment>
    );
  }
}

export default withStyles(useStyles)(PublicView);

import { gql } from '@apollo/client';

const GET_TEMPLATE = gql`
  query getTemplate($productId: ID!) {
    dynamicTemplate(productId: $productId) {
      fields {
        imageFolderId
        fieldName
        displayName
        description
        fieldType
        fieldValue
        imageList {
          items {
            label
            value
          }
        }
        required
        sortNo
        textList {
          items {
            label
            value
          }
          selectionType
        }
      }
      searchableFields
    }
  }
`;
export { GET_TEMPLATE };

import { gql } from '@apollo/client';

const AddressFragment = gql`
  fragment AddressDTO on AddressDTO {
    address1
    address2
    address3
    address4
    address5
    city
    contact
    country
    customerId
    email
    reference
    telephone
    zip
  }
`;

export default AddressFragment;

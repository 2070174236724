import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import DynamicField from "../DynamicField";
import DynamicFieldFooter from "../DynamicFieldFooter";
import TabPanel from "../../TabPanel";
import SearchField from "./SearchField";

const useStyles = makeStyles(theme => ({
  noPadding: {
    padding: 0,
  },
  dynamicWrap: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
  },
  formWrap: {
    padding: 10,
    paddingBottom: 70,
    overflow: "auto",
    minHeight: "75vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      paddingBottom: 30,
      minHeight: "unset",
    },
  },
  uploadBox: { paddingTop: 10 },
  input: {
    display: "none",
  },
  searchFieldCont: {
    height: 30,
  },
}));

const TabPanelInfo = ({
  tab,
  formValues,
  error,
  handleChange,
  editMode,
  quantity,
  product,
  hasError,
  handleTab,
  templates,
  onUpdateButton,
  setFormValues,
  handleDownloadPdf,
}) => {
  const classes = useStyles();
  const { id: productId } = product || {};

  const { searchableFields } = templates;
  return (
    <TabPanel value={tab} index={0} boxCustom={classes.noPadding}>
      <div className={classes.dynamicWrap}>
        <div className={classes.formWrap}>
          {searchableFields && (
            <SearchField product={product} setFormValues={setFormValues} />
          )}

          {templates.fields.map(field => (
            <DynamicField
              key={field.fieldName}
              formValues={formValues}
              field={field}
              error={error}
              handleChange={handleChange}
              productId={productId}
              setFormValues={setFormValues}
            />
          ))}
        </div>
        <DynamicFieldFooter
          onNextButton={() => handleTab(null, 1)}
          hasError={hasError}
          quantity={quantity}
          nextButton={!editMode}
          onUpdateButton={onUpdateButton}
          editMode={editMode}
          product={product}
          handleDownloadPdf={handleDownloadPdf}
        />
      </div>
    </TabPanel>
  );
};

TabPanelInfo.defaultProps = {
  formValues: {},
  error: {},
  editMode: false,
  selectedUser: null,
};

TabPanelInfo.propTypes = {
  handleDownloadPdf: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({}),
  tab: PropTypes.number.isRequired,
  hasError: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    dynamicFieldValues: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    id: PropTypes.string.isRequired,
    minimumPrice: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    quantities: PropTypes.shape({
      selectionType: PropTypes.string.isRequired,
      valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  formValues: PropTypes.shape({}),
  error: PropTypes.shape({}),
  templates: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    searchableFields: PropTypes.bool,
  }).isRequired,
  onUpdateButton: PropTypes.func.isRequired,
  handleTab: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  quantity: PropTypes.number.isRequired,
  setFormValues: PropTypes.func.isRequired,
};

export default TabPanelInfo;

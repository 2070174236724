import React, { Fragment, useEffect, useState } from "react";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import RenderCompany from "./RenderCompany";
import RenderNetwork from "./RenderNetwork";

const CompanyNetworkTree = ({
  handleContextMenu,
  handleDeleteNetwork,
  handleError,
  handleOnNodeToggle,
  handleRenameNetwork,
  handleSetEditNode,
  isEditMode,
  openedNodes,
  parentNetworkId,
  refresh,
  selectedNode,
  treeData,
}) => {
  const [tree, setTree] = useState([]);
  const [companyIds, setCompanyIds] = useState([]);

  useEffect(() => {
    const tempTree = Object.values(treeData).filter(
      x => x.isNetwork && !x.isRoot && x.parentNetworkId === parentNetworkId
    );
    setTree(tempTree);
    const childCompanyIds =
      treeData[`${parentNetworkId}`]?.childCompanyIds ?? [];
    setCompanyIds(childCompanyIds);
  }, [treeData]);

  if (
    (!Array.isArray(tree) || tree.length === 0) &&
    !(Array.isArray(companyIds) && companyIds.length > 0)
  ) {
    return null;
  }

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpandIcon={<ArrowRight />}
      expanded={openedNodes}
      onNodeToggle={handleOnNodeToggle}
    >
      {tree.map((node, idx) => (
        <Fragment key={`network-item-${node.networkId}-${idx}`}>
          <RenderNetwork
            handleContextMenu={handleContextMenu}
            handleDeleteNetwork={handleDeleteNetwork}
            handleError={handleError}
            handleRenameNetwork={handleRenameNetwork}
            handleSetEditNode={handleSetEditNode}
            idx={idx}
            isEditMode={isEditMode}
            node={treeData[node.networkId]}
            refresh={refresh}
            selectedNode={selectedNode}
            treeData={treeData}
          />
        </Fragment>
      ))}

      {companyIds.map(c => (
        <Fragment key={c}>
          <RenderCompany
            company={treeData[`c_${c}`]}
            handleContextMenu={handleContextMenu}
            handleMoveCompany={() => {}}
            key={c}
            treeData={treeData}
          />
        </Fragment>
      ))}
    </TreeView>
  );
};

export default CompanyNetworkTree;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: 0,
    "& label + .MuiInput-formControl": {
      marginTop: 22,
    },
    // "& label + .MuiInput-formControl.Mui-focused": {
    //   marginTop: 16
    // },
    "& .MuiInputLabel-shrink": {
      top: 10,
    },
  },
  textField: {
    fontSize: 12,
    width: "100%",
  },
  FormHelperText: {
    color: theme.palette.common.white,
    backgroundColor: "#f44336",
    display: "inline-block",
    marginLeft: 5,
    fontSize: 10,
    padding: "2px 5px",
    borderRadius: 3,
  },
}));

const InputField = ({
  error,
  isRequired,
  fieldName,
  displayName,
  description,
  handleChange,
  value,
}) => {
  const classes = useStyles();
  const shrink = true;
  let filteredDescription = description.replace("[", "");
  filteredDescription = filteredDescription.replace("]", "");
  return (
    <div>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel
          error={error ? error.error : false}
          required={isRequired}
          className={classes.textField}
          shrink={shrink}
        >
          {value && <span>{displayName === "" ? fieldName : displayName}</span>}
          {filteredDescription && (
            <span style={{ color: "grey" }}>{`${value &&
              ` | `}${filteredDescription}`}</span>
          )}
          {error && error.error && (
            <FormHelperText className={classes.FormHelperText}>
              <FormattedMessage id="context.required" />
            </FormHelperText>
          )}
        </InputLabel>
        <Input
          error={error ? error.error : false}
          required={isRequired}
          name={fieldName}
          className={classes.textField}
          onChange={handleChange}
          value={value}
          shrink={shrink}
          variant="outlined"
          placeholder={displayName}
        />
      </FormControl>
    </div>
  );
};

InputField.defaultProps = {
  displayName: null,
  isRequired: false,
  error: null,
  description: "",
};

InputField.propTypes = {
  description: PropTypes.string,
  error: PropTypes.shape({
    error: PropTypes.bool,
  }),
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default InputField;

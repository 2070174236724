import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import CustomTextField from "../common/TextField";
import OneStreamLabel from "./OneStreamLabel";

const useStyles = makeStyles(() => ({
  formControl: {
    marginBottom: 15,
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 3,
    },
  },
}));

const FormInput = ({
  name,
  formValues,
  isValid,
  label,
  errorMessage,
  placeholder,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.formControl}>
      <OneStreamLabel message={label} />
      <CustomTextField
        placeholder={placeholder}
        name={name}
        value={formValues[name]}
        onChange={onChange}
        error={!isValid}
      />
      {!isValid && <OneStreamLabel message={errorMessage} isError />}
    </Box>
  );
};

FormInput.defaultProps = {
  name: "",
  formValues: {},
  isValid: true,
  placeholder: "",
  label: null,
  errorMessage: null,
};

FormInput.propTypes = {
  name: PropTypes.string,
  formValues: PropTypes.shape({}),
  isValid: PropTypes.bool,
  handleCheckField: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  errorMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default FormInput;

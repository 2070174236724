import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = ({ primary, size }) => {
  const color = primary ? "primary" : "secondary";

  return <CircularProgress color={color} size={size} />;
};

Loading.defaultProps = {
  primary: false,
  size: 40,
};

Loading.propTypes = {
  primary: PropTypes.bool,
  size: PropTypes.number,
};

export default Loading;

import { handleActions } from "redux-actions";
import { imageBasketInitialize, imageBasketAddItem } from "../../../../actions";

const defaultState = {
  imageBasketItems: [],
};

export default handleActions(
  {
    [imageBasketInitialize]: (state, { payload }) => {
      const { items } = payload;
      return {
        ...state,
        imageBasketItems: items,
      };
    },
    [imageBasketAddItem]: (state, { payload }) => {
      const { item } = payload;
      return {
        ...state,
        imageBasketItems: [...state.imageBasketItems, item],
      };
    },
  },
  defaultState
);

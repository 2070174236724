import { Grid, MenuItem, Select } from "@mui/material";
import { TextField } from "../../components/common";

const BaseTypeEdit = ({ projectField, onFieldChange }) => {


  return (
    
    <Grid container>
      <Grid item sx={4}>Name: <TextField value={projectField.name} onChange={e => onFieldChange("name", e.target.value)}/></Grid>
      <Grid item sx={4}>Typ: <Select value={projectField.type } onChange={e => onFieldChange("type", e.target.value)}>
          <MenuItem value={0}>Sträng</MenuItem>
          <MenuItem value={1}>Siffra</MenuItem>
        <MenuItem value={2}>Datum</MenuItem>
        <MenuItem value={3}>Status</MenuItem>
        <MenuItem value={4}>Tid</MenuItem>
        </Select></Grid>
    </Grid> 
    
    );
}

export default BaseTypeEdit;
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import EditMenuSidebar from "./EditMenuSidebar";
import MainContent from "./MainContent";
import MainFooter from "./MainFooter";

const useStyles = makeStyles(() => ({
  cardBody: {
    height: 600,
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    height: "100%",
  },
}));

const Body = ({
  handleAddToMenu,
  handleOnRemoveSortlyMenu,
  handlePageAddToMenu,
  handlePageCheckboxChange,
  handleSaveMenu,
  setSortlyMenu,
  siteMenuPages,
  sortlyMenu,
  handleRenameLabel,
  siteMenus,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardBody}>
      <Grid container spacing={0} className={classes.container}>
        <EditMenuSidebar
          siteMenuPages={siteMenuPages}
          handlePageCheckboxChange={handlePageCheckboxChange}
          handlePageAddToMenu={handlePageAddToMenu}
          handleAddToMenu={handleAddToMenu}
        />
        <Grid item xs={12} md={9} className={classes.content}>
          <MainContent
            sortlyMenu={sortlyMenu}
            setSortlyMenu={setSortlyMenu}
            handleOnRemoveSortlyMenu={handleOnRemoveSortlyMenu}
            handleRenameLabel={handleRenameLabel}
          />
          <MainFooter handleSaveMenu={handleSaveMenu} />
        </Grid>
      </Grid>
    </Box>
  );
};

Body.defaultProps = {};

Body.propTypes = {
  handlePageCheckboxChange: PropTypes.func.isRequired,
  handleSaveMenu: PropTypes.func.isRequired,
  handlePageAddToMenu: PropTypes.func.isRequired,
  siteMenuPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortlyMenu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSortlyMenu: PropTypes.func.isRequired,
  handleOnRemoveSortlyMenu: PropTypes.func.isRequired,
  handleAddToMenu: PropTypes.func.isRequired,
  handleRenameLabel: PropTypes.func.isRequired,
};

export default Body;

import PropTypes from "prop-types";
import TreeItem from "@mui/lab/TreeItem";
import { makeStyles } from "@mui/styles";
import { STRUCTURE_TYPES } from "../../../constant/types";
import StructureLabel from "./StructureLabel";
import StructureNode from "./StructureNode";

const useStyles = makeStyles(() => ({
  item: {
    "& .MuiTreeItem-label": {
      color: "#5b5654",
    },
  },
}));

const StructureItem = ({ item, onClick }) => {
  const { type } = item;

  const classes = useStyles();

  switch (type) {
    case STRUCTURE_TYPES.FOLDER:
      return (
        <StructureNode
          node={item}
          onClick={onClick}
        />
      );

    case STRUCTURE_TYPES.SITE:
      return (
        <TreeItem
          className={classes.item}
          label={<StructureLabel item={item} />}
          nodeId={item.id}
          onClick={() => onClick(item)}
        />
      );

    default:
      console.warn(`Item type ${type} is not implemented.`);
      return null;
  }
};

StructureItem.defaultProps = {
  item: null,
};

StructureItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};

export default StructureItem;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Icon, Typography, IconButton } from "@mui/material";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  membersContainerItem: {
    display: "flex",
  },
  userBox: {
    padding: 10,
    margin: "0 0 23px 0",
    border: "1px solid #e3e3e3",
    borderRadius: 4,
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.05)",
    position: "relative",
    textAlign: "center",
    width: "100%",
    "&:hover": {
      cursor: "grab",
    },
  },
  profile: {
    width: 40,
    height: 40,
    objectFit: "cover",
    borderRadius: "50%",
  },
  name: {
    fontSize: 12,
    fontWeight: 500,
    wordBreak: "break-word",
    marginBottom: 2,
  },
  email: {
    fontSize: 10,
    fontWeight: 600,
    wordBreak: "break-word",
    marginBottom: 5,
  },
  username: {
    fontSize: 10,
    fontWeight: 600,
    wordBreak: "break-word",
    marginBottom: 2,
  },
  role: {
    fontSize: 10,
    wordBreak: "break-word",
    textTransform: "uppercase",
  },
  selected: {
    background: theme.palette.background.gray,
  },
  iconButtonEdit: {
    padding: 2,
    color: theme.palette.primary.main,
  },
  iconButtonLogin: {
    padding: 2,
    color: theme.palette.common.link,
    marginLeft: 4,
  },
  iconButtonRemove: {
    padding: 2,
    color: theme.palette.common.red,
    marginLeft: 4,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
  },
  iconEdit: {
    fontSize: 10,
  },
  iconLogin: {
    fontSize: 13,
  },
  iconRemove: {
    fontSize: 13,
  },
  userActions: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  loading: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  subscriptionLevel: {
    fontSize: 9,
    fontWeight: 500,
    color: "#aaa",
    marginBottom: 0,
    wordBreak: "break-word",
  },
  breadcrumbs: {
    fontSize: 9,
    fontWeight: 500,
    color: "#666",
    marginBottom: 0,
    wordBreak: "break-word",
  },
  crumbs: {
    cursor: "pointer",
  },
}));

const MemberBox = ({
  member,
  handleOpenUserModal,
  handleOpenDeleteModal,
  selectedMembers,
  setOpenedNodes,
  setSelectedGroupId,
  setSelectedMembers,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    avatar,
    firstname,
    groupBreadcrumbs = {},
    lastname,
    roleName,
    subscriptionLevel,
    userId,
    username,
  } = member;
  const { groups = [] } = groupBreadcrumbs;

  const handleBreadCrumbClick = (e, idx) => {
    e.preventDefault();
    e.stopPropagation();

    let nodes = [];
    for (let x = 0; x <= idx; x++) {
      nodes = [...nodes, groups[x].groupId];
    }
    const lastItem = nodes.length - 1;

    setSelectedGroupId(nodes[lastItem]);
    setOpenedNodes(nodes);
  };

  const handleDragStart = e => {
    let memberIds = [];

    if (selectedMembers.length > 1) {
      memberIds = [...selectedMembers];
    } else {
      memberIds = [userId];
    }

    e.dataTransfer.setData("users", JSON.stringify(memberIds));
    e.dataTransfer.setData("action", "MOVE_USERS");
  };

  const handleSelectMember = () => {
    if (selectedMembers.includes(userId)) {
      const tempValues = selectedMembers.filter(x => x !== userId);
      setSelectedMembers(tempValues);
    } else {
      setSelectedMembers(prevState => [...prevState, userId]);
    }
  };

  const handleOnDeleteClick = e => {
    e.stopPropagation();
    handleOpenDeleteModal(userId);
  };

  const handleOnEditClick = e => {
    e.stopPropagation();
    handleOpenUserModal("edit", userId);
  };

  const renderBreadcrumbs = () => {
    return (
      <Typography className={classes.breadcrumbs}>
        {groups.map((group, idx) => {
          return (
            <span
              key={`${member.userId}-${idx}`}
              className={classes.crumbs}
              onClick={e => handleBreadCrumbClick(e, idx)}
            >{`${idx > 0 ? " > " : ""}${group.name}`}</span>
          );
        })}
      </Typography>
    );
  };

  return (
    <Grid
      className={classes.membersContainerItem}
      onDragStart={e => handleDragStart(e)}
      draggable
      onClick={handleSelectMember}
      item
      xs={6}
      sm={4}
      md={3}
      key={userId}
    >
      <Box
        className={clsx([
          classes.userBox,
          selectedMembers.includes(userId) && classes.selected,
        ])}
      >
        <img src={avatar} alt="Profile" className={classes.profile} />
        <Typography className={classes.name}>
          {`${firstname} ${lastname}`}
        </Typography>
        <Typography className={classes.username}>{username}</Typography>
        <Typography className={classes.role}>{roleName}</Typography>
        <Typography className={classes.subscriptionLevel}>
          {subscriptionLevel}
        </Typography>
        {renderBreadcrumbs()}

        <Box className={classes.userActions}>
          <IconButton
            className={classes.iconButtonEdit}
            onClick={handleOnEditClick}
            title={intl.formatMessage({ id: "context.edit" })}
          >
            <Icon
              className={clsx([
                "fa fa-pencil-alt",
                classes.icon,
                classes.iconEdit,
              ])}
            />
          </IconButton>

          <IconButton
            className={classes.iconButtonRemove}
            onClick={handleOnDeleteClick}
            title={intl.formatMessage({ id: "context.delete" })}
          >
            <Icon
              className={clsx([
                "fa fa-times",
                classes.icon,
                classes.iconRemove,
              ])}
            />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

MemberBox.defaultProps = {
  member: null,
  selectedMembers: [],
};

MemberBox.propTypes = {
  handleOpenUserModal: PropTypes.func.isRequired,
  handleOpenDeleteModal: PropTypes.func.isRequired,
  member: PropTypes.shape({
    avatar: PropTypes.string,
    firstname: PropTypes.string,
    groupBreadcrumbs: PropTypes.shape({
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          groupId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    lastname: PropTypes.string,
    roleName: PropTypes.string,
    subscriptionLevel: PropTypes.string,
    userId: PropTypes.number,
    username: PropTypes.string,
  }),
  selectedMembers: PropTypes.arrayOf(PropTypes.number),
  setOpenedNodes: PropTypes.func.isRequired,
  setSelectedGroupId: PropTypes.func.isRequired,
  setSelectedMembers: PropTypes.func.isRequired,
};

export default MemberBox;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import SampleProfile from "../../../assets/img/default-avatar.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 15,
    marginLeft: "auto",
  },
  profile: {
    minWidth: 30,
    minHeight: 30,
    marginLeft: 10,
  },
  profileImg: {
    width: 30,
    height: 30,
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "#EDEDED",
  },
  messageBubble: {
    fontSize: 12,
    textAlign: "right",
    padding: 10,
    backgroundColor: "#0499FF",
    borderRadius: 6,
    color: theme.palette.common.white,
    lineHeight: 1.7,
  },
  messageInfo: {
    marginBottom: 5,
    marginRight: 40,
  },
  date: {
    fontSize: 12,
    color: "#888",
    marginRight: 15,
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
  },
}));

const MessageMe = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box
        className={classes.messageInfo}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Typography className={classes.date}>10:45 AM</Typography>
        <Typography className={classes.name}>John Doe</Typography>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.messageBubble}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor.
        </Box>
        <Box className={classes.profile}>
          <img
            src={SampleProfile}
            alt="Profile"
            className={classes.profileImg}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MessageMe;

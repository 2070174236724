import { gql } from '@apollo/client';

const UPDATE_COMMENT_LIKE = gql`
  mutation UpdateCommentLike($commentId: Int!, $like: Boolean) {
    newsContext {
      updateCommentLike(commentId: $commentId, like: $like) {
        commentId
        dislikes
        likes
        userHasDislikedComment
        userHasLikedComment
      }
    }
  }
`;

export default UPDATE_COMMENT_LIKE;

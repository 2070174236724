import { useContext } from "react";
import { projectDnDContext } from "../../context/ProjectDnDContext";

export const useProjectDnDContext = () => {
  const context = useContext(projectDnDContext);

  if (context === undefined) {
    throw new Error(
      "Hook useProjectDnDContext is used outside of its Provider."
    );
  }

  return context;
};

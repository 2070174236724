import React from "react";
import PropTypes from "prop-types";
import ConversationsList from "./ConversationsList";
import ChannelsSkeleton from "../../Skeleton/ChannelsSkeleton";

const ConversationContainer = ({
  activeConversationId,
  conversations,
  loading,
  onConversationSelected,
  onMessageAdded,
}) => {
  if (loading) {
    return <ChannelsSkeleton />;
  }

  return (
    <ConversationsList
      activeConversationId={activeConversationId}
      conversations={conversations}
      onConversationSelected={onConversationSelected}
      onMessageAdded={onMessageAdded}
    />
  );
};

ConversationsList.defaultProps = {
  activeConversationId: null,
  conversations: [],
  loading: false,
};

ConversationsList.propTypes = {
  activeConversationId: PropTypes.number,
  conversations: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  onConversationSelected: PropTypes.func.isRequired,
  onMessageAdded: PropTypes.func.isRequired,
};

export default ConversationContainer;

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  headingWrap: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    marginRight: "auto",
  },
  text: {
    marginBottom: 10,
  },
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    textTransform: "uppercase",
    fontWeight: 600,
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
}));

const ConvertToDo = ({ handleOpenPrint }) => {
  const classes = useStyles();
  return (
    <Box className={classes.headingWrap} display="flex" alignItems="center">
      <Typography className={classes.heading}>
        1. <FormattedMessage id="imageBasket.whatDoYouWantToDo" />
      </Typography>
      <Button className={classes.btn} onClick={handleOpenPrint}>
        <FormattedMessage id="btn.printImageMap" />
      </Button>
    </Box>
  );
};

ConvertToDo.propTypes = {
  handleOpenPrint: PropTypes.func.isRequired,
};

export default ConvertToDo;

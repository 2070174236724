import React,{ useRef, useState } from "react";
import { NumericFormat } from 'react-number-format';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from '@mui/material/Popper';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  TextField,
} from "@mui/material";
import { FormattedCurrency, TextAreaFull } from "../../../common";
import ArticlePricelistAutoComplete from "./ArticlePricelistAutoComplete";

const addBtn = {
  minWidth: "fit-content",
  padding: "0px 4px 0px 2px",
  color: "#2e7d32",
};

const dragIcon = {
  cursor: "grab",
};

const grCell = {
  padding: "0px 0px 0px 0px",
  margin: "2px 8px 2px 0px",
};

const grCell_last = {
  padding: "0px 0px 0px 0px",
  margin: "2px 2px 2px 0px",
};

const grCellRight = {
  textAlign: "right",
};

const grRow = {
  display: "grid",
  gridTemplateColumns: "28px 0.6fr 0.9fr 0.6fr 108px 108px 168px 78px 78px 0.39fr 64px",
  margin: "4px 0px",
  alignItems: "center",
};

const grFreeTextRow = {
  display: "grid",
  gridTemplateColumns: "28px auto 64px",
  margin: "4px 0px",
  alignItems: "center",
};

const noBorderBtm = {
  borderBottom: "none",
};

const removeBtn = {
  minWidth: "fit-content",
  padding: "0px 0px",
  color: "#d32f2f",
};

const amountInput = { width: { sm: 90, md: 100 }, input: { textAlign: "right", padding: "8.5px 14px 8.5px 14px" } };

const discountInput = { width: { sm: 66, md: 70 }, input: { textAlign: "right", padding: "8.5px 14px 8.5px 14px", width: { sm: 66, md: 70 } } };

const VATInput = { width: { sm: 66, md: 70 }, input: { textAlign: "right", padding: "8.5px 2px 8.5px 14px", width: { sm: 66, md: 70 } } };


const priceInput = {width: { sm: 150, md: 160 }, input: { textAlign: "right", padding: "8.5px 14px 8.5px 14px" } };

const unitInput = { width: { sm: 90, md: 100 }, input: { textAlign: "left", padding: "8.5px 144px 8.5px 14px", width: { sm: 90, md: 100 } } };

const endAdornment_ClearIndicator = {
  [`&& .${autocompleteClasses.inputRoot}`]: {
    paddingRight: "20px",
  },
  [`&& .${autocompleteClasses.endAdornment}`]: {
      paddingRight: 0,
      marginRight: "-6px",
  },
  [`&& .${autocompleteClasses.clearIndicator}`]: {
    marginRight: "-14px",
  },
};

const endAdornment_ClearIndicator_children = {
  [`&& .MuiOutlinedInput-root`]: {
    paddingRight: "20px",
  },
  [`&& .MuiAutocomplete-endAdornment`]: {
    paddingRight: 0,
    marginRight: "-6px",
  },
  [`&& .MuiAutocomplete-clearIndicator`]: {
    marginRight: "-14px",
  },
};

const standardFormatProps = {
  size: "small",
  variant: "outlined",
  InputProps: { disableUnderline: true },
};

const SalesDocumentRowTableRow = ({ articles, companyId, disableDelete, index, onAddRow, onChangeRow, onDeleteRow, row, settings }) => {

  const { currencySettings } = settings;

  const handleAddRow = () => onAddRow?.(row, index);
  const handleChangeRow = (name, value) => onChangeRow?.(row, index, name, value);
  const handleDeleteRow = () => onDeleteRow?.(row, index);
  const anchorRef = useRef(null);
  const [openPopper, setOpenPopper] = useState(false);
  
  const handleClick = (event, index = 0) => {
    const option = options[index];
    handleChangeRow("vAT", option);
    setOpenPopper(false);
  };

  const handleClickAway = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenPopper(false);
  };

  const options = [25.0, 12.0, 6.0, 0];

  const units = [{
    unit: "PIECES",
    label: "St"
  }, {
    unit: "GIGA_BYTES",
    label: "Gb"
  },
  {
    unit: "HOURS",
    label: "Timmar"
  },
  {
    unit: "PACKAGE",
    label: "Paket"
  },
  {
    unit: "GRAM",
    label: "Gram"
  }
  ];

  return (
    <>
      { row.unit === "FREE_TEXT_ROW" &&
        (<Box sx={grFreeTextRow}>
          <Box sx={[grCell, noBorderBtm]}><DragIndicatorIcon sx={dragIcon} /></Box>
        <Box sx={[grCell, noBorderBtm]}>
          <TextAreaFull
            defaultValue={row.description}
            onChange={(e) => handleChangeRow("description", e.target.value) }
          />

        </Box>
        <Box align="right" sx={[grCell_last, noBorderBtm]}>
          <IconButton
            onClick={handleAddRow}
            sx={addBtn}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <IconButton
            disabled={disableDelete}
            onClick={handleDeleteRow}
            sx={removeBtn}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Box>
        </Box>)}
      {
        row.unit !== "FREE_TEXT_ROW" && (
          <Box sx={grRow}>
            <Box sx={[grCell, noBorderBtm]}><DragIndicatorIcon sx={dragIcon} /></Box>
            <Box sx={[grCell, noBorderBtm]}>
              <Box component="span" sx={endAdornment_ClearIndicator_children}>
              <ArticlePricelistAutoComplete fullWidth companyId={companyId} articles={articles} selectedArticle={row.article}
                  onSelectArticle={(value) => handleChangeRow("article", value)} />
              </Box>
            </Box>
            <Box align={settings.tableLabels[0].align} sx={[grCell, noBorderBtm]}>
              <TextField
                {...standardFormatProps}
                defaultValue={row.description}
                fullWidth
                onChange={(e) => { handleChangeRow("description", e.target.value) }}
                value={row.description}
              />
            </Box>
            <Box align={settings.tableLabels[1].align} sx={[grCell, noBorderBtm]}>
              <TextField
                {...standardFormatProps}
                defaultValue={row.project}
                fullWidth
                onChange={(e) => { handleChangeRow("projekt", e.target.value) }}
                value={row.projekt}
              />
            </Box>
            <Box align={settings.tableLabels[3].align} sx={[grCell, noBorderBtm]}>
              <NumericFormat sx={amountInput} value={row.amount} defaultValue={row.amount}
                {...standardFormatProps}
                allowedDecimalSeparators={[',', '.']}
                allowLeadingZeros
                allowNegative
                customInput={TextField}
                decimalSeparator={currencySettings.decimalSeparator}
                onValueChange={(values) => {
                  const { formattedValue, value, floatValue } = values;
                  handleChangeRow("amount", floatValue);
                }}
                thousandSeparator={currencySettings.thousandSeparator} />
            </Box>
            <Box align={settings.tableLabels[4].align} sx={[grCell, noBorderBtm]}>

              <Autocomplete
                autoComplete
                freeSolo
                defaultValue={units.filter(n => n.unit === row.unit)[0]}
                filterSelectedOptions
                forcePopupIcon
                disableClearable
                getOptionLabel={(option) => { return option.label }}
                size="small"
                sx={[unitInput], [endAdornment_ClearIndicator]}
                onChange={(event, newValue) => {
                  handleChangeRow("unit", newValue.unit);
                }}
                ListboxProps={{ style: { maxHeight: 300 } }}
                options={units}
                isOptionEqualToValue={(option, value) => { return option.unit === value }} //return value === option.unit || value===""
                renderInput={(params) => <TextField {...params} />}
              />

            </Box>
            <Box align={settings.tableLabels[5].align} sx={[grCell, noBorderBtm, grCellRight]}>
              <NumericFormat sx={priceInput} value={row.price} defaultValue={0}
                onValueChange={(values) => {
                  const { formattedValue, value, floatValue } = values;
                  handleChangeRow("price", floatValue);
                }}
                customInput={TextField} suffix={" " + currencySettings.currency} {...standardFormatProps} allowLeadingZeros allowNegative allowedDecimalSeparators={[',', '.']} decimalScale={2} fixedDecimalScale decimalSeparator="," thousandSeparator=" " />
            </Box>
            <Box align={settings.tableLabels[6].align} sx={[grCell, noBorderBtm]}>
              <NumericFormat
                sx={discountInput}
                defaultValue={row.discount}
                isAllowed={(values) => {
                  const { formattedValue, value, floatValue } = values;
                  return value === "" || floatValue < 100;
                }}
                onValueChange={(values) => {
                  const { formattedValue, value, floatValue } = values;
                  handleChangeRow("discount", floatValue || 0);
                }}
                customInput={TextField} suffix={"%"} {...standardFormatProps} allowLeadingZeros allowedDecimalSeparators={[',', '.']} decimalSeparator="," thousandSeparator=" " />
            </Box>
            <Box sx={[grCell, noBorderBtm]}>
              <Box ref={anchorRef}>
                <NumericFormat
                  sx={VATInput}
                  value={row.vAT}
                  isAllowed={(values) => {
                    const { formattedValue, value, floatValue } = values;
                    return value === "" || floatValue < 100;
                  }}
                  allowedDecimalSeparators={[',', '.']}
                  decimalSeparator=","
                  thousandSeparator=" "
                  customInput={TextField}
                  suffix={"%"}
                  InputProps={{
                    endAdornment: <InputAdornment position='end' sx={{marginLeft: "-6px",marginRight: "-10px", paddingRight: "0px"} }><ArrowDropDownIcon /></InputAdornment>,
                    inputProps: { min: 0, max: 100 },
                  }}
                  onValueChange={(values) => {
                    const { formattedValue, value, floatValue } = values;
                    handleChangeRow("vAT", floatValue || 0);
                    setOpenPopper(false);
                  }}
                  onClick={() => setOpenPopper(true)}
              />
              <Popper
                anchorEl={anchorRef.current}
                disablePortal
                open={openPopper}
                role={undefined}
                sx={{ zIndex: 2 }}
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <MenuList id="split-button-menu">
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              onClick={e => handleClick(e, index)}
                            >
                              {option}%
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              </Box>
            </Box>
            <Box align={settings.tableLabels[6].align} sx={[grCell, noBorderBtm]}><FormattedCurrency value={row.amount * row.price * (1 - row.discount / 100.0)} /></Box>

            <Box align="right" sx={[grCell_last, noBorderBtm]}>
              <IconButton
                onClick={handleAddRow}
                sx={addBtn}
              >
                <AddCircleOutlineIcon />
              </IconButton>
              <IconButton
                disabled={disableDelete}
                onClick={handleDeleteRow}
                sx={removeBtn}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Box>
          </Box>)
      }</>
  );
}

export default SalesDocumentRowTableRow;

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Box, Icon, IconButton } from "@mui/material";
import clsx from "clsx";
import PageTabPanel from "../../common/PageTabPanel";
import EditTextModal from "./EditTextModal";
import EditWysiwygModal from "./EditWysiwygModal";

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 4,
    border: "1px solid #F2F2F2",
    backgroundColor: theme.palette.common.white,
  },
  cardHead: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    fontWeight: 600,
    borderBottom: "1px solid #F2F2F2",
  },
  cardActions: {
    marginLeft: "auto",
  },
  iconButtonEdit: {
    padding: 4,
    color: theme.palette.primary.main,
  },
  iconButtonReset: {
    padding: 4,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
  cardBody: {
    padding: 15,
  },
  text: {
    fontSize: 14,
  },
  h1: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
  },
}));

const SvenkaTab = () => {
  const classes = useStyles();

  const [openEditText, setOpenEditText] = useState(false);
  const handleOpenEditText = () => {
    setOpenEditText(true);
  };
  const handleCloseEditText = () => {
    setOpenEditText(false);
  };

  const [openEditWysiwyg, setOpenEditWysiwyg] = useState(false);
  const handleOpenEditWysiwyg = () => {
    setOpenEditWysiwyg(true);
  };
  const handleCloseEditWysiwyg = () => {
    setOpenEditWysiwyg(false);
  };

  return (
    <PageTabPanel spacing="2">
      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.custom}>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Box className={classes.cardHead}>
                Alternative cost center
                <Box className={classes.cardActions}>
                  <IconButton
                    className={classes.iconButtonEdit}
                    onClick={handleOpenEditText}
                  >
                    <Icon
                      className={clsx(["fa fa-pencil-alt", classes.icon])}
                    />
                  </IconButton>
                  <IconButton className={classes.iconButtonReset}>
                    <Icon className={clsx(["fa fa-redo", classes.icon])} />
                  </IconButton>
                </Box>
              </Box>
              <Box className={classes.cardBody}>
                <Typography className={classes.text}>Costplace</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.card}>
              <Box className={classes.cardHead}>
                Alternative cost center 2
                <Box className={classes.cardActions}>
                  <IconButton
                    className={classes.iconButtonEdit}
                    onClick={handleOpenEditText}
                  >
                    <Icon
                      className={clsx(["fa fa-pencil-alt", classes.icon])}
                    />
                  </IconButton>
                  <IconButton className={classes.iconButtonReset}>
                    <Icon className={clsx(["fa fa-redo", classes.icon])} />
                  </IconButton>
                </Box>
              </Box>
              <Box className={classes.cardBody}>
                <Typography className={classes.text}>Cost Center</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.card}>
              <Box className={classes.cardHead}>
                Help text publications
                <Box className={classes.cardActions}>
                  <IconButton
                    className={classes.iconButtonEdit}
                    onClick={handleOpenEditWysiwyg}
                  >
                    <Icon
                      className={clsx(["fa fa-pencil-alt", classes.icon])}
                    />
                  </IconButton>
                  <IconButton className={classes.iconButtonReset}>
                    <Icon className={clsx(["fa fa-redo", classes.icon])} />
                  </IconButton>
                </Box>
              </Box>
              <Box className={classes.cardBody}>
                <Typography className={classes.h1}>
                  Here's how to create your publication
                </Typography>
                <Typography className={classes.text}>
                  Upload or use your existing files. Check the files you want to
                  adjust and use the menu on the left to execute the
                  adjustments.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <EditTextModal open={openEditText} handleClose={handleCloseEditText} />
      <EditWysiwygModal
        open={openEditWysiwyg}
        handleClose={handleCloseEditWysiwyg}
      />
    </PageTabPanel>
  );
};

export default SvenkaTab;

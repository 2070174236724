import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDialogActions-root": {
      padding: "8px 24px 20px",
    },
  },
}));

/**
 * A modal dialog displaying an error message and a button for retrying an operation.
 *
 * @param {object} param0
 * @param {function} param0.onRetry Is called when the user retries the operation.
 * @param {boolean} param0.open Displays the dialog if true.
 */
const ErrorDialog = ({ onRetry, open }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.root} open={open}>
      <DialogContent>
        <Box mb={2}>
          <Typography component="h5" variant="h5">
            <FormattedMessage id="common.errorLabel" />
          </Typography>
        </Box>
        <Box>
          <FormattedMessage id="common.genericErrorMessage" />
        </Box>
      </DialogContent>
      <DialogActions>
        {!!onRetry && (
          <Box>
            <Button color="primary" onClick={onRetry} variant="contained">
              <FormattedMessage id="btn.retry" />
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  onRetry: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ErrorDialog;

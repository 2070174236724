import { gql } from '@apollo/client';

const UPDATE_ACCOUNT_PASSWORD_ADMIN = gql`
mutation updateAccountPassword($model:UpdateAccountPasswordInput!){
	companyAdminContext{
		users{
			updateAccountPassword(model:$model){
				success
				errorResult{
					data{
						key
						value
						__typename
					}					
					errorCode
					errorMessage
					__typename
				}
				__typename
			}
		}
	}
}
`;

export default UPDATE_ACCOUNT_PASSWORD_ADMIN;
import { gql } from '@apollo/client';

const getCompanyUsersListItems = gql`
  query getCompanyUsersListItems($companyId: Int!) {
    companyAdminContext {
      externalUsers {
        listExternalUsers(companyId: $companyId) {
          success
          errorResult {
            errorCode
            errorMessage
            data {
              key
              value
            }
          }
          totalUserCount
          externalUserListItems {
            avatar
            companyId
            companyName
            firstname
            lastname
            roleName
            userId
            username
          }
        }
      }
    }
  }
`;

export default getCompanyUsersListItems;

import { gql } from '@apollo/client';

const INIT_RESOURCE_VERSION_DOWNLOAD = gql`
  mutation InitResourceVersionDownload($resourceId: ID!, $versionNumber: Int) {
    initResourceVersionDownload(
      resourceId: $resourceId
      versionNumber: $versionNumber
    )
  }
`;

export default INIT_RESOURCE_VERSION_DOWNLOAD;

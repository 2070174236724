import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import getFolderTreeDataStructure from "../../../helpers/getFolderTreeDataStructure";
import HomeMadeFolderTree from "../../common/HomeMadeFolderTree";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#f5f5f5",
    padding: 15,
    overflowY: "auto",
  },
}));

const FolderTreeContainer = ({
  checkedFolders,
  folderPropertyFormValues,
  foldersData,
  onFolderRowClick,
  openedNodes,
  propertyFormValues,
  selectedFolder,
  selectedFolders,
  setCheckedFolders,
  setConfirmationModal,
  setFolderIdsUnchecked,
  setFolderPropertyFormValues,
  setPropertyFormValues,
}) => {
  const classes = useStyles();
  const [folderTree, setFolderTree] = useState({});

  useEffect(() => {
    const newFolderTree = getFolderTreeDataStructure({ folders: foldersData });
    setFolderTree(newFolderTree);
  }, [foldersData]);

  const handleCheckboxChange = (e, folder) => {
    if (!e.target.checked) {
      const folderIdsToUncheck = [folder.folderId];

      setFolderIdsUnchecked(folderIdsToUncheck);
      setConfirmationModal(true);
    }
  };
  return (
    <Box className={classes.root}>
      <HomeMadeFolderTree
        checkedFolders={checkedFolders}
        defaultExpanded={
          openedNodes && Array.isArray(openedNodes) ? openedNodes : []
        }
        folderPropertyFormValues={folderPropertyFormValues}
        folderTree={folderTree}
        handleCheckboxChange={handleCheckboxChange}
        isMonitoring
        onFolderRowClick={onFolderRowClick}
        propertyFormValues={propertyFormValues}
        selectedFolderIds={selectedFolder ? [selectedFolder.folderId] : []}
        setCheckedFolders={setCheckedFolders}
        setFolderPropertyFormValues={setFolderPropertyFormValues}
        setPropertyFormValues={setPropertyFormValues}
      />
    </Box>
  );
};

FolderTreeContainer.defaultProps = {
  selectedFolders: [],
};

FolderTreeContainer.propTypes = {
  checkedFolders: PropTypes.arrayOf(PropTypes.number).isRequired,
  foldersData: PropTypes.arrayOf(
    PropTypes.shape({
      childFolders: PropTypes.arrayOf(
        PropTypes.shape({
          folderId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          parentId: PropTypes.number,
        })
      ),
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  includeSubfolders: PropTypes.bool.isRequired,
  onFolderRowClick: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  selectedFolders: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number.isRequired,
      hasChildFolders: PropTypes.bool,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  setConfirmationModal: PropTypes.func.isRequired,
  setFolderIdsUnchecked: PropTypes.func.isRequired,
};

export default memo(FolderTreeContainer);

import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";
import SecuritySettingsFields from "../../../fragments/CompanyAdminContext/SecuritySettings";

const GET_COMPANY_SECURITY_SETTINGS = gql`
  query GetCompanySecuritySettings($companyId: Int!) {
    companyAdminContext {
      companySettings {
        getCompanySecuritySettings(companyId: $companyId) {
          success
          errorResult { ...ErrorResultFields }
          securitySettings { ...SecuritySettingsFields }
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${SecuritySettingsFields}
`;

export default GET_COMPANY_SECURITY_SETTINGS;

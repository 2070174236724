import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  skeletonWrap: {
    backgroundColor: "#fff",
    padding: "7px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  skeleton: {
    borderRadius: 4,
    marginBottom: 10,
  },
  skeletonText: {
    borderRadius: 4,
    marginBottom: 10,
  },
  spacer: {
    marginTop: 8,
    marginBottom: 8,
  },
  formWrap: {
    padding: 10,
    minHeight: "75vh",
  },
  imgWrap: {
    padding: 20,
    backgroundColor: "#F4F3EF",
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "94.5%",
  },
  footerWrap: {
    padding: 10,
    backgroundColor: "#f9f9f9",
  },
}));

const ProductModalSkeleton = () => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12} md={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Skeleton
                variant="rect"
                width="100%"
                height={40}
                animation="wave"
                classes={{ root: classes.skeleton }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton
                variant="rect"
                width="100%"
                height={40}
                animation="wave"
                classes={{ root: classes.skeleton }}
              />
            </Grid>
          </Grid>
          <Box className={classes.formWrap}>
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
            <Skeleton
              variant="rect"
              width="100%"
              height={40}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
          <Box className={classes.footerWrap}>
            <Skeleton
              variant="rect"
              width="20%"
              height={30}
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box className={classes.imgWrap}>
            <Skeleton
              variant="rect"
              width="75%"
              height="80%"
              animation="wave"
              classes={{ root: classes.skeleton }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductModalSkeleton;

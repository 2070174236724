import React from "react";
import SearchDropdown from "./SearchDropdown";

const SelectSearchField = ({ disabled, name, onChange, options, value }) => {
  const { label } = options;
  const dropdownOptions = options.options;
  return (
    <SearchDropdown sx={{ flex: "1 1 auto", minWidth: "10em" }}
      disabled={disabled}
      id={`ssf-${name}`}
      label={label}
      onChange={e => onChange?.({ name, value: e.target.value })}
      options={dropdownOptions}
      value={value}
    />
  );
}

export default SelectSearchField;

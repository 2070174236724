import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Grid, Box, Typography, Tabs, Tab } from "@mui/material";
import { useIntl } from "react-intl";
import AddressSection from "./AddressSection";

import ColumnThree from "./AboutSection/ColumnThree";

import SettingsIcon from '@mui/icons-material/Settings';
import ShieldIcon from '@mui/icons-material/Shield';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import { PrimaryButton } from "../../../common";
import AboutSection from "../../../UserDetails/AccountSettingsModal/AboutSection";
import AdminSecurityTab from "./AdminSecurityTab";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}


/*editMode = { editMode }
accountSettings = { accountSettings }
setAccountSettings = { setAccountSettings }
setValidationC3 = { setValidationC3 }
validationC3 = { validationC3 }*/

const AccountTabPanel = ({
  editMode,
  accountSettings,
  selectedUserId,
  setAccountSettings,
  validationC3,
  setValidationC3,
  onTabChange
}) => {
  const [value, setValue] = useState();
  const intl = useIntl();

  const handleTabs = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  useEffect(() => {
    setValue(editMode ? 0 : 2);
  }, [editMode]);

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ boxShadow: "none", backgroundColor: "white" }}
      >
        <Tabs
          value={value}
          onChange={handleTabs}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="true"
          allowScrollButtonsMobile
          aria-label="Account settings tab"
        >
          <Tab
            icon={<PersonIcon/> }
            label={intl.formatMessage({
              id: "account.about",
            })}
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
          />
          
            <Tab icon={<ContactMailIcon />}
              label={intl.formatMessage({
                id: "account.addresses",
              })}
              {...a11yProps(1)}
              style={{ textTransform: "none" }}
            />
            <Tab icon={<ShieldIcon />} label="Säkerhet" {...a11yProps(3)} style={{ textTransform: "none" }} value={3} />                    
            <Tab icon={<SettingsIcon />} {...a11yProps(2)} style={{ textTransform: "none" }} label={intl.formatMessage({ id: "account.settings", })} value={2} />

          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AboutSection accountSettings={accountSettings} setAccountSettings={setAccountSettings}/>
        
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddressSection 
			accountSettings={accountSettings} 
			setAccountSettings={setAccountSettings} />
      </TabPanel>
      <TabPanel value={value} index={3}>

        <AdminSecurityTab accountSettings={accountSettings} setAccountSettings={setAccountSettings} userId={selectedUserId} />


      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container spacing={1}>
          <ColumnThree
            editMode={editMode}
            formValues={accountSettings}
            setFormValues={setAccountSettings}
            validationC3={validationC3}
            setValidationC3={setValidationC3}
          />
        </Grid>
      </TabPanel>
    </>
  );
};

AccountTabPanel.defaultProps = {
  editMode: false,
  isViewedAs: false,
};

AccountTabPanel.propTypes = {
  editMode: PropTypes.bool,
  isViewedAs: PropTypes.bool,
};

export default AccountTabPanel;

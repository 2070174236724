import React, { useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/client";
import shortid from "shortid";
import { omitTypename } from "../../../../helpers/get";
import { GET_SHOPPING_CART_VIEW } from "../../../../graphql/queries";
import {
  SHOPPING_CART_SETTINGS,
  INITIAL_ADDRESS_FORM_VALUES,
} from "../../../../constant/initialValues";
import ReceiversSection from "./ReceiversSection";
import ReceiversTabs from "./ReceiversTabs";

const useStyles = makeStyles(() => ({
  root: { marginTop: 15 },
}));

const ReceiversSectionContainer = ({
  siteId,
  receivers,
  setReceivers,
  monitoringItemId,
  handleRemoveReceiverTrigger,
  orderRecipientTabSettings,
  setReceiversAddressErrors,
  receiversAddressErrors,
}) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const [addressCombinationsOptions, setAddressCombinationsOptions] = useState(
    []
  );
  const [addressCombinations, setAddressCombinations] = useState([]);

  const [shoppingCartSettings, setShoppingCartSettings] = useState(
    SHOPPING_CART_SETTINGS
  );

  const { data } = useQuery(GET_SHOPPING_CART_VIEW, {
    variables: {
      siteId,
    },
  });

  useEffect(() => {
    if (
      data &&
      data.shoppingCartView &&
      data.shoppingCartView.shoppingCartAddresses &&
      data.shoppingCartView.shoppingCartAddresses.addressCombinations
    ) {
      const { shoppingCartView } = data;
      const { shoppingCartAddresses } = shoppingCartView;
      const {
        addressCombinations: addressCombinationsData,
      } = shoppingCartAddresses;
      const dropDownOptions = addressCombinationsData.map(x => ({
        value: x.label,
        label: x.label,
      }));
      setAddressCombinations(addressCombinationsData);
      setAddressCombinationsOptions(dropDownOptions);
    }
    if (data && data.shoppingCartSettings) {
      const shoppingCartSettingsNoTypename = JSON.parse(
        JSON.stringify(data.shoppingCartSettings),
        omitTypename
      );
      setShoppingCartSettings(shoppingCartSettingsNoTypename);
    }
  }, [data]);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOnClickAddRecipientsTab = () => {
    setReceivers([
      ...receivers,
      { shortId: shortid.generate(), ...INITIAL_ADDRESS_FORM_VALUES },
    ]);
  };

  return (
    <Box className={classes.root}>
      <ReceiversTabs
        tabIndex={tabIndex}
        handleChangeTab={handleChangeTab}
        receivers={receivers}
        handleOnClickAddRecipientsTab={handleOnClickAddRecipientsTab}
        handleRemoveReceiver={handleRemoveReceiverTrigger}
      />
      {receivers.map((x, index) => {
        const { firstTab, subsequentTabs } = orderRecipientTabSettings || {};
        if (tabIndex === index) {
          const settings = index === 0 ? firstTab : subsequentTabs;

          if (!settings) {
            return "No order recipient settings.";
          }

          return (
            <ReceiversSection
              key={`address-section-${x.shortId}`}
              addressCombinationsOptions={addressCombinationsOptions}
              addressCombinations={addressCombinations}
              shoppingCartSettings={shoppingCartSettings}
              receivers={receivers}
              setReceivers={setReceivers}
              index={index}
              monitoringItemId={monitoringItemId}
              recipientTabSettings={settings}
              setReceiversAddressErrors={setReceiversAddressErrors}
              receiversAddressErrors={receiversAddressErrors}
            />
          );
        }
        return null;
      })}
    </Box>
  );
};

ReceiversSectionContainer.defaultProps = {
  monitoringItemId: null,
  receivers: [],
  orderRecipientTabSettings: {
    firstTab: {
      showDeliveryAddress: false,
      showInvoiceAddress: false,
      showOrdererAddress: false,
    },
    subsequentTabs: {
      showDeliveryAddress: false,
      showInvoiceAddress: false,
      showOrdererAddress: false,
    },
  },
  receiversAddressErrors: [],
};

ReceiversSectionContainer.propTypes = {
  orderRecipientTabSettings: PropTypes.shape({
    firstTab: PropTypes.shape({
      showDeliveryAddress: PropTypes.bool,
      showInvoiceAddress: PropTypes.bool,
      showOrdererAddress: PropTypes.bool,
    }),
    subsequentTabs: PropTypes.shape({
      showDeliveryAddress: PropTypes.bool,
      showInvoiceAddress: PropTypes.bool,
      showOrdererAddress: PropTypes.bool,
    }),
  }),
  setReceivers: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  receivers: PropTypes.arrayOf(PropTypes.shape({})),
  monitoringItemId: PropTypes.number,
  handleRemoveReceiverTrigger: PropTypes.func.isRequired,
  setReceiversAddressErrors: PropTypes.func.isRequired,
  receiversAddressErrors: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => {
  return {
    orderRecipientTabSettings: state.api.monitoring.orderRecipientTabSettings,
  };
};

export default connect(mapStateToProps)(memo(ReceiversSectionContainer));

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid } from "@mui/material";
import AvailableLifts from "./AvailableLifts";
import NoAvailableLifts from "./NoAvailableLifts";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
  },
  label: {
    fontWeight: 800,
    fontSize: 16,
  },
  liftLabel: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 15,
    fontWeight: 800,
  },
}));

const BookingRow = ({
  handleBooking,
  availableLifts,
  liftSchedStatus,
  alternativeLifts,
  avialbleLiftDate,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container>
        {liftSchedStatus !== "NOT_BOOKABLE" && (
          <AvailableLifts
            availableLifts={availableLifts}
            liftSchedStatus={liftSchedStatus}
            alternativeLifts={alternativeLifts}
            avialbleLiftDate={avialbleLiftDate}
            handleBooking={handleBooking}
          />
        )}
        {liftSchedStatus === "NOT_BOOKABLE" && <NoAvailableLifts />}
      </Grid>
    </Box>
  );
};

BookingRow.defaultProps = {
  availableLifts: [],
  liftSchedStatus: "",
  alternativeLifts: [],
  avialbleLiftDate: "",
};

BookingRow.propTypes = {
  alternativeLifts: PropTypes.arrayOf(PropTypes.shape({})),
  avialbleLiftDate: PropTypes.string,
  liftSchedStatus: PropTypes.string,
  availableLifts: PropTypes.arrayOf(PropTypes.shape({})),
  handleBooking: PropTypes.func.isRequired,
};

export default BookingRow;

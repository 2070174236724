import { gql } from '@apollo/client';

const SAVE_CART = gql`
  mutation SaveCart(
    $orderGroupId: Int!
    $items: [OrderGroupShoppingCartItemInput]
  ) {
    saveShoppingCartContent(
      orderGroups: { groups: [{ orderGroupId: $orderGroupId, items: $items }] }
    )
  }
`;

export default SAVE_CART;

import { gql } from '@apollo/client';
import DirectMessageFragment from "../fragments/DirectMessage";

const GET_DIRECT_MESSAGE_UPDATE = gql`
  subscription DirectMessageUpdateSubscription(
    $directMessageConversationId: Int!
    $fromMessage: ID
    $authToken: String!
  ) {
    directMessageUpdate(
      directMessageConversationId: $directMessageConversationId
      fromMessage: $fromMessage
      authToken: $authToken
    ) {
      cursor
      node {
        ...DirectMessageFragment
      }
    }
  }
  ${DirectMessageFragment}
`;

export default GET_DIRECT_MESSAGE_UPDATE;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";
import OrderGroup from "../fragments/OrderGroup";

const UPDATE_DYNAMIC_PRODUCT = gql`
  mutation UpdateDynamicProduct($item: DynamicProductUpdateInput!) {
    shoppingCartContext {
      updateDynamicProduct(item: $item) {
        errorResult {
          ...ErrorResultFields
        }
        result {
          groups {
            ...OrderGroupFragment
          }
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
  ${OrderGroup}
`;

export default UPDATE_DYNAMIC_PRODUCT;

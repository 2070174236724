import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles(theme => ({
  tag: {
    marginRight: 5,
    marginBottom: 5,
    fontSize: 10,
    textTransform: "uppercase",
    color: "#fff",
  },
  chipRoot: ({ color }) => ({
    backgroundColor:
      color === "light"
        ? theme.palette.primary.light
        : theme.palette.primary.main,
  }),
  icon: {
    color: "#fff",
    width: "14px !important",
  },
}));

const ChipComponent = ({ label, onDelete, color }) => {
  const classes = useStyles({
    color,
  });
  return (
    <Chip
      size="small"
      label={label}
      onDelete={onDelete}
      className={classes.tag}
      classes={{
        root: classes.chipRoot,
        deleteIcon: classes.icon,
      }}
    />
  );
};

ChipComponent.defaultProps = {
  color: "light",
};

ChipComponent.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ChipComponent;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Icon,
  Button,
} from "@mui/material";
import CustomTextField from "../../common/TextField";
import CustomSelect from "../../common/CustomSelect";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalFooter: {
    marginTop: 20,
    padding: "10px 0 0 0",
    borderTop: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginRight: 10,
      width: 240,
    },
    "& input": {
      paddingTop: 8,
      paddingBottom: 9,
    },
  },
  btnSave: {
    padding: "5px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    "& > span": {
      fontWeight: 400,
    },
  },
  labelSelect: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 8,
  },
  text: {
    fontSize: 12,
    marginTop: 10,
  },
  iconBtn: {
    padding: 4,
    marginLeft: 10,
    color: theme.palette.common.link,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
  },
  switchLabel: {
    fontSize: 12,
    fontWeight: 600,
    display: "inline-block",
  },
}));

const CreateChannelModal = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title="Create a channel" />
      <DraggableModalBody>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>Name</Typography>
            <CustomTextField />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.labelSelect}>Workspace</Typography>
            <CustomSelect
              options={[{ label: "Default", value: 1 }]}
              onChange={() => {}}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.formControl}>
          <Grid item xs={12}>
            <Typography className={classes.label}>
              Description <span>(optional)</span>
            </Typography>
            <CustomTextField />
          </Grid>
        </Grid>
        <Box mt={1}>
          <FormControlLabel
            control={<Switch name="checkedB" color="primary" />}
            label={
              <>
                <Typography className={classes.switchLabel}>
                  Make this channel private
                </Typography>
                <Tooltip
                  placement="top"
                  title="When a channel is set to private, it can only be viewed or joined by invitation."
                >
                  <IconButton className={classes.iconBtn}>
                    <Icon
                      className={clsx(["fa fa-question-circle", classes.icon])}
                    />
                  </IconButton>
                </Tooltip>
              </>
            }
            classes={{
              root: classes.switchRoot,
              label: classes.switchLabel,
            }}
          />
        </Box>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnSave}>Create</Button>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

CreateChannelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CreateChannelModal;

import { makeStyles } from "@mui/styles";
import { Card, CardContent, CardMedia, Skeleton } from "@mui/material";

const useStyles = makeStyles(theme => ({
  card: {
    border: "1px solid",
    borderColor: theme.palette.background.contrastGray,
    borderRadius: "4px",
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.05)",
    display: "flex",
    flexDirection: "column",
    padding: "14px",
    width: props => `${props.cardMediaSize}`,
  },
  cardContent: {
    padding: props => `${props.cardContentPadding}`,
    "&:last-child": {
      minHeight: "48px",
    },
  },
  media: {
    height: props => `${props.cardMediaSize}`,
  },
}));

const DocumentViewItemSkeleton = ({
  cardContentPadding = "4px 6px 4px 4px",
  cardMediaSize = "218px",
}) => {
  const classes = useStyles({ cardContentPadding, cardMediaSize });

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media}>
        <Skeleton variant="rect" animation="pulsate" height="100%" />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Skeleton variant="text" animation="wave" />
      </CardContent>
    </Card>
  );
};

export default DocumentViewItemSkeleton;

import React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  widgetBody: {
    padding: 5,
  },
}));

const DashboardWidgetBody = ({ children, customClass }) => {
  const classes = useStyles();

  return (
    <Box className={clsx([classes.widgetBody, customClass])}>{children}</Box>
  );
};

export default DashboardWidgetBody;

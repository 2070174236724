import { gql } from '@apollo/client';

const getLatestHighlightedProductsForSite = gql`
  query GetLatestHighlightedProductsForSite($siteId: Int!, $limit: Int) {
    widgets {
      getLatestHighlightedProductsForSite(siteId: $siteId, limit: $limit) {
        highlighted {
          from
          to
        }
        name
        fileExtension
        thumbnailImageUri
        productId
      }
    }
  }
`;

export default getLatestHighlightedProductsForSite;

import { gql } from '@apollo/client';
import SiteNewsItem from "../../fragments/SiteNewsItem";

const GET_NEWS_ITEM = gql`
  query GetSiteNewsItem($siteNewsId: ID!) {
    newsContext {
      getNewsItem(siteNewsId: $siteNewsId) {
        ...SiteNewsItemProperties
      }
    }
  }
  ${SiteNewsItem}
`;

export default GET_NEWS_ITEM;

import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Icon,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CategorySkeleton from "../Skeleton/CategorySkeleton";

const useStyles = makeStyles(theme => ({
  folderListWrap: {
    marginBottom: 20,
  },
  siteIcon: {
    display: "inline-block",
    marginRight: 10,
    fontSize: 14,
    verticalAlign: "middle",
    color: theme.palette.text.primary,
  },
  siteName: {
    display: "inline-block",
    fontSize: 12,
    marginBottom: 0,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  divider: {
    marginTop: 5,
    marginBottom: 2,
  },
  folderList: {
    padding: 0,
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiListItem-gutters": {
      paddingLeft: 10,
      paddingRight: 10,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 25,
    },
    "& .MuiListItemText-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiListItemText-primary": {
      fontSize: 12,
      color: theme.palette.text.primary,
    },
    "& .MuiSvgIcon-root": {
      width: "13px !important",
      color: theme.palette.text.secondary,
    },
  },
  siteFolderContainer: {
    marginTop: 20,
  },
}));

const FolderList = ({
  siteFolders,
  setFolderId,
  loading,
  error,
  detailedSearchCalled,
}) => {
  const classes = useStyles();

  const handleClick = id => {
    setFolderId({ id });
  };

  const renderFolders = (site, folders) => {
    return (
      <div className={classes.siteFolderContainer}>
        <Icon className={clsx(["far fa-folder", classes.siteIcon])} />
        <Typography className={classes.siteName}>{site}</Typography>
        <Divider className={classes.divider} />
        <List className={classes.folderList} component="nav">
          {folders.map(f => {
            return (
              <ListItem
                key={f.folderId}
                button
                onClick={() => handleClick(f.folderId)}
              >
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary={f.folderName} />
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  };

  const renderSiteFolders = () => {
    if (loading) return <CategorySkeleton />;
    if (error) return "An error occurred.";
    if (detailedSearchCalled && siteFolders.length === 0)
      return "No Results Found";
    return siteFolders.map(x => {
      return renderFolders(x.siteName, x.folders);
    });
  };

  return <Box className={classes.folderListWrap}>{renderSiteFolders()}</Box>;
};

FolderList.defaultProps = {
  loading: false,
  error: false,
};

FolderList.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  siteFolders: PropTypes.arrayOf(
    PropTypes.shape({ siteName: PropTypes.string })
  ).isRequired,
  setFolderId: PropTypes.func.isRequired,
  detailedSearchCalled: PropTypes.bool.isRequired,
};

export default FolderList;

import { gql } from '@apollo/client';

const FolderProductSettings = gql`
  fragment FolderProductSettingsFragment on FolderProductSettings {
    folderProductSettingsId
    quantityProperty {
      pricingModelId
      quantityFixedValues {
        id
        isDefault
        value
      }
      quantityName
      quantityRange {
        max
        min
      }
      quantityType
      quantityUnit
    }
    productionProperties {
      conditionalPropertyIds
      dependentPropertyIds
      id
      maxValue
      minValue
      name
      propertyValues {
        id
        name
        quantities {
          values
        }
        selected
      }
      type
    }
  }
`;

export default FolderProductSettings;

import { gql } from '@apollo/client';

const ADD_USER_TO_SHARE = gql`
  mutation AddUserToShare($shareId: Int!, $usernames: String) {
    shareContext {
      addUserToShare(shareId: $shareId, usernames: $usernames)
    }
  }
`;

export default ADD_USER_TO_SHARE;

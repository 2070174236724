import React, { useState, useEffect, memo, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { setMonitoringOrderRecipientTabSettings } from "../../../actions";
import { SELECT_FIELD_MANDATORY } from "../../../constant/types";
import {
  CREATE_MONITORING_ITEM,
  UPDATE_MONITORING_ITEM,
} from "../../../graphql/mutations";
import {
  GET_FOLDERS,
  GET_ORDER_RECIPIENT_TAB_SETTINGS,
  GET_MONITORING_PRODUCTION_SETTINGS_FOR_SITE,
} from "../../../graphql/queries";
import { omitTypename } from "../../../helpers/get";
import StepThree from "./StepThree";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import Stepper from "./Stepper";
import {
  notificationActionTypes,
  notificationOccasionTypes,
  PRINT_AND_MAIL,
} from "../../../constant/monitoringTypes";
import { getSiteOptions } from "../../../helpers/selectors";
import {
  getSelectedChildFolders,
  isActivateStepThree,
  isFilesAndFolderMonitorEnabled,
  isFolderMonitorEnabled,
  getMonitorFolderInput,
  getMonitorFileInput,
} from "../../../helpers/monitoring";
import StepFooter from "./StepFooter";
import {
  initialFilesFoldersSettings,
  initialRoleSettings,
  initialUserSettings,
} from "../../../constant/initialValues";
import { convertArrayToObject } from "../../../helpers/converters";

const STEP_ONE = <FormattedMessage id="monitoring.howIsTheMonitoringSent" />;
const STEP_TWO = <FormattedMessage id="monitoring.whatEventsAreMonitored" />;
const STEP_THREE = <FormattedMessage id="monitoring.whatIsBeingMonitored" />;
const useStyles = makeStyles(theme => ({
  modalBody: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "hidden",
    flex: 1,
  },
  cardHead: {
    padding: 20,
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: "0 2px 15px 0 rgba(0,0,0,0.05)",
    backgroundColor: theme.palette.common.white,
  },
  cardBody: {
    padding: 20,
    minHeight: "72%",
    position: "relative",
  },
}));

const MonitoringMain = ({
  currentViewer,
  folder,
  handleClose,
  initialFolders,
  initialMonitoring,
  initialSiteId,
  monitoringName,
  openedNodes,
  setMonitoringName,
  // eslint-disable-next-line no-shadow
  setMonitoringOrderRecipientTabSettings,
  siteOptions,
  initialWorkspaceName,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const workspaces = useSelector(
    state => state.api.currentViewer.viewer.structure.items
  );

  const [createMonitoringItem] = useMutation(CREATE_MONITORING_ITEM);
  const [updateMonitoringItem] = useMutation(UPDATE_MONITORING_ITEM);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationOccasion, setNotificationOccasion] = useState();
  const [recipients, setRecipients] = useState([]);
  const [siteId, setSiteId] = useState(initialSiteId);
  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName);
  const [includeSubfolders, setIncludeSubfolders] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState(
    folder ? [folder] : []
  );
  const [selectedFolder, setSelectedFolder] = useState(folder);
  const { folderId: selectedFolderId } = selectedFolder || {};
  const [activeStep, setActiveStep] = useState(0);
  const [printAndMailSelected, setPrintAndMailSelected] = useState(false);
  const [foldersData, setFoldersData] = useState(initialFolders);
  const [steps, setSteps] = useState([STEP_ONE, STEP_TWO, STEP_THREE]);
  const [folderMonitorEnabled, setFolderMonitorEnabled] = useState(false);
  const [receivers, setReceivers] = useState([]);
  const [receiversAddressErrors, setReceiversAddressErrors] = useState([]);

  const [
    filesAndFolderMonitorEnabled,
    setFilesAndFolderMonitorEnabled,
  ] = useState(false);

  const [filesFoldersSettings, setFilesFolderSettings] = useState(
    initialFilesFoldersSettings
  );
  const [roles, setRoles] = useState(initialRoleSettings);
  const [users, setUsers] = useState(initialUserSettings);
  const [foldersProductionSettings, setFoldersProductionSettings] = useState();

  const [productionSettingErrors, setProductionSettingErrors] = useState({});
  const [folderMandatoryFields, setFolderMandatoryFields] = useState({});

  const [getFolders, { data }] = useLazyQuery(GET_FOLDERS);

  const [disableSave, setDisableSave] = useState(false);

  const [
    getProductionSettingsForSite,
    { loading: loadingProductionSettings },
  ] = useLazyQuery(GET_MONITORING_PRODUCTION_SETTINGS_FOR_SITE, {
    // eslint-disable-next-line no-shadow
    onCompleted: data => {
      const {
        getProductionSettingsForSite: settingsTempArray,
      } = data.monitoringContext;
      const settingsTempArrayObj = convertArrayToObject(
        settingsTempArray,
        "folderId"
      );
      setFoldersProductionSettings(settingsTempArrayObj);
    },
    onError: () => {
      setFoldersProductionSettings();
    },
  });

  // Step 3.
  const [checkedFolders, setCheckedFolders] = useState([]);
  const [propertyFormValues, setPropertyFormValues] = useState([]);
  const [folderPropertyFormValues, setFolderPropertyFormValues] = useState([]);
  const [subFoldersWithDiffSettings, setSubFoldersWithDiffSettings] = useState(
    []
  );
  const [siteSupplierId, setSiteSupplierId] = useState(0);

  const { loading: loadingGetOrderRecipientTabSettings } = useQuery(
    GET_ORDER_RECIPIENT_TAB_SETTINGS,
    {
      variables: {
        siteId,
      },
      skip: !siteId,
      onCompleted: tabSettings => {
        const { monitoringContext } = tabSettings || {};
        const { getOrderRecipientTabSettings } = monitoringContext || {};
        setMonitoringOrderRecipientTabSettings({
          settings: getOrderRecipientTabSettings,
        });
      },
    }
  );

  useEffect(() => {
    if (siteId) {
      getProductionSettingsForSite({ variables: { siteId } });
      getFolders({ variables: { siteId } });
    }
  }, [siteId]);

  useEffect(() => {
    if (initialMonitoring) {
      const notifActionsTemp = [];
      if (initialMonitoring.notificationActions) {
        initialMonitoring.notificationActions.forEach(x => {
          const notifAction = notificationActionTypes.find(y => x === y.value);
          if (notifAction) {
            notifActionsTemp.push(notifAction);
          }
        });
      }

      setNotificationActions(notifActionsTemp);
      setNotificationOccasion(initialMonitoring.notificationOccasion);

      // Set the triggers
      const {
        triggers = {},
        siteId: existingSiteId,
        recipients: initialRecipients,
        orderRecipients: initOrderRecipients,
      } = initialMonitoring;

      if (
        initialRecipients &&
        Array.isArray(initialRecipients) &&
        initialRecipients.length > 0
      ) {
        const tempRecipients = initialRecipients.map(x => {
          return {
            label: `${x.name} (${x.username})`,
            value: x.userId,
          };
        });
        setRecipients(tempRecipients);
      }

      if (
        initOrderRecipients &&
        Array.isArray(initOrderRecipients) &&
        initOrderRecipients.length > 0
      ) {
        const tempReceivers = initOrderRecipients.map(x => {
          return {
            costPlace: x.costPlace,
            costPlace2: x.costPlace2,
            costPlace3: x.costPlace3,
            deliveryAddress: JSON.parse(
              JSON.stringify(x.deliveryAddress),
              omitTypename
            ),
            invoiceAddress: JSON.parse(
              JSON.stringify(x.invoiceAddress),
              omitTypename
            ),
            message: x.message,
            postalAddress: JSON.parse(
              JSON.stringify(x.ordererAddress),
              omitTypename
            ),
            userId: x.userId,
          };
        });
        setReceivers(tempReceivers);
      }

      setFilesFolderSettings(
        triggers.fileFolders || initialFilesFoldersSettings
      );
      setRoles(triggers.roles || initialRoleSettings);
      setUsers(triggers.users || initialUserSettings);
      setSiteId(existingSiteId);
      if (existingSiteId) {
        const site = workspaces.find(x => x.siteId === existingSiteId);

        setWorkspaceName(site.name);
      }
      const { monitoredFiles = [], monitoredFolders = [] } =
        initialMonitoring.fileMonitoring || {};

      let initialCheckedFolders = [];

      if (monitoredFiles && Array.isArray(monitoredFiles)) {
        monitoredFiles.forEach(file => {
          initialCheckedFolders = [...initialCheckedFolders, file.folderId];
        });
      }

      if (monitoredFolders && Array.isArray(monitoredFolders)) {
        monitoredFolders.forEach(ff => {
          initialCheckedFolders = [...initialCheckedFolders, ff.folderId];
        });
      }
      setCheckedFolders([...new Set([...initialCheckedFolders])]);

      setFolderPropertyFormValues(monitoredFolders);
      setPropertyFormValues(monitoredFiles);

      setSiteSupplierId(initialMonitoring.siteSupplierId);
    }
  }, [initialMonitoring]);

  useEffect(() => {
    if (data && data.folders) {
      setFoldersData(data.folders);
    }
  }, [data]);

  const handleSaveMonitoringItem = async () => {
    setDisableSave(true);
    const newMonitoredFiles = getMonitorFileInput(propertyFormValues);
    const newMonitoredFolders = getMonitorFolderInput(folderPropertyFormValues);

    let monitoringRecipientInput = [];
    monitoringRecipientInput = recipients.map(x => ({ userId: x.value }));

    let monitoringOrderRecipientInput = [];

    if (receivers && Array.isArray(receivers) && receivers.length > 0) {
      monitoringOrderRecipientInput = receivers.map(x => {
        return {
          userId: x.userId,
          deliveryAddress: x.deliveryAddress,
          invoiceAddress: x.invoiceAddress,
          ordererAddress: x.postalAddress,
        };
      });
    }

    const fileMonitoring = {
      monitoredFiles: newMonitoredFiles,
      monitoredFolders: newMonitoredFolders,
      shoppingCartSettings: {},
    };

    const monitoringTriggersInout = {
      filesFolders: filesFoldersSettings,
      roles,
      users,
    };

    const notificationActionsType = notificationActions.map(x => x.value);

    let monitoringItemInput = {
      fileMonitoring,
      monitoringItemId:
        initialMonitoring && initialMonitoring.monitoringItemId
          ? initialMonitoring.monitoringItemId
          : null,
      name: monitoringName,
      notificationActions: notificationActionsType,
      notificationOccasion,
      orderRecipients: monitoringOrderRecipientInput,
      recipients: monitoringRecipientInput,
      siteId,
      triggers: monitoringTriggersInout,
      userId: currentViewer ? currentViewer.userId : null,
      siteSupplierId: siteSupplierId,
    };

    if (initialMonitoring && initialMonitoring.monitoringItemId) {
      monitoringItemInput = {
        ...monitoringItemInput,
        monitoringItemId: initialMonitoring.monitoringItemId,
      };
    }

    try {
      const operation =
        initialMonitoring && initialMonitoring.monitoringItemId
          ? updateMonitoringItem
          : createMonitoringItem;

      const res = await operation({
        variables: { monitoringItemInput },
      });

      if (
        res &&
        res.data &&
        ((res.data.monitorContext &&
          res.data.monitorContext.createMonitoringItem) ||
          (res.data.monitorContext &&
            res.data.monitorContext.updateMonitoringItem))
      ) {
        const { success } =
          res.data.monitorContext.createMonitoringItem ||
          res.data.monitorContext.updateMonitoringItem ||
          {};

        if (success) {
          alert.success(<FormattedMessage id="monitoring.success" />);
          handleClose();
        } else {
          alert.error(<FormattedMessage id="monitoring.error" />);
        }
      }
      setDisableSave(false);
    } catch (e) {
      alert.error(<FormattedMessage id="monitoring.error" />);
    }
  };

  const handleSelectSubfolders = () => {
    setIncludeSubfolders(prevState => !prevState);
  };

  // eslint-disable-next-line no-shadow
  const handleSetSelectedFolders = (folder, foldersWithDiffSettings) => {
    const allSubfolders = getSelectedChildFolders({
      folder,
      folderArrays: [],
      foldersWithDiffSettings,
    });
    setSelectedFolders(allSubfolders);
  };

  // eslint-disable-next-line no-shadow
  const callbackOnFolderRowClick = folder => {
    setSelectedFolder(folder);
  };

  // eslint-disable-next-line no-shadow
  const handleOnFolderRowClick = useCallback(folder => {
    callbackOnFolderRowClick(folder);
  }, []);

  useEffect(() => {
    if (includeSubfolders) {
      handleSetSelectedFolders(selectedFolder, subFoldersWithDiffSettings);
    } else {
      setSelectedFolders(selectedFolder ? [selectedFolder] : []);
    }
  }, [includeSubfolders, selectedFolder, subFoldersWithDiffSettings]);

  useEffect(() => {
    if (selectedFolder && selectedFolderId && foldersProductionSettings) {
      const productionSettingsTemp =
        foldersProductionSettings[selectedFolderId];
      if (
        productionSettingsTemp &&
        productionSettingsTemp.folderProductSettings
      ) {
        const {
          productionProperties,
        } = productionSettingsTemp.folderProductSettings;
        let mandatoryFields = {};
        productionProperties
          .filter(property => property.type === SELECT_FIELD_MANDATORY)
          .forEach(x => {
            mandatoryFields = { ...mandatoryFields, [x.name]: "" };
          });
        const newValue = { [selectedFolderId]: mandatoryFields };

        setProductionSettingErrors({ ...productionSettingErrors, ...newValue });
        setFolderMandatoryFields(mandatoryFields);
      }
    }
  }, [selectedFolder, selectedFolderId, foldersProductionSettings]);

  useEffect(() => {
    setSelectedFolders(folder ? [folder] : []);
  }, [folder]);

  useEffect(() => {
    // const activateStepThree = isActivateStepThree({
    //   fileSettings,
    //   folderSettings,
    // });
    // if (activateStepThree && steps.length === 2) {
    //   setSteps([STEP_ONE, STEP_TWO, STEP_THREE]);
    // } else if (!activateStepThree && steps.length === 3) {
    //   setSteps([STEP_ONE, STEP_TWO]);
    // }

    const folderEnabledTemp = isFolderMonitorEnabled({ filesFoldersSettings });

    if (folderEnabledTemp !== folderMonitorEnabled) {
      setFolderMonitorEnabled(folderEnabledTemp);
    }
  }, [filesFoldersSettings]);

  useEffect(() => {
    const filesAndFolderEnabledTemp = isFilesAndFolderMonitorEnabled({
      filesFoldersSettings,
    });
    if (filesAndFolderEnabledTemp !== filesAndFolderMonitorEnabled) {
      setFilesAndFolderMonitorEnabled(filesAndFolderEnabledTemp);
    }
  }, [filesFoldersSettings]);

  useEffect(() => {
    if (
      notificationActions &&
      notificationActions.length > 0 &&
      notificationActions.find(x => x.value === PRINT_AND_MAIL)
    ) {
      setPrintAndMailSelected(true);
    } else {
      setPrintAndMailSelected(false);
    }
  }, [notificationActions]);

  useEffect(() => {
    if (!printAndMailSelected) {
      setCheckedFolders([]);
      setPropertyFormValues([]);
      setFolderPropertyFormValues([]);
    }
  }, [printAndMailSelected]);

  useEffect(() => {
    if (!filesAndFolderMonitorEnabled) {
      setPropertyFormValues([]);
      if (!folderMonitorEnabled) {
        setFolderPropertyFormValues([]);
        setCheckedFolders([]);
      }
    }
  }, [filesAndFolderMonitorEnabled, folderMonitorEnabled]);

  const addressErrors = receiversAddressErrors.some(x => x.errors);

  const haveFolderFileSettings = isActivateStepThree({
    filesFoldersSettings,
  });

  return (
    <div className={classes.modalBody}>
      <Box className={classes.cardHead}>
        <Stepper activeStep={activeStep} steps={steps} />
      </Box>
      <Box className={classes.cardBody}>
        <StepOne
          notificationOccasionTypes={notificationOccasionTypes}
          notificationActions={notificationActions}
          notificationActionTypes={notificationActionTypes}
          notificationOccasion={notificationOccasion}
          recipients={recipients}
          setNotificationActions={setNotificationActions}
          setNotificationOccasion={setNotificationOccasion}
          setRecipients={setRecipients}
          printAndMailSelected={printAndMailSelected}
          siteId={siteId}
          receivers={receivers}
          setReceivers={setReceivers}
          visible={activeStep === 0}
          monitoringItemId={
            initialMonitoring ? initialMonitoring.monitoringItemId : null
          }
          setReceiversAddressErrors={setReceiversAddressErrors}
          receiversAddressErrors={receiversAddressErrors}
        />
        <StepTwo
          filesFoldersSettings={filesFoldersSettings}
          handleSelectSubfolders={handleSelectSubfolders}
          includeSubfolders={includeSubfolders}
          roles={roles}
          selectedFolders={selectedFolders}
          setFilesFolderSettings={setFilesFolderSettings}
          setRoles={setRoles}
          setSiteId={setSiteId}
          setUsers={setUsers}
          siteId={siteId}
          siteOptions={siteOptions}
          users={users}
          visible={activeStep === 1}
        />
        <StepThree
          checkedFolders={checkedFolders}
          filesAndFolderMonitorEnabled={filesAndFolderMonitorEnabled}
          folderMandatoryFields={folderMandatoryFields}
          folderMonitorEnabled={folderMonitorEnabled}
          folderPropertyFormValues={folderPropertyFormValues}
          foldersData={foldersData}
          foldersProductionSettings={foldersProductionSettings}
          handleSelectSubfolders={handleSelectSubfolders}
          haveFolderFileSettings={haveFolderFileSettings}
          includeSubfolders={includeSubfolders}
          loadingProductionSettings={loadingProductionSettings}
          notificationActions={notificationActions}
          onFolderRowClick={handleOnFolderRowClick}
          openedNodes={openedNodes}
          printAndMailSelected={printAndMailSelected}
          productionSettingErrors={productionSettingErrors}
          propertyFormValues={propertyFormValues}
          selectedFolder={selectedFolder}
          selectedFolders={selectedFolders}
          setCheckedFolders={setCheckedFolders}
          setFolderPropertyFormValues={setFolderPropertyFormValues}
          setProductionSettingErrors={setProductionSettingErrors}
          setPropertyFormValues={setPropertyFormValues}
          setSelectedFolders={setSelectedFolders}
          setSiteId={setSiteId}
          setSubFoldersWithDiffSettings={setSubFoldersWithDiffSettings}
          setWorkspaceName={setWorkspaceName}
          siteId={siteId}
          siteOptions={siteOptions}
          subFoldersWithDiffSettings={subFoldersWithDiffSettings}
          visible={activeStep === 2 && steps && steps.length > 2}
          workspaceName={workspaceName}
          workspaces={workspaces}
          siteSupplierId={siteSupplierId}
          setSiteSupplierId={setSiteSupplierId}
        />
      </Box>
      <StepFooter
        disableSave={disableSave}
        activeStep={activeStep}
        addressErrors={addressErrors}
        handleSaveMonitoringItem={handleSaveMonitoringItem}
        monitoringName={monitoringName}
        notificationActions={notificationActions}
        notificationOccasion={notificationOccasion}
        receivers={receivers}
        recipients={recipients}
        setActiveStep={setActiveStep}
        setMonitoringName={setMonitoringName}
        stepsLength={steps ? steps.length : 0}
        folderPropertyFormValues={folderPropertyFormValues}
        propertyFormValues={propertyFormValues}
        foldersProductionSettings={foldersProductionSettings}
        loadingProductionSettings={loadingProductionSettings}
        siteSupplierId={siteSupplierId}
      />
    </div>
  );
};

MonitoringMain.defaultProps = {
  initialFolders: [],
  folder: null,
  initialMonitoring: null,
  openedNodes: [],
};

MonitoringMain.propTypes = {
  setMonitoringOrderRecipientTabSettings: PropTypes.func.isRequired,
  openedNodes: PropTypes.arrayOf(PropTypes.number),
  currentViewer: PropTypes.shape({
    userId: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  initialSiteId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  folder: PropTypes.shape({ folderId: PropTypes.number }),
  siteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  initialFolders: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  monitoringName: PropTypes.string.isRequired,
  setMonitoringName: PropTypes.func.isRequired,
  initialMonitoring: PropTypes.shape({
    monitoringItemId: PropTypes.number,
    recipients: PropTypes.arrayOf(PropTypes.shape({})),
    orderRecipients: PropTypes.arrayOf(PropTypes.shape({})),
    fileMonitoring: PropTypes.shape({}),
    notificationActions: PropTypes.arrayOf(PropTypes.string),
    notificationOccasion: PropTypes.string,
    triggers: PropTypes.shape({
      file: PropTypes.shape({
        create: PropTypes.bool,
        delete: PropTypes.bool,
        download: PropTypes.bool,
        lockChange: PropTypes.bool,
        update: PropTypes.bool,
      }),
      folder: PropTypes.shape({
        changedName: PropTypes.bool,
        create: PropTypes.bool,
        delete: PropTypes.bool,
        fileCountEqualTo: PropTypes.bool,
        fileCountEqualToValue: PropTypes.number,
        fileCountGreaterThan: PropTypes.bool,
        fileCountGreaterThanValue: PropTypes.number,
      }),
      roles: PropTypes.shape({
        create: PropTypes.bool,
        delete: PropTypes.bool,
        update: PropTypes.bool,
      }),
      users: PropTypes.shape({
        create: PropTypes.bool,
        delete: PropTypes.bool,
        update: PropTypes.bool,
      }),
    }),
    siteId: PropTypes.number,
  }),
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setMonitoringOrderRecipientTabSettings,
    },
    dispatch
  );
};

const mapStateToProps = state => {
  return {
    currentViewer: state.api.currentViewer.viewer,
    siteOptions: getSiteOptions(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(MonitoringMain));

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Backdrop, Box, Dialog, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { updateWorkspaceRoleRights } from "../../../actions";
import { subscriptionLevels } from "../../../constant";

import { DELETE_SITE_ROLE, UPDATE_SITE_ROLE } from "../../../graphql/mutations";

import {
  GET_SITE_ROLE,
  GET_RESOURCE_SITE_ROLE_RIGHTS,
  GET_AVAILABLE_COLOR_SCHEMES,
  GET_DEFAULT_SITE_ROLE_SETTINGS,
} from "../../../graphql/queries";

import { useSnackbar } from "../../../hooks";
import { useGetSiteRoles } from "../../../hooks/useGetSiteRoles";

import { CustomSelect, PrimaryButton } from "../../common";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";
import CreateNameModal from "../../common/CreateNameModal";
import ManageRolesHeader from "../../RoleManagement/ManageRolesHeader";

import ColorSetsModal from "../ColorSetsModal";
import EditMenusModal from "../EditMenusModal";

import {
  getFolderRights,
  initialRights,
  initialSiteRoleSettings,
} from "./helpers";

import ConfirmDeleteModal from "./ConfirmDeleteModal";
import ManageFolders from "./ManageFolders";
import Modules from "./Modules";
import ResourceRightsList from "./ResourceRightsList";
import SiteRoles from "./SiteRoles";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1800px",
    height: "96vh",
    margin: 0,
    maxHeight: "unset",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  btnSave: {
    marginTop: 5,
  },
  btnCancel: {
    marginTop: 5,
  },
  label: {
    fontSize: 11,
    marginBottom: 5,
  },
  paperSidebar: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 15,
    "& .MuiFormControl-marginDense": {
      marginTop: 0,
    },
  },
  space: {
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  treeLabel: {
    paddingRight: 5,
    borderBottom: "1px solid #ddd",
    paddingBottom: 7,
  },
  treeWrap: {
    maxHeight: 500,
    overflowY: "auto",
    marginTop: 5,
    marginBottom: 15,
  },
  roleNameWrap: {
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: "1px solid #ddd",
    "& input": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const ManageRolesModal = ({
  open,
  selectedSite,
  selectedWorkspaceName,
  setOpenManageRoles,
  siteRoleOptions,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const currentSiteRoleId = useSelector(
    state => state.ui.toggleWorkspaces.workspace.siteRoleId
  );

  const handleClose = () => {
    setOpenManageRoles(false);
  };

  const [getColorSchemes, { data: colorSchemesData }] = useLazyQuery(
    GET_AVAILABLE_COLOR_SCHEMES
  );
  const [getSiteRole, { data: siteRolesData }] = useLazyQuery(GET_SITE_ROLE);
  const [getResourceRights, { loading: fetchingResourceRights, data: resourceRightsData }] = useLazyQuery(GET_RESOURCE_SITE_ROLE_RIGHTS);
  const [getDefaultSiteRoles, { data: defaultSiteRolesData }] = useLazyQuery(
    GET_DEFAULT_SITE_ROLE_SETTINGS
  );

  const [deleteSiteRole] = useMutation(DELETE_SITE_ROLE);
  const [updateSiteRole] = useMutation(UPDATE_SITE_ROLE);

  const { execute: getSiteListRoles } = useGetSiteRoles({
    siteId: selectedSite,
  });

  const [settingValues, setSettingValues] = useState({
    workSpaceSettings: true,
  });

  const [siteRoleId, setSiteRoleId] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [siteRoleValues, setSiteRoleValues] = useState({
    ...initialRights,
  });
  const [siteSettings, setSiteSettings] = useState({});
  const [siteRoleFolders, setSiteRoleFolders] = useState([]);
  const [folderRights, setFolderRights] = useState({});
  const [resourceRights, setResourceRights] = useState([]);
  const [resourceRightsChanges, setResourceRightsChanges] = useState({});
  const [openColorSets, setOpenColorSets] = useState(false);
  const [openEditMenus, setOpenEditMenus] = useState(false);
  const [openEditFolderRights, setOpenEditFolderRights] = useState(false);

  const [colorSchemes, setColorSchemes] = useState([]);

  const [currentSubscriptionLevel, setCurrentSubscriptionLevel] = useState(
    null
  );

  const [roleSubscriptionLevel, setRoleSubscriptionlevel] = useState({});

  const [confirmDeleteRoleModal, setConfirmDeleteRoleModal] = useState(false);

  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);

  const modes = { DEFAULT: "DEFAULT", ADD: "ADD", EDIT: "EDIT" };
  const [mode, setMode] = useState(modes.DEFAULT);
  const modeIs = (...o) => o.includes(mode);

  const changeMode = newMode => {
    setMode(newMode);
    if (newMode === modes.ADD) {
      setSaving(false);
    } else if (newMode === modes.EDIT) {
      setSaving(false);
      setDeleting(false);
    }
  };

  const isValidRoleName = name => {
    return name.trim() !== "";
  };

  const [fetchingColorSchemes, setFetchingColorSchemes] = useState(false);
  const [fetchingSiteRole, setFetchingSiteRole] = useState(false);

  const fetchColorSchemes = () => {
    setFetchingColorSchemes(true);
    getColorSchemes({ variables: { siteId: selectedSite } });
  };

  const fetchSiteRoles = () => {
    setFetchingSiteRole(true);
    getSiteRole({ variables: { siteRoleId } });
  };

  const fetchResourceRights = (folderId) => {
    getResourceRights({ variables: { siteRoleId, folderId } });
  };

  const fetchDefaultSiteRoles = () => {
    setFetchingColorSchemes(true);
    getDefaultSiteRoles({ variables: { siteId: selectedSite } });
  };

  useEffect(() => {
    if (
      siteRolesData &&
      siteRolesData.siteRoles &&
      siteRolesData.siteRoles.getSiteRole
    ) {
      const {
        availableSiteFolderColorSets,
        folders,
        name,
        selectedSiteFolderColorSet,
        siteRoleRights,
        siteRoleSettings,
      } = siteRolesData.siteRoles.getSiteRole;

      setRoleName(name);
      setSelectedRoleName(name);

      const { subscriptionLevels } = siteRoleRights || {};

      setRoleSubscriptionlevel(subscriptionLevels);
      setSiteRoleValues({
        ...siteRoleRights,
        folderColorScheme:
          selectedSiteFolderColorSet &&
            selectedSiteFolderColorSet.siteColorSetId
            ? selectedSiteFolderColorSet.siteColorSetId
            : "0",
      });
      setSiteSettings(siteRoleSettings);
      setSiteRoleFolders(folders);
      const rights = getFolderRights({ folders });
      setFolderRights(rights);

      setColorSchemes(availableSiteFolderColorSets);

      setFetchingSiteRole(false);
    }
  }, [siteRolesData]);

  useEffect(() => {
    var rights = resourceRightsData?.siteRoles?.getResourceRoleRights;
    if (!!rights && rights != undefined) {
      setResourceRights(rights ?? []);
    }
  }, [resourceRightsData]);

  useEffect(() => {
    if (
      defaultSiteRolesData &&
      defaultSiteRolesData.siteRoles &&
      defaultSiteRolesData.siteRoles.getDefaultSiteRoleSettings
    ) {
      const {
        selectedSiteFolderColorSet,
        availableSiteFolderColorSets,
        folders,
        siteRoleRights,
        siteRoleSettings,
      } = defaultSiteRolesData.siteRoles.getDefaultSiteRoleSettings;
      const { subscriptionLevels } = siteRoleRights || {};

      setRoleSubscriptionlevel(subscriptionLevels);

      setSiteRoleValues({
        ...siteRoleRights,
        folderColorScheme:
          selectedSiteFolderColorSet &&
            selectedSiteFolderColorSet.siteColorSetId
            ? selectedSiteFolderColorSet.siteColorSetId
            : "0",
      });
      setSiteSettings(siteRoleSettings);
      setSiteRoleFolders(folders);
      const rights = getFolderRights({ folders });
      setFolderRights(rights);

      setColorSchemes(availableSiteFolderColorSets);
    }
  }, [defaultSiteRolesData]);

  useEffect(() => {
    if (
      colorSchemesData &&
      colorSchemesData.siteRoles &&
      colorSchemesData.siteRoles.availableSiteFolderColorSets
    ) {
      setColorSchemes(colorSchemesData.siteRoles.availableSiteFolderColorSets);

      setFetchingColorSchemes(false);
    }
  }, [colorSchemesData]);

  const handleCheckbox = e => {
    const { checked, name } = e.target;
    if (checked) {
      if (
        name === "canOnlySeeAssignedProducts" ||
        name === "canNotSeeOtherSuppliers"
      ) {
        setSiteRoleValues({
          ...siteRoleValues,
          [name]: checked,
          supplierInWorkspace: checked,
        });
        return;
      }
    }
    if (!name) {
      console.error("Check box has no name. Site roles.");
      return;
    }
    setSiteRoleValues({ ...siteRoleValues, [name]: checked });
  };

  const handleOpenMenu = name => {
    setOpenEditFolderRights(false);
    setSettingValues({ [name]: true });
  };

  const handleSelect = e => {
    const { name, value } = e.target;
    setSiteRoleValues({ ...siteRoleValues, [name]: value });
  };

  const handleOpenColorSets = () => {
    setOpenColorSets(true);
  };
  const handleCloseColorSets = () => {
    setOpenColorSets(false);
  };

  const [openCopyRole, setOpenCopyRole] = useState(false);

  const [copyNameValue, setCopyNameValue] = useState("");

  const handleOpenCopyRole = () => {
    setCopyNameValue("");
    setOpenCopyRole(true);
  };

  const handleCloseCopyRole = () => {
    setOpenCopyRole(false);
  };

  const handleOpenEditMenus = () => {
    setOpenEditMenus(true);
  };
  const handleCloseEditMenus = () => {
    setOpenEditMenus(false);
  };

  const handleOpenEditFolderRights = () => {
    setOpenEditFolderRights(true);
    setSettingValues({});
  };

  const handleSiteRoleChange = e => {
    setSiteRoleId(e.target.value);
    setRoleName("");
    changeMode(modes.EDIT);
  };

  const handleCheckedParentFolder = (right, checked, folders) => {
    let tempValues = {};
    folders.forEach(folder => {
      tempValues = {
        ...tempValues,
        [folder.folderId]: { ...tempValues[folder.folderId], [right]: checked },
      };

      if (folder.hasChildFolders) {
        const childFoldersData = handleCheckedParentFolder(
          right,
          checked,
          folder.childFolders
        );

        tempValues = { ...tempValues, ...childFoldersData };
      }
    });

    return tempValues;
  };

  const handleFolderRights = (e, folder) => {
    e.stopPropagation();
    const { name, checked } = e.target;
    const id = folder.folderId;

    if (folder.hasChildFolders) {
      let tempFolderRights = { ...folderRights };
      const childFoldersData = handleCheckedParentFolder(
        name,
        checked,
        folder.childFolders
      );

      tempFolderRights = {
        ...tempFolderRights,
        ...childFoldersData,
        [id]: { ...folderRights[id], [name]: checked },
      };

      setFolderRights(tempFolderRights);
    } else {
      setFolderRights({
        ...folderRights,
        [id]: { ...folderRights[id], [name]: checked },
      });
    }
  };

  const resetValues = () => {
    setSiteRoleId(null);
    setSelectedRoleName("");
    setRoleName("");
    setSiteRoleValues({});
    setSiteSettings({});
    setSiteRoleFolders([]);
    setResourceRights([]);
    setResourceRightsChanges({});
    setFolderRights({});
    setOpenColorSets(false);
    setOpenEditMenus(false);
  };

  const resetToInitialStateOfRole = () => {
    setSiteRoleValues({});
    setSiteSettings({});
    setSiteRoleFolders([]);
    setResourceRights([]);
    setResourceRightsChanges({});
    setFolderRights({});
    setOpenColorSets(false);
    setOpenEditMenus(false);
  };

  useEffect(() => {
    if (siteRoleId) {
      fetchSiteRoles();
      resetToInitialStateOfRole();
    }
  }, [siteRoleId]);

  const onClickAddRole = () => {
    setSiteRoleId(0);
    fetchDefaultSiteRoles();
    resetValues();
    changeMode(modes.ADD);
  };

  const handleCopyRoleSave = async () => {
    if (mode != modes.ADD && mode !== modes.EDIT) {
      return;
    }

    const alertError = id => {
      const operationErrorId = modeIs(modes.ADD)
        ? "workspaceRoles.errors.couldNotCreate"
        : "workspaceRoles.errors.couldNotUpdate";
      const operationError = intl.formatMessage({ id: operationErrorId });
      const specificError = intl.formatMessage({ id });
      snackbar.workspaceError(`${specificError}\n${operationError}`);
    };

    setSaving(true);
    setOpenCopyRole(false);
    try {
      const siteRole = createSaveParameters();
      siteRole.siteRoleId = 0;
      siteRole.name = copyNameValue;
      //s�tt namn till vad det nu ska vara och siteRoleId = 0

      const res = await updateSiteRole({ variables: { siteRole } });
      if (res && res.data && res.data.updateSiteRole) {
        const { siteRoleRights, siteRoleId } =
          res && res.data && res.data.updateSiteRole;
        if (siteRoleId === currentSiteRoleId) {
          dispatch(updateWorkspaceRoleRights({ items: siteRoleRights }));
        }
        onSuccessSaveSiteRole(siteRoleId);
      } else {
        alertError("workspaceRoles.errors.genericError");
      }
    } catch (err) {
      alertError("workspaceRoles.errors.genericError");
      console.error("error >", err);
    }

    setSaving(false);
  };

  const handleSaveSiteRole = async () => {
    if (mode !== modes.ADD && mode !== modes.EDIT) {
      return;
    }

    const alertError = id => {
      const operationErrorId = modeIs(modes.ADD)
        ? "workspaceRoles.errors.couldNotCreate"
        : "workspaceRoles.errors.couldNotUpdate";
      const operationError = intl.formatMessage({ id: operationErrorId });
      const specificError = intl.formatMessage({ id });
      snackbar.workspaceError(`${specificError}\n${operationError}`);
    };

    setSaving(true);

    try {
      const siteRole = createSaveParameters();
      const res = await updateSiteRole({ variables: { siteRole } });
      if (res && res.data && res.data.updateSiteRole) {
        const { siteRoleRights, siteRoleId } =
          res && res.data && res.data.updateSiteRole;
        if (siteRoleId === currentSiteRoleId) {
          dispatch(updateWorkspaceRoleRights({ items: siteRoleRights }));
        }
        onSuccessSaveSiteRole(siteRoleId);
      } else {
        alertError("workspaceRoles.errors.genericError");
      }
    } catch (err) {
      alertError("workspaceRoles.errors.genericError");
      console.error("error >", err);
    }

    setSaving(false);
  };

  const onSuccessSaveSiteRole = siteRoleId => {
    const messageId = modeIs(modes.ADD)
      ? "workspaceRoles.success.roleCreated"
      : "workspaceRoles.success.roleUpdated";
    setSaving(false);
    setSelectedRoleName(roleName);
    setSiteRoleId(siteRoleId);
    getSiteListRoles();
    setResourceRights([]);
    setResourceRightsChanges({});
    changeMode(modes.EDIT);
    alert.success(<FormattedMessage id={messageId} />);
  };

  const mapResourceRightsChanges = () => {
    const dict = resourceRightsChanges;
    const keys = Object.keys(dict);
    return keys.map(resourceId => {
      return {
        resourceId,
        read: dict[resourceId].read || false,
        write: dict[resourceId].write || false,
      };
    });
  };

  const createSaveParameters = () => {
    let siteRole = {};

    const keys = Object.keys(folderRights);

    const folders = keys.map(folder => {
      return {
        folderId: parseInt(folder, 10),
        read: folderRights[folder].read || false,
        write: folderRights[folder].write || false,
      };
    });
    const rights = {
      ...initialRights,
      ...siteRoleValues,
    };
    const settings = {
      ...initialSiteRoleSettings,
      ...siteSettings,
    };
    delete rights.folderColorScheme;
    delete rights.subscriptionLevels;

    const folderColorScheme =
      siteRoleValues.folderColorScheme === "0"
        ? null
        : siteRoleValues.folderColorScheme; // CustomSelect value for defaultOption is "0", and must be converted to null.

    siteRole = {
      defaultView: 1,
      folderColorScheme: folderColorScheme,
      folders,
      name: roleName.trim(),
      resourceRightsChanges: mapResourceRightsChanges(),
      rights,
      settings,
      siteId: selectedSite,
      siteRoleId: siteRoleId,
    };

    if (modeIs(modes.ADD)) {
      siteRole.siteRoleId = 0;
    }

    return siteRole;
  };

  const onChangeRoleName = e => {
    var newRoleName = e.target.value;
    setRoleName(newRoleName);
  };

  useEffect(() => {
    if (siteRoleId || modeIs(modes.ADD)) {
      let subscriptions = [];
      const keys = Object.keys(siteRoleValues);
      keys.forEach(key => {
        if (typeof siteRoleValues[key] === "boolean" && siteRoleValues[key]) {
          const tempLevel = roleSubscriptionLevel[key];
          if (tempLevel === undefined) {
            return;
          }

          subscriptions = [...subscriptions, subscriptionLevels[tempLevel]];
        }
      });
      const newSubscriptionLevel = Math.max(...subscriptions);
      setCurrentSubscriptionLevel(
        Number.isInteger(newSubscriptionLevel) ? newSubscriptionLevel : 1
      );
    }
  }, [siteRoleValues, siteRoleId, mode]);

  const handleConfirmDeleteRole = async ({ id }) => {
    setConfirmDeleteRoleModal(false);
    return await handleDeleteSiteRole(id);
  };

  const handleDeleteSiteRole = async id => {
    if (mode !== modes.EDIT) {
      return;
    }

    const alertError = id => {
      const specific = intl.formatMessage({ id });
      const couldNotDelete = intl.formatMessage({
        id: "workspaceRoles.errors.couldNotDelete",
      });
      snackbar.workspaceError(`${specific}\n${couldNotDelete}`);
    };

    if (!Number.isSafeInteger(id)) {
      console.error("Invalid id:", id);
      alertError("workspaceRoles.errors.genericError");
      return;
    }

    setDeleting(true);

    try {
      const res = await deleteSiteRole({ variables: { siteRoleId: id } });
      if (res && res.data && res.data.deleteSiteRole) {
        const { success, errorResult } =
          res && res.data && res.data.deleteSiteRole;
        if (success) {
          onSuccessDeleteSiteRole();
        } else if (errorResult.errorCode === "SITE_ROLE_IN_USE") {
          alertError("workspaceRoles.errors.roleInUse");
        } else {
          alertError("workspaceRoles.errors.genericError");
        }
      } else {
        alertError("workspaceRoles.errors.genericError");
      }
    } catch (err) {
      alertError("workspaceRoles.errors.genericError");
      console.error("error >", err);
    }

    setDeleting(false);
  };

  const onSuccessDeleteSiteRole = () => {
    resetValues();
    getSiteListRoles();
    changeMode(modes.DEFAULT);
    alert.success(<FormattedMessage id="workspaceRoles.success.roleDeleted" />);
  };

  const [dialogTitle, setDialogTitle] = useState("");

  useEffect(() => {
    var newDialogTitle = createDialogTitle();
    setDialogTitle(newDialogTitle);
  }, [selectedRoleName]);

  const createDialogTitle = () => {
    var a = selectedWorkspaceName || "";
    var b = selectedRoleName || "";
    if (b.length > 0) {
      a += " - " + b;
    }

    return intl.formatMessage(
      { id: "siteRoles.manageRolesforWorkspace" },
      { workspace: a.trim() }
    );
  };

  const handleOnFolderSelect = (folderId) => {
    fetchResourceRights(folderId);
  };

  const handleResourceRightsChange = ({ resourceId, name, value }) => {
    let newRights = {};

    if (name === "write") {
      newRights.write = value;
      if (value) {
        newRights.read = true; // Skrivrättighet innebär läsrättighet.
      }
    }
    else if (name === "read") {
      newRights.read = value;
      if (!value) {
        newRights.write = false; // Skrivrättighet förutsätter läsrättighet.
      }
    }
    else {
      console.warn("** Unhandled resource right", { name, value });
      return;
    }

    setResourceRightsChanges((prev) => {
      var updated = {
        ...prev,
        [resourceId]: {
          ...prev[resourceId],
          ...newRights
        }
      };
      return updated;
    });
  };

  return (
    <>
      <Dialog
        customClass={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        fullScreen
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DraggableModalHead handleClose={handleClose} title={dialogTitle} />
        <DraggableModalBody>
          <Grid container spacing={3} className={classes.formControl}>
            <Grid item xs={12} md={2}>
              <Paper className={classes.paperSidebar} elevation={0}>
                <Grid container>
                  <Grid item md={12}>
                    <CustomSelect
                      options={siteRoleOptions}
                      value={siteRoleId}
                      inputLabel={intl.formatMessage({ id: "common.select" })}
                      onChange={handleSiteRoleChange}
                      disabled={saving || deleting || fetchingSiteRole}
                    />
                  </Grid>
                </Grid>
                <Box className={classes.space} />
                <Grid container>
                  <Grid item md={12}>
                    <div className={classes.btnSave}>
                      <PrimaryButton
                        fullWidth
                        onClick={onClickAddRole}
                        disabled={saving || deleting || fetchingSiteRole}
                      >
                        <FormattedMessage id="btn.add" />
                      </PrimaryButton>
                    </div>
                  </Grid>
                  {modeIs(modes.ADD, modes.EDIT) && (
                    <Grid item md={12}>
                      <div className={classes.btnSave}>
                        <PrimaryButton
                          fullWidth
                          onClick={() => setConfirmDeleteRoleModal(true)}
                          disabled={
                            saving ||
                            deleting ||
                            fetchingSiteRole ||
                            modeIs(modes.ADD)
                          }
                        >
                          <FormattedMessage id="context.delete" />
                        </PrimaryButton>
                      </div>
                      <div className={classes.btnSave}>
                        <PrimaryButton
                          fullWidth
                          onClick={() => handleOpenCopyRole()}
                          disabled={
                            saving ||
                            deleting ||
                            fetchingSiteRole ||
                            modeIs(modes.ADD)
                          }
                        >
                          <FormattedMessage id="btn.saveCopy" />
                        </PrimaryButton>
                      </div>
                    </Grid>
                  )}
                  {modeIs(modes.ADD, modes.EDIT) && (
                    <Grid item md={12}>
                      <div className={classes.btnSave}>
                        <PrimaryButton
                          fullWidth
                          onClick={() => handleSaveSiteRole()}
                          disabled={
                            !isValidRoleName(roleName) ||
                            saving ||
                            deleting ||
                            fetchingSiteRole
                          }
                        >
                          <FormattedMessage id="btn.save" />
                        </PrimaryButton>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Paper>
              {modeIs(modes.ADD, modes.EDIT) && (
                <Modules
                  disabled={saving || deleting || fetchingSiteRole}
                  handleOpenEditFolderRights={handleOpenEditFolderRights}
                  handleOpenEditMenus={handleOpenEditMenus}
                  handleOpenMenu={handleOpenMenu}
                  settingValues={settingValues}
                  siteRoleValues={siteRoleValues}
                />
              )}
            </Grid>

            {modeIs(modes.ADD, modes.EDIT) && (
              <>
                <Grid item xs={12} md={10}>
                  <ManageRolesHeader
                    currentSubscriptionLevel={currentSubscriptionLevel}
                    disabled={saving || deleting || fetchingSiteRole}
                    handleChange={onChangeRoleName}
                    isCreating={modeIs(modes.ADD)}
                    roleName={roleName}
                  />
                  {!openEditFolderRights && (
                    <SiteRoles
                      colorSchemes={colorSchemes}
                      disabled={saving || deleting || fetchingSiteRole}
                      handleCheckbox={handleCheckbox}
                      handleOpenColorSets={handleOpenColorSets}
                      handleSelect={handleSelect}
                      roleSubscriptionLevel={roleSubscriptionLevel}
                      settingValues={settingValues}
                      siteRoleValues={siteRoleValues}
                    />
                  )}
                  {openEditFolderRights && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <ManageFolders
                          disabled={saving || deleting || fetchingSiteRole || fetchingResourceRights}
                          folderRights={folderRights}
                          handleFolderRights={handleFolderRights}
                          onFolderSelect={handleOnFolderSelect}
                          siteRoleFolders={siteRoleFolders}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ResourceRightsList
                          sx={{ fontSize: "12px", lineHeight: "17.5px", minHeight: "17.5px" }}
                          disabled={saving || deleting || fetchingSiteRole || fetchingResourceRights}
                          onChange={handleResourceRightsChange}
                          resourceRights={resourceRights}
                          resourceRightsChanges={resourceRightsChanges}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </DraggableModalBody>
      </Dialog>
      {openColorSets && (
        <ColorSetsModal
          colorSchemes={colorSchemes}
          disabled={fetchingSiteRole || fetchingColorSchemes}
          fetchColorSchemes={fetchColorSchemes}
          handleClose={handleCloseColorSets}
          open={openColorSets}
          selectedSite={selectedSite}
        />
      )}
      {openEditMenus && (
        <EditMenusModal
          open={openEditMenus}
          roleName={selectedRoleName}
          handleClose={handleCloseEditMenus}
          siteRoleId={siteRoleId}
        />
      )}
      {confirmDeleteRoleModal && (
        <ConfirmDeleteModal
          id={siteRoleId}
          name={roleName}
          onClose={() => setConfirmDeleteRoleModal(false)}
          onConfirm={handleConfirmDeleteRole}
          open={confirmDeleteRoleModal}
        />
      )}
      {openCopyRole && (
        <CreateNameModal
          open={openCopyRole}
          disabled={false}
          title={intl.formatMessage({ id: "btn.saveCopy" })}
          handleClose={handleCloseCopyRole}
          placeholder={intl.formatMessage({ id: "btn.saveCopy" })}
          value={copyNameValue}
          onChange={e => setCopyNameValue(e.target.value)}
          onClick={handleCopyRoleSave}
        />
      )}
    </>
  );
};

ManageRolesModal.defaultProps = {
  siteRoleOptions: [],
};

ManageRolesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenManageRoles: PropTypes.func.isRequired,
  siteRoleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
};

export default ManageRolesModal;

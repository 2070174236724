/* /src/components/auth/callback.jsx */
import React from "react";
import { AuthConsumer } from "../../providers/authProvider";
import { LoadingScreen } from "../common";

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return <LoadingScreen />;
    }}
  </AuthConsumer>
);

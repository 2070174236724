import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "../../../../hooks";
import axios from "axios";
import { saveAs } from "file-saver";
import VersionHistory from "./VersionHistory";
import { GET_RESOURCE_VERSION_HISTORY } from "../../../../graphql/queries";
import TableSkeleton from "../../../Skeleton/TableSkeleton";
import logger from "../../../../helpers/logger";
import {
  INIT_RESOURCE_VERSION_DOWNLOAD,
  INITIALIZE_FILE_UPLOAD,
} from "../../../../graphql/mutations";

const VersionHistoryContainer = ({ locked, resourceId, folderId }) => {
  const [versionHistory, setVersionHistory] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [initResourceVersionDownload] = useMutation(
    INIT_RESOURCE_VERSION_DOWNLOAD
  );
  const [uploadFile] = useMutation(INITIALIZE_FILE_UPLOAD);

  const { data, loading, error, refetch } = useQuery(
    GET_RESOURCE_VERSION_HISTORY,
    {
      variables: {
        resourceId,
      },
      skip: !resourceId,
    }
  );

  // eslint-disable-next-line no-shadow
  const handleDownloadResource = async ({ versionNumber }) => {
    try {
      const res = await initResourceVersionDownload({
        variables: {
          resourceId,
          versionNumber,
        },
      });

      if (res && res.data && res.data.initResourceVersionDownload) {
        saveAs(res.data.initResourceVersionDownload);
      } else {
        console.log("Error downloading resource");
      }
    } catch (err) {
      console.log("Error downloading resource ", err);
    }
  };

  const snackbar = useSnackbar();

  useEffect(() => {
    if (error || loading) {
      setVersionHistory([]);
    }
    if (
      data &&
      data.resourceVersionHistory &&
      data.resourceVersionHistory.versions
    ) {
      setVersionHistory(data.resourceVersionHistory.versions);
    }
  }, [data, loading, error]);

  const handleFileUpload = async e => {
    const { target } = e || {};
    const { files } = target || {};
    if (uploadedFile.length > 0) {
      snackbar.workspaceError(
        <createNewProductVersion id="product.uploadError" />
      );
      return;
    }

    const res = await uploadFile({
      variables: { folderId, resourceId },
    });

    if (res && res.data && res.data.initFileUpload) {
      const uploadUrl = res.data.initFileUpload.url;

      if (files && files[0]) {
        try {
          setLoadingUpload(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          const response = await axios.post(`${uploadUrl}`, formData);

          if (
            response &&
            response.data &&
            response.data.success &&
            response.status === 200
          ) {
            const id = response.data.resourceId;

            const newFile = { resourceId: id, name: files[0].name };
            setUploadedFile([newFile]);
            refetch();
            logger.log(`Success uploading file.`);
          } else {
            logger.error(
              `An error occurred while uploading the file. ${JSON.stringify(
                response
              )}`
            );
          }
          setLoadingUpload(false);
        } catch (uploadException) {
          setLoadingUpload(false);
          logger.error(
            `An error occurred while uploading the file. ${JSON.stringify(
              uploadException
            )}`
          );
        }
      } else {
        logger.warn("An error could be happening in selecting files.");
      }
    }
  };

  if (loading) {
    return <TableSkeleton />;
  }

  return (
    <VersionHistory
      locked={locked}
      items={versionHistory}
      onDownloadVersion={handleDownloadResource}
      refetchVersionHistory={refetch}
      onChange={handleFileUpload}
    />
  );
};

VersionHistoryContainer.defaultProps = {
  locked: false,
};

VersionHistoryContainer.propTypes = {
  resourceId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
};

export default VersionHistoryContainer;

import { gql } from '@apollo/client';

const GET_SAVED_NOTIFICATION = gql`
  query getSavedNotification($savedNotificationId: Int!) {
    myNotificationContext {
      getSavedEventsByNotification(savedNotificationId: $savedNotificationId) {
        savedNotificationId
        siteId
        name
        savedDate
        fileFolderEvents {
          ...folderEventField
          ...childfolderEvent
        }
        siteRoleEvents {
          eventId
          eventChannel
          userId
          username
          eventTime
          name
          previousName
          subscriptionLevelChanged
        }
        siteUserEvents {
          eventId
          eventChannel
          userId
          username
          eventTime
          affectedUsername
          previousSiteRole
        }
      }
    }
  }

  fragment folderEventField on FileFolderEventDTO {
    folderId
    name
    events {
      userId
      username
      siteId
      eventId
      eventChannel
      eventTime
      resourceEvent {
        name
        previousName
        resourceId
        versionId
        versionNo
      }
      folderEvent {
        previousName
        previousParentFolderId
      }
    }
  }

  fragment childfolderEvent on FileFolderEventDTO {
    children {
      ...folderEventField
      children {
        ...folderEventField
        children {
          ...folderEventField
          children {
            ...folderEventField
            children {
              ...folderEventField
              children {
                ...folderEventField
                children {
                  ...folderEventField
                  children {
                    ...folderEventField
                    children {
                      ...folderEventField
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_SAVED_NOTIFICATION;

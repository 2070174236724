import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, Typography, Icon } from "@mui/material";
import clsx from "clsx";
import UploadBox from "./UploadBox";
import { FormattedMessage } from "react-intl";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(theme => ({
  root: {

  },
  headTitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: "0px",
  },
  headSubTitle: {
    marginTop: "-2px",
    fontSize: 14,
    fontWeight: 400,
  },
  headMarginBottom: {
    marginBottom: "10px",
  },	

  actionButtons: {
    justifyContent: "space-between",
    "& > div": {
      border: "none",
      width: "unset",
      marginTop: "-2px",
      padding: "0px",
      "& button": {
        height: "26px",
      },
    },
    "& :only-child": {
      marginLeft: "auto",
    },
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  btnDesign: {
    fontSize: "12px",
    lineHeight: "14px",
  },
}));

const CompanyTemplates = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.headMarginBottom} xs={2}>
        <Typography className={classes.headTitle}><FormattedMessage id="admin.companyTemplates" /></Typography>
        {/*<Typography className={classes.headSubTitle}>SubHeader here</Typography>*/}
      </Box>
      <Card>
        <CardContent>
          <Box sx={{ minWidth: 120, paddingTop: "25px" }} />
        </CardContent>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: 20 }}>
            <FormattedMessage id="admin.companyTemplateImport" />
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ fontSize: 16 }}>
            <FormattedMessage id="admin.companyTemplateDescription" />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage id="admin.companyTemplateClarification" />
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
            <FormattedMessage id="admin.companySettings.companyLogoPlatform.Warning" />
          </Typography>
        </CardContent>
        <Divider />
        <CardActions sx={{ width: '100%', padding: "16px" }} className={classes.actionButtons}>
          <UploadBox />
          <Button size="small" className={classes.btnDesign} variant="outlined" startIcon={<Icon className={clsx(["fas fa-file-import",])} />}><FormattedMessage id="btn.importTemplate" /></Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CompanyTemplates;

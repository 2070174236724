import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Checkbox, Grid, Icon, IconButton, Box } from "@mui/material";
import PropTypes from "prop-types";
import clsx from "clsx";
import CustomTextField from "../../../../common/TextField";
import { setMetadata, removeMetadata } from "../../../../../actions";

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: 0,
    marginBottom: 3,
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  iconButtonDelete: {
    color: theme.palette.common.red,
    padding: 4,
  },
  icon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
  },
}));

const CustomMetadataRow = ({ metaIndex }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    customMetadata: { metadata },
  } = useSelector(state => state.api);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const label = name;
    dispatch(setMetadata({ label, value, metaIndex: index }));
  };

  const handleRemoveMetadata = index => {
    dispatch(removeMetadata({ metaIndex: index }));
  };

  const handleCheck = (event, index) => {
    const { name, checked } = event.target;
    dispatch(setMetadata({ label: name, value: checked, metaIndex: index }));
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Checkbox
            color="primary"
            name="display"
            checked={metadata[metaIndex].display || false}
            onChange={e => handleCheck(e, metaIndex)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            type="text"
            name="label"
            value={metadata[metaIndex].label || ""}
            onChange={e => handleChange(e, metaIndex)}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            type="text"
            name="value"
            value={metadata[metaIndex].value || ""}
            onChange={e => handleChange(e, metaIndex)}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            className={classes.iconButtonDelete}
            onClick={() => handleRemoveMetadata(metaIndex)}
          >
            <Icon className={clsx(["fa fa-trash-alt", classes.icon])} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

CustomMetadataRow.propTypes = {
  metaIndex: PropTypes.number.isRequired,
};

export default CustomMetadataRow;

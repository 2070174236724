import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  productInfo: {
    margin: 0,
  },
  dt: {
    width: "40%",
    wordBreak: "break-all",
    verticalAlign: "middle",
    fontWeight: 600,
    display: "inline-block",
    "&::after": {
      content: '":"',
    },
    fontSize: 12,
  },
  dd: {
    wordBreak: "break-all",
    verticalAlign: "middle",
    display: "inline-block",
    marginBottom: 0,
    marginLeft: 5,
    fontSize: 12,
  },
}));

const ProductMaterial = ({ material }) => {
  const classes = useStyles();
  return (
    <dl className={classes.productInfo}>
      <dt className={classes.dt}>
        <FormattedMessage id="product.material" />
      </dt>
      <dd className={classes.dd}>{material}</dd>
    </dl>
  );
};

ProductMaterial.propTypes = {
  material: PropTypes.string.isRequired,
};

export default ProductMaterial;

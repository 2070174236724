import { gql } from '@apollo/client';

const CREATE_NEWSPOST_TEMPLATE = gql`
  mutation CreateNewspostTemplate(
    $siteId: Int!
    $templateId: ID
    $thumbnailImage: ID
    $title: String
  ) {
    createNewspostTemplate(
      input: {
        siteId: $siteId
        templateId: $templateId
        thumbnailImage: $thumbnailImage
        title: $title
      }
    ) {
      id
      title
    }
  }
`;

export default CREATE_NEWSPOST_TEMPLATE;

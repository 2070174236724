import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";

const useStyles = makeStyles(() => ({
  margin: { marginTop: 0 },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const SimpleSelect = ({ value, label, onChange, options, shrink }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.margin} fullWidth>
      {label && <InputLabel shrink={shrink}>{label}</InputLabel>}
      <Select value={value} onChange={onChange} input={<BootstrapInput />}>
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label || option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SimpleSelect.defaultProps = {
  label: null,
  shrink: false,
};

SimpleSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    })
  ).isRequired,
  shrink: PropTypes.bool,
};

export default SimpleSelect;

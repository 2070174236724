import { createActions } from "redux-actions";

export const {
  setSiteUsers,
  setSiteUsersLoading,
  setSiteUsersError,
  setSiteRoles,
  setSiteRolesLoading,
  setSiteRolesError,
} = createActions({
  SET_SITE_USERS: ({ items }) => ({ items }),
  SET_SITE_USERS_LOADING: ({ loading }) => ({ loading }),
  SET_SITE_USERS_ERROR: ({ error }) => ({ error }),
  SET_SITE_ROLES: ({ items }) => ({ items }),
  SET_SITE_ROLES_LOADING: ({ loading }) => ({ loading }),
  SET_SITE_ROLES_ERROR: ({ error }) => ({ error }),
});

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { List } from "@mui/material";
import ConversationListItem from "./ConversationListItem";

const useStyles = makeStyles(() => ({
  listRoot: {
    padding: 0,
    height: "100%",
    width: "100%",
  },
  listItem: {
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2,
    justifyContent: "space-between",
    minHeight: 36,
    "& > div > button": {
      display: "none",
    },
    "&:hover > div > button": {
      display: "block",
    },
  },
  nameText: {
    fontSize: 12,
  },
  listItemActive: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  statusOnline: {
    width: 5,
    height: 5,
    backgroundColor: "#3FA844",
    borderRadius: "50%",
    marginRight: 8,
    display: "inline-block",
    verticalAlign: "middle",
  },
  statusOffline: {
    width: 5,
    height: 5,
    backgroundColor: "#a7a7a7",
    borderRadius: "50%",
    marginRight: 8,
    display: "inline-block",
    verticalAlign: "middle",
  },
  deleteIconContainer: {
    minWidth: 26,
    paddingRight: 5,
  },
  deleteIcon: {},
}));

const ConversationsList = ({
  activeConversationId,
  conversations,
  onConversationSelected,
  onMessageAdded,
}) => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.listRoot}>
      {conversations &&
        conversations.map((x, index) => {
          return (
            <ConversationListItem
              key={x.directMessageConversationId}
              activeConversationId={activeConversationId}
              conversation={x}
              onConversationSelected={onConversationSelected}
              onMessageAdded={onMessageAdded}
            />
          );
        })}
    </List>
  );
};

ConversationsList.defaultProps = {
  activeConversationId: null,
  conversations: [],
};

ConversationsList.propTypes = {
  activeConversationId: PropTypes.number,
  conversations: PropTypes.arrayOf(PropTypes.shape({})),
  onConversationSelected: PropTypes.func.isRequired,
  onMessageAdded: PropTypes.func.isRequired,
};

export default ConversationsList;

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const UPDATE_USER_FILE_VIEW_SETTINGS = gql`
  mutation UpdateUserFileViewSettings($update: UserFileViewSettingsInput) {
    siteSettingsContext {
      updateUserFileViewSettings(update: $update) {
        errorResult {
          ...ErrorResultFields
        }
        success
      }
    }
  }
  ${ErrorResultFragment}
`;

export default UPDATE_USER_FILE_VIEW_SETTINGS;

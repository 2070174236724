import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useIntl } from "react-intl";
import AddressFields from "./AddressFields";
import {
  Checkbox,
  DeliveryAddressHeader,
  InvoiceAddressHeader,
  PostalAddressHeader,
} from "../common";

const AddressSection = ({
  deliveryAddressChanged,
  deliveryAddress,
  deliveryAddressFieldsError,
  handleAddressFieldChange,
  handleChangeDeliveryAddress,
  invoiceAddress,
  invoiceAddressFieldsError,
  postalAddress,
  postalAddressFieldsError,
  sortedAddressSettings,
  showDeliveryAddress,
  showInvoiceAddress,
  showOrdererAddress,
  isAllFieldsHidden,
}) => {
  const {
    deliveryAddressSettings,
    postalAddressSettings,
    invoiceAddressSettings,
  } = sortedAddressSettings;

  const { delivery, postal, invoice } = isAllFieldsHidden;
  const intl = useIntl();

  return (
    <Grid container spacing={2} direction="row" align-items="center">
      {showDeliveryAddress && !delivery && (
        <Grid item xs={12} md={4}>
          <DeliveryAddressHeader />
          <AddressFields
            addressSettings={deliveryAddressSettings}
            address={deliveryAddress}
            handleAddressFieldChange={handleAddressFieldChange}
            addressType="deliveryAddress"
            errors={deliveryAddressFieldsError}
          />
          <Checkbox
            label={intl.formatMessage({
              id: "cart.changeAddress",
            })}
            onChange={handleChangeDeliveryAddress}
            value={deliveryAddressChanged}
          />
        </Grid>
      )}
      {showInvoiceAddress && !postal && (
        <Grid item xs={12} md={4}>
          <PostalAddressHeader />
          <AddressFields
            addressSettings={postalAddressSettings}
            address={postalAddress}
            handleAddressFieldChange={handleAddressFieldChange}
            addressType="postalAddress"
            errors={postalAddressFieldsError}
          />
        </Grid>
      )}
      {showOrdererAddress && !invoice && (
        <Grid item xs={12} md={4}>
          <InvoiceAddressHeader />
          <AddressFields
            addressSettings={invoiceAddressSettings}
            address={invoiceAddress}
            handleAddressFieldChange={handleAddressFieldChange}
            addressType="invoiceAddress"
            errors={invoiceAddressFieldsError}
          />
        </Grid>
      )}
    </Grid>
  );
};

AddressSection.defaultProps = {
  showDeliveryAddress: true,
  showInvoiceAddress: true,
  showOrdererAddress: true,
};

AddressSection.propTypes = {
  showDeliveryAddress: PropTypes.bool,
  showInvoiceAddress: PropTypes.bool,
  showOrdererAddress: PropTypes.bool,
  deliveryAddressChanged: PropTypes.bool.isRequired,
  handleChangeDeliveryAddress: PropTypes.func.isRequired,
  sortedAddressSettings: PropTypes.shape({
    deliveryAddressSettings: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        displayOrder: PropTypes.number,
        label: PropTypes.string,
      })
    ).isRequired,
    postalAddressSettings: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        displayOrder: PropTypes.number,
        label: PropTypes.string,
      })
    ).isRequired,
    invoiceAddressSettings: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        displayOrder: PropTypes.number,
        label: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  handleAddressFieldChange: PropTypes.func.isRequired,
  deliveryAddress: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    customerId: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
    telephone: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  postalAddress: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    customerId: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
    telephone: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  invoiceAddress: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    customerId: PropTypes.string,
    email: PropTypes.string,
    reference: PropTypes.string,
    telephone: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  deliveryAddressFieldsError: PropTypes.objectOf(PropTypes.string).isRequired,
  invoiceAddressFieldsError: PropTypes.objectOf(PropTypes.string).isRequired,
  postalAddressFieldsError: PropTypes.objectOf(PropTypes.string).isRequired,
  isAllFieldsHidden: PropTypes.shape({
    delivery: PropTypes.bool,
    postal: PropTypes.bool,
    invoice: PropTypes.bool,
  }).isRequired,
};

export default AddressSection;

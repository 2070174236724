import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";

import EmptyCategory from "../../assets/img/empty-category.svg";

const useStyles = makeStyles(theme => ({
  emptyWrap: {
    textAlign: "center",
    padding: "40px 0 !important",
    backgroundColor: theme.palette.common.white,
  },
  emptyText: {
    fontSize: 12,
    marginTop: 10,
    color: theme.palette.text.secondary,
  },
}));

const EmptyList = ({ messageId }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.emptyWrap}>
      <img src={EmptyCategory} alt="Empty" />
      <Typography className={classes.emptyText}>
        <FormattedMessage id={messageId} />
      </Typography>
    </Grid>
  );
};

EmptyList.defaultProps = {
  messageId: "product.categoryEmpty",
};
EmptyList.propTypes = {
  messageId: PropTypes.string,
};

export default EmptyList;

import React from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material";
import InputBase from "@mui/material/InputBase";

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.grey, 0.35),
    marginRight: 8,
    marginLeft: 0,
    width: "100%",
    marginBottom: 15,
  },
  searchIcon: {
    width: theme.spacing(4),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    right: 0,
    color: "#fff",
  },
  inputRoot: {
    color: theme.palette.common.white,
    marginRight: 8,
  },
  inputInput: {
    paddingTop: 11,
    paddingRight: 20,
    paddingBottom: 11,
    paddingLeft: 11,
    fontSize: 12,
  },
}));

const SearchBar = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.search}>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "Search" }}
        placeholder={intl.formatMessage({ id: "nav.search" })}
      />
      <div className={classes.searchIcon}>
        <i className={`nc-icon nc-zoom-split ${classes.zoomIcon}`} />
      </div>
    </div>
  );
};

export default SearchBar;

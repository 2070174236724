import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";
import CheckboxDefault from "../../common/FormControl/Checkbox";

const useStyles = makeStyles(() => ({
  label: {
    marginRight: 5,
    fontSize: 12,
  },
}));

const CheckboxField = ({ displayName, fieldName, handleChange, value }) => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container alignItems="center">
        <Typography className={classes.label}>{displayName}</Typography>
        <CheckboxDefault
          value={value}
          name={fieldName}
          onChange={handleChange}
        />
      </Grid>
    </Box>
  );
};

CheckboxField.defaultProps = {
  displayName: null,
  error: null,
  value: null,
};

CheckboxField.propTypes = {
  error: PropTypes.shape({
    error: PropTypes.bool,
  }),
  fieldName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

export default CheckboxField;

import { useEffect, useState } from "react";
import EditorContainer from "../../common/EditorContainer";
import StringEditor from "../../common/EditorContainer/StringEditor/StringEditor";

const NameField = ({ disabled, enableEdit, reset, onChange, value, onDirty }) => {
  const [state, setState] = useState({
    dirty: false,
    editValue: value,
  });

  const hasChanged = (newValue) => newValue !== value;

  const handleChange = (newValue) => {
    
    const changed = hasChanged(newValue);
    setState((prev) => ({
      ...prev,
      dirty: changed,
      editValue: newValue,
    }));

    if (state.dirty !== changed) {
      onDirty?.({ dirty: changed });      
    }

    if (changed) {
      onChange?.({ value: newValue });
    }
  };

  useEffect(() => {
    if (reset) {
      setState({
        dirty: false,
        editing: false,
        editValue: value,
      });
    }
  }, [reset, value]);

  const placeholder = "Uppgiftens namn";

  return (
    <EditorContainer
      disabled={disabled}
      fullWidth
      multiline={true}
      enableEdit={enableEdit} //TODO: Check this
      onChange={handleChange} //TODO: Will save direct onChange
      placeholder={placeholder}
      component={StringEditor}
      sx={{
        color: "rgba(0, 0, 0, 0.87)",
        border: "1px solid rgba(0, 0, 0, 0)",
        borderRadius: "4px",
        fontSize: "17px",
        fontWeight: 500,
        minHeight: "1.5em",
        lineHeight: "25.5px",
        padding: "4px",
        "&.MuiBox-root": { minHeight: "1.5em", padding: "0px", border: "0px", },
        "& .MuiTypography-root": { whiteSpace: "pre-wrap", },
        "&.MuiInputBase-root": { border: "1px solid rgba(0, 0, 0, 0.5)", },
        //"& .MuiInputBase-input": {padding: "0px", border: "0px",},
        "&:hover": {backgroundColor: '#f0f0f0', cursor: "text",},
        //"&:focus": {border: "1px solid rgba(0, 0, 0, 0.5)", cursor: "text",},
      }}
      value={state?.editValue}
    />
  );
};

export default NameField;

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import {
  DashboardContainer,
  InformationSection,
  SiteHeaderTitle,
} from "../../components/dashboard";

const useStyles = makeStyles(theme => ({
  headerTextBold: {
    fontWeight: 600,
    fontSize: 18,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
    marginBottom: 3,
  },
  headerTextNormal: {
    fontSize: 14,
    textAlign: "left",
  },
  headerTextItalic: {
    fontStyle: "italic",
    fontSize: 14,
    textAlign: "left",
  },
  mb8: {
    marginBottom: 8,
  },
}));

const Klara = workspacename => {
  const classes = useStyles();

  return (
    <DashboardContainer>
      <InformationSection>
        <SiteHeaderTitle title={"Välkommen till " + workspacename.workspace} />
        <Typography className={classes.headerTextNormal}>
          Klara är Kommunals webshop för kontorstryck: Brevpapper, Kuvert,
          Visitkort, Korrkort, Konferensblock. Klicka på det du vill köpa,
          priset står i anslutning till produkten. Var noga med att granska PDF
          innan godkännande av tryck.
        </Typography>
        <br />
        <Typography
          className={clsx([classes.headerTextBold, classes.headerTextNormal])}
        >
          Beräknad leveranstid: 7-10 arbetsdagar.
        </Typography>
        <br />
        <Typography className={classes.headerTextNormal}>
          Har du frågor om systemet, maila i första hand till wilma@kommunal.se
        </Typography>
        <Typography className={classes.headerTextNormal}>
          Har du frågor om din order, maila i första hand till
          kristina.arvidsson@exakta.se
        </Typography>
        <br />
        <Typography variant="h3" className={classes.headerTextBold}>
          Rutin för posthantering och svarkuvert
        </Typography>
        <br />
        <Typography className={classes.headerTextNormal}>
          Dessa rutiner gäller från 28 augusti 2017
        </Typography>
        <br />
        <Typography
          className={clsx([classes.headerTextBold, classes.headerTextNormal])}
        >
          Framtagna rutiner för underlättande av posthanteringen
        </Typography>
        <Typography className={classes.headerTextNormal}>
          Medlemscenter servar med svarspostkuvert som ska användas av
          avdelningar och sektioner eller av våra medlemmar.
        </Typography>
        <br />
        <Typography className={classes.headerTextNormal}>
          När kuverten börjar ta slut ska sektionen vända sig till sin avdelning
          för att beställa nya. Avdelningarna kontaktar kontorsvärden på
          respektive Medlemscenter när de behöver fylla på. De svarspostkuvert
          som sektioner och avdelningar kan beställa, är endast de som går till
          Medlemscentren.
        </Typography>
        <br />
        <Typography className={classes.headerTextItalic}>
          Det finns inga svarspostkuvert till ekonomicenter som är avsedda för
          sektioner, avdelningar eller medlemmar.
        </Typography>
        <Typography variant="body1" className={classes.headerTextItalic}>
          Det finns inga svarspostkuvert till a-kassan som är avsedda för
          sektioner, avdelningar eller medlemmar.
        </Typography>
        <Typography className={classes.headerTextItalic} variant="body1">
          (märkta med Arjeplog) Ska handlingar skickas till a-kassan görs det på
          egen bekostnad.
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Typography
              className={clsx([
                classes.headerTextNormal,
                classes.headerTextBold,
              ])}
            >
              Svarspost Inträdesansökan, ska skannas
            </Typography>
            <Typography
              className={clsx([classes.headerTextItalic, classes.mb8])}
            >
              (adressen finns förtryckt på inträdesansökan)
            </Typography>
            <Typography className={classes.headerTextNormal}>
              Kommunal/Kommunals a-kassa
            </Typography>
            <Typography className={classes.headerTextNormal}>
              SVARSPOST
            </Typography>
            <Typography className={classes.headerTextNormal}>
              20568158
            </Typography>
            <Typography className={classes.headerTextNormal}>
              938 19 Arjeplog
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              className={clsx([
                classes.headerTextNormal,
                classes.headerTextBold,
              ])}
            >
              Svarspostadress för Förbundspost, ska skannas:
            </Typography>
            <Typography
              className={clsx([classes.headerTextNormal, classes.mb8])}
            >
              Autogiromedgivande, svarskuvert till skanning samt blanketter som
              skannas ex: pensionsbrev mm.
            </Typography>
            <Typography className={classes.headerTextNormal}>
              Kommunal
            </Typography>
            <Typography className={classes.headerTextNormal}>
              SVARSPOST
            </Typography>
            <Typography className={classes.headerTextNormal}>
              20568158
            </Typography>
            <Typography className={classes.headerTextNormal}>
              938 19 Arjeplog
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Typography
              className={clsx([
                classes.headerTextNormal,
                classes.headerTextBold,
              ])}
            >
              Svarspostkuvert till Medlemscenter Malmö
            </Typography>
            <Typography className={classes.headerTextNormal}>
              Kommunal
            </Typography>
            <Typography className={classes.headerTextNormal}>
              SVARSPOST
            </Typography>
            <Typography className={classes.headerTextNormal}>
              20569091
            </Typography>
            <Typography className={classes.headerTextNormal}>
              201 10 Malmö
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              className={clsx([
                classes.headerTextNormal,
                classes.headerTextBold,
              ])}
            >
              Svarspostkuvert till Medlemscenter Sundsvall
            </Typography>
            <Typography className={classes.headerTextNormal}>
              Kommunal
            </Typography>
            <Typography className={classes.headerTextNormal}>
              SVARSPOST
            </Typography>
            <Typography className={classes.headerTextNormal}>
              20569089
            </Typography>
            <Typography className={classes.headerTextNormal}>
              858 00 Sundsvall
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant="h3" className={classes.headerTextBold}>
          Post där Kommunal inte står för portot
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Typography
              className={clsx([
                classes.headerTextNormal,
                classes.headerTextBold,
              ])}
            >
              Post till skanningscentral
            </Typography>
            <Typography
              className={clsx([classes.headerTextNormal, classes.mb8])}
            >
              Blanketter med styrtecken som inte har svarskuvert.
            </Typography>
            <Typography className={classes.headerTextNormal}>
              Kommunal
            </Typography>
            <Typography className={classes.headerTextNormal}>FE1051</Typography>
            <Typography className={classes.headerTextNormal}>
              930 88 Arjeplog
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              className={clsx([
                classes.headerTextNormal,
                classes.headerTextBold,
              ])}
            >
              Post till Medlemscenter:
            </Typography>
            <Typography className={classes.headerTextNormal}>
              Kommunal
            </Typography>
            <Typography className={classes.headerTextNormal}>
              Box 546
            </Typography>
            <Typography className={classes.headerTextNormal}>
              201 25 Malmö
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Typography className={classes.headerTextNormal}>Kommunal</Typography>
        <Typography className={classes.headerTextNormal}>Box 753</Typography>
        <Typography className={classes.headerTextNormal}>
          851 22 Sundsvall
        </Typography>
        <br />

        <Typography
          className={clsx([classes.headerTextNormal, classes.headerTextBold])}
        >
          Post till a-kassan
        </Typography>
        <Typography className={classes.headerTextNormal}>
          Kommunals A-kassa
        </Typography>
        <Typography className={classes.headerTextNormal}>FE1151</Typography>
        <Typography className={classes.headerTextNormal}>
          930 88 Arjeplog
        </Typography>
      </InformationSection>
    </DashboardContainer>
  );
};

Klara.propTypes = {};

export default Klara;

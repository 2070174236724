import { gql } from '@apollo/client';

import ErrorResultFragment from "../fragments/ErrorResult";
import StructureFieldsFragment from "../fragments/StructureFields";
import StructureRecursiveFragment from "../fragments/StructureRecursive";

const SAVE_STRUCTURE = gql`
  mutation SaveStructure($input: StructureInput) {
    saveStructure(input: $input) {
      __typename
      errorResult {
        ...ErrorResultFields
      }
      success
      result {
        items {
          ...StructureFields
          ...StructureRecursive
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${StructureFieldsFragment}
  ${StructureRecursiveFragment}
`;

export default SAVE_STRUCTURE;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import { ClearButton, PrimaryButton } from "../../common/Buttons";
import WarningImage from "../../../assets/img/warning-icon.svg";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
    textAlign: "center",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  img: {
    marginBottom: 10,
    width: 40,
  },
}));

const ConfirmRemoveRecipient = ({
  open,
  setOpen,
  handleConfirmButton,
  handleNoButton,
  toRemoveUser,
  tabNumber,
}) => {
  const classes = useStyles();

  return (
    <DraggableModal
      open={open}
      handleClose={() => setOpen(false)}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={() => setOpen(false)}
        title={<FormattedMessage id="product.edit" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <img src={WarningImage} alt="Warning" className={classes.img} />
        <FormattedMessage
          id="confirm.wantToRemoveTheUserConnected"
          values={{
            nameAndEmail: toRemoveUser ? toRemoveUser.label : "test",
            tabNumber:
              tabNumber !== null || tabNumber !== undefined ? tabNumber + 1 : 0,
          }}
        />
        <Box className={classes.modalFooter} align="right">
          <ClearButton onClick={handleNoButton} marginRight={15}>
            <FormattedMessage id="btn.no" />
          </ClearButton>
          <PrimaryButton onClick={handleConfirmButton}>
            <FormattedMessage id="btn.yes" />
          </PrimaryButton>
        </Box>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ConfirmRemoveRecipient.defaultProps = {};

ConfirmRemoveRecipient.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleConfirmButton: PropTypes.func.isRequired,
  handleNoButton: PropTypes.func.isRequired,
  toRemoveUser: PropTypes.shape({ label: PropTypes.string }).isRequired,
  tabNumber: PropTypes.number.isRequired,
};

export default ConfirmRemoveRecipient;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import Button from "@mui/material/Button";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useAlert } from "react-alert";
import { useSnackbar } from "../../../../../hooks";
import { UPDATE_RESOURCE_METADATA } from "../../../../../graphql/mutations";
import { GET_META_DATA } from "../../../../../graphql/queries";
import PageTabPanel from "../../../../common/PageTabPanel";
import { initialMetadataValues } from "../ProductInfo/helpers";
import Agency from "./Agency";
import Bureau from "./Bureau";
import Photograph from "./Photograph";
import Copywriter from "./Copywriter";
import Printshop from "./Printshop";
import Metadata from "./Metadata";
import Tags from "./Tags";
import CustomMetadata from "./CustomMetadata";
import { addMetadata, initMetadata } from "../../../../../actions";
import ProductResponsible from "./ProductResponsible";

const useStyles = makeStyles(theme => ({
  Accordion: {
    "& .MuiAccordionSummary-content p": {
      color: theme.palette.component.customPanelColor,
    },
    "& .MuiAccordionSummary-root": {
      backgroundColor: theme.palette.component.customPanelBackground,
    },
  },
  btnMain: {
    padding: "11px 22px",
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
    },
  },
  mobilePadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },
  },
}));

const initialValues = {
  afterTreatment: "",
  bureau: "",
  bureauContact: "",
  bureauInvoicedPrice: "",
  bureauOfferedPrice: "",
  bureauProjectName: "",
  copywriterInvoicedPrice: "",
  companyDeliveryDate: "",
  companyEnhet: "",
  companyManager: "",
  companyManagerEmail: "",
  companyOrderdate: "",
  copywriter: "",
  copywriterDescription: "",
  copywriterOfferedPrice: "",
  coverColor: "",
  insideColor: "",
  misc: "",
  pageRange: "",
  photograph: "",
  photographDescription: "",
  photographInvoicedPrice: "",
  photographOfferedPrice: "",
  printshop: "",
  printshopContact: "",
  printshopCoverPaper: "",
  printshopCoverPaperWeight: "",
  printshopDeliveryAddress: "",
  printShopFormatHeight: "",
  printshopFormatId: 0,
  printShopFormatWidth: "",
  printshopFreight: "",
  printshopInsidePaper: "",
  printshopInsidePaperWeight: "",
  printshopInvoicedPrice: "",
  printshopOfferedPrice: "",
  printshopOffertNo: "",
  printshopWeight: "",
  resourceId: null,
};

const CooperationInfo = ({
  setCooperationIsDraft,
  cooperationIsDraft,
  resourceId,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const { customMetadata } = useSelector(state => state.api);
  const [productaMetadata, setProductMetadata] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [metadataValues, setMetadataValues] = useState(initialMetadataValues);
  const [productResponsibleUser, setProductResponsibleUser] = useState({});
  const [compacompanyBestallareUser, setcompanyBestallareUser] = useState({});

  const [updateResourceMetaData] = useMutation(UPDATE_RESOURCE_METADATA);

  const [getMetaData, { data: metaDataData }] = useLazyQuery(GET_META_DATA);

  useEffect(() => {
    if (resourceId) {
      getMetaData({ variables: { resourceId } });
    }
  }, [resourceId]);

  useEffect(() => {
    if (
      metaDataData &&
      metaDataData.productEdit &&
      metaDataData.productEdit.getMetaData
    ) {
      setProductMetadata(metaDataData.productEdit.getMetaData);
    }
  }, [metaDataData]);

  useEffect(() => {
    if (productaMetadata) {
      const { resourceMeta } = productaMetadata;
      if (resourceMeta) {
        if (resourceMeta.productResponsibleLookupItem) {
          const {
            userId,
            firstname,
            lastname,
            username,
          } = resourceMeta.productResponsibleLookupItem;
          const tempUser = {
            label: `${firstname} ${lastname} (${username})`,
            value: userId,
          };
          setProductResponsibleUser(tempUser);
        }

        if (resourceMeta.companyBestallareLookupItem) {
          const {
            userId,
            firstname,
            lastname,
            username,
          } = resourceMeta.companyBestallareLookupItem;
          const tempUser = {
            label: `${firstname} ${lastname} (${username})`,
            value: userId,
          };
          setcompanyBestallareUser(tempUser);
        }

        setFormValues({ ...initialValues, ...resourceMeta });
      }
    }
  }, [productaMetadata]);

  const handleMetadataValues = e => {
    const { name, value } = e.target;

    setMetadataValues({ ...metadataValues, [name]: value });
  };

  const handleChange = e => {
    if (!cooperationIsDraft) {
      setCooperationIsDraft(true);
    }
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleIntChange = e => {
    if (!cooperationIsDraft) {
      setCooperationIsDraft(true);
    }
    const { name, value } = e.target;
    const newValue = parseInt(value, 10);
    setFormValues({ ...formValues, [name]: newValue });
  };

  const handleSelectResponsible = user => {
    if (!isEmpty(user)) {
      const { value, groupName } = user;

      setFormValues({
        ...formValues,
        companyBestallareLookupItem: {
          ...formValues.companyBestallareLookupItem,
          companyUserGroup: groupName,
        },
        companyBestallareUserId: value,
      });
    } else {
      setFormValues({
        ...formValues,
        companyBestallareLookupItem: null,
        companyBestallareUserId: null,
      });
    }
  };

  const handleSelectProductResponsible = user => {
    if (!isEmpty(user)) {
      const { value, groupName } = user;
      setFormValues({
        ...formValues,
        productResponsibleLookupItem: {
          ...formValues.productResponsibleLookupItem,
          companyUserGroup: groupName,
        },
        productResponsibleUserId: value,
      });
    } else {
      setFormValues({
        ...formValues,
        productResponsibleLookupItem: null,
        productResponsibleUserId: null,
      });
    }
  };

  const handleAddCustomMetadata = () => {
    const defaultValues = { display: true, label: "", value: "" };
    dispatch(addMetadata({ value: defaultValues }));
  };

  const handleSave = async () => {
    const resourceMetadata = { ...formValues, resourceId };
    delete resourceMetadata.companyBestallareLookupItem;
    delete resourceMetadata.productResponsibleLookupItem;
    delete resourceMetadata.productGroup;
    const metadata = { resourceMetadata };

    try {
      const res = await updateResourceMetaData({
        variables: {
          metadata,
        },
      });

      if (res && res.data && res.data.updateResourceMetaData) {
        alert.success(
          <FormattedMessage id="product.updatedResourceMatadata" />
        );
        if (cooperationIsDraft) {
          setCooperationIsDraft(false);
        }
      } else {
        snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
      }
    } catch (e) {
      console.log(`Error saving cooperation info ${JSON.stringify(e)}`);
      snackbar.workspaceError(<FormattedMessage id="common.errorOnSaving" />);
    }
  };

  return (
    <PageTabPanel spacing="3">
      {/*Left side */}
      <Grid item xs={12} md={6} className={classes.Accordion}>
        <ProductResponsible
          formValues={formValues}
          productResponsibleUser={productResponsibleUser}
          setProductResponsibleUser={setProductResponsibleUser}
          handleChange={handleChange}
          handleSelectProductResponsible={handleSelectProductResponsible}
        />
        <Agency
          formValues={formValues}
          compacompanyBestallareUser={compacompanyBestallareUser}
          setcompanyBestallareUser={setcompanyBestallareUser}
          handleChange={handleChange}
          handleSelectResponsible={handleSelectResponsible}
        />
        <Bureau formValues={formValues} handleChange={handleChange} />
        <Photograph formValues={formValues} handleChange={handleChange} />
      </Grid>
      {/*Right side */}
      <Grid item xs={12} md={6} className={classes.Accordion}>
        <Copywriter formValues={formValues} handleChange={handleChange} />
        <Printshop
          formValues={formValues}
          handleChange={handleChange}
          handleIntChange={handleIntChange}
        />
      </Grid>

      {/* <Grid item xs={12} md={6} className={classes.mobilePadding}>
        <Metadata
          metadataValues={metadataValues}
          handleMetadataValues={handleMetadataValues}
          setMetadataValues={setMetadataValues}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.mobilePadding}>
        <Tags tags={tags} setTags={setTags} />
        <CustomMetadata handleAddCustomMetadata={handleAddCustomMetadata} />
        <Copywriter formValues={formValues} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12} md={6} className={classes.mobilePadding}>
        <Agency
          formValues={formValues}
          handleChange={handleChange}
          handleSelectResponsible={handleSelectResponsible}
        />
        <Bureau formValues={formValues} handleChange={handleChange} />
        <Photograph formValues={formValues} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12} md={6} className={classes.mobilePadding}>
        <Copywriter formValues={formValues} handleChange={handleChange} />
        <Printshop
          formValues={formValues}
          handleChange={handleChange}
          handleIntChange={handleIntChange}
        />
      </Grid> */}

      <Grid item xs={12} className={classes.mobilePadding} align="right">
        <Button
          variant="contained"
          className={classes.btnMain}
          onClick={() => handleSave()}
        >
          <FormattedMessage id="btn.save" />
        </Button>
      </Grid>
    </PageTabPanel>
  );
};

CooperationInfo.defaultProps = {
  metaData: {},
  cooperationIsDraft: false,
  resourceId: null,
};

CooperationInfo.propTypes = {
  metaData: PropTypes.shape({
    resourceMeta: PropTypes.shape({}),
    imageMetadata: PropTypes.shape({}),
    tags: PropTypes.arrayOf(),
  }),
  setCooperationIsDraft: PropTypes.func.isRequired,
  cooperationIsDraft: PropTypes.bool,
  resourceId: PropTypes.string,
};

export default CooperationInfo;

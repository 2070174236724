import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Files from "./Files";
import Folders from "./Folders";
import Menus from "./Menus";
import DocumentCollaboration from "./DocumentCollaboration";

const FileSettings = ({
  colorSchemes,
  disabled,
  handleCheckbox,
  handleOpenColorSets,
  handleSelect,
  roleSubscriptionLevel,
  siteRoleValues,
}) => {
  return (
    <Grid container spacing={1}>
      <Folders
        colorSchemes={colorSchemes}
        disabled={disabled}
        handleCheckbox={handleCheckbox}
        handleOpenColorSets={handleOpenColorSets}
        handleSelect={handleSelect}
        roleSubscriptionLevel={roleSubscriptionLevel}
        siteRoleValues={siteRoleValues}
      />
      <DocumentCollaboration
        disabled={disabled}
        handleCheckbox={handleCheckbox}
        roleSubscriptionLevel={roleSubscriptionLevel}
        siteRoleValues={siteRoleValues}
      />

      <Menus
        disabled={disabled}
        handleCheckbox={handleCheckbox}
        roleSubscriptionLevel={roleSubscriptionLevel}
        siteRoleValues={siteRoleValues}
      />
    </Grid>
  );
};

FileSettings.defaultProps = {
  colorSchemes: [],
  disabled: false,
  siteRoleValues: {},
};

FileSettings.propTypes = {
  colorSchemes: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  handleCheckbox: PropTypes.func.isRequired,
  handleOpenColorSets: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  siteRoleValues: PropTypes.shape({}),
};

export default FileSettings;

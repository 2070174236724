import React from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { ButtonText } from "../../common";

const useStyles = makeStyles(theme =>
  createStyles({
    widgetFolderLink: {
      textDecoration: "none",
      textAlign: "left",
      fontSize: 12,
      color: theme.palette.primary.main,
      marginBottom: 8,
      display: "block",
    },
  })
);

const FolderItem = ({ folder, handleFolderClick }) => {
  const classes = useStyles();

  const onFolderClick = () => {
    handleFolderClick({ folder });
  };

  return (
    <Grid item xs={12} lg={3} key={folder.folderId}>
      <ButtonText
        onClick={onFolderClick}
        label={folder.name}
        btnClass={classes.widgetFolderLink}
      />
    </Grid>
  );
};

FolderItem.defaultProps = {};

FolderItem.propTypes = {
  folder: PropTypes.shape({
    folderId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  handleFolderClick: PropTypes.func.isRequired,
};

export default FolderItem;

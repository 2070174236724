import React from "react";
import { Separator } from "react-contexify";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ContextMenuItem from "./ContextMenuItem";

const RenderCrudItems = ({
  read,
  shareFolders,
  notifications,
  handleDisabled,
  handleDeleteFolder,
  handleCreateFolder,
  handleSetEditNode,
  directoryCRUD,
  write,
  locked,
}) => {
  const disabled = !(!locked && directoryCRUD && write);
  return (
    <>
      <ContextMenuItem
        disabled={disabled}
        onClick={() => (disabled ? handleDisabled() : handleCreateFolder())}
      >
        <FormattedMessage id="context.newFolder" />
      </ContextMenuItem>

      <ContextMenuItem
        disabled={disabled}
        onClick={() => (disabled ? handleDisabled() : handleSetEditNode(true))}
      >
        <FormattedMessage id="context.changeName" />
      </ContextMenuItem>

      <ContextMenuItem
        disabled={disabled}
        onClick={() => (disabled ? handleDisabled() : handleDeleteFolder())}
      >
        <FormattedMessage id="context.delete" />
      </ContextMenuItem>

      {(read || shareFolders || notifications) && <Separator />}
    </>
  );
};

RenderCrudItems.defaultProps = {
  locked: false,
};

RenderCrudItems.propTypes = {
  read: PropTypes.bool.isRequired,
  shareFolders: PropTypes.bool.isRequired,
  notifications: PropTypes.bool.isRequired,
  handleDeleteFolder: PropTypes.func.isRequired,
  handleCreateFolder: PropTypes.func.isRequired,
  handleSetEditNode: PropTypes.func.isRequired,
  directoryCRUD: PropTypes.bool.isRequired,
  write: PropTypes.bool.isRequired,
  locked: PropTypes.bool,
};

export default RenderCrudItems;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Icon, Typography, Button } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import AddressesTable from "./ReservationsTable";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #EEE",
    boxShadow: "0 2px 0 0 rgba(129,129,129,0.10)",
    borderRadius: 10,
  },
  head: {
    padding: "15px 20px",
    borderBottom: "2px solid #F5F5F5",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  headTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 20,
  },
  addLink: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: "auto",
    padding: 0,
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
      opacity: 0.8,
    },
  },
  addIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 12,
    marginRight: 8,
    marginTop: 3,
  },
  body: {
    padding: 20,
  },
  foot: {
    padding: "15px 20px",
    borderTop: "2px solid #F5F5F5",
  },
  btnSave: {
    padding: "10px 15px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.common.blue,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
      opacity: 0.8,
    },
  },
  btnIcon: {
    fontSize: 14,
    marginRight: 8,
    verticalAlign: "middle",
  },
}));

const Reservations = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        <Typography className={classes.headTitle}>Reservations</Typography>
        <Button className={classes.addLink}>
          <Icon className={clsx(["fas fa-plus", classes.addIcon])} />
          Add new reservation
        </Button>
      </Box>
      <Box className={classes.body}>
        <Grid container>
          <Grid item xs={12}>
            <AddressesTable />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.foot} align="right">
        <Button className={classes.btnSave}>
          <Icon className={clsx(["fa fa-save", classes.btnIcon])} />
          <FormattedMessage id="btn.saveChanges" />
        </Button>
      </Box>
    </Box>
  );
};

export default Reservations;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Paper, Grid } from "@mui/material";
import ImageListItem from "./ImageListItem";

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    borderRadius: 3,
    padding: 20,
    display: "block",
    color: theme.palette.component.productBoxColor,
  },
}));

const ImageList = ({
  colorModelOptions,
  fileFormatOptions,
  handleImageBasketItem,
  imageBasketItems,
  onDeleteItem,
  selectImageOption,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={3}>
        {imageBasketItems.map((i, idx) => (
          <ImageListItem
            key={i.itemId}
            colorModelOptions={colorModelOptions}
            fileFormatOptions={fileFormatOptions}
            handleImageBasketItem={handleImageBasketItem}
            idx={idx}
            imageBasketItem={i}
            onDeleteItem={onDeleteItem}
            selectImageOption={selectImageOption}
          />
        ))}
      </Grid>
    </Paper>
  );
};

ImageList.propTypes = {
  colorModelOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fileFormatOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleImageBasketItem: PropTypes.func.isRequired,
  imageBasketItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageName: PropTypes.string,
      itemId: PropTypes.number,
      productId: PropTypes.string,
      settings: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  selectImageOption: PropTypes.bool.isRequired,
};

export default ImageList;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Box, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  box: {
    alignItems: "center",
    zIndex: 1000,
  },
  actions: {
    marginLeft: "auto",
    marginTop: 8,
    zIndex: 1000,
  },
  btnSave: {
    margin: "0 4px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    margin: "0 4px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.text.lightGray,
    "&:hover": {
      backgroundColor: theme.palette.text.lightGray,
      opacity: 0.8,
    },
  },
  textField: {
    padding: "10px 12px",
  },
}));

const FormField = ({ handleCancel, handleSave, handleChange, value }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <TextField
        variant="outlined"
        onChange={handleChange}
        defaultValue={value || ""}
        autoFocus
        InputProps={{
          classes: { input: classes.textField },
        }}
      />
      <Box display="flex" className={classes.box}>
        <Box className={classes.actions}>
          <Button
            size="small"
            className={classes.btnCancel}
            onClick={handleCancel}
          >
            <FormattedMessage id="btn.cancel" />
          </Button>
          <Button size="small" className={classes.btnSave} onClick={handleSave}>
            <FormattedMessage id="btn.ok" />
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

FormField.defaultProps = {
  value: "",
};

FormField.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default FormField;

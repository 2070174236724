import { useEffect, useRef, useState } from "react";
import TextField from '@mui/material/TextField';

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const NumberValueEditor = ({
  disabled,
  enableEdit,
  field,
  inline,
  onUpdateTaskValue,
  size = "small",
  value,
}) => {
  const inputRef = useRef();

  const [internalValue, setInternalValue] = useState(value?.numericValue || "");
  const [isEditing, setIsEditing] = useState();
  const [selectionStart, setSelectionStart] = useState();

  const editable = enableEdit && !!onUpdateTaskValue;

  const getSanitizedInputValue = () => {
    const numericValue = value?.numericValue;
    if (Number.isFinite(numericValue)) {
      return numericValue.toString();
    }
    return "";
  };

  const checkIfDirty = () => {
    const numericValue = getSanitizedInputValue();
    return (internalValue !== numericValue);
  };

  const saveChanges = (e) => {
    if (checkIfDirty()) {
      setSelectionStart(e?.target?.selectionStart);
      if (internalValue == "") {
        onUpdateTaskValue(field.fieldId, null);
      }
      else {
        onUpdateTaskValue(field.fieldId, parseFloat(internalValue));
      }
    }
  };

  const startEditing = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    resetInitialValue();

    setIsEditing(true);
    setSelectionStart(e?.target?.selectionStart);
  };

  const stopEditing = () => {
    setIsEditing(false);
    inputRef.current?.setSelectionRange(selectionStart, selectionStart);
  };

  const resetInitialValue = () => {
    const numericValue = getSanitizedInputValue();
    setInternalValue(numericValue);
  };

  useEffect(() => {
    resetInitialValue();
  }, [field, value]);

  useEffect(() => {
    if (selectionStart && !checkIfDirty()) {
      inputRef.current?.setSelectionRange(selectionStart, selectionStart);
    }
  }, [value, internalValue]);

  if (isEditing) {

    return (
      <TextField
        autoFocus
        disabled={disabled}
        fullWidth
        inputProps={{
          style: {
            boxSizing: "content-box",
          },
        }}
        inputRef={inputRef}
        onBlur={
          (e) => {
            saveChanges();
            stopEditing();
          }
        }
        onChange={
          (e) => {
            const newValue = e.target.value;
            if (newValue === "" || newValue === "." || isNumeric(newValue)) {
              setInternalValue(newValue);
            }
          }
        }
        onKeyDown={
          (e) => {
            if (e.key === "Enter") {
              saveChanges(e);
            }
            else if (e.key === "Escape") {
              resetInitialValue();
            }
            else if (e.key === "e" || e.key === "E") {
              e.preventDefault();
            }
          }
        }
        size={size}
        sx={{
          marginTop: inline && "1px",
        }}
        value={internalValue}
      />
    );

  }
  else if (editable) {

    return (
      <TextField
        disabled={disabled}
        fullWidth
        inputProps={{
          style: {
            boxSizing: "content-box",
            paddingBottom: inline && "6px",
            paddingTop: inline && "6px",
          },
        }}
        onSelect={startEditing}
        size={size}
        sx={{
          "&:not(:hover) fieldset": {
            border: inline && "none",
          },
        }}
        value={internalValue}
      />
    );

  }
  else {

    return value?.numericValue;

  }

};

export default NumberValueEditor;

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Button } from "@mui/material";
import Description from "./Description";
import Image from "./Image";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  wrapper: {
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    width: "62.5%",
    display: "inline-flex",
    borderRadius: 0,
    "&:hover": {
      boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    },
    marginTop: 15,
    [theme.breakpoints.down("lg")]: {
      width: "90%",
      minWidth: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  wrapperEmpty: {
    marginBottom: theme.spacing(2),
    padding: "40px 0",
    boxShadow: "none",
    borderRadius: 0,
    textAlign: "center",
    backgroundColor: theme.palette.common.white,
  },
  emptyIcon: {
    verticalAlign: "middle",
    marginRight: 20,
    width: "60px !important",
    height: "60px !important",
    color: "#B8B8B8",
  },
  cover: {
    width: "15%",
    marginRight: 15,
    boxShadow: "none",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      minWidth: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingRight: 0,
      marginBottom: 20,
    },
    "& img": {
      border: "1px solid #dedede",
      boxShadow: "0 1px 5px #efefef",
    },
    "& img:hover": {
      boxShadow: "none",
    },
  },
  showHover: {
    display: "none",
    boxShadow: "none",
    verticalAlign: "bottom",
    color: theme.palette.component.productBoxColor,
  },
  showHoverActions: {
    display: "none",
    boxShadow: "none",
    backgroundColor: "transparent",
    marginTop: 10,
  },
  prod: {
    display: "inline-flex",
    boxShadow: "none",
    borderRadius: 0,
    padding: 15,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",
    },
    "&:hover": {
      "& $showHover": {
        display: "inline-block",
      },
      "& $showHoverActions": {
        display: "block",
      },
    },
  },
  prodDesc: {
    "&:hover": {
      cursor: "pointer",
    },
    "& div": {
      display: "inline-block",
    },
    color: theme.palette.component.productBoxColor,
    "& > span": {
      display: "none",
      color: theme.palette.common.link,
      fontSize: 11,
      marginBottom: 5,
    },
    "&:hover > span": {
      display: "block",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    color: theme.palette.component.productBoxColor,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 0,
    boxShadow: "none",
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: 0,
      marginBottom: 20,
    },
  },
  prodName: {
    fontSize: 14,
    fontWeight: 600,
  },
  prodInfo: {
    fontSize: 12,
    display: "block",
    "& span": {
      fontWeight: 600,
    },
  },
  popper: {
    position: "fixed !important",
    top: "110px !important",
    transform: "none !important",
    width: "39.5%",
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    maxWidth: "unset",
    minHeight: "87vh",
    maxHeight: "87vh",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "70%",
      objectFit: "contain",
      maxHeight: "75vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  popperRight: {
    position: "fixed !important",
    top: "165px !important",
    right: "60px !important",
    left: "auto !important",
    transform: "none !important",
    width: "67.5%",
  },
  tooltipRight: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    maxWidth: "unset",
    minHeight: "80vh",
    maxHeight: "80vh",
    textAlign: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "70%",
      objectFit: "contain",
      maxHeight: "75vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
    },
  },
  actionBtn: {
    padding: "4px 6px",
    "& .MuiIcon-root": {
      width: "unset",
      height: "unset",
      overflow: "unset",
      fontSize: 14,
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
    "&:hover": {
      "& .MuiIcon-root": {
        color: theme.palette.primary.alt,
        verticalAlign: "middle",
      },
    },
  },
  disableToolTip: {
    display: "none",
  },
  breadLink: {
    color: theme.palette.common.link,
    fontSize: 12,
    textDecoration: "none",
    padding: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btn: {
    width: "30%",
    padding: "5px 12px",
    fontWeight: 600,
    fontSize: 12,
    display: "inline-flex",
    alignSelf: "flex-end",
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "unset",
      padding: 15,
    },
  },
}));

const BookingRowItem = ({ lift, handleBooking }) => {
  const classes = useStyles();
  const {
    name,
    description,
    thumbnailImageUri,
    productImageUri,
    id,
    customMetadata,
  } = lift;
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.prod}>
        <Box className={classes.cover}>
          <Image img={thumbnailImageUri} hoverImg={productImageUri} />
        </Box>

        <Box className={classes.content}>
          <Typography className={classes.prodName}>{name}</Typography>
          <Box className={classes.prodDesc} elevation={0}>
            <Description description={description} />
          </Box>

          {Array.isArray(customMetadata) && customMetadata.length > 0 && (
            <>
              {customMetadata.map(x => {
                return (
                  <>
                    {x.display && (
                      <Typography className={classes.prodInfo}>
                        <span>{x.label}:</span> {x.value}
                      </Typography>
                    )}
                  </>
                );
              })}
            </>
          )}
          {/* 
          <Typography className={classes.prodInfo}>
            <span>
              <FormattedMessage id="product.height" />:
            </span>{" "}
            {height} m
          </Typography>

          <Typography className={classes.prodInfo}>
            <span>
              <FormattedMessage id="product.weight" />:
            </span>{" "}
            {weight} kg
          </Typography>

          <Typography className={classes.prodInfo}>
            <span>
              <FormattedMessage id="booking.dimension" />:
            </span>{" "}
            {dimension} m
          </Typography>

          <Typography className={classes.prodInfo}>
            <span>
              <FormattedMessage id="product.propulsion" />:
            </span>{" "}
            {drift}
          </Typography> */}
        </Box>
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleBooking(id)}
        >
          <FormattedMessage id="booking.bookNow" />
        </Button>
      </Box>
    </Box>
  );
};

BookingRowItem.defaultProps = {
  lift: null,
};

BookingRowItem.propTypes = {
  handleBooking: PropTypes.func.isRequired,
  lift: PropTypes.shape({
    name: PropTypes.string,
    height: PropTypes.string,
    drift: PropTypes.string,
    weight: PropTypes.string,
    dimension: PropTypes.string,
    description: PropTypes.string,
    thumbnailImageUri: PropTypes.string,
    productImageUri: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default BookingRowItem;

import { gql } from '@apollo/client';

const getSimpleSearch = gql`
  query getSimpleSearch($searchText: String, $siteId: Int!) {
    search {
      simpleSearch(searchText: $searchText, siteId: $siteId) {
        itemIds
        sites {
          folders {
            folderId
            folderName
          }
          siteId
          siteName
        }
        tags {
          tagId
          tagName
        }
        viewSelectorSettings {
          fileView
          productView
        }
      }
    }
  }
`;

export default getSimpleSearch;

import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { Box, Card, CardMedia, DialogContent } from '@mui/material';

import YouTube from "react-youtube";
import { pdfjs, Document, Page, Outline, Thumbnail } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ModalCompactHead, ModalCompact } from '../../common';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/*pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();*/

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const ShowContenDialog = ({ onClose, selectedValue, open }) => {

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onReady = e => {
    e.target.pauseVideo();
  };

  const opts = {
    playerVars: {
      cc_load_policy: 1,
      cc_lang_pref: "sv",      
    },
    width: "100%",
  };


  const [file, setFile] = useState(selectedValue.pdfUrl);
  const [numPages, setNumPages] = useState();
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  //useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onFileChange(event) {
    const { files } = event.target;

    if (files && files[0]) {
      setFile(files[0] || null);
    }
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <ModalCompact onClose={handleClose} open={open} fullScreen>
      <ModalCompactHead handleClose={onClose} title={selectedValue.title}></ModalCompactHead>
      <DialogContent sx={{ backgroundColor:"#f4f3ed" }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {selectedValue && selectedValue.videoUrl && (
          <Card>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/ffdjeYkdXKE?si=mL5wxKHyVEiGeMHA" title={selectedValue.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              
          </Card>
        )}


          {
            selectedValue && selectedValue.pdfUrl && (
              <Box ref={setContainerRef}>
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                  {Array.from(new Array(numPages), (el, index) => (

                    <Box sx={{margin:1}}>
                  <Page
                    
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={1024}
                    scale={1}
                  /></Box>
                ))}


              </Document>
            </Box>
            
            )
        }
        </Box>

      </DialogContent>
    </ModalCompact>
  );
}

export default ShowContenDialog;

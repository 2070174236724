import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import CartTable from "../CartTable";
import EmptyCartButton from "../EmptyCartButton";
import UpdateSelectedRows from "../CartTable/UpdateSelectedRows";
import UpdateRowsModal from "../UpdateRowsModal";

const useStyles = makeStyles(theme => ({
  pageTitle: {
    color: theme.palette.component.productBoxColor,
    fontWeight: 600,
    fontSize: 16,
    marginTop: 0,
    display: "inline-block",
  },
  productWrap: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(3),
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
}));

const CartTableContainer = ({
  handleConfirmationModalOpen,
  cartItems,
  fetchCart,
  loadingFetchCart,
  fileGroups,
  oneStreamGroups,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [showUpdateSuppliers, setShowUpdateSuppliers] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    let hasSuppliers = false;
    const hasFileGroups =
      Array.isArray(fileGroups) && fileGroups.length > 0 ? true : false;
    const hasOrderGroups =
      Array.isArray(oneStreamGroups) && oneStreamGroups.length > 0
        ? true
        : false;
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      hasSuppliers = cartItems.some(
        x =>
          Array.isArray(x.selectableSuppliers) &&
          x.selectableSuppliers.length > 1
      );
    }

    if (hasSuppliers || hasFileGroups || hasOrderGroups) {
      setShowUpdateSuppliers(true);
    } else {
      setShowUpdateSuppliers(false);
    }
  }, [cartItems, fileGroups, oneStreamGroups]);

  return (
    <Box className={classes.productWrap}>
      <h5 className={classes.pageTitle}>
        <FormattedMessage id="cart.products" />
      </h5>
      {showUpdateSuppliers && <UpdateSelectedRows handleOpen={handleOpen} />}
      <EmptyCartButton
        handleConfirmationModalOpen={handleConfirmationModalOpen}
      />
      <CartTable
        cartItems={cartItems}
        fetchCart={fetchCart}
        loadingFetchCart={loadingFetchCart}
        fileGroups={fileGroups}
        oneStreamGroups={oneStreamGroups}
        selectedItemIds={selectedItemIds}
        setSelectedItemIds={setSelectedItemIds}
        showUpdateSuppliers={showUpdateSuppliers}
      />
      {open && (
        <UpdateRowsModal
          open={open}
          handleClose={handleClose}
          selectedItemIds={selectedItemIds}
        />
      )}
    </Box>
  );
};

CartTableContainer.defaultProps = {
  oneStreamGroups: [],
  fileGroups: [],
  loadingFetchCart: false,
};

CartTableContainer.propTypes = {
  handleConfirmationModalOpen: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired,
  loadingFetchCart: PropTypes.bool,
  oneStreamGroups: PropTypes.arrayOf(
    PropTypes.shape({
      packageId: PropTypes.string,
      packageName: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  fileGroups: PropTypes.arrayOf(
    PropTypes.shape({
      folderId: PropTypes.number,
      packageId: PropTypes.string,
      packageName: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(CartTableContainer);

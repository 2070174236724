import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import ManageRolesMenu from "./ManageRolesMenu";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 25,
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    "& tr:last-child td": {
      borderBottom: "none",
    },
  },
  td: {
    padding: "4px 0",
    fontSize: 11,
  },
  tdCb: {
    padding: "4px 0",
    textAlign: "right",
    paddingRight: "0 !important",
    minWidth: 16,
    "& .MuiCheckbox-root": {
      padding: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "16px !important",
      height: "16px !important",
    },
    "& .MuiSelect-select": {
      padding: "4px 6px",
      paddingRight: 16,
      fontSize: 11,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSelect-iconOutlined": {
      right: 1,
      top: 5,
    },
    "& .MuiFormControl-fullWidth": {
      width: "auto",
    },
  },
  editIcon: {
    fontSize: 10,
  },
  editButton: {
    color: theme.palette.common.link,
    padding: "4px !important",
    marginLeft: 4,
  },
}));

const Modules = ({
  disabled,
  handleOpenEditFolderRights,
  handleOpenEditMenus,
  handleOpenMenu,
  settingValues,
  siteRoleValues,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const headers = [
    {
      label: intl.formatMessage({
        id: "siteRoles.workspaceSettings",
      }),
      name: "workSpaceSettings",
      checkbox: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.orderingModule",
      }),
      name: "orderingModule",
      checkbox: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.monitoring",
      }),
      name: "monitoring",
      checkbox: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.bookingModule",
      }),
      name: "booking",
      checkbox: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.documentCollaboration",
      }),
      name: "documentCollaboration",
      checkbox: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.messages",
      }),
      name: "messages",
      checkbox: true,
      disable: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.newsPost",
      }),
      name: "newsPost",
      checkbox: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.onlineDesigner",
      }),
      name: "onlineDesigner",
      checkbox: true,
      disable: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.publications",
      }),
      name: "publications",
      checkbox: true,
      disable: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.projectManagement",
      }),
      name: "projectManagement",
      checkbox: true,
      disable: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.procurement",
      }),
      name: "procurement",
      checkbox: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.oneStream",
      }),
      name: "oneStream",
      checkbox: true,
      disable: true,
    },
    {
      label: intl.formatMessage({
        id: "siteRoles.issueManagement",
      }),
      name: "issueManagement",
      checkbox: true,
      disable: true,
    },
    {
      label: intl.formatMessage({ id: "siteRoles.manageConsent" }),
      name: "manageConsent",
      checkbox: true

    },
  ];

  return (
    <Box className={classes.root}>
      <Typography className={classes.text}>
        <FormattedMessage id="siteRoles.modules" />
      </Typography>
      <Divider className={classes.divider} />
      <Table className={classes.table} size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.td}>
              <FormattedMessage id="siteRoles.editMenus" />
            </TableCell>
            <TableCell className={classes.tdCb}>
              <IconButton
                className={classes.editButton}
                disabled={disabled}
                onClick={handleOpenEditMenus}
              >
                <Icon
                  className={clsx(["fa fa-pencil-alt", classes.editIcon])}
                />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.td}>
              <FormattedMessage id="siteRoles.folderRights" />
            </TableCell>
            <TableCell className={classes.tdCb}>
              <IconButton
                className={classes.editButton}
                disabled={disabled}
                onClick={handleOpenEditFolderRights}
              >
                <Icon
                  className={clsx(["fa fa-pencil-alt", classes.editIcon])}
                />
              </IconButton>
            </TableCell>
          </TableRow>
          {headers.map(head => {
            return (
              <ManageRolesMenu
                checkbox={head.checkbox}
                disabled={head.disable ? head.disable : disabled}
                key={head.name}
                label={head.label}
                name={head.name}
                onChange={handleOpenMenu}
                settingValues={settingValues}
                siteRoleValues={siteRoleValues}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

Modules.defaultProps = {
  disabled: false,
  siteRoleValues: {},
};

Modules.propTypes = {
  disabled: PropTypes.bool,
  handleOpenEditFolderRights: PropTypes.func.isRequired,
  handleOpenEditMenus: PropTypes.func.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
  siteRoleValues: PropTypes.shape({}),
};

export default Modules;

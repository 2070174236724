import { gql } from '@apollo/client';

const getImageBasket = gql`
  query getImageBasket($orderGroupId: Int!) {
    imageBank(ordergroupId: $orderGroupId) {
      orderGroupId
      imageDownloadOptions {
        id
        imageDownloadOptions {
          availableColorModes
          availableFileFormats
          dPI
          height
          id
          name
          width
        }
        name
      }
      items {
        imageName
        itemId
        productId
        settings {
          selectedColorMode
          selectedFileFormat
          settingId
        }
      }
    }
  }
`;

export { getImageBasket };

import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import { Icon } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  noSiteText: {
    fontSize: 12,
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    textAlign: "center",
    backgroundColor: theme.palette.background.contrastGray,
  },
  noSiteIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    verticalAlign: "middle",
    fontSize: 14,
    marginRight: 10,
    color: theme.palette.text.lightGray,
  },
}));

const NoViewPermission = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.noSiteText}>
      <Icon
        className={clsx(["fa fa-exclamation-circle", classes.noSiteIcon])}
      />
      <FormattedMessage id="product.youHaveNoAccess" />
    </Typography>
  );
};

export default NoViewPermission;

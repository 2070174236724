import React from "react";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { QuantityField } from "../../common";

const useStyles = makeStyles(theme => ({
  qtyLabel: {
    margin: 0,
    fontSize: "0.8571em",
    marginBottom: 5,
    color: "#9A9A9A",
  },
  input: {
    display: "flex",
    boxShadow: "none",
    marginBottom: theme.spacing(1),
    "& input": {
      width: "100%",
      padding: ".375rem .75rem",
      border: "1px solid #DDDDDD",
      borderRadius: 4,
      color: "#66615b",
      lineHeight: "normal",
    },
    "& > .MuiBox-root": {
      width: "100%",
    },
  },
}));

const ProductQuantity = ({
  valuesList,
  selectionType,
  quantity,
  onChange,
  onClick,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <>
      <p className={classes.qtyLabel}>
        <FormattedMessage id="product.quantity" />
      </p>
      <Card className={classes.input}>
        <QuantityField
          valuesList={valuesList}
          selectionType={selectionType}
          quantity={quantity}
          onChange={onChange}
          onClick={onClick}
          defaultValue={defaultValue}
        />
      </Card>
    </>
  );
};

ProductQuantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  valuesList: PropTypes.arrayOf(PropTypes.number).isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  selectionType: PropTypes.string.isRequired,
};

export default ProductQuantity;

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import ChatIcon from "@mui/icons-material/Chat";
import PublicIcon from "@mui/icons-material/Public";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RowItem from "./RowItem";
import FaIcon from "./FaIcon";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 18,
    color: "rgba(0,0,0,0.42)",
  },
  actionIcon: {
    fontSize: 18,
    color: "rgba(0,0,0,1)",
  },
}));
const plusIcon = "far fa-plus-square";

const UserInformationColumn = ({
  handleOpenPrivacySetting,
  accountSettings,
  setAccountSettings,
  visibility,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showInputField, setShowInputField] = useState(null);
  const [value, setValue] = useState(null);

  const {
    companyUserGroup,
    company,
    directMessage,
    email,
    mainEducation,
    mobile,
    phone,
    visibilitySettings = {},
  } = accountSettings || {};

  const handleAdd = name => {
    setShowInputField(name);
  };

  const handleCancel = () => {
    setShowInputField(null);
  };

  const handleSave = (actionType, editValue, index = null) => {
    setAccountSettings({
      ...accountSettings,
      [showInputField]: value || editValue,
    });

    setShowInputField(null);
    setValue(null);
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <Fragment>
      <RowItem
        title={company}
        subTitle={intl.formatMessage({ id: "settings.theUsersCompany" })}
        leftIcon={<FaIcon inputName="company" icon="fas fa-briefcase" />}
        privacyIcon={<PublicIcon />}
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        onClickActionIcon="company"
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="company"
        settingKey="company"
        iconLabel="company"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        privacyIconDisable={false}
        actionIconDisable={false}
        visibility={visibility}
        setShowInputField={setShowInputField}
      />
      <RowItem
        title={companyUserGroup}
        subTitle={intl.formatMessage({ id: "settings.companyUserGroup" })}
        leftIcon={<FaIcon inputName="company" icon="fas fa-sitemap" />}
        privacyIcon={<PublicIcon />}
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        onClickActionIcon="companyUserGroup"
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="companyUserGroup"
        settingKey="companyUserGroup"
        iconLabel="companyUserGroup"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        hideActionButtons
        visibility={visibility}
        setShowInputField={setShowInputField}
      />
      <RowItem
        title={email}
        subTitle={intl.formatMessage({ id: "settings.email" })}
        leftIcon={<FaIcon inputName="email" icon="far fa-envelope" />}
        privacyIcon={<PublicIcon />}
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        onClickActionIcon="email"
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="email"
        settingKey="email"
        iconLabel="Email"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        hideActionButtons        
        visibility={visibility}
        setShowInputField={setShowInputField}
      />
      <RowItem
        title={mainEducation}
        subTitle={intl.formatMessage({ id: "settings.professionalDegrees" })}
        leftIcon={
          <FaIcon
            handleAdd={handleAdd}
            inputName="mainEducation"
            icon={mainEducation ? "fas fa-graduation-cap" : plusIcon}
          />
        }
        privacyIcon={
          visibilitySettings.mainEducation === "PUBLIC" ? (
            <PublicIcon />
          ) : (
            <FaIcon icon="fas fa-lock" />
          )
        }
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        onClickActionIcon="mainEducation"
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="mainEducation"
        settingKey="mainEducation"
        iconLabel="school/university"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        visibility={visibility}
        setShowInputField={setShowInputField}
      />
      <RowItem
        title={phone}
        subTitle={intl.formatMessage({ id: "settings.phone" })}
        leftIcon={
          <FaIcon
            handleAdd={handleAdd}
            inputName="phone"
            icon={phone ? "fas fa-phone" : plusIcon}
          />
        }
        privacyIcon={
          visibilitySettings.phone === "PUBLIC" ? (
            <PublicIcon />
          ) : (
            <FaIcon icon="fas fa-lock" />
          )
        }
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        onClickActionIcon="phone"
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="phone"
        settingKey="phone"
        iconLabel="Phone"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        visibility={visibility}
        setShowInputField={setShowInputField}
      />
      <RowItem
        title={mobile}
        subTitle={intl.formatMessage({ id: "settings.mobile" })}
        leftIcon={
          <FaIcon
            handleAdd={handleAdd}
            inputName="mobile"
            icon={mobile ? "fas fa-phone" : plusIcon}
          />
        }
        privacyIcon={
          visibilitySettings.mobile === "PUBLIC" ? (
            <PublicIcon />
          ) : (
            <FaIcon icon="fas fa-lock" />
          )
        }
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        onClickActionIcon="mobile"
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="mobile"
        settingKey="mobile"
        iconLabel="Mobile"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        visibility={visibility}
        setShowInputField={setShowInputField}
      />
      <RowItem
        title={directMessage}
        subTitle={intl.formatMessage({ id: "settings.instantMessage" })}
        leftIcon={
          directMessage ? (
            <ChatIcon className={classes.icon} />
          ) : (
            <FaIcon
              handleAdd={handleAdd}
              inputName="directMessage"
              icon={plusIcon}
            />
          )
        }
        privacyIcon={
          visibilitySettings.directMessage === "PUBLIC" ? (
            <PublicIcon />
          ) : (
            <FaIcon icon="fas fa-lock" />
          )
        }
        actionIcon={<MoreHorizIcon className={classes.actionIcon} />}
        onClickActionIcon="directMessage"
        handleOpenPrivacySetting={handleOpenPrivacySetting}
        name="directMessage"
        settingKey="directMessage"
        iconLabel="Direct message"
        showInputField={showInputField}
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleChange={handleChange}
        visibility={visibility}
        setShowInputField={setShowInputField}
      />
    </Fragment>
  );
};

UserInformationColumn.defaultProps = {
  accountSettings: {},
  visibilitySettings: {},
};

UserInformationColumn.propTypes = {
  handleOpenPrivacySetting: PropTypes.func.isRequired,
  accountSettings: PropTypes.shape({
    companyUserGroup: PropTypes.string,
    company: PropTypes.string,
    mainEducation: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    directMessage: PropTypes.string,
  }),
  visibilitySettings: PropTypes.shape({
    company: PropTypes.string,
    mainEducation: PropTypes.string,
    phone: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    directMessage: PropTypes.string,
  }),
  visibility: PropTypes.string.isRequired,
};

export default UserInformationColumn;

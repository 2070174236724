import React from "react";
import { FormattedMessage } from "react-intl";

export const ALL = "ALL_FILES_IN_FOLDER_AND_SUB_FOLDERS";
export const SELECTED = "SELECTED_FILES_IN_FOLDER";

export const PRINT_AND_MAIL = "PRINT_AND_MAIL";

export const notificationActionTypes = [
  { label: <FormattedMessage id="monitoring.how.email" />, value: "EMAIL" },
  {
    label: <FormattedMessage id="monitoring.how.printAndMail" />,
    value: "PRINT_AND_MAIL",
  },
  // {
  //   label: <FormattedMessage id="monitoring.how.systemNotification" />,
  //   value: "SYSTEM_NOTIFICATION",
  // },
];

export const notificationOccasionTypes = [
  { label: <FormattedMessage id="monitoring.when.daily" />, value: "DAILY" },
  {
    label: <FormattedMessage id="monitoring.when.immediately" />,
    value: "IMMEDIATELY",
  },
  { label: <FormattedMessage id="monitoring.when.weekly" />, value: "WEEKLY" },
];

export const productTypes = [
  { label: "Ritning", value: 1 },
  { label: "Standardinställning", value: 2 },
];
export const formatTypes = [
  { label: "Enligt fil", value: 1 },
  { label: "A4", value: 2 },
];
export const colorModeTypes = [
  { label: "Enligt fil", value: 1 },
  { label: "Färg", value: 2 },
];
export const printTypes = [
  { label: "Enkelsidigt", value: 1 },
  { label: "Dubbelsidigt", value: 2 },
];
export const paperTypes = [
  { label: "Enligt disciplin", value: 1 },
  { label: "Vitt", value: 2 },
];
export const handlingTypes = [
  { label: "Vikt", value: 1 },
  { label: "Inplastning", value: 2 },
];

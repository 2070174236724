import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import CustomSelect from "../../../../../common/CustomSelect";

const useStyles = makeStyles(theme => ({
  box: {
    boxShadow: "0 3px 10px 0 rgba(0,0,0,0.01)",
    borderRadius: 4,
    padding: "10px 15px",
    backgroundColor: theme.palette.common.white,
    "& $panelTitle": {
      marginBottom: 10,
      marginRight: 0,
    },
  },
  panelHead: {
    display: "flex",
    alignItems: "center",
  },
  panelTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.component.productBoxColor,
    marginRight: 10,
  },
}));

const PricePanelHeader = ({
  setSelectedOption,
  selectedOption,
  priceOptions,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} className={classes.panelHead}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={classes.box}>
            <CustomSelect
              inputLabel={<FormattedMessage id="product.priceModelTitle" />}
              onChange={e => setSelectedOption(e.target.value)}
              options={priceOptions}
              value={selectedOption}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

PricePanelHeader.defaultProps = {
  selectedOption: null,
};

PricePanelHeader.propTypes = {
  setSelectedOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.number,
  priceOptions: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
};

export default PricePanelHeader;

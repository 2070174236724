import { gql } from '@apollo/client';
import FolderFieldsFragment from "../fragments/FolderFields";
import FolderRecursiveFragment from "../fragments/FolderRecursive";

const foldersForDynamicTemplateField = gql`
  query FoldersForDynamicTemplateField($productId: ID!, $imageFolderid: Int!) {
    foldersForDynamicTemplateField(
      productId: $productId
      imageFolderid: $imageFolderid
    ) {
      ...FolderFields
      ...FolderRecursive
    }
  }
  ${FolderFieldsFragment}
  ${FolderRecursiveFragment}
`;

export default foldersForDynamicTemplateField;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import clsx from "clsx";
import { useIntl } from "react-intl";
import {
  Box,
  Icon,
  Tabs,
  AppBar,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import TabPanel from "../../../TabPanel";
import PageTabs from "../../../PageTabs";
import { geta11yProps } from "../../../../helpers/get";

import WorkspaceSettings from "./WorkspaceSettings";
import ViewSettings from "./ViewSettings";
import PopUpMessage from "./PopUpMessage";
import FolderProductSettings from "./FolderProductSettings";
import MonitorSettings from "./MonitorSettings";
import WorkspaceUsers from "./WorkspaceUsers";

import { GET_WORKSPACE_DETAILS } from "../../../../graphql/queries/CompanyAdminContext";

const useStyles = makeStyles(theme => ({
  modal: {
    width: 1200,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: 0,
  },
  root: {
    flexGrow: 1,
    position: "relative",
  },
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
    "& .fa": {
      fontSize: 12,
      marginLeft: 7,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  hasError: {
    backgroundColor: "#ffa7a7",
    opacity: 1,
    boxShadow: "inset 0px 0px 0px 2px #bb1335",
    "& .fa": {
      display: "inline-block !important",
      fontSize: 12,
      marginLeft: 7,
      color: "#b72b2b",
    },
    "& $indicator": {
      backgroundColor: "#bb1335 !important",
    },
  },
  label: {
    fontSize: 10,
  },
  draftIcon: {
    color: "#b72b2b",
  },
  loadingOverlay: {
    background: "#000",
    opacity: 0.5,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  loadingIcon: {
    color: "#fff",
  },
}));

const EditSiteModal = ({
  handleClose,
  networkSelectorItems,
  open,
  refresh,
  siteId,
  workspaceOwnerCandidates,
  workspaceOrderGroupCandidates,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [getWorkspaceDetails, { data: workspaceData, loading }] = useLazyQuery(
    GET_WORKSPACE_DETAILS
  );

  const [value, setValue] = useState(0);

  const [workspaceDetails, setWorkspaceDetails] = useState({});
  const [draftTabs, setDraftTabs] = useState({
    workspaceSettings: false,
    viewSettings: false,
    popUpMessage: false,
    folderProductSettings: false,
    monitorSettings: false,
  });

  const handleSetDraft = (name, draft) => {
    setDraftTabs({ ...draftTabs, [name]: draft });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (siteId) {
      getWorkspaceDetails({ variables: { siteId } });
    }
  }, [siteId]);

  useEffect(() => {
    if (
      workspaceData &&
      workspaceData.companyAdminContext &&
      workspaceData.companyAdminContext.companyWorkspaces &&
      workspaceData.companyAdminContext.companyWorkspaces.getWorkspaceDetails
    ) {
      const {
        details,
      } = workspaceData.companyAdminContext.companyWorkspaces.getWorkspaceDetails;

      setWorkspaceDetails(details);
    }
  }, [workspaceData]);

  const renderLabel = (label, draft, error) => {
    const errorIcon = error && <Icon className="fa fa-exclamation-circle" />;
    const draftIcon = draft && (
      <Icon className={clsx(["fa fa-edit", classes.draftIcon])} />
    );

    return (
      <>
        <Typography className={classes.label}>
          {intl.formatMessage({ id: label })}
        </Typography>
        {draftIcon}
        {errorIcon}
      </>
    );
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title={workspaceDetails?.name || "" }></DraggableModalHead>
      <DraggableModalBody customClass={classes.modalBody}>
        {loading && (
          <Grid container alignItems="center" justifyContent="center" sx={{minHeight: 60}}>
            <CircularProgress size={30} />
          </Grid>
        )}
        {!loading && (
          <Box>
            <AppBar position="static" elevation="0" className={classes.wrap}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                classes={{
                  indicator: classes.indicator,
                }}
                className={classes.tabsRoot}
              >
                <PageTabs
                  className={classes.pageTabs}
                  label={renderLabel(
                    "siteRoles.workspaceSettings",
                    draftTabs.workspaceSettings
                  )}
                  {...geta11yProps(0)}
                  value={0}
                />
                <PageTabs
                  className={classes.pageTabs}
                  label={renderLabel(
                    "sites.viewSettings",
                    draftTabs.viewSettings
                  )}
                  {...geta11yProps(1)}
                  value={1}
                />
                              {/*<PageTabs
                  className={classes.pageTabs}
                  label={renderLabel(
                    "sites.popUpMessage",
                    draftTabs.popUpMessage
                  )}
                  {...geta11yProps(2)}
                  value={2}
                />*/}
                <PageTabs
                  className={classes.pageTabs}
                  label={renderLabel(
                    "sites.folderProductSettings",
                    draftTabs.folderProductSettings
                  )}
                  {...geta11yProps(3)}
                  value={3-1}
                />
                <PageTabs
                  className={classes.pageTabs}
                  label={renderLabel(
                    "sites.monitorSettings",
                    draftTabs.monitorSettings
                  )}
                  {...geta11yProps(4)}
                  value={4-1}
                />
                <PageTabs
                  className={classes.pageTabs}
                  label={renderLabel("pageKey.SITE_USERS")}
                  {...geta11yProps(4)}
                  value={5-1}
                />
              </Tabs>
            </AppBar>
            {[
              <WorkspaceSettings
                handleClose={handleClose}
                handleSetDraft={handleSetDraft}
                networkSelectorItems={networkSelectorItems}
                refresh={refresh}
                workspaceDetails={workspaceDetails}
                workspaceOrderGroupCandidates={workspaceOrderGroupCandidates}
                workspaceOwnerCandidates={workspaceOwnerCandidates}
              />,
              <ViewSettings handleSetDraft={handleSetDraft} />,              
              <FolderProductSettings handleSetDraft={handleSetDraft} />,
              <MonitorSettings handleSetDraft={handleSetDraft} />,
              <WorkspaceUsers siteId={siteId} />,
            ].map((x, index) => {
              return (
                <>
                  <TabPanel
                    value={value}
                    className={classes.tabPanel}
                    index={index}
                  >
                    {x}
                  </TabPanel>
                </>
              );
            })}
          </Box>
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

EditSiteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.bool.isRequired,
};

export default EditSiteModal;

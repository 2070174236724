import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import TabPanel from "../TabPanel";
import PageTabs from "../PageTabs";
import { geta11yProps } from "../../helpers/get";
import UserInfoTab from "./UserInfoTab";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "900px",
    backgroundColor: theme.palette.background.cream,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "95%",
    },
  },
  wrap: {
    backgroundColor: theme.palette.component.tabWrap,
    "& .MuiIcon-root": {
      display: "none",
    },
  },
  indicator: {
    backgroundColor: theme.palette.component.tabBackground,
  },
  tabPanel: {
    backgroundColor: "#fff",
  },
  tabsRoot: {
    minHeight: "unset",
    "& svg": {
      display: "none",
    },
  },
  pageTabs: {
    minHeight: "unset",
    "& .MuiTab-wrapper": {
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
}));

const EditUserModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="context.editUser" />}
      />
      <DraggableModalBody>
        <AppBar position="static" elevation="0" className={classes.wrap}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Edit User"
            classes={{
              indicator: classes.indicator,
            }}
            className={classes.tabsRoot}
          >
            <PageTabs
              className={classes.pageTabs}
              label="Användaruppgifter"
              {...geta11yProps(0)}
            />
          </Tabs>
        </AppBar>
        {[<UserInfoTab />].map((x, index) => (
          <TabPanel value={value} className={classes.tabPanel} index={index}>
            {x}
          </TabPanel>
        ))}
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default EditUserModal;

import { gql } from '@apollo/client';

const FILE_DOWNLOAD = gql`
  mutation SaveDownload($productId: ID!, $filename: String) {
    dynamicTemplateEditContext {
      initFileDownload(productId: $productId, filename: $filename)
    }
  }
`;

export default FILE_DOWNLOAD;

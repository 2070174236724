import { gql } from '@apollo/client';

const PageInfo = gql`
  fragment PageInfoProperties on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

export default PageInfo;

import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Slider,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { currentViewer } from "../../../../actions";
import { CREATE_AVATAR } from "../../../../graphql/mutations";
import { useSnackbar } from "../../../../hooks";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "768px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    "& .MuiDialogContent-root": {
      paddingTop: 20,
    }
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  slider: {
    width: 215,
  },
  uploadButton: {
    textTransform: "none",
    fontSize: 10,
    borderRadius: 3,
    padding: "5px 15px",
    backgroundColor: "#66615B",
    color: "#fff",
    minWidth: 196,
  },
  selectedButton: {
    outlineColor: theme.palette.primary.main,
    outlineOffset: 0,
    outlineStyle: "solid",
    outlineWidth: "3px",
  },
  userAvatar: {
    width: 200,
    height: 200,
    borderWidth: 7,
    borderColor: "#fff",
    borderStyle: "solid",
    marginRight: 28,
    marginTop: "-4px",
  },
  btnSave: {
    marginLeft: "auto",
    marginTop: 32,
    marginRight: 30,
    minWidth: 196,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
}));

const AvatarUpload = ({
  defaultAvatar,
  gravatarUrl,
  setAvatarProfile,
  setOpenUpload,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const { api } = useSelector(state => state);
  const { avatar: initialAvatar } = api.currentViewer.viewer;
  const [editor, setEditor] = useState(null);
  const [editorImage, setEditorImage] = useState(null);
  const [editorPosition, setEditorPosition] = useState({ x: 0.5, y: 0.5 });
  const [editorScale, setEditorScale] = useState(1);
  const [isAllowedToSave, setIsAllowedToSave] = useState(false);
  const [isGravatarView, setIsGravatarView] = useState(false);
  const [isInitialView, setIsInitialView] = useState(true);
  const [isNoAvatarView, setIsNoAvatarView] = useState(false);
  const [isUploadView, setIsUploadView] = useState(false);
  const [previousEditorImage, setPreviousEditorImage] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [uploadAvatar] = useMutation(CREATE_AVATAR);

  const displayErrorMessage = textId => {
    snackbar.workspaceError(<FormattedMessage id={textId} />);
    setShowBackDrop(false);
  };

  const displayGenericErrorMessage = () => {
    displayErrorMessage("common.genericErrorMessage");
  };

  const displayInvalidImageErrorMessage = () => {
    displayErrorMessage("avatarUpload.error.invalidImage");
  };

  const handleModalClose = () => setOpenUpload(false);

  const onEditorImageLoadFailure = event => {
    restorePreviousEditorImage();
    setIsAllowedToSave(editorImage !== null);
    displayInvalidImageErrorMessage();
  };

  const onEditorImageLoadSuccess = imgInfo => {
    removePreviousEditorImage();
    setIsAllowedToSave(editorImage !== null);
  };

  const onEditorPositionChange = position => {
    setEditorPosition(position);
  };

  const onEditorScaleChange = (event, newValue) => {
    setEditorScale(newValue);
  };

  const onGravatarClick = () => {
    setEditor(null);
    setShowSlider(false);
    setIsAllowedToSave(true);
    setIsGravatarView(true);
    setIsInitialView(false);
    setIsNoAvatarView(false);
    setIsUploadView(false);
  };

  const onImageFileSelected = event => {
    if (event.target.files.length > 0) {
      setPreviousEditorImage(editorImage);
      setEditorImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onNoAvatarClick = () => {
    setEditor(null);
    setShowSlider(false);
    setIsAllowedToSave(true);
    setIsGravatarView(false);
    setIsInitialView(false);
    setIsNoAvatarView(true);
    setIsUploadView(false);
  };

  const onSaveClick = async () => {
    setShowBackDrop(true);

    if (isUploadView) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      updateAvatarSettings("SYSTEM", croppedImg);
    } else if (isNoAvatarView) {
      updateAvatarSettings("NO_AVATAR");
    } else if (isGravatarView) {
      updateAvatarSettings("GRAVATAR");
    }
  };

  const onUploadedAvatarClick = () => {
    // Don't call setEditor here.
    setShowSlider(true);
    setIsAllowedToSave(editorImage !== null);
    setIsGravatarView(false);
    setIsInitialView(false);
    setIsNoAvatarView(false);
    setIsUploadView(true);
  };

  const removePreviousEditorImage = () => {
    URL.revokeObjectURL(previousEditorImage);
    setPreviousEditorImage(null);
  };

  const restorePreviousEditorImage = () => {
    setEditorImage(previousEditorImage);
    setPreviousEditorImage(null);
  };

  const setEditorRef = edit => {
    setEditor(edit);
  };

  const updateAvatarSettings = async (avatarType, imageData = null) => {
    const validAvatarTypes = ["GRAVATAR", "NO_AVATAR", "SYSTEM"];
    if (
      !validAvatarTypes.includes(avatarType) ||
      (avatarType === "SYSTEM" && !imageData)
    ) {
      displayGenericErrorMessage();
      return;
    }
    try {
      const res = await uploadAvatar({
        variables: {
          avatarType,
          imageData,
        },
      });
      if (res.data.userAccountContext.uploadAvatar.success) {
        const viewer = JSON.parse(localStorage.getItem("viewer"));
        viewer.avatar = res.data.userAccountContext.uploadAvatar.avatarUrl;
        setAvatarProfile(res.data.userAccountContext.uploadAvatar.avatarUrl);
        localStorage.setItem("viewer", JSON.stringify(viewer));
        dispatch(currentViewer({ viewer }));
        if (avatarType !== "GRAVATAR") {
          setAvatarProfile(imageData);
        }
        setShowBackDrop(false);
        setOpenUpload(false);
      } else {
        displayGenericErrorMessage();
      }
    } catch (e) {
      displayGenericErrorMessage();
    }
  };

  return (
    <DraggableModal open customClass={classes.modal} handleClose={handleModalClose}>
      <DraggableModalHead
        handleClose={handleModalClose}
        title={<FormattedMessage id="avatarUpload.modalTitle" />}
      />
      <DraggableModalBody customClass={classes.body}>
        <Box style={{ height: 540 }}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Box className={classes.wrapper} style={{ height: 280 }}>
                <Box style={{ marginLeft: "auto" }}>
                  {isInitialView && (
                    <AvatarEditor
                      ref={setEditorRef}
                      image={initialAvatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={editorScale}
                    />
                  )}
                  {isNoAvatarView && (
                    <AvatarEditor
                      ref={setEditorRef}
                      image={defaultAvatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={1}
                    />
                  )}
                  {isUploadView && (
                    <AvatarEditor
                      ref={setEditorRef}
                      image={editorImage || defaultAvatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={editorScale}
                      position={editorPosition}
                      onLoadFailure={onEditorImageLoadFailure}
                      onLoadSuccess={onEditorImageLoadSuccess}
                      onPositionChange={onEditorPositionChange}
                    />
                  )}
                  {isGravatarView && (
                    <AvatarEditor
                      ref={setEditorRef}
                      disableHiDPIScaling
                      image={gravatarUrl || defaultAvatar}
                      borderRadius={150}
                      width={200}
                      height={200}
                      border={35}
                      scale={1}
                    />
                  )}
                </Box>
              </Box>
              <Box style={{ height: 150 }}>
                {isUploadView && (
                  <Box className={classes.wrapper}>
                    <Box
                      style={{
                        marginLeft: "auto",
                        textAlign: "center",
                        marginRight: 35,
                      }}
                    >
                      <Box>
                        <Button
                          variant="contained"
                          className={classes.uploadButton}
                          component="label"
                        >
                          <FormattedMessage id="btn.uploadFromComputer" />
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={onImageFileSelected}
                          />
                        </Button>
                      </Box>
                      <Box
                        style={{ margin: "4px 0", textTransform: "uppercase" }}
                      >
                        <FormattedMessage id="avatarUpload.text.or" />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          className={classes.uploadButton}
                          component="label"
                          disabled
                        >
                          <FormattedMessage id="btn.uploadFromSystem" />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
                {showSlider && (
                  <Box className={classes.wrapper}>
                    <Box
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ marginRight: 10 }}>
                        <FormattedMessage id="avatarUpload.text.zoom" />:{" "}
                      </Box>
                      <Slider
                        min={0.1}
                        className={classes.slider}
                        value={editorScale}
                        onChange={onEditorScaleChange}
                        aria-labelledby="continuous-slider"
                        step={0.01}
                        max={5}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={4} style={{ position: "relative" }}>
              <Box style={{ marginTop: "73%" }}>
                <Box style={{ display: "flex", marginBottom: 12 }}>
                  <Button
                    variant="contained"
                    className={clsx([
                      classes.uploadButton,
                      isNoAvatarView ? classes.selectedButton : "",
                    ])}
                    component="label"
                    onClick={onNoAvatarClick}
                  >
                    <FormattedMessage id="btn.noAvatar" />
                  </Button>
                </Box>
                <Box style={{ display: "flex", marginBottom: 12 }}>
                  <Button
                    variant="contained"
                    className={clsx([
                      classes.uploadButton,
                      isUploadView ? classes.selectedButton : "",
                    ])}
                    component="label"
                    onClick={onUploadedAvatarClick}
                  >
                    <FormattedMessage id="btn.uploadAvatar" />
                  </Button>
                </Box>
                <Box style={{ display: "flex", marginBottom: 12 }}>
                  <Button
                    variant="contained"
                    className={clsx([
                      classes.uploadButton,
                      isGravatarView ? classes.selectedButton : "",
                    ])}
                    onClick={onGravatarClick}
                  >
                    <FormattedMessage id="btn.uploadFromGravatar" />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box style={{ height: 35 }}>
            {isAllowedToSave && (
              <>
                <Box className={classes.wrapper}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={onSaveClick}
                    className={classes.btnSave}
                  >
                    <FormattedMessage id="btn.save" />
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Backdrop className={classes.backdrop} open={showBackDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </DraggableModalBody>
    </DraggableModal>
  );
};

AvatarUpload.propTypes = {
  defaultAvatar: PropTypes.node.isRequired,
  gravatarUrl: PropTypes.node.isRequired,
  setAvatarProfile: PropTypes.func.isRequired,
  setOpenUpload: PropTypes.func.isRequired,
};

export default AvatarUpload;

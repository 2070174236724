import { gql } from '@apollo/client';

const REMOVE_USER_FROM_WORKSPACE = gql`
  mutation RemoveUserFromWorkspace($companySiteSupplierUserId: ID!) {
    companyAdminContext {
      suppliers {
        removeUserFromWorkspace(
          companySiteSupplierUserId: $companySiteSupplierUserId
        ) {
          success
          message
        }
      }
    }
  }
`;

export default REMOVE_USER_FROM_WORKSPACE;

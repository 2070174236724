import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import { setCompanyRoles, setCompanyRolesLoading } from "../actions";
import { LIST_COMPANY_ROLES } from "../graphql/queries/CompanyAdminContext";
import { getAdminSelectedCompanyId } from "../helpers/adminSelectors";

// eslint-disable-next-line import/prefer-default-export
export const useGetCompanyRoles = () => {
  const dispatch = useDispatch();
  const companyId = useSelector(getAdminSelectedCompanyId);
  const [
    getCompanyRoles,
    { data, loading, error },
  ] = useLazyQuery(LIST_COMPANY_ROLES, { variables: { companyId } });

  useEffect(() => {
    if (
      data &&
      data.companyAdminContext &&
      data.companyAdminContext.companyRoles &&
      data.companyAdminContext.companyRoles.listCompanyRoles &&
      data.companyAdminContext.companyRoles.listCompanyRoles
    ) {
      const {
        companyRoleListItems,
      } = data.companyAdminContext.companyRoles.listCompanyRoles;

      if (
        Array.isArray(companyRoleListItems) &&
        companyRoleListItems.length > 0
      ) {
        const listRoles = companyRoleListItems.map(x => {
          return { label: x.name, value: x.companyRoleId };
        });

        dispatch(
          setCompanyRoles({
            items: listRoles,
          })
        );
      }
    }
  }, [data]);

  useEffect(() => {
    dispatch(
      setCompanyRolesLoading({
        loading,
      })
    );
  }, [loading]);

  return { execute: getCompanyRoles, data, loading, error };
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  Divider,
  CircularProgress,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { isEmpty } from "lodash";
import { GET_ONE_STREAM_JOB_REPORT } from "../../graphql/queries";
import DraggableModal from "../common/DraggableModal";
import DraggableModalHead from "../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../common/DraggableModal/DraggableModalBody";
import StatusItems from "./StatusItems";
import StatusModalEmpty from "./StatusModalEmpty";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "95%",
    },
  },
  statusOk: {
    color: "#36910f",
    fontWeight: 600,
  },
  divider: {
    marginTop: 10,
    marginBottom: 0,
  },
  td: {
    fontSize: 11,
    padding: "12px 7px",
  },
  errorTitle: {
    fontWeight: 600,
    fontSize: 11,
  },
  errorSubTitle: {
    fontSize: 11,
  },
  errorFix: {
    fontWeight: 600,
    fontSize: 11,
    color: theme.palette.primary.main,
  },
  statusIcon: {
    padding: 6,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
    borderRadius: "50%",
    marginLeft: 5,
  },
  statusIconOk: {
    backgroundColor: "#e7f7e0",
    color: "#37910f",
  },
  statusIconDanger: {
    padding: 4,
    backgroundColor: "#de514b",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#de514b",
    },
  },
  statusIconWarning: {
    padding: "5px 9px",
    backgroundColor: "#ffe5a8",
    color: "#da8f09",
  },
  action: {
    padding: 4,
  },
  actionIcon: {
    color: "#999",
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const StatusModal = ({ open, handleClose, id }) => {
  const classes = useStyles();

  const [jobReport, setJobReport] = useState({});

  const [getJobReport, { data, loading }] = useLazyQuery(
    GET_ONE_STREAM_JOB_REPORT,
    {
      variables: { id },
    }
  );

  const { detailedMessage, message, name, runListItems, status } = jobReport;

  useEffect(() => {
    getJobReport();
  }, []);

  useEffect(() => {
    if (data && data.oneStream && data.oneStream.oneStreamJobReport) {
      setJobReport(data.oneStream.oneStreamJobReport);
    }
  }, [data]);

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title={name} />
      <DraggableModalBody>
        {loading ? (
          <CircularProgress className={classes.spinner} size={20} />
        ) : (
          <>
            {!isEmpty(jobReport) ? (
              <>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Typography>{detailedMessage || message}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} align="right">
                      Status: <span className={classes.statusOk}>{status}</span>
                    </Grid>
                  </Grid>
                </Box>
                <Divider className={classes.divider} />
                <Table>
                  <TableBody>
                    {(runListItems || []).map(x => {
                      return <StatusItems folder={x} />;
                    })}
                  </TableBody>
                </Table>
              </>
            ) : (
              <StatusModalEmpty />
            )}
          </>
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

StatusModal.defaultProps = {
  id: null,
};

StatusModal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default StatusModal;

import { handleActions } from "redux-actions";
import {
  addMetadata,
  initMetadata,
  removeMetadata,
  setMetadata,
} from "../../../../actions";

const defaultState = {
  metaIndex: [],
  metadata: [],
  count: 0,
};

export default handleActions(
  {
    [initMetadata]: state => {
      return {
        ...state,
        metaIndex: [],
        metadata: [],
        count: 0,
      };
    },
    [addMetadata]: (state, { payload }) => {
      const { value } = payload;
      return {
        ...state,
        metaIndex: [...state.metadata, state.count + 1],
        metadata: [...state.metadata, value],
        count: state.count + 1,
      };
    },
    [removeMetadata]: (state, { payload }) => {
      const { metaIndex } = payload;
      const metadata = state.metadata.filter(
        (meta, index) => index !== metaIndex
      );
      const metaIndexFilter = state.metaIndex.filter(
        (meta, index) => index !== metaIndex
      );
      return {
        ...state,
        metadata,
        metaIndex: metaIndexFilter,
        count: state.count - 1,
      };
    },
    [setMetadata]: (state, { payload }) => {
      const { label, value, metaIndex } = payload;
      const metadata = [...state.metadata];
      const meta = metadata[metaIndex];
      meta[label] = value;
      metadata[metaIndex] = meta;
      return {
        ...state,
        metadata,
      };
    },
  },
  defaultState
);

import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";
import OrderGroup from "../fragments/OrderGroup";

const ADD_PRODUCT = gql`
  mutation AddProduct(
    $orderGroupId: Int!
    $item: OrderGroupShoppingCartItemInput
  ) {
    shoppingCartContext {
      addProduct(orderGroupId: $orderGroupId, item: $item) {
        errorResult {
          ...ErrorResultFields
        }
        success
        result {
          groups {
            ...OrderGroupFragment
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
  ${OrderGroup}
`;

export default ADD_PRODUCT;

import { combineReducers } from "redux";
import toggleCart from "./toggle-cart";
import toggleDetails from "./toggle-details";
import toggleWorkspaces from "./toggle-workspaces";
import toggleThemes from "./toggle-theme";
import locale from "./locale";
import fileView from "./fileView";

export default combineReducers({
  /**
   * Components
   */
  toggleCart,
  toggleDetails,
  toggleWorkspaces,
  toggleThemes,
  fileView,
  /**
   * HOC
   */
  /**
   * Layouts
   */
  /**
   * Pages
   */
  locale,
});

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { Box, TableRow, TableCell, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import { PinkSwitch } from "../../common";
import RowCheckbox from "./RowCheckbox";
import QuantityField from "../../common/FolderFilesPropertiesTable/QuantityField";
import ClearSettingsIcon from "./ClearSettingsIcon";

const useStyles = makeStyles(theme => ({
  folderIcon: {
    fontSize: 14,
    color: "#5b5654",
    paddingRight: 5,
  },
  headerCheckbox: { display: "inline-flex", alignItems: "center" },
  header: { display: "flex" },
  headerSubText: { color: "#808080" },
  headerTitleContainer: { flex: 1 },
  infoIcon: {
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 14,
    marginLeft: 5,
    verticalAlign: "middle",
    color: theme.palette.common.link,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const FolderSection = ({
  folder,
  productionSettings,
  handleCheckboxChangeFolder,
  selectedFoldersProperties,
  handleFolderInclude,
  folderPropertyFormValues,
  setFolderPropertyFormValues,
  onChangeQuantity,
  handleRemoveFolderSettings,
  folderMandatoryFields,
}) => {
  const classes = useStyles();
  const { quantityProperty = {}, productionProperties = [] } =
    productionSettings || {};
  const intl = useIntl();

  const { quantityType } = quantityProperty || {};

  const folderFormValues = folderPropertyFormValues.find(
    x => x.folderId === folder.folderId
  );

  const { isIncluded = false, productionSettings: folderSettingsValues = {} } =
    folderFormValues || {};

  const { orderQuantity, quantityFixedValueId } = folderSettingsValues || {};

  if (!folder || !folder.folderId) return null;

  const isSelected = selectedFoldersProperties.some(y => y === folder.folderId);

  const onCheckboxChange = event => {
    handleCheckboxChangeFolder(event, folder.folderId);
  };

  const onIncludeChange = event => {
    handleFolderInclude(event, folder.folderId, folder.name);
  };

  let firstProperty = true;
  const { productionSettings: folderProductionSettings = {} } =
    folderFormValues || {};
  const {
    propertyValueIds = [],
    propertyDataValues = [],
  } = folderProductionSettings;

  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Box display="flex" alignItems="center">
          <RowCheckbox onChange={onCheckboxChange} checked={isSelected} />
          <Icon className={clsx(["fa fa-folder", classes.folderIcon])} />
          <Box display="flex" flexDirection="column">
            <Box>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: folder.name }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              {propertyValueIds &&
                Array.isArray(propertyValueIds) &&
                propertyValueIds.length > 0 && (
                  <ClearSettingsIcon
                    onClick={() =>
                      handleRemoveFolderSettings({ folderId: folder.folderId })
                    }
                  />
                )}
              <Typography style={{ fontSize: 12, color: "#808080" }}>
                {/* {arrayPropertiesLabel.map(
                  (x, index) => `${index === 0 ? "" : " | "}${x.label}`
                )} */}

                {productionProperties.map(x => {
                  const { propertyValues = [] } = x;

                  let propertiesSelected = [];

                  propertyValues.forEach(pV => {
                    const exist = propertyValueIds.includes(pV.id);

                    if (exist) {
                      propertiesSelected = [...propertiesSelected, pV];
                    }
                  });

                  const isFirstProperty = firstProperty;

                  if (propertiesSelected && propertiesSelected.length > 0) {
                    firstProperty = false;
                    return `${
                      isFirstProperty ? "" : " | "
                    }${propertiesSelected.map(
                      (pS, index) => `${index === 0 ? "" : " "}${pS.name}`
                    )}`;
                  }

                  return "";
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell style={{ width: 150 }}>
        {isIncluded && quantityType && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <span style={{ flex: 1 }}>
              <QuantityField
                quantityProperty={quantityProperty}
                onChange={e => {
                  onChangeQuantity({
                    value: e.target.value,
                    folderId: folder.folderId,
                  });
                }}
                value={
                  quantityProperty.quantityType === "FIXED"
                    ? quantityFixedValueId
                    : orderQuantity
                }
              />
            </span>
            <Typography component="span" style={{ marginLeft: 5 }}>
              {intl.formatMessage({
                id: "common.quantity",
              })}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell align="center">
        <PinkSwitch checked={isIncluded} onChange={onIncludeChange} />
        <Typography component="span">
          {intl.formatMessage({
            id: "addFolderToBasket.include",
          })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

FolderSection.propTypes = {
  handleRemoveFolderSettings: PropTypes.func.isRequired,
  productionSettings: PropTypes.shape({}).isRequired,
  folder: PropTypes.shape({
    folderId: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  selectedFoldersProperties: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleCheckboxChangeFolder: PropTypes.func.isRequired,
};

export default FolderSection;

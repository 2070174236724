import { gql } from '@apollo/client';
import ErrorResultFragment from "../../../fragments/ErrorResult";

const INIT_COMPANY_LOGO_UPLOAD = gql`
  mutation InitCompanyLogoUpload($targetCompanyId: Int!) {
    companyAdminContext {
      settings {
        initCompanyLogoUpload(targetCompanyId: $targetCompanyId) {
          success
          url
          errorResult {
            ...ErrorResultFields
          }
        }
      }
    }
  }
  ${ErrorResultFragment}
`;

export default INIT_COMPANY_LOGO_UPLOAD;

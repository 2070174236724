import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import CustomSelect from "../../common/CustomSelect";
import CheckboxDefault from "../../common/FormControl/Checkbox";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#f7f7f7",
    borderRadius: 4,
    padding: 20,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  headingCustom: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 5,
    marginTop: 15,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  textExample: {
    fontSize: 12,
    "& span": {
      color: theme.palette.common.link,
    },
  },
  exampleWrap: {
    display: "flex",
    alignItems: "center",
  },
  example: {
    padding: 5,
    backgroundColor: "#ECECEC",
    borderRadius: 4,
    marginLeft: 10,
    marginBottom: 10,
  },
}));

const ManageSettingsInfo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.heading}>Match settings</Typography>
      <Typography className={classes.text}>
        <strong>"Name"</strong> indicates the field name.
      </Typography>
      <Typography className={classes.text}>
        <strong>"Show in Search"</strong> these additional fields appear in
        parentheses in the search box in addition to the specified search field.
      </Typography>
      <Box className={classes.exampleWrap}>
        <Typography className={classes.textExample}>Example:</Typography>
        <Box className={classes.example}>
          <Typography className={classes.textExample}>
            Carl Andersson <span>(carl.a@exemple.com)</span>
          </Typography>
        </Box>
      </Box>
      <Typography className={classes.text}>
        <strong>"Weight"</strong> indicates how unique a field is and whether it
        should be used to identify a person in the list. Good values ​​are to
        put 100 on full name, first name or last name and maybe 80 on an email
        address.
      </Typography>
      <Typography className={classes.text}>
        <strong>"Spell tolerance"</strong> specifies how insensitive the update
        should be for changed spelling. By "small deviation" is considered e.g.
        Andersson and Anderson are the same name and with a distance of "Big
        deviation" Carlsson and Karlson are considered to be the same.
      </Typography>
      <Typography className={classes.heading}>Search</Typography>
      <Typography className={classes.text}>
        Which field should be used for searching the list?
      </Typography>
      <CustomSelect options={[{ label: "Name", value: 1 }]} />
      <Typography className={classes.headingCustom}>
        How should changes be handled?
      </Typography>
      <Box>
        <CheckboxDefault label="Save change to the list" />
      </Box>
      <Box>
        <CheckboxDefault label="Personal staff list" />
      </Box>
      <Typography className={classes.text}>
        With personalized personnel list, each user will have their own set of
        saved items in the list.
      </Typography>
    </Box>
  );
};

export default ManageSettingsInfo;

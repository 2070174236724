import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAlert } from "react-alert";
import { useMutation } from "@apollo/client";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { TableRow, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { REMOVE_USER_FROM_WORKSPACE } from "../../../graphql/mutations/CompanyAdminContext";

const useStyles = makeStyles(() => ({
  td: {
    borderBottom: "1px solid #f1f1f1",
    fontSize: 12,
  },
}));

const ConnectedWorksapceRow = ({
  supplier,
  active,
  siteName,
  getCompanySupplierSettings,
}) => {
  const classes = useStyles();
  const alert = useAlert();
  const { username, companySiteSupplierUserId } = supplier;

  const [deleteModal, setDeleteModal] = useState(false);
  const [removeUserFromWorkspace] = useMutation(REMOVE_USER_FROM_WORKSPACE);

  const handleRemoveSupplier = async () => {
    try {
      const res = await removeUserFromWorkspace({
        variables: { companySiteSupplierUserId },
      });
      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.suppliers &&
        res.data.companyAdminContext.suppliers.removeUserFromWorkspace
      ) {
        const {
          success,
        } = res.data.companyAdminContext.suppliers.removeUserFromWorkspace;

        if (success) {
          alert.success(
            <FormattedMessage id="supplier.removedSupplierAlias" />
          );
          getCompanySupplierSettings();
        } else {
          alert.error(
            <FormattedMessage id="supplier.errorRemovingSupplierAlias" />
          );
        }
        setDeleteModal(false);
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <TableRow>
      <TableCell className={classes.td}>{siteName}</TableCell>
      <TableCell className={classes.td}>{username}</TableCell>
      <TableCell className={classes.td}>
        <>
          {active ? (
            <FormattedMessage id="supplier.ACTIVE" />
          ) : (
            <FormattedMessage id="supplier.INACTIVE" />
          )}
        </>
      </TableCell>
      <TableCell className={classes.td}>
        <Tooltip
          title={<FormattedMessage id="btn.removeUser" />}
          placement="top"
        >
          <IconButton
            edge="end"
            aria-label="delete"
            className={classes.removeRow}
            onClick={() => setDeleteModal(true)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </TableCell>

      {deleteModal && (
        <ConfirmDeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          onConfirmCloseModal={handleRemoveSupplier}
        />
      )}
    </TableRow>
  );
};

ConnectedWorksapceRow.defaultProps = {
  supplier: null,
  getCompanySupplierSettings: null,
  active: null,
  siteName: null,
};

ConnectedWorksapceRow.propTypes = {
  active: PropTypes.bool,
  siteName: PropTypes.string,
  supplier: PropTypes.shape({
    username: PropTypes.string,
    companySiteSupplierUserId: PropTypes.string,
  }),
  getCompanySupplierSettings: PropTypes.func,
};

export default ConnectedWorksapceRow;

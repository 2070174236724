import { gql } from '@apollo/client';

const REMOVE_USER_FROM_SHARE = gql`
  mutation RemoveUserFromShare($shareId: Int!, $usernames: String) {
    shareContext {
      removeUserFromShare(shareId: $shareId, usernames: $usernames)
    }
  }
`;

export default REMOVE_USER_FROM_SHARE;

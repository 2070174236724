import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { ModalFooter } from "../../common";
import { PrimaryButton } from "../../common/Buttons";
import FieldsForm from "./FieldsForm";
import DraggableModal from "../../common/DraggableModal";
import DraggableModalHead from "../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(() => ({
  modal: {
    width: 500,
  },
}));

const ChangeAddressModal = ({
  open,
  setOpen,
  addressFields,
  addressForm,
  modalTitle,
  handleSaveAccountSettings,
}) => {
  const classes = useStyles();

  const [addressFormState, setAddressFormState] = useState(addressForm);

  if (!addressFields || !addressForm) return null;

  const handleChange = e => {
    setAddressFormState({
      ...addressFormState,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead handleClose={handleClose} title={modalTitle} />
      <DraggableModalBody>
        <FieldsForm
          addressFields={addressFields}
          addressForm={addressFormState}
          handleChange={handleChange}
        />
        <ModalFooter>
          <PrimaryButton onClick={handleSaveAccountSettings}>
            <FormattedMessage id="btn.send" />
          </PrimaryButton>
        </ModalFooter>
      </DraggableModalBody>
    </DraggableModal>
  );
};

ChangeAddressModal.defaultProps = {};

ChangeAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  handleSaveAccountSettings: PropTypes.func.isRequired,
};

export default ChangeAddressModal;

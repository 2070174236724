const {
  NODE_ENV: nodeEnv,
  DEBUG: debugStr,
  PORT: port,
  REACT_APP_URL: appcUrl,
  REACT_APP_GRAPHQL_URL: graphqlUrl,
  REACT_APP_WEB_API_URL: webApiURL,
  REACT_APP_PUBLIC_GRAPHQL_URL: publicGraphqlUrl,
  REACT_APP_GRAPHQL_WS_URL: graphqlUrlWS,
  REACT_APP_OIDC_AUTHORIZE_URL: oidcAuthorizeUrl,
  REACT_APP_OIDC_CLIENT_ID: oidcClientId,
  REACT_APP_OIDC_REDIRECT_URI: oidcRedirectUri,
  REACT_APP_OIDC_LOGOUT_URL: oidcLogoutUrl,
  REACT_APP_OIDC_SCOPE: oidcScope,
  REACT_APP_OIDC_SILENT_RENEW_URI: oidcSilentRenewUri,
  REACT_APP_OIDC_LOGIN_URL: oidcLoginUrl,
  REACT_APP_OIDC_URL: oidcUrl,
  REACT_APP_API_URL: apiUrl,
  REACT_APP_PAGE_TITLE: pageTitle,
  REACT_APP_FAV_ICON: faviconUrl,
} = process.env;

const debug = debugStr === "true";

module.exports = {
  nodeEnv,
  debug,
  port,
  appcUrl,
  apiUrl,
  graphqlUrl,
  graphqlUrlWS,
  oidcAuthorizeUrl,
  oidcClientId,
  oidcRedirectUri,
  oidcLogoutUrl,
  oidcScope,
  oidcSilentRenewUri,
  oidcLoginUrl,
  oidcUrl,
  pageTitle,
  faviconUrl,
  webApiURL,
  publicGraphqlUrl,
};

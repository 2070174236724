import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getCurrentUser } from "../../../helpers/selectors";
import useChangeCurrentWorkspace from "../../../hooks/useChangeCurrentWorkspace";
import DashboardWidget from "../../common/DashboardWidget";
import DashboardWidgetBody from "../../common/DashboardWidgetBody";
import PIMLink from "./PIMLink";
import Workspace from "./Workspace";

const useStyles = makeStyles(() => ({
  body: {
    background: "transparent",
    overflowY: "auto",
    padding: 10,
  },
  title: {
    color: props => props.titleColor || "#212529",
    fontSize: 16,
    marginBottom: 10,
  },
  widgetHead: {
    paddingBottom: 10,
    paddingTop: 10,
  },
  workspaceContainer: {
    background: "transparent",
  },
}));

const useItemStyles = makeStyles(theme => ({
  root: {
    background: props => props.contentBG || "#ffffff",
    borderRadius: 4,
    borderTop: props => `1px solid ${props.dividerColor || "#dee2e6"}`,
    cursor: "pointer",
    flexWrap: "nowrap",
    padding: 11,
    "&.specialItem": {
      borderTop: "none",
    },
    "&:hover": {
      background: props =>
        `${props.contentBG || theme.palette.secondary.light}B7`,
    },
  },
  name: {
    //color: props => props.fontColor || "#212529",
    color: "#212529",
    fontSize: 14,
    fontWeight: 600,
  },
  description: {
    //color: props => props.fontColor || "#9a9a9a",
    color: "#9a9a9a",
    fontSize: 12,
  },
  iconContainer: {
    marginRight: 7,
    minWidth: 30,
    textAlign: "center",
  },
}));

const itemStylePropsFromLayout = (layout) => {
  const { contentBG, dividerColor, fontColor, titleColor } = layout || {};
  return { contentBG, dividerColor, fontColor, titleColor };
}

const WorkspaceWidget = ({ layout }) => {
  const { name, titleColor } = layout || {};
  const itemStyleProps = itemStylePropsFromLayout(layout);

  const classes = useStyles({ titleColor });
  const itemClasses = useItemStyles(itemStyleProps);

  const { companyId, sites: workspaces } = useSelector(getCurrentUser);

  const changeCurrentWorkspace = useChangeCurrentWorkspace();

  const haveWorkspaces = Array.isArray(workspaces) && workspaces.length > 0;

  const haveTitle = !!name;

  const navigateToWorkspace = (siteId) => {
    if (siteId > 0) {
      changeCurrentWorkspace(siteId);
    }
  };

  return (
    <DashboardWidget>
      <DashboardWidgetBody customClass={classes.body}>
        {haveTitle &&
          <Typography className={classes.title}>
            {name}
          </Typography>
        }
        <Box className={classes.workspaceContainer}>
          {haveWorkspaces &&
            workspaces.map((workspace, idx) => (
              <Workspace
                classes={itemClasses}
                className={(!haveTitle && idx === 0) ? "specialItem" : ""}
                key={`workspace-${workspace.id}`}
                onClick={navigateToWorkspace}
                workspace={workspace}
              />
            ))}

          {companyId === 20 && <PIMLink classes={itemClasses} />}
        </Box>
      </DashboardWidgetBody>
    </DashboardWidget>
  );
};

export default WorkspaceWidget;

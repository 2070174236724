import { gql } from '@apollo/client';

const SAVE_FIELDS = gql`
  mutation SaveFields($productId: ID!, $model: DynamicTemplateFieldsEditInput) {
    dynamicTemplateEditContext {
      saveFields(productId: $productId, model: $model) {
        productId
      }
    }
  }
`;

export default SAVE_FIELDS;

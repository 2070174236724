import { gql } from '@apollo/client';
import ErrorResultFragment from "../fragments/ErrorResult";

const RENAME_FOLDER = gql`
  mutation UpdateFolder($folderId: Int!, $newName: String) {
    renameFolder(folderId: $folderId, newName: $newName) {
      errorResult {
        ...ErrorResultFields
      }
      success
    }
  }
  ${ErrorResultFragment}
`;

export default RENAME_FOLDER;

import { debounce } from "lodash";

import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useLazyQuery } from "@apollo/client";
import { Box, Button, Grid, Stack, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import {
  COMPANY_RESPONSIBLE_LOOK_UP,
} from "../../graphql/queries";
import { useSearchV2 } from "../../hooks";

import ButtonText from "../common/ButtonText";
import SearchAutoComplete from "../common/SearchAutoComplete";
import TagsField from "../common/TagsField";
import WorkspaceSelector from "../common/WorkspaceSelector";

import DetailedSearchItem from "./DetailedSearchItem";

import FilterField from "./FilterField";
import { Chip } from "../common";
import CustomTextField from "../common/TextField";

const useStyles = makeStyles(theme => ({
  searchFooter: {
    padding: "8px 15px",
    margin: 0,
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.component.detailedSearchBorder,
    textAlign: "right",
  },
  searchBody: {
    padding: "10px",
    width: "100%",
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: theme.palette.background.lightGray,
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: theme.palette.background.lightGray,
      fontSize: 11,
    },
    "& .MuiInputBase-input": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiFormControl-marginDense": {
      marginTop: 1,
    },
    "& .MuiInputBase-formControl input": {
      fontSize: 11,
      padding: "7px 12px 7px 12px;",
    },
    "& .MuiSelect-select": {
      fontSize: 11,
      paddingTop: 4,
      paddingBottom: 5,
    },
    "& legend": {
      lineHeight: "10px",
    },
    "& .MuiInputBase-multiline": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputBase-multiline textarea": {
      fontSize: 12,
    },
  },
  label: {
    fontSize: 11,
    hyphens: "auto",
  },
  btnClear: {
    color: theme.palette.common.link,
    fontSize: 11,
    marginRight: 15,
  },
  btnSearch: {
    padding: "3px 25px",
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: 10,
    backgroundColor: theme.palette.primary.alt,
    minWidth: "unset",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.primary.alt,
      opacity: 0.8,
    },
    width: "auto",
  },
}));

const DetailedSearch = () => {
  const classes = useStyles();
  const intl = useIntl();

  const sites = useSelector(state => state.api.currentViewer.viewer.sites);
  const initialSiteId = useSelector(state => state.ui.toggleWorkspaces.workspace.id);
  const initialWorkspaceName = useSelector(state => state.ui.toggleWorkspaces.workspace.name);
  const workspaces = useSelector(state => state.api.currentViewer.viewer.structure.items);
  const searchQuery = useSelector(state => state.ui.toggleDetails.searchQuery);
  const productOwner = useSelector(state => state.ui.toggleDetails.productOwner);

  const [searchAutocomplete, setSearchAutoComplete] = useState(false);

  const [siteSelected, setSiteSelected] = useState(initialSiteId || 0);

  const [workspaceName, setWorkspaceName] = useState(initialWorkspaceName || "");
  const [searchTerm, setSearchTerm] = useState(searchQuery);

  const [filterItems, setFilterItems] = useState(
    !productOwner ? [] : [{
      key: "responsible",
      value: productOwner.value,
      label: productOwner.label
    }]
  );

  const filterFieldOptions = [
    { name: "name", type: "string", intl: "detailedSearch.name", },
    { name: "description", type: "string", intl: "detailedSearch.description", },
    { name: "articleNo", type: "string", intl: "detailedSearch.articleNo", },
    { name: "responsible", type: "selectPerson", intl: "detailedSearch.productOwner", },
    { name: "bureau", type: "string", intl: "detailedSearch.agency", },
    { name: "filenname", type: "string", intl: "detailedSearch.fileName", },
    { name: "location", type: "string", intl: "detailedSearch.imageLocation", },
    { name: "model", type: "string", intl: "detailedSearch.imageModel", },
    { name: "occupation", type: "string", intl: "detailedSearch.imageOccupation", },
    { name: "photographer", type: "string", intl: "detailedSearch.imagePhotographer", },
    { name: "folderName", type: "folder", intl: "detailedSearch.folderName", },
    { name: "tag", type: "string", intl: "product.tagging", },
  ];

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(productOwner);

  const [
    searchCompanyResponsible,
    { data: reponsibleData, loading: responsibleLoading },
  ] = useLazyQuery(COMPANY_RESPONSIBLE_LOOK_UP);

  const debounceFetchUsersList = useCallback(
    debounce(async searchTerm => {
      searchCompanyResponsible({
        variables: { searchTerm },
      });
    }, 500),
    []
  );

  const handleOnChange = async text => {
    if (text && text.length > 2) {
      debounceFetchUsersList(text);
    }
  };

  const setClose = () => {
    setSearchAutoComplete(false);
  };

  const setOpen = () => {
    setSearchAutoComplete(true);
  };

  useEffect(() => {
    handleDetailedSearchResults();
  }, []);

  useEffect(() => {
    if (initialSiteId) {
      setSiteSelected(initialSiteId);
    }
  }, [initialSiteId]);

  useEffect(() => {
    if (
      reponsibleData &&
      reponsibleData.productEdit &&
      Array.isArray(reponsibleData.productEdit.companyAnsvarigLookup) &&
      reponsibleData.productEdit.companyAnsvarigLookup.length > 0
    ) {
      const tempUsers = reponsibleData.productEdit.companyAnsvarigLookup.map(
        x => {
          return { label: x.user, value: x.userId };
        }
      );
      setUsers(tempUsers);
    }
  }, [reponsibleData]);

  const handleSetUsers = value => {
    setSelectedUser({ ...value });
  };

  const { execute: detailedSearch } = useSearchV2();

  const [workspaceSelector, setWorkspaceSelector] = useState(false);

  const handleToggle = () => {
    setWorkspaceSelector(prevState => !prevState);
  };

  const handleOnClickSite = site => {
    let found = {};
    if (site.siteId !== 0) {
      found = sites.find(x => x.id === site.siteId);
    } else {
      found = { ...site, id: 0 };
    }

    const { id, orderGroupId: groupId, name } = found;
    setSiteSelected(id);
    setWorkspaceName(name);
    setWorkspaceSelector(false);
  };

  const handleDetailedSearchResults = () => {

    var tempFields = filterItems.map(({ key, value }) => ({ key, value: value.toString() }));

    detailedSearch({
      variables: {
        searchTerm: searchTerm,
        siteId: siteSelected,
        filterItems: tempFields
      }
    });
  };

  const handleAddFilterItem = (groupKey, value) => {
    const filterItem = { key: groupKey };

    if (groupKey === "responsible") {
      filterItem.value = value.value;
      filterItem.label = value.label;
    } else {
      filterItem.value = value;
    }

    setFilterItems([...filterItems, filterItem]);
  }

  const handleDeleteFilterItem = (n) => {
    const tempFilterItems = [...filterItems];
    tempFilterItems.splice(n, 1);

    setFilterItems(tempFilterItems);
  }

  return (
    <>
      <Box className={classes.searchBody}>
        <Grid container spacing={1} alignItems="center">

          <Grid item xs={12}>
            <CustomTextField label={"Sök"} value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
          </Grid>

          <Grid item xs={12}>

            {filterItems && filterItems.length > 0 && filterItems.map((n, i) => (
              <Chip key={i} label={n.key + ":" + (n.label || n.value)} onDelete={n => handleDeleteFilterItem(i)} />
            ))}

          </Grid>

          <Grid item xs={12} className={classes.colPadding}>
            <WorkspaceSelector
              workspaces={workspaces}
              workspaceName={workspaceName}
              onClick={handleOnClickSite}
              handleToggle={handleToggle}
              open={workspaceSelector}
              searchable
              setOpen={setWorkspaceSelector}
              searchAll
            />
          </Grid>
        </Grid>



        {filterFieldOptions.filter(n => n.type === "string").map((filter) => (
          <FilterField sx={{ paddingBottom: 4 }}
            label={intl.formatMessage({ id: filter.intl })}
            groupKey={filter.name}
            onAddFilter={handleAddFilterItem}
          />
        ))}

        <Grid container spacing={1} alignItems="center" >
          <Grid item xs={10} className={classes.colPadding}>
            <SearchAutoComplete
              open={searchAutocomplete}
              setOpen={setOpen}
              setClose={setClose}
              onChange={e => handleOnChange(e)}
              options={users}
              size="small"
              loading={responsibleLoading}
              setValue={handleSetUsers}
              value={selectedUser}
              label={<FormattedMessage id="detailedSearch.productOwner" />}
              customClass={classes.searchField}
              customClasses={{ listbox: classes.listbox }}
            />
          </Grid>
          <Grid item xs={2} align="center" className={classes.pl0}>

            <IconButton sx={{ marginTop: 0.5 }} color="primary" onClick={n => handleAddFilterItem("responsible", selectedUser)}><AddIcon /></IconButton>

          </Grid>
        </Grid >

      </Box>
      <Box className={classes.searchFooter}>
        <ButtonText
          label={<FormattedMessage id="btn.reset" />}
          btnClass={classes.btnClear}
          onClick={() => { setFilterItems([]) } /*handleResetFields()*/}
        />
        <Button
          className={classes.btnSearch}
          onClick={() => handleDetailedSearchResults()}
        >
          <FormattedMessage id="btn.search" />
        </Button>
      </Box>
    </>
  );
};

export default DetailedSearch;

export const parseJwt = token => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const isTokenValid = token => {
  const parsedJwt = parseJwt(token);
  const { exp } = parsedJwt || {};

  const today = new Date().getTime() / 1000;
  if (exp > today) {
    return true;
  }
  return false;
};

export default parseJwt;

import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PageRoute from "../../../constant/pageRouteDictionary.json";

const useStyles = makeStyles(theme => ({
  editWrap: {
    padding: "7px 10px",
  },
  editLink: {
    color: theme.palette.common.link,
    fontSize: 12,
    textDecoration: "none",
  },
  editIcon: {
    marginRight: 5,
    width: "unset",
    height: "unset",
    overflow: "unset",
    fontSize: 11,
  },
}));

const OrganizeWorkspacesLink = () => {
  const classes = useStyles();

  return (
    <Box className={classes.editWrap} align="right">
      <Typography>
        <Link to={PageRoute.ORGANIZE_WORKSPACES} className={classes.editLink}>
          <Icon className={clsx(["fa fa-edit", classes.editIcon])} />
          <FormattedMessage id="product.edit" />
        </Link>
      </Typography>
    </Box>
  );
};

export default OrganizeWorkspacesLink;

import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Typography, CircularProgress, Box, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "500px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
    textAlign: "center",
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 5px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  deleteConfirmation: {
    fontWeight: "600",
  },
  circular: {
    color: "rgba(255,255,255,0.6)",
  },
}));

const DeleteModal = ({
  open,
  handleClose,
  name,
  handleDelete,
  handleMultipleDelete,
  isSelected,
  selectedResources,
  deleteLoading,
}) => {
  const classes = useStyles();

  const renderBody = () => {
    if (
      isSelected &&
      selectedResources &&
      Array.isArray(selectedResources) &&
      selectedResources.length > 1
    ) {
      let toDeleteResources = [];

      selectedResources.forEach(x => {
        if (!x.locked) {
          toDeleteResources = [...toDeleteResources, x];
        }
      });

      return (
        <>
          <Typography className={classes.deleteConfirmation}>
            <FormattedMessage id="resources.deleteMultipleFilesConfirmation" />
          </Typography>
          <br />
          <Typography>
            <FormattedMessage id="resources.youHaveSelectedFiles" />
          </Typography>
          <br />

          {toDeleteResources.map(x => {
            return <Typography key={x.id}>{x.name}</Typography>;
          })}
          <Box className={classes.modalFooter} align="right">
            <Button
              className={classes.btnCancel}
              onClick={() => handleClose()}
              disabled={deleteLoading}
            >
              <FormattedMessage id="btn.cancel" />
            </Button>
            <Button
              className={classes.btn}
              onClick={() => handleMultipleDelete(toDeleteResources)}
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <CircularProgress size={18} className={classes.circular} />
              ) : (
                <FormattedMessage id="btn.yes" />
              )}
            </Button>
          </Box>
        </>
      );
    }

    return (
      <>
        <FormattedMessage id="context.deleteConfirmation" />{" "}
        <strong>
          {name || (selectedResources[0] && selectedResources[0].name) || ""}?
        </strong>
        <Box className={classes.modalFooter} align="right">
          <Button className={classes.btnCancel} onClick={() => handleClose()}>
            <FormattedMessage id="btn.cancel" />
          </Button>
          <Button className={classes.btn} onClick={() => handleDelete()}>
            <FormattedMessage id="btn.yes" />
          </Button>
        </Box>
      </>
    );
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="product.delete" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        {renderBody()}
      </DraggableModalBody>
    </DraggableModal>
  );
};

DeleteModal.defaultProps = {
  name: "",
  isSelected: false,
  deleteLoading: false,
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleMultipleDelete: PropTypes.func.isRequired,
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  deleteLoading: PropTypes.bool,

  selectedResources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default DeleteModal;

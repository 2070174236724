import React, { useState, useEffect } from "react";
import { Grid, Typography, Icon, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useLazyQuery, useMutation } from "@apollo/client";
import clsx from "clsx";
import { useAlert } from "react-alert";
import DraggableModal from "../../../common/DraggableModal";
import DraggableModalHead from "../../../common/DraggableModal/DraggableModalHead";
import DraggableModalBody from "../../../common/DraggableModal/DraggableModalBody";
import { PrimaryButton } from "../../../common/Buttons";
import ConfirmationModal from "../../../common/ConfirmationModal";
import Address from "./Address";
import {
  addressess,
  deliveryAddress,
  postalAddress,
  invoiceAddress,
  visitingAddress,
} from "../helpers";
import { UPDATE_GROUP_ADDRESSES } from "../../../../graphql/mutations/CompanyAdminContext";
import { EDIT_GROUP_ADDRESSES } from "../../../../graphql/queries/CompanyAdminContext";
import { getSortedDeliverySettings } from "../../../../helpers/get";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "1200px",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  modalBody: {
    padding: "30px 20px 15px 20px",
    fontSize: 16,
  },
  modalFooter: {
    marginTop: 30,
    padding: "15px 0 0 0",
    borderTop: "1px solid #ddd",
  },
  btn: {
    padding: "3px 8px",
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  btnCancel: {
    textTransform: "none",
    color: theme.palette.common.link,
    marginRight: 5,
    fontSize: 12,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnContainer: {
    marginTop: 15,
  },
  editIcon: {
    fontSize: 18,
    marginRight: 7,
    color: "#0000ff",
  },
}));

const EditAddressModal = ({ open, handleClose, groupId }) => {
  const classes = useStyles();
  const alert = useAlert();
  const [updateGroupAddresses] = useMutation(UPDATE_GROUP_ADDRESSES);
  const [confirmModal, setConfirmModal] = useState(false);
  const [fieldAddresses, setFieldAddresses] = useState(addressess);
  const [fieldValues, setFieldValues] = useState({
    deliveryAddress,
    postalAddress,
    invoiceAddress,
    visitingAddress,
  });

  const [
    getAddresses,
    { data: groupAddress, loading: groupAddressLoading },
  ] = useLazyQuery(EDIT_GROUP_ADDRESSES);

  useEffect(() => {
    if (open) {
      getAddresses({ variables: { groupId } });
    }
  }, [open, groupId]);

  useEffect(() => {
    if (
      groupAddress &&
      groupAddress.companyAdminContext &&
      groupAddress.companyAdminContext.companyGroupSettings &&
      groupAddress.companyAdminContext.companyGroupSettings.editGroupAddresses
    ) {
      const {
        result,
      } = groupAddress.companyAdminContext.companyGroupSettings.editGroupAddresses;

      const {
        deliveryAddress,
        invoiceAddress,
        postalAddress,
        visitingAddress,
        deliveryAddressSettings,
        invoiceAddressSettings,
        postalAddressSettings,
        visitingAddressSettings,
      } = result;

      setFieldValues({
        deliveryAddress,
        invoiceAddress,
        postalAddress,
        visitingAddress,
      });

      const sortedDeliverySettings = getSortedDeliverySettings(
        deliveryAddressSettings
      );
      const sortedPostalSettings = getSortedDeliverySettings(
        postalAddressSettings
      );

      const sortedInvoiceSettings = getSortedDeliverySettings(
        invoiceAddressSettings
      );

      const sortedVisitingSettings = getSortedDeliverySettings(
        visitingAddressSettings
      );

      const tempAddress = [...fieldAddresses];

      tempAddress[0] = {
        ...fieldAddresses[0],
        fields: sortedDeliverySettings,
      };

      tempAddress[1] = {
        ...fieldAddresses[1],
        fields: sortedPostalSettings,
      };
      tempAddress[2] = {
        ...fieldAddresses[2],
        fields: sortedInvoiceSettings,
      };

      tempAddress[3] = {
        ...fieldAddresses[3],
        fields: sortedVisitingSettings,
      };
      setFieldAddresses(tempAddress);
    }
  }, [groupAddress]);

  const handleChange = (address, e) => {
    const { name, value } = e.target;
    setFieldValues({
      ...fieldValues,
      [address]: { ...fieldValues[address], [name]: value },
    });
  };

  const handeCloseConfirmModal = () => {
    setConfirmModal(false);
  };

  const handleUpdateAddresses = async () => {
    const update = {
      groupId,
      ...fieldValues,
    };
    try {
      const res = await updateGroupAddresses({ variables: { update } });
      if (
        res &&
        res.data &&
        res.data.companyAdminContext &&
        res.data.companyAdminContext.companyGroupSettings &&
        res.data.companyAdminContext.companyGroupSettings.updateGroupAddresses
      ) {
        const {
          success,
        } = res.data.companyAdminContext.companyGroupSettings.updateGroupAddresses;
        if (success) {
          alert.success(<FormattedMessage id="common.genericSuccess" />);
        } else {
          alert.error(<FormattedMessage id="common.genericErrorMessage" />);
        }
        handleClose();
      }
    } catch (err) {
      console.error("error >", err);
    }
  };

  return (
    <DraggableModal
      open={open}
      handleClose={handleClose}
      customClass={classes.modal}
    >
      <DraggableModalHead
        handleClose={handleClose}
        title={<FormattedMessage id="context.editAddresses" />}
      />
      <DraggableModalBody customClass={classes.modalBody}>
        <Grid container alignContent="center" justifyContent="flex-end">
          <Icon className={clsx(["fas fa-info-circle", classes.editIcon])} />
          <Typography>
            <FormattedMessage id="userGroups.emptyFields" />
          </Typography>
        </Grid>
        <Grid container spacing={1} className={classes.btnContainer}>
          {groupAddressLoading && (
            <Grid item container xs={12} alignItems="center" justifyContent="center">
              <CircularProgress size={30} />
            </Grid>
          )}

          {!groupAddressLoading && (
            <>
              {fieldAddresses.map(address => {
                return (
                  <Address
                    name={address.name}
                    label={address.label}
                    fields={address.fields}
                    fieldValues={fieldValues}
                    handleChange={handleChange}
                  />
                );
              })}
            </>
          )}
        </Grid>
        <Grid container justifyContent="flex-end" className={classes.btnContainer}>
          <PrimaryButton marginRight={7} onClick={() => setConfirmModal(true)}>
            <FormattedMessage id="btn.save" />
          </PrimaryButton>
          <PrimaryButton onClick={handleClose}>
            <FormattedMessage id="btn.cancel" />
          </PrimaryButton>
        </Grid>

        {confirmModal && (
          <ConfirmationModal
            open={confirmModal}
            handleClose={handeCloseConfirmModal}
            onClick={handleUpdateAddresses}
            message={<FormattedMessage id="addresses.saveChanges" />}
          />
        )}
      </DraggableModalBody>
    </DraggableModal>
  );
};

export default EditAddressModal;

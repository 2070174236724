import { useIntl } from "react-intl";

// eslint-disable-next-line import/prefer-default-export
export const getProductOptions = ({ intl }) => {
  const productTypeOptions = [
    {
      label: intl.formatMessage({
        id: "delivery.onlyViewable",
      }),
      value: "ONLY_DISPLAY",
    },
    {
      label: intl.formatMessage({
        id: "delivery.onlyDownloadable",
      }),
      value: "ONLY_DOWNLOAD",
    },
    {
      label: intl.formatMessage({
        id: "delivery.productContainer",
      }),
      value: "PRODUCT_CONTAINER",
    },
    {
      label: intl.formatMessage({
        id: "delivery.bookable",
      }),
      value: "BOOKABLE",
    },
    {
      label: intl.formatMessage({
        id: "delivery.stockProduct",
      }),
      value: "STOCK_PRODUCT",
    },
    {
      label: intl.formatMessage({
        id: "delivery.image",
      }),
      value: "IMAGE",
    },
    {
      label: intl.formatMessage({
        id: "delivery.onDemand",
      }),
      value: "ON_DEMAND",
    },
    {
      label: intl.formatMessage({
        id: "delivery.dynamicProduct",
      }),
      value: "DYNAMIC_PRODUCT",
    },
    // {
    //   label: intl.formatMessage({
    //     id: "delivery.kit"
    //   }),
    //   value: "KIT"
    // }
  ];

  return productTypeOptions;
};

export const initialDeliveryValues = {
  supplierIds: [],
  siteSuppliers: [],
  allowDownload: false,
};

export const initialBooking = {
  allowFreeBooking: false,
  bookingDays: 0,
};

export const initialDelivery = {
  bookingCalendar: false,
  orderShoppingCart: false,
  orderImageBasket: false,
  authentication: false,
  onlyDownloadable: false,
  onlyViewable: false,
  shipToSupplier: false,
  downloadable: false,
  availableAttachment: false,
  choiceOfDay: false,
};
